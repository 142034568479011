import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesJapanese = [
  {
    profile_section_id: 7175,
    name: "PIP21.7X2.0",
    type: "Pipe",
    designation: "PIP21.7X2.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "1,24",
    d_global: "21,7",
    i_global: "0,61",
    t_global: 2,
    z_global: "0,78",
  },
  {
    profile_section_id: 7176,
    name: "PIP27.2X2.0",
    type: "Pipe",
    designation: "PIP27.2X2.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "1,58",
    d_global: "27,2",
    i_global: "1,26",
    t_global: 2,
    z_global: "1,27",
  },
  {
    profile_section_id: 7177,
    name: "PIP27.2X2.3",
    type: "Pipe",
    designation: "PIP27.2X2.3",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "1,8",
    d_global: "27,2",
    i_global: "1,41",
    t_global: "2,3",
    z_global: "1,43",
  },
  {
    profile_section_id: 7178,
    name: "PIP34X2.3",
    type: "Pipe",
    designation: "PIP34X2.3",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "2,29",
    d_global: 34,
    i_global: "2,89",
    t_global: "2,3",
    z_global: "2,31",
  },
  {
    profile_section_id: 7179,
    name: "PIP42.7X2.3",
    type: "Pipe",
    designation: "PIP42.7X2.3",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "2,92",
    d_global: "42,7",
    i_global: "5,97",
    t_global: "2,3",
    z_global: "3,75",
  },
  {
    profile_section_id: 7180,
    name: "PIP42.7X2.5",
    type: "Pipe",
    designation: "PIP42.7X2.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "3,16",
    d_global: "42,7",
    i_global: "6,4",
    t_global: "2,5",
    z_global: "4,04",
  },
  {
    profile_section_id: 7181,
    name: "PIP42.7X2.8",
    type: "Pipe",
    designation: "PIP42.7X2.8",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "3,51",
    d_global: "42,7",
    i_global: "7,02",
    t_global: "2,8",
    z_global: "4,46",
  },
  {
    profile_section_id: 7182,
    name: "PIP48.6X2.3",
    type: "Pipe",
    designation: "PIP48.6X2.3",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "3,35",
    d_global: "48,6",
    i_global: "8,99",
    t_global: "2,3",
    z_global: "4,93",
  },
  {
    profile_section_id: 7183,
    name: "PIP48.6X2.5",
    type: "Pipe",
    designation: "PIP48.6X2.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "3,62",
    d_global: "48,6",
    i_global: "9,65",
    t_global: "2,5",
    z_global: "5,31",
  },
  {
    profile_section_id: 7184,
    name: "PIP48.6X2.8",
    type: "Pipe",
    designation: "PIP48.6X2.8",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "4,03",
    d_global: "48,6",
    i_global: "10,6",
    t_global: "2,8",
    z_global: "5,87",
  },
  {
    profile_section_id: 7185,
    name: "PIP48.6X3.2",
    type: "Pipe",
    designation: "PIP48.6X3.2",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "4,56",
    d_global: "48,6",
    i_global: "11,8",
    t_global: "3,2",
    z_global: "6,6",
  },
  {
    profile_section_id: 7186,
    name: "PIP60.5X2.3",
    type: "Pipe",
    designation: "PIP60.5X2.3",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "4,21",
    d_global: "60,5",
    i_global: "17,8",
    t_global: "2,3",
    z_global: "7,79",
  },
  {
    profile_section_id: 7187,
    name: "PIP60.5X3.2",
    type: "Pipe",
    designation: "PIP60.5X3.2",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "5,76",
    d_global: "60,5",
    i_global: "23,7",
    t_global: "3,2",
    z_global: "10,5",
  },
  {
    profile_section_id: 7188,
    name: "PIP60.5X4.0",
    type: "Pipe",
    designation: "PIP60.5X4.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "7,1",
    d_global: "60,5",
    i_global: "28,5",
    t_global: 4,
    z_global: "12,8",
  },
  {
    profile_section_id: 7189,
    name: "PIP76.3X2.8",
    type: "Pipe",
    designation: "PIP76.3X2.8",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "6,46",
    d_global: "76,3",
    i_global: "43,7",
    t_global: "2,8",
    z_global: "15,1",
  },
  {
    profile_section_id: 7190,
    name: "PIP76.3X3.2",
    type: "Pipe",
    designation: "PIP76.3X3.2",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "7,35",
    d_global: "76,3",
    i_global: "49,2",
    t_global: "3,2",
    z_global: "17,1",
  },
  {
    profile_section_id: 7191,
    name: "PIP76.3X4.0",
    type: "Pipe",
    designation: "PIP76.3X4.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "9,09",
    d_global: "76,3",
    i_global: "59,5",
    t_global: 4,
    z_global: "20,9",
  },
  {
    profile_section_id: 7192,
    name: "PIP89.1X2.8",
    type: "Pipe",
    designation: "PIP89.1X2.8",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "7,59",
    d_global: "89,1",
    i_global: "70,7",
    t_global: "2,8",
    z_global: "20,9",
  },
  {
    profile_section_id: 7193,
    name: "PIP89.1X3.2",
    type: "Pipe",
    designation: "PIP89.1X3.2",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "8,64",
    d_global: "89,1",
    i_global: "79,8",
    t_global: "3,2",
    z_global: "23,6",
  },
  {
    profile_section_id: 7194,
    name: "PIP89.1X4.0",
    type: "Pipe",
    designation: "PIP89.1X4.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "10,69",
    d_global: "89,1",
    i_global: 97,
    t_global: 4,
    z_global: 29,
  },
  {
    profile_section_id: 7195,
    name: "PIP101.6X3.2",
    type: "Pipe",
    designation: "PIP101.6X3.2",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "9,89",
    d_global: "101,6",
    i_global: 120,
    t_global: "3,2",
    z_global: 31,
  },
  {
    profile_section_id: 7196,
    name: "PIP101.6X4.0",
    type: "Pipe",
    designation: "PIP101.6X4.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "12,26",
    d_global: "101,6",
    i_global: 146,
    t_global: 4,
    z_global: "38,1",
  },
  {
    profile_section_id: 7197,
    name: "PIP101.6X5.0",
    type: "Pipe",
    designation: "PIP101.6X5.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "15,17",
    d_global: "101,6",
    i_global: 177,
    t_global: 5,
    z_global: "46,7",
  },
  {
    profile_section_id: 7198,
    name: "PIP114.3X3.2",
    type: "Pipe",
    designation: "PIP114.3X3.2",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "11,17",
    d_global: "114,3",
    i_global: 172,
    t_global: "3,2",
    z_global: "39,5",
  },
  {
    profile_section_id: 7199,
    name: "PIP114.3X3.5",
    type: "Pipe",
    designation: "PIP114.3X3.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "12,18",
    d_global: "114,3",
    i_global: 187,
    t_global: "3,5",
    z_global: 43,
  },
  {
    profile_section_id: 7200,
    name: "PIP114.3X4.5",
    type: "Pipe",
    designation: "PIP114.3X4.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "15,52",
    d_global: "114,3",
    i_global: 234,
    t_global: "4,5",
    z_global: "54,3",
  },
  {
    profile_section_id: 7201,
    name: "PIP114.3X5.6",
    type: "Pipe",
    designation: "PIP114.3X5.6",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "19,12",
    d_global: "114,3",
    i_global: 283,
    t_global: "5,6",
    z_global: "66,2",
  },
  {
    profile_section_id: 7202,
    name: "PIP139.8X3.6",
    type: "Pipe",
    designation: "PIP139.8X3.6",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "15,4",
    d_global: "139,8",
    i_global: 357,
    t_global: "3,6",
    z_global: "66,8",
  },
  {
    profile_section_id: 7203,
    name: "PIP139.8X4.0",
    type: "Pipe",
    designation: "PIP139.8X4.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "17,07",
    d_global: "139,8",
    i_global: 394,
    t_global: 4,
    z_global: "73,8",
  },
  {
    profile_section_id: 7204,
    name: "PIP139.8X4.5",
    type: "Pipe",
    designation: "PIP139.8X4.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "19,13",
    d_global: "139,8",
    i_global: 438,
    t_global: "4,5",
    z_global: "82,4",
  },
  {
    profile_section_id: 7205,
    name: "PIP139.8X6.0",
    type: "Pipe",
    designation: "PIP139.8X6.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "25,22",
    d_global: "139,8",
    i_global: 566,
    t_global: 6,
    z_global: 107,
  },
  {
    profile_section_id: 7206,
    name: "PIP165.2X4.5",
    type: "Pipe",
    designation: "PIP165.2X4.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "22,72",
    d_global: "165,2",
    i_global: 734,
    t_global: "4,5",
    z_global: 116,
  },
  {
    profile_section_id: 7207,
    name: "PIP165.2X5.0",
    type: "Pipe",
    designation: "PIP165.2X5.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "25,16",
    d_global: "165,2",
    i_global: 808,
    t_global: 5,
    z_global: 128,
  },
  {
    profile_section_id: 7208,
    name: "PIP165.2X6.0",
    type: "Pipe",
    designation: "PIP165.2X6.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "30,01",
    d_global: "165,2",
    i_global: 952,
    t_global: 6,
    z_global: 152,
  },
  {
    profile_section_id: 7209,
    name: "PIP165.2X7.1",
    type: "Pipe",
    designation: "PIP165.2X7.1",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "35,26",
    d_global: "165,2",
    i_global: 1100,
    t_global: "7,1",
    z_global: 178,
  },
  {
    profile_section_id: 7210,
    name: "PIP190.7X4.5",
    type: "Pipe",
    designation: "PIP190.7X4.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "26,32",
    d_global: "190,7",
    i_global: 1140,
    t_global: "4,5",
    z_global: 156,
  },
  {
    profile_section_id: 7211,
    name: "PIP190.7X5.3",
    type: "Pipe",
    designation: "PIP190.7X5.3",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "30,87",
    d_global: "190,7",
    i_global: 1330,
    t_global: "5,3",
    z_global: 182,
  },
  {
    profile_section_id: 7212,
    name: "PIP190.7X6.0",
    type: "Pipe",
    designation: "PIP190.7X6.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "34,82",
    d_global: "190,7",
    i_global: 1490,
    t_global: 6,
    z_global: 205,
  },
  {
    profile_section_id: 7213,
    name: "PIP190.7X7.0",
    type: "Pipe",
    designation: "PIP190.7X7.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "40,4",
    d_global: "190,7",
    i_global: 1710,
    t_global: 7,
    z_global: 236,
  },
  {
    profile_section_id: 7214,
    name: "PIP190.7X8.2",
    type: "Pipe",
    designation: "PIP190.7X8.2",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "47,01",
    d_global: "190,7",
    i_global: 1960,
    t_global: "8,2",
    z_global: 273,
  },
  {
    profile_section_id: 7215,
    name: "PIP216.3X4.5",
    type: "Pipe",
    designation: "PIP216.3X4.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "29,94",
    d_global: "216,3",
    i_global: 1680,
    t_global: "4,5",
    z_global: 202,
  },
  {
    profile_section_id: 7216,
    name: "PIP216.3X5.8",
    type: "Pipe",
    designation: "PIP216.3X5.8",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "38,36",
    d_global: "216,3",
    i_global: 2130,
    t_global: "5,8",
    z_global: 257,
  },
  {
    profile_section_id: 7217,
    name: "PIP216.3X6.0",
    type: "Pipe",
    designation: "PIP216.3X6.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "39,64",
    d_global: "216,3",
    i_global: 2190,
    t_global: 6,
    z_global: 265,
  },
  {
    profile_section_id: 7218,
    name: "PIP216.3X7.0",
    type: "Pipe",
    designation: "PIP216.3X7.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "46,03",
    d_global: "216,3",
    i_global: 2520,
    t_global: 7,
    z_global: 307,
  },
  {
    profile_section_id: 7219,
    name: "PIP216.3X8.0",
    type: "Pipe",
    designation: "PIP216.3X8.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "52,35",
    d_global: "216,3",
    i_global: 2840,
    t_global: 8,
    z_global: 347,
  },
  {
    profile_section_id: 7220,
    name: "PIP216.3X8.2",
    type: "Pipe",
    designation: "PIP216.3X8.2",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "53,61",
    d_global: "216,3",
    i_global: 2910,
    t_global: "8,2",
    z_global: 355,
  },
  {
    profile_section_id: 7221,
    name: "PIP267.4X6.0",
    type: "Pipe",
    designation: "PIP267.4X6.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "49,27",
    d_global: "267,4",
    i_global: 4210,
    t_global: 6,
    z_global: 410,
  },
  {
    profile_section_id: 7222,
    name: "PIP267.4X6.6",
    type: "Pipe",
    designation: "PIP267.4X6.6",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "54,08",
    d_global: "267,4",
    i_global: 4600,
    t_global: "6,6",
    z_global: 449,
  },
  {
    profile_section_id: 7223,
    name: "PIP267.4X7.0",
    type: "Pipe",
    designation: "PIP267.4X7.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "57,26",
    d_global: "267,4",
    i_global: 4860,
    t_global: 7,
    z_global: 475,
  },
  {
    profile_section_id: 7224,
    name: "PIP267.4X8.0",
    type: "Pipe",
    designation: "PIP267.4X8.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "65,19",
    d_global: "267,4",
    i_global: 5490,
    t_global: 8,
    z_global: 538,
  },
  {
    profile_section_id: 7225,
    name: "PIP267.4X9.0",
    type: "Pipe",
    designation: "PIP267.4X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "73,06",
    d_global: "267,4",
    i_global: 6110,
    t_global: 9,
    z_global: 601,
  },
  {
    profile_section_id: 7226,
    name: "PIP267.4X9.3",
    type: "Pipe",
    designation: "PIP267.4X9.3",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "75,41",
    d_global: "267,4",
    i_global: 6290,
    t_global: "9,3",
    z_global: 620,
  },
  {
    profile_section_id: 7227,
    name: "PIP318.5X6.0",
    type: "Pipe",
    designation: "PIP318.5X6.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "58,91",
    d_global: "318,5",
    i_global: 7190,
    t_global: 6,
    z_global: 586,
  },
  {
    profile_section_id: 7228,
    name: "PIP318.5X6.9",
    type: "Pipe",
    designation: "PIP318.5X6.9",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "67,55",
    d_global: "318,5",
    i_global: 8200,
    t_global: "6,9",
    z_global: 670,
  },
  {
    profile_section_id: 7229,
    name: "PIP318.5X8.0",
    type: "Pipe",
    designation: "PIP318.5X8.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "78,04",
    d_global: "318,5",
    i_global: 9410,
    t_global: 8,
    z_global: 771,
  },
  {
    profile_section_id: 7230,
    name: "PIP318.5X9.0",
    type: "Pipe",
    designation: "PIP318.5X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "87,51",
    d_global: "318,5",
    i_global: 10500,
    t_global: 9,
    z_global: 862,
  },
  {
    profile_section_id: 7231,
    name: "PIP318.5X10.3",
    type: "Pipe",
    designation: "PIP318.5X10.3",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "99,73",
    d_global: "318,5",
    i_global: 11900,
    t_global: "10,3",
    z_global: 979,
  },
  {
    profile_section_id: 7232,
    name: "PIP355.6X6.4",
    type: "Pipe",
    designation: "PIP355.6X6.4",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "70,21",
    d_global: "355,6",
    i_global: 10700,
    t_global: "6,4",
    z_global: 781,
  },
  {
    profile_section_id: 7233,
    name: "PIP355.6X7.9",
    type: "Pipe",
    designation: "PIP355.6X7.9",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "86,29",
    d_global: "355,6",
    i_global: 13000,
    t_global: "7,9",
    z_global: 955,
  },
  {
    profile_section_id: 7234,
    name: "PIP355.6X9.0",
    type: "Pipe",
    designation: "PIP355.6X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: 98,
    d_global: "355,6",
    i_global: 14700,
    t_global: 9,
    z_global: 1080,
  },
  {
    profile_section_id: 7235,
    name: "PIP355.6X9.5",
    type: "Pipe",
    designation: "PIP355.6X9.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "103,3",
    d_global: "355,6",
    i_global: 15500,
    t_global: "9,5",
    z_global: 1140,
  },
  {
    profile_section_id: 7236,
    name: "PIP355.6X12.0",
    type: "Pipe",
    designation: "PIP355.6X12.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "129,5",
    d_global: "355,6",
    i_global: 19100,
    t_global: 12,
    z_global: 1420,
  },
  {
    profile_section_id: 7237,
    name: "PIP355.6X12.7",
    type: "Pipe",
    designation: "PIP355.6X12.7",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "136,8",
    d_global: "355,6",
    i_global: 20100,
    t_global: "12,7",
    z_global: 1490,
  },
  {
    profile_section_id: 7238,
    name: "PIP406.4X7.9",
    type: "Pipe",
    designation: "PIP406.4X7.9",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "98,9",
    d_global: "406,4",
    i_global: 19600,
    t_global: "7,9",
    z_global: 1250,
  },
  {
    profile_section_id: 7239,
    name: "PIP406.4X9.0",
    type: "Pipe",
    designation: "PIP406.4X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "112,4",
    d_global: "406,4",
    i_global: 22200,
    t_global: 9,
    z_global: 1420,
  },
  {
    profile_section_id: 7240,
    name: "PIP406.4X9.5",
    type: "Pipe",
    designation: "PIP406.4X9.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "118,5",
    d_global: "406,4",
    i_global: 23300,
    t_global: "9,5",
    z_global: 1500,
  },
  {
    profile_section_id: 7241,
    name: "PIP406.4X12.0",
    type: "Pipe",
    designation: "PIP406.4X12.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "148,7",
    d_global: "406,4",
    i_global: 28900,
    t_global: 12,
    z_global: 1870,
  },
  {
    profile_section_id: 7242,
    name: "PIP406.4X12.7",
    type: "Pipe",
    designation: "PIP406.4X12.7",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "157,1",
    d_global: "406,4",
    i_global: 30500,
    t_global: "12,7",
    z_global: 1970,
  },
  {
    profile_section_id: 7243,
    name: "PIP406.4X16.0",
    type: "Pipe",
    designation: "PIP406.4X16.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "196,2",
    d_global: "406,4",
    i_global: 37400,
    t_global: 16,
    z_global: 2440,
  },
  {
    profile_section_id: 7244,
    name: "PIP406.4X19.0",
    type: "Pipe",
    designation: "PIP406.4X19.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "231,2",
    d_global: "406,4",
    i_global: 43500,
    t_global: 19,
    z_global: 2850,
  },
  {
    profile_section_id: 7245,
    name: "PIP457.2X9.0",
    type: "Pipe",
    designation: "PIP457.2X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "126,7",
    d_global: "457,2",
    i_global: 31800,
    t_global: 9,
    z_global: 1810,
  },
  {
    profile_section_id: 7246,
    name: "PIP457.2X9.5",
    type: "Pipe",
    designation: "PIP457.2X9.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "133,6",
    d_global: "457,2",
    i_global: 33500,
    t_global: "9,5",
    z_global: 1900,
  },
  {
    profile_section_id: 7247,
    name: "PIP457.2X12.0",
    type: "Pipe",
    designation: "PIP457.2X12.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "167,8",
    d_global: "457,2",
    i_global: 41600,
    t_global: 12,
    z_global: 2380,
  },
  {
    profile_section_id: 7248,
    name: "PIP457.2X12.7",
    type: "Pipe",
    designation: "PIP457.2X12.7",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "177,3",
    d_global: "457,2",
    i_global: 43800,
    t_global: "12,7",
    z_global: 2510,
  },
  {
    profile_section_id: 7249,
    name: "PIP457.2X16.0",
    type: "Pipe",
    designation: "PIP457.2X16.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "221,8",
    d_global: "457,2",
    i_global: 54000,
    t_global: 16,
    z_global: 3110,
  },
  {
    profile_section_id: 7250,
    name: "PIP457.2X19.0",
    type: "Pipe",
    designation: "PIP457.2X19.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "261,6",
    d_global: "457,2",
    i_global: 62900,
    t_global: 19,
    z_global: 3650,
  },
  {
    profile_section_id: 7251,
    name: "PIP500X9.0",
    type: "Pipe",
    designation: "PIP500X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "138,8",
    d_global: 500,
    i_global: 41800,
    t_global: 9,
    z_global: 2170,
  },
  {
    profile_section_id: 7252,
    name: "PIP500X12.0",
    type: "Pipe",
    designation: "PIP500X12.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: 184,
    d_global: 500,
    i_global: 54800,
    t_global: 12,
    z_global: 2860,
  },
  {
    profile_section_id: 7253,
    name: "PIP500X14.0",
    type: "Pipe",
    designation: "PIP500X14.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "213,8",
    d_global: 500,
    i_global: 63200,
    t_global: 14,
    z_global: 3310,
  },
  {
    profile_section_id: 7254,
    name: "PIP508X7.9",
    type: "Pipe",
    designation: "PIP508X7.9",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "124,1",
    d_global: 508,
    i_global: 38800,
    t_global: "7,9",
    z_global: 1980,
  },
  {
    profile_section_id: 7255,
    name: "PIP508X9.0",
    type: "Pipe",
    designation: "PIP508X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "141,1",
    d_global: 508,
    i_global: 43900,
    t_global: 9,
    z_global: 2240,
  },
  {
    profile_section_id: 7256,
    name: "PIP508X9.5",
    type: "Pipe",
    designation: "PIP508X9.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "148,8",
    d_global: 508,
    i_global: 46200,
    t_global: "9,5",
    z_global: 2360,
  },
  {
    profile_section_id: 7257,
    name: "PIP508X12.0",
    type: "Pipe",
    designation: "PIP508X12.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: 187,
    d_global: 508,
    i_global: 57500,
    t_global: 12,
    z_global: 2950,
  },
  {
    profile_section_id: 7258,
    name: "PIP508X12.7",
    type: "Pipe",
    designation: "PIP508X12.7",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "197,6",
    d_global: 508,
    i_global: 60600,
    t_global: "12,7",
    z_global: 3120,
  },
  {
    profile_section_id: 7259,
    name: "PIP508X14.0",
    type: "Pipe",
    designation: "PIP508X14.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "217,3",
    d_global: 508,
    i_global: 66300,
    t_global: 14,
    z_global: 3420,
  },
  {
    profile_section_id: 7260,
    name: "PIP508X16.0",
    type: "Pipe",
    designation: "PIP508X16.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "247,3",
    d_global: 508,
    i_global: 74900,
    t_global: 16,
    z_global: 3870,
  },
  {
    profile_section_id: 7261,
    name: "PIP508X19.0",
    type: "Pipe",
    designation: "PIP508X19.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "291,9",
    d_global: 508,
    i_global: 87400,
    t_global: 19,
    z_global: 4540,
  },
  {
    profile_section_id: 7262,
    name: "PIP508X22.0",
    type: "Pipe",
    designation: "PIP508X22.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "335,9",
    d_global: 508,
    i_global: 99400,
    t_global: 22,
    z_global: 5200,
  },
  {
    profile_section_id: 7263,
    name: "PIP558.8X9.0",
    type: "Pipe",
    designation: "PIP558.8X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "155,5",
    d_global: "558,8",
    i_global: 58800,
    t_global: 9,
    z_global: 2720,
  },
  {
    profile_section_id: 7264,
    name: "PIP558.8X12.0",
    type: "Pipe",
    designation: "PIP558.8X12.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "206,1",
    d_global: "558,8",
    i_global: 77100,
    t_global: 12,
    z_global: 3590,
  },
  {
    profile_section_id: 7265,
    name: "PIP558.8X16.0",
    type: "Pipe",
    designation: "PIP558.8X16.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "272,8",
    d_global: "558,8",
    i_global: 101000,
    t_global: 16,
    z_global: 4710,
  },
  {
    profile_section_id: 7266,
    name: "PIP558.8X19.0",
    type: "Pipe",
    designation: "PIP558.8X19.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "322,2",
    d_global: "558,8",
    i_global: 118000,
    t_global: 19,
    z_global: 5540,
  },
  {
    profile_section_id: 7267,
    name: "PIP558.8X22.0",
    type: "Pipe",
    designation: "PIP558.8X22.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: 371,
    d_global: "558,8",
    i_global: 134000,
    t_global: 22,
    z_global: 6340,
  },
  {
    profile_section_id: 7268,
    name: "PIP600X9.0",
    type: "Pipe",
    designation: "PIP600X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "167,1",
    d_global: 600,
    i_global: 73000,
    t_global: 9,
    z_global: 3140,
  },
  {
    profile_section_id: 7269,
    name: "PIP600X12.0",
    type: "Pipe",
    designation: "PIP600X12.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "221,7",
    d_global: 600,
    i_global: 95800,
    t_global: 12,
    z_global: 4150,
  },
  {
    profile_section_id: 7270,
    name: "PIP600X14.0",
    type: "Pipe",
    designation: "PIP600X14.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "257,7",
    d_global: 600,
    i_global: 111000,
    t_global: 14,
    z_global: 4810,
  },
  {
    profile_section_id: 7271,
    name: "PIP600X16.0",
    type: "Pipe",
    designation: "PIP600X16.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "293,6",
    d_global: 600,
    i_global: 125000,
    t_global: 16,
    z_global: 5460,
  },
  {
    profile_section_id: 7272,
    name: "PIP609.6X9.0",
    type: "Pipe",
    designation: "PIP609.6X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "169,8",
    d_global: "609,6",
    i_global: 76600,
    t_global: 9,
    z_global: 3250,
  },
  {
    profile_section_id: 7273,
    name: "PIP609.6X9.5",
    type: "Pipe",
    designation: "PIP609.6X9.5",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "179,1",
    d_global: "609,6",
    i_global: 80600,
    t_global: "9,5",
    z_global: 3420,
  },
  {
    profile_section_id: 7274,
    name: "PIP609.6X12.0",
    type: "Pipe",
    designation: "PIP609.6X12.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "225,3",
    d_global: "609,6",
    i_global: 101000,
    t_global: 12,
    z_global: 4290,
  },
  {
    profile_section_id: 7275,
    name: "PIP609.6X12.7",
    type: "Pipe",
    designation: "PIP609.6X12.7",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "238,2",
    d_global: "609,6",
    i_global: 106000,
    t_global: "12,7",
    z_global: 4530,
  },
  {
    profile_section_id: 7276,
    name: "PIP609.6X14.0",
    type: "Pipe",
    designation: "PIP609.6X14.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: 262,
    d_global: "609,6",
    i_global: 116000,
    t_global: 14,
    z_global: 4970,
  },
  {
    profile_section_id: 7277,
    name: "PIP609.6X16.0",
    type: "Pipe",
    designation: "PIP609.6X16.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "298,4",
    d_global: "609,6",
    i_global: 132000,
    t_global: 16,
    z_global: 5640,
  },
  {
    profile_section_id: 7278,
    name: "PIP609.6X19.0",
    type: "Pipe",
    designation: "PIP609.6X19.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "352,5",
    d_global: "609,6",
    i_global: 154000,
    t_global: 19,
    z_global: 6630,
  },
  {
    profile_section_id: 7279,
    name: "PIP609.6X22.0",
    type: "Pipe",
    designation: "PIP609.6X22.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "406,1",
    d_global: "609,6",
    i_global: 176000,
    t_global: 22,
    z_global: 7600,
  },
  {
    profile_section_id: 7280,
    name: "PIP700X9.0",
    type: "Pipe",
    designation: "PIP700X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "195,4",
    d_global: 700,
    i_global: 117000,
    t_global: 9,
    z_global: 4300,
  },
  {
    profile_section_id: 7281,
    name: "PIP700X12.0",
    type: "Pipe",
    designation: "PIP700X12.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "259,4",
    d_global: 700,
    i_global: 154000,
    t_global: 12,
    z_global: 5680,
  },
  {
    profile_section_id: 7282,
    name: "PIP700X14.0",
    type: "Pipe",
    designation: "PIP700X14.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "301,7",
    d_global: 700,
    i_global: 178000,
    t_global: 14,
    z_global: 6590,
  },
  {
    profile_section_id: 7283,
    name: "PIP700X16.0",
    type: "Pipe",
    designation: "PIP700X16.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "343,8",
    d_global: 700,
    i_global: 201000,
    t_global: 16,
    z_global: 7490,
  },
  {
    profile_section_id: 7284,
    name: "PIP711.2X9.0",
    type: "Pipe",
    designation: "PIP711.2X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "198,5",
    d_global: "711,2",
    i_global: 122000,
    t_global: 9,
    z_global: 4440,
  },
  {
    profile_section_id: 7285,
    name: "PIP711.2X12.0",
    type: "Pipe",
    designation: "PIP711.2X12.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "263,6",
    d_global: "711,2",
    i_global: 161000,
    t_global: 12,
    z_global: 5870,
  },
  {
    profile_section_id: 7286,
    name: "PIP711.2X14.0",
    type: "Pipe",
    designation: "PIP711.2X14.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "306,6",
    d_global: "711,2",
    i_global: 186000,
    t_global: 14,
    z_global: 6810,
  },
  {
    profile_section_id: 7287,
    name: "PIP711.2X16.0",
    type: "Pipe",
    designation: "PIP711.2X16.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "349,4",
    d_global: "711,2",
    i_global: 211000,
    t_global: 16,
    z_global: 7730,
  },
  {
    profile_section_id: 7288,
    name: "PIP711.2X19.0",
    type: "Pipe",
    designation: "PIP711.2X19.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "413,2",
    d_global: "711,2",
    i_global: 248000,
    t_global: 19,
    z_global: 9100,
  },
  {
    profile_section_id: 7289,
    name: "PIP711.2X22.0",
    type: "Pipe",
    designation: "PIP711.2X22.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "476,3",
    d_global: "711,2",
    i_global: 283000,
    t_global: 22,
    z_global: 10500,
  },
  {
    profile_section_id: 7290,
    name: "PIP812.8X9.0",
    type: "Pipe",
    designation: "PIP812.8X9.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "227,3",
    d_global: "812,8",
    i_global: 184000,
    t_global: 9,
    z_global: 5810,
  },
  {
    profile_section_id: 7291,
    name: "PIP812.8X12.0",
    type: "Pipe",
    designation: "PIP812.8X12.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "301,9",
    d_global: "812,8",
    i_global: 242000,
    t_global: 12,
    z_global: 7700,
  },
  {
    profile_section_id: 7292,
    name: "PIP812.8X14.0",
    type: "Pipe",
    designation: "PIP812.8X14.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "351,3",
    d_global: "812,8",
    i_global: 280000,
    t_global: 14,
    z_global: 8930,
  },
  {
    profile_section_id: 7293,
    name: "PIP812.8X16.0",
    type: "Pipe",
    designation: "PIP812.8X16.0",
    shape: "PIPE",
    country_code: "Japanese",
    ax_global: "400,5",
    d_global: "812,8",
    i_global: 318000,
    t_global: 16,
    z_global: 10200,
  },
  {
    profile_section_id: 7306,
    name: "CHS60.5x3.2",
    type: "Circular Hollow",
    designation: "CHS60.5x3.2",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "5,76",
    d_global: "60,5",
    i_global: "23,7",
    t_global: "3,2",
    z_global: "10,5",
  },
  {
    profile_section_id: 7307,
    name: "CHS60.5x4.5",
    type: "Circular Hollow",
    designation: "CHS60.5x4.5",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "7,92",
    d_global: "60,5",
    i_global: "31,2",
    t_global: "4,5",
    z_global: "14,1",
  },
  {
    profile_section_id: 7308,
    name: "CHS76.3x3.2",
    type: "Circular Hollow",
    designation: "CHS76.3x3.2",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "7,35",
    d_global: "76,3",
    i_global: "49,2",
    t_global: "3,2",
    z_global: "17,1",
  },
  {
    profile_section_id: 7309,
    name: "CHS76.3x4.5",
    type: "Circular Hollow",
    designation: "CHS76.3x4.5",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "10,15",
    d_global: "76,3",
    i_global: "65,7",
    t_global: "4,5",
    z_global: "23,2",
  },
  {
    profile_section_id: 7310,
    name: "CHS89.1x3.2",
    type: "Circular Hollow",
    designation: "CHS89.1x3.2",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "8,64",
    d_global: "89,1",
    i_global: "79,8",
    t_global: "3,2",
    z_global: "23,6",
  },
  {
    profile_section_id: 7311,
    name: "CHS89.1x4.5",
    type: "Circular Hollow",
    designation: "CHS89.1x4.5",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "11,96",
    d_global: "89,1",
    i_global: 107,
    t_global: "4,5",
    z_global: "32,2",
  },
  {
    profile_section_id: 7312,
    name: "CHS101.6x3.2",
    type: "Circular Hollow",
    designation: "CHS101.6x3.2",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "9,89",
    d_global: "101,6",
    i_global: 120,
    t_global: "3,2",
    z_global: 31,
  },
  {
    profile_section_id: 7313,
    name: "CHS101.6x4.5",
    type: "Circular Hollow",
    designation: "CHS101.6x4.5",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "13,73",
    d_global: "101,6",
    i_global: 162,
    t_global: "4,5",
    z_global: "42,5",
  },
  {
    profile_section_id: 7314,
    name: "CHS114.3x3.2",
    type: "Circular Hollow",
    designation: "CHS114.3x3.2",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "11,17",
    d_global: "114,3",
    i_global: 172,
    t_global: "3,2",
    z_global: "39,5",
  },
  {
    profile_section_id: 7315,
    name: "CHS114.3x4.5",
    type: "Circular Hollow",
    designation: "CHS114.3x4.5",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "15,52",
    d_global: "114,3",
    i_global: 234,
    t_global: "4,5",
    z_global: "54,3",
  },
  {
    profile_section_id: 7316,
    name: "CHS114.3x6",
    type: "Circular Hollow",
    designation: "CHS114.3x6",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "20,41",
    d_global: "114,3",
    i_global: 300,
    t_global: 6,
    z_global: "70,4",
  },
  {
    profile_section_id: 7317,
    name: "CHS139.8x4.5",
    type: "Circular Hollow",
    designation: "CHS139.8x4.5",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "19,13",
    d_global: "139,8",
    i_global: 438,
    t_global: "4,5",
    z_global: "82,4",
  },
  {
    profile_section_id: 7318,
    name: "CHS139.8x6",
    type: "Circular Hollow",
    designation: "CHS139.8x6",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "25,22",
    d_global: "139,8",
    i_global: 566,
    t_global: 6,
    z_global: 107,
  },
  {
    profile_section_id: 7319,
    name: "CHS165.2x4.5",
    type: "Circular Hollow",
    designation: "CHS165.2x4.5",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "22,72",
    d_global: "165,2",
    i_global: 734,
    t_global: "4,5",
    z_global: 116,
  },
  {
    profile_section_id: 7320,
    name: "CHS165.2x6",
    type: "Circular Hollow",
    designation: "CHS165.2x6",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "30,01",
    d_global: "165,2",
    i_global: 952,
    t_global: 6,
    z_global: 152,
  },
  {
    profile_section_id: 7321,
    name: "CHS190.7x4.5",
    type: "Circular Hollow",
    designation: "CHS190.7x4.5",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "26,32",
    d_global: "190,7",
    i_global: 1140,
    t_global: "4,5",
    z_global: 156,
  },
  {
    profile_section_id: 7322,
    name: "CHS190.7x6",
    type: "Circular Hollow",
    designation: "CHS190.7x6",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "34,82",
    d_global: "190,7",
    i_global: 1490,
    t_global: 6,
    z_global: 205,
  },
  {
    profile_section_id: 7323,
    name: "CHS190.7x8",
    type: "Circular Hollow",
    designation: "CHS190.7x8",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "45,92",
    d_global: "190,7",
    i_global: 1920,
    t_global: 8,
    z_global: 267,
  },
  {
    profile_section_id: 7324,
    name: "CHS216.3x6",
    type: "Circular Hollow",
    designation: "CHS216.3x6",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "39,64",
    d_global: "216,3",
    i_global: 2190,
    t_global: 6,
    z_global: 265,
  },
  {
    profile_section_id: 7325,
    name: "CHS216.3x8",
    type: "Circular Hollow",
    designation: "CHS216.3x8",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "52,35",
    d_global: "216,3",
    i_global: 2840,
    t_global: 8,
    z_global: 347,
  },
  {
    profile_section_id: 7326,
    name: "CHS216.3x10",
    type: "Circular Hollow",
    designation: "CHS216.3x10",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "64,81",
    d_global: "216,3",
    i_global: 3460,
    t_global: 10,
    z_global: 426,
  },
  {
    profile_section_id: 7327,
    name: "CHS216.3x12",
    type: "Circular Hollow",
    designation: "CHS216.3x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "77,02",
    d_global: "216,3",
    i_global: 4030,
    t_global: 12,
    z_global: 501,
  },
  {
    profile_section_id: 7328,
    name: "CHS216.3x12.7",
    type: "Circular Hollow",
    designation: "CHS216.3x12.7",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "81,23",
    d_global: "216,3",
    i_global: 4230,
    t_global: "12,7",
    z_global: 527,
  },
  {
    profile_section_id: 7329,
    name: "CHS267.4x6",
    type: "Circular Hollow",
    designation: "CHS267.4x6",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "49,27",
    d_global: "267,4",
    i_global: 4210,
    t_global: 6,
    z_global: 410,
  },
  {
    profile_section_id: 7330,
    name: "CHS267.4x8",
    type: "Circular Hollow",
    designation: "CHS267.4x8",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "65,19",
    d_global: "267,4",
    i_global: 5490,
    t_global: 8,
    z_global: 538,
  },
  {
    profile_section_id: 7331,
    name: "CHS267.4x9",
    type: "Circular Hollow",
    designation: "CHS267.4x9",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "73,06",
    d_global: "267,4",
    i_global: 6110,
    t_global: 9,
    z_global: 601,
  },
  {
    profile_section_id: 7332,
    name: "CHS267.4x12.7",
    type: "Circular Hollow",
    designation: "CHS267.4x12.7",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "101,6",
    d_global: "267,4",
    i_global: 8260,
    t_global: "12,7",
    z_global: 825,
  },
  {
    profile_section_id: 7333,
    name: "CHS318.5x6",
    type: "Circular Hollow",
    designation: "CHS318.5x6",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "58,91",
    d_global: "318,5",
    i_global: 7190,
    t_global: 6,
    z_global: 586,
  },
  {
    profile_section_id: 7334,
    name: "CHS318.5x7.9",
    type: "Circular Hollow",
    designation: "CHS318.5x7.9",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "77,09",
    d_global: "318,5",
    i_global: 9300,
    t_global: "7,9",
    z_global: 762,
  },
  {
    profile_section_id: 7335,
    name: "CHS318.5x8",
    type: "Circular Hollow",
    designation: "CHS318.5x8",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "78,04",
    d_global: "318,5",
    i_global: 9410,
    t_global: 8,
    z_global: 771,
  },
  {
    profile_section_id: 7336,
    name: "CHS318.5x9",
    type: "Circular Hollow",
    designation: "CHS318.5x9",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "87,51",
    d_global: "318,5",
    i_global: 10500,
    t_global: 9,
    z_global: 862,
  },
  {
    profile_section_id: 7337,
    name: "CHS318.5x12.7",
    type: "Circular Hollow",
    designation: "CHS318.5x12.7",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 122,
    d_global: "318,5",
    i_global: 14300,
    t_global: "12,7",
    z_global: 1190,
  },
  {
    profile_section_id: 7338,
    name: "CHS355.6x6",
    type: "Circular Hollow",
    designation: "CHS355.6x6",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "65,9",
    d_global: "355,6",
    i_global: 10100,
    t_global: 6,
    z_global: 733,
  },
  {
    profile_section_id: 7339,
    name: "CHS355.6x8",
    type: "Circular Hollow",
    designation: "CHS355.6x8",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "87,36",
    d_global: "355,6",
    i_global: 13200,
    t_global: 8,
    z_global: 967,
  },
  {
    profile_section_id: 7340,
    name: "CHS355.6x9",
    type: "Circular Hollow",
    designation: "CHS355.6x9",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 98,
    d_global: "355,6",
    i_global: 14700,
    t_global: 9,
    z_global: 1080,
  },
  {
    profile_section_id: 7341,
    name: "CHS355.6x11.1",
    type: "Circular Hollow",
    designation: "CHS355.6x11.1",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "120,1",
    d_global: "355,6",
    i_global: 17800,
    t_global: "11,1",
    z_global: 1320,
  },
  {
    profile_section_id: 7342,
    name: "CHS355.6x12",
    type: "Circular Hollow",
    designation: "CHS355.6x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "129,5",
    d_global: "355,6",
    i_global: 19100,
    t_global: 12,
    z_global: 1420,
  },
  {
    profile_section_id: 7343,
    name: "CHS355.6x16",
    type: "Circular Hollow",
    designation: "CHS355.6x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "170,7",
    d_global: "355,6",
    i_global: 24700,
    t_global: 16,
    z_global: 1850,
  },
  {
    profile_section_id: 7344,
    name: "CHS355.6x19",
    type: "Circular Hollow",
    designation: "CHS355.6x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "200,9",
    d_global: "355,6",
    i_global: 28500,
    t_global: 19,
    z_global: 2150,
  },
  {
    profile_section_id: 7345,
    name: "CHS406.4x9",
    type: "Circular Hollow",
    designation: "CHS406.4x9",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "112,4",
    d_global: "406,4",
    i_global: 22200,
    t_global: 9,
    z_global: 1420,
  },
  {
    profile_section_id: 7346,
    name: "CHS406.4x12",
    type: "Circular Hollow",
    designation: "CHS406.4x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "148,7",
    d_global: "406,4",
    i_global: 28900,
    t_global: 12,
    z_global: 1870,
  },
  {
    profile_section_id: 7347,
    name: "CHS406.4x14",
    type: "Circular Hollow",
    designation: "CHS406.4x14",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "172,6",
    d_global: "406,4",
    i_global: 33300,
    t_global: 14,
    z_global: 2160,
  },
  {
    profile_section_id: 7348,
    name: "CHS406.4x16",
    type: "Circular Hollow",
    designation: "CHS406.4x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "196,2",
    d_global: "406,4",
    i_global: 37400,
    t_global: 16,
    z_global: 2440,
  },
  {
    profile_section_id: 7349,
    name: "CHS406.4x19",
    type: "Circular Hollow",
    designation: "CHS406.4x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "231,2",
    d_global: "406,4",
    i_global: 43500,
    t_global: 19,
    z_global: 2850,
  },
  {
    profile_section_id: 7350,
    name: "CHS450x19",
    type: "Circular Hollow",
    designation: "CHS450x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "257,3",
    d_global: 450,
    i_global: 59900,
    t_global: 19,
    z_global: 3530,
  },
  {
    profile_section_id: 7351,
    name: "CHS457.2x9",
    type: "Circular Hollow",
    designation: "CHS457.2x9",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "126,7",
    d_global: "457,2",
    i_global: 31800,
    t_global: 9,
    z_global: 1810,
  },
  {
    profile_section_id: 7352,
    name: "CHS457.2x12",
    type: "Circular Hollow",
    designation: "CHS457.2x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "167,8",
    d_global: "457,2",
    i_global: 41600,
    t_global: 12,
    z_global: 2380,
  },
  {
    profile_section_id: 7353,
    name: "CHS457.2x14",
    type: "Circular Hollow",
    designation: "CHS457.2x14",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "194,9",
    d_global: "457,2",
    i_global: 47900,
    t_global: 14,
    z_global: 2750,
  },
  {
    profile_section_id: 7354,
    name: "CHS457.2x16",
    type: "Circular Hollow",
    designation: "CHS457.2x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "221,8",
    d_global: "457,2",
    i_global: 54000,
    t_global: 16,
    z_global: 3120,
  },
  {
    profile_section_id: 7355,
    name: "CHS457.2x19",
    type: "Circular Hollow",
    designation: "CHS457.2x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "261,6",
    d_global: "457,2",
    i_global: 62900,
    t_global: 19,
    z_global: 3650,
  },
  {
    profile_section_id: 7356,
    name: "CHS500x9",
    type: "Circular Hollow",
    designation: "CHS500x9",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "138,8",
    d_global: 500,
    i_global: 41800,
    t_global: 9,
    z_global: 2170,
  },
  {
    profile_section_id: 7357,
    name: "CHS500x12",
    type: "Circular Hollow",
    designation: "CHS500x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 184,
    d_global: 500,
    i_global: 54800,
    t_global: 12,
    z_global: 2860,
  },
  {
    profile_section_id: 7358,
    name: "CHS500x14",
    type: "Circular Hollow",
    designation: "CHS500x14",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "213,8",
    d_global: 500,
    i_global: 63200,
    t_global: 14,
    z_global: 3310,
  },
  {
    profile_section_id: 7359,
    name: "CHS500x16",
    type: "Circular Hollow",
    designation: "CHS500x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "243,3",
    d_global: 500,
    i_global: 71300,
    t_global: 16,
    z_global: 3750,
  },
  {
    profile_section_id: 7360,
    name: "CHS500x19",
    type: "Circular Hollow",
    designation: "CHS500x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "287,1",
    d_global: 500,
    i_global: 83200,
    t_global: 19,
    z_global: 4400,
  },
  {
    profile_section_id: 7361,
    name: "CHS500x22",
    type: "Circular Hollow",
    designation: "CHS500x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "330,4",
    d_global: 500,
    i_global: 94600,
    t_global: 22,
    z_global: 5030,
  },
  {
    profile_section_id: 7362,
    name: "CHS508x9",
    type: "Circular Hollow",
    designation: "CHS508x9",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "141,1",
    d_global: 508,
    i_global: 43900,
    t_global: 9,
    z_global: 2240,
  },
  {
    profile_section_id: 7363,
    name: "CHS508x12",
    type: "Circular Hollow",
    designation: "CHS508x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 187,
    d_global: 508,
    i_global: 57500,
    t_global: 12,
    z_global: 2950,
  },
  {
    profile_section_id: 7364,
    name: "CHS508x14",
    type: "Circular Hollow",
    designation: "CHS508x14",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "217,3",
    d_global: 508,
    i_global: 66300,
    t_global: 14,
    z_global: 3420,
  },
  {
    profile_section_id: 7365,
    name: "CHS508x16",
    type: "Circular Hollow",
    designation: "CHS508x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "247,3",
    d_global: 508,
    i_global: 74900,
    t_global: 16,
    z_global: 3870,
  },
  {
    profile_section_id: 7366,
    name: "CHS508x19",
    type: "Circular Hollow",
    designation: "CHS508x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "291,9",
    d_global: 508,
    i_global: 87400,
    t_global: 19,
    z_global: 4550,
  },
  {
    profile_section_id: 7367,
    name: "CHS508x22",
    type: "Circular Hollow",
    designation: "CHS508x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "335,9",
    d_global: 508,
    i_global: 99400,
    t_global: 22,
    z_global: 5200,
  },
  {
    profile_section_id: 7368,
    name: "CHS550x19",
    type: "Circular Hollow",
    designation: "CHS550x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 317,
    d_global: 550,
    i_global: 112000,
    t_global: 19,
    z_global: 5360,
  },
  {
    profile_section_id: 7369,
    name: "CHS550x22",
    type: "Circular Hollow",
    designation: "CHS550x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "364,9",
    d_global: 550,
    i_global: 127000,
    t_global: 22,
    z_global: 6140,
  },
  {
    profile_section_id: 7370,
    name: "CHS558.8x9",
    type: "Circular Hollow",
    designation: "CHS558.8x9",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "155,5",
    d_global: "558,8",
    i_global: 58800,
    t_global: 9,
    z_global: 2720,
  },
  {
    profile_section_id: 7371,
    name: "CHS558.8x12",
    type: "Circular Hollow",
    designation: "CHS558.8x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "206,1",
    d_global: "558,8",
    i_global: 77100,
    t_global: 12,
    z_global: 3590,
  },
  {
    profile_section_id: 7372,
    name: "CHS558.8x12.7",
    type: "Circular Hollow",
    designation: "CHS558.8x12.7",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "217,9",
    d_global: "558,8",
    i_global: 81300,
    t_global: "12,7",
    z_global: 3790,
  },
  {
    profile_section_id: 7373,
    name: "CHS558.8x14",
    type: "Circular Hollow",
    designation: "CHS558.8x14",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "239,6",
    d_global: "558,8",
    i_global: 89000,
    t_global: 14,
    z_global: 4160,
  },
  {
    profile_section_id: 7374,
    name: "CHS558.8x16",
    type: "Circular Hollow",
    designation: "CHS558.8x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "272,8",
    d_global: "558,8",
    i_global: 101000,
    t_global: 16,
    z_global: 4720,
  },
  {
    profile_section_id: 7375,
    name: "CHS558.8x19",
    type: "Circular Hollow",
    designation: "CHS558.8x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "322,2",
    d_global: "558,8",
    i_global: 118000,
    t_global: 19,
    z_global: 5540,
  },
  {
    profile_section_id: 7376,
    name: "CHS558.8x22",
    type: "Circular Hollow",
    designation: "CHS558.8x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 371,
    d_global: "558,8",
    i_global: 134000,
    t_global: 22,
    z_global: 6340,
  },
  {
    profile_section_id: 7377,
    name: "CHS558.8x25",
    type: "Circular Hollow",
    designation: "CHS558.8x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "419,2",
    d_global: "558,8",
    i_global: 150000,
    t_global: 25,
    z_global: 7130,
  },
  {
    profile_section_id: 7378,
    name: "CHS600x9",
    type: "Circular Hollow",
    designation: "CHS600x9",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "167,1",
    d_global: 600,
    i_global: 73000,
    t_global: 9,
    z_global: 3140,
  },
  {
    profile_section_id: 7379,
    name: "CHS600x12",
    type: "Circular Hollow",
    designation: "CHS600x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "221,7",
    d_global: 600,
    i_global: 95800,
    t_global: 12,
    z_global: 4150,
  },
  {
    profile_section_id: 7380,
    name: "CHS600x14",
    type: "Circular Hollow",
    designation: "CHS600x14",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "257,7",
    d_global: 600,
    i_global: 111000,
    t_global: 14,
    z_global: 4810,
  },
  {
    profile_section_id: 7381,
    name: "CHS600x16",
    type: "Circular Hollow",
    designation: "CHS600x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "293,6",
    d_global: 600,
    i_global: 125000,
    t_global: 16,
    z_global: 5460,
  },
  {
    profile_section_id: 7382,
    name: "CHS600x19",
    type: "Circular Hollow",
    designation: "CHS600x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "346,8",
    d_global: 600,
    i_global: 146000,
    t_global: 19,
    z_global: 6420,
  },
  {
    profile_section_id: 7383,
    name: "CHS600x22",
    type: "Circular Hollow",
    designation: "CHS600x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "399,5",
    d_global: 600,
    i_global: 167000,
    t_global: 22,
    z_global: 7350,
  },
  {
    profile_section_id: 7384,
    name: "CHS600x25",
    type: "Circular Hollow",
    designation: "CHS600x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "451,6",
    d_global: 600,
    i_global: 187000,
    t_global: 25,
    z_global: 8270,
  },
  {
    profile_section_id: 7385,
    name: "CHS600x28",
    type: "Circular Hollow",
    designation: "CHS600x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "503,2",
    d_global: 600,
    i_global: 206000,
    t_global: 28,
    z_global: 9170,
  },
  {
    profile_section_id: 7386,
    name: "CHS600x32",
    type: "Circular Hollow",
    designation: "CHS600x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 571,
    d_global: 600,
    i_global: 231000,
    t_global: 32,
    z_global: 10300,
  },
  {
    profile_section_id: 7387,
    name: "CHS600x36",
    type: "Circular Hollow",
    designation: "CHS600x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "637,9",
    d_global: 600,
    i_global: 255000,
    t_global: 36,
    z_global: 11500,
  },
  {
    profile_section_id: 7388,
    name: "CHS600x40",
    type: "Circular Hollow",
    designation: "CHS600x40",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "703,7",
    d_global: 600,
    i_global: 277000,
    t_global: 40,
    z_global: 12600,
  },
  {
    profile_section_id: 7389,
    name: "CHS609.6x9",
    type: "Circular Hollow",
    designation: "CHS609.6x9",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "169,8",
    d_global: "609,6",
    i_global: 76600,
    t_global: 9,
    z_global: 3250,
  },
  {
    profile_section_id: 7390,
    name: "CHS609.6x12",
    type: "Circular Hollow",
    designation: "CHS609.6x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "225,3",
    d_global: "609,6",
    i_global: 101000,
    t_global: 12,
    z_global: 4290,
  },
  {
    profile_section_id: 7391,
    name: "CHS609.6x14",
    type: "Circular Hollow",
    designation: "CHS609.6x14",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 262,
    d_global: "609,6",
    i_global: 116000,
    t_global: 14,
    z_global: 4970,
  },
  {
    profile_section_id: 7392,
    name: "CHS609.6x16",
    type: "Circular Hollow",
    designation: "CHS609.6x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "298,4",
    d_global: "609,6",
    i_global: 132000,
    t_global: 16,
    z_global: 5640,
  },
  {
    profile_section_id: 7393,
    name: "CHS609.6x19",
    type: "Circular Hollow",
    designation: "CHS609.6x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "352,5",
    d_global: "609,6",
    i_global: 154000,
    t_global: 19,
    z_global: 6630,
  },
  {
    profile_section_id: 7394,
    name: "CHS609.6x22",
    type: "Circular Hollow",
    designation: "CHS609.6x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "406,1",
    d_global: "609,6",
    i_global: 176000,
    t_global: 22,
    z_global: 7600,
  },
  {
    profile_section_id: 7395,
    name: "CHS650x16",
    type: "Circular Hollow",
    designation: "CHS650x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "318,7",
    d_global: 650,
    i_global: 160000,
    t_global: 16,
    z_global: 6430,
  },
  {
    profile_section_id: 7396,
    name: "CHS650x19",
    type: "Circular Hollow",
    designation: "CHS650x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "376,6",
    d_global: 650,
    i_global: 188000,
    t_global: 19,
    z_global: 7570,
  },
  {
    profile_section_id: 7397,
    name: "CHS650x22",
    type: "Circular Hollow",
    designation: "CHS650x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 434,
    d_global: 650,
    i_global: 214000,
    t_global: 22,
    z_global: 8680,
  },
  {
    profile_section_id: 7398,
    name: "CHS650x25",
    type: "Circular Hollow",
    designation: "CHS650x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "490,9",
    d_global: 650,
    i_global: 240000,
    t_global: 25,
    z_global: 9770,
  },
  {
    profile_section_id: 7399,
    name: "CHS650x28",
    type: "Circular Hollow",
    designation: "CHS650x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "547,1",
    d_global: 650,
    i_global: 265000,
    t_global: 28,
    z_global: 10800,
  },
  {
    profile_section_id: 7400,
    name: "CHS650x32",
    type: "Circular Hollow",
    designation: "CHS650x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "621,3",
    d_global: 650,
    i_global: 297000,
    t_global: 32,
    z_global: 12200,
  },
  {
    profile_section_id: 7401,
    name: "CHS650x36",
    type: "Circular Hollow",
    designation: "CHS650x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "694,4",
    d_global: 650,
    i_global: 328000,
    t_global: 36,
    z_global: 13600,
  },
  {
    profile_section_id: 7402,
    name: "CHS650x40",
    type: "Circular Hollow",
    designation: "CHS650x40",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "766,6",
    d_global: 650,
    i_global: 358000,
    t_global: 40,
    z_global: 14900,
  },
  {
    profile_section_id: 7403,
    name: "CHS660.4x12",
    type: "Circular Hollow",
    designation: "CHS660.4x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "244,4",
    d_global: "660,4",
    i_global: 129000,
    t_global: 12,
    z_global: 5050,
  },
  {
    profile_section_id: 7404,
    name: "CHS660.4x14",
    type: "Circular Hollow",
    designation: "CHS660.4x14",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "284,3",
    d_global: "660,4",
    i_global: 149000,
    t_global: 14,
    z_global: 5850,
  },
  {
    profile_section_id: 7405,
    name: "CHS660.4x16",
    type: "Circular Hollow",
    designation: "CHS660.4x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "323,9",
    d_global: "660,4",
    i_global: 168000,
    t_global: 16,
    z_global: 6650,
  },
  {
    profile_section_id: 7406,
    name: "CHS660.4x19",
    type: "Circular Hollow",
    designation: "CHS660.4x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "382,9",
    d_global: "660,4",
    i_global: 197000,
    t_global: 19,
    z_global: 7820,
  },
  {
    profile_section_id: 7407,
    name: "CHS660.4x22",
    type: "Circular Hollow",
    designation: "CHS660.4x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "441,2",
    d_global: "660,4",
    i_global: 225000,
    t_global: 22,
    z_global: 8970,
  },
  {
    profile_section_id: 7408,
    name: "CHS660.4x28",
    type: "Circular Hollow",
    designation: "CHS660.4x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "556,3",
    d_global: "660,4",
    i_global: 279000,
    t_global: 28,
    z_global: 11200,
  },
  {
    profile_section_id: 7409,
    name: "CHS660.4x36",
    type: "Circular Hollow",
    designation: "CHS660.4x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "706,2",
    d_global: "660,4",
    i_global: 345000,
    t_global: 36,
    z_global: 14100,
  },
  {
    profile_section_id: 7410,
    name: "CHS700x12",
    type: "Circular Hollow",
    designation: "CHS700x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "259,4",
    d_global: 700,
    i_global: 154000,
    t_global: 12,
    z_global: 5680,
  },
  {
    profile_section_id: 7411,
    name: "CHS700x14",
    type: "Circular Hollow",
    designation: "CHS700x14",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "301,7",
    d_global: 700,
    i_global: 178000,
    t_global: 14,
    z_global: 6590,
  },
  {
    profile_section_id: 7412,
    name: "CHS700x16",
    type: "Circular Hollow",
    designation: "CHS700x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "343,8",
    d_global: 700,
    i_global: 201000,
    t_global: 16,
    z_global: 7490,
  },
  {
    profile_section_id: 7413,
    name: "CHS700x19",
    type: "Circular Hollow",
    designation: "CHS700x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "406,5",
    d_global: 700,
    i_global: 236000,
    t_global: 19,
    z_global: 8810,
  },
  {
    profile_section_id: 7414,
    name: "CHS700x22",
    type: "Circular Hollow",
    designation: "CHS700x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "468,6",
    d_global: 700,
    i_global: 270000,
    t_global: 22,
    z_global: 10100,
  },
  {
    profile_section_id: 7415,
    name: "CHS700x25",
    type: "Circular Hollow",
    designation: "CHS700x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "530,1",
    d_global: 700,
    i_global: 302000,
    t_global: 25,
    z_global: 11400,
  },
  {
    profile_section_id: 7416,
    name: "CHS700x28",
    type: "Circular Hollow",
    designation: "CHS700x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "591,1",
    d_global: 700,
    i_global: 334000,
    t_global: 28,
    z_global: 12700,
  },
  {
    profile_section_id: 7417,
    name: "CHS700x32",
    type: "Circular Hollow",
    designation: "CHS700x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "671,5",
    d_global: 700,
    i_global: 375000,
    t_global: 32,
    z_global: 14300,
  },
  {
    profile_section_id: 7418,
    name: "CHS700x36",
    type: "Circular Hollow",
    designation: "CHS700x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 751,
    d_global: 700,
    i_global: 415000,
    t_global: 36,
    z_global: 15900,
  },
  {
    profile_section_id: 7419,
    name: "CHS700x40",
    type: "Circular Hollow",
    designation: "CHS700x40",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "829,4",
    d_global: 700,
    i_global: 453000,
    t_global: 40,
    z_global: 17400,
  },
  {
    profile_section_id: 7420,
    name: "CHS711.2x12",
    type: "Circular Hollow",
    designation: "CHS711.2x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "263,6",
    d_global: "711,2",
    i_global: 161000,
    t_global: 12,
    z_global: 5870,
  },
  {
    profile_section_id: 7421,
    name: "CHS711.2x14",
    type: "Circular Hollow",
    designation: "CHS711.2x14",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "306,6",
    d_global: "711,2",
    i_global: 186000,
    t_global: 14,
    z_global: 6810,
  },
  {
    profile_section_id: 7422,
    name: "CHS711.2x16",
    type: "Circular Hollow",
    designation: "CHS711.2x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "349,4",
    d_global: "711,2",
    i_global: 211000,
    t_global: 16,
    z_global: 7730,
  },
  {
    profile_section_id: 7423,
    name: "CHS711.2x19",
    type: "Circular Hollow",
    designation: "CHS711.2x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "413,2",
    d_global: "711,2",
    i_global: 248000,
    t_global: 19,
    z_global: 9110,
  },
  {
    profile_section_id: 7424,
    name: "CHS711.2x22",
    type: "Circular Hollow",
    designation: "CHS711.2x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "476,3",
    d_global: "711,2",
    i_global: 283000,
    t_global: 22,
    z_global: 10500,
  },
  {
    profile_section_id: 7425,
    name: "CHS711.2x25",
    type: "Circular Hollow",
    designation: "CHS711.2x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "538,9",
    d_global: "711,2",
    i_global: 318000,
    t_global: 25,
    z_global: 11800,
  },
  {
    profile_section_id: 7426,
    name: "CHS711.2x28",
    type: "Circular Hollow",
    designation: "CHS711.2x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 601,
    d_global: "711,2",
    i_global: 351000,
    t_global: 28,
    z_global: 13100,
  },
  {
    profile_section_id: 7427,
    name: "CHS750x16",
    type: "Circular Hollow",
    designation: "CHS750x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "368,9",
    d_global: 750,
    i_global: 249000,
    t_global: 16,
    z_global: 8620,
  },
  {
    profile_section_id: 7428,
    name: "CHS750x19",
    type: "Circular Hollow",
    designation: "CHS750x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "436,3",
    d_global: 750,
    i_global: 292000,
    t_global: 19,
    z_global: 10200,
  },
  {
    profile_section_id: 7429,
    name: "CHS750x22",
    type: "Circular Hollow",
    designation: "CHS750x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "503,2",
    d_global: 750,
    i_global: 334000,
    t_global: 22,
    z_global: 11700,
  },
  {
    profile_section_id: 7430,
    name: "CHS750x25",
    type: "Circular Hollow",
    designation: "CHS750x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "569,4",
    d_global: 750,
    i_global: 375000,
    t_global: 25,
    z_global: 13100,
  },
  {
    profile_section_id: 7431,
    name: "CHS750x28",
    type: "Circular Hollow",
    designation: "CHS750x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "635,1",
    d_global: 750,
    i_global: 414000,
    t_global: 28,
    z_global: 14600,
  },
  {
    profile_section_id: 7432,
    name: "CHS750x32",
    type: "Circular Hollow",
    designation: "CHS750x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "721,8",
    d_global: 750,
    i_global: 466000,
    t_global: 32,
    z_global: 16500,
  },
  {
    profile_section_id: 7433,
    name: "CHS750x36",
    type: "Circular Hollow",
    designation: "CHS750x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "807,5",
    d_global: 750,
    i_global: 516000,
    t_global: 36,
    z_global: 18400,
  },
  {
    profile_section_id: 7434,
    name: "CHS750x40",
    type: "Circular Hollow",
    designation: "CHS750x40",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "892,2",
    d_global: 750,
    i_global: 564000,
    t_global: 40,
    z_global: 20200,
  },
  {
    profile_section_id: 7435,
    name: "CHS762x16",
    type: "Circular Hollow",
    designation: "CHS762x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 375,
    d_global: 762,
    i_global: 261000,
    t_global: 16,
    z_global: 8910,
  },
  {
    profile_section_id: 7436,
    name: "CHS762x22",
    type: "Circular Hollow",
    designation: "CHS762x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "511,5",
    d_global: 762,
    i_global: 350000,
    t_global: 22,
    z_global: 12100,
  },
  {
    profile_section_id: 7437,
    name: "CHS762x28",
    type: "Circular Hollow",
    designation: "CHS762x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "645,7",
    d_global: 762,
    i_global: 435000,
    t_global: 28,
    z_global: 15100,
  },
  {
    profile_section_id: 7438,
    name: "CHS800x16",
    type: "Circular Hollow",
    designation: "CHS800x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "394,1",
    d_global: 800,
    i_global: 303000,
    t_global: 16,
    z_global: 9840,
  },
  {
    profile_section_id: 7439,
    name: "CHS800x19",
    type: "Circular Hollow",
    designation: "CHS800x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "466,2",
    d_global: 800,
    i_global: 356000,
    t_global: 19,
    z_global: 11600,
  },
  {
    profile_section_id: 7440,
    name: "CHS800x22",
    type: "Circular Hollow",
    designation: "CHS800x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "537,7",
    d_global: 800,
    i_global: 407000,
    t_global: 22,
    z_global: 13300,
  },
  {
    profile_section_id: 7441,
    name: "CHS800x25",
    type: "Circular Hollow",
    designation: "CHS800x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "608,7",
    d_global: 800,
    i_global: 457000,
    t_global: 25,
    z_global: 15000,
  },
  {
    profile_section_id: 7442,
    name: "CHS800x28",
    type: "Circular Hollow",
    designation: "CHS800x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "679,1",
    d_global: 800,
    i_global: 507000,
    t_global: 28,
    z_global: 16700,
  },
  {
    profile_section_id: 7443,
    name: "CHS800x32",
    type: "Circular Hollow",
    designation: "CHS800x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "772,1",
    d_global: 800,
    i_global: 570000,
    t_global: 32,
    z_global: 18900,
  },
  {
    profile_section_id: 7444,
    name: "CHS800x36",
    type: "Circular Hollow",
    designation: "CHS800x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "864,1",
    d_global: 800,
    i_global: 632000,
    t_global: 36,
    z_global: 21000,
  },
  {
    profile_section_id: 7445,
    name: "CHS800x40",
    type: "Circular Hollow",
    designation: "CHS800x40",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 955,
    d_global: 800,
    i_global: 691000,
    t_global: 40,
    z_global: 23100,
  },
  {
    profile_section_id: 7446,
    name: "CHS812.8x12",
    type: "Circular Hollow",
    designation: "CHS812.8x12",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "301,9",
    d_global: "812,8",
    i_global: 242000,
    t_global: 12,
    z_global: 7700,
  },
  {
    profile_section_id: 7447,
    name: "CHS812.8x14",
    type: "Circular Hollow",
    designation: "CHS812.8x14",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "351,3",
    d_global: "812,8",
    i_global: 280000,
    t_global: 14,
    z_global: 8930,
  },
  {
    profile_section_id: 7448,
    name: "CHS812.8x16",
    type: "Circular Hollow",
    designation: "CHS812.8x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "400,5",
    d_global: "812,8",
    i_global: 318000,
    t_global: 16,
    z_global: 10200,
  },
  {
    profile_section_id: 7449,
    name: "CHS812.8x19",
    type: "Circular Hollow",
    designation: "CHS812.8x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "473,8",
    d_global: "812,8",
    i_global: 373000,
    t_global: 19,
    z_global: 12000,
  },
  {
    profile_section_id: 7450,
    name: "CHS812.8x22",
    type: "Circular Hollow",
    designation: "CHS812.8x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "546,6",
    d_global: "812,8",
    i_global: 428000,
    t_global: 22,
    z_global: 13800,
  },
  {
    profile_section_id: 7451,
    name: "CHS812.8x25",
    type: "Circular Hollow",
    designation: "CHS812.8x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "618,7",
    d_global: "812,8",
    i_global: 480000,
    t_global: 25,
    z_global: 15500,
  },
  {
    profile_section_id: 7452,
    name: "CHS850x22",
    type: "Circular Hollow",
    designation: "CHS850x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "572,3",
    d_global: 850,
    i_global: 491000,
    t_global: 22,
    z_global: 15100,
  },
  {
    profile_section_id: 7453,
    name: "CHS850x25",
    type: "Circular Hollow",
    designation: "CHS850x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 648,
    d_global: 850,
    i_global: 552000,
    t_global: 25,
    z_global: 17000,
  },
  {
    profile_section_id: 7454,
    name: "CHS850x28",
    type: "Circular Hollow",
    designation: "CHS850x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "723,1",
    d_global: 850,
    i_global: 611000,
    t_global: 28,
    z_global: 18900,
  },
  {
    profile_section_id: 7455,
    name: "CHS850x32",
    type: "Circular Hollow",
    designation: "CHS850x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "822,3",
    d_global: 850,
    i_global: 689000,
    t_global: 32,
    z_global: 21400,
  },
  {
    profile_section_id: 7456,
    name: "CHS850x36",
    type: "Circular Hollow",
    designation: "CHS850x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "896,1",
    d_global: 850,
    i_global: 745000,
    t_global: 36,
    z_global: 23900,
  },
  {
    profile_section_id: 7457,
    name: "CHS850x40",
    type: "Circular Hollow",
    designation: "CHS850x40",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1018,
    d_global: 850,
    i_global: 837000,
    t_global: 40,
    z_global: 26300,
  },
  {
    profile_section_id: 7458,
    name: "CHS900x19",
    type: "Circular Hollow",
    designation: "CHS900x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "525,9",
    d_global: 900,
    i_global: 510000,
    t_global: 19,
    z_global: 14700,
  },
  {
    profile_section_id: 7459,
    name: "CHS900x22",
    type: "Circular Hollow",
    designation: "CHS900x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "606,8",
    d_global: 900,
    i_global: 585000,
    t_global: 22,
    z_global: 17000,
  },
  {
    profile_section_id: 7460,
    name: "CHS900x25",
    type: "Circular Hollow",
    designation: "CHS900x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "687,2",
    d_global: 900,
    i_global: 658000,
    t_global: 25,
    z_global: 19100,
  },
  {
    profile_section_id: 7461,
    name: "CHS900x28",
    type: "Circular Hollow",
    designation: "CHS900x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "767,1",
    d_global: 900,
    i_global: 730000,
    t_global: 28,
    z_global: 21300,
  },
  {
    profile_section_id: 7462,
    name: "CHS900x32",
    type: "Circular Hollow",
    designation: "CHS900x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "872,6",
    d_global: 900,
    i_global: 823000,
    t_global: 32,
    z_global: 24100,
  },
  {
    profile_section_id: 7463,
    name: "CHS900x36",
    type: "Circular Hollow",
    designation: "CHS900x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "977,2",
    d_global: 900,
    i_global: 913000,
    t_global: 36,
    z_global: 26900,
  },
  {
    profile_section_id: 7464,
    name: "CHS900x40",
    type: "Circular Hollow",
    designation: "CHS900x40",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1081,
    d_global: 900,
    i_global: 1000000,
    t_global: 40,
    z_global: 29600,
  },
  {
    profile_section_id: 7465,
    name: "CHS914.4x14",
    type: "Circular Hollow",
    designation: "CHS914.4x14",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 396,
    d_global: "914,4",
    i_global: 401000,
    t_global: 14,
    z_global: 11400,
  },
  {
    profile_section_id: 7466,
    name: "CHS914.4x16",
    type: "Circular Hollow",
    designation: "CHS914.4x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "451,6",
    d_global: "914,4",
    i_global: 456000,
    t_global: 16,
    z_global: 12900,
  },
  {
    profile_section_id: 7467,
    name: "CHS914.4x19",
    type: "Circular Hollow",
    designation: "CHS914.4x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "534,5",
    d_global: "914,4",
    i_global: 536000,
    t_global: 19,
    z_global: 15200,
  },
  {
    profile_section_id: 7468,
    name: "CHS914.4x22",
    type: "Circular Hollow",
    designation: "CHS914.4x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "616,5",
    d_global: "914,4",
    i_global: 614000,
    t_global: 22,
    z_global: 17500,
  },
  {
    profile_section_id: 7469,
    name: "CHS914.4x25",
    type: "Circular Hollow",
    designation: "CHS914.4x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "698,5",
    d_global: "914,4",
    i_global: 691000,
    t_global: 25,
    z_global: 19800,
  },
  {
    profile_section_id: 7470,
    name: "CHS1000x28",
    type: "Circular Hollow",
    designation: "CHS1000x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 855,
    d_global: 1000,
    i_global: 1010000,
    t_global: 28,
    z_global: 26500,
  },
  {
    profile_section_id: 7471,
    name: "CHS1000x32",
    type: "Circular Hollow",
    designation: "CHS1000x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "973,1",
    d_global: 1000,
    i_global: 1140000,
    t_global: 32,
    z_global: 30000,
  },
  {
    profile_section_id: 7472,
    name: "CHS1000x36",
    type: "Circular Hollow",
    designation: "CHS1000x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1090,
    d_global: 1000,
    i_global: 1270000,
    t_global: 36,
    z_global: 33500,
  },
  {
    profile_section_id: 7473,
    name: "CHS1000x40",
    type: "Circular Hollow",
    designation: "CHS1000x40",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1206,
    d_global: 1000,
    i_global: 1390000,
    t_global: 40,
    z_global: 36900,
  },
  {
    profile_section_id: 7474,
    name: "CHS1016x16",
    type: "Circular Hollow",
    designation: "CHS1016x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "502,7",
    d_global: 1016,
    i_global: 628000,
    t_global: 16,
    z_global: 16000,
  },
  {
    profile_section_id: 7475,
    name: "CHS1016x19",
    type: "Circular Hollow",
    designation: "CHS1016x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "595,1",
    d_global: 1016,
    i_global: 740000,
    t_global: 19,
    z_global: 18900,
  },
  {
    profile_section_id: 7476,
    name: "CHS1016x22",
    type: "Circular Hollow",
    designation: "CHS1016x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 687,
    d_global: 1016,
    i_global: 849000,
    t_global: 22,
    z_global: 21700,
  },
  {
    profile_section_id: 7477,
    name: "CHS1016x25",
    type: "Circular Hollow",
    designation: "CHS1016x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "778,3",
    d_global: 1016,
    i_global: 956000,
    t_global: 25,
    z_global: 24600,
  },
  {
    profile_section_id: 7478,
    name: "CHS1016x28",
    type: "Circular Hollow",
    designation: "CHS1016x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "869,1",
    d_global: 1016,
    i_global: 1060000,
    t_global: 28,
    z_global: 27300,
  },
  {
    profile_section_id: 7479,
    name: "CHS1066.8x16",
    type: "Circular Hollow",
    designation: "CHS1066.8x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "528,2",
    d_global: "1066,8",
    i_global: 729000,
    t_global: 16,
    z_global: 17700,
  },
  {
    profile_section_id: 7480,
    name: "CHS1066.8x19",
    type: "Circular Hollow",
    designation: "CHS1066.8x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "625,4",
    d_global: "1066,8",
    i_global: 859000,
    t_global: 19,
    z_global: 20900,
  },
  {
    profile_section_id: 7481,
    name: "CHS1066.8x22",
    type: "Circular Hollow",
    designation: "CHS1066.8x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "722,1",
    d_global: "1066,8",
    i_global: 986000,
    t_global: 22,
    z_global: 24000,
  },
  {
    profile_section_id: 7482,
    name: "CHS1066.8x25",
    type: "Circular Hollow",
    designation: "CHS1066.8x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "818,2",
    d_global: "1066,8",
    i_global: 1110000,
    t_global: 25,
    z_global: 27100,
  },
  {
    profile_section_id: 7483,
    name: "CHS1066.8x28",
    type: "Circular Hollow",
    designation: "CHS1066.8x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "913,8",
    d_global: "1066,8",
    i_global: 1230000,
    t_global: 28,
    z_global: 30200,
  },
  {
    profile_section_id: 7484,
    name: "CHS1117.6x16",
    type: "Circular Hollow",
    designation: "CHS1117.6x16",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "553,7",
    d_global: "1117,6",
    i_global: 840000,
    t_global: 16,
    z_global: 19400,
  },
  {
    profile_section_id: 7485,
    name: "CHS1117.6x19",
    type: "Circular Hollow",
    designation: "CHS1117.6x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "655,8",
    d_global: "1117,6",
    i_global: 990000,
    t_global: 19,
    z_global: 22900,
  },
  {
    profile_section_id: 7486,
    name: "CHS1117.6x22",
    type: "Circular Hollow",
    designation: "CHS1117.6x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "757,2",
    d_global: "1117,6",
    i_global: 1140000,
    t_global: 22,
    z_global: 26400,
  },
  {
    profile_section_id: 7487,
    name: "CHS1117.6x25",
    type: "Circular Hollow",
    designation: "CHS1117.6x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "858,1",
    d_global: "1117,6",
    i_global: 1280000,
    t_global: 25,
    z_global: 29800,
  },
  {
    profile_section_id: 7488,
    name: "CHS1117.6x28",
    type: "Circular Hollow",
    designation: "CHS1117.6x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "958,5",
    d_global: "1117,6",
    i_global: 1420000,
    t_global: 28,
    z_global: 33200,
  },
  {
    profile_section_id: 7489,
    name: "CHS1168.4x19",
    type: "Circular Hollow",
    designation: "CHS1168.4x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "686,1",
    d_global: "1168,4",
    i_global: 1130000,
    t_global: 19,
    z_global: 25100,
  },
  {
    profile_section_id: 7490,
    name: "CHS1168.4x22",
    type: "Circular Hollow",
    designation: "CHS1168.4x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "792,3",
    d_global: "1168,4",
    i_global: 1300000,
    t_global: 22,
    z_global: 28900,
  },
  {
    profile_section_id: 7491,
    name: "CHS1168.4x25",
    type: "Circular Hollow",
    designation: "CHS1168.4x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 898,
    d_global: "1168,4",
    i_global: 1470000,
    t_global: 25,
    z_global: 32700,
  },
  {
    profile_section_id: 7492,
    name: "CHS1168.4x28",
    type: "Circular Hollow",
    designation: "CHS1168.4x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1003,
    d_global: "1168,4",
    i_global: 1630000,
    t_global: 28,
    z_global: 36400,
  },
  {
    profile_section_id: 7493,
    name: "CHS1168.4x30",
    type: "Circular Hollow",
    designation: "CHS1168.4x30",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1073,
    d_global: "1168,4",
    i_global: 1740000,
    t_global: 30,
    z_global: 38900,
  },
  {
    profile_section_id: 7494,
    name: "CHS1168.4x32",
    type: "Circular Hollow",
    designation: "CHS1168.4x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1142,
    d_global: "1168,4",
    i_global: 1850000,
    t_global: 32,
    z_global: 41300,
  },
  {
    profile_section_id: 7495,
    name: "CHS1219.2x19",
    type: "Circular Hollow",
    designation: "CHS1219.2x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "716,4",
    d_global: "1219,2",
    i_global: 1290000,
    t_global: 19,
    z_global: 27400,
  },
  {
    profile_section_id: 7496,
    name: "CHS1219.2x22",
    type: "Circular Hollow",
    designation: "CHS1219.2x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "827,4",
    d_global: "1219,2",
    i_global: 1480000,
    t_global: 22,
    z_global: 31500,
  },
  {
    profile_section_id: 7497,
    name: "CHS1219.2x25",
    type: "Circular Hollow",
    designation: "CHS1219.2x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "937,9",
    d_global: "1219,2",
    i_global: 1670000,
    t_global: 25,
    z_global: 35700,
  },
  {
    profile_section_id: 7498,
    name: "CHS1219.2x28",
    type: "Circular Hollow",
    designation: "CHS1219.2x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1048,
    d_global: "1219,2",
    i_global: 1860000,
    t_global: 28,
    z_global: 39700,
  },
  {
    profile_section_id: 7499,
    name: "CHS1219.2x30",
    type: "Circular Hollow",
    designation: "CHS1219.2x30",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1121,
    d_global: "1219,2",
    i_global: 1980000,
    t_global: 30,
    z_global: 42400,
  },
  {
    profile_section_id: 7500,
    name: "CHS1219.2x32",
    type: "Circular Hollow",
    designation: "CHS1219.2x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1194,
    d_global: "1219,2",
    i_global: 2100000,
    t_global: 32,
    z_global: 45100,
  },
  {
    profile_section_id: 7501,
    name: "CHS1270x19",
    type: "Circular Hollow",
    designation: "CHS1270x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "746,7",
    d_global: 1270,
    i_global: 1460000,
    t_global: 19,
    z_global: 29700,
  },
  {
    profile_section_id: 7502,
    name: "CHS1270x22",
    type: "Circular Hollow",
    designation: "CHS1270x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "862,6",
    d_global: 1270,
    i_global: 1680000,
    t_global: 22,
    z_global: 34300,
  },
  {
    profile_section_id: 7503,
    name: "CHS1270x25",
    type: "Circular Hollow",
    designation: "CHS1270x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "977,8",
    d_global: 1270,
    i_global: 1900000,
    t_global: 25,
    z_global: 38800,
  },
  {
    profile_section_id: 7504,
    name: "CHS1270x28",
    type: "Circular Hollow",
    designation: "CHS1270x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1093,
    d_global: 1270,
    i_global: 2110000,
    t_global: 28,
    z_global: 43200,
  },
  {
    profile_section_id: 7505,
    name: "CHS1270x30",
    type: "Circular Hollow",
    designation: "CHS1270x30",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1169,
    d_global: 1270,
    i_global: 2250000,
    t_global: 30,
    z_global: 46100,
  },
  {
    profile_section_id: 7506,
    name: "CHS1270x32",
    type: "Circular Hollow",
    designation: "CHS1270x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1245,
    d_global: 1270,
    i_global: 2390000,
    t_global: 32,
    z_global: 49100,
  },
  {
    profile_section_id: 7507,
    name: "CHS1320.8x19",
    type: "Circular Hollow",
    designation: "CHS1320.8x19",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 777,
    d_global: "1320,8",
    i_global: 1650000,
    t_global: 19,
    z_global: 32200,
  },
  {
    profile_section_id: 7508,
    name: "CHS1320.8x22",
    type: "Circular Hollow",
    designation: "CHS1320.8x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "897,7",
    d_global: "1320,8",
    i_global: 1890000,
    t_global: 22,
    z_global: 37100,
  },
  {
    profile_section_id: 7509,
    name: "CHS1320.8x25",
    type: "Circular Hollow",
    designation: "CHS1320.8x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1018,
    d_global: "1320,8",
    i_global: 2140000,
    t_global: 25,
    z_global: 42000,
  },
  {
    profile_section_id: 7510,
    name: "CHS1320.8x28",
    type: "Circular Hollow",
    designation: "CHS1320.8x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1137,
    d_global: "1320,8",
    i_global: 2380000,
    t_global: 28,
    z_global: 46800,
  },
  {
    profile_section_id: 7511,
    name: "CHS1320.8x30",
    type: "Circular Hollow",
    designation: "CHS1320.8x30",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1217,
    d_global: "1320,8",
    i_global: 2540000,
    t_global: 30,
    z_global: 50000,
  },
  {
    profile_section_id: 7512,
    name: "CHS1320.8x32",
    type: "Circular Hollow",
    designation: "CHS1320.8x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1296,
    d_global: "1320,8",
    i_global: 2690000,
    t_global: 32,
    z_global: 53200,
  },
  {
    profile_section_id: 7513,
    name: "CHS1371.6x22",
    type: "Circular Hollow",
    designation: "CHS1371.6x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "932,8",
    d_global: "1371,6",
    i_global: 2120000,
    t_global: 22,
    z_global: 40100,
  },
  {
    profile_section_id: 7514,
    name: "CHS1371.6x25",
    type: "Circular Hollow",
    designation: "CHS1371.6x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1058,
    d_global: "1371,6",
    i_global: 2400000,
    t_global: 25,
    z_global: 45300,
  },
  {
    profile_section_id: 7515,
    name: "CHS1371.6x28",
    type: "Circular Hollow",
    designation: "CHS1371.6x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1182,
    d_global: "1371,6",
    i_global: 2670000,
    t_global: 28,
    z_global: 50600,
  },
  {
    profile_section_id: 7516,
    name: "CHS1371.6x30",
    type: "Circular Hollow",
    designation: "CHS1371.6x30",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1264,
    d_global: "1371,6",
    i_global: 2850000,
    t_global: 30,
    z_global: 54000,
  },
  {
    profile_section_id: 7517,
    name: "CHS1371.6x32",
    type: "Circular Hollow",
    designation: "CHS1371.6x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1347,
    d_global: "1371,6",
    i_global: 3020000,
    t_global: 32,
    z_global: 57400,
  },
  {
    profile_section_id: 7518,
    name: "CHS1371.6x36",
    type: "Circular Hollow",
    designation: "CHS1371.6x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1511,
    d_global: "1371,6",
    i_global: 3370000,
    t_global: 36,
    z_global: 64200,
  },
  {
    profile_section_id: 7519,
    name: "CHS1422.4x22",
    type: "Circular Hollow",
    designation: "CHS1422.4x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: "967,9",
    d_global: "1422,4",
    i_global: 2370000,
    t_global: 22,
    z_global: 43100,
  },
  {
    profile_section_id: 7520,
    name: "CHS1422.4x25",
    type: "Circular Hollow",
    designation: "CHS1422.4x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1098,
    d_global: "1422,4",
    i_global: 2680000,
    t_global: 25,
    z_global: 48800,
  },
  {
    profile_section_id: 7521,
    name: "CHS1422.4x28",
    type: "Circular Hollow",
    designation: "CHS1422.4x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1227,
    d_global: "1422,4",
    i_global: 2980000,
    t_global: 28,
    z_global: 54400,
  },
  {
    profile_section_id: 7522,
    name: "CHS1422.4x30",
    type: "Circular Hollow",
    designation: "CHS1422.4x30",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1312,
    d_global: "1422,4",
    i_global: 3180000,
    t_global: 30,
    z_global: 58200,
  },
  {
    profile_section_id: 7523,
    name: "CHS1422.4x32",
    type: "Circular Hollow",
    designation: "CHS1422.4x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1398,
    d_global: "1422,4",
    i_global: 3380000,
    t_global: 32,
    z_global: 61900,
  },
  {
    profile_section_id: 7524,
    name: "CHS1422.4x36",
    type: "Circular Hollow",
    designation: "CHS1422.4x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1568,
    d_global: "1422,4",
    i_global: 3770000,
    t_global: 36,
    z_global: 69200,
  },
  {
    profile_section_id: 7525,
    name: "CHS1422.4x40",
    type: "Circular Hollow",
    designation: "CHS1422.4x40",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1737,
    d_global: "1422,4",
    i_global: 4150000,
    t_global: 40,
    z_global: 76500,
  },
  {
    profile_section_id: 7526,
    name: "CHS1524x22",
    type: "Circular Hollow",
    designation: "CHS1524x22",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1038,
    d_global: 1524,
    i_global: 2930000,
    t_global: 22,
    z_global: 49600,
  },
  {
    profile_section_id: 7527,
    name: "CHS1524x25",
    type: "Circular Hollow",
    designation: "CHS1524x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1177,
    d_global: 1524,
    i_global: 3310000,
    t_global: 25,
    z_global: 56200,
  },
  {
    profile_section_id: 7528,
    name: "CHS1524x28",
    type: "Circular Hollow",
    designation: "CHS1524x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1316,
    d_global: 1524,
    i_global: 3680000,
    t_global: 28,
    z_global: 62700,
  },
  {
    profile_section_id: 7529,
    name: "CHS1524x30",
    type: "Circular Hollow",
    designation: "CHS1524x30",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1408,
    d_global: 1524,
    i_global: 3930000,
    t_global: 30,
    z_global: 67000,
  },
  {
    profile_section_id: 7530,
    name: "CHS1524x32",
    type: "Circular Hollow",
    designation: "CHS1524x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1500,
    d_global: 1524,
    i_global: 4180000,
    t_global: 32,
    z_global: 71200,
  },
  {
    profile_section_id: 7531,
    name: "CHS1524x36",
    type: "Circular Hollow",
    designation: "CHS1524x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1683,
    d_global: 1524,
    i_global: 4660000,
    t_global: 36,
    z_global: 79700,
  },
  {
    profile_section_id: 7532,
    name: "CHS1574.8x25",
    type: "Circular Hollow",
    designation: "CHS1574.8x25",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1217,
    d_global: "1574,8",
    i_global: 3660000,
    t_global: 25,
    z_global: 60100,
  },
  {
    profile_section_id: 7533,
    name: "CHS1574.8x28",
    type: "Circular Hollow",
    designation: "CHS1574.8x28",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1361,
    d_global: "1574,8",
    i_global: 4070000,
    t_global: 28,
    z_global: 67000,
  },
  {
    profile_section_id: 7534,
    name: "CHS1574.8x30",
    type: "Circular Hollow",
    designation: "CHS1574.8x30",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1456,
    d_global: "1574,8",
    i_global: 4340000,
    t_global: 30,
    z_global: 71600,
  },
  {
    profile_section_id: 7535,
    name: "CHS1574.8x32",
    type: "Circular Hollow",
    designation: "CHS1574.8x32",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1551,
    d_global: "1574,8",
    i_global: 4620000,
    t_global: 32,
    z_global: 76200,
  },
  {
    profile_section_id: 7536,
    name: "CHS1574.8x36",
    type: "Circular Hollow",
    designation: "CHS1574.8x36",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1740,
    d_global: "1574,8",
    i_global: 5150000,
    t_global: 36,
    z_global: 85300,
  },
  {
    profile_section_id: 7537,
    name: "CHS1574.8x40",
    type: "Circular Hollow",
    designation: "CHS1574.8x40",
    shape: "Circular Hollow",
    country_code: "Japanese",
    ax_global: 1929,
    d_global: "1574,8",
    i_global: 5680000,
    t_global: 40,
    z_global: 94200,
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: null,
    bf: null,
    c: null,
    ct: null,
    d: null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: null,
    i: null,
    i_global: p.i_global ? fixValueToNumber(p.i_global, "float") : undefined,
    ix: null,
    iy: null,
    iz: null,
    k: null,
    k1: null,
    od: null,
    r1: null,
    r2: null,
    rz: null,
    t: null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: null,
    tw: null,
    z: null,
    z_global: p.z_global ? fixValueToNumber(p.z_global, "float") : undefined,
    zx: null,
    zy: null,
    zz: null,
    width: 0,
    width_global: 0,
    thickness: 0,
    thickness_global: 0,
    height: 0,
    height_global: 0,

  };
  return profile;
});
