import { fixValueToNumber } from "../../../components/3d-models/utils";

export const pipingLongWeldingNeckFlanges = [
  {
    piping_flange_id: 3,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 108,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "79,4",
    n: "",
    a: 51,
    b: "25,4",
    c_mini: "14,3",
    r: "50,8",
  },
  {
    piping_flange_id: 4,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 117,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "88,9",
    n: "",
    a: "60,5",
    b: "31,8",
    c_mini: "15,7",
    r: "63,5",
  },
  {
    piping_flange_id: 5,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 127,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "98,4",
    n: "",
    a: 67,
    b: "38,1",
    c_mini: "17,5",
    r: 73,
  },
  {
    piping_flange_id: 6,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 152,
    dr_no: 4,
    dr_d: 19,
    dr_g: "120,6",
    n: "",
    a: "82,5",
    b: "50,8",
    c_mini: "19,1",
    r: "92,1",
  },
  {
    piping_flange_id: 7,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 178,
    dr_no: 4,
    dr_d: 19,
    dr_g: "139,7",
    n: "",
    a: "95,5",
    b: "63,5",
    c_mini: "22,3",
    r: "104,8",
  },
  {
    piping_flange_id: 8,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 190,
    dr_no: 4,
    dr_d: 19,
    dr_g: "152,4",
    n: "",
    a: 108,
    b: "76,2",
    c_mini: "23,9",
    r: 127,
  },
  {
    piping_flange_id: 9,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 216,
    dr_no: 8,
    dr_d: 19,
    dr_g: "177,8",
    n: "",
    a: 124,
    b: "88,9",
    c_mini: "23,9",
    r: "139,7",
  },
  {
    piping_flange_id: 10,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 229,
    dr_no: 8,
    dr_d: 19,
    dr_g: "190,5",
    n: "",
    a: 140,
    b: "101,6",
    c_mini: "23,9",
    r: "157,2",
  },
  {
    piping_flange_id: 11,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 254,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "215,9",
    n: "",
    a: 165,
    b: 127,
    c_mini: "23,9",
    r: "185,7",
  },
  {
    piping_flange_id: 12,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 279,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "241,3",
    n: "",
    a: 197,
    b: "152,4",
    c_mini: "25,4",
    r: "215,9",
  },
  {
    piping_flange_id: 13,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 343,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "298,4",
    n: "",
    a: 248,
    b: "203,2",
    c_mini: "28,5",
    r: "269,9",
  },
  {
    piping_flange_id: 14,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 406,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: 362,
    n: "",
    a: 305,
    b: 254,
    c_mini: "30,2",
    r: "323,8",
  },
  {
    piping_flange_id: 15,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 483,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: "431,8",
    n: "",
    a: 365,
    b: "304,8",
    c_mini: "31,8",
    r: 381,
  },
  {
    piping_flange_id: 16,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 533,
    dr_no: 12,
    dr_d: "28,5",
    dr_g: "476,2",
    n: "",
    a: "406,5",
    b: "355,6",
    c_mini: 35,
    r: "412,8",
  },
  {
    piping_flange_id: 17,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 597,
    dr_no: 16,
    dr_d: "28,5",
    dr_g: "539,8",
    n: "",
    a: 457,
    b: "406,4",
    c_mini: "36,6",
    r: "469,9",
  },
  {
    piping_flange_id: 18,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 635,
    dr_no: 16,
    dr_d: "31,8",
    dr_g: "577,8",
    n: "",
    a: 508,
    b: "457,2",
    c_mini: "39,7",
    r: "533,4",
  },
  {
    piping_flange_id: 19,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 698,
    dr_no: 20,
    dr_d: "31,8",
    dr_g: 635,
    n: "",
    a: 559,
    b: 508,
    c_mini: "42,9",
    r: "584,2",
  },
  {
    piping_flange_id: 20,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 150,
    o: 813,
    dr_no: 20,
    dr_d: 35,
    dr_g: "749,3",
    n: "",
    a: 667,
    b: "609,6",
    c_mini: "47,7",
    r: "692,2",
  },
  {
    piping_flange_id: 23,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    n: "",
    a: 54,
    b: "25,4",
    c_mini: "17,5",
    r: "50,8",
  },
  {
    piping_flange_id: 24,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 133,
    dr_no: 4,
    dr_d: 19,
    dr_g: "98,4",
    n: "",
    a: "63,5",
    b: "31,8",
    c_mini: 19,
    r: "63,5",
  },
  {
    piping_flange_id: 25,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    n: "",
    a: 70,
    b: "38,1",
    c_mini: 206,
    r: 73,
  },
  {
    piping_flange_id: 26,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    n: "",
    a: 84,
    b: "50,8",
    c_mini: "22,4",
    r: "92,1",
  },
  {
    piping_flange_id: 27,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    n: "",
    a: 100,
    b: "63,5",
    c_mini: "25,4",
    r: "104,8",
  },
  {
    piping_flange_id: 28,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    n: "",
    a: 117,
    b: "76,2",
    c_mini: "28,4",
    r: 127,
  },
  {
    piping_flange_id: 29,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 229,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "184,1",
    n: "",
    a: 133,
    b: "88,9",
    c_mini: "30,2",
    r: "139,7",
  },
  {
    piping_flange_id: 30,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 254,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: 200,
    n: "",
    a: 146,
    b: "101,6",
    c_mini: "31,8",
    r: "157,2",
  },
  {
    piping_flange_id: 31,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 279,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: 235,
    n: "",
    a: 184,
    b: 127,
    c_mini: 35,
    r: "185,7",
  },
  {
    piping_flange_id: 32,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 318,
    dr_no: 12,
    dr_d: "22,2",
    dr_g: "269,9",
    n: "",
    a: 206,
    b: "152,4",
    c_mini: "36,6",
    r: "215,9",
  },
  {
    piping_flange_id: 33,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 381,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: "330,2",
    n: "",
    a: 260,
    b: "203,2",
    c_mini: "41,1",
    r: "269,9",
  },
  {
    piping_flange_id: 34,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 444,
    dr_no: 16,
    dr_d: "28,5",
    dr_g: "387,4",
    n: "",
    a: 321,
    b: 254,
    c_mini: "47,8",
    r: "323,8",
  },
  {
    piping_flange_id: 35,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 521,
    dr_no: 16,
    dr_d: "31,8",
    dr_g: "450,8",
    n: "",
    a: 375,
    b: "304,8",
    c_mini: "50,8",
    r: 381,
  },
  {
    piping_flange_id: 36,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 584,
    dr_no: 20,
    dr_d: "31,8",
    dr_g: "514,4",
    n: "",
    a: 425,
    b: "355,6",
    c_mini: "53,8",
    r: "412,8",
  },
  {
    piping_flange_id: 37,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 648,
    dr_no: 20,
    dr_d: 35,
    dr_g: "571,5",
    n: "",
    a: 483,
    b: "406,4",
    c_mini: "57,2",
    r: "469,9",
  },
  {
    piping_flange_id: 38,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 711,
    dr_no: 24,
    dr_d: 35,
    dr_g: "628,6",
    n: "",
    a: 533,
    b: "457,2",
    c_mini: "60,5",
    r: "533,4",
  },
  {
    piping_flange_id: 39,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 775,
    dr_no: 24,
    dr_d: 35,
    dr_g: "685,8",
    n: "",
    a: 587,
    b: 508,
    c_mini: "63,5",
    r: "584,2",
  },
  {
    piping_flange_id: 40,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 300,
    o: 914,
    dr_no: 24,
    dr_d: "41,1",
    dr_g: "812,8",
    n: "",
    a: 702,
    b: "609,6",
    c_mini: "69,9",
    r: "692,2",
  },
  {
    piping_flange_id: 43,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    n: "",
    a: 54,
    b: "25,4",
    c_mini: "17,5",
    r: "50,8",
  },
  {
    piping_flange_id: 44,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 133,
    dr_no: 4,
    dr_d: 19,
    dr_g: "98,4",
    n: "",
    a: "63,5",
    b: "31,8",
    c_mini: "20,6",
    r: "63,5",
  },
  {
    piping_flange_id: 45,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    n: "",
    a: 70,
    b: 38,
    c_mini: "22,4",
    r: 73,
  },
  {
    piping_flange_id: 46,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    n: "",
    a: 84,
    b: "50,8",
    c_mini: "25,4",
    r: "92,1",
  },
  {
    piping_flange_id: 47,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    n: "",
    a: 100,
    b: "63,5",
    c_mini: "28,4",
    r: "104,8",
  },
  {
    piping_flange_id: 48,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    n: "",
    a: 117,
    b: "76,2",
    c_mini: "31,8",
    r: 127,
  },
  {
    piping_flange_id: 49,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 229,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "184,1",
    n: "",
    a: 133,
    b: "88,9",
    c_mini: 35,
    r: "139,7",
  },
  {
    piping_flange_id: 50,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 273,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "215,9",
    n: "",
    a: 152,
    b: "101,6",
    c_mini: "38,1",
    r: "157,2",
  },
  {
    piping_flange_id: 51,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 330,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "266,7",
    n: "",
    a: 190,
    b: 127,
    c_mini: "44,5",
    r: "185,7",
  },
  {
    piping_flange_id: 52,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 356,
    dr_no: 12,
    dr_d: "28,5",
    dr_g: "292,1",
    n: "",
    a: 222,
    b: "152,4",
    c_mini: "47,8",
    r: "215,9",
  },
  {
    piping_flange_id: 53,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 419,
    dr_no: 12,
    dr_d: "31,8",
    dr_g: "349,2",
    n: "",
    a: 273,
    b: "203,2",
    c_mini: "55,6",
    r: "269,9",
  },
  {
    piping_flange_id: 54,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 508,
    dr_no: 16,
    dr_d: 35,
    dr_g: "431,8",
    n: "",
    a: 343,
    b: 254,
    c_mini: "63,5",
    r: "323,8",
  },
  {
    piping_flange_id: 55,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 559,
    dr_no: 20,
    dr_d: 35,
    dr_g: 489,
    n: "",
    a: 400,
    b: "304,8",
    c_mini: "66,5",
    r: 381,
  },
  {
    piping_flange_id: 56,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 603,
    dr_no: 20,
    dr_d: "38,1",
    dr_g: 527,
    n: "",
    a: 432,
    b: "355,6",
    c_mini: "69,9",
    r: "412,8",
  },
  {
    piping_flange_id: 57,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 686,
    dr_no: 20,
    dr_d: "41,1",
    dr_g: "603,2",
    n: "",
    a: 495,
    b: "406,4",
    c_mini: "76,2",
    r: "469,9",
  },
  {
    piping_flange_id: 58,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 743,
    dr_no: 20,
    dr_d: "44,5",
    dr_g: 654,
    n: "",
    a: 546,
    b: "457,2",
    c_mini: "82,6",
    r: "533,4",
  },
  {
    piping_flange_id: 59,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 813,
    dr_no: 24,
    dr_d: "44,5",
    dr_g: "723,9",
    n: "",
    a: 610,
    b: 508,
    c_mini: "88,9",
    r: "584,2",
  },
  {
    piping_flange_id: 60,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 600,
    o: 940,
    dr_no: 24,
    dr_d: "50,8",
    dr_g: "838,2",
    n: "",
    a: 718,
    b: "609,6",
    c_mini: "101,6",
    r: "692,2",
  },
  {
    piping_flange_id: 63,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 149,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: "101,6",
    n: "",
    a: 54,
    b: "25,4",
    c_mini: "28,4",
    r: "50,8",
  },
  {
    piping_flange_id: 64,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 159,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: "111,1",
    n: "",
    a: "63,5",
    b: "31,8",
    c_mini: "28,4",
    r: "63,5",
  },
  {
    piping_flange_id: 65,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 178,
    dr_no: 4,
    dr_d: "28,5",
    dr_g: "123,8",
    n: "",
    a: 70,
    b: 38,
    c_mini: "31,8",
    r: 73,
  },
  {
    piping_flange_id: 66,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 216,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "165,1",
    n: "",
    a: 100,
    b: "50,8",
    c_mini: "38,1",
    r: "92,1",
  },
  {
    piping_flange_id: 67,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 244,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "190,5",
    n: "",
    a: 124,
    b: "63,5",
    c_mini: "41,1",
    r: "104,8",
  },
  {
    piping_flange_id: 68,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 241,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "190,5",
    n: "",
    a: 127,
    b: "76,2",
    c_mini: "38,1",
    r: 127,
  },
  {
    piping_flange_id: 69,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 292,
    dr_no: 8,
    dr_d: "31,8",
    dr_g: 235,
    n: "",
    a: "158,5",
    b: "101,6",
    c_mini: "44,5",
    r: "157,2",
  },
  {
    piping_flange_id: 70,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 349,
    dr_no: 8,
    dr_d: 35,
    dr_g: "279,4",
    n: "",
    a: 190,
    b: 127,
    c_mini: "50,8",
    r: "185,7",
  },
  {
    piping_flange_id: 71,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 381,
    dr_no: 12,
    dr_d: "31,8",
    dr_g: "317,5",
    n: "",
    a: 235,
    b: "152,4",
    c_mini: "55,6",
    r: "215,9",
  },
  {
    piping_flange_id: 72,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 470,
    dr_no: 12,
    dr_d: "38,1",
    dr_g: "393,7",
    n: "",
    a: "298,5",
    b: "203,2",
    c_mini: "63,5",
    r: "269,9",
  },
  {
    piping_flange_id: 73,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 546,
    dr_no: 16,
    dr_d: "38,1",
    dr_g: "469,9",
    n: "",
    a: "368,5",
    b: 254,
    c_mini: "69,9",
    r: "323,8",
  },
  {
    piping_flange_id: 74,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 610,
    dr_no: 20,
    dr_d: "38,1",
    dr_g: "533,4",
    n: "",
    a: 419,
    b: "304,8",
    c_mini: "79,2",
    r: 381,
  },
  {
    piping_flange_id: 75,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 641,
    dr_no: 20,
    dr_d: "41,1",
    dr_g: "558,8",
    n: "",
    a: 451,
    b: "355,6",
    c_mini: "85,9",
    r: "412,8",
  },
  {
    piping_flange_id: 76,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 705,
    dr_no: 20,
    dr_d: "44,5",
    dr_g: 616,
    n: "",
    a: 508,
    b: "406,4",
    c_mini: "88,9",
    r: "469,9",
  },
  {
    piping_flange_id: 77,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 787,
    dr_no: 20,
    dr_d: "50,8",
    dr_g: "685,8",
    n: "",
    a: 565,
    b: "457,2",
    c_mini: "101,6",
    r: "533,4",
  },
  {
    piping_flange_id: 78,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 857,
    dr_no: 20,
    dr_d: "53,8",
    dr_g: "749,3",
    n: "",
    a: "622,5",
    b: 508,
    c_mini: 108,
    r: "584,2",
  },
  {
    piping_flange_id: 79,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5 - 1996",
    shape: "FLANGE - LONG WELDING NECK",
    class: 900,
    o: 1041,
    dr_no: 20,
    dr_d: "66,5",
    dr_g: "901,7",
    n: "",
    a: "749,5",
    b: "609,6",
    c_mini: "139,7",
    r: "692,2",
  },
].map((item) => ({
  ...item,
  nps: item.nps + "",
  o: fixValueToNumber(item.o, "float"),
  dr_no: fixValueToNumber(item.dr_no, "float"),
  dr_d: fixValueToNumber(item.dr_d, "float"),
  dr_g: fixValueToNumber(item.dr_g, "float"),
  n: fixValueToNumber(item.n, "float"),
  a: fixValueToNumber(item.a, "float"),
  b: fixValueToNumber(item.b, "float"),
  c_mini: fixValueToNumber(item.c_mini, "float"),
  r: fixValueToNumber(item.r, "float"),
}));
