import React, { useEffect, useState } from "react";
import { Button, FormGroup, InputGroup, Icon, Spinner } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import Logo from "../../assets/A-Logo-Blue-Transparent.png";
import "./Login.css"; // Ensure this CSS file includes the styles you provided
import { secondServerAPI } from "../utils/agent";

type Props = {
  onUnload: () => any;
  onSubmit: (firstName: string, lastName: string, email: string, password: string, userCompany: string, callback: (success: boolean, message: string) => void) => any;
};

export function Register({ onUnload, onSubmit }: Props) {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [userCompany, setUserCompany] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [isEmailChecking, setIsEmailChecking] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);

  useEffect(() => {
    return () => onUnload();
  }, []);

  async function checkEmailExists(email: string) {
    setIsEmailChecking(true);
    try {
      const response = await fetch(`${secondServerAPI}rest/api/v1/ufc/checkUserAlreadyExists`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'email': email,
        },
      });

      if (response.status === 200) {
        setIsEmailValid(true);
        setMessage("User doesn't exist.");
      } else if (response.status === 400) {
        setIsEmailValid(false);
        setMessage("User already exists.");
      } else {
        setIsEmailValid(null);
        setMessage("An error occurred while checking the email.");
      }
    } catch (error) {
      setIsEmailValid(null);
      setMessage("An error occurred while checking the email.");
    } finally {
      setIsEmailChecking(false);
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (isEmailValid === false) {
      setMessage("Cannot register with an existing email.");
      setIsSuccess(false);
      return;
    }
    onSubmit(firstName, lastName, email, password, userCompany, (success, message) => {
      setIsSuccess(success);
      setMessage(message);
    });
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setter(event.target.value);
  };

  const handleEmailBlur = () => {
    if (email) {
      checkEmailExists(email);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isFormValid = () => {
    return (
      firstName !== "" &&
      lastName !== "" &&
      email !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      password === confirmPassword &&
      userCompany !== "" &&
      isEmailValid
    );
  };

  return (
    <div className="auth-page">
      <div className="primary-container">
        <div className="container-1">
          <h1>Welcome...</h1>
          <h2><i>Convert, Manage, and Optimize !</i></h2>
        </div>
        <div className="container-2">
          <div className="logo-container-2">
            <img className="primary-logo" src={Logo} alt="Logo" />
            <h1>
              Integrated
              <br />
              Design
              <br />
              Suite
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="login-text-group">first name</div>
            <FormGroup>
              <InputGroup
                large
                type="text"
                value={firstName}
                className="login-input-group"
                autoFocus={true}
                onChange={(e) => handleInputChange(e, setFirstName)}
              />
            </FormGroup>
            <div className="login-text-group">last name</div>
            <FormGroup>
              <InputGroup
                large
                type="text"
                value={lastName}
                className="login-input-group"
                onChange={(e) => handleInputChange(e, setLastName)}
              />
            </FormGroup>
            <div className="login-text-group">email</div>
            <FormGroup>
              <InputGroup
                large
                type="email"
                value={email}
                className="login-input-group"
                onChange={(e) => handleInputChange(e, setEmail)}
                onBlur={handleEmailBlur}
              />
              {isEmailChecking && <Spinner size={20} />}
            </FormGroup>
            <div className="login-text-group">password</div>
            <FormGroup style={{ position: "relative" }}>
              <InputGroup
                large
                type={showPassword ? "text" : "password"}
                className="login-input-group"
                value={password}
                onChange={(e) => handleInputChange(e, setPassword)}
              />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  borderRadius: "20px",
                  cursor: "pointer",
                  transform: "translateY(-50%)",
                }}
                onClick={togglePasswordVisibility}
              >
                <Icon icon={showPassword ? IconNames.EYE_OFF : IconNames.EYE_OPEN} />
              </span>
            </FormGroup>
            <div className="login-text-group">confirm password</div>
            <FormGroup style={{ position: "relative" }}>
              <InputGroup
                large
                type={showPassword ? "text" : "password"}
                className="login-input-group"
                value={confirmPassword}
                onChange={(e) => handleInputChange(e, setConfirmPassword)}
              />
            </FormGroup>
            <div className="login-text-group">Company</div>
            <FormGroup>
              <InputGroup
                large
                type="text"
                value={userCompany}
                className="login-input-group"
                onChange={(e) => handleInputChange(e, setUserCompany)}
              />
            </FormGroup>
            <FormGroup className="t-center">
              <Button large type="submit" className="form-group-button" disabled={!isFormValid()}>
                Register
              </Button>
            </FormGroup>
          </form>
          {isSuccess !== null && (
            <div className={`message ${isSuccess ? "success" : "error"}`}>
              {message}
            </div>
          )}
          {isSuccess ? (
            <div className="t-center">
              <a href="/" style={{color:"blue",fontSize:"18px"}}>Let&apos;s Start</a>
            </div>
          ):null} 
        </div>
      </div>
    </div>
  );
}
