import { fixValueToNumber } from "../../components/3d-models/utils";

export const pipingRingJointFacingFlanges = [
  {
    piping_flange_id: 1,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "63,5",
    p: "47,62",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R15",
  },
  {
    piping_flange_id: 2,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "73,2",
    p: "57,15",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R17",
  },
  {
    piping_flange_id: 3,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "82,5",
    p: "65,07",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R19",
  },
  {
    piping_flange_id: 4,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "101,6",
    p: "82,55",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R22",
  },
  {
    piping_flange_id: 5,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "120,7",
    p: "101,6",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R25",
  },
  {
    piping_flange_id: 6,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "133,4",
    p: "114,3",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R29",
  },
  {
    piping_flange_id: 7,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: 154,
    p: "131,78",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R33",
  },
  {
    piping_flange_id: 8,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "171,5",
    p: "149,22",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R36",
  },
  {
    piping_flange_id: 9,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "193,5",
    p: "171,45",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R40",
  },
  {
    piping_flange_id: 10,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: 219,
    p: "193,68",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R43",
  },
  {
    piping_flange_id: 11,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: 273,
    p: "247,65",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R48",
  },
  {
    piping_flange_id: 12,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "330,2",
    p: "304,8",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R52",
  },
  {
    piping_flange_id: 13,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "406,4",
    p: 381,
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R56",
  },
  {
    piping_flange_id: 14,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "425,5",
    p: "396,88",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: 3,
    r_no: "R59",
  },
  {
    piping_flange_id: 15,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "482,6",
    p: "454,02",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: 3,
    r_no: "R64",
  },
  {
    piping_flange_id: 16,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "546,1",
    p: "517,52",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: 3,
    r_no: "R68",
  },
  {
    piping_flange_id: 17,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "596,9",
    p: "558,8",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: 3,
    r_no: "R72",
  },
  {
    piping_flange_id: 18,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 150,
    k: "711,2",
    p: "673,1",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: 3,
    r_no: "R76",
  },
  {
    piping_flange_id: 19,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "50,8",
    p: "34,14",
    e: "5,56",
    f: "7,14",
    r: "0,8",
    d: 3,
    r_no: "R11",
  },
  {
    piping_flange_id: 20,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "63,5",
    p: "42,28",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R13",
  },
  {
    piping_flange_id: 21,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "69,9",
    p: "50,8",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R16",
  },
  {
    piping_flange_id: 22,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "79,2",
    p: "60,32",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R18",
  },
  {
    piping_flange_id: 23,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "90,4",
    p: "68,28",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R20",
  },
  {
    piping_flange_id: 24,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: 108,
    p: "82,55",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R23",
  },
  {
    piping_flange_id: 25,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: 127,
    p: "101,6",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R26",
  },
  {
    piping_flange_id: 26,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "146,1",
    p: "123,83",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R31",
  },
  {
    piping_flange_id: 27,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "158,8",
    p: "131,78",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R34",
  },
  {
    piping_flange_id: 28,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "174,8",
    p: "149,22",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R37",
  },
  {
    piping_flange_id: 29,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "209,6",
    p: "180,98",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R41",
  },
  {
    piping_flange_id: 30,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "241,3",
    p: "211,12",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R45",
  },
  {
    piping_flange_id: 31,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "301,8",
    p: "269,88",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R49",
  },
  {
    piping_flange_id: 32,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "355,6",
    p: "323,85",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R53",
  },
  {
    piping_flange_id: 33,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "412,8",
    p: 381,
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R57",
  },
  {
    piping_flange_id: 34,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "457,2",
    p: "419,1",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R61",
  },
  {
    piping_flange_id: 35,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: 508,
    p: "469,9",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R65",
  },
  {
    piping_flange_id: 36,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "574,5",
    p: "533,4",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R69",
  },
  {
    piping_flange_id: 37,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: 635,
    p: "584,2",
    e: "9,52",
    f: "13,49",
    r: "1,6",
    d: "5,6",
    r_no: "R73",
  },
  {
    piping_flange_id: 38,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "749,3",
    p: "692,15",
    e: "11,13",
    f: "16,66",
    r: "1,6",
    d: "6,4",
    r_no: "R77",
  },
  {
    piping_flange_id: 39,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "50,8",
    p: "34,14",
    e: "5,56",
    f: "7,14",
    r: "0,8",
    d: 3,
    r_no: "R11",
  },
  {
    piping_flange_id: 40,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "63,5",
    p: "42,28",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R13",
  },
  {
    piping_flange_id: 41,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "69,9",
    p: "50,8",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R16",
  },
  {
    piping_flange_id: 42,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "79,2",
    p: "60,32",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R18",
  },
  {
    piping_flange_id: 43,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "90,4",
    p: "68,28",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R20",
  },
  {
    piping_flange_id: 44,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: 108,
    p: "82,55",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R23",
  },
  {
    piping_flange_id: 45,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: 127,
    p: "101,6",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R26",
  },
  {
    piping_flange_id: 46,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "146,1",
    p: "123,83",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R31",
  },
  {
    piping_flange_id: 47,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "158,8",
    p: "131,78",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R34",
  },
  {
    piping_flange_id: 48,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "174,8",
    p: "149,22",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R37",
  },
  {
    piping_flange_id: 49,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "209,6",
    p: "180,98",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R41",
  },
  {
    piping_flange_id: 50,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "241,3",
    p: "211,12",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R45",
  },
  {
    piping_flange_id: 51,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "301,8",
    p: "269,88",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R49",
  },
  {
    piping_flange_id: 52,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "355,6",
    p: "323,85",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R53",
  },
  {
    piping_flange_id: 53,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "412,8",
    p: 381,
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R57",
  },
  {
    piping_flange_id: 54,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "457,2",
    p: "419,1",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R61",
  },
  {
    piping_flange_id: 55,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: 508,
    p: "469,9",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R65",
  },
  {
    piping_flange_id: 56,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "574,5",
    p: "533,4",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "5,6",
    r_no: "R69",
  },
  {
    piping_flange_id: 57,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: 635,
    p: "584,2",
    e: "9,52",
    f: "13,49",
    r: "1,6",
    d: "5,6",
    r_no: "R73",
  },
  {
    piping_flange_id: 58,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "749,3",
    p: "692,15",
    e: "11,13",
    f: "16,66",
    r: "1,6",
    d: "6,4",
    r_no: "R77",
  },
  {
    piping_flange_id: 59,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "50,8",
    p: "34,14",
    e: "5,56",
    f: "7,14",
    r: "0,8",
    d: 3,
    r_no: "R11",
  },
  {
    piping_flange_id: 60,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "63,5",
    p: "42,28",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R13",
  },
  {
    piping_flange_id: 61,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "69,9",
    p: "50,8",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R16",
  },
  {
    piping_flange_id: 62,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "79,2",
    p: "60,32",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R18",
  },
  {
    piping_flange_id: 63,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "90,4",
    p: "68,28",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R20",
  },
  {
    piping_flange_id: 64,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: 108,
    p: "82,55",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R23",
  },
  {
    piping_flange_id: 65,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: 127,
    p: "101,6",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R26",
  },
  {
    piping_flange_id: 66,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "146,1",
    p: "123,83",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R31",
  },
  {
    piping_flange_id: 67,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "158,8",
    p: "131,78",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R34",
  },
  {
    piping_flange_id: 68,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "174,8",
    p: "149,22",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R37",
  },
  {
    piping_flange_id: 69,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "209,6",
    p: "180,98",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R41",
  },
  {
    piping_flange_id: 70,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "241,3",
    p: "211,12",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R45",
  },
  {
    piping_flange_id: 71,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "301,8",
    p: "269,88",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R49",
  },
  {
    piping_flange_id: 72,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "355,6",
    p: "323,85",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R53",
  },
  {
    piping_flange_id: 73,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "412,8",
    p: 381,
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R57",
  },
  {
    piping_flange_id: 74,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "457,2",
    p: "419,1",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R61",
  },
  {
    piping_flange_id: 75,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: 508,
    p: "469,9",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R65",
  },
  {
    piping_flange_id: 76,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "574,5",
    p: "533,4",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,8",
    r_no: "R69",
  },
  {
    piping_flange_id: 77,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: 635,
    p: "584,2",
    e: "9,52",
    f: "13,49",
    r: "1,6",
    d: "4,8",
    r_no: "R73",
  },
  {
    piping_flange_id: 78,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "749,3",
    p: "692,15",
    e: "11,13",
    f: "16,66",
    r: "1,6",
    d: "5,6",
    r_no: "R77",
  },
  {
    piping_flange_id: 79,
    dn: 650,
    nps: 26,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "809,8",
    p: "749,3",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R93",
  },
  {
    piping_flange_id: 80,
    dn: 700,
    nps: 28,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "860,5",
    p: "800,1",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R94",
  },
  {
    piping_flange_id: 81,
    dn: 750,
    nps: 30,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "917,4",
    p: "857,25",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R95",
  },
  {
    piping_flange_id: 82,
    dn: 800,
    nps: 32,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "984,3",
    p: "914,4",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R96",
  },
  {
    piping_flange_id: 83,
    dn: 850,
    nps: 34,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: 1035,
    p: "965,2",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R97",
  },
  {
    piping_flange_id: 84,
    dn: 900,
    nps: 36,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "1092,2",
    p: "1022,35",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R98",
  },
  {
    piping_flange_id: 85,
    dn: 650,
    nps: 26,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "809,8",
    p: "749,3",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R93",
  },
  {
    piping_flange_id: 86,
    dn: 700,
    nps: 28,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "860,5",
    p: "800,1",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R94",
  },
  {
    piping_flange_id: 87,
    dn: 750,
    nps: 30,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "917,4",
    p: "857,25",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R95",
  },
  {
    piping_flange_id: 88,
    dn: 800,
    nps: 32,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "984,3",
    p: "914,4",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R96",
  },
  {
    piping_flange_id: 89,
    dn: 850,
    nps: 34,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: 1035,
    p: "965,2",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R97",
  },
  {
    piping_flange_id: 90,
    dn: 900,
    nps: 36,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 300,
    k: "1092,2",
    p: "1022,35",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R98",
  },
  {
    piping_flange_id: 91,
    dn: 650,
    nps: 26,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "809,8",
    p: "749,3",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R93",
  },
  {
    piping_flange_id: 92,
    dn: 700,
    nps: 28,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "860,5",
    p: "800,1",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R94",
  },
  {
    piping_flange_id: 93,
    dn: 750,
    nps: 30,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "917,4",
    p: "857,25",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R95",
  },
  {
    piping_flange_id: 94,
    dn: 800,
    nps: 32,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "984,3",
    p: "914,4",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R96",
  },
  {
    piping_flange_id: 95,
    dn: 850,
    nps: 34,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: 1035,
    p: "965,2",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R97",
  },
  {
    piping_flange_id: 96,
    dn: 900,
    nps: 36,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "1092,2",
    p: "1022,35",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R98",
  },
  {
    piping_flange_id: 97,
    dn: 650,
    nps: 26,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "809,8",
    p: "749,3",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R93",
  },
  {
    piping_flange_id: 98,
    dn: 700,
    nps: 28,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "860,5",
    p: "800,1",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R94",
  },
  {
    piping_flange_id: 99,
    dn: 750,
    nps: 30,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "917,4",
    p: "857,25",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R95",
  },
  {
    piping_flange_id: 100,
    dn: 800,
    nps: 32,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "984,3",
    p: "914,4",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R96",
  },
  {
    piping_flange_id: 101,
    dn: 850,
    nps: 34,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: 1035,
    p: "965,2",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R97",
  },
  {
    piping_flange_id: 102,
    dn: 900,
    nps: 36,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 400,
    k: "1092,2",
    p: "1022,35",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R98",
  },
  {
    piping_flange_id: 103,
    dn: 650,
    nps: 26,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "809,8",
    p: "749,3",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R93",
  },
  {
    piping_flange_id: 104,
    dn: 700,
    nps: 28,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "860,5",
    p: "800,1",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R94",
  },
  {
    piping_flange_id: 105,
    dn: 750,
    nps: 30,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "917,4",
    p: "857,25",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R95",
  },
  {
    piping_flange_id: 106,
    dn: 800,
    nps: 32,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "984,3",
    p: "914,4",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R96",
  },
  {
    piping_flange_id: 107,
    dn: 850,
    nps: 34,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: 1035,
    p: "965,2",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R97",
  },
  {
    piping_flange_id: 108,
    dn: 900,
    nps: 36,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "1092,2",
    p: "1022,35",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R98",
  },
  {
    piping_flange_id: 109,
    dn: 650,
    nps: 26,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "809,8",
    p: "749,3",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R93",
  },
  {
    piping_flange_id: 110,
    dn: 700,
    nps: 28,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "860,5",
    p: "800,1",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R94",
  },
  {
    piping_flange_id: 111,
    dn: 750,
    nps: 30,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "917,4",
    p: "857,25",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "",
    r_no: "R95",
  },
  {
    piping_flange_id: 112,
    dn: 800,
    nps: 32,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "984,3",
    p: "914,4",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R96",
  },
  {
    piping_flange_id: 113,
    dn: 850,
    nps: 34,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: 1035,
    p: "965,2",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R97",
  },
  {
    piping_flange_id: 114,
    dn: 900,
    nps: 36,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 600,
    k: "1092,2",
    p: "1022,35",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "",
    r_no: "R98",
  },
  {
    piping_flange_id: 115,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "60,5",
    p: "39,67",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R12",
  },
  {
    piping_flange_id: 116,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "66,5",
    p: "44,45",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R14",
  },
  {
    piping_flange_id: 117,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "71,4",
    p: "50,8",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R16",
  },
  {
    piping_flange_id: 118,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: 81,
    p: "60,32",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R18",
  },
  {
    piping_flange_id: 119,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "91,9",
    p: "68,28",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R20",
  },
  {
    piping_flange_id: 120,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "123,9",
    p: "95,25",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: 3,
    r_no: "R24",
  },
  {
    piping_flange_id: 121,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "136,7",
    p: "107,95",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: 3,
    r_no: "R27",
  },
  {
    piping_flange_id: 122,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "155,4",
    p: "123,82",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,1",
    r_no: "R31",
  },
  {
    piping_flange_id: 123,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "180,8",
    p: "149,22",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,1",
    r_no: "R37",
  },
  {
    piping_flange_id: 124,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "215,9",
    p: "180,98",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,1",
    r_no: "R41",
  },
  {
    piping_flange_id: 125,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "241,3",
    p: "211,12",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,1",
    r_no: "R45",
  },
  {
    piping_flange_id: 126,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "307,8",
    p: "269,88",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,1",
    r_no: "R49",
  },
  {
    piping_flange_id: 127,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: 362,
    p: "323,85",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,1",
    r_no: "R53",
  },
  {
    piping_flange_id: 128,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "419,1",
    p: 381,
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: "4,1",
    r_no: "R57",
  },
  {
    piping_flange_id: 129,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "466,9",
    p: "419,1",
    e: "11,13",
    f: "16,66",
    r: "1,6",
    d: "4,1",
    r_no: "R62",
  },
  {
    piping_flange_id: 130,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "523,7",
    p: "469,9",
    e: "11,13",
    f: "16,66",
    r: "1,6",
    d: "4,1",
    r_no: "R66",
  },
  {
    piping_flange_id: 131,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "593,9",
    p: "533,4",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "4,8",
    r_no: "R70",
  },
  {
    piping_flange_id: 132,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "647,7",
    p: "584,2",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "4,8",
    r_no: "R74",
  },
  {
    piping_flange_id: 133,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "771,7",
    p: "692,15",
    e: "15,88",
    f: "26,97",
    r: "2,3",
    d: "5,6",
    r_no: "R78",
  },
  {
    piping_flange_id: 134,
    dn: 650,
    nps: 26,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "831,9",
    p: "749,3",
    e: "17,48",
    f: "30,18",
    r: "2,3",
    d: "",
    r_no: "R100",
  },
  {
    piping_flange_id: 135,
    dn: 700,
    nps: 28,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: 889,
    p: "800,1",
    e: "17,48",
    f: "33,32",
    r: "2,3",
    d: "",
    r_no: "R101",
  },
  {
    piping_flange_id: 136,
    dn: 750,
    nps: 30,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "946,2",
    p: "857,25",
    e: "17,48",
    f: "33,32",
    r: "2,3",
    d: "",
    r_no: "R102",
  },
  {
    piping_flange_id: 137,
    dn: 800,
    nps: 32,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "1003,3",
    p: "914,4",
    e: "17,48",
    f: "33,32",
    r: "2,3",
    d: "",
    r_no: "R103",
  },
  {
    piping_flange_id: 138,
    dn: 850,
    nps: 34,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "1066,8",
    p: "965,2",
    e: "20,62",
    f: "36,53",
    r: "2,3",
    d: "",
    r_no: "R104",
  },
  {
    piping_flange_id: 139,
    dn: 900,
    nps: 36,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: 1124,
    p: "1022,35",
    e: "20,62",
    f: "36,53",
    r: "2,3",
    d: "",
    r_no: "R105",
  },
  {
    piping_flange_id: 140,
    dn: 650,
    nps: 26,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "831,9",
    p: "749,3",
    e: "17,48",
    f: "30,18",
    r: "2,3",
    d: "",
    r_no: "R100",
  },
  {
    piping_flange_id: 141,
    dn: 700,
    nps: 28,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: 889,
    p: "800,1",
    e: "17,48",
    f: "33,32",
    r: "2,3",
    d: "",
    r_no: "R101",
  },
  {
    piping_flange_id: 142,
    dn: 750,
    nps: 30,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "946,2",
    p: "857,25",
    e: "17,48",
    f: "33,32",
    r: "2,3",
    d: "",
    r_no: "R102",
  },
  {
    piping_flange_id: 143,
    dn: 800,
    nps: 32,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "1003,3",
    p: "914,4",
    e: "17,48",
    f: "33,32",
    r: "2,3",
    d: "",
    r_no: "R103",
  },
  {
    piping_flange_id: 144,
    dn: 850,
    nps: 34,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: "1066,8",
    p: "965,2",
    e: "20,62",
    f: "36,53",
    r: "2,3",
    d: "",
    r_no: "R104",
  },
  {
    piping_flange_id: 145,
    dn: 900,
    nps: 36,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 900,
    k: 1124,
    p: "1022,35",
    e: "20,62",
    f: "36,53",
    r: "2,3",
    d: "",
    r_no: "R105",
  },
  {
    piping_flange_id: 146,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: "60,5",
    p: "39,67",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R12",
  },
  {
    piping_flange_id: 147,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: "66,5",
    p: "44,45",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R14",
  },
  {
    piping_flange_id: 148,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: "71,4",
    p: "50,8",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R16",
  },
  {
    piping_flange_id: 149,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: 81,
    p: "60,32",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R18",
  },
  {
    piping_flange_id: 150,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: "91,9",
    p: "68,28",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R20",
  },
  {
    piping_flange_id: 151,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: "123,9",
    p: "95,25",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: 3,
    r_no: "R24",
  },
  {
    piping_flange_id: 152,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: "136,7",
    p: "107,95",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: 3,
    r_no: "R27",
  },
  {
    piping_flange_id: 153,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: "168,1",
    p: "136,52",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: 3,
    r_no: "R35",
  },
  {
    piping_flange_id: 154,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: "193,5",
    p: "161,92",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: 3,
    r_no: "R39",
  },
  {
    piping_flange_id: 155,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: "228,6",
    p: "193,68",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: 3,
    r_no: "R44",
  },
  {
    piping_flange_id: 156,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: 248,
    p: "211,12",
    e: "9,52",
    f: "13,49",
    r: "1,6",
    d: 3,
    r_no: "R46",
  },
  {
    piping_flange_id: 157,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: 318,
    p: "269,88",
    e: "11,13",
    f: "16,66",
    r: "1,6",
    d: "4,1",
    r_no: "R50",
  },
  {
    piping_flange_id: 158,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: 371,
    p: "323,85",
    e: "11,13",
    f: "16,66",
    r: "1,6",
    d: "4,1",
    r_no: "R54",
  },
  {
    piping_flange_id: 159,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: 438,
    p: 381,
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "4,8",
    r_no: "R58",
  },
  {
    piping_flange_id: 160,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: 489,
    p: "419,1",
    e: "15,88",
    f: "26,97",
    r: "2,3",
    d: "5,6",
    r_no: "R63",
  },
  {
    piping_flange_id: 161,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: 546,
    p: "469,9",
    e: "17,48",
    f: "30,18",
    r: "2,3",
    d: "7,9",
    r_no: "R67",
  },
  {
    piping_flange_id: 162,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: 613,
    p: "533,4",
    e: "17,48",
    f: "30,18",
    r: "2,3",
    d: "7,9",
    r_no: "R71",
  },
  {
    piping_flange_id: 163,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: 673,
    p: "584,2",
    e: "17,48",
    f: "33,32",
    r: "2,3",
    d: "9,7",
    r_no: "R75",
  },
  {
    piping_flange_id: 164,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 1500,
    k: 794,
    p: "692,15",
    e: "20,62",
    f: "36,53",
    r: "2,3",
    d: "11,2",
    r_no: "R79",
  },
  {
    piping_flange_id: 165,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: 65,
    p: "42,88",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R13",
  },
  {
    piping_flange_id: 166,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "73,2",
    p: "50,8",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R16",
  },
  {
    piping_flange_id: 167,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "82,6",
    p: "60,32",
    e: "6,35",
    f: "8,74",
    r: "0,8",
    d: "4,1",
    r_no: "R18",
  },
  {
    piping_flange_id: 168,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "101,6",
    p: "72,24",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: 3,
    r_no: "R21",
  },
  {
    piping_flange_id: 169,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "114,3",
    p: "82,55",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: 3,
    r_no: "R23",
  },
  {
    piping_flange_id: 170,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "133,4",
    p: "101,6",
    e: "7,92",
    f: "11,91",
    r: "0,8",
    d: 3,
    r_no: "R26",
  },
  {
    piping_flange_id: 171,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "149,4",
    p: "111,12",
    e: "9,52",
    f: "13,49",
    r: "1,6",
    d: 3,
    r_no: "R28",
  },
  {
    piping_flange_id: 172,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "168,1",
    p: 127,
    e: "9,52",
    f: "13,49",
    r: "1,6",
    d: 3,
    r_no: "R32",
  },
  {
    piping_flange_id: 173,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "203,2",
    p: "157,18",
    e: "11,13",
    f: "16,66",
    r: "1,6",
    d: "4,1",
    r_no: "R38",
  },
  {
    piping_flange_id: 174,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "241,3",
    p: "190,5",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "4,1",
    r_no: "R42",
  },
  {
    piping_flange_id: 175,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "279,4",
    p: "228,6",
    e: "12,7",
    f: "19,84",
    r: "1,6",
    d: "4,1",
    r_no: "R47",
  },
  {
    piping_flange_id: 176,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "339,9",
    p: "279,4",
    e: "14,27",
    f: "23,01",
    r: "1,6",
    d: "4,8",
    r_no: "R51",
  },
  {
    piping_flange_id: 177,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "425,5",
    p: "342,9",
    e: "17,48",
    f: "30,18",
    r: "2,3",
    d: "6,4",
    r_no: "R55",
  },
  {
    piping_flange_id: 178,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE -RING JOINT FACING",
    class: 2500,
    k: "495,3",
    p: "406,4",
    e: "17,48",
    f: "33,32",
    r: "2,3",
    d: "7,9",
    r_no: "R60",
  },
].map((item) => ({
  ...item,
  nps: item.nps + "",
  k: fixValueToNumber(item.k, "float"),
  e: fixValueToNumber(item.e, "float"),
  r: fixValueToNumber(item.r, "float"),
  p: fixValueToNumber(item.p, "float"),
  f: fixValueToNumber(item.f, "float"),
  d: fixValueToNumber(item.d, "float"),
}));
