import React from "react";
import "./privacyPolicy.css";

export function PrivacyPolicy() {
  return (
    <div className="auth-page">
      <div className="privacy-policy-container">
        <div className="privacy-policy-content">
          <h2>ASETS Software Privacy Policy</h2>

          <h3>1. Privacy Commitment and Personal Information</h3>
          <p>
            Your privacy and personal information are very important to us. The personal
            information that ASETS-CA Inc. (&quot;ASETS&quot;, &quot;Us&quot;, &quot;us&quot;, &quot;our&quot;, &quot;Our&quot;, &quot;We&quot; and &quot;we&quot;)
            collects about you (&quot;You&quot;, &quot;you&quot;, &quot;Your&quot;, &quot;your&quot;, or the &quot;User&quot;) is managed in
            accordance with this Privacy Policy (&quot;Policy&quot; or &quot;Privacy Policy&quot;). ASETS provides
            an engineering design & simulation platform for executing early-stage engineering
            calculations and material take off with a 3D Model. This Privacy Policy applies to the
            collection, use and disclosure of your personal information, including personal
            information relating to and collected in connection with your use of the Platform,
            the <a href="https://www.asets.io/">https://www.asets.io/</a> website, the <a href="https://www.asets-ca.com/">https://www.asets-ca.com/</a> website or any other
            ASETS website that you access (collectively, the &quot;Website&quot;), and any and all other
            related services offered by ASETS which may include maintenance and support
            services (the &quot;Services&quot;).
          </p>

          <h3>2. Changes to Privacy Policy</h3>
          <p>
            This Policy describes our current privacy practices. We update this Policy on an
            ongoing basis to ensure consumers, applicants, customers, service partners, and
            service providers are aware of updates to our privacy practices, to streamline those
            practices and to comply with applicable laws. Please visit the Website regularly for
            updates.
          </p>
          <p>
            ASETS may, at any time and from time to time at its sole discretion, update, revise,
            supplement, replace, and/or otherwise modify this Policy and/or impose new or
            additional rules, policies, terms, or conditions on You. Such updates, revisions,
            supplements, replacements, modifications, and additional rules, policies, terms, and
            conditions (collectively referred to as &quot;Revised Terms&quot;) will be effective immediately
            upon the Revised Terms being posted to the Website or by ASETS otherwise making
            them available to You (as the case may be). Any continued use of the Website, the
            Services, and/or the Platform following such posting of the Revised Terms to this
            Policy or by ASETS otherwise making them available to You (as the case may be),
            will be deemed to constitute acceptance of and consent to the Revised Terms.
          </p>

          <h3>3. Information We Collect, and Limiting Collection</h3>
          <p>
            ASETS collects information about identifiable individuals. Except where information
            is required to fulfill an identified purpose, you get to decide what information you
            want to share with us. ASETS only collects such Personal information as is
            reasonably necessary for ASETS to fulfill the purposes as set out in this Privacy
            Policy.
          </p>
          <p>
            Through the Platform and the Website, ASETS collects information about identifiable
            individuals (&quot;Personal Information&quot;) relating to use of the Platform and any related
            Services by users, which may include but may not be limited to the following
            information:
          </p>
          <ul>
            <li>
              Contact and biographical information that you provide to us, including your first
              and last name, your email address, your phone number, and your address, in
              connection with creating and maintaining an account registered with ASETS or
              otherwise in connection with your use of features of the Platform and any related
              Services;
            </li>
            <li>
              Information you provide directly to us when you request that we conduct a specific
              task on your behalf using the Services, or other information you provide when you
              communicate with us;
            </li>
            <li>
              Usage information, including the information created from your use of the Platform,
              the Website, and related Services, including automatically collected online
              identifiers such as general geographic location, IP address, and details regarding
              your use of the Platform, the Website, and the Services;
            </li>
            <li>
              Information that you provide to us for the purpose of obtaining or receiving updates
              about new services, and/or obtaining or receiving notifications; and
            </li>
            <li>
              Information about your online activity; for example, information about your web
              browser, Internet Protocol (IP) address, use of the Website, and history. Cookies
              (small computer files that a website&quot;s server places on your computer) collect
              information about your online behaviour. You can set your browser to reject
              cookies, but this may impair our ability to customize and test your experience, like
              remembering your preferred language or present location-specific information to
              you. By using and browsing the Website, you consent to cookies being used in
              accordance with this Privacy Policy. If you do not consent, you must disable cookies
              or refrain from using the Website. Cookies do not typically contain Personal
              Information, but Personal Information that we store about you may be linked to the
              information stored in and obtained from cookies.
            </li>
          </ul>

          <p>
            With respect to the Personal Information of any third parties that you may provide to
            ASETS in connection with your use of the Website, the Platform, or other Services,
            you are responsible for ensuring that you have any such third party&quot;s consent to the
            collection, use, and disclosure of their Personal Information in accordance with the
            terms of this Privacy Policy, and by providing such Personal Information to ASETS,
            you represent and warrant that you have such consent.
          </p>

          <h3>4. Identifying Purposes and Use of Personal Information</h3>
          <p>
            We will clearly identify the purposes for which Personal Information is collected, used
            or disclosed prior to, or at the time of, collection. Personal Information submitted to
            us, whether through the Platform, the Website, and/or any related Services, or
            otherwise, may be used by us for the purposes specified in this Privacy Policy or as
            specified in connection with the Platform, the Website, and/or any related Services,
            and you hereby consent to same. By registering to create an account with ASETS, by
            using the Platform and any related Services, or otherwise using the Website, the
            Platform, and the Services, you consent to the use of your Personal Information as
            indicated in this Privacy Policy.
          </p>
          <p>
            ASETS may use your Personal Information to (and you hereby consent to each of the
            following uses):
          </p>
          <ul>
            <li>
              Setting up an account with us or otherwise registering to use the Platform, the
              Website, and any related Services;
            </li>
            <li>
              Provide, update, maintain, administer and operate the Platform, the Website, and
              the Services and deliver and enable your use of the Platform, the Website, and the
              Services, including the development of new product and service offerings;
            </li>
            <li>
              To understand how you and our other users use the Services, the Website, and the
              Platform in order to improve the Website, the Services and Platform and our
              marketing efforts, and to assess trends in use of the Services, and other features of
              the Platform and the Website;
            </li>
            <li>
              Inform you of other products, promotions or services available from ASETS and its
              partners;
            </li>
            <li>Confirming your identity;</li>
            <li>
              Deal with inquiries, complaints, submissions and feedback relating to the Platform,
              the Website, and the Services, and to investigate and settle any dispute or claim
              which relates to the Website, our Platform, and related Services; and
            </li>
            <li>
              To manage, administer, maintain, service, and enforce our Privacy Policy and verify
              compliance with agreements between you and us relating to your use of the
              Platform and related Services, or to otherwise comply with applicable laws and
              regulations.
            </li>
          </ul>

          <p>
            ASETS may also anonymize, aggregate or otherwise strip any Personal Information we
            collect of all personally identifying characteristics.
          </p>
          <p>
            We may use your Personal Information to contact you. Your Personal Information
            allows us to contact you by various methods, including mail, email, and telephone at
            the contact points you have provided to us. We will contact you to respond to your
            questions, provide you with information about the Services that you use, inform you
            about the Website, the Platform, and any related Services and to update you on
            changes to our Website, our Platform, and any related Services. When you
            communicate online or by email, you should be aware that sending information over
            the internet is inherently insecure, as it can be intercepted or manipulated and
            retransmitted.
          </p>

          <h3>5. Disclosure of Personal Information</h3>
          <p>
            Any Personal Information provided by you, or that is derived automatically by or
            through the Website, the Platform, and related Services is not sold, rented, or shared
            by us with any third party without your consent, except in the circumstances described
            in this Privacy Policy. We may disclose your Personal Information to any of our
            employees, officers, insurers, professional advisers, agents, suppliers or
            subcontractors, to the extent that such disclosure is reasonably necessary for the
            purposes set out in this Privacy Policy.
          </p>
          <p>
            You acknowledge and agree that We may use and/or disclose your Personal
            Information, without notice, if legally permitted to do so according to the relevant
            provisions of the Canada Personal Information Protection and Electronic Documents
            Act, S.C. 2000, c.5, the Personal Information International Disclosure Protection Act
            (PIIDPA), or the Personal Information Protection Act, and/or any other relevant
            privacy enactment (collectively, the &quot;Privacy Acts&quot;). The Privacy Acts may permit the
            use and/or disclosure of Personal Information without notice if, for example:
          </p>
          <ul>
            <li>
              The Personal Information could be useful in the investigation of a contravention of
              the laws of Canada, a province or a foreign jurisdiction;
            </li>
            <li>
              The Personal Information is used or disclosed for the purpose of acting in respect of
              an emergency that threatens the life, health or security of an individual; and
            </li>
            <li>
              The Personal Information is disclosed to comply with the edicts of the law, or to
              comply with a subpoena or warrant issued or an order made by a court.
            </li>
          </ul>

          <p>
            In any event, ASETS may release Personal Information when it believes in good faith
            that such release is necessary to:
          </p>
          <ul>
            <li>Comply with any law, order, rule, or regulation;</li>
            <li>
              Enforce or apply this Privacy Policy or the End User License Agreement or any other
              contract or policy of ASETS; and
            </li>
            <li>
              Protect the rights (including intellectual property rights), property, or safety of ASETS,
              its members, employees, contractors, or others.
            </li>
          </ul>

          <p>
            We may disclose your Personal Information if ASETS or substantially all of its assets
            are acquired by a third party, in which case Personal Information held by it about its
            customers will be one of the transferred assets, in accordance with applicable law.
          </p>

          <p>
            You also acknowledge and agree that we may engage other companies and individuals
            (&quot;Third Party Service Providers&quot;) to perform some of the foregoing on our behalf. We
            may disclose your Personal Information to one or more Third Party Service Providers
            who provide services for us or perform Services on our behalf. We make every effort
            to ensure any company with which we may share Personal Information protects that
            Personal Information and data in a manner similar to this Privacy Policy and to limit the
            use of such Personal Information to the performance of the services being provided to
            ASETS.
          </p>
          <p>
            ASETS’s cloud-based software Platform is built on a software platform maintained by
            AWS. ASETS payment processing gateway is built on and maintained by Stripe
            [collectively referred to as “Service Partners”]. In conjunction with our provision of the
            Platform and Services, certain of your Personal Information, including for example
            contact and biographical information that you provide to us in connection with creating
            and maintaining your account, may be provided to these Service Partners. We will
            only provide to Service Partners such Personal Information as is required by them in
            order for us to provide the Services and the Platform. ASETS is not responsible for the
            Service Partners handling of your Personal Information. Their current privacy policy
            can be found <a href="https://stripe.com/en-ca/privacy">here</a>.
          </p>

          <p>
            We may disclose your Personal Information to other Third Party Service Providers,
            namely Third Party Service Providers who:
          </p>
          <ul>
            <li>
              Host and administer our Website, process and store data, and fulfill similar
              technology-related functions on our behalf or carry out any other purpose described
              in this Privacy Policy;
            </li>
            <li>
              Provide us with other computing and IT services and may access and use your
              Personal Information while performing their services; and/or
            </li>
            <li>
              Provide analytics services to help us understand how our Platform and Services are
              used.
            </li>
          </ul>

          <p>
            We make commercially reasonable efforts to ensure that all Third Party Service
            Providers acting on our behalf are obligated to provide a comparable level of
            protection for your Personal Information to the level of protection that we provide.
          </p>

          <h3>6. Consent</h3>
          <p>
            If you apply for registration of an account or otherwise apply to use the Software, the
            Website, and the Services, provide Personal Information through the Services, or
            provide your Personal Information to us in any other way, you acknowledge your
            consent to the collection, use and disclosure of your Personal Information as set out
            in this Policy and applicable laws. If we want to use your Personal Information for a
            purpose that was not disclosed at the time of initial consent, consent will be sought at
            the time of this new purpose.
          </p>
          <p>
            Withdrawal of consent. You may withdraw your consent to the collection, use, and
            disclosure of your Personal Information as set out in this Privacy Policy at any time.
            Withdrawal of your consent to the collection, use, and/or disclosure of your Personal
            Information as set out in this Privacy Policy may result in all or part of the Platform, the
            Website, and/or related Services being unavailable to you. If you withdraw your
            consent to the collection, use, and/or disclosure of your Personal Information as set
            out in this Privacy Policy, ASETS reserves the right to limit your access or prevent
            you from accessing the Platform, the Website, and/or any related Services.
          </p>
          <p>
            Updating consent. Subject to legal and contractual restrictions, you can, with
            reasonable notice to us, update your consent or withdraw your consent to the
            collection, use, and disclosure of your Personal Information for specific purposes,
            other than the collection, use, and disclosure which is required for us to maintain your
            account with us and to provide Services that you sign up for and for which you were
            approved.
          </p>

          <h3>7. Accuracy</h3>
          <p>
            We take steps to ensure that your Personal Information is as accurate, complete and
            up to date as is necessary for the purposes for which it is to be used. If you believe
            that the Personal Information, we have about you is not accurate or is incomplete,
            please contact us by email at <a href="mailto:privacy@asets-ca.com">privacy@asets-ca.com</a>.
          </p>

          <h3>8. Safeguards</h3>
          <p>
            We use a variety of procedures and practices appropriate to the sensitivity of Personal
            Information to protect against loss, theft and unauthorized access. Access to your
            Personal Information is restricted to those individuals and parties who require access
            for the purposes set out in this Policy. For example, ASETS may secure the Personal
            Information you provide on computer servers in a controlled, secure environment,
            protected from unauthorized access, use or disclosure. When Personal Information is
            transmitted to other websites or third parties for the purposes specified above, we may
            also, or in the alternative, protect it through the use of encryption, such as by way of
            the Secure Socket Layer (SSL) protocol.
          </p>
          <p>
            Personal Information may be accessed by persons within our organization, by our
            Service Partners or our Third-Party Service Providers, who require such access to
            carry out the purposes described in this Privacy Policy or otherwise permitted or
            required by applicable law.
          </p>
          <p>
            Even though we have taken steps to help protect the Personal Information in our
            control, you should know that we cannot fully eliminate security risks associated with
            Personal Information. No security measures can provide absolute protection. We
            cannot ensure or warrant the security of any Personal Information you provide to us.
            While we take all reasonable measures to protect data, there is always inherent risk
            when providing Personal Information. The transmission of Personal Information and
            data over the Internet is inherently insecure, and there are no security systems that
            are completely safe or fool-proof against hacking or tampering.
          </p>
          <p>
            You are solely responsible for controlling access to your account(s) associated with
            Platform, and maintaining the confidentiality and security of your accounts and related
            password information. You are entirely responsible for all activities that occur on or
            through your account(s), and ASETS shall not be responsible for any losses arising out
            of the unauthorized use of any of your account(s) associated with the Platform.
          </p>

          <h3>9. Storage</h3>
          <p>
            We may store your Personal Information in any jurisdiction where we operate,
            including in Canada. When we share your Personal Information with our Service
            Partners and Third Party Service Providers, they may store some of your Personal
            Information in their databases in Canada, the United States, or elsewhere in the
            world. If you are downloading, installing, or using our Platform from jurisdictions
            outside Canada, please be advised that by transmitting or sending Personal
            Information to us, you are transferring your Personal Information to us in Canada,
            where data protection and privacy laws may be less stringent than the laws of your
            country.
          </p>

          <h3>10. Retention</h3>
          <p>
            We will retain your Personal Information for as long as needed to provide you with our
            Services by or through the Platform, for our valid business purposes, and as
            necessary to comply with our legal obligations, resolve disputes, defend our legal
            rights, and enforce our agreements. You can request that we delete Personal
            Information about you in our possession. If you would like us to delete some or all of
            your Personal Information, or delete your account (if applicable), please contact us by
            email at <a href="mailto:privacy@asets-ca.com">privacy@asets-ca.com</a>.
          </p>

          <h3>11. Rights Regarding Personal Information</h3>
          <p>
            Different laws allow you to exercise rights relating to your Personal Information in our
            possession. Depending on where you are located, these rights can vary. Your rights
            usually include the right to:
          </p>
          <ul>
            <li>
              Access copies of the Personal Information that we collect, use and disclose about
              you, as long as such data remain available in our databases; and
            </li>
            <li>
              Ask for the deletion of your Personal Information in certain circumstances provided
              by applicable law.
            </li>
          </ul>
          <p>
            Depending on where you are located and subject to applicable law, you may have
            additional rights with respect to your Personal Information in our possession, including:
          </p>
          <ul>
            <li>
              “Right to rectification” – the right to ask for the rectification of your Personal
              Information in case it is incorrect, incomplete, invalid or ambiguous;
            </li>
            <li>
              “Right to be forgotten” - the right to request that we erase your Personal Information
              in certain circumstances provided by applicable law;
            </li>
            <li>
              “Data portability” - the right to request that we send to another company, where
              technically feasible, your Personal Information;
            </li>
            <li>
              The right to request that we restrict the processing of your Personal Information in
              certain circumstances; for example, if you contest the accuracy of the Personal
              Information while we are verifying the accuracy thereof; and
            </li>
            <li>
              The right to object to processing of your Personal Information where it is processed
              on the grounds of legitimate business interests.
            </li>
          </ul>
          <p>
            If you have any questions about your rights or if you want to exercise any of these
            rights, please contact us by email at <a href="mailto:privacy@asets-ca.com">privacy@asets-ca.com</a>. We will take reasonable steps to enable you to
            access your Personal Information, or to update or correct Personal Information in our
            possession that you have previously submitted.
          </p>
          <p>
            If you request a transcription, reproduction, or transmission of your Personal
            Information, we may have to charge a reasonable fee to process your request, subject
            to applicable laws. In this case, we will contact you about these charges before
            addressing your request.
          </p>
          <p>
            Additionally, in some cases, your ability to access or control your Personal Information
            will be limited, as required or permitted under the applicable laws. If your request is
            denied, we will notify you in writing, with the reasons for our refusal and information on
            how to appeal our decision.
          </p>
          <p>
            <strong>Nevada, California, Virginia Residents</strong>
          </p>
          <p>
            Nevada, California and Virginia residents have the right to submit a verified request
            directing us not to sell your Personal Information. To submit such a request, please
            contact us by email at <a href="mailto:privacy@asets-ca.com">privacy@asets-ca.com</a>.
          </p>

          <h3>12. Third Party Policies and Links</h3>
          <p>
            The Website may contain links to other third-party sites that are not governed by this
            Privacy Policy. Our Privacy Policy will no longer apply once you leave our Website.
            Additionally, we are not responsible for the privacy practices employed by third-party
            websites. Therefore, we suggest you examine those sites&quot; privacy statements to learn
            how your information may be collected, used, shared and disclosed. ASETS does not
            endorse, approve of, verify, attest to, or offer any representation or warranty with
            ASETS as to, the accuracy of the content of such websites that are linked or Internet
            resources that are linked from the Website. If you decide to leave the Website and
            access these third-party sites, you do so at your own risk.
          </p>
          <p>
            Any links from our Website to other websites, or references to products, services or
            publications other than those of ASETS, do not imply the endorsement or approval of
            such websites, products, services or publications by ASETS.
          </p>

          <h3>13. Cookies</h3>
          <p>
            A cookie is a small computer file or piece of information that may be stored on your
            computer&quot;s hard drive when you visit our Website. We may use cookies to improve our
            Website&quot;s functionality and, in some cases, provide visitors with a customized online
            experience.
          </p>
          <p>
            Cookies are widely used, and most web browsers are configured initially to accept
            cookies automatically. You may change your Internet browser settings to prevent your
            computer from accepting cookies or to notify you when you receive a cookie so that
            you may decline its acceptance. Please note, however, that if you disable cookies, you
            may not experience the optimal performance of the Website, our Services, and our
            Platform.
          </p>
          <p>
            If you do not wish to receive cookies, or want to be notified of when they are placed,
            you may set your web browser to do so, if your browser so permits. If you wish to
            delete cookies, you may set your web browser to do so, if your browser so permits.
            You acknowledge and agree that if cookies are turned off or deleted, you may not be
            able to view certain parts of the Website or Platform or use certain of certain Services
            available through the Website or certain features or functionalities thereof.
          </p>

          <h3>14. Openness, Individual Access and Challenging Compliance</h3>
          <p>
            You can write to ASETS to request access to the Personal Information we have on file
            for you. We will provide you with the Personal Information we have, subject to certain
            considerations specified by law. If you wish to request access to the Personal
            Information that we have on file for you, or request that such Personal Information be
            corrected, please contact ASETS’s designated Privacy Officer at <a href="mailto:privacy@asets-ca.com">privacy@asets-ca.com</a>.
          </p>
          <p>
            ASETS welcomes your comments regarding this Privacy Policy. If you would like more
            information on this Privacy Policy or ASETS’s organizational privacy policies, or if
            believe that ASETS has not adhered to the terms of this Privacy Policy, please contact
            ASETS’s designated Privacy Officer at <a href="mailto:privacy@asets-ca.com">privacy@asets-ca.com</a>. We will use commercially reasonable efforts to
            promptly identify and remedy any failure by ASETS to adhere to the terms of this
            Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
}
