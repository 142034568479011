import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesAmerican1 = [
  {
    profile_section_id: 1,
    name: "W14X873",
    type: "W Section",
    designation: "W14X873",
    shape: "I",
    country_code: "American",
    ax: 257,
    ax_global: "165806,12",
    bf: "18,8",
    bf_global: "477,52",
    ct: "3,89",
    ct_global: "98,806",
    d: "23,6",
    d_global: "599,44",
    ix: 2270,
    ix_global: "944845336,1",
    iy: 6170,
    iy_global: 2568147896,
    iz: 18200,
    iz_global: 7575411946,
    od: "6,11",
    od_global: "155,194",
    rz: "3,13",
    rz_global: "79,502",
    t: "10,13",
    t_global: "257,302",
    tf: "5,51",
    tf_global: "139,954",
    tw: "3,94",
    tw_global: "100,076",
    zx: 2030,
    zx_global: "33265739,92",
    zy: 1020,
    zy_global: "16714805,28",
    width: "18,8",
    width_global: "477,52",
    thickness: "5,51",
    thickness_global: "139,954",
    height: "23,6",
    height_global: "599,44",
  },
  {
    profile_section_id: 2,
    name: "W14X808",
    type: "W Section",
    designation: "W14X808",
    shape: "I",
    country_code: "American",
    ax: 238,
    ax_global: "153548,08",
    bf: "18,6",
    bf_global: "472,44",
    ct: "3,68",
    ct_global: "93,472",
    d: "22,8",
    d_global: "579,12",
    ix: 1840,
    ix_global: "765865823,1",
    iy: 5550,
    iy_global: 2310084412,
    iz: 16000,
    iz_global: 6659702810,
    od: "5,72",
    od_global: "145,288",
    rz: "3,06",
    rz_global: "77,724",
    t: "10,13",
    t_global: "257,302",
    tf: "5,12",
    tf_global: "130,048",
    tw: "3,74",
    tw_global: "94,996",
    zx: 1840,
    zx_global: "30152197,76",
    zy: 930,
    zy_global: "15239969,52",
    width: "18,6",
    width_global: "472,44",
    thickness: "5,12",
    thickness_global: "130,048",
    height: "22,8",
    height_global: "579,12",
  },
  {
    profile_section_id: 3,
    name: "W21X275",
    type: "W Section",
    designation: "W21X275",
    shape: "I",
    country_code: "American",
    ax: 81,
    ax_global: "52257,96",
    bf: "12,9",
    bf_global: "327,66",
    ct: "2,88",
    ct_global: "73,152",
    d: "24,1",
    d_global: "612,14",
    ix: 101,
    ix_global: "42039373,99",
    iy: 790,
    iy_global: "328822826,2",
    iz: 7630,
    iz_global: 3175845777,
    od: "2,7",
    od_global: "68,58",
    rz: "1,44",
    rz_global: "36,576",
    t: 18,
    t_global: "457,2",
    tf: "2,2",
    tf_global: "55,88",
    tw: "1,22",
    tw_global: "30,988",
    zx: 742,
    zx_global: "12159201,49",
    zy: 191,
    zy_global: "3129929,224",
    width: "12,9",
    width_global: "327,66",
    thickness: "2,2",
    thickness_global: "55,88",
    height: "24,1",
    height_global: "612,14",
  },
  {
    profile_section_id: 4,
    name: "W21X248",
    type: "W Section",
    designation: "W21X248",
    shape: "I",
    country_code: "American",
    ax: "73,1",
    ax_global: "47161,196",
    bf: "12,8",
    bf_global: "325,12",
    ct: "2,75",
    ct_global: "69,85",
    d: "23,7",
    d_global: "601,98",
    ix: "75,3",
    ix_global: "31342226,35",
    iy: 701,
    iy_global: "291778229,3",
    iz: 6770,
    iz_global: 2817886751,
    od: "2,5",
    od_global: "63,5",
    rz: "1,38",
    rz_global: "35,052",
    t: 18,
    t_global: "457,2",
    tf: 2,
    tf_global: "50,8",
    tw: "1,1",
    tw_global: "27,94",
    zx: 664,
    zx_global: "10881010,5",
    zy: 170,
    zy_global: "2785800,88",
    width: "12,8",
    width_global: "325,12",
    thickness: 2,
    thickness_global: "50,8",
    height: "23,7",
    height_global: "601,98",
  },
  {
    profile_section_id: 5,
    name: "W21X223",
    type: "W Section",
    designation: "W21X223",
    shape: "I",
    country_code: "American",
    ax: "65,7",
    ax_global: "42387,012",
    bf: "12,7",
    bf_global: "322,58",
    ct: "2,66",
    ct_global: "67,564",
    d: "23,4",
    d_global: "594,36",
    ix: "55,1",
    ix_global: "22934351,55",
    iy: 616,
    iy_global: "256398558,2",
    iz: 6010,
    iz_global: 2501550868,
    od: "2,3",
    od_global: "58,42",
    rz: "1,31",
    rz_global: "33,274",
    t: 18,
    t_global: "457,2",
    tf: "1,8",
    tf_global: "45,72",
    tw: 1,
    tw_global: "25,4",
    zx: 594,
    zx_global: "9733916,016",
    zy: 150,
    zy_global: "2458059,6",
    width: "12,7",
    width_global: "322,58",
    thickness: "1,8",
    thickness_global: "45,72",
    height: "23,4",
    height_global: "594,36",
  },
  {
    profile_section_id: 6,
    name: "W36X925",
    type: "W Section",
    designation: "W36X925",
    shape: "I",
    country_code: "American",
    ax: 272,
    ax_global: "175483,52",
    bf: "18,6",
    bf_global: "472,44",
    ct: "6,33",
    ct_global: "160,782",
    d: "43,1",
    d_global: "1094,74",
    ix: 1450,
    ix_global: "603535567,1",
    iy: 4940,
    iy_global: 2056183242,
    iz: 72900,
    iz_global: 30343270926,
    od: "5,48",
    od_global: "139,192",
    rz: "2,69",
    rz_global: "68,326",
    t: "31,5",
    t_global: "800,1",
    tf: "4,53",
    tf_global: "115,062",
    tw: "3,02",
    tw_global: "76,708",
    zx: 4130,
    zx_global: "67678574,32",
    zy: 862,
    zy_global: "14125649,17",
    width: "18,6",
    width_global: "472,44",
    thickness: "4,53",
    thickness_global: "115,062",
    height: "43,1",
    height_global: "1094,74",
  },
  {
    profile_section_id: 7,
    name: "W36X853",
    type: "W Section",
    designation: "W36X853",
    shape: "I",
    country_code: "American",
    ax: 251,
    ax_global: "161935,16",
    bf: "18,2",
    bf_global: "462,28",
    ct: "5,94",
    ct_global: "150,876",
    d: "43,1",
    d_global: "1094,74",
    ix: 1250,
    ix_global: 520289282,
    iy: 4600,
    iy_global: 1914664558,
    iz: 69900,
    iz_global: 29094576649,
    od: "5,48",
    od_global: "139,192",
    rz: "2,44",
    rz_global: "61,976",
    t: "31,5",
    t_global: "800,1",
    tf: "4,53",
    tf_global: "115,062",
    tw: "2,52",
    tw_global: "64,008",
    zx: 3920,
    zx_global: "64237290,88",
    zy: 805,
    zy_global: "13191586,52",
    width: "18,2",
    width_global: "462,28",
    thickness: "4,53",
    thickness_global: "115,062",
    height: "43,1",
    height_global: "1094,74",
  },
  {
    profile_section_id: 8,
    name: "W36X802",
    type: "W Section",
    designation: "W36X802",
    shape: "I",
    country_code: "American",
    ax: 236,
    ax_global: "152257,76",
    bf: 18,
    bf_global: "457,2",
    ct: "5,79",
    ct_global: "147,066",
    d: "42,6",
    d_global: "1082,04",
    ix: 1060,
    ix_global: "441205311,1",
    iy: 4210,
    iy_global: 1752334302,
    iz: 64800,
    iz_global: 26971796379,
    od: "5,24",
    od_global: "133,096",
    rz: "2,38",
    rz_global: "60,452",
    t: "31,5",
    t_global: "800,1",
    tf: "4,29",
    tf_global: "108,966",
    tw: "2,38",
    tw_global: "60,452",
    zx: 3660,
    zx_global: "59976654,24",
    zy: 744,
    zy_global: "12191975,62",
    width: 18,
    width_global: "457,2",
    thickness: "4,29",
    thickness_global: "108,966",
    height: "42,6",
    height_global: "1082,04",
  },
  {
    profile_section_id: 9,
    name: "W36X723",
    type: "W Section",
    designation: "W36X723",
    shape: "I",
    country_code: "American",
    ax: 213,
    ax_global: "137419,08",
    bf: "17,8",
    bf_global: "452,12",
    ct: "5,57",
    ct_global: "141,478",
    d: "41,8",
    d_global: "1061,72",
    ix: 795,
    ix_global: "330903983,4",
    iy: 3700,
    iy_global: 1540056275,
    iz: 57300,
    iz_global: 23850060687,
    od: "4,85",
    od_global: "123,19",
    rz: "2,25",
    rz_global: "57,15",
    t: "31,5",
    t_global: "800,1",
    tf: "3,9",
    tf_global: "99,06",
    tw: "2,17",
    tw_global: "55,118",
    zx: 3270,
    zx_global: "53585699,28",
    zy: 659,
    zy_global: "10799075,18",
    width: "17,8",
    width_global: "452,12",
    thickness: "3,9",
    thickness_global: "99,06",
    height: "41,8",
    height_global: "1061,72",
  },
  {
    profile_section_id: 10,
    name: "W40x655",
    type: "W Section",
    designation: "W40X655",
    shape: "I",
    country_code: "American",
    ax: 193,
    ax_global: "124515,88",
    bf: "16,9",
    bf_global: "429,26",
    ct: "5,83",
    ct_global: "148,082",
    d: "43,6",
    d_global: "1107,44",
    ix: 589,
    ix_global: "245160309,7",
    iy: 2870,
    iy_global: 1194584191,
    iz: 56600,
    iz_global: 23558698689,
    od: "4,72",
    od_global: "119,888",
    rz: "2,19",
    rz_global: "55,626",
    t: 34,
    t_global: "863,6",
    tf: "3,54",
    tf_global: "89,916",
    tw: "1,97",
    tw_global: "50,038",
    zx: 3080,
    zx_global: "50472157,12",
    zy: 542,
    zy_global: "8881788,688",
    width: "16,9",
    width_global: "429,26",
    thickness: "3,54",
    thickness_global: "89,916",
    height: "43,6",
    height_global: "1107,44",
  },
  {
    profile_section_id: 11,
    name: "W44X335",
    type: "W Section",
    designation: "W44X335",
    shape: "I",
    country_code: "American",
    ax: "98,5",
    ax_global: "63548,26",
    bf: "15,9",
    bf_global: "403,86",
    ct: "5,53",
    ct_global: "140,462",
    d: 44,
    d_global: "1117,6",
    ix: "74,7",
    ix_global: "31092487,49",
    iy: 1200,
    iy_global: "499477710,7",
    iz: 31100,
    iz_global: 12944797336,
    od: "2,56",
    od_global: "65,024",
    rz: "1,31",
    rz_global: "33,274",
    t: "38,88",
    t_global: "987,552",
    tf: "1,77",
    tf_global: "44,958",
    tw: "1,03",
    tw_global: "26,162",
    zx: 1620,
    zx_global: "26547043,68",
    zy: 236,
    zy_global: "3867347,104",
    width: "15,9",
    width_global: "403,86",
    thickness: "1,77",
    thickness_global: "44,958",
    height: 44,
    height_global: "1117,6",
  },
  {
    profile_section_id: 12,
    name: "W44X290",
    type: "W Section",
    designation: "W44X290",
    shape: "I",
    country_code: "American",
    ax: "85,4",
    ax_global: "55096,664",
    bf: "15,8",
    bf_global: "401,32",
    ct: "5,26",
    ct_global: "133,604",
    d: "43,6",
    d_global: "1107,44",
    ix: "50,9",
    ix_global: "21186179,56",
    iy: 1040,
    iy_global: "432880682,6",
    iz: 27000,
    iz_global: 11238248491,
    od: "2,36",
    od_global: "59,944",
    rz: "1,25",
    rz_global: "31,75",
    t: "38,88",
    t_global: "987,552",
    tf: "1,58",
    tf_global: "40,132",
    tw: "0,86",
    tw_global: "21,844",
    zx: 1410,
    zx_global: "23105760,24",
    zy: 205,
    zy_global: "3359348,12",
    width: "15,8",
    width_global: "401,32",
    thickness: "1,58",
    thickness_global: "40,132",
    height: "43,6",
    height_global: "1107,44",
  },
  {
    profile_section_id: 13,
    name: "W44X262",
    type: "W Section",
    designation: "W44X262",
    shape: "I",
    country_code: "American",
    ax: "77,2",
    ax_global: "49806,352",
    bf: "15,8",
    bf_global: "401,32",
    ct: "5,19",
    ct_global: "131,826",
    d: "43,3",
    d_global: "1099,82",
    ix: "37,3",
    ix_global: "15525432,17",
    iy: 923,
    iy_global: "384181605,8",
    iz: 24100,
    iz_global: 10031177357,
    od: "2,2",
    od_global: "55,88",
    rz: "1,19",
    rz_global: "30,226",
    t: "38,9",
    t_global: "988,06",
    tf: "1,42",
    tf_global: "36,068",
    tw: "0,79",
    tw_global: "20,066",
    zx: 1270,
    zx_global: "20811571,28",
    zy: 182,
    zy_global: "2982445,648",
    width: "15,8",
    width_global: "401,32",
    thickness: "1,42",
    thickness_global: "36,068",
    height: "43,3",
    height_global: "1099,82",
  },
  {
    profile_section_id: 14,
    name: "W40X593",
    type: "W Section",
    designation: "W40X593",
    shape: "I",
    country_code: "American",
    ax: 174,
    ax_global: "112257,84",
    bf: "16,7",
    bf_global: "424,18",
    ct: "5,66",
    ct_global: "143,764",
    d: 43,
    d_global: "1092,2",
    ix: 445,
    ix_global: "185222984,4",
    iy: 2520,
    iy_global: 1048903193,
    iz: 50400,
    iz_global: 20978063850,
    od: "4,41",
    od_global: "112,014",
    rz: "2,13",
    rz_global: "54,102",
    t: "34,18",
    t_global: "868,172",
    tf: "3,23",
    tf_global: "82,042",
    tw: "1,79",
    tw_global: "45,466",
    zx: 2760,
    zx_global: "45228296,64",
    zy: 481,
    zy_global: "7882177,784",
    width: "16,7",
    width_global: "424,18",
    thickness: "3,23",
    thickness_global: "82,042",
    height: 43,
    height_global: "1092,2",
  },
  {
    profile_section_id: 15,
    name: "W40X503",
    type: "W Section",
    designation: "W40X503",
    shape: "I",
    country_code: "American",
    ax: 148,
    ax_global: "95483,68",
    bf: "16,4",
    bf_global: "416,56",
    ct: "5,38",
    ct_global: "136,652",
    d: "42,1",
    d_global: "1069,34",
    ix: 277,
    ix_global: "115296104,9",
    iy: 2040,
    iy_global: "849112108,2",
    iz: 41600,
    iz_global: 17315227305,
    od: "3,94",
    od_global: "100,076",
    rz: 2,
    rz_global: "50,8",
    t: "34,22",
    t_global: "869,188",
    tf: "2,76",
    tf_global: "70,104",
    tw: "1,54",
    tw_global: "39,116",
    zx: 2320,
    zx_global: "38017988,48",
    zy: 394,
    zy_global: "6456503,216",
    width: "16,4",
    width_global: "416,56",
    thickness: "2,76",
    thickness_global: "70,104",
    height: "42,1",
    height_global: "1069,34",
  },
  {
    profile_section_id: 16,
    name: "W40X431",
    type: "W Section",
    designation: "W40X431",
    shape: "I",
    country_code: "American",
    ax: 127,
    ax_global: "81935,32",
    bf: "16,2",
    bf_global: "411,48",
    ct: "5,18",
    ct_global: "131,572",
    d: "41,3",
    d_global: "1049,02",
    ix: 177,
    ix_global: "73672962,33",
    iy: 1690,
    iy_global: "703431109,3",
    iz: 34800,
    iz_global: 14484853611,
    od: "3,54",
    od_global: "89,916",
    rz: "1,88",
    rz_global: "47,752",
    t: "34,22",
    t_global: "869,188",
    tf: "2,36",
    tf_global: "59,944",
    tw: "1,34",
    tw_global: "34,036",
    zx: 1960,
    zx_global: "32118645,44",
    zy: 328,
    zy_global: "5374956,992",
    width: "16,2",
    width_global: "411,48",
    thickness: "2,36",
    thickness_global: "59,944",
    height: "41,3",
    height_global: "1049,02",
  },
  {
    profile_section_id: 17,
    name: "W40X397",
    type: "W Section",
    designation: "W40X397",
    shape: "I",
    country_code: "American",
    ax: 117,
    ax_global: "75483,72",
    bf: "16,1",
    bf_global: "408,94",
    ct: "5,03",
    ct_global: "127,762",
    d: 41,
    d_global: "1041,4",
    ix: 142,
    ix_global: "59104862,44",
    iy: 1540,
    iy_global: "640996395,4",
    iz: 32000,
    iz_global: 13319405619,
    od: "3,38",
    od_global: "85,852",
    rz: "1,81",
    rz_global: "45,974",
    t: "34,24",
    t_global: "869,696",
    tf: "2,2",
    tf_global: "55,88",
    tw: "1,22",
    tw_global: "30,988",
    zx: 1800,
    zx_global: "29496715,2",
    zy: 300,
    zy_global: "4916119,2",
    width: "16,1",
    width_global: "408,94",
    thickness: "2,2",
    thickness_global: "55,88",
    height: 41,
    height_global: "1041,4",
  },
  {
    profile_section_id: 18,
    name: "W40X372",
    type: "W Section",
    designation: "W40X372",
    shape: "I",
    country_code: "American",
    ax: 110,
    ax_global: "70967,6",
    bf: "16,1",
    bf_global: "408,94",
    ct: "4,98",
    ct_global: "126,492",
    d: "40,6",
    d_global: "1031,24",
    ix: 116,
    ix_global: "48282845,37",
    iy: 1420,
    iy_global: "591048624,4",
    iz: 29600,
    iz_global: 12320450198,
    od: "3,23",
    od_global: "82,042",
    rz: "1,81",
    rz_global: "45,974",
    t: "34,14",
    t_global: "867,156",
    tf: "2,05",
    tf_global: "52,07",
    tw: "1,16",
    tw_global: "29,464",
    zx: 1680,
    zx_global: "27530267,52",
    zy: 277,
    zy_global: "4539216,728",
    width: "16,1",
    width_global: "408,94",
    thickness: "2,05",
    thickness_global: "52,07",
    height: "40,6",
    height_global: "1031,24",
  },
  {
    profile_section_id: 19,
    name: "W40X362",
    type: "W Section",
    designation: "W40X362",
    shape: "I",
    country_code: "American",
    ax: 106,
    ax_global: "68386,96",
    bf: 16,
    bf_global: "406,4",
    ct: "4,91",
    ct_global: "124,714",
    d: "40,6",
    d_global: "1031,24",
    ix: 109,
    ix_global: "45369225,39",
    iy: 1380,
    iy_global: "574399367,3",
    iz: 28900,
    iz_global: 12029088200,
    od: "3,19",
    od_global: "81,026",
    rz: "1,75",
    rz_global: "44,45",
    t: "34,22",
    t_global: "869,188",
    tf: "2,01",
    tf_global: "51,054",
    tw: "1,12",
    tw_global: "28,448",
    zx: 1640,
    zx_global: "26874784,96",
    zy: 270,
    zy_global: "4424507,28",
    width: 16,
    width_global: "406,4",
    thickness: "2,01",
    thickness_global: "51,054",
    height: "40,6",
    height_global: "1031,24",
  },
  {
    profile_section_id: 20,
    name: "W40X324",
    type: "W Section",
    designation: "W40X324",
    shape: "I",
    country_code: "American",
    ax: "95,3",
    ax_global: "61483,748",
    bf: "15,9",
    bf_global: "403,86",
    ct: "4,77",
    ct_global: "121,158",
    d: "40,2",
    d_global: "1021,08",
    ix: "79,4",
    ix_global: "33048775,19",
    iy: 1220,
    iy_global: "507802339,2",
    iz: 25600,
    iz_global: 10655524495,
    od: "2,99",
    od_global: "75,946",
    rz: "1,69",
    rz_global: "42,926",
    t: "34,22",
    t_global: "869,188",
    tf: "1,81",
    tf_global: "45,974",
    tw: 1,
    tw_global: "25,4",
    zx: 1460,
    zx_global: "23925113,44",
    zy: 239,
    zy_global: "3916508,296",
    width: "15,9",
    width_global: "403,86",
    thickness: "1,81",
    thickness_global: "45,974",
    height: "40,2",
    height_global: "1021,08",
  },
  {
    profile_section_id: 21,
    name: "W40X297",
    type: "W Section",
    designation: "W40X297",
    shape: "I",
    country_code: "American",
    ax: "87,3",
    ax_global: "56322,468",
    bf: "15,8",
    bf_global: "401,32",
    ct: "4,71",
    ct_global: "119,634",
    d: "39,8",
    d_global: "1010,92",
    ix: "61,2",
    ix_global: "25473363,25",
    iy: 1090,
    iy_global: "453692253,9",
    iz: 23200,
    iz_global: 9656569074,
    od: "2,83",
    od_global: "71,882",
    rz: "1,69",
    rz_global: "42,926",
    t: "34,14",
    t_global: "867,156",
    tf: "1,65",
    tf_global: "41,91",
    tw: "0,93",
    tw_global: "23,622",
    zx: 1330,
    zx_global: "21794795,12",
    zy: 215,
    zy_global: "3523218,76",
    width: "15,8",
    width_global: "401,32",
    thickness: "1,65",
    thickness_global: "41,91",
    height: "39,8",
    height_global: "1010,92",
  },
  {
    profile_section_id: 22,
    name: "W40X277",
    type: "W Section",
    designation: "W40X277",
    shape: "I",
    country_code: "American",
    ax: "81,5",
    ax_global: "52580,54",
    bf: "15,8",
    bf_global: "401,32",
    ct: "4,5",
    ct_global: "114,3",
    d: "39,7",
    d_global: "1008,38",
    ix: "51,5",
    ix_global: "21435918,42",
    iy: 1040,
    iy_global: "432880682,6",
    iz: 21900,
    iz_global: 9115468221,
    od: "2,76",
    od_global: "70,104",
    rz: "1,63",
    rz_global: "41,402",
    t: "34,18",
    t_global: "868,172",
    tf: "1,58",
    tf_global: "40,132",
    tw: "0,83",
    tw_global: "21,082",
    zx: 1250,
    zx_global: 20483830,
    zy: 204,
    zy_global: "3342961,056",
    width: "15,8",
    width_global: "401,32",
    thickness: "1,58",
    thickness_global: "40,132",
    height: "39,7",
    height_global: "1008,38",
  },
  {
    profile_section_id: 23,
    name: "W40X249",
    type: "W Section",
    designation: "W40X249",
    shape: "I",
    country_code: "American",
    ax: "73,5",
    ax_global: "47419,26",
    bf: "15,8",
    bf_global: "401,32",
    ct: "4,41",
    ct_global: "112,014",
    d: "39,4",
    d_global: "1000,76",
    ix: "38,1",
    ix_global: "15858417,32",
    iy: 926,
    iy_global: "385430300,1",
    iz: 19600,
    iz_global: 8158135942,
    od: "2,6",
    od_global: "66,04",
    rz: "1,56",
    rz_global: "39,624",
    t: "34,2",
    t_global: "868,68",
    tf: "1,42",
    tf_global: "36,068",
    tw: "0,75",
    tw_global: "19,05",
    zx: 1120,
    zx_global: "18353511,68",
    zy: 182,
    zy_global: "2982445,648",
    width: "15,8",
    width_global: "401,32",
    thickness: "1,42",
    thickness_global: "36,068",
    height: "39,4",
    height_global: "1000,76",
  },
  {
    profile_section_id: 24,
    name: "W40X215",
    type: "W Section",
    designation: "W40X215",
    shape: "I",
    country_code: "American",
    ax: "63,5",
    ax_global: "40967,66",
    bf: "15,8",
    bf_global: "401,32",
    ct: "4,28",
    ct_global: "108,712",
    d: 39,
    d_global: "990,6",
    ix: "24,8",
    ix_global: "10322539,35",
    iy: 803,
    iy_global: "334233834,8",
    iz: 16700,
    iz_global: 6951064808,
    od: "2,4",
    od_global: "60,96",
    rz: "1,56",
    rz_global: "39,624",
    t: "34,2",
    t_global: "868,68",
    tf: "1,22",
    tf_global: "30,988",
    tw: "0,65",
    tw_global: "16,51",
    zx: 964,
    zx_global: "15797129,7",
    zy: 156,
    zy_global: "2556381,984",
    width: "15,8",
    width_global: "401,32",
    thickness: "1,22",
    thickness_global: "30,988",
    height: 39,
    height_global: "990,6",
  },
  {
    profile_section_id: 25,
    name: "W40X199",
    type: "W Section",
    designation: "W40X199",
    shape: "I",
    country_code: "American",
    ax: "58,8",
    ax_global: "37935,408",
    bf: "15,8",
    bf_global: "401,32",
    ct: "4,47",
    ct_global: "113,538",
    d: "38,7",
    d_global: "982,98",
    ix: "18,3",
    ix_global: "7617035,088",
    iy: 695,
    iy_global: "289280840,8",
    iz: 14900,
    iz_global: 6201848241,
    od: "2,25",
    od_global: "57,15",
    rz: "1,56",
    rz_global: "39,624",
    t: "34,2",
    t_global: "868,68",
    tf: "1,07",
    tf_global: "27,178",
    tw: "0,65",
    tw_global: "16,51",
    zx: 869,
    zx_global: "14240358,62",
    zy: 137,
    zy_global: "2245027,768",
    width: "15,8",
    width_global: "401,32",
    thickness: "1,07",
    thickness_global: "27,178",
    height: "38,7",
    height_global: "982,98",
  },
  {
    profile_section_id: 26,
    name: "W40X392",
    type: "W Section",
    designation: "W40X392",
    shape: "I",
    country_code: "American",
    ax: 116,
    ax_global: "74838,56",
    bf: "12,4",
    bf_global: "314,96",
    ct: "5,94",
    ct_global: "150,876",
    d: "41,6",
    d_global: "1056,64",
    ix: 172,
    ix_global: "71591805,2",
    iy: 803,
    iy_global: "334233834,8",
    iz: 29900,
    iz_global: 12445319625,
    od: "3,7",
    od_global: "93,98",
    rz: "1,94",
    rz_global: "49,276",
    t: "34,2",
    t_global: "868,68",
    tf: "2,52",
    tf_global: "64,008",
    tw: "1,42",
    tw_global: "36,068",
    zx: 1710,
    zx_global: "28021879,44",
    zy: 212,
    zy_global: "3474057,568",
    width: "12,4",
    width_global: "314,96",
    thickness: "2,52",
    thickness_global: "64,008",
    height: "41,6",
    height_global: "1056,64",
  },
  {
    profile_section_id: 27,
    name: "W40X331",
    type: "W Section",
    designation: "W40X331",
    shape: "I",
    country_code: "American",
    ax: "97,7",
    ax_global: "63032,132",
    bf: "12,2",
    bf_global: "309,88",
    ct: "5,74",
    ct_global: "145,796",
    d: "40,8",
    d_global: "1036,32",
    ix: 105,
    ix_global: "43704299,69",
    iy: 644,
    iy_global: "268053038,1",
    iz: 24700,
    iz_global: 10280916212,
    od: "3,31",
    od_global: "84,074",
    rz: "1,81",
    rz_global: "45,974",
    t: "34,18",
    t_global: "868,172",
    tf: "2,13",
    tf_global: "54,102",
    tw: "1,22",
    tw_global: "30,988",
    zx: 1430,
    zx_global: "23433501,52",
    zy: 172,
    zy_global: "2818575,008",
    width: "12,2",
    width_global: "309,88",
    thickness: "2,13",
    thickness_global: "54,102",
    height: "40,8",
    height_global: "1036,32",
  },
  {
    profile_section_id: 28,
    name: "W40X327",
    type: "W Section",
    designation: "W40X327",
    shape: "I",
    country_code: "American",
    ax: "95,9",
    ax_global: "61870,844",
    bf: "12,1",
    bf_global: "307,34",
    ct: "5,66",
    ct_global: "143,764",
    d: "40,8",
    d_global: "1036,32",
    ix: 103,
    ix_global: "42871836,84",
    iy: 640,
    iy_global: "266388112,4",
    iz: 24500,
    iz_global: 10197669927,
    od: "3,31",
    od_global: "84,074",
    rz: "1,81",
    rz_global: "45,974",
    t: "34,18",
    t_global: "868,172",
    tf: "2,13",
    tf_global: "54,102",
    tw: "1,18",
    tw_global: "29,972",
    zx: 1410,
    zx_global: "23105760,24",
    zy: 170,
    zy_global: "2785800,88",
    width: "12,1",
    width_global: "307,34",
    thickness: "2,13",
    thickness_global: "54,102",
    height: "40,8",
    height_global: "1036,32",
  },
  {
    profile_section_id: 29,
    name: "W40X294",
    type: "W Section",
    designation: "W40X294",
    shape: "I",
    country_code: "American",
    ax: "86,2",
    ax_global: "55612,792",
    bf: 12,
    bf_global: "304,8",
    ct: "5,51",
    ct_global: "139,954",
    d: "40,4",
    d_global: "1026,16",
    ix: "76,6",
    ix_global: "31883327,2",
    iy: 562,
    iy_global: "233922061,2",
    iz: 21900,
    iz_global: 9115468221,
    od: "3,11",
    od_global: "78,994",
    rz: "1,75",
    rz_global: "44,45",
    t: "34,18",
    t_global: "868,172",
    tf: "1,93",
    tf_global: "49,022",
    tw: "1,06",
    tw_global: "26,924",
    zx: 1270,
    zx_global: "20811571,28",
    zy: 150,
    zy_global: "2458059,6",
    width: 12,
    width_global: "304,8",
    thickness: "1,93",
    thickness_global: "49,022",
    height: "40,4",
    height_global: "1026,16",
  },
  {
    profile_section_id: 30,
    name: "W40X278",
    type: "W Section",
    designation: "W40X278",
    shape: "I",
    country_code: "American",
    ax: "82,3",
    ax_global: "53096,668",
    bf: 12,
    bf_global: "304,8",
    ct: "5,51",
    ct_global: "139,954",
    d: "40,2",
    d_global: "1021,08",
    ix: 65,
    ix_global: "27055042,66",
    iy: 521,
    iy_global: "216856572,7",
    iz: 20500,
    iz_global: 8532744225,
    od: "2,99",
    od_global: "75,946",
    rz: "1,75",
    rz_global: "44,45",
    t: "34,22",
    t_global: "869,188",
    tf: "1,81",
    tf_global: "45,974",
    tw: "1,03",
    tw_global: "26,162",
    zx: 1190,
    zx_global: "19500606,16",
    zy: 140,
    zy_global: "2294188,96",
    width: 12,
    width_global: "304,8",
    thickness: "1,81",
    thickness_global: "45,974",
    height: "40,2",
    height_global: "1021,08",
  },
  {
    profile_section_id: 31,
    name: "W40X264",
    type: "W Section",
    designation: "W40X264",
    shape: "I",
    country_code: "American",
    ax: "77,4",
    ax_global: "49935,384",
    bf: "11,9",
    bf_global: "302,26",
    ct: "5,41",
    ct_global: "137,414",
    d: 40,
    d_global: 1016,
    ix: "56,1",
    ix_global: "23350582,98",
    iy: 493,
    iy_global: "205202092,8",
    iz: 19400,
    iz_global: 8074889657,
    od: "2,91",
    od_global: "73,914",
    rz: "1,69",
    rz_global: "42,926",
    t: "34,18",
    t_global: "868,172",
    tf: "1,73",
    tf_global: "43,942",
    tw: "0,96",
    tw_global: "24,384",
    zx: 1130,
    zx_global: "18517382,32",
    zy: 132,
    zy_global: "2163092,448",
    width: "11,9",
    width_global: "302,26",
    thickness: "1,73",
    thickness_global: "43,942",
    height: 40,
    height_global: 1016,
  },
  {
    profile_section_id: 32,
    name: "W40X235",
    type: "W Section",
    designation: "W40X235",
    shape: "I",
    country_code: "American",
    ax: "69,1",
    ax_global: "44580,556",
    bf: "11,9",
    bf_global: "302,26",
    ct: "5,17",
    ct_global: "131,318",
    d: "39,7",
    d_global: "1008,38",
    ix: "41,3",
    ix_global: "17190357,88",
    iy: 444,
    iy_global: 184806753,
    iz: 17400,
    iz_global: 7242426805,
    od: "2,76",
    od_global: "70,104",
    rz: "1,63",
    rz_global: "41,402",
    t: "34,18",
    t_global: "868,172",
    tf: "1,58",
    tf_global: "40,132",
    tw: "0,83",
    tw_global: "21,082",
    zx: 1010,
    zx_global: "16550934,64",
    zy: 118,
    zy_global: "1933673,552",
    width: "11,9",
    width_global: "302,26",
    thickness: "1,58",
    thickness_global: "40,132",
    height: "39,7",
    height_global: "1008,38",
  },
  {
    profile_section_id: 33,
    name: "W40X211",
    type: "W Section",
    designation: "W40X211",
    shape: "I",
    country_code: "American",
    ax: "62,1",
    ax_global: "40064,436",
    bf: "11,8",
    bf_global: "299,72",
    ct: "5,08",
    ct_global: "129,032",
    d: "39,4",
    d_global: "1000,76",
    ix: "30,4",
    ix_global: "12653435,34",
    iy: 390,
    iy_global: 162330256,
    iz: 15500,
    iz_global: 6451587097,
    od: "2,6",
    od_global: "66,04",
    rz: "1,56",
    rz_global: "39,624",
    t: "34,2",
    t_global: "868,68",
    tf: "1,42",
    tf_global: "36,068",
    tw: "0,75",
    tw_global: "19,05",
    zx: 906,
    zx_global: "14846679,98",
    zy: 105,
    zy_global: "1720641,72",
    width: "11,8",
    width_global: "299,72",
    thickness: "1,42",
    thickness_global: "36,068",
    height: "39,4",
    height_global: "1000,76",
  },
  {
    profile_section_id: 34,
    name: "W40X183",
    type: "W Section",
    designation: "W40X183",
    shape: "I",
    country_code: "American",
    ax: "53,3",
    ax_global: "34387,028",
    bf: "11,8",
    bf_global: "299,72",
    ct: "4,97",
    ct_global: "126,238",
    d: 39,
    d_global: "990,6",
    ix: "19,3",
    ix_global: "8033266,514",
    iy: 331,
    iy_global: "137772601,9",
    iz: 13200,
    iz_global: 5494254818,
    od: "2,38",
    od_global: "60,452",
    rz: "1,56",
    rz_global: "39,624",
    t: "34,24",
    t_global: "869,696",
    tf: "1,2",
    tf_global: "30,48",
    tw: "0,65",
    tw_global: "16,51",
    zx: 774,
    zx_global: "12683587,54",
    zy: "88,3",
    zy_global: "1446977,751",
    width: "11,8",
    width_global: "299,72",
    thickness: "1,2",
    thickness_global: "30,48",
    height: 39,
    height_global: "990,6",
  },
  {
    profile_section_id: 35,
    name: "W40X167",
    type: "W Section",
    designation: "W40X167",
    shape: "I",
    country_code: "American",
    ax: "49,3",
    ax_global: "31806,388",
    bf: "11,8",
    bf_global: "299,72",
    ct: "5,19",
    ct_global: "131,826",
    d: "38,6",
    d_global: "980,44",
    ix: 14,
    ix_global: "5827239,958",
    iy: 283,
    iy_global: "117793493,4",
    iz: 11600,
    iz_global: 4828284537,
    od: "2,21",
    od_global: "56,134",
    rz: "1,56",
    rz_global: "39,624",
    t: "34,18",
    t_global: "868,172",
    tf: "1,03",
    tf_global: "26,162",
    tw: "0,65",
    tw_global: "16,51",
    zx: 693,
    zx_global: "11356235,35",
    zy: 76,
    zy_global: "1245416,864",
    width: "11,8",
    width_global: "299,72",
    thickness: "1,03",
    thickness_global: "26,162",
    height: "38,6",
    height_global: "980,44",
  },
  {
    profile_section_id: 36,
    name: "W40X149",
    type: "W Section",
    designation: "W40X149",
    shape: "I",
    country_code: "American",
    ax: "43,8",
    ax_global: "28258,008",
    bf: "11,8",
    bf_global: "299,72",
    ct: "5,45",
    ct_global: "138,43",
    d: "38,2",
    d_global: "970,28",
    ix: "9,36",
    ix_global: "3895926,144",
    iy: 229,
    iy_global: "95316996,46",
    iz: 9800,
    iz_global: 4079067971,
    od: "2,01",
    od_global: "51,054",
    rz: "1,5",
    rz_global: "38,1",
    t: "34,18",
    t_global: "868,172",
    tf: "0,83",
    tf_global: "21,082",
    tw: "0,63",
    tw_global: "16,002",
    zx: 598,
    zx_global: "9799464,272",
    zy: "62,2",
    zy_global: "1019275,381",
    width: "11,8",
    width_global: "299,72",
    thickness: "0,83",
    thickness_global: "21,082",
    height: "38,2",
    height_global: "970,28",
  },
  {
    profile_section_id: 37,
    name: "W36X652",
    type: "W Section",
    designation: "W36X652",
    shape: "I",
    country_code: "American",
    ax: 192,
    ax_global: "123870,72",
    bf: "17,6",
    bf_global: "447,04",
    ct: "5,35",
    ct_global: "135,89",
    d: "41,1",
    d_global: "1043,94",
    ix: 593,
    ix_global: "246825235,4",
    iy: 3230,
    iy_global: 1344427505,
    iz: 50600,
    iz_global: 21061310135,
    od: "4,49",
    od_global: "114,046",
    rz: "2,19",
    rz_global: "55,626",
    t: "32,12",
    t_global: "815,848",
    tf: "3,54",
    tf_global: "89,916",
    tw: "1,97",
    tw_global: "50,038",
    zx: 2910,
    zx_global: "47686356,24",
    zy: 581,
    zy_global: "9520884,184",
    width: "17,6",
    width_global: "447,04",
    thickness: "3,54",
    thickness_global: "89,916",
    height: "41,1",
    height_global: "1043,94",
  },
  {
    profile_section_id: 38,
    name: "W36X529",
    type: "W Section",
    designation: "W36X529",
    shape: "I",
    country_code: "American",
    ax: 156,
    ax_global: "100644,96",
    bf: "17,2",
    bf_global: "436,88",
    ct: "4,96",
    ct_global: "125,984",
    d: "39,8",
    d_global: "1010,92",
    ix: 327,
    ix_global: "136107676,2",
    iy: 2490,
    iy_global: 1036416250,
    iz: 39600,
    iz_global: 16482764454,
    od: "3,86",
    od_global: "98,044",
    rz: 2,
    rz_global: "50,8",
    t: "32,08",
    t_global: "814,832",
    tf: "2,91",
    tf_global: "73,914",
    tw: "1,61",
    tw_global: "40,894",
    zx: 2330,
    zx_global: "38181859,12",
    zy: 454,
    zy_global: "7439727,056",
    width: "17,2",
    width_global: "436,88",
    thickness: "2,91",
    thickness_global: "73,914",
    height: "39,8",
    height_global: "1010,92",
  },
  {
    profile_section_id: 39,
    name: "W36X487",
    type: "W Section",
    designation: "W36X487",
    shape: "I",
    country_code: "American",
    ax: 143,
    ax_global: "92257,88",
    bf: "17,1",
    bf_global: "434,34",
    ct: "4,84",
    ct_global: "122,936",
    d: "39,3",
    d_global: "998,22",
    ix: 258,
    ix_global: "107387707,8",
    iy: 2250,
    iy_global: "936520707,6",
    iz: 36000,
    iz_global: 14984331322,
    od: "3,63",
    od_global: "92,202",
    rz: "1,88",
    rz_global: "47,752",
    t: "32,04",
    t_global: "813,816",
    tf: "2,68",
    tf_global: "68,072",
    tw: "1,5",
    tw_global: "38,1",
    zx: 2130,
    zx_global: "34904446,32",
    zy: 412,
    zy_global: "6751470,368",
    width: "17,1",
    width_global: "434,34",
    thickness: "2,68",
    thickness_global: "68,072",
    height: "39,3",
    height_global: "998,22",
  },
  {
    profile_section_id: 40,
    name: "W36X441",
    type: "W Section",
    designation: "W36X441",
    shape: "I",
    country_code: "American",
    ax: 130,
    ax_global: "83870,8",
    bf: 17,
    bf_global: "431,8",
    ct: "4,69",
    ct_global: "119,126",
    d: "38,9",
    d_global: "988,06",
    ix: 194,
    ix_global: "80748896,57",
    iy: 1990,
    iy_global: "828300536,9",
    iz: 32100,
    iz_global: 13361028762,
    od: "3,39",
    od_global: "86,106",
    rz: "1,88",
    rz_global: "47,752",
    t: "32,12",
    t_global: "815,848",
    tf: "2,44",
    tf_global: "61,976",
    tw: "1,36",
    tw_global: "34,544",
    zx: 1910,
    zx_global: "31299292,24",
    zy: 368,
    zy_global: "6030439,552",
    width: 17,
    width_global: "431,8",
    thickness: "2,44",
    thickness_global: "61,976",
    height: "38,9",
    height_global: "988,06",
  },
  {
    profile_section_id: 41,
    name: "W36X395",
    type: "W Section",
    designation: "W36X395",
    shape: "I",
    country_code: "American",
    ax: 116,
    ax_global: "74838,56",
    bf: "16,8",
    bf_global: "426,72",
    ct: "4,53",
    ct_global: "115,062",
    d: "38,4",
    d_global: "975,36",
    ix: 142,
    ix_global: "59104862,44",
    iy: 1750,
    iy_global: "728404994,8",
    iz: 28500,
    iz_global: 11862595630,
    od: "3,15",
    od_global: "80,01",
    rz: "1,81",
    rz_global: "45,974",
    t: "32,1",
    t_global: "815,34",
    tf: "2,2",
    tf_global: "55,88",
    tw: "1,22",
    tw_global: "30,988",
    zx: 1710,
    zx_global: "28021879,44",
    zy: 325,
    zy_global: "5325795,8",
    width: "16,8",
    width_global: "426,72",
    thickness: "2,2",
    thickness_global: "55,88",
    height: "38,4",
    height_global: "975,36",
  },
  {
    profile_section_id: 42,
    name: "W36X361",
    type: "W Section",
    designation: "W36X361",
    shape: "I",
    country_code: "American",
    ax: 106,
    ax_global: "68386,96",
    bf: "16,7",
    bf_global: "424,18",
    ct: "4,42",
    ct_global: "112,268",
    d: 38,
    d_global: "965,2",
    ix: 109,
    ix_global: "45369225,39",
    iy: 1570,
    iy_global: "653483338,2",
    iz: 25700,
    iz_global: 10697147638,
    od: "2,96",
    od_global: "75,184",
    rz: "1,75",
    rz_global: "44,45",
    t: "32,08",
    t_global: "814,832",
    tf: "2,01",
    tf_global: "51,054",
    tw: "1,12",
    tw_global: "28,448",
    zx: 1550,
    zx_global: "25399949,2",
    zy: 293,
    zy_global: "4801409,752",
    width: "16,7",
    width_global: "424,18",
    thickness: "2,01",
    thickness_global: "51,054",
    height: 38,
    height_global: "965,2",
  },
  {
    profile_section_id: 43,
    name: "W36X330",
    type: "W Section",
    designation: "W36X330",
    shape: "I",
    country_code: "American",
    ax: "96,9",
    ax_global: "62516,004",
    bf: "16,6",
    bf_global: "421,64",
    ct: "4,3",
    ct_global: "109,22",
    d: "37,7",
    d_global: "957,58",
    ix: "84,3",
    ix_global: "35088309,18",
    iy: 1420,
    iy_global: "591048624,4",
    iz: 23300,
    iz_global: 9698192216,
    od: "2,8",
    od_global: "71,12",
    rz: "1,75",
    rz_global: "44,45",
    t: "32,1",
    t_global: "815,34",
    tf: "1,85",
    tf_global: "46,99",
    tw: "1,02",
    tw_global: "25,908",
    zx: 1410,
    zx_global: "23105760,24",
    zy: 265,
    zy_global: "4342571,96",
    width: "16,6",
    width_global: "421,64",
    thickness: "1,85",
    thickness_global: "46,99",
    height: "37,7",
    height_global: "957,58",
  },
  {
    profile_section_id: 44,
    name: "W36X302",
    type: "W Section",
    designation: "W36X302",
    shape: "I",
    country_code: "American",
    ax: 89,
    ax_global: "57419,24",
    bf: "16,7",
    bf_global: "424,18",
    ct: "4,22",
    ct_global: "107,188",
    d: "37,3",
    d_global: "947,42",
    ix: "64,3",
    ix_global: "26763680,67",
    iy: 1300,
    iy_global: "541100853,3",
    iz: 21100,
    iz_global: 8782483080,
    od: "2,63",
    od_global: "66,802",
    rz: "1,69",
    rz_global: "42,926",
    t: "32,04",
    t_global: "813,816",
    tf: "1,68",
    tf_global: "42,672",
    tw: "0,94",
    tw_global: "23,876",
    zx: 1280,
    zx_global: "20975441,92",
    zy: 241,
    zy_global: "3949282,424",
    width: "16,7",
    width_global: "424,18",
    thickness: "1,68",
    thickness_global: "42,672",
    height: "37,3",
    height_global: "947,42",
  },
  {
    profile_section_id: 45,
    name: "W36X282",
    type: "W Section",
    designation: "W36X282",
    shape: "I",
    country_code: "American",
    ax: "82,9",
    ax_global: "53483,764",
    bf: "16,6",
    bf_global: "421,64",
    ct: "4,16",
    ct_global: "105,664",
    d: "37,1",
    d_global: "942,34",
    ix: "52,7",
    ix_global: "21935396,13",
    iy: 1200,
    iy_global: "499477710,7",
    iz: 19600,
    iz_global: 8158135942,
    od: "2,52",
    od_global: "64,008",
    rz: "1,63",
    rz_global: "41,402",
    t: "32,06",
    t_global: "814,324",
    tf: "1,57",
    tf_global: "39,878",
    tw: "0,89",
    tw_global: "22,606",
    zx: 1190,
    zx_global: "19500606,16",
    zy: 223,
    zy_global: "3654315,272",
    width: "16,6",
    width_global: "421,64",
    thickness: "1,57",
    thickness_global: "39,878",
    height: "37,1",
    height_global: "942,34",
  },
  {
    profile_section_id: 46,
    name: "W36X262",
    type: "W Section",
    designation: "W36X262",
    shape: "I",
    country_code: "American",
    ax: "77,2",
    ax_global: "49806,352",
    bf: "16,6",
    bf_global: "421,64",
    ct: "4,14",
    ct_global: "105,156",
    d: "36,9",
    d_global: "937,26",
    ix: "41,6",
    ix_global: "17315227,3",
    iy: 1090,
    iy_global: "453692253,9",
    iz: 17900,
    iz_global: 7450542518,
    od: "2,39",
    od_global: "60,706",
    rz: "1,63",
    rz_global: "41,402",
    t: "32,12",
    t_global: "815,848",
    tf: "1,44",
    tf_global: "36,576",
    tw: "0,84",
    tw_global: "21,336",
    zx: 1100,
    zx_global: "18025770,4",
    zy: 204,
    zy_global: "3342961,056",
    width: "16,6",
    width_global: "421,64",
    thickness: "1,44",
    thickness_global: "36,576",
    height: "36,9",
    height_global: "937,26",
  },
  {
    profile_section_id: 47,
    name: "W36X247",
    type: "W Section",
    designation: "W36X247",
    shape: "I",
    country_code: "American",
    ax: "72,5",
    ax_global: "46774,1",
    bf: "16,5",
    bf_global: "419,1",
    ct: "4,12",
    ct_global: "104,648",
    d: "36,7",
    d_global: "932,18",
    ix: "34,7",
    ix_global: "14443230,47",
    iy: 1010,
    iy_global: "420393739,9",
    iz: 16700,
    iz_global: 6951064808,
    od: "2,3",
    od_global: "58,42",
    rz: "1,63",
    rz_global: "41,402",
    t: "32,1",
    t_global: "815,34",
    tf: "1,35",
    tf_global: "34,29",
    tw: "0,8",
    tw_global: "20,32",
    zx: 1030,
    zx_global: "16878675,92",
    zy: 190,
    zy_global: "3113542,16",
    width: "16,5",
    width_global: "419,1",
    thickness: "1,35",
    thickness_global: "34,29",
    height: "36,7",
    height_global: "932,18",
  },
  {
    profile_section_id: 48,
    name: "W36X231",
    type: "W Section",
    designation: "W36X231",
    shape: "I",
    country_code: "American",
    ax: "68,2",
    ax_global: "43999,912",
    bf: "16,5",
    bf_global: "419,1",
    ct: "4,1",
    ct_global: "104,14",
    d: "36,5",
    d_global: "927,1",
    ix: "28,7",
    ix_global: "11945841,91",
    iy: 940,
    iy_global: "391257540,1",
    iz: 15600,
    iz_global: 6493210239,
    od: "2,21",
    od_global: "56,134",
    rz: "1,56",
    rz_global: "39,624",
    t: "32,08",
    t_global: "814,832",
    tf: "1,26",
    tf_global: "32,004",
    tw: "0,76",
    tw_global: "19,304",
    zx: 963,
    zx_global: "15780742,63",
    zy: 176,
    zy_global: "2884123,264",
    width: "16,5",
    width_global: "419,1",
    thickness: "1,26",
    thickness_global: "32,004",
    height: "36,5",
    height_global: "927,1",
  },
  {
    profile_section_id: 49,
    name: "W36X256",
    type: "W Section",
    designation: "W36X256",
    shape: "I",
    country_code: "American",
    ax: "75,3",
    ax_global: "48580,548",
    bf: "12,2",
    bf_global: "309,88",
    ct: "4,92",
    ct_global: "124,968",
    d: "37,4",
    d_global: "949,96",
    ix: "52,9",
    ix_global: "22018642,41",
    iy: 528,
    iy_global: "219770192,7",
    iz: 16800,
    iz_global: 6992687950,
    od: "2,48",
    od_global: "62,992",
    rz: "1,31",
    rz_global: "33,274",
    t: "32,44",
    t_global: "823,976",
    tf: "1,73",
    tf_global: "43,942",
    tw: "0,96",
    tw_global: "24,384",
    zx: 1040,
    zx_global: "17042546,56",
    zy: 137,
    zy_global: "2245027,768",
    width: "12,2",
    width_global: "309,88",
    thickness: "1,73",
    thickness_global: "43,942",
    height: "37,4",
    height_global: "949,96",
  },
  {
    profile_section_id: 50,
    name: "W36X232",
    type: "W Section",
    designation: "W36X232",
    shape: "I",
    country_code: "American",
    ax: 68,
    ax_global: "43870,88",
    bf: "12,1",
    bf_global: "307,34",
    ct: "4,82",
    ct_global: "122,428",
    d: "37,1",
    d_global: "942,34",
    ix: "39,6",
    ix_global: "16482764,45",
    iy: 468,
    iy_global: "194796307,2",
    iz: 15000,
    iz_global: 6243471384,
    od: "2,32",
    od_global: "58,928",
    rz: "1,25",
    rz_global: "31,75",
    t: "32,46",
    t_global: "824,484",
    tf: "1,57",
    tf_global: "39,878",
    tw: "0,87",
    tw_global: "22,098",
    zx: 936,
    zx_global: "15338291,9",
    zy: 122,
    zy_global: "1999221,808",
    width: "12,1",
    width_global: "307,34",
    thickness: "1,57",
    thickness_global: "39,878",
    height: "37,1",
    height_global: "942,34",
  },
  {
    profile_section_id: 51,
    name: "W36X210",
    type: "W Section",
    designation: "W36X210",
    shape: "I",
    country_code: "American",
    ax: "61,9",
    ax_global: "39935,404",
    bf: "12,2",
    bf_global: "309,88",
    ct: "4,87",
    ct_global: "123,698",
    d: "36,7",
    d_global: "932,18",
    ix: 28,
    ix_global: "11654479,92",
    iy: 411,
    iy_global: "171071115,9",
    iz: 13200,
    iz_global: 5494254818,
    od: "2,11",
    od_global: "53,594",
    rz: "1,25",
    rz_global: "31,75",
    t: "32,48",
    t_global: "824,992",
    tf: "1,36",
    tf_global: "34,544",
    tw: "0,83",
    tw_global: "21,082",
    zx: 833,
    zx_global: "13650424,31",
    zy: 107,
    zy_global: "1753415,848",
    width: "12,2",
    width_global: "309,88",
    thickness: "1,36",
    thickness_global: "34,544",
    height: "36,7",
    height_global: "932,18",
  },
  {
    profile_section_id: 52,
    name: "W36X194",
    type: "W Section",
    designation: "W36X194",
    shape: "I",
    country_code: "American",
    ax: 57,
    ax_global: "36774,12",
    bf: "12,1",
    bf_global: "307,34",
    ct: "4,8",
    ct_global: "121,92",
    d: "36,5",
    d_global: "927,1",
    ix: "22,2",
    ix_global: "9240337,648",
    iy: 375,
    iy_global: "156086784,6",
    iz: 12100,
    iz_global: 5036400250,
    od: "2,01",
    od_global: "51,054",
    rz: "1,19",
    rz_global: "30,226",
    t: "32,48",
    t_global: "824,992",
    tf: "1,26",
    tf_global: "32,004",
    tw: "0,77",
    tw_global: "19,558",
    zx: 767,
    zx_global: "12568878,09",
    zy: "97,7",
    zy_global: "1601016,153",
    width: "12,1",
    width_global: "307,34",
    thickness: "1,26",
    thickness_global: "32,004",
    height: "36,5",
    height_global: "927,1",
  },
  {
    profile_section_id: 53,
    name: "W36X182",
    type: "W Section",
    designation: "W36X182",
    shape: "I",
    country_code: "American",
    ax: "53,6",
    ax_global: "34580,576",
    bf: "12,1",
    bf_global: "307,34",
    ct: "4,77",
    ct_global: "121,158",
    d: "36,3",
    d_global: "922,02",
    ix: "18,5",
    ix_global: "7700281,374",
    iy: 347,
    iy_global: "144432304,7",
    iz: 11300,
    iz_global: 4703415109,
    od: "1,93",
    od_global: "49,022",
    rz: "1,19",
    rz_global: "30,226",
    t: "32,44",
    t_global: "823,976",
    tf: "1,18",
    tf_global: "29,972",
    tw: "0,72",
    tw_global: "18,288",
    zx: 718,
    zx_global: "11765911,95",
    zy: "90,7",
    zy_global: "1486306,705",
    width: "12,1",
    width_global: "307,34",
    thickness: "1,18",
    thickness_global: "29,972",
    height: "36,3",
    height_global: "922,02",
  },
  {
    profile_section_id: 54,
    name: "W36X170",
    type: "W Section",
    designation: "W36X170",
    shape: "I",
    country_code: "American",
    ax: 50,
    ax_global: 32258,
    bf: 12,
    bf_global: "304,8",
    ct: "4,73",
    ct_global: "120,142",
    d: "36,2",
    d_global: "919,48",
    ix: "15,1",
    ix_global: "6285094,527",
    iy: 320,
    iy_global: "133194056,2",
    iz: 10500,
    iz_global: 4370429969,
    od: "1,85",
    od_global: "46,99",
    rz: "1,19",
    rz_global: "30,226",
    t: "32,5",
    t_global: "825,5",
    tf: "1,1",
    tf_global: "27,94",
    tw: "0,68",
    tw_global: "17,272",
    zx: 668,
    zx_global: "10946558,75",
    zy: "83,8",
    zy_global: "1373235,963",
    width: 12,
    width_global: "304,8",
    thickness: "1,1",
    thickness_global: "27,94",
    height: "36,2",
    height_global: "919,48",
  },
  {
    profile_section_id: 55,
    name: "W36X160",
    type: "W Section",
    designation: "W36X160",
    shape: "I",
    country_code: "American",
    ax: 47,
    ax_global: "30322,52",
    bf: 12,
    bf_global: "304,8",
    ct: "4,74",
    ct_global: "120,396",
    d: 36,
    d_global: "914,4",
    ix: "12,4",
    ix_global: "5161269,677",
    iy: 295,
    iy_global: "122788270,6",
    iz: 9760,
    iz_global: 4062418714,
    od: "1,77",
    od_global: "44,958",
    rz: "1,13",
    rz_global: "28,702",
    t: "32,46",
    t_global: "824,484",
    tf: "1,02",
    tf_global: "25,908",
    tw: "0,65",
    tw_global: "16,51",
    zx: 624,
    zx_global: "10225527,94",
    zy: "77,3",
    zy_global: "1266720,047",
    width: 12,
    width_global: "304,8",
    thickness: "1,02",
    thickness_global: "25,908",
    height: 36,
    height_global: "914,4",
  },
  {
    profile_section_id: 56,
    name: "W36X150",
    type: "W Section",
    designation: "W36X150",
    shape: "I",
    country_code: "American",
    ax: "44,3",
    ax_global: "28580,588",
    bf: 12,
    bf_global: "304,8",
    ct: "4,78",
    ct_global: "121,412",
    d: "35,9",
    d_global: "911,86",
    ix: "10,1",
    ix_global: "4203937,399",
    iy: 270,
    iy_global: "112382484,9",
    iz: 9040,
    iz_global: 3762732087,
    od: "1,69",
    od_global: "42,926",
    rz: "1,13",
    rz_global: "28,702",
    t: "32,52",
    t_global: "826,008",
    tf: "0,94",
    tf_global: "23,876",
    tw: "0,63",
    tw_global: "16,002",
    zx: 581,
    zx_global: "9520884,184",
    zy: "70,9",
    zy_global: "1161842,838",
    width: 12,
    width_global: "304,8",
    thickness: "0,94",
    thickness_global: "23,876",
    height: "35,9",
    height_global: "911,86",
  },
  {
    profile_section_id: 57,
    name: "W36X135",
    type: "W Section",
    designation: "W36X135",
    shape: "I",
    country_code: "American",
    ax: "39,9",
    ax_global: "25741,884",
    bf: 12,
    bf_global: "304,8",
    ct: "4,96",
    ct_global: "125,984",
    d: "35,6",
    d_global: "904,24",
    ix: 7,
    ix_global: "2913619,979",
    iy: 225,
    iy_global: "93652070,76",
    iz: 7800,
    iz_global: 3246605120,
    od: "1,54",
    od_global: "39,116",
    rz: "1,13",
    rz_global: "28,702",
    t: "32,52",
    t_global: "826,008",
    tf: "0,79",
    tf_global: "20,066",
    tw: "0,6",
    tw_global: "15,24",
    zx: 509,
    zx_global: "8341015,576",
    zy: "59,7",
    zy_global: "978307,7208",
    width: 12,
    width_global: "304,8",
    thickness: "0,79",
    thickness_global: "20,066",
    height: "35,6",
    height_global: "904,24",
  },
  {
    profile_section_id: 58,
    name: "W33X387",
    type: "W Section",
    designation: "W33X387",
    shape: "I",
    country_code: "American",
    ax: 114,
    ax_global: "73548,24",
    bf: "16,2",
    bf_global: "411,48",
    ct: "4,27",
    ct_global: "108,458",
    d: 36,
    d_global: "914,4",
    ix: 148,
    ix_global: "61602250,99",
    iy: 1620,
    iy_global: "674294909,5",
    iz: 24300,
    iz_global: 10114423642,
    od: "3,07",
    od_global: "77,978",
    rz: "1,44",
    rz_global: "36,576",
    t: "29,86",
    t_global: "758,444",
    tf: "2,28",
    tf_global: "57,912",
    tw: "1,26",
    tw_global: "32,004",
    zx: 1560,
    zx_global: "25563819,84",
    zy: 312,
    zy_global: "5112763,968",
    width: "16,2",
    width_global: "411,48",
    thickness: "2,28",
    thickness_global: "57,912",
    height: 36,
    height_global: "914,4",
  },
  {
    profile_section_id: 59,
    name: "W33X354",
    type: "W Section",
    designation: "W33X354",
    shape: "I",
    country_code: "American",
    ax: 104,
    ax_global: "67096,64",
    bf: "16,1",
    bf_global: "408,94",
    ct: "4,15",
    ct_global: "105,41",
    d: "35,6",
    d_global: "904,24",
    ix: 115,
    ix_global: "47866613,94",
    iy: 1460,
    iy_global: "607697881,4",
    iz: 22000,
    iz_global: 9157091363,
    od: "2,88",
    od_global: "73,152",
    rz: "1,38",
    rz_global: "35,052",
    t: "29,84",
    t_global: "757,936",
    tf: "2,09",
    tf_global: "53,086",
    tw: "1,16",
    tw_global: "29,464",
    zx: 1420,
    zx_global: "23269630,88",
    zy: 282,
    zy_global: "4621152,048",
    width: "16,1",
    width_global: "408,94",
    thickness: "2,09",
    thickness_global: "53,086",
    height: "35,6",
    height_global: "904,24",
  },
  {
    profile_section_id: 60,
    name: "W33X318",
    type: "W Section",
    designation: "W33X318",
    shape: "I",
    country_code: "American",
    ax: "93,7",
    ax_global: "60451,492",
    bf: 16,
    bf_global: "406,4",
    ct: "4,02",
    ct_global: "102,108",
    d: "35,2",
    d_global: "894,08",
    ix: "84,4",
    ix_global: "35129932,32",
    iy: 1290,
    iy_global: 536938539,
    iz: 19500,
    iz_global: 8116512799,
    od: "2,68",
    od_global: "68,072",
    rz: "1,31",
    rz_global: "33,274",
    t: "29,84",
    t_global: "757,936",
    tf: "1,89",
    tf_global: "48,006",
    tw: "1,04",
    tw_global: "26,416",
    zx: 1270,
    zx_global: "20811571,28",
    zy: 250,
    zy_global: 4096766,
    width: 16,
    width_global: "406,4",
    thickness: "1,89",
    thickness_global: "48,006",
    height: "35,2",
    height_global: "894,08",
  },
  {
    profile_section_id: 61,
    name: "W33X291",
    type: "W Section",
    designation: "W33X291",
    shape: "I",
    country_code: "American",
    ax: "85,6",
    ax_global: "55225,696",
    bf: "15,9",
    bf_global: "403,86",
    ct: "3,93",
    ct_global: "99,822",
    d: "34,8",
    d_global: "883,92",
    ix: "65,1",
    ix_global: "27096665,81",
    iy: 1160,
    iy_global: "482828453,7",
    iz: 17700,
    iz_global: 7367296233,
    od: "2,52",
    od_global: "64,008",
    rz: "1,31",
    rz_global: "33,274",
    t: "29,76",
    t_global: "755,904",
    tf: "1,73",
    tf_global: "43,942",
    tw: "0,96",
    tw_global: "24,384",
    zx: 1160,
    zx_global: "19008994,24",
    zy: 226,
    zy_global: "3703476,464",
    width: "15,9",
    width_global: "403,86",
    thickness: "1,73",
    thickness_global: "43,942",
    height: "34,8",
    height_global: "883,92",
  },
  {
    profile_section_id: 62,
    name: "W33X263",
    type: "W Section",
    designation: "W33X263",
    shape: "I",
    country_code: "American",
    ax: "77,4",
    ax_global: "49935,384",
    bf: "15,8",
    bf_global: "401,32",
    ct: "3,83",
    ct_global: "97,282",
    d: "34,5",
    d_global: "876,3",
    ix: "48,7",
    ix_global: "20270470,43",
    iy: 1040,
    iy_global: "432880682,6",
    iz: 15900,
    iz_global: 6618079667,
    od: "2,36",
    od_global: "59,944",
    rz: "1,25",
    rz_global: "31,75",
    t: "29,78",
    t_global: "756,412",
    tf: "1,57",
    tf_global: "39,878",
    tw: "0,87",
    tw_global: "22,098",
    zx: 1040,
    zx_global: "17042546,56",
    zy: 202,
    zy_global: "3310186,928",
    width: "15,8",
    width_global: "401,32",
    thickness: "1,57",
    thickness_global: "39,878",
    height: "34,5",
    height_global: "876,3",
  },
  {
    profile_section_id: 63,
    name: "W33X241",
    type: "W Section",
    designation: "W33X241",
    shape: "I",
    country_code: "American",
    ax: "71,1",
    ax_global: "45870,876",
    bf: "15,9",
    bf_global: "403,86",
    ct: "3,84",
    ct_global: "97,536",
    d: "34,2",
    d_global: "868,68",
    ix: "36,2",
    ix_global: "15067577,61",
    iy: 933,
    iy_global: "388343920,1",
    iz: 14200,
    iz_global: 5910486244,
    od: "2,19",
    od_global: "55,626",
    rz: "1,25",
    rz_global: "31,75",
    t: "29,82",
    t_global: "757,428",
    tf: "1,4",
    tf_global: "35,56",
    tw: "0,83",
    tw_global: "21,082",
    zx: 940,
    zx_global: "15403840,16",
    zy: 182,
    zy_global: "2982445,648",
    width: "15,9",
    width_global: "403,86",
    thickness: "1,4",
    thickness_global: "35,56",
    height: "34,2",
    height_global: "868,68",
  },
  {
    profile_section_id: 64,
    name: "W33X221",
    type: "W Section",
    designation: "W33X221",
    shape: "I",
    country_code: "American",
    ax: "65,3",
    ax_global: "42128,948",
    bf: "15,8",
    bf_global: "401,32",
    ct: "3,81",
    ct_global: "96,774",
    d: "33,9",
    d_global: "861,06",
    ix: "27,8",
    ix_global: "11571233,63",
    iy: 840,
    iy_global: "349634397,5",
    iz: 12900,
    iz_global: 5369385390,
    od: "2,06",
    od_global: "52,324",
    rz: "1,19",
    rz_global: "30,226",
    t: "29,78",
    t_global: "756,412",
    tf: "1,28",
    tf_global: "32,512",
    tw: "0,78",
    tw_global: "19,812",
    zx: 857,
    zx_global: "14043713,85",
    zy: 164,
    zy_global: "2687478,496",
    width: "15,8",
    width_global: "401,32",
    thickness: "1,28",
    thickness_global: "32,512",
    height: "33,9",
    height_global: "861,06",
  },
  {
    profile_section_id: 65,
    name: "W33X201",
    type: "W Section",
    designation: "W33X201",
    shape: "I",
    country_code: "American",
    ax: "59,1",
    ax_global: "38128,956",
    bf: "15,7",
    bf_global: "398,78",
    ct: "3,77",
    ct_global: "95,758",
    d: "33,7",
    d_global: "855,98",
    ix: "20,8",
    ix_global: "8657613,652",
    iy: 749,
    iy_global: "311757337,8",
    iz: 11600,
    iz_global: 4828284537,
    od: "1,94",
    od_global: "49,276",
    rz: "1,19",
    rz_global: "30,226",
    t: "29,82",
    t_global: "757,428",
    tf: "1,15",
    tf_global: "29,21",
    tw: "0,71",
    tw_global: "18,034",
    zx: 773,
    zx_global: "12667200,47",
    zy: 147,
    zy_global: "2408898,408",
    width: "15,7",
    width_global: "398,78",
    thickness: "1,15",
    thickness_global: "29,21",
    height: "33,7",
    height_global: "855,98",
  },
  {
    profile_section_id: 66,
    name: "W33X169",
    type: "W Section",
    designation: "W33X169",
    shape: "I",
    country_code: "American",
    ax: "49,5",
    ax_global: "31935,42",
    bf: "11,5",
    bf_global: "292,1",
    ct: "4,21",
    ct_global: "106,934",
    d: "33,8",
    d_global: "858,52",
    ix: "17,7",
    ix_global: "7367296,233",
    iy: 310,
    iy_global: "129031741,9",
    iz: 9290,
    iz_global: 3866789944,
    od: "1,92",
    od_global: "48,768",
    rz: "1,19",
    rz_global: "30,226",
    t: "29,96",
    t_global: "760,984",
    tf: "1,22",
    tf_global: "30,988",
    tw: "0,67",
    tw_global: "17,018",
    zx: 629,
    zx_global: "10307463,26",
    zy: "84,4",
    zy_global: "1383068,202",
    width: "11,5",
    width_global: "292,1",
    thickness: "1,22",
    thickness_global: "30,988",
    height: "33,8",
    height_global: "858,52",
  },
  {
    profile_section_id: 67,
    name: "W33X152",
    type: "W Section",
    designation: "W33X152",
    shape: "I",
    country_code: "American",
    ax: "44,9",
    ax_global: "28967,684",
    bf: "11,6",
    bf_global: "294,64",
    ct: "4,26",
    ct_global: "108,204",
    d: "33,5",
    d_global: "850,9",
    ix: "12,4",
    ix_global: "5161269,677",
    iy: 273,
    iy_global: "113631179,2",
    iz: 8160,
    iz_global: 3396448433,
    od: "1,76",
    od_global: "44,704",
    rz: "1,13",
    rz_global: "28,702",
    t: "29,98",
    t_global: "761,492",
    tf: "1,06",
    tf_global: "26,924",
    tw: "0,64",
    tw_global: "16,256",
    zx: 559,
    zx_global: "9160368,776",
    zy: "73,9",
    zy_global: "1211004,03",
    width: "11,6",
    width_global: "294,64",
    thickness: "1,06",
    thickness_global: "26,924",
    height: "33,5",
    height_global: "850,9",
  },
  {
    profile_section_id: 68,
    name: "W33X141",
    type: "W Section",
    designation: "W33X141",
    shape: "I",
    country_code: "American",
    ax: "41,5",
    ax_global: "26774,14",
    bf: "11,5",
    bf_global: "292,1",
    ct: "4,29",
    ct_global: "108,966",
    d: "33,3",
    d_global: "845,82",
    ix: "9,7",
    ix_global: "4037444,828",
    iy: 246,
    iy_global: "102392930,7",
    iz: 7450,
    iz_global: 3100924121,
    od: "1,66",
    od_global: "42,164",
    rz: "1,13",
    rz_global: "28,702",
    t: "29,98",
    t_global: "761,492",
    tf: "0,96",
    tf_global: "24,384",
    tw: "0,6",
    tw_global: "15,24",
    zx: 514,
    zx_global: "8422950,896",
    zy: "66,9",
    zy_global: "1096294,582",
    width: "11,5",
    width_global: "292,1",
    thickness: "0,96",
    thickness_global: "24,384",
    height: "33,3",
    height_global: "845,82",
  },
  {
    profile_section_id: 69,
    name: "W33X130",
    type: "W Section",
    designation: "W33X130",
    shape: "I",
    country_code: "American",
    ax: "38,3",
    ax_global: "24709,628",
    bf: "11,5",
    bf_global: "292,1",
    ct: "4,36",
    ct_global: "110,744",
    d: "33,1",
    d_global: "840,74",
    ix: "7,37",
    ix_global: "3067625,607",
    iy: 218,
    iy_global: "90738450,78",
    iz: 6710,
    iz_global: 2792912866,
    od: "1,56",
    od_global: "39,624",
    rz: "1,13",
    rz_global: "28,702",
    t: "29,98",
    t_global: "761,492",
    tf: "0,85",
    tf_global: "21,59",
    tw: "0,58",
    tw_global: "14,732",
    zx: 467,
    zx_global: "7652758,888",
    zy: "59,5",
    zy_global: "975030,308",
    width: "11,5",
    width_global: "292,1",
    thickness: "0,85",
    thickness_global: "21,59",
    height: "33,1",
    height_global: "840,74",
  },
  {
    profile_section_id: 70,
    name: "W33X118",
    type: "W Section",
    designation: "W33X118",
    shape: "I",
    country_code: "American",
    ax: "34,7",
    ax_global: "22387,052",
    bf: "11,5",
    bf_global: "292,1",
    ct: "4,47",
    ct_global: "113,538",
    d: "32,9",
    d_global: "835,66",
    ix: "5,3",
    ix_global: "2206026,556",
    iy: 187,
    iy_global: "77835276,59",
    iz: 5900,
    iz_global: 2455765411,
    od: "1,44",
    od_global: "36,576",
    rz: "1,13",
    rz_global: "28,702",
    t: "30,02",
    t_global: "762,508",
    tf: "0,74",
    tf_global: "18,796",
    tw: "0,55",
    tw_global: "13,97",
    zx: 415,
    zx_global: "6800631,56",
    zy: "51,3",
    zy_global: "840656,3832",
    width: "11,5",
    width_global: "292,1",
    thickness: "0,74",
    thickness_global: "18,796",
    height: "32,9",
    height_global: "835,66",
  },
  {
    profile_section_id: 71,
    name: "W30X391",
    type: "W Section",
    designation: "W30X391",
    shape: "I",
    country_code: "American",
    ax: 115,
    ax_global: "74193,4",
    bf: "15,6",
    bf_global: "396,24",
    ct: 4,
    ct_global: "101,6",
    d: "33,2",
    d_global: "843,28",
    ix: 173,
    ix_global: "72008036,63",
    iy: 1550,
    iy_global: "645158709,7",
    iz: 20700,
    iz_global: 8615990510,
    od: "3,23",
    od_global: "82,042",
    rz: "1,5",
    rz_global: "38,1",
    t: "26,74",
    t_global: "679,196",
    tf: "2,44",
    tf_global: "61,976",
    tw: "1,36",
    tw_global: "34,544",
    zx: 1450,
    zx_global: "23761242,8",
    zy: 310,
    zy_global: "5079989,84",
    width: "15,6",
    width_global: "396,24",
    thickness: "2,44",
    thickness_global: "61,976",
    height: "33,2",
    height_global: "843,28",
  },
  {
    profile_section_id: 72,
    name: "W30X357",
    type: "W Section",
    designation: "W30X357",
    shape: "I",
    country_code: "American",
    ax: 105,
    ax_global: "67741,8",
    bf: "15,5",
    bf_global: "393,7",
    ct: "3,87",
    ct_global: "98,298",
    d: "32,8",
    d_global: "833,12",
    ix: 134,
    ix_global: "55775011,03",
    iy: 1390,
    iy_global: "578561681,6",
    iz: 18700,
    iz_global: 7783527659,
    od: "3,03",
    od_global: "76,962",
    rz: "1,44",
    rz_global: "36,576",
    t: "26,74",
    t_global: "679,196",
    tf: "2,24",
    tf_global: "56,896",
    tw: "1,24",
    tw_global: "31,496",
    zx: 1320,
    zx_global: "21630924,48",
    zy: 279,
    zy_global: "4571990,856",
    width: "15,5",
    width_global: "393,7",
    thickness: "2,24",
    thickness_global: "56,896",
    height: "32,8",
    height_global: "833,12",
  },
  {
    profile_section_id: 73,
    name: "W30X326",
    type: "W Section",
    designation: "W30X326",
    shape: "I",
    country_code: "American",
    ax: "95,9",
    ax_global: "61870,844",
    bf: "15,4",
    bf_global: "391,16",
    ct: "3,76",
    ct_global: "95,504",
    d: "32,4",
    d_global: "822,96",
    ix: 103,
    ix_global: "42871836,84",
    iy: 1240,
    iy_global: "516126967,7",
    iz: 16800,
    iz_global: 6992687950,
    od: "2,84",
    od_global: "72,136",
    rz: "1,38",
    rz_global: "35,052",
    t: "26,72",
    t_global: "678,688",
    tf: "2,05",
    tf_global: "52,07",
    tw: "1,14",
    tw_global: "28,956",
    zx: 1190,
    zx_global: "19500606,16",
    zy: 252,
    zy_global: "4129540,128",
    width: "15,4",
    width_global: "391,16",
    thickness: "2,05",
    thickness_global: "52,07",
    height: "32,4",
    height_global: "822,96",
  },
  {
    profile_section_id: 74,
    name: "W30X292",
    type: "W Section",
    designation: "W30X292",
    shape: "I",
    country_code: "American",
    ax: 86,
    ax_global: "55483,76",
    bf: "15,3",
    bf_global: "388,62",
    ct: "3,62",
    ct_global: "91,948",
    d: 32,
    d_global: "812,8",
    ix: "75,2",
    ix_global: "31300603,21",
    iy: 1100,
    iy_global: "457854568,2",
    iz: 14900,
    iz_global: 6201848241,
    od: "2,64",
    od_global: "67,056",
    rz: "1,31",
    rz_global: "33,274",
    t: "26,72",
    t_global: "678,688",
    tf: "1,85",
    tf_global: "46,99",
    tw: "1,02",
    tw_global: "25,908",
    zx: 1060,
    zx_global: "17370287,84",
    zy: 223,
    zy_global: "3654315,272",
    width: "15,3",
    width_global: "388,62",
    thickness: "1,85",
    thickness_global: "46,99",
    height: 32,
    height_global: "812,8",
  },
  {
    profile_section_id: 75,
    name: "W30X261",
    type: "W Section",
    designation: "W30X261",
    shape: "I",
    country_code: "American",
    ax: 77,
    ax_global: "49677,32",
    bf: "15,2",
    bf_global: "386,08",
    ct: "3,54",
    ct_global: "89,916",
    d: "31,6",
    d_global: "802,64",
    ix: "54,1",
    ix_global: "22518120,12",
    iy: 959,
    iy_global: "399165937,2",
    iz: 13100,
    iz_global: 5452631675,
    od: "2,44",
    od_global: "61,976",
    rz: "1,31",
    rz_global: "33,274",
    t: "26,72",
    t_global: "678,688",
    tf: "1,65",
    tf_global: "41,91",
    tw: "0,93",
    tw_global: "23,622",
    zx: 943,
    zx_global: "15453001,35",
    zy: 196,
    zy_global: "3211864,544",
    width: "15,2",
    width_global: "386,08",
    thickness: "1,65",
    thickness_global: "41,91",
    height: "31,6",
    height_global: "802,64",
  },
  {
    profile_section_id: 76,
    name: "W30X235",
    type: "W Section",
    designation: "W30X235",
    shape: "I",
    country_code: "American",
    ax: "69,3",
    ax_global: "44709,588",
    bf: "15,1",
    bf_global: "383,54",
    ct: "3,41",
    ct_global: "86,614",
    d: "31,3",
    d_global: "795,02",
    ix: "40,3",
    ix_global: "16774126,45",
    iy: 855,
    iy_global: "355877868,9",
    iz: 11700,
    iz_global: 4869907680,
    od: "2,29",
    od_global: "58,166",
    rz: "1,25",
    rz_global: "31,75",
    t: "26,72",
    t_global: "678,688",
    tf: "1,5",
    tf_global: "38,1",
    tw: "0,83",
    tw_global: "21,082",
    zx: 847,
    zx_global: "13879843,21",
    zy: 175,
    zy_global: "2867736,2",
    width: "15,1",
    width_global: "383,54",
    thickness: "1,5",
    thickness_global: "38,1",
    height: "31,3",
    height_global: "795,02",
  },
  {
    profile_section_id: 77,
    name: "W30X211",
    type: "W Section",
    designation: "W30X211",
    shape: "I",
    country_code: "American",
    ax: "62,3",
    ax_global: "40193,468",
    bf: "15,1",
    bf_global: "383,54",
    ct: "3,39",
    ct_global: "86,106",
    d: "30,9",
    d_global: "784,86",
    ix: "28,4",
    ix_global: "11820972,49",
    iy: 757,
    iy_global: "315087189,2",
    iz: 10300,
    iz_global: 4287183684,
    od: "2,1",
    od_global: "53,34",
    rz: "1,19",
    rz_global: "30,226",
    t: "26,7",
    t_global: "678,18",
    tf: "1,32",
    tf_global: "33,528",
    tw: "0,78",
    tw_global: "19,812",
    zx: 751,
    zx_global: "12306685,06",
    zy: 155,
    zy_global: "2539994,92",
    width: "15,1",
    width_global: "383,54",
    thickness: "1,32",
    thickness_global: "33,528",
    height: "30,9",
    height_global: "784,86",
  },
  {
    profile_section_id: 78,
    name: "W30X191",
    type: "W Section",
    designation: "W30X191",
    shape: "I",
    country_code: "American",
    ax: "56,1",
    ax_global: "36193,476",
    bf: 15,
    bf_global: 381,
    ct: "3,34",
    ct_global: "84,836",
    d: "30,7",
    d_global: "779,78",
    ix: 21,
    ix_global: "8740859,938",
    iy: 673,
    iy_global: "280123749,4",
    iz: 9200,
    iz_global: 3829329116,
    od: "1,97",
    od_global: "50,038",
    rz: "1,19",
    rz_global: "30,226",
    t: "26,76",
    t_global: "679,704",
    tf: "1,19",
    tf_global: "30,226",
    tw: "0,71",
    tw_global: "18,034",
    zx: 675,
    zx_global: "11061268,2",
    zy: 138,
    zy_global: "2261414,832",
    width: 15,
    width_global: 381,
    thickness: "1,19",
    thickness_global: "30,226",
    height: "30,7",
    height_global: "779,78",
  },
  {
    profile_section_id: 79,
    name: "W30X173",
    type: "W Section",
    designation: "W30X173",
    shape: "I",
    country_code: "American",
    ax: "50,9",
    ax_global: "32838,644",
    bf: 15,
    bf_global: 381,
    ct: "3,31",
    ct_global: "84,074",
    d: "30,4",
    d_global: "772,16",
    ix: "15,6",
    ix_global: "6493210,239",
    iy: 598,
    iy_global: "248906392,5",
    iz: 8230,
    iz_global: 3425584633,
    od: "1,85",
    od_global: "46,99",
    rz: "1,13",
    rz_global: "28,702",
    t: "26,7",
    t_global: "678,18",
    tf: "1,07",
    tf_global: "27,178",
    tw: "0,66",
    tw_global: "16,764",
    zx: 607,
    zx_global: "9946947,848",
    zy: 123,
    zy_global: "2015608,872",
    width: 15,
    width_global: 381,
    thickness: "1,07",
    thickness_global: "27,178",
    height: "30,4",
    height_global: "772,16",
  },
  {
    profile_section_id: 80,
    name: "W30X148",
    type: "W Section",
    designation: "W30X148",
    shape: "I",
    country_code: "American",
    ax: "43,6",
    ax_global: "28128,976",
    bf: "10,5",
    bf_global: "266,7",
    ct: "3,84",
    ct_global: "97,536",
    d: "30,7",
    d_global: "779,78",
    ix: "14,5",
    ix_global: "6035355,671",
    iy: 227,
    iy_global: "94484533,61",
    iz: 6680,
    iz_global: 2780425923,
    od: "1,83",
    od_global: "46,482",
    rz: "1,13",
    rz_global: "28,702",
    t: "27,04",
    t_global: "686,816",
    tf: "1,18",
    tf_global: "29,972",
    tw: "0,65",
    tw_global: "16,51",
    zx: 500,
    zx_global: 8193532,
    zy: 68,
    zy_global: "1114320,352",
    width: "10,5",
    width_global: "266,7",
    thickness: "1,18",
    thickness_global: "29,972",
    height: "30,7",
    height_global: "779,78",
  },
  {
    profile_section_id: 81,
    name: "W30X132",
    type: "W Section",
    designation: "W30X132",
    shape: "I",
    country_code: "American",
    ax: "38,8",
    ax_global: "25032,208",
    bf: "10,5",
    bf_global: "266,7",
    ct: "3,9",
    ct_global: "99,06",
    d: "30,3",
    d_global: "769,62",
    ix: "9,72",
    ix_global: "4045769,457",
    iy: 196,
    iy_global: "81581359,42",
    iz: 5770,
    iz_global: 2401655326,
    od: "1,65",
    od_global: "41,91",
    rz: "1,13",
    rz_global: "28,702",
    t: 27,
    t_global: "685,8",
    tf: 1,
    tf_global: "25,4",
    tw: "0,61",
    tw_global: "15,494",
    zx: 437,
    zx_global: "7161146,968",
    zy: "58,4",
    zy_global: "957004,5376",
    width: "10,5",
    width_global: "266,7",
    thickness: 1,
    thickness_global: "25,4",
    height: "30,3",
    height_global: "769,62",
  },
  {
    profile_section_id: 82,
    name: "W30X124",
    type: "W Section",
    designation: "W30X124",
    shape: "I",
    country_code: "American",
    ax: "36,5",
    ax_global: "23548,34",
    bf: "10,5",
    bf_global: "266,7",
    ct: "3,9",
    ct_global: "99,06",
    d: "30,2",
    d_global: "767,08",
    ix: "7,99",
    ix_global: "3325689,091",
    iy: 181,
    iy_global: "75337888,03",
    iz: 5360,
    iz_global: 2231000441,
    od: "1,58",
    od_global: "40,132",
    rz: "1,13",
    rz_global: "28,702",
    t: "27,04",
    t_global: "686,816",
    tf: "0,93",
    tf_global: "23,622",
    tw: "0,58",
    tw_global: "14,732",
    zx: 408,
    zx_global: "6685922,112",
    zy: 54,
    zy_global: "884901,456",
    width: "10,5",
    width_global: "266,7",
    thickness: "0,93",
    thickness_global: "23,622",
    height: "30,2",
    height_global: "767,08",
  },
  {
    profile_section_id: 83,
    name: "W30X116",
    type: "W Section",
    designation: "W30X116",
    shape: "I",
    country_code: "American",
    ax: "34,2",
    ax_global: "22064,472",
    bf: "10,5",
    bf_global: "266,7",
    ct: "3,94",
    ct_global: "100,076",
    d: 30,
    d_global: 762,
    ix: "6,43",
    ix_global: "2676368,067",
    iy: 164,
    iy_global: "68261953,8",
    iz: 4930,
    iz_global: 2052020928,
    od: "1,5",
    od_global: "38,1",
    rz: "1,13",
    rz_global: "28,702",
    t: 27,
    t_global: "685,8",
    tf: "0,85",
    tf_global: "21,59",
    tw: "0,56",
    tw_global: "14,224",
    zx: 378,
    zx_global: "6194310,192",
    zy: "49,2",
    zy_global: "806243,5488",
    width: "10,5",
    width_global: "266,7",
    thickness: "0,85",
    thickness_global: "21,59",
    height: 30,
    height_global: 762,
  },
  {
    profile_section_id: 84,
    name: "W30X108",
    type: "W Section",
    designation: "W30X108",
    shape: "I",
    country_code: "American",
    ax: "31,7",
    ax_global: "20451,572",
    bf: "10,5",
    bf_global: "266,7",
    ct: "4,01",
    ct_global: "101,854",
    d: "29,8",
    d_global: "756,92",
    ix: "4,99",
    ix_global: "2076994,814",
    iy: 146,
    iy_global: "60769788,14",
    iz: 4470,
    iz_global: 1860554472,
    od: "1,41",
    od_global: "35,814",
    rz: "1,13",
    rz_global: "28,702",
    t: "26,98",
    t_global: "685,292",
    tf: "0,76",
    tf_global: "19,304",
    tw: "0,55",
    tw_global: "13,97",
    zx: 346,
    zx_global: "5669924,144",
    zy: "43,9",
    zy_global: "719392,1096",
    width: "10,5",
    width_global: "266,7",
    thickness: "0,76",
    thickness_global: "19,304",
    height: "29,8",
    height_global: "756,92",
  },
  {
    profile_section_id: 85,
    name: "W30X99",
    type: "W Section",
    designation: "W30X99",
    shape: "I",
    country_code: "American",
    ax: 29,
    ax_global: "18709,64",
    bf: "10,5",
    bf_global: "266,7",
    ct: "4,09",
    ct_global: "103,886",
    d: "29,7",
    d_global: "754,38",
    ix: "3,77",
    ix_global: "1569192,475",
    iy: 128,
    iy_global: "53277622,48",
    iz: 3990,
    iz_global: 1660763388,
    od: "1,32",
    od_global: "33,528",
    rz: "1,06",
    rz_global: "26,924",
    t: "27,06",
    t_global: "687,324",
    tf: "0,67",
    tf_global: "17,018",
    tw: "0,52",
    tw_global: "13,208",
    zx: 312,
    zx_global: "5112763,968",
    zy: "38,6",
    zy_global: "632540,6704",
    width: "10,5",
    width_global: "266,7",
    thickness: "0,67",
    thickness_global: "17,018",
    height: "29,7",
    height_global: "754,38",
  },
  {
    profile_section_id: 86,
    name: "W30X90",
    type: "W Section",
    designation: "W30X90",
    shape: "I",
    country_code: "American",
    ax: "26,3",
    ax_global: "16967,708",
    bf: "10,4",
    bf_global: "264,16",
    ct: "4,04",
    ct_global: "102,616",
    d: "29,5",
    d_global: "749,3",
    ix: "2,84",
    ix_global: "1182097,249",
    iy: 115,
    iy_global: "47866613,94",
    iz: 3610,
    iz_global: 1502595446,
    od: "1,26",
    od_global: "32,004",
    rz: "1,06",
    rz_global: "26,924",
    t: "26,98",
    t_global: "685,292",
    tf: "0,61",
    tf_global: "15,494",
    tw: "0,47",
    tw_global: "11,938",
    zx: 283,
    zx_global: "4637539,112",
    zy: "34,7",
    zy_global: "568631,1208",
    width: "10,4",
    width_global: "264,16",
    thickness: "0,61",
    thickness_global: "15,494",
    height: "29,5",
    height_global: "749,3",
  },
  {
    profile_section_id: 87,
    name: "W27X539",
    type: "W Section",
    designation: "W27X539",
    shape: "I",
    country_code: "American",
    ax: 159,
    ax_global: "102580,44",
    bf: "15,3",
    bf_global: "388,62",
    ct: "4,34",
    ct_global: "110,236",
    d: "32,5",
    d_global: "825,5",
    ix: 496,
    ix_global: "206450787,1",
    iy: 2110,
    iy_global: 878248308,
    iz: 25600,
    iz_global: 10655524495,
    od: "4,33",
    od_global: "109,982",
    rz: "1,81",
    rz_global: "45,974",
    t: "23,84",
    t_global: "605,536",
    tf: "3,54",
    tf_global: "89,916",
    tw: "1,97",
    tw_global: "50,038",
    zx: 1890,
    zx_global: "30971550,96",
    zy: 437,
    zy_global: "7161146,968",
    width: "15,3",
    width_global: "388,62",
    thickness: "3,54",
    thickness_global: "89,916",
    height: "32,5",
    height_global: "825,5",
  },
  {
    profile_section_id: 88,
    name: "W27X368",
    type: "W Section",
    designation: "W27X368",
    shape: "I",
    country_code: "American",
    ax: 109,
    ax_global: "70322,44",
    bf: "14,7",
    bf_global: "373,38",
    ct: "3,71",
    ct_global: "94,234",
    d: "30,4",
    d_global: "772,16",
    ix: 170,
    ix_global: "70759342,35",
    iy: 1310,
    iy_global: "545263167,5",
    iz: 16200,
    iz_global: 6742949095,
    od: "3,27",
    od_global: "83,058",
    rz: "1,5",
    rz_global: "38,1",
    t: "23,86",
    t_global: "606,044",
    tf: "2,48",
    tf_global: "62,992",
    tw: "1,38",
    tw_global: "35,052",
    zx: 1240,
    zx_global: "20319959,36",
    zy: 279,
    zy_global: "4571990,856",
    width: "14,7",
    width_global: "373,38",
    thickness: "2,48",
    thickness_global: "62,992",
    height: "30,4",
    height_global: "772,16",
  },
  {
    profile_section_id: 89,
    name: "W27X336",
    type: "W Section",
    designation: "W27X336",
    shape: "I",
    country_code: "American",
    ax: "99,2",
    ax_global: "63999,872",
    bf: "14,6",
    bf_global: "370,84",
    ct: "3,58",
    ct_global: "90,932",
    d: 30,
    d_global: 762,
    ix: 131,
    ix_global: "54526316,75",
    iy: 1180,
    iy_global: "491153082,2",
    iz: 14600,
    iz_global: 6076978814,
    od: "3,07",
    od_global: "77,978",
    rz: "1,44",
    rz_global: "36,576",
    t: "23,86",
    t_global: "606,044",
    tf: "2,28",
    tf_global: "57,912",
    tw: "1,26",
    tw_global: "32,004",
    zx: 1130,
    zx_global: "18517382,32",
    zy: 252,
    zy_global: "4129540,128",
    width: "14,6",
    width_global: "370,84",
    thickness: "2,28",
    thickness_global: "57,912",
    height: 30,
    height_global: 762,
  },
  {
    profile_section_id: 90,
    name: "W27X307",
    type: "W Section",
    designation: "W27X307",
    shape: "I",
    country_code: "American",
    ax: "90,2",
    ax_global: "58193,432",
    bf: "14,4",
    bf_global: "365,76",
    ct: "3,47",
    ct_global: "88,138",
    d: "29,6",
    d_global: "751,84",
    ix: 101,
    ix_global: "42039373,99",
    iy: 1050,
    iy_global: "437042996,9",
    iz: 13100,
    iz_global: 5452631675,
    od: "2,88",
    od_global: "73,152",
    rz: "1,44",
    rz_global: "36,576",
    t: "23,84",
    t_global: "605,536",
    tf: "2,09",
    tf_global: "53,086",
    tw: "1,16",
    tw_global: "29,464",
    zx: 1030,
    zx_global: "16878675,92",
    zy: 227,
    zy_global: "3719863,528",
    width: "14,4",
    width_global: "365,76",
    thickness: "2,09",
    thickness_global: "53,086",
    height: "29,6",
    height_global: "751,84",
  },
  {
    profile_section_id: 91,
    name: "W27X281",
    type: "W Section",
    designation: "W27X281",
    shape: "I",
    country_code: "American",
    ax: "83,1",
    ax_global: "53612,796",
    bf: "14,4",
    bf_global: "365,76",
    ct: "3,35",
    ct_global: "85,09",
    d: "29,3",
    d_global: "744,22",
    ix: "79,5",
    ix_global: "33090398,34",
    iy: 953,
    iy_global: "396668548,6",
    iz: 11900,
    iz_global: 4953153965,
    od: "2,72",
    od_global: "69,088",
    rz: "1,38",
    rz_global: "35,052",
    t: "23,86",
    t_global: "606,044",
    tf: "1,93",
    tf_global: "49,022",
    tw: "1,06",
    tw_global: "26,924",
    zx: 936,
    zx_global: "15338291,9",
    zy: 206,
    zy_global: "3375735,184",
    width: "14,4",
    width_global: "365,76",
    thickness: "1,93",
    thickness_global: "49,022",
    height: "29,3",
    height_global: "744,22",
  },
  {
    profile_section_id: 92,
    name: "W27X258",
    type: "W Section",
    designation: "W27X258",
    shape: "I",
    country_code: "American",
    ax: "76,1",
    ax_global: "49096,676",
    bf: "14,3",
    bf_global: "363,22",
    ct: "3,27",
    ct_global: "83,058",
    d: 29,
    d_global: "736,6",
    ix: "61,6",
    ix_global: "25639855,82",
    iy: 859,
    iy_global: "357542794,6",
    iz: 10800,
    iz_global: 4495299396,
    od: "2,56",
    od_global: "65,024",
    rz: "1,31",
    rz_global: "33,274",
    t: "23,88",
    t_global: "606,552",
    tf: "1,77",
    tf_global: "44,958",
    tw: "0,98",
    tw_global: "24,892",
    zx: 852,
    zx_global: "13961778,53",
    zy: 187,
    zy_global: "3064380,968",
    width: "14,3",
    width_global: "363,22",
    thickness: "1,77",
    thickness_global: "44,958",
    height: 29,
    height_global: "736,6",
  },
  {
    profile_section_id: 93,
    name: "W27X235",
    type: "W Section",
    designation: "W27X235",
    shape: "I",
    country_code: "American",
    ax: "69,4",
    ax_global: "44774,104",
    bf: "14,2",
    bf_global: "360,68",
    ct: "3,2",
    ct_global: "81,28",
    d: "28,7",
    d_global: "728,98",
    ix: 47,
    ix_global: 19562877,
    iy: 769,
    iy_global: "320081966,3",
    iz: 9700,
    iz_global: 4037444828,
    od: "2,4",
    od_global: "60,96",
    rz: "1,31",
    rz_global: "33,274",
    t: "23,9",
    t_global: "607,06",
    tf: "1,61",
    tf_global: "40,894",
    tw: "0,91",
    tw_global: "23,114",
    zx: 772,
    zx_global: "12650813,41",
    zy: 168,
    zy_global: "2753026,752",
    width: "14,2",
    width_global: "360,68",
    thickness: "1,61",
    thickness_global: "40,894",
    height: "28,7",
    height_global: "728,98",
  },
  {
    profile_section_id: 94,
    name: "W27X217",
    type: "W Section",
    designation: "W27X217",
    shape: "I",
    country_code: "American",
    ax: "63,9",
    ax_global: "41225,724",
    bf: "14,1",
    bf_global: "358,14",
    ct: "3,1",
    ct_global: "78,74",
    d: "28,4",
    d_global: "721,36",
    ix: "37,6",
    ix_global: "15650301,6",
    iy: 704,
    iy_global: "293026923,6",
    iz: 8910,
    iz_global: 3708622002,
    od: "2,29",
    od_global: "58,166",
    rz: "1,25",
    rz_global: "31,75",
    t: "23,82",
    t_global: "605,028",
    tf: "1,5",
    tf_global: "38,1",
    tw: "0,83",
    tw_global: "21,082",
    zx: 711,
    zx_global: "11651202,5",
    zy: 154,
    zy_global: "2523607,856",
    width: "14,1",
    width_global: "358,14",
    thickness: "1,5",
    thickness_global: "38,1",
    height: "28,4",
    height_global: "721,36",
  },
  {
    profile_section_id: 95,
    name: "W27X194",
    type: "W Section",
    designation: "W27X194",
    shape: "I",
    country_code: "American",
    ax: "57,1",
    ax_global: "36838,636",
    bf: 14,
    bf_global: "355,6",
    ct: "3,02",
    ct_global: "76,708",
    d: "28,1",
    d_global: "713,74",
    ix: "27,1",
    ix_global: "11279871,63",
    iy: 619,
    iy_global: "257647252,4",
    iz: 7860,
    iz_global: 3271579005,
    od: "2,13",
    od_global: "54,102",
    rz: "1,19",
    rz_global: "30,226",
    t: "23,84",
    t_global: "605,536",
    tf: "1,34",
    tf_global: "34,036",
    tw: "0,75",
    tw_global: "19,05",
    zx: 631,
    zx_global: "10340237,38",
    zy: 136,
    zy_global: "2228640,704",
    width: 14,
    width_global: "355,6",
    thickness: "1,34",
    thickness_global: "34,036",
    height: "28,1",
    height_global: "713,74",
  },
  {
    profile_section_id: 96,
    name: "W27X178",
    type: "W Section",
    designation: "W27X178",
    shape: "I",
    country_code: "American",
    ax: "52,5",
    ax_global: "33870,9",
    bf: "14,1",
    bf_global: "358,14",
    ct: "3,04",
    ct_global: "77,216",
    d: "27,8",
    d_global: "706,12",
    ix: "20,1",
    ix_global: "8366251,655",
    iy: 555,
    iy_global: "231008441,2",
    iz: 7020,
    iz_global: 2921944608,
    od: "1,98",
    od_global: "50,292",
    rz: "1,19",
    rz_global: "30,226",
    t: "23,84",
    t_global: "605,536",
    tf: "1,19",
    tf_global: "30,226",
    tw: "0,72",
    tw_global: "18,288",
    zx: 570,
    zx_global: "9340626,48",
    zy: 122,
    zy_global: "1999221,808",
    width: "14,1",
    width_global: "358,14",
    thickness: "1,19",
    thickness_global: "30,226",
    height: "27,8",
    height_global: "706,12",
  },
  {
    profile_section_id: 97,
    name: "W27X161",
    type: "W Section",
    designation: "W27X161",
    shape: "I",
    country_code: "American",
    ax: "47,6",
    ax_global: "30709,616",
    bf: 14,
    bf_global: "355,6",
    ct: "2,98",
    ct_global: "75,692",
    d: "27,6",
    d_global: "701,04",
    ix: "15,1",
    ix_global: "6285094,527",
    iy: 497,
    iy_global: "206867018,5",
    iz: 6310,
    iz_global: 2626420296,
    od: "1,87",
    od_global: "47,498",
    rz: "1,19",
    rz_global: "30,226",
    t: "23,86",
    t_global: "606,044",
    tf: "1,08",
    tf_global: "27,432",
    tw: "0,66",
    tw_global: "16,764",
    zx: 515,
    zx_global: "8439337,96",
    zy: 109,
    zy_global: "1786189,976",
    width: 14,
    width_global: "355,6",
    thickness: "1,08",
    thickness_global: "27,432",
    height: "27,6",
    height_global: "701,04",
  },
  {
    profile_section_id: 98,
    name: "W27X146",
    type: "W Section",
    designation: "W27X146",
    shape: "I",
    country_code: "American",
    ax: "43,2",
    ax_global: "27870,912",
    bf: 14,
    bf_global: "355,6",
    ct: "2,94",
    ct_global: "74,676",
    d: "27,4",
    d_global: "695,96",
    ix: "11,3",
    ix_global: "4703415,109",
    iy: 443,
    iy_global: "184390521,5",
    iz: 5660,
    iz_global: 2355869869,
    od: "1,76",
    od_global: "44,704",
    rz: "1,13",
    rz_global: "28,702",
    t: "23,88",
    t_global: "606,552",
    tf: "0,97",
    tf_global: "24,638",
    tw: "0,6",
    tw_global: "15,24",
    zx: 464,
    zx_global: "7603597,696",
    zy: "97,7",
    zy_global: "1601016,153",
    width: 14,
    width_global: "355,6",
    thickness: "0,97",
    thickness_global: "24,638",
    height: "27,4",
    height_global: "695,96",
  },
  {
    profile_section_id: 99,
    name: "W27X129",
    type: "W Section",
    designation: "W27X129",
    shape: "I",
    country_code: "American",
    ax: "37,8",
    ax_global: "24387,048",
    bf: 10,
    bf_global: 254,
    ct: "3,39",
    ct_global: "86,106",
    d: "27,6",
    d_global: "701,04",
    ix: "11,1",
    ix_global: "4620168,824",
    iy: 184,
    iy_global: "76586582,31",
    iz: 4760,
    iz_global: 1981261586,
    od: "1,7",
    od_global: "43,18",
    rz: "1,13",
    rz_global: "28,702",
    t: "24,2",
    t_global: "614,68",
    tf: "1,1",
    tf_global: "27,94",
    tw: "0,61",
    tw_global: "15,494",
    zx: 395,
    zx_global: "6472890,28",
    zy: "57,6",
    zy_global: "943894,8864",
    width: 10,
    width_global: 254,
    thickness: "1,1",
    thickness_global: "27,94",
    height: "27,6",
    height_global: "701,04",
  },
  {
    profile_section_id: 100,
    name: "W27X114",
    type: "W Section",
    designation: "W27X114",
    shape: "I",
    country_code: "American",
    ax: "33,6",
    ax_global: "21677,376",
    bf: "10,1",
    bf_global: "256,54",
    ct: "3,42",
    ct_global: "86,868",
    d: "27,3",
    d_global: "693,42",
    ix: "7,33",
    ix_global: "3050976,35",
    iy: 159,
    iy_global: "66180796,67",
    iz: 4080,
    iz_global: 1698224216,
    od: "1,53",
    od_global: "38,862",
    rz: "1,13",
    rz_global: "28,702",
    t: "24,24",
    t_global: "615,696",
    tf: "0,93",
    tf_global: "23,622",
    tw: "0,57",
    tw_global: "14,478",
    zx: 343,
    zx_global: "5620762,952",
    zy: "49,3",
    zy_global: "807882,2552",
    width: "10,1",
    width_global: "256,54",
    thickness: "0,93",
    thickness_global: "23,622",
    height: "27,3",
    height_global: "693,42",
  },
  {
    profile_section_id: 101,
    name: "W27X102",
    type: "W Section",
    designation: "W27X102",
    shape: "I",
    country_code: "American",
    ax: 30,
    ax_global: "19354,8",
    bf: 10,
    bf_global: 254,
    ct: "3,37",
    ct_global: "85,598",
    d: "27,1",
    d_global: "688,34",
    ix: "5,28",
    ix_global: "2197701,927",
    iy: 139,
    iy_global: "57856168,16",
    iz: 3620,
    iz_global: 1506757761,
    od: "1,43",
    od_global: "36,322",
    rz: "1,06",
    rz_global: "26,924",
    t: "24,24",
    t_global: "615,696",
    tf: "0,83",
    tf_global: "21,082",
    tw: "0,52",
    tw_global: "13,208",
    zx: 305,
    zx_global: "4998054,52",
    zy: "43,4",
    zy_global: "711198,5776",
    width: 10,
    width_global: 254,
    thickness: "0,83",
    thickness_global: "21,082",
    height: "27,1",
    height_global: "688,34",
  },
  {
    profile_section_id: 102,
    name: "W27X94",
    type: "W Section",
    designation: "W27X94",
    shape: "I",
    country_code: "American",
    ax: "27,6",
    ax_global: "17806,416",
    bf: 10,
    bf_global: 254,
    ct: "3,41",
    ct_global: "86,614",
    d: "26,9",
    d_global: "683,26",
    ix: "4,03",
    ix_global: "1677412,645",
    iy: 124,
    iy_global: "51612696,77",
    iz: 3270,
    iz_global: 1361076762,
    od: "1,34",
    od_global: "34,036",
    rz: "1,06",
    rz_global: "26,924",
    t: "24,22",
    t_global: "615,188",
    tf: "0,75",
    tf_global: "19,05",
    tw: "0,49",
    tw_global: "12,446",
    zx: 278,
    zx_global: "4555603,792",
    zy: "38,8",
    zy_global: "635818,0832",
    width: 10,
    width_global: 254,
    thickness: "0,75",
    thickness_global: "19,05",
    height: "26,9",
    height_global: "683,26",
  },
  {
    profile_section_id: 103,
    name: "W27X84",
    type: "W Section",
    designation: "W27X84",
    shape: "I",
    country_code: "American",
    ax: "24,7",
    ax_global: "15935,452",
    bf: 10,
    bf_global: 254,
    ct: "3,48",
    ct_global: "88,392",
    d: "26,7",
    d_global: "678,18",
    ix: "2,81",
    ix_global: "1169610,306",
    iy: 106,
    iy_global: "44120531,11",
    iz: 2850,
    iz_global: 1186259563,
    od: "1,24",
    od_global: "31,496",
    rz: "1,06",
    rz_global: "26,924",
    t: "24,22",
    t_global: "615,188",
    tf: "0,64",
    tf_global: "16,256",
    tw: "0,46",
    tw_global: "11,684",
    zx: 244,
    zx_global: "3998443,616",
    zy: "33,2",
    zy_global: "544050,5248",
    width: 10,
    width_global: 254,
    thickness: "0,64",
    thickness_global: "16,256",
    height: "26,7",
    height_global: "678,18",
  },
  {
    profile_section_id: 104,
    name: "W24X370",
    type: "W Section",
    designation: "W24X370",
    shape: "I",
    country_code: "American",
    ax: 109,
    ax_global: "70322,44",
    bf: "13,7",
    bf_global: "347,98",
    ct: "3,57",
    ct_global: "90,678",
    d: 28,
    d_global: "711,2",
    ix: 201,
    ix_global: "83662516,55",
    iy: 1160,
    iy_global: "482828453,7",
    iz: 13400,
    iz_global: 5577501103,
    od: "3,22",
    od_global: "81,788",
    rz: "1,56",
    rz_global: "39,624",
    t: "21,56",
    t_global: "547,624",
    tf: "2,72",
    tf_global: "69,088",
    tw: "1,52",
    tw_global: "38,608",
    zx: 1130,
    zx_global: "18517382,32",
    zy: 267,
    zy_global: "4375346,088",
    width: "13,7",
    width_global: "347,98",
    thickness: "2,72",
    thickness_global: "69,088",
    height: 28,
    height_global: "711,2",
  },
  {
    profile_section_id: 105,
    name: "W24X335",
    type: "W Section",
    designation: "W24X335",
    shape: "I",
    country_code: "American",
    ax: "98,3",
    ax_global: "63419,228",
    bf: "13,5",
    bf_global: "342,9",
    ct: "3,42",
    ct_global: "86,868",
    d: "27,5",
    d_global: "698,5",
    ix: 152,
    ix_global: "63267176,69",
    iy: 1030,
    iy_global: "428718368,4",
    iz: 11900,
    iz_global: 4953153965,
    od: "2,98",
    od_global: "75,692",
    rz: "1,5",
    rz_global: "38,1",
    t: "21,54",
    t_global: "547,116",
    tf: "2,48",
    tf_global: "62,992",
    tw: "1,38",
    tw_global: "35,052",
    zx: 1020,
    zx_global: "16714805,28",
    zy: 238,
    zy_global: "3900121,232",
    width: "13,5",
    width_global: "342,9",
    thickness: "2,48",
    thickness_global: "62,992",
    height: "27,5",
    height_global: "698,5",
  },
  {
    profile_section_id: 106,
    name: "W24X306",
    type: "W Section",
    designation: "W24X306",
    shape: "I",
    country_code: "American",
    ax: "89,7",
    ax_global: "57870,852",
    bf: "13,4",
    bf_global: "340,36",
    ct: "3,29",
    ct_global: "83,566",
    d: "27,1",
    d_global: "688,34",
    ix: 117,
    ix_global: "48699076,8",
    iy: 919,
    iy_global: "382516680,1",
    iz: 10700,
    iz_global: 4453676254,
    od: "2,78",
    od_global: "70,612",
    rz: "1,44",
    rz_global: "36,576",
    t: "21,54",
    t_global: "547,116",
    tf: "2,28",
    tf_global: "57,912",
    tw: "1,26",
    tw_global: "32,004",
    zx: 922,
    zx_global: "15108873,01",
    zy: 214,
    zy_global: "3506831,696",
    width: "13,4",
    width_global: "340,36",
    thickness: "2,28",
    thickness_global: "57,912",
    height: "27,1",
    height_global: "688,34",
  },
  {
    profile_section_id: 107,
    name: "W24X279",
    type: "W Section",
    designation: "W24X279",
    shape: "I",
    country_code: "American",
    ax: "81,9",
    ax_global: "52838,604",
    bf: "13,3",
    bf_global: "337,82",
    ct: "3,18",
    ct_global: "80,772",
    d: "26,7",
    d_global: "678,18",
    ix: "90,5",
    ix_global: "37668944,02",
    iy: 823,
    iy_global: "342558463,3",
    iz: 9600,
    iz_global: 3995821686,
    od: "2,59",
    od_global: "65,786",
    rz: "1,44",
    rz_global: "36,576",
    t: "21,52",
    t_global: "546,608",
    tf: "2,09",
    tf_global: "53,086",
    tw: "1,16",
    tw_global: "29,464",
    zx: 835,
    zx_global: "13683198,44",
    zy: 193,
    zy_global: "3162703,352",
    width: "13,3",
    width_global: "337,82",
    thickness: "2,09",
    thickness_global: "53,086",
    height: "26,7",
    height_global: "678,18",
  },
  {
    profile_section_id: 108,
    name: "W24X250",
    type: "W Section",
    designation: "W24X250",
    shape: "I",
    country_code: "American",
    ax: "73,5",
    ax_global: "47419,26",
    bf: "13,2",
    bf_global: "335,28",
    ct: "3,05",
    ct_global: "77,47",
    d: "26,3",
    d_global: "668,02",
    ix: "66,6",
    ix_global: "27721012,94",
    iy: 724,
    iy_global: "301351552,1",
    iz: 8490,
    iz_global: 3533804803,
    od: "2,39",
    od_global: "60,706",
    rz: "1,38",
    rz_global: "35,052",
    t: "21,52",
    t_global: "546,608",
    tf: "1,89",
    tf_global: "48,006",
    tw: "1,04",
    tw_global: "26,416",
    zx: 744,
    zx_global: "12191975,62",
    zy: 171,
    zy_global: "2802187,944",
    width: "13,2",
    width_global: "335,28",
    thickness: "1,89",
    thickness_global: "48,006",
    height: "26,3",
    height_global: "668,02",
  },
  {
    profile_section_id: 109,
    name: "W24X229",
    type: "W Section",
    designation: "W24X229",
    shape: "I",
    country_code: "American",
    ax: "67,2",
    ax_global: "43354,752",
    bf: "13,1",
    bf_global: "332,74",
    ct: "2,96",
    ct_global: "75,184",
    d: 26,
    d_global: "660,4",
    ix: "51,3",
    ix_global: "21352672,13",
    iy: 651,
    iy_global: "270966658,1",
    iz: 7650,
    iz_global: 3184170406,
    od: "2,23",
    od_global: "56,642",
    rz: "1,31",
    rz_global: "33,274",
    t: "21,54",
    t_global: "547,116",
    tf: "1,73",
    tf_global: "43,942",
    tw: "0,96",
    tw_global: "24,384",
    zx: 675,
    zx_global: "11061268,2",
    zy: 154,
    zy_global: "2523607,856",
    width: "13,1",
    width_global: "332,74",
    thickness: "1,73",
    thickness_global: "43,942",
    height: 26,
    height_global: "660,4",
  },
  {
    profile_section_id: 110,
    name: "W24X207",
    type: "W Section",
    designation: "W24X207",
    shape: "I",
    country_code: "American",
    ax: "60,7",
    ax_global: "39161,212",
    bf: 13,
    bf_global: "330,2",
    ct: "2,87",
    ct_global: "72,898",
    d: "25,7",
    d_global: "652,78",
    ix: "38,3",
    ix_global: "15941663,6",
    iy: 578,
    iy_global: 240581764,
    iz: 6820,
    iz_global: 2838698323,
    od: "2,07",
    od_global: "52,578",
    rz: "1,25",
    rz_global: "31,75",
    t: "21,56",
    t_global: "547,624",
    tf: "1,57",
    tf_global: "39,878",
    tw: "0,87",
    tw_global: "22,098",
    zx: 606,
    zx_global: "9930560,784",
    zy: 137,
    zy_global: "2245027,768",
    width: 13,
    width_global: "330,2",
    thickness: "1,57",
    thickness_global: "39,878",
    height: "25,7",
    height_global: "652,78",
  },
  {
    profile_section_id: 111,
    name: "W24X192",
    type: "W Section",
    designation: "W24X192",
    shape: "I",
    country_code: "American",
    ax: "56,5",
    ax_global: "36451,54",
    bf: 13,
    bf_global: "330,2",
    ct: "2,8",
    ct_global: "71,12",
    d: "25,5",
    d_global: "647,7",
    ix: "30,8",
    ix_global: "12819927,91",
    iy: 530,
    iy_global: "220602655,6",
    iz: 6260,
    iz_global: 2605608724,
    od: "1,96",
    od_global: "49,784",
    rz: "1,25",
    rz_global: "31,75",
    t: "21,58",
    t_global: "548,132",
    tf: "1,46",
    tf_global: "37,084",
    tw: "0,81",
    tw_global: "20,574",
    zx: 559,
    zx_global: "9160368,776",
    zy: 126,
    zy_global: "2064770,064",
    width: 13,
    width_global: "330,2",
    thickness: "1,46",
    thickness_global: "37,084",
    height: "25,5",
    height_global: "647,7",
  },
  {
    profile_section_id: 112,
    name: "W24X176",
    type: "W Section",
    designation: "W24X176",
    shape: "I",
    country_code: "American",
    ax: "51,7",
    ax_global: "33354,772",
    bf: "12,9",
    bf_global: "327,66",
    ct: "2,74",
    ct_global: "69,596",
    d: "25,2",
    d_global: "640,08",
    ix: "23,9",
    ix_global: "9947931,072",
    iy: 479,
    iy_global: "199374852,9",
    iz: 5680,
    iz_global: 2364194497,
    od: "1,84",
    od_global: "46,736",
    rz: "1,19",
    rz_global: "30,226",
    t: "21,52",
    t_global: "546,608",
    tf: "1,34",
    tf_global: "34,036",
    tw: "0,75",
    tw_global: "19,05",
    zx: 511,
    zx_global: "8373789,704",
    zy: 115,
    zy_global: "1884512,36",
    width: "12,9",
    width_global: "327,66",
    thickness: "1,34",
    thickness_global: "34,036",
    height: "25,2",
    height_global: "640,08",
  },
  {
    profile_section_id: 113,
    name: "W24X162",
    type: "W Section",
    designation: "W24X162",
    shape: "I",
    country_code: "American",
    ax: "47,8",
    ax_global: "30838,648",
    bf: 13,
    bf_global: "330,2",
    ct: "2,7",
    ct_global: "68,58",
    d: 25,
    d_global: 635,
    ix: "18,5",
    ix_global: "7700281,374",
    iy: 443,
    iy_global: "184390521,5",
    iz: 5170,
    iz_global: 2151916470,
    od: "1,72",
    od_global: "43,688",
    rz: "1,19",
    rz_global: "30,226",
    t: "21,56",
    t_global: "547,624",
    tf: "1,22",
    tf_global: "30,988",
    tw: "0,7",
    tw_global: "17,78",
    zx: 468,
    zx_global: "7669145,952",
    zy: 105,
    zy_global: "1720641,72",
    width: 13,
    width_global: "330,2",
    thickness: "1,22",
    thickness_global: "30,988",
    height: 25,
    height_global: 635,
  },
  {
    profile_section_id: 114,
    name: "W24X146",
    type: "W Section",
    designation: "W24X146",
    shape: "I",
    country_code: "American",
    ax: 43,
    ax_global: "27741,88",
    bf: "12,9",
    bf_global: "327,66",
    ct: "2,66",
    ct_global: "67,564",
    d: "24,7",
    d_global: "627,38",
    ix: "13,4",
    ix_global: "5577501,103",
    iy: 391,
    iy_global: "162746487,4",
    iz: 4580,
    iz_global: 1906339929,
    od: "1,59",
    od_global: "40,386",
    rz: "1,13",
    rz_global: "28,702",
    t: "21,52",
    t_global: "546,608",
    tf: "1,09",
    tf_global: "27,686",
    tw: "0,65",
    tw_global: "16,51",
    zx: 418,
    zx_global: "6849792,752",
    zy: "93,2",
    zy_global: "1527274,365",
    width: "12,9",
    width_global: "327,66",
    thickness: "1,09",
    thickness_global: "27,686",
    height: "24,7",
    height_global: "627,38",
  },
  {
    profile_section_id: 115,
    name: "W24X131",
    type: "W Section",
    designation: "W24X131",
    shape: "I",
    country_code: "American",
    ax: "38,6",
    ax_global: "24903,176",
    bf: "12,9",
    bf_global: "327,66",
    ct: "2,65",
    ct_global: "67,31",
    d: "24,5",
    d_global: "622,3",
    ix: "9,5",
    ix_global: "3954198,543",
    iy: 340,
    iy_global: "141518684,7",
    iz: 4020,
    iz_global: 1673250331,
    od: "1,46",
    od_global: "37,084",
    rz: "1,13",
    rz_global: "28,702",
    t: "21,58",
    t_global: "548,132",
    tf: "0,96",
    tf_global: "24,384",
    tw: "0,6",
    tw_global: "15,24",
    zx: 370,
    zx_global: "6063213,68",
    zy: "81,5",
    zy_global: "1335545,716",
    width: "12,9",
    width_global: "327,66",
    thickness: "0,96",
    thickness_global: "24,384",
    height: "24,5",
    height_global: "622,3",
  },
  {
    profile_section_id: 116,
    name: "W24X117",
    type: "W Section",
    designation: "W24X117",
    shape: "I",
    country_code: "American",
    ax: "34,4",
    ax_global: "22193,504",
    bf: "12,8",
    bf_global: "325,12",
    ct: "2,62",
    ct_global: "66,548",
    d: "24,3",
    d_global: "617,22",
    ix: "6,72",
    ix_global: "2797075,18",
    iy: 297,
    iy_global: "123620733,4",
    iz: 3540,
    iz_global: 1473459247,
    od: "1,35",
    od_global: "34,29",
    rz: "1,13",
    rz_global: "28,702",
    t: "21,6",
    t_global: "548,64",
    tf: "0,85",
    tf_global: "21,59",
    tw: "0,55",
    tw_global: "13,97",
    zx: 327,
    zx_global: "5358569,928",
    zy: "71,4",
    zy_global: "1170036,37",
    width: "12,8",
    width_global: "325,12",
    thickness: "0,85",
    thickness_global: "21,59",
    height: "24,3",
    height_global: "617,22",
  },
  {
    profile_section_id: 117,
    name: "W24X104",
    type: "W Section",
    designation: "W24X104",
    shape: "I",
    country_code: "American",
    ax: "30,7",
    ax_global: "19806,412",
    bf: "12,8",
    bf_global: "325,12",
    ct: "2,59",
    ct_global: "65,786",
    d: "24,1",
    d_global: "612,14",
    ix: "4,72",
    ix_global: "1964612,329",
    iy: 259,
    iy_global: "107803939,2",
    iz: 3100,
    iz_global: 1290317419,
    od: "1,25",
    od_global: "31,75",
    rz: "1,06",
    rz_global: "26,924",
    t: "21,6",
    t_global: "548,64",
    tf: "0,75",
    tf_global: "19,05",
    tw: "0,5",
    tw_global: "12,7",
    zx: 289,
    zx_global: "4735861,496",
    zy: "62,4",
    zy_global: "1022552,794",
    width: "12,8",
    width_global: "325,12",
    thickness: "0,75",
    thickness_global: "19,05",
    height: "24,1",
    height_global: "612,14",
  },
  {
    profile_section_id: 118,
    name: "W24X103",
    type: "W Section",
    designation: "W24X103",
    shape: "I",
    country_code: "American",
    ax: "30,3",
    ax_global: "19548,348",
    bf: 9,
    bf_global: "228,6",
    ct: "3,01",
    ct_global: "76,454",
    d: "24,5",
    d_global: "622,3",
    ix: "7,07",
    ix_global: "2942756,179",
    iy: 119,
    iy_global: "49531539,65",
    iz: 3000,
    iz_global: 1248694277,
    od: "1,48",
    od_global: "37,592",
    rz: "1,13",
    rz_global: "28,702",
    t: "21,54",
    t_global: "547,116",
    tf: "0,98",
    tf_global: "24,892",
    tw: "0,55",
    tw_global: "13,97",
    zx: 280,
    zx_global: "4588377,92",
    zy: "41,5",
    zy_global: "680063,156",
    width: 9,
    width_global: "228,6",
    thickness: "0,98",
    thickness_global: "24,892",
    height: "24,5",
    height_global: "622,3",
  },
  {
    profile_section_id: 119,
    name: "W24X94",
    type: "W Section",
    designation: "W24X94",
    shape: "I",
    country_code: "American",
    ax: "27,7",
    ax_global: "17870,932",
    bf: "9,07",
    bf_global: "230,378",
    ct: "2,99",
    ct_global: "75,946",
    d: "24,3",
    d_global: "617,22",
    ix: "5,26",
    ix_global: "2189377,299",
    iy: 109,
    iy_global: "45369225,39",
    iz: 2700,
    iz_global: 1123824849,
    od: "1,38",
    od_global: "35,052",
    rz: "1,06",
    rz_global: "26,924",
    t: "21,54",
    t_global: "547,116",
    tf: "0,88",
    tf_global: "22,352",
    tw: "0,52",
    tw_global: "13,208",
    zx: 254,
    zx_global: "4162314,256",
    zy: "37,5",
    zy_global: "614514,9",
    width: "9,07",
    width_global: "230,378",
    thickness: "0,88",
    thickness_global: "22,352",
    height: "24,3",
    height_global: "617,22",
  },
  {
    profile_section_id: 120,
    name: "W24X84",
    type: "W Section",
    designation: "W24X84",
    shape: "I",
    country_code: "American",
    ax: "24,7",
    ax_global: "15935,452",
    bf: "9,02",
    bf_global: "229,108",
    ct: "2,97",
    ct_global: "75,438",
    d: "24,1",
    d_global: "612,14",
    ix: "3,7",
    ix_global: "1540056,275",
    iy: "94,4",
    iy_global: "39292246,58",
    iz: 2370,
    iz_global: "986468478,7",
    od: "1,27",
    od_global: "32,258",
    rz: "1,06",
    rz_global: "26,924",
    t: "21,56",
    t_global: "547,624",
    tf: "0,77",
    tf_global: "19,558",
    tw: "0,47",
    tw_global: "11,938",
    zx: 224,
    zx_global: "3670702,336",
    zy: "32,6",
    zy_global: "534218,2864",
    width: "9,02",
    width_global: "229,108",
    thickness: "0,77",
    thickness_global: "19,558",
    height: "24,1",
    height_global: "612,14",
  },
  {
    profile_section_id: 121,
    name: "W24X76",
    type: "W Section",
    designation: "W24X76",
    shape: "I",
    country_code: "American",
    ax: "22,4",
    ax_global: "14451,584",
    bf: "8,99",
    bf_global: "228,346",
    ct: 3,
    ct_global: "76,2",
    d: "23,9",
    d_global: "607,06",
    ix: "2,68",
    ix_global: "1115500,221",
    iy: "82,5",
    iy_global: "34339092,61",
    iz: 2100,
    iz_global: "874085993,8",
    od: "1,18",
    od_global: "29,972",
    rz: "1,06",
    rz_global: "26,924",
    t: "21,54",
    t_global: "547,116",
    tf: "0,68",
    tf_global: "17,272",
    tw: "0,44",
    tw_global: "11,176",
    zx: 200,
    zx_global: "3277412,8",
    zy: "28,6",
    zy_global: "468670,0304",
    width: "8,99",
    width_global: "228,346",
    thickness: "0,68",
    thickness_global: "17,272",
    height: "23,9",
    height_global: "607,06",
  },
  {
    profile_section_id: 122,
    name: "W24X68",
    type: "W Section",
    designation: "W24X68",
    shape: "I",
    country_code: "American",
    ax: "20,1",
    ax_global: "12967,716",
    bf: "8,97",
    bf_global: "227,838",
    ct: "3,06",
    ct_global: "77,724",
    d: "23,7",
    d_global: "601,98",
    ix: "1,87",
    ix_global: "778352,7659",
    iy: "70,4",
    iy_global: "29302692,36",
    iz: 1830,
    iz_global: "761703508,8",
    od: "1,09",
    od_global: "27,686",
    rz: "1,06",
    rz_global: "26,924",
    t: "21,52",
    t_global: "546,608",
    tf: "0,58",
    tf_global: "14,732",
    tw: "0,41",
    tw_global: "10,414",
    zx: 177,
    zx_global: "2900510,328",
    zy: "24,5",
    zy_global: "401483,068",
    width: "8,97",
    width_global: "227,838",
    thickness: "0,58",
    thickness_global: "14,732",
    height: "23,7",
    height_global: "601,98",
  },
  {
    profile_section_id: 123,
    name: "W24X62",
    type: "W Section",
    designation: "W24X62",
    shape: "I",
    country_code: "American",
    ax: "18,2",
    ax_global: "11741,912",
    bf: "7,04",
    bf_global: "178,816",
    ct: "3,46",
    ct_global: "87,884",
    d: "23,7",
    d_global: "601,98",
    ix: "1,71",
    ix_global: "711755,7378",
    iy: "34,5",
    iy_global: "14359984,18",
    iz: 1550,
    iz_global: "645158709,7",
    od: "1,09",
    od_global: "27,686",
    rz: "1,06",
    rz_global: "26,924",
    t: "21,52",
    t_global: "546,608",
    tf: "0,59",
    tf_global: "14,986",
    tw: "0,43",
    tw_global: "10,922",
    zx: 153,
    zx_global: "2507220,792",
    zy: "15,7",
    zy_global: "257276,9048",
    width: "7,04",
    width_global: "178,816",
    thickness: "0,59",
    thickness_global: "14,986",
    height: "23,7",
    height_global: "601,98",
  },
  {
    profile_section_id: 124,
    name: "W24X55",
    type: "W Section",
    designation: "W24X55",
    shape: "I",
    country_code: "American",
    ax: "16,2",
    ax_global: "10451,592",
    bf: "7,01",
    bf_global: "178,054",
    ct: "3,5",
    ct_global: "88,9",
    d: "23,6",
    d_global: "599,44",
    ix: "1,18",
    ix_global: "491153,0822",
    iy: "29,1",
    iy_global: "12112334,48",
    iz: 1350,
    iz_global: "561912424,6",
    od: "1,01",
    od_global: "25,654",
    rz: 1,
    rz_global: "25,4",
    t: "21,58",
    t_global: "548,132",
    tf: "0,51",
    tf_global: "12,954",
    tw: "0,4",
    tw_global: "10,16",
    zx: 134,
    zx_global: "2195866,576",
    zy: "13,3",
    zy_global: "217947,9512",
    width: "7,01",
    width_global: "178,054",
    thickness: "0,51",
    thickness_global: "12,954",
    height: "23,6",
    height_global: "599,44",
  },
  {
    profile_section_id: 125,
    name: "W21X201",
    type: "W Section",
    designation: "W21X201",
    shape: "I",
    country_code: "American",
    ax: "59,3",
    ax_global: "38257,988",
    bf: "12,6",
    bf_global: "320,04",
    ct: "2,57",
    ct_global: "65,278",
    d: 23,
    d_global: "584,2",
    ix: "40,9",
    ix_global: "17023865,31",
    iy: 542,
    iy_global: "225597432,7",
    iz: 5310,
    iz_global: 2210188870,
    od: "2,13",
    od_global: "54,102",
    rz: "1,31",
    rz_global: "33,274",
    t: "18,74",
    t_global: "475,996",
    tf: "1,63",
    tf_global: "41,402",
    tw: "0,91",
    tw_global: "23,114",
    zx: 530,
    zx_global: "8685143,92",
    zy: 133,
    zy_global: "2179479,512",
    width: "12,6",
    width_global: "320,04",
    thickness: "1,63",
    thickness_global: "41,402",
    height: 23,
    height_global: "584,2",
  },
  {
    profile_section_id: 126,
    name: "W21X182",
    type: "W Section",
    designation: "W21X182",
    shape: "I",
    country_code: "American",
    ax: "53,6",
    ax_global: "34580,576",
    bf: "12,5",
    bf_global: "317,5",
    ct: "2,48",
    ct_global: "62,992",
    d: "22,7",
    d_global: "576,58",
    ix: "30,7",
    ix_global: "12778304,77",
    iy: 483,
    iy_global: "201039778,6",
    iz: 4730,
    iz_global: 1968774643,
    od: "1,98",
    od_global: "50,292",
    rz: "1,25",
    rz_global: "31,75",
    t: "18,74",
    t_global: "475,996",
    tf: "1,48",
    tf_global: "37,592",
    tw: "0,83",
    tw_global: "21,082",
    zx: 476,
    zx_global: "7800242,464",
    zy: 119,
    zy_global: "1950060,616",
    width: "12,5",
    width_global: "317,5",
    thickness: "1,48",
    thickness_global: "37,592",
    height: "22,7",
    height_global: "576,58",
  },
  {
    profile_section_id: 127,
    name: "W21X166",
    type: "W Section",
    designation: "W21X166",
    shape: "I",
    country_code: "American",
    ax: "48,8",
    ax_global: "31483,808",
    bf: "12,4",
    bf_global: "314,96",
    ct: "2,39",
    ct_global: "60,706",
    d: "22,5",
    d_global: "571,5",
    ix: "23,6",
    ix_global: "9823061,644",
    iy: 435,
    iy_global: "181060670,1",
    iz: 4280,
    iz_global: 1781470502,
    od: "1,86",
    od_global: "47,244",
    rz: "1,19",
    rz_global: "30,226",
    t: "18,78",
    t_global: "477,012",
    tf: "1,36",
    tf_global: "34,544",
    tw: "0,75",
    tw_global: "19,05",
    zx: 432,
    zx_global: "7079211,648",
    zy: 108,
    zy_global: "1769802,912",
    width: "12,4",
    width_global: "314,96",
    thickness: "1,36",
    thickness_global: "34,544",
    height: "22,5",
    height_global: "571,5",
  },
  {
    profile_section_id: 128,
    name: "W21X147",
    type: "W Section",
    designation: "W21X147",
    shape: "I",
    country_code: "American",
    ax: "43,2",
    ax_global: "27870,912",
    bf: "12,5",
    bf_global: "317,5",
    ct: "2,39",
    ct_global: "60,706",
    d: "22,1",
    d_global: "561,34",
    ix: "15,4",
    ix_global: "6409963,954",
    iy: 376,
    iy_global: 156503016,
    iz: 3630,
    iz_global: 1510920075,
    od: "1,65",
    od_global: "41,91",
    rz: "1,19",
    rz_global: "30,226",
    t: "18,8",
    t_global: "477,52",
    tf: "1,15",
    tf_global: "29,21",
    tw: "0,72",
    tw_global: "18,288",
    zx: 373,
    zx_global: "6112374,872",
    zy: "92,6",
    zy_global: "1517442,126",
    width: "12,5",
    width_global: "317,5",
    thickness: "1,15",
    thickness_global: "29,21",
    height: "22,1",
    height_global: "561,34",
  },
  {
    profile_section_id: 129,
    name: "W21X132",
    type: "W Section",
    designation: "W21X132",
    shape: "I",
    country_code: "American",
    ax: "38,8",
    ax_global: "25032,208",
    bf: "12,4",
    bf_global: "314,96",
    ct: "2,33",
    ct_global: "59,182",
    d: "21,8",
    d_global: "553,72",
    ix: "11,3",
    ix_global: "4703415,109",
    iy: 333,
    iy_global: "138605064,7",
    iz: 3220,
    iz_global: 1340265190,
    od: "1,54",
    od_global: "39,116",
    rz: "1,13",
    rz_global: "28,702",
    t: "18,72",
    t_global: "475,488",
    tf: "1,04",
    tf_global: "26,416",
    tw: "0,65",
    tw_global: "16,51",
    zx: 333,
    zx_global: "5456892,312",
    zy: "82,3",
    zy_global: "1348655,367",
    width: "12,4",
    width_global: "314,96",
    thickness: "1,04",
    thickness_global: "26,416",
    height: "21,8",
    height_global: "553,72",
  },
  {
    profile_section_id: 130,
    name: "W21X122",
    type: "W Section",
    designation: "W21X122",
    shape: "I",
    country_code: "American",
    ax: "35,9",
    ax_global: "23161,244",
    bf: "12,4",
    bf_global: "314,96",
    ct: "2,28",
    ct_global: "57,912",
    d: "21,7",
    d_global: "551,18",
    ix: "8,98",
    ix_global: "3737758,202",
    iy: 305,
    iy_global: "126950584,8",
    iz: 2960,
    iz_global: 1232045020,
    od: "1,46",
    od_global: "37,084",
    rz: "1,13",
    rz_global: "28,702",
    t: "18,78",
    t_global: "477,012",
    tf: "0,96",
    tf_global: "24,384",
    tw: "0,6",
    tw_global: "15,24",
    zx: 307,
    zx_global: "5030828,648",
    zy: "75,6",
    zy_global: "1238862,038",
    width: "12,4",
    width_global: "314,96",
    thickness: "0,96",
    thickness_global: "24,384",
    height: "21,7",
    height_global: "551,18",
  },
  {
    profile_section_id: 131,
    name: "W21X111",
    type: "W Section",
    designation: "W21X111",
    shape: "I",
    country_code: "American",
    ax: "32,6",
    ax_global: "21032,216",
    bf: "12,3",
    bf_global: "312,42",
    ct: "2,23",
    ct_global: "56,642",
    d: "21,5",
    d_global: "546,1",
    ix: "6,83",
    ix_global: "2842860,637",
    iy: 274,
    iy_global: "114047410,6",
    iz: 2670,
    iz_global: 1111337906,
    od: "1,38",
    od_global: "35,052",
    rz: "1,13",
    rz_global: "28,702",
    t: "18,74",
    t_global: "475,996",
    tf: "0,88",
    tf_global: "22,352",
    tw: "0,55",
    tw_global: "13,97",
    zx: 279,
    zx_global: "4571990,856",
    zy: "68,2",
    zy_global: "1117597,765",
    width: "12,3",
    width_global: "312,42",
    thickness: "0,88",
    thickness_global: "22,352",
    height: "21,5",
    height_global: "546,1",
  },
  {
    profile_section_id: 132,
    name: "W21X101",
    type: "W Section",
    designation: "W21X101",
    shape: "I",
    country_code: "American",
    ax: "29,8",
    ax_global: "19225,768",
    bf: "12,3",
    bf_global: "312,42",
    ct: "2,18",
    ct_global: "55,372",
    d: "21,4",
    d_global: "543,56",
    ix: "5,21",
    ix_global: "2168565,727",
    iy: 248,
    iy_global: "103225393,5",
    iz: 2420,
    iz_global: 1007280050,
    od: "1,3",
    od_global: "33,02",
    rz: "1,06",
    rz_global: "26,924",
    t: "18,8",
    t_global: "477,52",
    tf: "0,8",
    tf_global: "20,32",
    tw: "0,5",
    tw_global: "12,7",
    zx: 253,
    zx_global: "4145927,192",
    zy: "61,7",
    zy_global: "1011081,849",
    width: "12,3",
    width_global: "312,42",
    thickness: "0,8",
    thickness_global: "20,32",
    height: "21,4",
    height_global: "543,56",
  },
  {
    profile_section_id: 133,
    name: "W21X93",
    type: "W Section",
    designation: "W21X93",
    shape: "I",
    country_code: "American",
    ax: "27,3",
    ax_global: "17612,868",
    bf: "8,42",
    bf_global: "213,868",
    ct: "2,74",
    ct_global: "69,596",
    d: "21,6",
    d_global: "548,64",
    ix: "6,03",
    ix_global: "2509875,496",
    iy: "92,9",
    iy_global: "38667899,44",
    iz: 2070,
    iz_global: 861599051,
    od: "1,43",
    od_global: "36,322",
    rz: "0,94",
    rz_global: "23,876",
    t: "18,74",
    t_global: "475,996",
    tf: "0,93",
    tf_global: "23,622",
    tw: "0,58",
    tw_global: "14,732",
    zx: 221,
    zx_global: "3621541,144",
    zy: "34,7",
    zy_global: "568631,1208",
    width: "8,42",
    width_global: "213,868",
    thickness: "0,93",
    thickness_global: "23,622",
    height: "21,6",
    height_global: "548,64",
  },
  {
    profile_section_id: 134,
    name: "W21X83",
    type: "W Section",
    designation: "W21X83",
    shape: "I",
    country_code: "American",
    ax: "24,4",
    ax_global: "15741,904",
    bf: "8,36",
    bf_global: "212,344",
    ct: "2,66",
    ct_global: "67,564",
    d: "21,4",
    d_global: "543,56",
    ix: "4,34",
    ix_global: "1806444,387",
    iy: "81,4",
    iy_global: "33881238,04",
    iz: 1830,
    iz_global: "761703508,8",
    od: "1,34",
    od_global: "34,036",
    rz: "0,88",
    rz_global: "22,352",
    t: "18,72",
    t_global: "475,488",
    tf: "0,83",
    tf_global: "21,082",
    tw: "0,52",
    tw_global: "13,208",
    zx: 196,
    zx_global: "3211864,544",
    zy: "30,5",
    zy_global: "499805,452",
    width: "8,36",
    width_global: "212,344",
    thickness: "0,83",
    thickness_global: "21,082",
    height: "21,4",
    height_global: "543,56",
  },
  {
    profile_section_id: 135,
    name: "W21X73",
    type: "W Section",
    designation: "W21X73",
    shape: "I",
    country_code: "American",
    ax: "21,5",
    ax_global: "13870,94",
    bf: "8,3",
    bf_global: "210,82",
    ct: "2,6",
    ct_global: "66,04",
    d: "21,2",
    d_global: "538,48",
    ix: "3,02",
    ix_global: "1257018,905",
    iy: "70,6",
    iy_global: "29385938,65",
    iz: 1600,
    iz_global: 665970281,
    od: "1,24",
    od_global: "31,496",
    rz: "0,88",
    rz_global: "22,352",
    t: "18,72",
    t_global: "475,488",
    tf: "0,74",
    tf_global: "18,796",
    tw: "0,46",
    tw_global: "11,684",
    zx: 172,
    zx_global: "2818575,008",
    zy: "26,6",
    zy_global: "435895,9024",
    width: "8,3",
    width_global: "210,82",
    thickness: "0,74",
    thickness_global: "18,796",
    height: "21,2",
    height_global: "538,48",
  },
  {
    profile_section_id: 136,
    name: "W21X68",
    type: "W Section",
    designation: "W21X68",
    shape: "I",
    country_code: "American",
    ax: 20,
    ax_global: "12903,2",
    bf: "8,27",
    bf_global: "210,058",
    ct: "2,59",
    ct_global: "65,786",
    d: "21,1",
    d_global: "535,94",
    ix: "2,45",
    ix_global: "1019766,993",
    iy: "64,7",
    iy_global: "26930173,24",
    iz: 1480,
    iz_global: "616022509,9",
    od: "1,19",
    od_global: "30,226",
    rz: "0,88",
    rz_global: "22,352",
    t: "18,72",
    t_global: "475,488",
    tf: "0,69",
    tf_global: "17,526",
    tw: "0,43",
    tw_global: "10,922",
    zx: 160,
    zx_global: "2621930,24",
    zy: "24,4",
    zy_global: "399844,3616",
    width: "8,27",
    width_global: "210,058",
    thickness: "0,69",
    thickness_global: "17,526",
    height: "21,1",
    height_global: "535,94",
  },
  {
    profile_section_id: 137,
    name: "W21X62",
    type: "W Section",
    designation: "W21X62",
    shape: "I",
    country_code: "American",
    ax: "18,3",
    ax_global: "11806,428",
    bf: "8,24",
    bf_global: "209,296",
    ct: "2,58",
    ct_global: "65,532",
    d: 21,
    d_global: "533,4",
    ix: "1,83",
    ix_global: "761703,5088",
    iy: "57,5",
    iy_global: "23933306,97",
    iz: 1330,
    iz_global: 553587796,
    od: "1,12",
    od_global: "28,448",
    rz: "0,81",
    rz_global: "20,574",
    t: "18,76",
    t_global: "476,504",
    tf: "0,61",
    tf_global: "15,494",
    tw: "0,4",
    tw_global: "10,16",
    zx: 144,
    zx_global: "2359737,216",
    zy: "21,7",
    zy_global: "355599,2888",
    width: "8,24",
    width_global: "209,296",
    thickness: "0,61",
    thickness_global: "15,494",
    height: 21,
    height_global: "533,4",
  },
  {
    profile_section_id: 138,
    name: "W21X55",
    type: "W Section",
    designation: "W21X55",
    shape: "I",
    country_code: "American",
    ax: "16,2",
    ax_global: "10451,592",
    bf: "8,22",
    bf_global: "208,788",
    ct: "2,64",
    ct_global: "67,056",
    d: "20,8",
    d_global: "528,32",
    ix: "1,24",
    ix_global: "516126,9677",
    iy: "48,4",
    iy_global: 20145601,
    iz: 1140,
    iz_global: "474503825,2",
    od: "1,02",
    od_global: "25,908",
    rz: "0,81",
    rz_global: "20,574",
    t: "18,76",
    t_global: "476,504",
    tf: "0,52",
    tf_global: "13,208",
    tw: "0,38",
    tw_global: "9,652",
    zx: 126,
    zx_global: "2064770,064",
    zy: "18,4",
    zy_global: "301521,9776",
    width: "8,22",
    width_global: "208,788",
    thickness: "0,52",
    thickness_global: "13,208",
    height: "20,8",
    height_global: "528,32",
  },
  {
    profile_section_id: 139,
    name: "W21X48",
    type: "W Section",
    designation: "W21X48",
    shape: "I",
    country_code: "American",
    ax: "14,1",
    ax_global: "9096,756",
    bf: "8,14",
    bf_global: "206,756",
    ct: "2,74",
    ct_global: "69,596",
    d: "20,6",
    d_global: "523,24",
    ix: "0,8",
    ix_global: "332985,1405",
    iy: "38,7",
    iy_global: "16108156,17",
    iz: 959,
    iz_global: "399165937,2",
    od: "0,93",
    od_global: "23,622",
    rz: "0,81",
    rz_global: "20,574",
    t: "18,74",
    t_global: "475,996",
    tf: "0,43",
    tf_global: "10,922",
    tw: "0,35",
    tw_global: "8,89",
    zx: 107,
    zx_global: "1753415,848",
    zy: "14,9",
    zy_global: "244167,2536",
    width: "8,14",
    width_global: "206,756",
    thickness: "0,43",
    thickness_global: "10,922",
    height: "20,6",
    height_global: "523,24",
  },
  {
    profile_section_id: 140,
    name: "W21X57",
    type: "W Section",
    designation: "W21X57",
    shape: "I",
    country_code: "American",
    ax: "16,7",
    ax_global: "10774,172",
    bf: "6,56",
    bf_global: "166,624",
    ct: "2,85",
    ct_global: "72,39",
    d: "21,1",
    d_global: "535,94",
    ix: "1,77",
    ix_global: "736729,6233",
    iy: "30,6",
    iy_global: "12736681,62",
    iz: 1170,
    iz_global: 486990768,
    od: "1,15",
    od_global: "29,21",
    rz: "0,81",
    rz_global: "20,574",
    t: "18,8",
    t_global: "477,52",
    tf: "0,65",
    tf_global: "16,51",
    tw: "0,41",
    tw_global: "10,414",
    zx: 129,
    zx_global: "2113931,256",
    zy: "14,8",
    zy_global: "242528,5472",
    width: "6,56",
    width_global: "166,624",
    thickness: "0,65",
    thickness_global: "16,51",
    height: "21,1",
    height_global: "535,94",
  },
  {
    profile_section_id: 141,
    name: "W21X50",
    type: "W Section",
    designation: "W21X50",
    shape: "I",
    country_code: "American",
    ax: "14,7",
    ax_global: "9483,852",
    bf: "6,53",
    bf_global: "165,862",
    ct: "2,93",
    ct_global: "74,422",
    d: "20,8",
    d_global: "528,32",
    ix: "1,14",
    ix_global: "474503,8252",
    iy: "24,9",
    iy_global: "10364162,5",
    iz: 984,
    iz_global: "409571722,8",
    od: "1,04",
    od_global: "26,416",
    rz: "0,81",
    rz_global: "20,574",
    t: "18,72",
    t_global: "475,488",
    tf: "0,54",
    tf_global: "13,716",
    tw: "0,38",
    tw_global: "9,652",
    zx: 110,
    zx_global: "1802577,04",
    zy: "12,2",
    zy_global: "199922,1808",
    width: "6,53",
    width_global: "165,862",
    thickness: "0,54",
    thickness_global: "13,716",
    height: "20,8",
    height_global: "528,32",
  },
  {
    profile_section_id: 142,
    name: "W21X44",
    type: "W Section",
    designation: "W21X44",
    shape: "I",
    country_code: "American",
    ax: 13,
    ax_global: "8387,08",
    bf: "6,5",
    bf_global: "165,1",
    ct: "2,98",
    ct_global: "75,692",
    d: "20,7",
    d_global: "525,78",
    ix: "0,77",
    ix_global: "320498,1977",
    iy: "20,7",
    iy_global: "8615990,51",
    iz: 843,
    iz_global: "350883091,8",
    od: "0,95",
    od_global: "24,13",
    rz: "0,81",
    rz_global: "20,574",
    t: "18,8",
    t_global: "477,52",
    tf: "0,45",
    tf_global: "11,43",
    tw: "0,35",
    tw_global: "8,89",
    zx: "95,4",
    zx_global: "1563325,906",
    zy: "10,2",
    zy_global: "167148,0528",
    width: "6,5",
    width_global: "165,1",
    thickness: "0,45",
    thickness_global: "11,43",
    height: "20,7",
    height_global: "525,78",
  },
  {
    profile_section_id: 143,
    name: "W18X311",
    type: "W Section",
    designation: "W18X311",
    shape: "I",
    country_code: "American",
    ax: "91,6",
    ax_global: "59096,656",
    bf: 12,
    bf_global: "304,8",
    ct: "2,93",
    ct_global: "74,422",
    d: "22,3",
    d_global: "566,42",
    ix: 176,
    ix_global: "73256730,91",
    iy: 795,
    iy_global: "330903983,4",
    iz: 6970,
    iz_global: 2901133036,
    od: "3,24",
    od_global: "82,296",
    rz: "1,38",
    rz_global: "35,052",
    t: "15,82",
    t_global: "401,828",
    tf: "2,74",
    tf_global: "69,596",
    tw: "1,52",
    tw_global: "38,608",
    zx: 754,
    zx_global: "12355846,26",
    zy: 207,
    zy_global: "3392122,248",
    width: 12,
    width_global: "304,8",
    thickness: "2,74",
    thickness_global: "69,596",
    height: "22,3",
    height_global: "566,42",
  },
  {
    profile_section_id: 144,
    name: "W18X283",
    type: "W Section",
    designation: "W18X283",
    shape: "I",
    country_code: "American",
    ax: "83,3",
    ax_global: "53741,828",
    bf: "11,9",
    bf_global: "302,26",
    ct: "2,8",
    ct_global: "71,12",
    d: "21,9",
    d_global: "556,26",
    ix: 134,
    ix_global: "55775011,03",
    iy: 704,
    iy_global: "293026923,6",
    iz: 6170,
    iz_global: 2568147896,
    od: 3,
    od_global: "76,2",
    rz: "1,31",
    rz_global: "33,274",
    t: "15,9",
    t_global: "403,86",
    tf: "2,5",
    tf_global: "63,5",
    tw: "1,4",
    tw_global: "35,56",
    zx: 676,
    zx_global: "11077655,26",
    zy: 185,
    zy_global: "3031606,84",
    width: "11,9",
    width_global: "302,26",
    thickness: "2,5",
    thickness_global: "63,5",
    height: "21,9",
    height_global: "556,26",
  },
  {
    profile_section_id: 145,
    name: "W18X258",
    type: "W Section",
    designation: "W18X258",
    shape: "I",
    country_code: "American",
    ax: 76,
    ax_global: "49032,16",
    bf: "11,8",
    bf_global: "299,72",
    ct: "2,68",
    ct_global: "68,072",
    d: "21,5",
    d_global: "546,1",
    ix: 103,
    ix_global: "42871836,84",
    iy: 628,
    iy_global: "261393335,3",
    iz: 5510,
    iz_global: 2293435155,
    od: "2,7",
    od_global: "68,58",
    rz: "1,25",
    rz_global: "31,75",
    t: "16,1",
    t_global: "408,94",
    tf: "2,3",
    tf_global: "58,42",
    tw: "1,28",
    tw_global: "32,512",
    zx: 611,
    zx_global: "10012496,1",
    zy: 166,
    zy_global: "2720252,624",
    width: "11,8",
    width_global: "299,72",
    thickness: "2,3",
    thickness_global: "58,42",
    height: "21,5",
    height_global: "546,1",
  },
  {
    profile_section_id: 146,
    name: "W18X234",
    type: "W Section",
    designation: "W18X234",
    shape: "I",
    country_code: "American",
    ax: "68,6",
    ax_global: "44257,976",
    bf: "11,7",
    bf_global: "297,18",
    ct: "2,55",
    ct_global: "64,77",
    d: "21,1",
    d_global: "535,94",
    ix: "78,7",
    ix_global: "32757413,19",
    iy: 558,
    iy_global: "232257135,5",
    iz: 4900,
    iz_global: 2039533985,
    od: "2,51",
    od_global: "63,754",
    rz: "1,19",
    rz_global: "30,226",
    t: "16,08",
    t_global: "408,432",
    tf: "2,11",
    tf_global: "53,594",
    tw: "1,16",
    tw_global: "29,464",
    zx: 549,
    zx_global: "8996498,136",
    zy: 149,
    zy_global: "2441672,536",
    width: "11,7",
    width_global: "297,18",
    thickness: "2,11",
    thickness_global: "53,594",
    height: "21,1",
    height_global: "535,94",
  },
  {
    profile_section_id: 147,
    name: "W18X211",
    type: "W Section",
    designation: "W18X211",
    shape: "I",
    country_code: "American",
    ax: "62,3",
    ax_global: "40193,468",
    bf: "11,6",
    bf_global: "294,64",
    ct: "2,44",
    ct_global: "61,976",
    d: "20,7",
    d_global: "525,78",
    ix: "58,6",
    ix_global: "24391161,54",
    iy: 493,
    iy_global: "205202092,8",
    iz: 4330,
    iz_global: 1802282073,
    od: "2,31",
    od_global: "58,674",
    rz: "1,19",
    rz_global: "30,226",
    t: "16,08",
    t_global: "408,432",
    tf: "1,91",
    tf_global: "48,514",
    tw: "1,06",
    tw_global: "26,924",
    zx: 490,
    zx_global: "8029661,36",
    zy: 132,
    zy_global: "2163092,448",
    width: "11,6",
    width_global: "294,64",
    thickness: "1,91",
    thickness_global: "48,514",
    height: "20,7",
    height_global: "525,78",
  },
  {
    profile_section_id: 148,
    name: "W18X192",
    type: "W Section",
    designation: "W18X192",
    shape: "I",
    country_code: "American",
    ax: "56,2",
    ax_global: "36257,992",
    bf: "11,5",
    bf_global: "292,1",
    ct: "2,34",
    ct_global: "59,436",
    d: "20,4",
    d_global: "518,16",
    ix: "44,7",
    ix_global: "18605544,72",
    iy: 440,
    iy_global: "183141827,3",
    iz: 3870,
    iz_global: 1610815617,
    od: "2,15",
    od_global: "54,61",
    rz: "1,13",
    rz_global: "28,702",
    t: "16,1",
    t_global: "408,94",
    tf: "1,75",
    tf_global: "44,45",
    tw: "0,96",
    tw_global: "24,384",
    zx: 442,
    zx_global: "7243082,288",
    zy: 119,
    zy_global: "1950060,616",
    width: "11,5",
    width_global: "292,1",
    thickness: "1,75",
    thickness_global: "44,45",
    height: "20,4",
    height_global: "518,16",
  },
  {
    profile_section_id: 149,
    name: "W18X175",
    type: "W Section",
    designation: "W18X175",
    shape: "I",
    country_code: "American",
    ax: "51,4",
    ax_global: "33161,224",
    bf: "11,4",
    bf_global: "289,56",
    ct: "2,26",
    ct_global: "57,404",
    d: 20,
    d_global: 508,
    ix: "33,8",
    ix_global: "14068622,19",
    iy: 391,
    iy_global: "162746487,4",
    iz: 3450,
    iz_global: 1435998418,
    od: "1,99",
    od_global: "50,546",
    rz: "1,25",
    rz_global: "31,75",
    t: "16,02",
    t_global: "406,908",
    tf: "1,59",
    tf_global: "40,386",
    tw: "0,89",
    tw_global: "22,606",
    zx: 398,
    zx_global: "6522051,472",
    zy: 106,
    zy_global: "1737028,784",
    width: "11,4",
    width_global: "289,56",
    thickness: "1,59",
    thickness_global: "40,386",
    height: 20,
    height_global: 508,
  },
  {
    profile_section_id: 150,
    name: "W18X158",
    type: "W Section",
    designation: "W18X158",
    shape: "I",
    country_code: "American",
    ax: "46,3",
    ax_global: "29870,908",
    bf: "11,3",
    bf_global: "287,02",
    ct: "2,17",
    ct_global: "55,118",
    d: "19,7",
    d_global: "500,38",
    ix: "25,2",
    ix_global: "10489031,93",
    iy: 347,
    iy_global: "144432304,7",
    iz: 3060,
    iz_global: 1273668162,
    od: "1,84",
    od_global: "46,736",
    rz: "1,25",
    rz_global: "31,75",
    t: "16,02",
    t_global: "406,908",
    tf: "1,44",
    tf_global: "36,576",
    tw: "0,81",
    tw_global: "20,574",
    zx: 356,
    zx_global: "5833794,784",
    zy: "94,8",
    zy_global: "1553493,667",
    width: "11,3",
    width_global: "287,02",
    thickness: "1,44",
    thickness_global: "36,576",
    height: "19,7",
    height_global: "500,38",
  },
  {
    profile_section_id: 151,
    name: "W18X143",
    type: "W Section",
    designation: "W18X143",
    shape: "I",
    country_code: "American",
    ax: 42,
    ax_global: "27096,72",
    bf: "11,2",
    bf_global: "284,48",
    ct: "2,09",
    ct_global: "53,086",
    d: "19,5",
    d_global: "495,3",
    ix: "19,2",
    ix_global: "7991643,372",
    iy: 311,
    iy_global: "129447973,4",
    iz: 2750,
    iz_global: 1144636420,
    od: "1,72",
    od_global: "43,688",
    rz: "1,19",
    rz_global: "30,226",
    t: "16,06",
    t_global: "407,924",
    tf: "1,32",
    tf_global: "33,528",
    tw: "0,73",
    tw_global: "18,542",
    zx: 322,
    zx_global: "5276634,608",
    zy: "85,4",
    zy_global: "1399455,266",
    width: "11,2",
    width_global: "284,48",
    thickness: "1,32",
    thickness_global: "33,528",
    height: "19,5",
    height_global: "495,3",
  },
  {
    profile_section_id: 152,
    name: "W18X130",
    type: "W Section",
    designation: "W18X130",
    shape: "I",
    country_code: "American",
    ax: "38,3",
    ax_global: "24709,628",
    bf: "11,2",
    bf_global: "284,48",
    ct: "2,02",
    ct_global: "51,308",
    d: "19,3",
    d_global: "490,22",
    ix: "14,5",
    ix_global: "6035355,671",
    iy: 278,
    iy_global: "115712336,3",
    iz: 2460,
    iz_global: 1023929307,
    od: "1,6",
    od_global: "40,64",
    rz: "1,19",
    rz_global: "30,226",
    t: "16,1",
    t_global: "408,94",
    tf: "1,2",
    tf_global: "30,48",
    tw: "0,67",
    tw_global: "17,018",
    zx: 290,
    zx_global: "4752248,56",
    zy: "76,7",
    zy_global: "1256887,809",
    width: "11,2",
    width_global: "284,48",
    thickness: "1,2",
    thickness_global: "30,48",
    height: "19,3",
    height_global: "490,22",
  },
  {
    profile_section_id: 153,
    name: "W18X119",
    type: "W Section",
    designation: "W18X119",
    shape: "I",
    country_code: "American",
    ax: "35,1",
    ax_global: "22645,116",
    bf: "11,3",
    bf_global: "287,02",
    ct: "2,03",
    ct_global: "51,562",
    d: 19,
    d_global: "482,6",
    ix: "10,6",
    ix_global: "4412053,111",
    iy: 253,
    iy_global: "105306550,7",
    iz: 2190,
    iz_global: "911546822,1",
    od: "1,46",
    od_global: "37,084",
    rz: "1,19",
    rz_global: "30,226",
    t: "16,08",
    t_global: "408,432",
    tf: "1,06",
    tf_global: "26,924",
    tw: "0,66",
    tw_global: "16,764",
    zx: 262,
    zx_global: "4293410,768",
    zy: "69,1",
    zy_global: "1132346,122",
    width: "11,3",
    width_global: "287,02",
    thickness: "1,06",
    thickness_global: "26,924",
    height: 19,
    height_global: "482,6",
  },
  {
    profile_section_id: 154,
    name: "W18X106",
    type: "W Section",
    designation: "W18X106",
    shape: "I",
    country_code: "American",
    ax: "31,1",
    ax_global: "20064,476",
    bf: "11,2",
    bf_global: "284,48",
    ct: "1,97",
    ct_global: "50,038",
    d: "18,7",
    d_global: "474,98",
    ix: "7,48",
    ix_global: "3113411,063",
    iy: 220,
    iy_global: "91570913,63",
    iz: 1910,
    iz_global: "795002022,9",
    od: "1,34",
    od_global: "34,036",
    rz: "1,13",
    rz_global: "28,702",
    t: "16,02",
    t_global: "406,908",
    tf: "0,94",
    tf_global: "23,876",
    tw: "0,59",
    tw_global: "14,986",
    zx: 230,
    zx_global: "3769024,72",
    zy: "60,5",
    zy_global: "991417,372",
    width: "11,2",
    width_global: "284,48",
    thickness: "0,94",
    thickness_global: "23,876",
    height: "18,7",
    height_global: "474,98",
  },
  {
    profile_section_id: 155,
    name: "W18X97",
    type: "W Section",
    designation: "W18X97",
    shape: "I",
    country_code: "American",
    ax: "28,5",
    ax_global: "18387,06",
    bf: "11,1",
    bf_global: "281,94",
    ct: "1,91",
    ct_global: "48,514",
    d: "18,6",
    d_global: "472,44",
    ix: "5,86",
    ix_global: "2439116,154",
    iy: 201,
    iy_global: "83662516,55",
    iz: 1750,
    iz_global: "728404994,8",
    od: "1,27",
    od_global: "32,258",
    rz: "1,13",
    rz_global: "28,702",
    t: "16,06",
    t_global: "407,924",
    tf: "0,87",
    tf_global: "22,098",
    tw: "0,54",
    tw_global: "13,716",
    zx: 211,
    zx_global: "3457670,504",
    zy: "55,3",
    zy_global: "906204,6392",
    width: "11,1",
    width_global: "281,94",
    thickness: "0,87",
    thickness_global: "22,098",
    height: "18,6",
    height_global: "472,44",
  },
  {
    profile_section_id: 156,
    name: "W18X86",
    type: "W Section",
    designation: "W18X86",
    shape: "I",
    country_code: "American",
    ax: "25,3",
    ax_global: "16322,548",
    bf: "11,1",
    bf_global: "281,94",
    ct: "1,86",
    ct_global: "47,244",
    d: "18,4",
    d_global: "467,36",
    ix: "4,1",
    ix_global: "1706548,845",
    iy: 175,
    iy_global: "72840499,48",
    iz: 1530,
    iz_global: "636834081,2",
    od: "1,17",
    od_global: "29,718",
    rz: "1,06",
    rz_global: "26,924",
    t: "16,06",
    t_global: "407,924",
    tf: "0,77",
    tf_global: "19,558",
    tw: "0,48",
    tw_global: "12,192",
    zx: 186,
    zx_global: "3047993,904",
    zy: "48,4",
    zy_global: "793133,8976",
    width: "11,1",
    width_global: "281,94",
    thickness: "0,77",
    thickness_global: "19,558",
    height: "18,4",
    height_global: "467,36",
  },
  {
    profile_section_id: 157,
    name: "W18X76",
    type: "W Section",
    designation: "W18X76",
    shape: "I",
    country_code: "American",
    ax: "22,3",
    ax_global: "14387,068",
    bf: 11,
    bf_global: "279,4",
    ct: "1,8",
    ct_global: "45,72",
    d: "18,2",
    d_global: "462,28",
    ix: "2,83",
    ix_global: "1177934,934",
    iy: 152,
    iy_global: "63267176,69",
    iz: 1330,
    iz_global: 553587796,
    od: "1,08",
    od_global: "27,432",
    rz: "1,06",
    rz_global: "26,924",
    t: "16,04",
    t_global: "407,416",
    tf: "0,68",
    tf_global: "17,272",
    tw: "0,42",
    tw_global: "10,668",
    zx: 163,
    zx_global: "2671091,432",
    zy: "42,2",
    zy_global: "691534,1008",
    width: 11,
    width_global: "279,4",
    thickness: "0,68",
    thickness_global: "17,272",
    height: "18,2",
    height_global: "462,28",
  },
  {
    profile_section_id: 158,
    name: "W18X71",
    type: "W Section",
    designation: "W18X71",
    shape: "I",
    country_code: "American",
    ax: "20,9",
    ax_global: "13483,844",
    bf: "7,64",
    bf_global: "194,056",
    ct: "2,26",
    ct_global: "57,404",
    d: "18,5",
    d_global: "469,9",
    ix: "3,49",
    ix_global: "1452647,675",
    iy: "60,3",
    iy_global: "25098754,96",
    iz: 1170,
    iz_global: 486990768,
    od: "1,21",
    od_global: "30,734",
    rz: "0,88",
    rz_global: "22,352",
    t: "16,08",
    t_global: "408,432",
    tf: "0,81",
    tf_global: "20,574",
    tw: "0,5",
    tw_global: "12,7",
    zx: 146,
    zx_global: "2392511,344",
    zy: "24,7",
    zy_global: "404760,4808",
    width: "7,64",
    width_global: "194,056",
    thickness: "0,81",
    thickness_global: "20,574",
    height: "18,5",
    height_global: "469,9",
  },
  {
    profile_section_id: 159,
    name: "W18X65",
    type: "W Section",
    designation: "W18X65",
    shape: "I",
    country_code: "American",
    ax: "19,1",
    ax_global: "12322,556",
    bf: "7,59",
    bf_global: "192,786",
    ct: "2,2",
    ct_global: "55,88",
    d: "18,4",
    d_global: "467,36",
    ix: "2,73",
    ix_global: "1136311,792",
    iy: "54,8",
    iy_global: "22809482,12",
    iz: 1070,
    iz_global: "445367625,4",
    od: "1,15",
    od_global: "29,21",
    rz: "0,88",
    rz_global: "22,352",
    t: "16,1",
    t_global: "408,94",
    tf: "0,75",
    tf_global: "19,05",
    tw: "0,45",
    tw_global: "11,43",
    zx: 133,
    zx_global: "2179479,512",
    zy: "22,5",
    zy_global: "368708,94",
    width: "7,59",
    width_global: "192,786",
    thickness: "0,75",
    thickness_global: "19,05",
    height: "18,4",
    height_global: "467,36",
  },
  {
    profile_section_id: 160,
    name: "W18X60",
    type: "W Section",
    designation: "W18X60",
    shape: "I",
    country_code: "American",
    ax: "17,6",
    ax_global: "11354,816",
    bf: "7,56",
    bf_global: "192,024",
    ct: "2,16",
    ct_global: "54,864",
    d: "18,2",
    d_global: "462,28",
    ix: "2,17",
    ix_global: "903222,1936",
    iy: "50,1",
    iy_global: "20853194,42",
    iz: 984,
    iz_global: "409571722,8",
    od: "1,1",
    od_global: "27,94",
    rz: "0,81",
    rz_global: "20,574",
    t: 16,
    t_global: "406,4",
    tf: "0,69",
    tf_global: "17,526",
    tw: "0,41",
    tw_global: "10,414",
    zx: 123,
    zx_global: "2015608,872",
    zy: "20,6",
    zy_global: "337573,5184",
    width: "7,56",
    width_global: "192,024",
    thickness: "0,69",
    thickness_global: "17,526",
    height: "18,2",
    height_global: "462,28",
  },
  {
    profile_section_id: 161,
    name: "W18X55",
    type: "W Section",
    designation: "W18X55",
    shape: "I",
    country_code: "American",
    ax: "16,2",
    ax_global: "10451,592",
    bf: "7,53",
    bf_global: "191,262",
    ct: "2,16",
    ct_global: "54,864",
    d: "18,1",
    d_global: "459,74",
    ix: "1,66",
    ix_global: "690944,1665",
    iy: "44,9",
    iy_global: "18688791,01",
    iz: 890,
    iz_global: "370445968,8",
    od: "1,03",
    od_global: "26,162",
    rz: "0,81",
    rz_global: "20,574",
    t: "16,04",
    t_global: "407,416",
    tf: "0,63",
    tf_global: "16,002",
    tw: "0,39",
    tw_global: "9,906",
    zx: 112,
    zx_global: "1835351,168",
    zy: "18,5",
    zy_global: "303160,684",
    width: "7,53",
    width_global: "191,262",
    thickness: "0,63",
    thickness_global: "16,002",
    height: "18,1",
    height_global: "459,74",
  },
  {
    profile_section_id: 162,
    name: "W18X50",
    type: "W Section",
    designation: "W18X50",
    shape: "I",
    country_code: "American",
    ax: "14,7",
    ax_global: "9483,852",
    bf: "7,5",
    bf_global: "190,5",
    ct: "2,12",
    ct_global: "53,848",
    d: 18,
    d_global: "457,2",
    ix: "1,24",
    ix_global: "516126,9677",
    iy: "40,1",
    iy_global: "16690880,17",
    iz: 800,
    iz_global: "332985140,5",
    od: "0,97",
    od_global: "24,638",
    rz: "0,81",
    rz_global: "20,574",
    t: "16,06",
    t_global: "407,924",
    tf: "0,57",
    tf_global: "14,478",
    tw: "0,35",
    tw_global: "8,89",
    zx: 101,
    zx_global: "1655093,464",
    zy: "16,6",
    zy_global: "272025,2624",
    width: "7,5",
    width_global: "190,5",
    thickness: "0,57",
    thickness_global: "14,478",
    height: 18,
    height_global: "457,2",
  },
  {
    profile_section_id: 163,
    name: "W18X46",
    type: "W Section",
    designation: "W18X46",
    shape: "I",
    country_code: "American",
    ax: "13,5",
    ax_global: "8709,66",
    bf: "6,06",
    bf_global: "153,924",
    ct: "2,33",
    ct_global: "59,182",
    d: "18,1",
    d_global: "459,74",
    ix: "1,22",
    ix_global: "507802,3392",
    iy: "22,5",
    iy_global: "9365207,076",
    iz: 712,
    iz_global: 296356775,
    od: "1,01",
    od_global: "25,654",
    rz: "0,81",
    rz_global: "20,574",
    t: "16,08",
    t_global: "408,432",
    tf: "0,6",
    tf_global: "15,24",
    tw: "0,36",
    tw_global: "9,144",
    zx: "90,7",
    zx_global: "1486306,705",
    zy: "11,7",
    zy_global: "191728,6488",
    width: "6,06",
    width_global: "153,924",
    thickness: "0,6",
    thickness_global: "15,24",
    height: "18,1",
    height_global: "459,74",
  },
  {
    profile_section_id: 164,
    name: "W18X40",
    type: "W Section",
    designation: "W18X40",
    shape: "I",
    country_code: "American",
    ax: "11,8",
    ax_global: "7612,888",
    bf: "6,02",
    bf_global: "152,908",
    ct: "2,29",
    ct_global: "58,166",
    d: "17,9",
    d_global: "454,66",
    ix: "0,81",
    ix_global: "337147,4547",
    iy: "19,1",
    iy_global: "7950020,229",
    iz: 612,
    iz_global: "254733632,5",
    od: "0,93",
    od_global: "23,622",
    rz: "0,81",
    rz_global: "20,574",
    t: "16,05",
    t_global: "407,67",
    tf: "0,53",
    tf_global: "13,462",
    tw: "0,32",
    tw_global: "8,128",
    zx: "78,4",
    zx_global: "1284745,818",
    zy: 10,
    zy_global: "163870,64",
    width: "6,02",
    width_global: "152,908",
    thickness: "0,53",
    thickness_global: "13,462",
    height: "17,9",
    height_global: "454,66",
  },
  {
    profile_section_id: 165,
    name: "W18X35",
    type: "W Section",
    designation: "W18X35",
    shape: "I",
    country_code: "American",
    ax: "10,3",
    ax_global: "6645,148",
    bf: 6,
    bf_global: "152,4",
    ct: "2,39",
    ct_global: "60,706",
    d: "17,7",
    d_global: "449,58",
    ix: "0,51",
    ix_global: "212278,0271",
    iy: "15,3",
    iy_global: "6368340,812",
    iz: 510,
    iz_global: "212278027,1",
    od: "0,83",
    od_global: "21,082",
    rz: "0,75",
    rz_global: "19,05",
    t: "16,05",
    t_global: "407,67",
    tf: "0,42",
    tf_global: "10,668",
    tw: "0,3",
    tw_global: "7,62",
    zx: "66,5",
    zx_global: "1089739,756",
    zy: "8,06",
    zy_global: "132079,7358",
    width: 6,
    width_global: "152,4",
    thickness: "0,42",
    thickness_global: "10,668",
    height: "17,7",
    height_global: "449,58",
  },
  {
    profile_section_id: 166,
    name: "W16X100",
    type: "W Section",
    designation: "W16X100",
    shape: "I",
    country_code: "American",
    ax: "29,4",
    ax_global: "18967,704",
    bf: "10,4",
    bf_global: "264,16",
    ct: "1,76",
    ct_global: "44,704",
    d: 17,
    d_global: "431,8",
    ix: "7,73",
    ix_global: "3217468,92",
    iy: 186,
    iy_global: "77419045,16",
    iz: 1490,
    iz_global: "620184824,1",
    od: "1,39",
    od_global: "35,306",
    rz: "1,13",
    rz_global: "28,702",
    t: "14,22",
    t_global: "361,188",
    tf: "0,98",
    tf_global: "24,892",
    tw: "0,58",
    tw_global: "14,732",
    zx: 198,
    zx_global: "3244638,672",
    zy: "54,9",
    zy_global: "899649,8136",
    width: "10,4",
    width_global: "264,16",
    thickness: "0,98",
    thickness_global: "24,892",
    height: 17,
    height_global: "431,8",
  },
  {
    profile_section_id: 167,
    name: "W16X89",
    type: "W Section",
    designation: "W16X89",
    shape: "I",
    country_code: "American",
    ax: "26,2",
    ax_global: "16903,192",
    bf: "10,4",
    bf_global: "264,16",
    ct: "1,7",
    ct_global: "43,18",
    d: "16,8",
    d_global: "426,72",
    ix: "5,45",
    ix_global: "2268461,27",
    iy: 163,
    iy_global: "67845722,37",
    iz: 1300,
    iz_global: "541100853,3",
    od: "1,28",
    od_global: "32,512",
    rz: "1,06",
    rz_global: "26,924",
    t: "14,24",
    t_global: "361,696",
    tf: "0,88",
    tf_global: "22,352",
    tw: "0,53",
    tw_global: "13,462",
    zx: 175,
    zx_global: "2867736,2",
    zy: "48,1",
    zy_global: "788217,7784",
    width: "10,4",
    width_global: "264,16",
    thickness: "0,88",
    thickness_global: "22,352",
    height: "16,8",
    height_global: "426,72",
  },
  {
    profile_section_id: 168,
    name: "W16X77",
    type: "W Section",
    designation: "W16X77",
    shape: "I",
    country_code: "American",
    ax: "22,6",
    ax_global: "14580,616",
    bf: "10,3",
    bf_global: "261,62",
    ct: "1,63",
    ct_global: "41,402",
    d: "16,5",
    d_global: "419,1",
    ix: "3,57",
    ix_global: "1485946,189",
    iy: 138,
    iy_global: "57439936,73",
    iz: 1110,
    iz_global: "462016882,4",
    od: "1,16",
    od_global: "29,464",
    rz: "1,06",
    rz_global: "26,924",
    t: "14,18",
    t_global: "360,172",
    tf: "0,76",
    tf_global: "19,304",
    tw: "0,46",
    tw_global: "11,684",
    zx: 150,
    zx_global: "2458059,6",
    zy: "41,1",
    zy_global: "673508,3304",
    width: "10,3",
    width_global: "261,62",
    thickness: "0,76",
    thickness_global: "19,304",
    height: "16,5",
    height_global: "419,1",
  },
  {
    profile_section_id: 169,
    name: "W16X67",
    type: "W Section",
    designation: "W16X67",
    shape: "I",
    country_code: "American",
    ax: "19,6",
    ax_global: "12645,136",
    bf: "10,2",
    bf_global: "259,08",
    ct: "1,56",
    ct_global: "39,624",
    d: "16,3",
    d_global: "414,02",
    ix: "2,39",
    ix_global: "994793,1072",
    iy: 119,
    iy_global: "49531539,65",
    iz: 954,
    iz_global: 397084780,
    od: "1,07",
    od_global: "27,178",
    rz: 1,
    rz_global: "25,4",
    t: "14,16",
    t_global: "359,664",
    tf: "0,67",
    tf_global: "17,018",
    tw: "0,4",
    tw_global: "10,16",
    zx: 130,
    zx_global: "2130318,32",
    zy: "35,5",
    zy_global: "581740,772",
    width: "10,2",
    width_global: "259,08",
    thickness: "0,67",
    thickness_global: "17,018",
    height: "16,3",
    height_global: "414,02",
  },
  {
    profile_section_id: 170,
    name: "W16X57",
    type: "W Section",
    designation: "W16X57",
    shape: "I",
    country_code: "American",
    ax: "16,8",
    ax_global: "10838,688",
    bf: "7,12",
    bf_global: "180,848",
    ct: "1,94",
    ct_global: "49,276",
    d: "16,4",
    d_global: "416,56",
    ix: "2,22",
    ix_global: "924033,7648",
    iy: "43,1",
    iy_global: "17939574,44",
    iz: 758,
    iz_global: "315503420,6",
    od: "1,12",
    od_global: "28,448",
    rz: "0,88",
    rz_global: "22,352",
    t: "14,16",
    t_global: "359,664",
    tf: "0,71",
    tf_global: "18,034",
    tw: "0,43",
    tw_global: "10,922",
    zx: 105,
    zx_global: "1720641,72",
    zy: "18,9",
    zy_global: "309715,5096",
    width: "7,12",
    width_global: "180,848",
    thickness: "0,71",
    thickness_global: "18,034",
    height: "16,4",
    height_global: "416,56",
  },
  {
    profile_section_id: 171,
    name: "W16X50",
    type: "W Section",
    designation: "W16X50",
    shape: "I",
    country_code: "American",
    ax: "14,7",
    ax_global: "9483,852",
    bf: "7,07",
    bf_global: "179,578",
    ct: "1,89",
    ct_global: "48,006",
    d: "16,3",
    d_global: "414,02",
    ix: "1,52",
    ix_global: "632671,7669",
    iy: "37,2",
    iy_global: "15483809,03",
    iz: 659,
    iz_global: "274296509,5",
    od: "1,03",
    od_global: "26,162",
    rz: "0,81",
    rz_global: "20,574",
    t: "14,24",
    t_global: "361,696",
    tf: "0,63",
    tf_global: "16,002",
    tw: "0,38",
    tw_global: "9,652",
    zx: 92,
    zx_global: "1507609,888",
    zy: "16,3",
    zy_global: "267109,1432",
    width: "7,07",
    width_global: "179,578",
    thickness: "0,63",
    thickness_global: "16,002",
    height: "16,3",
    height_global: "414,02",
  },
  {
    profile_section_id: 172,
    name: "W16X45",
    type: "W Section",
    designation: "W16X45",
    shape: "I",
    country_code: "American",
    ax: "13,3",
    ax_global: "8580,628",
    bf: "7,04",
    bf_global: "178,816",
    ct: "1,86",
    ct_global: "47,244",
    d: "16,1",
    d_global: "408,94",
    ix: "1,11",
    ix_global: "462016,8824",
    iy: "32,8",
    iy_global: "13652390,76",
    iz: 586,
    iz_global: "243911615,4",
    od: "0,97",
    od_global: "24,638",
    rz: "0,81",
    rz_global: "20,574",
    t: "14,17",
    t_global: "359,918",
    tf: "0,56",
    tf_global: "14,224",
    tw: "0,34",
    tw_global: "8,636",
    zx: "82,3",
    zx_global: "1348655,367",
    zy: "14,5",
    zy_global: "237612,428",
    width: "7,04",
    width_global: "178,816",
    thickness: "0,56",
    thickness_global: "14,224",
    height: "16,1",
    height_global: "408,94",
  },
  {
    profile_section_id: 173,
    name: "W16X40",
    type: "W Section",
    designation: "W16X40",
    shape: "I",
    country_code: "American",
    ax: "11,8",
    ax_global: "7612,888",
    bf: 7,
    bf_global: "177,8",
    ct: "1,81",
    ct_global: "45,974",
    d: 16,
    d_global: "406,4",
    ix: "0,79",
    ix_global: "328822,8262",
    iy: "28,9",
    iy_global: "12029088,2",
    iz: 518,
    iz_global: "215607878,5",
    od: "0,91",
    od_global: "23,114",
    rz: "0,81",
    rz_global: "20,574",
    t: "14,19",
    t_global: "360,426",
    tf: "0,51",
    tf_global: "12,954",
    tw: "0,3",
    tw_global: "7,62",
    zx: 73,
    zx_global: "1196255,672",
    zy: "12,7",
    zy_global: "208115,7128",
    width: 7,
    width_global: "177,8",
    thickness: "0,51",
    thickness_global: "12,954",
    height: 16,
    height_global: "406,4",
  },
  {
    profile_section_id: 174,
    name: "W16X36",
    type: "W Section",
    designation: "W16X36",
    shape: "I",
    country_code: "American",
    ax: "10,6",
    ax_global: "6838,696",
    bf: "6,99",
    bf_global: "177,546",
    ct: "1,88",
    ct_global: "47,752",
    d: "15,9",
    d_global: "403,86",
    ix: "0,55",
    ix_global: "228927,2841",
    iy: "24,5",
    iy_global: "10197669,93",
    iz: 448,
    iz_global: "186471678,7",
    od: "0,83",
    od_global: "21,082",
    rz: "0,75",
    rz_global: "19,05",
    t: "14,24",
    t_global: "361,696",
    tf: "0,43",
    tf_global: "10,922",
    tw: "0,29",
    tw_global: "7,366",
    zx: 64,
    zx_global: "1048772,096",
    zy: "10,8",
    zy_global: "176980,2912",
    width: "6,99",
    width_global: "177,546",
    thickness: "0,43",
    thickness_global: "10,922",
    height: "15,9",
    height_global: "403,86",
  },
  {
    profile_section_id: 175,
    name: "W16X31",
    type: "W Section",
    designation: "W16X31",
    shape: "I",
    country_code: "American",
    ax: "9,13",
    ax_global: "5890,3108",
    bf: "5,53",
    bf_global: "140,462",
    ct: "2,02",
    ct_global: "51,308",
    d: "15,9",
    d_global: "403,86",
    ix: "0,46",
    ix_global: "191466,4558",
    iy: "12,4",
    iy_global: "5161269,677",
    iz: 375,
    iz_global: "156086784,6",
    od: "0,84",
    od_global: "21,336",
    rz: "0,75",
    rz_global: "19,05",
    t: "14,22",
    t_global: "361,188",
    tf: "0,44",
    tf_global: "11,176",
    tw: "0,28",
    tw_global: "7,112",
    zx: 54,
    zx_global: "884901,456",
    zy: "7,03",
    zy_global: "115201,0599",
    width: "5,53",
    width_global: "140,462",
    thickness: "0,44",
    thickness_global: "11,176",
    height: "15,9",
    height_global: "403,86",
  },
  {
    profile_section_id: 176,
    name: "W16X26",
    type: "W Section",
    designation: "W16X26",
    shape: "I",
    country_code: "American",
    ax: "7,68",
    ax_global: "4954,8288",
    bf: "5,5",
    bf_global: "139,7",
    ct: "2,09",
    ct_global: "53,086",
    d: "15,7",
    d_global: "398,78",
    ix: "0,26",
    ix_global: "108220,1707",
    iy: "9,59",
    iy_global: "3991659,372",
    iz: 301,
    iz_global: "125285659,1",
    od: "0,75",
    od_global: "19,05",
    rz: "0,75",
    rz_global: "19,05",
    t: "14,21",
    t_global: "360,934",
    tf: "0,34",
    tf_global: "8,636",
    tw: "0,25",
    tw_global: "6,35",
    zx: "44,2",
    zx_global: "724308,2288",
    zy: "5,48",
    zy_global: "89801,11072",
    width: "5,5",
    width_global: "139,7",
    thickness: "0,34",
    thickness_global: "8,636",
    height: "15,7",
    height_global: "398,78",
  },
  {
    profile_section_id: 177,
    name: "W14X730",
    type: "W Section",
    designation: "W14X730",
    shape: "I",
    country_code: "American",
    ax: 215,
    ax_global: "138709,4",
    bf: "17,9",
    bf_global: "454,66",
    ct: "3,47",
    ct_global: "88,138",
    d: "22,4",
    d_global: "568,96",
    ix: 1450,
    ix_global: "603535567,1",
    iy: 4720,
    iy_global: 1964612329,
    iz: 14300,
    iz_global: 5952109386,
    od: "5,51",
    od_global: "139,954",
    rz: "2,75",
    rz_global: "69,85",
    t: "11,38",
    t_global: "289,052",
    tf: "4,91",
    tf_global: "124,714",
    tw: "3,07",
    tw_global: "77,978",
    zx: 1660,
    zx_global: "27202526,24",
    zy: 816,
    zy_global: "13371844,22",
    width: "17,9",
    width_global: "454,66",
    thickness: "4,91",
    thickness_global: "124,714",
    height: "22,4",
    height_global: "568,96",
  },
  {
    profile_section_id: 178,
    name: "W14X665",
    type: "W Section",
    designation: "W14X665",
    shape: "I",
    country_code: "American",
    ax: 196,
    ax_global: "126451,36",
    bf: "17,7",
    bf_global: "449,58",
    ct: "3,25",
    ct_global: "82,55",
    d: "21,6",
    d_global: "548,64",
    ix: 1120,
    ix_global: "466179196,7",
    iy: 4170,
    iy_global: 1735685045,
    iz: 12400,
    iz_global: 5161269677,
    od: "5,12",
    od_global: "130,048",
    rz: "2,63",
    rz_global: "66,802",
    t: "11,36",
    t_global: "288,544",
    tf: "4,52",
    tf_global: "114,808",
    tw: "2,83",
    tw_global: "71,882",
    zx: 1480,
    zx_global: "24252854,72",
    zy: 730,
    zy_global: "11962556,72",
    width: "17,7",
    width_global: "449,58",
    thickness: "4,52",
    thickness_global: "114,808",
    height: "21,6",
    height_global: "548,64",
  },
  {
    profile_section_id: 179,
    name: "W14X605",
    type: "W Section",
    designation: "W14X605",
    shape: "I",
    country_code: "American",
    ax: 178,
    ax_global: "114838,48",
    bf: "17,4",
    bf_global: "441,96",
    ct: "3,05",
    ct_global: "77,47",
    d: "20,9",
    d_global: "530,86",
    ix: 869,
    ix_global: "361705108,8",
    iy: 3680,
    iy_global: 1531731646,
    iz: 10800,
    iz_global: 4495299396,
    od: "4,76",
    od_global: "120,904",
    rz: "2,5",
    rz_global: "63,5",
    t: "11,38",
    t_global: "289,052",
    tf: "4,16",
    tf_global: "105,664",
    tw: "2,6",
    tw_global: "66,04",
    zx: 1320,
    zx_global: "21630924,48",
    zy: 652,
    zy_global: "10684365,73",
    width: "17,4",
    width_global: "441,96",
    thickness: "4,16",
    thickness_global: "105,664",
    height: "20,9",
    height_global: "530,86",
  },
  {
    profile_section_id: 180,
    name: "W14X550",
    type: "W Section",
    designation: "W14X550",
    shape: "I",
    country_code: "American",
    ax: 162,
    ax_global: "104515,92",
    bf: "17,2",
    bf_global: "436,88",
    ct: "2,85",
    ct_global: "72,39",
    d: "20,2",
    d_global: "513,08",
    ix: 669,
    ix_global: "278458823,7",
    iy: 3250,
    iy_global: 1352752133,
    iz: 9430,
    iz_global: 3925062343,
    od: "4,42",
    od_global: "112,268",
    rz: "2,38",
    rz_global: "60,452",
    t: "11,36",
    t_global: "288,544",
    tf: "3,82",
    tf_global: "97,028",
    tw: "2,38",
    tw_global: "60,452",
    zx: 1180,
    zx_global: "19336735,52",
    zy: 583,
    zy_global: "9553658,312",
    width: "17,2",
    width_global: "436,88",
    thickness: "3,82",
    thickness_global: "97,028",
    height: "20,2",
    height_global: "513,08",
  },
  {
    profile_section_id: 181,
    name: "W14X500",
    type: "W Section",
    designation: "W14X500",
    shape: "I",
    country_code: "American",
    ax: 147,
    ax_global: "94838,52",
    bf: 17,
    bf_global: "431,8",
    ct: "2,67",
    ct_global: "67,818",
    d: "19,6",
    d_global: "497,84",
    ix: 514,
    ix_global: "213942952,8",
    iy: 2880,
    iy_global: 1198746506,
    iz: 8210,
    iz_global: 3417260004,
    od: "4,1",
    od_global: "104,14",
    rz: "2,31",
    rz_global: "58,674",
    t: "11,4",
    t_global: "289,56",
    tf: "3,5",
    tf_global: "88,9",
    tw: "2,19",
    tw_global: "55,626",
    zx: 1050,
    zx_global: "17206417,2",
    zy: 522,
    zy_global: "8554047,408",
    width: 17,
    width_global: "431,8",
    thickness: "3,5",
    thickness_global: "88,9",
    height: "19,6",
    height_global: "497,84",
  },
  {
    profile_section_id: 182,
    name: "W14X455",
    type: "W Section",
    designation: "W14X455",
    shape: "I",
    country_code: "American",
    ax: 134,
    ax_global: "86451,44",
    bf: "16,8",
    bf_global: "426,72",
    ct: "2,51",
    ct_global: "63,754",
    d: 19,
    d_global: "482,6",
    ix: 395,
    ix_global: "164411413,1",
    iy: 2560,
    iy_global: 1065552450,
    iz: 7190,
    iz_global: 2992703950,
    od: "3,81",
    od_global: "96,774",
    rz: "2,25",
    rz_global: "57,15",
    t: "11,38",
    t_global: "289,052",
    tf: "3,21",
    tf_global: "81,534",
    tw: "2,02",
    tw_global: "51,308",
    zx: 936,
    zx_global: "15338291,9",
    zy: 468,
    zy_global: "7669145,952",
    width: "16,8",
    width_global: "426,72",
    thickness: "3,21",
    thickness_global: "81,534",
    height: 19,
    height_global: "482,6",
  },
  {
    profile_section_id: 183,
    name: "W14X426",
    type: "W Section",
    designation: "W14X426",
    shape: "I",
    country_code: "American",
    ax: 125,
    ax_global: 80645,
    bf: "16,7",
    bf_global: "424,18",
    ct: "2,4",
    ct_global: "60,96",
    d: "18,7",
    d_global: "474,98",
    ix: 331,
    ix_global: "137772601,9",
    iy: 2360,
    iy_global: "982306164,4",
    iz: 6600,
    iz_global: 2747127409,
    od: "3,63",
    od_global: "92,202",
    rz: "2,13",
    rz_global: "54,102",
    t: "11,44",
    t_global: "290,576",
    tf: "3,04",
    tf_global: "77,216",
    tw: "1,88",
    tw_global: "47,752",
    zx: 869,
    zx_global: "14240358,62",
    zy: 434,
    zy_global: "7111985,776",
    width: "16,7",
    width_global: "424,18",
    thickness: "3,04",
    thickness_global: "77,216",
    height: "18,7",
    height_global: "474,98",
  },
  {
    profile_section_id: 184,
    name: "W14X398",
    type: "W Section",
    designation: "W14X398",
    shape: "I",
    country_code: "American",
    ax: 117,
    ax_global: "75483,72",
    bf: "16,6",
    bf_global: "421,64",
    ct: "2,3",
    ct_global: "58,42",
    d: "18,3",
    d_global: "464,82",
    ix: 273,
    ix_global: "113631179,2",
    iy: 2170,
    iy_global: "903222193,6",
    iz: 6000,
    iz_global: 2497388554,
    od: "3,44",
    od_global: "87,376",
    rz: "2,13",
    rz_global: "54,102",
    t: "11,42",
    t_global: "290,068",
    tf: "2,85",
    tf_global: "72,39",
    tw: "1,77",
    tw_global: "44,958",
    zx: 801,
    zx_global: "13126038,26",
    zy: 402,
    zy_global: "6587599,728",
    width: "16,6",
    width_global: "421,64",
    thickness: "2,85",
    thickness_global: "72,39",
    height: "18,3",
    height_global: "464,82",
  },
  {
    profile_section_id: 185,
    name: "W14X370",
    type: "W Section",
    designation: "W14X370",
    shape: "I",
    country_code: "American",
    ax: 109,
    ax_global: "70322,44",
    bf: "16,5",
    bf_global: "419,1",
    ct: "2,19",
    ct_global: "55,626",
    d: "17,9",
    d_global: "454,66",
    ix: 222,
    ix_global: "92403376,48",
    iy: 1990,
    iy_global: "828300536,9",
    iz: 5440,
    iz_global: 2264298955,
    od: "3,26",
    od_global: "82,804",
    rz: "2,06",
    rz_global: "52,324",
    t: "11,38",
    t_global: "289,052",
    tf: "2,66",
    tf_global: "67,564",
    tw: "1,66",
    tw_global: "42,164",
    zx: 736,
    zx_global: "12060879,1",
    zy: 370,
    zy_global: "6063213,68",
    width: "16,5",
    width_global: "419,1",
    thickness: "2,66",
    thickness_global: "67,564",
    height: "17,9",
    height_global: "454,66",
  },
  {
    profile_section_id: 186,
    name: "W14X342",
    type: "W Section",
    designation: "W14X342",
    shape: "I",
    country_code: "American",
    ax: 101,
    ax_global: "65161,16",
    bf: "16,4",
    bf_global: "416,56",
    ct: "2,09",
    ct_global: "53,086",
    d: "17,5",
    d_global: "444,5",
    ix: 178,
    ix_global: "74089193,76",
    iy: 1810,
    iy_global: "753378880,3",
    iz: 4900,
    iz_global: 2039533985,
    od: "3,07",
    od_global: "77,978",
    rz: 2,
    rz_global: "50,8",
    t: "11,36",
    t_global: "288,544",
    tf: "2,47",
    tf_global: "62,738",
    tw: "1,54",
    tw_global: "39,116",
    zx: 672,
    zx_global: "11012107,01",
    zy: 338,
    zy_global: "5538827,632",
    width: "16,4",
    width_global: "416,56",
    thickness: "2,47",
    thickness_global: "62,738",
    height: "17,5",
    height_global: "444,5",
  },
  {
    profile_section_id: 187,
    name: "W14X311",
    type: "W Section",
    designation: "W14X311",
    shape: "I",
    country_code: "American",
    ax: "91,4",
    ax_global: "58967,624",
    bf: "16,2",
    bf_global: "411,48",
    ct: "1,97",
    ct_global: "50,038",
    d: "17,1",
    d_global: "434,34",
    ix: 136,
    ix_global: "56607473,88",
    iy: 1610,
    iy_global: "670132595,2",
    iz: 4330,
    iz_global: 1802282073,
    od: "2,86",
    od_global: "72,644",
    rz: "1,94",
    rz_global: "49,276",
    t: "11,38",
    t_global: "289,052",
    tf: "2,26",
    tf_global: "57,404",
    tw: "1,41",
    tw_global: "35,814",
    zx: 603,
    zx_global: "9881399,592",
    zy: 304,
    zy_global: "4981667,456",
    width: "16,2",
    width_global: "411,48",
    thickness: "2,26",
    thickness_global: "57,404",
    height: "17,1",
    height_global: "434,34",
  },
  {
    profile_section_id: 188,
    name: "W14X283",
    type: "W Section",
    designation: "W14X283",
    shape: "I",
    country_code: "American",
    ax: "83,3",
    ax_global: "53741,828",
    bf: "16,1",
    bf_global: "408,94",
    ct: "1,86",
    ct_global: "47,244",
    d: "16,7",
    d_global: "424,18",
    ix: 104,
    ix_global: "43288068,26",
    iy: 1440,
    iy_global: "599373252,9",
    iz: 3840,
    iz_global: 1598328674,
    od: "2,67",
    od_global: "67,818",
    rz: "1,88",
    rz_global: "47,752",
    t: "11,36",
    t_global: "288,544",
    tf: "2,07",
    tf_global: "52,578",
    tw: "1,29",
    tw_global: "32,766",
    zx: 542,
    zx_global: "8881788,688",
    zy: 274,
    zy_global: "4490055,536",
    width: "16,1",
    width_global: "408,94",
    thickness: "2,07",
    thickness_global: "52,578",
    height: "16,7",
    height_global: "424,18",
  },
  {
    profile_section_id: 189,
    name: "W14X257",
    type: "W Section",
    designation: "W14X257",
    shape: "I",
    country_code: "American",
    ax: "75,6",
    ax_global: "48774,096",
    bf: 16,
    bf_global: "406,4",
    ct: "1,75",
    ct_global: "44,45",
    d: "16,4",
    d_global: "416,56",
    ix: "79,1",
    ix_global: "32923905,76",
    iy: 1290,
    iy_global: 536938539,
    iz: 3400,
    iz_global: 1415186847,
    od: "2,49",
    od_global: "63,246",
    rz: "1,81",
    rz_global: "45,974",
    t: "11,42",
    t_global: "290,068",
    tf: "1,89",
    tf_global: "48,006",
    tw: "1,18",
    tw_global: "29,972",
    zx: 487,
    zx_global: "7980500,168",
    zy: 246,
    zy_global: "4031217,744",
    width: 16,
    width_global: "406,4",
    thickness: "1,89",
    thickness_global: "48,006",
    height: "16,4",
    height_global: "416,56",
  },
  {
    profile_section_id: 190,
    name: "W14X233",
    type: "W Section",
    designation: "W14X233",
    shape: "I",
    country_code: "American",
    ax: "68,5",
    ax_global: "44193,46",
    bf: "15,9",
    bf_global: "403,86",
    ct: "1,65",
    ct_global: "41,91",
    d: 16,
    d_global: "406,4",
    ix: "59,5",
    ix_global: "24765769,82",
    iy: 1150,
    iy_global: "478666139,4",
    iz: 3010,
    iz_global: 1252856591,
    od: "2,32",
    od_global: "58,928",
    rz: "1,75",
    rz_global: "44,45",
    t: "11,36",
    t_global: "288,544",
    tf: "1,72",
    tf_global: "43,688",
    tw: "1,07",
    tw_global: "27,178",
    zx: 436,
    zx_global: "7144759,904",
    zy: 221,
    zy_global: "3621541,144",
    width: "15,9",
    width_global: "403,86",
    thickness: "1,72",
    thickness_global: "43,688",
    height: 16,
    height_global: "406,4",
  },
  {
    profile_section_id: 191,
    name: "W14X211",
    type: "W Section",
    designation: "W14X211",
    shape: "I",
    country_code: "American",
    ax: 62,
    ax_global: "39999,92",
    bf: "15,8",
    bf_global: "401,32",
    ct: "1,57",
    ct_global: "39,878",
    d: "15,7",
    d_global: "398,78",
    ix: "44,6",
    ix_global: "18563921,58",
    iy: 1030,
    iy_global: "428718368,4",
    iz: 2660,
    iz_global: 1107175592,
    od: "2,16",
    od_global: "54,864",
    rz: "1,69",
    rz_global: "42,926",
    t: "11,38",
    t_global: "289,052",
    tf: "1,56",
    tf_global: "39,624",
    tw: "0,98",
    tw_global: "24,892",
    zx: 390,
    zx_global: "6390954,96",
    zy: 198,
    zy_global: "3244638,672",
    width: "15,8",
    width_global: "401,32",
    thickness: "1,56",
    thickness_global: "39,624",
    height: "15,7",
    height_global: "398,78",
  },
  {
    profile_section_id: 192,
    name: "W14X193",
    type: "W Section",
    designation: "W14X193",
    shape: "I",
    country_code: "American",
    ax: "56,8",
    ax_global: "36645,088",
    bf: "15,7",
    bf_global: "398,78",
    ct: "1,49",
    ct_global: "37,846",
    d: "15,5",
    d_global: "393,7",
    ix: "34,8",
    ix_global: "14484853,61",
    iy: 931,
    iy_global: "387511457,2",
    iz: 2400,
    iz_global: "998955421,4",
    od: "2,04",
    od_global: "51,816",
    rz: "1,69",
    rz_global: "42,926",
    t: "11,42",
    t_global: "290,068",
    tf: "1,44",
    tf_global: "36,576",
    tw: "0,89",
    tw_global: "22,606",
    zx: 355,
    zx_global: "5817407,72",
    zy: 180,
    zy_global: "2949671,52",
    width: "15,7",
    width_global: "398,78",
    thickness: "1,44",
    thickness_global: "36,576",
    height: "15,5",
    height_global: "393,7",
  },
  {
    profile_section_id: 193,
    name: "W14X176",
    type: "W Section",
    designation: "W14X176",
    shape: "I",
    country_code: "American",
    ax: "51,8",
    ax_global: "33419,288",
    bf: "15,7",
    bf_global: "398,78",
    ct: "1,43",
    ct_global: "36,322",
    d: "15,2",
    d_global: "386,08",
    ix: "26,5",
    ix_global: "11030132,78",
    iy: 838,
    iy_global: "348801934,7",
    iz: 2140,
    iz_global: "890735250,8",
    od: "1,91",
    od_global: "48,514",
    rz: "1,63",
    rz_global: "41,402",
    t: "11,38",
    t_global: "289,052",
    tf: "1,31",
    tf_global: "33,274",
    tw: "0,83",
    tw_global: "21,082",
    zx: 320,
    zx_global: "5243860,48",
    zy: 163,
    zy_global: "2671091,432",
    width: "15,7",
    width_global: "398,78",
    thickness: "1,31",
    thickness_global: "33,274",
    height: "15,2",
    height_global: "386,08",
  },
  {
    profile_section_id: 194,
    name: "W14X159",
    type: "W Section",
    designation: "W14X159",
    shape: "I",
    country_code: "American",
    ax: "46,7",
    ax_global: "30128,972",
    bf: "15,6",
    bf_global: "396,24",
    ct: "1,35",
    ct_global: "34,29",
    d: 15,
    d_global: 381,
    ix: "19,7",
    ix_global: "8199759,084",
    iy: 748,
    iy_global: "311341106,3",
    iz: 1900,
    iz_global: "790839708,6",
    od: "1,79",
    od_global: "45,466",
    rz: "1,56",
    rz_global: "39,624",
    t: "11,42",
    t_global: "290,068",
    tf: "1,19",
    tf_global: "30,226",
    tw: "0,75",
    tw_global: "19,05",
    zx: 287,
    zx_global: "4703087,368",
    zy: 146,
    zy_global: "2392511,344",
    width: "15,6",
    width_global: "396,24",
    thickness: "1,19",
    thickness_global: "30,226",
    height: 15,
    height_global: 381,
  },
  {
    profile_section_id: 195,
    name: "W14X145",
    type: "W Section",
    designation: "W14X145",
    shape: "I",
    country_code: "American",
    ax: "42,7",
    ax_global: "27548,332",
    bf: "15,5",
    bf_global: "393,7",
    ct: "1,29",
    ct_global: "32,766",
    d: "14,8",
    d_global: "375,92",
    ix: "15,2",
    ix_global: "6326717,669",
    iy: 677,
    iy_global: "281788675,1",
    iz: 1710,
    iz_global: "711755737,8",
    od: "1,69",
    od_global: "42,926",
    rz: "1,56",
    rz_global: "39,624",
    t: "11,42",
    t_global: "290,068",
    tf: "1,09",
    tf_global: "27,686",
    tw: "0,68",
    tw_global: "17,272",
    zx: 260,
    zx_global: "4260636,64",
    zy: 133,
    zy_global: "2179479,512",
    width: "15,5",
    width_global: "393,7",
    thickness: "1,09",
    thickness_global: "27,686",
    height: "14,8",
    height_global: "375,92",
  },
  {
    profile_section_id: 196,
    name: "W14X132",
    type: "W Section",
    designation: "W14X132",
    shape: "I",
    country_code: "American",
    ax: "38,8",
    ax_global: "25032,208",
    bf: "14,7",
    bf_global: "373,38",
    ct: "1,29",
    ct_global: "32,766",
    d: "14,7",
    d_global: "373,38",
    ix: "12,3",
    ix_global: "5119646,535",
    iy: 548,
    iy_global: "228094821,2",
    iz: 1530,
    iz_global: "636834081,2",
    od: "1,63",
    od_global: "41,402",
    rz: "1,56",
    rz_global: "39,624",
    t: "11,44",
    t_global: "290,576",
    tf: "1,03",
    tf_global: "26,162",
    tw: "0,65",
    tw_global: "16,51",
    zx: 234,
    zx_global: "3834572,976",
    zy: 113,
    zy_global: "1851738,232",
    width: "14,7",
    width_global: "373,38",
    thickness: "1,03",
    thickness_global: "26,162",
    height: "14,7",
    height_global: "373,38",
  },
  {
    profile_section_id: 197,
    name: "W14X120",
    type: "W Section",
    designation: "W14X120",
    shape: "I",
    country_code: "American",
    ax: "35,3",
    ax_global: "22774,148",
    bf: "14,7",
    bf_global: "373,38",
    ct: "1,24",
    ct_global: "31,496",
    d: "14,5",
    d_global: "368,3",
    ix: "9,37",
    ix_global: "3900088,458",
    iy: 495,
    iy_global: "206034555,7",
    iz: 1380,
    iz_global: "574399367,3",
    od: "1,54",
    od_global: "39,116",
    rz: "1,5",
    rz_global: "38,1",
    t: "11,42",
    t_global: "290,068",
    tf: "0,94",
    tf_global: "23,876",
    tw: "0,59",
    tw_global: "14,986",
    zx: 212,
    zx_global: "3474057,568",
    zy: 102,
    zy_global: "1671480,528",
    width: "14,7",
    width_global: "373,38",
    thickness: "0,94",
    thickness_global: "23,876",
    height: "14,5",
    height_global: "368,3",
  },
  {
    profile_section_id: 198,
    name: "W14X109",
    type: "W Section",
    designation: "W14X109",
    shape: "I",
    country_code: "American",
    ax: 32,
    ax_global: "20645,12",
    bf: "14,6",
    bf_global: "370,84",
    ct: "1,17",
    ct_global: "29,718",
    d: "14,3",
    d_global: "363,22",
    ix: "7,12",
    ix_global: "2963567,75",
    iy: 447,
    iy_global: "186055447,2",
    iz: 1240,
    iz_global: "516126967,7",
    od: "1,46",
    od_global: "37,084",
    rz: "1,5",
    rz_global: "38,1",
    t: "11,38",
    t_global: "289,052",
    tf: "0,86",
    tf_global: "21,844",
    tw: "0,53",
    tw_global: "13,462",
    zx: 192,
    zx_global: "3146316,288",
    zy: "92,7",
    zy_global: "1519080,833",
    width: "14,6",
    width_global: "370,84",
    thickness: "0,86",
    thickness_global: "21,844",
    height: "14,3",
    height_global: "363,22",
  },
  {
    profile_section_id: 199,
    name: "W14X99",
    type: "W Section",
    designation: "W14X99",
    shape: "I",
    country_code: "American",
    ax: "29,1",
    ax_global: "18774,156",
    bf: "14,6",
    bf_global: "370,84",
    ct: "1,14",
    ct_global: "28,956",
    d: "14,2",
    d_global: "360,68",
    ix: "5,37",
    ix_global: "2235162,755",
    iy: 402,
    iy_global: "167325033,1",
    iz: 1110,
    iz_global: "462016882,4",
    od: "1,38",
    od_global: "35,052",
    rz: "1,44",
    rz_global: "36,576",
    t: "11,44",
    t_global: "290,576",
    tf: "0,78",
    tf_global: "19,812",
    tw: "0,48",
    tw_global: "12,192",
    zx: 173,
    zx_global: "2834962,072",
    zy: "83,6",
    zy_global: "1369958,55",
    width: "14,6",
    width_global: "370,84",
    thickness: "0,78",
    thickness_global: "19,812",
    height: "14,2",
    height_global: "360,68",
  },
  {
    profile_section_id: 200,
    name: "W14X90",
    type: "W Section",
    designation: "W14X90",
    shape: "I",
    country_code: "American",
    ax: "26,5",
    ax_global: "17096,74",
    bf: "14,5",
    bf_global: "368,3",
    ct: "1,09",
    ct_global: "27,686",
    d: 14,
    d_global: "355,6",
    ix: "4,06",
    ix_global: "1689899,588",
    iy: 362,
    iy_global: "150675776,1",
    iz: 999,
    iz_global: "415815194,2",
    od: "1,31",
    od_global: "33,274",
    rz: "1,44",
    rz_global: "36,576",
    t: "11,38",
    t_global: "289,052",
    tf: "0,71",
    tf_global: "18,034",
    tw: "0,44",
    tw_global: "11,176",
    zx: 157,
    zx_global: "2572769,048",
    zy: "75,6",
    zy_global: "1238862,038",
    width: "14,5",
    width_global: "368,3",
    thickness: "0,71",
    thickness_global: "18,034",
    height: 14,
    height_global: "355,6",
  },
  {
    profile_section_id: 201,
    name: "W14X82",
    type: "W Section",
    designation: "W14X82",
    shape: "I",
    country_code: "American",
    ax: 24,
    ax_global: "15483,84",
    bf: "10,1",
    bf_global: "256,54",
    ct: "1,39",
    ct_global: "35,306",
    d: "14,3",
    d_global: "363,22",
    ix: "5,07",
    ix_global: "2110293,328",
    iy: 148,
    iy_global: "61602250,99",
    iz: 881,
    iz_global: 366699886,
    od: "1,45",
    od_global: "36,83",
    rz: "1,06",
    rz_global: "26,924",
    t: "11,4",
    t_global: "289,56",
    tf: "0,85",
    tf_global: "21,59",
    tw: "0,51",
    tw_global: "12,954",
    zx: 139,
    zx_global: "2277801,896",
    zy: "44,8",
    zy_global: "734140,4672",
    width: "10,1",
    width_global: "256,54",
    thickness: "0,85",
    thickness_global: "21,59",
    height: "14,3",
    height_global: "363,22",
  },
  {
    profile_section_id: 202,
    name: "W14X74",
    type: "W Section",
    designation: "W14X74",
    shape: "I",
    country_code: "American",
    ax: "21,8",
    ax_global: "14064,488",
    bf: "10,1",
    bf_global: "256,54",
    ct: "1,32",
    ct_global: "33,528",
    d: "14,2",
    d_global: "360,68",
    ix: "3,87",
    ix_global: "1610815,617",
    iy: 134,
    iy_global: "55775011,03",
    iz: 795,
    iz_global: "330903983,4",
    od: "1,38",
    od_global: "35,052",
    rz: "1,06",
    rz_global: "26,924",
    t: "11,44",
    t_global: "290,576",
    tf: "0,79",
    tf_global: "20,066",
    tw: "0,45",
    tw_global: "11,43",
    zx: 126,
    zx_global: "2064770,064",
    zy: "40,5",
    zy_global: "663676,092",
    width: "10,1",
    width_global: "256,54",
    thickness: "0,79",
    thickness_global: "20,066",
    height: "14,2",
    height_global: "360,68",
  },
  {
    profile_section_id: 203,
    name: "W14X68",
    type: "W Section",
    designation: "W14X68",
    shape: "I",
    country_code: "American",
    ax: 20,
    ax_global: "12903,2",
    bf: 10,
    bf_global: 254,
    ct: "1,29",
    ct_global: "32,766",
    d: 14,
    d_global: "355,6",
    ix: "3,01",
    ix_global: "1252856,591",
    iy: 121,
    iy_global: "50364002,5",
    iz: 722,
    iz_global: "300519089,3",
    od: "1,31",
    od_global: "33,274",
    rz: "1,06",
    rz_global: "26,924",
    t: "11,38",
    t_global: "289,052",
    tf: "0,72",
    tf_global: "18,288",
    tw: "0,41",
    tw_global: "10,414",
    zx: 115,
    zx_global: "1884512,36",
    zy: "36,9",
    zy_global: "604682,6616",
    width: 10,
    width_global: 254,
    thickness: "0,72",
    thickness_global: "18,288",
    height: 14,
    height_global: "355,6",
  },
  {
    profile_section_id: 204,
    name: "W14X61",
    type: "W Section",
    designation: "W14X61",
    shape: "I",
    country_code: "American",
    ax: "17,9",
    ax_global: "11548,364",
    bf: 10,
    bf_global: 254,
    ct: "1,25",
    ct_global: "31,75",
    d: "13,9",
    d_global: "353,06",
    ix: "2,19",
    ix_global: "911546,8221",
    iy: 107,
    iy_global: "44536762,54",
    iz: 640,
    iz_global: "266388112,4",
    od: "1,24",
    od_global: "31,496",
    rz: 1,
    rz_global: "25,4",
    t: "11,42",
    t_global: "290,068",
    tf: "0,65",
    tf_global: "16,51",
    tw: "0,38",
    tw_global: "9,652",
    zx: 102,
    zx_global: "1671480,528",
    zy: "32,8",
    zy_global: "537495,6992",
    width: 10,
    width_global: 254,
    thickness: "0,65",
    thickness_global: "16,51",
    height: "13,9",
    height_global: "353,06",
  },
  {
    profile_section_id: 205,
    name: "W14X53",
    type: "W Section",
    designation: "W14X53",
    shape: "I",
    country_code: "American",
    ax: "15,6",
    ax_global: "10064,496",
    bf: "8,06",
    bf_global: "204,724",
    ct: "1,38",
    ct_global: "35,052",
    d: "13,9",
    d_global: "353,06",
    ix: "1,94",
    ix_global: "807488,9657",
    iy: "57,7",
    iy_global: "24016553,26",
    iz: 541,
    iz_global: "225181201,2",
    od: "1,25",
    od_global: "31,75",
    rz: 1,
    rz_global: "25,4",
    t: "11,4",
    t_global: "289,56",
    tf: "0,66",
    tf_global: "16,764",
    tw: "0,37",
    tw_global: "9,398",
    zx: "87,1",
    zx_global: "1427313,274",
    zy: 22,
    zy_global: "360515,408",
    width: "8,06",
    width_global: "204,724",
    thickness: "0,66",
    thickness_global: "16,764",
    height: "13,9",
    height_global: "353,06",
  },
  {
    profile_section_id: 206,
    name: "W14X48",
    type: "W Section",
    designation: "W14X48",
    shape: "I",
    country_code: "American",
    ax: "14,1",
    ax_global: "9096,756",
    bf: "8,03",
    bf_global: "203,962",
    ct: "1,35",
    ct_global: "34,29",
    d: "13,8",
    d_global: "350,52",
    ix: "1,45",
    ix_global: "603535,5671",
    iy: "51,4",
    iy_global: "21394295,28",
    iz: 484,
    iz_global: 201456010,
    od: "1,19",
    od_global: "30,226",
    rz: 1,
    rz_global: "25,4",
    t: "11,42",
    t_global: "290,068",
    tf: "0,59",
    tf_global: "14,986",
    tw: "0,34",
    tw_global: "8,636",
    zx: "78,4",
    zx_global: "1284745,818",
    zy: "19,6",
    zy_global: "321186,4544",
    width: "8,03",
    width_global: "203,962",
    thickness: "0,59",
    thickness_global: "14,986",
    height: "13,8",
    height_global: "350,52",
  },
  {
    profile_section_id: 207,
    name: "W14X43",
    type: "W Section",
    designation: "W14X43",
    shape: "I",
    country_code: "American",
    ax: "12,6",
    ax_global: "8129,016",
    bf: 8,
    bf_global: "203,2",
    ct: "1,31",
    ct_global: "33,274",
    d: "13,7",
    d_global: "347,98",
    ix: "1,05",
    ix_global: "437042,9969",
    iy: "45,2",
    iy_global: "18813660,44",
    iz: 428,
    iz_global: "178147050,2",
    od: "1,12",
    od_global: "28,448",
    rz: 1,
    rz_global: "25,4",
    t: "11,46",
    t_global: "291,084",
    tf: "0,53",
    tf_global: "13,462",
    tw: "0,3",
    tw_global: "7,62",
    zx: "69,6",
    zx_global: "1140539,654",
    zy: "17,3",
    zy_global: "283496,2072",
    width: 8,
    width_global: "203,2",
    thickness: "0,53",
    thickness_global: "13,462",
    height: "13,7",
    height_global: "347,98",
  },
  {
    profile_section_id: 208,
    name: "W14X38",
    type: "W Section",
    designation: "W14X38",
    shape: "I",
    country_code: "American",
    ax: "11,2",
    ax_global: "7225,792",
    bf: "6,77",
    bf_global: "171,958",
    ct: "1,54",
    ct_global: "39,116",
    d: "14,1",
    d_global: "358,14",
    ix: "0,8",
    ix_global: "332985,1405",
    iy: "26,7",
    iy_global: "11113379,06",
    iz: 385,
    iz_global: "160249098,9",
    od: "0,92",
    od_global: "23,368",
    rz: "0,81",
    rz_global: "20,574",
    t: "12,27",
    t_global: "311,658",
    tf: "0,52",
    tf_global: "13,208",
    tw: "0,31",
    tw_global: "7,874",
    zx: "61,5",
    zx_global: "1007804,436",
    zy: "12,1",
    zy_global: "198283,4744",
    width: "6,77",
    width_global: "171,958",
    thickness: "0,52",
    thickness_global: "13,208",
    height: "14,1",
    height_global: "358,14",
  },
  {
    profile_section_id: 209,
    name: "W14X34",
    type: "W Section",
    designation: "W14X34",
    shape: "I",
    country_code: "American",
    ax: 10,
    ax_global: "6451,6",
    bf: "6,75",
    bf_global: "171,45",
    ct: "1,53",
    ct_global: "38,862",
    d: 14,
    d_global: "355,6",
    ix: "0,57",
    ix_global: "237251,9126",
    iy: "23,3",
    iy_global: "9698192,216",
    iz: 340,
    iz_global: "141518684,7",
    od: "0,85",
    od_global: "21,59",
    rz: "0,75",
    rz_global: "19,05",
    t: "12,29",
    t_global: "312,166",
    tf: "0,46",
    tf_global: "11,684",
    tw: "0,28",
    tw_global: "7,112",
    zx: "54,6",
    zx_global: "894733,6944",
    zy: "10,6",
    zy_global: "173702,8784",
    width: "6,75",
    width_global: "171,45",
    thickness: "0,46",
    thickness_global: "11,684",
    height: 14,
    height_global: "355,6",
  },
  {
    profile_section_id: 210,
    name: "W14X30",
    type: "W Section",
    designation: "W14X30",
    shape: "I",
    country_code: "American",
    ax: "8,85",
    ax_global: "5709,666",
    bf: "6,73",
    bf_global: "170,942",
    ct: "1,58",
    ct_global: "40,132",
    d: "13,8",
    d_global: "350,52",
    ix: "0,38",
    ix_global: "158167,9417",
    iy: "19,6",
    iy_global: "8158135,942",
    iz: 291,
    iz_global: "121123344,8",
    od: "0,79",
    od_global: "20,066",
    rz: "0,75",
    rz_global: "19,05",
    t: "12,23",
    t_global: "310,642",
    tf: "0,39",
    tf_global: "9,906",
    tw: "0,27",
    tw_global: "6,858",
    zx: "47,3",
    zx_global: "775108,1272",
    zy: "8,99",
    zy_global: "147319,7054",
    width: "6,73",
    width_global: "170,942",
    thickness: "0,39",
    thickness_global: "9,906",
    height: "13,8",
    height_global: "350,52",
  },
  {
    profile_section_id: 211,
    name: "W14X26",
    type: "W Section",
    designation: "W14X26",
    shape: "I",
    country_code: "American",
    ax: "7,69",
    ax_global: "4961,2804",
    bf: "5,03",
    bf_global: "127,762",
    ct: "1,72",
    ct_global: "43,688",
    d: "13,9",
    d_global: "353,06",
    ix: "0,36",
    ix_global: "149843,3132",
    iy: "8,91",
    iy_global: "3708622,002",
    iz: 245,
    iz_global: "101976699,3",
    od: "0,82",
    od_global: "20,828",
    rz: "0,75",
    rz_global: "19,05",
    t: "12,26",
    t_global: "311,404",
    tf: "0,42",
    tf_global: "10,668",
    tw: "0,26",
    tw_global: "6,604",
    zx: "40,2",
    zx_global: "658759,9728",
    zy: "5,54",
    zy_global: "90784,33456",
    width: "5,03",
    width_global: "127,762",
    thickness: "0,42",
    thickness_global: "10,668",
    height: "13,9",
    height_global: "353,06",
  },
  {
    profile_section_id: 212,
    name: "W14X22",
    type: "W Section",
    designation: "W14X22",
    shape: "I",
    country_code: "American",
    ax: "6,49",
    ax_global: "4187,0884",
    bf: 5,
    bf_global: 127,
    ct: "1,76",
    ct_global: "44,704",
    d: "13,7",
    d_global: "347,98",
    ix: "0,21",
    ix_global: "87408,59938",
    iy: 7,
    iy_global: "2913619,979",
    iz: 199,
    iz_global: "82830053,69",
    od: "0,73",
    od_global: "18,542",
    rz: "0,75",
    rz_global: "19,05",
    t: "12,23",
    t_global: "310,642",
    tf: "0,34",
    tf_global: "8,636",
    tw: "0,23",
    tw_global: "5,842",
    zx: "33,2",
    zx_global: "544050,5248",
    zy: "4,39",
    zy_global: "71939,21096",
    width: 5,
    width_global: 127,
    thickness: "0,34",
    thickness_global: "8,636",
    height: "13,7",
    height_global: "347,98",
  },
  {
    profile_section_id: 213,
    name: "W12X336",
    type: "W Section",
    designation: "W12X336",
    shape: "I",
    country_code: "American",
    ax: "98,9",
    ax_global: "63806,324",
    bf: "13,4",
    bf_global: "340,36",
    ct: "2,31",
    ct_global: "58,674",
    d: "16,8",
    d_global: "426,72",
    ix: 243,
    ix_global: "101144236,4",
    iy: 1190,
    iy_global: "495315396,5",
    iz: 4060,
    iz_global: 1689899588,
    od: "3,55",
    od_global: "90,17",
    rz: "1,69",
    rz_global: "42,926",
    t: "9,7",
    t_global: "246,38",
    tf: "2,96",
    tf_global: "75,184",
    tw: "1,78",
    tw_global: "45,212",
    zx: 603,
    zx_global: "9881399,592",
    zy: 274,
    zy_global: "4490055,536",
    width: "13,4",
    width_global: "340,36",
    thickness: "2,96",
    thickness_global: "75,184",
    height: "16,8",
    height_global: "426,72",
  },
  {
    profile_section_id: 214,
    name: "W12X305",
    type: "W Section",
    designation: "W12X305",
    shape: "I",
    country_code: "American",
    ax: "89,5",
    ax_global: "57741,82",
    bf: "13,2",
    bf_global: "335,28",
    ct: "2,16",
    ct_global: "54,864",
    d: "16,3",
    d_global: "414,02",
    ix: 185,
    ix_global: "77002813,74",
    iy: 1050,
    iy_global: "437042996,9",
    iz: 3550,
    iz_global: 1477621561,
    od: "3,3",
    od_global: "83,82",
    rz: "1,63",
    rz_global: "41,402",
    t: "9,7",
    t_global: "246,38",
    tf: "2,71",
    tf_global: "68,834",
    tw: "1,63",
    tw_global: "41,402",
    zx: 537,
    zx_global: "8799853,368",
    zy: 244,
    zy_global: "3998443,616",
    width: "13,2",
    width_global: "335,28",
    thickness: "2,71",
    thickness_global: "68,834",
    height: "16,3",
    height_global: "414,02",
  },
  {
    profile_section_id: 215,
    name: "W12X279",
    type: "W Section",
    designation: "W12X279",
    shape: "I",
    country_code: "American",
    ax: "81,9",
    ax_global: "52838,604",
    bf: "13,1",
    bf_global: "332,74",
    ct: "2,05",
    ct_global: "52,07",
    d: "15,9",
    d_global: "403,86",
    ix: 143,
    ix_global: "59521093,86",
    iy: 937,
    iy_global: "390008845,8",
    iz: 3110,
    iz_global: 1294479734,
    od: "3,07",
    od_global: "77,978",
    rz: "1,63",
    rz_global: "41,402",
    t: "9,76",
    t_global: "247,904",
    tf: "2,47",
    tf_global: "62,738",
    tw: "1,53",
    tw_global: "38,862",
    zx: 481,
    zx_global: "7882177,784",
    zy: 220,
    zy_global: "3605154,08",
    width: "13,1",
    width_global: "332,74",
    thickness: "2,47",
    thickness_global: "62,738",
    height: "15,9",
    height_global: "403,86",
  },
  {
    profile_section_id: 216,
    name: "W12X252",
    type: "W Section",
    designation: "W12X252",
    shape: "I",
    country_code: "American",
    ax: "74,1",
    ax_global: "47806,356",
    bf: 13,
    bf_global: "330,2",
    ct: "1,92",
    ct_global: "48,768",
    d: "15,4",
    d_global: "391,16",
    ix: 108,
    ix_global: "44952993,96",
    iy: 828,
    iy_global: "344639620,4",
    iz: 2720,
    iz_global: 1132149478,
    od: "2,85",
    od_global: "72,39",
    rz: "1,5",
    rz_global: "38,1",
    t: "9,7",
    t_global: "246,38",
    tf: "2,25",
    tf_global: "57,15",
    tw: "1,4",
    tw_global: "35,56",
    zx: 428,
    zx_global: "7013663,392",
    zy: 196,
    zy_global: "3211864,544",
    width: 13,
    width_global: "330,2",
    thickness: "2,25",
    thickness_global: "57,15",
    height: "15,4",
    height_global: "391,16",
  },
  {
    profile_section_id: 217,
    name: "W12X230",
    type: "W Section",
    designation: "W12X230",
    shape: "I",
    country_code: "American",
    ax: "67,7",
    ax_global: "43677,332",
    bf: "12,9",
    bf_global: "327,66",
    ct: "1,82",
    ct_global: "46,228",
    d: "15,1",
    d_global: "383,54",
    ix: "83,8",
    ix_global: "34880193,47",
    iy: 742,
    iy_global: "308843717,8",
    iz: 2420,
    iz_global: 1007280050,
    od: "2,67",
    od_global: "67,818",
    rz: "1,5",
    rz_global: "38,1",
    t: "9,76",
    t_global: "247,904",
    tf: "2,07",
    tf_global: "52,578",
    tw: "1,29",
    tw_global: "32,766",
    zx: 386,
    zx_global: "6325406,704",
    zy: 177,
    zy_global: "2900510,328",
    width: "12,9",
    width_global: "327,66",
    thickness: "2,07",
    thickness_global: "52,578",
    height: "15,1",
    height_global: "383,54",
  },
  {
    profile_section_id: 218,
    name: "W12X210",
    type: "W Section",
    designation: "W12X210",
    shape: "I",
    country_code: "American",
    ax: "61,8",
    ax_global: "39870,888",
    bf: "12,8",
    bf_global: "325,12",
    ct: "1,72",
    ct_global: "43,688",
    d: "14,7",
    d_global: "373,38",
    ix: "64,7",
    ix_global: "26930173,24",
    iy: 664,
    iy_global: "276377666,6",
    iz: 2140,
    iz_global: "890735250,8",
    od: "2,5",
    od_global: "63,5",
    rz: "1,44",
    rz_global: "36,576",
    t: "9,7",
    t_global: "246,38",
    tf: "1,9",
    tf_global: "48,26",
    tw: "1,18",
    tw_global: "29,972",
    zx: 348,
    zx_global: "5702698,272",
    zy: 159,
    zy_global: "2605543,176",
    width: "12,8",
    width_global: "325,12",
    thickness: "1,9",
    thickness_global: "48,26",
    height: "14,7",
    height_global: "373,38",
  },
  {
    profile_section_id: 219,
    name: "W12X190",
    type: "W Section",
    designation: "W12X190",
    shape: "I",
    country_code: "American",
    ax: 56,
    ax_global: "36128,96",
    bf: "12,7",
    bf_global: "322,58",
    ct: "1,62",
    ct_global: "41,148",
    d: "14,4",
    d_global: "365,76",
    ix: "48,8",
    ix_global: "20312093,57",
    iy: 589,
    iy_global: "245160309,7",
    iz: 1890,
    iz_global: "786677394,4",
    od: "2,33",
    od_global: "59,182",
    rz: "1,38",
    rz_global: "35,052",
    t: "9,74",
    t_global: "247,396",
    tf: "1,74",
    tf_global: "44,196",
    tw: "1,06",
    tw_global: "26,924",
    zx: 311,
    zx_global: "5096376,904",
    zy: 143,
    zy_global: "2343350,152",
    width: "12,7",
    width_global: "322,58",
    thickness: "1,74",
    thickness_global: "44,196",
    height: "14,4",
    height_global: "365,76",
  },
  {
    profile_section_id: 220,
    name: "W12X170",
    type: "W Section",
    designation: "W12X170",
    shape: "I",
    country_code: "American",
    ax: 50,
    ax_global: 32258,
    bf: "12,6",
    bf_global: "320,04",
    ct: "1,52",
    ct_global: "38,608",
    d: 14,
    d_global: "355,6",
    ix: "35,6",
    ix_global: "14817838,75",
    iy: 517,
    iy_global: 215191647,
    iz: 1650,
    iz_global: "686781852,2",
    od: "2,16",
    od_global: "54,864",
    rz: "1,31",
    rz_global: "33,274",
    t: "9,68",
    t_global: "245,872",
    tf: "1,56",
    tf_global: "39,624",
    tw: "0,96",
    tw_global: "24,384",
    zx: 275,
    zx_global: "4506442,6",
    zy: 126,
    zy_global: "2064770,064",
    width: "12,6",
    width_global: "320,04",
    thickness: "1,56",
    thickness_global: "39,624",
    height: 14,
    height_global: "355,6",
  },
  {
    profile_section_id: 221,
    name: "W12X152",
    type: "W Section",
    designation: "W12X152",
    shape: "I",
    country_code: "American",
    ax: "44,7",
    ax_global: "28838,652",
    bf: "12,5",
    bf_global: "317,5",
    ct: "1,43",
    ct_global: "36,322",
    d: "13,7",
    d_global: "347,98",
    ix: "25,8",
    ix_global: "10738770,78",
    iy: 454,
    iy_global: "188969067,2",
    iz: 1430,
    iz_global: "595210938,6",
    od: 2,
    od_global: "50,8",
    rz: "1,25",
    rz_global: "31,75",
    t: "9,7",
    t_global: "246,38",
    tf: "1,4",
    tf_global: "35,56",
    tw: "0,87",
    tw_global: "22,098",
    zx: 243,
    zx_global: "3982056,552",
    zy: 111,
    zy_global: "1818964,104",
    width: "12,5",
    width_global: "317,5",
    thickness: "1,4",
    thickness_global: "35,56",
    height: "13,7",
    height_global: "347,98",
  },
  {
    profile_section_id: 222,
    name: "W12X136",
    type: "W Section",
    designation: "W12X136",
    shape: "I",
    country_code: "American",
    ax: "39,9",
    ax_global: "25741,884",
    bf: "12,4",
    bf_global: "314,96",
    ct: "1,35",
    ct_global: "34,29",
    d: "13,4",
    d_global: "340,36",
    ix: "18,5",
    ix_global: "7700281,374",
    iy: 398,
    iy_global: "165660107,4",
    iz: 1240,
    iz_global: "516126967,7",
    od: "1,85",
    od_global: "46,99",
    rz: "1,25",
    rz_global: "31,75",
    t: "9,7",
    t_global: "246,38",
    tf: "1,25",
    tf_global: "31,75",
    tw: "0,79",
    tw_global: "20,066",
    zx: 214,
    zx_global: "3506831,696",
    zy: 98,
    zy_global: "1605932,272",
    width: "12,4",
    width_global: "314,96",
    thickness: "1,25",
    thickness_global: "31,75",
    height: "13,4",
    height_global: "340,36",
  },
  {
    profile_section_id: 223,
    name: "W12X120",
    type: "W Section",
    designation: "W12X120",
    shape: "I",
    country_code: "American",
    ax: "35,2",
    ax_global: "22709,632",
    bf: "12,3",
    bf_global: "312,42",
    ct: "1,28",
    ct_global: "32,512",
    d: "13,1",
    d_global: "332,74",
    ix: "12,9",
    ix_global: "5369385,39",
    iy: 345,
    iy_global: "143599841,8",
    iz: 1070,
    iz_global: "445367625,4",
    od: "1,7",
    od_global: "43,18",
    rz: "1,19",
    rz_global: "30,226",
    t: "9,7",
    t_global: "246,38",
    tf: "1,11",
    tf_global: "28,194",
    tw: "0,71",
    tw_global: "18,034",
    zx: 186,
    zx_global: "3047993,904",
    zy: "85,4",
    zy_global: "1399455,266",
    width: "12,3",
    width_global: "312,42",
    thickness: "1,11",
    thickness_global: "28,194",
    height: "13,1",
    height_global: "332,74",
  },
  {
    profile_section_id: 224,
    name: "W12X106",
    type: "W Section",
    designation: "W12X106",
    shape: "I",
    country_code: "American",
    ax: "31,2",
    ax_global: "20128,992",
    bf: "12,2",
    bf_global: "309,88",
    ct: "1,19",
    ct_global: "30,226",
    d: "12,9",
    d_global: "327,66",
    ix: "9,13",
    ix_global: "3800192,916",
    iy: 301,
    iy_global: "125285659,1",
    iz: 933,
    iz_global: "388343920,1",
    od: "1,59",
    od_global: "40,386",
    rz: "1,13",
    rz_global: "28,702",
    t: "9,72",
    t_global: "246,888",
    tf: "0,99",
    tf_global: "25,146",
    tw: "0,61",
    tw_global: "15,494",
    zx: 164,
    zx_global: "2687478,496",
    zy: "75,1",
    zy_global: "1230668,506",
    width: "12,2",
    width_global: "309,88",
    thickness: "0,99",
    thickness_global: "25,146",
    height: "12,9",
    height_global: "327,66",
  },
  {
    profile_section_id: 225,
    name: "W12X96",
    type: "W Section",
    designation: "W12X96",
    shape: "I",
    country_code: "American",
    ax: "28,2",
    ax_global: "18193,512",
    bf: "12,2",
    bf_global: "309,88",
    ct: "1,13",
    ct_global: "28,702",
    d: "12,7",
    d_global: "322,58",
    ix: "6,85",
    ix_global: "2851185,265",
    iy: 270,
    iy_global: "112382484,9",
    iz: 833,
    iz_global: "346720777,5",
    od: "1,5",
    od_global: "38,1",
    rz: "1,13",
    rz_global: "28,702",
    t: "9,7",
    t_global: "246,38",
    tf: "0,9",
    tf_global: "22,86",
    tw: "0,55",
    tw_global: "13,97",
    zx: 147,
    zx_global: "2408898,408",
    zy: "67,5",
    zy_global: "1106126,82",
    width: "12,2",
    width_global: "309,88",
    thickness: "0,9",
    thickness_global: "22,86",
    height: "12,7",
    height_global: "322,58",
  },
  {
    profile_section_id: 226,
    name: "W12X87",
    type: "W Section",
    designation: "W12X87",
    shape: "I",
    country_code: "American",
    ax: "25,6",
    ax_global: "16516,096",
    bf: "12,1",
    bf_global: "307,34",
    ct: "1,1",
    ct_global: "27,94",
    d: "12,5",
    d_global: "317,5",
    ix: "5,1",
    ix_global: "2122780,271",
    iy: 241,
    iy_global: "100311773,6",
    iz: 740,
    iz_global: "308011254,9",
    od: "1,41",
    od_global: "35,814",
    rz: "1,06",
    rz_global: "26,924",
    t: "9,68",
    t_global: "245,872",
    tf: "0,81",
    tf_global: "20,574",
    tw: "0,52",
    tw_global: "13,208",
    zx: 132,
    zx_global: "2163092,448",
    zy: "60,4",
    zy_global: "989778,6656",
    width: "12,1",
    width_global: "307,34",
    thickness: "0,81",
    thickness_global: "20,574",
    height: "12,5",
    height_global: "317,5",
  },
  {
    profile_section_id: 227,
    name: "W12X79",
    type: "W Section",
    designation: "W12X79",
    shape: "I",
    country_code: "American",
    ax: "23,2",
    ax_global: "14967,712",
    bf: "12,1",
    bf_global: "307,34",
    ct: "1,06",
    ct_global: "26,924",
    d: "12,4",
    d_global: "314,96",
    ix: "3,84",
    ix_global: "1598328,674",
    iy: 216,
    iy_global: "89905987,93",
    iz: 662,
    iz_global: "275545203,7",
    od: "1,33",
    od_global: "33,782",
    rz: "1,06",
    rz_global: "26,924",
    t: "9,74",
    t_global: "247,396",
    tf: "0,73",
    tf_global: "18,542",
    tw: "0,47",
    tw_global: "11,938",
    zx: 119,
    zx_global: "1950060,616",
    zy: "54,3",
    zy_global: "889817,5752",
    width: "12,1",
    width_global: "307,34",
    thickness: "0,73",
    thickness_global: "18,542",
    height: "12,4",
    height_global: "314,96",
  },
  {
    profile_section_id: 228,
    name: "W12X72",
    type: "W Section",
    designation: "W12X72",
    shape: "I",
    country_code: "American",
    ax: "21,1",
    ax_global: "13612,876",
    bf: 12,
    bf_global: "304,8",
    ct: "1,02",
    ct_global: "25,908",
    d: "12,3",
    d_global: "312,42",
    ix: "2,93",
    ix_global: "1219558,077",
    iy: 195,
    iy_global: "81165127,99",
    iz: 597,
    iz_global: "248490161,1",
    od: "1,27",
    od_global: "32,258",
    rz: "1,06",
    rz_global: "26,924",
    t: "9,76",
    t_global: "247,904",
    tf: "0,67",
    tf_global: "17,018",
    tw: "0,43",
    tw_global: "10,922",
    zx: 108,
    zx_global: "1769802,912",
    zy: "49,2",
    zy_global: "806243,5488",
    width: 12,
    width_global: "304,8",
    thickness: "0,67",
    thickness_global: "17,018",
    height: "12,3",
    height_global: "312,42",
  },
  {
    profile_section_id: 229,
    name: "W12X65",
    type: "W Section",
    designation: "W12X65",
    shape: "I",
    country_code: "American",
    ax: "19,1",
    ax_global: "12322,556",
    bf: 12,
    bf_global: "304,8",
    ct: "0,98",
    ct_global: "24,892",
    d: "12,1",
    d_global: "307,34",
    ix: "2,18",
    ix_global: "907384,5078",
    iy: 174,
    iy_global: "72424268,05",
    iz: 533,
    iz_global: "221851349,8",
    od: "1,2",
    od_global: "30,48",
    rz: 1,
    rz_global: "25,4",
    t: "9,7",
    t_global: "246,38",
    tf: "0,6",
    tf_global: "15,24",
    tw: "0,39",
    tw_global: "9,906",
    zx: "96,8",
    zx_global: "1586267,795",
    zy: "44,1",
    zy_global: "722669,5224",
    width: 12,
    width_global: "304,8",
    thickness: "0,6",
    thickness_global: "15,24",
    height: "12,1",
    height_global: "307,34",
  },
  {
    profile_section_id: 230,
    name: "W12X58",
    type: "W Section",
    designation: "W12X58",
    shape: "I",
    country_code: "American",
    ax: 17,
    ax_global: "10967,72",
    bf: 10,
    bf_global: 254,
    ct: "1,03",
    ct_global: "26,162",
    d: "12,2",
    d_global: "309,88",
    ix: "2,1",
    ix_global: "874085,9938",
    iy: 107,
    iy_global: "44536762,54",
    iz: 475,
    iz_global: "197709927,2",
    od: "1,24",
    od_global: "31,496",
    rz: "0,94",
    rz_global: "23,876",
    t: "9,72",
    t_global: "246,888",
    tf: "0,64",
    tf_global: "16,256",
    tw: "0,36",
    tw_global: "9,144",
    zx: "86,4",
    zx_global: "1415842,33",
    zy: "32,5",
    zy_global: "532579,58",
    width: 10,
    width_global: 254,
    thickness: "0,64",
    thickness_global: "16,256",
    height: "12,2",
    height_global: "309,88",
  },
  {
    profile_section_id: 231,
    name: "W12X53",
    type: "W Section",
    designation: "W12X53",
    shape: "I",
    country_code: "American",
    ax: "15,6",
    ax_global: "10064,496",
    bf: 10,
    bf_global: 254,
    ct: "1,02",
    ct_global: "25,908",
    d: "12,1",
    d_global: "307,34",
    ix: "1,58",
    ix_global: "657645,6524",
    iy: "95,8",
    iy_global: "39874970,57",
    iz: 425,
    iz_global: "176898355,9",
    od: "1,18",
    od_global: "29,972",
    rz: "0,94",
    rz_global: "23,876",
    t: "9,74",
    t_global: "247,396",
    tf: "0,57",
    tf_global: "14,478",
    tw: "0,34",
    tw_global: "8,636",
    zx: "77,9",
    zx_global: "1276552,286",
    zy: "29,1",
    zy_global: "476863,5624",
    width: 10,
    width_global: 254,
    thickness: "0,57",
    thickness_global: "14,478",
    height: "12,1",
    height_global: "307,34",
  },
  {
    profile_section_id: 232,
    name: "W12X50",
    type: "W Section",
    designation: "W12X50",
    shape: "I",
    country_code: "American",
    ax: "14,6",
    ax_global: "9419,336",
    bf: "8,08",
    bf_global: "205,232",
    ct: "1,17",
    ct_global: "29,718",
    d: "12,2",
    d_global: "309,88",
    ix: "1,71",
    ix_global: "711755,7378",
    iy: "56,3",
    iy_global: "23433829,26",
    iz: 391,
    iz_global: "162746487,4",
    od: "1,14",
    od_global: "28,956",
    rz: "0,94",
    rz_global: "23,876",
    t: "9,92",
    t_global: "251,968",
    tf: "0,64",
    tf_global: "16,256",
    tw: "0,37",
    tw_global: "9,398",
    zx: "71,9",
    zx_global: "1178229,902",
    zy: "21,3",
    zy_global: "349044,4632",
    width: "8,08",
    width_global: "205,232",
    thickness: "0,64",
    thickness_global: "16,256",
    height: "12,2",
    height_global: "309,88",
  },
  {
    profile_section_id: 233,
    name: "W12X45",
    type: "W Section",
    designation: "W12X45",
    shape: "I",
    country_code: "American",
    ax: "13,1",
    ax_global: "8451,596",
    bf: "8,05",
    bf_global: "204,47",
    ct: "1,13",
    ct_global: "28,702",
    d: "12,1",
    d_global: "307,34",
    ix: "1,26",
    ix_global: "524451,5963",
    iy: 50,
    iy_global: "20811571,28",
    iz: 348,
    iz_global: "144848536,1",
    od: "1,08",
    od_global: "27,432",
    rz: "0,94",
    rz_global: "23,876",
    t: "9,94",
    t_global: "252,476",
    tf: "0,57",
    tf_global: "14,478",
    tw: "0,34",
    tw_global: "8,636",
    zx: "64,2",
    zx_global: "1052049,509",
    zy: 19,
    zy_global: "311354,216",
    width: "8,05",
    width_global: "204,47",
    thickness: "0,57",
    thickness_global: "14,478",
    height: "12,1",
    height_global: "307,34",
  },
  {
    profile_section_id: 234,
    name: "W12X40",
    type: "W Section",
    designation: "W12X40",
    shape: "I",
    country_code: "American",
    ax: "11,7",
    ax_global: "7548,372",
    bf: "8,01",
    bf_global: "203,454",
    ct: "1,09",
    ct_global: "27,686",
    d: "11,9",
    d_global: "302,26",
    ix: "0,91",
    ix_global: "378770,5973",
    iy: "44,1",
    iy_global: "18355805,87",
    iz: 307,
    iz_global: "127783047,7",
    od: "1,02",
    od_global: "25,908",
    rz: "0,88",
    rz_global: "22,352",
    t: "9,86",
    t_global: "250,444",
    tf: "0,52",
    tf_global: "13,208",
    tw: "0,29",
    tw_global: "7,366",
    zx: 57,
    zx_global: "934062,648",
    zy: "16,8",
    zy_global: "275302,6752",
    width: "8,01",
    width_global: "203,454",
    thickness: "0,52",
    thickness_global: "13,208",
    height: "11,9",
    height_global: "302,26",
  },
  {
    profile_section_id: 235,
    name: "W12X35",
    type: "W Section",
    designation: "W12X35",
    shape: "I",
    country_code: "American",
    ax: "10,3",
    ax_global: "6645,148",
    bf: "6,56",
    bf_global: "166,624",
    ct: "1,3",
    ct_global: "33,02",
    d: "12,5",
    d_global: "317,5",
    ix: "0,74",
    ix_global: "308011,2549",
    iy: "24,5",
    iy_global: "10197669,93",
    iz: 285,
    iz_global: "118625956,3",
    od: "0,82",
    od_global: "20,828",
    rz: "0,75",
    rz_global: "19,05",
    t: "10,86",
    t_global: "275,844",
    tf: "0,52",
    tf_global: "13,208",
    tw: "0,3",
    tw_global: "7,62",
    zx: "51,2",
    zx_global: "839017,6768",
    zy: "11,5",
    zy_global: "188451,236",
    width: "6,56",
    width_global: "166,624",
    thickness: "0,52",
    thickness_global: "13,208",
    height: "12,5",
    height_global: "317,5",
  },
  {
    profile_section_id: 236,
    name: "W12X30",
    type: "W Section",
    designation: "W12X30",
    shape: "I",
    country_code: "American",
    ax: "8,79",
    ax_global: "5670,9564",
    bf: "6,52",
    bf_global: "165,608",
    ct: "1,27",
    ct_global: "32,258",
    d: "12,3",
    d_global: "312,42",
    ix: "0,46",
    ix_global: "191466,4558",
    iy: "20,3",
    iy_global: "8449497,94",
    iz: 238,
    iz_global: "99063079,29",
    od: "0,74",
    od_global: "18,796",
    rz: "0,75",
    rz_global: "19,05",
    t: "10,82",
    t_global: "274,828",
    tf: "0,44",
    tf_global: "11,176",
    tw: "0,26",
    tw_global: "6,604",
    zx: "43,1",
    zx_global: "706282,4584",
    zy: "9,56",
    zy_global: "156660,3318",
    width: "6,52",
    width_global: "165,608",
    thickness: "0,44",
    thickness_global: "11,176",
    height: "12,3",
    height_global: "312,42",
  },
  {
    profile_section_id: 237,
    name: "W12X26",
    type: "W Section",
    designation: "W12X26",
    shape: "I",
    country_code: "American",
    ax: "7,65",
    ax_global: "4935,474",
    bf: "6,49",
    bf_global: "164,846",
    ct: "1,25",
    ct_global: "31,75",
    d: "12,2",
    d_global: "309,88",
    ix: "0,3",
    ix_global: "124869,4277",
    iy: "17,3",
    iy_global: "7200803,663",
    iz: 204,
    iz_global: "84911210,82",
    od: "0,68",
    od_global: "17,272",
    rz: "0,75",
    rz_global: "19,05",
    t: "10,84",
    t_global: "275,336",
    tf: "0,38",
    tf_global: "9,652",
    tw: "0,23",
    tw_global: "5,842",
    zx: "37,2",
    zx_global: "609598,7808",
    zy: "8,17",
    zy_global: "133882,3129",
    width: "6,49",
    width_global: "164,846",
    thickness: "0,38",
    thickness_global: "9,652",
    height: "12,2",
    height_global: "309,88",
  },
  {
    profile_section_id: 238,
    name: "W12X22",
    type: "W Section",
    designation: "W12X22",
    shape: "I",
    country_code: "American",
    ax: "6,48",
    ax_global: "4180,6368",
    bf: "4,03",
    bf_global: "102,362",
    ct: "1,63",
    ct_global: "41,402",
    d: "12,3",
    d_global: "312,42",
    ix: "0,29",
    ix_global: "120707,1134",
    iy: "4,66",
    iy_global: "1939638,443",
    iz: 156,
    iz_global: "64932102,39",
    od: "0,72",
    od_global: "18,288",
    rz: "0,63",
    rz_global: "16,002",
    t: "10,85",
    t_global: "275,59",
    tf: "0,42",
    tf_global: "10,668",
    tw: "0,26",
    tw_global: "6,604",
    zx: "29,3",
    zx_global: "480140,9752",
    zy: "3,66",
    zy_global: "59976,65424",
    width: "4,03",
    width_global: "102,362",
    thickness: "0,42",
    thickness_global: "10,668",
    height: "12,3",
    height_global: "312,42",
  },
  {
    profile_section_id: 239,
    name: "W12X19",
    type: "W Section",
    designation: "W12X19",
    shape: "I",
    country_code: "American",
    ax: "5,57",
    ax_global: "3593,5412",
    bf: "4,01",
    bf_global: "101,854",
    ct: "1,65",
    ct_global: "41,91",
    d: "12,2",
    d_global: "309,88",
    ix: "0,18",
    ix_global: "74921,65661",
    iy: "3,76",
    iy_global: "1565030,16",
    iz: 130,
    iz_global: "54110085,33",
    od: "0,65",
    od_global: "16,51",
    rz: "0,56",
    rz_global: "14,224",
    t: "10,9",
    t_global: "276,86",
    tf: "0,35",
    tf_global: "8,89",
    tw: "0,23",
    tw_global: "5,842",
    zx: "24,7",
    zx_global: "404760,4808",
    zy: "2,98",
    zy_global: "48833,45072",
    width: "4,01",
    width_global: "101,854",
    thickness: "0,35",
    thickness_global: "8,89",
    height: "12,2",
    height_global: "309,88",
  },
  {
    profile_section_id: 240,
    name: "W12X16",
    type: "W Section",
    designation: "W12X16",
    shape: "I",
    country_code: "American",
    ax: "4,71",
    ax_global: "3038,7036",
    bf: "3,99",
    bf_global: "101,346",
    ct: "1,74",
    ct_global: "44,196",
    d: 12,
    d_global: "304,8",
    ix: "0,1",
    ix_global: "41623,14256",
    iy: "2,82",
    iy_global: "1173772,62",
    iz: 103,
    iz_global: "42871836,84",
    od: "0,56",
    od_global: "14,224",
    rz: "0,56",
    rz_global: "14,224",
    t: "10,87",
    t_global: "276,098",
    tf: "0,27",
    tf_global: "6,858",
    tw: "0,22",
    tw_global: "5,588",
    zx: "20,1",
    zx_global: "329379,9864",
    zy: "2,26",
    zy_global: "37034,76464",
    width: "3,99",
    width_global: "101,346",
    thickness: "0,27",
    thickness_global: "6,858",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 241,
    name: "W12X14",
    type: "W Section",
    designation: "W12X14",
    shape: "I",
    country_code: "American",
    ax: "4,16",
    ax_global: "2683,8656",
    bf: "3,97",
    bf_global: "100,838",
    ct: "1,76",
    ct_global: "44,704",
    d: "11,9",
    d_global: "302,26",
    ix: "0,07",
    ix_global: "29136,19979",
    iy: "2,36",
    iy_global: "982306,1644",
    iz: "88,6",
    iz_global: "36878104,31",
    od: "0,53",
    od_global: "13,462",
    rz: "0,56",
    rz_global: "14,224",
    t: "10,85",
    t_global: "275,59",
    tf: "0,23",
    tf_global: "5,842",
    tw: "0,2",
    tw_global: "5,08",
    zx: "17,4",
    zx_global: "285134,9136",
    zy: "1,9",
    zy_global: "31135,4216",
    width: "3,97",
    width_global: "100,838",
    thickness: "0,23",
    thickness_global: "5,842",
    height: "11,9",
    height_global: "302,26",
  },
  {
    profile_section_id: 242,
    name: "W10X112",
    type: "W Section",
    designation: "W10X112",
    shape: "I",
    country_code: "American",
    ax: "32,9",
    ax_global: "21225,764",
    bf: "10,4",
    bf_global: "264,16",
    ct: "1,21",
    ct_global: "30,734",
    d: "11,4",
    d_global: "289,56",
    ix: "15,1",
    ix_global: "6285094,527",
    iy: 236,
    iy_global: "98230616,44",
    iz: 716,
    iz_global: "298021700,7",
    od: "1,75",
    od_global: "44,45",
    rz: 1,
    rz_global: "25,4",
    t: "7,9",
    t_global: "200,66",
    tf: "1,25",
    tf_global: "31,75",
    tw: "0,76",
    tw_global: "19,304",
    zx: 147,
    zx_global: "2408898,408",
    zy: "69,2",
    zy_global: "1133984,829",
    width: "10,4",
    width_global: "264,16",
    thickness: "1,25",
    thickness_global: "31,75",
    height: "11,4",
    height_global: "289,56",
  },
  {
    profile_section_id: 243,
    name: "W10X100",
    type: "W Section",
    designation: "W10X100",
    shape: "I",
    country_code: "American",
    ax: "29,3",
    ax_global: "18903,188",
    bf: "10,3",
    bf_global: "261,62",
    ct: "1,13",
    ct_global: "28,702",
    d: "11,1",
    d_global: "281,94",
    ix: "10,9",
    ix_global: "4536922,539",
    iy: 207,
    iy_global: "86159905,1",
    iz: 623,
    iz_global: "259312178,1",
    od: "1,62",
    od_global: "41,148",
    rz: 1,
    rz_global: "25,4",
    t: "7,86",
    t_global: "199,644",
    tf: "1,12",
    tf_global: "28,448",
    tw: "0,68",
    tw_global: "17,272",
    zx: 130,
    zx_global: "2130318,32",
    zy: 61,
    zy_global: "999610,904",
    width: "10,3",
    width_global: "261,62",
    thickness: "1,12",
    thickness_global: "28,448",
    height: "11,1",
    height_global: "281,94",
  },
  {
    profile_section_id: 244,
    name: "W10X88",
    type: "W Section",
    designation: "W10X88",
    shape: "I",
    country_code: "American",
    ax: 26,
    ax_global: "16774,16",
    bf: "10,3",
    bf_global: "261,62",
    ct: "1,06",
    ct_global: "26,924",
    d: "10,8",
    d_global: "274,32",
    ix: "7,53",
    ix_global: "3134222,635",
    iy: 179,
    iy_global: "74505425,18",
    iz: 534,
    iz_global: "222267581,3",
    od: "1,49",
    od_global: "37,846",
    rz: "0,94",
    rz_global: "23,876",
    t: "7,82",
    t_global: "198,628",
    tf: "0,99",
    tf_global: "25,146",
    tw: "0,6",
    tw_global: "15,24",
    zx: 113,
    zx_global: "1851738,232",
    zy: "53,1",
    zy_global: "870153,0984",
    width: "10,3",
    width_global: "261,62",
    thickness: "0,99",
    thickness_global: "25,146",
    height: "10,8",
    height_global: "274,32",
  },
  {
    profile_section_id: 245,
    name: "W10X77",
    type: "W Section",
    designation: "W10X77",
    shape: "I",
    country_code: "American",
    ax: "22,7",
    ax_global: "14645,132",
    bf: "10,2",
    bf_global: "259,08",
    ct: "0,99",
    ct_global: "25,146",
    d: "10,6",
    d_global: "269,24",
    ix: "5,11",
    ix_global: "2126942,585",
    iy: 154,
    iy_global: "64099639,54",
    iz: 455,
    iz_global: "189385298,6",
    od: "1,37",
    od_global: "34,798",
    rz: "0,88",
    rz_global: "22,352",
    t: "7,86",
    t_global: "199,644",
    tf: "0,87",
    tf_global: "22,098",
    tw: "0,53",
    tw_global: "13,462",
    zx: "97,6",
    zx_global: "1599377,446",
    zy: "45,9",
    zy_global: "752166,2376",
    width: "10,2",
    width_global: "259,08",
    thickness: "0,87",
    thickness_global: "22,098",
    height: "10,6",
    height_global: "269,24",
  },
  {
    profile_section_id: 246,
    name: "W10X68",
    type: "W Section",
    designation: "W10X68",
    shape: "I",
    country_code: "American",
    ax: "19,9",
    ax_global: "12838,684",
    bf: "10,1",
    bf_global: "256,54",
    ct: "0,93",
    ct_global: "23,622",
    d: "10,4",
    d_global: "264,16",
    ix: "3,56",
    ix_global: "1481783,875",
    iy: 134,
    iy_global: "55775011,03",
    iz: 394,
    iz_global: "163995181,7",
    od: "1,27",
    od_global: "32,258",
    rz: "0,88",
    rz_global: "22,352",
    t: "7,86",
    t_global: "199,644",
    tf: "0,77",
    tf_global: "19,558",
    tw: "0,47",
    tw_global: "11,938",
    zx: "85,3",
    zx_global: "1397816,559",
    zy: "40,1",
    zy_global: "657121,2664",
    width: "10,1",
    width_global: "256,54",
    thickness: "0,77",
    thickness_global: "19,558",
    height: "10,4",
    height_global: "264,16",
  },
  {
    profile_section_id: 247,
    name: "W10X60",
    type: "W Section",
    designation: "W10X60",
    shape: "I",
    country_code: "American",
    ax: "17,7",
    ax_global: "11419,332",
    bf: "10,1",
    bf_global: "256,54",
    ct: "0,88",
    ct_global: "22,352",
    d: "10,2",
    d_global: "259,08",
    ix: "2,48",
    ix_global: "1032253,935",
    iy: 116,
    iy_global: "48282845,37",
    iz: 341,
    iz_global: "141934916,1",
    od: "1,18",
    od_global: "29,972",
    rz: "0,81",
    rz_global: "20,574",
    t: "7,84",
    t_global: "199,136",
    tf: "0,68",
    tf_global: "17,272",
    tw: "0,42",
    tw_global: "10,668",
    zx: "74,6",
    zx_global: "1222474,974",
    zy: 35,
    zy_global: "573547,24",
    width: "10,1",
    width_global: "256,54",
    thickness: "0,68",
    thickness_global: "17,272",
    height: "10,2",
    height_global: "259,08",
  },
  {
    profile_section_id: 248,
    name: "W10X54",
    type: "W Section",
    designation: "W10X54",
    shape: "I",
    country_code: "American",
    ax: "15,8",
    ax_global: "10193,528",
    bf: 10,
    bf_global: 254,
    ct: "0,84",
    ct_global: "21,336",
    d: "10,1",
    d_global: "256,54",
    ix: "1,82",
    ix_global: "757541,1946",
    iy: 103,
    iy_global: "42871836,84",
    iz: 303,
    iz_global: 126118122,
    od: "1,12",
    od_global: "28,448",
    rz: "0,81",
    rz_global: "20,574",
    t: "7,86",
    t_global: "199,644",
    tf: "0,61",
    tf_global: "15,494",
    tw: "0,37",
    tw_global: "9,398",
    zx: "66,6",
    zx_global: "1091378,462",
    zy: "31,3",
    zy_global: "512915,1032",
    width: 10,
    width_global: 254,
    thickness: "0,61",
    thickness_global: "15,494",
    height: "10,1",
    height_global: "256,54",
  },
  {
    profile_section_id: 249,
    name: "W10X49",
    type: "W Section",
    designation: "W10X49",
    shape: "I",
    country_code: "American",
    ax: "14,4",
    ax_global: "9290,304",
    bf: 10,
    bf_global: 254,
    ct: "0,81",
    ct_global: "20,574",
    d: 10,
    d_global: 254,
    ix: "1,39",
    ix_global: "578561,6816",
    iy: "93,4",
    iy_global: "38876015,15",
    iz: 272,
    iz_global: "113214947,8",
    od: "1,06",
    od_global: "26,924",
    rz: "0,81",
    rz_global: "20,574",
    t: "7,88",
    t_global: "200,152",
    tf: "0,56",
    tf_global: "14,224",
    tw: "0,34",
    tw_global: "8,636",
    zx: "60,4",
    zx_global: "989778,6656",
    zy: "28,3",
    zy_global: "463753,9112",
    width: 10,
    width_global: 254,
    thickness: "0,56",
    thickness_global: "14,224",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 250,
    name: "W10X45",
    type: "W Section",
    designation: "W10X45",
    shape: "I",
    country_code: "American",
    ax: "13,3",
    ax_global: "8580,628",
    bf: "8,02",
    bf_global: "203,708",
    ct: "0,91",
    ct_global: "23,114",
    d: "10,1",
    d_global: "256,54",
    ix: "1,51",
    ix_global: "628509,4527",
    iy: "53,4",
    iy_global: "22226758,13",
    iz: 248,
    iz_global: "103225393,5",
    od: "1,12",
    od_global: "28,448",
    rz: "0,81",
    rz_global: "20,574",
    t: "7,86",
    t_global: "199,644",
    tf: "0,62",
    tf_global: "15,748",
    tw: "0,35",
    tw_global: "8,89",
    zx: "54,9",
    zx_global: "899649,8136",
    zy: "20,3",
    zy_global: "332657,3992",
    width: "8,02",
    width_global: "203,708",
    thickness: "0,62",
    thickness_global: "15,748",
    height: "10,1",
    height_global: "256,54",
  },
  {
    profile_section_id: 251,
    name: "W10X39",
    type: "W Section",
    designation: "W10X39",
    shape: "I",
    country_code: "American",
    ax: "11,5",
    ax_global: "7419,34",
    bf: "7,99",
    bf_global: "202,946",
    ct: "0,88",
    ct_global: "22,352",
    d: "9,92",
    d_global: "251,968",
    ix: "0,98",
    ix_global: "407906,7971",
    iy: 45,
    iy_global: "18730414,15",
    iz: 209,
    iz_global: "86992367,95",
    od: "1,03",
    od_global: "26,162",
    rz: "0,81",
    rz_global: "20,574",
    t: "7,86",
    t_global: "199,644",
    tf: "0,53",
    tf_global: "13,462",
    tw: "0,32",
    tw_global: "8,128",
    zx: "46,8",
    zx_global: "766914,5952",
    zy: "17,2",
    zy_global: "281857,5008",
    width: "7,99",
    width_global: "202,946",
    thickness: "0,53",
    thickness_global: "13,462",
    height: "9,92",
    height_global: "251,968",
  },
  {
    profile_section_id: 252,
    name: "W10X33",
    type: "W Section",
    designation: "W10X33",
    shape: "I",
    country_code: "American",
    ax: "9,71",
    ax_global: "6264,5036",
    bf: "7,96",
    bf_global: "202,184",
    ct: "0,87",
    ct_global: "22,098",
    d: "9,73",
    d_global: "247,142",
    ix: "0,58",
    ix_global: "241414,2268",
    iy: "36,6",
    iy_global: "15234070,18",
    iz: 171,
    iz_global: "71175573,78",
    od: "0,94",
    od_global: "23,876",
    rz: "0,75",
    rz_global: "19,05",
    t: "7,86",
    t_global: "199,644",
    tf: "0,44",
    tf_global: "11,176",
    tw: "0,29",
    tw_global: "7,366",
    zx: "38,8",
    zx_global: "635818,0832",
    zy: 14,
    zy_global: "229418,896",
    width: "7,96",
    width_global: "202,184",
    thickness: "0,44",
    thickness_global: "11,176",
    height: "9,73",
    height_global: "247,142",
  },
  {
    profile_section_id: 253,
    name: "W10X30",
    type: "W Section",
    designation: "W10X30",
    shape: "I",
    country_code: "American",
    ax: "8,84",
    ax_global: "5703,2144",
    bf: "5,81",
    bf_global: "147,574",
    ct: "1,1",
    ct_global: "27,94",
    d: "10,5",
    d_global: "266,7",
    ix: "0,62",
    ix_global: "258063,4839",
    iy: "16,7",
    iy_global: "6951064,808",
    iz: 170,
    iz_global: "70759342,35",
    od: "0,81",
    od_global: "20,574",
    rz: "0,69",
    rz_global: "17,526",
    t: "8,88",
    t_global: "225,552",
    tf: "0,51",
    tf_global: "12,954",
    tw: "0,3",
    tw_global: "7,62",
    zx: "36,6",
    zx_global: "599766,5424",
    zy: "8,84",
    zy_global: "144861,6458",
    width: "5,81",
    width_global: "147,574",
    thickness: "0,51",
    thickness_global: "12,954",
    height: "10,5",
    height_global: "266,7",
  },
  {
    profile_section_id: 254,
    name: "W10X26",
    type: "W Section",
    designation: "W10X26",
    shape: "I",
    country_code: "American",
    ax: "7,61",
    ax_global: "4909,6676",
    bf: "5,77",
    bf_global: "146,558",
    ct: "1,06",
    ct_global: "26,924",
    d: "10,3",
    d_global: "261,62",
    ix: "0,4",
    ix_global: "166492,5702",
    iy: "14,1",
    iy_global: "5868863,101",
    iz: 144,
    iz_global: "59937325,29",
    od: "0,74",
    od_global: "18,796",
    rz: "0,69",
    rz_global: "17,526",
    t: "8,82",
    t_global: "224,028",
    tf: "0,44",
    tf_global: "11,176",
    tw: "0,26",
    tw_global: "6,604",
    zx: "31,3",
    zx_global: "512915,1032",
    zy: "7,5",
    zy_global: "122902,98",
    width: "5,77",
    width_global: "146,558",
    thickness: "0,44",
    thickness_global: "11,176",
    height: "10,3",
    height_global: "261,62",
  },
  {
    profile_section_id: 255,
    name: "W10X22",
    type: "W Section",
    designation: "W10X22",
    shape: "I",
    country_code: "American",
    ax: "6,49",
    ax_global: "4187,0884",
    bf: "5,75",
    bf_global: "146,05",
    ct: "1,07",
    ct_global: "27,178",
    d: "10,2",
    d_global: "259,08",
    ix: "0,24",
    ix_global: "99895,54214",
    iy: "11,4",
    iy_global: "4745038,252",
    iz: 118,
    iz_global: "49115308,22",
    od: "0,66",
    od_global: "16,764",
    rz: "0,63",
    rz_global: "16,002",
    t: "8,88",
    t_global: "225,552",
    tf: "0,36",
    tf_global: "9,144",
    tw: "0,24",
    tw_global: "6,096",
    zx: 26,
    zx_global: "426063,664",
    zy: "6,1",
    zy_global: "99961,0904",
    width: "5,75",
    width_global: "146,05",
    thickness: "0,36",
    thickness_global: "9,144",
    height: "10,2",
    height_global: "259,08",
  },
  {
    profile_section_id: 256,
    name: "W10X19",
    type: "W Section",
    designation: "W10X19",
    shape: "I",
    country_code: "American",
    ax: "5,62",
    ax_global: "3625,7992",
    bf: "4,02",
    bf_global: "102,108",
    ct: "1,28",
    ct_global: "32,512",
    d: "10,2",
    d_global: "259,08",
    ix: "0,23",
    ix_global: "95733,22789",
    iy: "4,29",
    iy_global: "1785632,816",
    iz: "96,3",
    iz_global: "40083086,29",
    od: "0,69",
    od_global: "17,526",
    rz: "0,63",
    rz_global: "16,002",
    t: "8,81",
    t_global: "223,774",
    tf: "0,4",
    tf_global: "10,16",
    tw: "0,25",
    tw_global: "6,35",
    zx: "21,6",
    zx_global: "353960,5824",
    zy: "3,35",
    zy_global: "54896,6644",
    width: "4,02",
    width_global: "102,108",
    thickness: "0,4",
    thickness_global: "10,16",
    height: "10,2",
    height_global: "259,08",
  },
  {
    profile_section_id: 257,
    name: "W10X17",
    type: "W Section",
    designation: "W10X17",
    shape: "I",
    country_code: "American",
    ax: "4,99",
    ax_global: "3219,3484",
    bf: "4,01",
    bf_global: "101,854",
    ct: "1,32",
    ct_global: "33,528",
    d: "10,1",
    d_global: "256,54",
    ix: "0,16",
    ix_global: "66597,0281",
    iy: "3,56",
    iy_global: "1481783,875",
    iz: "81,9",
    iz_global: "34089353,76",
    od: "0,63",
    od_global: "16,002",
    rz: "0,56",
    rz_global: "14,224",
    t: "8,84",
    t_global: "224,536",
    tf: "0,33",
    tf_global: "8,382",
    tw: "0,24",
    tw_global: "6,096",
    zx: "18,7",
    zx_global: "306438,0968",
    zy: "2,8",
    zy_global: "45883,7792",
    width: "4,01",
    width_global: "101,854",
    thickness: "0,33",
    thickness_global: "8,382",
    height: "10,1",
    height_global: "256,54",
  },
  {
    profile_section_id: 258,
    name: "W10X15",
    type: "W Section",
    designation: "W10X15",
    shape: "I",
    country_code: "American",
    ax: "4,41",
    ax_global: "2845,1556",
    bf: 4,
    bf_global: "101,6",
    ct: "1,37",
    ct_global: "34,798",
    d: "9,99",
    d_global: "253,746",
    ix: "0,1",
    ix_global: "41623,14256",
    iy: "2,89",
    iy_global: "1202908,82",
    iz: "68,9",
    iz_global: "28678345,22",
    od: "0,57",
    od_global: "14,478",
    rz: "0,56",
    rz_global: "14,224",
    t: "8,85",
    t_global: "224,79",
    tf: "0,27",
    tf_global: "6,858",
    tw: "0,23",
    tw_global: "5,842",
    zx: 16,
    zx_global: "262193,024",
    zy: "2,3",
    zy_global: "37690,2472",
    width: 4,
    width_global: "101,6",
    thickness: "0,27",
    thickness_global: "6,858",
    height: "9,99",
    height_global: "253,746",
  },
  {
    profile_section_id: 259,
    name: "W10X12",
    type: "W Section",
    designation: "W10X12",
    shape: "I",
    country_code: "American",
    ax: "3,54",
    ax_global: "2283,8664",
    bf: "3,96",
    bf_global: "100,584",
    ct: "1,36",
    ct_global: "34,544",
    d: "9,87",
    d_global: "250,698",
    ix: "0,05",
    ix_global: "20811,57128",
    iy: "2,18",
    iy_global: "907384,5078",
    iz: "53,8",
    iz_global: "22393250,7",
    od: "0,51",
    od_global: "12,954",
    rz: "0,56",
    rz_global: "14,224",
    t: "8,85",
    t_global: "224,79",
    tf: "0,21",
    tf_global: "5,334",
    tw: "0,19",
    tw_global: "4,826",
    zx: "12,6",
    zx_global: "206477,0064",
    zy: "1,74",
    zy_global: "28513,49136",
    width: "3,96",
    width_global: "100,584",
    thickness: "0,21",
    thickness_global: "5,334",
    height: "9,87",
    height_global: "250,698",
  },
  {
    profile_section_id: 260,
    name: "W8X67",
    type: "W Section",
    designation: "W8X67",
    shape: "I",
    country_code: "American",
    ax: "19,7",
    ax_global: "12709,652",
    bf: "8,28",
    bf_global: "210,312",
    ct: "0,94",
    ct_global: "23,876",
    d: 9,
    d_global: "228,6",
    ix: "5,05",
    ix_global: "2101968,699",
    iy: "88,6",
    iy_global: "36878104,31",
    iz: 272,
    iz_global: "113214947,8",
    od: "1,33",
    od_global: "33,782",
    rz: "0,94",
    rz_global: "23,876",
    t: "6,34",
    t_global: "161,036",
    tf: "0,94",
    tf_global: "23,876",
    tw: "0,57",
    tw_global: "14,478",
    zx: "70,1",
    zx_global: "1148733,186",
    zy: "32,7",
    zy_global: "535856,9928",
    width: "8,28",
    width_global: "210,312",
    thickness: "0,94",
    thickness_global: "23,876",
    height: 9,
    height_global: "228,6",
  },
  {
    profile_section_id: 261,
    name: "W8X58",
    type: "W Section",
    designation: "W8X58",
    shape: "I",
    country_code: "American",
    ax: "17,1",
    ax_global: "11032,236",
    bf: "8,22",
    bf_global: "208,788",
    ct: "0,87",
    ct_global: "22,098",
    d: "8,75",
    d_global: "222,25",
    ix: "3,33",
    ix_global: "1386050,647",
    iy: "75,1",
    iy_global: "31258980,06",
    iz: 228,
    iz_global: "94900765,04",
    od: "1,2",
    od_global: "30,48",
    rz: "0,88",
    rz_global: "22,352",
    t: "6,35",
    t_global: "161,29",
    tf: "0,81",
    tf_global: "20,574",
    tw: "0,51",
    tw_global: "12,954",
    zx: "59,8",
    zx_global: "979946,4272",
    zy: "27,9",
    zy_global: "457199,0856",
    width: "8,22",
    width_global: "208,788",
    thickness: "0,81",
    thickness_global: "20,574",
    height: "8,75",
    height_global: "222,25",
  },
  {
    profile_section_id: 262,
    name: "W8X48",
    type: "W Section",
    designation: "W8X48",
    shape: "I",
    country_code: "American",
    ax: "14,1",
    ax_global: "9096,756",
    bf: "8,11",
    bf_global: "205,994",
    ct: "0,78",
    ct_global: "19,812",
    d: "8,5",
    d_global: "215,9",
    ix: "1,96",
    ix_global: "815813,5942",
    iy: "60,9",
    iy_global: "25348493,82",
    iz: 184,
    iz_global: "76586582,31",
    od: "1,08",
    od_global: "27,432",
    rz: "0,81",
    rz_global: "20,574",
    t: "6,34",
    t_global: "161,036",
    tf: "0,69",
    tf_global: "17,526",
    tw: "0,4",
    tw_global: "10,16",
    zx: 49,
    zx_global: "802966,136",
    zy: "22,9",
    zy_global: "375263,7656",
    width: "8,11",
    width_global: "205,994",
    thickness: "0,69",
    thickness_global: "17,526",
    height: "8,5",
    height_global: "215,9",
  },
  {
    profile_section_id: 263,
    name: "W8X40",
    type: "W Section",
    designation: "W8X40",
    shape: "I",
    country_code: "American",
    ax: "11,7",
    ax_global: "7548,372",
    bf: "8,07",
    bf_global: "204,978",
    ct: "0,73",
    ct_global: "18,542",
    d: "8,25",
    d_global: "209,55",
    ix: "1,12",
    ix_global: "466179,1967",
    iy: "49,1",
    iy_global: 20436963,
    iz: 146,
    iz_global: "60769788,14",
    od: "0,95",
    od_global: "24,13",
    rz: "0,81",
    rz_global: "20,574",
    t: "6,34",
    t_global: "161,036",
    tf: "0,56",
    tf_global: "14,224",
    tw: "0,36",
    tw_global: "9,144",
    zx: "39,8",
    zx_global: "652205,1472",
    zy: "18,5",
    zy_global: "303160,684",
    width: "8,07",
    width_global: "204,978",
    thickness: "0,56",
    thickness_global: "14,224",
    height: "8,25",
    height_global: "209,55",
  },
  {
    profile_section_id: 264,
    name: "W8X35",
    type: "W Section",
    designation: "W8X35",
    shape: "I",
    country_code: "American",
    ax: "10,3",
    ax_global: "6645,148",
    bf: "8,02",
    bf_global: "203,708",
    ct: "0,69",
    ct_global: "17,526",
    d: "8,12",
    d_global: "206,248",
    ix: "0,77",
    ix_global: "320498,1977",
    iy: "42,6",
    iy_global: "17731458,73",
    iz: 127,
    iz_global: "52861391,05",
    od: "0,89",
    od_global: "22,606",
    rz: "0,81",
    rz_global: "20,574",
    t: "6,34",
    t_global: "161,036",
    tf: "0,5",
    tf_global: "12,7",
    tw: "0,31",
    tw_global: "7,874",
    zx: "34,7",
    zx_global: "568631,1208",
    zy: "16,1",
    zy_global: "263831,7304",
    width: "8,02",
    width_global: "203,708",
    thickness: "0,5",
    thickness_global: "12,7",
    height: "8,12",
    height_global: "206,248",
  },
  {
    profile_section_id: 265,
    name: "W8X31",
    type: "W Section",
    designation: "W8X31",
    shape: "I",
    country_code: "American",
    ax: "9,13",
    ax_global: "5890,3108",
    bf: 8,
    bf_global: "203,2",
    ct: "0,67",
    ct_global: "17,018",
    d: 8,
    d_global: "203,2",
    ix: "0,54",
    ix_global: "224764,9698",
    iy: "37,1",
    iy_global: "15442185,89",
    iz: 110,
    iz_global: "45785456,82",
    od: "0,83",
    od_global: "21,082",
    rz: "0,75",
    rz_global: "19,05",
    t: "6,34",
    t_global: "161,036",
    tf: "0,44",
    tf_global: "11,176",
    tw: "0,28",
    tw_global: "7,112",
    zx: "30,4",
    zx_global: "498166,7456",
    zy: "14,1",
    zy_global: "231057,6024",
    width: 8,
    width_global: "203,2",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 266,
    name: "W8X28",
    type: "W Section",
    designation: "W8X28",
    shape: "I",
    country_code: "American",
    ax: "8,25",
    ax_global: "5322,57",
    bf: "6,54",
    bf_global: "166,116",
    ct: "0,73",
    ct_global: "18,542",
    d: "8,06",
    d_global: "204,724",
    ix: "0,54",
    ix_global: "224764,9698",
    iy: "21,7",
    iy_global: "9032221,936",
    iz: 98,
    iz_global: "40790679,71",
    od: "0,86",
    od_global: "21,844",
    rz: "0,63",
    rz_global: "16,002",
    t: "6,34",
    t_global: "161,036",
    tf: "0,47",
    tf_global: "11,938",
    tw: "0,28",
    tw_global: "7,112",
    zx: "27,2",
    zx_global: "445728,1408",
    zy: "10,1",
    zy_global: "165509,3464",
    width: "6,54",
    width_global: "166,116",
    thickness: "0,47",
    thickness_global: "11,938",
    height: "8,06",
    height_global: "204,724",
  },
  {
    profile_section_id: 267,
    name: "W8X24",
    type: "W Section",
    designation: "W8X24",
    shape: "I",
    country_code: "American",
    ax: "7,08",
    ax_global: "4567,7328",
    bf: "6,5",
    bf_global: "165,1",
    ct: "0,69",
    ct_global: "17,526",
    d: "7,93",
    d_global: "201,422",
    ix: "0,35",
    ix_global: "145680,999",
    iy: "18,3",
    iy_global: "7617035,088",
    iz: "82,7",
    iz_global: "34422338,9",
    od: "0,79",
    od_global: "20,066",
    rz: "0,56",
    rz_global: "14,224",
    t: "6,34",
    t_global: "161,036",
    tf: "0,4",
    tf_global: "10,16",
    tw: "0,25",
    tw_global: "6,35",
    zx: "23,1",
    zx_global: "378541,1784",
    zy: "8,57",
    zy_global: "140437,1385",
    width: "6,5",
    width_global: "165,1",
    thickness: "0,4",
    thickness_global: "10,16",
    height: "7,93",
    height_global: "201,422",
  },
  {
    profile_section_id: 268,
    name: "W8X21",
    type: "W Section",
    designation: "W8X21",
    shape: "I",
    country_code: "American",
    ax: "6,16",
    ax_global: "3974,1856",
    bf: "5,27",
    bf_global: "133,858",
    ct: "0,83",
    ct_global: "21,082",
    d: "8,28",
    d_global: "210,312",
    ix: "0,28",
    ix_global: "116544,7992",
    iy: "9,77",
    iy_global: "4066581,028",
    iz: "75,3",
    iz_global: "31342226,35",
    od: "0,7",
    od_global: "17,78",
    rz: "0,56",
    rz_global: "14,224",
    t: "6,88",
    t_global: "174,752",
    tf: "0,4",
    tf_global: "10,16",
    tw: "0,25",
    tw_global: "6,35",
    zx: "20,4",
    zx_global: "334296,1056",
    zy: "5,69",
    zy_global: "93242,39416",
    width: "5,27",
    width_global: "133,858",
    thickness: "0,4",
    thickness_global: "10,16",
    height: "8,28",
    height_global: "210,312",
  },
  {
    profile_section_id: 269,
    name: "W8X18",
    type: "W Section",
    designation: "W8X18",
    shape: "I",
    country_code: "American",
    ax: "5,26",
    ax_global: "3393,5416",
    bf: "5,25",
    bf_global: "133,35",
    ct: "0,83",
    ct_global: "21,082",
    d: "8,14",
    d_global: "206,756",
    ix: "0,17",
    ix_global: "70759,34235",
    iy: "7,97",
    iy_global: "3317364,462",
    iz: "61,9",
    iz_global: "25764725,24",
    od: "0,63",
    od_global: "16,002",
    rz: "0,56",
    rz_global: "14,224",
    t: "6,88",
    t_global: "174,752",
    tf: "0,33",
    tf_global: "8,382",
    tw: "0,23",
    tw_global: "5,842",
    zx: 17,
    zx_global: "278580,088",
    zy: "4,66",
    zy_global: "76363,71824",
    width: "5,25",
    width_global: "133,35",
    thickness: "0,33",
    thickness_global: "8,382",
    height: "8,14",
    height_global: "206,756",
  },
  {
    profile_section_id: 270,
    name: "W8X15",
    type: "W Section",
    designation: "W8X15",
    shape: "I",
    country_code: "American",
    ax: "4,44",
    ax_global: "2864,5104",
    bf: "4,01",
    bf_global: "101,854",
    ct: 1,
    ct_global: "25,4",
    d: "8,11",
    d_global: "205,994",
    ix: "0,14",
    ix_global: "58272,39958",
    iy: "3,41",
    iy_global: "1419349,161",
    iz: 48,
    iz_global: "19979108,43",
    od: "0,61",
    od_global: "15,494",
    rz: "0,56",
    rz_global: "14,224",
    t: "6,88",
    t_global: "174,752",
    tf: "0,32",
    tf_global: "8,128",
    tw: "0,25",
    tw_global: "6,35",
    zx: "13,6",
    zx_global: "222864,0704",
    zy: "2,67",
    zy_global: "43753,46088",
    width: "4,01",
    width_global: "101,854",
    thickness: "0,32",
    thickness_global: "8,128",
    height: "8,11",
    height_global: "205,994",
  },
  {
    profile_section_id: 271,
    name: "W8X13",
    type: "W Section",
    designation: "W8X13",
    shape: "I",
    country_code: "American",
    ax: "3,84",
    ax_global: "2477,4144",
    bf: 4,
    bf_global: "101,6",
    ct: "1,03",
    ct_global: "26,162",
    d: "7,99",
    d_global: "202,946",
    ix: "0,09",
    ix_global: "37460,8283",
    iy: "2,73",
    iy_global: "1136311,792",
    iz: "39,6",
    iz_global: "16482764,45",
    od: "0,56",
    od_global: "14,224",
    rz: "0,56",
    rz_global: "14,224",
    t: "6,88",
    t_global: "174,752",
    tf: "0,26",
    tf_global: "6,604",
    tw: "0,23",
    tw_global: "5,842",
    zx: "11,4",
    zx_global: "186812,5296",
    zy: "2,15",
    zy_global: "35232,1876",
    width: 4,
    width_global: "101,6",
    thickness: "0,26",
    thickness_global: "6,604",
    height: "7,99",
    height_global: "202,946",
  },
  {
    profile_section_id: 272,
    name: "W8X10",
    type: "W Section",
    designation: "W8X10",
    shape: "I",
    country_code: "American",
    ax: "2,96",
    ax_global: "1909,6736",
    bf: "3,94",
    bf_global: "100,076",
    ct: "0,95",
    ct_global: "24,13",
    d: "7,89",
    d_global: "200,406",
    ix: "0,04",
    ix_global: "16649,25702",
    iy: "2,09",
    iy_global: "869923,6795",
    iz: "30,8",
    iz_global: "12819927,91",
    od: "0,51",
    od_global: "12,954",
    rz: "0,5",
    rz_global: "12,7",
    t: "6,88",
    t_global: "174,752",
    tf: "0,2",
    tf_global: "5,08",
    tw: "0,17",
    tw_global: "4,318",
    zx: "8,87",
    zx_global: "145353,2577",
    zy: "1,66",
    zy_global: "27202,52624",
    width: "3,94",
    width_global: "100,076",
    thickness: "0,2",
    thickness_global: "5,08",
    height: "7,89",
    height_global: "200,406",
  },
  {
    profile_section_id: 273,
    name: "W6X25",
    type: "W Section",
    designation: "W6X25",
    shape: "I",
    country_code: "American",
    ax: "7,34",
    ax_global: "4735,4744",
    bf: "6,08",
    bf_global: "154,432",
    ct: "0,61",
    ct_global: "15,494",
    d: "6,38",
    d_global: "162,052",
    ix: "0,46",
    ix_global: "191466,4558",
    iy: "17,1",
    iy_global: "7117557,378",
    iz: "53,4",
    iz_global: "22226758,13",
    od: "0,7",
    od_global: "17,78",
    rz: "0,56",
    rz_global: "14,224",
    t: "4,97",
    t_global: "126,238",
    tf: "0,46",
    tf_global: "11,684",
    tw: "0,32",
    tw_global: "8,128",
    zx: "18,9",
    zx_global: "309715,5096",
    zy: "8,56",
    zy_global: "140273,2678",
    width: "6,08",
    width_global: "154,432",
    thickness: "0,46",
    thickness_global: "11,684",
    height: "6,38",
    height_global: "162,052",
  },
  {
    profile_section_id: 274,
    name: "W6X20",
    type: "W Section",
    designation: "W6X20",
    shape: "I",
    country_code: "American",
    ax: "5,87",
    ax_global: "3787,0892",
    bf: "6,02",
    bf_global: "152,908",
    ct: "0,56",
    ct_global: "14,224",
    d: "6,2",
    d_global: "157,48",
    ix: "0,24",
    ix_global: "99895,54214",
    iy: "13,3",
    iy_global: "5535877,96",
    iz: "41,4",
    iz_global: "17231981,02",
    od: "0,61",
    od_global: "15,494",
    rz: "0,56",
    rz_global: "14,224",
    t: "4,97",
    t_global: "126,238",
    tf: "0,36",
    tf_global: "9,144",
    tw: "0,26",
    tw_global: "6,604",
    zx: 15,
    zx_global: "245805,96",
    zy: "6,72",
    zy_global: "110121,0701",
    width: "6,02",
    width_global: "152,908",
    thickness: "0,36",
    thickness_global: "9,144",
    height: "6,2",
    height_global: "157,48",
  },
  {
    profile_section_id: 275,
    name: "W6X15",
    type: "W Section",
    designation: "W6X15",
    shape: "I",
    country_code: "American",
    ax: "4,43",
    ax_global: "2858,0588",
    bf: "5,99",
    bf_global: "152,146",
    ct: "0,56",
    ct_global: "14,224",
    d: "5,99",
    d_global: "152,146",
    ix: "0,1",
    ix_global: "41623,14256",
    iy: "9,32",
    iy_global: "3879276,887",
    iz: "29,1",
    iz_global: "12112334,48",
    od: "0,51",
    od_global: "12,954",
    rz: "0,56",
    rz_global: "14,224",
    t: "4,97",
    t_global: "126,238",
    tf: "0,26",
    tf_global: "6,604",
    tw: "0,23",
    tw_global: "5,842",
    zx: "10,8",
    zx_global: "176980,2912",
    zy: "4,75",
    zy_global: "77838,554",
    width: "5,99",
    width_global: "152,146",
    thickness: "0,26",
    thickness_global: "6,604",
    height: "5,99",
    height_global: "152,146",
  },
  {
    profile_section_id: 276,
    name: "W6X16",
    type: "W Section",
    designation: "W6X16",
    shape: "I",
    country_code: "American",
    ax: "4,74",
    ax_global: "3058,0584",
    bf: "4,03",
    bf_global: "102,362",
    ct: "0,68",
    ct_global: "17,272",
    d: "6,28",
    d_global: "159,512",
    ix: "0,22",
    ix_global: "91570,91363",
    iy: "4,43",
    iy_global: "1843905,215",
    iz: "32,1",
    iz_global: "13361028,76",
    od: "0,66",
    od_global: "16,764",
    rz: "0,56",
    rz_global: "14,224",
    t: "4,97",
    t_global: "126,238",
    tf: "0,41",
    tf_global: "10,414",
    tw: "0,26",
    tw_global: "6,604",
    zx: "11,7",
    zx_global: "191728,6488",
    zy: "3,39",
    zy_global: "55552,14696",
    width: "4,03",
    width_global: "102,362",
    thickness: "0,41",
    thickness_global: "10,414",
    height: "6,28",
    height_global: "159,512",
  },
  {
    profile_section_id: 277,
    name: "W6X12",
    type: "W Section",
    designation: "W6X12",
    shape: "I",
    country_code: "American",
    ax: "3,55",
    ax_global: "2290,318",
    bf: 4,
    bf_global: "101,6",
    ct: "0,68",
    ct_global: "17,272",
    d: "6,03",
    d_global: "153,162",
    ix: "0,09",
    ix_global: "37460,8283",
    iy: "2,99",
    iy_global: "1244531,963",
    iz: "22,1",
    iz_global: "9198714,506",
    od: "0,53",
    od_global: "13,462",
    rz: "0,56",
    rz_global: "14,224",
    t: "4,97",
    t_global: "126,238",
    tf: "0,28",
    tf_global: "7,112",
    tw: "0,23",
    tw_global: "5,842",
    zx: "8,3",
    zx_global: "136012,6312",
    zy: "2,32",
    zy_global: "38017,98848",
    width: 4,
    width_global: "101,6",
    thickness: "0,28",
    thickness_global: "7,112",
    height: "6,03",
    height_global: "153,162",
  },
  {
    profile_section_id: 278,
    name: "W6X9",
    type: "W Section",
    designation: "W6X9",
    shape: "I",
    country_code: "American",
    ax: "2,68",
    ax_global: "1729,0288",
    bf: "3,94",
    bf_global: "100,076",
    ct: "0,62",
    ct_global: "15,748",
    d: "5,9",
    d_global: "149,86",
    ix: "0,04",
    ix_global: "16649,25702",
    iy: "2,2",
    iy_global: "915709,1363",
    iz: "16,4",
    iz_global: "6826195,38",
    od: "0,47",
    od_global: "11,938",
    rz: "0,5",
    rz_global: "12,7",
    t: "4,97",
    t_global: "126,238",
    tf: "0,22",
    tf_global: "5,588",
    tw: "0,17",
    tw_global: "4,318",
    zx: "6,23",
    zx_global: "102091,4087",
    zy: "1,72",
    zy_global: "28185,75008",
    width: "3,94",
    width_global: "100,076",
    thickness: "0,22",
    thickness_global: "5,588",
    height: "5,9",
    height_global: "149,86",
  },
  {
    profile_section_id: 279,
    name: "W6X8.5",
    type: "W Section",
    designation: "W6X8.5",
    shape: "I",
    country_code: "American",
    ax: "2,52",
    ax_global: "1625,8032",
    bf: "3,94",
    bf_global: "100,076",
    ct: "0,64",
    ct_global: "16,256",
    d: "5,83",
    d_global: "148,082",
    ix: "0,03",
    ix_global: "12486,94277",
    iy: "1,99",
    iy_global: "828300,5369",
    iz: "14,9",
    iz_global: "6201848,241",
    od: "0,45",
    od_global: "11,43",
    rz: "0,5",
    rz_global: "12,7",
    t: "4,94",
    t_global: "125,476",
    tf: "0,2",
    tf_global: "5,08",
    tw: "0,17",
    tw_global: "4,318",
    zx: "5,73",
    zx_global: "93897,87672",
    zy: "1,56",
    zy_global: "25563,81984",
    width: "3,94",
    width_global: "100,076",
    thickness: "0,2",
    thickness_global: "5,08",
    height: "5,83",
    height_global: "148,082",
  },
  {
    profile_section_id: 280,
    name: "W5X19",
    type: "W Section",
    designation: "W5X19",
    shape: "I",
    country_code: "American",
    ax: "5,56",
    ax_global: "3587,0896",
    bf: "5,03",
    bf_global: "127,762",
    ct: "0,49",
    ct_global: "12,446",
    d: "5,15",
    d_global: "130,81",
    ix: "0,32",
    ix_global: "133194,0562",
    iy: "9,13",
    iy_global: "3800192,916",
    iz: "26,3",
    iz_global: "10946886,49",
    od: "0,73",
    od_global: "18,542",
    rz: "0,44",
    rz_global: "11,176",
    t: "3,69",
    t_global: "93,726",
    tf: "0,43",
    tf_global: "10,922",
    tw: "0,27",
    tw_global: "6,858",
    zx: "11,6",
    zx_global: "190089,9424",
    zy: "5,53",
    zy_global: "90620,46392",
    width: "5,03",
    width_global: "127,762",
    thickness: "0,43",
    thickness_global: "10,922",
    height: "5,15",
    height_global: "130,81",
  },
  {
    profile_section_id: 281,
    name: "W5X16",
    type: "W Section",
    designation: "W5X16",
    shape: "I",
    country_code: "American",
    ax: "4,71",
    ax_global: "3038,7036",
    bf: 5,
    bf_global: 127,
    ct: "0,46",
    ct_global: "11,684",
    d: "5,01",
    d_global: "127,254",
    ix: "0,19",
    ix_global: "79083,97086",
    iy: "7,51",
    iy_global: "3125898,006",
    iz: "21,4",
    iz_global: "8907352,508",
    od: "0,66",
    od_global: "16,764",
    rz: "0,44",
    rz_global: "11,176",
    t: "3,69",
    t_global: "93,726",
    tf: "0,36",
    tf_global: "9,144",
    tw: "0,24",
    tw_global: "6,096",
    zx: "9,63",
    zx_global: "157807,4263",
    zy: "4,58",
    zy_global: "75052,75312",
    width: 5,
    width_global: 127,
    thickness: "0,36",
    thickness_global: "9,144",
    height: "5,01",
    height_global: "127,254",
  },
  {
    profile_section_id: 282,
    name: "W4X13",
    type: "W Section",
    designation: "W4X13",
    shape: "I",
    country_code: "American",
    ax: "3,83",
    ax_global: "2470,9628",
    bf: "4,06",
    bf_global: "103,124",
    ct: "0,44",
    ct_global: "11,176",
    d: "4,16",
    d_global: "105,664",
    ix: "0,15",
    ix_global: "62434,71384",
    iy: "3,86",
    iy_global: "1606653,303",
    iz: "11,3",
    iz_global: "4703415,109",
    od: "0,59",
    od_global: "14,986",
    rz: "0,5",
    rz_global: "12,7",
    t: "2,97",
    t_global: "75,438",
    tf: "0,34",
    tf_global: "8,636",
    tw: "0,28",
    tw_global: "7,112",
    zx: "6,28",
    zx_global: "102910,7619",
    zy: "2,92",
    zy_global: "47850,22688",
    width: "4,06",
    width_global: "103,124",
    thickness: "0,34",
    thickness_global: "8,636",
    height: "4,16",
    height_global: "105,664",
  },
  {
    profile_section_id: 283,
    name: "W21X300",
    type: "W Shape",
    designation: "W21X300",
    shape: "I",
    country_code: "American",
    ax: "88,2",
    ax_global: "56903,112",
    bf: "12,99",
    bf_global: "329,946",
    ct: 0,
    d: "24,53",
    d_global: "623,062",
    ix: 130,
    ix_global: "54110085,33",
    iy: 873,
    iy_global: "363370034,5",
    iz: 8480,
    iz_global: 3529642489,
    k: "3,125",
    k_global: "79,375",
    k1: "1,25",
    k1_global: "31,75",
    t: "18,25",
    t_global: "463,55",
    tf: "2,38",
    tf_global: "60,452",
    tw: "1,32",
    tw_global: "33,528",
    zx: 816,
    zx_global: "13371844,22",
    zy: 210,
    zy_global: "3441283,44",
    width: "12,99",
    width_global: "329,946",
    thickness: "2,38",
    thickness_global: "60,452",
    height: "24,53",
    height_global: "623,062",
  },
  {
    profile_section_id: 284,
    name: "W21X333",
    type: "W Shape",
    designation: "W21X333",
    shape: "I",
    country_code: "American",
    ax: "97,9",
    ax_global: "63161,164",
    bf: "13,13",
    bf_global: "333,502",
    ct: 0,
    d: 25,
    d_global: 635,
    ix: 174,
    ix_global: "72424268,05",
    iy: 994,
    iy_global: 413734037,
    iz: 9610,
    iz_global: 3999984000,
    k: "3,375",
    k_global: "85,725",
    k1: "1,3125",
    k1_global: "33,3375",
    t: "18,25",
    t_global: "463,55",
    tf: "2,62",
    tf_global: "66,548",
    tw: "1,46",
    tw_global: "37,084",
    zx: 915,
    zx_global: "14994163,56",
    zy: 237,
    zy_global: "3883734,168",
    width: "13,13",
    width_global: "333,502",
    thickness: "2,62",
    thickness_global: "66,548",
    height: 25,
    height_global: 635,
  },
  {
    profile_section_id: 285,
    name: "W21X364",
    type: "W Shape",
    designation: "W21X364",
    shape: "I",
    country_code: "American",
    ax: 107,
    ax_global: "69032,12",
    bf: "13,265",
    bf_global: "336,931",
    ct: 0,
    d: "25,47",
    d_global: "646,938",
    ix: 225,
    ix_global: "93652070,76",
    iy: 1120,
    iy_global: "466179196,7",
    iz: 10800,
    iz_global: 4495299396,
    k: "3,625",
    k_global: "92,075",
    k1: "1,375",
    k1_global: "34,925",
    t: "18,25",
    t_global: "463,55",
    tf: "2,85",
    tf_global: "72,39",
    tw: "1,59",
    tw_global: "40,386",
    zx: 1010,
    zx_global: "16550934,64",
    zy: 263,
    zy_global: "4309797,832",
    width: "13,265",
    width_global: "336,931",
    thickness: "2,85",
    thickness_global: "72,39",
    height: "25,47",
    height_global: "646,938",
  },
  {
    profile_section_id: 286,
    name: "W21X402",
    type: "W Shape",
    designation: "W21X402",
    shape: "I",
    country_code: "American",
    ax: 118,
    ax_global: "76128,88",
    bf: "13,405",
    bf_global: "340,487",
    ct: 0,
    d: "26,02",
    d_global: "660,908",
    ix: 297,
    ix_global: "123620733,4",
    iy: 1270,
    iy_global: "528613910,5",
    iz: 12200,
    iz_global: 5078023392,
    k: "3,875",
    k_global: "98,425",
    k1: "1,4375",
    k1_global: "36,5125",
    t: "18,25",
    t_global: "463,55",
    tf: "3,13",
    tf_global: "79,502",
    tw: "1,73",
    tw_global: "43,942",
    zx: 1130,
    zx_global: "18517382,32",
    zy: 296,
    zy_global: "4850570,944",
    width: "13,405",
    width_global: "340,487",
    thickness: "3,13",
    thickness_global: "79,502",
    height: "26,02",
    height_global: "660,908",
  },
  {
    profile_section_id: 287,
    name: "W24X408",
    type: "W Shape",
    designation: "W24X408",
    shape: "I",
    country_code: "American",
    ax: 119,
    ax_global: "76774,04",
    bf: "13,8",
    bf_global: "350,52",
    ct: "3,74",
    d: "28,54",
    d_global: "724,916",
    ix: 271,
    ix_global: "112798716,3",
    iy: 1320,
    iy_global: "549425481,8",
    iz: 15100,
    iz_global: 6285094527,
    k: "3,75",
    k_global: "95,25",
    k1: "1,375",
    k1_global: "34,925",
    t: 21,
    t_global: "533,4",
    tf: "2,99",
    tf_global: "75,946",
    tw: "1,65",
    tw_global: "41,91",
    zx: 1250,
    zx_global: 20483830,
    zy: 300,
    zy_global: "4916119,2",
    width: "13,8",
    width_global: "350,52",
    thickness: "2,99",
    thickness_global: "75,946",
    height: "28,54",
    height_global: "724,916",
  },
  {
    profile_section_id: 288,
    name: "W24X450",
    type: "W Shape",
    designation: "W24X450",
    shape: "I",
    country_code: "American",
    ax: 132,
    ax_global: "85161,12",
    bf: "13,955",
    bf_global: "354,457",
    ct: 0,
    d: "29,09",
    d_global: "738,886",
    ix: 357,
    ix_global: "148594618,9",
    iy: 1490,
    iy_global: "620184824,1",
    iz: 17100,
    iz_global: 7117557378,
    k: "4,0625",
    k_global: "103,1875",
    k1: "1,5",
    k1_global: "38,1",
    t: 21,
    t_global: "533,4",
    tf: "3,27",
    tf_global: "83,058",
    tw: "1,81",
    tw_global: "45,974",
    zx: 1410,
    zx_global: "23105760,24",
    zy: 337,
    zy_global: "5522440,568",
    width: "13,955",
    width_global: "354,457",
    thickness: "3,27",
    thickness_global: "83,058",
    height: "29,09",
    height_global: "738,886",
  },
  {
    profile_section_id: 289,
    name: "W24X492",
    type: "W Shape",
    designation: "W24X492",
    shape: "I",
    country_code: "American",
    ax: 144,
    ax_global: "92903,04",
    bf: "14,115",
    bf_global: "358,521",
    ct: "4,07",
    d: "29,65",
    d_global: "753,11",
    ix: 456,
    ix_global: "189801530,1",
    iy: 1670,
    iy_global: "695106480,8",
    iz: 19100,
    iz_global: 7950020229,
    k: "4,313",
    k_global: "109,5502",
    k1: "1,563",
    k1_global: "39,7002",
    t: 21,
    t_global: "533,4",
    tf: "3,54",
    tf_global: "89,916",
    tw: "1,97",
    tw_global: "50,038",
    zx: 1550,
    zx_global: "25399949,2",
    zy: 375,
    zy_global: 6145149,
    width: "14,115",
    width_global: "358,521",
    thickness: "3,54",
    thickness_global: "89,916",
    height: "29,65",
    height_global: "753,11",
  },
  {
    profile_section_id: 290,
    name: "W27X407",
    type: "W Shape",
    designation: "W27X407",
    shape: "I",
    country_code: "American",
    ax: 119,
    ax_global: "76774,04",
    bf: "14,8",
    bf_global: "375,92",
    ct: 0,
    d: "30,87",
    d_global: "784,098",
    ix: 225,
    ix_global: "93652070,76",
    iy: 1480,
    iy_global: "616022509,9",
    iz: 18100,
    iz_global: 7533788803,
    k: "3,4375",
    k_global: "87,3125",
    k1: "1,4375",
    k1_global: "36,5125",
    t: 24,
    t_global: "609,6",
    tf: "2,72",
    tf_global: "69,088",
    tw: "1,52",
    tw_global: "38,608",
    zx: 1380,
    zx_global: "22614148,32",
    zy: 313,
    zy_global: "5129151,032",
    width: "14,8",
    width_global: "375,92",
    thickness: "2,72",
    thickness_global: "69,088",
    height: "30,87",
    height_global: "784,098",
  },
  {
    profile_section_id: 291,
    name: "W27X448",
    type: "W Shape",
    designation: "W27X448",
    shape: "I",
    country_code: "American",
    ax: 131,
    ax_global: "84515,96",
    bf: "14,94",
    bf_global: "379,476",
    ct: "4,02",
    d: "31,42",
    d_global: "798,068",
    ix: 297,
    ix_global: "123620733,4",
    iy: 1670,
    iy_global: "695106480,8",
    iz: 20400,
    iz_global: 8491121082,
    k: "3,688",
    k_global: "93,6752",
    k1: "1,5",
    k1_global: "38,1",
    t: 24,
    t_global: "609,6",
    tf: "2,99",
    tf_global: "75,946",
    tw: "1,65",
    tw_global: "41,91",
    zx: 1530,
    zx_global: "25072207,92",
    zy: 351,
    zy_global: "5751859,464",
    width: "14,94",
    width_global: "379,476",
    thickness: "2,99",
    thickness_global: "75,946",
    height: "31,42",
    height_global: "798,068",
  },
  {
    profile_section_id: 292,
    name: "W27X494",
    type: "W Shape",
    designation: "W27X494",
    shape: "I",
    country_code: "American",
    ax: 145,
    ax_global: "93548,2",
    bf: "15,095",
    bf_global: "383,413",
    ct: 0,
    d: "31,97",
    d_global: "812,038",
    ix: 391,
    ix_global: "162746487,4",
    iy: 1890,
    iy_global: "786677394,4",
    iz: 22900,
    iz_global: 9531699646,
    k: 4,
    k_global: "101,6",
    k1: "1,5625",
    k1_global: "39,6875",
    t: 24,
    t_global: "609,6",
    tf: "3,27",
    tf_global: "83,058",
    tw: "1,81",
    tw_global: "45,974",
    zx: 1710,
    zx_global: "28021879,44",
    zy: 394,
    zy_global: "6456503,216",
    width: "15,095",
    width_global: "383,413",
    thickness: "3,27",
    thickness_global: "83,058",
    height: "31,97",
    height_global: "812,038",
  },
  {
    profile_section_id: 293,
    name: "W30X433",
    type: "W Shape",
    designation: "W30X433",
    shape: "I",
    country_code: "American",
    ax: 127,
    ax_global: "81935,32",
    bf: "15,725",
    bf_global: "399,415",
    ct: 0,
    d: "33,66",
    d_global: "854,964",
    ix: 231,
    ix_global: "96149459,31",
    iy: 1750,
    iy_global: "728404994,8",
    iz: 23200,
    iz_global: 9656569074,
    k: "3,4375",
    k_global: "87,3125",
    k1: "1,5",
    k1_global: "38,1",
    t: "26,75",
    t_global: "679,45",
    tf: "2,68",
    tf_global: "68,072",
    tw: "1,5",
    tw_global: "38,1",
    zx: 1610,
    zx_global: "26383173,04",
    zy: 348,
    zy_global: "5702698,272",
    width: "15,725",
    width_global: "399,415",
    thickness: "2,68",
    thickness_global: "68,072",
    height: "33,66",
    height_global: "854,964",
  },
  {
    profile_section_id: 294,
    name: "W30X477",
    type: "W Shape",
    designation: "W30X477",
    shape: "I",
    country_code: "American",
    ax: 140,
    ax_global: "90322,4",
    bf: "15,865",
    bf_global: "402,971",
    ct: "4,3",
    d: "34,21",
    d_global: "868,934",
    ix: 307,
    ix_global: "127783047,7",
    iy: 1970,
    iy_global: "819975908,4",
    iz: 26100,
    iz_global: 10863640208,
    k: "3,75",
    k_global: "95,25",
    k1: "1,563",
    k1_global: "39,7002",
    t: "26,75",
    t_global: "679,45",
    tf: "2,95",
    tf_global: "74,93",
    tw: "1,63",
    tw_global: "41,402",
    zx: 1790,
    zx_global: "29332844,56",
    zy: 390,
    zy_global: "6390954,96",
    width: "15,865",
    width_global: "402,971",
    thickness: "2,95",
    thickness_global: "74,93",
    height: "34,21",
    height_global: "868,934",
  },
  {
    profile_section_id: 295,
    name: "W30X526",
    type: "W Shape",
    designation: "W30X526",
    shape: "I",
    country_code: "American",
    ax: 154,
    ax_global: "99354,64",
    bf: "16,02",
    bf_global: "406,908",
    ct: 0,
    d: "34,76",
    d_global: "882,904",
    ix: 405,
    ix_global: "168573727,4",
    iy: 2230,
    iy_global: "928196079,1",
    iz: 29300,
    iz_global: 12195580770,
    k: 4,
    k_global: "101,6",
    k1: "1,625",
    k1_global: "41,275",
    t: "26,75",
    t_global: "679,45",
    tf: "3,23",
    tf_global: "82,042",
    tw: "1,79",
    tw_global: "45,466",
    zx: 1990,
    zx_global: "32610257,36",
    zy: 438,
    zy_global: "7177534,032",
    width: "16,02",
    width_global: "406,908",
    thickness: "3,23",
    thickness_global: "82,042",
    height: "34,76",
    height_global: "882,904",
  },
  {
    profile_section_id: 296,
    name: "W30X581",
    type: "W Shape",
    designation: "W30X581",
    shape: "I",
    country_code: "American",
    ax: 170,
    ax_global: "109677,2",
    bf: "16,2",
    bf_global: "411,48",
    ct: 0,
    d: "35,39",
    d_global: "898,906",
    ix: 537,
    ix_global: "223516275,5",
    iy: 2530,
    iy_global: 1053065507,
    iz: 33000,
    iz_global: 13735637045,
    k: "4,3125",
    k_global: "109,5375",
    k1: "1,6875",
    k1_global: "42,8625",
    t: "26,75",
    t_global: "679,45",
    tf: "3,54",
    tf_global: "89,916",
    tw: "1,97",
    tw_global: "50,038",
    zx: 2210,
    zx_global: "36215411,44",
    zy: 492,
    zy_global: "8062435,488",
    width: "16,2",
    width_global: "411,48",
    thickness: "3,54",
    thickness_global: "89,916",
    height: "35,39",
    height_global: "898,906",
  },
  {
    profile_section_id: 297,
    name: "W33X424",
    type: "W Shape",
    designation: "W33X424",
    shape: "I",
    country_code: "American",
    ax: 124,
    ax_global: "79999,84",
    bf: "16,315",
    bf_global: "414,401",
    ct: 0,
    d: "36,34",
    d_global: "923,036",
    ix: 193,
    ix_global: "80332665,14",
    iy: 1800,
    iy_global: "749216566,1",
    iz: 26900,
    iz_global: 11196625349,
    k: "3,07",
    k_global: "77,978",
    k1: "1,4375",
    k1_global: "36,5125",
    t: "29,86",
    t_global: "758,444",
    tf: "2,48",
    tf_global: "62,992",
    tw: "1,38",
    tw_global: "35,052",
    zx: 1700,
    zx_global: "27858008,8",
    zy: 345,
    zy_global: "5653537,08",
    width: "16,315",
    width_global: "414,401",
    thickness: "2,48",
    thickness_global: "62,992",
    height: "36,34",
    height_global: "923,036",
  },
  {
    profile_section_id: 298,
    name: "W33X468",
    type: "W Shape",
    designation: "W33X468",
    shape: "I",
    country_code: "American",
    ax: 137,
    ax_global: "88386,92",
    bf: "16,455",
    bf_global: "417,957",
    ct: 0,
    d: "36,81",
    d_global: "934,974",
    ix: 256,
    ix_global: 106555245,
    iy: 2030,
    iy_global: 844949794,
    iz: 30100,
    iz_global: 12528565911,
    k: "3,5",
    k_global: "88,9",
    k1: "1,5625",
    k1_global: "39,6875",
    t: "29,75",
    t_global: "755,65",
    tf: "2,72",
    tf_global: "69,088",
    tw: "1,52",
    tw_global: "38,608",
    zx: 1890,
    zx_global: "30971550,96",
    zy: 387,
    zy_global: "6341793,768",
    width: "16,455",
    width_global: "417,957",
    thickness: "2,72",
    thickness_global: "69,088",
    height: "36,81",
    height_global: "934,974",
  },
  {
    profile_section_id: 299,
    name: "W33X515",
    type: "W Shape",
    designation: "W33X515",
    shape: "I",
    country_code: "American",
    ax: 151,
    ax_global: "97419,16",
    bf: "16,59",
    bf_global: "421,386",
    ct: 0,
    d: "37,36",
    d_global: "948,944",
    ix: 338,
    ix_global: "140686221,9",
    iy: 2290,
    iy_global: "953169964,6",
    iz: 33700,
    iz_global: 14026999043,
    k: "3,8125",
    k_global: "96,8375",
    k1: "1,625",
    k1_global: "41,275",
    t: "29,75",
    t_global: "755,65",
    tf: "2,99",
    tf_global: "75,946",
    tw: "1,65",
    tw_global: "41,91",
    zx: 2110,
    zx_global: "34576705,04",
    zy: 433,
    zy_global: "7095598,712",
    width: "16,59",
    width_global: "421,386",
    thickness: "2,99",
    thickness_global: "75,946",
    height: "37,36",
    height_global: "948,944",
  },
  {
    profile_section_id: 300,
    name: "W33X567",
    type: "W Shape",
    designation: "W33X567",
    shape: "I",
    country_code: "American",
    ax: 166,
    ax_global: "107096,56",
    bf: "16,75",
    bf_global: "425,45",
    ct: 0,
    d: "37,91",
    d_global: "962,914",
    ix: 444,
    ix_global: 184806753,
    iy: 2580,
    iy_global: 1073877078,
    iz: 37700,
    iz_global: 15691924745,
    k: "4,0625",
    k_global: "103,1875",
    k1: "1,6875",
    k1_global: "42,8625",
    t: "29,75",
    t_global: "755,65",
    tf: "3,27",
    tf_global: "83,058",
    tw: "1,81",
    tw_global: "45,974",
    zx: 2330,
    zx_global: "38181859,12",
    zy: 485,
    zy_global: "7947726,04",
    width: "16,75",
    width_global: "425,45",
    thickness: "3,27",
    thickness_global: "83,058",
    height: "37,91",
    height_global: "962,914",
  },
  {
    profile_section_id: 301,
    name: "W33X619",
    type: "W Shape",
    designation: "W33X619",
    shape: "I",
    country_code: "American",
    ax: 181,
    ax_global: "116773,96",
    bf: "16,91",
    bf_global: "429,514",
    ct: 0,
    d: "38,47",
    d_global: "977,138",
    ix: 567,
    ix_global: "236003218,3",
    iy: 2870,
    iy_global: 1194584191,
    iz: 41800,
    iz_global: 17398473590,
    k: "4,375",
    k_global: "111,125",
    k1: "1,75",
    k1_global: "44,45",
    t: "29,75",
    t_global: "755,65",
    tf: "3,54",
    tf_global: "89,916",
    tw: "1,97",
    tw_global: "50,038",
    zx: 2560,
    zx_global: "41950883,84",
    zy: 537,
    zy_global: "8799853,368",
    width: "16,91",
    width_global: "429,514",
    thickness: "3,54",
    thickness_global: "89,916",
    height: "38,47",
    height_global: "977,138",
  },
  {
    profile_section_id: 302,
    name: "W36X230",
    type: "W Shape",
    designation: "W36X230",
    shape: "I",
    country_code: "American",
    ax: "67,6",
    ax_global: "43612,816",
    bf: "16,47",
    bf_global: "418,338",
    ct: "4,01",
    d: "35,9",
    d_global: "911,86",
    ix: "28,6",
    ix_global: "11904218,77",
    iy: 940,
    iy_global: "391257540,1",
    iz: 15000,
    iz_global: 6243471384,
    k: "2,21",
    k_global: "56,134",
    k1: "1,5625",
    k1_global: "39,6875",
    t: "31,48",
    t_global: "799,592",
    tf: "1,26",
    tf_global: "32,004",
    tw: "0,76",
    tw_global: "19,304",
    zx: 943,
    zx_global: "15453001,35",
    zy: 176,
    zy_global: "2884123,264",
    width: "16,47",
    width_global: "418,338",
    thickness: "1,26",
    thickness_global: "32,004",
    height: "35,9",
    height_global: "911,86",
  },
  {
    profile_section_id: 303,
    name: "W36X245",
    type: "W Shape",
    designation: "W36X245",
    shape: "I",
    country_code: "American",
    ax: "72,1",
    ax_global: "46516,036",
    bf: "16,51",
    bf_global: "419,354",
    ct: "4,03",
    d: "36,08",
    d_global: "916,432",
    ix: "34,6",
    ix_global: "14401607,33",
    iy: 1010,
    iy_global: "420393739,9",
    iz: 16100,
    iz_global: 6701325952,
    k: "2,3",
    k_global: "58,42",
    k1: "1,625",
    k1_global: "41,275",
    t: "31,5",
    t_global: "800,1",
    tf: "1,35",
    tf_global: "34,29",
    tw: "0,8",
    tw_global: "20,32",
    zx: 1010,
    zx_global: "16550934,64",
    zy: 190,
    zy_global: "3113542,16",
    width: "16,51",
    width_global: "419,354",
    thickness: "1,35",
    thickness_global: "34,29",
    height: "36,08",
    height_global: "916,432",
  },
  {
    profile_section_id: 304,
    name: "W36X260",
    type: "W Shape",
    designation: "W36X260",
    shape: "I",
    country_code: "American",
    ax: "76,5",
    ax_global: "49354,74",
    bf: "16,55",
    bf_global: "420,37",
    ct: "4,05",
    d: "36,26",
    d_global: "921,004",
    ix: "41,5",
    ix_global: "17273604,16",
    iy: 1090,
    iy_global: "453692253,9",
    iz: 17300,
    iz_global: 7200803663,
    k: "2,39",
    k_global: "60,706",
    k1: "1,625",
    k1_global: "41,275",
    t: "31,52",
    t_global: "800,608",
    tf: "1,44",
    tf_global: "36,576",
    tw: "0,84",
    tw_global: "21,336",
    zx: 1080,
    zx_global: "17698029,12",
    zy: 204,
    zy_global: "3342961,056",
    width: "16,55",
    width_global: "420,37",
    thickness: "1,44",
    thickness_global: "36,576",
    height: "36,26",
    height_global: "921,004",
  },
  {
    profile_section_id: 305,
    name: "W36X280",
    type: "W Shape",
    designation: "W36X280",
    shape: "I",
    country_code: "American",
    ax: "82,4",
    ax_global: "53161,184",
    bf: "16,595",
    bf_global: "421,513",
    ct: "4,07",
    d: "36,52",
    d_global: "927,608",
    ix: "52,6",
    ix_global: "21893772,99",
    iy: 1200,
    iy_global: "499477710,7",
    iz: 18900,
    iz_global: 7866773944,
    k: "2,52",
    k_global: "64,008",
    k1: "1,625",
    k1_global: "41,275",
    t: "31,46",
    t_global: "799,084",
    tf: "1,57",
    tf_global: "39,878",
    tw: "0,885",
    tw_global: "22,479",
    zx: 1170,
    zx_global: "19172864,88",
    zy: 223,
    zy_global: "3654315,272",
    width: "16,595",
    width_global: "421,513",
    thickness: "1,57",
    thickness_global: "39,878",
    height: "36,52",
    height_global: "927,608",
  },
  {
    profile_section_id: 306,
    name: "W36X300",
    type: "W Shape",
    designation: "W36X300",
    shape: "I",
    country_code: "American",
    ax: "88,3",
    ax_global: "56967,628",
    bf: "16,655",
    bf_global: "423,037",
    ct: "4,13",
    d: "36,74",
    d_global: "933,196",
    ix: "64,2",
    ix_global: "26722057,52",
    iy: 1300,
    iy_global: "541100853,3",
    iz: 20300,
    iz_global: 8449497940,
    k: "2,63",
    k_global: "66,802",
    k1: "1,6875",
    k1_global: "42,8625",
    t: "31,44",
    t_global: "798,576",
    tf: "1,68",
    tf_global: "42,672",
    tw: "0,945",
    tw_global: "24,003",
    zx: 1260,
    zx_global: "20647700,64",
    zy: 241,
    zy_global: "3949282,424",
    width: "16,655",
    width_global: "423,037",
    thickness: "1,68",
    thickness_global: "42,672",
    height: "36,74",
    height_global: "933,196",
  },
  {
    profile_section_id: 307,
    name: "W36X328",
    type: "W Shape",
    designation: "W36X328",
    shape: "I",
    country_code: "American",
    ax: "96,4",
    ax_global: "62193,424",
    bf: "16,63",
    bf_global: "422,402",
    ct: "4,21",
    d: "37,09",
    d_global: "942,086",
    ix: "84,5",
    ix_global: "35171555,46",
    iy: 1420,
    iy_global: "591048624,4",
    iz: 22500,
    iz_global: 9365207076,
    k: "2,8",
    k_global: "71,12",
    k1: "1,75",
    k1_global: "44,45",
    t: "31,5",
    t_global: "800,1",
    tf: "1,85",
    tf_global: "46,99",
    tw: "1,02",
    tw_global: "25,908",
    zx: 1380,
    zx_global: "22614148,32",
    zy: 265,
    zy_global: "4342571,96",
    width: "16,63",
    width_global: "422,402",
    thickness: "1,85",
    thickness_global: "46,99",
    height: "37,09",
    height_global: "942,086",
  },
  {
    profile_section_id: 308,
    name: "W36X359",
    type: "W Shape",
    designation: "W36X359",
    shape: "I",
    country_code: "American",
    ax: 105,
    ax_global: "67741,8",
    bf: "16,73",
    bf_global: "424,942",
    ct: "4,33",
    d: "37,4",
    d_global: "949,96",
    ix: 109,
    ix_global: "45369225,39",
    iy: 1570,
    iy_global: "653483338,2",
    iz: 24800,
    iz_global: 10322539355,
    k: "2,96",
    k_global: "75,184",
    k1: "1,75",
    k1_global: "44,45",
    t: "31,48",
    t_global: "799,592",
    tf: "2,01",
    tf_global: "51,054",
    tw: "1,12",
    tw_global: "28,448",
    zx: 1510,
    zx_global: "24744466,64",
    zy: 292,
    zy_global: "4785022,688",
    width: "16,73",
    width_global: "424,942",
    thickness: "2,01",
    thickness_global: "51,054",
    height: "37,4",
    height_global: "949,96",
  },
  {
    profile_section_id: 309,
    name: "W36X393",
    type: "W Shape",
    designation: "W36X393",
    shape: "I",
    country_code: "American",
    ax: 115,
    ax_global: "74193,4",
    bf: "16,83",
    bf_global: "427,482",
    ct: "4,44",
    d: "37,8",
    d_global: "960,12",
    ix: 143,
    ix_global: "59521093,86",
    iy: 1750,
    iy_global: "728404994,8",
    iz: 27500,
    iz_global: 11446364204,
    k: "3,15",
    k_global: "80,01",
    k1: "1,8125",
    k1_global: "46,0375",
    t: "31,5",
    t_global: "800,1",
    tf: "2,2",
    tf_global: "55,88",
    tw: "1,22",
    tw_global: "30,988",
    zx: 1660,
    zx_global: "27202526,24",
    zy: 325,
    zy_global: "5325795,8",
    width: "16,83",
    width_global: "427,482",
    thickness: "2,2",
    thickness_global: "55,88",
    height: "37,8",
    height_global: "960,12",
  },
  {
    profile_section_id: 310,
    name: "W36X439",
    type: "W Shape",
    designation: "W36X439",
    shape: "I",
    country_code: "American",
    ax: 128,
    ax_global: "82580,48",
    bf: "16,965",
    bf_global: "430,911",
    ct: "4,6",
    d: "38,26",
    d_global: "971,804",
    ix: 195,
    ix_global: "81165127,99",
    iy: 1990,
    iy_global: "828300536,9",
    iz: 31000,
    iz_global: 12903174194,
    k: "3,39",
    k_global: "86,106",
    k1: "1,875",
    k1_global: "47,625",
    t: "31,52",
    t_global: "800,608",
    tf: "2,44",
    tf_global: "61,976",
    tw: "1,36",
    tw_global: "34,544",
    zx: 1860,
    zx_global: "30479939,04",
    zy: 367,
    zy_global: "6014052,488",
    width: "16,965",
    width_global: "430,911",
    thickness: "2,44",
    thickness_global: "61,976",
    height: "38,26",
    height_global: "971,804",
  },
  {
    profile_section_id: 311,
    name: "W36X485",
    type: "W Shape",
    designation: "W36X485",
    shape: "I",
    country_code: "American",
    ax: 142,
    ax_global: "91612,72",
    bf: "17,105",
    bf_global: "434,467",
    ct: 0,
    d: "38,74",
    d_global: "983,996",
    ix: 260,
    ix_global: "108220170,7",
    iy: 2250,
    iy_global: "936520707,6",
    iz: 34700,
    iz_global: 14443230468,
    k: "3,8125",
    k_global: "96,8375",
    k1: "1,75",
    k1_global: "44,45",
    t: "31,125",
    t_global: "790,575",
    tf: "2,68",
    tf_global: "68,072",
    tw: "1,5",
    tw_global: "38,1",
    zx: 2070,
    zx_global: "33921222,48",
    zy: 412,
    zy_global: "6751470,368",
    width: "17,105",
    width_global: "434,467",
    thickness: "2,68",
    thickness_global: "68,072",
    height: "38,74",
    height_global: "983,996",
  },
  {
    profile_section_id: 312,
    name: "W36X527",
    type: "W Shape",
    designation: "W36X527",
    shape: "I",
    country_code: "American",
    ax: 154,
    ax_global: "99354,64",
    bf: "17,22",
    bf_global: "437,388",
    ct: "4,87",
    d: "39,21",
    d_global: "995,934",
    ix: 330,
    ix_global: "137356370,4",
    iy: 2490,
    iy_global: 1036416250,
    iz: 38300,
    iz_global: 15941663600,
    k: "3,86",
    k_global: "98,044",
    k1: 2,
    k1_global: "50,8",
    t: "31,48",
    t_global: "799,592",
    tf: "2,91",
    tf_global: "73,914",
    tw: "1,61",
    tw_global: "40,894",
    zx: 2270,
    zx_global: "37198635,28",
    zy: 454,
    zy_global: "7439727,056",
    width: "17,22",
    width_global: "437,388",
    thickness: "2,91",
    thickness_global: "73,914",
    height: "39,21",
    height_global: "995,934",
  },
  {
    profile_section_id: 313,
    name: "W36X588",
    type: "W Shape",
    designation: "W36X588",
    shape: "I",
    country_code: "American",
    ax: 172,
    ax_global: "110967,52",
    bf: "17,4",
    bf_global: "441,96",
    ct: 0,
    d: "39,84",
    d_global: "1011,936",
    ix: 453,
    ix_global: "188552835,8",
    iy: 2850,
    iy_global: 1186259563,
    iz: 43500,
    iz_global: 18106067014,
    k: "4,375",
    k_global: "111,125",
    k1: "1,875",
    k1_global: "47,625",
    t: "31,125",
    t_global: "790,575",
    tf: "3,23",
    tf_global: "82,042",
    tw: "1,79",
    tw_global: "45,466",
    zx: 2550,
    zx_global: "41787013,2",
    zy: 517,
    zy_global: "8472112,088",
    width: "17,4",
    width_global: "441,96",
    thickness: "3,23",
    thickness_global: "82,042",
    height: "39,84",
    height_global: "1011,936",
  },
  {
    profile_section_id: 314,
    name: "W36X650",
    type: "W Shape",
    designation: "W36X650",
    shape: "I",
    country_code: "American",
    ax: 190,
    ax_global: "122580,4",
    bf: "17,575",
    bf_global: "446,405",
    ct: "5,26",
    d: "40,47",
    d_global: "1027,938",
    ix: 600,
    ix_global: "249738855,4",
    iy: 3230,
    iy_global: 1344427505,
    iz: 48900,
    iz_global: 20353716712,
    k: "4,49",
    k_global: "114,046",
    k1: "2,1875",
    k1_global: "55,5625",
    t: "31,52",
    t_global: "800,608",
    tf: "3,54",
    tf_global: "89,916",
    tw: "1,97",
    tw_global: "50,038",
    zx: 2840,
    zx_global: "46539261,76",
    zy: 580,
    zy_global: "9504497,12",
    width: "17,575",
    width_global: "446,405",
    thickness: "3,54",
    thickness_global: "89,916",
    height: "40,47",
    height_global: "1027,938",
  },
  {
    profile_section_id: 315,
    name: "W36X720",
    type: "W Shape",
    designation: "W36X720",
    shape: "I",
    country_code: "American",
    ax: 211,
    ax_global: "136128,76",
    bf: "17,775",
    bf_global: "451,485",
    ct: 0,
    d: "41,19",
    d_global: "1046,226",
    ix: 804,
    ix_global: "334650066,2",
    iy: 3680,
    iy_global: 1531731646,
    iz: 55300,
    iz_global: 23017597836,
    k: "5,0625",
    k_global: "128,5875",
    k1: "2,0625",
    k1_global: "52,3875",
    t: "31,125",
    t_global: "790,575",
    tf: "3,9",
    tf_global: "99,06",
    tw: "2,165",
    tw_global: "54,991",
    zx: 3190,
    zx_global: "52274734,16",
    zy: 656,
    zy_global: "10749913,98",
    width: "17,775",
    width_global: "451,485",
    thickness: "3,9",
    thickness_global: "99,06",
    height: "41,19",
    height_global: "1046,226",
  },
  {
    profile_section_id: 316,
    name: "W36X798",
    type: "W Shape",
    designation: "W36X798",
    shape: "I",
    country_code: "American",
    ax: 234,
    ax_global: "150967,44",
    bf: "17,99",
    bf_global: "456,946",
    ct: "5,71",
    d: "41,97",
    d_global: "1066,038",
    ix: 1070,
    ix_global: "445367625,4",
    iy: 4200,
    iy_global: 1748171988,
    iz: 62600,
    iz_global: 26056087243,
    k: "5,24",
    k_global: "133,096",
    k1: "2,375",
    k1_global: "60,325",
    t: "31,52",
    t_global: "800,608",
    tf: "4,29",
    tf_global: "108,966",
    tw: "2,38",
    tw_global: "60,452",
    zx: 3570,
    zx_global: "58501818,48",
    zy: 743,
    zy_global: "12175588,55",
    width: "17,99",
    width_global: "456,946",
    thickness: "4,29",
    thickness_global: "108,966",
    height: "41,97",
    height_global: "1066,038",
  },
  {
    profile_section_id: 317,
    name: "W36X800",
    type: "W Shape",
    designation: "W36X800",
    shape: "I",
    country_code: "American",
    ax: 236,
    ax_global: "152257,76",
    bf: 18,
    bf_global: "457,2",
    ct: "5,8",
    d: "42,6",
    d_global: "1082,04",
    ix: 1060,
    ix_global: "441205311,1",
    iy: 4200,
    iy_global: 1748171988,
    iz: 64700,
    iz_global: 26930173236,
    k: "5,24",
    k_global: "133,096",
    k1: "2,375",
    k1_global: "60,325",
    t: "32,12",
    t_global: "815,848",
    tf: "4,29",
    tf_global: "108,966",
    tw: "2,38",
    tw_global: "60,452",
    zx: 3650,
    zx_global: "59812783,6",
    zy: 743,
    zy_global: "12175588,55",
    width: 18,
    width_global: "457,2",
    thickness: "4,29",
    thickness_global: "108,966",
    height: "42,6",
    height_global: "1082,04",
  },
  {
    profile_section_id: 318,
    name: "W36X848",
    type: "W Shape",
    designation: "W36X848",
    shape: "I",
    country_code: "American",
    ax: 249,
    ax_global: "160644,84",
    bf: "18,13",
    bf_global: "460,502",
    ct: "5,86",
    d: "42,45",
    d_global: "1078,23",
    ix: 1270,
    ix_global: "528613910,5",
    iy: 4550,
    iy_global: 1893852986,
    iz: 67400,
    iz_global: 28053998085,
    k: "5,688",
    k_global: "144,4752",
    k1: "2,25",
    k1_global: "57,15",
    t: "31,125",
    t_global: "790,575",
    tf: "4,53",
    tf_global: "115,062",
    tw: "2,52",
    tw_global: "64,008",
    zx: 3830,
    zx_global: "62762455,12",
    zy: 799,
    zy_global: "13093264,14",
    width: "18,13",
    width_global: "460,502",
    thickness: "4,53",
    thickness_global: "115,062",
    height: "42,45",
    height_global: "1078,23",
  },
  {
    profile_section_id: 319,
    name: "W40X192",
    type: "W Shape",
    designation: "W40X192",
    shape: "I",
    country_code: "American",
    ax: "56,5",
    ax_global: "36451,54",
    bf: "17,71",
    bf_global: "449,834",
    ct: 0,
    d: "38,2",
    d_global: "970,28",
    ix: "13,7",
    ix_global: "5702370,531",
    iy: 770,
    iy_global: "320498197,7",
    iz: 13500,
    iz_global: 5619124246,
    k: "2,25",
    k_global: "57,15",
    k1: "1,5625",
    k1_global: "39,6875",
    t: "33,75",
    t_global: "857,25",
    tf: "0,83",
    tf_global: "21,082",
    tw: "0,71",
    tw_global: "18,034",
    zx: 807,
    zx_global: "13224360,65",
    zy: 135,
    zy_global: "2212253,64",
    width: "17,71",
    width_global: "449,834",
    thickness: "0,83",
    thickness_global: "21,082",
    height: "38,2",
    height_global: "970,28",
  },
  {
    profile_section_id: 320,
    name: "W40X221",
    type: "W Shape",
    designation: "W40X221",
    shape: "I",
    country_code: "American",
    ax: "64,8",
    ax_global: "41806,368",
    bf: "17,71",
    bf_global: "449,834",
    ct: 0,
    d: "38,67",
    d_global: "982,218",
    ix: "21,2",
    ix_global: "8824106,223",
    iy: 988,
    iy_global: "411236648,5",
    iz: 16600,
    iz_global: 6909441665,
    k: "2,6",
    k_global: "66,04",
    k1: "1,5625",
    k1_global: "39,6875",
    t: "34,2",
    t_global: "868,68",
    tf: "1,065",
    tf_global: "27,051",
    tw: "0,71",
    tw_global: "18,034",
    zx: 967,
    zx_global: "15846290,89",
    zy: 172,
    zy_global: "2818575,008",
    width: "17,71",
    width_global: "449,834",
    thickness: "1,065",
    thickness_global: "27,051",
    height: "38,67",
    height_global: "982,218",
  },
  {
    profile_section_id: 321,
    name: "W40X244",
    type: "W Shape",
    designation: "W40X244",
    shape: "I",
    country_code: "American",
    ax: "71,7",
    ax_global: "46257,972",
    bf: "17,71",
    bf_global: "449,834",
    ct: 0,
    d: "39,06",
    d_global: "992,124",
    ix: "30,4",
    ix_global: "12653435,34",
    iy: 1170,
    iy_global: 486990768,
    iz: 19200,
    iz_global: 7991643372,
    k: "2,625",
    k_global: "66,675",
    k1: "1,5625",
    k1_global: "39,6875",
    t: "33,75",
    t_global: "857,25",
    tf: "1,26",
    tf_global: "32,004",
    tw: "0,71",
    tw_global: "18,034",
    zx: 1100,
    zx_global: "18025770,4",
    zy: 203,
    zy_global: "3326573,992",
    width: "17,71",
    width_global: "449,834",
    thickness: "1,26",
    thickness_global: "32,004",
    height: "39,06",
    height_global: "992,124",
  },
  {
    profile_section_id: 322,
    name: "W40X268",
    type: "W Shape",
    designation: "W40X268",
    shape: "I",
    country_code: "American",
    ax: "78,8",
    ax_global: "50838,608",
    bf: "17,75",
    bf_global: "450,85",
    ct: 0,
    d: "39,37",
    d_global: "999,998",
    ix: "41,1",
    ix_global: "17107111,59",
    iy: 1320,
    iy_global: "549425481,8",
    iz: 21500,
    iz_global: 8948975650,
    k: "2,8125",
    k_global: "71,4375",
    k1: "1,5625",
    k1_global: "39,6875",
    t: "33,75",
    t_global: "857,25",
    tf: "1,415",
    tf_global: "35,941",
    tw: "0,75",
    tw_global: "19,05",
    zx: 1220,
    zx_global: "19992218,08",
    zy: 229,
    zy_global: "3752637,656",
    width: "17,75",
    width_global: "450,85",
    thickness: "1,415",
    thickness_global: "35,941",
    height: "39,37",
    height_global: "999,998",
  },
  {
    profile_section_id: 323,
    name: "W40X298",
    type: "W Shape",
    designation: "W40X298",
    shape: "I",
    country_code: "American",
    ax: "87,6",
    ax_global: "56516,016",
    bf: "17,83",
    bf_global: "452,882",
    ct: 0,
    d: "39,69",
    d_global: "1008,126",
    ix: "56,3",
    ix_global: "23433829,26",
    iy: 1490,
    iy_global: "620184824,1",
    iz: 24200,
    iz_global: 10072800500,
    k: 3,
    k_global: "76,2",
    k1: "1,625",
    k1_global: "41,275",
    t: "33,75",
    t_global: "857,25",
    tf: "1,575",
    tf_global: "40,005",
    tw: "0,83",
    tw_global: "21,082",
    zx: 1370,
    zx_global: "22450277,68",
    zy: 257,
    zy_global: "4211475,448",
    width: "17,83",
    width_global: "452,882",
    thickness: "1,575",
    thickness_global: "40,005",
    height: "39,69",
    height_global: "1008,126",
  },
  {
    profile_section_id: 324,
    name: "W40X328",
    type: "W Shape",
    designation: "W40X328",
    shape: "I",
    country_code: "American",
    ax: "96,4",
    ax_global: "62193,424",
    bf: "17,91",
    bf_global: "454,914",
    ct: 0,
    d: 40,
    d_global: 1016,
    ix: "74,2",
    ix_global: "30884371,78",
    iy: 1660,
    iy_global: "690944166,5",
    iz: 26800,
    iz_global: 11155002206,
    k: "3,125",
    k_global: "79,375",
    k1: "1,6875",
    k1_global: "42,8625",
    t: "33,75",
    t_global: "857,25",
    tf: "1,73",
    tf_global: "43,942",
    tw: "0,91",
    tw_global: "23,114",
    zx: 1510,
    zx_global: "24744466,64",
    zy: 286,
    zy_global: "4686700,304",
    width: "17,91",
    width_global: "454,914",
    thickness: "1,73",
    thickness_global: "43,942",
    height: 40,
    height_global: 1016,
  },
  {
    profile_section_id: 325,
    name: "W40X436",
    type: "W Shape",
    designation: "W40X436",
    shape: "I",
    country_code: "American",
    ax: 128,
    ax_global: "82580,48",
    bf: "16,24",
    bf_global: "412,496",
    ct: 0,
    d: "41,34",
    d_global: "1050,036",
    ix: 186,
    ix_global: "77419045,16",
    iy: 1720,
    iy_global: 715918052,
    iz: 35400,
    iz_global: 14734592466,
    k: "3,8125",
    k_global: "96,8375",
    k1: "1,9375",
    k1_global: "49,2125",
    t: "33,75",
    t_global: "857,25",
    tf: "2,4",
    tf_global: "60,96",
    tw: "1,34",
    tw_global: "34,036",
    zx: 1980,
    zx_global: "32446386,72",
    zy: 334,
    zy_global: "5473279,376",
    width: "16,24",
    width_global: "412,496",
    thickness: "2,4",
    thickness_global: "60,96",
    height: "41,34",
    height_global: "1050,036",
  },
  {
    profile_section_id: 326,
    name: "W40X480",
    type: "W Shape",
    designation: "W40X480",
    shape: "I",
    country_code: "American",
    ax: 140,
    ax_global: "90322,4",
    bf: "16,36",
    bf_global: "415,544",
    ct: 0,
    d: "41,81",
    d_global: "1061,974",
    ix: 245,
    ix_global: "101976699,3",
    iy: 1940,
    iy_global: "807488965,7",
    iz: 39500,
    iz_global: 16441141311,
    k: 4,
    k_global: "101,6",
    k1: 2,
    k1_global: "50,8",
    t: "33,75",
    t_global: "857,25",
    tf: "2,64",
    tf_global: "67,056",
    tw: "1,46",
    tw_global: "37,084",
    zx: 2180,
    zx_global: "35723799,52",
    zy: 374,
    zy_global: "6128761,936",
    width: "16,36",
    width_global: "415,544",
    thickness: "2,64",
    thickness_global: "67,056",
    height: "41,81",
    height_global: "1061,974",
  },
  {
    profile_section_id: 327,
    name: "W40X531",
    type: "W Shape",
    designation: "W40X531",
    shape: "I",
    country_code: "American",
    ax: 156,
    ax_global: "100644,96",
    bf: "16,51",
    bf_global: "419,354",
    ct: 0,
    d: "42,34",
    d_global: "1075,436",
    ix: 329,
    ix_global: 136940139,
    iy: 2200,
    iy_global: "915709136,3",
    iz: 44300,
    iz_global: 18439052154,
    k: "4,3125",
    k_global: "109,5375",
    k1: 2,
    k1_global: "50,8",
    t: "33,75",
    t_global: "857,25",
    tf: "2,91",
    tf_global: "73,914",
    tw: "1,61",
    tw_global: "40,894",
    zx: 2450,
    zx_global: "40148306,8",
    zy: 422,
    zy_global: "6915341,008",
    width: "16,51",
    width_global: "419,354",
    thickness: "2,91",
    thickness_global: "73,914",
    height: "42,34",
    height_global: "1075,436",
  },
  {
    profile_section_id: 328,
    name: "W44X230",
    type: "W Shape",
    designation: "W44X230",
    shape: "I",
    country_code: "American",
    ax: "67,7",
    ax_global: "43677,332",
    bf: "15,8",
    bf_global: "401,32",
    ct: "5,17",
    d: "42,9",
    d_global: "1089,66",
    ix: "24,9",
    ix_global: "10364162,5",
    iy: 796,
    iy_global: "331320214,8",
    iz: 20800,
    iz_global: 8657613652,
    k: "2,01",
    k_global: "51,054",
    k1: "1,1875",
    k1_global: "30,1625",
    t: "38,88",
    t_global: "987,552",
    tf: "1,22",
    tf_global: "30,988",
    tw: "0,71",
    tw_global: "18,034",
    zx: 1100,
    zx_global: "18025770,4",
    zy: 157,
    zy_global: "2572769,048",
    width: "15,8",
    width_global: "401,32",
    thickness: "1,22",
    thickness_global: "30,988",
    height: "42,9",
    height_global: "1089,66",
  },
  {
    profile_section_id: 400,
    name: "M14X18",
    type: "M Shape",
    designation: "M14X18",
    shape: "I",
    country_code: "American",
    ax: "5,1",
    ax_global: "3290,316",
    bf: 4,
    bf_global: "101,6",
    ct: "2,12",
    d: 14,
    d_global: "355,6",
    ix: "0,1",
    ix_global: "41623,14256",
    iy: "2,6",
    iy_global: "1082201,707",
    iz: 148,
    iz_global: "61602250,99",
    k: "0,625",
    k_global: "15,875",
    t: "12,75",
    t_global: "323,85",
    tf: "0,27",
    tf_global: "6,858",
    tw: "0,215",
    tw_global: "5,461",
    zx: "24,9",
    zx_global: "408037,8936",
    zy: "2,2",
    zy_global: "36051,5408",
    width: 4,
    width_global: "101,6",
    thickness: "0,27",
    thickness_global: "6,858",
    height: 14,
    height_global: "355,6",
  },
  {
    profile_section_id: 401,
    name: "M12.5X12.4",
    type: "M Section",
    designation: "M12.5X12",
    shape: "I",
    country_code: "American",
    ax: "3,63",
    ax_global: "2341,9308",
    bf: "3,75",
    bf_global: "95,25",
    ct: "1,74",
    ct_global: "44,196",
    d: "12,5",
    d_global: "317,5",
    ix: "0,05",
    ix_global: "20811,57128",
    iy: "2,01",
    iy_global: "836625,1655",
    iz: "89,3",
    iz_global: "37169466,31",
    k: "0,56",
    k_global: "14,224",
    k1: "0,38",
    k1_global: "9,652",
    t: "11,37",
    t_global: "288,798",
    tf: "0,23",
    tf_global: "5,842",
    tw: "0,16",
    tw_global: "4,064",
    zx: "16,5",
    zx_global: "270386,556",
    zy: "1,68",
    zy_global: "27530,26752",
    width: "3,75",
    width_global: "95,25",
    thickness: "0,23",
    thickness_global: "5,842",
    height: "12,5",
    height_global: "317,5",
  },
  {
    profile_section_id: 402,
    name: "M12.5X11.6",
    type: "M Section",
    designation: "M12.5X11",
    shape: "I",
    country_code: "American",
    ax: "3,4",
    ax_global: "2193,544",
    bf: "3,5",
    bf_global: "88,9",
    ct: "1,84",
    ct_global: "46,736",
    d: "12,5",
    d_global: "317,5",
    ix: "0,04",
    ix_global: "16649,25702",
    iy: "1,51",
    iy_global: "628509,4527",
    iz: "80,3",
    iz_global: "33423383,48",
    k: "0,56",
    k_global: "14,224",
    k1: "0,38",
    k1_global: "9,652",
    t: "11,37",
    t_global: "288,798",
    tf: "0,21",
    tf_global: "5,334",
    tw: "0,16",
    tw_global: "4,064",
    zx: 15,
    zx_global: "245805,96",
    zy: "1,37",
    zy_global: "22450,27768",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,21",
    thickness_global: "5,334",
    height: "12,5",
    height_global: "317,5",
  },
  {
    profile_section_id: 403,
    name: "M12X11.8",
    type: "M Section",
    designation: "M12X11",
    shape: "I",
    country_code: "American",
    ax: "3,47",
    ax_global: "2238,7052",
    bf: "3,07",
    bf_global: "77,978",
    ct: "1,89",
    ct_global: "48,006",
    d: 12,
    d_global: "304,8",
    ix: "0,05",
    ix_global: "20811,57128",
    iy: "1,09",
    iy_global: "453692,2539",
    iz: "72,2",
    iz_global: "30051908,93",
    k: "0,56",
    k_global: "14,224",
    k1: "0,38",
    k1_global: "9,652",
    t: "10,87",
    t_global: "276,098",
    tf: "0,23",
    tf_global: "5,842",
    tw: "0,18",
    tw_global: "4,572",
    zx: "14,3",
    zx_global: "234335,0152",
    zy: "1,15",
    zy_global: "18845,1236",
    width: "3,07",
    width_global: "77,978",
    thickness: "0,23",
    thickness_global: "5,842",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 404,
    name: "M12X10.8",
    type: "M Section",
    designation: "M12X10.8",
    shape: "I",
    country_code: "American",
    ax: "3,18",
    ax_global: "2051,6088",
    bf: "3,07",
    bf_global: "77,978",
    ct: "1,86",
    ct_global: "47,244",
    d: "11,97",
    d_global: "304,038",
    ix: "0,04",
    ix_global: "16649,25702",
    iy: "1,01",
    iy_global: "420393,7399",
    iz: "66,7",
    iz_global: "27762636,09",
    k: "0,56",
    k_global: "14,224",
    k1: "0,38",
    k1_global: "9,652",
    t: "10,84",
    t_global: "275,336",
    tf: "0,21",
    tf_global: "5,334",
    tw: "0,16",
    tw_global: "4,064",
    zx: "13,2",
    zx_global: "216309,2448",
    zy: "1,07",
    zy_global: "17534,15848",
    width: "3,07",
    width_global: "77,978",
    thickness: "0,21",
    thickness_global: "5,334",
    height: "11,97",
    height_global: "304,038",
  },
  {
    profile_section_id: 405,
    name: "M12X10",
    type: "M Section",
    designation: "M12X10",
    shape: "I",
    country_code: "American",
    ax: "2,95",
    ax_global: "1903,222",
    bf: "3,25",
    bf_global: "82,55",
    ct: "1,86",
    ct_global: "47,244",
    d: "11,97",
    d_global: "304,038",
    ix: "0,03",
    ix_global: "12486,94277",
    iy: "1,03",
    iy_global: "428718,3684",
    iz: "61,7",
    iz_global: "25681478,96",
    k: "0,5",
    k_global: "12,7",
    k1: "0,38",
    k1_global: "9,652",
    t: "10,97",
    t_global: "278,638",
    tf: "0,18",
    tf_global: "4,572",
    tw: "0,15",
    tw_global: "3,81",
    zx: "12,2",
    zx_global: "199922,1808",
    zy: "1,02",
    zy_global: "16714,80528",
    width: "3,25",
    width_global: "82,55",
    thickness: "0,18",
    thickness_global: "4,572",
    height: "11,97",
    height_global: "304,038",
  },
  {
    profile_section_id: 406,
    name: "M10X9",
    type: "M Section",
    designation: "M10X9",
    shape: "I",
    country_code: "American",
    ax: "2,65",
    ax_global: "1709,674",
    bf: "2,69",
    bf_global: "68,326",
    ct: "1,54",
    ct_global: "39,116",
    d: 10,
    d_global: 254,
    ix: "0,03",
    ix_global: "12486,94277",
    iy: "0,67",
    iy_global: "278875,0552",
    iz: 39,
    iz_global: "16233025,6",
    k: "0,56",
    k_global: "14,224",
    k1: "0,38",
    k1_global: "9,652",
    t: "8,87",
    t_global: "225,298",
    tf: "0,21",
    tf_global: "5,334",
    tw: "0,16",
    tw_global: "4,064",
    zx: "9,22",
    zx_global: "151088,7301",
    zy: "0,81",
    zy_global: "13273,52184",
    width: "2,69",
    width_global: "68,326",
    thickness: "0,21",
    thickness_global: "5,334",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 407,
    name: "M10X8",
    type: "M Section",
    designation: "M10X8",
    shape: "I",
    country_code: "American",
    ax: "2,37",
    ax_global: "1529,0292",
    bf: "2,69",
    bf_global: "68,326",
    ct: "1,52",
    ct_global: "38,608",
    d: "9,95",
    d_global: "252,73",
    ix: "0,02",
    ix_global: "8324,628512",
    iy: "0,59",
    iy_global: "245576,5411",
    iz: "34,6",
    iz_global: "14401607,33",
    k: "0,56",
    k_global: "14,224",
    k1: "0,38",
    k1_global: "9,652",
    t: "8,82",
    t_global: "224,028",
    tf: "0,18",
    tf_global: "4,572",
    tw: "0,14",
    tw_global: "3,556",
    zx: "8,2",
    zx_global: "134373,9248",
    zy: "0,71",
    zy_global: "11634,81544",
    width: "2,69",
    width_global: "68,326",
    thickness: "0,18",
    thickness_global: "4,572",
    height: "9,95",
    height_global: "252,73",
  },
  {
    profile_section_id: 408,
    name: "M10X7.5",
    type: "M Section",
    designation: "M10X7",
    shape: "I",
    country_code: "American",
    ax: "2,22",
    ax_global: "1432,2552",
    bf: "2,69",
    bf_global: "68,326",
    ct: "1,51",
    ct_global: "38,354",
    d: "9,99",
    d_global: "253,746",
    ix: "0,02",
    ix_global: "8324,628512",
    iy: "0,56",
    iy_global: "233089,5983",
    iz: 33,
    iz_global: "13735637,04",
    k: "0,44",
    k_global: "11,176",
    k1: "0,31",
    k1_global: "7,874",
    t: "9,11",
    t_global: "231,394",
    tf: "0,17",
    tf_global: "4,318",
    tw: "0,13",
    tw_global: "3,302",
    zx: "7,77",
    zx_global: "127327,4873",
    zy: "0,67",
    zy_global: "10979,33288",
    width: "2,69",
    width_global: "68,326",
    thickness: "0,17",
    thickness_global: "4,318",
    height: "9,99",
    height_global: "253,746",
  },
  {
    profile_section_id: 409,
    name: "M8X6.5",
    type: "M Section",
    designation: "M8X6",
    shape: "I",
    country_code: "American",
    ax: "1,92",
    ax_global: "1238,7072",
    bf: "2,28",
    bf_global: "57,912",
    ct: "1,18",
    ct_global: "29,972",
    d: 8,
    d_global: "203,2",
    ix: "0,02",
    ix_global: "8324,628512",
    iy: "0,38",
    iy_global: "158167,9417",
    iz: "18,5",
    iz_global: "7700281,374",
    k: "0,56",
    k_global: "14,224",
    k1: "0,38",
    k1_global: "9,652",
    t: "6,87",
    t_global: "174,498",
    tf: "0,19",
    tf_global: "4,826",
    tw: "0,14",
    tw_global: "3,556",
    zx: "5,43",
    zx_global: "88981,75752",
    zy: "0,53",
    zy_global: "8685,14392",
    width: "2,28",
    width_global: "57,912",
    thickness: "0,19",
    thickness_global: "4,826",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 410,
    name: "M8X6.2",
    type: "M Section",
    designation: "M8X6.2",
    shape: "I",
    country_code: "American",
    ax: "1,82",
    ax_global: "1174,1912",
    bf: "2,28",
    bf_global: "57,912",
    ct: "1,18",
    ct_global: "29,972",
    d: 8,
    d_global: "203,2",
    ix: "0,02",
    ix_global: "8324,628512",
    iy: "0,35",
    iy_global: "145680,999",
    iz: "17,6",
    iz_global: "7325673,091",
    k: "0,44",
    k_global: "11,176",
    k1: "0,25",
    k1_global: "6,35",
    t: "7,12",
    t_global: "180,848",
    tf: "0,18",
    tf_global: "4,572",
    tw: "0,13",
    tw_global: "3,302",
    zx: "5,15",
    zx_global: "84393,3796",
    zy: "0,5",
    zy_global: "8193,532",
    width: "2,28",
    width_global: "57,912",
    thickness: "0,18",
    thickness_global: "4,572",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 411,
    name: "M6X20",
    type: "M Shape",
    designation: "M6X20",
    shape: "I",
    country_code: "American",
    ax: "5,89",
    ax_global: "3799,9924",
    bf: "5,938",
    bf_global: "150,8252",
    ct: "0,53",
    d: 6,
    d_global: "152,4",
    ix: "0,29",
    ix_global: "120707,1134",
    iy: "11,6",
    iy_global: "4828284,537",
    iz: 39,
    iz_global: "16233025,6",
    tf: "0,379",
    tf_global: "9,6266",
    tw: "0,25",
    tw_global: "6,35",
    zx: "14,5",
    zx_global: "237612,428",
    zy: "6,3",
    zy_global: "103238,5032",
    width: "5,938",
    width_global: "150,8252",
    thickness: "0,379",
    thickness_global: "9,6266",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 412,
    name: "M6X4",
    type: "M Shape",
    designation: "M6X4",
    shape: "I",
    country_code: "American",
    ax: "1,29",
    ax_global: "832,2564",
    bf: "1,844",
    bf_global: "46,8376",
    ct: "0,84",
    d: 6,
    d_global: "152,4",
    ix: "0,01",
    ix_global: "4162,314256",
    iy: "0,2",
    iy_global: "83246,28512",
    iz: "7,2",
    iz_global: "2996866,264",
    k: "0,375",
    k_global: "9,525",
    k1: "0,25",
    k1_global: "6,35",
    t: "5,25",
    t_global: "133,35",
    tf: "0,171",
    tf_global: "4,3434",
    tw: "0,114",
    tw_global: "2,8956",
    zx: "2,8",
    zx_global: "45883,7792",
    zy: "0,3",
    zy_global: "4916,1192",
    width: "1,844",
    width_global: "46,8376",
    thickness: "0,171",
    thickness_global: "4,3434",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 413,
    name: "M6X3.7",
    type: "M Section",
    designation: "M6X3",
    shape: "I",
    country_code: "American",
    ax: "1,09",
    ax_global: "703,2244",
    bf: 2,
    bf_global: "50,8",
    ct: "0,83",
    ct_global: "21,082",
    d: "5,92",
    d_global: "150,368",
    ix: "0,01",
    ix_global: "4162,314256",
    iy: "0,17",
    iy_global: "70759,34235",
    iz: "5,96",
    iz_global: "2480739,297",
    k: "0,31",
    k_global: "7,874",
    k1: "0,25",
    k1_global: "6,35",
    t: "5,29",
    t_global: "134,366",
    tf: "0,13",
    tf_global: "3,302",
    tw: "0,1",
    tw_global: "2,54",
    zx: "2,33",
    zx_global: "38181,85912",
    zy: "0,27",
    zy_global: "4424,50728",
    width: 2,
    width_global: "50,8",
    thickness: "0,13",
    thickness_global: "3,302",
    height: "5,92",
    height_global: "150,368",
  },
  {
    profile_section_id: 414,
    name: "M5X18",
    type: "M Shape",
    designation: "M5X18",
    shape: "I",
    country_code: "American",
    ax: "5,55",
    ax_global: "3580,638",
    bf: "5,003",
    bf_global: "127,0762",
    ct: "0,51",
    d: 5,
    d_global: 127,
    ix: "0,34",
    ix_global: "141518,6847",
    iy: "7,9",
    iy_global: "3288228,262",
    iz: "24,1",
    iz_global: "10031177,36",
    k: "0,8125",
    k_global: "20,6375",
    k1: "0,5",
    k1_global: "12,7",
    t: "3,375",
    t_global: "85,725",
    tf: "0,416",
    tf_global: "10,5664",
    tw: "0,316",
    tw_global: "8,0264",
    zx: 11,
    zx_global: "180257,704",
    zy: 5,
    zy_global: "81935,32",
    width: "5,003",
    width_global: "127,0762",
    thickness: "0,416",
    thickness_global: "10,5664",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 415,
    name: "M4X13",
    type: "M Shape",
    designation: "M4X13",
    shape: "I",
    country_code: "American",
    ax: "3,81",
    ax_global: "2458,0596",
    bf: "3,94",
    bf_global: "100,076",
    ct: "0,41",
    d: 4,
    d_global: "101,6",
    ix: "0,19",
    ix_global: "79083,97086",
    iy: "3,4",
    iy_global: "1415186,847",
    iz: "10,5",
    iz_global: "4370429,969",
    tf: "0,371",
    tf_global: "9,4234",
    tw: "0,254",
    tw_global: "6,4516",
    zx: "6,1",
    zx_global: "99961,0904",
    zy: "2,7",
    zy_global: "44245,0728",
    width: "3,94",
    width_global: "100,076",
    thickness: "0,371",
    thickness_global: "9,4234",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 416,
    name: "M4X6",
    type: "M Section",
    designation: "M4X6",
    shape: "I",
    country_code: "American",
    ax: "1,75",
    ax_global: "1129,03",
    bf: "3,8",
    bf_global: "96,52",
    ct: "0,34",
    ct_global: "8,636",
    d: "3,8",
    d_global: "96,52",
    ix: "0,02",
    ix_global: "8324,628512",
    iy: "1,47",
    iy_global: "611860,1956",
    iz: "4,72",
    iz_global: "1964612,329",
    k: "0,5",
    k_global: "12,7",
    k1: "0,38",
    k1_global: "9,652",
    t: "2,8",
    t_global: "71,12",
    tf: "0,16",
    tf_global: "4,064",
    tw: "0,13",
    tw_global: "3,302",
    zx: "2,74",
    zx_global: "44900,55536",
    zy: "1,18",
    zy_global: "19336,73552",
    width: "3,8",
    width_global: "96,52",
    thickness: "0,16",
    thickness_global: "4,064",
    height: "3,8",
    height_global: "96,52",
  },
  {
    profile_section_id: 417,
    name: "M4X4.08",
    type: "M Section",
    designation: "M4X4",
    shape: "I",
    country_code: "American",
    ax: "1,27",
    ax_global: "819,3532",
    bf: "2,25",
    bf_global: "57,15",
    ct: "0,41",
    ct_global: "10,414",
    d: 4,
    d_global: "101,6",
    ix: "0,01",
    ix_global: "4162,314256",
    iy: "0,33",
    iy_global: "137356,3704",
    iz: "3,53",
    iz_global: "1469296,932",
    k: "0,56",
    k_global: "14,224",
    k1: "0,38",
    k1_global: "9,652",
    t: "2,87",
    t_global: "72,898",
    tf: "0,17",
    tf_global: "4,318",
    tw: "0,12",
    tw_global: "3,048",
    zx: 2,
    zx_global: "32774,128",
    zy: "0,45",
    zy_global: "7374,1788",
    width: "2,25",
    width_global: "57,15",
    thickness: "0,17",
    thickness_global: "4,318",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 418,
    name: "M4X3.45",
    type: "M Section",
    designation: "M4X3.4",
    shape: "I",
    country_code: "American",
    ax: "1,01",
    ax_global: "651,6116",
    bf: "2,25",
    bf_global: "57,15",
    ct: "0,4",
    ct_global: "10,16",
    d: 4,
    d_global: "101,6",
    ix: "0,01",
    ix_global: "4162,314256",
    iy: "0,25",
    iy_global: "104057,8564",
    iz: "2,86",
    iz_global: "1190421,877",
    k: "0,5",
    k_global: "12,7",
    k1: "0,38",
    k1_global: "9,652",
    t: 3,
    t_global: "76,2",
    tf: "0,13",
    tf_global: "3,302",
    tw: "0,09",
    tw_global: "2,286",
    zx: "1,6",
    zx_global: "26219,3024",
    zy: "0,35",
    zy_global: "5735,4724",
    width: "2,25",
    width_global: "57,15",
    thickness: "0,13",
    thickness_global: "3,302",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 419,
    name: "M4X3.2",
    type: "M Section",
    designation: "M4X3.2",
    shape: "I",
    country_code: "American",
    ax: "1,01",
    ax_global: "651,6116",
    bf: "2,25",
    bf_global: "57,15",
    ct: "0,4",
    ct_global: "10,16",
    d: 4,
    d_global: "101,6",
    ix: "0,01",
    ix_global: "4162,314256",
    iy: "0,25",
    iy_global: "104057,8564",
    iz: "2,86",
    iz_global: "1190421,877",
    k: "0,5",
    k_global: "12,7",
    k1: "0,38",
    k1_global: "9,652",
    t: 3,
    t_global: "76,2",
    tf: "0,13",
    tf_global: "3,302",
    tw: "0,09",
    tw_global: "2,286",
    zx: "1,6",
    zx_global: "26219,3024",
    zy: "0,35",
    zy_global: "5735,4724",
    width: "2,25",
    width_global: "57,15",
    thickness: "0,13",
    thickness_global: "3,302",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 420,
    name: "M3X2.9",
    type: "M Section",
    designation: "M3X2",
    shape: "I",
    country_code: "American",
    ax: "0,91",
    ax_global: "587,0956",
    bf: "2,25",
    bf_global: "57,15",
    ct: "0,26",
    ct_global: "6,604",
    d: 3,
    d_global: "76,2",
    ix: "0,01",
    ix_global: "4162,314256",
    iy: "0,25",
    iy_global: "104057,8564",
    iz: "1,5",
    iz_global: "624347,1384",
    k: "0,5",
    k_global: "12,7",
    k1: "0,38",
    k1_global: "9,652",
    t: 2,
    t_global: "50,8",
    tf: "0,13",
    tf_global: "3,302",
    tw: "0,09",
    tw_global: "2,286",
    zx: "1,12",
    zx_global: "18353,51168",
    zy: "0,34",
    zy_global: "5571,60176",
    width: "2,25",
    width_global: "57,15",
    thickness: "0,13",
    thickness_global: "3,302",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 500,
    name: "HP18X204",
    type: "HP Shape",
    designation: "HP18X204",
    shape: "I",
    country_code: "American",
    ax: "60,2",
    ax_global: "38838,632",
    b: "18,3",
    b_global: "464,82",
    bf: "18,1",
    bf_global: "459,74",
    ct: "1,93",
    ct_global: "49,022",
    ix: "29,5",
    ix_global: "12278827,06",
    iy: 1120,
    iy_global: "466179196,7",
    iz: 3480,
    iz_global: 1448485361,
    k: "2,31",
    k_global: "58,674",
    k1: "1,75",
    k1_global: "44,45",
    t: "13,68",
    t_global: "347,472",
    tf: "1,13",
    tf_global: "28,702",
    tw: "1,13",
    tw_global: "28,702",
    zx: 433,
    zx_global: "7095598,712",
    zy: 191,
    zy_global: "3129929,224",
    width: "18,1",
    width_global: "459,74",
    thickness: "1,13",
    thickness_global: "28,702",
    height: "18,3",
    height_global: "464,82",
  },
  {
    profile_section_id: 501,
    name: "HP18X181",
    type: "HP Shape",
    designation: "HP18X181",
    shape: "I",
    country_code: "American",
    ax: "53,2",
    ax_global: "34322,512",
    b: 18,
    b_global: "457,2",
    bf: 18,
    bf_global: "457,2",
    ct: "1,84",
    ct_global: "46,736",
    ix: "20,7",
    ix_global: "8615990,51",
    iy: 974,
    iy_global: "405409408,5",
    iz: 3020,
    iz_global: 1257018905,
    k: "2,18",
    k_global: "55,372",
    k1: "1,68",
    k1_global: "42,672",
    t: "13,64",
    t_global: "346,456",
    tf: 1,
    tf_global: "25,4",
    tw: 1,
    tw_global: "25,4",
    zx: 379,
    zx_global: "6210697,256",
    zy: 167,
    zy_global: "2736639,688",
    width: 18,
    width_global: "457,2",
    thickness: 1,
    thickness_global: "25,4",
    height: 18,
    height_global: "457,2",
  },
  {
    profile_section_id: 502,
    name: "HP18X157",
    type: "HP Shape",
    designation: "HP18X157",
    shape: "I",
    country_code: "American",
    ax: "46,2",
    ax_global: "29806,392",
    b: "17,7",
    b_global: "449,58",
    bf: "17,9",
    bf_global: "454,66",
    ct: "1,75",
    ct_global: "44,45",
    ix: "13,9",
    ix_global: "5785616,816",
    iy: 833,
    iy_global: "346720777,5",
    iz: 2570,
    iz_global: 1069714764,
    k: "2,05",
    k_global: "52,07",
    k1: "1,62",
    k1_global: "41,148",
    t: "13,6",
    t_global: "345,44",
    tf: "0,87",
    tf_global: "22,098",
    tw: "0,87",
    tw_global: "22,098",
    zx: 327,
    zx_global: "5358569,928",
    zy: 143,
    zy_global: "2343350,152",
    width: "17,9",
    width_global: "454,66",
    thickness: "0,87",
    thickness_global: "22,098",
    height: "17,7",
    height_global: "449,58",
  },
  {
    profile_section_id: 503,
    name: "HP18X135",
    type: "HP Shape",
    designation: "HP18X135",
    shape: "I",
    country_code: "American",
    ax: "39,9",
    ax_global: "25741,884",
    b: "17,5",
    b_global: "444,5",
    bf: "17,8",
    bf_global: "452,12",
    ct: "1,68",
    ct_global: "42,672",
    ix: "9,12",
    ix_global: "3796030,601",
    iy: 706,
    iy_global: "293859386,5",
    iz: 2200,
    iz_global: "915709136,3",
    k: "1,93",
    k_global: "49,022",
    k1: "1,56",
    k1_global: "39,624",
    t: "13,64",
    t_global: "346,456",
    tf: "0,75",
    tf_global: "19,05",
    tw: "0,75",
    tw_global: "19,05",
    zx: 281,
    zx_global: "4604764,984",
    zy: 122,
    zy_global: "1999221,808",
    width: "17,8",
    width_global: "452,12",
    thickness: "0,75",
    thickness_global: "19,05",
    height: "17,5",
    height_global: "444,5",
  },
  {
    profile_section_id: 504,
    name: "HP16X183",
    type: "HP Shape",
    designation: "HP16X183",
    shape: "I",
    country_code: "American",
    ax: "54,1",
    ax_global: "34903,156",
    b: "16,5",
    b_global: "419,1",
    bf: "16,3",
    bf_global: "414,02",
    ct: "1,78",
    ct_global: "45,212",
    ix: "26,9",
    ix_global: "11196625,35",
    iy: 818,
    iy_global: "340477306,1",
    iz: 2510,
    iz_global: 1044740878,
    k: "2,31",
    k_global: "58,674",
    k1: "1,75",
    k1_global: "44,45",
    t: "11,88",
    t_global: "301,752",
    tf: "1,13",
    tf_global: "28,702",
    tw: "1,13",
    tw_global: "28,702",
    zx: 349,
    zx_global: "5719085,336",
    zy: 156,
    zy_global: "2556381,984",
    width: "16,3",
    width_global: "414,02",
    thickness: "1,13",
    thickness_global: "28,702",
    height: "16,5",
    height_global: "419,1",
  },
  {
    profile_section_id: 505,
    name: "HP16X162",
    type: "HP Shape",
    designation: "HP16X162",
    shape: "I",
    country_code: "American",
    ax: "47,7",
    ax_global: "30774,132",
    b: "16,3",
    b_global: "414,02",
    bf: "16,1",
    bf_global: "408,94",
    ct: "1,71",
    ct_global: "43,434",
    ix: "18,8",
    ix_global: "7825150,801",
    iy: 697,
    iy_global: "290113303,6",
    iz: 2190,
    iz_global: "911546822,1",
    k: "2,18",
    k_global: "55,372",
    k1: "1,68",
    k1_global: "42,672",
    t: "11,94",
    t_global: "303,276",
    tf: 1,
    tf_global: "25,4",
    tw: 1,
    tw_global: "25,4",
    zx: 306,
    zx_global: "5014441,584",
    zy: 134,
    zy_global: "2195866,576",
    width: "16,1",
    width_global: "408,94",
    thickness: 1,
    thickness_global: "25,4",
    height: "16,3",
    height_global: "414,02",
  },
  {
    profile_section_id: 506,
    name: "HP16X141",
    type: "HP Shape",
    designation: "HP16X141",
    shape: "I",
    country_code: "American",
    ax: "41,7",
    ax_global: "26903,172",
    b: 16,
    b_global: "406,4",
    bf: 16,
    bf_global: "406,4",
    ct: "1,62",
    ct_global: "41,148",
    ix: "12,9",
    ix_global: "5369385,39",
    iy: 599,
    iy_global: "249322623,9",
    iz: 1870,
    iz_global: "778352765,9",
    k: "2,06",
    k_global: "52,324",
    k1: "1,62",
    k1_global: "41,148",
    t: "11,88",
    t_global: "301,752",
    tf: "0,88",
    tf_global: "22,352",
    tw: "0,88",
    tw_global: "22,352",
    zx: 264,
    zx_global: "4326184,896",
    zy: 116,
    zy_global: "1900899,424",
    width: 16,
    width_global: "406,4",
    thickness: "0,88",
    thickness_global: "22,352",
    height: 16,
    height_global: "406,4",
  },
  {
    profile_section_id: 507,
    name: "HP16X121",
    type: "HP Shape",
    designation: "HP16X121",
    shape: "I",
    country_code: "American",
    ax: "35,8",
    ax_global: "23096,728",
    b: "15,8",
    b_global: "401,32",
    bf: "15,9",
    bf_global: "403,86",
    ct: "1,55",
    ct_global: "39,37",
    ix: "8,35",
    ix_global: "3475532,404",
    iy: 504,
    iy_global: "209780638,5",
    iz: 1590,
    iz_global: "661807966,7",
    k: "1,93",
    k_global: "49,022",
    k1: "1,56",
    k1_global: "39,624",
    t: "11,94",
    t_global: "303,276",
    tf: "0,75",
    tf_global: "19,05",
    tw: "0,75",
    tw_global: "19,05",
    zx: 226,
    zx_global: "3703476,464",
    zy: "97,6",
    zy_global: "1599377,446",
    width: "15,9",
    width_global: "403,86",
    thickness: "0,75",
    thickness_global: "19,05",
    height: "15,8",
    height_global: "401,32",
  },
  {
    profile_section_id: 508,
    name: "HP16X101",
    type: "HP Shape",
    designation: "HP16X101",
    shape: "I",
    country_code: "American",
    ax: "29,9",
    ax_global: "19290,284",
    b: "15,5",
    b_global: "393,7",
    bf: "15,8",
    bf_global: "401,32",
    ct: "1,45",
    ct_global: "36,83",
    ix: "5,07",
    ix_global: "2110293,328",
    iy: 412,
    iy_global: "171487347,3",
    iz: 1300,
    iz_global: "541100853,3",
    k: "1,81",
    k_global: "45,974",
    k1: "1,49",
    k1_global: "37,846",
    t: "11,88",
    t_global: "301,752",
    tf: "0,63",
    tf_global: "16,002",
    tw: "0,63",
    tw_global: "16,002",
    zx: 187,
    zx_global: "3064380,968",
    zy: "80,1",
    zy_global: "1312603,826",
    width: "15,8",
    width_global: "401,32",
    thickness: "0,63",
    thickness_global: "16,002",
    height: "15,5",
    height_global: "393,7",
  },
  {
    profile_section_id: 509,
    name: "HP16X88",
    type: "HP Shape",
    designation: "HP16X88",
    shape: "I",
    country_code: "American",
    ax: "25,8",
    ax_global: "16645,128",
    b: "15,3",
    b_global: "388,62",
    bf: "15,7",
    bf_global: "398,78",
    ct: "1,4",
    ct_global: "35,56",
    ix: "3,45",
    ix_global: "1435998,418",
    iy: 349,
    iy_global: "145264767,5",
    iz: 1110,
    iz_global: "462016882,4",
    k: "1,72",
    k_global: "43,688",
    k1: "1,45",
    k1_global: "36,83",
    t: "11,86",
    t_global: "301,244",
    tf: "0,54",
    tf_global: "13,716",
    tw: "0,54",
    tw_global: "13,716",
    zx: 161,
    zx_global: "2638317,304",
    zy: "68,2",
    zy_global: "1117597,765",
    width: "15,7",
    width_global: "398,78",
    thickness: "0,54",
    thickness_global: "13,716",
    height: "15,3",
    height_global: "388,62",
  },
  {
    profile_section_id: 510,
    name: "HP14X117",
    type: "HP Shape",
    designation: "HP14X117",
    shape: "I",
    country_code: "American",
    ax: "34,4",
    ax_global: "22193,504",
    b: "14,2",
    b_global: "360,68",
    bf: "14,9",
    bf_global: "378,46",
    ct: "1,45",
    ct_global: "36,83",
    ix: "8,02",
    ix_global: "3338176,033",
    iy: 443,
    iy_global: "184390521,5",
    iz: 1220,
    iz_global: "507802339,2",
    k: "1,5",
    k_global: "38,1",
    k1: "1,06",
    k1_global: "26,924",
    t: "11,2",
    t_global: "284,48",
    tf: "0,81",
    tf_global: "20,574",
    tw: "0,81",
    tw_global: "20,574",
    zx: 194,
    zx_global: "3179090,416",
    zy: "91,4",
    zy_global: "1497777,65",
    width: "14,9",
    width_global: "378,46",
    thickness: "0,81",
    thickness_global: "20,574",
    height: "14,2",
    height_global: "360,68",
  },
  {
    profile_section_id: 511,
    name: "HP14X102",
    type: "HP Shape",
    designation: "HP14X102",
    shape: "I",
    country_code: "American",
    ax: "30,1",
    ax_global: "19419,316",
    b: 14,
    b_global: "355,6",
    bf: "14,8",
    bf_global: "375,92",
    ct: "1,38",
    ct_global: "35,052",
    ix: "5,39",
    ix_global: "2243487,384",
    iy: 380,
    iy_global: "158167941,7",
    iz: 1050,
    iz_global: "437042996,9",
    k: "1,38",
    k_global: "35,052",
    k1: 1,
    k1_global: "25,4",
    t: "11,24",
    t_global: "285,496",
    tf: "0,7",
    tf_global: "17,78",
    tw: "0,7",
    tw_global: "17,78",
    zx: 169,
    zx_global: "2769413,816",
    zy: "78,8",
    zy_global: "1291300,643",
    width: "14,8",
    width_global: "375,92",
    thickness: "0,7",
    thickness_global: "17,78",
    height: 14,
    height_global: "355,6",
  },
  {
    profile_section_id: 512,
    name: "HP14X89",
    type: "HP Shape",
    designation: "HP14X89",
    shape: "I",
    country_code: "American",
    ax: "26,1",
    ax_global: "16838,676",
    b: "13,8",
    b_global: "350,52",
    bf: "14,7",
    bf_global: "373,38",
    ct: "1,33",
    ct_global: "33,782",
    ix: "3,59",
    ix_global: "1494270,818",
    iy: 326,
    iy_global: "135691444,7",
    iz: 904,
    iz_global: "376273208,7",
    k: "1,31",
    k_global: "33,274",
    k1: "0,94",
    k1_global: "23,876",
    t: "11,18",
    t_global: "283,972",
    tf: "0,61",
    tf_global: "15,494",
    tw: "0,61",
    tw_global: "15,494",
    zx: 146,
    zx_global: "2392511,344",
    zy: "67,7",
    zy_global: "1109404,233",
    width: "14,7",
    width_global: "373,38",
    thickness: "0,61",
    thickness_global: "15,494",
    height: "13,8",
    height_global: "350,52",
  },
  {
    profile_section_id: 513,
    name: "HP14X73",
    type: "HP Shape",
    designation: "HP14X73",
    shape: "I",
    country_code: "American",
    ax: "21,4",
    ax_global: "13806,424",
    b: "13,6",
    b_global: "345,44",
    bf: "14,6",
    bf_global: "370,84",
    ct: "1,26",
    ct_global: "32,004",
    ix: "2,01",
    ix_global: "836625,1655",
    iy: 261,
    iy_global: "108636402,1",
    iz: 729,
    iz_global: "303432709,3",
    k: "1,19",
    k_global: "30,226",
    k1: "0,88",
    k1_global: "22,352",
    t: "11,22",
    t_global: "284,988",
    tf: "0,51",
    tf_global: "12,954",
    tw: "0,51",
    tw_global: "12,954",
    zx: 118,
    zx_global: "1933673,552",
    zy: "54,6",
    zy_global: "894733,6944",
    width: "14,6",
    width_global: "370,84",
    thickness: "0,51",
    thickness_global: "12,954",
    height: "13,6",
    height_global: "345,44",
  },
  {
    profile_section_id: 514,
    name: "HP13X60",
    type: "HP Shape",
    designation: "HP13X60",
    shape: "I",
    country_code: "American",
    ax: "17,5",
    ax_global: "11290,3",
    bf: "12,9",
    bf_global: "327,66",
    ct: 0,
    d: "12,54",
    d_global: "318,516",
    ix: "1,21",
    ix_global: "503640,025",
    iy: 165,
    iy_global: "68678185,22",
    iz: 503,
    iz_global: "209364407,1",
    k: "1,125",
    k_global: "28,575",
    k1: "0,875",
    k1_global: "22,225",
    t: "10,25",
    t_global: "260,35",
    tf: "0,46",
    tf_global: "11,684",
    tw: "0,46",
    tw_global: "11,684",
    zx: 89,
    zx_global: "1458448,696",
    zy: 39,
    zy_global: "639095,496",
    width: "12,9",
    width_global: "327,66",
    thickness: "0,46",
    thickness_global: "11,684",
    height: "12,54",
    height_global: "318,516",
  },
  {
    profile_section_id: 515,
    name: "HP13X73",
    type: "HP Shape",
    designation: "HP13X73",
    shape: "I",
    country_code: "American",
    ax: "21,6",
    ax_global: "13935,456",
    bf: "13,005",
    bf_global: "330,327",
    ct: 0,
    d: "12,75",
    d_global: "323,85",
    ix: "2,26",
    ix_global: "940683,0219",
    iy: 207,
    iy_global: "86159905,1",
    iz: 630,
    iz_global: "262225798,1",
    k: "1,25",
    k_global: "31,75",
    k1: "0,9375",
    k1_global: "23,8125",
    t: "10,25",
    t_global: "260,35",
    tf: "0,565",
    tf_global: "14,351",
    tw: "0,565",
    tw_global: "14,351",
    zx: 110,
    zx_global: "1802577,04",
    zy: "48,8",
    zy_global: "799688,7232",
    width: "13,005",
    width_global: "330,327",
    thickness: "0,565",
    thickness_global: "14,351",
    height: "12,75",
    height_global: "323,85",
  },
  {
    profile_section_id: 516,
    name: "HP13X87",
    type: "HP Shape",
    designation: "HP13X87",
    shape: "I",
    country_code: "American",
    ax: "25,5",
    ax_global: "16451,58",
    bf: "13,105",
    bf_global: "332,867",
    ct: 0,
    d: "12,95",
    d_global: "328,93",
    ix: "3,71",
    ix_global: "1544218,589",
    iy: 250,
    iy_global: "104057856,4",
    iz: 755,
    iz_global: "314254726,3",
    k: "1,375",
    k_global: "34,925",
    k1: "0,9375",
    k1_global: "23,8125",
    t: "10,25",
    t_global: "260,35",
    tf: "0,665",
    tf_global: "16,891",
    tw: "0,665",
    tw_global: "16,891",
    zx: 131,
    zx_global: "2146705,384",
    zy: "58,5",
    zy_global: "958643,244",
    width: "13,105",
    width_global: "332,867",
    thickness: "0,665",
    thickness_global: "16,891",
    height: "12,95",
    height_global: "328,93",
  },
  {
    profile_section_id: 517,
    name: "HP13X100",
    type: "HP Shape",
    designation: "HP13X100",
    shape: "I",
    country_code: "American",
    ax: "29,4",
    ax_global: "18967,704",
    bf: "13,205",
    bf_global: "335,407",
    ct: 0,
    d: "13,15",
    d_global: "334,01",
    ix: "5,68",
    ix_global: "2364194,497",
    iy: 294,
    iy_global: "122372039,1",
    iz: 886,
    iz_global: "368781043,1",
    k: "1,4375",
    k_global: "36,5125",
    k1: 1,
    k1_global: "25,4",
    t: "10,25",
    t_global: "260,35",
    tf: "0,765",
    tf_global: "19,431",
    tw: "0,765",
    tw_global: "19,431",
    zx: 153,
    zx_global: "2507220,792",
    zy: "68,6",
    zy_global: "1124152,59",
    width: "13,205",
    width_global: "335,407",
    thickness: "0,765",
    thickness_global: "19,431",
    height: "13,15",
    height_global: "334,01",
  },
  {
    profile_section_id: 518,
    name: "HP12X84",
    type: "HP Shape",
    designation: "HP12X84",
    shape: "I",
    country_code: "American",
    ax: "24,6",
    ax_global: "15870,936",
    b: "12,3",
    b_global: "312,42",
    bf: "12,3",
    bf_global: "312,42",
    ct: "1,27",
    ct_global: "32,258",
    ix: "4,24",
    ix_global: "1764821,245",
    iy: 213,
    iy_global: "88657293,65",
    iz: 650,
    iz_global: "270550426,6",
    k: "1,38",
    k_global: "35,052",
    k1: 1,
    k1_global: "25,4",
    t: "9,54",
    t_global: "242,316",
    tf: "0,69",
    tf_global: "17,526",
    tw: "0,69",
    tw_global: "17,526",
    zx: 120,
    zx_global: "1966447,68",
    zy: "53,2",
    zy_global: "871791,8048",
    width: "12,3",
    width_global: "312,42",
    thickness: "0,69",
    thickness_global: "17,526",
    height: "12,3",
    height_global: "312,42",
  },
  {
    profile_section_id: 519,
    name: "HP12X74",
    type: "HP Shape",
    designation: "HP12X74",
    shape: "I",
    country_code: "American",
    ax: "21,8",
    ax_global: "14064,488",
    b: "12,1",
    b_global: "307,34",
    bf: "12,2",
    bf_global: "309,88",
    ct: "1,21",
    ct_global: "30,734",
    ix: "2,98",
    ix_global: "1240369,648",
    iy: 186,
    iy_global: "77419045,16",
    iz: 569,
    iz_global: "236835681,2",
    k: "1,31",
    k_global: "33,274",
    k1: "0,94",
    k1_global: "23,876",
    t: "9,48",
    t_global: "240,792",
    tf: "0,61",
    tf_global: "15,494",
    tw: "0,6",
    tw_global: "15,24",
    zx: 105,
    zx_global: "1720641,72",
    zy: "46,6",
    zy_global: "763637,1824",
    width: "12,2",
    width_global: "309,88",
    thickness: "0,61",
    thickness_global: "15,494",
    height: "12,1",
    height_global: "307,34",
  },
  {
    profile_section_id: 520,
    name: "HP12X63",
    type: "HP Shape",
    designation: "HP12X63",
    shape: "I",
    country_code: "American",
    ax: "18,4",
    ax_global: "11870,944",
    b: "11,9",
    b_global: "302,26",
    bf: "12,1",
    bf_global: "307,34",
    ct: "1,16",
    ct_global: "29,464",
    ix: "1,83",
    ix_global: "761703,5088",
    iy: 153,
    iy_global: "63683408,12",
    iz: 472,
    iz_global: "196461232,9",
    k: "1,25",
    k_global: "31,75",
    k1: "0,88",
    k1_global: "22,352",
    t: "9,4",
    t_global: "238,76",
    tf: "0,52",
    tf_global: "13,208",
    tw: "0,52",
    tw_global: "13,208",
    zx: "88,3",
    zx_global: "1446977,751",
    zy: "38,7",
    zy_global: "634179,3768",
    width: "12,1",
    width_global: "307,34",
    thickness: "0,52",
    thickness_global: "13,208",
    height: "11,9",
    height_global: "302,26",
  },
  {
    profile_section_id: 521,
    name: "HP12X53",
    type: "HP Shape",
    designation: "HP12X53",
    shape: "I",
    country_code: "American",
    ax: "15,5",
    ax_global: "9999,98",
    b: "11,8",
    b_global: "299,72",
    bf: 12,
    bf_global: "304,8",
    ct: "1,12",
    ct_global: "28,448",
    ix: "1,12",
    ix_global: "466179,1967",
    iy: 127,
    iy_global: "52861391,05",
    iz: 393,
    iz_global: "163578950,3",
    k: "1,13",
    k_global: "28,702",
    k1: "0,88",
    k1_global: "22,352",
    t: "9,54",
    t_global: "242,316",
    tf: "0,44",
    tf_global: "11,176",
    tw: "0,44",
    tw_global: "11,176",
    zx: 74,
    zx_global: "1212642,736",
    zy: "32,2",
    zy_global: "527663,4608",
    width: 12,
    width_global: "304,8",
    thickness: "0,44",
    thickness_global: "11,176",
    height: "11,8",
    height_global: "299,72",
  },
  {
    profile_section_id: 522,
    name: "HP10X57",
    type: "HP Shape",
    designation: "HP10X57",
    shape: "I",
    country_code: "American",
    ax: "16,7",
    ax_global: "10774,172",
    b: "9,99",
    b_global: "253,746",
    bf: "10,2",
    bf_global: "259,08",
    ct: "1,03",
    ct_global: "26,162",
    ix: "1,97",
    ix_global: "819975,9084",
    iy: 101,
    iy_global: "42039373,99",
    iz: 294,
    iz_global: "122372039,1",
    k: "1,25",
    k_global: "31,75",
    k1: "0,94",
    k1_global: "23,876",
    t: "7,49",
    t_global: "190,246",
    tf: "0,56",
    tf_global: "14,224",
    tw: "0,56",
    tw_global: "14,224",
    zx: "66,5",
    zx_global: "1089739,756",
    zy: "30,3",
    zy_global: "496528,0392",
    width: "10,2",
    width_global: "259,08",
    thickness: "0,56",
    thickness_global: "14,224",
    height: "9,99",
    height_global: "253,746",
  },
  {
    profile_section_id: 523,
    name: "HP10X42",
    type: "HP Shape",
    designation: "HP10X42",
    shape: "I",
    country_code: "American",
    ax: "12,4",
    ax_global: "7999,984",
    b: "9,7",
    b_global: "246,38",
    bf: "10,1",
    bf_global: "256,54",
    ct: "0,93",
    ct_global: "23,622",
    ix: "0,81",
    ix_global: "337147,4547",
    iy: "71,7",
    iy_global: "29843793,22",
    iz: 210,
    iz_global: "87408599,38",
    k: "1,13",
    k_global: "28,702",
    k1: "0,81",
    k1_global: "20,574",
    t: "7,44",
    t_global: "188,976",
    tf: "0,42",
    tf_global: "10,668",
    tw: "0,41",
    tw_global: "10,414",
    zx: "48,3",
    zx_global: "791495,1912",
    zy: "21,8",
    zy_global: "357237,9952",
    width: "10,1",
    width_global: "256,54",
    thickness: "0,42",
    thickness_global: "10,668",
    height: "9,7",
    height_global: "246,38",
  },
  {
    profile_section_id: 524,
    name: "HP8X36",
    type: "HP Shape",
    designation: "HP8X36",
    shape: "I",
    country_code: "American",
    ax: "10,6",
    ax_global: "6838,696",
    b: "8,02",
    b_global: "203,708",
    bf: "8,16",
    bf_global: "207,264",
    ct: "0,82",
    ct_global: "20,828",
    ix: "0,77",
    ix_global: "320498,1977",
    iy: "40,3",
    iy_global: "16774126,45",
    iz: 119,
    iz_global: "49531539,65",
    k: "1,13",
    k_global: "28,702",
    k1: "0,88",
    k1_global: "22,352",
    t: "5,76",
    t_global: "146,304",
    tf: "0,45",
    tf_global: "11,43",
    tw: "0,45",
    tw_global: "11,43",
    zx: "33,6",
    zx_global: "550605,3504",
    zy: "15,2",
    zy_global: "249083,3728",
    width: "8,16",
    width_global: "207,264",
    thickness: "0,45",
    thickness_global: "11,43",
    height: "8,02",
    height_global: "203,708",
  },
  {
    profile_section_id: 525,
    name: "S3X5",
    type: "S Shape",
    designation: "S3X5",
    shape: "I",
    country_code: "American",
    ax: "1,67",
    ax_global: "1077,4172",
    bf: "2,33",
    bf_global: "59,182",
    ct: "0,33",
    d: 3,
    d_global: "76,2",
    ix: "0,04",
    ix_global: "16649,25702",
    iy: "0,5",
    iy_global: "208115,7128",
    iz: "2,5",
    iz_global: "1040578,564",
    k: "0,625",
    k_global: "15,875",
    t: "1,75",
    t_global: "44,45",
    tf: "0,26",
    tf_global: "6,604",
    tw: "0,17",
    tw_global: "4,318",
    zx: 2,
    zx_global: "32774,128",
    zy: "0,7",
    zy_global: "11470,9448",
    width: "2,33",
    width_global: "59,182",
    thickness: "0,26",
    thickness_global: "6,604",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 526,
    name: "S3X7",
    type: "S Shape",
    designation: "S3X7",
    shape: "I",
    country_code: "American",
    ax: "2,21",
    ax_global: "1425,8036",
    bf: "2,509",
    bf_global: "63,7286",
    ct: "0,43",
    d: 3,
    d_global: "76,2",
    ix: "0,09",
    ix_global: "37460,8283",
    iy: "0,6",
    iy_global: "249738,8554",
    iz: "2,9",
    iz_global: "1207071,134",
    k: "0,625",
    k_global: "15,875",
    t: "1,75",
    t_global: "44,45",
    tf: "0,26",
    tf_global: "6,604",
    tw: "0,349",
    tw_global: "8,8646",
    zx: "2,4",
    zx_global: "39328,9536",
    zy: "0,8",
    zy_global: "13109,6512",
    width: "2,509",
    width_global: "63,7286",
    thickness: "0,26",
    thickness_global: "6,604",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 527,
    name: "S4X7",
    type: "S Shape",
    designation: "S4X7",
    shape: "I",
    country_code: "American",
    ax: "2,26",
    ax_global: "1458,0616",
    bf: "2,663",
    bf_global: "67,6402",
    ct: "0,45",
    d: 4,
    d_global: "101,6",
    ix: "0,07",
    ix_global: "29136,19979",
    iy: "0,8",
    iy_global: "332985,1405",
    iz: "6,1",
    iz_global: "2539011,696",
    k: "0,75",
    k_global: "19,05",
    t: "2,5",
    t_global: "63,5",
    tf: "0,293",
    tf_global: "7,4422",
    tw: "0,193",
    tw_global: "4,9022",
    zx: "3,5",
    zx_global: "57354,724",
    zy: 1,
    zy_global: "16387,064",
    width: "2,663",
    width_global: "67,6402",
    thickness: "0,293",
    thickness_global: "7,4422",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 528,
    name: "S4X9",
    type: "S Shape",
    designation: "S4X9",
    shape: "I",
    country_code: "American",
    ax: "2,79",
    ax_global: "1799,9964",
    bf: "2,796",
    bf_global: "71,0184",
    ct: "0,55",
    d: 4,
    d_global: "101,6",
    ix: "0,12",
    ix_global: "49947,77107",
    iy: "0,9",
    iy_global: "374608,283",
    iz: "6,8",
    iz_global: "2830373,694",
    k: "0,75",
    k_global: "19,05",
    t: "2,5",
    t_global: "63,5",
    tf: "0,293",
    tf_global: "7,4422",
    tw: "0,326",
    tw_global: "8,2804",
    zx: 4,
    zx_global: "65548,256",
    zy: "1,1",
    zy_global: "18025,7704",
    width: "2,796",
    width_global: "71,0184",
    thickness: "0,293",
    thickness_global: "7,4422",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 529,
    name: "S5X10",
    type: "S Shape",
    designation: "S5X10",
    shape: "I",
    country_code: "American",
    ax: "2,94",
    ax_global: "1896,7704",
    bf: "3,004",
    bf_global: "76,3016",
    ct: "0,57",
    d: 5,
    d_global: 127,
    ix: "0,11",
    ix_global: "45785,45682",
    iy: "1,2",
    iy_global: "499477,7107",
    iz: "12,3",
    iz_global: "5119646,535",
    k: "0,75",
    k_global: "19,05",
    t: "3,5",
    t_global: "88,9",
    tf: "0,326",
    tf_global: "8,2804",
    tw: "0,214",
    tw_global: "5,4356",
    zx: "5,7",
    zx_global: "93406,2648",
    zy: "1,4",
    zy_global: "22941,8896",
    width: "3,004",
    width_global: "76,3016",
    thickness: "0,326",
    thickness_global: "8,2804",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 530,
    name: "S5X14",
    type: "S Shape",
    designation: "S5X14",
    shape: "I",
    country_code: "American",
    ax: "4,34",
    ax_global: "2799,9944",
    bf: "3,284",
    bf_global: "83,4136",
    ct: "0,79",
    d: 5,
    d_global: 127,
    ix: "0,32",
    ix_global: "133194,0562",
    iy: "1,7",
    iy_global: "707593,4235",
    iz: "15,2",
    iz_global: "6326717,669",
    k: "0,75",
    k_global: "19,05",
    t: "3,5",
    t_global: "88,9",
    tf: "0,326",
    tf_global: "8,2804",
    tw: "0,494",
    tw_global: "12,5476",
    zx: "7,4",
    zx_global: "121264,2736",
    zy: "1,9",
    zy_global: "31135,4216",
    width: "3,284",
    width_global: "83,4136",
    thickness: "0,326",
    thickness_global: "8,2804",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 531,
    name: "S6X12",
    type: "S Shape",
    designation: "S6X12",
    shape: "I",
    country_code: "American",
    ax: "3,67",
    ax_global: "2367,7372",
    bf: "3,332",
    bf_global: "84,6328",
    ct: "0,69",
    d: 6,
    d_global: "152,4",
    ix: "0,17",
    ix_global: "70759,34235",
    iy: "1,8",
    iy_global: "749216,5661",
    iz: "22,1",
    iz_global: "9198714,506",
    k: "0,8125",
    k_global: "20,6375",
    t: "4,375",
    t_global: "111,125",
    tf: "0,359",
    tf_global: "9,1186",
    tw: "0,232",
    tw_global: "5,8928",
    zx: "8,5",
    zx_global: "139290,044",
    zy: "1,9",
    zy_global: "31135,4216",
    width: "3,332",
    width_global: "84,6328",
    thickness: "0,359",
    thickness_global: "9,1186",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 532,
    name: "S6X17",
    type: "S Shape",
    designation: "S6X17",
    shape: "I",
    country_code: "American",
    ax: "5,07",
    ax_global: "3270,9612",
    bf: "3,565",
    bf_global: "90,551",
    ct: "0,91",
    d: 6,
    d_global: "152,4",
    ix: "0,37",
    ix_global: "154005,6275",
    iy: "2,3",
    iy_global: "957332,2789",
    iz: "26,3",
    iz_global: "10946886,49",
    k: "0,8125",
    k_global: "20,6375",
    t: "4,375",
    t_global: "111,125",
    tf: "0,359",
    tf_global: "9,1186",
    tw: "0,465",
    tw_global: "11,811",
    zx: "10,6",
    zx_global: "173702,8784",
    zy: "2,4",
    zy_global: "39328,9536",
    width: "3,565",
    width_global: "90,551",
    thickness: "0,359",
    thickness_global: "9,1186",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 533,
    name: "S7X15",
    type: "S Shape",
    designation: "S7X15",
    shape: "I",
    country_code: "American",
    ax: "4,5",
    ax_global: "2903,22",
    bf: "3,662",
    bf_global: "93,0148",
    ct: "0,82",
    d: 7,
    d_global: "177,8",
    ix: "0,24",
    ix_global: "99895,54214",
    iy: "2,6",
    iy_global: "1082201,707",
    iz: "36,7",
    iz_global: "15275693,32",
    k: "0,9375",
    k_global: "23,8125",
    t: "5,125",
    t_global: "130,175",
    tf: "0,392",
    tf_global: "9,9568",
    tw: "0,252",
    tw_global: "6,4008",
    zx: "12,1",
    zx_global: "198283,4744",
    zy: "2,4",
    zy_global: "39328,9536",
    width: "3,662",
    width_global: "93,0148",
    thickness: "0,392",
    thickness_global: "9,9568",
    height: 7,
    height_global: "177,8",
  },
  {
    profile_section_id: 534,
    name: "S7X20",
    type: "S Shape",
    designation: "S7X20",
    shape: "I",
    country_code: "American",
    ax: "5,88",
    ax_global: "3793,5408",
    bf: "3,86",
    bf_global: "98,044",
    ct: "1,04",
    d: 7,
    d_global: "177,8",
    ix: "0,45",
    ix_global: "187304,1415",
    iy: "3,2",
    iy_global: "1331940,562",
    iz: "42,4",
    iz_global: "17648212,45",
    k: "0,9375",
    k_global: "23,8125",
    t: "5,125",
    t_global: "130,175",
    tf: "0,392",
    tf_global: "9,9568",
    tw: "0,45",
    tw_global: "11,43",
    zx: "14,5",
    zx_global: "237612,428",
    zy: 3,
    zy_global: "49161,192",
    width: "3,86",
    width_global: "98,044",
    thickness: "0,392",
    thickness_global: "9,9568",
    height: 7,
    height_global: "177,8",
  },
  {
    profile_section_id: 535,
    name: "S8X18",
    type: "S Shape",
    designation: "S8X18",
    shape: "I",
    country_code: "American",
    ax: "5,41",
    ax_global: "3490,3156",
    bf: "4,001",
    bf_global: "101,6254",
    ct: "0,94",
    d: 8,
    d_global: "203,2",
    ix: "0,34",
    ix_global: "141518,6847",
    iy: "3,7",
    iy_global: "1540056,275",
    iz: "57,6",
    iz_global: "23974930,11",
    k: 1,
    k_global: "25,4",
    t: 6,
    t_global: "152,4",
    tf: "0,426",
    tf_global: "10,8204",
    tw: "0,271",
    tw_global: "6,8834",
    zx: "16,5",
    zx_global: "270386,556",
    zy: "3,2",
    zy_global: "52438,6048",
    width: "4,001",
    width_global: "101,6254",
    thickness: "0,426",
    thickness_global: "10,8204",
    height: 8,
    height_global: "203,2",
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: p.ax ? fixValueToNumber(p.ax, "float") : null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: p.b ? fixValueToNumber(p.b, "float") : null,
    b_global: p.b_global ? fixValueToNumber(p.b_global, "float") : undefined,
    bf: p.bf ? fixValueToNumber(p.bf, "float") : null,
    bf_global: p.bf_global ? fixValueToNumber(p.bf_global, "float") : undefined,
    c: null,
    ct: p.ct ? fixValueToNumber(p.ct, "float") : null,
    ct_global: p.ct_global ? fixValueToNumber(p.ct_global, "float") : undefined,
    d: p.d ? fixValueToNumber(p.d, "float") : null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: null,
    i: null,
    ix: p.ix ? fixValueToNumber(p.ix, "float") : null,
    ix_global: p.ix_global ? fixValueToNumber(p.ix_global, "float") : undefined,
    iy: p.iy ? fixValueToNumber(p.iy, "float") : null,
    iy_global: p.iy_global ? fixValueToNumber(p.iy_global, "float") : undefined,
    iz: p.iz ? fixValueToNumber(p.iz, "float") : null,
    iz_global: p.iz_global ? fixValueToNumber(p.iz_global, "float") : undefined,
    k: p.k ? fixValueToNumber(p.k, "float") : null,
    k_global: p.k_global ? fixValueToNumber(p.k_global, "float") : undefined,
    k1: p.k1 ? fixValueToNumber(p.k1, "float") : null,
    k1_global: p.k1_global ? fixValueToNumber(p.k1_global, "float") : undefined,
    od: p.od ? fixValueToNumber(p.od, "float") : null,
    od_global: p.od_global ? fixValueToNumber(p.od_global, "float") : undefined,
    r1: null,
    r2: null,
    rz: p.rz ? fixValueToNumber(p.rz, "float") : null,
    rz_global: p.rz_global ? fixValueToNumber(p.rz_global, "float") : undefined,
    t: p.t ? fixValueToNumber(p.t, "float") : null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: p.tf ? fixValueToNumber(p.tf, "float") : null,
    tf_global: p.tf_global ? fixValueToNumber(p.tf_global, "float") : undefined,
    tw: p.tw ? fixValueToNumber(p.tw, "float") : null,
    tw_global: p.tw_global ? fixValueToNumber(p.tw_global, "float") : undefined,
    z: null,
    zx: p.zx ? fixValueToNumber(p.zx, "float") : null,
    zx_global: p.zx_global ? fixValueToNumber(p.zx_global, "float") : undefined,
    zy: p.zy ? fixValueToNumber(p.zy, "float") : null,
    zy_global: p.zy_global ? fixValueToNumber(p.zy_global, "float") : undefined,
    zz: null,
    width: fixValueToNumber(p.width, "float"),
    width_global: fixValueToNumber(p.width_global, "float"),
    thickness: fixValueToNumber(p.thickness, "float"),
    thickness_global: p.thickness_global
      ? fixValueToNumber(p.thickness_global, "float")
      : undefined,
    height: fixValueToNumber(p.height, "float"),
    height_global: fixValueToNumber(p.height_global, "float"),
  };
  return profile;
});
