import { Button, Checkbox, Icon, InputGroup, Tooltip, Dialog, FormGroup, Spinner, ProgressBar } from "@blueprintjs/core";
import React, { useState, ChangeEvent, useEffect } from "react";
import Axios from "axios";
import "./fileUpload.css";
import { secondServerAPI } from "../../utils/agent";
import { ApplicationState } from "../../../store";
import { useSelector } from "react-redux";

interface File {
    id: string;
    name: string;
    size: number;
    type: string;
    lastModified: Date;
    preview: string;
    downloadLink: string;
}

export function FileUpload() {
    const [files, setFiles] = useState<File[]>([]);
    const [sharedFiles, setSharedFiles] = useState<File[]>([]);
    const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
    const [view, setView] = useState<"grid" | "list">("list");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [activeTab, setActiveTab] = useState<"myFiles" | "sharedFiles">("myFiles");
    const [fileTypeFilter, setFileTypeFilter] = useState<string>("");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFilter, setIsFilter] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [downloadProgress, setDownloadProgress] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [remainingTime, setRemainingTime] = useState<number | null>(null);


    const supported3DFormats = [
        "e57", 
        "obj", 
        "stl", 
        "ply", 
        "fbx", 
        "3ds", 
        "gltf", 
        "glb", 
        "pdf", 
        "doc", 
        "docx", 
        "rvt", 
        "rfa", 
        "dxf", 
        "rft", 
        "rte", 
        "dgn", 
        "dwf", 
        "dwg", 
        "ifc"
    ];
    
    const auth = useSelector((state: ApplicationState) => state.auth);

    useEffect(() => {
        if (activeTab === "myFiles") {
            fetchFiles();
        } else if (activeTab === "sharedFiles") {
            fetchSharedFiles();
        }
    }, [activeTab]);

    async function checkUserCredits() {
        try {
            const response = await Axios.get(`${secondServerAPI}/rest/api/v1/ufc/getUserCredits`, {
                headers: {
                    'user-id': auth.User_id
                    // 'user-id': 1,
                }
            });
            return response.data.availableCredits >= 1;
        } catch (error) {
            console.error("Error checking user credits:", error);
            return false;
        }
    }

    async function updateUserCredits() {
        try {
            await Axios.post(`${secondServerAPI}/rest/api/v1/ufc/updateCredits`, null, {
                headers: {
                    'user-id': auth.User_id
                    // 'user-id': 1,
                }
            });
        } catch (error) {
            console.error("Error updating user credits:", error);
        }
    }

    function fetchFiles() {
        const userId = Number(auth.User_id); 
        if (isNaN(userId)) {
            throw new Error("Invalid user ID");
        }
        Axios.get(`${secondServerAPI}/rest/API/v1/ufc/getAllFiless`, {
            headers: {
                // 'user-id' : auth.User_id,
                'user-id': userId,
                // 'user-id': 1,
            }
        }).then(response => {
            console.log("call made successfully", response);
            const fetchedFiles = response.data.files.map((file: any) => ({
                id: file.file_id,
                name: file.file_name,
                size: file.file_size_in_mb,
                type: file.file_type,
                lastModified: new Date(),
                preview: '',
                downloadLink: '',
            }));
            setFiles(fetchedFiles);
        }).catch(error => {
            console.log("call failed", error);
        });
    }

    function handleAddtoMyFile(fileId: string) {
        Axios.post(`${secondServerAPI}/rest/api/v1/ufc/addFilesToUploadedFromShared`, {
            userId: auth.User_id,
            fileId: fileId
        }).then(response => {
            if (response.status === 200) {
                alert("File added to your files successfully");
            } else {
                alert("Failed to add file to your files");
            }
        }).catch(error => {
            console.error("Error adding file to your files:", error);
            alert("Failed to add file to your files");
        });
    }

    function fetchSharedFiles() {
        Axios.get(`${secondServerAPI}/rest/api/v1/ufc/getSharedFileDetailsForUser`, {
            headers: {
                'user-id' : auth.User_id,
            }
        }).then(response => {
            if (response.status === 200) {
                const fetchedFiles = response.data.map((file: any) => ({
                    id: file.fileId,
                    name: file.fileName,
                    size: file.fileSizeInMB,
                    type: file.fileType,
                    lastModified: new Date(file.createdOn),
                    preview: '',
                    downloadLink: file.fileAddress,

                }));
                setSharedFiles(fetchedFiles);
            } else if (response.status === 404) {
                setSharedFiles([]);
            }
        }).catch(error => {
            console.error("Error fetching shared files:", error);
            setSharedFiles([]);
        });
    }

    // async function handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
    //     setIsLoading(true);
    //     const selectedFiles = event.target.files;
    
    //     if (!selectedFiles) {
    //         alert("No files selected. Please try again...");
    //         setIsLoading(false);
    //         return;
    //     }
    
    //     const hasCredits = await checkUserCredits();
    //     if (!hasCredits) {
    //         alert("You do not have enough credits to upload files.");
    //         setIsLoading(false);
    //         return;
    //     }
    
    //     const CHUNK_SIZE = 5 * 1024 * 1024; 
    //     const totalChunksProgress: { [fileId: string]: number } = {}; 
    //     const chunkStartTimes: { [fileId: string]: number[] } = {}; 
    
    //     const uploadChunk = async (file: File, chunk: Blob, chunkIndex: number, totalChunks: number, newFileName: string, fileType: string) => {
    //         const formData = new FormData();
    //         formData.append('file', chunk);
    //         const fileSizeInMb = (file.size / (1024 * 1024)).toFixed(2); 
    
    //         const startTime = Date.now();
    //         chunkStartTimes[file.id].push(startTime);
    
    //         try {
    //             const response = await Axios.post(`${secondServerAPI}/zuul/node/upload`, formData, {
    //                 headers: {
    //                     'Content-Type': 'multipart/form-data',
    //                     'user-id': auth.User_id,
    //                     'chunkindex': String(chunkIndex),
    //                     'totalchunks': String(totalChunks),
    //                     'file-name': newFileName,
    //                     'file-type': fileType,
    //                     'file-size-in-mb': fileSizeInMb,  
    //                 },
    //                 onUploadProgress: (progressEvent) => {
    //                     const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //                     const overallProgress = Math.round((chunkIndex / totalChunks) * 100 + (percentCompleted / totalChunks));
    //                     totalChunksProgress[file.id] = overallProgress;
    //                     setUploadProgress(overallProgress); 
    //                 }
    //             });
    
    //             if (response.status !== 200) {
    //                 throw new Error(`Chunk ${chunkIndex + 1} of ${file.name} failed to upload.`);
    //             }
    //         } catch (error) {
    //             console.error(`Error uploading chunk ${chunkIndex + 1}:`, error);
    //             alert(`Failed to upload chunk ${chunkIndex + 1} of ${file.name}.`);
    //             throw error;
    //         }
    //     };

        
    
    //     const calculateRemainingTime = (fileId: string, currentChunk: number, totalChunks: number) => {
    //         const chunkTimes = chunkStartTimes[fileId];
    //         const currentTime = Date.now();
    //         const elapsedTime = currentTime - chunkTimes[0]; 
    
    //         const averageTimePerChunk = elapsedTime / currentChunk;
    //         const remainingChunks = totalChunks - currentChunk;
    //         const estimatedRemainingTime = Math.round(averageTimePerChunk * remainingChunks / 1000); 
    
    //         return estimatedRemainingTime;
    //     };
    
    //     // const checkFileProcessingStatus = async (fileId: string) => {
    //     //     try {
    //     //         const response = await Axios.get(`${secondServerAPI}/rest/api/v1/ufc/checkFileStatus`, {
    //     //             headers: {
    //     //                 'user-id': auth.User_id,
    //     //                 'file-id': fileId
    //     //             }
    //     //         });
    //     //         return response.data.isProcessed; 
    //     //     } catch (error) {
    //     //         console.error("Error checking file status:", error);
    //     //         return false;
    //     //     }
    //     // };
    
    //     // const pollFileVisibility = (fileId: string) => {
    //     //     const pollInterval = setInterval(async () => {
    //     //         const isFileVisible = await checkFileProcessingStatus(fileId);
    //     //         if (isFileVisible) {
    //     //             clearInterval(pollInterval);
    //     //             alert(`File is now available: ${fileId}`);
    //     //             fetchFiles(); 
    //     //         }
    //     //     }, 5000); 
    //     // };
    
    //     const uploadFileInChunks = async (file: File) => {
    //         const fileSize = file.size;
    //         const totalChunks = Math.ceil(fileSize / CHUNK_SIZE);
    //         const newFileName = file.name.replace(/[^a-zA-Z0-9.]/g, ''); 
    //         const extension = file.name.split('.').pop()?.toLowerCase();
    //         const mimeTypes: { [key: string]: string } = {
    //             'stl': 'application/vnd.ms-pkistl',
    //             'obj': 'application/x-tgif',
    //             'fbx': 'application/octet-stream',
    //             'glb': 'model/gltf-binary',
    //             'gltf': 'model/gltf+json',
    //             'dae': 'model/vnd.collada+xml',
    //             'e57': 'model/vnd.e57+xml',
    //             'ply': 'model/vnd.ply+xml',
    //             'doc': 'application/msword',
    //             'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    //             'pdf': 'application/pdf',
    //             'rvt': 'application/octet-stream',
    //             'rfa': 'application/octet-stream',
    //             'dxf': 'image/vnd.dxf',
    //             'rft': 'application/octet-stream',
    //             'rte': 'application/octet-stream',
    //             'dgn': 'application/vnd.dgn',
    //             'dwf': 'model/vnd.dwf',
    //             'dwg': 'image/vnd.dwg',
    //             'ifc': 'application/octet-stream'
    //         };
    //         const fileType = mimeTypes[extension || ''] || 'application/octet-stream';
    
    //         totalChunksProgress[file.id] = 0; 
    //         chunkStartTimes[file.id] = [];   
    
    //         for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    //             const start = chunkIndex * CHUNK_SIZE;
    //             const end = Math.min(start + CHUNK_SIZE, fileSize);
    //             const chunk = file.slice(start, end);
    
    //             await uploadChunk(file, chunk, chunkIndex, totalChunks, newFileName, fileType);
    
    //             const remainingTime = calculateRemainingTime(file.id, chunkIndex + 1, totalChunks);
    //             setRemainingTime(remainingTime); 
    //             console.log(`Estimated time remaining for ${file.name}: ${remainingTime} seconds`);
    //         }

    //         await updateUserCredits();
    
    //         alert(`File ${file.name} uploaded successfully.`);

    //         //pollFileVisibility(file.id);
    //     };
    
    //     const newFiles = Array.from(selectedFiles).map(file => {
    //         const fileSizeInMB = file.size / (1024 * 1024);
    //         if (fileSizeInMB > 10024) {
    //             alert("File size must be less than 10GB.");
    //             return null;
    //         }
    
    //         let newFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '');  
    //         const existingFileNames = files.map(f => f.name);
    //         let nameConflictCount = 0;
    //         while (existingFileNames.includes(newFileName)) {
    //             nameConflictCount++;
    //             newFileName = newFileName.replace(/(\.[\w\d_-]+)$/i, `(${nameConflictCount})$1`);
    //         }
    
    //         return {
    //             id: String(new Date().getTime() + Math.random()),
    //             name: newFileName,
    //             size: fileSizeInMB,
    //             type: file.type,
    //             lastModified: new Date(file.lastModified),
    //             preview: URL.createObjectURL(file),
    //             downloadLink: URL.createObjectURL(file),
    //         };
    //     }).filter(Boolean) as File[];
    
    //     setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    
    //     for (const file of Array.from(selectedFiles)) {
    //         await uploadFileInChunks(file);
    //     }
    
    //     setIsLoading(false);
    //     fetchFiles(); 
    // }

    async function handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
        setIsLoading(true);
        const selectedFiles = event.target.files;
    
        if (!selectedFiles) {
            alert("No files selected. Please try again...");
            setIsLoading(false);
            return;
        }
    
        const hasCredits = await checkUserCredits();
        if (!hasCredits) {
            alert("You do not have enough credits to upload files.");
            setIsLoading(false);
            return;
        }
    
        const CHUNK_SIZE = 5 * 1024 * 1024; 
        const totalChunksProgress: { [fileId: string]: number } = {};
        const chunkStartTimes: { [fileId: string]: number[] } = {};
    
        const uploadChunk = async (file: File, chunk: Blob, chunkIndex: number, totalChunks: number, newFileName: string, fileType: string) => {
            const formData = new FormData();
            formData.append('file', chunk);
            const fileSizeInMb = (file.size / (1024 * 1024)).toFixed(2);
    
            const startTime = Date.now();
            chunkStartTimes[file.id].push(startTime);
    
            try {
                const response = await Axios.post(`${secondServerAPI}/zuul/node/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'user-id': auth.User_id,
                        'chunkindex': String(chunkIndex),
                        'totalchunks': String(totalChunks),
                        'file-name': newFileName,
                        'file-type': fileType,
                        'file-size-in-mb': fileSizeInMb,
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        const overallProgress = Math.round((chunkIndex / totalChunks) * 100 + (percentCompleted / totalChunks));
                        totalChunksProgress[file.id] = overallProgress;
                        setUploadProgress(overallProgress); 
                    }
                });
    
                if (response.status !== 200) {
                    throw new Error(`Chunk ${chunkIndex + 1} of ${file.name} failed to upload.`);
                }
            } catch (error) {
                console.error(`Error uploading chunk ${chunkIndex + 1}:`, error);
                alert(`Failed to upload chunk ${chunkIndex + 1} of ${file.name}.`);
                throw error;
            }
        };
    
        const calculateRemainingTime = (fileId: string, currentChunk: number, totalChunks: number) => {
            const chunkTimes = chunkStartTimes[fileId];
            const currentTime = Date.now();
            const elapsedTime = currentTime - chunkTimes[0];
    
            const averageTimePerChunk = elapsedTime / currentChunk;
            const remainingChunks = totalChunks - currentChunk;
            const estimatedRemainingTime = Math.round((averageTimePerChunk * remainingChunks) / 1000);
    
            return estimatedRemainingTime;
        };
    
        const uploadFileInChunks = async (file: File) => {
            const fileSize = file.size;
            const totalChunks = Math.ceil(fileSize / CHUNK_SIZE);
            const newFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '');
            const extension = file.name.split('.').pop()?.toLowerCase();
            const mimeTypes: { [key: string]: string } = {
                // MIME type map
                'stl': 'application/vnd.ms-pkistl',
                'obj': 'application/x-tgif',
                'fbx': 'application/octet-stream',
                'glb': 'model/gltf-binary',
                'gltf': 'model/gltf+json',
                'dae': 'model/vnd.collada+xml',
                'e57': 'model/vnd.e57+xml',
                'ply': 'model/vnd.ply+xml',
                'doc': 'application/msword',
                'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'pdf': 'application/pdf',
                'rvt': 'application/octet-stream',
                'rfa': 'application/octet-stream',
                'dxf': 'image/vnd.dxf',
                'rft': 'application/octet-stream',
                'rte': 'application/octet-stream',
                'dgn': 'application/vnd.dgn',
                'dwf': 'model/vnd.dwf',
                'dwg': 'image/vnd.dwg',
                'ifc': 'application/octet-stream'
            };
            const fileType = mimeTypes[extension || ''] || 'application/octet-stream';
    
            totalChunksProgress[file.id] = 0;
            chunkStartTimes[file.id] = [];
    
            for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
                const start = chunkIndex * CHUNK_SIZE;
                const end = Math.min(start + CHUNK_SIZE, fileSize);
                const chunk = file.slice(start, end);
    
                await uploadChunk(file, chunk, chunkIndex, totalChunks, newFileName, fileType);
    
                const remainingTime = calculateRemainingTime(file.id, chunkIndex + 1, totalChunks);
                setRemainingTime(remainingTime);
                console.log(`Estimated time remaining for ${file.name}: ${remainingTime} seconds`);
            }
    
            await updateUserCredits();
        };
 
        const uploadPromises = Array.from(selectedFiles).map(file => uploadFileInChunks(file));
        await Promise.all(uploadPromises);
        alert(`Files uploaded successfully.`);
        setIsLoading(false);
        fetchFiles();
    }
    
    
    function triggerFileInput() {
        document.getElementById("fileInput")?.click();
    }

    function handleCheckboxChange(fileId: string, isChecked: boolean) {
        setSelectedFileIds(prevSelectedFileIds => {
            if (isChecked) {
                return [...prevSelectedFileIds, fileId];
            } else {
                return prevSelectedFileIds.filter(id => id !== fileId);
            }
        });
    }

    function handleSelectAll(isChecked: boolean) {
        if (isChecked) {
            const fileIds = activeTab === "myFiles" ? filteredFiles.map(file => file.id) : filteredSharedFiles.map(file => file.id);
            setSelectedFileIds(fileIds);
        } else {
            setSelectedFileIds([]);
        }
    }

    function handleDeleteFile() {
        setIsLoading(true);

        const sharedFilesToDelete = selectedFileIds.filter(fileId => 
            sharedFiles.some(sharedFile => sharedFile.id === fileId)
        );
    
        if (sharedFilesToDelete.length > 0) {
            alert("Shared files cannot be deleted.");
            setIsLoading(false);
            setSelectedFileIds([]); 
            return;
        }
    
        const deleteFilePromises = selectedFileIds.map(fileId => {
            return Axios.delete(`${secondServerAPI}/rest/api/v1/ufc/deleteUploadedFile`, {
                headers: {
                    'file-id': fileId,
                    'user-id': auth.User_id,
                    // 'user-id': 1,
                }
            });
        });
    
        Promise.all(deleteFilePromises)
            .then(responses => {
                const allSuccessful = responses.every(response => response.status === 200);
                if (allSuccessful) {
                    console.log("All files deleted successfully");
                    setIsDialogOpen(false);
                    fetchFiles();  
                    alert("File deleted successfully");
                } else {
                    console.log("Some files failed to delete.");
                    alert("Failed to delete some files");
                }
            })
            .catch(error => {
                console.error("Error deleting files:", error);
                alert("Failed to delete files");
            })
            .finally(() => {
                setIsLoading(false);
                setIsDialogOpen(false);
                setSelectedFileIds([]);  
            });
    }
    
    function handleShareFile() {
        setIsLoading(true);
        const shareFilePromises = selectedFileIds.map(fileId => {
            return Axios.post(`${secondServerAPI}/rest/api/v1/ufc/shareFile`, {
                toUserEmail: email,
                fileId,
                fromUserId: auth.User_id,
            });
        });

        Promise.all(shareFilePromises)
            .then(responses => {
                const allSuccessful = responses.every(response => response.status === 200);
                if (allSuccessful) {
                    console.log("All files shared successfully");
                    setIsDialogOpen(false);
                    setIsLoading(false);
                    alert("File shared successfully");
                } else {
                    const failedShares = responses.filter(response => response.status !== 200);
                    console.log(`${failedShares.length} files failed to share.`);
                    setIsDialogOpen(false);
                    setIsLoading(false);
                    alert("Failed to share some files");
                }
            })
            .catch(error => {
                console.error("Error sharing files:", error);
                setIsLoading(false);
                setIsDialogOpen(false);
                alert("Failed to share files");
            });
    }

    function handleDownloadSelectedFiles() {
        if (selectedFileIds.length === 0) {
            alert('Please select at least one file to download.');
            return;
        }
    
        setIsLoading(true);
        setDownloadProgress(0);
    
        Axios.post(`${secondServerAPI}/rest/api/v1/ufc/downloadFiles`, {
            // userId: 1,
            userId: auth.User_id,
            fileIds: selectedFileIds
        }, {
            headers: {
                'Content-Type': 'application/json'
            },
            responseType: 'blob'
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Download_files.zip');
            document.body.appendChild(link);
            link.click();
            link.remove();
    
            setIsLoading(false);
            setDownloadProgress(100);
        })
        .catch(error => {
            console.error('Error downloading files:', error);
            setIsLoading(false);
            setDownloadProgress(0);
        });
    }

    function handleFilterChange(event: ChangeEvent<HTMLSelectElement>) {
        setFileTypeFilter(event.target.value);
    }

    function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
        setSearchQuery(event.target.value);
    }

    function handleFilterClick() {
        setIsFilter(!isFilter);
    }

    const itemsPerPage = 20;

    const filteredFiles = files.filter(file => {
        const matchesType = fileTypeFilter === "" || file.type === fileTypeFilter;
        const matchesSearch = file.name.toLowerCase().includes(searchQuery.toLowerCase());
        return matchesType && matchesSearch;
    });

    const filteredSharedFiles = sharedFiles.filter(file => {
        const matchesType = fileTypeFilter === "" || file.type === fileTypeFilter;
        const matchesSearch = file.name.toLowerCase().includes(searchQuery.toLowerCase());
        return matchesType && matchesSearch;
    });

    const filesToShow = filteredFiles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const sharedFilesToShow = filteredSharedFiles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const totalPages = Math.ceil((activeTab === "myFiles" ? filteredFiles.length : filteredSharedFiles.length) / itemsPerPage);

    return (
        <div className="primary-file-upload-container">
            <div className="primary-file-handler-container">
                <div className="file-handler">
                    <h1 style={{ color: "#2c3e50" }}>Upload</h1>
                </div>
                <div className="file-controller">
                    <div className="left-controls">
                        <Tooltip position="bottom" content={"Grid View"}>
                            <Button
                                icon="grid-view"
                                onClick={() => setView("grid")}
                                active={view === "grid"}
                            />
                        </Tooltip>
                        <Tooltip position="bottom" content={"List View"}>
                            <Button
                                icon="list-columns"
                                onClick={() => setView("list")}
                                active={view === "list"}
                            />
                        </Tooltip>
                        <Tooltip position="bottom" content={"Upload Files"}>
                            <Button
                                icon="cloud-upload"
                                text="Upload"
                                className="bp3-intent-primary"
                                onClick={triggerFileInput}
                            />
                        </Tooltip>
                        <Tooltip position="bottom" content={"View Personal Files"}>
                            <Button
                                icon="person"
                                text="my files"
                                onClick={() => setActiveTab("myFiles")}
                                active={activeTab === "myFiles"}
                            />
                        </Tooltip>
                        <Tooltip position="bottom" content={"View Files Shared with you"}>
                            <Button
                                icon="folder-shared"
                                text="shared-file"
                                onClick={() => setActiveTab("sharedFiles")}
                                active={activeTab === "sharedFiles"}
                            />
                        </Tooltip>
                        <input
                            id="fileInput"
                            type="file"
                            accept=".e57,.idsv,.obj,.stl,.ply,.fbx,.3ds,.gltf,.glb,.doc,.pdf,.docx,.las,.rvt,.rfa,.dxf,.rft,.rte,.dgn,.dwf,.dwg,.ifc,.ppt,.pptx,.pps,.ppsx,.pot,.potx,"
                            style={{ display: "none" }}
                            onChange={handleFileUpload}
                            multiple
                        />
                    </div>
                    <div className="right-controls">
                        <InputGroup
                            id="fileSearch"
                            name="fileSearch"
                            placeholder="Search"
                            leftIcon="search"
                            className="input-field"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <p style={{ fontSize: "16px", color: "black", padding: "5px", margin: "0" }}>Select All</p>
                            <Tooltip position="bottom" content={"Select All Files"}>
                                <Checkbox
                                    style={{ margin: "0", alignSelf: "center" }}
                                    label="Select All"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleSelectAll(e.target.checked)
                                    }
                                />
                            </Tooltip>
                        </div>

                        {isFilter && (
                            <select onChange={handleFilterChange} value={fileTypeFilter}>
                                <option value="">All</option>
                                {supported3DFormats.map(format => (
                                    <option key={format} value={format}>{format.toUpperCase()}</option>
                                ))}
                            </select>
                        )}
                        {/* <Tooltip position="bottom" content={"Filter Files"}>
                            <Button icon="filter" onClick={handleFilterClick} />
                        </Tooltip> */}
                        <Tooltip position="bottom" content={"Download Files"}>
                            <Button className="bp3-intent-success" icon="download" onClick={handleDownloadSelectedFiles} />
                        </Tooltip>
                        <Tooltip position="bottom" content={"Share Files"}>
                            <Button className="bp3-intent-warning" icon="document-share" onClick={() => setIsDialogOpen(true)} />
                        </Tooltip>
                        <Tooltip position="bottom" content={"Delete Files"}>
                            <Button className="bp3-intent-danger" icon="delete" onClick={handleDeleteFile} />
                        </Tooltip>
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className="progress-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', width: '100%' }}>
                    <ProgressBar value={uploadProgress / 100} intent="primary" style={{ width: '100%', height: '20px' }} />
                    {remainingTime !== null && (
                    <div className="remaining-time" style={{ fontSize: '14px', color: '#555', marginTop: '5px', textAlign: 'center' }}>
                        Estimated time remaining: {remainingTime} seconds
                    </div>
                    )}
                </div>
                )}

            <div className="file-viewer">
                <div className={`file-list ${view === "grid" ? "grid-view" : "list-view"}`}>
                    {activeTab === "myFiles" && filesToShow.map(file => (
                        <div key={file.id} className="file-item">
                            <Icon icon="document" />
                            <div className="file-info">
                                <h3>{file.name}</h3>
                                <p>{file.type} - {file.size} MB</p>
                            </div>
                            <Checkbox
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    handleCheckboxChange(file.id, e.target.checked)}
                                checked={selectedFileIds.includes(file.id)}
                            />
                        </div>
                    ))}
                    {activeTab === "sharedFiles" && sharedFilesToShow.length > 0 ? (
                        sharedFilesToShow.map(file => (
                            <div key={file.id} className="file-item">
                                <Icon icon="document" />
                                <div className="file-info">
                                    <h3>{file.name}</h3>
                                    <p>{file.type} - {file.size} MB</p>
                                </div>
                                <Checkbox
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        handleCheckboxChange(file.id, e.target.checked)}
                                    checked={selectedFileIds.includes(file.id)}
                                />
                                <Button text="Add to My Files" onClick={() => handleAddtoMyFile(file.id)} />
                            </div>
                        ))
                    ) : null}
                    {activeTab === "sharedFiles" && sharedFilesToShow.length === 0 ? (
                        <div className="no-files-message">
                            No files shared with you
                        </div>
                    ) : null}
                    {activeTab === "myFiles" && filesToShow.length === 0 ? (
                        <div className="no-files-message">
                            No files available
                        </div>
                    ) : null}
                </div>
                <div className="file-pagination">
                    {filesToShow.length > 0 && (
                        <>
                            <Button icon="chevron-left" disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)} />
                            <span>{currentPage} / {totalPages}</span>
                            <Button icon="chevron-right" disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)} />
                        </>
                    )}
                </div>
            </div>
            <Dialog
                isOpen={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                title="Share File"
            >
                <div className="bp3-dialog-body">
                    <FormGroup
                        label="Enter the email to share the file:"
                        labelFor="email-input"
                    >
                        <InputGroup
                            id="email-input"
                            placeholder="Email"
                            value={email}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                        />
                    </FormGroup>
                </div>
                <div className="bp3-dialog-footer">
                    <Button text="Cancel" onClick={() => setIsDialogOpen(false)} />
                    <Button
                        text="OK"
                        intent="primary"
                        onClick={handleShareFile}
                    />
                </div>
            </Dialog>
            {isLoading && (
                <div className="loading-overlay">
                    <Spinner />
                </div>
            )}
        </div>
    );
}
