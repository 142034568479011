import * as THREE from 'three';
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter.js';

class CustomOBJExporter extends OBJExporter {
    constructor() {
        super();
    }

    sanitizeName(name) {
        const sanitized = name.replace(/[^a-zA-Z0-9_]/g, '_');
        console.log(`Sanitized name: ${sanitized}`); 
        return sanitized;
    }

    parse(object) {
        let output = '';

        let indexVertex = 0;
        let indexVertexUvs = 0;
        let indexNormals = 0;

        const vertex = new THREE.Vector3();
        const normal = new THREE.Vector3();
        const uv = new THREE.Vector2();

        const parseMesh = (mesh) => {
            let nbVertex = 0;
            let nbNormals = 0;
            let nbVertexUvs = 0;

            let geometry = mesh.geometry;
            const normalMatrixWorld = new THREE.Matrix3();

            if (geometry instanceof THREE.Geometry) {
                geometry = new THREE.BufferGeometry().setFromObject(mesh);
            }

            if (geometry instanceof THREE.BufferGeometry) {
                const vertices = geometry.getAttribute('position');
                const normals = geometry.getAttribute('normal');
                const uvs = geometry.getAttribute('uv');
                const indices = geometry.getIndex();

                const sanitizedMeshName = this.sanitizeName(mesh.name);
                output += 'o ' + sanitizedMeshName + '\n';

                if (mesh.material && mesh.material.name) {
                    const sanitizedMaterialName = this.sanitizeName(mesh.material.name);
                    output += 'usemtl ' + sanitizedMaterialName + '\n';
                }

                if (vertices !== undefined && vertices !== null && vertices.count > 0) {
                    for (let i = 0, l = vertices.count; i < l; i++, nbVertex++) {
                        vertex.x = vertices.getX(i);
                        vertex.y = vertices.getY(i);
                        vertex.z = vertices.getZ(i);

                        vertex.applyMatrix4(mesh.matrixWorld);
                        output += 'v ' + vertex.x + ' ' + vertex.y + ' ' + vertex.z + '\n';
                    }
                } else {
                    console.warn('No vertices found in mesh geometry:', geometry);
                }

                if (uvs !== undefined && uvs !== null && uvs.count > 0) {
                    for (let i = 0, l = uvs.count; i < l; i++, nbVertexUvs++) {
                        uv.x = uvs.getX(i);
                        uv.y = uvs.getY(i);
                        output += 'vt ' + uv.x + ' ' + uv.y + '\n';
                    }
                } else {
                    console.warn('No UVs found in mesh geometry:', geometry);
                }

                if (normals !== undefined && normals !== null && normals.count > 0) {
                    normalMatrixWorld.getNormalMatrix(mesh.matrixWorld);

                    for (let i = 0, l = normals.count; i < l; i++, nbNormals++) {
                        normal.x = normals.getX(i);
                        normal.y = normals.getY(i);
                        normal.z = normals.getZ(i);

                        normal.applyMatrix3(normalMatrixWorld).normalize();
                        output += 'vn ' + normal.x + ' ' + normal.y + ' ' + normal.z + '\n';
                    }
                } else {
                    console.warn('No normals found in mesh geometry:', geometry);
                }

                if (indices !== null && indices !== undefined) {
                    for (let i = 0, l = indices.count; i < l; i += 3) {
                        const face = [];

                        for (let m = 0; m < 3; m++) {
                            const j = indices.getX(i + m) + 1;
                            face[m] = (indexVertex + j) + (normals || uvs ? '/' + (uvs ? (indexVertexUvs + j) : '') + (normals ? '/' + (indexNormals + j) : '') : '');
                        }

                        output += 'f ' + face.join(' ') + "\n";
                    }
                } else if (vertices !== undefined && vertices !== null && vertices.count > 0) {
                    for (let i = 0, l = vertices.count; i < l; i += 3) {
                        const face = [];

                        for (let m = 0; m < 3; m++) {
                            const j = i + m + 1;
                            face[m] = (indexVertex + j) + (normals || uvs ? '/' + (uvs ? (indexVertexUvs + j) : '') + (normals ? '/' + (indexNormals + j) : '') : '');
                        }

                        output += 'f ' + face.join(' ') + "\n";
                    }
                } else {
                    console.warn('No indices or vertices found in mesh geometry:', geometry);
                }
            } else {
                console.warn('THREE.OBJExporter.parseMesh(): geometry type unsupported', geometry);
            }

            indexVertex += nbVertex;
            indexVertexUvs += nbVertexUvs;
            indexNormals += nbNormals;
        };

        const parseLine = (line) => {
            let nbVertex = 0;

            let geometry = line.geometry;
            const type = line.type;

            if (geometry instanceof THREE.Geometry) {
                geometry = new THREE.BufferGeometry().setFromObject(line);
            }

            if (geometry instanceof THREE.BufferGeometry) {
                const vertices = geometry.getAttribute('position');
                const sanitizedLineName = this.sanitizeName(line.name);
                output += 'o ' + sanitizedLineName + '\n';

                if (vertices !== undefined && vertices !== null && vertices.count > 0) {
                    for (let i = 0, l = vertices.count; i < l; i++, nbVertex++) {
                        vertex.x = vertices.getX(i);
                        vertex.y = vertices.getY(i);
                        vertex.z = vertices.getZ(i);

                        vertex.applyMatrix4(line.matrixWorld);
                        output += 'v ' + vertex.x + ' ' + vertex.y + ' ' + vertex.z + '\n';
                    }
                } else {
                    console.warn('No vertices found in line geometry:', geometry);
                }

                if (type === 'Line') {
                    output += 'l ';
                    for (let j = 1, l = vertices.count; j <= l; j++) {
                        output += (indexVertex + j) + ' ';
                    }
                    output += '\n';
                }

                if (type === 'LineSegments') {
                    for (let j = 1, k = j + 1, l = vertices.count; j < l; j += 2, k = j + 1) {
                        output += 'l ' + (indexVertex + j) + ' ' + (indexVertex + k) + '\n';
                    }
                }
            } else {
                console.warn('THREE.OBJExporter.parseLine(): geometry type unsupported', geometry);
            }

            indexVertex += nbVertex;
        };

        object.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                parseMesh(child);
            }
            if (child instanceof THREE.Line) {
                parseLine(child);
            }
        });

        console.log(output);  
        return output;
    }
}

export { CustomOBJExporter };
