import { fixValueToNumber } from "../../../components/3d-models/utils";

export const pipingSS = [
  {
    piping_details_id: 1,
    nominal_pipe_size_inch: "1/8",
    outside_diameter_inch: "0,405",
    outside_diameter_mm: "10,3",
    wall_thickness_inch: "0,049",
    wall_thickness_mm: "1,24",
    weight_lbpft: "0,19",
    weight_kgpm: "0,28",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 2,
    nominal_pipe_size_inch: "1/8",
    outside_diameter_inch: "0,405",
    outside_diameter_mm: "10,3",
    wall_thickness_inch: "0,049",
    wall_thickness_mm: "1,24",
    weight_lbpft: "0,19",
    weight_kgpm: "0,28",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 3,
    nominal_pipe_size_inch: "1/8",
    outside_diameter_inch: "0,405",
    outside_diameter_mm: "10,3",
    wall_thickness_inch: "0,057",
    wall_thickness_mm: "1,45",
    weight_lbpft: "0,21",
    weight_kgpm: "0,32",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 4,
    nominal_pipe_size_inch: "1/8",
    outside_diameter_inch: "0,405",
    outside_diameter_mm: "10,3",
    wall_thickness_inch: "0,057",
    wall_thickness_mm: "1,45",
    weight_lbpft: "0,21",
    weight_kgpm: "0,32",
    specification: "-",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 5,
    nominal_pipe_size_inch: "1/8",
    outside_diameter_inch: "0,405",
    outside_diameter_mm: "10,3",
    wall_thickness_inch: "0,068",
    wall_thickness_mm: "1,73",
    weight_lbpft: "0,24",
    weight_kgpm: "0,37",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 6,
    nominal_pipe_size_inch: "1/8",
    outside_diameter_inch: "0,405",
    outside_diameter_mm: "10,3",
    wall_thickness_inch: "0,068",
    wall_thickness_mm: "1,73",
    weight_lbpft: "0,24",
    weight_kgpm: "0,37",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 7,
    nominal_pipe_size_inch: "1/8",
    outside_diameter_inch: "0,405",
    outside_diameter_mm: "10,3",
    wall_thickness_inch: "0,095",
    wall_thickness_mm: "2,41",
    weight_lbpft: "0,31",
    weight_kgpm: "0,47",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 8,
    nominal_pipe_size_inch: "1/8",
    outside_diameter_inch: "0,405",
    outside_diameter_mm: "10,3",
    wall_thickness_inch: "0,095",
    wall_thickness_mm: "2,41",
    weight_lbpft: "0,31",
    weight_kgpm: "0,47",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 9,
    nominal_pipe_size_inch: "1/4",
    outside_diameter_inch: "0,54",
    outside_diameter_mm: "13,7",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "0,33",
    weight_kgpm: "0,49",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 10,
    nominal_pipe_size_inch: "1/4",
    outside_diameter_inch: "0,54",
    outside_diameter_mm: "13,7",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "0,33",
    weight_kgpm: "0,49",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 11,
    nominal_pipe_size_inch: "1/4",
    outside_diameter_inch: "0,54",
    outside_diameter_mm: "13,7",
    wall_thickness_inch: "0,073",
    wall_thickness_mm: "1,85",
    weight_lbpft: "0,36",
    weight_kgpm: "0,54",
    specification: "-",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 12,
    nominal_pipe_size_inch: "1/4",
    outside_diameter_inch: "0,54",
    outside_diameter_mm: "13,7",
    wall_thickness_inch: "0,073",
    wall_thickness_mm: "1,85",
    weight_lbpft: "0,36",
    weight_kgpm: "0,54",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 13,
    nominal_pipe_size_inch: "1/4",
    outside_diameter_inch: "0,54",
    outside_diameter_mm: "13,7",
    wall_thickness_inch: "0,088",
    wall_thickness_mm: "2,24",
    weight_lbpft: "0,42",
    weight_kgpm: "0,63",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 14,
    nominal_pipe_size_inch: "1/4",
    outside_diameter_inch: "0,54",
    outside_diameter_mm: "13,7",
    wall_thickness_inch: "0,088",
    wall_thickness_mm: "2,24",
    weight_lbpft: "0,42",
    weight_kgpm: "0,63",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 15,
    nominal_pipe_size_inch: "1/4",
    outside_diameter_inch: "0,54",
    outside_diameter_mm: "13,7",
    wall_thickness_inch: "0,119",
    wall_thickness_mm: "3,02",
    weight_lbpft: "0,54",
    weight_kgpm: "0,8",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 16,
    nominal_pipe_size_inch: "1/4",
    outside_diameter_inch: "0,54",
    outside_diameter_mm: "13,7",
    wall_thickness_inch: "0,119",
    wall_thickness_mm: "3,02",
    weight_lbpft: "0,54",
    weight_kgpm: "0,8",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 17,
    nominal_pipe_size_inch: "3/8",
    outside_diameter_inch: "0,675",
    outside_diameter_mm: "17,1",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "0,42",
    weight_kgpm: "0,63",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 18,
    nominal_pipe_size_inch: "3/8",
    outside_diameter_inch: "0,675",
    outside_diameter_mm: "17,1",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "0,42",
    weight_kgpm: "0,63",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 19,
    nominal_pipe_size_inch: "3/8",
    outside_diameter_inch: "0,675",
    outside_diameter_mm: "17,1",
    wall_thickness_inch: "0,073",
    wall_thickness_mm: "1,85",
    weight_lbpft: "0,47",
    weight_kgpm: "0,7",
    specification: "-",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 20,
    nominal_pipe_size_inch: "3/8",
    outside_diameter_inch: "0,675",
    outside_diameter_mm: "17,1",
    wall_thickness_inch: "0,073",
    wall_thickness_mm: "1,85",
    weight_lbpft: "0,47",
    weight_kgpm: "0,7",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 21,
    nominal_pipe_size_inch: "3/8",
    outside_diameter_inch: "0,675",
    outside_diameter_mm: "17,1",
    wall_thickness_inch: "0,091",
    wall_thickness_mm: "2,31",
    weight_lbpft: "0,57",
    weight_kgpm: "0,84",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 22,
    nominal_pipe_size_inch: "3/8",
    outside_diameter_inch: "0,675",
    outside_diameter_mm: "17,1",
    wall_thickness_inch: "0,091",
    wall_thickness_mm: "2,31",
    weight_lbpft: "0,57",
    weight_kgpm: "0,84",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 23,
    nominal_pipe_size_inch: "3/8",
    outside_diameter_inch: "0,675",
    outside_diameter_mm: "17,1",
    wall_thickness_inch: "0,126",
    wall_thickness_mm: "3,2",
    weight_lbpft: "0,74",
    weight_kgpm: "1,1",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 24,
    nominal_pipe_size_inch: "3/8",
    outside_diameter_inch: "0,675",
    outside_diameter_mm: "17,1",
    wall_thickness_inch: "0,126",
    wall_thickness_mm: "3,2",
    weight_lbpft: "0,74",
    weight_kgpm: "1,1",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 25,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "0,54",
    weight_kgpm: "0,8",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 26,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "0,54",
    weight_kgpm: "0,8",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 27,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,083",
    wall_thickness_mm: "2,11",
    weight_lbpft: "0,67",
    weight_kgpm: 1,
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 28,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,083",
    wall_thickness_mm: "2,11",
    weight_lbpft: "0,67",
    weight_kgpm: 1,
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 29,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,095",
    wall_thickness_mm: "2,41",
    weight_lbpft: "0,76",
    weight_kgpm: "1,12",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 30,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,095",
    wall_thickness_mm: "2,41",
    weight_lbpft: "0,76",
    weight_kgpm: "1,12",
    specification: "-",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 31,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "0,85",
    weight_kgpm: "1,27",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 32,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "0,85",
    weight_kgpm: "1,27",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 33,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,147",
    wall_thickness_mm: "3,73",
    weight_lbpft: "1,09",
    weight_kgpm: "1,62",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 34,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,147",
    wall_thickness_mm: "3,73",
    weight_lbpft: "1,09",
    weight_kgpm: "1,62",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 35,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "1,31",
    weight_kgpm: "1,95",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 36,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "1,31",
    weight_kgpm: "1,95",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 37,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,294",
    wall_thickness_mm: "7,47",
    weight_lbpft: "1,71",
    weight_kgpm: "2,55",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 38,
    nominal_pipe_size_inch: "1/2",
    outside_diameter_inch: "0,84",
    outside_diameter_mm: "21,3",
    wall_thickness_inch: "0,294",
    wall_thickness_mm: "7,47",
    weight_lbpft: "1,71",
    weight_kgpm: "2,55",
    specification: "5L",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 39,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "0,69",
    weight_kgpm: "1,03",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 40,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "0,69",
    weight_kgpm: "1,03",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 41,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,083",
    wall_thickness_mm: "2,11",
    weight_lbpft: "0,86",
    weight_kgpm: "1,28",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 42,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,083",
    wall_thickness_mm: "2,11",
    weight_lbpft: "0,86",
    weight_kgpm: "1,28",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 43,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,095",
    wall_thickness_mm: "2,41",
    weight_lbpft: "0,97",
    weight_kgpm: "1,44",
    specification: "-",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 44,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,095",
    wall_thickness_mm: "2,41",
    weight_lbpft: "0,97",
    weight_kgpm: "1,44",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 45,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,113",
    wall_thickness_mm: "2,87",
    weight_lbpft: "1,13",
    weight_kgpm: "1,69",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 46,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,113",
    wall_thickness_mm: "2,87",
    weight_lbpft: "1,13",
    weight_kgpm: "1,69",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 47,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,154",
    wall_thickness_mm: "3,91",
    weight_lbpft: "1,47",
    weight_kgpm: "2,2",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 48,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,154",
    wall_thickness_mm: "3,91",
    weight_lbpft: "1,47",
    weight_kgpm: "2,2",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 49,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "1,94",
    weight_kgpm: "2,9",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 50,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "1,94",
    weight_kgpm: "2,9",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 51,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,308",
    wall_thickness_mm: "7,82",
    weight_lbpft: "2,44",
    weight_kgpm: "3,64",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 52,
    nominal_pipe_size_inch: "3/4",
    outside_diameter_inch: "1,05",
    outside_diameter_mm: "26,7",
    wall_thickness_inch: "0,308",
    wall_thickness_mm: "7,82",
    weight_lbpft: "2,44",
    weight_kgpm: "3,64",
    specification: "5L",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 53,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "0,87",
    weight_kgpm: "1,3",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 54,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "0,87",
    weight_kgpm: "1,3",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 55,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "1,4",
    weight_kgpm: "2,09",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 56,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "1,4",
    weight_kgpm: "2,09",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 57,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,114",
    wall_thickness_mm: "2,9",
    weight_lbpft: "1,46",
    weight_kgpm: "2,18",
    specification: "-",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 58,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,114",
    wall_thickness_mm: "2,9",
    weight_lbpft: "1,46",
    weight_kgpm: "2,18",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 59,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,133",
    wall_thickness_mm: "3,38",
    weight_lbpft: "1,68",
    weight_kgpm: "2,5",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 60,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,133",
    wall_thickness_mm: "3,38",
    weight_lbpft: "1,68",
    weight_kgpm: "2,5",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 61,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,179",
    wall_thickness_mm: "4,55",
    weight_lbpft: "2,17",
    weight_kgpm: "3,24",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 62,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,179",
    wall_thickness_mm: "4,55",
    weight_lbpft: "2,17",
    weight_kgpm: "3,24",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 63,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "2,84",
    weight_kgpm: "4,24",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 64,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "2,84",
    weight_kgpm: "4,24",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 65,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,358",
    wall_thickness_mm: "9,09",
    weight_lbpft: "3,66",
    weight_kgpm: "5,45",
    specification: "5L",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 66,
    nominal_pipe_size_inch: "1",
    outside_diameter_inch: "1,315",
    outside_diameter_mm: "33,4",
    wall_thickness_inch: "0,358",
    wall_thickness_mm: "9,09",
    weight_lbpft: "3,66",
    weight_kgpm: "5,45",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 67,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "1,11",
    weight_kgpm: "1,65",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 68,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "1,11",
    weight_kgpm: "1,65",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 69,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "1,81",
    weight_kgpm: "2,7",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 70,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "1,81",
    weight_kgpm: "2,7",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 71,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,117",
    wall_thickness_mm: "2,97",
    weight_lbpft: "1,93",
    weight_kgpm: "2,87",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 72,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,117",
    wall_thickness_mm: "2,97",
    weight_lbpft: "1,93",
    weight_kgpm: "2,87",
    specification: "-",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 73,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,14",
    wall_thickness_mm: "3,56",
    weight_lbpft: "2,27",
    weight_kgpm: "3,39",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 74,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,14",
    wall_thickness_mm: "3,56",
    weight_lbpft: "2,27",
    weight_kgpm: "3,39",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 75,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,191",
    wall_thickness_mm: "4,85",
    weight_lbpft: 3,
    weight_kgpm: "4,47",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 76,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,191",
    wall_thickness_mm: "4,85",
    weight_lbpft: 3,
    weight_kgpm: "4,47",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 77,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "3,76",
    weight_kgpm: "5,61",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 78,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "3,76",
    weight_kgpm: "5,61",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 79,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,382",
    wall_thickness_mm: "9,7",
    weight_lbpft: "5,21",
    weight_kgpm: "7,77",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 80,
    nominal_pipe_size_inch: "1 1/4",
    outside_diameter_inch: "1,66",
    outside_diameter_mm: "42,2",
    wall_thickness_inch: "0,382",
    wall_thickness_mm: "9,7",
    weight_lbpft: "5,21",
    weight_kgpm: "7,77",
    specification: "5L",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 81,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "1,28",
    weight_kgpm: "1,91",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 82,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "1,28",
    weight_kgpm: "1,91",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 83,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "2,09",
    weight_kgpm: "3,11",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 84,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "2,09",
    weight_kgpm: "3,11",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 85,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,125",
    wall_thickness_mm: "3,18",
    weight_lbpft: "2,37",
    weight_kgpm: "3,53",
    specification: "-",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 86,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,125",
    wall_thickness_mm: "3,18",
    weight_lbpft: "2,37",
    weight_kgpm: "3,53",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 87,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,145",
    wall_thickness_mm: "3,68",
    weight_lbpft: "2,72",
    weight_kgpm: "4,05",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 88,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,145",
    wall_thickness_mm: "3,68",
    weight_lbpft: "2,72",
    weight_kgpm: "4,05",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 89,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,2",
    wall_thickness_mm: "5,08",
    weight_lbpft: "3,63",
    weight_kgpm: "5,41",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 90,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,2",
    wall_thickness_mm: "5,08",
    weight_lbpft: "3,63",
    weight_kgpm: "5,41",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 91,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "4,86",
    weight_kgpm: "7,25",
    specification: "-",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 92,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "4,86",
    weight_kgpm: "7,25",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 93,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,4",
    wall_thickness_mm: "10,15",
    weight_lbpft: "6,41",
    weight_kgpm: "9,56",
    specification: "5L",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 94,
    nominal_pipe_size_inch: "1 1/2",
    outside_diameter_inch: "1,9",
    outside_diameter_mm: "48,3",
    wall_thickness_inch: "0,4",
    wall_thickness_mm: "10,15",
    weight_lbpft: "6,41",
    weight_kgpm: "9,56",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 95,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,065",
    wall_thickness_mm: "1,65",
    weight_lbpft: "1,61",
    weight_kgpm: "2,4",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 96,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,083",
    wall_thickness_mm: "2,11",
    weight_lbpft: "2,03",
    weight_kgpm: "3,03",
    specification: "5L",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 97,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "2,64",
    weight_kgpm: "3,93",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 98,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,125",
    wall_thickness_mm: "3,18",
    weight_lbpft: 3,
    weight_kgpm: "4,48",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 99,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,141",
    wall_thickness_mm: "3,58",
    weight_lbpft: "3,36",
    weight_kgpm: "5,01",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 100,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,154",
    wall_thickness_mm: "3,91",
    weight_lbpft: "3,65",
    weight_kgpm: "5,44",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 101,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,154",
    wall_thickness_mm: "3,91",
    weight_lbpft: "3,65",
    weight_kgpm: "5,44",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 102,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,172",
    wall_thickness_mm: "4,37",
    weight_lbpft: "4,05",
    weight_kgpm: "6,03",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 103,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "4,39",
    weight_kgpm: "6,54",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 104,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,218",
    wall_thickness_mm: "5,54",
    weight_lbpft: "5,02",
    weight_kgpm: "7,48",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 105,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,218",
    wall_thickness_mm: "5,54",
    weight_lbpft: "5,02",
    weight_kgpm: "7,48",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 106,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "5,67",
    weight_kgpm: "8,45",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 107,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "6,28",
    weight_kgpm: "9,36",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 108,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "7,46",
    weight_kgpm: "11,11",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 109,
    nominal_pipe_size_inch: 2,
    outside_diameter_inch: "2,375",
    outside_diameter_mm: "60,3",
    wall_thickness_inch: "0,436",
    wall_thickness_mm: "11,07",
    weight_lbpft: "9,03",
    weight_kgpm: "13,44",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 110,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,083",
    wall_thickness_mm: "2,11",
    weight_lbpft: "2,47",
    weight_kgpm: "3,69",
    specification: "5L",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 111,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "3,22",
    weight_kgpm: "4,8",
    specification: "5L",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 112,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,12",
    wall_thickness_mm: "3,05",
    weight_lbpft: "3,53",
    weight_kgpm: "5,26",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 113,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,125",
    wall_thickness_mm: "3,18",
    weight_lbpft: "3,67",
    weight_kgpm: "5,48",
    specification: "5L",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 114,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,141",
    wall_thickness_mm: "3,58",
    weight_lbpft: "4,12",
    weight_kgpm: "6,13",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 115,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,156",
    wall_thickness_mm: "3,96",
    weight_lbpft: "4,53",
    weight_kgpm: "6,74",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 116,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,172",
    wall_thickness_mm: "4,37",
    weight_lbpft: "4,97",
    weight_kgpm: "7,4",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 117,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "5,4",
    weight_kgpm: "8,04",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 118,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,203",
    wall_thickness_mm: "5,16",
    weight_lbpft: "5,79",
    weight_kgpm: "8,63",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 119,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,203",
    wall_thickness_mm: "5,16",
    weight_lbpft: "5,79",
    weight_kgpm: "8,63",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 120,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,216",
    wall_thickness_mm: "5,49",
    weight_lbpft: "6,13",
    weight_kgpm: "9,14",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 121,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "7,01",
    weight_kgpm: "10,44",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 122,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,276",
    wall_thickness_mm: "7,01",
    weight_lbpft: "7,66",
    weight_kgpm: "11,41",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 123,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,276",
    wall_thickness_mm: "7,01",
    weight_lbpft: "7,66",
    weight_kgpm: "11,41",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 124,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "10,01",
    weight_kgpm: "14,92",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 125,
    nominal_pipe_size_inch: "2 1/2",
    outside_diameter_inch: "2,875",
    outside_diameter_mm: 73,
    wall_thickness_inch: "0,552",
    wall_thickness_mm: "14,02",
    weight_lbpft: "13,69",
    weight_kgpm: "20,39",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 126,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,083",
    wall_thickness_mm: "2,11",
    weight_lbpft: "3,03",
    weight_kgpm: "4,52",
    specification: "5L",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 127,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "3,95",
    weight_kgpm: "5,88",
    specification: "5L",
    schedule: "-",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 128,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,12",
    wall_thickness_mm: "3,05",
    weight_lbpft: "4,33",
    weight_kgpm: "6,45",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 129,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,125",
    wall_thickness_mm: "3,18",
    weight_lbpft: "4,51",
    weight_kgpm: "6,72",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 130,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,141",
    wall_thickness_mm: "3,58",
    weight_lbpft: "5,06",
    weight_kgpm: "7,53",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 131,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,156",
    wall_thickness_mm: "3,96",
    weight_lbpft: "5,57",
    weight_kgpm: "8,29",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 132,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,172",
    wall_thickness_mm: "4,37",
    weight_lbpft: "6,11",
    weight_kgpm: "9,11",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 133,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "6,65",
    weight_kgpm: "9,92",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 134,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,216",
    wall_thickness_mm: "5,49",
    weight_lbpft: "7,58",
    weight_kgpm: "11,29",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 135,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,216",
    wall_thickness_mm: "5,49",
    weight_lbpft: "7,58",
    weight_kgpm: "11,29",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 136,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "8,68",
    weight_kgpm: "12,93",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 137,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "9,66",
    weight_kgpm: "14,4",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 138,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,3",
    wall_thickness_mm: "7,62",
    weight_lbpft: "10,25",
    weight_kgpm: "15,27",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 139,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,3",
    wall_thickness_mm: "7,62",
    weight_lbpft: "10,25",
    weight_kgpm: "15,27",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 140,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "14,32",
    weight_kgpm: "21,35",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 141,
    nominal_pipe_size_inch: 3,
    outside_diameter_inch: "3,5",
    outside_diameter_mm: "88,9",
    wall_thickness_inch: "0,6",
    wall_thickness_mm: "15,24",
    weight_lbpft: "18,58",
    weight_kgpm: "27,68",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 142,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,083",
    wall_thickness_mm: "2,11",
    weight_lbpft: "3,47",
    weight_kgpm: "5,18",
    specification: "5L",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 143,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "4,53",
    weight_kgpm: "6,75",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 144,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,12",
    wall_thickness_mm: "3,05",
    weight_lbpft: "4,97",
    weight_kgpm: "7,4",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 145,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,125",
    wall_thickness_mm: "3,18",
    weight_lbpft: "5,17",
    weight_kgpm: "7,72",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 146,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,141",
    wall_thickness_mm: "3,58",
    weight_lbpft: "5,81",
    weight_kgpm: "8,65",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 147,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,156",
    wall_thickness_mm: "3,96",
    weight_lbpft: "6,4",
    weight_kgpm: "9,53",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 148,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,172",
    wall_thickness_mm: "4,37",
    weight_lbpft: "7,03",
    weight_kgpm: "10,48",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 149,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "7,65",
    weight_kgpm: "11,41",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 150,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,226",
    wall_thickness_mm: "5,74",
    weight_lbpft: "9,11",
    weight_kgpm: "13,57",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 151,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,226",
    wall_thickness_mm: "5,74",
    weight_lbpft: "9,11",
    weight_kgpm: "13,57",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 152,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "10,01",
    weight_kgpm: "14,92",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 153,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "11,16",
    weight_kgpm: "16,63",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 154,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,318",
    wall_thickness_mm: "8,08",
    weight_lbpft: "12,5",
    weight_kgpm: "18,63",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 155,
    nominal_pipe_size_inch: "3 1/2",
    outside_diameter_inch: 4,
    outside_diameter_mm: "101,6",
    wall_thickness_inch: "0,318",
    wall_thickness_mm: "8,08",
    weight_lbpft: "12,5",
    weight_kgpm: "18,63",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 156,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,083",
    wall_thickness_mm: "2,11",
    weight_lbpft: "3,92",
    weight_kgpm: "5,84",
    specification: "5L",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 157,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "5,11",
    weight_kgpm: "7,62",
    specification: "-",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 158,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,12",
    wall_thickness_mm: "3,05",
    weight_lbpft: "5,61",
    weight_kgpm: "8,36",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 159,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,125",
    wall_thickness_mm: "3,18",
    weight_lbpft: "5,84",
    weight_kgpm: "8,71",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 160,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,141",
    wall_thickness_mm: "3,58",
    weight_lbpft: "6,56",
    weight_kgpm: "9,77",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 161,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,156",
    wall_thickness_mm: "3,96",
    weight_lbpft: "7,24",
    weight_kgpm: "10,78",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 162,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,172",
    wall_thickness_mm: "4,37",
    weight_lbpft: "7,95",
    weight_kgpm: "11,85",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 163,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "8,66",
    weight_kgpm: "12,91",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 164,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,203",
    wall_thickness_mm: "5,16",
    weight_lbpft: "9,32",
    weight_kgpm: "13,89",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 165,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "10,01",
    weight_kgpm: "14,91",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 166,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,237",
    wall_thickness_mm: "6,02",
    weight_lbpft: "10,79",
    weight_kgpm: "16,07",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 167,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,237",
    wall_thickness_mm: "6,02",
    weight_lbpft: "10,79",
    weight_kgpm: "16,07",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 168,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "11,35",
    weight_kgpm: "16,9",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 169,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "12,66",
    weight_kgpm: "18,87",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 170,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "13,96",
    weight_kgpm: "20,78",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 171,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,337",
    wall_thickness_mm: "8,56",
    weight_lbpft: "14,98",
    weight_kgpm: "22,32",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 172,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,337",
    wall_thickness_mm: "8,56",
    weight_lbpft: "14,98",
    weight_kgpm: "22,32",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 173,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: 19,
    weight_kgpm: "28,32",
    specification: "5L",
    schedule: 120,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 174,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,531",
    wall_thickness_mm: "13,49",
    weight_lbpft: "22,51",
    weight_kgpm: "33,54",
    specification: "5L",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 175,
    nominal_pipe_size_inch: 4,
    outside_diameter_inch: "4,5",
    outside_diameter_mm: "114,3",
    wall_thickness_inch: "0,674",
    wall_thickness_mm: "17,12",
    weight_lbpft: "27,54",
    weight_kgpm: "41,03",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 176,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,083",
    wall_thickness_mm: "2,11",
    weight_lbpft: "4,86",
    weight_kgpm: "7,24",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 177,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "6,36",
    weight_kgpm: "9,47",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 178,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,125",
    wall_thickness_mm: "3,18",
    weight_lbpft: "7,26",
    weight_kgpm: "10,83",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 179,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,134",
    wall_thickness_mm: "3,4",
    weight_lbpft: "7,77",
    weight_kgpm: "11,57",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 180,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,156",
    wall_thickness_mm: "3,96",
    weight_lbpft: "9,01",
    weight_kgpm: "13,41",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 181,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "10,79",
    weight_kgpm: "16,09",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 182,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "12,5",
    weight_kgpm: "18,61",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 183,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,258",
    wall_thickness_mm: "6,55",
    weight_lbpft: "14,62",
    weight_kgpm: "21,77",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 184,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,258",
    wall_thickness_mm: "6,55",
    weight_lbpft: "14,62",
    weight_kgpm: "21,77",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 185,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "15,85",
    weight_kgpm: "23,62",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 186,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "17,5",
    weight_kgpm: "26,05",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 187,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "19,17",
    weight_kgpm: "28,57",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 188,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "20,78",
    weight_kgpm: "30,97",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 189,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "20,78",
    weight_kgpm: "30,97",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 190,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "27,04",
    weight_kgpm: "40,28",
    specification: "5L",
    schedule: 120,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 191,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "32,96",
    weight_kgpm: "49,11",
    specification: "5L",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 192,
    nominal_pipe_size_inch: 5,
    outside_diameter_inch: "5,563",
    outside_diameter_mm: "141,3",
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "38,55",
    weight_kgpm: "57,43",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 193,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,083",
    wall_thickness_mm: "2,11",
    weight_lbpft: "5,8",
    weight_kgpm: "8,65",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 194,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "7,59",
    weight_kgpm: "11,31",
    specification: "5L",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 195,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,125",
    wall_thickness_mm: "3,18",
    weight_lbpft: "8,68",
    weight_kgpm: "12,95",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 196,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,134",
    wall_thickness_mm: "3,4",
    weight_lbpft: "9,29",
    weight_kgpm: "13,84",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 197,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,141",
    wall_thickness_mm: "3,58",
    weight_lbpft: "9,76",
    weight_kgpm: "14,54",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 198,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,156",
    wall_thickness_mm: "3,96",
    weight_lbpft: "10,78",
    weight_kgpm: "16,05",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 199,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,172",
    wall_thickness_mm: "4,37",
    weight_lbpft: "11,85",
    weight_kgpm: "17,67",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 200,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "12,92",
    weight_kgpm: "19,27",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 201,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,203",
    wall_thickness_mm: "5,16",
    weight_lbpft: "13,92",
    weight_kgpm: "20,76",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 202,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "14,98",
    weight_kgpm: "22,31",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 203,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "17,02",
    weight_kgpm: "25,36",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 204,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,28",
    wall_thickness_mm: "7,11",
    weight_lbpft: "18,97",
    weight_kgpm: "28,26",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 205,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,28",
    wall_thickness_mm: "7,11",
    weight_lbpft: "18,97",
    weight_kgpm: "28,26",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 206,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "21,04",
    weight_kgpm: "31,32",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 207,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "23,08",
    weight_kgpm: "34,39",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 208,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "25,03",
    weight_kgpm: "37,31",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 209,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,432",
    wall_thickness_mm: "10,97",
    weight_lbpft: "28,57",
    weight_kgpm: "42,56",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 210,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,432",
    wall_thickness_mm: "10,97",
    weight_lbpft: "28,57",
    weight_kgpm: "42,56",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 211,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "32,71",
    weight_kgpm: "48,73",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 212,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "36,39",
    weight_kgpm: "54,2",
    specification: "5L",
    schedule: 120,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 213,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "40,05",
    weight_kgpm: "59,69",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 214,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,719",
    wall_thickness_mm: "18,26",
    weight_lbpft: "45,35",
    weight_kgpm: "67,56",
    specification: "5L",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 215,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "47,06",
    weight_kgpm: "70,11",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 216,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,864",
    wall_thickness_mm: "21,95",
    weight_lbpft: "53,16",
    weight_kgpm: "79,22",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 217,
    nominal_pipe_size_inch: 6,
    outside_diameter_inch: "6,625",
    outside_diameter_mm: "168,3",
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "53,73",
    weight_kgpm: "80,07",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 218,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,109",
    wall_thickness_mm: "2,77",
    weight_lbpft: "9,93",
    weight_kgpm: "14,79",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 219,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,125",
    wall_thickness_mm: "3,18",
    weight_lbpft: "11,35",
    weight_kgpm: "16,93",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 220,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,148",
    wall_thickness_mm: "3,76",
    weight_lbpft: "13,4",
    weight_kgpm: "19,96",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 221,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,156",
    wall_thickness_mm: "3,96",
    weight_lbpft: "14,11",
    weight_kgpm: "21,01",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 222,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "16,94",
    weight_kgpm: "25,26",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 223,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,203",
    wall_thickness_mm: "5,16",
    weight_lbpft: "18,26",
    weight_kgpm: "27,22",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 224,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "19,66",
    weight_kgpm: "29,28",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 225,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "22,36",
    weight_kgpm: "33,31",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 226,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,277",
    wall_thickness_mm: "7,04",
    weight_lbpft: "24,7",
    weight_kgpm: "36,81",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 227,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "27,7",
    weight_kgpm: "41,24",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 228,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,322",
    wall_thickness_mm: "8,18",
    weight_lbpft: "28,55",
    weight_kgpm: "42,55",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 229,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,322",
    wall_thickness_mm: "8,18",
    weight_lbpft: "28,55",
    weight_kgpm: "42,55",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 230,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "30,42",
    weight_kgpm: "45,34",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 231,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "33,04",
    weight_kgpm: "49,25",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 232,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "35,64",
    weight_kgpm: "53,08",
    specification: "-",
    schedule: 60,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 233,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "38,3",
    weight_kgpm: "57,08",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 234,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "43,39",
    weight_kgpm: "64,64",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 235,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "43,39",
    weight_kgpm: "64,64",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 236,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "48,4",
    weight_kgpm: "72,08",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 237,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,594",
    wall_thickness_mm: "15,09",
    weight_lbpft: "50,95",
    weight_kgpm: "75,92",
    specification: "-",
    schedule: 100,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 238,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "53,4",
    weight_kgpm: "79,58",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 239,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,719",
    wall_thickness_mm: "18,26",
    weight_lbpft: "60,71",
    weight_kgpm: "90,44",
    specification: "5L",
    schedule: 120,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 240,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "63,08",
    weight_kgpm: "93,98",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 241,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "67,76",
    weight_kgpm: "100,92",
    specification: "5L",
    schedule: 140,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 242,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "72,42",
    weight_kgpm: "107,92",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 243,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: "0,906",
    wall_thickness_mm: "23,01",
    weight_lbpft: "74,69",
    weight_kgpm: "111,27",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 244,
    nominal_pipe_size_inch: 8,
    outside_diameter_inch: "8,625",
    outside_diameter_mm: "219,1",
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "81,44",
    weight_kgpm: "121,33",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 245,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,134",
    wall_thickness_mm: "3,4",
    weight_lbpft: "15,19",
    weight_kgpm: "22,63",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 246,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,156",
    wall_thickness_mm: "3,96",
    weight_lbpft: "17,65",
    weight_kgpm: "26,28",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 247,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,165",
    wall_thickness_mm: "4,19",
    weight_lbpft: "18,65",
    weight_kgpm: "27,78",
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 248,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "21,21",
    weight_kgpm: "31,63",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 249,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,203",
    wall_thickness_mm: "5,16",
    weight_lbpft: "22,87",
    weight_kgpm: "34,09",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 250,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "24,63",
    weight_kgpm: "36,68",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 251,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "28,04",
    weight_kgpm: "41,77",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 252,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,279",
    wall_thickness_mm: "7,09",
    weight_lbpft: "31,2",
    weight_kgpm: "46,51",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 253,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,307",
    wall_thickness_mm: "7,8",
    weight_lbpft: "34,24",
    weight_kgpm: "51,03",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 254,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "38,23",
    weight_kgpm: "56,98",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 255,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,365",
    wall_thickness_mm: "9,27",
    weight_lbpft: "40,48",
    weight_kgpm: "60,31",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 256,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,365",
    wall_thickness_mm: "9,27",
    weight_lbpft: "40,48",
    weight_kgpm: "60,31",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 257,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "48,24",
    weight_kgpm: "71,9",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 258,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "54,74",
    weight_kgpm: "81,55",
    specification: "5L",
    schedule: 60,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 259,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "54,74",
    weight_kgpm: "81,55",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 260,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "61,15",
    weight_kgpm: "91,08",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 261,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,594",
    wall_thickness_mm: "15,09",
    weight_lbpft: "64,43",
    weight_kgpm: "96,01",
    specification: "-",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 262,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "67,58",
    weight_kgpm: "100,73",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 263,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,719",
    wall_thickness_mm: "18,26",
    weight_lbpft: "77,03",
    weight_kgpm: "114,75",
    specification: "5L",
    schedule: 100,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 264,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "86,18",
    weight_kgpm: "128,38",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 265,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,844",
    wall_thickness_mm: "21,44",
    weight_lbpft: "89,29",
    weight_kgpm: "133,06",
    specification: "-",
    schedule: 120,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 266,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "92,28",
    weight_kgpm: "137,52",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 267,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "98,3",
    weight_kgpm: "146,48",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 268,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "104,13",
    weight_kgpm: "155,15",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 269,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "104,13",
    weight_kgpm: "155,15",
    specification: "5L",
    schedule: 140,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 270,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "115,64",
    weight_kgpm: "172,33",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 271,
    nominal_pipe_size_inch: 10,
    outside_diameter_inch: "10,75",
    outside_diameter_mm: 273,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "126,83",
    weight_kgpm: "188,97",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 272,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,156",
    wall_thickness_mm: "3,96",
    weight_lbpft: "20,98",
    weight_kgpm: "31,25",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 273,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,172",
    wall_thickness_mm: "4,37",
    weight_lbpft: "23,11",
    weight_kgpm: "34,43",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 274,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,18",
    wall_thickness_mm: "4,57",
    weight_lbpft: "24,17",
    weight_kgpm: 36,
    specification: "-",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 275,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "25,22",
    weight_kgpm: "37,62",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 276,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,203",
    wall_thickness_mm: "5,16",
    weight_lbpft: "27,2",
    weight_kgpm: "40,56",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 277,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "29,31",
    weight_kgpm: "43,65",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 278,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "33,38",
    weight_kgpm: "49,73",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 279,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "37,42",
    weight_kgpm: "55,77",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 280,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "41,45",
    weight_kgpm: "61,71",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 281,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,33",
    wall_thickness_mm: "8,38",
    weight_lbpft: "43,77",
    weight_kgpm: "65,2",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 282,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "45,58",
    weight_kgpm: "67,93",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 283,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "49,56",
    weight_kgpm: "73,88",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 284,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "53,52",
    weight_kgpm: "79,73",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 285,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "57,59",
    weight_kgpm: "85,84",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 286,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "65,42",
    weight_kgpm: "97,46",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 287,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "73,15",
    weight_kgpm: "108,96",
    specification: "5L",
    schedule: 60,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 288,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "80,93",
    weight_kgpm: "120,62",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 289,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "88,63",
    weight_kgpm: "132,08",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 290,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "96,12",
    weight_kgpm: "143,21",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 291,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "103,53",
    weight_kgpm: "154,21",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 292,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,844",
    wall_thickness_mm: "21,44",
    weight_lbpft: "107,32",
    weight_kgpm: "159,91",
    specification: "-",
    schedule: 100,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 293,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "110,97",
    weight_kgpm: "165,37",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 294,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "118,33",
    weight_kgpm: "176,33",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 295,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "125,49",
    weight_kgpm: "186,97",
    specification: "5L",
    schedule: "XXS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 296,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "125,49",
    weight_kgpm: "186,97",
    specification: "5L",
    schedule: 120,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 297,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "132,57",
    weight_kgpm: "197,48",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 298,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "139,67",
    weight_kgpm: "208,14",
    specification: "5L",
    schedule: 140,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 299,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "153,53",
    weight_kgpm: "228,74",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 300,
    nominal_pipe_size_inch: 12,
    outside_diameter_inch: "12,75",
    outside_diameter_mm: "323,8",
    wall_thickness_inch: "1,312",
    wall_thickness_mm: "33,32",
    weight_lbpft: "160,27",
    weight_kgpm: "238,76",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 301,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,156",
    wall_thickness_mm: "3,96",
    weight_lbpft: "23,07",
    weight_kgpm: "34,36",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 302,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "27,73",
    weight_kgpm: "41,35",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 303,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,203",
    wall_thickness_mm: "5,16",
    weight_lbpft: "29,91",
    weight_kgpm: "44,59",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 304,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,21",
    wall_thickness_mm: "5,33",
    weight_lbpft: "30,93",
    weight_kgpm: "46,04",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 305,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "32,23",
    weight_kgpm: "47,99",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 306,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "36,71",
    weight_kgpm: "54,69",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 307,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "41,17",
    weight_kgpm: "61,35",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 308,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "45,61",
    weight_kgpm: "67,9",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 309,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "50,17",
    weight_kgpm: "74,76",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 310,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "54,57",
    weight_kgpm: "81,33",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 311,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "54,57",
    weight_kgpm: "81,33",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 312,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "58,94",
    weight_kgpm: "87,79",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 313,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "63,44",
    weight_kgpm: "94,55",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 314,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "67,78",
    weight_kgpm: "100,94",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 315,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "72,09",
    weight_kgpm: "107,39",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 316,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "80,66",
    weight_kgpm: "120,11",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 317,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,594",
    wall_thickness_mm: "15,09",
    weight_lbpft: "85,05",
    weight_kgpm: "126,71",
    specification: "-",
    schedule: 60,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 318,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "89,28",
    weight_kgpm: "133,03",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 319,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "97,81",
    weight_kgpm: "145,75",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 320,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "106,13",
    weight_kgpm: "158,1",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 321,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "114,37",
    weight_kgpm: "170,33",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 322,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "122,65",
    weight_kgpm: "182,75",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 323,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "130,85",
    weight_kgpm: "194,96",
    specification: "5L",
    schedule: 100,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 324,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "138,84",
    weight_kgpm: "206,83",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 325,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "146,74",
    weight_kgpm: "218,57",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 326,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "1,094",
    wall_thickness_mm: "27,79",
    weight_lbpft: "150,79",
    weight_kgpm: "224,65",
    specification: "-",
    schedule: 120,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 327,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "154,69",
    weight_kgpm: "230,48",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 328,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "170,21",
    weight_kgpm: "253,56",
    specification: "5L",
    schedule: 140,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 329,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "1,406",
    wall_thickness_mm: "35,71",
    weight_lbpft: "189,11",
    weight_kgpm: "281,7",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 330,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: 2,
    wall_thickness_mm: "50,8",
    weight_lbpft: "256,32",
    weight_kgpm: "381,83",
    specification: "-",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 331,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "2,125",
    wall_thickness_mm: "53,98",
    weight_lbpft: "269,5",
    weight_kgpm: "401,5",
    specification: "-",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 332,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "2,2",
    wall_thickness_mm: "55,88",
    weight_lbpft: "277,25",
    weight_kgpm: "413,01",
    specification: "-",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 333,
    nominal_pipe_size_inch: 14,
    outside_diameter_inch: 14,
    outside_diameter_mm: "355,6",
    wall_thickness_inch: "2,5",
    wall_thickness_mm: "63,5",
    weight_lbpft: "307,05",
    weight_kgpm: "457,4",
    specification: "-",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 334,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,165",
    wall_thickness_mm: "4,19",
    weight_lbpft: "27,9",
    weight_kgpm: "41,56",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 335,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "31,75",
    weight_kgpm: "47,34",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 336,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,203",
    wall_thickness_mm: "5,16",
    weight_lbpft: "34,25",
    weight_kgpm: "51,06",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 337,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "36,91",
    weight_kgpm: "54,96",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 338,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "42,05",
    weight_kgpm: "62,64",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 339,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "47,17",
    weight_kgpm: "70,3",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 340,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "52,27",
    weight_kgpm: "77,83",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 341,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "57,52",
    weight_kgpm: "85,71",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 342,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "62,58",
    weight_kgpm: "93,27",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 343,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "62,58",
    weight_kgpm: "93,27",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 344,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "67,62",
    weight_kgpm: "100,7",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 345,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "72,8",
    weight_kgpm: "108,49",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 346,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "77,79",
    weight_kgpm: "115,86",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 347,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "82,77",
    weight_kgpm: "123,3",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 348,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "82,77",
    weight_kgpm: "123,3",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 349,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "92,66",
    weight_kgpm: "137,99",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 350,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "102,63",
    weight_kgpm: "152,93",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 351,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,656",
    wall_thickness_mm: "16,66",
    weight_lbpft: "107,5",
    weight_kgpm: "160,12",
    specification: "-",
    schedule: 60,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 352,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "112,51",
    weight_kgpm: "167,65",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 353,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "122,15",
    weight_kgpm: "181,97",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 354,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "131,71",
    weight_kgpm: "196,16",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 355,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,844",
    wall_thickness_mm: "21,44",
    weight_lbpft: "136,61",
    weight_kgpm: "203,53",
    specification: "-",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 356,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "141,34",
    weight_kgpm: "210,6",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 357,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "150,89",
    weight_kgpm: "224,82",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 358,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "160,2",
    weight_kgpm: "238,64",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 359,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "1,031",
    wall_thickness_mm: "26,19",
    weight_lbpft: "164,82",
    weight_kgpm: "245,56",
    specification: "-",
    schedule: 100,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 360,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "169,43",
    weight_kgpm: "252,35",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 361,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "178,72",
    weight_kgpm: "266,28",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 362,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "187,93",
    weight_kgpm: 280,
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 363,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "1,219",
    wall_thickness_mm: "30,96",
    weight_lbpft: "192,43",
    weight_kgpm: "286,64",
    specification: "-",
    schedule: 120,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 364,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "196,91",
    weight_kgpm: "293,33",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 365,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "1,438",
    wall_thickness_mm: "36,53",
    weight_lbpft: "223,64",
    weight_kgpm: "333,19",
    specification: "-",
    schedule: 140,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 366,
    nominal_pipe_size_inch: 16,
    outside_diameter_inch: 16,
    outside_diameter_mm: "406,4",
    wall_thickness_inch: "1,594",
    wall_thickness_mm: "40,49",
    weight_lbpft: "245,25",
    weight_kgpm: "365,35",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 367,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,165",
    wall_thickness_mm: "4,19",
    weight_lbpft: "31,43",
    weight_kgpm: "46,81",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 368,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "35,76",
    weight_kgpm: "53,31",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 369,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "41,59",
    weight_kgpm: "61,9",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 370,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "47,39",
    weight_kgpm: "70,57",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 371,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "53,18",
    weight_kgpm: "79,21",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 372,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "58,94",
    weight_kgpm: "87,71",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 373,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "64,87",
    weight_kgpm: "96,61",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 374,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "70,59",
    weight_kgpm: "105,16",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 375,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "76,29",
    weight_kgpm: "113,57",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 376,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "82,15",
    weight_kgpm: "122,38",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 377,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "87,81",
    weight_kgpm: "130,72",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 378,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "93,45",
    weight_kgpm: "139,15",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 379,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "104,67",
    weight_kgpm: "155,8",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 380,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "115,98",
    weight_kgpm: "172,74",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 381,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "127,21",
    weight_kgpm: "189,46",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 382,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "138,17",
    weight_kgpm: "205,74",
    specification: "5L",
    schedule: 60,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 383,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "149,06",
    weight_kgpm: "221,89",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 384,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "160,03",
    weight_kgpm: "238,34",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 385,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "170,92",
    weight_kgpm: "254,55",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 386,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "181,56",
    weight_kgpm: "270,34",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 387,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "192,11",
    weight_kgpm: 286,
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 388,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "202,75",
    weight_kgpm: "301,94",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 389,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "1,156",
    wall_thickness_mm: "29,36",
    weight_lbpft: "207,96",
    weight_kgpm: "309,62",
    specification: "-",
    schedule: 100,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 390,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "213,31",
    weight_kgpm: "317,66",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 391,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "223,61",
    weight_kgpm: "332,95",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 392,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "1,375",
    wall_thickness_mm: "34,93",
    weight_lbpft: "244,14",
    weight_kgpm: "363,56",
    specification: "-",
    schedule: 120,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 393,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "1,562",
    wall_thickness_mm: "39,67",
    weight_lbpft: "274,22",
    weight_kgpm: "408,26",
    specification: "-",
    schedule: 140,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 394,
    nominal_pipe_size_inch: 18,
    outside_diameter_inch: 18,
    outside_diameter_mm: 457,
    wall_thickness_inch: "1,781",
    wall_thickness_mm: "45,24",
    weight_lbpft: "308,5",
    weight_kgpm: "459,37",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 395,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "39,78",
    weight_kgpm: "59,25",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 396,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "46,27",
    weight_kgpm: "68,89",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 397,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "52,73",
    weight_kgpm: "78,55",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 398,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "59,18",
    weight_kgpm: "88,19",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 399,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "65,6",
    weight_kgpm: "97,67",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 400,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "72,21",
    weight_kgpm: "107,6",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 401,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "78,6",
    weight_kgpm: "117,15",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 402,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "78,6",
    weight_kgpm: "117,15",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 403,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "84,96",
    weight_kgpm: "126,53",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 404,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "91,51",
    weight_kgpm: "136,37",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 405,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "97,83",
    weight_kgpm: "145,7",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 406,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "104,13",
    weight_kgpm: "155,12",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 407,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "104,13",
    weight_kgpm: "155,12",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 408,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "116,67",
    weight_kgpm: "173,74",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 409,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,594",
    wall_thickness_mm: "15,09",
    weight_lbpft: "123,11",
    weight_kgpm: "183,42",
    specification: "-",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 410,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "129,33",
    weight_kgpm: "192,71",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 411,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "141,9",
    weight_kgpm: "211,44",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 412,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "154,19",
    weight_kgpm: "229,7",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 413,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "166,4",
    weight_kgpm: "247,83",
    specification: "5L",
    schedule: 60,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 414,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "178,72",
    weight_kgpm: "266,29",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 415,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "190,96",
    weight_kgpm: "284,52",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 416,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "202,92",
    weight_kgpm: "302,28",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 417,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "1,031",
    wall_thickness_mm: "26,19",
    weight_lbpft: "208,87",
    weight_kgpm: "311,17",
    specification: "-",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 418,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "214,8",
    weight_kgpm: "319,92",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 419,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "226,78",
    weight_kgpm: "337,89",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 420,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "238,68",
    weight_kgpm: "355,61",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 421,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "250,31",
    weight_kgpm: "372,88",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 422,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "1,281",
    wall_thickness_mm: "32,54",
    weight_lbpft: "256,1",
    weight_kgpm: "381,53",
    specification: "-",
    schedule: 100,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 423,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "1,312",
    wall_thickness_mm: "33,32",
    weight_lbpft: "261,86",
    weight_kgpm: "390,03",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 424,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "1,375",
    wall_thickness_mm: "34,93",
    weight_lbpft: "273,51",
    weight_kgpm: "407,49",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 425,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "1,5",
    wall_thickness_mm: "38,1",
    weight_lbpft: "296,37",
    weight_kgpm: "441,49",
    specification: "-",
    schedule: 120,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 426,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "1,75",
    wall_thickness_mm: "44,45",
    weight_lbpft: "341,09",
    weight_kgpm: "508,11",
    specification: "-",
    schedule: 140,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 427,
    nominal_pipe_size_inch: 20,
    outside_diameter_inch: 20,
    outside_diameter_mm: 508,
    wall_thickness_inch: "1,969",
    wall_thickness_mm: "50,01",
    weight_lbpft: "379,17",
    weight_kgpm: "564,81",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 428,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,188",
    wall_thickness_mm: "4,78",
    weight_lbpft: "43,8",
    weight_kgpm: "65,24",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 429,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,219",
    wall_thickness_mm: "5,56",
    weight_lbpft: "50,94",
    weight_kgpm: "75,88",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 430,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "58,07",
    weight_kgpm: "86,54",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 431,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "65,18",
    weight_kgpm: "97,17",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 432,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "72,27",
    weight_kgpm: "107,63",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 433,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "79,56",
    weight_kgpm: "118,6",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 434,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "86,61",
    weight_kgpm: "129,13",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 435,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "86,61",
    weight_kgpm: "129,13",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 436,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "93,63",
    weight_kgpm: "139,5",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 437,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "100,86",
    weight_kgpm: "150,37",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 438,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "107,85",
    weight_kgpm: "160,68",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 439,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "114,81",
    weight_kgpm: "171,09",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 440,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "114,81",
    weight_kgpm: "171,09",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 441,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "128,67",
    weight_kgpm: "191,69",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 442,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "142,68",
    weight_kgpm: "212,69",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 443,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "156,6",
    weight_kgpm: "233,43",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 444,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "170,21",
    weight_kgpm: "253,65",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 445,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "183,75",
    weight_kgpm: "273,76",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 446,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "197,41",
    weight_kgpm: "294,25",
    specification: "5L",
    schedule: 60,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 447,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: 211,
    weight_kgpm: "314,49",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 448,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "224,28",
    weight_kgpm: "334,23",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 449,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "237,48",
    weight_kgpm: "353,84",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 450,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "250,81",
    weight_kgpm: "373,83",
    specification: "5L",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 451,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "264,06",
    weight_kgpm: "393,57",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 452,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "277,01",
    weight_kgpm: "412,81",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 453,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "1,312",
    wall_thickness_mm: "33,32",
    weight_lbpft: "289,88",
    weight_kgpm: "431,94",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 454,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "1,375",
    wall_thickness_mm: "34,93",
    weight_lbpft: "302,88",
    weight_kgpm: "451,42",
    specification: "5L",
    schedule: 100,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 455,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "1,438",
    wall_thickness_mm: "36,53",
    weight_lbpft: "315,79",
    weight_kgpm: "470,66",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 456,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "1,5",
    wall_thickness_mm: "38,1",
    weight_lbpft: "328,41",
    weight_kgpm: "489,41",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 457,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "1,625",
    wall_thickness_mm: "41,28",
    weight_lbpft: "353,61",
    weight_kgpm: "527,02",
    specification: "-",
    schedule: 120,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 458,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "1,875",
    wall_thickness_mm: "47,63",
    weight_lbpft: 403,
    weight_kgpm: "600,63",
    specification: "-",
    schedule: 140,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 459,
    nominal_pipe_size_inch: 22,
    outside_diameter_inch: 22,
    outside_diameter_mm: 559,
    wall_thickness_inch: "2,125",
    wall_thickness_mm: "53,98",
    weight_lbpft: "451,06",
    weight_kgpm: "672,26",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 460,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,218",
    wall_thickness_mm: "5,54",
    weight_lbpft: "55,37",
    weight_kgpm: "82,47",
    specification: "-",
    schedule: 5,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 461,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "63,41",
    weight_kgpm: "94,53",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 462,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "71,18",
    weight_kgpm: "106,15",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 463,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "78,93",
    weight_kgpm: "117,59",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 464,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "86,91",
    weight_kgpm: "129,59",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 465,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "94,62",
    weight_kgpm: "141,12",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 466,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "94,62",
    weight_kgpm: "141,12",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 467,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "102,31",
    weight_kgpm: "152,47",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 468,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "110,22",
    weight_kgpm: "164,37",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 469,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "117,86",
    weight_kgpm: "175,66",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 470,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "125,49",
    weight_kgpm: "187,06",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 471,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "140,68",
    weight_kgpm: "209,64",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 472,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "156,03",
    weight_kgpm: "232,66",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 473,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "171,29",
    weight_kgpm: "255,41",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 474,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "186,23",
    weight_kgpm: "277,61",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 475,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "201,09",
    weight_kgpm: "299,69",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 476,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "216,1",
    weight_kgpm: "322,21",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 477,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "231,03",
    weight_kgpm: "344,46",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 478,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "0,969",
    wall_thickness_mm: "24,61",
    weight_lbpft: "238,35",
    weight_kgpm: "355,26",
    specification: "-",
    schedule: 60,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 479,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "245,64",
    weight_kgpm: "366,17",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 480,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "260,17",
    weight_kgpm: "387,76",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 481,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "274,84",
    weight_kgpm: "409,77",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 482,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "289,44",
    weight_kgpm: "431,52",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 483,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "1,219",
    wall_thickness_mm: "30,96",
    weight_lbpft: "296,58",
    weight_kgpm: "442,08",
    specification: "-",
    schedule: 80,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 484,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "303,71",
    weight_kgpm: "452,74",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 485,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "1,312",
    wall_thickness_mm: "33,32",
    weight_lbpft: "317,91",
    weight_kgpm: "473,84",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 486,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "1,375",
    wall_thickness_mm: "34,93",
    weight_lbpft: "332,25",
    weight_kgpm: "495,35",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 487,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "1,438",
    wall_thickness_mm: "36,53",
    weight_lbpft: "346,5",
    weight_kgpm: "516,8",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 488,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "1,5",
    wall_thickness_mm: "38,1",
    weight_lbpft: "360,45",
    weight_kgpm: "537,33",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 489,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "1,531",
    wall_thickness_mm: "38,89",
    weight_lbpft: "367,39",
    weight_kgpm: "547,71",
    specification: "-",
    schedule: 100,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 490,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "1,562",
    wall_thickness_mm: "39,67",
    weight_lbpft: "374,31",
    weight_kgpm: "557,43",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 491,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "1,812",
    wall_thickness_mm: "46,02",
    weight_lbpft: "429,39",
    weight_kgpm: "640,03",
    specification: "-",
    schedule: 120,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 492,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "2,062",
    wall_thickness_mm: "52,37",
    weight_lbpft: "483,12",
    weight_kgpm: "720,15",
    specification: "-",
    schedule: 140,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 493,
    nominal_pipe_size_inch: 24,
    outside_diameter_inch: 24,
    outside_diameter_mm: 610,
    wall_thickness_inch: "2,344",
    wall_thickness_mm: "59,54",
    weight_lbpft: "542,13",
    weight_kgpm: "808,22",
    specification: "-",
    schedule: 160,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 494,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "68,75",
    weight_kgpm: "102,36",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 495,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "77,18",
    weight_kgpm: "114,95",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 496,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "85,6",
    weight_kgpm: "127,36",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 497,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "94,26",
    weight_kgpm: "140,37",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 498,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "102,63",
    weight_kgpm: "152,87",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 499,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "110,98",
    weight_kgpm: "165,18",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 500,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "119,57",
    weight_kgpm: "178,09",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 501,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "127,88",
    weight_kgpm: "190,34",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 502,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "136,17",
    weight_kgpm: "202,72",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 503,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "136,17",
    weight_kgpm: "202,72",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 504,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "152,68",
    weight_kgpm: "227,23",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 505,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "169,38",
    weight_kgpm: "252,24",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 506,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "185,99",
    weight_kgpm: "276,96",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 507,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "202,25",
    weight_kgpm: "301,1",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 508,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "218,43",
    weight_kgpm: "325,12",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 509,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "234,79",
    weight_kgpm: "349,62",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 510,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "251,07",
    weight_kgpm: "373,84",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 511,
    nominal_pipe_size_inch: 26,
    outside_diameter_inch: 26,
    outside_diameter_mm: 660,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: 267,
    weight_kgpm: "397,49",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 512,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "74,09",
    weight_kgpm: "110,34",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 513,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "83,19",
    weight_kgpm: "123,93",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 514,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "92,26",
    weight_kgpm: "137,32",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 515,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "101,61",
    weight_kgpm: "151,36",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 516,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "110,64",
    weight_kgpm: "164,85",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 517,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "119,65",
    weight_kgpm: "178,15",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 518,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "128,93",
    weight_kgpm: "192,09",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 519,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "137,9",
    weight_kgpm: "205,32",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 520,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "146,85",
    weight_kgpm: "218,69",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 521,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "146,85",
    weight_kgpm: "218,69",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 522,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "164,69",
    weight_kgpm: "245,18",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 523,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "182,73",
    weight_kgpm: "271,21",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 524,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "200,68",
    weight_kgpm: "298,95",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 525,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "218,27",
    weight_kgpm: "325,06",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 526,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "235,78",
    weight_kgpm: "351,05",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 527,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "253,48",
    weight_kgpm: "377,58",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 528,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "271,1",
    weight_kgpm: "403,81",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 529,
    nominal_pipe_size_inch: 28,
    outside_diameter_inch: 28,
    outside_diameter_mm: 711,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "288,36",
    weight_kgpm: "429,44",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 530,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "79,43",
    weight_kgpm: "118,33",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 531,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "89,19",
    weight_kgpm: "132,91",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 532,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "98,93",
    weight_kgpm: "147,28",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 533,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "108,95",
    weight_kgpm: "162,35",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 534,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "118,65",
    weight_kgpm: "176,84",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 535,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "128,32",
    weight_kgpm: "191,11",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 536,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "138,29",
    weight_kgpm: "206,09",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 537,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "147,92",
    weight_kgpm: "220,3",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 538,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "157,53",
    weight_kgpm: "234,67",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 539,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "157,53",
    weight_kgpm: "234,67",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 540,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "176,69",
    weight_kgpm: "263,12",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 541,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "196,08",
    weight_kgpm: "292,18",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 542,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "215,38",
    weight_kgpm: "320,93",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 543,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "234,29",
    weight_kgpm: "349,02",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 544,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "253,12",
    weight_kgpm: "376,98",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 545,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "272,17",
    weight_kgpm: "405,54",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 546,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "291,14",
    weight_kgpm: "433,78",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 547,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "309,72",
    weight_kgpm: "461,38",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 548,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "328,22",
    weight_kgpm: "488,85",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 549,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "346,93",
    weight_kgpm: "516,9",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 550,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "365,56",
    weight_kgpm: "544,65",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 551,
    nominal_pipe_size_inch: 30,
    outside_diameter_inch: 30,
    outside_diameter_mm: 762,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "383,81",
    weight_kgpm: "571,75",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 552,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "84,77",
    weight_kgpm: "126,31",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 553,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "95,19",
    weight_kgpm: "141,89",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 554,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "105,59",
    weight_kgpm: "157,24",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 555,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "116,3",
    weight_kgpm: "173,34",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 556,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "126,66",
    weight_kgpm: "188,82",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 557,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "136,99",
    weight_kgpm: "204,08",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 558,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "147,64",
    weight_kgpm: "220,08",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 559,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "157,94",
    weight_kgpm: "235,28",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 560,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "168,21",
    weight_kgpm: "250,64",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 561,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "168,21",
    weight_kgpm: "250,64",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 562,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "188,7",
    weight_kgpm: "281,07",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 563,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "209,43",
    weight_kgpm: "312,15",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 564,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "230,08",
    weight_kgpm: "342,91",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 565,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "250,31",
    weight_kgpm: "372,98",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 566,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "270,47",
    weight_kgpm: "402,92",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 567,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "290,86",
    weight_kgpm: "433,49",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 568,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "311,17",
    weight_kgpm: "463,75",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 569,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "331,08",
    weight_kgpm: "493,32",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 570,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "350,9",
    weight_kgpm: "522,77",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 571,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "370,96",
    weight_kgpm: "552,85",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 572,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "390,94",
    weight_kgpm: "582,61",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 573,
    nominal_pipe_size_inch: 32,
    outside_diameter_inch: 32,
    outside_diameter_mm: 813,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "410,51",
    weight_kgpm: "611,68",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 574,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "90,11",
    weight_kgpm: "134,3",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 575,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "101,19",
    weight_kgpm: "150,87",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 576,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "112,25",
    weight_kgpm: "167,2",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 577,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "123,65",
    weight_kgpm: "184,33",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 578,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "134,67",
    weight_kgpm: "200,31",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 579,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "145,67",
    weight_kgpm: "217,05",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 580,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: 157,
    weight_kgpm: "234,08",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 581,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "167,95",
    weight_kgpm: "250,26",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 582,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "178,89",
    weight_kgpm: "266,61",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 583,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "178,89",
    weight_kgpm: "266,61",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 584,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "200,7",
    weight_kgpm: "299,02",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 585,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "222,78",
    weight_kgpm: "332,12",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 586,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "244,77",
    weight_kgpm: "364,9",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 587,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "266,33",
    weight_kgpm: "396,93",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 588,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "287,81",
    weight_kgpm: "428,85",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 589,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "309,55",
    weight_kgpm: "461,45",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 590,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "331,21",
    weight_kgpm: "493,72",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 591,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "352,44",
    weight_kgpm: "525,27",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 592,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "373,59",
    weight_kgpm: "556,69",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 593,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "394,99",
    weight_kgpm: "588,79",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 594,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "416,31",
    weight_kgpm: "620,56",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 595,
    nominal_pipe_size_inch: 34,
    outside_diameter_inch: 34,
    outside_diameter_mm: 864,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "437,21",
    weight_kgpm: "651,61",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 596,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,25",
    wall_thickness_mm: "6,35",
    weight_lbpft: "95,45",
    weight_kgpm: "142,13",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 597,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,281",
    wall_thickness_mm: "7,14",
    weight_lbpft: "107,2",
    weight_kgpm: "159,67",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 598,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "118,92",
    weight_kgpm: "176,96",
    specification: "5L",
    schedule: 10,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 599,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: 131,
    weight_kgpm: "195,11",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 600,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "142,68",
    weight_kgpm: "212,56",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 601,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "154,34",
    weight_kgpm: "229,76",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 602,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "166,35",
    weight_kgpm: "247,31",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 603,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "177,97",
    weight_kgpm: "264,94",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 604,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "189,57",
    weight_kgpm: "282,27",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 605,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "189,57",
    weight_kgpm: "282,27",
    specification: "5L",
    schedule: 20,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 606,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "212,7",
    weight_kgpm: "316,11",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 607,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "236,13",
    weight_kgpm: "351,7",
    specification: "5L",
    schedule: 30,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 608,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "259,47",
    weight_kgpm: "386,45",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 609,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "282,35",
    weight_kgpm: "420,42",
    specification: "5L",
    schedule: 40,
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 610,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "305,16",
    weight_kgpm: "454,27",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 611,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "328,24",
    weight_kgpm: "488,86",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 612,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "351,25",
    weight_kgpm: "523,11",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 613,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "373,8",
    weight_kgpm: "556,59",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 614,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "396,27",
    weight_kgpm: "589,95",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 615,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "419,02",
    weight_kgpm: "624,03",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 616,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "441,69",
    weight_kgpm: "657,77",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 617,
    nominal_pipe_size_inch: 36,
    outside_diameter_inch: 36,
    outside_diameter_mm: 914,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "463,91",
    weight_kgpm: "690,76",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 618,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "125,58",
    weight_kgpm: "186,92",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 619,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "138,35",
    weight_kgpm: "206,1",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 620,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "150,69",
    weight_kgpm: "224,54",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 621,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "163,01",
    weight_kgpm: "242,72",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 622,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "175,71",
    weight_kgpm: "261,8",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 623,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "187,99",
    weight_kgpm: "279,92",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 624,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "200,25",
    weight_kgpm: "298,24",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 625,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "224,71",
    weight_kgpm: "334,56",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 626,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "249,48",
    weight_kgpm: "371,68",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 627,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "274,16",
    weight_kgpm: "408,43",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 628,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "298,37",
    weight_kgpm: "444,38",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 629,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "322,5",
    weight_kgpm: "480,21",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 630,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "346,93",
    weight_kgpm: "516,82",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 631,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "371,28",
    weight_kgpm: "553,08",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 632,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "395,16",
    weight_kgpm: "588,53",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 633,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "418,96",
    weight_kgpm: "623,87",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 634,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "443,05",
    weight_kgpm: "659,97",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 635,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "467,06",
    weight_kgpm: "695,73",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 636,
    nominal_pipe_size_inch: 38,
    outside_diameter_inch: 38,
    outside_diameter_mm: 965,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "490,61",
    weight_kgpm: "730,69",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 637,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,312",
    wall_thickness_mm: "7,92",
    weight_lbpft: "132,25",
    weight_kgpm: "196,89",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 638,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "145,69",
    weight_kgpm: "217,09",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 639,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "158,7",
    weight_kgpm: "236,53",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 640,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "171,68",
    weight_kgpm: "255,69",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 641,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "185,06",
    weight_kgpm: "275,8",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 642,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "198,01",
    weight_kgpm: "294,9",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 643,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "210,93",
    weight_kgpm: "314,22",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 644,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "236,71",
    weight_kgpm: "352,51",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 645,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "262,83",
    weight_kgpm: "391,65",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 646,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "288,86",
    weight_kgpm: "430,42",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 647,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "314,39",
    weight_kgpm: "468,34",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 648,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "339,84",
    weight_kgpm: "506,14",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 649,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "365,62",
    weight_kgpm: "544,78",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 650,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "391,32",
    weight_kgpm: "583,05",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 651,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "416,52",
    weight_kgpm: "620,48",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 652,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "441,64",
    weight_kgpm: "657,78",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 653,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "467,08",
    weight_kgpm: "695,92",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 654,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "492,44",
    weight_kgpm: "733,68",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 655,
    nominal_pipe_size_inch: 40,
    outside_diameter_inch: 40,
    outside_diameter_mm: 1016,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "517,31",
    weight_kgpm: "770,62",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 656,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "153,04",
    weight_kgpm: "228,09",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 657,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "166,71",
    weight_kgpm: "248,52",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 658,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "180,35",
    weight_kgpm: "268,66",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 659,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "194,42",
    weight_kgpm: "289,8",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 660,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "208,03",
    weight_kgpm: "309,88",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 661,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "221,61",
    weight_kgpm: "330,19",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 662,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "248,72",
    weight_kgpm: "370,45",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 663,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "276,18",
    weight_kgpm: "411,62",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 664,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "303,55",
    weight_kgpm: "452,4",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 665,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "330,41",
    weight_kgpm: "492,3",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 666,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "357,19",
    weight_kgpm: "532,07",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 667,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "384,31",
    weight_kgpm: "572,73",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 668,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "411,35",
    weight_kgpm: "613,02",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 669,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "437,88",
    weight_kgpm: "652,42",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 670,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "464,32",
    weight_kgpm: "691,7",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 671,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "491,11",
    weight_kgpm: "731,86",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 672,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "517,82",
    weight_kgpm: "771,64",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 673,
    nominal_pipe_size_inch: 42,
    outside_diameter_inch: 42,
    outside_diameter_mm: 1067,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "544,01",
    weight_kgpm: "810,55",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 674,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "160,39",
    weight_kgpm: "239,08",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 675,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "174,72",
    weight_kgpm: "260,5",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 676,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "189,03",
    weight_kgpm: "281,62",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 677,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "203,78",
    weight_kgpm: "303,8",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 678,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "218,04",
    weight_kgpm: "324,8",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 679,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "232,29",
    weight_kgpm: "346,16",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 680,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "260,72",
    weight_kgpm: "388,4",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 681,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "289,53",
    weight_kgpm: "431,59",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 682,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "318,25",
    weight_kgpm: "474,37",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 683,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "346,43",
    weight_kgpm: "516,26",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 684,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "374,53",
    weight_kgpm: 558,
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 685,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: 403,
    weight_kgpm: "600,69",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 686,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "431,39",
    weight_kgpm: "642,99",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 687,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "459,24",
    weight_kgpm: "684,37",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 688,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "487,01",
    weight_kgpm: "725,62",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 689,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "515,14",
    weight_kgpm: "767,8",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 690,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "543,19",
    weight_kgpm: "809,6",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 691,
    nominal_pipe_size_inch: 44,
    outside_diameter_inch: 44,
    outside_diameter_mm: 1118,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "570,71",
    weight_kgpm: "850,48",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 692,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "167,74",
    weight_kgpm: "249,85",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 693,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "182,73",
    weight_kgpm: "272,25",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 694,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "197,7",
    weight_kgpm: "294,34",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 695,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "213,13",
    weight_kgpm: "317,52",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 696,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "228,06",
    weight_kgpm: "339,54",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 697,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "242,97",
    weight_kgpm: "351,82",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 698,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "272,73",
    weight_kgpm: 406,
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 699,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "302,88",
    weight_kgpm: "451,17",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 700,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "332,95",
    weight_kgpm: "495,94",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 701,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "362,45",
    weight_kgpm: "539,76",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 702,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "391,88",
    weight_kgpm: "583,43",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 703,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "421,69",
    weight_kgpm: "628,1",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 704,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "451,42",
    weight_kgpm: "672,37",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 705,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "480,6",
    weight_kgpm: "715,68",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 706,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "509,69",
    weight_kgpm: "758,88",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 707,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "539,17",
    weight_kgpm: "803,04",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 708,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "568,57",
    weight_kgpm: "846,81",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 709,
    nominal_pipe_size_inch: 46,
    outside_diameter_inch: 46,
    outside_diameter_mm: 1168,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "597,41",
    weight_kgpm: "889,63",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 710,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,344",
    wall_thickness_mm: "8,74",
    weight_lbpft: "175,08",
    weight_kgpm: "260,85",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 711,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "190,74",
    weight_kgpm: "284,24",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 712,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "206,37",
    weight_kgpm: "307,3",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 713,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "222,49",
    weight_kgpm: "331,52",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 714,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "238,08",
    weight_kgpm: "354,52",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 715,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "253,65",
    weight_kgpm: "377,79",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 716,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "284,73",
    weight_kgpm: "423,94",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 717,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "316,23",
    weight_kgpm: "471,14",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 718,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "347,64",
    weight_kgpm: "517,92",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 719,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "378,47",
    weight_kgpm: "563,7",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 720,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "409,22",
    weight_kgpm: "609,36",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 721,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "440,38",
    weight_kgpm: "656,06",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 722,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "471,46",
    weight_kgpm: "702,34",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 723,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "501,96",
    weight_kgpm: "747,63",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 724,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "532,38",
    weight_kgpm: "792,8",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 725,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "563,2",
    weight_kgpm: "838,99",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 726,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "593,94",
    weight_kgpm: "884,77",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 727,
    nominal_pipe_size_inch: 48,
    outside_diameter_inch: 48,
    outside_diameter_mm: 1219,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "624,11",
    weight_kgpm: "929,56",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 728,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "206,76",
    weight_kgpm: "308,21",
    specification: "5L",
    schedule: "STD",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 729,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "223,72",
    weight_kgpm: "333,24",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 730,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "241,2",
    weight_kgpm: "359,51",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 731,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "258,11",
    weight_kgpm: "384,48",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 732,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "275,01",
    weight_kgpm: "409,64",
    specification: "5L",
    schedule: "XS",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 733,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "308,74",
    weight_kgpm: "459,84",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 734,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "342,93",
    weight_kgpm: "511,09",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 735,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "377,03",
    weight_kgpm: "561,89",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 736,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "410,51",
    weight_kgpm: "611,62",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 737,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "443,91",
    weight_kgpm: "661,23",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 738,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "477,76",
    weight_kgpm: "711,98",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 739,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "511,53",
    weight_kgpm: "762,28",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 740,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "544,68",
    weight_kgpm: "811,52",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 741,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "577,75",
    weight_kgpm: "860,63",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 742,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "611,26",
    weight_kgpm: "910,88",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 743,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "644,69",
    weight_kgpm: "960,68",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 744,
    nominal_pipe_size_inch: 52,
    outside_diameter_inch: 52,
    outside_diameter_mm: 1321,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "677,51",
    weight_kgpm: "1009,42",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 745,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "222,78",
    weight_kgpm: "331,94",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 746,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "241,06",
    weight_kgpm: "358,91",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 747,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "259,91",
    weight_kgpm: "387,24",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 748,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "278,15",
    weight_kgpm: "414,14",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 749,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "296,37",
    weight_kgpm: "441,37",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 750,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "332,75",
    weight_kgpm: "495,38",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 751,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "369,63",
    weight_kgpm: "550,54",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 752,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "406,42",
    weight_kgpm: "605,43",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 753,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "442,55",
    weight_kgpm: "659,07",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 754,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "478,6",
    weight_kgpm: "712,59",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 755,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "515,14",
    weight_kgpm: "767,34",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 756,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "551,6",
    weight_kgpm: "821,63",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 757,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "587,4",
    weight_kgpm: "875,78",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 758,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "623,12",
    weight_kgpm: "927,81",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 759,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "659,32",
    weight_kgpm: "982,06",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 760,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "695,45",
    weight_kgpm: "1035,85",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 761,
    nominal_pipe_size_inch: 56,
    outside_diameter_inch: 56,
    outside_diameter_mm: 1422,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "730,91",
    weight_kgpm: "1088,5",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 762,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "238,8",
    weight_kgpm: "355,92",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 763,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "258,4",
    weight_kgpm: "384,85",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 764,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "278,62",
    weight_kgpm: "415,23",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 765,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "298,19",
    weight_kgpm: "444,1",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 766,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "317,73",
    weight_kgpm: "473,31",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 767,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "356,76",
    weight_kgpm: "531,27",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 768,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "396,33",
    weight_kgpm: "590,58",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 769,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "435,81",
    weight_kgpm: "649,4",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 770,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "474,59",
    weight_kgpm: "706,98",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 771,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "513,29",
    weight_kgpm: "764,45",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 772,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "552,52",
    weight_kgpm: "823,26",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 773,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "591,67",
    weight_kgpm: "881,57",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 774,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "630,12",
    weight_kgpm: "938,67",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 775,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "668,48",
    weight_kgpm: "995,64",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 776,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "707,38",
    weight_kgpm: "1053,95",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 777,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "746,2",
    weight_kgpm: "1111,76",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 778,
    nominal_pipe_size_inch: 60,
    outside_diameter_inch: 60,
    outside_diameter_mm: 1524,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "784,31",
    weight_kgpm: "1168,36",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 779,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "0,375",
    wall_thickness_mm: "9,53",
    weight_lbpft: "254,82",
    weight_kgpm: "379,89",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 780,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "0,406",
    wall_thickness_mm: "10,31",
    weight_lbpft: "275,75",
    weight_kgpm: "410,78",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 781,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "0,438",
    wall_thickness_mm: "11,13",
    weight_lbpft: "297,33",
    weight_kgpm: "443,23",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 782,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "318,22",
    weight_kgpm: "474,06",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 783,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "339,09",
    weight_kgpm: "505,26",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 784,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "380,76",
    weight_kgpm: "567,16",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 785,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "423,03",
    weight_kgpm: "630,52",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 786,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "465,21",
    weight_kgpm: "693,36",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 787,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "506,63",
    weight_kgpm: "754,9",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 788,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "547,98",
    weight_kgpm: "816,32",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 789,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "589,9",
    weight_kgpm: "879,17",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 790,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "631,74",
    weight_kgpm: "941,51",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 791,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "672,84",
    weight_kgpm: "1002,56",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 792,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "713,85",
    weight_kgpm: "1063,48",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 793,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "755,44",
    weight_kgpm: "1125,83",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 794,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "796,95",
    weight_kgpm: "1187,67",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 795,
    nominal_pipe_size_inch: 64,
    outside_diameter_inch: 64,
    outside_diameter_mm: 1626,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "837,71",
    weight_kgpm: "1248,23",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 796,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "0,469",
    wall_thickness_mm: "11,91",
    weight_lbpft: "338,26",
    weight_kgpm: "503,72",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 797,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "360,45",
    weight_kgpm: "536,89",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 798,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "404,77",
    weight_kgpm: "602,71",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 799,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "449,73",
    weight_kgpm: "670,08",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 800,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "494,6",
    weight_kgpm: "736,9",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 801,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "538,67",
    weight_kgpm: "802,35",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 802,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "582,66",
    weight_kgpm: "867,68",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 803,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "627,28",
    weight_kgpm: "934,54",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 804,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "671,82",
    weight_kgpm: "1000,86",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 805,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "715,56",
    weight_kgpm: "1065,82",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 806,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "759,22",
    weight_kgpm: "1130,66",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 807,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "803,5",
    weight_kgpm: "1197,02",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 808,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "847,7",
    weight_kgpm: "1262,84",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 809,
    nominal_pipe_size_inch: 68,
    outside_diameter_inch: 68,
    outside_diameter_mm: 1727,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "891,11",
    weight_kgpm: "1327,3",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 810,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "381,81",
    weight_kgpm: "568,83",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 811,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "428,78",
    weight_kgpm: "638,6",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 812,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "476,43",
    weight_kgpm: "710,02",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 813,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "523,99",
    weight_kgpm: "780,87",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 814,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "570,71",
    weight_kgpm: "850,29",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 815,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "617,35",
    weight_kgpm: "919,54",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 816,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "664,66",
    weight_kgpm: "990,46",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 817,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "711,89",
    weight_kgpm: "1060,8",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 818,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "758,28",
    weight_kgpm: "1129,71",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 819,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "804,59",
    weight_kgpm: "1198,49",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 820,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "851,56",
    weight_kgpm: "1268,91",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 821,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "898,45",
    weight_kgpm: "1338,75",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 822,
    nominal_pipe_size_inch: 72,
    outside_diameter_inch: 72,
    outside_diameter_mm: 1829,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "944,51",
    weight_kgpm: "1407,17",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 823,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: "0,5",
    wall_thickness_mm: "12,7",
    weight_lbpft: "403,17",
    weight_kgpm: "600,46",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 824,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "452,79",
    weight_kgpm: "674,14",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 825,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "503,13",
    weight_kgpm: "749,57",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 826,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "553,38",
    weight_kgpm: "824,4",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 827,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "602,75",
    weight_kgpm: "897,71",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 828,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "652,04",
    weight_kgpm: "970,9",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 829,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "702,04",
    weight_kgpm: "1045,82",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 830,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "751,96",
    weight_kgpm: "1120,16",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 831,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: 801,
    weight_kgpm: "1192,97",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 832,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "849,96",
    weight_kgpm: "1265,67",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 833,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "899,62",
    weight_kgpm: "1340,09",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 834,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "949,2",
    weight_kgpm: "1413,92",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 835,
    nominal_pipe_size_inch: 76,
    outside_diameter_inch: 76,
    outside_diameter_mm: 1930,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "997,91",
    weight_kgpm: "1486,24",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 836,
    nominal_pipe_size_inch: 80,
    outside_diameter_inch: 80,
    outside_diameter_mm: 2032,
    wall_thickness_inch: "0,562",
    wall_thickness_mm: "14,27",
    weight_lbpft: "476,8",
    weight_kgpm: "710,04",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 837,
    nominal_pipe_size_inch: 80,
    outside_diameter_inch: 80,
    outside_diameter_mm: 2032,
    wall_thickness_inch: "0,625",
    wall_thickness_mm: "15,88",
    weight_lbpft: "529,83",
    weight_kgpm: "789,51",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 838,
    nominal_pipe_size_inch: 80,
    outside_diameter_inch: 80,
    outside_diameter_mm: 2032,
    wall_thickness_inch: "0,688",
    wall_thickness_mm: "17,48",
    weight_lbpft: "582,77",
    weight_kgpm: "868,37",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 839,
    nominal_pipe_size_inch: 80,
    outside_diameter_inch: 80,
    outside_diameter_mm: 2032,
    wall_thickness_inch: "0,75",
    wall_thickness_mm: "19,05",
    weight_lbpft: "634,79",
    weight_kgpm: "945,63",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 840,
    nominal_pipe_size_inch: 80,
    outside_diameter_inch: 80,
    outside_diameter_mm: 2032,
    wall_thickness_inch: "0,812",
    wall_thickness_mm: "20,62",
    weight_lbpft: "686,73",
    weight_kgpm: "1022,76",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 841,
    nominal_pipe_size_inch: 80,
    outside_diameter_inch: 80,
    outside_diameter_mm: 2032,
    wall_thickness_inch: "0,875",
    wall_thickness_mm: "22,23",
    weight_lbpft: "739,42",
    weight_kgpm: "1101,74",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 842,
    nominal_pipe_size_inch: 80,
    outside_diameter_inch: 80,
    outside_diameter_mm: 2032,
    wall_thickness_inch: "0,938",
    wall_thickness_mm: "23,83",
    weight_lbpft: "792,03",
    weight_kgpm: "1180,1",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 843,
    nominal_pipe_size_inch: 80,
    outside_diameter_inch: 80,
    outside_diameter_mm: 2032,
    wall_thickness_inch: 1,
    wall_thickness_mm: "25,4",
    weight_lbpft: "843,72",
    weight_kgpm: "1256,86",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 844,
    nominal_pipe_size_inch: 80,
    outside_diameter_inch: 80,
    outside_diameter_mm: 2032,
    wall_thickness_inch: "1,062",
    wall_thickness_mm: "26,97",
    weight_lbpft: "895,33",
    weight_kgpm: "1333,5",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 845,
    nominal_pipe_size_inch: 80,
    outside_diameter_inch: 80,
    outside_diameter_mm: 2032,
    wall_thickness_inch: "1,125",
    wall_thickness_mm: "28,58",
    weight_lbpft: "947,68",
    weight_kgpm: "1411,97",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 846,
    nominal_pipe_size_inch: 80,
    outside_diameter_inch: 80,
    outside_diameter_mm: 2032,
    wall_thickness_inch: "1,188",
    wall_thickness_mm: "30,18",
    weight_lbpft: "999,95",
    weight_kgpm: "1489,83",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
  {
    piping_details_id: 847,
    nominal_pipe_size_inch: 80,
    outside_diameter_inch: 80,
    outside_diameter_mm: 2032,
    wall_thickness_inch: "1,25",
    wall_thickness_mm: "31,75",
    weight_lbpft: "1051,31",
    weight_kgpm: "1566,11",
    specification: "5L",
    schedule: "",
    material: "ASME B 36.10 M-1996",
    country_code: "American",
    type: "Steel Pipe",
  },
].map((item) => ({
  piping_details_id: item.piping_details_id,
  nominal_pipe_size_inch: item.nominal_pipe_size_inch + "",
  schedule: item.schedule + "",
  outside_diameter: fixValueToNumber(item.outside_diameter_inch, "float"),
  wall_thickness: fixValueToNumber(item.wall_thickness_inch, "float"),
  weight: fixValueToNumber(item.weight_lbpft, "float"),
  outside_diameter_global: fixValueToNumber(item.outside_diameter_mm, "float"),
  wall_thickness_global: fixValueToNumber(item.wall_thickness_mm, "float"),
  weight_global: fixValueToNumber(item.weight_kgpm, "float"),
  specification: item.specification,
  material: item.material,
  country_code: item.country_code,
  type: item.type,
}));
