import React, { useState, useEffect, useRef } from 'react';

interface OTPInputProps {
  otpLength?: number;
  onOTPComplete: (otp: string) => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ otpLength = 6, onOTPComplete }) => {
    const [otp, setOtp] = useState<Array<string>>(new Array(otpLength).fill(''));
    const inputsRef = useRef<Array<HTMLInputElement | null>>(new Array(otpLength).fill(null));

    useEffect(() => {
        inputsRef.current.find((input, index) => {
            if (otp[index] === '') {
                input?.focus();
                return true;
            }
            return false;
        });
    }, [otp]);

    const handleOTPInput = (value: string, index: number) => {
        if (!/^\d$/.test(value)) return; 

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (index < otpLength - 1) {
            inputsRef.current[index + 1]?.focus();
        }

        if (newOtp.every(digit => digit) && newOtp.join('').length === otpLength) {
            onOTPComplete(newOtp.join(''));
        }
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>, index: number) => {
        event.target.select(); 
    };

    const handleBackspace = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Backspace') {
            if (otp[index] === '' && index > 0) {
                inputsRef.current[index - 1]?.focus();
            } else {
                const newOtp = [...otp];
                newOtp[index] = '';
                setOtp(newOtp);
            }
        }
    };

    const setRef = (input: HTMLInputElement | null, index: number) => {
        inputsRef.current[index] = input;
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
            {otp.map((digit, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength={1}
                    style={{ width: '40px', textAlign: 'center' }}
                    value={digit}
                    onChange={e => handleOTPInput(e.target.value, index)}
                    onKeyDown={e => handleBackspace(e, index)}
                    onFocus={e => handleFocus(e, index)}
                    ref={input => setRef(input, index)}
                    pattern="[0-9]*"
                    inputMode="numeric"
                />
            ))}
        </div>
    );
};

export default OTPInput;
