import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { ApplicationState } from '../../store';
import { Card, Elevation, Button, Icon, HTMLTable } from '@blueprintjs/core';
import Axios from 'axios';
import { secondServerAPI } from '../utils/agent';
import UserList from './usersList';
import UserDetails from './userDetails';

interface User {
  username: string;
  email: string;
  userId: number;
  companyName: string;
  idsRegistrationOn: string;
  productAccess: string[];
  currentlyLoggedIn: boolean;
  currentLoginTime: string | null;
  loginActivitiesOfUser: {
    date: string;
    loginActivities: {
      loginTime: string;
      logoutTime: string;
      durationOfLogin: string;
    }[];
    totalLoginDurationInADay: string;
  }[];
  totalLoginHours: string;
}

interface UploadedFiles {
  fileName: string;
  uploadedBy: string;
  uploadedOn: string;
  fileSize: number;
  fileType: string;
}

interface SharedFiles {
  fileName: string;
  sharedBy: string;
  sharedTo: string;
  sharedOn: string;
}

export default function AdminDashboard() {
  const [users, setUsers] = useState<User[]>([]);
  const [loggedInUsers, setLoggedInUsers] = useState<User[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles[]>([]);
  const [sharedFiles, setSharedFiles] = useState<SharedFiles[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeView, setActiveView] = useState<string>('option1');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [isButton1, setIsButton1] = useState<boolean>(false);
  const [isListView, setIsListView] = useState<boolean>(false);

  const auth = useSelector((state: ApplicationState) => state.auth);

  useEffect(() => {
    if (activeView !== 'default') {
      Axios.get(`${secondServerAPI}/rest/api/v1/ufc/getAllUsersActivity`, {
        headers: {
          'user-id': 1,
        },
      })
        .then(response => {
          if (Array.isArray(response.data)) {
            setUsers(response.data);
          } else {
            setUsers([]);
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.error("Error fetching logged in users:", error);
          setIsLoading(false);
        });
    }
  }, [activeView]);

  useEffect(() => {
    if (isButton1) {
      Axios.get(`${secondServerAPI}/rest/api/v1/ufc/getFileDetailsForAdmin`, {
        headers: {
          'user-id': 1,
        },
      })
        .then(response => {
          setUploadedFiles(response.data.uploadedFiles || []);
          setSharedFiles(response.data.sharedFiles || []);
        })
        .catch(error => {
          console.error("Error fetching file details:", error);
        });
    }
  }, [isButton1]);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const handleButtonClick = (view: string) => setActiveView(view);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  function calculateLoginDuration(loginTime: string): string {
    const loginDateUTC = new Date(loginTime);
    const nowUTC = new Date();
    const difference = nowUTC.getTime() - loginDateUTC.getTime();
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    return `${hours} hours and ${minutes} minutes`;
  }

  function searchItems(items: User[]): User[] {
    return items.filter((item) =>
      item.username.toLowerCase().includes(searchText.toLowerCase()) ||
      item.email.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  function handleButton1Click() {
    setIsButton1(!isButton1);
  }

  const handleUserCardClick = (user: User) => {
    if (activeView === "userDetails") {
      setActiveView('option1');
    } else {
      setSelectedUser(user);
      setActiveView('userDetails');
    }
  };

  const toggleView = () => {
    setIsListView(!isListView);
  };

  return (
    <div className='admin-dashboard'>
      <div className="content-area">
        <div className="main-content-1">
          <Card className="intro-card" interactive={true} elevation={Elevation.TWO}>
            <h1>Welcome Back to <span style={{ color: "#1f8eb7" }}>IDS™</span></h1>
            <p>Dive into Your Digital Ecosystem</p>
          </Card>
          {activeView === 'option1' && !isLoading ? (
            users.length > 0 ? (
              <div>
                <div className='option-div-2'>
                  <Card className="user-intro-card" interactive={true} elevation={Elevation.TWO}>
                    <h1>Current Active Sessions Overview</h1>
                    <p>Explore the active connections within <span style={{ color: "#1f8eb7" }}>IDS™</span> platform. Here&apos;s a glance at who&apos;s online now:</p>
                  </Card>
                  <Button className='bp3-intent-primary' text="View Usage" onClick={handleButton1Click} />
                  {isButton1 && (
                    <Button className='bp3-intent-primary' text={isListView ? "Brick View" : "List View"} onClick={toggleView} />
                  )}
                </div>
                {isButton1 === false ? (
                  <div className="user-cards-container">
                    {users.map(user => (
                      <Card key={user.userId} className="user-card" interactive={true} elevation={Elevation.TWO} onClick={() => handleUserCardClick(user)}>
                        <h2>{user.username}</h2>
                        <text><Icon icon="envelope" intent='primary' /> Email: {user.email}</text>
                        <text><Icon icon="office" intent='success' /> Company : {user.companyName}</text>
                        <text><Icon icon="time" intent='warning' /> Login Time: {new Date(user.currentLoginTime).toLocaleString()}</text>
                        <text><Icon icon="stopwatch" intent='danger' /> Total Time on Prod: {user.totalLoginHours}</text>
                      </Card>
                    ))}
                  </div>
                ) : null}
                {isButton1 === true && (
                  isListView ? (
                    <div>
                      <h2>Uploaded Files</h2>
                      <HTMLTable striped bordered interactive className="file-table">
                        <thead>
                          <tr>
                            <th>File Name</th>
                            <th>Uploaded By</th>
                            <th>Uploaded On</th>
                            <th>File Size (MB)</th>
                            <th>File Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {uploadedFiles.map((file, index) => (
                            <tr key={index}>
                              <td>{file.fileName}</td>
                              <td>{file.uploadedBy}</td>
                              <td>{new Date(file.uploadedOn).toLocaleString()}</td>
                              <td>{file.fileSize}</td>
                              <td>{file.fileType}</td>
                            </tr>
                          ))}
                        </tbody>
                      </HTMLTable>
                      <h2>Shared Files</h2>
                      <HTMLTable striped bordered interactive className="file-table">
                        <thead>
                          <tr>
                            <th>File Name</th>
                            <th>Shared By</th>
                            <th>Shared To</th>
                            <th>Shared On</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sharedFiles.map((file, index) => (
                            <tr key={index}>
                              <td>{file.fileName}</td>
                              <td>{file.sharedBy}</td>
                              <td>{file.sharedTo}</td>
                              <td>{new Date(file.sharedOn).toLocaleString()}</td>
                            </tr>
                          ))}
                        </tbody>
                      </HTMLTable>
                    </div>
                  ) : (
                    <div>
                      <h2>Uploaded Files</h2>
                      <div className="user-cards-container">
                        {uploadedFiles.map((file, index) => (
                          <Card key={index} className="user-card" elevation={Elevation.TWO}>
                            <h2>{file.fileName}</h2>
                            <text><Icon icon="user" intent='primary' />Uploaded By: {file.uploadedBy}</text>
                            <text><Icon icon="time" intent='success' />Uploaded On: {new Date(file.uploadedOn).toLocaleString()}</text>
                            <text><Icon icon="database" intent='warning' />File Size: {file.fileSize} MB</text>
                            <text><Icon icon="many-to-many" intent='danger' />File Type: {file.fileType}</text>
                          </Card>
                        ))}
                      </div>
                      <h2>Shared Files</h2>
                      <div className="user-cards-container">
                        {sharedFiles.map((file, index) => (
                          <Card key={index} className="user-card" elevation={Elevation.TWO}>
                            <h2>{file.fileName}</h2>
                            <text><Icon icon="person" intent='primary' />Shared By: {file.sharedBy}</text>
                            <text><Icon icon="people" intent='success' />Shared To: {file.sharedTo}</text>
                            <text><Icon icon="time" intent='danger' />Shared On: {new Date(file.sharedOn).toLocaleString()}</text>
                          </Card>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : (
              <Card className="no-users-card" interactive={true} elevation={Elevation.ZERO}>
                <h5>No Users Logged In</h5>
              </Card>
            )
          ) : null}
          {activeView === 'userDetails' && selectedUser && (
            <UserDetails user={selectedUser} />
          )}
        </div>
      </div>
    </div>
  );
}
