import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesAmerican2 = [
  {
    profile_section_id: 536,
    name: "S8X23",
    type: "S Shape",
    designation: "S8X23",
    shape: "I",
    country_code: "American",
    ax: "6,77",
    ax_global: "4367,7332",
    bf: "4,171",
    bf_global: "105,9434",
    ct: "1,15",
    d: 8,
    d_global: "203,2",
    ix: "0,55",
    ix_global: "228927,2841",
    iy: "4,3",
    iy_global: "1789795,13",
    iz: "64,9",
    iz_global: "27013419,52",
    k: 1,
    k_global: "25,4",
    t: 6,
    t_global: "152,4",
    tf: "0,426",
    tf_global: "10,8204",
    tw: "0,441",
    tw_global: "11,2014",
    zx: "19,3",
    zx_global: "316270,3352",
    zy: "3,7",
    zy_global: "60632,1368",
    width: "4,171",
    width_global: "105,9434",
    thickness: "0,426",
    thickness_global: "10,8204",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 537,
    name: "S10X25",
    type: "S Shape",
    designation: "S10X25",
    shape: "I",
    country_code: "American",
    ax: "7,46",
    ax_global: "4812,8936",
    bf: "4,661",
    bf_global: "118,3894",
    ct: "1,2",
    d: 10,
    d_global: 254,
    ix: "0,6",
    ix_global: "249738,8554",
    iy: "6,8",
    iy_global: "2830373,694",
    iz: 124,
    iz_global: "51612696,77",
    k: "1,125",
    k_global: "28,575",
    t: "7,75",
    t_global: "196,85",
    tf: "0,491",
    tf_global: "12,4714",
    tw: "0,311",
    tw_global: "7,8994",
    zx: "28,4",
    zx_global: "465392,6176",
    zy: 5,
    zy_global: "81935,32",
    width: "4,661",
    width_global: "118,3894",
    thickness: "0,491",
    thickness_global: "12,4714",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 538,
    name: "S10X35",
    type: "S Shape",
    designation: "S10X35",
    shape: "I",
    country_code: "American",
    ax: "10,3",
    ax_global: "6645,148",
    bf: "4,944",
    bf_global: "125,5776",
    ct: "1,56",
    d: 10,
    d_global: 254,
    ix: "1,29",
    ix_global: "536938,539",
    iy: "8,4",
    iy_global: "3496343,975",
    iz: 147,
    iz_global: "61186019,56",
    k: "1,125",
    k_global: "28,575",
    t: "7,75",
    t_global: "196,85",
    tf: "0,491",
    tf_global: "12,4714",
    tw: "0,594",
    tw_global: "15,0876",
    zx: "35,4",
    zx_global: "580102,0656",
    zy: "6,2",
    zy_global: "101599,7968",
    width: "4,944",
    width_global: "125,5776",
    thickness: "0,491",
    thickness_global: "12,4714",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 539,
    name: "S12X31",
    type: "S Shape",
    designation: "S12X31",
    shape: "I",
    country_code: "American",
    ax: "9,35",
    ax_global: "6032,246",
    bf: 5,
    bf_global: 127,
    ct: "1,51",
    d: 12,
    d_global: "304,8",
    ix: "0,9",
    ix_global: "374608,283",
    iy: "9,4",
    iy_global: "3912575,401",
    iz: 218,
    iz_global: "90738450,78",
    k: "1,1875",
    k_global: "30,1625",
    t: "9,625",
    t_global: "244,475",
    tf: "0,544",
    tf_global: "13,8176",
    tw: "0,35",
    tw_global: "8,89",
    zx: 42,
    zx_global: "688256,688",
    zy: "6,4",
    zy_global: "104877,2096",
    width: 5,
    width_global: 127,
    thickness: "0,544",
    thickness_global: "13,8176",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 540,
    name: "S12X35",
    type: "S Shape",
    designation: "S12X35",
    shape: "I",
    country_code: "American",
    ax: "10,3",
    ax_global: "6645,148",
    bf: "5,078",
    bf_global: "128,9812",
    ct: "1,64",
    d: 12,
    d_global: "304,8",
    ix: "1,08",
    ix_global: "449529,9396",
    iy: "9,9",
    iy_global: "4120691,113",
    iz: 229,
    iz_global: "95316996,46",
    k: "1,1875",
    k_global: "30,1625",
    t: "9,625",
    t_global: "244,475",
    tf: "0,544",
    tf_global: "13,8176",
    tw: "0,428",
    tw_global: "10,8712",
    zx: "44,8",
    zx_global: "734140,4672",
    zy: "6,8",
    zy_global: "111432,0352",
    width: "5,078",
    width_global: "128,9812",
    thickness: "0,544",
    thickness_global: "13,8176",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 541,
    name: "S12X40",
    type: "S Shape",
    designation: "S12X40",
    shape: "I",
    country_code: "American",
    ax: 12,
    ax_global: "7741,92",
    bf: "5,252",
    bf_global: "133,4008",
    ct: "1,58",
    d: 12,
    d_global: "304,8",
    ix: "1,76",
    ix_global: "732567,3091",
    iy: "13,6",
    iy_global: "5660747,388",
    iz: 272,
    iz_global: "113214947,8",
    k: "1,4375",
    k_global: "36,5125",
    t: "9,125",
    t_global: "231,775",
    tf: "0,659",
    tf_global: "16,7386",
    tw: "0,462",
    tw_global: "11,7348",
    zx: "53,1",
    zx_global: "870153,0984",
    zy: "8,9",
    zy_global: "145844,8696",
    width: "5,252",
    width_global: "133,4008",
    thickness: "0,659",
    thickness_global: "16,7386",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 542,
    name: "S12X50",
    type: "S Shape",
    designation: "S12X50",
    shape: "I",
    country_code: "American",
    ax: "14,7",
    ax_global: "9483,852",
    bf: "5,477",
    bf_global: "139,1158",
    ct: "1,84",
    d: 12,
    d_global: "304,8",
    ix: "2,82",
    ix_global: "1173772,62",
    iy: "15,7",
    iy_global: "6534833,382",
    iz: 305,
    iz_global: "126950584,8",
    k: "1,4375",
    k_global: "36,5125",
    t: "9,125",
    t_global: "231,775",
    tf: "0,659",
    tf_global: "16,7386",
    tw: "0,687",
    tw_global: "17,4498",
    zx: "61,2",
    zx_global: "1002888,317",
    zy: "10,3",
    zy_global: "168786,7592",
    width: "5,477",
    width_global: "139,1158",
    thickness: "0,659",
    thickness_global: "16,7386",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 543,
    name: "S15X42",
    type: "S Shape",
    designation: "S15X42",
    shape: "I",
    country_code: "American",
    ax: "12,6",
    ax_global: "8129,016",
    bf: "5,501",
    bf_global: "139,7254",
    ct: "2,01",
    d: 15,
    d_global: 381,
    ix: "1,54",
    ix_global: "640996,3954",
    iy: "14,4",
    iy_global: "5993732,529",
    iz: 447,
    iz_global: "186055447,2",
    k: "1,375",
    k_global: "34,925",
    t: "12,25",
    t_global: "311,15",
    tf: "0,622",
    tf_global: "15,7988",
    tw: "0,411",
    tw_global: "10,4394",
    zx: "69,3",
    zx_global: "1135623,535",
    zy: 9,
    zy_global: "147483,576",
    width: "5,501",
    width_global: "139,7254",
    thickness: "0,622",
    thickness_global: "15,7988",
    height: 15,
    height_global: 381,
  },
  {
    profile_section_id: 544,
    name: "S15X50",
    type: "S Shape",
    designation: "S15X50",
    shape: "I",
    country_code: "American",
    ax: "14,7",
    ax_global: "9483,852",
    bf: "5,64",
    bf_global: "143,256",
    ct: "2,25",
    d: 15,
    d_global: 381,
    ix: "2,12",
    ix_global: "882410,6223",
    iy: "15,7",
    iy_global: "6534833,382",
    iz: 486,
    iz_global: "202288472,8",
    k: "1,375",
    k_global: "34,925",
    t: "12,25",
    t_global: "311,15",
    tf: "0,622",
    tf_global: "15,7988",
    tw: "0,55",
    tw_global: "13,97",
    zx: "77,1",
    zx_global: "1263442,634",
    zy: 10,
    zy_global: "163870,64",
    width: "5,64",
    width_global: "143,256",
    thickness: "0,622",
    thickness_global: "15,7988",
    height: 15,
    height_global: 381,
  },
  {
    profile_section_id: 545,
    name: "S18X54",
    type: "S Shape",
    designation: "S18X54",
    shape: "I",
    country_code: "American",
    ax: "16,1",
    ax_global: "10387,076",
    bf: "6,001",
    bf_global: "152,4254",
    ct: "2,5",
    d: 18,
    d_global: "457,2",
    ix: "2,37",
    ix_global: "986468,4787",
    iy: "20,8",
    iy_global: "8657613,652",
    iz: 804,
    iz_global: "334650066,2",
    k: "1,5",
    k_global: "38,1",
    t: 15,
    t_global: 381,
    tf: "0,691",
    tf_global: "17,5514",
    tw: "0,461",
    tw_global: "11,7094",
    zx: 105,
    zx_global: "1720641,72",
    zy: "12,1",
    zy_global: "198283,4744",
    width: "6,001",
    width_global: "152,4254",
    thickness: "0,691",
    thickness_global: "17,5514",
    height: 18,
    height_global: "457,2",
  },
  {
    profile_section_id: 546,
    name: "S18X70",
    type: "S Shape",
    designation: "S18X70",
    shape: "I",
    country_code: "American",
    ax: "20,6",
    ax_global: "13290,296",
    bf: "6,251",
    bf_global: "158,7754",
    ct: "2,94",
    d: 18,
    d_global: "457,2",
    ix: "4,15",
    ix_global: "1727360,416",
    iy: "24,1",
    iy_global: "10031177,36",
    iz: 926,
    iz_global: "385430300,1",
    k: "1,5",
    k_global: "38,1",
    t: 15,
    t_global: 381,
    tf: "0,691",
    tf_global: "17,5514",
    tw: "0,711",
    tw_global: "18,0594",
    zx: 125,
    zx_global: 2048383,
    zy: "14,4",
    zy_global: "235973,7216",
    width: "6,251",
    width_global: "158,7754",
    thickness: "0,691",
    thickness_global: "17,5514",
    height: 18,
    height_global: "457,2",
  },
  {
    profile_section_id: 547,
    name: "S20X66",
    type: "S Shape",
    designation: "S20X66",
    shape: "I",
    country_code: "American",
    ax: "19,4",
    ax_global: "12516,104",
    bf: "6,255",
    bf_global: "158,877",
    ct: "2,81",
    d: 20,
    d_global: 508,
    ix: "3,58",
    ix_global: "1490108,504",
    iy: "27,7",
    iy_global: "11529610,49",
    iz: 1190,
    iz_global: "495315396,5",
    k: "1,625",
    k_global: "41,275",
    t: "16,75",
    t_global: "425,45",
    tf: "0,795",
    tf_global: "20,193",
    tw: "0,505",
    tw_global: "12,827",
    zx: 140,
    zx_global: "2294188,96",
    zy: "15,3",
    zy_global: "250722,0792",
    width: "6,255",
    width_global: "158,877",
    thickness: "0,795",
    thickness_global: "20,193",
    height: 20,
    height_global: 508,
  },
  {
    profile_section_id: 548,
    name: "S20X75",
    type: "S Shape",
    designation: "S20X75",
    shape: "I",
    country_code: "American",
    ax: 22,
    ax_global: "14193,52",
    bf: "6,385",
    bf_global: "162,179",
    ct: "3,07",
    d: 20,
    d_global: 508,
    ix: "4,59",
    ix_global: "1910502,244",
    iy: "29,8",
    iy_global: "12403696,48",
    iz: 1280,
    iz_global: "532776224,8",
    k: "1,625",
    k_global: "41,275",
    t: "16,75",
    t_global: "425,45",
    tf: "0,795",
    tf_global: "20,193",
    tw: "0,635",
    tw_global: "16,129",
    zx: 153,
    zx_global: "2507220,792",
    zy: "16,7",
    zy_global: "273663,9688",
    width: "6,385",
    width_global: "162,179",
    thickness: "0,795",
    thickness_global: "20,193",
    height: 20,
    height_global: 508,
  },
  {
    profile_section_id: 549,
    name: "S20X86",
    type: "S Shape",
    designation: "S20X86",
    shape: "I",
    country_code: "American",
    ax: "25,3",
    ax_global: "16322,548",
    bf: "7,06",
    bf_global: "179,324",
    ct: "2,91",
    d: "20,3",
    d_global: "515,62",
    ix: "6,64",
    ix_global: "2763776,666",
    iy: "46,8",
    iy_global: "19479630,72",
    iz: 1580,
    iz_global: "657645652,4",
    k: "1,75",
    k_global: "44,45",
    t: "16,8",
    t_global: "426,72",
    tf: "0,92",
    tf_global: "23,368",
    tw: "0,66",
    tw_global: "16,764",
    zx: 183,
    zx_global: "2998832,712",
    zy: 23,
    zy_global: "376902,472",
    width: "7,06",
    width_global: "179,324",
    thickness: "0,92",
    thickness_global: "23,368",
    height: "20,3",
    height_global: "515,62",
  },
  {
    profile_section_id: 550,
    name: "S20X96",
    type: "S Shape",
    designation: "S20X96",
    shape: "I",
    country_code: "American",
    ax: "28,2",
    ax_global: "18193,512",
    bf: "7,2",
    bf_global: "182,88",
    ct: "3,13",
    d: "20,3",
    d_global: "515,62",
    ix: "8,39",
    ix_global: "3492181,661",
    iy: "50,2",
    iy_global: "20894817,57",
    iz: 1670,
    iz_global: "695106480,8",
    k: "1,75",
    k_global: "44,45",
    t: "16,8",
    t_global: "426,72",
    tf: "0,92",
    tf_global: "23,368",
    tw: "0,8",
    tw_global: "20,32",
    zx: 198,
    zx_global: "3244638,672",
    zy: "24,9",
    zy_global: "408037,8936",
    width: "7,2",
    width_global: "182,88",
    thickness: "0,92",
    thickness_global: "23,368",
    height: "20,3",
    height_global: "515,62",
  },
  {
    profile_section_id: 551,
    name: "S24X80",
    type: "S Shape",
    designation: "S24X80",
    shape: "I",
    country_code: "American",
    ax: "23,5",
    ax_global: "15161,26",
    bf: 7,
    bf_global: "177,8",
    ct: "3,29",
    d: 24,
    d_global: "609,6",
    ix: "4,88",
    ix_global: "2031209,357",
    iy: "42,2",
    iy_global: "17564966,16",
    iz: 2100,
    iz_global: "874085993,8",
    k: "1,75",
    k_global: "44,45",
    t: "20,5",
    t_global: "520,7",
    tf: "0,87",
    tf_global: "22,098",
    tw: "0,5",
    tw_global: "12,7",
    zx: 204,
    zx_global: "3342961,056",
    zy: "20,7",
    zy_global: "339212,2248",
    width: 7,
    width_global: "177,8",
    thickness: "0,87",
    thickness_global: "22,098",
    height: 24,
    height_global: "609,6",
  },
  {
    profile_section_id: 552,
    name: "S24X90",
    type: "S Shape",
    designation: "S24X90",
    shape: "I",
    country_code: "American",
    ax: "26,5",
    ax_global: "17096,74",
    bf: "7,125",
    bf_global: "180,975",
    ct: "3,6",
    d: 24,
    d_global: "609,6",
    ix: "6,04",
    ix_global: "2514037,811",
    iy: "44,9",
    iy_global: "18688791,01",
    iz: 2250,
    iz_global: "936520707,6",
    k: "1,75",
    k_global: "44,45",
    t: "20,5",
    t_global: "520,7",
    tf: "0,87",
    tf_global: "22,098",
    tw: "0,625",
    tw_global: "15,875",
    zx: 222,
    zx_global: "3637928,208",
    zy: "22,3",
    zy_global: "365431,5272",
    width: "7,125",
    width_global: "180,975",
    thickness: "0,87",
    thickness_global: "22,098",
    height: 24,
    height_global: "609,6",
  },
  {
    profile_section_id: 553,
    name: "S24X100",
    type: "S Shape",
    designation: "S24X100",
    shape: "I",
    country_code: "American",
    ax: "29,3",
    ax_global: "18903,188",
    bf: "7,245",
    bf_global: "184,023",
    ct: "3,84",
    d: 24,
    d_global: "609,6",
    ix: "7,58",
    ix_global: "3155034,206",
    iy: "47,7",
    iy_global: 19854239,
    iz: 2390,
    iz_global: "994793107,2",
    k: "1,75",
    k_global: "44,45",
    t: "20,5",
    t_global: "520,7",
    tf: "0,87",
    tf_global: "22,098",
    tw: "0,745",
    tw_global: "18,923",
    zx: 240,
    zx_global: "3932895,36",
    zy: "23,9",
    zy_global: "391650,8296",
    width: "7,245",
    width_global: "184,023",
    thickness: "0,87",
    thickness_global: "22,098",
    height: 24,
    height_global: "609,6",
  },
  {
    profile_section_id: 554,
    name: "S24X106",
    type: "S Shape",
    designation: "S24X106",
    shape: "I",
    country_code: "American",
    ax: "31,2",
    ax_global: "20128,992",
    bf: "7,87",
    bf_global: "199,898",
    ct: "3,28",
    d: "24,5",
    d_global: "622,3",
    ix: "10,1",
    ix_global: "4203937,399",
    iy: "77,1",
    iy_global: "32091442,91",
    iz: 2940,
    iz_global: 1223720391,
    k: 2,
    k_global: "50,8",
    t: "20,5",
    t_global: "520,7",
    tf: "1,09",
    tf_global: "27,686",
    tw: "0,62",
    tw_global: "15,748",
    zx: 279,
    zx_global: "4571990,856",
    zy: "33,2",
    zy_global: "544050,5248",
    width: "7,87",
    width_global: "199,898",
    thickness: "1,09",
    thickness_global: "27,686",
    height: "24,5",
    height_global: "622,3",
  },
  {
    profile_section_id: 555,
    name: "S24X121",
    type: "S Shape",
    designation: "S24X121",
    shape: "I",
    country_code: "American",
    ax: "35,6",
    ax_global: "22967,696",
    bf: "8,05",
    bf_global: "204,47",
    ct: "3,63",
    d: "24,5",
    d_global: "622,3",
    ix: "12,8",
    ix_global: "5327762,248",
    iy: "83,3",
    iy_global: "34672077,75",
    iz: 3160,
    iz_global: 1315291305,
    k: 2,
    k_global: "50,8",
    t: "20,5",
    t_global: "520,7",
    tf: "1,09",
    tf_global: "27,686",
    tw: "0,8",
    tw_global: "20,32",
    zx: 306,
    zx_global: "5014441,584",
    zy: "36,2",
    zy_global: "593211,7168",
    width: "8,05",
    width_global: "204,47",
    thickness: "1,09",
    thickness_global: "27,686",
    height: "24,5",
    height_global: "622,3",
  },
  {
    profile_section_id: 556,
    name: "B923030",
    type: "B Shape",
    designation: "B923030",
    shape: "I",
    country_code: "American",
    ax: "241,88",
    ax_global: "156051,3008",
    bf: 30,
    bf_global: 762,
    ct: 0,
    d: 96,
    d_global: "2438,4",
    ix: "549,75",
    ix_global: "228823226,2",
    iy: "13502,4",
    iy_global: 5620123201,
    iz: "431105,7",
    iz_global: "1,79E+11",
    tf: 3,
    tf_global: "76,2",
    tw: "0,688",
    tw_global: "17,4752",
    zx: 9762,
    zx_global: "159970518,8",
    zy: 1361,
    zy_global: "22302794,1",
    width: 30,
    width_global: 762,
    thickness: 3,
    thickness_global: "76,2",
    height: 96,
    height_global: "2438,4",
  },
  {
    profile_section_id: 557,
    name: "B923025",
    type: "B Shape",
    designation: "B923025",
    shape: "I",
    country_code: "American",
    ax: "211,88",
    ax_global: "136696,5008",
    bf: 30,
    bf_global: 762,
    ct: 0,
    d: 95,
    d_global: 2413,
    ix: "322,25",
    ix_global: "134130576,9",
    iy: "11252,4",
    iy_global: 4683602493,
    iz: "362703,2",
    iz_global: "1,51E+11",
    tf: "2,5",
    tf_global: "63,5",
    tw: "0,688",
    tw_global: "17,4752",
    zx: 8330,
    zx_global: "136504243,1",
    zy: 1136,
    zy_global: "18615704,7",
    width: 30,
    width_global: 762,
    thickness: "2,5",
    thickness_global: "63,5",
    height: 95,
    height_global: 2413,
  },
  {
    profile_section_id: 558,
    name: "B923020",
    type: "B Shape",
    designation: "B923020",
    shape: "I",
    country_code: "American",
    ax: "181,88",
    ax_global: "117341,7008",
    bf: 30,
    bf_global: 762,
    ct: 0,
    d: 94,
    d_global: "2387,6",
    ix: "169,75",
    ix_global: "70655284,5",
    iy: "9002,4",
    iy_global: 3747081786,
    iz: "295725,7",
    iz_global: "1,23E+11",
    tf: 2,
    tf_global: "50,8",
    tw: "0,688",
    tw_global: "17,4752",
    zx: 6912,
    zx_global: "113267386,4",
    zy: 911,
    zy_global: "14928615,3",
    width: 30,
    width_global: 762,
    thickness: 2,
    thickness_global: "50,8",
    height: 94,
    height_global: "2387,6",
  },
  {
    profile_section_id: 559,
    name: "B923017",
    type: "B Shape",
    designation: "B923017",
    shape: "I",
    country_code: "American",
    ax: "166,88",
    ax_global: "107664,3008",
    bf: 30,
    bf_global: 762,
    ct: 0,
    d: "93,5",
    d_global: "2374,9",
    ix: "116,94",
    ix_global: "48674102,91",
    iy: "7877,4",
    iy_global: 3278821432,
    iz: "262766,6",
    iz_global: "1,09E+11",
    tf: "1,75",
    tf_global: "44,45",
    tw: "0,688",
    tw_global: "17,4752",
    zx: 6209,
    zx_global: "101747280,4",
    zy: 798,
    zy_global: "13076877,07",
    width: 30,
    width_global: 762,
    thickness: "1,75",
    thickness_global: "44,45",
    height: "93,5",
    height_global: "2374,9",
  },
  {
    profile_section_id: 560,
    name: "B923015",
    type: "B Shape",
    designation: "B923015",
    shape: "I",
    country_code: "American",
    ax: "151,88",
    ax_global: "97986,9008",
    bf: 30,
    bf_global: 762,
    ct: 0,
    d: 93,
    d_global: "2362,2",
    ix: "77,25",
    ix_global: "32153877,63",
    iy: "6752,4",
    iy_global: 2810561078,
    iz: "230158,2",
    iz_global: 95799075700,
    tf: "1,5",
    tf_global: "38,1",
    tw: "0,688",
    tw_global: "17,4752",
    zx: 5510,
    zx_global: "90292722,64",
    zy: 686,
    zy_global: "11241525,9",
    width: 30,
    width_global: 762,
    thickness: "1,5",
    thickness_global: "38,1",
    height: 93,
    height_global: "2362,2",
  },
  {
    profile_section_id: 561,
    name: "B923012",
    type: "B Shape",
    designation: "B923012",
    shape: "I",
    country_code: "American",
    ax: "136,88",
    ax_global: "88309,5008",
    bf: 30,
    bf_global: 762,
    ct: 0,
    d: "92,5",
    d_global: "2349,5",
    ix: "48,81",
    ix_global: "20316255,88",
    iy: "5627,4",
    iy_global: 2342300724,
    iz: "197898,5",
    iz_global: 82371574779,
    tf: "1,25",
    tf_global: "31,75",
    tw: "0,688",
    tw_global: "17,4752",
    zx: 4814,
    zx_global: "78887326,1",
    zy: 573,
    zy_global: "9389787,672",
    width: 30,
    width_global: 762,
    thickness: "1,25",
    thickness_global: "31,75",
    height: "92,5",
    height_global: "2349,5",
  },
  {
    profile_section_id: 562,
    name: "B923010",
    type: "B Shape",
    designation: "B923010",
    shape: "I",
    country_code: "American",
    ax: "121,88",
    ax_global: "78632,1008",
    bf: 30,
    bf_global: 762,
    ct: 0,
    d: 92,
    d_global: "2336,8",
    ix: "29,75",
    ix_global: "12382884,91",
    iy: "4502,4",
    iy_global: 1874040371,
    iz: "165985,7",
    iz_global: 69088464540,
    tf: 1,
    tf_global: "25,4",
    tw: "0,688",
    tw_global: "17,4752",
    zx: 4122,
    zx_global: "67547477,81",
    zy: 461,
    zy_global: "7554436,504",
    width: 30,
    width_global: 762,
    thickness: 1,
    thickness_global: "25,4",
    height: 92,
    height_global: "2336,8",
  },
  {
    profile_section_id: 563,
    name: "B862830",
    type: "B Shape",
    designation: "B862830",
    shape: "I",
    country_code: "American",
    ax: "220,5",
    ax_global: "142257,78",
    bf: 28,
    bf_global: "711,2",
    ct: 0,
    d: 90,
    d_global: 2286,
    ix: "510,84",
    ix_global: "212627661,5",
    iy: "10977,7",
    iy_global: 4569263721,
    iz: 348894,
    iz_global: "1,45E+11",
    tf: 3,
    tf_global: "76,2",
    tw: "0,625",
    tw_global: "15,875",
    zx: 8411,
    zx_global: "137831595,3",
    zy: 1184,
    zy_global: "19402283,78",
    width: 28,
    width_global: "711,2",
    thickness: 3,
    thickness_global: "76,2",
    height: 90,
    height_global: 2286,
  },
  {
    profile_section_id: 564,
    name: "B862825",
    type: "B Shape",
    designation: "B862825",
    shape: "I",
    country_code: "American",
    ax: "192,5",
    ax_global: "124193,3",
    bf: 28,
    bf_global: "711,2",
    ct: 0,
    d: 89,
    d_global: "2260,6",
    ix: "298,5",
    ix_global: "124245080,5",
    iy: "9148,4",
    iy_global: 3807851574,
    iz: "292821,7",
    iz_global: "1,22E+11",
    tf: "2,5",
    tf_global: "63,5",
    tw: "0,625",
    tw_global: "15,875",
    zx: 7158,
    zx_global: "117298604,1",
    zy: 988,
    zy_global: "16190419,23",
    width: 28,
    width_global: "711,2",
    thickness: "2,5",
    thickness_global: "63,5",
    height: 89,
    height_global: "2260,6",
  },
  {
    profile_section_id: 565,
    name: "B862820",
    type: "B Shape",
    designation: "B862820",
    shape: "I",
    country_code: "American",
    ax: "164,5",
    ax_global: "106128,82",
    bf: 28,
    bf_global: "711,2",
    ct: 0,
    d: 88,
    d_global: "2235,2",
    ix: "156,17",
    ix_global: "65002861,74",
    iy: 7319,
    iy_global: 3046397804,
    iz: "237995,3",
    iz_global: 99061123005,
    tf: 2,
    tf_global: "50,8",
    tw: "0,625",
    tw_global: "15,875",
    zx: 5919,
    zx_global: "96995031,82",
    zy: 792,
    zy_global: "12978554,69",
    width: 28,
    width_global: "711,2",
    thickness: 2,
    thickness_global: "50,8",
    height: 88,
    height_global: "2235,2",
  },
  {
    profile_section_id: 566,
    name: "B862817",
    type: "B Shape",
    designation: "B862817",
    shape: "I",
    country_code: "American",
    ax: "150,5",
    ax_global: "97096,58",
    bf: 28,
    bf_global: "711,2",
    ct: 0,
    d: "87,5",
    d_global: "2222,5",
    ix: "106,88",
    ix_global: "44486814,77",
    iy: "6404,4",
    iy_global: 2665712542,
    iz: 211045,
    iz_global: 87843561216,
    tf: "1,75",
    tf_global: "44,45",
    tw: "0,625",
    tw_global: "15,875",
    zx: 5304,
    zx_global: "86916987,46",
    zy: 694,
    zy_global: "11372622,42",
    width: 28,
    width_global: "711,2",
    thickness: "1,75",
    thickness_global: "44,45",
    height: "87,5",
    height_global: "2222,5",
  },
  {
    profile_section_id: 567,
    name: "B862815",
    type: "B Shape",
    designation: "B862815",
    shape: "I",
    country_code: "American",
    ax: "136,5",
    ax_global: "88064,34",
    bf: 28,
    bf_global: "711,2",
    ct: 0,
    d: 87,
    d_global: "2209,8",
    ix: "69,84",
    ix_global: "29069602,76",
    iy: "5489,7",
    iy_global: 2284985657,
    iz: 184401,
    iz_global: 76753491112,
    tf: "1,5",
    tf_global: "38,1",
    tw: "0,625",
    tw_global: "15,875",
    zx: 4694,
    zx_global: "76920878,42",
    zy: 596,
    zy_global: "9766690,144",
    width: 28,
    width_global: "711,2",
    thickness: "1,5",
    thickness_global: "38,1",
    height: 87,
    height_global: "2209,8",
  },
  {
    profile_section_id: 568,
    name: "B862812",
    type: "B Shape",
    designation: "B862812",
    shape: "I",
    country_code: "American",
    ax: "122,5",
    ax_global: "79032,1",
    bf: 28,
    bf_global: "711,2",
    ct: 0,
    d: "86,5",
    d_global: "2197,1",
    ix: "43,29",
    ix_global: "18018658,41",
    iy: 4575,
    iy_global: 1904258772,
    iz: "158061,5",
    iz_global: 65790163477,
    tf: "1,25",
    tf_global: "31,75",
    tw: "0,625",
    tw_global: "15,875",
    zx: 4086,
    zx_global: "66957543,5",
    zy: 498,
    zy_global: "8160757,872",
    width: 28,
    width_global: "711,2",
    thickness: "1,25",
    thickness_global: "31,75",
    height: "86,5",
    height_global: "2197,1",
  },
  {
    profile_section_id: 569,
    name: "B862810",
    type: "B Shape",
    designation: "B862810",
    shape: "I",
    country_code: "American",
    ax: "108,5",
    ax_global: "69999,86",
    bf: 28,
    bf_global: "711,2",
    ct: 0,
    d: 86,
    d_global: "2184,4",
    ix: "25,5",
    ix_global: "10613901,35",
    iy: "3660,4",
    iy_global: 1523573510,
    iz: "132024,7",
    iz_global: 54952829095,
    tf: 1,
    tf_global: "25,4",
    tw: "0,625",
    tw_global: "15,875",
    zx: 3483,
    zx_global: "57076143,91",
    zy: 400,
    zy_global: "6554825,6",
    width: 28,
    width_global: "711,2",
    thickness: 1,
    thickness_global: "25,4",
    height: 86,
    height_global: "2184,4",
  },
  {
    profile_section_id: 570,
    name: "B802630",
    type: "B Shape",
    designation: "B802630",
    shape: "I",
    country_code: "American",
    ax: "204,75",
    ax_global: "132096,51",
    bf: 26,
    bf_global: "660,4",
    ct: 0,
    d: 84,
    d_global: "2133,6",
    ix: "474,35",
    ix_global: "197439376,7",
    iy: "8789,6",
    iy_global: 3658507738,
    iz: "280712,3",
    iz_global: "1,17E+11",
    tf: 3,
    tf_global: "76,2",
    tw: "0,625",
    tw_global: "15,875",
    zx: 7269,
    zx_global: "119117568,2",
    zy: 1022,
    zy_global: "16747579,41",
    width: 26,
    width_global: "660,4",
    thickness: 3,
    thickness_global: "76,2",
    height: 84,
    height_global: "2133,6",
  },
  {
    profile_section_id: 571,
    name: "B802625",
    type: "B Shape",
    designation: "B802625",
    shape: "I",
    country_code: "American",
    ax: "178,75",
    ax_global: "115322,35",
    bf: 26,
    bf_global: "660,4",
    ct: 0,
    d: 83,
    d_global: "2108,2",
    ix: "277,18",
    ix_global: "115371026,5",
    iy: "7324,9",
    iy_global: 3048853569,
    iz: "235392,1",
    iz_global: 97977589358,
    tf: "2,5",
    tf_global: "63,5",
    tw: "0,625",
    tw_global: "15,875",
    zx: 6183,
    zx_global: "101321216,7",
    zy: 853,
    zy_global: "13978165,59",
    width: 26,
    width_global: "660,4",
    thickness: "2,5",
    thickness_global: "63,5",
    height: 83,
    height_global: "2108,2",
  },
  {
    profile_section_id: 572,
    name: "B802620",
    type: "B Shape",
    designation: "B802620",
    shape: "I",
    country_code: "American",
    ax: "152,75",
    ax_global: "98548,19",
    bf: 26,
    bf_global: "660,4",
    ct: 0,
    d: 82,
    d_global: "2082,8",
    ix: "145,01",
    ix_global: "60357719,03",
    iy: "5860,3",
    iy_global: 2439241023,
    iz: "191150,9",
    iz_global: 79563011612,
    tf: 2,
    tf_global: "50,8",
    tw: "0,625",
    tw_global: "15,875",
    zx: 5111,
    zx_global: "83754284,1",
    zy: 684,
    zy_global: "11208751,78",
    width: 26,
    width_global: "660,4",
    thickness: 2,
    thickness_global: "50,8",
    height: 82,
    height_global: "2082,8",
  },
  {
    profile_section_id: 573,
    name: "B802617",
    type: "B Shape",
    designation: "B802617",
    shape: "I",
    country_code: "American",
    ax: "139,75",
    ax_global: "90161,11",
    bf: 26,
    bf_global: "660,4",
    ct: 0,
    d: "81,5",
    d_global: "2070,1",
    ix: "99,24",
    ix_global: "41306806,68",
    iy: "5127,9",
    iy_global: 2134393127,
    iz: "169430,9",
    iz_global: 70522465048,
    tf: "1,75",
    tf_global: "44,45",
    tw: "0,625",
    tw_global: "15,875",
    zx: 4579,
    zx_global: "75036366,06",
    zy: 599,
    zy_global: "9815851,336",
    width: 26,
    width_global: "660,4",
    thickness: "1,75",
    thickness_global: "44,45",
    height: "81,5",
    height_global: "2070,1",
  },
  {
    profile_section_id: 574,
    name: "B802615",
    type: "B Shape",
    designation: "B802615",
    shape: "I",
    country_code: "American",
    ax: "126,75",
    ax_global: "81774,03",
    bf: 26,
    bf_global: "660,4",
    ct: 0,
    d: 81,
    d_global: "2057,4",
    ix: "64,85",
    ix_global: "26992607,95",
    iy: "4395,6",
    iy_global: 1829586854,
    iz: "147975,8",
    iz_global: 61592178188,
    tf: "1,5",
    tf_global: "38,1",
    tw: "0,625",
    tw_global: "15,875",
    zx: 4051,
    zx_global: "66383996,26",
    zy: 515,
    zy_global: "8439337,96",
    width: 26,
    width_global: "660,4",
    thickness: "1,5",
    thickness_global: "38,1",
    height: 81,
    height_global: "2057,4",
  },
  {
    profile_section_id: 575,
    name: "B802612",
    type: "B Shape",
    designation: "B802612",
    shape: "I",
    country_code: "American",
    ax: "113,75",
    ax_global: "73386,95",
    bf: 26,
    bf_global: "660,4",
    ct: 0,
    d: "80,5",
    d_global: "2044,7",
    ix: "40,2",
    ix_global: "16732503,31",
    iy: "3663,3",
    iy_global: 1524780581,
    iz: "126783,9",
    iz_global: 52771443440,
    tf: "1,25",
    tf_global: "31,75",
    tw: "0,625",
    tw_global: "15,875",
    zx: 3526,
    zx_global: "57780787,66",
    zy: 430,
    zy_global: "7046437,52",
    width: 26,
    width_global: "660,4",
    thickness: "1,25",
    thickness_global: "31,75",
    height: "80,5",
    height_global: "2044,7",
  },
  {
    profile_section_id: 576,
    name: "B802610",
    type: "B Shape",
    designation: "B802610",
    shape: "I",
    country_code: "American",
    ax: "100,75",
    ax_global: "64999,87",
    bf: 26,
    bf_global: "660,4",
    ct: 0,
    d: 80,
    d_global: 2032,
    ix: "23,68",
    ix_global: "9856360,158",
    iy: "2930,9",
    iy_global: 1219932685,
    iz: "105853,6",
    iz_global: 44059594833,
    tf: 1,
    tf_global: "25,4",
    tw: "0,625",
    tw_global: "15,875",
    zx: 3005,
    zx_global: "49243127,32",
    zy: 346,
    zy_global: "5669924,144",
    width: 26,
    width_global: "660,4",
    thickness: 1,
    thickness_global: "25,4",
    height: 80,
    height_global: 2032,
  },
  {
    profile_section_id: 577,
    name: "B802608",
    type: "B Shape",
    designation: "B802608",
    shape: "I",
    country_code: "American",
    ax: "94,25",
    ax_global: "60806,33",
    bf: 26,
    bf_global: "660,4",
    ct: 0,
    d: "79,75",
    d_global: "2025,65",
    ix: "17,96",
    ix_global: "7475516,404",
    iy: "2564,8",
    iy_global: 1067550360,
    iz: "95486,1",
    iz_global: 39744315528,
    tf: "0,875",
    tf_global: "22,225",
    tw: "0,625",
    tw_global: "15,875",
    zx: 2745,
    zx_global: "44982490,68",
    zy: 303,
    zy_global: "4965280,392",
    width: 26,
    width_global: "660,4",
    thickness: "0,875",
    thickness_global: "22,225",
    height: "79,75",
    height_global: "2025,65",
  },
  {
    profile_section_id: 578,
    name: "B742430",
    type: "B Shape",
    designation: "B742430",
    shape: "I",
    country_code: "American",
    ax: "184,5",
    ax_global: "119032,02",
    bf: 24,
    bf_global: "609,6",
    ct: 0,
    d: 78,
    d_global: "1981,2",
    ix: "436,27",
    ix_global: 181589284,
    iy: "6913,1",
    iy_global: 2877449468,
    iz: 220104,
    iz_global: 91614201700,
    tf: 3,
    tf_global: "76,2",
    tw: "0,563",
    tw_global: "14,3002",
    zx: 6129,
    zx_global: "100436315,3",
    zy: 870,
    zy_global: "14256745,68",
    width: 24,
    width_global: "609,6",
    thickness: 3,
    thickness_global: "76,2",
    height: 78,
    height_global: "1981,2",
  },
  {
    profile_section_id: 579,
    name: "B742425",
    type: "B Shape",
    designation: "B742425",
    shape: "I",
    country_code: "American",
    ax: "160,5",
    ax_global: "103548,18",
    bf: 24,
    bf_global: "609,6",
    ct: 0,
    d: 77,
    d_global: "1955,8",
    ix: "254,27",
    ix_global: "105835164,6",
    iy: "5761,1",
    iy_global: 2397950866,
    iz: 184066,
    iz_global: 76614053584,
    tf: "2,5",
    tf_global: "63,5",
    tw: "0,563",
    tw_global: "14,3002",
    zx: 5199,
    zx_global: "85196345,74",
    zy: 726,
    zy_global: "11897008,46",
    width: 24,
    width_global: "609,6",
    thickness: "2,5",
    thickness_global: "63,5",
    height: 77,
    height_global: "1955,8",
  },
  {
    profile_section_id: 580,
    name: "B742420",
    type: "B Shape",
    designation: "B742420",
    shape: "I",
    country_code: "American",
    ax: "136,5",
    ax_global: "88064,34",
    bf: 24,
    bf_global: "609,6",
    ct: 0,
    d: 76,
    d_global: "1930,4",
    ix: "132,27",
    ix_global: "55054930,66",
    iy: "4609,1",
    iy_global: 1918452264,
    iz: 148952,
    iz_global: 61998503306,
    tf: 2,
    tf_global: "50,8",
    tw: "0,563",
    tw_global: "14,3002",
    zx: 4281,
    zx_global: "70153020,98",
    zy: 582,
    zy_global: "9537271,248",
    width: 24,
    width_global: "609,6",
    thickness: 2,
    thickness_global: "50,8",
    height: 76,
    height_global: "1930,4",
  },
  {
    profile_section_id: 581,
    name: "B742417",
    type: "B Shape",
    designation: "B742417",
    shape: "I",
    country_code: "American",
    ax: "124,5",
    ax_global: "80322,42",
    bf: 24,
    bf_global: "609,6",
    ct: 0,
    d: "75,5",
    d_global: "1917,7",
    ix: "90,02",
    ix_global: "37469152,93",
    iy: "4033,1",
    iy_global: 1678702963,
    iz: "131737,8",
    iz_global: 54833412299,
    tf: "1,75",
    tf_global: "44,45",
    tw: "0,563",
    tw_global: "14,3002",
    zx: 3827,
    zx_global: "62713293,93",
    zy: 510,
    zy_global: "8357402,64",
    width: 24,
    width_global: "609,6",
    thickness: "1,75",
    thickness_global: "44,45",
    height: "75,5",
    height_global: "1917,7",
  },
  {
    profile_section_id: 582,
    name: "B742415",
    type: "B Shape",
    designation: "B742415",
    shape: "I",
    country_code: "American",
    ax: "112,5",
    ax_global: "72580,5",
    bf: 24,
    bf_global: "609,6",
    ct: 0,
    d: 75,
    d_global: 1905,
    ix: "58,27",
    ix_global: "24253805,17",
    iy: "3457,1",
    iy_global: 1438953661,
    iz: 114750,
    iz_global: 47762556088,
    tf: "1,5",
    tf_global: "38,1",
    tw: "0,563",
    tw_global: "14,3002",
    zx: 3375,
    zx_global: 55306341,
    zy: 438,
    zy_global: "7177534,032",
    width: 24,
    width_global: "609,6",
    thickness: "1,5",
    thickness_global: "38,1",
    height: 75,
    height_global: 1905,
  },
  {
    profile_section_id: 583,
    name: "B742412",
    type: "B Shape",
    designation: "B742412",
    shape: "I",
    country_code: "American",
    ax: "100,5",
    ax_global: "64838,58",
    bf: 24,
    bf_global: "609,6",
    ct: 0,
    d: "74,5",
    d_global: "1892,3",
    ix: "35,52",
    ix_global: "14784540,24",
    iy: "2881,1",
    iy_global: 1199204360,
    iz: "97987,3",
    iz_global: 40785393570,
    tf: "1,25",
    tf_global: "31,75",
    tw: "0,563",
    tw_global: "14,3002",
    zx: 2927,
    zx_global: "47964936,33",
    zy: 366,
    zy_global: "5997665,424",
    width: 24,
    width_global: "609,6",
    thickness: "1,25",
    thickness_global: "31,75",
    height: "74,5",
    height_global: "1892,3",
  },
  {
    profile_section_id: 584,
    name: "B742410",
    type: "B Shape",
    designation: "B742410",
    shape: "I",
    country_code: "American",
    ax: "88,5",
    ax_global: "57096,66",
    bf: 24,
    bf_global: "609,6",
    ct: 0,
    d: 74,
    d_global: "1879,6",
    ix: "20,27",
    ix_global: "8437010,997",
    iy: "2305,1",
    iy_global: "959455059,2",
    iz: 81448,
    iz_global: 33901217152,
    tf: 1,
    tf_global: "25,4",
    tw: "0,563",
    tw_global: "14,3002",
    zx: 2481,
    zx_global: "40656305,78",
    zy: 294,
    zy_global: "4817796,816",
    width: 24,
    width_global: "609,6",
    thickness: 1,
    thickness_global: "25,4",
    height: 74,
    height_global: "1879,6",
  },
  {
    profile_section_id: 585,
    name: "B742408",
    type: "B Shape",
    designation: "B742408",
    shape: "I",
    country_code: "American",
    ax: "82,5",
    ax_global: "53225,7",
    bf: 24,
    bf_global: "609,6",
    ct: 0,
    d: "73,75",
    d_global: "1873,25",
    ix: "14,99",
    ix_global: "6239309,07",
    iy: "2017,1",
    iy_global: "839580408,6",
    iz: "73261,8",
    iz_global: 30493863456,
    tf: "0,875",
    tf_global: "22,225",
    tw: "0,563",
    tw_global: "14,3002",
    zx: 2259,
    zx_global: "37018377,58",
    zy: 258,
    zy_global: "4227862,512",
    width: 24,
    width_global: "609,6",
    thickness: "0,875",
    thickness_global: "22,225",
    height: "73,75",
    height_global: "1873,25",
  },
  {
    profile_section_id: 586,
    name: "B682230",
    type: "B Shape",
    designation: "B682230",
    shape: "I",
    country_code: "American",
    ax: 165,
    ax_global: "106451,4",
    bf: 22,
    bf_global: "558,8",
    ct: 0,
    d: 72,
    d_global: "1828,8",
    ix: "398,75",
    ix_global: 165972281,
    iy: "5324,7",
    iy_global: 2216307472,
    iz: 169191,
    iz_global: 70422611129,
    tf: 3,
    tf_global: "76,2",
    tw: "0,5",
    tw_global: "12,7",
    zx: 5099,
    zx_global: "83557639,34",
    zy: 730,
    zy_global: "11962556,72",
    width: 22,
    width_global: "558,8",
    thickness: 3,
    thickness_global: "76,2",
    height: 72,
    height_global: "1828,8",
  },
  {
    profile_section_id: 587,
    name: "B682225",
    type: "B Shape",
    designation: "B682225",
    shape: "I",
    country_code: "American",
    ax: 143,
    ax_global: "92257,88",
    bf: 22,
    bf_global: "558,8",
    ct: 0,
    d: 71,
    d_global: "1803,4",
    ix: "231,92",
    ix_global: "96532392,23",
    iy: "4437,4",
    iy_global: 1846985328,
    iz: "141073,2",
    iz_global: 58719099150,
    tf: "2,5",
    tf_global: "63,5",
    tw: "0,5",
    tw_global: "12,7",
    zx: 4312,
    zx_global: "70661019,97",
    zy: 609,
    zy_global: "9979721,976",
    width: 22,
    width_global: "558,8",
    thickness: "2,5",
    thickness_global: "63,5",
    height: 71,
    height_global: "1803,4",
  },
  {
    profile_section_id: 588,
    name: "B682220",
    type: "B Shape",
    designation: "B682220",
    shape: "I",
    country_code: "American",
    ax: 121,
    ax_global: "78064,36",
    bf: 22,
    bf_global: "558,8",
    ct: 0,
    d: 70,
    d_global: 1778,
    ix: "120,08",
    ix_global: "49981069,59",
    iy: 3550,
    iy_global: 1477621561,
    iz: "113736,3",
    iz_global: 47340622291,
    tf: 2,
    tf_global: "50,8",
    tw: "0,5",
    tw_global: "12,7",
    zx: 3537,
    zx_global: "57961045,37",
    zy: 488,
    zy_global: "7996887,232",
    width: 22,
    width_global: "558,8",
    thickness: 2,
    thickness_global: "50,8",
    height: 70,
    height_global: 1778,
  },
  {
    profile_section_id: 589,
    name: "B682217",
    type: "B Shape",
    designation: "B682217",
    shape: "I",
    country_code: "American",
    ax: 110,
    ax_global: "70967,6",
    bf: 22,
    bf_global: "558,8",
    ct: 0,
    d: "69,5",
    d_global: "1765,3",
    ix: "81,35",
    ix_global: "33860426,47",
    iy: "3106,4",
    iy_global: 1292981300,
    iz: "100357,4",
    iz_global: 41771903672,
    tf: "1,75",
    tf_global: "44,45",
    tw: "0,5",
    tw_global: "12,7",
    zx: 3153,
    zx_global: "51668412,79",
    zy: 428,
    zy_global: "7013663,392",
    width: 22,
    width_global: "558,8",
    thickness: "1,75",
    thickness_global: "44,45",
    height: "69,5",
    height_global: "1765,3",
  },
  {
    profile_section_id: 590,
    name: "B682215",
    type: "B Shape",
    designation: "B682215",
    shape: "I",
    country_code: "American",
    ax: 99,
    ax_global: "63870,84",
    bf: 22,
    bf_global: "558,8",
    ct: 0,
    d: 69,
    d_global: "1752,6",
    ix: "52,25",
    ix_global: "21748091,99",
    iy: "2662,7",
    iy_global: 1108299417,
    iz: "87169,5",
    iz_global: 36282685254,
    tf: "1,5",
    tf_global: "38,1",
    tw: "0,5",
    tw_global: "12,7",
    zx: 2772,
    zx_global: "45424941,41",
    zy: 367,
    zy_global: "6014052,488",
    width: 22,
    width_global: "558,8",
    thickness: "1,5",
    thickness_global: "38,1",
    height: 69,
    height_global: "1752,6",
  },
  {
    profile_section_id: 591,
    name: "B682212",
    type: "B Shape",
    designation: "B682212",
    shape: "I",
    country_code: "American",
    ax: 88,
    ax_global: "56774,08",
    bf: 22,
    bf_global: "558,8",
    ct: 0,
    d: "68,5",
    d_global: "1739,9",
    ix: "31,4",
    ix_global: "13069666,76",
    iy: 2219,
    iy_global: "923617533,4",
    iz: "74171,4",
    iz_global: 30872467561,
    tf: "1,25",
    tf_global: "31,75",
    tw: "0,5",
    tw_global: "12,7",
    zx: 2394,
    zx_global: "39230631,22",
    zy: 307,
    zy_global: "5030828,648",
    width: 22,
    width_global: "558,8",
    thickness: "1,25",
    thickness_global: "31,75",
    height: "68,5",
    height_global: "1739,9",
  },
  {
    profile_section_id: 592,
    name: "B682210",
    type: "B Shape",
    designation: "B682210",
    shape: "I",
    country_code: "American",
    ax: 77,
    ax_global: "49677,32",
    bf: 22,
    bf_global: "558,8",
    ct: 0,
    d: 68,
    d_global: "1727,2",
    ix: "17,42",
    ix_global: "7250751,434",
    iy: "1775,4",
    iy_global: 738977273,
    iz: "61361,7",
    iz_global: 25540667868,
    tf: 1,
    tf_global: "25,4",
    tw: "0,5",
    tw_global: "12,7",
    zx: 2019,
    zx_global: "33085482,22",
    zy: 246,
    zy_global: "4031217,744",
    width: 22,
    width_global: "558,8",
    thickness: 1,
    thickness_global: "25,4",
    height: 68,
    height_global: "1727,2",
  },
  {
    profile_section_id: 593,
    name: "B682208",
    type: "B Shape",
    designation: "B682208",
    shape: "I",
    country_code: "American",
    ax: "71,5",
    ax_global: "46128,94",
    bf: 22,
    bf_global: "558,8",
    ct: 0,
    d: "67,75",
    d_global: "1720,85",
    ix: "12,58",
    ix_global: "5236191,334",
    iy: "1553,5",
    iy_global: "646615519,7",
    iz: 55027,
    iz_global: 22903966656,
    tf: "0,875",
    tf_global: "22,225",
    tw: "0,5",
    tw_global: "12,7",
    zx: 1832,
    zx_global: "30021101,25",
    zy: 216,
    zy_global: "3539605,824",
    width: 22,
    width_global: "558,8",
    thickness: "0,875",
    thickness_global: "22,225",
    height: "67,75",
    height_global: "1720,85",
  },
  {
    profile_section_id: 594,
    name: "B682207",
    type: "B Shape",
    designation: "B682207",
    shape: "I",
    country_code: "American",
    ax: 66,
    ax_global: "42580,56",
    bf: 22,
    bf_global: "558,8",
    ct: 0,
    d: "67,5",
    d_global: "1714,5",
    ix: "8,94",
    ix_global: "3721108,945",
    iy: "1331,7",
    iy_global: "554295389,5",
    iz: "48738,9",
    iz_global: 20286661829,
    tf: "0,75",
    tf_global: "19,05",
    tw: "0,5",
    tw_global: "12,7",
    zx: 1646,
    zx_global: "26973107,34",
    zy: 186,
    zy_global: "3047993,904",
    width: 22,
    width_global: "558,8",
    thickness: "0,75",
    thickness_global: "19,05",
    height: "67,5",
    height_global: "1714,5",
  },
  {
    profile_section_id: 595,
    name: "B612025",
    type: "B Shape",
    designation: "B612025",
    shape: "I",
    country_code: "American",
    ax: "126,25",
    ax_global: "81451,45",
    bf: 20,
    bf_global: 508,
    ct: 0,
    d: 65,
    d_global: 1651,
    ix: "210,01",
    ix_global: "87412761,69",
    iy: "3333,8",
    iy_global: 1387632327,
    iz: "105583,3",
    iz_global: 43947087479,
    tf: "2,5",
    tf_global: "63,5",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 3519,
    zx_global: "57666078,22",
    zy: 503,
    zy_global: "8242693,192",
    width: 20,
    width_global: 508,
    thickness: "2,5",
    thickness_global: "63,5",
    height: 65,
    height_global: 1651,
  },
  {
    profile_section_id: 596,
    name: "B612020",
    type: "B Shape",
    designation: "B612020",
    shape: "I",
    country_code: "American",
    ax: "106,25",
    ax_global: "68548,25",
    bf: 20,
    bf_global: 508,
    ct: 0,
    d: 64,
    d_global: "1625,6",
    ix: "108,34",
    ix_global: "45094512,65",
    iy: "2667,1",
    iy_global: 1110130835,
    iz: "84781,7",
    iz_global: 35288807856,
    tf: 2,
    tf_global: "50,8",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 2874,
    zx_global: "47096421,94",
    zy: 403,
    zy_global: "6603986,792",
    width: 20,
    width_global: 508,
    thickness: 2,
    thickness_global: "50,8",
    height: 64,
    height_global: "1625,6",
  },
  {
    profile_section_id: 597,
    name: "B612017",
    type: "B Shape",
    designation: "B612017",
    shape: "I",
    country_code: "American",
    ax: "96,25",
    ax_global: "62096,65",
    bf: 20,
    bf_global: 508,
    ct: 0,
    d: "63,5",
    d_global: "1612,9",
    ix: "73,13",
    ix_global: "30439004,15",
    iy: "2333,8",
    iy_global: "971400901,1",
    iz: "74621,5",
    iz_global: 31059813325,
    tf: "1,75",
    tf_global: "44,45",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 2555,
    zx_global: "41868948,52",
    zy: 353,
    zy_global: "5784633,592",
    width: 20,
    width_global: 508,
    thickness: "1,75",
    thickness_global: "44,45",
    height: "63,5",
    height_global: "1612,9",
  },
  {
    profile_section_id: 598,
    name: "B612015",
    type: "B Shape",
    designation: "B612015",
    shape: "I",
    country_code: "American",
    ax: "86,25",
    ax_global: "55645,05",
    bf: 20,
    bf_global: 508,
    ct: 0,
    d: 63,
    d_global: "1600,2",
    ix: "46,67",
    ix_global: "19425520,63",
    iy: "2000,4",
    iy_global: "832629343,8",
    iz: 64620,
    iz_global: 26896874722,
    tf: "1,5",
    tf_global: "38,1",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 2239,
    zx_global: "36690636,3",
    zy: 303,
    zy_global: "4965280,392",
    width: 20,
    width_global: 508,
    thickness: "1,5",
    thickness_global: "38,1",
    height: 63,
    height_global: "1600,2",
  },
  {
    profile_section_id: 599,
    name: "B612012",
    type: "B Shape",
    designation: "B612012",
    shape: "I",
    country_code: "American",
    ax: "76,25",
    ax_global: "49193,45",
    bf: 20,
    bf_global: 508,
    ct: 0,
    d: "62,5",
    d_global: "1587,5",
    ix: "27,72",
    ix_global: "11537935,12",
    iy: "1667,1",
    iy_global: "693899409,6",
    iz: 54776,
    iz_global: 22799492569,
    tf: "1,25",
    tf_global: "31,75",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 1925,
    zx_global: "31545098,2",
    zy: 253,
    zy_global: "4145927,192",
    width: 20,
    width_global: 508,
    thickness: "1,25",
    thickness_global: "31,75",
    height: "62,5",
    height_global: "1587,5",
  },
  {
    profile_section_id: 600,
    name: "B612010",
    type: "B Shape",
    designation: "B612010",
    shape: "I",
    country_code: "American",
    ax: "66,25",
    ax_global: "42741,85",
    bf: 20,
    bf_global: 508,
    ct: 0,
    d: 62,
    d_global: "1574,8",
    ix: "15,01",
    ix_global: "6247633,698",
    iy: "1333,8",
    iy_global: "555169475,5",
    iz: "45088,3",
    iz_global: 18767167387,
    tf: 1,
    tf_global: "25,4",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 1614,
    zx_global: "26448721,3",
    zy: 203,
    zy_global: "3326573,992",
    width: 20,
    width_global: 508,
    thickness: 1,
    thickness_global: "25,4",
    height: 62,
    height_global: "1574,8",
  },
  {
    profile_section_id: 601,
    name: "B612008",
    type: "B Shape",
    designation: "B612008",
    shape: "I",
    country_code: "American",
    ax: "61,25",
    ax_global: "39516,05",
    bf: 20,
    bf_global: 508,
    ct: 0,
    d: "61,75",
    d_global: "1568,45",
    ix: "10,61",
    ix_global: "4416215,426",
    iy: "1167,1",
    iy_global: "485783696,8",
    iz: "40302,7",
    iz_global: 16775250277,
    tf: "0,875",
    tf_global: "22,225",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 1459,
    zx_global: "23908726,38",
    zy: 178,
    zy_global: "2916897,392",
    width: 20,
    width_global: 508,
    thickness: "0,875",
    thickness_global: "22,225",
    height: "61,75",
    height_global: "1568,45",
  },
  {
    profile_section_id: 602,
    name: "B612007",
    type: "B Shape",
    designation: "B612007",
    shape: "I",
    country_code: "American",
    ax: "56,25",
    ax_global: "36290,25",
    bf: 20,
    bf_global: 508,
    ct: 0,
    d: "61,5",
    d_global: "1562,1",
    ix: "7,3",
    ix_global: "3038489,407",
    iy: "1000,4",
    iy_global: "416397918,2",
    iz: "35555,6",
    iz_global: 14799358076,
    tf: "0,75",
    tf_global: "19,05",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 1305,
    zx_global: "21385118,52",
    zy: 153,
    zy_global: "2507220,792",
    width: 20,
    width_global: 508,
    thickness: "0,75",
    thickness_global: "19,05",
    height: "61,5",
    height_global: "1562,1",
  },
  {
    profile_section_id: 603,
    name: "B571825",
    type: "B Shape",
    designation: "B571825",
    shape: "I",
    country_code: "American",
    ax: "114,5",
    ax_global: "73870,82",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: 61,
    d_global: "1549,4",
    ix: "189,06",
    ix_global: "78692713,32",
    iy: "2430,4",
    iy_global: 1011608857,
    iz: "83450,2",
    iz_global: 34734595713,
    tf: "2,5",
    tf_global: "63,5",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 2976,
    zx_global: "48767902,46",
    zy: 408,
    zy_global: "6685922,112",
    width: 18,
    width_global: "457,2",
    thickness: "2,5",
    thickness_global: "63,5",
    height: 61,
    height_global: "1549,4",
  },
  {
    profile_section_id: 604,
    name: "B571820",
    type: "B Shape",
    designation: "B571820",
    shape: "I",
    country_code: "American",
    ax: "96,5",
    ax_global: "62257,94",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: 60,
    d_global: 1524,
    ix: "97,56",
    ix_global: "40607537,88",
    iy: "1944,4",
    iy_global: "809320383,9",
    iz: "66978,7",
    iz_global: 27878639786,
    tf: 2,
    tf_global: "50,8",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 2431,
    zx_global: "39836952,58",
    zy: 327,
    zy_global: "5358569,928",
    width: 18,
    width_global: "457,2",
    thickness: 2,
    thickness_global: "50,8",
    height: 60,
    height_global: 1524,
  },
  {
    profile_section_id: 605,
    name: "B571817",
    type: "B Shape",
    designation: "B571817",
    shape: "I",
    country_code: "American",
    ax: "87,5",
    ax_global: "56451,5",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: "59,5",
    d_global: "1511,3",
    ix: "65,88",
    ix_global: "27421326,32",
    iy: "1701,4",
    iy_global: "708176147,5",
    iz: 58946,
    iz_global: 24535177613,
    tf: "1,75",
    tf_global: "44,45",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 2162,
    zx_global: "35428832,37",
    zy: 286,
    zy_global: "4686700,304",
    width: 18,
    width_global: "457,2",
    thickness: "1,75",
    thickness_global: "44,45",
    height: "59,5",
    height_global: "1511,3",
  },
  {
    profile_section_id: 606,
    name: "B571815",
    type: "B Shape",
    designation: "B571815",
    shape: "I",
    country_code: "American",
    ax: "78,5",
    ax_global: "50645,06",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: 59,
    d_global: "1498,6",
    ix: "42,06",
    ix_global: "17506693,76",
    iy: "1458,4",
    iy_global: "607031911,1",
    iz: "51047,2",
    iz_global: 21247448829,
    tf: "1,5",
    tf_global: "38,1",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 1896,
    zx_global: "31069873,34",
    zy: 246,
    zy_global: "4031217,744",
    width: 18,
    width_global: "457,2",
    thickness: "1,5",
    thickness_global: "38,1",
    height: 59,
    height_global: "1498,6",
  },
  {
    profile_section_id: 607,
    name: "B571812",
    type: "B Shape",
    designation: "B571812",
    shape: "I",
    country_code: "American",
    ax: "69,5",
    ax_global: "44838,62",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: "58,5",
    d_global: "1485,9",
    ix: 25,
    ix_global: "10405785,64",
    iy: "1215,4",
    iy_global: "505887674,7",
    iz: "43281,1",
    iz_global: 18014953955,
    tf: "1,25",
    tf_global: "31,75",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 1631,
    zx_global: "26727301,38",
    zy: 205,
    zy_global: "3359348,12",
    width: 18,
    width_global: "457,2",
    thickness: "1,25",
    thickness_global: "31,75",
    height: "58,5",
    height_global: "1485,9",
  },
  {
    profile_section_id: 608,
    name: "B571810",
    type: "B Shape",
    designation: "B571810",
    shape: "I",
    country_code: "American",
    ax: "60,5",
    ax_global: "39032,18",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: 58,
    d_global: "1473,2",
    ix: "13,56",
    ix_global: "5644098,131",
    iy: "972,4",
    iy_global: "404743438,3",
    iz: "35646,7",
    iz_global: 14837276759,
    tf: 1,
    tf_global: "25,4",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 1369,
    zx_global: "22433890,62",
    zy: 165,
    zy_global: "2703865,56",
    width: 18,
    width_global: "457,2",
    thickness: 1,
    thickness_global: "25,4",
    height: 58,
    height_global: "1473,2",
  },
  {
    profile_section_id: 609,
    name: "B571808",
    type: "B Shape",
    designation: "B571808",
    shape: "I",
    country_code: "American",
    ax: 56,
    ax_global: "36128,96",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: "57,75",
    d_global: "1466,85",
    ix: "9,6",
    ix_global: "3995821,686",
    iy: "850,9",
    iy_global: 354171320,
    iz: "31878,5",
    iz_global: 13268833501,
    tf: "0,875",
    tf_global: "22,225",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 1239,
    zx_global: "20303572,3",
    zy: 144,
    zy_global: "2359737,216",
    width: 18,
    width_global: "457,2",
    thickness: "0,875",
    thickness_global: "22,225",
    height: "57,75",
    height_global: "1466,85",
  },
  {
    profile_section_id: 610,
    name: "B571807",
    type: "B Shape",
    designation: "B571807",
    shape: "I",
    country_code: "American",
    ax: "51,5",
    ax_global: "33225,74",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: "57,5",
    d_global: "1460,5",
    ix: "6,63",
    ix_global: "2759614,352",
    iy: "729,4",
    iy_global: "303599201,8",
    iz: "28142,7",
    iz_global: 11713876141,
    tf: "0,75",
    tf_global: "19,05",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 1109,
    zx_global: "18173253,98",
    zy: 124,
    zy_global: "2031995,936",
    width: 18,
    width_global: "457,2",
    thickness: "0,75",
    thickness_global: "19,05",
    height: "57,5",
    height_global: "1460,5",
  },
  {
    profile_section_id: 611,
    name: "B571806",
    type: "B Shape",
    designation: "B571806",
    shape: "I",
    country_code: "American",
    ax: 47,
    ax_global: "30322,52",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: "57,25",
    d_global: "1454,15",
    ix: "4,49",
    ix_global: "1868879,101",
    iy: "607,9",
    iy_global: "253027083,6",
    iz: "24439,3",
    iz_global: 10172404680,
    tf: "0,625",
    tf_global: "15,875",
    tw: "0,438",
    tw_global: "11,1252",
    zx: 980,
    zx_global: "16059322,72",
    zy: 104,
    zy_global: "1704254,656",
    width: 18,
    width_global: "457,2",
    thickness: "0,625",
    thickness_global: "15,875",
    height: "57,25",
    height_global: "1454,15",
  },
  {
    profile_section_id: 612,
    name: "B531822",
    type: "B Shape",
    designation: "B531822",
    shape: "I",
    country_code: "American",
    ax: "100,5",
    ax_global: "64838,58",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: "56,5",
    d_global: "1435,1",
    ix: "137,6",
    ix_global: "57273444,16",
    iy: "2187,2",
    iy_global: "910381374,1",
    iz: "64025,2",
    iz_global: 26649300270,
    tf: "2,25",
    tf_global: "57,15",
    tw: "0,375",
    tw_global: "9,525",
    zx: 2451,
    zx_global: "40164693,86",
    zy: 366,
    zy_global: "5997665,424",
    width: 18,
    width_global: "457,2",
    thickness: "2,25",
    thickness_global: "57,15",
    height: "56,5",
    height_global: "1435,1",
  },
  {
    profile_section_id: 613,
    name: "B531820",
    type: "B Shape",
    designation: "B531820",
    shape: "I",
    country_code: "American",
    ax: "91,5",
    ax_global: "59032,14",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: 56,
    d_global: "1422,4",
    ix: "96,91",
    ix_global: "40336987,45",
    iy: "1944,2",
    iy_global: "809237137,7",
    iz: 56906,
    iz_global: 23686065505,
    tf: 2,
    tf_global: "50,8",
    tw: "0,375",
    tw_global: "9,525",
    zx: 2198,
    zx_global: "36018766,67",
    zy: 326,
    zy_global: "5342182,864",
    width: 18,
    width_global: "457,2",
    thickness: 2,
    thickness_global: "50,8",
    height: 56,
    height_global: "1422,4",
  },
  {
    profile_section_id: 614,
    name: "B531817",
    type: "B Shape",
    designation: "B531817",
    shape: "I",
    country_code: "American",
    ax: "82,5",
    ax_global: "53225,7",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: "55,5",
    d_global: "1409,7",
    ix: "65,23",
    ix_global: "27150775,89",
    iy: "1701,2",
    iy_global: "708092901,2",
    iz: "49912,8",
    iz_global: 20775275900,
    tf: "1,75",
    tf_global: "44,45",
    tw: "0,375",
    tw_global: "9,525",
    zx: 1947,
    zx_global: "31905613,61",
    zy: 285,
    zy_global: "4670313,24",
    width: 18,
    width_global: "457,2",
    thickness: "1,75",
    thickness_global: "44,45",
    height: "55,5",
    height_global: "1409,7",
  },
  {
    profile_section_id: 615,
    name: "B531815",
    type: "B Shape",
    designation: "B531815",
    shape: "I",
    country_code: "American",
    ax: "73,5",
    ax_global: "47419,26",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: 55,
    d_global: 1397,
    ix: "41,41",
    ix_global: "17236143,33",
    iy: "1458,2",
    iy_global: "606948664,8",
    iz: "43044,5",
    iz_global: 17916473599,
    tf: "1,5",
    tf_global: "38,1",
    tw: "0,375",
    tw_global: "9,525",
    zx: 1698,
    zx_global: "27825234,67",
    zy: 245,
    zy_global: "4014830,68",
    width: 18,
    width_global: "457,2",
    thickness: "1,5",
    thickness_global: "38,1",
    height: 55,
    height_global: 1397,
  },
  {
    profile_section_id: 616,
    name: "B531812",
    type: "B Shape",
    designation: "B531812",
    shape: "I",
    country_code: "American",
    ax: "64,5",
    ax_global: "41612,82",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: "54,5",
    d_global: "1384,3",
    ix: "24,35",
    ix_global: "10135235,21",
    iy: "1215,2",
    iy_global: "505804428,4",
    iz: "36299,9",
    iz_global: 15109159126,
    tf: "1,25",
    tf_global: "31,75",
    tw: "0,375",
    tw_global: "9,525",
    zx: 1452,
    zx_global: "23794016,93",
    zy: 204,
    zy_global: "3342961,056",
    width: 18,
    width_global: "457,2",
    thickness: "1,25",
    thickness_global: "31,75",
    height: "54,5",
    height_global: "1384,3",
  },
  {
    profile_section_id: 617,
    name: "B531810",
    type: "B Shape",
    designation: "B531810",
    shape: "I",
    country_code: "American",
    ax: "55,5",
    ax_global: "35806,38",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: 54,
    d_global: "1371,6",
    ix: "12,91",
    ix_global: "5373547,704",
    iy: "972,2",
    iy_global: 404660192,
    iz: 29678,
    iz_global: 12352916249,
    tf: 1,
    tf_global: "25,4",
    tw: "0,375",
    tw_global: "9,525",
    zx: 1208,
    zx_global: "19795573,31",
    zy: 164,
    zy_global: "2687478,496",
    width: 18,
    width_global: "457,2",
    thickness: 1,
    thickness_global: "25,4",
    height: 54,
    height_global: "1371,6",
  },
  {
    profile_section_id: 618,
    name: "B531808",
    type: "B Shape",
    designation: "B531808",
    shape: "I",
    country_code: "American",
    ax: 51,
    ax_global: "32903,16",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: "53,75",
    d_global: "1365,25",
    ix: "8,95",
    ix_global: "3725271,259",
    iy: "850,7",
    iy_global: "354088073,8",
    iz: "26412,7",
    iz_global: 10993795775,
    tf: "0,875",
    tf_global: "22,225",
    tw: "0,375",
    tw_global: "9,525",
    zx: 1086,
    zx_global: "17796351,5",
    zy: 144,
    zy_global: "2359737,216",
    width: 18,
    width_global: "457,2",
    thickness: "0,875",
    thickness_global: "22,225",
    height: "53,75",
    height_global: "1365,25",
  },
  {
    profile_section_id: 619,
    name: "B531807",
    type: "B Shape",
    designation: "B531807",
    shape: "I",
    country_code: "American",
    ax: "46,5",
    ax_global: "29999,94",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: "53,5",
    d_global: "1358,9",
    ix: "5,98",
    ix_global: "2489063,925",
    iy: "729,2",
    iy_global: "303515955,5",
    iz: "23177,6",
    iz_global: 9647245490,
    tf: "0,75",
    tf_global: "19,05",
    tw: "0,375",
    tw_global: "9,525",
    zx: 966,
    zx_global: "15829903,82",
    zy: 123,
    zy_global: "2015608,872",
    width: 18,
    width_global: "457,2",
    thickness: "0,75",
    thickness_global: "19,05",
    height: "53,5",
    height_global: "1358,9",
  },
  {
    profile_section_id: 620,
    name: "B531806",
    type: "B Shape",
    designation: "B531806",
    shape: "I",
    country_code: "American",
    ax: 42,
    ax_global: "27096,72",
    bf: 18,
    bf_global: "457,2",
    ct: 0,
    d: "53,25",
    d_global: "1352,55",
    ix: "3,84",
    ix_global: "1598328,674",
    iy: "607,7",
    iy_global: "252943837,3",
    iz: "19972,6",
    iz_global: 8313223771,
    tf: "0,625",
    tf_global: "15,875",
    tw: "0,375",
    tw_global: "9,525",
    zx: 846,
    zx_global: "13863456,14",
    zy: 103,
    zy_global: "1687867,592",
    width: 18,
    width_global: "457,2",
    thickness: "0,625",
    thickness_global: "15,875",
    height: "53,25",
    height_global: "1352,55",
  },
  {
    profile_section_id: 621,
    name: "B491622",
    type: "B Shape",
    designation: "B491622",
    shape: "I",
    country_code: "American",
    ax: 90,
    ax_global: "58064,4",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: "52,5",
    d_global: "1333,5",
    ix: "122,34",
    ix_global: "50921752,61",
    iy: "1536,2",
    iy_global: 639414716,
    iz: "48937,5",
    iz_global: 20369325390,
    tf: "2,25",
    tf_global: "57,15",
    tw: "0,375",
    tw_global: "9,525",
    zx: 2025,
    zx_global: "33183804,6",
    zy: 290,
    zy_global: "4752248,56",
    width: 16,
    width_global: "406,4",
    thickness: "2,25",
    thickness_global: "57,15",
    height: "52,5",
    height_global: "1333,5",
  },
  {
    profile_section_id: 622,
    name: "B491620",
    type: "B Shape",
    designation: "B491620",
    shape: "I",
    country_code: "American",
    ax: 82,
    ax_global: "52903,12",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: 52,
    d_global: "1320,8",
    ix: "86,18",
    ix_global: "35870824,26",
    iy: "1365,5",
    iy_global: "568364011,7",
    iz: "43477,3",
    iz_global: 18096618560,
    tf: 2,
    tf_global: "50,8",
    tw: "0,375",
    tw_global: "9,525",
    zx: 1816,
    zx_global: "29758908,22",
    zy: 258,
    zy_global: "4227862,512",
    width: 16,
    width_global: "406,4",
    thickness: 2,
    thickness_global: "50,8",
    height: 52,
    height_global: "1320,8",
  },
  {
    profile_section_id: 623,
    name: "B491617",
    type: "B Shape",
    designation: "B491617",
    shape: "I",
    country_code: "American",
    ax: 74,
    ax_global: "47741,84",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: "51,5",
    d_global: "1308,1",
    ix: "58,01",
    ix_global: 24145585,
    iy: "1194,9",
    iy_global: "497354930,4",
    iz: "38121,2",
    iz_global: 15867241422,
    tf: "1,75",
    tf_global: "44,45",
    tw: "0,375",
    tw_global: "9,525",
    zx: 1609,
    zx_global: "26366785,98",
    zy: 226,
    zy_global: "3703476,464",
    width: 16,
    width_global: "406,4",
    thickness: "1,75",
    thickness_global: "44,45",
    height: "51,5",
    height_global: "1308,1",
  },
  {
    profile_section_id: 624,
    name: "B491615",
    type: "B Shape",
    designation: "B491615",
    shape: "I",
    country_code: "American",
    ax: 66,
    ax_global: "42580,56",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: 51,
    d_global: "1295,4",
    ix: "36,84",
    ix_global: "15333965,72",
    iy: "1024,2",
    iy_global: "426304226,1",
    iz: 32868,
    iz_global: 13680694497,
    tf: "1,5",
    tf_global: "38,1",
    tw: "0,375",
    tw_global: "9,525",
    zx: 1404,
    zx_global: "23007437,86",
    zy: 194,
    zy_global: "3179090,416",
    width: 16,
    width_global: "406,4",
    thickness: "1,5",
    thickness_global: "38,1",
    height: 51,
    height_global: "1295,4",
  },
  {
    profile_section_id: 625,
    name: "B491612",
    type: "B Shape",
    designation: "B491612",
    shape: "I",
    country_code: "American",
    ax: 58,
    ax_global: "37419,28",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: "50,5",
    d_global: "1282,7",
    ix: "21,68",
    ix_global: "9023897,307",
    iy: "853,5",
    iy_global: "355253521,7",
    iz: "27716,8",
    iz_global: 11536603177,
    tf: "1,25",
    tf_global: "31,75",
    tw: "0,375",
    tw_global: "9,525",
    zx: 1201,
    zx_global: "19680863,86",
    zy: 162,
    zy_global: "2654704,368",
    width: 16,
    width_global: "406,4",
    thickness: "1,25",
    thickness_global: "31,75",
    height: "50,5",
    height_global: "1282,7",
  },
  {
    profile_section_id: 626,
    name: "B491610",
    type: "B Shape",
    designation: "B491610",
    shape: "I",
    country_code: "American",
    ax: 50,
    ax_global: 32258,
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: 50,
    d_global: 1270,
    ix: "11,51",
    ix_global: "4790823,709",
    iy: "682,9",
    iy_global: "284244440,5",
    iz: "22666,7",
    iz_global: 9434592855,
    tf: 1,
    tf_global: "25,4",
    tw: "0,375",
    tw_global: "9,525",
    zx: 1000,
    zx_global: 16387064,
    zy: 130,
    zy_global: "2130318,32",
    width: 16,
    width_global: "406,4",
    thickness: 1,
    thickness_global: "25,4",
    height: 50,
    height_global: 1270,
  },
  {
    profile_section_id: 627,
    name: "B491608",
    type: "B Shape",
    designation: "B491608",
    shape: "I",
    country_code: "American",
    ax: 46,
    ax_global: "29677,36",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: "49,75",
    d_global: "1263,65",
    ix: "7,99",
    ix_global: "3325689,091",
    iy: "597,5",
    iy_global: "248698276,8",
    iz: "20179,1",
    iz_global: 8399175560,
    tf: "0,875",
    tf_global: "22,225",
    tw: "0,375",
    tw_global: "9,525",
    zx: 900,
    zx_global: "14748357,6",
    zy: 114,
    zy_global: "1868125,296",
    width: 16,
    width_global: "406,4",
    thickness: "0,875",
    thickness_global: "22,225",
    height: "49,75",
    height_global: "1263,65",
  },
  {
    profile_section_id: 628,
    name: "B491607",
    type: "B Shape",
    designation: "B491607",
    shape: "I",
    country_code: "American",
    ax: 42,
    ax_global: "27096,72",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: "49,5",
    d_global: "1257,3",
    ix: "5,34",
    ix_global: "2222675,813",
    iy: "512,2",
    iy_global: "213193736,2",
    iz: "17716,5",
    iz_global: 7374164052,
    tf: "0,75",
    tf_global: "19,05",
    tw: "0,375",
    tw_global: "9,525",
    zx: 801,
    zx_global: "13126038,26",
    zy: 98,
    zy_global: "1605932,272",
    width: 16,
    width_global: "406,4",
    thickness: "0,75",
    thickness_global: "19,05",
    height: "49,5",
    height_global: "1257,3",
  },
  {
    profile_section_id: 629,
    name: "B491606",
    type: "B Shape",
    designation: "B491606",
    shape: "I",
    country_code: "American",
    ax: 38,
    ax_global: "24516,08",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: "49,25",
    d_global: "1250,95",
    ix: "3,45",
    ix_global: "1435998,418",
    iy: "426,9",
    iy_global: "177689195,6",
    iz: "15278,6",
    iz_global: 6359433459,
    tf: "0,625",
    tf_global: "15,875",
    tw: "0,375",
    tw_global: "9,525",
    zx: 702,
    zx_global: "11503718,93",
    zy: 82,
    zy_global: "1343739,248",
    width: 16,
    width_global: "406,4",
    thickness: "0,625",
    thickness_global: "15,875",
    height: "49,25",
    height_global: "1250,95",
  },
  {
    profile_section_id: 630,
    name: "B451617",
    type: "B Shape",
    designation: "B451617",
    shape: "I",
    country_code: "American",
    ax: "69,75",
    ax_global: "44999,91",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: "47,5",
    d_global: "1206,5",
    ix: "57,61",
    ix_global: "23979092,43",
    iy: "1194,8",
    iy_global: "497313307,3",
    iz: "31535,5",
    iz_global: 13126066122,
    tf: "1,75",
    tf_global: "44,45",
    tw: "0,313",
    tw_global: "7,9502",
    zx: 1432,
    zx_global: "23466275,65",
    zy: 225,
    zy_global: "3687089,4",
    width: 16,
    width_global: "406,4",
    thickness: "1,75",
    thickness_global: "44,45",
    height: "47,5",
    height_global: "1206,5",
  },
  {
    profile_section_id: 631,
    name: "B451615",
    type: "B Shape",
    designation: "B451615",
    shape: "I",
    country_code: "American",
    ax: "61,75",
    ax_global: "39838,63",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: 47,
    d_global: "1193,8",
    ix: "36,45",
    ix_global: "15171635,46",
    iy: "1024,1",
    iy_global: 426262603,
    iz: "27070,3",
    iz_global: 11267509560,
    tf: "1,5",
    tf_global: "38,1",
    tw: "0,313",
    tw_global: "7,9502",
    zx: 1243,
    zx_global: "20369120,55",
    zy: 193,
    zy_global: "3162703,352",
    width: 16,
    width_global: "406,4",
    thickness: "1,5",
    thickness_global: "38,1",
    height: 47,
    height_global: "1193,8",
  },
  {
    profile_section_id: 632,
    name: "B451612",
    type: "B Shape",
    designation: "B451612",
    shape: "I",
    country_code: "American",
    ax: "53,75",
    ax_global: "34677,35",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: "46,5",
    d_global: "1181,1",
    ix: "21,28",
    ix_global: "8857404,737",
    iy: "853,4",
    iy_global: "355211898,6",
    iz: "22699,2",
    iz_global: 9448120376,
    tf: "1,25",
    tf_global: "31,75",
    tw: "0,313",
    tw_global: "7,9502",
    zx: 1056,
    zx_global: "17304739,58",
    zy: 161,
    zy_global: "2638317,304",
    width: 16,
    width_global: "406,4",
    thickness: "1,25",
    thickness_global: "31,75",
    height: "46,5",
    height_global: "1181,1",
  },
  {
    profile_section_id: 633,
    name: "B451610",
    type: "B Shape",
    designation: "B451610",
    shape: "I",
    country_code: "American",
    ax: "45,75",
    ax_global: "29516,07",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: 46,
    d_global: "1168,4",
    ix: "11,11",
    ix_global: "4624331,138",
    iy: "682,8",
    iy_global: "284202817,4",
    iz: 18421,
    iz_global: 7667399091,
    tf: 1,
    tf_global: "25,4",
    tw: "0,313",
    tw_global: "7,9502",
    zx: 871,
    zx_global: "14273132,74",
    zy: 129,
    zy_global: "2113931,256",
    width: 16,
    width_global: "406,4",
    thickness: 1,
    thickness_global: "25,4",
    height: 46,
    height_global: "1168,4",
  },
  {
    profile_section_id: 634,
    name: "B451608",
    type: "B Shape",
    designation: "B451608",
    shape: "I",
    country_code: "American",
    ax: "41,75",
    ax_global: "26935,43",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: "45,75",
    d_global: "1162,05",
    ix: "7,59",
    ix_global: "3159196,52",
    iy: "597,4",
    iy_global: "248656653,7",
    iz: "16316,5",
    iz_global: 6791440056,
    tf: "0,875",
    tf_global: "22,225",
    tw: "0,313",
    tw_global: "7,9502",
    zx: 780,
    zx_global: "12781909,92",
    zy: 113,
    zy_global: "1851738,232",
    width: 16,
    width_global: "406,4",
    thickness: "0,875",
    thickness_global: "22,225",
    height: "45,75",
    height_global: "1162,05",
  },
  {
    profile_section_id: 635,
    name: "B451607",
    type: "B Shape",
    designation: "B451607",
    shape: "I",
    country_code: "American",
    ax: "37,75",
    ax_global: "24354,79",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: "45,5",
    d_global: "1155,7",
    ix: "4,95",
    ix_global: "2060345,557",
    iy: "512,1",
    iy_global: 213152113,
    iz: "14234,8",
    iz_global: 5924971097,
    tf: "0,75",
    tf_global: "19,05",
    tw: "0,313",
    tw_global: "7,9502",
    zx: 688,
    zx_global: "11274300,03",
    zy: 97,
    zy_global: "1589545,208",
    width: 16,
    width_global: "406,4",
    thickness: "0,75",
    thickness_global: "19,05",
    height: "45,5",
    height_global: "1155,7",
  },
  {
    profile_section_id: 636,
    name: "B451606",
    type: "B Shape",
    designation: "B451606",
    shape: "I",
    country_code: "American",
    ax: "33,75",
    ax_global: "21774,15",
    bf: 16,
    bf_global: "406,4",
    ct: 0,
    d: "45,25",
    d_global: "1149,35",
    ix: "3,05",
    ix_global: "1269505,848",
    iy: "426,8",
    iy_global: "177647572,4",
    iz: "12175,9",
    iz_global: 5067992215,
    tf: "0,625",
    tf_global: "15,875",
    tw: "0,313",
    tw_global: "7,9502",
    zx: 598,
    zx_global: "9799464,272",
    zy: 81,
    zy_global: "1327352,184",
    width: 16,
    width_global: "406,4",
    thickness: "0,625",
    thickness_global: "15,875",
    height: "45,25",
    height_global: "1149,35",
  },
  {
    profile_section_id: 658,
    name: "C15X50",
    type: "Channel",
    designation: "C15X50",
    shape: "C",
    country_code: "American",
    ax: "14,7",
    ax_global: "9483,852",
    b: 15,
    b_global: 381,
    bf: "3,72",
    bf_global: "94,488",
    ct: "0,8",
    ct_global: "20,32",
    ix: "2,65",
    ix_global: "1103013,278",
    iy: 11,
    iy_global: "4578545,682",
    iz: 404,
    iz_global: "168157495,9",
    tf: "0,65",
    tf_global: "16,51",
    tw: "0,72",
    tw_global: "18,288",
    zx: "68,5",
    zx_global: "1122513,884",
    zy: "8,14",
    zy_global: "133390,701",
    width: "3,72",
    width_global: "94,488",
    thickness: "0,65",
    thickness_global: "16,51",
    height: 15,
    height_global: 381,
  },
  {
    profile_section_id: 659,
    name: "C15X40",
    type: "Channel",
    designation: "C15X40",
    shape: "C",
    country_code: "American",
    ax: "11,8",
    ax_global: "7612,888",
    b: 15,
    b_global: 381,
    bf: "3,52",
    bf_global: "89,408",
    ct: "0,78",
    ct_global: "19,812",
    ix: "1,45",
    ix_global: "603535,5671",
    iy: "9,17",
    iy_global: "3816842,173",
    iz: 348,
    iz_global: "144848536,1",
    tf: "0,65",
    tf_global: "16,51",
    tw: "0,52",
    tw_global: "13,208",
    zx: "57,5",
    zx_global: "942256,18",
    zy: "6,84",
    zy_global: "112087,5178",
    width: "3,52",
    width_global: "89,408",
    thickness: "0,65",
    thickness_global: "16,51",
    height: 15,
    height_global: 381,
  },
  {
    profile_section_id: 660,
    name: "C15X33.9",
    type: "Channel",
    designation: "C15X33",
    shape: "C",
    country_code: "American",
    ax: 10,
    ax_global: "6451,6",
    b: 15,
    b_global: 381,
    bf: "3,4",
    bf_global: "86,36",
    ct: "0,79",
    ct_global: "20,066",
    ix: "1,01",
    ix_global: "420393,7399",
    iy: "8,07",
    iy_global: "3358987,605",
    iz: 315,
    iz_global: "131112899,1",
    tf: "0,65",
    tf_global: "16,51",
    tw: "0,4",
    tw_global: "10,16",
    zx: "50,8",
    zx_global: "832462,8512",
    zy: "6,19",
    zy_global: "101435,9262",
    width: "3,4",
    width_global: "86,36",
    thickness: "0,65",
    thickness_global: "16,51",
    height: 15,
    height_global: 381,
  },
  {
    profile_section_id: 661,
    name: "C12X30",
    type: "Channel",
    designation: "C12X30",
    shape: "C",
    country_code: "American",
    ax: "8,81",
    ax_global: "5683,8596",
    b: 12,
    b_global: "304,8",
    bf: "3,17",
    bf_global: "80,518",
    ct: "0,67",
    ct_global: "17,018",
    ix: "0,86",
    ix_global: "357959,026",
    iy: "5,12",
    iy_global: "2131104,899",
    iz: 162,
    iz_global: "67429490,95",
    tf: "0,5",
    tf_global: "12,7",
    tw: "0,51",
    tw_global: "12,954",
    zx: "33,8",
    zx_global: "553882,7632",
    zy: "4,32",
    zy_global: "70792,11648",
    width: "3,17",
    width_global: "80,518",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 662,
    name: "C12X25",
    type: "Channel",
    designation: "C12X25",
    shape: "C",
    country_code: "American",
    ax: "7,34",
    ax_global: "4735,4744",
    b: 12,
    b_global: "304,8",
    bf: "3,05",
    bf_global: "77,47",
    ct: "0,67",
    ct_global: "17,018",
    ix: "0,54",
    ix_global: "224764,9698",
    iy: "4,45",
    iy_global: "1852229,844",
    iz: 144,
    iz_global: "59937325,29",
    tf: "0,5",
    tf_global: "12,7",
    tw: "0,39",
    tw_global: "9,906",
    zx: "29,4",
    zx_global: "481779,6816",
    zy: "3,82",
    zy_global: "62598,58448",
    width: "3,05",
    width_global: "77,47",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 663,
    name: "C12X20.7",
    type: "Channel",
    designation: "C12X20",
    shape: "C",
    country_code: "American",
    ax: "6,08",
    ax_global: "3922,5728",
    b: 12,
    b_global: "304,8",
    bf: "2,94",
    bf_global: "74,676",
    ct: "0,7",
    ct_global: "17,78",
    ix: "0,37",
    ix_global: "154005,6275",
    iy: "3,86",
    iy_global: "1606653,303",
    iz: 129,
    iz_global: "53693853,9",
    tf: "0,5",
    tf_global: "12,7",
    tw: "0,28",
    tw_global: "7,112",
    zx: "25,6",
    zx_global: "419508,8384",
    zy: "3,47",
    zy_global: "56863,11208",
    width: "2,94",
    width_global: "74,676",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 664,
    name: "C10X30",
    type: "Channel",
    designation: "C10X30",
    shape: "C",
    country_code: "American",
    ax: "8,81",
    ax_global: "5683,8596",
    b: 10,
    b_global: 254,
    bf: "3,03",
    bf_global: "76,962",
    ct: "0,65",
    ct_global: "16,51",
    ix: "1,22",
    ix_global: "507802,3392",
    iy: "3,93",
    iy_global: "1635789,503",
    iz: 103,
    iz_global: "42871836,84",
    tf: "0,44",
    tf_global: "11,176",
    tw: "0,67",
    tw_global: "17,018",
    zx: "26,7",
    zx_global: "437534,6088",
    zy: "3,78",
    zy_global: "61943,10192",
    width: "3,03",
    width_global: "76,962",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 665,
    name: "C10X25",
    type: "Channel",
    designation: "C10X25",
    shape: "C",
    country_code: "American",
    ax: "7,35",
    ax_global: "4741,926",
    b: 10,
    b_global: 254,
    bf: "2,89",
    bf_global: "73,406",
    ct: "0,62",
    ct_global: "15,748",
    ix: "0,69",
    ix_global: "287199,6837",
    iy: "3,34",
    iy_global: "1390212,962",
    iz: "91,1",
    iz_global: "37918682,87",
    tf: "0,44",
    tf_global: "11,176",
    tw: "0,53",
    tw_global: "13,462",
    zx: "23,1",
    zx_global: "378541,1784",
    zy: "3,18",
    zy_global: "52110,86352",
    width: "2,89",
    width_global: "73,406",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 666,
    name: "C10X20",
    type: "Channel",
    designation: "C10X20",
    shape: "C",
    country_code: "American",
    ax: "5,87",
    ax_global: "3787,0892",
    b: 10,
    b_global: 254,
    bf: "2,74",
    bf_global: "69,596",
    ct: "0,61",
    ct_global: "15,494",
    ix: "0,37",
    ix_global: "154005,6275",
    iy: "2,8",
    iy_global: "1165447,992",
    iz: "78,9",
    iz_global: "32840659,48",
    tf: "0,44",
    tf_global: "11,176",
    tw: "0,38",
    tw_global: "9,652",
    zx: "19,4",
    zx_global: "317909,0416",
    zy: "2,7",
    zy_global: "44245,0728",
    width: "2,74",
    width_global: "69,596",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 667,
    name: "C10X15.3",
    type: "Channel",
    designation: "C10X15",
    shape: "C",
    country_code: "American",
    ax: "4,48",
    ax_global: "2890,3168",
    b: 10,
    b_global: 254,
    bf: "2,6",
    bf_global: "66,04",
    ct: "0,63",
    ct_global: "16,002",
    ix: "0,21",
    ix_global: "87408,59938",
    iy: "2,27",
    iy_global: "944845,3361",
    iz: "67,3",
    iz_global: "28012374,94",
    tf: "0,44",
    tf_global: "11,176",
    tw: "0,24",
    tw_global: "6,096",
    zx: "15,9",
    zx_global: "260554,3176",
    zy: "2,34",
    zy_global: "38345,72976",
    width: "2,6",
    width_global: "66,04",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 668,
    name: "C9X20",
    type: "Channel",
    designation: "C9X20",
    shape: "C",
    country_code: "American",
    ax: "5,87",
    ax_global: "3787,0892",
    b: 9,
    b_global: "228,6",
    bf: "2,65",
    bf_global: "67,31",
    ct: "0,58",
    ct_global: "14,732",
    ix: "0,43",
    ix_global: "178979,513",
    iy: "2,41",
    iy_global: "1003117,736",
    iz: "60,9",
    iz_global: "25348493,82",
    tf: "0,41",
    tf_global: "10,414",
    tw: "0,45",
    tw_global: "11,43",
    zx: "16,9",
    zx_global: "276941,3816",
    zy: "2,46",
    zy_global: "40312,17744",
    width: "2,65",
    width_global: "67,31",
    thickness: "0,41",
    thickness_global: "10,414",
    height: 9,
    height_global: "228,6",
  },
  {
    profile_section_id: 669,
    name: "C9X15",
    type: "Channel",
    designation: "C9X15",
    shape: "C",
    country_code: "American",
    ax: "4,4",
    ax_global: "2838,704",
    b: 9,
    b_global: "228,6",
    bf: "2,49",
    bf_global: "63,246",
    ct: "0,59",
    ct_global: "14,986",
    ix: "0,21",
    ix_global: "87408,59938",
    iy: "1,91",
    iy_global: "795002,0229",
    iz: 51,
    iz_global: "21227802,71",
    tf: "0,41",
    tf_global: "10,414",
    tw: "0,28",
    tw_global: "7,112",
    zx: "13,6",
    zx_global: "222864,0704",
    zy: "2,04",
    zy_global: "33429,61056",
    width: "2,49",
    width_global: "63,246",
    thickness: "0,41",
    thickness_global: "10,414",
    height: 9,
    height_global: "228,6",
  },
  {
    profile_section_id: 670,
    name: "C9X13.4",
    type: "Channel",
    designation: "C9X13",
    shape: "C",
    country_code: "American",
    ax: "3,94",
    ax_global: "2541,9304",
    b: 9,
    b_global: "228,6",
    bf: "2,43",
    bf_global: "61,722",
    ct: "0,6",
    ct_global: "15,24",
    ix: "0,17",
    ix_global: "70759,34235",
    iy: "1,75",
    iy_global: "728404,9948",
    iz: "47,8",
    iz_global: "19895862,14",
    tf: "0,41",
    tf_global: "10,414",
    tw: "0,23",
    tw_global: "5,842",
    zx: "12,6",
    zx_global: "206477,0064",
    zy: "1,94",
    zy_global: "31790,90416",
    width: "2,43",
    width_global: "61,722",
    thickness: "0,41",
    thickness_global: "10,414",
    height: 9,
    height_global: "228,6",
  },
  {
    profile_section_id: 671,
    name: "C8X18.75",
    type: "Channel",
    designation: "C8X18",
    shape: "C",
    country_code: "American",
    ax: "5,51",
    ax_global: "3554,8316",
    b: 8,
    b_global: "203,2",
    bf: "2,53",
    bf_global: "64,262",
    ct: "0,56",
    ct_global: "14,224",
    ix: "0,43",
    ix_global: "178979,513",
    iy: "1,97",
    iy_global: "819975,9084",
    iz: "43,9",
    iz_global: "18272559,58",
    tf: "0,39",
    tf_global: "9,906",
    tw: "0,49",
    tw_global: "12,446",
    zx: "13,9",
    zx_global: "227780,1896",
    zy: "2,17",
    zy_global: "35559,92888",
    width: "2,53",
    width_global: "64,262",
    thickness: "0,39",
    thickness_global: "9,906",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 672,
    name: "C8X13.75",
    type: "Channel",
    designation: "C8X13",
    shape: "C",
    country_code: "American",
    ax: "4,03",
    ax_global: "2599,9948",
    b: 8,
    b_global: "203,2",
    bf: "2,34",
    bf_global: "59,436",
    ct: "0,55",
    ct_global: "13,97",
    ix: "0,19",
    ix_global: "79083,97086",
    iy: "1,52",
    iy_global: "632671,7669",
    iz: "36,1",
    iz_global: "15025954,46",
    tf: "0,39",
    tf_global: "9,906",
    tw: "0,3",
    tw_global: "7,62",
    zx: 11,
    zx_global: "180257,704",
    zy: "1,73",
    zy_global: "28349,62072",
    width: "2,34",
    width_global: "59,436",
    thickness: "0,39",
    thickness_global: "9,906",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 673,
    name: "C8X11.5",
    type: "Channel",
    designation: "C8X11",
    shape: "C",
    country_code: "American",
    ax: "3,37",
    ax_global: "2174,1892",
    b: 8,
    b_global: "203,2",
    bf: "2,26",
    bf_global: "57,404",
    ct: "0,57",
    ct_global: "14,478",
    ix: "0,13",
    ix_global: "54110,08533",
    iy: "1,31",
    iy_global: "545263,1675",
    iz: "32,5",
    iz_global: "13527521,33",
    tf: "0,39",
    tf_global: "9,906",
    tw: "0,22",
    tw_global: "5,588",
    zx: "9,63",
    zx_global: "157807,4263",
    zy: "1,57",
    zy_global: "25727,69048",
    width: "2,26",
    width_global: "57,404",
    thickness: "0,39",
    thickness_global: "9,906",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 674,
    name: "C7X14.75",
    type: "Channel",
    designation: "C7X14",
    shape: "C",
    country_code: "American",
    ax: "4,33",
    ax_global: "2793,5428",
    b: 7,
    b_global: "177,8",
    bf: "2,3",
    bf_global: "58,42",
    ct: "0,53",
    ct_global: "13,462",
    ix: "0,27",
    ix_global: "112382,4849",
    iy: "1,37",
    iy_global: "570237,0531",
    iz: "27,2",
    iz_global: "11321494,78",
    tf: "0,37",
    tf_global: "9,398",
    tw: "0,42",
    tw_global: "10,668",
    zx: "9,75",
    zx_global: "159773,874",
    zy: "1,63",
    zy_global: "26710,91432",
    width: "2,3",
    width_global: "58,42",
    thickness: "0,37",
    thickness_global: "9,398",
    height: 7,
    height_global: "177,8",
  },
  {
    profile_section_id: 675,
    name: "C7X12.25",
    type: "Channel",
    designation: "C7X12",
    shape: "C",
    country_code: "American",
    ax: "3,59",
    ax_global: "2316,1244",
    b: 7,
    b_global: "177,8",
    bf: "2,19",
    bf_global: "55,626",
    ct: "0,53",
    ct_global: "13,462",
    ix: "0,16",
    ix_global: "66597,0281",
    iy: "1,16",
    iy_global: "482828,4537",
    iz: "24,2",
    iz_global: "10072800,5",
    tf: "0,37",
    tf_global: "9,398",
    tw: "0,31",
    tw_global: "7,874",
    zx: "8,46",
    zx_global: "138634,5614",
    zy: "1,42",
    zy_global: "23269,63088",
    width: "2,19",
    width_global: "55,626",
    thickness: "0,37",
    thickness_global: "9,398",
    height: 7,
    height_global: "177,8",
  },
  {
    profile_section_id: 676,
    name: "C7X9.8",
    type: "Channel",
    designation: "C7X9",
    shape: "C",
    country_code: "American",
    ax: "2,87",
    ax_global: "1851,6092",
    b: 7,
    b_global: "177,8",
    bf: "2,09",
    bf_global: "53,086",
    ct: "0,54",
    ct_global: "13,716",
    ix: "0,1",
    ix_global: "41623,14256",
    iy: "0,96",
    iy_global: "399582,1686",
    iz: "21,2",
    iz_global: "8824106,223",
    tf: "0,37",
    tf_global: "9,398",
    tw: "0,21",
    tw_global: "5,334",
    zx: "7,19",
    zx_global: "117822,9902",
    zy: "1,26",
    zy_global: "20647,70064",
    width: "2,09",
    width_global: "53,086",
    thickness: "0,37",
    thickness_global: "9,398",
    height: 7,
    height_global: "177,8",
  },
  {
    profile_section_id: 677,
    name: "C6X13",
    type: "Channel",
    designation: "C6X13",
    shape: "C",
    country_code: "American",
    ax: "3,82",
    ax_global: "2464,5112",
    b: 6,
    b_global: "152,4",
    bf: "2,16",
    bf_global: "54,864",
    ct: "0,51",
    ct_global: "12,954",
    ix: "0,24",
    ix_global: "99895,54214",
    iy: "1,05",
    iy_global: "437042,9969",
    iz: "17,3",
    iz_global: "7200803,663",
    tf: "0,34",
    tf_global: "8,636",
    tw: "0,44",
    tw_global: "11,176",
    zx: "7,29",
    zx_global: "119461,6966",
    zy: "1,35",
    zy_global: "22122,5364",
    width: "2,16",
    width_global: "54,864",
    thickness: "0,34",
    thickness_global: "8,636",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 678,
    name: "C6X10.5",
    type: "Channel",
    designation: "C6X10",
    shape: "C",
    country_code: "American",
    ax: "3,07",
    ax_global: "1980,6412",
    b: 6,
    b_global: "152,4",
    bf: "2,03",
    bf_global: "51,562",
    ct: "0,5",
    ct_global: "12,7",
    ix: "0,13",
    ix_global: "54110,08533",
    iy: "0,86",
    iy_global: "357959,026",
    iz: "15,1",
    iz_global: "6285094,527",
    tf: "0,34",
    tf_global: "8,636",
    tw: "0,31",
    tw_global: "7,874",
    zx: "6,18",
    zx_global: "101272,0555",
    zy: "1,14",
    zy_global: "18681,25296",
    width: "2,03",
    width_global: "51,562",
    thickness: "0,34",
    thickness_global: "8,636",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 679,
    name: "C6X8.2",
    type: "Channel",
    designation: "C6X8",
    shape: "C",
    country_code: "American",
    ax: "2,39",
    ax_global: "1541,9324",
    b: 6,
    b_global: "152,4",
    bf: "1,92",
    bf_global: "48,768",
    ct: "0,51",
    ct_global: "12,954",
    ix: "0,07",
    ix_global: "29136,19979",
    iy: "0,69",
    iy_global: "287199,6837",
    iz: "13,1",
    iz_global: "5452631,675",
    tf: "0,34",
    tf_global: "8,636",
    tw: "0,2",
    tw_global: "5,08",
    zx: "5,16",
    zx_global: "84557,25024",
    zy: "0,99",
    zy_global: "16223,19336",
    width: "1,92",
    width_global: "48,768",
    thickness: "0,34",
    thickness_global: "8,636",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 680,
    name: "C5X9",
    type: "Channel",
    designation: "C5X9",
    shape: "C",
    country_code: "American",
    ax: "2,64",
    ax_global: "1703,2224",
    b: 5,
    b_global: 127,
    bf: "1,89",
    bf_global: "48,006",
    ct: "0,48",
    ct_global: "12,192",
    ix: "0,11",
    ix_global: "45785,45682",
    iy: "0,62",
    iy_global: "258063,4839",
    iz: "8,89",
    iz_global: "3700297,374",
    tf: "0,32",
    tf_global: "8,128",
    tw: "0,33",
    tw_global: "8,382",
    zx: "4,39",
    zx_global: "71939,21096",
    zy: "0,91",
    zy_global: "14912,22824",
    width: "1,89",
    width_global: "48,006",
    thickness: "0,32",
    thickness_global: "8,128",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 681,
    name: "C5X6.7",
    type: "Channel",
    designation: "C5X6",
    shape: "C",
    country_code: "American",
    ax: "1,97",
    ax_global: "1270,9652",
    b: 5,
    b_global: 127,
    bf: "1,75",
    bf_global: "44,45",
    ct: "0,48",
    ct_global: "12,192",
    ix: "0,05",
    ix_global: "20811,57128",
    iy: "0,47",
    iy_global: "195628,77",
    iz: "7,48",
    iz_global: "3113411,063",
    tf: "0,32",
    tf_global: "8,128",
    tw: "0,19",
    tw_global: "4,826",
    zx: "3,55",
    zx_global: "58174,0772",
    zy: "0,76",
    zy_global: "12454,16864",
    width: "1,75",
    width_global: "44,45",
    thickness: "0,32",
    thickness_global: "8,128",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 682,
    name: "C4X7.25",
    type: "Channel",
    designation: "C4X7",
    shape: "C",
    country_code: "American",
    ax: "2,13",
    ax_global: "1374,1908",
    b: 4,
    b_global: "101,6",
    bf: "1,72",
    bf_global: "43,688",
    ct: "0,46",
    ct_global: "11,684",
    ix: "0,08",
    ix_global: "33298,51405",
    iy: "0,42",
    iy_global: "174817,1988",
    iz: "4,58",
    iz_global: "1906339,929",
    tf: "0,3",
    tf_global: "7,62",
    tw: "0,32",
    tw_global: "8,128",
    zx: "2,84",
    zx_global: "46539,26176",
    zy: "0,69",
    zy_global: "11307,07416",
    width: "1,72",
    width_global: "43,688",
    thickness: "0,3",
    thickness_global: "7,62",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 683,
    name: "C4X6.25",
    type: "Channel",
    designation: "C4X6",
    shape: "C",
    country_code: "American",
    ax: "1,77",
    ax_global: "1141,9332",
    b: 4,
    b_global: "101,6",
    bf: "1,65",
    bf_global: "41,91",
    ct: "0,44",
    ct_global: "11,176",
    ix: "0,05",
    ix_global: "20811,57128",
    iy: "0,34",
    iy_global: "141518,6847",
    iz: 4,
    iz_global: "1664925,702",
    tf: "0,27",
    tf_global: "6,858",
    tw: "0,25",
    tw_global: "6,35",
    zx: "2,43",
    zx_global: "39820,56552",
    zy: "0,57",
    zy_global: "9340,62648",
    width: "1,65",
    width_global: "41,91",
    thickness: "0,27",
    thickness_global: "6,858",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 684,
    name: "C4X5.4",
    type: "Channel",
    designation: "C4X5",
    shape: "C",
    country_code: "American",
    ax: "1,58",
    ax_global: "1019,3528",
    b: 4,
    b_global: "101,6",
    bf: "1,58",
    bf_global: "40,132",
    ct: "0,46",
    ct_global: "11,684",
    ix: "0,04",
    ix_global: "16649,25702",
    iy: "0,31",
    iy_global: "129031,7419",
    iz: "3,85",
    iz_global: "1602490,989",
    tf: "0,3",
    tf_global: "7,62",
    tw: "0,18",
    tw_global: "4,572",
    zx: "2,29",
    zx_global: "37526,37656",
    zy: "0,56",
    zy_global: "9176,75584",
    width: "1,58",
    width_global: "40,132",
    thickness: "0,3",
    thickness_global: "7,62",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 685,
    name: "C4X4.5",
    type: "Channel",
    designation: "C4X4",
    shape: "C",
    country_code: "American",
    ax: "1,38",
    ax_global: "890,3208",
    b: 4,
    b_global: "101,6",
    bf: "1,58",
    bf_global: "40,132",
    ct: "0,49",
    ct_global: "12,446",
    ix: "0,03",
    ix_global: "12486,94277",
    iy: "0,29",
    iy_global: "120707,1134",
    iz: "3,65",
    iz_global: "1519244,703",
    tf: "0,3",
    tf_global: "7,62",
    tw: "0,13",
    tw_global: "3,302",
    zx: "2,12",
    zx_global: "34740,57568",
    zy: "0,53",
    zy_global: "8685,14392",
    width: "1,58",
    width_global: "40,132",
    thickness: "0,3",
    thickness_global: "7,62",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 686,
    name: "C3X6",
    type: "Channel",
    designation: "C3X6",
    shape: "C",
    country_code: "American",
    ax: "1,76",
    ax_global: "1135,4816",
    b: 3,
    b_global: "76,2",
    bf: "1,6",
    bf_global: "40,64",
    ct: "0,46",
    ct_global: "11,684",
    ix: "0,07",
    ix_global: "29136,19979",
    iy: "0,3",
    iy_global: "124869,4277",
    iz: "2,07",
    iz_global: "861599,051",
    tf: "0,27",
    tf_global: "6,858",
    tw: "0,36",
    tw_global: "9,144",
    zx: "1,74",
    zx_global: "28513,49136",
    zy: "0,54",
    zy_global: "8849,01456",
    width: "1,6",
    width_global: "40,64",
    thickness: "0,27",
    thickness_global: "6,858",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 687,
    name: "C3X5",
    type: "Channel",
    designation: "C3X5",
    shape: "C",
    country_code: "American",
    ax: "1,47",
    ax_global: "948,3852",
    b: 3,
    b_global: "76,2",
    bf: "1,5",
    bf_global: "38,1",
    ct: "0,44",
    ct_global: "11,176",
    ix: "0,04",
    ix_global: "16649,25702",
    iy: "0,24",
    iy_global: "99895,54214",
    iz: "1,85",
    iz_global: "770028,1374",
    tf: "0,27",
    tf_global: "6,858",
    tw: "0,26",
    tw_global: "6,604",
    zx: "1,52",
    zx_global: "24908,33728",
    zy: "0,46",
    zy_global: "7538,04944",
    width: "1,5",
    width_global: "38,1",
    thickness: "0,27",
    thickness_global: "6,858",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 688,
    name: "C3X4.1",
    type: "Channel",
    designation: "C3X4",
    shape: "C",
    country_code: "American",
    ax: "1,2",
    ax_global: "774,192",
    b: 3,
    b_global: "76,2",
    bf: "1,41",
    bf_global: "35,814",
    ct: "0,44",
    ct_global: "11,176",
    ix: "0,03",
    ix_global: "12486,94277",
    iy: "0,19",
    iy_global: "79083,97086",
    iz: "1,65",
    iz_global: "686781,8522",
    tf: "0,27",
    tf_global: "6,858",
    tw: "0,17",
    tw_global: "4,318",
    zx: "1,32",
    zx_global: "21630,92448",
    zy: "0,4",
    zy_global: "6554,8256",
    width: "1,41",
    width_global: "35,814",
    thickness: "0,27",
    thickness_global: "6,858",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 689,
    name: "C3X3.5",
    type: "Channel",
    designation: "C3X3",
    shape: "C",
    country_code: "American",
    ax: "1,09",
    ax_global: "703,2244",
    b: 3,
    b_global: "76,2",
    bf: "1,37",
    bf_global: "34,798",
    ct: "0,44",
    ct_global: "11,176",
    ix: "0,02",
    ix_global: "8324,628512",
    iy: "0,17",
    iy_global: "70759,34235",
    iz: "1,57",
    iz_global: "653483,3382",
    tf: "0,27",
    tf_global: "6,858",
    tw: "0,13",
    tw_global: "3,302",
    zx: "1,24",
    zx_global: "20319,95936",
    zy: "0,36",
    zy_global: "5899,34304",
    width: "1,37",
    width_global: "34,798",
    thickness: "0,27",
    thickness_global: "6,858",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 690,
    name: "MC6X12",
    type: "MC Channel",
    designation: "MC6X12",
    shape: "C",
    country_code: "American",
    ax: "3,53",
    ax_global: "2277,4148",
    bf: "2,497",
    bf_global: "63,4238",
    ct: "0,7",
    d: 6,
    d_global: "152,4",
    ix: "0,2",
    ix_global: "83246,28512",
    iy: "1,87",
    iy_global: "778352,7659",
    iz: "18,7",
    iz_global: "7783527,659",
    tf: "0,375",
    tf_global: "9,525",
    tw: "0,31",
    tw_global: "7,874",
    zx: "7,4",
    zx_global: "121264,2736",
    zy: "1,8",
    zy_global: "29496,7152",
  },
  {
    profile_section_id: 691,
    name: "MC6X15.1",
    type: "MC Channel",
    designation: "MC6X151",
    shape: "C",
    country_code: "American",
    ax: "4,44",
    ax_global: "2864,5104",
    bf: "2,941",
    bf_global: "74,7014",
    ct: "0,94",
    d: 6,
    d_global: "152,4",
    ix: "0,3",
    ix_global: "124869,4277",
    iy: "3,51",
    iy_global: "1460972,304",
    iz: 25,
    iz_global: "10405785,64",
    tf: "0,475",
    tf_global: "12,065",
    tw: "0,316",
    tw_global: "8,0264",
    zx: "9,7",
    zx_global: "158954,5208",
    zy: 3,
    zy_global: "49161,192",
  },
  {
    profile_section_id: 692,
    name: "MC6X15.3",
    type: "MC Channel",
    designation: "MC6X153",
    shape: "C",
    country_code: "American",
    ax: "4,5",
    ax_global: "2903,22",
    bf: "3,5",
    bf_global: "88,9",
    ct: "1,05",
    d: 6,
    d_global: "152,4",
    ix: "0,2",
    ix_global: "83246,28512",
    iy: "4,97",
    iy_global: "2068670,185",
    iz: "25,4",
    iz_global: "10572278,21",
    tf: "0,385",
    tf_global: "9,779",
    tw: "0,34",
    tw_global: "8,636",
    zx: "9,8",
    zx_global: "160593,2272",
    zy: "3,3",
    zy_global: "54077,3112",
  },
  {
    profile_section_id: 693,
    name: "MC6X16.3",
    type: "MC Channel",
    designation: "MC6X16",
    shape: "C",
    country_code: "American",
    ax: "4,79",
    ax_global: "3090,3164",
    bf: 3,
    bf_global: "76,2",
    ct: "0,93",
    d: 6,
    d_global: "152,4",
    ix: "0,3",
    ix_global: "124869,4277",
    iy: "3,82",
    iy_global: "1590004,046",
    iz: 26,
    iz_global: "10822017,07",
    tf: "0,475",
    tf_global: "12,065",
    tw: "0,375",
    tw_global: "9,525",
    zx: "10,2",
    zx_global: "167148,0528",
    zy: "3,2",
    zy_global: "52438,6048",
  },
  {
    profile_section_id: 694,
    name: "MC6X18",
    type: "MC Channel",
    designation: "MC6X18",
    shape: "C",
    country_code: "American",
    ax: "5,29",
    ax_global: "3412,8964",
    bf: "3,504",
    bf_global: "89,0016",
    ct: "1,12",
    d: 6,
    d_global: "152,4",
    ix: "0,4",
    ix_global: "166492,5702",
    iy: "5,93",
    iy_global: "2468252,354",
    iz: "29,7",
    iz_global: "12362073,34",
    tf: "0,475",
    tf_global: "12,065",
    tw: "0,379",
    tw_global: "9,6266",
    zx: "11,5",
    zx_global: "188451,236",
    zy: "4,1",
    zy_global: "67186,9624",
  },
  {
    profile_section_id: 695,
    name: "MC7X19.1",
    type: "MC Channel",
    designation: "MC7X19",
    shape: "C",
    country_code: "American",
    ax: "5,61",
    ax_global: "3619,3476",
    bf: "3,452",
    bf_global: "87,6808",
    ct: "1,08",
    d: 7,
    d_global: "177,8",
    ix: "0,4",
    ix_global: "166492,5702",
    iy: "6,11",
    iy_global: "2543174,01",
    iz: "43,2",
    iz_global: "17981197,59",
    tf: "0,5",
    tf_global: "12,7",
    tw: "0,352",
    tw_global: "8,9408",
    zx: "14,3",
    zx_global: "234335,0152",
    zy: "4,3",
    zy_global: "70464,3752",
  },
  {
    profile_section_id: 696,
    name: "MC7X17",
    type: "MC Channel",
    designation: "MC7X17",
    shape: "C",
    country_code: "American",
    ax: "5,17",
    ax_global: "3335,4772",
    bf: 3,
    bf_global: "76,2",
    ct: "0,87",
    d: 7,
    d_global: "177,8",
    ix: "0,3",
    ix_global: "124869,4277",
    iy: "4,01",
    iy_global: "1669088,017",
    iz: "37,6",
    iz_global: "15650301,6",
    tf: "0,475",
    tf_global: "12,065",
    tw: "0,375",
    tw_global: "9,525",
    zx: "12,7",
    zx_global: "208115,7128",
    zy: "3,3",
    zy_global: "54077,3112",
  },
  {
    profile_section_id: 697,
    name: "MC7X22.7",
    type: "MC Channel",
    designation: "MC7X22",
    shape: "C",
    country_code: "American",
    ax: "6,67",
    ax_global: "4303,2172",
    bf: "3,603",
    bf_global: "91,5162",
    ct: "1,04",
    d: 7,
    d_global: "177,8",
    ix: "0,6",
    ix_global: "249738,8554",
    iy: "7,29",
    iy_global: "3034327,093",
    iz: "47,5",
    iz_global: "19770992,72",
    tf: "0,5",
    tf_global: "12,7",
    tw: "0,503",
    tw_global: "12,7762",
    zx: "16,2",
    zx_global: "265470,4368",
    zy: "4,9",
    zy_global: "80296,6136",
  },
  {
    profile_section_id: 698,
    name: "MC8X8.5",
    type: "MC Channel",
    designation: "MC8X8",
    shape: "C",
    country_code: "American",
    ax: "2,5",
    ax_global: "1612,9",
    bf: "1,874",
    bf_global: "47,5996",
    ct: "0,43",
    d: 8,
    d_global: "203,2",
    ix: "0,1",
    ix_global: "41623,14256",
    iy: "0,63",
    iy_global: "262225,7981",
    iz: "23,3",
    iz_global: "9698192,216",
    tf: "0,311",
    tf_global: "7,8994",
    tw: "0,179",
    tw_global: "4,5466",
    zx: "6,9",
    zx_global: "113070,7416",
    zy: "0,9",
    zy_global: "14748,3576",
  },
  {
    profile_section_id: 699,
    name: "MC8X18.7",
    type: "MC Channel",
    designation: "MC8X18",
    shape: "C",
    country_code: "American",
    ax: "5,5",
    ax_global: "3548,38",
    bf: "2,978",
    bf_global: "75,6412",
    ct: "0,85",
    d: 8,
    d_global: "203,2",
    ix: "0,4",
    ix_global: "166492,5702",
    iy: "4,2",
    iy_global: "1748171,988",
    iz: "52,5",
    iz_global: "21852149,84",
    tf: "0,5",
    tf_global: "12,7",
    tw: "0,353",
    tw_global: "8,9662",
    zx: "15,4",
    zx_global: "252360,7856",
    zy: "3,4",
    zy_global: "55716,0176",
  },
  {
    profile_section_id: 700,
    name: "MC8X20",
    type: "MC Channel",
    designation: "MC8X20",
    shape: "C",
    country_code: "American",
    ax: "5,88",
    ax_global: "3793,5408",
    bf: "3,025",
    bf_global: "76,835",
    ct: "0,84",
    d: 8,
    d_global: "203,2",
    ix: "0,4",
    ix_global: "166492,5702",
    iy: "4,47",
    iy_global: "1860554,472",
    iz: "54,5",
    iz_global: "22684612,7",
    tf: "0,5",
    tf_global: "12,7",
    tw: "0,4",
    tw_global: "10,16",
    zx: "16,2",
    zx_global: "265470,4368",
    zy: "3,6",
    zy_global: "58993,4304",
  },
  {
    profile_section_id: 701,
    name: "MC8X21.4",
    type: "MC Channel",
    designation: "MC8X21",
    shape: "C",
    country_code: "American",
    ax: "6,28",
    ax_global: "4051,6048",
    bf: "3,45",
    bf_global: "87,63",
    ct: "1,02",
    d: 8,
    d_global: "203,2",
    ix: "0,5",
    ix_global: "208115,7128",
    iy: "6,64",
    iy_global: "2763776,666",
    iz: "61,6",
    iz_global: "25639855,82",
    tf: "0,525",
    tf_global: "13,335",
    tw: "0,375",
    tw_global: "9,525",
    zx: 18,
    zx_global: "294967,152",
    zy: "4,7",
    zy_global: "77019,2008",
  },
  {
    profile_section_id: 702,
    name: "MC8X22.8",
    type: "MC Channel",
    designation: "MC8X22",
    shape: "C",
    country_code: "American",
    ax: "6,7",
    ax_global: "4322,572",
    bf: "3,502",
    bf_global: "88,9508",
    ct: "1,01",
    d: 8,
    d_global: "203,2",
    ix: "0,6",
    ix_global: "249738,8554",
    iy: "7,07",
    iy_global: "2942756,179",
    iz: "63,8",
    iz_global: "26555564,95",
    tf: "0,525",
    tf_global: "13,335",
    tw: "0,427",
    tw_global: "10,8458",
    zx: "18,8",
    zx_global: "308076,8032",
    zy: "4,9",
    zy_global: "80296,6136",
  },
  {
    profile_section_id: 703,
    name: "MC9X23.9",
    type: "MC Channel",
    designation: "MC9X23",
    shape: "C",
    country_code: "American",
    ax: "7,02",
    ax_global: "4529,0232",
    bf: "3,45",
    bf_global: "87,63",
    ct: "0,98",
    d: 9,
    d_global: "228,6",
    ix: "0,6",
    ix_global: "249738,8554",
    iy: "7,22",
    iy_global: "3005190,893",
    iz: 85,
    iz_global: "35379671,18",
    tf: "0,55",
    tf_global: "13,97",
    tw: "0,4",
    tw_global: "10,16",
    zx: "22,2",
    zx_global: "363792,8208",
    zy: "5,1",
    zy_global: "83574,0264",
  },
  {
    profile_section_id: 704,
    name: "MC9X25.5",
    type: "MC Channel",
    designation: "MC9X25",
    shape: "C",
    country_code: "American",
    ax: "7,47",
    ax_global: "4819,3452",
    bf: "3,5",
    bf_global: "88,9",
    ct: "0,97",
    d: 9,
    d_global: "228,6",
    ix: "0,7",
    ix_global: "291361,9979",
    iy: "7,65",
    iy_global: "3184170,406",
    iz: 88,
    iz_global: "36628365,45",
    tf: "0,55",
    tf_global: "13,97",
    tw: "0,45",
    tw_global: "11,43",
    zx: "23,2",
    zx_global: "380179,8848",
    zy: "5,2",
    zy_global: "85212,7328",
  },
  {
    profile_section_id: 705,
    name: "MC10X6",
    type: "MC Channel",
    designation: "MC10X6",
    shape: "C",
    country_code: "American",
    ax: "1,91",
    ax_global: "1232,2556",
    bf: "1,127",
    bf_global: "28,6258",
    ct: "0,18",
    d: 10,
    d_global: 254,
    ix: 0,
    ix_global: 0,
    iy: "0,11",
    iy_global: "45785,45682",
    iz: "22,1",
    iz_global: "9198714,506",
    tf: "0,202",
    tf_global: "5,1308",
    tw: "0,152",
    tw_global: "3,8608",
    zx: "5,7",
    zx_global: "93406,2648",
    zy: "0,3",
    zy_global: "4916,1192",
  },
  {
    profile_section_id: 706,
    name: "MC10X8.4",
    type: "MC Channel",
    designation: "MC10X8",
    shape: "C",
    country_code: "American",
    ax: "2,46",
    ax_global: "1587,0936",
    bf: "1,5",
    bf_global: "38,1",
    ct: "0,28",
    d: 10,
    d_global: 254,
    ix: 0,
    ix_global: 0,
    iy: "0,33",
    iy_global: "137356,3704",
    iz: 32,
    iz_global: "13319405,62",
    tf: "0,28",
    tf_global: "7,112",
    tw: "0,17",
    tw_global: "4,318",
    zx: "7,9",
    zx_global: "129457,8056",
    zy: "0,6",
    zy_global: "9832,2384",
  },
  {
    profile_section_id: 707,
    name: "MC10X22",
    type: "MC Channel",
    designation: "MC10X22",
    shape: "C",
    country_code: "American",
    ax: "6,45",
    ax_global: "4161,282",
    bf: "3,315",
    bf_global: "84,201",
    ct: "0,99",
    d: 10,
    d_global: 254,
    ix: "0,5",
    ix_global: "208115,7128",
    iy: "6,5",
    iy_global: "2705504,266",
    iz: 103,
    iz_global: "42871836,84",
    tf: "0,575",
    tf_global: "14,605",
    tw: "0,29",
    tw_global: "7,366",
    zx: "23,6",
    zx_global: "386734,7104",
    zy: "4,9",
    zy_global: "80296,6136",
  },
  {
    profile_section_id: 708,
    name: "MC10X25",
    type: "MC Channel",
    designation: "MC10X25",
    shape: "C",
    country_code: "American",
    ax: "7,35",
    ax_global: "4741,926",
    bf: "3,405",
    bf_global: "86,487",
    ct: "0,95",
    d: 10,
    d_global: 254,
    ix: "0,6",
    ix_global: "249738,8554",
    iy: "7,35",
    iy_global: "3059300,978",
    iz: 110,
    iz_global: "45785456,82",
    tf: "0,575",
    tf_global: "14,605",
    tw: "0,38",
    tw_global: "9,652",
    zx: "25,8",
    zx_global: "422786,2512",
    zy: "5,2",
    zy_global: "85212,7328",
  },
  {
    profile_section_id: 709,
    name: "MC10X28.5",
    type: "MC Channel",
    designation: "MC10X28",
    shape: "C",
    country_code: "American",
    ax: "8,37",
    ax_global: "5399,9892",
    bf: "3,95",
    bf_global: "100,33",
    ct: "1,12",
    d: 10,
    d_global: 254,
    ix: "0,8",
    ix_global: "332985,1405",
    iy: "11,4",
    iy_global: "4745038,252",
    iz: 127,
    iz_global: "52861391,05",
    tf: "0,575",
    tf_global: "14,605",
    tw: "0,425",
    tw_global: "10,795",
    zx: "29,6",
    zx_global: "485057,0944",
    zy: "6,8",
    zy_global: "111432,0352",
  },
  {
    profile_section_id: 710,
    name: "MC10X33.6",
    type: "MC Channel",
    designation: "MC10X33",
    shape: "C",
    country_code: "American",
    ax: "9,87",
    ax_global: "6367,7292",
    bf: "4,1",
    bf_global: "104,14",
    ct: "1,08",
    d: 10,
    d_global: 254,
    ix: "1,2",
    ix_global: "499477,7107",
    iy: "13,2",
    iy_global: "5494254,818",
    iz: 139,
    iz_global: "57856168,16",
    tf: "0,575",
    tf_global: "14,605",
    tw: "0,575",
    tw_global: "14,605",
    zx: "33,4",
    zx_global: "547327,9376",
    zy: "7,5",
    zy_global: "122902,98",
  },
  {
    profile_section_id: 711,
    name: "MC10X41.1",
    type: "MC Channel",
    designation: "MC10X41",
    shape: "C",
    country_code: "American",
    ax: "12,1",
    ax_global: "7806,436",
    bf: "4,321",
    bf_global: "109,7534",
    ct: "1,09",
    d: 10,
    d_global: 254,
    ix: "2,3",
    ix_global: "957332,2789",
    iy: "15,8",
    iy_global: "6576456,524",
    iz: 158,
    iz_global: "65764565,24",
    tf: "0,575",
    tf_global: "14,605",
    tw: "0,796",
    tw_global: "20,2184",
    zx: "38,9",
    zx_global: "637456,7896",
    zy: "8,7",
    zy_global: "142567,4568",
  },
  {
    profile_section_id: 712,
    name: "MC12X10.6",
    type: "MC Channel",
    designation: "MC12X10",
    shape: "C",
    country_code: "American",
    ax: "3,1",
    ax_global: "1999,996",
    bf: "1,5",
    bf_global: "38,1",
    ct: "0,27",
    d: 12,
    d_global: "304,8",
    ix: "0,1",
    ix_global: "41623,14256",
    iy: "0,38",
    iy_global: "158167,9417",
    iz: "55,4",
    iz_global: "23059220,98",
    tf: "0,309",
    tf_global: "7,8486",
    tw: "0,19",
    tw_global: "4,826",
    zx: "11,6",
    zx_global: "190089,9424",
    zy: "0,6",
    zy_global: "9832,2384",
  },
  {
    profile_section_id: 713,
    name: "MC12X31.8",
    type: "MC Channel",
    designation: "MC12X31",
    shape: "C",
    country_code: "American",
    ax: "9,12",
    ax_global: "5883,8592",
    bf: "3,67",
    bf_global: "93,218",
    ct: "1,08",
    d: 12,
    d_global: "304,8",
    ix: 1,
    ix_global: "416231,4256",
    iy: "11,3",
    iy_global: "4703415,109",
    iz: 203,
    iz_global: "84494979,4",
    tf: "0,7",
    tf_global: "17,78",
    tw: "0,37",
    tw_global: "9,398",
    zx: "39,3",
    zx_global: "644011,6152",
    zy: "7,4",
    zy_global: "121264,2736",
  },
  {
    profile_section_id: 714,
    name: "MC12X35",
    type: "MC Channel",
    designation: "MC12X35",
    shape: "C",
    country_code: "American",
    ax: "10,3",
    ax_global: "6645,148",
    bf: "3,767",
    bf_global: "95,6818",
    ct: "1,05",
    d: 12,
    d_global: "304,8",
    ix: "1,3",
    ix_global: "541100,8533",
    iy: "12,7",
    iy_global: "5286139,105",
    iz: 216,
    iz_global: "89905987,93",
    tf: "0,7",
    tf_global: "17,78",
    tw: "0,467",
    tw_global: "11,8618",
    zx: "42,8",
    zx_global: "701366,3392",
    zy: "7,9",
    zy_global: "129457,8056",
  },
  {
    profile_section_id: 715,
    name: "MC12X40",
    type: "MC Channel",
    designation: "MC12X40",
    shape: "C",
    country_code: "American",
    ax: "11,8",
    ax_global: "7612,888",
    bf: "3,89",
    bf_global: "98,806",
    ct: "1,04",
    d: 12,
    d_global: "304,8",
    ix: "1,7",
    ix_global: "707593,4235",
    iy: "14,3",
    iy_global: "5952109,386",
    iz: 234,
    iz_global: "97398153,59",
    tf: "0,7",
    tf_global: "17,78",
    tw: "0,59",
    tw_global: "14,986",
    zx: "47,3",
    zx_global: "775108,1272",
    zy: "8,6",
    zy_global: "140928,7504",
  },
  {
    profile_section_id: 716,
    name: "MC12X45",
    type: "MC Channel",
    designation: "MC12X45",
    shape: "C",
    country_code: "American",
    ax: "13,2",
    ax_global: "8516,112",
    bf: "4,012",
    bf_global: "101,9048",
    ct: "1,04",
    d: 12,
    d_global: "304,8",
    ix: "2,3",
    ix_global: "957332,2789",
    iy: "15,8",
    iy_global: "6576456,524",
    iz: 252,
    iz_global: "104890319,3",
    tf: "0,7",
    tf_global: "17,78",
    tw: "0,712",
    tw_global: "18,0848",
    zx: "51,7",
    zx_global: "847211,2088",
    zy: "9,4",
    zy_global: "154038,4016",
  },
  {
    profile_section_id: 717,
    name: "MC12X50",
    type: "MC Channel",
    designation: "MC12X50",
    shape: "C",
    country_code: "American",
    ax: "14,7",
    ax_global: "9483,852",
    bf: "4,135",
    bf_global: "105,029",
    ct: "1,05",
    d: 12,
    d_global: "304,8",
    ix: "3,2",
    ix_global: "1331940,562",
    iy: "17,4",
    iy_global: "7242426,805",
    iz: 269,
    iz_global: "111966253,5",
    tf: "0,7",
    tf_global: "17,78",
    tw: "0,835",
    tw_global: "21,209",
    zx: "56,1",
    zx_global: "919314,2904",
    zy: "10,2",
    zy_global: "167148,0528",
  },
  {
    profile_section_id: 718,
    name: "MC13X31.8",
    type: "MC Channel",
    designation: "MC13X31",
    shape: "C",
    country_code: "American",
    ax: "9,35",
    ax_global: "6032,246",
    bf: 4,
    bf_global: "101,6",
    ct: 1,
    d: 13,
    d_global: "330,2",
    ix: "0,9",
    ix_global: "374608,283",
    iy: "11,4",
    iy_global: "4745038,252",
    iz: 239,
    iz_global: "99479310,72",
    tf: "0,61",
    tf_global: "15,494",
    tw: "0,375",
    tw_global: "9,525",
    zx: "43,1",
    zx_global: "706282,4584",
    zy: "7,6",
    zy_global: "124541,6864",
  },
  {
    profile_section_id: 719,
    name: "MC13X35",
    type: "MC Channel",
    designation: "MC13X35",
    shape: "C",
    country_code: "American",
    ax: "10,3",
    ax_global: "6645,148",
    bf: "4,072",
    bf_global: "103,4288",
    ct: "0,98",
    d: 13,
    d_global: "330,2",
    ix: "1,1",
    ix_global: "457854,5682",
    iy: "12,3",
    iy_global: "5119646,535",
    iz: 252,
    iz_global: "104890319,3",
    tf: "0,61",
    tf_global: "15,494",
    tw: "0,447",
    tw_global: "11,3538",
    zx: "46,2",
    zx_global: "757082,3568",
    zy: 8,
    zy_global: "131096,512",
  },
  {
    profile_section_id: 720,
    name: "MC13X40",
    type: "MC Channel",
    designation: "MC13X40",
    shape: "C",
    country_code: "American",
    ax: "11,8",
    ax_global: "7612,888",
    bf: "4,185",
    bf_global: "106,299",
    ct: "0,96",
    d: 13,
    d_global: "330,2",
    ix: "1,6",
    ix_global: "665970,281",
    iy: "13,7",
    iy_global: "5702370,531",
    iz: 273,
    iz_global: "113631179,2",
    tf: "0,61",
    tf_global: "15,494",
    tw: "0,56",
    tw_global: "14,224",
    zx: "50,9",
    zx_global: "834101,5576",
    zy: "8,6",
    zy_global: "140928,7504",
  },
  {
    profile_section_id: 721,
    name: "MC13X50",
    type: "MC Channel",
    designation: "MC13X50",
    shape: "C",
    country_code: "American",
    ax: "14,7",
    ax_global: "9483,852",
    bf: "4,412",
    bf_global: "112,0648",
    ct: "0,97",
    d: 13,
    d_global: "330,2",
    ix: 3,
    ix_global: "1248694,277",
    iy: "16,5",
    iy_global: "6867818,522",
    iz: 314,
    iz_global: "130696667,6",
    tf: "0,61",
    tf_global: "15,494",
    tw: "0,787",
    tw_global: "19,9898",
    zx: "60,5",
    zx_global: "991417,372",
    zy: "10,1",
    zy_global: "165509,3464",
  },
  {
    profile_section_id: 722,
    name: "MC18X42.7",
    type: "MC Channel",
    designation: "MC18X42",
    shape: "C",
    country_code: "American",
    ax: "12,6",
    ax_global: "8129,016",
    bf: "3,95",
    bf_global: "100,33",
    ct: "0,88",
    d: 18,
    d_global: "457,2",
    ix: "1,2",
    ix_global: "499477,7107",
    iy: "14,4",
    iy_global: "5993732,529",
    iz: 554,
    iz_global: "230592209,8",
    tf: "0,625",
    tf_global: "15,875",
    tw: "0,45",
    tw_global: "11,43",
    zx: "74,4",
    zx_global: "1219197,562",
    zy: "8,1",
    zy_global: "132735,2184",
  },
  {
    profile_section_id: 723,
    name: "MC18X45.8",
    type: "MC Channel",
    designation: "MC18X45",
    shape: "C",
    country_code: "American",
    ax: "13,5",
    ax_global: "8709,66",
    bf: 4,
    bf_global: "101,6",
    ct: "0,87",
    d: 18,
    d_global: "457,2",
    ix: "1,5",
    ix_global: "624347,1384",
    iy: "15,1",
    iy_global: "6285094,527",
    iz: 578,
    iz_global: 240581764,
    tf: "0,625",
    tf_global: "15,875",
    tw: "0,5",
    tw_global: "12,7",
    zx: "78,4",
    zx_global: "1284745,818",
    zy: "8,4",
    zy_global: "137651,3376",
  },
  {
    profile_section_id: 724,
    name: "MC18X51.9",
    type: "MC Channel",
    designation: "MC18X51",
    shape: "C",
    country_code: "American",
    ax: "15,3",
    ax_global: "9870,948",
    bf: "4,1",
    bf_global: "104,14",
    ct: "0,86",
    d: 18,
    d_global: "457,2",
    ix: 2,
    ix_global: "832462,8512",
    iy: "16,4",
    iy_global: "6826195,38",
    iz: 627,
    iz_global: "260977103,9",
    tf: "0,625",
    tf_global: "15,875",
    tw: "0,6",
    tw_global: "15,24",
    zx: "86,5",
    zx_global: "1417481,036",
    zy: "9,1",
    zy_global: "149122,2824",
  },
  {
    profile_section_id: 725,
    name: "MC18X58",
    type: "MC Channel",
    designation: "MC18X58",
    shape: "C",
    country_code: "American",
    ax: "17,1",
    ax_global: "11032,236",
    bf: "4,2",
    bf_global: "106,68",
    ct: "0,86",
    d: 18,
    d_global: "457,2",
    ix: "2,8",
    ix_global: "1165447,992",
    iy: "17,8",
    iy_global: "7408919,376",
    iz: 676,
    iz_global: "281372443,7",
    tf: "0,625",
    tf_global: "15,875",
    tw: "0,7",
    tw_global: "17,78",
    zx: "94,6",
    zx_global: "1550216,254",
    zy: "9,9",
    zy_global: "162231,9336",
  },
  {
    profile_section_id: 726,
    name: "L2X2X1/8",
    type: "Angle",
    designation: "L20202",
    shape: "L",
    country_code: "American",
    ax: "0,49",
    ax_global: "316,1284",
    b: 2,
    b_global: "50,8",
    d: 2,
    d_global: "50,8",
    rz: "0,39",
    rz_global: "9,906",
    t: "0,13",
    t_global: "3,302",
    width: 2,
    width_global: "50,8",
    thickness: "0,13",
    thickness_global: "3,302",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 727,
    name: "L2X2X3/16",
    type: "Angle",
    designation: "L20203",
    shape: "L",
    country_code: "American",
    ax: "0,72",
    ax_global: "464,5152",
    b: 2,
    b_global: "50,8",
    d: 2,
    d_global: "50,8",
    rz: "0,39",
    rz_global: "9,906",
    t: "0,19",
    t_global: "4,826",
    width: 2,
    width_global: "50,8",
    thickness: "0,19",
    thickness_global: "4,826",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 728,
    name: "L2X2X1/4",
    type: "Angle",
    designation: "L20204",
    shape: "L",
    country_code: "American",
    ax: "0,94",
    ax_global: "606,4504",
    b: 2,
    b_global: "50,8",
    d: 2,
    d_global: "50,8",
    rz: "0,39",
    rz_global: "9,906",
    t: "0,25",
    t_global: "6,35",
    width: 2,
    width_global: "50,8",
    thickness: "0,25",
    thickness_global: "6,35",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 729,
    name: "L2X2X5/16",
    type: "Angle",
    designation: "L20205",
    shape: "L",
    country_code: "American",
    ax: "1,16",
    ax_global: "748,3856",
    b: 2,
    b_global: "50,8",
    d: 2,
    d_global: "50,8",
    rz: "0,39",
    rz_global: "9,906",
    t: "0,31",
    t_global: "7,874",
    width: 2,
    width_global: "50,8",
    thickness: "0,31",
    thickness_global: "7,874",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 730,
    name: "L2X2X3/8",
    type: "Angle",
    designation: "L20206",
    shape: "L",
    country_code: "American",
    ax: "1,37",
    ax_global: "883,8692",
    b: 2,
    b_global: "50,8",
    d: 2,
    d_global: "50,8",
    rz: "0,39",
    rz_global: "9,906",
    t: "0,38",
    t_global: "9,652",
    width: 2,
    width_global: "50,8",
    thickness: "0,38",
    thickness_global: "9,652",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 731,
    name: "L2-1/2X1-1/2X3/16",
    type: "Angle",
    designation: "L25153",
    shape: "L",
    country_code: "American",
    ax: "0,72",
    ax_global: "464,5152",
    b: "1,5",
    b_global: "38,1",
    d: "2,5",
    d_global: "63,5",
    rz: "0,32",
    rz_global: "8,128",
    t: "0,19",
    t_global: "4,826",
    width: "2,5",
    width_global: "63,5",
    thickness: "0,19",
    thickness_global: "4,826",
    height: "1,5",
    height_global: "38,1",
  },
  {
    profile_section_id: 732,
    name: "L2-1/2X2X3/16",
    type: "Angle",
    designation: "L25203",
    shape: "L",
    country_code: "American",
    ax: "0,82",
    ax_global: "529,0312",
    b: 2,
    b_global: "50,8",
    d: "2,5",
    d_global: "63,5",
    rz: "0,43",
    rz_global: "10,922",
    t: "0,19",
    t_global: "4,826",
    width: "2,5",
    width_global: "63,5",
    thickness: "0,19",
    thickness_global: "4,826",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 733,
    name: "L2-1/2X2-1/2X3/16",
    type: "Angle",
    designation: "L25253",
    shape: "L",
    country_code: "American",
    ax: "0,9",
    ax_global: "580,644",
    b: "2,5",
    b_global: "63,5",
    d: "2,5",
    d_global: "63,5",
    rz: "0,48",
    rz_global: "12,192",
    t: "0,19",
    t_global: "4,826",
    width: "2,5",
    width_global: "63,5",
    thickness: "0,19",
    thickness_global: "4,826",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 734,
    name: "L2-1/2X1-1/2X1/4",
    type: "Angle",
    designation: "L25154",
    shape: "L",
    country_code: "American",
    ax: "0,95",
    ax_global: "612,902",
    b: "1,5",
    b_global: "38,1",
    d: "2,5",
    d_global: "63,5",
    rz: "0,32",
    rz_global: "8,128",
    t: "0,25",
    t_global: "6,35",
    width: "2,5",
    width_global: "63,5",
    thickness: "0,25",
    thickness_global: "6,35",
    height: "1,5",
    height_global: "38,1",
  },
  {
    profile_section_id: 735,
    name: "L2-1/2X2X1/4",
    type: "Angle",
    designation: "L25204",
    shape: "L",
    country_code: "American",
    ax: "1,07",
    ax_global: "690,3212",
    b: 2,
    b_global: "50,8",
    d: "2,5",
    d_global: "63,5",
    rz: "0,42",
    rz_global: "10,668",
    t: "0,25",
    t_global: "6,35",
    width: "2,5",
    width_global: "63,5",
    thickness: "0,25",
    thickness_global: "6,35",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 736,
    name: "L2-1/2X2-1/2X1/4",
    type: "Angle",
    designation: "L25254",
    shape: "L",
    country_code: "American",
    ax: "1,19",
    ax_global: "767,7404",
    b: "2,5",
    b_global: "63,5",
    d: "2,5",
    d_global: "63,5",
    rz: "0,48",
    rz_global: "12,192",
    t: "0,25",
    t_global: "6,35",
    width: "2,5",
    width_global: "63,5",
    thickness: "0,25",
    thickness_global: "6,35",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 737,
    name: "L2-1/2X2X5/16",
    type: "Angle",
    designation: "L25205",
    shape: "L",
    country_code: "American",
    ax: "1,32",
    ax_global: "851,6112",
    b: 2,
    b_global: "50,8",
    d: "2,5",
    d_global: "63,5",
    rz: "0,42",
    rz_global: "10,668",
    t: "0,31",
    t_global: "7,874",
    width: "2,5",
    width_global: "63,5",
    thickness: "0,31",
    thickness_global: "7,874",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 738,
    name: "L2-1/2X2-1/2X5/16",
    type: "Angle",
    designation: "L25255",
    shape: "L",
    country_code: "American",
    ax: "1,46",
    ax_global: "941,9336",
    b: "2,5",
    b_global: "63,5",
    d: "2,5",
    d_global: "63,5",
    rz: "0,48",
    rz_global: "12,192",
    t: "0,31",
    t_global: "7,874",
    width: "2,5",
    width_global: "63,5",
    thickness: "0,31",
    thickness_global: "7,874",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 739,
    name: "L2-1/2X2X3/8",
    type: "Angle",
    designation: "L25206",
    shape: "L",
    country_code: "American",
    ax: "1,55",
    ax_global: "999,998",
    b: 2,
    b_global: "50,8",
    d: "2,5",
    d_global: "63,5",
    rz: "0,42",
    rz_global: "10,668",
    t: "0,38",
    t_global: "9,652",
    width: "2,5",
    width_global: "63,5",
    thickness: "0,38",
    thickness_global: "9,652",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 740,
    name: "L2-1/2X2-1/2X3/8",
    type: "Angle",
    designation: "L25256",
    shape: "L",
    country_code: "American",
    ax: "1,73",
    ax_global: "1116,1268",
    b: "2,5",
    b_global: "63,5",
    d: "2,5",
    d_global: "63,5",
    rz: "0,48",
    rz_global: "12,192",
    t: "0,38",
    t_global: "9,652",
    width: "2,5",
    width_global: "63,5",
    thickness: "0,38",
    thickness_global: "9,652",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 741,
    name: "L2-1/2X2-1/2X1/2",
    type: "Angle",
    designation: "L25258",
    shape: "L",
    country_code: "American",
    ax: "2,26",
    ax_global: "1458,0616",
    b: "2,5",
    b_global: "63,5",
    d: "2,5",
    d_global: "63,5",
    rz: "0,48",
    rz_global: "12,192",
    t: "0,5",
    t_global: "12,7",
    width: "2,5",
    width_global: "63,5",
    thickness: "0,5",
    thickness_global: "12,7",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 742,
    name: "L3X2X3/16",
    type: "Angle",
    designation: "L30203",
    shape: "L",
    country_code: "American",
    ax: "0,92",
    ax_global: "593,5472",
    b: 2,
    b_global: "50,8",
    d: 3,
    d_global: "76,2",
    rz: "0,44",
    rz_global: "11,176",
    t: "0,19",
    t_global: "4,826",
    width: 3,
    width_global: "76,2",
    thickness: "0,19",
    thickness_global: "4,826",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 743,
    name: "L3X2-1/2X3/16",
    type: "Angle",
    designation: "L30253",
    shape: "L",
    country_code: "American",
    ax: 1,
    ax_global: "645,16",
    b: "2,5",
    b_global: "63,5",
    d: 3,
    d_global: "76,2",
    rz: "0,52",
    rz_global: "13,208",
    t: "0,19",
    t_global: "4,826",
    width: 3,
    width_global: "76,2",
    thickness: "0,19",
    thickness_global: "4,826",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 744,
    name: "L3X3X3/16",
    type: "Angle",
    designation: "L30303",
    shape: "L",
    country_code: "American",
    ax: "1,09",
    ax_global: "703,2244",
    b: 3,
    b_global: "76,2",
    d: 3,
    d_global: "76,2",
    rz: "0,59",
    rz_global: "14,986",
    t: "0,19",
    t_global: "4,826",
    width: 3,
    width_global: "76,2",
    thickness: "0,19",
    thickness_global: "4,826",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 745,
    name: "L3X2X1/4",
    type: "Angle",
    designation: "L30204",
    shape: "L",
    country_code: "American",
    ax: "1,2",
    ax_global: "774,192",
    b: 2,
    b_global: "50,8",
    d: 3,
    d_global: "76,2",
    rz: "0,43",
    rz_global: "10,922",
    t: "0,25",
    t_global: "6,35",
    width: 3,
    width_global: "76,2",
    thickness: "0,25",
    thickness_global: "6,35",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 746,
    name: "L3X2-1/2X1/4",
    type: "Angle",
    designation: "L30254",
    shape: "L",
    country_code: "American",
    ax: "1,32",
    ax_global: "851,6112",
    b: "2,5",
    b_global: "63,5",
    d: 3,
    d_global: "76,2",
    rz: "0,52",
    rz_global: "13,208",
    t: "0,25",
    t_global: "6,35",
    width: 3,
    width_global: "76,2",
    thickness: "0,25",
    thickness_global: "6,35",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 747,
    name: "L3X3X1/4",
    type: "Angle",
    designation: "L30304",
    shape: "L",
    country_code: "American",
    ax: "1,44",
    ax_global: "929,0304",
    b: 3,
    b_global: "76,2",
    d: 3,
    d_global: "76,2",
    rz: "0,58",
    rz_global: "14,732",
    t: "0,25",
    t_global: "6,35",
    width: 3,
    width_global: "76,2",
    thickness: "0,25",
    thickness_global: "6,35",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 748,
    name: "L3X2X5/16",
    type: "Angle",
    designation: "L30205",
    shape: "L",
    country_code: "American",
    ax: "1,48",
    ax_global: "954,8368",
    b: 2,
    b_global: "50,8",
    d: 3,
    d_global: "76,2",
    rz: "0,43",
    rz_global: "10,922",
    t: "0,31",
    t_global: "7,874",
    width: 3,
    width_global: "76,2",
    thickness: "0,31",
    thickness_global: "7,874",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 749,
    name: "L3X2-1/2X5/16",
    type: "Angle",
    designation: "L30255",
    shape: "L",
    country_code: "American",
    ax: "1,63",
    ax_global: "1051,6108",
    b: "2,5",
    b_global: "63,5",
    d: 3,
    d_global: "76,2",
    rz: "0,52",
    rz_global: "13,208",
    t: "0,31",
    t_global: "7,874",
    width: 3,
    width_global: "76,2",
    thickness: "0,31",
    thickness_global: "7,874",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 750,
    name: "L3X2X3/8",
    type: "Angle",
    designation: "L30206",
    shape: "L",
    country_code: "American",
    ax: "1,75",
    ax_global: "1129,03",
    b: 2,
    b_global: "50,8",
    d: 3,
    d_global: "76,2",
    rz: "0,43",
    rz_global: "10,922",
    t: "0,38",
    t_global: "9,652",
    width: 3,
    width_global: "76,2",
    thickness: "0,38",
    thickness_global: "9,652",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 751,
    name: "L3x2x7/16",
    type: "Angle",
    designation: "L30207",
    shape: "L",
    country_code: "American",
    ax: "1,998",
    ax_global: "1289,02968",
    b: 2,
    b_global: "50,8",
    d: 3,
    d_global: "76,2",
    r1: "0,429",
    r1_global: "10,8966",
    rz: "0,429",
    rz_global: "10,8966",
    t: "0,438",
    t_global: "11,1252",
    width: 2,
    width_global: "50,8",
    thickness: "0,438",
    thickness_global: "11,1252",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 752,
    name: "L3X3X5/16",
    type: "Angle",
    designation: "L30305",
    shape: "L",
    country_code: "American",
    ax: "1,78",
    ax_global: "1148,3848",
    b: 3,
    b_global: "76,2",
    d: 3,
    d_global: "76,2",
    rz: "0,58",
    rz_global: "14,732",
    t: "0,31",
    t_global: "7,874",
    width: 3,
    width_global: "76,2",
    thickness: "0,31",
    thickness_global: "7,874",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 753,
    name: "L3X2-1/2X3/8",
    type: "Angle",
    designation: "L30256",
    shape: "L",
    country_code: "American",
    ax: "1,93",
    ax_global: "1245,1588",
    b: "2,5",
    b_global: "63,5",
    d: 3,
    d_global: "76,2",
    rz: "0,52",
    rz_global: "13,208",
    t: "0,38",
    t_global: "9,652",
    width: 3,
    width_global: "76,2",
    thickness: "0,38",
    thickness_global: "9,652",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 754,
    name: "L3X3X3/8",
    type: "Angle",
    designation: "L30306",
    shape: "L",
    country_code: "American",
    ax: "2,11",
    ax_global: "1361,2876",
    b: 3,
    b_global: "76,2",
    d: 3,
    d_global: "76,2",
    rz: "0,58",
    rz_global: "14,732",
    t: "0,38",
    t_global: "9,652",
    width: 3,
    width_global: "76,2",
    thickness: "0,38",
    thickness_global: "9,652",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 755,
    name: "L3X2-1/2X7/16",
    type: "Angle",
    designation: "L30257",
    shape: "L",
    country_code: "American",
    ax: "2,22",
    ax_global: "1432,2552",
    b: "2,5",
    b_global: "63,5",
    d: 3,
    d_global: "76,2",
    rz: "0,52",
    rz_global: "13,208",
    t: "0,44",
    t_global: "11,176",
    width: 3,
    width_global: "76,2",
    thickness: "0,44",
    thickness_global: "11,176",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 756,
    name: "L3X2X1/2",
    type: "Angle",
    designation: "L30208",
    shape: "L",
    country_code: "American",
    ax: "2,26",
    ax_global: "1458,0616",
    b: 2,
    b_global: "50,8",
    d: 3,
    d_global: "76,2",
    rz: "0,42",
    rz_global: "10,668",
    t: "0,5",
    t_global: "12,7",
    width: 3,
    width_global: "76,2",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 2,
    height_global: "50,8",
  },
  {
    profile_section_id: 757,
    name: "L3X3X7/16",
    type: "Angle",
    designation: "L30307",
    shape: "L",
    country_code: "American",
    ax: "2,43",
    ax_global: "1567,7388",
    b: 3,
    b_global: "76,2",
    d: 3,
    d_global: "76,2",
    rz: "0,58",
    rz_global: "14,732",
    t: "0,44",
    t_global: "11,176",
    width: 3,
    width_global: "76,2",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 758,
    name: "L3X2-1/2X1/2",
    type: "Angle",
    designation: "L30258",
    shape: "L",
    country_code: "American",
    ax: "2,5",
    ax_global: "1612,9",
    b: "2,5",
    b_global: "63,5",
    d: 3,
    d_global: "76,2",
    rz: "0,52",
    rz_global: "13,208",
    t: "0,5",
    t_global: "12,7",
    width: 3,
    width_global: "76,2",
    thickness: "0,5",
    thickness_global: "12,7",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 759,
    name: "L3X3X1/2",
    type: "Angle",
    designation: "L30308",
    shape: "L",
    country_code: "American",
    ax: "2,76",
    ax_global: "1780,6416",
    b: 3,
    b_global: "76,2",
    d: 3,
    d_global: "76,2",
    rz: "0,58",
    rz_global: "14,732",
    t: "0,5",
    t_global: "12,7",
    width: 3,
    width_global: "76,2",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 760,
    name: "L3-1/2X2-1/2X1/4",
    type: "Angle",
    designation: "L35254",
    shape: "L",
    country_code: "American",
    ax: "1,45",
    ax_global: "935,482",
    b: "2,5",
    b_global: "63,5",
    d: "3,5",
    d_global: "88,9",
    rz: "0,54",
    rz_global: "13,716",
    t: "0,25",
    t_global: "6,35",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,25",
    thickness_global: "6,35",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 761,
    name: "L3-1/2x31/4",
    type: "Angle",
    designation: "L35304",
    shape: "L",
    country_code: "American",
    ax: "1,563",
    ax_global: "1008,38508",
    b: 3,
    b_global: "76,2",
    d: "3,5",
    d_global: "88,9",
    r1: "0,631",
    r1_global: "16,0274",
    rz: "0,631",
    rz_global: "16,0274",
    t: "0,25",
    t_global: "6,35",
    width: 3,
    width_global: "76,2",
    thickness: "0,25",
    thickness_global: "6,35",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 762,
    name: "L3-1/2X3-1/2X1/4",
    type: "Angle",
    designation: "L35354",
    shape: "L",
    country_code: "American",
    ax: "1,7",
    ax_global: "1096,772",
    b: "3,5",
    b_global: "88,9",
    d: "3,5",
    d_global: "88,9",
    rz: "0,69",
    rz_global: "17,526",
    t: "0,25",
    t_global: "6,35",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,25",
    thickness_global: "6,35",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 763,
    name: "L3-1/2X2-1/2X5/16",
    type: "Angle",
    designation: "L35255",
    shape: "L",
    country_code: "American",
    ax: "1,79",
    ax_global: "1154,8364",
    b: "2,5",
    b_global: "63,5",
    d: "3,5",
    d_global: "88,9",
    rz: "0,54",
    rz_global: "13,716",
    t: "0,31",
    t_global: "7,874",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,31",
    thickness_global: "7,874",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 764,
    name: "L3-1/2X3X5/16",
    type: "Angle",
    designation: "L35305",
    shape: "L",
    country_code: "American",
    ax: "1,95",
    ax_global: "1258,062",
    b: 3,
    b_global: "76,2",
    d: "3,5",
    d_global: "88,9",
    rz: "0,62",
    rz_global: "15,748",
    t: "0,31",
    t_global: "7,874",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,31",
    thickness_global: "7,874",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 765,
    name: "L3-1/2X3-1/2X5/16",
    type: "Angle",
    designation: "L35355",
    shape: "L",
    country_code: "American",
    ax: "2,1",
    ax_global: "1354,836",
    b: "3,5",
    b_global: "88,9",
    d: "3,5",
    d_global: "88,9",
    rz: "0,69",
    rz_global: "17,526",
    t: "0,31",
    t_global: "7,874",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,31",
    thickness_global: "7,874",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 766,
    name: "L3-1/2X2-1/2X3/8",
    type: "Angle",
    designation: "L35256",
    shape: "L",
    country_code: "American",
    ax: "2,12",
    ax_global: "1367,7392",
    b: "2,5",
    b_global: "63,5",
    d: "3,5",
    d_global: "88,9",
    rz: "0,54",
    rz_global: "13,716",
    t: "0,38",
    t_global: "9,652",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,38",
    thickness_global: "9,652",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 767,
    name: "L3-1/2x2-1/2x7/16",
    type: "Angle",
    designation: "L35257",
    shape: "L",
    country_code: "American",
    ax: "2,436",
    ax_global: "1571,60976",
    b: "2,5",
    b_global: "63,5",
    d: "3,5",
    d_global: "88,9",
    r1: "0,535",
    r1_global: "13,589",
    rz: "0,535",
    rz_global: "13,589",
    t: "0,438",
    t_global: "11,1252",
    width: "2,5",
    width_global: "63,5",
    thickness: "0,438",
    thickness_global: "11,1252",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 768,
    name: "L3-1/2X3X3/8",
    type: "Angle",
    designation: "L35306",
    shape: "L",
    country_code: "American",
    ax: "2,32",
    ax_global: "1496,7712",
    b: 3,
    b_global: "76,2",
    d: "3,5",
    d_global: "88,9",
    rz: "0,62",
    rz_global: "15,748",
    t: "0,38",
    t_global: "9,652",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,38",
    thickness_global: "9,652",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 769,
    name: "L3-1/2X3-1/2X3/8",
    type: "Angle",
    designation: "L35356",
    shape: "L",
    country_code: "American",
    ax: "2,5",
    ax_global: "1612,9",
    b: "3,5",
    b_global: "88,9",
    d: "3,5",
    d_global: "88,9",
    rz: "0,68",
    rz_global: "17,272",
    t: "0,38",
    t_global: "9,652",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,38",
    thickness_global: "9,652",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 770,
    name: "L3-1/2X3X7/16",
    type: "Angle",
    designation: "L35307",
    shape: "L",
    country_code: "American",
    ax: "2,67",
    ax_global: "1722,5772",
    b: 3,
    b_global: "76,2",
    d: "3,5",
    d_global: "88,9",
    rz: "0,62",
    rz_global: "15,748",
    t: "0,44",
    t_global: "11,176",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 771,
    name: "L3-1/2X2-1/2X1/2",
    type: "Angle",
    designation: "L35258",
    shape: "L",
    country_code: "American",
    ax: "2,77",
    ax_global: "1787,0932",
    b: "2,5",
    b_global: "63,5",
    d: "3,5",
    d_global: "88,9",
    rz: "0,53",
    rz_global: "13,462",
    t: "0,5",
    t_global: "12,7",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,5",
    thickness_global: "12,7",
    height: "2,5",
    height_global: "63,5",
  },
  {
    profile_section_id: 772,
    name: "L3-1/2x3-1/2x7",
    type: "Angle",
    designation: "L35357",
    shape: "L",
    country_code: "American",
    ax: "2,874",
    ax_global: "1854,18984",
    b: "3,5",
    b_global: "88,9",
    d: "3,5",
    d_global: "88,9",
    r1: "0,684",
    r1_global: "17,3736",
    rz: "0,684",
    rz_global: "17,3736",
    t: "0,438",
    t_global: "11,1252",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,438",
    thickness_global: "11,1252",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 773,
    name: "L3-1/2X3X1/2",
    type: "Angle",
    designation: "L35308",
    shape: "L",
    country_code: "American",
    ax: "3,02",
    ax_global: "1948,3832",
    b: 3,
    b_global: "76,2",
    d: "3,5",
    d_global: "88,9",
    rz: "0,62",
    rz_global: "15,748",
    t: "0,5",
    t_global: "12,7",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 774,
    name: "L3-1/2X3-1/2X1/2",
    type: "Angle",
    designation: "L35358",
    shape: "L",
    country_code: "American",
    ax: "3,25",
    ax_global: "2096,77",
    b: "3,5",
    b_global: "88,9",
    d: "3,5",
    d_global: "88,9",
    rz: "0,68",
    rz_global: "17,272",
    t: "0,5",
    t_global: "12,7",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,5",
    thickness_global: "12,7",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 775,
    name: "L4X3X1/4",
    type: "Angle",
    designation: "L40304",
    shape: "L",
    country_code: "American",
    ax: "1,69",
    ax_global: "1090,3204",
    b: 3,
    b_global: "76,2",
    d: 4,
    d_global: "101,6",
    rz: "0,64",
    rz_global: "16,256",
    t: "0,25",
    t_global: "6,35",
    width: 4,
    width_global: "101,6",
    thickness: "0,25",
    thickness_global: "6,35",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 776,
    name: "L4X3-1/2X1/4",
    type: "Angle",
    designation: "L40354",
    shape: "L",
    country_code: "American",
    ax: "1,82",
    ax_global: "1174,1912",
    b: "3,5",
    b_global: "88,9",
    d: 4,
    d_global: "101,6",
    rz: "0,72",
    rz_global: "18,288",
    t: "0,25",
    t_global: "6,35",
    width: 4,
    width_global: "101,6",
    thickness: "0,25",
    thickness_global: "6,35",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 777,
    name: "L4X4X1/4",
    type: "Angle",
    designation: "L40404",
    shape: "L",
    country_code: "American",
    ax: "1,93",
    ax_global: "1245,1588",
    b: 4,
    b_global: "101,6",
    d: 4,
    d_global: "101,6",
    rz: "0,78",
    rz_global: "19,812",
    t: "0,25",
    t_global: "6,35",
    width: 4,
    width_global: "101,6",
    thickness: "0,25",
    thickness_global: "6,35",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 778,
    name: "L4X3X5/16",
    type: "Angle",
    designation: "L40305",
    shape: "L",
    country_code: "American",
    ax: "2,09",
    ax_global: "1348,3844",
    b: 3,
    b_global: "76,2",
    d: 4,
    d_global: "101,6",
    rz: "0,64",
    rz_global: "16,256",
    t: "0,31",
    t_global: "7,874",
    width: 4,
    width_global: "101,6",
    thickness: "0,31",
    thickness_global: "7,874",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 779,
    name: "L4x3x7/16",
    type: "Angle",
    designation: "L40307",
    shape: "L",
    country_code: "American",
    ax: "2,874",
    ax_global: "1854,18984",
    b: 3,
    b_global: "76,2",
    d: 4,
    d_global: "101,6",
    r1: "0,641",
    r1_global: "16,2814",
    rz: "0,641",
    rz_global: "16,2814",
    t: "0,438",
    t_global: "11,1252",
    width: 3,
    width_global: "76,2",
    thickness: "0,438",
    thickness_global: "11,1252",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 780,
    name: "L4X3-1/2X5/16",
    type: "Angle",
    designation: "L40355",
    shape: "L",
    country_code: "American",
    ax: "2,25",
    ax_global: "1451,61",
    b: "3,5",
    b_global: "88,9",
    d: 4,
    d_global: "101,6",
    rz: "0,72",
    rz_global: "18,288",
    t: "0,31",
    t_global: "7,874",
    width: 4,
    width_global: "101,6",
    thickness: "0,31",
    thickness_global: "7,874",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 781,
    name: "L4X4X5/16",
    type: "Angle",
    designation: "L40405",
    shape: "L",
    country_code: "American",
    ax: "2,4",
    ax_global: "1548,384",
    b: 4,
    b_global: "101,6",
    d: 4,
    d_global: "101,6",
    rz: "0,78",
    rz_global: "19,812",
    t: "0,31",
    t_global: "7,874",
    width: 4,
    width_global: "101,6",
    thickness: "0,31",
    thickness_global: "7,874",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 782,
    name: "L4X3X3/8",
    type: "Angle",
    designation: "L40306",
    shape: "L",
    country_code: "American",
    ax: "2,49",
    ax_global: "1606,4484",
    b: 3,
    b_global: "76,2",
    d: 4,
    d_global: "101,6",
    rz: "0,64",
    rz_global: "16,256",
    t: "0,38",
    t_global: "9,652",
    width: 4,
    width_global: "101,6",
    thickness: "0,38",
    thickness_global: "9,652",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 783,
    name: "L4X3-1/2X3/8",
    type: "Angle",
    designation: "L40356",
    shape: "L",
    country_code: "American",
    ax: "2,68",
    ax_global: "1729,0288",
    b: "3,5",
    b_global: "88,9",
    d: 4,
    d_global: "101,6",
    rz: "0,72",
    rz_global: "18,288",
    t: "0,38",
    t_global: "9,652",
    width: 4,
    width_global: "101,6",
    thickness: "0,38",
    thickness_global: "9,652",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 784,
    name: "L4x3-1/2x7",
    type: "Angle",
    designation: "L40357",
    shape: "L",
    country_code: "American",
    ax: "3,093",
    ax_global: "1995,47988",
    b: "3,5",
    b_global: "88,9",
    d: 4,
    d_global: "101,6",
    r1: "0,724",
    r1_global: "18,3896",
    rz: "0,724",
    rz_global: "18,3896",
    t: "0,438",
    t_global: "11,1252",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,438",
    thickness_global: "11,1252",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 785,
    name: "L4X4X3/8",
    type: "Angle",
    designation: "L40406",
    shape: "L",
    country_code: "American",
    ax: "2,86",
    ax_global: "1845,1576",
    b: 4,
    b_global: "101,6",
    d: 4,
    d_global: "101,6",
    rz: "0,78",
    rz_global: "19,812",
    t: "0,38",
    t_global: "9,652",
    width: 4,
    width_global: "101,6",
    thickness: "0,38",
    thickness_global: "9,652",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 786,
    name: "L4X3X1/2",
    type: "Angle",
    designation: "L40308",
    shape: "L",
    country_code: "American",
    ax: "3,25",
    ax_global: "2096,77",
    b: 3,
    b_global: "76,2",
    d: 4,
    d_global: "101,6",
    rz: "0,63",
    rz_global: "16,002",
    t: "0,5",
    t_global: "12,7",
    width: 4,
    width_global: "101,6",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 787,
    name: "L4X4X7/16",
    type: "Angle",
    designation: "L40407",
    shape: "L",
    country_code: "American",
    ax: "3,3",
    ax_global: "2129,028",
    b: 4,
    b_global: "101,6",
    d: 4,
    d_global: "101,6",
    rz: "0,78",
    rz_global: "19,812",
    t: "0,44",
    t_global: "11,176",
    width: 4,
    width_global: "101,6",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 788,
    name: "L4X3-1/2X1/2",
    type: "Angle",
    designation: "L40358",
    shape: "L",
    country_code: "American",
    ax: "3,5",
    ax_global: "2258,06",
    b: "3,5",
    b_global: "88,9",
    d: 4,
    d_global: "101,6",
    rz: "0,72",
    rz_global: "18,288",
    t: "0,5",
    t_global: "12,7",
    width: 4,
    width_global: "101,6",
    thickness: "0,5",
    thickness_global: "12,7",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 789,
    name: "L4X4X1/2",
    type: "Angle",
    designation: "L40408",
    shape: "L",
    country_code: "American",
    ax: "3,75",
    ax_global: "2419,35",
    b: 4,
    b_global: "101,6",
    d: 4,
    d_global: "101,6",
    rz: "0,78",
    rz_global: "19,812",
    t: "0,5",
    t_global: "12,7",
    width: 4,
    width_global: "101,6",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 790,
    name: "L4X3X5/8",
    type: "Angle",
    designation: "L403010",
    shape: "L",
    country_code: "American",
    ax: "3,99",
    ax_global: "2574,1884",
    b: 3,
    b_global: "76,2",
    d: 4,
    d_global: "101,6",
    rz: "0,63",
    rz_global: "16,002",
    t: "0,63",
    t_global: "16,002",
    width: 4,
    width_global: "101,6",
    thickness: "0,63",
    thickness_global: "16,002",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 791,
    name: "L4x3-1/2x5/8",
    type: "Angle",
    designation: "L403510",
    shape: "L",
    country_code: "American",
    ax: "4,297",
    ax_global: "2772,25252",
    b: "3,5",
    b_global: "88,9",
    d: 4,
    d_global: "101,6",
    r1: "0,719",
    r1_global: "18,2626",
    rz: "0,719",
    rz_global: "18,2626",
    t: "0,625",
    t_global: "15,875",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,625",
    thickness_global: "15,875",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 792,
    name: "L4X4X5/8",
    type: "Angle",
    designation: "L404010",
    shape: "L",
    country_code: "American",
    ax: "4,61",
    ax_global: "2974,1876",
    b: 4,
    b_global: "101,6",
    d: 4,
    d_global: "101,6",
    rz: "0,77",
    rz_global: "19,558",
    t: "0,63",
    t_global: "16,002",
    width: 4,
    width_global: "101,6",
    thickness: "0,63",
    thickness_global: "16,002",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 793,
    name: "L4X4X3/4",
    type: "Angle",
    designation: "L404012",
    shape: "L",
    country_code: "American",
    ax: "5,44",
    ax_global: "3509,6704",
    b: 4,
    b_global: "101,6",
    d: 4,
    d_global: "101,6",
    rz: "0,77",
    rz_global: "19,558",
    t: "0,75",
    t_global: "19,05",
    width: 4,
    width_global: "101,6",
    thickness: "0,75",
    thickness_global: "19,05",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 794,
    name: "L5X3X1/4",
    type: "Angle",
    designation: "L50304",
    shape: "L",
    country_code: "American",
    ax: "1,94",
    ax_global: "1251,6104",
    b: 3,
    b_global: "76,2",
    d: 5,
    d_global: 127,
    rz: "0,65",
    rz_global: "16,51",
    t: "0,25",
    t_global: "6,35",
    width: 5,
    width_global: 127,
    thickness: "0,25",
    thickness_global: "6,35",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 795,
    name: "L5X3-1/2X1/4",
    type: "Angle",
    designation: "L50354",
    shape: "L",
    country_code: "American",
    ax: "2,07",
    ax_global: "1335,4812",
    b: "3,5",
    b_global: "88,9",
    d: 5,
    d_global: 127,
    rz: "0,76",
    rz_global: "19,304",
    t: "0,25",
    t_global: "6,35",
    width: 5,
    width_global: 127,
    thickness: "0,25",
    thickness_global: "6,35",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 796,
    name: "L5X3X5/16",
    type: "Angle",
    designation: "L50305",
    shape: "L",
    country_code: "American",
    ax: "2,41",
    ax_global: "1554,8356",
    b: 3,
    b_global: "76,2",
    d: 5,
    d_global: 127,
    rz: "0,65",
    rz_global: "16,51",
    t: "0,31",
    t_global: "7,874",
    width: 5,
    width_global: 127,
    thickness: "0,31",
    thickness_global: "7,874",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 797,
    name: "L5X3-1/2X5/16",
    type: "Angle",
    designation: "L50355",
    shape: "L",
    country_code: "American",
    ax: "2,56",
    ax_global: "1651,6096",
    b: "3,5",
    b_global: "88,9",
    d: 5,
    d_global: 127,
    rz: "0,76",
    rz_global: "19,304",
    t: "0,31",
    t_global: "7,874",
    width: 5,
    width_global: 127,
    thickness: "0,31",
    thickness_global: "7,874",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 798,
    name: "L5X3X3/8",
    type: "Angle",
    designation: "L50306",
    shape: "L",
    country_code: "American",
    ax: "2,86",
    ax_global: "1845,1576",
    b: 3,
    b_global: "76,2",
    d: 5,
    d_global: 127,
    rz: "0,65",
    rz_global: "16,51",
    t: "0,38",
    t_global: "9,652",
    width: 5,
    width_global: 127,
    thickness: "0,38",
    thickness_global: "9,652",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 799,
    name: "L5X5X5/16",
    type: "Angle",
    designation: "L50505",
    shape: "L",
    country_code: "American",
    ax: "3,07",
    ax_global: "1980,6412",
    b: 5,
    b_global: 127,
    d: 5,
    d_global: 127,
    rz: "0,99",
    rz_global: "25,146",
    t: "0,31",
    t_global: "7,874",
    width: 5,
    width_global: 127,
    thickness: "0,31",
    thickness_global: "7,874",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 800,
    name: "L5X3-1/2X3/8",
    type: "Angle",
    designation: "L50356",
    shape: "L",
    country_code: "American",
    ax: "3,05",
    ax_global: "1967,738",
    b: "3,5",
    b_global: "88,9",
    d: 5,
    d_global: 127,
    rz: "0,76",
    rz_global: "19,304",
    t: "0,38",
    t_global: "9,652",
    width: 5,
    width_global: 127,
    thickness: "0,38",
    thickness_global: "9,652",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 801,
    name: "L5X3X7/16",
    type: "Angle",
    designation: "L50307",
    shape: "L",
    country_code: "American",
    ax: "3,31",
    ax_global: "2135,4796",
    b: 3,
    b_global: "76,2",
    d: 5,
    d_global: 127,
    rz: "0,64",
    rz_global: "16,256",
    t: "0,44",
    t_global: "11,176",
    width: 5,
    width_global: 127,
    thickness: "0,44",
    thickness_global: "11,176",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 802,
    name: "L5X5X3/8",
    type: "Angle",
    designation: "L50506",
    shape: "L",
    country_code: "American",
    ax: "3,65",
    ax_global: "2354,834",
    b: 5,
    b_global: 127,
    d: 5,
    d_global: 127,
    rz: "0,99",
    rz_global: "25,146",
    t: "0,38",
    t_global: "9,652",
    width: 5,
    width_global: 127,
    thickness: "0,38",
    thickness_global: "9,652",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 803,
    name: "L5X3X1/2",
    type: "Angle",
    designation: "L50308",
    shape: "L",
    country_code: "American",
    ax: "3,75",
    ax_global: "2419,35",
    b: 3,
    b_global: "76,2",
    d: 5,
    d_global: 127,
    rz: "0,64",
    rz_global: "16,256",
    t: "0,5",
    t_global: "12,7",
    width: 5,
    width_global: 127,
    thickness: "0,5",
    thickness_global: "12,7",
    height: 3,
    height_global: "76,2",
  },
  {
    profile_section_id: 804,
    name: "L5x3-1/2x7/16",
    type: "Angle",
    designation: "L50357",
    shape: "L",
    country_code: "American",
    ax: "3,493",
    ax_global: "2253,54388",
    b: "3,5",
    b_global: "88,9",
    d: 5,
    d_global: 127,
    r1: "0,758",
    r1_global: "19,2532",
    rz: "0,758",
    rz_global: "19,2532",
    t: "0,433",
    t_global: "10,9982",
    width: "3,5",
    width_global: "88,9",
    thickness: "0,433",
    thickness_global: "10,9982",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 805,
    name: "L5X3-1/2X1/2",
    type: "Angle",
    designation: "L50358",
    shape: "L",
    country_code: "American",
    ax: 4,
    ax_global: "2580,64",
    b: "3,5",
    b_global: "88,9",
    d: 5,
    d_global: 127,
    rz: "0,75",
    rz_global: "19,05",
    t: "0,5",
    t_global: "12,7",
    width: 5,
    width_global: 127,
    thickness: "0,5",
    thickness_global: "12,7",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 806,
    name: "L5X5X7/16",
    type: "Angle",
    designation: "L50507",
    shape: "L",
    country_code: "American",
    ax: "4,22",
    ax_global: "2722,5752",
    b: 5,
    b_global: 127,
    d: 5,
    d_global: 127,
    rz: "0,98",
    rz_global: "24,892",
    t: "0,44",
    t_global: "11,176",
    width: 5,
    width_global: 127,
    thickness: "0,44",
    thickness_global: "11,176",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 807,
    name: "L5X5X1/2",
    type: "Angle",
    designation: "L50508",
    shape: "L",
    country_code: "American",
    ax: "4,79",
    ax_global: "3090,3164",
    b: 5,
    b_global: 127,
    d: 5,
    d_global: 127,
    rz: "0,98",
    rz_global: "24,892",
    t: "0,5",
    t_global: "12,7",
    width: 5,
    width_global: 127,
    thickness: "0,5",
    thickness_global: "12,7",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 808,
    name: "L5x3x5/8",
    type: "Angle",
    designation: "L503010",
    shape: "L",
    country_code: "American",
    ax: "4,609",
    ax_global: "2973,54244",
    b: 3,
    b_global: "76,2",
    d: 5,
    d_global: 127,
    r1: "0,644",
    r1_global: "16,3576",
    rz: "0,644",
    rz_global: "16,3576",
    t: "0,625",
    t_global: "15,875",
    width: 3,
    width_global: "76,2",
    thickness: "0,625",
    thickness_global: "15,875",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 809,
    name: "L5X3-1/2X5/8",
    type: "Angle",
    designation: "L503510",
    shape: "L",
    country_code: "American",
    ax: "4,93",
    ax_global: "3180,6388",
    b: "3,5",
    b_global: "88,9",
    d: 5,
    d_global: 127,
    rz: "0,75",
    rz_global: "19,05",
    t: "0,63",
    t_global: "16,002",
    width: 5,
    width_global: 127,
    thickness: "0,63",
    thickness_global: "16,002",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 810,
    name: "L5X3-1/2X3/4",
    type: "Angle",
    designation: "L503512",
    shape: "L",
    country_code: "American",
    ax: "5,85",
    ax_global: "3774,186",
    b: "3,5",
    b_global: "88,9",
    d: 5,
    d_global: 127,
    rz: "0,74",
    rz_global: "18,796",
    t: "0,75",
    t_global: "19,05",
    width: 5,
    width_global: 127,
    thickness: "0,75",
    thickness_global: "19,05",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 811,
    name: "L5X5X5/8",
    type: "Angle",
    designation: "L505010",
    shape: "L",
    country_code: "American",
    ax: "5,9",
    ax_global: "3806,444",
    b: 5,
    b_global: 127,
    d: 5,
    d_global: 127,
    rz: "0,97",
    rz_global: "24,638",
    t: "0,63",
    t_global: "16,002",
    width: 5,
    width_global: 127,
    thickness: "0,63",
    thickness_global: "16,002",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 812,
    name: "L5X5X3/4",
    type: "Angle",
    designation: "L505012",
    shape: "L",
    country_code: "American",
    ax: "6,98",
    ax_global: "4503,2168",
    b: 5,
    b_global: 127,
    d: 5,
    d_global: 127,
    rz: "0,97",
    rz_global: "24,638",
    t: "0,75",
    t_global: "19,05",
    width: 5,
    width_global: 127,
    thickness: "0,75",
    thickness_global: "19,05",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 813,
    name: "L5X5X7/8",
    type: "Angle",
    designation: "L505014",
    shape: "L",
    country_code: "American",
    ax: 8,
    ax_global: "5161,28",
    b: 5,
    b_global: 127,
    d: 5,
    d_global: 127,
    rz: "0,97",
    rz_global: "24,638",
    t: "0,88",
    t_global: "22,352",
    width: 5,
    width_global: 127,
    thickness: "0,88",
    thickness_global: "22,352",
    height: 5,
    height_global: 127,
  },
  {
    profile_section_id: 814,
    name: "L6X3-1/2X5/16",
    type: "Angle",
    designation: "L60355",
    shape: "L",
    country_code: "American",
    ax: "2,89",
    ax_global: "1864,5124",
    b: "3,5",
    b_global: "88,9",
    d: 6,
    d_global: "152,4",
    rz: "0,77",
    rz_global: "19,558",
    t: "0,31",
    t_global: "7,874",
    width: 6,
    width_global: "152,4",
    thickness: "0,31",
    thickness_global: "7,874",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 815,
    name: "L6X4X5/16",
    type: "Angle",
    designation: "L60405",
    shape: "L",
    country_code: "American",
    ax: "3,03",
    ax_global: "1954,8348",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    rz: "0,87",
    rz_global: "22,098",
    t: "0,31",
    t_global: "7,874",
    width: 6,
    width_global: "152,4",
    thickness: "0,31",
    thickness_global: "7,874",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 816,
    name: "L6X3-1/2X3/8",
    type: "Angle",
    designation: "L60356",
    shape: "L",
    country_code: "American",
    ax: "3,44",
    ax_global: "2219,3504",
    b: "3,5",
    b_global: "88,9",
    d: 6,
    d_global: "152,4",
    rz: "0,76",
    rz_global: "19,304",
    t: "0,38",
    t_global: "9,652",
    width: 6,
    width_global: "152,4",
    thickness: "0,38",
    thickness_global: "9,652",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 817,
    name: "L6X4X3/8",
    type: "Angle",
    designation: "L60406",
    shape: "L",
    country_code: "American",
    ax: "3,61",
    ax_global: "2329,0276",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    rz: "0,87",
    rz_global: "22,098",
    t: "0,38",
    t_global: "9,652",
    width: 6,
    width_global: "152,4",
    thickness: "0,38",
    thickness_global: "9,652",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 818,
    name: "L6X6X5/16",
    type: "Angle",
    designation: "L60605",
    shape: "L",
    country_code: "American",
    ax: "3,67",
    ax_global: "2367,7372",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    rz: "1,19",
    rz_global: "30,226",
    t: "0,31",
    t_global: "7,874",
    width: 6,
    width_global: "152,4",
    thickness: "0,31",
    thickness_global: "7,874",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 819,
    name: "L6X4X7/16",
    type: "Angle",
    designation: "L60407",
    shape: "L",
    country_code: "American",
    ax: "4,18",
    ax_global: "2696,7688",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    rz: "0,87",
    rz_global: "22,098",
    t: "0,44",
    t_global: "11,176",
    width: 6,
    width_global: "152,4",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 820,
    name: "L6X6X3/8",
    type: "Angle",
    designation: "L60606",
    shape: "L",
    country_code: "American",
    ax: "4,38",
    ax_global: "2825,8008",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    rz: "1,19",
    rz_global: "30,226",
    t: "0,38",
    t_global: "9,652",
    width: 6,
    width_global: "152,4",
    thickness: "0,38",
    thickness_global: "9,652",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 821,
    name: "L6X3-1/2X1/2",
    type: "Angle",
    designation: "L60358",
    shape: "L",
    country_code: "American",
    ax: "4,5",
    ax_global: "2903,22",
    b: "3,5",
    b_global: "88,9",
    d: 6,
    d_global: "152,4",
    rz: "0,76",
    rz_global: "19,304",
    t: "0,5",
    t_global: "12,7",
    width: 6,
    width_global: "152,4",
    thickness: "0,5",
    thickness_global: "12,7",
    height: "3,5",
    height_global: "88,9",
  },
  {
    profile_section_id: 822,
    name: "L6X4X1/2",
    type: "Angle",
    designation: "L60408",
    shape: "L",
    country_code: "American",
    ax: "4,75",
    ax_global: "3064,51",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    rz: "0,86",
    rz_global: "21,844",
    t: "0,5",
    t_global: "12,7",
    width: 6,
    width_global: "152,4",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 823,
    name: "L6X6X7/16",
    type: "Angle",
    designation: "L60607",
    shape: "L",
    country_code: "American",
    ax: "5,08",
    ax_global: "3277,4128",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    rz: "1,18",
    rz_global: "29,972",
    t: "0,44",
    t_global: "11,176",
    width: 6,
    width_global: "152,4",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 824,
    name: "L6X4X9/16",
    type: "Angle",
    designation: "L60409",
    shape: "L",
    country_code: "American",
    ax: "5,31",
    ax_global: "3425,7996",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    rz: "0,86",
    rz_global: "21,844",
    t: "0,56",
    t_global: "14,224",
    width: 6,
    width_global: "152,4",
    thickness: "0,56",
    thickness_global: "14,224",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 825,
    name: "L6X6X1/2",
    type: "Angle",
    designation: "L60608",
    shape: "L",
    country_code: "American",
    ax: "5,77",
    ax_global: "3722,5732",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    rz: "1,18",
    rz_global: "29,972",
    t: "0,5",
    t_global: "12,7",
    width: 6,
    width_global: "152,4",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 826,
    name: "L6X4X5/8",
    type: "Angle",
    designation: "L604010",
    shape: "L",
    country_code: "American",
    ax: "5,86",
    ax_global: "3780,6376",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    rz: "0,86",
    rz_global: "21,844",
    t: "0,63",
    t_global: "16,002",
    width: 6,
    width_global: "152,4",
    thickness: "0,63",
    thickness_global: "16,002",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 827,
    name: "L6X6X9/16",
    type: "Angle",
    designation: "L60609",
    shape: "L",
    country_code: "American",
    ax: "6,45",
    ax_global: "4161,282",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    rz: "1,18",
    rz_global: "29,972",
    t: "0,56",
    t_global: "14,224",
    width: 6,
    width_global: "152,4",
    thickness: "0,56",
    thickness_global: "14,224",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 828,
    name: "L6X4X3/4",
    type: "Angle",
    designation: "L604012",
    shape: "L",
    country_code: "American",
    ax: "6,94",
    ax_global: "4477,4104",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    rz: "0,86",
    rz_global: "21,844",
    t: "0,75",
    t_global: "19,05",
    width: 6,
    width_global: "152,4",
    thickness: "0,75",
    thickness_global: "19,05",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 829,
    name: "L6X6X5/8",
    type: "Angle",
    designation: "L606010",
    shape: "L",
    country_code: "American",
    ax: "7,13",
    ax_global: "4599,9908",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    rz: "1,17",
    rz_global: "29,718",
    t: "0,63",
    t_global: "16,002",
    width: 6,
    width_global: "152,4",
    thickness: "0,63",
    thickness_global: "16,002",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 830,
    name: "L6X4X7/8",
    type: "Angle",
    designation: "L604014",
    shape: "L",
    country_code: "American",
    ax: 8,
    ax_global: "5161,28",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    rz: "0,85",
    rz_global: "21,59",
    t: "0,88",
    t_global: "22,352",
    width: 6,
    width_global: "152,4",
    thickness: "0,88",
    thickness_global: "22,352",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 831,
    name: "L6X6X3/4",
    type: "Angle",
    designation: "L606012",
    shape: "L",
    country_code: "American",
    ax: "8,46",
    ax_global: "5458,0536",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    rz: "1,17",
    rz_global: "29,718",
    t: "0,75",
    t_global: "19,05",
    width: 6,
    width_global: "152,4",
    thickness: "0,75",
    thickness_global: "19,05",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 832,
    name: "L6X6X7/8",
    type: "Angle",
    designation: "L606014",
    shape: "L",
    country_code: "American",
    ax: "9,75",
    ax_global: "6290,31",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    rz: "1,17",
    rz_global: "29,718",
    t: "0,88",
    t_global: "22,352",
    width: 6,
    width_global: "152,4",
    thickness: "0,88",
    thickness_global: "22,352",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 833,
    name: "L6X6X1",
    type: "Angle",
    designation: "L606016",
    shape: "L",
    country_code: "American",
    ax: 11,
    ax_global: "7096,76",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    rz: "1,17",
    rz_global: "29,718",
    t: 1,
    t_global: "25,4",
    width: 6,
    width_global: "152,4",
    thickness: 1,
    thickness_global: "25,4",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 834,
    name: "L7X4X3/8",
    type: "Angle",
    designation: "L70406",
    shape: "L",
    country_code: "American",
    ax: 4,
    ax_global: "2580,64",
    b: 4,
    b_global: "101,6",
    d: 7,
    d_global: "177,8",
    rz: "0,87",
    rz_global: "22,098",
    t: "0,38",
    t_global: "9,652",
    width: 7,
    width_global: "177,8",
    thickness: "0,38",
    thickness_global: "9,652",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 835,
    name: "L7X4X7/16",
    type: "Angle",
    designation: "L70407",
    shape: "L",
    country_code: "American",
    ax: "4,63",
    ax_global: "2987,0908",
    b: 4,
    b_global: "101,6",
    d: 7,
    d_global: "177,8",
    rz: "0,87",
    rz_global: "22,098",
    t: "0,44",
    t_global: "11,176",
    width: 7,
    width_global: "177,8",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 836,
    name: "L7X4X1/2",
    type: "Angle",
    designation: "L70408",
    shape: "L",
    country_code: "American",
    ax: "5,26",
    ax_global: "3393,5416",
    b: 4,
    b_global: "101,6",
    d: 7,
    d_global: "177,8",
    rz: "0,87",
    rz_global: "22,098",
    t: "0,5",
    t_global: "12,7",
    width: 7,
    width_global: "177,8",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 837,
    name: "L7X4X5/8",
    type: "Angle",
    designation: "L704010",
    shape: "L",
    country_code: "American",
    ax: "6,5",
    ax_global: "4193,54",
    b: 4,
    b_global: "101,6",
    d: 7,
    d_global: "177,8",
    rz: "0,86",
    rz_global: "21,844",
    t: "0,63",
    t_global: "16,002",
    width: 7,
    width_global: "177,8",
    thickness: "0,63",
    thickness_global: "16,002",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 838,
    name: "L7X4X3/4",
    type: "Angle",
    designation: "L704012",
    shape: "L",
    country_code: "American",
    ax: "7,74",
    ax_global: "4993,5384",
    b: 4,
    b_global: "101,6",
    d: 7,
    d_global: "177,8",
    rz: "0,85",
    rz_global: "21,59",
    t: "0,75",
    t_global: "19,05",
    width: 7,
    width_global: "177,8",
    thickness: "0,75",
    thickness_global: "19,05",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 839,
    name: "L8X4X7/16",
    type: "Angle",
    designation: "L80407",
    shape: "L",
    country_code: "American",
    ax: "5,11",
    ax_global: "3296,7676",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    rz: "0,87",
    rz_global: "22,098",
    t: "0,44",
    t_global: "11,176",
    width: 8,
    width_global: "203,2",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 840,
    name: "L8X4X1/2",
    type: "Angle",
    designation: "L80408",
    shape: "L",
    country_code: "American",
    ax: "5,8",
    ax_global: "3741,928",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    rz: "0,86",
    rz_global: "21,844",
    t: "0,5",
    t_global: "12,7",
    width: 8,
    width_global: "203,2",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 841,
    name: "L8X6X7/16",
    type: "Angle",
    designation: "L80607",
    shape: "L",
    country_code: "American",
    ax: "5,99",
    ax_global: "3864,5084",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    rz: "1,31",
    rz_global: "33,274",
    t: "0,44",
    t_global: "11,176",
    width: 8,
    width_global: "203,2",
    thickness: "0,44",
    thickness_global: "11,176",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 842,
    name: "L8X4X9/16",
    type: "Angle",
    designation: "L80409",
    shape: "L",
    country_code: "American",
    ax: "6,49",
    ax_global: "4187,0884",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    rz: "0,86",
    rz_global: "21,844",
    t: "0,56",
    t_global: "14,224",
    width: 8,
    width_global: "203,2",
    thickness: "0,56",
    thickness_global: "14,224",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 843,
    name: "L8X6X1/2",
    type: "Angle",
    designation: "L80608",
    shape: "L",
    country_code: "American",
    ax: "6,8",
    ax_global: "4387,088",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    rz: "1,3",
    rz_global: "33,02",
    t: "0,5",
    t_global: "12,7",
    width: 8,
    width_global: "203,2",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 844,
    name: "L8X4X5/8",
    type: "Angle",
    designation: "L804010",
    shape: "L",
    country_code: "American",
    ax: "7,16",
    ax_global: "4619,3456",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    rz: "0,86",
    rz_global: "21,844",
    t: "0,63",
    t_global: "16,002",
    width: 8,
    width_global: "203,2",
    thickness: "0,63",
    thickness_global: "16,002",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 845,
    name: "L8X6X9/16",
    type: "Angle",
    designation: "L80609",
    shape: "L",
    country_code: "American",
    ax: "7,61",
    ax_global: "4909,6676",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    rz: "1,3",
    rz_global: "33,02",
    t: "0,56",
    t_global: "14,224",
    width: 8,
    width_global: "203,2",
    thickness: "0,56",
    thickness_global: "14,224",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 846,
    name: "L8X8X1/2",
    type: "Angle",
    designation: "L80808",
    shape: "L",
    country_code: "American",
    ax: "7,84",
    ax_global: "5058,0544",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    rz: "1,59",
    rz_global: "40,386",
    t: "0,5",
    t_global: "12,7",
    width: 8,
    width_global: "203,2",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 847,
    name: "L8X6X5/8",
    type: "Angle",
    designation: "L806010",
    shape: "L",
    country_code: "American",
    ax: "8,41",
    ax_global: "5425,7956",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    rz: "1,29",
    rz_global: "32,766",
    t: "0,63",
    t_global: "16,002",
    width: 8,
    width_global: "203,2",
    thickness: "0,63",
    thickness_global: "16,002",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 848,
    name: "L8X4X3/4",
    type: "Angle",
    designation: "L804012",
    shape: "L",
    country_code: "American",
    ax: "8,49",
    ax_global: "5477,4084",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    rz: "0,85",
    rz_global: "21,59",
    t: "0,75",
    t_global: "19,05",
    width: 8,
    width_global: "203,2",
    thickness: "0,75",
    thickness_global: "19,05",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 849,
    name: "L8X8X9/16",
    type: "Angle",
    designation: "L80809",
    shape: "L",
    country_code: "American",
    ax: "8,77",
    ax_global: "5658,0532",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    rz: "1,58",
    rz_global: "40,132",
    t: "0,56",
    t_global: "14,224",
    width: 8,
    width_global: "203,2",
    thickness: "0,56",
    thickness_global: "14,224",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 850,
    name: "L8X8X5/8",
    type: "Angle",
    designation: "L808010",
    shape: "L",
    country_code: "American",
    ax: "9,69",
    ax_global: "6251,6004",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    rz: "1,58",
    rz_global: "40,132",
    t: "0,63",
    t_global: "16,002",
    width: 8,
    width_global: "203,2",
    thickness: "0,63",
    thickness_global: "16,002",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 851,
    name: "L8X4X7/8",
    type: "Angle",
    designation: "L804014",
    shape: "L",
    country_code: "American",
    ax: "9,79",
    ax_global: "6316,1164",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    rz: "0,85",
    rz_global: "21,59",
    t: "0,88",
    t_global: "22,352",
    width: 8,
    width_global: "203,2",
    thickness: "0,88",
    thickness_global: "22,352",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 852,
    name: "L8X6X3/4",
    type: "Angle",
    designation: "L806012",
    shape: "L",
    country_code: "American",
    ax: "9,99",
    ax_global: "6445,1484",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    rz: "1,29",
    rz_global: "32,766",
    t: "0,75",
    t_global: "19,05",
    width: 8,
    width_global: "203,2",
    thickness: "0,75",
    thickness_global: "19,05",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 853,
    name: "L8X4X1",
    type: "Angle",
    designation: "L804016",
    shape: "L",
    country_code: "American",
    ax: "11,1",
    ax_global: "7161,276",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    rz: "0,84",
    rz_global: "21,336",
    t: 1,
    t_global: "25,4",
    width: 8,
    width_global: "203,2",
    thickness: 1,
    thickness_global: "25,4",
    height: 4,
    height_global: "101,6",
  },
  {
    profile_section_id: 854,
    name: "L8X8X3/4",
    type: "Angle",
    designation: "L808012",
    shape: "L",
    country_code: "American",
    ax: "11,5",
    ax_global: "7419,34",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    rz: "1,57",
    rz_global: "39,878",
    t: "0,75",
    t_global: "19,05",
    width: 8,
    width_global: "203,2",
    thickness: "0,75",
    thickness_global: "19,05",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 855,
    name: "L8X6X7/8",
    type: "Angle",
    designation: "L806014",
    shape: "L",
    country_code: "American",
    ax: "11,5",
    ax_global: "7419,34",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    rz: "1,28",
    rz_global: "32,512",
    t: "0,88",
    t_global: "22,352",
    width: 8,
    width_global: "203,2",
    thickness: "0,88",
    thickness_global: "22,352",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 856,
    name: "L8X6X1",
    type: "Angle",
    designation: "L806016",
    shape: "L",
    country_code: "American",
    ax: "13,1",
    ax_global: "8451,596",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    rz: "1,28",
    rz_global: "32,512",
    t: 1,
    t_global: "25,4",
    width: 8,
    width_global: "203,2",
    thickness: 1,
    thickness_global: "25,4",
    height: 6,
    height_global: "152,4",
  },
  {
    profile_section_id: 857,
    name: "L8X8X7/8",
    type: "Angle",
    designation: "L808014",
    shape: "L",
    country_code: "American",
    ax: "13,3",
    ax_global: "8580,628",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    rz: "1,57",
    rz_global: "39,878",
    t: "0,88",
    t_global: "22,352",
    width: 8,
    width_global: "203,2",
    thickness: "0,88",
    thickness_global: "22,352",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 858,
    name: "L8X8X1",
    type: "Angle",
    designation: "L808016",
    shape: "L",
    country_code: "American",
    ax: "15,1",
    ax_global: "9741,916",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    rz: "1,56",
    rz_global: "39,624",
    t: 1,
    t_global: "25,4",
    width: 8,
    width_global: "203,2",
    thickness: 1,
    thickness_global: "25,4",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 859,
    name: "L8X8X1-1/8",
    type: "Angle",
    designation: "L808018",
    shape: "L",
    country_code: "American",
    ax: "16,8",
    ax_global: "10838,688",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    rz: "1,56",
    rz_global: "39,624",
    t: "1,13",
    t_global: "28,702",
    width: 8,
    width_global: "203,2",
    thickness: "1,13",
    thickness_global: "28,702",
    height: 8,
    height_global: "203,2",
  },
  {
    profile_section_id: 860,
    name: "L9x4x1/2",
    type: "Angle",
    designation: "L90408",
    shape: "L",
    country_code: "American",
    ax: "6,25",
    ax_global: "4032,25",
    b: 4,
    b_global: "101,6",
    d: 9,
    d_global: "228,6",
    r1: "0,854",
    r1_global: "21,6916",
    rz: "0,854",
    rz_global: "21,6916",
    t: "0,5",
    t_global: "12,7",
    width: 4,
    width_global: "101,6",
    thickness: "0,5",
    thickness_global: "12,7",
    height: 9,
    height_global: "228,6",
  },
  {
    profile_section_id: 861,
    name: "L9x4x9/16",
    type: "Angle",
    designation: "L90409",
    shape: "L",
    country_code: "American",
    ax: "7,002",
    ax_global: "4517,41032",
    b: 4,
    b_global: "101,6",
    d: 9,
    d_global: "228,6",
    r1: "0,85",
    r1_global: "21,59",
    rz: "0,85",
    rz_global: "21,59",
    t: "0,563",
    t_global: "14,3002",
    width: 4,
    width_global: "101,6",
    thickness: "0,563",
    thickness_global: "14,3002",
    height: 9,
    height_global: "228,6",
  },
  {
    profile_section_id: 862,
    name: "L9x4x5/8",
    type: "Angle",
    designation: "L904010",
    shape: "L",
    country_code: "American",
    ax: "7,734",
    ax_global: "4989,66744",
    b: 4,
    b_global: "101,6",
    d: 9,
    d_global: "228,6",
    r1: "0,847",
    r1_global: "21,5138",
    rz: "0,847",
    rz_global: "21,5138",
    t: "0,625",
    t_global: "15,875",
    width: 4,
    width_global: "101,6",
    thickness: "0,625",
    thickness_global: "15,875",
    height: 9,
    height_global: "228,6",
  },
  {
    profile_section_id: 863,
    name: "L10X10X3/4",
    type: "Angle",
    designation: "L10010012",
    shape: "L",
    country_code: "American",
    ax: "14,5",
    ax_global: "9354,82",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    rz: "1,96",
    rz_global: "49,784",
    t: "0,75",
    t_global: "19,05",
    width: 10,
    width_global: 254,
    thickness: "0,75",
    thickness_global: "19,05",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 864,
    name: "L10X10X7/8",
    type: "Angle",
    designation: "L10010014",
    shape: "L",
    country_code: "American",
    ax: "16,8",
    ax_global: "10838,688",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    rz: "1,95",
    rz_global: "49,53",
    t: "0,88",
    t_global: "22,352",
    width: 10,
    width_global: 254,
    thickness: "0,88",
    thickness_global: "22,352",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 865,
    name: "L10X10X1",
    type: "Angle",
    designation: "L10010016",
    shape: "L",
    country_code: "American",
    ax: "19,1",
    ax_global: "12322,556",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    rz: "1,95",
    rz_global: "49,53",
    t: 1,
    t_global: "25,4",
    width: 10,
    width_global: 254,
    thickness: 1,
    thickness_global: "25,4",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 866,
    name: "L10X10X1-1/8",
    type: "Angle",
    designation: "L10010018",
    shape: "L",
    country_code: "American",
    ax: "21,4",
    ax_global: "13806,424",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    rz: "1,95",
    rz_global: "49,53",
    t: "1,13",
    t_global: "28,702",
    width: 10,
    width_global: 254,
    thickness: "1,13",
    thickness_global: "28,702",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 867,
    name: "L10X10X1-1/4",
    type: "Angle",
    designation: "L10010020",
    shape: "L",
    country_code: "American",
    ax: "23,5",
    ax_global: "15161,26",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    rz: "1,94",
    rz_global: "49,276",
    t: "1,25",
    t_global: "31,75",
    width: 10,
    width_global: 254,
    thickness: "1,25",
    thickness_global: "31,75",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 868,
    name: "L10X10X1-3/8",
    type: "Angle",
    designation: "L10010022",
    shape: "L",
    country_code: "American",
    ax: "25,7",
    ax_global: "16580,612",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    rz: "1,94",
    rz_global: "49,276",
    t: "1,38",
    t_global: "35,052",
    width: 10,
    width_global: 254,
    thickness: "1,38",
    thickness_global: "35,052",
    height: 10,
    height_global: 254,
  },
  {
    profile_section_id: 869,
    name: "L12X12X1",
    type: "Angle",
    designation: "L12012016",
    shape: "L",
    country_code: "American",
    ax: 23,
    ax_global: "14838,68",
    b: 12,
    b_global: "304,8",
    d: 12,
    d_global: "304,8",
    rz: "2,34",
    rz_global: "59,436",
    t: 1,
    t_global: "25,4",
    width: 12,
    width_global: "304,8",
    thickness: 1,
    thickness_global: "25,4",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 870,
    name: "L12X12X1-1/8",
    type: "Angle",
    designation: "L12012018",
    shape: "L",
    country_code: "American",
    ax: "25,8",
    ax_global: "16645,128",
    b: 12,
    b_global: "304,8",
    d: 12,
    d_global: "304,8",
    rz: "2,34",
    rz_global: "59,436",
    t: "1,13",
    t_global: "28,702",
    width: 12,
    width_global: "304,8",
    thickness: "1,13",
    thickness_global: "28,702",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 871,
    name: "L12X12X1-1/4",
    type: "Angle",
    designation: "L12012020",
    shape: "L",
    country_code: "American",
    ax: "28,4",
    ax_global: "18322,544",
    b: 12,
    b_global: "304,8",
    d: 12,
    d_global: "304,8",
    rz: "2,33",
    rz_global: "59,182",
    t: "1,25",
    t_global: "31,75",
    width: 12,
    width_global: "304,8",
    thickness: "1,25",
    thickness_global: "31,75",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 872,
    name: "L12X12X1-3/8",
    type: "Angle",
    designation: "L12012022",
    shape: "L",
    country_code: "American",
    ax: "31,1",
    ax_global: "20064,476",
    b: 12,
    b_global: "304,8",
    d: 12,
    d_global: "304,8",
    rz: "2,32",
    rz_global: "58,928",
    t: "1,38",
    t_global: "35,052",
    width: 12,
    width_global: "304,8",
    thickness: "1,38",
    thickness_global: "35,052",
    height: 12,
    height_global: "304,8",
  },
  {
    profile_section_id: 873,
    name: "HSS20X12X5/8",
    type: "HSS Rectangle",
    designation: "HSST20X12X0.625",
    shape: "RHS",
    country_code: "American",
    ax: 35,
    ax_global: "22580,6",
    b: 12,
    b_global: "304,8",
    c: 257,
    c_global: "4211475,448",
    d: 20,
    d_global: 508,
    ix: 1890,
    ix_global: "786677394,4",
    iy: 851,
    iy_global: "354212943,2",
    iz: 1880,
    iz_global: "782515080,1",
    t: "0,581",
    t_global: "14,7574",
    zy: 162,
    zy_global: "2654704,368",
    zz: 230,
    zz_global: "3769024,72",
  },
  {
    profile_section_id: 874,
    name: "HSS20X12X1/2",
    type: "HSS Rectangle",
    designation: "HSST20X12X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "28,3",
    ax_global: "18258,028",
    b: 12,
    b_global: "304,8",
    c: 209,
    c_global: "3424896,376",
    d: 20,
    d_global: 508,
    ix: 1540,
    ix_global: "640996395,4",
    iy: 705,
    iy_global: 293443155,
    iz: 1550,
    iz_global: "645158709,7",
    t: "0,465",
    t_global: "11,811",
    zy: 132,
    zy_global: "2163092,448",
    zz: 188,
    zz_global: "3080768,032",
  },
  {
    profile_section_id: 875,
    name: "HSS20X12X3/8",
    type: "HSS Rectangle",
    designation: "HSST20X12X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "21,5",
    ax_global: "13870,94",
    b: 12,
    b_global: "304,8",
    c: 160,
    c_global: "2621930,24",
    d: 20,
    d_global: 508,
    ix: 1180,
    ix_global: "491153082,2",
    iy: 547,
    iy_global: "227678589,8",
    iz: 1200,
    iz_global: "499477710,7",
    t: "0,349",
    t_global: "8,8646",
    zy: 102,
    zy_global: "1671480,528",
    zz: 144,
    zz_global: "2359737,216",
  },
  {
    profile_section_id: 876,
    name: "HSS20X12X5/16",
    type: "HSS Rectangle",
    designation: "HSST20X12X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "18,1",
    ax_global: "11677,396",
    b: 12,
    b_global: "304,8",
    c: 134,
    c_global: "2195866,576",
    d: 20,
    d_global: 508,
    ix: 997,
    ix_global: "414982731,3",
    iy: 464,
    iy_global: "193131381,5",
    iz: 1010,
    iz_global: "420393739,9",
    t: "0,291",
    t_global: "7,3914",
    zy: "85,8",
    zy_global: "1406010,091",
    zz: 122,
    zz_global: "1999221,808",
  },
  {
    profile_section_id: 877,
    name: "HSS20X8X5/8",
    type: "HSS Rectangle",
    designation: "HSST20X8X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "30,3",
    ax_global: "19548,348",
    b: 8,
    b_global: "203,2",
    c: 167,
    c_global: "2736639,688",
    d: 20,
    d_global: 508,
    ix: 916,
    ix_global: "381267985,8",
    iy: 338,
    iy_global: "140686221,9",
    iz: 1440,
    iz_global: "599373252,9",
    t: "0,581",
    t_global: "14,7574",
    zy: "96,4",
    zy_global: "1579712,97",
    zz: 185,
    zz_global: "3031606,84",
  },
  {
    profile_section_id: 878,
    name: "HSS20X8X1/2",
    type: "HSS Rectangle",
    designation: "HSST20X8X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "24,6",
    ax_global: "15870,936",
    b: 8,
    b_global: "203,2",
    c: 137,
    c_global: "2245027,768",
    d: 20,
    d_global: 508,
    ix: 757,
    ix_global: "315087189,2",
    iy: 283,
    iy_global: "117793493,4",
    iz: 1190,
    iz_global: "495315396,5",
    t: "0,465",
    t_global: "11,811",
    zy: "79,5",
    zy_global: "1302771,588",
    zz: 152,
    zz_global: "2490833,728",
  },
  {
    profile_section_id: 879,
    name: "HSS20X8X3/8",
    type: "HSS Rectangle",
    designation: "HSST20X8X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "18,7",
    ax_global: "12064,492",
    b: 8,
    b_global: "203,2",
    c: 105,
    c_global: "1720641,72",
    d: 20,
    d_global: 508,
    ix: 586,
    ix_global: "243911615,4",
    iy: 222,
    iy_global: "92403376,48",
    iz: 926,
    iz_global: "385430300,1",
    t: "0,349",
    t_global: "8,8646",
    zy: "61,5",
    zy_global: "1007804,436",
    zz: 117,
    zz_global: "1917286,488",
  },
  {
    profile_section_id: 880,
    name: "HSS20X8X5/16",
    type: "HSS Rectangle",
    designation: "HSST20X8X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "15,7",
    ax_global: "10129,012",
    b: 8,
    b_global: "203,2",
    c: "88,3",
    c_global: "1446977,751",
    d: 20,
    d_global: 508,
    ix: 496,
    ix_global: "206450787,1",
    iy: 189,
    iy_global: "78667739,44",
    iz: 786,
    iz_global: "327157900,5",
    t: "0,291",
    t_global: "7,3914",
    zy: 52,
    zy_global: "852127,328",
    zz: "98,6",
    zz_global: "1615764,51",
  },
  {
    profile_section_id: 881,
    name: "HSS20X4X1/2",
    type: "HSS Rectangle",
    designation: "HSST20X4X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "20,9",
    ax_global: "13483,844",
    b: 4,
    b_global: "101,6",
    c: "63,8",
    c_global: "1045494,683",
    d: 20,
    d_global: 508,
    ix: 195,
    ix_global: "81165127,99",
    iy: "58,7",
    iy_global: "24432784,68",
    iz: 838,
    iz_global: "348801934,7",
    t: "0,465",
    t_global: "11,811",
    zy: 34,
    zy_global: "557160,176",
    zz: 115,
    zz_global: "1884512,36",
  },
  {
    profile_section_id: 882,
    name: "HSS20X4X3/8",
    type: "HSS Rectangle",
    designation: "HSST20X4X0.375",
    shape: "RHS",
    country_code: "American",
    ax: 16,
    ax_global: "10322,56",
    b: 4,
    b_global: "101,6",
    c: "49,9",
    c_global: "817714,4936",
    d: 20,
    d_global: 508,
    ix: 156,
    ix_global: "64932102,39",
    iy: "47,6",
    iy_global: "19812615,86",
    iz: 657,
    iz_global: "273464046,6",
    t: "0,349",
    t_global: "8,8646",
    zy: "26,8",
    zy_global: "439173,3152",
    zz: "89,3",
    zz_global: "1463364,815",
  },
  {
    profile_section_id: 883,
    name: "HSS20X4X5/16",
    type: "HSS Rectangle",
    designation: "HSST20X4X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "13,4",
    ax_global: "8645,144",
    b: 4,
    b_global: "101,6",
    c: "42,4",
    c_global: "694811,5136",
    d: 20,
    d_global: 508,
    ix: 134,
    ix_global: "55775011,03",
    iy: "41,2",
    iy_global: "17148734,73",
    iz: 560,
    iz_global: "233089598,3",
    t: "0,291",
    t_global: "7,3914",
    zy: "22,9",
    zy_global: "375263,7656",
    zz: "75,6",
    zz_global: "1238862,038",
  },
  {
    profile_section_id: 884,
    name: "HSS20X4X1/4",
    type: "HSS Rectangle",
    designation: "HSST20X4X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "10,8",
    ax_global: "6967,728",
    b: 4,
    b_global: "101,6",
    c: "34,7",
    c_global: "568631,1208",
    d: 20,
    d_global: 508,
    ix: 111,
    ix_global: "46201688,24",
    iy: "34,3",
    iy_global: "14276737,9",
    iz: 458,
    iz_global: "190633992,9",
    t: "0,233",
    t_global: "5,9182",
    zy: "18,7",
    zy_global: "306438,0968",
    zz: "61,5",
    zz_global: "1007804,436",
  },
  {
    profile_section_id: 885,
    name: "HSS18X6X5/8",
    type: "HSS Rectangle",
    designation: "HSST18X6X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "25,7",
    ax_global: "16580,612",
    b: 6,
    b_global: "152,4",
    c: 109,
    c_global: "1786189,976",
    d: 18,
    d_global: "457,2",
    ix: 462,
    ix_global: "192298918,6",
    iy: 158,
    iy_global: "65764565,24",
    iz: 923,
    iz_global: "384181605,8",
    t: "0,581",
    t_global: "14,7574",
    zy: 61,
    zy_global: "999610,904",
    zz: 135,
    zz_global: "2212253,64",
  },
  {
    profile_section_id: 886,
    name: "HSS18X6X1/2",
    type: "HSS Rectangle",
    designation: "HSST18X6X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "20,9",
    ax_global: "13483,844",
    b: 6,
    b_global: "152,4",
    c: "89,9",
    c_global: "1473197,054",
    d: 18,
    d_global: "457,2",
    ix: 387,
    ix_global: "161081561,7",
    iy: 134,
    iy_global: "55775011,03",
    iz: 770,
    iz_global: "320498197,7",
    t: "0,465",
    t_global: "11,811",
    zy: "50,7",
    zy_global: "830824,1448",
    zz: 112,
    zz_global: "1835351,168",
  },
  {
    profile_section_id: 887,
    name: "HSS18X6X3/8",
    type: "HSS Rectangle",
    designation: "HSST18X6X0.375",
    shape: "RHS",
    country_code: "American",
    ax: 16,
    ax_global: "10322,56",
    b: 6,
    b_global: "152,4",
    c: "69,5",
    c_global: "1138900,948",
    d: 18,
    d_global: "457,2",
    ix: 302,
    ix_global: "125701890,5",
    iy: 106,
    iy_global: "44120531,11",
    iz: 602,
    iz_global: "250571318,2",
    t: "0,349",
    t_global: "8,8646",
    zy: "39,5",
    zy_global: "647289,028",
    zz: "86,4",
    zz_global: "1415842,33",
  },
  {
    profile_section_id: 888,
    name: "HSS18X6X5/16",
    type: "HSS Rectangle",
    designation: "HSST18X6X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "13,4",
    ax_global: "8645,144",
    b: 6,
    b_global: "152,4",
    c: "58,7",
    c_global: "961920,6568",
    d: 18,
    d_global: "457,2",
    ix: 257,
    ix_global: "106971476,4",
    iy: "91,3",
    iy_global: "38001929,16",
    iz: 513,
    iz_global: "213526721,3",
    t: "0,291",
    t_global: "7,3914",
    zy: "33,5",
    zy_global: "548966,644",
    zz: "73,1",
    zz_global: "1197894,378",
  },
  {
    profile_section_id: 889,
    name: "HSS18X6X1/4",
    type: "HSS Rectangle",
    designation: "HSST18X6X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "10,8",
    ax_global: "6967,728",
    b: 6,
    b_global: "152,4",
    c: "47,7",
    c_global: "781662,9528",
    d: 18,
    d_global: "457,2",
    ix: 210,
    ix_global: "87408599,38",
    iy: "75,1",
    iy_global: "31258980,06",
    iz: 419,
    iz_global: "174400967,3",
    t: "0,233",
    t_global: "5,9182",
    zy: "27,3",
    zy_global: "447366,8472",
    zz: "59,4",
    zz_global: "973391,6016",
  },
  {
    profile_section_id: 890,
    name: "HSS16X16X5/8",
    type: "HSS Rectangle",
    designation: "HSST16X16X0.625",
    shape: "RHS",
    country_code: "American",
    ax: 35,
    ax_global: "22580,6",
    b: 16,
    b_global: "406,4",
    c: 276,
    c_global: "4522829,664",
    d: 16,
    d_global: "406,4",
    ix: 2170,
    ix_global: "903222193,6",
    iy: 1370,
    iy_global: "570237053,1",
    iz: 1370,
    iz_global: "570237053,1",
    t: "0,581",
    t_global: "14,7574",
    zy: 200,
    zy_global: "3277412,8",
    zz: 200,
    zz_global: "3277412,8",
  },
  {
    profile_section_id: 891,
    name: "HSS16X16X1/2",
    type: "HSS Rectangle",
    designation: "HSST16X16X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "28,3",
    ax_global: "18258,028",
    b: 16,
    b_global: "406,4",
    c: 224,
    c_global: "3670702,336",
    d: 16,
    d_global: "406,4",
    ix: 1770,
    ix_global: "736729623,3",
    iy: 1130,
    iy_global: "470341510,9",
    iz: 1130,
    iz_global: "470341510,9",
    t: "0,465",
    t_global: "11,811",
    zy: 164,
    zy_global: "2687478,496",
    zz: 164,
    zz_global: "2687478,496",
  },
  {
    profile_section_id: 892,
    name: "HSS16X16X3/8",
    type: "HSS Rectangle",
    designation: "HSST16X16X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "21,5",
    ax_global: "13870,94",
    b: 16,
    b_global: "406,4",
    c: 171,
    c_global: "2802187,944",
    d: 16,
    d_global: "406,4",
    ix: 1350,
    ix_global: "561912424,6",
    iy: 873,
    iy_global: "363370034,5",
    iz: 873,
    iz_global: "363370034,5",
    t: "0,349",
    t_global: "8,8646",
    zy: 126,
    zy_global: "2064770,064",
    zz: 126,
    zz_global: "2064770,064",
  },
  {
    profile_section_id: 893,
    name: "HSS16X16X5/16",
    type: "HSS Rectangle",
    designation: "HSST16X16X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "18,1",
    ax_global: "11677,396",
    b: 16,
    b_global: "406,4",
    c: 144,
    c_global: "2359737,216",
    d: 16,
    d_global: "406,4",
    ix: 1140,
    ix_global: "474503825,2",
    iy: 739,
    iy_global: "307595023,5",
    iz: 739,
    iz_global: "307595023,5",
    t: "0,291",
    t_global: "7,3914",
    zy: 106,
    zy_global: "1737028,784",
    zz: 106,
    zz_global: "1737028,784",
  },
  {
    profile_section_id: 894,
    name: "HSS16X12X5/8",
    type: "HSS Rectangle",
    designation: "HSST16X12X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "30,3",
    ax_global: "19548,348",
    b: 12,
    b_global: "304,8",
    c: 204,
    c_global: "3342961,056",
    d: 16,
    d_global: "406,4",
    ix: 1370,
    ix_global: "570237053,1",
    iy: 700,
    iy_global: "291361997,9",
    iz: 1090,
    iz_global: "453692253,9",
    t: "0,581",
    t_global: "14,7574",
    zy: 135,
    zy_global: "2212253,64",
    zz: 165,
    zz_global: "2703865,56",
  },
  {
    profile_section_id: 895,
    name: "HSS16X12X1/2",
    type: "HSS Rectangle",
    designation: "HSST16X12X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "24,6",
    ax_global: "15870,936",
    b: 12,
    b_global: "304,8",
    c: 166,
    c_global: "2720252,624",
    d: 16,
    d_global: "406,4",
    ix: 1120,
    ix_global: "466179196,7",
    iy: 581,
    iy_global: "241830458,3",
    iz: 904,
    iz_global: "376273208,7",
    t: "0,465",
    t_global: "11,811",
    zy: 111,
    zy_global: "1818964,104",
    zz: 135,
    zz_global: "2212253,64",
  },
  {
    profile_section_id: 896,
    name: "HSS16X12X3/8",
    type: "HSS Rectangle",
    designation: "HSST16X12X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "18,7",
    ax_global: "12064,492",
    b: 12,
    b_global: "304,8",
    c: 127,
    c_global: "2081157,128",
    d: 16,
    d_global: "406,4",
    ix: 862,
    ix_global: "358791488,9",
    iy: 452,
    iy_global: "188136604,4",
    iz: 702,
    iz_global: "292194460,8",
    t: "0,349",
    t_global: "8,8646",
    zy: "85,5",
    zy_global: "1401093,972",
    zz: 104,
    zz_global: "1704254,656",
  },
  {
    profile_section_id: 897,
    name: "HSS16X12X5/16",
    type: "HSS Rectangle",
    designation: "HSST16X12X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "15,7",
    ax_global: "10129,012",
    b: 12,
    b_global: "304,8",
    c: 107,
    c_global: "1753415,848",
    d: 16,
    d_global: "406,4",
    ix: 727,
    ix_global: "302600246,4",
    iy: 384,
    iy_global: "159832867,4",
    iz: 595,
    iz_global: "247657698,2",
    t: "0,291",
    t_global: "7,3914",
    zy: "72,2",
    zy_global: "1183146,021",
    zz: "87,7",
    zz_global: "1437145,513",
  },
  {
    profile_section_id: 898,
    name: "HSS16X8X5/8",
    type: "HSS Rectangle",
    designation: "HSST16X8X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "25,7",
    ax_global: "16580,612",
    b: 8,
    b_global: "203,2",
    c: 132,
    c_global: "2163092,448",
    d: 16,
    d_global: "406,4",
    ix: 681,
    ix_global: "283453600,8",
    iy: 274,
    iy_global: "114047410,6",
    iz: 815,
    iz_global: "339228611,9",
    t: "0,581",
    t_global: "14,7574",
    zy: "79,2",
    zy_global: "1297855,469",
    zz: 129,
    zz_global: "2113931,256",
  },
  {
    profile_section_id: 899,
    name: "HSS16X8X1/2",
    type: "HSS Rectangle",
    designation: "HSST16X8X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "20,9",
    ax_global: "13483,844",
    b: 8,
    b_global: "203,2",
    c: 108,
    c_global: "1769802,912",
    d: 16,
    d_global: "406,4",
    ix: 563,
    ix_global: "234338292,6",
    iy: 230,
    iy_global: "95733227,89",
    iz: 679,
    iz_global: 282621138,
    t: "0,465",
    t_global: "11,811",
    zy: "65,5",
    zy_global: "1073352,692",
    zz: 106,
    zz_global: "1737028,784",
  },
  {
    profile_section_id: 900,
    name: "HSS16X8X3/8",
    type: "HSS Rectangle",
    designation: "HSST16X8X0.375",
    shape: "RHS",
    country_code: "American",
    ax: 16,
    ax_global: "10322,56",
    b: 8,
    b_global: "203,2",
    c: "83,4",
    c_global: "1366681,138",
    d: 16,
    d_global: "406,4",
    ix: 436,
    ix_global: "181476901,6",
    iy: 181,
    iy_global: "75337888,03",
    iz: 531,
    iz_global: 221018887,
    t: "0,349",
    t_global: "8,8646",
    zy: "50,8",
    zy_global: "832462,8512",
    zz: "82,1",
    zz_global: "1345377,954",
  },
  {
    profile_section_id: 901,
    name: "HSS16X8X5/16",
    type: "HSS Rectangle",
    designation: "HSST16X8X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "13,4",
    ax_global: "8645,144",
    b: 8,
    b_global: "203,2",
    c: "70,4",
    c_global: "1153649,306",
    d: 16,
    d_global: "406,4",
    ix: 369,
    ix_global: 153589396,
    iy: 155,
    iy_global: "64515870,97",
    iz: 451,
    iz_global: "187720372,9",
    t: "0,291",
    t_global: "7,3914",
    zy: 43,
    zy_global: "704643,752",
    zz: "69,4",
    zz_global: "1137262,242",
  },
  {
    profile_section_id: 902,
    name: "HSS16X8X1/4",
    type: "HSS Rectangle",
    designation: "HSST16X8X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "10,8",
    ax_global: "6967,728",
    b: 8,
    b_global: "203,2",
    c: 57,
    c_global: "934062,648",
    d: 16,
    d_global: "406,4",
    ix: 300,
    ix_global: "124869427,7",
    iy: 127,
    iy_global: "52861391,05",
    iz: 368,
    iz_global: "153173164,6",
    t: "0,233",
    t_global: "5,9182",
    zy: 35,
    zy_global: "573547,24",
    zz: "56,4",
    zz_global: "924230,4096",
  },
  {
    profile_section_id: 903,
    name: "HSS16X4X5/8",
    type: "HSS Rectangle",
    designation: "HSST16X4X0.625",
    shape: "RHS",
    country_code: "American",
    ax: 21,
    ax_global: "13548,36",
    b: 4,
    b_global: "101,6",
    c: "60,5",
    c_global: "991417,372",
    d: 16,
    d_global: "406,4",
    ix: 174,
    ix_global: "72424268,05",
    iy: "54,1",
    iy_global: "22518120,12",
    iz: 539,
    iz_global: "224348738,4",
    t: "0,581",
    t_global: "14,7574",
    zy: "32,5",
    zy_global: "532579,58",
    zz: "92,9",
    zz_global: "1522358,246",
  },
  {
    profile_section_id: 904,
    name: "HSS16X4X1/2",
    type: "HSS Rectangle",
    designation: "HSST16X4X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "17,2",
    ax_global: "11096,752",
    b: 4,
    b_global: "101,6",
    c: "50,7",
    c_global: "830824,1448",
    d: 16,
    d_global: "406,4",
    ix: 150,
    ix_global: "62434713,84",
    iy: 47,
    iy_global: 19562877,
    iz: 455,
    iz_global: "189385298,6",
    t: "0,465",
    t_global: "11,811",
    zy: "27,4",
    zy_global: "449005,5536",
    zz: "77,3",
    zz_global: "1266720,047",
  },
  {
    profile_section_id: 905,
    name: "HSS16X4X3/8",
    type: "HSS Rectangle",
    designation: "HSST16X4X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "13,2",
    ax_global: "8516,112",
    b: 4,
    b_global: "101,6",
    c: "39,7",
    c_global: "650566,4408",
    d: 16,
    d_global: "406,4",
    ix: 120,
    ix_global: "49947771,07",
    iy: "38,3",
    iy_global: "15941663,6",
    iz: 360,
    iz_global: "149843313,2",
    t: "0,349",
    t_global: "8,8646",
    zy: "21,7",
    zy_global: "355599,2888",
    zz: "60,2",
    zz_global: "986501,2528",
  },
  {
    profile_section_id: 906,
    name: "HSS16X4X5/16",
    type: "HSS Rectangle",
    designation: "HSST16X4X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "11,1",
    ax_global: "7161,276",
    b: 4,
    b_global: "101,6",
    c: "33,8",
    c_global: "553882,7632",
    d: 16,
    d_global: "406,4",
    ix: 103,
    ix_global: "42871836,84",
    iy: "33,2",
    iy_global: "13818883,33",
    iz: 308,
    iz_global: "128199279,1",
    t: "0,291",
    t_global: "7,3914",
    zy: "18,5",
    zy_global: "303160,684",
    zz: "51,1",
    zz_global: "837378,9704",
  },
  {
    profile_section_id: 907,
    name: "HSS16X4X1/4",
    type: "HSS Rectangle",
    designation: "HSST16X4X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "8,96",
    ax_global: "5780,6336",
    b: 4,
    b_global: "101,6",
    c: "27,6",
    c_global: "452282,9664",
    d: 16,
    d_global: "406,4",
    ix: "85,2",
    ix_global: "35462917,46",
    iy: "27,7",
    iy_global: "11529610,49",
    iz: 253,
    iz_global: "105306550,7",
    t: "0,233",
    t_global: "5,9182",
    zy: "15,2",
    zy_global: "249083,3728",
    zz: "41,7",
    zz_global: "683340,5688",
  },
  {
    profile_section_id: 908,
    name: "HSS16X4X3/16",
    type: "HSS Rectangle",
    designation: "HSST16X4X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "6,76",
    ax_global: "4361,2816",
    b: 4,
    b_global: "101,6",
    c: "21,1",
    c_global: "345767,0504",
    d: 14,
    d_global: "355,6",
    ix: "65,5",
    ix_global: "27263158,38",
    iy: "21,5",
    iy_global: "8948975,65",
    iz: 193,
    iz_global: "80332665,14",
    t: "0,174",
    t_global: "4,4196",
    zy: "11,7",
    zy_global: "191728,6488",
    zz: "31,7",
    zz_global: "519469,9288",
  },
  {
    profile_section_id: 909,
    name: "HSS14X14X5/8",
    type: "HSS Rectangle",
    designation: "HSST14X14X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "30,3",
    ax_global: "19548,348",
    b: 14,
    b_global: "355,6",
    c: 208,
    c_global: "3408509,312",
    d: 14,
    d_global: "355,6",
    ix: 1430,
    ix_global: "595210938,6",
    iy: 897,
    iy_global: "373359588,8",
    iz: 897,
    iz_global: "373359588,8",
    t: "0,581",
    t_global: "14,7574",
    zy: 151,
    zy_global: "2474446,664",
    zz: 151,
    zz_global: "2474446,664",
  },
  {
    profile_section_id: 910,
    name: "HSS14X14X1/2",
    type: "HSS Rectangle",
    designation: "HSST14X14X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "24,6",
    ax_global: "15870,936",
    b: 14,
    b_global: "355,6",
    c: 170,
    c_global: "2785800,88",
    d: 14,
    d_global: "355,6",
    ix: 1170,
    ix_global: 486990768,
    iy: 743,
    iy_global: "309259949,2",
    iz: 743,
    iz_global: "309259949,2",
    t: "0,465",
    t_global: "11,811",
    zy: 124,
    zy_global: "2031995,936",
    zz: 124,
    zz_global: "2031995,936",
  },
  {
    profile_section_id: 911,
    name: "HSS14X14X3/8",
    type: "HSS Rectangle",
    designation: "HSST14X14X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "18,7",
    ax_global: "12064,492",
    b: 14,
    b_global: "355,6",
    c: 130,
    c_global: "2130318,32",
    d: 14,
    d_global: "355,6",
    ix: 900,
    ix_global: 374608283,
    iy: 577,
    iy_global: "240165532,6",
    iz: 577,
    iz_global: "240165532,6",
    t: "0,349",
    t_global: "8,8646",
    zy: "95,4",
    zy_global: "1563325,906",
    zz: "95,4",
    zz_global: "1563325,906",
  },
  {
    profile_section_id: 912,
    name: "HSS14X14X5/16",
    type: "HSS Rectangle",
    designation: "HSST14X14X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "15,7",
    ax_global: "10129,012",
    b: 14,
    b_global: "355,6",
    c: 109,
    c_global: "1786189,976",
    d: 14,
    d_global: "355,6",
    ix: 759,
    ix_global: 315919652,
    iy: 490,
    iy_global: "203953398,5",
    iz: 490,
    iz_global: "203953398,5",
    t: "0,291",
    t_global: "7,3914",
    zy: "80,5",
    zy_global: "1319158,652",
    zz: "80,5",
    zz_global: "1319158,652",
  },
  {
    profile_section_id: 913,
    name: "HSS14X10X5/8",
    type: "HSS Rectangle",
    designation: "HSST14X10X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "25,7",
    ax_global: "16580,612",
    b: 10,
    b_global: 254,
    c: 146,
    c_global: "2392511,344",
    d: 14,
    d_global: "355,6",
    ix: 832,
    ix_global: "346304546,1",
    iy: 407,
    iy_global: "169406190,2",
    iz: 687,
    iz_global: "285950989,4",
    t: "0,581",
    t_global: "14,7574",
    zy: "95,1",
    zy_global: "1558409,786",
    zz: 120,
    zz_global: "1966447,68",
  },
  {
    profile_section_id: 914,
    name: "HSS14X10X1/2",
    type: "HSS Rectangle",
    designation: "HSST14X10X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "20,9",
    ax_global: "13483,844",
    b: 10,
    b_global: 254,
    c: 120,
    c_global: "1966447,68",
    d: 14,
    d_global: "355,6",
    ix: 685,
    ix_global: "285118526,5",
    iy: 341,
    iy_global: "141934916,1",
    iz: 573,
    iz_global: "238500606,9",
    t: "0,465",
    t_global: "11,811",
    zy: "78,5",
    zy_global: "1286384,524",
    zz: "98,8",
    zz_global: "1619041,923",
  },
  {
    profile_section_id: 915,
    name: "HSS14X10X3/8",
    type: "HSS Rectangle",
    designation: "HSST14X10X0.375",
    shape: "RHS",
    country_code: "American",
    ax: 16,
    ax_global: "10322,56",
    b: 10,
    b_global: 254,
    c: "91,8",
    c_global: "1504332,475",
    d: 14,
    d_global: "355,6",
    ix: 528,
    ix_global: "219770192,7",
    iy: 267,
    iy_global: "111133790,6",
    iz: 447,
    iz_global: "186055447,2",
    t: "0,349",
    t_global: "8,8646",
    zy: "60,7",
    zy_global: "994694,7848",
    zz: "76,3",
    zz_global: "1250332,983",
  },
  {
    profile_section_id: 916,
    name: "HSS14X10X5/16",
    type: "HSS Rectangle",
    designation: "HSST14X10X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "13,4",
    ax_global: "8645,144",
    b: 10,
    b_global: 254,
    c: "77,4",
    c_global: "1268358,754",
    d: 14,
    d_global: "355,6",
    ix: 446,
    ix_global: "185639215,8",
    iy: 227,
    iy_global: "94484533,61",
    iz: 380,
    iz_global: "158167941,7",
    t: "0,291",
    t_global: "7,3914",
    zy: "51,4",
    zy_global: "842295,0896",
    zz: "64,6",
    zz_global: "1058604,334",
  },
  {
    profile_section_id: 917,
    name: "HSS14X10X1/4",
    type: "HSS Rectangle",
    designation: "HSST14X10X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "10,8",
    ax_global: "6967,728",
    b: 10,
    b_global: 254,
    c: "62,6",
    c_global: "1025830,206",
    d: 14,
    d_global: "355,6",
    ix: 362,
    ix_global: "150675776,1",
    iy: 186,
    iy_global: "77419045,16",
    iz: 310,
    iz_global: "129031741,9",
    t: "0,233",
    t_global: "5,9182",
    zy: "41,8",
    zy_global: "684979,2752",
    zz: "52,4",
    zz_global: "858682,1536",
  },
  {
    profile_section_id: 918,
    name: "HSS14X6X5/8",
    type: "HSS Rectangle",
    designation: "HSST14X6X0.625",
    shape: "RHS",
    country_code: "American",
    ax: 21,
    ax_global: "13548,36",
    b: 10,
    b_global: 254,
    c: "83,7",
    c_global: "1371597,257",
    d: 14,
    d_global: "355,6",
    ix: 334,
    ix_global: "139021296,2",
    iy: 124,
    iy_global: "51612696,77",
    iz: 478,
    iz_global: "198958621,4",
    t: "0,581",
    t_global: "14,7574",
    zy: "48,4",
    zy_global: "793133,8976",
    zz: "88,7",
    zz_global: "1453532,577",
  },
  {
    profile_section_id: 919,
    name: "HSS14X6X1/2",
    type: "HSS Rectangle",
    designation: "HSST14X6X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "17,2",
    ax_global: "11096,752",
    b: 6,
    b_global: "152,4",
    c: "69,3",
    c_global: "1135623,535",
    d: 14,
    d_global: "355,6",
    ix: 279,
    ix_global: "116128567,7",
    iy: 105,
    iy_global: "43704299,69",
    iz: 402,
    iz_global: "167325033,1",
    t: "0,465",
    t_global: "11,811",
    zy: "40,4",
    zy_global: "662037,3856",
    zz: "73,6",
    zz_global: "1206087,91",
  },
  {
    profile_section_id: 920,
    name: "HSS14X6X3/8",
    type: "HSS Rectangle",
    designation: "HSST14X6X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "13,2",
    ax_global: "8516,112",
    b: 6,
    b_global: "152,4",
    c: "53,7",
    c_global: "879985,3368",
    d: 14,
    d_global: "355,6",
    ix: 219,
    ix_global: "91154682,21",
    iy: "84,1",
    iy_global: "35005062,89",
    iz: 317,
    iz_global: "131945361,9",
    t: "0,349",
    t_global: "8,8646",
    zy: "31,6",
    zy_global: "517831,2224",
    zz: "57,3",
    zz_global: "938978,7672",
  },
  {
    profile_section_id: 921,
    name: "HSS14X6X5/16",
    type: "HSS Rectangle",
    designation: "HSST14X6X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "11,1",
    ax_global: "7161,276",
    b: 6,
    b_global: "152,4",
    c: "45,5",
    c_global: "745611,412",
    d: 14,
    d_global: "355,6",
    ix: 186,
    ix_global: "77419045,16",
    iy: "72,3",
    iy_global: "30093532,07",
    iz: 271,
    iz_global: "112798716,3",
    t: "0,291",
    t_global: "7,3914",
    zy: "26,9",
    zy_global: "440812,0216",
    zz: "48,6",
    zz_global: "796411,3104",
  },
  {
    profile_section_id: 922,
    name: "HSS14X6X1/4",
    type: "HSS Rectangle",
    designation: "HSST14X6X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "8,96",
    ax_global: "5780,6336",
    b: 6,
    b_global: "152,4",
    c: "36,9",
    c_global: "604682,6616",
    d: 14,
    d_global: "355,6",
    ix: 152,
    ix_global: "63267176,69",
    iy: "59,6",
    iy_global: "24807392,97",
    iz: 222,
    iz_global: "92403376,48",
    t: "0,233",
    t_global: "5,9182",
    zy: 22,
    zy_global: "360515,408",
    zz: "39,6",
    zz_global: "648927,7344",
  },
  {
    profile_section_id: 923,
    name: "HSS14X6X3/16",
    type: "HSS Rectangle",
    designation: "HSST14X6X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "6,76",
    ax_global: "4361,2816",
    b: 6,
    b_global: "152,4",
    c: 28,
    c_global: "458837,792",
    d: 14,
    d_global: "355,6",
    ix: 116,
    ix_global: "48282845,37",
    iy: "45,9",
    iy_global: "19105022,44",
    iz: 170,
    iz_global: "70759342,35",
    t: "0,174",
    t_global: "4,4196",
    zy: "16,7",
    zy_global: "273663,9688",
    zz: "30,1",
    zz_global: "493250,6264",
  },
  {
    profile_section_id: 924,
    name: "HSS14X4X5/8",
    type: "HSS Rectangle",
    designation: "HSST14X4X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "18,7",
    ax_global: "12064,492",
    b: 4,
    b_global: "101,6",
    c: "52,6",
    c_global: "861959,5664",
    d: 14,
    d_global: "355,6",
    ix: 148,
    ix_global: "61602250,99",
    iy: "47,2",
    iy_global: "19646123,29",
    iz: 373,
    iz_global: "155254321,7",
    t: "0,581",
    t_global: "14,7574",
    zy: "28,5",
    zy_global: "467031,324",
    zz: "73,1",
    zz_global: "1197894,378",
  },
  {
    profile_section_id: 925,
    name: "HSS14X4X1/2",
    type: "HSS Rectangle",
    designation: "HSST14X4X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "15,3",
    ax_global: "9870,948",
    b: 4,
    b_global: "101,6",
    c: "44,1",
    c_global: "722669,5224",
    d: 14,
    d_global: "355,6",
    ix: 127,
    ix_global: "52861391,05",
    iy: "41,2",
    iy_global: "17148734,73",
    iz: 317,
    iz_global: "131945361,9",
    t: "0,465",
    t_global: "11,811",
    zy: "24,1",
    zy_global: "394928,2424",
    zz: 61,
    zz_global: "999610,904",
  },
  {
    profile_section_id: 926,
    name: "HSS14X4X3/8",
    type: "HSS Rectangle",
    designation: "HSST14X4X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "11,8",
    ax_global: "7612,888",
    b: 4,
    b_global: "101,6",
    c: "34,6",
    c_global: "566992,4144",
    d: 14,
    d_global: "355,6",
    ix: 102,
    ix_global: "42455605,41",
    iy: "33,6",
    iy_global: "13985375,9",
    iz: 252,
    iz_global: "104890319,3",
    t: "0,349",
    t_global: "8,8646",
    zy: "19,1",
    zy_global: "312992,9224",
    zz: "47,8",
    zz_global: "783301,6592",
  },
  {
    profile_section_id: 927,
    name: "HSS14X4X5/16",
    type: "HSS Rectangle",
    designation: "HSST14X4X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "9,92",
    ax_global: "6399,9872",
    b: 4,
    b_global: "101,6",
    c: "29,5",
    c_global: "483418,388",
    d: 14,
    d_global: "355,6",
    ix: "87,7",
    ix_global: "36503496,03",
    iy: "29,2",
    iy_global: "12153957,63",
    iz: 216,
    iz_global: "89905987,93",
    t: "0,291",
    t_global: "7,3914",
    zy: "16,4",
    zy_global: "268747,8496",
    zz: "40,6",
    zz_global: "665314,7984",
  },
  {
    profile_section_id: 928,
    name: "HSS14X4X1/4",
    type: "HSS Rectangle",
    designation: "HSST14X4X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "8,03",
    ax_global: "5180,6348",
    b: 4,
    b_global: "101,6",
    c: "24,1",
    c_global: "394928,2424",
    d: 14,
    d_global: "355,6",
    ix: "72,4",
    ix_global: "30135155,21",
    iy: "24,4",
    iy_global: "10156046,78",
    iz: 178,
    iz_global: "74089193,76",
    t: "0,233",
    t_global: "5,9182",
    zy: "13,5",
    zy_global: "221225,364",
    zz: "33,2",
    zz_global: "544050,5248",
  },
  {
    profile_section_id: 929,
    name: "HSS14X4X3/16",
    type: "HSS Rectangle",
    designation: "HSST14X4X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "6,06",
    ax_global: "3909,6696",
    b: 4,
    b_global: "101,6",
    c: "18,4",
    c_global: "301521,9776",
    d: 14,
    d_global: "355,6",
    ix: "55,8",
    ix_global: "23225713,55",
    iy: 19,
    iy_global: "7908397,086",
    iz: 137,
    iz_global: "57023705,31",
    t: "0,174",
    t_global: "4,4196",
    zy: "10,3",
    zy_global: "168786,7592",
    zz: "25,3",
    zz_global: "414592,7192",
  },
  {
    profile_section_id: 930,
    name: "HSS12X12X5/8",
    type: "HSS Rectangle",
    designation: "HSST12X12X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "25,7",
    ax_global: "16580,612",
    b: 12,
    b_global: "304,8",
    c: 151,
    c_global: "2474446,664",
    d: 12,
    d_global: "304,8",
    ix: 885,
    ix_global: "368364811,7",
    iy: 548,
    iy_global: "228094821,2",
    iz: 548,
    iz_global: "228094821,2",
    t: "0,581",
    t_global: "14,7574",
    zy: 109,
    zy_global: "1786189,976",
    zz: 109,
    zz_global: "1786189,976",
  },
  {
    profile_section_id: 931,
    name: "HSS12X12X1/2",
    type: "HSS Rectangle",
    designation: "HSST12X12X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "20,9",
    ax_global: "13483,844",
    b: 12,
    b_global: "304,8",
    c: 123,
    c_global: "2015608,872",
    d: 12,
    d_global: "304,8",
    ix: 728,
    ix_global: "303016477,8",
    iy: 457,
    iy_global: "190217761,5",
    iz: 457,
    iz_global: "190217761,5",
    t: "0,465",
    t_global: "11,811",
    zy: "89,6",
    zy_global: "1468280,934",
    zz: "89,6",
    zz_global: "1468280,934",
  },
  {
    profile_section_id: 932,
    name: "HSS12X12X3/8",
    type: "HSS Rectangle",
    designation: "HSST12X12X0.375",
    shape: "RHS",
    country_code: "American",
    ax: 16,
    ax_global: "10322,56",
    b: 12,
    b_global: "304,8",
    c: "94,6",
    c_global: "1550216,254",
    d: 12,
    d_global: "304,8",
    ix: 561,
    ix_global: "233505829,8",
    iy: 357,
    iy_global: "148594618,9",
    iz: 357,
    iz_global: "148594618,9",
    t: "0,349",
    t_global: "8,8646",
    zy: "69,2",
    zy_global: "1133984,829",
    zz: "69,2",
    zz_global: "1133984,829",
  },
  {
    profile_section_id: 933,
    name: "HSS12X12X5/16",
    type: "HSS Rectangle",
    designation: "HSST12X12X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "13,4",
    ax_global: "8645,144",
    b: 12,
    b_global: "304,8",
    c: "79,7",
    c_global: "1306049,001",
    d: 12,
    d_global: "304,8",
    ix: 474,
    ix_global: "197293695,7",
    iy: 304,
    iy_global: "126534353,4",
    iz: 304,
    iz_global: "126534353,4",
    t: "0,291",
    t_global: "7,3914",
    zy: "58,6",
    zy_global: "960281,9504",
    zz: "58,6",
    zz_global: "960281,9504",
  },
  {
    profile_section_id: 934,
    name: "HSS12X12X1/4",
    type: "HSS Rectangle",
    designation: "HSST12X12X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "10,8",
    ax_global: "6967,728",
    b: 12,
    b_global: "304,8",
    c: "64,5",
    c_global: "1056965,628",
    d: 12,
    d_global: "304,8",
    ix: 384,
    ix_global: "159832867,4",
    iy: 248,
    iy_global: "103225393,5",
    iz: 248,
    iz_global: "103225393,5",
    t: "0,233",
    t_global: "5,9182",
    zy: "47,6",
    zy_global: "780024,2464",
    zz: "47,6",
    zz_global: "780024,2464",
  },
  {
    profile_section_id: 935,
    name: "HSS12X12X3/16",
    type: "HSS Rectangle",
    designation: "HSST12X12X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "8,15",
    ax_global: "5258,054",
    b: 12,
    b_global: "304,8",
    c: "48,6",
    c_global: "796411,3104",
    d: 12,
    d_global: "304,8",
    ix: 290,
    ix_global: "120707113,4",
    iy: 189,
    iy_global: "78667739,44",
    iz: 189,
    iz_global: "78667739,44",
    t: "0,174",
    t_global: "4,4196",
    zy: 36,
    zy_global: "589934,304",
    zz: 36,
    zz_global: "589934,304",
  },
  {
    profile_section_id: 936,
    name: "HSS12X10X1/2",
    type: "HSS Rectangle",
    designation: "HSST12X10X0.5",
    shape: "RHS",
    country_code: "American",
    ax: 19,
    ax_global: "12258,04",
    b: 10,
    b_global: 254,
    c: 102,
    c_global: "1671480,528",
    d: 12,
    d_global: "304,8",
    ix: 545,
    ix_global: 226846127,
    iy: 298,
    iy_global: "124036964,8",
    iz: 395,
    iz_global: "164411413,1",
    t: "0,465",
    t_global: "11,811",
    zy: "69,6",
    zy_global: "1140539,654",
    zz: "78,8",
    zz_global: "1291300,643",
  },
  {
    profile_section_id: 937,
    name: "HSS12X10X3/8",
    type: "HSS Rectangle",
    designation: "HSST12X10X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "14,6",
    ax_global: "9419,336",
    b: 10,
    b_global: 254,
    c: "78,3",
    c_global: "1283107,111",
    d: 12,
    d_global: "304,8",
    ix: 421,
    ix_global: "175233430,2",
    iy: 234,
    iy_global: "97398153,59",
    iz: 310,
    iz_global: "129031741,9",
    t: "0,349",
    t_global: "8,8646",
    zy: 54,
    zy_global: "884901,456",
    zz: "61,1",
    zz_global: "1001249,61",
  },
  {
    profile_section_id: 938,
    name: "HSS12X10X5/16",
    type: "HSS Rectangle",
    designation: "HSST12X10X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "12,2",
    ax_global: "7870,952",
    b: 10,
    b_global: 254,
    c: "66,1",
    c_global: "1083184,93",
    d: 12,
    d_global: "304,8",
    ix: 356,
    ix_global: "148178387,5",
    iy: 200,
    iy_global: "83246285,12",
    iz: 264,
    iz_global: "109885096,4",
    t: "0,291",
    t_global: "7,3914",
    zy: "45,7",
    zy_global: "748888,8248",
    zz: "51,7",
    zz_global: "847211,2088",
  },
  {
    profile_section_id: 939,
    name: "HSS12X10X1/4",
    type: "HSS Rectangle",
    designation: "HSST12X10X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "9,9",
    ax_global: "6387,084",
    b: 10,
    b_global: 254,
    c: "53,5",
    c_global: "876707,924",
    d: 12,
    d_global: "304,8",
    ix: 289,
    ix_global: 120290882,
    iy: 164,
    iy_global: "68261953,8",
    iz: 216,
    iz_global: "89905987,93",
    t: "0,233",
    t_global: "5,9182",
    zy: "37,2",
    zy_global: "609598,7808",
    zz: "42,1",
    zz_global: "689895,3944",
  },
  {
    profile_section_id: 940,
    name: "HSS12X8X5/8",
    type: "HSS Rectangle",
    designation: "HSST12X8X0.625",
    shape: "RHS",
    country_code: "American",
    ax: 21,
    ax_global: "13548,36",
    b: 8,
    b_global: "203,2",
    c: "97,7",
    c_global: "1601016,153",
    d: 12,
    d_global: "304,8",
    ix: 454,
    ix_global: "188969067,2",
    iy: 210,
    iy_global: "87408599,38",
    iz: 397,
    iz_global: 165243876,
    t: "0,581",
    t_global: "14,7574",
    zy: "61,9",
    zy_global: "1014359,262",
    zz: "82,1",
    zz_global: "1345377,954",
  },
  {
    profile_section_id: 941,
    name: "HSS12X8X1/2",
    type: "HSS Rectangle",
    designation: "HSST12X8X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "17,2",
    ax_global: "11096,752",
    b: 8,
    b_global: "203,2",
    c: "80,4",
    c_global: "1317519,946",
    d: 12,
    d_global: "304,8",
    ix: 377,
    ix_global: "156919247,5",
    iy: 178,
    iy_global: "74089193,76",
    iz: 333,
    iz_global: "138605064,7",
    t: "0,465",
    t_global: "11,811",
    zy: "51,5",
    zy_global: "843933,796",
    zz: "68,1",
    zz_global: "1115959,058",
  },
  {
    profile_section_id: 942,
    name: "HSS12X8X3/8",
    type: "HSS Rectangle",
    designation: "HSST12X8X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "13,2",
    ax_global: "8516,112",
    b: 8,
    b_global: "203,2",
    c: "62,1",
    c_global: "1017636,674",
    d: 12,
    d_global: "304,8",
    ix: 293,
    ix_global: "121955807,7",
    iy: 140,
    iy_global: "58272399,58",
    iz: 262,
    iz_global: "109052633,5",
    t: "0,349",
    t_global: "8,8646",
    zy: "40,1",
    zy_global: "657121,2664",
    zz: 53,
    zz_global: "868514,392",
  },
  {
    profile_section_id: 943,
    name: "HSS12X8X5/16",
    type: "HSS Rectangle",
    designation: "HSST12X8X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "11,1",
    ax_global: "7161,276",
    b: 8,
    b_global: "203,2",
    c: "52,4",
    c_global: "858682,1536",
    d: 12,
    d_global: "304,8",
    ix: 248,
    ix_global: "103225393,5",
    iy: 120,
    iy_global: "49947771,07",
    iz: 224,
    iz_global: "93235839,33",
    t: "0,291",
    t_global: "7,3914",
    zy: "34,1",
    zy_global: "558798,8824",
    zz: "44,9",
    zz_global: "735779,1736",
  },
  {
    profile_section_id: 944,
    name: "HSS12X8X1/4",
    type: "HSS Rectangle",
    designation: "HSST12X8X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "8,96",
    ax_global: "5780,6336",
    b: 8,
    b_global: "203,2",
    c: "42,5",
    c_global: "696450,22",
    d: 12,
    d_global: "304,8",
    ix: 202,
    ix_global: "84078747,97",
    iy: "98,8",
    iy_global: "41123664,85",
    iz: 184,
    iz_global: "76586582,31",
    t: "0,233",
    t_global: "5,9182",
    zy: "27,8",
    zy_global: "455560,3792",
    zz: "36,6",
    zz_global: "599766,5424",
  },
  {
    profile_section_id: 945,
    name: "HSS12X8X3/16",
    type: "HSS Rectangle",
    designation: "HSST12X8X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "6,76",
    ax_global: "4361,2816",
    b: 8,
    b_global: "203,2",
    c: "32,2",
    c_global: "527663,4608",
    d: 12,
    d_global: "304,8",
    ix: 153,
    ix_global: "63683408,12",
    iy: "75,7",
    iy_global: "31508718,92",
    iz: 140,
    iz_global: "58272399,58",
    t: "0,174",
    t_global: "4,4196",
    zy: "21,1",
    zy_global: "345767,0504",
    zz: "27,8",
    zz_global: "455560,3792",
  },
  {
    profile_section_id: 946,
    name: "HSS12X6X5/8",
    type: "HSS Rectangle",
    designation: "HSST12X6X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "18,7",
    ax_global: "12064,492",
    b: 6,
    b_global: "152,4",
    c: "71,1",
    c_global: "1165120,25",
    d: 12,
    d_global: "304,8",
    ix: 271,
    ix_global: "112798716,3",
    iy: 107,
    iy_global: "44536762,54",
    iz: 321,
    iz_global: "133610287,6",
    t: "0,581",
    t_global: "14,7574",
    zy: "42,1",
    zy_global: "689895,3944",
    zz: "68,8",
    zz_global: "1127430,003",
  },
  {
    profile_section_id: 947,
    name: "HSS12X6X1/2",
    type: "HSS Rectangle",
    designation: "HSST12X6X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "15,3",
    ax_global: "9870,948",
    b: 6,
    b_global: "152,4",
    c: 59,
    c_global: "966836,776",
    d: 12,
    d_global: "304,8",
    ix: 227,
    ix_global: "94484533,61",
    iy: "91,1",
    iy_global: "37918682,87",
    iz: 271,
    iz_global: "112798716,3",
    t: "0,465",
    t_global: "11,811",
    zy: "35,2",
    zy_global: "576824,6528",
    zz: "57,4",
    zz_global: "940617,4736",
  },
  {
    profile_section_id: 948,
    name: "HSS12X6X3/8",
    type: "HSS Rectangle",
    designation: "HSST12X6X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "11,8",
    ax_global: "7612,888",
    b: 6,
    b_global: "152,4",
    c: "45,8",
    c_global: "750527,5312",
    d: 12,
    d_global: "304,8",
    ix: 178,
    ix_global: "74089193,76",
    iy: "72,9",
    iy_global: "30343270,93",
    iz: 215,
    iz_global: "89489756,5",
    t: "0,349",
    t_global: "8,8646",
    zy: "27,7",
    zy_global: "453921,6728",
    zz: "44,8",
    zz_global: "734140,4672",
  },
  {
    profile_section_id: 949,
    name: "HSS12X6X5/16",
    type: "HSS Rectangle",
    designation: "HSST12X6X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "9,92",
    ax_global: "6399,9872",
    b: 6,
    b_global: "152,4",
    c: "38,8",
    c_global: "635818,0832",
    d: 12,
    d_global: "304,8",
    ix: 152,
    ix_global: "63267176,69",
    iy: "62,8",
    iy_global: "26139333,53",
    iz: 184,
    iz_global: "76586582,31",
    t: "0,291",
    t_global: "7,3914",
    zy: "23,6",
    zy_global: "386734,7104",
    zz: "38,1",
    zz_global: "624347,1384",
  },
  {
    profile_section_id: 950,
    name: "HSS12X6X1/4",
    type: "HSS Rectangle",
    designation: "HSST12X6X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "8,03",
    ax_global: "5180,6348",
    b: 6,
    b_global: "152,4",
    c: "31,6",
    c_global: "517831,2224",
    d: 12,
    d_global: "304,8",
    ix: 124,
    ix_global: "51612696,77",
    iy: "51,9",
    iy_global: "21602410,99",
    iz: 151,
    iz_global: "62850945,27",
    t: "0,233",
    t_global: "5,9182",
    zy: "19,3",
    zy_global: "316270,3352",
    zz: "31,1",
    zz_global: "509637,6904",
  },
  {
    profile_section_id: 951,
    name: "HSS12X6X3/16",
    type: "HSS Rectangle",
    designation: "HSST12X6X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "6,06",
    ax_global: "3909,6696",
    b: 6,
    b_global: "152,4",
    c: 24,
    c_global: "393289,536",
    d: 12,
    d_global: "304,8",
    ix: "94,6",
    ix_global: "39375492,86",
    iy: 40,
    iy_global: "16649257,02",
    iz: 116,
    iz_global: "48282845,37",
    t: "0,174",
    t_global: "4,4196",
    zy: "14,7",
    zy_global: "240889,8408",
    zz: "23,7",
    zz_global: "388373,4168",
  },
  {
    profile_section_id: 952,
    name: "HSS12X4X5/8",
    type: "HSS Rectangle",
    designation: "HSST12X4X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "16,4",
    ax_global: "10580,624",
    b: 4,
    b_global: "101,6",
    c: "44,6",
    c_global: "730863,0544",
    d: 12,
    d_global: "304,8",
    ix: 122,
    ix_global: "50780233,92",
    iy: "40,4",
    iy_global: "16815749,59",
    iz: 245,
    iz_global: "101976699,3",
    t: "0,581",
    t_global: "14,7574",
    zy: "24,5",
    zy_global: "401483,068",
    zz: "55,5",
    zz_global: "909482,052",
  },
  {
    profile_section_id: 953,
    name: "HSS12X4X1/2",
    type: "HSS Rectangle",
    designation: "HSST12X4X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "13,5",
    ax_global: "8709,66",
    b: 4,
    b_global: "101,6",
    c: "37,5",
    c_global: "614514,9",
    d: 12,
    d_global: "304,8",
    ix: 105,
    ix_global: "43704299,69",
    iy: "35,3",
    iy_global: "14692969,32",
    iz: 210,
    iz_global: "87408599,38",
    t: "0,465",
    t_global: "11,811",
    zy: "20,9",
    zy_global: "342489,6376",
    zz: "46,7",
    zz_global: "765275,8888",
  },
  {
    profile_section_id: 954,
    name: "HSS12X4X3/8",
    type: "HSS Rectangle",
    designation: "HSST12X4X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "10,4",
    ax_global: "6709,664",
    b: 4,
    b_global: "101,6",
    c: "29,5",
    c_global: "483418,388",
    d: 12,
    d_global: "304,8",
    ix: "84,1",
    ix_global: "35005062,89",
    iy: "28,9",
    iy_global: "12029088,2",
    iz: 168,
    iz_global: "69926879,5",
    t: "0,349",
    t_global: "8,8646",
    zy: "16,6",
    zy_global: "272025,2624",
    zz: "36,7",
    zz_global: "601405,2488",
  },
  {
    profile_section_id: 955,
    name: "HSS12X4X5/16",
    type: "HSS Rectangle",
    designation: "HSST12X4X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "8,76",
    ax_global: "5651,6016",
    b: 4,
    b_global: "101,6",
    c: "25,2",
    c_global: "412954,0128",
    d: 12,
    d_global: "304,8",
    ix: "72,4",
    ix_global: "30135155,21",
    iy: "25,2",
    iy_global: "10489031,93",
    iz: 144,
    iz_global: "59937325,29",
    t: "0,291",
    t_global: "7,3914",
    zy: "14,2",
    zy_global: "232696,3088",
    zz: "31,3",
    zz_global: "512915,1032",
  },
  {
    profile_section_id: 956,
    name: "HSS12X4X1/4",
    type: "HSS Rectangle",
    designation: "HSST12X4X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "7,1",
    ax_global: "4580,636",
    b: 4,
    b_global: "101,6",
    c: "20,6",
    c_global: "337573,5184",
    d: 12,
    d_global: "304,8",
    ix: "59,8",
    ix_global: "24890639,25",
    iy: 21,
    iy_global: "8740859,938",
    iz: 119,
    iz_global: "49531539,65",
    t: "0,233",
    t_global: "5,9182",
    zy: "11,7",
    zy_global: "191728,6488",
    zz: "25,6",
    zz_global: "419508,8384",
  },
  {
    profile_section_id: 957,
    name: "HSS12X4X3/16",
    type: "HSS Rectangle",
    designation: "HSST12X4X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "5,37",
    ax_global: "3464,5092",
    b: 4,
    b_global: "101,6",
    c: "15,7",
    c_global: "257276,9048",
    d: 12,
    d_global: "304,8",
    ix: "46,1",
    ix_global: "19188268,72",
    iy: "16,4",
    iy_global: "6826195,38",
    iz: "91,8",
    iz_global: "38210044,87",
    t: "0,174",
    t_global: "4,4196",
    zy: 9,
    zy_global: "147483,576",
    zz: "19,6",
    zz_global: "321186,4544",
  },
  {
    profile_section_id: 958,
    name: "HSS12X3-1/2X3/8",
    type: "HSS Rectangle",
    designation: "HSST12X3.5X0.375",
    shape: "RHS",
    country_code: "American",
    ax: 10,
    ax_global: "6451,6",
    b: "3,5",
    b_global: "88,9",
    c: "25,5",
    c_global: "417870,132",
    d: 12,
    d_global: "304,8",
    ix: "64,7",
    ix_global: "26930173,24",
    iy: "21,3",
    iy_global: "8865729,365",
    iz: 156,
    iz_global: "64932102,39",
    t: "0,349",
    t_global: "8,8646",
    zy: 14,
    zy_global: "229418,896",
    zz: "34,7",
    zz_global: "568631,1208",
  },
  {
    profile_section_id: 959,
    name: "HSS12X3-1/2X5/16",
    type: "HSS Rectangle",
    designation: "HSST12X3.5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "8,46",
    ax_global: "5458,0536",
    b: "3,5",
    b_global: "88,9",
    c: "21,8",
    c_global: "357237,9952",
    d: 12,
    d_global: "304,8",
    ix: 56,
    ix_global: "23308959,83",
    iy: "18,6",
    iy_global: "7741904,516",
    iz: 134,
    iz_global: "55775011,03",
    t: "0,291",
    t_global: "7,3914",
    zy: "12,1",
    zy_global: "198283,4744",
    zz: "29,6",
    zz_global: "485057,0944",
  },
  {
    profile_section_id: 960,
    name: "HSS12X3X5/16",
    type: "HSS Rectangle",
    designation: "HSST12X3X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "8,17",
    ax_global: "5270,9572",
    b: 3,
    b_global: "76,2",
    c: "18,4",
    c_global: "301521,9776",
    d: 12,
    d_global: "304,8",
    ix: "41,3",
    ix_global: "17190357,88",
    iy: "13,1",
    iy_global: "5452631,675",
    iz: 124,
    iz_global: "51612696,77",
    t: "0,291",
    t_global: "7,3914",
    zy: 10,
    zy_global: "163870,64",
    zz: "27,9",
    zz_global: "457199,0856",
  },
  {
    profile_section_id: 961,
    name: "HSS12X3X1/4",
    type: "HSS Rectangle",
    designation: "HSST12X3X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "6,63",
    ax_global: "4277,4108",
    b: 3,
    b_global: "76,2",
    c: "15,1",
    c_global: "247444,6664",
    d: 12,
    d_global: "304,8",
    ix: "34,5",
    ix_global: "14359984,18",
    iy: "11,1",
    iy_global: "4620168,824",
    iz: 103,
    iz_global: "42871836,84",
    t: "0,233",
    t_global: "5,9182",
    zy: "8,28",
    zy_global: "135684,8899",
    zz: "22,9",
    zz_global: "375263,7656",
  },
  {
    profile_section_id: 962,
    name: "HSS12X3X3/16",
    type: "HSS Rectangle",
    designation: "HSST12X3X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "5,02",
    ax_global: "3238,7032",
    b: 3,
    b_global: "76,2",
    c: "11,6",
    c_global: "190089,9424",
    d: 12,
    d_global: "304,8",
    ix: "26,8",
    ix_global: "11155002,21",
    iy: "8,72",
    iy_global: "3629538,031",
    iz: "79,6",
    iz_global: "33132021,48",
    t: "0,174",
    t_global: "4,4196",
    zy: "6,4",
    zy_global: "104877,2096",
    zz: "17,5",
    zz_global: "286773,62",
  },
  {
    profile_section_id: 963,
    name: "HSS12X2X5/16",
    type: "HSS Rectangle",
    designation: "HSST12X2X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "7,59",
    ax_global: "4896,7644",
    b: 2,
    b_global: "50,8",
    c: "11,6",
    c_global: "190089,9424",
    d: 12,
    d_global: "304,8",
    ix: "17,6",
    ix_global: "7325673,091",
    iy: "5,1",
    iy_global: "2122780,271",
    iz: 104,
    iz_global: "43288068,26",
    t: "0,291",
    t_global: "7,3914",
    zy: "6,05",
    zy_global: "99141,7372",
    zz: "24,5",
    zz_global: "401483,068",
  },
  {
    profile_section_id: 964,
    name: "HSS12X2X1/4",
    type: "HSS Rectangle",
    designation: "HSST12X2X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "6,17",
    ax_global: "3980,6372",
    b: 2,
    b_global: "50,8",
    c: "9,64",
    c_global: "157971,297",
    d: 12,
    d_global: "304,8",
    ix: "15,1",
    ix_global: "6285094,527",
    iy: "4,41",
    iy_global: "1835580,587",
    iz: "86,9",
    iz_global: "36170510,88",
    t: "0,233",
    t_global: "5,9182",
    zy: "5,08",
    zy_global: "83246,28512",
    zz: "20,1",
    zz_global: "329379,9864",
  },
  {
    profile_section_id: 965,
    name: "HSS12X2X3/16",
    type: "HSS Rectangle",
    designation: "HSST12X2X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "4,67",
    ax_global: "3012,8972",
    b: 2,
    b_global: "50,8",
    c: "7,49",
    c_global: "122739,1094",
    d: 12,
    d_global: "304,8",
    ix: 12,
    ix_global: "4994777,107",
    iy: "3,55",
    iy_global: "1477621,561",
    iz: "67,4",
    iz_global: "28053998,09",
    t: "0,174",
    t_global: "4,4196",
    zy: "3,97",
    zy_global: "65056,64408",
    zz: "15,5",
    zz_global: "253999,492",
  },
  {
    profile_section_id: 966,
    name: "HSS10X10X5/8",
    type: "HSS Rectangle",
    designation: "HSST10X10X0.625",
    shape: "RHS",
    country_code: "American",
    ax: 21,
    ax_global: "13548,36",
    b: 10,
    b_global: 254,
    c: 102,
    c_global: "1671480,528",
    d: 10,
    d_global: 254,
    ix: 498,
    ix_global: "207283249,9",
    iy: 304,
    iy_global: "126534353,4",
    iz: 304,
    iz_global: "126534353,4",
    t: "0,581",
    t_global: "14,7574",
    zy: "73,2",
    zy_global: "1199533,085",
    zz: "73,2",
    zz_global: "1199533,085",
  },
  {
    profile_section_id: 967,
    name: "HSS10X10X1/2",
    type: "HSS Rectangle",
    designation: "HSST10X10X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "17,2",
    ax_global: "11096,752",
    b: 10,
    b_global: 254,
    c: "84,2",
    c_global: "1379790,789",
    d: 10,
    d_global: 254,
    ix: 412,
    ix_global: "171487347,3",
    iy: 256,
    iy_global: 106555245,
    iz: 256,
    iz_global: 106555245,
    t: "0,465",
    t_global: "11,811",
    zy: "60,7",
    zy_global: "994694,7848",
    zz: "60,7",
    zz_global: "994694,7848",
  },
  {
    profile_section_id: 968,
    name: "HSS10X10X3/8",
    type: "HSS Rectangle",
    designation: "HSST10X10X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "13,2",
    ax_global: "8516,112",
    b: 10,
    b_global: 254,
    c: "64,8",
    c_global: "1061881,747",
    d: 10,
    d_global: 254,
    ix: 320,
    ix_global: "133194056,2",
    iy: 202,
    iy_global: "84078747,97",
    iz: 202,
    iz_global: "84078747,97",
    t: "0,349",
    t_global: "8,8646",
    zy: "47,2",
    zy_global: "773469,4208",
    zz: "47,2",
    zz_global: "773469,4208",
  },
  {
    profile_section_id: 969,
    name: "HSS10X10X5/16",
    type: "HSS Rectangle",
    designation: "HSST10X10X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "11,1",
    ax_global: "7161,276",
    b: 10,
    b_global: 254,
    c: "54,8",
    c_global: "898011,1072",
    d: 10,
    d_global: 254,
    ix: 271,
    ix_global: "112798716,3",
    iy: 172,
    iy_global: "71591805,2",
    iz: 172,
    iz_global: "71591805,2",
    t: "0,291",
    t_global: "7,3914",
    zy: "40,1",
    zy_global: "657121,2664",
    zz: "40,1",
    zz_global: "657121,2664",
  },
  {
    profile_section_id: 970,
    name: "HSS10X10X1/4",
    type: "HSS Rectangle",
    designation: "HSST10X10X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "8,96",
    ax_global: "5780,6336",
    b: 10,
    b_global: 254,
    c: "44,4",
    c_global: "727585,6416",
    d: 10,
    d_global: 254,
    ix: 220,
    ix_global: "91570913,63",
    iy: 141,
    iy_global: "58688631,01",
    iz: 141,
    iz_global: "58688631,01",
    t: "0,233",
    t_global: "5,9182",
    zy: "32,7",
    zy_global: "535856,9928",
    zz: "32,7",
    zz_global: "535856,9928",
  },
  {
    profile_section_id: 971,
    name: "HSS10X10X3/16",
    type: "HSS Rectangle",
    designation: "HSST10X10X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "6,76",
    ax_global: "4361,2816",
    b: 10,
    b_global: 254,
    c: "33,6",
    c_global: "550605,3504",
    d: 10,
    d_global: 254,
    ix: 167,
    ix_global: "69510648,08",
    iy: 108,
    iy_global: "44952993,96",
    iz: 108,
    iz_global: "44952993,96",
    t: "0,174",
    t_global: "4,4196",
    zy: "24,8",
    zy_global: "406399,1872",
    zz: "24,8",
    zz_global: "406399,1872",
  },
  {
    profile_section_id: 972,
    name: "HSS10X8X5/8",
    type: "HSS Rectangle",
    designation: "HSST10X8X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "18,7",
    ax_global: "12064,492",
    b: 8,
    b_global: "203,2",
    c: "80,4",
    c_global: "1317519,946",
    d: 10,
    d_global: 254,
    ix: 346,
    ix_global: "144016073,3",
    iy: 178,
    iy_global: "74089193,76",
    iz: 253,
    iz_global: "105306550,7",
    t: "0,581",
    t_global: "14,7574",
    zy: "53,3",
    zy_global: "873430,5112",
    zz: "62,2",
    zz_global: "1019275,381",
  },
  {
    profile_section_id: 973,
    name: "HSS10X8X1/2",
    type: "HSS Rectangle",
    designation: "HSST10X8X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "15,3",
    ax_global: "9870,948",
    b: 8,
    b_global: "203,2",
    c: "66,4",
    c_global: "1088101,05",
    d: 10,
    d_global: 254,
    ix: 288,
    ix_global: "119874650,6",
    iy: 151,
    iy_global: "62850945,27",
    iz: 214,
    iz_global: "89073525,08",
    t: "0,465",
    t_global: "11,811",
    zy: "44,5",
    zy_global: "729224,348",
    zz: "51,9",
    zz_global: "850488,6216",
  },
  {
    profile_section_id: 974,
    name: "HSS10X8X3/8",
    type: "HSS Rectangle",
    designation: "HSST10X8X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "11,8",
    ax_global: "7612,888",
    b: 8,
    b_global: "203,2",
    c: "51,4",
    c_global: "842295,0896",
    d: 10,
    d_global: 254,
    ix: 224,
    ix_global: "93235839,33",
    iy: 120,
    iy_global: "49947771,07",
    iz: 169,
    iz_global: "70343110,93",
    t: "0,349",
    t_global: "8,8646",
    zy: "34,8",
    zy_global: "570269,8272",
    zz: "40,5",
    zz_global: "663676,092",
  },
  {
    profile_section_id: 975,
    name: "HSS10X8X5/16",
    type: "HSS Rectangle",
    designation: "HSST10X8X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "9,92",
    ax_global: "6399,9872",
    b: 8,
    b_global: "203,2",
    c: "43,5",
    c_global: "712837,284",
    d: 10,
    d_global: 254,
    ix: 190,
    ix_global: "79083970,86",
    iy: 103,
    iy_global: "42871836,84",
    iz: 145,
    iz_global: "60353556,71",
    t: "0,291",
    t_global: "7,3914",
    zy: "29,6",
    zy_global: "485057,0944",
    zz: "34,4",
    zz_global: "563715,0016",
  },
  {
    profile_section_id: 976,
    name: "HSS10X8X1/4",
    type: "HSS Rectangle",
    designation: "HSST10X8X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "8,03",
    ax_global: "5180,6348",
    b: 8,
    b_global: "203,2",
    c: "35,3",
    c_global: "578463,3592",
    d: 10,
    d_global: 254,
    ix: 155,
    ix_global: "64515870,97",
    iy: "84,7",
    iy_global: "35254801,75",
    iz: 119,
    iz_global: "49531539,65",
    t: "0,233",
    t_global: "5,9182",
    zy: "24,2",
    zy_global: "396566,9488",
    zz: "28,1",
    zz_global: "460476,4984",
  },
  {
    profile_section_id: 977,
    name: "HSS10X8X3/16",
    type: "HSS Rectangle",
    designation: "HSST10X8X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "6,06",
    ax_global: "3909,6696",
    b: 8,
    b_global: "203,2",
    c: "26,7",
    c_global: "437534,6088",
    d: 10,
    d_global: 254,
    ix: 118,
    ix_global: "49115308,22",
    iy: "65,1",
    iy_global: "27096665,81",
    iz: "91,4",
    iz_global: "38043552,3",
    t: "0,174",
    t_global: "4,4196",
    zy: "18,4",
    zy_global: "301521,9776",
    zz: "21,4",
    zz_global: "350683,1696",
  },
  {
    profile_section_id: 978,
    name: "HSS10X6X5/8",
    type: "HSS Rectangle",
    designation: "HSST10X6X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "16,4",
    ax_global: "10580,624",
    b: 6,
    b_global: "152,4",
    c: "58,6",
    c_global: "960281,9504",
    d: 10,
    d_global: 254,
    ix: 209,
    ix_global: "86992367,95",
    iy: "89,4",
    iy_global: "37211089,45",
    iz: 201,
    iz_global: "83662516,55",
    t: "0,581",
    t_global: "14,7574",
    zy: "35,8",
    zy_global: "586656,8912",
    zz: "51,3",
    zz_global: "840656,3832",
  },
  {
    profile_section_id: 979,
    name: "HSS10X6X1/2",
    type: "HSS Rectangle",
    designation: "HSST10X6X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "13,5",
    ax_global: "8709,66",
    b: 6,
    b_global: "152,4",
    c: "48,7",
    c_global: "798050,0168",
    d: 10,
    d_global: 254,
    ix: 176,
    ix_global: "73256730,91",
    iy: "76,8",
    iy_global: "31966573,49",
    iz: 171,
    iz_global: "71175573,78",
    t: "0,465",
    t_global: "11,811",
    zy: "30,1",
    zy_global: "493250,6264",
    zz: 43,
    zz_global: "704643,752",
  },
  {
    profile_section_id: 980,
    name: "HSS10X6X3/8",
    type: "HSS Rectangle",
    designation: "HSST10X6X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "10,4",
    ax_global: "6709,664",
    b: 6,
    b_global: "152,4",
    c: "37,9",
    c_global: "621069,7256",
    d: 10,
    d_global: 254,
    ix: 139,
    ix_global: "57856168,16",
    iy: "61,8",
    iy_global: "25723102,1",
    iz: 137,
    iz_global: "57023705,31",
    t: "0,349",
    t_global: "8,8646",
    zy: "23,7",
    zy_global: "388373,4168",
    zz: "33,8",
    zz_global: "553882,7632",
  },
  {
    profile_section_id: 981,
    name: "HSS10X6X5/16",
    type: "HSS Rectangle",
    designation: "HSST10X6X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "8,76",
    ax_global: "5651,6016",
    b: 6,
    b_global: "152,4",
    c: "32,2",
    c_global: "527663,4608",
    d: 10,
    d_global: 254,
    ix: 118,
    ix_global: "49115308,22",
    iy: "53,3",
    iy_global: "22185134,98",
    iz: 118,
    iz_global: "49115308,22",
    t: "0,291",
    t_global: "7,3914",
    zy: "20,2",
    zy_global: "331018,6928",
    zz: "28,8",
    zz_global: "471947,4432",
  },
  {
    profile_section_id: 982,
    name: "HSS10X6X1/4",
    type: "HSS Rectangle",
    designation: "HSST10X6X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "7,1",
    ax_global: "4580,636",
    b: 6,
    b_global: "152,4",
    c: "26,2",
    c_global: "429341,0768",
    d: 10,
    d_global: 254,
    ix: "96,7",
    ix_global: "40249578,86",
    iy: "44,1",
    iy_global: "18355805,87",
    iz: "96,9",
    iz_global: "40332825,14",
    t: "0,233",
    t_global: "5,9182",
    zy: "16,6",
    zy_global: "272025,2624",
    zz: "23,6",
    zz_global: "386734,7104",
  },
  {
    profile_section_id: 983,
    name: "HSS10X6X3/16",
    type: "HSS Rectangle",
    designation: "HSST10X6X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "5,37",
    ax_global: "3464,5092",
    b: 6,
    b_global: "152,4",
    c: "19,9",
    c_global: "326102,5736",
    d: 10,
    d_global: 254,
    ix: "73,8",
    ix_global: "30717879,21",
    iy: "34,1",
    iy_global: "14193491,61",
    iz: "74,6",
    iz_global: "31050864,35",
    t: "0,174",
    t_global: "4,4196",
    zy: "12,7",
    zy_global: "208115,7128",
    zz: 18,
    zz_global: "294967,152",
  },
  {
    profile_section_id: 984,
    name: "HSS10X5X3/8",
    type: "HSS Rectangle",
    designation: "HSST10X5X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "9,67",
    ax_global: "6238,6972",
    b: 5,
    b_global: 127,
    c: "31,2",
    c_global: "511276,3968",
    d: 10,
    d_global: 254,
    ix: 100,
    ix_global: "41623142,56",
    iy: "40,6",
    iy_global: "16898995,88",
    iz: 120,
    iz_global: "49947771,07",
    t: "0,349",
    t_global: "8,8646",
    zy: "18,7",
    zy_global: "306438,0968",
    zz: "30,4",
    zz_global: "498166,7456",
  },
  {
    profile_section_id: 985,
    name: "HSS10X5X5/16",
    type: "HSS Rectangle",
    designation: "HSST10X5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "8,17",
    ax_global: "5270,9572",
    b: 5,
    b_global: 127,
    c: "26,5",
    c_global: "434257,196",
    d: 10,
    d_global: 254,
    ix: 86,
    ix_global: "35795902,6",
    iy: "35,2",
    iy_global: "14651346,18",
    iz: 104,
    iz_global: "43288068,26",
    t: "0,291",
    t_global: "7,3914",
    zy: 16,
    zy_global: "262193,024",
    zz: 26,
    zz_global: "426063,664",
  },
  {
    profile_section_id: 986,
    name: "HSS10X5X1/4",
    type: "HSS Rectangle",
    designation: "HSST10X5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "6,63",
    ax_global: "4277,4108",
    b: 5,
    b_global: 127,
    c: "21,6",
    c_global: "353960,5824",
    d: 10,
    d_global: 254,
    ix: "70,7",
    ix_global: "29427561,79",
    iy: "29,3",
    iy_global: "12195580,77",
    iz: "85,8",
    iz_global: "35712656,32",
    t: "0,233",
    t_global: "5,9182",
    zy: "13,2",
    zy_global: "216309,2448",
    zz: "21,3",
    zz_global: "349044,4632",
  },
  {
    profile_section_id: 987,
    name: "HSS10X5X3/16",
    type: "HSS Rectangle",
    designation: "HSST10X5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "5,02",
    ax_global: "3238,7032",
    b: 5,
    b_global: 127,
    c: "16,5",
    c_global: "270386,556",
    d: 10,
    d_global: 254,
    ix: "54,1",
    ix_global: "22518120,12",
    iy: "22,7",
    iy_global: "9448453,361",
    iz: "66,2",
    iz_global: "27554520,37",
    t: "0,174",
    t_global: "4,4196",
    zy: "10,1",
    zy_global: "165509,3464",
    zz: "16,3",
    zz_global: "267109,1432",
  },
  {
    profile_section_id: 988,
    name: "HSS10X4X5/8",
    type: "HSS Rectangle",
    designation: "HSST10X4X0.625",
    shape: "RHS",
    country_code: "American",
    ax: 14,
    ax_global: "9032,24",
    b: 4,
    b_global: "101,6",
    c: "36,7",
    c_global: "601405,2488",
    d: 10,
    d_global: 254,
    ix: "95,7",
    ix_global: "39833347,43",
    iy: "33,5",
    iy_global: "13943752,76",
    iz: 149,
    iz_global: "62018482,41",
    t: "0,581",
    t_global: "14,7574",
    zy: "20,6",
    zy_global: "337573,5184",
    zz: "40,3",
    zz_global: "660398,6792",
  },
  {
    profile_section_id: 989,
    name: "HSS10X4X1/2",
    type: "HSS Rectangle",
    designation: "HSST10X4X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "11,6",
    ax_global: "7483,856",
    b: 4,
    b_global: "101,6",
    c: 31,
    c_global: "507998,984",
    d: 10,
    d_global: 254,
    ix: "82,6",
    ix_global: "34380715,75",
    iy: "29,5",
    iy_global: "12278827,06",
    iz: 129,
    iz_global: "53693853,9",
    t: "0,465",
    t_global: "11,811",
    zy: "17,6",
    zy_global: "288412,3264",
    zz: "34,1",
    zz_global: "558798,8824",
  },
  {
    profile_section_id: 990,
    name: "HSS10X4X3/8",
    type: "HSS Rectangle",
    designation: "HSST10X4X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "8,97",
    ax_global: "5787,0852",
    b: 4,
    b_global: "101,6",
    c: "24,4",
    c_global: "399844,3616",
    d: 10,
    d_global: 254,
    ix: "66,5",
    ix_global: "27679389,8",
    iy: "24,3",
    iy_global: "10114423,64",
    iz: 104,
    iz_global: "43288068,26",
    t: "0,349",
    t_global: "8,8646",
    zy: 14,
    zy_global: "229418,896",
    zz: 27,
    zz_global: "442450,728",
  },
  {
    profile_section_id: 991,
    name: "HSS10X4X5/16",
    type: "HSS Rectangle",
    designation: "HSST10X4X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "7,59",
    ax_global: "4896,7644",
    b: 4,
    b_global: "101,6",
    c: "20,9",
    c_global: "342489,6376",
    d: 10,
    d_global: 254,
    ix: "57,3",
    ix_global: "23850060,69",
    iy: "21,2",
    iy_global: "8824106,223",
    iz: "90,1",
    iz_global: "37502451,45",
    t: "0,291",
    t_global: "7,3914",
    zy: "12,1",
    zy_global: "198283,4744",
    zz: "23,1",
    zz_global: "378541,1784",
  },
  {
    profile_section_id: 992,
    name: "HSS10X4X1/4",
    type: "HSS Rectangle",
    designation: "HSST10X4X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "6,17",
    ax_global: "3980,6372",
    b: 4,
    b_global: "101,6",
    c: "17,1",
    c_global: "280218,7944",
    d: 10,
    d_global: 254,
    ix: "47,4",
    ix_global: "19729369,57",
    iy: "17,7",
    iy_global: "7367296,233",
    iz: "74,7",
    iz_global: "31092487,49",
    t: "0,233",
    t_global: "5,9182",
    zy: 10,
    zy_global: "163870,64",
    zz: 19,
    zz_global: "311354,216",
  },
  {
    profile_section_id: 993,
    name: "HSS10X4X3/16",
    type: "HSS Rectangle",
    designation: "HSST10X4X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "4,67",
    ax_global: "3012,8972",
    b: 4,
    b_global: "101,6",
    c: "13,1",
    c_global: "214670,5384",
    d: 10,
    d_global: 254,
    ix: "36,5",
    ix_global: "15192447,03",
    iy: "13,9",
    iy_global: "5785616,816",
    iz: "57,8",
    iz_global: "24058176,4",
    t: "0,174",
    t_global: "4,4196",
    zy: "7,66",
    zy_global: "125524,9102",
    zz: "14,6",
    zz_global: "239251,1344",
  },
  {
    profile_section_id: 994,
    name: "HSS10X4X1/8",
    type: "HSS Rectangle",
    designation: "HSST10X4X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "3,16",
    ax_global: "2038,7056",
    b: 4,
    b_global: "101,6",
    c: "8,9",
    c_global: "145844,8696",
    d: 10,
    d_global: 254,
    ix: "25,1",
    ix_global: "10447408,78",
    iy: "9,65",
    iy_global: "4016633,257",
    iz: "39,8",
    iz_global: "16566010,74",
    t: "0,116",
    t_global: "2,9464",
    zy: "5,26",
    zy_global: "86195,95664",
    zz: 10,
    zz_global: "163870,64",
  },
  {
    profile_section_id: 995,
    name: "HSS10X3-1/2X1/2",
    type: "HSS Rectangle",
    designation: "HSST10X3.5X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "11,1",
    ax_global: "7161,276",
    b: "3,5",
    b_global: "88,9",
    c: "26,5",
    c_global: "434257,196",
    d: 10,
    d_global: 254,
    ix: "63,2",
    ix_global: "26305826,1",
    iy: "21,4",
    iy_global: "8907352,508",
    iz: 118,
    iz_global: "49115308,22",
    t: "0,465",
    t_global: "11,811",
    zy: "14,7",
    zy_global: "240889,8408",
    zz: "31,9",
    zz_global: "522747,3416",
  },
  {
    profile_section_id: 996,
    name: "HSS10X3-1/2X3/8",
    type: "HSS Rectangle",
    designation: "HSST10X3.5X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "8,62",
    ax_global: "5561,2792",
    b: "3,5",
    b_global: "88,9",
    c: "21,1",
    c_global: "345767,0504",
    d: 10,
    d_global: 254,
    ix: "51,5",
    ix_global: "21435918,42",
    iy: "17,8",
    iy_global: "7408919,376",
    iz: "96,1",
    iz_global: 39999840,
    t: "0,349",
    t_global: "8,8646",
    zy: "11,8",
    zy_global: "193367,3552",
    zz: "25,3",
    zz_global: "414592,7192",
  },
  {
    profile_section_id: 997,
    name: "HSS10X3-1/2X5/16",
    type: "HSS Rectangle",
    designation: "HSST10X3.5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "7,3",
    ax_global: "4709,668",
    b: "3,5",
    b_global: "88,9",
    c: 18,
    c_global: "294967,152",
    d: 10,
    d_global: 254,
    ix: "44,6",
    ix_global: "18563921,58",
    iy: "15,6",
    iy_global: "6493210,239",
    iz: "83,2",
    iz_global: "34630454,61",
    t: "0,291",
    t_global: "7,3914",
    zy: "10,2",
    zy_global: "167148,0528",
    zz: "21,7",
    zz_global: "355599,2888",
  },
  {
    profile_section_id: 998,
    name: "HSS10X3-1/2X1/4",
    type: "HSS Rectangle",
    designation: "HSST10X3.5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "5,93",
    ax_global: "3825,7988",
    b: "3,5",
    b_global: "88,9",
    c: "14,8",
    c_global: "242528,5472",
    d: 10,
    d_global: 254,
    ix: 37,
    ix_global: "15400562,75",
    iy: "13,1",
    iy_global: "5452631,675",
    iz: "69,1",
    iz_global: "28761591,51",
    t: "0,233",
    t_global: "5,9182",
    zy: "8,45",
    zy_global: "138470,6908",
    zz: "17,9",
    zz_global: "293328,4456",
  },
  {
    profile_section_id: 999,
    name: "HSS10X3-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSST10X3.5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "4,5",
    ax_global: "2903,22",
    b: "3,5",
    b_global: "88,9",
    c: "11,4",
    c_global: "186812,5296",
    d: 10,
    d_global: 254,
    ix: "28,6",
    ix_global: "11904218,77",
    iy: "10,3",
    iy_global: "4287183,684",
    iz: "53,6",
    iz_global: "22310004,41",
    t: "0,174",
    t_global: "4,4196",
    zy: "6,52",
    zy_global: "106843,6573",
    zz: "13,7",
    zz_global: "224502,7768",
  },
  {
    profile_section_id: 1000,
    name: "HSS10X3-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSST10X3.5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "3,04",
    ax_global: "1961,2864",
    b: "3,5",
    b_global: "88,9",
    c: "7,75",
    c_global: "126999,746",
    d: 10,
    d_global: 254,
    ix: "19,8",
    ix_global: "8241382,227",
    iy: "7,22",
    iy_global: "3005190,893",
    iz: 37,
    iz_global: "15400562,75",
    t: "0,116",
    t_global: "2,9464",
    zy: "4,48",
    zy_global: "73414,04672",
    zz: "9,37",
    zz_global: "153546,7897",
  },
  {
    profile_section_id: 1001,
    name: "HSS10X3X3/8",
    type: "HSS Rectangle",
    designation: "HSST10X3X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "8,27",
    ax_global: "5335,4732",
    b: 3,
    b_global: "76,2",
    c: "17,7",
    c_global: "290051,0328",
    d: 10,
    d_global: 254,
    ix: "37,8",
    ix_global: "15733547,89",
    iy: "12,4",
    iy_global: "5161269,677",
    iz: 88,
    iz_global: "36628365,45",
    t: "0,349",
    t_global: "8,8646",
    zy: "9,73",
    zy_global: "159446,1327",
    zz: "23,7",
    zz_global: "388373,4168",
  },
  {
    profile_section_id: 1002,
    name: "HSS10X3X5/16",
    type: "HSS Rectangle",
    designation: "HSST10X3X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "7,01",
    ax_global: "4522,5716",
    b: 3,
    b_global: "76,2",
    c: "15,2",
    c_global: "249083,3728",
    d: 10,
    d_global: 254,
    ix: 33,
    ix_global: "13735637,04",
    iy: 11,
    iy_global: "4578545,682",
    iz: "76,3",
    iz_global: "31758457,77",
    t: "0,291",
    t_global: "7,3914",
    zy: "8,42",
    zy_global: "137979,0789",
    zz: "20,3",
    zz_global: "332657,3992",
  },
  {
    profile_section_id: 1003,
    name: "HSS10X3X1/4",
    type: "HSS Rectangle",
    designation: "HSST10X3X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "5,7",
    ax_global: "3677,412",
    b: 3,
    b_global: "76,2",
    c: "12,5",
    c_global: "204838,3",
    d: 10,
    d_global: 254,
    ix: "27,6",
    ix_global: "11487987,35",
    iy: "9,28",
    iy_global: "3862627,63",
    iz: "63,6",
    iz_global: "26472318,67",
    t: "0,233",
    t_global: "5,9182",
    zy: "6,99",
    zy_global: "114545,5774",
    zz: "16,7",
    zz_global: "273663,9688",
  },
  {
    profile_section_id: 1004,
    name: "HSS10X3X3/16",
    type: "HSS Rectangle",
    designation: "HSST10X3X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "4,32",
    ax_global: "2787,0912",
    b: 3,
    b_global: "76,2",
    c: "9,64",
    c_global: "157971,297",
    d: 10,
    d_global: 254,
    ix: "21,5",
    ix_global: "8948975,65",
    iy: "7,33",
    iy_global: "3050976,35",
    iz: "49,4",
    iz_global: "20561832,42",
    t: "0,174",
    t_global: "4,4196",
    zy: "5,41",
    zy_global: "88654,01624",
    zz: "12,8",
    zz_global: "209754,4192",
  },
  {
    profile_section_id: 1005,
    name: "HSS10X3X1/8",
    type: "HSS Rectangle",
    designation: "HSST10X3X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,93",
    ax_global: "1890,3188",
    b: 3,
    b_global: "76,2",
    c: "6,61",
    c_global: "108318,493",
    d: 10,
    d_global: 254,
    ix: "14,9",
    ix_global: "6201848,241",
    iy: "5,16",
    iy_global: "2147754,156",
    iz: "34,2",
    iz_global: "14235114,76",
    t: "0,116",
    t_global: "2,9464",
    zy: "3,74",
    zy_global: "61287,61936",
    zz: "8,8",
    zz_global: "144206,1632",
  },
  {
    profile_section_id: 1006,
    name: "HSS10X2X3/8",
    type: "HSS Rectangle",
    designation: "HSST10X2X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "7,58",
    ax_global: "4890,3128",
    b: 2,
    b_global: "50,8",
    c: 11,
    c_global: "180257,704",
    d: 10,
    d_global: 254,
    ix: "15,9",
    ix_global: "6618079,667",
    iy: "4,7",
    iy_global: "1956287,7",
    iz: "71,7",
    iz_global: "29843793,22",
    t: "0,349",
    t_global: "8,8646",
    zy: "5,76",
    zy_global: "94389,48864",
    zz: "20,3",
    zz_global: "332657,3992",
  },
  {
    profile_section_id: 1007,
    name: "HSS10X2X5/16",
    type: "HSS Rectangle",
    designation: "HSST10X2X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "6,43",
    ax_global: "4148,3788",
    b: 2,
    b_global: "50,8",
    c: "9,56",
    c_global: "156660,3318",
    d: 10,
    d_global: 254,
    ix: "14,2",
    ix_global: "5910486,244",
    iy: "4,24",
    iy_global: "1764821,245",
    iz: "62,6",
    iz_global: "26056087,24",
    t: "0,291",
    t_global: "7,3914",
    zy: "5,06",
    zy_global: "82918,54384",
    zz: "17,5",
    zz_global: "286773,62",
  },
  {
    profile_section_id: 1008,
    name: "HSS10X2X1/4",
    type: "HSS Rectangle",
    designation: "HSST10X2X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "5,24",
    ax_global: "3380,6384",
    b: 2,
    b_global: "50,8",
    c: "7,99",
    c_global: "130932,6414",
    d: 10,
    d_global: 254,
    ix: "12,2",
    ix_global: "5078023,392",
    iy: "3,67",
    iy_global: "1527569,332",
    iz: "52,5",
    iz_global: "21852149,84",
    t: "0,233",
    t_global: "5,9182",
    zy: "4,26",
    zy_global: "69808,89264",
    zz: "14,4",
    zz_global: "235973,7216",
  },
  {
    profile_section_id: 1009,
    name: "HSS10X2X3/16",
    type: "HSS Rectangle",
    designation: "HSST10X2X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,98",
    ax_global: "2567,7368",
    b: 2,
    b_global: "50,8",
    c: "6,22",
    c_global: "101927,5381",
    d: 10,
    d_global: 254,
    ix: "9,74",
    ix_global: "4054094,085",
    iy: "2,97",
    iy_global: "1236207,334",
    iz: 41,
    iz_global: "17065488,45",
    t: "0,174",
    t_global: "4,4196",
    zy: "3,34",
    zy_global: "54732,79376",
    zz: "11,1",
    zz_global: "181896,4104",
  },
  {
    profile_section_id: 1010,
    name: "HSS10X2X1/8",
    type: "HSS Rectangle",
    designation: "HSST10X2X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,7",
    ax_global: "1741,932",
    b: 2,
    b_global: "50,8",
    c: "4,31",
    c_global: "70628,24584",
    d: 10,
    d_global: 254,
    ix: "6,9",
    ix_global: "2871996,837",
    iy: "2,14",
    iy_global: "890735,2508",
    iz: "28,5",
    iz_global: "11862595,63",
    t: "0,116",
    t_global: "2,9464",
    zy: "2,33",
    zy_global: "38181,85912",
    zz: "7,65",
    zz_global: "125361,0396",
  },
  {
    profile_section_id: 1011,
    name: "HSS9X9X5/8",
    type: "HSS Rectangle",
    designation: "HSST9X9X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "18,7",
    ax_global: "12064,492",
    b: 9,
    b_global: "228,6",
    c: "81,6",
    c_global: "1337184,422",
    d: 9,
    d_global: "228,6",
    ix: 356,
    ix_global: "148178387,5",
    iy: 216,
    iy_global: "89905987,93",
    iz: 216,
    iz_global: "89905987,93",
    t: "0,581",
    t_global: "14,7574",
    zy: "58,1",
    zy_global: "952088,4184",
    zz: "58,1",
    zz_global: "952088,4184",
  },
  {
    profile_section_id: 1012,
    name: "HSS9X9X1/2",
    type: "HSS Rectangle",
    designation: "HSST9X9X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "15,3",
    ax_global: "9870,948",
    b: 9,
    b_global: "228,6",
    c: "67,4",
    c_global: "1104488,114",
    d: 9,
    d_global: "228,6",
    ix: 296,
    ix_global: 123204502,
    iy: 183,
    iy_global: "76170350,88",
    iz: 183,
    iz_global: "76170350,88",
    t: "0,465",
    t_global: "11,811",
    zy: "48,4",
    zy_global: "793133,8976",
    zz: "48,4",
    zz_global: "793133,8976",
  },
  {
    profile_section_id: 1013,
    name: "HSS9X9X3/8",
    type: "HSS Rectangle",
    designation: "HSST9X9X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "11,8",
    ax_global: "7612,888",
    b: 9,
    b_global: "228,6",
    c: "52,1",
    c_global: "853766,0344",
    d: 9,
    d_global: "228,6",
    ix: 231,
    ix_global: "96149459,31",
    iy: 145,
    iy_global: "60353556,71",
    iz: 145,
    iz_global: "60353556,71",
    t: "0,349",
    t_global: "8,8646",
    zy: "37,8",
    zy_global: "619431,0192",
    zz: "37,8",
    zz_global: "619431,0192",
  },
  {
    profile_section_id: 1014,
    name: "HSS9X9X5/16",
    type: "HSS Rectangle",
    designation: "HSST9X9X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "9,92",
    ax_global: "6399,9872",
    b: 9,
    b_global: "228,6",
    c: 44,
    c_global: "721030,816",
    d: 9,
    d_global: "228,6",
    ix: 196,
    ix_global: "81581359,42",
    iy: 124,
    iy_global: "51612696,77",
    iz: 124,
    iz_global: "51612696,77",
    t: "0,291",
    t_global: "7,3914",
    zy: "32,1",
    zy_global: "526024,7544",
    zz: "32,1",
    zz_global: "526024,7544",
  },
  {
    profile_section_id: 1015,
    name: "HSS9X9X1/4",
    type: "HSS Rectangle",
    designation: "HSST9X9X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "8,03",
    ax_global: "5180,6348",
    b: 9,
    b_global: "228,6",
    c: "35,8",
    c_global: "586656,8912",
    d: 9,
    d_global: "228,6",
    ix: 159,
    ix_global: "66180796,67",
    iy: 102,
    iy_global: "42455605,41",
    iz: 102,
    iz_global: "42455605,41",
    t: "0,233",
    t_global: "5,9182",
    zy: "26,2",
    zy_global: "429341,0768",
    zz: "26,2",
    zz_global: "429341,0768",
  },
  {
    profile_section_id: 1016,
    name: "HSS9X9X3/16",
    type: "HSS Rectangle",
    designation: "HSST9X9X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "6,06",
    ax_global: "3909,6696",
    b: 9,
    b_global: "228,6",
    c: "27,1",
    c_global: "444089,4344",
    d: 9,
    d_global: "228,6",
    ix: 121,
    ix_global: "50364002,5",
    iy: "78,2",
    iy_global: "32549297,48",
    iz: "78,2",
    iz_global: "32549297,48",
    t: "0,174",
    t_global: "4,4196",
    zy: 20,
    zy_global: "327741,28",
    zz: 20,
    zz_global: "327741,28",
  },
  {
    profile_section_id: 1017,
    name: "HSS9X9X1/8",
    type: "HSS Rectangle",
    designation: "HSST9X9X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "4,09",
    ax_global: "2638,7044",
    b: 9,
    b_global: "228,6",
    c: "18,3",
    c_global: "299883,2712",
    d: 9,
    d_global: "228,6",
    ix: 82,
    ix_global: "34130976,9",
    iy: "53,5",
    iy_global: "22268381,27",
    iz: "53,5",
    iz_global: "22268381,27",
    t: "0,116",
    t_global: "2,9464",
    zy: "13,6",
    zy_global: "222864,0704",
    zz: "13,6",
    zz_global: "222864,0704",
  },
  {
    profile_section_id: 1018,
    name: "HSS9X7X5/8",
    type: "HSS Rectangle",
    designation: "HSST9X7X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "16,4",
    ax_global: "10580,624",
    b: 7,
    b_global: "177,8",
    c: 62,
    c_global: "1015997,968",
    d: 9,
    d_global: "228,6",
    ix: 235,
    ix_global: "97814385,02",
    iy: 117,
    iy_global: "48699076,8",
    iz: 174,
    iz_global: "72424268,05",
    t: "0,581",
    t_global: "14,7574",
    zy: "40,5",
    zy_global: "663676,092",
    zz: "48,3",
    zz_global: "791495,1912",
  },
  {
    profile_section_id: 1019,
    name: "HSS9X7X1/2",
    type: "HSS Rectangle",
    designation: "HSST9X7X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "13,5",
    ax_global: "8709,66",
    b: 7,
    b_global: "177,8",
    c: "51,5",
    c_global: "843933,796",
    d: 9,
    d_global: "228,6",
    ix: 197,
    ix_global: "81997590,84",
    iy: 100,
    iy_global: "41623142,56",
    iz: 149,
    iz_global: "62018482,41",
    t: "0,465",
    t_global: "11,811",
    zy: 34,
    zy_global: "557160,176",
    zz: "40,5",
    zz_global: "663676,092",
  },
  {
    profile_section_id: 1020,
    name: "HSS9X7X3/8",
    type: "HSS Rectangle",
    designation: "HSST9X7X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "10,4",
    ax_global: "6709,664",
    b: 7,
    b_global: "177,8",
    c: 40,
    c_global: "655482,56",
    d: 9,
    d_global: "228,6",
    ix: 154,
    ix_global: "64099639,54",
    iy: "80,4",
    iy_global: "33465006,62",
    iz: 119,
    iz_global: "49531539,65",
    t: "0,349",
    t_global: "8,8646",
    zy: "26,7",
    zy_global: "437534,6088",
    zz: "31,8",
    zz_global: "521108,6352",
  },
  {
    profile_section_id: 1021,
    name: "HSS9X7X5/16",
    type: "HSS Rectangle",
    designation: "HSST9X7X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "8,76",
    ax_global: "5651,6016",
    b: 7,
    b_global: "177,8",
    c: "33,9",
    c_global: "555521,4696",
    d: 9,
    d_global: "228,6",
    ix: 131,
    ix_global: "54526316,75",
    iy: "69,2",
    iy_global: "28803214,65",
    iz: 102,
    iz_global: "42455605,41",
    t: "0,291",
    t_global: "7,3914",
    zy: "22,8",
    zy_global: "373625,0592",
    zz: "27,1",
    zz_global: "444089,4344",
  },
  {
    profile_section_id: 1022,
    name: "HSS9X7X1/4",
    type: "HSS Rectangle",
    designation: "HSST9X7X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "7,1",
    ax_global: "4580,636",
    b: 7,
    b_global: "177,8",
    c: "27,6",
    c_global: "452282,9664",
    d: 9,
    d_global: "228,6",
    ix: 107,
    ix_global: "44536762,54",
    iy: "57,2",
    iy_global: "23808437,54",
    iz: "84,1",
    iz_global: "35005062,89",
    t: "0,233",
    t_global: "5,9182",
    zy: "18,7",
    zy_global: "306438,0968",
    zz: "22,2",
    zz_global: "363792,8208",
  },
  {
    profile_section_id: 1023,
    name: "HSS9X7X3/16",
    type: "HSS Rectangle",
    designation: "HSST9X7X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "5,37",
    ax_global: "3464,5092",
    b: 7,
    b_global: "177,8",
    c: "20,9",
    c_global: "342489,6376",
    d: 9,
    d_global: "228,6",
    ix: "81,7",
    ix_global: "34006107,47",
    iy: "44,1",
    iy_global: "18355805,87",
    iz: "64,7",
    iz_global: "26930173,24",
    t: "0,174",
    t_global: "4,4196",
    zy: "14,3",
    zy_global: "234335,0152",
    zz: "16,9",
    zz_global: "276941,3816",
  },
  {
    profile_section_id: 1024,
    name: "HSS9X5X5/8",
    type: "HSS Rectangle",
    designation: "HSST9X5X0.625",
    shape: "RHS",
    country_code: "American",
    ax: 14,
    ax_global: "9032,24",
    b: 5,
    b_global: 127,
    c: "42,5",
    c_global: "696450,22",
    d: 9,
    d_global: "228,6",
    ix: 128,
    ix_global: "53277622,48",
    iy: 52,
    iy_global: "21644034,13",
    iz: 133,
    iz_global: "55358779,6",
    t: "0,581",
    t_global: "14,7574",
    zy: "25,3",
    zy_global: "414592,7192",
    zz: "38,5",
    zz_global: "630901,964",
  },
  {
    profile_section_id: 1025,
    name: "HSS9X5X1/2",
    type: "HSS Rectangle",
    designation: "HSST9X5X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "11,6",
    ax_global: "7483,856",
    b: 5,
    b_global: 127,
    c: "35,6",
    c_global: "583379,4784",
    d: 9,
    d_global: "228,6",
    ix: 109,
    ix_global: "45369225,39",
    iy: "45,2",
    iy_global: "18813660,44",
    iz: 115,
    iz_global: "47866613,94",
    t: "0,465",
    t_global: "11,811",
    zy: "21,5",
    zy_global: "352321,876",
    zz: "32,5",
    zz_global: "532579,58",
  },
  {
    profile_section_id: 1026,
    name: "HSS9X5X3/8",
    type: "HSS Rectangle",
    designation: "HSST9X5X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "8,97",
    ax_global: "5787,0852",
    b: 5,
    b_global: 127,
    c: "27,9",
    c_global: "457199,0856",
    d: 9,
    d_global: "228,6",
    ix: "86,9",
    ix_global: "36170510,88",
    iy: "36,8",
    iy_global: "15317316,46",
    iz: "92,5",
    iz_global: "38501406,87",
    t: "0,349",
    t_global: "8,8646",
    zy: "17,1",
    zy_global: "280218,7944",
    zz: "25,7",
    zz_global: "421147,5448",
  },
  {
    profile_section_id: 1027,
    name: "HSS9X5X5/16",
    type: "HSS Rectangle",
    designation: "HSST9X5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "7,59",
    ax_global: "4896,7644",
    b: 5,
    b_global: 127,
    c: "23,8",
    c_global: "390012,1232",
    d: 9,
    d_global: "228,6",
    ix: "74,4",
    ix_global: "30967618,06",
    iy: 32,
    iy_global: "13319405,62",
    iz: "79,8",
    iz_global: "33215267,76",
    t: "0,291",
    t_global: "7,3914",
    zy: "14,6",
    zy_global: "239251,1344",
    zz: 22,
    zz_global: "360515,408",
  },
  {
    profile_section_id: 1028,
    name: "HSS9X5X1/4",
    type: "HSS Rectangle",
    designation: "HSST9X5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "6,17",
    ax_global: "3980,6372",
    b: 5,
    b_global: 127,
    c: "19,4",
    c_global: "317909,0416",
    d: 9,
    d_global: "228,6",
    ix: "61,2",
    ix_global: "25473363,25",
    iy: "26,6",
    iy_global: "11071755,92",
    iz: "66,1",
    iz_global: "27512897,23",
    t: "0,233",
    t_global: "5,9182",
    zy: 12,
    zy_global: "196644,768",
    zz: "18,1",
    zz_global: "296605,8584",
  },
  {
    profile_section_id: 1029,
    name: "HSS9X5X3/16",
    type: "HSS Rectangle",
    designation: "HSST9X5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "4,67",
    ax_global: "3012,8972",
    b: 5,
    b_global: 127,
    c: "14,8",
    c_global: "242528,5472",
    d: 9,
    d_global: "228,6",
    ix: "46,9",
    ix_global: "19521253,86",
    iy: "20,7",
    iy_global: "8615990,51",
    iz: "51,1",
    iz_global: "21269425,85",
    t: "0,174",
    t_global: "4,4196",
    zy: "9,25",
    zy_global: "151580,342",
    zz: "13,8",
    zz_global: "226141,4832",
  },
  {
    profile_section_id: 1030,
    name: "HSS9X3X1/2",
    type: "HSS Rectangle",
    designation: "HSST9X3X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "9,74",
    ax_global: "6283,8584",
    b: 3,
    b_global: "76,2",
    c: "19,7",
    c_global: "322825,1608",
    d: 9,
    d_global: "228,6",
    ix: 40,
    ix_global: "16649257,02",
    iy: "13,2",
    iy_global: "5494254,818",
    iz: "80,8",
    iz_global: "33631499,19",
    t: "0,465",
    t_global: "11,811",
    zy: "10,8",
    zy_global: "176980,2912",
    zz: "24,6",
    zz_global: "403121,7744",
  },
  {
    profile_section_id: 1031,
    name: "HSS9X3X3/8",
    type: "HSS Rectangle",
    designation: "HSST9X3X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "7,58",
    ax_global: "4890,3128",
    b: 3,
    b_global: "76,2",
    c: "15,8",
    c_global: "258915,6112",
    d: 9,
    d_global: "228,6",
    ix: "33,1",
    ix_global: "13777260,19",
    iy: "11,2",
    iy_global: "4661791,967",
    iz: "66,3",
    iz_global: "27596143,52",
    t: "0,349",
    t_global: "8,8646",
    zy: "8,8",
    zy_global: "144206,1632",
    zz: "19,7",
    zz_global: "322825,1608",
  },
  {
    profile_section_id: 1032,
    name: "HSS9X3X5/16",
    type: "HSS Rectangle",
    designation: "HSST9X3X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "6,43",
    ax_global: "4148,3788",
    b: 3,
    b_global: "76,2",
    c: "13,6",
    c_global: "222864,0704",
    d: 9,
    d_global: "228,6",
    ix: "28,9",
    ix_global: "12029088,2",
    iy: "9,88",
    iy_global: "4112366,485",
    iz: "57,7",
    iz_global: "24016553,26",
    t: "0,291",
    t_global: "7,3914",
    zy: "7,63",
    zy_global: "125033,2983",
    zz: "16,9",
    zz_global: "276941,3816",
  },
  {
    profile_section_id: 1033,
    name: "HSS9X3X1/4",
    type: "HSS Rectangle",
    designation: "HSST9X3X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "5,24",
    ax_global: "3380,6384",
    b: 3,
    b_global: "76,2",
    c: "11,3",
    c_global: "185173,8232",
    d: 9,
    d_global: "228,6",
    ix: "24,2",
    ix_global: "10072800,5",
    iy: "8,38",
    iy_global: "3488019,347",
    iz: "48,2",
    iz_global: "20062354,71",
    t: "0,233",
    t_global: "5,9182",
    zy: "6,35",
    zy_global: "104057,8564",
    zz: 14,
    zz_global: "229418,896",
  },
  {
    profile_section_id: 1034,
    name: "HSS9X3X3/16",
    type: "HSS Rectangle",
    designation: "HSST9X3X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,98",
    ax_global: "2567,7368",
    b: 3,
    b_global: "76,2",
    c: "8,66",
    c_global: "141911,9742",
    d: 9,
    d_global: "228,6",
    ix: "18,9",
    ix_global: "7866773,944",
    iy: "6,64",
    iy_global: "2763776,666",
    iz: "37,6",
    iz_global: "15650301,6",
    t: "0,174",
    t_global: "4,4196",
    zy: "4,92",
    zy_global: "80624,35488",
    zz: "10,8",
    zz_global: "176980,2912",
  },
  {
    profile_section_id: 1035,
    name: "HSS8X8X5/8",
    type: "HSS Rectangle",
    designation: "HSST8X8X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "16,4",
    ax_global: "10580,624",
    b: 8,
    b_global: "203,2",
    c: "63,2",
    c_global: "1035662,445",
    d: 8,
    d_global: "203,2",
    ix: 244,
    ix_global: "101560467,8",
    iy: 146,
    iy_global: "60769788,14",
    iz: 146,
    iz_global: "60769788,14",
    t: "0,581",
    t_global: "14,7574",
    zy: "44,7",
    zy_global: "732501,7608",
    zz: "44,7",
    zz_global: "732501,7608",
  },
  {
    profile_section_id: 1036,
    name: "HSS8X8X1/2",
    type: "HSS Rectangle",
    designation: "HSST8X8X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "13,5",
    ax_global: "8709,66",
    b: 8,
    b_global: "203,2",
    c: "52,4",
    c_global: "858682,1536",
    d: 8,
    d_global: "203,2",
    ix: 204,
    ix_global: "84911210,82",
    iy: 125,
    iy_global: "52028928,2",
    iz: 125,
    iz_global: "52028928,2",
    t: "0,465",
    t_global: "11,811",
    zy: "37,5",
    zy_global: "614514,9",
    zz: "37,5",
    zz_global: "614514,9",
  },
  {
    profile_section_id: 1037,
    name: "HSS8X8X3/8",
    type: "HSS Rectangle",
    designation: "HSST8X8X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "10,4",
    ax_global: "6709,664",
    b: 8,
    b_global: "203,2",
    c: "40,7",
    c_global: "666953,5048",
    d: 8,
    d_global: "203,2",
    ix: 160,
    ix_global: "66597028,1",
    iy: 100,
    iy_global: "41623142,56",
    iz: 100,
    iz_global: "41623142,56",
    t: "0,349",
    t_global: "8,8646",
    zy: "29,4",
    zy_global: "481779,6816",
    zz: "29,4",
    zz_global: "481779,6816",
  },
  {
    profile_section_id: 1038,
    name: "HSS8X8X5/16",
    type: "HSS Rectangle",
    designation: "HSST8X8X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "8,76",
    ax_global: "5651,6016",
    b: 8,
    b_global: "203,2",
    c: "34,5",
    c_global: "565353,708",
    d: 8,
    d_global: "203,2",
    ix: 136,
    ix_global: "56607473,88",
    iy: "85,6",
    iy_global: "35629410,03",
    iz: "85,6",
    iz_global: "35629410,03",
    t: "0,291",
    t_global: "7,3914",
    zy: "25,1",
    zy_global: "411315,3064",
    zz: "25,1",
    zz_global: "411315,3064",
  },
  {
    profile_section_id: 1039,
    name: "HSS8X8X1/4",
    type: "HSS Rectangle",
    designation: "HSST8X8X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "7,1",
    ax_global: "4580,636",
    b: 8,
    b_global: "203,2",
    c: "28,1",
    c_global: "460476,4984",
    d: 8,
    d_global: "203,2",
    ix: 111,
    ix_global: "46201688,24",
    iy: "70,7",
    iy_global: "29427561,79",
    iz: "70,7",
    iz_global: "29427561,79",
    t: "0,233",
    t_global: "5,9182",
    zy: "20,5",
    zy_global: "335934,812",
    zz: "20,5",
    zz_global: "335934,812",
  },
  {
    profile_section_id: 1040,
    name: "HSS8X8X3/16",
    type: "HSS Rectangle",
    designation: "HSST8X8X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "5,37",
    ax_global: "3464,5092",
    b: 8,
    b_global: "203,2",
    c: "21,3",
    c_global: "349044,4632",
    d: 8,
    d_global: "203,2",
    ix: "84,5",
    ix_global: "35171555,46",
    iy: "54,4",
    iy_global: "22642989,55",
    iz: "54,4",
    iz_global: "22642989,55",
    t: "0,174",
    t_global: "4,4196",
    zy: "15,7",
    zy_global: "257276,9048",
    zz: "15,7",
    zz_global: "257276,9048",
  },
  {
    profile_section_id: 1041,
    name: "HSS8X8X1/8",
    type: "HSS Rectangle",
    designation: "HSST8X8X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "3,62",
    ax_global: "2335,4792",
    b: 8,
    b_global: "203,2",
    c: "14,4",
    c_global: "235973,7216",
    d: 8,
    d_global: "203,2",
    ix: "57,3",
    ix_global: "23850060,69",
    iy: "37,4",
    iy_global: "15567055,32",
    iz: "37,4",
    iz_global: "15567055,32",
    t: "0,116",
    t_global: "2,9464",
    zy: "10,7",
    zy_global: "175341,5848",
    zz: "10,7",
    zz_global: "175341,5848",
  },
  {
    profile_section_id: 1042,
    name: "HSS8X6X5/8",
    type: "HSS Rectangle",
    designation: "HSST8X6X0.625",
    shape: "RHS",
    country_code: "American",
    ax: 14,
    ax_global: "9032,24",
    b: 6,
    b_global: "152,4",
    c: 46,
    c_global: "753804,944",
    d: 8,
    d_global: "203,2",
    ix: 150,
    ix_global: "62434713,84",
    iy: "72,3",
    iy_global: "30093532,07",
    iz: 114,
    iz_global: "47450382,52",
    t: "0,581",
    t_global: "14,7574",
    zy: "29,5",
    zy_global: "483418,388",
    zz: "36,1",
    zz_global: "591573,0104",
  },
  {
    profile_section_id: 1043,
    name: "HSS8X6X1/2",
    type: "HSS Rectangle",
    designation: "HSST8X6X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "11,6",
    ax_global: "7483,856",
    b: 6,
    b_global: "152,4",
    c: "38,4",
    c_global: "629263,2576",
    d: 8,
    d_global: "203,2",
    ix: 127,
    ix_global: "52861391,05",
    iy: "62,5",
    iy_global: "26014464,1",
    iz: "98,2",
    iz_global: "40873925,99",
    t: "0,465",
    t_global: "11,811",
    zy: "24,9",
    zy_global: "408037,8936",
    zz: "30,5",
    zz_global: "499805,452",
  },
  {
    profile_section_id: 1044,
    name: "HSS8X6X3/8",
    type: "HSS Rectangle",
    designation: "HSST8X6X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "8,97",
    ax_global: "5787,0852",
    b: 6,
    b_global: "152,4",
    c: 30,
    c_global: "491611,92",
    d: 8,
    d_global: "203,2",
    ix: 100,
    ix_global: "41623142,56",
    iy: "50,6",
    iy_global: "21061310,14",
    iz: "79,1",
    iz_global: "32923905,76",
    t: "0,349",
    t_global: "8,8646",
    zy: "19,8",
    zy_global: "324463,8672",
    zz: "24,1",
    zz_global: "394928,2424",
  },
  {
    profile_section_id: 1045,
    name: "HSS8X6X5/16",
    type: "HSS Rectangle",
    designation: "HSST8X6X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "7,59",
    ax_global: "4896,7644",
    b: 6,
    b_global: "152,4",
    c: "25,5",
    c_global: "417870,132",
    d: 8,
    d_global: "203,2",
    ix: "85,8",
    ix_global: "35712656,32",
    iy: "43,8",
    iy_global: "18230936,44",
    iz: "68,3",
    iz_global: "28428606,37",
    t: "0,291",
    t_global: "7,3914",
    zy: "16,9",
    zy_global: "276941,3816",
    zz: "20,6",
    zz_global: "337573,5184",
  },
  {
    profile_section_id: 1046,
    name: "HSS8X6X1/4",
    type: "HSS Rectangle",
    designation: "HSST8X6X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "6,17",
    ax_global: "3980,6372",
    b: 6,
    b_global: "152,4",
    c: "20,8",
    c_global: "340850,9312",
    d: 8,
    d_global: "203,2",
    ix: "70,3",
    ix_global: "29261069,22",
    iy: "36,4",
    iy_global: "15150823,89",
    iz: "56,6",
    iz_global: "23558698,69",
    t: "0,233",
    t_global: "5,9182",
    zy: "13,9",
    zy_global: "227780,1896",
    zz: "16,9",
    zz_global: "276941,3816",
  },
  {
    profile_section_id: 1047,
    name: "HSS8X6X3/16",
    type: "HSS Rectangle",
    designation: "HSST8X6X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "4,67",
    ax_global: "3012,8972",
    b: 6,
    b_global: "152,4",
    c: "15,8",
    c_global: "258915,6112",
    d: 8,
    d_global: "203,2",
    ix: "53,7",
    ix_global: "22351627,55",
    iy: "28,2",
    iy_global: "11737726,2",
    iz: "43,7",
    iz_global: "18189313,3",
    t: "0,174",
    t_global: "4,4196",
    zy: "10,7",
    zy_global: "175341,5848",
    zz: 13,
    zz_global: "213031,832",
  },
  {
    profile_section_id: 1048,
    name: "HSS8X4X5/8",
    type: "HSS Rectangle",
    designation: "HSST8X4X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "11,7",
    ax_global: "7548,372",
    b: 4,
    b_global: "101,6",
    c: "28,7",
    c_global: "470308,7368",
    d: 8,
    d_global: "203,2",
    ix: "70,3",
    ix_global: "29261069,22",
    iy: "26,6",
    iy_global: "11071755,92",
    iz: 82,
    iz_global: "34130976,9",
    t: "0,581",
    t_global: "14,7574",
    zy: "16,6",
    zy_global: "272025,2624",
    zz: "27,4",
    zz_global: "449005,5536",
  },
  {
    profile_section_id: 1049,
    name: "HSS8X4X1/2",
    type: "HSS Rectangle",
    designation: "HSST8X4X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "9,74",
    ax_global: "6283,8584",
    b: 4,
    b_global: "101,6",
    c: "24,4",
    c_global: "399844,3616",
    d: 8,
    d_global: "203,2",
    ix: "61,1",
    ix_global: "25431740,1",
    iy: "23,6",
    iy_global: "9823061,644",
    iz: "71,8",
    iz_global: "29885416,36",
    t: "0,465",
    t_global: "11,811",
    zy: "14,3",
    zy_global: "234335,0152",
    zz: "23,5",
    zz_global: "385096,004",
  },
  {
    profile_section_id: 1050,
    name: "HSS8X4X3/8",
    type: "HSS Rectangle",
    designation: "HSST8X4X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "7,58",
    ax_global: "4890,3128",
    b: 4,
    b_global: "101,6",
    c: "19,3",
    c_global: "316270,3352",
    d: 8,
    d_global: "203,2",
    ix: "49,3",
    ix_global: "20520209,28",
    iy: "19,6",
    iy_global: "8158135,942",
    iz: "58,7",
    iz_global: "24432784,68",
    t: "0,349",
    t_global: "8,8646",
    zy: "11,5",
    zy_global: "188451,236",
    zz: "18,8",
    zz_global: "308076,8032",
  },
  {
    profile_section_id: 1051,
    name: "HSS8X4X5/16",
    type: "HSS Rectangle",
    designation: "HSST8X4X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "6,43",
    ax_global: "4148,3788",
    b: 4,
    b_global: "101,6",
    c: "16,5",
    c_global: "270386,556",
    d: 8,
    d_global: "203,2",
    ix: "42,6",
    ix_global: "17731458,73",
    iy: "17,2",
    iy_global: "7159180,52",
    iz: 51,
    iz_global: "21227802,71",
    t: "0,291",
    t_global: "7,3914",
    zy: "9,91",
    zy_global: "162395,8042",
    zz: "16,1",
    zz_global: "263831,7304",
  },
  {
    profile_section_id: 1052,
    name: "HSS8X4X1/4",
    type: "HSS Rectangle",
    designation: "HSST8X4X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "5,24",
    ax_global: "3380,6384",
    b: 4,
    b_global: "101,6",
    c: "13,6",
    c_global: "222864,0704",
    d: 8,
    d_global: "203,2",
    ix: "35,3",
    ix_global: "14692969,32",
    iy: "14,4",
    iy_global: "5993732,529",
    iz: "42,5",
    iz_global: "17689835,59",
    t: "0,233",
    t_global: "5,9182",
    zy: "8,2",
    zy_global: "134373,9248",
    zz: "13,3",
    zz_global: "217947,9512",
  },
  {
    profile_section_id: 1053,
    name: "HSS8X4X3/16",
    type: "HSS Rectangle",
    designation: "HSST8X4X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,98",
    ax_global: "2567,7368",
    b: 4,
    b_global: "101,6",
    c: "10,4",
    c_global: "170425,4656",
    d: 8,
    d_global: "203,2",
    ix: "27,2",
    ix_global: "11321494,78",
    iy: "11,3",
    iy_global: "4703415,109",
    iz: "33,1",
    iz_global: "13777260,19",
    t: "0,174",
    t_global: "4,4196",
    zy: "6,33",
    zy_global: "103730,1151",
    zz: "10,2",
    zz_global: "167148,0528",
  },
  {
    profile_section_id: 1054,
    name: "HSS8X4X1/8",
    type: "HSS Rectangle",
    designation: "HSST8X4X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,7",
    ax_global: "1741,932",
    b: 4,
    b_global: "101,6",
    c: "7,1",
    c_global: "116348,1544",
    d: 8,
    d_global: "203,2",
    ix: "18,7",
    ix_global: "7783527,659",
    iy: "7,9",
    iy_global: "3288228,262",
    iz: "22,9",
    iz_global: "9531699,646",
    t: "0,116",
    t_global: "2,9464",
    zy: "4,36",
    zy_global: "71447,59904",
    zz: "7,02",
    zz_global: "115037,1893",
  },
  {
    profile_section_id: 1055,
    name: "HSS8X3X1/2",
    type: "HSS Rectangle",
    designation: "HSST8X3X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "8,81",
    ax_global: "5683,8596",
    b: 3,
    b_global: "76,2",
    c: "17,4",
    c_global: "285134,9136",
    d: 8,
    d_global: "203,2",
    ix: "34,3",
    ix_global: "14276737,9",
    iy: "11,7",
    iy_global: "4869907,68",
    iz: "58,6",
    iz_global: "24391161,54",
    t: "0,465",
    t_global: "11,811",
    zy: "9,64",
    zy_global: "157971,297",
    zz: 20,
    zz_global: "327741,28",
  },
  {
    profile_section_id: 1056,
    name: "HSS8X3X3/8",
    type: "HSS Rectangle",
    designation: "HSST8X3X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "6,88",
    ax_global: "4438,7008",
    b: 3,
    b_global: "76,2",
    c: 14,
    c_global: "229418,896",
    d: 8,
    d_global: "203,2",
    ix: "28,5",
    ix_global: "11862595,63",
    iy: 10,
    iy_global: "4162314,256",
    iz: "48,5",
    iz_global: "20187224,14",
    t: "0,349",
    t_global: "8,8646",
    zy: "7,88",
    zy_global: "129130,0643",
    zz: "16,1",
    zz_global: "263831,7304",
  },
  {
    profile_section_id: 1057,
    name: "HSS8X3X5/16",
    type: "HSS Rectangle",
    designation: "HSST8X3X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "5,85",
    ax_global: "3774,186",
    b: 3,
    b_global: "76,2",
    c: "12,1",
    c_global: "198283,4744",
    d: 8,
    d_global: "203,2",
    ix: "24,9",
    ix_global: "10364162,5",
    iy: "8,81",
    iy_global: "3666998,86",
    iz: "42,4",
    iz_global: "17648212,45",
    t: "0,291",
    t_global: "7,3914",
    zy: "6,84",
    zy_global: "112087,5178",
    zz: "13,9",
    zz_global: "227780,1896",
  },
  {
    profile_section_id: 1058,
    name: "HSS8X3X1/4",
    type: "HSS Rectangle",
    designation: "HSST8X3X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "4,77",
    ax_global: "3077,4132",
    b: 3,
    b_global: "76,2",
    c: 10,
    c_global: "163870,64",
    d: 8,
    d_global: "203,2",
    ix: "20,8",
    ix_global: "8657613,652",
    iy: "7,49",
    iy_global: "3117573,378",
    iz: "35,5",
    iz_global: "14776215,61",
    t: "0,233",
    t_global: "5,9182",
    zy: "5,7",
    zy_global: "93406,2648",
    zz: "11,5",
    zz_global: "188451,236",
  },
  {
    profile_section_id: 1059,
    name: "HSS8X3X3/16",
    type: "HSS Rectangle",
    designation: "HSST8X3X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,63",
    ax_global: "2341,9308",
    b: 3,
    b_global: "76,2",
    c: "7,68",
    c_global: "125852,6515",
    d: 8,
    d_global: "203,2",
    ix: "16,2",
    ix_global: "6742949,095",
    iy: "5,94",
    iy_global: "2472414,668",
    iz: "27,8",
    iz_global: "11571233,63",
    t: "0,174",
    t_global: "4,4196",
    zy: "4,43",
    zy_global: "72594,69352",
    zz: "8,87",
    zz_global: "145353,2577",
  },
  {
    profile_section_id: 1060,
    name: "HSS8X3X1/8",
    type: "HSS Rectangle",
    designation: "HSST8X3X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,46",
    ax_global: "1587,0936",
    b: 3,
    b_global: "76,2",
    c: "5,27",
    c_global: "86359,82728",
    d: 8,
    d_global: "203,2",
    ix: "11,3",
    ix_global: "4703415,109",
    iy: "4,2",
    iy_global: "1748171,988",
    iz: "19,3",
    iz_global: "8033266,514",
    t: "0,116",
    t_global: "2,9464",
    zy: "3,07",
    zy_global: "50308,28648",
    zz: "6,11",
    zz_global: "100124,961",
  },
  {
    profile_section_id: 1061,
    name: "HSS8X2X3/8",
    type: "HSS Rectangle",
    designation: "HSST8X2X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "6,18",
    ax_global: "3987,0888",
    b: 2,
    b_global: "50,8",
    c: "8,65",
    c_global: "141748,1036",
    d: 8,
    d_global: "203,2",
    ix: "12,1",
    ix_global: "5036400,25",
    iy: "3,73",
    iy_global: "1552543,217",
    iz: "38,2",
    iz_global: "15900040,46",
    t: "0,349",
    t_global: "8,8646",
    zy: "4,61",
    zy_global: "75544,36504",
    zz: "13,4",
    zz_global: "219586,6576",
  },
  {
    profile_section_id: 1062,
    name: "HSS8X2X5/16",
    type: "HSS Rectangle",
    designation: "HSST8X2X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "5,26",
    ax_global: "3393,5416",
    b: 2,
    b_global: "50,8",
    c: "7,57",
    c_global: "124050,0745",
    d: 8,
    d_global: "203,2",
    ix: "10,9",
    ix_global: "4536922,539",
    iy: "3,38",
    iy_global: "1406862,219",
    iz: "33,7",
    iz_global: "14026999,04",
    t: "0,291",
    t_global: "7,3914",
    zy: "4,06",
    zy_global: "66531,47984",
    zz: "11,6",
    zz_global: "190089,9424",
  },
  {
    profile_section_id: 1063,
    name: "HSS8X2X1/4",
    type: "HSS Rectangle",
    designation: "HSST8X2X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "4,3",
    ax_global: "2774,188",
    b: 2,
    b_global: "50,8",
    c: "6,35",
    c_global: "104057,8564",
    d: 8,
    d_global: "203,2",
    ix: "9,36",
    ix_global: "3895926,144",
    iy: "2,94",
    iy_global: "1223720,391",
    iz: "28,5",
    iz_global: "11862595,63",
    t: "0,233",
    t_global: "5,9182",
    zy: "3,43",
    zy_global: "56207,62952",
    zz: "9,68",
    zz_global: "158626,7795",
  },
  {
    profile_section_id: 1064,
    name: "HSS8X2X3/16",
    type: "HSS Rectangle",
    designation: "HSST8X2X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,28",
    ax_global: "2116,1248",
    b: 2,
    b_global: "50,8",
    c: "4,95",
    c_global: "81115,9668",
    d: 8,
    d_global: "203,2",
    ix: "7,48",
    ix_global: "3113411,063",
    iy: "2,39",
    iy_global: "994793,1072",
    iz: "22,4",
    iz_global: "9323583,933",
    t: "0,174",
    t_global: "4,4196",
    zy: "2,7",
    zy_global: "44245,0728",
    zz: "7,51",
    zz_global: "123066,8506",
  },
  {
    profile_section_id: 1065,
    name: "HSS8X2X1/8",
    type: "HSS Rectangle",
    designation: "HSST8X2X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,23",
    ax_global: "1438,7068",
    b: 2,
    b_global: "50,8",
    c: "3,44",
    c_global: "56371,50016",
    d: 8,
    d_global: "203,2",
    ix: "5,3",
    ix_global: "2206026,556",
    iy: "1,72",
    iy_global: "715918,052",
    iz: "15,7",
    iz_global: "6534833,382",
    t: "0,116",
    t_global: "2,9464",
    zy: "1,9",
    zy_global: "31135,4216",
    zz: "5,19",
    zz_global: "85048,86216",
  },
  {
    profile_section_id: 1066,
    name: "HSS7X7X5/8",
    type: "HSS Rectangle",
    designation: "HSST7X7X0.625",
    shape: "RHS",
    country_code: "American",
    ax: 14,
    ax_global: "9032,24",
    b: 7,
    b_global: "177,8",
    c: "47,1",
    c_global: "771830,7144",
    d: 7,
    d_global: "177,8",
    ix: 158,
    ix_global: "65764565,24",
    iy: "93,4",
    iy_global: "38876015,15",
    iz: "93,4",
    iz_global: "38876015,15",
    t: "0,581",
    t_global: "14,7574",
    zy: "33,1",
    zy_global: "542411,8184",
    zz: "33,1",
    zz_global: "542411,8184",
  },
  {
    profile_section_id: 1067,
    name: "HSS7X7X1/2",
    type: "HSS Rectangle",
    designation: "HSST7X7X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "11,6",
    ax_global: "7483,856",
    b: 7,
    b_global: "177,8",
    c: "39,3",
    c_global: "644011,6152",
    d: 7,
    d_global: "177,8",
    ix: 133,
    ix_global: "55358779,6",
    iy: "80,5",
    iy_global: "33506629,76",
    iz: "80,5",
    iz_global: "33506629,76",
    t: "0,465",
    t_global: "11,811",
    zy: "27,9",
    zy_global: "457199,0856",
    zz: "27,9",
    zz_global: "457199,0856",
  },
  {
    profile_section_id: 1068,
    name: "HSS7X7X3/8",
    type: "HSS Rectangle",
    designation: "HSST7X7X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "8,97",
    ax_global: "5787,0852",
    b: 7,
    b_global: "177,8",
    c: "30,7",
    c_global: "503082,8648",
    d: 7,
    d_global: "177,8",
    ix: 105,
    ix_global: "43704299,69",
    iy: 65,
    iy_global: "27055042,66",
    iz: 65,
    iz_global: "27055042,66",
    t: "0,349",
    t_global: "8,8646",
    zy: "22,1",
    zy_global: "362154,1144",
    zz: "22,1",
    zz_global: "362154,1144",
  },
  {
    profile_section_id: 1069,
    name: "HSS7X7X5/16",
    type: "HSS Rectangle",
    designation: "HSST7X7X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "7,59",
    ax_global: "4896,7644",
    b: 7,
    b_global: "177,8",
    c: "26,1",
    c_global: "427702,3704",
    d: 7,
    d_global: "177,8",
    ix: "89,7",
    ix_global: "37335958,88",
    iy: "56,1",
    iy_global: "23350582,98",
    iz: "56,1",
    iz_global: "23350582,98",
    t: "0,291",
    t_global: "7,3914",
    zy: "18,9",
    zy_global: "309715,5096",
    zz: "18,9",
    zz_global: "309715,5096",
  },
  {
    profile_section_id: 1070,
    name: "HSS7X7X1/4",
    type: "HSS Rectangle",
    designation: "HSST7X7X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "6,17",
    ax_global: "3980,6372",
    b: 7,
    b_global: "177,8",
    c: "21,3",
    c_global: "349044,4632",
    d: 7,
    d_global: "177,8",
    ix: "73,5",
    ix_global: "30593009,78",
    iy: "46,5",
    iy_global: "19354761,29",
    iz: "46,5",
    iz_global: "19354761,29",
    t: "0,233",
    t_global: "5,9182",
    zy: "15,5",
    zy_global: "253999,492",
    zz: "15,5",
    zz_global: "253999,492",
  },
  {
    profile_section_id: 1071,
    name: "HSS7X7X3/16",
    type: "HSS Rectangle",
    designation: "HSST7X7X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "4,67",
    ax_global: "3012,8972",
    b: 7,
    b_global: "177,8",
    c: "16,2",
    c_global: "265470,4368",
    d: 7,
    d_global: "177,8",
    ix: "56,1",
    ix_global: "23350582,98",
    iy: 36,
    iy_global: "14984331,32",
    iz: 36,
    iz_global: "14984331,32",
    t: "0,174",
    t_global: "4,4196",
    zy: "11,9",
    zy_global: "195006,0616",
    zz: "11,9",
    zz_global: "195006,0616",
  },
  {
    profile_section_id: 1072,
    name: "HSS7X7X1/8",
    type: "HSS Rectangle",
    designation: "HSST7X7X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "3,16",
    ax_global: "2038,7056",
    b: 7,
    b_global: "177,8",
    c: 11,
    c_global: "180257,704",
    d: 7,
    d_global: "177,8",
    ix: "38,2",
    ix_global: "15900040,46",
    iy: "24,8",
    iy_global: "10322539,35",
    iz: "24,8",
    iz_global: "10322539,35",
    t: "0,116",
    t_global: "2,9464",
    zy: "8,13",
    zy_global: "133226,8303",
    zz: "8,13",
    zz_global: "133226,8303",
  },
  {
    profile_section_id: 1073,
    name: "HSS7X5X1/2",
    type: "HSS Rectangle",
    designation: "HSST7X5X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "9,74",
    ax_global: "6283,8584",
    b: 5,
    b_global: 127,
    c: "27,2",
    c_global: "445728,1408",
    d: 7,
    d_global: "177,8",
    ix: "75,8",
    ix_global: "31550342,06",
    iy: "35,6",
    iy_global: "14817838,75",
    iz: "60,6",
    iz_global: "25223624,39",
    t: "0,465",
    t_global: "11,811",
    zy: "17,3",
    zy_global: "283496,2072",
    zz: "21,9",
    zz_global: "358876,7016",
  },
  {
    profile_section_id: 1074,
    name: "HSS7X5X3/8",
    type: "HSS Rectangle",
    designation: "HSST7X5X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "7,58",
    ax_global: "4890,3128",
    b: 5,
    b_global: 127,
    c: "21,4",
    c_global: "350683,1696",
    d: 7,
    d_global: "177,8",
    ix: "60,6",
    ix_global: "25223624,39",
    iy: "29,3",
    iy_global: "12195580,77",
    iz: "49,5",
    iz_global: "20603455,57",
    t: "0,349",
    t_global: "8,8646",
    zy: "13,8",
    zy_global: "226141,4832",
    zz: "17,5",
    zz_global: "286773,62",
  },
  {
    profile_section_id: 1075,
    name: "HSS7X5X5/16",
    type: "HSS Rectangle",
    designation: "HSST7X5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "6,43",
    ax_global: "4148,3788",
    b: 5,
    b_global: 127,
    c: "18,3",
    c_global: "299883,2712",
    d: 7,
    d_global: "177,8",
    ix: "52,1",
    ix_global: "21685657,27",
    iy: "25,5",
    iy_global: "10613901,35",
    iz: 43,
    iz_global: "17897951,3",
    t: "0,291",
    t_global: "7,3914",
    zy: "11,9",
    zy_global: "195006,0616",
    zz: 15,
    zz_global: "245805,96",
  },
  {
    profile_section_id: 1076,
    name: "HSS7X5X1/4",
    type: "HSS Rectangle",
    designation: "HSST7X5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "5,24",
    ax_global: "3380,6384",
    b: 5,
    b_global: 127,
    c: 15,
    c_global: "245805,96",
    d: 7,
    d_global: "177,8",
    ix: "42,9",
    ix_global: "17856328,16",
    iy: "21,3",
    iy_global: "8865729,365",
    iz: "35,9",
    iz_global: "14942708,18",
    t: "0,233",
    t_global: "5,9182",
    zy: "9,83",
    zy_global: "161084,8391",
    zz: "12,4",
    zz_global: "203199,5936",
  },
  {
    profile_section_id: 1077,
    name: "HSS7X5X3/16",
    type: "HSS Rectangle",
    designation: "HSST7X5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,98",
    ax_global: "2567,7368",
    b: 5,
    b_global: 127,
    c: "11,4",
    c_global: "186812,5296",
    d: 7,
    d_global: "177,8",
    ix: "32,9",
    ix_global: "13694013,9",
    iy: "16,6",
    iy_global: "6909441,665",
    iz: "27,9",
    iz_global: "11612856,77",
    t: "0,174",
    t_global: "4,4196",
    zy: "7,57",
    zy_global: "124050,0745",
    zz: "9,52",
    zz_global: "156004,8493",
  },
  {
    profile_section_id: 1078,
    name: "HSS7X5X1/8",
    type: "HSS Rectangle",
    designation: "HSST7X5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,7",
    ax_global: "1741,932",
    b: 5,
    b_global: 127,
    c: "7,79",
    c_global: "127655,2286",
    d: 7,
    d_global: "177,8",
    ix: "22,5",
    ix_global: "9365207,076",
    iy: "11,6",
    iy_global: "4828284,537",
    iz: "19,3",
    iz_global: "8033266,514",
    t: "0,116",
    t_global: "2,9464",
    zy: "5,2",
    zy_global: "85212,7328",
    zz: "6,53",
    zz_global: "107007,5279",
  },
  {
    profile_section_id: 1079,
    name: "HSS7X4X1/2",
    type: "HSS Rectangle",
    designation: "HSST7X4X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "8,81",
    ax_global: "5683,8596",
    b: 4,
    b_global: "101,6",
    c: "21,1",
    c_global: "345767,0504",
    d: 7,
    d_global: "177,8",
    ix: "50,5",
    ix_global: "21019686,99",
    iy: "20,7",
    iy_global: "8615990,51",
    iz: "50,7",
    iz_global: "21102933,28",
    t: "0,465",
    t_global: "11,811",
    zy: "12,6",
    zy_global: "206477,0064",
    zz: "18,8",
    zz_global: "308076,8032",
  },
  {
    profile_section_id: 1080,
    name: "HSS7X4X3/8",
    type: "HSS Rectangle",
    designation: "HSST7X4X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "6,88",
    ax_global: "4438,7008",
    b: 4,
    b_global: "101,6",
    c: "16,8",
    c_global: "275302,6752",
    d: 7,
    d_global: "177,8",
    ix: 41,
    ix_global: "17065488,45",
    iy: "17,3",
    iy_global: "7200803,663",
    iz: "41,8",
    iz_global: "17398473,59",
    t: "0,349",
    t_global: "8,8646",
    zy: "10,2",
    zy_global: "167148,0528",
    zz: "15,1",
    zz_global: "247444,6664",
  },
  {
    profile_section_id: 1081,
    name: "HSS7X4X5/16",
    type: "HSS Rectangle",
    designation: "HSST7X4X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "5,85",
    ax_global: "3774,186",
    b: 4,
    b_global: "101,6",
    c: "14,4",
    c_global: "235973,7216",
    d: 7,
    d_global: "177,8",
    ix: "35,4",
    ix_global: "14734592,47",
    iy: "15,2",
    iy_global: "6326717,669",
    iz: "36,5",
    iz_global: "15192447,03",
    t: "0,291",
    t_global: "7,3914",
    zy: "8,83",
    zy_global: "144697,7751",
    zz: "13,1",
    zz_global: "214670,5384",
  },
  {
    profile_section_id: 1082,
    name: "HSS7X4X1/4",
    type: "HSS Rectangle",
    designation: "HSST7X4X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "4,77",
    ax_global: "3077,4132",
    b: 4,
    b_global: "101,6",
    c: "11,8",
    c_global: "193367,3552",
    d: 7,
    d_global: "177,8",
    ix: "29,3",
    ix_global: "12195580,77",
    iy: "12,8",
    iy_global: "5327762,248",
    iz: "30,5",
    iz_global: "12695058,48",
    t: "0,233",
    t_global: "5,9182",
    zy: "7,33",
    zy_global: "120117,1791",
    zz: "10,8",
    zz_global: "176980,2912",
  },
  {
    profile_section_id: 1083,
    name: "HSS7X4X3/16",
    type: "HSS Rectangle",
    designation: "HSST7X4X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,63",
    ax_global: "2341,9308",
    b: 4,
    b_global: "101,6",
    c: "9,07",
    c_global: "148630,6705",
    d: 7,
    d_global: "177,8",
    ix: "22,7",
    ix_global: "9448453,361",
    iy: 10,
    iy_global: "4162314,256",
    iz: "23,8",
    iz_global: "9906307,929",
    t: "0,174",
    t_global: "4,4196",
    zy: "5,67",
    zy_global: "92914,65288",
    zz: "8,33",
    zz_global: "136504,2431",
  },
  {
    profile_section_id: 1084,
    name: "HSS7X4X1/8",
    type: "HSS Rectangle",
    designation: "HSST7X4X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,46",
    ax_global: "1587,0936",
    b: 4,
    b_global: "101,6",
    c: "6,2",
    c_global: "101599,7968",
    d: 7,
    d_global: "177,8",
    ix: "15,6",
    ix_global: "6493210,239",
    iy: "7,03",
    iy_global: "2926106,922",
    iz: "16,6",
    iz_global: "6909441,665",
    t: "0,116",
    t_global: "2,9464",
    zy: "3,91",
    zy_global: "64073,42024",
    zz: "5,73",
    zz_global: "93897,87672",
  },
  {
    profile_section_id: 1085,
    name: "HSS7X3X1/2",
    type: "HSS Rectangle",
    designation: "HSST7X3X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "7,88",
    ax_global: "5083,8608",
    b: 3,
    b_global: "76,2",
    c: 15,
    c_global: "245805,96",
    d: 7,
    d_global: "177,8",
    ix: "28,6",
    ix_global: "11904218,77",
    iy: "10,2",
    iy_global: "4245560,541",
    iz: "40,7",
    iz_global: "16940619,02",
    t: "0,465",
    t_global: "11,811",
    zy: "8,46",
    zy_global: "138634,5614",
    zz: "15,8",
    zz_global: "258915,6112",
  },
  {
    profile_section_id: 1086,
    name: "HSS7X3X3/8",
    type: "HSS Rectangle",
    designation: "HSST7X3X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "6,18",
    ax_global: "3987,0888",
    b: 3,
    b_global: "76,2",
    c: "12,1",
    c_global: "198283,4744",
    d: 7,
    d_global: "177,8",
    ix: "23,9",
    ix_global: "9947931,072",
    iy: "8,71",
    iy_global: "3625375,717",
    iz: "34,1",
    iz_global: "14193491,61",
    t: "0,349",
    t_global: "8,8646",
    zy: "6,95",
    zy_global: "113890,0948",
    zz: "12,8",
    zz_global: "209754,4192",
  },
  {
    profile_section_id: 1087,
    name: "HSS7X3X5/16",
    type: "HSS Rectangle",
    designation: "HSST7X3X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "5,26",
    ax_global: "3393,5416",
    b: 3,
    b_global: "76,2",
    c: "10,5",
    c_global: "172064,172",
    d: 7,
    d_global: "177,8",
    ix: "20,9",
    ix_global: "8699236,795",
    iy: "7,74",
    iy_global: "3221631,234",
    iz: "29,9",
    iz_global: "12445319,63",
    t: "0,291",
    t_global: "7,3914",
    zy: "6,05",
    zy_global: "99141,7372",
    zz: "11,1",
    zz_global: "181896,4104",
  },
  {
    profile_section_id: 1088,
    name: "HSS7X3X1/4",
    type: "HSS Rectangle",
    designation: "HSST7X3X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "4,3",
    ax_global: "2774,188",
    b: 3,
    b_global: "76,2",
    c: "8,68",
    c_global: "142239,7155",
    d: 7,
    d_global: "177,8",
    ix: "17,5",
    ix_global: "7284049,948",
    iy: "6,6",
    iy_global: "2747127,409",
    iz: "25,2",
    iz_global: "10489031,93",
    t: "0,233",
    t_global: "5,9182",
    zy: "5,06",
    zy_global: "82918,54384",
    zz: "9,22",
    zz_global: "151088,7301",
  },
  {
    profile_section_id: 1089,
    name: "HSS7X3X3/16",
    type: "HSS Rectangle",
    designation: "HSST7X3X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,28",
    ax_global: "2116,1248",
    b: 3,
    b_global: "76,2",
    c: "6,69",
    c_global: "109629,4582",
    d: 7,
    d_global: "177,8",
    ix: "13,7",
    ix_global: "5702370,531",
    iy: "5,24",
    iy_global: "2181052,67",
    iz: "19,8",
    iz_global: "8241382,227",
    t: "0,174",
    t_global: "4,4196",
    zy: "3,94",
    zy_global: "64565,03216",
    zz: "7,14",
    zz_global: "117003,637",
  },
  {
    profile_section_id: 1090,
    name: "HSS7X3X1/8",
    type: "HSS Rectangle",
    designation: "HSST7X3X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,23",
    ax_global: "1438,7068",
    b: 3,
    b_global: "76,2",
    c: "4,6",
    c_global: "75380,4944",
    d: 7,
    d_global: "177,8",
    ix: "9,48",
    ix_global: "3945873,915",
    iy: "3,71",
    iy_global: "1544218,589",
    iz: "13,8",
    iz_global: "5743993,673",
    t: "0,116",
    t_global: "2,9464",
    zy: "2,73",
    zy_global: "44736,68472",
    zz: "4,93",
    zz_global: "80788,22552",
  },
  {
    profile_section_id: 1091,
    name: "HSS7X2X1/2",
    type: "HSS Rectangle",
    designation: "HSST7X2X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "3,84",
    ax_global: "2477,4144",
    b: 2,
    b_global: "50,8",
    c: "5,52",
    c_global: "90456,59328",
    d: 7,
    d_global: "177,8",
    ix: "7,95",
    ix_global: "3309039,834",
    iy: "2,58",
    iy_global: "1073877,078",
    iz: "19,8",
    iz_global: "8241382,227",
    t: "0,233",
    t_global: "5,9182",
    zy: "3,02",
    zy_global: "49488,93328",
    zz: "7,64",
    zz_global: "125197,169",
  },
  {
    profile_section_id: 1092,
    name: "HSS7X2X3/16",
    type: "HSS Rectangle",
    designation: "HSST7X2X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "2,93",
    ax_global: "1890,3188",
    b: 2,
    b_global: "50,8",
    c: "4,32",
    c_global: "70792,11648",
    d: 7,
    d_global: "177,8",
    ix: "6,35",
    ix_global: "2643069,553",
    iy: "2,1",
    iy_global: "874085,9938",
    iz: "15,7",
    iz_global: "6534833,382",
    t: "0,174",
    t_global: "4,4196",
    zy: "2,39",
    zy_global: "39165,08296",
    zz: "5,95",
    zz_global: "97503,0308",
  },
  {
    profile_section_id: 1093,
    name: "HSS7X2X1/4",
    type: "HSS Rectangle",
    designation: "HSST7X2X0.25",
    shape: "RHS",
    country_code: "American",
    ax: 2,
    ax_global: "1290,32",
    b: 2,
    b_global: "50,8",
    c: 3,
    c_global: "49161,192",
    d: 7,
    d_global: "177,8",
    ix: "4,51",
    ix_global: "1877203,729",
    iy: "1,52",
    iy_global: "632671,7669",
    iz: "11,1",
    iz_global: "4620168,824",
    t: "0,116",
    t_global: "2,9464",
    zy: "1,68",
    zy_global: "27530,26752",
    zz: "4,13",
    zz_global: "67678,57432",
  },
  {
    profile_section_id: 1094,
    name: "HSS6X6X5/8",
    type: "HSS Rectangle",
    designation: "HSST6X6X0.625",
    shape: "RHS",
    country_code: "American",
    ax: "11,7",
    ax_global: "7548,372",
    b: 6,
    b_global: "152,4",
    c: "33,4",
    c_global: "547327,9376",
    d: 6,
    d_global: "152,4",
    ix: "94,9",
    ix_global: "39500362,29",
    iy: "55,2",
    iy_global: "22975974,69",
    iz: "55,2",
    iz_global: "22975974,69",
    t: "0,581",
    t_global: "14,7574",
    zy: "23,2",
    zy_global: "380179,8848",
    zz: "23,2",
    zz_global: "380179,8848",
  },
  {
    profile_section_id: 1095,
    name: "HSS6X6X1/2",
    type: "HSS Rectangle",
    designation: "HSST6X6X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "9,74",
    ax_global: "6283,8584",
    b: 6,
    b_global: "152,4",
    c: "28,1",
    c_global: "460476,4984",
    d: 6,
    d_global: "152,4",
    ix: "81,1",
    ix_global: "33756368,62",
    iy: "48,3",
    iy_global: "20103977,86",
    iz: "48,3",
    iz_global: "20103977,86",
    t: "0,465",
    t_global: "11,811",
    zy: "19,8",
    zy_global: "324463,8672",
    zz: "19,8",
    zz_global: "324463,8672",
  },
  {
    profile_section_id: 1096,
    name: "HSS6X6X3/8",
    type: "HSS Rectangle",
    designation: "HSST6X6X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "7,58",
    ax_global: "4890,3128",
    b: 6,
    b_global: "152,4",
    c: "22,1",
    c_global: "362154,1144",
    d: 6,
    d_global: "152,4",
    ix: "64,6",
    ix_global: "26888550,09",
    iy: "39,5",
    iy_global: "16441141,31",
    iz: "39,5",
    iz_global: "16441141,31",
    t: "0,349",
    t_global: "8,8646",
    zy: "15,8",
    zy_global: "258915,6112",
    zz: "15,8",
    zz_global: "258915,6112",
  },
  {
    profile_section_id: 1097,
    name: "HSS6X6X5/16",
    type: "HSS Rectangle",
    designation: "HSST6X6X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "6,43",
    ax_global: "4148,3788",
    b: 6,
    b_global: "152,4",
    c: "18,9",
    c_global: "309715,5096",
    d: 6,
    d_global: "152,4",
    ix: "55,4",
    ix_global: "23059220,98",
    iy: "34,3",
    iy_global: "14276737,9",
    iz: "34,3",
    iz_global: "14276737,9",
    t: "0,291",
    t_global: "7,3914",
    zy: "13,6",
    zy_global: "222864,0704",
    zz: "13,6",
    zz_global: "222864,0704",
  },
  {
    profile_section_id: 1098,
    name: "HSS6X6X1/4",
    type: "HSS Rectangle",
    designation: "HSST6X6X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "5,24",
    ax_global: "3380,6384",
    b: 6,
    b_global: "152,4",
    c: "15,4",
    c_global: "252360,7856",
    d: 6,
    d_global: "152,4",
    ix: "45,6",
    ix_global: "18980153,01",
    iy: "28,6",
    iy_global: "11904218,77",
    iz: "28,6",
    iz_global: "11904218,77",
    t: "0,233",
    t_global: "5,9182",
    zy: "11,2",
    zy_global: "183535,1168",
    zz: "11,2",
    zz_global: "183535,1168",
  },
  {
    profile_section_id: 1099,
    name: "HSS6X6X3/16",
    type: "HSS Rectangle",
    designation: "HSST6X6X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,98",
    ax_global: "2567,7368",
    b: 6,
    b_global: "152,4",
    c: "11,8",
    c_global: "193367,3552",
    d: 6,
    d_global: "152,4",
    ix: 35,
    ix_global: "14568099,9",
    iy: "22,3",
    iy_global: "9281960,791",
    iz: "22,3",
    iz_global: "9281960,791",
    t: "0,174",
    t_global: "4,4196",
    zy: "8,63",
    zy_global: "141420,3623",
    zz: "8,63",
    zz_global: "141420,3623",
  },
  {
    profile_section_id: 1100,
    name: "HSS6X6X1/8",
    type: "HSS Rectangle",
    designation: "HSST6X6X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,7",
    ax_global: "1741,932",
    b: 6,
    b_global: "152,4",
    c: "8,03",
    c_global: "131588,1239",
    d: 6,
    d_global: "152,4",
    ix: "23,9",
    ix_global: "9947931,072",
    iy: "15,5",
    iy_global: "6451587,097",
    iz: "15,5",
    iz_global: "6451587,097",
    t: "0,116",
    t_global: "2,9464",
    zy: "5,92",
    zy_global: "97011,41888",
    zz: "5,92",
    zz_global: "97011,41888",
  },
  {
    profile_section_id: 1101,
    name: "HSS6X5X1/2",
    type: "HSS Rectangle",
    designation: "HSST6X5X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "8,81",
    ax_global: "5683,8596",
    b: 5,
    b_global: 127,
    c: 23,
    c_global: "376902,472",
    d: 6,
    d_global: "152,4",
    ix: "59,8",
    ix_global: "24890639,25",
    iy: "30,8",
    iy_global: "12819927,91",
    iz: "41,1",
    iz_global: "17107111,59",
    t: "0,465",
    t_global: "11,811",
    zy: "15,2",
    zy_global: "249083,3728",
    zz: "17,2",
    zz_global: "281857,5008",
  },
  {
    profile_section_id: 1102,
    name: "HSS6X5X3/8",
    type: "HSS Rectangle",
    designation: "HSST6X5X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "6,88",
    ax_global: "4438,7008",
    b: 5,
    b_global: 127,
    c: "18,2",
    c_global: "298244,5648",
    d: 6,
    d_global: "152,4",
    ix: "48,1",
    ix_global: "20020731,57",
    iy: "25,5",
    iy_global: "10613901,35",
    iz: "33,9",
    iz_global: "14110245,33",
    t: "0,349",
    t_global: "8,8646",
    zy: "12,2",
    zy_global: "199922,1808",
    zz: "13,8",
    zz_global: "226141,4832",
  },
  {
    profile_section_id: 1103,
    name: "HSS6X5X5/16",
    type: "HSS Rectangle",
    designation: "HSST6X5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "5,85",
    ax_global: "3774,186",
    b: 5,
    b_global: 127,
    c: "15,6",
    c_global: "255638,1984",
    d: 6,
    d_global: "152,4",
    ix: "41,4",
    ix_global: "17231981,02",
    iy: "22,3",
    iy_global: "9281960,791",
    iz: "29,6",
    iz_global: "12320450,2",
    t: "0,291",
    t_global: "7,3914",
    zy: "10,5",
    zy_global: "172064,172",
    zz: "11,9",
    zz_global: "195006,0616",
  },
  {
    profile_section_id: 1104,
    name: "HSS6X5X1/4",
    type: "HSS Rectangle",
    designation: "HSST6X5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "4,77",
    ax_global: "3077,4132",
    b: 5,
    b_global: 127,
    c: "12,8",
    c_global: "209754,4192",
    d: 6,
    d_global: "152,4",
    ix: "34,2",
    ix_global: "14235114,76",
    iy: "18,7",
    iy_global: "7783527,659",
    iz: "24,7",
    iz_global: "10280916,21",
    t: "0,233",
    t_global: "5,9182",
    zy: "8,72",
    zy_global: "142895,1981",
    zz: "9,87",
    zz_global: "161740,3217",
  },
  {
    profile_section_id: 1105,
    name: "HSS6X5X3/16",
    type: "HSS Rectangle",
    designation: "HSST6X5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,63",
    ax_global: "2341,9308",
    b: 5,
    b_global: 127,
    c: "9,76",
    c_global: "159937,7446",
    d: 6,
    d_global: "152,4",
    ix: "26,3",
    ix_global: "10946886,49",
    iy: "14,6",
    iy_global: "6076978,814",
    iz: "19,3",
    iz_global: "8033266,514",
    t: "0,174",
    t_global: "4,4196",
    zy: "6,73",
    zy_global: "110284,9407",
    zz: "7,62",
    zz_global: "124869,4277",
  },
  {
    profile_section_id: 1106,
    name: "HSS6X5X1/8",
    type: "HSS Rectangle",
    designation: "HSST6X5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,46",
    ax_global: "1587,0936",
    b: 5,
    b_global: 127,
    c: "6,66",
    c_global: "109137,8462",
    d: 6,
    d_global: "152,4",
    ix: 18,
    ix_global: "7492165,661",
    iy: "10,2",
    iy_global: "4245560,541",
    iz: "13,4",
    iz_global: "5577501,103",
    t: "0,116",
    t_global: "2,9464",
    zy: "4,63",
    zy_global: "75872,10632",
    zz: "5,24",
    zz_global: "85868,21536",
  },
  {
    profile_section_id: 1107,
    name: "HSS6X4X1/2",
    type: "HSS Rectangle",
    designation: "HSST6X4X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "7,88",
    ax_global: "5083,8608",
    b: 4,
    b_global: "101,6",
    c: "17,8",
    c_global: "291689,7392",
    d: 6,
    d_global: "152,4",
    ix: "40,3",
    ix_global: "16774126,45",
    iy: "17,8",
    iy_global: "7408919,376",
    iz: 34,
    iz_global: "14151868,47",
    t: "0,465",
    t_global: "11,811",
    zy: 11,
    zy_global: "180257,704",
    zz: "14,6",
    zz_global: "239251,1344",
  },
  {
    profile_section_id: 1108,
    name: "HSS6X4X3/8",
    type: "HSS Rectangle",
    designation: "HSST6X4X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "6,18",
    ax_global: "3987,0888",
    b: 4,
    b_global: "101,6",
    c: "14,2",
    c_global: "232696,3088",
    d: 6,
    d_global: "152,4",
    ix: "32,8",
    ix_global: "13652390,76",
    iy: "14,9",
    iy_global: "6201848,241",
    iz: "28,3",
    iz_global: "11779349,34",
    t: "0,349",
    t_global: "8,8646",
    zy: "8,94",
    zy_global: "146500,3522",
    zz: "11,9",
    zz_global: "195006,0616",
  },
  {
    profile_section_id: 1109,
    name: "HSS6X4X5/16",
    type: "HSS Rectangle",
    designation: "HSST6X4X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "5,26",
    ax_global: "3393,5416",
    b: 4,
    b_global: "101,6",
    c: "12,2",
    c_global: "199922,1808",
    d: 6,
    d_global: "152,4",
    ix: "28,4",
    ix_global: "11820972,49",
    iy: "13,2",
    iy_global: "5494254,818",
    iz: "24,8",
    iz_global: "10322539,35",
    t: "0,291",
    t_global: "7,3914",
    zy: "7,75",
    zy_global: "126999,746",
    zz: "10,3",
    zz_global: "168786,7592",
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: p.ax ? fixValueToNumber(p.ax, "float") : null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: p.b ? fixValueToNumber(p.b, "float") : null,
    b_global: p.b_global ? fixValueToNumber(p.b_global, "float") : undefined,
    bf: p.bf ? fixValueToNumber(p.bf, "float") : null,
    bf_global: p.bf_global ? fixValueToNumber(p.bf_global, "float") : undefined,
    c: p.c ? fixValueToNumber(p.c, "float") : null,
    c_global: p.c_global ? fixValueToNumber(p.c_global, "float") : undefined,
    ct: p.ct ? fixValueToNumber(p.ct, "float") : null,
    ct_global: p.ct_global ? fixValueToNumber(p.ct_global, "float") : undefined,
    d: p.d ? fixValueToNumber(p.d, "float") : null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: null,
    i: null,
    ix: p.ix ? fixValueToNumber(p.ix, "float") : null,
    ix_global: p.ix_global ? fixValueToNumber(p.ix_global, "float") : undefined,
    iy: p.iy ? fixValueToNumber(p.iy, "float") : null,
    iy_global: p.iy_global ? fixValueToNumber(p.iy_global, "float") : undefined,
    iz: p.iz ? fixValueToNumber(p.iz, "float") : null,
    iz_global: p.iz_global ? fixValueToNumber(p.iz_global, "float") : undefined,
    k: p.k ? fixValueToNumber(p.k, "float") : null,
    k_global: p.k_global ? fixValueToNumber(p.k_global, "float") : undefined,
    k1: null,
    od: null,
    r1: p.r1 ? fixValueToNumber(p.r1, "float") : null,
    r1_global: p.r1_global ? fixValueToNumber(p.r1_global, "float") : undefined,
    r2: null,
    rz: p.rz ? fixValueToNumber(p.rz, "float") : null,
    rz_global: p.rz_global ? fixValueToNumber(p.rz_global, "float") : undefined,
    t: p.t ? fixValueToNumber(p.t, "float") : null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: p.tf ? fixValueToNumber(p.tf, "float") : null,
    tf_global: p.tf_global ? fixValueToNumber(p.tf_global, "float") : undefined,
    tw: p.tw ? fixValueToNumber(p.tw, "float") : null,
    tw_global: p.tw_global ? fixValueToNumber(p.tw_global, "float") : undefined,
    z: null,
    zx: p.zx ? fixValueToNumber(p.zx, "float") : null,
    zx_global: p.zx_global ? fixValueToNumber(p.zx_global, "float") : undefined,
    zy: p.zy ? fixValueToNumber(p.zy, "float") : null,
    zy_global: p.zy_global ? fixValueToNumber(p.zy_global, "float") : undefined,
    zz: p.zz ? fixValueToNumber(p.zz, "float") : null,
    zz_global: p.zz_global ? fixValueToNumber(p.zz_global, "float") : undefined,
    width: fixValueToNumber(p.width, "float"),
    width_global: fixValueToNumber(p.width_global, "float"),
    thickness: fixValueToNumber(p.thickness, "float"),
    thickness_global: p.thickness_global
      ? fixValueToNumber(p.thickness_global, "float")
      : undefined,
    height: fixValueToNumber(p.height, "float"),
    height_global: fixValueToNumber(p.height_global, "float"),
  };
  return profile;
});
