import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesBritish = [
  {
    profile_section_id: 1261,
    name: "UB1016X305X487",
    type: "UB Shape",
    designation: "UB1016X305X487",
    shape: "I",
    country_code: "British",
    ax: 620,
    ax_global: 62000,
    bf: "308,5",
    bf_global: "308,5",
    ct: "14,4",
    ct_global: 144,
    d: "1036,3",
    d_global: "1036,3",
    ix: 4300,
    ix_global: 43000000,
    iy: 26700,
    iy_global: 267000000,
    iz: 1020000,
    iz_global: 10200000000,
    tf: "54,1",
    tf_global: "54,1",
    tw: 30,
    tw_global: 30,
    zx: 23200,
    zx_global: 23200000,
    zy: 2800,
    zy_global: 2800000,
    width: "308,5",
    width_global: "308,5",
    thickness: "54,1",
    thickness_global: "54,1",
    height: "1036,3",
    height_global: "1036,3",
  },
  {
    profile_section_id: 1262,
    name: "UB1016X305X437",
    type: "UB Shape",
    designation: "UB1016X305X437",
    shape: "I",
    country_code: "British",
    ax: 557,
    ax_global: 55700,
    bf: "305,4",
    bf_global: "305,4",
    ct: 14,
    ct_global: 140,
    d: "1026,1",
    d_global: "1026,1",
    ix: 3190,
    ix_global: 31900000,
    iy: 23400,
    iy_global: 234000000,
    iz: 910000,
    iz_global: 9100000000,
    tf: 49,
    tf_global: 49,
    tw: "26,9",
    tw_global: "26,9",
    zx: 20800,
    zx_global: 20800000,
    zy: 2470,
    zy_global: 2470000,
    width: "305,4",
    width_global: "305,4",
    thickness: 49,
    thickness_global: 49,
    height: "1026,1",
    height_global: "1026,1",
  },
  {
    profile_section_id: 1263,
    name: "UB1016X305X393",
    type: "UB Shape",
    designation: "UB1016X305X393",
    shape: "I",
    country_code: "British",
    ax: 500,
    ax_global: 50000,
    bf: 303,
    bf_global: 303,
    ct: "13,8",
    ct_global: 138,
    d: "1015,9",
    d_global: "1015,9",
    ix: 2330,
    ix_global: 23300000,
    iy: 20500,
    iy_global: 205000000,
    iz: 808000,
    iz_global: 8080000000,
    tf: "43,9",
    tf_global: "43,9",
    tw: "24,4",
    tw_global: "24,4",
    zx: 18500,
    zx_global: 18500000,
    zy: 2170,
    zy_global: 2170000,
    width: 303,
    width_global: 303,
    thickness: "43,9",
    thickness_global: "43,9",
    height: "1015,9",
    height_global: "1015,9",
  },
  {
    profile_section_id: 1264,
    name: "UB1016X305X349",
    type: "UB Shape",
    designation: "UB1016X305X349",
    shape: "I",
    country_code: "British",
    ax: 445,
    ax_global: 44500,
    bf: 302,
    bf_global: 302,
    ct: "13,1",
    ct_global: 131,
    d: "1008,1",
    d_global: "1008,1",
    ix: 1720,
    ix_global: 17200000,
    iy: 18500,
    iy_global: 185000000,
    iz: 723000,
    iz_global: 7230000000,
    tf: 40,
    tf_global: 40,
    tw: "21,1",
    tw_global: "21,1",
    zx: 16600,
    zx_global: 16600000,
    zy: 1940,
    zy_global: 1940000,
    width: 302,
    width_global: 302,
    thickness: 40,
    thickness_global: 40,
    height: "1008,1",
    height_global: "1008,1",
  },
  {
    profile_section_id: 1265,
    name: "UB1016X305X314",
    type: "UB Shape",
    designation: "UB1016X305X314",
    shape: "I",
    country_code: "British",
    ax: 400,
    ax_global: 40000,
    bf: 300,
    bf_global: 300,
    ct: "12,9",
    ct_global: 129,
    d: "999,9",
    d_global: "999,9",
    ix: 1260,
    ix_global: 12600000,
    iy: 16200,
    iy_global: 162000000,
    iz: 644000,
    iz_global: 6440000000,
    tf: "35,9",
    tf_global: "35,9",
    tw: "19,1",
    tw_global: "19,1",
    zx: 14800,
    zx_global: 14800000,
    zy: 1710,
    zy_global: 1710000,
    width: 300,
    width_global: 300,
    thickness: "35,9",
    thickness_global: "35,9",
    height: "999,9",
    height_global: "999,9",
  },
  {
    profile_section_id: 1266,
    name: "UB1016X305X272",
    type: "UB Shape",
    designation: "UB1016X305X272",
    shape: "I",
    country_code: "British",
    ax: 347,
    ax_global: 34700,
    bf: 300,
    bf_global: 300,
    ct: "12,5",
    ct_global: 125,
    d: "990,1",
    d_global: "990,1",
    ix: 835,
    ix_global: 8350000,
    iy: 14000,
    iy_global: 140000000,
    iz: 554000,
    iz_global: 5540000000,
    tf: 31,
    tf_global: 31,
    tw: "16,5",
    tw_global: "16,5",
    zx: 12800,
    zx_global: 12800000,
    zy: 1470,
    zy_global: 1470000,
    width: 300,
    width_global: 300,
    thickness: 31,
    thickness_global: 31,
    height: "990,1",
    height_global: "990,1",
  },
  {
    profile_section_id: 1267,
    name: "UB1016X305X249",
    type: "UB Shape",
    designation: "UB1016X305X249",
    shape: "I",
    country_code: "British",
    ax: 317,
    ax_global: 31700,
    bf: 300,
    bf_global: 300,
    ct: "13,2",
    ct_global: 132,
    d: "980,1",
    d_global: "980,1",
    ix: 582,
    ix_global: 5820000,
    iy: 11800,
    iy_global: 118000000,
    iz: 481000,
    iz_global: 4810000000,
    tf: 26,
    tf_global: 26,
    tw: "16,5",
    tw_global: "16,5",
    zx: 11300,
    zx_global: 11300000,
    zy: 1240,
    zy_global: 1240000,
    width: 300,
    width_global: 300,
    thickness: 26,
    thickness_global: 26,
    height: "980,1",
    height_global: "980,1",
  },
  {
    profile_section_id: 1268,
    name: "UB1016X305X222",
    type: "UB Shape",
    designation: "UB1016X305X222",
    shape: "I",
    country_code: "British",
    ax: 283,
    ax_global: 28300,
    bf: 300,
    bf_global: 300,
    ct: "13,8",
    ct_global: 138,
    d: "970,3",
    d_global: "970,3",
    ix: 390,
    ix_global: 3900000,
    iy: 9550,
    iy_global: 95500000,
    iz: 408000,
    iz_global: 4080000000,
    tf: "21,1",
    tf_global: "21,1",
    tw: 16,
    tw_global: 16,
    zx: 9810,
    zx_global: 9810000,
    zy: 1020,
    zy_global: 1020000,
    width: 300,
    width_global: 300,
    thickness: "21,1",
    thickness_global: "21,1",
    height: "970,3",
    height_global: "970,3",
  },
  {
    profile_section_id: 1269,
    name: "UB914X419X388",
    type: "UB Shape",
    designation: "UB914X419X388",
    shape: "I",
    country_code: "British",
    ax: 494,
    ax_global: 49400,
    bf: "420,5",
    bf_global: "420,5",
    ct: "10,3",
    ct_global: 103,
    d: 921,
    d_global: 921,
    ix: 1730,
    ix_global: 17300000,
    iy: 45400,
    iy_global: 454000000,
    iz: 720000,
    iz_global: 7200000000,
    tf: "36,6",
    tf_global: "36,6",
    tw: "21,4",
    tw_global: "21,4",
    zx: 17700,
    zx_global: 17700000,
    zy: 3340,
    zy_global: 3340000,
    width: "420,5",
    width_global: "420,5",
    thickness: "36,6",
    thickness_global: "36,6",
    height: 921,
    height_global: 921,
  },
  {
    profile_section_id: 1270,
    name: "UB914X419X343",
    type: "UB Shape",
    designation: "UB914X419X343",
    shape: "I",
    country_code: "British",
    ax: 437,
    ax_global: 43700,
    bf: "418,5",
    bf_global: "418,5",
    ct: "10,2",
    ct_global: 102,
    d: "911,8",
    d_global: "911,8",
    ix: 1190,
    ix_global: 11900000,
    iy: 39200,
    iy_global: 392000000,
    iz: 626000,
    iz_global: 6260000000,
    tf: 32,
    tf_global: 32,
    tw: "19,4",
    tw_global: "19,4",
    zx: 15500,
    zx_global: 15500000,
    zy: 2890,
    zy_global: 2890000,
    width: "418,5",
    width_global: "418,5",
    thickness: 32,
    thickness_global: 32,
    height: "911,8",
    height_global: "911,8",
  },
  {
    profile_section_id: 1271,
    name: "UB914X305X289",
    type: "UB Shape",
    designation: "UB914X305X289",
    shape: "I",
    country_code: "British",
    ax: 368,
    ax_global: 36800,
    bf: "307,7",
    bf_global: "307,7",
    ct: "12,2",
    ct_global: 122,
    d: "926,6",
    d_global: "926,6",
    ix: 926,
    ix_global: 9260000,
    iy: 15600,
    iy_global: 156000000,
    iz: 504000,
    iz_global: 5040000000,
    tf: 32,
    tf_global: 32,
    tw: "19,5",
    tw_global: "19,5",
    zx: 12600,
    zx_global: 12600000,
    zy: 1600,
    zy_global: 1600000,
    width: "307,7",
    width_global: "307,7",
    thickness: 32,
    thickness_global: 32,
    height: "926,6",
    height_global: "926,6",
  },
  {
    profile_section_id: 1272,
    name: "UB914X305X253",
    type: "UB Shape",
    designation: "UB914X305X253",
    shape: "I",
    country_code: "British",
    ax: 323,
    ax_global: 32300,
    bf: "305,5",
    bf_global: "305,5",
    ct: 12,
    ct_global: 120,
    d: "918,4",
    d_global: "918,4",
    ix: 626,
    ix_global: 6260000,
    iy: 13300,
    iy_global: 133000000,
    iz: 436000,
    iz_global: 4360000000,
    tf: "27,9",
    tf_global: "27,9",
    tw: "17,3",
    tw_global: "17,3",
    zx: 10900,
    zx_global: 10900000,
    zy: 1370,
    zy_global: 1370000,
    width: "305,5",
    width_global: "305,5",
    thickness: "27,9",
    thickness_global: "27,9",
    height: "918,4",
    height_global: "918,4",
  },
  {
    profile_section_id: 1273,
    name: "UB914X305X224",
    type: "UB Shape",
    designation: "UB914X305X224",
    shape: "I",
    country_code: "British",
    ax: 286,
    ax_global: 28600,
    bf: "304,1",
    bf_global: "304,1",
    ct: "12,1",
    ct_global: 121,
    d: "910,4",
    d_global: "910,4",
    ix: 422,
    ix_global: 4220000,
    iy: 11200,
    iy_global: 112000000,
    iz: 376000,
    iz_global: 3760000000,
    tf: "23,9",
    tf_global: "23,9",
    tw: "15,9",
    tw_global: "15,9",
    zx: 9530,
    zx_global: 9530000,
    zy: 1160,
    zy_global: 1160000,
    width: "304,1",
    width_global: "304,1",
    thickness: "23,9",
    thickness_global: "23,9",
    height: "910,4",
    height_global: "910,4",
  },
  {
    profile_section_id: 1274,
    name: "UB914X305X201",
    type: "UB Shape",
    designation: "UB914X305X201",
    shape: "I",
    country_code: "British",
    ax: 256,
    ax_global: 25600,
    bf: "303,3",
    bf_global: "303,3",
    ct: "12,5",
    ct_global: 125,
    d: 903,
    d_global: 903,
    ix: 291,
    ix_global: 2910000,
    iy: 9420,
    iy_global: 94200000,
    iz: 325000,
    iz_global: 3250000000,
    tf: "20,2",
    tf_global: "20,2",
    tw: "15,1",
    tw_global: "15,1",
    zx: 8350,
    zx_global: 8350000,
    zy: 982,
    zy_global: 982000,
    width: "303,3",
    width_global: "303,3",
    thickness: "20,2",
    thickness_global: "20,2",
    height: 903,
    height_global: 903,
  },
  {
    profile_section_id: 1275,
    name: "UB838X292X226",
    type: "UB Shape",
    designation: "UB838X292X226",
    shape: "I",
    country_code: "British",
    ax: 289,
    ax_global: 28900,
    bf: "293,8",
    bf_global: "293,8",
    ct: "10,8",
    ct_global: 108,
    d: "850,9",
    d_global: "850,9",
    ix: 514,
    ix_global: 5140000,
    iy: 11400,
    iy_global: 114000000,
    iz: 340000,
    iz_global: 3400000000,
    tf: "26,8",
    tf_global: "26,8",
    tw: "16,1",
    tw_global: "16,1",
    zx: 9160,
    zx_global: 9160000,
    zy: 1210,
    zy_global: 1210000,
    width: "293,8",
    width_global: "293,8",
    thickness: "26,8",
    thickness_global: "26,8",
    height: "850,9",
    height_global: "850,9",
  },
  {
    profile_section_id: 1276,
    name: "UB838X292X194",
    type: "UB Shape",
    designation: "UB838X292X194",
    shape: "I",
    country_code: "British",
    ax: 247,
    ax_global: 24700,
    bf: "292,4",
    bf_global: "292,4",
    ct: "11,1",
    ct_global: 111,
    d: "840,7",
    d_global: "840,7",
    ix: 306,
    ix_global: 3060000,
    iy: 9070,
    iy_global: 90700000,
    iz: 279000,
    iz_global: 2790000000,
    tf: "21,7",
    tf_global: "21,7",
    tw: "14,7",
    tw_global: "14,7",
    zx: 7640,
    zx_global: 7640000,
    zy: 974,
    zy_global: 974000,
    width: "292,4",
    width_global: "292,4",
    thickness: "21,7",
    thickness_global: "21,7",
    height: "840,7",
    height_global: "840,7",
  },
  {
    profile_section_id: 1277,
    name: "UB838X292X176",
    type: "UB Shape",
    designation: "UB838X292X176",
    shape: "I",
    country_code: "British",
    ax: 224,
    ax_global: 22400,
    bf: "291,7",
    bf_global: "291,7",
    ct: "11,4",
    ct_global: 114,
    d: "834,9",
    d_global: "834,9",
    ix: 221,
    ix_global: 2210000,
    iy: 7800,
    iy_global: 78000000,
    iz: 246000,
    iz_global: 2460000000,
    tf: "18,8",
    tf_global: "18,8",
    tw: 14,
    tw_global: 14,
    zx: 6810,
    zx_global: 6810000,
    zy: 842,
    zy_global: 842000,
    width: "291,7",
    width_global: "291,7",
    thickness: "18,8",
    thickness_global: "18,8",
    height: "834,9",
    height_global: "834,9",
  },
  {
    profile_section_id: 1278,
    name: "UB762X267X197",
    type: "UB Shape",
    designation: "UB762X267X197",
    shape: "I",
    country_code: "British",
    ax: 251,
    ax_global: 25100,
    bf: 268,
    bf_global: 268,
    ct: "9,89",
    ct_global: "98,9",
    d: "769,8",
    d_global: "769,8",
    ix: 404,
    ix_global: 4040000,
    iy: 8170,
    iy_global: 81700000,
    iz: 240000,
    iz_global: 2400000000,
    tf: "25,4",
    tf_global: "25,4",
    tw: "15,6",
    tw_global: "15,6",
    zx: 7170,
    zx_global: 7170000,
    zy: 958,
    zy_global: 958000,
    width: 268,
    width_global: 268,
    thickness: "25,4",
    thickness_global: "25,4",
    height: "769,8",
    height_global: "769,8",
  },
  {
    profile_section_id: 1279,
    name: "UB762X267X173",
    type: "UB Shape",
    designation: "UB762X267X173",
    shape: "I",
    country_code: "British",
    ax: 220,
    ax_global: 22000,
    bf: "266,7",
    bf_global: "266,7",
    ct: "9,98",
    ct_global: "99,8",
    d: "762,2",
    d_global: "762,2",
    ix: 267,
    ix_global: 2670000,
    iy: 6850,
    iy_global: 68500000,
    iz: 205000,
    iz_global: 2050000000,
    tf: "21,6",
    tf_global: "21,6",
    tw: "14,3",
    tw_global: "14,3",
    zx: 6200,
    zx_global: 6200000,
    zy: 807,
    zy_global: 807000,
    width: "266,7",
    width_global: "266,7",
    thickness: "21,6",
    thickness_global: "21,6",
    height: "762,2",
    height_global: "762,2",
  },
  {
    profile_section_id: 1280,
    name: "UB762X267X147",
    type: "UB Shape",
    designation: "UB762X267X147",
    shape: "I",
    country_code: "British",
    ax: 187,
    ax_global: 18700,
    bf: "265,2",
    bf_global: "265,2",
    ct: "10,2",
    ct_global: 102,
    d: 754,
    d_global: 754,
    ix: 159,
    ix_global: 1590000,
    iy: 5460,
    iy_global: 54600000,
    iz: 169000,
    iz_global: 1690000000,
    tf: "17,5",
    tf_global: "17,5",
    tw: "12,8",
    tw_global: "12,8",
    zx: 5160,
    zx_global: 5160000,
    zy: 647,
    zy_global: 647000,
    width: "265,2",
    width_global: "265,2",
    thickness: "17,5",
    thickness_global: "17,5",
    height: 754,
    height_global: 754,
  },
  {
    profile_section_id: 1281,
    name: "UB762X267X134",
    type: "UB Shape",
    designation: "UB762X267X134",
    shape: "I",
    country_code: "British",
    ax: 171,
    ax_global: 17100,
    bf: "264,4",
    bf_global: "264,4",
    ct: "10,3",
    ct_global: 103,
    d: 750,
    d_global: 750,
    ix: 119,
    ix_global: 1190000,
    iy: 4790,
    iy_global: 47900000,
    iz: 151000,
    iz_global: 1510000000,
    tf: "15,5",
    tf_global: "15,5",
    tw: 12,
    tw_global: 12,
    zx: 4640,
    zx_global: 4640000,
    zy: 570,
    zy_global: 570000,
    width: "264,4",
    width_global: "264,4",
    thickness: "15,5",
    thickness_global: "15,5",
    height: 750,
    height_global: 750,
  },
  {
    profile_section_id: 1282,
    name: "UB686X254X170",
    type: "UB Shape",
    designation: "UB686X254X170",
    shape: "I",
    country_code: "British",
    ax: 217,
    ax_global: 21700,
    bf: "255,8",
    bf_global: "255,8",
    ct: "8,67",
    ct_global: "86,7",
    d: "692,9",
    d_global: "692,9",
    ix: 308,
    ix_global: 3080000,
    iy: 6630,
    iy_global: 66300000,
    iz: 170000,
    iz_global: 1700000000,
    tf: "23,7",
    tf_global: "23,7",
    tw: "14,5",
    tw_global: "14,5",
    zx: 5630,
    zx_global: 5630000,
    zy: 811,
    zy_global: 811000,
    width: "255,8",
    width_global: "255,8",
    thickness: "23,7",
    thickness_global: "23,7",
    height: "692,9",
    height_global: "692,9",
  },
  {
    profile_section_id: 1283,
    name: "UB686X254X152",
    type: "UB Shape",
    designation: "UB686X254X152",
    shape: "I",
    country_code: "British",
    ax: 194,
    ax_global: 19400,
    bf: "254,5",
    bf_global: "254,5",
    ct: "8,61",
    ct_global: "86,1",
    d: "687,5",
    d_global: "687,5",
    ix: 220,
    ix_global: 2200000,
    iy: 5780,
    iy_global: 57800000,
    iz: 150000,
    iz_global: 1500000000,
    tf: 21,
    tf_global: 21,
    tw: "13,2",
    tw_global: "13,2",
    zx: 5000,
    zx_global: 5000000,
    zy: 710,
    zy_global: 710000,
    width: "254,5",
    width_global: "254,5",
    thickness: 21,
    thickness_global: 21,
    height: "687,5",
    height_global: "687,5",
  },
  {
    profile_section_id: 1284,
    name: "UB686X254X140",
    type: "UB Shape",
    designation: "UB686X254X140",
    shape: "I",
    country_code: "British",
    ax: 178,
    ax_global: 17800,
    bf: "253,7",
    bf_global: "253,7",
    ct: "8,63",
    ct_global: "86,3",
    d: "683,5",
    d_global: "683,5",
    ix: 169,
    ix_global: 1690000,
    iy: 5180,
    iy_global: 51800000,
    iz: 136000,
    iz_global: 1360000000,
    tf: 19,
    tf_global: 19,
    tw: "12,4",
    tw_global: "12,4",
    zx: 4560,
    zx_global: 4560000,
    zy: 638,
    zy_global: 638000,
    width: "253,7",
    width_global: "253,7",
    thickness: 19,
    thickness_global: 19,
    height: "683,5",
    height_global: "683,5",
  },
  {
    profile_section_id: 1285,
    name: "UB686X254X125",
    type: "UB Shape",
    designation: "UB686X254X125",
    shape: "I",
    country_code: "British",
    ax: 159,
    ax_global: 15900,
    bf: 253,
    bf_global: 253,
    ct: "8,85",
    ct_global: "88,5",
    d: "677,9",
    d_global: "677,9",
    ix: 116,
    ix_global: 1160000,
    iy: 4380,
    iy_global: 43800000,
    iz: 118000,
    iz_global: 1180000000,
    tf: "16,2",
    tf_global: "16,2",
    tw: "11,7",
    tw_global: "11,7",
    zx: 3990,
    zx_global: 3990000,
    zy: 542,
    zy_global: 542000,
    width: 253,
    width_global: 253,
    thickness: "16,2",
    thickness_global: "16,2",
    height: "677,9",
    height_global: "677,9",
  },
  {
    profile_section_id: 1286,
    name: "UB610X305X238",
    type: "UB Shape",
    designation: "UB610X305X238",
    shape: "I",
    country_code: "British",
    ax: 303,
    ax_global: 30300,
    bf: "311,4",
    bf_global: "311,4",
    ct: "7,11",
    ct_global: "71,1",
    d: "635,8",
    d_global: "635,8",
    ix: 785,
    ix_global: 7850000,
    iy: 15800,
    iy_global: 158000000,
    iz: 209000,
    iz_global: 2090000000,
    tf: "31,4",
    tf_global: "31,4",
    tw: "18,4",
    tw_global: "18,4",
    zx: 7490,
    zx_global: 7490000,
    zy: 1570,
    zy_global: 1570000,
    width: "311,4",
    width_global: "311,4",
    thickness: "31,4",
    thickness_global: "31,4",
    height: "635,8",
    height_global: "635,8",
  },
  {
    profile_section_id: 1287,
    name: "UB610X305X179",
    type: "UB Shape",
    designation: "UB610X305X179",
    shape: "I",
    country_code: "British",
    ax: 228,
    ax_global: 22800,
    bf: "307,1",
    bf_global: "307,1",
    ct: "6,69",
    ct_global: "66,9",
    d: "620,2",
    d_global: "620,2",
    ix: 340,
    ix_global: 3400000,
    iy: 11400,
    iy_global: 114000000,
    iz: 153000,
    iz_global: 1530000000,
    tf: "23,6",
    tf_global: "23,6",
    tw: "14,1",
    tw_global: "14,1",
    zx: 5550,
    zx_global: 5550000,
    zy: 1140,
    zy_global: 1140000,
    width: "307,1",
    width_global: "307,1",
    thickness: "23,6",
    thickness_global: "23,6",
    height: "620,2",
    height_global: "620,2",
  },
  {
    profile_section_id: 1288,
    name: "UB610X305X149",
    type: "UB Shape",
    designation: "UB610X305X149",
    shape: "I",
    country_code: "British",
    ax: 190,
    ax_global: 19000,
    bf: "304,8",
    bf_global: "304,8",
    ct: "6,45",
    ct_global: "64,5",
    d: "612,4",
    d_global: "612,4",
    ix: 200,
    ix_global: 2000000,
    iy: 9310,
    iy_global: 93100000,
    iz: 126000,
    iz_global: 1260000000,
    tf: "19,7",
    tf_global: "19,7",
    tw: "11,8",
    tw_global: "11,8",
    zx: 4590,
    zx_global: 4590000,
    zy: 937,
    zy_global: 937000,
    width: "304,8",
    width_global: "304,8",
    thickness: "19,7",
    thickness_global: "19,7",
    height: "612,4",
    height_global: "612,4",
  },
  {
    profile_section_id: 1289,
    name: "UB610X229X140",
    type: "UB Shape",
    designation: "UB610X229X140",
    shape: "I",
    country_code: "British",
    ax: 178,
    ax_global: 17800,
    bf: "230,2",
    bf_global: "230,2",
    ct: "7,61",
    ct_global: "76,1",
    d: "617,2",
    d_global: "617,2",
    ix: 216,
    ix_global: 2160000,
    iy: 4510,
    iy_global: 45100000,
    iz: 112000,
    iz_global: 1120000000,
    tf: "22,1",
    tf_global: "22,1",
    tw: "13,1",
    tw_global: "13,1",
    zx: 4140,
    zx_global: 4140000,
    zy: 611,
    zy_global: 611000,
    width: "230,2",
    width_global: "230,2",
    thickness: "22,1",
    thickness_global: "22,1",
    height: "617,2",
    height_global: "617,2",
  },
  {
    profile_section_id: 1290,
    name: "UB610X229X125",
    type: "UB Shape",
    designation: "UB610X229X125",
    shape: "I",
    country_code: "British",
    ax: 159,
    ax_global: 15900,
    bf: 229,
    bf_global: 229,
    ct: "7,54",
    ct_global: "75,4",
    d: "612,2",
    d_global: "612,2",
    ix: 154,
    ix_global: 1540000,
    iy: 3930,
    iy_global: 39300000,
    iz: 98600,
    iz_global: 986000000,
    tf: "19,6",
    tf_global: "19,6",
    tw: "11,9",
    tw_global: "11,9",
    zx: 3680,
    zx_global: 3680000,
    zy: 535,
    zy_global: 535000,
    width: 229,
    width_global: 229,
    thickness: "19,6",
    thickness_global: "19,6",
    height: "612,2",
    height_global: "612,2",
  },
  {
    profile_section_id: 1291,
    name: "UB610X229X113",
    type: "UB Shape",
    designation: "UB610X229X113",
    shape: "I",
    country_code: "British",
    ax: 144,
    ax_global: 14400,
    bf: "228,2",
    bf_global: "228,2",
    ct: "7,58",
    ct_global: "75,8",
    d: "607,6",
    d_global: "607,6",
    ix: 111,
    ix_global: 1110000,
    iy: 3430,
    iy_global: 34300000,
    iz: 87300,
    iz_global: 873000000,
    tf: "17,3",
    tf_global: "17,3",
    tw: "11,1",
    tw_global: "11,1",
    zx: 3280,
    zx_global: 3280000,
    zy: 469,
    zy_global: 469000,
    width: "228,2",
    width_global: "228,2",
    thickness: "17,3",
    thickness_global: "17,3",
    height: "607,6",
    height_global: "607,6",
  },
  {
    profile_section_id: 1292,
    name: "UB610X229X101",
    type: "UB Shape",
    designation: "UB610X229X101",
    shape: "I",
    country_code: "British",
    ax: 129,
    ax_global: 12900,
    bf: "227,6",
    bf_global: "227,6",
    ct: "7,78",
    ct_global: "77,8",
    d: "602,6",
    d_global: "602,6",
    ix: 77,
    ix_global: 770000,
    iy: 2910,
    iy_global: 29100000,
    iz: 75800,
    iz_global: 758000000,
    tf: "14,8",
    tf_global: "14,8",
    tw: "10,5",
    tw_global: "10,5",
    zx: 2880,
    zx_global: 2880000,
    zy: 400,
    zy_global: 400000,
    width: "227,6",
    width_global: "227,6",
    thickness: "14,8",
    thickness_global: "14,8",
    height: "602,6",
    height_global: "602,6",
  },
  {
    profile_section_id: 1293,
    name: "UB610X178X100",
    type: "UB Shape",
    designation: "UB610X178X100",
    shape: "I",
    country_code: "British",
    ax: 128,
    ax_global: 12800,
    bf: "179,2",
    bf_global: "179,2",
    ct: "8,57",
    ct_global: "85,7",
    d: "607,4",
    d_global: "607,4",
    ix: 95,
    ix_global: 950000,
    iy: 1660,
    iy_global: 16600000,
    iz: 72500,
    iz_global: 725000000,
    tf: "17,2",
    tf_global: "17,2",
    tw: "11,3",
    tw_global: "11,3",
    zx: 2790,
    zx_global: 2790000,
    zy: 296,
    zy_global: 296000,
    width: "179,2",
    width_global: "179,2",
    thickness: "17,2",
    thickness_global: "17,2",
    height: "607,4",
    height_global: "607,4",
  },
  {
    profile_section_id: 1294,
    name: "UB610X178X92",
    type: "UB Shape",
    designation: "UB610X178X92",
    shape: "I",
    country_code: "British",
    ax: 117,
    ax_global: 11700,
    bf: "178,8",
    bf_global: "178,8",
    ct: "8,78",
    ct_global: "87,8",
    d: 603,
    d_global: 603,
    ix: 71,
    ix_global: 710000,
    iy: 1440,
    iy_global: 14400000,
    iz: 64600,
    iz_global: 646000000,
    tf: 15,
    tf_global: 15,
    tw: "10,9",
    tw_global: "10,9",
    zx: 2510,
    zx_global: 2510000,
    zy: 258,
    zy_global: 258000,
    width: "178,8",
    width_global: "178,8",
    thickness: 15,
    thickness_global: 15,
    height: 603,
    height_global: 603,
  },
  {
    profile_section_id: 1295,
    name: "UB610X178X82",
    type: "UB Shape",
    designation: "UB610X178X82",
    shape: "I",
    country_code: "British",
    ax: 104,
    ax_global: 10400,
    bf: "177,9",
    bf_global: "177,9",
    ct: "8,88",
    ct_global: "88,8",
    d: "598,6",
    d_global: "598,6",
    ix: "48,8",
    ix_global: 488000,
    iy: 1210,
    iy_global: 12100000,
    iz: 55900,
    iz_global: 559000000,
    tf: "12,8",
    tf_global: "12,8",
    tw: 10,
    tw_global: 10,
    zx: 2190,
    zx_global: 2190000,
    zy: 218,
    zy_global: 218000,
    width: "177,9",
    width_global: "177,9",
    thickness: "12,8",
    thickness_global: "12,8",
    height: "598,6",
    height_global: "598,6",
  },
  {
    profile_section_id: 1296,
    name: "UB533X312X272",
    type: "UB Shape",
    designation: "UB533X312X272",
    shape: "I",
    country_code: "British",
    ax: 348,
    ax_global: 34800,
    bf: "320,2",
    bf_global: "320,2",
    ct: "6,28",
    ct_global: "62,8",
    d: "577,1",
    d_global: "577,1",
    ix: 1290,
    ix_global: 12900000,
    iy: 20600,
    iy_global: 206000000,
    iz: 199000,
    iz_global: 1990000000,
    tf: "37,6",
    tf_global: "37,6",
    tw: "21,1",
    tw_global: "21,1",
    zx: 7870,
    zx_global: 7870000,
    zy: 1990,
    zy_global: 1990000,
    width: "320,2",
    width_global: "320,2",
    thickness: "37,6",
    thickness_global: "37,6",
    height: "577,1",
    height_global: "577,1",
  },
  {
    profile_section_id: 1297,
    name: "UB533X312X219",
    type: "UB Shape",
    designation: "UB533X312X219",
    shape: "I",
    country_code: "British",
    ax: 279,
    ax_global: 27900,
    bf: "317,4",
    bf_global: "317,4",
    ct: "6,09",
    ct_global: "60,9",
    d: "560,3",
    d_global: "560,3",
    ix: 642,
    ix_global: 6420000,
    iy: 15600,
    iy_global: 156000000,
    iz: 151000,
    iz_global: 1510000000,
    tf: "29,2",
    tf_global: "29,2",
    tw: "18,3",
    tw_global: "18,3",
    zx: 6120,
    zx_global: 6120000,
    zy: 1510,
    zy_global: 1510000,
    width: "317,4",
    width_global: "317,4",
    thickness: "29,2",
    thickness_global: "29,2",
    height: "560,3",
    height_global: "560,3",
  },
  {
    profile_section_id: 1298,
    name: "UB533X312X182",
    type: "UB Shape",
    designation: "UB533X312X182",
    shape: "I",
    country_code: "British",
    ax: 231,
    ax_global: 23100,
    bf: "314,5",
    bf_global: "314,5",
    ct: "5,78",
    ct_global: "57,8",
    d: "550,7",
    d_global: "550,7",
    ix: 373,
    ix_global: 3730000,
    iy: 12700,
    iy_global: 127000000,
    iz: 123000,
    iz_global: 1230000000,
    tf: "24,4",
    tf_global: "24,4",
    tw: "15,2",
    tw_global: "15,2",
    zx: 5040,
    zx_global: 5040000,
    zy: 1240,
    zy_global: 1240000,
    width: "314,5",
    width_global: "314,5",
    thickness: "24,4",
    thickness_global: "24,4",
    height: "550,7",
    height_global: "550,7",
  },
  {
    profile_section_id: 1299,
    name: "UB533X312X150",
    type: "UB Shape",
    designation: "UB533X312X150",
    shape: "I",
    country_code: "British",
    ax: 192,
    ax_global: 19200,
    bf: 312,
    bf_global: 312,
    ct: "5,54",
    ct_global: "55,4",
    d: "542,5",
    d_global: "542,5",
    ix: 216,
    ix_global: 2160000,
    iy: 10300,
    iy_global: 103000000,
    iz: 101000,
    iz_global: 1010000000,
    tf: "20,3",
    tf_global: "20,3",
    tw: "12,7",
    tw_global: "12,7",
    zx: 4150,
    zx_global: 4150000,
    zy: 1010,
    zy_global: 1010000,
    width: 312,
    width_global: 312,
    thickness: "20,3",
    thickness_global: "20,3",
    height: "542,5",
    height_global: "542,5",
  },
  {
    profile_section_id: 1300,
    name: "UB533X210X138",
    type: "UB Shape",
    designation: "UB533X210X138",
    shape: "I",
    country_code: "British",
    ax: 176,
    ax_global: 17600,
    bf: "213,9",
    bf_global: "213,9",
    ct: "6,94",
    ct_global: "69,4",
    d: "549,1",
    d_global: "549,1",
    ix: 250,
    ix_global: 2500000,
    iy: 3860,
    iy_global: 38600000,
    iz: 86100,
    iz_global: 861000000,
    tf: "23,6",
    tf_global: "23,6",
    tw: "14,7",
    tw_global: "14,7",
    zx: 3610,
    zx_global: 3610000,
    zy: 568,
    zy_global: 568000,
    width: "213,9",
    width_global: "213,9",
    thickness: "23,6",
    thickness_global: "23,6",
    height: "549,1",
    height_global: "549,1",
  },
  {
    profile_section_id: 1301,
    name: "UB533X210X122",
    type: "UB Shape",
    designation: "UB533X210X122",
    shape: "I",
    country_code: "British",
    ax: 155,
    ax_global: 15500,
    bf: "211,9",
    bf_global: "211,9",
    ct: "6,66",
    ct_global: "66,6",
    d: "544,5",
    d_global: "544,5",
    ix: 178,
    ix_global: 1780000,
    iy: 3390,
    iy_global: 33900000,
    iz: 76000,
    iz_global: 760000000,
    tf: "21,3",
    tf_global: "21,3",
    tw: "12,7",
    tw_global: "12,7",
    zx: 3200,
    zx_global: 3200000,
    zy: 500,
    zy_global: 500000,
    width: "211,9",
    width_global: "211,9",
    thickness: "21,3",
    thickness_global: "21,3",
    height: "544,5",
    height_global: "544,5",
  },
  {
    profile_section_id: 1302,
    name: "UB533X210X109",
    type: "UB Shape",
    designation: "UB533X210X109",
    shape: "I",
    country_code: "British",
    ax: 139,
    ax_global: 13900,
    bf: "210,8",
    bf_global: "210,8",
    ct: "6,61",
    ct_global: "66,1",
    d: "539,5",
    d_global: "539,5",
    ix: 126,
    ix_global: 1260000,
    iy: 2940,
    iy_global: 29400000,
    iz: 66800,
    iz_global: 668000000,
    tf: "18,8",
    tf_global: "18,8",
    tw: "11,6",
    tw_global: "11,6",
    zx: 2830,
    zx_global: 2830000,
    zy: 436,
    zy_global: 436000,
    width: "210,8",
    width_global: "210,8",
    thickness: "18,8",
    thickness_global: "18,8",
    height: "539,5",
    height_global: "539,5",
  },
  {
    profile_section_id: 1303,
    name: "UB533X210X101",
    type: "UB Shape",
    designation: "UB533X210X101",
    shape: "I",
    country_code: "British",
    ax: 129,
    ax_global: 12900,
    bf: 210,
    bf_global: 210,
    ct: "6,53",
    ct_global: "65,3",
    d: "536,7",
    d_global: "536,7",
    ix: 101,
    ix_global: 1010000,
    iy: 2690,
    iy_global: 26900000,
    iz: 61500,
    iz_global: 615000000,
    tf: "17,4",
    tf_global: "17,4",
    tw: "10,8",
    tw_global: "10,8",
    zx: 2610,
    zx_global: 2610000,
    zy: 399,
    zy_global: 399000,
    width: 210,
    width_global: 210,
    thickness: "17,4",
    thickness_global: "17,4",
    height: "536,7",
    height_global: "536,7",
  },
  {
    profile_section_id: 1304,
    name: "UB533X210X92",
    type: "UB Shape",
    designation: "UB533X210X92",
    shape: "I",
    country_code: "British",
    ax: 117,
    ax_global: 11700,
    bf: "209,3",
    bf_global: "209,3",
    ct: "6,55",
    ct_global: "65,5",
    d: "533,1",
    d_global: "533,1",
    ix: "75,7",
    ix_global: 757000,
    iy: 2390,
    iy_global: 23900000,
    iz: 55200,
    iz_global: 552000000,
    tf: "15,6",
    tf_global: "15,6",
    tw: "10,1",
    tw_global: "10,1",
    zx: 2360,
    zx_global: 2360000,
    zy: 355,
    zy_global: 355000,
    width: "209,3",
    width_global: "209,3",
    thickness: "15,6",
    thickness_global: "15,6",
    height: "533,1",
    height_global: "533,1",
  },
  {
    profile_section_id: 1305,
    name: "UB533X210X82",
    type: "UB Shape",
    designation: "UB533X210X82",
    shape: "I",
    country_code: "British",
    ax: 105,
    ax_global: 10500,
    bf: "208,8",
    bf_global: "208,8",
    ct: "6,75",
    ct_global: "67,5",
    d: "528,3",
    d_global: "528,3",
    ix: "51,5",
    ix_global: 515000,
    iy: 2010,
    iy_global: 20100000,
    iz: 47500,
    iz_global: 475000000,
    tf: "13,2",
    tf_global: "13,2",
    tw: "9,6",
    tw_global: "9,6",
    zx: 2060,
    zx_global: 2060000,
    zy: 300,
    zy_global: 300000,
    width: "208,8",
    width_global: "208,8",
    thickness: "13,2",
    thickness_global: "13,2",
    height: "528,3",
    height_global: "528,3",
  },
  {
    profile_section_id: 1306,
    name: "UB533X165X85",
    type: "UB Shape",
    designation: "UB533X165X85",
    shape: "I",
    country_code: "British",
    ax: 108,
    ax_global: 10800,
    bf: "166,5",
    bf_global: "166,5",
    ct: "7,23",
    ct_global: "72,3",
    d: "534,9",
    d_global: "534,9",
    ix: "73,8",
    ix_global: 738000,
    iy: 1270,
    iy_global: 12700000,
    iz: 48500,
    iz_global: 485000000,
    tf: "16,5",
    tf_global: "16,5",
    tw: "10,3",
    tw_global: "10,3",
    zx: 2100,
    zx_global: 2100000,
    zy: 243,
    zy_global: 243000,
    width: "166,5",
    width_global: "166,5",
    thickness: "16,5",
    thickness_global: "16,5",
    height: "534,9",
    height_global: "534,9",
  },
  {
    profile_section_id: 1307,
    name: "UB533X165X74",
    type: "UB Shape",
    designation: "UB533X165X74",
    shape: "I",
    country_code: "British",
    ax: "95,2",
    ax_global: 9520,
    bf: "165,9",
    bf_global: "165,9",
    ct: "7,46",
    ct_global: "74,6",
    d: "529,1",
    d_global: "529,1",
    ix: "47,9",
    ix_global: 479000,
    iy: 1040,
    iy_global: 10400000,
    iz: 41100,
    iz_global: 411000000,
    tf: "13,6",
    tf_global: "13,6",
    tw: "9,7",
    tw_global: "9,7",
    zx: 1810,
    zx_global: 1810000,
    zy: 200,
    zy_global: 200000,
    width: "165,9",
    width_global: "165,9",
    thickness: "13,6",
    thickness_global: "13,6",
    height: "529,1",
    height_global: "529,1",
  },
  {
    profile_section_id: 1308,
    name: "UB533X165X66",
    type: "UB Shape",
    designation: "UB533X165X66",
    shape: "I",
    country_code: "British",
    ax: "83,7",
    ax_global: 8370,
    bf: "165,1",
    bf_global: "165,1",
    ct: "7,59",
    ct_global: "75,9",
    d: "524,7",
    d_global: "524,7",
    ix: 32,
    ix_global: 320000,
    iy: 859,
    iy_global: 8590000,
    iz: 35000,
    iz_global: 350000000,
    tf: "11,4",
    tf_global: "11,4",
    tw: "8,9",
    tw_global: "8,9",
    zx: 1560,
    zx_global: 1560000,
    zy: 166,
    zy_global: 166000,
    width: "165,1",
    width_global: "165,1",
    thickness: "11,4",
    thickness_global: "11,4",
    height: "524,7",
    height_global: "524,7",
  },
  {
    profile_section_id: 1309,
    name: "UB457X191X161",
    type: "UB Shape",
    designation: "UB457X191X161",
    shape: "I",
    country_code: "British",
    ax: 206,
    ax_global: 20600,
    bf: "199,4",
    bf_global: "199,4",
    ct: "6,22",
    ct_global: "62,2",
    d: 492,
    d_global: 492,
    ix: 515,
    ix_global: 5150000,
    iy: 4250,
    iy_global: 42500000,
    iz: 79800,
    iz_global: 798000000,
    tf: 32,
    tf_global: 32,
    tw: 18,
    tw_global: 18,
    zx: 3780,
    zx_global: 3780000,
    zy: 672,
    zy_global: 672000,
    width: "199,4",
    width_global: "199,4",
    thickness: 32,
    thickness_global: 32,
    height: 492,
    height_global: 492,
  },
  {
    profile_section_id: 1310,
    name: "UB457X191X133",
    type: "UB Shape",
    designation: "UB457X191X133",
    shape: "I",
    country_code: "British",
    ax: 170,
    ax_global: 17000,
    bf: "196,7",
    bf_global: "196,7",
    ct: "5,96",
    ct_global: "59,6",
    d: "480,6",
    d_global: "480,6",
    ix: 292,
    ix_global: 2920000,
    iy: 3350,
    iy_global: 33500000,
    iz: 63800,
    iz_global: 638000000,
    tf: "26,3",
    tf_global: "26,3",
    tw: "15,3",
    tw_global: "15,3",
    zx: 3070,
    zx_global: 3070000,
    zy: 535,
    zy_global: 535000,
    width: "196,7",
    width_global: "196,7",
    thickness: "26,3",
    thickness_global: "26,3",
    height: "480,6",
    height_global: "480,6",
  },
  {
    profile_section_id: 1311,
    name: "UB457X191X106",
    type: "UB Shape",
    designation: "UB457X191X106",
    shape: "I",
    country_code: "British",
    ax: 135,
    ax_global: 13500,
    bf: 194,
    bf_global: 194,
    ct: "5,73",
    ct_global: "57,3",
    d: "469,2",
    d_global: "469,2",
    ix: 146,
    ix_global: 1460000,
    iy: 2510,
    iy_global: 25100000,
    iz: 48900,
    iz_global: 489000000,
    tf: "20,6",
    tf_global: "20,6",
    tw: "12,6",
    tw_global: "12,6",
    zx: 2390,
    zx_global: 2390000,
    zy: 405,
    zy_global: 405000,
    width: 194,
    width_global: 194,
    thickness: "20,6",
    thickness_global: "20,6",
    height: "469,2",
    height_global: "469,2",
  },
  {
    profile_section_id: 1312,
    name: "UB457X191X98",
    type: "UB Shape",
    designation: "UB457X191X98",
    shape: "I",
    country_code: "British",
    ax: 125,
    ax_global: 12500,
    bf: "192,8",
    bf_global: "192,8",
    ct: "5,53",
    ct_global: "55,3",
    d: "467,2",
    d_global: "467,2",
    ix: 121,
    ix_global: 1210000,
    iy: 2350,
    iy_global: 23500000,
    iz: 45700,
    iz_global: 457000000,
    tf: "19,6",
    tf_global: "19,6",
    tw: "11,4",
    tw_global: "11,4",
    zx: 2230,
    zx_global: 2230000,
    zy: 379,
    zy_global: 379000,
    width: "192,8",
    width_global: "192,8",
    thickness: "19,6",
    thickness_global: "19,6",
    height: "467,2",
    height_global: "467,2",
  },
  {
    profile_section_id: 1313,
    name: "UB457X191X89",
    type: "UB Shape",
    designation: "UB457X191X89",
    shape: "I",
    country_code: "British",
    ax: 114,
    ax_global: 11400,
    bf: "191,9",
    bf_global: "191,9",
    ct: "5,47",
    ct_global: "54,7",
    d: "463,4",
    d_global: "463,4",
    ix: "90,7",
    ix_global: 907000,
    iy: 2090,
    iy_global: 20900000,
    iz: 41000,
    iz_global: 410000000,
    tf: "17,7",
    tf_global: "17,7",
    tw: "10,5",
    tw_global: "10,5",
    zx: 2010,
    zx_global: 2010000,
    zy: 338,
    zy_global: 338000,
    width: "191,9",
    width_global: "191,9",
    thickness: "17,7",
    thickness_global: "17,7",
    height: "463,4",
    height_global: "463,4",
  },
  {
    profile_section_id: 1314,
    name: "UB457X191X82",
    type: "UB Shape",
    designation: "UB457X191X82",
    shape: "I",
    country_code: "British",
    ax: 104,
    ax_global: 10400,
    bf: "191,3",
    bf_global: "191,3",
    ct: "5,47",
    ct_global: "54,7",
    d: 460,
    d_global: 460,
    ix: "69,2",
    ix_global: 692000,
    iy: 1870,
    iy_global: 18700000,
    iz: 37100,
    iz_global: 371000000,
    tf: 16,
    tf_global: 16,
    tw: "9,9",
    tw_global: "9,9",
    zx: 1830,
    zx_global: 1830000,
    zy: 304,
    zy_global: 304000,
    width: "191,3",
    width_global: "191,3",
    thickness: 16,
    thickness_global: 16,
    height: 460,
    height_global: 460,
  },
  {
    profile_section_id: 1315,
    name: "UB457X191X74",
    type: "UB Shape",
    designation: "UB457X191X74",
    shape: "I",
    country_code: "British",
    ax: "94,6",
    ax_global: 9460,
    bf: "190,4",
    bf_global: "190,4",
    ct: "5,38",
    ct_global: "53,8",
    d: 457,
    d_global: 457,
    ix: "51,8",
    ix_global: 518000,
    iy: 1670,
    iy_global: 16700000,
    iz: 33300,
    iz_global: 333000000,
    tf: "14,5",
    tf_global: "14,5",
    tw: 9,
    tw_global: 9,
    zx: 1650,
    zx_global: 1650000,
    zy: 272,
    zy_global: 272000,
    width: "190,4",
    width_global: "190,4",
    thickness: "14,5",
    thickness_global: "14,5",
    height: 457,
    height_global: 457,
  },
  {
    profile_section_id: 1316,
    name: "UB457X191X67",
    type: "UB Shape",
    designation: "UB457X191X67",
    shape: "I",
    country_code: "British",
    ax: "85,5",
    ax_global: 8550,
    bf: "189,9",
    bf_global: "189,9",
    ct: "5,46",
    ct_global: "54,6",
    d: "453,4",
    d_global: "453,4",
    ix: "37,1",
    ix_global: 371000,
    iy: 1450,
    iy_global: 14500000,
    iz: 29400,
    iz_global: 294000000,
    tf: "12,7",
    tf_global: "12,7",
    tw: "8,5",
    tw_global: "8,5",
    zx: 1470,
    zx_global: 1470000,
    zy: 237,
    zy_global: 237000,
    width: "189,9",
    width_global: "189,9",
    thickness: "12,7",
    thickness_global: "12,7",
    height: "453,4",
    height_global: "453,4",
  },
  {
    profile_section_id: 1317,
    name: "UB457X152X82",
    type: "UB Shape",
    designation: "UB457X152X82",
    shape: "I",
    country_code: "British",
    ax: 105,
    ax_global: 10500,
    bf: "155,3",
    bf_global: "155,3",
    ct: "5,96",
    ct_global: "59,6",
    d: "465,8",
    d_global: "465,8",
    ix: "89,2",
    ix_global: 892000,
    iy: 1180,
    iy_global: 11800000,
    iz: 36600,
    iz_global: 366000000,
    tf: "18,9",
    tf_global: "18,9",
    tw: "10,5",
    tw_global: "10,5",
    zx: 1810,
    zx_global: 1810000,
    zy: 240,
    zy_global: 240000,
    width: "155,3",
    width_global: "155,3",
    thickness: "18,9",
    thickness_global: "18,9",
    height: "465,8",
    height_global: "465,8",
  },
  {
    profile_section_id: 1318,
    name: "UB457X152X74",
    type: "UB Shape",
    designation: "UB457X152X74",
    shape: "I",
    country_code: "British",
    ax: "94,5",
    ax_global: 9450,
    bf: "154,4",
    bf_global: "154,4",
    ct: "5,88",
    ct_global: "58,8",
    d: 462,
    d_global: 462,
    ix: "65,9",
    ix_global: 659000,
    iy: 1050,
    iy_global: 10500000,
    iz: 32700,
    iz_global: 327000000,
    tf: 17,
    tf_global: 17,
    tw: "9,6",
    tw_global: "9,6",
    zx: 1630,
    zx_global: 1630000,
    zy: 213,
    zy_global: 213000,
    width: "154,4",
    width_global: "154,4",
    thickness: 17,
    thickness_global: 17,
    height: 462,
    height_global: 462,
  },
  {
    profile_section_id: 1319,
    name: "UB457X152X67",
    type: "UB Shape",
    designation: "UB457X152X67",
    shape: "I",
    country_code: "British",
    ax: "85,6",
    ax_global: 8560,
    bf: "153,8",
    bf_global: "153,8",
    ct: "5,91",
    ct_global: "59,1",
    d: 458,
    d_global: 458,
    ix: "47,7",
    ix_global: 477000,
    iy: 913,
    iy_global: 9130000,
    iz: 28900,
    iz_global: 289000000,
    tf: 15,
    tf_global: 15,
    tw: 9,
    tw_global: 9,
    zx: 1450,
    zx_global: 1450000,
    zy: 187,
    zy_global: 187000,
    width: "153,8",
    width_global: "153,8",
    thickness: 15,
    thickness_global: 15,
    height: 458,
    height_global: 458,
  },
  {
    profile_section_id: 1320,
    name: "UB457X152X60",
    type: "UB Shape",
    designation: "UB457X152X60",
    shape: "I",
    country_code: "British",
    ax: "76,2",
    ax_global: 7620,
    bf: "152,9",
    bf_global: "152,9",
    ct: "5,84",
    ct_global: "58,4",
    d: "454,6",
    d_global: "454,6",
    ix: "33,8",
    ix_global: 338000,
    iy: 795,
    iy_global: 7950000,
    iz: 25500,
    iz_global: 255000000,
    tf: "13,3",
    tf_global: "13,3",
    tw: "8,1",
    tw_global: "8,1",
    zx: 1290,
    zx_global: 1290000,
    zy: 163,
    zy_global: 163000,
    width: "152,9",
    width_global: "152,9",
    thickness: "13,3",
    thickness_global: "13,3",
    height: "454,6",
    height_global: "454,6",
  },
  {
    profile_section_id: 1321,
    name: "UB457X152X52",
    type: "UB Shape",
    designation: "UB457X152X52",
    shape: "I",
    country_code: "British",
    ax: "66,6",
    ax_global: 6660,
    bf: "152,4",
    bf_global: "152,4",
    ct: "6,04",
    ct_global: "60,4",
    d: "449,8",
    d_global: "449,8",
    ix: "21,4",
    ix_global: 214000,
    iy: 645,
    iy_global: 6450000,
    iz: 21400,
    iz_global: 214000000,
    tf: "10,9",
    tf_global: "10,9",
    tw: "7,6",
    tw_global: "7,6",
    zx: 1100,
    zx_global: 1100000,
    zy: 133,
    zy_global: 133000,
    width: "152,4",
    width_global: "152,4",
    thickness: "10,9",
    thickness_global: "10,9",
    height: "449,8",
    height_global: "449,8",
  },
  {
    profile_section_id: 1322,
    name: "UB406X178X85",
    type: "UB Shape",
    designation: "UB406X178X85",
    shape: "I",
    country_code: "British",
    ax: 109,
    ax_global: 10900,
    bf: "181,9",
    bf_global: "181,9",
    ct: "4,91",
    ct_global: "49,1",
    d: "417,2",
    d_global: "417,2",
    ix: 93,
    ix_global: 930000,
    iy: 1830,
    iy_global: 18300000,
    iz: 31700,
    iz_global: 317000000,
    tf: "18,2",
    tf_global: "18,2",
    tw: "10,9",
    tw_global: "10,9",
    zx: 1730,
    zx_global: 1730000,
    zy: 313,
    zy_global: 313000,
    width: "181,9",
    width_global: "181,9",
    thickness: "18,2",
    thickness_global: "18,2",
    height: "417,2",
    height_global: "417,2",
  },
  {
    profile_section_id: 1323,
    name: "UB406X178X74",
    type: "UB Shape",
    designation: "UB406X178X74",
    shape: "I",
    country_code: "British",
    ax: "94,5",
    ax_global: 9450,
    bf: "179,5",
    bf_global: "179,5",
    ct: "4,76",
    ct_global: "47,6",
    d: "412,8",
    d_global: "412,8",
    ix: "62,8",
    ix_global: 628000,
    iy: 1550,
    iy_global: 15500000,
    iz: 27300,
    iz_global: 273000000,
    tf: 16,
    tf_global: 16,
    tw: "9,5",
    tw_global: "9,5",
    zx: 1500,
    zx_global: 1500000,
    zy: 267,
    zy_global: 267000,
    width: "179,5",
    width_global: "179,5",
    thickness: 16,
    thickness_global: 16,
    height: "412,8",
    height_global: "412,8",
  },
  {
    profile_section_id: 1324,
    name: "UB406X178X67",
    type: "UB Shape",
    designation: "UB406X178X67",
    shape: "I",
    country_code: "British",
    ax: "85,5",
    ax_global: 8550,
    bf: "178,8",
    bf_global: "178,8",
    ct: "4,73",
    ct_global: "47,3",
    d: "409,4",
    d_global: "409,4",
    ix: "46,1",
    ix_global: 461000,
    iy: 1360,
    iy_global: 13600000,
    iz: 24300,
    iz_global: 243000000,
    tf: "14,3",
    tf_global: "14,3",
    tw: "8,8",
    tw_global: "8,8",
    zx: 1350,
    zx_global: 1350000,
    zy: 237,
    zy_global: 237000,
    width: "178,8",
    width_global: "178,8",
    thickness: "14,3",
    thickness_global: "14,3",
    height: "409,4",
    height_global: "409,4",
  },
  {
    profile_section_id: 1325,
    name: "UB406X178X60",
    type: "UB Shape",
    designation: "UB406X178X60",
    shape: "I",
    country_code: "British",
    ax: "76,5",
    ax_global: 7650,
    bf: "177,9",
    bf_global: "177,9",
    ct: "4,64",
    ct_global: "46,4",
    d: "406,4",
    d_global: "406,4",
    ix: "33,3",
    ix_global: 333000,
    iy: 1200,
    iy_global: 12000000,
    iz: 21600,
    iz_global: 216000000,
    tf: "12,8",
    tf_global: "12,8",
    tw: "7,9",
    tw_global: "7,9",
    zx: 1200,
    zx_global: 1200000,
    zy: 209,
    zy_global: 209000,
    width: "177,9",
    width_global: "177,9",
    thickness: "12,8",
    thickness_global: "12,8",
    height: "406,4",
    height_global: "406,4",
  },
  {
    profile_section_id: 1326,
    name: "UB406X178X54",
    type: "UB Shape",
    designation: "UB406X178X54",
    shape: "I",
    country_code: "British",
    ax: 69,
    ax_global: 6900,
    bf: "177,7",
    bf_global: "177,7",
    ct: "4,83",
    ct_global: "48,3",
    d: "402,6",
    d_global: "402,6",
    ix: "23,1",
    ix_global: 231000,
    iy: 1020,
    iy_global: 10200000,
    iz: 18700,
    iz_global: 187000000,
    tf: "10,9",
    tf_global: "10,9",
    tw: "7,7",
    tw_global: "7,7",
    zx: 1050,
    zx_global: 1050000,
    zy: 178,
    zy_global: 178000,
    width: "177,7",
    width_global: "177,7",
    thickness: "10,9",
    thickness_global: "10,9",
    height: "402,6",
    height_global: "402,6",
  },
  {
    profile_section_id: 1327,
    name: "UB406X140X53",
    type: "UB Shape",
    designation: "UB406X140X53",
    shape: "I",
    country_code: "British",
    ax: "67,9",
    ax_global: 6790,
    bf: "143,3",
    bf_global: "143,3",
    ct: "5,16",
    ct_global: "51,6",
    d: "406,6",
    d_global: "406,6",
    ix: 29,
    ix_global: 290000,
    iy: 635,
    iy_global: 6350000,
    iz: 18300,
    iz_global: 183000000,
    tf: "12,9",
    tf_global: "12,9",
    tw: "7,9",
    tw_global: "7,9",
    zx: 1030,
    zx_global: 1030000,
    zy: 139,
    zy_global: 139000,
    width: "143,3",
    width_global: "143,3",
    thickness: "12,9",
    thickness_global: "12,9",
    height: "406,6",
    height_global: "406,6",
  },
  {
    profile_section_id: 1328,
    name: "UB406X140X46",
    type: "UB Shape",
    designation: "UB406X140X46",
    shape: "I",
    country_code: "British",
    ax: "58,6",
    ax_global: 5860,
    bf: "142,2",
    bf_global: "142,2",
    ct: "5,02",
    ct_global: "50,2",
    d: "403,2",
    d_global: "403,2",
    ix: 19,
    ix_global: 190000,
    iy: 538,
    iy_global: 5380000,
    iz: 15700,
    iz_global: 157000000,
    tf: "11,2",
    tf_global: "11,2",
    tw: "6,8",
    tw_global: "6,8",
    zx: 888,
    zx_global: 888000,
    zy: 118,
    zy_global: 118000,
    width: "142,2",
    width_global: "142,2",
    thickness: "11,2",
    thickness_global: "11,2",
    height: "403,2",
    height_global: "403,2",
  },
  {
    profile_section_id: 1329,
    name: "UB406X140X39",
    type: "UB Shape",
    designation: "UB406X140X39",
    shape: "I",
    country_code: "British",
    ax: "49,7",
    ax_global: 4970,
    bf: "141,8",
    bf_global: "141,8",
    ct: "5,32",
    ct_global: "53,2",
    d: 398,
    d_global: 398,
    ix: "10,7",
    ix_global: 107000,
    iy: 410,
    iy_global: 4100000,
    iz: 12500,
    iz_global: 125000000,
    tf: "8,6",
    tf_global: "8,6",
    tw: "6,4",
    tw_global: "6,4",
    zx: 724,
    zx_global: 724000,
    zy: "90,8",
    zy_global: 90800,
    width: "141,8",
    width_global: "141,8",
    thickness: "8,6",
    thickness_global: "8,6",
    height: 398,
    height_global: 398,
  },
  {
    profile_section_id: 1330,
    name: "UB356X171X67",
    type: "UB Shape",
    designation: "UB356X171X67",
    shape: "I",
    country_code: "British",
    ax: "85,5",
    ax_global: 8550,
    bf: "173,2",
    bf_global: "173,2",
    ct: 4,
    ct_global: 40,
    d: "363,4",
    d_global: "363,4",
    ix: "55,7",
    ix_global: 557000,
    iy: 1360,
    iy_global: 13600000,
    iz: 19500,
    iz_global: 195000000,
    tf: "15,7",
    tf_global: "15,7",
    tw: "9,1",
    tw_global: "9,1",
    zx: 1210,
    zx_global: 1210000,
    zy: 243,
    zy_global: 243000,
    width: "173,2",
    width_global: "173,2",
    thickness: "15,7",
    thickness_global: "15,7",
    height: "363,4",
    height_global: "363,4",
  },
  {
    profile_section_id: 1331,
    name: "UB356X171X57",
    type: "UB Shape",
    designation: "UB356X171X57",
    shape: "I",
    country_code: "British",
    ax: "72,6",
    ax_global: 7260,
    bf: "172,2",
    bf_global: "172,2",
    ct: "3,97",
    ct_global: "39,7",
    d: 358,
    d_global: 358,
    ix: "33,4",
    ix_global: 334000,
    iy: 1110,
    iy_global: 11100000,
    iz: 16000,
    iz_global: 160000000,
    tf: 13,
    tf_global: 13,
    tw: "8,1",
    tw_global: "8,1",
    zx: 1010,
    zx_global: 1010000,
    zy: 199,
    zy_global: 199000,
    width: "172,2",
    width_global: "172,2",
    thickness: 13,
    thickness_global: 13,
    height: 358,
    height_global: 358,
  },
  {
    profile_section_id: 1332,
    name: "UB356X171X51",
    type: "UB Shape",
    designation: "UB356X171X51",
    shape: "I",
    country_code: "British",
    ax: "64,9",
    ax_global: 6490,
    bf: "171,5",
    bf_global: "171,5",
    ct: "3,94",
    ct_global: "39,4",
    d: 355,
    d_global: 355,
    ix: "23,8",
    ix_global: 238000,
    iy: 968,
    iy_global: 9680000,
    iz: 14100,
    iz_global: 141000000,
    tf: "11,5",
    tf_global: "11,5",
    tw: "7,4",
    tw_global: "7,4",
    zx: 896,
    zx_global: 896000,
    zy: 174,
    zy_global: 174000,
    width: "171,5",
    width_global: "171,5",
    thickness: "11,5",
    thickness_global: "11,5",
    height: 355,
    height_global: 355,
  },
  {
    profile_section_id: 1333,
    name: "UB356X171X45",
    type: "UB Shape",
    designation: "UB356X171X45",
    shape: "I",
    country_code: "British",
    ax: "57,3",
    ax_global: 5730,
    bf: "171,1",
    bf_global: "171,1",
    ct: "4,05",
    ct_global: "40,5",
    d: "351,4",
    d_global: "351,4",
    ix: "15,8",
    ix_global: 158000,
    iy: 811,
    iy_global: 8110000,
    iz: 12100,
    iz_global: 121000000,
    tf: "9,7",
    tf_global: "9,7",
    tw: 7,
    tw_global: 7,
    zx: 775,
    zx_global: 775000,
    zy: 147,
    zy_global: 147000,
    width: "171,1",
    width_global: "171,1",
    thickness: "9,7",
    thickness_global: "9,7",
    height: "351,4",
    height_global: "351,4",
  },
  {
    profile_section_id: 1334,
    name: "UB356X127X39",
    type: "UB Shape",
    designation: "UB356X127X39",
    shape: "I",
    country_code: "British",
    ax: "49,8",
    ax_global: 4980,
    bf: 126,
    bf_global: 126,
    ct: "4,43",
    ct_global: "44,3",
    d: "353,4",
    d_global: "353,4",
    ix: "15,1",
    ix_global: 151000,
    iy: 358,
    iy_global: 3580000,
    iz: 10200,
    iz_global: 102000000,
    tf: "10,7",
    tf_global: "10,7",
    tw: "6,6",
    tw_global: "6,6",
    zx: 659,
    zx_global: 659000,
    zy: 89,
    zy_global: 89000,
    width: 126,
    width_global: 126,
    thickness: "10,7",
    thickness_global: "10,7",
    height: "353,4",
    height_global: "353,4",
  },
  {
    profile_section_id: 1335,
    name: "UB356X127X33",
    type: "UB Shape",
    designation: "UB356X127X33",
    shape: "I",
    country_code: "British",
    ax: "42,1",
    ax_global: 4210,
    bf: "125,4",
    bf_global: "125,4",
    ct: "4,56",
    ct_global: "45,6",
    d: 349,
    d_global: 349,
    ix: "8,79",
    ix_global: 87900,
    iy: 280,
    iy_global: 2800000,
    iz: 8250,
    iz_global: 82500000,
    tf: "8,5",
    tf_global: "8,5",
    tw: 6,
    tw_global: 6,
    zx: 543,
    zx_global: 543000,
    zy: "70,2",
    zy_global: 70200,
    width: "125,4",
    width_global: "125,4",
    thickness: "8,5",
    thickness_global: "8,5",
    height: 349,
    height_global: 349,
  },
  {
    profile_section_id: 1336,
    name: "UB305X165X54",
    type: "UB Shape",
    designation: "UB305X165X54",
    shape: "I",
    country_code: "British",
    ax: "68,8",
    ax_global: 6880,
    bf: "166,9",
    bf_global: "166,9",
    ct: "3,21",
    ct_global: "32,1",
    d: "310,4",
    d_global: "310,4",
    ix: "34,8",
    ix_global: 348000,
    iy: 1060,
    iy_global: 10600000,
    iz: 11700,
    iz_global: 117000000,
    tf: "13,7",
    tf_global: "13,7",
    tw: "7,9",
    tw_global: "7,9",
    zx: 846,
    zx_global: 846000,
    zy: 196,
    zy_global: 196000,
    width: "166,9",
    width_global: "166,9",
    thickness: "13,7",
    thickness_global: "13,7",
    height: "310,4",
    height_global: "310,4",
  },
  {
    profile_section_id: 1337,
    name: "UB305X165X46",
    type: "UB Shape",
    designation: "UB305X165X46",
    shape: "I",
    country_code: "British",
    ax: "58,7",
    ax_global: 5870,
    bf: "165,7",
    bf_global: "165,7",
    ct: "3,07",
    ct_global: "30,7",
    d: "306,6",
    d_global: "306,6",
    ix: "22,2",
    ix_global: 222000,
    iy: 896,
    iy_global: 8960000,
    iz: 9900,
    iz_global: 99000000,
    tf: "11,8",
    tf_global: "11,8",
    tw: "6,7",
    tw_global: "6,7",
    zx: 720,
    zx_global: 720000,
    zy: 166,
    zy_global: 166000,
    width: "165,7",
    width_global: "165,7",
    thickness: "11,8",
    thickness_global: "11,8",
    height: "306,6",
    height_global: "306,6",
  },
  {
    profile_section_id: 1338,
    name: "UB305X165X40",
    type: "UB Shape",
    designation: "UB305X165X40",
    shape: "I",
    country_code: "British",
    ax: "51,3",
    ax_global: 5130,
    bf: 165,
    bf_global: 165,
    ct: "3,03",
    ct_global: "30,3",
    d: "303,4",
    d_global: "303,4",
    ix: "14,7",
    ix_global: 147000,
    iy: 764,
    iy_global: 7640000,
    iz: 8500,
    iz_global: 85000000,
    tf: "10,2",
    tf_global: "10,2",
    tw: 6,
    tw_global: 6,
    zx: 623,
    zx_global: 623000,
    zy: 142,
    zy_global: 142000,
    width: 165,
    width_global: 165,
    thickness: "10,2",
    thickness_global: "10,2",
    height: "303,4",
    height_global: "303,4",
  },
  {
    profile_section_id: 1339,
    name: "UB305X127X48",
    type: "UB Shape",
    designation: "UB305X127X48",
    shape: "I",
    country_code: "British",
    ax: "61,2",
    ax_global: 6120,
    bf: "125,3",
    bf_global: "125,3",
    ct: "3,94",
    ct_global: "39,4",
    d: 311,
    d_global: 311,
    ix: "31,8",
    ix_global: 318000,
    iy: 461,
    iy_global: 4610000,
    iz: 9570,
    iz_global: 95700000,
    tf: 14,
    tf_global: 14,
    tw: 9,
    tw_global: 9,
    zx: 711,
    zx_global: 711000,
    zy: 116,
    zy_global: 116000,
    width: "125,3",
    width_global: "125,3",
    thickness: 14,
    thickness_global: 14,
    height: 311,
    height_global: 311,
  },
  {
    profile_section_id: 1340,
    name: "UB305X127X42",
    type: "UB Shape",
    designation: "UB305X127X42",
    shape: "I",
    country_code: "British",
    ax: "53,4",
    ax_global: 5340,
    bf: "124,3",
    bf_global: "124,3",
    ct: "3,87",
    ct_global: "38,7",
    d: "307,2",
    d_global: "307,2",
    ix: "21,1",
    ix_global: 211000,
    iy: 389,
    iy_global: 3890000,
    iz: 8200,
    iz_global: 82000000,
    tf: "12,1",
    tf_global: "12,1",
    tw: 8,
    tw_global: 8,
    zx: 614,
    zx_global: 614000,
    zy: "98,4",
    zy_global: 98400,
    width: "124,3",
    width_global: "124,3",
    thickness: "12,1",
    thickness_global: "12,1",
    height: "307,2",
    height_global: "307,2",
  },
  {
    profile_section_id: 1341,
    name: "UB305X127X37",
    type: "UB Shape",
    designation: "UB305X127X37",
    shape: "I",
    country_code: "British",
    ax: "47,2",
    ax_global: 4720,
    bf: "123,4",
    bf_global: "123,4",
    ct: "3,78",
    ct_global: "37,8",
    d: "304,4",
    d_global: "304,4",
    ix: "14,8",
    ix_global: 148000,
    iy: 336,
    iy_global: 3360000,
    iz: 7170,
    iz_global: 71700000,
    tf: "10,7",
    tf_global: "10,7",
    tw: "7,1",
    tw_global: "7,1",
    zx: 539,
    zx_global: 539000,
    zy: "85,4",
    zy_global: 85400,
    width: "123,4",
    width_global: "123,4",
    thickness: "10,7",
    thickness_global: "10,7",
    height: "304,4",
    height_global: "304,4",
  },
  {
    profile_section_id: 1342,
    name: "UB305X102X33",
    type: "UB Shape",
    designation: "UB305X102X33",
    shape: "I",
    country_code: "British",
    ax: "41,8",
    ax_global: 4180,
    bf: "102,4",
    bf_global: "102,4",
    ct: "4,14",
    ct_global: "41,4",
    d: "312,7",
    d_global: "312,7",
    ix: "12,2",
    ix_global: 122000,
    iy: 194,
    iy_global: 1940000,
    iz: 6500,
    iz_global: 65000000,
    tf: "10,8",
    tf_global: "10,8",
    tw: "6,6",
    tw_global: "6,6",
    zx: 481,
    zx_global: 481000,
    zy: 60,
    zy_global: 60000,
    width: "102,4",
    width_global: "102,4",
    thickness: "10,8",
    thickness_global: "10,8",
    height: "312,7",
    height_global: "312,7",
  },
  {
    profile_section_id: 1343,
    name: "UB305X102X28",
    type: "UB Shape",
    designation: "UB305X102X28",
    shape: "I",
    country_code: "British",
    ax: "35,9",
    ax_global: 3590,
    bf: "101,8",
    bf_global: "101,8",
    ct: "4,2",
    ct_global: 42,
    d: "308,7",
    d_global: "308,7",
    ix: "7,4",
    ix_global: 74000,
    iy: 155,
    iy_global: 1550000,
    iz: 5370,
    iz_global: 53700000,
    tf: "8,8",
    tf_global: "8,8",
    tw: 6,
    tw_global: 6,
    zx: 403,
    zx_global: 403000,
    zy: "48,4",
    zy_global: 48400,
    width: "101,8",
    width_global: "101,8",
    thickness: "8,8",
    thickness_global: "8,8",
    height: "308,7",
    height_global: "308,7",
  },
  {
    profile_section_id: 1344,
    name: "UB305X102X25",
    type: "UB Shape",
    designation: "UB305X102X25",
    shape: "I",
    country_code: "British",
    ax: "31,6",
    ax_global: 3160,
    bf: "101,6",
    bf_global: "101,6",
    ct: "4,43",
    ct_global: "44,3",
    d: "305,1",
    d_global: "305,1",
    ix: "4,77",
    ix_global: 47700,
    iy: 123,
    iy_global: 1230000,
    iz: 4460,
    iz_global: 44600000,
    tf: 7,
    tf_global: 7,
    tw: "5,8",
    tw_global: "5,8",
    zx: 342,
    zx_global: 342000,
    zy: "38,8",
    zy_global: 38800,
    width: "101,6",
    width_global: "101,6",
    thickness: 7,
    thickness_global: 7,
    height: "305,1",
    height_global: "305,1",
  },
  {
    profile_section_id: 1345,
    name: "UB254X146X43",
    type: "UB Shape",
    designation: "UB254X146X43",
    shape: "I",
    country_code: "British",
    ax: "54,8",
    ax_global: 5480,
    bf: "147,3",
    bf_global: "147,3",
    ct: "2,64",
    ct_global: "26,4",
    d: "259,6",
    d_global: "259,6",
    ix: "23,9",
    ix_global: 239000,
    iy: 677,
    iy_global: 6770000,
    iz: 6540,
    iz_global: 65400000,
    tf: "12,7",
    tf_global: "12,7",
    tw: "7,2",
    tw_global: "7,2",
    zx: 566,
    zx_global: 566000,
    zy: 141,
    zy_global: 141000,
    width: "147,3",
    width_global: "147,3",
    thickness: "12,7",
    thickness_global: "12,7",
    height: "259,6",
    height_global: "259,6",
  },
  {
    profile_section_id: 1346,
    name: "UB254X146X37",
    type: "UB Shape",
    designation: "UB254X146X37",
    shape: "I",
    country_code: "British",
    ax: "47,2",
    ax_global: 4720,
    bf: "146,4",
    bf_global: "146,4",
    ct: "2,55",
    ct_global: "25,5",
    d: 256,
    d_global: 256,
    ix: "15,3",
    ix_global: 153000,
    iy: 571,
    iy_global: 5710000,
    iz: 5540,
    iz_global: 55400000,
    tf: "10,9",
    tf_global: "10,9",
    tw: "6,3",
    tw_global: "6,3",
    zx: 483,
    zx_global: 483000,
    zy: 119,
    zy_global: 119000,
    width: "146,4",
    width_global: "146,4",
    thickness: "10,9",
    thickness_global: "10,9",
    height: 256,
    height_global: 256,
  },
  {
    profile_section_id: 1347,
    name: "UB254X146X31",
    type: "UB Shape",
    designation: "UB254X146X31",
    shape: "I",
    country_code: "British",
    ax: "39,7",
    ax_global: 3970,
    bf: "146,1",
    bf_global: "146,1",
    ct: "2,66",
    ct_global: "26,6",
    d: "251,4",
    d_global: "251,4",
    ix: "8,55",
    ix_global: 85500,
    iy: 448,
    iy_global: 4480000,
    iz: 4410,
    iz_global: 44100000,
    tf: "8,6",
    tf_global: "8,6",
    tw: 6,
    tw_global: 6,
    zx: 393,
    zx_global: 393000,
    zy: "94,1",
    zy_global: 94100,
    width: "146,1",
    width_global: "146,1",
    thickness: "8,6",
    thickness_global: "8,6",
    height: "251,4",
    height_global: "251,4",
  },
  {
    profile_section_id: 1348,
    name: "UB254X102X28",
    type: "UB Shape",
    designation: "UB254X102X28",
    shape: "I",
    country_code: "British",
    ax: "36,1",
    ax_global: 3610,
    bf: "102,2",
    bf_global: "102,2",
    ct: "3,24",
    ct_global: "32,4",
    d: "260,4",
    d_global: "260,4",
    ix: "9,57",
    ix_global: 95700,
    iy: 179,
    iy_global: 1790000,
    iz: 4000,
    iz_global: 40000000,
    tf: 10,
    tf_global: 10,
    tw: "6,3",
    tw_global: "6,3",
    zx: 353,
    zx_global: 353000,
    zy: "54,8",
    zy_global: 54800,
    width: "102,2",
    width_global: "102,2",
    thickness: 10,
    thickness_global: 10,
    height: "260,4",
    height_global: "260,4",
  },
  {
    profile_section_id: 1349,
    name: "UB254X102X25",
    type: "UB Shape",
    designation: "UB254X102X25",
    shape: "I",
    country_code: "British",
    ax: 32,
    ax_global: 3200,
    bf: "101,9",
    bf_global: "101,9",
    ct: "3,32",
    ct_global: "33,2",
    d: "257,2",
    d_global: "257,2",
    ix: "6,42",
    ix_global: 64200,
    iy: 149,
    iy_global: 1490000,
    iz: 3410,
    iz_global: 34100000,
    tf: "8,4",
    tf_global: "8,4",
    tw: 6,
    tw_global: 6,
    zx: 306,
    zx_global: 306000,
    zy: 46,
    zy_global: 46000,
    width: "101,9",
    width_global: "101,9",
    thickness: "8,4",
    thickness_global: "8,4",
    height: "257,2",
    height_global: "257,2",
  },
  {
    profile_section_id: 1350,
    name: "UB254X102X22",
    type: "UB Shape",
    designation: "UB254X102X22",
    shape: "I",
    country_code: "British",
    ax: 28,
    ax_global: 2800,
    bf: "101,6",
    bf_global: "101,6",
    ct: "3,45",
    ct_global: "34,5",
    d: 254,
    d_global: 254,
    ix: "4,15",
    ix_global: 41500,
    iy: 119,
    iy_global: 1190000,
    iz: 2840,
    iz_global: 28400000,
    tf: "6,8",
    tf_global: "6,8",
    tw: "5,7",
    tw_global: "5,7",
    zx: 259,
    zx_global: 259000,
    zy: "37,3",
    zy_global: 37300,
    width: "101,6",
    width_global: "101,6",
    thickness: "6,8",
    thickness_global: "6,8",
    height: 254,
    height_global: 254,
  },
  {
    profile_section_id: 1351,
    name: "UB203X133X30",
    type: "UB Shape",
    designation: "UB203X133X30",
    shape: "I",
    country_code: "British",
    ax: "38,2",
    ax_global: 3820,
    bf: "133,9",
    bf_global: "133,9",
    ct: "2,11",
    ct_global: "21,1",
    d: "206,8",
    d_global: "206,8",
    ix: "10,3",
    ix_global: 103000,
    iy: 385,
    iy_global: 3850000,
    iz: 2900,
    iz_global: 29000000,
    tf: "9,6",
    tf_global: "9,6",
    tw: "6,4",
    tw_global: "6,4",
    zx: 314,
    zx_global: 314000,
    zy: "88,2",
    zy_global: 88200,
    width: "133,9",
    width_global: "133,9",
    thickness: "9,6",
    thickness_global: "9,6",
    height: "206,8",
    height_global: "206,8",
  },
  {
    profile_section_id: 1352,
    name: "UB203X133X25",
    type: "UB Shape",
    designation: "UB203X133X25",
    shape: "I",
    country_code: "British",
    ax: 32,
    ax_global: 3200,
    bf: "133,2",
    bf_global: "133,2",
    ct: "2,1",
    ct_global: 21,
    d: "203,2",
    d_global: "203,2",
    ix: "5,96",
    ix_global: 59600,
    iy: 308,
    iy_global: 3080000,
    iz: 2340,
    iz_global: 23400000,
    tf: "7,8",
    tf_global: "7,8",
    tw: "5,7",
    tw_global: "5,7",
    zx: 258,
    zx_global: 258000,
    zy: "70,9",
    zy_global: 70900,
    width: "133,2",
    width_global: "133,2",
    thickness: "7,8",
    thickness_global: "7,8",
    height: "203,2",
    height_global: "203,2",
  },
  {
    profile_section_id: 1353,
    name: "UB203X102X23",
    type: "UB Shape",
    designation: "UB203X102X23",
    shape: "I",
    country_code: "British",
    ax: "29,4",
    ax_global: 2940,
    bf: "101,8",
    bf_global: "101,8",
    ct: 0,
    ct_global: 0,
    d: "203,2",
    d_global: "203,2",
    ix: "7,02",
    ix_global: 70200,
    iy: 164,
    iy_global: 1640000,
    iz: 2100,
    iz_global: 21000000,
    tf: "9,3",
    tf_global: "9,3",
    tw: "5,4",
    tw_global: "5,4",
    zx: 234,
    zx_global: 234000,
    zy: "49,7",
    zy_global: 49700,
    width: "101,8",
    width_global: "101,8",
    thickness: "9,3",
    thickness_global: "9,3",
    height: "203,2",
    height_global: "203,2",
  },
  {
    profile_section_id: 1354,
    name: "UB178X102X19",
    type: "UB Shape",
    designation: "UB178X102X19",
    shape: "I",
    country_code: "British",
    ax: "24,3",
    ax_global: 2430,
    bf: "101,2",
    bf_global: "101,2",
    ct: 0,
    ct_global: 0,
    d: "177,8",
    d_global: "177,8",
    ix: "4,41",
    ix_global: 44100,
    iy: 137,
    iy_global: 1370000,
    iz: 1360,
    iz_global: 13600000,
    tf: "7,9",
    tf_global: "7,9",
    tw: "4,8",
    tw_global: "4,8",
    zx: 171,
    zx_global: 171000,
    zy: "41,6",
    zy_global: 41600,
    width: "101,2",
    width_global: "101,2",
    thickness: "7,9",
    thickness_global: "7,9",
    height: "177,8",
    height_global: "177,8",
  },
  {
    profile_section_id: 1355,
    name: "UB152X89X16",
    type: "UB Shape",
    designation: "UB152X89X16",
    shape: "I",
    country_code: "British",
    ax: "20,3",
    ax_global: 2030,
    bf: "88,7",
    bf_global: "88,7",
    ct: 0,
    ct_global: 0,
    d: "152,4",
    d_global: "152,4",
    ix: "3,56",
    ix_global: 35600,
    iy: "89,8",
    iy_global: 898000,
    iz: 834,
    iz_global: 8340000,
    tf: "7,7",
    tf_global: "7,7",
    tw: "4,5",
    tw_global: "4,5",
    zx: 123,
    zx_global: 123000,
    zy: "31,2",
    zy_global: 31200,
    width: "88,7",
    width_global: "88,7",
    thickness: "7,7",
    thickness_global: "7,7",
    height: "152,4",
    height_global: "152,4",
  },
  {
    profile_section_id: 1356,
    name: "UB127X76X13",
    type: "UB Shape",
    designation: "UB127X76X13",
    shape: "I",
    country_code: "British",
    ax: "16,5",
    ax_global: 1650,
    bf: 76,
    bf_global: 76,
    ct: 0,
    ct_global: 0,
    d: 127,
    d_global: 127,
    ix: "2,85",
    ix_global: 28500,
    iy: "55,7",
    iy_global: 557000,
    iz: 473,
    iz_global: 4730000,
    tf: "7,6",
    tf_global: "7,6",
    tw: 4,
    tw_global: 4,
    zx: "84,2",
    zx_global: 84200,
    zy: "22,6",
    zy_global: 22600,
    width: 76,
    width_global: 76,
    thickness: "7,6",
    thickness_global: "7,6",
    height: 127,
    height_global: 127,
  },
  {
    profile_section_id: 1357,
    name: "UC356X406X634",
    type: "UC Shape",
    designation: "UC356X406X634",
    shape: "I",
    country_code: "British",
    ax: 808,
    ax_global: 80800,
    bf: 424,
    bf_global: 424,
    ct: "6,1",
    ct_global: 61,
    d: "474,6",
    d_global: "474,6",
    ix: 13700,
    ix_global: 137000000,
    iy: 98100,
    iy_global: 981000000,
    iz: 275000,
    iz_global: 2750000000,
    tf: 77,
    tf_global: 77,
    tw: "47,6",
    tw_global: "47,6",
    zx: 14200,
    zx_global: 14200000,
    zy: 7110,
    zy_global: 7110000,
    width: 424,
    width_global: 424,
    thickness: 77,
    thickness_global: 77,
    height: "474,6",
    height_global: "474,6",
  },
  {
    profile_section_id: 1358,
    name: "UC356X406X551",
    type: "UC Shape",
    designation: "UC356X406X551",
    shape: "I",
    country_code: "British",
    ax: 702,
    ax_global: 70200,
    bf: "418,5",
    bf_global: "418,5",
    ct: "5,58",
    ct_global: "55,8",
    d: "455,6",
    d_global: "455,6",
    ix: 9240,
    ix_global: 92400000,
    iy: 82700,
    iy_global: 827000000,
    iz: 227000,
    iz_global: 2270000000,
    tf: "67,5",
    tf_global: "67,5",
    tw: "42,1",
    tw_global: "42,1",
    zx: 12100,
    zx_global: 12100000,
    zy: 6060,
    zy_global: 6060000,
    width: "418,5",
    width_global: "418,5",
    thickness: "67,5",
    thickness_global: "67,5",
    height: "455,6",
    height_global: "455,6",
  },
  {
    profile_section_id: 1359,
    name: "UC356X406X467",
    type: "UC Shape",
    designation: "UC356X406X467",
    shape: "I",
    country_code: "British",
    ax: 595,
    ax_global: 59500,
    bf: "412,2",
    bf_global: "412,2",
    ct: "4,98",
    ct_global: "49,8",
    d: "436,6",
    d_global: "436,6",
    ix: 5810,
    ix_global: 58100000,
    iy: 67800,
    iy_global: 678000000,
    iz: 183000,
    iz_global: 1830000000,
    tf: 58,
    tf_global: 58,
    tw: "35,8",
    tw_global: "35,8",
    zx: 10000,
    zx_global: 10000000,
    zy: 5030,
    zy_global: 5030000,
    width: "412,2",
    width_global: "412,2",
    thickness: 58,
    thickness_global: 58,
    height: "436,6",
    height_global: "436,6",
  },
  {
    profile_section_id: 1360,
    name: "UC356X406X393",
    type: "UC Shape",
    designation: "UC356X406X393",
    shape: "I",
    country_code: "British",
    ax: 501,
    ax_global: 50100,
    bf: 407,
    bf_global: 407,
    ct: "4,52",
    ct_global: "45,2",
    d: 419,
    d_global: 419,
    ix: 3550,
    ix_global: 35500000,
    iy: 55400,
    iy_global: 554000000,
    iz: 147000,
    iz_global: 1470000000,
    tf: "49,2",
    tf_global: "49,2",
    tw: "30,6",
    tw_global: "30,6",
    zx: 8220,
    zx_global: 8220000,
    zy: 4150,
    zy_global: 4150000,
    width: 407,
    width_global: 407,
    thickness: "49,2",
    thickness_global: "49,2",
    height: 419,
    height_global: 419,
  },
  {
    profile_section_id: 1361,
    name: "UC356X406X340",
    type: "UC Shape",
    designation: "UC356X406X340",
    shape: "I",
    country_code: "British",
    ax: 433,
    ax_global: 43300,
    bf: 403,
    bf_global: 403,
    ct: "4,16",
    ct_global: "41,6",
    d: "406,4",
    d_global: "406,4",
    ix: 2340,
    ix_global: 23400000,
    iy: 46900,
    iy_global: 469000000,
    iz: 123000,
    iz_global: 1230000000,
    tf: "42,9",
    tf_global: "42,9",
    tw: "26,6",
    tw_global: "26,6",
    zx: 7000,
    zx_global: 7000000,
    zy: 3540,
    zy_global: 3540000,
    width: 403,
    width_global: 403,
    thickness: "42,9",
    thickness_global: "42,9",
    height: "406,4",
    height_global: "406,4",
  },
  {
    profile_section_id: 1362,
    name: "UC356X406X287",
    type: "UC Shape",
    designation: "UC356X406X287",
    shape: "I",
    country_code: "British",
    ax: 366,
    ax_global: 36600,
    bf: 399,
    bf_global: 399,
    ct: "37,86",
    ct_global: "378,6",
    d: "393,6",
    d_global: "393,6",
    ix: 1440,
    ix_global: 14400000,
    iy: 38700,
    iy_global: 387000000,
    iz: 99900,
    iz_global: 999000000,
    tf: "36,5",
    tf_global: "36,5",
    tw: "22,6",
    tw_global: "22,6",
    zx: 5810,
    zx_global: 5810000,
    zy: 2950,
    zy_global: 2950000,
    width: 399,
    width_global: 399,
    thickness: "36,5",
    thickness_global: "36,5",
    height: "393,6",
    height_global: "393,6",
  },
  {
    profile_section_id: 1363,
    name: "UC356X406X235",
    type: "UC Shape",
    designation: "UC356X406X235",
    shape: "I",
    country_code: "British",
    ax: 299,
    ax_global: 29900,
    bf: "394,8",
    bf_global: "394,8",
    ct: "3,4",
    ct_global: 34,
    d: 381,
    d_global: 381,
    ix: 812,
    ix_global: 8120000,
    iy: 31000,
    iy_global: 310000000,
    iz: 79100,
    iz_global: 791000000,
    tf: "30,2",
    tf_global: "30,2",
    tw: "18,4",
    tw_global: "18,4",
    zx: 4690,
    zx_global: 4690000,
    zy: 2380,
    zy_global: 2380000,
    width: "394,8",
    width_global: "394,8",
    thickness: "30,2",
    thickness_global: "30,2",
    height: 381,
    height_global: 381,
  },
  {
    profile_section_id: 1364,
    name: "UC356X368X202",
    type: "UC Shape",
    designation: "UC356X368X202",
    shape: "I",
    country_code: "British",
    ax: 257,
    ax_global: 25700,
    bf: "374,7",
    bf_global: "374,7",
    ct: "3,29",
    ct_global: "32,9",
    d: "374,6",
    d_global: "374,6",
    ix: 558,
    ix_global: 5580000,
    iy: 23700,
    iy_global: 237000000,
    iz: 66300,
    iz_global: 663000000,
    tf: 27,
    tf_global: 27,
    tw: "16,5",
    tw_global: "16,5",
    zx: 3970,
    zx_global: 3970000,
    zy: 1920,
    zy_global: 1920000,
    width: "374,7",
    width_global: "374,7",
    thickness: 27,
    thickness_global: 27,
    height: "374,6",
    height_global: "374,6",
  },
  {
    profile_section_id: 1365,
    name: "UC356X368X177",
    type: "UC Shape",
    designation: "UC356X368X177",
    shape: "I",
    country_code: "British",
    ax: 226,
    ax_global: 22600,
    bf: "372,6",
    bf_global: "372,6",
    ct: "3,09",
    ct_global: "30,9",
    d: "368,2",
    d_global: "368,2",
    ix: 381,
    ix_global: 3810000,
    iy: 20500,
    iy_global: 205000000,
    iz: 57100,
    iz_global: 571000000,
    tf: "23,8",
    tf_global: "23,8",
    tw: "14,4",
    tw_global: "14,4",
    zx: 3460,
    zx_global: 3460000,
    zy: 1670,
    zy_global: 1670000,
    width: "372,6",
    width_global: "372,6",
    thickness: "23,8",
    thickness_global: "23,8",
    height: "368,2",
    height_global: "368,2",
  },
  {
    profile_section_id: 1366,
    name: "UC356X368X153",
    type: "UC Shape",
    designation: "UC356X368X153",
    shape: "I",
    country_code: "British",
    ax: 195,
    ax_global: 19500,
    bf: "370,5",
    bf_global: "370,5",
    ct: "2,88",
    ct_global: "28,8",
    d: 362,
    d_global: 362,
    ix: 251,
    ix_global: 2510000,
    iy: 17600,
    iy_global: 176000000,
    iz: 48600,
    iz_global: 486000000,
    tf: "20,7",
    tf_global: "20,7",
    tw: "12,3",
    tw_global: "12,3",
    zx: 2960,
    zx_global: 2960000,
    zy: 1430,
    zy_global: 1430000,
    width: "370,5",
    width_global: "370,5",
    thickness: "20,7",
    thickness_global: "20,7",
    height: 362,
    height_global: 362,
  },
  {
    profile_section_id: 1367,
    name: "UC356X368X129",
    type: "UC Shape",
    designation: "UC356X368X129",
    shape: "I",
    country_code: "British",
    ax: 164,
    ax_global: 16400,
    bf: "368,6",
    bf_global: "368,6",
    ct: "2,69",
    ct_global: "26,9",
    d: "355,6",
    d_global: "355,6",
    ix: 153,
    ix_global: 1530000,
    iy: 14600,
    iy_global: 146000000,
    iz: 40200,
    iz_global: 402000000,
    tf: "17,5",
    tf_global: "17,5",
    tw: "10,4",
    tw_global: "10,4",
    zx: 2480,
    zx_global: 2480000,
    zy: 1200,
    zy_global: 1200000,
    width: "368,6",
    width_global: "368,6",
    thickness: "17,5",
    thickness_global: "17,5",
    height: "355,6",
    height_global: "355,6",
  },
  {
    profile_section_id: 1368,
    name: "UC305X305X283",
    type: "UC Shape",
    designation: "UC305X305X283",
    shape: "I",
    country_code: "British",
    ax: 360,
    ax_global: 36000,
    bf: "322,2",
    bf_global: "322,2",
    ct: "4,1",
    ct_global: 41,
    d: "365,3",
    d_global: "365,3",
    ix: 2030,
    ix_global: 20300000,
    iy: 24600,
    iy_global: 246000000,
    iz: 78900,
    iz_global: 789000000,
    tf: "44,1",
    tf_global: "44,1",
    tw: "26,8",
    tw_global: "26,8",
    zx: 5110,
    zx_global: 5110000,
    zy: 2340,
    zy_global: 2340000,
    width: "322,2",
    width_global: "322,2",
    thickness: "44,1",
    thickness_global: "44,1",
    height: "365,3",
    height_global: "365,3",
  },
  {
    profile_section_id: 1369,
    name: "UC305X305X240",
    type: "UC Shape",
    designation: "UC305X305X240",
    shape: "I",
    country_code: "British",
    ax: 306,
    ax_global: 30600,
    bf: "318,4",
    bf_global: "318,4",
    ct: "3,74",
    ct_global: "37,4",
    d: "352,5",
    d_global: "352,5",
    ix: 1270,
    ix_global: 12700000,
    iy: 20300,
    iy_global: 203000000,
    iz: 64200,
    iz_global: 642000000,
    tf: "37,7",
    tf_global: "37,7",
    tw: 23,
    tw_global: 23,
    zx: 4250,
    zx_global: 4250000,
    zy: 1950,
    zy_global: 1950000,
    width: "318,4",
    width_global: "318,4",
    thickness: "37,7",
    thickness_global: "37,7",
    height: "352,5",
    height_global: "352,5",
  },
  {
    profile_section_id: 1370,
    name: "UC305X305X198",
    type: "UC Shape",
    designation: "UC305X305X198",
    shape: "I",
    country_code: "British",
    ax: 252,
    ax_global: 25200,
    bf: "314,5",
    bf_global: "314,5",
    ct: "3,37",
    ct_global: "33,7",
    d: "339,9",
    d_global: "339,9",
    ix: 734,
    ix_global: 7340000,
    iy: 16300,
    iy_global: 163000000,
    iz: 50900,
    iz_global: 509000000,
    tf: "31,4",
    tf_global: "31,4",
    tw: "19,1",
    tw_global: "19,1",
    zx: 3440,
    zx_global: 3440000,
    zy: 1580,
    zy_global: 1580000,
    width: "314,5",
    width_global: "314,5",
    thickness: "31,4",
    thickness_global: "31,4",
    height: "339,9",
    height_global: "339,9",
  },
  {
    profile_section_id: 1371,
    name: "UC305X305X158",
    type: "UC Shape",
    designation: "UC305X305X158",
    shape: "I",
    country_code: "British",
    ax: 201,
    ax_global: 20100,
    bf: "311,2",
    bf_global: "311,2",
    ct: "3,04",
    ct_global: "30,4",
    d: "327,1",
    d_global: "327,1",
    ix: 378,
    ix_global: 3780000,
    iy: 12600,
    iy_global: 126000000,
    iz: 38700,
    iz_global: 387000000,
    tf: 25,
    tf_global: 25,
    tw: "15,8",
    tw_global: "15,8",
    zx: 2680,
    zx_global: 2680000,
    zy: 1230,
    zy_global: 1230000,
    width: "311,2",
    width_global: "311,2",
    thickness: 25,
    thickness_global: 25,
    height: "327,1",
    height_global: "327,1",
  },
  {
    profile_section_id: 1372,
    name: "UC305X305X137",
    type: "UC Shape",
    designation: "UC305X305X137",
    shape: "I",
    country_code: "British",
    ax: 174,
    ax_global: 17400,
    bf: "309,2",
    bf_global: "309,2",
    ct: "2,86",
    ct_global: "28,6",
    d: "320,5",
    d_global: "320,5",
    ix: 249,
    ix_global: 2490000,
    iy: 10700,
    iy_global: 107000000,
    iz: 32800,
    iz_global: 328000000,
    tf: "21,7",
    tf_global: "21,7",
    tw: "13,8",
    tw_global: "13,8",
    zx: 2300,
    zx_global: 2300000,
    zy: 1050,
    zy_global: 1050000,
    width: "309,2",
    width_global: "309,2",
    thickness: "21,7",
    thickness_global: "21,7",
    height: "320,5",
    height_global: "320,5",
  },
  {
    profile_section_id: 1373,
    name: "UC305X305X118",
    type: "UC Shape",
    designation: "UC305X305X118",
    shape: "I",
    country_code: "British",
    ax: 150,
    ax_global: 15000,
    bf: "307,4",
    bf_global: "307,4",
    ct: "2,69",
    ct_global: "26,9",
    d: "314,5",
    d_global: "314,5",
    ix: 161,
    ix_global: 1610000,
    iy: 9060,
    iy_global: 90600000,
    iz: 27700,
    iz_global: 277000000,
    tf: "18,7",
    tf_global: "18,7",
    tw: 12,
    tw_global: 12,
    zx: 1960,
    zx_global: 1960000,
    zy: 895,
    zy_global: 895000,
    width: "307,4",
    width_global: "307,4",
    thickness: "18,7",
    thickness_global: "18,7",
    height: "314,5",
    height_global: "314,5",
  },
  {
    profile_section_id: 1374,
    name: "UC305X305X97",
    type: "UC Shape",
    designation: "UC305X305X97",
    shape: "I",
    country_code: "British",
    ax: 123,
    ax_global: 12300,
    bf: "305,3",
    bf_global: "305,3",
    ct: "2,5",
    ct_global: 25,
    d: "307,9",
    d_global: "307,9",
    ix: "91,2",
    ix_global: 912000,
    iy: 7310,
    iy_global: 73100000,
    iz: 22200,
    iz_global: 222000000,
    tf: "15,4",
    tf_global: "15,4",
    tw: "9,9",
    tw_global: "9,9",
    zx: 1590,
    zx_global: 1590000,
    zy: 726,
    zy_global: 726000,
    width: "305,3",
    width_global: "305,3",
    thickness: "15,4",
    thickness_global: "15,4",
    height: "307,9",
    height_global: "307,9",
  },
  {
    profile_section_id: 1375,
    name: "UC254X254X167",
    type: "UC Shape",
    designation: "UC254X254X167",
    shape: "I",
    country_code: "British",
    ax: 213,
    ax_global: 21300,
    bf: "265,2",
    bf_global: "265,2",
    ct: "3,07",
    ct_global: "30,7",
    d: "289,1",
    d_global: "289,1",
    ix: 626,
    ix_global: 6260000,
    iy: 9870,
    iy_global: 98700000,
    iz: 30000,
    iz_global: 300000000,
    tf: "31,7",
    tf_global: "31,7",
    tw: "19,2",
    tw_global: "19,2",
    zx: 2420,
    zx_global: 2420000,
    zy: 1140,
    zy_global: 1140000,
    width: "265,2",
    width_global: "265,2",
    thickness: "31,7",
    thickness_global: "31,7",
    height: "289,1",
    height_global: "289,1",
  },
  {
    profile_section_id: 1376,
    name: "UC254X254X132",
    type: "UC Shape",
    designation: "UC254X254X132",
    shape: "I",
    country_code: "British",
    ax: 168,
    ax_global: 16800,
    bf: "261,3",
    bf_global: "261,3",
    ct: "2,7",
    ct_global: 27,
    d: "276,3",
    d_global: "276,3",
    ix: 319,
    ix_global: 3190000,
    iy: 7530,
    iy_global: 75300000,
    iz: 22500,
    iz_global: 225000000,
    tf: "25,3",
    tf_global: "25,3",
    tw: "15,3",
    tw_global: "15,3",
    zx: 1870,
    zx_global: 1870000,
    zy: 878,
    zy_global: 878000,
    width: "261,3",
    width_global: "261,3",
    thickness: "25,3",
    thickness_global: "25,3",
    height: "276,3",
    height_global: "276,3",
  },
  {
    profile_section_id: 1377,
    name: "UC254X254X107",
    type: "UC Shape",
    designation: "UC254X254X107",
    shape: "I",
    country_code: "British",
    ax: 136,
    ax_global: 13600,
    bf: "258,8",
    bf_global: "258,8",
    ct: "2,45",
    ct_global: "24,5",
    d: "266,7",
    d_global: "266,7",
    ix: 172,
    ix_global: 1720000,
    iy: 5930,
    iy_global: 59300000,
    iz: 17500,
    iz_global: 175000000,
    tf: "20,5",
    tf_global: "20,5",
    tw: "12,8",
    tw_global: "12,8",
    zx: 1480,
    zx_global: 1480000,
    zy: 697,
    zy_global: 697000,
    width: "258,8",
    width_global: "258,8",
    thickness: "20,5",
    thickness_global: "20,5",
    height: "266,7",
    height_global: "266,7",
  },
  {
    profile_section_id: 1378,
    name: "UC254X254X89",
    type: "UC Shape",
    designation: "UC254X254X89",
    shape: "I",
    country_code: "British",
    ax: 113,
    ax_global: 11300,
    bf: "256,3",
    bf_global: "256,3",
    ct: "2,21",
    ct_global: "22,1",
    d: "260,3",
    d_global: "260,3",
    ix: 102,
    ix_global: 1020000,
    iy: 4860,
    iy_global: 48600000,
    iz: 14300,
    iz_global: 143000000,
    tf: "17,3",
    tf_global: "17,3",
    tw: "10,3",
    tw_global: "10,3",
    zx: 1220,
    zx_global: 1220000,
    zy: 575,
    zy_global: 575000,
    width: "256,3",
    width_global: "256,3",
    thickness: "17,3",
    thickness_global: "17,3",
    height: "260,3",
    height_global: "260,3",
  },
  {
    profile_section_id: 1379,
    name: "UC254X254X73",
    type: "UC Shape",
    designation: "UC254X254X73",
    shape: "I",
    country_code: "British",
    ax: "93,1",
    ax_global: 9310,
    bf: "254,6",
    bf_global: "254,6",
    ct: "2,05",
    ct_global: "20,5",
    d: "254,1",
    d_global: "254,1",
    ix: "57,6",
    ix_global: 576000,
    iy: 3910,
    iy_global: 39100000,
    iz: 11400,
    iz_global: 114000000,
    tf: "14,2",
    tf_global: "14,2",
    tw: "8,6",
    tw_global: "8,6",
    zx: 992,
    zx_global: 992000,
    zy: 465,
    zy_global: 465000,
    width: "254,6",
    width_global: "254,6",
    thickness: "14,2",
    thickness_global: "14,2",
    height: "254,1",
    height_global: "254,1",
  },
  {
    profile_section_id: 1380,
    name: "UC203X203X127",
    type: "UC Shape",
    designation: "UC203X203X127",
    shape: "I",
    country_code: "British",
    ax: 162,
    ax_global: 16200,
    bf: "213,9",
    bf_global: "213,9",
    ct: "2,73",
    ct_global: "27,3",
    d: "241,4",
    d_global: "241,4",
    ix: 427,
    ix_global: 4270000,
    iy: 4920,
    iy_global: 49200000,
    iz: 15400,
    iz_global: 154000000,
    tf: "30,1",
    tf_global: "30,1",
    tw: "18,1",
    tw_global: "18,1",
    zx: 1520,
    zx_global: 1520000,
    zy: 704,
    zy_global: 704000,
    width: "213,9",
    width_global: "213,9",
    thickness: "30,1",
    thickness_global: "30,1",
    height: "241,4",
    height_global: "241,4",
  },
  {
    profile_section_id: 1381,
    name: "UC203X203X113",
    type: "UC Shape",
    designation: "UC203X203X113",
    shape: "I",
    country_code: "British",
    ax: 145,
    ax_global: 14500,
    bf: "212,1",
    bf_global: "212,1",
    ct: "2,56",
    ct_global: "25,6",
    d: 235,
    d_global: 235,
    ix: 305,
    ix_global: 3050000,
    iy: 4290,
    iy_global: 42900000,
    iz: 13300,
    iz_global: 133000000,
    tf: "26,9",
    tf_global: "26,9",
    tw: "16,3",
    tw_global: "16,3",
    zx: 1330,
    zx_global: 1330000,
    zy: 618,
    zy_global: 618000,
    width: "212,1",
    width_global: "212,1",
    thickness: "26,9",
    thickness_global: "26,9",
    height: 235,
    height_global: 235,
  },
  {
    profile_section_id: 1382,
    name: "UC203X203X100",
    type: "UC Shape",
    designation: "UC203X203X100",
    shape: "I",
    country_code: "British",
    ax: 127,
    ax_global: 12700,
    bf: "210,3",
    bf_global: "210,3",
    ct: "2,38",
    ct_global: "23,8",
    d: "228,6",
    d_global: "228,6",
    ix: 210,
    ix_global: 2100000,
    iy: 3680,
    iy_global: 36800000,
    iz: 11300,
    iz_global: 113000000,
    tf: "23,7",
    tf_global: "23,7",
    tw: "14,5",
    tw_global: "14,5",
    zx: 1150,
    zx_global: 1150000,
    zy: 534,
    zy_global: 534000,
    width: "210,3",
    width_global: "210,3",
    thickness: "23,7",
    thickness_global: "23,7",
    height: "228,6",
    height_global: "228,6",
  },
  {
    profile_section_id: 1383,
    name: "UC203X203X86",
    type: "UC Shape",
    designation: "UC203X203X86",
    shape: "I",
    country_code: "British",
    ax: 110,
    ax_global: 11000,
    bf: "209,1",
    bf_global: "209,1",
    ct: "2,2",
    ct_global: 22,
    d: "222,2",
    d_global: "222,2",
    ix: 137,
    ix_global: 1370000,
    iy: 3130,
    iy_global: 31300000,
    iz: 9450,
    iz_global: 94500000,
    tf: "20,5",
    tf_global: "20,5",
    tw: "12,7",
    tw_global: "12,7",
    zx: 977,
    zx_global: 977000,
    zy: 456,
    zy_global: 456000,
    width: "209,1",
    width_global: "209,1",
    thickness: "20,5",
    thickness_global: "20,5",
    height: "222,2",
    height_global: "222,2",
  },
  {
    profile_section_id: 1384,
    name: "UC203X203X71",
    type: "UC Shape",
    designation: "UC203X203X71",
    shape: "I",
    country_code: "British",
    ax: "90,4",
    ax_global: 9040,
    bf: "206,4",
    bf_global: "206,4",
    ct: "1,95",
    ct_global: "19,5",
    d: "215,8",
    d_global: "215,8",
    ix: "80,2",
    ix_global: 802000,
    iy: 2540,
    iy_global: 25400000,
    iz: 7620,
    iz_global: 76200000,
    tf: "17,3",
    tf_global: "17,3",
    tw: 10,
    tw_global: 10,
    zx: 799,
    zx_global: 799000,
    zy: 374,
    zy_global: 374000,
    width: "206,4",
    width_global: "206,4",
    thickness: "17,3",
    thickness_global: "17,3",
    height: "215,8",
    height_global: "215,8",
  },
  {
    profile_section_id: 1385,
    name: "UC203X203X60",
    type: "UC Shape",
    designation: "UC203X203X60",
    shape: "I",
    country_code: "British",
    ax: "76,4",
    ax_global: 7640,
    bf: "205,8",
    bf_global: "205,8",
    ct: "1,89",
    ct_global: "18,9",
    d: "209,6",
    d_global: "209,6",
    ix: "47,2",
    ix_global: 472000,
    iy: 2060,
    iy_global: 20600000,
    iz: 6120,
    iz_global: 61200000,
    tf: "14,2",
    tf_global: "14,2",
    tw: "9,4",
    tw_global: "9,4",
    zx: 656,
    zx_global: 656000,
    zy: 305,
    zy_global: 305000,
    width: "205,8",
    width_global: "205,8",
    thickness: "14,2",
    thickness_global: "14,2",
    height: "209,6",
    height_global: "209,6",
  },
  {
    profile_section_id: 1386,
    name: "UC203X203X52",
    type: "UC Shape",
    designation: "UC203X203X52",
    shape: "I",
    country_code: "British",
    ax: "66,3",
    ax_global: 6630,
    bf: "204,3",
    bf_global: "204,3",
    ct: "1,75",
    ct_global: "17,5",
    d: "206,2",
    d_global: "206,2",
    ix: "31,8",
    ix_global: 318000,
    iy: 1780,
    iy_global: 17800000,
    iz: 5260,
    iz_global: 52600000,
    tf: "12,5",
    tf_global: "12,5",
    tw: "7,9",
    tw_global: "7,9",
    zx: 567,
    zx_global: 567000,
    zy: 264,
    zy_global: 264000,
    width: "204,3",
    width_global: "204,3",
    thickness: "12,5",
    thickness_global: "12,5",
    height: "206,2",
    height_global: "206,2",
  },
  {
    profile_section_id: 1387,
    name: "UC203X203X46",
    type: "UC Shape",
    designation: "UC203X203X46",
    shape: "I",
    country_code: "British",
    ax: "58,7",
    ax_global: 5870,
    bf: "203,6",
    bf_global: "203,6",
    ct: "1,69",
    ct_global: "16,9",
    d: "203,2",
    d_global: "203,2",
    ix: "22,2",
    ix_global: 222000,
    iy: 1550,
    iy_global: 15500000,
    iz: 4570,
    iz_global: 45700000,
    tf: 11,
    tf_global: 11,
    tw: "7,2",
    tw_global: "7,2",
    zx: 497,
    zx_global: 497000,
    zy: 231,
    zy_global: 231000,
    width: "203,6",
    width_global: "203,6",
    thickness: 11,
    thickness_global: 11,
    height: "203,2",
    height_global: "203,2",
  },
  {
    profile_section_id: 1388,
    name: "UC152X152X51",
    type: "UC Shape",
    designation: "UC152X152X51",
    shape: "I",
    country_code: "British",
    ax: "65,2",
    ax_global: 6520,
    bf: "157,4",
    bf_global: "157,4",
    ct: "1,79",
    ct_global: "17,9",
    d: "170,2",
    d_global: "170,2",
    ix: "48,8",
    ix_global: 488000,
    iy: 1020,
    iy_global: 10200000,
    iz: 3230,
    iz_global: 32300000,
    tf: "15,7",
    tf_global: "15,7",
    tw: 11,
    tw_global: 11,
    zx: 438,
    zx_global: 438000,
    zy: 199,
    zy_global: 199000,
    width: "157,4",
    width_global: "157,4",
    thickness: "15,7",
    thickness_global: "15,7",
    height: "170,2",
    height_global: "170,2",
  },
  {
    profile_section_id: 1389,
    name: "UC152X152X44",
    type: "UC Shape",
    designation: "UC152X152X44",
    shape: "I",
    country_code: "British",
    ax: "56,1",
    ax_global: 5610,
    bf: "155,9",
    bf_global: "155,9",
    ct: "1,66",
    ct_global: "16,6",
    d: 166,
    d_global: 166,
    ix: "31,7",
    ix_global: 317000,
    iy: 860,
    iy_global: 8600000,
    iz: 2700,
    iz_global: 27000000,
    tf: "13,6",
    tf_global: "13,6",
    tw: "9,5",
    tw_global: "9,5",
    zx: 372,
    zx_global: 372000,
    zy: 169,
    zy_global: 169000,
    width: "155,9",
    width_global: "155,9",
    thickness: "13,6",
    thickness_global: "13,6",
    height: 166,
    height_global: 166,
  },
  {
    profile_section_id: 1390,
    name: "UC152X152X37",
    type: "UC Shape",
    designation: "UC152X152X37",
    shape: "I",
    country_code: "British",
    ax: "47,1",
    ax_global: 4710,
    bf: "154,4",
    bf_global: "154,4",
    ct: "1,53",
    ct_global: "15,3",
    d: "161,8",
    d_global: "161,8",
    ix: "19,2",
    ix_global: 192000,
    iy: 706,
    iy_global: 7060000,
    iz: 2210,
    iz_global: 22100000,
    tf: "11,5",
    tf_global: "11,5",
    tw: 8,
    tw_global: 8,
    zx: 309,
    zx_global: 309000,
    zy: 140,
    zy_global: 140000,
    width: "154,4",
    width_global: "154,4",
    thickness: "11,5",
    thickness_global: "11,5",
    height: "161,8",
    height_global: "161,8",
  },
  {
    profile_section_id: 1391,
    name: "UC152X152X30",
    type: "UC Shape",
    designation: "UC152X152X30",
    shape: "I",
    country_code: "British",
    ax: "38,3",
    ax_global: 3830,
    bf: "152,9",
    bf_global: "152,9",
    ct: "1,41",
    ct_global: "14,1",
    d: "157,6",
    d_global: "157,6",
    ix: "10,5",
    ix_global: 105000,
    iy: 560,
    iy_global: 5600000,
    iz: 1750,
    iz_global: 17500000,
    tf: "9,4",
    tf_global: "9,4",
    tw: "6,5",
    tw_global: "6,5",
    zx: 248,
    zx_global: 248000,
    zy: 112,
    zy_global: 112000,
    width: "152,9",
    width_global: "152,9",
    thickness: "9,4",
    thickness_global: "9,4",
    height: "157,6",
    height_global: "157,6",
  },
  {
    profile_section_id: 1392,
    name: "UC152X152X23",
    type: "UC Shape",
    designation: "UC152X152X23",
    shape: "I",
    country_code: "British",
    ax: "29,2",
    ax_global: 2920,
    bf: "152,2",
    bf_global: "152,2",
    ct: "1,39",
    ct_global: "13,9",
    d: "152,4",
    d_global: "152,4",
    ix: "4,63",
    ix_global: 46300,
    iy: 400,
    iy_global: 4000000,
    iz: 1250,
    iz_global: 12500000,
    tf: "6,8",
    tf_global: "6,8",
    tw: "5,8",
    tw_global: "5,8",
    zx: 182,
    zx_global: 182000,
    zy: "80,1",
    zy_global: 80100,
    width: "152,2",
    width_global: "152,2",
    thickness: "6,8",
    thickness_global: "6,8",
    height: "152,4",
    height_global: "152,4",
  },
  {
    profile_section_id: 1393,
    name: "UP356X368X174",
    type: "UP Shape",
    designation: "UP356X368X174",
    shape: "I",
    country_code: "British",
    ax: 221,
    ax_global: 22100,
    bf: "378,5",
    bf_global: "378,5",
    ct: "3,687",
    ct_global: "36,87",
    d: "361,4",
    d_global: "361,4",
    ix: 330,
    ix_global: 3300000,
    iy: 18500,
    iy_global: 185000000,
    iz: 51000,
    iz_global: 510000000,
    tf: "20,4",
    tf_global: "20,4",
    tw: "20,3",
    tw_global: "20,3",
    zx: 3190,
    zx_global: 3190000,
    zy: 1500,
    zy_global: 1500000,
    width: "378,5",
    width_global: "378,5",
    thickness: "20,4",
    thickness_global: "20,4",
    height: "361,4",
    height_global: "361,4",
  },
  {
    profile_section_id: 1394,
    name: "UP356X368X152",
    type: "UP Shape",
    designation: "UP356X368X152",
    shape: "I",
    country_code: "British",
    ax: 194,
    ax_global: 19400,
    bf: 376,
    bf_global: 376,
    ct: "3,533",
    ct_global: "35,33",
    d: "356,4",
    d_global: "356,4",
    ix: 223,
    ix_global: 2230000,
    iy: 15900,
    iy_global: 159000000,
    iz: 44000,
    iz_global: 440000000,
    tf: "17,9",
    tf_global: "17,9",
    tw: "17,8",
    tw_global: "17,8",
    zx: 2770,
    zx_global: 2770000,
    zy: 1290,
    zy_global: 1290000,
    width: 376,
    width_global: 376,
    thickness: "17,9",
    thickness_global: "17,9",
    height: "356,4",
    height_global: "356,4",
  },
  {
    profile_section_id: 1395,
    name: "UP356X368X133",
    type: "UP Shape",
    designation: "UP356X368X133",
    shape: "I",
    country_code: "British",
    ax: 169,
    ax_global: 16900,
    bf: "373,8",
    bf_global: "373,8",
    ct: "3,397",
    ct_global: "33,97",
    d: 352,
    d_global: 352,
    ix: 151,
    ix_global: 1510000,
    iy: 13700,
    iy_global: 137000000,
    iz: 38000,
    iz_global: 380000000,
    tf: "15,7",
    tf_global: "15,7",
    tw: "15,6",
    tw_global: "15,6",
    zx: 2410,
    zx_global: 2410000,
    zy: 1120,
    zy_global: 1120000,
    width: "373,8",
    width_global: "373,8",
    thickness: "15,7",
    thickness_global: "15,7",
    height: 352,
    height_global: 352,
  },
  {
    profile_section_id: 1396,
    name: "UP356X368X109",
    type: "UP Shape",
    designation: "UP356X368X109",
    shape: "I",
    country_code: "British",
    ax: 139,
    ax_global: 13900,
    bf: 371,
    bf_global: 371,
    ct: "3,221",
    ct_global: "32,21",
    d: "346,4",
    d_global: "346,4",
    ix: "84,6",
    ix_global: 846000,
    iy: 11000,
    iy_global: 110000000,
    iz: 30600,
    iz_global: 306000000,
    tf: "12,9",
    tf_global: "12,9",
    tw: "12,8",
    tw_global: "12,8",
    zx: 1960,
    zx_global: 1960000,
    zy: 903,
    zy_global: 903000,
    width: 371,
    width_global: 371,
    thickness: "12,9",
    thickness_global: "12,9",
    height: "346,4",
    height_global: "346,4",
  },
  {
    profile_section_id: 1397,
    name: "UP305X305X223",
    type: "UP Shape",
    designation: "UP305X305X223",
    shape: "I",
    country_code: "British",
    ax: 284,
    ax_global: 28400,
    bf: "325,7",
    bf_global: "325,7",
    ct: "4,031",
    ct_global: "40,31",
    d: "337,9",
    d_global: "337,9",
    ix: 943,
    ix_global: 9430000,
    iy: 17600,
    iy_global: 176000000,
    iz: 52700,
    iz_global: 527000000,
    tf: "30,4",
    tf_global: "30,4",
    tw: "30,3",
    tw_global: "30,3",
    zx: 3650,
    zx_global: 3650000,
    zy: 1680,
    zy_global: 1680000,
    width: "325,7",
    width_global: "325,7",
    thickness: "30,4",
    thickness_global: "30,4",
    height: "337,9",
    height_global: "337,9",
  },
  {
    profile_section_id: 1398,
    name: "UP305X305X186",
    type: "UP Shape",
    designation: "UP305X305X186",
    shape: "I",
    country_code: "British",
    ax: 237,
    ax_global: 23700,
    bf: "320,9",
    bf_global: "320,9",
    ct: "3,741",
    ct_global: "37,41",
    d: "328,3",
    d_global: "328,3",
    ix: 560,
    ix_global: 5600000,
    iy: 14100,
    iy_global: 141000000,
    iz: 42600,
    iz_global: 426000000,
    tf: "25,6",
    tf_global: "25,6",
    tw: "25,5",
    tw_global: "25,5",
    zx: 3000,
    zx_global: 3000000,
    zy: 1370,
    zy_global: 1370000,
    width: "320,9",
    width_global: "320,9",
    thickness: "25,6",
    thickness_global: "25,6",
    height: "328,3",
    height_global: "328,3",
  },
  {
    profile_section_id: 1399,
    name: "UP305X305X149",
    type: "UP Shape",
    designation: "UP305X305X149",
    shape: "I",
    country_code: "British",
    ax: 190,
    ax_global: 19000,
    bf: 316,
    bf_global: 316,
    ct: "3,443",
    ct_global: "34,43",
    d: "318,5",
    d_global: "318,5",
    ix: 295,
    ix_global: 2950000,
    iy: 10900,
    iy_global: 109000000,
    iz: 33100,
    iz_global: 331000000,
    tf: "20,7",
    tf_global: "20,7",
    tw: "20,6",
    tw_global: "20,6",
    zx: 2370,
    zx_global: 2370000,
    zy: 1070,
    zy_global: 1070000,
    width: 316,
    width_global: 316,
    thickness: "20,7",
    thickness_global: "20,7",
    height: "318,5",
    height_global: "318,5",
  },
  {
    profile_section_id: 1400,
    name: "UP305X305X126",
    type: "UP Shape",
    designation: "UP305X305X126",
    shape: "I",
    country_code: "British",
    ax: 161,
    ax_global: 16100,
    bf: "312,9",
    bf_global: "312,9",
    ct: "3,252",
    ct_global: "32,52",
    d: "312,3",
    d_global: "312,3",
    ix: 182,
    ix_global: 1820000,
    iy: 9000,
    iy_global: 90000000,
    iz: 27400,
    iz_global: 274000000,
    tf: "17,6",
    tf_global: "17,6",
    tw: "17,5",
    tw_global: "17,5",
    zx: 1990,
    zx_global: 1990000,
    zy: 885,
    zy_global: 885000,
    width: "312,9",
    width_global: "312,9",
    thickness: "17,6",
    thickness_global: "17,6",
    height: "312,3",
    height_global: "312,3",
  },
  {
    profile_section_id: 1401,
    name: "UP305X305X110",
    type: "UP Shape",
    designation: "UP305X305X110",
    shape: "I",
    country_code: "British",
    ax: 140,
    ax_global: 14000,
    bf: "310,7",
    bf_global: "310,7",
    ct: "3,115",
    ct_global: "31,15",
    d: "307,9",
    d_global: "307,9",
    ix: 122,
    ix_global: 1220000,
    iy: 7710,
    iy_global: 77100000,
    iz: 23600,
    iz_global: 236000000,
    tf: "15,4",
    tf_global: "15,4",
    tw: "15,3",
    tw_global: "15,3",
    zx: 1720,
    zx_global: 1720000,
    zy: 762,
    zy_global: 762000,
    width: "310,7",
    width_global: "310,7",
    thickness: "15,4",
    thickness_global: "15,4",
    height: "307,9",
    height_global: "307,9",
  },
  {
    profile_section_id: 1402,
    name: "UP305X305X95",
    type: "UP Shape",
    designation: "UP305X305X95",
    shape: "I",
    country_code: "British",
    ax: 121,
    ax_global: 12100,
    bf: "308,7",
    bf_global: "308,7",
    ct: "2,995",
    ct_global: "29,95",
    d: "303,7",
    d_global: "303,7",
    ix: 80,
    ix_global: 800000,
    iy: 6530,
    iy_global: 65300000,
    iz: 20000,
    iz_global: 200000000,
    tf: "13,3",
    tf_global: "13,3",
    tw: "13,3",
    tw_global: "13,3",
    zx: 1470,
    zx_global: 1470000,
    zy: 648,
    zy_global: 648000,
    width: "308,7",
    width_global: "308,7",
    thickness: "13,3",
    thickness_global: "13,3",
    height: "303,7",
    height_global: "303,7",
  },
  {
    profile_section_id: 1403,
    name: "UP305X305X88",
    type: "UP Shape",
    designation: "UP305X305X88",
    shape: "I",
    country_code: "British",
    ax: 112,
    ax_global: 11200,
    bf: "307,8",
    bf_global: "307,8",
    ct: "2,945",
    ct_global: "29,45",
    d: "301,7",
    d_global: "301,7",
    ix: "64,2",
    ix_global: 642000,
    iy: 5980,
    iy_global: 59800000,
    iz: 18400,
    iz_global: 184000000,
    tf: "12,3",
    tf_global: "12,3",
    tw: "12,4",
    tw_global: "12,4",
    zx: 1360,
    zx_global: 1360000,
    zy: 595,
    zy_global: 595000,
    width: "307,8",
    width_global: "307,8",
    thickness: "12,3",
    thickness_global: "12,3",
    height: "301,7",
    height_global: "301,7",
  },
  {
    profile_section_id: 1404,
    name: "UP305X305X79",
    type: "UP Shape",
    designation: "UP305X305X79",
    shape: "I",
    country_code: "British",
    ax: 100,
    ax_global: 10000,
    bf: "306,4",
    bf_global: "306,4",
    ct: "2,842",
    ct_global: "28,42",
    d: "299,3",
    d_global: "299,3",
    ix: "46,9",
    ix_global: 469000,
    iy: 5330,
    iy_global: 53300000,
    iz: 16400,
    iz_global: 164000000,
    tf: "11,1",
    tf_global: "11,1",
    tw: 11,
    tw_global: 11,
    zx: 1220,
    zx_global: 1220000,
    zy: 531,
    zy_global: 531000,
    width: "306,4",
    width_global: "306,4",
    thickness: "11,1",
    thickness_global: "11,1",
    height: "299,3",
    height_global: "299,3",
  },
  {
    profile_section_id: 1405,
    name: "UP254X254X85",
    type: "UP Shape",
    designation: "UP254X254X85",
    shape: "I",
    country_code: "British",
    ax: 108,
    ax_global: 10800,
    bf: "260,4",
    bf_global: "260,4",
    ct: "2,635",
    ct_global: "26,35",
    d: "254,3",
    d_global: "254,3",
    ix: "81,8",
    ix_global: 818000,
    iy: 4220,
    iy_global: 42200000,
    iz: 12300,
    iz_global: 123000000,
    tf: "14,3",
    tf_global: "14,3",
    tw: "14,4",
    tw_global: "14,4",
    zx: 1090,
    zx_global: 1090000,
    zy: 498,
    zy_global: 498000,
    width: "260,4",
    width_global: "260,4",
    thickness: "14,3",
    thickness_global: "14,3",
    height: "254,3",
    height_global: "254,3",
  },
  {
    profile_section_id: 1406,
    name: "UP254X254X71",
    type: "UP Shape",
    designation: "UP254X254X71",
    shape: "I",
    country_code: "British",
    ax: "90,4",
    ax_global: 9040,
    bf: 258,
    bf_global: 258,
    ct: "2,484",
    ct_global: "24,84",
    d: "249,7",
    d_global: "249,7",
    ix: "48,4",
    ix_global: 484000,
    iy: 3440,
    iy_global: 34400000,
    iz: 10100,
    iz_global: 101000000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 904,
    zx_global: 904000,
    zy: 409,
    zy_global: 409000,
    width: 258,
    width_global: 258,
    thickness: 12,
    thickness_global: 12,
    height: "249,7",
    height_global: "249,7",
  },
  {
    profile_section_id: 1407,
    name: "UP254X254X63",
    type: "UP Shape",
    designation: "UP254X254X63",
    shape: "I",
    country_code: "British",
    ax: "80,2",
    ax_global: 8020,
    bf: "256,6",
    bf_global: "256,6",
    ct: "2,391",
    ct_global: "23,91",
    d: "247,1",
    d_global: "247,1",
    ix: "34,3",
    ix_global: 343000,
    iy: 3020,
    iy_global: 30200000,
    iz: 8860,
    iz_global: 88600000,
    tf: "10,7",
    tf_global: "10,7",
    tw: "10,6",
    tw_global: "10,6",
    zx: 799,
    zx_global: 799000,
    zy: 360,
    zy_global: 360000,
    width: "256,6",
    width_global: "256,6",
    thickness: "10,7",
    thickness_global: "10,7",
    height: "247,1",
    height_global: "247,1",
  },
  {
    profile_section_id: 1408,
    name: "UP203X203X54",
    type: "UP Shape",
    designation: "UP203X203X54",
    shape: "I",
    country_code: "British",
    ax: "68,7",
    ax_global: 6870,
    bf: "207,7",
    bf_global: "207,7",
    ct: "2,1",
    ct_global: 21,
    d: 204,
    d_global: 204,
    ix: "32,7",
    ix_global: 327000,
    iy: 1710,
    iy_global: 17100000,
    iz: 5030,
    iz_global: 50300000,
    tf: "11,4",
    tf_global: "11,4",
    tw: "11,3",
    tw_global: "11,3",
    zx: 557,
    zx_global: 557000,
    zy: 252,
    zy_global: 252000,
    width: "207,7",
    width_global: "207,7",
    thickness: "11,4",
    thickness_global: "11,4",
    height: 204,
    height_global: 204,
  },
  {
    profile_section_id: 1409,
    name: "UP203X203X45",
    type: "UP Shape",
    designation: "UP203X203X45",
    shape: "I",
    country_code: "British",
    ax: "57,2",
    ax_global: 5720,
    bf: "205,9",
    bf_global: "205,9",
    ct: "1,991",
    ct_global: "19,91",
    d: "200,2",
    d_global: "200,2",
    ix: "19,2",
    ix_global: 192000,
    iy: 1380,
    iy_global: 13800000,
    iz: 4100,
    iz_global: 41000000,
    tf: "9,5",
    tf_global: "9,5",
    tw: "9,5",
    tw_global: "9,5",
    zx: 459,
    zx_global: 459000,
    zy: 206,
    zy_global: 206000,
    width: "205,9",
    width_global: "205,9",
    thickness: "9,5",
    thickness_global: "9,5",
    height: "200,2",
    height_global: "200,2",
  },
  {
    profile_section_id: 1410,
    name: "JO254X203X82",
    type: "JO Shape",
    designation: "JO254X203",
    shape: "I",
    country_code: "British",
    ax: 105,
    ax_global: 10500,
    bf: "203,2",
    bf_global: "203,2",
    ct: "2,346",
    ct_global: "23,46",
    d: 254,
    d_global: 254,
    ix: "166,6",
    ix_global: 1666000,
    iy: 2280,
    iy_global: 22800000,
    iz: 12020,
    iz_global: 120200000,
    tf: "19,9",
    tf_global: "19,9",
    tw: "10,2",
    tw_global: "10,2",
    zx: 1077,
    zx_global: 1077000,
    zy: 371,
    zy_global: 371000,
    width: "203,2",
    width_global: "203,2",
    thickness: "19,9",
    thickness_global: "19,9",
    height: 254,
    height_global: 254,
  },
  {
    profile_section_id: 1411,
    name: "JO254X114X37",
    type: "JO Shape",
    designation: "JO254X114",
    shape: "I",
    country_code: "British",
    ax: "47,4",
    ax_global: 4740,
    bf: "114,3",
    bf_global: "114,3",
    ct: "3,004",
    ct_global: "30,04",
    d: 254,
    d_global: 254,
    ix: "199,2",
    ix_global: 1992000,
    iy: 270,
    iy_global: 2700000,
    iz: 5092,
    iz_global: 50920000,
    tf: "12,8",
    tf_global: "12,8",
    tw: "7,6",
    tw_global: "7,6",
    zx: 460,
    zx_global: 460000,
    zy: 79,
    zy_global: 79000,
    width: "114,3",
    width_global: "114,3",
    thickness: "12,8",
    thickness_global: "12,8",
    height: 254,
    height_global: 254,
  },
  {
    profile_section_id: 1412,
    name: "JO203X152X52",
    type: "JO Shape",
    designation: "JO203X152",
    shape: "I",
    country_code: "British",
    ax: "66,6",
    ax_global: 6660,
    bf: "152,4",
    bf_global: "152,4",
    ct: "2,001",
    ct_global: "20,01",
    d: "203,2",
    d_global: "203,2",
    ix: "133,2",
    ix_global: 1332000,
    iy: 816,
    iy_global: 8160000,
    iz: 4798,
    iz_global: 47980000,
    tf: "16,5",
    tf_global: "16,5",
    tw: "8,9",
    tw_global: "8,9",
    zx: 541,
    zx_global: 541000,
    zy: 176,
    zy_global: 176000,
    width: "152,4",
    width_global: "152,4",
    thickness: "16,5",
    thickness_global: "16,5",
    height: "203,2",
    height_global: "203,2",
  },
  {
    profile_section_id: 1413,
    name: "JO203X102",
    type: "JO Shape",
    designation: "JO203X102",
    shape: "I",
    country_code: "British",
    ax: "32,3",
    ax_global: 3230,
    bf: "101,6",
    bf_global: "101,6",
    ct: "2,215",
    ct_global: "22,15",
    d: "203,2",
    d_global: "203,2",
    ix: 161,
    ix_global: 1610000,
    iy: 163,
    iy_global: 1630000,
    iz: 2294,
    iz_global: 22940000,
    tf: "10,4",
    tf_global: "10,4",
    tw: "5,8",
    tw_global: "5,8",
    zx: 256,
    zx_global: 256000,
    zy: 52,
    zy_global: 52000,
    width: "101,6",
    width_global: "101,6",
    thickness: "10,4",
    thickness_global: "10,4",
    height: "203,2",
    height_global: "203,2",
  },
  {
    profile_section_id: 1414,
    name: "JO178X102",
    type: "JO Shape",
    designation: "JO178X102",
    shape: "I",
    country_code: "British",
    ax: "27,4",
    ax_global: 2740,
    bf: "101,6",
    bf_global: "101,6",
    ct: "1,857",
    ct_global: "18,57",
    d: "177,8",
    d_global: "177,8",
    ix: "138,2",
    ix_global: 1382000,
    iy: 139,
    iy_global: 1390000,
    iz: 1519,
    iz_global: 15190000,
    tf: 9,
    tf_global: 9,
    tw: "5,3",
    tw_global: "5,3",
    zx: 193,
    zx_global: 193000,
    zy: 44,
    zy_global: 44000,
    width: "101,6",
    width_global: "101,6",
    thickness: 9,
    thickness_global: 9,
    height: "177,8",
    height_global: "177,8",
  },
  {
    profile_section_id: 1415,
    name: "JO152X127X37",
    type: "JO Shape",
    designation: "JO152X127",
    shape: "I",
    country_code: "British",
    ax: "47,5",
    ax_global: 4750,
    bf: 127,
    bf_global: 127,
    ct: "1,725",
    ct_global: "17,25",
    d: "152,4",
    d_global: "152,4",
    ix: "94,3",
    ix_global: 943000,
    iy: 378,
    iy_global: 3780000,
    iz: 1818,
    iz_global: 18180000,
    tf: "13,2",
    tf_global: "13,2",
    tw: "10,4",
    tw_global: "10,4",
    zx: 279,
    zx_global: 279000,
    zy: 100,
    zy_global: 100000,
    width: 127,
    width_global: 127,
    thickness: "13,2",
    thickness_global: "13,2",
    height: "152,4",
    height_global: "152,4",
  },
  {
    profile_section_id: 1416,
    name: "JO152X89",
    type: "JO Shape",
    designation: "JO152X89",
    shape: "I",
    country_code: "British",
    ax: "21,8",
    ax_global: 2180,
    bf: "88,9",
    bf_global: "88,9",
    ct: "1,594",
    ct_global: "15,94",
    d: "152,4",
    d_global: "152,4",
    ix: "117,7",
    ix_global: 1177000,
    iy: 86,
    iy_global: 860000,
    iz: 881,
    iz_global: 8810000,
    tf: "8,3",
    tf_global: "8,3",
    tw: "4,9",
    tw_global: "4,9",
    zx: 131,
    zx_global: 131000,
    zy: 31,
    zy_global: 31000,
    width: "88,9",
    width_global: "88,9",
    thickness: "8,3",
    thickness_global: "8,3",
    height: "152,4",
    height_global: "152,4",
  },
  {
    profile_section_id: 1417,
    name: "JO152X76",
    type: "JO Shape",
    designation: "JO152X76",
    shape: "I",
    country_code: "British",
    ax: "22,8",
    ax_global: 2280,
    bf: "76,2",
    bf_global: "76,2",
    ct: "1,791",
    ct_global: "17,91",
    d: "152,4",
    d_global: "152,4",
    ix: "111,9",
    ix_global: 1119000,
    iy: 61,
    iy_global: 610000,
    iz: 874,
    iz_global: 8740000,
    tf: "9,6",
    tf_global: "9,6",
    tw: "5,8",
    tw_global: "5,8",
    zx: 133,
    zx_global: 133000,
    zy: 27,
    zy_global: 27000,
    width: "76,2",
    width_global: "76,2",
    thickness: "9,6",
    thickness_global: "9,6",
    height: "152,4",
    height_global: "152,4",
  },
  {
    profile_section_id: 1418,
    name: "JO127X114X29",
    type: "JO Shape",
    designation: "JO127X114",
    shape: "I",
    country_code: "British",
    ax: "37,4",
    ax_global: 3740,
    bf: "114,3",
    bf_global: "114,3",
    ct: "1,488",
    ct_global: "14,88",
    d: 127,
    d_global: 127,
    ix: "79,5",
    ix_global: 795000,
    iy: 242,
    iy_global: 2420000,
    iz: 979,
    iz_global: 9790000,
    tf: "11,5",
    tf_global: "11,5",
    tw: "10,2",
    tw_global: "10,2",
    zx: 181,
    zx_global: 181000,
    zy: 71,
    zy_global: 71000,
    width: "114,3",
    width_global: "114,3",
    thickness: "11,5",
    thickness_global: "11,5",
    height: 127,
    height_global: 127,
  },
  {
    profile_section_id: 1419,
    name: "JO127X114X27",
    type: "JO Shape",
    designation: "JO127X114A",
    shape: "I",
    country_code: "British",
    ax: "34,2",
    ax_global: 3420,
    bf: "114,3",
    bf_global: "114,3",
    ct: "1,295",
    ct_global: "12,95",
    d: 127,
    d_global: 127,
    ix: "79,5",
    ix_global: 795000,
    iy: 236,
    iy_global: 2360000,
    iz: 946,
    iz_global: 9460000,
    tf: "11,4",
    tf_global: "11,4",
    tw: "7,4",
    tw_global: "7,4",
    zx: 172,
    zx_global: 172000,
    zy: 68,
    zy_global: 68000,
    width: "114,3",
    width_global: "114,3",
    thickness: "11,4",
    thickness_global: "11,4",
    height: 127,
    height_global: 127,
  },
  {
    profile_section_id: 1420,
    name: "JO127X76X16",
    type: "JO Shape",
    designation: "JO127X76",
    shape: "I",
    country_code: "British",
    ax: 21,
    ax_global: 2100,
    bf: "76,2",
    bf_global: "76,2",
    ct: "1,407",
    ct_global: "14,07",
    d: 127,
    d_global: 127,
    ix: "86,5",
    ix_global: 865000,
    iy: 60,
    iy_global: 600000,
    iz: 569,
    iz_global: 5690000,
    tf: "9,6",
    tf_global: "9,6",
    tw: "5,6",
    tw_global: "5,6",
    zx: 104,
    zx_global: 104000,
    zy: 26,
    zy_global: 26000,
    width: "76,2",
    width_global: "76,2",
    thickness: "9,6",
    thickness_global: "9,6",
    height: 127,
    height_global: 127,
  },
  {
    profile_section_id: 1421,
    name: "JO127X76A",
    type: "JO Shape",
    designation: "JO127X76A",
    shape: "I",
    country_code: "British",
    ax: 17,
    ax_global: 1700,
    bf: "76,2",
    bf_global: "76,2",
    ct: "1,341",
    ct_global: "13,41",
    d: 127,
    d_global: 127,
    ix: "94,2",
    ix_global: 942000,
    iy: 50,
    iy_global: 500000,
    iz: 476,
    iz_global: 4760000,
    tf: "7,6",
    tf_global: "7,6",
    tw: "4,5",
    tw_global: "4,5",
    zx: 85,
    zx_global: 85000,
    zy: 21,
    zy_global: 21000,
    width: "76,2",
    width_global: "76,2",
    thickness: "7,6",
    thickness_global: "7,6",
    height: 127,
    height_global: 127,
  },
  {
    profile_section_id: 1422,
    name: "JO114X114X27",
    type: "JO Shape",
    designation: "JO114X114",
    shape: "I",
    country_code: "British",
    ax: "34,4",
    ax_global: 3440,
    bf: "114,3",
    bf_global: "114,3",
    ct: "1,291",
    ct_global: "12,91",
    d: "114,3",
    d_global: "114,3",
    ix: "60,8",
    ix_global: 608000,
    iy: 223,
    iy_global: 2230000,
    iz: 735,
    iz_global: 7350000,
    tf: "10,7",
    tf_global: "10,7",
    tw: "9,5",
    tw_global: "9,5",
    zx: 151,
    zx_global: 151000,
    zy: 66,
    zy_global: 66000,
    width: "114,3",
    width_global: "114,3",
    thickness: "10,7",
    thickness_global: "10,7",
    height: "114,3",
    height_global: "114,3",
  },
  {
    profile_section_id: 1423,
    name: "JO102X102X23",
    type: "JO Shape",
    designation: "JO102X102",
    shape: "I",
    country_code: "British",
    ax: "29,3",
    ax_global: 2930,
    bf: "101,6",
    bf_global: "101,6",
    ct: "1,198",
    ct_global: "11,98",
    d: "101,6",
    d_global: "101,6",
    ix: "55,2",
    ix_global: 552000,
    iy: 154,
    iy_global: 1540000,
    iz: 486,
    iz_global: 4860000,
    tf: "10,3",
    tf_global: "10,3",
    tw: "9,5",
    tw_global: "9,5",
    zx: 113,
    zx_global: 113000,
    zy: 51,
    zy_global: 51000,
    width: "101,6",
    width_global: "101,6",
    thickness: "10,3",
    thickness_global: "10,3",
    height: "101,6",
    height_global: "101,6",
  },
  {
    profile_section_id: 1424,
    name: "JO102X64",
    type: "JO Shape",
    designation: "JO102X64",
    shape: "I",
    country_code: "British",
    ax: "12,3",
    ax_global: 1230,
    bf: "63,5",
    bf_global: "63,5",
    ct: "1,097",
    ct_global: "10,97",
    d: "101,6",
    d_global: "101,6",
    ix: "73,2",
    ix_global: 732000,
    iy: 25,
    iy_global: 250000,
    iz: 218,
    iz_global: 2180000,
    tf: "6,6",
    tf_global: "6,6",
    tw: "4,1",
    tw_global: "4,1",
    zx: 49,
    zx_global: 49000,
    zy: 13,
    zy_global: 13000,
    width: "63,5",
    width_global: "63,5",
    thickness: "6,6",
    thickness_global: "6,6",
    height: "101,6",
    height_global: "101,6",
  },
  {
    profile_section_id: 1425,
    name: "JO102X44X7",
    type: "JO Shape",
    designation: "JO102X44",
    shape: "I",
    country_code: "British",
    ax: "9,5",
    ax_global: 950,
    bf: "44,4",
    bf_global: "44,4",
    ct: "1,359",
    ct_global: "13,59",
    d: "101,6",
    d_global: "101,6",
    ix: "74,7",
    ix_global: 747000,
    iy: 8,
    iy_global: 80000,
    iz: 152,
    iz_global: 1520000,
    tf: "6,1",
    tf_global: "6,1",
    tw: "4,3",
    tw_global: "4,3",
    zx: 35,
    zx_global: 35000,
    zy: 6,
    zy_global: 6000,
    width: "44,4",
    width_global: "44,4",
    thickness: "6,1",
    thickness_global: "6,1",
    height: "101,6",
    height_global: "101,6",
  },
  {
    profile_section_id: 1426,
    name: "JO89X89X19",
    type: "JO Shape",
    designation: "JO89X89",
    shape: "I",
    country_code: "British",
    ax: "24,9",
    ax_global: 2490,
    bf: "88,9",
    bf_global: "88,9",
    ct: "1,1",
    ct_global: 11,
    d: "88,9",
    d_global: "88,9",
    ix: "44,2",
    ix_global: 442000,
    iy: 101,
    iy_global: 1010000,
    iz: 307,
    iz_global: 3070000,
    tf: "9,9",
    tf_global: "9,9",
    tw: "9,5",
    tw_global: "9,5",
    zx: 83,
    zx_global: 83000,
    zy: 38,
    zy_global: 38000,
    width: "88,9",
    width_global: "88,9",
    thickness: "9,9",
    thickness_global: "9,9",
    height: "88,9",
    height_global: "88,9",
  },
  {
    profile_section_id: 1427,
    name: "JO76X76X15",
    type: "JO Shape",
    designation: "JO76X76",
    shape: "I",
    country_code: "British",
    ax: "19,1",
    ax_global: 1910,
    bf: 80,
    bf_global: 80,
    ct: "0,9578",
    ct_global: "9,578",
    d: "76,2",
    d_global: "76,2",
    ix: 38,
    ix_global: 380000,
    iy: 61,
    iy_global: 610000,
    iz: 172,
    iz_global: 1720000,
    tf: "8,4",
    tf_global: "8,4",
    tw: "8,9",
    tw_global: "8,9",
    zx: 54,
    zx_global: 54000,
    zy: 26,
    zy_global: 26000,
    width: 80,
    width_global: 80,
    thickness: "8,4",
    thickness_global: "8,4",
    height: "76,2",
    height_global: "76,2",
  },
  {
    profile_section_id: 1428,
    name: "JO76X76AX13",
    type: "JO Shape",
    designation: "JO76X76A",
    shape: "I",
    country_code: "British",
    ax: "16,2",
    ax_global: 1620,
    bf: "76,2",
    bf_global: "76,2",
    ct: "0,7845",
    ct_global: "7,845",
    d: "76,2",
    d_global: "76,2",
    ix: "38,1",
    ix_global: 381000,
    iy: 52,
    iy_global: 520000,
    iz: 158,
    iz_global: 1580000,
    tf: "8,4",
    tf_global: "8,4",
    tw: "5,1",
    tw_global: "5,1",
    zx: 49,
    zx_global: 49000,
    zy: 22,
    zy_global: 22000,
    width: "76,2",
    width_global: "76,2",
    thickness: "8,4",
    thickness_global: "8,4",
    height: "76,2",
    height_global: "76,2",
  },
  {
    profile_section_id: 1508,
    name: "CH76X38",
    type: "Channel Section",
    designation: "CH76X38",
    shape: "C",
    country_code: "British",
    ax: "8,53",
    ax_global: 853,
    bf: "38,1",
    bf_global: "38,1",
    ct: "1,2",
    ct_global: 12,
    d: "76,2",
    d_global: "76,2",
    de: "45,8",
    de_global: "45,8",
    iy: "10,7",
    iy_global: 107000,
    iz: "74,1",
    iz_global: 741000,
    tf: "6,8",
    tf_global: "6,8",
    tw: "5,1",
    tw_global: "5,1",
    zx: "23,4",
    zx_global: 23400,
    zy: "7,8",
    zy_global: 7800,
    width: "38,1",
    width_global: "38,1",
    thickness: "6,8",
    thickness_global: "6,8",
    height: "76,2",
    height_global: "76,2",
  },
  {
    profile_section_id: 1509,
    name: "CH100X50X10",
    type: "Channel Section",
    designation: "CH100X50X10",
    shape: "C",
    country_code: "British",
    ax: 13,
    ax_global: 1300,
    bf: 50,
    bf_global: 50,
    ct: "1,7",
    ct_global: 17,
    d: 100,
    d_global: 100,
    de: 65,
    de_global: 65,
    iy: "32,3",
    iy_global: 323000,
    iz: 208,
    iz_global: 2080000,
    tf: "8,5",
    tf_global: "8,5",
    tw: 5,
    tw_global: 5,
    zx: "48,9",
    zx_global: 48900,
    zy: "17,5",
    zy_global: 17500,
    width: 50,
    width_global: 50,
    thickness: "8,5",
    thickness_global: "8,5",
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1510,
    name: "CH102X51",
    type: "Channel Section",
    designation: "CH102X51",
    shape: "C",
    country_code: "British",
    ax: "13,28",
    ax_global: 1328,
    bf: "50,8",
    bf_global: "50,8",
    ct: "1,5",
    ct_global: 15,
    d: "101,6",
    d_global: "101,6",
    de: "65,7",
    de_global: "65,7",
    iy: "29,1",
    iy_global: 291000,
    iz: "207,7",
    iz_global: 2077000,
    tf: "7,6",
    tf_global: "7,6",
    tw: "6,1",
    tw_global: "6,1",
    zx: "48,8",
    zx_global: 48800,
    zy: "15,7",
    zy_global: 15700,
    width: "50,8",
    width_global: "50,8",
    thickness: "7,6",
    thickness_global: "7,6",
    height: "101,6",
    height_global: "101,6",
  },
  {
    profile_section_id: 1511,
    name: "CH125X65X15",
    type: "Channel Section",
    designation: "CH125X65X15",
    shape: "C",
    country_code: "British",
    ax: "18,8",
    ax_global: 1880,
    bf: 65,
    bf_global: 65,
    ct: "2,3",
    ct_global: 23,
    d: 125,
    d_global: 125,
    de: 82,
    de_global: 82,
    iy: 80,
    iy_global: 800000,
    iz: 483,
    iz_global: 4830000,
    tf: "9,5",
    tf_global: "9,5",
    tw: "5,5",
    tw_global: "5,5",
    zx: "89,9",
    zx_global: 89900,
    zy: "33,2",
    zy_global: 33200,
    width: 65,
    width_global: 65,
    thickness: "9,5",
    thickness_global: "9,5",
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 1512,
    name: "CH127X64",
    type: "Channel Section",
    designation: "CH127X64",
    shape: "C",
    country_code: "British",
    ax: 19,
    ax_global: 1900,
    bf: "63,5",
    bf_global: "63,5",
    ct: "1,9",
    ct_global: 19,
    d: 127,
    d_global: 127,
    de: 84,
    de_global: 84,
    iy: "67,2",
    iy_global: 672000,
    iz: 482,
    iz_global: 4820000,
    tf: "9,2",
    tf_global: "9,2",
    tw: "6,4",
    tw_global: "6,4",
    zx: "89,4",
    zx_global: 89400,
    zy: "29,3",
    zy_global: 29300,
    width: "63,5",
    width_global: "63,5",
    thickness: "9,2",
    thickness_global: "9,2",
    height: 127,
    height_global: 127,
  },
  {
    profile_section_id: 1513,
    name: "CH152X76",
    type: "Channel Section",
    designation: "CH152X76",
    shape: "C",
    country_code: "British",
    ax: "22,8",
    ax_global: 2280,
    bf: "76,2",
    bf_global: "76,2",
    ct: "2,2",
    ct_global: 22,
    d: "152,4",
    d_global: "152,4",
    de: "105,9",
    de_global: "105,9",
    iy: 114,
    iy_global: 1140000,
    iz: 852,
    iz_global: 8520000,
    tf: 9,
    tf_global: 9,
    tw: "6,4",
    tw_global: "6,4",
    zx: 130,
    zx_global: 130000,
    zy: "41,2",
    zy_global: 41200,
    width: "76,2",
    width_global: "76,2",
    thickness: 9,
    thickness_global: 9,
    height: "152,4",
    height_global: "152,4",
  },
  {
    profile_section_id: 1514,
    name: "CH150X75X18",
    type: "Channel Section",
    designation: "CH150X75X18",
    shape: "C",
    country_code: "British",
    ax: "22,8",
    ax_global: 2280,
    bf: 75,
    bf_global: 75,
    ct: "2,6",
    ct_global: 26,
    d: 150,
    d_global: 150,
    de: 106,
    de_global: 106,
    iy: 131,
    iy_global: 1310000,
    iz: 861,
    iz_global: 8610000,
    tf: 10,
    tf_global: 10,
    tw: "5,5",
    tw_global: "5,5",
    zx: 132,
    zx_global: 132000,
    zy: "47,2",
    zy_global: 47200,
    width: 75,
    width_global: 75,
    thickness: 10,
    thickness_global: 10,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1515,
    name: "CH180X75X20",
    type: "Channel Section",
    designation: "CH180X75X20",
    shape: "C",
    country_code: "British",
    ax: "25,9",
    ax_global: 2590,
    bf: 75,
    bf_global: 75,
    ct: "2,4",
    ct_global: 24,
    d: 180,
    d_global: 180,
    de: 135,
    de_global: 135,
    iy: 146,
    iy_global: 1460000,
    iz: 1370,
    iz_global: 13700000,
    tf: "10,5",
    tf_global: "10,5",
    tw: 6,
    tw_global: 6,
    zx: 176,
    zx_global: 176000,
    zy: "51,8",
    zy_global: 51800,
    width: 75,
    width_global: 75,
    thickness: "10,5",
    thickness_global: "10,5",
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 1516,
    name: "CH178X76",
    type: "Channel Section",
    designation: "CH178X76",
    shape: "C",
    country_code: "British",
    ax: "26,6",
    ax_global: 2660,
    bf: "76,2",
    bf_global: "76,2",
    ct: "2,2",
    ct_global: 22,
    d: "177,8",
    d_global: "177,8",
    de: "128,8",
    de_global: "128,8",
    iy: 134,
    iy_global: 1340000,
    iz: 1338,
    iz_global: 13380000,
    tf: "10,3",
    tf_global: "10,3",
    tw: "6,6",
    tw_global: "6,6",
    zx: 176,
    zx_global: 176000,
    zy: "48,1",
    zy_global: 48100,
    width: "76,2",
    width_global: "76,2",
    thickness: "10,3",
    thickness_global: "10,3",
    height: "177,8",
    height_global: "177,8",
  },
  {
    profile_section_id: 1517,
    name: "CH200X75X23",
    type: "Channel Section",
    designation: "CH200X75X23",
    shape: "C",
    country_code: "British",
    ax: "29,9",
    ax_global: 2990,
    bf: 75,
    bf_global: 75,
    ct: "2,5",
    ct_global: 25,
    d: 200,
    d_global: 200,
    de: 151,
    de_global: 151,
    iy: 170,
    iy_global: 1700000,
    iz: 1963,
    iz_global: 19630000,
    tf: "12,5",
    tf_global: "12,5",
    tw: 6,
    tw_global: 6,
    zx: 227,
    zx_global: 227000,
    zy: "60,6",
    zy_global: 60600,
    width: 75,
    width_global: 75,
    thickness: "12,5",
    thickness_global: "12,5",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1518,
    name: "CH203X76",
    type: "Channel Section",
    designation: "CH203X76",
    shape: "C",
    country_code: "British",
    ax: "30,4",
    ax_global: 3040,
    bf: "76,2",
    bf_global: "76,2",
    ct: "2,1",
    ct_global: 21,
    d: "203,2",
    d_global: "203,2",
    de: "152,5",
    de_global: "152,5",
    iy: 152,
    iy_global: 1520000,
    iz: 1955,
    iz_global: 19550000,
    tf: "11,2",
    tf_global: "11,2",
    tw: "7,1",
    tw_global: "7,1",
    zx: 226,
    zx_global: 226000,
    zy: "53,5",
    zy_global: 53500,
    width: "76,2",
    width_global: "76,2",
    thickness: "11,2",
    thickness_global: "11,2",
    height: "203,2",
    height_global: "203,2",
  },
  {
    profile_section_id: 1519,
    name: "CH152X89",
    type: "Channel Section",
    designation: "CH152X89",
    shape: "C",
    country_code: "British",
    ax: "30,4",
    ax_global: 3040,
    bf: "88,9",
    bf_global: "88,9",
    ct: "2,9",
    ct_global: 29,
    d: "152,4",
    d_global: "152,4",
    de: 97,
    de_global: 97,
    iy: 216,
    iy_global: 2160000,
    iz: 1168,
    iz_global: 11680000,
    tf: "11,6",
    tf_global: "11,6",
    tw: "7,1",
    tw_global: "7,1",
    zx: 178,
    zx_global: 178000,
    zy: "68,3",
    zy_global: 68300,
    width: "88,9",
    width_global: "88,9",
    thickness: "11,6",
    thickness_global: "11,6",
    height: "152,4",
    height_global: "152,4",
  },
  {
    profile_section_id: 1520,
    name: "CH150X90X24",
    type: "Channel Section",
    designation: "CH150X90X24",
    shape: "C",
    country_code: "British",
    ax: "30,4",
    ax_global: 3040,
    bf: 90,
    bf_global: 90,
    ct: "3,3",
    ct_global: 33,
    d: 150,
    d_global: 150,
    de: 102,
    de_global: 102,
    iy: 253,
    iy_global: 2530000,
    iz: 1162,
    iz_global: 11620000,
    tf: 12,
    tf_global: 12,
    tw: "6,5",
    tw_global: "6,5",
    zx: 179,
    zx_global: 179000,
    zy: "76,9",
    zy_global: 76900,
    width: 90,
    width_global: 90,
    thickness: 12,
    thickness_global: 12,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1521,
    name: "CH230X75X26",
    type: "Channel Section",
    designation: "CH230X75X26",
    shape: "C",
    country_code: "British",
    ax: "32,7",
    ax_global: 3270,
    bf: 75,
    bf_global: 75,
    ct: "2,3",
    ct_global: 23,
    d: 230,
    d_global: 230,
    de: 181,
    de_global: 181,
    iy: 181,
    iy_global: 1810000,
    iz: 2748,
    iz_global: 27480000,
    tf: "12,5",
    tf_global: "12,5",
    tw: "6,5",
    tw_global: "6,5",
    zx: 278,
    zx_global: 278000,
    zy: "63,2",
    zy_global: 63200,
    width: 75,
    width_global: 75,
    thickness: "12,5",
    thickness_global: "12,5",
    height: 230,
    height_global: 230,
  },
  {
    profile_section_id: 1522,
    name: "CH180X90X26",
    type: "Channel Section",
    designation: "CH180X90X26",
    shape: "C",
    country_code: "British",
    ax: "33,2",
    ax_global: 3320,
    bf: 90,
    bf_global: 90,
    ct: "3,2",
    ct_global: 32,
    d: 180,
    d_global: 180,
    de: 131,
    de_global: 131,
    iy: 277,
    iy_global: 2770000,
    iz: 1817,
    iz_global: 18170000,
    tf: "12,5",
    tf_global: "12,5",
    tw: "6,5",
    tw_global: "6,5",
    zx: 232,
    zx_global: 232000,
    zy: "83,5",
    zy_global: 83500,
    width: 90,
    width_global: 90,
    thickness: "12,5",
    thickness_global: "12,5",
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 1523,
    name: "CH229X76",
    type: "Channel Section",
    designation: "CH229X76",
    shape: "C",
    country_code: "British",
    ax: "33,2",
    ax_global: 3320,
    bf: "76,2",
    bf_global: "76,2",
    ct: 2,
    ct_global: 20,
    d: "228,6",
    d_global: "228,6",
    de: 178,
    de_global: 178,
    iy: 159,
    iy_global: 1590000,
    iz: 2615,
    iz_global: 26150000,
    tf: "11,2",
    tf_global: "11,2",
    tw: "7,6",
    tw_global: "7,6",
    zx: 271,
    zx_global: 271000,
    zy: "54,5",
    zy_global: 54500,
    width: "76,2",
    width_global: "76,2",
    thickness: "11,2",
    thickness_global: "11,2",
    height: "228,6",
    height_global: "228,6",
  },
  {
    profile_section_id: 1524,
    name: "CH178X89",
    type: "Channel Section",
    designation: "CH178X89",
    shape: "C",
    country_code: "British",
    ax: "34,1",
    ax_global: 3410,
    bf: "88,9",
    bf_global: "88,9",
    ct: "2,8",
    ct_global: 28,
    d: "177,8",
    d_global: "177,8",
    de: 121,
    de_global: 121,
    iy: 241,
    iy_global: 2410000,
    iz: 1753,
    iz_global: 17530000,
    tf: "12,3",
    tf_global: "12,3",
    tw: "7,6",
    tw_global: "7,6",
    zx: 230,
    zx_global: 230000,
    zy: "75,4",
    zy_global: 75400,
    width: "88,9",
    width_global: "88,9",
    thickness: "12,3",
    thickness_global: "12,3",
    height: "177,8",
    height_global: "177,8",
  },
  {
    profile_section_id: 1525,
    name: "CH260X75X28",
    type: "Channel Section",
    designation: "CH260X75X28",
    shape: "C",
    country_code: "British",
    ax: "35,1",
    ax_global: 3510,
    bf: 75,
    bf_global: 75,
    ct: "2,1",
    ct_global: 21,
    d: 260,
    d_global: 260,
    de: 212,
    de_global: 212,
    iy: 185,
    iy_global: 1850000,
    iz: 3619,
    iz_global: 36190000,
    tf: 12,
    tf_global: 12,
    tw: 7,
    tw_global: 7,
    zx: 328,
    zx_global: 328000,
    zy: 62,
    zy_global: 62000,
    width: 75,
    width_global: 75,
    thickness: 12,
    thickness_global: 12,
    height: 260,
    height_global: 260,
  },
  {
    profile_section_id: 1526,
    name: "CH254X76",
    type: "Channel Section",
    designation: "CH254X76",
    shape: "C",
    country_code: "British",
    ax: "35,9",
    ax_global: 3590,
    bf: "76,2",
    bf_global: "76,2",
    ct: "1,9",
    ct_global: 19,
    d: 254,
    d_global: 254,
    de: "203,8",
    de_global: "203,8",
    iy: 162,
    iy_global: 1620000,
    iz: 3355,
    iz_global: 33550000,
    tf: "10,9",
    tf_global: "10,9",
    tw: "8,1",
    tw_global: "8,1",
    zx: 316,
    zx_global: 316000,
    zy: "53,9",
    zy_global: 53900,
    width: "76,2",
    width_global: "76,2",
    thickness: "10,9",
    thickness_global: "10,9",
    height: 254,
    height_global: 254,
  },
  {
    profile_section_id: 1527,
    name: "CH200X90X30",
    type: "Channel Section",
    designation: "CH200X90X30",
    shape: "C",
    country_code: "British",
    ax: "37,9",
    ax_global: 3790,
    bf: 90,
    bf_global: 90,
    ct: "3,1",
    ct_global: 31,
    d: 200,
    d_global: 200,
    de: 148,
    de_global: 148,
    iy: 314,
    iy_global: 3140000,
    iz: 2523,
    iz_global: 25230000,
    tf: 14,
    tf_global: 14,
    tw: 7,
    tw_global: 7,
    zx: 291,
    zx_global: 291000,
    zy: "94,5",
    zy_global: 94500,
    width: 90,
    width_global: 90,
    thickness: 14,
    thickness_global: 14,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1528,
    name: "CH203X89",
    type: "Channel Section",
    designation: "CH203X89",
    shape: "C",
    country_code: "British",
    ax: "37,9",
    ax_global: 3790,
    bf: "88,9",
    bf_global: "88,9",
    ct: "2,7",
    ct_global: 27,
    d: "203,2",
    d_global: "203,2",
    de: "145,2",
    de_global: "145,2",
    iy: 265,
    iy_global: 2650000,
    iz: 2492,
    iz_global: 24920000,
    tf: "12,9",
    tf_global: "12,9",
    tw: "8,1",
    tw_global: "8,1",
    zx: 287,
    zx_global: 287000,
    zy: "81,7",
    zy_global: 81700,
    width: "88,9",
    width_global: "88,9",
    thickness: "12,9",
    thickness_global: "12,9",
    height: "203,2",
    height_global: "203,2",
  },
  {
    profile_section_id: 1529,
    name: "CH230X90X32",
    type: "Channel Section",
    designation: "CH230X90X32",
    shape: "C",
    country_code: "British",
    ax: 41,
    ax_global: 4100,
    bf: 90,
    bf_global: 90,
    ct: "2,9",
    ct_global: 29,
    d: 230,
    d_global: 230,
    de: 178,
    de_global: 178,
    iy: 334,
    iy_global: 3340000,
    iz: 3518,
    iz_global: 35180000,
    tf: 14,
    tf_global: 14,
    tw: "7,5",
    tw_global: "7,5",
    zx: 355,
    zx_global: 355000,
    zy: "98,9",
    zy_global: 98900,
    width: 90,
    width_global: 90,
    thickness: 14,
    thickness_global: 14,
    height: 230,
    height_global: 230,
  },
  {
    profile_section_id: 1530,
    name: "CH229X89",
    type: "Channel Section",
    designation: "CH229X89",
    shape: "C",
    country_code: "British",
    ax: "41,6",
    ax_global: 4160,
    bf: "88,9",
    bf_global: "88,9",
    ct: "2,5",
    ct_global: 25,
    d: "228,6",
    d_global: "228,6",
    de: "169,8",
    de_global: "169,8",
    iy: 285,
    iy_global: 2850000,
    iz: 3383,
    iz_global: 33830000,
    tf: "13,3",
    tf_global: "13,3",
    tw: "8,6",
    tw_global: "8,6",
    zx: 348,
    zx_global: 348000,
    zy: "86,3",
    zy_global: 86300,
    width: "88,9",
    width_global: "88,9",
    thickness: "13,3",
    thickness_global: "13,3",
    height: "228,6",
    height_global: "228,6",
  },
  {
    profile_section_id: 1531,
    name: "CH260X90X35",
    type: "Channel Section",
    designation: "CH260X90X35",
    shape: "C",
    country_code: "British",
    ax: "44,4",
    ax_global: 4440,
    bf: 90,
    bf_global: 90,
    ct: "2,7",
    ct_global: 27,
    d: 260,
    d_global: 260,
    de: 208,
    de_global: 208,
    iy: 353,
    iy_global: 3530000,
    iz: 4728,
    iz_global: 47280000,
    tf: 14,
    tf_global: 14,
    tw: 8,
    tw_global: 8,
    zx: 425,
    zx_global: 425000,
    zy: 102,
    zy_global: 102000,
    width: 90,
    width_global: 90,
    thickness: 14,
    thickness_global: 14,
    height: 260,
    height_global: 260,
  },
  {
    profile_section_id: 1532,
    name: "CH254X89",
    type: "Channel Section",
    designation: "CH254X89",
    shape: "C",
    country_code: "British",
    ax: "45,4",
    ax_global: 4540,
    bf: "88,9",
    bf_global: "88,9",
    ct: "2,4",
    ct_global: 24,
    d: 254,
    d_global: 254,
    de: "194,7",
    de_global: "194,7",
    iy: 302,
    iy_global: 3020000,
    iz: 4445,
    iz_global: 44450000,
    tf: "13,6",
    tf_global: "13,6",
    tw: "9,1",
    tw_global: "9,1",
    zx: 414,
    zx_global: 414000,
    zy: "89,6",
    zy_global: 89600,
    width: "88,9",
    width_global: "88,9",
    thickness: "13,6",
    thickness_global: "13,6",
    height: 254,
    height_global: 254,
  },
  {
    profile_section_id: 1533,
    name: "CH300X90X41",
    type: "Channel Section",
    designation: "CH300X90X41",
    shape: "C",
    country_code: "British",
    ax: "52,7",
    ax_global: 5270,
    bf: 90,
    bf_global: 90,
    ct: "2,6",
    ct_global: 26,
    d: 300,
    d_global: 300,
    de: 245,
    de_global: 245,
    iy: 404,
    iy_global: 4040000,
    iz: 7218,
    iz_global: 72180000,
    tf: "15,5",
    tf_global: "15,5",
    tw: 9,
    tw_global: 9,
    zx: 568,
    zx_global: 568000,
    zy: 114,
    zy_global: 114000,
    width: 90,
    width_global: 90,
    thickness: "15,5",
    thickness_global: "15,5",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 1534,
    name: "CH305X89",
    type: "Channel Section",
    designation: "CH305X89",
    shape: "C",
    country_code: "British",
    ax: "53,3",
    ax_global: 5330,
    bf: "88,9",
    bf_global: "88,9",
    ct: "2,2",
    ct_global: 22,
    d: "304,8",
    d_global: "304,8",
    de: "245,4",
    de_global: "245,4",
    iy: 326,
    iy_global: 3260000,
    iz: 7078,
    iz_global: 70780000,
    tf: "13,7",
    tf_global: "13,7",
    tw: "10,2",
    tw_global: "10,2",
    zx: 559,
    zx_global: 559000,
    zy: "92,9",
    zy_global: 92900,
    width: "88,9",
    width_global: "88,9",
    thickness: "13,7",
    thickness_global: "13,7",
    height: "304,8",
    height_global: "304,8",
  },
  {
    profile_section_id: 1535,
    name: "CH300X100X46",
    type: "Channel Section",
    designation: "CH300X100X46",
    shape: "C",
    country_code: "British",
    ax: 58,
    ax_global: 5800,
    bf: 100,
    bf_global: 100,
    ct: "3,1",
    ct_global: 31,
    d: 300,
    d_global: 300,
    de: 237,
    de_global: 237,
    iy: 568,
    iy_global: 5680000,
    iz: 8229,
    iz_global: 82290000,
    tf: "16,5",
    tf_global: "16,5",
    tw: 9,
    tw_global: 9,
    zx: 641,
    zx_global: 641000,
    zy: 148,
    zy_global: 148000,
    width: 100,
    width_global: 100,
    thickness: "16,5",
    thickness_global: "16,5",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 1536,
    name: "CH305X102",
    type: "Channel Section",
    designation: "CH305X102",
    shape: "C",
    country_code: "British",
    ax: "58,9",
    ax_global: 5890,
    bf: "101,6",
    bf_global: "101,6",
    ct: "2,7",
    ct_global: 27,
    d: "304,8",
    d_global: "304,8",
    de: "239,2",
    de_global: "239,2",
    iy: 499,
    iy_global: 4990000,
    iz: 8208,
    iz_global: 82080000,
    tf: "14,8",
    tf_global: "14,8",
    tw: "10,2",
    tw_global: "10,2",
    zx: 638,
    zx_global: 638000,
    zy: 128,
    zy_global: 128000,
    width: "101,6",
    width_global: "101,6",
    thickness: "14,8",
    thickness_global: "14,8",
    height: "304,8",
    height_global: "304,8",
  },
  {
    profile_section_id: 1537,
    name: "CH380X100X54",
    type: "Channel Section",
    designation: "CH380X100X54",
    shape: "C",
    country_code: "British",
    ax: "68,7",
    ax_global: 6870,
    bf: 100,
    bf_global: 100,
    ct: "2,8",
    ct_global: 28,
    d: 380,
    d_global: 380,
    de: 315,
    de_global: 315,
    iy: 643,
    iy_global: 6430000,
    iz: 15030,
    iz_global: 150300000,
    tf: "17,5",
    tf_global: "17,5",
    tw: "9,5",
    tw_global: "9,5",
    zx: 933,
    zx_global: 933000,
    zy: 161,
    zy_global: 161000,
    width: 100,
    width_global: 100,
    thickness: "17,5",
    thickness_global: "17,5",
    height: 380,
    height_global: 380,
  },
  {
    profile_section_id: 1538,
    name: "CH381X102",
    type: "Channel Section",
    designation: "CH381X102",
    shape: "C",
    country_code: "British",
    ax: "70,1",
    ax_global: 7010,
    bf: "101,6",
    bf_global: "101,6",
    ct: "2,5",
    ct_global: 25,
    d: 381,
    d_global: 381,
    de: "312,4",
    de_global: "312,4",
    iy: 579,
    iy_global: 5790000,
    iz: 14870,
    iz_global: 148700000,
    tf: "16,3",
    tf_global: "16,3",
    tw: "10,4",
    tw_global: "10,4",
    zx: 931,
    zx_global: 931000,
    zy: 144,
    zy_global: 144000,
    width: "101,6",
    width_global: "101,6",
    thickness: "16,3",
    thickness_global: "16,3",
    height: 381,
    height_global: 381,
  },
  {
    profile_section_id: 1539,
    name: "CH430X100X64",
    type: "Channel Section",
    designation: "CH430X100X64",
    shape: "C",
    country_code: "British",
    ax: "82,1",
    ax_global: 8210,
    bf: 100,
    bf_global: 100,
    ct: "2,6",
    ct_global: 26,
    d: 430,
    d_global: 430,
    de: 362,
    de_global: 362,
    iy: 722,
    iy_global: 7220000,
    iz: 21940,
    iz_global: 219400000,
    tf: 19,
    tf_global: 19,
    tw: 11,
    tw_global: 11,
    zx: 1222,
    zx_global: 1222000,
    zy: 176,
    zy_global: 176000,
    width: 100,
    width_global: 100,
    thickness: 19,
    thickness_global: 19,
    height: 430,
    height_global: 430,
  },
  {
    profile_section_id: 1540,
    name: "CH432X102",
    type: "Channel Section",
    designation: "CH432X102",
    shape: "C",
    country_code: "British",
    ax: "83,4",
    ax_global: 8340,
    bf: "101,6",
    bf_global: "101,6",
    ct: "2,3",
    ct_global: 23,
    d: "431,8",
    d_global: "431,8",
    de: "362,4",
    de_global: "362,4",
    iy: 627,
    iy_global: 6270000,
    iz: 21370,
    iz_global: 213700000,
    tf: "16,8",
    tf_global: "16,8",
    tw: "12,2",
    tw_global: "12,2",
    zx: 1205,
    zx_global: 1205000,
    zy: 153,
    zy_global: 153000,
    width: "101,6",
    width_global: "101,6",
    thickness: "16,8",
    thickness_global: "16,8",
    height: "431,8",
    height_global: "431,8",
  },
  {
    profile_section_id: 1541,
    name: "200x200x24 EA",
    type: "Angle",
    designation: "UA200x200x24",
    shape: "L",
    country_code: "British",
    ax: "90,6",
    ax_global: 9060,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    rz: 39,
    rz_global: 39,
    t: 24,
    t_global: 24,
    width: 200,
    width_global: 200,
    thickness: 24,
    thickness_global: 24,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1542,
    name: "200x200x20 EA",
    type: "Angle",
    designation: "UA200x200x20",
    shape: "L",
    country_code: "British",
    ax: "76,3",
    ax_global: 7630,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    rz: "39,2",
    rz_global: "39,2",
    t: 20,
    t_global: 20,
    width: 200,
    width_global: 200,
    thickness: 20,
    thickness_global: 20,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1543,
    name: "200x200x18 EA",
    type: "Angle",
    designation: "UA200x200x18",
    shape: "L",
    country_code: "British",
    ax: "69,1",
    ax_global: 6910,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    rz: 39,
    rz_global: 39,
    t: 18,
    t_global: 18,
    width: 200,
    width_global: 200,
    thickness: 18,
    thickness_global: 18,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1544,
    name: "200x200x16 EA",
    type: "Angle",
    designation: "UA200x200x16",
    shape: "L",
    country_code: "British",
    ax: "61,8",
    ax_global: 6180,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    rz: "39,4",
    rz_global: "39,4",
    t: 16,
    t_global: 16,
    width: 200,
    width_global: 200,
    thickness: 16,
    thickness_global: 16,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1545,
    name: "150x150x18 EA",
    type: "Angle",
    designation: "UA150x150x18",
    shape: "L",
    country_code: "British",
    ax: 51,
    ax_global: 5100,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    rz: "29,2",
    rz_global: "29,2",
    t: 18,
    t_global: 18,
    width: 150,
    width_global: 150,
    thickness: 18,
    thickness_global: 18,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1546,
    name: "150x150x15 EA",
    type: "Angle",
    designation: "UA150x150x15",
    shape: "L",
    country_code: "British",
    ax: 43,
    ax_global: 4300,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    rz: "29,3",
    rz_global: "29,3",
    t: 15,
    t_global: 15,
    width: 150,
    width_global: 150,
    thickness: 15,
    thickness_global: 15,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1547,
    name: "150x150x12 EA",
    type: "Angle",
    designation: "UA150x150x12",
    shape: "L",
    country_code: "British",
    ax: "34,8",
    ax_global: 3480,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    rz: "29,5",
    rz_global: "29,5",
    t: 12,
    t_global: 12,
    width: 150,
    width_global: 150,
    thickness: 12,
    thickness_global: 12,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1548,
    name: "150x150x10 EA",
    type: "Angle",
    designation: "UA150x150x10",
    shape: "L",
    country_code: "British",
    ax: "29,3",
    ax_global: 2930,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    rz: "29,7",
    rz_global: "29,7",
    t: 10,
    t_global: 10,
    width: 150,
    width_global: 150,
    thickness: 10,
    thickness_global: 10,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1549,
    name: "120x120x15 EA",
    type: "Angle",
    designation: "UA120x120x15",
    shape: "L",
    country_code: "British",
    ax: "33,9",
    ax_global: 3390,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    rz: "23,4",
    rz_global: "23,4",
    t: 15,
    t_global: 15,
    width: 120,
    width_global: 120,
    thickness: 15,
    thickness_global: 15,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 1550,
    name: "120x120x12 EA",
    type: "Angle",
    designation: "UA120x120x12",
    shape: "L",
    country_code: "British",
    ax: "27,5",
    ax_global: 2750,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    rz: "23,5",
    rz_global: "23,5",
    t: 12,
    t_global: 12,
    width: 120,
    width_global: 120,
    thickness: 12,
    thickness_global: 12,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 1551,
    name: "120x120x10 EA",
    type: "Angle",
    designation: "UA120x120x10",
    shape: "L",
    country_code: "British",
    ax: "23,2",
    ax_global: 2320,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    rz: "23,6",
    rz_global: "23,6",
    t: 10,
    t_global: 10,
    width: 120,
    width_global: 120,
    thickness: 10,
    thickness_global: 10,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 1552,
    name: "120x120x8 EA",
    type: "Angle",
    designation: "UA120x120x8",
    shape: "L",
    country_code: "British",
    ax: "18,7",
    ax_global: 1870,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    rz: "23,8",
    rz_global: "23,8",
    t: 8,
    t_global: 8,
    width: 120,
    width_global: 120,
    thickness: 8,
    thickness_global: 8,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 1553,
    name: "100x100x15 EA",
    type: "Angle",
    designation: "UA100x100x15",
    shape: "L",
    country_code: "British",
    ax: "27,9",
    ax_global: 2790,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    rz: "19,4",
    rz_global: "19,4",
    t: 15,
    t_global: 15,
    width: 100,
    width_global: 100,
    thickness: 15,
    thickness_global: 15,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1554,
    name: "100x100x12 EA",
    type: "Angle",
    designation: "UA100x100x12",
    shape: "L",
    country_code: "British",
    ax: "22,7",
    ax_global: 2270,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    rz: "19,4",
    rz_global: "19,4",
    t: 12,
    t_global: 12,
    width: 100,
    width_global: 100,
    thickness: 12,
    thickness_global: 12,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1555,
    name: "100x100x10 EA",
    type: "Angle",
    designation: "UA100x100x10",
    shape: "L",
    country_code: "British",
    ax: "19,2",
    ax_global: 1920,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    rz: "19,5",
    rz_global: "19,5",
    t: 10,
    t_global: 10,
    width: 100,
    width_global: 100,
    thickness: 10,
    thickness_global: 10,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1556,
    name: "100x100x8 EA",
    type: "Angle",
    designation: "UA100x100x8",
    shape: "L",
    country_code: "British",
    ax: "15,5",
    ax_global: 1550,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    rz: "19,6",
    rz_global: "19,6",
    t: 8,
    t_global: 8,
    width: 100,
    width_global: 100,
    thickness: 8,
    thickness_global: 8,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1557,
    name: "90x90x12 EA",
    type: "Angle",
    designation: "UA90x90x12",
    shape: "L",
    country_code: "British",
    ax: "20,3",
    ax_global: 2030,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    rz: "17,5",
    rz_global: "17,5",
    t: 12,
    t_global: 12,
    width: 90,
    width_global: 90,
    thickness: 12,
    thickness_global: 12,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 1558,
    name: "90x90x10 EA",
    type: "Angle",
    designation: "UA90x90x10",
    shape: "L",
    country_code: "British",
    ax: "17,1",
    ax_global: 1710,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    rz: "17,5",
    rz_global: "17,5",
    t: 10,
    t_global: 10,
    width: 90,
    width_global: 90,
    thickness: 10,
    thickness_global: 10,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 1559,
    name: "90x90x8 EA",
    type: "Angle",
    designation: "UA90x90x8",
    shape: "L",
    country_code: "British",
    ax: "13,9",
    ax_global: 1390,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    rz: "17,6",
    rz_global: "17,6",
    t: 8,
    t_global: 8,
    width: 90,
    width_global: 90,
    thickness: 8,
    thickness_global: 8,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 1560,
    name: "90x90x7 EA",
    type: "Angle",
    designation: "UA90x90x7",
    shape: "L",
    country_code: "British",
    ax: "12,2",
    ax_global: 1220,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    rz: "17,7",
    rz_global: "17,7",
    t: 7,
    t_global: 7,
    width: 90,
    width_global: 90,
    thickness: 7,
    thickness_global: 7,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 1561,
    name: "80x80x10 EA",
    type: "Angle",
    designation: "UA80x80x10",
    shape: "L",
    country_code: "British",
    ax: "15,1",
    ax_global: 1510,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    rz: "15,5",
    rz_global: "15,5",
    t: 10,
    t_global: 10,
    width: 80,
    width_global: 80,
    thickness: 10,
    thickness_global: 10,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 1562,
    name: "80x80x8 EA",
    type: "Angle",
    designation: "UA80x80x8",
    shape: "L",
    country_code: "British",
    ax: "12,3",
    ax_global: 1230,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    rz: "15,6",
    rz_global: "15,6",
    t: 8,
    t_global: 8,
    width: 80,
    width_global: 80,
    thickness: 8,
    thickness_global: 8,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 1563,
    name: "75x75x8 EA",
    type: "Angle",
    designation: "UA75x75x8",
    shape: "L",
    country_code: "British",
    ax: "11,4",
    ax_global: 1140,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    rz: "14,6",
    rz_global: "14,6",
    t: 8,
    t_global: 8,
    width: 75,
    width_global: 75,
    thickness: 8,
    thickness_global: 8,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 1564,
    name: "75x75x6 EA",
    type: "Angle",
    designation: "UA75x75x6",
    shape: "L",
    country_code: "British",
    ax: "8,73",
    ax_global: 873,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    rz: "14,7",
    rz_global: "14,7",
    t: 6,
    t_global: 6,
    width: 75,
    width_global: 75,
    thickness: 6,
    thickness_global: 6,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 1565,
    name: "70x70x7 EA",
    type: "Angle",
    designation: "UA70x70x7",
    shape: "L",
    country_code: "British",
    ax: "9,4",
    ax_global: 940,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    rz: "13,6",
    rz_global: "13,6",
    t: 7,
    t_global: 7,
    width: 70,
    width_global: 70,
    thickness: 7,
    thickness_global: 7,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 1566,
    name: "70x70x6 EA",
    type: "Angle",
    designation: "UA70x70x6",
    shape: "L",
    country_code: "British",
    ax: "8,13",
    ax_global: 813,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    rz: "13,7",
    rz_global: "13,7",
    t: 6,
    t_global: 6,
    width: 70,
    width_global: 70,
    thickness: 6,
    thickness_global: 6,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 1567,
    name: "65x60x7 EA",
    type: "Angle",
    designation: "UA65x60x7",
    shape: "L",
    country_code: "British",
    ax: "8,73",
    ax_global: 873,
    b: 60,
    b_global: 60,
    d: 65,
    d_global: 65,
    rz: "12,6",
    rz_global: "12,6",
    t: 7,
    t_global: 7,
    width: 60,
    width_global: 60,
    thickness: 7,
    thickness_global: 7,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 1568,
    name: "60x60x8 EA",
    type: "Angle",
    designation: "UA60x60x8",
    shape: "L",
    country_code: "British",
    ax: "9,03",
    ax_global: 903,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    rz: "11,6",
    rz_global: "11,6",
    t: 8,
    t_global: 8,
    width: 60,
    width_global: 60,
    thickness: 8,
    thickness_global: 8,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 1569,
    name: "60x60x6 EA",
    type: "Angle",
    designation: "UA60x60x6",
    shape: "L",
    country_code: "British",
    ax: "6,91",
    ax_global: 691,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    rz: "11,7",
    rz_global: "11,7",
    t: 6,
    t_global: 6,
    width: 60,
    width_global: 60,
    thickness: 6,
    thickness_global: 6,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 1570,
    name: "60x60x5 EA",
    type: "Angle",
    designation: "UA60x60x5",
    shape: "L",
    country_code: "British",
    ax: "5,82",
    ax_global: 582,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    rz: "11,7",
    rz_global: "11,7",
    t: 5,
    t_global: 5,
    width: 60,
    width_global: 60,
    thickness: 5,
    thickness_global: 5,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 1571,
    name: "50x50x6 EA",
    type: "Angle",
    designation: "UA50x50x6",
    shape: "L",
    country_code: "British",
    ax: "5,69",
    ax_global: 569,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    rz: "9,68",
    rz_global: "9,68",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 1572,
    name: "50x50x5 EA",
    type: "Angle",
    designation: "UA50x50x5",
    shape: "L",
    country_code: "British",
    ax: "4,8",
    ax_global: 480,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    rz: "9,73",
    rz_global: "9,73",
    t: 5,
    t_global: 5,
    width: 50,
    width_global: 50,
    thickness: 5,
    thickness_global: 5,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 1573,
    name: "50x50x4 EA",
    type: "Angle",
    designation: "UA50x50x4",
    shape: "L",
    country_code: "British",
    ax: "3,89",
    ax_global: 389,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    rz: "9,79",
    rz_global: "9,79",
    t: 4,
    t_global: 4,
    width: 50,
    width_global: 50,
    thickness: 4,
    thickness_global: 4,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 1574,
    name: "45x45x5 EA",
    type: "Angle",
    designation: "UA45x45x5",
    shape: "L",
    country_code: "British",
    ax: "3,9",
    ax_global: 390,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    rz: "8,7",
    rz_global: "8,7",
    t: 5,
    t_global: 5,
    width: 45,
    width_global: 45,
    thickness: 5,
    thickness_global: 5,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 1575,
    name: "40x40x5 EA",
    type: "Angle",
    designation: "UA40x40x5",
    shape: "L",
    country_code: "British",
    ax: "3,79",
    ax_global: 379,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    rz: "7,73",
    rz_global: "7,73",
    t: 5,
    t_global: 5,
    width: 40,
    width_global: 40,
    thickness: 5,
    thickness_global: 5,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 1576,
    name: "40x40x4 EA",
    type: "Angle",
    designation: "UA40x40x4",
    shape: "L",
    country_code: "British",
    ax: "3,08",
    ax_global: 308,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    rz: "7,77",
    rz_global: "7,77",
    t: 4,
    t_global: 4,
    width: 40,
    width_global: 40,
    thickness: 4,
    thickness_global: 4,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 1577,
    name: "35x35x4 EA",
    type: "Angle",
    designation: "UA35x35x4",
    shape: "L",
    country_code: "British",
    ax: "2,67",
    ax_global: 267,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    rz: "6,78",
    rz_global: "6,78",
    t: 4,
    t_global: 4,
    width: 35,
    width_global: 35,
    thickness: 4,
    thickness_global: 4,
    height: 35,
    height_global: 35,
  },
  {
    profile_section_id: 1578,
    name: "30x30x4 EA",
    type: "Angle",
    designation: "UA30x30x4",
    shape: "L",
    country_code: "British",
    ax: "2,27",
    ax_global: 227,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    rz: "5,77",
    rz_global: "5,77",
    t: 4,
    t_global: 4,
    width: 30,
    width_global: 30,
    thickness: 4,
    thickness_global: 4,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 1579,
    name: "30x30x3 EA",
    type: "Angle",
    designation: "UA30x30x3",
    shape: "L",
    country_code: "British",
    ax: "1,74",
    ax_global: 174,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    rz: "5,81",
    rz_global: "5,81",
    t: 3,
    t_global: 3,
    width: 30,
    width_global: 30,
    thickness: 3,
    thickness_global: 3,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 1580,
    name: "25x25x4 EA",
    type: "Angle",
    designation: "UA25x25x4",
    shape: "L",
    country_code: "British",
    ax: "1,85",
    ax_global: 185,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    rz: "4,82",
    rz_global: "4,82",
    t: 4,
    t_global: 4,
    width: 25,
    width_global: 25,
    thickness: 4,
    thickness_global: 4,
    height: 25,
    height_global: 25,
  },
  {
    profile_section_id: 1581,
    name: "25x25x3 EA",
    type: "Angle",
    designation: "UA25x25x3",
    shape: "L",
    country_code: "British",
    ax: "1,42",
    ax_global: 142,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    rz: "4,84",
    rz_global: "4,84",
    t: 3,
    t_global: 3,
    width: 25,
    width_global: 25,
    thickness: 3,
    thickness_global: 3,
    height: 25,
    height_global: 25,
  },
  {
    profile_section_id: 1582,
    name: "20x20x3 EA",
    type: "Angle",
    designation: "UA20x20x3",
    shape: "L",
    country_code: "British",
    ax: "1,12",
    ax_global: 112,
    b: 20,
    b_global: 20,
    d: 20,
    d_global: 20,
    rz: "3,83",
    rz_global: "3,83",
    t: 3,
    t_global: 3,
    width: 20,
    width_global: 20,
    thickness: 3,
    thickness_global: 3,
    height: 20,
    height_global: 20,
  },
  {
    profile_section_id: 1583,
    name: "200x150x18 UA",
    type: "Angle",
    designation: "UA200x150x18",
    shape: "L",
    country_code: "British",
    ax: 60,
    ax_global: 6000,
    b: 150,
    b_global: 150,
    d: 200,
    d_global: 200,
    rz: "32,2",
    rz_global: "32,2",
    t: 18,
    t_global: 18,
    width: 150,
    width_global: 150,
    thickness: 18,
    thickness_global: 18,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1584,
    name: "200x150x15 UA",
    type: "Angle",
    designation: "UA200x150x15",
    shape: "L",
    country_code: "British",
    ax: "50,5",
    ax_global: 5050,
    b: 150,
    b_global: 150,
    d: 200,
    d_global: 200,
    rz: "32,3",
    rz_global: "32,3",
    t: 15,
    t_global: 15,
    width: 150,
    width_global: 150,
    thickness: 15,
    thickness_global: 15,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1585,
    name: "200x150x12 UA",
    type: "Angle",
    designation: "UA200x150x12",
    shape: "L",
    country_code: "British",
    ax: "40,8",
    ax_global: 4080,
    b: 150,
    b_global: 150,
    d: 200,
    d_global: 200,
    rz: "32,5",
    rz_global: "32,5",
    t: 12,
    t_global: 12,
    width: 150,
    width_global: 150,
    thickness: 12,
    thickness_global: 12,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1586,
    name: "200x100x15 UA",
    type: "Angle",
    designation: "UA200x100x15",
    shape: "L",
    country_code: "British",
    ax: 43,
    ax_global: 4300,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    rz: "21,2",
    rz_global: "21,2",
    t: 15,
    t_global: 15,
    width: 100,
    width_global: 100,
    thickness: 15,
    thickness_global: 15,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1587,
    name: "200x100x12 UA",
    type: "Angle",
    designation: "UA200x100x12",
    shape: "L",
    country_code: "British",
    ax: "34,8",
    ax_global: 3480,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    rz: "21,4",
    rz_global: "21,4",
    t: 12,
    t_global: 12,
    width: 100,
    width_global: 100,
    thickness: 12,
    thickness_global: 12,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1588,
    name: "200x100x10 UA",
    type: "Angle",
    designation: "UA200x100x10",
    shape: "L",
    country_code: "British",
    ax: "29,2",
    ax_global: 2920,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    rz: "21,5",
    rz_global: "21,5",
    t: 10,
    t_global: 10,
    width: 100,
    width_global: 100,
    thickness: 10,
    thickness_global: 10,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1589,
    name: "150x90x15 UA",
    type: "Angle",
    designation: "UA150x90x15",
    shape: "L",
    country_code: "British",
    ax: "33,9",
    ax_global: 3390,
    b: 90,
    b_global: 90,
    d: 150,
    d_global: 150,
    rz: "19,3",
    rz_global: "19,3",
    t: 15,
    t_global: 15,
    width: 90,
    width_global: 90,
    thickness: 15,
    thickness_global: 15,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1590,
    name: "150x90x12 UA",
    type: "Angle",
    designation: "UA150x90x12",
    shape: "L",
    country_code: "British",
    ax: "27,5",
    ax_global: 2750,
    b: 90,
    b_global: 90,
    d: 150,
    d_global: 150,
    rz: "19,4",
    rz_global: "19,4",
    t: 12,
    t_global: 12,
    width: 90,
    width_global: 90,
    thickness: 12,
    thickness_global: 12,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1591,
    name: "150x90x10 UA",
    type: "Angle",
    designation: "UA150x90x10",
    shape: "L",
    country_code: "British",
    ax: "23,2",
    ax_global: 2320,
    b: 90,
    b_global: 90,
    d: 150,
    d_global: 150,
    rz: "19,5",
    rz_global: "19,5",
    t: 10,
    t_global: 10,
    width: 90,
    width_global: 90,
    thickness: 10,
    thickness_global: 10,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1592,
    name: "150x75x15 UA",
    type: "Angle",
    designation: "UA150x75x15",
    shape: "L",
    country_code: "British",
    ax: "31,7",
    ax_global: 3170,
    b: 75,
    b_global: 75,
    d: 150,
    d_global: 150,
    rz: "15,8",
    rz_global: "15,8",
    t: 15,
    t_global: 15,
    width: 75,
    width_global: 75,
    thickness: 15,
    thickness_global: 15,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1593,
    name: "150x75x12 UA",
    type: "Angle",
    designation: "UA150x75x12",
    shape: "L",
    country_code: "British",
    ax: "25,7",
    ax_global: 2570,
    b: 75,
    b_global: 75,
    d: 150,
    d_global: 150,
    rz: "15,9",
    rz_global: "15,9",
    t: 12,
    t_global: 12,
    width: 75,
    width_global: 75,
    thickness: 12,
    thickness_global: 12,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1594,
    name: "150x75x10 UA",
    type: "Angle",
    designation: "UA150x75x10",
    shape: "L",
    country_code: "British",
    ax: "21,7",
    ax_global: 2170,
    b: 75,
    b_global: 75,
    d: 150,
    d_global: 150,
    rz: 16,
    rz_global: 16,
    t: 10,
    t_global: 10,
    width: 75,
    width_global: 75,
    thickness: 10,
    thickness_global: 10,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1595,
    name: "125x75x12 UA",
    type: "Angle",
    designation: "UA125x75x12",
    shape: "L",
    country_code: "British",
    ax: "22,7",
    ax_global: 2270,
    b: 75,
    b_global: 75,
    d: 125,
    d_global: 125,
    rz: "16,1",
    rz_global: "16,1",
    t: 12,
    t_global: 12,
    width: 75,
    width_global: 75,
    thickness: 12,
    thickness_global: 12,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 1596,
    name: "125x75x10 UA",
    type: "Angle",
    designation: "UA125x75x10",
    shape: "L",
    country_code: "British",
    ax: "19,1",
    ax_global: 1910,
    b: 75,
    b_global: 75,
    d: 125,
    d_global: 125,
    rz: "16,1",
    rz_global: "16,1",
    t: 10,
    t_global: 10,
    width: 75,
    width_global: 75,
    thickness: 10,
    thickness_global: 10,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 1597,
    name: "125x75x8 UA",
    type: "Angle",
    designation: "UA125x75x8",
    shape: "L",
    country_code: "British",
    ax: "15,5",
    ax_global: 1550,
    b: 75,
    b_global: 75,
    d: 125,
    d_global: 125,
    rz: "16,3",
    rz_global: "16,3",
    t: 8,
    t_global: 8,
    width: 75,
    width_global: 75,
    thickness: 8,
    thickness_global: 8,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 1598,
    name: "100x75x12 UA",
    type: "Angle",
    designation: "UA100x75x12",
    shape: "L",
    country_code: "British",
    ax: "19,7",
    ax_global: 1970,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    rz: "15,9",
    rz_global: "15,9",
    t: 12,
    t_global: 12,
    width: 75,
    width_global: 75,
    thickness: 12,
    thickness_global: 12,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1599,
    name: "100x75x10 UA",
    type: "Angle",
    designation: "UA100x75x10",
    shape: "L",
    country_code: "British",
    ax: "16,6",
    ax_global: 1660,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    rz: "15,9",
    rz_global: "15,9",
    t: 10,
    t_global: 10,
    width: 75,
    width_global: 75,
    thickness: 10,
    thickness_global: 10,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1600,
    name: "100x75x8 UA",
    type: "Angle",
    designation: "UA100x75x8",
    shape: "L",
    country_code: "British",
    ax: "13,5",
    ax_global: 1350,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    rz: 16,
    rz_global: 16,
    t: 8,
    t_global: 8,
    width: 75,
    width_global: 75,
    thickness: 8,
    thickness_global: 8,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1601,
    name: "100x65x10 UA",
    type: "Angle",
    designation: "UA100x65x10",
    shape: "L",
    country_code: "British",
    ax: "15,6",
    ax_global: 1560,
    b: 65,
    b_global: 65,
    d: 100,
    d_global: 100,
    rz: "13,9",
    rz_global: "13,9",
    t: 10,
    t_global: 10,
    width: 65,
    width_global: 65,
    thickness: 10,
    thickness_global: 10,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1602,
    name: "100x65x8 UA",
    type: "Angle",
    designation: "UA100x65x8",
    shape: "L",
    country_code: "British",
    ax: "12,7",
    ax_global: 1270,
    b: 65,
    b_global: 65,
    d: 100,
    d_global: 100,
    rz: 14,
    rz_global: 14,
    t: 8,
    t_global: 8,
    width: 65,
    width_global: 65,
    thickness: 8,
    thickness_global: 8,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1603,
    name: "100x65x7 UA",
    type: "Angle",
    designation: "UA100x65x7",
    shape: "L",
    country_code: "British",
    ax: "11,2",
    ax_global: 1120,
    b: 65,
    b_global: 65,
    d: 100,
    d_global: 100,
    rz: 14,
    rz_global: 14,
    t: 7,
    t_global: 7,
    width: 65,
    width_global: 65,
    thickness: 7,
    thickness_global: 7,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1604,
    name: "100x50x8 UA",
    type: "Angle",
    designation: "UA100x50x8",
    shape: "L",
    country_code: "British",
    ax: "11,4",
    ax_global: 1140,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    rz: "10,6",
    rz_global: "10,6",
    t: 8,
    t_global: 8,
    width: 50,
    width_global: 50,
    thickness: 8,
    thickness_global: 8,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1605,
    name: "100x50x6 UA",
    type: "Angle",
    designation: "UA100x50x6",
    shape: "L",
    country_code: "British",
    ax: "8,71",
    ax_global: 871,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    rz: "10,7",
    rz_global: "10,7",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1606,
    name: "80x60x7 UA",
    type: "Angle",
    designation: "UA80x60x7",
    shape: "L",
    country_code: "British",
    ax: "9,38",
    ax_global: 938,
    b: 60,
    b_global: 60,
    d: 80,
    d_global: 80,
    rz: "12,8",
    rz_global: "12,8",
    t: 7,
    t_global: 7,
    width: 60,
    width_global: 60,
    thickness: 7,
    thickness_global: 7,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 1607,
    name: "80x40x8 UA",
    type: "Angle",
    designation: "UA80x40x8",
    shape: "L",
    country_code: "British",
    ax: "9,01",
    ax_global: 901,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    rz: "8,38",
    rz_global: "8,38",
    t: 8,
    t_global: 8,
    width: 40,
    width_global: 40,
    thickness: 8,
    thickness_global: 8,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 1608,
    name: "80x40x6 UA",
    type: "Angle",
    designation: "UA80x40x6",
    shape: "L",
    country_code: "British",
    ax: "6,89",
    ax_global: 689,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    rz: "8,45",
    rz_global: "8,45",
    t: 6,
    t_global: 6,
    width: 40,
    width_global: 40,
    thickness: 6,
    thickness_global: 6,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 1609,
    name: "75x50x8 UA",
    type: "Angle",
    designation: "UA75x50x8",
    shape: "L",
    country_code: "British",
    ax: "9,41",
    ax_global: 941,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    rz: "10,7",
    rz_global: "10,7",
    t: 8,
    t_global: 8,
    width: 50,
    width_global: 50,
    thickness: 8,
    thickness_global: 8,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 1610,
    name: "75x50x6 UA",
    type: "Angle",
    designation: "UA75x50x6",
    shape: "L",
    country_code: "British",
    ax: "7,19",
    ax_global: 719,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    rz: "10,8",
    rz_global: "10,8",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 1611,
    name: "70x50x6 UA",
    type: "Angle",
    designation: "UA70x50x6",
    shape: "L",
    country_code: "British",
    ax: "6,89",
    ax_global: 689,
    b: 50,
    b_global: 50,
    d: 70,
    d_global: 70,
    rz: "10,7",
    rz_global: "10,7",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 1612,
    name: "65x50x5 UA",
    type: "Angle",
    designation: "UA65x50x5",
    shape: "L",
    country_code: "British",
    ax: "5,54",
    ax_global: 554,
    b: 50,
    b_global: 50,
    d: 65,
    d_global: 65,
    rz: "10,7",
    rz_global: "10,7",
    t: 5,
    t_global: 5,
    width: 50,
    width_global: 50,
    thickness: 5,
    thickness_global: 5,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 1613,
    name: "60x40x6 UA",
    type: "Angle",
    designation: "UA60x40x6",
    shape: "L",
    country_code: "British",
    ax: "5,68",
    ax_global: 568,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    rz: "8,55",
    rz_global: "8,55",
    t: 6,
    t_global: 6,
    width: 40,
    width_global: 40,
    thickness: 6,
    thickness_global: 6,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 1614,
    name: "60x40x5 UA",
    type: "Angle",
    designation: "UA60x40x5",
    shape: "L",
    country_code: "British",
    ax: "4,79",
    ax_global: 479,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    rz: "8,6",
    rz_global: "8,6",
    t: 5,
    t_global: 5,
    width: 40,
    width_global: 40,
    thickness: 5,
    thickness_global: 5,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 1615,
    name: "60x30x5 UA",
    type: "Angle",
    designation: "UA60x30x5",
    shape: "L",
    country_code: "British",
    ax: "4,28",
    ax_global: 428,
    b: 30,
    b_global: 30,
    d: 60,
    d_global: 60,
    rz: "6,33",
    rz_global: "6,33",
    t: 5,
    t_global: 5,
    width: 30,
    width_global: 30,
    thickness: 5,
    thickness_global: 5,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 1616,
    name: "50x30x5 UA",
    type: "Angle",
    designation: "UA50x30x5",
    shape: "L",
    country_code: "British",
    ax: "3,78",
    ax_global: 378,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    rz: "6,39",
    rz_global: "6,39",
    t: 5,
    t_global: 5,
    width: 30,
    width_global: 30,
    thickness: 5,
    thickness_global: 5,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 1617,
    name: "45x30x4 UA",
    type: "Angle",
    designation: "UA45x30x4",
    shape: "L",
    country_code: "British",
    ax: "2,87",
    ax_global: 287,
    b: 30,
    b_global: 30,
    d: 45,
    d_global: 45,
    rz: "6,4",
    rz_global: "6,4",
    t: 4,
    t_global: 4,
    width: 30,
    width_global: 30,
    thickness: 4,
    thickness_global: 4,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 1618,
    name: "40x25x4 UA",
    type: "Angle",
    designation: "UA40x25x4",
    shape: "L",
    country_code: "British",
    ax: "2,46",
    ax_global: 246,
    b: 25,
    b_global: 25,
    d: 40,
    d_global: 40,
    rz: "5,34",
    rz_global: "5,34",
    t: 4,
    t_global: 4,
    width: 25,
    width_global: 25,
    thickness: 4,
    thickness_global: 4,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 1619,
    name: "40x20x4 UA",
    type: "Angle",
    designation: "UA40x20x4",
    shape: "L",
    country_code: "British",
    ax: "2,26",
    ax_global: 226,
    b: 20,
    b_global: 20,
    d: 40,
    d_global: 40,
    rz: "4,17",
    rz_global: "4,17",
    t: 4,
    t_global: 4,
    width: 20,
    width_global: 20,
    thickness: 4,
    thickness_global: 4,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 1620,
    name: "30x20x4 UA",
    type: "Angle",
    designation: "UA30x20x4",
    shape: "L",
    country_code: "British",
    ax: "1,86",
    ax_global: 186,
    b: 20,
    b_global: 20,
    d: 30,
    d_global: 30,
    rz: "4,21",
    rz_global: "4,21",
    t: 4,
    t_global: 4,
    width: 20,
    width_global: 20,
    thickness: 4,
    thickness_global: 4,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 1621,
    name: "30x20x3 UA",
    type: "Angle",
    designation: "UA30x20x3",
    shape: "L",
    country_code: "British",
    ax: "1,43",
    ax_global: 143,
    b: 20,
    b_global: 20,
    d: 30,
    d_global: 30,
    rz: "4,24",
    rz_global: "4,24",
    t: 3,
    t_global: 3,
    width: 20,
    width_global: 20,
    thickness: 3,
    thickness_global: 3,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 1622,
    name: "TUB20202.0",
    type: "Tube",
    designation: "TUB20202.0",
    shape: "TUBE",
    country_code: "British",
    ax: "1,42",
    ax_global: 142,
    b: 20,
    b_global: 20,
    d: 20,
    d_global: 20,
    ix: "1,22",
    ix_global: 12200,
    iy: "0,76",
    iy_global: 7600,
    iz: "0,76",
    iz_global: 7600,
    t: 2,
    t_global: 2,
    zx: "0,95",
    zx_global: 950,
    zy: "0,95",
    zy_global: 950,
  },
  {
    profile_section_id: 1623,
    name: "TUB20202.5",
    type: "Tube",
    designation: "TUB20202.5",
    shape: "TUBE",
    country_code: "British",
    ax: "1,72",
    ax_global: 172,
    b: 20,
    b_global: 20,
    d: 20,
    d_global: 20,
    ix: "1,41",
    ix_global: 14100,
    iy: "0,87",
    iy_global: 8700,
    iz: "0,87",
    iz_global: 8700,
    t: "2,5",
    t_global: "2,5",
    zx: "1,12",
    zx_global: 1120,
    zy: "1,12",
    zy_global: 1120,
  },
  {
    profile_section_id: 1624,
    name: "TUB25252.0",
    type: "Tube",
    designation: "TUB25252.0",
    shape: "TUBE",
    country_code: "British",
    ax: "1,82",
    ax_global: 182,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    ix: "2,52",
    ix_global: 25200,
    iy: "1,59",
    iy_global: 15900,
    iz: "1,59",
    iz_global: 15900,
    t: 2,
    t_global: 2,
    zx: "1,56",
    zx_global: 1560,
    zy: "1,56",
    zy_global: 1560,
  },
  {
    profile_section_id: 1625,
    name: "TUB25252.5",
    type: "Tube",
    designation: "TUB25252.5",
    shape: "TUBE",
    country_code: "British",
    ax: "2,22",
    ax_global: 222,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    ix: "2,97",
    ix_global: 29700,
    iy: "1,85",
    iy_global: 18500,
    iz: "1,85",
    iz_global: 18500,
    t: "2,5",
    t_global: "2,5",
    zx: "1,86",
    zx_global: 1860,
    zy: "1,86",
    zy_global: 1860,
  },
  {
    profile_section_id: 1626,
    name: "TUB25253.0",
    type: "Tube",
    designation: "TUB25253.0",
    shape: "TUBE",
    country_code: "British",
    ax: "2,6",
    ax_global: 260,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    ix: "3,36",
    ix_global: 33600,
    iy: "2,06",
    iy_global: 20600,
    iz: "2,06",
    iz_global: 20600,
    t: 3,
    t_global: 3,
    zx: "2,12",
    zx_global: 2120,
    zy: "2,12",
    zy_global: 2120,
  },
  {
    profile_section_id: 1627,
    name: "TUB30302.5",
    type: "Tube",
    designation: "TUB30302.5",
    shape: "TUBE",
    country_code: "British",
    ax: "2,72",
    ax_global: 272,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    ix: "5,4",
    ix_global: 54000,
    iy: "3,4",
    iy_global: 34000,
    iz: "3,4",
    iz_global: 34000,
    t: "2,5",
    t_global: "2,5",
    zx: "2,79",
    zx_global: 2790,
    zy: "2,79",
    zy_global: 2790,
  },
  {
    profile_section_id: 1628,
    name: "TUB25253.2",
    type: "Tube",
    designation: "TUB25253.2",
    shape: "TUBE",
    country_code: "British",
    ax: "2,74",
    ax_global: 274,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    ix: "3,49",
    ix_global: 34900,
    iy: "2,14",
    iy_global: 21400,
    iz: "2,14",
    iz_global: 21400,
    t: "3,2",
    t_global: "3,2",
    zx: "2,21",
    zx_global: 2210,
    zy: "2,21",
    zy_global: 2210,
  },
  {
    profile_section_id: 1629,
    name: "TUB30303.2",
    type: "Tube",
    designation: "TUB30303.2",
    shape: "TUBE",
    country_code: "British",
    ax: "3,38",
    ax_global: 338,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    ix: "6,45",
    ix_global: 64500,
    iy: 4,
    iy_global: 40000,
    iz: 4,
    iz_global: 40000,
    t: "3,2",
    t_global: "3,2",
    zx: "2,67",
    zx_global: 2670,
    zy: "2,67",
    zy_global: 2670,
  },
  {
    profile_section_id: 1630,
    name: "TUB50252.5",
    type: "Tube",
    designation: "TUB50252.5",
    shape: "TUBE",
    country_code: "British",
    ax: "3,47",
    ax_global: 347,
    b: 25,
    b_global: 25,
    d: 50,
    d_global: 50,
    ix: "8,41",
    ix_global: 84100,
    iy: "3,44",
    iy_global: 34400,
    iz: "10,6",
    iz_global: 106000,
    t: "2,5",
    t_global: "2,5",
    zx: "5,41",
    zx_global: 5410,
    zy: "3,26",
    zy_global: 3260,
  },
  {
    profile_section_id: 1631,
    name: "TUB40402.5",
    type: "Tube",
    designation: "TUB40402.5",
    shape: "TUBE",
    country_code: "British",
    ax: "3,68",
    ax_global: 368,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    ix: "13,6",
    ix_global: 136000,
    iy: "8,54",
    iy_global: 85400,
    iz: "8,54",
    iz_global: 85400,
    t: "2,5",
    t_global: "2,5",
    zx: "5,14",
    zx_global: 5140,
    zy: "5,14",
    zy_global: 5140,
  },
  {
    profile_section_id: 1632,
    name: "TUB50302.5",
    type: "Tube",
    designation: "TUB50302.5",
    shape: "TUBE",
    country_code: "British",
    ax: "3,68",
    ax_global: 368,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    ix: "11,7",
    ix_global: 117000,
    iy: "5,22",
    iy_global: 52200,
    iz: "11,8",
    iz_global: 118000,
    t: "2,5",
    t_global: "2,5",
    zx: "5,92",
    zx_global: 5920,
    zy: "4,11",
    zy_global: 4110,
  },
  {
    profile_section_id: 1633,
    name: "TUB50253.0",
    type: "Tube",
    designation: "TUB50253.0",
    shape: "TUBE",
    country_code: "British",
    ax: "4,1",
    ax_global: 410,
    b: 25,
    b_global: 25,
    d: 50,
    d_global: 50,
    ix: "9,64",
    ix_global: 96400,
    iy: "3,89",
    iy_global: 38900,
    iz: "12,2",
    iz_global: 122000,
    t: 3,
    t_global: 3,
    zx: "6,3",
    zx_global: 6300,
    zy: "3,77",
    zy_global: 3770,
  },
  {
    profile_section_id: 1634,
    name: "TUB40403.0",
    type: "Tube",
    designation: "TUB40403.0",
    shape: "TUBE",
    country_code: "British",
    ax: "4,34",
    ax_global: 434,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    ix: "15,7",
    ix_global: 157000,
    iy: "9,78",
    iy_global: 97800,
    iz: "9,78",
    iz_global: 97800,
    t: 3,
    t_global: 3,
    zx: "5,97",
    zx_global: 5970,
    zy: "5,97",
    zy_global: 5970,
  },
  {
    profile_section_id: 1635,
    name: "TUB50253.2",
    type: "Tube",
    designation: "TUB50253.2",
    shape: "TUBE",
    country_code: "British",
    ax: "4,34",
    ax_global: 434,
    b: 25,
    b_global: 25,
    d: 50,
    d_global: 50,
    ix: "10,1",
    ix_global: 101000,
    iy: "4,05",
    iy_global: 40500,
    iz: "12,8",
    iz_global: 128000,
    t: "3,2",
    t_global: "3,2",
    zx: "6,64",
    zx_global: 6640,
    zy: "3,96",
    zy_global: 3960,
  },
  {
    profile_section_id: 1636,
    name: "TUB50303.0",
    type: "Tube",
    designation: "TUB50303.0",
    shape: "TUBE",
    country_code: "British",
    ax: "4,34",
    ax_global: 434,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    ix: "13,5",
    ix_global: 135000,
    iy: "5,94",
    iy_global: 59400,
    iz: "13,6",
    iz_global: 136000,
    t: 3,
    t_global: 3,
    zx: "6,88",
    zx_global: 6880,
    zy: "4,76",
    zy_global: 4760,
  },
  {
    profile_section_id: 1637,
    name: "TUB40403.2",
    type: "Tube",
    designation: "TUB40403.2",
    shape: "TUBE",
    country_code: "British",
    ax: "4,6",
    ax_global: 460,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    ix: "16,5",
    ix_global: 165000,
    iy: "10,2",
    iy_global: 102000,
    iz: "10,2",
    iz_global: 102000,
    t: "3,2",
    t_global: "3,2",
    zx: "6,28",
    zx_global: 6280,
    zy: "6,28",
    zy_global: 6280,
  },
  {
    profile_section_id: 1638,
    name: "TUB50303.2",
    type: "Tube",
    designation: "TUB50303.2",
    shape: "TUBE",
    country_code: "British",
    ax: "4,6",
    ax_global: 460,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    ix: "14,2",
    ix_global: 142000,
    iy: "6,2",
    iy_global: 62000,
    iz: "14,2",
    iz_global: 142000,
    t: "3,2",
    t_global: "3,2",
    zx: "7,25",
    zx_global: 7250,
    zy: 5,
    zy_global: 5000,
  },
  {
    profile_section_id: 1639,
    name: "TUB50502.5",
    type: "Tube",
    designation: "TUB50502.5",
    shape: "TUBE",
    country_code: "British",
    ax: "4,68",
    ax_global: 468,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    ix: "27,5",
    ix_global: 275000,
    iy: "17,5",
    iy_global: 175000,
    iz: "17,5",
    iz_global: 175000,
    t: "2,5",
    t_global: "2,5",
    zx: "8,29",
    zx_global: 8290,
    zy: "8,29",
    zy_global: 8290,
  },
  {
    profile_section_id: 1640,
    name: "TUB60402.5",
    type: "Tube",
    designation: "TUB60402.5",
    shape: "TUBE",
    country_code: "British",
    ax: "4,68",
    ax_global: 468,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    ix: "25,1",
    ix_global: 251000,
    iy: "12,1",
    iy_global: 121000,
    iz: "22,8",
    iz_global: 228000,
    t: "2,5",
    t_global: "2,5",
    zx: "9,32",
    zx_global: 9320,
    zy: "7,02",
    zy_global: 7020,
  },
  {
    profile_section_id: 1641,
    name: "TUB40403.6",
    type: "Tube",
    designation: "TUB40403.6",
    shape: "TUBE",
    country_code: "British",
    ax: "5,1",
    ax_global: 510,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    ix: "18,1",
    ix_global: 181000,
    iy: "11,1",
    iy_global: 111000,
    iz: "11,1",
    iz_global: 111000,
    t: "3,6",
    t_global: "3,6",
    zx: "6,88",
    zx_global: 6880,
    zy: "6,88",
    zy_global: 6880,
  },
  {
    profile_section_id: 1642,
    name: "TUB50303.6",
    type: "Tube",
    designation: "TUB50303.6",
    shape: "TUBE",
    country_code: "British",
    ax: "5,1",
    ax_global: 510,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    ix: "15,4",
    ix_global: 154000,
    iy: "6,67",
    iy_global: 66700,
    iz: "15,4",
    iz_global: 154000,
    t: "3,6",
    t_global: "3,6",
    zx: "7,94",
    zx_global: 7940,
    zy: "5,46",
    zy_global: 5460,
  },
  {
    profile_section_id: 1643,
    name: "TUB50503.0",
    type: "Tube",
    designation: "TUB50503.0",
    shape: "TUBE",
    country_code: "British",
    ax: "5,54",
    ax_global: 554,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    ix: "32,1",
    ix_global: 321000,
    iy: "20,2",
    iy_global: 202000,
    iz: "20,2",
    iz_global: 202000,
    t: 3,
    t_global: 3,
    zx: "9,7",
    zx_global: 9700,
    zy: "9,7",
    zy_global: 9700,
  },
  {
    profile_section_id: 1644,
    name: "TUB60403.0",
    type: "Tube",
    designation: "TUB60403.0",
    shape: "TUBE",
    country_code: "British",
    ax: "5,54",
    ax_global: 554,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    ix: "29,2",
    ix_global: 292000,
    iy: "13,9",
    iy_global: 139000,
    iz: "26,5",
    iz_global: 265000,
    t: 3,
    t_global: 3,
    zx: "10,9",
    zx_global: 10900,
    zy: "8,19",
    zy_global: 8190,
  },
  {
    profile_section_id: 1645,
    name: "TUB40404.0",
    type: "Tube",
    designation: "TUB40404.0",
    shape: "TUBE",
    country_code: "British",
    ax: "5,59",
    ax_global: 559,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    ix: "19,5",
    ix_global: 195000,
    iy: "11,8",
    iy_global: 118000,
    iz: "11,8",
    iz_global: 118000,
    t: 4,
    t_global: 4,
    zx: "7,44",
    zx_global: 7440,
    zy: "7,44",
    zy_global: 7440,
  },
  {
    profile_section_id: 1646,
    name: "TUB50304.0",
    type: "Tube",
    designation: "TUB50304.0",
    shape: "TUBE",
    country_code: "British",
    ax: "5,59",
    ax_global: 559,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    ix: "16,6",
    ix_global: 166000,
    iy: "7,08",
    iy_global: 70800,
    iz: "16,5",
    iz_global: 165000,
    t: 4,
    t_global: 4,
    zx: "8,59",
    zx_global: 8590,
    zy: "5,88",
    zy_global: 5880,
  },
  {
    profile_section_id: 1647,
    name: "TUB50503.2",
    type: "Tube",
    designation: "TUB50503.2",
    shape: "TUBE",
    country_code: "British",
    ax: "5,88",
    ax_global: 588,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    ix: "33,8",
    ix_global: 338000,
    iy: "21,2",
    iy_global: 212000,
    iz: "21,2",
    iz_global: 212000,
    t: "3,2",
    t_global: "3,2",
    zx: "10,2",
    zx_global: 10200,
    zy: "10,2",
    zy_global: 10200,
  },
  {
    profile_section_id: 1648,
    name: "TUB60403.2",
    type: "Tube",
    designation: "TUB60403.2",
    shape: "TUBE",
    country_code: "British",
    ax: "5,88",
    ax_global: 588,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    ix: "30,8",
    ix_global: 308000,
    iy: "14,6",
    iy_global: 146000,
    iz: "27,8",
    iz_global: 278000,
    t: "3,2",
    t_global: "3,2",
    zx: "11,5",
    zx_global: 11500,
    zy: "8,64",
    zy_global: 8640,
  },
  {
    profile_section_id: 1649,
    name: "TUB50503.6",
    type: "Tube",
    designation: "TUB50503.6",
    shape: "TUBE",
    country_code: "British",
    ax: "6,54",
    ax_global: 654,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    ix: "37,2",
    ix_global: 372000,
    iy: "23,2",
    iy_global: 232000,
    iz: "23,2",
    iz_global: 232000,
    t: "3,6",
    t_global: "3,6",
    zx: "11,3",
    zx_global: 11300,
    zy: "11,3",
    zy_global: 11300,
  },
  {
    profile_section_id: 1650,
    name: "TUB60403.6",
    type: "Tube",
    designation: "TUB60403.6",
    shape: "TUBE",
    country_code: "British",
    ax: "6,54",
    ax_global: 654,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    ix: "33,8",
    ix_global: 338000,
    iy: "15,9",
    iy_global: 159000,
    iz: "30,4",
    iz_global: 304000,
    t: "3,6",
    t_global: "3,6",
    zx: "12,7",
    zx_global: 12700,
    zy: "9,5",
    zy_global: 9500,
  },
  {
    profile_section_id: 1651,
    name: "TUB40405.0",
    type: "Tube",
    designation: "TUB40405.0",
    shape: "TUBE",
    country_code: "British",
    ax: "6,73",
    ax_global: 673,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    ix: "22,5",
    ix_global: 225000,
    iy: "13,4",
    iy_global: 134000,
    iz: "13,4",
    iz_global: 134000,
    t: 5,
    t_global: 5,
    zx: "8,66",
    zx_global: 8660,
    zy: "8,66",
    zy_global: 8660,
  },
  {
    profile_section_id: 1652,
    name: "TUB50305.0",
    type: "Tube",
    designation: "TUB50305.0",
    shape: "TUBE",
    country_code: "British",
    ax: "6,73",
    ax_global: 673,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    ix: 19,
    ix_global: 190000,
    iy: "7,89",
    iy_global: 78900,
    iz: "18,7",
    iz_global: 187000,
    t: 5,
    t_global: 5,
    zx: 10,
    zx_global: 10000,
    zy: "6,8",
    zy_global: 6800,
  },
  {
    profile_section_id: 1653,
    name: "TUB60603.0",
    type: "Tube",
    designation: "TUB60603.0",
    shape: "TUBE",
    country_code: "British",
    ax: "6,74",
    ax_global: 674,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    ix: "56,9",
    ix_global: 569000,
    iy: "36,2",
    iy_global: 362000,
    iz: "36,2",
    iz_global: 362000,
    t: 3,
    t_global: 3,
    zx: "14,3",
    zx_global: 14300,
    zy: "14,3",
    zy_global: 14300,
  },
  {
    profile_section_id: 1654,
    name: "TUB80403.0",
    type: "Tube",
    designation: "TUB80403.0",
    shape: "TUBE",
    country_code: "British",
    ax: "6,74",
    ax_global: 674,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: "43,8",
    ix_global: 438000,
    iy: 18,
    iy_global: 180000,
    iz: "54,2",
    iz_global: 542000,
    t: 3,
    t_global: 3,
    zx: "17,1",
    zx_global: 17100,
    zy: "10,4",
    zy_global: 10400,
  },
  {
    profile_section_id: 1655,
    name: "TUB60603.2",
    type: "Tube",
    designation: "TUB60603.2",
    shape: "TUBE",
    country_code: "British",
    ax: "7,16",
    ax_global: 716,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    ix: "60,2",
    ix_global: 602000,
    iy: "38,2",
    iy_global: 382000,
    iz: "38,2",
    iz_global: 382000,
    t: "3,2",
    t_global: "3,2",
    zx: "15,2",
    zx_global: 15200,
    zy: "15,2",
    zy_global: 15200,
  },
  {
    profile_section_id: 1656,
    name: "TUB76513.0",
    type: "Tube",
    designation: "TUB76513.0",
    shape: "TUBE",
    country_code: "British",
    ax: "7,16",
    ax_global: 716,
    b: 51,
    b_global: 51,
    d: 76,
    d_global: 76,
    ix: "62,1",
    ix_global: 621000,
    iy: 30,
    iy_global: 300000,
    iz: "56,7",
    iz_global: 567000,
    t: 3,
    t_global: 3,
    zx: "18,2",
    zx_global: 18200,
    zy: "13,7",
    zy_global: 13700,
  },
  {
    profile_section_id: 1657,
    name: "TUB80403.2",
    type: "Tube",
    designation: "TUB80403.2",
    shape: "TUBE",
    country_code: "British",
    ax: "7,16",
    ax_global: 716,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: "46,2",
    ix_global: 462000,
    iy: "18,9",
    iy_global: 189000,
    iz: "57,2",
    iz_global: 572000,
    t: "3,2",
    t_global: "3,2",
    zx: 18,
    zx_global: 18000,
    zy: 11,
    zy_global: 11000,
  },
  {
    profile_section_id: 1658,
    name: "TUB50504.0",
    type: "Tube",
    designation: "TUB50504.0",
    shape: "TUBE",
    country_code: "British",
    ax: "7,19",
    ax_global: 719,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    ix: "40,4",
    ix_global: 404000,
    iy: 25,
    iy_global: 250000,
    iz: 25,
    iz_global: 250000,
    t: 4,
    t_global: 4,
    zx: "12,3",
    zx_global: 12300,
    zy: "12,3",
    zy_global: 12300,
  },
  {
    profile_section_id: 1659,
    name: "TUB60404.0",
    type: "Tube",
    designation: "TUB60404.0",
    shape: "TUBE",
    country_code: "British",
    ax: "7,19",
    ax_global: 719,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    ix: "36,7",
    ix_global: 367000,
    iy: 17,
    iy_global: 170000,
    iz: "32,8",
    iz_global: 328000,
    t: 4,
    t_global: 4,
    zx: "13,8",
    zx_global: 13800,
    zy: "10,3",
    zy_global: 10300,
  },
  {
    profile_section_id: 1660,
    name: "TUB76513.2",
    type: "Tube",
    designation: "TUB76513.2",
    shape: "TUBE",
    country_code: "British",
    ax: "7,61",
    ax_global: 761,
    b: 51,
    b_global: 51,
    d: 76,
    d_global: 76,
    ix: "65,7",
    ix_global: 657000,
    iy: "31,6",
    iy_global: 316000,
    iz: "59,8",
    iz_global: 598000,
    t: "3,2",
    t_global: "3,2",
    zx: "19,2",
    zx_global: 19200,
    zy: "14,5",
    zy_global: 14500,
  },
  {
    profile_section_id: 1661,
    name: "TUB70703.0",
    type: "Tube",
    designation: "TUB70703.0",
    shape: "TUBE",
    country_code: "British",
    ax: "7,94",
    ax_global: 794,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    ix: "92,2",
    ix_global: 922000,
    iy: 59,
    iy_global: 590000,
    iz: 59,
    iz_global: 590000,
    t: 3,
    t_global: 3,
    zx: "19,9",
    zx_global: 19900,
    zy: "19,9",
    zy_global: 19900,
  },
  {
    profile_section_id: 1662,
    name: "TUB90503.0",
    type: "Tube",
    designation: "TUB90503.0",
    shape: "TUBE",
    country_code: "British",
    ax: "7,94",
    ax_global: 794,
    b: 50,
    b_global: 50,
    d: 90,
    d_global: 90,
    ix: "76,5",
    ix_global: 765000,
    iy: "33,5",
    iy_global: 335000,
    iz: "84,4",
    iz_global: 844000,
    t: 3,
    t_global: 3,
    zx: "23,2",
    zx_global: 23200,
    zy: "15,3",
    zy_global: 15300,
  },
  {
    profile_section_id: 1663,
    name: "TUB60603.6",
    type: "Tube",
    designation: "TUB60603.6",
    shape: "TUBE",
    country_code: "British",
    ax: "7,98",
    ax_global: 798,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    ix: "66,5",
    ix_global: 665000,
    iy: "41,9",
    iy_global: 419000,
    iz: "41,9",
    iz_global: 419000,
    t: "3,6",
    t_global: "3,6",
    zx: "16,8",
    zx_global: 16800,
    zy: "16,8",
    zy_global: 16800,
  },
  {
    profile_section_id: 1664,
    name: "TUB80403.6",
    type: "Tube",
    designation: "TUB80403.6",
    shape: "TUBE",
    country_code: "British",
    ax: "7,98",
    ax_global: 798,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: "50,8",
    ix_global: 508000,
    iy: "20,6",
    iy_global: 206000,
    iz: "62,8",
    iz_global: 628000,
    t: "3,6",
    t_global: "3,6",
    zx: 20,
    zx_global: 20000,
    zy: "12,1",
    zy_global: 12100,
  },
  {
    profile_section_id: 1665,
    name: "TUB70703.2",
    type: "Tube",
    designation: "TUB70703.2",
    shape: "TUBE",
    country_code: "British",
    ax: "8,44",
    ax_global: 844,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    ix: "97,6",
    ix_global: 976000,
    iy: "62,3",
    iy_global: 623000,
    iz: "62,3",
    iz_global: 623000,
    t: "3,2",
    t_global: "3,2",
    zx: 21,
    zx_global: 21000,
    zy: 21,
    zy_global: 21000,
  },
  {
    profile_section_id: 1666,
    name: "TUB90503.2",
    type: "Tube",
    designation: "TUB90503.2",
    shape: "TUBE",
    country_code: "British",
    ax: "8,44",
    ax_global: 844,
    b: 50,
    b_global: 50,
    d: 90,
    d_global: 90,
    ix: "80,9",
    ix_global: 809000,
    iy: "35,3",
    iy_global: 353000,
    iz: "89,1",
    iz_global: 891000,
    t: "3,2",
    t_global: "3,2",
    zx: "24,6",
    zx_global: 24600,
    zy: "16,2",
    zy_global: 16200,
  },
  {
    profile_section_id: 1667,
    name: "TUB76513.6",
    type: "Tube",
    designation: "TUB76513.6",
    shape: "TUBE",
    country_code: "British",
    ax: "8,49",
    ax_global: 849,
    b: 51,
    b_global: 51,
    d: 76,
    d_global: 76,
    ix: "72,5",
    ix_global: 725000,
    iy: "34,6",
    iy_global: 346000,
    iz: "65,8",
    iz_global: 658000,
    t: "3,6",
    t_global: "3,6",
    zx: "21,3",
    zx_global: 21300,
    zy: 16,
    zy_global: 16000,
  },
  {
    profile_section_id: 1668,
    name: "TUB100503.0",
    type: "Tube",
    designation: "TUB100503.0",
    shape: "TUBE",
    country_code: "British",
    ax: "8,54",
    ax_global: 854,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    ix: "88,4",
    ix_global: 884000,
    iy: "36,8",
    iy_global: 368000,
    iz: 110,
    iz_global: 1100000,
    t: 3,
    t_global: 3,
    zx: "27,3",
    zx_global: 27300,
    zy: "16,8",
    zy_global: 16800,
  },
  {
    profile_section_id: 1669,
    name: "TUB50505.0",
    type: "Tube",
    designation: "TUB50505.0",
    shape: "TUBE",
    country_code: "British",
    ax: "8,73",
    ax_global: 873,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    ix: "47,6",
    ix_global: 476000,
    iy: "28,9",
    iy_global: 289000,
    iz: "28,9",
    iz_global: 289000,
    t: 5,
    t_global: 5,
    zx: "14,5",
    zx_global: 14500,
    zy: "14,5",
    zy_global: 14500,
  },
  {
    profile_section_id: 1670,
    name: "TUB60405.0",
    type: "Tube",
    designation: "TUB60405.0",
    shape: "TUBE",
    country_code: "British",
    ax: "8,73",
    ax_global: 873,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    ix: 43,
    ix_global: 430000,
    iy: "19,5",
    iy_global: 195000,
    iz: "38,1",
    iz_global: 381000,
    t: 5,
    t_global: 5,
    zx: "16,4",
    zx_global: 16400,
    zy: "12,2",
    zy_global: 12200,
  },
  {
    profile_section_id: 1671,
    name: "TUB60604.0",
    type: "Tube",
    designation: "TUB60604.0",
    shape: "TUBE",
    country_code: "British",
    ax: "8,79",
    ax_global: 879,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    ix: "72,5",
    ix_global: 725000,
    iy: "45,4",
    iy_global: 454000,
    iz: "45,4",
    iz_global: 454000,
    t: 4,
    t_global: 4,
    zx: "18,3",
    zx_global: 18300,
    zy: "18,3",
    zy_global: 18300,
  },
  {
    profile_section_id: 1672,
    name: "TUB80404.0",
    type: "Tube",
    designation: "TUB80404.0",
    shape: "TUBE",
    country_code: "British",
    ax: "8,79",
    ax_global: 879,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: "55,2",
    ix_global: 552000,
    iy: "22,2",
    iy_global: 222000,
    iz: "68,2",
    iz_global: 682000,
    t: 4,
    t_global: 4,
    zx: "21,8",
    zx_global: 21800,
    zy: "13,2",
    zy_global: 13200,
  },
  {
    profile_section_id: 1673,
    name: "TUB100503.2",
    type: "Tube",
    designation: "TUB100503.2",
    shape: "TUBE",
    country_code: "British",
    ax: "9,08",
    ax_global: 908,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    ix: "93,4",
    ix_global: 934000,
    iy: "38,8",
    iy_global: 388000,
    iz: 116,
    iz_global: 1160000,
    t: "3,2",
    t_global: "3,2",
    zx: "28,9",
    zx_global: 28900,
    zy: "17,7",
    zy_global: 17700,
  },
  {
    profile_section_id: 1674,
    name: "TUB100603.0",
    type: "Tube",
    designation: "TUB100603.0",
    shape: "TUBE",
    country_code: "British",
    ax: "9,14",
    ax_global: 914,
    b: 60,
    b_global: 60,
    d: 100,
    d_global: 100,
    ix: 121,
    ix_global: 1210000,
    iy: "55,7",
    iy_global: 557000,
    iz: 124,
    iz_global: 1240000,
    t: 3,
    t_global: 3,
    zx: "30,2",
    zx_global: 30200,
    zy: "21,2",
    zy_global: 21200,
  },
  {
    profile_section_id: 1675,
    name: "TUB80803.0",
    type: "Tube",
    designation: "TUB80803.0",
    shape: "TUBE",
    country_code: "British",
    ax: "9,2",
    ax_global: 920,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    ix: 139,
    ix_global: 1390000,
    iy: "90,6",
    iy_global: 906000,
    iz: "90,6",
    iz_global: 906000,
    t: 3,
    t_global: 3,
    zx: "26,5",
    zx_global: 26500,
    zy: "26,5",
    zy_global: 26500,
  },
  {
    profile_section_id: 1676,
    name: "TUB76514.0",
    type: "Tube",
    designation: "TUB76514.0",
    shape: "TUBE",
    country_code: "British",
    ax: "9,35",
    ax_global: 935,
    b: 51,
    b_global: 51,
    d: 76,
    d_global: 76,
    ix: "79,1",
    ix_global: 791000,
    iy: "37,5",
    iy_global: 375000,
    iz: "71,5",
    iz_global: 715000,
    t: 4,
    t_global: 4,
    zx: "23,3",
    zx_global: 23300,
    zy: "17,5",
    zy_global: 17500,
  },
  {
    profile_section_id: 1677,
    name: "TUB70703.6",
    type: "Tube",
    designation: "TUB70703.6",
    shape: "TUBE",
    country_code: "British",
    ax: "9,42",
    ax_global: 942,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    ix: 108,
    ix_global: 1080000,
    iy: "68,6",
    iy_global: 686000,
    iz: "68,6",
    iz_global: 686000,
    t: "3,6",
    t_global: "3,6",
    zx: "23,3",
    zx_global: 23300,
    zy: "23,3",
    zy_global: 23300,
  },
  {
    profile_section_id: 1678,
    name: "TUB90503.6",
    type: "Tube",
    designation: "TUB90503.6",
    shape: "TUBE",
    country_code: "British",
    ax: "9,42",
    ax_global: 942,
    b: 50,
    b_global: 50,
    d: 90,
    d_global: 90,
    ix: "89,4",
    ix_global: 894000,
    iy: "38,7",
    iy_global: 387000,
    iz: "98,3",
    iz_global: 983000,
    t: "3,6",
    t_global: "3,6",
    zx: "27,2",
    zx_global: 27200,
    zy: 18,
    zy_global: 18000,
  },
  {
    profile_section_id: 1679,
    name: "TUB80803.2",
    type: "Tube",
    designation: "TUB80803.2",
    shape: "TUBE",
    country_code: "British",
    ax: "9,72",
    ax_global: 972,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    ix: 148,
    ix_global: 1480000,
    iy: 95,
    iy_global: 950000,
    iz: 95,
    iz_global: 950000,
    t: "3,2",
    t_global: "3,2",
    zx: "27,9",
    zx_global: 27900,
    zy: "27,9",
    zy_global: 27900,
  },
  {
    profile_section_id: 1680,
    name: "TUB100603.2",
    type: "Tube",
    designation: "TUB100603.2",
    shape: "TUBE",
    country_code: "British",
    ax: "9,72",
    ax_global: 972,
    b: 60,
    b_global: 60,
    d: 100,
    d_global: 100,
    ix: 129,
    ix_global: 1290000,
    iy: "58,8",
    iy_global: 588000,
    iz: 131,
    iz_global: 1310000,
    t: "3,2",
    t_global: "3,2",
    zx: 32,
    zx_global: 32000,
    zy: "22,4",
    zy_global: 22400,
  },
  {
    profile_section_id: 1681,
    name: "TUB100503.6",
    type: "Tube",
    designation: "TUB100503.6",
    shape: "TUBE",
    country_code: "British",
    ax: "10,1",
    ax_global: 1010,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    ix: 103,
    ix_global: 1030000,
    iy: "42,6",
    iy_global: 426000,
    iz: 128,
    iz_global: 1280000,
    t: "3,6",
    t_global: "3,6",
    zx: "32,1",
    zx_global: 32100,
    zy: "19,6",
    zy_global: 19600,
  },
  {
    profile_section_id: 1682,
    name: "TUB50506.0",
    type: "Tube",
    designation: "TUB50506.0",
    shape: "TUBE",
    country_code: "British",
    ax: "10,2",
    ax_global: 1020,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    ix: "53,6",
    ix_global: 536000,
    iy: 32,
    iy_global: 320000,
    iz: 32,
    iz_global: 320000,
    t: 6,
    t_global: 6,
    zx: "16,5",
    zx_global: 16500,
    zy: "16,5",
    zy_global: 16500,
  },
  {
    profile_section_id: 1683,
    name: "TUB60406.0",
    type: "Tube",
    designation: "TUB60406.0",
    shape: "TUBE",
    country_code: "British",
    ax: "10,2",
    ax_global: 1020,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    ix: "48,2",
    ix_global: 482000,
    iy: "21,4",
    iy_global: 214000,
    iz: "42,3",
    iz_global: 423000,
    t: 6,
    t_global: 6,
    zx: "18,6",
    zx_global: 18600,
    zy: "13,7",
    zy_global: 13700,
  },
  {
    profile_section_id: 1684,
    name: "TUB70704.0",
    type: "Tube",
    designation: "TUB70704.0",
    shape: "TUBE",
    country_code: "British",
    ax: "10,4",
    ax_global: 1040,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    ix: 118,
    ix_global: 1180000,
    iy: "74,7",
    iy_global: 747000,
    iz: "74,7",
    iz_global: 747000,
    t: 4,
    t_global: 4,
    zx: "25,5",
    zx_global: 25500,
    zy: "25,5",
    zy_global: 25500,
  },
  {
    profile_section_id: 1685,
    name: "TUB90504.0",
    type: "Tube",
    designation: "TUB90504.0",
    shape: "TUBE",
    country_code: "British",
    ax: "10,4",
    ax_global: 1040,
    b: 50,
    b_global: 50,
    d: 90,
    d_global: 90,
    ix: "97,5",
    ix_global: 975000,
    iy: "41,9",
    iy_global: 419000,
    iz: 107,
    iz_global: 1070000,
    t: 4,
    t_global: 4,
    zx: "29,8",
    zx_global: 29800,
    zy: "19,6",
    zy_global: 19600,
  },
  {
    profile_section_id: 1686,
    name: "TUB50506.3",
    type: "Tube",
    designation: "TUB50506.3",
    shape: "TUBE",
    country_code: "British",
    ax: "10,6",
    ax_global: 1060,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    ix: "55,2",
    ix_global: 552000,
    iy: "32,8",
    iy_global: 328000,
    iz: "32,8",
    iz_global: 328000,
    t: "6,3",
    t_global: "6,3",
    zx: 17,
    zx_global: 17000,
    zy: 17,
    zy_global: 17000,
  },
  {
    profile_section_id: 1687,
    name: "TUB60406.3",
    type: "Tube",
    designation: "TUB60406.3",
    shape: "TUBE",
    country_code: "British",
    ax: "10,6",
    ax_global: 1060,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    ix: "49,5",
    ix_global: 495000,
    iy: "21,9",
    iy_global: 219000,
    iz: "43,4",
    iz_global: 434000,
    t: "6,3",
    t_global: "6,3",
    zx: "19,2",
    zx_global: 19200,
    zy: "14,2",
    zy_global: 14200,
  },
  {
    profile_section_id: 1688,
    name: "TUB60605.0",
    type: "Tube",
    designation: "TUB60605.0",
    shape: "TUBE",
    country_code: "British",
    ax: "10,7",
    ax_global: 1070,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    ix: "86,4",
    ix_global: 864000,
    iy: "53,3",
    iy_global: 533000,
    iz: "53,3",
    iz_global: 533000,
    t: 5,
    t_global: 5,
    zx: "21,9",
    zx_global: 21900,
    zy: "21,9",
    zy_global: 21900,
  },
  {
    profile_section_id: 1689,
    name: "TUB80405.0",
    type: "Tube",
    designation: "TUB80405.0",
    shape: "TUBE",
    country_code: "British",
    ax: "10,7",
    ax_global: 1070,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: "65,1",
    ix_global: 651000,
    iy: "25,7",
    iy_global: 257000,
    iz: "80,3",
    iz_global: 803000,
    t: 5,
    t_global: 5,
    zx: "26,1",
    zx_global: 26100,
    zy: "15,7",
    zy_global: 15700,
  },
  {
    profile_section_id: 1690,
    name: "TUB80803.6",
    type: "Tube",
    designation: "TUB80803.6",
    shape: "TUBE",
    country_code: "British",
    ax: "10,9",
    ax_global: 1090,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    ix: 164,
    ix_global: 1640000,
    iy: 105,
    iy_global: 1050000,
    iz: 105,
    iz_global: 1050000,
    t: "3,6",
    t_global: "3,6",
    zx: 31,
    zx_global: 31000,
    zy: 31,
    zy_global: 31000,
  },
  {
    profile_section_id: 1691,
    name: "TUB100603.6",
    type: "Tube",
    designation: "TUB100603.6",
    shape: "TUBE",
    country_code: "British",
    ax: "10,9",
    ax_global: 1090,
    b: 60,
    b_global: 60,
    d: 100,
    d_global: 100,
    ix: 142,
    ix_global: 1420000,
    iy: "64,8",
    iy_global: 648000,
    iz: 145,
    iz_global: 1450000,
    t: "3,6",
    t_global: "3,6",
    zx: "35,6",
    zx_global: 35600,
    zy: "24,9",
    zy_global: 24900,
  },
  {
    profile_section_id: 1692,
    name: "TUB100504.0",
    type: "Tube",
    designation: "TUB100504.0",
    shape: "TUBE",
    country_code: "British",
    ax: "11,2",
    ax_global: 1120,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    ix: 113,
    ix_global: 1130000,
    iy: "46,2",
    iy_global: 462000,
    iz: 140,
    iz_global: 1400000,
    t: 4,
    t_global: 4,
    zx: "35,2",
    zx_global: 35200,
    zy: "21,5",
    zy_global: 21500,
  },
  {
    profile_section_id: 1693,
    name: "TUB76515.0",
    type: "Tube",
    designation: "TUB76515.0",
    shape: "TUBE",
    country_code: "British",
    ax: "11,4",
    ax_global: 1140,
    b: 51,
    b_global: 51,
    d: 76,
    d_global: 76,
    ix: "94,2",
    ix_global: 942000,
    iy: "43,9",
    iy_global: 439000,
    iz: "84,4",
    iz_global: 844000,
    t: 5,
    t_global: 5,
    zx: 28,
    zx_global: 28000,
    zy: "20,9",
    zy_global: 20900,
  },
  {
    profile_section_id: 1694,
    name: "TUB80804.0",
    type: "Tube",
    designation: "TUB80804.0",
    shape: "TUBE",
    country_code: "British",
    ax: 12,
    ax_global: 1200,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    ix: 180,
    ix_global: 1800000,
    iy: 114,
    iy_global: 1140000,
    iz: 114,
    iz_global: 1140000,
    t: 4,
    t_global: 4,
    zx: 34,
    zx_global: 34000,
    zy: 34,
    zy_global: 34000,
  },
  {
    profile_section_id: 1695,
    name: "TUB100604.0",
    type: "Tube",
    designation: "TUB100604.0",
    shape: "TUBE",
    country_code: "British",
    ax: 12,
    ax_global: 1200,
    b: 60,
    b_global: 60,
    d: 100,
    d_global: 100,
    ix: 156,
    ix_global: 1560000,
    iy: "70,5",
    iy_global: 705000,
    iz: 158,
    iz_global: 1580000,
    t: 4,
    t_global: 4,
    zx: "39,1",
    zx_global: 39100,
    zy: "27,3",
    zy_global: 27300,
  },
  {
    profile_section_id: 1696,
    name: "TUB90903.6",
    type: "Tube",
    designation: "TUB90903.6",
    shape: "TUBE",
    country_code: "British",
    ax: "12,3",
    ax_global: 1230,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    ix: 237,
    ix_global: 2370000,
    iy: 152,
    iy_global: 1520000,
    iz: 152,
    iz_global: 1520000,
    t: "3,6",
    t_global: "3,6",
    zx: "39,7",
    zx_global: 39700,
    zy: "39,7",
    zy_global: 39700,
  },
  {
    profile_section_id: 1697,
    name: "TUB120603.6",
    type: "Tube",
    designation: "TUB120603.6",
    shape: "TUBE",
    country_code: "British",
    ax: "12,3",
    ax_global: 1230,
    b: 60,
    b_global: 60,
    d: 120,
    d_global: 120,
    ix: 183,
    ix_global: 1830000,
    iy: "76,3",
    iy_global: 763000,
    iz: 227,
    iz_global: 2270000,
    t: "3,6",
    t_global: "3,6",
    zx: "47,2",
    zx_global: 47200,
    zy: "28,9",
    zy_global: 28900,
  },
  {
    profile_section_id: 1698,
    name: "TUB60606.0",
    type: "Tube",
    designation: "TUB60606.0",
    shape: "TUBE",
    country_code: "British",
    ax: "12,6",
    ax_global: 1260,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    ix: "98,6",
    ix_global: 986000,
    iy: "59,9",
    iy_global: 599000,
    iz: "59,9",
    iz_global: 599000,
    t: 6,
    t_global: 6,
    zx: "25,1",
    zx_global: 25100,
    zy: "25,1",
    zy_global: 25100,
  },
  {
    profile_section_id: 1699,
    name: "TUB80406.0",
    type: "Tube",
    designation: "TUB80406.0",
    shape: "TUBE",
    country_code: "British",
    ax: "12,6",
    ax_global: 1260,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: "73,4",
    ix_global: 734000,
    iy: "28,5",
    iy_global: 285000,
    iz: "90,5",
    iz_global: 905000,
    t: 6,
    t_global: 6,
    zx: 30,
    zx_global: 30000,
    zy: "17,8",
    zy_global: 17800,
  },
  {
    profile_section_id: 1700,
    name: "TUB70705.0",
    type: "Tube",
    designation: "TUB70705.0",
    shape: "TUBE",
    country_code: "British",
    ax: "12,7",
    ax_global: 1270,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    ix: 142,
    ix_global: 1420000,
    iy: "88,5",
    iy_global: 885000,
    iz: "88,5",
    iz_global: 885000,
    t: 5,
    t_global: 5,
    zx: "30,8",
    zx_global: 30800,
    zy: "30,8",
    zy_global: 30800,
  },
  {
    profile_section_id: 1701,
    name: "TUB90505.0",
    type: "Tube",
    designation: "TUB90505.0",
    shape: "TUBE",
    country_code: "British",
    ax: "12,7",
    ax_global: 1270,
    b: 50,
    b_global: 50,
    d: 90,
    d_global: 90,
    ix: 116,
    ix_global: 1160000,
    iy: "49,2",
    iy_global: 492000,
    iz: 127,
    iz_global: 1270000,
    t: 5,
    t_global: 5,
    zx: 36,
    zx_global: 36000,
    zy: "23,5",
    zy_global: 23500,
  },
  {
    profile_section_id: 1702,
    name: "TUB60606.3",
    type: "Tube",
    designation: "TUB60606.3",
    shape: "TUBE",
    country_code: "British",
    ax: "13,1",
    ax_global: 1310,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    ix: 102,
    ix_global: 1020000,
    iy: "61,6",
    iy_global: 616000,
    iz: "61,6",
    iz_global: 616000,
    t: "6,3",
    t_global: "6,3",
    zx: 26,
    zx_global: 26000,
    zy: 26,
    zy_global: 26000,
  },
  {
    profile_section_id: 1703,
    name: "TUB80406.3",
    type: "Tube",
    designation: "TUB80406.3",
    shape: "TUBE",
    country_code: "British",
    ax: "13,1",
    ax_global: 1310,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: "75,6",
    ix_global: 756000,
    iy: "29,2",
    iy_global: 292000,
    iz: "93,3",
    iz_global: 933000,
    t: "6,3",
    t_global: "6,3",
    zx: "31,1",
    zx_global: 31100,
    zy: "18,4",
    zy_global: 18400,
  },
  {
    profile_section_id: 1704,
    name: "TUB76516.0",
    type: "Tube",
    designation: "TUB76516.0",
    shape: "TUBE",
    country_code: "British",
    ax: "13,4",
    ax_global: 1340,
    b: 51,
    b_global: 51,
    d: 76,
    d_global: 76,
    ix: 108,
    ix_global: 1080000,
    iy: "49,2",
    iy_global: 492000,
    iz: "95,6",
    iz_global: 956000,
    t: 6,
    t_global: 6,
    zx: "32,2",
    zx_global: 32200,
    zy: "23,9",
    zy_global: 23900,
  },
  {
    profile_section_id: 1705,
    name: "TUB90904.0",
    type: "Tube",
    designation: "TUB90904.0",
    shape: "TUBE",
    country_code: "British",
    ax: "13,6",
    ax_global: 1360,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    ix: 260,
    ix_global: 2600000,
    iy: 166,
    iy_global: 1660000,
    iz: 166,
    iz_global: 1660000,
    t: 4,
    t_global: 4,
    zx: "43,6",
    zx_global: 43600,
    zy: "43,6",
    zy_global: 43600,
  },
  {
    profile_section_id: 1706,
    name: "TUB120604.0",
    type: "Tube",
    designation: "TUB120604.0",
    shape: "TUBE",
    country_code: "British",
    ax: "13,6",
    ax_global: 1360,
    b: 60,
    b_global: 60,
    d: 120,
    d_global: 120,
    ix: 201,
    ix_global: 2010000,
    iy: "83,1",
    iy_global: 831000,
    iz: 249,
    iz_global: 2490000,
    t: 4,
    t_global: 4,
    zx: "51,9",
    zx_global: 51900,
    zy: "31,7",
    zy_global: 31700,
  },
  {
    profile_section_id: 1707,
    name: "TUB100505.0",
    type: "Tube",
    designation: "TUB100505.0",
    shape: "TUBE",
    country_code: "British",
    ax: "13,7",
    ax_global: 1370,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    ix: 135,
    ix_global: 1350000,
    iy: "54,3",
    iy_global: 543000,
    iz: 167,
    iz_global: 1670000,
    t: 5,
    t_global: 5,
    zx: "42,6",
    zx_global: 42600,
    zy: "25,8",
    zy_global: 25800,
  },
  {
    profile_section_id: 1708,
    name: "TUB1001003.6",
    type: "Tube",
    designation: "TUB1001003.6",
    shape: "TUBE",
    country_code: "British",
    ax: "13,7",
    ax_global: 1370,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 328,
    ix_global: 3280000,
    iy: 212,
    iy_global: 2120000,
    iz: 212,
    iz_global: 2120000,
    t: "3,6",
    t_global: "3,6",
    zx: "49,5",
    zx_global: 49500,
    zy: "49,5",
    zy_global: 49500,
  },
  {
    profile_section_id: 1709,
    name: "TUB120803.6",
    type: "Tube",
    designation: "TUB120803.6",
    shape: "TUBE",
    country_code: "British",
    ax: "13,7",
    ax_global: 1370,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    ix: 301,
    ix_global: 3010000,
    iy: 147,
    iy_global: 1470000,
    iz: 276,
    iz_global: 2760000,
    t: "3,6",
    t_global: "3,6",
    zx: "55,6",
    zx_global: 55600,
    zy: 42,
    zy_global: 42000,
  },
  {
    profile_section_id: 1710,
    name: "TUB76516.3",
    type: "Tube",
    designation: "TUB76516.3",
    shape: "TUBE",
    country_code: "British",
    ax: 14,
    ax_global: 1400,
    b: 51,
    b_global: 51,
    d: 76,
    d_global: 76,
    ix: 111,
    ix_global: 1110000,
    iy: "50,6",
    iy_global: 506000,
    iz: "98,6",
    iz_global: 986000,
    t: "6,3",
    t_global: "6,3",
    zx: "33,4",
    zx_global: 33400,
    zy: "24,8",
    zy_global: 24800,
  },
  {
    profile_section_id: 1711,
    name: "TUB80805.0",
    type: "Tube",
    designation: "TUB80805.0",
    shape: "TUBE",
    country_code: "British",
    ax: "14,7",
    ax_global: 1470,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    ix: 217,
    ix_global: 2170000,
    iy: 137,
    iy_global: 1370000,
    iz: 137,
    iz_global: 1370000,
    t: 5,
    t_global: 5,
    zx: "41,1",
    zx_global: 41100,
    zy: "41,1",
    zy_global: 41100,
  },
  {
    profile_section_id: 1712,
    name: "TUB100605.0",
    type: "Tube",
    designation: "TUB100605.0",
    shape: "TUBE",
    country_code: "British",
    ax: "14,7",
    ax_global: 1470,
    b: 60,
    b_global: 60,
    d: 100,
    d_global: 100,
    ix: 188,
    ix_global: 1880000,
    iy: "83,6",
    iy_global: 836000,
    iz: 189,
    iz_global: 1890000,
    t: 5,
    t_global: 5,
    zx: "47,4",
    zx_global: 47400,
    zy: "32,9",
    zy_global: 32900,
  },
  {
    profile_section_id: 1713,
    name: "TUB70706.0",
    type: "Tube",
    designation: "TUB70706.0",
    shape: "TUBE",
    country_code: "British",
    ax: 15,
    ax_global: 1500,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    ix: 163,
    ix_global: 1630000,
    iy: 101,
    iy_global: 1010000,
    iz: 101,
    iz_global: 1010000,
    t: 6,
    t_global: 6,
    zx: "35,5",
    zx_global: 35500,
    zy: "35,5",
    zy_global: 35500,
  },
  {
    profile_section_id: 1714,
    name: "TUB90506.0",
    type: "Tube",
    designation: "TUB90506.0",
    shape: "TUBE",
    country_code: "British",
    ax: 15,
    ax_global: 1500,
    b: 50,
    b_global: 50,
    d: 90,
    d_global: 90,
    ix: 133,
    ix_global: 1330000,
    iy: "55,4",
    iy_global: 554000,
    iz: 145,
    iz_global: 1450000,
    t: 6,
    t_global: 6,
    zx: "41,6",
    zx_global: 41600,
    zy: 27,
    zy_global: 27000,
  },
  {
    profile_section_id: 1715,
    name: "TUB1001004.0",
    type: "Tube",
    designation: "TUB1001004.0",
    shape: "TUBE",
    country_code: "British",
    ax: "15,2",
    ax_global: 1520,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 361,
    ix_global: 3610000,
    iy: 232,
    iy_global: 2320000,
    iz: 232,
    iz_global: 2320000,
    t: 4,
    t_global: 4,
    zx: "54,4",
    zx_global: 54400,
    zy: "54,4",
    zy_global: 54400,
  },
  {
    profile_section_id: 1716,
    name: "TUB120804.0",
    type: "Tube",
    designation: "TUB120804.0",
    shape: "TUBE",
    country_code: "British",
    ax: "15,2",
    ax_global: 1520,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    ix: 330,
    ix_global: 3300000,
    iy: 161,
    iy_global: 1610000,
    iz: 303,
    iz_global: 3030000,
    t: 4,
    t_global: 4,
    zx: "61,2",
    zx_global: 61200,
    zy: "46,1",
    zy_global: 46100,
  },
  {
    profile_section_id: 1717,
    name: "TUB70706.3",
    type: "Tube",
    designation: "TUB70706.3",
    shape: "TUBE",
    country_code: "British",
    ax: "15,6",
    ax_global: 1560,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    ix: 169,
    ix_global: 1690000,
    iy: 104,
    iy_global: 1040000,
    iz: 104,
    iz_global: 1040000,
    t: "6,3",
    t_global: "6,3",
    zx: "36,9",
    zx_global: 36900,
    zy: "36,9",
    zy_global: 36900,
  },
  {
    profile_section_id: 1718,
    name: "TUB90506.3",
    type: "Tube",
    designation: "TUB90506.3",
    shape: "TUBE",
    country_code: "British",
    ax: "15,6",
    ax_global: 1560,
    b: 50,
    b_global: 50,
    d: 90,
    d_global: 90,
    ix: 138,
    ix_global: 1380000,
    iy: 57,
    iy_global: 570000,
    iz: 150,
    iz_global: 1500000,
    t: "6,3",
    t_global: "6,3",
    zx: "43,2",
    zx_global: 43200,
    zy: 28,
    zy_global: 28000,
  },
  {
    profile_section_id: 1719,
    name: "TUB60608.0",
    type: "Tube",
    designation: "TUB60608.0",
    shape: "TUBE",
    country_code: "British",
    ax: 16,
    ax_global: 1600,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    ix: 118,
    ix_global: 1180000,
    iy: "69,7",
    iy_global: 697000,
    iz: "69,7",
    iz_global: 697000,
    t: 8,
    t_global: 8,
    zx: "30,4",
    zx_global: 30400,
    zy: "30,4",
    zy_global: 30400,
  },
  {
    profile_section_id: 1720,
    name: "TUB80408.0",
    type: "Tube",
    designation: "TUB80408.0",
    shape: "TUBE",
    country_code: "British",
    ax: 16,
    ax_global: 1600,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: "85,8",
    ix_global: 858000,
    iy: "32,1",
    iy_global: 321000,
    iz: 106,
    iz_global: 1060000,
    t: 8,
    t_global: 8,
    zx: "36,5",
    zx_global: 36500,
    zy: "21,2",
    zy_global: 21200,
  },
  {
    profile_section_id: 1721,
    name: "TUB100506.0",
    type: "Tube",
    designation: "TUB100506.0",
    shape: "TUBE",
    country_code: "British",
    ax: "16,2",
    ax_global: 1620,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    ix: 154,
    ix_global: 1540000,
    iy: "61,2",
    iy_global: 612000,
    iz: 190,
    iz_global: 1900000,
    t: 6,
    t_global: 6,
    zx: "49,4",
    zx_global: 49400,
    zy: "29,7",
    zy_global: 29700,
  },
  {
    profile_section_id: 1722,
    name: "TUB90905.0",
    type: "Tube",
    designation: "TUB90905.0",
    shape: "TUBE",
    country_code: "British",
    ax: "16,7",
    ax_global: 1670,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    ix: 316,
    ix_global: 3160000,
    iy: 200,
    iy_global: 2000000,
    iz: 200,
    iz_global: 2000000,
    t: 5,
    t_global: 5,
    zx: 53,
    zx_global: 53000,
    zy: 53,
    zy_global: 53000,
  },
  {
    profile_section_id: 1723,
    name: "TUB120605.0",
    type: "Tube",
    designation: "TUB120605.0",
    shape: "TUBE",
    country_code: "British",
    ax: "16,7",
    ax_global: 1670,
    b: 60,
    b_global: 60,
    d: 120,
    d_global: 120,
    ix: 242,
    ix_global: 2420000,
    iy: "98,8",
    iy_global: 988000,
    iz: 299,
    iz_global: 2990000,
    t: 5,
    t_global: 5,
    zx: "63,1",
    zx_global: 63100,
    zy: "38,4",
    zy_global: 38400,
  },
  {
    profile_section_id: 1724,
    name: "TUB100506.3",
    type: "Tube",
    designation: "TUB100506.3",
    shape: "TUBE",
    country_code: "British",
    ax: "16,9",
    ax_global: 1690,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    ix: 160,
    ix_global: 1600000,
    iy: 63,
    iy_global: 630000,
    iz: 197,
    iz_global: 1970000,
    t: "6,3",
    t_global: "6,3",
    zx: "51,3",
    zx_global: 51300,
    zy: "30,8",
    zy_global: 30800,
  },
  {
    profile_section_id: 1725,
    name: "TUB76518.0",
    type: "Tube",
    designation: "TUB76518.0",
    shape: "TUBE",
    country_code: "British",
    ax: "17,1",
    ax_global: 1710,
    b: 51,
    b_global: 51,
    d: 76,
    d_global: 76,
    ix: 129,
    ix_global: 1290000,
    iy: 57,
    iy_global: 570000,
    iz: 113,
    iz_global: 1130000,
    t: 8,
    t_global: 8,
    zx: "39,4",
    zx_global: 39400,
    zy: 29,
    zy_global: 29000,
  },
  {
    profile_section_id: 1726,
    name: "TUB80806.0",
    type: "Tube",
    designation: "TUB80806.0",
    shape: "TUBE",
    country_code: "British",
    ax: "17,4",
    ax_global: 1740,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    ix: 252,
    ix_global: 2520000,
    iy: 156,
    iy_global: 1560000,
    iz: 156,
    iz_global: 1560000,
    t: 6,
    t_global: 6,
    zx: "47,8",
    zx_global: 47800,
    zy: "47,8",
    zy_global: 47800,
  },
  {
    profile_section_id: 1727,
    name: "TUB100606.0",
    type: "Tube",
    designation: "TUB100606.0",
    shape: "TUBE",
    country_code: "British",
    ax: "17,4",
    ax_global: 1740,
    b: 60,
    b_global: 60,
    d: 100,
    d_global: 100,
    ix: 216,
    ix_global: 2160000,
    iy: 95,
    iy_global: 950000,
    iz: 217,
    iz_global: 2170000,
    t: 6,
    t_global: 6,
    zx: "55,1",
    zx_global: 55100,
    zy: "38,1",
    zy_global: 38100,
  },
  {
    profile_section_id: 1728,
    name: "TUB80806.3",
    type: "Tube",
    designation: "TUB80806.3",
    shape: "TUBE",
    country_code: "British",
    ax: "18,1",
    ax_global: 1810,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    ix: 262,
    ix_global: 2620000,
    iy: 162,
    iy_global: 1620000,
    iz: 162,
    iz_global: 1620000,
    t: "6,3",
    t_global: "6,3",
    zx: "49,7",
    zx_global: 49700,
    zy: "49,7",
    zy_global: 49700,
  },
  {
    profile_section_id: 1729,
    name: "TUB100606.3",
    type: "Tube",
    designation: "TUB100606.3",
    shape: "TUBE",
    country_code: "British",
    ax: "18,1",
    ax_global: 1810,
    b: 60,
    b_global: 60,
    d: 100,
    d_global: 100,
    ix: 224,
    ix_global: 2240000,
    iy: "98,1",
    iy_global: 981000,
    iz: 225,
    iz_global: 2250000,
    t: "6,3",
    t_global: "6,3",
    zx: "57,3",
    zx_global: 57300,
    zy: "39,5",
    zy_global: 39500,
  },
  {
    profile_section_id: 1730,
    name: "TUB1201204.0",
    type: "Tube",
    designation: "TUB1201204.0",
    shape: "TUBE",
    country_code: "British",
    ax: "18,4",
    ax_global: 1840,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    ix: 635,
    ix_global: 6350000,
    iy: 410,
    iy_global: 4100000,
    iz: 410,
    iz_global: 4100000,
    t: 4,
    t_global: 4,
    zx: "79,7",
    zx_global: 79700,
    zy: "79,7",
    zy_global: 79700,
  },
  {
    profile_section_id: 1731,
    name: "TUB160804.0",
    type: "Tube",
    designation: "TUB160804.0",
    shape: "TUBE",
    country_code: "British",
    ax: "18,4",
    ax_global: 1840,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    ix: 493,
    ix_global: 4930000,
    iy: 207,
    iy_global: 2070000,
    iz: 612,
    iz_global: 6120000,
    t: 4,
    t_global: 4,
    zx: "94,7",
    zx_global: 94700,
    zy: "58,3",
    zy_global: 58300,
  },
  {
    profile_section_id: 1732,
    name: "TUB1001005.0",
    type: "Tube",
    designation: "TUB1001005.0",
    shape: "TUBE",
    country_code: "British",
    ax: "18,7",
    ax_global: 1870,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 439,
    ix_global: 4390000,
    iy: 279,
    iy_global: 2790000,
    iz: 279,
    iz_global: 2790000,
    t: 5,
    t_global: 5,
    zx: "66,4",
    zx_global: 66400,
    zy: "66,4",
    zy_global: 66400,
  },
  {
    profile_section_id: 1733,
    name: "TUB120805.0",
    type: "Tube",
    designation: "TUB120805.0",
    shape: "TUBE",
    country_code: "British",
    ax: "18,7",
    ax_global: 1870,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    ix: 401,
    ix_global: 4010000,
    iy: 193,
    iy_global: 1930000,
    iz: 365,
    iz_global: 3650000,
    t: 5,
    t_global: 5,
    zx: "74,6",
    zx_global: 74600,
    zy: "56,1",
    zy_global: 56100,
  },
  {
    profile_section_id: 1734,
    name: "TUB70708.0",
    type: "Tube",
    designation: "TUB70708.0",
    shape: "TUBE",
    country_code: "British",
    ax: "19,2",
    ax_global: 1920,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    ix: 200,
    ix_global: 2000000,
    iy: 120,
    iy_global: 1200000,
    iz: 120,
    iz_global: 1200000,
    t: 8,
    t_global: 8,
    zx: "43,8",
    zx_global: 43800,
    zy: "43,8",
    zy_global: 43800,
  },
  {
    profile_section_id: 1735,
    name: "TUB90508.0",
    type: "Tube",
    designation: "TUB90508.0",
    shape: "TUBE",
    country_code: "British",
    ax: "19,2",
    ax_global: 1920,
    b: 50,
    b_global: 50,
    d: 90,
    d_global: 90,
    ix: 160,
    ix_global: 1600000,
    iy: "64,6",
    iy_global: 646000,
    iz: 174,
    iz_global: 1740000,
    t: 8,
    t_global: 8,
    zx: "51,4",
    zx_global: 51400,
    zy: "32,9",
    zy_global: 32900,
  },
  {
    profile_section_id: 1736,
    name: "TUB1501004.0",
    type: "Tube",
    designation: "TUB1501004.0",
    shape: "TUBE",
    country_code: "British",
    ax: "19,2",
    ax_global: 1920,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    ix: 660,
    ix_global: 6600000,
    iy: 324,
    iy_global: 3240000,
    iz: 607,
    iz_global: 6070000,
    t: 4,
    t_global: 4,
    zx: "97,4",
    zx_global: 97400,
    zy: "73,6",
    zy_global: 73600,
  },
  {
    profile_section_id: 1737,
    name: "TUB90906.0",
    type: "Tube",
    designation: "TUB90906.0",
    shape: "TUBE",
    country_code: "British",
    ax: "19,8",
    ax_global: 1980,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    ix: 367,
    ix_global: 3670000,
    iy: 230,
    iy_global: 2300000,
    iz: 230,
    iz_global: 2300000,
    t: 6,
    t_global: 6,
    zx: "61,8",
    zx_global: 61800,
    zy: "61,8",
    zy_global: 61800,
  },
  {
    profile_section_id: 1738,
    name: "TUB120606.0",
    type: "Tube",
    designation: "TUB120606.0",
    shape: "TUBE",
    country_code: "British",
    ax: "19,8",
    ax_global: 1980,
    b: 60,
    b_global: 60,
    d: 120,
    d_global: 120,
    ix: 279,
    ix_global: 2790000,
    iy: 113,
    iy_global: 1130000,
    iz: 345,
    iz_global: 3450000,
    t: 6,
    t_global: 6,
    zx: "73,6",
    zx_global: 73600,
    zy: "44,5",
    zy_global: 44500,
  },
  {
    profile_section_id: 1739,
    name: "TUB90906.3",
    type: "Tube",
    designation: "TUB90906.3",
    shape: "TUBE",
    country_code: "British",
    ax: "20,7",
    ax_global: 2070,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    ix: 382,
    ix_global: 3820000,
    iy: 238,
    iy_global: 2380000,
    iz: 238,
    iz_global: 2380000,
    t: "6,3",
    t_global: "6,3",
    zx: "64,3",
    zx_global: 64300,
    zy: "64,3",
    zy_global: 64300,
  },
  {
    profile_section_id: 1740,
    name: "TUB120606.3",
    type: "Tube",
    designation: "TUB120606.3",
    shape: "TUBE",
    country_code: "British",
    ax: "20,7",
    ax_global: 2070,
    b: 60,
    b_global: 60,
    d: 120,
    d_global: 120,
    ix: 290,
    ix_global: 2900000,
    iy: 116,
    iy_global: 1160000,
    iz: 358,
    iz_global: 3580000,
    t: "6,3",
    t_global: "6,3",
    zx: "76,7",
    zx_global: 76700,
    zy: "46,3",
    zy_global: 46300,
  },
  {
    profile_section_id: 1741,
    name: "TUB100508.0",
    type: "Tube",
    designation: "TUB100508.0",
    shape: "TUBE",
    country_code: "British",
    ax: "20,8",
    ax_global: 2080,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    ix: 186,
    ix_global: 1860000,
    iy: "71,7",
    iy_global: 717000,
    iz: 230,
    iz_global: 2300000,
    t: 8,
    t_global: 8,
    zx: "61,4",
    zx_global: 61400,
    zy: "36,3",
    zy_global: 36300,
  },
  {
    profile_section_id: 1742,
    name: "TUB1001006.0",
    type: "Tube",
    designation: "TUB1001006.0",
    shape: "TUBE",
    country_code: "British",
    ax: "22,2",
    ax_global: 2220,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 513,
    ix_global: 5130000,
    iy: 323,
    iy_global: 3230000,
    iz: 323,
    iz_global: 3230000,
    t: 6,
    t_global: 6,
    zx: "77,6",
    zx_global: 77600,
    zy: "77,6",
    zy_global: 77600,
  },
  {
    profile_section_id: 1743,
    name: "TUB120806.0",
    type: "Tube",
    designation: "TUB120806.0",
    shape: "TUBE",
    country_code: "British",
    ax: "22,2",
    ax_global: 2220,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    ix: 468,
    ix_global: 4680000,
    iy: 222,
    iy_global: 2220000,
    iz: 423,
    iz_global: 4230000,
    t: 6,
    t_global: 6,
    zx: "87,3",
    zx_global: 87300,
    zy: "65,5",
    zy_global: 65500,
  },
  {
    profile_section_id: 1744,
    name: "TUB80808.0",
    type: "Tube",
    designation: "TUB80808.0",
    shape: "TUBE",
    country_code: "British",
    ax: "22,4",
    ax_global: 2240,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    ix: 312,
    ix_global: 3120000,
    iy: 189,
    iy_global: 1890000,
    iz: 189,
    iz_global: 1890000,
    t: 8,
    t_global: 8,
    zx: "59,5",
    zx_global: 59500,
    zy: "59,5",
    zy_global: 59500,
  },
  {
    profile_section_id: 1745,
    name: "TUB100608.0",
    type: "Tube",
    designation: "TUB100608.0",
    shape: "TUBE",
    country_code: "British",
    ax: "22,4",
    ax_global: 2240,
    b: 60,
    b_global: 60,
    d: 100,
    d_global: 100,
    ix: 265,
    ix_global: 2650000,
    iy: 113,
    iy_global: 1130000,
    iz: 264,
    iz_global: 2640000,
    t: 8,
    t_global: 8,
    zx: "68,7",
    zx_global: 68700,
    zy: "47,1",
    zy_global: 47100,
  },
  {
    profile_section_id: 1746,
    name: "TUB1201205.0",
    type: "Tube",
    designation: "TUB1201205.0",
    shape: "TUBE",
    country_code: "British",
    ax: "22,7",
    ax_global: 2270,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    ix: 777,
    ix_global: 7770000,
    iy: 498,
    iy_global: 4980000,
    iz: 498,
    iz_global: 4980000,
    t: 5,
    t_global: 5,
    zx: "97,6",
    zx_global: 97600,
    zy: "97,6",
    zy_global: 97600,
  },
  {
    profile_section_id: 1747,
    name: "TUB160805.0",
    type: "Tube",
    designation: "TUB160805.0",
    shape: "TUBE",
    country_code: "British",
    ax: "22,7",
    ax_global: 2270,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    ix: 600,
    ix_global: 6000000,
    iy: 249,
    iy_global: 2490000,
    iz: 744,
    iz_global: 7440000,
    t: 5,
    t_global: 5,
    zx: 116,
    zx_global: 116000,
    zy: "71,1",
    zy_global: 71100,
  },
  {
    profile_section_id: 1748,
    name: "TUB1001006.3",
    type: "Tube",
    designation: "TUB1001006.3",
    shape: "TUBE",
    country_code: "British",
    ax: "23,2",
    ax_global: 2320,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 534,
    ix_global: 5340000,
    iy: 336,
    iy_global: 3360000,
    iz: 336,
    iz_global: 3360000,
    t: "6,3",
    t_global: "6,3",
    zx: "80,9",
    zx_global: 80900,
    zy: "80,9",
    zy_global: 80900,
  },
  {
    profile_section_id: 1749,
    name: "TUB120806.3",
    type: "Tube",
    designation: "TUB120806.3",
    shape: "TUBE",
    country_code: "British",
    ax: "23,2",
    ax_global: 2320,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    ix: 487,
    ix_global: 4870000,
    iy: 230,
    iy_global: 2300000,
    iz: 440,
    iz_global: 4400000,
    t: "6,3",
    t_global: "6,3",
    zx: 91,
    zx_global: 91000,
    zy: "68,2",
    zy_global: 68200,
  },
  {
    profile_section_id: 1750,
    name: "TUB1501005.0",
    type: "Tube",
    designation: "TUB1501005.0",
    shape: "TUBE",
    country_code: "British",
    ax: "23,7",
    ax_global: 2370,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    ix: 807,
    ix_global: 8070000,
    iy: 392,
    iy_global: 3920000,
    iz: 739,
    iz_global: 7390000,
    t: 5,
    t_global: 5,
    zx: 119,
    zx_global: 119000,
    zy: "90,1",
    zy_global: 90100,
  },
  {
    profile_section_id: 1751,
    name: "TUB90908.0",
    type: "Tube",
    designation: "TUB90908.0",
    shape: "TUBE",
    country_code: "British",
    ax: "25,6",
    ax_global: 2560,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    ix: 459,
    ix_global: 4590000,
    iy: 281,
    iy_global: 2810000,
    iz: 281,
    iz_global: 2810000,
    t: 8,
    t_global: 8,
    zx: "77,6",
    zx_global: 77600,
    zy: "77,6",
    zy_global: 77600,
  },
  {
    profile_section_id: 1752,
    name: "TUB120608.0",
    type: "Tube",
    designation: "TUB120608.0",
    shape: "TUBE",
    country_code: "British",
    ax: "25,6",
    ax_global: 2560,
    b: 60,
    b_global: 60,
    d: 120,
    d_global: 120,
    ix: 344,
    ix_global: 3440000,
    iy: 135,
    iy_global: 1350000,
    iz: 425,
    iz_global: 4250000,
    t: 8,
    t_global: 8,
    zx: "92,7",
    zx_global: 92700,
    zy: "55,4",
    zy_global: 55400,
  },
  {
    profile_section_id: 1753,
    name: "TUB1401405.0",
    type: "Tube",
    designation: "TUB1401405.0",
    shape: "TUBE",
    country_code: "British",
    ax: "26,7",
    ax_global: 2670,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    ix: 1253,
    ix_global: 12530000,
    iy: 807,
    iy_global: 8070000,
    iz: 807,
    iz_global: 8070000,
    t: 5,
    t_global: 5,
    zx: 135,
    zx_global: 135000,
    zy: 135,
    zy_global: 135000,
  },
  {
    profile_section_id: 1754,
    name: "TUB1201206.0",
    type: "Tube",
    designation: "TUB1201206.0",
    shape: "TUBE",
    country_code: "British",
    ax: 27,
    ax_global: 2700,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    ix: 911,
    ix_global: 9110000,
    iy: 579,
    iy_global: 5790000,
    iz: 579,
    iz_global: 5790000,
    t: 6,
    t_global: 6,
    zx: 115,
    zx_global: 115000,
    zy: 115,
    zy_global: 115000,
  },
  {
    profile_section_id: 1755,
    name: "TUB160806.0",
    type: "Tube",
    designation: "TUB160806.0",
    shape: "TUBE",
    country_code: "British",
    ax: 27,
    ax_global: 2700,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    ix: 701,
    ix_global: 7010000,
    iy: 288,
    iy_global: 2880000,
    iz: 868,
    iz_global: 8680000,
    t: 6,
    t_global: 6,
    zx: 136,
    zx_global: 136000,
    zy: "83,3",
    zy_global: 83300,
  },
  {
    profile_section_id: 1756,
    name: "TUB1201206.3",
    type: "Tube",
    designation: "TUB1201206.3",
    shape: "TUBE",
    country_code: "British",
    ax: "28,2",
    ax_global: 2820,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    ix: 950,
    ix_global: 9500000,
    iy: 603,
    iy_global: 6030000,
    iz: 603,
    iz_global: 6030000,
    t: "6,3",
    t_global: "6,3",
    zx: 120,
    zx_global: 120000,
    zy: 120,
    zy_global: 120000,
  },
  {
    profile_section_id: 1757,
    name: "TUB1501006.0",
    type: "Tube",
    designation: "TUB1501006.0",
    shape: "TUBE",
    country_code: "British",
    ax: "28,2",
    ax_global: 2820,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    ix: 946,
    ix_global: 9460000,
    iy: 456,
    iy_global: 4560000,
    iz: 862,
    iz_global: 8620000,
    t: 6,
    t_global: 6,
    zx: 141,
    zx_global: 141000,
    zy: 106,
    zy_global: 106000,
  },
  {
    profile_section_id: 1758,
    name: "TUB160806.3",
    type: "Tube",
    designation: "TUB160806.3",
    shape: "TUBE",
    country_code: "British",
    ax: "28,2",
    ax_global: 2820,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    ix: 730,
    ix_global: 7300000,
    iy: 299,
    iy_global: 2990000,
    iz: 903,
    iz_global: 9030000,
    t: "6,3",
    t_global: "6,3",
    zx: 142,
    zx_global: 142000,
    zy: "86,8",
    zy_global: 86800,
  },
  {
    profile_section_id: 1759,
    name: "TUB1501505.0",
    type: "Tube",
    designation: "TUB1501505.0",
    shape: "TUBE",
    country_code: "British",
    ax: "28,7",
    ax_global: 2870,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    ix: 1550,
    ix_global: 15500000,
    iy: 1002,
    iy_global: 10020000,
    iz: 1002,
    iz_global: 10020000,
    t: 5,
    t_global: 5,
    zx: 156,
    zx_global: 156000,
    zy: 156,
    zy_global: 156000,
  },
  {
    profile_section_id: 1760,
    name: "TUB2001005.0",
    type: "Tube",
    designation: "TUB2001005.0",
    shape: "TUBE",
    country_code: "British",
    ax: "28,7",
    ax_global: 2870,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    ix: 1204,
    ix_global: 12040000,
    iy: 505,
    iy_global: 5050000,
    iz: 1495,
    iz_global: 14950000,
    t: 5,
    t_global: 5,
    zx: 185,
    zx_global: 185000,
    zy: 114,
    zy_global: 114000,
  },
  {
    profile_section_id: 1761,
    name: "TUB1001008.0",
    type: "Tube",
    designation: "TUB1001008.0",
    shape: "TUBE",
    country_code: "British",
    ax: "28,8",
    ax_global: 2880,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 646,
    ix_global: 6460000,
    iy: 400,
    iy_global: 4000000,
    iz: 400,
    iz_global: 4000000,
    t: 8,
    t_global: 8,
    zx: "98,2",
    zx_global: 98200,
    zy: "98,2",
    zy_global: 98200,
  },
  {
    profile_section_id: 1762,
    name: "TUB120808.0",
    type: "Tube",
    designation: "TUB120808.0",
    shape: "TUBE",
    country_code: "British",
    ax: "28,8",
    ax_global: 2880,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    ix: 587,
    ix_global: 5870000,
    iy: 273,
    iy_global: 2730000,
    iz: 525,
    iz_global: 5250000,
    t: 8,
    t_global: 8,
    zx: 111,
    zx_global: 111000,
    zy: "82,6",
    zy_global: 82600,
  },
  {
    profile_section_id: 1763,
    name: "TUB1501006.3",
    type: "Tube",
    designation: "TUB1501006.3",
    shape: "TUBE",
    country_code: "British",
    ax: "29,5",
    ax_global: 2950,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    ix: 986,
    ix_global: 9860000,
    iy: 474,
    iy_global: 4740000,
    iz: 898,
    iz_global: 8980000,
    t: "6,3",
    t_global: "6,3",
    zx: 147,
    zx_global: 147000,
    zy: 110,
    zy_global: 110000,
  },
  {
    profile_section_id: 1764,
    name: "TUB1601605.0",
    type: "Tube",
    designation: "TUB1601605.0",
    shape: "TUBE",
    country_code: "British",
    ax: "30,7",
    ax_global: 3070,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    ix: 1892,
    ix_global: 18920000,
    iy: 1225,
    iy_global: 12250000,
    iz: 1225,
    iz_global: 12250000,
    t: 5,
    t_global: 5,
    zx: 178,
    zx_global: 178000,
    zy: 178,
    zy_global: 178000,
  },
  {
    profile_section_id: 1765,
    name: "TUB1401406.0",
    type: "Tube",
    designation: "TUB1401406.0",
    shape: "TUBE",
    country_code: "British",
    ax: "31,8",
    ax_global: 3180,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    ix: 1475,
    ix_global: 14750000,
    iy: 944,
    iy_global: 9440000,
    iz: 944,
    iz_global: 9440000,
    t: 6,
    t_global: 6,
    zx: 159,
    zx_global: 159000,
    zy: 159,
    zy_global: 159000,
  },
  {
    profile_section_id: 1766,
    name: "TUB1401406.3",
    type: "Tube",
    designation: "TUB1401406.3",
    shape: "TUBE",
    country_code: "British",
    ax: "33,3",
    ax_global: 3330,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    ix: 1540,
    ix_global: 15400000,
    iy: 984,
    iy_global: 9840000,
    iz: 984,
    iz_global: 9840000,
    t: "6,3",
    t_global: "6,3",
    zx: 166,
    zx_global: 166000,
    zy: 166,
    zy_global: 166000,
  },
  {
    profile_section_id: 1767,
    name: "TUB1501506.0",
    type: "Tube",
    designation: "TUB1501506.0",
    shape: "TUBE",
    country_code: "British",
    ax: "34,2",
    ax_global: 3420,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    ix: 1828,
    ix_global: 18280000,
    iy: 1174,
    iy_global: 11740000,
    iz: 1174,
    iz_global: 11740000,
    t: 6,
    t_global: 6,
    zx: 184,
    zx_global: 184000,
    zy: 184,
    zy_global: 184000,
  },
  {
    profile_section_id: 1768,
    name: "TUB2001006.0",
    type: "Tube",
    designation: "TUB2001006.0",
    shape: "TUBE",
    country_code: "British",
    ax: "34,2",
    ax_global: 3420,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    ix: 1414,
    ix_global: 14140000,
    iy: 589,
    iy_global: 5890000,
    iz: 1754,
    iz_global: 17540000,
    t: 6,
    t_global: 6,
    zx: 218,
    zx_global: 218000,
    zy: 134,
    zy_global: 134000,
  },
  {
    profile_section_id: 1769,
    name: "TUB1801805.0",
    type: "Tube",
    designation: "TUB1801805.0",
    shape: "TUBE",
    country_code: "British",
    ax: "34,7",
    ax_global: 3470,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    ix: 2718,
    ix_global: 27180000,
    iy: 1765,
    iy_global: 17650000,
    iz: 1765,
    iz_global: 17650000,
    t: 5,
    t_global: 5,
    zx: 227,
    zx_global: 227000,
    zy: 227,
    zy_global: 227000,
  },
  {
    profile_section_id: 1770,
    name: "TUB10010010.0",
    type: "Tube",
    designation: "TUB10010010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "34,9",
    ax_global: 3490,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 761,
    ix_global: 7610000,
    iy: 462,
    iy_global: 4620000,
    iz: 462,
    iz_global: 4620000,
    t: 10,
    t_global: 10,
    zx: 116,
    zx_global: 116000,
    zy: 116,
    zy_global: 116000,
  },
  {
    profile_section_id: 1771,
    name: "TUB1208010.0",
    type: "Tube",
    designation: "TUB1208010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "34,9",
    ax_global: 3490,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    ix: 688,
    ix_global: 6880000,
    iy: 313,
    iy_global: 3130000,
    iz: 609,
    iz_global: 6090000,
    t: 10,
    t_global: 10,
    zx: 131,
    zx_global: 131000,
    zy: "97,3",
    zy_global: 97300,
  },
  {
    profile_section_id: 1772,
    name: "TUB1201208.0",
    type: "Tube",
    designation: "TUB1201208.0",
    shape: "TUBE",
    country_code: "British",
    ax: "35,2",
    ax_global: 3520,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    ix: 1160,
    ix_global: 11600000,
    iy: 726,
    iy_global: 7260000,
    iz: 726,
    iz_global: 7260000,
    t: 8,
    t_global: 8,
    zx: 146,
    zx_global: 146000,
    zy: 146,
    zy_global: 146000,
  },
  {
    profile_section_id: 1773,
    name: "TUB160808.0",
    type: "Tube",
    designation: "TUB160808.0",
    shape: "TUBE",
    country_code: "British",
    ax: "35,2",
    ax_global: 3520,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    ix: 883,
    ix_global: 8830000,
    iy: 356,
    iy_global: 3560000,
    iz: 1091,
    iz_global: 10910000,
    t: 8,
    t_global: 8,
    zx: 175,
    zx_global: 175000,
    zy: 106,
    zy_global: 106000,
  },
  {
    profile_section_id: 1774,
    name: "TUB1501506.3",
    type: "Tube",
    designation: "TUB1501506.3",
    shape: "TUBE",
    country_code: "British",
    ax: "35,8",
    ax_global: 3580,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    ix: 1909,
    ix_global: 19090000,
    iy: 1223,
    iy_global: 12230000,
    iz: 1223,
    iz_global: 12230000,
    t: "6,3",
    t_global: "6,3",
    zx: 192,
    zx_global: 192000,
    zy: 192,
    zy_global: 192000,
  },
  {
    profile_section_id: 1775,
    name: "TUB2001006.3",
    type: "Tube",
    designation: "TUB2001006.3",
    shape: "TUBE",
    country_code: "British",
    ax: "35,8",
    ax_global: 3580,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    ix: 1475,
    ix_global: 14750000,
    iy: 613,
    iy_global: 6130000,
    iz: 1829,
    iz_global: 18290000,
    t: "6,3",
    t_global: "6,3",
    zx: 228,
    zx_global: 228000,
    zy: 140,
    zy_global: 140000,
  },
  {
    profile_section_id: 1776,
    name: "TUB1601606.0",
    type: "Tube",
    designation: "TUB1601606.0",
    shape: "TUBE",
    country_code: "British",
    ax: "36,6",
    ax_global: 3660,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    ix: 2233,
    ix_global: 22330000,
    iy: 1437,
    iy_global: 14370000,
    iz: 1437,
    iz_global: 14370000,
    t: 6,
    t_global: 6,
    zx: 210,
    zx_global: 210000,
    zy: 210,
    zy_global: 210000,
  },
  {
    profile_section_id: 1777,
    name: "TUB1501008.0",
    type: "Tube",
    designation: "TUB1501008.0",
    shape: "TUBE",
    country_code: "British",
    ax: "36,8",
    ax_global: 3680,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    ix: 1203,
    ix_global: 12030000,
    iy: 569,
    iy_global: 5690000,
    iz: 1087,
    iz_global: 10870000,
    t: 8,
    t_global: 8,
    zx: 180,
    zx_global: 180000,
    zy: 135,
    zy_global: 135000,
  },
  {
    profile_section_id: 1778,
    name: "TUB1601606.3",
    type: "Tube",
    designation: "TUB1601606.3",
    shape: "TUBE",
    country_code: "British",
    ax: "38,3",
    ax_global: 3830,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    ix: 2333,
    ix_global: 23330000,
    iy: 1499,
    iy_global: 14990000,
    iz: 1499,
    iz_global: 14990000,
    t: "6,3",
    t_global: "6,3",
    zx: 220,
    zx_global: 220000,
    zy: 220,
    zy_global: 220000,
  },
  {
    profile_section_id: 1779,
    name: "TUB2002005.0",
    type: "Tube",
    designation: "TUB2002005.0",
    shape: "TUBE",
    country_code: "British",
    ax: "38,7",
    ax_global: 3870,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 3756,
    ix_global: 37560000,
    iy: 2445,
    iy_global: 24450000,
    iz: 2445,
    iz_global: 24450000,
    t: 5,
    t_global: 5,
    zx: 283,
    zx_global: 283000,
    zy: 283,
    zy_global: 283000,
  },
  {
    profile_section_id: 1780,
    name: "TUB2501505.0",
    type: "Tube",
    designation: "TUB2501505.0",
    shape: "TUBE",
    country_code: "British",
    ax: "38,7",
    ax_global: 3870,
    b: 150,
    b_global: 150,
    d: 250,
    d_global: 250,
    ix: 3278,
    ix_global: 32780000,
    iy: 1527,
    iy_global: 15270000,
    iz: 3360,
    iz_global: 33600000,
    t: 5,
    t_global: 5,
    zx: 324,
    zx_global: 324000,
    zy: 228,
    zy_global: 228000,
  },
  {
    profile_section_id: 1781,
    name: "TUB1801806.0",
    type: "Tube",
    designation: "TUB1801806.0",
    shape: "TUBE",
    country_code: "British",
    ax: "41,4",
    ax_global: 4140,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    ix: 3215,
    ix_global: 32150000,
    iy: 2077,
    iy_global: 20770000,
    iz: 2077,
    iz_global: 20770000,
    t: 6,
    t_global: 6,
    zx: 269,
    zx_global: 269000,
    zy: 269,
    zy_global: 269000,
  },
  {
    profile_section_id: 1782,
    name: "TUB1401408.0",
    type: "Tube",
    designation: "TUB1401408.0",
    shape: "TUBE",
    country_code: "British",
    ax: "41,6",
    ax_global: 4160,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    ix: 1892,
    ix_global: 18920000,
    iy: 1195,
    iy_global: 11950000,
    iz: 1195,
    iz_global: 11950000,
    t: 8,
    t_global: 8,
    zx: 204,
    zx_global: 204000,
    zy: 204,
    zy_global: 204000,
  },
  {
    profile_section_id: 1783,
    name: "TUB12012010.0",
    type: "Tube",
    designation: "TUB12012010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "42,9",
    ax_global: 4290,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    ix: 1382,
    ix_global: 13820000,
    iy: 852,
    iy_global: 8520000,
    iz: 852,
    iz_global: 8520000,
    t: 10,
    t_global: 10,
    zx: 175,
    zx_global: 175000,
    zy: 175,
    zy_global: 175000,
  },
  {
    profile_section_id: 1784,
    name: "TUB1608010.0",
    type: "Tube",
    designation: "TUB1608010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "42,9",
    ax_global: 4290,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    ix: 1041,
    ix_global: 10410000,
    iy: 411,
    iy_global: 4110000,
    iz: 1284,
    iz_global: 12840000,
    t: 10,
    t_global: 10,
    zx: 209,
    zx_global: 209000,
    zy: 125,
    zy_global: 125000,
  },
  {
    profile_section_id: 1785,
    name: "TUB1801806.3",
    type: "Tube",
    designation: "TUB1801806.3",
    shape: "TUBE",
    country_code: "British",
    ax: "43,3",
    ax_global: 4330,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    ix: 3361,
    ix_global: 33610000,
    iy: 2168,
    iy_global: 21680000,
    iz: 2168,
    iz_global: 21680000,
    t: "6,3",
    t_global: "6,3",
    zx: 281,
    zx_global: 281000,
    zy: 281,
    zy_global: 281000,
  },
  {
    profile_section_id: 1786,
    name: "TUB1501508.0",
    type: "Tube",
    designation: "TUB1501508.0",
    shape: "TUBE",
    country_code: "British",
    ax: "44,8",
    ax_global: 4480,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    ix: 2351,
    ix_global: 23510000,
    iy: 1491,
    iy_global: 14910000,
    iz: 1491,
    iz_global: 14910000,
    t: 8,
    t_global: 8,
    zx: 237,
    zx_global: 237000,
    zy: 237,
    zy_global: 237000,
  },
  {
    profile_section_id: 1787,
    name: "TUB2001008.0",
    type: "Tube",
    designation: "TUB2001008.0",
    shape: "TUBE",
    country_code: "British",
    ax: "44,8",
    ax_global: 4480,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    ix: 1804,
    ix_global: 18040000,
    iy: 739,
    iy_global: 7390000,
    iz: 2234,
    iz_global: 22340000,
    t: 8,
    t_global: 8,
    zx: 282,
    zx_global: 282000,
    zy: 172,
    zy_global: 172000,
  },
  {
    profile_section_id: 1788,
    name: "TUB15010010.0",
    type: "Tube",
    designation: "TUB15010010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "44,9",
    ax_global: 4490,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    ix: 1432,
    ix_global: 14320000,
    iy: 665,
    iy_global: 6650000,
    iz: 1282,
    iz_global: 12820000,
    t: 10,
    t_global: 10,
    zx: 216,
    zx_global: 216000,
    zy: 161,
    zy_global: 161000,
  },
  {
    profile_section_id: 1789,
    name: "TUB2002006.0",
    type: "Tube",
    designation: "TUB2002006.0",
    shape: "TUBE",
    country_code: "British",
    ax: "46,2",
    ax_global: 4620,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 4449,
    ix_global: 44490000,
    iy: 2883,
    iy_global: 28830000,
    iz: 2883,
    iz_global: 28830000,
    t: 6,
    t_global: 6,
    zx: 335,
    zx_global: 335000,
    zy: 335,
    zy_global: 335000,
  },
  {
    profile_section_id: 1790,
    name: "TUB2501506.0",
    type: "Tube",
    designation: "TUB2501506.0",
    shape: "TUBE",
    country_code: "British",
    ax: "46,2",
    ax_global: 4620,
    b: 150,
    b_global: 150,
    d: 250,
    d_global: 250,
    ix: 3877,
    ix_global: 38770000,
    iy: 1796,
    iy_global: 17960000,
    iz: 3965,
    iz_global: 39650000,
    t: 6,
    t_global: 6,
    zx: 385,
    zx_global: 385000,
    zy: 270,
    zy_global: 270000,
  },
  {
    profile_section_id: 1791,
    name: "TUB1601608.0",
    type: "Tube",
    designation: "TUB1601608.0",
    shape: "TUBE",
    country_code: "British",
    ax: 48,
    ax_global: 4800,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    ix: 2880,
    ix_global: 28800000,
    iy: 1831,
    iy_global: 18310000,
    iz: 1831,
    iz_global: 18310000,
    t: 8,
    t_global: 8,
    zx: 272,
    zx_global: 272000,
    zy: 272,
    zy_global: 272000,
  },
  {
    profile_section_id: 1792,
    name: "TUB2002006.3",
    type: "Tube",
    designation: "TUB2002006.3",
    shape: "TUBE",
    country_code: "British",
    ax: "48,4",
    ax_global: 4840,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 4653,
    ix_global: 46530000,
    iy: 3011,
    iy_global: 30110000,
    iz: 3011,
    iz_global: 30110000,
    t: "6,3",
    t_global: "6,3",
    zx: 350,
    zx_global: 350000,
    zy: 350,
    zy_global: 350000,
  },
  {
    profile_section_id: 1793,
    name: "TUB2501506.3",
    type: "Tube",
    designation: "TUB2501506.3",
    shape: "TUBE",
    country_code: "British",
    ax: "48,4",
    ax_global: 4840,
    b: 150,
    b_global: 150,
    d: 250,
    d_global: 250,
    ix: 4054,
    ix_global: 40540000,
    iy: 1874,
    iy_global: 18740000,
    iz: 4143,
    iz_global: 41430000,
    t: "6,3",
    t_global: "6,3",
    zx: 402,
    zx_global: 402000,
    zy: 283,
    zy_global: 283000,
  },
  {
    profile_section_id: 1794,
    name: "TUB2502505.0",
    type: "Tube",
    designation: "TUB2502505.0",
    shape: "TUBE",
    country_code: "British",
    ax: "48,7",
    ax_global: 4870,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 7430,
    ix_global: 74300000,
    iy: 4861,
    iy_global: 48610000,
    iz: 4861,
    iz_global: 48610000,
    t: 5,
    t_global: 5,
    zx: 447,
    zx_global: 447000,
    zy: 447,
    zy_global: 447000,
  },
  {
    profile_section_id: 1795,
    name: "TUB3002005.0",
    type: "Tube",
    designation: "TUB3002005.0",
    shape: "TUBE",
    country_code: "British",
    ax: "48,7",
    ax_global: 4870,
    b: 200,
    b_global: 200,
    d: 300,
    d_global: 300,
    ix: 6824,
    ix_global: 68240000,
    iy: 3396,
    iy_global: 33960000,
    iz: 6322,
    iz_global: 63220000,
    t: 5,
    t_global: 5,
    zx: 501,
    zx_global: 501000,
    zy: 380,
    zy_global: 380000,
  },
  {
    profile_section_id: 1796,
    name: "TUB12012012.0",
    type: "Tube",
    designation: "TUB12012012.0",
    shape: "TUBE",
    country_code: "British",
    ax: "50,3",
    ax_global: 5030,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    ix: 1578,
    ix_global: 15780000,
    iy: 958,
    iy_global: 9580000,
    iz: 958,
    iz_global: 9580000,
    t: 12,
    t_global: 12,
    zx: 201,
    zx_global: 201000,
    zy: 201,
    zy_global: 201000,
  },
  {
    profile_section_id: 1797,
    name: "TUB1608012.0",
    type: "Tube",
    designation: "TUB1608012.0",
    shape: "TUBE",
    country_code: "British",
    ax: "50,3",
    ax_global: 5030,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    ix: 1175,
    ix_global: 11750000,
    iy: 455,
    iy_global: 4550000,
    iz: 1449,
    iz_global: 14490000,
    t: 12,
    t_global: 12,
    zx: 240,
    zx_global: 240000,
    zy: 142,
    zy_global: 142000,
  },
  {
    profile_section_id: 1798,
    name: "TUB14014010.0",
    type: "Tube",
    designation: "TUB14014010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "50,9",
    ax_global: 5090,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    ix: 2272,
    ix_global: 22720000,
    iy: 1416,
    iy_global: 14160000,
    iz: 1416,
    iz_global: 14160000,
    t: 10,
    t_global: 10,
    zx: 246,
    zx_global: 246000,
    zy: 246,
    zy_global: 246000,
  },
  {
    profile_section_id: 1799,
    name: "TUB12012012.5",
    type: "Tube",
    designation: "TUB12012012.5",
    shape: "TUBE",
    country_code: "British",
    ax: "52,1",
    ax_global: 5210,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    ix: 1623,
    ix_global: 16230000,
    iy: 982,
    iy_global: 9820000,
    iz: 982,
    iz_global: 9820000,
    t: "12,5",
    t_global: "12,5",
    zx: 207,
    zx_global: 207000,
    zy: 207,
    zy_global: 207000,
  },
  {
    profile_section_id: 1800,
    name: "TUB1608012.5",
    type: "Tube",
    designation: "TUB1608012.5",
    shape: "TUBE",
    country_code: "British",
    ax: "52,1",
    ax_global: 5210,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    ix: 1204,
    ix_global: 12040000,
    iy: 465,
    iy_global: 4650000,
    iz: 1485,
    iz_global: 14850000,
    t: "12,5",
    t_global: "12,5",
    zx: 247,
    zx_global: 247000,
    zy: 146,
    zy_global: 146000,
  },
  {
    profile_section_id: 1801,
    name: "TUB15010012.0",
    type: "Tube",
    designation: "TUB15010012.0",
    shape: "TUBE",
    country_code: "British",
    ax: "52,7",
    ax_global: 5270,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    ix: 1633,
    ix_global: 16330000,
    iy: 745,
    iy_global: 7450000,
    iz: 1450,
    iz_global: 14500000,
    t: 12,
    t_global: 12,
    zx: 249,
    zx_global: 249000,
    zy: 185,
    zy_global: 185000,
  },
  {
    profile_section_id: 1802,
    name: "TUB1801808.0",
    type: "Tube",
    designation: "TUB1801808.0",
    shape: "TUBE",
    country_code: "British",
    ax: "54,4",
    ax_global: 5440,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    ix: 4162,
    ix_global: 41620000,
    iy: 2661,
    iy_global: 26610000,
    iz: 2661,
    iz_global: 26610000,
    t: 8,
    t_global: 8,
    zx: 349,
    zx_global: 349000,
    zy: 349,
    zy_global: 349000,
  },
  {
    profile_section_id: 1803,
    name: "TUB15010012.5",
    type: "Tube",
    designation: "TUB15010012.5",
    shape: "TUBE",
    country_code: "British",
    ax: "54,6",
    ax_global: 5460,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    ix: 1679,
    ix_global: 16790000,
    iy: 763,
    iy_global: 7630000,
    iz: 1488,
    iz_global: 14880000,
    t: "12,5",
    t_global: "12,5",
    zx: 256,
    zx_global: 256000,
    zy: 190,
    zy_global: 190000,
  },
  {
    profile_section_id: 1804,
    name: "TUB15015010.0",
    type: "Tube",
    designation: "TUB15015010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "54,9",
    ax_global: 5490,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    ix: 2832,
    ix_global: 28320000,
    iy: 1773,
    iy_global: 17730000,
    iz: 1773,
    iz_global: 17730000,
    t: 10,
    t_global: 10,
    zx: 286,
    zx_global: 286000,
    zy: 286,
    zy_global: 286000,
  },
  {
    profile_section_id: 1805,
    name: "TUB20010010.0",
    type: "Tube",
    designation: "TUB20010010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "54,9",
    ax_global: 5490,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    ix: 2156,
    ix_global: 21560000,
    iy: 869,
    iy_global: 8690000,
    iz: 2664,
    iz_global: 26640000,
    t: 10,
    t_global: 10,
    zx: 341,
    zx_global: 341000,
    zy: 206,
    zy_global: 206000,
  },
  {
    profile_section_id: 1806,
    name: "TUB2502506.0",
    type: "Tube",
    designation: "TUB2502506.0",
    shape: "TUBE",
    country_code: "British",
    ax: "58,2",
    ax_global: 5820,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 8825,
    ix_global: 88250000,
    iy: 5752,
    iy_global: 57520000,
    iz: 5752,
    iz_global: 57520000,
    t: 6,
    t_global: 6,
    zx: 531,
    zx_global: 531000,
    zy: 531,
    zy_global: 531000,
  },
  {
    profile_section_id: 1807,
    name: "TUB3002006.0",
    type: "Tube",
    designation: "TUB3002006.0",
    shape: "TUBE",
    country_code: "British",
    ax: "58,2",
    ax_global: 5820,
    b: 200,
    b_global: 200,
    d: 300,
    d_global: 300,
    ix: 8100,
    ix_global: 81000000,
    iy: 4013,
    iy_global: 40130000,
    iz: 7486,
    iz_global: 74860000,
    t: 6,
    t_global: 6,
    zx: 596,
    zx_global: 596000,
    zy: 451,
    zy_global: 451000,
  },
  {
    profile_section_id: 1808,
    name: "TUB16016010.0",
    type: "Tube",
    designation: "TUB16016010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "58,9",
    ax_global: 5890,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    ix: 3478,
    ix_global: 34780000,
    iy: 2186,
    iy_global: 21860000,
    iz: 2186,
    iz_global: 21860000,
    t: 10,
    t_global: 10,
    zx: 329,
    zx_global: 329000,
    zy: 329,
    zy_global: 329000,
  },
  {
    profile_section_id: 1809,
    name: "TUB14014012.0",
    type: "Tube",
    designation: "TUB14014012.0",
    shape: "TUBE",
    country_code: "British",
    ax: "59,9",
    ax_global: 5990,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    ix: 2616,
    ix_global: 26160000,
    iy: 1609,
    iy_global: 16090000,
    iz: 1609,
    iz_global: 16090000,
    t: 12,
    t_global: 12,
    zx: 284,
    zx_global: 284000,
    zy: 284,
    zy_global: 284000,
  },
  {
    profile_section_id: 1810,
    name: "TUB2002008.0",
    type: "Tube",
    designation: "TUB2002008.0",
    shape: "TUBE",
    country_code: "British",
    ax: "60,8",
    ax_global: 6080,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 5778,
    ix_global: 57780000,
    iy: 3709,
    iy_global: 37090000,
    iz: 3709,
    iz_global: 37090000,
    t: 8,
    t_global: 8,
    zx: 436,
    zx_global: 436000,
    zy: 436,
    zy_global: 436000,
  },
  {
    profile_section_id: 1811,
    name: "TUB2501508.0",
    type: "Tube",
    designation: "TUB2501508.0",
    shape: "TUBE",
    country_code: "British",
    ax: "60,8",
    ax_global: 6080,
    b: 150,
    b_global: 150,
    d: 250,
    d_global: 250,
    ix: 5021,
    ix_global: 50210000,
    iy: 2298,
    iy_global: 22980000,
    iz: 5111,
    iz_global: 51110000,
    t: 8,
    t_global: 8,
    zx: 501,
    zx_global: 501000,
    zy: 350,
    zy_global: 350000,
  },
  {
    profile_section_id: 1812,
    name: "TUB2502506.3",
    type: "Tube",
    designation: "TUB2502506.3",
    shape: "TUBE",
    country_code: "British",
    ax: 61,
    ax_global: 6100,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 9238,
    ix_global: 92380000,
    iy: 6014,
    iy_global: 60140000,
    iz: 6014,
    iz_global: 60140000,
    t: "6,3",
    t_global: "6,3",
    zx: 556,
    zx_global: 556000,
    zy: 556,
    zy_global: 556000,
  },
  {
    profile_section_id: 1813,
    name: "TUB3002006.3",
    type: "Tube",
    designation: "TUB3002006.3",
    shape: "TUBE",
    country_code: "British",
    ax: 61,
    ax_global: 6100,
    b: 200,
    b_global: 200,
    d: 300,
    d_global: 300,
    ix: 8476,
    ix_global: 84760000,
    iy: 4193,
    iy_global: 41930000,
    iz: 7829,
    iz_global: 78290000,
    t: "6,3",
    t_global: "6,3",
    zx: 624,
    zx_global: 624000,
    zy: 472,
    zy_global: 472000,
  },
  {
    profile_section_id: 1814,
    name: "TUB14014012.5",
    type: "Tube",
    designation: "TUB14014012.5",
    shape: "TUBE",
    country_code: "British",
    ax: "62,1",
    ax_global: 6210,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    ix: 2696,
    ix_global: 26960000,
    iy: 1653,
    iy_global: 16530000,
    iz: 1653,
    iz_global: 16530000,
    t: "12,5",
    t_global: "12,5",
    zx: 293,
    zx_global: 293000,
    zy: 293,
    zy_global: 293000,
  },
  {
    profile_section_id: 1815,
    name: "TUB15015012.0",
    type: "Tube",
    designation: "TUB15015012.0",
    shape: "TUBE",
    country_code: "British",
    ax: "64,7",
    ax_global: 6470,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    ix: 3272,
    ix_global: 32720000,
    iy: 2023,
    iy_global: 20230000,
    iz: 2023,
    iz_global: 20230000,
    t: 12,
    t_global: 12,
    zx: 331,
    zx_global: 331000,
    zy: 331,
    zy_global: 331000,
  },
  {
    profile_section_id: 1816,
    name: "TUB20010012.0",
    type: "Tube",
    designation: "TUB20010012.0",
    shape: "TUBE",
    country_code: "British",
    ax: "64,7",
    ax_global: 6470,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    ix: 2469,
    ix_global: 24690000,
    iy: 979,
    iy_global: 9790000,
    iz: 3047,
    iz_global: 30470000,
    t: 12,
    t_global: 12,
    zx: 395,
    zx_global: 395000,
    zy: 237,
    zy_global: 237000,
  },
  {
    profile_section_id: 1817,
    name: "TUB18018010.0",
    type: "Tube",
    designation: "TUB18018010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "66,9",
    ax_global: 6690,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    ix: 5048,
    ix_global: 50480000,
    iy: 3193,
    iy_global: 31930000,
    iz: 3193,
    iz_global: 31930000,
    t: 10,
    t_global: 10,
    zx: 424,
    zx_global: 424000,
    zy: 424,
    zy_global: 424000,
  },
  {
    profile_section_id: 1818,
    name: "TUB15015012.5",
    type: "Tube",
    designation: "TUB15015012.5",
    shape: "TUBE",
    country_code: "British",
    ax: "67,1",
    ax_global: 6710,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    ix: 3375,
    ix_global: 33750000,
    iy: 2080,
    iy_global: 20800000,
    iz: 2080,
    iz_global: 20800000,
    t: "12,5",
    t_global: "12,5",
    zx: 342,
    zx_global: 342000,
    zy: 342,
    zy_global: 342000,
  },
  {
    profile_section_id: 1819,
    name: "TUB20010012.5",
    type: "Tube",
    designation: "TUB20010012.5",
    shape: "TUBE",
    country_code: "British",
    ax: "67,1",
    ax_global: 6710,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    ix: 2541,
    ix_global: 25410000,
    iy: 1004,
    iy_global: 10040000,
    iz: 3136,
    iz_global: 31360000,
    t: "12,5",
    t_global: "12,5",
    zx: 408,
    zx_global: 408000,
    zy: 245,
    zy_global: 245000,
  },
  {
    profile_section_id: 1820,
    name: "TUB16016012.0",
    type: "Tube",
    designation: "TUB16016012.0",
    shape: "TUBE",
    country_code: "British",
    ax: "69,5",
    ax_global: 6950,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    ix: 4028,
    ix_global: 40280000,
    iy: 2502,
    iy_global: 25020000,
    iz: 2502,
    iz_global: 25020000,
    t: 12,
    t_global: 12,
    zx: 382,
    zx_global: 382000,
    zy: 382,
    zy_global: 382000,
  },
  {
    profile_section_id: 1821,
    name: "TUB3003006.0",
    type: "Tube",
    designation: "TUB3003006.0",
    shape: "TUBE",
    country_code: "British",
    ax: "70,2",
    ax_global: 7020,
    b: 300,
    b_global: 300,
    d: 300,
    d_global: 300,
    ix: 15410,
    ix_global: 154100000,
    iy: 10080,
    iy_global: 100800000,
    iz: 10080,
    iz_global: 100800000,
    t: 6,
    t_global: 6,
    zx: 772,
    zx_global: 772000,
    zy: 772,
    zy_global: 772000,
  },
  {
    profile_section_id: 1822,
    name: "TUB4002006.0",
    type: "Tube",
    designation: "TUB4002006.0",
    shape: "TUBE",
    country_code: "British",
    ax: "70,2",
    ax_global: 7020,
    b: 200,
    b_global: 200,
    d: 400,
    d_global: 400,
    ix: 12050,
    ix_global: 120500000,
    iy: 5142,
    iy_global: 51420000,
    iz: 15000,
    iz_global: 150000000,
    t: 6,
    t_global: 6,
    zx: 917,
    zx_global: 917000,
    zy: 568,
    zy_global: 568000,
  },
  {
    profile_section_id: 1823,
    name: "TUB16016012.5",
    type: "Tube",
    designation: "TUB16016012.5",
    shape: "TUBE",
    country_code: "British",
    ax: "72,1",
    ax_global: 7210,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    ix: 4158,
    ix_global: 41580000,
    iy: 2576,
    iy_global: 25760000,
    iz: 2576,
    iz_global: 25760000,
    t: "12,5",
    t_global: "12,5",
    zx: 395,
    zx_global: 395000,
    zy: 395,
    zy_global: 395000,
  },
  {
    profile_section_id: 1824,
    name: "TUB3003006.3",
    type: "Tube",
    designation: "TUB3003006.3",
    shape: "TUBE",
    country_code: "British",
    ax: "73,6",
    ax_global: 7360,
    b: 300,
    b_global: 300,
    d: 300,
    d_global: 300,
    ix: 16140,
    ix_global: 161400000,
    iy: 10550,
    iy_global: 105500000,
    iz: 10550,
    iz_global: 105500000,
    t: "6,3",
    t_global: "6,3",
    zx: 809,
    zx_global: 809000,
    zy: 809,
    zy_global: 809000,
  },
  {
    profile_section_id: 1825,
    name: "TUB4002006.3",
    type: "Tube",
    designation: "TUB4002006.3",
    shape: "TUBE",
    country_code: "British",
    ax: "73,6",
    ax_global: 7360,
    b: 200,
    b_global: 200,
    d: 400,
    d_global: 400,
    ix: 12610,
    ix_global: 126100000,
    iy: 5376,
    iy_global: 53760000,
    iz: 15700,
    iz_global: 157000000,
    t: "6,3",
    t_global: "6,3",
    zx: 960,
    zx_global: 960000,
    zy: 594,
    zy_global: 594000,
  },
  {
    profile_section_id: 1826,
    name: "TUB20020010.0",
    type: "Tube",
    designation: "TUB20020010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "74,9",
    ax_global: 7490,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 7031,
    ix_global: 70310000,
    iy: 4471,
    iy_global: 44710000,
    iz: 4471,
    iz_global: 44710000,
    t: 10,
    t_global: 10,
    zx: 531,
    zx_global: 531000,
    zy: 531,
    zy_global: 531000,
  },
  {
    profile_section_id: 1827,
    name: "TUB25015010.0",
    type: "Tube",
    designation: "TUB25015010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "74,9",
    ax_global: 7490,
    b: 150,
    b_global: 150,
    d: 250,
    d_global: 250,
    ix: 6090,
    ix_global: 60900000,
    iy: 2755,
    iy_global: 27550000,
    iz: 6174,
    iz_global: 61740000,
    t: 10,
    t_global: 10,
    zx: 611,
    zx_global: 611000,
    zy: 426,
    zy_global: 426000,
  },
  {
    profile_section_id: 1828,
    name: "TUB2502508.0",
    type: "Tube",
    designation: "TUB2502508.0",
    shape: "TUBE",
    country_code: "British",
    ax: "76,8",
    ax_global: 7680,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 11530,
    ix_global: 115300000,
    iy: 7455,
    iy_global: 74550000,
    iz: 7455,
    iz_global: 74550000,
    t: 8,
    t_global: 8,
    zx: 694,
    zx_global: 694000,
    zy: 694,
    zy_global: 694000,
  },
  {
    profile_section_id: 1829,
    name: "TUB3002008.0",
    type: "Tube",
    designation: "TUB3002008.0",
    shape: "TUBE",
    country_code: "British",
    ax: "76,8",
    ax_global: 7680,
    b: 200,
    b_global: 200,
    d: 300,
    d_global: 300,
    ix: 10560,
    ix_global: 105600000,
    iy: 5184,
    iy_global: 51840000,
    iz: 9717,
    iz_global: 97170000,
    t: 8,
    t_global: 8,
    zx: 779,
    zx_global: 779000,
    zy: 589,
    zy_global: 589000,
  },
  {
    profile_section_id: 1830,
    name: "TUB18018012.0",
    type: "Tube",
    designation: "TUB18018012.0",
    shape: "TUBE",
    country_code: "British",
    ax: "79,1",
    ax_global: 7910,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    ix: 5873,
    ix_global: 58730000,
    iy: 3677,
    iy_global: 36770000,
    iz: 3677,
    iz_global: 36770000,
    t: 12,
    t_global: 12,
    zx: 494,
    zx_global: 494000,
    zy: 494,
    zy_global: 494000,
  },
  {
    profile_section_id: 1831,
    name: "TUB18018012.5",
    type: "Tube",
    designation: "TUB18018012.5",
    shape: "TUBE",
    country_code: "British",
    ax: "82,1",
    ax_global: 8210,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    ix: 6070,
    ix_global: 60700000,
    iy: 3790,
    iy_global: 37900000,
    iz: 3790,
    iz_global: 37900000,
    t: "12,5",
    t_global: "12,5",
    zx: 511,
    zx_global: 511000,
    zy: 511,
    zy_global: 511000,
  },
  {
    profile_section_id: 1832,
    name: "TUB15015016.0",
    type: "Tube",
    designation: "TUB15015016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 83,
    ax_global: 8300,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    ix: 4026,
    ix_global: 40260000,
    iy: 2430,
    iy_global: 24300000,
    iz: 2430,
    iz_global: 24300000,
    t: 16,
    t_global: 16,
    zx: 411,
    zx_global: 411000,
    zy: 411,
    zy_global: 411000,
  },
  {
    profile_section_id: 1833,
    name: "TUB20010016.0",
    type: "Tube",
    designation: "TUB20010016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 83,
    ax_global: 8300,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    ix: 2982,
    ix_global: 29820000,
    iy: 1147,
    iy_global: 11470000,
    iz: 3678,
    iz_global: 36780000,
    t: 16,
    t_global: 16,
    zx: 491,
    zx_global: 491000,
    zy: 290,
    zy_global: 290000,
  },
  {
    profile_section_id: 1834,
    name: "TUB20020012.0",
    type: "Tube",
    designation: "TUB20020012.0",
    shape: "TUBE",
    country_code: "British",
    ax: "88,7",
    ax_global: 8870,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 8208,
    ix_global: 82080000,
    iy: 5171,
    iy_global: 51710000,
    iz: 5171,
    iz_global: 51710000,
    t: 12,
    t_global: 12,
    zx: 621,
    zx_global: 621000,
    zy: 621,
    zy_global: 621000,
  },
  {
    profile_section_id: 1835,
    name: "TUB25015012.0",
    type: "Tube",
    designation: "TUB25015012.0",
    shape: "TUBE",
    country_code: "British",
    ax: "88,7",
    ax_global: 8870,
    b: 150,
    b_global: 150,
    d: 250,
    d_global: 250,
    ix: 7088,
    ix_global: 70880000,
    iy: 3168,
    iy_global: 31680000,
    iz: 7154,
    iz_global: 71540000,
    t: 12,
    t_global: 12,
    zx: 715,
    zx_global: 715000,
    zy: 497,
    zy_global: 497000,
  },
  {
    profile_section_id: 1836,
    name: "TUB16016016.0",
    type: "Tube",
    designation: "TUB16016016.0",
    shape: "TUBE",
    country_code: "British",
    ax: "89,4",
    ax_global: 8940,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    ix: 4988,
    ix_global: 49880000,
    iy: 3028,
    iy_global: 30280000,
    iz: 3028,
    iz_global: 30280000,
    t: 16,
    t_global: 16,
    zx: 476,
    zx_global: 476000,
    zy: 476,
    zy_global: 476000,
  },
  {
    profile_section_id: 1837,
    name: "TUB20020012.5",
    type: "Tube",
    designation: "TUB20020012.5",
    shape: "TUBE",
    country_code: "British",
    ax: "92,1",
    ax_global: 9210,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 8491,
    ix_global: 84910000,
    iy: 5336,
    iy_global: 53360000,
    iz: 5336,
    iz_global: 53360000,
    t: "12,5",
    t_global: "12,5",
    zx: 643,
    zx_global: 643000,
    zy: 643,
    zy_global: 643000,
  },
  {
    profile_section_id: 1838,
    name: "TUB25015012.5",
    type: "Tube",
    designation: "TUB25015012.5",
    shape: "TUBE",
    country_code: "British",
    ax: "92,1",
    ax_global: 9210,
    b: 150,
    b_global: 150,
    d: 250,
    d_global: 250,
    ix: 7326,
    ix_global: 73260000,
    iy: 3265,
    iy_global: 32650000,
    iz: 7387,
    iz_global: 73870000,
    t: "12,5",
    t_global: "12,5",
    zx: 740,
    zx_global: 740000,
    zy: 514,
    zy_global: 514000,
  },
  {
    profile_section_id: 1839,
    name: "TUB3003008.0",
    type: "Tube",
    designation: "TUB3003008.0",
    shape: "TUBE",
    country_code: "British",
    ax: "92,8",
    ax_global: 9280,
    b: 300,
    b_global: 300,
    d: 300,
    d_global: 300,
    ix: 20190,
    ix_global: 201900000,
    iy: 13130,
    iy_global: 131300000,
    iz: 13130,
    iz_global: 131300000,
    t: 8,
    t_global: 8,
    zx: 1013,
    zx_global: 1013000,
    zy: 1013,
    zy_global: 1013000,
  },
  {
    profile_section_id: 1840,
    name: "TUB4002008.0",
    type: "Tube",
    designation: "TUB4002008.0",
    shape: "TUBE",
    country_code: "British",
    ax: "92,8",
    ax_global: 9280,
    b: 200,
    b_global: 200,
    d: 400,
    d_global: 400,
    ix: 15730,
    ix_global: 157300000,
    iy: 6660,
    iy_global: 66600000,
    iz: 19560,
    iz_global: 195600000,
    t: 8,
    t_global: 8,
    zx: 1203,
    zx_global: 1203000,
    zy: 743,
    zy_global: 743000,
  },
  {
    profile_section_id: 1841,
    name: "TUB25025010.0",
    type: "Tube",
    designation: "TUB25025010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "94,9",
    ax_global: 9490,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 14110,
    ix_global: 141100000,
    iy: 9055,
    iy_global: 90550000,
    iz: 9055,
    iz_global: 90550000,
    t: 10,
    t_global: 10,
    zx: 851,
    zx_global: 851000,
    zy: 851,
    zy_global: 851000,
  },
  {
    profile_section_id: 1842,
    name: "TUB30020010.0",
    type: "Tube",
    designation: "TUB30020010.0",
    shape: "TUBE",
    country_code: "British",
    ax: "94,9",
    ax_global: 9490,
    b: 200,
    b_global: 200,
    d: 300,
    d_global: 300,
    ix: 12910,
    ix_global: 129100000,
    iy: 6278,
    iy_global: 62780000,
    iz: 11820,
    iz_global: 118200000,
    t: 10,
    t_global: 10,
    zx: 956,
    zx_global: 956000,
    zy: 721,
    zy_global: 721000,
  },
  {
    profile_section_id: 1843,
    name: "TUB18018016.0",
    type: "Tube",
    designation: "TUB18018016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 102,
    ax_global: 10200,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    ix: 7343,
    ix_global: 73430000,
    iy: 4504,
    iy_global: 45040000,
    iz: 4504,
    iz_global: 45040000,
    t: 16,
    t_global: 16,
    zx: 621,
    zx_global: 621000,
    zy: 621,
    zy_global: 621000,
  },
  {
    profile_section_id: 1844,
    name: "TUB3503508.0",
    type: "Tube",
    designation: "TUB3503508.0",
    shape: "TUBE",
    country_code: "British",
    ax: 109,
    ax_global: 10900,
    b: 350,
    b_global: 350,
    d: 350,
    d_global: 350,
    ix: 32380,
    ix_global: 323800000,
    iy: 21130,
    iy_global: 211300000,
    iz: 21130,
    iz_global: 211300000,
    t: 8,
    t_global: 8,
    zx: 1392,
    zx_global: 1392000,
    zy: 1392,
    zy_global: 1392000,
  },
  {
    profile_section_id: 1845,
    name: "TUB4502508.0",
    type: "Tube",
    designation: "TUB4502508.0",
    shape: "TUBE",
    country_code: "British",
    ax: 109,
    ax_global: 10900,
    b: 250,
    b_global: 250,
    d: 450,
    d_global: 450,
    ix: 27080,
    ix_global: 270800000,
    iy: 12140,
    iy_global: 121400000,
    iz: 30080,
    iz_global: 300800000,
    t: 8,
    t_global: 8,
    zx: 1622,
    zx_global: 1622000,
    zy: 1081,
    zy_global: 1081000,
  },
  {
    profile_section_id: 1846,
    name: "TUB25025012.0",
    type: "Tube",
    designation: "TUB25025012.0",
    shape: "TUBE",
    country_code: "British",
    ax: 113,
    ax_global: 11300,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 16570,
    ix_global: 165700000,
    iy: 10560,
    iy_global: 105600000,
    iz: 10560,
    iz_global: 105600000,
    t: 12,
    t_global: 12,
    zx: 1000,
    zx_global: 1000000,
    zy: 1000,
    zy_global: 1000000,
  },
  {
    profile_section_id: 1847,
    name: "TUB30020012.0",
    type: "Tube",
    designation: "TUB30020012.0",
    shape: "TUBE",
    country_code: "British",
    ax: 113,
    ax_global: 11300,
    b: 200,
    b_global: 200,
    d: 300,
    d_global: 300,
    ix: 15140,
    ix_global: 151400000,
    iy: 7294,
    iy_global: 72940000,
    iz: 13800,
    iz_global: 138000000,
    t: 12,
    t_global: 12,
    zx: 1124,
    zx_global: 1124000,
    zy: 847,
    zy_global: 847000,
  },
  {
    profile_section_id: 1848,
    name: "TUB20020016.0",
    type: "Tube",
    designation: "TUB20020016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 115,
    ax_global: 11500,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 10340,
    ix_global: 103400000,
    iy: 6394,
    iy_global: 63940000,
    iz: 6394,
    iz_global: 63940000,
    t: 16,
    t_global: 16,
    zx: 785,
    zx_global: 785000,
    zy: 785,
    zy_global: 785000,
  },
  {
    profile_section_id: 1849,
    name: "TUB25015016.0",
    type: "Tube",
    designation: "TUB25015016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 115,
    ax_global: 11500,
    b: 150,
    b_global: 150,
    d: 250,
    d_global: 250,
    ix: 8868,
    ix_global: 88680000,
    iy: 3873,
    iy_global: 38730000,
    iz: 8879,
    iz_global: 88790000,
    t: 16,
    t_global: 16,
    zx: 906,
    zx_global: 906000,
    zy: 625,
    zy_global: 625000,
  },
  {
    profile_section_id: 1850,
    name: "TUB30030010.0",
    type: "Tube",
    designation: "TUB30030010.0",
    shape: "TUBE",
    country_code: "British",
    ax: 115,
    ax_global: 11500,
    b: 300,
    b_global: 300,
    d: 300,
    d_global: 300,
    ix: 24810,
    ix_global: 248100000,
    iy: 16030,
    iy_global: 160300000,
    iz: 16030,
    iz_global: 160300000,
    t: 10,
    t_global: 10,
    zx: 1246,
    zx_global: 1246000,
    zy: 1246,
    zy_global: 1246000,
  },
  {
    profile_section_id: 1851,
    name: "TUB40020010.0",
    type: "Tube",
    designation: "TUB40020010.0",
    shape: "TUBE",
    country_code: "British",
    ax: 115,
    ax_global: 11500,
    b: 200,
    b_global: 200,
    d: 400,
    d_global: 400,
    ix: 19260,
    ix_global: 192600000,
    iy: 8084,
    iy_global: 80840000,
    iz: 23910,
    iz_global: 239100000,
    t: 10,
    t_global: 10,
    zx: 1480,
    zx_global: 1480000,
    zy: 911,
    zy_global: 911000,
  },
  {
    profile_section_id: 1852,
    name: "TUB25025012.5",
    type: "Tube",
    designation: "TUB25025012.5",
    shape: "TUBE",
    country_code: "British",
    ax: 117,
    ax_global: 11700,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 17160,
    ix_global: 171600000,
    iy: 10920,
    iy_global: 109200000,
    iz: 10920,
    iz_global: 109200000,
    t: "12,5",
    t_global: "12,5",
    zx: 1037,
    zx_global: 1037000,
    zy: 1037,
    zy_global: 1037000,
  },
  {
    profile_section_id: 1853,
    name: "TUB30020012.5",
    type: "Tube",
    designation: "TUB30020012.5",
    shape: "TUBE",
    country_code: "British",
    ax: 117,
    ax_global: 11700,
    b: 200,
    b_global: 200,
    d: 300,
    d_global: 300,
    ix: 15680,
    ix_global: 156800000,
    iy: 7537,
    iy_global: 75370000,
    iz: 14270,
    iz_global: 142700000,
    t: "12,5",
    t_global: "12,5",
    zx: 1165,
    zx_global: 1165000,
    zy: 877,
    zy_global: 877000,
  },
  {
    profile_section_id: 1854,
    name: "TUB4004008.0",
    type: "Tube",
    designation: "TUB4004008.0",
    shape: "TUBE",
    country_code: "British",
    ax: 125,
    ax_global: 12500,
    b: 400,
    b_global: 400,
    d: 400,
    d_global: 400,
    ix: 48690,
    ix_global: 486900000,
    iy: 31860,
    iy_global: 318600000,
    iz: 31860,
    iz_global: 318600000,
    t: 8,
    t_global: 8,
    zx: 1830,
    zx_global: 1830000,
    zy: 1830,
    zy_global: 1830000,
  },
  {
    profile_section_id: 1855,
    name: "TUB5003008.0",
    type: "Tube",
    designation: "TUB5003008.0",
    shape: "TUBE",
    country_code: "British",
    ax: 125,
    ax_global: 12500,
    b: 300,
    b_global: 300,
    d: 500,
    d_global: 500,
    ix: 42560,
    ix_global: 425600000,
    iy: 19950,
    iy_global: 199500000,
    iz: 43730,
    iz_global: 437300000,
    t: 8,
    t_global: 8,
    zx: 2100,
    zx_global: 2100000,
    zy: 1480,
    zy_global: 1480000,
  },
  {
    profile_section_id: 1856,
    name: "TUB35035010.0",
    type: "Tube",
    designation: "TUB35035010.0",
    shape: "TUBE",
    country_code: "British",
    ax: 135,
    ax_global: 13500,
    b: 350,
    b_global: 350,
    d: 350,
    d_global: 350,
    ix: 39890,
    ix_global: 398900000,
    iy: 25880,
    iy_global: 258800000,
    iz: 25880,
    iz_global: 258800000,
    t: 10,
    t_global: 10,
    zx: 1715,
    zx_global: 1715000,
    zy: 1715,
    zy_global: 1715000,
  },
  {
    profile_section_id: 1857,
    name: "TUB45025010.0",
    type: "Tube",
    designation: "TUB45025010.0",
    shape: "TUBE",
    country_code: "British",
    ax: 135,
    ax_global: 13500,
    b: 250,
    b_global: 250,
    d: 450,
    d_global: 450,
    ix: 33280,
    ix_global: 332800000,
    iy: 14820,
    iy_global: 148200000,
    iz: 36890,
    iz_global: 368900000,
    t: 10,
    t_global: 10,
    zx: 2000,
    zx_global: 2000000,
    zy: 1331,
    zy_global: 1331000,
  },
  {
    profile_section_id: 1858,
    name: "TUB30030012.0",
    type: "Tube",
    designation: "TUB30030012.0",
    shape: "TUBE",
    country_code: "British",
    ax: 137,
    ax_global: 13700,
    b: 300,
    b_global: 300,
    d: 300,
    d_global: 300,
    ix: 29250,
    ix_global: 292500000,
    iy: 18780,
    iy_global: 187800000,
    iz: 18780,
    iz_global: 187800000,
    t: 12,
    t_global: 12,
    zx: 1470,
    zx_global: 1470000,
    zy: 1470,
    zy_global: 1470000,
  },
  {
    profile_section_id: 1859,
    name: "TUB40020012.0",
    type: "Tube",
    designation: "TUB40020012.0",
    shape: "TUBE",
    country_code: "British",
    ax: 137,
    ax_global: 13700,
    b: 200,
    b_global: 200,
    d: 400,
    d_global: 400,
    ix: 22620,
    ix_global: 226200000,
    iy: 9418,
    iy_global: 94180000,
    iz: 28060,
    iz_global: 280600000,
    t: 12,
    t_global: 12,
    zx: 1748,
    zx_global: 1748000,
    zy: 1072,
    zy_global: 1072000,
  },
  {
    profile_section_id: 1860,
    name: "TUB30030012.5",
    type: "Tube",
    designation: "TUB30030012.5",
    shape: "TUBE",
    country_code: "British",
    ax: 142,
    ax_global: 14200,
    b: 300,
    b_global: 300,
    d: 300,
    d_global: 300,
    ix: 30330,
    ix_global: 303300000,
    iy: 19440,
    iy_global: 194400000,
    iz: 19440,
    iz_global: 194400000,
    t: "12,5",
    t_global: "12,5",
    zx: 1525,
    zx_global: 1525000,
    zy: 1525,
    zy_global: 1525000,
  },
  {
    profile_section_id: 1861,
    name: "TUB40020012.5",
    type: "Tube",
    designation: "TUB40020012.5",
    shape: "TUBE",
    country_code: "British",
    ax: 142,
    ax_global: 14200,
    b: 200,
    b_global: 200,
    d: 400,
    d_global: 400,
    ix: 23440,
    ix_global: 234400000,
    iy: 9738,
    iy_global: 97380000,
    iz: 29060,
    iz_global: 290600000,
    t: "12,5",
    t_global: "12,5",
    zx: 1813,
    zx_global: 1813000,
    zy: 1111,
    zy_global: 1111000,
  },
  {
    profile_section_id: 1862,
    name: "TUB25025016.0",
    type: "Tube",
    designation: "TUB25025016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 147,
    ax_global: 14700,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 21140,
    ix_global: 211400000,
    iy: 13270,
    iy_global: 132700000,
    iz: 13270,
    iz_global: 132700000,
    t: 16,
    t_global: 16,
    zx: 1280,
    zx_global: 1280000,
    zy: 1280,
    zy_global: 1280000,
  },
  {
    profile_section_id: 1863,
    name: "TUB30020016.0",
    type: "Tube",
    designation: "TUB30020016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 147,
    ax_global: 14700,
    b: 200,
    b_global: 200,
    d: 300,
    d_global: 300,
    ix: 19250,
    ix_global: 192500000,
    iy: 9109,
    iy_global: 91090000,
    iz: 17390,
    iz_global: 173900000,
    t: 16,
    t_global: 16,
    zx: 1441,
    zx_global: 1441000,
    zy: 1080,
    zy_global: 1080000,
  },
  {
    profile_section_id: 1864,
    name: "TUB40040010.0",
    type: "Tube",
    designation: "TUB40040010.0",
    shape: "TUBE",
    country_code: "British",
    ax: 155,
    ax_global: 15500,
    b: 400,
    b_global: 400,
    d: 400,
    d_global: 400,
    ix: 60090,
    ix_global: 600900000,
    iy: 39130,
    iy_global: 391300000,
    iz: 39130,
    iz_global: 391300000,
    t: 10,
    t_global: 10,
    zx: 2260,
    zx_global: 2260000,
    zy: 2260,
    zy_global: 2260000,
  },
  {
    profile_section_id: 1865,
    name: "TUB50030010.0",
    type: "Tube",
    designation: "TUB50030010.0",
    shape: "TUBE",
    country_code: "British",
    ax: 155,
    ax_global: 15500,
    b: 300,
    b_global: 300,
    d: 500,
    d_global: 500,
    ix: 52450,
    ix_global: 524500000,
    iy: 24440,
    iy_global: 244400000,
    iz: 53760,
    iz_global: 537600000,
    t: 10,
    t_global: 10,
    zx: 2595,
    zx_global: 2595000,
    zy: 1826,
    zy_global: 1826000,
  },
  {
    profile_section_id: 1866,
    name: "TUB35035012.0",
    type: "Tube",
    designation: "TUB35035012.0",
    shape: "TUBE",
    country_code: "British",
    ax: 161,
    ax_global: 16100,
    b: 350,
    b_global: 350,
    d: 350,
    d_global: 350,
    ix: 47150,
    ix_global: 471500000,
    iy: 30430,
    iy_global: 304300000,
    iz: 30430,
    iz_global: 304300000,
    t: 12,
    t_global: 12,
    zx: 2030,
    zx_global: 2030000,
    zy: 2030,
    zy_global: 2030000,
  },
  {
    profile_section_id: 1867,
    name: "TUB45025012.0",
    type: "Tube",
    designation: "TUB45025012.0",
    shape: "TUBE",
    country_code: "British",
    ax: 161,
    ax_global: 16100,
    b: 250,
    b_global: 250,
    d: 450,
    d_global: 450,
    ix: 39260,
    ix_global: 392600000,
    iy: 17360,
    iy_global: 173600000,
    iz: 43430,
    iz_global: 434300000,
    t: 12,
    t_global: 12,
    zx: 2367,
    zx_global: 2367000,
    zy: 1572,
    zy_global: 1572000,
  },
  {
    profile_section_id: 1868,
    name: "TUB35035012.5",
    type: "Tube",
    designation: "TUB35035012.5",
    shape: "TUBE",
    country_code: "British",
    ax: 167,
    ax_global: 16700,
    b: 350,
    b_global: 350,
    d: 350,
    d_global: 350,
    ix: 48930,
    ix_global: 489300000,
    iy: 31540,
    iy_global: 315400000,
    iz: 31540,
    iz_global: 315400000,
    t: "12,5",
    t_global: "12,5",
    zx: 2107,
    zx_global: 2107000,
    zy: 2107,
    zy_global: 2107000,
  },
  {
    profile_section_id: 1869,
    name: "TUB45025012.5",
    type: "Tube",
    designation: "TUB45025012.5",
    shape: "TUBE",
    country_code: "British",
    ax: 167,
    ax_global: 16700,
    b: 250,
    b_global: 250,
    d: 450,
    d_global: 450,
    ix: 40720,
    ix_global: 407200000,
    iy: 17970,
    iy_global: 179700000,
    iz: 45030,
    iz_global: 450300000,
    t: "12,5",
    t_global: "12,5",
    zx: 2458,
    zx_global: 2458000,
    zy: 1631,
    zy_global: 1631000,
  },
  {
    profile_section_id: 1870,
    name: "TUB30030016.0",
    type: "Tube",
    designation: "TUB30030016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 179,
    ax_global: 17900,
    b: 300,
    b_global: 300,
    d: 300,
    d_global: 300,
    ix: 37620,
    ix_global: 376200000,
    iy: 23850,
    iy_global: 238500000,
    iz: 23850,
    iz_global: 238500000,
    t: 16,
    t_global: 16,
    zx: 1895,
    zx_global: 1895000,
    zy: 1895,
    zy_global: 1895000,
  },
  {
    profile_section_id: 1871,
    name: "TUB40020016.0",
    type: "Tube",
    designation: "TUB40020016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 179,
    ax_global: 17900,
    b: 200,
    b_global: 200,
    d: 400,
    d_global: 400,
    ix: 28870,
    ix_global: 288700000,
    iy: 11820,
    iy_global: 118200000,
    iz: 35740,
    iz_global: 357400000,
    t: 16,
    t_global: 16,
    zx: 2256,
    zx_global: 2256000,
    zy: 1374,
    zy_global: 1374000,
  },
  {
    profile_section_id: 1872,
    name: "TUB40040012.0",
    type: "Tube",
    designation: "TUB40040012.0",
    shape: "TUBE",
    country_code: "British",
    ax: 185,
    ax_global: 18500,
    b: 400,
    b_global: 400,
    d: 400,
    d_global: 400,
    ix: 71180,
    ix_global: 711800000,
    iy: 46130,
    iy_global: 461300000,
    iz: 46130,
    iz_global: 461300000,
    t: 12,
    t_global: 12,
    zx: 2679,
    zx_global: 2679000,
    zy: 2679,
    zy_global: 2679000,
  },
  {
    profile_section_id: 1873,
    name: "TUB50030012.0",
    type: "Tube",
    designation: "TUB50030012.0",
    shape: "TUBE",
    country_code: "British",
    ax: 185,
    ax_global: 18500,
    b: 300,
    b_global: 300,
    d: 500,
    d_global: 500,
    ix: 62040,
    ix_global: 620400000,
    iy: 28740,
    iy_global: 287400000,
    iz: 63450,
    iz_global: 634500000,
    t: 12,
    t_global: 12,
    zx: 3077,
    zx_global: 3077000,
    zy: 2161,
    zy_global: 2161000,
  },
  {
    profile_section_id: 1874,
    name: "TUB40040012.5",
    type: "Tube",
    designation: "TUB40040012.5",
    shape: "TUBE",
    country_code: "British",
    ax: 192,
    ax_global: 19200,
    b: 400,
    b_global: 400,
    d: 400,
    d_global: 400,
    ix: 73910,
    ix_global: 739100000,
    iy: 47840,
    iy_global: 478400000,
    iz: 47840,
    iz_global: 478400000,
    t: "12,5",
    t_global: "12,5",
    zx: 2782,
    zx_global: 2782000,
    zy: 2782,
    zy_global: 2782000,
  },
  {
    profile_section_id: 1875,
    name: "TUB50030012.5",
    type: "Tube",
    designation: "TUB50030012.5",
    shape: "TUBE",
    country_code: "British",
    ax: 192,
    ax_global: 19200,
    b: 300,
    b_global: 300,
    d: 500,
    d_global: 500,
    ix: 64390,
    ix_global: 643900000,
    iy: 29780,
    iy_global: 297800000,
    iz: 65810,
    iz_global: 658100000,
    t: "12,5",
    t_global: "12,5",
    zx: 3196,
    zx_global: 3196000,
    zy: 2244,
    zy_global: 2244000,
  },
  {
    profile_section_id: 1876,
    name: "TUB45045012.0",
    type: "Tube",
    designation: "TUB45045012.0",
    shape: "TUBE",
    country_code: "British",
    ax: 207,
    ax_global: 20700,
    b: 450,
    b_global: 450,
    d: 450,
    d_global: 450,
    ix: 102400,
    ix_global: 1024000000,
    iy: 65430,
    iy_global: 654300000,
    iz: 65430,
    iz_global: 654300000,
    t: 12,
    t_global: 12,
    zx: 3371,
    zx_global: 3371000,
    zy: 3371,
    zy_global: 3371000,
  },
  {
    profile_section_id: 1877,
    name: "TUB35035016.0",
    type: "Tube",
    designation: "TUB35035016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 211,
    ax_global: 21100,
    b: 350,
    b_global: 350,
    d: 350,
    d_global: 350,
    ix: 60990,
    ix_global: 609900000,
    iy: 38940,
    iy_global: 389400000,
    iz: 38940,
    iz_global: 389400000,
    t: 16,
    t_global: 16,
    zx: 2630,
    zx_global: 2630000,
    zy: 2630,
    zy_global: 2630000,
  },
  {
    profile_section_id: 1878,
    name: "TUB45025016.0",
    type: "Tube",
    designation: "TUB45025016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 211,
    ax_global: 21100,
    b: 250,
    b_global: 250,
    d: 450,
    d_global: 450,
    ix: 50550,
    ix_global: 505500000,
    iy: 22040,
    iy_global: 220400000,
    iz: 55710,
    iz_global: 557100000,
    t: 16,
    t_global: 16,
    zx: 3070,
    zx_global: 3070000,
    zy: 2029,
    zy_global: 2029000,
  },
  {
    profile_section_id: 1879,
    name: "TUB50050012.0",
    type: "Tube",
    designation: "TUB50050012.0",
    shape: "TUBE",
    country_code: "British",
    ax: 231,
    ax_global: 23100,
    b: 500,
    b_global: 500,
    d: 500,
    d_global: 500,
    ix: 141500,
    ix_global: 1415000000,
    iy: 90750,
    iy_global: 907500000,
    iz: 90750,
    iz_global: 907500000,
    t: 12,
    t_global: 12,
    zx: 4196,
    zx_global: 4196000,
    zy: 4196,
    zy_global: 4196000,
  },
  {
    profile_section_id: 1880,
    name: "TUB35035019.0",
    type: "Tube",
    designation: "TUB35035019.0",
    shape: "TUBE",
    country_code: "British",
    ax: 242,
    ax_global: 24200,
    b: 350,
    b_global: 350,
    d: 350,
    d_global: 350,
    ix: 70930,
    ix_global: 709300000,
    iy: 43360,
    iy_global: 433600000,
    iz: 43360,
    iz_global: 433600000,
    t: 19,
    t_global: 19,
    zx: 2967,
    zx_global: 2967000,
    zy: 2967,
    zy_global: 2967000,
  },
  {
    profile_section_id: 1881,
    name: "TUB40040016.0",
    type: "Tube",
    designation: "TUB40040016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 243,
    ax_global: 24300,
    b: 400,
    b_global: 400,
    d: 400,
    d_global: 400,
    ix: 92440,
    ix_global: 924400000,
    iy: 59340,
    iy_global: 593400000,
    iz: 59340,
    iz_global: 593400000,
    t: 16,
    t_global: 16,
    zx: 3484,
    zx_global: 3484000,
    zy: 3484,
    zy_global: 3484000,
  },
  {
    profile_section_id: 1882,
    name: "TUB50030016.0",
    type: "Tube",
    designation: "TUB50030016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 243,
    ax_global: 24300,
    b: 300,
    b_global: 300,
    d: 500,
    d_global: 500,
    ix: 80330,
    ix_global: 803300000,
    iy: 36770,
    iy_global: 367700000,
    iz: 81780,
    iz_global: 817800000,
    t: 16,
    t_global: 16,
    zx: 4005,
    zx_global: 4005000,
    zy: 2804,
    zy_global: 2804000,
  },
  {
    profile_section_id: 1883,
    name: "TUB45045016.0",
    type: "Tube",
    designation: "TUB45045016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 271,
    ax_global: 27100,
    b: 450,
    b_global: 450,
    d: 450,
    d_global: 450,
    ix: 133500,
    ix_global: 1335000000,
    iy: 84070,
    iy_global: 840700000,
    iz: 84070,
    iz_global: 840700000,
    t: 16,
    t_global: 16,
    zx: 4376,
    zx_global: 4376000,
    zy: 4376,
    zy_global: 4376000,
  },
  {
    profile_section_id: 1884,
    name: "TUB35035022.0",
    type: "Tube",
    designation: "TUB35035022.0",
    shape: "TUBE",
    country_code: "British",
    ax: 276,
    ax_global: 27600,
    b: 350,
    b_global: 350,
    d: 350,
    d_global: 350,
    ix: 80180,
    ix_global: 801800000,
    iy: 48350,
    iy_global: 483500000,
    iz: 48350,
    iz_global: 483500000,
    t: 22,
    t_global: 22,
    zx: 3343,
    zx_global: 3343000,
    zy: 3343,
    zy_global: 3343000,
  },
  {
    profile_section_id: 1885,
    name: "TUB40040020.0",
    type: "Tube",
    designation: "TUB40040020.0",
    shape: "TUBE",
    country_code: "British",
    ax: 300,
    ax_global: 30000,
    b: 400,
    b_global: 400,
    d: 400,
    d_global: 400,
    ix: 112500,
    ix_global: 1125000000,
    iy: 71530,
    iy_global: 715300000,
    iz: 71530,
    iz_global: 715300000,
    t: 20,
    t_global: 20,
    zx: 4247,
    zx_global: 4247000,
    zy: 4247,
    zy_global: 4247000,
  },
  {
    profile_section_id: 1886,
    name: "TUB50030020.0",
    type: "Tube",
    designation: "TUB50030020.0",
    shape: "TUBE",
    country_code: "British",
    ax: 300,
    ax_global: 30000,
    b: 300,
    b_global: 300,
    d: 500,
    d_global: 500,
    ix: 97450,
    ix_global: 974500000,
    iy: 44080,
    iy_global: 440800000,
    iz: 98780,
    iz_global: 987800000,
    t: 20,
    t_global: 20,
    zx: 4885,
    zx_global: 4885000,
    zy: 3408,
    zy_global: 3408000,
  },
  {
    profile_section_id: 1887,
    name: "TUB50050016.0",
    type: "Tube",
    designation: "TUB50050016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 303,
    ax_global: 30300,
    b: 500,
    b_global: 500,
    d: 500,
    d_global: 500,
    ix: 184800,
    ix_global: 1848000000,
    iy: 117100,
    iy_global: 1171000000,
    iz: 117100,
    iz_global: 1171000000,
    t: 16,
    t_global: 16,
    zx: 5461,
    zx_global: 5461000,
    zy: 5461,
    zy_global: 5461000,
  },
  {
    profile_section_id: 1888,
    name: "TUB35035025.0",
    type: "Tube",
    designation: "TUB35035025.0",
    shape: "TUBE",
    country_code: "British",
    ax: 309,
    ax_global: 30900,
    b: 350,
    b_global: 350,
    d: 350,
    d_global: 350,
    ix: 88880,
    ix_global: 888800000,
    iy: 52890,
    iy_global: 528900000,
    iz: 52890,
    iz_global: 528900000,
    t: 25,
    t_global: 25,
    zx: 3695,
    zx_global: 3695000,
    zy: 3695,
    zy_global: 3695000,
  },
  {
    profile_section_id: 1889,
    name: "TUB45045019.0",
    type: "Tube",
    designation: "TUB45045019.0",
    shape: "TUBE",
    country_code: "British",
    ax: 318,
    ax_global: 31800,
    b: 450,
    b_global: 450,
    d: 450,
    d_global: 450,
    ix: 155800,
    ix_global: 1558000000,
    iy: 97060,
    iy_global: 970600000,
    iz: 97060,
    iz_global: 970600000,
    t: 19,
    t_global: 19,
    zx: 5092,
    zx_global: 5092000,
    zy: 5092,
    zy_global: 5092000,
  },
  {
    profile_section_id: 1890,
    name: "TUB40040022.0",
    type: "Tube",
    designation: "TUB40040022.0",
    shape: "TUBE",
    country_code: "British",
    ax: 320,
    ax_global: 32000,
    b: 400,
    b_global: 400,
    d: 400,
    d_global: 400,
    ix: 122400,
    ix_global: 1224000000,
    iy: 74710,
    iy_global: 747100000,
    iz: 74710,
    iz_global: 747100000,
    t: 22,
    t_global: 22,
    zx: 4476,
    zx_global: 4476000,
    zy: 4476,
    zy_global: 4476000,
  },
  {
    profile_section_id: 1891,
    name: "TUB55055016.0",
    type: "Tube",
    designation: "TUB55055016.0",
    shape: "TUBE",
    country_code: "British",
    ax: 335,
    ax_global: 33500,
    b: 550,
    b_global: 550,
    d: 550,
    d_global: 550,
    ix: 247900,
    ix_global: 2479000000,
    iy: 157700,
    iy_global: 1577000000,
    iz: 157700,
    iz_global: 1577000000,
    t: 16,
    t_global: 16,
    zx: 6666,
    zx_global: 6666000,
    zy: 6666,
    zy_global: 6666000,
  },
  {
    profile_section_id: 1892,
    name: "TUB50050019.0",
    type: "Tube",
    designation: "TUB50050019.0",
    shape: "TUBE",
    country_code: "British",
    ax: 356,
    ax_global: 35600,
    b: 500,
    b_global: 500,
    d: 500,
    d_global: 500,
    ix: 216100,
    ix_global: 2161000000,
    iy: 135500,
    iy_global: 1355000000,
    iz: 135500,
    iz_global: 1355000000,
    t: 19,
    t_global: 19,
    zx: 6368,
    zx_global: 6368000,
    zy: 6368,
    zy_global: 6368000,
  },
  {
    profile_section_id: 1893,
    name: "TUB40040025.0",
    type: "Tube",
    designation: "TUB40040025.0",
    shape: "TUBE",
    country_code: "British",
    ax: 359,
    ax_global: 35900,
    b: 400,
    b_global: 400,
    d: 400,
    d_global: 400,
    ix: 136100,
    ix_global: 1361000000,
    iy: 82150,
    iy_global: 821500000,
    iz: 82150,
    iz_global: 821500000,
    t: 25,
    t_global: 25,
    zx: 4967,
    zx_global: 4967000,
    zy: 4967,
    zy_global: 4967000,
  },
  {
    profile_section_id: 1894,
    name: "TUB45045022.0",
    type: "Tube",
    designation: "TUB45045022.0",
    shape: "TUBE",
    country_code: "British",
    ax: 364,
    ax_global: 36400,
    b: 450,
    b_global: 450,
    d: 450,
    d_global: 450,
    ix: 177200,
    ix_global: 1772000000,
    iy: 109200,
    iy_global: 1092000000,
    iz: 109200,
    iz_global: 1092000000,
    t: 22,
    t_global: 22,
    zx: 5775,
    zx_global: 5775000,
    zy: 5775,
    zy_global: 5775000,
  },
  {
    profile_section_id: 1895,
    name: "TUB55055019.0",
    type: "Tube",
    designation: "TUB55055019.0",
    shape: "TUBE",
    country_code: "British",
    ax: 394,
    ax_global: 39400,
    b: 550,
    b_global: 550,
    d: 550,
    d_global: 550,
    ix: 290200,
    ix_global: 2902000000,
    iy: 183000,
    iy_global: 1830000000,
    iz: 183000,
    iz_global: 1830000000,
    t: 19,
    t_global: 19,
    zx: 7787,
    zx_global: 7787000,
    zy: 7787,
    zy_global: 7787000,
  },
  {
    profile_section_id: 1896,
    name: "TUB50050022.0",
    type: "Tube",
    designation: "TUB50050022.0",
    shape: "TUBE",
    country_code: "British",
    ax: 408,
    ax_global: 40800,
    b: 500,
    b_global: 500,
    d: 500,
    d_global: 500,
    ix: 246200,
    ix_global: 2462000000,
    iy: 153000,
    iy_global: 1530000000,
    iz: 153000,
    iz_global: 1530000000,
    t: 22,
    t_global: 22,
    zx: 7239,
    zx_global: 7239000,
    zy: 7239,
    zy_global: 7239000,
  },
  {
    profile_section_id: 1897,
    name: "TUB45045025.0",
    type: "Tube",
    designation: "TUB45045025.0",
    shape: "TUBE",
    country_code: "British",
    ax: 409,
    ax_global: 40900,
    b: 450,
    b_global: 450,
    d: 450,
    d_global: 450,
    ix: 197700,
    ix_global: 1977000000,
    iy: 120600,
    iy_global: 1206000000,
    iz: 120600,
    iz_global: 1206000000,
    t: 25,
    t_global: 25,
    zx: 6427,
    zx_global: 6427000,
    zy: 6427,
    zy_global: 6427000,
  },
  {
    profile_section_id: 1898,
    name: "TUB45045028.0",
    type: "Tube",
    designation: "TUB45045028.0",
    shape: "TUBE",
    country_code: "British",
    ax: 452,
    ax_global: 45200,
    b: 450,
    b_global: 450,
    d: 450,
    d_global: 450,
    ix: 217300,
    ix_global: 2173000000,
    iy: 131200,
    iy_global: 1312000000,
    iz: 131200,
    iz_global: 1312000000,
    t: 28,
    t_global: 28,
    zx: 7047,
    zx_global: 7047000,
    zy: 7047,
    zy_global: 7047000,
  },
  {
    profile_section_id: 1899,
    name: "TUB55055022.0",
    type: "Tube",
    designation: "TUB55055022.0",
    shape: "TUBE",
    country_code: "British",
    ax: 452,
    ax_global: 45200,
    b: 550,
    b_global: 550,
    d: 550,
    d_global: 550,
    ix: 331300,
    ix_global: 3313000000,
    iy: 207100,
    iy_global: 2071000000,
    iz: 207100,
    iz_global: 2071000000,
    t: 22,
    t_global: 22,
    zx: 8868,
    zx_global: 8868000,
    zy: 8868,
    zy_global: 8868000,
  },
  {
    profile_section_id: 1900,
    name: "TUB50050025.0",
    type: "Tube",
    designation: "TUB50050025.0",
    shape: "TUBE",
    country_code: "British",
    ax: 459,
    ax_global: 45900,
    b: 500,
    b_global: 500,
    d: 500,
    d_global: 500,
    ix: 275300,
    ix_global: 2753000000,
    iy: 169400,
    iy_global: 1694000000,
    iz: 169400,
    iz_global: 1694000000,
    t: 25,
    t_global: 25,
    zx: 8074,
    zx_global: 8074000,
    zy: 8074,
    zy_global: 8074000,
  },
  {
    profile_section_id: 1901,
    name: "TUB50050028.0",
    type: "Tube",
    designation: "TUB50050028.0",
    shape: "TUBE",
    country_code: "British",
    ax: 508,
    ax_global: 50800,
    b: 500,
    b_global: 500,
    d: 500,
    d_global: 500,
    ix: 303300,
    ix_global: 3033000000,
    iy: 184900,
    iy_global: 1849000000,
    iz: 184900,
    iz_global: 1849000000,
    t: 28,
    t_global: 28,
    zx: 8874,
    zx_global: 8874000,
    zy: 8874,
    zy_global: 8874000,
  },
  {
    profile_section_id: 1902,
    name: "TUB45045032.0",
    type: "Tube",
    designation: "TUB45045032.0",
    shape: "TUBE",
    country_code: "British",
    ax: 509,
    ax_global: 50900,
    b: 450,
    b_global: 450,
    d: 450,
    d_global: 450,
    ix: 242000,
    ix_global: 2420000000,
    iy: 144100,
    iy_global: 1441000000,
    iz: 144100,
    iz_global: 1441000000,
    t: 32,
    t_global: 32,
    zx: 7826,
    zx_global: 7826000,
    zy: 7826,
    zy_global: 7826000,
  },
  {
    profile_section_id: 1903,
    name: "TUB55055025.0",
    type: "Tube",
    designation: "TUB55055025.0",
    shape: "TUBE",
    country_code: "British",
    ax: 509,
    ax_global: 50900,
    b: 550,
    b_global: 550,
    d: 550,
    d_global: 550,
    ix: 371000,
    ix_global: 3710000000,
    iy: 230000,
    iy_global: 2300000000,
    iz: 230000,
    iz_global: 2300000000,
    t: 25,
    t_global: 25,
    zx: 9909,
    zx_global: 9909000,
    zy: 9909,
    zy_global: 9909000,
  },
  {
    profile_section_id: 1904,
    name: "TUB60060025.0",
    type: "Tube",
    designation: "TUB60060025.0",
    shape: "TUBE",
    country_code: "British",
    ax: 559,
    ax_global: 55900,
    b: 600,
    b_global: 600,
    d: 600,
    d_global: 600,
    ix: 486600,
    ix_global: 4866000000,
    iy: 303400,
    iy_global: 3034000000,
    iz: 303400,
    iz_global: 3034000000,
    t: 25,
    t_global: 25,
    zx: 11930,
    zx_global: 11930000,
    zy: 11930,
    zy_global: 11930000,
  },
  {
    profile_section_id: 1905,
    name: "TUB55055028.0",
    type: "Tube",
    designation: "TUB55055028.0",
    shape: "TUBE",
    country_code: "British",
    ax: 564,
    ax_global: 56400,
    b: 550,
    b_global: 550,
    d: 550,
    d_global: 550,
    ix: 409400,
    ix_global: 4094000000,
    iy: 251600,
    iy_global: 2516000000,
    iz: 251600,
    iz_global: 2516000000,
    t: 28,
    t_global: 28,
    zx: 10910,
    zx_global: 10910000,
    zy: 10910,
    zy_global: 10910000,
  },
  {
    profile_section_id: 1906,
    name: "TUB50050032.0",
    type: "Tube",
    designation: "TUB50050032.0",
    shape: "TUBE",
    country_code: "British",
    ax: 573,
    ax_global: 57300,
    b: 500,
    b_global: 500,
    d: 500,
    d_global: 500,
    ix: 338900,
    ix_global: 3389000000,
    iy: 204000,
    iy_global: 2040000000,
    iz: 204000,
    iz_global: 2040000000,
    t: 32,
    t_global: 32,
    zx: 9886,
    zx_global: 9886000,
    zy: 9886,
    zy_global: 9886000,
  },
  {
    profile_section_id: 1907,
    name: "TUB60060028.0",
    type: "Tube",
    designation: "TUB60060028.0",
    shape: "TUBE",
    country_code: "British",
    ax: 620,
    ax_global: 62000,
    b: 600,
    b_global: 600,
    d: 600,
    d_global: 600,
    ix: 537700,
    ix_global: 5377000000,
    iy: 332700,
    iy_global: 3327000000,
    iz: 332700,
    iz_global: 3327000000,
    t: 28,
    t_global: 28,
    zx: 13160,
    zx_global: 13160000,
    zy: 13160,
    zy_global: 13160000,
  },
  {
    profile_section_id: 1908,
    name: "TUB50050036.0",
    type: "Tube",
    designation: "TUB50050036.0",
    shape: "TUBE",
    country_code: "British",
    ax: 635,
    ax_global: 63500,
    b: 500,
    b_global: 500,
    d: 500,
    d_global: 500,
    ix: 372500,
    ix_global: 3725000000,
    iy: 221500,
    iy_global: 2215000000,
    iz: 221500,
    iz_global: 2215000000,
    t: 36,
    t_global: 36,
    zx: 10840,
    zx_global: 10840000,
    zy: 10840,
    zy_global: 10840000,
  },
  {
    profile_section_id: 1909,
    name: "TUB55055032.0",
    type: "Tube",
    designation: "TUB55055032.0",
    shape: "TUBE",
    country_code: "British",
    ax: 637,
    ax_global: 63700,
    b: 550,
    b_global: 550,
    d: 550,
    d_global: 550,
    ix: 458600,
    ix_global: 4586000000,
    iy: 278600,
    iy_global: 2786000000,
    iz: 278600,
    iz_global: 2786000000,
    t: 32,
    t_global: 32,
    zx: 12190,
    zx_global: 12190000,
    zy: 12190,
    zy_global: 12190000,
  },
  {
    profile_section_id: 1910,
    name: "TUB70070025.0",
    type: "Tube",
    designation: "TUB70070025.0",
    shape: "TUBE",
    country_code: "British",
    ax: 659,
    ax_global: 65900,
    b: 700,
    b_global: 700,
    d: 700,
    d_global: 700,
    ix: 784900,
    ix_global: 7849000000,
    iy: 494100,
    iy_global: 4941000000,
    iz: 494100,
    iz_global: 4941000000,
    t: 25,
    t_global: 25,
    zx: 16540,
    zx_global: 16540000,
    zy: 16540,
    zy_global: 16540000,
  },
  {
    profile_section_id: 1911,
    name: "TUB60060032.0",
    type: "Tube",
    designation: "TUB60060032.0",
    shape: "TUBE",
    country_code: "British",
    ax: 701,
    ax_global: 70100,
    b: 600,
    b_global: 600,
    d: 600,
    d_global: 600,
    ix: 603400,
    ix_global: 6034000000,
    iy: 369400,
    iy_global: 3694000000,
    iz: 369400,
    iz_global: 3694000000,
    t: 32,
    t_global: 32,
    zx: 14730,
    zx_global: 14730000,
    zy: 14730,
    zy_global: 14730000,
  },
  {
    profile_section_id: 1912,
    name: "TUB55055036.0",
    type: "Tube",
    designation: "TUB55055036.0",
    shape: "TUBE",
    country_code: "British",
    ax: 707,
    ax_global: 70700,
    b: 550,
    b_global: 550,
    d: 550,
    d_global: 550,
    ix: 505400,
    ix_global: 5054000000,
    iy: 303500,
    iy_global: 3035000000,
    iz: 303500,
    iz_global: 3035000000,
    t: 36,
    t_global: 36,
    zx: 13390,
    zx_global: 13390000,
    zy: 13390,
    zy_global: 13390000,
  },
  {
    profile_section_id: 1913,
    name: "TUB70070028.0",
    type: "Tube",
    designation: "TUB70070028.0",
    shape: "TUBE",
    country_code: "British",
    ax: 732,
    ax_global: 73200,
    b: 700,
    b_global: 700,
    d: 700,
    d_global: 700,
    ix: 869200,
    ix_global: 8692000000,
    iy: 543500,
    iy_global: 5435000000,
    iz: 543500,
    iz_global: 5435000000,
    t: 28,
    t_global: 28,
    zx: 18280,
    zx_global: 18280000,
    zy: 18280,
    zy_global: 18280000,
  },
  {
    profile_section_id: 1914,
    name: "TUB55055040.0",
    type: "Tube",
    designation: "TUB55055040.0",
    shape: "TUBE",
    country_code: "British",
    ax: 775,
    ax_global: 77500,
    b: 550,
    b_global: 550,
    d: 550,
    d_global: 550,
    ix: 549800,
    ix_global: 5498000000,
    iy: 326500,
    iy_global: 3265000000,
    iz: 326500,
    iz_global: 3265000000,
    t: 40,
    t_global: 40,
    zx: 14540,
    zx_global: 14540000,
    zy: 14540,
    zy_global: 14540000,
  },
  {
    profile_section_id: 1915,
    name: "TUB60060036.0",
    type: "Tube",
    designation: "TUB60060036.0",
    shape: "TUBE",
    country_code: "British",
    ax: 779,
    ax_global: 77900,
    b: 600,
    b_global: 600,
    d: 600,
    d_global: 600,
    ix: 666300,
    ix_global: 6663000000,
    iy: 403700,
    iy_global: 4037000000,
    iz: 403700,
    iz_global: 4037000000,
    t: 36,
    t_global: 36,
    zx: 16220,
    zx_global: 16220000,
    zy: 16220,
    zy_global: 16220000,
  },
  {
    profile_section_id: 1916,
    name: "TUB70070032.0",
    type: "Tube",
    designation: "TUB70070032.0",
    shape: "TUBE",
    country_code: "British",
    ax: 829,
    ax_global: 82900,
    b: 700,
    b_global: 700,
    d: 700,
    d_global: 700,
    ix: 978300,
    ix_global: 9783000000,
    iy: 606200,
    iy_global: 6062000000,
    iz: 606200,
    iz_global: 6062000000,
    t: 32,
    t_global: 32,
    zx: 20530,
    zx_global: 20530000,
    zy: 20530,
    zy_global: 20530000,
  },
  {
    profile_section_id: 1917,
    name: "TUB60060040.0",
    type: "Tube",
    designation: "TUB60060040.0",
    shape: "TUBE",
    country_code: "British",
    ax: 855,
    ax_global: 85500,
    b: 600,
    b_global: 600,
    d: 600,
    d_global: 600,
    ix: 726400,
    ix_global: 7264000000,
    iy: 435500,
    iy_global: 4355000000,
    iz: 435500,
    iz_global: 4355000000,
    t: 40,
    t_global: 40,
    zx: 17640,
    zx_global: 17640000,
    zy: 17640,
    zy_global: 17640000,
  },
  {
    profile_section_id: 1918,
    name: "TUB70070036.0",
    type: "Tube",
    designation: "TUB70070036.0",
    shape: "TUBE",
    country_code: "British",
    ax: 923,
    ax_global: 92300,
    b: 700,
    b_global: 700,
    d: 700,
    d_global: 700,
    ix: 1083700,
    ix_global: 10837000000,
    iy: 665400,
    iy_global: 6654000000,
    iz: 665400,
    iz_global: 6654000000,
    t: 36,
    t_global: 36,
    zx: 22690,
    zx_global: 22690000,
    zy: 22690,
    zy_global: 22690000,
  },
  {
    profile_section_id: 1919,
    name: "TUB70070040.0",
    type: "Tube",
    designation: "TUB70070040.0",
    shape: "TUBE",
    country_code: "British",
    ax: 1015,
    ax_global: 101500,
    b: 700,
    b_global: 700,
    d: 700,
    d_global: 700,
    ix: 1185200,
    ix_global: 11852000000,
    iy: 721200,
    iy_global: 7212000000,
    iz: 721200,
    iz_global: 7212000000,
    t: 40,
    t_global: 40,
    zx: 24760,
    zx_global: 24760000,
    zy: 24760,
    zy_global: 24760000,
  },
  {
    profile_section_id: 4274,
    name: "PIP1683.6",
    type: "Pipe",
    designation: "PIP1683.6",
    shape: "PIPE",
    country_code: "British",
    ax: "18,6",
    ax_global: 1860,
    i: 632,
    i_global: 6320000,
    od: "168,3",
    od_global: "168,3",
    tw: "3,6",
    tw_global: "3,6",
    z: "97,7",
    z_global: 97700,
  },
  {
    profile_section_id: 4275,
    name: "PIP1684.0",
    type: "Pipe",
    designation: "PIP1684.0",
    shape: "PIPE",
    country_code: "British",
    ax: "20,6",
    ax_global: 2060,
    i: 697,
    i_global: 6970000,
    od: "168,3",
    od_global: "168,3",
    tw: 4,
    tw_global: 4,
    z: 108,
    z_global: 108000,
  },
  {
    profile_section_id: 4276,
    name: "PIP1685.0",
    type: "Pipe",
    designation: "PIP1685.0",
    shape: "PIPE",
    country_code: "British",
    ax: "25,7",
    ax_global: 2570,
    i: 856,
    i_global: 8560000,
    od: "168,3",
    od_global: "168,3",
    tw: 5,
    tw_global: 5,
    z: 133,
    z_global: 133000,
  },
  {
    profile_section_id: 4277,
    name: "PIP1686.0",
    type: "Pipe",
    designation: "PIP1686.0",
    shape: "PIPE",
    country_code: "British",
    ax: "30,6",
    ax_global: 3060,
    i: 1009,
    i_global: 10090000,
    od: "168,3",
    od_global: "168,3",
    tw: 6,
    tw_global: 6,
    z: 158,
    z_global: 158000,
  },
  {
    profile_section_id: 4278,
    name: "PIP1686.3",
    type: "Pipe",
    designation: "PIP1686.3",
    shape: "PIPE",
    country_code: "British",
    ax: "32,1",
    ax_global: 3210,
    i: 1053,
    i_global: 10530000,
    od: "168,3",
    od_global: "168,3",
    tw: "6,3",
    tw_global: "6,3",
    z: 165,
    z_global: 165000,
  },
  {
    profile_section_id: 4279,
    name: "PIP1688.0",
    type: "Pipe",
    designation: "PIP1688.0",
    shape: "PIPE",
    country_code: "British",
    ax: "40,3",
    ax_global: 4030,
    i: 1297,
    i_global: 12970000,
    od: "168,3",
    od_global: "168,3",
    tw: 8,
    tw_global: 8,
    z: 206,
    z_global: 206000,
  },
  {
    profile_section_id: 4280,
    name: "PIP19310.0",
    type: "Pipe",
    designation: "PIP19310.0",
    shape: "PIPE",
    country_code: "British",
    ax: "57,7",
    ax_global: 5770,
    i: 2442,
    i_global: 24420000,
    od: "193,7",
    od_global: "193,7",
    tw: 10,
    tw_global: 10,
    z: 338,
    z_global: 338000,
  },
  {
    profile_section_id: 4281,
    name: "PIP19312.0",
    type: "Pipe",
    designation: "PIP19312.0",
    shape: "PIPE",
    country_code: "British",
    ax: "68,5",
    ax_global: 6850,
    i: 2839,
    i_global: 28390000,
    od: "193,7",
    od_global: "193,7",
    tw: 12,
    tw_global: 12,
    z: 397,
    z_global: 397000,
  },
  {
    profile_section_id: 4282,
    name: "PIP19312.5",
    type: "Pipe",
    designation: "PIP19312.5",
    shape: "PIPE",
    country_code: "British",
    ax: "71,2",
    ax_global: 7120,
    i: 2934,
    i_global: 29340000,
    od: "193,7",
    od_global: "193,7",
    tw: "12,5",
    tw_global: "12,5",
    z: 411,
    z_global: 411000,
  },
  {
    profile_section_id: 4283,
    name: "PIP19316.0",
    type: "Pipe",
    designation: "PIP19316.0",
    shape: "PIPE",
    country_code: "British",
    ax: "89,3",
    ax_global: 8930,
    i: 3554,
    i_global: 35540000,
    od: "193,7",
    od_global: "193,7",
    tw: 16,
    tw_global: 16,
    z: 507,
    z_global: 507000,
  },
  {
    profile_section_id: 4284,
    name: "PIP1935.0",
    type: "Pipe",
    designation: "PIP1935.0",
    shape: "PIPE",
    country_code: "British",
    ax: "29,6",
    ax_global: 2960,
    i: 1320,
    i_global: 13200000,
    od: "193,7",
    od_global: "193,7",
    tw: 5,
    tw_global: 5,
    z: 178,
    z_global: 178000,
  },
  {
    profile_section_id: 4285,
    name: "PIP1936.0",
    type: "Pipe",
    designation: "PIP1936.0",
    shape: "PIPE",
    country_code: "British",
    ax: "35,4",
    ax_global: 3540,
    i: 1560,
    i_global: 15600000,
    od: "193,7",
    od_global: "193,7",
    tw: 6,
    tw_global: 6,
    z: 211,
    z_global: 211000,
  },
  {
    profile_section_id: 4286,
    name: "PIP1936.3",
    type: "Pipe",
    designation: "PIP1936.3",
    shape: "PIPE",
    country_code: "British",
    ax: "37,1",
    ax_global: 3710,
    i: 1630,
    i_global: 16300000,
    od: "193,7",
    od_global: "193,7",
    tw: "6,3",
    tw_global: "6,3",
    z: 221,
    z_global: 221000,
  },
  {
    profile_section_id: 4287,
    name: "PIP1938.0",
    type: "Pipe",
    designation: "PIP1938.0",
    shape: "PIPE",
    country_code: "British",
    ax: "46,7",
    ax_global: 4670,
    i: 2016,
    i_global: 20160000,
    od: "193,7",
    od_global: "193,7",
    tw: 8,
    tw_global: 8,
    z: 276,
    z_global: 276000,
  },
  {
    profile_section_id: 4288,
    name: "PIP213.2",
    type: "Pipe",
    designation: "PIP213.2",
    shape: "PIPE",
    country_code: "British",
    ax: "1,82",
    ax_global: 182,
    i: "0,77",
    i_global: 7700,
    od: "21,3",
    od_global: "21,3",
    tw: "3,2",
    tw_global: "3,2",
    z: "1,06",
    z_global: 1060,
  },
  {
    profile_section_id: 4289,
    name: "PIP21910.0",
    type: "Pipe",
    designation: "PIP21910.0",
    shape: "PIPE",
    country_code: "British",
    ax: "65,7",
    ax_global: 6570,
    i: 3598,
    i_global: 35980000,
    od: "219,1",
    od_global: "219,1",
    tw: 10,
    tw_global: 10,
    z: 438,
    z_global: 438000,
  },
  {
    profile_section_id: 4290,
    name: "PIP21912.0",
    type: "Pipe",
    designation: "PIP21912.0",
    shape: "PIPE",
    country_code: "British",
    ax: "78,1",
    ax_global: 7810,
    i: 4200,
    i_global: 42000000,
    od: "219,1",
    od_global: "219,1",
    tw: 12,
    tw_global: 12,
    z: 515,
    z_global: 515000,
  },
  {
    profile_section_id: 4291,
    name: "PIP21912.5",
    type: "Pipe",
    designation: "PIP21912.5",
    shape: "PIPE",
    country_code: "British",
    ax: "81,1",
    ax_global: 8110,
    i: 4345,
    i_global: 43450000,
    od: "219,1",
    od_global: "219,1",
    tw: "12,5",
    tw_global: "12,5",
    z: 534,
    z_global: 534000,
  },
  {
    profile_section_id: 4292,
    name: "PIP21916.0",
    type: "Pipe",
    designation: "PIP21916.0",
    shape: "PIPE",
    country_code: "British",
    ax: 102,
    ax_global: 10200,
    i: 5297,
    i_global: 52970000,
    od: "219,1",
    od_global: "219,1",
    tw: 16,
    tw_global: 16,
    z: 661,
    z_global: 661000,
  },
  {
    profile_section_id: 4293,
    name: "PIP21920.0",
    type: "Pipe",
    designation: "PIP21920.0",
    shape: "PIPE",
    country_code: "British",
    ax: 125,
    ax_global: 12500,
    i: 6261,
    i_global: 62610000,
    od: "219,1",
    od_global: "219,1",
    tw: 20,
    tw_global: 20,
    z: 795,
    z_global: 795000,
  },
  {
    profile_section_id: 4294,
    name: "PIP2195.0",
    type: "Pipe",
    designation: "PIP2195.0",
    shape: "PIPE",
    country_code: "British",
    ax: "33,6",
    ax_global: 3360,
    i: 1928,
    i_global: 19280000,
    od: "219,1",
    od_global: "219,1",
    tw: 5,
    tw_global: 5,
    z: 229,
    z_global: 229000,
  },
  {
    profile_section_id: 4295,
    name: "PIP2196.0",
    type: "Pipe",
    designation: "PIP2196.0",
    shape: "PIPE",
    country_code: "British",
    ax: "40,2",
    ax_global: 4020,
    i: 2282,
    i_global: 22820000,
    od: "219,1",
    od_global: "219,1",
    tw: 6,
    tw_global: 6,
    z: 273,
    z_global: 273000,
  },
  {
    profile_section_id: 4296,
    name: "PIP2196.3",
    type: "Pipe",
    designation: "PIP2196.3",
    shape: "PIPE",
    country_code: "British",
    ax: "42,1",
    ax_global: 4210,
    i: 2386,
    i_global: 23860000,
    od: "219,1",
    od_global: "219,1",
    tw: "6,3",
    tw_global: "6,3",
    z: 285,
    z_global: 285000,
  },
  {
    profile_section_id: 4297,
    name: "PIP2198.0",
    type: "Pipe",
    designation: "PIP2198.0",
    shape: "PIPE",
    country_code: "British",
    ax: "53,1",
    ax_global: 5310,
    i: 2960,
    i_global: 29600000,
    od: "219,1",
    od_global: "219,1",
    tw: 8,
    tw_global: 8,
    z: 357,
    z_global: 357000,
  },
  {
    profile_section_id: 4298,
    name: "PIP24410.0",
    type: "Pipe",
    designation: "PIP24410.0",
    shape: "PIPE",
    country_code: "British",
    ax: "73,7",
    ax_global: 7370,
    i: 5073,
    i_global: 50730000,
    od: "244,5",
    od_global: "244,5",
    tw: 10,
    tw_global: 10,
    z: 550,
    z_global: 550000,
  },
  {
    profile_section_id: 4299,
    name: "PIP24412.0",
    type: "Pipe",
    designation: "PIP24412.0",
    shape: "PIPE",
    country_code: "British",
    ax: "87,7",
    ax_global: 8770,
    i: 5938,
    i_global: 59380000,
    od: "244,5",
    od_global: "244,5",
    tw: 12,
    tw_global: 12,
    z: 649,
    z_global: 649000,
  },
  {
    profile_section_id: 4300,
    name: "PIP24412.5",
    type: "Pipe",
    designation: "PIP24412.5",
    shape: "PIPE",
    country_code: "British",
    ax: "91,1",
    ax_global: 9110,
    i: 6147,
    i_global: 61470000,
    od: "244,5",
    od_global: "244,5",
    tw: "12,5",
    tw_global: "12,5",
    z: 673,
    z_global: 673000,
  },
  {
    profile_section_id: 4301,
    name: "PIP24416.0",
    type: "Pipe",
    designation: "PIP24416.0",
    shape: "PIPE",
    country_code: "British",
    ax: 115,
    ax_global: 11500,
    i: 7533,
    i_global: 75330000,
    od: "244,5",
    od_global: "244,5",
    tw: 16,
    tw_global: 16,
    z: 837,
    z_global: 837000,
  },
  {
    profile_section_id: 4302,
    name: "PIP24420.0",
    type: "Pipe",
    designation: "PIP24420.0",
    shape: "PIPE",
    country_code: "British",
    ax: 141,
    ax_global: 14100,
    i: 8957,
    i_global: 89570000,
    od: "244,5",
    od_global: "244,5",
    tw: 20,
    tw_global: 20,
    z: 1011,
    z_global: 1011000,
  },
  {
    profile_section_id: 4303,
    name: "PIP24425.0",
    type: "Pipe",
    designation: "PIP24425.0",
    shape: "PIPE",
    country_code: "British",
    ax: 172,
    ax_global: 17200,
    i: 10520,
    i_global: 105200000,
    od: "244,5",
    od_global: "244,5",
    tw: 25,
    tw_global: 25,
    z: 1210,
    z_global: 1210000,
  },
  {
    profile_section_id: 4304,
    name: "PIP2445.0",
    type: "Pipe",
    designation: "PIP2445.0",
    shape: "PIPE",
    country_code: "British",
    ax: "37,6",
    ax_global: 3760,
    i: 2699,
    i_global: 26990000,
    od: "244,5",
    od_global: "244,5",
    tw: 5,
    tw_global: 5,
    z: 287,
    z_global: 287000,
  },
  {
    profile_section_id: 4305,
    name: "PIP2446.0",
    type: "Pipe",
    designation: "PIP2446.0",
    shape: "PIPE",
    country_code: "British",
    ax: 45,
    ax_global: 4500,
    i: 3199,
    i_global: 31990000,
    od: "244,5",
    od_global: "244,5",
    tw: 6,
    tw_global: 6,
    z: 341,
    z_global: 341000,
  },
  {
    profile_section_id: 4306,
    name: "PIP2446.3",
    type: "Pipe",
    designation: "PIP2446.3",
    shape: "PIPE",
    country_code: "British",
    ax: "47,1",
    ax_global: 4710,
    i: 3346,
    i_global: 33460000,
    od: "244,5",
    od_global: "244,5",
    tw: "6,3",
    tw_global: "6,3",
    z: 358,
    z_global: 358000,
  },
  {
    profile_section_id: 4307,
    name: "PIP2448.0",
    type: "Pipe",
    designation: "PIP2448.0",
    shape: "PIPE",
    country_code: "British",
    ax: "59,4",
    ax_global: 5940,
    i: 4160,
    i_global: 41600000,
    od: "244,5",
    od_global: "244,5",
    tw: 8,
    tw_global: 8,
    z: 448,
    z_global: 448000,
  },
  {
    profile_section_id: 4308,
    name: "PIP263.2",
    type: "Pipe",
    designation: "PIP263.2",
    shape: "PIPE",
    country_code: "British",
    ax: "2,38",
    ax_global: 238,
    i: "1,7",
    i_global: 17000,
    od: "26,9",
    od_global: "26,9",
    tw: "3,2",
    tw_global: "3,2",
    z: "1,81",
    z_global: 1810,
  },
  {
    profile_section_id: 4309,
    name: "PIP27310.0",
    type: "Pipe",
    designation: "PIP27310.0",
    shape: "PIPE",
    country_code: "British",
    ax: "82,6",
    ax_global: 8260,
    i: 7154,
    i_global: 71540000,
    od: 273,
    od_global: 273,
    tw: 10,
    tw_global: 10,
    z: 692,
    z_global: 692000,
  },
  {
    profile_section_id: 4310,
    name: "PIP27312.0",
    type: "Pipe",
    designation: "PIP27312.0",
    shape: "PIPE",
    country_code: "British",
    ax: "98,4",
    ax_global: 9840,
    i: 8396,
    i_global: 83960000,
    od: 273,
    od_global: 273,
    tw: 12,
    tw_global: 12,
    z: 818,
    z_global: 818000,
  },
  {
    profile_section_id: 4311,
    name: "PIP27312.5",
    type: "Pipe",
    designation: "PIP27312.5",
    shape: "PIPE",
    country_code: "British",
    ax: 102,
    ax_global: 10200,
    i: 8697,
    i_global: 86970000,
    od: 273,
    od_global: 273,
    tw: "12,5",
    tw_global: "12,5",
    z: 849,
    z_global: 849000,
  },
  {
    profile_section_id: 4312,
    name: "PIP27316.0",
    type: "Pipe",
    designation: "PIP27316.0",
    shape: "PIPE",
    country_code: "British",
    ax: 129,
    ax_global: 12900,
    i: 10710,
    i_global: 107100000,
    od: 273,
    od_global: 273,
    tw: 16,
    tw_global: 16,
    z: 1058,
    z_global: 1058000,
  },
  {
    profile_section_id: 4313,
    name: "PIP27320.0",
    type: "Pipe",
    designation: "PIP27320.0",
    shape: "PIPE",
    country_code: "British",
    ax: 159,
    ax_global: 15900,
    i: 12800,
    i_global: 128000000,
    od: 273,
    od_global: 273,
    tw: 20,
    tw_global: 20,
    z: 1283,
    z_global: 1283000,
  },
  {
    profile_section_id: 4314,
    name: "PIP27325.0",
    type: "Pipe",
    designation: "PIP27325.0",
    shape: "PIPE",
    country_code: "British",
    ax: 195,
    ax_global: 19500,
    i: 15130,
    i_global: 151300000,
    od: 273,
    od_global: 273,
    tw: 25,
    tw_global: 25,
    z: 1543,
    z_global: 1543000,
  },
  {
    profile_section_id: 4315,
    name: "PIP2735.0",
    type: "Pipe",
    designation: "PIP2735.0",
    shape: "PIPE",
    country_code: "British",
    ax: "42,1",
    ax_global: 4210,
    i: 3781,
    i_global: 37810000,
    od: 273,
    od_global: 273,
    tw: 5,
    tw_global: 5,
    z: 359,
    z_global: 359000,
  },
  {
    profile_section_id: 4316,
    name: "PIP2736.0",
    type: "Pipe",
    designation: "PIP2736.0",
    shape: "PIPE",
    country_code: "British",
    ax: "50,3",
    ax_global: 5030,
    i: 4487,
    i_global: 44870000,
    od: 273,
    od_global: 273,
    tw: 6,
    tw_global: 6,
    z: 428,
    z_global: 428000,
  },
  {
    profile_section_id: 4317,
    name: "PIP2736.3",
    type: "Pipe",
    designation: "PIP2736.3",
    shape: "PIPE",
    country_code: "British",
    ax: "52,8",
    ax_global: 5280,
    i: 4696,
    i_global: 46960000,
    od: 273,
    od_global: 273,
    tw: "6,3",
    tw_global: "6,3",
    z: 448,
    z_global: 448000,
  },
  {
    profile_section_id: 4318,
    name: "PIP2738.0",
    type: "Pipe",
    designation: "PIP2738.0",
    shape: "PIPE",
    country_code: "British",
    ax: "66,6",
    ax_global: 6660,
    i: 5852,
    i_global: 58520000,
    od: 273,
    od_global: 273,
    tw: 8,
    tw_global: 8,
    z: 562,
    z_global: 562000,
  },
  {
    profile_section_id: 4319,
    name: "PIP32310.0",
    type: "Pipe",
    designation: "PIP32310.0",
    shape: "PIPE",
    country_code: "British",
    ax: "98,6",
    ax_global: 9860,
    i: 12160,
    i_global: 121600000,
    od: "323,9",
    od_global: "323,9",
    tw: 10,
    tw_global: 10,
    z: 986,
    z_global: 986000,
  },
  {
    profile_section_id: 4320,
    name: "PIP32312.0",
    type: "Pipe",
    designation: "PIP32312.0",
    shape: "PIPE",
    country_code: "British",
    ax: 118,
    ax_global: 11800,
    i: 14320,
    i_global: 143200000,
    od: "323,9",
    od_global: "323,9",
    tw: 12,
    tw_global: 12,
    z: 1168,
    z_global: 1168000,
  },
  {
    profile_section_id: 4321,
    name: "PIP32312.5",
    type: "Pipe",
    designation: "PIP32312.5",
    shape: "PIPE",
    country_code: "British",
    ax: 122,
    ax_global: 12200,
    i: 14850,
    i_global: 148500000,
    od: "323,9",
    od_global: "323,9",
    tw: "12,5",
    tw_global: "12,5",
    z: 1213,
    z_global: 1213000,
  },
  {
    profile_section_id: 4322,
    name: "PIP32316.0",
    type: "Pipe",
    designation: "PIP32316.0",
    shape: "PIPE",
    country_code: "British",
    ax: 155,
    ax_global: 15500,
    i: 18390,
    i_global: 183900000,
    od: "323,9",
    od_global: "323,9",
    tw: 16,
    tw_global: 16,
    z: 1518,
    z_global: 1518000,
  },
  {
    profile_section_id: 4323,
    name: "PIP32320.0",
    type: "Pipe",
    designation: "PIP32320.0",
    shape: "PIPE",
    country_code: "British",
    ax: 191,
    ax_global: 19100,
    i: 22140,
    i_global: 221400000,
    od: "323,9",
    od_global: "323,9",
    tw: 20,
    tw_global: 20,
    z: 1850,
    z_global: 1850000,
  },
  {
    profile_section_id: 4324,
    name: "PIP32325.0",
    type: "Pipe",
    designation: "PIP32325.0",
    shape: "PIPE",
    country_code: "British",
    ax: 235,
    ax_global: 23500,
    i: 26400,
    i_global: 264000000,
    od: "323,9",
    od_global: "323,9",
    tw: 25,
    tw_global: 25,
    z: 2239,
    z_global: 2239000,
  },
  {
    profile_section_id: 4325,
    name: "PIP3235.0",
    type: "Pipe",
    designation: "PIP3235.0",
    shape: "PIPE",
    country_code: "British",
    ax: "50,1",
    ax_global: 5010,
    i: 6369,
    i_global: 63690000,
    od: "323,9",
    od_global: "323,9",
    tw: 5,
    tw_global: 5,
    z: 509,
    z_global: 509000,
  },
  {
    profile_section_id: 4326,
    name: "PIP3236.0",
    type: "Pipe",
    designation: "PIP3236.0",
    shape: "PIPE",
    country_code: "British",
    ax: "59,9",
    ax_global: 5990,
    i: 7572,
    i_global: 75720000,
    od: "323,9",
    od_global: "323,9",
    tw: 6,
    tw_global: 6,
    z: 606,
    z_global: 606000,
  },
  {
    profile_section_id: 4327,
    name: "PIP3236.3",
    type: "Pipe",
    designation: "PIP3236.3",
    shape: "PIPE",
    country_code: "British",
    ax: "62,9",
    ax_global: 6290,
    i: 7929,
    i_global: 79290000,
    od: "323,9",
    od_global: "323,9",
    tw: "6,3",
    tw_global: "6,3",
    z: 636,
    z_global: 636000,
  },
  {
    profile_section_id: 4328,
    name: "PIP3238.0",
    type: "Pipe",
    designation: "PIP3238.0",
    shape: "PIPE",
    country_code: "British",
    ax: "79,4",
    ax_global: 7940,
    i: 9910,
    i_global: 99100000,
    od: "323,9",
    od_global: "323,9",
    tw: 8,
    tw_global: 8,
    z: 799,
    z_global: 799000,
  },
  {
    profile_section_id: 4329,
    name: "PIP332.6",
    type: "Pipe",
    designation: "PIP332.6",
    shape: "PIPE",
    country_code: "British",
    ax: "2,54",
    ax_global: 254,
    i: "3,09",
    i_global: 30900,
    od: "33,7",
    od_global: "33,7",
    tw: "2,6",
    tw_global: "2,6",
    z: "2,52",
    z_global: 2520,
  },
  {
    profile_section_id: 4330,
    name: "PIP333.0",
    type: "Pipe",
    designation: "PIP333.0",
    shape: "PIPE",
    country_code: "British",
    ax: "2,89",
    ax_global: 289,
    i: "3,44",
    i_global: 34400,
    od: "33,7",
    od_global: "33,7",
    tw: 3,
    tw_global: 3,
    z: "2,84",
    z_global: 2840,
  },
  {
    profile_section_id: 4331,
    name: "PIP333.2",
    type: "Pipe",
    designation: "PIP333.2",
    shape: "PIPE",
    country_code: "British",
    ax: "3,07",
    ax_global: 307,
    i: "3,6",
    i_global: 36000,
    od: "33,7",
    od_global: "33,7",
    tw: "3,2",
    tw_global: "3,2",
    z: "2,99",
    z_global: 2990,
  },
  {
    profile_section_id: 4332,
    name: "PIP333.6",
    type: "Pipe",
    designation: "PIP333.6",
    shape: "PIPE",
    country_code: "British",
    ax: "3,4",
    ax_global: 340,
    i: "3,91",
    i_global: 39100,
    od: "33,7",
    od_global: "33,7",
    tw: "3,6",
    tw_global: "3,6",
    z: "3,28",
    z_global: 3280,
  },
  {
    profile_section_id: 4333,
    name: "PIP334.0",
    type: "Pipe",
    designation: "PIP334.0",
    shape: "PIPE",
    country_code: "British",
    ax: "3,73",
    ax_global: 373,
    i: "4,19",
    i_global: 41900,
    od: "33,7",
    od_global: "33,7",
    tw: 4,
    tw_global: 4,
    z: "3,55",
    z_global: 3550,
  },
  {
    profile_section_id: 4334,
    name: "PIP35510.0",
    type: "Pipe",
    designation: "PIP35510.0",
    shape: "PIPE",
    country_code: "British",
    ax: 109,
    ax_global: 10900,
    i: 16220,
    i_global: 162200000,
    od: "355,6",
    od_global: "355,6",
    tw: 10,
    tw_global: 10,
    z: 1195,
    z_global: 1195000,
  },
  {
    profile_section_id: 4335,
    name: "PIP35512.0",
    type: "Pipe",
    designation: "PIP35512.0",
    shape: "PIPE",
    country_code: "British",
    ax: 130,
    ax_global: 13000,
    i: 19140,
    i_global: 191400000,
    od: "355,6",
    od_global: "355,6",
    tw: 12,
    tw_global: 12,
    z: 1417,
    z_global: 1417000,
  },
  {
    profile_section_id: 4336,
    name: "PIP35512.5",
    type: "Pipe",
    designation: "PIP35512.5",
    shape: "PIPE",
    country_code: "British",
    ax: 135,
    ax_global: 13500,
    i: 19850,
    i_global: 198500000,
    od: "355,6",
    od_global: "355,6",
    tw: "12,5",
    tw_global: "12,5",
    z: 1472,
    z_global: 1472000,
  },
  {
    profile_section_id: 4337,
    name: "PIP35516.0",
    type: "Pipe",
    designation: "PIP35516.0",
    shape: "PIPE",
    country_code: "British",
    ax: 171,
    ax_global: 17100,
    i: 24660,
    i_global: 246600000,
    od: "355,6",
    od_global: "355,6",
    tw: 16,
    tw_global: 16,
    z: 1847,
    z_global: 1847000,
  },
  {
    profile_section_id: 4338,
    name: "PIP35520.0",
    type: "Pipe",
    designation: "PIP35520.0",
    shape: "PIPE",
    country_code: "British",
    ax: 211,
    ax_global: 21100,
    i: 29790,
    i_global: 297900000,
    od: "355,6",
    od_global: "355,6",
    tw: 20,
    tw_global: 20,
    z: 2255,
    z_global: 2255000,
  },
  {
    profile_section_id: 4339,
    name: "PIP35525.0",
    type: "Pipe",
    designation: "PIP35525.0",
    shape: "PIPE",
    country_code: "British",
    ax: 260,
    ax_global: 26000,
    i: 35680,
    i_global: 356800000,
    od: "355,6",
    od_global: "355,6",
    tw: 25,
    tw_global: 25,
    z: 2738,
    z_global: 2738000,
  },
  {
    profile_section_id: 4340,
    name: "PIP3556.3",
    type: "Pipe",
    designation: "PIP3556.3",
    shape: "PIPE",
    country_code: "British",
    ax: "69,1",
    ax_global: 6910,
    i: 10550,
    i_global: 105500000,
    od: "355,6",
    od_global: "355,6",
    tw: "6,3",
    tw_global: "6,3",
    z: 769,
    z_global: 769000,
  },
  {
    profile_section_id: 4341,
    name: "PIP3558.0",
    type: "Pipe",
    designation: "PIP3558.0",
    shape: "PIPE",
    country_code: "British",
    ax: "87,4",
    ax_global: 8740,
    i: 13200,
    i_global: 132000000,
    od: "355,6",
    od_global: "355,6",
    tw: 8,
    tw_global: 8,
    z: 967,
    z_global: 967000,
  },
  {
    profile_section_id: 4342,
    name: "PIP40610.0",
    type: "Pipe",
    designation: "PIP40610.0",
    shape: "PIPE",
    country_code: "British",
    ax: 125,
    ax_global: 12500,
    i: 24480,
    i_global: 244800000,
    od: "406,4",
    od_global: "406,4",
    tw: 10,
    tw_global: 10,
    z: 1572,
    z_global: 1572000,
  },
  {
    profile_section_id: 4343,
    name: "PIP40612.0",
    type: "Pipe",
    designation: "PIP40612.0",
    shape: "PIPE",
    country_code: "British",
    ax: 149,
    ax_global: 14900,
    i: 28940,
    i_global: 289400000,
    od: "406,4",
    od_global: "406,4",
    tw: 12,
    tw_global: 12,
    z: 1867,
    z_global: 1867000,
  },
  {
    profile_section_id: 4344,
    name: "PIP40612.5",
    type: "Pipe",
    designation: "PIP40612.5",
    shape: "PIPE",
    country_code: "British",
    ax: 155,
    ax_global: 15500,
    i: 30030,
    i_global: 300300000,
    od: "406,4",
    od_global: "406,4",
    tw: "12,5",
    tw_global: "12,5",
    z: 1940,
    z_global: 1940000,
  },
  {
    profile_section_id: 4345,
    name: "PIP40616.0",
    type: "Pipe",
    designation: "PIP40616.0",
    shape: "PIPE",
    country_code: "British",
    ax: 196,
    ax_global: 19600,
    i: 37450,
    i_global: 374500000,
    od: "406,4",
    od_global: "406,4",
    tw: 16,
    tw_global: 16,
    z: 2440,
    z_global: 2440000,
  },
  {
    profile_section_id: 4346,
    name: "PIP40620.0",
    type: "Pipe",
    designation: "PIP40620.0",
    shape: "PIPE",
    country_code: "British",
    ax: 243,
    ax_global: 24300,
    i: 45430,
    i_global: 454300000,
    od: "406,4",
    od_global: "406,4",
    tw: 20,
    tw_global: 20,
    z: 2989,
    z_global: 2989000,
  },
  {
    profile_section_id: 4347,
    name: "PIP40625.0",
    type: "Pipe",
    designation: "PIP40625.0",
    shape: "PIPE",
    country_code: "British",
    ax: 300,
    ax_global: 30000,
    i: 54700,
    i_global: 547000000,
    od: "406,4",
    od_global: "406,4",
    tw: 25,
    tw_global: 25,
    z: 3642,
    z_global: 3642000,
  },
  {
    profile_section_id: 4348,
    name: "PIP40632.0",
    type: "Pipe",
    designation: "PIP40632.0",
    shape: "PIPE",
    country_code: "British",
    ax: 376,
    ax_global: 37600,
    i: 66430,
    i_global: 664300000,
    od: "406,4",
    od_global: "406,4",
    tw: 32,
    tw_global: 32,
    z: 4497,
    z_global: 4497000,
  },
  {
    profile_section_id: 4349,
    name: "PIP4066.3",
    type: "Pipe",
    designation: "PIP4066.3",
    shape: "PIPE",
    country_code: "British",
    ax: "79,2",
    ax_global: 7920,
    i: 15850,
    i_global: 158500000,
    od: "406,4",
    od_global: "406,4",
    tw: "6,3",
    tw_global: "6,3",
    z: 1009,
    z_global: 1009000,
  },
  {
    profile_section_id: 4350,
    name: "PIP4068.0",
    type: "Pipe",
    designation: "PIP4068.0",
    shape: "PIPE",
    country_code: "British",
    ax: 100,
    ax_global: 10000,
    i: 19870,
    i_global: 198700000,
    od: "406,4",
    od_global: "406,4",
    tw: 8,
    tw_global: 8,
    z: 1270,
    z_global: 1270000,
  },
  {
    profile_section_id: 4351,
    name: "PIP422.6",
    type: "Pipe",
    designation: "PIP422.6",
    shape: "PIPE",
    country_code: "British",
    ax: "3,25",
    ax_global: 325,
    i: "6,46",
    i_global: 64600,
    od: "42,4",
    od_global: "42,4",
    tw: "2,6",
    tw_global: "2,6",
    z: "4,12",
    z_global: 4120,
  },
  {
    profile_section_id: 4352,
    name: "PIP423.0",
    type: "Pipe",
    designation: "PIP423.0",
    shape: "PIPE",
    country_code: "British",
    ax: "3,71",
    ax_global: 371,
    i: "7,25",
    i_global: 72500,
    od: "42,4",
    od_global: "42,4",
    tw: 3,
    tw_global: 3,
    z: "4,67",
    z_global: 4670,
  },
  {
    profile_section_id: 4353,
    name: "PIP423.2",
    type: "Pipe",
    designation: "PIP423.2",
    shape: "PIPE",
    country_code: "British",
    ax: "3,94",
    ax_global: 394,
    i: "7,62",
    i_global: 76200,
    od: "42,4",
    od_global: "42,4",
    tw: "3,2",
    tw_global: "3,2",
    z: "4,93",
    z_global: 4930,
  },
  {
    profile_section_id: 4354,
    name: "PIP423.6",
    type: "Pipe",
    designation: "PIP423.6",
    shape: "PIPE",
    country_code: "British",
    ax: "4,39",
    ax_global: 439,
    i: "8,33",
    i_global: 83300,
    od: "42,4",
    od_global: "42,4",
    tw: "3,6",
    tw_global: "3,6",
    z: "5,44",
    z_global: 5440,
  },
  {
    profile_section_id: 4355,
    name: "PIP424.0",
    type: "Pipe",
    designation: "PIP424.0",
    shape: "PIPE",
    country_code: "British",
    ax: "4,83",
    ax_global: 483,
    i: "8,99",
    i_global: 89900,
    od: "42,4",
    od_global: "42,4",
    tw: 4,
    tw_global: 4,
    z: "5,92",
    z_global: 5920,
  },
  {
    profile_section_id: 4356,
    name: "PIP45710.0",
    type: "Pipe",
    designation: "PIP45710.0",
    shape: "PIPE",
    country_code: "British",
    ax: 140,
    ax_global: 14000,
    i: 35090,
    i_global: 350900000,
    od: 457,
    od_global: 457,
    tw: 10,
    tw_global: 10,
    z: 1998,
    z_global: 1998000,
  },
  {
    profile_section_id: 4357,
    name: "PIP45712.0",
    type: "Pipe",
    designation: "PIP45712.0",
    shape: "PIPE",
    country_code: "British",
    ax: 168,
    ax_global: 16800,
    i: 41560,
    i_global: 415600000,
    od: 457,
    od_global: 457,
    tw: 12,
    tw_global: 12,
    z: 2377,
    z_global: 2377000,
  },
  {
    profile_section_id: 4358,
    name: "PIP45712.5",
    type: "Pipe",
    designation: "PIP45712.5",
    shape: "PIPE",
    country_code: "British",
    ax: 175,
    ax_global: 17500,
    i: 43140,
    i_global: 431400000,
    od: 457,
    od_global: 457,
    tw: "12,5",
    tw_global: "12,5",
    z: 2470,
    z_global: 2470000,
  },
  {
    profile_section_id: 4359,
    name: "PIP45716.0",
    type: "Pipe",
    designation: "PIP45716.0",
    shape: "PIPE",
    country_code: "British",
    ax: 222,
    ax_global: 22200,
    i: 53960,
    i_global: 539600000,
    od: 457,
    od_global: 457,
    tw: 16,
    tw_global: 16,
    z: 3113,
    z_global: 3113000,
  },
  {
    profile_section_id: 4360,
    name: "PIP45720.0",
    type: "Pipe",
    designation: "PIP45720.0",
    shape: "PIPE",
    country_code: "British",
    ax: 275,
    ax_global: 27500,
    i: 65680,
    i_global: 656800000,
    od: 457,
    od_global: 457,
    tw: 20,
    tw_global: 20,
    z: 3822,
    z_global: 3822000,
  },
  {
    profile_section_id: 4361,
    name: "PIP45725.0",
    type: "Pipe",
    designation: "PIP45725.0",
    shape: "PIPE",
    country_code: "British",
    ax: 339,
    ax_global: 33900,
    i: 79420,
    i_global: 794200000,
    od: 457,
    od_global: 457,
    tw: 25,
    tw_global: 25,
    z: 4671,
    z_global: 4671000,
  },
  {
    profile_section_id: 4362,
    name: "PIP45732.0",
    type: "Pipe",
    designation: "PIP45732.0",
    shape: "PIPE",
    country_code: "British",
    ax: 427,
    ax_global: 42700,
    i: 97010,
    i_global: 970100000,
    od: 457,
    od_global: 457,
    tw: 32,
    tw_global: 32,
    z: 5791,
    z_global: 5791000,
  },
  {
    profile_section_id: 4363,
    name: "PIP45740.0",
    type: "Pipe",
    designation: "PIP45740.0",
    shape: "PIPE",
    country_code: "British",
    ax: 524,
    ax_global: 52400,
    i: 114900,
    i_global: 1149000000,
    od: 457,
    od_global: 457,
    tw: 40,
    tw_global: 40,
    z: 6977,
    z_global: 6977000,
  },
  {
    profile_section_id: 4364,
    name: "PIP4576.3",
    type: "Pipe",
    designation: "PIP4576.3",
    shape: "PIPE",
    country_code: "British",
    ax: "89,2",
    ax_global: 8920,
    i: 22650,
    i_global: 226500000,
    od: 457,
    od_global: 457,
    tw: "6,3",
    tw_global: "6,3",
    z: 1280,
    z_global: 1280000,
  },
  {
    profile_section_id: 4365,
    name: "PIP4578.0",
    type: "Pipe",
    designation: "PIP4578.0",
    shape: "PIPE",
    country_code: "British",
    ax: 113,
    ax_global: 11300,
    i: 28450,
    i_global: 284500000,
    od: 457,
    od_global: 457,
    tw: 8,
    tw_global: 8,
    z: 1613,
    z_global: 1613000,
  },
  {
    profile_section_id: 4366,
    name: "PIP482.5",
    type: "Pipe",
    designation: "PIP482.5",
    shape: "PIPE",
    country_code: "British",
    ax: "3,6",
    ax_global: 360,
    i: "9,46",
    i_global: 94600,
    od: "48,3",
    od_global: "48,3",
    tw: "2,5",
    tw_global: "2,5",
    z: "5,25",
    z_global: 5250,
  },
  {
    profile_section_id: 4367,
    name: "PIP483.0",
    type: "Pipe",
    designation: "PIP483.0",
    shape: "PIPE",
    country_code: "British",
    ax: "4,27",
    ax_global: 427,
    i: 11,
    i_global: 110000,
    od: "48,3",
    od_global: "48,3",
    tw: 3,
    tw_global: 3,
    z: "6,17",
    z_global: 6170,
  },
  {
    profile_section_id: 4368,
    name: "PIP483.2",
    type: "Pipe",
    designation: "PIP483.2",
    shape: "PIPE",
    country_code: "British",
    ax: "4,53",
    ax_global: 453,
    i: "11,6",
    i_global: 116000,
    od: "48,3",
    od_global: "48,3",
    tw: "3,2",
    tw_global: "3,2",
    z: "6,52",
    z_global: 6520,
  },
  {
    profile_section_id: 4369,
    name: "PIP483.6",
    type: "Pipe",
    designation: "PIP483.6",
    shape: "PIPE",
    country_code: "British",
    ax: "5,06",
    ax_global: 506,
    i: "12,7",
    i_global: 127000,
    od: "48,3",
    od_global: "48,3",
    tw: "3,6",
    tw_global: "3,6",
    z: "7,21",
    z_global: 7210,
  },
  {
    profile_section_id: 4370,
    name: "PIP484.0",
    type: "Pipe",
    designation: "PIP484.0",
    shape: "PIPE",
    country_code: "British",
    ax: "5,57",
    ax_global: 557,
    i: "13,8",
    i_global: 138000,
    od: "48,3",
    od_global: "48,3",
    tw: 4,
    tw_global: 4,
    z: "7,87",
    z_global: 7870,
  },
  {
    profile_section_id: 4371,
    name: "PIP485.0",
    type: "Pipe",
    designation: "PIP485.0",
    shape: "PIPE",
    country_code: "British",
    ax: "6,8",
    ax_global: 680,
    i: "16,2",
    i_global: 162000,
    od: "48,3",
    od_global: "48,3",
    tw: 5,
    tw_global: 5,
    z: "9,42",
    z_global: 9420,
  },
  {
    profile_section_id: 4372,
    name: "PIP50810.0",
    type: "Pipe",
    designation: "PIP50810.0",
    shape: "PIPE",
    country_code: "British",
    ax: 156,
    ax_global: 15600,
    i: 48520,
    i_global: 485200000,
    od: 508,
    od_global: 508,
    tw: 10,
    tw_global: 10,
    z: 2480,
    z_global: 2480000,
  },
  {
    profile_section_id: 4373,
    name: "PIP50812.0",
    type: "Pipe",
    designation: "PIP50812.0",
    shape: "PIPE",
    country_code: "British",
    ax: 187,
    ax_global: 18700,
    i: 57540,
    i_global: 575400000,
    od: 508,
    od_global: 508,
    tw: 12,
    tw_global: 12,
    z: 2953,
    z_global: 2953000,
  },
  {
    profile_section_id: 4374,
    name: "PIP50812.5",
    type: "Pipe",
    designation: "PIP50812.5",
    shape: "PIPE",
    country_code: "British",
    ax: 195,
    ax_global: 19500,
    i: 59760,
    i_global: 597600000,
    od: 508,
    od_global: 508,
    tw: "12,5",
    tw_global: "12,5",
    z: 3070,
    z_global: 3070000,
  },
  {
    profile_section_id: 4375,
    name: "PIP50816.0",
    type: "Pipe",
    designation: "PIP50816.0",
    shape: "PIPE",
    country_code: "British",
    ax: 247,
    ax_global: 24700,
    i: 74910,
    i_global: 749100000,
    od: 508,
    od_global: 508,
    tw: 16,
    tw_global: 16,
    z: 3874,
    z_global: 3874000,
  },
  {
    profile_section_id: 4376,
    name: "PIP50820.0",
    type: "Pipe",
    designation: "PIP50820.0",
    shape: "PIPE",
    country_code: "British",
    ax: 307,
    ax_global: 30700,
    i: 91430,
    i_global: 914300000,
    od: 508,
    od_global: 508,
    tw: 20,
    tw_global: 20,
    z: 4766,
    z_global: 4766000,
  },
  {
    profile_section_id: 4377,
    name: "PIP50825.0",
    type: "Pipe",
    designation: "PIP50825.0",
    shape: "PIPE",
    country_code: "British",
    ax: 379,
    ax_global: 37900,
    i: 110900,
    i_global: 1109000000,
    od: 508,
    od_global: 508,
    tw: 25,
    tw_global: 25,
    z: 5837,
    z_global: 5837000,
  },
  {
    profile_section_id: 4378,
    name: "PIP50832.0",
    type: "Pipe",
    designation: "PIP50832.0",
    shape: "PIPE",
    country_code: "British",
    ax: 479,
    ax_global: 47900,
    i: 136100,
    i_global: 1361000000,
    od: 508,
    od_global: 508,
    tw: 32,
    tw_global: 32,
    z: 7261,
    z_global: 7261000,
  },
  {
    profile_section_id: 4379,
    name: "PIP50840.0",
    type: "Pipe",
    designation: "PIP50840.0",
    shape: "PIPE",
    country_code: "British",
    ax: 588,
    ax_global: 58800,
    i: 162200,
    i_global: 1622000000,
    od: 508,
    od_global: 508,
    tw: 40,
    tw_global: 40,
    z: 8782,
    z_global: 8782000,
  },
  {
    profile_section_id: 4380,
    name: "PIP50850.0",
    type: "Pipe",
    designation: "PIP50850.0",
    shape: "PIPE",
    country_code: "British",
    ax: 719,
    ax_global: 71900,
    i: 190900,
    i_global: 1909000000,
    od: 508,
    od_global: 508,
    tw: 50,
    tw_global: 50,
    z: 10530,
    z_global: 10530000,
  },
  {
    profile_section_id: 4381,
    name: "PIP5086.3",
    type: "Pipe",
    designation: "PIP5086.3",
    shape: "PIPE",
    country_code: "British",
    ax: "99,3",
    ax_global: 9930,
    i: 31250,
    i_global: 312500000,
    od: 508,
    od_global: 508,
    tw: "6,3",
    tw_global: "6,3",
    z: 1586,
    z_global: 1586000,
  },
  {
    profile_section_id: 4382,
    name: "PIP5088.0",
    type: "Pipe",
    designation: "PIP5088.0",
    shape: "PIPE",
    country_code: "British",
    ax: 126,
    ax_global: 12600,
    i: 39280,
    i_global: 392800000,
    od: 508,
    od_global: 508,
    tw: 8,
    tw_global: 8,
    z: 2000,
    z_global: 2000000,
  },
  {
    profile_section_id: 4383,
    name: "PIP55920.0",
    type: "Pipe",
    designation: "PIP55920.0",
    shape: "PIPE",
    country_code: "British",
    ax: 339,
    ax_global: 33900,
    i: 123200,
    i_global: 1232000000,
    od: 559,
    od_global: 559,
    tw: 20,
    tw_global: 20,
    z: 5813,
    z_global: 5813000,
  },
  {
    profile_section_id: 4384,
    name: "PIP55925.0",
    type: "Pipe",
    designation: "PIP55925.0",
    shape: "PIPE",
    country_code: "British",
    ax: 419,
    ax_global: 41900,
    i: 149800,
    i_global: 1498000000,
    od: 559,
    od_global: 559,
    tw: 25,
    tw_global: 25,
    z: 7134,
    z_global: 7134000,
  },
  {
    profile_section_id: 4385,
    name: "PIP55932.0",
    type: "Pipe",
    designation: "PIP55932.0",
    shape: "PIPE",
    country_code: "British",
    ax: 530,
    ax_global: 53000,
    i: 184600,
    i_global: 1846000000,
    od: 559,
    od_global: 559,
    tw: 32,
    tw_global: 32,
    z: 8898,
    z_global: 8898000,
  },
  {
    profile_section_id: 4386,
    name: "PIP55940.0",
    type: "Pipe",
    designation: "PIP55940.0",
    shape: "PIPE",
    country_code: "British",
    ax: 652,
    ax_global: 65200,
    i: 220900,
    i_global: 2209000000,
    od: 559,
    od_global: 559,
    tw: 40,
    tw_global: 40,
    z: 10800,
    z_global: 10800000,
  },
  {
    profile_section_id: 4387,
    name: "PIP55950.0",
    type: "Pipe",
    designation: "PIP55950.0",
    shape: "PIPE",
    country_code: "British",
    ax: 800,
    ax_global: 80000,
    i: 261400,
    i_global: 2614000000,
    od: 559,
    od_global: 559,
    tw: 50,
    tw_global: 50,
    z: 13000,
    z_global: 13000000,
  },
  {
    profile_section_id: 4388,
    name: "PIP602.5",
    type: "Pipe",
    designation: "PIP602.5",
    shape: "PIPE",
    country_code: "British",
    ax: "4,54",
    ax_global: 454,
    i: 19,
    i_global: 190000,
    od: "60,3",
    od_global: "60,3",
    tw: "2,5",
    tw_global: "2,5",
    z: "8,36",
    z_global: 8360,
  },
  {
    profile_section_id: 4389,
    name: "PIP603.0",
    type: "Pipe",
    designation: "PIP603.0",
    shape: "PIPE",
    country_code: "British",
    ax: "5,4",
    ax_global: 540,
    i: "22,2",
    i_global: 222000,
    od: "60,3",
    od_global: "60,3",
    tw: 3,
    tw_global: 3,
    z: "9,86",
    z_global: 9860,
  },
  {
    profile_section_id: 4390,
    name: "PIP603.2",
    type: "Pipe",
    designation: "PIP603.2",
    shape: "PIPE",
    country_code: "British",
    ax: "5,74",
    ax_global: 574,
    i: "23,5",
    i_global: 235000,
    od: "60,3",
    od_global: "60,3",
    tw: "3,2",
    tw_global: "3,2",
    z: "10,4",
    z_global: 10400,
  },
  {
    profile_section_id: 4391,
    name: "PIP603.6",
    type: "Pipe",
    designation: "PIP603.6",
    shape: "PIPE",
    country_code: "British",
    ax: "6,41",
    ax_global: 641,
    i: "25,9",
    i_global: 259000,
    od: "60,3",
    od_global: "60,3",
    tw: "3,6",
    tw_global: "3,6",
    z: "11,6",
    z_global: 11600,
  },
  {
    profile_section_id: 4392,
    name: "PIP604.0",
    type: "Pipe",
    designation: "PIP604.0",
    shape: "PIPE",
    country_code: "British",
    ax: "7,07",
    ax_global: 707,
    i: "28,2",
    i_global: 282000,
    od: "60,3",
    od_global: "60,3",
    tw: 4,
    tw_global: 4,
    z: "12,7",
    z_global: 12700,
  },
  {
    profile_section_id: 4393,
    name: "PIP605.0",
    type: "Pipe",
    designation: "PIP605.0",
    shape: "PIPE",
    country_code: "British",
    ax: "8,69",
    ax_global: 869,
    i: "33,5",
    i_global: 335000,
    od: "60,3",
    od_global: "60,3",
    tw: 5,
    tw_global: 5,
    z: "15,3",
    z_global: 15300,
  },
  {
    profile_section_id: 4394,
    name: "PIP61020.0",
    type: "Pipe",
    designation: "PIP61020.0",
    shape: "PIPE",
    country_code: "British",
    ax: 371,
    ax_global: 37100,
    i: 161500,
    i_global: 1615000000,
    od: 610,
    od_global: 610,
    tw: 20,
    tw_global: 20,
    z: 6965,
    z_global: 6965000,
  },
  {
    profile_section_id: 4395,
    name: "PIP61025.0",
    type: "Pipe",
    designation: "PIP61025.0",
    shape: "PIPE",
    country_code: "British",
    ax: 459,
    ax_global: 45900,
    i: 196900,
    i_global: 1969000000,
    od: 610,
    od_global: 610,
    tw: 25,
    tw_global: 25,
    z: 8561,
    z_global: 8561000,
  },
  {
    profile_section_id: 4396,
    name: "PIP61032.0",
    type: "Pipe",
    designation: "PIP61032.0",
    shape: "PIPE",
    country_code: "British",
    ax: 581,
    ax_global: 58100,
    i: 243400,
    i_global: 2434000000,
    od: 610,
    od_global: 610,
    tw: 32,
    tw_global: 32,
    z: 10700,
    z_global: 10700000,
  },
  {
    profile_section_id: 4397,
    name: "PIP61040.0",
    type: "Pipe",
    designation: "PIP61040.0",
    shape: "PIPE",
    country_code: "British",
    ax: 716,
    ax_global: 71600,
    i: 292300,
    i_global: 2923000000,
    od: 610,
    od_global: 610,
    tw: 40,
    tw_global: 40,
    z: 13020,
    z_global: 13020000,
  },
  {
    profile_section_id: 4398,
    name: "PIP61050.0",
    type: "Pipe",
    designation: "PIP61050.0",
    shape: "PIPE",
    country_code: "British",
    ax: 880,
    ax_global: 88000,
    i: 347600,
    i_global: 3476000000,
    od: 610,
    od_global: 610,
    tw: 50,
    tw_global: 50,
    z: 15720,
    z_global: 15720000,
  },
  {
    profile_section_id: 4399,
    name: "PIP66020.0",
    type: "Pipe",
    designation: "PIP66020.0",
    shape: "PIPE",
    country_code: "British",
    ax: 402,
    ax_global: 40200,
    i: 206100,
    i_global: 2061000000,
    od: 660,
    od_global: 660,
    tw: 20,
    tw_global: 20,
    z: 8195,
    z_global: 8195000,
  },
  {
    profile_section_id: 4400,
    name: "PIP66025.0",
    type: "Pipe",
    designation: "PIP66025.0",
    shape: "PIPE",
    country_code: "British",
    ax: 499,
    ax_global: 49900,
    i: 251800,
    i_global: 2518000000,
    od: 660,
    od_global: 660,
    tw: 25,
    tw_global: 25,
    z: 10090,
    z_global: 10090000,
  },
  {
    profile_section_id: 4401,
    name: "PIP66032.0",
    type: "Pipe",
    designation: "PIP66032.0",
    shape: "PIPE",
    country_code: "British",
    ax: 631,
    ax_global: 63100,
    i: 312000,
    i_global: 3120000000,
    od: 660,
    od_global: 660,
    tw: 32,
    tw_global: 32,
    z: 12630,
    z_global: 12630000,
  },
  {
    profile_section_id: 4402,
    name: "PIP66040.0",
    type: "Pipe",
    designation: "PIP66040.0",
    shape: "PIPE",
    country_code: "British",
    ax: 779,
    ax_global: 77900,
    i: 375900,
    i_global: 3759000000,
    od: 660,
    od_global: 660,
    tw: 40,
    tw_global: 40,
    z: 15400,
    z_global: 15400000,
  },
  {
    profile_section_id: 4403,
    name: "PIP66050.0",
    type: "Pipe",
    designation: "PIP66050.0",
    shape: "PIPE",
    country_code: "British",
    ax: 958,
    ax_global: 95800,
    i: 448700,
    i_global: 4487000000,
    od: 660,
    od_global: 660,
    tw: 50,
    tw_global: 50,
    z: 18650,
    z_global: 18650000,
  },
  {
    profile_section_id: 4404,
    name: "PIP762.5",
    type: "Pipe",
    designation: "PIP762.5",
    shape: "PIPE",
    country_code: "British",
    ax: "5,78",
    ax_global: 578,
    i: "39,2",
    i_global: 392000,
    od: "76,1",
    od_global: "76,1",
    tw: "2,5",
    tw_global: "2,5",
    z: "13,5",
    z_global: 13500,
  },
  {
    profile_section_id: 4405,
    name: "PIP763.0",
    type: "Pipe",
    designation: "PIP763.0",
    shape: "PIPE",
    country_code: "British",
    ax: "6,89",
    ax_global: 689,
    i: "46,1",
    i_global: 461000,
    od: "76,1",
    od_global: "76,1",
    tw: 3,
    tw_global: 3,
    z: 16,
    z_global: 16000,
  },
  {
    profile_section_id: 4406,
    name: "PIP763.2",
    type: "Pipe",
    designation: "PIP763.2",
    shape: "PIPE",
    country_code: "British",
    ax: "7,33",
    ax_global: 733,
    i: "48,8",
    i_global: 488000,
    od: "76,1",
    od_global: "76,1",
    tw: "3,2",
    tw_global: "3,2",
    z: 17,
    z_global: 17000,
  },
  {
    profile_section_id: 4407,
    name: "PIP763.6",
    type: "Pipe",
    designation: "PIP763.6",
    shape: "PIPE",
    country_code: "British",
    ax: "8,2",
    ax_global: 820,
    i: 54,
    i_global: 540000,
    od: "76,1",
    od_global: "76,1",
    tw: "3,6",
    tw_global: "3,6",
    z: "18,9",
    z_global: 18900,
  },
  {
    profile_section_id: 4408,
    name: "PIP764.0",
    type: "Pipe",
    designation: "PIP764.0",
    shape: "PIPE",
    country_code: "British",
    ax: "9,06",
    ax_global: 906,
    i: "59,1",
    i_global: 591000,
    od: "76,1",
    od_global: "76,1",
    tw: 4,
    tw_global: 4,
    z: "20,8",
    z_global: 20800,
  },
  {
    profile_section_id: 4409,
    name: "PIP765.0",
    type: "Pipe",
    designation: "PIP765.0",
    shape: "PIPE",
    country_code: "British",
    ax: "11,2",
    ax_global: 1120,
    i: "70,9",
    i_global: 709000,
    od: "76,1",
    od_global: "76,1",
    tw: 5,
    tw_global: 5,
    z: "25,3",
    z_global: 25300,
  },
  {
    profile_section_id: 4410,
    name: "PIP766.0",
    type: "Pipe",
    designation: "PIP766.0",
    shape: "PIPE",
    country_code: "British",
    ax: "13,2",
    ax_global: 1320,
    i: "81,8",
    i_global: 818000,
    od: "76,1",
    od_global: "76,1",
    tw: 6,
    tw_global: 6,
    z: "29,6",
    z_global: 29600,
  },
  {
    profile_section_id: 4411,
    name: "PIP766.3",
    type: "Pipe",
    designation: "PIP766.3",
    shape: "PIPE",
    country_code: "British",
    ax: "13,8",
    ax_global: 1380,
    i: "84,8",
    i_global: 848000,
    od: "76,1",
    od_global: "76,1",
    tw: "6,3",
    tw_global: "6,3",
    z: "30,8",
    z_global: 30800,
  },
  {
    profile_section_id: 4412,
    name: "PIP882.5",
    type: "Pipe",
    designation: "PIP882.5",
    shape: "PIPE",
    country_code: "British",
    ax: "6,79",
    ax_global: 679,
    i: "63,4",
    i_global: 634000,
    od: "88,9",
    od_global: "88,9",
    tw: "2,5",
    tw_global: "2,5",
    z: "18,7",
    z_global: 18700,
  },
  {
    profile_section_id: 4413,
    name: "PIP883.0",
    type: "Pipe",
    designation: "PIP883.0",
    shape: "PIPE",
    country_code: "British",
    ax: "8,1",
    ax_global: 810,
    i: "74,8",
    i_global: 748000,
    od: "88,9",
    od_global: "88,9",
    tw: 3,
    tw_global: 3,
    z: "22,1",
    z_global: 22100,
  },
  {
    profile_section_id: 4414,
    name: "PIP883.2",
    type: "Pipe",
    designation: "PIP883.2",
    shape: "PIPE",
    country_code: "British",
    ax: "8,62",
    ax_global: 862,
    i: "79,2",
    i_global: 792000,
    od: "88,9",
    od_global: "88,9",
    tw: "3,2",
    tw_global: "3,2",
    z: "23,5",
    z_global: 23500,
  },
  {
    profile_section_id: 4415,
    name: "PIP883.6",
    type: "Pipe",
    designation: "PIP883.6",
    shape: "PIPE",
    country_code: "British",
    ax: "9,65",
    ax_global: 965,
    i: "87,9",
    i_global: 879000,
    od: "88,9",
    od_global: "88,9",
    tw: "3,6",
    tw_global: "3,6",
    z: "26,2",
    z_global: 26200,
  },
  {
    profile_section_id: 4416,
    name: "PIP884.0",
    type: "Pipe",
    designation: "PIP884.0",
    shape: "PIPE",
    country_code: "British",
    ax: "10,7",
    ax_global: 1070,
    i: "96,3",
    i_global: 963000,
    od: "88,9",
    od_global: "88,9",
    tw: 4,
    tw_global: 4,
    z: "28,9",
    z_global: 28900,
  },
  {
    profile_section_id: 4417,
    name: "PIP885.0",
    type: "Pipe",
    designation: "PIP885.0",
    shape: "PIPE",
    country_code: "British",
    ax: "13,2",
    ax_global: 1320,
    i: 116,
    i_global: 1160000,
    od: "88,9",
    od_global: "88,9",
    tw: 5,
    tw_global: 5,
    z: "35,2",
    z_global: 35200,
  },
  {
    profile_section_id: 4418,
    name: "PIP886.0",
    type: "Pipe",
    designation: "PIP886.0",
    shape: "PIPE",
    country_code: "British",
    ax: "15,6",
    ax_global: 1560,
    i: 135,
    i_global: 1350000,
    od: "88,9",
    od_global: "88,9",
    tw: 6,
    tw_global: 6,
    z: "41,3",
    z_global: 41300,
  },
  {
    profile_section_id: 4419,
    name: "PIP886.3",
    type: "Pipe",
    designation: "PIP886.3",
    shape: "PIPE",
    country_code: "British",
    ax: "16,3",
    ax_global: 1630,
    i: 140,
    i_global: 1400000,
    od: "88,9",
    od_global: "88,9",
    tw: "6,3",
    tw_global: "6,3",
    z: "43,1",
    z_global: 43100,
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: p.ax ? fixValueToNumber(p.ax, "float") : null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: p.b ? fixValueToNumber(p.b, "float") : null,
    b_global: p.b_global ? fixValueToNumber(p.b_global, "float") : undefined,
    bf: p.bf ? fixValueToNumber(p.bf, "float") : null,
    bf_global: p.bf_global ? fixValueToNumber(p.bf_global, "float") : undefined,
    c: null,
    ct: p.ct ? fixValueToNumber(p.ct, "float") : null,
    ct_global: p.ct_global ? fixValueToNumber(p.ct_global, "float") : undefined,
    d: p.d ? fixValueToNumber(p.d, "float") : null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: p.de ? fixValueToNumber(p.de, "float") : null,
    de_global: p.de_global ? fixValueToNumber(p.de_global, "float") : undefined,
    i: p.i ? fixValueToNumber(p.i, "float") : null,
    i_global: p.i_global ? fixValueToNumber(p.i_global, "float") : undefined,
    ix: p.ix ? fixValueToNumber(p.ix, "float") : null,
    ix_global: p.ix_global ? fixValueToNumber(p.ix_global, "float") : undefined,
    iy: p.iy ? fixValueToNumber(p.iy, "float") : null,
    iy_global: p.iy_global ? fixValueToNumber(p.iy_global, "float") : undefined,
    iz: p.iz ? fixValueToNumber(p.iz, "float") : null,
    iz_global: p.iz_global ? fixValueToNumber(p.iz_global, "float") : undefined,
    k: null,
    k1: null,
    od: p.od ? fixValueToNumber(p.od, "float") : null,
    od_global: p.od_global ? fixValueToNumber(p.od_global, "float") : undefined,
    r1: null,
    r2: null,
    rz: p.rz ? fixValueToNumber(p.rz, "float") : null,
    rz_global: p.rz_global ? fixValueToNumber(p.rz_global, "float") : undefined,
    t: p.t ? fixValueToNumber(p.t, "float") : null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: p.tf ? fixValueToNumber(p.tf, "float") : null,
    tf_global: p.tf_global ? fixValueToNumber(p.tf_global, "float") : undefined,
    tw: p.tw ? fixValueToNumber(p.tw, "float") : null,
    tw_global: p.tw_global ? fixValueToNumber(p.tw_global, "float") : undefined,
    z: p.z ? fixValueToNumber(p.z, "float") : null,
    z_global: p.z_global ? fixValueToNumber(p.z_global, "float") : undefined,
    zx: p.zx ? fixValueToNumber(p.zx, "float") : null,
    zx_global: p.zx_global ? fixValueToNumber(p.zx_global, "float") : undefined,
    zy: p.zy ? fixValueToNumber(p.zy, "float") : null,
    zy_global: p.zy_global ? fixValueToNumber(p.zy_global, "float") : undefined,
    zz: null,
    width: fixValueToNumber(p.width, "float"),
    width_global: fixValueToNumber(p.width_global, "float"),
    thickness: fixValueToNumber(p.thickness, "float"),
    thickness_global: p.thickness_global
      ? fixValueToNumber(p.thickness_global, "float")
      : undefined,
    height: fixValueToNumber(p.height, "float"),
    height_global: fixValueToNumber(p.height_global, "float"),
  };
  return profile;
});
