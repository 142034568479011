export const IndianLCsJSON = [
  {
    "id": 1,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 101,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "1,5",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 2,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 102,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "0,6",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 3,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 103,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "0,6",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 4,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 104,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "0,6",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 5,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 105,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "0,6",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 6,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 106,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "0,6",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 7,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 107,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "0,6",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 8,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 108,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "0,6",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 9,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 109,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "0,6",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 10,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 110,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,6",
    "SZ": "0,2",
    "SY": "0,2"
  },
  {
    "id": 11,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 111,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,6",
    "SZ": "0,2",
    "SY": "-0,2"
  },
  {
    "id": 12,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 112,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,6",
    "SZ": "-0,2",
    "SY": "0,2"
  },
  {
    "id": 13,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 113,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,6",
    "SZ": "-0,2",
    "SY": "-0,2"
  },
  {
    "id": 14,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 114,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,6",
    "SZ": "0,2",
    "SY": "0,2"
  },
  {
    "id": 15,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 115,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,6",
    "SZ": "0,2",
    "SY": "-0,2"
  },
  {
    "id": 16,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 116,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,6",
    "SZ": "-0,2",
    "SY": "0,2"
  },
  {
    "id": 17,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 117,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,6",
    "SZ": "-0,2",
    "SY": "-0,2"
  },
  {
    "id": 18,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 118,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "0,6",
    "SY": "0,2"
  },
  {
    "id": 19,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 119,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "0,6",
    "SY": "-0,2"
  },
  {
    "id": 20,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 120,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "0,6",
    "SY": "0,2"
  },
  {
    "id": 21,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 121,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "0,6",
    "SY": "-0,2"
  },
  {
    "id": 22,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 122,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "-0,6",
    "SY": "0,2"
  },
  {
    "id": 23,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 123,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "-0,6",
    "SY": "-0,2"
  },
  {
    "id": 24,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 124,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "-0,6",
    "SY": "0,2"
  },
  {
    "id": 25,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 125,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "-0,6",
    "SY": "-0,2"
  },
  {
    "id": 26,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 126,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "0,2",
    "SY": "0,6"
  },
  {
    "id": 27,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 127,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "-0,2",
    "SY": "0,6"
  },
  {
    "id": 28,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 128,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "0,2",
    "SY": "0,6"
  },
  {
    "id": 29,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 129,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "-0,2",
    "SY": "0,6"
  },
  {
    "id": 30,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 130,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "0,2",
    "SY": "-0,6"
  },
  {
    "id": 31,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 131,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "-0,2",
    "SY": "-0,6"
  },
  {
    "id": 32,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 132,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "0,2",
    "SY": "-0,6"
  },
  {
    "id": 33,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 133,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "1,2",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,2",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "-0,2",
    "SY": "-0,6"
  },
  {
    "id": 34,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 134,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "1,5",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 35,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 135,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "1,5",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 36,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 136,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "1,5",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 37,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 137,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "1,5",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 38,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 138,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "1,5",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 39,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 139,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "1,5",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 40,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 140,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "1,5",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 41,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 141,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "1,5",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 42,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 142,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "1,5",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 43,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 143,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "1,5",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 44,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 144,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "1,5",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 45,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 145,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "1,5",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 46,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 146,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "1,5",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 47,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 147,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "1,5",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 48,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 148,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "1,5",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 49,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 149,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "1,5",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 50,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 150,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "0,5",
    "SY": "0,5"
  },
  {
    "id": 51,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 151,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "0,5",
    "SY": "-0,5"
  },
  {
    "id": 52,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 152,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "-0,5",
    "SY": "0,5"
  },
  {
    "id": 53,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 153,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "-0,5",
    "SY": "-0,5"
  },
  {
    "id": 54,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 154,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "0,5",
    "SY": "0,5"
  },
  {
    "id": 55,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 155,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "0,5",
    "SY": "-0,5"
  },
  {
    "id": 56,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 156,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "-0,5",
    "SY": "0,5"
  },
  {
    "id": 57,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 157,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "-0,5",
    "SY": "-0,5"
  },
  {
    "id": 58,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 158,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "1,5",
    "SY": "0,5"
  },
  {
    "id": 59,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 159,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "1,5",
    "SY": "-0,5"
  },
  {
    "id": 60,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 160,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "1,5",
    "SY": "0,5"
  },
  {
    "id": 61,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 161,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "1,5",
    "SY": "-0,5"
  },
  {
    "id": 62,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 162,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-1,5",
    "SY": "0,5"
  },
  {
    "id": 63,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 163,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-1,5",
    "SY": "-0,5"
  },
  {
    "id": 64,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 164,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-1,5",
    "SY": "0,5"
  },
  {
    "id": 65,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 165,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-1,5",
    "SY": "-0,5"
  },
  {
    "id": 66,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 166,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "0,5",
    "SY": "1,5"
  },
  {
    "id": 67,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 167,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-0,5",
    "SY": "1,5"
  },
  {
    "id": 68,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 168,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "0,5",
    "SY": "1,5"
  },
  {
    "id": 69,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 169,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-0,5",
    "SY": "1,5"
  },
  {
    "id": 70,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 170,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "0,5",
    "SY": "-1,5"
  },
  {
    "id": 71,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 171,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-0,5",
    "SY": "-1,5"
  },
  {
    "id": 72,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 172,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "0,5",
    "SY": "-1,5"
  },
  {
    "id": 73,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 173,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "1,5",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "1,5",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-0,5",
    "SY": "-1,5"
  },
  {
    "id": 74,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 174,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "0,5",
    "SY": "0,5"
  },
  {
    "id": 75,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 175,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "0,5",
    "SY": "-0,5"
  },
  {
    "id": 76,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 176,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "-0,5",
    "SY": "0,5"
  },
  {
    "id": 77,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 177,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "-0,5",
    "SY": "-0,5"
  },
  {
    "id": 78,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 178,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "0,5",
    "SY": "0,5"
  },
  {
    "id": 79,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 179,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "0,5",
    "SY": "-0,5"
  },
  {
    "id": 80,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 180,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "-0,5",
    "SY": "0,5"
  },
  {
    "id": 81,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 181,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "-0,5",
    "SY": "-0,5"
  },
  {
    "id": 82,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 182,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "1,5",
    "SY": "0,5"
  },
  {
    "id": 83,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 183,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "1,5",
    "SY": "-0,5"
  },
  {
    "id": 84,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 184,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "1,5",
    "SY": "0,5"
  },
  {
    "id": 85,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 185,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "1,5",
    "SY": "-0,5"
  },
  {
    "id": 86,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 186,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-1,5",
    "SY": "0,5"
  },
  {
    "id": 87,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 187,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-1,5",
    "SY": "-0,5"
  },
  {
    "id": 88,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 188,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-1,5",
    "SY": "0,5"
  },
  {
    "id": 89,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 189,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-1,5",
    "SY": "-0,5"
  },
  {
    "id": 90,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 190,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "0,5",
    "SY": "1,5"
  },
  {
    "id": 91,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 191,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-0,5",
    "SY": "1,5"
  },
  {
    "id": 92,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 192,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "0,5",
    "SY": "1,5"
  },
  {
    "id": 93,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 193,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-0,5",
    "SY": "1,5"
  },
  {
    "id": 94,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 194,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "0,5",
    "SY": "-1,5"
  },
  {
    "id": 95,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 195,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-0,5",
    "SY": "-1,5"
  },
  {
    "id": 96,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 196,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "0,5",
    "SY": "-1,5"
  },
  {
    "id": 97,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 197,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "0,9",
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "0,9",
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-0,5",
    "SY": "-1,5"
  },
  {
    "id": 98,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 301,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "1,5",
    "TL": "1,5",
    "PE": "",
    "PT": "1,5",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,5",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 99,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 302,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "1,2",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,2",
    "EO": "",
    "WLpX": "0,15",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 100,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 303,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "1,2",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,2",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "0,15",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 101,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 304,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "1,2",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,2",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "0,15",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 102,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 305,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "1,2",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,2",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "0,15",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 103,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 306,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "1,2",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,2",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "0,15",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 104,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 307,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "1,2",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,2",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "0,15",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 105,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 308,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "1,2",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,2",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "0,15",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 106,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 309,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "1,2",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,2",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "0,15",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 107,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 310,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "1,5",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,5",
    "EO": "",
    "WLpX": "0,375",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 108,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 311,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "1,5",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,5",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "0,375",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 109,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 312,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "1,5",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,5",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "0,375",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 110,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 313,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "1,5",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,5",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "0,375",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 111,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 314,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "1,5",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,5",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "0,375",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 112,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 315,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "1,5",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,5",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "0,375",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 113,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 316,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "1,5",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,5",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "0,375",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 114,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 317,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "1,5",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "1,5",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "0,375",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 115,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 318,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "0,9",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "0,9",
    "EO": "",
    "WLpX": "0,375",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 116,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 319,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "0,9",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "0,9",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "0,375",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 117,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 320,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "0,9",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "0,9",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "0,375",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 118,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 321,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "0,9",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "0,9",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "0,375",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 119,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 322,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "0,9",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "0,9",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "0,375",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 120,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 323,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "0,9",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "0,9",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "0,375",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 121,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 324,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "0,9",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "0,9",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "0,375",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 122,
    "selected": false,
    "CONDITION": "Hydrotest",
    "LC_No": 325,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "0,9",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": "",
    "ET": "0,9",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "0,375",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 123,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 501,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "1,5",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 124,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 502,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "1,5",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 125,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 503,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "0,6",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 126,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 504,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "0,6",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 127,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 505,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "0,6",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 128,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 506,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "0,6",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 129,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 507,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "0,6",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 130,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 508,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "0,6",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 131,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 509,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "0,6",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 132,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 510,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "0,6",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 133,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 511,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "0,6",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 134,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 512,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "0,6",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 135,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 513,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "0,6",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 136,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 514,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "0,6",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 137,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 515,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "0,6",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 138,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 516,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "0,6",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 139,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 517,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "0,6",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 140,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 518,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "0,6",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 141,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 519,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,6",
    "SZ": "0,2",
    "SY": "0,2"
  },
  {
    "id": 142,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 520,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,6",
    "SZ": "0,2",
    "SY": "-0,2"
  },
  {
    "id": 143,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 521,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,6",
    "SZ": "-0,2",
    "SY": "0,2"
  },
  {
    "id": 144,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 522,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,6",
    "SZ": "-0,2",
    "SY": "-0,2"
  },
  {
    "id": 145,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 523,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,6",
    "SZ": "0,2",
    "SY": "0,2"
  },
  {
    "id": 146,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 524,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,6",
    "SZ": "0,2",
    "SY": "-0,2"
  },
  {
    "id": 147,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 525,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,6",
    "SZ": "-0,2",
    "SY": "0,2"
  },
  {
    "id": 148,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 526,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,6",
    "SZ": "-0,2",
    "SY": "-0,2"
  },
  {
    "id": 149,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 527,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "0,6",
    "SY": "0,2"
  },
  {
    "id": 150,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 528,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "0,6",
    "SY": "-0,2"
  },
  {
    "id": 151,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 529,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "0,6",
    "SY": "0,2"
  },
  {
    "id": 152,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 530,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "0,6",
    "SY": "-0,2"
  },
  {
    "id": 153,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 531,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "-0,6",
    "SY": "0,2"
  },
  {
    "id": 154,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 532,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "-0,6",
    "SY": "-0,2"
  },
  {
    "id": 155,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 533,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "-0,6",
    "SY": "0,2"
  },
  {
    "id": 156,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 534,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "-0,6",
    "SY": "-0,2"
  },
  {
    "id": 157,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 535,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "0,2",
    "SY": "0,6"
  },
  {
    "id": 158,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 536,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "-0,2",
    "SY": "0,6"
  },
  {
    "id": 159,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 537,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "0,2",
    "SY": "0,6"
  },
  {
    "id": 160,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 538,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "-0,2",
    "SY": "0,6"
  },
  {
    "id": 161,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 539,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "0,2",
    "SY": "-0,6"
  },
  {
    "id": 162,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 540,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "-0,2",
    "SY": "-0,6"
  },
  {
    "id": 163,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 541,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "0,2",
    "SY": "-0,6"
  },
  {
    "id": 164,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 542,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "1,2",
    "TF": "1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "-0,2",
    "SY": "-0,6"
  },
  {
    "id": 165,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 543,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,6",
    "SZ": "0,2",
    "SY": "0,2"
  },
  {
    "id": 166,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 544,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,6",
    "SZ": "0,2",
    "SY": "-0,2"
  },
  {
    "id": 167,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 545,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,6",
    "SZ": "-0,2",
    "SY": "0,2"
  },
  {
    "id": 168,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 546,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,6",
    "SZ": "-0,2",
    "SY": "-0,2"
  },
  {
    "id": 169,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 547,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,6",
    "SZ": "0,2",
    "SY": "0,2"
  },
  {
    "id": 170,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 548,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,6",
    "SZ": "0,2",
    "SY": "-0,2"
  },
  {
    "id": 171,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 549,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,6",
    "SZ": "-0,2",
    "SY": "0,2"
  },
  {
    "id": 172,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 550,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,6",
    "SZ": "-0,2",
    "SY": "-0,2"
  },
  {
    "id": 173,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 551,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "0,6",
    "SY": "0,2"
  },
  {
    "id": 174,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 552,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "0,6",
    "SY": "-0,2"
  },
  {
    "id": 175,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 553,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "0,6",
    "SY": "0,2"
  },
  {
    "id": 176,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 554,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "0,6",
    "SY": "-0,2"
  },
  {
    "id": 177,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 555,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "-0,6",
    "SY": "0,2"
  },
  {
    "id": 178,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 556,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "-0,6",
    "SY": "-0,2"
  },
  {
    "id": 179,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 557,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "-0,6",
    "SY": "0,2"
  },
  {
    "id": 180,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 558,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "-0,6",
    "SY": "-0,2"
  },
  {
    "id": 181,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 559,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "0,2",
    "SY": "0,6"
  },
  {
    "id": 182,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 560,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "-0,2",
    "SY": "0,6"
  },
  {
    "id": 183,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 561,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "0,2",
    "SY": "0,6"
  },
  {
    "id": 184,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 562,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "-0,2",
    "SY": "0,6"
  },
  {
    "id": 185,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 563,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "0,2",
    "SY": "-0,6"
  },
  {
    "id": 186,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 564,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,2",
    "SZ": "-0,2",
    "SY": "-0,6"
  },
  {
    "id": 187,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 565,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "0,2",
    "SY": "-0,6"
  },
  {
    "id": 188,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 566,
    "LC_Type": "Ultimate",
    "DL": "1,2",
    "LL": "1,2",
    "TL": "1,2",
    "PE": "",
    "PT": "",
    "PO": "1,2",
    "TA": "-1,2",
    "TF": "-1,2",
    "EE": "",
    "ET": "",
    "EO": "1,2",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,2",
    "SZ": "-0,2",
    "SY": "-0,6"
  },
  {
    "id": 189,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 567,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "1,5",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 190,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 568,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "1,5",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 191,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 569,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "1,5",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 192,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 570,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "1,5",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 193,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 571,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "1,5",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 194,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 572,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "1,5",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 195,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 573,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "1,5",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 196,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 574,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "1,5",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 197,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 575,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "1,5",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 198,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 576,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "1,5",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 199,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 577,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "1,5",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 200,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 578,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "1,5",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 201,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 579,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "1,5",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 202,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 580,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "1,5",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 203,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 581,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "1,5",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 204,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 582,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "1,5",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 205,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 583,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "1,5",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 206,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 584,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "1,5",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 207,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 585,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "1,5",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 208,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 586,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "1,5",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 209,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 587,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "1,5",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 210,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 588,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "1,5",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 211,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 589,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "1,5",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 212,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 590,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "1,5",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 213,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 591,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "1,5",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 214,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 592,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "1,5",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 215,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 593,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "1,5",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 216,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 594,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "1,5",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 217,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 595,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "1,5",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 218,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 596,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "1,5",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 219,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 597,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "1,5",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 220,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 598,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "1,5",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 221,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 599,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "0,5",
    "SY": "0,5"
  },
  {
    "id": 222,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 600,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "0,5",
    "SY": "-0,5"
  },
  {
    "id": 223,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 601,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "-0,5",
    "SY": "0,5"
  },
  {
    "id": 224,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 602,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "-0,5",
    "SY": "-0,5"
  },
  {
    "id": 225,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 603,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "0,5",
    "SY": "0,5"
  },
  {
    "id": 226,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 604,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "0,5",
    "SY": "-0,5"
  },
  {
    "id": 227,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 605,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "-0,5",
    "SY": "0,5"
  },
  {
    "id": 228,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 606,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "-0,5",
    "SY": "-0,5"
  },
  {
    "id": 229,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 607,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "1,5",
    "SY": "0,5"
  },
  {
    "id": 230,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 608,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "1,5",
    "SY": "-0,5"
  },
  {
    "id": 231,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 609,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "1,5",
    "SY": "0,5"
  },
  {
    "id": 232,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 610,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "1,5",
    "SY": "-0,5"
  },
  {
    "id": 233,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 611,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-1,5",
    "SY": "0,5"
  },
  {
    "id": 234,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 612,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-1,5",
    "SY": "-0,5"
  },
  {
    "id": 235,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 613,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-1,5",
    "SY": "0,5"
  },
  {
    "id": 236,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 614,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-1,5",
    "SY": "-0,5"
  },
  {
    "id": 237,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 615,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "0,5",
    "SY": "1,5"
  },
  {
    "id": 238,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 616,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-0,5",
    "SY": "1,5"
  },
  {
    "id": 239,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 617,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "0,5",
    "SY": "1,5"
  },
  {
    "id": 240,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 618,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-0,5",
    "SY": "1,5"
  },
  {
    "id": 241,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 619,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "0,5",
    "SY": "-1,5"
  },
  {
    "id": 242,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 620,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-0,5",
    "SY": "-1,5"
  },
  {
    "id": 243,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 621,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "0,5",
    "SY": "-1,5"
  },
  {
    "id": 244,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 622,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "1,5",
    "TF": "1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-0,5",
    "SY": "-1,5"
  },
  {
    "id": 245,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 623,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "0,5",
    "SY": "0,5"
  },
  {
    "id": 246,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 624,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "0,5",
    "SY": "-0,5"
  },
  {
    "id": 247,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 625,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "-0,5",
    "SY": "0,5"
  },
  {
    "id": 248,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 626,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "-0,5",
    "SY": "-0,5"
  },
  {
    "id": 249,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 627,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "0,5",
    "SY": "0,5"
  },
  {
    "id": 250,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 628,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "0,5",
    "SY": "-0,5"
  },
  {
    "id": 251,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 629,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "-0,5",
    "SY": "0,5"
  },
  {
    "id": 252,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 630,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "-0,5",
    "SY": "-0,5"
  },
  {
    "id": 253,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 631,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "1,5",
    "SY": "0,5"
  },
  {
    "id": 254,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 632,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "1,5",
    "SY": "-0,5"
  },
  {
    "id": 255,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 633,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "1,5",
    "SY": "0,5"
  },
  {
    "id": 256,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 634,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "1,5",
    "SY": "-0,5"
  },
  {
    "id": 257,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 635,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-1,5",
    "SY": "0,5"
  },
  {
    "id": 258,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 636,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-1,5",
    "SY": "-0,5"
  },
  {
    "id": 259,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 637,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-1,5",
    "SY": "0,5"
  },
  {
    "id": 260,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 638,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-1,5",
    "SY": "-0,5"
  },
  {
    "id": 261,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 639,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "0,5",
    "SY": "1,5"
  },
  {
    "id": 262,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 640,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-0,5",
    "SY": "1,5"
  },
  {
    "id": 263,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 641,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "0,5",
    "SY": "1,5"
  },
  {
    "id": 264,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 642,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-0,5",
    "SY": "1,5"
  },
  {
    "id": 265,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 643,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "0,5",
    "SY": "-1,5"
  },
  {
    "id": 266,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 644,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-0,5",
    "SY": "-1,5"
  },
  {
    "id": 267,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 645,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "0,5",
    "SY": "-1,5"
  },
  {
    "id": 268,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 646,
    "LC_Type": "Ultimate",
    "DL": "1,5",
    "LL": "",
    "TL": "1,5",
    "PE": "",
    "PT": "",
    "PO": "1,5",
    "TA": "-1,5",
    "TF": "-1,5",
    "EE": "",
    "ET": "",
    "EO": "1,5",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-0,5",
    "SY": "-1,5"
  },
  {
    "id": 269,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 647,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "0,5",
    "SY": "0,5"
  },
  {
    "id": 270,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 648,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "0,5",
    "SY": "-0,5"
  },
  {
    "id": 271,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 649,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "-0,5",
    "SY": "0,5"
  },
  {
    "id": 272,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 650,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "-0,5",
    "SY": "-0,5"
  },
  {
    "id": 273,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 651,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "0,5",
    "SY": "0,5"
  },
  {
    "id": 274,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 652,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "0,5",
    "SY": "-0,5"
  },
  {
    "id": 275,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 653,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "-0,5",
    "SY": "0,5"
  },
  {
    "id": 276,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 654,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "-0,5",
    "SY": "-0,5"
  },
  {
    "id": 277,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 655,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "1,5",
    "SY": "0,5"
  },
  {
    "id": 278,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 656,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "1,5",
    "SY": "-0,5"
  },
  {
    "id": 279,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 657,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "1,5",
    "SY": "0,5"
  },
  {
    "id": 280,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 658,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "1,5",
    "SY": "-0,5"
  },
  {
    "id": 281,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 659,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-1,5",
    "SY": "0,5"
  },
  {
    "id": 282,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 660,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-1,5",
    "SY": "-0,5"
  },
  {
    "id": 283,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 661,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-1,5",
    "SY": "0,5"
  },
  {
    "id": 284,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 662,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-1,5",
    "SY": "-0,5"
  },
  {
    "id": 285,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 663,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "0,5",
    "SY": "1,5"
  },
  {
    "id": 286,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 664,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-0,5",
    "SY": "1,5"
  },
  {
    "id": 287,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 665,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "0,5",
    "SY": "1,5"
  },
  {
    "id": 288,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 666,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-0,5",
    "SY": "1,5"
  },
  {
    "id": 289,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 667,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "0,5",
    "SY": "-1,5"
  },
  {
    "id": 290,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 668,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-0,5",
    "SY": "-1,5"
  },
  {
    "id": 291,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 669,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "0,5",
    "SY": "-1,5"
  },
  {
    "id": 292,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 670,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "0,9",
    "TF": "0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-0,5",
    "SY": "-1,5"
  },
  {
    "id": 293,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 671,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "0,5",
    "SY": "0,5"
  },
  {
    "id": 294,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 672,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "0,5",
    "SY": "-0,5"
  },
  {
    "id": 295,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 673,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "-0,5",
    "SY": "0,5"
  },
  {
    "id": 296,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 674,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "1,5",
    "SZ": "-0,5",
    "SY": "-0,5"
  },
  {
    "id": 297,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 675,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "0,5",
    "SY": "0,5"
  },
  {
    "id": 298,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 676,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "0,5",
    "SY": "-0,5"
  },
  {
    "id": 299,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 677,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "-0,5",
    "SY": "0,5"
  },
  {
    "id": 300,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 678,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-1,5",
    "SZ": "-0,5",
    "SY": "-0,5"
  },
  {
    "id": 301,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 679,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "1,5",
    "SY": "0,5"
  },
  {
    "id": 302,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 680,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "1,5",
    "SY": "-0,5"
  },
  {
    "id": 303,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 681,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "1,5",
    "SY": "0,5"
  },
  {
    "id": 304,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 682,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "1,5",
    "SY": "-0,5"
  },
  {
    "id": 305,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 683,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-1,5",
    "SY": "0,5"
  },
  {
    "id": 306,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 684,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-1,5",
    "SY": "-0,5"
  },
  {
    "id": 307,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 685,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-1,5",
    "SY": "0,5"
  },
  {
    "id": 308,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 686,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-1,5",
    "SY": "-0,5"
  },
  {
    "id": 309,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 687,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "0,5",
    "SY": "1,5"
  },
  {
    "id": 310,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 688,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-0,5",
    "SY": "1,5"
  },
  {
    "id": 311,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 689,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "0,5",
    "SY": "1,5"
  },
  {
    "id": 312,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 690,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-0,5",
    "SY": "1,5"
  },
  {
    "id": 313,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 691,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "0,5",
    "SY": "-1,5"
  },
  {
    "id": 314,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 692,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,5",
    "SZ": "-0,5",
    "SY": "-1,5"
  },
  {
    "id": 315,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 693,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "0,5",
    "SY": "-1,5"
  },
  {
    "id": 316,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 694,
    "LC_Type": "Ultimate",
    "DL": "0,9",
    "LL": "",
    "TL": "0,9",
    "PE": "",
    "PT": "",
    "PO": "0,9",
    "TA": "-0,9",
    "TF": "-0,9",
    "EE": "",
    "ET": "",
    "EO": "0,9",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,5",
    "SZ": "-0,5",
    "SY": "-1,5"
  },
  {
    "id": 317,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1101,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": 1,
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 318,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1102,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "0,8",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 319,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1103,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "0,8",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 320,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1104,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "0,8",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 321,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1105,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "0,8",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 322,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1106,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "0,8",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 323,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1107,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "0,8",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 324,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1108,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "0,8",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 325,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1109,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "0,8",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 326,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1110,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,8",
    "SZ": "0,267",
    "SY": "0,267"
  },
  {
    "id": 327,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1111,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,8",
    "SZ": "0,267",
    "SY": "-0,267"
  },
  {
    "id": 328,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1112,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,8",
    "SZ": "-0,267",
    "SY": "0,267"
  },
  {
    "id": 329,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1113,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,8",
    "SZ": "-0,267",
    "SY": "-0,267"
  },
  {
    "id": 330,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1114,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,8",
    "SZ": "0,267",
    "SY": "0,267"
  },
  {
    "id": 331,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1115,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,8",
    "SZ": "0,267",
    "SY": "-0,267"
  },
  {
    "id": 332,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1116,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,8",
    "SZ": "-0,267",
    "SY": "0,267"
  },
  {
    "id": 333,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1117,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,8",
    "SZ": "-0,267",
    "SY": "-0,267"
  },
  {
    "id": 334,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1118,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "0,8",
    "SY": "0,267"
  },
  {
    "id": 335,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1119,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "0,8",
    "SY": "-0,267"
  },
  {
    "id": 336,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1120,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "0,8",
    "SY": "0,267"
  },
  {
    "id": 337,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1121,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "0,8",
    "SY": "-0,267"
  },
  {
    "id": 338,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1122,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "-0,8",
    "SY": "0,267"
  },
  {
    "id": 339,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1123,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "-0,8",
    "SY": "-0,267"
  },
  {
    "id": 340,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1124,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "-0,8",
    "SY": "0,267"
  },
  {
    "id": 341,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1125,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "-0,8",
    "SY": "-0,267"
  },
  {
    "id": 342,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1126,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "0,267",
    "SY": "0,8"
  },
  {
    "id": 343,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1127,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "-0,267",
    "SY": "0,8"
  },
  {
    "id": 344,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1128,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "0,267",
    "SY": "0,8"
  },
  {
    "id": 345,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1129,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "-0,267",
    "SY": "0,8"
  },
  {
    "id": 346,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1130,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "0,267",
    "SY": "-0,8"
  },
  {
    "id": 347,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1131,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "-0,267",
    "SY": "-0,8"
  },
  {
    "id": 348,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1132,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "0,267",
    "SY": "-0,8"
  },
  {
    "id": 349,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1133,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "-0,267",
    "SY": "-0,8"
  },
  {
    "id": 350,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1134,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": 1,
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 351,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1135,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": 1,
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 352,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1136,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": 1,
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 353,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1137,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": 1,
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 354,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1138,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": 1,
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 355,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1139,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": 1,
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 356,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1140,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": 1,
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 357,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1141,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": 1,
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 358,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1142,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": 1,
    "SZ": "0,333",
    "SY": "0,333"
  },
  {
    "id": 359,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1143,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": 1,
    "SZ": "0,333",
    "SY": "-0,333"
  },
  {
    "id": 360,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1144,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": 1,
    "SZ": "-0,333",
    "SY": "0,333"
  },
  {
    "id": 361,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1145,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": 1,
    "SZ": "-0,333",
    "SY": "-0,333"
  },
  {
    "id": 362,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1146,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": -1,
    "SZ": "0,333",
    "SY": "0,333"
  },
  {
    "id": 363,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1147,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": -1,
    "SZ": "0,333",
    "SY": "-0,333"
  },
  {
    "id": 364,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1148,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": -1,
    "SZ": "-0,333",
    "SY": "0,333"
  },
  {
    "id": 365,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1149,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": -1,
    "SZ": "-0,333",
    "SY": "-0,333"
  },
  {
    "id": 366,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1150,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": 1,
    "SY": "0,333"
  },
  {
    "id": 367,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1151,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": 1,
    "SY": "-0,333"
  },
  {
    "id": 368,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1152,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": 1,
    "SY": "0,333"
  },
  {
    "id": 369,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1153,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": 1,
    "SY": "-0,333"
  },
  {
    "id": 370,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1154,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": -1,
    "SY": "0,333"
  },
  {
    "id": 371,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1155,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": -1,
    "SY": "-0,333"
  },
  {
    "id": 372,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1156,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": -1,
    "SY": "0,333"
  },
  {
    "id": 373,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1157,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": -1,
    "SY": "-0,333"
  },
  {
    "id": 374,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1158,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": "0,333",
    "SY": 1
  },
  {
    "id": 375,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1159,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": "-0,333",
    "SY": 1
  },
  {
    "id": 376,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1160,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": "0,333",
    "SY": 1
  },
  {
    "id": 377,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1161,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": "-0,333",
    "SY": 1
  },
  {
    "id": 378,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1162,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": "0,333",
    "SY": -1
  },
  {
    "id": 379,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1163,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": "-0,333",
    "SY": -1
  },
  {
    "id": 380,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1164,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": "0,333",
    "SY": -1
  },
  {
    "id": 381,
    "selected": false,
    "CONDITION": "Erection / shutdown",
    "LC_No": 1165,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": 1,
    "PT": "",
    "PO": "",
    "TA": "",
    "TF": "",
    "EE": 1,
    "ET": "",
    "EO": "",
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": "-0,333",
    "SY": -1
  },
  {
    "id": 382,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1501,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": 1,
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 383,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1502,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": 1,
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 384,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1503,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "0,8",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 385,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1504,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "0,8",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 386,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1505,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "0,8",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 387,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1506,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "0,8",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 388,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1507,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "0,8",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 389,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1508,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "0,8",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 390,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1509,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "0,8",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 391,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1510,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "0,8",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 392,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1511,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,8",
    "SZ": "0,267",
    "SY": "0,267"
  },
  {
    "id": 393,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1512,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,8",
    "SZ": "0,267",
    "SY": "-0,267"
  },
  {
    "id": 394,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1513,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,8",
    "SZ": "-0,267",
    "SY": "0,267"
  },
  {
    "id": 395,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1514,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,8",
    "SZ": "-0,267",
    "SY": "-0,267"
  },
  {
    "id": 396,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1515,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,8",
    "SZ": "0,267",
    "SY": "0,267"
  },
  {
    "id": 397,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1516,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,8",
    "SZ": "0,267",
    "SY": "-0,267"
  },
  {
    "id": 398,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1517,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,8",
    "SZ": "-0,267",
    "SY": "0,267"
  },
  {
    "id": 399,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1518,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,8",
    "SZ": "-0,267",
    "SY": "-0,267"
  },
  {
    "id": 400,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1519,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "0,8",
    "SY": "0,267"
  },
  {
    "id": 401,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1520,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "0,8",
    "SY": "-0,267"
  },
  {
    "id": 402,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1521,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "0,8",
    "SY": "0,267"
  },
  {
    "id": 403,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1522,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "0,8",
    "SY": "-0,267"
  },
  {
    "id": 404,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1523,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "-0,8",
    "SY": "0,267"
  },
  {
    "id": 405,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1524,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "-0,8",
    "SY": "-0,267"
  },
  {
    "id": 406,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1525,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "-0,8",
    "SY": "0,267"
  },
  {
    "id": 407,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1526,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "-0,8",
    "SY": "-0,267"
  },
  {
    "id": 408,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1527,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "0,267",
    "SY": "0,8"
  },
  {
    "id": 409,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1528,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "-0,267",
    "SY": "0,8"
  },
  {
    "id": 410,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1529,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "0,267",
    "SY": "0,8"
  },
  {
    "id": 411,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1530,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "-0,267",
    "SY": "0,8"
  },
  {
    "id": 412,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1531,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "0,267",
    "SY": "-0,8"
  },
  {
    "id": 413,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1532,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "-0,267",
    "SY": "-0,8"
  },
  {
    "id": 414,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1533,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "0,267",
    "SY": "-0,8"
  },
  {
    "id": 415,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1534,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "-0,267",
    "SY": "-0,8"
  },
  {
    "id": 416,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1535,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,8",
    "SZ": "0,267",
    "SY": "0,267"
  },
  {
    "id": 417,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1536,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,8",
    "SZ": "0,267",
    "SY": "-0,267"
  },
  {
    "id": 418,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1537,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,8",
    "SZ": "-0,267",
    "SY": "0,267"
  },
  {
    "id": 419,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1538,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,8",
    "SZ": "-0,267",
    "SY": "-0,267"
  },
  {
    "id": 420,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1539,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,8",
    "SZ": "0,267",
    "SY": "0,267"
  },
  {
    "id": 421,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1540,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,8",
    "SZ": "0,267",
    "SY": "-0,267"
  },
  {
    "id": 422,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1541,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,8",
    "SZ": "-0,267",
    "SY": "0,267"
  },
  {
    "id": 423,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1542,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,8",
    "SZ": "-0,267",
    "SY": "-0,267"
  },
  {
    "id": 424,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1543,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "0,8",
    "SY": "0,267"
  },
  {
    "id": 425,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1544,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "0,8",
    "SY": "-0,267"
  },
  {
    "id": 426,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1545,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "0,8",
    "SY": "0,267"
  },
  {
    "id": 427,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1546,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "0,8",
    "SY": "-0,267"
  },
  {
    "id": 428,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1547,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "-0,8",
    "SY": "0,267"
  },
  {
    "id": 429,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1548,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "-0,8",
    "SY": "-0,267"
  },
  {
    "id": 430,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1549,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "-0,8",
    "SY": "0,267"
  },
  {
    "id": 431,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1550,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "-0,8",
    "SY": "-0,267"
  },
  {
    "id": 432,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1551,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "0,267",
    "SY": "0,8"
  },
  {
    "id": 433,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1552,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "-0,267",
    "SY": "0,8"
  },
  {
    "id": 434,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1553,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "0,267",
    "SY": "0,8"
  },
  {
    "id": 435,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1554,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "-0,267",
    "SY": "0,8"
  },
  {
    "id": 436,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1555,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "0,267",
    "SY": "-0,8"
  },
  {
    "id": 437,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1556,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,267",
    "SZ": "-0,267",
    "SY": "-0,8"
  },
  {
    "id": 438,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1557,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "0,267",
    "SY": "-0,8"
  },
  {
    "id": 439,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1558,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "0,8",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,267",
    "SZ": "-0,267",
    "SY": "-0,8"
  },
  {
    "id": 440,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1559,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": 1,
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 441,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1560,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": 1,
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 442,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1561,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": 1,
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 443,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1562,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": 1,
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 444,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1563,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": 1,
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 445,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1564,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": 1,
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 446,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1565,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": 1,
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 447,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1566,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": 1,
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 448,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1567,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": 1,
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 449,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1568,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": 1,
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 450,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1569,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": 1,
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 451,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1570,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": 1,
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 452,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1571,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": 1,
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 453,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1572,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": 1,
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 454,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1573,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": 1,
    "WLpXpZ": "",
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 455,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1574,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": 1,
    "SX": "",
    "SZ": "",
    "SY": ""
  },
  {
    "id": 456,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1575,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": 1,
    "SZ": "0,333",
    "SY": "0,333"
  },
  {
    "id": 457,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1576,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": 1,
    "SZ": "0,333",
    "SY": "-0,333"
  },
  {
    "id": 458,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1577,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": 1,
    "SZ": "-0,333",
    "SY": "0,333"
  },
  {
    "id": 459,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1578,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": 1,
    "SZ": "-0,333",
    "SY": "-0,333"
  },
  {
    "id": 460,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1579,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": -1,
    "SZ": "0,333",
    "SY": "0,333"
  },
  {
    "id": 461,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1580,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": -1,
    "SZ": "0,333",
    "SY": "-0,333"
  },
  {
    "id": 462,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1581,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": -1,
    "SZ": "-0,333",
    "SY": "0,333"
  },
  {
    "id": 463,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1582,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": -1,
    "SZ": "-0,333",
    "SY": "-0,333"
  },
  {
    "id": 464,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1583,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": 1,
    "SY": "0,333"
  },
  {
    "id": 465,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1584,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": 1,
    "SY": "-0,333"
  },
  {
    "id": 466,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1585,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": 1,
    "SY": "0,333"
  },
  {
    "id": 467,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1586,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": 1,
    "SY": "-0,333"
  },
  {
    "id": 468,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1587,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": -1,
    "SY": "0,333"
  },
  {
    "id": 469,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1588,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": -1,
    "SY": "-0,333"
  },
  {
    "id": 470,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1589,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": -1,
    "SY": "0,333"
  },
  {
    "id": 471,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1590,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": -1,
    "SY": "-0,333"
  },
  {
    "id": 472,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1591,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": "0,333",
    "SY": 1
  },
  {
    "id": 473,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1592,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": "-0,333",
    "SY": 1
  },
  {
    "id": 474,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1593,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": "0,333",
    "SY": 1
  },
  {
    "id": 475,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1594,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": "-0,333",
    "SY": 1
  },
  {
    "id": 476,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1595,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": "0,333",
    "SY": -1
  },
  {
    "id": 477,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1596,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": "-0,333",
    "SY": -1
  },
  {
    "id": 478,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1597,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": "0,333",
    "SY": -1
  },
  {
    "id": 479,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1598,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": 1,
    "TF": 1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": "-0,333",
    "SY": -1
  },
  {
    "id": 480,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1599,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": 1,
    "SZ": "0,333",
    "SY": "0,333"
  },
  {
    "id": 481,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1600,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": 1,
    "SZ": "0,333",
    "SY": "-0,333"
  },
  {
    "id": 482,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1601,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": 1,
    "SZ": "-0,333",
    "SY": "0,333"
  },
  {
    "id": 483,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1602,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": 1,
    "SZ": "-0,333",
    "SY": "-0,333"
  },
  {
    "id": 484,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1603,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": -1,
    "SZ": "0,333",
    "SY": "0,333"
  },
  {
    "id": 485,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1604,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": -1,
    "SZ": "0,333",
    "SY": "-0,333"
  },
  {
    "id": 486,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1605,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": -1,
    "SZ": "-0,333",
    "SY": "0,333"
  },
  {
    "id": 487,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1606,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": -1,
    "SZ": "-0,333",
    "SY": "-0,333"
  },
  {
    "id": 488,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1607,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": 1,
    "SY": "0,333"
  },
  {
    "id": 489,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1608,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": 1,
    "SY": "-0,333"
  },
  {
    "id": 490,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1609,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": 1,
    "SY": "0,333"
  },
  {
    "id": 491,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1610,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": 1,
    "SY": "-0,333"
  },
  {
    "id": 492,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1611,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": -1,
    "SY": "0,333"
  },
  {
    "id": 493,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1612,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": -1,
    "SY": "-0,333"
  },
  {
    "id": 494,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1613,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": -1,
    "SY": "0,333"
  },
  {
    "id": 495,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1614,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": -1,
    "SY": "-0,333"
  },
  {
    "id": 496,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1615,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": "0,333",
    "SY": 1
  },
  {
    "id": 497,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1616,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": "-0,333",
    "SY": 1
  },
  {
    "id": 498,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1617,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": "0,333",
    "SY": 1
  },
  {
    "id": 499,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1618,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": "-0,333",
    "SY": 1
  },
  {
    "id": 500,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1619,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": "0,333",
    "SY": -1
  },
  {
    "id": 501,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1620,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "0,333",
    "SZ": "-0,333",
    "SY": -1
  },
  {
    "id": 502,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1621,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": "0,333",
    "SY": -1
  },
  {
    "id": 503,
    "selected": false,
    "CONDITION": "Operating",
    "LC_No": 1622,
    "LC_Type": "Serviceability",
    "DL": 1,
    "LL": "",
    "TL": 1,
    "PE": "",
    "PT": "",
    "PO": 1,
    "TA": -1,
    "TF": -1,
    "EE": "",
    "ET": "",
    "EO": 1,
    "WLpX": "",
    "WLmZpX": "",
    "WLmZ": "",
    "WLmXmZ": "",
    "WLmX": "",
    "WLpZmX": "",
    "WLpZ": "",
    "WLpXpZ": "",
    "SX": "-0,333",
    "SZ": "-0,333",
    "SY": -1
  }
]
