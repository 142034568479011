import { fixValueToNumber } from "../../components/3d-models/utils";

export const pipingReducers = [
  {
    piping_reducers_id: 1,
    nps: "3/4 x 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 27,
    d2: 21,
    h: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "2,87",
    t2: "2,77",
    weight: "0,07",
  },
  {
    piping_reducers_id: 2,
    nps: "3/4 x 3/8",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 27,
    d2: 17,
    h: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "2,87",
    t2: "2,31",
    weight: "0,07",
  },
  {
    piping_reducers_id: 3,
    nps: "1 x 3/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 33,
    d2: 27,
    h: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,38",
    t2: "2,87",
    weight: "0,13",
  },
  {
    piping_reducers_id: 4,
    nps: "1 x 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 33,
    d2: 21,
    h: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,38",
    t2: "2,77",
    weight: "0,13",
  },
  {
    piping_reducers_id: 5,
    nps: "1 1/4 x 1",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 42,
    d2: 33,
    h: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,56",
    t2: "3,38",
    weight: "0,17",
  },
  {
    piping_reducers_id: 6,
    nps: "1 1/4 x 3/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 42,
    d2: 27,
    h: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,56",
    t2: "2,87",
    weight: "0,17",
  },
  {
    piping_reducers_id: 7,
    nps: "1 1/4 x1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 42,
    d2: 21,
    h: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,56",
    t2: "2,77",
    weight: "0,17",
  },
  {
    piping_reducers_id: 8,
    nps: "1 1/2 x 1 1/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 48,
    d2: 42,
    h: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,68",
    t2: "3,56",
    weight: "0,26",
  },
  {
    piping_reducers_id: 9,
    nps: "1 1/2 x 1",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 48,
    d2: 33,
    h: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,68",
    t2: "3,38",
    weight: "0,26",
  },
  {
    piping_reducers_id: 10,
    nps: "1 1/2 x 3/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 48,
    d2: 27,
    h: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,68",
    t2: "2,87",
    weight: "0,26",
  },
  {
    piping_reducers_id: 11,
    nps: "1 1/2 x 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 48,
    d2: 21,
    h: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,68",
    t2: "2,77",
    weight: "0,26",
  },
  {
    piping_reducers_id: 12,
    nps: "2 x 1 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 60,
    d2: 48,
    h: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,91",
    t2: "3,68",
    weight: "0,41",
  },
  {
    piping_reducers_id: 13,
    nps: "2 x 1 1/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 60,
    d2: 42,
    h: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,91",
    t2: "3,56",
    weight: "0,41",
  },
  {
    piping_reducers_id: 14,
    nps: "2 x 1",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 60,
    d2: 33,
    h: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,91",
    t2: "3,38",
    weight: "0,41",
  },
  {
    piping_reducers_id: 15,
    nps: "2 x 3/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 60,
    d2: 27,
    h: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "3,91",
    t2: "2,87",
    weight: "0,41",
  },
  {
    piping_reducers_id: 16,
    nps: "2 1/2 x 2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 73,
    d2: 60,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,16",
    t2: "3,91",
    weight: "0,77",
  },
  {
    piping_reducers_id: 17,
    nps: "2 1/2 x 1 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 73,
    d2: 48,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,16",
    t2: "3,68",
    weight: "0,77",
  },
  {
    piping_reducers_id: 18,
    nps: "2 1/2 x 1 1/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 73,
    d2: 42,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,16",
    t2: "3,56",
    weight: "0,77",
  },
  {
    piping_reducers_id: 19,
    nps: "2 1/2 x 1",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 73,
    d2: 33,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,16",
    t2: "3,38",
    weight: "0,77",
  },
  {
    piping_reducers_id: 20,
    nps: "3 x 2 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 89,
    d2: 73,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,49",
    t2: "5,16",
    weight: 1,
  },
  {
    piping_reducers_id: 21,
    nps: "3 x 2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 89,
    d2: 60,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,49",
    t2: "3,91",
    weight: 1,
  },
  {
    piping_reducers_id: 22,
    nps: "3 x 1 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 89,
    d2: 48,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,49",
    t2: "3,68",
    weight: 1,
  },
  {
    piping_reducers_id: 23,
    nps: "3 x 1 1/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 89,
    d2: 42,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,49",
    t2: "3,56",
    weight: 1,
  },
  {
    piping_reducers_id: 24,
    nps: "3 1/2 x 3",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 102,
    d2: 89,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,74",
    t2: "5,49",
    weight: "1,4",
  },
  {
    piping_reducers_id: 25,
    nps: "3 1/2 x 2 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 102,
    d2: 73,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,74",
    t2: "5,16",
    weight: "1,4",
  },
  {
    piping_reducers_id: 26,
    nps: "3 1/2 x 2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 102,
    d2: 60,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,74",
    t2: "3,91",
    weight: "1,4",
  },
  {
    piping_reducers_id: 27,
    nps: "3 1/2 x 1 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 102,
    d2: 48,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,74",
    t2: "3,68",
    weight: "1,4",
  },
  {
    piping_reducers_id: 28,
    nps: "3 1/2 x 1 1/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 102,
    d2: 42,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "5,74",
    t2: "3,56",
    weight: "1,4",
  },
  {
    piping_reducers_id: 29,
    nps: "4 x 3 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 114,
    d2: 102,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "6,02",
    t2: "5,74",
    weight: "1,6",
  },
  {
    piping_reducers_id: 30,
    nps: "4 x 3",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 114,
    d2: 89,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "6,02",
    t2: "5,49",
    weight: "1,6",
  },
  {
    piping_reducers_id: 31,
    nps: "4 x 2 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 114,
    d2: 73,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "6,02",
    t2: "5,16",
    weight: "1,6",
  },
  {
    piping_reducers_id: 32,
    nps: "4 x 2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 114,
    d2: 60,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "6,02",
    t2: "3,91",
    weight: "1,6",
  },
  {
    piping_reducers_id: 33,
    nps: "4 x 1 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 114,
    d2: 48,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "6,02",
    t2: "3,68",
    weight: "1,6",
  },
  {
    piping_reducers_id: 34,
    nps: "5 x 4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 141,
    d2: 114,
    h: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "6,55",
    t2: "6,02",
    weight: "2,8",
  },
  {
    piping_reducers_id: 35,
    nps: "5 x 3 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 141,
    d2: 102,
    h: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "6,55",
    t2: "5,74",
    weight: "2,8",
  },
  {
    piping_reducers_id: 36,
    nps: "5 x 3",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 141,
    d2: 89,
    h: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "6,55",
    t2: "5,49",
    weight: "2,8",
  },
  {
    piping_reducers_id: 37,
    nps: "5 x 2 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 141,
    d2: 73,
    h: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "6,35",
    t2: "5,16",
    weight: "2,8",
  },
  {
    piping_reducers_id: 38,
    nps: "5 x 2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 141,
    d2: 60,
    h: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "6,55",
    t2: "3,91",
    weight: "2,8",
  },
  {
    piping_reducers_id: 39,
    nps: "6 x 5",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 168,
    d2: 141,
    h: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "7,11",
    t2: "6,55",
    weight: "3,9",
  },
  {
    piping_reducers_id: 40,
    nps: "6 x 4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 168,
    d2: 114,
    h: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "7,11",
    t2: "6,02",
    weight: "3,9",
  },
  {
    piping_reducers_id: 41,
    nps: "6 x 3 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 168,
    d2: 102,
    h: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "7,11",
    t2: "5,74",
    weight: "3,9",
  },
  {
    piping_reducers_id: 42,
    nps: "6 x 3",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 168,
    d2: 89,
    h: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "7,11",
    t2: "5,49",
    weight: "3,9",
  },
  {
    piping_reducers_id: 43,
    nps: "6 x 2 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 168,
    d2: 73,
    h: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "7,11",
    t2: "5,16",
    weight: "3,9",
  },
  {
    piping_reducers_id: 44,
    nps: "8 x 6",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 219,
    d2: 168,
    h: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "8,18",
    t2: "7,11",
    weight: "6,5",
  },
  {
    piping_reducers_id: 45,
    nps: "8 x 5",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 219,
    d2: 141,
    h: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "8,18",
    t2: "6,55",
    weight: "6,5",
  },
  {
    piping_reducers_id: 46,
    nps: "8 x 4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 219,
    d2: 114,
    h: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "8,18",
    t2: "6,02",
    weight: "6,5",
  },
  {
    piping_reducers_id: 47,
    nps: "8 x 3 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 219,
    d2: 102,
    h: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "8,18",
    t2: "5,74",
    weight: "6,5",
  },
  {
    piping_reducers_id: 48,
    nps: "10 x 8",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 273,
    d2: 219,
    h: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,27",
    t2: "8,18",
    weight: "10,7",
  },
  {
    piping_reducers_id: 49,
    nps: "10 x 6",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 273,
    d2: 168,
    h: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,27",
    t2: "7,11",
    weight: "10,7",
  },
  {
    piping_reducers_id: 50,
    nps: "10 x 5",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 273,
    d2: 141,
    h: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,27",
    t2: "6,55",
    weight: "10,7",
  },
  {
    piping_reducers_id: 51,
    nps: "10 x 4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 273,
    d2: 114,
    h: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,27",
    t2: "6,02",
    weight: "10,7",
  },
  {
    piping_reducers_id: 52,
    nps: "12 x 10",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 324,
    d2: 273,
    h: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,27",
    weight: 15,
  },
  {
    piping_reducers_id: 53,
    nps: "12 x 8",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 324,
    d2: 219,
    h: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "8,18",
    weight: 15,
  },
  {
    piping_reducers_id: 54,
    nps: "12 x 6",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 324,
    d2: 168,
    h: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "7,11",
    weight: 15,
  },
  {
    piping_reducers_id: 55,
    nps: "12 x 5",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 324,
    d2: 141,
    h: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "6,55",
    weight: 15,
  },
  {
    piping_reducers_id: 56,
    nps: "14 x 12",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 356,
    d2: 324,
    h: 330,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: "26,9",
  },
  {
    piping_reducers_id: 57,
    nps: "14 x 10",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 356,
    d2: 273,
    h: 330,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,27",
    weight: "26,9",
  },
  {
    piping_reducers_id: 58,
    nps: "14 x 8",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 356,
    d2: 219,
    h: 330,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "8,18",
    weight: "26,9",
  },
  {
    piping_reducers_id: 59,
    nps: "14 x 6",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 356,
    d2: 168,
    h: 330,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "7,11",
    weight: "26,9",
  },
  {
    piping_reducers_id: 60,
    nps: "16 x 14",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 406,
    d2: 356,
    h: 356,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 33,
  },
  {
    piping_reducers_id: 61,
    nps: "16 x 12",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 406,
    d2: 324,
    h: 356,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 33,
  },
  {
    piping_reducers_id: 62,
    nps: "16 x 10",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 406,
    d2: 273,
    h: 356,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,27",
    weight: 33,
  },
  {
    piping_reducers_id: 63,
    nps: "16 x 8",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 406,
    d2: 219,
    h: 356,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "8,18",
    weight: 33,
  },
  {
    piping_reducers_id: 64,
    nps: "18 x 16",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 457,
    d2: 406,
    h: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 40,
  },
  {
    piping_reducers_id: 65,
    nps: "18 x 14",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 457,
    d2: 356,
    h: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 40,
  },
  {
    piping_reducers_id: 66,
    nps: "18 x 12",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 457,
    d2: 324,
    h: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 40,
  },
  {
    piping_reducers_id: 67,
    nps: "18 x 10",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 457,
    d2: 273,
    h: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,27",
    weight: 40,
  },
  {
    piping_reducers_id: 68,
    nps: "20 x 18",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 508,
    d2: 457,
    h: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 59,
  },
  {
    piping_reducers_id: 69,
    nps: "20 x 16",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 508,
    d2: 406,
    h: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 59,
  },
  {
    piping_reducers_id: 70,
    nps: "20 x 14",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 508,
    d2: 356,
    h: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 59,
  },
  {
    piping_reducers_id: 71,
    nps: "20 x 12",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 508,
    d2: 324,
    h: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 59,
  },
  {
    piping_reducers_id: 72,
    nps: "22 x 20",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 559,
    d2: 508,
    h: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 65,
  },
  {
    piping_reducers_id: 73,
    nps: "22 x 18",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 559,
    d2: 457,
    h: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 65,
  },
  {
    piping_reducers_id: 74,
    nps: "22 x 16",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 559,
    d2: 406,
    h: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 65,
  },
  {
    piping_reducers_id: 75,
    nps: "22 x 14",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 559,
    d2: 356,
    h: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 65,
  },
  {
    piping_reducers_id: 76,
    nps: "24 x 22",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 610,
    d2: 559,
    h: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 72,
  },
  {
    piping_reducers_id: 77,
    nps: "24 x 20",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 610,
    d2: 508,
    h: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 72,
  },
  {
    piping_reducers_id: 78,
    nps: "24 x 18",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 610,
    d2: 457,
    h: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 72,
  },
  {
    piping_reducers_id: 79,
    nps: "24 x 16",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 610,
    d2: 406,
    h: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 72,
  },
  {
    piping_reducers_id: 80,
    nps: "26 x 24",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 660,
    d2: 610,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 91,
  },
  {
    piping_reducers_id: 81,
    nps: "26 x 22",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 660,
    d2: 559,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 91,
  },
  {
    piping_reducers_id: 82,
    nps: "26 x 20",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 660,
    d2: 508,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 91,
  },
  {
    piping_reducers_id: 83,
    nps: "26 x 18",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 660,
    d2: 457,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 91,
  },
  {
    piping_reducers_id: 84,
    nps: "28 x 26",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 711,
    d2: 660,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 95,
  },
  {
    piping_reducers_id: 85,
    nps: "28 x 24",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 711,
    d2: 610,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 95,
  },
  {
    piping_reducers_id: 86,
    nps: "28 x 20",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 711,
    d2: 508,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 95,
  },
  {
    piping_reducers_id: 87,
    nps: "28 x 18",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 711,
    d2: 457,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 95,
  },
  {
    piping_reducers_id: 88,
    nps: "30 x 28",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 762,
    d2: 711,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 100,
  },
  {
    piping_reducers_id: 89,
    nps: "30 x 26",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 762,
    d2: 660,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 100,
  },
  {
    piping_reducers_id: 90,
    nps: "30 x 24",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 762,
    d2: 610,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 100,
  },
  {
    piping_reducers_id: 91,
    nps: "30 x 20",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 762,
    d2: 508,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 100,
  },
  {
    piping_reducers_id: 92,
    nps: "32 x 30",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 813,
    d2: 762,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 115,
  },
  {
    piping_reducers_id: 93,
    nps: "32 x 28",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 813,
    d2: 711,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 115,
  },
  {
    piping_reducers_id: 94,
    nps: "32 x 26",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 813,
    d2: 660,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 115,
  },
  {
    piping_reducers_id: 95,
    nps: "32 x 24",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 813,
    d2: 610,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 115,
  },
  {
    piping_reducers_id: 96,
    nps: "34 x 32",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 864,
    d2: 813,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 123,
  },
  {
    piping_reducers_id: 97,
    nps: "34 x 30",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 864,
    d2: 762,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 123,
  },
  {
    piping_reducers_id: 98,
    nps: "34 x 26",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 864,
    d2: 660,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 123,
  },
  {
    piping_reducers_id: 99,
    nps: "34 x 24",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 864,
    d2: 610,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 123,
  },
  {
    piping_reducers_id: 100,
    nps: "36 x 34",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 914,
    d2: 864,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 129,
  },
  {
    piping_reducers_id: 101,
    nps: "36 x 32",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 914,
    d2: 813,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 129,
  },
  {
    piping_reducers_id: 102,
    nps: "36 x 30",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 914,
    d2: 762,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 129,
  },
  {
    piping_reducers_id: 103,
    nps: "36 x 26",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 914,
    d2: 660,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 129,
  },
  {
    piping_reducers_id: 104,
    nps: "36 x 24",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 914,
    d2: 610,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 129,
  },
  {
    piping_reducers_id: 105,
    nps: "38 x 36",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 965,
    d2: 914,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 136,
  },
  {
    piping_reducers_id: 106,
    nps: "38 x 34",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 965,
    d2: 864,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 136,
  },
  {
    piping_reducers_id: 107,
    nps: "38 x 32",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 965,
    d2: 813,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 136,
  },
  {
    piping_reducers_id: 108,
    nps: "38 x 30",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 965,
    d2: 762,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 136,
  },
  {
    piping_reducers_id: 109,
    nps: "38 x 28",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 965,
    d2: 711,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 136,
  },
  {
    piping_reducers_id: 110,
    nps: "38 x 26",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 965,
    d2: 660,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 136,
  },
  {
    piping_reducers_id: 111,
    nps: "40 x 38",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1016,
    d2: 965,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 143,
  },
  {
    piping_reducers_id: 112,
    nps: "40 x 36",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1016,
    d2: 914,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 143,
  },
  {
    piping_reducers_id: 113,
    nps: "40 x 34",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1016,
    d2: 864,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 143,
  },
  {
    piping_reducers_id: 114,
    nps: "40 x 32",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1016,
    d2: 813,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 143,
  },
  {
    piping_reducers_id: 115,
    nps: "40 x 30",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1016,
    d2: 762,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 143,
  },
  {
    piping_reducers_id: 116,
    nps: "42 x 40",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1067,
    d2: 1016,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 152,
  },
  {
    piping_reducers_id: 117,
    nps: "42 x 38",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1067,
    d2: 965,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 152,
  },
  {
    piping_reducers_id: 118,
    nps: "42 x 36",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1067,
    d2: 914,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 152,
  },
  {
    piping_reducers_id: 119,
    nps: "42 x 34",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1067,
    d2: 864,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 152,
  },
  {
    piping_reducers_id: 120,
    nps: "42 x 32",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1067,
    d2: 813,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 152,
  },
  {
    piping_reducers_id: 121,
    nps: "42 x 30",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1067,
    d2: 762,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 152,
  },
  {
    piping_reducers_id: 122,
    nps: "44 x 42",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1118,
    d2: 1067,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 159,
  },
  {
    piping_reducers_id: 123,
    nps: "44 x 40",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1118,
    d2: 1016,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 159,
  },
  {
    piping_reducers_id: 124,
    nps: "44 x 38",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1118,
    d2: 965,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 159,
  },
  {
    piping_reducers_id: 125,
    nps: "44 x 36",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1118,
    d2: 914,
    h: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 159,
  },
  {
    piping_reducers_id: 126,
    nps: "46 x 44",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1168,
    d2: 1118,
    h: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 170,
  },
  {
    piping_reducers_id: 127,
    nps: "46 x 42",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1168,
    d2: 1067,
    h: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 170,
  },
  {
    piping_reducers_id: 128,
    nps: "46 x 40",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1168,
    d2: 1016,
    h: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 170,
  },
  {
    piping_reducers_id: 129,
    nps: "46 x 38",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1168,
    d2: 965,
    h: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 170,
  },
  {
    piping_reducers_id: 130,
    nps: "48 x 46",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1219,
    d2: 1168,
    h: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 181,
  },
  {
    piping_reducers_id: 131,
    nps: "48 x 44",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1219,
    d2: 1118,
    h: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 181,
  },
  {
    piping_reducers_id: 132,
    nps: "48 x 42",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1219,
    d2: 1067,
    h: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 181,
  },
  {
    piping_reducers_id: 133,
    nps: "48 x 40",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 1219,
    d2: 1016,
    h: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t1: "9,52",
    t2: "9,52",
    weight: 181,
  },
  {
    piping_reducers_id: 134,
    nps: "3/4 x 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 27,
    d2: 21,
    h: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "3,91",
    t2: "3,73",
    weight: "0,1",
  },
  {
    piping_reducers_id: 135,
    nps: "3/4 x 3/8",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 27,
    d2: 17,
    h: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "3,91",
    t2: "3,2",
    weight: "0,1",
  },
  {
    piping_reducers_id: 136,
    nps: "1 x 3/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 33,
    d2: 27,
    h: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "4,55",
    t2: "3,91",
    weight: "0,16",
  },
  {
    piping_reducers_id: 137,
    nps: "1 x 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 33,
    d2: 21,
    h: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "4,55",
    t2: "3,73",
    weight: "0,16",
  },
  {
    piping_reducers_id: 138,
    nps: "1 1/4 x 1",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 42,
    d2: 33,
    h: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "4,85",
    t2: "4,55",
    weight: "0,23",
  },
  {
    piping_reducers_id: 139,
    nps: "1 1/4 x 3/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 42,
    d2: 27,
    h: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "4,85",
    t2: "3,91",
    weight: "0,23",
  },
  {
    piping_reducers_id: 140,
    nps: "1 1/4 x1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 42,
    d2: 21,
    h: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "4,85",
    t2: "3,73",
    weight: "0,23",
  },
  {
    piping_reducers_id: 141,
    nps: "1 1/2 x 1 1/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 48,
    d2: 42,
    h: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "5,08",
    t2: "4,85",
    weight: "0,35",
  },
  {
    piping_reducers_id: 142,
    nps: "1 1/2 x 1",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 48,
    d2: 33,
    h: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "5,08",
    t2: "4,55",
    weight: "0,35",
  },
  {
    piping_reducers_id: 143,
    nps: "1 1/2 x 3/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 48,
    d2: 27,
    h: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "5,08",
    t2: "3,91",
    weight: "0,35",
  },
  {
    piping_reducers_id: 144,
    nps: "1 1/2 x 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 48,
    d2: 21,
    h: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "5,08",
    t2: "3,73",
    weight: "0,35",
  },
  {
    piping_reducers_id: 145,
    nps: "2 x 1 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 60,
    d2: 48,
    h: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "5,54",
    t2: "5,08",
    weight: "0,57",
  },
  {
    piping_reducers_id: 146,
    nps: "2 x 1 1/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 60,
    d2: 42,
    h: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "5,54",
    t2: "4,85",
    weight: "0,57",
  },
  {
    piping_reducers_id: 147,
    nps: "2 x 1",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 60,
    d2: 33,
    h: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "5,54",
    t2: "4,55",
    weight: "0,57",
  },
  {
    piping_reducers_id: 148,
    nps: "2 x 3/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 60,
    d2: 27,
    h: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "5,54",
    t2: "3,91",
    weight: "0,57",
  },
  {
    piping_reducers_id: 149,
    nps: "2 1/2 x 2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 73,
    d2: 60,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "7,01",
    t2: "5,54",
    weight: "1,01",
  },
  {
    piping_reducers_id: 150,
    nps: "2 1/2 x 1 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 73,
    d2: 48,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "7,01",
    t2: "5,08",
    weight: "1,01",
  },
  {
    piping_reducers_id: 151,
    nps: "2 1/2 x 1 1/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 73,
    d2: 42,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "7,01",
    t2: "4,85",
    weight: "1,01",
  },
  {
    piping_reducers_id: 152,
    nps: "2 1/2 x 1",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 73,
    d2: 33,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "7,01",
    t2: "4,55",
    weight: "1,01",
  },
  {
    piping_reducers_id: 153,
    nps: "3 x 2 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 89,
    d2: 73,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "7,62",
    t2: "7,01",
    weight: "4,36",
  },
  {
    piping_reducers_id: 154,
    nps: "3 x 2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 89,
    d2: 60,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "7,62",
    t2: "5,54",
    weight: "1,36",
  },
  {
    piping_reducers_id: 155,
    nps: "3 x 1 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 89,
    d2: 48,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "7,62",
    t2: "5,08",
    weight: "1,36",
  },
  {
    piping_reducers_id: 156,
    nps: "3 x 1 1/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 89,
    d2: 42,
    h: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "7,62",
    t2: "4,85",
    weight: "1,36",
  },
  {
    piping_reducers_id: 157,
    nps: "3 1/2 x 3",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 102,
    d2: 89,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "8,08",
    t2: "7,62",
    weight: "1,89",
  },
  {
    piping_reducers_id: 158,
    nps: "3 1/2 x 2 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 102,
    d2: 73,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "8,08",
    t2: "7,01",
    weight: "1,89",
  },
  {
    piping_reducers_id: 159,
    nps: "3 1/2 x 2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 102,
    d2: 60,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "8,08",
    t2: "5,54",
    weight: "1,89",
  },
  {
    piping_reducers_id: 160,
    nps: "3 1/2 x 1 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 102,
    d2: 48,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "8,08",
    t2: "5,08",
    weight: "1,89",
  },
  {
    piping_reducers_id: 161,
    nps: "3 1/2 x 1 1/4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 102,
    d2: 42,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "8,08",
    t2: "4,85",
    weight: "1,89",
  },
  {
    piping_reducers_id: 162,
    nps: "4 x 3 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 114,
    d2: 102,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "8,56",
    t2: "8,08",
    weight: "2,27",
  },
  {
    piping_reducers_id: 163,
    nps: "4 x 3",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 114,
    d2: 89,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "8,56",
    t2: "7,62",
    weight: "2,27",
  },
  {
    piping_reducers_id: 164,
    nps: "4 x 2 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 114,
    d2: 73,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "8,56",
    t2: "7,01",
    weight: "2,27",
  },
  {
    piping_reducers_id: 165,
    nps: "4 x 2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 114,
    d2: 60,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "8,56",
    t2: "5,54",
    weight: "2,27",
  },
  {
    piping_reducers_id: 166,
    nps: "4 x 1 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 114,
    d2: 48,
    h: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "8,56",
    t2: "5,08",
    weight: "2,27",
  },
  {
    piping_reducers_id: 167,
    nps: "5 x 4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 141,
    d2: 114,
    h: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "9,52",
    t2: "8,56",
    weight: "3,93",
  },
  {
    piping_reducers_id: 168,
    nps: "5 x 3 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 141,
    d2: 102,
    h: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "9,52",
    t2: "8,08",
    weight: "3,93",
  },
  {
    piping_reducers_id: 169,
    nps: "5 x 3",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 141,
    d2: 89,
    h: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "9,52",
    t2: "7,62",
    weight: "3,93",
  },
  {
    piping_reducers_id: 170,
    nps: "5 x 2 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 141,
    d2: 73,
    h: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "9,52",
    t2: "7,01",
    weight: "3,93",
  },
  {
    piping_reducers_id: 171,
    nps: "5 x 2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 141,
    d2: 60,
    h: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "9,52",
    t2: "5,54",
    weight: "3,93",
  },
  {
    piping_reducers_id: 172,
    nps: "6 x 5",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 168,
    d2: 141,
    h: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "10,97",
    t2: "9,52",
    weight: "5,95",
  },
  {
    piping_reducers_id: 173,
    nps: "6 x 4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 168,
    d2: 114,
    h: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "10,97",
    t2: "8,56",
    weight: "5,95",
  },
  {
    piping_reducers_id: 174,
    nps: "6 x 3 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 168,
    d2: 102,
    h: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "10,97",
    t2: "8,08",
    weight: "5,95",
  },
  {
    piping_reducers_id: 175,
    nps: "6 x 3",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 168,
    d2: 89,
    h: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "10,97",
    t2: "7,62",
    weight: "5,95",
  },
  {
    piping_reducers_id: 176,
    nps: "6 x 2 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 168,
    d2: 73,
    h: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "10,97",
    t2: "7,01",
    weight: "5,95",
  },
  {
    piping_reducers_id: 177,
    nps: "8 x 6",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 219,
    d2: 168,
    h: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "10,97",
    weight: "9,86",
  },
  {
    piping_reducers_id: 178,
    nps: "8 x 5",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 219,
    d2: 141,
    h: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "9,52",
    weight: "9,86",
  },
  {
    piping_reducers_id: 179,
    nps: "8 x 4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 219,
    d2: 114,
    h: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "8,56",
    weight: "9,86",
  },
  {
    piping_reducers_id: 180,
    nps: "8 x 3 1/2",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 219,
    d2: 102,
    h: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "8,08",
    weight: "9,86",
  },
  {
    piping_reducers_id: 181,
    nps: "10 x 8",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 273,
    d2: 219,
    h: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: "14,5",
  },
  {
    piping_reducers_id: 182,
    nps: "10 x 6",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 273,
    d2: 168,
    h: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "10,97",
    weight: "14,5",
  },
  {
    piping_reducers_id: 183,
    nps: "10 x 5",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 273,
    d2: 141,
    h: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "9,52",
    weight: "14,5",
  },
  {
    piping_reducers_id: 184,
    nps: "10 x 4",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 273,
    d2: 114,
    h: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "8,56",
    weight: "14,5",
  },
  {
    piping_reducers_id: 185,
    nps: "12 x 10",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 324,
    d2: 273,
    h: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: "19,8",
  },
  {
    piping_reducers_id: 186,
    nps: "12 x 8",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 324,
    d2: 219,
    h: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: "19,8",
  },
  {
    piping_reducers_id: 187,
    nps: "12 x 6",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 324,
    d2: 168,
    h: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "10,97",
    weight: "19,8",
  },
  {
    piping_reducers_id: 188,
    nps: "12 x 5",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 324,
    d2: 141,
    h: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "9,52",
    weight: "19,8",
  },
  {
    piping_reducers_id: 189,
    nps: "14 x 12",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 356,
    d2: 324,
    h: 330,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: "35,5",
  },
  {
    piping_reducers_id: 190,
    nps: "14 x 10",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 356,
    d2: 273,
    h: 330,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: "35,5",
  },
  {
    piping_reducers_id: 191,
    nps: "14 x 8",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 356,
    d2: 219,
    h: 330,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: "35,5",
  },
  {
    piping_reducers_id: 192,
    nps: "14 x 6",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 356,
    d2: 168,
    h: 330,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "10,97",
    weight: "35,5",
  },
  {
    piping_reducers_id: 193,
    nps: "16 x 14",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 406,
    d2: 356,
    h: 356,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: 44,
  },
  {
    piping_reducers_id: 194,
    nps: "16 x 12",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 406,
    d2: 324,
    h: 356,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: 44,
  },
  {
    piping_reducers_id: 195,
    nps: "16 x 10",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 406,
    d2: 273,
    h: 356,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: 44,
  },
  {
    piping_reducers_id: 196,
    nps: "16 x 8",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 406,
    d2: 219,
    h: 356,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: 44,
  },
  {
    piping_reducers_id: 197,
    nps: "18 x 16",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 457,
    d2: 406,
    h: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: 53,
  },
  {
    piping_reducers_id: 198,
    nps: "18 x 14",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 457,
    d2: 356,
    h: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: 53,
  },
  {
    piping_reducers_id: 199,
    nps: "18 x 12",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 457,
    d2: 324,
    h: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: 53,
  },
  {
    piping_reducers_id: 200,
    nps: "18 x 10",
    material: "ASME B 16.9-1993",
    shape: "REDUCERS - CONCENTRIC AND ECCENTRIC",
    d1: 457,
    d2: 273,
    h: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t1: "12,7",
    t2: "12,7",
    weight: 53,
  },
].map((item) => ({
  ...item,
  id: item.piping_reducers_id,
  nps: item.nps + "",
  t1: fixValueToNumber(item.t1, "float"),
  t2: fixValueToNumber(item.t2, "float"),
  weight: fixValueToNumber(item.weight, "float"),
}));
