import { fixValueToNumber } from "../../components/3d-models/utils";

export const pipingElbows = [
  {
    piping_elbows_id: 3,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 33,
    a: 22,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,38",
    weight: "0,08",
  },
  {
    piping_elbows_id: 4,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 42,
    a: 25,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,56",
    weight: "0,12",
  },
  {
    piping_elbows_id: 5,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 48,
    a: 29,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,68",
    weight: "0,18",
  },
  {
    piping_elbows_id: 6,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 60,
    a: 35,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,91",
    weight: "0,32",
  },
  {
    piping_elbows_id: 7,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 73,
    a: 44,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,16",
    weight: "0,64",
  },
  {
    piping_elbows_id: 8,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 89,
    a: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,49",
    weight: "1,02",
  },
  {
    piping_elbows_id: 9,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 102,
    a: 57,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,74",
    weight: "1,43",
  },
  {
    piping_elbows_id: 10,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 114,
    a: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,02",
    weight: "1,95",
  },
  {
    piping_elbows_id: 11,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 141,
    a: 79,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,55",
    weight: "3,25",
  },
  {
    piping_elbows_id: 12,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 168,
    a: 95,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "7,11",
    weight: "5,1",
  },
  {
    piping_elbows_id: 13,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 219,
    a: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "8,18",
    weight: "11,15",
  },
  {
    piping_elbows_id: 14,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 273,
    a: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,27",
    weight: "20,5",
  },
  {
    piping_elbows_id: 15,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 324,
    a: 190,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 27,
  },
  {
    piping_elbows_id: 16,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 356,
    a: 222,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 34,
  },
  {
    piping_elbows_id: 17,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 406,
    a: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 48,
  },
  {
    piping_elbows_id: 18,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 457,
    a: 286,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 60,
  },
  {
    piping_elbows_id: 19,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 508,
    a: 318,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: "71,2",
  },
  {
    piping_elbows_id: 20,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 559,
    a: 343,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 89,
  },
  {
    piping_elbows_id: 21,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 610,
    a: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: "102,95",
  },
  {
    piping_elbows_id: 22,
    nps: 26,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 660,
    a: 406,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: "124,9",
  },
  {
    piping_elbows_id: 23,
    nps: 28,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 711,
    a: 438,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 150,
  },
  {
    piping_elbows_id: 24,
    nps: 30,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 762,
    a: 470,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 167,
  },
  {
    piping_elbows_id: 25,
    nps: 32,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 813,
    a: 502,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 193,
  },
  {
    piping_elbows_id: 26,
    nps: 34,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 864,
    a: 533,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 215,
  },
  {
    piping_elbows_id: 27,
    nps: 36,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 914,
    a: 565,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 241,
  },
  {
    piping_elbows_id: 28,
    nps: 38,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 965,
    a: 600,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 272,
  },
  {
    piping_elbows_id: 29,
    nps: 40,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 1016,
    a: 632,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 290,
  },
  {
    piping_elbows_id: 30,
    nps: 42,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 1067,
    a: 660,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 327,
  },
  {
    piping_elbows_id: 31,
    nps: 44,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 1118,
    a: 695,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 363,
  },
  {
    piping_elbows_id: 32,
    nps: 46,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 1168,
    a: 727,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 408,
  },
  {
    piping_elbows_id: 33,
    nps: 48,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 1219,
    a: 759,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 443,
  },
  {
    piping_elbows_id: 36,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 33,
    a: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,38",
    weight: "0,15",
  },
  {
    piping_elbows_id: 37,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 42,
    a: 48,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,56",
    weight: "0,25",
  },
  {
    piping_elbows_id: 38,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 48,
    a: 57,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,68",
    weight: "0,36",
  },
  {
    piping_elbows_id: 39,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 60,
    a: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,91",
    weight: "0,65",
  },
  {
    piping_elbows_id: 40,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 73,
    a: 95,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,16",
    weight: "1,28",
  },
  {
    piping_elbows_id: 41,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 89,
    a: 114,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,49",
    weight: "2,03",
  },
  {
    piping_elbows_id: 42,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 102,
    a: 133,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,74",
    weight: "2,87",
  },
  {
    piping_elbows_id: 43,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 114,
    a: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,02",
    weight: "3,9",
  },
  {
    piping_elbows_id: 44,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 141,
    a: 190,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,55",
    weight: "6,5",
  },
  {
    piping_elbows_id: 45,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 168,
    a: 229,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "7,11",
    weight: "10,2",
  },
  {
    piping_elbows_id: 46,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 219,
    a: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "8,18",
    weight: "20,3",
  },
  {
    piping_elbows_id: 47,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 273,
    a: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,27",
    weight: 37,
  },
  {
    piping_elbows_id: 48,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 324,
    a: 457,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 54,
  },
  {
    piping_elbows_id: 49,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 356,
    a: 533,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 68,
  },
  {
    piping_elbows_id: 50,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 406,
    a: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: "89,2",
  },
  {
    piping_elbows_id: 51,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 457,
    a: 686,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: "112,9",
  },
  {
    piping_elbows_id: 52,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 508,
    a: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: "142,4",
  },
  {
    piping_elbows_id: 53,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 559,
    a: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 178,
  },
  {
    piping_elbows_id: 54,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 610,
    a: 914,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 202,
  },
  {
    piping_elbows_id: 55,
    nps: 26,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 660,
    a: 991,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 270,
  },
  {
    piping_elbows_id: 56,
    nps: 28,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 711,
    a: 1067,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 300,
  },
  {
    piping_elbows_id: 57,
    nps: 30,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 762,
    a: 1143,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 333,
  },
  {
    piping_elbows_id: 58,
    nps: 32,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 813,
    a: 1219,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 390,
  },
  {
    piping_elbows_id: 59,
    nps: 34,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 864,
    a: 1295,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 430,
  },
  {
    piping_elbows_id: 60,
    nps: 36,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 914,
    a: 1372,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 482,
  },
  {
    piping_elbows_id: 61,
    nps: 38,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 965,
    a: 1448,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,53",
    weight: 540,
  },
  {
    piping_elbows_id: 62,
    nps: 40,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 1016,
    a: 1524,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 580,
  },
  {
    piping_elbows_id: 63,
    nps: 42,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 1067,
    a: 1600,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 655,
  },
  {
    piping_elbows_id: 64,
    nps: 44,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 1118,
    a: 1676,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,53",
    weight: 726,
  },
  {
    piping_elbows_id: 65,
    nps: 46,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 1168,
    a: 1753,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,53",
    weight: 816,
  },
  {
    piping_elbows_id: 66,
    nps: 48,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 1219,
    a: 1829,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 885,
  },
  {
    piping_elbows_id: 67,
    nps: 1,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 33,
    a: 25,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,38",
    weight: "0,1",
  },
  {
    piping_elbows_id: 68,
    nps: "1 1/4",
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 42,
    a: 32,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,56",
    weight: "0,16",
  },
  {
    piping_elbows_id: 69,
    nps: "1 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 48,
    a: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,68",
    weight: "0,24",
  },
  {
    piping_elbows_id: 70,
    nps: 2,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 60,
    a: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,91",
    weight: "0,43",
  },
  {
    piping_elbows_id: 71,
    nps: "2 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 73,
    a: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,16",
    weight: "0,82",
  },
  {
    piping_elbows_id: 72,
    nps: 3,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 89,
    a: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,49",
    weight: "1,35",
  },
  {
    piping_elbows_id: 73,
    nps: "3 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 102,
    a: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,74",
    weight: "1,9",
  },
  {
    piping_elbows_id: 74,
    nps: 4,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 114,
    a: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,02",
    weight: "2,6",
  },
  {
    piping_elbows_id: 75,
    nps: 5,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 141,
    a: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,55",
    weight: "4,35",
  },
  {
    piping_elbows_id: 76,
    nps: 6,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 168,
    a: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "7,11",
    weight: "6,45",
  },
  {
    piping_elbows_id: 77,
    nps: 8,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 219,
    a: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "8,18",
    weight: "16,5",
  },
  {
    piping_elbows_id: 78,
    nps: 10,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 273,
    a: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,27",
    weight: "31,15",
  },
  {
    piping_elbows_id: 79,
    nps: 12,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 324,
    a: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 45,
  },
  {
    piping_elbows_id: 80,
    nps: 14,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 356,
    a: 356,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: "52,5",
  },
  {
    piping_elbows_id: 81,
    nps: 16,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 406,
    a: 406,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: "71,25",
  },
  {
    piping_elbows_id: 82,
    nps: 18,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 457,
    a: 457,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 90,
  },
  {
    piping_elbows_id: 83,
    nps: 20,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 508,
    a: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 110,
  },
  {
    piping_elbows_id: 84,
    nps: 22,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 559,
    a: 559,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 132,
  },
  {
    piping_elbows_id: 85,
    nps: 24,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 610,
    a: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 165,
  },
  {
    piping_elbows_id: 88,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 33,
    a: 22,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,55",
    weight: "0,1",
  },
  {
    piping_elbows_id: 89,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 42,
    a: 25,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,85",
    weight: "0,18",
  },
  {
    piping_elbows_id: 90,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 48,
    a: 29,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,08",
    weight: "0,25",
  },
  {
    piping_elbows_id: 91,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 60,
    a: 35,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,54",
    weight: "0,47",
  },
  {
    piping_elbows_id: 92,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 73,
    a: 44,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,01",
    weight: "0,85",
  },
  {
    piping_elbows_id: 93,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 89,
    a: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,62",
    weight: "1,37",
  },
  {
    piping_elbows_id: 94,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 102,
    a: 57,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,08",
    weight: "1,97",
  },
  {
    piping_elbows_id: 95,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 114,
    a: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,56",
    weight: "2,7",
  },
  {
    piping_elbows_id: 96,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 141,
    a: 79,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "9,52",
    weight: "4,42",
  },
  {
    piping_elbows_id: 97,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 168,
    a: 95,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "10,97",
    weight: "7,67",
  },
  {
    piping_elbows_id: 98,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 219,
    a: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "14,95",
  },
  {
    piping_elbows_id: 99,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 273,
    a: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "24,3",
  },
  {
    piping_elbows_id: 100,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 324,
    a: 190,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 35,
  },
  {
    piping_elbows_id: 101,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 356,
    a: 222,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "47,17",
  },
  {
    piping_elbows_id: 102,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 406,
    a: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "62,37",
  },
  {
    piping_elbows_id: 103,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 457,
    a: 286,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 79,
  },
  {
    piping_elbows_id: 104,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 508,
    a: 318,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "97,16",
  },
  {
    piping_elbows_id: 105,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 559,
    a: 343,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 118,
  },
  {
    piping_elbows_id: 106,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 610,
    a: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "141,2",
  },
  {
    piping_elbows_id: 107,
    nps: 26,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 660,
    a: 406,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "165,71",
  },
  {
    piping_elbows_id: 108,
    nps: 28,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 711,
    a: 438,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 202,
  },
  {
    piping_elbows_id: 109,
    nps: 30,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 762,
    a: 470,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 220,
  },
  {
    piping_elbows_id: 110,
    nps: 32,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 813,
    a: 502,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 255,
  },
  {
    piping_elbows_id: 111,
    nps: 34,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 864,
    a: 533,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 280,
  },
  {
    piping_elbows_id: 112,
    nps: 36,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 914,
    a: 565,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 312,
  },
  {
    piping_elbows_id: 113,
    nps: 38,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 965,
    a: 600,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 354,
  },
  {
    piping_elbows_id: 114,
    nps: 40,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 1016,
    a: 632,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 372,
  },
  {
    piping_elbows_id: 115,
    nps: 42,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 1067,
    a: 660,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 420,
  },
  {
    piping_elbows_id: 116,
    nps: 44,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 1118,
    a: 695,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 488,
  },
  {
    piping_elbows_id: 117,
    nps: 46,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 1168,
    a: 727,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 530,
  },
  {
    piping_elbows_id: 118,
    nps: 48,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 1219,
    a: 759,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 567,
  },
  {
    piping_elbows_id: 121,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 33,
    a: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,55",
    weight: "0,2",
  },
  {
    piping_elbows_id: 122,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 42,
    a: 48,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,85",
    weight: "0,35",
  },
  {
    piping_elbows_id: 123,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 48,
    a: 57,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,08",
    weight: "0,6",
  },
  {
    piping_elbows_id: 124,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 60,
    a: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,54",
    weight: "0,94",
  },
  {
    piping_elbows_id: 125,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 73,
    a: 95,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,01",
    weight: "1,7",
  },
  {
    piping_elbows_id: 126,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 89,
    a: 114,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,62",
    weight: "2,75",
  },
  {
    piping_elbows_id: 127,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 102,
    a: 133,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,08",
    weight: "3,95",
  },
  {
    piping_elbows_id: 128,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 114,
    a: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,56",
    weight: "5,4",
  },
  {
    piping_elbows_id: 129,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 141,
    a: 190,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "9,52",
    weight: "8,85",
  },
  {
    piping_elbows_id: 130,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 168,
    a: 229,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "10,97",
    weight: "15,35",
  },
  {
    piping_elbows_id: 131,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 219,
    a: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "29,9",
  },
  {
    piping_elbows_id: 132,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 273,
    a: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "48,6",
  },
  {
    piping_elbows_id: 133,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 324,
    a: 457,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 70,
  },
  {
    piping_elbows_id: 134,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 356,
    a: 533,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "94,35",
  },
  {
    piping_elbows_id: 135,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 406,
    a: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "124,75",
  },
  {
    piping_elbows_id: 136,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 457,
    a: 686,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "157,5",
  },
  {
    piping_elbows_id: 137,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 508,
    a: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "194,3",
  },
  {
    piping_elbows_id: 138,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 559,
    a: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 236,
  },
  {
    piping_elbows_id: 139,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 610,
    a: 914,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "268,5",
  },
  {
    piping_elbows_id: 140,
    nps: 26,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 660,
    a: 991,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 348,
  },
  {
    piping_elbows_id: 141,
    nps: 28,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 711,
    a: 1067,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 404,
  },
  {
    piping_elbows_id: 142,
    nps: 30,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 762,
    a: 1143,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 441,
  },
  {
    piping_elbows_id: 143,
    nps: 32,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 813,
    a: 1219,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 510,
  },
  {
    piping_elbows_id: 144,
    nps: 34,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 864,
    a: 1295,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 560,
  },
  {
    piping_elbows_id: 145,
    nps: 36,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 914,
    a: 1372,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 625,
  },
  {
    piping_elbows_id: 146,
    nps: 38,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 965,
    a: 1448,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 710,
  },
  {
    piping_elbows_id: 147,
    nps: 40,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 1016,
    a: 1524,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 745,
  },
  {
    piping_elbows_id: 148,
    nps: 42,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 1067,
    a: 1600,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 840,
  },
  {
    piping_elbows_id: 149,
    nps: 44,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 1118,
    a: 1676,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 970,
  },
  {
    piping_elbows_id: 150,
    nps: 46,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 1168,
    a: 1753,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 1055,
  },
  {
    piping_elbows_id: 151,
    nps: 48,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 90,
    d: 1219,
    a: 1829,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 1134,
  },
  {
    piping_elbows_id: 152,
    nps: 1,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 33,
    a: 25,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,55",
    weight: "0,13",
  },
  {
    piping_elbows_id: 153,
    nps: "1 1/4",
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 42,
    a: 32,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,85",
    weight: "0,23",
  },
  {
    piping_elbows_id: 154,
    nps: "1 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 48,
    a: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,08",
    weight: "0,3",
  },
  {
    piping_elbows_id: 155,
    nps: 2,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 60,
    a: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,54",
    weight: "0,6",
  },
  {
    piping_elbows_id: 156,
    nps: "2 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 73,
    a: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,01",
    weight: "1,15",
  },
  {
    piping_elbows_id: 157,
    nps: 3,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 89,
    a: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,62",
    weight: "1,85",
  },
  {
    piping_elbows_id: 158,
    nps: "3 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 102,
    a: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,08",
    weight: "2,6",
  },
  {
    piping_elbows_id: 159,
    nps: 4,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 114,
    a: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,56",
    weight: "3,55",
  },
  {
    piping_elbows_id: 160,
    nps: 5,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 141,
    a: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "9,52",
    weight: "6,2",
  },
  {
    piping_elbows_id: 161,
    nps: 6,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 168,
    a: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "10,97",
    weight: "10,2",
  },
  {
    piping_elbows_id: 162,
    nps: 8,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 219,
    a: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "22,5",
  },
  {
    piping_elbows_id: 163,
    nps: 10,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 273,
    a: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "31,15",
  },
  {
    piping_elbows_id: 164,
    nps: 12,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 324,
    a: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "49,03",
  },
  {
    piping_elbows_id: 165,
    nps: 14,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 356,
    a: 356,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "63,11",
  },
  {
    piping_elbows_id: 166,
    nps: 16,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 406,
    a: 406,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "82,63",
  },
  {
    piping_elbows_id: 167,
    nps: 18,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 457,
    a: 457,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "104,87",
  },
  {
    piping_elbows_id: 168,
    nps: 20,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 508,
    a: 508,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "129,54",
  },
  {
    piping_elbows_id: 169,
    nps: 22,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 559,
    a: 559,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 157,
  },
  {
    piping_elbows_id: 170,
    nps: 24,
    material: "ASME B 16.28-1994",
    shape: "SR ELBOWS",
    degree: 90,
    d: 610,
    a: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "188,41",
  },
  {
    piping_elbows_id: 173,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 33,
    a: 22,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "9,09",
    weight: "0,19",
  },
  {
    piping_elbows_id: 174,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 42,
    a: 25,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "9,7",
    weight: "0,3",
  },
  {
    piping_elbows_id: 175,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 48,
    a: 29,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "10,16",
    weight: "0,45",
  },
  {
    piping_elbows_id: 176,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 60,
    a: 35,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "11,07",
    weight: "0,84",
  },
  {
    piping_elbows_id: 177,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 73,
    a: 44,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "14,02",
    weight: "1,6",
  },
  {
    piping_elbows_id: 178,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 89,
    a: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "15,24",
    weight: "2,6",
  },
  {
    piping_elbows_id: 179,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 102,
    a: 57,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 180,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 114,
    a: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "17,12",
    weight: "5,2",
  },
  {
    piping_elbows_id: 181,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 141,
    a: 79,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "19,05",
    weight: "9,08",
  },
  {
    piping_elbows_id: 182,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 168,
    a: 95,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "21,95",
    weight: 15,
  },
  {
    piping_elbows_id: 183,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 219,
    a: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "22,23",
    weight: 27,
  },
  {
    piping_elbows_id: 184,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 273,
    a: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "25,4",
    weight: "48,5",
  },
  {
    piping_elbows_id: 185,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 324,
    a: 190,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "25,4",
    weight: "70,8",
  },
  {
    piping_elbows_id: 186,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 356,
    a: 222,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 187,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 406,
    a: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 188,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 457,
    a: 286,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 189,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 508,
    a: 318,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 190,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 559,
    a: 343,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 191,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 610,
    a: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 192,
    nps: 26,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 660,
    a: 406,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 193,
    nps: 28,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 711,
    a: 438,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 194,
    nps: 30,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 762,
    a: 470,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 195,
    nps: 32,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 813,
    a: 502,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 196,
    nps: 34,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 864,
    a: 533,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 197,
    nps: 36,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 914,
    a: 565,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 198,
    nps: 38,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 965,
    a: 600,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 199,
    nps: 40,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 1016,
    a: 632,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_elbows_id: 200,
    nps: 42,
    material: "ASME B 16.9-1993",
    shape: "LR ELBOWS",
    degree: 45,
    d: 1067,
    a: 660,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
].map(item => ({
  ...item,
  id: item.piping_elbows_id,
  nps: item.nps + "",
  t: fixValueToNumber(item.t, "float"),
  weight: fixValueToNumber(item.weight, "float"),
}));
