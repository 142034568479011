import React, { useState, useEffect, useMemo } from 'react';
import { CustomDlg } from '../../../common/CustomDlg';
import { exportToCSV, fixNumberToStr, getElementByName, getGLTFItems2 } from '../../../3d-models/utils';
import { Button, Icon } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import Item from 'antd/es/list/Item';

type Props = {
  onClose: () => any;
}

export default function AssetMTO({ onClose }: Props) {
  const [activeTab, setActiveTab] = useState<string>("wall");
  // const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [GLTFItemsState, setGLTFItemsState] = useState<any[]>([]);
  const [totalWeight, setTotalWeight] = useState<number>(0);
  const [hiddenItems, setHiddenItems] = useState<Set<string>>(new Set());

  const scene = useSelector(
    (state: ApplicationState) => state.main.scene
  );

  const allProjects = useSelector(
    (state: ApplicationState) => state.main.projects
  );

  const currentProject = useSelector(
    (state: ApplicationState) => state.main.currentProject
  );

  const project_101 = useMemo(() => {
    return getElementByName(allProjects, currentProject);
  }, [allProjects, currentProject]);

  useEffect(() => {
    const handleSceneChange = () => {
      const updatedItems = getGLTFItems2(scene, project_101);
      setGLTFItemsState(updatedItems);
    };
  
    scene.addEventListener('objectChange', handleSceneChange);
  
    return () => {
      scene.removeEventListener('objectChange', handleSceneChange);
    };
  }, [scene, project_101]);

  useEffect(() => {
    const items = getGLTFItems2(scene, project_101);
    const nameCountMap = new Map<string, number>();
  
    const itemsWithUniqueIds = items.map((item) => {
      if (item.id) {
        return { ...item, uniqueId: item.id };
      } else {
        const itemName = item.name || "Unnamed";
        const count = nameCountMap.get(itemName) || 0;
        nameCountMap.set(itemName, count + 1);
        const uniqueId = count > 0 ? `${itemName}_${count + 1}` : itemName;
  
        return { ...item, uniqueId };
      }
    });
  
    console.log("These are the GLTF items with unique IDs:", itemsWithUniqueIds);
    setGLTFItemsState(itemsWithUniqueIds);
  }, [scene, project_101]);
  

  // const filteredGLTFItems = useMemo(() => {
  //   return GLTFItemsState
  //     .map((item, index) => ({ ...item, originalIndex: index })) 
  //     .filter(item => item.Type?.toLowerCase() === activeTab);  
  // }, [GLTFItemsState, activeTab]);

  const filteredGLTFItems = useMemo(() => {
    return GLTFItemsState
      .map((item, index) => ({ ...item, originalIndex: index })) 
      .filter(item => item.Type?.toLowerCase() === activeTab.toLowerCase());  
  }, [GLTFItemsState, activeTab]);
  

  useEffect(() => {
    const total = filteredGLTFItems.reduce((acc, item) => {
      const weight = parseFloat(item.Weight) || 0;
      return acc + weight;
    }, 0);
    setTotalWeight(total);
  }, [filteredGLTFItems]);

  const handleExportToCSV = () => {
    const selectedData = selectedItems.map(index => GLTFItemsState[index]);
    exportToCSV(selectedData, `export_${activeTab}.csv`);
  };

  const handleImportFromCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target?.result;
      if (content) {
        const parsedData = parseCSV(content);
        setGLTFItemsState(prevItems => [...prevItems, ...parsedData]);
      }
    };
    reader.readAsText(file);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedItems(filteredGLTFItems.map((item) => item.uniqueId));
    } else {
      setSelectedItems([]);
    }
  };
  
  const handleSelectItem = (uniqueId: string) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(uniqueId)) {
        return prevSelected.filter(id => id !== uniqueId);
      } else {
        return [...prevSelected, uniqueId];
      }
    });
  };
  
  const handleDeleteSelected = () => {
    console.log("Deleting selected items:", selectedItems);
  
    setGLTFItemsState(prevItems => {
      const remainingItems = prevItems.filter(item => !selectedItems.includes(item.uniqueId));
      selectedItems.forEach(uniqueId => {
        const itemToRemove = prevItems.find(item => item.uniqueId === uniqueId);
        if (itemToRemove) {
          console.log("Attempting to remove item from scene:", itemToRemove);
          const removedItems = new Set(); 
          const removeItemFromScene = (parent) => {
            parent.children.forEach(child => {
              if (child.userData && child.userData.isGLTFObject) {
                const matchById = child.userData.id === itemToRemove.id;
                const matchByName = child.userData.name === itemToRemove.name && child.userData.projectname === itemToRemove.projectname;
  
                if ((matchById) && !removedItems.has(child)) {
                  console.log("Removing child from scene:", child.userData);
                  parent.remove(child); 
                  removedItems.add(child); 
                } else if (child.children.length > 0) {
                  removeItemFromScene(child); 
                }
              }
            });
          };
  
          removeItemFromScene(scene); 
        } else {
          console.log("Item not found in previous GLTF items:", uniqueId);
        }
      });
  
      return remainingItems;
    });
  
    setSelectedItems([]); 
  };
  
  
  
  const handleInputChange = (uniqueId: string, key: string, value: string) => {
    setGLTFItemsState(prevItems => {
      const updatedItems = prevItems.map(item => 
        item.uniqueId === uniqueId ? { ...item, [key]: value } : item
      );
      const updatedItem = updatedItems.find(item => item.uniqueId === uniqueId);
      if (updatedItem) {
        updateSceneWithNewItem(updatedItem); 
      }
      return updatedItems;
    });
  };
  
  const handlePositionChange = (uniqueId: string, axis: 'x' | 'y' | 'z', value: number) => {
    setGLTFItemsState(prevItems => {
      const updatedItems = prevItems.map(item => 
        item.uniqueId === uniqueId ? { ...item, position: { ...item.position, [axis]: value } } : item
      );
      const updatedItem = updatedItems.find(item => item.uniqueId === uniqueId);
      if (updatedItem) {
        updateSceneWithNewItem(updatedItem); 
      }
      return updatedItems;
    });
  };

  const handleToggleVisibility = () => {
    console.log("Toggling visibility for selected items:", selectedItems);
  
    setGLTFItemsState((prevItems) => {
      const updatedItems = [...prevItems];
  
      selectedItems.forEach((uniqueId) => {
        const itemToToggle = updatedItems.find((item) => item.uniqueId === uniqueId);
        if (itemToToggle) {
          const toggleItemInScene = (parent) => {
            parent.children.forEach((child) => {
              if (child.userData && child.userData.isGLTFObject) {
                const matchById = child.userData.id === itemToToggle.id;
                const matchByName =
                  child.userData.name === itemToToggle.name &&
                  child.userData.projectname === itemToToggle.projectname;
  
                if (matchById || matchByName) {
                  console.log("Toggling visibility for item in scene:", child.userData);
                  child.visible = !child.visible; 
                } else if (child.children.length > 0) {
                  toggleItemInScene(child);
                }
              }
            });
          };
  
          toggleItemInScene(scene);
        } else {
          console.log("Item not found in GLTF items for toggling visibility:", uniqueId);
        }
      });
  
      return updatedItems;
    });
  };
  
  
  const updateSceneWithNewItem = (newItem: any) => {
    const updateItemInScene = (parent: any) => {
        parent.children.forEach((child: any) => {
            if (child.userData && child.userData.isGLTFObject) {
                const matchById = child.userData.id === newItem.uniqueId; 
                const matchByName = child.userData.name === newItem.name && child.userData.projectname === newItem.projectname; 
                
                if (matchById) {
                    console.log("Updating position for item in scene:", child.userData);
                    child.position.set(newItem.position.x, newItem.position.y, newItem.position.z);
                } else if (child.children.length > 0) {
                    updateItemInScene(child);
                }
            }
        });
    };

    updateItemInScene(scene); 
   };

  //  const getTableHeader = () => {
  //   return (
  //     <tr>
  //       <th><input type="checkbox" onChange={handleSelectAll} /></th>
  //       <th>ID</th><th>Name</th><th>Material</th>
  //       <th>Position X</th><th>Position Y</th><th>Position Z</th>
        
  //       {activeTab === 'wall' && (
  //         <>
  //           <th>Height (m)</th><th>Width (m)</th><th>Length (m)</th><th>Density</th><th>Weight (Kg)</th>
  //         </>
  //       )}
  //       {activeTab === 'slab' && (
  //         <>
  //           <th>Thickness (mm)</th><th>Width (m)</th><th>Length (m)</th><th>Density</th><th>Weight (Kg)</th>
  //         </>
  //       )}
  //       {activeTab === 'door' && (
  //         <>
  //           <th>Width (m)</th><th>Height (m)</th>
  //         </>
  //       )}
  //       {activeTab === 'window' && (
  //         <>
  //           <th>Glass Type</th><th>Height (m)</th><th>Length (m)</th>
  //         </>
  //       )}
  //       {activeTab === 'equipment' && (
  //         <>
  //           <th>Height (m)</th><th>Length (m)</th><th>Width (m)</th><th>Weight (Kg)</th>
  //         </>
  //       )}
  //       {activeTab === 'roof' && (
  //         <>
  //           <th>Base Level</th><th>Thickness (mm)</th><th>Rafter Cut</th>
  //         </>
  //       )}
  //       {activeTab === 'ceiling' && (
  //         <>
  //           <th>Level</th><th>Height Offset (m)</th><th>Material</th>
  //         </>
  //       )}
  //       {activeTab === 'stair' && (
  //         <>
  //           <th>Base Level</th><th>Top Level</th><th>Number of Steps</th><th>Tread Depth (m)</th><th>Riser Height (m)</th>
  //         </>
  //       )}
  //       {activeTab === 'beam' && (
  //         <>
  //           <th>Base Level</th><th>Material</th><th>Length (m)</th><th>Width (m)</th><th>Height (m)</th>
  //         </>
  //       )}
  //       {activeTab === 'column' && (
  //         <>
  //           <th>Base Level</th><th>Top Level</th><th>Material</th><th>Width (m)</th><th>Height (m)</th>
  //         </>
  //       )}
  //       {activeTab === 'mechanicalEquipment' && (
  //         <>
  //           <th>System Type</th><th>Capacity</th><th>Dimensions</th><th>Weight (Kg)</th>
  //         </>
  //       )}
  //       {activeTab === 'hvacEquipment' && (
  //         <>
  //           <th>System Type</th><th>Flow Rate (L/s)</th><th>Power (kW)</th><th>Dimensions</th><th>Weight (Kg)</th>
  //         </>
  //       )}
  //       {activeTab === 'plumbingFixture' && (
  //         <>
  //           <th>System Type</th><th>Flow Rate (L/s)</th><th>Capacity</th><th>Dimensions</th><th>Material</th>
  //         </>
  //       )}
  //       {activeTab === 'electricalEquipment' && (
  //         <>
  //           <th>System Type</th><th>Power Rating (kW)</th><th>Voltage (V)</th><th>Dimensions</th><th>Weight (Kg)</th>
  //         </>
  //       )}
  //       {activeTab === 'fireProtectionSystem' && (
  //         <>
  //           <th>System Type</th><th>Coverage Area (sq.m)</th><th>Pressure (Pa)</th><th>Flow Rate (L/s)</th><th>Material</th>
  //         </>
  //       )}
  //       {activeTab === 'elevator' && (
  //         <>
  //           <th>Capacity (Kg)</th><th>Speed (m/s)</th><th>Dimensions</th><th>Power (kW)</th>
  //         </>
  //       )}
  //     </tr>
  //   );
  // };
  

  const getTableHeader = () => {
    return (
      <tr>
        <th><input type="checkbox" onChange={handleSelectAll} /></th>
        <th>ID</th><th>Name</th><th>Material</th>
        <th>Position X</th><th>Position Y</th><th>Position Z</th>
        
        {activeTab === 'wall' && (
          <>
            <th>Height</th><th>Width</th><th>Length</th><th>Density</th><th>Weight</th>
          </>
        )}
        {activeTab === 'slab' && (
          <>
            <th>Thickness</th><th>Width</th><th>Length</th><th>Density</th><th>Weight</th>
          </>
        )}
        {activeTab === 'door' && (
          <>
            <th>Width</th><th>Height</th>
          </>
        )}
        {activeTab === 'window' && (
          <>
            <th>Glass Type</th><th>Height</th><th>Length</th>
          </>
        )}
        {activeTab === 'mechanical equipment' && (
          <>
            <th>System Type</th><th>Capacity</th><th>Dimensions</th><th>Weight</th>
          </>
        )}
        {activeTab === 'hvacEquipment' && (
          <>
            <th>System Type</th><th>Flow Rate</th><th>Power</th><th>Dimensions</th><th>Weight</th>
          </>
        )}
        {activeTab === 'plumbingFixture' && (
          <>
            <th>System Type</th><th>Flow Rate</th><th>Capacity</th><th>Dimensions</th><th>Material</th>
          </>
        )}
        {activeTab === 'electricalEquipment' && (
          <>
            <th>System Type</th><th>Power Rating</th><th>Voltage</th><th>Dimensions</th><th>Weight</th>
          </>
        )}
        {activeTab === 'fireProtectionSystem' && (
          <>
            <th>System Type</th><th>Coverage Area</th><th>Pressure</th><th>Flow Rate</th><th>Material</th>
          </>
        )}
        {activeTab === 'cableTray' && (
          <>
            <th>Horizontal Justification</th><th>Vertical Justification</th><th>Rung Space</th><th>Width</th><th>Height</th>
          </>
        )}
        {activeTab === 'flexDuct' && (
          <>
            <th>System Type</th><th>Loss Coefficient</th><th>Additional Flow</th><th>Velocity</th>
          </>
        )}
      </tr>
    );
  };
  

  const getTableBody = () => {
    return filteredGLTFItems.map((item) => {
      const index = item.uniqueId;
      console.log("these are the items", item)
      return (
        <tr key={index}>
          <td>
            <input
              className='w-60'
              type="checkbox"
              checked={selectedItems.includes(index)}
              onChange={() => handleSelectItem(index)}
            />
          </td>
          <td className='w-150'>{item.uniqueId || index}</td> 
          <td>
            <input
              className='w-150'
              type="text"
              value={item.name || ''}
              onChange={(e) => handleInputChange(index, 'name', e.target.value)}
            />
          </td>
          <td>
            <input
              className='w-150'
              type="text"
              value={item.Material || ''}
              onChange={(e) => handleInputChange(index, 'Material', e.target.value)}
            />
          </td>
          <td>
            <input
              className='w-90'
              type="number"
              step="0.01"
              value={item.position?.x.toFixed(2) || 0}
              onChange={(e) => handlePositionChange(index, 'x', parseFloat(e.target.value))}
            />
          </td>
          <td>
            <input
              className='w-90'
              type="number"
              step="0.01"
              value={item.position?.y.toFixed(2) || 0}
              onChange={(e) => handlePositionChange(index, 'y', parseFloat(e.target.value))}
            />
          </td>
          <td>
            <input
              className='w-90'
              type="number"
              step="0.01"
              value={item.position?.z.toFixed(2) || 0}
              onChange={(e) => handlePositionChange(index, 'z', parseFloat(e.target.value))}
            />
          </td>
          {activeTab === 'wall' && (
            <>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Height || ''}
                  onChange={(e) => handleInputChange(index, 'Height', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Width || ''}
                  onChange={(e) => handleInputChange(index, 'Width', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.length || ''}
                  onChange={(e) => handleInputChange(index, 'length', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Density || ''}
                  onChange={(e) => handleInputChange(index, 'Density', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Weight || ''}
                  onChange={(e) => handleInputChange(index, 'Weight', e.target.value)}
                />
              </td>
            </>
          )}
          {activeTab === 'slab' && (
            <>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Thickness || ''}
                  onChange={(e) => handleInputChange(index, 'Thickness', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Width || ''}
                  onChange={(e) => handleInputChange(index, 'Width', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.length || ''}
                  onChange={(e) => handleInputChange(index, 'length', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Density || ''}
                  onChange={(e) => handleInputChange(index, 'Density', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Weight || ''}
                  onChange={(e) => handleInputChange(index, 'Weight', e.target.value)}
                />
              </td>
            </>
          )}
          {activeTab === 'door' && (
            <>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Width || ''}
                  onChange={(e) => handleInputChange(index, 'Width', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Height || ''}
                  onChange={(e) => handleInputChange(index, 'Height', e.target.value)}
                />
              </td>
            </>
          )}
          {activeTab === 'window' && (
            <>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.GlassType || ''}
                  onChange={(e) => handleInputChange(index, 'GlassType', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Height || ''}
                  onChange={(e) => handleInputChange(index, 'Height', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.length || ''}
                  onChange={(e) => handleInputChange(index, 'length', e.target.value)}
                />
              </td>
            </>
          )}
          {activeTab === 'mechanicalEquipment' && (
            <>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.SystemType || ''}
                  onChange={(e) => handleInputChange(index, 'SystemType', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Capacity || ''}
                  onChange={(e) => handleInputChange(index, 'Capacity', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Dimnesions || ''}
                  onChange={(e) => handleInputChange(index, 'Dimnesions', e.target.value)}
                />
              </td>
              <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.Weight || ''}
                  onChange={(e) => handleInputChange(index, 'Weight', e.target.value)}
                />
              </td>
            </>
          )}
          {activeTab == "hvacEquipment" && (
            <>
               <td>
                <input
                  className='w-90'
                  type="text"
                  value={item.SystemType || ''}
                  onChange={(e) => handleInputChange(index, 'SystemType', e.target.value)}
                />
              </td>
              <td>
                <input
                    className='w-90'
                    type="text"
                    value={item.FlowRate || ''}
                    onChange={(e) => handleInputChange(index, 'FlowRate', e.target.value)}
                  />
              </td>
              <td>
                <input
                    className='w-90'
                    type="text"
                    value={item.Power || ''}
                    onChange={(e) => handleInputChange(index, 'Power', e.target.value)}
                  />
              </td>
              <td>
                <input
                    className='w-90'
                    type="text"
                    value={item.Dimensions || ''}
                    onChange={(e) => handleInputChange(index, 'Dimensions', e.target.value)}
                  />
              </td>
              <td>
                <input
                    className='w-90'
                    type="text"
                    value={item.Weight || ''}
                    onChange={(e) => handleInputChange(index, 'Weight', e.target.value)}
                  />
              </td>
            </>
          )}
          {activeTab === "plumbingFixture" && (
              <>
              <td>
              <input
                className='w-90'
                type="text"
                value={item.SystemType || ''}
                onChange={(e) => handleInputChange(index, 'SystemType', e.target.value)}
              />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.FlowRate || ''}
                  onChange={(e) => handleInputChange(index, 'FlowRate', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.Capacity || ''}
                  onChange={(e) => handleInputChange(index, 'Capacity', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.Dimensions || ''}
                  onChange={(e) => handleInputChange(index, 'Dimensions', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.Material || ''}
                  onChange={(e) => handleInputChange(index, 'Material', e.target.value)}
                />
            </td>
          </>
          )}
          {activeTab === "electricalEquipment" && (
            <>
              <td>
              <input
                className='w-90'
                type="text"
                value={item.SystemType || ''}
                onChange={(e) => handleInputChange(index, 'SystemType', e.target.value)}
              />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.PowerRating || ''}
                  onChange={(e) => handleInputChange(index, 'PowerRating', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.Voltage || ''}
                  onChange={(e) => handleInputChange(index, 'Voltage', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.Dimensions || ''}
                  onChange={(e) => handleInputChange(index, 'Dimensions', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.Weight || ''}
                  onChange={(e) => handleInputChange(index, 'Weight', e.target.value)}
                />
            </td>
          </>
          )}
          {activeTab === "fireProtectionSystem" && (
            <>
              <td>
              <input
                className='w-90'
                type="text"
                value={item.SystemType || ''}
                onChange={(e) => handleInputChange(index, 'SystemType', e.target.value)}
              />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.CoverageArea || ''}
                  onChange={(e) => handleInputChange(index, 'CoverageArea', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.Pressure || ''}
                  onChange={(e) => handleInputChange(index, 'Pressure', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.FlowRate || ''}
                  onChange={(e) => handleInputChange(index, 'FlowRate', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.Material || ''}
                  onChange={(e) => handleInputChange(index, 'Material', e.target.value)}
                />
            </td>
          </>
          )}
          {activeTab === "cableTray" && (
            <>
              <td>
              <input
                className='w-90'
                type="text"
                value={item.HorizontalJustification || ''}
                onChange={(e) => handleInputChange(index, 'HorizontalJustification', e.target.value)}
              />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.VerticalJustification || ''}
                  onChange={(e) => handleInputChange(index, 'VerticalJustification', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.RungSpace || ''}
                  onChange={(e) => handleInputChange(index, 'RungSpace', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.Width || ''}
                  onChange={(e) => handleInputChange(index, 'Width', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.Height || ''}
                  onChange={(e) => handleInputChange(index, 'Height', e.target.value)}
                />
            </td>
          </>
          )}
          {activeTab === "flexDuct" && (
            <>
              <td>
              <input
                className='w-90'
                type="text"
                value={item.SystemType || ''}
                onChange={(e) => handleInputChange(index, 'SystemType', e.target.value)}
              />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.LossCoefficient || ''}
                  onChange={(e) => handleInputChange(index, 'LossCoefficient', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.AdditionalFlow || ''}
                  onChange={(e) => handleInputChange(index, 'AdditionalFlow', e.target.value)}
                />
            </td>
            <td>
              <input
                  className='w-90'
                  type="text"
                  value={item.Velocity || ''}
                  onChange={(e) => handleInputChange(index, 'Velocity', e.target.value)}
                />
            </td>
          </>
          )}
        </tr>
      );
    });
  };

  //   const getTableBody = () => {
  //     return filteredGLTFItems.map((item) => {
  //         const index = item.uniqueId;
  //         const type = item.type; // Assume `item.type` defines the type (wall, slab, etc.)

  //         // Helper function to generate dynamic fields based on the item type
  //         const renderFieldsForType = () => {
  //             switch (type) {
  //                 case "wall":
  //                     return (
  //                         <>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Height || ""}
  //                                     onChange={(e) => handleInputChange(index, "Height", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Width || ""}
  //                                     onChange={(e) => handleInputChange(index, "Width", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.length || ""}
  //                                     onChange={(e) => handleInputChange(index, "length", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Density || ""}
  //                                     onChange={(e) => handleInputChange(index, "Density", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Weight || ""}
  //                                     onChange={(e) => handleInputChange(index, "Weight", e.target.value)}
  //                                 />
  //                             </td>
  //                         </>
  //                     );
  //                 case "slab":
  //                     return (
  //                         <>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Thickness || ""}
  //                                     onChange={(e) => handleInputChange(index, "Thickness", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Width || ""}
  //                                     onChange={(e) => handleInputChange(index, "Width", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.length || ""}
  //                                     onChange={(e) => handleInputChange(index, "length", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Density || ""}
  //                                     onChange={(e) => handleInputChange(index, "Density", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Weight || ""}
  //                                     onChange={(e) => handleInputChange(index, "Weight", e.target.value)}
  //                                 />
  //                             </td>
  //                         </>
  //                     );
  //                 case "door":
  //                     return (
  //                         <>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Width || ""}
  //                                     onChange={(e) => handleInputChange(index, "Width", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Height || ""}
  //                                     onChange={(e) => handleInputChange(index, "Height", e.target.value)}
  //                                 />
  //                             </td>
  //                         </>
  //                     );
  //                 case "window":
  //                     return (
  //                         <>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Height || ""}
  //                                     onChange={(e) => handleInputChange(index, "Height", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.length || ""}
  //                                     onChange={(e) => handleInputChange(index, "length", e.target.value)}
  //                                 />
  //                             </td>
  //                         </>
  //                     );
  //                 case "floor":
  //                     return (
  //                         <>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Thickness || ""}
  //                                     onChange={(e) => handleInputChange(index, "Thickness", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.ElevationAtTop || ""}
  //                                     onChange={(e) => handleInputChange(index, "ElevationAtTop", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.ElevationAtBottom || ""}
  //                                     onChange={(e) => handleInputChange(index, "ElevationAtBottom", e.target.value)}
  //                                 />
  //                             </td>
  //                         </>
  //                     );
  //                 case "roof":
  //                     return (
  //                         <>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.BaseLevel || ""}
  //                                     onChange={(e) => handleInputChange(index, "BaseLevel", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.CutoffLevel || ""}
  //                                     onChange={(e) => handleInputChange(index, "CutoffLevel", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Thickness || ""}
  //                                     onChange={(e) => handleInputChange(index, "Thickness", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <select
  //                                     className="w-90"
  //                                     value={item.RafterCut || ""}
  //                                     onChange={(e) => handleInputChange(index, "RafterCut", e.target.value)}
  //                                 >
  //                                     <option value="">Select</option>
  //                                     <option value="PlumbCut">Plumb Cut</option>
  //                                     <option value="SquareCut">Square Cut</option>
  //                                 </select>
  //                             </td>
  //                         </>
  //                     );
  //                 case "ceiling":
  //                     return (
  //                         <>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Level || ""}
  //                                     onChange={(e) => handleInputChange(index, "Level", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.HeightOffset || ""}
  //                                     onChange={(e) => handleInputChange(index, "HeightOffset", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Material || ""}
  //                                     onChange={(e) => handleInputChange(index, "Material", e.target.value)}
  //                                 />
  //                             </td>
  //                         </>
  //                     );
  //                 case "mechanicalEquipment":
  //                     return (
  //                         <>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.SystemType || ""}
  //                                     onChange={(e) => handleInputChange(index, "SystemType", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Capacity || ""}
  //                                     onChange={(e) => handleInputChange(index, "Capacity", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Dimensions || ""}
  //                                     onChange={(e) => handleInputChange(index, "Dimensions", e.target.value)}
  //                                 />
  //                             </td>
  //                             <td>
  //                                 <input
  //                                     className="w-90"
  //                                     type="text"
  //                                     value={item.Weight || ""}
  //                                     onChange={(e) => handleInputChange(index, "Weight", e.target.value)}
  //                                 />
  //                             </td>
  //                         </>
  //                     );
  //                 // Add cases for other types as needed...
  //                 default:
  //                     return null;
  //             }
  //         };

  //         return (
  //             <tr key={index}>
  //                 <td>
  //                     <input
  //                         className="w-60"
  //                         type="checkbox"
  //                         checked={selectedItems.includes(index)}
  //                         onChange={() => handleSelectItem(index)}
  //                     />
  //                 </td>
  //                 <td className="w-150">{item.uniqueId || index}</td>
  //                 <td>
  //                     <input
  //                         className="w-150"
  //                         type="text"
  //                         value={item.name || ""}
  //                         onChange={(e) => handleInputChange(index, "name", e.target.value)}
  //                     />
  //                 </td>
  //                 <td>
  //                     <input
  //                         className="w-150"
  //                         type="text"
  //                         value={item.Material || ""}
  //                         onChange={(e) => handleInputChange(index, "Material", e.target.value)}
  //                     />
  //                 </td>
  //                 <td>
  //                     <input
  //                         className="w-90"
  //                         type="number"
  //                         step="0.01"
  //                         value={item.position?.x.toFixed(2) || 0}
  //                         onChange={(e) => handlePositionChange(index, "x", parseFloat(e.target.value))}
  //                     />
  //                 </td>
  //                 <td>
  //                     <input
  //                         className="w-90"
  //                         type="number"
  //                         step="0.01"
  //                         value={item.position?.y.toFixed(2) || 0}
  //                         onChange={(e) => handlePositionChange(index, "y", parseFloat(e.target.value))}
  //                     />
  //                 </td>
  //                 <td>
  //                     <input
  //                         className="w-90"
  //                         type="number"
  //                         step="0.01"
  //                         value={item.position?.z.toFixed(2) || 0}
  //                         onChange={(e) => handlePositionChange(index, "z", parseFloat(e.target.value))}
  //                     />
  //                 </td>
  //                 {renderFieldsForType()}
  //             </tr>
  //         );
  //     });
  // };


  return (
    <CustomDlg
      title="Asset Manager"
      onClose={onClose}
      body={
        <div className="d-flex f-column f-grow">
          <div className="d-flex f-ai-center bg-dark label-light">
            <Button
                className='w-120'
                text={"wall"}
                onClick={() => setActiveTab("wall")}
            />
            <Button
                className='w-120'
                text={
                <>
                  Slab
                </>
                }
                onClick={() => setActiveTab("slab")}
            />
            <Button
                className='w-120'
                text={
                <>
                  Door
                </>
                }
                onClick={() => setActiveTab("door")}
            />
            <Button
                className='w-120'
                text={
                <>
                 Window
                </>
                }
                onClick={() => setActiveTab("window")}
            />
            <Button
                className='w-120'
                text={
                <>
                  Mechanical Equipment
                </>
                }
                onClick={() => setActiveTab("mechanicalEquipment")}
            />
            <Button
                className='w-120'
                text={
                <>
                  HVAC Equipment
                </>
                }
                onClick={() => setActiveTab("hvacEquipment")}
            />
            <Button
                className='w-120'
                text={
                <>
                  Plumbing Fixture
                </>
                }
                onClick={() => setActiveTab("plumbingFixture")}
            />
            <Button
                className='w-120'
                text={
                <>
                  Electrical Equipment
                </>
                }
                onClick={() => setActiveTab("electricalEquipment")}
            />
            <Button
                className='w-120'
                text={
                <>
                  Fire Protection System
                </>
                }
                onClick={() => setActiveTab("fireProtectionSystem")}
            />
            <Button
                className='w-120'
                text={
                <>
                  Cable Tray
                </>
                }
                onClick={() => setActiveTab("cableTray")}
            />
            <Button
                className='w-120'
                text={
                <>
                  Flex Duct
                </>
                }
                onClick={() => setActiveTab("flexDuct")}
            />
            </div>

          <div className='d-flex f-ai-center bg-dark label-light'>
            <Button small intent="danger" icon="delete" text="Delete Selected" onClick={handleDeleteSelected} disabled={selectedItems.length === 0} />
            <Button small intent="primary" icon="export" text="Export to CSV" onClick={handleExportToCSV} disabled={selectedItems.length === 0} />
            <Button small intent="success" icon="import" text="Import from CSV">
              <input type="file" accept=".csv" onChange={handleImportFromCSV} style={{ display: 'none' }} />
            </Button>
            <Button
                small
                intent="warning"
                icon="eye-off"
                text="Toggle Visibility"
                onClick={handleToggleVisibility}
                disabled={selectedItems.length === 0}
            />
          </div>
          <div className="hr" />
          <div className={"p-5 bg-dark"}>
            <div className={"table-container"}>
              <table className="table bg-gray">
                <thead>
                  {getTableHeader()}
                </thead>
                <tbody>
                  {getTableBody()}
                  <tr style={{ backgroundColor: '#444', color: '#fff', fontWeight: 'bold' }}> 
                    <td colSpan={
                      activeTab === 'equipment' ? 10 :
                      activeTab === 'wall' ? 11 : 
                      activeTab === 'slab' ? 11 :
                      activeTab === 'door' ? 9 : 
                      activeTab === 'window' ? 10 :
                      activeTab === 'mechanicalEquipment' ? 11 :
                      activeTab === 'hvacEquipment' ? 11 :
                      activeTab === 'plumbingFixture' ? 12 :
                      activeTab === 'electricalEquipment' ? 11 :
                      activeTab === 'fireProtectionSystem' ? 12 :
                      activeTab === 'cableTray' ? 11 :
                      activeTab === 'flexDuct' ? 11 :
                      8} style={{ textAlign: 'right' }}>
                      Total Weight:
                    </td>
                    <td>
                      {totalWeight.toFixed(2)} Kg
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          
        </div>
      }
    />
  );
}
