import React from 'react';
import { useSelector } from "react-redux";
import { ApplicationState } from '../../store';
import { Card, HotkeysProvider, Icon, IconSize } from '@blueprintjs/core';
import { Cell, Column, CellRenderer, Table2 } from "@blueprintjs/table";
import logo from "../../assets/A-Logo-White-Transparent.png";
import "./userDetails.css";
import Axios from 'axios';
import { secondServerAPI } from '../utils/agent';
import { Link} from "react-router-dom";

interface User {
    username: string;
    email: string;
    userId: number;
    companyName: string;
    idsRegistrationOn: string;
    productAccess: string[];
    currentlyLoggedIn: boolean;
    currentLoginTime: string | null;
    loginActivitiesOfUser: {
        date: string;
        loginActivities: {
            loginTime: string;
            logoutTime: string;
            durationOfLogin: string;
        }[];
        totalLoginDurationInADay: string;
    }[];
    totalLoginHours: string;
}

interface UserDetailsProps {
    user: User;
  }

  const UserDetails: React.FC<UserDetailsProps> = ({ user }) => {
    const dateCellRenderer: CellRenderer = (rowIndex) => <Cell>{user.loginActivitiesOfUser[rowIndex].date}</Cell>;

    const totalLoginDurationCellRenderer: CellRenderer = (rowIndex) => (
      <Cell>{user.loginActivitiesOfUser[rowIndex].totalLoginDurationInADay}</Cell>
    );

    const loginDetailsCellRenderer: CellRenderer = (rowIndex) => {
      const loginActivities = user.loginActivitiesOfUser[rowIndex].loginActivities.map((activity, index) => (
        <div key={index} className="login-activity">
          <p>Login: {activity.loginTime} - Logout: {activity.logoutTime}</p>
        </div>
      ));

      return <Cell>{loginActivities}</Cell>;
    };
    return (
      <div className="user-details">
        <Card className="user-details-card">
          <h1>{user.username}</h1>
          <p><Icon icon="envelope" intent='primary' iconSize={25}/><span className='span-class'>Email:</span> {user.email}</p>
          <p><Icon icon="office" intent='success' iconSize={25}/><span className='span-class'>Company:</span> {user.companyName}</p>
          <p><Icon icon="add-row-top" intent='warning' iconSize={25}/><span className='span-class'>IDS Registration On:</span> {user.idsRegistrationOn}</p>
          <p><Icon icon="key" intent='danger' iconSize={25}/><span className='span-class'>Products Access:</span> {user.productAccess.join(' || ')}</p>
          <p><Icon icon="log-in" intent='primary' iconSize={25}/><span className='span-class'>Currently Logged In:</span> {user.currentlyLoggedIn}</p>
          <p><Icon icon="time" intent='success' iconSize={25}/><span className='span-class'>Login Time:</span> {user.currentLoginTime}</p>
          <p><Icon icon="calculator" intent='warning' iconSize={25}/><span className='span-class'>Total Login Hours:</span> {user.totalLoginHours}</p>
        </Card>
        <ul className="user-details-list">
  {user.loginActivitiesOfUser.map((activity, index) => (
    <li key={index} className="list-item">
      <div className="activity-date">Date: {activity.date}</div>
      <div className="activity-duration">Total Login Duration: {activity.totalLoginDurationInADay}</div>
      <ul>
        {activity.loginActivities.map((detail, detailIndex) => (
          <li key={detailIndex} className="detail">
            <div>Login: {detail.loginTime} - Logout: {detail.logoutTime} - Duration: {detail.durationOfLogin}</div>
          </li>
        ))}
      </ul>
    </li>
  ))}
</ul>


      </div>
      
    );
  };
  
  export default UserDetails;