import { fixValueToNumber } from "../../../components/3d-models/utils";

export const pipingCS = [
  {
    piping_data_id: 1,
    nominal_pipe_size_inch: "1/8",
    schedule: "10S",
    outside_diameter: "0,41",
    wall_thickness: "0,05",
    weight: "0,19",
    outside_diameter_global: "10,3",
    wall_thickness_global: "1,24",
    weight_global: "0,28",
    ixx: "23565,22",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 2,
    nominal_pipe_size_inch: "1/8",
    schedule: "Std.",
    outside_diameter: "0,41",
    wall_thickness: "0,07",
    weight: "0,24",
    outside_diameter_global: "10,3",
    wall_thickness_global: "1,73",
    weight_global: "0,36",
    ixx: "28333,83",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 3,
    nominal_pipe_size_inch: "1/8",
    schedule: "X-Stg.",
    outside_diameter: "0,41",
    wall_thickness: "0,1",
    weight: "0,31",
    outside_diameter_global: "10,3",
    wall_thickness_global: "2,41",
    weight_global: "0,47",
    ixx: "32386,65",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 4,
    nominal_pipe_size_inch: "1/4",
    schedule: "10S",
    outside_diameter: "0,54",
    wall_thickness: "0,07",
    weight: "0,33",
    outside_diameter_global: "13,7",
    wall_thickness_global: "1,65",
    weight_global: "0,49",
    ixx: "74237,99",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 5,
    nominal_pipe_size_inch: "1/4",
    schedule: "Std.",
    outside_diameter: "0,54",
    wall_thickness: "0,09",
    weight: "0,42",
    outside_diameter_global: "13,7",
    wall_thickness_global: "2,24",
    weight_global: "0,63",
    ixx: "88232,79",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 6,
    nominal_pipe_size_inch: "1/4",
    schedule: "X-Stg.",
    outside_diameter: "0,54",
    wall_thickness: "0,12",
    weight: "0,54",
    outside_diameter_global: "13,7",
    wall_thickness_global: "3,02",
    weight_global: "0,8",
    ixx: "100311,39",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 7,
    nominal_pipe_size_inch: "3/8",
    schedule: "10S",
    outside_diameter: "0,68",
    wall_thickness: "0,07",
    weight: "0,42",
    outside_diameter_global: "17,1",
    wall_thickness_global: "1,65",
    weight_global: "0,63",
    ixx: "156092,12",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 8,
    nominal_pipe_size_inch: "3/8",
    schedule: "Std.",
    outside_diameter: "0,68",
    wall_thickness: "0,09",
    weight: "0,57",
    outside_diameter_global: "17,1",
    wall_thickness_global: "2,31",
    weight_global: "0,84",
    ixx: "194210,88",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 9,
    nominal_pipe_size_inch: "3/8",
    schedule: "X-Stg.",
    outside_diameter: "0,68",
    wall_thickness: "0,13",
    weight: "0,74",
    outside_diameter_global: "17,1",
    wall_thickness_global: "3,2",
    weight_global: "1,1",
    ixx: "229591,71",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 10,
    nominal_pipe_size_inch: "1/2",
    schedule: "10S",
    outside_diameter: "0,84",
    wall_thickness: "0,08",
    weight: "0,67",
    outside_diameter_global: "21,3",
    wall_thickness_global: "2,11",
    weight_global: 1,
    ixx: "381180,14",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 11,
    nominal_pipe_size_inch: "1/2",
    schedule: "Std.",
    outside_diameter: "0,84",
    wall_thickness: "0,11",
    weight: "0,85",
    outside_diameter_global: "21,3",
    wall_thickness_global: "2,77",
    weight_global: "1,27",
    ixx: "455306,33",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 12,
    nominal_pipe_size_inch: "1/2",
    schedule: "X-Stg.",
    outside_diameter: "0,84",
    wall_thickness: "0,15",
    weight: "1,09",
    outside_diameter_global: "21,3",
    wall_thickness_global: "3,73",
    weight_global: "1,62",
    ixx: "534818,16",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 13,
    nominal_pipe_size_inch: "1/2",
    schedule: 160,
    outside_diameter: "0,84",
    wall_thickness: "0,14",
    weight: "1,31",
    outside_diameter_global: "21,3",
    wall_thickness_global: "3,51",
    weight_global: "1,95",
    ixx: "518718,74",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 14,
    nominal_pipe_size_inch: "1/2",
    schedule: "XX-Stg.",
    outside_diameter: "0,84",
    wall_thickness: "0,29",
    weight: "1,71",
    outside_diameter_global: "21,3",
    wall_thickness_global: "7,47",
    weight_global: "2,55",
    ixx: "645757,97",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 15,
    nominal_pipe_size_inch: "3/4",
    schedule: "10S",
    outside_diameter: "1,05",
    wall_thickness: "0,08",
    weight: "0,86",
    outside_diameter_global: "26,7",
    wall_thickness_global: "2,11",
    weight_global: "1,28",
    ixx: "790897,23",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 16,
    nominal_pipe_size_inch: "3/4",
    schedule: "Std.",
    outside_diameter: "1,05",
    wall_thickness: "0,11",
    weight: "1,13",
    outside_diameter_global: "26,7",
    wall_thickness_global: "2,87",
    weight_global: "1,68",
    ixx: "986603,7",
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 17,
    nominal_pipe_size_inch: "3/4",
    schedule: "X-Stg.",
    outside_diameter: "1,05",
    wall_thickness: "0,15",
    weight: "1,47",
    outside_diameter_global: "26,7",
    wall_thickness_global: "3,91",
    weight_global: "2,19",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 18,
    nominal_pipe_size_inch: "3/4",
    schedule: 160,
    outside_diameter: "1,05",
    wall_thickness: "0,22",
    weight: "1,94",
    outside_diameter_global: "26,7",
    wall_thickness_global: "5,56",
    weight_global: "2,89",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 19,
    nominal_pipe_size_inch: "3/4",
    schedule: "XX-Stg.",
    outside_diameter: "1,05",
    wall_thickness: "0,31",
    weight: "2,44",
    outside_diameter_global: "26,7",
    wall_thickness_global: "7,82",
    weight_global: "3,63",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 20,
    nominal_pipe_size_inch: 1,
    schedule: "10S",
    outside_diameter: "1,32",
    wall_thickness: "0,11",
    weight: "1,4",
    outside_diameter_global: "33,4",
    wall_thickness_global: "2,77",
    weight_global: "2,09",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 21,
    nominal_pipe_size_inch: 1,
    schedule: "Std.",
    outside_diameter: "1,32",
    wall_thickness: "0,13",
    weight: "1,68",
    outside_diameter_global: "33,4",
    wall_thickness_global: "3,38",
    weight_global: "2,5",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 22,
    nominal_pipe_size_inch: 1,
    schedule: "X-Stg.",
    outside_diameter: "1,32",
    wall_thickness: "0,18",
    weight: "2,17",
    outside_diameter_global: "33,4",
    wall_thickness_global: "4,55",
    weight_global: "3,23",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 23,
    nominal_pipe_size_inch: 1,
    schedule: 160,
    outside_diameter: "1,32",
    wall_thickness: "0,25",
    weight: "2,85",
    outside_diameter_global: "33,4",
    wall_thickness_global: "6,35",
    weight_global: "4,24",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 24,
    nominal_pipe_size_inch: 1,
    schedule: "XX-Stg.",
    outside_diameter: "1,32",
    wall_thickness: "0,36",
    weight: "3,66",
    outside_diameter_global: "33,4",
    wall_thickness_global: "9,09",
    weight_global: "5,45",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 25,
    nominal_pipe_size_inch: "1 1/4",
    schedule: "10S",
    outside_diameter: "1,66",
    wall_thickness: "0,11",
    weight: "1,81",
    outside_diameter_global: "42,2",
    wall_thickness_global: "2,77",
    weight_global: "2,69",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 26,
    nominal_pipe_size_inch: "1 1/4",
    schedule: "Std.",
    outside_diameter: "1,66",
    wall_thickness: "0,14",
    weight: "2,27",
    outside_diameter_global: "42,2",
    wall_thickness_global: "3,56",
    weight_global: "3,38",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 27,
    nominal_pipe_size_inch: "1 1/4",
    schedule: "-",
    outside_diameter: "1,66",
    wall_thickness: "0,19",
    weight: 3,
    outside_diameter_global: "42,2",
    wall_thickness_global: "4,85",
    weight_global: "4,46",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 28,
    nominal_pipe_size_inch: "1 1/4",
    schedule: "-",
    outside_diameter: "1,66",
    wall_thickness: "0,25",
    weight: "3,76",
    outside_diameter_global: "42,2",
    wall_thickness_global: "6,35",
    weight_global: "5,6",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 29,
    nominal_pipe_size_inch: "1 1/4",
    schedule: "-",
    outside_diameter: "1,66",
    wall_thickness: "0,38",
    weight: "5,21",
    outside_diameter_global: "42,2",
    wall_thickness_global: "9,7",
    weight_global: "7,76",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 30,
    nominal_pipe_size_inch: "1 1/2",
    schedule: "10S",
    outside_diameter: "1,9",
    wall_thickness: "0,11",
    weight: "2,09",
    outside_diameter_global: "48,3",
    wall_thickness_global: "2,77",
    weight_global: "3,1",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 31,
    nominal_pipe_size_inch: "1 1/2",
    schedule: "Std.",
    outside_diameter: "1,9",
    wall_thickness: "0,15",
    weight: "2,72",
    outside_diameter_global: "48,3",
    wall_thickness_global: "3,68",
    weight_global: "4,04",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 32,
    nominal_pipe_size_inch: "1 1/2",
    schedule: "X-Stg.",
    outside_diameter: "1,9",
    wall_thickness: "0,2",
    weight: "3,63",
    outside_diameter_global: "48,3",
    wall_thickness_global: "5,08",
    weight_global: "5,4",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 33,
    nominal_pipe_size_inch: "1 1/2",
    schedule: 160,
    outside_diameter: "1,9",
    wall_thickness: "0,28",
    weight: "4,86",
    outside_diameter_global: "48,3",
    wall_thickness_global: "7,14",
    weight_global: "7,24",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 34,
    nominal_pipe_size_inch: "1 1/2",
    schedule: "XX-Stg.",
    outside_diameter: "1,9",
    wall_thickness: "0,4",
    weight: "6,41",
    outside_diameter_global: "48,3",
    wall_thickness_global: "10,16",
    weight_global: "9,54",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 35,
    nominal_pipe_size_inch: 2,
    schedule: "10S",
    outside_diameter: "2,38",
    wall_thickness: "0,11",
    weight: "2,64",
    outside_diameter_global: "60,3",
    wall_thickness_global: "2,77",
    weight_global: "3,93",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 36,
    nominal_pipe_size_inch: 2,
    schedule: "Std.",
    outside_diameter: "2,38",
    wall_thickness: "0,15",
    weight: "3,65",
    outside_diameter_global: "60,3",
    wall_thickness_global: "3,91",
    weight_global: "5,44",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 37,
    nominal_pipe_size_inch: 2,
    schedule: "X-Stg.",
    outside_diameter: "2,38",
    wall_thickness: "0,22",
    weight: "5,02",
    outside_diameter_global: "60,3",
    wall_thickness_global: "5,54",
    weight_global: "7,47",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 38,
    nominal_pipe_size_inch: 2,
    schedule: "-",
    outside_diameter: "2,38",
    wall_thickness: "0,25",
    weight: "5,67",
    outside_diameter_global: "60,3",
    wall_thickness_global: "6,35",
    weight_global: "8,44",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 39,
    nominal_pipe_size_inch: 2,
    schedule: 160,
    outside_diameter: "2,38",
    wall_thickness: "0,34",
    weight: "7,45",
    outside_diameter_global: "60,3",
    wall_thickness_global: "8,74",
    weight_global: "11,09",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 40,
    nominal_pipe_size_inch: 2,
    schedule: "XX-Stg.",
    outside_diameter: "2,38",
    wall_thickness: "0,44",
    weight: "9,03",
    outside_diameter_global: "60,3",
    wall_thickness_global: "11,07",
    weight_global: "13,44",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 41,
    nominal_pipe_size_inch: "2 1/2",
    schedule: "10S",
    outside_diameter: "2,88",
    wall_thickness: "0,12",
    weight: "3,53",
    outside_diameter_global: 73,
    wall_thickness_global: "3,05",
    weight_global: "5,25",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 42,
    nominal_pipe_size_inch: "2 1/2",
    schedule: "Std.",
    outside_diameter: "2,88",
    wall_thickness: "0,2",
    weight: "5,79",
    outside_diameter_global: 73,
    wall_thickness_global: "5,16",
    weight_global: "8,62",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 43,
    nominal_pipe_size_inch: "2 1/2",
    schedule: "X-Stg.",
    outside_diameter: "2,88",
    wall_thickness: "0,28",
    weight: "7,66",
    outside_diameter_global: 73,
    wall_thickness_global: "7,01",
    weight_global: "11,4",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 44,
    nominal_pipe_size_inch: "2 1/2",
    schedule: 160,
    outside_diameter: "2,88",
    wall_thickness: "0,38",
    weight: "10,01",
    outside_diameter_global: 73,
    wall_thickness_global: "9,53",
    weight_global: "14,9",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 45,
    nominal_pipe_size_inch: "2 1/2",
    schedule: "XX-Stg.",
    outside_diameter: "2,88",
    wall_thickness: "0,55",
    weight: "13,69",
    outside_diameter_global: 73,
    wall_thickness_global: "14,02",
    weight_global: "20,37",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 46,
    nominal_pipe_size_inch: 3,
    schedule: "10S",
    outside_diameter: "3,5",
    wall_thickness: "0,12",
    weight: "4,33",
    outside_diameter_global: "88,9",
    wall_thickness_global: "3,05",
    weight_global: "6,44",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 47,
    nominal_pipe_size_inch: 3,
    schedule: "API",
    outside_diameter: "3,5",
    wall_thickness: "0,13",
    weight: "4,52",
    outside_diameter_global: "88,9",
    wall_thickness_global: "3,18",
    weight_global: "6,73",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 48,
    nominal_pipe_size_inch: 3,
    schedule: "API",
    outside_diameter: "3,5",
    wall_thickness: "0,16",
    weight: "5,58",
    outside_diameter_global: "88,9",
    wall_thickness_global: "3,96",
    weight_global: "8,3",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 49,
    nominal_pipe_size_inch: 3,
    schedule: "API",
    outside_diameter: "3,5",
    wall_thickness: "0,19",
    weight: "6,65",
    outside_diameter_global: "88,9",
    wall_thickness_global: "4,78",
    weight_global: "9,9",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 50,
    nominal_pipe_size_inch: 3,
    schedule: "Std.",
    outside_diameter: "3,5",
    wall_thickness: "0,22",
    weight: "7,57",
    outside_diameter_global: "88,9",
    wall_thickness_global: "5,49",
    weight_global: "11,27",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 51,
    nominal_pipe_size_inch: 3,
    schedule: "API",
    outside_diameter: "3,5",
    wall_thickness: "0,25",
    weight: "8,68",
    outside_diameter_global: "88,9",
    wall_thickness_global: "6,35",
    weight_global: "12,92",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 52,
    nominal_pipe_size_inch: 3,
    schedule: "API",
    outside_diameter: "3,5",
    wall_thickness: "0,28",
    weight: "9,65",
    outside_diameter_global: "88,9",
    wall_thickness_global: "7,14",
    weight_global: "14,36",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 53,
    nominal_pipe_size_inch: 3,
    schedule: "X-Stg.",
    outside_diameter: "3,5",
    wall_thickness: "0,3",
    weight: "10,25",
    outside_diameter_global: "88,9",
    wall_thickness_global: "7,62",
    weight_global: "15,25",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 54,
    nominal_pipe_size_inch: 3,
    schedule: 160,
    outside_diameter: "3,5",
    wall_thickness: "0,44",
    weight: "14,32",
    outside_diameter_global: "88,9",
    wall_thickness_global: "11,13",
    weight_global: "21,31",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 55,
    nominal_pipe_size_inch: 3,
    schedule: "XX-Stg.",
    outside_diameter: "3,5",
    wall_thickness: "0,6",
    weight: "18,58",
    outside_diameter_global: "88,9",
    wall_thickness_global: "15,24",
    weight_global: "27,65",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 56,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "10S",
    outside_diameter: 4,
    wall_thickness: "0,12",
    weight: "4,97",
    outside_diameter_global: "101,6",
    wall_thickness_global: "3,05",
    weight_global: "7,4",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 57,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "API",
    outside_diameter: 4,
    wall_thickness: "0,13",
    weight: "5,18",
    outside_diameter_global: "101,6",
    wall_thickness_global: "3,18",
    weight_global: "7,71",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 58,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "API",
    outside_diameter: 4,
    wall_thickness: "0,16",
    weight: "6,41",
    outside_diameter_global: "101,6",
    wall_thickness_global: "3,96",
    weight_global: "9,54",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 59,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "API",
    outside_diameter: 4,
    wall_thickness: "0,19",
    weight: "7,71",
    outside_diameter_global: "101,6",
    wall_thickness_global: "4,78",
    weight_global: "11,47",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 60,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "Std.",
    outside_diameter: 4,
    wall_thickness: "0,23",
    weight: "9,11",
    outside_diameter_global: "101,6",
    wall_thickness_global: "5,74",
    weight_global: "13,56",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 61,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "API",
    outside_diameter: 4,
    wall_thickness: "0,25",
    weight: "10,02",
    outside_diameter_global: "101,6",
    wall_thickness_global: "6,35",
    weight_global: "14,91",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 62,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "API",
    outside_diameter: 4,
    wall_thickness: "0,28",
    weight: "11,17",
    outside_diameter_global: "101,6",
    wall_thickness_global: "7,14",
    weight_global: "16,62",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 63,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "X-Stg.",
    outside_diameter: 4,
    wall_thickness: "0,32",
    weight: "12,51",
    outside_diameter_global: "101,6",
    wall_thickness_global: "8,08",
    weight_global: "18,62",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 64,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "XX-Stg.",
    outside_diameter: 4,
    wall_thickness: "0,64",
    weight: "22,85",
    outside_diameter_global: "101,6",
    wall_thickness_global: "16,15",
    weight_global: "34,01",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 65,
    nominal_pipe_size_inch: 4,
    schedule: "10S",
    outside_diameter: "4,5",
    wall_thickness: "0,12",
    weight: "5,61",
    outside_diameter_global: "114,3",
    wall_thickness_global: "3,05",
    weight_global: "8,35",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 66,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,13",
    weight: "5,84",
    outside_diameter_global: "114,3",
    wall_thickness_global: "3,18",
    weight_global: "8,69",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 67,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,16",
    weight: "7,24",
    outside_diameter_global: "114,3",
    wall_thickness_global: "3,96",
    weight_global: "10,77",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 68,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,19",
    weight: "8,56",
    outside_diameter_global: "114,3",
    wall_thickness_global: "4,78",
    weight_global: "12,74",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 69,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,22",
    weight: "10,02",
    outside_diameter_global: "114,3",
    wall_thickness_global: "5,56",
    weight_global: "14,91",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 70,
    nominal_pipe_size_inch: 4,
    schedule: "Std.",
    outside_diameter: "4,5",
    wall_thickness: "0,24",
    weight: "10,79",
    outside_diameter_global: "114,3",
    wall_thickness_global: "6,02",
    weight_global: "16,06",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 71,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,25",
    weight: "11,35",
    outside_diameter_global: "114,3",
    wall_thickness_global: "6,35",
    weight_global: "16,89",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 72,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,28",
    weight: "12,67",
    outside_diameter_global: "114,3",
    wall_thickness_global: "7,14",
    weight_global: "18,86",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 73,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,31",
    weight: 14,
    outside_diameter_global: "114,3",
    wall_thickness_global: "7,92",
    weight_global: "20,83",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 74,
    nominal_pipe_size_inch: 4,
    schedule: "X-Stg.",
    outside_diameter: "4,5",
    wall_thickness: "0,34",
    weight: "14,98",
    outside_diameter_global: "114,3",
    wall_thickness_global: "8,56",
    weight_global: "22,29",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 75,
    nominal_pipe_size_inch: 4,
    schedule: 120,
    outside_diameter: "4,5",
    wall_thickness: "0,44",
    weight: 19,
    outside_diameter_global: "114,3",
    wall_thickness_global: "11,13",
    weight_global: "28,28",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 76,
    nominal_pipe_size_inch: 4,
    schedule: "--",
    outside_diameter: "4,5",
    wall_thickness: "0,5",
    weight: "21,36",
    outside_diameter_global: "114,3",
    wall_thickness_global: "12,7",
    weight_global: "31,79",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 77,
    nominal_pipe_size_inch: 4,
    schedule: 160,
    outside_diameter: "4,5",
    wall_thickness: "0,53",
    weight: "22,6",
    outside_diameter_global: "114,3",
    wall_thickness_global: "13,49",
    weight_global: "33,63",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 78,
    nominal_pipe_size_inch: 4,
    schedule: "XX-Stg.",
    outside_diameter: "4,5",
    wall_thickness: "0,67",
    weight: "27,54",
    outside_diameter_global: "114,3",
    wall_thickness_global: "17,12",
    weight_global: "40,98",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 79,
    nominal_pipe_size_inch: 5,
    schedule: "10S",
    outside_diameter: "5,56",
    wall_thickness: "0,13",
    weight: "7,77",
    outside_diameter_global: "141,3",
    wall_thickness_global: "3,4",
    weight_global: "11,56",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 80,
    nominal_pipe_size_inch: 5,
    schedule: "API",
    outside_diameter: "5,56",
    wall_thickness: "0,16",
    weight: "9,02",
    outside_diameter_global: "141,3",
    wall_thickness_global: "3,96",
    weight_global: "13,42",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 81,
    nominal_pipe_size_inch: 5,
    schedule: "API",
    outside_diameter: "5,56",
    wall_thickness: "0,19",
    weight: "10,8",
    outside_diameter_global: "141,3",
    wall_thickness_global: "4,78",
    weight_global: "16,07",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 82,
    nominal_pipe_size_inch: 5,
    schedule: "API",
    outside_diameter: "5,56",
    wall_thickness: "0,22",
    weight: "12,51",
    outside_diameter_global: "141,3",
    wall_thickness_global: "5,56",
    weight_global: "18,62",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 83,
    nominal_pipe_size_inch: 5,
    schedule: "Std.",
    outside_diameter: "5,56",
    wall_thickness: "0,26",
    weight: "14,62",
    outside_diameter_global: "141,3",
    wall_thickness_global: "6,55",
    weight_global: "21,76",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 84,
    nominal_pipe_size_inch: 5,
    schedule: "API",
    outside_diameter: "5,56",
    wall_thickness: "0,28",
    weight: "15,86",
    outside_diameter_global: "141,3",
    wall_thickness_global: "7,14",
    weight_global: "23,6",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 85,
    nominal_pipe_size_inch: 5,
    schedule: "API",
    outside_diameter: "5,56",
    wall_thickness: "0,31",
    weight: "17,51",
    outside_diameter_global: "141,3",
    wall_thickness_global: "7,92",
    weight_global: "26,06",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 86,
    nominal_pipe_size_inch: 5,
    schedule: "API",
    outside_diameter: "5,56",
    wall_thickness: "0,34",
    weight: "19,19",
    outside_diameter_global: "141,3",
    wall_thickness_global: "8,74",
    weight_global: "28,56",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 87,
    nominal_pipe_size_inch: 5,
    schedule: "X-Stg.",
    outside_diameter: "5,56",
    wall_thickness: "0,38",
    weight: "20,78",
    outside_diameter_global: "141,3",
    wall_thickness_global: "9,53",
    weight_global: "30,92",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 88,
    nominal_pipe_size_inch: 5,
    schedule: 120,
    outside_diameter: "5,56",
    wall_thickness: "0,5",
    weight: "27,1",
    outside_diameter_global: "141,3",
    wall_thickness_global: "12,7",
    weight_global: "40,33",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 89,
    nominal_pipe_size_inch: 5,
    schedule: 160,
    outside_diameter: "5,56",
    wall_thickness: "0,63",
    weight: "32,96",
    outside_diameter_global: "141,3",
    wall_thickness_global: "15,88",
    weight_global: "49,05",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 90,
    nominal_pipe_size_inch: 5,
    schedule: "XX-Stg.",
    outside_diameter: "5,56",
    wall_thickness: "0,75",
    weight: "38,55",
    outside_diameter_global: "141,3",
    wall_thickness_global: "19,05",
    weight_global: "57,37",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 91,
    nominal_pipe_size_inch: 6,
    schedule: "12 Ga.",
    outside_diameter: "6,63",
    wall_thickness: "0,1",
    weight: "7,25",
    outside_diameter_global: "168,3",
    wall_thickness_global: "2,64",
    weight_global: "10,79",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 92,
    nominal_pipe_size_inch: 6,
    schedule: "10S",
    outside_diameter: "6,63",
    wall_thickness: "0,13",
    weight: "9,29",
    outside_diameter_global: "168,3",
    wall_thickness_global: "3,4",
    weight_global: "13,83",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 93,
    nominal_pipe_size_inch: 6,
    schedule: "8 Ga.",
    outside_diameter: "6,63",
    wall_thickness: "0,16",
    weight: "11,33",
    outside_diameter_global: "168,3",
    wall_thickness_global: "4,17",
    weight_global: "16,86",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 94,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,19",
    weight: "12,93",
    outside_diameter_global: "168,3",
    wall_thickness_global: "4,78",
    weight_global: "19,24",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 95,
    nominal_pipe_size_inch: 6,
    schedule: "6Ga.",
    outside_diameter: "6,63",
    wall_thickness: "0,19",
    weight: "13,34",
    outside_diameter_global: "168,3",
    wall_thickness_global: "4,93",
    weight_global: "19,85",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 96,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,22",
    weight: "15,02",
    outside_diameter_global: "168,3",
    wall_thickness_global: "5,56",
    weight_global: "22,35",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 97,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,25",
    weight: "17,02",
    outside_diameter_global: "168,3",
    wall_thickness_global: "6,35",
    weight_global: "25,33",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 98,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,28",
    weight: "18,86",
    outside_diameter_global: "168,3",
    wall_thickness_global: "7,04",
    weight_global: "28,07",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 99,
    nominal_pipe_size_inch: 6,
    schedule: "Std.",
    outside_diameter: "6,63",
    wall_thickness: "0,28",
    weight: "18,97",
    outside_diameter_global: "168,3",
    wall_thickness_global: "7,11",
    weight_global: "28,23",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 100,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,31",
    weight: "21,05",
    outside_diameter_global: "168,3",
    wall_thickness_global: "7,92",
    weight_global: "31,33",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 101,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,34",
    weight: "23,09",
    outside_diameter_global: "168,3",
    wall_thickness_global: "8,74",
    weight_global: "34,36",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 102,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,38",
    weight: "25,1",
    outside_diameter_global: "168,3",
    wall_thickness_global: "9,53",
    weight_global: "37,35",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 103,
    nominal_pipe_size_inch: 6,
    schedule: "X-Stg.",
    outside_diameter: "6,63",
    wall_thickness: "0,43",
    weight: "28,57",
    outside_diameter_global: "168,3",
    wall_thickness_global: "10,97",
    weight_global: "42,52",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 104,
    nominal_pipe_size_inch: 6,
    schedule: "--",
    outside_diameter: "6,63",
    wall_thickness: "0,5",
    weight: "32,79",
    outside_diameter_global: "168,3",
    wall_thickness_global: "12,7",
    weight_global: "48,8",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 105,
    nominal_pipe_size_inch: 6,
    schedule: 120,
    outside_diameter: "6,63",
    wall_thickness: "0,56",
    weight: "36,4",
    outside_diameter_global: "168,3",
    wall_thickness_global: "14,27",
    weight_global: "54,17",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 106,
    nominal_pipe_size_inch: 6,
    schedule: 160,
    outside_diameter: "6,63",
    wall_thickness: "0,72",
    weight: "45,3",
    outside_diameter_global: "168,3",
    wall_thickness_global: "18,26",
    weight_global: "67,41",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 107,
    nominal_pipe_size_inch: 6,
    schedule: "XX-Stg.",
    outside_diameter: "6,63",
    wall_thickness: "0,86",
    weight: "53,16",
    outside_diameter_global: "168,3",
    wall_thickness_global: "21,95",
    weight_global: "79,11",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 108,
    nominal_pipe_size_inch: 8,
    schedule: "12 Ga.",
    outside_diameter: "8,63",
    wall_thickness: "0,1",
    weight: "9,47",
    outside_diameter_global: "219,1",
    wall_thickness_global: "2,64",
    weight_global: "14,09",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 109,
    nominal_pipe_size_inch: 8,
    schedule: "10 Ga.",
    outside_diameter: "8,63",
    wall_thickness: "0,13",
    weight: "12,16",
    outside_diameter_global: "219,1",
    wall_thickness_global: "3,4",
    weight_global: "18,1",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 110,
    nominal_pipe_size_inch: 8,
    schedule: "10S",
    outside_diameter: "8,63",
    wall_thickness: "0,15",
    weight: "13,4",
    outside_diameter_global: "219,1",
    wall_thickness_global: "3,76",
    weight_global: "19,94",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 111,
    nominal_pipe_size_inch: 8,
    schedule: "8 Ga.",
    outside_diameter: "8,63",
    wall_thickness: "0,16",
    weight: "14,83",
    outside_diameter_global: "219,1",
    wall_thickness_global: "4,17",
    weight_global: "22,07",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 112,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,19",
    weight: "16,9",
    outside_diameter_global: "219,1",
    wall_thickness_global: "4,78",
    weight_global: "25,15",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 113,
    nominal_pipe_size_inch: 8,
    schedule: "6 Ga.",
    outside_diameter: "8,63",
    wall_thickness: "0,19",
    weight: "17,48",
    outside_diameter_global: "219,1",
    wall_thickness_global: "4,93",
    weight_global: "26,01",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 114,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,2",
    weight: "18,3",
    outside_diameter_global: "219,1",
    wall_thickness_global: "5,16",
    weight_global: "27,23",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 115,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,22",
    weight: "19,64",
    outside_diameter_global: "219,1",
    wall_thickness_global: "5,56",
    weight_global: "29,23",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 116,
    nominal_pipe_size_inch: 8,
    schedule: "3 Ga.",
    outside_diameter: "8,63",
    wall_thickness: "0,24",
    weight: "21,42",
    outside_diameter_global: "219,1",
    wall_thickness_global: "6,07",
    weight_global: "31,88",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 117,
    nominal_pipe_size_inch: 8,
    schedule: 20,
    outside_diameter: "8,63",
    wall_thickness: "0,25",
    weight: "22,4",
    outside_diameter_global: "219,1",
    wall_thickness_global: "6,35",
    weight_global: "33,34",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 118,
    nominal_pipe_size_inch: 8,
    schedule: 30,
    outside_diameter: "8,63",
    wall_thickness: "0,28",
    weight: "24,7",
    outside_diameter_global: "219,1",
    wall_thickness_global: "7,04",
    weight_global: "36,76",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 119,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,31",
    weight: "27,72",
    outside_diameter_global: "219,1",
    wall_thickness_global: "7,92",
    weight_global: "41,25",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 120,
    nominal_pipe_size_inch: 8,
    schedule: "Std.",
    outside_diameter: "8,63",
    wall_thickness: "0,32",
    weight: "28,55",
    outside_diameter_global: "219,1",
    wall_thickness_global: "8,18",
    weight_global: "42,49",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 121,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,34",
    weight: "30,4",
    outside_diameter_global: "219,1",
    wall_thickness_global: "8,74",
    weight_global: "45,24",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 122,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,38",
    weight: "33,1",
    outside_diameter_global: "219,1",
    wall_thickness_global: "9,53",
    weight_global: "49,26",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 123,
    nominal_pipe_size_inch: 8,
    schedule: 60,
    outside_diameter: "8,63",
    wall_thickness: "0,41",
    weight: "35,7",
    outside_diameter_global: "219,1",
    wall_thickness_global: "10,31",
    weight_global: "53,13",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 124,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,44",
    weight: "38,33",
    outside_diameter_global: "219,1",
    wall_thickness_global: "11,13",
    weight_global: "57,04",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 125,
    nominal_pipe_size_inch: 8,
    schedule: "X-Stg.",
    outside_diameter: "8,63",
    wall_thickness: "0,5",
    weight: "43,39",
    outside_diameter_global: "219,1",
    wall_thickness_global: "12,7",
    weight_global: "64,57",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 126,
    nominal_pipe_size_inch: 8,
    schedule: 100,
    outside_diameter: "8,63",
    wall_thickness: "0,59",
    weight: "50,9",
    outside_diameter_global: "219,1",
    wall_thickness_global: "15,09",
    weight_global: "75,75",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 127,
    nominal_pipe_size_inch: 8,
    schedule: "--",
    outside_diameter: "8,63",
    wall_thickness: "0,63",
    weight: "53,4",
    outside_diameter_global: "219,1",
    wall_thickness_global: "15,88",
    weight_global: "79,47",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 128,
    nominal_pipe_size_inch: 8,
    schedule: 120,
    outside_diameter: "8,63",
    wall_thickness: "0,72",
    weight: "60,7",
    outside_diameter_global: "219,1",
    wall_thickness_global: "18,26",
    weight_global: "90,33",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 129,
    nominal_pipe_size_inch: 8,
    schedule: 140,
    outside_diameter: "8,63",
    wall_thickness: "0,81",
    weight: "67,8",
    outside_diameter_global: "219,1",
    wall_thickness_global: "20,62",
    weight_global: "100,9",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 130,
    nominal_pipe_size_inch: 8,
    schedule: "XX-Stg.",
    outside_diameter: "8,63",
    wall_thickness: "0,88",
    weight: "72,42",
    outside_diameter_global: "219,1",
    wall_thickness_global: "22,23",
    weight_global: "107,77",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 131,
    nominal_pipe_size_inch: 8,
    schedule: 160,
    outside_diameter: "8,63",
    wall_thickness: "0,91",
    weight: "74,7",
    outside_diameter_global: "219,1",
    wall_thickness_global: "23,01",
    weight_global: "111,17",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 132,
    nominal_pipe_size_inch: 10,
    schedule: "12 Ga.",
    outside_diameter: "10,75",
    wall_thickness: "0,1",
    weight: "11,83",
    outside_diameter_global: "273,1",
    wall_thickness_global: "2,64",
    weight_global: "17,61",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 133,
    nominal_pipe_size_inch: 10,
    schedule: "10 Ga.",
    outside_diameter: "10,75",
    wall_thickness: "0,13",
    weight: "15,21",
    outside_diameter_global: "273,1",
    wall_thickness_global: "3,4",
    weight_global: "22,64",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 134,
    nominal_pipe_size_inch: 10,
    schedule: "8 Ga.",
    outside_diameter: "10,75",
    wall_thickness: "0,16",
    weight: "18,56",
    outside_diameter_global: "273,1",
    wall_thickness_global: "4,17",
    weight_global: "27,62",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 135,
    nominal_pipe_size_inch: 10,
    schedule: "10S",
    outside_diameter: "10,75",
    wall_thickness: "0,17",
    weight: "18,65",
    outside_diameter_global: "273,1",
    wall_thickness_global: "4,19",
    weight_global: "27,75",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 136,
    nominal_pipe_size_inch: 10,
    schedule: "API",
    outside_diameter: "10,75",
    wall_thickness: "0,19",
    weight: "21,12",
    outside_diameter_global: "273,1",
    wall_thickness_global: "4,78",
    weight_global: "31,43",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 137,
    nominal_pipe_size_inch: 10,
    schedule: "6 Ga.",
    outside_diameter: "10,75",
    wall_thickness: "0,19",
    weight: "21,89",
    outside_diameter_global: "273,1",
    wall_thickness_global: "4,93",
    weight_global: "32,58",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 138,
    nominal_pipe_size_inch: 10,
    schedule: "API",
    outside_diameter: "10,75",
    wall_thickness: "0,2",
    weight: "22,86",
    outside_diameter_global: "273,1",
    wall_thickness_global: "5,16",
    weight_global: "34,02",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 139,
    nominal_pipe_size_inch: 10,
    schedule: "API",
    outside_diameter: "10,75",
    wall_thickness: "0,22",
    weight: "24,6",
    outside_diameter_global: "273,1",
    wall_thickness_global: "5,56",
    weight_global: "36,61",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 140,
    nominal_pipe_size_inch: 10,
    schedule: "3 Ga.",
    outside_diameter: "10,75",
    wall_thickness: "0,24",
    weight: "28,05",
    outside_diameter_global: "273,1",
    wall_thickness_global: "6,07",
    weight_global: "41,74",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 141,
    nominal_pipe_size_inch: 10,
    schedule: 20,
    outside_diameter: "10,75",
    wall_thickness: "0,25",
    weight: "28,03",
    outside_diameter_global: "273,1",
    wall_thickness_global: "6,35",
    weight_global: "41,71",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 142,
    nominal_pipe_size_inch: 10,
    schedule: "API",
    outside_diameter: "10,75",
    wall_thickness: "0,28",
    weight: "31,2",
    outside_diameter_global: "273,1",
    wall_thickness_global: "7,09",
    weight_global: "46,43",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 143,
    nominal_pipe_size_inch: 10,
    schedule: 30,
    outside_diameter: "10,75",
    wall_thickness: "0,31",
    weight: "34,24",
    outside_diameter_global: "273,1",
    wall_thickness_global: "7,8",
    weight_global: "50,96",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 144,
    nominal_pipe_size_inch: 10,
    schedule: "API",
    outside_diameter: "10,75",
    wall_thickness: "0,34",
    weight: "38,26",
    outside_diameter_global: "273,1",
    wall_thickness_global: "8,74",
    weight_global: "56,94",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 145,
    nominal_pipe_size_inch: 10,
    schedule: "Std.",
    outside_diameter: "10,75",
    wall_thickness: "0,37",
    weight: "40,48",
    outside_diameter_global: "273,1",
    wall_thickness_global: "9,27",
    weight_global: "60,24",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 146,
    nominal_pipe_size_inch: 10,
    schedule: "API",
    outside_diameter: "10,75",
    wall_thickness: "0,44",
    weight: "48,28",
    outside_diameter_global: "273,1",
    wall_thickness_global: "11,13",
    weight_global: "71,85",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 147,
    nominal_pipe_size_inch: 10,
    schedule: "X-Stg.",
    outside_diameter: "10,75",
    wall_thickness: "0,5",
    weight: "54,74",
    outside_diameter_global: "273,1",
    wall_thickness_global: "12,7",
    weight_global: "81,46",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 148,
    nominal_pipe_size_inch: 10,
    schedule: 80,
    outside_diameter: "10,75",
    wall_thickness: "0,59",
    weight: "64,4",
    outside_diameter_global: "273,1",
    wall_thickness_global: "15,09",
    weight_global: "95,84",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 149,
    nominal_pipe_size_inch: 10,
    schedule: 100,
    outside_diameter: "10,75",
    wall_thickness: "0,72",
    weight: 77,
    outside_diameter_global: "273,1",
    wall_thickness_global: "18,26",
    weight_global: "114,59",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 150,
    nominal_pipe_size_inch: 10,
    schedule: "--",
    outside_diameter: "10,75",
    wall_thickness: "0,75",
    weight: "80,1",
    outside_diameter_global: "273,1",
    wall_thickness_global: "19,05",
    weight_global: "119,2",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 151,
    nominal_pipe_size_inch: 10,
    schedule: 120,
    outside_diameter: "10,75",
    wall_thickness: "0,84",
    weight: "89,2",
    outside_diameter_global: "273,1",
    wall_thickness_global: "21,44",
    weight_global: "132,74",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 152,
    nominal_pipe_size_inch: 10,
    schedule: 140,
    outside_diameter: "10,75",
    wall_thickness: 1,
    weight: "104,2",
    outside_diameter_global: "273,1",
    wall_thickness_global: "25,4",
    weight_global: "155,07",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 153,
    nominal_pipe_size_inch: 10,
    schedule: 160,
    outside_diameter: "10,75",
    wall_thickness: "1,13",
    weight: 116,
    outside_diameter_global: "273,1",
    wall_thickness_global: "28,58",
    weight_global: "172,63",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 154,
    nominal_pipe_size_inch: 12,
    schedule: "12 Ga.",
    outside_diameter: "12,75",
    wall_thickness: "0,1",
    weight: "14,1",
    outside_diameter_global: "323,9",
    wall_thickness_global: "2,64",
    weight_global: "20,98",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 155,
    nominal_pipe_size_inch: 12,
    schedule: "10 Ga.",
    outside_diameter: "12,75",
    wall_thickness: "0,13",
    weight: "18,1",
    outside_diameter_global: "323,9",
    wall_thickness_global: "3,4",
    weight_global: "26,94",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 156,
    nominal_pipe_size_inch: 12,
    schedule: "8 Ga.",
    outside_diameter: "12,75",
    wall_thickness: "0,16",
    weight: "22,1",
    outside_diameter_global: "323,9",
    wall_thickness_global: "4,17",
    weight_global: "32,89",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 157,
    nominal_pipe_size_inch: 12,
    schedule: "10S",
    outside_diameter: "12,75",
    wall_thickness: "0,18",
    weight: "24,2",
    outside_diameter_global: "323,9",
    wall_thickness_global: "4,57",
    weight_global: "36,01",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 158,
    nominal_pipe_size_inch: 12,
    schedule: "6 Ga.",
    outside_diameter: "12,75",
    wall_thickness: "0,19",
    weight: 26,
    outside_diameter_global: "323,9",
    wall_thickness_global: "4,93",
    weight_global: "38,69",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 159,
    nominal_pipe_size_inch: 12,
    schedule: "API",
    outside_diameter: "12,75",
    wall_thickness: "0,2",
    weight: "27,2",
    outside_diameter_global: "323,9",
    wall_thickness_global: "5,16",
    weight_global: "40,48",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 160,
    nominal_pipe_size_inch: 12,
    schedule: "API",
    outside_diameter: "12,75",
    wall_thickness: "0,22",
    weight: "29,3",
    outside_diameter_global: "323,9",
    wall_thickness_global: "5,56",
    weight_global: "43,6",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 161,
    nominal_pipe_size_inch: 12,
    schedule: "3 Ga.",
    outside_diameter: "12,75",
    wall_thickness: "0,24",
    weight: 32,
    outside_diameter_global: "323,9",
    wall_thickness_global: "6,07",
    weight_global: "47,62",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 162,
    nominal_pipe_size_inch: 12,
    schedule: 20,
    outside_diameter: "12,75",
    wall_thickness: "0,25",
    weight: "33,4",
    outside_diameter_global: "323,9",
    wall_thickness_global: "6,35",
    weight_global: "49,71",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 163,
    nominal_pipe_size_inch: 12,
    schedule: "API",
    outside_diameter: "12,75",
    wall_thickness: "0,28",
    weight: "37,4",
    outside_diameter_global: "323,9",
    wall_thickness_global: "7,14",
    weight_global: "55,66",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 164,
    nominal_pipe_size_inch: 12,
    schedule: "API",
    outside_diameter: "12,75",
    wall_thickness: "0,31",
    weight: "41,5",
    outside_diameter_global: "323,9",
    wall_thickness_global: "7,92",
    weight_global: "61,76",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 165,
    nominal_pipe_size_inch: 12,
    schedule: 30,
    outside_diameter: "12,75",
    wall_thickness: "0,33",
    weight: "43,8",
    outside_diameter_global: "323,9",
    wall_thickness_global: "8,38",
    weight_global: "65,18",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 166,
    nominal_pipe_size_inch: 12,
    schedule: "API",
    outside_diameter: "12,75",
    wall_thickness: "0,34",
    weight: "45,5",
    outside_diameter_global: "323,9",
    wall_thickness_global: "8,74",
    weight_global: "67,71",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 167,
    nominal_pipe_size_inch: 12,
    schedule: "Std.",
    outside_diameter: "12,75",
    wall_thickness: "0,38",
    weight: "49,6",
    outside_diameter_global: "323,9",
    wall_thickness_global: "9,53",
    weight_global: "73,81",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 168,
    nominal_pipe_size_inch: 12,
    schedule: 40,
    outside_diameter: "12,75",
    wall_thickness: "0,41",
    weight: "53,6",
    outside_diameter_global: "323,9",
    wall_thickness_global: "10,31",
    weight_global: "79,77",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 169,
    nominal_pipe_size_inch: 12,
    schedule: "API",
    outside_diameter: "12,75",
    wall_thickness: "0,44",
    weight: "57,5",
    outside_diameter_global: "323,9",
    wall_thickness_global: "11,13",
    weight_global: "85,57",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 170,
    nominal_pipe_size_inch: 12,
    schedule: "X-Stg.",
    outside_diameter: "12,75",
    wall_thickness: "0,5",
    weight: "65,4",
    outside_diameter_global: "323,9",
    wall_thickness_global: "12,7",
    weight_global: "97,33",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 171,
    nominal_pipe_size_inch: 12,
    schedule: 60,
    outside_diameter: "12,75",
    wall_thickness: "0,56",
    weight: "73,2",
    outside_diameter_global: "323,9",
    wall_thickness_global: "14,27",
    weight_global: "108,93",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 172,
    nominal_pipe_size_inch: 12,
    schedule: "--",
    outside_diameter: "12,75",
    wall_thickness: "0,63",
    weight: "80,9",
    outside_diameter_global: "323,9",
    wall_thickness_global: "15,88",
    weight_global: "120,39",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 173,
    nominal_pipe_size_inch: 12,
    schedule: 80,
    outside_diameter: "12,75",
    wall_thickness: "0,69",
    weight: "88,6",
    outside_diameter_global: "323,9",
    wall_thickness_global: "17,48",
    weight_global: "131,85",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 174,
    nominal_pipe_size_inch: 12,
    schedule: "--",
    outside_diameter: "12,75",
    wall_thickness: "0,75",
    weight: "96,2",
    outside_diameter_global: "323,9",
    wall_thickness_global: "19,05",
    weight_global: "143,16",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 175,
    nominal_pipe_size_inch: 12,
    schedule: 100,
    outside_diameter: "12,75",
    wall_thickness: "0,84",
    weight: 108,
    outside_diameter_global: "323,9",
    wall_thickness_global: "21,44",
    weight_global: "160,72",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 176,
    nominal_pipe_size_inch: 12,
    schedule: "--",
    outside_diameter: "12,75",
    wall_thickness: "0,88",
    weight: "110,9",
    outside_diameter_global: "323,9",
    wall_thickness_global: "22,23",
    weight_global: "165,04",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 177,
    nominal_pipe_size_inch: 12,
    schedule: 120,
    outside_diameter: "12,75",
    wall_thickness: 1,
    weight: "125,5",
    outside_diameter_global: "323,9",
    wall_thickness_global: "25,4",
    weight_global: "186,77",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 178,
    nominal_pipe_size_inch: 12,
    schedule: 140,
    outside_diameter: "12,75",
    wall_thickness: "1,13",
    weight: 140,
    outside_diameter_global: "323,9",
    wall_thickness_global: "28,58",
    weight_global: "208,34",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 179,
    nominal_pipe_size_inch: 12,
    schedule: "--",
    outside_diameter: "12,75",
    wall_thickness: "1,25",
    weight: "153,6",
    outside_diameter_global: "323,9",
    wall_thickness_global: "31,75",
    weight_global: "228,58",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 180,
    nominal_pipe_size_inch: 12,
    schedule: 160,
    outside_diameter: "12,75",
    wall_thickness: "1,31",
    weight: 161,
    outside_diameter_global: "323,9",
    wall_thickness_global: "33,32",
    weight_global: "239,59",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 181,
    nominal_pipe_size_inch: 12,
    schedule: "--",
    outside_diameter: "12,75",
    wall_thickness: "1,38",
    weight: "167,2",
    outside_diameter_global: "323,9",
    wall_thickness_global: "34,93",
    weight_global: "248,82",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 182,
    nominal_pipe_size_inch: 12,
    schedule: "--",
    outside_diameter: "12,75",
    wall_thickness: "1,5",
    weight: "180,4",
    outside_diameter_global: "323,9",
    wall_thickness_global: "38,1",
    weight_global: "268,47",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 183,
    nominal_pipe_size_inch: 14,
    schedule: "10 Ga.",
    outside_diameter: 14,
    wall_thickness: "0,13",
    weight: 20,
    outside_diameter_global: "355,6",
    wall_thickness_global: "3,4",
    weight_global: "29,76",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 184,
    nominal_pipe_size_inch: 14,
    schedule: "8 Ga.",
    outside_diameter: 14,
    wall_thickness: "0,16",
    weight: 24,
    outside_diameter_global: "355,6",
    wall_thickness_global: "4,17",
    weight_global: "35,72",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 185,
    nominal_pipe_size_inch: 14,
    schedule: "6 Ga.",
    outside_diameter: 14,
    wall_thickness: "0,19",
    weight: 29,
    outside_diameter_global: "355,6",
    wall_thickness_global: "4,93",
    weight_global: "43,16",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 186,
    nominal_pipe_size_inch: 14,
    schedule: "API",
    outside_diameter: 14,
    wall_thickness: "0,21",
    weight: 31,
    outside_diameter_global: "355,6",
    wall_thickness_global: "5,33",
    weight_global: "46,13",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 187,
    nominal_pipe_size_inch: 14,
    schedule: "API",
    outside_diameter: 14,
    wall_thickness: "0,22",
    weight: 32,
    outside_diameter_global: "355,6",
    wall_thickness_global: "5,56",
    weight_global: "47,62",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 188,
    nominal_pipe_size_inch: 14,
    schedule: "3 Ga.",
    outside_diameter: 14,
    wall_thickness: "0,24",
    weight: 35,
    outside_diameter_global: "355,6",
    wall_thickness_global: "6,07",
    weight_global: "52,09",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 189,
    nominal_pipe_size_inch: 14,
    schedule: 10,
    outside_diameter: 14,
    wall_thickness: "0,25",
    weight: 37,
    outside_diameter_global: "355,6",
    wall_thickness_global: "6,35",
    weight_global: "55,06",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 190,
    nominal_pipe_size_inch: 14,
    schedule: "API",
    outside_diameter: 14,
    wall_thickness: "0,28",
    weight: 41,
    outside_diameter_global: "355,6",
    wall_thickness_global: "7,14",
    weight_global: "61,02",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 191,
    nominal_pipe_size_inch: 14,
    schedule: 20,
    outside_diameter: 14,
    wall_thickness: "0,31",
    weight: 46,
    outside_diameter_global: "355,6",
    wall_thickness_global: "7,92",
    weight_global: "68,46",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 192,
    nominal_pipe_size_inch: 14,
    schedule: "API",
    outside_diameter: 14,
    wall_thickness: "0,34",
    weight: 50,
    outside_diameter_global: "355,6",
    wall_thickness_global: "8,74",
    weight_global: "74,41",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 193,
    nominal_pipe_size_inch: 14,
    schedule: "Std.",
    outside_diameter: 14,
    wall_thickness: "0,38",
    weight: 55,
    outside_diameter_global: "355,6",
    wall_thickness_global: "9,53",
    weight_global: "81,85",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 194,
    nominal_pipe_size_inch: 14,
    schedule: 40,
    outside_diameter: 14,
    wall_thickness: "0,44",
    weight: 63,
    outside_diameter_global: "355,6",
    wall_thickness_global: "11,13",
    weight_global: "93,75",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 195,
    nominal_pipe_size_inch: 14,
    schedule: "X-Stg.",
    outside_diameter: 14,
    wall_thickness: "0,5",
    weight: 72,
    outside_diameter_global: "355,6",
    wall_thickness_global: "12,7",
    weight_global: "107,15",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 196,
    nominal_pipe_size_inch: 14,
    schedule: 60,
    outside_diameter: 14,
    wall_thickness: "0,59",
    weight: 85,
    outside_diameter_global: "355,6",
    wall_thickness_global: "15,09",
    weight_global: "126,49",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 197,
    nominal_pipe_size_inch: 14,
    schedule: "--",
    outside_diameter: 14,
    wall_thickness: "0,63",
    weight: 89,
    outside_diameter_global: "355,6",
    wall_thickness_global: "15,88",
    weight_global: "132,45",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 198,
    nominal_pipe_size_inch: 14,
    schedule: 80,
    outside_diameter: 14,
    wall_thickness: "0,75",
    weight: 107,
    outside_diameter_global: "355,6",
    wall_thickness_global: "19,05",
    weight_global: "159,23",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 199,
    nominal_pipe_size_inch: 14,
    schedule: "--",
    outside_diameter: 14,
    wall_thickness: "0,88",
    weight: 123,
    outside_diameter_global: "355,6",
    wall_thickness_global: "22,23",
    weight_global: "183,04",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 200,
    nominal_pipe_size_inch: 14,
    schedule: 100,
    outside_diameter: 14,
    wall_thickness: "0,94",
    weight: 131,
    outside_diameter_global: "355,6",
    wall_thickness_global: "23,83",
    weight_global: "194,95",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 201,
    nominal_pipe_size_inch: 14,
    schedule: "--",
    outside_diameter: 14,
    wall_thickness: 1,
    weight: 139,
    outside_diameter_global: "355,6",
    wall_thickness_global: "25,4",
    weight_global: "206,86",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 202,
    nominal_pipe_size_inch: 14,
    schedule: 120,
    outside_diameter: 14,
    wall_thickness: "1,09",
    weight: 151,
    outside_diameter_global: "355,6",
    wall_thickness_global: "27,79",
    weight_global: "224,71",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 203,
    nominal_pipe_size_inch: 14,
    schedule: "--",
    outside_diameter: 14,
    wall_thickness: "1,13",
    weight: 155,
    outside_diameter_global: "355,6",
    wall_thickness_global: "28,58",
    weight_global: "230,67",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 204,
    nominal_pipe_size_inch: 14,
    schedule: 140,
    outside_diameter: 14,
    wall_thickness: "1,25",
    weight: 171,
    outside_diameter_global: "355,6",
    wall_thickness_global: "31,75",
    weight_global: "254,48",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 205,
    nominal_pipe_size_inch: 14,
    schedule: "--",
    outside_diameter: 14,
    wall_thickness: "1,38",
    weight: 186,
    outside_diameter_global: "355,6",
    wall_thickness_global: "34,93",
    weight_global: "276,8",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 206,
    nominal_pipe_size_inch: 14,
    schedule: 160,
    outside_diameter: 14,
    wall_thickness: "1,41",
    weight: 190,
    outside_diameter_global: "355,6",
    wall_thickness_global: "35,71",
    weight_global: "282,75",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 207,
    nominal_pipe_size_inch: 14,
    schedule: "--",
    outside_diameter: 14,
    wall_thickness: "1,5",
    weight: 200,
    outside_diameter_global: "355,6",
    wall_thickness_global: "38,1",
    weight_global: "297,63",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 208,
    nominal_pipe_size_inch: 16,
    schedule: "10 Ga.",
    outside_diameter: 16,
    wall_thickness: "0,13",
    weight: 23,
    outside_diameter_global: "406,4",
    wall_thickness_global: "3,4",
    weight_global: "34,23",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 209,
    nominal_pipe_size_inch: 16,
    schedule: "8 Ga.",
    outside_diameter: 16,
    wall_thickness: "0,16",
    weight: 28,
    outside_diameter_global: "406,4",
    wall_thickness_global: "4,17",
    weight_global: "41,67",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 210,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "0,19",
    weight: 32,
    outside_diameter_global: "406,4",
    wall_thickness_global: "4,78",
    weight_global: "47,62",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 211,
    nominal_pipe_size_inch: 16,
    schedule: "6 Ga.",
    outside_diameter: 16,
    wall_thickness: "0,19",
    weight: 33,
    outside_diameter_global: "406,4",
    wall_thickness_global: "4,93",
    weight_global: "49,11",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 212,
    nominal_pipe_size_inch: 16,
    schedule: "API",
    outside_diameter: 16,
    wall_thickness: "0,22",
    weight: 37,
    outside_diameter_global: "406,4",
    wall_thickness_global: "5,56",
    weight_global: "55,06",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 213,
    nominal_pipe_size_inch: 16,
    schedule: "3 Ga.",
    outside_diameter: 16,
    wall_thickness: "0,24",
    weight: 40,
    outside_diameter_global: "406,4",
    wall_thickness_global: "6,07",
    weight_global: "59,53",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 214,
    nominal_pipe_size_inch: 16,
    schedule: 10,
    outside_diameter: 16,
    wall_thickness: "0,25",
    weight: 42,
    outside_diameter_global: "406,4",
    wall_thickness_global: "6,35",
    weight_global: "62,5",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 215,
    nominal_pipe_size_inch: 16,
    schedule: "API",
    outside_diameter: 16,
    wall_thickness: "0,28",
    weight: 47,
    outside_diameter_global: "406,4",
    wall_thickness_global: "7,14",
    weight_global: "69,94",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 216,
    nominal_pipe_size_inch: 16,
    schedule: 20,
    outside_diameter: 16,
    wall_thickness: "0,31",
    weight: 52,
    outside_diameter_global: "406,4",
    wall_thickness_global: "7,92",
    weight_global: "77,39",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 217,
    nominal_pipe_size_inch: 16,
    schedule: "API",
    outside_diameter: 16,
    wall_thickness: "0,34",
    weight: 57,
    outside_diameter_global: "406,4",
    wall_thickness_global: "8,74",
    weight_global: "84,83",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 218,
    nominal_pipe_size_inch: 16,
    schedule: "Std.",
    outside_diameter: 16,
    wall_thickness: "0,38",
    weight: 63,
    outside_diameter_global: "406,4",
    wall_thickness_global: "9,53",
    weight_global: "93,75",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 219,
    nominal_pipe_size_inch: 16,
    schedule: "API",
    outside_diameter: 16,
    wall_thickness: "0,44",
    weight: 73,
    outside_diameter_global: "406,4",
    wall_thickness_global: "11,13",
    weight_global: "108,64",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 220,
    nominal_pipe_size_inch: 16,
    schedule: "X-Stg.",
    outside_diameter: 16,
    wall_thickness: "0,5",
    weight: 83,
    outside_diameter_global: "406,4",
    wall_thickness_global: "12,7",
    weight_global: "123,52",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 221,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "0,63",
    weight: 103,
    outside_diameter_global: "406,4",
    wall_thickness_global: "15,88",
    weight_global: "153,28",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 222,
    nominal_pipe_size_inch: 16,
    schedule: 60,
    outside_diameter: 16,
    wall_thickness: "0,66",
    weight: 108,
    outside_diameter_global: "406,4",
    wall_thickness_global: "16,66",
    weight_global: "160,72",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 223,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "0,75",
    weight: 122,
    outside_diameter_global: "406,4",
    wall_thickness_global: "19,05",
    weight_global: "181,56",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 224,
    nominal_pipe_size_inch: 16,
    schedule: 80,
    outside_diameter: 16,
    wall_thickness: "0,84",
    weight: 137,
    outside_diameter_global: "406,4",
    wall_thickness_global: "21,44",
    weight_global: "203,88",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 225,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "0,88",
    weight: 141,
    outside_diameter_global: "406,4",
    wall_thickness_global: "22,23",
    weight_global: "209,83",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 226,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: 1,
    weight: 160,
    outside_diameter_global: "406,4",
    wall_thickness_global: "25,4",
    weight_global: "238,11",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 227,
    nominal_pipe_size_inch: 16,
    schedule: 100,
    outside_diameter: 16,
    wall_thickness: "1,03",
    weight: 165,
    outside_diameter_global: "406,4",
    wall_thickness_global: "26,19",
    weight_global: "245,55",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 228,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "1,13",
    weight: 179,
    outside_diameter_global: "406,4",
    wall_thickness_global: "28,58",
    weight_global: "266,38",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 229,
    nominal_pipe_size_inch: 16,
    schedule: 120,
    outside_diameter: 16,
    wall_thickness: "1,22",
    weight: 193,
    outside_diameter_global: "406,4",
    wall_thickness_global: "30,96",
    weight_global: "287,22",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 230,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "1,25",
    weight: 197,
    outside_diameter_global: "406,4",
    wall_thickness_global: "31,75",
    weight_global: "293,17",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 231,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "1,38",
    weight: 215,
    outside_diameter_global: "406,4",
    wall_thickness_global: "34,93",
    weight_global: "319,96",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 232,
    nominal_pipe_size_inch: 16,
    schedule: 140,
    outside_diameter: 16,
    wall_thickness: "1,44",
    weight: 224,
    outside_diameter_global: "406,4",
    wall_thickness_global: "36,53",
    weight_global: "333,35",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 233,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "1,5",
    weight: 232,
    outside_diameter_global: "406,4",
    wall_thickness_global: "38,1",
    weight_global: "345,25",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 234,
    nominal_pipe_size_inch: 16,
    schedule: 160,
    outside_diameter: 16,
    wall_thickness: "1,59",
    weight: 245,
    outside_diameter_global: "406,4",
    wall_thickness_global: "40,49",
    weight_global: "364,6",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 235,
    nominal_pipe_size_inch: 18,
    schedule: "10 Ga.",
    outside_diameter: 18,
    wall_thickness: "0,13",
    weight: 26,
    outside_diameter_global: "457,2",
    wall_thickness_global: "3,4",
    weight_global: "38,69",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 236,
    nominal_pipe_size_inch: 18,
    schedule: "8 Ga.",
    outside_diameter: 18,
    wall_thickness: "0,16",
    weight: 31,
    outside_diameter_global: "457,2",
    wall_thickness_global: "4,17",
    weight_global: "46,13",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 237,
    nominal_pipe_size_inch: 18,
    schedule: "6 Ga.",
    outside_diameter: 18,
    wall_thickness: "0,19",
    weight: 37,
    outside_diameter_global: "457,2",
    wall_thickness_global: "4,93",
    weight_global: "55,06",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 238,
    nominal_pipe_size_inch: 18,
    schedule: "3 Ga.",
    outside_diameter: 18,
    wall_thickness: "0,24",
    weight: 45,
    outside_diameter_global: "457,2",
    wall_thickness_global: "6,07",
    weight_global: "66,97",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 239,
    nominal_pipe_size_inch: 18,
    schedule: 10,
    outside_diameter: 18,
    wall_thickness: "0,25",
    weight: 47,
    outside_diameter_global: "457,2",
    wall_thickness_global: "6,35",
    weight_global: "69,94",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 240,
    nominal_pipe_size_inch: 18,
    schedule: "API",
    outside_diameter: 18,
    wall_thickness: "0,28",
    weight: 49,
    outside_diameter_global: "457,2",
    wall_thickness_global: "7,14",
    weight_global: "72,92",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 241,
    nominal_pipe_size_inch: 18,
    schedule: 20,
    outside_diameter: 18,
    wall_thickness: "0,31",
    weight: 59,
    outside_diameter_global: "457,2",
    wall_thickness_global: "7,92",
    weight_global: "87,8",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 242,
    nominal_pipe_size_inch: 18,
    schedule: "API",
    outside_diameter: 18,
    wall_thickness: "0,34",
    weight: 65,
    outside_diameter_global: "457,2",
    wall_thickness_global: "8,74",
    weight_global: "96,73",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 243,
    nominal_pipe_size_inch: 18,
    schedule: "Std.",
    outside_diameter: 18,
    wall_thickness: "0,38",
    weight: 71,
    outside_diameter_global: "457,2",
    wall_thickness_global: "9,53",
    weight_global: "105,66",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 244,
    nominal_pipe_size_inch: 18,
    schedule: "API",
    outside_diameter: 18,
    wall_thickness: "0,41",
    weight: 76,
    outside_diameter_global: "457,2",
    wall_thickness_global: "10,31",
    weight_global: "113,1",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 245,
    nominal_pipe_size_inch: 18,
    schedule: 30,
    outside_diameter: 18,
    wall_thickness: "0,44",
    weight: 82,
    outside_diameter_global: "457,2",
    wall_thickness_global: "11,13",
    weight_global: "122,03",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 246,
    nominal_pipe_size_inch: 18,
    schedule: "X-Stg.",
    outside_diameter: 18,
    wall_thickness: "0,5",
    weight: 93,
    outside_diameter_global: "457,2",
    wall_thickness_global: "12,7",
    weight_global: "138,4",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 247,
    nominal_pipe_size_inch: 18,
    schedule: 40,
    outside_diameter: 18,
    wall_thickness: "0,56",
    weight: 105,
    outside_diameter_global: "457,2",
    wall_thickness_global: "14,27",
    weight_global: "156,26",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 248,
    nominal_pipe_size_inch: 18,
    schedule: "--",
    outside_diameter: 18,
    wall_thickness: "0,63",
    weight: 116,
    outside_diameter_global: "457,2",
    wall_thickness_global: "15,88",
    weight_global: "172,63",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 249,
    nominal_pipe_size_inch: 18,
    schedule: 60,
    outside_diameter: 18,
    wall_thickness: "0,75",
    weight: 138,
    outside_diameter_global: "457,2",
    wall_thickness_global: "19,05",
    weight_global: "205,37",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 250,
    nominal_pipe_size_inch: 18,
    schedule: "--",
    outside_diameter: 18,
    wall_thickness: "0,88",
    weight: 160,
    outside_diameter_global: "457,2",
    wall_thickness_global: "22,23",
    weight_global: "238,11",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 251,
    nominal_pipe_size_inch: 18,
    schedule: 80,
    outside_diameter: 18,
    wall_thickness: "0,94",
    weight: 171,
    outside_diameter_global: "457,2",
    wall_thickness_global: "23,83",
    weight_global: "254,48",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 252,
    nominal_pipe_size_inch: 18,
    schedule: "--",
    outside_diameter: 18,
    wall_thickness: 1,
    weight: 182,
    outside_diameter_global: "457,2",
    wall_thickness_global: "25,4",
    weight_global: "270,85",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 253,
    nominal_pipe_size_inch: 18,
    schedule: "--",
    outside_diameter: 18,
    wall_thickness: "1,13",
    weight: 203,
    outside_diameter_global: "457,2",
    wall_thickness_global: "28,58",
    weight_global: "302,1",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 254,
    nominal_pipe_size_inch: 18,
    schedule: 100,
    outside_diameter: 18,
    wall_thickness: "1,16",
    weight: 208,
    outside_diameter_global: "457,2",
    wall_thickness_global: "29,36",
    weight_global: "309,54",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 255,
    nominal_pipe_size_inch: 18,
    schedule: "--",
    outside_diameter: 18,
    wall_thickness: "1,25",
    weight: 224,
    outside_diameter_global: "457,2",
    wall_thickness_global: "31,75",
    weight_global: "333,35",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 256,
    nominal_pipe_size_inch: 18,
    schedule: 120,
    outside_diameter: 18,
    wall_thickness: "1,38",
    weight: 244,
    outside_diameter_global: "457,2",
    wall_thickness_global: "34,93",
    weight_global: "363,11",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 257,
    nominal_pipe_size_inch: 18,
    schedule: "--",
    outside_diameter: 18,
    wall_thickness: "1,5",
    weight: 265,
    outside_diameter_global: "457,2",
    wall_thickness_global: "38,1",
    weight_global: "394,36",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 258,
    nominal_pipe_size_inch: 18,
    schedule: 140,
    outside_diameter: 18,
    wall_thickness: "1,56",
    weight: 275,
    outside_diameter_global: "457,2",
    wall_thickness_global: "39,67",
    weight_global: "409,25",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 259,
    nominal_pipe_size_inch: 18,
    schedule: 160,
    outside_diameter: 18,
    wall_thickness: "1,78",
    weight: 309,
    outside_diameter_global: "457,2",
    wall_thickness_global: "45,24",
    weight_global: "459,84",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 260,
    nominal_pipe_size_inch: 20,
    schedule: "10 Ga.",
    outside_diameter: 20,
    wall_thickness: "0,13",
    weight: 28,
    outside_diameter_global: 508,
    wall_thickness_global: "3,4",
    weight_global: "41,67",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 261,
    nominal_pipe_size_inch: 20,
    schedule: "8 Ga.",
    outside_diameter: 20,
    wall_thickness: "0,16",
    weight: 35,
    outside_diameter_global: 508,
    wall_thickness_global: "4,17",
    weight_global: "52,09",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 262,
    nominal_pipe_size_inch: 20,
    schedule: "6 Ga.",
    outside_diameter: 20,
    wall_thickness: "0,19",
    weight: 41,
    outside_diameter_global: 508,
    wall_thickness_global: "4,93",
    weight_global: "61,02",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 263,
    nominal_pipe_size_inch: 20,
    schedule: "3 Ga.",
    outside_diameter: 20,
    wall_thickness: "0,24",
    weight: 50,
    outside_diameter_global: 508,
    wall_thickness_global: "6,07",
    weight_global: "74,41",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 264,
    nominal_pipe_size_inch: 20,
    schedule: 10,
    outside_diameter: 20,
    wall_thickness: "0,25",
    weight: 53,
    outside_diameter_global: 508,
    wall_thickness_global: "6,35",
    weight_global: "78,87",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 265,
    nominal_pipe_size_inch: 20,
    schedule: "API",
    outside_diameter: 20,
    wall_thickness: "0,28",
    weight: 59,
    outside_diameter_global: 508,
    wall_thickness_global: "7,14",
    weight_global: "87,8",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 266,
    nominal_pipe_size_inch: 20,
    schedule: "API",
    outside_diameter: 20,
    wall_thickness: "0,31",
    weight: 66,
    outside_diameter_global: 508,
    wall_thickness_global: "7,92",
    weight_global: "98,22",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 267,
    nominal_pipe_size_inch: 20,
    schedule: "API",
    outside_diameter: 20,
    wall_thickness: "0,34",
    weight: 72,
    outside_diameter_global: 508,
    wall_thickness_global: "8,74",
    weight_global: "107,15",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 268,
    nominal_pipe_size_inch: 20,
    schedule: "Std.",
    outside_diameter: 20,
    wall_thickness: "0,38",
    weight: 79,
    outside_diameter_global: 508,
    wall_thickness_global: "9,53",
    weight_global: "117,57",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 269,
    nominal_pipe_size_inch: 20,
    schedule: "API",
    outside_diameter: 20,
    wall_thickness: "0,41",
    weight: 85,
    outside_diameter_global: 508,
    wall_thickness_global: "10,31",
    weight_global: "126,49",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 270,
    nominal_pipe_size_inch: 20,
    schedule: "API",
    outside_diameter: 20,
    wall_thickness: "0,44",
    weight: 92,
    outside_diameter_global: 508,
    wall_thickness_global: "11,13",
    weight_global: "136,91",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 271,
    nominal_pipe_size_inch: 20,
    schedule: "X-Stg.",
    outside_diameter: 20,
    wall_thickness: "0,5",
    weight: 105,
    outside_diameter_global: 508,
    wall_thickness_global: "12,7",
    weight_global: "156,26",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 272,
    nominal_pipe_size_inch: 20,
    schedule: 40,
    outside_diameter: 20,
    wall_thickness: "0,59",
    weight: 123,
    outside_diameter_global: 508,
    wall_thickness_global: "15,09",
    weight_global: "183,04",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 273,
    nominal_pipe_size_inch: 20,
    schedule: "--",
    outside_diameter: 20,
    wall_thickness: "0,63",
    weight: 129,
    outside_diameter_global: 508,
    wall_thickness_global: "15,88",
    weight_global: "191,97",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 274,
    nominal_pipe_size_inch: 20,
    schedule: 60,
    outside_diameter: 20,
    wall_thickness: "0,81",
    weight: 167,
    outside_diameter_global: 508,
    wall_thickness_global: "20,62",
    weight_global: "248,52",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 275,
    nominal_pipe_size_inch: 20,
    schedule: "--",
    outside_diameter: 20,
    wall_thickness: "0,88",
    weight: 179,
    outside_diameter_global: 508,
    wall_thickness_global: "22,23",
    weight_global: "266,38",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 276,
    nominal_pipe_size_inch: 20,
    schedule: "--",
    outside_diameter: 20,
    wall_thickness: 1,
    weight: 203,
    outside_diameter_global: 508,
    wall_thickness_global: "25,4",
    weight_global: "302,1",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 277,
    nominal_pipe_size_inch: 20,
    schedule: 80,
    outside_diameter: 20,
    wall_thickness: "1,03",
    weight: 209,
    outside_diameter_global: 508,
    wall_thickness_global: "26,19",
    weight_global: "311,03",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 278,
    nominal_pipe_size_inch: 20,
    schedule: "--",
    outside_diameter: 20,
    wall_thickness: "1,13",
    weight: 227,
    outside_diameter_global: 508,
    wall_thickness_global: "28,58",
    weight_global: "337,81",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 279,
    nominal_pipe_size_inch: 20,
    schedule: "--",
    outside_diameter: 20,
    wall_thickness: "1,25",
    weight: 250,
    outside_diameter_global: 508,
    wall_thickness_global: "31,75",
    weight_global: "372,04",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 280,
    nominal_pipe_size_inch: 20,
    schedule: 100,
    outside_diameter: 20,
    wall_thickness: "1,28",
    weight: 256,
    outside_diameter_global: 508,
    wall_thickness_global: "32,54",
    weight_global: "380,97",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 281,
    nominal_pipe_size_inch: 20,
    schedule: "--",
    outside_diameter: 20,
    wall_thickness: "1,38",
    weight: 274,
    outside_diameter_global: 508,
    wall_thickness_global: "34,93",
    weight_global: "407,76",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 282,
    nominal_pipe_size_inch: 20,
    schedule: 120,
    outside_diameter: 20,
    wall_thickness: "1,5",
    weight: 297,
    outside_diameter_global: 508,
    wall_thickness_global: "38,1",
    weight_global: "441,99",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 283,
    nominal_pipe_size_inch: 20,
    schedule: 140,
    outside_diameter: 20,
    wall_thickness: "1,75",
    weight: 342,
    outside_diameter_global: 508,
    wall_thickness_global: "44,45",
    weight_global: "508,95",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 284,
    nominal_pipe_size_inch: 20,
    schedule: 160,
    outside_diameter: 20,
    wall_thickness: "1,97",
    weight: 379,
    outside_diameter_global: 508,
    wall_thickness_global: "50,01",
    weight_global: "564,01",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 285,
    nominal_pipe_size_inch: 22,
    schedule: "8 Ga.",
    outside_diameter: 22,
    wall_thickness: "0,16",
    weight: 38,
    outside_diameter_global: "558,8",
    wall_thickness_global: "4,17",
    weight_global: "56,55",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 286,
    nominal_pipe_size_inch: 22,
    schedule: "6 Ga.",
    outside_diameter: 22,
    wall_thickness: "0,19",
    weight: 45,
    outside_diameter_global: "558,8",
    wall_thickness_global: "4,93",
    weight_global: "66,97",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 287,
    nominal_pipe_size_inch: 22,
    schedule: "3 Ga.",
    outside_diameter: 22,
    wall_thickness: "0,24",
    weight: 56,
    outside_diameter_global: "558,8",
    wall_thickness_global: "6,07",
    weight_global: "83,34",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 288,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,25",
    weight: 58,
    outside_diameter_global: "558,8",
    wall_thickness_global: "6,35",
    weight_global: "86,31",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 289,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,28",
    weight: 65,
    outside_diameter_global: "558,8",
    wall_thickness_global: "7,14",
    weight_global: "96,73",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 290,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,31",
    weight: 72,
    outside_diameter_global: "558,8",
    wall_thickness_global: "7,92",
    weight_global: "107,15",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 291,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,34",
    weight: 80,
    outside_diameter_global: "558,8",
    wall_thickness_global: "8,74",
    weight_global: "119,05",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 292,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,38",
    weight: 87,
    outside_diameter_global: "558,8",
    wall_thickness_global: "9,53",
    weight_global: "129,47",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 293,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,41",
    weight: 94,
    outside_diameter_global: "558,8",
    wall_thickness_global: "10,31",
    weight_global: "139,89",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 294,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,44",
    weight: 101,
    outside_diameter_global: "558,8",
    wall_thickness_global: "11,13",
    weight_global: "150,31",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 295,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,5",
    weight: 115,
    outside_diameter_global: "558,8",
    wall_thickness_global: "12,7",
    weight_global: "171,14",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 296,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "0,63",
    weight: 143,
    outside_diameter_global: "558,8",
    wall_thickness_global: "15,88",
    weight_global: "212,81",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 297,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "0,75",
    weight: 170,
    outside_diameter_global: "558,8",
    wall_thickness_global: "19,05",
    weight_global: "252,99",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 298,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "0,88",
    weight: 198,
    outside_diameter_global: "558,8",
    wall_thickness_global: "22,23",
    weight_global: "294,66",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 299,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: 1,
    weight: 224,
    outside_diameter_global: "558,8",
    wall_thickness_global: "25,4",
    weight_global: "333,35",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 300,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "1,13",
    weight: 251,
    outside_diameter_global: "558,8",
    wall_thickness_global: "28,58",
    weight_global: "373,53",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 301,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "1,25",
    weight: 277,
    outside_diameter_global: "558,8",
    wall_thickness_global: "31,75",
    weight_global: "412,22",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 302,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "1,38",
    weight: 303,
    outside_diameter_global: "558,8",
    wall_thickness_global: "34,93",
    weight_global: "450,91",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 303,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "1,5",
    weight: 329,
    outside_diameter_global: "558,8",
    wall_thickness_global: "38,1",
    weight_global: "489,61",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 304,
    nominal_pipe_size_inch: 24,
    schedule: "8 Ga.",
    outside_diameter: 24,
    wall_thickness: "0,16",
    weight: 42,
    outside_diameter_global: "609,6",
    wall_thickness_global: "4,17",
    weight_global: "62,5",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 305,
    nominal_pipe_size_inch: 24,
    schedule: "6 Ga.",
    outside_diameter: 24,
    wall_thickness: "0,19",
    weight: 49,
    outside_diameter_global: "609,6",
    wall_thickness_global: "4,93",
    weight_global: "72,92",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 306,
    nominal_pipe_size_inch: 24,
    schedule: "3 Ga.",
    outside_diameter: 24,
    wall_thickness: "0,24",
    weight: 61,
    outside_diameter_global: "609,6",
    wall_thickness_global: "6,07",
    weight_global: "90,78",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 307,
    nominal_pipe_size_inch: 24,
    schedule: 10,
    outside_diameter: 24,
    wall_thickness: "0,25",
    weight: 63,
    outside_diameter_global: "609,6",
    wall_thickness_global: "6,35",
    weight_global: "93,75",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 308,
    nominal_pipe_size_inch: 24,
    schedule: "API",
    outside_diameter: 24,
    wall_thickness: "0,28",
    weight: 71,
    outside_diameter_global: "609,6",
    wall_thickness_global: "7,14",
    weight_global: "105,66",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 309,
    nominal_pipe_size_inch: 24,
    schedule: "API",
    outside_diameter: 24,
    wall_thickness: "0,31",
    weight: 79,
    outside_diameter_global: "609,6",
    wall_thickness_global: "7,92",
    weight_global: "117,57",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 310,
    nominal_pipe_size_inch: 24,
    schedule: "API",
    outside_diameter: 24,
    wall_thickness: "0,34",
    weight: 87,
    outside_diameter_global: "609,6",
    wall_thickness_global: "8,74",
    weight_global: "129,47",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 311,
    nominal_pipe_size_inch: 24,
    schedule: "Std.",
    outside_diameter: 24,
    wall_thickness: "0,38",
    weight: 95,
    outside_diameter_global: "609,6",
    wall_thickness_global: "9,53",
    weight_global: "141,38",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 312,
    nominal_pipe_size_inch: 24,
    schedule: "API",
    outside_diameter: 24,
    wall_thickness: "0,41",
    weight: 102,
    outside_diameter_global: "609,6",
    wall_thickness_global: "10,31",
    weight_global: "151,79",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 313,
    nominal_pipe_size_inch: 24,
    schedule: "API",
    outside_diameter: 24,
    wall_thickness: "0,44",
    weight: 110,
    outside_diameter_global: "609,6",
    wall_thickness_global: "11,13",
    weight_global: "163,7",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 314,
    nominal_pipe_size_inch: 24,
    schedule: "X-Stg.",
    outside_diameter: 24,
    wall_thickness: "0,5",
    weight: 125,
    outside_diameter_global: "609,6",
    wall_thickness_global: "12,7",
    weight_global: "186,02",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 315,
    nominal_pipe_size_inch: 24,
    schedule: 30,
    outside_diameter: 24,
    wall_thickness: "0,56",
    weight: 141,
    outside_diameter_global: "609,6",
    wall_thickness_global: "14,27",
    weight_global: "209,83",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 316,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "0,63",
    weight: 156,
    outside_diameter_global: "609,6",
    wall_thickness_global: "15,88",
    weight_global: "232,15",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 317,
    nominal_pipe_size_inch: 24,
    schedule: 40,
    outside_diameter: 24,
    wall_thickness: "0,69",
    weight: 171,
    outside_diameter_global: "609,6",
    wall_thickness_global: "17,48",
    weight_global: "254,48",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 318,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "0,75",
    weight: 186,
    outside_diameter_global: "609,6",
    wall_thickness_global: "19,05",
    weight_global: "276,8",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 319,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "0,88",
    weight: 216,
    outside_diameter_global: "609,6",
    wall_thickness_global: "22,23",
    weight_global: "321,44",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 320,
    nominal_pipe_size_inch: 24,
    schedule: 60,
    outside_diameter: 24,
    wall_thickness: "0,97",
    weight: 238,
    outside_diameter_global: "609,6",
    wall_thickness_global: "24,61",
    weight_global: "354,18",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 321,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: 1,
    weight: 246,
    outside_diameter_global: "609,6",
    wall_thickness_global: "25,4",
    weight_global: "366,09",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 322,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "1,13",
    weight: 275,
    outside_diameter_global: "609,6",
    wall_thickness_global: "28,58",
    weight_global: "409,25",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 323,
    nominal_pipe_size_inch: 24,
    schedule: 80,
    outside_diameter: 24,
    wall_thickness: "1,22",
    weight: 297,
    outside_diameter_global: "609,6",
    wall_thickness_global: "30,96",
    weight_global: "441,99",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 324,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "1,25",
    weight: 304,
    outside_diameter_global: "609,6",
    wall_thickness_global: "31,75",
    weight_global: "452,4",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 325,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "1,38",
    weight: 332,
    outside_diameter_global: "609,6",
    wall_thickness_global: "34,93",
    weight_global: "494,07",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 326,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "1,5",
    weight: 361,
    outside_diameter_global: "609,6",
    wall_thickness_global: "38,1",
    weight_global: "537,23",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 327,
    nominal_pipe_size_inch: 24,
    schedule: 100,
    outside_diameter: 24,
    wall_thickness: "1,53",
    weight: 367,
    outside_diameter_global: "609,6",
    wall_thickness_global: "38,89",
    weight_global: "546,16",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 328,
    nominal_pipe_size_inch: 24,
    schedule: 120,
    outside_diameter: 24,
    wall_thickness: "1,81",
    weight: 429,
    outside_diameter_global: "609,6",
    wall_thickness_global: "46,02",
    weight_global: "638,42",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 329,
    nominal_pipe_size_inch: 24,
    schedule: 140,
    outside_diameter: 24,
    wall_thickness: "2,06",
    weight: 484,
    outside_diameter_global: "609,6",
    wall_thickness_global: "52,37",
    weight_global: "720,27",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 330,
    nominal_pipe_size_inch: 24,
    schedule: 160,
    outside_diameter: 24,
    wall_thickness: "2,34",
    weight: 542,
    outside_diameter_global: "609,6",
    wall_thickness_global: "59,54",
    weight_global: "806,59",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 331,
    nominal_pipe_size_inch: 26,
    schedule: "8 Ga.",
    outside_diameter: 26,
    wall_thickness: "0,16",
    weight: 45,
    outside_diameter_global: "660,4",
    wall_thickness_global: "4,17",
    weight_global: "66,97",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 332,
    nominal_pipe_size_inch: 26,
    schedule: "6 Ga.",
    outside_diameter: 26,
    wall_thickness: "0,19",
    weight: 54,
    outside_diameter_global: "660,4",
    wall_thickness_global: "4,93",
    weight_global: "80,36",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 333,
    nominal_pipe_size_inch: 26,
    schedule: "3 Ga.",
    outside_diameter: 26,
    wall_thickness: "0,24",
    weight: 66,
    outside_diameter_global: "660,4",
    wall_thickness_global: "6,07",
    weight_global: "98,22",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 334,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,25",
    weight: 67,
    outside_diameter_global: "660,4",
    wall_thickness_global: "6,35",
    weight_global: "99,71",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 335,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,28",
    weight: 77,
    outside_diameter_global: "660,4",
    wall_thickness_global: "7,14",
    weight_global: "114,59",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 336,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,31",
    weight: 84,
    outside_diameter_global: "660,4",
    wall_thickness_global: "7,92",
    weight_global: "125,01",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 337,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,34",
    weight: 94,
    outside_diameter_global: "660,4",
    wall_thickness_global: "8,74",
    weight_global: "139,89",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 338,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,38",
    weight: 103,
    outside_diameter_global: "660,4",
    wall_thickness_global: "9,53",
    weight_global: "153,28",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 339,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,41",
    weight: 111,
    outside_diameter_global: "660,4",
    wall_thickness_global: "10,31",
    weight_global: "165,19",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 340,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,44",
    weight: 120,
    outside_diameter_global: "660,4",
    wall_thickness_global: "11,13",
    weight_global: "178,58",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 341,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,5",
    weight: 136,
    outside_diameter_global: "660,4",
    wall_thickness_global: "12,7",
    weight_global: "202,39",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 342,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: "0,63",
    weight: 169,
    outside_diameter_global: "660,4",
    wall_thickness_global: "15,88",
    weight_global: "251,5",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 343,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: "0,75",
    weight: 202,
    outside_diameter_global: "660,4",
    wall_thickness_global: "19,05",
    weight_global: "300,61",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 344,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: "0,88",
    weight: 235,
    outside_diameter_global: "660,4",
    wall_thickness_global: "22,23",
    weight_global: "349,72",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 345,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: 1,
    weight: 267,
    outside_diameter_global: "660,4",
    wall_thickness_global: "25,4",
    weight_global: "397,34",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 346,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: "1,13",
    weight: 299,
    outside_diameter_global: "660,4",
    wall_thickness_global: "28,58",
    weight_global: "444,96",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 347,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: "1,38",
    weight: 362,
    outside_diameter_global: "660,4",
    wall_thickness_global: "34,93",
    weight_global: "538,72",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 348,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: "1,5",
    weight: 393,
    outside_diameter_global: "660,4",
    wall_thickness_global: "38,1",
    weight_global: "584,85",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 349,
    nominal_pipe_size_inch: 30,
    schedule: "8 Ga.",
    outside_diameter: 30,
    wall_thickness: "0,16",
    weight: 52,
    outside_diameter_global: 762,
    wall_thickness_global: "4,17",
    weight_global: "77,39",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 350,
    nominal_pipe_size_inch: 30,
    schedule: "6 Ga.",
    outside_diameter: 30,
    wall_thickness: "0,19",
    weight: 62,
    outside_diameter_global: 762,
    wall_thickness_global: "4,93",
    weight_global: "92,27",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 351,
    nominal_pipe_size_inch: 30,
    schedule: "3 Ga.",
    outside_diameter: 30,
    wall_thickness: "0,24",
    weight: 76,
    outside_diameter_global: 762,
    wall_thickness_global: "6,07",
    weight_global: "113,1",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 352,
    nominal_pipe_size_inch: 30,
    schedule: "API",
    outside_diameter: 30,
    wall_thickness: "0,25",
    weight: 79,
    outside_diameter_global: 762,
    wall_thickness_global: "6,35",
    weight_global: "117,57",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 353,
    nominal_pipe_size_inch: 30,
    schedule: "API",
    outside_diameter: 30,
    wall_thickness: "0,28",
    weight: 89,
    outside_diameter_global: 762,
    wall_thickness_global: "7,14",
    weight_global: "132,45",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 354,
    nominal_pipe_size_inch: 30,
    schedule: 10,
    outside_diameter: 30,
    wall_thickness: "0,31",
    weight: 99,
    outside_diameter_global: 762,
    wall_thickness_global: "7,92",
    weight_global: "147,33",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 355,
    nominal_pipe_size_inch: 30,
    schedule: "API",
    outside_diameter: 30,
    wall_thickness: "0,34",
    weight: 109,
    outside_diameter_global: 762,
    wall_thickness_global: "8,74",
    weight_global: "162,21",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 356,
    nominal_pipe_size_inch: 30,
    schedule: "API",
    outside_diameter: 30,
    wall_thickness: "0,38",
    weight: 119,
    outside_diameter_global: 762,
    wall_thickness_global: "9,53",
    weight_global: "177,09",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 357,
    nominal_pipe_size_inch: 30,
    schedule: "API",
    outside_diameter: 30,
    wall_thickness: "0,41",
    weight: 130,
    outside_diameter_global: 762,
    wall_thickness_global: "10,31",
    weight_global: "193,46",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 358,
    nominal_pipe_size_inch: 30,
    schedule: "API",
    outside_diameter: 30,
    wall_thickness: "0,44",
    weight: 138,
    outside_diameter_global: 762,
    wall_thickness_global: "11,13",
    weight_global: "205,37",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 359,
    nominal_pipe_size_inch: 30,
    schedule: 20,
    outside_diameter: 30,
    wall_thickness: "0,5",
    weight: 158,
    outside_diameter_global: 762,
    wall_thickness_global: "12,7",
    weight_global: "235,13",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 360,
    nominal_pipe_size_inch: 30,
    schedule: 30,
    outside_diameter: 30,
    wall_thickness: "0,63",
    weight: 196,
    outside_diameter_global: 762,
    wall_thickness_global: "15,88",
    weight_global: "291,68",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 361,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: "0,75",
    weight: 234,
    outside_diameter_global: 762,
    wall_thickness_global: "19,05",
    weight_global: "348,23",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 362,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: "0,88",
    weight: 272,
    outside_diameter_global: 762,
    wall_thickness_global: "22,23",
    weight_global: "404,78",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 363,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: 1,
    weight: 310,
    outside_diameter_global: 762,
    wall_thickness_global: "25,4",
    weight_global: "461,33",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 364,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: "1,13",
    weight: 347,
    outside_diameter_global: 762,
    wall_thickness_global: "28,58",
    weight_global: "516,39",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 365,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: "1,25",
    weight: 384,
    outside_diameter_global: 762,
    wall_thickness_global: "31,75",
    weight_global: "571,46",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 366,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: "1,38",
    weight: 421,
    outside_diameter_global: 762,
    wall_thickness_global: "34,93",
    weight_global: "626,52",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 367,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: "1,5",
    weight: 457,
    outside_diameter_global: 762,
    wall_thickness_global: "38,1",
    weight_global: "680,09",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 368,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,25",
    weight: 85,
    outside_diameter_global: "812,8",
    wall_thickness_global: "6,35",
    weight_global: "126,49",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 369,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,28",
    weight: 95,
    outside_diameter_global: "812,8",
    wall_thickness_global: "7,14",
    weight_global: "141,38",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 370,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,31",
    weight: 106,
    outside_diameter_global: "812,8",
    wall_thickness_global: "7,92",
    weight_global: "157,75",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 371,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,34",
    weight: 116,
    outside_diameter_global: "812,8",
    wall_thickness_global: "8,74",
    weight_global: "172,63",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 372,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,38",
    weight: 127,
    outside_diameter_global: "812,8",
    wall_thickness_global: "9,53",
    weight_global: 189,
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 373,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,41",
    weight: 137,
    outside_diameter_global: "812,8",
    wall_thickness_global: "10,31",
    weight_global: "203,88",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 374,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,44",
    weight: 148,
    outside_diameter_global: "812,8",
    wall_thickness_global: "11,13",
    weight_global: "220,25",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 375,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,5",
    weight: 168,
    outside_diameter_global: "812,8",
    wall_thickness_global: "12,7",
    weight_global: "250,01",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 376,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "0,63",
    weight: 209,
    outside_diameter_global: "812,8",
    wall_thickness_global: "15,88",
    weight_global: "311,03",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 377,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "0,75",
    weight: 250,
    outside_diameter_global: "812,8",
    wall_thickness_global: "19,05",
    weight_global: "372,04",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 378,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "0,88",
    weight: 291,
    outside_diameter_global: "812,8",
    wall_thickness_global: "22,23",
    weight_global: "433,06",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 379,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: 1,
    weight: 331,
    outside_diameter_global: "812,8",
    wall_thickness_global: "25,4",
    weight_global: "492,58",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 380,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "1,13",
    weight: 371,
    outside_diameter_global: "812,8",
    wall_thickness_global: "28,58",
    weight_global: "552,11",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 381,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "1,25",
    weight: 410,
    outside_diameter_global: "812,8",
    wall_thickness_global: "31,75",
    weight_global: "610,15",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 382,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "1,38",
    weight: 450,
    outside_diameter_global: "812,8",
    wall_thickness_global: "34,93",
    weight_global: "669,67",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 383,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "1,5",
    weight: 489,
    outside_diameter_global: "812,8",
    wall_thickness_global: "38,1",
    weight_global: "727,71",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 384,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,25",
    weight: 90,
    outside_diameter_global: "863,6",
    wall_thickness_global: "6,35",
    weight_global: "133,94",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 385,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,28",
    weight: 101,
    outside_diameter_global: "863,6",
    wall_thickness_global: "7,14",
    weight_global: "150,31",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 386,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,31",
    weight: 112,
    outside_diameter_global: "863,6",
    wall_thickness_global: "7,92",
    weight_global: "166,67",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 387,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,34",
    weight: 124,
    outside_diameter_global: "863,6",
    wall_thickness_global: "8,74",
    weight_global: "184,53",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 388,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,38",
    weight: 135,
    outside_diameter_global: "863,6",
    wall_thickness_global: "9,53",
    weight_global: "200,9",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 389,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,41",
    weight: 146,
    outside_diameter_global: "863,6",
    wall_thickness_global: "10,31",
    weight_global: "217,27",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 390,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,44",
    weight: 157,
    outside_diameter_global: "863,6",
    wall_thickness_global: "11,13",
    weight_global: "233,64",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 391,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,5",
    weight: 179,
    outside_diameter_global: "863,6",
    wall_thickness_global: "12,7",
    weight_global: "266,38",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 392,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "0,63",
    weight: 223,
    outside_diameter_global: "863,6",
    wall_thickness_global: "15,88",
    weight_global: "331,86",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 393,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "0,75",
    weight: 266,
    outside_diameter_global: "863,6",
    wall_thickness_global: "19,05",
    weight_global: "395,85",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 394,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "0,88",
    weight: 308,
    outside_diameter_global: "863,6",
    wall_thickness_global: "22,23",
    weight_global: "458,36",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 395,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: 1,
    weight: 353,
    outside_diameter_global: "863,6",
    wall_thickness_global: "25,4",
    weight_global: "525,32",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 396,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "1,13",
    weight: 395,
    outside_diameter_global: "863,6",
    wall_thickness_global: "28,58",
    weight_global: "587,83",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 397,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "1,25",
    weight: 437,
    outside_diameter_global: "863,6",
    wall_thickness_global: "31,75",
    weight_global: "650,33",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 398,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "1,38",
    weight: 479,
    outside_diameter_global: "863,6",
    wall_thickness_global: "34,93",
    weight_global: "712,83",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 399,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "1,5",
    weight: 521,
    outside_diameter_global: "863,6",
    wall_thickness_global: "38,1",
    weight_global: "775,33",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 400,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "0,16",
    weight: 63,
    outside_diameter_global: "914,4",
    wall_thickness_global: "4,17",
    weight_global: "93,75",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 401,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "0,19",
    weight: 74,
    outside_diameter_global: "914,4",
    wall_thickness_global: "4,93",
    weight_global: "110,12",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 402,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "0,24",
    weight: 91,
    outside_diameter_global: "914,4",
    wall_thickness_global: "6,07",
    weight_global: "135,42",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 403,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,25",
    weight: 96,
    outside_diameter_global: "914,4",
    wall_thickness_global: "6,35",
    weight_global: "142,86",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 404,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,28",
    weight: 107,
    outside_diameter_global: "914,4",
    wall_thickness_global: "7,14",
    weight_global: "159,23",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 405,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,31",
    weight: 119,
    outside_diameter_global: "914,4",
    wall_thickness_global: "7,92",
    weight_global: "177,09",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 406,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,34",
    weight: 131,
    outside_diameter_global: "914,4",
    wall_thickness_global: "8,74",
    weight_global: "194,95",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 407,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,38",
    weight: 143,
    outside_diameter_global: "914,4",
    wall_thickness_global: "9,53",
    weight_global: "212,81",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 408,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,41",
    weight: 154,
    outside_diameter_global: "914,4",
    wall_thickness_global: "10,31",
    weight_global: "229,18",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 409,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,44",
    weight: 166,
    outside_diameter_global: "914,4",
    wall_thickness_global: "11,13",
    weight_global: "247,04",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 410,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,5",
    weight: 190,
    outside_diameter_global: "914,4",
    wall_thickness_global: "12,7",
    weight_global: "282,75",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 411,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "0,63",
    weight: 236,
    outside_diameter_global: "914,4",
    wall_thickness_global: "15,88",
    weight_global: "351,21",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 412,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "0,75",
    weight: 282,
    outside_diameter_global: "914,4",
    wall_thickness_global: "19,05",
    weight_global: "419,66",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 413,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "0,88",
    weight: 329,
    outside_diameter_global: "914,4",
    wall_thickness_global: "22,23",
    weight_global: "489,61",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 414,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: 1,
    weight: 374,
    outside_diameter_global: "914,4",
    wall_thickness_global: "25,4",
    weight_global: "556,57",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 415,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "1,13",
    weight: 419,
    outside_diameter_global: "914,4",
    wall_thickness_global: "28,58",
    weight_global: "623,54",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 416,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "1,25",
    weight: 464,
    outside_diameter_global: "914,4",
    wall_thickness_global: "31,75",
    weight_global: "690,51",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 417,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "1,38",
    weight: 509,
    outside_diameter_global: "914,4",
    wall_thickness_global: "34,93",
    weight_global: "757,48",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 418,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "1,5",
    weight: 553,
    outside_diameter_global: "914,4",
    wall_thickness_global: "38,1",
    weight_global: "822,96",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 419,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "0,25",
    weight: 112,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "6,35",
    weight_global: "166,67",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 420,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "0,38",
    weight: 167,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "9,53",
    weight_global: "248,52",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 421,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "0,5",
    weight: 222,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "12,7",
    weight_global: "330,37",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 422,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "0,63",
    weight: 276,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "15,88",
    weight_global: "410,73",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 423,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "0,75",
    weight: 331,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "19,05",
    weight_global: "492,58",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 424,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "0,88",
    weight: 385,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "22,23",
    weight_global: "572,94",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 425,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: 1,
    weight: 438,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "25,4",
    weight_global: "651,82",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 426,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "1,13",
    weight: 492,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "28,58",
    weight_global: "732,18",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 427,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "1,25",
    weight: 544,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "31,75",
    weight_global: "809,56",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 428,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "1,38",
    weight: 597,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "34,93",
    weight_global: "888,43",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 429,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "1,5",
    weight: 649,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "38,1",
    weight_global: "965,82",
    ixx: 1000000,
    type: 0,
    country_code: "American",
  },
  {
    piping_data_id: 430,
    nominal_pipe_size_inch: "1/8",
    schedule: "10S",
    outside_diameter: "0,41",
    wall_thickness: "0,05",
    weight: "0,19",
    outside_diameter_global: "10,3",
    wall_thickness_global: "1,24",
    weight_global: "0,28",
    ixx: "23565,22",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 431,
    nominal_pipe_size_inch: "1/8",
    schedule: "Std.",
    outside_diameter: "0,41",
    wall_thickness: "0,07",
    weight: "0,24",
    outside_diameter_global: "10,3",
    wall_thickness_global: "1,73",
    weight_global: "0,36",
    ixx: "28333,83",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 432,
    nominal_pipe_size_inch: "1/8",
    schedule: "X-Stg.",
    outside_diameter: "0,41",
    wall_thickness: "0,1",
    weight: "0,31",
    outside_diameter_global: "10,3",
    wall_thickness_global: "2,41",
    weight_global: "0,47",
    ixx: "32386,65",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 433,
    nominal_pipe_size_inch: "1/4",
    schedule: "10S",
    outside_diameter: "0,54",
    wall_thickness: "0,07",
    weight: "0,33",
    outside_diameter_global: "13,7",
    wall_thickness_global: "1,65",
    weight_global: "0,49",
    ixx: "74237,99",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 434,
    nominal_pipe_size_inch: "1/4",
    schedule: "Std.",
    outside_diameter: "0,54",
    wall_thickness: "0,09",
    weight: "0,42",
    outside_diameter_global: "13,7",
    wall_thickness_global: "2,24",
    weight_global: "0,63",
    ixx: "88232,79",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 435,
    nominal_pipe_size_inch: "1/4",
    schedule: "X-Stg.",
    outside_diameter: "0,54",
    wall_thickness: "0,12",
    weight: "0,54",
    outside_diameter_global: "13,7",
    wall_thickness_global: "3,02",
    weight_global: "0,8",
    ixx: "100311,39",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 436,
    nominal_pipe_size_inch: "3/8",
    schedule: "10S",
    outside_diameter: "0,68",
    wall_thickness: "0,07",
    weight: "0,42",
    outside_diameter_global: "17,1",
    wall_thickness_global: "1,65",
    weight_global: "0,63",
    ixx: "156092,12",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 437,
    nominal_pipe_size_inch: "3/8",
    schedule: "Std.",
    outside_diameter: "0,68",
    wall_thickness: "0,09",
    weight: "0,57",
    outside_diameter_global: "17,1",
    wall_thickness_global: "2,31",
    weight_global: "0,84",
    ixx: "194210,88",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 438,
    nominal_pipe_size_inch: "3/8",
    schedule: "X-Stg.",
    outside_diameter: "0,68",
    wall_thickness: "0,13",
    weight: "0,74",
    outside_diameter_global: "17,1",
    wall_thickness_global: "3,2",
    weight_global: "1,1",
    ixx: "229591,71",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 439,
    nominal_pipe_size_inch: "1/2",
    schedule: "10S",
    outside_diameter: "0,84",
    wall_thickness: "0,08",
    weight: "0,67",
    outside_diameter_global: "21,3",
    wall_thickness_global: "2,11",
    weight_global: 1,
    ixx: "381180,14",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 440,
    nominal_pipe_size_inch: "1/2",
    schedule: "Std.",
    outside_diameter: "0,84",
    wall_thickness: "0,11",
    weight: "0,85",
    outside_diameter_global: "21,3",
    wall_thickness_global: "2,77",
    weight_global: "1,27",
    ixx: "455306,33",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 441,
    nominal_pipe_size_inch: "1/2",
    schedule: "X-Stg.",
    outside_diameter: "0,84",
    wall_thickness: "0,15",
    weight: "1,09",
    outside_diameter_global: "21,3",
    wall_thickness_global: "3,73",
    weight_global: "1,62",
    ixx: "534818,16",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 442,
    nominal_pipe_size_inch: "1/2",
    schedule: 160,
    outside_diameter: "0,84",
    wall_thickness: "0,14",
    weight: "1,31",
    outside_diameter_global: "21,3",
    wall_thickness_global: "3,51",
    weight_global: "1,95",
    ixx: "518718,74",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 443,
    nominal_pipe_size_inch: "1/2",
    schedule: "XX-Stg.",
    outside_diameter: "0,84",
    wall_thickness: "0,29",
    weight: "1,71",
    outside_diameter_global: "21,3",
    wall_thickness_global: "7,47",
    weight_global: "2,55",
    ixx: "645757,97",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 444,
    nominal_pipe_size_inch: "3/4",
    schedule: "10S",
    outside_diameter: "1,05",
    wall_thickness: "0,08",
    weight: "0,86",
    outside_diameter_global: "26,7",
    wall_thickness_global: "2,11",
    weight_global: "1,28",
    ixx: "790897,23",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 445,
    nominal_pipe_size_inch: "3/4",
    schedule: "Std.",
    outside_diameter: "1,05",
    wall_thickness: "0,11",
    weight: "1,13",
    outside_diameter_global: "26,7",
    wall_thickness_global: "2,87",
    weight_global: "1,68",
    ixx: "986603,7",
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 446,
    nominal_pipe_size_inch: "3/4",
    schedule: "X-Stg.",
    outside_diameter: "1,05",
    wall_thickness: "0,15",
    weight: "1,47",
    outside_diameter_global: "26,7",
    wall_thickness_global: "3,91",
    weight_global: "2,19",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 447,
    nominal_pipe_size_inch: "3/4",
    schedule: 160,
    outside_diameter: "1,05",
    wall_thickness: "0,22",
    weight: "1,94",
    outside_diameter_global: "26,7",
    wall_thickness_global: "5,56",
    weight_global: "2,89",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 448,
    nominal_pipe_size_inch: "3/4",
    schedule: "XX-Stg.",
    outside_diameter: "1,05",
    wall_thickness: "0,31",
    weight: "2,44",
    outside_diameter_global: "26,7",
    wall_thickness_global: "7,82",
    weight_global: "3,63",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 449,
    nominal_pipe_size_inch: 1,
    schedule: "10S",
    outside_diameter: "1,32",
    wall_thickness: "0,11",
    weight: "1,4",
    outside_diameter_global: "33,4",
    wall_thickness_global: "2,77",
    weight_global: "2,09",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 450,
    nominal_pipe_size_inch: 1,
    schedule: "Std.",
    outside_diameter: "1,32",
    wall_thickness: "0,13",
    weight: "1,68",
    outside_diameter_global: "33,4",
    wall_thickness_global: "3,38",
    weight_global: "2,5",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 451,
    nominal_pipe_size_inch: 1,
    schedule: "X-Stg.",
    outside_diameter: "1,32",
    wall_thickness: "0,18",
    weight: "2,17",
    outside_diameter_global: "33,4",
    wall_thickness_global: "4,55",
    weight_global: "3,23",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 452,
    nominal_pipe_size_inch: 1,
    schedule: 160,
    outside_diameter: "1,32",
    wall_thickness: "0,25",
    weight: "2,85",
    outside_diameter_global: "33,4",
    wall_thickness_global: "6,35",
    weight_global: "4,24",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 453,
    nominal_pipe_size_inch: 1,
    schedule: "XX-Stg.",
    outside_diameter: "1,32",
    wall_thickness: "0,36",
    weight: "3,66",
    outside_diameter_global: "33,4",
    wall_thickness_global: "9,09",
    weight_global: "5,45",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 454,
    nominal_pipe_size_inch: "1 1/4",
    schedule: "10S",
    outside_diameter: "1,66",
    wall_thickness: "0,11",
    weight: "1,81",
    outside_diameter_global: "42,2",
    wall_thickness_global: "2,77",
    weight_global: "2,69",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 455,
    nominal_pipe_size_inch: "1 1/4",
    schedule: "Std.",
    outside_diameter: "1,66",
    wall_thickness: "0,14",
    weight: "2,27",
    outside_diameter_global: "42,2",
    wall_thickness_global: "3,56",
    weight_global: "3,38",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 456,
    nominal_pipe_size_inch: "1 1/4",
    schedule: "-",
    outside_diameter: "1,66",
    wall_thickness: "0,19",
    weight: 3,
    outside_diameter_global: "42,2",
    wall_thickness_global: "4,85",
    weight_global: "4,46",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 457,
    nominal_pipe_size_inch: "1 1/4",
    schedule: "-",
    outside_diameter: "1,66",
    wall_thickness: "0,25",
    weight: "3,76",
    outside_diameter_global: "42,2",
    wall_thickness_global: "6,35",
    weight_global: "5,6",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 458,
    nominal_pipe_size_inch: "1 1/4",
    schedule: "-",
    outside_diameter: "1,66",
    wall_thickness: "0,38",
    weight: "5,21",
    outside_diameter_global: "42,2",
    wall_thickness_global: "9,7",
    weight_global: "7,76",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 459,
    nominal_pipe_size_inch: "1 1/2",
    schedule: "10S",
    outside_diameter: "1,9",
    wall_thickness: "0,11",
    weight: "2,09",
    outside_diameter_global: "48,3",
    wall_thickness_global: "2,77",
    weight_global: "3,1",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 460,
    nominal_pipe_size_inch: "1 1/2",
    schedule: "Std.",
    outside_diameter: "1,9",
    wall_thickness: "0,15",
    weight: "2,72",
    outside_diameter_global: "48,3",
    wall_thickness_global: "3,68",
    weight_global: "4,04",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 461,
    nominal_pipe_size_inch: "1 1/2",
    schedule: "X-Stg.",
    outside_diameter: "1,9",
    wall_thickness: "0,2",
    weight: "3,63",
    outside_diameter_global: "48,3",
    wall_thickness_global: "5,08",
    weight_global: "5,4",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 462,
    nominal_pipe_size_inch: "1 1/2",
    schedule: 160,
    outside_diameter: "1,9",
    wall_thickness: "0,28",
    weight: "4,86",
    outside_diameter_global: "48,3",
    wall_thickness_global: "7,14",
    weight_global: "7,24",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 463,
    nominal_pipe_size_inch: "1 1/2",
    schedule: "XX-Stg.",
    outside_diameter: "1,9",
    wall_thickness: "0,4",
    weight: "6,41",
    outside_diameter_global: "48,3",
    wall_thickness_global: "10,16",
    weight_global: "9,54",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 464,
    nominal_pipe_size_inch: 2,
    schedule: "10S",
    outside_diameter: "2,38",
    wall_thickness: "0,11",
    weight: "2,64",
    outside_diameter_global: "60,3",
    wall_thickness_global: "2,77",
    weight_global: "3,93",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 465,
    nominal_pipe_size_inch: 2,
    schedule: "Std.",
    outside_diameter: "2,38",
    wall_thickness: "0,15",
    weight: "3,65",
    outside_diameter_global: "60,3",
    wall_thickness_global: "3,91",
    weight_global: "5,44",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 466,
    nominal_pipe_size_inch: 2,
    schedule: "X-Stg.",
    outside_diameter: "2,38",
    wall_thickness: "0,22",
    weight: "5,02",
    outside_diameter_global: "60,3",
    wall_thickness_global: "5,54",
    weight_global: "7,47",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 467,
    nominal_pipe_size_inch: 2,
    schedule: "-",
    outside_diameter: "2,38",
    wall_thickness: "0,25",
    weight: "5,67",
    outside_diameter_global: "60,3",
    wall_thickness_global: "6,35",
    weight_global: "8,44",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 468,
    nominal_pipe_size_inch: 2,
    schedule: 160,
    outside_diameter: "2,38",
    wall_thickness: "0,34",
    weight: "7,45",
    outside_diameter_global: "60,3",
    wall_thickness_global: "8,74",
    weight_global: "11,09",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 469,
    nominal_pipe_size_inch: 2,
    schedule: "XX-Stg.",
    outside_diameter: "2,38",
    wall_thickness: "0,44",
    weight: "9,03",
    outside_diameter_global: "60,3",
    wall_thickness_global: "11,07",
    weight_global: "13,44",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 470,
    nominal_pipe_size_inch: "2 1/2",
    schedule: "10S",
    outside_diameter: "2,88",
    wall_thickness: "0,12",
    weight: "3,53",
    outside_diameter_global: 73,
    wall_thickness_global: "3,05",
    weight_global: "5,25",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 471,
    nominal_pipe_size_inch: "2 1/2",
    schedule: "Std.",
    outside_diameter: "2,88",
    wall_thickness: "0,2",
    weight: "5,79",
    outside_diameter_global: 73,
    wall_thickness_global: "5,16",
    weight_global: "8,62",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 472,
    nominal_pipe_size_inch: "2 1/2",
    schedule: "X-Stg.",
    outside_diameter: "2,88",
    wall_thickness: "0,28",
    weight: "7,66",
    outside_diameter_global: 73,
    wall_thickness_global: "7,01",
    weight_global: "11,4",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 473,
    nominal_pipe_size_inch: "2 1/2",
    schedule: 160,
    outside_diameter: "2,88",
    wall_thickness: "0,38",
    weight: "10,01",
    outside_diameter_global: 73,
    wall_thickness_global: "9,53",
    weight_global: "14,9",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 474,
    nominal_pipe_size_inch: "2 1/2",
    schedule: "XX-Stg.",
    outside_diameter: "2,88",
    wall_thickness: "0,55",
    weight: "13,69",
    outside_diameter_global: 73,
    wall_thickness_global: "14,02",
    weight_global: "20,37",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 475,
    nominal_pipe_size_inch: 3,
    schedule: "10S",
    outside_diameter: "3,5",
    wall_thickness: "0,12",
    weight: "4,33",
    outside_diameter_global: "88,9",
    wall_thickness_global: "3,05",
    weight_global: "6,44",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 476,
    nominal_pipe_size_inch: 3,
    schedule: "API",
    outside_diameter: "3,5",
    wall_thickness: "0,13",
    weight: "4,52",
    outside_diameter_global: "88,9",
    wall_thickness_global: "3,18",
    weight_global: "6,73",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 477,
    nominal_pipe_size_inch: 3,
    schedule: "API",
    outside_diameter: "3,5",
    wall_thickness: "0,16",
    weight: "5,58",
    outside_diameter_global: "88,9",
    wall_thickness_global: "3,96",
    weight_global: "8,3",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 478,
    nominal_pipe_size_inch: 3,
    schedule: "API",
    outside_diameter: "3,5",
    wall_thickness: "0,19",
    weight: "6,65",
    outside_diameter_global: "88,9",
    wall_thickness_global: "4,78",
    weight_global: "9,9",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 479,
    nominal_pipe_size_inch: 3,
    schedule: "Std.",
    outside_diameter: "3,5",
    wall_thickness: "0,22",
    weight: "7,57",
    outside_diameter_global: "88,9",
    wall_thickness_global: "5,49",
    weight_global: "11,27",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 480,
    nominal_pipe_size_inch: 3,
    schedule: "API",
    outside_diameter: "3,5",
    wall_thickness: "0,25",
    weight: "8,68",
    outside_diameter_global: "88,9",
    wall_thickness_global: "6,35",
    weight_global: "12,92",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 481,
    nominal_pipe_size_inch: 3,
    schedule: "API",
    outside_diameter: "3,5",
    wall_thickness: "0,28",
    weight: "9,65",
    outside_diameter_global: "88,9",
    wall_thickness_global: "7,14",
    weight_global: "14,36",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 482,
    nominal_pipe_size_inch: 3,
    schedule: "X-Stg.",
    outside_diameter: "3,5",
    wall_thickness: "0,3",
    weight: "10,25",
    outside_diameter_global: "88,9",
    wall_thickness_global: "7,62",
    weight_global: "15,25",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 483,
    nominal_pipe_size_inch: 3,
    schedule: 160,
    outside_diameter: "3,5",
    wall_thickness: "0,44",
    weight: "14,32",
    outside_diameter_global: "88,9",
    wall_thickness_global: "11,13",
    weight_global: "21,31",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 484,
    nominal_pipe_size_inch: 3,
    schedule: "XX-Stg.",
    outside_diameter: "3,5",
    wall_thickness: "0,6",
    weight: "18,58",
    outside_diameter_global: "88,9",
    wall_thickness_global: "15,24",
    weight_global: "27,65",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 485,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "10S",
    outside_diameter: 4,
    wall_thickness: "0,12",
    weight: "4,97",
    outside_diameter_global: "101,6",
    wall_thickness_global: "3,05",
    weight_global: "7,4",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 486,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "API",
    outside_diameter: 4,
    wall_thickness: "0,13",
    weight: "5,18",
    outside_diameter_global: "101,6",
    wall_thickness_global: "3,18",
    weight_global: "7,71",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 487,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "API",
    outside_diameter: 4,
    wall_thickness: "0,16",
    weight: "6,41",
    outside_diameter_global: "101,6",
    wall_thickness_global: "3,96",
    weight_global: "9,54",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 488,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "API",
    outside_diameter: 4,
    wall_thickness: "0,19",
    weight: "7,71",
    outside_diameter_global: "101,6",
    wall_thickness_global: "4,78",
    weight_global: "11,47",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 489,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "Std.",
    outside_diameter: 4,
    wall_thickness: "0,23",
    weight: "9,11",
    outside_diameter_global: "101,6",
    wall_thickness_global: "5,74",
    weight_global: "13,56",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 490,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "API",
    outside_diameter: 4,
    wall_thickness: "0,25",
    weight: "10,02",
    outside_diameter_global: "101,6",
    wall_thickness_global: "6,35",
    weight_global: "14,91",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 491,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "API",
    outside_diameter: 4,
    wall_thickness: "0,28",
    weight: "11,17",
    outside_diameter_global: "101,6",
    wall_thickness_global: "7,14",
    weight_global: "16,62",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 492,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "X-Stg.",
    outside_diameter: 4,
    wall_thickness: "0,32",
    weight: "12,51",
    outside_diameter_global: "101,6",
    wall_thickness_global: "8,08",
    weight_global: "18,62",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 493,
    nominal_pipe_size_inch: "3 1/2",
    schedule: "XX-Stg.",
    outside_diameter: 4,
    wall_thickness: "0,64",
    weight: "22,85",
    outside_diameter_global: "101,6",
    wall_thickness_global: "16,15",
    weight_global: "34,01",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 494,
    nominal_pipe_size_inch: 4,
    schedule: "10S",
    outside_diameter: "4,5",
    wall_thickness: "0,12",
    weight: "5,61",
    outside_diameter_global: "114,3",
    wall_thickness_global: "3,05",
    weight_global: "8,35",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 495,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,13",
    weight: "5,84",
    outside_diameter_global: "114,3",
    wall_thickness_global: "3,18",
    weight_global: "8,69",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 496,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,16",
    weight: "7,24",
    outside_diameter_global: "114,3",
    wall_thickness_global: "3,96",
    weight_global: "10,77",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 497,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,19",
    weight: "8,56",
    outside_diameter_global: "114,3",
    wall_thickness_global: "4,78",
    weight_global: "12,74",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 498,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,22",
    weight: "10,02",
    outside_diameter_global: "114,3",
    wall_thickness_global: "5,56",
    weight_global: "14,91",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 499,
    nominal_pipe_size_inch: 4,
    schedule: "Std.",
    outside_diameter: "4,5",
    wall_thickness: "0,24",
    weight: "10,79",
    outside_diameter_global: "114,3",
    wall_thickness_global: "6,02",
    weight_global: "16,06",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 500,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,25",
    weight: "11,35",
    outside_diameter_global: "114,3",
    wall_thickness_global: "6,35",
    weight_global: "16,89",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 501,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,28",
    weight: "12,67",
    outside_diameter_global: "114,3",
    wall_thickness_global: "7,14",
    weight_global: "18,86",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 502,
    nominal_pipe_size_inch: 4,
    schedule: "API",
    outside_diameter: "4,5",
    wall_thickness: "0,31",
    weight: 14,
    outside_diameter_global: "114,3",
    wall_thickness_global: "7,92",
    weight_global: "20,83",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 503,
    nominal_pipe_size_inch: 4,
    schedule: "X-Stg.",
    outside_diameter: "4,5",
    wall_thickness: "0,34",
    weight: "14,98",
    outside_diameter_global: "114,3",
    wall_thickness_global: "8,56",
    weight_global: "22,29",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 504,
    nominal_pipe_size_inch: 4,
    schedule: 120,
    outside_diameter: "4,5",
    wall_thickness: "0,44",
    weight: 19,
    outside_diameter_global: "114,3",
    wall_thickness_global: "11,13",
    weight_global: "28,28",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 505,
    nominal_pipe_size_inch: 4,
    schedule: "--",
    outside_diameter: "4,5",
    wall_thickness: "0,5",
    weight: "21,36",
    outside_diameter_global: "114,3",
    wall_thickness_global: "12,7",
    weight_global: "31,79",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 506,
    nominal_pipe_size_inch: 4,
    schedule: 160,
    outside_diameter: "4,5",
    wall_thickness: "0,53",
    weight: "22,6",
    outside_diameter_global: "114,3",
    wall_thickness_global: "13,49",
    weight_global: "33,63",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 507,
    nominal_pipe_size_inch: 4,
    schedule: "XX-Stg.",
    outside_diameter: "4,5",
    wall_thickness: "0,67",
    weight: "27,54",
    outside_diameter_global: "114,3",
    wall_thickness_global: "17,12",
    weight_global: "40,98",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 508,
    nominal_pipe_size_inch: 5,
    schedule: "10S",
    outside_diameter: "5,56",
    wall_thickness: "0,13",
    weight: "7,77",
    outside_diameter_global: "141,3",
    wall_thickness_global: "3,4",
    weight_global: "11,56",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 509,
    nominal_pipe_size_inch: 5,
    schedule: "API",
    outside_diameter: "5,56",
    wall_thickness: "0,16",
    weight: "9,02",
    outside_diameter_global: "141,3",
    wall_thickness_global: "3,96",
    weight_global: "13,42",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 510,
    nominal_pipe_size_inch: 5,
    schedule: "API",
    outside_diameter: "5,56",
    wall_thickness: "0,19",
    weight: "10,8",
    outside_diameter_global: "141,3",
    wall_thickness_global: "4,78",
    weight_global: "16,07",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 511,
    nominal_pipe_size_inch: 5,
    schedule: "API",
    outside_diameter: "5,56",
    wall_thickness: "0,22",
    weight: "12,51",
    outside_diameter_global: "141,3",
    wall_thickness_global: "5,56",
    weight_global: "18,62",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 512,
    nominal_pipe_size_inch: 5,
    schedule: "Std.",
    outside_diameter: "5,56",
    wall_thickness: "0,26",
    weight: "14,62",
    outside_diameter_global: "141,3",
    wall_thickness_global: "6,55",
    weight_global: "21,76",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 513,
    nominal_pipe_size_inch: 5,
    schedule: "API",
    outside_diameter: "5,56",
    wall_thickness: "0,28",
    weight: "15,86",
    outside_diameter_global: "141,3",
    wall_thickness_global: "7,14",
    weight_global: "23,6",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 514,
    nominal_pipe_size_inch: 5,
    schedule: "API",
    outside_diameter: "5,56",
    wall_thickness: "0,31",
    weight: "17,51",
    outside_diameter_global: "141,3",
    wall_thickness_global: "7,92",
    weight_global: "26,06",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 515,
    nominal_pipe_size_inch: 5,
    schedule: "API",
    outside_diameter: "5,56",
    wall_thickness: "0,34",
    weight: "19,19",
    outside_diameter_global: "141,3",
    wall_thickness_global: "8,74",
    weight_global: "28,56",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 516,
    nominal_pipe_size_inch: 5,
    schedule: "X-Stg.",
    outside_diameter: "5,56",
    wall_thickness: "0,38",
    weight: "20,78",
    outside_diameter_global: "141,3",
    wall_thickness_global: "9,53",
    weight_global: "30,92",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 517,
    nominal_pipe_size_inch: 5,
    schedule: 120,
    outside_diameter: "5,56",
    wall_thickness: "0,5",
    weight: "27,1",
    outside_diameter_global: "141,3",
    wall_thickness_global: "12,7",
    weight_global: "40,33",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 518,
    nominal_pipe_size_inch: 5,
    schedule: 160,
    outside_diameter: "5,56",
    wall_thickness: "0,63",
    weight: "32,96",
    outside_diameter_global: "141,3",
    wall_thickness_global: "15,88",
    weight_global: "49,05",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 519,
    nominal_pipe_size_inch: 5,
    schedule: "XX-Stg.",
    outside_diameter: "5,56",
    wall_thickness: "0,75",
    weight: "38,55",
    outside_diameter_global: "141,3",
    wall_thickness_global: "19,05",
    weight_global: "57,37",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 520,
    nominal_pipe_size_inch: 6,
    schedule: "12 Ga.",
    outside_diameter: "6,63",
    wall_thickness: "0,1",
    weight: "7,25",
    outside_diameter_global: "168,3",
    wall_thickness_global: "2,64",
    weight_global: "10,79",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 521,
    nominal_pipe_size_inch: 6,
    schedule: "10S",
    outside_diameter: "6,63",
    wall_thickness: "0,13",
    weight: "9,29",
    outside_diameter_global: "168,3",
    wall_thickness_global: "3,4",
    weight_global: "13,83",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 522,
    nominal_pipe_size_inch: 6,
    schedule: "8 Ga.",
    outside_diameter: "6,63",
    wall_thickness: "0,16",
    weight: "11,33",
    outside_diameter_global: "168,3",
    wall_thickness_global: "4,17",
    weight_global: "16,86",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 523,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,19",
    weight: "12,93",
    outside_diameter_global: "168,3",
    wall_thickness_global: "4,78",
    weight_global: "19,24",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 524,
    nominal_pipe_size_inch: 6,
    schedule: "6Ga.",
    outside_diameter: "6,63",
    wall_thickness: "0,19",
    weight: "13,34",
    outside_diameter_global: "168,3",
    wall_thickness_global: "4,93",
    weight_global: "19,85",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 525,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,22",
    weight: "15,02",
    outside_diameter_global: "168,3",
    wall_thickness_global: "5,56",
    weight_global: "22,35",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 526,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,25",
    weight: "17,02",
    outside_diameter_global: "168,3",
    wall_thickness_global: "6,35",
    weight_global: "25,33",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 527,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,28",
    weight: "18,86",
    outside_diameter_global: "168,3",
    wall_thickness_global: "7,04",
    weight_global: "28,07",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 528,
    nominal_pipe_size_inch: 6,
    schedule: "Std.",
    outside_diameter: "6,63",
    wall_thickness: "0,28",
    weight: "18,97",
    outside_diameter_global: "168,3",
    wall_thickness_global: "7,11",
    weight_global: "28,23",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 529,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,31",
    weight: "21,05",
    outside_diameter_global: "168,3",
    wall_thickness_global: "7,92",
    weight_global: "31,33",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 530,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,34",
    weight: "23,09",
    outside_diameter_global: "168,3",
    wall_thickness_global: "8,74",
    weight_global: "34,36",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 531,
    nominal_pipe_size_inch: 6,
    schedule: "API",
    outside_diameter: "6,63",
    wall_thickness: "0,38",
    weight: "25,1",
    outside_diameter_global: "168,3",
    wall_thickness_global: "9,53",
    weight_global: "37,35",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 532,
    nominal_pipe_size_inch: 6,
    schedule: "X-Stg.",
    outside_diameter: "6,63",
    wall_thickness: "0,43",
    weight: "28,57",
    outside_diameter_global: "168,3",
    wall_thickness_global: "10,97",
    weight_global: "42,52",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 533,
    nominal_pipe_size_inch: 6,
    schedule: "--",
    outside_diameter: "6,63",
    wall_thickness: "0,5",
    weight: "32,79",
    outside_diameter_global: "168,3",
    wall_thickness_global: "12,7",
    weight_global: "48,8",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 534,
    nominal_pipe_size_inch: 6,
    schedule: 120,
    outside_diameter: "6,63",
    wall_thickness: "0,56",
    weight: "36,4",
    outside_diameter_global: "168,3",
    wall_thickness_global: "14,27",
    weight_global: "54,17",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 535,
    nominal_pipe_size_inch: 6,
    schedule: 160,
    outside_diameter: "6,63",
    wall_thickness: "0,72",
    weight: "45,3",
    outside_diameter_global: "168,3",
    wall_thickness_global: "18,26",
    weight_global: "67,41",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 536,
    nominal_pipe_size_inch: 6,
    schedule: "XX-Stg.",
    outside_diameter: "6,63",
    wall_thickness: "0,86",
    weight: "53,16",
    outside_diameter_global: "168,3",
    wall_thickness_global: "21,95",
    weight_global: "79,11",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 537,
    nominal_pipe_size_inch: 8,
    schedule: "12 Ga.",
    outside_diameter: "8,63",
    wall_thickness: "0,1",
    weight: "9,47",
    outside_diameter_global: "219,1",
    wall_thickness_global: "2,64",
    weight_global: "14,09",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 538,
    nominal_pipe_size_inch: 8,
    schedule: "10 Ga.",
    outside_diameter: "8,63",
    wall_thickness: "0,13",
    weight: "12,16",
    outside_diameter_global: "219,1",
    wall_thickness_global: "3,4",
    weight_global: "18,1",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 539,
    nominal_pipe_size_inch: 8,
    schedule: "10S",
    outside_diameter: "8,63",
    wall_thickness: "0,15",
    weight: "13,4",
    outside_diameter_global: "219,1",
    wall_thickness_global: "3,76",
    weight_global: "19,94",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 540,
    nominal_pipe_size_inch: 8,
    schedule: "8 Ga.",
    outside_diameter: "8,63",
    wall_thickness: "0,16",
    weight: "14,83",
    outside_diameter_global: "219,1",
    wall_thickness_global: "4,17",
    weight_global: "22,07",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 541,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,19",
    weight: "16,9",
    outside_diameter_global: "219,1",
    wall_thickness_global: "4,78",
    weight_global: "25,15",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 542,
    nominal_pipe_size_inch: 8,
    schedule: "6 Ga.",
    outside_diameter: "8,63",
    wall_thickness: "0,19",
    weight: "17,48",
    outside_diameter_global: "219,1",
    wall_thickness_global: "4,93",
    weight_global: "26,01",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 543,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,2",
    weight: "18,3",
    outside_diameter_global: "219,1",
    wall_thickness_global: "5,16",
    weight_global: "27,23",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 544,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,22",
    weight: "19,64",
    outside_diameter_global: "219,1",
    wall_thickness_global: "5,56",
    weight_global: "29,23",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 545,
    nominal_pipe_size_inch: 8,
    schedule: "3 Ga.",
    outside_diameter: "8,63",
    wall_thickness: "0,24",
    weight: "21,42",
    outside_diameter_global: "219,1",
    wall_thickness_global: "6,07",
    weight_global: "31,88",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 546,
    nominal_pipe_size_inch: 8,
    schedule: 20,
    outside_diameter: "8,63",
    wall_thickness: "0,25",
    weight: "22,4",
    outside_diameter_global: "219,1",
    wall_thickness_global: "6,35",
    weight_global: "33,34",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 547,
    nominal_pipe_size_inch: 8,
    schedule: 30,
    outside_diameter: "8,63",
    wall_thickness: "0,28",
    weight: "24,7",
    outside_diameter_global: "219,1",
    wall_thickness_global: "7,04",
    weight_global: "36,76",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 548,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,31",
    weight: "27,72",
    outside_diameter_global: "219,1",
    wall_thickness_global: "7,92",
    weight_global: "41,25",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 549,
    nominal_pipe_size_inch: 8,
    schedule: "Std.",
    outside_diameter: "8,63",
    wall_thickness: "0,32",
    weight: "28,55",
    outside_diameter_global: "219,1",
    wall_thickness_global: "8,18",
    weight_global: "42,49",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 550,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,34",
    weight: "30,4",
    outside_diameter_global: "219,1",
    wall_thickness_global: "8,74",
    weight_global: "45,24",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 551,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,38",
    weight: "33,1",
    outside_diameter_global: "219,1",
    wall_thickness_global: "9,53",
    weight_global: "49,26",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 552,
    nominal_pipe_size_inch: 8,
    schedule: 60,
    outside_diameter: "8,63",
    wall_thickness: "0,41",
    weight: "35,7",
    outside_diameter_global: "219,1",
    wall_thickness_global: "10,31",
    weight_global: "53,13",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 553,
    nominal_pipe_size_inch: 8,
    schedule: "API",
    outside_diameter: "8,63",
    wall_thickness: "0,44",
    weight: "38,33",
    outside_diameter_global: "219,1",
    wall_thickness_global: "11,13",
    weight_global: "57,04",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 554,
    nominal_pipe_size_inch: 8,
    schedule: "X-Stg.",
    outside_diameter: "8,63",
    wall_thickness: "0,5",
    weight: "43,39",
    outside_diameter_global: "219,1",
    wall_thickness_global: "12,7",
    weight_global: "64,57",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 555,
    nominal_pipe_size_inch: 8,
    schedule: 100,
    outside_diameter: "8,63",
    wall_thickness: "0,59",
    weight: "50,9",
    outside_diameter_global: "219,1",
    wall_thickness_global: "15,09",
    weight_global: "75,75",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 556,
    nominal_pipe_size_inch: 8,
    schedule: "--",
    outside_diameter: "8,63",
    wall_thickness: "0,63",
    weight: "53,4",
    outside_diameter_global: "219,1",
    wall_thickness_global: "15,88",
    weight_global: "79,47",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 557,
    nominal_pipe_size_inch: 8,
    schedule: 120,
    outside_diameter: "8,63",
    wall_thickness: "0,72",
    weight: "60,7",
    outside_diameter_global: "219,1",
    wall_thickness_global: "18,26",
    weight_global: "90,33",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 558,
    nominal_pipe_size_inch: 8,
    schedule: 140,
    outside_diameter: "8,63",
    wall_thickness: "0,81",
    weight: "67,8",
    outside_diameter_global: "219,1",
    wall_thickness_global: "20,62",
    weight_global: "100,9",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 559,
    nominal_pipe_size_inch: 8,
    schedule: "XX-Stg.",
    outside_diameter: "8,63",
    wall_thickness: "0,88",
    weight: "72,42",
    outside_diameter_global: "219,1",
    wall_thickness_global: "22,23",
    weight_global: "107,77",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 560,
    nominal_pipe_size_inch: 8,
    schedule: 160,
    outside_diameter: "8,63",
    wall_thickness: "0,91",
    weight: "74,7",
    outside_diameter_global: "219,1",
    wall_thickness_global: "23,01",
    weight_global: "111,17",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 561,
    nominal_pipe_size_inch: 10,
    schedule: "12 Ga.",
    outside_diameter: "10,75",
    wall_thickness: "0,1",
    weight: "11,83",
    outside_diameter_global: "273,1",
    wall_thickness_global: "2,64",
    weight_global: "17,61",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 562,
    nominal_pipe_size_inch: 10,
    schedule: "10 Ga.",
    outside_diameter: "10,75",
    wall_thickness: "0,13",
    weight: "15,21",
    outside_diameter_global: "273,1",
    wall_thickness_global: "3,4",
    weight_global: "22,64",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 563,
    nominal_pipe_size_inch: 10,
    schedule: "8 Ga.",
    outside_diameter: "10,75",
    wall_thickness: "0,16",
    weight: "18,56",
    outside_diameter_global: "273,1",
    wall_thickness_global: "4,17",
    weight_global: "27,62",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 564,
    nominal_pipe_size_inch: 10,
    schedule: "10S",
    outside_diameter: "10,75",
    wall_thickness: "0,17",
    weight: "18,65",
    outside_diameter_global: "273,1",
    wall_thickness_global: "4,19",
    weight_global: "27,75",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 565,
    nominal_pipe_size_inch: 10,
    schedule: "API",
    outside_diameter: "10,75",
    wall_thickness: "0,19",
    weight: "21,12",
    outside_diameter_global: "273,1",
    wall_thickness_global: "4,78",
    weight_global: "31,43",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 566,
    nominal_pipe_size_inch: 10,
    schedule: "6 Ga.",
    outside_diameter: "10,75",
    wall_thickness: "0,19",
    weight: "21,89",
    outside_diameter_global: "273,1",
    wall_thickness_global: "4,93",
    weight_global: "32,58",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 567,
    nominal_pipe_size_inch: 10,
    schedule: "API",
    outside_diameter: "10,75",
    wall_thickness: "0,2",
    weight: "22,86",
    outside_diameter_global: "273,1",
    wall_thickness_global: "5,16",
    weight_global: "34,02",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 568,
    nominal_pipe_size_inch: 10,
    schedule: "API",
    outside_diameter: "10,75",
    wall_thickness: "0,22",
    weight: "24,6",
    outside_diameter_global: "273,1",
    wall_thickness_global: "5,56",
    weight_global: "36,61",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 569,
    nominal_pipe_size_inch: 10,
    schedule: "3 Ga.",
    outside_diameter: "10,75",
    wall_thickness: "0,24",
    weight: "28,05",
    outside_diameter_global: "273,1",
    wall_thickness_global: "6,07",
    weight_global: "41,74",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 570,
    nominal_pipe_size_inch: 10,
    schedule: 20,
    outside_diameter: "10,75",
    wall_thickness: "0,25",
    weight: "28,03",
    outside_diameter_global: "273,1",
    wall_thickness_global: "6,35",
    weight_global: "41,71",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 571,
    nominal_pipe_size_inch: 10,
    schedule: "API",
    outside_diameter: "10,75",
    wall_thickness: "0,28",
    weight: "31,2",
    outside_diameter_global: "273,1",
    wall_thickness_global: "7,09",
    weight_global: "46,43",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 572,
    nominal_pipe_size_inch: 10,
    schedule: 30,
    outside_diameter: "10,75",
    wall_thickness: "0,31",
    weight: "34,24",
    outside_diameter_global: "273,1",
    wall_thickness_global: "7,8",
    weight_global: "50,96",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 573,
    nominal_pipe_size_inch: 10,
    schedule: "API",
    outside_diameter: "10,75",
    wall_thickness: "0,34",
    weight: "38,26",
    outside_diameter_global: "273,1",
    wall_thickness_global: "8,74",
    weight_global: "56,94",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 574,
    nominal_pipe_size_inch: 10,
    schedule: "Std.",
    outside_diameter: "10,75",
    wall_thickness: "0,37",
    weight: "40,48",
    outside_diameter_global: "273,1",
    wall_thickness_global: "9,27",
    weight_global: "60,24",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 575,
    nominal_pipe_size_inch: 10,
    schedule: "API",
    outside_diameter: "10,75",
    wall_thickness: "0,44",
    weight: "48,28",
    outside_diameter_global: "273,1",
    wall_thickness_global: "11,13",
    weight_global: "71,85",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 576,
    nominal_pipe_size_inch: 10,
    schedule: "X-Stg.",
    outside_diameter: "10,75",
    wall_thickness: "0,5",
    weight: "54,74",
    outside_diameter_global: "273,1",
    wall_thickness_global: "12,7",
    weight_global: "81,46",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 577,
    nominal_pipe_size_inch: 10,
    schedule: 80,
    outside_diameter: "10,75",
    wall_thickness: "0,59",
    weight: "64,4",
    outside_diameter_global: "273,1",
    wall_thickness_global: "15,09",
    weight_global: "95,84",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 578,
    nominal_pipe_size_inch: 10,
    schedule: 100,
    outside_diameter: "10,75",
    wall_thickness: "0,72",
    weight: 77,
    outside_diameter_global: "273,1",
    wall_thickness_global: "18,26",
    weight_global: "114,59",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 579,
    nominal_pipe_size_inch: 10,
    schedule: "--",
    outside_diameter: "10,75",
    wall_thickness: "0,75",
    weight: "80,1",
    outside_diameter_global: "273,1",
    wall_thickness_global: "19,05",
    weight_global: "119,2",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 580,
    nominal_pipe_size_inch: 10,
    schedule: 120,
    outside_diameter: "10,75",
    wall_thickness: "0,84",
    weight: "89,2",
    outside_diameter_global: "273,1",
    wall_thickness_global: "21,44",
    weight_global: "132,74",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 581,
    nominal_pipe_size_inch: 10,
    schedule: 140,
    outside_diameter: "10,75",
    wall_thickness: 1,
    weight: "104,2",
    outside_diameter_global: "273,1",
    wall_thickness_global: "25,4",
    weight_global: "155,07",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 582,
    nominal_pipe_size_inch: 10,
    schedule: 160,
    outside_diameter: "10,75",
    wall_thickness: "1,13",
    weight: 116,
    outside_diameter_global: "273,1",
    wall_thickness_global: "28,58",
    weight_global: "172,63",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 583,
    nominal_pipe_size_inch: 12,
    schedule: "12 Ga.",
    outside_diameter: "12,75",
    wall_thickness: "0,1",
    weight: "14,1",
    outside_diameter_global: "323,9",
    wall_thickness_global: "2,64",
    weight_global: "20,98",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 584,
    nominal_pipe_size_inch: 12,
    schedule: "10 Ga.",
    outside_diameter: "12,75",
    wall_thickness: "0,13",
    weight: "18,1",
    outside_diameter_global: "323,9",
    wall_thickness_global: "3,4",
    weight_global: "26,94",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 585,
    nominal_pipe_size_inch: 12,
    schedule: "8 Ga.",
    outside_diameter: "12,75",
    wall_thickness: "0,16",
    weight: "22,1",
    outside_diameter_global: "323,9",
    wall_thickness_global: "4,17",
    weight_global: "32,89",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 586,
    nominal_pipe_size_inch: 12,
    schedule: "10S",
    outside_diameter: "12,75",
    wall_thickness: "0,18",
    weight: "24,2",
    outside_diameter_global: "323,9",
    wall_thickness_global: "4,57",
    weight_global: "36,01",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 587,
    nominal_pipe_size_inch: 12,
    schedule: "6 Ga.",
    outside_diameter: "12,75",
    wall_thickness: "0,19",
    weight: 26,
    outside_diameter_global: "323,9",
    wall_thickness_global: "4,93",
    weight_global: "38,69",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 588,
    nominal_pipe_size_inch: 12,
    schedule: "API",
    outside_diameter: "12,75",
    wall_thickness: "0,2",
    weight: "27,2",
    outside_diameter_global: "323,9",
    wall_thickness_global: "5,16",
    weight_global: "40,48",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 589,
    nominal_pipe_size_inch: 12,
    schedule: "API",
    outside_diameter: "12,75",
    wall_thickness: "0,22",
    weight: "29,3",
    outside_diameter_global: "323,9",
    wall_thickness_global: "5,56",
    weight_global: "43,6",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 590,
    nominal_pipe_size_inch: 12,
    schedule: "3 Ga.",
    outside_diameter: "12,75",
    wall_thickness: "0,24",
    weight: 32,
    outside_diameter_global: "323,9",
    wall_thickness_global: "6,07",
    weight_global: "47,62",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 591,
    nominal_pipe_size_inch: 12,
    schedule: 20,
    outside_diameter: "12,75",
    wall_thickness: "0,25",
    weight: "33,4",
    outside_diameter_global: "323,9",
    wall_thickness_global: "6,35",
    weight_global: "49,71",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 592,
    nominal_pipe_size_inch: 12,
    schedule: "API",
    outside_diameter: "12,75",
    wall_thickness: "0,28",
    weight: "37,4",
    outside_diameter_global: "323,9",
    wall_thickness_global: "7,14",
    weight_global: "55,66",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 593,
    nominal_pipe_size_inch: 12,
    schedule: "API",
    outside_diameter: "12,75",
    wall_thickness: "0,31",
    weight: "41,5",
    outside_diameter_global: "323,9",
    wall_thickness_global: "7,92",
    weight_global: "61,76",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 594,
    nominal_pipe_size_inch: 12,
    schedule: 30,
    outside_diameter: "12,75",
    wall_thickness: "0,33",
    weight: "43,8",
    outside_diameter_global: "323,9",
    wall_thickness_global: "8,38",
    weight_global: "65,18",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 595,
    nominal_pipe_size_inch: 12,
    schedule: "API",
    outside_diameter: "12,75",
    wall_thickness: "0,34",
    weight: "45,5",
    outside_diameter_global: "323,9",
    wall_thickness_global: "8,74",
    weight_global: "67,71",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 596,
    nominal_pipe_size_inch: 12,
    schedule: "Std.",
    outside_diameter: "12,75",
    wall_thickness: "0,38",
    weight: "49,6",
    outside_diameter_global: "323,9",
    wall_thickness_global: "9,53",
    weight_global: "73,81",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 597,
    nominal_pipe_size_inch: 12,
    schedule: 40,
    outside_diameter: "12,75",
    wall_thickness: "0,41",
    weight: "53,6",
    outside_diameter_global: "323,9",
    wall_thickness_global: "10,31",
    weight_global: "79,77",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 598,
    nominal_pipe_size_inch: 12,
    schedule: "API",
    outside_diameter: "12,75",
    wall_thickness: "0,44",
    weight: "57,5",
    outside_diameter_global: "323,9",
    wall_thickness_global: "11,13",
    weight_global: "85,57",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 599,
    nominal_pipe_size_inch: 12,
    schedule: "X-Stg.",
    outside_diameter: "12,75",
    wall_thickness: "0,5",
    weight: "65,4",
    outside_diameter_global: "323,9",
    wall_thickness_global: "12,7",
    weight_global: "97,33",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 600,
    nominal_pipe_size_inch: 12,
    schedule: 60,
    outside_diameter: "12,75",
    wall_thickness: "0,56",
    weight: "73,2",
    outside_diameter_global: "323,9",
    wall_thickness_global: "14,27",
    weight_global: "108,93",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 601,
    nominal_pipe_size_inch: 12,
    schedule: "--",
    outside_diameter: "12,75",
    wall_thickness: "0,63",
    weight: "80,9",
    outside_diameter_global: "323,9",
    wall_thickness_global: "15,88",
    weight_global: "120,39",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 602,
    nominal_pipe_size_inch: 12,
    schedule: 80,
    outside_diameter: "12,75",
    wall_thickness: "0,69",
    weight: "88,6",
    outside_diameter_global: "323,9",
    wall_thickness_global: "17,48",
    weight_global: "131,85",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 603,
    nominal_pipe_size_inch: 12,
    schedule: "--",
    outside_diameter: "12,75",
    wall_thickness: "0,75",
    weight: "96,2",
    outside_diameter_global: "323,9",
    wall_thickness_global: "19,05",
    weight_global: "143,16",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 604,
    nominal_pipe_size_inch: 12,
    schedule: 100,
    outside_diameter: "12,75",
    wall_thickness: "0,84",
    weight: 108,
    outside_diameter_global: "323,9",
    wall_thickness_global: "21,44",
    weight_global: "160,72",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 605,
    nominal_pipe_size_inch: 12,
    schedule: "--",
    outside_diameter: "12,75",
    wall_thickness: "0,88",
    weight: "110,9",
    outside_diameter_global: "323,9",
    wall_thickness_global: "22,23",
    weight_global: "165,04",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 606,
    nominal_pipe_size_inch: 12,
    schedule: 120,
    outside_diameter: "12,75",
    wall_thickness: 1,
    weight: "125,5",
    outside_diameter_global: "323,9",
    wall_thickness_global: "25,4",
    weight_global: "186,77",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 607,
    nominal_pipe_size_inch: 12,
    schedule: 140,
    outside_diameter: "12,75",
    wall_thickness: "1,13",
    weight: 140,
    outside_diameter_global: "323,9",
    wall_thickness_global: "28,58",
    weight_global: "208,34",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 608,
    nominal_pipe_size_inch: 12,
    schedule: "--",
    outside_diameter: "12,75",
    wall_thickness: "1,25",
    weight: "153,6",
    outside_diameter_global: "323,9",
    wall_thickness_global: "31,75",
    weight_global: "228,58",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 609,
    nominal_pipe_size_inch: 12,
    schedule: 160,
    outside_diameter: "12,75",
    wall_thickness: "1,31",
    weight: 161,
    outside_diameter_global: "323,9",
    wall_thickness_global: "33,32",
    weight_global: "239,59",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 610,
    nominal_pipe_size_inch: 12,
    schedule: "--",
    outside_diameter: "12,75",
    wall_thickness: "1,38",
    weight: "167,2",
    outside_diameter_global: "323,9",
    wall_thickness_global: "34,93",
    weight_global: "248,82",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 611,
    nominal_pipe_size_inch: 12,
    schedule: "--",
    outside_diameter: "12,75",
    wall_thickness: "1,5",
    weight: "180,4",
    outside_diameter_global: "323,9",
    wall_thickness_global: "38,1",
    weight_global: "268,47",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 612,
    nominal_pipe_size_inch: 14,
    schedule: "10 Ga.",
    outside_diameter: 14,
    wall_thickness: "0,13",
    weight: 20,
    outside_diameter_global: "355,6",
    wall_thickness_global: "3,4",
    weight_global: "29,76",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 613,
    nominal_pipe_size_inch: 14,
    schedule: "8 Ga.",
    outside_diameter: 14,
    wall_thickness: "0,16",
    weight: 24,
    outside_diameter_global: "355,6",
    wall_thickness_global: "4,17",
    weight_global: "35,72",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 614,
    nominal_pipe_size_inch: 14,
    schedule: "6 Ga.",
    outside_diameter: 14,
    wall_thickness: "0,19",
    weight: 29,
    outside_diameter_global: "355,6",
    wall_thickness_global: "4,93",
    weight_global: "43,16",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 615,
    nominal_pipe_size_inch: 14,
    schedule: "API",
    outside_diameter: 14,
    wall_thickness: "0,21",
    weight: 31,
    outside_diameter_global: "355,6",
    wall_thickness_global: "5,33",
    weight_global: "46,13",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 616,
    nominal_pipe_size_inch: 14,
    schedule: "API",
    outside_diameter: 14,
    wall_thickness: "0,22",
    weight: 32,
    outside_diameter_global: "355,6",
    wall_thickness_global: "5,56",
    weight_global: "47,62",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 617,
    nominal_pipe_size_inch: 14,
    schedule: "3 Ga.",
    outside_diameter: 14,
    wall_thickness: "0,24",
    weight: 35,
    outside_diameter_global: "355,6",
    wall_thickness_global: "6,07",
    weight_global: "52,09",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 618,
    nominal_pipe_size_inch: 14,
    schedule: 10,
    outside_diameter: 14,
    wall_thickness: "0,25",
    weight: 37,
    outside_diameter_global: "355,6",
    wall_thickness_global: "6,35",
    weight_global: "55,06",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 619,
    nominal_pipe_size_inch: 14,
    schedule: "API",
    outside_diameter: 14,
    wall_thickness: "0,28",
    weight: 41,
    outside_diameter_global: "355,6",
    wall_thickness_global: "7,14",
    weight_global: "61,02",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 620,
    nominal_pipe_size_inch: 14,
    schedule: 20,
    outside_diameter: 14,
    wall_thickness: "0,31",
    weight: 46,
    outside_diameter_global: "355,6",
    wall_thickness_global: "7,92",
    weight_global: "68,46",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 621,
    nominal_pipe_size_inch: 14,
    schedule: "API",
    outside_diameter: 14,
    wall_thickness: "0,34",
    weight: 50,
    outside_diameter_global: "355,6",
    wall_thickness_global: "8,74",
    weight_global: "74,41",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 622,
    nominal_pipe_size_inch: 14,
    schedule: "Std.",
    outside_diameter: 14,
    wall_thickness: "0,38",
    weight: 55,
    outside_diameter_global: "355,6",
    wall_thickness_global: "9,53",
    weight_global: "81,85",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 623,
    nominal_pipe_size_inch: 14,
    schedule: 40,
    outside_diameter: 14,
    wall_thickness: "0,44",
    weight: 63,
    outside_diameter_global: "355,6",
    wall_thickness_global: "11,13",
    weight_global: "93,75",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 624,
    nominal_pipe_size_inch: 14,
    schedule: "X-Stg.",
    outside_diameter: 14,
    wall_thickness: "0,5",
    weight: 72,
    outside_diameter_global: "355,6",
    wall_thickness_global: "12,7",
    weight_global: "107,15",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 625,
    nominal_pipe_size_inch: 14,
    schedule: 60,
    outside_diameter: 14,
    wall_thickness: "0,59",
    weight: 85,
    outside_diameter_global: "355,6",
    wall_thickness_global: "15,09",
    weight_global: "126,49",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 626,
    nominal_pipe_size_inch: 14,
    schedule: "--",
    outside_diameter: 14,
    wall_thickness: "0,63",
    weight: 89,
    outside_diameter_global: "355,6",
    wall_thickness_global: "15,88",
    weight_global: "132,45",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 627,
    nominal_pipe_size_inch: 14,
    schedule: 80,
    outside_diameter: 14,
    wall_thickness: "0,75",
    weight: 107,
    outside_diameter_global: "355,6",
    wall_thickness_global: "19,05",
    weight_global: "159,23",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 628,
    nominal_pipe_size_inch: 14,
    schedule: "--",
    outside_diameter: 14,
    wall_thickness: "0,88",
    weight: 123,
    outside_diameter_global: "355,6",
    wall_thickness_global: "22,23",
    weight_global: "183,04",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 629,
    nominal_pipe_size_inch: 14,
    schedule: 100,
    outside_diameter: 14,
    wall_thickness: "0,94",
    weight: 131,
    outside_diameter_global: "355,6",
    wall_thickness_global: "23,83",
    weight_global: "194,95",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 630,
    nominal_pipe_size_inch: 14,
    schedule: "--",
    outside_diameter: 14,
    wall_thickness: 1,
    weight: 139,
    outside_diameter_global: "355,6",
    wall_thickness_global: "25,4",
    weight_global: "206,86",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 631,
    nominal_pipe_size_inch: 14,
    schedule: 120,
    outside_diameter: 14,
    wall_thickness: "1,09",
    weight: 151,
    outside_diameter_global: "355,6",
    wall_thickness_global: "27,79",
    weight_global: "224,71",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 632,
    nominal_pipe_size_inch: 14,
    schedule: "--",
    outside_diameter: 14,
    wall_thickness: "1,13",
    weight: 155,
    outside_diameter_global: "355,6",
    wall_thickness_global: "28,58",
    weight_global: "230,67",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 633,
    nominal_pipe_size_inch: 14,
    schedule: 140,
    outside_diameter: 14,
    wall_thickness: "1,25",
    weight: 171,
    outside_diameter_global: "355,6",
    wall_thickness_global: "31,75",
    weight_global: "254,48",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 634,
    nominal_pipe_size_inch: 14,
    schedule: "--",
    outside_diameter: 14,
    wall_thickness: "1,38",
    weight: 186,
    outside_diameter_global: "355,6",
    wall_thickness_global: "34,93",
    weight_global: "276,8",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 635,
    nominal_pipe_size_inch: 14,
    schedule: 160,
    outside_diameter: 14,
    wall_thickness: "1,41",
    weight: 190,
    outside_diameter_global: "355,6",
    wall_thickness_global: "35,71",
    weight_global: "282,75",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 636,
    nominal_pipe_size_inch: 14,
    schedule: "--",
    outside_diameter: 14,
    wall_thickness: "1,5",
    weight: 200,
    outside_diameter_global: "355,6",
    wall_thickness_global: "38,1",
    weight_global: "297,63",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 637,
    nominal_pipe_size_inch: 16,
    schedule: "10 Ga.",
    outside_diameter: 16,
    wall_thickness: "0,13",
    weight: 23,
    outside_diameter_global: "406,4",
    wall_thickness_global: "3,4",
    weight_global: "34,23",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 638,
    nominal_pipe_size_inch: 16,
    schedule: "8 Ga.",
    outside_diameter: 16,
    wall_thickness: "0,16",
    weight: 28,
    outside_diameter_global: "406,4",
    wall_thickness_global: "4,17",
    weight_global: "41,67",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 639,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "0,19",
    weight: 32,
    outside_diameter_global: "406,4",
    wall_thickness_global: "4,78",
    weight_global: "47,62",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 640,
    nominal_pipe_size_inch: 16,
    schedule: "6 Ga.",
    outside_diameter: 16,
    wall_thickness: "0,19",
    weight: 33,
    outside_diameter_global: "406,4",
    wall_thickness_global: "4,93",
    weight_global: "49,11",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 641,
    nominal_pipe_size_inch: 16,
    schedule: "API",
    outside_diameter: 16,
    wall_thickness: "0,22",
    weight: 37,
    outside_diameter_global: "406,4",
    wall_thickness_global: "5,56",
    weight_global: "55,06",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 642,
    nominal_pipe_size_inch: 16,
    schedule: "3 Ga.",
    outside_diameter: 16,
    wall_thickness: "0,24",
    weight: 40,
    outside_diameter_global: "406,4",
    wall_thickness_global: "6,07",
    weight_global: "59,53",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 643,
    nominal_pipe_size_inch: 16,
    schedule: 10,
    outside_diameter: 16,
    wall_thickness: "0,25",
    weight: 42,
    outside_diameter_global: "406,4",
    wall_thickness_global: "6,35",
    weight_global: "62,5",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 644,
    nominal_pipe_size_inch: 16,
    schedule: "API",
    outside_diameter: 16,
    wall_thickness: "0,28",
    weight: 47,
    outside_diameter_global: "406,4",
    wall_thickness_global: "7,14",
    weight_global: "69,94",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 645,
    nominal_pipe_size_inch: 16,
    schedule: 20,
    outside_diameter: 16,
    wall_thickness: "0,31",
    weight: 52,
    outside_diameter_global: "406,4",
    wall_thickness_global: "7,92",
    weight_global: "77,39",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 646,
    nominal_pipe_size_inch: 16,
    schedule: "API",
    outside_diameter: 16,
    wall_thickness: "0,34",
    weight: 57,
    outside_diameter_global: "406,4",
    wall_thickness_global: "8,74",
    weight_global: "84,83",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 647,
    nominal_pipe_size_inch: 16,
    schedule: "Std.",
    outside_diameter: 16,
    wall_thickness: "0,38",
    weight: 63,
    outside_diameter_global: "406,4",
    wall_thickness_global: "9,53",
    weight_global: "93,75",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 648,
    nominal_pipe_size_inch: 16,
    schedule: "API",
    outside_diameter: 16,
    wall_thickness: "0,44",
    weight: 73,
    outside_diameter_global: "406,4",
    wall_thickness_global: "11,13",
    weight_global: "108,64",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 649,
    nominal_pipe_size_inch: 16,
    schedule: "X-Stg.",
    outside_diameter: 16,
    wall_thickness: "0,5",
    weight: 83,
    outside_diameter_global: "406,4",
    wall_thickness_global: "12,7",
    weight_global: "123,52",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 650,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "0,63",
    weight: 103,
    outside_diameter_global: "406,4",
    wall_thickness_global: "15,88",
    weight_global: "153,28",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 651,
    nominal_pipe_size_inch: 16,
    schedule: 60,
    outside_diameter: 16,
    wall_thickness: "0,66",
    weight: 108,
    outside_diameter_global: "406,4",
    wall_thickness_global: "16,66",
    weight_global: "160,72",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 652,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "0,75",
    weight: 122,
    outside_diameter_global: "406,4",
    wall_thickness_global: "19,05",
    weight_global: "181,56",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 653,
    nominal_pipe_size_inch: 16,
    schedule: 80,
    outside_diameter: 16,
    wall_thickness: "0,84",
    weight: 137,
    outside_diameter_global: "406,4",
    wall_thickness_global: "21,44",
    weight_global: "203,88",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 654,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "0,88",
    weight: 141,
    outside_diameter_global: "406,4",
    wall_thickness_global: "22,23",
    weight_global: "209,83",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 655,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: 1,
    weight: 160,
    outside_diameter_global: "406,4",
    wall_thickness_global: "25,4",
    weight_global: "238,11",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 656,
    nominal_pipe_size_inch: 16,
    schedule: 100,
    outside_diameter: 16,
    wall_thickness: "1,03",
    weight: 165,
    outside_diameter_global: "406,4",
    wall_thickness_global: "26,19",
    weight_global: "245,55",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 657,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "1,13",
    weight: 179,
    outside_diameter_global: "406,4",
    wall_thickness_global: "28,58",
    weight_global: "266,38",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 658,
    nominal_pipe_size_inch: 16,
    schedule: 120,
    outside_diameter: 16,
    wall_thickness: "1,22",
    weight: 193,
    outside_diameter_global: "406,4",
    wall_thickness_global: "30,96",
    weight_global: "287,22",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 659,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "1,25",
    weight: 197,
    outside_diameter_global: "406,4",
    wall_thickness_global: "31,75",
    weight_global: "293,17",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 660,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "1,38",
    weight: 215,
    outside_diameter_global: "406,4",
    wall_thickness_global: "34,93",
    weight_global: "319,96",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 661,
    nominal_pipe_size_inch: 16,
    schedule: 140,
    outside_diameter: 16,
    wall_thickness: "1,44",
    weight: 224,
    outside_diameter_global: "406,4",
    wall_thickness_global: "36,53",
    weight_global: "333,35",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 662,
    nominal_pipe_size_inch: 16,
    schedule: "--",
    outside_diameter: 16,
    wall_thickness: "1,5",
    weight: 232,
    outside_diameter_global: "406,4",
    wall_thickness_global: "38,1",
    weight_global: "345,25",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 663,
    nominal_pipe_size_inch: 16,
    schedule: 160,
    outside_diameter: 16,
    wall_thickness: "1,59",
    weight: 245,
    outside_diameter_global: "406,4",
    wall_thickness_global: "40,49",
    weight_global: "364,6",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 664,
    nominal_pipe_size_inch: 18,
    schedule: "10 Ga.",
    outside_diameter: 18,
    wall_thickness: "0,13",
    weight: 26,
    outside_diameter_global: "457,2",
    wall_thickness_global: "3,4",
    weight_global: "38,69",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 665,
    nominal_pipe_size_inch: 18,
    schedule: "8 Ga.",
    outside_diameter: 18,
    wall_thickness: "0,16",
    weight: 31,
    outside_diameter_global: "457,2",
    wall_thickness_global: "4,17",
    weight_global: "46,13",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 666,
    nominal_pipe_size_inch: 18,
    schedule: "6 Ga.",
    outside_diameter: 18,
    wall_thickness: "0,19",
    weight: 37,
    outside_diameter_global: "457,2",
    wall_thickness_global: "4,93",
    weight_global: "55,06",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 667,
    nominal_pipe_size_inch: 18,
    schedule: "3 Ga.",
    outside_diameter: 18,
    wall_thickness: "0,24",
    weight: 45,
    outside_diameter_global: "457,2",
    wall_thickness_global: "6,07",
    weight_global: "66,97",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 668,
    nominal_pipe_size_inch: 18,
    schedule: 10,
    outside_diameter: 18,
    wall_thickness: "0,25",
    weight: 47,
    outside_diameter_global: "457,2",
    wall_thickness_global: "6,35",
    weight_global: "69,94",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 669,
    nominal_pipe_size_inch: 18,
    schedule: "API",
    outside_diameter: 18,
    wall_thickness: "0,28",
    weight: 49,
    outside_diameter_global: "457,2",
    wall_thickness_global: "7,14",
    weight_global: "72,92",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 670,
    nominal_pipe_size_inch: 18,
    schedule: 20,
    outside_diameter: 18,
    wall_thickness: "0,31",
    weight: 59,
    outside_diameter_global: "457,2",
    wall_thickness_global: "7,92",
    weight_global: "87,8",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 671,
    nominal_pipe_size_inch: 18,
    schedule: "API",
    outside_diameter: 18,
    wall_thickness: "0,34",
    weight: 65,
    outside_diameter_global: "457,2",
    wall_thickness_global: "8,74",
    weight_global: "96,73",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 672,
    nominal_pipe_size_inch: 18,
    schedule: "Std.",
    outside_diameter: 18,
    wall_thickness: "0,38",
    weight: 71,
    outside_diameter_global: "457,2",
    wall_thickness_global: "9,53",
    weight_global: "105,66",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 673,
    nominal_pipe_size_inch: 18,
    schedule: "API",
    outside_diameter: 18,
    wall_thickness: "0,41",
    weight: 76,
    outside_diameter_global: "457,2",
    wall_thickness_global: "10,31",
    weight_global: "113,1",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 674,
    nominal_pipe_size_inch: 18,
    schedule: 30,
    outside_diameter: 18,
    wall_thickness: "0,44",
    weight: 82,
    outside_diameter_global: "457,2",
    wall_thickness_global: "11,13",
    weight_global: "122,03",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 675,
    nominal_pipe_size_inch: 18,
    schedule: "X-Stg.",
    outside_diameter: 18,
    wall_thickness: "0,5",
    weight: 93,
    outside_diameter_global: "457,2",
    wall_thickness_global: "12,7",
    weight_global: "138,4",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 676,
    nominal_pipe_size_inch: 18,
    schedule: 40,
    outside_diameter: 18,
    wall_thickness: "0,56",
    weight: 105,
    outside_diameter_global: "457,2",
    wall_thickness_global: "14,27",
    weight_global: "156,26",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 677,
    nominal_pipe_size_inch: 18,
    schedule: "--",
    outside_diameter: 18,
    wall_thickness: "0,63",
    weight: 116,
    outside_diameter_global: "457,2",
    wall_thickness_global: "15,88",
    weight_global: "172,63",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 678,
    nominal_pipe_size_inch: 18,
    schedule: 60,
    outside_diameter: 18,
    wall_thickness: "0,75",
    weight: 138,
    outside_diameter_global: "457,2",
    wall_thickness_global: "19,05",
    weight_global: "205,37",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 679,
    nominal_pipe_size_inch: 18,
    schedule: "--",
    outside_diameter: 18,
    wall_thickness: "0,88",
    weight: 160,
    outside_diameter_global: "457,2",
    wall_thickness_global: "22,23",
    weight_global: "238,11",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 680,
    nominal_pipe_size_inch: 18,
    schedule: 80,
    outside_diameter: 18,
    wall_thickness: "0,94",
    weight: 171,
    outside_diameter_global: "457,2",
    wall_thickness_global: "23,83",
    weight_global: "254,48",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 681,
    nominal_pipe_size_inch: 18,
    schedule: "--",
    outside_diameter: 18,
    wall_thickness: 1,
    weight: 182,
    outside_diameter_global: "457,2",
    wall_thickness_global: "25,4",
    weight_global: "270,85",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 682,
    nominal_pipe_size_inch: 18,
    schedule: "--",
    outside_diameter: 18,
    wall_thickness: "1,13",
    weight: 203,
    outside_diameter_global: "457,2",
    wall_thickness_global: "28,58",
    weight_global: "302,1",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 683,
    nominal_pipe_size_inch: 18,
    schedule: 100,
    outside_diameter: 18,
    wall_thickness: "1,16",
    weight: 208,
    outside_diameter_global: "457,2",
    wall_thickness_global: "29,36",
    weight_global: "309,54",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 684,
    nominal_pipe_size_inch: 18,
    schedule: "--",
    outside_diameter: 18,
    wall_thickness: "1,25",
    weight: 224,
    outside_diameter_global: "457,2",
    wall_thickness_global: "31,75",
    weight_global: "333,35",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 685,
    nominal_pipe_size_inch: 18,
    schedule: 120,
    outside_diameter: 18,
    wall_thickness: "1,38",
    weight: 244,
    outside_diameter_global: "457,2",
    wall_thickness_global: "34,93",
    weight_global: "363,11",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 686,
    nominal_pipe_size_inch: 18,
    schedule: "--",
    outside_diameter: 18,
    wall_thickness: "1,5",
    weight: 265,
    outside_diameter_global: "457,2",
    wall_thickness_global: "38,1",
    weight_global: "394,36",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 687,
    nominal_pipe_size_inch: 18,
    schedule: 140,
    outside_diameter: 18,
    wall_thickness: "1,56",
    weight: 275,
    outside_diameter_global: "457,2",
    wall_thickness_global: "39,67",
    weight_global: "409,25",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 688,
    nominal_pipe_size_inch: 18,
    schedule: 160,
    outside_diameter: 18,
    wall_thickness: "1,78",
    weight: 309,
    outside_diameter_global: "457,2",
    wall_thickness_global: "45,24",
    weight_global: "459,84",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 689,
    nominal_pipe_size_inch: 20,
    schedule: "10 Ga.",
    outside_diameter: 20,
    wall_thickness: "0,13",
    weight: 28,
    outside_diameter_global: 508,
    wall_thickness_global: "3,4",
    weight_global: "41,67",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 690,
    nominal_pipe_size_inch: 20,
    schedule: "8 Ga.",
    outside_diameter: 20,
    wall_thickness: "0,16",
    weight: 35,
    outside_diameter_global: 508,
    wall_thickness_global: "4,17",
    weight_global: "52,09",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 691,
    nominal_pipe_size_inch: 20,
    schedule: "6 Ga.",
    outside_diameter: 20,
    wall_thickness: "0,19",
    weight: 41,
    outside_diameter_global: 508,
    wall_thickness_global: "4,93",
    weight_global: "61,02",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 692,
    nominal_pipe_size_inch: 20,
    schedule: "3 Ga.",
    outside_diameter: 20,
    wall_thickness: "0,24",
    weight: 50,
    outside_diameter_global: 508,
    wall_thickness_global: "6,07",
    weight_global: "74,41",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 693,
    nominal_pipe_size_inch: 20,
    schedule: 10,
    outside_diameter: 20,
    wall_thickness: "0,25",
    weight: 53,
    outside_diameter_global: 508,
    wall_thickness_global: "6,35",
    weight_global: "78,87",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 694,
    nominal_pipe_size_inch: 20,
    schedule: "API",
    outside_diameter: 20,
    wall_thickness: "0,28",
    weight: 59,
    outside_diameter_global: 508,
    wall_thickness_global: "7,14",
    weight_global: "87,8",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 695,
    nominal_pipe_size_inch: 20,
    schedule: "API",
    outside_diameter: 20,
    wall_thickness: "0,31",
    weight: 66,
    outside_diameter_global: 508,
    wall_thickness_global: "7,92",
    weight_global: "98,22",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 696,
    nominal_pipe_size_inch: 20,
    schedule: "API",
    outside_diameter: 20,
    wall_thickness: "0,34",
    weight: 72,
    outside_diameter_global: 508,
    wall_thickness_global: "8,74",
    weight_global: "107,15",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 697,
    nominal_pipe_size_inch: 20,
    schedule: "Std.",
    outside_diameter: 20,
    wall_thickness: "0,38",
    weight: 79,
    outside_diameter_global: 508,
    wall_thickness_global: "9,53",
    weight_global: "117,57",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 698,
    nominal_pipe_size_inch: 20,
    schedule: "API",
    outside_diameter: 20,
    wall_thickness: "0,41",
    weight: 85,
    outside_diameter_global: 508,
    wall_thickness_global: "10,31",
    weight_global: "126,49",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 699,
    nominal_pipe_size_inch: 20,
    schedule: "API",
    outside_diameter: 20,
    wall_thickness: "0,44",
    weight: 92,
    outside_diameter_global: 508,
    wall_thickness_global: "11,13",
    weight_global: "136,91",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 700,
    nominal_pipe_size_inch: 20,
    schedule: "X-Stg.",
    outside_diameter: 20,
    wall_thickness: "0,5",
    weight: 105,
    outside_diameter_global: 508,
    wall_thickness_global: "12,7",
    weight_global: "156,26",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 701,
    nominal_pipe_size_inch: 20,
    schedule: 40,
    outside_diameter: 20,
    wall_thickness: "0,59",
    weight: 123,
    outside_diameter_global: 508,
    wall_thickness_global: "15,09",
    weight_global: "183,04",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 702,
    nominal_pipe_size_inch: 20,
    schedule: "--",
    outside_diameter: 20,
    wall_thickness: "0,63",
    weight: 129,
    outside_diameter_global: 508,
    wall_thickness_global: "15,88",
    weight_global: "191,97",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 703,
    nominal_pipe_size_inch: 20,
    schedule: 60,
    outside_diameter: 20,
    wall_thickness: "0,81",
    weight: 167,
    outside_diameter_global: 508,
    wall_thickness_global: "20,62",
    weight_global: "248,52",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 704,
    nominal_pipe_size_inch: 20,
    schedule: "--",
    outside_diameter: 20,
    wall_thickness: "0,88",
    weight: 179,
    outside_diameter_global: 508,
    wall_thickness_global: "22,23",
    weight_global: "266,38",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 705,
    nominal_pipe_size_inch: 20,
    schedule: "--",
    outside_diameter: 20,
    wall_thickness: 1,
    weight: 203,
    outside_diameter_global: 508,
    wall_thickness_global: "25,4",
    weight_global: "302,1",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 706,
    nominal_pipe_size_inch: 20,
    schedule: 80,
    outside_diameter: 20,
    wall_thickness: "1,03",
    weight: 209,
    outside_diameter_global: 508,
    wall_thickness_global: "26,19",
    weight_global: "311,03",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 707,
    nominal_pipe_size_inch: 20,
    schedule: "--",
    outside_diameter: 20,
    wall_thickness: "1,13",
    weight: 227,
    outside_diameter_global: 508,
    wall_thickness_global: "28,58",
    weight_global: "337,81",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 708,
    nominal_pipe_size_inch: 20,
    schedule: "--",
    outside_diameter: 20,
    wall_thickness: "1,25",
    weight: 250,
    outside_diameter_global: 508,
    wall_thickness_global: "31,75",
    weight_global: "372,04",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 709,
    nominal_pipe_size_inch: 20,
    schedule: 100,
    outside_diameter: 20,
    wall_thickness: "1,28",
    weight: 256,
    outside_diameter_global: 508,
    wall_thickness_global: "32,54",
    weight_global: "380,97",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 710,
    nominal_pipe_size_inch: 20,
    schedule: "--",
    outside_diameter: 20,
    wall_thickness: "1,38",
    weight: 274,
    outside_diameter_global: 508,
    wall_thickness_global: "34,93",
    weight_global: "407,76",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 711,
    nominal_pipe_size_inch: 20,
    schedule: 120,
    outside_diameter: 20,
    wall_thickness: "1,5",
    weight: 297,
    outside_diameter_global: 508,
    wall_thickness_global: "38,1",
    weight_global: "441,99",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 712,
    nominal_pipe_size_inch: 20,
    schedule: 140,
    outside_diameter: 20,
    wall_thickness: "1,75",
    weight: 342,
    outside_diameter_global: 508,
    wall_thickness_global: "44,45",
    weight_global: "508,95",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 713,
    nominal_pipe_size_inch: 20,
    schedule: 160,
    outside_diameter: 20,
    wall_thickness: "1,97",
    weight: 379,
    outside_diameter_global: 508,
    wall_thickness_global: "50,01",
    weight_global: "564,01",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 714,
    nominal_pipe_size_inch: 22,
    schedule: "8 Ga.",
    outside_diameter: 22,
    wall_thickness: "0,16",
    weight: 38,
    outside_diameter_global: "558,8",
    wall_thickness_global: "4,17",
    weight_global: "56,55",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 715,
    nominal_pipe_size_inch: 22,
    schedule: "6 Ga.",
    outside_diameter: 22,
    wall_thickness: "0,19",
    weight: 45,
    outside_diameter_global: "558,8",
    wall_thickness_global: "4,93",
    weight_global: "66,97",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 716,
    nominal_pipe_size_inch: 22,
    schedule: "3 Ga.",
    outside_diameter: 22,
    wall_thickness: "0,24",
    weight: 56,
    outside_diameter_global: "558,8",
    wall_thickness_global: "6,07",
    weight_global: "83,34",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 717,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,25",
    weight: 58,
    outside_diameter_global: "558,8",
    wall_thickness_global: "6,35",
    weight_global: "86,31",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 718,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,28",
    weight: 65,
    outside_diameter_global: "558,8",
    wall_thickness_global: "7,14",
    weight_global: "96,73",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 719,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,31",
    weight: 72,
    outside_diameter_global: "558,8",
    wall_thickness_global: "7,92",
    weight_global: "107,15",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 720,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,34",
    weight: 80,
    outside_diameter_global: "558,8",
    wall_thickness_global: "8,74",
    weight_global: "119,05",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 721,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,38",
    weight: 87,
    outside_diameter_global: "558,8",
    wall_thickness_global: "9,53",
    weight_global: "129,47",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 722,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,41",
    weight: 94,
    outside_diameter_global: "558,8",
    wall_thickness_global: "10,31",
    weight_global: "139,89",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 723,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,44",
    weight: 101,
    outside_diameter_global: "558,8",
    wall_thickness_global: "11,13",
    weight_global: "150,31",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 724,
    nominal_pipe_size_inch: 22,
    schedule: "API",
    outside_diameter: 22,
    wall_thickness: "0,5",
    weight: 115,
    outside_diameter_global: "558,8",
    wall_thickness_global: "12,7",
    weight_global: "171,14",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 725,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "0,63",
    weight: 143,
    outside_diameter_global: "558,8",
    wall_thickness_global: "15,88",
    weight_global: "212,81",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 726,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "0,75",
    weight: 170,
    outside_diameter_global: "558,8",
    wall_thickness_global: "19,05",
    weight_global: "252,99",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 727,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "0,88",
    weight: 198,
    outside_diameter_global: "558,8",
    wall_thickness_global: "22,23",
    weight_global: "294,66",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 728,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: 1,
    weight: 224,
    outside_diameter_global: "558,8",
    wall_thickness_global: "25,4",
    weight_global: "333,35",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 729,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "1,13",
    weight: 251,
    outside_diameter_global: "558,8",
    wall_thickness_global: "28,58",
    weight_global: "373,53",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 730,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "1,25",
    weight: 277,
    outside_diameter_global: "558,8",
    wall_thickness_global: "31,75",
    weight_global: "412,22",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 731,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "1,38",
    weight: 303,
    outside_diameter_global: "558,8",
    wall_thickness_global: "34,93",
    weight_global: "450,91",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 732,
    nominal_pipe_size_inch: 22,
    schedule: "--",
    outside_diameter: 22,
    wall_thickness: "1,5",
    weight: 329,
    outside_diameter_global: "558,8",
    wall_thickness_global: "38,1",
    weight_global: "489,61",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 733,
    nominal_pipe_size_inch: 24,
    schedule: "8 Ga.",
    outside_diameter: 24,
    wall_thickness: "0,16",
    weight: 42,
    outside_diameter_global: "609,6",
    wall_thickness_global: "4,17",
    weight_global: "62,5",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 734,
    nominal_pipe_size_inch: 24,
    schedule: "6 Ga.",
    outside_diameter: 24,
    wall_thickness: "0,19",
    weight: 49,
    outside_diameter_global: "609,6",
    wall_thickness_global: "4,93",
    weight_global: "72,92",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 735,
    nominal_pipe_size_inch: 24,
    schedule: "3 Ga.",
    outside_diameter: 24,
    wall_thickness: "0,24",
    weight: 61,
    outside_diameter_global: "609,6",
    wall_thickness_global: "6,07",
    weight_global: "90,78",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 736,
    nominal_pipe_size_inch: 24,
    schedule: 10,
    outside_diameter: 24,
    wall_thickness: "0,25",
    weight: 63,
    outside_diameter_global: "609,6",
    wall_thickness_global: "6,35",
    weight_global: "93,75",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 737,
    nominal_pipe_size_inch: 24,
    schedule: "API",
    outside_diameter: 24,
    wall_thickness: "0,28",
    weight: 71,
    outside_diameter_global: "609,6",
    wall_thickness_global: "7,14",
    weight_global: "105,66",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 738,
    nominal_pipe_size_inch: 24,
    schedule: "API",
    outside_diameter: 24,
    wall_thickness: "0,31",
    weight: 79,
    outside_diameter_global: "609,6",
    wall_thickness_global: "7,92",
    weight_global: "117,57",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 739,
    nominal_pipe_size_inch: 24,
    schedule: "API",
    outside_diameter: 24,
    wall_thickness: "0,34",
    weight: 87,
    outside_diameter_global: "609,6",
    wall_thickness_global: "8,74",
    weight_global: "129,47",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 740,
    nominal_pipe_size_inch: 24,
    schedule: "Std.",
    outside_diameter: 24,
    wall_thickness: "0,38",
    weight: 95,
    outside_diameter_global: "609,6",
    wall_thickness_global: "9,53",
    weight_global: "141,38",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 741,
    nominal_pipe_size_inch: 24,
    schedule: "API",
    outside_diameter: 24,
    wall_thickness: "0,41",
    weight: 102,
    outside_diameter_global: "609,6",
    wall_thickness_global: "10,31",
    weight_global: "151,79",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 742,
    nominal_pipe_size_inch: 24,
    schedule: "API",
    outside_diameter: 24,
    wall_thickness: "0,44",
    weight: 110,
    outside_diameter_global: "609,6",
    wall_thickness_global: "11,13",
    weight_global: "163,7",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 743,
    nominal_pipe_size_inch: 24,
    schedule: "X-Stg.",
    outside_diameter: 24,
    wall_thickness: "0,5",
    weight: 125,
    outside_diameter_global: "609,6",
    wall_thickness_global: "12,7",
    weight_global: "186,02",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 744,
    nominal_pipe_size_inch: 24,
    schedule: 30,
    outside_diameter: 24,
    wall_thickness: "0,56",
    weight: 141,
    outside_diameter_global: "609,6",
    wall_thickness_global: "14,27",
    weight_global: "209,83",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 745,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "0,63",
    weight: 156,
    outside_diameter_global: "609,6",
    wall_thickness_global: "15,88",
    weight_global: "232,15",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 746,
    nominal_pipe_size_inch: 24,
    schedule: 40,
    outside_diameter: 24,
    wall_thickness: "0,69",
    weight: 171,
    outside_diameter_global: "609,6",
    wall_thickness_global: "17,48",
    weight_global: "254,48",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 747,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "0,75",
    weight: 186,
    outside_diameter_global: "609,6",
    wall_thickness_global: "19,05",
    weight_global: "276,8",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 748,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "0,88",
    weight: 216,
    outside_diameter_global: "609,6",
    wall_thickness_global: "22,23",
    weight_global: "321,44",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 749,
    nominal_pipe_size_inch: 24,
    schedule: 60,
    outside_diameter: 24,
    wall_thickness: "0,97",
    weight: 238,
    outside_diameter_global: "609,6",
    wall_thickness_global: "24,61",
    weight_global: "354,18",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 750,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: 1,
    weight: 246,
    outside_diameter_global: "609,6",
    wall_thickness_global: "25,4",
    weight_global: "366,09",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 751,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "1,13",
    weight: 275,
    outside_diameter_global: "609,6",
    wall_thickness_global: "28,58",
    weight_global: "409,25",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 752,
    nominal_pipe_size_inch: 24,
    schedule: 80,
    outside_diameter: 24,
    wall_thickness: "1,22",
    weight: 297,
    outside_diameter_global: "609,6",
    wall_thickness_global: "30,96",
    weight_global: "441,99",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 753,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "1,25",
    weight: 304,
    outside_diameter_global: "609,6",
    wall_thickness_global: "31,75",
    weight_global: "452,4",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 754,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "1,38",
    weight: 332,
    outside_diameter_global: "609,6",
    wall_thickness_global: "34,93",
    weight_global: "494,07",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 755,
    nominal_pipe_size_inch: 24,
    schedule: "--",
    outside_diameter: 24,
    wall_thickness: "1,5",
    weight: 361,
    outside_diameter_global: "609,6",
    wall_thickness_global: "38,1",
    weight_global: "537,23",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 756,
    nominal_pipe_size_inch: 24,
    schedule: 100,
    outside_diameter: 24,
    wall_thickness: "1,53",
    weight: 367,
    outside_diameter_global: "609,6",
    wall_thickness_global: "38,89",
    weight_global: "546,16",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 757,
    nominal_pipe_size_inch: 24,
    schedule: 120,
    outside_diameter: 24,
    wall_thickness: "1,81",
    weight: 429,
    outside_diameter_global: "609,6",
    wall_thickness_global: "46,02",
    weight_global: "638,42",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 758,
    nominal_pipe_size_inch: 24,
    schedule: 140,
    outside_diameter: 24,
    wall_thickness: "2,06",
    weight: 484,
    outside_diameter_global: "609,6",
    wall_thickness_global: "52,37",
    weight_global: "720,27",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 759,
    nominal_pipe_size_inch: 24,
    schedule: 160,
    outside_diameter: 24,
    wall_thickness: "2,34",
    weight: 542,
    outside_diameter_global: "609,6",
    wall_thickness_global: "59,54",
    weight_global: "806,59",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 760,
    nominal_pipe_size_inch: 26,
    schedule: "8 Ga.",
    outside_diameter: 26,
    wall_thickness: "0,16",
    weight: 45,
    outside_diameter_global: "660,4",
    wall_thickness_global: "4,17",
    weight_global: "66,97",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 761,
    nominal_pipe_size_inch: 26,
    schedule: "6 Ga.",
    outside_diameter: 26,
    wall_thickness: "0,19",
    weight: 54,
    outside_diameter_global: "660,4",
    wall_thickness_global: "4,93",
    weight_global: "80,36",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 762,
    nominal_pipe_size_inch: 26,
    schedule: "3 Ga.",
    outside_diameter: 26,
    wall_thickness: "0,24",
    weight: 66,
    outside_diameter_global: "660,4",
    wall_thickness_global: "6,07",
    weight_global: "98,22",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 763,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,25",
    weight: 67,
    outside_diameter_global: "660,4",
    wall_thickness_global: "6,35",
    weight_global: "99,71",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 764,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,28",
    weight: 77,
    outside_diameter_global: "660,4",
    wall_thickness_global: "7,14",
    weight_global: "114,59",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 765,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,31",
    weight: 84,
    outside_diameter_global: "660,4",
    wall_thickness_global: "7,92",
    weight_global: "125,01",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 766,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,34",
    weight: 94,
    outside_diameter_global: "660,4",
    wall_thickness_global: "8,74",
    weight_global: "139,89",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 767,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,38",
    weight: 103,
    outside_diameter_global: "660,4",
    wall_thickness_global: "9,53",
    weight_global: "153,28",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 768,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,41",
    weight: 111,
    outside_diameter_global: "660,4",
    wall_thickness_global: "10,31",
    weight_global: "165,19",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 769,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,44",
    weight: 120,
    outside_diameter_global: "660,4",
    wall_thickness_global: "11,13",
    weight_global: "178,58",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 770,
    nominal_pipe_size_inch: 26,
    schedule: "API",
    outside_diameter: 26,
    wall_thickness: "0,5",
    weight: 136,
    outside_diameter_global: "660,4",
    wall_thickness_global: "12,7",
    weight_global: "202,39",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 771,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: "0,63",
    weight: 169,
    outside_diameter_global: "660,4",
    wall_thickness_global: "15,88",
    weight_global: "251,5",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 772,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: "0,75",
    weight: 202,
    outside_diameter_global: "660,4",
    wall_thickness_global: "19,05",
    weight_global: "300,61",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 773,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: "0,88",
    weight: 235,
    outside_diameter_global: "660,4",
    wall_thickness_global: "22,23",
    weight_global: "349,72",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 774,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: 1,
    weight: 267,
    outside_diameter_global: "660,4",
    wall_thickness_global: "25,4",
    weight_global: "397,34",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 775,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: "1,13",
    weight: 299,
    outside_diameter_global: "660,4",
    wall_thickness_global: "28,58",
    weight_global: "444,96",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 776,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: "1,38",
    weight: 362,
    outside_diameter_global: "660,4",
    wall_thickness_global: "34,93",
    weight_global: "538,72",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 777,
    nominal_pipe_size_inch: 26,
    schedule: "--",
    outside_diameter: 26,
    wall_thickness: "1,5",
    weight: 393,
    outside_diameter_global: "660,4",
    wall_thickness_global: "38,1",
    weight_global: "584,85",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 778,
    nominal_pipe_size_inch: 30,
    schedule: "8 Ga.",
    outside_diameter: 30,
    wall_thickness: "0,16",
    weight: 52,
    outside_diameter_global: 762,
    wall_thickness_global: "4,17",
    weight_global: "77,39",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 779,
    nominal_pipe_size_inch: 30,
    schedule: "6 Ga.",
    outside_diameter: 30,
    wall_thickness: "0,19",
    weight: 62,
    outside_diameter_global: 762,
    wall_thickness_global: "4,93",
    weight_global: "92,27",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 780,
    nominal_pipe_size_inch: 30,
    schedule: "3 Ga.",
    outside_diameter: 30,
    wall_thickness: "0,24",
    weight: 76,
    outside_diameter_global: 762,
    wall_thickness_global: "6,07",
    weight_global: "113,1",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 781,
    nominal_pipe_size_inch: 30,
    schedule: "API",
    outside_diameter: 30,
    wall_thickness: "0,25",
    weight: 79,
    outside_diameter_global: 762,
    wall_thickness_global: "6,35",
    weight_global: "117,57",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 782,
    nominal_pipe_size_inch: 30,
    schedule: "API",
    outside_diameter: 30,
    wall_thickness: "0,28",
    weight: 89,
    outside_diameter_global: 762,
    wall_thickness_global: "7,14",
    weight_global: "132,45",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 783,
    nominal_pipe_size_inch: 30,
    schedule: 10,
    outside_diameter: 30,
    wall_thickness: "0,31",
    weight: 99,
    outside_diameter_global: 762,
    wall_thickness_global: "7,92",
    weight_global: "147,33",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 784,
    nominal_pipe_size_inch: 30,
    schedule: "API",
    outside_diameter: 30,
    wall_thickness: "0,34",
    weight: 109,
    outside_diameter_global: 762,
    wall_thickness_global: "8,74",
    weight_global: "162,21",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 785,
    nominal_pipe_size_inch: 30,
    schedule: "API",
    outside_diameter: 30,
    wall_thickness: "0,38",
    weight: 119,
    outside_diameter_global: 762,
    wall_thickness_global: "9,53",
    weight_global: "177,09",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 786,
    nominal_pipe_size_inch: 30,
    schedule: "API",
    outside_diameter: 30,
    wall_thickness: "0,41",
    weight: 130,
    outside_diameter_global: 762,
    wall_thickness_global: "10,31",
    weight_global: "193,46",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 787,
    nominal_pipe_size_inch: 30,
    schedule: "API",
    outside_diameter: 30,
    wall_thickness: "0,44",
    weight: 138,
    outside_diameter_global: 762,
    wall_thickness_global: "11,13",
    weight_global: "205,37",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 788,
    nominal_pipe_size_inch: 30,
    schedule: 20,
    outside_diameter: 30,
    wall_thickness: "0,5",
    weight: 158,
    outside_diameter_global: 762,
    wall_thickness_global: "12,7",
    weight_global: "235,13",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 789,
    nominal_pipe_size_inch: 30,
    schedule: 30,
    outside_diameter: 30,
    wall_thickness: "0,63",
    weight: 196,
    outside_diameter_global: 762,
    wall_thickness_global: "15,88",
    weight_global: "291,68",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 790,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: "0,75",
    weight: 234,
    outside_diameter_global: 762,
    wall_thickness_global: "19,05",
    weight_global: "348,23",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 791,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: "0,88",
    weight: 272,
    outside_diameter_global: 762,
    wall_thickness_global: "22,23",
    weight_global: "404,78",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 792,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: 1,
    weight: 310,
    outside_diameter_global: 762,
    wall_thickness_global: "25,4",
    weight_global: "461,33",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 793,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: "1,13",
    weight: 347,
    outside_diameter_global: 762,
    wall_thickness_global: "28,58",
    weight_global: "516,39",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 794,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: "1,25",
    weight: 384,
    outside_diameter_global: 762,
    wall_thickness_global: "31,75",
    weight_global: "571,46",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 795,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: "1,38",
    weight: 421,
    outside_diameter_global: 762,
    wall_thickness_global: "34,93",
    weight_global: "626,52",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 796,
    nominal_pipe_size_inch: 30,
    schedule: "--",
    outside_diameter: 30,
    wall_thickness: "1,5",
    weight: 457,
    outside_diameter_global: 762,
    wall_thickness_global: "38,1",
    weight_global: "680,09",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 797,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,25",
    weight: 85,
    outside_diameter_global: "812,8",
    wall_thickness_global: "6,35",
    weight_global: "126,49",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 798,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,28",
    weight: 95,
    outside_diameter_global: "812,8",
    wall_thickness_global: "7,14",
    weight_global: "141,38",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 799,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,31",
    weight: 106,
    outside_diameter_global: "812,8",
    wall_thickness_global: "7,92",
    weight_global: "157,75",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 800,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,34",
    weight: 116,
    outside_diameter_global: "812,8",
    wall_thickness_global: "8,74",
    weight_global: "172,63",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 801,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,38",
    weight: 127,
    outside_diameter_global: "812,8",
    wall_thickness_global: "9,53",
    weight_global: 189,
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 802,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,41",
    weight: 137,
    outside_diameter_global: "812,8",
    wall_thickness_global: "10,31",
    weight_global: "203,88",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 803,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,44",
    weight: 148,
    outside_diameter_global: "812,8",
    wall_thickness_global: "11,13",
    weight_global: "220,25",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 804,
    nominal_pipe_size_inch: 32,
    schedule: "API",
    outside_diameter: 32,
    wall_thickness: "0,5",
    weight: 168,
    outside_diameter_global: "812,8",
    wall_thickness_global: "12,7",
    weight_global: "250,01",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 805,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "0,63",
    weight: 209,
    outside_diameter_global: "812,8",
    wall_thickness_global: "15,88",
    weight_global: "311,03",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 806,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "0,75",
    weight: 250,
    outside_diameter_global: "812,8",
    wall_thickness_global: "19,05",
    weight_global: "372,04",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 807,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "0,88",
    weight: 291,
    outside_diameter_global: "812,8",
    wall_thickness_global: "22,23",
    weight_global: "433,06",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 808,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: 1,
    weight: 331,
    outside_diameter_global: "812,8",
    wall_thickness_global: "25,4",
    weight_global: "492,58",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 809,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "1,13",
    weight: 371,
    outside_diameter_global: "812,8",
    wall_thickness_global: "28,58",
    weight_global: "552,11",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 810,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "1,25",
    weight: 410,
    outside_diameter_global: "812,8",
    wall_thickness_global: "31,75",
    weight_global: "610,15",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 811,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "1,38",
    weight: 450,
    outside_diameter_global: "812,8",
    wall_thickness_global: "34,93",
    weight_global: "669,67",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 812,
    nominal_pipe_size_inch: 32,
    schedule: "--",
    outside_diameter: 32,
    wall_thickness: "1,5",
    weight: 489,
    outside_diameter_global: "812,8",
    wall_thickness_global: "38,1",
    weight_global: "727,71",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 813,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,25",
    weight: 90,
    outside_diameter_global: "863,6",
    wall_thickness_global: "6,35",
    weight_global: "133,94",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 814,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,28",
    weight: 101,
    outside_diameter_global: "863,6",
    wall_thickness_global: "7,14",
    weight_global: "150,31",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 815,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,31",
    weight: 112,
    outside_diameter_global: "863,6",
    wall_thickness_global: "7,92",
    weight_global: "166,67",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 816,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,34",
    weight: 124,
    outside_diameter_global: "863,6",
    wall_thickness_global: "8,74",
    weight_global: "184,53",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 817,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,38",
    weight: 135,
    outside_diameter_global: "863,6",
    wall_thickness_global: "9,53",
    weight_global: "200,9",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 818,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,41",
    weight: 146,
    outside_diameter_global: "863,6",
    wall_thickness_global: "10,31",
    weight_global: "217,27",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 819,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,44",
    weight: 157,
    outside_diameter_global: "863,6",
    wall_thickness_global: "11,13",
    weight_global: "233,64",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 820,
    nominal_pipe_size_inch: 34,
    schedule: "API",
    outside_diameter: 34,
    wall_thickness: "0,5",
    weight: 179,
    outside_diameter_global: "863,6",
    wall_thickness_global: "12,7",
    weight_global: "266,38",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 821,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "0,63",
    weight: 223,
    outside_diameter_global: "863,6",
    wall_thickness_global: "15,88",
    weight_global: "331,86",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 822,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "0,75",
    weight: 266,
    outside_diameter_global: "863,6",
    wall_thickness_global: "19,05",
    weight_global: "395,85",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 823,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "0,88",
    weight: 308,
    outside_diameter_global: "863,6",
    wall_thickness_global: "22,23",
    weight_global: "458,36",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 824,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: 1,
    weight: 353,
    outside_diameter_global: "863,6",
    wall_thickness_global: "25,4",
    weight_global: "525,32",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 825,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "1,13",
    weight: 395,
    outside_diameter_global: "863,6",
    wall_thickness_global: "28,58",
    weight_global: "587,83",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 826,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "1,25",
    weight: 437,
    outside_diameter_global: "863,6",
    wall_thickness_global: "31,75",
    weight_global: "650,33",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 827,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "1,38",
    weight: 479,
    outside_diameter_global: "863,6",
    wall_thickness_global: "34,93",
    weight_global: "712,83",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 828,
    nominal_pipe_size_inch: 34,
    schedule: "--",
    outside_diameter: 34,
    wall_thickness: "1,5",
    weight: 521,
    outside_diameter_global: "863,6",
    wall_thickness_global: "38,1",
    weight_global: "775,33",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 829,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "0,16",
    weight: 63,
    outside_diameter_global: "914,4",
    wall_thickness_global: "4,17",
    weight_global: "93,75",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 830,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "0,19",
    weight: 74,
    outside_diameter_global: "914,4",
    wall_thickness_global: "4,93",
    weight_global: "110,12",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 831,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "0,24",
    weight: 91,
    outside_diameter_global: "914,4",
    wall_thickness_global: "6,07",
    weight_global: "135,42",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 832,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,25",
    weight: 96,
    outside_diameter_global: "914,4",
    wall_thickness_global: "6,35",
    weight_global: "142,86",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 833,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,28",
    weight: 107,
    outside_diameter_global: "914,4",
    wall_thickness_global: "7,14",
    weight_global: "159,23",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 834,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,31",
    weight: 119,
    outside_diameter_global: "914,4",
    wall_thickness_global: "7,92",
    weight_global: "177,09",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 835,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,34",
    weight: 131,
    outside_diameter_global: "914,4",
    wall_thickness_global: "8,74",
    weight_global: "194,95",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 836,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,38",
    weight: 143,
    outside_diameter_global: "914,4",
    wall_thickness_global: "9,53",
    weight_global: "212,81",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 837,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,41",
    weight: 154,
    outside_diameter_global: "914,4",
    wall_thickness_global: "10,31",
    weight_global: "229,18",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 838,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,44",
    weight: 166,
    outside_diameter_global: "914,4",
    wall_thickness_global: "11,13",
    weight_global: "247,04",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 839,
    nominal_pipe_size_inch: 36,
    schedule: "API",
    outside_diameter: 36,
    wall_thickness: "0,5",
    weight: 190,
    outside_diameter_global: "914,4",
    wall_thickness_global: "12,7",
    weight_global: "282,75",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 840,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "0,63",
    weight: 236,
    outside_diameter_global: "914,4",
    wall_thickness_global: "15,88",
    weight_global: "351,21",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 841,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "0,75",
    weight: 282,
    outside_diameter_global: "914,4",
    wall_thickness_global: "19,05",
    weight_global: "419,66",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 842,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "0,88",
    weight: 329,
    outside_diameter_global: "914,4",
    wall_thickness_global: "22,23",
    weight_global: "489,61",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 843,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: 1,
    weight: 374,
    outside_diameter_global: "914,4",
    wall_thickness_global: "25,4",
    weight_global: "556,57",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 844,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "1,13",
    weight: 419,
    outside_diameter_global: "914,4",
    wall_thickness_global: "28,58",
    weight_global: "623,54",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 845,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "1,25",
    weight: 464,
    outside_diameter_global: "914,4",
    wall_thickness_global: "31,75",
    weight_global: "690,51",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 846,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "1,38",
    weight: 509,
    outside_diameter_global: "914,4",
    wall_thickness_global: "34,93",
    weight_global: "757,48",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 847,
    nominal_pipe_size_inch: 36,
    schedule: "--",
    outside_diameter: 36,
    wall_thickness: "1,5",
    weight: 553,
    outside_diameter_global: "914,4",
    wall_thickness_global: "38,1",
    weight_global: "822,96",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 848,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "0,25",
    weight: 112,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "6,35",
    weight_global: "166,67",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 849,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "0,38",
    weight: 167,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "9,53",
    weight_global: "248,52",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 850,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "0,5",
    weight: 222,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "12,7",
    weight_global: "330,37",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 851,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "0,63",
    weight: 276,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "15,88",
    weight_global: "410,73",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 852,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "0,75",
    weight: 331,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "19,05",
    weight_global: "492,58",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 853,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "0,88",
    weight: 385,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "22,23",
    weight_global: "572,94",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 854,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: 1,
    weight: 438,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "25,4",
    weight_global: "651,82",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 855,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "1,13",
    weight: 492,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "28,58",
    weight_global: "732,18",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 856,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "1,25",
    weight: 544,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "31,75",
    weight_global: "809,56",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 857,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "1,38",
    weight: 597,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "34,93",
    weight_global: "888,43",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
  {
    piping_data_id: 858,
    nominal_pipe_size_inch: 42,
    schedule: "--",
    outside_diameter: 42,
    wall_thickness: "1,5",
    weight: 649,
    outside_diameter_global: "1066,8",
    wall_thickness_global: "38,1",
    weight_global: "965,82",
    ixx: 1000000,
    type: 1,
    country_code: "American",
  },
].map((item) => ({
  piping_details_id: item.piping_data_id,
  nominal_pipe_size_inch: (item.nominal_pipe_size_inch + ""),
  schedule: item.schedule + "",
  outside_diameter: fixValueToNumber(item.outside_diameter, "float"),
  wall_thickness: fixValueToNumber(item.wall_thickness, "float"),
  weight: fixValueToNumber(item.weight, "float"),
  outside_diameter_global: fixValueToNumber(
    item.outside_diameter_global,
    "float"
  ),
  wall_thickness_global: fixValueToNumber(item.wall_thickness_global, "float"),
  weight_global: fixValueToNumber(item.weight_global, "float"),
  specification: "",
  ixx: item.ixx,
  material: "",
  country_code: item.country_code,
  type: item.type,
}));
