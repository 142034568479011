import { fixValueToNumber } from "../../components/3d-models/utils";

export const pipingReturns = [
  {
    piping_returns_id: 3,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 33,
    o: 76,
    k: 56,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,38",
    weigth: "0,31",
  },
  {
    piping_returns_id: 4,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 42,
    o: 95,
    k: 70,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,56",
    weigth: "0,5",
  },
  {
    piping_returns_id: 5,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 48,
    o: 114,
    k: 83,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,68",
    weigth: "0,73",
  },
  {
    piping_returns_id: 6,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 60,
    o: 152,
    k: 106,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,91",
    weigth: "1,3",
  },
  {
    piping_returns_id: 7,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 73,
    o: 191,
    k: 132,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,16",
    weigth: "2,57",
  },
  {
    piping_returns_id: 8,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 89,
    o: 229,
    k: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,49",
    weigth: "4,07",
  },
  {
    piping_returns_id: 9,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 102,
    o: 267,
    k: 184,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,74",
    weigth: "5,74",
  },
  {
    piping_returns_id: 10,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 114,
    o: 305,
    k: 210,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,02",
    weigth: "7,8",
  },
  {
    piping_returns_id: 11,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 141,
    o: 381,
    k: 262,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,55",
    weigth: 13,
  },
  {
    piping_returns_id: 12,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 168,
    o: 457,
    k: 313,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "7,11",
    weigth: "20,4",
  },
  {
    piping_returns_id: 13,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 219,
    o: 610,
    k: 414,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "8,18",
    weigth: "40,6",
  },
  {
    piping_returns_id: 14,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 273,
    o: 762,
    k: 518,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,27",
    weigth: 74,
  },
  {
    piping_returns_id: 15,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 324,
    o: 914,
    k: 619,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: 108,
  },
  {
    piping_returns_id: 16,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 356,
    o: 1067,
    k: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: 136,
  },
  {
    piping_returns_id: 17,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 406,
    o: 1219,
    k: 813,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: "178,4",
  },
  {
    piping_returns_id: 18,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 457,
    o: 1372,
    k: 914,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: "225,8",
  },
  {
    piping_returns_id: 19,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 508,
    o: 1524,
    k: 1016,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: "284,8",
  },
  {
    piping_returns_id: 20,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 559,
    o: 1676,
    k: 1118,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: 346,
  },
  {
    piping_returns_id: 21,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 610,
    o: 1829,
    k: 1219,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: "411,8",
  },
  {
    piping_returns_id: 22,
    nps: 1,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 33,
    o: 51,
    k: 41,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,38",
    weigth: "0,2",
  },
  {
    piping_returns_id: 23,
    nps: "1 1/4",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 42,
    o: 64,
    k: 52,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,56",
    weigth: "0,33",
  },
  {
    piping_returns_id: 24,
    nps: "1 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 48,
    o: 76,
    k: 62,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,68",
    weigth: "0,48",
  },
  {
    piping_returns_id: 25,
    nps: 2,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 60,
    o: 102,
    k: 81,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,91",
    weigth: "0,86",
  },
  {
    piping_returns_id: 26,
    nps: "2 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 73,
    o: 127,
    k: 100,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,16",
    weigth: "1,64",
  },
  {
    piping_returns_id: 27,
    nps: 3,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 89,
    o: 152,
    k: 121,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,49",
    weigth: "2,7",
  },
  {
    piping_returns_id: 28,
    nps: "3 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 102,
    o: 178,
    k: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,74",
    weigth: "3,8",
  },
  {
    piping_returns_id: 29,
    nps: 4,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 114,
    o: 203,
    k: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,02",
    weigth: "5,2",
  },
  {
    piping_returns_id: 30,
    nps: 5,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 141,
    o: 254,
    k: 197,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,55",
    weigth: "8,7",
  },
  {
    piping_returns_id: 31,
    nps: 6,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 168,
    o: 305,
    k: 237,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "7,11",
    weigth: "12,9",
  },
  {
    piping_returns_id: 32,
    nps: 8,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 219,
    o: 406,
    k: 313,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "8,18",
    weigth: 33,
  },
  {
    piping_returns_id: 33,
    nps: 10,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 273,
    o: 508,
    k: 391,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,27",
    weigth: "62,3",
  },
  {
    piping_returns_id: 34,
    nps: 12,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 324,
    o: 610,
    k: 467,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: 90,
  },
  {
    piping_returns_id: 35,
    nps: 14,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 356,
    o: 711,
    k: 533,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: 106,
  },
  {
    piping_returns_id: 36,
    nps: 16,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 406,
    o: 813,
    k: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: "142,5",
  },
  {
    piping_returns_id: 37,
    nps: 18,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 457,
    o: 914,
    k: 686,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: 180,
  },
  {
    piping_returns_id: 38,
    nps: 20,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 508,
    o: 1016,
    k: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: 219,
  },
  {
    piping_returns_id: 39,
    nps: 22,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 559,
    o: 1118,
    k: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: 264,
  },
  {
    piping_returns_id: 40,
    nps: 24,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 610,
    o: 1219,
    k: 914,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weigth: 330,
  },
  {
    piping_returns_id: 41,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 21,
    o: 76,
    k: 48,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "3,73",
    weigth: "0,35",
  },
  {
    piping_returns_id: 42,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 27,
    o: 76,
    k: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "3,91",
    weigth: "0,35",
  },
  {
    piping_returns_id: 43,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 33,
    o: 76,
    k: 56,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,55",
    weigth: "0,4",
  },
  {
    piping_returns_id: 44,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 42,
    o: 95,
    k: 70,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,85",
    weigth: "0,7",
  },
  {
    piping_returns_id: 45,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 48,
    o: 114,
    k: 83,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,08",
    weigth: "1,2",
  },
  {
    piping_returns_id: 46,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 60,
    o: 152,
    k: 106,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,54",
    weigth: "1,88",
  },
  {
    piping_returns_id: 47,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 73,
    o: 191,
    k: 132,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,01",
    weigth: "3,4",
  },
  {
    piping_returns_id: 48,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 89,
    o: 229,
    k: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,62",
    weigth: "5,5",
  },
  {
    piping_returns_id: 49,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 102,
    o: 267,
    k: 184,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,08",
    weigth: "7,9",
  },
  {
    piping_returns_id: 50,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 114,
    o: 305,
    k: 210,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,56",
    weigth: "10,8",
  },
  {
    piping_returns_id: 51,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 141,
    o: 381,
    k: 262,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "9,52",
    weigth: "17,7",
  },
  {
    piping_returns_id: 52,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 168,
    o: 457,
    k: 313,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "10,97",
    weigth: "30,7",
  },
  {
    piping_returns_id: 53,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 219,
    o: 610,
    k: 414,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "59,8",
  },
  {
    piping_returns_id: 54,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 273,
    o: 762,
    k: 518,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "97,2",
  },
  {
    piping_returns_id: 55,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 324,
    o: 914,
    k: 619,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: 140,
  },
  {
    piping_returns_id: 56,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 356,
    o: 1067,
    k: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "188,7",
  },
  {
    piping_returns_id: 57,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 406,
    o: 1219,
    k: 813,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "249,5",
  },
  {
    piping_returns_id: 58,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 457,
    o: 1372,
    k: 914,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "315,08",
  },
  {
    piping_returns_id: 59,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 508,
    o: 1524,
    k: 1016,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "388,62",
  },
  {
    piping_returns_id: 60,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 559,
    o: 1676,
    k: 1118,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: 466,
  },
  {
    piping_returns_id: 61,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 610,
    o: 1829,
    k: 1219,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "564,78",
  },
  {
    piping_returns_id: 62,
    nps: 1,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 33,
    o: 51,
    k: 41,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,55",
    weigth: "0,27",
  },
  {
    piping_returns_id: 63,
    nps: "1 1/4",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 42,
    o: 64,
    k: 52,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,85",
    weigth: "0,5",
  },
  {
    piping_returns_id: 64,
    nps: "1 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 48,
    o: 76,
    k: 62,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,08",
    weigth: "0,6",
  },
  {
    piping_returns_id: 65,
    nps: 2,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 60,
    o: 102,
    k: 81,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,54",
    weigth: "1,2",
  },
  {
    piping_returns_id: 66,
    nps: "2 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 73,
    o: 127,
    k: 100,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,01",
    weigth: "2,3",
  },
  {
    piping_returns_id: 67,
    nps: 3,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 89,
    o: 152,
    k: 121,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,62",
    weigth: "3,7",
  },
  {
    piping_returns_id: 68,
    nps: "3 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 102,
    o: 178,
    k: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,08",
    weigth: "5,2",
  },
  {
    piping_returns_id: 69,
    nps: 4,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 114,
    o: 203,
    k: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,56",
    weigth: "7,1",
  },
  {
    piping_returns_id: 70,
    nps: 5,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 141,
    o: 254,
    k: 197,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "9,52",
    weigth: "12,4",
  },
  {
    piping_returns_id: 71,
    nps: 6,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 168,
    o: 305,
    k: 237,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "10,97",
    weigth: "20,4",
  },
  {
    piping_returns_id: 72,
    nps: 8,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 219,
    o: 406,
    k: 313,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: 45,
  },
  {
    piping_returns_id: 73,
    nps: 10,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 273,
    o: 508,
    k: 391,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "62,3",
  },
  {
    piping_returns_id: 74,
    nps: 12,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 324,
    o: 610,
    k: 467,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "98,06",
  },
  {
    piping_returns_id: 75,
    nps: 14,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 356,
    o: 711,
    k: 533,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "126,21",
  },
  {
    piping_returns_id: 76,
    nps: 16,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 406,
    o: 813,
    k: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "165,26",
  },
  {
    piping_returns_id: 77,
    nps: 18,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 457,
    o: 914,
    k: 686,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "209,75",
  },
  {
    piping_returns_id: 78,
    nps: 20,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 508,
    o: 1016,
    k: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "259,7",
  },
  {
    piping_returns_id: 79,
    nps: 22,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 559,
    o: 1118,
    k: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: 314,
  },
  {
    piping_returns_id: 80,
    nps: 24,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 610,
    o: 1219,
    k: 914,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weigth: "376,82",
  },
  {
    piping_returns_id: 83,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 33,
    o: 76,
    k: 56,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "9,09",
    weigth: "0,69",
  },
  {
    piping_returns_id: 84,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 42,
    o: 95,
    k: 70,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "9,7",
    weigth: "1,22",
  },
  {
    piping_returns_id: 85,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 48,
    o: 114,
    k: 83,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "10,16",
    weigth: "1,8",
  },
  {
    piping_returns_id: 86,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 60,
    o: 152,
    k: 106,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "11,07",
    weigth: "3,4",
  },
  {
    piping_returns_id: 87,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 73,
    o: 191,
    k: 132,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "14,02",
    weigth: "6,4",
  },
  {
    piping_returns_id: 88,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 89,
    o: 229,
    k: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "15,24",
    weigth: "10,35",
  },
  {
    piping_returns_id: 89,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 102,
    o: 267,
    k: 184,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 90,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 114,
    o: 305,
    k: 210,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "17,12",
    weigth: "20,6",
  },
  {
    piping_returns_id: 91,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 141,
    o: 381,
    k: 262,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "19,05",
    weigth: "36,23",
  },
  {
    piping_returns_id: 92,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 168,
    o: 457,
    k: 313,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "21,95",
    weigth: "59,5",
  },
  {
    piping_returns_id: 93,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 219,
    o: 610,
    k: 414,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "22,23",
    weigth: 109,
  },
  {
    piping_returns_id: 94,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 273,
    o: 762,
    k: 518,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "25,4",
    weigth: 194,
  },
  {
    piping_returns_id: 95,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 324,
    o: 914,
    k: 619,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "25,4",
    weigth: 280,
  },
  {
    piping_returns_id: 96,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 356,
    o: 1067,
    k: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 97,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 406,
    o: 1219,
    k: 813,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 98,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 457,
    o: 1372,
    k: 914,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 99,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 508,
    o: 1524,
    k: 1016,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 100,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 559,
    o: 1676,
    k: 1118,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 101,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 610,
    o: 1829,
    k: 1219,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 102,
    nps: 1,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 33,
    o: 51,
    k: 41,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "9,09",
    weigth: "0,46",
  },
  {
    piping_returns_id: 103,
    nps: "1 1/4",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 42,
    o: 64,
    k: 52,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "9,7",
    weigth: "0,82",
  },
  {
    piping_returns_id: 104,
    nps: "1 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 48,
    o: 76,
    k: 62,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "10,16",
    weigth: "1,2",
  },
  {
    piping_returns_id: 105,
    nps: 2,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 60,
    o: 102,
    k: 81,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "11,07",
    weigth: "2,26",
  },
  {
    piping_returns_id: 106,
    nps: "2 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 73,
    o: 127,
    k: 100,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "14,02",
    weigth: "4,06",
  },
  {
    piping_returns_id: 107,
    nps: 3,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 89,
    o: 152,
    k: 121,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "15,24",
    weigth: "6,94",
  },
  {
    piping_returns_id: 108,
    nps: "3 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 102,
    o: 178,
    k: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 109,
    nps: 4,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 114,
    o: 203,
    k: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "17,12",
    weigth: "13,72",
  },
  {
    piping_returns_id: 110,
    nps: 5,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 141,
    o: 254,
    k: 197,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "19,05",
    weigth: 24,
  },
  {
    piping_returns_id: 111,
    nps: 6,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 168,
    o: 305,
    k: 237,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "21,95",
    weigth: 40,
  },
  {
    piping_returns_id: 112,
    nps: 8,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 219,
    o: 406,
    k: 313,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "22,23",
    weigth: 72,
  },
  {
    piping_returns_id: 113,
    nps: 10,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 273,
    o: 508,
    k: 391,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "25,4",
    weigth: 130,
  },
  {
    piping_returns_id: 114,
    nps: 12,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 324,
    o: 610,
    k: 467,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "25,4",
    weigth: 188,
  },
  {
    piping_returns_id: 115,
    nps: 14,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 356,
    o: 711,
    k: 533,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 116,
    nps: 16,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 406,
    o: 813,
    k: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 117,
    nps: 18,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 457,
    o: 914,
    k: 686,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 118,
    nps: 20,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 508,
    o: 1016,
    k: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 119,
    nps: 22,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 559,
    o: 1118,
    k: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 120,
    nps: 24,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 610,
    o: 1219,
    k: 914,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 123,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 33,
    o: 76,
    k: 56,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 124,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 42,
    o: 95,
    k: 70,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 125,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 48,
    o: 114,
    k: 83,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 126,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 60,
    o: 152,
    k: 106,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 127,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 73,
    o: 191,
    k: 132,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 128,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 89,
    o: 229,
    k: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 129,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 102,
    o: 267,
    k: 184,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 130,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 114,
    o: 305,
    k: 210,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 131,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 141,
    o: 381,
    k: 262,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 132,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 168,
    o: 457,
    k: 313,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 133,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 219,
    o: 610,
    k: 414,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 134,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 273,
    o: 762,
    k: 518,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 135,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 324,
    o: 914,
    k: 619,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 136,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 356,
    o: 1067,
    k: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weigth: "114,94",
  },
  {
    piping_returns_id: 137,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 406,
    o: 1219,
    k: 813,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weigth: "165,24",
  },
  {
    piping_returns_id: 138,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 457,
    o: 1372,
    k: 914,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weigth: 164,
  },
  {
    piping_returns_id: 139,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 508,
    o: 1524,
    k: 1016,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weigth: 200,
  },
  {
    piping_returns_id: 140,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 559,
    o: 1676,
    k: 1118,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weigth: 240,
  },
  {
    piping_returns_id: 141,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 610,
    o: 1829,
    k: 1219,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weigth: 292,
  },
  {
    piping_returns_id: 142,
    nps: 1,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 33,
    o: 51,
    k: 41,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 143,
    nps: "1 1/4",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 42,
    o: 64,
    k: 52,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 144,
    nps: "1 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 48,
    o: 76,
    k: 62,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 145,
    nps: 2,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 60,
    o: 102,
    k: 81,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 146,
    nps: "2 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 73,
    o: 127,
    k: 100,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 147,
    nps: 3,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 89,
    o: 152,
    k: 121,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 148,
    nps: "3 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 102,
    o: 178,
    k: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 149,
    nps: 4,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 114,
    o: 203,
    k: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 150,
    nps: 5,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 141,
    o: 254,
    k: 197,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 151,
    nps: 6,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 168,
    o: 305,
    k: 237,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 152,
    nps: 8,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 219,
    o: 406,
    k: 313,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 153,
    nps: 10,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 273,
    o: 508,
    k: 391,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 154,
    nps: 12,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 324,
    o: 610,
    k: 467,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 155,
    nps: 14,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 356,
    o: 711,
    k: 533,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weigth: 60,
  },
  {
    piping_returns_id: 156,
    nps: 16,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 406,
    o: 813,
    k: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weigth: "79,35",
  },
  {
    piping_returns_id: 157,
    nps: 18,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 457,
    o: 914,
    k: 686,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weigth: "97,5",
  },
  {
    piping_returns_id: 158,
    nps: 20,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 508,
    o: 1016,
    k: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weigth: 130,
  },
  {
    piping_returns_id: 159,
    nps: 22,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 559,
    o: 1118,
    k: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weigth: 160,
  },
  {
    piping_returns_id: 160,
    nps: 24,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 610,
    o: 1219,
    k: 914,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weigth: 192,
  },
  {
    piping_returns_id: 163,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 33,
    o: 76,
    k: 56,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 164,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 42,
    o: 95,
    k: 70,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 165,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 48,
    o: 114,
    k: 83,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 166,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 60,
    o: 152,
    k: 106,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 167,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 73,
    o: 191,
    k: 132,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 168,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 89,
    o: 229,
    k: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 169,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 102,
    o: 267,
    k: 184,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 170,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 114,
    o: 305,
    k: 210,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 171,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 141,
    o: 381,
    k: 262,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 172,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 168,
    o: 457,
    k: 313,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 173,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 219,
    o: 610,
    k: 414,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "6,35",
    weigth: "33,14",
  },
  {
    piping_returns_id: 174,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 273,
    o: 762,
    k: 518,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "6,35",
    weigth: "51,58",
  },
  {
    piping_returns_id: 175,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 324,
    o: 914,
    k: 619,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "6,35",
    weigth: "74,46",
  },
  {
    piping_returns_id: 176,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 356,
    o: 1067,
    k: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "7,92",
    weigth: "119,86",
  },
  {
    piping_returns_id: 177,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 406,
    o: 1219,
    k: 813,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "7,92",
    weigth: "156,18",
  },
  {
    piping_returns_id: 178,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 457,
    o: 1372,
    k: 914,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "7,92",
    weigth: "198,86",
  },
  {
    piping_returns_id: 179,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 508,
    o: 1524,
    k: 1016,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "9,52",
    weigth: "284,8",
  },
  {
    piping_returns_id: 180,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 559,
    o: 1676,
    k: 1118,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "9,52",
    weigth: 346,
  },
  {
    piping_returns_id: 181,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "LR RETURNS",
    degree: 180,
    d: 610,
    o: 1829,
    k: 1219,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "9,52",
    weigth: "411,8",
  },
  {
    piping_returns_id: 182,
    nps: 1,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 33,
    o: 51,
    k: 41,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 183,
    nps: "1 1/4",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 42,
    o: 64,
    k: 52,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 184,
    nps: "1 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 48,
    o: 76,
    k: 62,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 185,
    nps: 2,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 60,
    o: 102,
    k: 81,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 186,
    nps: "2 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 73,
    o: 127,
    k: 100,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 187,
    nps: 3,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 89,
    o: 152,
    k: 121,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 188,
    nps: "3 1/2",
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 102,
    o: 178,
    k: 140,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 189,
    nps: 4,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 114,
    o: 203,
    k: 159,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 190,
    nps: 5,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 141,
    o: 254,
    k: 197,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 191,
    nps: 6,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 168,
    o: 305,
    k: 237,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weigth: "",
  },
  {
    piping_returns_id: 192,
    nps: 8,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 219,
    o: 406,
    k: 313,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "6,35",
    weigth: "22,25",
  },
  {
    piping_returns_id: 193,
    nps: 10,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 273,
    o: 508,
    k: 391,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "6,35",
    weigth: "34,7",
  },
  {
    piping_returns_id: 194,
    nps: 12,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 324,
    o: 610,
    k: 467,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "6,35",
    weigth: 50,
  },
  {
    piping_returns_id: 195,
    nps: 14,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 356,
    o: 711,
    k: 533,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "7,92",
    weigth: 79,
  },
  {
    piping_returns_id: 196,
    nps: 16,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 406,
    o: 813,
    k: 610,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "7,92",
    weigth: "104,45",
  },
  {
    piping_returns_id: 197,
    nps: 18,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 457,
    o: 914,
    k: 686,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "7,92",
    weigth: "130,75",
  },
  {
    piping_returns_id: 198,
    nps: 20,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 508,
    o: 1016,
    k: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "9,52",
    weigth: 219,
  },
  {
    piping_returns_id: 199,
    nps: 22,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 559,
    o: 1118,
    k: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "9,52",
    weigth: 264,
  },
  {
    piping_returns_id: 200,
    nps: 24,
    material: "ASME B 16.28-1994",
    shape: "SR RETURNS",
    degree: 180,
    d: 610,
    o: 1219,
    k: 914,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "9,52",
    weigth: 330,
  },
].map((item) => ({
  ...item,
  id: item.piping_returns_id,
  nps: item.nps + "",
  t: fixValueToNumber(item.t, "float"),
  weigth: fixValueToNumber(item.weigth, "float"),
}));
