import { secondServerAPI } from "../../../../pages/utils/agent";
import axios from "axios";
import { IfcModelDataValidationModelIfc, ModelsDataIfc, Project, ProjectModelIfc } from "../../../../store/main/types";
import { CustomDlg } from "../../../common/CustomDlg";
import React, { useState, useEffect } from "react";
import { Button } from "@blueprintjs/core";
import { GeneralCheckBoxCell } from "../../../common/GeneralCheckBoxCell";

type Props = {
    project : Project
    onClose: () => any;
};

export function IfcDlg({ project, onClose }: Props) {
    const [checkedModels, setCheckedModels] = useState({
        Structural: false,
        Piping: false,
        IDSV: false,
    });
    const [idsvItems, setIdsvItems] = useState<any[]>([]);

    useEffect(() => {
        console.log("this is the current project", project)
        if (checkedModels.IDSV) {
            // setIdsvItems([
            //     { id: 1, name: "IDSV Item 1" },
            //     { id: 2, name: "IDSV Item 2" },
            //     { id: 3, name: "IDSV Item 3" },
            // ]);
        } else {
            setIdsvItems([]);
        }
    }, [checkedModels.IDSV]);

    const handleCheckboxChange = (model: string) => {
        setCheckedModels((prev) => ({
            ...prev,
            [model]: !prev[model],
        }));
    };

    const handleIFCCall = () => {
        exporttoIFC(project);
    }

    return (
        <CustomDlg
            zIndex={10}
            title="IFC Exporter"
            idText="simple-dialog"
            position="center"
            onClose={onClose}
            body={
                <div className={"d-flex f-column f-grow bg-dark w-700"}>
                    <div className="d-flex label-light bg-dark f-ai-center w-700">
                        <Button
                            small
                            text={"export/IFC"}
                            intent="success"
                            onClick={handleIFCCall}
                        />
                    </div>
                    <div className="p-5 bg-light">
                        <div className="small-table-container">
                            <table className="table bg-gray w-150">
                                <thead>
                                    <tr>
                                        <th>Checkbox</th>
                                        <th>Model</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={checkedModels.Structural}
                                                onChange={() => handleCheckboxChange("Structural")}
                                            />
                                        </td>
                                        <td>Structural</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={checkedModels.Piping}
                                                onChange={() => handleCheckboxChange("Piping")}
                                            />
                                        </td>
                                        <td>Piping</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={checkedModels.IDSV}
                                                onChange={() => handleCheckboxChange("IDSV")}
                                            />
                                        </td>
                                        <td>IDSV</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {checkedModels.IDSV && (
                            <div className="mt-4">
                                <h5>IDSV Items</h5>
                                <table className="table bg-gray">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {idsvItems.map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>{item.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            }
        />

    );
}


export function exporttoIFC(project: Project) {
    const analysis_data = create_analysis_data(project);
    axios.post(`${secondServerAPI}/ifc/ifc/structure`, analysis_data, {
      responseType: 'blob'
    })
    .then((response: any) => {
      const fileURL = URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${project.name}_analysis_data.ifc`);
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.parentNode?.removeChild(fileLink);
      URL.revokeObjectURL(fileURL);
    })
    .catch((error) => {
      console.error("Error exporting to IFC:", error);
    });
  }


function create_analysis_data(project: Project) {
    const replaceNullWithZero = (value: any) => (value === null || value === 'null' || value === true || value === false) ? 0 : value;
  
    const data_1: ModelsDataIfc[] = project.models.map(model => ({
      name: replaceNullWithZero(model.name),
      project: replaceNullWithZero(model.project),
      type: replaceNullWithZero(model.type),
      baseElevation: replaceNullWithZero(model.baseElevation),
      startPos: {
        x: replaceNullWithZero(model.startPos?.x),
        y: replaceNullWithZero(model.startPos?.y),
        z: replaceNullWithZero(model.startPos?.z)
      },
      direction: replaceNullWithZero(model.direction),
      CSLibrary: replaceNullWithZero(model.CSLibrary),
      frameColProfile: {
        ...model.frameColProfile,
        b: replaceNullWithZero(model.frameColProfile.b),
        c: replaceNullWithZero(model.frameColProfile.c),
        de: replaceNullWithZero(model.frameColProfile.de),
        i: replaceNullWithZero(model.frameColProfile.i),
        k: replaceNullWithZero(model.frameColProfile.k),
        k1: replaceNullWithZero(model.frameColProfile.k1),
        r1: replaceNullWithZero(model.frameColProfile.r1),
        r2: replaceNullWithZero(model.frameColProfile.r2),
        z: replaceNullWithZero(model.frameColProfile.z),
        zz: replaceNullWithZero(model.frameColProfile.zz),
        created_by: replaceNullWithZero(model.frameColProfile.created_by),
        updated_by: replaceNullWithZero(model.frameColProfile.updated_by),
        width_global: replaceNullWithZero(model.frameColProfile.width_global),
        thickness_global: replaceNullWithZero(model.frameColProfile.thickness_global),
        height_global: replaceNullWithZero(model.frameColProfile.height_global),
        ax_global: replaceNullWithZero(model.frameColProfile.ax_global),
        b_global: replaceNullWithZero(model.frameColProfile.b_global),
        bf_global: replaceNullWithZero(model.frameColProfile.bf_global),
        c_global: replaceNullWithZero(model.frameColProfile.c_global),
        ct_global: replaceNullWithZero(model.frameColProfile.ct_global),
        d_global: replaceNullWithZero(model.frameColProfile.d_global),
        de_global: replaceNullWithZero(model.frameColProfile.de_global),
        i_global: replaceNullWithZero(model.frameColProfile.i_global),
        ix_global: replaceNullWithZero(model.frameColProfile.ix_global),
        iy_global: replaceNullWithZero(model.frameColProfile.iy_global),
        iz_global: replaceNullWithZero(model.frameColProfile.iz_global),
        k_global: replaceNullWithZero(model.frameColProfile.k_global),
        k1_global: replaceNullWithZero(model.frameColProfile.k1_global),
        od_global: replaceNullWithZero(model.frameColProfile.od_global),
        r1_global: replaceNullWithZero(model.frameColProfile.r1_global),
        r2_global: replaceNullWithZero(model.frameColProfile.r2_global),
        rz_global: replaceNullWithZero(model.frameColProfile.rz_global),
        t_global: replaceNullWithZero(model.frameColProfile.t_global),
        tf_global: replaceNullWithZero(model.frameColProfile.tf_global),
        tw_global: replaceNullWithZero(model.frameColProfile.tw_global),
        z_global: replaceNullWithZero(model.frameColProfile.z_global),
        zx_global: replaceNullWithZero(model.frameColProfile.zx_global),
        zy_global: replaceNullWithZero(model.frameColProfile.zy_global),
        zz_global: replaceNullWithZero(model.frameColProfile.zz_global)
      },
      frameBeamProfile: {
        ...model.frameBeamProfile,
        b: replaceNullWithZero(model.frameBeamProfile.b),
        c: replaceNullWithZero(model.frameBeamProfile.c),
        de: replaceNullWithZero(model.frameBeamProfile.de),
        i: replaceNullWithZero(model.frameBeamProfile.i),
        k: replaceNullWithZero(model.frameBeamProfile.k),
        k1: replaceNullWithZero(model.frameBeamProfile.k1),
        r1: replaceNullWithZero(model.frameBeamProfile.r1),
        r2: replaceNullWithZero(model.frameBeamProfile.r2),
        z: replaceNullWithZero(model.frameBeamProfile.z),
        zz: replaceNullWithZero(model.frameBeamProfile.zz),
        created_by: replaceNullWithZero(model.frameBeamProfile.created_by),
        updated_by: replaceNullWithZero(model.frameBeamProfile.updated_by),
        width_global: replaceNullWithZero(model.frameBeamProfile.width_global),
        thickness_global: replaceNullWithZero(model.frameBeamProfile.thickness_global),
        height_global: replaceNullWithZero(model.frameBeamProfile.height_global),
        ax_global: replaceNullWithZero(model.frameBeamProfile.ax_global),
        b_global: replaceNullWithZero(model.frameBeamProfile.b_global),
        bf_global: replaceNullWithZero(model.frameBeamProfile.bf_global),
        c_global: replaceNullWithZero(model.frameBeamProfile.c_global),
        ct_global: replaceNullWithZero(model.frameBeamProfile.ct_global),
        d_global: replaceNullWithZero(model.frameBeamProfile.d_global),
        de_global: replaceNullWithZero(model.frameBeamProfile.de_global),
        i_global: replaceNullWithZero(model.frameBeamProfile.i_global),
        ix_global: replaceNullWithZero(model.frameBeamProfile.ix_global),
        iy_global: replaceNullWithZero(model.frameBeamProfile.iy_global),
        iz_global: replaceNullWithZero(model.frameBeamProfile.iz_global),
        k_global: replaceNullWithZero(model.frameBeamProfile.k_global),
        k1_global: replaceNullWithZero(model.frameBeamProfile.k1_global),
        od_global: replaceNullWithZero(model.frameBeamProfile.od_global),
        r1_global: replaceNullWithZero(model.frameBeamProfile.r1_global),
        r2_global: replaceNullWithZero(model.frameBeamProfile.r2_global),
        rz_global: replaceNullWithZero(model.frameBeamProfile.rz_global),
        t_global: replaceNullWithZero(model.frameBeamProfile.t_global),
        z_global: replaceNullWithZero(model.frameBeamProfile.z_global),
        tf_global: replaceNullWithZero(model.frameBeamProfile.tf_global),
        tw_global: replaceNullWithZero(model.frameBeamProfile.tw_global),
        zx_global: replaceNullWithZero(model.frameBeamProfile.zx_global),
        zy_global: replaceNullWithZero(model.frameBeamProfile.zy_global),
        zz_global: replaceNullWithZero(model.frameBeamProfile.zz_global)
      },
      columns: model.columns.map(column => ({
        ...column,
        orientation: replaceNullWithZero(column.orientation),
        startConnected: column.startConnected || [],
        connected: column.connected || [],
        endConnected: column.endConnected || [],
        profile: {
          profile_section_id: replaceNullWithZero(column.profile?.profile_section_id),
          name: replaceNullWithZero(column.profile?.name),
          type: replaceNullWithZero(column.profile?.type),
          designation: replaceNullWithZero(column.profile?.designation),
          ax: replaceNullWithZero(column.profile?.ax),
          ax_global: replaceNullWithZero(column.profile?.ax_global),
          b: replaceNullWithZero(column.profile?.b),
          b_global: replaceNullWithZero(column.profile?.b_global),
          bf: replaceNullWithZero(column.profile?.bf),
          bf_global: replaceNullWithZero(column.profile?.bf_global),
          c: replaceNullWithZero(column.profile?.c),
          c_global: replaceNullWithZero(column.profile?.c_global),
          country_code: replaceNullWithZero(column.profile?.country_code),
          created_by: replaceNullWithZero(column.profile?.created_by),
          created_on: replaceNullWithZero(column.profile?.created_on),
          ct: replaceNullWithZero(column.profile?.ct),
          ct_global: replaceNullWithZero(column.profile?.ct_global),
          d: replaceNullWithZero(column.profile?.d),
          d_global: replaceNullWithZero(column.profile?.d_global),
          de: replaceNullWithZero(column.profile?.de),
          de_global: replaceNullWithZero(column.profile?.de_global),
          height: replaceNullWithZero(column.profile?.height),
          height_global: replaceNullWithZero(column.profile?.height_global),
          i: replaceNullWithZero(column.profile?.i),
          i_global: replaceNullWithZero(column.profile?.i_global),
          ix: replaceNullWithZero(column.profile?.ix),
          ix_global: replaceNullWithZero(column.profile?.ix_global),
          iy: replaceNullWithZero(column.profile?.iy),
          iy_global: replaceNullWithZero(column.profile?.iy_global),
          iz: replaceNullWithZero(column.profile?.iz),
          iz_global: replaceNullWithZero(column.profile?.iz_global),
          k: replaceNullWithZero(column.profile?.k),
          k1: replaceNullWithZero(column.profile?.k1),
          k1_global: replaceNullWithZero(column.profile?.k1_global),
          k_global: replaceNullWithZero(column.profile?.k_global),
          od: replaceNullWithZero(column.profile?.od),
          od_global: replaceNullWithZero(column.profile?.od_global),
          r1: replaceNullWithZero(column.profile?.r1),
          r1_global: replaceNullWithZero(column.profile?.r1_global),
          r2: replaceNullWithZero(column.profile?.r2),
          r2_global: replaceNullWithZero(column.profile?.r2_global),
          rz: replaceNullWithZero(column.profile?.rz),
          rz_global: replaceNullWithZero(column.profile?.rz_global),
          shape: replaceNullWithZero(column.profile?.shape),
          t: replaceNullWithZero(column.profile?.t),
          t_global: replaceNullWithZero(column.profile?.t_global),
          tf: replaceNullWithZero(column.profile?.tf),
          tf_global: replaceNullWithZero(column.profile?.tf_global),
          thickness: replaceNullWithZero(column.profile?.thickness),
          thickness_global: replaceNullWithZero(column.profile?.thickness_global),
          tw: replaceNullWithZero(column.profile?.tw),
          tw_global: replaceNullWithZero(column.profile?.tw_global),
          updated_by: replaceNullWithZero(column.profile?.updated_by),
          updated_on: replaceNullWithZero(column.profile?.updated_on),
          width: replaceNullWithZero(column.profile?.width),
          width_global: replaceNullWithZero(column.profile?.width_global),
          z: replaceNullWithZero(column.profile?.z),
          z_global: replaceNullWithZero(column.profile?.z_global),
          zx: replaceNullWithZero(column.profile?.zx),
          zx_global: replaceNullWithZero(column.profile?.zx_global),
          zy: replaceNullWithZero(column.profile?.zy),
          zy_global: replaceNullWithZero(column.profile?.zy_global),
          zz: replaceNullWithZero(column.profile?.zz),
          zz_global: replaceNullWithZero(column.profile?.zz_global)
        }
      })),    
      beams: model.beams.map(beam => ({
        ...beam,
        orientation: replaceNullWithZero(beam.orientation),
        startConnected: beam.startConnected || [],
        connected: beam.connected || [],
        endConnected: beam.endConnected || [],
        profile: {
          profile_section_id: replaceNullWithZero(beam.profile?.profile_section_id),
          name: replaceNullWithZero(beam.profile?.name),
          type: replaceNullWithZero(beam.profile?.type),
          designation: replaceNullWithZero(beam.profile?.designation),
          ax: replaceNullWithZero(beam.profile?.ax),
          ax_global: replaceNullWithZero(beam.profile?.ax_global),
          b: replaceNullWithZero(beam.profile?.b),
          b_global: replaceNullWithZero(beam.profile?.b_global),
          bf: replaceNullWithZero(beam.profile?.bf),
          bf_global: replaceNullWithZero(beam.profile?.bf_global),
          c: replaceNullWithZero(beam.profile?.c),
          c_global: replaceNullWithZero(beam.profile?.c_global),
          country_code: replaceNullWithZero(beam.profile?.country_code),
          created_by: replaceNullWithZero(beam.profile?.created_by),
          created_on: replaceNullWithZero(beam.profile?.created_on),
          ct: replaceNullWithZero(beam.profile?.ct),
          ct_global: replaceNullWithZero(beam.profile?.ct_global),
          d: replaceNullWithZero(beam.profile?.d),
          d_global: replaceNullWithZero(beam.profile?.d_global),
          de: replaceNullWithZero(beam.profile?.de),
          de_global: replaceNullWithZero(beam.profile?.de_global),
          height: replaceNullWithZero(beam.profile?.height),
          height_global: replaceNullWithZero(beam.profile?.height_global),
          i: replaceNullWithZero(beam.profile?.i),
          i_global: replaceNullWithZero(beam.profile?.i_global),
          ix: replaceNullWithZero(beam.profile?.ix),
          ix_global: replaceNullWithZero(beam.profile?.ix_global),
          iy: replaceNullWithZero(beam.profile?.iy),
          iy_global: replaceNullWithZero(beam.profile?.iy_global),
          iz: replaceNullWithZero(beam.profile?.iz),
          iz_global: replaceNullWithZero(beam.profile?.iz_global),
          k: replaceNullWithZero(beam.profile?.k),
          k1: replaceNullWithZero(beam.profile?.k1),
          k1_global: replaceNullWithZero(beam.profile?.k1_global),
          k_global: replaceNullWithZero(beam.profile?.k_global),
          od: replaceNullWithZero(beam.profile?.od),
          od_global: replaceNullWithZero(beam.profile?.od_global),
          r1: replaceNullWithZero(beam.profile?.r1),
          r1_global: replaceNullWithZero(beam.profile?.r1_global),
          r2: replaceNullWithZero(beam.profile?.r2),
          r2_global: replaceNullWithZero(beam.profile?.r2_global),
          rz: replaceNullWithZero(beam.profile?.rz),
          rz_global: replaceNullWithZero(beam.profile?.rz_global),
          shape: replaceNullWithZero(beam.profile?.shape),
          t: replaceNullWithZero(beam.profile?.t),
          t_global: replaceNullWithZero(beam.profile?.t_global),
          tf: replaceNullWithZero(beam.profile?.tf),
          tf_global: replaceNullWithZero(beam.profile?.tf_global),
          thickness: replaceNullWithZero(beam.profile?.thickness),
          thickness_global: replaceNullWithZero(beam.profile?.thickness_global),
          tw: replaceNullWithZero(beam.profile?.tw),
          tw_global: replaceNullWithZero(beam.profile?.tw_global),
          updated_by: replaceNullWithZero(beam.profile?.updated_by),
          updated_on: replaceNullWithZero(beam.profile?.updated_on),
          width: replaceNullWithZero(beam.profile?.width),
          width_global: replaceNullWithZero(beam.profile?.width_global),
          z: replaceNullWithZero(beam.profile?.z),
          z_global: replaceNullWithZero(beam.profile?.z_global),
          zx: replaceNullWithZero(beam.profile?.zx),
          zx_global: replaceNullWithZero(beam.profile?.zx_global),
          zy: replaceNullWithZero(beam.profile?.zy),
          zy_global: replaceNullWithZero(beam.profile?.zy_global),
          zz: replaceNullWithZero(beam.profile?.zz),
          zz_global: replaceNullWithZero(beam.profile?.zz_global)
        }
      }))    
    }));
  
    const data_2: ProjectModelIfc = {
      name: replaceNullWithZero(project.name),
      models: data_1
    };
  
    const data_3: IfcModelDataValidationModelIfc = {
      name: replaceNullWithZero(project.name),
      project: data_2
    };
  
    return data_3;
  }
