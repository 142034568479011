import React, { useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Camera, Object3D, Scene } from 'three';
import { ButtonGroup, Button, Tooltip } from '@blueprintjs/core';
// import ConcreteFloorTexturePath from "../../assets/Textures/Wall_Textures/2K/Poliigon_ConcreteWallCladding_7856_BaseColor.jpg"
// import ConcreteFloorNormalMapPath from "../../assets/Textures/Wall_Textures/2K/Poliigon_ConcreteWallCladding_7856_Normal.png"
// import ConcreteFloorRoughnessMapPath from "../../assets/Textures/Wall_Textures/2K/Poliigon_ConcreteWallCladding_7856_Roughness.jpg";
// import ConcreteFloorMetalnessMapPath from "../../assets/Textures/Wall_Textures/2K/Poliigon_ConcreteWallCladding_7856_Metallic.jpg"
// import RoughWallTexturePath from "../../assets/Textures/Wall_Textures/StuccoRoughCast001/StuccoRoughCast001_COL_1K_METALNESS.png";
// import RoughWallNormalMapPath from "../../assets/Textures/Wall_Textures/StuccoRoughCast001/StuccoRoughCast001_NRM_1K_METALNESS.png";
// import RoughWallBumpMapPath from "../../assets/Textures/Wall_Textures/StuccoRoughCast001/StuccoRoughCast001_BUMP_1K_METALNESS.png";
// import RoughWallDisplacementMapPath from "../../assets/Textures/Wall_Textures/StuccoRoughCast001/StuccoRoughCast001_DISP_1K_METALNESS.png";
// import RoughWallRoughnessMapPath from "../../assets/Textures/Wall_Textures/StuccoRoughCast001/StuccoRoughCast001_ROUGHNESS_1K_METALNESS.png";
// import RoughWallMetalnessMapPath from "../../assets/Textures/Wall_Textures/StuccoRoughCast001/StuccoRoughCast001_ROUGHNESS_1K_METALNESS.png";
// import BrickWallTexturePath from "../../assets/Textures/Wall_Textures/1K/Poliigon_BrickReclaimedRunning_7787_BaseColor.jpg";
// import BrickWallNormalMapPath from "../../assets/Textures/Wall_Textures/1K/Poliigon_BrickReclaimedRunning_7787_Normal.png";
// import BrickWallDisplacementMapPath from "../../assets/Textures/Wall_Textures/1K/Poliigon_BrickReclaimedRunning_7787_AmbientOcclusion.jpg";
// import BrickWallRoughnessMapPath from "../../assets/Textures/Wall_Textures/1K/Poliigon_BrickReclaimedRunning_7787_Roughness.jpg";
// import BrickWallMetalnessMapPath from "../../assets/Textures/Wall_Textures/1K/Poliigon_BrickReclaimedRunning_7787_Metallic.jpg";
// import asphaltTexturePath from "../../assets/Textures/Floor_Textures/CityStreetAsphaltGenericClean001/CityStreetAsphaltGenericClean001_COL_1K.jpg";
// import asphaltNormalMapPath from "../../assets/Textures/Floor_Textures/CityStreetAsphaltGenericClean001/CityStreetAsphaltGenericClean001_NRM_1K.jpg";
// import asphaltBumpMapPath from "../../assets/Textures/Floor_Textures/CityStreetAsphaltGenericClean001/CityStreetAsphaltGenericClean001_AO_1K.jpg";
// import asphaltDisplacementMapPath from "../../assets/Textures/Floor_Textures/CityStreetAsphaltGenericClean001/CityStreetAsphaltGenericClean001_DISP_1K.jpg";
// import asphaltRoughnessMapPath from "../../assets/Textures/Floor_Textures/CityStreetAsphaltGenericClean001/CityStreetAsphaltGenericClean001_REFL_1K.jpg";
// import asphaltMetalnessMapPath from "../../assets/Textures/Floor_Textures/CityStreetAsphaltGenericClean001/CityStreetAsphaltGenericClean001_REFL_1K.jpg";
// import ConcreteTexturePath from "../../assets/Textures/Floor_Textures/Concrete_floor/Poliigon_ConcreteFloorPoured_7656_BaseColor.jpg";
// import ConcreteNormalMapPath from "../../assets/Textures/Floor_Textures/Concrete_floor/Poliigon_ConcreteFloorPoured_7656_Normal.png";
// import ConcreteRoughnessMapPath from "../../assets/Textures/Floor_Textures/Concrete_floor/Poliigon_ConcreteFloorPoured_7656_Roughness.jpg";
// import ConcreteMetalnessMapPath from "../../assets/Textures/Floor_Textures/Concrete_floor/Poliigon_ConcreteFloorPoured_7656_Metallic.jpg";
// import GrassTexturePath from "../../assets/Textures/Floor_Textures/Grass_Floor/Poliigon_GrassPatchyGround_4585_BaseColor.jpg";
// import GrassNormalMapPath from "../../assets/Textures/Floor_Textures/Grass_Floor/Poliigon_GrassPatchyGround_4585_Normal.png";
// import GrassRoughnessMapPath from "../../assets/Textures/Floor_Textures/Grass_Floor/Poliigon_GrassPatchyGround_4585_Roughness.jpg";
// import GrassMetalnessMapPath from "../../assets/Textures/Floor_Textures/Grass_Floor/Poliigon_GrassPatchyGround_4585_Metallic.jpg";
import './TransformerControlsComponent.css';
interface TransformControlsComponentProps {
  camera: Camera;
  scene: Scene;
  canvas: HTMLCanvasElement;
  controls: OrbitControls | null;
  item: Object3D;
  onClose: () => void;
  onDelete: () => void;
  onClone: (clonedItem: Object3D) => void;
}

interface UserDataPanelProps {
  userData: Record<string, any>;
  item?: THREE.Object3D & { material?: THREE.Material }; 
  isClippedAsset: boolean;
  pivotRef: React.RefObject<THREE.Object3D | null>;
  onUpdate: (
    newUserData: Record<string, any>,
    updatedTransforms: {
      position?: THREE.Vector3;
      rotation?: THREE.Euler;
      scale?: THREE.Vector3;
      opacity?: number; 
    }
  ) => void;
}

const UserDataPanel: React.FC<UserDataPanelProps> = ({ userData, item,isClippedAsset,pivotRef, onUpdate }) => {
  const [editableData, setEditableData] = useState<Record<string, any>>(userData || {});
  const [position, setPosition] = useState(item?.position?.clone() || new THREE.Vector3());
  const [rotation, setRotation] = useState(item?.rotation?.clone() || new THREE.Euler());
  const [scale, setScale] = useState(item?.scale?.clone() || new THREE.Vector3(1, 1, 1));
  const [opacity, setOpacity] = useState(item?.material?.opacity || 1);
  const [type, setType] = useState(editableData.Type || "none");

  useEffect(() => {
    if (Object.keys(editableData).length === 0) {
      console.log("This is the User Data", userData)
      setEditableData(userData);
    }
  }, [userData]);

  useEffect(() => {
    if (item) {
      setPosition(item.position.clone());
      setRotation(item.rotation.clone());
      setScale(item.scale.clone());
      setOpacity(item.material?.opacity || 1);
    }
  }, [item]);

  const handleUserDataChange = (key: string, value: any) => {
    setEditableData((prevData) => {
      const newUserData = { ...prevData, [key]: value };
      if (key === "Type") {
        setType(value);
      }
      onUpdate(newUserData, {});
      return newUserData;
    });
  };
  
  const handleUserDataBlur = (key: string) => {
    if (item) {
      item.userData[key] = editableData[key];
      onUpdate({ ...editableData }, {});
    }
  };

  const handlePositionChange = (axis: "x" | "y" | "z", value: number) => {
    if (!item) return;
    const newPosition = position.clone();
    newPosition[axis] = value;
    setPosition(newPosition);
    item.position.set(newPosition.x, newPosition.y, newPosition.z);
    item.updateMatrix();
    onUpdate(editableData, { position: newPosition });
  };

  const handleRotationChange = (axis: "x" | "y" | "z", value: number) => {
    const target = isClippedAsset ? pivotRef.current : item;
    if (!target) return;
    
    const newRotation = rotation.clone();
    newRotation[axis] = THREE.MathUtils.degToRad(value);
  
    setRotation(newRotation);
    target.rotation.set(newRotation.x, newRotation.y, newRotation.z);
    target.updateMatrix();
  
    onUpdate(editableData, { rotation: newRotation });
  };
  
  const handleScaleChange = (axis: "x" | "y" | "z", value: number) => {
    if (!item) return;
    const newScale = scale.clone();
    newScale[axis] = value;
    setScale(newScale);
    item.scale.set(newScale.x, newScale.y, newScale.z);
    item.updateMatrix();
    onUpdate(editableData, { scale: newScale });
  };

  const handleOpacityChange = (value: number) => {
    if (!item || !item.material) return;
    setOpacity(value);
    item.material.opacity = value;
    item.material.transparent = true; 
    onUpdate(editableData, { opacity: value });
  };

  const handleDownloadCSV = () => {
    const transformParams = {
      position: `${position.x},${position.y},${position.z}`,
      rotation: `${THREE.MathUtils.radToDeg(rotation.x)},${THREE.MathUtils.radToDeg(rotation.y)},${THREE.MathUtils.radToDeg(rotation.z)}`,
      scale: `${scale.x},${scale.y},${scale.z}`,
      opacity: opacity,
    };

    const csvContent = [
      ...Object.entries(editableData).filter(([key]) => key !== 'isGLTFObject').map(([key, value]) => `${key},${value}`),
      ...Object.entries(transformParams).map(([key, value]) => `${key},${value}`)
    ].join("\n");

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Asset_${Object.name}_Data.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleUploadCSV = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
  
    input.onchange = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
  
      reader.onload = (e) => {
        const content = e.target.result as string;
        const rows = content.split("\n");
        const newEditableData: Record<string, any> = {};
        const transformData = {
          position: null as THREE.Vector3 | null,
          rotation: null as THREE.Euler | null,
          scale: null as THREE.Vector3 | null,
          opacity: null as number | null,
        };
  
        let objectName: string | null = null;
  
        rows.forEach((row) => {
          const values = row.split("\t"); 
  
          if (values.length >= 2) {
            const key = values[0].trim();
            const value = values.slice(1).map((v) => v.trim()).filter((v) => v !== '');
            if (key === "name") {
              objectName = value[0].trim(); 
              console.log("This is the name of the object: ", objectName);
            } else if (key === "position" || key === "rotation" || key === "scale") {
              if (value.length === 3) {
                const [x, y, z] = value.map(parseFloat);
                if (key === "position") {
                  transformData.position = new THREE.Vector3(x, y, z);
                } else if (key === "rotation") {
                  transformData.rotation = new THREE.Euler(
                    THREE.MathUtils.degToRad(x),
                    THREE.MathUtils.degToRad(y),
                    THREE.MathUtils.degToRad(z)
                  );
                } else if (key === "scale") {
                  transformData.scale = new THREE.Vector3(x, y, z);
                }
              }
            } else if (key === "opacity" && value.length === 1) {
              transformData.opacity = parseFloat(value[0]);
            } else {
              newEditableData[key] = value.length === 1 ? value[0] : value; // Handle multiple column values
            }
          }
        });

        if (objectName && objectName.trim().toLowerCase() === item?.userData.name.trim().toLowerCase()) {
          console.log("This is the Transform Data: ", transformData);
          if (transformData.position) {
            handlePositionChange("x", transformData.position.x);
            handlePositionChange("y", transformData.position.y);
            handlePositionChange("z", transformData.position.z);
          }
          if (transformData.rotation) {
            handleRotationChange("x", THREE.MathUtils.radToDeg(transformData.rotation.x));
            handleRotationChange("y", THREE.MathUtils.radToDeg(transformData.rotation.y));
            handleRotationChange("z", THREE.MathUtils.radToDeg(transformData.rotation.z));
          }
          if (transformData.scale) {
            handleScaleChange("x", transformData.scale.x);
            handleScaleChange("y", transformData.scale.y);
            handleScaleChange("z", transformData.scale.z);
          }
          if (transformData.opacity !== null) {
            handleOpacityChange(transformData.opacity);
          }
          setEditableData(newEditableData);
          onUpdate(newEditableData, {
            position: transformData.position || undefined,
            rotation: transformData.rotation || undefined,
            scale: transformData.scale || undefined,
            opacity: transformData.opacity || undefined,
          });
        } else {
          alert("The object name in the CSV does not match the current item.");
        }
      };
  
      reader.readAsText(file);
    };
  
    input.click();
  };

  const handleTextureSelectionChange = (textureType: string) => {
    if (!item || !item.material) return;

    if (item.material.isMaterial) {
      item.material = item.material.clone();
    }
  
    let texturePath: string | null = null;
    let normalMapPath: string | null = null;
    let bumpMapPath: string | null = null;
    let displacementMapPath: string | null = null;
    let roughnessMapPath: string | null = null;
    let metalnessMapPath: string | null = null;

    switch (textureType) {
      case "concrete_wall":
        texturePath = ConcreteFloorTexturePath;
        normalMapPath = ConcreteFloorNormalMapPath;
        roughnessMapPath = ConcreteFloorRoughnessMapPath;
        metalnessMapPath = ConcreteFloorMetalnessMapPath;
        break;
      case "Rough_Wall":
        texturePath = RoughWallTexturePath;
        normalMapPath = RoughWallNormalMapPath;
        bumpMapPath = RoughWallBumpMapPath;
        displacementMapPath = RoughWallDisplacementMapPath;
        roughnessMapPath = RoughWallRoughnessMapPath;
        metalnessMapPath = RoughWallMetalnessMapPath;
        break;
      case "brick_wall":
        texturePath = BrickWallTexturePath;
        normalMapPath = BrickWallNormalMapPath;
        displacementMapPath = BrickWallDisplacementMapPath;
        roughnessMapPath = BrickWallRoughnessMapPath;
        metalnessMapPath = BrickWallMetalnessMapPath;
        break;
      case "asphalt":
        texturePath = asphaltTexturePath;
        normalMapPath = asphaltNormalMapPath;
        bumpMapPath = asphaltBumpMapPath;
        displacementMapPath = asphaltDisplacementMapPath;
        roughnessMapPath = asphaltRoughnessMapPath;
        metalnessMapPath = asphaltMetalnessMapPath;
        break;
      case "Concrete":
        texturePath = ConcreteTexturePath;
        normalMapPath = ConcreteNormalMapPath;
        roughnessMapPath = ConcreteRoughnessMapPath;
        metalnessMapPath = ConcreteMetalnessMapPath;
        break;
      case "Grass":
        texturePath = GrassTexturePath;
        normalMapPath = GrassNormalMapPath;
        roughnessMapPath = GrassRoughnessMapPath;
        metalnessMapPath = GrassMetalnessMapPath;
        break;
      default:
        return; 
    }
  
    const textureLoader = new THREE.TextureLoader();
  
    const loadMap = (path: string | null, applyMapCallback: (map: THREE.Texture) => void) => {
      if (path) {
        textureLoader.load(
          path,
          (map) => applyMapCallback(map),
          undefined,
          (error) => console.error(`Error loading map at ${path}:`, error)
        );
      }
    };

    const disposeOldMaps = (material: THREE.Material) => {
      if (material.map) material.map.dispose();
      if (material.normalMap) material.normalMap.dispose();
      if (material.bumpMap) material.bumpMap.dispose();
      if (material.displacementMap) material.displacementMap.dispose();
      if (material.roughnessMap) material.roughnessMap.dispose();
      if (material.metalnessMap) material.metalnessMap.dispose();
    };
  
    disposeOldMaps(item.material);

    loadMap(texturePath, (texture) => {
      item.material.map = texture;
  
      loadMap(normalMapPath, (normalMap) => {
        item.material.normalMap = normalMap;
      });
  
      loadMap(bumpMapPath, (bumpMap) => {
        item.material.bumpMap = bumpMap;
        item.material.bumpScale = 0.1; 
      });
  
      loadMap(displacementMapPath, (displacementMap) => {
        item.material.displacementMap = displacementMap;
        item.material.displacementScale = 0.2; 
      });
  
      loadMap(roughnessMapPath, (roughnessMap) => {
        item.material.roughnessMap = roughnessMap;
      });
  
      loadMap(metalnessMapPath, (metalnessMap) => {
        item.material.metalnessMap = metalnessMap;
      });
  
      item.material.needsUpdate = true; 

      const newEditableData = {
        ...editableData,
        texture: textureType,
      };
      setEditableData(newEditableData);
  
      onUpdate(newEditableData, {});

      if (item.userData) {
        item.userData.texture = textureType;
        console.log("Updated item user data with texture type:", item.userData);
      }
    });
  };

  const renderFieldsBasedOnType = () => {
    switch (type) {
      case "wall":
        return (
          <>
            <li key="TextureSelection">
              <strong>Select Texture:</strong>
              <select
                onChange={(e) => handleTextureSelectionChange(e.target.value)}
                value={editableData.texture || ""}
              >
                <option value="">Select Texture</option>
                <option value="concrete_wall">Concrete Wall</option>
                <option value="Rough_Wall">Rough Wall</option>
                <option value="brick_wall">Brick Wall</option>
              </select>
            </li>
            <li key="Height">
              <strong>Height (m):</strong>
              <input
                type="text"
                value={editableData.Height || ""}
                onChange={(e) => handleUserDataChange("Height", e.target.value)}
                onBlur={() => handleUserDataBlur("Height")}
              />
            </li>
            <li key="Width">
              <strong>Width (m):</strong>
              <input
                type="text"
                value={editableData.Width || ""}
                onChange={(e) => handleUserDataChange("Width", e.target.value)}
                onBlur={() => handleUserDataBlur("Width")}
              />
            </li>
            <li key="Length">
              <strong>Length (m):</strong>
              <input
                type="text"
                value={editableData.length || ""}
                onChange={(e) => handleUserDataChange("length", e.target.value)}
                onBlur={() => handleUserDataBlur("length")}
              />
            </li>
            <li key="Density">
              <strong>Density (kg/m³):</strong>
              <input
                type="text"
                value={editableData.Density || ""}
                onChange={(e) => handleUserDataChange("Density", e.target.value)}
                onBlur={() => handleUserDataBlur("Density")}
              />
            </li>
            <li key="Weight">
              <strong>Weight (kg):</strong>
              <input
                type="text"
                value={editableData.Weight || ""}
                onChange={(e) => handleUserDataChange("Weight", e.target.value)}
                onBlur={() => handleUserDataBlur("Weight")}
              />
            </li>
          </>
        );
      case "slab":
        return (
          <>
            <li key="TextureSelection">
              <strong>Select Texture:</strong>
              <select
                onChange={(e) => handleTextureSelectionChange(e.target.value)}
                value={editableData.texture || ""}
              >
                <option value="">Select Texture</option>
                <option value="asphalt">Asphalt</option>
                <option value="Concrete">Concrete</option>
                <option value="Grass">Grass</option>
              </select>
            </li>
            <li key="Thickness">
              <strong>Thickness (mm):</strong>
              <input
                type="text"
                value={editableData.Thickness || ""}
                onChange={(e) => handleUserDataChange("Thickness", e.target.value)}
                onBlur={() => handleUserDataBlur("Thickness")}
              />
            </li>
            <li key="Width">
              <strong>Width (m):</strong>
              <input
                type="text"
                value={editableData.Width || ""}
                onChange={(e) => handleUserDataChange("Width", e.target.value)}
                onBlur={() => handleUserDataBlur("Width")}
              />
            </li>
            <li key="Length">
              <strong>Length (m):</strong>
              <input
                type="text"
                value={editableData.length || ""}
                onChange={(e) => handleUserDataChange("length", e.target.value)}
                onBlur={() => handleUserDataBlur("length")}
              />
            </li>
            <li key="Density">
              <strong>Density (kg/m³):</strong>
              <input
                type="text"
                value={editableData.Density || ""}
                onChange={(e) => handleUserDataChange("Density", e.target.value)}
                onBlur={() => handleUserDataBlur("Density")}
              />
            </li>
            <li key="Weight">
              <strong>Weight (kg):</strong>
              <input
                type="text"
                value={editableData.Weight || ""}
                onChange={(e) => handleUserDataChange("Weight", e.target.value)}
                onBlur={() => handleUserDataBlur("Weight")}
              />
            </li>
          </>
        );
      case "door":
        return (
          <>
            <li key="Width">
              <strong>Width (m):</strong>
              <input
                type="text"
                value={editableData.Width || ""}
                onChange={(e) => handleUserDataChange("Width", e.target.value)}
                onBlur={() => handleUserDataBlur("Width")}
              />
            </li>
            <li key="Height">
              <strong>Height (m):</strong>
              <input
                type="text"
                value={editableData.Height || ""}
                onChange={(e) => handleUserDataChange("Height", e.target.value)}
                onBlur={() => handleUserDataBlur("Height")}
              />
            </li>
          </>
        );
      case "window":
        return (
          <>
            <li key="Height">
              <strong>Height (m):</strong>
              <input
                type="text"
                value={editableData.Height || ""}
                onChange={(e) => handleUserDataChange("Height", e.target.value)}
                onBlur={() => handleUserDataBlur("Height")}
              />
            </li>
            <li key="Length">
              <strong>Length (m):</strong>
              <input
                type="text"
                value={editableData.length || ""}
                onChange={(e) => handleUserDataChange("length", e.target.value)}
                onBlur={() => handleUserDataBlur("length")}
              />
            </li>
          </>
        );
      case "floor":
        return (
          <>
            <li key="Thickness">
              <strong>Thickness (mm):</strong>
              <input
                type="text"
                value={editableData.Thickness || ""}
                onChange={(e) => handleUserDataChange("Thickness", e.target.value)}
                onBlur={() => handleUserDataBlur("Thickness")}
              />
            </li>
            <li key="ElevationAtTop">
              <strong>Elevation at Top (m):</strong>
              <input
                type="text"
                value={editableData.ElevationAtTop || ""}
                onChange={(e) => handleUserDataChange("ElevationAtTop", e.target.value)}
                onBlur={() => handleUserDataBlur("ElevationAtTop")}
              />
            </li>
            <li key="ElevationAtBottom">
              <strong>Elevation at Bottom (m):</strong>
              <input
                type="text"
                value={editableData.ElevationAtBottom || ""}
                onChange={(e) => handleUserDataChange("ElevationAtBottom", e.target.value)}
                onBlur={() => handleUserDataBlur("ElevationAtBottom")}
              />
            </li>
          </>
        );
      case "cableTray":
        return (
          <>
            <li key="HorizontalJustification">
              <strong>Horizontal Justification:</strong>
              <input
                type="text"
                value={editableData.HorizontalJustification || ""}
                onChange={(e) => handleUserDataChange("HorizontalJustification", e.target.value)}
                onBlur={() => handleUserDataBlur("HorizontalJustification")}
              />
            </li>
            <li key="VerticalJustification">
              <strong>Vertical Justification:</strong>
              <input
                type="text"
                value={editableData.VerticalJustification || ""}
                onChange={(e) => handleUserDataChange("VerticalJustification", e.target.value)}
                onBlur={() => handleUserDataBlur("VerticalJustification")}
              />
            </li>
            <li key="RungSpace">
              <strong>Rung Space (m):</strong>
              <input
                type="text"
                value={editableData.RungSpace || ""}
                onChange={(e) => handleUserDataChange("RungSpace", e.target.value)}
                onBlur={() => handleUserDataBlur("RungSpace")}
              />
            </li>
            <li key="Width">
              <strong>Width (m):</strong>
              <input
                type="text"
                value={editableData.Width || ""}
                onChange={(e) => handleUserDataChange("Width", e.target.value)}
                onBlur={() => handleUserDataBlur("Width")}
              />
            </li>
            <li key="Height">
              <strong>Height (m):</strong>
              <input
                type="text"
                value={editableData.Height || ""}
                onChange={(e) => handleUserDataChange("Height", e.target.value)}
                onBlur={() => handleUserDataBlur("Height")}
              />
            </li>
          </>
        );
      case "flexDuct":
        return (
          <>
            <li key="SystemType">
              <strong>System Type:</strong>
              <input
                type="text"
                value={editableData.SystemType || ""}
                onChange={(e) => handleUserDataChange("SystemType", e.target.value)}
                onBlur={() => handleUserDataBlur("SystemType")}
              />
            </li>
            <li key="LossCoefficient">
              <strong>Loss Coefficient:</strong>
              <input
                type="text"
                value={editableData.LossCoefficient || ""}
                onChange={(e) => handleUserDataChange("LossCoefficient", e.target.value)}
                onBlur={() => handleUserDataBlur("LossCoefficient")}
              />
            </li>
            <li key="AdditionalFlow">
              <strong>Additional Flow (L/s):</strong>
              <input
                type="text"
                value={editableData.AdditionalFlow || ""}
                onChange={(e) => handleUserDataChange("AdditionalFlow", e.target.value)}
                onBlur={() => handleUserDataBlur("AdditionalFlow")}
              />
            </li>
            <li key="Velocity">
              <strong>Velocity (m/s):</strong>
              <input
                type="text"
                value={editableData.Velocity || ""}
                onChange={(e) => handleUserDataChange("Velocity", e.target.value)}
                onBlur={() => handleUserDataBlur("Velocity")}
              />
            </li>
          </>
        );
      case "roof":
        return (
          <>
            <li key="BaseLevel">
              <strong>Base Level (m):</strong>
              <input
                  type="text"
                  value={editableData.BaseLevel || ""}
                  onChange={(e) => handleUserDataChange("BaseLevel", e.target.value)}
                  onBlur={() => handleUserDataBlur("BaseLevel")}
                />
              </li>
              <li key="CutoffLevel">
                <strong>Cutoff Level (m):</strong>
                <input
                  type="text"
                  value={editableData.CutoffLevel || ""}
                  onChange={(e) => handleUserDataChange("CutoffLevel", e.target.value)}
                  onBlur={() => handleUserDataBlur("CutoffLevel")}
                />
              </li>
              <li key="Thickness">
                <strong>Thickness (mm):</strong>
                <input
                  type="text"
                  value={editableData.Thickness || ""}
                  onChange={(e) => handleUserDataChange("Thickness", e.target.value)}
                  onBlur={() => handleUserDataBlur("Thickness")}
                />
              </li>
              <li key="RafterCut">
                <strong>Rafter Cut:</strong>
                <select
                  onChange={(e) => handleUserDataChange("RafterCut", e.target.value)}
                  value={editableData.RafterCut || ""}
                >
                  <option value="">Select Rafter Cut</option>
                  <option value="PlumbCut">Plumb Cut</option>
                  <option value="SquareCut">Square Cut</option>
                </select>
              </li>
            </>
          );
      case "ceiling":
            return (
              <>
                <li key="Level">
                  <strong>Level:</strong>
                  <input
                    type="text"
                    value={editableData.Level || ""}
                    onChange={(e) => handleUserDataChange("Level", e.target.value)}
                    onBlur={() => handleUserDataBlur("Level")}
                  />
                </li>
                <li key="HeightOffset">
                  <strong>Height Offset From Level (m):</strong>
                  <input
                    type="text"
                    value={editableData.HeightOffset || ""}
                    onChange={(e) => handleUserDataChange("HeightOffset", e.target.value)}
                    onBlur={() => handleUserDataBlur("HeightOffset")}
                  />
                </li>
                <li key="Material">
                  <strong>Material:</strong>
                  <input
                    type="text"
                    value={editableData.Material || ""}
                    onChange={(e) => handleUserDataChange("Material", e.target.value)}
                    onBlur={() => handleUserDataBlur("Material")}
                  />
                </li>
              </>
            );
      case "mechanicalEquipment":
        return (
                <>
                  <li key="SystemType">
                    <strong>System Type:</strong>
                    <input
                      type="text"
                      value={editableData.SystemType || ""}
                      onChange={(e) => handleUserDataChange("SystemType", e.target.value)}
                      onBlur={() => handleUserDataBlur("SystemType")}
                    />
                  </li>
                  <li key="Capacity">
                    <strong>Capacity:</strong>
                    <input
                      type="text"
                      value={editableData.Capacity || ""}
                      onChange={(e) => handleUserDataChange("Capacity", e.target.value)}
                      onBlur={() => handleUserDataBlur("Capacity")}
                    />
                  </li>
                  <li key="Dimensions">
                    <strong>Dimensions (L x W x H):</strong>
                    <input
                      type="text"
                      value={editableData.Dimensions || ""}
                      onChange={(e) => handleUserDataChange("Dimensions", e.target.value)}
                      onBlur={() => handleUserDataBlur("Dimensions")}
                    />
                  </li>
                  <li key="Weight">
                    <strong>Weight (kg):</strong>
                    <input
                      type="text"
                      value={editableData.Weight || ""}
                      onChange={(e) => handleUserDataChange("Weight", e.target.value)}
                      onBlur={() => handleUserDataBlur("Weight")}
                    />
                  </li>
                </>
        );
      case "hvacEquipment":
        return (
            <>
              <li key="SystemType">
                <strong>System Type:</strong>
                <input
                  type="text"
                  value={editableData.SystemType || ""}
                  onChange={(e) => handleUserDataChange("SystemType", e.target.value)}
                  onBlur={() => handleUserDataBlur("SystemType")}
                />
              </li>
              <li key="FlowRate">
                <strong>Flow Rate (L/s):</strong>
                <input
                  type="text"
                  value={editableData.FlowRate || ""}
                  onChange={(e) => handleUserDataChange("FlowRate", e.target.value)}
                  onBlur={() => handleUserDataBlur("FlowRate")}
                />
              </li>
              <li key="Power">
                <strong>Power (kW):</strong>
                <input
                  type="text"
                  value={editableData.Power || ""}
                  onChange={(e) => handleUserDataChange("Power", e.target.value)}
                  onBlur={() => handleUserDataBlur("Power")}
                />
              </li>
              <li key="Dimensions">
                <strong>Dimensions (L x W x H):</strong>
                <input
                  type="text"
                  value={editableData.Dimensions || ""}
                  onChange={(e) => handleUserDataChange("Dimensions", e.target.value)}
                  onBlur={() => handleUserDataBlur("Dimensions")}
                />
              </li>
              <li key="Weight">
                <strong>Weight (kg):</strong>
                <input
                  type="text"
                  value={editableData.Weight || ""}
                  onChange={(e) => handleUserDataChange("Weight", e.target.value)}
                  onBlur={() => handleUserDataBlur("Weight")}
                />
              </li>
            </>
        );
      case "plumbingFixture":
        return (
            <>
              <li key="SystemType">
                <strong>System Type:</strong>
                <input
                  type="text"
                  value={editableData.SystemType || ""}
                  onChange={(e) => handleUserDataChange("SystemType", e.target.value)}
                  onBlur={() => handleUserDataBlur("SystemType")}
                />
              </li>
              <li key="FlowRate">
                <strong>Flow Rate (L/s):</strong>
                <input
                  type="text"
                  value={editableData.FlowRate || ""}
                  onChange={(e) => handleUserDataChange("FlowRate", e.target.value)}
                  onBlur={() => handleUserDataBlur("FlowRate")}
                />
              </li>
              <li key="Capacity">
                <strong>Capacity (L):</strong>
                <input
                  type="text"
                  value={editableData.Capacity || ""}
                  onChange={(e) => handleUserDataChange("Capacity", e.target.value)}
                  onBlur={() => handleUserDataBlur("Capacity")}
                />
              </li>
              <li key="Dimensions">
                <strong>Dimensions (L x W x H):</strong>
                <input
                  type="text"
                  value={editableData.Dimensions || ""}
                  onChange={(e) => handleUserDataChange("Dimensions", e.target.value)}
                  onBlur={() => handleUserDataBlur("Dimensions")}
                />
              </li>
              <li key="Material">
                <strong>Material:</strong>
                <input
                  type="text"
                  value={editableData.Material || ""}
                  onChange={(e) => handleUserDataChange("Material", e.target.value)}
                  onBlur={() => handleUserDataBlur("Material")}
                />
              </li>
            </>
        );
      case "electricalEquipment":
        return (
            <>
              <li key="SystemType">
                <strong>System Type:</strong>
                <input
                  type="text"
                  value={editableData.SystemType || ""}
                  onChange={(e) => handleUserDataChange("SystemType", e.target.value)}
                  onBlur={() => handleUserDataBlur("SystemType")}
                />
              </li>
              <li key="PowerRating">
                <strong>Power Rating (kW):</strong>
                <input
                  type="text"
                  value={editableData.PowerRating || ""}
                  onChange={(e) => handleUserDataChange("PowerRating", e.target.value)}
                  onBlur={() => handleUserDataBlur("PowerRating")}
                />
              </li>
              <li key="Voltage">
                <strong>Voltage (V):</strong>
                <input
                  type="text"
                  value={editableData.Voltage || ""}
                  onChange={(e) => handleUserDataChange("Voltage", e.target.value)}
                  onBlur={() => handleUserDataBlur("Voltage")}
                />
              </li>
              <li key="Dimensions">
                <strong>Dimensions (L x W x H):</strong>
                <input
                  type="text"
                  value={editableData.Dimensions || ""}
                  onChange={(e) => handleUserDataChange("Dimensions", e.target.value)}
                  onBlur={() => handleUserDataBlur("Dimensions")}
                />
              </li>
              <li key="Weight">
                <strong>Weight (kg):</strong>
                <input
                  type="text"
                  value={editableData.Weight || ""}
                  onChange={(e) => handleUserDataChange("Weight", e.target.value)}
                  onBlur={() => handleUserDataBlur("Weight")}
                />
              </li>
            </>
        );
      case "fireProtectionSystem":
        return (
            <>
              <li key="SystemType">
                <strong>System Type:</strong>
                <input
                  type="text"
                  value={editableData.SystemType || ""}
                  onChange={(e) => handleUserDataChange("SystemType", e.target.value)}
                  onBlur={() => handleUserDataBlur("SystemType")}
                />
              </li>
              <li key="CoverageArea">
                <strong>Coverage Area (sq/m):</strong>
                <input
                  type="text"
                  value={editableData.CoverageArea || ""}
                  onChange={(e) => handleUserDataChange("CoverageArea", e.target.value)}
                  onBlur={() => handleUserDataBlur("CoverageArea")}
                />
              </li>
              <li key="Pressure">
                <strong>Pressure (Pa):</strong>
                <input
                  type="text"
                  value={editableData.Pressure || ""}
                  onChange={(e) => handleUserDataChange("Pressure", e.target.value)}
                  onBlur={() => handleUserDataBlur("Pressure")}
                />
              </li>
              <li key="FlowRate">
                <strong>Flow Rate (L/s):</strong>
                <input
                  type="text"
                  value={editableData.FlowRate || ""}
                  onChange={(e) => handleUserDataChange("FlowRate", e.target.value)}
                  onBlur={() => handleUserDataBlur("FlowRate")}
                />
              </li>
              <li key="Material">
                <strong>Material:</strong>
                <input
                  type="text"
                  value={editableData.Material || ""}
                  onChange={(e) => handleUserDataChange("Material", e.target.value)}
                  onBlur={() => handleUserDataBlur("Material")}
                />
              </li>
            </>
        );             
                     
        default:
        return null;
    }
  };
  
  

  return (
    <div className="user-data-panel">
      <h4>Asset Data</h4>
      <li key="ID">
          <strong>ID:</strong> {item?.userData.id || "N/A"}
        </li>
      <ul>
        <li key="Type">
          <strong>Type:</strong>
          <select
            value={type}
            onChange={(e) => handleUserDataChange("Type", e.target.value)}
            onBlur={() => handleUserDataBlur("Type")}
          >
            <option value="none">None Selected</option>
            <option value="wall">Wall</option>
            <option value="slab">Slab</option>
            <option value="floor">Floor</option>
            <option value="roof">Roof</option>
            <option value="ceiling">Ceiling</option>
            <option value="door">Door</option>
            <option value="window">Window</option>
            <option value="cableTray">Cable Tray</option>
            <option value="flexDuct">Flex Duct</option>
            <option value="mechanicalEquipment">Mechanical Equipment</option>
            <option value="hvacEquipment">HVAC Equipment</option>
            <option value="plumbingFixture">Plumbing Fixture</option>
            <option value="electricalEquipment">Electrical Equipment</option>
            <option value="fireProtectionSystem">Fire Protection Equipment</option>
          </select>
        </li>
        <li key="Name">
          <strong>Name:</strong>
          <input
            type="text"
            value={editableData.name || ""}
            onChange={(e) => handleUserDataChange("name", e.target.value)}
            onBlur={() => handleUserDataBlur("name")}
          />
        </li>
        <li key="ClippedAsset">
          <strong>Clipped Asset:</strong>
          <select
            value={editableData.ClippedAsset ? "true" : "false"}
            onChange={(e) => handleUserDataChange("ClippedAsset", e.target.value)}
            onBlur={() => handleUserDataBlur("ClippedAsset")}
          >
            <option value="false">False</option>
            <option value="true">True</option>
          </select>
        </li>
        {renderFieldsBasedOnType()}
      </ul>
      {item && (
        <>
          <div className="UDTransform">
            <label>
              <strong>Position:</strong>
              <div>
                <label>X:</label>
                <input
                  type="number"
                  step="0.01"
                  value={position.x}
                  onChange={(e) =>
                    handlePositionChange("x", parseFloat(e.target.value))
                  }
                />
                <label>Y:</label>
                <input
                  type="number"
                  step="0.01"
                  value={position.y}
                  onChange={(e) =>
                    handlePositionChange("y", parseFloat(e.target.value))
                  }
                />
                <label>Z:</label>
                <input
                  type="number"
                  step="0.01"
                  value={position.z}
                  onChange={(e) =>
                    handlePositionChange("z", parseFloat(e.target.value))
                  }
                />
              </div>
            </label>

            <label>
              <strong>Rotation (degrees):</strong>
              <div>
                <label>X:</label>
                <input
                  type="number"
                  step="1"
                  value={THREE.MathUtils.radToDeg(rotation.x)}
                  onChange={(e) =>
                    handleRotationChange("x", parseFloat(e.target.value))
                  }
                />
                <label>Y:</label>
                <input
                  type="number"
                  step="1"
                  value={THREE.MathUtils.radToDeg(rotation.y)}
                  onChange={(e) =>
                    handleRotationChange("y", parseFloat(e.target.value))
                  }
                />
                <label>Z:</label>
                <input
                  type="number"
                  step="1"
                  value={THREE.MathUtils.radToDeg(rotation.z)}
                  onChange={(e) =>
                    handleRotationChange("z", parseFloat(e.target.value))
                  }
                />
              </div>
            </label>

            <label>
              <strong>Scale:</strong>
              <div>
                <label>X:</label>
                <input
                  type="number"
                  step="0.01"
                  value={scale.x}
                  onChange={(e) =>
                    handleScaleChange("x", parseFloat(e.target.value))
                  }
                />
                <label>Y:</label>
                <input
                  type="number"
                  step="0.01"
                  value={scale.y}
                  onChange={(e) =>
                    handleScaleChange("y", parseFloat(e.target.value))
                  }
                />
                <label>Z:</label>
                <input
                  type="number"
                  step="0.01"
                  value={scale.z}
                  onChange={(e) =>
                    handleScaleChange("z", parseFloat(e.target.value))
                  }
                />
              </div>
            </label>
            <label>
              <strong>Transparency:</strong>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={opacity}
                onChange={(e) => handleOpacityChange(parseFloat(e.target.value))}
              />
            </label>
          </div>
          <button onClick={handleDownloadCSV}>Download CSV</button>
          <button onClick={handleUploadCSV}>Upload CSV</button>
        </>
      )}
    </div>
  );
};

const TransformControlsComponent: React.FC<TransformControlsComponentProps> = ({
  camera,
  scene,
  canvas,
  controls,
  item,
  onClose,
  onDelete,
  onClone,
}) => {
  const [transformControls, setTransformControls] = useState<TransformControls | null>(null);
  const [mode, setMode] = useState<'translate' | 'rotate' | 'scale'>('translate');
  const [selectedUserData, setSelectedUserData] = useState<Record<string, any>>(item.userData || {});
  const [selectedItemId, setSelectedItemId] = useState(item?.userData.id || "");
  const [isClippedAsset, setIsClippedAsset] = useState<boolean>(item.userData.ClippedAsset);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const pivotRef = useRef<THREE.Object3D | null>(null);
  const isDeletedRef = useRef(false);

  useEffect(()=> {
    console.log("this is the value of the ClippedAsset", isClippedAsset);
    setIsClippedAsset(item.userData.ClippedAsset);
  },[updateTrigger])

  useEffect(() => {
    setSelectedUserData(item.userData);
    setSelectedItemId(item?.userData.id || ""); 
  }, [item]);

  useEffect(() => {
    const cleanUp = () => {
      if (pivotRef.current) {
        pivotRef.current.remove(item);
        scene.remove(pivotRef.current);
      }
  
      if (!isDeletedRef.current) {
        item.updateMatrixWorld(true);
        if (pivotRef.current) {
          item.applyMatrix4(pivotRef.current.matrixWorld);
        }
        scene.add(item);
      }
    };

    if (isClippedAsset) {
      item.updateMatrixWorld(true);
      
      const box = new THREE.Box3().setFromObject(item);
      const center = box.getCenter(new THREE.Vector3());
  
      const pivot = new THREE.Object3D();
      pivot.position.copy(center);
      scene.add(pivot);
  
      item.position.sub(center);
      pivot.add(item);
  
      pivotRef.current = pivot;
  
      const transformControls = new TransformControls(camera, canvas);

      transformControls.attach(pivot);
      scene.add(transformControls);
  
      const onChange = () => {
        if (controls) controls.update();
      };
  
      const onDraggingChanged = (event: any) => {
        if (controls) {
          controls.enabled = !event.value;
        }
      };
  
      transformControls.addEventListener('change', onChange);
      transformControls.addEventListener('dragging-changed', onDraggingChanged);
  
      setTransformControls(transformControls);
  
      return () => {
        if (transformControls) {
          transformControls.removeEventListener('change', onChange);
          transformControls.removeEventListener('dragging-changed', onDraggingChanged);
          transformControls.detach();
          scene.remove(transformControls);
          if (controls) {
            controls.enabled = true;
          }
        }
        cleanUp();
      };
    } else {
      const transformControls = new TransformControls(camera, canvas);
      transformControls.attach(item);
      scene.add(transformControls);
  
      const onChange = () => {
        controls && controls.update();
      };
  
      const onDraggingChanged = (event: any) => {
        if (controls) {
          controls.enabled = !event.value;
        }
      };
  
      transformControls.addEventListener('change', onChange);
      transformControls.addEventListener('dragging-changed', onDraggingChanged);

      setTransformControls(transformControls);
  
      return () => {
        if (transformControls) {
          transformControls.removeEventListener('change', onChange);
          transformControls.removeEventListener('dragging-changed', onDraggingChanged);
          transformControls.detach();
          scene.remove(transformControls);
          if (controls) {
            controls.enabled = true;
          }
        }
        cleanUp();
      };
    }
  }, [camera, canvas, controls, item, scene, isClippedAsset]);

  const handleModeChange = (newMode: 'translate' | 'rotate' | 'scale') => {
    setMode(newMode);
    if (transformControls) {
      transformControls.setMode(newMode);
    }
  };

  const handleClone = () => {
    const baseName = item.userData.name || "Object";
    const sameNameCount = scene.children.filter((obj) => obj.userData.name && obj.userData.name === baseName).length;
    const uniqueId = `${baseName}_ID_${sameNameCount}`;
  
    if (isClippedAsset) {
      const clonedItem = item.clone();
      clonedItem.position.set(0, 0, 0);
      clonedItem.rotation.set(0, 0, 0);
      clonedItem.scale.set(1, 1, 1);
      clonedItem.updateMatrix();
      clonedItem.updateMatrixWorld(true);
      clonedItem.userData.name = baseName;
      clonedItem.userData.id = uniqueId;
  
      scene.add(clonedItem);
      onClone(clonedItem);
    } else {
      const clonedItem = item.clone();
      clonedItem.userData.name = baseName;
      clonedItem.userData.id = uniqueId;
  
      scene.add(clonedItem);
      onClone(clonedItem);
    }
  };
  
  const handleDelete = () => {
    isDeletedRef.current = true;

    if (pivotRef.current) {
      pivotRef.current.remove(item);
      scene.remove(item);
      scene.remove(pivotRef.current);
    }

    if (transformControls) {
      transformControls.detach();
      scene.remove(transformControls);
    }

    onDelete();
  };

  const handleClose = () => {
    onClose();
    if (transformControls) {
      transformControls.detach();
      scene.remove(transformControls);
    }
  };

  useEffect(() => {
    setSelectedUserData(item.userData);

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        switch (event.key) {
          case '1':
            event.preventDefault();
            handleModeChange('translate');
            break;
          case '2':
            event.preventDefault();
            handleModeChange('rotate');
            break;
          case '3':
            event.preventDefault();
            handleModeChange('scale');
            break;
          case 'c':
            event.preventDefault();
            handleClone();
            break;
          case 'Delete':
            event.preventDefault();
            handleDelete();
            break;
          case 'x':
            event.preventDefault();
            handleClose();
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [transformControls, handleClone, item]);

  return (
    <div className="transform-controls-wrapper">
      <div className="transform-controls-container">
        <ButtonGroup vertical>
          <Tooltip content="Translate the object" className="transform-controls-tooltip">
            <Button
              icon="arrows-horizontal"
              active={mode === 'translate'}
              onClick={() => handleModeChange('translate')}
              className={`transform-controls-button ${mode === 'translate' ? 'active' : ''}`}
            />
          </Tooltip>
          <Tooltip content="Rotate the object" className="transform-controls-tooltip">
            <Button
              icon="repeat"
              active={mode === 'rotate'}
              onClick={() => handleModeChange('rotate')}
              className={`transform-controls-button ${mode === 'rotate' ? 'active' : ''}`}
            />
          </Tooltip>
          <Tooltip content="Scale the object" className="transform-controls-tooltip">
            <Button
              icon="zoom-to-fit"
              active={mode === 'scale'}
              onClick={() => handleModeChange('scale')}
              className={`transform-controls-button ${mode === 'scale' ? 'active' : ''}`}
            />
          </Tooltip>
          <Tooltip content="Clone the object" className="transform-controls-tooltip">
            <Button
              icon="duplicate"
              onClick={handleClone}
              className="transform-controls-button"
            />
          </Tooltip>
          <Tooltip content="Delete the object" className="transform-controls-tooltip">
            <Button
              icon="trash"
              onClick={handleDelete}
              className="transform-controls-button"
            />
          </Tooltip>
          <Tooltip content="Close controls" className="transform-controls-tooltip">
            <Button
              icon="cross"
              onClick={handleClose}
              className="transform-controls-button"
            />
          </Tooltip>
        </ButtonGroup>
      </div>
      <UserDataPanel
        userData={selectedUserData}
        item={item ? item : undefined} 
        isClippedAsset={isClippedAsset} 
        pivotRef={pivotRef}
        onUpdate={(newUserData, updatedTransforms) => {
          console.log('Updated UserData:', newUserData);
          console.log('Updated Transforms:', updatedTransforms);
          setSelectedUserData(newUserData);
          setIsClippedAsset(newUserData.ClippedAsset);
          setSelectedItemId(newUserData.id);
        }}
      />

    </div>
  );
};

export default TransformControlsComponent;
