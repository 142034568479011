import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesChinese = [
  {
    profile_section_id: 4722,
    name: "PIP32X2.5",
    type: "Pipe",
    designation: "PIP32X2.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "2,32",
    d_global: 32,
    i_global: "2,54",
    t_global: "2,5",
    z_global: "2,18",
  },
  {
    profile_section_id: 4723,
    name: "PIP32X3.0",
    type: "Pipe",
    designation: "PIP32X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "2,73",
    d_global: 32,
    i_global: "2,9",
    t_global: 3,
    z_global: "2,52",
  },
  {
    profile_section_id: 4724,
    name: "PIP32X3.5",
    type: "Pipe",
    designation: "PIP32X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "3,13",
    d_global: 32,
    i_global: "3,23",
    t_global: "3,5",
    z_global: "2,84",
  },
  {
    profile_section_id: 4725,
    name: "PIP32X4.0",
    type: "Pipe",
    designation: "PIP32X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "3,52",
    d_global: 32,
    i_global: "3,52",
    t_global: 4,
    z_global: "3,14",
  },
  {
    profile_section_id: 4726,
    name: "PIP38X2.5",
    type: "Pipe",
    designation: "PIP38X2.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "2,79",
    d_global: 38,
    i_global: "4,41",
    t_global: "2,5",
    z_global: "3,15",
  },
  {
    profile_section_id: 4727,
    name: "PIP38X3.0",
    type: "Pipe",
    designation: "PIP38X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "3,3",
    d_global: 38,
    i_global: "5,09",
    t_global: 3,
    z_global: "3,68",
  },
  {
    profile_section_id: 4728,
    name: "PIP38X3.5",
    type: "Pipe",
    designation: "PIP38X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "3,79",
    d_global: 38,
    i_global: "5,7",
    t_global: "3,5",
    z_global: "4,17",
  },
  {
    profile_section_id: 4729,
    name: "PIP38X4.0",
    type: "Pipe",
    designation: "PIP38X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "4,27",
    d_global: 38,
    i_global: "6,26",
    t_global: 4,
    z_global: "4,62",
  },
  {
    profile_section_id: 4730,
    name: "PIP42X2.5",
    type: "Pipe",
    designation: "PIP42X2.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "3,1",
    d_global: 42,
    i_global: "6,07",
    t_global: "2,5",
    z_global: "3,9",
  },
  {
    profile_section_id: 4731,
    name: "PIP42X3.0",
    type: "Pipe",
    designation: "PIP42X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "3,68",
    d_global: 42,
    i_global: "7,03",
    t_global: 3,
    z_global: "4,57",
  },
  {
    profile_section_id: 4732,
    name: "PIP42X3.5",
    type: "Pipe",
    designation: "PIP42X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "4,23",
    d_global: 42,
    i_global: "7,91",
    t_global: "3,5",
    z_global: "5,19",
  },
  {
    profile_section_id: 4733,
    name: "PIP42X4.0",
    type: "Pipe",
    designation: "PIP42X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "4,78",
    d_global: 42,
    i_global: "8,71",
    t_global: 4,
    z_global: "5,79",
  },
  {
    profile_section_id: 4734,
    name: "PIP45X2.5",
    type: "Pipe",
    designation: "PIP45X2.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "3,34",
    d_global: 45,
    i_global: "7,56",
    t_global: "2,5",
    z_global: "4,52",
  },
  {
    profile_section_id: 4735,
    name: "PIP45X3.0",
    type: "Pipe",
    designation: "PIP45X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "3,96",
    d_global: 45,
    i_global: "8,77",
    t_global: 3,
    z_global: "5,29",
  },
  {
    profile_section_id: 4736,
    name: "PIP45X3.5",
    type: "Pipe",
    designation: "PIP45X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "4,56",
    d_global: 45,
    i_global: "9,89",
    t_global: "3,5",
    z_global: "6,03",
  },
  {
    profile_section_id: 4737,
    name: "PIP45X4.0",
    type: "Pipe",
    designation: "PIP45X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "5,15",
    d_global: 45,
    i_global: "10,93",
    t_global: 4,
    z_global: "6,72",
  },
  {
    profile_section_id: 4738,
    name: "PIP50X2.5",
    type: "Pipe",
    designation: "PIP50X2.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "3,73",
    d_global: 50,
    i_global: "10,55",
    t_global: "2,5",
    z_global: "5,64",
  },
  {
    profile_section_id: 4739,
    name: "PIP50X3.0",
    type: "Pipe",
    designation: "PIP50X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "4,43",
    d_global: 50,
    i_global: "12,28",
    t_global: 3,
    z_global: "6,63",
  },
  {
    profile_section_id: 4740,
    name: "PIP50X3.5",
    type: "Pipe",
    designation: "PIP50X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "5,11",
    d_global: 50,
    i_global: "13,9",
    t_global: "3,5",
    z_global: "7,57",
  },
  {
    profile_section_id: 4741,
    name: "PIP50X4.0",
    type: "Pipe",
    designation: "PIP50X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "5,78",
    d_global: 50,
    i_global: "15,41",
    t_global: 4,
    z_global: "8,46",
  },
  {
    profile_section_id: 4742,
    name: "PIP50X4.5",
    type: "Pipe",
    designation: "PIP50X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "6,43",
    d_global: 50,
    i_global: "16,81",
    t_global: "4,5",
    z_global: "9,32",
  },
  {
    profile_section_id: 4743,
    name: "PIP50X5.0",
    type: "Pipe",
    designation: "PIP50X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "7,07",
    d_global: 50,
    i_global: "18,11",
    t_global: 5,
    z_global: "10,13",
  },
  {
    profile_section_id: 4744,
    name: "PIP54X3.0",
    type: "Pipe",
    designation: "PIP54X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "4,81",
    d_global: 54,
    i_global: "15,68",
    t_global: 3,
    z_global: "7,81",
  },
  {
    profile_section_id: 4745,
    name: "PIP54X3.5",
    type: "Pipe",
    designation: "PIP54X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "5,55",
    d_global: 54,
    i_global: "17,79",
    t_global: "3,5",
    z_global: "8,93",
  },
  {
    profile_section_id: 4746,
    name: "PIP54X4.0",
    type: "Pipe",
    designation: "PIP54X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "6,28",
    d_global: 54,
    i_global: "19,76",
    t_global: 4,
    z_global: 10,
  },
  {
    profile_section_id: 4747,
    name: "PIP54X4.5",
    type: "Pipe",
    designation: "PIP54X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: 7,
    d_global: 54,
    i_global: "21,61",
    t_global: "4,5",
    z_global: "11,04",
  },
  {
    profile_section_id: 4748,
    name: "PIP54X5.0",
    type: "Pipe",
    designation: "PIP54X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "7,7",
    d_global: 54,
    i_global: "23,34",
    t_global: 5,
    z_global: "12,02",
  },
  {
    profile_section_id: 4749,
    name: "PIP54X5.5",
    type: "Pipe",
    designation: "PIP54X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "8,38",
    d_global: 54,
    i_global: "24,96",
    t_global: "5,5",
    z_global: "12,94",
  },
  {
    profile_section_id: 4750,
    name: "PIP54X6.0",
    type: "Pipe",
    designation: "PIP54X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "9,05",
    d_global: 54,
    i_global: "26,46",
    t_global: 6,
    z_global: "13,84",
  },
  {
    profile_section_id: 4751,
    name: "PIP57X3.0",
    type: "Pipe",
    designation: "PIP57X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "5,09",
    d_global: 57,
    i_global: "18,61",
    t_global: 3,
    z_global: "8,75",
  },
  {
    profile_section_id: 4752,
    name: "PIP57X3.5",
    type: "Pipe",
    designation: "PIP57X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "5,88",
    d_global: 57,
    i_global: "21,14",
    t_global: "3,5",
    z_global: "10,02",
  },
  {
    profile_section_id: 4753,
    name: "PIP57X4.0",
    type: "Pipe",
    designation: "PIP57X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "6,66",
    d_global: 57,
    i_global: "23,52",
    t_global: 4,
    z_global: "11,24",
  },
  {
    profile_section_id: 4754,
    name: "PIP57X4.5",
    type: "Pipe",
    designation: "PIP57X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "7,42",
    d_global: 57,
    i_global: "25,76",
    t_global: "4,5",
    z_global: "12,4",
  },
  {
    profile_section_id: 4755,
    name: "PIP57X5.0",
    type: "Pipe",
    designation: "PIP57X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "8,17",
    d_global: 57,
    i_global: "27,86",
    t_global: 5,
    z_global: "13,53",
  },
  {
    profile_section_id: 4756,
    name: "PIP57X5.5",
    type: "Pipe",
    designation: "PIP57X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "8,9",
    d_global: 57,
    i_global: "29,84",
    t_global: "5,5",
    z_global: "14,6",
  },
  {
    profile_section_id: 4757,
    name: "PIP57X6.0",
    type: "Pipe",
    designation: "PIP57X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "9,61",
    d_global: 57,
    i_global: "31,69",
    t_global: 6,
    z_global: "15,61",
  },
  {
    profile_section_id: 4758,
    name: "PIP60X3.0",
    type: "Pipe",
    designation: "PIP60X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "5,37",
    d_global: 60,
    i_global: "21,88",
    t_global: 3,
    z_global: "9,75",
  },
  {
    profile_section_id: 4759,
    name: "PIP60X3.5",
    type: "Pipe",
    designation: "PIP60X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "6,21",
    d_global: 60,
    i_global: "24,88",
    t_global: "3,5",
    z_global: "11,17",
  },
  {
    profile_section_id: 4760,
    name: "PIP60X4.0",
    type: "Pipe",
    designation: "PIP60X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "7,04",
    d_global: 60,
    i_global: "27,73",
    t_global: 4,
    z_global: "12,56",
  },
  {
    profile_section_id: 4761,
    name: "PIP60X4.5",
    type: "Pipe",
    designation: "PIP60X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "7,85",
    d_global: 60,
    i_global: "30,41",
    t_global: "4,5",
    z_global: "13,88",
  },
  {
    profile_section_id: 4762,
    name: "PIP60X5.0",
    type: "Pipe",
    designation: "PIP60X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "8,64",
    d_global: 60,
    i_global: "32,94",
    t_global: 5,
    z_global: "15,13",
  },
  {
    profile_section_id: 4763,
    name: "PIP60X5.5",
    type: "Pipe",
    designation: "PIP60X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "9,42",
    d_global: 60,
    i_global: "35,32",
    t_global: "5,5",
    z_global: "16,35",
  },
  {
    profile_section_id: 4764,
    name: "PIP60X6.0",
    type: "Pipe",
    designation: "PIP60X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "10,18",
    d_global: 60,
    i_global: "37,56",
    t_global: 6,
    z_global: "17,51",
  },
  {
    profile_section_id: 4765,
    name: "PIP63.5X3.0",
    type: "Pipe",
    designation: "PIP63.5X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "5,7",
    d_global: "63,5",
    i_global: "26,15",
    t_global: 3,
    z_global: "10,98",
  },
  {
    profile_section_id: 4766,
    name: "PIP63.5X3.5",
    type: "Pipe",
    designation: "PIP63.5X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "6,6",
    d_global: "63,5",
    i_global: "29,79",
    t_global: "3,5",
    z_global: "12,61",
  },
  {
    profile_section_id: 4767,
    name: "PIP63.5X4.0",
    type: "Pipe",
    designation: "PIP63.5X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "7,48",
    d_global: "63,5",
    i_global: "33,24",
    t_global: 4,
    z_global: "14,18",
  },
  {
    profile_section_id: 4768,
    name: "PIP63.5X4.5",
    type: "Pipe",
    designation: "PIP63.5X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "8,34",
    d_global: "63,5",
    i_global: "36,5",
    t_global: "4,5",
    z_global: "15,67",
  },
  {
    profile_section_id: 4769,
    name: "PIP63.5X5.0",
    type: "Pipe",
    designation: "PIP63.5X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "9,19",
    d_global: "63,5",
    i_global: "39,6",
    t_global: 5,
    z_global: "17,12",
  },
  {
    profile_section_id: 4770,
    name: "PIP63.5X5.5",
    type: "Pipe",
    designation: "PIP63.5X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "10,02",
    d_global: "63,5",
    i_global: "42,52",
    t_global: "5,5",
    z_global: "18,5",
  },
  {
    profile_section_id: 4771,
    name: "PIP63.5X6.0",
    type: "Pipe",
    designation: "PIP63.5X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "10,84",
    d_global: "63,5",
    i_global: "45,28",
    t_global: 6,
    z_global: "19,85",
  },
  {
    profile_section_id: 4772,
    name: "PIP68X3.0",
    type: "Pipe",
    designation: "PIP68X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "6,13",
    d_global: 68,
    i_global: "32,42",
    t_global: 3,
    z_global: "12,69",
  },
  {
    profile_section_id: 4773,
    name: "PIP68X3.5",
    type: "Pipe",
    designation: "PIP68X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "7,09",
    d_global: 68,
    i_global: "36,99",
    t_global: "3,5",
    z_global: "14,56",
  },
  {
    profile_section_id: 4774,
    name: "PIP68X4.0",
    type: "Pipe",
    designation: "PIP68X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "8,04",
    d_global: 68,
    i_global: "41,34",
    t_global: 4,
    z_global: "16,39",
  },
  {
    profile_section_id: 4775,
    name: "PIP68X4.5",
    type: "Pipe",
    designation: "PIP68X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "8,98",
    d_global: 68,
    i_global: "45,47",
    t_global: "4,5",
    z_global: "18,16",
  },
  {
    profile_section_id: 4776,
    name: "PIP68X5.0",
    type: "Pipe",
    designation: "PIP68X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "9,9",
    d_global: 68,
    i_global: "49,41",
    t_global: 5,
    z_global: "19,86",
  },
  {
    profile_section_id: 4777,
    name: "PIP68X5.5",
    type: "Pipe",
    designation: "PIP68X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "10,8",
    d_global: 68,
    i_global: "53,14",
    t_global: "5,5",
    z_global: "21,5",
  },
  {
    profile_section_id: 4778,
    name: "PIP68X6.0",
    type: "Pipe",
    designation: "PIP68X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "11,69",
    d_global: 68,
    i_global: "56,68",
    t_global: 6,
    z_global: "23,08",
  },
  {
    profile_section_id: 4779,
    name: "PIP70X3.0",
    type: "Pipe",
    designation: "PIP70X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "6,31",
    d_global: 70,
    i_global: "35,5",
    t_global: 3,
    z_global: "13,47",
  },
  {
    profile_section_id: 4780,
    name: "PIP70X3.5",
    type: "Pipe",
    designation: "PIP70X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "7,31",
    d_global: 70,
    i_global: "40,53",
    t_global: "3,5",
    z_global: "15,48",
  },
  {
    profile_section_id: 4781,
    name: "PIP70X4.0",
    type: "Pipe",
    designation: "PIP70X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "8,29",
    d_global: 70,
    i_global: "45,33",
    t_global: 4,
    z_global: "17,43",
  },
  {
    profile_section_id: 4782,
    name: "PIP70X4.5",
    type: "Pipe",
    designation: "PIP70X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "9,26",
    d_global: 70,
    i_global: "49,89",
    t_global: "4,5",
    z_global: "19,31",
  },
  {
    profile_section_id: 4783,
    name: "PIP70X5.0",
    type: "Pipe",
    designation: "PIP70X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "10,21",
    d_global: 70,
    i_global: "54,24",
    t_global: 5,
    z_global: "21,13",
  },
  {
    profile_section_id: 4784,
    name: "PIP70X5.5",
    type: "Pipe",
    designation: "PIP70X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "11,14",
    d_global: 70,
    i_global: "58,38",
    t_global: "5,5",
    z_global: "22,88",
  },
  {
    profile_section_id: 4785,
    name: "PIP70X6.0",
    type: "Pipe",
    designation: "PIP70X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "12,06",
    d_global: 70,
    i_global: "62,31",
    t_global: 6,
    z_global: "24,58",
  },
  {
    profile_section_id: 4786,
    name: "PIP73X3.0",
    type: "Pipe",
    designation: "PIP73X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "6,6",
    d_global: 73,
    i_global: "40,48",
    t_global: 3,
    z_global: "14,71",
  },
  {
    profile_section_id: 4787,
    name: "PIP73X3.5",
    type: "Pipe",
    designation: "PIP73X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "7,64",
    d_global: 73,
    i_global: "46,26",
    t_global: "3,5",
    z_global: "16,91",
  },
  {
    profile_section_id: 4788,
    name: "PIP73X4.0",
    type: "Pipe",
    designation: "PIP73X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "8,67",
    d_global: 73,
    i_global: "51,78",
    t_global: 4,
    z_global: "19,05",
  },
  {
    profile_section_id: 4789,
    name: "PIP73X4.5",
    type: "Pipe",
    designation: "PIP73X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "9,68",
    d_global: 73,
    i_global: "57,04",
    t_global: "4,5",
    z_global: "21,12",
  },
  {
    profile_section_id: 4790,
    name: "PIP73X5.0",
    type: "Pipe",
    designation: "PIP73X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "10,68",
    d_global: 73,
    i_global: "62,07",
    t_global: 5,
    z_global: "23,12",
  },
  {
    profile_section_id: 4791,
    name: "PIP73X5.5",
    type: "Pipe",
    designation: "PIP73X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "11,66",
    d_global: 73,
    i_global: "66,87",
    t_global: "5,5",
    z_global: "25,06",
  },
  {
    profile_section_id: 4792,
    name: "PIP73X6.0",
    type: "Pipe",
    designation: "PIP73X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "12,63",
    d_global: 73,
    i_global: "71,43",
    t_global: 6,
    z_global: "26,95",
  },
  {
    profile_section_id: 4793,
    name: "PIP76X3.0",
    type: "Pipe",
    designation: "PIP76X3.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "6,88",
    d_global: 76,
    i_global: "45,91",
    t_global: 3,
    z_global: "15,99",
  },
  {
    profile_section_id: 4794,
    name: "PIP76X3.5",
    type: "Pipe",
    designation: "PIP76X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "7,97",
    d_global: 76,
    i_global: "52,5",
    t_global: "3,5",
    z_global: "18,4",
  },
  {
    profile_section_id: 4795,
    name: "PIP76X4.0",
    type: "Pipe",
    designation: "PIP76X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "9,05",
    d_global: 76,
    i_global: "58,81",
    t_global: 4,
    z_global: "20,75",
  },
  {
    profile_section_id: 4796,
    name: "PIP76X4.5",
    type: "Pipe",
    designation: "PIP76X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "10,11",
    d_global: 76,
    i_global: "64,85",
    t_global: "4,5",
    z_global: "23,02",
  },
  {
    profile_section_id: 4797,
    name: "PIP76X5.0",
    type: "Pipe",
    designation: "PIP76X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "11,15",
    d_global: 76,
    i_global: "70,62",
    t_global: 5,
    z_global: "25,21",
  },
  {
    profile_section_id: 4798,
    name: "PIP76X5.5",
    type: "Pipe",
    designation: "PIP76X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "12,18",
    d_global: 76,
    i_global: "76,14",
    t_global: "5,5",
    z_global: "27,34",
  },
  {
    profile_section_id: 4799,
    name: "PIP76X6.0",
    type: "Pipe",
    designation: "PIP76X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "13,19",
    d_global: 76,
    i_global: "81,41",
    t_global: 6,
    z_global: "29,4",
  },
  {
    profile_section_id: 4800,
    name: "PIP83X3.5",
    type: "Pipe",
    designation: "PIP83X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "8,74",
    d_global: 83,
    i_global: "69,19",
    t_global: "3,5",
    z_global: "22,12",
  },
  {
    profile_section_id: 4801,
    name: "PIP83X4.0",
    type: "Pipe",
    designation: "PIP83X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "9,93",
    d_global: 83,
    i_global: "77,64",
    t_global: 4,
    z_global: "24,98",
  },
  {
    profile_section_id: 4802,
    name: "PIP83X4.5",
    type: "Pipe",
    designation: "PIP83X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "11,1",
    d_global: 83,
    i_global: "85,76",
    t_global: "4,5",
    z_global: "27,75",
  },
  {
    profile_section_id: 4803,
    name: "PIP83X5.0",
    type: "Pipe",
    designation: "PIP83X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "12,25",
    d_global: 83,
    i_global: "93,56",
    t_global: 5,
    z_global: "30,42",
  },
  {
    profile_section_id: 4804,
    name: "PIP83X5.5",
    type: "Pipe",
    designation: "PIP83X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "13,39",
    d_global: 83,
    i_global: "101,04",
    t_global: "5,5",
    z_global: "33,05",
  },
  {
    profile_section_id: 4805,
    name: "PIP83X6.0",
    type: "Pipe",
    designation: "PIP83X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "14,51",
    d_global: 83,
    i_global: "108,22",
    t_global: 6,
    z_global: "35,58",
  },
  {
    profile_section_id: 4806,
    name: "PIP83X6.5",
    type: "Pipe",
    designation: "PIP83X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "15,62",
    d_global: 83,
    i_global: "115,1",
    t_global: "6,5",
    z_global: "38,06",
  },
  {
    profile_section_id: 4807,
    name: "PIP83X7.0",
    type: "Pipe",
    designation: "PIP83X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "16,71",
    d_global: 83,
    i_global: "121,69",
    t_global: 7,
    z_global: "40,45",
  },
  {
    profile_section_id: 4808,
    name: "PIP89X3.5",
    type: "Pipe",
    designation: "PIP89X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "9,4",
    d_global: 89,
    i_global: "86,05",
    t_global: "3,5",
    z_global: "25,59",
  },
  {
    profile_section_id: 4809,
    name: "PIP89X4.0",
    type: "Pipe",
    designation: "PIP89X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "10,68",
    d_global: 89,
    i_global: "96,68",
    t_global: 4,
    z_global: "28,9",
  },
  {
    profile_section_id: 4810,
    name: "PIP89X4.5",
    type: "Pipe",
    designation: "PIP89X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "11,95",
    d_global: 89,
    i_global: "106,92",
    t_global: "4,5",
    z_global: "32,16",
  },
  {
    profile_section_id: 4811,
    name: "PIP89X5.0",
    type: "Pipe",
    designation: "PIP89X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "13,19",
    d_global: 89,
    i_global: "116,79",
    t_global: 5,
    z_global: "35,28",
  },
  {
    profile_section_id: 4812,
    name: "PIP89X5.5",
    type: "Pipe",
    designation: "PIP89X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "14,43",
    d_global: 89,
    i_global: "126,29",
    t_global: "5,5",
    z_global: "38,38",
  },
  {
    profile_section_id: 4813,
    name: "PIP89X6.0",
    type: "Pipe",
    designation: "PIP89X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "15,65",
    d_global: 89,
    i_global: "135,43",
    t_global: 6,
    z_global: "41,37",
  },
  {
    profile_section_id: 4814,
    name: "PIP89X6.5",
    type: "Pipe",
    designation: "PIP89X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "16,85",
    d_global: 89,
    i_global: "144,22",
    t_global: "6,5",
    z_global: "44,27",
  },
  {
    profile_section_id: 4815,
    name: "PIP89X7.0",
    type: "Pipe",
    designation: "PIP89X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "18,03",
    d_global: 89,
    i_global: "152,67",
    t_global: 7,
    z_global: "47,09",
  },
  {
    profile_section_id: 4816,
    name: "PIP95X3.5",
    type: "Pipe",
    designation: "PIP95X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "10,06",
    d_global: 95,
    i_global: "105,45",
    t_global: "3,5",
    z_global: "29,31",
  },
  {
    profile_section_id: 4817,
    name: "PIP95X4.0",
    type: "Pipe",
    designation: "PIP95X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "11,44",
    d_global: 95,
    i_global: "118,6",
    t_global: 4,
    z_global: "33,16",
  },
  {
    profile_section_id: 4818,
    name: "PIP95X4.5",
    type: "Pipe",
    designation: "PIP95X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "12,79",
    d_global: 95,
    i_global: "131,31",
    t_global: "4,5",
    z_global: "36,86",
  },
  {
    profile_section_id: 4819,
    name: "PIP95X5.0",
    type: "Pipe",
    designation: "PIP95X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "14,14",
    d_global: 95,
    i_global: "143,58",
    t_global: 5,
    z_global: "40,53",
  },
  {
    profile_section_id: 4820,
    name: "PIP95X5.5",
    type: "Pipe",
    designation: "PIP95X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "15,46",
    d_global: 95,
    i_global: "155,43",
    t_global: "5,5",
    z_global: "44,06",
  },
  {
    profile_section_id: 4821,
    name: "PIP95X6.0",
    type: "Pipe",
    designation: "PIP95X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "16,78",
    d_global: 95,
    i_global: "166,86",
    t_global: 6,
    z_global: "47,56",
  },
  {
    profile_section_id: 4822,
    name: "PIP95X6.5",
    type: "Pipe",
    designation: "PIP95X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "18,07",
    d_global: 95,
    i_global: "177,89",
    t_global: "6,5",
    z_global: "50,93",
  },
  {
    profile_section_id: 4823,
    name: "PIP95X7.0",
    type: "Pipe",
    designation: "PIP95X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "19,35",
    d_global: 95,
    i_global: "188,51",
    t_global: 7,
    z_global: "54,23",
  },
  {
    profile_section_id: 4824,
    name: "PIP102X3.5",
    type: "Pipe",
    designation: "PIP102X3.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "10,83",
    d_global: 102,
    i_global: "131,52",
    t_global: "3,5",
    z_global: "33,96",
  },
  {
    profile_section_id: 4825,
    name: "PIP102X4.0",
    type: "Pipe",
    designation: "PIP102X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "12,32",
    d_global: 102,
    i_global: "148,09",
    t_global: 4,
    z_global: "38,45",
  },
  {
    profile_section_id: 4826,
    name: "PIP102X4.5",
    type: "Pipe",
    designation: "PIP102X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "13,78",
    d_global: 102,
    i_global: "164,14",
    t_global: "4,5",
    z_global: "42,78",
  },
  {
    profile_section_id: 4827,
    name: "PIP102X5.0",
    type: "Pipe",
    designation: "PIP102X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "15,24",
    d_global: 102,
    i_global: "179,68",
    t_global: 5,
    z_global: "47,08",
  },
  {
    profile_section_id: 4828,
    name: "PIP102X5.5",
    type: "Pipe",
    designation: "PIP102X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "16,67",
    d_global: 102,
    i_global: "194,72",
    t_global: "5,5",
    z_global: "51,22",
  },
  {
    profile_section_id: 4829,
    name: "PIP102X6.0",
    type: "Pipe",
    designation: "PIP102X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "18,1",
    d_global: 102,
    i_global: "209,28",
    t_global: 6,
    z_global: "55,34",
  },
  {
    profile_section_id: 4830,
    name: "PIP102X6.5",
    type: "Pipe",
    designation: "PIP102X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "19,5",
    d_global: 102,
    i_global: "223,35",
    t_global: "6,5",
    z_global: "59,31",
  },
  {
    profile_section_id: 4831,
    name: "PIP102X7.0",
    type: "Pipe",
    designation: "PIP102X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "20,89",
    d_global: 102,
    i_global: "236,96",
    t_global: 7,
    z_global: "63,21",
  },
  {
    profile_section_id: 4832,
    name: "PIP108X4",
    type: "Pipe",
    designation: "PIP108X4",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "13,06",
    d_global: 108,
    i_global: "176,95",
    t_global: 4,
    z_global: "43,28",
  },
  {
    profile_section_id: 4833,
    name: "PIP108X4.5",
    type: "Pipe",
    designation: "PIP108X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "14,62",
    d_global: 108,
    i_global: "196,3",
    t_global: "4,5",
    z_global: "48,23",
  },
  {
    profile_section_id: 4834,
    name: "PIP108X5",
    type: "Pipe",
    designation: "PIP108X5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "16,17",
    d_global: 108,
    i_global: "215,06",
    t_global: 5,
    z_global: "53,09",
  },
  {
    profile_section_id: 4835,
    name: "PIP108X5.5",
    type: "Pipe",
    designation: "PIP108X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "17,7",
    d_global: 108,
    i_global: "233,26",
    t_global: "5,5",
    z_global: "57,84",
  },
  {
    profile_section_id: 4836,
    name: "PIP108X6",
    type: "Pipe",
    designation: "PIP108X6",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "19,22",
    d_global: 108,
    i_global: "250,91",
    t_global: 6,
    z_global: "62,5",
  },
  {
    profile_section_id: 4837,
    name: "PIP108X6.5",
    type: "Pipe",
    designation: "PIP108X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "20,72",
    d_global: 108,
    i_global: "268,01",
    t_global: "6,5",
    z_global: "67,06",
  },
  {
    profile_section_id: 4838,
    name: "PIP108X7",
    type: "Pipe",
    designation: "PIP108X7",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "22,2",
    d_global: 108,
    i_global: "284,58",
    t_global: 7,
    z_global: "71,52",
  },
  {
    profile_section_id: 4839,
    name: "PIP108X7.5",
    type: "Pipe",
    designation: "PIP108X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "23,67",
    d_global: 108,
    i_global: "300,63",
    t_global: "7,5",
    z_global: "75,89",
  },
  {
    profile_section_id: 4840,
    name: "PIP108X8",
    type: "Pipe",
    designation: "PIP108X8",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "25,12",
    d_global: 108,
    i_global: "316,17",
    t_global: 8,
    z_global: "80,17",
  },
  {
    profile_section_id: 4841,
    name: "PIP114X4.0",
    type: "Pipe",
    designation: "PIP114X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "13,82",
    d_global: 114,
    i_global: "209,35",
    t_global: 4,
    z_global: "48,4",
  },
  {
    profile_section_id: 4842,
    name: "PIP114X4.5",
    type: "Pipe",
    designation: "PIP114X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "15,48",
    d_global: 114,
    i_global: "232,41",
    t_global: "4,5",
    z_global: "53,99",
  },
  {
    profile_section_id: 4843,
    name: "PIP114X5.0",
    type: "Pipe",
    designation: "PIP114X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "17,12",
    d_global: 114,
    i_global: "254,81",
    t_global: 5,
    z_global: "59,44",
  },
  {
    profile_section_id: 4844,
    name: "PIP114X5.5",
    type: "Pipe",
    designation: "PIP114X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "18,75",
    d_global: 114,
    i_global: "276,58",
    t_global: "5,5",
    z_global: "64,8",
  },
  {
    profile_section_id: 4845,
    name: "PIP114X6.0",
    type: "Pipe",
    designation: "PIP114X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "20,36",
    d_global: 114,
    i_global: "297,73",
    t_global: 6,
    z_global: "70,03",
  },
  {
    profile_section_id: 4846,
    name: "PIP114X6.5",
    type: "Pipe",
    designation: "PIP114X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "21,95",
    d_global: 114,
    i_global: "318,26",
    t_global: "6,5",
    z_global: "75,15",
  },
  {
    profile_section_id: 4847,
    name: "PIP114X7.0",
    type: "Pipe",
    designation: "PIP114X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "23,53",
    d_global: 114,
    i_global: "338,19",
    t_global: 7,
    z_global: "80,19",
  },
  {
    profile_section_id: 4848,
    name: "PIP114X7.5",
    type: "Pipe",
    designation: "PIP114X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "25,09",
    d_global: 114,
    i_global: "357,56",
    t_global: "7,5",
    z_global: "85,11",
  },
  {
    profile_section_id: 4849,
    name: "PIP114X8.0",
    type: "Pipe",
    designation: "PIP114X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "26,64",
    d_global: 114,
    i_global: "376,3",
    t_global: 8,
    z_global: "89,94",
  },
  {
    profile_section_id: 4850,
    name: "PIP121X4.0",
    type: "Pipe",
    designation: "PIP121X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "14,7",
    d_global: 121,
    i_global: "251,87",
    t_global: 4,
    z_global: "54,76",
  },
  {
    profile_section_id: 4851,
    name: "PIP121X4.5",
    type: "Pipe",
    designation: "PIP121X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "16,47",
    d_global: 121,
    i_global: "279,83",
    t_global: "4,5",
    z_global: "61,11",
  },
  {
    profile_section_id: 4852,
    name: "PIP121X5.0",
    type: "Pipe",
    designation: "PIP121X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "18,22",
    d_global: 121,
    i_global: "307,05",
    t_global: 5,
    z_global: "67,32",
  },
  {
    profile_section_id: 4853,
    name: "PIP121X5.5",
    type: "Pipe",
    designation: "PIP121X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "19,96",
    d_global: 121,
    i_global: "333,54",
    t_global: "5,5",
    z_global: "73,43",
  },
  {
    profile_section_id: 4854,
    name: "PIP121X6.0",
    type: "Pipe",
    designation: "PIP121X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "21,68",
    d_global: 121,
    i_global: "359,32",
    t_global: 6,
    z_global: "79,41",
  },
  {
    profile_section_id: 4855,
    name: "PIP121X6.5",
    type: "Pipe",
    designation: "PIP121X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "23,38",
    d_global: 121,
    i_global: "384,4",
    t_global: "6,5",
    z_global: "85,26",
  },
  {
    profile_section_id: 4856,
    name: "PIP121X7.0",
    type: "Pipe",
    designation: "PIP121X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "25,07",
    d_global: 121,
    i_global: "408,8",
    t_global: 7,
    z_global: "91,03",
  },
  {
    profile_section_id: 4857,
    name: "PIP121X7.5",
    type: "Pipe",
    designation: "PIP121X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "26,74",
    d_global: 121,
    i_global: "432,51",
    t_global: "7,5",
    z_global: "96,66",
  },
  {
    profile_section_id: 4858,
    name: "PIP121X8.0",
    type: "Pipe",
    designation: "PIP121X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "28,4",
    d_global: 121,
    i_global: "455,57",
    t_global: 8,
    z_global: "102,21",
  },
  {
    profile_section_id: 4859,
    name: "PIP127X4.0",
    type: "Pipe",
    designation: "PIP127X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "15,46",
    d_global: 127,
    i_global: "292,61",
    t_global: 4,
    z_global: "60,56",
  },
  {
    profile_section_id: 4860,
    name: "PIP127X4.5",
    type: "Pipe",
    designation: "PIP127X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "17,32",
    d_global: 127,
    i_global: "325,29",
    t_global: "4,5",
    z_global: "67,58",
  },
  {
    profile_section_id: 4861,
    name: "PIP127X5.0",
    type: "Pipe",
    designation: "PIP127X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "19,16",
    d_global: 127,
    i_global: "357,14",
    t_global: 5,
    z_global: "74,45",
  },
  {
    profile_section_id: 4862,
    name: "PIP127X5.5",
    type: "Pipe",
    designation: "PIP127X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "20,99",
    d_global: 127,
    i_global: "388,19",
    t_global: "5,5",
    z_global: "81,23",
  },
  {
    profile_section_id: 4863,
    name: "PIP127X6.0",
    type: "Pipe",
    designation: "PIP127X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "22,81",
    d_global: 127,
    i_global: "418,44",
    t_global: 6,
    z_global: "87,91",
  },
  {
    profile_section_id: 4864,
    name: "PIP127X6.5",
    type: "Pipe",
    designation: "PIP127X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "24,61",
    d_global: 127,
    i_global: "447,92",
    t_global: "6,5",
    z_global: "94,45",
  },
  {
    profile_section_id: 4865,
    name: "PIP127X7.0",
    type: "Pipe",
    designation: "PIP127X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "26,39",
    d_global: 127,
    i_global: "476,63",
    t_global: 7,
    z_global: "100,86",
  },
  {
    profile_section_id: 4866,
    name: "PIP127X7.5",
    type: "Pipe",
    designation: "PIP127X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "28,16",
    d_global: 127,
    i_global: "504,58",
    t_global: "7,5",
    z_global: "107,18",
  },
  {
    profile_section_id: 4867,
    name: "PIP127X8.0",
    type: "Pipe",
    designation: "PIP127X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "29,91",
    d_global: 127,
    i_global: "531,8",
    t_global: 8,
    z_global: "113,36",
  },
  {
    profile_section_id: 4868,
    name: "PIP133X4.0",
    type: "Pipe",
    designation: "PIP133X4.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "16,21",
    d_global: 133,
    i_global: "337,53",
    t_global: 4,
    z_global: "66,6",
  },
  {
    profile_section_id: 4869,
    name: "PIP133X4.5",
    type: "Pipe",
    designation: "PIP133X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "18,17",
    d_global: 133,
    i_global: "375,42",
    t_global: "4,5",
    z_global: "74,36",
  },
  {
    profile_section_id: 4870,
    name: "PIP133X5.0",
    type: "Pipe",
    designation: "PIP133X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "20,11",
    d_global: 133,
    i_global: "412,4",
    t_global: 5,
    z_global: "81,98",
  },
  {
    profile_section_id: 4871,
    name: "PIP133X5.5",
    type: "Pipe",
    designation: "PIP133X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "22,03",
    d_global: 133,
    i_global: "448,5",
    t_global: "5,5",
    z_global: "89,46",
  },
  {
    profile_section_id: 4872,
    name: "PIP133X6.0",
    type: "Pipe",
    designation: "PIP133X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "23,94",
    d_global: 133,
    i_global: "483,72",
    t_global: 6,
    z_global: "96,84",
  },
  {
    profile_section_id: 4873,
    name: "PIP133X6.5",
    type: "Pipe",
    designation: "PIP133X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "25,83",
    d_global: 133,
    i_global: "518,07",
    t_global: "6,5",
    z_global: "104,07",
  },
  {
    profile_section_id: 4874,
    name: "PIP133X7.0",
    type: "Pipe",
    designation: "PIP133X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "27,71",
    d_global: 133,
    i_global: "551,58",
    t_global: 7,
    z_global: "111,2",
  },
  {
    profile_section_id: 4875,
    name: "PIP133X7.5",
    type: "Pipe",
    designation: "PIP133X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "29,57",
    d_global: 133,
    i_global: "584,25",
    t_global: "7,5",
    z_global: "118,2",
  },
  {
    profile_section_id: 4876,
    name: "PIP133X8.0",
    type: "Pipe",
    designation: "PIP133X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "31,42",
    d_global: 133,
    i_global: "616,11",
    t_global: 8,
    z_global: "125,09",
  },
  {
    profile_section_id: 4877,
    name: "PIP140X4.5",
    type: "Pipe",
    designation: "PIP140X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "19,16",
    d_global: 140,
    i_global: "440,12",
    t_global: "4,5",
    z_global: "82,69",
  },
  {
    profile_section_id: 4878,
    name: "PIP140X5.0",
    type: "Pipe",
    designation: "PIP140X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "21,21",
    d_global: 140,
    i_global: "483,76",
    t_global: 5,
    z_global: "91,2",
  },
  {
    profile_section_id: 4879,
    name: "PIP140X5.5",
    type: "Pipe",
    designation: "PIP140X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "23,24",
    d_global: 140,
    i_global: "526,4",
    t_global: "5,5",
    z_global: "99,56",
  },
  {
    profile_section_id: 4880,
    name: "PIP140X6.0",
    type: "Pipe",
    designation: "PIP140X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "25,26",
    d_global: 140,
    i_global: "568,06",
    t_global: 6,
    z_global: "107,81",
  },
  {
    profile_section_id: 4881,
    name: "PIP140X6.5",
    type: "Pipe",
    designation: "PIP140X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "27,26",
    d_global: 140,
    i_global: "608,76",
    t_global: "6,5",
    z_global: "115,91",
  },
  {
    profile_section_id: 4882,
    name: "PIP140X7.0",
    type: "Pipe",
    designation: "PIP140X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "29,25",
    d_global: 140,
    i_global: "648,51",
    t_global: 7,
    z_global: "123,9",
  },
  {
    profile_section_id: 4883,
    name: "PIP140X7.5",
    type: "Pipe",
    designation: "PIP140X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "31,22",
    d_global: 140,
    i_global: "687,32",
    t_global: "7,5",
    z_global: "131,75",
  },
  {
    profile_section_id: 4884,
    name: "PIP140X8.0",
    type: "Pipe",
    designation: "PIP140X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "33,18",
    d_global: 140,
    i_global: "725,21",
    t_global: 8,
    z_global: "139,5",
  },
  {
    profile_section_id: 4885,
    name: "PIP140X9.0",
    type: "Pipe",
    designation: "PIP140X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "37,04",
    d_global: 140,
    i_global: "798,29",
    t_global: 9,
    z_global: "154,54",
  },
  {
    profile_section_id: 4886,
    name: "PIP140X10.0",
    type: "Pipe",
    designation: "PIP140X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "40,84",
    d_global: 140,
    i_global: "867,86",
    t_global: 10,
    z_global: "169,1",
  },
  {
    profile_section_id: 4887,
    name: "PIP146X4.5",
    type: "Pipe",
    designation: "PIP146X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: 20,
    d_global: 146,
    i_global: "501,16",
    t_global: "4,5",
    z_global: "90,14",
  },
  {
    profile_section_id: 4888,
    name: "PIP146X5.0",
    type: "Pipe",
    designation: "PIP146X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "22,15",
    d_global: 146,
    i_global: "551,1",
    t_global: 5,
    z_global: "99,47",
  },
  {
    profile_section_id: 4889,
    name: "PIP146X5.5",
    type: "Pipe",
    designation: "PIP146X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "24,28",
    d_global: 146,
    i_global: "599,95",
    t_global: "5,5",
    z_global: "108,65",
  },
  {
    profile_section_id: 4890,
    name: "PIP146X6.0",
    type: "Pipe",
    designation: "PIP146X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "26,39",
    d_global: 146,
    i_global: "647,73",
    t_global: 6,
    z_global: "117,67",
  },
  {
    profile_section_id: 4891,
    name: "PIP146X6.5",
    type: "Pipe",
    designation: "PIP146X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "28,49",
    d_global: 146,
    i_global: "694,44",
    t_global: "6,5",
    z_global: "126,58",
  },
  {
    profile_section_id: 4892,
    name: "PIP146X7.0",
    type: "Pipe",
    designation: "PIP146X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "30,57",
    d_global: 146,
    i_global: "740,12",
    t_global: 7,
    z_global: "135,34",
  },
  {
    profile_section_id: 4893,
    name: "PIP146X7.5",
    type: "Pipe",
    designation: "PIP146X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "32,63",
    d_global: 146,
    i_global: "784,77",
    t_global: "7,5",
    z_global: "143,94",
  },
  {
    profile_section_id: 4894,
    name: "PIP146X8.0",
    type: "Pipe",
    designation: "PIP146X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "34,68",
    d_global: 146,
    i_global: "828,41",
    t_global: 8,
    z_global: "152,43",
  },
  {
    profile_section_id: 4895,
    name: "PIP146X9.0",
    type: "Pipe",
    designation: "PIP146X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "38,74",
    d_global: 146,
    i_global: "912,71",
    t_global: 9,
    z_global: "169,04",
  },
  {
    profile_section_id: 4896,
    name: "PIP146X10.0",
    type: "Pipe",
    designation: "PIP146X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "42,73",
    d_global: 146,
    i_global: "993,16",
    t_global: 10,
    z_global: "185,09",
  },
  {
    profile_section_id: 4897,
    name: "PIP152X4.5",
    type: "Pipe",
    designation: "PIP152X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "20,85",
    d_global: 152,
    i_global: "567,61",
    t_global: "4,5",
    z_global: "97,95",
  },
  {
    profile_section_id: 4898,
    name: "PIP152X5.0",
    type: "Pipe",
    designation: "PIP152X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "23,09",
    d_global: 152,
    i_global: "624,43",
    t_global: 5,
    z_global: "108,11",
  },
  {
    profile_section_id: 4899,
    name: "PIP152X5.5",
    type: "Pipe",
    designation: "PIP152X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "25,31",
    d_global: 152,
    i_global: "680,06",
    t_global: "5,5",
    z_global: "118,1",
  },
  {
    profile_section_id: 4900,
    name: "PIP152X6.0",
    type: "Pipe",
    designation: "PIP152X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "27,52",
    d_global: 152,
    i_global: "734,52",
    t_global: 6,
    z_global: "127,97",
  },
  {
    profile_section_id: 4901,
    name: "PIP152X6.5",
    type: "Pipe",
    designation: "PIP152X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "29,71",
    d_global: 152,
    i_global: "787,82",
    t_global: "6,5",
    z_global: "137,68",
  },
  {
    profile_section_id: 4902,
    name: "PIP152X7.0",
    type: "Pipe",
    designation: "PIP152X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "31,89",
    d_global: 152,
    i_global: "839,99",
    t_global: 7,
    z_global: "147,28",
  },
  {
    profile_section_id: 4903,
    name: "PIP152X7.5",
    type: "Pipe",
    designation: "PIP152X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "34,05",
    d_global: 152,
    i_global: "891,03",
    t_global: "7,5",
    z_global: "156,71",
  },
  {
    profile_section_id: 4904,
    name: "PIP152X8.0",
    type: "Pipe",
    designation: "PIP152X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "36,19",
    d_global: 152,
    i_global: "940,97",
    t_global: 8,
    z_global: "165,98",
  },
  {
    profile_section_id: 4905,
    name: "PIP152X9.0",
    type: "Pipe",
    designation: "PIP152X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "40,43",
    d_global: 152,
    i_global: "1037,59",
    t_global: 9,
    z_global: "184,14",
  },
  {
    profile_section_id: 4906,
    name: "PIP152X10.0",
    type: "Pipe",
    designation: "PIP152X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "44,61",
    d_global: 152,
    i_global: "1129,99",
    t_global: 10,
    z_global: "201,76",
  },
  {
    profile_section_id: 4907,
    name: "PIP159X4.5",
    type: "Pipe",
    designation: "PIP159X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "21,84",
    d_global: 159,
    i_global: "652,27",
    t_global: "4,5",
    z_global: "107,47",
  },
  {
    profile_section_id: 4908,
    name: "PIP159X5.0",
    type: "Pipe",
    designation: "PIP159X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "24,19",
    d_global: 159,
    i_global: "717,88",
    t_global: 5,
    z_global: "118,65",
  },
  {
    profile_section_id: 4909,
    name: "PIP159X5.5",
    type: "Pipe",
    designation: "PIP159X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "26,52",
    d_global: 159,
    i_global: "782,18",
    t_global: "5,5",
    z_global: "129,66",
  },
  {
    profile_section_id: 4910,
    name: "PIP159X6.0",
    type: "Pipe",
    designation: "PIP159X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "28,84",
    d_global: 159,
    i_global: "845,19",
    t_global: 6,
    z_global: "140,54",
  },
  {
    profile_section_id: 4911,
    name: "PIP159X6.5",
    type: "Pipe",
    designation: "PIP159X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "31,14",
    d_global: 159,
    i_global: "906,92",
    t_global: "6,5",
    z_global: "151,25",
  },
  {
    profile_section_id: 4912,
    name: "PIP159X7.0",
    type: "Pipe",
    designation: "PIP159X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "33,43",
    d_global: 159,
    i_global: "967,41",
    t_global: 7,
    z_global: "161,84",
  },
  {
    profile_section_id: 4913,
    name: "PIP159X7.5",
    type: "Pipe",
    designation: "PIP159X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "35,7",
    d_global: 159,
    i_global: "1026,65",
    t_global: "7,5",
    z_global: "172,26",
  },
  {
    profile_section_id: 4914,
    name: "PIP159X8.0",
    type: "Pipe",
    designation: "PIP159X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "37,95",
    d_global: 159,
    i_global: "1084,67",
    t_global: 8,
    z_global: "182,51",
  },
  {
    profile_section_id: 4915,
    name: "PIP159X9.0",
    type: "Pipe",
    designation: "PIP159X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "42,41",
    d_global: 159,
    i_global: "1197,12",
    t_global: 9,
    z_global: "202,61",
  },
  {
    profile_section_id: 4916,
    name: "PIP159X10.0",
    type: "Pipe",
    designation: "PIP159X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "46,81",
    d_global: 159,
    i_global: "1304,88",
    t_global: 10,
    z_global: "222,14",
  },
  {
    profile_section_id: 4917,
    name: "PIP168X4.5",
    type: "Pipe",
    designation: "PIP168X4.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "23,11",
    d_global: 168,
    i_global: "772,96",
    t_global: "4,5",
    z_global: "120,34",
  },
  {
    profile_section_id: 4918,
    name: "PIP168X5.0",
    type: "Pipe",
    designation: "PIP168X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "25,6",
    d_global: 168,
    i_global: "851,14",
    t_global: 5,
    z_global: "132,9",
  },
  {
    profile_section_id: 4919,
    name: "PIP168X5.5",
    type: "Pipe",
    designation: "PIP168X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "28,08",
    d_global: 168,
    i_global: "927,85",
    t_global: "5,5",
    z_global: "145,33",
  },
  {
    profile_section_id: 4920,
    name: "PIP168X6.0",
    type: "Pipe",
    designation: "PIP168X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "30,54",
    d_global: 168,
    i_global: "1003,12",
    t_global: 6,
    z_global: "157,58",
  },
  {
    profile_section_id: 4921,
    name: "PIP168X6.5",
    type: "Pipe",
    designation: "PIP168X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "32,98",
    d_global: 168,
    i_global: "1076,95",
    t_global: "6,5",
    z_global: "169,64",
  },
  {
    profile_section_id: 4922,
    name: "PIP168X7.0",
    type: "Pipe",
    designation: "PIP168X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "35,41",
    d_global: 168,
    i_global: "1149,36",
    t_global: 7,
    z_global: "181,58",
  },
  {
    profile_section_id: 4923,
    name: "PIP168X7.5",
    type: "Pipe",
    designation: "PIP168X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "37,82",
    d_global: 168,
    i_global: "1220,38",
    t_global: "7,5",
    z_global: "193,33",
  },
  {
    profile_section_id: 4924,
    name: "PIP168X8.0",
    type: "Pipe",
    designation: "PIP168X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "40,42",
    d_global: 168,
    i_global: "1290,01",
    t_global: 8,
    z_global: "204,91",
  },
  {
    profile_section_id: 4925,
    name: "PIP168X9.0",
    type: "Pipe",
    designation: "PIP168X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "44,96",
    d_global: 168,
    i_global: "1425,22",
    t_global: 9,
    z_global: "227,68",
  },
  {
    profile_section_id: 4926,
    name: "PIP168X10.0",
    type: "Pipe",
    designation: "PIP168X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "49,64",
    d_global: 168,
    i_global: "1555,13",
    t_global: 10,
    z_global: "249,8",
  },
  {
    profile_section_id: 4927,
    name: "PIP180X5.0",
    type: "Pipe",
    designation: "PIP180X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "27,49",
    d_global: 180,
    i_global: "1053,17",
    t_global: 5,
    z_global: "153,22",
  },
  {
    profile_section_id: 4928,
    name: "PIP180X5.5",
    type: "Pipe",
    designation: "PIP180X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "30,15",
    d_global: 180,
    i_global: "1148,79",
    t_global: "5,5",
    z_global: "167,57",
  },
  {
    profile_section_id: 4929,
    name: "PIP180X6.0",
    type: "Pipe",
    designation: "PIP180X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "32,8",
    d_global: 180,
    i_global: "1242,72",
    t_global: 6,
    z_global: "181,77",
  },
  {
    profile_section_id: 4930,
    name: "PIP180X6.5",
    type: "Pipe",
    designation: "PIP180X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "35,43",
    d_global: 180,
    i_global: 1335,
    t_global: "6,5",
    z_global: "195,78",
  },
  {
    profile_section_id: 4931,
    name: "PIP180X7.0",
    type: "Pipe",
    designation: "PIP180X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "38,04",
    d_global: 180,
    i_global: "1425,63",
    t_global: 7,
    z_global: "209,6",
  },
  {
    profile_section_id: 4932,
    name: "PIP180X7.5",
    type: "Pipe",
    designation: "PIP180X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "40,64",
    d_global: 180,
    i_global: "1514,64",
    t_global: "7,5",
    z_global: "223,28",
  },
  {
    profile_section_id: 4933,
    name: "PIP180X8.0",
    type: "Pipe",
    designation: "PIP180X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "43,23",
    d_global: 180,
    i_global: "1602,04",
    t_global: 8,
    z_global: "236,82",
  },
  {
    profile_section_id: 4934,
    name: "PIP180X9.0",
    type: "Pipe",
    designation: "PIP180X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "48,35",
    d_global: 180,
    i_global: "1772,12",
    t_global: 9,
    z_global: "263,33",
  },
  {
    profile_section_id: 4935,
    name: "PIP180X10.0",
    type: "Pipe",
    designation: "PIP180X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "53,41",
    d_global: 180,
    i_global: "1936,01",
    t_global: 10,
    z_global: "289,19",
  },
  {
    profile_section_id: 4936,
    name: "PIP180X12.0",
    type: "Pipe",
    designation: "PIP180X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "63,33",
    d_global: 180,
    i_global: "2245,84",
    t_global: 12,
    z_global: "338,87",
  },
  {
    profile_section_id: 4937,
    name: "PIP194X5.0",
    type: "Pipe",
    designation: "PIP194X5.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "29,69",
    d_global: 194,
    i_global: "1326,54",
    t_global: 5,
    z_global: "178,72",
  },
  {
    profile_section_id: 4938,
    name: "PIP194X5.5",
    type: "Pipe",
    designation: "PIP194X5.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "32,57",
    d_global: 194,
    i_global: "1447,86",
    t_global: "5,5",
    z_global: "195,54",
  },
  {
    profile_section_id: 4939,
    name: "PIP194X6.0",
    type: "Pipe",
    designation: "PIP194X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "35,44",
    d_global: 194,
    i_global: "1567,21",
    t_global: 6,
    z_global: "212,21",
  },
  {
    profile_section_id: 4940,
    name: "PIP194X6.5",
    type: "Pipe",
    designation: "PIP194X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "38,29",
    d_global: 194,
    i_global: "1684,61",
    t_global: "6,5",
    z_global: "228,66",
  },
  {
    profile_section_id: 4941,
    name: "PIP194X7.0",
    type: "Pipe",
    designation: "PIP194X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "41,12",
    d_global: 194,
    i_global: "1800,08",
    t_global: 7,
    z_global: "244,91",
  },
  {
    profile_section_id: 4942,
    name: "PIP194X7.5",
    type: "Pipe",
    designation: "PIP194X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "43,94",
    d_global: 194,
    i_global: "1913,64",
    t_global: "7,5",
    z_global: 261,
  },
  {
    profile_section_id: 4943,
    name: "PIP194X8.0",
    type: "Pipe",
    designation: "PIP194X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "46,75",
    d_global: 194,
    i_global: "2025,31",
    t_global: 8,
    z_global: "276,95",
  },
  {
    profile_section_id: 4944,
    name: "PIP194X9.0",
    type: "Pipe",
    designation: "PIP194X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "52,31",
    d_global: 194,
    i_global: "2243,08",
    t_global: 9,
    z_global: "308,22",
  },
  {
    profile_section_id: 4945,
    name: "PIP194X10.0",
    type: "Pipe",
    designation: "PIP194X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "57,81",
    d_global: 194,
    i_global: "2453,55",
    t_global: 10,
    z_global: "338,79",
  },
  {
    profile_section_id: 4946,
    name: "PIP194X12.0",
    type: "Pipe",
    designation: "PIP194X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "68,61",
    d_global: 194,
    i_global: "2853,25",
    t_global: 12,
    z_global: "397,71",
  },
  {
    profile_section_id: 4947,
    name: "PIP203X6.0",
    type: "Pipe",
    designation: "PIP203X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "37,13",
    d_global: 203,
    i_global: "1803,07",
    t_global: 6,
    z_global: "232,97",
  },
  {
    profile_section_id: 4948,
    name: "PIP203X6.5",
    type: "Pipe",
    designation: "PIP203X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "40,13",
    d_global: 203,
    i_global: "1938,81",
    t_global: "6,5",
    z_global: "251,16",
  },
  {
    profile_section_id: 4949,
    name: "PIP203X7.0",
    type: "Pipe",
    designation: "PIP203X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "43,1",
    d_global: 203,
    i_global: "2072,43",
    t_global: 7,
    z_global: "269,06",
  },
  {
    profile_section_id: 4950,
    name: "PIP203X7.5",
    type: "Pipe",
    designation: "PIP203X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "46,06",
    d_global: 203,
    i_global: "2203,94",
    t_global: "7,5",
    z_global: "286,8",
  },
  {
    profile_section_id: 4951,
    name: "PIP203X8.0",
    type: "Pipe",
    designation: "PIP203X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "49,01",
    d_global: 203,
    i_global: "2333,37",
    t_global: 8,
    z_global: "304,39",
  },
  {
    profile_section_id: 4952,
    name: "PIP203X9.0",
    type: "Pipe",
    designation: "PIP203X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "54,85",
    d_global: 203,
    i_global: "2586,08",
    t_global: 9,
    z_global: "338,91",
  },
  {
    profile_section_id: 4953,
    name: "PIP203X10.0",
    type: "Pipe",
    designation: "PIP203X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "60,63",
    d_global: 203,
    i_global: "2830,72",
    t_global: 10,
    z_global: "372,7",
  },
  {
    profile_section_id: 4954,
    name: "PIP203X12.0",
    type: "Pipe",
    designation: "PIP203X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "72,01",
    d_global: 203,
    i_global: "3296,49",
    t_global: 12,
    z_global: "438,06",
  },
  {
    profile_section_id: 4955,
    name: "PIP203X14.0",
    type: "Pipe",
    designation: "PIP203X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "83,13",
    d_global: 203,
    i_global: "3732,07",
    t_global: 14,
    z_global: "500,41",
  },
  {
    profile_section_id: 4956,
    name: "PIP203X16.0",
    type: "Pipe",
    designation: "PIP203X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: 94,
    d_global: 203,
    i_global: "4138,78",
    t_global: 16,
    z_global: "559,86",
  },
  {
    profile_section_id: 4957,
    name: "PIP219X6.0",
    type: "Pipe",
    designation: "PIP219X6.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "40,15",
    d_global: 219,
    i_global: "2278,74",
    t_global: 6,
    z_global: "272,38",
  },
  {
    profile_section_id: 4958,
    name: "PIP219X6.5",
    type: "Pipe",
    designation: "PIP219X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "43,39",
    d_global: 219,
    i_global: "2451,64",
    t_global: "6,5",
    z_global: "293,67",
  },
  {
    profile_section_id: 4959,
    name: "PIP219X7.0",
    type: "Pipe",
    designation: "PIP219X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "46,62",
    d_global: 219,
    i_global: "2622,04",
    t_global: 7,
    z_global: "314,79",
  },
  {
    profile_section_id: 4960,
    name: "PIP219X7.5",
    type: "Pipe",
    designation: "PIP219X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "49,83",
    d_global: 219,
    i_global: "2789,96",
    t_global: "7,5",
    z_global: "335,67",
  },
  {
    profile_section_id: 4961,
    name: "PIP219X8.0",
    type: "Pipe",
    designation: "PIP219X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "53,03",
    d_global: 219,
    i_global: "2955,43",
    t_global: 8,
    z_global: "356,38",
  },
  {
    profile_section_id: 4962,
    name: "PIP219X9.0",
    type: "Pipe",
    designation: "PIP219X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "59,38",
    d_global: 219,
    i_global: "3279,12",
    t_global: 9,
    z_global: "397,16",
  },
  {
    profile_section_id: 4963,
    name: "PIP219X10.0",
    type: "Pipe",
    designation: "PIP219X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "65,66",
    d_global: 219,
    i_global: "3593,29",
    t_global: 10,
    z_global: "437,08",
  },
  {
    profile_section_id: 4964,
    name: "PIP219X12.0",
    type: "Pipe",
    designation: "PIP219X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "78,04",
    d_global: 219,
    i_global: "4193,81",
    t_global: 12,
    z_global: "514,51",
  },
  {
    profile_section_id: 4965,
    name: "PIP219X14.0",
    type: "Pipe",
    designation: "PIP219X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "90,16",
    d_global: 219,
    i_global: "4758,5",
    t_global: 14,
    z_global: "588,68",
  },
  {
    profile_section_id: 4966,
    name: "PIP219X16.0",
    type: "Pipe",
    designation: "PIP219X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "102,04",
    d_global: 219,
    i_global: "5288,81",
    t_global: 16,
    z_global: "659,75",
  },
  {
    profile_section_id: 4967,
    name: "PIP245X6.5",
    type: "Pipe",
    designation: "PIP245X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "48,7",
    d_global: 245,
    i_global: "3465,46",
    t_global: "6,5",
    z_global: "369,94",
  },
  {
    profile_section_id: 4968,
    name: "PIP245X7.0",
    type: "Pipe",
    designation: "PIP245X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "52,34",
    d_global: 245,
    i_global: "3709,06",
    t_global: 7,
    z_global: "396,75",
  },
  {
    profile_section_id: 4969,
    name: "PIP245X7.5",
    type: "Pipe",
    designation: "PIP245X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "55,96",
    d_global: 245,
    i_global: "3949,52",
    t_global: "7,5",
    z_global: "423,3",
  },
  {
    profile_section_id: 4970,
    name: "PIP245X8.0",
    type: "Pipe",
    designation: "PIP245X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "59,56",
    d_global: 245,
    i_global: "4186,87",
    t_global: 8,
    z_global: "449,59",
  },
  {
    profile_section_id: 4971,
    name: "PIP245X9.0",
    type: "Pipe",
    designation: "PIP245X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "66,73",
    d_global: 245,
    i_global: "4652,32",
    t_global: 9,
    z_global: "501,58",
  },
  {
    profile_section_id: 4972,
    name: "PIP245X10.0",
    type: "Pipe",
    designation: "PIP245X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "73,83",
    d_global: 245,
    i_global: "5105,63",
    t_global: 10,
    z_global: "552,6",
  },
  {
    profile_section_id: 4973,
    name: "PIP245X12.0",
    type: "Pipe",
    designation: "PIP245X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "87,84",
    d_global: 245,
    i_global: "5976,67",
    t_global: 12,
    z_global: "651,87",
  },
  {
    profile_section_id: 4974,
    name: "PIP245X14.0",
    type: "Pipe",
    designation: "PIP245X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "101,6",
    d_global: 245,
    i_global: "6801,68",
    t_global: 14,
    z_global: "747,51",
  },
  {
    profile_section_id: 4975,
    name: "PIP245X16.0",
    type: "Pipe",
    designation: "PIP245X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "115,11",
    d_global: 245,
    i_global: "7582,3",
    t_global: 16,
    z_global: "839,57",
  },
  {
    profile_section_id: 4976,
    name: "PIP273X6.5",
    type: "Pipe",
    designation: "PIP273X6.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "54,42",
    d_global: 273,
    i_global: "4834,18",
    t_global: "6,5",
    z_global: "461,92",
  },
  {
    profile_section_id: 4977,
    name: "PIP273X7.0",
    type: "Pipe",
    designation: "PIP273X7.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "58,5",
    d_global: 273,
    i_global: "5177,3",
    t_global: 7,
    z_global: "495,62",
  },
  {
    profile_section_id: 4978,
    name: "PIP273X7.5",
    type: "Pipe",
    designation: "PIP273X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "62,56",
    d_global: 273,
    i_global: "5516,47",
    t_global: "7,5",
    z_global: "529,02",
  },
  {
    profile_section_id: 4979,
    name: "PIP273X8.0",
    type: "Pipe",
    designation: "PIP273X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "66,6",
    d_global: 273,
    i_global: "5851,71",
    t_global: 8,
    z_global: "562,12",
  },
  {
    profile_section_id: 4980,
    name: "PIP273X9.0",
    type: "Pipe",
    designation: "PIP273X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "74,64",
    d_global: 273,
    i_global: "6510,56",
    t_global: 9,
    z_global: "627,6",
  },
  {
    profile_section_id: 4981,
    name: "PIP273X10.0",
    type: "Pipe",
    designation: "PIP273X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "82,62",
    d_global: 273,
    i_global: "7154,09",
    t_global: 10,
    z_global: "692,07",
  },
  {
    profile_section_id: 4982,
    name: "PIP273X12.0",
    type: "Pipe",
    designation: "PIP273X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "98,39",
    d_global: 273,
    i_global: "8396,14",
    t_global: 12,
    z_global: "817,9",
  },
  {
    profile_section_id: 4983,
    name: "PIP273X14.0",
    type: "Pipe",
    designation: "PIP273X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "114,91",
    d_global: 273,
    i_global: "9579,75",
    t_global: 14,
    z_global: "939,66",
  },
  {
    profile_section_id: 4984,
    name: "PIP273X16.0",
    type: "Pipe",
    designation: "PIP273X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "129,18",
    d_global: 273,
    i_global: "10706,79",
    t_global: 16,
    z_global: "1057,4",
  },
  {
    profile_section_id: 4985,
    name: "PIP299X7.5",
    type: "Pipe",
    designation: "PIP299X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "68,68",
    d_global: 299,
    i_global: "7300,02",
    t_global: "7,5",
    z_global: "637,64",
  },
  {
    profile_section_id: 4986,
    name: "PIP299X8.0",
    type: "Pipe",
    designation: "PIP299X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "73,14",
    d_global: 299,
    i_global: "7747,42",
    t_global: 8,
    z_global: "677,89",
  },
  {
    profile_section_id: 4987,
    name: "PIP299X9.0",
    type: "Pipe",
    designation: "PIP299X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: 82,
    d_global: 299,
    i_global: "8628,09",
    t_global: 9,
    z_global: "757,39",
  },
  {
    profile_section_id: 4988,
    name: "PIP299X10.0",
    type: "Pipe",
    designation: "PIP299X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "90,79",
    d_global: 299,
    i_global: "9490,15",
    t_global: 10,
    z_global: "835,69",
  },
  {
    profile_section_id: 4989,
    name: "PIP299X12.0",
    type: "Pipe",
    designation: "PIP299X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "108,2",
    d_global: 299,
    i_global: "11159,52",
    t_global: 12,
    z_global: "989,05",
  },
  {
    profile_section_id: 4990,
    name: "PIP299X14.0",
    type: "Pipe",
    designation: "PIP299X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "125,35",
    d_global: 299,
    i_global: "12757,61",
    t_global: 14,
    z_global: "1137,83",
  },
  {
    profile_section_id: 4991,
    name: "PIP299X16.0",
    type: "Pipe",
    designation: "PIP299X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "142,25",
    d_global: 299,
    i_global: "14286,48",
    t_global: 16,
    z_global: "1282,18",
  },
  {
    profile_section_id: 4992,
    name: "PIP325X7.5",
    type: "Pipe",
    designation: "PIP325X7.5",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "74,81",
    d_global: 325,
    i_global: "9431,8",
    t_global: "7,5",
    z_global: "756,51",
  },
  {
    profile_section_id: 4993,
    name: "PIP325X8.0",
    type: "Pipe",
    designation: "PIP325X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "79,67",
    d_global: 325,
    i_global: "10013,92",
    t_global: 8,
    z_global: "804,38",
  },
  {
    profile_section_id: 4994,
    name: "PIP325X9.0",
    type: "Pipe",
    designation: "PIP325X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "89,35",
    d_global: 325,
    i_global: "11161,33",
    t_global: 9,
    z_global: "899,27",
  },
  {
    profile_section_id: 4995,
    name: "PIP325X10.0",
    type: "Pipe",
    designation: "PIP325X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "98,96",
    d_global: 325,
    i_global: "12286,52",
    t_global: 10,
    z_global: "992,84",
  },
  {
    profile_section_id: 4996,
    name: "PIP325X12.0",
    type: "Pipe",
    designation: "PIP325X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: 118,
    d_global: 325,
    i_global: "14471,45",
    t_global: 12,
    z_global: "1176,35",
  },
  {
    profile_section_id: 4997,
    name: "PIP325X14.0",
    type: "Pipe",
    designation: "PIP325X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "136,78",
    d_global: 325,
    i_global: "16570,98",
    t_global: 14,
    z_global: "1354,85",
  },
  {
    profile_section_id: 4998,
    name: "PIP325X16.0",
    type: "Pipe",
    designation: "PIP325X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "155,32",
    d_global: 325,
    i_global: "18587,38",
    t_global: 16,
    z_global: "1528,61",
  },
  {
    profile_section_id: 4999,
    name: "PIP351X8.0",
    type: "Pipe",
    designation: "PIP351X8.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "86,21",
    d_global: 351,
    i_global: "12684,36",
    t_global: 8,
    z_global: "941,8",
  },
  {
    profile_section_id: 5000,
    name: "PIP351X9.0",
    type: "Pipe",
    designation: "PIP351X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "96,7",
    d_global: 351,
    i_global: "14147,55",
    t_global: 9,
    z_global: "1053,32",
  },
  {
    profile_section_id: 5001,
    name: "PIP351X10.0",
    type: "Pipe",
    designation: "PIP351X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "107,13",
    d_global: 351,
    i_global: "15584,62",
    t_global: 10,
    z_global: "1163,52",
  },
  {
    profile_section_id: 5002,
    name: "PIP351X12.0",
    type: "Pipe",
    designation: "PIP351X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "127,8",
    d_global: 351,
    i_global: "18381,63",
    t_global: 12,
    z_global: "1379,88",
  },
  {
    profile_section_id: 5003,
    name: "PIP351X14.0",
    type: "Pipe",
    designation: "PIP351X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "148,22",
    d_global: 351,
    i_global: "21077,86",
    t_global: 14,
    z_global: "1590,91",
  },
  {
    profile_section_id: 5004,
    name: "PIP351X16.0",
    type: "Pipe",
    designation: "PIP351X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "168,39",
    d_global: 351,
    i_global: "23675,75",
    t_global: 16,
    z_global: "1796,68",
  },
  {
    profile_section_id: 5005,
    name: "PIP377X9.0",
    type: "Pipe",
    designation: "PIP377X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: 104,
    d_global: 377,
    i_global: "17628,57",
    t_global: 9,
    z_global: "1218,93",
  },
  {
    profile_section_id: 5006,
    name: "PIP377X10.0",
    type: "Pipe",
    designation: "PIP377X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "115,24",
    d_global: 377,
    i_global: "19430,86",
    t_global: 10,
    z_global: "1347,01",
  },
  {
    profile_section_id: 5007,
    name: "PIP377X11.0",
    type: "Pipe",
    designation: "PIP377X11.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "126,42",
    d_global: 377,
    i_global: "21203,11",
    t_global: 11,
    z_global: "1473,65",
  },
  {
    profile_section_id: 5008,
    name: "PIP377X12.0",
    type: "Pipe",
    designation: "PIP377X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "137,53",
    d_global: 377,
    i_global: "22945,66",
    t_global: 12,
    z_global: "1598,84",
  },
  {
    profile_section_id: 5009,
    name: "PIP377X13.0",
    type: "Pipe",
    designation: "PIP377X13.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "148,59",
    d_global: 377,
    i_global: "24658,84",
    t_global: 13,
    z_global: "1722,66",
  },
  {
    profile_section_id: 5010,
    name: "PIP377X14.0",
    type: "Pipe",
    designation: "PIP377X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "159,58",
    d_global: 377,
    i_global: "26342,98",
    t_global: 14,
    z_global: "1844,99",
  },
  {
    profile_section_id: 5011,
    name: "PIP377X15.0",
    type: "Pipe",
    designation: "PIP377X15.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "170,5",
    d_global: 377,
    i_global: "27998,42",
    t_global: 15,
    z_global: "1965,84",
  },
  {
    profile_section_id: 5012,
    name: "PIP377X16.0",
    type: "Pipe",
    designation: "PIP377X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "181,37",
    d_global: 377,
    i_global: "29625,48",
    t_global: 16,
    z_global: "2085,32",
  },
  {
    profile_section_id: 5013,
    name: "PIP402X9.0",
    type: "Pipe",
    designation: "PIP402X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "111,06",
    d_global: 402,
    i_global: "21469,37",
    t_global: 9,
    z_global: "1390,17",
  },
  {
    profile_section_id: 5014,
    name: "PIP402X10.0",
    type: "Pipe",
    designation: "PIP402X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "123,09",
    d_global: 402,
    i_global: "23676,21",
    t_global: 10,
    z_global: "1536,78",
  },
  {
    profile_section_id: 5015,
    name: "PIP402X11.0",
    type: "Pipe",
    designation: "PIP402X11.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "135,05",
    d_global: 402,
    i_global: "25848,66",
    t_global: 11,
    z_global: "1681,84",
  },
  {
    profile_section_id: 5016,
    name: "PIP402X12.0",
    type: "Pipe",
    designation: "PIP402X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "146,95",
    d_global: 402,
    i_global: "27987,08",
    t_global: 12,
    z_global: "1825,36",
  },
  {
    profile_section_id: 5017,
    name: "PIP402X13.0",
    type: "Pipe",
    designation: "PIP402X13.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "158,79",
    d_global: 402,
    i_global: "30091,82",
    t_global: 13,
    z_global: "1967,35",
  },
  {
    profile_section_id: 5018,
    name: "PIP402X14.0",
    type: "Pipe",
    designation: "PIP402X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "170,56",
    d_global: 402,
    i_global: "32163,24",
    t_global: 14,
    z_global: "2107,81",
  },
  {
    profile_section_id: 5019,
    name: "PIP402X15.0",
    type: "Pipe",
    designation: "PIP402X15.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "182,28",
    d_global: 402,
    i_global: "34201,69",
    t_global: 15,
    z_global: "2246,74",
  },
  {
    profile_section_id: 5020,
    name: "PIP402X16.0",
    type: "Pipe",
    designation: "PIP402X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "193,93",
    d_global: 402,
    i_global: "36207,53",
    t_global: 16,
    z_global: "2384,15",
  },
  {
    profile_section_id: 5021,
    name: "PIP426X9.0",
    type: "Pipe",
    designation: "PIP426X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "117,84",
    d_global: 426,
    i_global: "25646,28",
    t_global: 9,
    z_global: "1565,14",
  },
  {
    profile_section_id: 5022,
    name: "PIP426X10.0",
    type: "Pipe",
    designation: "PIP426X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "130,62",
    d_global: 426,
    i_global: "28294,52",
    t_global: 10,
    z_global: "1730,72",
  },
  {
    profile_section_id: 5023,
    name: "PIP426X11.0",
    type: "Pipe",
    designation: "PIP426X11.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "143,34",
    d_global: 426,
    i_global: "30903,91",
    t_global: 11,
    z_global: "1894,65",
  },
  {
    profile_section_id: 5024,
    name: "PIP426X12.0",
    type: "Pipe",
    designation: "PIP426X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: 156,
    d_global: 426,
    i_global: "33474,84",
    t_global: 12,
    z_global: "2056,94",
  },
  {
    profile_section_id: 5025,
    name: "PIP426X13.0",
    type: "Pipe",
    designation: "PIP426X13.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "168,59",
    d_global: 426,
    i_global: "36007,67",
    t_global: 13,
    z_global: "2217,6",
  },
  {
    profile_section_id: 5026,
    name: "PIP426X14.0",
    type: "Pipe",
    designation: "PIP426X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "181,12",
    d_global: 426,
    i_global: "38502,8",
    t_global: 14,
    z_global: "2376,63",
  },
  {
    profile_section_id: 5027,
    name: "PIP426X15.0",
    type: "Pipe",
    designation: "PIP426X15.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "193,58",
    d_global: 426,
    i_global: "40960,6",
    t_global: 15,
    z_global: "2534,04",
  },
  {
    profile_section_id: 5028,
    name: "PIP426X16.0",
    type: "Pipe",
    designation: "PIP426X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "205,98",
    d_global: 426,
    i_global: "43381,44",
    t_global: 16,
    z_global: "2689,84",
  },
  {
    profile_section_id: 5029,
    name: "PIP450X9.0",
    type: "Pipe",
    designation: "PIP450X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "124,63",
    d_global: 450,
    i_global: "30332,67",
    t_global: 9,
    z_global: "1750,49",
  },
  {
    profile_section_id: 5030,
    name: "PIP450X10.0",
    type: "Pipe",
    designation: "PIP450X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "138,61",
    d_global: 450,
    i_global: "33477,56",
    t_global: 10,
    z_global: "1942,48",
  },
  {
    profile_section_id: 5031,
    name: "PIP450X11.0",
    type: "Pipe",
    designation: "PIP450X11.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "151,63",
    d_global: 450,
    i_global: "36578,87",
    t_global: 11,
    z_global: "2120,12",
  },
  {
    profile_section_id: 5032,
    name: "PIP450X12.0",
    type: "Pipe",
    designation: "PIP450X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "165,04",
    d_global: 450,
    i_global: "39637,01",
    t_global: 12,
    z_global: "2302,34",
  },
  {
    profile_section_id: 5033,
    name: "PIP450X13.0",
    type: "Pipe",
    designation: "PIP450X13.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "178,38",
    d_global: 450,
    i_global: "42652,38",
    t_global: 13,
    z_global: "2482,82",
  },
  {
    profile_section_id: 5034,
    name: "PIP450X14.0",
    type: "Pipe",
    designation: "PIP450X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "191,67",
    d_global: 450,
    i_global: "45625,38",
    t_global: 14,
    z_global: "2661,58",
  },
  {
    profile_section_id: 5035,
    name: "PIP450X15.0",
    type: "Pipe",
    designation: "PIP450X15.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "204,89",
    d_global: 450,
    i_global: "48556,41",
    t_global: 15,
    z_global: "2838,63",
  },
  {
    profile_section_id: 5036,
    name: "PIP450X16.0",
    type: "Pipe",
    designation: "PIP450X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "218,04",
    d_global: 450,
    i_global: "51445,87",
    t_global: 16,
    z_global: "3013,97",
  },
  {
    profile_section_id: 5037,
    name: "PIP480X9.0",
    type: "Pipe",
    designation: "PIP480X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "133,11",
    d_global: 480,
    i_global: "36951,77",
    t_global: 9,
    z_global: "1996,83",
  },
  {
    profile_section_id: 5038,
    name: "PIP480X10.0",
    type: "Pipe",
    designation: "PIP480X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "147,58",
    d_global: 480,
    i_global: "40800,14",
    t_global: 10,
    z_global: "2209,2",
  },
  {
    profile_section_id: 5039,
    name: "PIP480X11.0",
    type: "Pipe",
    designation: "PIP480X11.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "161,99",
    d_global: 480,
    i_global: "44598,63",
    t_global: 11,
    z_global: "2419,79",
  },
  {
    profile_section_id: 5040,
    name: "PIP480X12.0",
    type: "Pipe",
    designation: "PIP480X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "176,34",
    d_global: 480,
    i_global: "48347,69",
    t_global: 12,
    z_global: "2628,53",
  },
  {
    profile_section_id: 5041,
    name: "PIP480X13.0",
    type: "Pipe",
    designation: "PIP480X13.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "190,63",
    d_global: 480,
    i_global: "52047,74",
    t_global: 13,
    z_global: "2835,41",
  },
  {
    profile_section_id: 5042,
    name: "PIP480X14.0",
    type: "Pipe",
    designation: "PIP480X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "204,85",
    d_global: 480,
    i_global: "55699,21",
    t_global: 14,
    z_global: "3040,46",
  },
  {
    profile_section_id: 5043,
    name: "PIP480X15.0",
    type: "Pipe",
    designation: "PIP480X15.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "219,02",
    d_global: 480,
    i_global: "59302,54",
    t_global: 15,
    z_global: "3243,67",
  },
  {
    profile_section_id: 5044,
    name: "PIP480X16.0",
    type: "Pipe",
    designation: "PIP480X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "233,11",
    d_global: 480,
    i_global: "62858,14",
    t_global: 16,
    z_global: "3445,05",
  },
  {
    profile_section_id: 5045,
    name: "PIP500X9.0",
    type: "Pipe",
    designation: "PIP500X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "138,76",
    d_global: 500,
    i_global: "41860,49",
    t_global: 9,
    z_global: "2169,92",
  },
  {
    profile_section_id: 5046,
    name: "PIP500X10.0",
    type: "Pipe",
    designation: "PIP500X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "153,86",
    d_global: 500,
    i_global: "46231,77",
    t_global: 10,
    z_global: "2401,22",
  },
  {
    profile_section_id: 5047,
    name: "PIP500X11.0",
    type: "Pipe",
    designation: "PIP500X11.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "168,9",
    d_global: 500,
    i_global: "50548,75",
    t_global: 11,
    z_global: "2630,57",
  },
  {
    profile_section_id: 5048,
    name: "PIP500X12.0",
    type: "Pipe",
    designation: "PIP500X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "183,88",
    d_global: 500,
    i_global: "54811,88",
    t_global: 12,
    z_global: "2857,99",
  },
  {
    profile_section_id: 5049,
    name: "PIP500X13.0",
    type: "Pipe",
    designation: "PIP500X13.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "198,79",
    d_global: 500,
    i_global: "59021,61",
    t_global: 13,
    z_global: "3083,47",
  },
  {
    profile_section_id: 5050,
    name: "PIP500X14.0",
    type: "Pipe",
    designation: "PIP500X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "213,65",
    d_global: 500,
    i_global: "63178,39",
    t_global: 14,
    z_global: "3307,04",
  },
  {
    profile_section_id: 5051,
    name: "PIP500X15.0",
    type: "Pipe",
    designation: "PIP500X15.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "228,44",
    d_global: 500,
    i_global: "67282,66",
    t_global: 15,
    z_global: "3528,69",
  },
  {
    profile_section_id: 5052,
    name: "PIP500X16.0",
    type: "Pipe",
    designation: "PIP500X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "243,16",
    d_global: 500,
    i_global: "71334,87",
    t_global: 16,
    z_global: "3748,43",
  },
  {
    profile_section_id: 5053,
    name: "PIP530X9.0",
    type: "Pipe",
    designation: "PIP530X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "147,23",
    d_global: 530,
    i_global: "50009,99",
    t_global: 9,
    z_global: "2443,19",
  },
  {
    profile_section_id: 5054,
    name: "PIP530X10.0",
    type: "Pipe",
    designation: "PIP530X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "163,28",
    d_global: 530,
    i_global: "55251,25",
    t_global: 10,
    z_global: "2704,24",
  },
  {
    profile_section_id: 5055,
    name: "PIP530X11.0",
    type: "Pipe",
    designation: "PIP530X11.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "179,26",
    d_global: 530,
    i_global: "60431,21",
    t_global: 11,
    z_global: "2963,24",
  },
  {
    profile_section_id: 5056,
    name: "PIP530X12.0",
    type: "Pipe",
    designation: "PIP530X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "195,18",
    d_global: 530,
    i_global: "65550,35",
    t_global: 12,
    z_global: "3220,18",
  },
  {
    profile_section_id: 5057,
    name: "PIP530X13.0",
    type: "Pipe",
    designation: "PIP530X13.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "211,04",
    d_global: 530,
    i_global: "70609,15",
    t_global: 13,
    z_global: "3475,07",
  },
  {
    profile_section_id: 5058,
    name: "PIP530X14.0",
    type: "Pipe",
    designation: "PIP530X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "226,83",
    d_global: 530,
    i_global: "75608,08",
    t_global: 14,
    z_global: "3727,92",
  },
  {
    profile_section_id: 5059,
    name: "PIP530X15.0",
    type: "Pipe",
    designation: "PIP530X15.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "242,57",
    d_global: 530,
    i_global: "80547,62",
    t_global: 15,
    z_global: "3978,73",
  },
  {
    profile_section_id: 5060,
    name: "PIP530X16.0",
    type: "Pipe",
    designation: "PIP530X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "258,23",
    d_global: 530,
    i_global: "85428,24",
    t_global: 16,
    z_global: "4227,52",
  },
  {
    profile_section_id: 5061,
    name: "PIP560X9.0",
    type: "Pipe",
    designation: "PIP560X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "155,71",
    d_global: 560,
    i_global: "59154,07",
    t_global: 9,
    z_global: "2732,66",
  },
  {
    profile_section_id: 5062,
    name: "PIP560X10.0",
    type: "Pipe",
    designation: "PIP560X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "172,7",
    d_global: 560,
    i_global: "65373,7",
    t_global: 10,
    z_global: "3025,27",
  },
  {
    profile_section_id: 5063,
    name: "PIP560X11.0",
    type: "Pipe",
    designation: "PIP560X11.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "189,62",
    d_global: 560,
    i_global: "71524,61",
    t_global: 11,
    z_global: "3315,71",
  },
  {
    profile_section_id: 5064,
    name: "PIP560X12.0",
    type: "Pipe",
    designation: "PIP560X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "206,49",
    d_global: 560,
    i_global: "77607,3",
    t_global: 12,
    z_global: "3603,97",
  },
  {
    profile_section_id: 5065,
    name: "PIP560X13.0",
    type: "Pipe",
    designation: "PIP560X13.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "223,29",
    d_global: 560,
    i_global: "83622,29",
    t_global: 13,
    z_global: "3890,07",
  },
  {
    profile_section_id: 5066,
    name: "PIP560X14.0",
    type: "Pipe",
    designation: "PIP560X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "240,02",
    d_global: 560,
    i_global: "89570,06",
    t_global: 14,
    z_global: 4174,
  },
  {
    profile_section_id: 5067,
    name: "PIP560X15.0",
    type: "Pipe",
    designation: "PIP560X15.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "256,7",
    d_global: 560,
    i_global: "95451,14",
    t_global: 15,
    z_global: "4455,78",
  },
  {
    profile_section_id: 5068,
    name: "PIP560X16.0",
    type: "Pipe",
    designation: "PIP560X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "273,31",
    d_global: 560,
    i_global: 101266,
    t_global: 16,
    z_global: "4735,4",
  },
  {
    profile_section_id: 5069,
    name: "PIP630X9.0",
    type: "Pipe",
    designation: "PIP630X9.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "175,5",
    d_global: 630,
    i_global: "84679,83",
    t_global: 9,
    z_global: "3471,19",
  },
  {
    profile_section_id: 5070,
    name: "PIP630X10.0",
    type: "Pipe",
    designation: "PIP630X10.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "194,68",
    d_global: 630,
    i_global: "93639,59",
    t_global: 10,
    z_global: "3844,35",
  },
  {
    profile_section_id: 5071,
    name: "PIP630X11.0",
    type: "Pipe",
    designation: "PIP630X11.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "213,8",
    d_global: 630,
    i_global: "102511,6",
    t_global: 11,
    z_global: "4215,15",
  },
  {
    profile_section_id: 5072,
    name: "PIP630X12.0",
    type: "Pipe",
    designation: "PIP630X12.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "232,86",
    d_global: 630,
    i_global: "111296,6",
    t_global: 12,
    z_global: "4583,5",
  },
  {
    profile_section_id: 5073,
    name: "PIP630X13.0",
    type: "Pipe",
    designation: "PIP630X13.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "251,86",
    d_global: 630,
    i_global: 119995,
    t_global: 13,
    z_global: "4949,4",
  },
  {
    profile_section_id: 5074,
    name: "PIP630X14.0",
    type: "Pipe",
    designation: "PIP630X14.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "270,79",
    d_global: 630,
    i_global: "128607,4",
    t_global: 14,
    z_global: "5312,86",
  },
  {
    profile_section_id: 5075,
    name: "PIP630X15.0",
    type: "Pipe",
    designation: "PIP630X15.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "289,67",
    d_global: 630,
    i_global: "137134,4",
    t_global: 15,
    z_global: "5673,89",
  },
  {
    profile_section_id: 5076,
    name: "PIP630X16.0",
    type: "Pipe",
    designation: "PIP630X16.0",
    shape: "PIPE",
    country_code: "Chinese",
    ax_global: "308,47",
    d_global: 630,
    i_global: "145576,5",
    t_global: 16,
    z_global: "6032,48",
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: null,
    bf: null,
    c: null,
    ct: null,
    d: null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: null,
    i: null,
    i_global: p.i_global ? fixValueToNumber(p.i_global, "float") : undefined,
    ix: null,
    iy: null,
    iz: null,
    k: null,
    k1: null,
    od: null,
    r1: null,
    r2: null,
    rz: null,
    t: null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: null,
    tw: null,
    z: null,
    z_global: p.z_global ? fixValueToNumber(p.z_global, "float") : undefined,
    zx: null,
    zy: null,
    zz: null,
    width: 0,
    width_global: 0,
    thickness: 0,
    thickness_global: 0,
    height: 0,
    height_global: 0,
  };
  return profile;
});
