import { fixValueToNumber } from "../../components/3d-models/utils";

export const pipingSlipOnFlanges = [
  {
    piping_flange_id: 1,
    dn: 25,
    nps: 1,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    b_mini: "34,5",
    tt: "6,4",
    c_mini: "38,1",
    y: "47,8",
    x: 54,
    r: "50,8",
    weight: 3,
  },
  {
    piping_flange_id: 2,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    b_mini: "49,5",
    tt: "6,4",
    c_mini: "38,1",
    y: "47,8",
    x: 70,
    r: 73,
    weight: 5,
  },
  {
    piping_flange_id: 3,
    dn: 50,
    nps: 2,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    b_mini: 62,
    tt: "6,4",
    c_mini: "38,1",
    y: "49,3",
    x: 84,
    r: "92,1",
    weight: "5,5",
  },
  {
    piping_flange_id: 4,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    b_mini: "74,7",
    tt: "6,4",
    c_mini: "38,1",
    y: "50,8",
    x: 100,
    r: "104,8",
    weight: "6,2",
  },
  {
    piping_flange_id: 5,
    dn: 80,
    nps: 3,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    b_mini: "90,7",
    tt: "9,5",
    c_mini: "38,1",
    y: "52,3",
    x: 117,
    r: 127,
    weight: 8,
  },
  {
    piping_flange_id: 6,
    dn: 100,
    nps: 4,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 254,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: 200,
    b_mini: "116,1",
    tt: "12,7",
    c_mini: "38,1",
    y: "53,8",
    x: 146,
    r: "157,2",
    weight: "12,1",
  },
  {
    piping_flange_id: 7,
    dn: 150,
    nps: 6,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 318,
    dr_no: 12,
    dr_d: "22,2",
    dr_g: "269,9",
    b_mini: "170,7",
    tt: "12,7",
    c_mini: "38,1",
    y: "53,8",
    x: 206,
    r: "215,9",
    weight: "18,5",
  },
  {
    piping_flange_id: 8,
    dn: 200,
    nps: 8,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 381,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: "330,2",
    b_mini: "221,5",
    tt: "12,7",
    c_mini: "41,1",
    y: 62,
    x: 260,
    r: "269,9",
    weight: 26,
  },
  {
    piping_flange_id: 9,
    dn: 250,
    nps: 10,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 444,
    dr_no: 16,
    dr_d: "28,5",
    dr_g: "387,4",
    b_mini: "276,4",
    tt: "12,7",
    c_mini: "47,8",
    y: "66,5",
    x: 320,
    r: "323,8",
    weight: 38,
  },
  {
    piping_flange_id: 10,
    dn: 300,
    nps: 12,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 521,
    dr_no: 16,
    dr_d: "31,8",
    dr_g: "450,8",
    b_mini: "327,2",
    tt: "12,7",
    c_mini: "50,8",
    y: "73,2",
    x: 375,
    r: 381,
    weight: 52,
  },
  {
    piping_flange_id: 11,
    dn: 350,
    nps: 14,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 584,
    dr_no: 20,
    dr_d: "31,8",
    dr_g: "514,4",
    b_mini: "359,2",
    tt: "12,7",
    c_mini: "53,8",
    y: "76,2",
    x: 425,
    r: "412,8",
    weight: 74,
  },
  {
    piping_flange_id: 12,
    dn: 400,
    nps: 16,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 648,
    dr_no: 20,
    dr_d: 35,
    dr_g: "571,5",
    b_mini: "410,5",
    tt: "12,7",
    c_mini: "57,2",
    y: "82,6",
    x: 483,
    r: "469,9",
    weight: 100,
  },
  {
    piping_flange_id: 13,
    dn: 450,
    nps: 18,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 711,
    dr_no: 24,
    dr_d: 35,
    dr_g: "628,6",
    b_mini: "461,8",
    tt: "12,7",
    c_mini: "60,5",
    y: "88,9",
    x: 533,
    r: "533,4",
    weight: 127,
  },
  {
    piping_flange_id: 14,
    dn: 500,
    nps: 20,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 775,
    dr_no: 24,
    dr_d: 35,
    dr_g: "685,8",
    b_mini: "513,1",
    tt: "12,7",
    c_mini: "63,5",
    y: "95,2",
    x: 587,
    r: "584,2",
    weight: 147,
  },
  {
    piping_flange_id: 15,
    dn: 600,
    nps: 24,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE SLIP ON",
    class: 300,
    o: 914,
    dr_no: 24,
    dr_d: "41,1",
    dr_g: "812,8",
    b_mini: 616,
    tt: "12,7",
    c_mini: "69,9",
    y: "106,4",
    x: 701,
    r: "692,2",
    weight: 208,
  },
].map((item) => ({
  ...item,
  nps: item.nps + "",
  o: fixValueToNumber(item.o, "float"),
  dr_d: fixValueToNumber(item.dr_d, "float"),
  dr_g: fixValueToNumber(item.dr_g, "float"),
  r: fixValueToNumber(item.r, "float"),
  x: fixValueToNumber(item.x, "float"),
  y: fixValueToNumber(item.y, "float"),
  tt: fixValueToNumber(item.tt, "float"),
  weight: fixValueToNumber(item.weight, "float"),
}));
