import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Card, Elevation, Icon, HTMLTable } from '@blueprintjs/core';
import './PointsModule.css';
import { secondServerAPI } from '../utils/agent';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

interface CreditDetails {
  availableCredits: number;
  latestPurchasedCredits: number;
  creditAvailableOn: string;
  creditAvailableTill: string;
}

export default function CreditChecker() {
  const [creditDetails, setCreditDetails] = useState<CreditDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const auth = useSelector((state: ApplicationState) => state.auth);

  useEffect(() => {
    Axios.get(`${secondServerAPI}/rest/api/v1/ufc/getUserCredits`, {
      headers: {
        // 'user-id': 1,
        'user-id': auth.User_id
      },
    })
      .then(response => {
        setCreditDetails(response.data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error fetching credit details:", error);
        setIsLoading(false);
      });
  }, []);

  const calculatePieChartValues = (availableCredits: number, latestPurchasedCredits: number) => {
    const totalCredits = availableCredits + latestPurchasedCredits;
    const availablePercentage = (availableCredits / totalCredits) * 100;
    return availablePercentage;
  };

  const availablePercentage = creditDetails
    ? calculatePieChartValues(creditDetails.availableCredits, creditDetails.latestPurchasedCredits)
    : 0;

  return (
    <div className="credit-checker">
      <Card className="credit-info-card" interactive={true} elevation={Elevation.TWO}>
        <h1>Credit Information</h1>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          creditDetails && (
            <>
              <HTMLTable striped bordered className="credit-details-table">
                <tbody>
                  <tr>
                    <td><Icon icon="dollar" /> Available Credits</td>
                    <td>{creditDetails.availableCredits}</td>
                  </tr>
                  <tr>
                    <td><Icon icon="credit-card" /> Latest Purchased Credits</td>
                    <td>{creditDetails.latestPurchasedCredits}</td>
                  </tr>
                  <tr>
                    <td><Icon icon="calendar" /> Credits Available From</td>
                    <td>{new Date(creditDetails.creditAvailableOn).toLocaleString()}</td>
                  </tr>
                  <tr>
                    <td><Icon icon="calendar" /> Credits Available Till</td>
                    <td>{new Date(creditDetails.creditAvailableTill).toLocaleString()}</td>
                  </tr>
                </tbody>
              </HTMLTable>
              {/* <div className="pie-chart-container">
                <svg viewBox="0 0 32 32" className="pie-chart">
                  <circle
                    r="16"
                    cx="16"
                    cy="16"
                    style={{
                      strokeDasharray: `${availablePercentage} ${100 - availablePercentage}`,
                      stroke: availablePercentage > 0 ? '#2b6cb0' : '#f56565',
                    }}
                  />
                </svg>
                <div className="pie-chart-legend">
                  <div><span className="legend-dot available"></span> Available Credits</div>
                  <div><span className="legend-dot used"></span> Used Credits</div>
                </div>
              </div> */}
            </>
          )
        )}
      </Card>
    </div>
  );
}
