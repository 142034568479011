import React, { useState, useEffect } from "react";
import { CustomDlg } from "../../common/CustomDlg";
import { Button } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { addEventAction } from "../../../store/ui/actions";
import { ApplicationState } from "../../../store";
import Axios from "axios";
import { setProjectResponse } from "../../../store/main/actions";
import { setProjectName } from "../../../store/main/actions";
import { secondServerAPI } from "../../../pages/utils/agent";

type Props = {
    onClose: () => any;
}

type Project = {
    projectId: number;
    projectName: string;
    // companyName: string;
    projectPhase: string;
}

export function GetLayouts(props: Props) {
    const { onClose } = props;
    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedProjectId, setSelectedProjectId] = useState<number | null>(null);
    const [run, setRun] = useState<Boolean>(true);
    const [projectResponse1, setProjectResponse1] = useState<any>({});

    const auth = useSelector((state: ApplicationState) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await Axios.get(`${secondServerAPI}rest/api/v1/dashboard/get/userandprojects`, {
                    headers: {
                        // "user-id": 1, 
                        "user-id" : auth.User_id,
                    },
                });
                if (response.status === 200) {
                    const projectsData = response.data.projectResponses.map((proj: any) => ({
                        projectId: proj.projectId,
                        projectName: proj.projectName,
                        projectPhase: proj.projectPhase,
                    }));
                    setProjects(projectsData);
                    // if (projectsData.length > 0) {
                    //     setSelectedProject(projectsData[0].projectName);
                    // }
                    // setProjects(response.data);
                    // console.log("Projects data", response.data);
                }
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        fetchProjects();
    }, []);

    useEffect(() => {
        handleGetProjectName();
        setRun(false);
    }, [run]);

    async function handleGetProjectName() {
        try {
            const response = await Axios.get(
                `${secondServerAPI}/rest/api/v1/layouts/getAllProjects`,
                {
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id": 1,
                    }
                }
            );
            if (Array.isArray(response.data)) {
                setProjects(response.data);
            } else {
                console.error("Expected array but received", response.data);
            }
        } catch (error) {
            console.error("Error fetching project names:", error);
        }
    }

    async function handleGetLayout() {
        if (selectedProjectId === null) {
            dispatch(
                addEventAction(
                    "Please select a project",
                    "warning"
                )
            );
        } else {
            try {
                const response = await Axios.get(
                    `${secondServerAPI}/rest/api/v1/layouts/getAllLayouts`,
                    {
                        headers: {
                            "project-id": selectedProjectId.toString(),
                            // "user-id": 1
                            "user-id" : auth.User_id,
                        }
                    }
                );
                setProjectResponse1(response.data); 
                dispatch(setProjectResponse(response.data));
                const selectedProject = projects.find(project => project.projectId === selectedProjectId);
                if (selectedProject) {
                    dispatch(setProjectName(selectedProject.projectName)); 
                }
            } catch (error) {
                dispatch(
                    addEventAction(
                        `${error} Could not retrieve layout. Please try again`,
                        "danger"
                    )
                );
            }
        }
    }

    return (
        <>
            <CustomDlg
                title={"Layout Data"}
                idText="Get Layouts"
                body={
                    <div>
                        <div className="d-flex f-column" style={{ margin: 5, padding: 15, backgroundColor: "black" }}>
                            <div className="d-flex f-row">
                                <div style={{ margin: 5, color: "white", fontSize: 14}}>Project Name: </div>
                                <select
                                    value={selectedProjectId || ""}
                                    onChange={(e) => setSelectedProjectId(Number(e.target.value))}
                                    className="bp3-select w-100"
                                >
                                    <option className="bp3-select w-100" value="">Select </option>
                                    {projects.map((project) => (
                                        <option key={project.projectId} value={project.projectId}>
                                            {project.projectName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <Button
                                className='bp3-intent-success bp3-large bp3-icon-cloud-download table-area-button'
                                text="Get Layouts"
                                onClick={handleGetLayout}
                                style={{ margin: 5 }}
                            />
                        </div>
                    </div>
                }
                onClose={onClose}
            />
        </>
    );
}
