import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesDutch2 = [
  {
    profile_section_id: 5972,
    name: "PIPBN132X30.5",
    type: "Pipe",
    designation: "PIPBN132X30.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "97,26",
    d_global: 132,
    i_global: "1365,53",
    t_global: "30,5",
    z_global: "206,9",
  },
  {
    profile_section_id: 5973,
    name: "PIPBN133X4",
    type: "Pipe",
    designation: "PIPBN133X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,21",
    d_global: 133,
    i_global: "337,52",
    t_global: 4,
    z_global: "50,76",
  },
  {
    profile_section_id: 5974,
    name: "PIPBN133X4.5",
    type: "Pipe",
    designation: "PIPBN133X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,17",
    d_global: 133,
    i_global: "375,42",
    t_global: "4,5",
    z_global: "56,45",
  },
  {
    profile_section_id: 5975,
    name: "PIPBN133X5",
    type: "Pipe",
    designation: "PIPBN133X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,11",
    d_global: 133,
    i_global: "412,4",
    t_global: 5,
    z_global: "62,02",
  },
  {
    profile_section_id: 5976,
    name: "PIPBN133X5.6",
    type: "Pipe",
    designation: "PIPBN133X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "22,41",
    d_global: 133,
    i_global: "455,61",
    t_global: "5,6",
    z_global: "68,51",
  },
  {
    profile_section_id: 5977,
    name: "PIPBN133X6.3",
    type: "Pipe",
    designation: "PIPBN133X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "25,08",
    d_global: 133,
    i_global: "504,43",
    t_global: "6,3",
    z_global: "75,85",
  },
  {
    profile_section_id: 5978,
    name: "PIPBN133X7.1",
    type: "Pipe",
    designation: "PIPBN133X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "28,08",
    d_global: 133,
    i_global: "558,18",
    t_global: "7,1",
    z_global: "83,94",
  },
  {
    profile_section_id: 5979,
    name: "PIPBN133X8",
    type: "Pipe",
    designation: "PIPBN133X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "31,42",
    d_global: 133,
    i_global: "616,11",
    t_global: 8,
    z_global: "92,65",
  },
  {
    profile_section_id: 5980,
    name: "PIPBN133X8.8",
    type: "Pipe",
    designation: "PIPBN133X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "34,34",
    d_global: 133,
    i_global: "665,4",
    t_global: "8,8",
    z_global: "100,06",
  },
  {
    profile_section_id: 5981,
    name: "PIPBN133X10",
    type: "Pipe",
    designation: "PIPBN133X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "38,64",
    d_global: 133,
    i_global: "735,59",
    t_global: 10,
    z_global: "110,61",
  },
  {
    profile_section_id: 5982,
    name: "PIPBN133X11",
    type: "Pipe",
    designation: "PIPBN133X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "42,16",
    d_global: 133,
    i_global: "790,77",
    t_global: 11,
    z_global: "118,91",
  },
  {
    profile_section_id: 5983,
    name: "PIPBN133X12.5",
    type: "Pipe",
    designation: "PIPBN133X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "47,32",
    d_global: 133,
    i_global: "868,12",
    t_global: "12,5",
    z_global: "130,54",
  },
  {
    profile_section_id: 5984,
    name: "PIPBN133X14.2",
    type: "Pipe",
    designation: "PIPBN133X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 53,
    d_global: 133,
    i_global: "948,33",
    t_global: "14,2",
    z_global: "142,61",
  },
  {
    profile_section_id: 5985,
    name: "PIPBN133X16",
    type: "Pipe",
    designation: "PIPBN133X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "58,81",
    d_global: 133,
    i_global: "1025,14",
    t_global: 16,
    z_global: "154,16",
  },
  {
    profile_section_id: 5986,
    name: "PIPBN133X17.5",
    type: "Pipe",
    designation: "PIPBN133X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "63,5",
    d_global: 133,
    i_global: "1083,18",
    t_global: "17,5",
    z_global: "162,88",
  },
  {
    profile_section_id: 5987,
    name: "PIPBN133X20",
    type: "Pipe",
    designation: "PIPBN133X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 71,
    d_global: 133,
    i_global: "1168,75",
    t_global: 20,
    z_global: "175,75",
  },
  {
    profile_section_id: 5988,
    name: "PIPBN133X22.2",
    type: "Pipe",
    designation: "PIPBN133X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "77,28",
    d_global: 133,
    i_global: "1233,46",
    t_global: "22,2",
    z_global: "185,48",
  },
  {
    profile_section_id: 5989,
    name: "PIPBN133X25",
    type: "Pipe",
    designation: "PIPBN133X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "84,82",
    d_global: 133,
    i_global: "1302,99",
    t_global: 25,
    z_global: "195,94",
  },
  {
    profile_section_id: 5990,
    name: "PIPBN133X28",
    type: "Pipe",
    designation: "PIPBN133X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "92,36",
    d_global: 133,
    i_global: "1363,39",
    t_global: 28,
    z_global: "205,02",
  },
  {
    profile_section_id: 5991,
    name: "PIPBN133X30",
    type: "Pipe",
    designation: "PIPBN133X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "97,08",
    d_global: 133,
    i_global: "1396,55",
    t_global: 30,
    z_global: "210,01",
  },
  {
    profile_section_id: 5992,
    name: "PIPBN133X32",
    type: "Pipe",
    designation: "PIPBN133X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "101,54",
    d_global: 133,
    i_global: "1424,68",
    t_global: 32,
    z_global: "214,24",
  },
  {
    profile_section_id: 5993,
    name: "PIPBN133X36",
    type: "Pipe",
    designation: "PIPBN133X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "109,7",
    d_global: 133,
    i_global: "1467,98",
    t_global: 36,
    z_global: "220,75",
  },
  {
    profile_section_id: 5994,
    name: "PIPBN133X40",
    type: "Pipe",
    designation: "PIPBN133X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "116,87",
    d_global: 133,
    i_global: "1497,22",
    t_global: 40,
    z_global: "225,15",
  },
  {
    profile_section_id: 5995,
    name: "PIPBN139.7X4",
    type: "Pipe",
    designation: "PIPBN139.7X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "17,05",
    d_global: "139,7",
    i_global: "392,86",
    t_global: 4,
    z_global: "56,24",
  },
  {
    profile_section_id: 5996,
    name: "PIPBN139.7X4.5",
    type: "Pipe",
    designation: "PIPBN139.7X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "19,11",
    d_global: "139,7",
    i_global: "437,2",
    t_global: "4,5",
    z_global: "62,59",
  },
  {
    profile_section_id: 5997,
    name: "PIPBN139.7X5",
    type: "Pipe",
    designation: "PIPBN139.7X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "21,16",
    d_global: "139,7",
    i_global: "480,54",
    t_global: 5,
    z_global: "68,8",
  },
  {
    profile_section_id: 5998,
    name: "PIPBN139.7X5.6",
    type: "Pipe",
    designation: "PIPBN139.7X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,59",
    d_global: "139,7",
    i_global: "531,24",
    t_global: "5,6",
    z_global: "76,05",
  },
  {
    profile_section_id: 5999,
    name: "PIPBN139.7X6.3",
    type: "Pipe",
    designation: "PIPBN139.7X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "26,4",
    d_global: "139,7",
    i_global: "588,62",
    t_global: "6,3",
    z_global: "84,27",
  },
  {
    profile_section_id: 6000,
    name: "PIPBN139.7X7.1",
    type: "Pipe",
    designation: "PIPBN139.7X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "29,58",
    d_global: "139,7",
    i_global: "651,92",
    t_global: "7,1",
    z_global: "93,33",
  },
  {
    profile_section_id: 6001,
    name: "PIPBN139.7X8",
    type: "Pipe",
    designation: "PIPBN139.7X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "33,1",
    d_global: "139,7",
    i_global: "720,29",
    t_global: 8,
    z_global: "103,12",
  },
  {
    profile_section_id: 6002,
    name: "PIPBN139.7X8.8",
    type: "Pipe",
    designation: "PIPBN139.7X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "36,19",
    d_global: "139,7",
    i_global: "778,61",
    t_global: "8,8",
    z_global: "111,47",
  },
  {
    profile_section_id: 6003,
    name: "PIPBN139.7X10",
    type: "Pipe",
    designation: "PIPBN139.7X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "40,75",
    d_global: "139,7",
    i_global: "861,89",
    t_global: 10,
    z_global: "123,39",
  },
  {
    profile_section_id: 6004,
    name: "PIPBN139.7X11",
    type: "Pipe",
    designation: "PIPBN139.7X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "44,48",
    d_global: "139,7",
    i_global: "927,58",
    t_global: 11,
    z_global: "132,79",
  },
  {
    profile_section_id: 6005,
    name: "PIPBN139.7X12.5",
    type: "Pipe",
    designation: "PIPBN139.7X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "49,95",
    d_global: "139,7",
    i_global: "1020,01",
    t_global: "12,5",
    z_global: "146,03",
  },
  {
    profile_section_id: 6006,
    name: "PIPBN139.7X14.2",
    type: "Pipe",
    designation: "PIPBN139.7X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "55,99",
    d_global: "139,7",
    i_global: "1116,36",
    t_global: "14,2",
    z_global: "159,82",
  },
  {
    profile_section_id: 6007,
    name: "PIPBN139.7X16",
    type: "Pipe",
    designation: "PIPBN139.7X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "62,18",
    d_global: "139,7",
    i_global: "1209,19",
    t_global: 16,
    z_global: "173,11",
  },
  {
    profile_section_id: 6008,
    name: "PIPBN139.7X17.5",
    type: "Pipe",
    designation: "PIPBN139.7X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "67,18",
    d_global: "139,7",
    i_global: "1279,76",
    t_global: "17,5",
    z_global: "183,22",
  },
  {
    profile_section_id: 6009,
    name: "PIPBN139.7X20",
    type: "Pipe",
    designation: "PIPBN139.7X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "75,21",
    d_global: "139,7",
    i_global: "1384,62",
    t_global: 20,
    z_global: "198,23",
  },
  {
    profile_section_id: 6010,
    name: "PIPBN139.7X22.2",
    type: "Pipe",
    designation: "PIPBN139.7X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "81,95",
    d_global: "139,7",
    i_global: "1464,73",
    t_global: "22,2",
    z_global: "209,7",
  },
  {
    profile_section_id: 6011,
    name: "PIPBN139.7X25",
    type: "Pipe",
    designation: "PIPBN139.7X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "90,09",
    d_global: "139,7",
    i_global: "1551,84",
    t_global: 25,
    z_global: "222,17",
  },
  {
    profile_section_id: 6012,
    name: "PIPBN139.7X28",
    type: "Pipe",
    designation: "PIPBN139.7X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "98,26",
    d_global: "139,7",
    i_global: "1628,71",
    t_global: 28,
    z_global: "233,17",
  },
  {
    profile_section_id: 6013,
    name: "PIPBN139.7X30",
    type: "Pipe",
    designation: "PIPBN139.7X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "103,39",
    d_global: "139,7",
    i_global: "1671,57",
    t_global: 30,
    z_global: "239,31",
  },
  {
    profile_section_id: 6014,
    name: "PIPBN139.7X32",
    type: "Pipe",
    designation: "PIPBN139.7X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "108,27",
    d_global: "139,7",
    i_global: "1708,43",
    t_global: 32,
    z_global: "244,59",
  },
  {
    profile_section_id: 6015,
    name: "PIPBN139.7X36",
    type: "Pipe",
    designation: "PIPBN139.7X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "117,28",
    d_global: "139,7",
    i_global: "1766,51",
    t_global: 36,
    z_global: "252,9",
  },
  {
    profile_section_id: 6016,
    name: "PIPBN139.7X40",
    type: "Pipe",
    designation: "PIPBN139.7X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "125,29",
    d_global: "139,7",
    i_global: "1807,28",
    t_global: 40,
    z_global: "258,74",
  },
  {
    profile_section_id: 6017,
    name: "PIPBN140X14",
    type: "Pipe",
    designation: "PIPBN140X14",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "55,42",
    d_global: 140,
    i_global: "1113,34",
    t_global: 14,
    z_global: "159,05",
  },
  {
    profile_section_id: 6018,
    name: "PIPBN140X20",
    type: "Pipe",
    designation: "PIPBN140X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "75,4",
    d_global: 140,
    i_global: "1394,87",
    t_global: 20,
    z_global: "199,27",
  },
  {
    profile_section_id: 6019,
    name: "PIPBN140X30",
    type: "Pipe",
    designation: "PIPBN140X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "103,67",
    d_global: 140,
    i_global: "1684,68",
    t_global: 30,
    z_global: "240,67",
  },
  {
    profile_section_id: 6020,
    name: "PIPBN146X4.5",
    type: "Pipe",
    designation: "PIPBN146X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 20,
    d_global: 146,
    i_global: "501,17",
    t_global: "4,5",
    z_global: "68,65",
  },
  {
    profile_section_id: 6021,
    name: "PIPBN146X5",
    type: "Pipe",
    designation: "PIPBN146X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "22,15",
    d_global: 146,
    i_global: "551,1",
    t_global: 5,
    z_global: "75,49",
  },
  {
    profile_section_id: 6022,
    name: "PIPBN146X5.6",
    type: "Pipe",
    designation: "PIPBN146X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "24,7",
    d_global: 146,
    i_global: "609,59",
    t_global: "5,6",
    z_global: "83,51",
  },
  {
    profile_section_id: 6023,
    name: "PIPBN146X6.3",
    type: "Pipe",
    designation: "PIPBN146X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "27,65",
    d_global: 146,
    i_global: "675,88",
    t_global: "6,3",
    z_global: "92,59",
  },
  {
    profile_section_id: 6024,
    name: "PIPBN146X7.1",
    type: "Pipe",
    designation: "PIPBN146X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "30,98",
    d_global: 146,
    i_global: "749,13",
    t_global: "7,1",
    z_global: "102,62",
  },
  {
    profile_section_id: 6025,
    name: "PIPBN146X8",
    type: "Pipe",
    designation: "PIPBN146X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "34,68",
    d_global: 146,
    i_global: "828,41",
    t_global: 8,
    z_global: "113,48",
  },
  {
    profile_section_id: 6026,
    name: "PIPBN146X8.8",
    type: "Pipe",
    designation: "PIPBN146X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "37,93",
    d_global: 146,
    i_global: "896,16",
    t_global: "8,8",
    z_global: "122,76",
  },
  {
    profile_section_id: 6027,
    name: "PIPBN146X10",
    type: "Pipe",
    designation: "PIPBN146X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "42,73",
    d_global: 146,
    i_global: "993,16",
    t_global: 10,
    z_global: "136,05",
  },
  {
    profile_section_id: 6028,
    name: "PIPBN146X11",
    type: "Pipe",
    designation: "PIPBN146X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "46,65",
    d_global: 146,
    i_global: "1069,86",
    t_global: 11,
    z_global: "146,56",
  },
  {
    profile_section_id: 6029,
    name: "PIPBN146X12.5",
    type: "Pipe",
    designation: "PIPBN146X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "52,43",
    d_global: 146,
    i_global: "1178,16",
    t_global: "12,5",
    z_global: "161,39",
  },
  {
    profile_section_id: 6030,
    name: "PIPBN146X14.2",
    type: "Pipe",
    designation: "PIPBN146X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "58,8",
    d_global: 146,
    i_global: "1291,54",
    t_global: "14,2",
    z_global: "176,92",
  },
  {
    profile_section_id: 6031,
    name: "PIPBN146X16",
    type: "Pipe",
    designation: "PIPBN146X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "65,35",
    d_global: 146,
    i_global: "1401,33",
    t_global: 16,
    z_global: "191,96",
  },
  {
    profile_section_id: 6032,
    name: "PIPBN146X17.5",
    type: "Pipe",
    designation: "PIPBN146X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "70,65",
    d_global: 146,
    i_global: "1485,21",
    t_global: "17,5",
    z_global: "203,45",
  },
  {
    profile_section_id: 6033,
    name: "PIPBN146X20",
    type: "Pipe",
    designation: "PIPBN146X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "79,17",
    d_global: 146,
    i_global: "1610,68",
    t_global: 20,
    z_global: "220,64",
  },
  {
    profile_section_id: 6034,
    name: "PIPBN146X22.2",
    type: "Pipe",
    designation: "PIPBN146X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "86,34",
    d_global: 146,
    i_global: "1707,34",
    t_global: "22,2",
    z_global: "233,88",
  },
  {
    profile_section_id: 6035,
    name: "PIPBN146X25",
    type: "Pipe",
    designation: "PIPBN146X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "95,03",
    d_global: 146,
    i_global: "1813,47",
    t_global: 25,
    z_global: "248,42",
  },
  {
    profile_section_id: 6036,
    name: "PIPBN146X28",
    type: "Pipe",
    designation: "PIPBN146X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "103,8",
    d_global: 146,
    i_global: "1908,33",
    t_global: 28,
    z_global: "261,42",
  },
  {
    profile_section_id: 6037,
    name: "PIPBN146X30",
    type: "Pipe",
    designation: "PIPBN146X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "109,33",
    d_global: 146,
    i_global: "1961,88",
    t_global: 30,
    z_global: "268,75",
  },
  {
    profile_section_id: 6038,
    name: "PIPBN146X36",
    type: "Pipe",
    designation: "PIPBN146X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "124,41",
    d_global: 146,
    i_global: "2083,2",
    t_global: 36,
    z_global: "285,37",
  },
  {
    profile_section_id: 6039,
    name: "PIPBN150X12.5",
    type: "Pipe",
    designation: "PIPBN150X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 54,
    d_global: 150,
    i_global: "1286,63",
    t_global: "12,5",
    z_global: "171,55",
  },
  {
    profile_section_id: 6040,
    name: "PIPBN150X22",
    type: "Pipe",
    designation: "PIPBN150X22",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "88,47",
    d_global: 150,
    i_global: "1865,33",
    t_global: 22,
    z_global: "248,71",
  },
  {
    profile_section_id: 6041,
    name: "PIPBN150X35",
    type: "Pipe",
    designation: "PIPBN150X35",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "126,45",
    d_global: 150,
    i_global: "2283,99",
    t_global: 35,
    z_global: "304,53",
  },
  {
    profile_section_id: 6042,
    name: "PIPBN152.4X4.5",
    type: "Pipe",
    designation: "PIPBN152.4X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,91",
    d_global: "152,4",
    i_global: "572,24",
    t_global: "4,5",
    z_global: "75,1",
  },
  {
    profile_section_id: 6043,
    name: "PIPBN152.4X5",
    type: "Pipe",
    designation: "PIPBN152.4X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,15",
    d_global: "152,4",
    i_global: "629,54",
    t_global: 5,
    z_global: "82,62",
  },
  {
    profile_section_id: 6044,
    name: "PIPBN152.4X5.6",
    type: "Pipe",
    designation: "PIPBN152.4X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "25,83",
    d_global: "152,4",
    i_global: "696,72",
    t_global: "5,6",
    z_global: "91,43",
  },
  {
    profile_section_id: 6045,
    name: "PIPBN152.4X6.3",
    type: "Pipe",
    designation: "PIPBN152.4X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "28,92",
    d_global: "152,4",
    i_global: "772,96",
    t_global: "6,3",
    z_global: "101,44",
  },
  {
    profile_section_id: 6046,
    name: "PIPBN152.4X7.1",
    type: "Pipe",
    designation: "PIPBN152.4X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "32,41",
    d_global: "152,4",
    i_global: "857,34",
    t_global: "7,1",
    z_global: "112,51",
  },
  {
    profile_section_id: 6047,
    name: "PIPBN152.4X8",
    type: "Pipe",
    designation: "PIPBN152.4X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "36,29",
    d_global: "152,4",
    i_global: "948,82",
    t_global: 8,
    z_global: "124,52",
  },
  {
    profile_section_id: 6048,
    name: "PIPBN152.4X8.8",
    type: "Pipe",
    designation: "PIPBN152.4X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "39,7",
    d_global: "152,4",
    i_global: "1027,15",
    t_global: "8,8",
    z_global: "134,8",
  },
  {
    profile_section_id: 6049,
    name: "PIPBN152.4X10",
    type: "Pipe",
    designation: "PIPBN152.4X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "44,74",
    d_global: "152,4",
    i_global: "1139,53",
    t_global: 10,
    z_global: "149,54",
  },
  {
    profile_section_id: 6050,
    name: "PIPBN152.4X11",
    type: "Pipe",
    designation: "PIPBN152.4X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "48,86",
    d_global: "152,4",
    i_global: "1228,63",
    t_global: 11,
    z_global: "161,24",
  },
  {
    profile_section_id: 6051,
    name: "PIPBN152.4X12.5",
    type: "Pipe",
    designation: "PIPBN152.4X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "54,94",
    d_global: "152,4",
    i_global: "1354,8",
    t_global: "12,5",
    z_global: "177,8",
  },
  {
    profile_section_id: 6052,
    name: "PIPBN152.4X14.2",
    type: "Pipe",
    designation: "PIPBN152.4X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "61,65",
    d_global: "152,4",
    i_global: "1487,42",
    t_global: "14,2",
    z_global: "195,2",
  },
  {
    profile_section_id: 6053,
    name: "PIPBN152.4X16",
    type: "Pipe",
    designation: "PIPBN152.4X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "68,56",
    d_global: "152,4",
    i_global: "1616,43",
    t_global: 16,
    z_global: "212,13",
  },
  {
    profile_section_id: 6054,
    name: "PIPBN152.4X17.5",
    type: "Pipe",
    designation: "PIPBN152.4X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "74,17",
    d_global: "152,4",
    i_global: "1715,46",
    t_global: "17,5",
    z_global: "225,13",
  },
  {
    profile_section_id: 6055,
    name: "PIPBN152.4X20",
    type: "Pipe",
    designation: "PIPBN152.4X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "83,19",
    d_global: "152,4",
    i_global: "1864,46",
    t_global: 20,
    z_global: "244,68",
  },
  {
    profile_section_id: 6056,
    name: "PIPBN152.4X22.2",
    type: "Pipe",
    designation: "PIPBN152.4X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "90,81",
    d_global: "152,4",
    i_global: "1980,12",
    t_global: "22,2",
    z_global: "259,86",
  },
  {
    profile_section_id: 6057,
    name: "PIPBN152.4X25",
    type: "Pipe",
    designation: "PIPBN152.4X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "100,06",
    d_global: "152,4",
    i_global: "2108,23",
    t_global: 25,
    z_global: "276,67",
  },
  {
    profile_section_id: 6058,
    name: "PIPBN152.4X28",
    type: "Pipe",
    designation: "PIPBN152.4X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "109,43",
    d_global: "152,4",
    i_global: "2224,04",
    t_global: 28,
    z_global: "291,87",
  },
  {
    profile_section_id: 6059,
    name: "PIPBN152.4X30",
    type: "Pipe",
    designation: "PIPBN152.4X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "115,36",
    d_global: "152,4",
    i_global: "2290,14",
    t_global: 30,
    z_global: "300,54",
  },
  {
    profile_section_id: 6060,
    name: "PIPBN152.4X32",
    type: "Pipe",
    designation: "PIPBN152.4X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "121,04",
    d_global: "152,4",
    i_global: "2348,19",
    t_global: 32,
    z_global: "308,16",
  },
  {
    profile_section_id: 6061,
    name: "PIPBN152.4X36",
    type: "Pipe",
    designation: "PIPBN152.4X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "131,65",
    d_global: "152,4",
    i_global: "2442,84",
    t_global: 36,
    z_global: "320,58",
  },
  {
    profile_section_id: 6062,
    name: "PIPBN152.4X40",
    type: "Pipe",
    designation: "PIPBN152.4X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "141,25",
    d_global: "152,4",
    i_global: "2513,08",
    t_global: 40,
    z_global: "329,8",
  },
  {
    profile_section_id: 6063,
    name: "PIPBN152.4X45",
    type: "Pipe",
    designation: "PIPBN152.4X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "151,83",
    d_global: "152,4",
    i_global: "2573,53",
    t_global: 45,
    z_global: "337,73",
  },
  {
    profile_section_id: 6064,
    name: "PIPBN159X4.5",
    type: "Pipe",
    designation: "PIPBN159X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "21,84",
    d_global: 159,
    i_global: "652,27",
    t_global: "4,5",
    z_global: "82,05",
  },
  {
    profile_section_id: 6065,
    name: "PIPBN159X5",
    type: "Pipe",
    designation: "PIPBN159X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "24,19",
    d_global: 159,
    i_global: "717,88",
    t_global: 5,
    z_global: "90,3",
  },
  {
    profile_section_id: 6066,
    name: "PIPBN159X5.6",
    type: "Pipe",
    designation: "PIPBN159X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "26,99",
    d_global: 159,
    i_global: "794,88",
    t_global: "5,6",
    z_global: "99,98",
  },
  {
    profile_section_id: 6067,
    name: "PIPBN159X6.3",
    type: "Pipe",
    designation: "PIPBN159X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "30,22",
    d_global: 159,
    i_global: "882,38",
    t_global: "6,3",
    z_global: "110,99",
  },
  {
    profile_section_id: 6068,
    name: "PIPBN159X7.1",
    type: "Pipe",
    designation: "PIPBN159X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "33,88",
    d_global: 159,
    i_global: "979,35",
    t_global: "7,1",
    z_global: "123,19",
  },
  {
    profile_section_id: 6069,
    name: "PIPBN159X8",
    type: "Pipe",
    designation: "PIPBN159X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "37,95",
    d_global: 159,
    i_global: "1084,67",
    t_global: 8,
    z_global: "136,44",
  },
  {
    profile_section_id: 6070,
    name: "PIPBN159X8.8",
    type: "Pipe",
    designation: "PIPBN159X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "41,52",
    d_global: 159,
    i_global: "1175,01",
    t_global: "8,8",
    z_global: "147,8",
  },
  {
    profile_section_id: 6071,
    name: "PIPBN159X10",
    type: "Pipe",
    designation: "PIPBN159X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "46,81",
    d_global: 159,
    i_global: "1304,88",
    t_global: 10,
    z_global: "164,14",
  },
  {
    profile_section_id: 6072,
    name: "PIPBN159X11",
    type: "Pipe",
    designation: "PIPBN159X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "51,15",
    d_global: 159,
    i_global: "1408,09",
    t_global: 11,
    z_global: "177,12",
  },
  {
    profile_section_id: 6073,
    name: "PIPBN159X12.5",
    type: "Pipe",
    designation: "PIPBN159X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "57,53",
    d_global: 159,
    i_global: "1554,65",
    t_global: "12,5",
    z_global: "195,55",
  },
  {
    profile_section_id: 6074,
    name: "PIPBN159X14.2",
    type: "Pipe",
    designation: "PIPBN159X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "64,6",
    d_global: 159,
    i_global: "1709,27",
    t_global: "14,2",
    z_global: 215,
  },
  {
    profile_section_id: 6075,
    name: "PIPBN159X16",
    type: "Pipe",
    designation: "PIPBN159X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "71,88",
    d_global: 159,
    i_global: "1860,34",
    t_global: 16,
    z_global: 234,
  },
  {
    profile_section_id: 6076,
    name: "PIPBN159X17.5",
    type: "Pipe",
    designation: "PIPBN159X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "77,79",
    d_global: 159,
    i_global: "1976,79",
    t_global: "17,5",
    z_global: "248,65",
  },
  {
    profile_section_id: 6077,
    name: "PIPBN159X20",
    type: "Pipe",
    designation: "PIPBN159X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "87,34",
    d_global: 159,
    i_global: "2152,95",
    t_global: 20,
    z_global: "270,81",
  },
  {
    profile_section_id: 6078,
    name: "PIPBN159X22.2",
    type: "Pipe",
    designation: "PIPBN159X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "95,41",
    d_global: 159,
    i_global: "2290,66",
    t_global: "22,2",
    z_global: "288,13",
  },
  {
    profile_section_id: 6079,
    name: "PIPBN159X25",
    type: "Pipe",
    designation: "PIPBN159X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "105,24",
    d_global: 159,
    i_global: "2444,41",
    t_global: 25,
    z_global: "307,47",
  },
  {
    profile_section_id: 6080,
    name: "PIPBN159X28",
    type: "Pipe",
    designation: "PIPBN159X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "115,23",
    d_global: 159,
    i_global: "2584,83",
    t_global: 28,
    z_global: "325,14",
  },
  {
    profile_section_id: 6081,
    name: "PIPBN159X30",
    type: "Pipe",
    designation: "PIPBN159X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "121,58",
    d_global: 159,
    i_global: "2665,78",
    t_global: 30,
    z_global: "335,32",
  },
  {
    profile_section_id: 6082,
    name: "PIPBN159X32",
    type: "Pipe",
    designation: "PIPBN159X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "127,67",
    d_global: 159,
    i_global: "2737,5",
    t_global: 32,
    z_global: "344,34",
  },
  {
    profile_section_id: 6083,
    name: "PIPBN159X36",
    type: "Pipe",
    designation: "PIPBN159X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "139,11",
    d_global: 159,
    i_global: "2856,1",
    t_global: 36,
    z_global: "359,26",
  },
  {
    profile_section_id: 6084,
    name: "PIPBN159X40",
    type: "Pipe",
    designation: "PIPBN159X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "149,54",
    d_global: 159,
    i_global: "2946,12",
    t_global: 40,
    z_global: "370,58",
  },
  {
    profile_section_id: 6085,
    name: "PIPBN159X45",
    type: "Pipe",
    designation: "PIPBN159X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "161,16",
    d_global: 159,
    i_global: "3026,05",
    t_global: 45,
    z_global: "380,63",
  },
  {
    profile_section_id: 6086,
    name: "PIPBN159X50",
    type: "Pipe",
    designation: "PIPBN159X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "171,22",
    d_global: 159,
    i_global: "3077,84",
    t_global: 50,
    z_global: "387,15",
  },
  {
    profile_section_id: 6087,
    name: "PIPBN160X14",
    type: "Pipe",
    designation: "PIPBN160X14",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "64,21",
    d_global: 160,
    i_global: "1726,72",
    t_global: 14,
    z_global: "215,84",
  },
  {
    profile_section_id: 6088,
    name: "PIPBN160X24",
    type: "Pipe",
    designation: "PIPBN160X24",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "102,54",
    d_global: 160,
    i_global: "2444,59",
    t_global: 24,
    z_global: "305,57",
  },
  {
    profile_section_id: 6089,
    name: "PIPBN160X35",
    type: "Pipe",
    designation: "PIPBN160X35",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "137,44",
    d_global: 160,
    i_global: "2894,93",
    t_global: 35,
    z_global: "361,87",
  },
  {
    profile_section_id: 6090,
    name: "PIPBN165.1X5",
    type: "Pipe",
    designation: "PIPBN165.1X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "25,15",
    d_global: "165,1",
    i_global: "806,54",
    t_global: 5,
    z_global: "97,7",
  },
  {
    profile_section_id: 6091,
    name: "PIPBN165.1X5.4",
    type: "Pipe",
    designation: "PIPBN165.1X5.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "27,09",
    d_global: "165,1",
    i_global: "864,7",
    t_global: "5,4",
    z_global: "104,75",
  },
  {
    profile_section_id: 6092,
    name: "PIPBN165.1X5.6",
    type: "Pipe",
    designation: "PIPBN165.1X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "28,06",
    d_global: "165,1",
    i_global: "893,44",
    t_global: "5,6",
    z_global: "108,23",
  },
  {
    profile_section_id: 6093,
    name: "PIPBN165.1X6.3",
    type: "Pipe",
    designation: "PIPBN165.1X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "31,43",
    d_global: "165,1",
    i_global: "992,28",
    t_global: "6,3",
    z_global: "120,2",
  },
  {
    profile_section_id: 6094,
    name: "PIPBN165.1X7.1",
    type: "Pipe",
    designation: "PIPBN165.1X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "35,24",
    d_global: "165,1",
    i_global: "1101,96",
    t_global: "7,1",
    z_global: "133,49",
  },
  {
    profile_section_id: 6095,
    name: "PIPBN165.1X8",
    type: "Pipe",
    designation: "PIPBN165.1X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "39,48",
    d_global: "165,1",
    i_global: "1221,25",
    t_global: 8,
    z_global: "147,94",
  },
  {
    profile_section_id: 6096,
    name: "PIPBN165.1X8.8",
    type: "Pipe",
    designation: "PIPBN165.1X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "43,21",
    d_global: "165,1",
    i_global: "1323,71",
    t_global: "8,8",
    z_global: "160,35",
  },
  {
    profile_section_id: 6097,
    name: "PIPBN165.1X10",
    type: "Pipe",
    designation: "PIPBN165.1X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "48,73",
    d_global: "165,1",
    i_global: "1471,29",
    t_global: 10,
    z_global: "178,23",
  },
  {
    profile_section_id: 6098,
    name: "PIPBN165.1X11",
    type: "Pipe",
    designation: "PIPBN165.1X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "53,25",
    d_global: "165,1",
    i_global: "1588,8",
    t_global: 11,
    z_global: "192,47",
  },
  {
    profile_section_id: 6099,
    name: "PIPBN165.1X12.5",
    type: "Pipe",
    designation: "PIPBN165.1X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "59,93",
    d_global: "165,1",
    i_global: "1756,05",
    t_global: "12,5",
    z_global: "212,73",
  },
  {
    profile_section_id: 6100,
    name: "PIPBN165.1X14.2",
    type: "Pipe",
    designation: "PIPBN165.1X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "67,32",
    d_global: "165,1",
    i_global: "1933,06",
    t_global: "14,2",
    z_global: "234,17",
  },
  {
    profile_section_id: 6101,
    name: "PIPBN165.1X16",
    type: "Pipe",
    designation: "PIPBN165.1X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "74,95",
    d_global: "165,1",
    i_global: "2106,62",
    t_global: 16,
    z_global: "255,19",
  },
  {
    profile_section_id: 6102,
    name: "PIPBN165.1X17.5",
    type: "Pipe",
    designation: "PIPBN165.1X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "81,15",
    d_global: "165,1",
    i_global: "2240,89",
    t_global: "17,5",
    z_global: "271,46",
  },
  {
    profile_section_id: 6103,
    name: "PIPBN165.1X20",
    type: "Pipe",
    designation: "PIPBN165.1X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "91,17",
    d_global: "165,1",
    i_global: "2444,93",
    t_global: 20,
    z_global: "296,18",
  },
  {
    profile_section_id: 6104,
    name: "PIPBN165.1X22.2",
    type: "Pipe",
    designation: "PIPBN165.1X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "99,66",
    d_global: "165,1",
    i_global: "2605,35",
    t_global: "22,2",
    z_global: "315,61",
  },
  {
    profile_section_id: 6105,
    name: "PIPBN165.1X25",
    type: "Pipe",
    designation: "PIPBN165.1X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "110,03",
    d_global: "165,1",
    i_global: "2785,66",
    t_global: 25,
    z_global: "337,45",
  },
  {
    profile_section_id: 6106,
    name: "PIPBN165.1X28",
    type: "Pipe",
    designation: "PIPBN165.1X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "120,6",
    d_global: "165,1",
    i_global: "2951,73",
    t_global: 28,
    z_global: "357,57",
  },
  {
    profile_section_id: 6107,
    name: "PIPBN165.1X30",
    type: "Pipe",
    designation: "PIPBN165.1X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "127,33",
    d_global: "165,1",
    i_global: "3048,25",
    t_global: 30,
    z_global: "369,26",
  },
  {
    profile_section_id: 6108,
    name: "PIPBN165.1X32",
    type: "Pipe",
    designation: "PIPBN165.1X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "133,81",
    d_global: "165,1",
    i_global: "3134,36",
    t_global: 32,
    z_global: "379,69",
  },
  {
    profile_section_id: 6109,
    name: "PIPBN165.1X36",
    type: "Pipe",
    designation: "PIPBN165.1X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "146,01",
    d_global: "165,1",
    i_global: "3278,41",
    t_global: 36,
    z_global: "397,14",
  },
  {
    profile_section_id: 6110,
    name: "PIPBN165.1X40",
    type: "Pipe",
    designation: "PIPBN165.1X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "157,21",
    d_global: "165,1",
    i_global: "3389,74",
    t_global: 40,
    z_global: "410,63",
  },
  {
    profile_section_id: 6111,
    name: "PIPBN165.1X45",
    type: "Pipe",
    designation: "PIPBN165.1X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "169,79",
    d_global: "165,1",
    i_global: "3491,04",
    t_global: 45,
    z_global: "422,9",
  },
  {
    profile_section_id: 6112,
    name: "PIPBN165.1X50",
    type: "Pipe",
    designation: "PIPBN165.1X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "180,8",
    d_global: "165,1",
    i_global: "3559,02",
    t_global: 50,
    z_global: "431,14",
  },
  {
    profile_section_id: 6113,
    name: "PIPBN168.3X5",
    type: "Pipe",
    designation: "PIPBN168.3X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "25,65",
    d_global: "168,3",
    i_global: "855,85",
    t_global: 5,
    z_global: "101,7",
  },
  {
    profile_section_id: 6114,
    name: "PIPBN168.3X5.6",
    type: "Pipe",
    designation: "PIPBN168.3X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "28,62",
    d_global: "168,3",
    i_global: "948,25",
    t_global: "5,6",
    z_global: "112,69",
  },
  {
    profile_section_id: 6115,
    name: "PIPBN168.3X6.3",
    type: "Pipe",
    designation: "PIPBN168.3X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "32,06",
    d_global: "168,3",
    i_global: "1053,42",
    t_global: "6,3",
    z_global: "125,18",
  },
  {
    profile_section_id: 6116,
    name: "PIPBN168.3X7.1",
    type: "Pipe",
    designation: "PIPBN168.3X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "35,96",
    d_global: "168,3",
    i_global: "1170,19",
    t_global: "7,1",
    z_global: "139,06",
  },
  {
    profile_section_id: 6117,
    name: "PIPBN168.3X8",
    type: "Pipe",
    designation: "PIPBN168.3X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "40,29",
    d_global: "168,3",
    i_global: "1297,27",
    t_global: 8,
    z_global: "154,16",
  },
  {
    profile_section_id: 6118,
    name: "PIPBN168.3X8.8",
    type: "Pipe",
    designation: "PIPBN168.3X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "44,1",
    d_global: "168,3",
    i_global: "1406,52",
    t_global: "8,8",
    z_global: "167,14",
  },
  {
    profile_section_id: 6119,
    name: "PIPBN168.3X10",
    type: "Pipe",
    designation: "PIPBN168.3X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "49,73",
    d_global: "168,3",
    i_global: "1563,98",
    t_global: 10,
    z_global: "185,86",
  },
  {
    profile_section_id: 6120,
    name: "PIPBN168.3X11",
    type: "Pipe",
    designation: "PIPBN168.3X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "54,36",
    d_global: "168,3",
    i_global: "1689,5",
    t_global: 11,
    z_global: "200,77",
  },
  {
    profile_section_id: 6121,
    name: "PIPBN168.3X12.5",
    type: "Pipe",
    designation: "PIPBN168.3X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "61,18",
    d_global: "168,3",
    i_global: "1868,35",
    t_global: "12,5",
    z_global: "222,03",
  },
  {
    profile_section_id: 6122,
    name: "PIPBN168.3X14.2",
    type: "Pipe",
    designation: "PIPBN168.3X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "68,74",
    d_global: "168,3",
    i_global: "2057,92",
    t_global: "14,2",
    z_global: "244,55",
  },
  {
    profile_section_id: 6123,
    name: "PIPBN168.3X16",
    type: "Pipe",
    designation: "PIPBN168.3X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "76,55",
    d_global: "168,3",
    i_global: "2244,12",
    t_global: 16,
    z_global: "266,68",
  },
  {
    profile_section_id: 6124,
    name: "PIPBN168.3X17.5",
    type: "Pipe",
    designation: "PIPBN168.3X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "82,91",
    d_global: "168,3",
    i_global: "2388,43",
    t_global: "17,5",
    z_global: "283,83",
  },
  {
    profile_section_id: 6125,
    name: "PIPBN168.3X20",
    type: "Pipe",
    designation: "PIPBN168.3X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "93,18",
    d_global: "168,3",
    i_global: "2608,2",
    t_global: 20,
    z_global: "309,95",
  },
  {
    profile_section_id: 6126,
    name: "PIPBN168.3X22.2",
    type: "Pipe",
    designation: "PIPBN168.3X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "101,9",
    d_global: "168,3",
    i_global: "2781,49",
    t_global: "22,2",
    z_global: "330,54",
  },
  {
    profile_section_id: 6127,
    name: "PIPBN168.3X25",
    type: "Pipe",
    designation: "PIPBN168.3X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "112,55",
    d_global: "168,3",
    i_global: "2976,87",
    t_global: 25,
    z_global: "353,76",
  },
  {
    profile_section_id: 6128,
    name: "PIPBN168.3X28",
    type: "Pipe",
    designation: "PIPBN168.3X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "123,41",
    d_global: "168,3",
    i_global: "3157,57",
    t_global: 28,
    z_global: "375,23",
  },
  {
    profile_section_id: 6129,
    name: "PIPBN168.3X30",
    type: "Pipe",
    designation: "PIPBN168.3X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "130,34",
    d_global: "168,3",
    i_global: 3263,
    t_global: 30,
    z_global: "387,76",
  },
  {
    profile_section_id: 6130,
    name: "PIPBN168.3X32",
    type: "Pipe",
    designation: "PIPBN168.3X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "137,02",
    d_global: "168,3",
    i_global: "3357,37",
    t_global: 32,
    z_global: "398,97",
  },
  {
    profile_section_id: 6131,
    name: "PIPBN168.3X36",
    type: "Pipe",
    designation: "PIPBN168.3X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "149,63",
    d_global: "168,3",
    i_global: "3516,12",
    t_global: 36,
    z_global: "417,84",
  },
  {
    profile_section_id: 6132,
    name: "PIPBN168.3X40",
    type: "Pipe",
    designation: "PIPBN168.3X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "161,23",
    d_global: "168,3",
    i_global: "3639,87",
    t_global: 40,
    z_global: "432,55",
  },
  {
    profile_section_id: 6133,
    name: "PIPBN168.3X45",
    type: "Pipe",
    designation: "PIPBN168.3X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "174,31",
    d_global: "168,3",
    i_global: "3753,77",
    t_global: 45,
    z_global: "446,08",
  },
  {
    profile_section_id: 6134,
    name: "PIPBN168.3X50",
    type: "Pipe",
    designation: "PIPBN168.3X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "185,83",
    d_global: "168,3",
    i_global: "3831,46",
    t_global: 50,
    z_global: "455,31",
  },
  {
    profile_section_id: 6135,
    name: "PIPBN170X15",
    type: "Pipe",
    designation: "PIPBN170X15",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "73,04",
    d_global: 170,
    i_global: "2214,09",
    t_global: 15,
    z_global: "260,48",
  },
  {
    profile_section_id: 6136,
    name: "PIPBN170X26",
    type: "Pipe",
    designation: "PIPBN170X26",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "117,62",
    d_global: 170,
    i_global: "3148,13",
    t_global: 26,
    z_global: "370,37",
  },
  {
    profile_section_id: 6137,
    name: "PIPBN170X35",
    type: "Pipe",
    designation: "PIPBN170X35",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "148,44",
    d_global: 170,
    i_global: "3608,95",
    t_global: 35,
    z_global: "424,58",
  },
  {
    profile_section_id: 6138,
    name: "PIPBN171X5",
    type: "Pipe",
    designation: "PIPBN171X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "26,08",
    d_global: 171,
    i_global: "898,98",
    t_global: 5,
    z_global: "105,14",
  },
  {
    profile_section_id: 6139,
    name: "PIPBN171X5.6",
    type: "Pipe",
    designation: "PIPBN171X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "29,1",
    d_global: 171,
    i_global: "996,21",
    t_global: "5,6",
    z_global: "116,52",
  },
  {
    profile_section_id: 6140,
    name: "PIPBN171X6.3",
    type: "Pipe",
    designation: "PIPBN171X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "32,6",
    d_global: 171,
    i_global: "1106,92",
    t_global: "6,3",
    z_global: "129,46",
  },
  {
    profile_section_id: 6141,
    name: "PIPBN171X7.1",
    type: "Pipe",
    designation: "PIPBN171X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "36,56",
    d_global: 171,
    i_global: "1229,9",
    t_global: "7,1",
    z_global: "143,85",
  },
  {
    profile_section_id: 6142,
    name: "PIPBN171X8",
    type: "Pipe",
    designation: "PIPBN171X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "40,97",
    d_global: 171,
    i_global: "1363,82",
    t_global: 8,
    z_global: "159,51",
  },
  {
    profile_section_id: 6143,
    name: "PIPBN171X8.8",
    type: "Pipe",
    designation: "PIPBN171X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "44,84",
    d_global: 171,
    i_global: "1479,01",
    t_global: "8,8",
    z_global: "172,98",
  },
  {
    profile_section_id: 6144,
    name: "PIPBN171X10",
    type: "Pipe",
    designation: "PIPBN171X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "50,58",
    d_global: 171,
    i_global: "1645,17",
    t_global: 10,
    z_global: "192,42",
  },
  {
    profile_section_id: 6145,
    name: "PIPBN171X11",
    type: "Pipe",
    designation: "PIPBN171X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "55,29",
    d_global: 171,
    i_global: "1777,71",
    t_global: 11,
    z_global: "207,92",
  },
  {
    profile_section_id: 6146,
    name: "PIPBN171X12.5",
    type: "Pipe",
    designation: "PIPBN171X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "62,24",
    d_global: 171,
    i_global: "1966,76",
    t_global: "12,5",
    z_global: "230,03",
  },
  {
    profile_section_id: 6147,
    name: "PIPBN171X14.2",
    type: "Pipe",
    designation: "PIPBN171X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "69,95",
    d_global: 171,
    i_global: "2167,37",
    t_global: "14,2",
    z_global: "253,49",
  },
  {
    profile_section_id: 6148,
    name: "PIPBN171X16",
    type: "Pipe",
    designation: "PIPBN171X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "77,91",
    d_global: 171,
    i_global: "2364,71",
    t_global: 16,
    z_global: "276,57",
  },
  {
    profile_section_id: 6149,
    name: "PIPBN171X17.5",
    type: "Pipe",
    designation: "PIPBN171X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "84,39",
    d_global: 171,
    i_global: "2517,86",
    t_global: "17,5",
    z_global: "294,49",
  },
  {
    profile_section_id: 6150,
    name: "PIPBN171X20",
    type: "Pipe",
    designation: "PIPBN171X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "94,88",
    d_global: 171,
    i_global: "2751,53",
    t_global: 20,
    z_global: "321,82",
  },
  {
    profile_section_id: 6151,
    name: "PIPBN171X22.2",
    type: "Pipe",
    designation: "PIPBN171X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "103,78",
    d_global: 171,
    i_global: "2936,18",
    t_global: "22,2",
    z_global: "343,41",
  },
  {
    profile_section_id: 6152,
    name: "PIPBN171X25",
    type: "Pipe",
    designation: "PIPBN171X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "114,67",
    d_global: 171,
    i_global: "3144,92",
    t_global: 25,
    z_global: "367,83",
  },
  {
    profile_section_id: 6153,
    name: "PIPBN171X28",
    type: "Pipe",
    designation: "PIPBN171X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "125,79",
    d_global: 171,
    i_global: "3338,61",
    t_global: 28,
    z_global: "390,48",
  },
  {
    profile_section_id: 6154,
    name: "PIPBN171X30",
    type: "Pipe",
    designation: "PIPBN171X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "132,89",
    d_global: 171,
    i_global: "3451,97",
    t_global: 30,
    z_global: "403,74",
  },
  {
    profile_section_id: 6155,
    name: "PIPBN171X32",
    type: "Pipe",
    designation: "PIPBN171X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "139,74",
    d_global: 171,
    i_global: "3553,71",
    t_global: 32,
    z_global: "415,64",
  },
  {
    profile_section_id: 6156,
    name: "PIPBN171X36",
    type: "Pipe",
    designation: "PIPBN171X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "152,68",
    d_global: 171,
    i_global: "3725,62",
    t_global: 36,
    z_global: "435,75",
  },
  {
    profile_section_id: 6157,
    name: "PIPBN171X40",
    type: "Pipe",
    designation: "PIPBN171X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "164,62",
    d_global: 171,
    i_global: "3860,53",
    t_global: 40,
    z_global: "451,52",
  },
  {
    profile_section_id: 6158,
    name: "PIPBN177.8X5",
    type: "Pipe",
    designation: "PIPBN177.8X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "27,14",
    d_global: "177,8",
    i_global: "1013,97",
    t_global: 5,
    z_global: "114,06",
  },
  {
    profile_section_id: 6159,
    name: "PIPBN177.8X5.6",
    type: "Pipe",
    designation: "PIPBN177.8X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "30,3",
    d_global: "177,8",
    i_global: "1124,1",
    t_global: "5,6",
    z_global: "126,45",
  },
  {
    profile_section_id: 6160,
    name: "PIPBN177.8X6.3",
    type: "Pipe",
    designation: "PIPBN177.8X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "33,94",
    d_global: "177,8",
    i_global: "1249,62",
    t_global: "6,3",
    z_global: "140,56",
  },
  {
    profile_section_id: 6161,
    name: "PIPBN177.8X7.1",
    type: "Pipe",
    designation: "PIPBN177.8X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "38,08",
    d_global: "177,8",
    i_global: "1389,21",
    t_global: "7,1",
    z_global: "156,27",
  },
  {
    profile_section_id: 6162,
    name: "PIPBN177.8X8",
    type: "Pipe",
    designation: "PIPBN177.8X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "42,68",
    d_global: "177,8",
    i_global: "1541,44",
    t_global: 8,
    z_global: "173,39",
  },
  {
    profile_section_id: 6163,
    name: "PIPBN177.8X8.8",
    type: "Pipe",
    designation: "PIPBN177.8X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "46,72",
    d_global: "177,8",
    i_global: "1672,55",
    t_global: "8,8",
    z_global: "188,14",
  },
  {
    profile_section_id: 6164,
    name: "PIPBN177.8X10",
    type: "Pipe",
    designation: "PIPBN177.8X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "52,72",
    d_global: "177,8",
    i_global: "1861,98",
    t_global: 10,
    z_global: "209,45",
  },
  {
    profile_section_id: 6165,
    name: "PIPBN177.8X11",
    type: "Pipe",
    designation: "PIPBN177.8X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "57,64",
    d_global: "177,8",
    i_global: "2013,38",
    t_global: 11,
    z_global: "226,48",
  },
  {
    profile_section_id: 6166,
    name: "PIPBN177.8X12.5",
    type: "Pipe",
    designation: "PIPBN177.8X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "64,91",
    d_global: "177,8",
    i_global: "2229,8",
    t_global: "12,5",
    z_global: "250,82",
  },
  {
    profile_section_id: 6167,
    name: "PIPBN177.8X14.2",
    type: "Pipe",
    designation: "PIPBN177.8X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "72,98",
    d_global: "177,8",
    i_global: "2460,13",
    t_global: "14,2",
    z_global: "276,73",
  },
  {
    profile_section_id: 6168,
    name: "PIPBN177.8X16",
    type: "Pipe",
    designation: "PIPBN177.8X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "81,33",
    d_global: "177,8",
    i_global: "2687,46",
    t_global: 16,
    z_global: "302,3",
  },
  {
    profile_section_id: 6169,
    name: "PIPBN177.8X17.5",
    type: "Pipe",
    designation: "PIPBN177.8X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "88,13",
    d_global: "177,8",
    i_global: "2864,47",
    t_global: "17,5",
    z_global: "322,21",
  },
  {
    profile_section_id: 6170,
    name: "PIPBN177.8X20",
    type: "Pipe",
    designation: "PIPBN177.8X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "99,15",
    d_global: "177,8",
    i_global: "3135,68",
    t_global: 20,
    z_global: "352,72",
  },
  {
    profile_section_id: 6171,
    name: "PIPBN177.8X22.2",
    type: "Pipe",
    designation: "PIPBN177.8X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "108,52",
    d_global: "177,8",
    i_global: "3351,14",
    t_global: "22,2",
    z_global: "376,96",
  },
  {
    profile_section_id: 6172,
    name: "PIPBN177.8X25",
    type: "Pipe",
    designation: "PIPBN177.8X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "120,01",
    d_global: "177,8",
    i_global: "3596,19",
    t_global: 25,
    z_global: "404,52",
  },
  {
    profile_section_id: 6173,
    name: "PIPBN177.8X28",
    type: "Pipe",
    designation: "PIPBN177.8X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "131,77",
    d_global: "177,8",
    i_global: "3825,32",
    t_global: 28,
    z_global: "430,29",
  },
  {
    profile_section_id: 6174,
    name: "PIPBN177.8X30",
    type: "Pipe",
    designation: "PIPBN177.8X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "139,3",
    d_global: "177,8",
    i_global: "3960,39",
    t_global: 30,
    z_global: "445,49",
  },
  {
    profile_section_id: 6175,
    name: "PIPBN177.8X32",
    type: "Pipe",
    designation: "PIPBN177.8X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "146,57",
    d_global: "177,8",
    i_global: "4082,39",
    t_global: 32,
    z_global: "459,21",
  },
  {
    profile_section_id: 6176,
    name: "PIPBN177.8X36",
    type: "Pipe",
    designation: "PIPBN177.8X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "160,37",
    d_global: "177,8",
    i_global: "4290,6",
    t_global: 36,
    z_global: "482,63",
  },
  {
    profile_section_id: 6177,
    name: "PIPBN177.8X40",
    type: "Pipe",
    designation: "PIPBN177.8X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "173,16",
    d_global: "177,8",
    i_global: "4456,57",
    t_global: 40,
    z_global: "501,3",
  },
  {
    profile_section_id: 6178,
    name: "PIPBN177.8X45",
    type: "Pipe",
    designation: "PIPBN177.8X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "187,74",
    d_global: "177,8",
    i_global: "4613,95",
    t_global: 45,
    z_global: 519,
  },
  {
    profile_section_id: 6179,
    name: "PIPBN177.8X50",
    type: "Pipe",
    designation: "PIPBN177.8X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "200,75",
    d_global: "177,8",
    i_global: "4725,81",
    t_global: 50,
    z_global: "531,59",
  },
  {
    profile_section_id: 6180,
    name: "PIPBN177.8X55",
    type: "Pipe",
    designation: "PIPBN177.8X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "212,18",
    d_global: "177,8",
    i_global: "4801,93",
    t_global: 55,
    z_global: "540,15",
  },
  {
    profile_section_id: 6181,
    name: "PIPBN180X15",
    type: "Pipe",
    designation: "PIPBN180X15",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "77,75",
    d_global: 180,
    i_global: "2667,95",
    t_global: 15,
    z_global: "296,44",
  },
  {
    profile_section_id: 6182,
    name: "PIPBN180X27.5",
    type: "Pipe",
    designation: "PIPBN180X27.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "131,75",
    d_global: 180,
    i_global: "3954,57",
    t_global: "27,5",
    z_global: "439,4",
  },
  {
    profile_section_id: 6183,
    name: "PIPBN180X40",
    type: "Pipe",
    designation: "PIPBN180X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "175,93",
    d_global: 180,
    i_global: "4662,12",
    t_global: 40,
    z_global: "518,01",
  },
  {
    profile_section_id: 6184,
    name: "PIPBN190X15",
    type: "Pipe",
    designation: "PIPBN190X15",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "82,47",
    d_global: 190,
    i_global: "3180,13",
    t_global: 15,
    z_global: "334,75",
  },
  {
    profile_section_id: 6185,
    name: "PIPBN190X29",
    type: "Pipe",
    designation: "PIPBN190X29",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "146,68",
    d_global: 190,
    i_global: "4906,85",
    t_global: 29,
    z_global: "516,51",
  },
  {
    profile_section_id: 6186,
    name: "PIPBN190X42",
    type: "Pipe",
    designation: "PIPBN190X42",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "195,28",
    d_global: 190,
    i_global: "5777,4",
    t_global: 42,
    z_global: "608,15",
  },
  {
    profile_section_id: 6187,
    name: "PIPBN191X6.3",
    type: "Pipe",
    designation: "PIPBN191X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "36,56",
    d_global: 191,
    i_global: "1560,65",
    t_global: "6,3",
    z_global: "163,42",
  },
  {
    profile_section_id: 6188,
    name: "PIPBN191X7.1",
    type: "Pipe",
    designation: "PIPBN191X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "41,02",
    d_global: 191,
    i_global: "1736,64",
    t_global: "7,1",
    z_global: "181,85",
  },
  {
    profile_section_id: 6189,
    name: "PIPBN191X8",
    type: "Pipe",
    designation: "PIPBN191X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "45,99",
    d_global: 191,
    i_global: 1929,
    t_global: 8,
    z_global: "201,99",
  },
  {
    profile_section_id: 6190,
    name: "PIPBN191X8.8",
    type: "Pipe",
    designation: "PIPBN191X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "50,37",
    d_global: 191,
    i_global: "2095,07",
    t_global: "8,8",
    z_global: "219,38",
  },
  {
    profile_section_id: 6191,
    name: "PIPBN191X10",
    type: "Pipe",
    designation: "PIPBN191X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "56,86",
    d_global: 191,
    i_global: "2335,71",
    t_global: 10,
    z_global: "244,58",
  },
  {
    profile_section_id: 6192,
    name: "PIPBN191X11",
    type: "Pipe",
    designation: "PIPBN191X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "62,2",
    d_global: 191,
    i_global: "2528,65",
    t_global: 11,
    z_global: "264,78",
  },
  {
    profile_section_id: 6193,
    name: "PIPBN191X12.5",
    type: "Pipe",
    designation: "PIPBN191X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "70,1",
    d_global: 191,
    i_global: "2805,49",
    t_global: "12,5",
    z_global: "293,77",
  },
  {
    profile_section_id: 6194,
    name: "PIPBN191X14.2",
    type: "Pipe",
    designation: "PIPBN191X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "78,87",
    d_global: 191,
    i_global: "3101,61",
    t_global: "14,2",
    z_global: "324,78",
  },
  {
    profile_section_id: 6195,
    name: "PIPBN191X16",
    type: "Pipe",
    designation: "PIPBN191X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "87,96",
    d_global: 191,
    i_global: "3395,54",
    t_global: 16,
    z_global: "355,55",
  },
  {
    profile_section_id: 6196,
    name: "PIPBN191X17.5",
    type: "Pipe",
    designation: "PIPBN191X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "95,39",
    d_global: 191,
    i_global: "3625,7",
    t_global: "17,5",
    z_global: "379,65",
  },
  {
    profile_section_id: 6197,
    name: "PIPBN191X20",
    type: "Pipe",
    designation: "PIPBN191X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "107,44",
    d_global: 191,
    i_global: "3980,88",
    t_global: 20,
    z_global: "416,85",
  },
  {
    profile_section_id: 6198,
    name: "PIPBN191X22.2",
    type: "Pipe",
    designation: "PIPBN191X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "117,73",
    d_global: 191,
    i_global: "4265,58",
    t_global: "22,2",
    z_global: "446,66",
  },
  {
    profile_section_id: 6199,
    name: "PIPBN191X25",
    type: "Pipe",
    designation: "PIPBN191X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "130,38",
    d_global: 191,
    i_global: "4592,66",
    t_global: 25,
    z_global: "480,91",
  },
  {
    profile_section_id: 6200,
    name: "PIPBN191X28",
    type: "Pipe",
    designation: "PIPBN191X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "143,38",
    d_global: 191,
    i_global: "4902,42",
    t_global: 28,
    z_global: "513,34",
  },
  {
    profile_section_id: 6201,
    name: "PIPBN191X30",
    type: "Pipe",
    designation: "PIPBN191X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "151,74",
    d_global: 191,
    i_global: "5087,24",
    t_global: 30,
    z_global: "532,7",
  },
  {
    profile_section_id: 6202,
    name: "PIPBN191X32",
    type: "Pipe",
    designation: "PIPBN191X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "159,84",
    d_global: 191,
    i_global: "5255,88",
    t_global: 32,
    z_global: "550,35",
  },
  {
    profile_section_id: 6203,
    name: "PIPBN191X36",
    type: "Pipe",
    designation: "PIPBN191X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "175,3",
    d_global: 191,
    i_global: "5548,49",
    t_global: 36,
    z_global: "580,99",
  },
  {
    profile_section_id: 6204,
    name: "PIPBN191X40",
    type: "Pipe",
    designation: "PIPBN191X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "189,75",
    d_global: 191,
    i_global: "5787,68",
    t_global: 40,
    z_global: "606,04",
  },
  {
    profile_section_id: 6205,
    name: "PIPBN191X45",
    type: "Pipe",
    designation: "PIPBN191X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "206,4",
    d_global: 191,
    i_global: "6022,06",
    t_global: 45,
    z_global: "630,58",
  },
  {
    profile_section_id: 6206,
    name: "PIPBN193.7X5.6",
    type: "Pipe",
    designation: "PIPBN193.7X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "33,09",
    d_global: "193,7",
    i_global: "1464,87",
    t_global: "5,6",
    z_global: "151,25",
  },
  {
    profile_section_id: 6207,
    name: "PIPBN193.7X6.3",
    type: "Pipe",
    designation: "PIPBN193.7X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "37,09",
    d_global: "193,7",
    i_global: "1630,05",
    t_global: "6,3",
    z_global: "168,31",
  },
  {
    profile_section_id: 6208,
    name: "PIPBN193.7X7.1",
    type: "Pipe",
    designation: "PIPBN193.7X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "41,62",
    d_global: "193,7",
    i_global: "1814,18",
    t_global: "7,1",
    z_global: "187,32",
  },
  {
    profile_section_id: 6209,
    name: "PIPBN193.7X8",
    type: "Pipe",
    designation: "PIPBN193.7X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "46,67",
    d_global: "193,7",
    i_global: "2015,54",
    t_global: 8,
    z_global: "208,11",
  },
  {
    profile_section_id: 6210,
    name: "PIPBN193.7X8.8",
    type: "Pipe",
    designation: "PIPBN193.7X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "51,12",
    d_global: "193,7",
    i_global: "2189,45",
    t_global: "8,8",
    z_global: "226,07",
  },
  {
    profile_section_id: 6211,
    name: "PIPBN193.7X10",
    type: "Pipe",
    designation: "PIPBN193.7X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "57,71",
    d_global: "193,7",
    i_global: "2441,59",
    t_global: 10,
    z_global: "252,1",
  },
  {
    profile_section_id: 6212,
    name: "PIPBN193.7X11",
    type: "Pipe",
    designation: "PIPBN193.7X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "63,14",
    d_global: "193,7",
    i_global: "2643,87",
    t_global: 11,
    z_global: "272,99",
  },
  {
    profile_section_id: 6213,
    name: "PIPBN193.7X12.5",
    type: "Pipe",
    designation: "PIPBN193.7X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "71,16",
    d_global: "193,7",
    i_global: "2934,31",
    t_global: "12,5",
    z_global: "302,98",
  },
  {
    profile_section_id: 6214,
    name: "PIPBN193.7X14.2",
    type: "Pipe",
    designation: "PIPBN193.7X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "80,08",
    d_global: "193,7",
    i_global: "3245,27",
    t_global: "14,2",
    z_global: "335,08",
  },
  {
    profile_section_id: 6215,
    name: "PIPBN193.7X16",
    type: "Pipe",
    designation: "PIPBN193.7X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "89,32",
    d_global: "193,7",
    i_global: "3554,26",
    t_global: 16,
    z_global: "366,99",
  },
  {
    profile_section_id: 6216,
    name: "PIPBN193.7X17.5",
    type: "Pipe",
    designation: "PIPBN193.7X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "96,87",
    d_global: "193,7",
    i_global: "3796,46",
    t_global: "17,5",
    z_global: "391,99",
  },
  {
    profile_section_id: 6217,
    name: "PIPBN193.7X20",
    type: "Pipe",
    designation: "PIPBN193.7X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "109,14",
    d_global: "193,7",
    i_global: "4170,7",
    t_global: 20,
    z_global: "430,63",
  },
  {
    profile_section_id: 6218,
    name: "PIPBN193.7X22.2",
    type: "Pipe",
    designation: "PIPBN193.7X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "119,61",
    d_global: "193,7",
    i_global: "4471,18",
    t_global: "22,2",
    z_global: "461,66",
  },
  {
    profile_section_id: 6219,
    name: "PIPBN193.7X25",
    type: "Pipe",
    designation: "PIPBN193.7X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "132,5",
    d_global: "193,7",
    i_global: "4817,03",
    t_global: 25,
    z_global: "497,37",
  },
  {
    profile_section_id: 6220,
    name: "PIPBN193.7X28",
    type: "Pipe",
    designation: "PIPBN193.7X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "145,76",
    d_global: "193,7",
    i_global: "5145,32",
    t_global: 28,
    z_global: "531,27",
  },
  {
    profile_section_id: 6221,
    name: "PIPBN193.7X30",
    type: "Pipe",
    designation: "PIPBN193.7X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "154,28",
    d_global: "193,7",
    i_global: "5341,63",
    t_global: 30,
    z_global: "551,54",
  },
  {
    profile_section_id: 6222,
    name: "PIPBN193.7X32",
    type: "Pipe",
    designation: "PIPBN193.7X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "162,56",
    d_global: "193,7",
    i_global: "5521,08",
    t_global: 32,
    z_global: "570,07",
  },
  {
    profile_section_id: 6223,
    name: "PIPBN193.7X36",
    type: "Pipe",
    designation: "PIPBN193.7X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "178,35",
    d_global: "193,7",
    i_global: "5833,37",
    t_global: 36,
    z_global: "602,31",
  },
  {
    profile_section_id: 6224,
    name: "PIPBN193.7X40",
    type: "Pipe",
    designation: "PIPBN193.7X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "193,15",
    d_global: "193,7",
    i_global: "6089,79",
    t_global: 40,
    z_global: "628,79",
  },
  {
    profile_section_id: 6225,
    name: "PIPBN193.7X45",
    type: "Pipe",
    designation: "PIPBN193.7X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "210,22",
    d_global: "193,7",
    i_global: "6342,51",
    t_global: 45,
    z_global: "654,88",
  },
  {
    profile_section_id: 6226,
    name: "PIPBN193.7X50",
    type: "Pipe",
    designation: "PIPBN193.7X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "225,72",
    d_global: "193,7",
    i_global: "6531,78",
    t_global: 50,
    z_global: "674,42",
  },
  {
    profile_section_id: 6227,
    name: "PIPBN193.7X55",
    type: "Pipe",
    designation: "PIPBN193.7X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "239,66",
    d_global: "193,7",
    i_global: "6669,24",
    t_global: 55,
    z_global: "688,62",
  },
  {
    profile_section_id: 6228,
    name: "PIPBN200X20",
    type: "Pipe",
    designation: "PIPBN200X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "113,1",
    d_global: 200,
    i_global: "4636,99",
    t_global: 20,
    z_global: "463,7",
  },
  {
    profile_section_id: 6229,
    name: "PIPBN200X30",
    type: "Pipe",
    designation: "PIPBN200X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "160,22",
    d_global: 200,
    i_global: "5968,24",
    t_global: 30,
    z_global: "596,82",
  },
  {
    profile_section_id: 6230,
    name: "PIPBN200X44",
    type: "Pipe",
    designation: "PIPBN200X44",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "215,64",
    d_global: 200,
    i_global: "7081,58",
    t_global: 44,
    z_global: "708,16",
  },
  {
    profile_section_id: 6231,
    name: "PIPBN203X5.6",
    type: "Pipe",
    designation: "PIPBN203X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "34,73",
    d_global: 203,
    i_global: "1692,93",
    t_global: "5,6",
    z_global: "166,79",
  },
  {
    profile_section_id: 6232,
    name: "PIPBN203X6.3",
    type: "Pipe",
    designation: "PIPBN203X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "38,93",
    d_global: 203,
    i_global: "1884,77",
    t_global: "6,3",
    z_global: "185,69",
  },
  {
    profile_section_id: 6233,
    name: "PIPBN203X7.1",
    type: "Pipe",
    designation: "PIPBN203X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "43,7",
    d_global: 203,
    i_global: "2098,9",
    t_global: "7,1",
    z_global: "206,79",
  },
  {
    profile_section_id: 6234,
    name: "PIPBN203X8",
    type: "Pipe",
    designation: "PIPBN203X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "49,01",
    d_global: 203,
    i_global: "2333,37",
    t_global: 8,
    z_global: "229,89",
  },
  {
    profile_section_id: 6235,
    name: "PIPBN203X8.8",
    type: "Pipe",
    designation: "PIPBN203X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "53,69",
    d_global: 203,
    i_global: "2536,19",
    t_global: "8,8",
    z_global: "249,87",
  },
  {
    profile_section_id: 6236,
    name: "PIPBN203X10",
    type: "Pipe",
    designation: "PIPBN203X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "60,63",
    d_global: 203,
    i_global: "2830,72",
    t_global: 10,
    z_global: "278,89",
  },
  {
    profile_section_id: 6237,
    name: "PIPBN203X11",
    type: "Pipe",
    designation: "PIPBN203X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "66,35",
    d_global: 203,
    i_global: "3067,46",
    t_global: 11,
    z_global: "302,21",
  },
  {
    profile_section_id: 6238,
    name: "PIPBN203X12.5",
    type: "Pipe",
    designation: "PIPBN203X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "74,81",
    d_global: 203,
    i_global: "3408,17",
    t_global: "12,5",
    z_global: "335,78",
  },
  {
    profile_section_id: 6239,
    name: "PIPBN203X14.2",
    type: "Pipe",
    designation: "PIPBN203X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "84,22",
    d_global: 203,
    i_global: "3774,02",
    t_global: "14,2",
    z_global: "371,82",
  },
  {
    profile_section_id: 6240,
    name: "PIPBN203X16",
    type: "Pipe",
    designation: "PIPBN203X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 94,
    d_global: 203,
    i_global: "4138,78",
    t_global: 16,
    z_global: "407,76",
  },
  {
    profile_section_id: 6241,
    name: "PIPBN203X17.5",
    type: "Pipe",
    designation: "PIPBN203X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "101,98",
    d_global: 203,
    i_global: "4425,66",
    t_global: "17,5",
    z_global: "436,02",
  },
  {
    profile_section_id: 6242,
    name: "PIPBN203X20",
    type: "Pipe",
    designation: "PIPBN203X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "114,98",
    d_global: 203,
    i_global: "4870,79",
    t_global: 20,
    z_global: "479,88",
  },
  {
    profile_section_id: 6243,
    name: "PIPBN203X22.2",
    type: "Pipe",
    designation: "PIPBN203X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "126,1",
    d_global: 203,
    i_global: "5230,06",
    t_global: "22,2",
    z_global: "515,28",
  },
  {
    profile_section_id: 6244,
    name: "PIPBN203X25",
    type: "Pipe",
    designation: "PIPBN203X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "139,8",
    d_global: 203,
    i_global: "5646,03",
    t_global: 25,
    z_global: "556,26",
  },
  {
    profile_section_id: 6245,
    name: "PIPBN203X28",
    type: "Pipe",
    designation: "PIPBN203X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "153,94",
    d_global: 203,
    i_global: "6043,8",
    t_global: 28,
    z_global: "595,45",
  },
  {
    profile_section_id: 6246,
    name: "PIPBN203X30",
    type: "Pipe",
    designation: "PIPBN203X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "163,05",
    d_global: 203,
    i_global: "6283,28",
    t_global: 30,
    z_global: "619,04",
  },
  {
    profile_section_id: 6247,
    name: "PIPBN203X36",
    type: "Pipe",
    designation: "PIPBN203X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "188,87",
    d_global: 203,
    i_global: "6890,31",
    t_global: 36,
    z_global: "678,85",
  },
  {
    profile_section_id: 6248,
    name: "PIPBN203X40",
    type: "Pipe",
    designation: "PIPBN203X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "204,83",
    d_global: 203,
    i_global: "7212,39",
    t_global: 40,
    z_global: "710,58",
  },
  {
    profile_section_id: 6249,
    name: "PIPBN203X45",
    type: "Pipe",
    designation: "PIPBN203X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "223,37",
    d_global: 203,
    i_global: "7535,57",
    t_global: 45,
    z_global: "742,42",
  },
  {
    profile_section_id: 6250,
    name: "PIPBN203X50",
    type: "Pipe",
    designation: "PIPBN203X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "240,33",
    d_global: 203,
    i_global: "7783,45",
    t_global: 50,
    z_global: "766,84",
  },
  {
    profile_section_id: 6251,
    name: "PIPBN212X21",
    type: "Pipe",
    designation: "PIPBN212X21",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "126,01",
    d_global: 212,
    i_global: "5815,64",
    t_global: 21,
    z_global: "548,65",
  },
  {
    profile_section_id: 6252,
    name: "PIPBN212X31",
    type: "Pipe",
    designation: "PIPBN212X31",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "176,27",
    d_global: 212,
    i_global: "7430,42",
    t_global: 31,
    z_global: "700,98",
  },
  {
    profile_section_id: 6253,
    name: "PIPBN212X43.5",
    type: "Pipe",
    designation: "PIPBN212X43.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "230,27",
    d_global: 212,
    i_global: "8717,05",
    t_global: "43,5",
    z_global: "822,36",
  },
  {
    profile_section_id: 6254,
    name: "PIPBN216X6.3",
    type: "Pipe",
    designation: "PIPBN216X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "41,5",
    d_global: 216,
    i_global: "2283,43",
    t_global: "6,3",
    z_global: "211,43",
  },
  {
    profile_section_id: 6255,
    name: "PIPBN216X7.1",
    type: "Pipe",
    designation: "PIPBN216X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "46,6",
    d_global: 216,
    i_global: "2544,69",
    t_global: "7,1",
    z_global: "235,62",
  },
  {
    profile_section_id: 6256,
    name: "PIPBN216X8",
    type: "Pipe",
    designation: "PIPBN216X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "52,28",
    d_global: 216,
    i_global: "2831,27",
    t_global: 8,
    z_global: "262,15",
  },
  {
    profile_section_id: 6257,
    name: "PIPBN216X8.8",
    type: "Pipe",
    designation: "PIPBN216X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "57,28",
    d_global: 216,
    i_global: "3079,6",
    t_global: "8,8",
    z_global: "285,15",
  },
  {
    profile_section_id: 6258,
    name: "PIPBN216X10",
    type: "Pipe",
    designation: "PIPBN216X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "64,72",
    d_global: 216,
    i_global: "3440,99",
    t_global: 10,
    z_global: "318,61",
  },
  {
    profile_section_id: 6259,
    name: "PIPBN216X11",
    type: "Pipe",
    designation: "PIPBN216X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "70,84",
    d_global: 216,
    i_global: "3732,18",
    t_global: 11,
    z_global: "345,57",
  },
  {
    profile_section_id: 6260,
    name: "PIPBN216X12.5",
    type: "Pipe",
    designation: "PIPBN216X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "79,91",
    d_global: 216,
    i_global: "4152,4",
    t_global: "12,5",
    z_global: "384,48",
  },
  {
    profile_section_id: 6261,
    name: "PIPBN216X14.2",
    type: "Pipe",
    designation: "PIPBN216X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "90,02",
    d_global: 216,
    i_global: "4605,29",
    t_global: "14,2",
    z_global: "426,42",
  },
  {
    profile_section_id: 6262,
    name: "PIPBN216X16",
    type: "Pipe",
    designation: "PIPBN216X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "100,53",
    d_global: 216,
    i_global: "5058,72",
    t_global: 16,
    z_global: "468,4",
  },
  {
    profile_section_id: 6263,
    name: "PIPBN216X17.5",
    type: "Pipe",
    designation: "PIPBN216X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "109,13",
    d_global: 216,
    i_global: "5416,79",
    t_global: "17,5",
    z_global: "501,56",
  },
  {
    profile_section_id: 6264,
    name: "PIPBN216X20",
    type: "Pipe",
    designation: "PIPBN216X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "123,15",
    d_global: 216,
    i_global: "5975,26",
    t_global: 20,
    z_global: "553,26",
  },
  {
    profile_section_id: 6265,
    name: "PIPBN216X22.2",
    type: "Pipe",
    designation: "PIPBN216X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "135,16",
    d_global: 216,
    i_global: "6428,89",
    t_global: "22,2",
    z_global: "595,27",
  },
  {
    profile_section_id: 6266,
    name: "PIPBN216X25",
    type: "Pipe",
    designation: "PIPBN216X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "150,01",
    d_global: 216,
    i_global: "6957,89",
    t_global: 25,
    z_global: "644,25",
  },
  {
    profile_section_id: 6267,
    name: "PIPBN216X28",
    type: "Pipe",
    designation: "PIPBN216X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "165,37",
    d_global: 216,
    i_global: "7468,26",
    t_global: 28,
    z_global: "691,51",
  },
  {
    profile_section_id: 6268,
    name: "PIPBN216X30",
    type: "Pipe",
    designation: "PIPBN216X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "175,3",
    d_global: 216,
    i_global: "7778,1",
    t_global: 30,
    z_global: "720,19",
  },
  {
    profile_section_id: 6269,
    name: "PIPBN216X32",
    type: "Pipe",
    designation: "PIPBN216X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "184,98",
    d_global: 216,
    i_global: 8065,
    t_global: 32,
    z_global: "746,76",
  },
  {
    profile_section_id: 6270,
    name: "PIPBN216X36",
    type: "Pipe",
    designation: "PIPBN216X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "203,58",
    d_global: 216,
    i_global: "8574,59",
    t_global: 36,
    z_global: "793,94",
  },
  {
    profile_section_id: 6271,
    name: "PIPBN216X40",
    type: "Pipe",
    designation: "PIPBN216X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "221,17",
    d_global: 216,
    i_global: "9005,97",
    t_global: 40,
    z_global: "833,89",
  },
  {
    profile_section_id: 6272,
    name: "PIPBN216X45",
    type: "Pipe",
    designation: "PIPBN216X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "241,75",
    d_global: 216,
    i_global: "9448,02",
    t_global: 45,
    z_global: "874,82",
  },
  {
    profile_section_id: 6273,
    name: "PIPBN216X50",
    type: "Pipe",
    designation: "PIPBN216X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "260,75",
    d_global: 216,
    i_global: "9796,46",
    t_global: 50,
    z_global: "907,08",
  },
  {
    profile_section_id: 6274,
    name: "PIPBN219.1X6.3",
    type: "Pipe",
    designation: "PIPBN219.1X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "42,12",
    d_global: "219,1",
    i_global: "2386,14",
    t_global: "6,3",
    z_global: "217,81",
  },
  {
    profile_section_id: 6275,
    name: "PIPBN219.1X7.1",
    type: "Pipe",
    designation: "PIPBN219.1X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "47,29",
    d_global: "219,1",
    i_global: "2659,58",
    t_global: "7,1",
    z_global: "242,77",
  },
  {
    profile_section_id: 6276,
    name: "PIPBN219.1X8",
    type: "Pipe",
    designation: "PIPBN219.1X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "53,06",
    d_global: "219,1",
    i_global: "2959,63",
    t_global: 8,
    z_global: "270,16",
  },
  {
    profile_section_id: 6277,
    name: "PIPBN219.1X8.8",
    type: "Pipe",
    designation: "PIPBN219.1X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "58,14",
    d_global: "219,1",
    i_global: "3219,74",
    t_global: "8,8",
    z_global: "293,91",
  },
  {
    profile_section_id: 6278,
    name: "PIPBN219.1X10",
    type: "Pipe",
    designation: "PIPBN219.1X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "65,69",
    d_global: "219,1",
    i_global: "3598,44",
    t_global: 10,
    z_global: "328,48",
  },
  {
    profile_section_id: 6279,
    name: "PIPBN219.1X11",
    type: "Pipe",
    designation: "PIPBN219.1X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "71,91",
    d_global: "219,1",
    i_global: "3903,74",
    t_global: 11,
    z_global: "356,34",
  },
  {
    profile_section_id: 6280,
    name: "PIPBN219.1X12.5",
    type: "Pipe",
    designation: "PIPBN219.1X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "81,13",
    d_global: "219,1",
    i_global: "4344,58",
    t_global: "12,5",
    z_global: "396,58",
  },
  {
    profile_section_id: 6281,
    name: "PIPBN219.1X14.2",
    type: "Pipe",
    designation: "PIPBN219.1X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "91,41",
    d_global: "219,1",
    i_global: "4820,09",
    t_global: "14,2",
    z_global: "439,99",
  },
  {
    profile_section_id: 6282,
    name: "PIPBN219.1X16",
    type: "Pipe",
    designation: "PIPBN219.1X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "102,09",
    d_global: "219,1",
    i_global: "5296,59",
    t_global: 16,
    z_global: "483,49",
  },
  {
    profile_section_id: 6283,
    name: "PIPBN219.1X17.5",
    type: "Pipe",
    designation: "PIPBN219.1X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "110,84",
    d_global: "219,1",
    i_global: "5673,22",
    t_global: "17,5",
    z_global: "517,87",
  },
  {
    profile_section_id: 6284,
    name: "PIPBN219.1X20",
    type: "Pipe",
    designation: "PIPBN219.1X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "125,1",
    d_global: "219,1",
    i_global: "6261,29",
    t_global: 20,
    z_global: "571,55",
  },
  {
    profile_section_id: 6285,
    name: "PIPBN219.1X22.2",
    type: "Pipe",
    designation: "PIPBN219.1X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "137,32",
    d_global: "219,1",
    i_global: "6739,63",
    t_global: "22,2",
    z_global: "615,21",
  },
  {
    profile_section_id: 6286,
    name: "PIPBN219.1X25",
    type: "Pipe",
    designation: "PIPBN219.1X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "152,45",
    d_global: "219,1",
    i_global: "7298,31",
    t_global: 25,
    z_global: "666,21",
  },
  {
    profile_section_id: 6287,
    name: "PIPBN219.1X28",
    type: "Pipe",
    designation: "PIPBN219.1X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "168,1",
    d_global: "219,1",
    i_global: "7838,35",
    t_global: 28,
    z_global: "715,5",
  },
  {
    profile_section_id: 6288,
    name: "PIPBN219.1X30",
    type: "Pipe",
    designation: "PIPBN219.1X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "178,22",
    d_global: "219,1",
    i_global: "8166,78",
    t_global: 30,
    z_global: "745,49",
  },
  {
    profile_section_id: 6289,
    name: "PIPBN219.1X32",
    type: "Pipe",
    designation: "PIPBN219.1X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "188,09",
    d_global: "219,1",
    i_global: "8471,35",
    t_global: 32,
    z_global: "773,29",
  },
  {
    profile_section_id: 6290,
    name: "PIPBN219.1X36",
    type: "Pipe",
    designation: "PIPBN219.1X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "207,08",
    d_global: "219,1",
    i_global: "9013,63",
    t_global: 36,
    z_global: "822,79",
  },
  {
    profile_section_id: 6291,
    name: "PIPBN219.1X40",
    type: "Pipe",
    designation: "PIPBN219.1X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "225,06",
    d_global: "219,1",
    i_global: "9474,28",
    t_global: 40,
    z_global: "864,84",
  },
  {
    profile_section_id: 6292,
    name: "PIPBN219.1X45",
    type: "Pipe",
    designation: "PIPBN219.1X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "246,13",
    d_global: "219,1",
    i_global: "9948,44",
    t_global: 45,
    z_global: "908,12",
  },
  {
    profile_section_id: 6293,
    name: "PIPBN219.1X50",
    type: "Pipe",
    designation: "PIPBN219.1X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "265,62",
    d_global: "219,1",
    i_global: "10324,32",
    t_global: 50,
    z_global: "942,43",
  },
  {
    profile_section_id: 6294,
    name: "PIPBN219.1X55",
    type: "Pipe",
    designation: "PIPBN219.1X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "283,54",
    d_global: "219,1",
    i_global: "10616,55",
    t_global: 55,
    z_global: "969,11",
  },
  {
    profile_section_id: 6295,
    name: "PIPBN224X22",
    type: "Pipe",
    designation: "PIPBN224X22",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "139,61",
    d_global: 224,
    i_global: "7205,4",
    t_global: 22,
    z_global: "643,34",
  },
  {
    profile_section_id: 6296,
    name: "PIPBN224X32",
    type: "Pipe",
    designation: "PIPBN224X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "193,02",
    d_global: 224,
    i_global: "9141,4",
    t_global: 32,
    z_global: "816,2",
  },
  {
    profile_section_id: 6297,
    name: "PIPBN224X46",
    type: "Pipe",
    designation: "PIPBN224X46",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "257,23",
    d_global: 224,
    i_global: "10868,12",
    t_global: 46,
    z_global: "970,37",
  },
  {
    profile_section_id: 6298,
    name: "PIPBN229X6.5",
    type: "Pipe",
    designation: "PIPBN229X6.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "45,44",
    d_global: 229,
    i_global: "2814,06",
    t_global: "6,5",
    z_global: "245,77",
  },
  {
    profile_section_id: 6299,
    name: "PIPBN229X7.1",
    type: "Pipe",
    designation: "PIPBN229X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "49,5",
    d_global: 229,
    i_global: "3049,54",
    t_global: "7,1",
    z_global: "266,33",
  },
  {
    profile_section_id: 6300,
    name: "PIPBN229X8",
    type: "Pipe",
    designation: "PIPBN229X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "55,54",
    d_global: 229,
    i_global: "3395,43",
    t_global: 8,
    z_global: "296,55",
  },
  {
    profile_section_id: 6301,
    name: "PIPBN229X8.8",
    type: "Pipe",
    designation: "PIPBN229X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "60,88",
    d_global: 229,
    i_global: "3695,62",
    t_global: "8,8",
    z_global: "322,76",
  },
  {
    profile_section_id: 6302,
    name: "PIPBN229X10",
    type: "Pipe",
    designation: "PIPBN229X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "68,8",
    d_global: 229,
    i_global: "4133,3",
    t_global: 10,
    z_global: "360,99",
  },
  {
    profile_section_id: 6303,
    name: "PIPBN229X11",
    type: "Pipe",
    designation: "PIPBN229X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "75,34",
    d_global: 229,
    i_global: "4486,69",
    t_global: 11,
    z_global: "391,85",
  },
  {
    profile_section_id: 6304,
    name: "PIPBN229X12.5",
    type: "Pipe",
    designation: "PIPBN229X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "85,02",
    d_global: 229,
    i_global: "4997,92",
    t_global: "12,5",
    z_global: "436,5",
  },
  {
    profile_section_id: 6305,
    name: "PIPBN229X14.2",
    type: "Pipe",
    designation: "PIPBN229X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "95,82",
    d_global: 229,
    i_global: "5550,66",
    t_global: "14,2",
    z_global: "484,77",
  },
  {
    profile_section_id: 6306,
    name: "PIPBN229X16",
    type: "Pipe",
    designation: "PIPBN229X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "107,07",
    d_global: 229,
    i_global: "6106,08",
    t_global: 16,
    z_global: "533,28",
  },
  {
    profile_section_id: 6307,
    name: "PIPBN229X17.5",
    type: "Pipe",
    designation: "PIPBN229X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "116,28",
    d_global: 229,
    i_global: "6546,24",
    t_global: "17,5",
    z_global: "571,72",
  },
  {
    profile_section_id: 6308,
    name: "PIPBN229X20",
    type: "Pipe",
    designation: "PIPBN229X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "131,32",
    d_global: 229,
    i_global: "7235,82",
    t_global: 20,
    z_global: "631,95",
  },
  {
    profile_section_id: 6309,
    name: "PIPBN229X22.2",
    type: "Pipe",
    designation: "PIPBN229X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "144,23",
    d_global: 229,
    i_global: "7799,03",
    t_global: "22,2",
    z_global: "681,14",
  },
  {
    profile_section_id: 6310,
    name: "PIPBN229X25",
    type: "Pipe",
    designation: "PIPBN229X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "160,22",
    d_global: 229,
    i_global: "8459,88",
    t_global: 25,
    z_global: "738,85",
  },
  {
    profile_section_id: 6311,
    name: "PIPBN229X28",
    type: "Pipe",
    designation: "PIPBN229X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "176,81",
    d_global: 229,
    i_global: "9102,34",
    t_global: 28,
    z_global: "794,96",
  },
  {
    profile_section_id: 6312,
    name: "PIPBN229X30",
    type: "Pipe",
    designation: "PIPBN229X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "187,55",
    d_global: 229,
    i_global: "9495,11",
    t_global: 30,
    z_global: "829,27",
  },
  {
    profile_section_id: 6313,
    name: "PIPBN229X36",
    type: "Pipe",
    designation: "PIPBN229X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "218,28",
    d_global: 229,
    i_global: "10516,9",
    t_global: 36,
    z_global: "918,51",
  },
  {
    profile_section_id: 6314,
    name: "PIPBN229X40",
    type: "Pipe",
    designation: "PIPBN229X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "237,5",
    d_global: 229,
    i_global: "11079,88",
    t_global: 40,
    z_global: "967,67",
  },
  {
    profile_section_id: 6315,
    name: "PIPBN229X45",
    type: "Pipe",
    designation: "PIPBN229X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "260,12",
    d_global: 229,
    i_global: "11666,88",
    t_global: 45,
    z_global: "1018,94",
  },
  {
    profile_section_id: 6316,
    name: "PIPBN229X50",
    type: "Pipe",
    designation: "PIPBN229X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "281,17",
    d_global: 229,
    i_global: "12139,98",
    t_global: 50,
    z_global: "1060,26",
  },
  {
    profile_section_id: 6317,
    name: "PIPBN236X23",
    type: "Pipe",
    designation: "PIPBN236X23",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "153,91",
    d_global: 236,
    i_global: "8830,01",
    t_global: 23,
    z_global: "748,31",
  },
  {
    profile_section_id: 6318,
    name: "PIPBN236X33",
    type: "Pipe",
    designation: "PIPBN236X33",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "210,46",
    d_global: 236,
    i_global: "11127,3",
    t_global: 33,
    z_global: "942,99",
  },
  {
    profile_section_id: 6319,
    name: "PIPBN236X48",
    type: "Pipe",
    designation: "PIPBN236X48",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "283,5",
    d_global: 236,
    i_global: "13341,38",
    t_global: 48,
    z_global: "1130,63",
  },
  {
    profile_section_id: 6320,
    name: "PIPBN241X7.1",
    type: "Pipe",
    designation: "PIPBN241X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "52,17",
    d_global: 241,
    i_global: "3571,16",
    t_global: "7,1",
    z_global: "296,36",
  },
  {
    profile_section_id: 6321,
    name: "PIPBN241X8",
    type: "Pipe",
    designation: "PIPBN241X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "58,56",
    d_global: 241,
    i_global: "3978,59",
    t_global: 8,
    z_global: "330,17",
  },
  {
    profile_section_id: 6322,
    name: "PIPBN241X8.8",
    type: "Pipe",
    designation: "PIPBN241X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "64,19",
    d_global: 241,
    i_global: "4332,64",
    t_global: "8,8",
    z_global: "359,56",
  },
  {
    profile_section_id: 6323,
    name: "PIPBN241X10",
    type: "Pipe",
    designation: "PIPBN241X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "72,57",
    d_global: 241,
    i_global: "4849,63",
    t_global: 10,
    z_global: "402,46",
  },
  {
    profile_section_id: 6324,
    name: "PIPBN241X11",
    type: "Pipe",
    designation: "PIPBN241X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "79,48",
    d_global: 241,
    i_global: "5267,79",
    t_global: 11,
    z_global: "437,16",
  },
  {
    profile_section_id: 6325,
    name: "PIPBN241X12.5",
    type: "Pipe",
    designation: "PIPBN241X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "89,73",
    d_global: 241,
    i_global: "5873,9",
    t_global: "12,5",
    z_global: "487,46",
  },
  {
    profile_section_id: 6326,
    name: "PIPBN241X14.2",
    type: "Pipe",
    designation: "PIPBN241X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "101,18",
    d_global: 241,
    i_global: "6530,95",
    t_global: "14,2",
    z_global: "541,99",
  },
  {
    profile_section_id: 6327,
    name: "PIPBN241X16",
    type: "Pipe",
    designation: "PIPBN241X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "113,1",
    d_global: 241,
    i_global: "7193,13",
    t_global: 16,
    z_global: "596,94",
  },
  {
    profile_section_id: 6328,
    name: "PIPBN241X17.5",
    type: "Pipe",
    designation: "PIPBN241X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "122,88",
    d_global: 241,
    i_global: "7719,43",
    t_global: "17,5",
    z_global: "640,62",
  },
  {
    profile_section_id: 6329,
    name: "PIPBN241X20",
    type: "Pipe",
    designation: "PIPBN241X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "138,86",
    d_global: 241,
    i_global: "8546,91",
    t_global: 20,
    z_global: "709,29",
  },
  {
    profile_section_id: 6330,
    name: "PIPBN241X22.2",
    type: "Pipe",
    designation: "PIPBN241X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "152,6",
    d_global: 241,
    i_global: "9225,77",
    t_global: "22,2",
    z_global: "765,62",
  },
  {
    profile_section_id: 6331,
    name: "PIPBN241X25",
    type: "Pipe",
    designation: "PIPBN241X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "169,65",
    d_global: 241,
    i_global: "10026,29",
    t_global: 25,
    z_global: "832,06",
  },
  {
    profile_section_id: 6332,
    name: "PIPBN241X28",
    type: "Pipe",
    designation: "PIPBN241X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "187,36",
    d_global: 241,
    i_global: "10809,3",
    t_global: 28,
    z_global: "897,04",
  },
  {
    profile_section_id: 6333,
    name: "PIPBN241X30",
    type: "Pipe",
    designation: "PIPBN241X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "198,86",
    d_global: 241,
    i_global: "11290,68",
    t_global: 30,
    z_global: "936,99",
  },
  {
    profile_section_id: 6334,
    name: "PIPBN241X32",
    type: "Pipe",
    designation: "PIPBN241X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "210,11",
    d_global: 241,
    i_global: "11741,19",
    t_global: 32,
    z_global: "974,37",
  },
  {
    profile_section_id: 6335,
    name: "PIPBN241X36",
    type: "Pipe",
    designation: "PIPBN241X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "231,85",
    d_global: 241,
    i_global: "12554,94",
    t_global: 36,
    z_global: "1041,9",
  },
  {
    profile_section_id: 6336,
    name: "PIPBN241X40",
    type: "Pipe",
    designation: "PIPBN241X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "252,58",
    d_global: 241,
    i_global: "13260,98",
    t_global: 40,
    z_global: "1100,5",
  },
  {
    profile_section_id: 6337,
    name: "PIPBN241X45",
    type: "Pipe",
    designation: "PIPBN241X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "277,09",
    d_global: 241,
    i_global: "14007,17",
    t_global: 45,
    z_global: "1162,42",
  },
  {
    profile_section_id: 6338,
    name: "PIPBN241X50",
    type: "Pipe",
    designation: "PIPBN241X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "300,02",
    d_global: 241,
    i_global: "14618,95",
    t_global: 50,
    z_global: "1213,19",
  },
  {
    profile_section_id: 6339,
    name: "PIPBN244.5X6.3",
    type: "Pipe",
    designation: "PIPBN244.5X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "47,14",
    d_global: "244,5",
    i_global: "3346,03",
    t_global: "6,3",
    z_global: "273,7",
  },
  {
    profile_section_id: 6340,
    name: "PIPBN244.5X7.1",
    type: "Pipe",
    designation: "PIPBN244.5X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "52,95",
    d_global: "244,5",
    i_global: "3733,78",
    t_global: "7,1",
    z_global: "305,42",
  },
  {
    profile_section_id: 6341,
    name: "PIPBN244.5X8",
    type: "Pipe",
    designation: "PIPBN244.5X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "59,44",
    d_global: "244,5",
    i_global: "4160,45",
    t_global: 8,
    z_global: "340,32",
  },
  {
    profile_section_id: 6342,
    name: "PIPBN244.5X8.8",
    type: "Pipe",
    designation: "PIPBN244.5X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "65,16",
    d_global: "244,5",
    i_global: "4531,34",
    t_global: "8,8",
    z_global: "370,66",
  },
  {
    profile_section_id: 6343,
    name: "PIPBN244.5X10",
    type: "Pipe",
    designation: "PIPBN244.5X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "73,67",
    d_global: "244,5",
    i_global: "5073,15",
    t_global: 10,
    z_global: "414,98",
  },
  {
    profile_section_id: 6344,
    name: "PIPBN244.5X11",
    type: "Pipe",
    designation: "PIPBN244.5X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "80,69",
    d_global: "244,5",
    i_global: "5511,58",
    t_global: 11,
    z_global: "450,85",
  },
  {
    profile_section_id: 6345,
    name: "PIPBN244.5X12.5",
    type: "Pipe",
    designation: "PIPBN244.5X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "91,11",
    d_global: "244,5",
    i_global: "6147,42",
    t_global: "12,5",
    z_global: "502,86",
  },
  {
    profile_section_id: 6346,
    name: "PIPBN244.5X14.2",
    type: "Pipe",
    designation: "PIPBN244.5X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "102,74",
    d_global: "244,5",
    i_global: "6837,2",
    t_global: "14,2",
    z_global: "559,28",
  },
  {
    profile_section_id: 6347,
    name: "PIPBN244.5X16",
    type: "Pipe",
    designation: "PIPBN244.5X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "114,86",
    d_global: "244,5",
    i_global: "7532,91",
    t_global: 16,
    z_global: "616,19",
  },
  {
    profile_section_id: 6348,
    name: "PIPBN244.5X17.5",
    type: "Pipe",
    designation: "PIPBN244.5X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "124,8",
    d_global: "244,5",
    i_global: "8086,28",
    t_global: "17,5",
    z_global: "661,46",
  },
  {
    profile_section_id: 6349,
    name: "PIPBN244.5X20",
    type: "Pipe",
    designation: "PIPBN244.5X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "141,06",
    d_global: "244,5",
    i_global: "8957,2",
    t_global: 20,
    z_global: "732,7",
  },
  {
    profile_section_id: 6350,
    name: "PIPBN244.5X22.2",
    type: "Pipe",
    designation: "PIPBN244.5X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "155,04",
    d_global: "244,5",
    i_global: "9672,55",
    t_global: "22,2",
    z_global: "791,21",
  },
  {
    profile_section_id: 6351,
    name: "PIPBN244.5X25",
    type: "Pipe",
    designation: "PIPBN244.5X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "172,39",
    d_global: "244,5",
    i_global: "10517,22",
    t_global: 25,
    z_global: "860,3",
  },
  {
    profile_section_id: 6352,
    name: "PIPBN244.5X28",
    type: "Pipe",
    designation: "PIPBN244.5X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "190,44",
    d_global: "244,5",
    i_global: "11344,77",
    t_global: 28,
    z_global: 928,
  },
  {
    profile_section_id: 6353,
    name: "PIPBN244.5X30",
    type: "Pipe",
    designation: "PIPBN244.5X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "202,16",
    d_global: "244,5",
    i_global: "11854,31",
    t_global: 30,
    z_global: "969,68",
  },
  {
    profile_section_id: 6354,
    name: "PIPBN244.5X32",
    type: "Pipe",
    designation: "PIPBN244.5X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "213,63",
    d_global: "244,5",
    i_global: "12331,76",
    t_global: 32,
    z_global: "1008,73",
  },
  {
    profile_section_id: 6355,
    name: "PIPBN244.5X36",
    type: "Pipe",
    designation: "PIPBN244.5X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "235,81",
    d_global: "244,5",
    i_global: "13195,89",
    t_global: 36,
    z_global: "1079,42",
  },
  {
    profile_section_id: 6356,
    name: "PIPBN244.5X40",
    type: "Pipe",
    designation: "PIPBN244.5X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "256,98",
    d_global: "244,5",
    i_global: "13947,79",
    t_global: 40,
    z_global: "1140,92",
  },
  {
    profile_section_id: 6357,
    name: "PIPBN244.5X45",
    type: "Pipe",
    designation: "PIPBN244.5X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "282,04",
    d_global: "244,5",
    i_global: "14745,31",
    t_global: 45,
    z_global: "1206,16",
  },
  {
    profile_section_id: 6358,
    name: "PIPBN244.5X50",
    type: "Pipe",
    designation: "PIPBN244.5X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "305,52",
    d_global: "244,5",
    i_global: "15402,12",
    t_global: 50,
    z_global: "1259,89",
  },
  {
    profile_section_id: 6359,
    name: "PIPBN244.5X55",
    type: "Pipe",
    designation: "PIPBN244.5X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "327,43",
    d_global: "244,5",
    i_global: "15935,83",
    t_global: 55,
    z_global: "1303,55",
  },
  {
    profile_section_id: 6360,
    name: "PIPBN244.5X60",
    type: "Pipe",
    designation: "PIPBN244.5X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "347,77",
    d_global: "244,5",
    i_global: "16362,89",
    t_global: 60,
    z_global: "1338,48",
  },
  {
    profile_section_id: 6361,
    name: "PIPBN244.5X65",
    type: "Pipe",
    designation: "PIPBN244.5X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "366,55",
    d_global: "244,5",
    i_global: "16698,54",
    t_global: 65,
    z_global: "1365,93",
  },
  {
    profile_section_id: 6362,
    name: "PIPBN244.5X70",
    type: "Pipe",
    designation: "PIPBN244.5X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "383,75",
    d_global: "244,5",
    i_global: "16956,88",
    t_global: 70,
    z_global: "1387,07",
  },
  {
    profile_section_id: 6363,
    name: "PIPBN250X25",
    type: "Pipe",
    designation: "PIPBN250X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "176,71",
    d_global: 250,
    i_global: "11320,78",
    t_global: 25,
    z_global: "905,66",
  },
  {
    profile_section_id: 6364,
    name: "PIPBN250X35",
    type: "Pipe",
    designation: "PIPBN250X35",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "236,4",
    d_global: 250,
    i_global: "14021,76",
    t_global: 35,
    z_global: "1121,74",
  },
  {
    profile_section_id: 6365,
    name: "PIPBN250X50",
    type: "Pipe",
    designation: "PIPBN250X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "314,16",
    d_global: 250,
    i_global: "16689,71",
    t_global: 50,
    z_global: "1335,18",
  },
  {
    profile_section_id: 6366,
    name: "PIPBN254X6.5",
    type: "Pipe",
    designation: "PIPBN254X6.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "50,54",
    d_global: 254,
    i_global: "3872,56",
    t_global: "6,5",
    z_global: "304,93",
  },
  {
    profile_section_id: 6367,
    name: "PIPBN254X7.1",
    type: "Pipe",
    designation: "PIPBN254X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "55,07",
    d_global: 254,
    i_global: "4199,91",
    t_global: "7,1",
    z_global: "330,7",
  },
  {
    profile_section_id: 6368,
    name: "PIPBN254X8",
    type: "Pipe",
    designation: "PIPBN254X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "61,83",
    d_global: 254,
    i_global: "4681,81",
    t_global: 8,
    z_global: "368,65",
  },
  {
    profile_section_id: 6369,
    name: "PIPBN254X8.8",
    type: "Pipe",
    designation: "PIPBN254X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "67,79",
    d_global: 254,
    i_global: "5101,09",
    t_global: "8,8",
    z_global: "401,66",
  },
  {
    profile_section_id: 6370,
    name: "PIPBN254X10",
    type: "Pipe",
    designation: "PIPBN254X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "76,65",
    d_global: 254,
    i_global: "5714,24",
    t_global: 10,
    z_global: "449,94",
  },
  {
    profile_section_id: 6371,
    name: "PIPBN254X11",
    type: "Pipe",
    designation: "PIPBN254X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "83,97",
    d_global: 254,
    i_global: "6210,98",
    t_global: 11,
    z_global: "489,05",
  },
  {
    profile_section_id: 6372,
    name: "PIPBN254X12.5",
    type: "Pipe",
    designation: "PIPBN254X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "94,84",
    d_global: 254,
    i_global: "6932,39",
    t_global: "12,5",
    z_global: "545,86",
  },
  {
    profile_section_id: 6373,
    name: "PIPBN254X14.2",
    type: "Pipe",
    designation: "PIPBN254X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "106,98",
    d_global: 254,
    i_global: "7716,42",
    t_global: "14,2",
    z_global: "607,59",
  },
  {
    profile_section_id: 6374,
    name: "PIPBN254X16",
    type: "Pipe",
    designation: "PIPBN254X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "119,63",
    d_global: 254,
    i_global: "8508,82",
    t_global: 16,
    z_global: "669,99",
  },
  {
    profile_section_id: 6375,
    name: "PIPBN254X17.5",
    type: "Pipe",
    designation: "PIPBN254X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "130,02",
    d_global: 254,
    i_global: "9140,35",
    t_global: "17,5",
    z_global: "719,71",
  },
  {
    profile_section_id: 6376,
    name: "PIPBN254X20",
    type: "Pipe",
    designation: "PIPBN254X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "147,03",
    d_global: 254,
    i_global: "10136,75",
    t_global: 20,
    z_global: "798,17",
  },
  {
    profile_section_id: 6377,
    name: "PIPBN254X22.2",
    type: "Pipe",
    designation: "PIPBN254X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "161,67",
    d_global: 254,
    i_global: "10957,68",
    t_global: "22,2",
    z_global: "862,81",
  },
  {
    profile_section_id: 6378,
    name: "PIPBN254X25",
    type: "Pipe",
    designation: "PIPBN254X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "179,86",
    d_global: 254,
    i_global: "11930,31",
    t_global: 25,
    z_global: "939,39",
  },
  {
    profile_section_id: 6379,
    name: "PIPBN254X28",
    type: "Pipe",
    designation: "PIPBN254X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "198,8",
    d_global: 254,
    i_global: "12887,21",
    t_global: 28,
    z_global: "1014,74",
  },
  {
    profile_section_id: 6380,
    name: "PIPBN254X30",
    type: "Pipe",
    designation: "PIPBN254X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "211,12",
    d_global: 254,
    i_global: "13478,64",
    t_global: 30,
    z_global: "1061,31",
  },
  {
    profile_section_id: 6381,
    name: "PIPBN254X36",
    type: "Pipe",
    designation: "PIPBN254X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "246,55",
    d_global: 254,
    i_global: "15045,85",
    t_global: 36,
    z_global: "1184,71",
  },
  {
    profile_section_id: 6382,
    name: "PIPBN254X40",
    type: "Pipe",
    designation: "PIPBN254X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "268,92",
    d_global: 254,
    i_global: "15932,18",
    t_global: 40,
    z_global: "1254,5",
  },
  {
    profile_section_id: 6383,
    name: "PIPBN254X45",
    type: "Pipe",
    designation: "PIPBN254X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "295,47",
    d_global: 254,
    i_global: "16880,76",
    t_global: 45,
    z_global: "1329,19",
  },
  {
    profile_section_id: 6384,
    name: "PIPBN254X50",
    type: "Pipe",
    designation: "PIPBN254X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "320,44",
    d_global: 254,
    i_global: "17670,8",
    t_global: 50,
    z_global: "1391,4",
  },
  {
    profile_section_id: 6385,
    name: "PIPBN254X60",
    type: "Pipe",
    designation: "PIPBN254X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "365,68",
    d_global: 254,
    i_global: "18849,05",
    t_global: 60,
    z_global: "1484,18",
  },
  {
    profile_section_id: 6386,
    name: "PIPBN267X6.3",
    type: "Pipe",
    designation: "PIPBN267X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "51,6",
    d_global: 267,
    i_global: "4386,09",
    t_global: "6,3",
    z_global: "328,55",
  },
  {
    profile_section_id: 6387,
    name: "PIPBN267X7.1",
    type: "Pipe",
    designation: "PIPBN267X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "57,97",
    d_global: 267,
    i_global: "4898,48",
    t_global: "7,1",
    z_global: "366,93",
  },
  {
    profile_section_id: 6388,
    name: "PIPBN267X8",
    type: "Pipe",
    designation: "PIPBN267X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "65,09",
    d_global: 267,
    i_global: "5463,4",
    t_global: 8,
    z_global: "409,24",
  },
  {
    profile_section_id: 6389,
    name: "PIPBN267X8.8",
    type: "Pipe",
    designation: "PIPBN267X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "71,38",
    d_global: 267,
    i_global: "5955,46",
    t_global: "8,8",
    z_global: "446,1",
  },
  {
    profile_section_id: 6390,
    name: "PIPBN267X10",
    type: "Pipe",
    designation: "PIPBN267X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "80,74",
    d_global: 267,
    i_global: 6676,
    t_global: 10,
    z_global: "500,07",
  },
  {
    profile_section_id: 6391,
    name: "PIPBN267X11",
    type: "Pipe",
    designation: "PIPBN267X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "88,47",
    d_global: 267,
    i_global: "7260,62",
    t_global: 11,
    z_global: "543,87",
  },
  {
    profile_section_id: 6392,
    name: "PIPBN267X12.5",
    type: "Pipe",
    designation: "PIPBN267X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "99,94",
    d_global: 267,
    i_global: "8111,1",
    t_global: "12,5",
    z_global: "607,57",
  },
  {
    profile_section_id: 6393,
    name: "PIPBN267X14.2",
    type: "Pipe",
    designation: "PIPBN267X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "112,78",
    d_global: 267,
    i_global: "9037,48",
    t_global: "14,2",
    z_global: "676,97",
  },
  {
    profile_section_id: 6394,
    name: "PIPBN267X16",
    type: "Pipe",
    designation: "PIPBN267X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "126,17",
    d_global: 267,
    i_global: "9976,13",
    t_global: 16,
    z_global: "747,28",
  },
  {
    profile_section_id: 6395,
    name: "PIPBN267X17.5",
    type: "Pipe",
    designation: "PIPBN267X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "137,17",
    d_global: 267,
    i_global: "10726,08",
    t_global: "17,5",
    z_global: "803,45",
  },
  {
    profile_section_id: 6396,
    name: "PIPBN267X20",
    type: "Pipe",
    designation: "PIPBN267X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "155,19",
    d_global: 267,
    i_global: "11912,94",
    t_global: 20,
    z_global: "892,36",
  },
  {
    profile_section_id: 6397,
    name: "PIPBN267X22.2",
    type: "Pipe",
    designation: "PIPBN267X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "170,73",
    d_global: 267,
    i_global: "12894,49",
    t_global: "22,2",
    z_global: "965,88",
  },
  {
    profile_section_id: 6398,
    name: "PIPBN267X25",
    type: "Pipe",
    designation: "PIPBN267X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "190,07",
    d_global: 267,
    i_global: "14062,3",
    t_global: 25,
    z_global: "1053,36",
  },
  {
    profile_section_id: 6399,
    name: "PIPBN267X28",
    type: "Pipe",
    designation: "PIPBN267X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "210,24",
    d_global: 267,
    i_global: "15217,1",
    t_global: 28,
    z_global: "1139,86",
  },
  {
    profile_section_id: 6400,
    name: "PIPBN267X30",
    type: "Pipe",
    designation: "PIPBN267X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "223,37",
    d_global: 267,
    i_global: "15934,18",
    t_global: 30,
    z_global: "1193,57",
  },
  {
    profile_section_id: 6401,
    name: "PIPBN267X32",
    type: "Pipe",
    designation: "PIPBN267X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "236,25",
    d_global: 267,
    i_global: "16610,88",
    t_global: 32,
    z_global: "1244,26",
  },
  {
    profile_section_id: 6402,
    name: "PIPBN267X36",
    type: "Pipe",
    designation: "PIPBN267X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "261,25",
    d_global: 267,
    i_global: "17849,26",
    t_global: 36,
    z_global: "1337,02",
  },
  {
    profile_section_id: 6403,
    name: "PIPBN267X40",
    type: "Pipe",
    designation: "PIPBN267X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "285,26",
    d_global: 267,
    i_global: "18944,25",
    t_global: 40,
    z_global: "1419,05",
  },
  {
    profile_section_id: 6404,
    name: "PIPBN267X45",
    type: "Pipe",
    designation: "PIPBN267X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "313,85",
    d_global: 267,
    i_global: "20128,85",
    t_global: 45,
    z_global: "1507,78",
  },
  {
    profile_section_id: 6405,
    name: "PIPBN267X50",
    type: "Pipe",
    designation: "PIPBN267X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "340,86",
    d_global: 267,
    i_global: "21128,81",
    t_global: 50,
    z_global: "1582,68",
  },
  {
    profile_section_id: 6406,
    name: "PIPBN267X55",
    type: "Pipe",
    designation: "PIPBN267X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "366,31",
    d_global: 267,
    i_global: "21964,39",
    t_global: 55,
    z_global: "1645,27",
  },
  {
    profile_section_id: 6407,
    name: "PIPBN267X60",
    type: "Pipe",
    designation: "PIPBN267X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "390,19",
    d_global: 267,
    i_global: "22654,68",
    t_global: 60,
    z_global: "1696,98",
  },
  {
    profile_section_id: 6408,
    name: "PIPBN267X65",
    type: "Pipe",
    designation: "PIPBN267X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "412,49",
    d_global: 267,
    i_global: "23217,58",
    t_global: 65,
    z_global: "1739,14",
  },
  {
    profile_section_id: 6409,
    name: "PIPBN267X70",
    type: "Pipe",
    designation: "PIPBN267X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "433,23",
    d_global: 267,
    i_global: "23669,82",
    t_global: 70,
    z_global: "1773,02",
  },
  {
    profile_section_id: 6410,
    name: "PIPBN267X75",
    type: "Pipe",
    designation: "PIPBN267X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "452,39",
    d_global: 267,
    i_global: "24026,96",
    t_global: 75,
    z_global: "1799,77",
  },
  {
    profile_section_id: 6411,
    name: "PIPBN267X80",
    type: "Pipe",
    designation: "PIPBN267X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "469,98",
    d_global: 267,
    i_global: "24303,37",
    t_global: 80,
    z_global: "1820,48",
  },
  {
    profile_section_id: 6412,
    name: "PIPBN273X6.3",
    type: "Pipe",
    designation: "PIPBN273X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "52,79",
    d_global: 273,
    i_global: "4695,82",
    t_global: "6,3",
    z_global: "344,02",
  },
  {
    profile_section_id: 6413,
    name: "PIPBN273X7.1",
    type: "Pipe",
    designation: "PIPBN273X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "59,31",
    d_global: 273,
    i_global: "5245,45",
    t_global: "7,1",
    z_global: "384,28",
  },
  {
    profile_section_id: 6414,
    name: "PIPBN273X8",
    type: "Pipe",
    designation: "PIPBN273X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "66,6",
    d_global: 273,
    i_global: "5851,71",
    t_global: 8,
    z_global: "428,7",
  },
  {
    profile_section_id: 6415,
    name: "PIPBN273X8.8",
    type: "Pipe",
    designation: "PIPBN273X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "73,04",
    d_global: 273,
    i_global: "6380,03",
    t_global: "8,8",
    z_global: "467,4",
  },
  {
    profile_section_id: 6416,
    name: "PIPBN273X10",
    type: "Pipe",
    designation: "PIPBN273X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "82,62",
    d_global: 273,
    i_global: "7154,09",
    t_global: 10,
    z_global: "524,11",
  },
  {
    profile_section_id: 6417,
    name: "PIPBN273X11",
    type: "Pipe",
    designation: "PIPBN273X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "90,54",
    d_global: 273,
    i_global: "7782,54",
    t_global: 11,
    z_global: "570,15",
  },
  {
    profile_section_id: 6418,
    name: "PIPBN273X12.5",
    type: "Pipe",
    designation: "PIPBN273X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "102,3",
    d_global: 273,
    i_global: "8697,45",
    t_global: "12,5",
    z_global: "637,18",
  },
  {
    profile_section_id: 6419,
    name: "PIPBN273X14.2",
    type: "Pipe",
    designation: "PIPBN273X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "115,45",
    d_global: 273,
    i_global: "9694,97",
    t_global: "14,2",
    z_global: "710,25",
  },
  {
    profile_section_id: 6420,
    name: "PIPBN273X16",
    type: "Pipe",
    designation: "PIPBN273X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "129,18",
    d_global: 273,
    i_global: "10706,79",
    t_global: 16,
    z_global: "784,38",
  },
  {
    profile_section_id: 6421,
    name: "PIPBN273X17.5",
    type: "Pipe",
    designation: "PIPBN273X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "140,47",
    d_global: 273,
    i_global: "11516,04",
    t_global: "17,5",
    z_global: "843,67",
  },
  {
    profile_section_id: 6422,
    name: "PIPBN273X20",
    type: "Pipe",
    designation: "PIPBN273X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "158,96",
    d_global: 273,
    i_global: "12798,44",
    t_global: 20,
    z_global: "937,61",
  },
  {
    profile_section_id: 6423,
    name: "PIPBN273X22.2",
    type: "Pipe",
    designation: "PIPBN273X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "174,92",
    d_global: 273,
    i_global: "13860,69",
    t_global: "22,2",
    z_global: "1015,43",
  },
  {
    profile_section_id: 6424,
    name: "PIPBN273X25",
    type: "Pipe",
    designation: "PIPBN273X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "194,78",
    d_global: 273,
    i_global: "15126,76",
    t_global: 25,
    z_global: "1108,19",
  },
  {
    profile_section_id: 6425,
    name: "PIPBN273X28",
    type: "Pipe",
    designation: "PIPBN273X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "215,51",
    d_global: 273,
    i_global: "16381,43",
    t_global: 28,
    z_global: "1200,11",
  },
  {
    profile_section_id: 6426,
    name: "PIPBN273X30",
    type: "Pipe",
    designation: "PIPBN273X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "229,02",
    d_global: 273,
    i_global: "17162,06",
    t_global: 30,
    z_global: "1257,29",
  },
  {
    profile_section_id: 6427,
    name: "PIPBN273X32",
    type: "Pipe",
    designation: "PIPBN273X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "242,28",
    d_global: 273,
    i_global: "17899,92",
    t_global: 32,
    z_global: "1311,35",
  },
  {
    profile_section_id: 6428,
    name: "PIPBN273X36",
    type: "Pipe",
    designation: "PIPBN273X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "268,04",
    d_global: 273,
    i_global: "19253,7",
    t_global: 36,
    z_global: "1410,53",
  },
  {
    profile_section_id: 6429,
    name: "PIPBN273X40",
    type: "Pipe",
    designation: "PIPBN273X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "292,8",
    d_global: 273,
    i_global: "20455,13",
    t_global: 40,
    z_global: "1498,54",
  },
  {
    profile_section_id: 6430,
    name: "PIPBN273X45",
    type: "Pipe",
    designation: "PIPBN273X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "322,33",
    d_global: 273,
    i_global: "21760,73",
    t_global: 45,
    z_global: "1594,19",
  },
  {
    profile_section_id: 6431,
    name: "PIPBN273X50",
    type: "Pipe",
    designation: "PIPBN273X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "350,29",
    d_global: 273,
    i_global: "22868,96",
    t_global: 50,
    z_global: "1675,38",
  },
  {
    profile_section_id: 6432,
    name: "PIPBN273X55",
    type: "Pipe",
    designation: "PIPBN273X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "376,68",
    d_global: 273,
    i_global: "23800,8",
    t_global: 55,
    z_global: "1743,65",
  },
  {
    profile_section_id: 6433,
    name: "PIPBN273X60",
    type: "Pipe",
    designation: "PIPBN273X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "401,5",
    d_global: 273,
    i_global: "24576,04",
    t_global: 60,
    z_global: "1800,44",
  },
  {
    profile_section_id: 6434,
    name: "PIPBN273X65",
    type: "Pipe",
    designation: "PIPBN273X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "424,74",
    d_global: 273,
    i_global: "25213,29",
    t_global: 65,
    z_global: "1847,13",
  },
  {
    profile_section_id: 6435,
    name: "PIPBN273X70",
    type: "Pipe",
    designation: "PIPBN273X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "446,42",
    d_global: 273,
    i_global: "25729,99",
    t_global: 70,
    z_global: "1884,98",
  },
  {
    profile_section_id: 6436,
    name: "PIPBN273X75",
    type: "Pipe",
    designation: "PIPBN273X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "466,53",
    d_global: 273,
    i_global: "26142,4",
    t_global: 75,
    z_global: "1915,19",
  },
  {
    profile_section_id: 6437,
    name: "PIPBN273X80",
    type: "Pipe",
    designation: "PIPBN273X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "485,06",
    d_global: 273,
    i_global: "26465,58",
    t_global: 80,
    z_global: "1938,87",
  },
  {
    profile_section_id: 6438,
    name: "PIPBN273X85",
    type: "Pipe",
    designation: "PIPBN273X85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "502,03",
    d_global: 273,
    i_global: "26713,46",
    t_global: 85,
    z_global: "1957,03",
  },
  {
    profile_section_id: 6439,
    name: "PIPBN279X8",
    type: "Pipe",
    designation: "PIPBN279X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "68,11",
    d_global: 279,
    i_global: "6258,01",
    t_global: 8,
    z_global: "448,6",
  },
  {
    profile_section_id: 6440,
    name: "PIPBN279X8.8",
    type: "Pipe",
    designation: "PIPBN279X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "74,7",
    d_global: 279,
    i_global: "6824,31",
    t_global: "8,8",
    z_global: "489,2",
  },
  {
    profile_section_id: 6441,
    name: "PIPBN279X10",
    type: "Pipe",
    designation: "PIPBN279X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "84,51",
    d_global: 279,
    i_global: "7654,49",
    t_global: 10,
    z_global: "548,71",
  },
  {
    profile_section_id: 6442,
    name: "PIPBN279X11",
    type: "Pipe",
    designation: "PIPBN279X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "92,61",
    d_global: 279,
    i_global: "8328,91",
    t_global: 11,
    z_global: "597,05",
  },
  {
    profile_section_id: 6443,
    name: "PIPBN279X12.5",
    type: "Pipe",
    designation: "PIPBN279X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "104,65",
    d_global: 279,
    i_global: "9311,42",
    t_global: "12,5",
    z_global: "667,49",
  },
  {
    profile_section_id: 6444,
    name: "PIPBN279X14.2",
    type: "Pipe",
    designation: "PIPBN279X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "118,13",
    d_global: 279,
    i_global: "10383,63",
    t_global: "14,2",
    z_global: "744,35",
  },
  {
    profile_section_id: 6445,
    name: "PIPBN279X16",
    type: "Pipe",
    designation: "PIPBN279X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "132,2",
    d_global: 279,
    i_global: "11472,33",
    t_global: 16,
    z_global: "822,39",
  },
  {
    profile_section_id: 6446,
    name: "PIPBN279X17.5",
    type: "Pipe",
    designation: "PIPBN279X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "143,77",
    d_global: 279,
    i_global: "12343,94",
    t_global: "17,5",
    z_global: "884,87",
  },
  {
    profile_section_id: 6447,
    name: "PIPBN279X20",
    type: "Pipe",
    designation: "PIPBN279X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "162,73",
    d_global: 279,
    i_global: "13726,86",
    t_global: 20,
    z_global: 984,
  },
  {
    profile_section_id: 6448,
    name: "PIPBN279X22.2",
    type: "Pipe",
    designation: "PIPBN279X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "179,1",
    d_global: 279,
    i_global: "14874,13",
    t_global: "22,2",
    z_global: "1066,25",
  },
  {
    profile_section_id: 6449,
    name: "PIPBN279X25",
    type: "Pipe",
    designation: "PIPBN279X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "199,49",
    d_global: 279,
    i_global: "16243,82",
    t_global: 25,
    z_global: "1164,43",
  },
  {
    profile_section_id: 6450,
    name: "PIPBN279X30",
    type: "Pipe",
    designation: "PIPBN279X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "234,68",
    d_global: 279,
    i_global: "18451,77",
    t_global: 30,
    z_global: "1322,71",
  },
  {
    profile_section_id: 6451,
    name: "PIPBN279X36",
    type: "Pipe",
    designation: "PIPBN279X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "274,83",
    d_global: 279,
    i_global: "20730,51",
    t_global: 36,
    z_global: "1486,06",
  },
  {
    profile_section_id: 6452,
    name: "PIPBN279X40",
    type: "Pipe",
    designation: "PIPBN279X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "300,34",
    d_global: 279,
    i_global: "22045,06",
    t_global: 40,
    z_global: "1580,29",
  },
  {
    profile_section_id: 6453,
    name: "PIPBN279X45",
    type: "Pipe",
    designation: "PIPBN279X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "330,81",
    d_global: 279,
    i_global: "23479,63",
    t_global: 45,
    z_global: "1683,13",
  },
  {
    profile_section_id: 6454,
    name: "PIPBN279X50",
    type: "Pipe",
    designation: "PIPBN279X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "359,71",
    d_global: 279,
    i_global: "24703,7",
    t_global: 50,
    z_global: "1770,87",
  },
  {
    profile_section_id: 6455,
    name: "PIPBN292X7.5",
    type: "Pipe",
    designation: "PIPBN292X7.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "67,03",
    d_global: 292,
    i_global: "6786,87",
    t_global: "7,5",
    z_global: "464,85",
  },
  {
    profile_section_id: 6456,
    name: "PIPBN292X8",
    type: "Pipe",
    designation: "PIPBN292X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "71,38",
    d_global: 292,
    i_global: "7201,94",
    t_global: 8,
    z_global: "493,28",
  },
  {
    profile_section_id: 6457,
    name: "PIPBN292X8.8",
    type: "Pipe",
    designation: "PIPBN292X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "78,29",
    d_global: 292,
    i_global: "7856,72",
    t_global: "8,8",
    z_global: "538,13",
  },
  {
    profile_section_id: 6458,
    name: "PIPBN292X10",
    type: "Pipe",
    designation: "PIPBN292X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "88,59",
    d_global: 292,
    i_global: "8817,65",
    t_global: 10,
    z_global: "603,95",
  },
  {
    profile_section_id: 6459,
    name: "PIPBN292X11",
    type: "Pipe",
    designation: "PIPBN292X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "97,11",
    d_global: 292,
    i_global: "9599,23",
    t_global: 11,
    z_global: "657,48",
  },
  {
    profile_section_id: 6460,
    name: "PIPBN292X12.5",
    type: "Pipe",
    designation: "PIPBN292X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "109,76",
    d_global: 292,
    i_global: "10739,48",
    t_global: "12,5",
    z_global: "735,58",
  },
  {
    profile_section_id: 6461,
    name: "PIPBN292X14.2",
    type: "Pipe",
    designation: "PIPBN292X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "123,93",
    d_global: 292,
    i_global: "11986,11",
    t_global: "14,2",
    z_global: "820,97",
  },
  {
    profile_section_id: 6462,
    name: "PIPBN292X16",
    type: "Pipe",
    designation: "PIPBN292X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "138,73",
    d_global: 292,
    i_global: "13254,53",
    t_global: 16,
    z_global: "907,84",
  },
  {
    profile_section_id: 6463,
    name: "PIPBN292X17.5",
    type: "Pipe",
    designation: "PIPBN292X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "150,91",
    d_global: 292,
    i_global: "14272,06",
    t_global: "17,5",
    z_global: "977,54",
  },
  {
    profile_section_id: 6464,
    name: "PIPBN292X20",
    type: "Pipe",
    designation: "PIPBN292X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "170,9",
    d_global: 292,
    i_global: "15890,53",
    t_global: 20,
    z_global: "1088,39",
  },
  {
    profile_section_id: 6465,
    name: "PIPBN292X22.2",
    type: "Pipe",
    designation: "PIPBN292X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "188,17",
    d_global: 292,
    i_global: "17237,3",
    t_global: "22,2",
    z_global: "1180,64",
  },
  {
    profile_section_id: 6466,
    name: "PIPBN292X25",
    type: "Pipe",
    designation: "PIPBN292X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "209,7",
    d_global: 292,
    i_global: "18850,57",
    t_global: 25,
    z_global: "1291,13",
  },
  {
    profile_section_id: 6467,
    name: "PIPBN292X28",
    type: "Pipe",
    designation: "PIPBN292X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "232,23",
    d_global: 292,
    i_global: "20459,16",
    t_global: 28,
    z_global: "1401,31",
  },
  {
    profile_section_id: 6468,
    name: "PIPBN292X30",
    type: "Pipe",
    designation: "PIPBN292X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "246,93",
    d_global: 292,
    i_global: "21465,55",
    t_global: 30,
    z_global: "1470,24",
  },
  {
    profile_section_id: 6469,
    name: "PIPBN292X36",
    type: "Pipe",
    designation: "PIPBN292X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "289,53",
    d_global: 292,
    i_global: "24187,27",
    t_global: 36,
    z_global: "1656,66",
  },
  {
    profile_section_id: 6470,
    name: "PIPBN292X40",
    type: "Pipe",
    designation: "PIPBN292X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "316,67",
    d_global: 292,
    i_global: "25770,81",
    t_global: 40,
    z_global: "1765,12",
  },
  {
    profile_section_id: 6471,
    name: "PIPBN292X45",
    type: "Pipe",
    designation: "PIPBN292X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "349,19",
    d_global: 292,
    i_global: "27513,4",
    t_global: 45,
    z_global: "1884,48",
  },
  {
    profile_section_id: 6472,
    name: "PIPBN292X50",
    type: "Pipe",
    designation: "PIPBN292X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "380,13",
    d_global: 292,
    i_global: "29015,53",
    t_global: 50,
    z_global: "1987,37",
  },
  {
    profile_section_id: 6473,
    name: "PIPBN298.5X7.1",
    type: "Pipe",
    designation: "PIPBN298.5X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 65,
    d_global: "298,5",
    i_global: "6903,11",
    t_global: "7,1",
    z_global: "462,52",
  },
  {
    profile_section_id: 6474,
    name: "PIPBN298.5X8",
    type: "Pipe",
    designation: "PIPBN298.5X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "73,01",
    d_global: "298,5",
    i_global: "7707,57",
    t_global: 8,
    z_global: "516,42",
  },
  {
    profile_section_id: 6475,
    name: "PIPBN298.5X8.8",
    type: "Pipe",
    designation: "PIPBN298.5X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "80,09",
    d_global: "298,5",
    i_global: "8409,86",
    t_global: "8,8",
    z_global: "563,47",
  },
  {
    profile_section_id: 6476,
    name: "PIPBN298.5X10",
    type: "Pipe",
    designation: "PIPBN298.5X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "90,63",
    d_global: "298,5",
    i_global: "9441,02",
    t_global: 10,
    z_global: "632,56",
  },
  {
    profile_section_id: 6477,
    name: "PIPBN298.5X11",
    type: "Pipe",
    designation: "PIPBN298.5X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "99,35",
    d_global: "298,5",
    i_global: "10280,2",
    t_global: 11,
    z_global: "688,79",
  },
  {
    profile_section_id: 6478,
    name: "PIPBN298.5X12.5",
    type: "Pipe",
    designation: "PIPBN298.5X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "112,31",
    d_global: "298,5",
    i_global: "11505,27",
    t_global: "12,5",
    z_global: "770,87",
  },
  {
    profile_section_id: 6479,
    name: "PIPBN298.5X14.2",
    type: "Pipe",
    designation: "PIPBN298.5X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "126,83",
    d_global: "298,5",
    i_global: "12845,79",
    t_global: "14,2",
    z_global: "860,69",
  },
  {
    profile_section_id: 6480,
    name: "PIPBN298.5X16",
    type: "Pipe",
    designation: "PIPBN298.5X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 142,
    d_global: "298,5",
    i_global: "14211,05",
    t_global: 16,
    z_global: "952,16",
  },
  {
    profile_section_id: 6481,
    name: "PIPBN298.5X17.5",
    type: "Pipe",
    designation: "PIPBN298.5X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "154,49",
    d_global: "298,5",
    i_global: "15307,28",
    t_global: "17,5",
    z_global: "1025,61",
  },
  {
    profile_section_id: 6482,
    name: "PIPBN298.5X20",
    type: "Pipe",
    designation: "PIPBN298.5X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "174,99",
    d_global: "298,5",
    i_global: "17052,95",
    t_global: 20,
    z_global: "1142,58",
  },
  {
    profile_section_id: 6483,
    name: "PIPBN298.5X22.2",
    type: "Pipe",
    designation: "PIPBN298.5X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "192,7",
    d_global: "298,5",
    i_global: "18507,6",
    t_global: "22,2",
    z_global: "1240,04",
  },
  {
    profile_section_id: 6484,
    name: "PIPBN298.5X25",
    type: "Pipe",
    designation: "PIPBN298.5X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "214,81",
    d_global: "298,5",
    i_global: "20252,82",
    t_global: 25,
    z_global: "1356,97",
  },
  {
    profile_section_id: 6485,
    name: "PIPBN298.5X28",
    type: "Pipe",
    designation: "PIPBN298.5X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "237,94",
    d_global: "298,5",
    i_global: "21996,23",
    t_global: 28,
    z_global: "1473,78",
  },
  {
    profile_section_id: 6486,
    name: "PIPBN298.5X30",
    type: "Pipe",
    designation: "PIPBN298.5X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "253,06",
    d_global: "298,5",
    i_global: "23088,84",
    t_global: 30,
    z_global: "1546,99",
  },
  {
    profile_section_id: 6487,
    name: "PIPBN298.5X32",
    type: "Pipe",
    designation: "PIPBN298.5X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "267,92",
    d_global: "298,5",
    i_global: "24127,84",
    t_global: 32,
    z_global: "1616,61",
  },
  {
    profile_section_id: 6488,
    name: "PIPBN298.5X36",
    type: "Pipe",
    designation: "PIPBN298.5X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "296,88",
    d_global: "298,5",
    i_global: "26052,1",
    t_global: 36,
    z_global: "1745,53",
  },
  {
    profile_section_id: 6489,
    name: "PIPBN298.5X40",
    type: "Pipe",
    designation: "PIPBN298.5X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "324,84",
    d_global: "298,5",
    i_global: "27782,91",
    t_global: 40,
    z_global: "1861,5",
  },
  {
    profile_section_id: 6490,
    name: "PIPBN298.5X45",
    type: "Pipe",
    designation: "PIPBN298.5X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "358,38",
    d_global: "298,5",
    i_global: "29694,8",
    t_global: 45,
    z_global: "1989,6",
  },
  {
    profile_section_id: 6491,
    name: "PIPBN298.5X50",
    type: "Pipe",
    designation: "PIPBN298.5X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "390,34",
    d_global: "298,5",
    i_global: "31350,51",
    t_global: 50,
    z_global: "2100,54",
  },
  {
    profile_section_id: 6492,
    name: "PIPBN298.5X55",
    type: "Pipe",
    designation: "PIPBN298.5X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "420,74",
    d_global: "298,5",
    i_global: "32774,03",
    t_global: 55,
    z_global: "2195,91",
  },
  {
    profile_section_id: 6493,
    name: "PIPBN298.5X60",
    type: "Pipe",
    designation: "PIPBN298.5X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "449,56",
    d_global: "298,5",
    i_global: "33988,14",
    t_global: 60,
    z_global: "2277,26",
  },
  {
    profile_section_id: 6494,
    name: "PIPBN298.5X65",
    type: "Pipe",
    designation: "PIPBN298.5X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "476,82",
    d_global: "298,5",
    i_global: "35014,48",
    t_global: 65,
    z_global: "2346,03",
  },
  {
    profile_section_id: 6495,
    name: "PIPBN298.5X70",
    type: "Pipe",
    designation: "PIPBN298.5X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "502,5",
    d_global: "298,5",
    i_global: "35873,47",
    t_global: 70,
    z_global: "2403,58",
  },
  {
    profile_section_id: 6496,
    name: "PIPBN298.5X75",
    type: "Pipe",
    designation: "PIPBN298.5X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "526,61",
    d_global: "298,5",
    i_global: "36584,38",
    t_global: 75,
    z_global: "2451,22",
  },
  {
    profile_section_id: 6497,
    name: "PIPBN298.5X80",
    type: "Pipe",
    designation: "PIPBN298.5X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "549,15",
    d_global: "298,5",
    i_global: "37165,3",
    t_global: 80,
    z_global: "2490,14",
  },
  {
    profile_section_id: 6498,
    name: "PIPBN298.5X85",
    type: "Pipe",
    designation: "PIPBN298.5X85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "570,12",
    d_global: "298,5",
    i_global: "37633,12",
    t_global: 85,
    z_global: "2521,48",
  },
  {
    profile_section_id: 6499,
    name: "PIPBN298.5X90",
    type: "Pipe",
    designation: "PIPBN298.5X90",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "589,52",
    d_global: "298,5",
    i_global: "38003,58",
    t_global: 90,
    z_global: "2546,3",
  },
  {
    profile_section_id: 6500,
    name: "PIPBN305X7.5",
    type: "Pipe",
    designation: "PIPBN305X7.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "70,1",
    d_global: 305,
    i_global: "7759,93",
    t_global: "7,5",
    z_global: "508,85",
  },
  {
    profile_section_id: 6501,
    name: "PIPBN305X8",
    type: "Pipe",
    designation: "PIPBN305X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "74,64",
    d_global: 305,
    i_global: "8236,34",
    t_global: 8,
    z_global: "540,09",
  },
  {
    profile_section_id: 6502,
    name: "PIPBN305X8.8",
    type: "Pipe",
    designation: "PIPBN305X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "81,89",
    d_global: 305,
    i_global: "8988,37",
    t_global: "8,8",
    z_global: "589,4",
  },
  {
    profile_section_id: 6503,
    name: "PIPBN305X10",
    type: "Pipe",
    designation: "PIPBN305X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "92,68",
    d_global: 305,
    i_global: "10093,1",
    t_global: 10,
    z_global: "661,84",
  },
  {
    profile_section_id: 6504,
    name: "PIPBN305X11",
    type: "Pipe",
    designation: "PIPBN305X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "101,6",
    d_global: 305,
    i_global: "10992,64",
    t_global: 11,
    z_global: "720,83",
  },
  {
    profile_section_id: 6505,
    name: "PIPBN305X12.5",
    type: "Pipe",
    designation: "PIPBN305X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "114,86",
    d_global: 305,
    i_global: "12306,65",
    t_global: "12,5",
    z_global: "806,99",
  },
  {
    profile_section_id: 6506,
    name: "PIPBN305X14.2",
    type: "Pipe",
    designation: "PIPBN305X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "129,73",
    d_global: 305,
    i_global: "13745,67",
    t_global: "14,2",
    z_global: "901,36",
  },
  {
    profile_section_id: 6507,
    name: "PIPBN305X16",
    type: "Pipe",
    designation: "PIPBN305X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "145,27",
    d_global: 305,
    i_global: "15212,57",
    t_global: 16,
    z_global: "997,54",
  },
  {
    profile_section_id: 6508,
    name: "PIPBN305X17.5",
    type: "Pipe",
    designation: "PIPBN305X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "158,06",
    d_global: 305,
    i_global: "16391,46",
    t_global: "17,5",
    z_global: "1074,85",
  },
  {
    profile_section_id: 6509,
    name: "PIPBN305X20",
    type: "Pipe",
    designation: "PIPBN305X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "179,07",
    d_global: 305,
    i_global: "18270,82",
    t_global: 20,
    z_global: "1198,09",
  },
  {
    profile_section_id: 6510,
    name: "PIPBN305X22.2",
    type: "Pipe",
    designation: "PIPBN305X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "197,23",
    d_global: 305,
    i_global: "19838,97",
    t_global: "22,2",
    z_global: "1300,92",
  },
  {
    profile_section_id: 6511,
    name: "PIPBN305X25",
    type: "Pipe",
    designation: "PIPBN305X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "219,91",
    d_global: 305,
    i_global: "21723,13",
    t_global: 25,
    z_global: "1424,47",
  },
  {
    profile_section_id: 6512,
    name: "PIPBN305X30",
    type: "Pipe",
    designation: "PIPBN305X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "259,18",
    d_global: 305,
    i_global: "24792,32",
    t_global: 30,
    z_global: "1625,73",
  },
  {
    profile_section_id: 6513,
    name: "PIPBN305X36",
    type: "Pipe",
    designation: "PIPBN305X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "304,23",
    d_global: 305,
    i_global: "28011,01",
    t_global: 36,
    z_global: "1836,79",
  },
  {
    profile_section_id: 6514,
    name: "PIPBN305X40",
    type: "Pipe",
    designation: "PIPBN305X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "333,01",
    d_global: 305,
    i_global: "29897,95",
    t_global: 40,
    z_global: "1960,52",
  },
  {
    profile_section_id: 6515,
    name: "PIPBN305X45",
    type: "Pipe",
    designation: "PIPBN305X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "367,57",
    d_global: 305,
    i_global: "31989,76",
    t_global: 45,
    z_global: "2097,69",
  },
  {
    profile_section_id: 6516,
    name: "PIPBN305X50",
    type: "Pipe",
    designation: "PIPBN305X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "400,55",
    d_global: 305,
    i_global: "33809,18",
    t_global: 50,
    z_global: 2217,
  },
  {
    profile_section_id: 6517,
    name: "PIPBN318X7.5",
    type: "Pipe",
    designation: "PIPBN318X7.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "73,16",
    d_global: 318,
    i_global: "8821,84",
    t_global: "7,5",
    z_global: "554,83",
  },
  {
    profile_section_id: 6518,
    name: "PIPBN318X8",
    type: "Pipe",
    designation: "PIPBN318X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "77,91",
    d_global: 318,
    i_global: "9365,35",
    t_global: 8,
    z_global: "589,02",
  },
  {
    profile_section_id: 6519,
    name: "PIPBN318X8.8",
    type: "Pipe",
    designation: "PIPBN318X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "85,48",
    d_global: 318,
    i_global: "10223,81",
    t_global: "8,8",
    z_global: "643,01",
  },
  {
    profile_section_id: 6520,
    name: "PIPBN318X10",
    type: "Pipe",
    designation: "PIPBN318X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "96,76",
    d_global: 318,
    i_global: "11486,02",
    t_global: 10,
    z_global: "722,39",
  },
  {
    profile_section_id: 6521,
    name: "PIPBN318X11",
    type: "Pipe",
    designation: "PIPBN318X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "106,09",
    d_global: 318,
    i_global: "12514,83",
    t_global: 11,
    z_global: "787,1",
  },
  {
    profile_section_id: 6522,
    name: "PIPBN318X12.5",
    type: "Pipe",
    designation: "PIPBN318X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "119,97",
    d_global: 318,
    i_global: "14019,42",
    t_global: "12,5",
    z_global: "881,72",
  },
  {
    profile_section_id: 6523,
    name: "PIPBN318X14.2",
    type: "Pipe",
    designation: "PIPBN318X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "135,53",
    d_global: 318,
    i_global: "15669,65",
    t_global: "14,2",
    z_global: "985,51",
  },
  {
    profile_section_id: 6524,
    name: "PIPBN318X16",
    type: "Pipe",
    designation: "PIPBN318X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "151,8",
    d_global: 318,
    i_global: "17354,74",
    t_global: 16,
    z_global: "1091,49",
  },
  {
    profile_section_id: 6525,
    name: "PIPBN318X17.5",
    type: "Pipe",
    designation: "PIPBN318X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "165,21",
    d_global: 318,
    i_global: "18711,21",
    t_global: "17,5",
    z_global: "1176,81",
  },
  {
    profile_section_id: 6526,
    name: "PIPBN318X20",
    type: "Pipe",
    designation: "PIPBN318X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "187,24",
    d_global: 318,
    i_global: "20878,08",
    t_global: 20,
    z_global: "1313,09",
  },
  {
    profile_section_id: 6527,
    name: "PIPBN318X22.2",
    type: "Pipe",
    designation: "PIPBN318X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "206,3",
    d_global: 318,
    i_global: "22690,64",
    t_global: "22,2",
    z_global: "1427,08",
  },
  {
    profile_section_id: 6528,
    name: "PIPBN318X25",
    type: "Pipe",
    designation: "PIPBN318X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "230,12",
    d_global: 318,
    i_global: "24874,43",
    t_global: 25,
    z_global: "1564,43",
  },
  {
    profile_section_id: 6529,
    name: "PIPBN318X28",
    type: "Pipe",
    designation: "PIPBN318X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "255,1",
    d_global: 318,
    i_global: "27067,1",
    t_global: 28,
    z_global: "1702,33",
  },
  {
    profile_section_id: 6530,
    name: "PIPBN318X30",
    type: "Pipe",
    designation: "PIPBN318X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "271,43",
    d_global: 318,
    i_global: "28447,6",
    t_global: 30,
    z_global: "1789,16",
  },
  {
    profile_section_id: 6531,
    name: "PIPBN318X36",
    type: "Pipe",
    designation: "PIPBN318X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "318,93",
    d_global: 318,
    i_global: "32220,36",
    t_global: 36,
    z_global: "2026,44",
  },
  {
    profile_section_id: 6532,
    name: "PIPBN318X40",
    type: "Pipe",
    designation: "PIPBN318X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "349,35",
    d_global: 318,
    i_global: "34447,18",
    t_global: 40,
    z_global: "2166,49",
  },
  {
    profile_section_id: 6533,
    name: "PIPBN318X45",
    type: "Pipe",
    designation: "PIPBN318X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "385,94",
    d_global: 318,
    i_global: "36932,01",
    t_global: 45,
    z_global: "2322,77",
  },
  {
    profile_section_id: 6534,
    name: "PIPBN318X50",
    type: "Pipe",
    designation: "PIPBN318X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "420,97",
    d_global: 318,
    i_global: "39110,54",
    t_global: 50,
    z_global: "2459,78",
  },
  {
    profile_section_id: 6535,
    name: "PIPBN323.9X7.1",
    type: "Pipe",
    designation: "PIPBN323.9X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "70,66",
    d_global: "323,9",
    i_global: "8869,35",
    t_global: "7,1",
    z_global: "547,66",
  },
  {
    profile_section_id: 6536,
    name: "PIPBN323.9X8",
    type: "Pipe",
    designation: "PIPBN323.9X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "79,39",
    d_global: "323,9",
    i_global: "9910,08",
    t_global: 8,
    z_global: "611,92",
  },
  {
    profile_section_id: 6537,
    name: "PIPBN323.9X8.8",
    type: "Pipe",
    designation: "PIPBN323.9X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "87,11",
    d_global: "323,9",
    i_global: "10819,98",
    t_global: "8,8",
    z_global: "668,11",
  },
  {
    profile_section_id: 6538,
    name: "PIPBN323.9X10",
    type: "Pipe",
    designation: "PIPBN323.9X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "98,61",
    d_global: "323,9",
    i_global: "12158,34",
    t_global: 10,
    z_global: "750,75",
  },
  {
    profile_section_id: 6539,
    name: "PIPBN323.9X11",
    type: "Pipe",
    designation: "PIPBN323.9X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "108,13",
    d_global: "323,9",
    i_global: "13249,69",
    t_global: 11,
    z_global: "818,13",
  },
  {
    profile_section_id: 6540,
    name: "PIPBN323.9X12.5",
    type: "Pipe",
    designation: "PIPBN323.9X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "122,29",
    d_global: "323,9",
    i_global: "14846,53",
    t_global: "12,5",
    z_global: "916,74",
  },
  {
    profile_section_id: 6541,
    name: "PIPBN323.9X14.2",
    type: "Pipe",
    designation: "PIPBN323.9X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "138,16",
    d_global: "323,9",
    i_global: "16599,08",
    t_global: "14,2",
    z_global: "1024,95",
  },
  {
    profile_section_id: 6542,
    name: "PIPBN323.9X16",
    type: "Pipe",
    designation: "PIPBN323.9X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "154,77",
    d_global: "323,9",
    i_global: "18389,93",
    t_global: 16,
    z_global: "1135,53",
  },
  {
    profile_section_id: 6543,
    name: "PIPBN323.9X17.5",
    type: "Pipe",
    designation: "PIPBN323.9X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "168,45",
    d_global: "323,9",
    i_global: "19832,55",
    t_global: "17,5",
    z_global: "1224,61",
  },
  {
    profile_section_id: 6544,
    name: "PIPBN323.9X20",
    type: "Pipe",
    designation: "PIPBN323.9X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "190,95",
    d_global: "323,9",
    i_global: "22139,05",
    t_global: 20,
    z_global: "1367,03",
  },
  {
    profile_section_id: 6545,
    name: "PIPBN323.9X22.2",
    type: "Pipe",
    designation: "PIPBN323.9X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "210,42",
    d_global: "323,9",
    i_global: "24070,43",
    t_global: "22,2",
    z_global: "1486,29",
  },
  {
    profile_section_id: 6546,
    name: "PIPBN323.9X25",
    type: "Pipe",
    designation: "PIPBN323.9X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "234,76",
    d_global: "323,9",
    i_global: "26400,08",
    t_global: 25,
    z_global: "1630,14",
  },
  {
    profile_section_id: 6547,
    name: "PIPBN323.9X28",
    type: "Pipe",
    designation: "PIPBN323.9X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "260,29",
    d_global: "323,9",
    i_global: "28742,48",
    t_global: 28,
    z_global: "1774,78",
  },
  {
    profile_section_id: 6548,
    name: "PIPBN323.9X30",
    type: "Pipe",
    designation: "PIPBN323.9X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "276,99",
    d_global: "323,9",
    i_global: "30219,1",
    t_global: 30,
    z_global: "1865,95",
  },
  {
    profile_section_id: 6549,
    name: "PIPBN323.9X32",
    type: "Pipe",
    designation: "PIPBN323.9X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "293,45",
    d_global: "323,9",
    i_global: "31630,09",
    t_global: 32,
    z_global: "1953,08",
  },
  {
    profile_section_id: 6550,
    name: "PIPBN323.9X36",
    type: "Pipe",
    designation: "PIPBN323.9X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "325,61",
    d_global: "323,9",
    i_global: 34263,
    t_global: 36,
    z_global: "2115,65",
  },
  {
    profile_section_id: 6551,
    name: "PIPBN323.9X40",
    type: "Pipe",
    designation: "PIPBN323.9X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "356,76",
    d_global: "323,9",
    i_global: "36656,66",
    t_global: 40,
    z_global: "2263,46",
  },
  {
    profile_section_id: 6552,
    name: "PIPBN323.9X45",
    type: "Pipe",
    designation: "PIPBN323.9X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "394,29",
    d_global: "323,9",
    i_global: "39335,02",
    t_global: 45,
    z_global: "2428,84",
  },
  {
    profile_section_id: 6553,
    name: "PIPBN323.9X50",
    type: "Pipe",
    designation: "PIPBN323.9X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "430,24",
    d_global: "323,9",
    i_global: "41691,02",
    t_global: 50,
    z_global: "2574,31",
  },
  {
    profile_section_id: 6554,
    name: "PIPBN323.9X55",
    type: "Pipe",
    designation: "PIPBN323.9X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "464,63",
    d_global: "323,9",
    i_global: "43751,61",
    t_global: 55,
    z_global: "2701,55",
  },
  {
    profile_section_id: 6555,
    name: "PIPBN323.9X60",
    type: "Pipe",
    designation: "PIPBN323.9X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "497,44",
    d_global: "323,9",
    i_global: "45542,61",
    t_global: 60,
    z_global: "2812,14",
  },
  {
    profile_section_id: 6556,
    name: "PIPBN323.9X65",
    type: "Pipe",
    designation: "PIPBN323.9X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "528,68",
    d_global: "323,9",
    i_global: "47088,61",
    t_global: 65,
    z_global: "2907,6",
  },
  {
    profile_section_id: 6557,
    name: "PIPBN323.9X70",
    type: "Pipe",
    designation: "PIPBN323.9X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "558,36",
    d_global: "323,9",
    i_global: "48413,04",
    t_global: 70,
    z_global: "2989,38",
  },
  {
    profile_section_id: 6558,
    name: "PIPBN323.9X75",
    type: "Pipe",
    designation: "PIPBN323.9X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "586,46",
    d_global: "323,9",
    i_global: "49538,16",
    t_global: 75,
    z_global: "3058,86",
  },
  {
    profile_section_id: 6559,
    name: "PIPBN323.9X80",
    type: "Pipe",
    designation: "PIPBN323.9X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "612,99",
    d_global: "323,9",
    i_global: "50485,05",
    t_global: 80,
    z_global: "3117,32",
  },
  {
    profile_section_id: 6560,
    name: "PIPBN323.9X85",
    type: "Pipe",
    designation: "PIPBN323.9X85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "637,95",
    d_global: "323,9",
    i_global: "51273,61",
    t_global: 85,
    z_global: "3166,01",
  },
  {
    profile_section_id: 6561,
    name: "PIPBN323.9X90",
    type: "Pipe",
    designation: "PIPBN323.9X90",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "661,34",
    d_global: "323,9",
    i_global: "51922,54",
    t_global: 90,
    z_global: "3206,09",
  },
  {
    profile_section_id: 6562,
    name: "PIPBN330X12.5",
    type: "Pipe",
    designation: "PIPBN330X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "124,68",
    d_global: 330,
    i_global: "15735,25",
    t_global: "12,5",
    z_global: "953,65",
  },
  {
    profile_section_id: 6563,
    name: "PIPBN330X14.2",
    type: "Pipe",
    designation: "PIPBN330X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "140,88",
    d_global: 330,
    i_global: "17597,94",
    t_global: "14,2",
    z_global: "1066,54",
  },
  {
    profile_section_id: 6564,
    name: "PIPBN330X16",
    type: "Pipe",
    designation: "PIPBN330X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "157,83",
    d_global: 330,
    i_global: "19502,71",
    t_global: 16,
    z_global: "1181,98",
  },
  {
    profile_section_id: 6565,
    name: "PIPBN330X17.5",
    type: "Pipe",
    designation: "PIPBN330X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "171,81",
    d_global: 330,
    i_global: "21038,16",
    t_global: "17,5",
    z_global: "1275,04",
  },
  {
    profile_section_id: 6566,
    name: "PIPBN330X20",
    type: "Pipe",
    designation: "PIPBN330X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "194,78",
    d_global: 330,
    i_global: "23495,19",
    t_global: 20,
    z_global: "1423,95",
  },
  {
    profile_section_id: 6567,
    name: "PIPBN330X25",
    type: "Pipe",
    designation: "PIPBN330X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "239,55",
    d_global: 330,
    i_global: "28041,9",
    t_global: 25,
    z_global: "1699,51",
  },
  {
    profile_section_id: 6568,
    name: "PIPBN330X30",
    type: "Pipe",
    designation: "PIPBN330X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "282,74",
    d_global: 330,
    i_global: "32126,71",
    t_global: 30,
    z_global: "1947,07",
  },
  {
    profile_section_id: 6569,
    name: "PIPBN330X36",
    type: "Pipe",
    designation: "PIPBN330X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "332,51",
    d_global: 330,
    i_global: "36464,29",
    t_global: 36,
    z_global: "2209,96",
  },
  {
    profile_section_id: 6570,
    name: "PIPBN330X40",
    type: "Pipe",
    designation: "PIPBN330X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "364,42",
    d_global: 330,
    i_global: 39039,
    t_global: 40,
    z_global: 2366,
  },
  {
    profile_section_id: 6571,
    name: "PIPBN330X45",
    type: "Pipe",
    designation: "PIPBN330X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "402,91",
    d_global: 330,
    i_global: "41927,75",
    t_global: 45,
    z_global: "2541,07",
  },
  {
    profile_section_id: 6572,
    name: "PIPBN330X50",
    type: "Pipe",
    designation: "PIPBN330X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "439,82",
    d_global: 330,
    i_global: "44477,1",
    t_global: 50,
    z_global: "2695,58",
  },
  {
    profile_section_id: 6573,
    name: "PIPBN343X8.8",
    type: "Pipe",
    designation: "PIPBN343X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "92,39",
    d_global: 343,
    i_global: "12908,12",
    t_global: "8,8",
    z_global: "752,66",
  },
  {
    profile_section_id: 6574,
    name: "PIPBN343X10",
    type: "Pipe",
    designation: "PIPBN343X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "104,62",
    d_global: 343,
    i_global: "14513,9",
    t_global: 10,
    z_global: "846,29",
  },
  {
    profile_section_id: 6575,
    name: "PIPBN343X11",
    type: "Pipe",
    designation: "PIPBN343X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "114,73",
    d_global: 343,
    i_global: "15824,99",
    t_global: 11,
    z_global: "922,74",
  },
  {
    profile_section_id: 6576,
    name: "PIPBN343X12.5",
    type: "Pipe",
    designation: "PIPBN343X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "129,79",
    d_global: 343,
    i_global: "17746,19",
    t_global: "12,5",
    z_global: "1034,76",
  },
  {
    profile_section_id: 6577,
    name: "PIPBN343X14.2",
    type: "Pipe",
    designation: "PIPBN343X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "146,68",
    d_global: 343,
    i_global: "19858,8",
    t_global: "14,2",
    z_global: "1157,95",
  },
  {
    profile_section_id: 6578,
    name: "PIPBN343X16",
    type: "Pipe",
    designation: "PIPBN343X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "164,37",
    d_global: 343,
    i_global: "22022,25",
    t_global: 16,
    z_global: "1284,1",
  },
  {
    profile_section_id: 6579,
    name: "PIPBN343X17.5",
    type: "Pipe",
    designation: "PIPBN343X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "178,95",
    d_global: 343,
    i_global: "23768,65",
    t_global: "17,5",
    z_global: "1385,93",
  },
  {
    profile_section_id: 6580,
    name: "PIPBN343X20",
    type: "Pipe",
    designation: "PIPBN343X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "202,95",
    d_global: 343,
    i_global: "26568,03",
    t_global: 20,
    z_global: "1549,16",
  },
  {
    profile_section_id: 6581,
    name: "PIPBN343X22.2",
    type: "Pipe",
    designation: "PIPBN343X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "223,74",
    d_global: 343,
    i_global: "28919,5",
    t_global: "22,2",
    z_global: "1686,27",
  },
  {
    profile_section_id: 6582,
    name: "PIPBN343X25",
    type: "Pipe",
    designation: "PIPBN343X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "249,76",
    d_global: 343,
    i_global: "31765,61",
    t_global: 25,
    z_global: "1852,22",
  },
  {
    profile_section_id: 6583,
    name: "PIPBN343X28",
    type: "Pipe",
    designation: "PIPBN343X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "277,09",
    d_global: 343,
    i_global: "34639,18",
    t_global: 28,
    z_global: "2019,78",
  },
  {
    profile_section_id: 6584,
    name: "PIPBN343X30",
    type: "Pipe",
    designation: "PIPBN343X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 295,
    d_global: 343,
    i_global: "36457,39",
    t_global: 30,
    z_global: "2125,8",
  },
  {
    profile_section_id: 6585,
    name: "PIPBN343X32",
    type: "Pipe",
    designation: "PIPBN343X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "312,65",
    d_global: 343,
    i_global: "38200,13",
    t_global: 32,
    z_global: "2227,41",
  },
  {
    profile_section_id: 6586,
    name: "PIPBN343X36",
    type: "Pipe",
    designation: "PIPBN343X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "347,21",
    d_global: 343,
    i_global: "41467,58",
    t_global: 36,
    z_global: "2417,93",
  },
  {
    profile_section_id: 6587,
    name: "PIPBN343X40",
    type: "Pipe",
    designation: "PIPBN343X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "380,76",
    d_global: 343,
    i_global: "44458,13",
    t_global: 40,
    z_global: "2592,31",
  },
  {
    profile_section_id: 6588,
    name: "PIPBN343X45",
    type: "Pipe",
    designation: "PIPBN343X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "421,29",
    d_global: 343,
    i_global: "47831,41",
    t_global: 45,
    z_global: 2789,
  },
  {
    profile_section_id: 6589,
    name: "PIPBN343X50",
    type: "Pipe",
    designation: "PIPBN343X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "460,24",
    d_global: 343,
    i_global: "50827,55",
    t_global: 50,
    z_global: "2963,7",
  },
  {
    profile_section_id: 6590,
    name: "PIPBN343X55",
    type: "Pipe",
    designation: "PIPBN343X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "497,63",
    d_global: 343,
    i_global: "53475,76",
    t_global: 55,
    z_global: "3118,12",
  },
  {
    profile_section_id: 6591,
    name: "PIPBN355.6X8",
    type: "Pipe",
    designation: "PIPBN355.6X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "87,36",
    d_global: "355,6",
    i_global: "13201,38",
    t_global: 8,
    z_global: "742,49",
  },
  {
    profile_section_id: 6592,
    name: "PIPBN355.6X8.8",
    type: "Pipe",
    designation: "PIPBN355.6X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "95,88",
    d_global: "355,6",
    i_global: "14423,13",
    t_global: "8,8",
    z_global: "811,2",
  },
  {
    profile_section_id: 6593,
    name: "PIPBN355.6X10",
    type: "Pipe",
    designation: "PIPBN355.6X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "108,57",
    d_global: "355,6",
    i_global: "16223,5",
    t_global: 10,
    z_global: "912,46",
  },
  {
    profile_section_id: 6594,
    name: "PIPBN355.6X11",
    type: "Pipe",
    designation: "PIPBN355.6X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "119,09",
    d_global: "355,6",
    i_global: "17694,6",
    t_global: 11,
    z_global: "995,2",
  },
  {
    profile_section_id: 6595,
    name: "PIPBN355.6X12.5",
    type: "Pipe",
    designation: "PIPBN355.6X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "134,74",
    d_global: "355,6",
    i_global: "19852,18",
    t_global: "12,5",
    z_global: "1116,55",
  },
  {
    profile_section_id: 6596,
    name: "PIPBN355.6X14.2",
    type: "Pipe",
    designation: "PIPBN355.6X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "152,3",
    d_global: "355,6",
    i_global: "22227,44",
    t_global: "14,2",
    z_global: "1250,14",
  },
  {
    profile_section_id: 6597,
    name: "PIPBN355.6X16",
    type: "Pipe",
    designation: "PIPBN355.6X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "170,7",
    d_global: "355,6",
    i_global: 24663,
    t_global: 16,
    z_global: "1387,12",
  },
  {
    profile_section_id: 6598,
    name: "PIPBN355.6X17.5",
    type: "Pipe",
    designation: "PIPBN355.6X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "185,88",
    d_global: "355,6",
    i_global: "26631,49",
    t_global: "17,5",
    z_global: "1497,83",
  },
  {
    profile_section_id: 6599,
    name: "PIPBN355.6X20",
    type: "Pipe",
    designation: "PIPBN355.6X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "210,86",
    d_global: "355,6",
    i_global: "29791,71",
    t_global: 20,
    z_global: "1675,57",
  },
  {
    profile_section_id: 6600,
    name: "PIPBN355.6X22.2",
    type: "Pipe",
    designation: "PIPBN355.6X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "232,52",
    d_global: "355,6",
    i_global: "32451,21",
    t_global: "22,2",
    z_global: "1825,15",
  },
  {
    profile_section_id: 6601,
    name: "PIPBN355.6X25",
    type: "Pipe",
    designation: "PIPBN355.6X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "259,65",
    d_global: "355,6",
    i_global: "35676,71",
    t_global: 25,
    z_global: "2006,56",
  },
  {
    profile_section_id: 6602,
    name: "PIPBN355.6X28",
    type: "Pipe",
    designation: "PIPBN355.6X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "288,17",
    d_global: "355,6",
    i_global: "38941,32",
    t_global: 28,
    z_global: "2190,18",
  },
  {
    profile_section_id: 6603,
    name: "PIPBN355.6X30",
    type: "Pipe",
    designation: "PIPBN355.6X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "306,87",
    d_global: "355,6",
    i_global: "41011,5",
    t_global: 30,
    z_global: "2306,61",
  },
  {
    profile_section_id: 6604,
    name: "PIPBN355.6X32",
    type: "Pipe",
    designation: "PIPBN355.6X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "325,32",
    d_global: "355,6",
    i_global: "42999,32",
    t_global: 32,
    z_global: "2418,41",
  },
  {
    profile_section_id: 6605,
    name: "PIPBN355.6X36",
    type: "Pipe",
    designation: "PIPBN355.6X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "361,46",
    d_global: "355,6",
    i_global: "46736,73",
    t_global: 36,
    z_global: "2628,61",
  },
  {
    profile_section_id: 6606,
    name: "PIPBN355.6X40",
    type: "Pipe",
    designation: "PIPBN355.6X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "396,59",
    d_global: "355,6",
    i_global: "50170,89",
    t_global: 40,
    z_global: "2821,76",
  },
  {
    profile_section_id: 6607,
    name: "PIPBN355.6X45",
    type: "Pipe",
    designation: "PIPBN355.6X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "439,1",
    d_global: "355,6",
    i_global: "54062,79",
    t_global: 45,
    z_global: "3040,65",
  },
  {
    profile_section_id: 6608,
    name: "PIPBN355.6X50",
    type: "Pipe",
    designation: "PIPBN355.6X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "480,04",
    d_global: "355,6",
    i_global: "57539,05",
    t_global: 50,
    z_global: "3236,17",
  },
  {
    profile_section_id: 6609,
    name: "PIPBN355.6X55",
    type: "Pipe",
    designation: "PIPBN355.6X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "519,4",
    d_global: "355,6",
    i_global: "60630,39",
    t_global: 55,
    z_global: "3410,03",
  },
  {
    profile_section_id: 6610,
    name: "PIPBN355.6X60",
    type: "Pipe",
    designation: "PIPBN355.6X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "557,19",
    d_global: "355,6",
    i_global: "63366,31",
    t_global: 60,
    z_global: "3563,91",
  },
  {
    profile_section_id: 6611,
    name: "PIPBN355.6X65",
    type: "Pipe",
    designation: "PIPBN355.6X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "593,42",
    d_global: "355,6",
    i_global: "65775,18",
    t_global: 65,
    z_global: "3699,39",
  },
  {
    profile_section_id: 6612,
    name: "PIPBN355.6X70",
    type: "Pipe",
    designation: "PIPBN355.6X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "628,07",
    d_global: "355,6",
    i_global: "67884,14",
    t_global: 70,
    z_global: "3818,01",
  },
  {
    profile_section_id: 6613,
    name: "PIPBN355.6X75",
    type: "Pipe",
    designation: "PIPBN355.6X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "661,15",
    d_global: "355,6",
    i_global: "69719,2",
    t_global: 75,
    z_global: "3921,22",
  },
  {
    profile_section_id: 6614,
    name: "PIPBN355.6X80",
    type: "Pipe",
    designation: "PIPBN355.6X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "692,66",
    d_global: "355,6",
    i_global: "71305,16",
    t_global: 80,
    z_global: "4010,41",
  },
  {
    profile_section_id: 6615,
    name: "PIPBN355.6X85",
    type: "Pipe",
    designation: "PIPBN355.6X85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "722,6",
    d_global: "355,6",
    i_global: "72665,66",
    t_global: 85,
    z_global: "4086,93",
  },
  {
    profile_section_id: 6616,
    name: "PIPBN355.6X90",
    type: "Pipe",
    designation: "PIPBN355.6X90",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "750,97",
    d_global: "355,6",
    i_global: "73823,14",
    t_global: 90,
    z_global: "4152,03",
  },
  {
    profile_section_id: 6617,
    name: "PIPBN355.6X100",
    type: "Pipe",
    designation: "PIPBN355.6X100",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "802,99",
    d_global: "355,6",
    i_global: "75613,02",
    t_global: 100,
    z_global: "4252,7",
  },
  {
    profile_section_id: 6618,
    name: "PIPBN368X8",
    type: "Pipe",
    designation: "PIPBN368X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "90,48",
    d_global: 368,
    i_global: "14664,65",
    t_global: 8,
    z_global: "796,99",
  },
  {
    profile_section_id: 6619,
    name: "PIPBN368X8.8",
    type: "Pipe",
    designation: "PIPBN368X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "99,3",
    d_global: 368,
    i_global: "16025,52",
    t_global: "8,8",
    z_global: "870,95",
  },
  {
    profile_section_id: 6620,
    name: "PIPBN368X10",
    type: "Pipe",
    designation: "PIPBN368X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "112,47",
    d_global: 368,
    i_global: "18032,16",
    t_global: 10,
    z_global: "980,01",
  },
  {
    profile_section_id: 6621,
    name: "PIPBN368X11",
    type: "Pipe",
    designation: "PIPBN368X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "123,37",
    d_global: 368,
    i_global: "19672,94",
    t_global: 11,
    z_global: "1069,18",
  },
  {
    profile_section_id: 6622,
    name: "PIPBN368X12.5",
    type: "Pipe",
    designation: "PIPBN368X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "139,6",
    d_global: 368,
    i_global: "22081,34",
    t_global: "12,5",
    z_global: "1200,07",
  },
  {
    profile_section_id: 6623,
    name: "PIPBN368X14.2",
    type: "Pipe",
    designation: "PIPBN368X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "157,83",
    d_global: 368,
    i_global: "24735,5",
    t_global: "14,2",
    z_global: "1344,32",
  },
  {
    profile_section_id: 6624,
    name: "PIPBN368X16",
    type: "Pipe",
    designation: "PIPBN368X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "176,93",
    d_global: 368,
    i_global: "27460,23",
    t_global: 16,
    z_global: "1492,4",
  },
  {
    profile_section_id: 6625,
    name: "PIPBN368X17.5",
    type: "Pipe",
    designation: "PIPBN368X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "192,7",
    d_global: 368,
    i_global: "29664,93",
    t_global: "17,5",
    z_global: "1612,22",
  },
  {
    profile_section_id: 6626,
    name: "PIPBN368X20",
    type: "Pipe",
    designation: "PIPBN368X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "218,65",
    d_global: 368,
    i_global: "33209,3",
    t_global: 20,
    z_global: "1804,85",
  },
  {
    profile_section_id: 6627,
    name: "PIPBN368X22.2",
    type: "Pipe",
    designation: "PIPBN368X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "241,17",
    d_global: 368,
    i_global: "36197,13",
    t_global: "22,2",
    z_global: "1967,23",
  },
  {
    profile_section_id: 6628,
    name: "PIPBN368X25",
    type: "Pipe",
    designation: "PIPBN368X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "269,39",
    d_global: 368,
    i_global: "39827,52",
    t_global: 25,
    z_global: "2164,54",
  },
  {
    profile_section_id: 6629,
    name: "PIPBN368X28",
    type: "Pipe",
    designation: "PIPBN368X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "299,08",
    d_global: 368,
    i_global: "43510,1",
    t_global: 28,
    z_global: "2364,68",
  },
  {
    profile_section_id: 6630,
    name: "PIPBN368X30",
    type: "Pipe",
    designation: "PIPBN368X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "318,56",
    d_global: 368,
    i_global: "45849,98",
    t_global: 30,
    z_global: "2491,85",
  },
  {
    profile_section_id: 6631,
    name: "PIPBN368X32",
    type: "Pipe",
    designation: "PIPBN368X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "337,78",
    d_global: 368,
    i_global: "48100,45",
    t_global: 32,
    z_global: "2614,16",
  },
  {
    profile_section_id: 6632,
    name: "PIPBN368X36",
    type: "Pipe",
    designation: "PIPBN368X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "375,48",
    d_global: 368,
    i_global: "52342,35",
    t_global: 36,
    z_global: "2844,69",
  },
  {
    profile_section_id: 6633,
    name: "PIPBN368X40",
    type: "Pipe",
    designation: "PIPBN368X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "412,18",
    d_global: 368,
    i_global: "56253,91",
    t_global: 40,
    z_global: "3057,28",
  },
  {
    profile_section_id: 6634,
    name: "PIPBN368X45",
    type: "Pipe",
    designation: "PIPBN368X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "456,63",
    d_global: 368,
    i_global: "60705,6",
    t_global: 45,
    z_global: "3299,22",
  },
  {
    profile_section_id: 6635,
    name: "PIPBN368X50",
    type: "Pipe",
    designation: "PIPBN368X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "499,51",
    d_global: 368,
    i_global: "64701,95",
    t_global: 50,
    z_global: "3516,41",
  },
  {
    profile_section_id: 6636,
    name: "PIPBN368X55",
    type: "Pipe",
    designation: "PIPBN368X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "540,83",
    d_global: 368,
    i_global: "68275,13",
    t_global: 55,
    z_global: "3710,6",
  },
  {
    profile_section_id: 6637,
    name: "PIPBN368X60",
    type: "Pipe",
    designation: "PIPBN368X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "580,57",
    d_global: 368,
    i_global: "71456,1",
    t_global: 60,
    z_global: "3883,48",
  },
  {
    profile_section_id: 6638,
    name: "PIPBN368X65",
    type: "Pipe",
    designation: "PIPBN368X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "618,74",
    d_global: 368,
    i_global: "74274,7",
    t_global: 65,
    z_global: "4036,67",
  },
  {
    profile_section_id: 6639,
    name: "PIPBN368X70",
    type: "Pipe",
    designation: "PIPBN368X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "655,34",
    d_global: 368,
    i_global: "76759,53",
    t_global: 70,
    z_global: "4171,71",
  },
  {
    profile_section_id: 6640,
    name: "PIPBN368X75",
    type: "Pipe",
    designation: "PIPBN368X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "690,36",
    d_global: 368,
    i_global: "78938,05",
    t_global: 75,
    z_global: "4290,11",
  },
  {
    profile_section_id: 6641,
    name: "PIPBN368X80",
    type: "Pipe",
    designation: "PIPBN368X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "723,82",
    d_global: 368,
    i_global: "80836,55",
    t_global: 80,
    z_global: "4393,29",
  },
  {
    profile_section_id: 6642,
    name: "PIPBN368X85",
    type: "Pipe",
    designation: "PIPBN368X85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "755,71",
    d_global: 368,
    i_global: "82480,09",
    t_global: 85,
    z_global: "4482,61",
  },
  {
    profile_section_id: 6643,
    name: "PIPBN368X90",
    type: "Pipe",
    designation: "PIPBN368X90",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "786,03",
    d_global: 368,
    i_global: "83892,61",
    t_global: 90,
    z_global: "4559,38",
  },
  {
    profile_section_id: 6644,
    name: "PIPBN368X100",
    type: "Pipe",
    designation: "PIPBN368X100",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "841,95",
    d_global: 368,
    i_global: "86114,32",
    t_global: 100,
    z_global: "4680,13",
  },
  {
    profile_section_id: 6645,
    name: "PIPBN394X10",
    type: "Pipe",
    designation: "PIPBN394X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "120,64",
    d_global: 394,
    i_global: "22250,92",
    t_global: 10,
    z_global: "1129,49",
  },
  {
    profile_section_id: 6646,
    name: "PIPBN394X11",
    type: "Pipe",
    designation: "PIPBN394X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "132,36",
    d_global: 394,
    i_global: "24288,85",
    t_global: 11,
    z_global: "1232,94",
  },
  {
    profile_section_id: 6647,
    name: "PIPBN394X12.5",
    type: "Pipe",
    designation: "PIPBN394X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "149,81",
    d_global: 394,
    i_global: "27284,72",
    t_global: "12,5",
    z_global: "1385,01",
  },
  {
    profile_section_id: 6648,
    name: "PIPBN394X14.2",
    type: "Pipe",
    designation: "PIPBN394X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "169,43",
    d_global: 394,
    i_global: "30592,84",
    t_global: "14,2",
    z_global: "1552,94",
  },
  {
    profile_section_id: 6649,
    name: "PIPBN394X16",
    type: "Pipe",
    designation: "PIPBN394X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 190,
    d_global: 394,
    i_global: "33996,38",
    t_global: 16,
    z_global: "1725,7",
  },
  {
    profile_section_id: 6650,
    name: "PIPBN394X17.5",
    type: "Pipe",
    designation: "PIPBN394X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "206,99",
    d_global: 394,
    i_global: "36756,16",
    t_global: "17,5",
    z_global: "1865,8",
  },
  {
    profile_section_id: 6651,
    name: "PIPBN394X20",
    type: "Pipe",
    designation: "PIPBN394X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "234,99",
    d_global: 394,
    i_global: "41204,52",
    t_global: 20,
    z_global: "2091,6",
  },
  {
    profile_section_id: 6652,
    name: "PIPBN394X22.2",
    type: "Pipe",
    designation: "PIPBN394X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "259,31",
    d_global: 394,
    i_global: "44966,25",
    t_global: "22,2",
    z_global: "2282,55",
  },
  {
    profile_section_id: 6653,
    name: "PIPBN394X25",
    type: "Pipe",
    designation: "PIPBN394X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "289,81",
    d_global: 394,
    i_global: "49552,77",
    t_global: 25,
    z_global: "2515,37",
  },
  {
    profile_section_id: 6654,
    name: "PIPBN394X28",
    type: "Pipe",
    designation: "PIPBN394X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "321,95",
    d_global: 394,
    i_global: "54224,5",
    t_global: 28,
    z_global: "2752,51",
  },
  {
    profile_section_id: 6655,
    name: "PIPBN394X30",
    type: "Pipe",
    designation: "PIPBN394X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "343,06",
    d_global: 394,
    i_global: "57203,86",
    t_global: 30,
    z_global: "2903,75",
  },
  {
    profile_section_id: 6656,
    name: "PIPBN394X36",
    type: "Pipe",
    designation: "PIPBN394X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "404,89",
    d_global: 394,
    i_global: "65521,07",
    t_global: 36,
    z_global: "3325,94",
  },
  {
    profile_section_id: 6657,
    name: "PIPBN394X40",
    type: "Pipe",
    designation: "PIPBN394X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "444,85",
    d_global: 394,
    i_global: "70573,15",
    t_global: 40,
    z_global: "3582,39",
  },
  {
    profile_section_id: 6658,
    name: "PIPBN394X45",
    type: "Pipe",
    designation: "PIPBN394X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "493,39",
    d_global: 394,
    i_global: "76367,7",
    t_global: 45,
    z_global: "3876,53",
  },
  {
    profile_section_id: 6659,
    name: "PIPBN394X50",
    type: "Pipe",
    designation: "PIPBN394X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "540,35",
    d_global: 394,
    i_global: "81617,76",
    t_global: 50,
    z_global: "4143,03",
  },
  {
    profile_section_id: 6660,
    name: "PIPBN406.4X8.8",
    type: "Pipe",
    designation: "PIPBN406.4X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "109,92",
    d_global: "406,4",
    i_global: "21731,73",
    t_global: "8,8",
    z_global: "1069,47",
  },
  {
    profile_section_id: 6661,
    name: "PIPBN406.4X10",
    type: "Pipe",
    designation: "PIPBN406.4X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "124,53",
    d_global: "406,4",
    i_global: "24475,81",
    t_global: 10,
    z_global: "1204,52",
  },
  {
    profile_section_id: 6662,
    name: "PIPBN406.4X11",
    type: "Pipe",
    designation: "PIPBN406.4X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "136,64",
    d_global: "406,4",
    i_global: "26723,82",
    t_global: 11,
    z_global: "1315,15",
  },
  {
    profile_section_id: 6663,
    name: "PIPBN406.4X12.5",
    type: "Pipe",
    designation: "PIPBN406.4X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "154,68",
    d_global: "406,4",
    i_global: "30030,67",
    t_global: "12,5",
    z_global: "1477,89",
  },
  {
    profile_section_id: 6664,
    name: "PIPBN406.4X14.2",
    type: "Pipe",
    designation: "PIPBN406.4X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "174,96",
    d_global: "406,4",
    i_global: "33685,26",
    t_global: "14,2",
    z_global: "1657,74",
  },
  {
    profile_section_id: 6665,
    name: "PIPBN406.4X16",
    type: "Pipe",
    designation: "PIPBN406.4X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "196,24",
    d_global: "406,4",
    i_global: "37448,82",
    t_global: 16,
    z_global: "1842,95",
  },
  {
    profile_section_id: 6666,
    name: "PIPBN406.4X17.5",
    type: "Pipe",
    designation: "PIPBN406.4X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "213,81",
    d_global: "406,4",
    i_global: "40503,29",
    t_global: "17,5",
    z_global: "1993,27",
  },
  {
    profile_section_id: 6667,
    name: "PIPBN406.4X20",
    type: "Pipe",
    designation: "PIPBN406.4X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "242,78",
    d_global: "406,4",
    i_global: "45432,14",
    t_global: 20,
    z_global: "2235,83",
  },
  {
    profile_section_id: 6668,
    name: "PIPBN406.4X22.2",
    type: "Pipe",
    designation: "PIPBN406.4X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "267,95",
    d_global: "406,4",
    i_global: "49605,81",
    t_global: "22,2",
    z_global: "2441,23",
  },
  {
    profile_section_id: 6669,
    name: "PIPBN406.4X25",
    type: "Pipe",
    designation: "PIPBN406.4X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "299,55",
    d_global: "406,4",
    i_global: "54702,09",
    t_global: 25,
    z_global: "2692,03",
  },
  {
    profile_section_id: 6670,
    name: "PIPBN406.4X28",
    type: "Pipe",
    designation: "PIPBN406.4X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "332,86",
    d_global: "406,4",
    i_global: "59902,2",
    t_global: 28,
    z_global: "2947,94",
  },
  {
    profile_section_id: 6671,
    name: "PIPBN406.4X30",
    type: "Pipe",
    designation: "PIPBN406.4X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "354,75",
    d_global: "406,4",
    i_global: "63223,73",
    t_global: 30,
    z_global: "3111,4",
  },
  {
    profile_section_id: 6672,
    name: "PIPBN406.4X32",
    type: "Pipe",
    designation: "PIPBN406.4X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "376,39",
    d_global: "406,4",
    i_global: "66432,17",
    t_global: 32,
    z_global: "3269,3",
  },
  {
    profile_section_id: 6673,
    name: "PIPBN406.4X36",
    type: "Pipe",
    designation: "PIPBN406.4X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "418,91",
    d_global: "406,4",
    i_global: "72520,13",
    t_global: 36,
    z_global: "3568,9",
  },
  {
    profile_section_id: 6674,
    name: "PIPBN406.4X40",
    type: "Pipe",
    designation: "PIPBN406.4X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "460,43",
    d_global: "406,4",
    i_global: "78186,48",
    t_global: 40,
    z_global: "3847,76",
  },
  {
    profile_section_id: 6675,
    name: "PIPBN406.4X45",
    type: "Pipe",
    designation: "PIPBN406.4X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "510,92",
    d_global: "406,4",
    i_global: "84706,85",
    t_global: 45,
    z_global: "4168,64",
  },
  {
    profile_section_id: 6676,
    name: "PIPBN406.4X50",
    type: "Pipe",
    designation: "PIPBN406.4X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "559,83",
    d_global: "406,4",
    i_global: "90637,45",
    t_global: 50,
    z_global: "4460,5",
  },
  {
    profile_section_id: 6677,
    name: "PIPBN406.4X55",
    type: "Pipe",
    designation: "PIPBN406.4X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "607,18",
    d_global: "406,4",
    i_global: "96014,93",
    t_global: 55,
    z_global: "4725,14",
  },
  {
    profile_section_id: 6678,
    name: "PIPBN406.4X60",
    type: "Pipe",
    designation: "PIPBN406.4X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "652,95",
    d_global: "406,4",
    i_global: "100874,8",
    t_global: 60,
    z_global: "4964,31",
  },
  {
    profile_section_id: 6679,
    name: "PIPBN406.4X65",
    type: "Pipe",
    designation: "PIPBN406.4X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "697,15",
    d_global: "406,4",
    i_global: "105251,4",
    t_global: 65,
    z_global: "5179,7",
  },
  {
    profile_section_id: 6680,
    name: "PIPBN406.4X70",
    type: "Pipe",
    designation: "PIPBN406.4X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "739,78",
    d_global: "406,4",
    i_global: 109178,
    t_global: 70,
    z_global: "5372,93",
  },
  {
    profile_section_id: 6681,
    name: "PIPBN406.4X75",
    type: "Pipe",
    designation: "PIPBN406.4X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "780,84",
    d_global: "406,4",
    i_global: "112686,3",
    t_global: 75,
    z_global: "5545,59",
  },
  {
    profile_section_id: 6682,
    name: "PIPBN406.4X80",
    type: "Pipe",
    designation: "PIPBN406.4X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "820,33",
    d_global: "406,4",
    i_global: "115807,4",
    t_global: 80,
    z_global: "5699,18",
  },
  {
    profile_section_id: 6683,
    name: "PIPBN406.4X85",
    type: "Pipe",
    designation: "PIPBN406.4X85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "858,25",
    d_global: "406,4",
    i_global: "118570,6",
    t_global: 85,
    z_global: "5835,17",
  },
  {
    profile_section_id: 6684,
    name: "PIPBN406.4X90",
    type: "Pipe",
    designation: "PIPBN406.4X90",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "894,6",
    d_global: "406,4",
    i_global: "121004,7",
    t_global: 90,
    z_global: "5954,95",
  },
  {
    profile_section_id: 6685,
    name: "PIPBN406.4X100",
    type: "Pipe",
    designation: "PIPBN406.4X100",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "962,58",
    d_global: "406,4",
    i_global: "124992,7",
    t_global: 100,
    z_global: "6151,22",
  },
  {
    profile_section_id: 6686,
    name: "PIPBN419X10",
    type: "Pipe",
    designation: "PIPBN419X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "128,49",
    d_global: 419,
    i_global: "26883,72",
    t_global: 10,
    z_global: "1283,23",
  },
  {
    profile_section_id: 6687,
    name: "PIPBN419X11",
    type: "Pipe",
    designation: "PIPBN419X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "140,99",
    d_global: 419,
    i_global: "29359,5",
    t_global: 11,
    z_global: "1401,41",
  },
  {
    profile_section_id: 6688,
    name: "PIPBN419X12.5",
    type: "Pipe",
    designation: "PIPBN419X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "159,63",
    d_global: 419,
    i_global: "33003,65",
    t_global: "12,5",
    z_global: "1575,35",
  },
  {
    profile_section_id: 6689,
    name: "PIPBN419X14.2",
    type: "Pipe",
    designation: "PIPBN419X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "180,58",
    d_global: 419,
    i_global: "37034,27",
    t_global: "14,2",
    z_global: "1767,75",
  },
  {
    profile_section_id: 6690,
    name: "PIPBN419X16",
    type: "Pipe",
    designation: "PIPBN419X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "202,57",
    d_global: 419,
    i_global: "41188,79",
    t_global: 16,
    z_global: "1966,05",
  },
  {
    profile_section_id: 6691,
    name: "PIPBN419X17.5",
    type: "Pipe",
    designation: "PIPBN419X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "220,74",
    d_global: 419,
    i_global: "44563,46",
    t_global: "17,5",
    z_global: "2127,13",
  },
  {
    profile_section_id: 6692,
    name: "PIPBN419X20",
    type: "Pipe",
    designation: "PIPBN419X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "250,7",
    d_global: 419,
    i_global: "50014,78",
    t_global: 20,
    z_global: "2387,34",
  },
  {
    profile_section_id: 6693,
    name: "PIPBN419X22.5",
    type: "Pipe",
    designation: "PIPBN419X22.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "280,27",
    d_global: 419,
    i_global: "55254,57",
    t_global: "22,5",
    z_global: "2637,45",
  },
  {
    profile_section_id: 6694,
    name: "PIPBN419X25",
    type: "Pipe",
    designation: "PIPBN419X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "309,45",
    d_global: 419,
    i_global: "60288,38",
    t_global: 25,
    z_global: "2877,73",
  },
  {
    profile_section_id: 6695,
    name: "PIPBN419X28",
    type: "Pipe",
    designation: "PIPBN419X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "343,94",
    d_global: 419,
    i_global: "66064,73",
    t_global: 28,
    z_global: "3153,45",
  },
  {
    profile_section_id: 6696,
    name: "PIPBN419X30",
    type: "Pipe",
    designation: "PIPBN419X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "366,62",
    d_global: 419,
    i_global: "69759,81",
    t_global: 30,
    z_global: "3329,82",
  },
  {
    profile_section_id: 6697,
    name: "PIPBN419X32",
    type: "Pipe",
    designation: "PIPBN419X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "389,05",
    d_global: 419,
    i_global: "73333,43",
    t_global: 32,
    z_global: "3500,4",
  },
  {
    profile_section_id: 6698,
    name: "PIPBN419X36",
    type: "Pipe",
    designation: "PIPBN419X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "433,16",
    d_global: 419,
    i_global: "80126,99",
    t_global: 36,
    z_global: "3824,68",
  },
  {
    profile_section_id: 6699,
    name: "PIPBN419X40",
    type: "Pipe",
    designation: "PIPBN419X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "476,27",
    d_global: 419,
    i_global: "86466,59",
    t_global: 40,
    z_global: "4127,28",
  },
  {
    profile_section_id: 6700,
    name: "PIPBN419X45",
    type: "Pipe",
    designation: "PIPBN419X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "528,73",
    d_global: 419,
    i_global: "93784,15",
    t_global: 45,
    z_global: "4476,57",
  },
  {
    profile_section_id: 6701,
    name: "PIPBN419X50",
    type: "Pipe",
    designation: "PIPBN419X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "579,62",
    d_global: 419,
    i_global: 100464,
    t_global: 50,
    z_global: "4795,42",
  },
  {
    profile_section_id: 6702,
    name: "PIPBN419X55",
    type: "Pipe",
    designation: "PIPBN419X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "628,95",
    d_global: 419,
    i_global: "106544,4",
    t_global: 55,
    z_global: "5085,65",
  },
  {
    profile_section_id: 6703,
    name: "PIPBN419X60",
    type: "Pipe",
    designation: "PIPBN419X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "676,7",
    d_global: 419,
    i_global: "112062,2",
    t_global: 60,
    z_global: "5349,03",
  },
  {
    profile_section_id: 6704,
    name: "PIPBN419X65",
    type: "Pipe",
    designation: "PIPBN419X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "722,88",
    d_global: 419,
    i_global: "117053,3",
    t_global: 65,
    z_global: "5587,27",
  },
  {
    profile_section_id: 6705,
    name: "PIPBN419X70",
    type: "Pipe",
    designation: "PIPBN419X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "767,49",
    d_global: 419,
    i_global: "121552,4",
    t_global: 70,
    z_global: "5802,02",
  },
  {
    profile_section_id: 6706,
    name: "PIPBN419X75",
    type: "Pipe",
    designation: "PIPBN419X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "810,53",
    d_global: 419,
    i_global: "125592,8",
    t_global: 75,
    z_global: "5994,88",
  },
  {
    profile_section_id: 6707,
    name: "PIPBN419X80",
    type: "Pipe",
    designation: "PIPBN419X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 852,
    d_global: 419,
    i_global: "129206,9",
    t_global: 80,
    z_global: "6167,39",
  },
  {
    profile_section_id: 6708,
    name: "PIPBN419X85",
    type: "Pipe",
    designation: "PIPBN419X85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "891,9",
    d_global: 419,
    i_global: "132425,7",
    t_global: 85,
    z_global: "6321,03",
  },
  {
    profile_section_id: 6709,
    name: "PIPBN419X90",
    type: "Pipe",
    designation: "PIPBN419X90",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "930,23",
    d_global: 419,
    i_global: "135279,2",
    t_global: 90,
    z_global: "6457,24",
  },
  {
    profile_section_id: 6710,
    name: "PIPBN419X100",
    type: "Pipe",
    designation: "PIPBN419X100",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1002,17",
    d_global: 419,
    i_global: "140004,1",
    t_global: 100,
    z_global: "6682,77",
  },
  {
    profile_section_id: 6711,
    name: "PIPBN445X10",
    type: "Pipe",
    designation: "PIPBN445X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "136,66",
    d_global: 445,
    i_global: "32341,27",
    t_global: 10,
    z_global: "1453,54",
  },
  {
    profile_section_id: 6712,
    name: "PIPBN445X11",
    type: "Pipe",
    designation: "PIPBN445X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "149,98",
    d_global: 445,
    i_global: "35334,64",
    t_global: 11,
    z_global: "1588,07",
  },
  {
    profile_section_id: 6713,
    name: "PIPBN445X12.5",
    type: "Pipe",
    designation: "PIPBN445X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "169,84",
    d_global: 445,
    i_global: "39745,77",
    t_global: "12,5",
    z_global: "1786,33",
  },
  {
    profile_section_id: 6714,
    name: "PIPBN445X14.2",
    type: "Pipe",
    designation: "PIPBN445X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "192,18",
    d_global: 445,
    i_global: "44632,06",
    t_global: "14,2",
    z_global: "2005,93",
  },
  {
    profile_section_id: 6715,
    name: "PIPBN445X16",
    type: "Pipe",
    designation: "PIPBN445X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "215,64",
    d_global: 445,
    i_global: "49677,01",
    t_global: 16,
    z_global: "2232,67",
  },
  {
    profile_section_id: 6716,
    name: "PIPBN445X17.5",
    type: "Pipe",
    designation: "PIPBN445X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "235,03",
    d_global: 445,
    i_global: "53781,57",
    t_global: "17,5",
    z_global: "2417,15",
  },
  {
    profile_section_id: 6717,
    name: "PIPBN445X20",
    type: "Pipe",
    designation: "PIPBN445X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "267,04",
    d_global: 445,
    i_global: "60425,1",
    t_global: 20,
    z_global: "2715,74",
  },
  {
    profile_section_id: 6718,
    name: "PIPBN445X22.2",
    type: "Pipe",
    designation: "PIPBN445X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "294,87",
    d_global: 445,
    i_global: "66071,4",
    t_global: "22,2",
    z_global: "2969,5",
  },
  {
    profile_section_id: 6719,
    name: "PIPBN445X25",
    type: "Pipe",
    designation: "PIPBN445X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "329,87",
    d_global: 445,
    i_global: "72993,43",
    t_global: 25,
    z_global: "3280,6",
  },
  {
    profile_section_id: 6720,
    name: "PIPBN445X30",
    type: "Pipe",
    designation: "PIPBN445X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "391,13",
    d_global: 445,
    i_global: "84642,61",
    t_global: 30,
    z_global: "3804,16",
  },
  {
    profile_section_id: 6721,
    name: "PIPBN445X36",
    type: "Pipe",
    designation: "PIPBN445X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "462,57",
    d_global: 445,
    i_global: "97472,93",
    t_global: 36,
    z_global: "4380,81",
  },
  {
    profile_section_id: 6722,
    name: "PIPBN445X40",
    type: "Pipe",
    designation: "PIPBN445X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "508,94",
    d_global: 445,
    i_global: "105366,1",
    t_global: 40,
    z_global: "4735,55",
  },
  {
    profile_section_id: 6723,
    name: "PIPBN445X45",
    type: "Pipe",
    designation: "PIPBN445X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "565,49",
    d_global: 445,
    i_global: "114528,7",
    t_global: 45,
    z_global: "5147,36",
  },
  {
    profile_section_id: 6724,
    name: "PIPBN445X50",
    type: "Pipe",
    designation: "PIPBN445X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "620,46",
    d_global: 445,
    i_global: "122948,9",
    t_global: 50,
    z_global: "5525,8",
  },
  {
    profile_section_id: 6725,
    name: "PIPBN457X10",
    type: "Pipe",
    designation: "PIPBN457X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "140,43",
    d_global: 457,
    i_global: "35091,32",
    t_global: 10,
    z_global: "1535,72",
  },
  {
    profile_section_id: 6726,
    name: "PIPBN457X11",
    type: "Pipe",
    designation: "PIPBN457X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "154,13",
    d_global: 457,
    i_global: "38346,11",
    t_global: 11,
    z_global: "1678,17",
  },
  {
    profile_section_id: 6727,
    name: "PIPBN457X12.5",
    type: "Pipe",
    designation: "PIPBN457X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "174,55",
    d_global: 457,
    i_global: "43144,8",
    t_global: "12,5",
    z_global: "1888,17",
  },
  {
    profile_section_id: 6728,
    name: "PIPBN457X14.2",
    type: "Pipe",
    designation: "PIPBN457X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "197,54",
    d_global: 457,
    i_global: "48463,8",
    t_global: "14,2",
    z_global: "2120,95",
  },
  {
    profile_section_id: 6729,
    name: "PIPBN457X16",
    type: "Pipe",
    designation: "PIPBN457X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "221,67",
    d_global: 457,
    i_global: "53959,38",
    t_global: 16,
    z_global: "2361,46",
  },
  {
    profile_section_id: 6730,
    name: "PIPBN457X17.5",
    type: "Pipe",
    designation: "PIPBN457X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "241,63",
    d_global: 457,
    i_global: "58433,59",
    t_global: "17,5",
    z_global: "2557,27",
  },
  {
    profile_section_id: 6731,
    name: "PIPBN457X20",
    type: "Pipe",
    designation: "PIPBN457X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "274,58",
    d_global: 457,
    i_global: "65681,48",
    t_global: 20,
    z_global: "2874,46",
  },
  {
    profile_section_id: 6732,
    name: "PIPBN457X22.2",
    type: "Pipe",
    designation: "PIPBN457X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "303,24",
    d_global: 457,
    i_global: "71847,59",
    t_global: "22,2",
    z_global: "3144,31",
  },
  {
    profile_section_id: 6733,
    name: "PIPBN457X25",
    type: "Pipe",
    designation: "PIPBN457X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "339,29",
    d_global: 457,
    i_global: "79415,11",
    t_global: 25,
    z_global: "3475,5",
  },
  {
    profile_section_id: 6734,
    name: "PIPBN457X28",
    type: "Pipe",
    designation: "PIPBN457X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "377,37",
    d_global: 457,
    i_global: "87183,83",
    t_global: 28,
    z_global: "3815,49",
  },
  {
    profile_section_id: 6735,
    name: "PIPBN457X30",
    type: "Pipe",
    designation: "PIPBN457X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "402,44",
    d_global: 457,
    i_global: "92172,9",
    t_global: 30,
    z_global: "4033,82",
  },
  {
    profile_section_id: 6736,
    name: "PIPBN457X32",
    type: "Pipe",
    designation: "PIPBN457X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "427,26",
    d_global: 457,
    i_global: "97013,42",
    t_global: 32,
    z_global: "4245,66",
  },
  {
    profile_section_id: 6737,
    name: "PIPBN457X36",
    type: "Pipe",
    designation: "PIPBN457X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "476,14",
    d_global: 457,
    i_global: "106260,7",
    t_global: 36,
    z_global: "4650,36",
  },
  {
    profile_section_id: 6738,
    name: "PIPBN457X40",
    type: "Pipe",
    designation: "PIPBN457X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "524,02",
    d_global: 457,
    i_global: "114949,2",
    t_global: 40,
    z_global: "5030,6",
  },
  {
    profile_section_id: 6739,
    name: "PIPBN457X45",
    type: "Pipe",
    designation: "PIPBN457X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "582,45",
    d_global: 457,
    i_global: "125058,8",
    t_global: 45,
    z_global: "5473,03",
  },
  {
    profile_section_id: 6740,
    name: "PIPBN457X50",
    type: "Pipe",
    designation: "PIPBN457X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "639,31",
    d_global: 457,
    i_global: 134375,
    t_global: 50,
    z_global: "5880,74",
  },
  {
    profile_section_id: 6741,
    name: "PIPBN457X55",
    type: "Pipe",
    designation: "PIPBN457X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "694,61",
    d_global: 457,
    i_global: "142940,4",
    t_global: 55,
    z_global: "6255,6",
  },
  {
    profile_section_id: 6742,
    name: "PIPBN457X60",
    type: "Pipe",
    designation: "PIPBN457X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "748,33",
    d_global: 457,
    i_global: "150796,4",
    t_global: 60,
    z_global: "6599,4",
  },
  {
    profile_section_id: 6743,
    name: "PIPBN457X65",
    type: "Pipe",
    designation: "PIPBN457X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "800,48",
    d_global: 457,
    i_global: "157983,3",
    t_global: 65,
    z_global: "6913,93",
  },
  {
    profile_section_id: 6744,
    name: "PIPBN457X70",
    type: "Pipe",
    designation: "PIPBN457X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "851,06",
    d_global: 457,
    i_global: "164540,3",
    t_global: 70,
    z_global: "7200,89",
  },
  {
    profile_section_id: 6745,
    name: "PIPBN457X75",
    type: "Pipe",
    designation: "PIPBN457X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "900,07",
    d_global: 457,
    i_global: "170505,2",
    t_global: 75,
    z_global: "7461,93",
  },
  {
    profile_section_id: 6746,
    name: "PIPBN457X80",
    type: "Pipe",
    designation: "PIPBN457X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "947,5",
    d_global: 457,
    i_global: "175914,8",
    t_global: 80,
    z_global: "7698,68",
  },
  {
    profile_section_id: 6747,
    name: "PIPBN457X85",
    type: "Pipe",
    designation: "PIPBN457X85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "993,37",
    d_global: 457,
    i_global: "180804,8",
    t_global: 85,
    z_global: "7912,68",
  },
  {
    profile_section_id: 6748,
    name: "PIPBN457X90",
    type: "Pipe",
    designation: "PIPBN457X90",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1037,67",
    d_global: 457,
    i_global: "185209,5",
    t_global: 90,
    z_global: "8105,45",
  },
  {
    profile_section_id: 6749,
    name: "PIPBN457X100",
    type: "Pipe",
    designation: "PIPBN457X100",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1121,55",
    d_global: 457,
    i_global: "192694,7",
    t_global: 100,
    z_global: "8433,03",
  },
  {
    profile_section_id: 6750,
    name: "PIPBN457.2X10",
    type: "Pipe",
    designation: "PIPBN457.2X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "140,49",
    d_global: "457,2",
    i_global: "35138,43",
    t_global: 10,
    z_global: "1537,11",
  },
  {
    profile_section_id: 6751,
    name: "PIPBN470X10.5",
    type: "Pipe",
    designation: "PIPBN470X10.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "151,57",
    d_global: 470,
    i_global: "40025,1",
    t_global: "10,5",
    z_global: "1703,2",
  },
  {
    profile_section_id: 6752,
    name: "PIPBN470X11",
    type: "Pipe",
    designation: "PIPBN470X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "158,62",
    d_global: 470,
    i_global: "41796,51",
    t_global: 11,
    z_global: "1778,58",
  },
  {
    profile_section_id: 6753,
    name: "PIPBN470X12.5",
    type: "Pipe",
    designation: "PIPBN470X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "179,66",
    d_global: 470,
    i_global: 47040,
    t_global: "12,5",
    z_global: "2001,7",
  },
  {
    profile_section_id: 6754,
    name: "PIPBN470X14",
    type: "Pipe",
    designation: "PIPBN470X14",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "203,34",
    d_global: 470,
    i_global: "52855,78",
    t_global: "14,2",
    z_global: "2249,18",
  },
  {
    profile_section_id: 6755,
    name: "PIPBN470X16",
    type: "Pipe",
    designation: "PIPBN470X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "228,21",
    d_global: 470,
    i_global: "58868,98",
    t_global: 16,
    z_global: "2505,06",
  },
  {
    profile_section_id: 6756,
    name: "PIPBN470X17.5",
    type: "Pipe",
    designation: "PIPBN470X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "248,77",
    d_global: 470,
    i_global: 63768,
    t_global: "17,5",
    z_global: "2713,53",
  },
  {
    profile_section_id: 6757,
    name: "PIPBN470X20",
    type: "Pipe",
    designation: "PIPBN470X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "282,74",
    d_global: 470,
    i_global: "71710,78",
    t_global: 20,
    z_global: "3051,52",
  },
  {
    profile_section_id: 6758,
    name: "PIPBN470X22.2",
    type: "Pipe",
    designation: "PIPBN470X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "312,31",
    d_global: 470,
    i_global: "78474,98",
    t_global: "22,2",
    z_global: "3339,36",
  },
  {
    profile_section_id: 6759,
    name: "PIPBN470X25",
    type: "Pipe",
    designation: "PIPBN470X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "349,5",
    d_global: 470,
    i_global: "86785,76",
    t_global: 25,
    z_global: "3693,01",
  },
  {
    profile_section_id: 6760,
    name: "PIPBN470X28",
    type: "Pipe",
    designation: "PIPBN470X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "388,8",
    d_global: 470,
    i_global: "95328,79",
    t_global: 28,
    z_global: "4056,54",
  },
  {
    profile_section_id: 6761,
    name: "PIPBN470X30",
    type: "Pipe",
    designation: "PIPBN470X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "414,69",
    d_global: 470,
    i_global: "100821,6",
    t_global: 30,
    z_global: "4290,28",
  },
  {
    profile_section_id: 6762,
    name: "PIPBN470X36",
    type: "Pipe",
    designation: "PIPBN470X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "490,84",
    d_global: 470,
    i_global: "116361,6",
    t_global: 36,
    z_global: "4951,56",
  },
  {
    profile_section_id: 6763,
    name: "PIPBN470X45",
    type: "Pipe",
    designation: "PIPBN470X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "600,83",
    d_global: 470,
    i_global: "137176,9",
    t_global: 45,
    z_global: "5837,31",
  },
  {
    profile_section_id: 6764,
    name: "PIPBN470X50",
    type: "Pipe",
    designation: "PIPBN470X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "659,73",
    d_global: 470,
    i_global: "147533,1",
    t_global: 50,
    z_global: "6278,01",
  },
  {
    profile_section_id: 6765,
    name: "PIPBN470X60",
    type: "Pipe",
    designation: "PIPBN470X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "772,83",
    d_global: 470,
    i_global: 165869,
    t_global: 60,
    z_global: "7058,26",
  },
  {
    profile_section_id: 6766,
    name: "PIPBN495X12.5",
    type: "Pipe",
    designation: "PIPBN495X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "189,48",
    d_global: 495,
    i_global: "55176,38",
    t_global: "12,5",
    z_global: "2229,35",
  },
  {
    profile_section_id: 6767,
    name: "PIPBN495X14.2",
    type: "Pipe",
    designation: "PIPBN495X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "214,49",
    d_global: 495,
    i_global: "62032,64",
    t_global: "14,2",
    z_global: "2506,37",
  },
  {
    profile_section_id: 6768,
    name: "PIPBN495X16",
    type: "Pipe",
    designation: "PIPBN495X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "240,77",
    d_global: 495,
    i_global: "69130,66",
    t_global: 16,
    z_global: "2793,16",
  },
  {
    profile_section_id: 6769,
    name: "PIPBN495X17.5",
    type: "Pipe",
    designation: "PIPBN495X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "262,52",
    d_global: 495,
    i_global: "74920,55",
    t_global: "17,5",
    z_global: "3027,09",
  },
  {
    profile_section_id: 6770,
    name: "PIPBN495X20",
    type: "Pipe",
    designation: "PIPBN495X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "298,45",
    d_global: 495,
    i_global: "84321,82",
    t_global: 20,
    z_global: "3406,94",
  },
  {
    profile_section_id: 6771,
    name: "PIPBN495X22.2",
    type: "Pipe",
    designation: "PIPBN495X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "329,75",
    d_global: 495,
    i_global: "92342,52",
    t_global: "22,2",
    z_global: "3731,01",
  },
  {
    profile_section_id: 6772,
    name: "PIPBN495X25",
    type: "Pipe",
    designation: "PIPBN495X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "369,14",
    d_global: 495,
    i_global: "102216,4",
    t_global: 25,
    z_global: "4129,95",
  },
  {
    profile_section_id: 6773,
    name: "PIPBN495X30",
    type: "Pipe",
    designation: "PIPBN495X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "438,25",
    d_global: 495,
    i_global: "118944,4",
    t_global: 30,
    z_global: "4805,83",
  },
  {
    profile_section_id: 6774,
    name: "PIPBN495X36",
    type: "Pipe",
    designation: "PIPBN495X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "519,12",
    d_global: 495,
    i_global: 137551,
    t_global: 36,
    z_global: "5557,62",
  },
  {
    profile_section_id: 6775,
    name: "PIPBN495X40",
    type: "Pipe",
    designation: "PIPBN495X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "571,77",
    d_global: 495,
    i_global: "149106,9",
    t_global: 40,
    z_global: "6024,52",
  },
  {
    profile_section_id: 6776,
    name: "PIPBN495X45",
    type: "Pipe",
    designation: "PIPBN495X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "636,17",
    d_global: 495,
    i_global: "162641,5",
    t_global: 45,
    z_global: "6571,37",
  },
  {
    profile_section_id: 6777,
    name: "PIPBN495X50",
    type: "Pipe",
    designation: "PIPBN495X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 699,
    d_global: 495,
    i_global: "175209,8",
    t_global: 50,
    z_global: "7079,18",
  },
  {
    profile_section_id: 6778,
    name: "PIPBN508X11",
    type: "Pipe",
    designation: "PIPBN508X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "171,75",
    d_global: 508,
    i_global: "53055,99",
    t_global: 11,
    z_global: "2088,82",
  },
  {
    profile_section_id: 6779,
    name: "PIPBN508X12.5",
    type: "Pipe",
    designation: "PIPBN508X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "194,58",
    d_global: 508,
    i_global: "59755,4",
    t_global: "12,5",
    z_global: "2352,57",
  },
  {
    profile_section_id: 6780,
    name: "PIPBN508X14.2",
    type: "Pipe",
    designation: "PIPBN508X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "220,29",
    d_global: 508,
    i_global: "67198,64",
    t_global: "14,2",
    z_global: "2645,62",
  },
  {
    profile_section_id: 6781,
    name: "PIPBN508X16",
    type: "Pipe",
    designation: "PIPBN508X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "247,31",
    d_global: 508,
    i_global: "74909,04",
    t_global: 16,
    z_global: "2949,18",
  },
  {
    profile_section_id: 6782,
    name: "PIPBN508X17.5",
    type: "Pipe",
    designation: "PIPBN508X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "269,67",
    d_global: 508,
    i_global: "81202,13",
    t_global: "17,5",
    z_global: "3196,93",
  },
  {
    profile_section_id: 6783,
    name: "PIPBN508X20",
    type: "Pipe",
    designation: "PIPBN508X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "306,62",
    d_global: 508,
    i_global: "91427,79",
    t_global: 20,
    z_global: "3599,52",
  },
  {
    profile_section_id: 6784,
    name: "PIPBN508X22.2",
    type: "Pipe",
    designation: "PIPBN508X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "338,81",
    d_global: 508,
    i_global: "100159,3",
    t_global: "22,2",
    z_global: "3943,28",
  },
  {
    profile_section_id: 6785,
    name: "PIPBN508X25",
    type: "Pipe",
    designation: "PIPBN508X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "379,35",
    d_global: 508,
    i_global: "110918,3",
    t_global: 25,
    z_global: "4366,86",
  },
  {
    profile_section_id: 6786,
    name: "PIPBN508X28",
    type: "Pipe",
    designation: "PIPBN508X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "422,23",
    d_global: 508,
    i_global: 122016,
    t_global: 28,
    z_global: "4803,78",
  },
  {
    profile_section_id: 6787,
    name: "PIPBN508X30",
    type: "Pipe",
    designation: "PIPBN508X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "450,5",
    d_global: 508,
    i_global: "129173,1",
    t_global: 30,
    z_global: "5085,56",
  },
  {
    profile_section_id: 6788,
    name: "PIPBN508X32",
    type: "Pipe",
    designation: "PIPBN508X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "478,53",
    d_global: 508,
    i_global: 136141,
    t_global: 32,
    z_global: "5359,88",
  },
  {
    profile_section_id: 6789,
    name: "PIPBN508X36",
    type: "Pipe",
    designation: "PIPBN508X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "533,82",
    d_global: 508,
    i_global: "149522,8",
    t_global: 36,
    z_global: "5886,73",
  },
  {
    profile_section_id: 6790,
    name: "PIPBN508X40",
    type: "Pipe",
    designation: "PIPBN508X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "588,11",
    d_global: 508,
    i_global: "162187,9",
    t_global: 40,
    z_global: "6385,35",
  },
  {
    profile_section_id: 6791,
    name: "PIPBN508X45",
    type: "Pipe",
    designation: "PIPBN508X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "654,55",
    d_global: 508,
    i_global: "177051,1",
    t_global: 45,
    z_global: "6970,52",
  },
  {
    profile_section_id: 6792,
    name: "PIPBN508X50",
    type: "Pipe",
    designation: "PIPBN508X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "719,42",
    d_global: 508,
    i_global: 190885,
    t_global: 50,
    z_global: "7515,16",
  },
  {
    profile_section_id: 6793,
    name: "PIPBN508X55",
    type: "Pipe",
    designation: "PIPBN508X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "782,73",
    d_global: 508,
    i_global: "203738,2",
    t_global: 55,
    z_global: "8021,19",
  },
  {
    profile_section_id: 6794,
    name: "PIPBN508X60",
    type: "Pipe",
    designation: "PIPBN508X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "844,46",
    d_global: 508,
    i_global: "215658,2",
    t_global: 60,
    z_global: "8490,48",
  },
  {
    profile_section_id: 6795,
    name: "PIPBN508X65",
    type: "Pipe",
    designation: "PIPBN508X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "904,62",
    d_global: 508,
    i_global: "226691,4",
    t_global: 65,
    z_global: "8924,86",
  },
  {
    profile_section_id: 6796,
    name: "PIPBN508X70",
    type: "Pipe",
    designation: "PIPBN508X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "963,21",
    d_global: 508,
    i_global: "236882,8",
    t_global: 70,
    z_global: "9326,09",
  },
  {
    profile_section_id: 6797,
    name: "PIPBN508X75",
    type: "Pipe",
    designation: "PIPBN508X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1020,23",
    d_global: 508,
    i_global: "246276,4",
    t_global: 75,
    z_global: "9695,92",
  },
  {
    profile_section_id: 6798,
    name: "PIPBN508X80",
    type: "Pipe",
    designation: "PIPBN508X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1075,68",
    d_global: 508,
    i_global: 254915,
    t_global: 80,
    z_global: "10036,02",
  },
  {
    profile_section_id: 6799,
    name: "PIPBN508X85",
    type: "Pipe",
    designation: "PIPBN508X85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1129,56",
    d_global: 508,
    i_global: "262840,1",
    t_global: 85,
    z_global: "10348,03",
  },
  {
    profile_section_id: 6800,
    name: "PIPBN508X90",
    type: "Pipe",
    designation: "PIPBN508X90",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1181,87",
    d_global: 508,
    i_global: "270092,1",
    t_global: 90,
    z_global: "10633,55",
  },
  {
    profile_section_id: 6801,
    name: "PIPBN508X100",
    type: "Pipe",
    designation: "PIPBN508X100",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1281,77",
    d_global: 508,
    i_global: "282732,8",
    t_global: 100,
    z_global: "11131,21",
  },
  {
    profile_section_id: 6802,
    name: "PIPBN521X11.5",
    type: "Pipe",
    designation: "PIPBN521X11.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "184,07",
    d_global: 521,
    i_global: "59760,13",
    t_global: "11,5",
    z_global: "2294,05",
  },
  {
    profile_section_id: 6803,
    name: "PIPBN521X12.5",
    type: "Pipe",
    designation: "PIPBN521X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "199,69",
    d_global: 521,
    i_global: "64581,05",
    t_global: "12,5",
    z_global: "2479,12",
  },
  {
    profile_section_id: 6804,
    name: "PIPBN521X14.2",
    type: "Pipe",
    designation: "PIPBN521X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "226,09",
    d_global: 521,
    i_global: "72643,85",
    t_global: "14,2",
    z_global: "2788,63",
  },
  {
    profile_section_id: 6805,
    name: "PIPBN521X16",
    type: "Pipe",
    designation: "PIPBN521X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "253,84",
    d_global: 521,
    i_global: "81000,88",
    t_global: 16,
    z_global: "3109,44",
  },
  {
    profile_section_id: 6806,
    name: "PIPBN521X17.5",
    type: "Pipe",
    designation: "PIPBN521X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "276,81",
    d_global: 521,
    i_global: "87825,51",
    t_global: "17,5",
    z_global: "3371,42",
  },
  {
    profile_section_id: 6807,
    name: "PIPBN521X20",
    type: "Pipe",
    designation: "PIPBN521X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "314,79",
    d_global: 521,
    i_global: "98922,39",
    t_global: 20,
    z_global: "3797,41",
  },
  {
    profile_section_id: 6808,
    name: "PIPBN521X22.2",
    type: "Pipe",
    designation: "PIPBN521X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "347,88",
    d_global: 521,
    i_global: "108405,6",
    t_global: "22,2",
    z_global: "4161,44",
  },
  {
    profile_section_id: 6809,
    name: "PIPBN521X25",
    type: "Pipe",
    designation: "PIPBN521X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "389,56",
    d_global: 521,
    i_global: "120101,1",
    t_global: 25,
    z_global: "4610,4",
  },
  {
    profile_section_id: 6810,
    name: "PIPBN521X28",
    type: "Pipe",
    designation: "PIPBN521X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "433,67",
    d_global: 521,
    i_global: "132177,4",
    t_global: 28,
    z_global: "5073,99",
  },
  {
    profile_section_id: 6811,
    name: "PIPBN521X30",
    type: "Pipe",
    designation: "PIPBN521X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "462,76",
    d_global: 521,
    i_global: "139972,9",
    t_global: 30,
    z_global: "5373,24",
  },
  {
    profile_section_id: 6812,
    name: "PIPBN521X32",
    type: "Pipe",
    designation: "PIPBN521X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "491,6",
    d_global: 521,
    i_global: 147568,
    t_global: 32,
    z_global: "5664,8",
  },
  {
    profile_section_id: 6813,
    name: "PIPBN521X36",
    type: "Pipe",
    designation: "PIPBN521X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "548,52",
    d_global: 521,
    i_global: "162171,2",
    t_global: 36,
    z_global: "6225,38",
  },
  {
    profile_section_id: 6814,
    name: "PIPBN521X40",
    type: "Pipe",
    designation: "PIPBN521X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "604,44",
    d_global: 521,
    i_global: "176014,4",
    t_global: 40,
    z_global: "6756,79",
  },
  {
    profile_section_id: 6815,
    name: "PIPBN521X45",
    type: "Pipe",
    designation: "PIPBN521X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "672,93",
    d_global: 521,
    i_global: "192290,3",
    t_global: 45,
    z_global: "7381,59",
  },
  {
    profile_section_id: 6816,
    name: "PIPBN521X50",
    type: "Pipe",
    designation: "PIPBN521X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "739,85",
    d_global: 521,
    i_global: 207472,
    t_global: 50,
    z_global: "7964,38",
  },
  {
    profile_section_id: 6817,
    name: "PIPBN546X14.2",
    type: "Pipe",
    designation: "PIPBN546X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "237,24",
    d_global: 546,
    i_global: "83927,2",
    t_global: "14,2",
    z_global: "3074,26",
  },
  {
    profile_section_id: 6818,
    name: "PIPBN546X16",
    type: "Pipe",
    designation: "PIPBN546X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "266,41",
    d_global: 546,
    i_global: "93627,43",
    t_global: 16,
    z_global: "3429,58",
  },
  {
    profile_section_id: 6819,
    name: "PIPBN546X17.5",
    type: "Pipe",
    designation: "PIPBN546X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "290,56",
    d_global: 546,
    i_global: "101556,8",
    t_global: "17,5",
    z_global: "3720,03",
  },
  {
    profile_section_id: 6820,
    name: "PIPBN546X20",
    type: "Pipe",
    designation: "PIPBN546X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "330,5",
    d_global: 546,
    i_global: "114465,5",
    t_global: 20,
    z_global: "4192,88",
  },
  {
    profile_section_id: 6821,
    name: "PIPBN546X22.2",
    type: "Pipe",
    designation: "PIPBN546X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "365,32",
    d_global: 546,
    i_global: 125513,
    t_global: "22,2",
    z_global: "4597,55",
  },
  {
    profile_section_id: 6822,
    name: "PIPBN546X25",
    type: "Pipe",
    designation: "PIPBN546X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "409,19",
    d_global: 546,
    i_global: "139159,2",
    t_global: 25,
    z_global: "5097,41",
  },
  {
    profile_section_id: 6823,
    name: "PIPBN546X30",
    type: "Pipe",
    designation: "PIPBN546X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "486,32",
    d_global: 546,
    i_global: "162403,6",
    t_global: 30,
    z_global: "5948,85",
  },
  {
    profile_section_id: 6824,
    name: "PIPBN546X36",
    type: "Pipe",
    designation: "PIPBN546X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "576,8",
    d_global: 546,
    i_global: "188465,3",
    t_global: 36,
    z_global: "6903,49",
  },
  {
    profile_section_id: 6825,
    name: "PIPBN546X40",
    type: "Pipe",
    designation: "PIPBN546X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "635,86",
    d_global: 546,
    i_global: 204775,
    t_global: 40,
    z_global: "7500,92",
  },
  {
    profile_section_id: 6826,
    name: "PIPBN546X50",
    type: "Pipe",
    designation: "PIPBN546X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "779,11",
    d_global: 546,
    i_global: "242028,2",
    t_global: 50,
    z_global: "8865,5",
  },
  {
    profile_section_id: 6827,
    name: "PIPBN559X12.5",
    type: "Pipe",
    designation: "PIPBN559X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "214,61",
    d_global: 559,
    i_global: "80161,81",
    t_global: "12,5",
    z_global: "2868,04",
  },
  {
    profile_section_id: 6828,
    name: "PIPBN559X14.2",
    type: "Pipe",
    designation: "PIPBN559X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "243,04",
    d_global: 559,
    i_global: "90230,73",
    t_global: "14,2",
    z_global: "3228,29",
  },
  {
    profile_section_id: 6829,
    name: "PIPBN559X16",
    type: "Pipe",
    designation: "PIPBN559X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "272,94",
    d_global: 559,
    i_global: 100683,
    t_global: 16,
    z_global: "3602,26",
  },
  {
    profile_section_id: 6830,
    name: "PIPBN559X17.5",
    type: "Pipe",
    designation: "PIPBN559X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "297,71",
    d_global: 559,
    i_global: "109231,2",
    t_global: "17,5",
    z_global: "3908,09",
  },
  {
    profile_section_id: 6831,
    name: "PIPBN559X20",
    type: "Pipe",
    designation: "PIPBN559X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "338,66",
    d_global: 559,
    i_global: "123155,5",
    t_global: 20,
    z_global: "4406,28",
  },
  {
    profile_section_id: 6832,
    name: "PIPBN559X22.2",
    type: "Pipe",
    designation: "PIPBN559X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "374,38",
    d_global: 559,
    i_global: "135080,5",
    t_global: "22,2",
    z_global: "4832,93",
  },
  {
    profile_section_id: 6833,
    name: "PIPBN559X25",
    type: "Pipe",
    designation: "PIPBN559X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "419,4",
    d_global: 559,
    i_global: "149821,6",
    t_global: 25,
    z_global: "5360,34",
  },
  {
    profile_section_id: 6834,
    name: "PIPBN559X28",
    type: "Pipe",
    designation: "PIPBN559X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "467,09",
    d_global: 559,
    i_global: "165084,9",
    t_global: 28,
    z_global: "5906,44",
  },
  {
    profile_section_id: 6835,
    name: "PIPBN559X30",
    type: "Pipe",
    designation: "PIPBN559X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "498,57",
    d_global: 559,
    i_global: "174961,6",
    t_global: 30,
    z_global: "6259,81",
  },
  {
    profile_section_id: 6836,
    name: "PIPBN559X32",
    type: "Pipe",
    designation: "PIPBN559X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "529,8",
    d_global: 559,
    i_global: "184603,5",
    t_global: 32,
    z_global: "6604,78",
  },
  {
    profile_section_id: 6837,
    name: "PIPBN559X36",
    type: "Pipe",
    designation: "PIPBN559X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "591,5",
    d_global: 559,
    i_global: "203198,4",
    t_global: 36,
    z_global: "7270,07",
  },
  {
    profile_section_id: 6838,
    name: "PIPBN559X40",
    type: "Pipe",
    designation: "PIPBN559X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "652,19",
    d_global: 559,
    i_global: "220899,1",
    t_global: 40,
    z_global: "7903,37",
  },
  {
    profile_section_id: 6839,
    name: "PIPBN559X45",
    type: "Pipe",
    designation: "PIPBN559X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "726,65",
    d_global: 559,
    i_global: 241812,
    t_global: 45,
    z_global: "8651,59",
  },
  {
    profile_section_id: 6840,
    name: "PIPBN559X50",
    type: "Pipe",
    designation: "PIPBN559X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "799,54",
    d_global: 559,
    i_global: "261429,1",
    t_global: 50,
    z_global: "9353,45",
  },
  {
    profile_section_id: 6841,
    name: "PIPBN559X55",
    type: "Pipe",
    designation: "PIPBN559X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "870,85",
    d_global: 559,
    i_global: 279805,
    t_global: 55,
    z_global: "10010,91",
  },
  {
    profile_section_id: 6842,
    name: "PIPBN559X60",
    type: "Pipe",
    designation: "PIPBN559X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "940,59",
    d_global: 559,
    i_global: "296993,4",
    t_global: 60,
    z_global: "10625,88",
  },
  {
    profile_section_id: 6843,
    name: "PIPBN559X65",
    type: "Pipe",
    designation: "PIPBN559X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1008,77",
    d_global: 559,
    i_global: "313046,4",
    t_global: 65,
    z_global: "11200,23",
  },
  {
    profile_section_id: 6844,
    name: "PIPBN559X70",
    type: "Pipe",
    designation: "PIPBN559X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1075,37",
    d_global: 559,
    i_global: "328015,2",
    t_global: 70,
    z_global: "11735,79",
  },
  {
    profile_section_id: 6845,
    name: "PIPBN559X75",
    type: "Pipe",
    designation: "PIPBN559X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1140,4",
    d_global: 559,
    i_global: "341949,8",
    t_global: 75,
    z_global: "12234,34",
  },
  {
    profile_section_id: 6846,
    name: "PIPBN559X80",
    type: "Pipe",
    designation: "PIPBN559X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1203,86",
    d_global: 559,
    i_global: "354898,9",
    t_global: 80,
    z_global: "12697,64",
  },
  {
    profile_section_id: 6847,
    name: "PIPBN559X85",
    type: "Pipe",
    designation: "PIPBN559X85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1265,75",
    d_global: 559,
    i_global: "366910,2",
    t_global: 85,
    z_global: "13127,38",
  },
  {
    profile_section_id: 6848,
    name: "PIPBN559X90",
    type: "Pipe",
    designation: "PIPBN559X90",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1326,07",
    d_global: 559,
    i_global: 378030,
    t_global: 90,
    z_global: "13525,22",
  },
  {
    profile_section_id: 6849,
    name: "PIPBN559X100",
    type: "Pipe",
    designation: "PIPBN559X100",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1441,99",
    d_global: 559,
    i_global: 397775,
    t_global: 100,
    z_global: "14231,67",
  },
  {
    profile_section_id: 6850,
    name: "PIPBN610X14.2",
    type: "Pipe",
    designation: "PIPBN610X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "265,79",
    d_global: 610,
    i_global: "118003,9",
    t_global: "14,2",
    z_global: "3868,98",
  },
  {
    profile_section_id: 6851,
    name: "PIPBN610X16",
    type: "Pipe",
    designation: "PIPBN610X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "298,58",
    d_global: 610,
    i_global: "131781,4",
    t_global: 16,
    z_global: "4320,7",
  },
  {
    profile_section_id: 6852,
    name: "PIPBN610X17.5",
    type: "Pipe",
    designation: "PIPBN610X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "325,74",
    d_global: 610,
    i_global: "143067,7",
    t_global: "17,5",
    z_global: "4690,74",
  },
  {
    profile_section_id: 6853,
    name: "PIPBN610X20",
    type: "Pipe",
    designation: "PIPBN610X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "370,71",
    d_global: 610,
    i_global: "161489,6",
    t_global: 20,
    z_global: "5294,74",
  },
  {
    profile_section_id: 6854,
    name: "PIPBN610X22.2",
    type: "Pipe",
    designation: "PIPBN610X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "409,95",
    d_global: 610,
    i_global: "177304,9",
    t_global: "22,2",
    z_global: "5813,27",
  },
  {
    profile_section_id: 6855,
    name: "PIPBN610X25",
    type: "Pipe",
    designation: "PIPBN610X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "459,46",
    d_global: 610,
    i_global: "196906,4",
    t_global: 25,
    z_global: "6455,95",
  },
  {
    profile_section_id: 6856,
    name: "PIPBN610X28",
    type: "Pipe",
    designation: "PIPBN610X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "511,95",
    d_global: 610,
    i_global: "217265,6",
    t_global: 28,
    z_global: "7123,46",
  },
  {
    profile_section_id: 6857,
    name: "PIPBN610X30",
    type: "Pipe",
    designation: "PIPBN610X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "546,64",
    d_global: 610,
    i_global: "230475,9",
    t_global: 30,
    z_global: "7556,59",
  },
  {
    profile_section_id: 6858,
    name: "PIPBN610X32",
    type: "Pipe",
    designation: "PIPBN610X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "581,07",
    d_global: 610,
    i_global: "243401,1",
    t_global: 32,
    z_global: "7980,36",
  },
  {
    profile_section_id: 6859,
    name: "PIPBN610X36",
    type: "Pipe",
    designation: "PIPBN610X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "649,18",
    d_global: 610,
    i_global: "268412,7",
    t_global: 36,
    z_global: "8800,42",
  },
  {
    profile_section_id: 6860,
    name: "PIPBN610X40",
    type: "Pipe",
    designation: "PIPBN610X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "716,28",
    d_global: 610,
    i_global: "292333,1",
    t_global: 40,
    z_global: "9584,69",
  },
  {
    profile_section_id: 6861,
    name: "PIPBN610X45",
    type: "Pipe",
    designation: "PIPBN610X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "798,75",
    d_global: 610,
    i_global: 320748,
    t_global: 45,
    z_global: "10516,33",
  },
  {
    profile_section_id: 6862,
    name: "PIPBN610X50",
    type: "Pipe",
    designation: "PIPBN610X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "879,65",
    d_global: 610,
    i_global: "347570,1",
    t_global: 50,
    z_global: "11395,74",
  },
  {
    profile_section_id: 6863,
    name: "PIPBN610X55",
    type: "Pipe",
    designation: "PIPBN610X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "958,97",
    d_global: 610,
    i_global: 372860,
    t_global: 55,
    z_global: "12224,92",
  },
  {
    profile_section_id: 6864,
    name: "PIPBN610X60",
    type: "Pipe",
    designation: "PIPBN610X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1036,73",
    d_global: 610,
    i_global: "396677,1",
    t_global: 60,
    z_global: "13005,81",
  },
  {
    profile_section_id: 6865,
    name: "PIPBN610X65",
    type: "Pipe",
    designation: "PIPBN610X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1112,91",
    d_global: 610,
    i_global: "419079,9",
    t_global: 65,
    z_global: "13740,32",
  },
  {
    profile_section_id: 6866,
    name: "PIPBN610X70",
    type: "Pipe",
    designation: "PIPBN610X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1187,52",
    d_global: 610,
    i_global: "440125,3",
    t_global: 70,
    z_global: "14430,34",
  },
  {
    profile_section_id: 6867,
    name: "PIPBN610X75",
    type: "Pipe",
    designation: "PIPBN610X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1260,56",
    d_global: 610,
    i_global: "459869,5",
    t_global: 75,
    z_global: "15077,69",
  },
  {
    profile_section_id: 6868,
    name: "PIPBN610X80",
    type: "Pipe",
    designation: "PIPBN610X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1332,04",
    d_global: 610,
    i_global: "478367,2",
    t_global: 80,
    z_global: "15684,17",
  },
  {
    profile_section_id: 6869,
    name: "PIPBN610X85",
    type: "Pipe",
    designation: "PIPBN610X85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1401,94",
    d_global: 610,
    i_global: "495671,9",
    t_global: 85,
    z_global: "16251,54",
  },
  {
    profile_section_id: 6870,
    name: "PIPBN610X90",
    type: "Pipe",
    designation: "PIPBN610X90",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1470,27",
    d_global: 610,
    i_global: "511836,1",
    t_global: 90,
    z_global: "16781,51",
  },
  {
    profile_section_id: 6871,
    name: "PIPBN610X100",
    type: "Pipe",
    designation: "PIPBN610X100",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1602,21",
    d_global: 610,
    i_global: "540946,9",
    t_global: 100,
    z_global: "17735,96",
  },
  {
    profile_section_id: 6872,
    name: "PIPBN622X14.2",
    type: "Pipe",
    designation: "PIPBN622X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "271,14",
    d_global: 622,
    i_global: "125275,8",
    t_global: "14,2",
    z_global: "4028,16",
  },
  {
    profile_section_id: 6873,
    name: "PIPBN622X16",
    type: "Pipe",
    designation: "PIPBN622X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "304,61",
    d_global: 622,
    i_global: "139926,6",
    t_global: 16,
    z_global: "4499,25",
  },
  {
    profile_section_id: 6874,
    name: "PIPBN622X17.5",
    type: "Pipe",
    designation: "PIPBN622X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "332,34",
    d_global: 622,
    i_global: "151932,5",
    t_global: "17,5",
    z_global: "4885,29",
  },
  {
    profile_section_id: 6875,
    name: "PIPBN622X20",
    type: "Pipe",
    designation: "PIPBN622X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "378,25",
    d_global: 622,
    i_global: "171537,3",
    t_global: 20,
    z_global: "5515,67",
  },
  {
    profile_section_id: 6876,
    name: "PIPBN622X22.2",
    type: "Pipe",
    designation: "PIPBN622X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "418,32",
    d_global: 622,
    i_global: "188376,5",
    t_global: "22,2",
    z_global: "6057,12",
  },
  {
    profile_section_id: 6877,
    name: "PIPBN622X25",
    type: "Pipe",
    designation: "PIPBN622X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "468,88",
    d_global: 622,
    i_global: "209258,8",
    t_global: 25,
    z_global: "6728,58",
  },
  {
    profile_section_id: 6878,
    name: "PIPBN622X28",
    type: "Pipe",
    designation: "PIPBN622X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "522,51",
    d_global: 622,
    i_global: "230962,3",
    t_global: 28,
    z_global: "7426,44",
  },
  {
    profile_section_id: 6879,
    name: "PIPBN622X30",
    type: "Pipe",
    designation: "PIPBN622X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "557,95",
    d_global: 622,
    i_global: 245053,
    t_global: 30,
    z_global: "7879,52",
  },
  {
    profile_section_id: 6880,
    name: "PIPBN622X32",
    type: "Pipe",
    designation: "PIPBN622X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "593,13",
    d_global: 622,
    i_global: "258846,1",
    t_global: 32,
    z_global: "8323,02",
  },
  {
    profile_section_id: 6881,
    name: "PIPBN622X36",
    type: "Pipe",
    designation: "PIPBN622X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "662,75",
    d_global: 622,
    i_global: "285555,9",
    t_global: 36,
    z_global: "9181,86",
  },
  {
    profile_section_id: 6882,
    name: "PIPBN622X40",
    type: "Pipe",
    designation: "PIPBN622X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "731,36",
    d_global: 622,
    i_global: "311125,4",
    t_global: 40,
    z_global: "10004,03",
  },
  {
    profile_section_id: 6883,
    name: "PIPBN622X45",
    type: "Pipe",
    designation: "PIPBN622X45",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "815,71",
    d_global: 622,
    i_global: "341533,6",
    t_global: 45,
    z_global: "10981,79",
  },
  {
    profile_section_id: 6884,
    name: "PIPBN622X50",
    type: "Pipe",
    designation: "PIPBN622X50",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "898,5",
    d_global: 622,
    i_global: "370274,5",
    t_global: 50,
    z_global: "11905,93",
  },
  {
    profile_section_id: 6885,
    name: "PIPBN622X55",
    type: "Pipe",
    designation: "PIPBN622X55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "979,71",
    d_global: 622,
    i_global: "397410,3",
    t_global: 55,
    z_global: "12778,46",
  },
  {
    profile_section_id: 6886,
    name: "PIPBN622X60",
    type: "Pipe",
    designation: "PIPBN622X60",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1059,35",
    d_global: 622,
    i_global: "423001,8",
    t_global: 60,
    z_global: "13601,34",
  },
  {
    profile_section_id: 6887,
    name: "PIPBN622X65",
    type: "Pipe",
    designation: "PIPBN622X65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1137,41",
    d_global: 622,
    i_global: "447108,8",
    t_global: 65,
    z_global: "14376,49",
  },
  {
    profile_section_id: 6888,
    name: "PIPBN622X70",
    type: "Pipe",
    designation: "PIPBN622X70",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1213,91",
    d_global: 622,
    i_global: "469789,8",
    t_global: 70,
    z_global: "15105,78",
  },
  {
    profile_section_id: 6889,
    name: "PIPBN622X75",
    type: "Pipe",
    designation: "PIPBN622X75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1288,84",
    d_global: 622,
    i_global: "491102,2",
    t_global: 75,
    z_global: "15791,07",
  },
  {
    profile_section_id: 6890,
    name: "PIPBN622X80",
    type: "Pipe",
    designation: "PIPBN622X80",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1362,19",
    d_global: 622,
    i_global: "511102,2",
    t_global: 80,
    z_global: "16434,15",
  },
  {
    profile_section_id: 6891,
    name: "PIPBN622X85",
    type: "Pipe",
    designation: "PIPBN622X85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1433,98",
    d_global: 622,
    i_global: "529844,8",
    t_global: 85,
    z_global: "17036,81",
  },
  {
    profile_section_id: 6892,
    name: "PIPBN622X90",
    type: "Pipe",
    designation: "PIPBN622X90",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1504,19",
    d_global: 622,
    i_global: "547383,9",
    t_global: 90,
    z_global: "17600,77",
  },
  {
    profile_section_id: 6893,
    name: "PIPBN622X100",
    type: "Pipe",
    designation: "PIPBN622X100",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1639,91",
    d_global: 622,
    i_global: "579060,9",
    t_global: 100,
    z_global: "18619,32",
  },
  {
    profile_section_id: 6894,
    name: "RD5.5",
    type: "Solid Round",
    designation: "RD5.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "0,24",
    i_global: 0,
    z_global: "0,02",
  },
  {
    profile_section_id: 6895,
    name: "RD6",
    type: "Solid Round",
    designation: "RD6",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "0,28",
    i_global: "0,01",
    z_global: "0,02",
  },
  {
    profile_section_id: 6896,
    name: "RD6.5",
    type: "Solid Round",
    designation: "RD6.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "0,33",
    i_global: "0,01",
    z_global: "0,03",
  },
  {
    profile_section_id: 6897,
    name: "RD7",
    type: "Solid Round",
    designation: "RD7",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "0,38",
    i_global: "0,01",
    z_global: "0,03",
  },
  {
    profile_section_id: 6898,
    name: "RD7.5",
    type: "Solid Round",
    designation: "RD7.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "0,44",
    i_global: "0,02",
    z_global: "0,04",
  },
  {
    profile_section_id: 6899,
    name: "RD8",
    type: "Solid Round",
    designation: "RD8",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "0,5",
    i_global: "0,02",
    z_global: "0,05",
  },
  {
    profile_section_id: 6900,
    name: "RD8.5",
    type: "Solid Round",
    designation: "RD8.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "0,57",
    i_global: "0,03",
    z_global: "0,06",
  },
  {
    profile_section_id: 6901,
    name: "RD9",
    type: "Solid Round",
    designation: "RD9",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "0,64",
    i_global: "0,03",
    z_global: "0,07",
  },
  {
    profile_section_id: 6902,
    name: "RD9.5",
    type: "Solid Round",
    designation: "RD9.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "0,71",
    i_global: "0,04",
    z_global: "0,08",
  },
  {
    profile_section_id: 6903,
    name: "RD10",
    type: "Solid Round",
    designation: "RD10",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "0,79",
    i_global: "0,05",
    z_global: "0,1",
  },
  {
    profile_section_id: 6904,
    name: "RD10.5",
    type: "Solid Round",
    designation: "RD10.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "0,87",
    i_global: "0,06",
    z_global: "0,11",
  },
  {
    profile_section_id: 6905,
    name: "RD11",
    type: "Solid Round",
    designation: "RD11",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "0,95",
    i_global: "0,07",
    z_global: "0,13",
  },
  {
    profile_section_id: 6906,
    name: "RD11.5",
    type: "Solid Round",
    designation: "RD11.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "1,04",
    i_global: "0,09",
    z_global: "0,15",
  },
  {
    profile_section_id: 6907,
    name: "RD12",
    type: "Solid Round",
    designation: "RD12",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "1,13",
    i_global: "0,1",
    z_global: "0,17",
  },
  {
    profile_section_id: 6908,
    name: "RD12.5",
    type: "Solid Round",
    designation: "RD12.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "1,23",
    i_global: "0,12",
    z_global: "0,19",
  },
  {
    profile_section_id: 6909,
    name: "RD13",
    type: "Solid Round",
    designation: "RD13",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "1,33",
    i_global: "0,14",
    z_global: "0,22",
  },
  {
    profile_section_id: 6910,
    name: "RD13.4",
    type: "Solid Round",
    designation: "RD13.4",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "1,41",
    i_global: "0,16",
    z_global: "0,24",
  },
  {
    profile_section_id: 6911,
    name: "RD13.75",
    type: "Solid Round",
    designation: "RD13.75",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "1,48",
    i_global: "0,18",
    z_global: "0,26",
  },
  {
    profile_section_id: 6912,
    name: "RD14",
    type: "Solid Round",
    designation: "RD14",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "1,54",
    i_global: "0,19",
    z_global: "0,27",
  },
  {
    profile_section_id: 6913,
    name: "RD15",
    type: "Solid Round",
    designation: "RD15",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "1,77",
    i_global: "0,25",
    z_global: "0,33",
  },
  {
    profile_section_id: 6914,
    name: "RD15.5",
    type: "Solid Round",
    designation: "RD15.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "1,89",
    i_global: "0,28",
    z_global: "0,37",
  },
  {
    profile_section_id: 6915,
    name: "RD15.75",
    type: "Solid Round",
    designation: "RD15.75",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "1,95",
    i_global: "0,3",
    z_global: "0,38",
  },
  {
    profile_section_id: 6916,
    name: "RD16",
    type: "Solid Round",
    designation: "RD16",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,01",
    i_global: "0,32",
    z_global: "0,4",
  },
  {
    profile_section_id: 6917,
    name: "RD16.2",
    type: "Solid Round",
    designation: "RD16.2",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,06",
    i_global: "0,34",
    z_global: "0,42",
  },
  {
    profile_section_id: 6918,
    name: "RD16.5",
    type: "Solid Round",
    designation: "RD16.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,14",
    i_global: "0,36",
    z_global: "0,44",
  },
  {
    profile_section_id: 6919,
    name: "RD17",
    type: "Solid Round",
    designation: "RD17",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,27",
    i_global: "0,41",
    z_global: "0,48",
  },
  {
    profile_section_id: 6920,
    name: "RD17.25",
    type: "Solid Round",
    designation: "RD17.25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,34",
    i_global: "0,43",
    z_global: "0,5",
  },
  {
    profile_section_id: 6921,
    name: "RD17.5",
    type: "Solid Round",
    designation: "RD17.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,41",
    i_global: "0,46",
    z_global: "0,53",
  },
  {
    profile_section_id: 6922,
    name: "RD17.7",
    type: "Solid Round",
    designation: "RD17.7",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,46",
    i_global: "0,48",
    z_global: "0,54",
  },
  {
    profile_section_id: 6923,
    name: "RD17.9",
    type: "Solid Round",
    designation: "RD17.9",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,52",
    i_global: "0,5",
    z_global: "0,56",
  },
  {
    profile_section_id: 6924,
    name: "RD18",
    type: "Solid Round",
    designation: "RD18",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,54",
    i_global: "0,52",
    z_global: "0,57",
  },
  {
    profile_section_id: 6925,
    name: "RD18.25",
    type: "Solid Round",
    designation: "RD18.25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,62",
    i_global: "0,54",
    z_global: "0,6",
  },
  {
    profile_section_id: 6926,
    name: "RD18.4",
    type: "Solid Round",
    designation: "RD18.4",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,66",
    i_global: "0,56",
    z_global: "0,61",
  },
  {
    profile_section_id: 6927,
    name: "RD18.5",
    type: "Solid Round",
    designation: "RD18.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,69",
    i_global: "0,57",
    z_global: "0,62",
  },
  {
    profile_section_id: 6928,
    name: "RD18.7",
    type: "Solid Round",
    designation: "RD18.7",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,75",
    i_global: "0,6",
    z_global: "0,64",
  },
  {
    profile_section_id: 6929,
    name: "RD19",
    type: "Solid Round",
    designation: "RD19",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,84",
    i_global: "0,64",
    z_global: "0,67",
  },
  {
    profile_section_id: 6930,
    name: "RD19.25",
    type: "Solid Round",
    designation: "RD19.25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,91",
    i_global: "0,67",
    z_global: "0,7",
  },
  {
    profile_section_id: 6931,
    name: "RD19.5",
    type: "Solid Round",
    designation: "RD19.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "2,99",
    i_global: "0,71",
    z_global: "0,73",
  },
  {
    profile_section_id: 6932,
    name: "RD19.7",
    type: "Solid Round",
    designation: "RD19.7",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,05",
    i_global: "0,74",
    z_global: "0,75",
  },
  {
    profile_section_id: 6933,
    name: "RD20",
    type: "Solid Round",
    designation: "RD20",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,14",
    i_global: "0,79",
    z_global: "0,79",
  },
  {
    profile_section_id: 6934,
    name: "RD20.25",
    type: "Solid Round",
    designation: "RD20.25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,22",
    i_global: "0,83",
    z_global: "0,82",
  },
  {
    profile_section_id: 6935,
    name: "RD20.5",
    type: "Solid Round",
    designation: "RD20.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,3",
    i_global: "0,87",
    z_global: "0,85",
  },
  {
    profile_section_id: 6936,
    name: "RD20.75",
    type: "Solid Round",
    designation: "RD20.75",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,38",
    i_global: "0,91",
    z_global: "0,88",
  },
  {
    profile_section_id: 6937,
    name: "RD21",
    type: "Solid Round",
    designation: "RD21",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,46",
    i_global: "0,95",
    z_global: "0,91",
  },
  {
    profile_section_id: 6938,
    name: "RD21.25",
    type: "Solid Round",
    designation: "RD21.25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,55",
    i_global: 1,
    z_global: "0,94",
  },
  {
    profile_section_id: 6939,
    name: "RD21.5",
    type: "Solid Round",
    designation: "RD21.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,63",
    i_global: "1,05",
    z_global: "0,98",
  },
  {
    profile_section_id: 6940,
    name: "RD21.7",
    type: "Solid Round",
    designation: "RD21.7",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,7",
    i_global: "1,09",
    z_global: 1,
  },
  {
    profile_section_id: 6941,
    name: "RD21.85",
    type: "Solid Round",
    designation: "RD21.85",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,75",
    i_global: "1,12",
    z_global: "1,02",
  },
  {
    profile_section_id: 6942,
    name: "RD22",
    type: "Solid Round",
    designation: "RD22",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,8",
    i_global: "1,15",
    z_global: "1,05",
  },
  {
    profile_section_id: 6943,
    name: "RD22.25",
    type: "Solid Round",
    designation: "RD22.25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,89",
    i_global: "1,2",
    z_global: "1,08",
  },
  {
    profile_section_id: 6944,
    name: "RD22.5",
    type: "Solid Round",
    designation: "RD22.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "3,98",
    i_global: "1,26",
    z_global: "1,12",
  },
  {
    profile_section_id: 6945,
    name: "RD22.75",
    type: "Solid Round",
    designation: "RD22.75",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "4,06",
    i_global: "1,31",
    z_global: "1,16",
  },
  {
    profile_section_id: 6946,
    name: "RD23",
    type: "Solid Round",
    designation: "RD23",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "4,15",
    i_global: "1,37",
    z_global: "1,19",
  },
  {
    profile_section_id: 6947,
    name: "RD23.25",
    type: "Solid Round",
    designation: "RD23.25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "4,25",
    i_global: "1,43",
    z_global: "1,23",
  },
  {
    profile_section_id: 6948,
    name: "RD23.5",
    type: "Solid Round",
    designation: "RD23.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "4,34",
    i_global: "1,5",
    z_global: "1,27",
  },
  {
    profile_section_id: 6949,
    name: "RD23.65",
    type: "Solid Round",
    designation: "RD23.65",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "4,39",
    i_global: "1,54",
    z_global: "1,3",
  },
  {
    profile_section_id: 6950,
    name: "RD23.75",
    type: "Solid Round",
    designation: "RD23.75",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "4,43",
    i_global: "1,56",
    z_global: "1,32",
  },
  {
    profile_section_id: 6951,
    name: "RD24",
    type: "Solid Round",
    designation: "RD24",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "4,52",
    i_global: "1,63",
    z_global: "1,36",
  },
  {
    profile_section_id: 6952,
    name: "RD24.25",
    type: "Solid Round",
    designation: "RD24.25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "4,62",
    i_global: "1,7",
    z_global: "1,4",
  },
  {
    profile_section_id: 6953,
    name: "RD24.5",
    type: "Solid Round",
    designation: "RD24.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "4,71",
    i_global: "1,77",
    z_global: "1,44",
  },
  {
    profile_section_id: 6954,
    name: "RD24.75",
    type: "Solid Round",
    designation: "RD24.75",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "4,81",
    i_global: "1,84",
    z_global: "1,49",
  },
  {
    profile_section_id: 6955,
    name: "RD25",
    type: "Solid Round",
    designation: "RD25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "4,91",
    i_global: "1,92",
    z_global: "1,53",
  },
  {
    profile_section_id: 6956,
    name: "RD25.5",
    type: "Solid Round",
    designation: "RD25.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "5,11",
    i_global: "2,08",
    z_global: "1,63",
  },
  {
    profile_section_id: 6957,
    name: "RD25.65",
    type: "Solid Round",
    designation: "RD25.65",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "5,17",
    i_global: "2,12",
    z_global: "1,66",
  },
  {
    profile_section_id: 6958,
    name: "RD26",
    type: "Solid Round",
    designation: "RD26",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "5,31",
    i_global: "2,24",
    z_global: "1,73",
  },
  {
    profile_section_id: 6959,
    name: "RD26.25",
    type: "Solid Round",
    designation: "RD26.25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "5,41",
    i_global: "2,33",
    z_global: "1,78",
  },
  {
    profile_section_id: 6960,
    name: "RD26.5",
    type: "Solid Round",
    designation: "RD26.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "5,52",
    i_global: "2,42",
    z_global: "1,83",
  },
  {
    profile_section_id: 6961,
    name: "RD26.65",
    type: "Solid Round",
    designation: "RD26.65",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "5,58",
    i_global: "2,48",
    z_global: "1,86",
  },
  {
    profile_section_id: 6962,
    name: "RD27",
    type: "Solid Round",
    designation: "RD27",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "5,73",
    i_global: "2,61",
    z_global: "1,93",
  },
  {
    profile_section_id: 6963,
    name: "RD27.25",
    type: "Solid Round",
    designation: "RD27.25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "5,83",
    i_global: "2,71",
    z_global: "1,99",
  },
  {
    profile_section_id: 6964,
    name: "RD27.75",
    type: "Solid Round",
    designation: "RD27.75",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "6,05",
    i_global: "2,91",
    z_global: "2,1",
  },
  {
    profile_section_id: 6965,
    name: "RD28",
    type: "Solid Round",
    designation: "RD28",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "6,16",
    i_global: "3,02",
    z_global: "2,16",
  },
  {
    profile_section_id: 6966,
    name: "RD28.25",
    type: "Solid Round",
    designation: "RD28.25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "6,27",
    i_global: "3,13",
    z_global: "2,21",
  },
  {
    profile_section_id: 6967,
    name: "RD29",
    type: "Solid Round",
    designation: "RD29",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "6,61",
    i_global: "3,47",
    z_global: "2,39",
  },
  {
    profile_section_id: 6968,
    name: "RD29.25",
    type: "Solid Round",
    designation: "RD29.25",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "6,72",
    i_global: "3,59",
    z_global: "2,46",
  },
  {
    profile_section_id: 6969,
    name: "RD29.6",
    type: "Solid Round",
    designation: "RD29.6",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "6,88",
    i_global: "3,77",
    z_global: "2,55",
  },
  {
    profile_section_id: 6970,
    name: "RD29.7",
    type: "Solid Round",
    designation: "RD29.7",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "6,93",
    i_global: "3,82",
    z_global: "2,57",
  },
  {
    profile_section_id: 6971,
    name: "RD30",
    type: "Solid Round",
    designation: "RD30",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "7,07",
    i_global: "3,98",
    z_global: "2,65",
  },
  {
    profile_section_id: 6972,
    name: "RD31",
    type: "Solid Round",
    designation: "RD31",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "7,55",
    i_global: "4,53",
    z_global: "2,92",
  },
  {
    profile_section_id: 6973,
    name: "RD31.3",
    type: "Solid Round",
    designation: "RD31.3",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "7,69",
    i_global: "4,71",
    z_global: "3,01",
  },
  {
    profile_section_id: 6974,
    name: "RD32",
    type: "Solid Round",
    designation: "RD32",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "8,04",
    i_global: "5,15",
    z_global: "3,22",
  },
  {
    profile_section_id: 6975,
    name: "RD32.5",
    type: "Solid Round",
    designation: "RD32.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "8,3",
    i_global: "5,48",
    z_global: "3,37",
  },
  {
    profile_section_id: 6976,
    name: "RD32.7",
    type: "Solid Round",
    designation: "RD32.7",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "8,4",
    i_global: "5,61",
    z_global: "3,43",
  },
  {
    profile_section_id: 6977,
    name: "RD33",
    type: "Solid Round",
    designation: "RD33",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "8,55",
    i_global: "5,82",
    z_global: "3,53",
  },
  {
    profile_section_id: 6978,
    name: "RD34",
    type: "Solid Round",
    designation: "RD34",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "9,08",
    i_global: "6,56",
    z_global: "3,86",
  },
  {
    profile_section_id: 6979,
    name: "RD34.5",
    type: "Solid Round",
    designation: "RD34.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "9,35",
    i_global: "6,95",
    z_global: "4,03",
  },
  {
    profile_section_id: 6980,
    name: "RD35",
    type: "Solid Round",
    designation: "RD35",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "9,62",
    i_global: "7,37",
    z_global: "4,21",
  },
  {
    profile_section_id: 6981,
    name: "RD35.5",
    type: "Solid Round",
    designation: "RD35.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "9,9",
    i_global: "7,8",
    z_global: "4,39",
  },
  {
    profile_section_id: 6982,
    name: "RD36",
    type: "Solid Round",
    designation: "RD36",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "10,2",
    i_global: "8,24",
    z_global: "4,58",
  },
  {
    profile_section_id: 6983,
    name: "RD37",
    type: "Solid Round",
    designation: "RD37",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "10,8",
    i_global: "9,2",
    z_global: "4,97",
  },
  {
    profile_section_id: 6984,
    name: "RD37.7",
    type: "Solid Round",
    designation: "RD37.7",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "11,2",
    i_global: "9,92",
    z_global: "5,26",
  },
  {
    profile_section_id: 6985,
    name: "RD38",
    type: "Solid Round",
    designation: "RD38",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "11,3",
    i_global: "10,2",
    z_global: "5,39",
  },
  {
    profile_section_id: 6986,
    name: "RD38.5",
    type: "Solid Round",
    designation: "RD38.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "11,6",
    i_global: "10,8",
    z_global: "5,6",
  },
  {
    profile_section_id: 6987,
    name: "RD39",
    type: "Solid Round",
    designation: "RD39",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "11,9",
    i_global: "11,4",
    z_global: "5,82",
  },
  {
    profile_section_id: 6988,
    name: "RD40",
    type: "Solid Round",
    designation: "RD40",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "12,6",
    i_global: "12,6",
    z_global: "6,28",
  },
  {
    profile_section_id: 6989,
    name: "RD41",
    type: "Solid Round",
    designation: "RD41",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "13,2",
    i_global: "13,9",
    z_global: "6,77",
  },
  {
    profile_section_id: 6990,
    name: "RD41.5",
    type: "Solid Round",
    designation: "RD41.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "13,5",
    i_global: "14,6",
    z_global: "7,02",
  },
  {
    profile_section_id: 6991,
    name: "RD42",
    type: "Solid Round",
    designation: "RD42",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "13,9",
    i_global: "15,3",
    z_global: "7,27",
  },
  {
    profile_section_id: 6992,
    name: "RD43",
    type: "Solid Round",
    designation: "RD43",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "14,5",
    i_global: "16,8",
    z_global: "7,81",
  },
  {
    profile_section_id: 6993,
    name: "RD44",
    type: "Solid Round",
    designation: "RD44",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "15,2",
    i_global: "18,4",
    z_global: "8,36",
  },
  {
    profile_section_id: 6994,
    name: "RD44.5",
    type: "Solid Round",
    designation: "RD44.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "15,6",
    i_global: "19,2",
    z_global: "8,65",
  },
  {
    profile_section_id: 6995,
    name: "RD45",
    type: "Solid Round",
    designation: "RD45",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "15,9",
    i_global: "20,1",
    z_global: "8,95",
  },
  {
    profile_section_id: 6996,
    name: "RD46",
    type: "Solid Round",
    designation: "RD46",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "16,6",
    i_global: 22,
    z_global: "9,56",
  },
  {
    profile_section_id: 6997,
    name: "RD47",
    type: "Solid Round",
    designation: "RD47",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "17,3",
    i_global: 24,
    z_global: "10,2",
  },
  {
    profile_section_id: 6998,
    name: "RD47.5",
    type: "Solid Round",
    designation: "RD47.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "17,7",
    i_global: 25,
    z_global: "10,5",
  },
  {
    profile_section_id: 6999,
    name: "RD48",
    type: "Solid Round",
    designation: "RD48",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "18,1",
    i_global: "26,1",
    z_global: "10,9",
  },
  {
    profile_section_id: 7000,
    name: "RD49",
    type: "Solid Round",
    designation: "RD49",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "18,9",
    i_global: "28,3",
    z_global: "11,6",
  },
  {
    profile_section_id: 7001,
    name: "RD50",
    type: "Solid Round",
    designation: "RD50",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "19,6",
    i_global: "30,7",
    z_global: "12,3",
  },
  {
    profile_section_id: 7002,
    name: "RD51",
    type: "Solid Round",
    designation: "RD51",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "20,4",
    i_global: "33,2",
    z_global: 13,
  },
  {
    profile_section_id: 7003,
    name: "RD51.5",
    type: "Solid Round",
    designation: "RD51.5",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "20,8",
    i_global: "34,5",
    z_global: "13,4",
  },
  {
    profile_section_id: 7004,
    name: "RD52",
    type: "Solid Round",
    designation: "RD52",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "21,2",
    i_global: "35,9",
    z_global: "13,8",
  },
  {
    profile_section_id: 7005,
    name: "RD53",
    type: "Solid Round",
    designation: "RD53",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "22,1",
    i_global: "38,7",
    z_global: "14,6",
  },
  {
    profile_section_id: 7006,
    name: "RD54",
    type: "Solid Round",
    designation: "RD54",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "22,9",
    i_global: "41,7",
    z_global: "15,5",
  },
  {
    profile_section_id: 7007,
    name: "RD55",
    type: "Solid Round",
    designation: "RD55",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "23,8",
    i_global: "44,9",
    z_global: "16,3",
  },
  {
    profile_section_id: 7008,
    name: "RD56",
    type: "Solid Round",
    designation: "RD56",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "24,6",
    i_global: "48,3",
    z_global: "17,2",
  },
  {
    profile_section_id: 7009,
    name: "RD57",
    type: "Solid Round",
    designation: "RD57",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "25,5",
    i_global: "51,8",
    z_global: "18,2",
  },
  {
    profile_section_id: 7010,
    name: "RD58",
    type: "Solid Round",
    designation: "RD58",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "26,4",
    i_global: "55,5",
    z_global: "19,2",
  },
  {
    profile_section_id: 7011,
    name: "RD60",
    type: "Solid Round",
    designation: "RD60",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "28,3",
    i_global: "63,6",
    z_global: "21,2",
  },
  {
    profile_section_id: 7012,
    name: "RD61",
    type: "Solid Round",
    designation: "RD61",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "29,2",
    i_global: 68,
    z_global: "22,3",
  },
  {
    profile_section_id: 7013,
    name: "RD63",
    type: "Solid Round",
    designation: "RD63",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "31,2",
    i_global: "77,3",
    z_global: "24,5",
  },
  {
    profile_section_id: 7014,
    name: "RD65",
    type: "Solid Round",
    designation: "RD65",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "33,2",
    i_global: "87,6",
    z_global: 27,
  },
  {
    profile_section_id: 7015,
    name: "RD66",
    type: "Solid Round",
    designation: "RD66",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "34,2",
    i_global: "93,1",
    z_global: "28,2",
  },
  {
    profile_section_id: 7016,
    name: "RD68",
    type: "Solid Round",
    designation: "RD68",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "36,3",
    i_global: 105,
    z_global: "30,9",
  },
  {
    profile_section_id: 7017,
    name: "RD70",
    type: "Solid Round",
    designation: "RD70",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "38,5",
    i_global: 118,
    z_global: "33,7",
  },
  {
    profile_section_id: 7018,
    name: "RD73",
    type: "Solid Round",
    designation: "RD73",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "41,9",
    i_global: 139,
    z_global: "38,2",
  },
  {
    profile_section_id: 7019,
    name: "RD75",
    type: "Solid Round",
    designation: "RD75",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "44,2",
    i_global: 155,
    z_global: "41,4",
  },
  {
    profile_section_id: 7020,
    name: "RD78",
    type: "Solid Round",
    designation: "RD78",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "47,8",
    i_global: 182,
    z_global: "46,6",
  },
  {
    profile_section_id: 7021,
    name: "RD80",
    type: "Solid Round",
    designation: "RD80",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "50,3",
    i_global: 201,
    z_global: "50,3",
  },
  {
    profile_section_id: 7022,
    name: "RD83",
    type: "Solid Round",
    designation: "RD83",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "54,1",
    i_global: 233,
    z_global: "56,1",
  },
  {
    profile_section_id: 7023,
    name: "RD85",
    type: "Solid Round",
    designation: "RD85",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "56,7",
    i_global: 256,
    z_global: "60,3",
  },
  {
    profile_section_id: 7024,
    name: "RD90",
    type: "Solid Round",
    designation: "RD90",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "63,6",
    i_global: 322,
    z_global: "71,6",
  },
  {
    profile_section_id: 7025,
    name: "RD95",
    type: "Solid Round",
    designation: "RD95",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "70,9",
    i_global: 400,
    z_global: "84,2",
  },
  {
    profile_section_id: 7026,
    name: "RD100",
    type: "Solid Round",
    designation: "RD100",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "78,5",
    i_global: 491,
    z_global: "98,2",
  },
  {
    profile_section_id: 7027,
    name: "RD105",
    type: "Solid Round",
    designation: "RD105",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: "86,6",
    i_global: 597,
    z_global: 114,
  },
  {
    profile_section_id: 7028,
    name: "RD110",
    type: "Solid Round",
    designation: "RD110",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 95,
    i_global: 719,
    z_global: 131,
  },
  {
    profile_section_id: 7029,
    name: "RD115",
    type: "Solid Round",
    designation: "RD115",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 104,
    i_global: 859,
    z_global: 149,
  },
  {
    profile_section_id: 7030,
    name: "RD120",
    type: "Solid Round",
    designation: "RD120",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 113,
    i_global: 1020,
    z_global: 170,
  },
  {
    profile_section_id: 7031,
    name: "RD125",
    type: "Solid Round",
    designation: "RD125",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 123,
    i_global: 1198,
    z_global: 192,
  },
  {
    profile_section_id: 7032,
    name: "RD130",
    type: "Solid Round",
    designation: "RD130",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 133,
    i_global: 1402,
    z_global: 216,
  },
  {
    profile_section_id: 7033,
    name: "RD135",
    type: "Solid Round",
    designation: "RD135",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 143,
    i_global: 1630,
    z_global: 242,
  },
  {
    profile_section_id: 7034,
    name: "RD140",
    type: "Solid Round",
    designation: "RD140",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 154,
    i_global: 1886,
    z_global: 269,
  },
  {
    profile_section_id: 7035,
    name: "RD150",
    type: "Solid Round",
    designation: "RD150",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 177,
    i_global: 2485,
    z_global: 331,
  },
  {
    profile_section_id: 7036,
    name: "RD160",
    type: "Solid Round",
    designation: "RD160",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 201,
    i_global: 3217,
    z_global: 402,
  },
  {
    profile_section_id: 7037,
    name: "RD165",
    type: "Solid Round",
    designation: "RD165",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 214,
    i_global: 3638,
    z_global: 441,
  },
  {
    profile_section_id: 7038,
    name: "RD170",
    type: "Solid Round",
    designation: "RD170",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 227,
    i_global: 4100,
    z_global: 482,
  },
  {
    profile_section_id: 7039,
    name: "RD180",
    type: "Solid Round",
    designation: "RD180",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 254,
    i_global: 5153,
    z_global: 573,
  },
  {
    profile_section_id: 7040,
    name: "RD190",
    type: "Solid Round",
    designation: "RD190",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 284,
    i_global: 6397,
    z_global: 673,
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: null,
    bf: null,
    c: null,
    ct: null,
    d: null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: null,
    i: null,
    i_global: p.i_global ? fixValueToNumber(p.i_global, "float") : undefined,
    ix: null,
    iy: null,
    iz: null,
    k: null,
    k1: null,
    od: null,
    r1: null,
    r2: null,
    rz: null,
    t: null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: null,
    tw: null,
    z: null,
    z_global: p.z_global ? fixValueToNumber(p.z_global, "float") : undefined,
    zx: null,
    zy: null,
    zz: null,
    width: 0,
    width_global: 0,
    thickness: 0,
    thickness_global: 0,
    height: 0,
    height_global: 0,
  };
  return profile;
});
