import { fixValueToNumber } from "../../components/3d-models/utils";

export const pipingThreadedFlanges = [
  {
    piping_flange_id: 1,
    dn: 25,
    nps: 1,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE THREADED",
    class: 300,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    c_mini: "38,1",
    y: "47,8",
    r: "50,8",
    x: 54,
    tt: "6,4",
    qb: "35,8",
    qf: 33,
    f: "36,6",
    weight: 3,
  },
  {
    piping_flange_id: 2,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE THREADED",
    class: 300,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    c_mini: "38,1",
    y: "47,8",
    r: 73,
    x: 70,
    tt: "6,4",
    qb: "50,5",
    qf: 48,
    f: "37,3",
    weight: 5,
  },
  {
    piping_flange_id: 3,
    dn: 50,
    nps: 2,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE THREADED",
    class: 300,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    c_mini: "38,1",
    y: "49,3",
    r: "92,1",
    x: 84,
    tt: "6,4",
    qb: "63,5",
    qf: "59,9",
    f: "38,1",
    weight: "5,5",
  },
  {
    piping_flange_id: 4,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE THREADED",
    class: 300,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    c_mini: "38,1",
    y: "50,8",
    r: "104,8",
    x: 100,
    tt: "6,4",
    qb: "76,2",
    qf: "72,1",
    f: "44,5",
    weight: "6,2",
  },
  {
    piping_flange_id: 5,
    dn: 80,
    nps: 3,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE THREADED",
    class: 300,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    c_mini: "38,1",
    y: "52,3",
    r: 127,
    x: 117,
    tt: "9,5",
    qb: "92,2",
    qf: "87,9",
    f: 46,
    weight: 8,
  },
  {
    piping_flange_id: 6,
    dn: 100,
    nps: 4,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE THREADED",
    class: 300,
    o: 254,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: 200,
    c_mini: "38,1",
    y: "53,8",
    r: "157,2",
    x: 146,
    tt: "12,7",
    qb: "117,6",
    qf: 113,
    f: "47,8",
    weight: "12,1",
  },
  {
    piping_flange_id: 7,
    dn: 150,
    nps: 6,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE THREADED",
    class: 300,
    o: 318,
    dr_no: 12,
    dr_d: "22,2",
    dr_g: "269,9",
    c_mini: "38,1",
    y: "53,8",
    r: "215,9",
    x: 206,
    tt: "12,7",
    qb: "171,5",
    qf: "166,9",
    f: "47,8",
    weight: "18,5",
  },
  {
    piping_flange_id: 8,
    dn: 200,
    nps: 8,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE THREADED",
    class: 300,
    o: 381,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: "330,2",
    c_mini: "41,1",
    y: 62,
    r: "269,9",
    x: 260,
    tt: "12,7",
    qb: "222,3",
    qf: "217,2",
    f: "55,6",
    weight: 26,
  },
].map((item) => ({
  ...item,
  nps: item.nps + "",
  o: fixValueToNumber(item.o, "float"),
  dr_d: fixValueToNumber(item.dr_d, "float"),
  dr_g: fixValueToNumber(item.dr_g, "float"),
  r: fixValueToNumber(item.r, "float"),
  x: fixValueToNumber(item.x, "float"),
  y: fixValueToNumber(item.y, "float"),
  tt: fixValueToNumber(item.tt, "float"),
  qb: fixValueToNumber(item.qb, "float"),
  qf: fixValueToNumber(item.qf, "float"),
  f: fixValueToNumber(item.f, "float"),
  weight: fixValueToNumber(item.weight, "float"),
}));
