import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesRest = [
  {
    profile_section_id: 4420,
    name: "TCE33.4x3.2",
    type: "Tube",
    designation: "TCE33.4x3.2",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "3,04",
    d_global: "33,4",
    i_global: "3,5",
    t_global: "3,2",
    z: "2,93",
  },
  {
    profile_section_id: 4421,
    name: "TCE33.4x3.6",
    type: "Tube",
    designation: "TCE33.4x3.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "3,37",
    d_global: "33,4",
    i_global: "3,8",
    t_global: "3,6",
    z: "3,21",
  },
  {
    profile_section_id: 4422,
    name: "TCE38.1x3.2",
    type: "Tube",
    designation: "TCE38.1x3.2",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "3,51",
    d_global: "38,1",
    i_global: "5,39",
    t_global: "3,2",
    z: "3,91",
  },
  {
    profile_section_id: 4423,
    name: "TCE38.1x3.6",
    type: "Tube",
    designation: "TCE38.1x3.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "3,9",
    d_global: "38,1",
    i_global: "5,87",
    t_global: "3,6",
    z: "4,3",
  },
  {
    profile_section_id: 4424,
    name: "TCE38.1x4",
    type: "Tube",
    designation: "TCE38.1x4",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "4,29",
    d_global: "38,1",
    i_global: "6,31",
    t_global: 4,
    z: "4,67",
  },
  {
    profile_section_id: 4425,
    name: "TCE42.2x3.6",
    type: "Tube",
    designation: "TCE42.2x3.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "4,37",
    d_global: "42,2",
    i_global: "8,2",
    t_global: "3,6",
    z: "5,38",
  },
  {
    profile_section_id: 4426,
    name: "TCE42.2x4",
    type: "Tube",
    designation: "TCE42.2x4",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "4,8",
    d_global: "42,2",
    i_global: "8,85",
    t_global: 4,
    z: "5,86",
  },
  {
    profile_section_id: 4427,
    name: "TCE42.2x4.5",
    type: "Tube",
    designation: "TCE42.2x4.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "5,33",
    d_global: "42,2",
    i_global: "9,6",
    t_global: "4,5",
    z: "6,43",
  },
  {
    profile_section_id: 4428,
    name: "TCE42.2x5",
    type: "Tube",
    designation: "TCE42.2x5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "5,84",
    d_global: "42,2",
    i_global: "10,3",
    t_global: 5,
    z: "6,96",
  },
  {
    profile_section_id: 4429,
    name: "TCE48.3x3.6",
    type: "Tube",
    designation: "TCE48.3x3.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "5,06",
    d_global: "48,3",
    i_global: "12,7",
    t_global: "3,6",
    z: "7,21",
  },
  {
    profile_section_id: 4430,
    name: "TCE48.3x4",
    type: "Tube",
    designation: "TCE48.3x4",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "5,57",
    d_global: "48,3",
    i_global: "13,8",
    t_global: 4,
    z: "7,87",
  },
  {
    profile_section_id: 4431,
    name: "TCE48.3x4.5",
    type: "Tube",
    designation: "TCE48.3x4.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "6,19",
    d_global: "48,3",
    i_global: 15,
    t_global: "4,5",
    z: "8,66",
  },
  {
    profile_section_id: 4432,
    name: "TCE48.3x5",
    type: "Tube",
    designation: "TCE48.3x5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "6,8",
    d_global: "48,3",
    i_global: "16,2",
    t_global: 5,
    z: "9,42",
  },
  {
    profile_section_id: 4433,
    name: "TCE48.3x5.6",
    type: "Tube",
    designation: "TCE48.3x5.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "7,51",
    d_global: "48,3",
    i_global: "17,4",
    t_global: "5,6",
    z: "10,3",
  },
  {
    profile_section_id: 4434,
    name: "TCE48.3x6.3",
    type: "Tube",
    designation: "TCE48.3x6.3",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "8,31",
    d_global: "48,3",
    i_global: "18,7",
    t_global: "6,3",
    z: "11,2",
  },
  {
    profile_section_id: 4435,
    name: "TCE60.3x3.6",
    type: "Tube",
    designation: "TCE60.3x3.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "6,41",
    d_global: "60,3",
    i_global: "25,9",
    t_global: "3,6",
    z: "11,6",
  },
  {
    profile_section_id: 4436,
    name: "TCE60.3x4",
    type: "Tube",
    designation: "TCE60.3x4",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "7,07",
    d_global: "60,3",
    i_global: "28,2",
    t_global: 4,
    z: "12,7",
  },
  {
    profile_section_id: 4437,
    name: "TCE60.3x4.5",
    type: "Tube",
    designation: "TCE60.3x4.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "7,89",
    d_global: "60,3",
    i_global: "30,9",
    t_global: "4,5",
    z: 14,
  },
  {
    profile_section_id: 4438,
    name: "TCE60.3x5",
    type: "Tube",
    designation: "TCE60.3x5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "8,69",
    d_global: "60,3",
    i_global: "33,5",
    t_global: 5,
    z: "15,3",
  },
  {
    profile_section_id: 4439,
    name: "TCE60.3x5.6",
    type: "Tube",
    designation: "TCE60.3x5.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "9,62",
    d_global: "60,3",
    i_global: "36,4",
    t_global: "5,6",
    z: "16,8",
  },
  {
    profile_section_id: 4440,
    name: "TCE60.3x6.3",
    type: "Tube",
    designation: "TCE60.3x6.3",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "10,7",
    d_global: "60,3",
    i_global: "39,5",
    t_global: "6,3",
    z: "18,5",
  },
  {
    profile_section_id: 4441,
    name: "TCE60.3x7.1",
    type: "Tube",
    designation: "TCE60.3x7.1",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "11,9",
    d_global: "60,3",
    i_global: "42,7",
    t_global: "7,1",
    z: "20,2",
  },
  {
    profile_section_id: 4442,
    name: "TCE60.3x8",
    type: "Tube",
    designation: "TCE60.3x8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "13,1",
    d_global: "60,3",
    i_global: 46,
    t_global: 8,
    z: "22,1",
  },
  {
    profile_section_id: 4443,
    name: "TCE60.3x8.8",
    type: "Tube",
    designation: "TCE60.3x8.8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "14,2",
    d_global: "60,3",
    i_global: "48,6",
    t_global: "8,8",
    z: "23,6",
  },
  {
    profile_section_id: 4444,
    name: "TCE73x3.6",
    type: "Tube",
    designation: "TCE73x3.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "7,85",
    d_global: 73,
    i_global: "47,4",
    t_global: "3,6",
    z: "17,4",
  },
  {
    profile_section_id: 4445,
    name: "TCE73x4",
    type: "Tube",
    designation: "TCE73x4",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "8,67",
    d_global: 73,
    i_global: "51,8",
    t_global: 4,
    z: "19,1",
  },
  {
    profile_section_id: 4446,
    name: "TCE73x4.5",
    type: "Tube",
    designation: "TCE73x4.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "9,68",
    d_global: 73,
    i_global: 57,
    t_global: "4,5",
    z: "21,1",
  },
  {
    profile_section_id: 4447,
    name: "TCE73x5",
    type: "Tube",
    designation: "TCE73x5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "10,7",
    d_global: 73,
    i_global: "62,1",
    t_global: 5,
    z: "23,2",
  },
  {
    profile_section_id: 4448,
    name: "TCE73x5.6",
    type: "Tube",
    designation: "TCE73x5.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "11,9",
    d_global: 73,
    i_global: "67,8",
    t_global: "5,6",
    z: "25,5",
  },
  {
    profile_section_id: 4449,
    name: "TCE73x6.3",
    type: "Tube",
    designation: "TCE73x6.3",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "13,2",
    d_global: 73,
    i_global: "74,1",
    t_global: "6,3",
    z: "28,1",
  },
  {
    profile_section_id: 4450,
    name: "TCE73x7.1",
    type: "Tube",
    designation: "TCE73x7.1",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "14,7",
    d_global: 73,
    i_global: "80,7",
    t_global: "7,1",
    z: 31,
  },
  {
    profile_section_id: 4451,
    name: "TCE73x8",
    type: "Tube",
    designation: "TCE73x8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "16,3",
    d_global: 73,
    i_global: "87,6",
    t_global: 8,
    z: 34,
  },
  {
    profile_section_id: 4452,
    name: "TCE73x8.8",
    type: "Tube",
    designation: "TCE73x8.8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "17,7",
    d_global: 73,
    i_global: "93,2",
    t_global: "8,8",
    z: "36,5",
  },
  {
    profile_section_id: 4453,
    name: "TCE73x10",
    type: "Tube",
    designation: "TCE73x10",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "19,8",
    d_global: 73,
    i_global: 101,
    t_global: 10,
    z: 40,
  },
  {
    profile_section_id: 4454,
    name: "TCE73x11",
    type: "Tube",
    designation: "TCE73x11",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "21,4",
    d_global: 73,
    i_global: 106,
    t_global: 11,
    z: "42,7",
  },
  {
    profile_section_id: 4455,
    name: "TCE88.9x3.6",
    type: "Tube",
    designation: "TCE88.9x3.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "9,65",
    d_global: "88,9",
    i_global: "87,9",
    t_global: "3,6",
    z: "26,2",
  },
  {
    profile_section_id: 4456,
    name: "TCE88.9x4",
    type: "Tube",
    designation: "TCE88.9x4",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "10,7",
    d_global: "88,9",
    i_global: "96,3",
    t_global: 4,
    z: "28,9",
  },
  {
    profile_section_id: 4457,
    name: "TCE88.9x4.5",
    type: "Tube",
    designation: "TCE88.9x4.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "11,9",
    d_global: "88,9",
    i_global: 107,
    t_global: "4,5",
    z: "32,1",
  },
  {
    profile_section_id: 4458,
    name: "TCE88.9x5",
    type: "Tube",
    designation: "TCE88.9x5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "13,2",
    d_global: "88,9",
    i_global: 116,
    t_global: 5,
    z: "35,2",
  },
  {
    profile_section_id: 4459,
    name: "TCE88.9x5.6",
    type: "Tube",
    designation: "TCE88.9x5.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "14,7",
    d_global: "88,9",
    i_global: 128,
    t_global: "5,6",
    z: "38,9",
  },
  {
    profile_section_id: 4460,
    name: "TCE88.9x6.3",
    type: "Tube",
    designation: "TCE88.9x6.3",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "16,3",
    d_global: "88,9",
    i_global: 140,
    t_global: "6,3",
    z: "43,1",
  },
  {
    profile_section_id: 4461,
    name: "TCE88.9x7.1",
    type: "Tube",
    designation: "TCE88.9x7.1",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "18,2",
    d_global: "88,9",
    i_global: 154,
    t_global: "7,1",
    z: "47,6",
  },
  {
    profile_section_id: 4462,
    name: "TCE88.9x8",
    type: "Tube",
    designation: "TCE88.9x8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "20,3",
    d_global: "88,9",
    i_global: 168,
    t_global: 8,
    z: "52,5",
  },
  {
    profile_section_id: 4463,
    name: "TCE88.9x8.8",
    type: "Tube",
    designation: "TCE88.9x8.8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "22,1",
    d_global: "88,9",
    i_global: 180,
    t_global: "8,8",
    z: "56,7",
  },
  {
    profile_section_id: 4464,
    name: "TCE88.9x10",
    type: "Tube",
    designation: "TCE88.9x10",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "24,8",
    d_global: "88,9",
    i_global: 196,
    t_global: 10,
    z: "62,6",
  },
  {
    profile_section_id: 4465,
    name: "TCE88.9x11",
    type: "Tube",
    designation: "TCE88.9x11",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "26,9",
    d_global: "88,9",
    i_global: 208,
    t_global: 11,
    z: "67,2",
  },
  {
    profile_section_id: 4466,
    name: "TCE88.9x12.5",
    type: "Tube",
    designation: "TCE88.9x12.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 30,
    d_global: "88,9",
    i_global: 225,
    t_global: "12,5",
    z: "73,6",
  },
  {
    profile_section_id: 4467,
    name: "TCE101.6x4",
    type: "Tube",
    designation: "TCE101.6x4",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "12,3",
    d_global: "101,6",
    i_global: 146,
    t_global: 4,
    z: "38,1",
  },
  {
    profile_section_id: 4468,
    name: "TCE101.6x4.5",
    type: "Tube",
    designation: "TCE101.6x4.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "13,7",
    d_global: "101,6",
    i_global: 162,
    t_global: "4,5",
    z: "42,5",
  },
  {
    profile_section_id: 4469,
    name: "TCE101.6x5",
    type: "Tube",
    designation: "TCE101.6x5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "15,2",
    d_global: "101,6",
    i_global: 177,
    t_global: 5,
    z: "46,7",
  },
  {
    profile_section_id: 4470,
    name: "TCE101.6x5.6",
    type: "Tube",
    designation: "TCE101.6x5.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "16,9",
    d_global: "101,6",
    i_global: 195,
    t_global: "5,6",
    z: "51,7",
  },
  {
    profile_section_id: 4471,
    name: "TCE101.6x6.3",
    type: "Tube",
    designation: "TCE101.6x6.3",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "18,9",
    d_global: "101,6",
    i_global: 215,
    t_global: "6,3",
    z: "57,3",
  },
  {
    profile_section_id: 4472,
    name: "TCE101.6x7.1",
    type: "Tube",
    designation: "TCE101.6x7.1",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "21,1",
    d_global: "101,6",
    i_global: 237,
    t_global: "7,1",
    z: "63,5",
  },
  {
    profile_section_id: 4473,
    name: "TCE101.6x8",
    type: "Tube",
    designation: "TCE101.6x8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "23,5",
    d_global: "101,6",
    i_global: 260,
    t_global: 8,
    z: "70,3",
  },
  {
    profile_section_id: 4474,
    name: "TCE101.6x8.8",
    type: "Tube",
    designation: "TCE101.6x8.8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "25,7",
    d_global: "101,6",
    i_global: 279,
    t_global: "8,8",
    z: 76,
  },
  {
    profile_section_id: 4475,
    name: "TCE101.6x10",
    type: "Tube",
    designation: "TCE101.6x10",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "28,8",
    d_global: "101,6",
    i_global: 305,
    t_global: 10,
    z: "84,2",
  },
  {
    profile_section_id: 4476,
    name: "TCE101.6x11",
    type: "Tube",
    designation: "TCE101.6x11",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "31,3",
    d_global: "101,6",
    i_global: 326,
    t_global: 11,
    z: "90,7",
  },
  {
    profile_section_id: 4477,
    name: "TCE101.6x12.5",
    type: "Tube",
    designation: "TCE101.6x12.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 35,
    d_global: "101,6",
    i_global: 354,
    t_global: "12,5",
    z: "99,9",
  },
  {
    profile_section_id: 4478,
    name: "TCE114.3x4",
    type: "Tube",
    designation: "TCE114.3x4",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "13,9",
    d_global: "114,3",
    i_global: 211,
    t_global: 4,
    z: "48,7",
  },
  {
    profile_section_id: 4479,
    name: "TCE114.3x4.5",
    type: "Tube",
    designation: "TCE114.3x4.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "15,5",
    d_global: "114,3",
    i_global: 234,
    t_global: "4,5",
    z: "54,3",
  },
  {
    profile_section_id: 4480,
    name: "TCE114.3x5",
    type: "Tube",
    designation: "TCE114.3x5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "17,2",
    d_global: "114,3",
    i_global: 257,
    t_global: 5,
    z: "59,8",
  },
  {
    profile_section_id: 4481,
    name: "TCE114.3x5.6",
    type: "Tube",
    designation: "TCE114.3x5.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "19,1",
    d_global: "114,3",
    i_global: 283,
    t_global: "5,6",
    z: "66,2",
  },
  {
    profile_section_id: 4482,
    name: "TCE114.3x6.3",
    type: "Tube",
    designation: "TCE114.3x6.3",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "21,4",
    d_global: "114,3",
    i_global: 313,
    t_global: "6,3",
    z: "73,6",
  },
  {
    profile_section_id: 4483,
    name: "TCE114.3x7.1",
    type: "Tube",
    designation: "TCE114.3x7.1",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "23,9",
    d_global: "114,3",
    i_global: 345,
    t_global: "7,1",
    z: "81,7",
  },
  {
    profile_section_id: 4484,
    name: "TCE114.3x8",
    type: "Tube",
    designation: "TCE114.3x8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "26,7",
    d_global: "114,3",
    i_global: 379,
    t_global: 8,
    z: "90,6",
  },
  {
    profile_section_id: 4485,
    name: "TCE114.3x8.8",
    type: "Tube",
    designation: "TCE114.3x8.8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "29,2",
    d_global: "114,3",
    i_global: 409,
    t_global: "8,8",
    z: "98,2",
  },
  {
    profile_section_id: 4486,
    name: "TCE114.3x10",
    type: "Tube",
    designation: "TCE114.3x10",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "32,8",
    d_global: "114,3",
    i_global: 450,
    t_global: 10,
    z: 109,
  },
  {
    profile_section_id: 4487,
    name: "TCE114.3x11",
    type: "Tube",
    designation: "TCE114.3x11",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "35,7",
    d_global: "114,3",
    i_global: 482,
    t_global: 11,
    z: 118,
  },
  {
    profile_section_id: 4488,
    name: "TCE114.3x12.5",
    type: "Tube",
    designation: "TCE114.3x12.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 40,
    d_global: "114,3",
    i_global: 526,
    t_global: "12,5",
    z: 130,
  },
  {
    profile_section_id: 4489,
    name: "TCE114.3x14.2",
    type: "Tube",
    designation: "TCE114.3x14.2",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "44,7",
    d_global: "114,3",
    i_global: 571,
    t_global: "14,2",
    z: 143,
  },
  {
    profile_section_id: 4490,
    name: "TCE141.3x5",
    type: "Tube",
    designation: "TCE141.3x5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "21,4",
    d_global: "141,3",
    i_global: 498,
    t_global: 5,
    z: "92,9",
  },
  {
    profile_section_id: 4491,
    name: "TCE141.3x5.6",
    type: "Tube",
    designation: "TCE141.3x5.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "23,9",
    d_global: "141,3",
    i_global: 550,
    t_global: "5,6",
    z: 103,
  },
  {
    profile_section_id: 4492,
    name: "TCE141.3x6.3",
    type: "Tube",
    designation: "TCE141.3x6.3",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "26,7",
    d_global: "141,3",
    i_global: 610,
    t_global: "6,3",
    z: 115,
  },
  {
    profile_section_id: 4493,
    name: "TCE141.3x7.1",
    type: "Tube",
    designation: "TCE141.3x7.1",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "29,9",
    d_global: "141,3",
    i_global: 676,
    t_global: "7,1",
    z: 128,
  },
  {
    profile_section_id: 4494,
    name: "TCE141.3x8",
    type: "Tube",
    designation: "TCE141.3x8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "33,5",
    d_global: "141,3",
    i_global: 747,
    t_global: 8,
    z: 142,
  },
  {
    profile_section_id: 4495,
    name: "TCE141.3x8.8",
    type: "Tube",
    designation: "TCE141.3x8.8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "36,6",
    d_global: "141,3",
    i_global: 807,
    t_global: "8,8",
    z: 155,
  },
  {
    profile_section_id: 4496,
    name: "TCE141.3x10",
    type: "Tube",
    designation: "TCE141.3x10",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "41,2",
    d_global: "141,3",
    i_global: 894,
    t_global: 10,
    z: 173,
  },
  {
    profile_section_id: 4497,
    name: "TCE141.3x11",
    type: "Tube",
    designation: "TCE141.3x11",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 45,
    d_global: "141,3",
    i_global: 962,
    t_global: 11,
    z: 187,
  },
  {
    profile_section_id: 4498,
    name: "TCE141.3x12.5",
    type: "Tube",
    designation: "TCE141.3x12.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "50,6",
    d_global: "141,3",
    i_global: 1059,
    t_global: "12,5",
    z: 208,
  },
  {
    profile_section_id: 4499,
    name: "TCE141.3x14.2",
    type: "Tube",
    designation: "TCE141.3x14.2",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "56,7",
    d_global: "141,3",
    i_global: 1159,
    t_global: "14,2",
    z: 230,
  },
  {
    profile_section_id: 4500,
    name: "TCE141.3x16",
    type: "Tube",
    designation: "TCE141.3x16",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 63,
    d_global: "141,3",
    i_global: 1256,
    t_global: 16,
    z: 253,
  },
  {
    profile_section_id: 4501,
    name: "TCE141.3x17.5",
    type: "Tube",
    designation: "TCE141.3x17.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "68,1",
    d_global: "141,3",
    i_global: 1330,
    t_global: "17,5",
    z: 270,
  },
  {
    profile_section_id: 4502,
    name: "TCE168.3x5",
    type: "Tube",
    designation: "TCE168.3x5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "25,7",
    d_global: "168,3",
    i_global: 856,
    t_global: 5,
    z: 133,
  },
  {
    profile_section_id: 4503,
    name: "TCE168.3x5.6",
    type: "Tube",
    designation: "TCE168.3x5.6",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "28,6",
    d_global: "168,3",
    i_global: 948,
    t_global: "5,6",
    z: 148,
  },
  {
    profile_section_id: 4504,
    name: "TCE168.3x6.3",
    type: "Tube",
    designation: "TCE168.3x6.3",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "32,1",
    d_global: "168,3",
    i_global: 1053,
    t_global: "6,3",
    z: 165,
  },
  {
    profile_section_id: 4505,
    name: "TCE168.3x7.1",
    type: "Tube",
    designation: "TCE168.3x7.1",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 36,
    d_global: "168,3",
    i_global: 1170,
    t_global: "7,1",
    z: 185,
  },
  {
    profile_section_id: 4506,
    name: "TCE168.3x8",
    type: "Tube",
    designation: "TCE168.3x8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "40,3",
    d_global: "168,3",
    i_global: 1297,
    t_global: 8,
    z: 206,
  },
  {
    profile_section_id: 4507,
    name: "TCE168.3x8.8",
    type: "Tube",
    designation: "TCE168.3x8.8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "44,1",
    d_global: "168,3",
    i_global: 1407,
    t_global: "8,8",
    z: 224,
  },
  {
    profile_section_id: 4508,
    name: "TCE168.3x10",
    type: "Tube",
    designation: "TCE168.3x10",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "49,7",
    d_global: "168,3",
    i_global: 1564,
    t_global: 10,
    z: 251,
  },
  {
    profile_section_id: 4509,
    name: "TCE168.3x11",
    type: "Tube",
    designation: "TCE168.3x11",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "54,4",
    d_global: "168,3",
    i_global: 1689,
    t_global: 11,
    z: 273,
  },
  {
    profile_section_id: 4510,
    name: "TCE168.3x12.5",
    type: "Tube",
    designation: "TCE168.3x12.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "61,2",
    d_global: "168,3",
    i_global: 1868,
    t_global: "12,5",
    z: 304,
  },
  {
    profile_section_id: 4511,
    name: "TCE168.3x14.2",
    type: "Tube",
    designation: "TCE168.3x14.2",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "68,7",
    d_global: "168,3",
    i_global: 2058,
    t_global: "14,2",
    z: 338,
  },
  {
    profile_section_id: 4512,
    name: "TCE168.3x16",
    type: "Tube",
    designation: "TCE168.3x16",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "76,6",
    d_global: "168,3",
    i_global: 2244,
    t_global: 16,
    z: 372,
  },
  {
    profile_section_id: 4513,
    name: "TCE168.3x17.5",
    type: "Tube",
    designation: "TCE168.3x17.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "82,9",
    d_global: "168,3",
    i_global: 2388,
    t_global: "17,5",
    z: 400,
  },
  {
    profile_section_id: 4514,
    name: "TCE168.3x20",
    type: "Tube",
    designation: "TCE168.3x20",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "93,2",
    d_global: "168,3",
    i_global: 2608,
    t_global: 20,
    z: 443,
  },
  {
    profile_section_id: 4515,
    name: "TCE219.1x6.3",
    type: "Tube",
    designation: "TCE219.1x6.3",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "42,1",
    d_global: "219,1",
    i_global: 2386,
    t_global: "6,3",
    z: 285,
  },
  {
    profile_section_id: 4516,
    name: "TCE219.1x7.1",
    type: "Tube",
    designation: "TCE219.1x7.1",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "47,3",
    d_global: "219,1",
    i_global: 2660,
    t_global: "7,1",
    z: 319,
  },
  {
    profile_section_id: 4517,
    name: "TCE219.1x8",
    type: "Tube",
    designation: "TCE219.1x8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "53,1",
    d_global: "219,1",
    i_global: 2960,
    t_global: 8,
    z: 357,
  },
  {
    profile_section_id: 4518,
    name: "TCE219.1x8.8",
    type: "Tube",
    designation: "TCE219.1x8.8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "58,1",
    d_global: "219,1",
    i_global: 3220,
    t_global: "8,8",
    z: 389,
  },
  {
    profile_section_id: 4519,
    name: "TCE219.1x10",
    type: "Tube",
    designation: "TCE219.1x10",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "65,7",
    d_global: "219,1",
    i_global: 3598,
    t_global: 10,
    z: 438,
  },
  {
    profile_section_id: 4520,
    name: "TCE219.1x11",
    type: "Tube",
    designation: "TCE219.1x11",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "71,9",
    d_global: "219,1",
    i_global: 3904,
    t_global: 11,
    z: 477,
  },
  {
    profile_section_id: 4521,
    name: "TCE219.1x12.5",
    type: "Tube",
    designation: "TCE219.1x12.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "81,1",
    d_global: "219,1",
    i_global: 4345,
    t_global: "12,5",
    z: 534,
  },
  {
    profile_section_id: 4522,
    name: "TCE219.1x14.2",
    type: "Tube",
    designation: "TCE219.1x14.2",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "91,4",
    d_global: "219,1",
    i_global: 4820,
    t_global: "14,2",
    z: 597,
  },
  {
    profile_section_id: 4523,
    name: "TCE219.1x16",
    type: "Tube",
    designation: "TCE219.1x16",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 102,
    d_global: "219,1",
    i_global: 5297,
    t_global: 16,
    z: 661,
  },
  {
    profile_section_id: 4524,
    name: "TCE219.1x17.5",
    type: "Tube",
    designation: "TCE219.1x17.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 111,
    d_global: "219,1",
    i_global: 5673,
    t_global: "17,5",
    z: 713,
  },
  {
    profile_section_id: 4525,
    name: "TCE219.1x20",
    type: "Tube",
    designation: "TCE219.1x20",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 125,
    d_global: "219,1",
    i_global: 6261,
    t_global: 20,
    z: 795,
  },
  {
    profile_section_id: 4526,
    name: "TCE219.1x25",
    type: "Tube",
    designation: "TCE219.1x25",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 152,
    d_global: "219,1",
    i_global: 7298,
    t_global: 25,
    z: 947,
  },
  {
    profile_section_id: 4527,
    name: "TCE273x6.3",
    type: "Tube",
    designation: "TCE273x6.3",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "52,8",
    d_global: 273,
    i_global: 4696,
    t_global: "6,3",
    z: 448,
  },
  {
    profile_section_id: 4528,
    name: "TCE273x7.1",
    type: "Tube",
    designation: "TCE273x7.1",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "59,3",
    d_global: 273,
    i_global: 5245,
    t_global: "7,1",
    z: 502,
  },
  {
    profile_section_id: 4529,
    name: "TCE273x8",
    type: "Tube",
    designation: "TCE273x8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "66,6",
    d_global: 273,
    i_global: 5852,
    t_global: 8,
    z: 562,
  },
  {
    profile_section_id: 4530,
    name: "TCE273x8.8",
    type: "Tube",
    designation: "TCE273x8.8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 73,
    d_global: 273,
    i_global: 6380,
    t_global: "8,8",
    z: 614,
  },
  {
    profile_section_id: 4531,
    name: "TCE273x10",
    type: "Tube",
    designation: "TCE273x10",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "82,6",
    d_global: 273,
    i_global: 7154,
    t_global: 10,
    z: 692,
  },
  {
    profile_section_id: 4532,
    name: "TCE273x11",
    type: "Tube",
    designation: "TCE273x11",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "90,5",
    d_global: 273,
    i_global: 7783,
    t_global: 11,
    z: 756,
  },
  {
    profile_section_id: 4533,
    name: "TCE273x12.5",
    type: "Tube",
    designation: "TCE273x12.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 102,
    d_global: 273,
    i_global: 8697,
    t_global: "12,5",
    z: 849,
  },
  {
    profile_section_id: 4534,
    name: "TCE273x14.2",
    type: "Tube",
    designation: "TCE273x14.2",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 115,
    d_global: 273,
    i_global: 9695,
    t_global: "14,2",
    z: 952,
  },
  {
    profile_section_id: 4535,
    name: "TCE273x16",
    type: "Tube",
    designation: "TCE273x16",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 129,
    d_global: 273,
    i_global: 10707,
    t_global: 16,
    z: 1058,
  },
  {
    profile_section_id: 4536,
    name: "TCE273x17.5",
    type: "Tube",
    designation: "TCE273x17.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 140,
    d_global: 273,
    i_global: 11516,
    t_global: "17,5",
    z: 1144,
  },
  {
    profile_section_id: 4537,
    name: "TCE273x20",
    type: "Tube",
    designation: "TCE273x20",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 159,
    d_global: 273,
    i_global: 12798,
    t_global: 20,
    z: 1283,
  },
  {
    profile_section_id: 4538,
    name: "TCE273x25",
    type: "Tube",
    designation: "TCE273x25",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 195,
    d_global: 273,
    i_global: 15127,
    t_global: 25,
    z: 1543,
  },
  {
    profile_section_id: 4539,
    name: "TCE273x30",
    type: "Tube",
    designation: "TCE273x30",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 229,
    d_global: 273,
    i_global: 17162,
    t_global: 30,
    z: 1780,
  },
  {
    profile_section_id: 4540,
    name: "TCE323.8x7.1",
    type: "Tube",
    designation: "TCE323.8x7.1",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "70,6",
    d_global: "323,8",
    i_global: 8861,
    t_global: "7,1",
    z: 712,
  },
  {
    profile_section_id: 4541,
    name: "TCE323.8x8",
    type: "Tube",
    designation: "TCE323.8x8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "79,4",
    d_global: "323,8",
    i_global: 9901,
    t_global: 8,
    z: 798,
  },
  {
    profile_section_id: 4542,
    name: "TCE323.8x8.8",
    type: "Tube",
    designation: "TCE323.8x8.8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "87,1",
    d_global: "323,8",
    i_global: 10810,
    t_global: "8,8",
    z: 873,
  },
  {
    profile_section_id: 4543,
    name: "TCE323.8x10",
    type: "Tube",
    designation: "TCE323.8x10",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "98,6",
    d_global: "323,8",
    i_global: 12147,
    t_global: 10,
    z: 985,
  },
  {
    profile_section_id: 4544,
    name: "TCE323.8x11",
    type: "Tube",
    designation: "TCE323.8x11",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 108,
    d_global: "323,8",
    i_global: 13237,
    t_global: 11,
    z: 1077,
  },
  {
    profile_section_id: 4545,
    name: "TCE323.8x12.5",
    type: "Tube",
    designation: "TCE323.8x12.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 122,
    d_global: "323,8",
    i_global: 14832,
    t_global: "12,5",
    z: 1212,
  },
  {
    profile_section_id: 4546,
    name: "TCE323.8x14.2",
    type: "Tube",
    designation: "TCE323.8x14.2",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 138,
    d_global: "323,8",
    i_global: 16583,
    t_global: "14,2",
    z: 1362,
  },
  {
    profile_section_id: 4547,
    name: "TCE323.8x16",
    type: "Tube",
    designation: "TCE323.8x16",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 155,
    d_global: "323,8",
    i_global: 18372,
    t_global: 16,
    z: 1517,
  },
  {
    profile_section_id: 4548,
    name: "TCE323.8x17.5",
    type: "Tube",
    designation: "TCE323.8x17.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 168,
    d_global: "323,8",
    i_global: 19813,
    t_global: "17,5",
    z: 1644,
  },
  {
    profile_section_id: 4549,
    name: "TCE323.8x20",
    type: "Tube",
    designation: "TCE323.8x20",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 191,
    d_global: "323,8",
    i_global: 22117,
    t_global: 20,
    z: 1849,
  },
  {
    profile_section_id: 4550,
    name: "TCE323.8x25",
    type: "Tube",
    designation: "TCE323.8x25",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 235,
    d_global: "323,8",
    i_global: 26374,
    t_global: 25,
    z: 2237,
  },
  {
    profile_section_id: 4551,
    name: "TCE355.6x8.8",
    type: "Tube",
    designation: "TCE355.6x8.8",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: "95,9",
    d_global: "355,6",
    i_global: 14423,
    t_global: "8,8",
    z: 1059,
  },
  {
    profile_section_id: 4552,
    name: "TCE355.6x10",
    type: "Tube",
    designation: "TCE355.6x10",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 109,
    d_global: "355,6",
    i_global: 16223,
    t_global: 10,
    z: 1195,
  },
  {
    profile_section_id: 4553,
    name: "TCE355.6x11",
    type: "Tube",
    designation: "TCE355.6x11",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 119,
    d_global: "355,6",
    i_global: 17695,
    t_global: 11,
    z: 1307,
  },
  {
    profile_section_id: 4554,
    name: "TCE355.6x12.5",
    type: "Tube",
    designation: "TCE355.6x12.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 135,
    d_global: "355,6",
    i_global: 19852,
    t_global: "12,5",
    z: 1472,
  },
  {
    profile_section_id: 4555,
    name: "TCE355.6x14.2",
    type: "Tube",
    designation: "TCE355.6x14.2",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 152,
    d_global: "355,6",
    i_global: 22227,
    t_global: "14,2",
    z: 1656,
  },
  {
    profile_section_id: 4556,
    name: "TCE355.6x16",
    type: "Tube",
    designation: "TCE355.6x16",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 171,
    d_global: "355,6",
    i_global: 24663,
    t_global: 16,
    z: 1847,
  },
  {
    profile_section_id: 4557,
    name: "TCE355.6x17.5",
    type: "Tube",
    designation: "TCE355.6x17.5",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 186,
    d_global: "355,6",
    i_global: 26631,
    t_global: "17,5",
    z: 2002,
  },
  {
    profile_section_id: 4558,
    name: "TCE355.6x20",
    type: "Tube",
    designation: "TCE355.6x20",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 211,
    d_global: "355,6",
    i_global: 29792,
    t_global: 20,
    z: 2255,
  },
  {
    profile_section_id: 4559,
    name: "TCE355.6x25",
    type: "Tube",
    designation: "TCE355.6x25",
    shape: "Tube",
    country_code: "Brazilian",
    ax_global: 260,
    d_global: "355,6",
    i_global: 35677,
    t_global: 25,
    z: 2738,
  },
  {
    profile_section_id: 4560,
    name: "DN25STD",
    type: "Pipe",
    designation: "DN25STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "3,18",
    i_global: "3,64",
    z: "3,06",
  },
  {
    profile_section_id: 4561,
    name: "DN25XS",
    type: "Pipe",
    designation: "DN25XS",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "4,08",
    i_global: "4,4",
    z: "3,82",
  },
  {
    profile_section_id: 4562,
    name: "DN32STD",
    type: "Pipe",
    designation: "DN32STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "4,33",
    i_global: "8,13",
    z: "5,33",
  },
  {
    profile_section_id: 4563,
    name: "DN32XS",
    type: "Pipe",
    designation: "DN32XS",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "5,73",
    i_global: "10,09",
    z: "6,8",
  },
  {
    profile_section_id: 4564,
    name: "DN40STD",
    type: "Pipe",
    designation: "DN40STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "5,1",
    i_global: "12,93",
    z: "7,34",
  },
  {
    profile_section_id: 4565,
    name: "DN40XS",
    type: "Pipe",
    designation: "DN40XS",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "6,88",
    i_global: "16,33",
    z: "9,53",
  },
  {
    profile_section_id: 4566,
    name: "DN50STD",
    type: "Pipe",
    designation: "DN50STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "6,88",
    i_global: "27,66",
    z: "12,45",
  },
  {
    profile_section_id: 4567,
    name: "DN50XS",
    type: "Pipe",
    designation: "DN50XS",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "9,55",
    i_global: "36,56",
    z: "16,91",
  },
  {
    profile_section_id: 4568,
    name: "DN65STD",
    type: "Pipe",
    designation: "DN65STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "10,96",
    i_global: "63,63",
    z: "23,79",
  },
  {
    profile_section_id: 4569,
    name: "DN65XS",
    type: "Pipe",
    designation: "DN65XS",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "14,52",
    i_global: 80,
    z: "30,64",
  },
  {
    profile_section_id: 4570,
    name: "DN80STD",
    type: "Pipe",
    designation: "DN80STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "14,39",
    i_global: "125,7",
    z: "38,25",
  },
  {
    profile_section_id: 4571,
    name: "DN80XS",
    type: "Pipe",
    designation: "DN80XS",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "19,49",
    i_global: "162,1",
    z: "50,49",
  },
  {
    profile_section_id: 4572,
    name: "DN90STD",
    type: "Pipe",
    designation: "DN90STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "17,32",
    i_global: "199,3",
    z: "52,81",
  },
  {
    profile_section_id: 4573,
    name: "DN90XS",
    type: "Pipe",
    designation: "DN90XS",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "23,69",
    i_global: "261,5",
    z: "70,84",
  },
  {
    profile_section_id: 4574,
    name: "DN100STD",
    type: "Pipe",
    designation: "DN100STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "20,51",
    i_global: "301,1",
    z: "70,65",
  },
  {
    profile_section_id: 4575,
    name: "DN100XS",
    type: "Pipe",
    designation: "DN100XS",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "28,41",
    i_global: 400,
    z: "95,92",
  },
  {
    profile_section_id: 4576,
    name: "DN125STD",
    type: "Pipe",
    designation: "DN125STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "27,77",
    i_global: "630,8",
    z: 119,
  },
  {
    profile_section_id: 4577,
    name: "DN125XS",
    type: "Pipe",
    designation: "DN125XS",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "39,36",
    i_global: 860,
    z: "165,6",
  },
  {
    profile_section_id: 4578,
    name: "DN150STD",
    type: "Pipe",
    designation: "DN150STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "36,05",
    i_global: 1172,
    z: "184,9",
  },
  {
    profile_section_id: 4579,
    name: "DN150XS",
    type: "Pipe",
    designation: "DN150XS",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "54,27",
    i_global: 1686,
    z: 272,
  },
  {
    profile_section_id: 4580,
    name: "DN200STD",
    type: "Pipe",
    designation: "DN200STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "54,14",
    i_global: 3019,
    z: "364,1",
  },
  {
    profile_section_id: 4581,
    name: "DN200XS",
    type: "Pipe",
    designation: "DN200XS",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "82,29",
    i_global: 4402,
    z: "541,7",
  },
  {
    profile_section_id: 4582,
    name: "DN250STD",
    type: "Pipe",
    designation: "DN250STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "76,82",
    i_global: 6693,
    z: "645,5",
  },
  {
    profile_section_id: 4583,
    name: "DN250",
    type: "Pipe",
    designation: "DN250",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "122,3",
    i_global: 10220,
    z: 1006,
  },
  {
    profile_section_id: 4584,
    name: "DN300STD",
    type: "Pipe",
    designation: "DN300STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "94,14",
    i_global: 11630,
    z: "941,2",
  },
  {
    profile_section_id: 4585,
    name: "DN300",
    type: "Pipe",
    designation: "DN300",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "101,5",
    i_global: 12490,
    z: 1013,
  },
  {
    profile_section_id: 4586,
    name: "DN350STD",
    type: "Pipe",
    designation: "DN350STD",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "103,6",
    i_global: 15480,
    z: 1138,
  },
  {
    profile_section_id: 4587,
    name: "DN350",
    type: "Pipe",
    designation: "DN350",
    shape: "PIPE",
    country_code: "Brazilian",
    ax_global: "120,5",
    i_global: 17840,
    z: 1318,
  },
  {
    profile_section_id: 4588,
    name: "HS406x13",
    type: "Hss Round",
    designation: "HSSP406x13",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 15700,
    d_global: "406,4",
    i_global: 305000000,
    z_global: 1970000,
  },
  {
    profile_section_id: 4589,
    name: "HS406x9.5",
    type: "Hss Round",
    designation: "HSSP406x9.5",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 11900,
    d_global: "406,4",
    i_global: 234000000,
    z_global: 1500000,
  },
  {
    profile_section_id: 4590,
    name: "HS406x6.4",
    type: "Hss Round",
    designation: "HSSP406x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 7980,
    d_global: "406,4",
    i_global: 160000000,
    z_global: 1020000,
  },
  {
    profile_section_id: 4591,
    name: "HS356x16",
    type: "Hss Round",
    designation: "HSSP356x16",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 16900,
    d_global: "355,6",
    i_global: 245000000,
    z_global: 1830000,
  },
  {
    profile_section_id: 4592,
    name: "HS356x13",
    type: "Hss Round",
    designation: "HSSP356x13",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 13700,
    d_global: "355,6",
    i_global: 201000000,
    z_global: 1490000,
  },
  {
    profile_section_id: 4593,
    name: "HS356x9.5",
    type: "Hss Round",
    designation: "HSSP356x9.5",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 10400,
    d_global: "355,6",
    i_global: 155000000,
    z_global: 1140000,
  },
  {
    profile_section_id: 4594,
    name: "HS356x6.4",
    type: "Hss Round",
    designation: "HSSP356x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 6970,
    d_global: "355,6",
    i_global: 106000000,
    z_global: 775000,
  },
  {
    profile_section_id: 4595,
    name: "HS324x13",
    type: "Hss Round",
    designation: "HSSP324x13",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 12400,
    d_global: "323,9",
    i_global: 151000000,
    z_global: 1230000,
  },
  {
    profile_section_id: 4596,
    name: "HS324x9.5",
    type: "Hss Round",
    designation: "HSSP324x9.5",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 9410,
    d_global: "323,9",
    i_global: 116000000,
    z_global: 942000,
  },
  {
    profile_section_id: 4597,
    name: "HS324x6.4",
    type: "Hss Round",
    designation: "HSSP324x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 6330,
    d_global: "323,9",
    i_global: 79900000,
    z_global: 640000,
  },
  {
    profile_section_id: 4598,
    name: "HS273x13",
    type: "Hss Round",
    designation: "HSSP273x13",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 10400,
    d_global: "273,1",
    i_global: 88300000,
    z_global: 862000,
  },
  {
    profile_section_id: 4599,
    name: "HS273x6.4",
    type: "Hss Round",
    designation: "HSSP273x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 5320,
    d_global: "273,1",
    i_global: 47400000,
    z_global: 452000,
  },
  {
    profile_section_id: 4600,
    name: "HS273x4.8",
    type: "Hss Round",
    designation: "HSSP273x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 4030,
    d_global: "273,1",
    i_global: 36300000,
    z_global: 344000,
  },
  {
    profile_section_id: 4601,
    name: "HS219x16",
    type: "Hss Round",
    designation: "HSSP219x16",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 10100,
    d_global: "219,1",
    i_global: 52700000,
    z_global: 657000,
  },
  {
    profile_section_id: 4602,
    name: "HS219x13",
    type: "Hss Round",
    designation: "HSSP219x13",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 8230,
    d_global: "219,1",
    i_global: 44000000,
    z_global: 542000,
  },
  {
    profile_section_id: 4603,
    name: "HS219x9.5",
    type: "Hss Round",
    designation: "HSSP219x9.5",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 6270,
    d_global: "219,1",
    i_global: 34500000,
    z_global: 419000,
  },
  {
    profile_section_id: 4604,
    name: "HS219x6.4",
    type: "Hss Round",
    designation: "HSSP219x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 4240,
    d_global: "219,1",
    i_global: 24000000,
    z_global: 288000,
  },
  {
    profile_section_id: 4605,
    name: "HS219x4.8",
    type: "Hss Round",
    designation: "HSSP219x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3220,
    d_global: "219,1",
    i_global: 18500000,
    z_global: 220000,
  },
  {
    profile_section_id: 4606,
    name: "HS178x13",
    type: "Hss Round",
    designation: "HSSP178x13",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 6590,
    d_global: "177,8",
    i_global: 22600000,
    z_global: 347000,
  },
  {
    profile_section_id: 4607,
    name: "HS178x9.5",
    type: "Hss Round",
    designation: "HSSP178x9.5",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 5040,
    d_global: "177,8",
    i_global: 17900000,
    z_global: 270000,
  },
  {
    profile_section_id: 4608,
    name: "HS178x8.0",
    type: "Hss Round",
    designation: "HSSP178x8.0",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 4240,
    d_global: "177,8",
    i_global: 15300000,
    z_global: 230000,
  },
  {
    profile_section_id: 4609,
    name: "HS178x6.4",
    type: "Hss Round",
    designation: "HSSP178x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3420,
    d_global: "177,8",
    i_global: 12600000,
    z_global: 187000,
  },
  {
    profile_section_id: 4610,
    name: "HS178x4.8",
    type: "Hss Round",
    designation: "HSSP178x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2600,
    d_global: "177,8",
    i_global: 9730000,
    z_global: 143000,
  },
  {
    profile_section_id: 4611,
    name: "HS168x13",
    type: "Hss Round",
    designation: "HSSP168x13",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 6210,
    d_global: "168,3",
    i_global: 18900000,
    z_global: 308000,
  },
  {
    profile_section_id: 4612,
    name: "HS168x9.5",
    type: "Hss Round",
    designation: "HSSP168x9.5",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 4750,
    d_global: "168,3",
    i_global: 15000000,
    z_global: 241000,
  },
  {
    profile_section_id: 4613,
    name: "HS168x8.0",
    type: "Hss Round",
    designation: "HSSP168x8.0",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 4000,
    d_global: "168,3",
    i_global: 12900000,
    z_global: 205000,
  },
  {
    profile_section_id: 4614,
    name: "HS168x6.4",
    type: "Hss Round",
    designation: "HSSP168x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3230,
    d_global: "168,3",
    i_global: 10600000,
    z_global: 167000,
  },
  {
    profile_section_id: 4615,
    name: "HS168x4.8",
    type: "Hss Round",
    designation: "HSSP168x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2460,
    d_global: "168,3",
    i_global: 8210000,
    z_global: 128000,
  },
  {
    profile_section_id: 4616,
    name: "HS168x3.2",
    type: "Hss Round",
    designation: "HSSP168x3.2",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1650,
    d_global: "168,3",
    i_global: 5620000,
    z_global: 86700,
  },
  {
    profile_section_id: 4617,
    name: "HS152x9.5",
    type: "Hss Round",
    designation: "HSSP152x9.5",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 4280,
    d_global: "152,4",
    i_global: 11000000,
    z_global: 195000,
  },
  {
    profile_section_id: 4618,
    name: "HS152x8.0",
    type: "Hss Round",
    designation: "HSSP152x8.0",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3610,
    d_global: "152,4",
    i_global: 9440000,
    z_global: 166000,
  },
  {
    profile_section_id: 4619,
    name: "HS152x6.4",
    type: "Hss Round",
    designation: "HSSP152x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2910,
    d_global: "152,4",
    i_global: 7780000,
    z_global: 136000,
  },
  {
    profile_section_id: 4620,
    name: "HS152x4.8",
    type: "Hss Round",
    designation: "HSSP152x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2220,
    d_global: "152,4",
    i_global: 6040000,
    z_global: 104000,
  },
  {
    profile_section_id: 4621,
    name: "HS152x3.2",
    type: "Hss Round",
    designation: "HSSP152x3.2",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1490,
    d_global: "152,4",
    i_global: 4150000,
    z_global: 70800,
  },
  {
    profile_section_id: 4622,
    name: "HS141x9.5",
    type: "Hss Round",
    designation: "HSSP141x9.5",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3950,
    d_global: "141,3",
    i_global: 8610000,
    z_global: 166000,
  },
  {
    profile_section_id: 4623,
    name: "HS141x6.4",
    type: "Hss Round",
    designation: "HSSP141x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2690,
    d_global: "141,3",
    i_global: 6140000,
    z_global: 116000,
  },
  {
    profile_section_id: 4624,
    name: "HS141x4.8",
    type: "Hss Round",
    designation: "HSSP141x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2050,
    d_global: "141,3",
    i_global: 4780000,
    z_global: 89100,
  },
  {
    profile_section_id: 4625,
    name: "HS127x13",
    type: "Hss Round",
    designation: "HSSP127x13",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 4560,
    d_global: 127,
    i_global: 7540000,
    z_global: 167000,
  },
  {
    profile_section_id: 4626,
    name: "HS127x9.5",
    type: "Hss Round",
    designation: "HSSP127x9.5",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3520,
    d_global: 127,
    i_global: 6110000,
    z_global: 132000,
  },
  {
    profile_section_id: 4627,
    name: "HS127x8.0",
    type: "Hss Round",
    designation: "HSSP127x8.0",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2970,
    d_global: 127,
    i_global: 5290000,
    z_global: 113000,
  },
  {
    profile_section_id: 4628,
    name: "HS127x6.4",
    type: "Hss Round",
    designation: "HSSP127x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2410,
    d_global: 127,
    i_global: 4390000,
    z_global: 92500,
  },
  {
    profile_section_id: 4629,
    name: "HS127x4.8",
    type: "Hss Round",
    designation: "HSSP127x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1840,
    d_global: 127,
    i_global: 3430000,
    z_global: 71400,
  },
  {
    profile_section_id: 4630,
    name: "HS127x3.2",
    type: "Hss Round",
    designation: "HSSP127x3.2",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1240,
    d_global: 127,
    i_global: 2370000,
    z_global: 48800,
  },
  {
    profile_section_id: 4631,
    name: "HS114x9.5",
    type: "Hss Round",
    designation: "HSSP114x9.5",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3140,
    d_global: "114,3",
    i_global: 4340000,
    z_global: 105000,
  },
  {
    profile_section_id: 4632,
    name: "HS114x4.8",
    type: "Hss Round",
    designation: "HSSP114x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1640,
    d_global: "114,3",
    i_global: 2470000,
    z_global: 57400,
  },
  {
    profile_section_id: 4633,
    name: "HS114x3.2",
    type: "Hss Round",
    designation: "HSSP114x3.2",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1110,
    d_global: "114,3",
    i_global: 1710000,
    z_global: 39300,
  },
  {
    profile_section_id: 4634,
    name: "HS102x8.0",
    type: "Hss Round",
    designation: "HSSP102x8.0",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2340,
    d_global: "101,6",
    i_global: 2580000,
    z_global: 69900,
  },
  {
    profile_section_id: 4635,
    name: "HS102x6.4",
    type: "Hss Round",
    designation: "HSSP102x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1900,
    d_global: "101,6",
    i_global: 2160000,
    z_global: 57700,
  },
  {
    profile_section_id: 4636,
    name: "HS102x4.8",
    type: "Hss Round",
    designation: "HSSP102x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1450,
    d_global: "101,6",
    i_global: 1710000,
    z_global: 44800,
  },
  {
    profile_section_id: 4637,
    name: "HS102x3.2",
    type: "Hss Round",
    designation: "HSSP102x3.2",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 983,
    d_global: "101,6",
    i_global: 1190000,
    z_global: 30800,
  },
  {
    profile_section_id: 4638,
    name: "HS89x8.0",
    type: "Hss Round",
    designation: "HSSP89x8.0",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2020,
    d_global: "88,9",
    i_global: 1670000,
    z_global: 52300,
  },
  {
    profile_section_id: 4639,
    name: "HS89x6.4",
    type: "Hss Round",
    designation: "HSSP89x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1650,
    d_global: "88,9",
    i_global: 1410000,
    z_global: 43400,
  },
  {
    profile_section_id: 4640,
    name: "HS89x4.8",
    type: "Hss Round",
    designation: "HSSP89x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1260,
    d_global: "88,9",
    i_global: 1120000,
    z_global: 33900,
  },
  {
    profile_section_id: 4641,
    name: "HS89x3.2",
    type: "Hss Round",
    designation: "HSSP89x3.2",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 856,
    d_global: "88,9",
    i_global: 788000,
    z_global: 23400,
  },
  {
    profile_section_id: 4642,
    name: "HS76x6.4",
    type: "Hss Round",
    designation: "HSSP76x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1390,
    d_global: "76,2",
    i_global: 857000,
    z_global: 31100,
  },
  {
    profile_section_id: 4643,
    name: "HS76x4.8",
    type: "Hss Round",
    designation: "HSSP76x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1070,
    d_global: "76,2",
    i_global: 687000,
    z_global: 24400,
  },
  {
    profile_section_id: 4644,
    name: "HS73x6.4",
    type: "Hss Round",
    designation: "HSSP73x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1330,
    d_global: 73,
    i_global: 745000,
    z_global: 28300,
  },
  {
    profile_section_id: 4645,
    name: "HS73x4.8",
    type: "Hss Round",
    designation: "HSSP73x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1020,
    d_global: 73,
    i_global: 599000,
    z_global: 22300,
  },
  {
    profile_section_id: 4646,
    name: "HS73x3.2",
    type: "Hss Round",
    designation: "HSSP73x3.2",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 698,
    d_global: 73,
    i_global: 426000,
    z_global: 15500,
  },
  {
    profile_section_id: 4647,
    name: "HS64x6.4",
    type: "Hss Round",
    designation: "HSSP64x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1140,
    d_global: "63,5",
    i_global: 471000,
    z_global: 20800,
  },
  {
    profile_section_id: 4648,
    name: "HS64x4.8",
    type: "Hss Round",
    designation: "HSSP64x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 882,
    d_global: "63,5",
    i_global: 383000,
    z_global: 16500,
  },
  {
    profile_section_id: 4649,
    name: "HS64x3.2",
    type: "Hss Round",
    designation: "HSSP64x3.2",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 603,
    d_global: "63,5",
    i_global: 275000,
    z_global: 11600,
  },
  {
    profile_section_id: 4650,
    name: "HS60x6.4",
    type: "Hss Round",
    designation: "HSSP60x6.4",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1080,
    d_global: "60,3",
    i_global: 397000,
    z_global: 18600,
  },
  {
    profile_section_id: 4651,
    name: "HS60x4.8",
    type: "Hss Round",
    designation: "HSSP60x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 834,
    d_global: "60,3",
    i_global: 324000,
    z_global: 14800,
  },
  {
    profile_section_id: 4652,
    name: "HS60x3.2",
    type: "Hss Round",
    designation: "HSSP60x3.2",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 571,
    d_global: "60,3",
    i_global: 233000,
    z_global: 10400,
  },
  {
    profile_section_id: 4653,
    name: "HS48x4.8",
    type: "Hss Round",
    designation: "HSSP48x4.8",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 654,
    d_global: "48,3",
    i_global: 157000,
    z_global: 9090,
  },
  {
    profile_section_id: 4654,
    name: "HS48x3.2",
    type: "Hss Round",
    designation: "HSSP48x3.2",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 451,
    d_global: "48,3",
    i_global: 115000,
    z_global: 6480,
  },
  {
    profile_section_id: 4655,
    name: "HA406x13",
    type: "Hss Round",
    designation: "HSSP406x13HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 14200,
    d_global: "406,4",
    i_global: 277000000,
    z_global: 1780000,
  },
  {
    profile_section_id: 4656,
    name: "HA406x9.5",
    type: "Hss Round",
    designation: "HSSP406x9.5HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 10700,
    d_global: "406,4",
    i_global: 212000000,
    z_global: 1360000,
  },
  {
    profile_section_id: 4657,
    name: "HA406x6.4",
    type: "Hss Round",
    designation: "HSSP406x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 7190,
    d_global: "406,4",
    i_global: 144000000,
    z_global: 918000,
  },
  {
    profile_section_id: 4658,
    name: "HA356x16",
    type: "Hss Round",
    designation: "HSSP356x16HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 15300,
    d_global: "355,6",
    i_global: 224000000,
    z_global: 1670000,
  },
  {
    profile_section_id: 4659,
    name: "HA356x13",
    type: "Hss Round",
    designation: "HSSP356x13HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 12400,
    d_global: "355,6",
    i_global: 183000000,
    z_global: 1350000,
  },
  {
    profile_section_id: 4660,
    name: "HA356x9.5",
    type: "Hss Round",
    designation: "HSSP356x9.5HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 9350,
    d_global: "355,6",
    i_global: 141000000,
    z_global: 1030000,
  },
  {
    profile_section_id: 4661,
    name: "HA356x6.4",
    type: "Hss Round",
    designation: "HSSP356x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 6280,
    d_global: "355,6",
    i_global: 96200000,
    z_global: 700000,
  },
  {
    profile_section_id: 4662,
    name: "HA324x13",
    type: "Hss Round",
    designation: "HSSP324x13HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 11200,
    d_global: "323,9",
    i_global: 137000000,
    z_global: 1120000,
  },
  {
    profile_section_id: 4663,
    name: "HA324x9.5",
    type: "Hss Round",
    designation: "HSSP324x9.5HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 8500,
    d_global: "323,9",
    i_global: 106000000,
    z_global: 853000,
  },
  {
    profile_section_id: 4664,
    name: "HA324x6.4",
    type: "Hss Round",
    designation: "HSSP324x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 5710,
    d_global: "323,9",
    i_global: 72300000,
    z_global: 579000,
  },
  {
    profile_section_id: 4665,
    name: "HA273x13",
    type: "Hss Round",
    designation: "HSSP273x13HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 9400,
    d_global: "273,1",
    i_global: 80600000,
    z_global: 783000,
  },
  {
    profile_section_id: 4666,
    name: "HA273x6.4",
    type: "Hss Round",
    designation: "HSSP273x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 4800,
    d_global: "273,1",
    i_global: 42900000,
    z_global: 409000,
  },
  {
    profile_section_id: 4667,
    name: "HA273x4.8",
    type: "Hss Round",
    designation: "HSSP273x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3630,
    d_global: "273,1",
    i_global: 32800000,
    z_global: 311000,
  },
  {
    profile_section_id: 4668,
    name: "HA219x16",
    type: "Hss Round",
    designation: "HSSP219x16HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 9200,
    d_global: "219,1",
    i_global: 48500000,
    z_global: 600000,
  },
  {
    profile_section_id: 4669,
    name: "HA219x13",
    type: "Hss Round",
    designation: "HSSP219x13HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 7460,
    d_global: "219,1",
    i_global: 40300000,
    z_global: 493000,
  },
  {
    profile_section_id: 4670,
    name: "HA219x9.5",
    type: "Hss Round",
    designation: "HSSP219x9.5HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 5670,
    d_global: "219,1",
    i_global: 31500000,
    z_global: 380000,
  },
  {
    profile_section_id: 4671,
    name: "HA219x6.4",
    type: "Hss Round",
    designation: "HSSP219x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3830,
    d_global: "219,1",
    i_global: 21800000,
    z_global: 260000,
  },
  {
    profile_section_id: 4672,
    name: "HA219x4.8",
    type: "Hss Round",
    designation: "HSSP219x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2900,
    d_global: "219,1",
    i_global: 16700000,
    z_global: 199000,
  },
  {
    profile_section_id: 4673,
    name: "HA178x13",
    type: "Hss Round",
    designation: "HSSP178x13HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 5970,
    d_global: "177,8",
    i_global: 20800000,
    z_global: 317000,
  },
  {
    profile_section_id: 4674,
    name: "HA178x9.5",
    type: "Hss Round",
    designation: "HSSP178x9.5HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 4560,
    d_global: "177,8",
    i_global: 16400000,
    z_global: 246000,
  },
  {
    profile_section_id: 4675,
    name: "HA178x8.0",
    type: "Hss Round",
    designation: "HSSP178x8.0HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3840,
    d_global: "177,8",
    i_global: 14000000,
    z_global: 208000,
  },
  {
    profile_section_id: 4676,
    name: "HA178x6.4",
    type: "Hss Round",
    designation: "HSSP178x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3090,
    d_global: "177,8",
    i_global: 11400000,
    z_global: 169000,
  },
  {
    profile_section_id: 4677,
    name: "HA178x4.8",
    type: "Hss Round",
    designation: "HSSP178x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2340,
    d_global: "177,8",
    i_global: 8830000,
    z_global: 130000,
  },
  {
    profile_section_id: 4678,
    name: "HA168x13",
    type: "Hss Round",
    designation: "HSSP168x13HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 5630,
    d_global: "168,3",
    i_global: 17400000,
    z_global: 282000,
  },
  {
    profile_section_id: 4679,
    name: "HA168x9.5",
    type: "Hss Round",
    designation: "HSSP168x9.5HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 4300,
    d_global: "168,3",
    i_global: 13800000,
    z_global: 219000,
  },
  {
    profile_section_id: 4680,
    name: "HA168x8.0",
    type: "Hss Round",
    designation: "HSSP168x8.0HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3620,
    d_global: "168,3",
    i_global: 11800000,
    z_global: 186000,
  },
  {
    profile_section_id: 4681,
    name: "HA168x6.4",
    type: "Hss Round",
    designation: "HSSP168x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2920,
    d_global: "168,3",
    i_global: 9660000,
    z_global: 151000,
  },
  {
    profile_section_id: 4682,
    name: "HA168x4.8",
    type: "Hss Round",
    designation: "HSSP168x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2220,
    d_global: "168,3",
    i_global: 7460000,
    z_global: 116000,
  },
  {
    profile_section_id: 4683,
    name: "HA168x3.2",
    type: "Hss Round",
    designation: "HSSP168x3.2HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1490,
    d_global: "168,3",
    i_global: 5090000,
    z_global: 78300,
  },
  {
    profile_section_id: 4684,
    name: "HA152x9.5",
    type: "Hss Round",
    designation: "HSSP152x9.5HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3880,
    d_global: "152,4",
    i_global: 10100000,
    z_global: 178000,
  },
  {
    profile_section_id: 4685,
    name: "HA152x8.0",
    type: "Hss Round",
    designation: "HSSP152x8.0HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3260,
    d_global: "152,4",
    i_global: 8630000,
    z_global: 151000,
  },
  {
    profile_section_id: 4686,
    name: "HA152x6.4",
    type: "Hss Round",
    designation: "HSSP152x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2630,
    d_global: "152,4",
    i_global: 7090000,
    z_global: 123000,
  },
  {
    profile_section_id: 4687,
    name: "HA152x4.8",
    type: "Hss Round",
    designation: "HSSP152x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2000,
    d_global: "152,4",
    i_global: 5490000,
    z_global: 94400,
  },
  {
    profile_section_id: 4688,
    name: "HA152x3.2",
    type: "Hss Round",
    designation: "HSSP152x3.2HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1340,
    d_global: "152,4",
    i_global: 3760000,
    z_global: 64000,
  },
  {
    profile_section_id: 4689,
    name: "HA141x9.5",
    type: "Hss Round",
    designation: "HSSP141x9.5HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3580,
    d_global: "141,3",
    i_global: 7910000,
    z_global: 151000,
  },
  {
    profile_section_id: 4690,
    name: "HA141x6.4",
    type: "Hss Round",
    designation: "HSSP141x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2430,
    d_global: "141,3",
    i_global: 5600000,
    z_global: 105000,
  },
  {
    profile_section_id: 4691,
    name: "HA141x4.8",
    type: "Hss Round",
    designation: "HSSP141x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1850,
    d_global: "141,3",
    i_global: 4350000,
    z_global: 80800,
  },
  {
    profile_section_id: 4692,
    name: "HA127x13",
    type: "Hss Round",
    designation: "HSSP127x13HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 4150,
    d_global: 127,
    i_global: 7000000,
    z_global: 153000,
  },
  {
    profile_section_id: 4693,
    name: "HA127x9.5",
    type: "Hss Round",
    designation: "HSSP127x9.5HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 3190,
    d_global: 127,
    i_global: 5620000,
    z_global: 120000,
  },
  {
    profile_section_id: 4694,
    name: "HA127x8.0",
    type: "Hss Round",
    designation: "HSSP127x8.0HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2690,
    d_global: 127,
    i_global: 4850000,
    z_global: 103000,
  },
  {
    profile_section_id: 4695,
    name: "HA127x6.4",
    type: "Hss Round",
    designation: "HSSP127x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2180,
    d_global: 127,
    i_global: 4010000,
    z_global: 84100,
  },
  {
    profile_section_id: 4696,
    name: "HA127x4.8",
    type: "Hss Round",
    designation: "HSSP127x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1660,
    d_global: 127,
    i_global: 3120000,
    z_global: 64800,
  },
  {
    profile_section_id: 4697,
    name: "HA127x3.2",
    type: "Hss Round",
    designation: "HSSP127x3.2HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1120,
    d_global: 127,
    i_global: 2150000,
    z_global: 44100,
  },
  {
    profile_section_id: 4698,
    name: "HA114x9.5",
    type: "Hss Round",
    designation: "HSSP114x9.5HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2850,
    d_global: "114,3",
    i_global: 4010000,
    z_global: 96100,
  },
  {
    profile_section_id: 4699,
    name: "HA114x4.8",
    type: "Hss Round",
    designation: "HSSP114x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1490,
    d_global: "114,3",
    i_global: 2250000,
    z_global: 52100,
  },
  {
    profile_section_id: 4700,
    name: "HA114x3.2",
    type: "Hss Round",
    designation: "HSSP114x3.2HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1000,
    d_global: "114,3",
    i_global: 1560000,
    z_global: 35500,
  },
  {
    profile_section_id: 4701,
    name: "HA102x8.0",
    type: "Hss Round",
    designation: "HSSP102x8.0HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 2120,
    d_global: "101,6",
    i_global: 2380000,
    z_global: 63900,
  },
  {
    profile_section_id: 4702,
    name: "HA102x6.4",
    type: "Hss Round",
    designation: "HSSP102x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1720,
    d_global: "101,6",
    i_global: 1990000,
    z_global: 52600,
  },
  {
    profile_section_id: 4703,
    name: "HA102x4.8",
    type: "Hss Round",
    designation: "HSSP102x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1310,
    d_global: "101,6",
    i_global: 1560000,
    z_global: 40800,
  },
  {
    profile_section_id: 4704,
    name: "HA102x3.2",
    type: "Hss Round",
    designation: "HSSP102x3.2HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 888,
    d_global: "101,6",
    i_global: 1080000,
    z_global: 27900,
  },
  {
    profile_section_id: 4705,
    name: "HA89x8.0",
    type: "Hss Round",
    designation: "HSSP89x8.0HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1840,
    d_global: "88,9",
    i_global: 1550000,
    z_global: 47900,
  },
  {
    profile_section_id: 4706,
    name: "HA89x6.4",
    type: "Hss Round",
    designation: "HSSP89x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1490,
    d_global: "88,9",
    i_global: 1300000,
    z_global: 39600,
  },
  {
    profile_section_id: 4707,
    name: "HA89x4.8",
    type: "Hss Round",
    designation: "HSSP89x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1140,
    d_global: "88,9",
    i_global: 1030000,
    z_global: 30800,
  },
  {
    profile_section_id: 4708,
    name: "HA89x3.2",
    type: "Hss Round",
    designation: "HSSP89x3.2HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 774,
    d_global: "88,9",
    i_global: 717000,
    z_global: 21200,
  },
  {
    profile_section_id: 4709,
    name: "HA76x6.4",
    type: "Hss Round",
    designation: "HSSP76x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1270,
    d_global: "76,2",
    i_global: 791000,
    z_global: 28500,
  },
  {
    profile_section_id: 4710,
    name: "HA76x4.8",
    type: "Hss Round",
    designation: "HSSP76x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 972,
    d_global: "76,2",
    i_global: 630000,
    z_global: 22300,
  },
  {
    profile_section_id: 4711,
    name: "HA73x6.4",
    type: "Hss Round",
    designation: "HSSP73x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1210,
    d_global: 73,
    i_global: 689000,
    z_global: 25900,
  },
  {
    profile_section_id: 4712,
    name: "HA73x4.8",
    type: "Hss Round",
    designation: "HSSP73x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 928,
    d_global: 73,
    i_global: 550000,
    z_global: 20300,
  },
  {
    profile_section_id: 4713,
    name: "HA73x3.2",
    type: "Hss Round",
    designation: "HSSP73x3.2HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 631,
    d_global: 73,
    i_global: 388000,
    z_global: 14100,
  },
  {
    profile_section_id: 4714,
    name: "HA64x6.4",
    type: "Hss Round",
    designation: "HSSP64x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 1040,
    d_global: "63,5",
    i_global: 437000,
    z_global: 19100,
  },
  {
    profile_section_id: 4715,
    name: "HA64x4.8",
    type: "Hss Round",
    designation: "HSSP64x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 800,
    d_global: "63,5",
    i_global: 352000,
    z_global: 15100,
  },
  {
    profile_section_id: 4716,
    name: "HA64x3.2",
    type: "Hss Round",
    designation: "HSSP64x3.2HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 545,
    d_global: "63,5",
    i_global: 251000,
    z_global: 10500,
  },
  {
    profile_section_id: 4717,
    name: "HA60x6.4",
    type: "Hss Round",
    designation: "HSSP60x6.4HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 980,
    d_global: "60,3",
    i_global: 369000,
    z_global: 17100,
  },
  {
    profile_section_id: 4718,
    name: "HA60x4.8",
    type: "Hss Round",
    designation: "HSSP60x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 757,
    d_global: "60,3",
    i_global: 298000,
    z_global: 13500,
  },
  {
    profile_section_id: 4719,
    name: "HA60x3.2",
    type: "Hss Round",
    designation: "HSSP60x3.2HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 516,
    d_global: "60,3",
    i_global: 214000,
    z_global: 9450,
  },
  {
    profile_section_id: 4720,
    name: "HA48x4.8",
    type: "Hss Round",
    designation: "HSSP48x4.8HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 595,
    d_global: "48,3",
    i_global: 145000,
    z_global: 8350,
  },
  {
    profile_section_id: 4721,
    name: "HA48x3.2",
    type: "Hss Round",
    designation: "HSSP48x3.2HA",
    shape: "Hss Round",
    country_code: "Canadian",
    ax_global: 409,
    d_global: "48,3",
    i_global: 106000,
    z_global: 5920,
  },
  {
    profile_section_id: 7041,
    name: "RD200",
    type: "Solid Round",
    designation: "RD200",
    shape: "Solid Round",
    country_code: "Dutch",
    ax_global: 314,
    i_global: 7854,
    z_global: 785,
  },
  {
    profile_section_id: 7042,
    name: "PIP332.6",
    type: "Pipe",
    designation: "PIP332.6",
    shape: "PIPE",
    country_code: "French",
    ax_global: "2,54",
    d_global: "33,7",
    i_global: "3,09",
    t_global: "2,6",
    z_global: "2,52",
  },
  {
    profile_section_id: 7043,
    name: "PIP333.2",
    type: "Pipe",
    designation: "PIP333.2",
    shape: "PIPE",
    country_code: "French",
    ax_global: "3,07",
    d_global: "33,7",
    i_global: "3,6",
    t_global: "3,2",
    z_global: "2,99",
  },
  {
    profile_section_id: 7044,
    name: "PIP422.6",
    type: "Pipe",
    designation: "PIP422.6",
    shape: "PIPE",
    country_code: "French",
    ax_global: "3,25",
    d_global: "42,4",
    i_global: "6,46",
    t_global: "2,9",
    z_global: "4,12",
  },
  {
    profile_section_id: 7045,
    name: "PIP423.2",
    type: "Pipe",
    designation: "PIP423.2",
    shape: "PIPE",
    country_code: "French",
    ax_global: "3,94",
    d_global: "42,4",
    i_global: "7,62",
    t_global: "3,2",
    z_global: "4,93",
  },
  {
    profile_section_id: 7046,
    name: "PIP483.2",
    type: "Pipe",
    designation: "PIP483.2",
    shape: "PIPE",
    country_code: "French",
    ax_global: "4,53",
    d_global: "48,3",
    i_global: "11,6",
    t_global: "3,2",
    z_global: "6,52",
  },
  {
    profile_section_id: 7047,
    name: "PIP603.2",
    type: "Pipe",
    designation: "PIP603.2",
    shape: "PIPE",
    country_code: "French",
    ax_global: "5,74",
    d_global: "60,3",
    i_global: "23,47",
    t_global: "3,6",
    z_global: "10,44",
  },
  {
    profile_section_id: 7048,
    name: "PIP763.6",
    type: "Pipe",
    designation: "PIP763.6",
    shape: "PIPE",
    country_code: "French",
    ax_global: "8,2",
    d_global: "76,1",
    i_global: "54,01",
    t_global: 4,
    z_global: "18,94",
  },
  {
    profile_section_id: 7049,
    name: "PIP883.2",
    type: "Pipe",
    designation: "PIP883.2",
    shape: "PIPE",
    country_code: "French",
    ax_global: "8,62",
    d_global: "88,9",
    i_global: "79,2",
    t_global: "3,6",
    z_global: "23,51",
  },
  {
    profile_section_id: 7050,
    name: "PIP884.0",
    type: "Pipe",
    designation: "PIP884.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "10,7",
    d_global: "88,9",
    i_global: "96,34",
    t_global: "3,6",
    z_global: "28,85",
  },
  {
    profile_section_id: 7051,
    name: "PIP1143.6",
    type: "Pipe",
    designation: "PIP1143.6",
    shape: "PIPE",
    country_code: "French",
    ax_global: "12,52",
    d_global: "114,3",
    i_global: 192,
    t_global: "3,6",
    z_global: "44,13",
  },
  {
    profile_section_id: 7052,
    name: "PIP1394.0",
    type: "Pipe",
    designation: "PIP1394.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "17,05",
    d_global: "139,7",
    i_global: "392,9",
    t_global: "3,6",
    z_global: "73,68",
  },
  {
    profile_section_id: 7053,
    name: "PIP1683.6",
    type: "Pipe",
    designation: "PIP1683.6",
    shape: "PIPE",
    country_code: "French",
    ax_global: "18,63",
    d_global: "168,3",
    i_global: "631,9",
    t_global: "4,5",
    z_global: "97,67",
  },
  {
    profile_section_id: 7054,
    name: "PIP1686.3",
    type: "Pipe",
    designation: "PIP1686.3",
    shape: "PIPE",
    country_code: "French",
    ax_global: "32,06",
    d_global: "168,3",
    i_global: 1053,
    t_global: 4,
    z_global: "165,4",
  },
  {
    profile_section_id: 7055,
    name: "PIP1936.3",
    type: "Pipe",
    designation: "PIP1936.3",
    shape: "PIPE",
    country_code: "French",
    ax_global: "37,09",
    d_global: "193,7",
    i_global: 1630,
    t_global: "6,3",
    z_global: "221,3",
  },
  {
    profile_section_id: 7056,
    name: "PIP2196.3",
    type: "Pipe",
    designation: "PIP2196.3",
    shape: "PIPE",
    country_code: "French",
    ax_global: "42,12",
    d_global: "219,1",
    i_global: 2386,
    t_global: 10,
    z_global: "285,4",
  },
  {
    profile_section_id: 7057,
    name: "PIP2446.3",
    type: "Pipe",
    designation: "PIP2446.3",
    shape: "PIPE",
    country_code: "French",
    ax_global: "47,14",
    d_global: "244,5",
    i_global: 3346,
    t_global: "6,3",
    z_global: "357,5",
  },
  {
    profile_section_id: 7058,
    name: "PIP16810.0",
    type: "Pipe",
    designation: "PIP16810.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "49,73",
    d_global: "168,3",
    i_global: 1564,
    t_global: "4,5",
    z_global: "250,9",
  },
  {
    profile_section_id: 7059,
    name: "PIP2736.3",
    type: "Pipe",
    designation: "PIP2736.3",
    shape: "PIPE",
    country_code: "French",
    ax_global: "52,78",
    d_global: 273,
    i_global: 4696,
    t_global: 10,
    z_global: "448,2",
  },
  {
    profile_section_id: 7060,
    name: "PIP19310.0",
    type: "Pipe",
    designation: "PIP19310.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "57,71",
    d_global: "193,7",
    i_global: 2442,
    t_global: "12,5",
    z_global: "337,8",
  },
  {
    profile_section_id: 7061,
    name: "PIP2448.0",
    type: "Pipe",
    designation: "PIP2448.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "59,44",
    d_global: "244,5",
    i_global: 4160,
    t_global: "6,3",
    z_global: "447,6",
  },
  {
    profile_section_id: 7062,
    name: "PIP16812.5",
    type: "Pipe",
    designation: "PIP16812.5",
    shape: "PIPE",
    country_code: "French",
    ax_global: "61,18",
    d_global: "168,3",
    i_global: 1868,
    t_global: 10,
    z_global: "304,1",
  },
  {
    profile_section_id: 7063,
    name: "PIP3236.3",
    type: "Pipe",
    designation: "PIP3236.3",
    shape: "PIPE",
    country_code: "French",
    ax_global: "62,85",
    d_global: 323,
    i_global: 5143,
    t_global: "12,5",
    z_global: "635,6",
  },
  {
    profile_section_id: 7064,
    name: "PIP21910.0",
    type: "Pipe",
    designation: "PIP21910.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "65,69",
    d_global: "219,1",
    i_global: 3598,
    t_global: 10,
    z_global: "437,6",
  },
  {
    profile_section_id: 7065,
    name: "PIP19312.5",
    type: "Pipe",
    designation: "PIP19312.5",
    shape: "PIPE",
    country_code: "French",
    ax_global: "71,16",
    d_global: "193,7",
    i_global: 2934,
    t_global: "6,3",
    z_global: "411,1",
  },
  {
    profile_section_id: 7066,
    name: "PIP24410.0",
    type: "Pipe",
    designation: "PIP24410.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "73,67",
    d_global: "244,5",
    i_global: 5073,
    t_global: "12,5",
    z_global: "550,2",
  },
  {
    profile_section_id: 7067,
    name: "PIP4066.3",
    type: "Pipe",
    designation: "PIP4066.3",
    shape: "PIPE",
    country_code: "French",
    ax_global: "79,19",
    d_global: "406,4",
    i_global: 15850,
    t_global: 10,
    z_global: 1009,
  },
  {
    profile_section_id: 7068,
    name: "PIP21912.5",
    type: "Pipe",
    designation: "PIP21912.5",
    shape: "PIPE",
    country_code: "French",
    ax_global: "81,13",
    d_global: "219,1",
    i_global: 4345,
    t_global: 8,
    z_global: "534,2",
  },
  {
    profile_section_id: 7069,
    name: "PIP27310.0",
    type: "Pipe",
    designation: "PIP27310.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "82,62",
    d_global: 273,
    i_global: 7154,
    t_global: "12,5",
    z_global: 692,
  },
  {
    profile_section_id: 7070,
    name: "PIP3558.0",
    type: "Pipe",
    designation: "PIP3558.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "87,36",
    d_global: "355,6",
    i_global: 13200,
    t_global: "12,5",
    z_global: "966,8",
  },
  {
    profile_section_id: 7071,
    name: "PIP24412.5",
    type: "Pipe",
    designation: "PIP24412.5",
    shape: "PIPE",
    country_code: "French",
    ax_global: "91,11",
    d_global: "244,5",
    i_global: 5073,
    t_global: 16,
    z_global: "550,2",
  },
  {
    profile_section_id: 7072,
    name: "PIP27312.5",
    type: "Pipe",
    designation: "PIP27312.5",
    shape: "PIPE",
    country_code: "French",
    ax_global: "102,3",
    d_global: 273,
    i_global: 8697,
    t_global: 10,
    z_global: "848,9",
  },
  {
    profile_section_id: 7073,
    name: "PIP21916.0",
    type: "Pipe",
    designation: "PIP21916.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "102,1",
    d_global: "219,1",
    i_global: 5297,
    t_global: 16,
    z_global: "661,4",
  },
  {
    profile_section_id: 7074,
    name: "PIP35510.0",
    type: "Pipe",
    designation: "PIP35510.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "108,6",
    d_global: "355,6",
    i_global: 16220,
    t_global: "12,5",
    z_global: 1195,
  },
  {
    profile_section_id: 7075,
    name: "PIP24416.0",
    type: "Pipe",
    designation: "PIP24416.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "114,9",
    d_global: "244,5",
    i_global: 7533,
    t_global: 16,
    z_global: "836,8",
  },
  {
    profile_section_id: 7076,
    name: "PIP32312.5",
    type: "Pipe",
    designation: "PIP32312.5",
    shape: "PIPE",
    country_code: "French",
    ax_global: "122,3",
    d_global: 323,
    i_global: 14850,
    t_global: "12,5",
    z_global: 1213,
  },
  {
    profile_section_id: 7077,
    name: "PIP27316.0",
    type: "Pipe",
    designation: "PIP27316.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "129,2",
    d_global: 273,
    i_global: 10710,
    t_global: "12,5",
    z_global: 1058,
  },
  {
    profile_section_id: 7078,
    name: "PIP35512.5",
    type: "Pipe",
    designation: "PIP35512.5",
    shape: "PIPE",
    country_code: "French",
    ax_global: "134,7",
    d_global: "355,6",
    i_global: 19850,
    t_global: 16,
    z_global: 1472,
  },
  {
    profile_section_id: 7079,
    name: "PIP40612.5",
    type: "Pipe",
    designation: "PIP40612.5",
    shape: "PIPE",
    country_code: "French",
    ax_global: "154,7",
    d_global: "406,4",
    i_global: 30030,
    t_global: 16,
    z_global: 1940,
  },
  {
    profile_section_id: 7080,
    name: "PIP32316.0",
    type: "Pipe",
    designation: "PIP32316.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "154,8",
    d_global: 323,
    i_global: 18390,
    t_global: 16,
    z_global: 1518,
  },
  {
    profile_section_id: 7081,
    name: "PIP35516.0",
    type: "Pipe",
    designation: "PIP35516.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "170,7",
    d_global: "355,6",
    i_global: 24660,
    z_global: 1847,
  },
  {
    profile_section_id: 7082,
    name: "PIP40616.0",
    type: "Pipe",
    designation: "PIP40616.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "196,2",
    d_global: "406,4",
    i_global: 37450,
    z_global: 2440,
  },
  {
    profile_section_id: 7083,
    name: "PIP212.3",
    type: "Pipe",
    designation: "PIP212.3",
    shape: "PIPE",
    country_code: "French",
    ax_global: "1,37",
    d_global: "21,3",
    i_global: "0,63",
    t_global: "2,6",
    z_global: "0,83",
  },
  {
    profile_section_id: 7084,
    name: "PIP262.3",
    type: "Pipe",
    designation: "PIP262.3",
    shape: "PIPE",
    country_code: "French",
    ax_global: "1,79",
    d_global: "26,9",
    i_global: "1,36",
    t_global: "3,2",
    z_global: "1,4",
  },
  {
    profile_section_id: 7085,
    name: "PIP482.9",
    type: "Pipe",
    designation: "PIP482.9",
    shape: "PIPE",
    country_code: "French",
    ax_global: "4,14",
    d_global: "48,3",
    i_global: "10,7",
    t_global: "2,9",
    z_global: "5,99",
  },
  {
    profile_section_id: 7086,
    name: "PIP602.9",
    type: "Pipe",
    designation: "PIP602.9",
    shape: "PIPE",
    country_code: "French",
    ax_global: "5,23",
    d_global: "60,3",
    i_global: "21,59",
    t_global: "2,9",
    z_global: "9,56",
  },
  {
    profile_section_id: 7087,
    name: "PIP762.9",
    type: "Pipe",
    designation: "PIP762.9",
    shape: "PIPE",
    country_code: "French",
    ax_global: "6,67",
    d_global: "76,1",
    i_global: "44,74",
    t_global: "3,2",
    z_global: "15,55",
  },
  {
    profile_section_id: 7088,
    name: "PIP1013.6",
    type: "Pipe",
    designation: "PIP1013.6",
    shape: "PIPE",
    country_code: "French",
    ax_global: "11,08",
    d_global: "101,6",
    i_global: "133,2",
    t_global: 4,
    z_global: "34,59",
  },
  {
    profile_section_id: 7089,
    name: "PIP1933.6",
    type: "Pipe",
    designation: "PIP1933.6",
    shape: "PIPE",
    country_code: "French",
    ax_global: "21,5",
    d_global: "193,7",
    i_global: "971,5",
    t_global: "3,6",
    z_global: "130,1",
  },
  {
    profile_section_id: 7090,
    name: "PIP1684.5",
    type: "Pipe",
    designation: "PIP1684.5",
    shape: "PIPE",
    country_code: "French",
    ax_global: "23,16",
    d_global: "168,3",
    i_global: "777,2",
    t_global: "3,6",
    z_global: "120,8",
  },
  {
    profile_section_id: 7091,
    name: "PIP2193.6",
    type: "Pipe",
    designation: "PIP2193.6",
    shape: "PIPE",
    country_code: "French",
    ax_global: "24,37",
    d_global: "219,1",
    i_global: 1415,
    t_global: "6,3",
    z_global: "167,2",
  },
  {
    profile_section_id: 7092,
    name: "PIP2733.6",
    type: "Pipe",
    designation: "PIP2733.6",
    shape: "PIPE",
    country_code: "French",
    ax_global: "30,47",
    d_global: 273,
    i_global: 2765,
    t_global: "6,3",
    z_global: "261,3",
  },
  {
    profile_section_id: 7093,
    name: "PIP3234.0",
    type: "Pipe",
    designation: "PIP3234.0",
    shape: "PIPE",
    country_code: "French",
    ax_global: "40,2",
    d_global: 323,
    i_global: 5143,
    t_global: "6,3",
    z_global: "409,4",
  },
  {
    profile_section_id: 7094,
    name: "PIP4064.5",
    type: "Pipe",
    designation: "PIP4064.5",
    shape: "PIPE",
    country_code: "French",
    ax_global: "56,82",
    d_global: "406,4",
    i_global: 11470,
    t_global: 8,
    z_global: "726,9",
  },
  {
    profile_section_id: 7095,
    name: "PIP422.0",
    type: "Pipe",
    designation: "PIP422.0",
    shape: "PIPE",
    country_code: "German",
    ax_global: "2,54",
    d_global: "42,4",
    i_global: "5,19",
    t_global: 2,
    z_global: "3,26",
  },
  {
    profile_section_id: 7096,
    name: "PIP482.3",
    type: "Pipe",
    designation: "PIP482.3",
    shape: "PIPE",
    country_code: "German",
    ax_global: "3,32",
    d_global: "48,3",
    i_global: "8,81",
    t_global: "2,3",
    z_global: "4,87",
  },
  {
    profile_section_id: 7097,
    name: "PIP482.6",
    type: "Pipe",
    designation: "PIP482.6",
    shape: "PIPE",
    country_code: "German",
    ax_global: "3,73",
    d_global: "48,3",
    i_global: "9,78",
    t_global: "2,6",
    z_global: "5,43",
  },
  {
    profile_section_id: 7098,
    name: "PIP602.3",
    type: "Pipe",
    designation: "PIP602.3",
    shape: "PIPE",
    country_code: "German",
    ax_global: "4,19",
    d_global: "60,3",
    i_global: "17,65",
    t_global: "2,3",
    z_global: "7,74",
  },
  {
    profile_section_id: 7099,
    name: "PIP602.9",
    type: "Pipe",
    designation: "PIP602.9",
    shape: "PIPE",
    country_code: "German",
    ax_global: "5,23",
    d_global: "60,3",
    i_global: "21,59",
    t_global: "2,9",
    z_global: "9,55",
  },
  {
    profile_section_id: 7100,
    name: "PIP425.0",
    type: "Pipe",
    designation: "PIP425.0",
    shape: "PIPE",
    country_code: "German",
    ax_global: "5,87",
    d_global: "42,4",
    i_global: "10,46",
    t_global: 5,
    z_global: "6,99",
  },
  {
    profile_section_id: 7101,
    name: "PIP762.6",
    type: "Pipe",
    designation: "PIP762.6",
    shape: "PIPE",
    country_code: "German",
    ax_global: 6,
    d_global: "76,1",
    i_global: "40,59",
    t_global: "2,6",
    z_global: "14,05",
  },
  {
    profile_section_id: 7102,
    name: "PIP762.9",
    type: "Pipe",
    designation: "PIP762.9",
    shape: "PIPE",
    country_code: "German",
    ax_global: "6,67",
    d_global: "76,1",
    i_global: "44,74",
    t_global: "2,9",
    z_global: "15,54",
  },
  {
    profile_section_id: 7103,
    name: "PIP882.9",
    type: "Pipe",
    designation: "PIP882.9",
    shape: "PIPE",
    country_code: "German",
    ax_global: "7,84",
    d_global: "88,9",
    i_global: "72,52",
    t_global: "2,9",
    z_global: "21,45",
  },
  {
    profile_section_id: 7104,
    name: "PIP1012.9",
    type: "Pipe",
    designation: "PIP1012.9",
    shape: "PIPE",
    country_code: "German",
    ax_global: "8,99",
    d_global: "101,6",
    i_global: "109,59",
    t_global: "2,9",
    z_global: "28,25",
  },
  {
    profile_section_id: 7105,
    name: "PIP1013.6",
    type: "Pipe",
    designation: "PIP1013.6",
    shape: "PIPE",
    country_code: "German",
    ax_global: "11,08",
    d_global: "101,6",
    i_global: "133,24",
    t_global: "3,6",
    z_global: "34,57",
  },
  {
    profile_section_id: 7106,
    name: "PIP885.6",
    type: "Pipe",
    designation: "PIP885.6",
    shape: "PIPE",
    country_code: "German",
    ax_global: "14,65",
    d_global: "88,9",
    i_global: "127,69",
    t_global: "5,6",
    z_global: "38,86",
  },
  {
    profile_section_id: 7107,
    name: "PIP1016.3",
    type: "Pipe",
    designation: "PIP1016.3",
    shape: "PIPE",
    country_code: "German",
    ax_global: "18,86",
    d_global: "101,6",
    i_global: "215,07",
    t_global: "6,3",
    z_global: "57,22",
  },
  {
    profile_section_id: 7108,
    name: "PIP1684.5",
    type: "Pipe",
    designation: "PIP1684.5",
    shape: "PIPE",
    country_code: "German",
    ax_global: "23,16",
    d_global: "168,3",
    i_global: "777,22",
    t_global: "4,5",
    z_global: "120,74",
  },
  {
    profile_section_id: 7109,
    name: "PIP1147.1",
    type: "Pipe",
    designation: "PIP1147.1",
    shape: "PIPE",
    country_code: "German",
    ax_global: "23,91",
    d_global: "114,3",
    i_global: "344,99",
    t_global: "7,1",
    z_global: "81,59",
  },
  {
    profile_section_id: 7110,
    name: "PIP1934.5",
    type: "Pipe",
    designation: "PIP1934.5",
    shape: "PIPE",
    country_code: "German",
    ax_global: "26,75",
    d_global: "193,7",
    i_global: "1197,52",
    t_global: "4,5",
    z_global: "161,09",
  },
  {
    profile_section_id: 7111,
    name: "PIP1397.1",
    type: "Pipe",
    designation: "PIP1397.1",
    shape: "PIPE",
    country_code: "German",
    ax_global: "29,58",
    d_global: "139,7",
    i_global: "651,92",
    t_global: "7,1",
    z_global: "124,84",
  },
  {
    profile_section_id: 7112,
    name: "PIP2194.5",
    type: "Pipe",
    designation: "PIP2194.5",
    shape: "PIPE",
    country_code: "German",
    ax_global: "30,34",
    d_global: "219,1",
    i_global: "1747,24",
    t_global: "4,5",
    z_global: "207,24",
  },
  {
    profile_section_id: 7113,
    name: "PIP1935.4",
    type: "Pipe",
    designation: "PIP1935.4",
    shape: "PIPE",
    country_code: "German",
    ax_global: "31,94",
    d_global: "193,7",
    i_global: "1416,97",
    t_global: "5,4",
    z_global: "191,47",
  },
  {
    profile_section_id: 7114,
    name: "PIP2195.9",
    type: "Pipe",
    designation: "PIP2195.9",
    shape: "PIPE",
    country_code: "German",
    ax_global: "39,52",
    d_global: "219,1",
    i_global: "2247,01",
    t_global: "5,9",
    z_global: "268,18",
  },
  {
    profile_section_id: 7115,
    name: "PIP1938.8",
    type: "Pipe",
    designation: "PIP1938.8",
    shape: "PIPE",
    country_code: "German",
    ax_global: "51,12",
    d_global: "193,7",
    i_global: "2189,45",
    t_global: "8,8",
    z_global: "300,85",
  },
  {
    profile_section_id: 7116,
    name: "PIP3235.6",
    type: "Pipe",
    designation: "PIP3235.6",
    shape: "PIPE",
    country_code: "German",
    ax_global: 56,
    d_global: "323,9",
    i_global: "7094,02",
    t_global: "5,6",
    z_global: "567,36",
  },
  {
    profile_section_id: 7117,
    name: "PIP3555.6",
    type: "Pipe",
    designation: "PIP3555.6",
    shape: "PIPE",
    country_code: "German",
    ax_global: "61,58",
    d_global: "355,6",
    i_global: "9431,12",
    t_global: "5,6",
    z_global: 686,
  },
  {
    profile_section_id: 7118,
    name: "PIP3237.1",
    type: "Pipe",
    designation: "PIP3237.1",
    shape: "PIPE",
    country_code: "German",
    ax_global: "70,66",
    d_global: "323,9",
    i_global: "8869,35",
    t_global: "7,1",
    z_global: "712,57",
  },
  {
    profile_section_id: 7119,
    name: "PIP4068.8",
    type: "Pipe",
    designation: "PIP4068.8",
    shape: "PIPE",
    country_code: "German",
    ax_global: "109,92",
    d_global: "406,4",
    i_global: "21731,74",
    t_global: "8,8",
    z_global: "1391,15",
  },
  {
    profile_section_id: 7538,
    name: "OCE21",
    type: "OC Pipes",
    designation: "OCE21",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "1,61",
    d_global: "2,13",
    i_global: "0,71",
    t_global: "0,28",
    z_global: "0,97",
  },
  {
    profile_section_id: 7539,
    name: "OCXE21",
    type: "OC Pipes",
    designation: "OCXE21",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "2,06",
    d_global: "2,13",
    i_global: "0,83",
    t_global: "0,37",
    z_global: "1,18",
  },
  {
    profile_section_id: 7540,
    name: "OCE27",
    type: "OC Pipes",
    designation: "OCE27",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "2,15",
    d_global: "2,67",
    i_global: "1,54",
    t_global: "0,29",
    z_global: "1,64",
  },
  {
    profile_section_id: 7541,
    name: "OCXE27",
    type: "OC Pipes",
    designation: "OCXE27",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "2,79",
    d_global: "2,67",
    i_global: "1,87",
    t_global: "0,39",
    z_global: "2,05",
  },
  {
    profile_section_id: 7542,
    name: "OCE33",
    type: "OC Pipes",
    designation: "OCE33",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "3,19",
    d_global: "3,34",
    i_global: "3,62",
    t_global: "0,34",
    z_global: "3,06",
  },
  {
    profile_section_id: 7543,
    name: "OCXE33",
    type: "OC Pipes",
    designation: "OCXE33",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "4,12",
    d_global: "3,34",
    i_global: "4,41",
    t_global: "0,45",
    z_global: "3,82",
  },
  {
    profile_section_id: 7544,
    name: "OCE42",
    type: "OC Pipes",
    designation: "OCE42",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "4,32",
    d_global: "4,22",
    i_global: "8,12",
    t_global: "0,36",
    z_global: "5,31",
  },
  {
    profile_section_id: 7545,
    name: "OCXE42",
    type: "OC Pipes",
    designation: "OCXE42",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "5,68",
    d_global: "4,22",
    i_global: "10,07",
    t_global: "0,49",
    z_global: "6,78",
  },
  {
    profile_section_id: 7546,
    name: "OCE48",
    type: "OC Pipes",
    designation: "OCE48",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "5,15",
    d_global: "4,83",
    i_global: "12,9",
    t_global: "0,37",
    z_global: "7,34",
  },
  {
    profile_section_id: 7547,
    name: "OCXE48",
    type: "OC Pipes",
    designation: "OCXE48",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "6,9",
    d_global: "4,83",
    i_global: "16,27",
    t_global: "0,51",
    z_global: "9,52",
  },
  {
    profile_section_id: 7548,
    name: "OCE60",
    type: "OC Pipes",
    designation: "OCE60",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "6,9",
    d_global: "6,03",
    i_global: "27,72",
    t_global: "0,39",
    z_global: "12,47",
  },
  {
    profile_section_id: 7549,
    name: "OCXE60",
    type: "OC Pipes",
    designation: "OCXE60",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "9,55",
    d_global: "6,03",
    i_global: "36,13",
    t_global: "0,55",
    z_global: "16,71",
  },
  {
    profile_section_id: 7550,
    name: "OCXXE60",
    type: "OC Pipes",
    designation: "OCXXE60",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "17,16",
    d_global: "6,03",
    i_global: "54,53",
    t_global: "1,11",
    z_global: "27,37",
  },
  {
    profile_section_id: 7551,
    name: "OCE73",
    type: "OC Pipes",
    designation: "OCE73",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "10,97",
    d_global: "7,3",
    i_global: "63,68",
    t_global: "0,52",
    z_global: "23,76",
  },
  {
    profile_section_id: 7552,
    name: "OCXE73",
    type: "OC Pipes",
    designation: "OCXE73",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "14,58",
    d_global: "7,3",
    i_global: "79,92",
    t_global: "0,7",
    z_global: "30,64",
  },
  {
    profile_section_id: 7553,
    name: "OCXXE73",
    type: "OC Pipes",
    designation: "OCXXE73",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: 26,
    d_global: "7,3",
    i_global: "119,46",
    t_global: "1,4",
    z_global: "49,82",
  },
  {
    profile_section_id: 7554,
    name: "OCE89",
    type: "OC Pipes",
    designation: "OCE89",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "14,39",
    d_global: "8,89",
    i_global: "125,7",
    t_global: "0,55",
    z_global: "38,18",
  },
  {
    profile_section_id: 7555,
    name: "OCXE89",
    type: "OC Pipes",
    designation: "OCXE89",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "19,48",
    d_global: "8,89",
    i_global: "161,91",
    t_global: "0,76",
    z_global: "50,47",
  },
  {
    profile_section_id: 7556,
    name: "OCXXE89",
    type: "OC Pipes",
    designation: "OCXXE89",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "35,29",
    d_global: "8,89",
    i_global: "249,32",
    t_global: "1,52",
    z_global: "83,9",
  },
  {
    profile_section_id: 7557,
    name: "OCE102",
    type: "OC Pipes",
    designation: "OCE102",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "17,29",
    d_global: "10,16",
    i_global: "199,37",
    t_global: "0,57",
    z_global: "52,77",
  },
  {
    profile_section_id: 7558,
    name: "OCXE102",
    type: "OC Pipes",
    designation: "OCXE102",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "23,74",
    d_global: "10,16",
    i_global: "261,39",
    t_global: "0,81",
    z_global: "70,79",
  },
  {
    profile_section_id: 7559,
    name: "OCE114",
    type: "OC Pipes",
    designation: "OCE114",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "20,45",
    d_global: "11,43",
    i_global: "300,94",
    t_global: "0,6",
    z_global: "70,63",
  },
  {
    profile_section_id: 7560,
    name: "OCEXE114",
    type: "OC Pipes",
    designation: "OCEXE114",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "28,45",
    d_global: "11,43",
    i_global: 400,
    t_global: "0,86",
    z_global: "95,86",
  },
  {
    profile_section_id: 7561,
    name: "OCEXXE114",
    type: "OC Pipes",
    designation: "OCEXXE114",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "52,26",
    d_global: "11,43",
    i_global: "636,83",
    t_global: "1,64",
    z_global: "163,38",
  },
  {
    profile_section_id: 7562,
    name: "OCE141",
    type: "OC Pipes",
    designation: "OCE141",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "27,74",
    d_global: "14,13",
    i_global: "632,67",
    t_global: "0,66",
    z_global: "119,13",
  },
  {
    profile_section_id: 7563,
    name: "OCXE141",
    type: "OC Pipes",
    designation: "OCXE141",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "39,42",
    d_global: "14,13",
    i_global: "861,6",
    t_global: "0,95",
    z_global: "165,51",
  },
  {
    profile_section_id: 7564,
    name: "OCXXE141",
    type: "OC Pipes",
    designation: "OCXXE141",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "72,9",
    d_global: "14,13",
    i_global: "1398,54",
    t_global: "1,91",
    z_global: "286,77",
  },
  {
    profile_section_id: 7565,
    name: "OCE168",
    type: "OC Pipes",
    designation: "OCE168",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: 36,
    d_global: "16,83",
    i_global: "1169,61",
    t_global: "0,71",
    z_global: "183,54",
  },
  {
    profile_section_id: 7566,
    name: "OCXE168",
    type: "OC Pipes",
    designation: "OCXE168",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "54,19",
    d_global: "16,83",
    i_global: "1685,74",
    t_global: "1,1",
    z_global: "272,03",
  },
  {
    profile_section_id: 7567,
    name: "OCXXE168",
    type: "OC Pipes",
    designation: "OCXXE168",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "100,64",
    d_global: "16,83",
    i_global: "2759,61",
    t_global: "2,19",
    z_global: "473,59",
  },
  {
    profile_section_id: 7568,
    name: "OCE219",
    type: "OC Pipes",
    designation: "OCE219",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "54,19",
    d_global: "21,91",
    i_global: "3017,68",
    t_global: "0,82",
    z_global: "363,79",
  },
  {
    profile_section_id: 7569,
    name: "OCXE219",
    type: "OC Pipes",
    designation: "OCXE219",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "82,58",
    d_global: "21,91",
    i_global: "4412,05",
    t_global: "1,27",
    z_global: "540,77",
  },
  {
    profile_section_id: 7570,
    name: "OCXXE219",
    type: "OC Pipes",
    designation: "OCXXE219",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "137,42",
    d_global: "21,91",
    i_global: "6742,95",
    t_global: "2,22",
    z_global: "865,24",
  },
  {
    profile_section_id: 7571,
    name: "OCE273",
    type: "OC Pipes",
    designation: "OCE273",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "76,77",
    d_global: "27,3",
    i_global: "6701,33",
    t_global: "0,93",
    z_global: "645,65",
  },
  {
    profile_section_id: 7572,
    name: "OCXE273",
    type: "OC Pipes",
    designation: "OCXE273",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "103,87",
    d_global: "27,3",
    i_global: "8824,11",
    t_global: "1,27",
    z_global: "861,96",
  },
  {
    profile_section_id: 7573,
    name: "OCE324",
    type: "OC Pipes",
    designation: "OCE324",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "94,19",
    d_global: "32,38",
    i_global: "11612,86",
    t_global: "0,95",
    z_global: "940,62",
  },
  {
    profile_section_id: 7574,
    name: "OCXE324",
    type: "OC Pipes",
    designation: "OCXE324",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "123,87",
    d_global: "32,38",
    i_global: "15067,58",
    t_global: "1,27",
    z_global: "1230,67",
  },
  {
    profile_section_id: 7575,
    name: "OCE356",
    type: "OC Pipes",
    designation: "OCE356",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "103,61",
    d_global: "35,56",
    i_global: "1522,9",
    t_global: "0,95",
    z_global: "1141,08",
  },
  {
    profile_section_id: 7576,
    name: "OCXE356",
    type: "OC Pipes",
    designation: "OCXE356",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "136,81",
    d_global: "35,56",
    i_global: "20135,5",
    t_global: "1,27",
    z_global: "1493,95",
  },
  {
    profile_section_id: 7577,
    name: "OCE406",
    type: "OC Pipes",
    designation: "OCE406",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "118,82",
    d_global: "40,64",
    i_global: "23407,2",
    t_global: "0,95",
    z_global: "1500,57",
  },
  {
    profile_section_id: 7578,
    name: "OCXE406",
    type: "OC Pipes",
    designation: "OCXE406",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "157,08",
    d_global: "40,64",
    i_global: "30465,7",
    t_global: "1,27",
    z_global: "1969,18",
  },
  {
    profile_section_id: 7579,
    name: "OCXE457",
    type: "OC Pipes",
    designation: "OCXE457",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "177,27",
    d_global: "45,72",
    i_global: "43777,3",
    t_global: "1,27",
    z_global: "2509,95",
  },
  {
    profile_section_id: 7580,
    name: "OCXE508",
    type: "OC Pipes",
    designation: "OCXE508",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "197,62",
    d_global: "50,8",
    i_global: "60639,4",
    t_global: "1,27",
    z_global: "3116,27",
  },
  {
    profile_section_id: 7581,
    name: "OCE610",
    type: "OC Pipes",
    designation: "OCE610",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "179,77",
    d_global: "60,96",
    i_global: "81045,4",
    t_global: "0,95",
    z_global: "3430,14",
  },
  {
    profile_section_id: 7582,
    name: "OCXE610",
    type: "OC Pipes",
    designation: "OCXE610",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "238,31",
    d_global: "60,96",
    i_global: 106325,
    t_global: "1,27",
    z_global: "4525,56",
  },
  {
    profile_section_id: 7583,
    name: "OCE762",
    type: "OC Pipes",
    designation: "OCE762",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "187,63",
    d_global: "76,2",
    i_global: "133377,3",
    t_global: "0,79",
    z_global: "4506,44",
  },
  {
    profile_section_id: 7584,
    name: "OCXE762",
    type: "OC Pipes",
    designation: "OCXE762",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "225,28",
    d_global: "76,2",
    i_global: "159472,8",
    t_global: "0,95",
    z_global: "5393,52",
  },
  {
    profile_section_id: 7585,
    name: "OCE914",
    type: "OC Pipes",
    designation: "OCE914",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "270,79",
    d_global: "91,44",
    i_global: "276936,9",
    t_global: "0,95",
    z_global: "7799,35",
  },
  {
    profile_section_id: 7586,
    name: "OCXE914",
    type: "OC Pipes",
    designation: "OCXE914",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "359,6",
    d_global: "91,44",
    i_global: 365220,
    t_global: "1,27",
    z_global: "10326,58",
  },
  {
    profile_section_id: 7587,
    name: "OCE1067",
    type: "OC Pipes",
    designation: "OCE1067",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "316,6",
    d_global: "106,68",
    i_global: 442577,
    t_global: "0,95",
    z_global: "10647,62",
  },
  {
    profile_section_id: 7588,
    name: "OCXE1067",
    type: "OC Pipes",
    designation: "OCXE1067",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "420,65",
    d_global: "106,68",
    i_global: "584542,1",
    t_global: "1,27",
    z_global: "14111,99",
  },
  {
    profile_section_id: 7589,
    name: "OCE1219",
    type: "OC Pipes",
    designation: "OCE1219",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "363,11",
    d_global: "121,92",
    i_global: "662152,3",
    t_global: "0,95",
    z_global: "13938,35",
  },
  {
    profile_section_id: 7590,
    name: "OCXE1219",
    type: "OC Pipes",
    designation: "OCXE1219",
    shape: "OC Pipes",
    country_code: "Mexican",
    ax_global: "481,29",
    d_global: "121,92",
    i_global: "875536,6",
    t_global: "1,27",
    z_global: "18487,34",
  },
  {
    profile_section_id: 8867,
    name: "PIP32X2.0CHS",
    type: "Pipe",
    designation: "PIP32X2.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "1,88",
    d_global: 32,
    i_global: "2,1",
    t_global: 2,
    z_global: "1,33",
  },
  {
    profile_section_id: 8868,
    name: "PIP34X2.0CHS",
    type: "Pipe",
    designation: "PIP34X2.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "2,01",
    d_global: 34,
    i_global: "2,6",
    t_global: 2,
    z_global: "1,52",
  },
  {
    profile_section_id: 8869,
    name: "PIP38X2.0CHS",
    type: "Pipe",
    designation: "PIP38X2.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "2,26",
    d_global: 38,
    i_global: "3,7",
    t_global: 2,
    z_global: "1,93",
  },
  {
    profile_section_id: 8870,
    name: "PIP32X2.5CHS",
    type: "Pipe",
    designation: "PIP32X2.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "2,32",
    d_global: 32,
    i_global: "2,5",
    t_global: "2,5",
    z_global: "1,59",
  },
  {
    profile_section_id: 8871,
    name: "PIP34X2.5CHS",
    type: "Pipe",
    designation: "PIP34X2.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "2,47",
    d_global: 34,
    i_global: "3,1",
    t_global: "2,5",
    z_global: "1,82",
  },
  {
    profile_section_id: 8872,
    name: "PIP43X2.0CHS",
    type: "Pipe",
    designation: "PIP43X2.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "2,58",
    d_global: 43,
    i_global: "5,4",
    t_global: 2,
    z_global: "2,52",
  },
  {
    profile_section_id: 8873,
    name: "PIP38X2.5CHS",
    type: "Pipe",
    designation: "PIP38X2.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "2,79",
    d_global: 38,
    i_global: "4,4",
    t_global: "2,5",
    z_global: "2,32",
  },
  {
    profile_section_id: 8874,
    name: "PIP48X2.0CHS",
    type: "Pipe",
    designation: "PIP48X2.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "2,89",
    d_global: 48,
    i_global: "7,7",
    t_global: 2,
    z_global: "3,19",
  },
  {
    profile_section_id: 8875,
    name: "PIP34X3.0CHS",
    type: "Pipe",
    designation: "PIP34X3.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "2,92",
    d_global: 34,
    i_global: "3,5",
    t_global: 3,
    z_global: "2,08",
  },
  {
    profile_section_id: 8876,
    name: "PIP51X2.0CHS",
    type: "Pipe",
    designation: "PIP51X2.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "3,08",
    d_global: 51,
    i_global: "9,3",
    t_global: 2,
    z_global: "3,63",
  },
  {
    profile_section_id: 8877,
    name: "PIP43X2.5CHS",
    type: "Pipe",
    designation: "PIP43X2.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "3,18",
    d_global: 43,
    i_global: "6,6",
    t_global: "2,5",
    z_global: "3,04",
  },
  {
    profile_section_id: 8878,
    name: "PIP38X3.0CHS",
    type: "Pipe",
    designation: "PIP38X3.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "3,3",
    d_global: 38,
    i_global: "5,1",
    t_global: 3,
    z_global: "2,68",
  },
  {
    profile_section_id: 8879,
    name: "PIP48X2.5CHS",
    type: "Pipe",
    designation: "PIP48X2.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "3,57",
    d_global: 48,
    i_global: "9,3",
    t_global: "2,5",
    z_global: "3,86",
  },
  {
    profile_section_id: 8880,
    name: "PIP60X2.0CHS",
    type: "Pipe",
    designation: "PIP60X2.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "3,64",
    d_global: 60,
    i_global: "15,3",
    t_global: 2,
    z_global: "5,11",
  },
  {
    profile_section_id: 8881,
    name: "PIP34X4.0CHS",
    type: "Pipe",
    designation: "PIP34X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "3,77",
    d_global: 34,
    i_global: "4,3",
    t_global: 4,
    z_global: "2,54",
  },
  {
    profile_section_id: 8882,
    name: "PIP43X3.0CHS",
    type: "Pipe",
    designation: "PIP43X3.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "3,77",
    d_global: 43,
    i_global: "7,6",
    t_global: 3,
    z_global: "3,53",
  },
  {
    profile_section_id: 8883,
    name: "PIP51X2.5CHS",
    type: "Pipe",
    designation: "PIP51X2.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "3,81",
    d_global: 51,
    i_global: "11,2",
    t_global: "2,5",
    z_global: "4,4",
  },
  {
    profile_section_id: 8884,
    name: "PIP63X2.0CHS",
    type: "Pipe",
    designation: "PIP63X2.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "3,83",
    d_global: 63,
    i_global: "17,8",
    t_global: 2,
    z_global: "5,67",
  },
  {
    profile_section_id: 8885,
    name: "PIP48X3.0CHS",
    type: "Pipe",
    designation: "PIP48X3.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "4,24",
    d_global: 48,
    i_global: "10,8",
    t_global: 3,
    z_global: "4,49",
  },
  {
    profile_section_id: 8886,
    name: "PIP38X4.0CHS",
    type: "Pipe",
    designation: "PIP38X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "4,27",
    d_global: 38,
    i_global: "6,3",
    t_global: 4,
    z_global: "3,29",
  },
  {
    profile_section_id: 8887,
    name: "PIP51X3.0CHS",
    type: "Pipe",
    designation: "PIP51X3.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "4,52",
    d_global: 51,
    i_global: "13,1",
    t_global: 3,
    z_global: "5,13",
  },
  {
    profile_section_id: 8888,
    name: "PIP60X2.5CHS",
    type: "Pipe",
    designation: "PIP60X2.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "4,52",
    d_global: 60,
    i_global: "18,7",
    t_global: "2,5",
    z_global: "6,23",
  },
  {
    profile_section_id: 8889,
    name: "PIP63X2.5CHS",
    type: "Pipe",
    designation: "PIP63X2.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "4,75",
    d_global: 63,
    i_global: "21,8",
    t_global: "2,5",
    z_global: "6,91",
  },
  {
    profile_section_id: 8890,
    name: "PIP48X3.5CHS",
    type: "Pipe",
    designation: "PIP48X3.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "4,89",
    d_global: 48,
    i_global: "12,2",
    t_global: "3,5",
    z_global: "5,08",
  },
  {
    profile_section_id: 8891,
    name: "PIP43X4.0CHS",
    type: "Pipe",
    designation: "PIP43X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "4,9",
    d_global: 43,
    i_global: "9,4",
    t_global: 4,
    z_global: "4,38",
  },
  {
    profile_section_id: 8892,
    name: "PIP51X3.5CHS",
    type: "Pipe",
    designation: "PIP51X3.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "5,22",
    d_global: 51,
    i_global: "14,8",
    t_global: "3,5",
    z_global: "5,81",
  },
  {
    profile_section_id: 8893,
    name: "PIP60X3.0CHS",
    type: "Pipe",
    designation: "PIP60X3.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "5,37",
    d_global: 60,
    i_global: "21,9",
    t_global: 3,
    z_global: "7,29",
  },
  {
    profile_section_id: 8894,
    name: "PIP43X4.5CHS",
    type: "Pipe",
    designation: "PIP43X4.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "5,44",
    d_global: 43,
    i_global: "10,2",
    t_global: "4,5",
    z_global: "4,75",
  },
  {
    profile_section_id: 8895,
    name: "PIP48X4.0CHS",
    type: "Pipe",
    designation: "PIP48X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "5,53",
    d_global: 48,
    i_global: "13,5",
    t_global: 4,
    z_global: "5,62",
  },
  {
    profile_section_id: 8896,
    name: "PIP63X3.0CHS",
    type: "Pipe",
    designation: "PIP63X3.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "5,65",
    d_global: 63,
    i_global: "25,5",
    t_global: 3,
    z_global: "8,1",
  },
  {
    profile_section_id: 8897,
    name: "PIP76X2.5CHS",
    type: "Pipe",
    designation: "PIP76X2.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "5,77",
    d_global: 76,
    i_global: 39,
    t_global: "2,5",
    z_global: "10,3",
  },
  {
    profile_section_id: 8898,
    name: "PIP51X4.0CHS",
    type: "Pipe",
    designation: "PIP51X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "5,91",
    d_global: 51,
    i_global: "16,4",
    t_global: 4,
    z_global: "6,44",
  },
  {
    profile_section_id: 8899,
    name: "PIP60X3.5CHS",
    type: "Pipe",
    designation: "PIP60X3.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "6,21",
    d_global: 60,
    i_global: "24,9",
    t_global: "3,5",
    z_global: "8,29",
  },
  {
    profile_section_id: 8900,
    name: "PIP63X3.5CHS",
    type: "Pipe",
    designation: "PIP63X3.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "6,54",
    d_global: 63,
    i_global: "29,1",
    t_global: "3,5",
    z_global: "9,22",
  },
  {
    profile_section_id: 8901,
    name: "PIP76X3.0CHS",
    type: "Pipe",
    designation: "PIP76X3.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "6,88",
    d_global: 76,
    i_global: "45,9",
    t_global: 3,
    z_global: "12,1",
  },
  {
    profile_section_id: 8902,
    name: "PIP60X4.0CHS",
    type: "Pipe",
    designation: "PIP60X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "7,04",
    d_global: 60,
    i_global: "27,7",
    t_global: 4,
    z_global: "9,24",
  },
  {
    profile_section_id: 8903,
    name: "PIP63X4.0CHS",
    type: "Pipe",
    designation: "PIP63X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "7,41",
    d_global: 63,
    i_global: "32,4",
    t_global: 4,
    z_global: "10,3",
  },
  {
    profile_section_id: 8904,
    name: "PIP76X3.5CHS",
    type: "Pipe",
    designation: "PIP76X3.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "7,97",
    d_global: 76,
    i_global: "52,5",
    t_global: "3,5",
    z_global: "13,8",
  },
  {
    profile_section_id: 8905,
    name: "PIP89X3.0CHS",
    type: "Pipe",
    designation: "PIP89X3.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "8,11",
    d_global: 89,
    i_global: 75,
    t_global: 3,
    z_global: "16,9",
  },
  {
    profile_section_id: 8906,
    name: "PIP63X4.5CHS",
    type: "Pipe",
    designation: "PIP63X4.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "8,27",
    d_global: 63,
    i_global: "35,6",
    t_global: "4,5",
    z_global: "11,3",
  },
  {
    profile_section_id: 8907,
    name: "PIP76X4.0CHS",
    type: "Pipe",
    designation: "PIP76X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "9,05",
    d_global: 76,
    i_global: "58,8",
    t_global: 4,
    z_global: "15,5",
  },
  {
    profile_section_id: 8908,
    name: "PIP102X3.0CHS",
    type: "Pipe",
    designation: "PIP102X3.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "9,33",
    d_global: 102,
    i_global: 114,
    t_global: 3,
    z_global: "22,4",
  },
  {
    profile_section_id: 8909,
    name: "PIP89X3.5CHS",
    type: "Pipe",
    designation: "PIP89X3.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "9,4",
    d_global: 89,
    i_global: "86,1",
    t_global: "3,5",
    z_global: "19,3",
  },
  {
    profile_section_id: 8910,
    name: "PIP89X4.0CHS",
    type: "Pipe",
    designation: "PIP89X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "10,7",
    d_global: 89,
    i_global: "96,7",
    t_global: 4,
    z_global: "21,7",
  },
  {
    profile_section_id: 8911,
    name: "PIP102X3.5CHS",
    type: "Pipe",
    designation: "PIP102X3.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "10,8",
    d_global: 102,
    i_global: 132,
    t_global: "3,5",
    z_global: "25,8",
  },
  {
    profile_section_id: 8912,
    name: "PIP76X5.0CHS",
    type: "Pipe",
    designation: "PIP76X5.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "11,2",
    d_global: 76,
    i_global: "70,6",
    t_global: 5,
    z_global: "18,6",
  },
  {
    profile_section_id: 8913,
    name: "PIP114X3.5CHS",
    type: "Pipe",
    designation: "PIP114X3.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "12,2",
    d_global: 114,
    i_global: 186,
    t_global: "3,5",
    z_global: "32,6",
  },
  {
    profile_section_id: 8914,
    name: "PIP102X4.0CHS",
    type: "Pipe",
    designation: "PIP102X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "12,3",
    d_global: 102,
    i_global: 148,
    t_global: 4,
    z_global: 29,
  },
  {
    profile_section_id: 8915,
    name: "PIP89X5.0CHS",
    type: "Pipe",
    designation: "PIP89X5.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "13,2",
    d_global: 89,
    i_global: 117,
    t_global: 5,
    z_global: "26,2",
  },
  {
    profile_section_id: 8916,
    name: "PIP114X4.0CHS",
    type: "Pipe",
    designation: "PIP114X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "13,8",
    d_global: 114,
    i_global: 209,
    t_global: 4,
    z_global: "36,7",
  },
  {
    profile_section_id: 8917,
    name: "PIP102X5.0CHS",
    type: "Pipe",
    designation: "PIP102X5.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "15,2",
    d_global: 102,
    i_global: 180,
    t_global: 5,
    z_global: "35,2",
  },
  {
    profile_section_id: 8918,
    name: "PIP127X4.0CHS",
    type: "Pipe",
    designation: "PIP127X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "15,5",
    d_global: 127,
    i_global: 293,
    t_global: 4,
    z_global: "46,1",
  },
  {
    profile_section_id: 8919,
    name: "PIP177X3.0CHS",
    type: "Pipe",
    designation: "PIP177X3.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "16,4",
    d_global: 177,
    i_global: 621,
    t_global: 3,
    z_global: "70,1",
  },
  {
    profile_section_id: 8920,
    name: "PIP114X5.0CHS",
    type: "Pipe",
    designation: "PIP114X5.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "17,1",
    d_global: 114,
    i_global: 255,
    t_global: 5,
    z_global: "44,7",
  },
  {
    profile_section_id: 8921,
    name: "PIP140X4.0CHS",
    type: "Pipe",
    designation: "PIP140X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "17,1",
    d_global: 140,
    i_global: 395,
    t_global: 4,
    z_global: "56,5",
  },
  {
    profile_section_id: 8922,
    name: "PIP102X6.0CHS",
    type: "Pipe",
    designation: "PIP102X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "18,1",
    d_global: 102,
    i_global: 209,
    t_global: 6,
    z_global: 41,
  },
  {
    profile_section_id: 8923,
    name: "PIP152X4.0CHS",
    type: "Pipe",
    designation: "PIP152X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "18,6",
    d_global: 152,
    i_global: 510,
    t_global: 4,
    z_global: "67,1",
  },
  {
    profile_section_id: 8924,
    name: "PIP127X5.0CHS",
    type: "Pipe",
    designation: "PIP127X5.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "19,2",
    d_global: 127,
    i_global: 357,
    t_global: 5,
    z_global: "56,2",
  },
  {
    profile_section_id: 8925,
    name: "PIP165X4.0CHS",
    type: "Pipe",
    designation: "PIP165X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "20,2",
    d_global: 165,
    i_global: 656,
    t_global: 4,
    z_global: "79,5",
  },
  {
    profile_section_id: 8926,
    name: "PIP114X6.0CHS",
    type: "Pipe",
    designation: "PIP114X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "20,4",
    d_global: 114,
    i_global: 298,
    t_global: 6,
    z_global: "52,2",
  },
  {
    profile_section_id: 8927,
    name: "PIP194X3.5CHS",
    type: "Pipe",
    designation: "PIP194X3.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "20,9",
    d_global: 194,
    i_global: 951,
    t_global: "3,5",
    z_global: 98,
  },
  {
    profile_section_id: 8928,
    name: "PIP140X5.0CHS",
    type: "Pipe",
    designation: "PIP140X5.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "21,2",
    d_global: 140,
    i_global: 484,
    t_global: 5,
    z_global: "69,1",
  },
  {
    profile_section_id: 8929,
    name: "PIP177X4.0CHS",
    type: "Pipe",
    designation: "PIP177X4.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "21,7",
    d_global: 177,
    i_global: 814,
    t_global: 4,
    z_global: "91,9",
  },
  {
    profile_section_id: 8930,
    name: "PIP127X6.0CHS",
    type: "Pipe",
    designation: "PIP127X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "22,8",
    d_global: 127,
    i_global: 418,
    t_global: 6,
    z_global: "65,9",
  },
  {
    profile_section_id: 8931,
    name: "PIP152X5.0CHS",
    type: "Pipe",
    designation: "PIP152X5.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "23,1",
    d_global: 152,
    i_global: 624,
    t_global: 5,
    z_global: "82,2",
  },
  {
    profile_section_id: 8932,
    name: "PIP219X3.5CHS",
    type: "Pipe",
    designation: "PIP219X3.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "23,7",
    d_global: 219,
    i_global: 1380,
    t_global: "3,5",
    z_global: 126,
  },
  {
    profile_section_id: 8933,
    name: "PIP177X4.5CHS",
    type: "Pipe",
    designation: "PIP177X4.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "24,4",
    d_global: 177,
    i_global: 908,
    t_global: "4,5",
    z_global: 103,
  },
  {
    profile_section_id: 8934,
    name: "PIP165X5.0CHS",
    type: "Pipe",
    designation: "PIP165X5.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "25,1",
    d_global: 165,
    i_global: 805,
    t_global: 5,
    z_global: "97,6",
  },
  {
    profile_section_id: 8935,
    name: "PIP140X6.0CHS",
    type: "Pipe",
    designation: "PIP140X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "25,3",
    d_global: 140,
    i_global: 568,
    t_global: 6,
    z_global: "81,2",
  },
  {
    profile_section_id: 8936,
    name: "PIP194X4.5CHS",
    type: "Pipe",
    designation: "PIP194X4.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "26,8",
    d_global: 194,
    i_global: 1200,
    t_global: "4,5",
    z_global: 124,
  },
  {
    profile_section_id: 8937,
    name: "PIP152X6.0CHS",
    type: "Pipe",
    designation: "PIP152X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "27,5",
    d_global: 152,
    i_global: 735,
    t_global: 6,
    z_global: "96,6",
  },
  {
    profile_section_id: 8938,
    name: "PIP165X6.0CHS",
    type: "Pipe",
    designation: "PIP165X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: 30,
    d_global: 165,
    i_global: 948,
    t_global: 6,
    z_global: 115,
  },
  {
    profile_section_id: 8939,
    name: "PIP219X4.5CHS",
    type: "Pipe",
    designation: "PIP219X4.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "30,3",
    d_global: 219,
    i_global: 1740,
    t_global: "4,5",
    z_global: 159,
  },
  {
    profile_section_id: 8940,
    name: "PIP194X6.0CHS",
    type: "Pipe",
    designation: "PIP194X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "35,4",
    d_global: 194,
    i_global: 1570,
    t_global: 6,
    z_global: 162,
  },
  {
    profile_section_id: 8941,
    name: "PIP273X4.5CHS",
    type: "Pipe",
    designation: "PIP273X4.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: 38,
    d_global: 273,
    i_global: 3420,
    t_global: "4,5",
    z_global: 251,
  },
  {
    profile_section_id: 8942,
    name: "PIP219X6.0CHS",
    type: "Pipe",
    designation: "PIP219X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "40,1",
    d_global: 219,
    i_global: 2280,
    t_global: 6,
    z_global: 208,
  },
  {
    profile_section_id: 8943,
    name: "PIP324X4.5CHS",
    type: "Pipe",
    designation: "PIP324X4.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "45,2",
    d_global: 324,
    i_global: 5760,
    t_global: "4,5",
    z_global: 356,
  },
  {
    profile_section_id: 8944,
    name: "PIP194X8.0CHS",
    type: "Pipe",
    designation: "PIP194X8.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "46,7",
    d_global: 194,
    i_global: 2030,
    t_global: 8,
    z_global: 209,
  },
  {
    profile_section_id: 8945,
    name: "PIP273X6.0CHS",
    type: "Pipe",
    designation: "PIP273X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "50,3",
    d_global: 273,
    i_global: 4490,
    t_global: 6,
    z_global: 329,
  },
  {
    profile_section_id: 8946,
    name: "PIP219X8.0CHS",
    type: "Pipe",
    designation: "PIP219X8.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: 53,
    d_global: 219,
    i_global: 2960,
    t_global: 8,
    z_global: 270,
  },
  {
    profile_section_id: 8947,
    name: "PIP194X9.5CHS",
    type: "Pipe",
    designation: "PIP194X9.5CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "55,1",
    d_global: 194,
    i_global: 2350,
    t_global: "9,5",
    z_global: 242,
  },
  {
    profile_section_id: 8948,
    name: "PIP324X6.0CHS",
    type: "Pipe",
    designation: "PIP324X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "59,9",
    d_global: 324,
    i_global: 7580,
    t_global: 6,
    z_global: 468,
  },
  {
    profile_section_id: 8949,
    name: "PIP219X10.0CHS",
    type: "Pipe",
    designation: "PIP219X10.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "65,7",
    d_global: 219,
    i_global: 3590,
    t_global: 10,
    z_global: 328,
  },
  {
    profile_section_id: 8950,
    name: "PIP356X6.0CHS",
    type: "Pipe",
    designation: "PIP356X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: 66,
    d_global: 356,
    i_global: 10100,
    t_global: 6,
    z_global: 568,
  },
  {
    profile_section_id: 8951,
    name: "PIP273X8.0CHS",
    type: "Pipe",
    designation: "PIP273X8.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "66,6",
    d_global: 273,
    i_global: 5850,
    t_global: 8,
    z_global: 429,
  },
  {
    profile_section_id: 8952,
    name: "PIP406X6.0CHS",
    type: "Pipe",
    designation: "PIP406X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "75,4",
    d_global: 406,
    i_global: 15100,
    t_global: 6,
    z_global: 743,
  },
  {
    profile_section_id: 8953,
    name: "PIP324X8.0CHS",
    type: "Pipe",
    designation: "PIP324X8.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "79,4",
    d_global: 324,
    i_global: 9920,
    t_global: 8,
    z_global: 612,
  },
  {
    profile_section_id: 8954,
    name: "PIP273X10.0CHS",
    type: "Pipe",
    designation: "PIP273X10.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "82,6",
    d_global: 273,
    i_global: 7150,
    t_global: 10,
    z_global: 524,
  },
  {
    profile_section_id: 8955,
    name: "PIP457X6.0CHS",
    type: "Pipe",
    designation: "PIP457X6.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: 85,
    d_global: 457,
    i_global: 21600,
    t_global: 6,
    z_global: 946,
  },
  {
    profile_section_id: 8956,
    name: "PIP356X8.0CHS",
    type: "Pipe",
    designation: "PIP356X8.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "87,5",
    d_global: 356,
    i_global: 13200,
    t_global: 8,
    z_global: 744,
  },
  {
    profile_section_id: 8957,
    name: "PIP324X10.0CHS",
    type: "Pipe",
    designation: "PIP324X10.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: "98,6",
    d_global: 324,
    i_global: 12200,
    t_global: 10,
    z_global: 751,
  },
  {
    profile_section_id: 8958,
    name: "PIP406X8.0CHS",
    type: "Pipe",
    designation: "PIP406X8.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: 100,
    d_global: 406,
    i_global: 19800,
    t_global: 8,
    z_global: 976,
  },
  {
    profile_section_id: 8959,
    name: "PIP356X10.0CHS",
    type: "Pipe",
    designation: "PIP356X10.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: 109,
    d_global: 356,
    i_global: 16300,
    t_global: 10,
    z_global: 915,
  },
  {
    profile_section_id: 8960,
    name: "PIP457X8.0CHS",
    type: "Pipe",
    designation: "PIP457X8.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: 113,
    d_global: 457,
    i_global: 28400,
    t_global: 8,
    z_global: 1240,
  },
  {
    profile_section_id: 8961,
    name: "PIP406X10.0CHS",
    type: "Pipe",
    designation: "PIP406X10.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: 124,
    d_global: 406,
    i_global: 24400,
    t_global: 10,
    z_global: 1200,
  },
  {
    profile_section_id: 8962,
    name: "PIP457X10.0CHS",
    type: "Pipe",
    designation: "PIP457X10.0CHS",
    shape: "PIPE",
    country_code: "South African",
    ax_global: 140,
    d_global: 457,
    i_global: 35100,
    t_global: 10,
    z_global: 1540,
  },
  {
    profile_section_id: 8963,
    name: "P 21.7X2.0",
    type: "Pipe",
    designation: "P 21.7X2.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "1,24",
    d_global: "21,7",
    i_global: "0,61",
    t_global: 2,
    z_global: "7,78",
  },
  {
    profile_section_id: 8964,
    name: "P 27.2X2.0",
    type: "Pipe",
    designation: "P 27.2X2.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "1,58",
    d_global: "27,2",
    i_global: "1,26",
    t_global: 2,
    z_global: "12,71",
  },
  {
    profile_section_id: 8965,
    name: "P 27.2X2.3",
    type: "Pipe",
    designation: "P 27.2X2.3",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "1,8",
    d_global: "27,2",
    i_global: "1,41",
    t_global: "2,3",
    z_global: "14,28",
  },
  {
    profile_section_id: 8966,
    name: "P 34.0X2.3",
    type: "Pipe",
    designation: "P 34.0X2.3",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "2,29",
    d_global: 34,
    i_global: "2,89",
    t_global: "2,3",
    z_global: "23,13",
  },
  {
    profile_section_id: 8967,
    name: "P 42.7X2.3",
    type: "Pipe",
    designation: "P 42.7X2.3",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "2,92",
    d_global: "42,7",
    i_global: "5,97",
    t_global: "2,3",
    z_global: "37,54",
  },
  {
    profile_section_id: 8968,
    name: "P 42.7X2.5",
    type: "Pipe",
    designation: "P 42.7X2.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "3,16",
    d_global: "42,7",
    i_global: "6,4",
    t_global: "2,5",
    z_global: "40,41",
  },
  {
    profile_section_id: 8969,
    name: "P 64.2X2.3",
    type: "Pipe",
    designation: "P 64.2X2.3",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "3,35",
    d_global: "64,16",
    i_global: "8,99",
    t_global: "2,3",
    z_global: "49,29",
  },
  {
    profile_section_id: 8970,
    name: "P 48.6X2.5",
    type: "Pipe",
    designation: "P 48.6X2.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "3,62",
    d_global: "48,6",
    i_global: "9,65",
    t_global: "2,5",
    z_global: "53,13",
  },
  {
    profile_section_id: 8971,
    name: "P 48.6X2.8",
    type: "Pipe",
    designation: "P 48.6X2.8",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "4,03",
    d_global: "48,6",
    i_global: "10,6",
    t_global: "2,8",
    z_global: "58,75",
  },
  {
    profile_section_id: 8972,
    name: "P 48.6X3.2",
    type: "Pipe",
    designation: "P 48.6X3.2",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "4,56",
    d_global: "48,6",
    i_global: "11,8",
    t_global: "3,2",
    z_global: 66,
  },
  {
    profile_section_id: 8973,
    name: "P 60.5X2.3",
    type: "Pipe",
    designation: "P 60.5X2.3",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "4,21",
    d_global: "60,5",
    i_global: "17,8",
    t_global: "2,3",
    z_global: "77,88",
  },
  {
    profile_section_id: 8974,
    name: "P 60.5X3.2",
    type: "Pipe",
    designation: "P 60.5X3.2",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "5,76",
    d_global: "60,5",
    i_global: "23,7",
    t_global: "3,2",
    z_global: "105,08",
  },
  {
    profile_section_id: 8975,
    name: "P 60.5X4.0",
    type: "Pipe",
    designation: "P 60.5X4.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "7,1",
    d_global: "60,5",
    i_global: "28,5",
    t_global: 4,
    z_global: "127,79",
  },
  {
    profile_section_id: 8976,
    name: "P 76.3X2.8",
    type: "Pipe",
    designation: "P 76.3X2.8",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "6,46",
    d_global: "76,3",
    i_global: "43,7",
    t_global: "2,8",
    z_global: "151,19",
  },
  {
    profile_section_id: 8977,
    name: "P 76.3X3.2",
    type: "Pipe",
    designation: "P 76.3X3.2",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "7,35",
    d_global: "76,3",
    i_global: "49,2",
    t_global: "3,2",
    z_global: "170,93",
  },
  {
    profile_section_id: 8978,
    name: "P 76.3X4.0",
    type: "Pipe",
    designation: "P 76.3X4.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "9,09",
    d_global: "76,3",
    i_global: "59,5",
    t_global: 4,
    z_global: "209,09",
  },
  {
    profile_section_id: 8979,
    name: "P 89.1X2.8",
    type: "Pipe",
    designation: "P 89.1X2.8",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "7,59",
    d_global: "89,1",
    i_global: "70,7",
    t_global: "2,8",
    z_global: "208,43",
  },
  {
    profile_section_id: 8980,
    name: "P 89.1X3.2",
    type: "Pipe",
    designation: "P 89.1X3.2",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "8,64",
    d_global: "89,1",
    i_global: "79,8",
    t_global: "3,2",
    z_global: "236,03",
  },
  {
    profile_section_id: 8981,
    name: "P 101.6X3.2",
    type: "Pipe",
    designation: "P 101.6X3.2",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "9,89",
    d_global: "101,6",
    i_global: 120,
    t_global: "3,2",
    z_global: "309,66",
  },
  {
    profile_section_id: 8982,
    name: "P 101.6X4.0",
    type: "Pipe",
    designation: "P 101.6X4.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "12,26",
    d_global: "101,6",
    i_global: 146,
    t_global: 4,
    z_global: 380,
  },
  {
    profile_section_id: 8983,
    name: "P 101.6X5.0",
    type: "Pipe",
    designation: "P 101.6X5.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "15,17",
    d_global: "101,6",
    i_global: 177,
    t_global: 5,
    z_global: 466,
  },
  {
    profile_section_id: 8984,
    name: "P 114.3X3.2",
    type: "Pipe",
    designation: "P 114.3X3.2",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "11,17",
    d_global: "114,3",
    i_global: 172,
    t_global: "3,2",
    z_global: 394,
  },
  {
    profile_section_id: 8985,
    name: "P 114.3X3.5",
    type: "Pipe",
    designation: "P 114.3X3.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "12,18",
    d_global: "114,3",
    i_global: 187,
    t_global: "3,5",
    z_global: 429,
  },
  {
    profile_section_id: 8986,
    name: "P 114.3X4.5",
    type: "Pipe",
    designation: "P 114.3X4.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "15,52",
    d_global: "114,3",
    i_global: 234,
    t_global: "4,5",
    z_global: 542,
  },
  {
    profile_section_id: 8987,
    name: "P 139.8X3.6",
    type: "Pipe",
    designation: "P 139.8X3.6",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "15,4",
    d_global: "139,8",
    i_global: 357,
    t_global: "3,6",
    z_global: 667,
  },
  {
    profile_section_id: 8988,
    name: "P 139.8X4.0",
    type: "Pipe",
    designation: "P 139.8X4.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "17,07",
    d_global: "139,8",
    i_global: 394,
    t_global: 4,
    z_global: 737,
  },
  {
    profile_section_id: 8989,
    name: "P 139.8X4.5",
    type: "Pipe",
    designation: "P 139.8X4.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "19,13",
    d_global: "139,8",
    i_global: 438,
    t_global: "4,5",
    z_global: 823,
  },
  {
    profile_section_id: 8990,
    name: "P 139.8X6.0",
    type: "Pipe",
    designation: "P 139.8X6.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "25,22",
    d_global: "139,8",
    i_global: 566,
    t_global: 6,
    z_global: 1073,
  },
  {
    profile_section_id: 8991,
    name: "P 165.2X4.5",
    type: "Pipe",
    designation: "P 165.2X4.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "22,72",
    d_global: "165,2",
    i_global: 734,
    t_global: "4,5",
    z_global: 1161,
  },
  {
    profile_section_id: 8992,
    name: "P 165.2X5.0",
    type: "Pipe",
    designation: "P 165.2X5.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "25,16",
    d_global: "165,2",
    i_global: 808,
    t_global: 5,
    z_global: 1282,
  },
  {
    profile_section_id: 8993,
    name: "P 165.2X6.0",
    type: "Pipe",
    designation: "P 165.2X6.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "30,01",
    d_global: "165,2",
    i_global: 952,
    t_global: 6,
    z_global: 1519,
  },
  {
    profile_section_id: 8994,
    name: "P 165.2X7.1",
    type: "Pipe",
    designation: "P 165.2X7.1",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "35,26",
    d_global: "165,2",
    i_global: 1100,
    t_global: "7,1",
    z_global: 1774,
  },
  {
    profile_section_id: 8995,
    name: "P 190.7X4.5",
    type: "Pipe",
    designation: "P 190.7X4.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "26,32",
    d_global: "190,7",
    i_global: 1140,
    t_global: "4,5",
    z_global: 1558,
  },
  {
    profile_section_id: 8996,
    name: "P 190.7X5.3",
    type: "Pipe",
    designation: "P 190.7X5.3",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "30,87",
    d_global: "190,7",
    i_global: 1330,
    t_global: "5,3",
    z_global: 1820,
  },
  {
    profile_section_id: 8997,
    name: "P 190.7X6.0",
    type: "Pipe",
    designation: "P 190.7X6.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "34,82",
    d_global: "190,7",
    i_global: 1490,
    t_global: 6,
    z_global: 2045,
  },
  {
    profile_section_id: 8998,
    name: "P 190.7X7.0",
    type: "Pipe",
    designation: "P 190.7X7.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "40,4",
    d_global: "190,7",
    i_global: 1710,
    t_global: 7,
    z_global: 2361,
  },
  {
    profile_section_id: 8999,
    name: "P 190.7X8.2",
    type: "Pipe",
    designation: "P 190.7X8.2",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "47,01",
    d_global: "190,7",
    i_global: 1960,
    t_global: "8,2",
    z_global: 2730,
  },
  {
    profile_section_id: 9000,
    name: "P 216.3X4.5",
    type: "Pipe",
    designation: "P 216.3X4.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "29,94",
    d_global: "216,3",
    i_global: 1680,
    t_global: "4,5",
    z_global: 2017,
  },
  {
    profile_section_id: 9001,
    name: "P 216.3X5.8",
    type: "Pipe",
    designation: "P 216.3X5.8",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "38,36",
    d_global: "216,3",
    i_global: 2130,
    t_global: "5,8",
    z_global: 2568,
  },
  {
    profile_section_id: 9002,
    name: "P 216.3X6.0",
    type: "Pipe",
    designation: "P 216.3X6.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "39,64",
    d_global: "216,3",
    i_global: 2190,
    t_global: 6,
    z_global: 2651,
  },
  {
    profile_section_id: 9003,
    name: "P 216.3X7.0",
    type: "Pipe",
    designation: "P 216.3X7.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "46,03",
    d_global: "216,3",
    i_global: 2520,
    t_global: 7,
    z_global: 3064,
  },
  {
    profile_section_id: 9004,
    name: "P 216.3X8.0",
    type: "Pipe",
    designation: "P 216.3X8.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "52,35",
    d_global: "216,3",
    i_global: 2840,
    t_global: 8,
    z_global: 3469,
  },
  {
    profile_section_id: 9005,
    name: "P 216.3X8.2",
    type: "Pipe",
    designation: "P 216.3X8.2",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "53,61",
    d_global: "216,3",
    i_global: 2910,
    t_global: "8,2",
    z_global: 3549,
  },
  {
    profile_section_id: 9006,
    name: "P 267.4X6.0",
    type: "Pipe",
    designation: "P 267.4X6.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "49,27",
    d_global: "267,4",
    i_global: 4210,
    t_global: 6,
    z_global: 4096,
  },
  {
    profile_section_id: 9007,
    name: "P 267.4X6.6",
    type: "Pipe",
    designation: "P 267.4X6.6",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "54,08",
    d_global: "267,4",
    i_global: 4600,
    t_global: "6,6",
    z_global: 4485,
  },
  {
    profile_section_id: 9008,
    name: "P 267.4X7.0",
    type: "Pipe",
    designation: "P 267.4X7.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "57,26",
    d_global: "267,4",
    i_global: 4860,
    t_global: 7,
    z_global: 4743,
  },
  {
    profile_section_id: 9009,
    name: "P 267.4X8.0",
    type: "Pipe",
    designation: "P 267.4X8.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "65,19",
    d_global: "267,4",
    i_global: 5490,
    t_global: 8,
    z_global: 5379,
  },
  {
    profile_section_id: 9010,
    name: "P 267.4X9.0",
    type: "Pipe",
    designation: "P 267.4X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "73,06",
    d_global: "267,4",
    i_global: 6110,
    t_global: 9,
    z_global: 6006,
  },
  {
    profile_section_id: 9011,
    name: "P 267.4X9.3",
    type: "Pipe",
    designation: "P 267.4X9.3",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "75,41",
    d_global: "267,4",
    i_global: 6290,
    t_global: "9,3",
    z_global: 6192,
  },
  {
    profile_section_id: 9012,
    name: "P 318.5X6.0",
    type: "Pipe",
    designation: "P 318.5X6.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "58,91",
    d_global: "318,5",
    i_global: 7190,
    t_global: 6,
    z_global: 5854,
  },
  {
    profile_section_id: 9013,
    name: "P 318.5X6.9",
    type: "Pipe",
    designation: "P 318.5X6.9",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "67,55",
    d_global: "318,5",
    i_global: 8200,
    t_global: "6,9",
    z_global: 6694,
  },
  {
    profile_section_id: 9014,
    name: "P 318.5X8.0",
    type: "Pipe",
    designation: "P 318.5X8.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "78,04",
    d_global: "318,5",
    i_global: 9410,
    t_global: 8,
    z_global: 7707,
  },
  {
    profile_section_id: 9015,
    name: "P 318.5X9.0",
    type: "Pipe",
    designation: "P 318.5X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "87,51",
    d_global: "318,5",
    i_global: 10500,
    t_global: 9,
    z_global: 8615,
  },
  {
    profile_section_id: 9016,
    name: "P 318.5X10.3",
    type: "Pipe",
    designation: "P 318.5X10.3",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "99,73",
    d_global: "318,5",
    i_global: 11900,
    t_global: "10,3",
    z_global: 9777,
  },
  {
    profile_section_id: 9017,
    name: "P 355.6X6.4",
    type: "Pipe",
    designation: "P 355.6X6.4",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "70,21",
    d_global: "355,6",
    i_global: 10700,
    t_global: "6,4",
    z_global: 7797,
  },
  {
    profile_section_id: 9018,
    name: "P 355.6X7.9",
    type: "Pipe",
    designation: "P 355.6X7.9",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "86,29",
    d_global: "355,6",
    i_global: 13000,
    t_global: "7,9",
    z_global: 9543,
  },
  {
    profile_section_id: 9019,
    name: "P 355.6X9.0",
    type: "Pipe",
    designation: "P 355.6X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: 98,
    d_global: "355,6",
    i_global: 14700,
    t_global: 9,
    z_global: 10804,
  },
  {
    profile_section_id: 9020,
    name: "P 355.6X9.5",
    type: "Pipe",
    designation: "P 355.6X9.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "103,3",
    d_global: "355,6",
    i_global: 15500,
    t_global: "9,5",
    z_global: 11371,
  },
  {
    profile_section_id: 9021,
    name: "P 355.6X12.0",
    type: "Pipe",
    designation: "P 355.6X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "129,5",
    d_global: "355,6",
    i_global: 19100,
    t_global: 12,
    z_global: 14159,
  },
  {
    profile_section_id: 9022,
    name: "P 355.6X12.7",
    type: "Pipe",
    designation: "P 355.6X12.7",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "136,8",
    d_global: "355,6",
    i_global: 20100,
    t_global: "12,7",
    z_global: 14925,
  },
  {
    profile_section_id: 9023,
    name: "P 406.4X7.9",
    type: "Pipe",
    designation: "P 406.4X7.9",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "98,9",
    d_global: "406,4",
    i_global: 19600,
    t_global: "7,9",
    z_global: 12535,
  },
  {
    profile_section_id: 9024,
    name: "P 406.4X9.0",
    type: "Pipe",
    designation: "P 406.4X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "112,4",
    d_global: "406,4",
    i_global: 22200,
    t_global: 9,
    z_global: 14202,
  },
  {
    profile_section_id: 9025,
    name: "P 406.4X9.5",
    type: "Pipe",
    designation: "P 406.4X9.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "118,5",
    d_global: "406,4",
    i_global: 23300,
    t_global: "9,5",
    z_global: 14954,
  },
  {
    profile_section_id: 9026,
    name: "P 406.4X12.0",
    type: "Pipe",
    designation: "P 406.4X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "148,7",
    d_global: "406,4",
    i_global: 28900,
    t_global: 12,
    z_global: 18654,
  },
  {
    profile_section_id: 9027,
    name: "P 406.4X12.7",
    type: "Pipe",
    designation: "P 406.4X12.7",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "157,1",
    d_global: "406,4",
    i_global: 30500,
    t_global: "12,7",
    z_global: 19673,
  },
  {
    profile_section_id: 9028,
    name: "P 406.4X16.0",
    type: "Pipe",
    designation: "P 406.4X16.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "196,2",
    d_global: "406,4",
    i_global: 37400,
    t_global: 16,
    z_global: 24376,
  },
  {
    profile_section_id: 9029,
    name: "P 406.4X19.0",
    type: "Pipe",
    designation: "P 406.4X19.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "231,2",
    d_global: "406,4",
    i_global: 43500,
    t_global: 19,
    z_global: 28511,
  },
  {
    profile_section_id: 9030,
    name: "P 457.2X9.0",
    type: "Pipe",
    designation: "P 457.2X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "126,7",
    d_global: "457,2",
    i_global: 31800,
    t_global: 9,
    z_global: 18065,
  },
  {
    profile_section_id: 9031,
    name: "P 457.2X9.5",
    type: "Pipe",
    designation: "P 457.2X9.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "133,6",
    d_global: "457,2",
    i_global: 33500,
    t_global: "9,5",
    z_global: 19026,
  },
  {
    profile_section_id: 9032,
    name: "P 457.2X12.0",
    type: "Pipe",
    designation: "P 457.2X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "167,8",
    d_global: "457,2",
    i_global: 41600,
    t_global: 12,
    z_global: 23768,
  },
  {
    profile_section_id: 9033,
    name: "P 457.2X12.7",
    type: "Pipe",
    designation: "P 457.2X12.7",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "177,3",
    d_global: "457,2",
    i_global: 43800,
    t_global: "12,7",
    z_global: 25076,
  },
  {
    profile_section_id: 9034,
    name: "P 457.2X16.0",
    type: "Pipe",
    designation: "P 457.2X16.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "221,8",
    d_global: "457,2",
    i_global: 54000,
    t_global: 16,
    z_global: 31129,
  },
  {
    profile_section_id: 9035,
    name: "P 457.2X19.0",
    type: "Pipe",
    designation: "P 457.2X19.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "261,6",
    d_global: "457,2",
    i_global: 62900,
    t_global: 19,
    z_global: 36472,
  },
  {
    profile_section_id: 9036,
    name: "P 500.0X9.0",
    type: "Pipe",
    designation: "P 500.0X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "138,8",
    d_global: 500,
    i_global: 41800,
    t_global: 9,
    z_global: 21679,
  },
  {
    profile_section_id: 9037,
    name: "P 500.0X12.0",
    type: "Pipe",
    designation: "P 500.0X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: 184,
    d_global: 500,
    i_global: 54800,
    t_global: 12,
    z_global: 28556,
  },
  {
    profile_section_id: 9038,
    name: "P 500.0X14.0",
    type: "Pipe",
    designation: "P 500.0X14.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "213,8",
    d_global: 500,
    i_global: 63200,
    t_global: 14,
    z_global: 33045,
  },
  {
    profile_section_id: 9039,
    name: "P 508.0X7.9",
    type: "Pipe",
    designation: "P 508.0X7.9",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "124,1",
    d_global: 508,
    i_global: 38800,
    t_global: "7,9",
    z_global: 19741,
  },
  {
    profile_section_id: 9040,
    name: "P 508.0X9.0",
    type: "Pipe",
    designation: "P 508.0X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "141,1",
    d_global: 508,
    i_global: 43900,
    t_global: 9,
    z_global: 22391,
  },
  {
    profile_section_id: 9041,
    name: "P 508.0X9.5",
    type: "Pipe",
    designation: "P 508.0X9.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "148,8",
    d_global: 508,
    i_global: 46200,
    t_global: "9,5",
    z_global: 23588,
  },
  {
    profile_section_id: 9042,
    name: "P 508.0X12.0",
    type: "Pipe",
    designation: "P 508.0X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: 187,
    d_global: 508,
    i_global: 57500,
    t_global: 12,
    z_global: 29500,
  },
  {
    profile_section_id: 9043,
    name: "P 508.0X12.7",
    type: "Pipe",
    designation: "P 508.0X12.7",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "197,6",
    d_global: 508,
    i_global: 60600,
    t_global: "12,7",
    z_global: 31133,
  },
  {
    profile_section_id: 9044,
    name: "P 508.0X14.0",
    type: "Pipe",
    designation: "P 508.0X14.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "217,3",
    d_global: 508,
    i_global: 66300,
    t_global: 14,
    z_global: 34142,
  },
  {
    profile_section_id: 9045,
    name: "P 508.0X16.0",
    type: "Pipe",
    designation: "P 508.0X16.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "247,3",
    d_global: 508,
    i_global: 74900,
    t_global: 16,
    z_global: 38707,
  },
  {
    profile_section_id: 9046,
    name: "P 508.0X19.0",
    type: "Pipe",
    designation: "P 508.0X19.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "291,9",
    d_global: 508,
    i_global: 87400,
    t_global: 19,
    z_global: 45413,
  },
  {
    profile_section_id: 9047,
    name: "P 508.0X22.0",
    type: "Pipe",
    designation: "P 508.0X22.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "335,9",
    d_global: 508,
    i_global: 99400,
    t_global: 22,
    z_global: 51950,
  },
  {
    profile_section_id: 9048,
    name: "P 558.8X9.0",
    type: "Pipe",
    designation: "P 558.8X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "155,5",
    d_global: "558,8",
    i_global: 58800,
    t_global: 9,
    z_global: 27182,
  },
  {
    profile_section_id: 9049,
    name: "P 558.8X12.0",
    type: "Pipe",
    designation: "P 558.8X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "206,1",
    d_global: "558,8",
    i_global: 77100,
    t_global: 12,
    z_global: 35851,
  },
  {
    profile_section_id: 9050,
    name: "P 558.6X16.0",
    type: "Pipe",
    designation: "P 558.6X16.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "272,8",
    d_global: "558,6",
    i_global: 101000,
    t_global: 16,
    z_global: 47388,
  },
  {
    profile_section_id: 9051,
    name: "P 558.6X19.0",
    type: "Pipe",
    designation: "P 558.6X19.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "322,2",
    d_global: "558,6",
    i_global: 118000,
    t_global: 19,
    z_global: 55605,
  },
  {
    profile_section_id: 9052,
    name: "P 558.6X22.0",
    type: "Pipe",
    designation: "P 558.6X22.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: 371,
    d_global: "558,6",
    i_global: 134000,
    t_global: 22,
    z_global: 63635,
  },
  {
    profile_section_id: 9053,
    name: "P 600.0X9.0",
    type: "Pipe",
    designation: "P 600.0X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "167,1",
    d_global: 600,
    i_global: 73000,
    t_global: 9,
    z_global: 31408,
  },
  {
    profile_section_id: 9054,
    name: "P 600.0X12.0",
    type: "Pipe",
    designation: "P 600.0X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "221,7",
    d_global: 600,
    i_global: 95800,
    t_global: 12,
    z_global: 41456,
  },
  {
    profile_section_id: 9055,
    name: "P 600.0X14.0",
    type: "Pipe",
    designation: "P 600.0X14.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "257,7",
    d_global: 600,
    i_global: 111000,
    t_global: 14,
    z_global: 48039,
  },
  {
    profile_section_id: 9056,
    name: "P 600.0X16.0",
    type: "Pipe",
    designation: "P 600.0X16.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "293,6",
    d_global: 600,
    i_global: 125000,
    t_global: 16,
    z_global: 54531,
  },
  {
    profile_section_id: 9057,
    name: "P 609.6X9.0",
    type: "Pipe",
    designation: "P 609.6X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "169,8",
    d_global: "609,6",
    i_global: 76600,
    t_global: 9,
    z_global: 32437,
  },
  {
    profile_section_id: 9058,
    name: "P 609.6X9.5",
    type: "Pipe",
    designation: "P 609.6X9.5",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "179,1",
    d_global: "609,6",
    i_global: 80600,
    t_global: "9,5",
    z_global: 34182,
  },
  {
    profile_section_id: 9059,
    name: "P 609.6X12.0",
    type: "Pipe",
    designation: "P 609.6X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "225,3",
    d_global: "609,6",
    i_global: 101000,
    t_global: 12,
    z_global: 42821,
  },
  {
    profile_section_id: 9060,
    name: "P 609.6X12.7",
    type: "Pipe",
    designation: "P 609.6X12.7",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "238,2",
    d_global: "609,6",
    i_global: 106000,
    t_global: "12,7",
    z_global: 45213,
  },
  {
    profile_section_id: 9061,
    name: "P 609.6X14.0",
    type: "Pipe",
    designation: "P 609.6X14.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: 262,
    d_global: "609,6",
    i_global: 116000,
    t_global: 14,
    z_global: 49626,
  },
  {
    profile_section_id: 9062,
    name: "P 609.6X16.0",
    type: "Pipe",
    designation: "P 609.6X16.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "298,4",
    d_global: "609,6",
    i_global: 132000,
    t_global: 16,
    z_global: 56338,
  },
  {
    profile_section_id: 9063,
    name: "P 609.6X19.0",
    type: "Pipe",
    designation: "P 609.6X19.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "352,5",
    d_global: "609,6",
    i_global: 154000,
    t_global: 19,
    z_global: 66234,
  },
  {
    profile_section_id: 9064,
    name: "P 609.6X22.0",
    type: "Pipe",
    designation: "P 609.6X22.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "406,1",
    d_global: "609,6",
    i_global: 176000,
    t_global: 22,
    z_global: 75925,
  },
  {
    profile_section_id: 9065,
    name: "P 700.0X9.0",
    type: "Pipe",
    designation: "P 700.0X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "195,4",
    d_global: 700,
    i_global: 117000,
    t_global: 9,
    z_global: 42935,
  },
  {
    profile_section_id: 9066,
    name: "P 700.0X12.0",
    type: "Pipe",
    designation: "P 700.0X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "259,4",
    d_global: 700,
    i_global: 154000,
    t_global: 12,
    z_global: 56754,
  },
  {
    profile_section_id: 9067,
    name: "P 700.0X14.0",
    type: "Pipe",
    designation: "P 700.0X14.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "301,7",
    d_global: 700,
    i_global: 178000,
    t_global: 14,
    z_global: 65831,
  },
  {
    profile_section_id: 9068,
    name: "P 700.0X16.0",
    type: "Pipe",
    designation: "P 700.0X16.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "343,8",
    d_global: 700,
    i_global: 201000,
    t_global: 16,
    z_global: 74801,
  },
  {
    profile_section_id: 9069,
    name: "P 711.2X9.0",
    type: "Pipe",
    designation: "P 711.2X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "198,5",
    d_global: "711,2",
    i_global: 122000,
    t_global: 9,
    z_global: 44338,
  },
  {
    profile_section_id: 9070,
    name: "P 711.2X12.0",
    type: "Pipe",
    designation: "P 711.2X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "263,6",
    d_global: "711,2",
    i_global: 161000,
    t_global: 12,
    z_global: 58616,
  },
  {
    profile_section_id: 9071,
    name: "P 711.2X14.0",
    type: "Pipe",
    designation: "P 711.2X14.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "306,6",
    d_global: "711,2",
    i_global: 186000,
    t_global: 14,
    z_global: 67998,
  },
  {
    profile_section_id: 9072,
    name: "P 711.2X16.0",
    type: "Pipe",
    designation: "P 711.2X16.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "349,4",
    d_global: "711,2",
    i_global: 211000,
    t_global: 16,
    z_global: 77270,
  },
  {
    profile_section_id: 9073,
    name: "P 711.2X19.0",
    type: "Pipe",
    designation: "P 711.2X19.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "413,2",
    d_global: "711,2",
    i_global: 248000,
    t_global: 19,
    z_global: 90974,
  },
  {
    profile_section_id: 9074,
    name: "P 711.2X22.0",
    type: "Pipe",
    designation: "P 711.2X22.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "476,3",
    d_global: "711,2",
    i_global: 283000,
    t_global: 22,
    z_global: 104437,
  },
  {
    profile_section_id: 9075,
    name: "P 812.8X9.0",
    type: "Pipe",
    designation: "P 812.8X9.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "227,3",
    d_global: "812,8",
    i_global: 184000,
    t_global: 9,
    z_global: 58096,
  },
  {
    profile_section_id: 9076,
    name: "P 812.8X12.0",
    type: "Pipe",
    designation: "P 812.8X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "301,9",
    d_global: "812,8",
    i_global: 242000,
    t_global: 12,
    z_global: 76887,
  },
  {
    profile_section_id: 9077,
    name: "P 812.8X14.0",
    type: "Pipe",
    designation: "P 812.8X14.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "351,3",
    d_global: "812,8",
    i_global: 280000,
    t_global: 14,
    z_global: 89257,
  },
  {
    profile_section_id: 9078,
    name: "P 812.8X16.0",
    type: "Pipe",
    designation: "P 812.8X16.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "400,5",
    d_global: "812,8",
    i_global: 318000,
    t_global: 16,
    z_global: 101501,
  },
  {
    profile_section_id: 9079,
    name: "P 812.8X19.0",
    type: "Pipe",
    designation: "P 812.8X19.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "473,8",
    d_global: "812,8",
    i_global: 373000,
    t_global: 19,
    z_global: 119634,
  },
  {
    profile_section_id: 9080,
    name: "P 812.8X22.0",
    type: "Pipe",
    designation: "P 812.8X22.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "546,6",
    d_global: "812,8",
    i_global: 428000,
    t_global: 22,
    z_global: 137487,
  },
  {
    profile_section_id: 9081,
    name: "P 914.4X12.0",
    type: "Pipe",
    designation: "P 914.4X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "340,2",
    d_global: "914,4",
    i_global: 348000,
    t_global: 12,
    z_global: 97634,
  },
  {
    profile_section_id: 9082,
    name: "P 914.4X14.0",
    type: "Pipe",
    designation: "P 914.4X14.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: 396,
    d_global: "914,4",
    i_global: 401000,
    t_global: 14,
    z_global: 113404,
  },
  {
    profile_section_id: 9083,
    name: "P 914.4X16.0",
    type: "Pipe",
    designation: "P 914.4X16.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "451,6",
    d_global: "914,4",
    i_global: 456000,
    t_global: 16,
    z_global: 129033,
  },
  {
    profile_section_id: 9084,
    name: "P 914.4X19.0",
    type: "Pipe",
    designation: "P 914.4X19.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "534,5",
    d_global: "914,4",
    i_global: 536000,
    t_global: 19,
    z_global: 152212,
  },
  {
    profile_section_id: 9085,
    name: "P 914.4X22.0",
    type: "Pipe",
    designation: "P 914.4X22.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "616,5",
    d_global: "914,4",
    i_global: 614000,
    t_global: 22,
    z_global: 175075,
  },
  {
    profile_section_id: 9086,
    name: "P 1016.0X12.0",
    type: "Pipe",
    designation: "P 1016.0X12.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "378,5",
    d_global: 1016,
    i_global: 477000,
    t_global: 12,
    z_global: 120855,
  },
  {
    profile_section_id: 9087,
    name: "P 1016.0X14.0",
    type: "Pipe",
    designation: "P 1016.0X14.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "440,7",
    d_global: 1016,
    i_global: 553000,
    t_global: 14,
    z_global: 140439,
  },
  {
    profile_section_id: 9088,
    name: "P 1016.0X16.0",
    type: "Pipe",
    designation: "P 1016.0X16.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "502,7",
    d_global: 1016,
    i_global: 628000,
    t_global: 16,
    z_global: 159864,
  },
  {
    profile_section_id: 9089,
    name: "P 1016.0X19.0",
    type: "Pipe",
    designation: "P 1016.0X19.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: "595,1",
    d_global: 1016,
    i_global: 740000,
    t_global: 19,
    z_global: 188708,
  },
  {
    profile_section_id: 9090,
    name: "P 1016.0X22.0",
    type: "Pipe",
    designation: "P 1016.0X22.0",
    shape: "PIPE",
    country_code: "South Korean",
    ax_global: 687,
    d_global: 1016,
    i_global: 849000,
    t_global: 22,
    z_global: 217201,
  },
  {
    profile_section_id: 9091,
    name: "PIP422.6",
    type: "Pipe",
    designation: "PIP422.6",
    shape: "PIPE",
    country_code: "German",
    ax_global: "3,25",
    d_global: "42,4",
    i_global: "6,46",
    t_global: "2,6",
    z_global: "4,12",
  },
  {
    profile_section_id: 9092,
    name: "PIP485.0",
    type: "Pipe",
    designation: "PIP485.0",
    shape: "PIPE",
    country_code: "German",
    ax_global: "6,8",
    d_global: "48,3",
    i_global: "16,15",
    t_global: 5,
    z_global: "9,37",
  },
  {
    profile_section_id: 9093,
    name: "PIP883.2",
    type: "Pipe",
    designation: "PIP883.2",
    shape: "PIPE",
    country_code: "German",
    ax_global: "8,62",
    d_global: "88,9",
    i_global: "79,21",
    t_global: "3,2",
    z_global: "23,5",
  },
  {
    profile_section_id: 9094,
    name: "PIP605.0",
    type: "Pipe",
    designation: "PIP605.0",
    shape: "PIPE",
    country_code: "German",
    ax_global: "8,69",
    d_global: "60,3",
    i_global: "33,48",
    t_global: 5,
    z_global: "15,29",
  },
  {
    profile_section_id: 9095,
    name: "PIP1143.2",
    type: "Pipe",
    designation: "PIP1143.2",
    shape: "PIPE",
    country_code: "German",
    ax_global: "11,17",
    d_global: "114,3",
    i_global: "172,47",
    t_global: "3,2",
    z_global: "39,5",
  },
  {
    profile_section_id: 9096,
    name: "PIP765.0",
    type: "Pipe",
    designation: "PIP765.0",
    shape: "PIPE",
    country_code: "German",
    ax_global: "11,17",
    d_global: "76,1",
    i_global: "70,92",
    t_global: 5,
    z_global: "25,28",
  },
  {
    profile_section_id: 9097,
    name: "PIP1143.6",
    type: "Pipe",
    designation: "PIP1143.6",
    shape: "PIPE",
    country_code: "German",
    ax_global: "12,52",
    d_global: "114,3",
    i_global: "191,98",
    t_global: "3,6",
    z_global: "44,12",
  },
  {
    profile_section_id: 9098,
    name: "PIP1393.6",
    type: "Pipe",
    designation: "PIP1393.6",
    shape: "PIPE",
    country_code: "German",
    ax_global: "15,39",
    d_global: "139,7",
    i_global: "356,65",
    t_global: "3,6",
    z_global: "66,68",
  },
  {
    profile_section_id: 9099,
    name: "PIP1394.0",
    type: "Pipe",
    designation: "PIP1394.0",
    shape: "PIPE",
    country_code: "German",
    ax_global: "17,05",
    d_global: "139,7",
    i_global: "392,86",
    t_global: 4,
    z_global: "73,66",
  },
  {
    profile_section_id: 9100,
    name: "PIP1684.0",
    type: "Pipe",
    designation: "PIP1684.0",
    shape: "PIPE",
    country_code: "German",
    ax_global: "20,65",
    d_global: "168,3",
    i_global: "697,09",
    t_global: 4,
    z_global: "107,98",
  },
  {
    profile_section_id: 9101,
    name: "PIP2445.0",
    type: "Pipe",
    designation: "PIP2445.0",
    shape: "PIPE",
    country_code: "German",
    ax_global: "37,62",
    d_global: "244,5",
    i_global: "2698,58",
    t_global: 5,
    z_global: "286,8",
  },
  {
    profile_section_id: 9102,
    name: "PIP1688.0",
    type: "Pipe",
    designation: "PIP1688.0",
    shape: "PIPE",
    country_code: "German",
    ax_global: "40,29",
    d_global: "168,3",
    i_global: "1297,27",
    t_global: 8,
    z_global: "205,57",
  },
  {
    profile_section_id: 9103,
    name: "PIP2735.0",
    type: "Pipe",
    designation: "PIP2735.0",
    shape: "PIPE",
    country_code: "German",
    ax_global: "42,1",
    d_global: 273,
    i_global: "3780,81",
    t_global: 5,
    z_global: "359,12",
  },
  {
    profile_section_id: 9104,
    name: "PIP2446.3",
    type: "Pipe",
    designation: "PIP2446.3",
    shape: "PIPE",
    country_code: "German",
    ax_global: "47,14",
    d_global: "244,5",
    i_global: "3346,03",
    t_global: "6,3",
    z_global: "357,46",
  },
  {
    profile_section_id: 9105,
    name: "PIP2736.3",
    type: "Pipe",
    designation: "PIP2736.3",
    shape: "PIPE",
    country_code: "German",
    ax_global: "52,79",
    d_global: 273,
    i_global: "4695,82",
    t_global: "6,3",
    z_global: "448,11",
  },
  {
    profile_section_id: 9106,
    name: "PIP4066.3",
    type: "Pipe",
    designation: "PIP4066.3",
    shape: "PIPE",
    country_code: "German",
    ax_global: "79,19",
    d_global: "406,4",
    i_global: "15849,43",
    t_global: "6,3",
    z_global: "1008,5",
  },
  {
    profile_section_id: 9107,
    name: "PIP21912.5",
    type: "Pipe",
    designation: "PIP21912.5",
    shape: "PIPE",
    country_code: "German",
    ax_global: "81,13",
    d_global: "219,1",
    i_global: "4344,58",
    t_global: "12,5",
    z_global: "533,54",
  },
  {
    profile_section_id: 9108,
    name: "PIP3558.0",
    type: "Pipe",
    designation: "PIP3558.0",
    shape: "PIPE",
    country_code: "German",
    ax_global: "87,36",
    d_global: "355,6",
    i_global: "13201,38",
    t_global: 8,
    z_global: "966,61",
  },
  {
    profile_section_id: 9109,
    name: "PIP24412.5",
    type: "Pipe",
    designation: "PIP24412.5",
    shape: "PIPE",
    country_code: "German",
    ax_global: "91,11",
    d_global: "244,5",
    i_global: "6147,42",
    t_global: "12,5",
    z_global: "672,8",
  },
  {
    profile_section_id: 9110,
    name: "PIP27312.5",
    type: "Pipe",
    designation: "PIP27312.5",
    shape: "PIPE",
    country_code: "German",
    ax_global: "102,3",
    d_global: 273,
    i_global: "8697,45",
    t_global: "12,5",
    z_global: "848,25",
  },
  {
    profile_section_id: 9111,
    name: "PIP32312.5",
    type: "Pipe",
    designation: "PIP32312.5",
    shape: "PIPE",
    country_code: "German",
    ax_global: "122,29",
    d_global: "323,9",
    i_global: "14846,53",
    t_global: "12,5",
    z_global: "1212,13",
  },
  {
    profile_section_id: 9112,
    name: "PIP35512.5",
    type: "Pipe",
    designation: "PIP35512.5",
    shape: "PIPE",
    country_code: "German",
    ax_global: "134,74",
    d_global: "355,6",
    i_global: "19852,18",
    t_global: "12,5",
    z_global: "1471,47",
  },
  {
    profile_section_id: 9113,
    name: "PIP40612.5",
    type: "Pipe",
    designation: "PIP40612.5",
    shape: "PIPE",
    country_code: "German",
    ax_global: "154,68",
    d_global: "406,4",
    i_global: "30030,67",
    t_global: "12,5",
    z_global: "1939,46",
  },
  {
    profile_section_id: 9114,
    name: "PIP212.3",
    type: "Pipe",
    designation: "PIP212.3",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "1,37",
    d_global: "21,3",
    i_global: "0,63",
    t_global: "2,3",
    z_global: "0,83",
  },
  {
    profile_section_id: 9115,
    name: "PIP262.3",
    type: "Pipe",
    designation: "PIP262.3",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "1,79",
    d_global: "26,9",
    i_global: "1,36",
    t_global: "2,3",
    z_global: "1,4",
  },
  {
    profile_section_id: 9116,
    name: "PIP482.9",
    type: "Pipe",
    designation: "PIP482.9",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "4,14",
    d_global: "48,3",
    i_global: "10,7",
    t_global: "2,9",
    z_global: "5,99",
  },
  {
    profile_section_id: 9117,
    name: "PIP602.9",
    type: "Pipe",
    designation: "PIP602.9",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "5,23",
    d_global: "60,3",
    i_global: "21,59",
    t_global: "2,9",
    z_global: "9,56",
  },
  {
    profile_section_id: 9118,
    name: "PIP762.9",
    type: "Pipe",
    designation: "PIP762.9",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "6,67",
    d_global: "76,1",
    i_global: "44,74",
    t_global: "2,9",
    z_global: "15,55",
  },
  {
    profile_section_id: 9119,
    name: "PIP1013.6",
    type: "Pipe",
    designation: "PIP1013.6",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "11,08",
    d_global: "101,6",
    i_global: "133,2",
    t_global: "3,6",
    z_global: "34,59",
  },
  {
    profile_section_id: 9120,
    name: "PIP1933.6",
    type: "Pipe",
    designation: "PIP1933.6",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "21,5",
    d_global: "193,7",
    i_global: "971,5",
    t_global: "3,6",
    z_global: "130,1",
  },
  {
    profile_section_id: 9121,
    name: "PIP1684.5",
    type: "Pipe",
    designation: "PIP1684.5",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "23,16",
    d_global: "168,3",
    i_global: "777,2",
    t_global: "4,5",
    z_global: "120,8",
  },
  {
    profile_section_id: 9122,
    name: "PIP2193.6",
    type: "Pipe",
    designation: "PIP2193.6",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "24,37",
    d_global: "219,1",
    i_global: 1415,
    t_global: "3,6",
    z_global: "167,2",
  },
  {
    profile_section_id: 9123,
    name: "PIP2733.6",
    type: "Pipe",
    designation: "PIP2733.6",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "30,47",
    d_global: 273,
    i_global: 2765,
    t_global: "3,6",
    z_global: "261,3",
  },
  {
    profile_section_id: 9124,
    name: "PIP3234.0",
    type: "Pipe",
    designation: "PIP3234.0",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "40,2",
    d_global: 323,
    i_global: 5143,
    t_global: 4,
    z_global: "409,4",
  },
  {
    profile_section_id: 9125,
    name: "PIP4064.5",
    type: "Pipe",
    designation: "PIP4064.5",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "56,82",
    d_global: "406,4",
    i_global: 11470,
    t_global: "4,5",
    z_global: "726,9",
  },
  {
    profile_section_id: 9126,
    name: "PIP1143.6",
    type: "Pipe",
    designation: "PIP1143.6",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "12,52",
    d_global: "114,3",
    i_global: 192,
    t_global: "3,6",
    z_global: "44,13",
  },
  {
    profile_section_id: 9127,
    name: "PIP1394.0",
    type: "Pipe",
    designation: "PIP1394.0",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "17,05",
    d_global: "139,7",
    i_global: "392,9",
    t_global: 4,
    z_global: "73,68",
  },
  {
    profile_section_id: 9128,
    name: "PIP16810.0",
    type: "Pipe",
    designation: "PIP16810.0",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "49,73",
    d_global: "168,3",
    i_global: 1564,
    t_global: 10,
    z_global: "250,9",
  },
  {
    profile_section_id: 9129,
    name: "PIP16812.5",
    type: "Pipe",
    designation: "PIP16812.5",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "61,18",
    d_global: "168,3",
    i_global: 1868,
    t_global: "12,5",
    z_global: "304,1",
  },
  {
    profile_section_id: 9130,
    name: "PIP1683.6",
    type: "Pipe",
    designation: "PIP1683.6",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "18,63",
    d_global: "168,3",
    i_global: "631,9",
    t_global: "3,6",
    z_global: "97,67",
  },
  {
    profile_section_id: 9131,
    name: "PIP1686.3",
    type: "Pipe",
    designation: "PIP1686.3",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "32,06",
    d_global: "168,3",
    i_global: 1053,
    t_global: "6,3",
    z_global: "165,4",
  },
  {
    profile_section_id: 9132,
    name: "PIP19310.0",
    type: "Pipe",
    designation: "PIP19310.0",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "57,71",
    d_global: "193,7",
    i_global: 2442,
    t_global: 10,
    z_global: "337,8",
  },
  {
    profile_section_id: 9133,
    name: "PIP19312.5",
    type: "Pipe",
    designation: "PIP19312.5",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "71,16",
    d_global: "193,7",
    i_global: 2934,
    t_global: "12,5",
    z_global: "411,1",
  },
  {
    profile_section_id: 9134,
    name: "PIP1936.3",
    type: "Pipe",
    designation: "PIP1936.3",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "37,09",
    d_global: "193,7",
    i_global: 1630,
    t_global: "6,3",
    z_global: "221,3",
  },
  {
    profile_section_id: 9135,
    name: "PIP21910.0",
    type: "Pipe",
    designation: "PIP21910.0",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "65,69",
    d_global: "219,1",
    i_global: 3598,
    t_global: 10,
    z_global: "437,6",
  },
  {
    profile_section_id: 9136,
    name: "PIP21912.5",
    type: "Pipe",
    designation: "PIP21912.5",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "81,13",
    d_global: "219,1",
    i_global: 4345,
    t_global: "12,5",
    z_global: "534,2",
  },
  {
    profile_section_id: 9137,
    name: "PIP21916.0",
    type: "Pipe",
    designation: "PIP21916.0",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "102,1",
    d_global: "219,1",
    i_global: 5297,
    t_global: 16,
    z_global: "661,4",
  },
  {
    profile_section_id: 9138,
    name: "PIP2196.3",
    type: "Pipe",
    designation: "PIP2196.3",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "42,12",
    d_global: "219,1",
    i_global: 2386,
    t_global: "6,3",
    z_global: "285,4",
  },
  {
    profile_section_id: 9139,
    name: "PIP24410.0",
    type: "Pipe",
    designation: "PIP24410.0",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "73,67",
    d_global: "244,5",
    i_global: 5073,
    t_global: 10,
    z_global: "550,2",
  },
  {
    profile_section_id: 9140,
    name: "PIP24412.5",
    type: "Pipe",
    designation: "PIP24412.5",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "91,11",
    d_global: "244,5",
    i_global: 5073,
    t_global: "12,5",
    z_global: "550,2",
  },
  {
    profile_section_id: 9141,
    name: "PIP2446.3",
    type: "Pipe",
    designation: "PIP2446.3",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "47,14",
    d_global: "244,5",
    i_global: 3346,
    t_global: "6,3",
    z_global: "357,5",
  },
  {
    profile_section_id: 9142,
    name: "PIP2448.0",
    type: "Pipe",
    designation: "PIP2448.0",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "59,44",
    d_global: "244,5",
    i_global: 4160,
    t_global: 8,
    z_global: "447,6",
  },
  {
    profile_section_id: 9143,
    name: "PIP27310.0",
    type: "Pipe",
    designation: "PIP27310.0",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "82,62",
    d_global: 273,
    i_global: 7154,
    t_global: 10,
    z_global: 692,
  },
  {
    profile_section_id: 9144,
    name: "PIP27312.5",
    type: "Pipe",
    designation: "PIP27312.5",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "102,3",
    d_global: 273,
    i_global: 8697,
    t_global: "12,5",
    z_global: "848,9",
  },
  {
    profile_section_id: 9145,
    name: "PIP2736.3",
    type: "Pipe",
    designation: "PIP2736.3",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "52,78",
    d_global: 273,
    i_global: 4696,
    t_global: "6,3",
    z_global: "448,2",
  },
  {
    profile_section_id: 9146,
    name: "PIP3236.3",
    type: "Pipe",
    designation: "PIP3236.3",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "62,85",
    d_global: 323,
    i_global: 5143,
    t_global: "6,3",
    z_global: "635,6",
  },
  {
    profile_section_id: 9147,
    name: "PIP332.6",
    type: "Pipe",
    designation: "PIP332.6",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "2,54",
    d_global: "33,7",
    i_global: "3,09",
    t_global: "2,6",
    z_global: "2,52",
  },
  {
    profile_section_id: 9148,
    name: "PIP333.2",
    type: "Pipe",
    designation: "PIP333.2",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "3,07",
    d_global: "33,7",
    i_global: "3,6",
    t_global: "3,2",
    z_global: "2,99",
  },
  {
    profile_section_id: 9149,
    name: "PIP35510.0",
    type: "Pipe",
    designation: "PIP35510.0",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "108,6",
    d_global: "355,6",
    i_global: 16220,
    t_global: 10,
    z_global: 1195,
  },
  {
    profile_section_id: 9150,
    name: "PIP3558.0",
    type: "Pipe",
    designation: "PIP3558.0",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "87,36",
    d_global: "355,6",
    i_global: 13200,
    t_global: 8,
    z_global: "966,8",
  },
  {
    profile_section_id: 9151,
    name: "PIP4066.3",
    type: "Pipe",
    designation: "PIP4066.3",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "79,19",
    d_global: "406,4",
    i_global: 15850,
    t_global: "6,3",
    z_global: 1009,
  },
  {
    profile_section_id: 9152,
    name: "PIP422.6",
    type: "Pipe",
    designation: "PIP422.6",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "3,25",
    d_global: "42,4",
    i_global: "6,46",
    t_global: "2,6",
    z_global: "4,12",
  },
  {
    profile_section_id: 9153,
    name: "PIP423.2",
    type: "Pipe",
    designation: "PIP423.2",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "3,94",
    d_global: "42,4",
    i_global: "7,62",
    t_global: "3,2",
    z_global: "4,93",
  },
  {
    profile_section_id: 9154,
    name: "PIP483.2",
    type: "Pipe",
    designation: "PIP483.2",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "4,53",
    d_global: "48,3",
    i_global: "11,6",
    t_global: "3,2",
    z_global: "6,52",
  },
  {
    profile_section_id: 9155,
    name: "PIP603.2",
    type: "Pipe",
    designation: "PIP603.2",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "5,74",
    d_global: "60,3",
    i_global: "23,47",
    t_global: "3,2",
    z_global: "10,44",
  },
  {
    profile_section_id: 9156,
    name: "PIP763.6",
    type: "Pipe",
    designation: "PIP763.6",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "8,2",
    d_global: "76,1",
    i_global: "54,01",
    t_global: "3,6",
    z_global: "18,94",
  },
  {
    profile_section_id: 9157,
    name: "PIP883.2",
    type: "Pipe",
    designation: "PIP883.2",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "8,62",
    d_global: "88,9",
    i_global: "79,2",
    t_global: "3,2",
    z_global: "23,51",
  },
  {
    profile_section_id: 9158,
    name: "PIP884.0",
    type: "Pipe",
    designation: "PIP884.0",
    shape: "PIPE",
    country_code: "Spanish",
    ax_global: "10,7",
    d_global: "88,9",
    i_global: "96,34",
    t_global: 4,
    z_global: "28,85",
  },
  {
    profile_section_id: 9159,
    name: "101.6 x 10 CHS",
    type: "Circle Hollow",
    designation: "101.6x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: "28,8",
    ax_global: 2880,
    c: 0,
    c_global: 0,
    i: 305,
    i_global: 3050000,
    od: "101,6",
    od_global: "101,6",
    tw: 10,
    tw_global: 10,
    z: "84,2",
    z_global: 84200,
  },
  {
    profile_section_id: 9160,
    name: "101.6 x 3.2 CHS",
    type: "Circle Hollow",
    designation: "101.6x3.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "9,89",
    ax_global: 989,
    c: 0,
    c_global: 0,
    i: 120,
    i_global: 1200000,
    od: "101,6",
    od_global: "101,6",
    tw: "3,2",
    tw_global: "3,2",
    z: 31,
    z_global: 31000,
  },
  {
    profile_section_id: 9161,
    name: "101.6 x 4 CHS",
    type: "Circle Hollow",
    designation: "101.6x4CHS",
    shape: "CHS",
    country_code: "European",
    ax: "12,3",
    ax_global: 1230,
    c: 0,
    c_global: 0,
    i: 146,
    i_global: 1460000,
    od: "101,6",
    od_global: "101,6",
    tw: 4,
    tw_global: 4,
    z: "38,1",
    z_global: 38100,
  },
  {
    profile_section_id: 9162,
    name: "101.6 x 5 CHS",
    type: "Circle Hollow",
    designation: "101.6x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "15,2",
    ax_global: 1520,
    c: 0,
    c_global: 0,
    i: 177,
    i_global: 1770000,
    od: "101,6",
    od_global: "101,6",
    tw: 5,
    tw_global: 5,
    z: "46,7",
    z_global: 46700,
  },
  {
    profile_section_id: 9163,
    name: "101.6 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "101.6x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "18,9",
    ax_global: 1890,
    c: 0,
    c_global: 0,
    i: 215,
    i_global: 2150000,
    od: "101,6",
    od_global: "101,6",
    tw: "6,3",
    tw_global: "6,3",
    z: "57,3",
    z_global: 57300,
  },
  {
    profile_section_id: 9164,
    name: "101.6 x 8 CHS",
    type: "Circle Hollow",
    designation: "101.6x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: "23,5",
    ax_global: 2350,
    c: 0,
    c_global: 0,
    i: 260,
    i_global: 2600000,
    od: "101,6",
    od_global: "101,6",
    tw: 8,
    tw_global: 8,
    z: "70,3",
    z_global: 70300,
  },
  {
    profile_section_id: 9165,
    name: "1016 x 10 CHS",
    type: "Circle Hollow",
    designation: "1016x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 316,
    ax_global: 31600,
    c: 0,
    c_global: 0,
    i: 399900,
    i_global: 3999000000,
    od: 1016,
    od_global: 1016,
    tw: 10,
    tw_global: 10,
    z: 10120,
    z_global: 10120000,
  },
  {
    profile_section_id: 9166,
    name: "1016 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "1016x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 394,
    ax_global: 39400,
    c: 0,
    c_global: 0,
    i: 496100,
    i_global: 4961000000,
    od: 1016,
    od_global: 1016,
    tw: "12,5",
    tw_global: "12,5",
    z: 12590,
    z_global: 12590000,
  },
  {
    profile_section_id: 9167,
    name: "1016 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "1016x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 447,
    ax_global: 44700,
    c: 0,
    c_global: 0,
    i: 561000,
    i_global: 5610000000,
    od: 1016,
    od_global: 1016,
    tw: "14,2",
    tw_global: "14,2",
    z: 14300,
    z_global: 14300000,
  },
  {
    profile_section_id: 9168,
    name: "1016 x 16 CHS",
    type: "Circle Hollow",
    designation: "1016x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 503,
    ax_global: 50300,
    c: 0,
    c_global: 0,
    i: 628480,
    i_global: 6284800000,
    od: 1016,
    od_global: 1016,
    tw: 16,
    tw_global: 16,
    z: 16000,
    z_global: 16000000,
  },
  {
    profile_section_id: 9169,
    name: "1016 x 20 CHS",
    type: "Circle Hollow",
    designation: "1016x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 626,
    ax_global: 62600,
    c: 0,
    c_global: 0,
    i: 776300,
    i_global: 7763000000,
    od: 1016,
    od_global: 1016,
    tw: 20,
    tw_global: 20,
    z: 19840,
    z_global: 19840000,
  },
  {
    profile_section_id: 9170,
    name: "1016 x 25 CHS",
    type: "Circle Hollow",
    designation: "1016x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 778,
    ax_global: 77800,
    c: 0,
    c_global: 0,
    i: 956100,
    i_global: 9561000000,
    od: 1016,
    od_global: 1016,
    tw: 25,
    tw_global: 25,
    z: 24560,
    z_global: 24560000,
  },
  {
    profile_section_id: 9171,
    name: "1016 x 30 CHS",
    type: "Circle Hollow",
    designation: "1016x30CHS",
    shape: "CHS",
    country_code: "European",
    ax: 929,
    ax_global: 92900,
    c: 0,
    c_global: 0,
    i: 1130000,
    i_global: 11300000000,
    od: 1016,
    od_global: 1016,
    tw: 30,
    tw_global: 30,
    z: 29180,
    z_global: 29180000,
  },
  {
    profile_section_id: 9172,
    name: "1016 x 8 CHS",
    type: "Circle Hollow",
    designation: "1016x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: 253,
    ax_global: 25300,
    c: 0,
    c_global: 0,
    i: 321800,
    i_global: 3218000000,
    od: 1016,
    od_global: 1016,
    tw: 8,
    tw_global: 8,
    z: 8129,
    z_global: 8129000,
  },
  {
    profile_section_id: 9173,
    name: "1067 x 10 CHS",
    type: "Circle Hollow",
    designation: "1067x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 332,
    ax_global: 33200,
    c: 0,
    c_global: 0,
    i: 463800,
    i_global: 4638000000,
    od: 1067,
    od_global: 1067,
    tw: 10,
    tw_global: 10,
    z: 11170,
    z_global: 11170000,
  },
  {
    profile_section_id: 9174,
    name: "1067 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "1067x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 414,
    ax_global: 41400,
    c: 0,
    c_global: 0,
    i: 575670,
    i_global: 5756700000,
    od: 1067,
    od_global: 1067,
    tw: "12,5",
    tw_global: "12,5",
    z: 13900,
    z_global: 13900000,
  },
  {
    profile_section_id: 9175,
    name: "1067 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "1067x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 470,
    ax_global: 47000,
    c: 0,
    c_global: 0,
    i: 651000,
    i_global: 6510000000,
    od: 1067,
    od_global: 1067,
    tw: "14,2",
    tw_global: "14,2",
    z: 15700,
    z_global: 15700000,
  },
  {
    profile_section_id: 9176,
    name: "1067 x 16 CHS",
    type: "Circle Hollow",
    designation: "1067x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 528,
    ax_global: 52800,
    c: 0,
    c_global: 0,
    i: 729610,
    i_global: 7296100000,
    od: 1067,
    od_global: 1067,
    tw: 16,
    tw_global: 16,
    z: 17680,
    z_global: 17680000,
  },
  {
    profile_section_id: 9177,
    name: "1067 x 20 CHS",
    type: "Circle Hollow",
    designation: "1067x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 658,
    ax_global: 65800,
    c: 0,
    c_global: 0,
    i: 901800,
    i_global: 9018000000,
    od: 1067,
    od_global: 1067,
    tw: 20,
    tw_global: 20,
    z: 21930,
    z_global: 21930000,
  },
  {
    profile_section_id: 9178,
    name: "1067 x 25 CHS",
    type: "Circle Hollow",
    designation: "1067x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 818,
    ax_global: 81800,
    c: 0,
    c_global: 0,
    i: 1111000,
    i_global: 11110000000,
    od: 1067,
    od_global: 1067,
    tw: 25,
    tw_global: 25,
    z: 27150,
    z_global: 27150000,
  },
  {
    profile_section_id: 9179,
    name: "1067 x 30 CHS",
    type: "Circle Hollow",
    designation: "1067x30CHS",
    shape: "CHS",
    country_code: "European",
    ax: 977,
    ax_global: 97700,
    c: 0,
    c_global: 0,
    i: 1315000,
    i_global: 13150000000,
    od: 1067,
    od_global: 1067,
    tw: 30,
    tw_global: 30,
    z: 32270,
    z_global: 32270000,
  },
  {
    profile_section_id: 9180,
    name: "114.3 x 10 CHS",
    type: "Circle Hollow",
    designation: "114.3x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: "32,8",
    ax_global: 3280,
    c: 0,
    c_global: 0,
    i: 450,
    i_global: 4500000,
    od: "114,3",
    od_global: "114,3",
    tw: 10,
    tw_global: 10,
    z: 109,
    z_global: 109000,
  },
  {
    profile_section_id: 9181,
    name: "114.3 x 3.2 CHS",
    type: "Circle Hollow",
    designation: "114.3x3.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "11,2",
    ax_global: 1120,
    c: 0,
    c_global: 0,
    i: 172,
    i_global: 1720000,
    od: "114,3",
    od_global: "114,3",
    tw: "3,2",
    tw_global: "3,2",
    z: "39,5",
    z_global: 39500,
  },
  {
    profile_section_id: 9182,
    name: "114.3 x 4 CHS",
    type: "Circle Hollow",
    designation: "114.3x4CHS",
    shape: "CHS",
    country_code: "European",
    ax: "13,9",
    ax_global: 1390,
    c: 0,
    c_global: 0,
    i: 211,
    i_global: 2110000,
    od: "114,3",
    od_global: "114,3",
    tw: 4,
    tw_global: 4,
    z: "48,7",
    z_global: 48700,
  },
  {
    profile_section_id: 9183,
    name: "114.3 x 5 CHS",
    type: "Circle Hollow",
    designation: "114.3x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "17,2",
    ax_global: 1720,
    c: 0,
    c_global: 0,
    i: 257,
    i_global: 2570000,
    od: "114,3",
    od_global: "114,3",
    tw: 5,
    tw_global: 5,
    z: "59,8",
    z_global: 59800,
  },
  {
    profile_section_id: 9184,
    name: "114.3 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "114.3x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "21,4",
    ax_global: 2140,
    c: 0,
    c_global: 0,
    i: 313,
    i_global: 3130000,
    od: "114,3",
    od_global: "114,3",
    tw: "6,3",
    tw_global: "6,3",
    z: "73,6",
    z_global: 73600,
  },
  {
    profile_section_id: 9185,
    name: "114.3 x 8 CHS",
    type: "Circle Hollow",
    designation: "114.3x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: "26,7",
    ax_global: 2670,
    c: 0,
    c_global: 0,
    i: 379,
    i_global: 3790000,
    od: "114,3",
    od_global: "114,3",
    tw: 8,
    tw_global: 8,
    z: "90,6",
    z_global: 90600,
  },
  {
    profile_section_id: 9186,
    name: "1168 x 10 CHS",
    type: "Circle Hollow",
    designation: "1168x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 364,
    ax_global: 36400,
    c: 0,
    c_global: 0,
    i: 609800,
    i_global: 6098000000,
    od: 1168,
    od_global: 1168,
    tw: 10,
    tw_global: 10,
    z: 13410,
    z_global: 13410000,
  },
  {
    profile_section_id: 9187,
    name: "1168 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "1168x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 454,
    ax_global: 45400,
    c: 0,
    c_global: 0,
    i: 757400,
    i_global: 7574000000,
    od: 1168,
    od_global: 1168,
    tw: "12,5",
    tw_global: "12,5",
    z: 16690,
    z_global: 16690000,
  },
  {
    profile_section_id: 9188,
    name: "1168 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "1168x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 515,
    ax_global: 51500,
    c: 0,
    c_global: 0,
    i: 856700,
    i_global: 8567000000,
    od: 1168,
    od_global: 1168,
    tw: "14,2",
    tw_global: "14,2",
    z: 18910,
    z_global: 18910000,
  },
  {
    profile_section_id: 9189,
    name: "1168 x 16 CHS",
    type: "Circle Hollow",
    designation: "1168x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 579,
    ax_global: 57900,
    c: 0,
    c_global: 0,
    i: 960800,
    i_global: 9608000000,
    od: 1168,
    od_global: 1168,
    tw: 16,
    tw_global: 16,
    z: 21240,
    z_global: 21240000,
  },
  {
    profile_section_id: 9190,
    name: "1168 x 20 CHS",
    type: "Circle Hollow",
    designation: "1168x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 721,
    ax_global: 72100,
    c: 0,
    c_global: 0,
    i: 1189000,
    i_global: 11890000000,
    od: 1168,
    od_global: 1168,
    tw: 20,
    tw_global: 20,
    z: 26360,
    z_global: 26360000,
  },
  {
    profile_section_id: 9191,
    name: "1168 x 25 CHS",
    type: "Circle Hollow",
    designation: "1168x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 898,
    ax_global: 89800,
    c: 0,
    c_global: 0,
    i: 1467000,
    i_global: 14670000000,
    od: 1168,
    od_global: 1168,
    tw: 25,
    tw_global: 25,
    z: 32670,
    z_global: 32670000,
  },
  {
    profile_section_id: 9192,
    name: "1219 x 10 CHS",
    type: "Circle Hollow",
    designation: "1219x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 380,
    ax_global: 38000,
    c: 0,
    c_global: 0,
    i: 694000,
    i_global: 6940000000,
    od: 1219,
    od_global: 1219,
    tw: 10,
    tw_global: 10,
    z: 14620,
    z_global: 14620000,
  },
  {
    profile_section_id: 9193,
    name: "1219 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "1219x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 474,
    ax_global: 47400,
    c: 0,
    c_global: 0,
    i: 862200,
    i_global: 8622000000,
    od: 1219,
    od_global: 1219,
    tw: "12,5",
    tw_global: "12,5",
    z: 18200,
    z_global: 18200000,
  },
  {
    profile_section_id: 9194,
    name: "1219 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "1219x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 537,
    ax_global: 53700,
    c: 0,
    c_global: 0,
    i: 975300,
    i_global: 9753000000,
    od: 1219,
    od_global: 1219,
    tw: "14,2",
    tw_global: "14,2",
    z: 20610,
    z_global: 20610000,
  },
  {
    profile_section_id: 9195,
    name: "1219 x 16 CHS",
    type: "Circle Hollow",
    designation: "1219x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 605,
    ax_global: 60500,
    c: 0,
    c_global: 0,
    i: 1094000,
    i_global: 10940000000,
    od: 1219,
    od_global: 1219,
    tw: 16,
    tw_global: 16,
    z: 23160,
    z_global: 23160000,
  },
  {
    profile_section_id: 9196,
    name: "1219 x 20 CHS",
    type: "Circle Hollow",
    designation: "1219x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 753,
    ax_global: 75300,
    c: 0,
    c_global: 0,
    i: 1354000,
    i_global: 13540000000,
    od: 1219,
    od_global: 1219,
    tw: 20,
    tw_global: 20,
    z: 28760,
    z_global: 28760000,
  },
  {
    profile_section_id: 9197,
    name: "1219 x 25 CHS",
    type: "Circle Hollow",
    designation: "1219x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 938,
    ax_global: 93800,
    c: 0,
    c_global: 0,
    i: 1672000,
    i_global: 16720000000,
    od: 1219,
    od_global: 1219,
    tw: 25,
    tw_global: 25,
    z: 35650,
    z_global: 35650000,
  },
  {
    profile_section_id: 9198,
    name: "139.7 x 10 CHS",
    type: "Circle Hollow",
    designation: "139.7x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: "40,7",
    ax_global: 4070,
    c: 0,
    c_global: 0,
    i: 862,
    i_global: 8620000,
    od: "139,7",
    od_global: "139,7",
    tw: 10,
    tw_global: 10,
    z: 169,
    z_global: 169000,
  },
  {
    profile_section_id: 9199,
    name: "139.7 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "139.7x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 50,
    ax_global: 5000,
    c: 0,
    c_global: 0,
    i: 1020,
    i_global: 10200000,
    od: "139,7",
    od_global: "139,7",
    tw: "12,5",
    tw_global: "12,5",
    z: 203,
    z_global: 203000,
  },
  {
    profile_section_id: 9200,
    name: "139.7 x 4 CHS",
    type: "Circle Hollow",
    designation: "139.7x4CHS",
    shape: "CHS",
    country_code: "European",
    ax: "17,1",
    ax_global: 1710,
    c: 0,
    c_global: 0,
    i: 393,
    i_global: 3930000,
    od: "139,7",
    od_global: "139,7",
    tw: 4,
    tw_global: 4,
    z: "73,7",
    z_global: 73700,
  },
  {
    profile_section_id: 9201,
    name: "139.7 x 5 CHS",
    type: "Circle Hollow",
    designation: "139.7x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "21,2",
    ax_global: 2120,
    c: 0,
    c_global: 0,
    i: 481,
    i_global: 4810000,
    od: "139,7",
    od_global: "139,7",
    tw: 5,
    tw_global: 5,
    z: "90,8",
    z_global: 90800,
  },
  {
    profile_section_id: 9202,
    name: "139.7 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "139.7x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "26,4",
    ax_global: 2640,
    c: 0,
    c_global: 0,
    i: 589,
    i_global: 5890000,
    od: "139,7",
    od_global: "139,7",
    tw: "6,3",
    tw_global: "6,3",
    z: 112,
    z_global: 112000,
  },
  {
    profile_section_id: 9203,
    name: "139.7 x 8 CHS",
    type: "Circle Hollow",
    designation: "139.7x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: "33,1",
    ax_global: 3310,
    c: 0,
    c_global: 0,
    i: 720,
    i_global: 7200000,
    od: "139,7",
    od_global: "139,7",
    tw: 8,
    tw_global: 8,
    z: 139,
    z_global: 139000,
  },
  {
    profile_section_id: 9204,
    name: "168.3 x 10 CHS",
    type: "Circle Hollow",
    designation: "168.3x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: "49,7",
    ax_global: 4970,
    c: 0,
    c_global: 0,
    i: 1564,
    i_global: 15640000,
    od: "168,3",
    od_global: "168,3",
    tw: 10,
    tw_global: 10,
    z: 251,
    z_global: 251000,
  },
  {
    profile_section_id: 9205,
    name: "168.3 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "168.3x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "61,2",
    ax_global: 6120,
    c: 0,
    c_global: 0,
    i: 1868,
    i_global: 18680000,
    od: "168,3",
    od_global: "168,3",
    tw: "12,5",
    tw_global: "12,5",
    z: 304,
    z_global: 304000,
  },
  {
    profile_section_id: 9206,
    name: "168.3 x 4 CHS",
    type: "Circle Hollow",
    designation: "168.3x4CHS",
    shape: "CHS",
    country_code: "European",
    ax: "20,6",
    ax_global: 2060,
    c: 0,
    c_global: 0,
    i: 697,
    i_global: 6970000,
    od: "168,3",
    od_global: "168,3",
    tw: 4,
    tw_global: 4,
    z: 108,
    z_global: 108000,
  },
  {
    profile_section_id: 9207,
    name: "168.3 x 5 CHS",
    type: "Circle Hollow",
    designation: "168.3x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "25,7",
    ax_global: 2570,
    c: 0,
    c_global: 0,
    i: 856,
    i_global: 8560000,
    od: "168,3",
    od_global: "168,3",
    tw: 5,
    tw_global: 5,
    z: 133,
    z_global: 133000,
  },
  {
    profile_section_id: 9208,
    name: "168.3 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "168.3x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "32,1",
    ax_global: 3210,
    c: 0,
    c_global: 0,
    i: 1053,
    i_global: 10530000,
    od: "168,3",
    od_global: "168,3",
    tw: "6,3",
    tw_global: "6,3",
    z: 165,
    z_global: 165000,
  },
  {
    profile_section_id: 9209,
    name: "168.3 x 8 CHS",
    type: "Circle Hollow",
    designation: "168.3x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: "40,3",
    ax_global: 4030,
    c: 0,
    c_global: 0,
    i: 1297,
    i_global: 12970000,
    od: "168,3",
    od_global: "168,3",
    tw: 8,
    tw_global: 8,
    z: 206,
    z_global: 206000,
  },
  {
    profile_section_id: 9210,
    name: "177.8 x 10 CHS",
    type: "Circle Hollow",
    designation: "177.8x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: "52,7",
    ax_global: 5270,
    c: 0,
    c_global: 0,
    i: 1862,
    i_global: 18620000,
    od: "177,8",
    od_global: "177,8",
    tw: 10,
    tw_global: 10,
    z: 282,
    z_global: 282000,
  },
  {
    profile_section_id: 9211,
    name: "177.8 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "177.8x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "64,9",
    ax_global: 6490,
    c: 0,
    c_global: 0,
    i: 2230,
    i_global: 22300000,
    od: "177,8",
    od_global: "177,8",
    tw: "12,5",
    tw_global: "12,5",
    z: 342,
    z_global: 342000,
  },
  {
    profile_section_id: 9212,
    name: "177.8 x 5 CHS",
    type: "Circle Hollow",
    designation: "177.8x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "27,1",
    ax_global: 2710,
    c: 0,
    c_global: 0,
    i: 1014,
    i_global: 10140000,
    od: "177,8",
    od_global: "177,8",
    tw: 5,
    tw_global: 5,
    z: 149,
    z_global: 149000,
  },
  {
    profile_section_id: 9213,
    name: "177.8 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "177.8x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "33,9",
    ax_global: 3390,
    c: 0,
    c_global: 0,
    i: 1250,
    i_global: 12500000,
    od: "177,8",
    od_global: "177,8",
    tw: "6,3",
    tw_global: "6,3",
    z: 185,
    z_global: 185000,
  },
  {
    profile_section_id: 9214,
    name: "177.8 x 8 CHS",
    type: "Circle Hollow",
    designation: "177.8x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: "42,7",
    ax_global: 4270,
    c: 0,
    c_global: 0,
    i: 1541,
    i_global: 15410000,
    od: "177,8",
    od_global: "177,8",
    tw: 8,
    tw_global: 8,
    z: 231,
    z_global: 231000,
  },
  {
    profile_section_id: 9215,
    name: "193.7 x 10 CHS",
    type: "Circle Hollow",
    designation: "193.7x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: "57,7",
    ax_global: 5770,
    c: 0,
    c_global: 0,
    i: 2442,
    i_global: 24420000,
    od: "193,7",
    od_global: "193,7",
    tw: 10,
    tw_global: 10,
    z: 338,
    z_global: 338000,
  },
  {
    profile_section_id: 9216,
    name: "193.7 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "193.7x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "71,2",
    ax_global: 7120,
    c: 0,
    c_global: 0,
    i: 2934,
    i_global: 29340000,
    od: "193,7",
    od_global: "193,7",
    tw: "12,5",
    tw_global: "12,5",
    z: 411,
    z_global: 411000,
  },
  {
    profile_section_id: 9217,
    name: "193.7 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "193.7x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "80,1",
    ax_global: 8010,
    c: 0,
    c_global: 0,
    i: 3245,
    i_global: 32450000,
    od: "193,7",
    od_global: "193,7",
    tw: "14,2",
    tw_global: "14,2",
    z: 458,
    z_global: 458000,
  },
  {
    profile_section_id: 9218,
    name: "193.7 x 16 CHS",
    type: "Circle Hollow",
    designation: "193.7x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: "89,3",
    ax_global: 8930,
    c: 0,
    c_global: 0,
    i: 3554,
    i_global: 35540000,
    od: "193,7",
    od_global: "193,7",
    tw: 16,
    tw_global: 16,
    z: 507,
    z_global: 507000,
  },
  {
    profile_section_id: 9219,
    name: "193.7 x 5 CHS",
    type: "Circle Hollow",
    designation: "193.7x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "29,6",
    ax_global: 2960,
    c: 0,
    c_global: 0,
    i: 1320,
    i_global: 13200000,
    od: "193,7",
    od_global: "193,7",
    tw: 5,
    tw_global: 5,
    z: 178,
    z_global: 178000,
  },
  {
    profile_section_id: 9220,
    name: "193.7 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "193.7x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "37,1",
    ax_global: 3710,
    c: 0,
    c_global: 0,
    i: 1630,
    i_global: 16300000,
    od: "193,7",
    od_global: "193,7",
    tw: "6,3",
    tw_global: "6,3",
    z: 221,
    z_global: 221000,
  },
  {
    profile_section_id: 9221,
    name: "193.7 x 8 CHS",
    type: "Circle Hollow",
    designation: "193.7x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: "46,7",
    ax_global: 4670,
    c: 0,
    c_global: 0,
    i: 2016,
    i_global: 20160000,
    od: "193,7",
    od_global: "193,7",
    tw: 8,
    tw_global: 8,
    z: 276,
    z_global: 276000,
  },
  {
    profile_section_id: 9222,
    name: "21.3 x 2.3 CHS",
    type: "Circle Hollow",
    designation: "21.3x2.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "1,37",
    ax_global: 137,
    c: 0,
    c_global: 0,
    i: "0,63",
    i_global: 6300,
    od: "21,3",
    od_global: "21,3",
    tw: "2,3",
    tw_global: "2,3",
    z: "0,83",
    z_global: 830,
  },
  {
    profile_section_id: 9223,
    name: "21.3 x 2.6 CHS",
    type: "Circle Hollow",
    designation: "21.3x2.6CHS",
    shape: "CHS",
    country_code: "European",
    ax: "1,53",
    ax_global: 153,
    c: 0,
    c_global: 0,
    i: "0,68",
    i_global: 6800,
    od: "21,3",
    od_global: "21,3",
    tw: "2,6",
    tw_global: "2,6",
    z: "0,92",
    z_global: 920,
  },
  {
    profile_section_id: 9224,
    name: "21.3 x 3.2 CHS",
    type: "Circle Hollow",
    designation: "21.3x3.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "1,82",
    ax_global: 182,
    c: 0,
    c_global: 0,
    i: "0,77",
    i_global: 7700,
    od: "21,3",
    od_global: "21,3",
    tw: "3,2",
    tw_global: "3,2",
    z: "1,06",
    z_global: 1060,
  },
  {
    profile_section_id: 9225,
    name: "219.1 x 10 CHS",
    type: "Circle Hollow",
    designation: "219.1x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: "65,7",
    ax_global: 6570,
    c: 0,
    c_global: 0,
    i: 3598,
    i_global: 35980000,
    od: "219,1",
    od_global: "219,1",
    tw: 10,
    tw_global: 10,
    z: 438,
    z_global: 438000,
  },
  {
    profile_section_id: 9226,
    name: "219.1 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "219.1x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "81,1",
    ax_global: 8110,
    c: 0,
    c_global: 0,
    i: 4345,
    i_global: 43450000,
    od: "219,1",
    od_global: "219,1",
    tw: "12,5",
    tw_global: "12,5",
    z: 534,
    z_global: 534000,
  },
  {
    profile_section_id: 9227,
    name: "219.1 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "219.1x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "91,4",
    ax_global: 9140,
    c: 0,
    c_global: 0,
    i: 4820,
    i_global: 48200000,
    od: "219,1",
    od_global: "219,1",
    tw: "14,2",
    tw_global: "14,2",
    z: 597,
    z_global: 597000,
  },
  {
    profile_section_id: 9228,
    name: "219.1 x 16 CHS",
    type: "Circle Hollow",
    designation: "219.1x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 102,
    ax_global: 10200,
    c: 0,
    c_global: 0,
    i: 5297,
    i_global: 52970000,
    od: "219,1",
    od_global: "219,1",
    tw: 16,
    tw_global: 16,
    z: 661,
    z_global: 661000,
  },
  {
    profile_section_id: 9229,
    name: "219.1 x 20 CHS",
    type: "Circle Hollow",
    designation: "219.1x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 125,
    ax_global: 12500,
    c: 0,
    c_global: 0,
    i: 6261,
    i_global: 62610000,
    od: "219,1",
    od_global: "219,1",
    tw: 20,
    tw_global: 20,
    z: 795,
    z_global: 795000,
  },
  {
    profile_section_id: 9230,
    name: "219.1 x 5 CHS",
    type: "Circle Hollow",
    designation: "219.1x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "33,6",
    ax_global: 3360,
    c: 0,
    c_global: 0,
    i: 1928,
    i_global: 19280000,
    od: "219,1",
    od_global: "219,1",
    tw: 5,
    tw_global: 5,
    z: 229,
    z_global: 229000,
  },
  {
    profile_section_id: 9231,
    name: "219.1 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "219.1x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "42,1",
    ax_global: 4210,
    c: 0,
    c_global: 0,
    i: 2386,
    i_global: 23860000,
    od: "219,1",
    od_global: "219,1",
    tw: "6,3",
    tw_global: "6,3",
    z: 285,
    z_global: 285000,
  },
  {
    profile_section_id: 9232,
    name: "219.1 x 8 CHS",
    type: "Circle Hollow",
    designation: "219.1x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: "53,1",
    ax_global: 5310,
    c: 0,
    c_global: 0,
    i: 2960,
    i_global: 29600000,
    od: "219,1",
    od_global: "219,1",
    tw: 8,
    tw_global: 8,
    z: 357,
    z_global: 357000,
  },
  {
    profile_section_id: 9233,
    name: "244.5 x 10 CHS",
    type: "Circle Hollow",
    designation: "244.5x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: "73,7",
    ax_global: 7370,
    c: 0,
    c_global: 0,
    i: 5073,
    i_global: 50730000,
    od: "244,5",
    od_global: "244,5",
    tw: 10,
    tw_global: 10,
    z: 550,
    z_global: 550000,
  },
  {
    profile_section_id: 9234,
    name: "244.5 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "244.5x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "91,1",
    ax_global: 9110,
    c: 0,
    c_global: 0,
    i: 6147,
    i_global: 61470000,
    od: "244,5",
    od_global: "244,5",
    tw: "12,5",
    tw_global: "12,5",
    z: 673,
    z_global: 673000,
  },
  {
    profile_section_id: 9235,
    name: "244.5 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "244.5x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "102,7",
    ax_global: 10270,
    c: 0,
    c_global: 0,
    i: 6837,
    i_global: 68370000,
    od: "244,5",
    od_global: "244,5",
    tw: "14,2",
    tw_global: "14,2",
    z: 754,
    z_global: 754000,
  },
  {
    profile_section_id: 9236,
    name: "244.5 x 16 CHS",
    type: "Circle Hollow",
    designation: "244.5x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 115,
    ax_global: 11500,
    c: 0,
    c_global: 0,
    i: 7533,
    i_global: 75330000,
    od: "244,5",
    od_global: "244,5",
    tw: 16,
    tw_global: 16,
    z: 837,
    z_global: 837000,
  },
  {
    profile_section_id: 9237,
    name: "244.5 x 20 CHS",
    type: "Circle Hollow",
    designation: "244.5x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 141,
    ax_global: 14100,
    c: 0,
    c_global: 0,
    i: 8957,
    i_global: 89570000,
    od: "244,5",
    od_global: "244,5",
    tw: 20,
    tw_global: 20,
    z: 1011,
    z_global: 1011000,
  },
  {
    profile_section_id: 9238,
    name: "244.5 x 25 CHS",
    type: "Circle Hollow",
    designation: "244.5x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 172,
    ax_global: 17200,
    c: 0,
    c_global: 0,
    i: 10520,
    i_global: 105200000,
    od: "244,5",
    od_global: "244,5",
    tw: 25,
    tw_global: 25,
    z: 1210,
    z_global: 1210000,
  },
  {
    profile_section_id: 9239,
    name: "244.5 x 5 CHS",
    type: "Circle Hollow",
    designation: "244.5x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "37,6",
    ax_global: 3760,
    c: 0,
    c_global: 0,
    i: 2699,
    i_global: 26990000,
    od: "244,5",
    od_global: "244,5",
    tw: 5,
    tw_global: 5,
    z: 287,
    z_global: 287000,
  },
  {
    profile_section_id: 9240,
    name: "244.5 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "244.5x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "47,1",
    ax_global: 4710,
    c: 0,
    c_global: 0,
    i: 3346,
    i_global: 33460000,
    od: "244,5",
    od_global: "244,5",
    tw: "6,3",
    tw_global: "6,3",
    z: 358,
    z_global: 358000,
  },
  {
    profile_section_id: 9241,
    name: "244.5 x 8 CHS",
    type: "Circle Hollow",
    designation: "244.5x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: "59,4",
    ax_global: 5940,
    c: 0,
    c_global: 0,
    i: 4160,
    i_global: 41600000,
    od: "244,5",
    od_global: "244,5",
    tw: 8,
    tw_global: 8,
    z: 448,
    z_global: 448000,
  },
  {
    profile_section_id: 9242,
    name: "26.9 x 2.3 CHS",
    type: "Circle Hollow",
    designation: "26.9x2.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "1,78",
    ax_global: 178,
    c: 0,
    c_global: 0,
    i: "1,36",
    i_global: 13600,
    od: "26,9",
    od_global: "26,9",
    tw: "2,3",
    tw_global: "2,3",
    z: "1,4",
    z_global: 1400,
  },
  {
    profile_section_id: 9243,
    name: "26.9 x 2.6 CHS",
    type: "Circle Hollow",
    designation: "26.9x2.6CHS",
    shape: "CHS",
    country_code: "European",
    ax: "1,98",
    ax_global: 198,
    c: 0,
    c_global: 0,
    i: "1,48",
    i_global: 14800,
    od: "26,9",
    od_global: "26,9",
    tw: "2,6",
    tw_global: "2,6",
    z: "1,54",
    z_global: 1540,
  },
  {
    profile_section_id: 9244,
    name: "26.9 x 3.2 CHS",
    type: "Circle Hollow",
    designation: "26.9x3.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "2,38",
    ax_global: 238,
    c: 0,
    c_global: 0,
    i: "1,7",
    i_global: 17000,
    od: "26,9",
    od_global: "26,9",
    tw: "3,2",
    tw_global: "3,2",
    z: "1,81",
    z_global: 1810,
  },
  {
    profile_section_id: 9245,
    name: "273 x 10 CHS",
    type: "Circle Hollow",
    designation: "273x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: "82,6",
    ax_global: 8260,
    c: 0,
    c_global: 0,
    i: 7154,
    i_global: 71540000,
    od: 273,
    od_global: 273,
    tw: 10,
    tw_global: 10,
    z: 692,
    z_global: 692000,
  },
  {
    profile_section_id: 9246,
    name: "273 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "273x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 102,
    ax_global: 10200,
    c: 0,
    c_global: 0,
    i: 8697,
    i_global: 86970000,
    od: 273,
    od_global: 273,
    tw: "12,5",
    tw_global: "12,5",
    z: 849,
    z_global: 849000,
  },
  {
    profile_section_id: 9247,
    name: "273 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "273x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 115,
    ax_global: 11500,
    c: 0,
    c_global: 0,
    i: 9695,
    i_global: 96950000,
    od: 273,
    od_global: 273,
    tw: "14,2",
    tw_global: "14,2",
    z: 952,
    z_global: 952000,
  },
  {
    profile_section_id: 9248,
    name: "273 x 16 CHS",
    type: "Circle Hollow",
    designation: "273x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 129,
    ax_global: 12900,
    c: 0,
    c_global: 0,
    i: 10710,
    i_global: 107100000,
    od: 273,
    od_global: 273,
    tw: 16,
    tw_global: 16,
    z: 1058,
    z_global: 1058000,
  },
  {
    profile_section_id: 9249,
    name: "273 x 20 CHS",
    type: "Circle Hollow",
    designation: "273x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 159,
    ax_global: 15900,
    c: 0,
    c_global: 0,
    i: 12800,
    i_global: 128000000,
    od: 273,
    od_global: 273,
    tw: 20,
    tw_global: 20,
    z: 1283,
    z_global: 1283000,
  },
  {
    profile_section_id: 9250,
    name: "273 x 25 CHS",
    type: "Circle Hollow",
    designation: "273x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 195,
    ax_global: 19500,
    c: 0,
    c_global: 0,
    i: 15130,
    i_global: 151300000,
    od: 273,
    od_global: 273,
    tw: 25,
    tw_global: 25,
    z: 1543,
    z_global: 1543000,
  },
  {
    profile_section_id: 9251,
    name: "273 x 5 CHS",
    type: "Circle Hollow",
    designation: "273x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "42,1",
    ax_global: 4210,
    c: 0,
    c_global: 0,
    i: 3781,
    i_global: 37810000,
    od: 273,
    od_global: 273,
    tw: 5,
    tw_global: 5,
    z: 359,
    z_global: 359000,
  },
  {
    profile_section_id: 9252,
    name: "273 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "273x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "52,8",
    ax_global: 5280,
    c: 0,
    c_global: 0,
    i: 4696,
    i_global: 46960000,
    od: 273,
    od_global: 273,
    tw: "6,3",
    tw_global: "6,3",
    z: 448,
    z_global: 448000,
  },
  {
    profile_section_id: 9253,
    name: "273 x 8 CHS",
    type: "Circle Hollow",
    designation: "273x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: "66,6",
    ax_global: 6660,
    c: 0,
    c_global: 0,
    i: 5852,
    i_global: 58520000,
    od: 273,
    od_global: 273,
    tw: 8,
    tw_global: 8,
    z: 562,
    z_global: 562000,
  },
  {
    profile_section_id: 9254,
    name: "323.9 x 10 CHS",
    type: "Circle Hollow",
    designation: "323.9x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: "98,6",
    ax_global: 9860,
    c: 0,
    c_global: 0,
    i: 12160,
    i_global: 121600000,
    od: "323,9",
    od_global: "323,9",
    tw: 10,
    tw_global: 10,
    z: 986,
    z_global: 986000,
  },
  {
    profile_section_id: 9255,
    name: "323.9 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "323.9x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 122,
    ax_global: 12200,
    c: 0,
    c_global: 0,
    i: 14850,
    i_global: 148500000,
    od: "323,9",
    od_global: "323,9",
    tw: "12,5",
    tw_global: "12,5",
    z: 1213,
    z_global: 1213000,
  },
  {
    profile_section_id: 9256,
    name: "323.9 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "323.9x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 138,
    ax_global: 13800,
    c: 0,
    c_global: 0,
    i: 16600,
    i_global: 166000000,
    od: "323,9",
    od_global: "323,9",
    tw: "14,2",
    tw_global: "14,2",
    z: 1363,
    z_global: 1363000,
  },
  {
    profile_section_id: 9257,
    name: "323.9 x 16 CHS",
    type: "Circle Hollow",
    designation: "323.9x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 155,
    ax_global: 15500,
    c: 0,
    c_global: 0,
    i: 18390,
    i_global: 183900000,
    od: "323,9",
    od_global: "323,9",
    tw: 16,
    tw_global: 16,
    z: 1518,
    z_global: 1518000,
  },
  {
    profile_section_id: 9258,
    name: "323.9 x 20 CHS",
    type: "Circle Hollow",
    designation: "323.9x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 191,
    ax_global: 19100,
    c: 0,
    c_global: 0,
    i: 22140,
    i_global: 221400000,
    od: "323,9",
    od_global: "323,9",
    tw: 20,
    tw_global: 20,
    z: 1850,
    z_global: 1850000,
  },
  {
    profile_section_id: 9259,
    name: "323.9 x 25 CHS",
    type: "Circle Hollow",
    designation: "323.9x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 235,
    ax_global: 23500,
    c: 0,
    c_global: 0,
    i: 26400,
    i_global: 264000000,
    od: "323,9",
    od_global: "323,9",
    tw: 25,
    tw_global: 25,
    z: 2239,
    z_global: 2239000,
  },
  {
    profile_section_id: 9260,
    name: "323.9 x 5 CHS",
    type: "Circle Hollow",
    designation: "323.9x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "50,1",
    ax_global: 5010,
    c: 0,
    c_global: 0,
    i: 6369,
    i_global: 63690000,
    od: "323,9",
    od_global: "323,9",
    tw: 5,
    tw_global: 5,
    z: 509,
    z_global: 509000,
  },
  {
    profile_section_id: 9261,
    name: "323.9 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "323.9x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "62,9",
    ax_global: 6290,
    c: 0,
    c_global: 0,
    i: 7929,
    i_global: 79290000,
    od: "323,9",
    od_global: "323,9",
    tw: "6,3",
    tw_global: "6,3",
    z: 636,
    z_global: 636000,
  },
  {
    profile_section_id: 9262,
    name: "323.9 x 8 CHS",
    type: "Circle Hollow",
    designation: "323.9x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: "79,4",
    ax_global: 7940,
    c: 0,
    c_global: 0,
    i: 9910,
    i_global: 99100000,
    od: "323,9",
    od_global: "323,9",
    tw: 8,
    tw_global: 8,
    z: 799,
    z_global: 799000,
  },
  {
    profile_section_id: 9263,
    name: "33.7 x 2.6 CHS",
    type: "Circle Hollow",
    designation: "33.7x2.6CHS",
    shape: "CHS",
    country_code: "European",
    ax: "2,54",
    ax_global: 254,
    c: 0,
    c_global: 0,
    i: "3,09",
    i_global: 30900,
    od: "33,7",
    od_global: "33,7",
    tw: "2,6",
    tw_global: "2,6",
    z: "2,52",
    z_global: 2520,
  },
  {
    profile_section_id: 9264,
    name: "33.7 x 3.2 CHS",
    type: "Circle Hollow",
    designation: "33.7x3.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "3,07",
    ax_global: 307,
    c: 0,
    c_global: 0,
    i: "3,6",
    i_global: 36000,
    od: "33,7",
    od_global: "33,7",
    tw: "3,2",
    tw_global: "3,2",
    z: "2,99",
    z_global: 2990,
  },
  {
    profile_section_id: 9265,
    name: "33.7 x 4 CHS",
    type: "Circle Hollow",
    designation: "33.7x4CHS",
    shape: "CHS",
    country_code: "European",
    ax: "3,73",
    ax_global: 373,
    c: 0,
    c_global: 0,
    i: "4,19",
    i_global: 41900,
    od: "33,7",
    od_global: "33,7",
    tw: 4,
    tw_global: 4,
    z: "3,55",
    z_global: 3550,
  },
  {
    profile_section_id: 9266,
    name: "355.6 x 10 CHS",
    type: "Circle Hollow",
    designation: "355.6x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 109,
    ax_global: 10900,
    c: 0,
    c_global: 0,
    i: 16220,
    i_global: 162200000,
    od: "355,6",
    od_global: "355,6",
    tw: 10,
    tw_global: 10,
    z: 1195,
    z_global: 1195000,
  },
  {
    profile_section_id: 9267,
    name: "355.6 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "355.6x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 135,
    ax_global: 13500,
    c: 0,
    c_global: 0,
    i: 19850,
    i_global: 198500000,
    od: "355,6",
    od_global: "355,6",
    tw: "12,5",
    tw_global: "12,5",
    z: 1472,
    z_global: 1472000,
  },
  {
    profile_section_id: 9268,
    name: "355.6 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "355.6x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 152,
    ax_global: 15200,
    c: 0,
    c_global: 0,
    i: 22230,
    i_global: 222300000,
    od: "355,6",
    od_global: "355,6",
    tw: "14,2",
    tw_global: "14,2",
    z: 1656,
    z_global: 1656000,
  },
  {
    profile_section_id: 9269,
    name: "355.6 x 16 CHS",
    type: "Circle Hollow",
    designation: "355.6x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 171,
    ax_global: 17100,
    c: 0,
    c_global: 0,
    i: 24660,
    i_global: 246600000,
    od: "355,6",
    od_global: "355,6",
    tw: 16,
    tw_global: 16,
    z: 1847,
    z_global: 1847000,
  },
  {
    profile_section_id: 9270,
    name: "355.6 x 20 CHS",
    type: "Circle Hollow",
    designation: "355.6x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 211,
    ax_global: 21100,
    c: 0,
    c_global: 0,
    i: 29790,
    i_global: 297900000,
    od: "355,6",
    od_global: "355,6",
    tw: 20,
    tw_global: 20,
    z: 2255,
    z_global: 2255000,
  },
  {
    profile_section_id: 9271,
    name: "355.6 x 25 CHS",
    type: "Circle Hollow",
    designation: "355.6x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 260,
    ax_global: 26000,
    c: 0,
    c_global: 0,
    i: 35680,
    i_global: 356800000,
    od: "355,6",
    od_global: "355,6",
    tw: 25,
    tw_global: 25,
    z: 2738,
    z_global: 2738000,
  },
  {
    profile_section_id: 9272,
    name: "355.6 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "355.6x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "69,1",
    ax_global: 6910,
    c: 0,
    c_global: 0,
    i: 10550,
    i_global: 105500000,
    od: "355,6",
    od_global: "355,6",
    tw: "6,3",
    tw_global: "6,3",
    z: 769,
    z_global: 769000,
  },
  {
    profile_section_id: 9273,
    name: "355.6 x 8 CHS",
    type: "Circle Hollow",
    designation: "355.6x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: "87,4",
    ax_global: 8740,
    c: 0,
    c_global: 0,
    i: 13200,
    i_global: 132000000,
    od: "355,6",
    od_global: "355,6",
    tw: 8,
    tw_global: 8,
    z: 967,
    z_global: 967000,
  },
  {
    profile_section_id: 9274,
    name: "406.4 x 10 CHS",
    type: "Circle Hollow",
    designation: "406.4x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 125,
    ax_global: 12500,
    c: 0,
    c_global: 0,
    i: 24480,
    i_global: 244800000,
    od: "406,4",
    od_global: "406,4",
    tw: 10,
    tw_global: 10,
    z: 1572,
    z_global: 1572000,
  },
  {
    profile_section_id: 9275,
    name: "406.4 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "406.4x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 155,
    ax_global: 15500,
    c: 0,
    c_global: 0,
    i: 30030,
    i_global: 300300000,
    od: "406,4",
    od_global: "406,4",
    tw: "12,5",
    tw_global: "12,5",
    z: 1940,
    z_global: 1940000,
  },
  {
    profile_section_id: 9276,
    name: "406.4 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "406.4x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 175,
    ax_global: 17500,
    c: 0,
    c_global: 0,
    i: 33690,
    i_global: 336900000,
    od: "406,4",
    od_global: "406,4",
    tw: "14,2",
    tw_global: "14,2",
    z: 2185,
    z_global: 2185000,
  },
  {
    profile_section_id: 9277,
    name: "406.4 x 16 CHS",
    type: "Circle Hollow",
    designation: "406.4x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 196,
    ax_global: 19600,
    c: 0,
    c_global: 0,
    i: 37450,
    i_global: 374500000,
    od: "406,4",
    od_global: "406,4",
    tw: 16,
    tw_global: 16,
    z: 2440,
    z_global: 2440000,
  },
  {
    profile_section_id: 9278,
    name: "406.4 x 20 CHS",
    type: "Circle Hollow",
    designation: "406.4x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 243,
    ax_global: 24300,
    c: 0,
    c_global: 0,
    i: 45430,
    i_global: 454300000,
    od: "406,4",
    od_global: "406,4",
    tw: 20,
    tw_global: 20,
    z: 2989,
    z_global: 2989000,
  },
  {
    profile_section_id: 9279,
    name: "406.4 x 25 CHS",
    type: "Circle Hollow",
    designation: "406.4x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 300,
    ax_global: 30000,
    c: 0,
    c_global: 0,
    i: 54700,
    i_global: 547000000,
    od: "406,4",
    od_global: "406,4",
    tw: 25,
    tw_global: 25,
    z: 3642,
    z_global: 3642000,
  },
  {
    profile_section_id: 9280,
    name: "406.4 x 30 CHS",
    type: "Circle Hollow",
    designation: "406.4x30CHS",
    shape: "CHS",
    country_code: "European",
    ax: 355,
    ax_global: 35500,
    c: 0,
    c_global: 0,
    i: 63220,
    i_global: 632200000,
    od: "406,4",
    od_global: "406,4",
    tw: 30,
    tw_global: 30,
    z: 4259,
    z_global: 4259000,
  },
  {
    profile_section_id: 9281,
    name: "406.4 x 40 CHS",
    type: "Circle Hollow",
    designation: "406.4x40CHS",
    shape: "CHS",
    country_code: "European",
    ax: 460,
    ax_global: 46000,
    c: 0,
    c_global: 0,
    i: 78190,
    i_global: 781900000,
    od: "406,4",
    od_global: "406,4",
    tw: 40,
    tw_global: 40,
    z: 5391,
    z_global: 5391000,
  },
  {
    profile_section_id: 9282,
    name: "406.4 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "406.4x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "79,2",
    ax_global: 7920,
    c: 0,
    c_global: 0,
    i: 15850,
    i_global: 158500000,
    od: "406,4",
    od_global: "406,4",
    tw: "6,3",
    tw_global: "6,3",
    z: 1009,
    z_global: 1009000,
  },
  {
    profile_section_id: 9283,
    name: "406.4 x 8 CHS",
    type: "Circle Hollow",
    designation: "406.4x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: 100,
    ax_global: 10000,
    c: 0,
    c_global: 0,
    i: 19870,
    i_global: 198700000,
    od: "406,4",
    od_global: "406,4",
    tw: 8,
    tw_global: 8,
    z: 1270,
    z_global: 1270000,
  },
  {
    profile_section_id: 9284,
    name: "42.4 x 2.6 CHS",
    type: "Circle Hollow",
    designation: "42.4x2.6CHS",
    shape: "CHS",
    country_code: "European",
    ax: "3,25",
    ax_global: 325,
    c: 0,
    c_global: 0,
    i: "6,46",
    i_global: 64600,
    od: "42,4",
    od_global: "42,4",
    tw: "2,6",
    tw_global: "2,6",
    z: "4,12",
    z_global: 4120,
  },
  {
    profile_section_id: 9285,
    name: "42.4 x 3.2 CHS",
    type: "Circle Hollow",
    designation: "42.4x3.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "3,94",
    ax_global: 394,
    c: 0,
    c_global: 0,
    i: "7,62",
    i_global: 76200,
    od: "42,4",
    od_global: "42,4",
    tw: "3,2",
    tw_global: "3,2",
    z: "4,93",
    z_global: 4930,
  },
  {
    profile_section_id: 9286,
    name: "42.4 x 4 CHS",
    type: "Circle Hollow",
    designation: "42.4x4CHS",
    shape: "CHS",
    country_code: "European",
    ax: "4,83",
    ax_global: 483,
    c: 0,
    c_global: 0,
    i: "8,99",
    i_global: 89900,
    od: "42,4",
    od_global: "42,4",
    tw: 4,
    tw_global: 4,
    z: "5,92",
    z_global: 5920,
  },
  {
    profile_section_id: 9287,
    name: "457 x 10 CHS",
    type: "Circle Hollow",
    designation: "457x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 140,
    ax_global: 14000,
    c: 0,
    c_global: 0,
    i: 35090,
    i_global: 350900000,
    od: 457,
    od_global: 457,
    tw: 10,
    tw_global: 10,
    z: 1998,
    z_global: 1998000,
  },
  {
    profile_section_id: 9288,
    name: "457 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "457x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 175,
    ax_global: 17500,
    c: 0,
    c_global: 0,
    i: 43150,
    i_global: 431500000,
    od: 457,
    od_global: 457,
    tw: "12,5",
    tw_global: "12,5",
    z: 2470,
    z_global: 2470000,
  },
  {
    profile_section_id: 9289,
    name: "457 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "457x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 198,
    ax_global: 19800,
    c: 0,
    c_global: 0,
    i: 48460,
    i_global: 484600000,
    od: 457,
    od_global: 457,
    tw: "14,2",
    tw_global: "14,2",
    z: 2785,
    z_global: 2785000,
  },
  {
    profile_section_id: 9290,
    name: "457 x 16 CHS",
    type: "Circle Hollow",
    designation: "457x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 222,
    ax_global: 22200,
    c: 0,
    c_global: 0,
    i: 53960,
    i_global: 539600000,
    od: 457,
    od_global: 457,
    tw: 16,
    tw_global: 16,
    z: 3113,
    z_global: 3113000,
  },
  {
    profile_section_id: 9291,
    name: "457 x 20 CHS",
    type: "Circle Hollow",
    designation: "457x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 275,
    ax_global: 27500,
    c: 0,
    c_global: 0,
    i: 65680,
    i_global: 656800000,
    od: 457,
    od_global: 457,
    tw: 20,
    tw_global: 20,
    z: 3822,
    z_global: 3822000,
  },
  {
    profile_section_id: 9292,
    name: "457 x 25 CHS",
    type: "Circle Hollow",
    designation: "457x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 339,
    ax_global: 33900,
    c: 0,
    c_global: 0,
    i: 79420,
    i_global: 794200000,
    od: 457,
    od_global: 457,
    tw: 25,
    tw_global: 25,
    z: 4671,
    z_global: 4671000,
  },
  {
    profile_section_id: 9293,
    name: "457 x 30 CHS",
    type: "Circle Hollow",
    designation: "457x30CHS",
    shape: "CHS",
    country_code: "European",
    ax: 402,
    ax_global: 40200,
    c: 0,
    c_global: 0,
    i: 92170,
    i_global: 921700000,
    od: 457,
    od_global: 457,
    tw: 30,
    tw_global: 30,
    z: 5479,
    z_global: 5479000,
  },
  {
    profile_section_id: 9294,
    name: "457 x 40 CHS",
    type: "Circle Hollow",
    designation: "457x40CHS",
    shape: "CHS",
    country_code: "European",
    ax: 524,
    ax_global: 52400,
    c: 0,
    c_global: 0,
    i: 115000,
    i_global: 1150000000,
    od: 457,
    od_global: 457,
    tw: 40,
    tw_global: 40,
    z: 6977,
    z_global: 6977000,
  },
  {
    profile_section_id: 9295,
    name: "457 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "457x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "89,2",
    ax_global: 8920,
    c: 0,
    c_global: 0,
    i: 22650,
    i_global: 226500000,
    od: 457,
    od_global: 457,
    tw: "6,3",
    tw_global: "6,3",
    z: 1280,
    z_global: 1280000,
  },
  {
    profile_section_id: 9296,
    name: "457 x 8 CHS",
    type: "Circle Hollow",
    designation: "457x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: 113,
    ax_global: 11300,
    c: 0,
    c_global: 0,
    i: 28450,
    i_global: 284500000,
    od: 457,
    od_global: 457,
    tw: 8,
    tw_global: 8,
    z: 1613,
    z_global: 1613000,
  },
  {
    profile_section_id: 9297,
    name: "48.3 x 2.6 CHS",
    type: "Circle Hollow",
    designation: "48.3x2.6CHS",
    shape: "CHS",
    country_code: "European",
    ax: "3,73",
    ax_global: 373,
    c: 0,
    c_global: 0,
    i: "9,8",
    i_global: 98000,
    od: "48,3",
    od_global: "48,3",
    tw: "2,6",
    tw_global: "2,6",
    z: "5,44",
    z_global: 5440,
  },
  {
    profile_section_id: 9298,
    name: "48.3 x 3.2 CHS",
    type: "Circle Hollow",
    designation: "48.3x3.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "4,53",
    ax_global: 453,
    c: 0,
    c_global: 0,
    i: "11,6",
    i_global: 116000,
    od: "48,3",
    od_global: "48,3",
    tw: "3,2",
    tw_global: "3,2",
    z: "6,52",
    z_global: 6520,
  },
  {
    profile_section_id: 9299,
    name: "48.3 x 4 CHS",
    type: "Circle Hollow",
    designation: "48.3x4CHS",
    shape: "CHS",
    country_code: "European",
    ax: "5,57",
    ax_global: 557,
    c: 0,
    c_global: 0,
    i: "13,8",
    i_global: 138000,
    od: "48,3",
    od_global: "48,3",
    tw: 4,
    tw_global: 4,
    z: "7,87",
    z_global: 7870,
  },
  {
    profile_section_id: 9300,
    name: "48.3 x 5 CHS",
    type: "Circle Hollow",
    designation: "48.3x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "6,8",
    ax_global: 680,
    c: 0,
    c_global: 0,
    i: "16,2",
    i_global: 162000,
    od: "48,3",
    od_global: "48,3",
    tw: 5,
    tw_global: 5,
    z: "9,42",
    z_global: 9420,
  },
  {
    profile_section_id: 9301,
    name: "508 x 10 CHS",
    type: "Circle Hollow",
    designation: "508x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 156,
    ax_global: 15600,
    c: 0,
    c_global: 0,
    i: 48520,
    i_global: 485200000,
    od: 508,
    od_global: 508,
    tw: 10,
    tw_global: 10,
    z: 2480,
    z_global: 2480000,
  },
  {
    profile_section_id: 9302,
    name: "508 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "508x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 195,
    ax_global: 19500,
    c: 0,
    c_global: 0,
    i: 59760,
    i_global: 597600000,
    od: 508,
    od_global: 508,
    tw: "12,5",
    tw_global: "12,5",
    z: 3070,
    z_global: 3070000,
  },
  {
    profile_section_id: 9303,
    name: "508 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "508x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 220,
    ax_global: 22000,
    c: 0,
    c_global: 0,
    i: 67200,
    i_global: 672000000,
    od: 508,
    od_global: 508,
    tw: "14,2",
    tw_global: "14,2",
    z: 3463,
    z_global: 3463000,
  },
  {
    profile_section_id: 9304,
    name: "508 x 16 CHS",
    type: "Circle Hollow",
    designation: "508x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 247,
    ax_global: 24700,
    c: 0,
    c_global: 0,
    i: 74910,
    i_global: 749100000,
    od: 508,
    od_global: 508,
    tw: 16,
    tw_global: 16,
    z: 3874,
    z_global: 3874000,
  },
  {
    profile_section_id: 9305,
    name: "508 x 20 CHS",
    type: "Circle Hollow",
    designation: "508x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 307,
    ax_global: 30700,
    c: 0,
    c_global: 0,
    i: 91430,
    i_global: 914300000,
    od: 508,
    od_global: 508,
    tw: 20,
    tw_global: 20,
    z: 4766,
    z_global: 4766000,
  },
  {
    profile_section_id: 9306,
    name: "508 x 25 CHS",
    type: "Circle Hollow",
    designation: "508x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 379,
    ax_global: 37900,
    c: 0,
    c_global: 0,
    i: 111000,
    i_global: 1110000000,
    od: 508,
    od_global: 508,
    tw: 25,
    tw_global: 25,
    z: 5837,
    z_global: 5837000,
  },
  {
    profile_section_id: 9307,
    name: "508 x 30 CHS",
    type: "Circle Hollow",
    designation: "508x30CHS",
    shape: "CHS",
    country_code: "European",
    ax: 451,
    ax_global: 45100,
    c: 0,
    c_global: 0,
    i: 129200,
    i_global: 1292000000,
    od: 508,
    od_global: 508,
    tw: 30,
    tw_global: 30,
    z: 6864,
    z_global: 6864000,
  },
  {
    profile_section_id: 9308,
    name: "508 x 40 CHS",
    type: "Circle Hollow",
    designation: "508x40CHS",
    shape: "CHS",
    country_code: "European",
    ax: 588,
    ax_global: 58800,
    c: 0,
    c_global: 0,
    i: 162200,
    i_global: 1622000000,
    od: 508,
    od_global: 508,
    tw: 40,
    tw_global: 40,
    z: 8782,
    z_global: 8782000,
  },
  {
    profile_section_id: 9309,
    name: "508 x 50 CHS",
    type: "Circle Hollow",
    designation: "508x50CHS",
    shape: "CHS",
    country_code: "European",
    ax: 719,
    ax_global: 71900,
    c: 0,
    c_global: 0,
    i: 190900,
    i_global: 1909000000,
    od: 508,
    od_global: 508,
    tw: 50,
    tw_global: 50,
    z: 10530,
    z_global: 10530000,
  },
  {
    profile_section_id: 9310,
    name: "508 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "508x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "99,3",
    ax_global: 9930,
    c: 0,
    c_global: 0,
    i: 31250,
    i_global: 312500000,
    od: 508,
    od_global: 508,
    tw: "6,3",
    tw_global: "6,3",
    z: 1586,
    z_global: 1586000,
  },
  {
    profile_section_id: 9311,
    name: "508 x 8 CHS",
    type: "Circle Hollow",
    designation: "508x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: 126,
    ax_global: 12600,
    c: 0,
    c_global: 0,
    i: 39280,
    i_global: 392800000,
    od: 508,
    od_global: 508,
    tw: 8,
    tw_global: 8,
    z: 2000,
    z_global: 2000000,
  },
  {
    profile_section_id: 9312,
    name: "60.3 x 2.6 CHS",
    type: "Circle Hollow",
    designation: "60.3x2.6CHS",
    shape: "CHS",
    country_code: "European",
    ax: "4,71",
    ax_global: 471,
    c: 0,
    c_global: 0,
    i: "19,7",
    i_global: 197000,
    od: "60,3",
    od_global: "60,3",
    tw: "2,6",
    tw_global: "2,6",
    z: "8,66",
    z_global: 8660,
  },
  {
    profile_section_id: 9313,
    name: "60.3 x 3.2 CHS",
    type: "Circle Hollow",
    designation: "60.3x3.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "5,74",
    ax_global: 574,
    c: 0,
    c_global: 0,
    i: "23,5",
    i_global: 235000,
    od: "60,3",
    od_global: "60,3",
    tw: "3,2",
    tw_global: "3,2",
    z: "10,4",
    z_global: 10400,
  },
  {
    profile_section_id: 9314,
    name: "60.3 x 4 CHS",
    type: "Circle Hollow",
    designation: "60.3x4CHS",
    shape: "CHS",
    country_code: "European",
    ax: "7,07",
    ax_global: 707,
    c: 0,
    c_global: 0,
    i: "28,2",
    i_global: 282000,
    od: "60,3",
    od_global: "60,3",
    tw: 4,
    tw_global: 4,
    z: "12,7",
    z_global: 12700,
  },
  {
    profile_section_id: 9315,
    name: "60.3 x 5 CHS",
    type: "Circle Hollow",
    designation: "60.3x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "8,69",
    ax_global: 869,
    c: 0,
    c_global: 0,
    i: "33,5",
    i_global: 335000,
    od: "60,3",
    od_global: "60,3",
    tw: 5,
    tw_global: 5,
    z: "15,3",
    z_global: 15300,
  },
  {
    profile_section_id: 9316,
    name: "610 x 10 CHS",
    type: "Circle Hollow",
    designation: "610x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 188,
    ax_global: 18800,
    c: 0,
    c_global: 0,
    i: 84850,
    i_global: 848500000,
    od: 610,
    od_global: 610,
    tw: 10,
    tw_global: 10,
    z: 3600,
    z_global: 3600000,
  },
  {
    profile_section_id: 9317,
    name: "610 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "610x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 235,
    ax_global: 23500,
    c: 0,
    c_global: 0,
    i: 104800,
    i_global: 1048000000,
    od: 610,
    od_global: 610,
    tw: "12,5",
    tw_global: "12,5",
    z: 4463,
    z_global: 4463000,
  },
  {
    profile_section_id: 9318,
    name: "610 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "610x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 266,
    ax_global: 26600,
    c: 0,
    c_global: 0,
    i: 118000,
    i_global: 1180000000,
    od: 610,
    od_global: 610,
    tw: "14,2",
    tw_global: "14,2",
    z: 5042,
    z_global: 5042000,
  },
  {
    profile_section_id: 9319,
    name: "610 x 16 CHS",
    type: "Circle Hollow",
    designation: "610x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 299,
    ax_global: 29900,
    c: 0,
    c_global: 0,
    i: 131800,
    i_global: 1318000000,
    od: 610,
    od_global: 610,
    tw: 16,
    tw_global: 16,
    z: 5647,
    z_global: 5647000,
  },
  {
    profile_section_id: 9320,
    name: "610 x 20 CHS",
    type: "Circle Hollow",
    designation: "610x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 371,
    ax_global: 37100,
    c: 0,
    c_global: 0,
    i: 161500,
    i_global: 1615000000,
    od: 610,
    od_global: 610,
    tw: 20,
    tw_global: 20,
    z: 6965,
    z_global: 6965000,
  },
  {
    profile_section_id: 9321,
    name: "610 x 25 CHS",
    type: "Circle Hollow",
    designation: "610x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 459,
    ax_global: 45900,
    c: 0,
    c_global: 0,
    i: 196900,
    i_global: 1969000000,
    od: 610,
    od_global: 610,
    tw: 25,
    tw_global: 25,
    z: 8561,
    z_global: 8561000,
  },
  {
    profile_section_id: 9322,
    name: "610 x 30 CHS",
    type: "Circle Hollow",
    designation: "610x30CHS",
    shape: "CHS",
    country_code: "European",
    ax: 547,
    ax_global: 54700,
    c: 0,
    c_global: 0,
    i: 230500,
    i_global: 2305000000,
    od: 610,
    od_global: 610,
    tw: 30,
    tw_global: 30,
    z: 10100,
    z_global: 10100000,
  },
  {
    profile_section_id: 9323,
    name: "610 x 40 CHS",
    type: "Circle Hollow",
    designation: "610x40CHS",
    shape: "CHS",
    country_code: "European",
    ax: 716,
    ax_global: 71600,
    c: 0,
    c_global: 0,
    i: 292300,
    i_global: 2923000000,
    od: 610,
    od_global: 610,
    tw: 40,
    tw_global: 40,
    z: 13020,
    z_global: 13020000,
  },
  {
    profile_section_id: 9324,
    name: "610 x 50 CHS",
    type: "Circle Hollow",
    designation: "610x50CHS",
    shape: "CHS",
    country_code: "European",
    ax: 880,
    ax_global: 88000,
    c: 0,
    c_global: 0,
    i: 347600,
    i_global: 3476000000,
    od: 610,
    od_global: 610,
    tw: 50,
    tw_global: 50,
    z: 15720,
    z_global: 15720000,
  },
  {
    profile_section_id: 9325,
    name: "610 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "610x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: 119,
    ax_global: 11900,
    c: 0,
    c_global: 0,
    i: 54440,
    i_global: 544400000,
    od: 610,
    od_global: 610,
    tw: "6,3",
    tw_global: "6,3",
    z: 2296,
    z_global: 2296000,
  },
  {
    profile_section_id: 9326,
    name: "610 x 8 CHS",
    type: "Circle Hollow",
    designation: "610x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: 151,
    ax_global: 15100,
    c: 0,
    c_global: 0,
    i: 68550,
    i_global: 685500000,
    od: 610,
    od_global: 610,
    tw: 8,
    tw_global: 8,
    z: 2899,
    z_global: 2899000,
  },
  {
    profile_section_id: 9327,
    name: "711 x 10 CHS",
    type: "Circle Hollow",
    designation: "711x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 220,
    ax_global: 22000,
    c: 0,
    c_global: 0,
    i: 135300,
    i_global: 1353000000,
    od: 711,
    od_global: 711,
    tw: 10,
    tw_global: 10,
    z: 4914,
    z_global: 4914000,
  },
  {
    profile_section_id: 9328,
    name: "711 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "711x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 274,
    ax_global: 27400,
    c: 0,
    c_global: 0,
    i: 167300,
    i_global: 1673000000,
    od: 711,
    od_global: 711,
    tw: "12,5",
    tw_global: "12,5",
    z: 6099,
    z_global: 6099000,
  },
  {
    profile_section_id: 9329,
    name: "711 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "711x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 311,
    ax_global: 31100,
    c: 0,
    c_global: 0,
    i: 188700,
    i_global: 1887000000,
    od: 711,
    od_global: 711,
    tw: "14,2",
    tw_global: "14,2",
    z: 6895,
    z_global: 6895000,
  },
  {
    profile_section_id: 9330,
    name: "711 x 16 CHS",
    type: "Circle Hollow",
    designation: "711x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 349,
    ax_global: 34900,
    c: 0,
    c_global: 0,
    i: 211000,
    i_global: 2110000000,
    od: 711,
    od_global: 711,
    tw: 16,
    tw_global: 16,
    z: 7730,
    z_global: 7730000,
  },
  {
    profile_section_id: 9331,
    name: "711 x 20 CHS",
    type: "Circle Hollow",
    designation: "711x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 434,
    ax_global: 43400,
    c: 0,
    c_global: 0,
    i: 259400,
    i_global: 2594000000,
    od: 711,
    od_global: 711,
    tw: 20,
    tw_global: 20,
    z: 9552,
    z_global: 9552000,
  },
  {
    profile_section_id: 9332,
    name: "711 x 25 CHS",
    type: "Circle Hollow",
    designation: "711x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 539,
    ax_global: 53900,
    c: 0,
    c_global: 0,
    i: 317400,
    i_global: 3174000000,
    od: 711,
    od_global: 711,
    tw: 25,
    tw_global: 25,
    z: 11770,
    z_global: 11770000,
  },
  {
    profile_section_id: 9333,
    name: "711 x 30 CHS",
    type: "Circle Hollow",
    designation: "711x30CHS",
    shape: "CHS",
    country_code: "European",
    ax: 642,
    ax_global: 64200,
    c: 0,
    c_global: 0,
    i: 372800,
    i_global: 3728000000,
    od: 711,
    od_global: 711,
    tw: 30,
    tw_global: 30,
    z: 13920,
    z_global: 13920000,
  },
  {
    profile_section_id: 9334,
    name: "711 x 40 CHS",
    type: "Circle Hollow",
    designation: "711x40CHS",
    shape: "CHS",
    country_code: "European",
    ax: 843,
    ax_global: 84300,
    c: 0,
    c_global: 0,
    i: 476200,
    i_global: 4762000000,
    od: 711,
    od_global: 711,
    tw: 40,
    tw_global: 40,
    z: 18030,
    z_global: 18030000,
  },
  {
    profile_section_id: 9335,
    name: "711 x 50 CHS",
    type: "Circle Hollow",
    designation: "711x50CHS",
    shape: "CHS",
    country_code: "European",
    ax: 1038,
    ax_global: 103800,
    c: 0,
    c_global: 0,
    i: 570300,
    i_global: 5703000000,
    od: 711,
    od_global: 711,
    tw: 50,
    tw_global: 50,
    z: 21890,
    z_global: 21890000,
  },
  {
    profile_section_id: 9336,
    name: "711 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "711x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: 139,
    ax_global: 13900,
    c: 0,
    c_global: 0,
    i: 86590,
    i_global: 865900000,
    od: 711,
    od_global: 711,
    tw: "6,3",
    tw_global: "6,3",
    z: 3129,
    z_global: 3129000,
  },
  {
    profile_section_id: 9337,
    name: "711 x 60 CHS",
    type: "Circle Hollow",
    designation: "711x60CHS",
    shape: "CHS",
    country_code: "European",
    ax: 1227,
    ax_global: 122700,
    c: 0,
    c_global: 0,
    i: 655600,
    i_global: 6556000000,
    od: 711,
    od_global: 711,
    tw: 60,
    tw_global: 60,
    z: 25500,
    z_global: 25500000,
  },
  {
    profile_section_id: 9338,
    name: "711 x 8 CHS",
    type: "Circle Hollow",
    designation: "711x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: 177,
    ax_global: 17700,
    c: 0,
    c_global: 0,
    i: 109200,
    i_global: 1092000000,
    od: 711,
    od_global: 711,
    tw: 8,
    tw_global: 8,
    z: 3954,
    z_global: 3954000,
  },
  {
    profile_section_id: 9339,
    name: "76.1 x 2.6 CHS",
    type: "Circle Hollow",
    designation: "76.1x2.6CHS",
    shape: "CHS",
    country_code: "European",
    ax: 6,
    ax_global: 600,
    c: 0,
    c_global: 0,
    i: "40,6",
    i_global: 406000,
    od: "76,1",
    od_global: "76,1",
    tw: "2,6",
    tw_global: "2,6",
    z: "14,1",
    z_global: 14100,
  },
  {
    profile_section_id: 9340,
    name: "76.1 x 3.2 CHS",
    type: "Circle Hollow",
    designation: "76.1x3.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "7,33",
    ax_global: 733,
    c: 0,
    c_global: 0,
    i: "48,8",
    i_global: 488000,
    od: "76,1",
    od_global: "76,1",
    tw: "3,2",
    tw_global: "3,2",
    z: 17,
    z_global: 17000,
  },
  {
    profile_section_id: 9341,
    name: "76.1 x 4 CHS",
    type: "Circle Hollow",
    designation: "76.1x4CHS",
    shape: "CHS",
    country_code: "European",
    ax: "9,06",
    ax_global: 906,
    c: 0,
    c_global: 0,
    i: "59,1",
    i_global: 591000,
    od: "76,1",
    od_global: "76,1",
    tw: 4,
    tw_global: 4,
    z: "20,8",
    z_global: 20800,
  },
  {
    profile_section_id: 9342,
    name: "76.1 x 5 CHS",
    type: "Circle Hollow",
    designation: "76.1x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "11,2",
    ax_global: 1120,
    c: 0,
    c_global: 0,
    i: "70,9",
    i_global: 709000,
    od: "76,1",
    od_global: "76,1",
    tw: 5,
    tw_global: 5,
    z: "25,3",
    z_global: 25300,
  },
  {
    profile_section_id: 9343,
    name: "762 x 10 CHS",
    type: "Circle Hollow",
    designation: "762x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 236,
    ax_global: 23600,
    c: 0,
    c_global: 0,
    i: 167000,
    i_global: 1670000000,
    od: 762,
    od_global: 762,
    tw: 10,
    tw_global: 10,
    z: 5655,
    z_global: 5655000,
  },
  {
    profile_section_id: 9344,
    name: "762 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "762x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 294,
    ax_global: 29400,
    c: 0,
    c_global: 0,
    i: 206700,
    i_global: 2067000000,
    od: 762,
    od_global: 762,
    tw: "12,5",
    tw_global: "12,5",
    z: 7023,
    z_global: 7023000,
  },
  {
    profile_section_id: 9345,
    name: "762 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "762x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 334,
    ax_global: 33400,
    c: 0,
    c_global: 0,
    i: 233000,
    i_global: 2330000000,
    od: 762,
    od_global: 762,
    tw: "14,2",
    tw_global: "14,2",
    z: 7940,
    z_global: 7940000,
  },
  {
    profile_section_id: 9346,
    name: "762 x 16 CHS",
    type: "Circle Hollow",
    designation: "762x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 375,
    ax_global: 37500,
    c: 0,
    c_global: 0,
    i: 261000,
    i_global: 2610000000,
    od: 762,
    od_global: 762,
    tw: 16,
    tw_global: 16,
    z: 8906,
    z_global: 8906000,
  },
  {
    profile_section_id: 9347,
    name: "762 x 20 CHS",
    type: "Circle Hollow",
    designation: "762x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 466,
    ax_global: 46600,
    c: 0,
    c_global: 0,
    i: 321100,
    i_global: 3211000000,
    od: 762,
    od_global: 762,
    tw: 20,
    tw_global: 20,
    z: 11010,
    z_global: 11010000,
  },
  {
    profile_section_id: 9348,
    name: "762 x 25 CHS",
    type: "Circle Hollow",
    designation: "762x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 579,
    ax_global: 57900,
    c: 0,
    c_global: 0,
    i: 393500,
    i_global: 3935000000,
    od: 762,
    od_global: 762,
    tw: 25,
    tw_global: 25,
    z: 13580,
    z_global: 13580000,
  },
  {
    profile_section_id: 9349,
    name: "762 x 30 CHS",
    type: "Circle Hollow",
    designation: "762x30CHS",
    shape: "CHS",
    country_code: "European",
    ax: 690,
    ax_global: 69000,
    c: 0,
    c_global: 0,
    i: 462900,
    i_global: 4629000000,
    od: 762,
    od_global: 762,
    tw: 30,
    tw_global: 30,
    z: 16080,
    z_global: 16080000,
  },
  {
    profile_section_id: 9350,
    name: "762 x 40 CHS",
    type: "Circle Hollow",
    designation: "762x40CHS",
    shape: "CHS",
    country_code: "European",
    ax: 907,
    ax_global: 90700,
    c: 0,
    c_global: 0,
    i: 593000,
    i_global: 5930000000,
    od: 762,
    od_global: 762,
    tw: 40,
    tw_global: 40,
    z: 20870,
    z_global: 20870000,
  },
  {
    profile_section_id: 9351,
    name: "762 x 50 CHS",
    type: "Circle Hollow",
    designation: "762x50CHS",
    shape: "CHS",
    country_code: "European",
    ax: 1118,
    ax_global: 111800,
    c: 0,
    c_global: 0,
    i: 712200,
    i_global: 7122000000,
    od: 762,
    od_global: 762,
    tw: 50,
    tw_global: 50,
    z: 25390,
    z_global: 25390000,
  },
  {
    profile_section_id: 9352,
    name: "762 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "762x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: 150,
    ax_global: 15000,
    c: 0,
    c_global: 0,
    i: 106800,
    i_global: 1068000000,
    od: 762,
    od_global: 762,
    tw: "6,3",
    tw_global: "6,3",
    z: 3598,
    z_global: 3598000,
  },
  {
    profile_section_id: 9353,
    name: "762 x 8 CHS",
    type: "Circle Hollow",
    designation: "762x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: 190,
    ax_global: 19000,
    c: 0,
    c_global: 0,
    i: 134700,
    i_global: 1347000000,
    od: 762,
    od_global: 762,
    tw: 8,
    tw_global: 8,
    z: 4548,
    z_global: 4548000,
  },
  {
    profile_section_id: 9354,
    name: "813 x 10 CHS",
    type: "Circle Hollow",
    designation: "813x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 252,
    ax_global: 25200,
    c: 0,
    c_global: 0,
    i: 203400,
    i_global: 2034000000,
    od: 813,
    od_global: 813,
    tw: 10,
    tw_global: 10,
    z: 6448,
    z_global: 6448000,
  },
  {
    profile_section_id: 9355,
    name: "813 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "813x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 314,
    ax_global: 31400,
    c: 0,
    c_global: 0,
    i: 251900,
    i_global: 2519000000,
    od: 813,
    od_global: 813,
    tw: "12,5",
    tw_global: "12,5",
    z: 8011,
    z_global: 8011000,
  },
  {
    profile_section_id: 9356,
    name: "813 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "813x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 356,
    ax_global: 35600,
    c: 0,
    c_global: 0,
    i: 284000,
    i_global: 2840000000,
    od: 813,
    od_global: 813,
    tw: "14,2",
    tw_global: "14,2",
    z: 9060,
    z_global: 9060000,
  },
  {
    profile_section_id: 9357,
    name: "813 x 16 CHS",
    type: "Circle Hollow",
    designation: "813x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 401,
    ax_global: 40100,
    c: 0,
    c_global: 0,
    i: 318200,
    i_global: 3182000000,
    od: 813,
    od_global: 813,
    tw: 16,
    tw_global: 16,
    z: 10170,
    z_global: 10170000,
  },
  {
    profile_section_id: 9358,
    name: "813 x 20 CHS",
    type: "Circle Hollow",
    designation: "813x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 498,
    ax_global: 49800,
    c: 0,
    c_global: 0,
    i: 391900,
    i_global: 3919000000,
    od: 813,
    od_global: 813,
    tw: 20,
    tw_global: 20,
    z: 12580,
    z_global: 12580000,
  },
  {
    profile_section_id: 9359,
    name: "813 x 25 CHS",
    type: "Circle Hollow",
    designation: "813x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 619,
    ax_global: 61900,
    c: 0,
    c_global: 0,
    i: 480900,
    i_global: 4809000000,
    od: 813,
    od_global: 813,
    tw: 25,
    tw_global: 25,
    z: 15530,
    z_global: 15530000,
  },
  {
    profile_section_id: 9360,
    name: "813 x 30 CHS",
    type: "Circle Hollow",
    designation: "813x30CHS",
    shape: "CHS",
    country_code: "European",
    ax: 738,
    ax_global: 73800,
    c: 0,
    c_global: 0,
    i: 566400,
    i_global: 5664000000,
    od: 813,
    od_global: 813,
    tw: 30,
    tw_global: 30,
    z: 18400,
    z_global: 18400000,
  },
  {
    profile_section_id: 9361,
    name: "813 x 8 CHS",
    type: "Circle Hollow",
    designation: "813x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: 202,
    ax_global: 20200,
    c: 0,
    c_global: 0,
    i: 163900,
    i_global: 1639000000,
    od: 813,
    od_global: 813,
    tw: 8,
    tw_global: 8,
    z: 5184,
    z_global: 5184000,
  },
  {
    profile_section_id: 9362,
    name: "88.9 x 3.2 CHS",
    type: "Circle Hollow",
    designation: "88.9x3.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: "8,62",
    ax_global: 862,
    c: 0,
    c_global: 0,
    i: "79,2",
    i_global: 792000,
    od: "88,9",
    od_global: "88,9",
    tw: "3,2",
    tw_global: "3,2",
    z: "23,5",
    z_global: 23500,
  },
  {
    profile_section_id: 9363,
    name: "88.9 x 4 CHS",
    type: "Circle Hollow",
    designation: "88.9x4CHS",
    shape: "CHS",
    country_code: "European",
    ax: "10,7",
    ax_global: 1070,
    c: 0,
    c_global: 0,
    i: "96,3",
    i_global: 963000,
    od: "88,9",
    od_global: "88,9",
    tw: 4,
    tw_global: 4,
    z: "28,9",
    z_global: 28900,
  },
  {
    profile_section_id: 9364,
    name: "88.9 x 5 CHS",
    type: "Circle Hollow",
    designation: "88.9x5CHS",
    shape: "CHS",
    country_code: "European",
    ax: "13,2",
    ax_global: 1320,
    c: 0,
    c_global: 0,
    i: 116,
    i_global: 1160000,
    od: "88,9",
    od_global: "88,9",
    tw: 5,
    tw_global: 5,
    z: "35,2",
    z_global: 35200,
  },
  {
    profile_section_id: 9365,
    name: "88.9 x 6.3 CHS",
    type: "Circle Hollow",
    designation: "88.9x6.3CHS",
    shape: "CHS",
    country_code: "European",
    ax: "16,3",
    ax_global: 1630,
    c: 0,
    c_global: 0,
    i: 140,
    i_global: 1400000,
    od: "88,9",
    od_global: "88,9",
    tw: "6,3",
    tw_global: "6,3",
    z: "43,1",
    z_global: 43100,
  },
  {
    profile_section_id: 9366,
    name: "914 x 10 CHS",
    type: "Circle Hollow",
    designation: "914x10CHS",
    shape: "CHS",
    country_code: "European",
    ax: 284,
    ax_global: 28400,
    c: 0,
    c_global: 0,
    i: 290500,
    i_global: 2905000000,
    od: 914,
    od_global: 914,
    tw: 10,
    tw_global: 10,
    z: 8172,
    z_global: 8172000,
  },
  {
    profile_section_id: 9367,
    name: "914 x 12.5 CHS",
    type: "Circle Hollow",
    designation: "914x12.5CHS",
    shape: "CHS",
    country_code: "European",
    ax: 354,
    ax_global: 35400,
    c: 0,
    c_global: 0,
    i: 359700,
    i_global: 3597000000,
    od: 914,
    od_global: 914,
    tw: "12,5",
    tw_global: "12,5",
    z: 10160,
    z_global: 10160000,
  },
  {
    profile_section_id: 9368,
    name: "914 x 14.2 CHS",
    type: "Circle Hollow",
    designation: "914x14.2CHS",
    shape: "CHS",
    country_code: "European",
    ax: 401,
    ax_global: 40100,
    c: 0,
    c_global: 0,
    i: 406000,
    i_global: 4060000000,
    od: 914,
    od_global: 914,
    tw: "14,2",
    tw_global: "14,2",
    z: 11500,
    z_global: 11500000,
  },
  {
    profile_section_id: 9369,
    name: "914 x 16 CHS",
    type: "Circle Hollow",
    designation: "914x16CHS",
    shape: "CHS",
    country_code: "European",
    ax: 451,
    ax_global: 45100,
    c: 0,
    c_global: 0,
    i: 455100,
    i_global: 4551000000,
    od: 914,
    od_global: 914,
    tw: 16,
    tw_global: 16,
    z: 12900,
    z_global: 12900000,
  },
  {
    profile_section_id: 9370,
    name: "914 x 20 CHS",
    type: "Circle Hollow",
    designation: "914x20CHS",
    shape: "CHS",
    country_code: "European",
    ax: 562,
    ax_global: 56200,
    c: 0,
    c_global: 0,
    i: 561500,
    i_global: 5615000000,
    od: 914,
    od_global: 914,
    tw: 20,
    tw_global: 20,
    z: 15990,
    z_global: 15990000,
  },
  {
    profile_section_id: 9371,
    name: "914 x 25 CHS",
    type: "Circle Hollow",
    designation: "914x25CHS",
    shape: "CHS",
    country_code: "European",
    ax: 698,
    ax_global: 69800,
    c: 0,
    c_global: 0,
    i: 690320,
    i_global: 6903200000,
    od: 914,
    od_global: 914,
    tw: 25,
    tw_global: 25,
    z: 19760,
    z_global: 19760000,
  },
  {
    profile_section_id: 9372,
    name: "914 x 30 CHS",
    type: "Circle Hollow",
    designation: "914x30CHS",
    shape: "CHS",
    country_code: "European",
    ax: 833,
    ax_global: 83300,
    c: 0,
    c_global: 0,
    i: 814800,
    i_global: 8148000000,
    od: 914,
    od_global: 914,
    tw: 30,
    tw_global: 30,
    z: 23450,
    z_global: 23450000,
  },
  {
    profile_section_id: 9373,
    name: "914 x 8 CHS",
    type: "Circle Hollow",
    designation: "914x8CHS",
    shape: "CHS",
    country_code: "European",
    ax: 228,
    ax_global: 22800,
    c: 0,
    c_global: 0,
    i: 233700,
    i_global: 2337000000,
    od: 914,
    od_global: 914,
    tw: 8,
    tw_global: 8,
    z: 6567,
    z_global: 6567000,
  },
  {
    profile_section_id: 9374,
    name: "21.3x2 CHS",
    type: "Circular Hollow",
    designation: "21.3x2 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "1,21",
    d_global: "21,3",
    i_global: "0,57",
    t_global: 2,
    z_global: "0,75",
  },
  {
    profile_section_id: 9375,
    name: "21.3x2.6 CHS",
    type: "Circular Hollow",
    designation: "21.3x2.6 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "1,53",
    d_global: "21,3",
    i_global: "0,68",
    t_global: "2,6",
    z_global: "0,92",
  },
  {
    profile_section_id: 9376,
    name: "21.3x3.2 CHS",
    type: "Circular Hollow",
    designation: "21.3x3.2 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "1,82",
    d_global: "21,3",
    i_global: "0,77",
    t_global: "3,2",
    z_global: "1,06",
  },
  {
    profile_section_id: 9377,
    name: "26.9x2.3 CHS",
    type: "Circular Hollow",
    designation: "26.9x2.3 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "1,78",
    d_global: "26,9",
    i_global: "1,36",
    t_global: "2,3",
    z_global: "1,4",
  },
  {
    profile_section_id: 9378,
    name: "26.9x2.6 CHS",
    type: "Circular Hollow",
    designation: "26.9x2.6 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "1,98",
    d_global: "26,9",
    i_global: "1,48",
    t_global: "2,6",
    z_global: "1,54",
  },
  {
    profile_section_id: 9379,
    name: "26.9x3.2 CHS",
    type: "Circular Hollow",
    designation: "26.9x3.2 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "2,38",
    d_global: "26,9",
    i_global: "1,7",
    t_global: "3,2",
    z_global: "1,81",
  },
  {
    profile_section_id: 9380,
    name: "33.7x2.6 CHS",
    type: "Circular Hollow",
    designation: "33.7x2.6 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "2,54",
    d_global: "33,7",
    i_global: "3,09",
    t_global: "2,6",
    z_global: "2,52",
  },
  {
    profile_section_id: 9381,
    name: "33.7x3.2 CHS",
    type: "Circular Hollow",
    designation: "33.7x3.2 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "3,07",
    d_global: "33,7",
    i_global: "3,6",
    t_global: "3,2",
    z_global: "2,99",
  },
  {
    profile_section_id: 9382,
    name: "33.7x4 CHS",
    type: "Circular Hollow",
    designation: "33.7x4 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "3,73",
    d_global: "33,7",
    i_global: "4,19",
    t_global: 4,
    z_global: "3,55",
  },
  {
    profile_section_id: 9383,
    name: "42.4x2.6 CHS",
    type: "Circular Hollow",
    designation: "42.4x2.6 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "3,25",
    d_global: "42,4",
    i_global: "6,46",
    t_global: "2,6",
    z_global: "4,12",
  },
  {
    profile_section_id: 9384,
    name: "42.4x3.2 CHS",
    type: "Circular Hollow",
    designation: "42.4x3.2 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "3,94",
    d_global: "42,4",
    i_global: "7,62",
    t_global: "3,2",
    z_global: "4,93",
  },
  {
    profile_section_id: 9385,
    name: "42.4x4 CHS",
    type: "Circular Hollow",
    designation: "42.4x4 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "4,83",
    d_global: "42,4",
    i_global: "8,99",
    t_global: 4,
    z_global: "5,92",
  },
  {
    profile_section_id: 9386,
    name: "48.3x2.9 CHS",
    type: "Circular Hollow",
    designation: "48.3x2.9 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "4,14",
    d_global: "48,3",
    i_global: "10,7",
    t_global: "2,9",
    z_global: "5,99",
  },
  {
    profile_section_id: 9387,
    name: "48.3x3.2 CHS",
    type: "Circular Hollow",
    designation: "48.3x3.2 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "4,53",
    d_global: "48,3",
    i_global: "11,59",
    t_global: "3,2",
    z_global: "6,52",
  },
  {
    profile_section_id: 9388,
    name: "48.3x4 CHS",
    type: "Circular Hollow",
    designation: "48.3x4 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "5,57",
    d_global: "48,3",
    i_global: "13,77",
    t_global: 4,
    z_global: "7,87",
  },
  {
    profile_section_id: 9389,
    name: "60.3x2.9 CHS",
    type: "Circular Hollow",
    designation: "60.3x2.9 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "5,23",
    d_global: "60,3",
    i_global: "21,59",
    t_global: "2,9",
    z_global: "9,56",
  },
  {
    profile_section_id: 9390,
    name: "60.3x3.6 CHS",
    type: "Circular Hollow",
    designation: "60.3x3.6 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "6,41",
    d_global: "60,3",
    i_global: "25,87",
    t_global: "3,6",
    z_global: "11,59",
  },
  {
    profile_section_id: 9391,
    name: "60.3x4.5 CHS",
    type: "Circular Hollow",
    designation: "60.3x4.5 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "7,89",
    d_global: "60,3",
    i_global: "30,9",
    t_global: "4,5",
    z_global: "14,04",
  },
  {
    profile_section_id: 9392,
    name: "76.1x3.2 CHS",
    type: "Circular Hollow",
    designation: "76.1x3.2 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "7,33",
    d_global: "76,1",
    i_global: "48,78",
    t_global: "3,2",
    z_global: "17,02",
  },
  {
    profile_section_id: 9393,
    name: "76.1x3.6 CHS",
    type: "Circular Hollow",
    designation: "76.1x3.6 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "8,2",
    d_global: "76,1",
    i_global: "54,01",
    t_global: "3,6",
    z_global: "18,94",
  },
  {
    profile_section_id: 9394,
    name: "76.1x4.5 CHS",
    type: "Circular Hollow",
    designation: "76.1x4.5 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "10,12",
    d_global: "76,1",
    i_global: "65,12",
    t_global: "4,5",
    z_global: "23,1",
  },
  {
    profile_section_id: 9395,
    name: "88.9x3.2 CHS",
    type: "Circular Hollow",
    designation: "88.9x3.2 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "8,62",
    d_global: "88,9",
    i_global: "79,21",
    t_global: "3,2",
    z_global: "23,51",
  },
  {
    profile_section_id: 9396,
    name: "88.9x4 CHS",
    type: "Circular Hollow",
    designation: "88.9x4 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "10,67",
    d_global: "88,9",
    i_global: "96,34",
    t_global: 4,
    z_global: "28,85",
  },
  {
    profile_section_id: 9397,
    name: "88.9x4.8 CHS",
    type: "Circular Hollow",
    designation: "88.9x4.8 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "12,68",
    d_global: "88,9",
    i_global: "112,49",
    t_global: "4,8",
    z_global: "33,99",
  },
  {
    profile_section_id: 9398,
    name: "114.3x3.6 CHS",
    type: "Circular Hollow",
    designation: "114.3x3.6 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "12,52",
    d_global: "114,3",
    i_global: "191,98",
    t_global: "3,6",
    z_global: "44,13",
  },
  {
    profile_section_id: 9399,
    name: "114.3x4.5 CHS",
    type: "Circular Hollow",
    designation: "114.3x4.5 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "15,52",
    d_global: "114,3",
    i_global: "234,32",
    t_global: "4,5",
    z_global: "54,28",
  },
  {
    profile_section_id: 9400,
    name: "114.3x5.4 CHS",
    type: "Circular Hollow",
    designation: "114.3x5.4 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "18,47",
    d_global: "114,3",
    i_global: "274,54",
    t_global: "5,4",
    z_global: "64,09",
  },
  {
    profile_section_id: 9401,
    name: "139.7x4.5 CHS",
    type: "Circular Hollow",
    designation: "139.7x4.5 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "19,11",
    d_global: "139,7",
    i_global: "437,2",
    t_global: "4,5",
    z_global: "82,29",
  },
  {
    profile_section_id: 9402,
    name: "139.7x4.8 CHS",
    type: "Circular Hollow",
    designation: "139.7x4.8 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "20,34",
    d_global: "139,7",
    i_global: "463,33",
    t_global: "4,8",
    z_global: "87,39",
  },
  {
    profile_section_id: 9403,
    name: "139.7x5.4 CHS",
    type: "Circular Hollow",
    designation: "139.7x5.4 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "22,78",
    d_global: "139,7",
    i_global: "514,5",
    t_global: "5,4",
    z_global: "97,45",
  },
  {
    profile_section_id: 9404,
    name: "165.1x4.5 CHS",
    type: "Circular Hollow",
    designation: "165.1x4.5 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "22,7",
    d_global: "165,1",
    i_global: "732,57",
    t_global: "4,5",
    z_global: "116,1",
  },
  {
    profile_section_id: 9405,
    name: "165.1x4.8 CHS",
    type: "Circular Hollow",
    designation: "165.1x4.8 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "24,2",
    d_global: "165,1",
    i_global: "777,13",
    t_global: "4,8",
    z_global: "123,38",
  },
  {
    profile_section_id: 9406,
    name: "165.1x5.4 CHS",
    type: "Circular Hollow",
    designation: "165.1x5.4 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "27,1",
    d_global: "165,1",
    i_global: "864,7",
    t_global: "5,4",
    z_global: "137,77",
  },
  {
    profile_section_id: 9407,
    name: "219.1x4.8 CHS",
    type: "Circular Hollow",
    designation: "219.1x4.8 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "32,33",
    d_global: "219,1",
    i_global: "1856,03",
    t_global: "4,8",
    z_global: "220,47",
  },
  {
    profile_section_id: 9408,
    name: "219.1x6 CHS",
    type: "Circular Hollow",
    designation: "219.1x6 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "40,17",
    d_global: "219,1",
    i_global: "2281,95",
    t_global: 6,
    z_global: "272,54",
  },
  {
    profile_section_id: 9409,
    name: "219.1x8 CHS",
    type: "Circular Hollow",
    designation: "219.1x8 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "53,06",
    d_global: "219,1",
    i_global: "2959,63",
    t_global: 8,
    z_global: "356,68",
  },
  {
    profile_section_id: 9410,
    name: "219.1x10 CHS",
    type: "Circular Hollow",
    designation: "219.1x10 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "65,69",
    d_global: "219,1",
    i_global: "3598,44",
    t_global: 10,
    z_global: "437,56",
  },
  {
    profile_section_id: 9411,
    name: "273x6 CHS",
    type: "Circular Hollow",
    designation: "273x6 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "50,33",
    d_global: 273,
    i_global: "4487,08",
    t_global: 6,
    z_global: "427,81",
  },
  {
    profile_section_id: 9412,
    name: "273x8 CHS",
    type: "Circular Hollow",
    designation: "273x8 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "66,6",
    d_global: 273,
    i_global: "5851,71",
    t_global: 8,
    z_global: "561,97",
  },
  {
    profile_section_id: 9413,
    name: "273x10 CHS",
    type: "Circular Hollow",
    designation: "273x10 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "82,62",
    d_global: 273,
    i_global: "7154,09",
    t_global: 10,
    z_global: "692,02",
  },
  {
    profile_section_id: 9414,
    name: "323.9x6.3 CHS",
    type: "Circular Hollow",
    designation: "323.9x6.3 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "62,86",
    d_global: "323,9",
    i_global: "7928,9",
    t_global: "6,3",
    z_global: "635,56",
  },
  {
    profile_section_id: 9415,
    name: "323.9x8 CHS",
    type: "Circular Hollow",
    designation: "323.9x8 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "79,39",
    d_global: "323,9",
    i_global: "9910,08",
    t_global: 8,
    z_global: "798,51",
  },
  {
    profile_section_id: 9416,
    name: "323.9x10 CHS",
    type: "Circular Hollow",
    designation: "323.9x10 CHS",
    shape: "Circular Hollow",
    country_code: "TATA Structura",
    ax_global: "98,61",
    d_global: "323,9",
    i_global: "12158,34",
    t_global: 10,
    z_global: "985,67",
  },
  {
    profile_section_id: 9417,
    name: "21.3X1.6CHS",
    type: "Circular Hollow",
    designation: "21.3X1.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "0,99",
    d_global: "21,3",
    i_global: "0,48",
    t_global: "1,6",
    z_global: "0,62",
  },
  {
    profile_section_id: 9418,
    name: "21.3X1.8CHS",
    type: "Circular Hollow",
    designation: "21.3X1.8CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,1",
    d_global: "21,3",
    i_global: "0,53",
    t_global: "1,8",
    z_global: "0,69",
  },
  {
    profile_section_id: 9419,
    name: "21.3X2.0CHS",
    type: "Circular Hollow",
    designation: "21.3X2.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,21",
    d_global: "21,3",
    i_global: "0,57",
    t_global: 2,
    z_global: "0,75",
  },
  {
    profile_section_id: 9420,
    name: "21.3X2.3CHS",
    type: "Circular Hollow",
    designation: "21.3X2.3CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,37",
    d_global: "21,3",
    i_global: "0,63",
    t_global: "2,3",
    z_global: "0,83",
  },
  {
    profile_section_id: 9421,
    name: "21.3X2.6CHS",
    type: "Circular Hollow",
    designation: "21.3X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,53",
    d_global: "21,3",
    i_global: "0,68",
    t_global: "2,6",
    z_global: "0,92",
  },
  {
    profile_section_id: 9422,
    name: "21.3X2.9CHS",
    type: "Circular Hollow",
    designation: "21.3X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,68",
    d_global: "21,3",
    i_global: "0,73",
    t_global: "2,9",
    z_global: "0,99",
  },
  {
    profile_section_id: 9423,
    name: "26.4X1.6CHS",
    type: "Circular Hollow",
    designation: "26.4X1.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,25",
    d_global: "26,4",
    i_global: "0,96",
    t_global: "1,6",
    z_global: "0,99",
  },
  {
    profile_section_id: 9424,
    name: "26.4X1.8CHS",
    type: "Circular Hollow",
    designation: "26.4X1.8CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,39",
    d_global: "26,4",
    i_global: "1,06",
    t_global: "1,8",
    z_global: "1,09",
  },
  {
    profile_section_id: 9425,
    name: "26.4X2.0CHS",
    type: "Circular Hollow",
    designation: "26.4X2.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,53",
    d_global: "26,4",
    i_global: "1,15",
    t_global: 2,
    z_global: "1,19",
  },
  {
    profile_section_id: 9426,
    name: "26.4X2.3CHS",
    type: "Circular Hollow",
    designation: "26.4X2.3CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,74",
    d_global: "26,4",
    i_global: "1,28",
    t_global: "2,3",
    z_global: "1,34",
  },
  {
    profile_section_id: 9427,
    name: "26.4X2.6CHS",
    type: "Circular Hollow",
    designation: "26.4X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,94",
    d_global: "26,4",
    i_global: "1,39",
    t_global: "2,6",
    z_global: "1,48",
  },
  {
    profile_section_id: 9428,
    name: "26.4X2.9CHS",
    type: "Circular Hollow",
    designation: "26.4X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "2,14",
    d_global: "26,4",
    i_global: "1,5",
    t_global: "2,9",
    z_global: "1,61",
  },
  {
    profile_section_id: 9429,
    name: "33.7X1.6CHS",
    type: "Circular Hollow",
    designation: "33.7X1.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,61",
    d_global: "33,7",
    i_global: "2,08",
    t_global: "1,6",
    z_global: "1,65",
  },
  {
    profile_section_id: 9430,
    name: "33.7X1.8CHS",
    type: "Circular Hollow",
    designation: "33.7X1.8CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,8",
    d_global: "33,7",
    i_global: "2,3",
    t_global: "1,8",
    z_global: "1,83",
  },
  {
    profile_section_id: 9431,
    name: "33.7X2.0CHS",
    type: "Circular Hollow",
    designation: "33.7X2.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "1,99",
    d_global: "33,7",
    i_global: "2,51",
    t_global: 2,
    z_global: "2,01",
  },
  {
    profile_section_id: 9432,
    name: "33.7X2.3CHS",
    type: "Circular Hollow",
    designation: "33.7X2.3CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "2,27",
    d_global: "33,7",
    i_global: "2,81",
    t_global: "2,3",
    z_global: "2,27",
  },
  {
    profile_section_id: 9433,
    name: "33.7X2.6CHS",
    type: "Circular Hollow",
    designation: "33.7X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "2,54",
    d_global: "33,7",
    i_global: "3,09",
    t_global: "2,6",
    z_global: "2,52",
  },
  {
    profile_section_id: 9434,
    name: "33.7X2.9CHS",
    type: "Circular Hollow",
    designation: "33.7X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "2,81",
    d_global: "33,7",
    i_global: "3,36",
    t_global: "2,9",
    z_global: "2,76",
  },
  {
    profile_section_id: 9435,
    name: "33.7X3.2CHS",
    type: "Circular Hollow",
    designation: "33.7X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "3,07",
    d_global: "33,7",
    i_global: "3,6",
    t_global: "3,2",
    z_global: "2,99",
  },
  {
    profile_section_id: 9436,
    name: "33.7X3.6CHS",
    type: "Circular Hollow",
    designation: "33.7X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "3,4",
    d_global: "33,7",
    i_global: "3,91",
    t_global: "3,6",
    z_global: "3,28",
  },
  {
    profile_section_id: 9437,
    name: "42.4X1.6CHS",
    type: "Circular Hollow",
    designation: "42.4X1.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "2,05",
    d_global: "42,4",
    i_global: "4,27",
    t_global: "1,6",
    z_global: "2,66",
  },
  {
    profile_section_id: 9438,
    name: "42.4X1.8CHS",
    type: "Circular Hollow",
    designation: "42.4X1.8CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "2,3",
    d_global: "42,4",
    i_global: "4,74",
    t_global: "1,8",
    z_global: "2,97",
  },
  {
    profile_section_id: 9439,
    name: "42.4X2.0CHS",
    type: "Circular Hollow",
    designation: "42.4X2.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "2,54",
    d_global: "42,4",
    i_global: "5,19",
    t_global: 2,
    z_global: "3,27",
  },
  {
    profile_section_id: 9440,
    name: "42.4X2.3CHS",
    type: "Circular Hollow",
    designation: "42.4X2.3CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "2,9",
    d_global: "42,4",
    i_global: "5,84",
    t_global: "2,3",
    z_global: "3,7",
  },
  {
    profile_section_id: 9441,
    name: "42.4X2.6CHS",
    type: "Circular Hollow",
    designation: "42.4X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "3,25",
    d_global: "42,4",
    i_global: "6,46",
    t_global: "2,6",
    z_global: "4,12",
  },
  {
    profile_section_id: 9442,
    name: "42.4X2.9CHS",
    type: "Circular Hollow",
    designation: "42.4X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "3,6",
    d_global: "42,4",
    i_global: "7,06",
    t_global: "2,9",
    z_global: "4,53",
  },
  {
    profile_section_id: 9443,
    name: "42.4X3.2CHS",
    type: "Circular Hollow",
    designation: "42.4X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "3,94",
    d_global: "42,4",
    i_global: "7,62",
    t_global: "3,2",
    z_global: "4,93",
  },
  {
    profile_section_id: 9444,
    name: "42.4X3.6CHS",
    type: "Circular Hollow",
    designation: "42.4X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "4,39",
    d_global: "42,4",
    i_global: "8,33",
    t_global: "3,6",
    z_global: "5,44",
  },
  {
    profile_section_id: 9445,
    name: "48.3X1.6CHS",
    type: "Circular Hollow",
    designation: "48.3X1.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "2,35",
    d_global: "48,3",
    i_global: "6,41",
    t_global: "1,6",
    z_global: "3,49",
  },
  {
    profile_section_id: 9446,
    name: "48.3X1.8CHS",
    type: "Circular Hollow",
    designation: "48.3X1.8CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "2,63",
    d_global: "48,3",
    i_global: "7,12",
    t_global: "1,8",
    z_global: "3,89",
  },
  {
    profile_section_id: 9447,
    name: "48.3X2.0CHS",
    type: "Circular Hollow",
    designation: "48.3X2.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "2,91",
    d_global: "48,3",
    i_global: "7,81",
    t_global: 2,
    z_global: "4,29",
  },
  {
    profile_section_id: 9448,
    name: "48.3X2.3CHS",
    type: "Circular Hollow",
    designation: "48.3X2.3CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "3,32",
    d_global: "48,3",
    i_global: "8,81",
    t_global: "2,3",
    z_global: "4,87",
  },
  {
    profile_section_id: 9449,
    name: "48.3X2.6CHS",
    type: "Circular Hollow",
    designation: "48.3X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "3,73",
    d_global: "48,3",
    i_global: "9,78",
    t_global: "2,6",
    z_global: "5,44",
  },
  {
    profile_section_id: 9450,
    name: "48.3X2.9CHS",
    type: "Circular Hollow",
    designation: "48.3X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "4,14",
    d_global: "48,3",
    i_global: "10,7",
    t_global: "2,9",
    z_global: "5,99",
  },
  {
    profile_section_id: 9451,
    name: "48.3X3.2CHS",
    type: "Circular Hollow",
    designation: "48.3X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "4,53",
    d_global: "48,3",
    i_global: "11,59",
    t_global: "3,2",
    z_global: "6,52",
  },
  {
    profile_section_id: 9452,
    name: "48.3X3.6CHS",
    type: "Circular Hollow",
    designation: "48.3X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "5,06",
    d_global: "48,3",
    i_global: "12,71",
    t_global: "3,6",
    z_global: "7,21",
  },
  {
    profile_section_id: 9453,
    name: "48.3X4.0CHS",
    type: "Circular Hollow",
    designation: "48.3X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "5,57",
    d_global: "48,3",
    i_global: "13,77",
    t_global: 4,
    z_global: "7,87",
  },
  {
    profile_section_id: 9454,
    name: "48.3X4.5CHS",
    type: "Circular Hollow",
    designation: "48.3X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "6,19",
    d_global: "48,3",
    i_global: "15,01",
    t_global: "4,5",
    z_global: "8,66",
  },
  {
    profile_section_id: 9455,
    name: "48.3X5.0CHS",
    type: "Circular Hollow",
    designation: "48.3X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "6,8",
    d_global: "48,3",
    i_global: "16,15",
    t_global: 5,
    z_global: "9,42",
  },
  {
    profile_section_id: 9456,
    name: "60.3X1.6CHS",
    type: "Circular Hollow",
    designation: "60.3X1.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "2,95",
    d_global: "60,3",
    i_global: "12,72",
    t_global: "1,6",
    z_global: "5,51",
  },
  {
    profile_section_id: 9457,
    name: "60.3X2.0CHS",
    type: "Circular Hollow",
    designation: "60.3X2.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "3,66",
    d_global: "60,3",
    i_global: "15,58",
    t_global: 2,
    z_global: "6,8",
  },
  {
    profile_section_id: 9458,
    name: "60.3X2.3CHS",
    type: "Circular Hollow",
    designation: "60.3X2.3CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "4,19",
    d_global: "60,3",
    i_global: "17,65",
    t_global: "2,3",
    z_global: "7,74",
  },
  {
    profile_section_id: 9459,
    name: "60.3X2.6CHS",
    type: "Circular Hollow",
    designation: "60.3X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "4,71",
    d_global: "60,3",
    i_global: "19,65",
    t_global: "2,6",
    z_global: "8,66",
  },
  {
    profile_section_id: 9460,
    name: "60.3X2.9CHS",
    type: "Circular Hollow",
    designation: "60.3X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "5,23",
    d_global: "60,3",
    i_global: "21,59",
    t_global: "2,9",
    z_global: "9,56",
  },
  {
    profile_section_id: 9461,
    name: "60.3X3.2CHS",
    type: "Circular Hollow",
    designation: "60.3X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "5,74",
    d_global: "60,3",
    i_global: "23,47",
    t_global: "3,2",
    z_global: "10,44",
  },
  {
    profile_section_id: 9462,
    name: "60.3X3.6CHS",
    type: "Circular Hollow",
    designation: "60.3X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "6,41",
    d_global: "60,3",
    i_global: "25,87",
    t_global: "3,6",
    z_global: "11,59",
  },
  {
    profile_section_id: 9463,
    name: "60.3X4.0CHS",
    type: "Circular Hollow",
    designation: "60.3X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "7,07",
    d_global: "60,3",
    i_global: "28,17",
    t_global: 4,
    z_global: "12,7",
  },
  {
    profile_section_id: 9464,
    name: "60.3X4.5CHS",
    type: "Circular Hollow",
    designation: "60.3X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "7,89",
    d_global: "60,3",
    i_global: "30,9",
    t_global: "4,5",
    z_global: "14,04",
  },
  {
    profile_section_id: 9465,
    name: "60.3X5.0CHS",
    type: "Circular Hollow",
    designation: "60.3X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "8,69",
    d_global: "60,3",
    i_global: "33,48",
    t_global: 5,
    z_global: "15,33",
  },
  {
    profile_section_id: 9466,
    name: "76.2X2.0CHS",
    type: "Circular Hollow",
    designation: "76.2X2.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "4,66",
    d_global: "76,2",
    i_global: "32,11",
    t_global: 2,
    z_global: "11,01",
  },
  {
    profile_section_id: 9467,
    name: "76.2X2.3CHS",
    type: "Circular Hollow",
    designation: "76.2X2.3CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "5,34",
    d_global: "76,2",
    i_global: "36,49",
    t_global: "2,3",
    z_global: "12,56",
  },
  {
    profile_section_id: 9468,
    name: "76.2X2.6CHS",
    type: "Circular Hollow",
    designation: "76.2X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "6,01",
    d_global: "76,2",
    i_global: "40,76",
    t_global: "2,6",
    z_global: "14,09",
  },
  {
    profile_section_id: 9469,
    name: "76.2X2.9CHS",
    type: "Circular Hollow",
    designation: "76.2X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "6,68",
    d_global: "76,2",
    i_global: "44,92",
    t_global: "2,9",
    z_global: "15,59",
  },
  {
    profile_section_id: 9470,
    name: "76.2X3.2CHS",
    type: "Circular Hollow",
    designation: "76.2X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "7,34",
    d_global: "76,2",
    i_global: "48,98",
    t_global: "3,2",
    z_global: "17,06",
  },
  {
    profile_section_id: 9471,
    name: "76.2X3.6CHS",
    type: "Circular Hollow",
    designation: "76.2X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "8,21",
    d_global: "76,2",
    i_global: "54,23",
    t_global: "3,6",
    z_global: "18,99",
  },
  {
    profile_section_id: 9472,
    name: "76.2X4.0CHS",
    type: "Circular Hollow",
    designation: "76.2X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "9,07",
    d_global: "76,2",
    i_global: "59,3",
    t_global: 4,
    z_global: "20,87",
  },
  {
    profile_section_id: 9473,
    name: "76.2X4.5CHS",
    type: "Circular Hollow",
    designation: "76.2X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "10,14",
    d_global: "76,2",
    i_global: "65,39",
    t_global: "4,5",
    z_global: "23,16",
  },
  {
    profile_section_id: 9474,
    name: "76.2X5.0CHS",
    type: "Circular Hollow",
    designation: "76.2X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "11,18",
    d_global: "76,2",
    i_global: "71,22",
    t_global: 5,
    z_global: "25,39",
  },
  {
    profile_section_id: 9475,
    name: "88.9X2.0CHS",
    type: "Circular Hollow",
    designation: "88.9X2.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "5,46",
    d_global: "88,9",
    i_global: "51,57",
    t_global: 2,
    z_global: "15,11",
  },
  {
    profile_section_id: 9476,
    name: "88.9X2.3CHS",
    type: "Circular Hollow",
    designation: "88.9X2.3CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "6,26",
    d_global: "88,9",
    i_global: "58,7",
    t_global: "2,3",
    z_global: "17,25",
  },
  {
    profile_section_id: 9477,
    name: "88.9X2.6CHS",
    type: "Circular Hollow",
    designation: "88.9X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "7,05",
    d_global: "88,9",
    i_global: "65,68",
    t_global: "2,6",
    z_global: "19,37",
  },
  {
    profile_section_id: 9478,
    name: "88.9X2.9CHS",
    type: "Circular Hollow",
    designation: "88.9X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "7,84",
    d_global: "88,9",
    i_global: "72,52",
    t_global: "2,9",
    z_global: "21,46",
  },
  {
    profile_section_id: 9479,
    name: "88.9X3.2CHS",
    type: "Circular Hollow",
    designation: "88.9X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "8,62",
    d_global: "88,9",
    i_global: "79,21",
    t_global: "3,2",
    z_global: "23,51",
  },
  {
    profile_section_id: 9480,
    name: "88.9X3.6CHS",
    type: "Circular Hollow",
    designation: "88.9X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "9,65",
    d_global: "88,9",
    i_global: "87,9",
    t_global: "3,6",
    z_global: "26,21",
  },
  {
    profile_section_id: 9481,
    name: "88.9X4.0CHS",
    type: "Circular Hollow",
    designation: "88.9X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "10,67",
    d_global: "88,9",
    i_global: "96,34",
    t_global: 4,
    z_global: "28,85",
  },
  {
    profile_section_id: 9482,
    name: "88.9X4.5CHS",
    type: "Circular Hollow",
    designation: "88.9X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "11,93",
    d_global: "88,9",
    i_global: "106,54",
    t_global: "4,5",
    z_global: "32,09",
  },
  {
    profile_section_id: 9483,
    name: "88.9X5.0CHS",
    type: "Circular Hollow",
    designation: "88.9X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "13,18",
    d_global: "88,9",
    i_global: "116,37",
    t_global: 5,
    z_global: "35,24",
  },
  {
    profile_section_id: 9484,
    name: "101.6X2.0CHS",
    type: "Circular Hollow",
    designation: "101.6X2.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "6,26",
    d_global: "101,6",
    i_global: "77,63",
    t_global: 2,
    z_global: "19,84",
  },
  {
    profile_section_id: 9485,
    name: "101.6X2.3CHS",
    type: "Circular Hollow",
    designation: "101.6X2.3CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "7,18",
    d_global: "101,6",
    i_global: "88,48",
    t_global: "2,3",
    z_global: "22,68",
  },
  {
    profile_section_id: 9486,
    name: "101.6X2.6CHS",
    type: "Circular Hollow",
    designation: "101.6X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "8,09",
    d_global: "101,6",
    i_global: "99,14",
    t_global: "2,6",
    z_global: "25,49",
  },
  {
    profile_section_id: 9487,
    name: "101.6X2.9CHS",
    type: "Circular Hollow",
    designation: "101.6X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "8,99",
    d_global: "101,6",
    i_global: "109,59",
    t_global: "2,9",
    z_global: "28,26",
  },
  {
    profile_section_id: 9488,
    name: "101.6X3.2CHS",
    type: "Circular Hollow",
    designation: "101.6X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "9,89",
    d_global: "101,6",
    i_global: "119,85",
    t_global: "3,2",
    z_global: 31,
  },
  {
    profile_section_id: 9489,
    name: "101.6X3.6CHS",
    type: "Circular Hollow",
    designation: "101.6X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "11,08",
    d_global: "101,6",
    i_global: "133,24",
    t_global: "3,6",
    z_global: "34,59",
  },
  {
    profile_section_id: 9490,
    name: "101.6X4.0CHS",
    type: "Circular Hollow",
    designation: "101.6X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "12,26",
    d_global: "101,6",
    i_global: "146,28",
    t_global: 4,
    z_global: "38,12",
  },
  {
    profile_section_id: 9491,
    name: "101.6X4.5CHS",
    type: "Circular Hollow",
    designation: "101.6X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "13,73",
    d_global: "101,6",
    i_global: "162,13",
    t_global: "4,5",
    z_global: "42,46",
  },
  {
    profile_section_id: 9492,
    name: "101.6X5.0CHS",
    type: "Circular Hollow",
    designation: "101.6X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "15,17",
    d_global: "101,6",
    i_global: "177,47",
    t_global: 5,
    z_global: "46,7",
  },
  {
    profile_section_id: 9493,
    name: "114.3X2.0CHS",
    type: "Circular Hollow",
    designation: "114.3X2.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "7,06",
    d_global: "114,3",
    i_global: "111,27",
    t_global: 2,
    z_global: "25,23",
  },
  {
    profile_section_id: 9494,
    name: "114.3X2.3CHS",
    type: "Circular Hollow",
    designation: "114.3X2.3CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "8,09",
    d_global: "114,3",
    i_global: "126,95",
    t_global: "2,3",
    z_global: "28,86",
  },
  {
    profile_section_id: 9495,
    name: "114.3X2.6CHS",
    type: "Circular Hollow",
    designation: "114.3X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "9,12",
    d_global: "114,3",
    i_global: "142,37",
    t_global: "2,6",
    z_global: "32,45",
  },
  {
    profile_section_id: 9496,
    name: "114.3X2.9CHS",
    type: "Circular Hollow",
    designation: "114.3X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "10,15",
    d_global: "114,3",
    i_global: "157,55",
    t_global: "2,9",
    z_global: 36,
  },
  {
    profile_section_id: 9497,
    name: "114.3X3.2CHS",
    type: "Circular Hollow",
    designation: "114.3X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "11,17",
    d_global: "114,3",
    i_global: "172,47",
    t_global: "3,2",
    z_global: "39,51",
  },
  {
    profile_section_id: 9498,
    name: "114.3X3.6CHS",
    type: "Circular Hollow",
    designation: "114.3X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "12,52",
    d_global: "114,3",
    i_global: "191,98",
    t_global: "3,6",
    z_global: "44,13",
  },
  {
    profile_section_id: 9499,
    name: "114.3X4.0CHS",
    type: "Circular Hollow",
    designation: "114.3X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "13,86",
    d_global: "114,3",
    i_global: "211,07",
    t_global: 4,
    z_global: "48,69",
  },
  {
    profile_section_id: 9500,
    name: "114.3X4.5CHS",
    type: "Circular Hollow",
    designation: "114.3X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "15,52",
    d_global: "114,3",
    i_global: "234,32",
    t_global: "4,5",
    z_global: "54,28",
  },
  {
    profile_section_id: 9501,
    name: "114.3X5.0CHS",
    type: "Circular Hollow",
    designation: "114.3X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "17,17",
    d_global: "114,3",
    i_global: "256,92",
    t_global: 5,
    z_global: "59,77",
  },
  {
    profile_section_id: 9502,
    name: "114.3X6.0CHS",
    type: "Circular Hollow",
    designation: "114.3X6.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "20,41",
    d_global: "114,3",
    i_global: "300,21",
    t_global: 6,
    z_global: "70,45",
  },
  {
    profile_section_id: 9503,
    name: "127X2.6CHS",
    type: "Circular Hollow",
    designation: "127X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "10,16",
    d_global: 127,
    i_global: "196,65",
    t_global: "2,6",
    z_global: "40,24",
  },
  {
    profile_section_id: 9504,
    name: "127X2.9CHS",
    type: "Circular Hollow",
    designation: "127X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "11,31",
    d_global: 127,
    i_global: "217,78",
    t_global: "2,9",
    z_global: "44,67",
  },
  {
    profile_section_id: 9505,
    name: "127X3.2CHS",
    type: "Circular Hollow",
    designation: "127X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "12,45",
    d_global: 127,
    i_global: "238,6",
    t_global: "3,2",
    z_global: "49,06",
  },
  {
    profile_section_id: 9506,
    name: "127X3.6CHS",
    type: "Circular Hollow",
    designation: "127X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "13,96",
    d_global: 127,
    i_global: "265,87",
    t_global: "3,6",
    z_global: "54,83",
  },
  {
    profile_section_id: 9507,
    name: "127X4.0CHS",
    type: "Circular Hollow",
    designation: "127X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "15,46",
    d_global: 127,
    i_global: "292,61",
    t_global: 4,
    z_global: "60,54",
  },
  {
    profile_section_id: 9508,
    name: "127X4.5CHS",
    type: "Circular Hollow",
    designation: "127X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "17,32",
    d_global: 127,
    i_global: "325,29",
    t_global: "4,5",
    z_global: "67,56",
  },
  {
    profile_section_id: 9509,
    name: "127X5.0CHS",
    type: "Circular Hollow",
    designation: "127X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "19,16",
    d_global: 127,
    i_global: "357,14",
    t_global: 5,
    z_global: "74,46",
  },
  {
    profile_section_id: 9510,
    name: "127X6.0CHS",
    type: "Circular Hollow",
    designation: "127X6.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "22,81",
    d_global: 127,
    i_global: "418,44",
    t_global: 6,
    z_global: "87,92",
  },
  {
    profile_section_id: 9511,
    name: "139.7X2.6CHS",
    type: "Circular Hollow",
    designation: "139.7X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "11,2",
    d_global: "139,7",
    i_global: "263,21",
    t_global: "2,6",
    z_global: "48,88",
  },
  {
    profile_section_id: 9512,
    name: "139.7X2.9CHS",
    type: "Circular Hollow",
    designation: "139.7X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "12,46",
    d_global: "139,7",
    i_global: "291,68",
    t_global: "2,9",
    z_global: "54,28",
  },
  {
    profile_section_id: 9513,
    name: "139.7X3.2CHS",
    type: "Circular Hollow",
    designation: "139.7X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "13,72",
    d_global: "139,7",
    i_global: "319,78",
    t_global: "3,2",
    z_global: "59,63",
  },
  {
    profile_section_id: 9514,
    name: "139.7X3.6CHS",
    type: "Circular Hollow",
    designation: "139.7X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "15,39",
    d_global: "139,7",
    i_global: "356,65",
    t_global: "3,6",
    z_global: "66,7",
  },
  {
    profile_section_id: 9515,
    name: "139.7X4.0CHS",
    type: "Circular Hollow",
    designation: "139.7X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "17,05",
    d_global: "139,7",
    i_global: "392,86",
    t_global: 4,
    z_global: "73,68",
  },
  {
    profile_section_id: 9516,
    name: "139.7X4.5CHS",
    type: "Circular Hollow",
    designation: "139.7X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "19,11",
    d_global: "139,7",
    i_global: "437,2",
    t_global: "4,5",
    z_global: "82,29",
  },
  {
    profile_section_id: 9517,
    name: "139.7X5.0CHS",
    type: "Circular Hollow",
    designation: "139.7X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "21,16",
    d_global: "139,7",
    i_global: "480,54",
    t_global: 5,
    z_global: "90,76",
  },
  {
    profile_section_id: 9518,
    name: "139.7X6.0CHS",
    type: "Circular Hollow",
    designation: "139.7X6.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "25,2",
    d_global: "139,7",
    i_global: "564,26",
    t_global: 6,
    z_global: "107,33",
  },
  {
    profile_section_id: 9519,
    name: "139.7X8.0CHS",
    type: "Circular Hollow",
    designation: "139.7X8.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "33,1",
    d_global: "139,7",
    i_global: "720,29",
    t_global: 8,
    z_global: "138,93",
  },
  {
    profile_section_id: 9520,
    name: "165.1X2.6CHS",
    type: "Circular Hollow",
    designation: "165.1X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "13,27",
    d_global: "165,1",
    i_global: "438,23",
    t_global: "2,6",
    z_global: "68,66",
  },
  {
    profile_section_id: 9521,
    name: "165.1X2.9CHS",
    type: "Circular Hollow",
    designation: "165.1X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "14,78",
    d_global: "165,1",
    i_global: "486,13",
    t_global: "2,9",
    z_global: "76,3",
  },
  {
    profile_section_id: 9522,
    name: "165.1X3.2CHS",
    type: "Circular Hollow",
    designation: "165.1X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "16,28",
    d_global: "165,1",
    i_global: "533,48",
    t_global: "3,2",
    z_global: "83,89",
  },
  {
    profile_section_id: 9523,
    name: "165.1X3.6CHS",
    type: "Circular Hollow",
    designation: "165.1X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "18,27",
    d_global: "165,1",
    i_global: "595,79",
    t_global: "3,6",
    z_global: "93,91",
  },
  {
    profile_section_id: 9524,
    name: "165.1X4.0CHS",
    type: "Circular Hollow",
    designation: "165.1X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "20,24",
    d_global: "165,1",
    i_global: "657,16",
    t_global: 4,
    z_global: "103,83",
  },
  {
    profile_section_id: 9525,
    name: "165.1X4.5CHS",
    type: "Circular Hollow",
    designation: "165.1X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "22,7",
    d_global: "165,1",
    i_global: "732,57",
    t_global: "4,5",
    z_global: "116,1",
  },
  {
    profile_section_id: 9526,
    name: "165.1X5.0CHS",
    type: "Circular Hollow",
    designation: "165.1X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "25,15",
    d_global: "165,1",
    i_global: "806,54",
    t_global: 5,
    z_global: "128,2",
  },
  {
    profile_section_id: 9527,
    name: "165.1X6.0CHS",
    type: "Circular Hollow",
    designation: "165.1X6.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "29,99",
    d_global: "165,1",
    i_global: "950,25",
    t_global: 6,
    z_global: "151,95",
  },
  {
    profile_section_id: 9528,
    name: "165.1X8.0CHS",
    type: "Circular Hollow",
    designation: "165.1X8.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "39,48",
    d_global: "165,1",
    i_global: "1221,25",
    t_global: 8,
    z_global: "197,61",
  },
  {
    profile_section_id: 9529,
    name: "168.3X2.6CHS",
    type: "Circular Hollow",
    designation: "168.3X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "13,53",
    d_global: "168,3",
    i_global: "464,63",
    t_global: "2,6",
    z_global: "71,39",
  },
  {
    profile_section_id: 9530,
    name: "168.3X2.9CHS",
    type: "Circular Hollow",
    designation: "168.3X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "15,07",
    d_global: "168,3",
    i_global: "515,46",
    t_global: "2,9",
    z_global: "79,34",
  },
  {
    profile_section_id: 9531,
    name: "168.3X3.2CHS",
    type: "Circular Hollow",
    designation: "168.3X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "16,6",
    d_global: "168,3",
    i_global: "565,74",
    t_global: "3,2",
    z_global: "87,24",
  },
  {
    profile_section_id: 9532,
    name: "168.3X3.6CHS",
    type: "Circular Hollow",
    designation: "168.3X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "18,63",
    d_global: "168,3",
    i_global: "631,9",
    t_global: "3,6",
    z_global: "97,67",
  },
  {
    profile_section_id: 9533,
    name: "168.3X4.0CHS",
    type: "Circular Hollow",
    designation: "168.3X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "20,65",
    d_global: "168,3",
    i_global: "697,09",
    t_global: 4,
    z_global: 108,
  },
  {
    profile_section_id: 9534,
    name: "168.3X4.5CHS",
    type: "Circular Hollow",
    designation: "168.3X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "23,16",
    d_global: "168,3",
    i_global: "777,22",
    t_global: "4,5",
    z_global: "120,77",
  },
  {
    profile_section_id: 9535,
    name: "168.3X5.0CHS",
    type: "Circular Hollow",
    designation: "168.3X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "25,65",
    d_global: "168,3",
    i_global: "855,85",
    t_global: 5,
    z_global: "133,38",
  },
  {
    profile_section_id: 9536,
    name: "168.3X6.0CHS",
    type: "Circular Hollow",
    designation: "168.3X6.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "30,59",
    d_global: "168,3",
    i_global: "1008,69",
    t_global: 6,
    z_global: "158,12",
  },
  {
    profile_section_id: 9537,
    name: "168.3X8.0CHS",
    type: "Circular Hollow",
    designation: "168.3X8.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "40,29",
    d_global: "168,3",
    i_global: "1297,27",
    t_global: 8,
    z_global: "205,74",
  },
  {
    profile_section_id: 9538,
    name: "193.7X2.6CHS",
    type: "Circular Hollow",
    designation: "193.7X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "15,61",
    d_global: "193,7",
    i_global: "712,68",
    t_global: "2,6",
    z_global: "94,96",
  },
  {
    profile_section_id: 9539,
    name: "193.7X2.9CHS",
    type: "Circular Hollow",
    designation: "193.7X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "17,38",
    d_global: "193,7",
    i_global: "791,21",
    t_global: "2,9",
    z_global: "105,58",
  },
  {
    profile_section_id: 9540,
    name: "193.7X3.2CHS",
    type: "Circular Hollow",
    designation: "193.7X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "19,15",
    d_global: "193,7",
    i_global: 869,
    t_global: "3,2",
    z_global: "116,14",
  },
  {
    profile_section_id: 9541,
    name: "193.7X3.6CHS",
    type: "Circular Hollow",
    designation: "193.7X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "21,5",
    d_global: "193,7",
    i_global: "971,55",
    t_global: "3,6",
    z_global: "130,11",
  },
  {
    profile_section_id: 9542,
    name: "193.7X4.0CHS",
    type: "Circular Hollow",
    designation: "193.7X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "23,84",
    d_global: "193,7",
    i_global: "1072,79",
    t_global: 4,
    z_global: "143,97",
  },
  {
    profile_section_id: 9543,
    name: "193.7X4.5CHS",
    type: "Circular Hollow",
    designation: "193.7X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "26,75",
    d_global: "193,7",
    i_global: "1197,52",
    t_global: "4,5",
    z_global: "161,12",
  },
  {
    profile_section_id: 9544,
    name: "193.7X5.0CHS",
    type: "Circular Hollow",
    designation: "193.7X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "29,64",
    d_global: "193,7",
    i_global: "1320,23",
    t_global: 5,
    z_global: "178,08",
  },
  {
    profile_section_id: 9545,
    name: "193.7X6.0CHS",
    type: "Circular Hollow",
    designation: "193.7X6.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "35,38",
    d_global: "193,7",
    i_global: "1559,72",
    t_global: 6,
    z_global: "211,46",
  },
  {
    profile_section_id: 9546,
    name: "193.7X8.0CHS",
    type: "Circular Hollow",
    designation: "193.7X8.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "46,67",
    d_global: "193,7",
    i_global: "2015,54",
    t_global: 8,
    z_global: "276,05",
  },
  {
    profile_section_id: 9547,
    name: "219.7X2.6CHS",
    type: "Circular Hollow",
    designation: "219.7X2.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "17,73",
    d_global: "219,7",
    i_global: "1044,9",
    t_global: "2,6",
    z_global: "122,55",
  },
  {
    profile_section_id: 9548,
    name: "219.7X2.9CHS",
    type: "Circular Hollow",
    designation: "219.7X2.9CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "19,75",
    d_global: "219,7",
    i_global: "1160,68",
    t_global: "2,9",
    z_global: "136,31",
  },
  {
    profile_section_id: 9549,
    name: "219.7X3.2CHS",
    type: "Circular Hollow",
    designation: "219.7X3.2CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "21,76",
    d_global: "219,7",
    i_global: "1275,49",
    t_global: "3,2",
    z_global: 150,
  },
  {
    profile_section_id: 9550,
    name: "219.7X3.6CHS",
    type: "Circular Hollow",
    designation: "219.7X3.6CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "24,44",
    d_global: "219,7",
    i_global: "1427,08",
    t_global: "3,6",
    z_global: "168,13",
  },
  {
    profile_section_id: 9551,
    name: "219.7X4.0CHS",
    type: "Circular Hollow",
    designation: "219.7X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "27,11",
    d_global: "219,7",
    i_global: "1576,96",
    t_global: 4,
    z_global: "186,13",
  },
  {
    profile_section_id: 9552,
    name: "219.7X4.5CHS",
    type: "Circular Hollow",
    designation: "219.7X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "30,42",
    d_global: "219,7",
    i_global: "1761,93",
    t_global: "4,5",
    z_global: "208,43",
  },
  {
    profile_section_id: 9553,
    name: "219.7X5.0CHS",
    type: "Circular Hollow",
    designation: "219.7X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "33,72",
    d_global: "219,7",
    i_global: "1944,29",
    t_global: 5,
    z_global: "230,52",
  },
  {
    profile_section_id: 9554,
    name: "219.7X6.0CHS",
    type: "Circular Hollow",
    designation: "219.7X6.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "40,28",
    d_global: "219,7",
    i_global: "2301,27",
    t_global: 6,
    z_global: "274,08",
  },
  {
    profile_section_id: 9555,
    name: "219.7X8.0CHS",
    type: "Circular Hollow",
    designation: "219.7X8.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "53,21",
    d_global: "219,7",
    i_global: "2984,92",
    t_global: 8,
    z_global: "358,71",
  },
  {
    profile_section_id: 9556,
    name: "254X4.0CHS",
    type: "Circular Hollow",
    designation: "254X4.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "31,42",
    d_global: 254,
    i_global: 2455,
    t_global: 4,
    z_global: "250,02",
  },
  {
    profile_section_id: 9557,
    name: "254X4.5CHS",
    type: "Circular Hollow",
    designation: "254X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "35,27",
    d_global: 254,
    i_global: "2745,52",
    t_global: "4,5",
    z_global: "280,16",
  },
  {
    profile_section_id: 9558,
    name: "254X5.0CHS",
    type: "Circular Hollow",
    designation: "254X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "39,11",
    d_global: 254,
    i_global: "3032,52",
    t_global: 5,
    z_global: "310,05",
  },
  {
    profile_section_id: 9559,
    name: "254X6.0CHS",
    type: "Circular Hollow",
    designation: "254X6.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "46,75",
    d_global: 254,
    i_global: "3596,01",
    t_global: 6,
    z_global: "369,1",
  },
  {
    profile_section_id: 9560,
    name: "254X8.0CHS",
    type: "Circular Hollow",
    designation: "254X8.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "61,83",
    d_global: 254,
    i_global: "4681,82",
    t_global: 8,
    z_global: "484,3",
  },
  {
    profile_section_id: 9561,
    name: "273.1X4.5CHS",
    type: "Circular Hollow",
    designation: "273.1X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "37,97",
    d_global: "273,1",
    i_global: "3425,41",
    t_global: "4,5",
    z_global: "324,69",
  },
  {
    profile_section_id: 9562,
    name: "273.1X5.0CHS",
    type: "Circular Hollow",
    designation: "273.1X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "42,11",
    d_global: "273,1",
    i_global: "3785,05",
    t_global: 5,
    z_global: "359,43",
  },
  {
    profile_section_id: 9563,
    name: "273.1X6.0CHS",
    type: "Circular Hollow",
    designation: "273.1X6.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "50,35",
    d_global: "273,1",
    i_global: "4492,13",
    t_global: 6,
    z_global: "428,13",
  },
  {
    profile_section_id: 9564,
    name: "273.1X8.0CHS",
    type: "Circular Hollow",
    designation: "273.1X8.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "66,63",
    d_global: "273,1",
    i_global: "5858,34",
    t_global: 8,
    z_global: "562,39",
  },
  {
    profile_section_id: 9565,
    name: "273.1X10.0CHS",
    type: "Circular Hollow",
    designation: "273.1X10.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "82,66",
    d_global: "273,1",
    i_global: "7162,25",
    t_global: 10,
    z_global: "692,55",
  },
  {
    profile_section_id: 9566,
    name: "323X4.5CHS",
    type: "Circular Hollow",
    designation: "323X4.5CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "45,03",
    d_global: 323,
    i_global: "5710,67",
    t_global: "4,5",
    z_global: "456,52",
  },
  {
    profile_section_id: 9567,
    name: "323X5.0CHS",
    type: "Circular Hollow",
    designation: "323X5.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "49,95",
    d_global: 323,
    i_global: "6315,66",
    t_global: 5,
    z_global: "505,66",
  },
  {
    profile_section_id: 9568,
    name: "323X6.0CHS",
    type: "Circular Hollow",
    designation: "323X6.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "59,75",
    d_global: 323,
    i_global: "7508,35",
    t_global: 6,
    z_global: "603,01",
  },
  {
    profile_section_id: 9569,
    name: "323X8.0CHS",
    type: "Circular Hollow",
    designation: "323X8.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "79,17",
    d_global: 323,
    i_global: "9825,66",
    t_global: 8,
    z_global: "793,97",
  },
  {
    profile_section_id: 9570,
    name: "323X10.0CHS",
    type: "Circular Hollow",
    designation: "323X10.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "98,33",
    d_global: 323,
    i_global: "12054,13",
    t_global: 10,
    z_global: "980,02",
  },
  {
    profile_section_id: 9571,
    name: "355.6X6.0CHS",
    type: "Circular Hollow",
    designation: "355.6X6.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "65,9",
    d_global: "355,6",
    i_global: "10070,55",
    t_global: 6,
    z_global: "733,39",
  },
  {
    profile_section_id: 9572,
    name: "355.6X8.0CHS",
    type: "Circular Hollow",
    designation: "355.6X8.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "87,36",
    d_global: "355,6",
    i_global: "13201,37",
    t_global: 8,
    z_global: "966,78",
  },
  {
    profile_section_id: 9573,
    name: "355.6X10.0CHS",
    type: "Circular Hollow",
    designation: "355.6X10.0CHS",
    shape: "Circular Hollow",
    country_code: "APL Apollo tubes",
    ax_global: "108,57",
    d_global: "355,6",
    i_global: "16223,5",
    t_global: 10,
    z_global: "1194,73",
  },
  {
    profile_section_id: 9702,
    name: "PIP30",
    type: "Pipe",
    designation: "PIP30",
    shape: "PIPE",
    country_code: "Venezulan",
    ax_global: "0,81",
    d_global: 3,
    i_global: "0,86",
    t_global: "0,09",
    z_global: "0,75",
  },
  {
    profile_section_id: 9703,
    name: "PIP34",
    type: "Pipe",
    designation: "PIP34",
    shape: "PIPE",
    country_code: "Venezulan",
    ax_global: "0,95",
    d_global: "3,5",
    i_global: "1,38",
    t_global: "0,09",
    z_global: "1,03",
  },
  {
    profile_section_id: 9704,
    name: "PIP44",
    type: "Pipe",
    designation: "PIP44",
    shape: "PIPE",
    country_code: "Venezulan",
    ax_global: "1,36",
    d_global: "4,49",
    i_global: "3,29",
    t_global: "0,1",
    z_global: "1,9",
  },
  {
    profile_section_id: 9705,
    name: "PIP50",
    type: "Pipe",
    designation: "PIP50",
    shape: "PIPE",
    country_code: "Venezulan",
    ax_global: "1,81",
    d_global: 5,
    i_global: "5,41",
    t_global: "0,12",
    z_global: "2,81",
  },
  {
    profile_section_id: 9706,
    name: "PIP54",
    type: "Pipe",
    designation: "PIP54",
    shape: "PIPE",
    country_code: "Venezulan",
    ax_global: "2,26",
    d_global: "5,51",
    i_global: "8,12",
    t_global: "0,13",
    z_global: "3,87",
  },
  {
    profile_section_id: 9707,
    name: "PIP60",
    type: "Pipe",
    designation: "PIP60",
    shape: "PIPE",
    country_code: "Venezulan",
    ax_global: "2,9",
    d_global: "5,98",
    i_global: "12,35",
    t_global: "0,16",
    z_global: "5,35",
  },
  {
    profile_section_id: 9708,
    name: "PIP65",
    type: "Pipe",
    designation: "PIP65",
    shape: "PIPE",
    country_code: "Venezulan",
    ax_global: "3,44",
    d_global: "6,61",
    i_global: "17,9",
    t_global: "0,17",
    z_global: "7,03",
  },
  {
    profile_section_id: 9709,
    name: "PIP75",
    type: "Pipe",
    designation: "PIP75",
    shape: "PIPE",
    country_code: "Venezulan",
    ax_global: "4,14",
    d_global: "7,64",
    i_global: "28,83",
    t_global: "0,18",
    z_global: "9,86",
  },
  {
    profile_section_id: 9710,
    name: "PIP85",
    type: "Pipe",
    designation: "PIP85",
    shape: "PIPE",
    country_code: "Venezulan",
    ax_global: "5,72",
    d_global: "8,62",
    i_global: "50,45",
    t_global: "0,22",
    z_global: "15,3",
  },
  {
    profile_section_id: 9711,
    name: "PIP106",
    type: "Pipe",
    designation: "PIP106",
    shape: "PIPE",
    country_code: "Venezulan",
    ax_global: "9,07",
    d_global: "10,75",
    i_global: "124,4",
    t_global: "0,28",
    z_global: "30,22",
  },
  {
    profile_section_id: 9712,
    name: "PIP126",
    type: "Pipe",
    designation: "PIP126",
    shape: "PIPE",
    country_code: "Venezulan",
    ax_global: "13,8",
    d_global: "12,76",
    i_global: "265,3",
    t_global: "0,35",
    z_global: "54,5",
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: p.ax ? fixValueToNumber(p.ax, "float") : null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: null,
    bf: null,
    c: p.c ? fixValueToNumber(p.c, "float") : null,
    c_global: p.c_global ? fixValueToNumber(p.c_global, "float") : undefined,
    ct: null,
    d: null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: null,
    i: p.i ? fixValueToNumber(p.i, "float") : null,
    i_global: p.i_global ? fixValueToNumber(p.i_global, "float") : undefined,
    ix: null,
    iy: null,
    iz: null,
    k: null,
    k1: null,
    od: p.od ? fixValueToNumber(p.od, "float") : null,
    od_global: p.od_global ? fixValueToNumber(p.od_global, "float") : undefined,
    r1: null,
    r2: null,
    rz: null,
    t: null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: null,
    tw: p.tw ? fixValueToNumber(p.tw, "float") : null,
    tw_global: p.tw_global ? fixValueToNumber(p.tw_global, "float") : undefined,
    z: p.z ? fixValueToNumber(p.z, "float") : null,
    z_global: p.z_global ? fixValueToNumber(p.z_global, "float") : undefined,
    zx: null,
    zy: null,
    zz: null,
    width: 0,
    width_global: 0,
    thickness: 0,
    thickness_global: 0,
    height: 0,
    height_global: 0,
  };
  return profile;
});
