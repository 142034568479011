import React, { useState, useEffect } from "react";
import { Button, FormGroup, InputGroup, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import OTPInput from './OTPInput';
import { useHistory } from "react-router-dom";
import Logo from "../../assets/A-Logo-Blue-Transparent.png";
import "./Login.css";

type Props = {
    inProgress?: boolean;
    onUnload: () => any;
    onSubmit: (otp: string, password: string) => any;
    loginError: string;
    setLoginError: (error: string) => void;
};

export const Updatepass: React.FC<Props> = ({ onUnload, onSubmit, inProgress, loginError, setLoginError }) => {
    const [otp, setOTP] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [passwordUpdateSuccess, setPasswordUpdateSuccess] = useState<boolean>(false);
    const [passupdateStatus, setPassupdateStatus] = useState<String>("");


    useEffect(() => {
        return () => onUnload();
    }, [onUnload]);

    const history = useHistory();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoginError("");
        setPassupdateStatus("");
        if (password !== confirmPassword) {
            setLoginError("Passwords do not match.");
            return;
        }
        onSubmit(otp, password)
          .then(() => {
              setPasswordUpdateSuccess(true); 
          })
          .catch((error:any) => {
              const errorMessage = "OTP is invalid.";
              setPassupdateStatus(errorMessage);
          });
    };
    
    

    const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

    return (
        <div className="auth-page">
        <div className="primary-container">
            <div className="container-1">
                <h1>reset password.</h1>
            </div>
            <div className="container-2">
                <div className="logo-container-2">
                    <img className="primary-logo" src={Logo} alt="Logo" />
                    <h1>
                        Integrated
                        <br />
                        Design
                        <br />
                        Suite
                    </h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <text className="login-text-group">enter OTP</text>
                        <OTPInput otpLength={6} onOTPComplete={setOTP} />
                    </FormGroup>
                    <FormGroup>
                        <text className="login-text-group">new password</text>
                        <InputGroup
                            large
                            type={showPassword ? "text" : "password"}
                            rightElement={
                                <Button
                                    icon={showPassword ? IconNames.EYE_OFF : IconNames.EYE_OPEN}
                                    minimal={true}
                                    onClick={togglePasswordVisibility}
                                />
                            }
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <text className="login-text-group">confirm new password</text>
                        <InputGroup
                            large
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </FormGroup>
                    {loginError && <div className="error-message">{loginError}</div>}
                    {passupdateStatus && <div className="error-message">{passupdateStatus}</div>}
                    <FormGroup className="t-center">
                        <Button
                            large
                            type="submit"
                            className="form-group-button"
                            intent="primary"
                            loading={inProgress}
                        >
                            Update Password
                        </Button>
                    </FormGroup>
                    {passwordUpdateSuccess && (
                        <Button
                            large
                            style={{ paddingRight: '5px' }}
                            intent="success"
                            onClick={() => history.push('/')}
                        >
                            Proceed to Login Page
                        </Button>
                    )}
                </form>
            </div>
        </div>
        </div>
    );
};
