import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesDutch1 = [
  {
    profile_section_id: 5077,
    name: "PIPBG9.5X1",
    type: "Pipe",
    designation: "PIPBG9.5X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,27",
    d_global: "9,5",
    i_global: "0,02",
    t_global: 1,
    z_global: "0,05",
  },
  {
    profile_section_id: 5078,
    name: "PIPBG10X1",
    type: "Pipe",
    designation: "PIPBG10X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,28",
    d_global: 10,
    i_global: "0,03",
    t_global: 1,
    z_global: "0,06",
  },
  {
    profile_section_id: 5079,
    name: "PIPBG10X1.2",
    type: "Pipe",
    designation: "PIPBG10X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,33",
    d_global: 10,
    i_global: "0,03",
    t_global: "1,2",
    z_global: "0,07",
  },
  {
    profile_section_id: 5080,
    name: "PIPBG12X1",
    type: "Pipe",
    designation: "PIPBG12X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,35",
    d_global: 12,
    i_global: "0,05",
    t_global: 1,
    z_global: "0,09",
  },
  {
    profile_section_id: 5081,
    name: "PIPBG12.7X1",
    type: "Pipe",
    designation: "PIPBG12.7X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,37",
    d_global: "12,7",
    i_global: "0,06",
    t_global: 1,
    z_global: "0,1",
  },
  {
    profile_section_id: 5082,
    name: "PIPBG12.7X1.2",
    type: "Pipe",
    designation: "PIPBG12.7X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,43",
    d_global: "12,7",
    i_global: "0,07",
    t_global: "1,2",
    z_global: "0,11",
  },
  {
    profile_section_id: 5083,
    name: "PIPBG12.7X1.5",
    type: "Pipe",
    designation: "PIPBG12.7X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,53",
    d_global: "12,7",
    i_global: "0,08",
    t_global: "1,5",
    z_global: "0,13",
  },
  {
    profile_section_id: 5084,
    name: "PIPBG13X1",
    type: "Pipe",
    designation: "PIPBG13X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,38",
    d_global: 13,
    i_global: "0,07",
    t_global: 1,
    z_global: "0,1",
  },
  {
    profile_section_id: 5085,
    name: "PIPBG13X1.2",
    type: "Pipe",
    designation: "PIPBG13X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,44",
    d_global: 13,
    i_global: "0,08",
    t_global: "1,2",
    z_global: "0,12",
  },
  {
    profile_section_id: 5086,
    name: "PIPBG13X1.5",
    type: "Pipe",
    designation: "PIPBG13X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,54",
    d_global: 13,
    i_global: "0,09",
    t_global: "1,5",
    z_global: "0,14",
  },
  {
    profile_section_id: 5087,
    name: "PIPBG13.5X2.35",
    type: "Pipe",
    designation: "PIPBG13.5X2.35",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,82",
    d_global: "13,5",
    i_global: "0,13",
    t_global: "2,35",
    z_global: "0,2",
  },
  {
    profile_section_id: 5088,
    name: "PIPBG14X1",
    type: "Pipe",
    designation: "PIPBG14X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,41",
    d_global: 14,
    i_global: "0,09",
    t_global: 1,
    z_global: "0,12",
  },
  {
    profile_section_id: 5089,
    name: "PIPBG15X1",
    type: "Pipe",
    designation: "PIPBG15X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,44",
    d_global: 15,
    i_global: "0,11",
    t_global: 1,
    z_global: "0,14",
  },
  {
    profile_section_id: 5090,
    name: "PIPBG15X1.2",
    type: "Pipe",
    designation: "PIPBG15X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,52",
    d_global: 15,
    i_global: "0,13",
    t_global: "1,2",
    z_global: "0,17",
  },
  {
    profile_section_id: 5091,
    name: "PIPBG15X1.5",
    type: "Pipe",
    designation: "PIPBG15X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,64",
    d_global: 15,
    i_global: "0,15",
    t_global: "1,5",
    z_global: "0,2",
  },
  {
    profile_section_id: 5092,
    name: "PIPBG16X1",
    type: "Pipe",
    designation: "PIPBG16X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,47",
    d_global: 16,
    i_global: "0,13",
    t_global: 1,
    z_global: "0,17",
  },
  {
    profile_section_id: 5093,
    name: "PIPBG16X1.2",
    type: "Pipe",
    designation: "PIPBG16X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,56",
    d_global: 16,
    i_global: "0,15",
    t_global: "1,2",
    z_global: "0,19",
  },
  {
    profile_section_id: 5094,
    name: "PIPBG16X1.5",
    type: "Pipe",
    designation: "PIPBG16X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,68",
    d_global: 16,
    i_global: "0,18",
    t_global: "1,5",
    z_global: "0,23",
  },
  {
    profile_section_id: 5095,
    name: "PIPBG16X2",
    type: "Pipe",
    designation: "PIPBG16X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,88",
    d_global: 16,
    i_global: "0,22",
    t_global: 2,
    z_global: "0,28",
  },
  {
    profile_section_id: 5096,
    name: "PIPBG17.2X1.8",
    type: "Pipe",
    designation: "PIPBG17.2X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,87",
    d_global: "17,2",
    i_global: "0,26",
    t_global: "1,8",
    z_global: "0,3",
  },
  {
    profile_section_id: 5097,
    name: "PIPBG17.2X2.35",
    type: "Pipe",
    designation: "PIPBG17.2X2.35",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,1",
    d_global: "17,2",
    i_global: "0,31",
    t_global: "2,35",
    z_global: "0,36",
  },
  {
    profile_section_id: 5098,
    name: "PIPBG17.2X2.9",
    type: "Pipe",
    designation: "PIPBG17.2X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,3",
    d_global: "17,2",
    i_global: "0,35",
    t_global: "2,9",
    z_global: "0,4",
  },
  {
    profile_section_id: 5099,
    name: "PIPBG18X1",
    type: "Pipe",
    designation: "PIPBG18X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,53",
    d_global: 18,
    i_global: "0,19",
    t_global: 1,
    z_global: "0,21",
  },
  {
    profile_section_id: 5100,
    name: "PIPBG18X1.5",
    type: "Pipe",
    designation: "PIPBG18X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,78",
    d_global: 18,
    i_global: "0,27",
    t_global: "1,5",
    z_global: "0,3",
  },
  {
    profile_section_id: 5101,
    name: "PIPBG19X1",
    type: "Pipe",
    designation: "PIPBG19X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,57",
    d_global: 19,
    i_global: "0,23",
    t_global: 1,
    z_global: "0,24",
  },
  {
    profile_section_id: 5102,
    name: "PIPBG19X1.2",
    type: "Pipe",
    designation: "PIPBG19X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,67",
    d_global: 19,
    i_global: "0,27",
    t_global: "1,2",
    z_global: "0,28",
  },
  {
    profile_section_id: 5103,
    name: "PIPBG19X1.25",
    type: "Pipe",
    designation: "PIPBG19X1.25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,7",
    d_global: 19,
    i_global: "0,28",
    t_global: "1,25",
    z_global: "0,29",
  },
  {
    profile_section_id: 5104,
    name: "PIPBG19X1.5",
    type: "Pipe",
    designation: "PIPBG19X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,82",
    d_global: 19,
    i_global: "0,32",
    t_global: "1,5",
    z_global: "0,34",
  },
  {
    profile_section_id: 5105,
    name: "PIPBG19X1.8",
    type: "Pipe",
    designation: "PIPBG19X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,97",
    d_global: 19,
    i_global: "0,36",
    t_global: "1,8",
    z_global: "0,38",
  },
  {
    profile_section_id: 5106,
    name: "PIPBG19X2",
    type: "Pipe",
    designation: "PIPBG19X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,07",
    d_global: 19,
    i_global: "0,39",
    t_global: 2,
    z_global: "0,41",
  },
  {
    profile_section_id: 5107,
    name: "PIPBG20X1",
    type: "Pipe",
    designation: "PIPBG20X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,6",
    d_global: 20,
    i_global: "0,27",
    t_global: 1,
    z_global: "0,27",
  },
  {
    profile_section_id: 5108,
    name: "PIPBG20X1.5",
    type: "Pipe",
    designation: "PIPBG20X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,87",
    d_global: 20,
    i_global: "0,38",
    t_global: "1,5",
    z_global: "0,38",
  },
  {
    profile_section_id: 5109,
    name: "PIPBG20X2",
    type: "Pipe",
    designation: "PIPBG20X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,13",
    d_global: 20,
    i_global: "0,46",
    t_global: 2,
    z_global: "0,46",
  },
  {
    profile_section_id: 5110,
    name: "PIPBG21.3X2",
    type: "Pipe",
    designation: "PIPBG21.3X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,21",
    d_global: "21,3",
    i_global: "0,57",
    t_global: 2,
    z_global: "0,54",
  },
  {
    profile_section_id: 5111,
    name: "PIPBG21.3X2.65",
    type: "Pipe",
    designation: "PIPBG21.3X2.65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,55",
    d_global: "21,3",
    i_global: "0,69",
    t_global: "2,65",
    z_global: "0,65",
  },
  {
    profile_section_id: 5112,
    name: "PIPBG21.3X3.25",
    type: "Pipe",
    designation: "PIPBG21.3X3.25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,84",
    d_global: "21,3",
    i_global: "0,78",
    t_global: "3,25",
    z_global: "0,73",
  },
  {
    profile_section_id: 5113,
    name: "PIPBG22X1",
    type: "Pipe",
    designation: "PIPBG22X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,66",
    d_global: 22,
    i_global: "0,36",
    t_global: 1,
    z_global: "0,33",
  },
  {
    profile_section_id: 5114,
    name: "PIPBG22X1.2",
    type: "Pipe",
    designation: "PIPBG22X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,78",
    d_global: 22,
    i_global: "0,42",
    t_global: "1,2",
    z_global: "0,39",
  },
  {
    profile_section_id: 5115,
    name: "PIPBG22X1.5",
    type: "Pipe",
    designation: "PIPBG22X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,97",
    d_global: 22,
    i_global: "0,51",
    t_global: "1,5",
    z_global: "0,46",
  },
  {
    profile_section_id: 5116,
    name: "PIPBG22X1.8",
    type: "Pipe",
    designation: "PIPBG22X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,14",
    d_global: 22,
    i_global: "0,59",
    t_global: "1,8",
    z_global: "0,53",
  },
  {
    profile_section_id: 5117,
    name: "PIPBG22X2",
    type: "Pipe",
    designation: "PIPBG22X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,26",
    d_global: 22,
    i_global: "0,64",
    t_global: 2,
    z_global: "0,58",
  },
  {
    profile_section_id: 5118,
    name: "PIPBG22.2X1",
    type: "Pipe",
    designation: "PIPBG22.2X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,67",
    d_global: "22,2",
    i_global: "0,38",
    t_global: 1,
    z_global: "0,34",
  },
  {
    profile_section_id: 5119,
    name: "PIPBG22.2X1.2",
    type: "Pipe",
    designation: "PIPBG22.2X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,79",
    d_global: "22,2",
    i_global: "0,44",
    t_global: "1,2",
    z_global: "0,39",
  },
  {
    profile_section_id: 5120,
    name: "PIPBG22.2X1.5",
    type: "Pipe",
    designation: "PIPBG22.2X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,98",
    d_global: "22,2",
    i_global: "0,53",
    t_global: "1,5",
    z_global: "0,47",
  },
  {
    profile_section_id: 5121,
    name: "PIPBG22.2X2",
    type: "Pipe",
    designation: "PIPBG22.2X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,27",
    d_global: "22,2",
    i_global: "0,65",
    t_global: 2,
    z_global: "0,59",
  },
  {
    profile_section_id: 5122,
    name: "PIPBG25X1",
    type: "Pipe",
    designation: "PIPBG25X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,75",
    d_global: 25,
    i_global: "0,54",
    t_global: 1,
    z_global: "0,43",
  },
  {
    profile_section_id: 5123,
    name: "PIPBG25X1.2",
    type: "Pipe",
    designation: "PIPBG25X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,9",
    d_global: 25,
    i_global: "0,64",
    t_global: "1,2",
    z_global: "0,51",
  },
  {
    profile_section_id: 5124,
    name: "PIPBG25X1.5",
    type: "Pipe",
    designation: "PIPBG25X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,11",
    d_global: 25,
    i_global: "0,77",
    t_global: "1,5",
    z_global: "0,61",
  },
  {
    profile_section_id: 5125,
    name: "PIPBG25X1.8",
    type: "Pipe",
    designation: "PIPBG25X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,31",
    d_global: 25,
    i_global: "0,89",
    t_global: "1,8",
    z_global: "0,71",
  },
  {
    profile_section_id: 5126,
    name: "PIPBG25X2",
    type: "Pipe",
    designation: "PIPBG25X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,45",
    d_global: 25,
    i_global: "0,96",
    t_global: 2,
    z_global: "0,77",
  },
  {
    profile_section_id: 5127,
    name: "PIPBG25.4X1",
    type: "Pipe",
    designation: "PIPBG25.4X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,77",
    d_global: "25,4",
    i_global: "0,57",
    t_global: 1,
    z_global: "0,45",
  },
  {
    profile_section_id: 5128,
    name: "PIPBG25.4X1.2",
    type: "Pipe",
    designation: "PIPBG25.4X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,91",
    d_global: "25,4",
    i_global: "0,67",
    t_global: "1,2",
    z_global: "0,53",
  },
  {
    profile_section_id: 5129,
    name: "PIPBG25.4X1.5",
    type: "Pipe",
    designation: "PIPBG25.4X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,13",
    d_global: "25,4",
    i_global: "0,81",
    t_global: "1,5",
    z_global: "0,64",
  },
  {
    profile_section_id: 5130,
    name: "PIPBG25.4X1.8",
    type: "Pipe",
    designation: "PIPBG25.4X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,33",
    d_global: "25,4",
    i_global: "0,94",
    t_global: "1,8",
    z_global: "0,74",
  },
  {
    profile_section_id: 5131,
    name: "PIPBG25.4X2",
    type: "Pipe",
    designation: "PIPBG25.4X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,47",
    d_global: "25,4",
    i_global: "1,01",
    t_global: 2,
    z_global: "0,8",
  },
  {
    profile_section_id: 5132,
    name: "PIPBG26X1",
    type: "Pipe",
    designation: "PIPBG26X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,79",
    d_global: 26,
    i_global: "0,61",
    t_global: 1,
    z_global: "0,47",
  },
  {
    profile_section_id: 5133,
    name: "PIPBG26X1.2",
    type: "Pipe",
    designation: "PIPBG26X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,93",
    d_global: 26,
    i_global: "0,72",
    t_global: "1,2",
    z_global: "0,55",
  },
  {
    profile_section_id: 5134,
    name: "PIPBG26X1.5",
    type: "Pipe",
    designation: "PIPBG26X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,15",
    d_global: 26,
    i_global: "0,87",
    t_global: "1,5",
    z_global: "0,67",
  },
  {
    profile_section_id: 5135,
    name: "PIPBG26X2",
    type: "Pipe",
    designation: "PIPBG26X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,51",
    d_global: 26,
    i_global: "1,09",
    t_global: 2,
    z_global: "0,84",
  },
  {
    profile_section_id: 5136,
    name: "PIPBG26.9X2",
    type: "Pipe",
    designation: "PIPBG26.9X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,56",
    d_global: "26,9",
    i_global: "1,22",
    t_global: 2,
    z_global: "0,91",
  },
  {
    profile_section_id: 5137,
    name: "PIPBG26.9X2.35",
    type: "Pipe",
    designation: "PIPBG26.9X2.35",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,81",
    d_global: "26,9",
    i_global: "1,38",
    t_global: "2,35",
    z_global: "1,02",
  },
  {
    profile_section_id: 5138,
    name: "PIPBG26.9X2.65",
    type: "Pipe",
    designation: "PIPBG26.9X2.65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,02",
    d_global: "26,9",
    i_global: "1,5",
    t_global: "2,65",
    z_global: "1,12",
  },
  {
    profile_section_id: 5139,
    name: "PIPBG26.9X3.25",
    type: "Pipe",
    designation: "PIPBG26.9X3.25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,41",
    d_global: "26,9",
    i_global: "1,72",
    t_global: "3,25",
    z_global: "1,28",
  },
  {
    profile_section_id: 5140,
    name: "PIPBG28X1.2",
    type: "Pipe",
    designation: "PIPBG28X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,01",
    d_global: 28,
    i_global: "0,91",
    t_global: "1,2",
    z_global: "0,65",
  },
  {
    profile_section_id: 5141,
    name: "PIPBG28X1.5",
    type: "Pipe",
    designation: "PIPBG28X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,25",
    d_global: 28,
    i_global: "1,1",
    t_global: "1,5",
    z_global: "0,79",
  },
  {
    profile_section_id: 5142,
    name: "PIPBG28.6X1.2",
    type: "Pipe",
    designation: "PIPBG28.6X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,03",
    d_global: "28,6",
    i_global: "0,97",
    t_global: "1,2",
    z_global: "0,68",
  },
  {
    profile_section_id: 5143,
    name: "PIPBG28.6X1.5",
    type: "Pipe",
    designation: "PIPBG28.6X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,28",
    d_global: "28,6",
    i_global: "1,18",
    t_global: "1,5",
    z_global: "0,82",
  },
  {
    profile_section_id: 5144,
    name: "PIPBG28.6X2",
    type: "Pipe",
    designation: "PIPBG28.6X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,67",
    d_global: "28,6",
    i_global: "1,49",
    t_global: 2,
    z_global: "1,04",
  },
  {
    profile_section_id: 5145,
    name: "PIPBG30X1",
    type: "Pipe",
    designation: "PIPBG30X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,91",
    d_global: 30,
    i_global: "0,96",
    t_global: 1,
    z_global: "0,64",
  },
  {
    profile_section_id: 5146,
    name: "PIPBG30X1.2",
    type: "Pipe",
    designation: "PIPBG30X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,09",
    d_global: 30,
    i_global: "1,13",
    t_global: "1,2",
    z_global: "0,75",
  },
  {
    profile_section_id: 5147,
    name: "PIPBG30X1.5",
    type: "Pipe",
    designation: "PIPBG30X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,34",
    d_global: 30,
    i_global: "1,37",
    t_global: "1,5",
    z_global: "0,91",
  },
  {
    profile_section_id: 5148,
    name: "PIPBG30X2",
    type: "Pipe",
    designation: "PIPBG30X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,76",
    d_global: 30,
    i_global: "1,73",
    t_global: 2,
    z_global: "1,16",
  },
  {
    profile_section_id: 5149,
    name: "PIPBG31.7X1",
    type: "Pipe",
    designation: "PIPBG31.7X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,96",
    d_global: "31,7",
    i_global: "1,14",
    t_global: 1,
    z_global: "0,72",
  },
  {
    profile_section_id: 5150,
    name: "PIPBG31.7X1.5",
    type: "Pipe",
    designation: "PIPBG31.7X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,42",
    d_global: "31,7",
    i_global: "1,63",
    t_global: "1,5",
    z_global: "1,03",
  },
  {
    profile_section_id: 5151,
    name: "PIPBG31.8X2",
    type: "Pipe",
    designation: "PIPBG31.8X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,87",
    d_global: "31,8",
    i_global: "2,09",
    t_global: 2,
    z_global: "1,31",
  },
  {
    profile_section_id: 5152,
    name: "PIPBG31.8X2.6",
    type: "Pipe",
    designation: "PIPBG31.8X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,39",
    d_global: "31,8",
    i_global: "2,56",
    t_global: "2,6",
    z_global: "1,61",
  },
  {
    profile_section_id: 5153,
    name: "PIPBG32X1",
    type: "Pipe",
    designation: "PIPBG32X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,97",
    d_global: 32,
    i_global: "1,17",
    t_global: 1,
    z_global: "0,73",
  },
  {
    profile_section_id: 5154,
    name: "PIPBG32X1.2",
    type: "Pipe",
    designation: "PIPBG32X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,16",
    d_global: 32,
    i_global: "1,38",
    t_global: "1,2",
    z_global: "0,86",
  },
  {
    profile_section_id: 5155,
    name: "PIPBG32X1.5",
    type: "Pipe",
    designation: "PIPBG32X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,44",
    d_global: 32,
    i_global: "1,68",
    t_global: "1,5",
    z_global: "1,05",
  },
  {
    profile_section_id: 5156,
    name: "PIPBG32X1.8",
    type: "Pipe",
    designation: "PIPBG32X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,71",
    d_global: 32,
    i_global: "1,95",
    t_global: "1,8",
    z_global: "1,22",
  },
  {
    profile_section_id: 5157,
    name: "PIPBG32X2",
    type: "Pipe",
    designation: "PIPBG32X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,88",
    d_global: 32,
    i_global: "2,13",
    t_global: 2,
    z_global: "1,33",
  },
  {
    profile_section_id: 5158,
    name: "PIPBG32X2.5",
    type: "Pipe",
    designation: "PIPBG32X2.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,32",
    d_global: 32,
    i_global: "2,54",
    t_global: "2,5",
    z_global: "1,59",
  },
  {
    profile_section_id: 5159,
    name: "PIPBG33.7X2.55",
    type: "Pipe",
    designation: "PIPBG33.7X2.55",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,5",
    d_global: "33,7",
    i_global: "3,05",
    t_global: "2,55",
    z_global: "1,81",
  },
  {
    profile_section_id: 5160,
    name: "PIPBG33.7X2.65",
    type: "Pipe",
    designation: "PIPBG33.7X2.65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,58",
    d_global: "33,7",
    i_global: "3,14",
    t_global: "2,65",
    z_global: "1,86",
  },
  {
    profile_section_id: 5161,
    name: "PIPBG33.7X3.25",
    type: "Pipe",
    designation: "PIPBG33.7X3.25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,11",
    d_global: "33,7",
    i_global: "3,64",
    t_global: "3,25",
    z_global: "2,16",
  },
  {
    profile_section_id: 5162,
    name: "PIPBG33.7X4.05",
    type: "Pipe",
    designation: "PIPBG33.7X4.05",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,77",
    d_global: "33,7",
    i_global: "4,22",
    t_global: "4,05",
    z_global: "2,51",
  },
  {
    profile_section_id: 5163,
    name: "PIPBG35X1",
    type: "Pipe",
    designation: "PIPBG35X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,07",
    d_global: 35,
    i_global: "1,54",
    t_global: 1,
    z_global: "0,88",
  },
  {
    profile_section_id: 5164,
    name: "PIPBG35X1.2",
    type: "Pipe",
    designation: "PIPBG35X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,27",
    d_global: 35,
    i_global: "1,82",
    t_global: "1,2",
    z_global: "1,04",
  },
  {
    profile_section_id: 5165,
    name: "PIPBG35X1.5",
    type: "Pipe",
    designation: "PIPBG35X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,58",
    d_global: 35,
    i_global: "2,22",
    t_global: "1,5",
    z_global: "1,27",
  },
  {
    profile_section_id: 5166,
    name: "PIPBG35X2",
    type: "Pipe",
    designation: "PIPBG35X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,07",
    d_global: 35,
    i_global: "2,83",
    t_global: 2,
    z_global: "1,62",
  },
  {
    profile_section_id: 5167,
    name: "PIPBG38X1.2",
    type: "Pipe",
    designation: "PIPBG38X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,39",
    d_global: 38,
    i_global: "2,35",
    t_global: "1,2",
    z_global: "1,24",
  },
  {
    profile_section_id: 5168,
    name: "PIPBG38X1.5",
    type: "Pipe",
    designation: "PIPBG38X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,72",
    d_global: 38,
    i_global: "2,87",
    t_global: "1,5",
    z_global: "1,51",
  },
  {
    profile_section_id: 5169,
    name: "PIPBG38X2",
    type: "Pipe",
    designation: "PIPBG38X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,26",
    d_global: 38,
    i_global: "3,68",
    t_global: 2,
    z_global: "1,94",
  },
  {
    profile_section_id: 5170,
    name: "PIPBG38X2.3",
    type: "Pipe",
    designation: "PIPBG38X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,58",
    d_global: 38,
    i_global: "4,13",
    t_global: "2,3",
    z_global: "2,17",
  },
  {
    profile_section_id: 5171,
    name: "PIPBG38X2.5",
    type: "Pipe",
    designation: "PIPBG38X2.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,79",
    d_global: 38,
    i_global: "4,41",
    t_global: "2,5",
    z_global: "2,32",
  },
  {
    profile_section_id: 5172,
    name: "PIPBG38X2.6",
    type: "Pipe",
    designation: "PIPBG38X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,89",
    d_global: 38,
    i_global: "4,55",
    t_global: "2,6",
    z_global: "2,4",
  },
  {
    profile_section_id: 5173,
    name: "PIPBG38X4",
    type: "Pipe",
    designation: "PIPBG38X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,27",
    d_global: 38,
    i_global: "6,26",
    t_global: 4,
    z_global: "3,29",
  },
  {
    profile_section_id: 5174,
    name: "PIPBG38X5",
    type: "Pipe",
    designation: "PIPBG38X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,18",
    d_global: 38,
    i_global: "7,22",
    t_global: 5,
    z_global: "3,8",
  },
  {
    profile_section_id: 5175,
    name: "PIPBG40X1",
    type: "Pipe",
    designation: "PIPBG40X1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,23",
    d_global: 40,
    i_global: "2,33",
    t_global: 1,
    z_global: "1,17",
  },
  {
    profile_section_id: 5176,
    name: "PIPBG40X2",
    type: "Pipe",
    designation: "PIPBG40X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,39",
    d_global: 40,
    i_global: "4,32",
    t_global: 2,
    z_global: "2,16",
  },
  {
    profile_section_id: 5177,
    name: "PIPBG41.5X1.2",
    type: "Pipe",
    designation: "PIPBG41.5X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,52",
    d_global: "41,5",
    i_global: "3,09",
    t_global: "1,2",
    z_global: "1,49",
  },
  {
    profile_section_id: 5178,
    name: "PIPBG41.5X1.5",
    type: "Pipe",
    designation: "PIPBG41.5X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,88",
    d_global: "41,5",
    i_global: "3,77",
    t_global: "1,5",
    z_global: "1,82",
  },
  {
    profile_section_id: 5179,
    name: "PIPBG41.5X2",
    type: "Pipe",
    designation: "PIPBG41.5X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,48",
    d_global: "41,5",
    i_global: "4,85",
    t_global: 2,
    z_global: "2,34",
  },
  {
    profile_section_id: 5180,
    name: "PIPBG42.4X2.2",
    type: "Pipe",
    designation: "PIPBG42.4X2.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,78",
    d_global: "42,4",
    i_global: "5,63",
    t_global: "2,2",
    z_global: "2,65",
  },
  {
    profile_section_id: 5181,
    name: "PIPBG42.4X2.6",
    type: "Pipe",
    designation: "PIPBG42.4X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,25",
    d_global: "42,4",
    i_global: "6,46",
    t_global: "2,6",
    z_global: "3,05",
  },
  {
    profile_section_id: 5182,
    name: "PIPBG42.4X3.2",
    type: "Pipe",
    designation: "PIPBG42.4X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,94",
    d_global: "42,4",
    i_global: "7,62",
    t_global: "3,2",
    z_global: "3,59",
  },
  {
    profile_section_id: 5183,
    name: "PIPBG42.4X4.05",
    type: "Pipe",
    designation: "PIPBG42.4X4.05",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,88",
    d_global: "42,4",
    i_global: "9,07",
    t_global: "4,05",
    z_global: "4,28",
  },
  {
    profile_section_id: 5184,
    name: "PIPBG42.4X5",
    type: "Pipe",
    designation: "PIPBG42.4X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,87",
    d_global: "42,4",
    i_global: "10,46",
    t_global: 5,
    z_global: "4,93",
  },
  {
    profile_section_id: 5185,
    name: "PIPBG44.5X1.2",
    type: "Pipe",
    designation: "PIPBG44.5X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,63",
    d_global: "44,5",
    i_global: "3,83",
    t_global: "1,2",
    z_global: "1,72",
  },
  {
    profile_section_id: 5186,
    name: "PIPBG44.5X1.5",
    type: "Pipe",
    designation: "PIPBG44.5X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,03",
    d_global: "44,5",
    i_global: "4,69",
    t_global: "1,5",
    z_global: "2,11",
  },
  {
    profile_section_id: 5187,
    name: "PIPBG44.5X2",
    type: "Pipe",
    designation: "PIPBG44.5X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,67",
    d_global: "44,5",
    i_global: "6,04",
    t_global: 2,
    z_global: "2,72",
  },
  {
    profile_section_id: 5188,
    name: "PIPBG44.5X2.25",
    type: "Pipe",
    designation: "PIPBG44.5X2.25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,99",
    d_global: "44,5",
    i_global: "6,68",
    t_global: "2,25",
    z_global: 3,
  },
  {
    profile_section_id: 5189,
    name: "PIPBG44.5X2.3",
    type: "Pipe",
    designation: "PIPBG44.5X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,05",
    d_global: "44,5",
    i_global: "6,81",
    t_global: "2,3",
    z_global: "3,06",
  },
  {
    profile_section_id: 5190,
    name: "PIPBG44.5X2.6",
    type: "Pipe",
    designation: "PIPBG44.5X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,42",
    d_global: "44,5",
    i_global: "7,54",
    t_global: "2,6",
    z_global: "3,39",
  },
  {
    profile_section_id: 5191,
    name: "PIPBG44.5X4",
    type: "Pipe",
    designation: "PIPBG44.5X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,09",
    d_global: "44,5",
    i_global: "10,54",
    t_global: 4,
    z_global: "4,74",
  },
  {
    profile_section_id: 5192,
    name: "PIPBG44.5X5",
    type: "Pipe",
    designation: "PIPBG44.5X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,2",
    d_global: "44,5",
    i_global: "12,29",
    t_global: 5,
    z_global: "5,53",
  },
  {
    profile_section_id: 5193,
    name: "PIPBG47.5X1.2",
    type: "Pipe",
    designation: "PIPBG47.5X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,75",
    d_global: "47,5",
    i_global: "4,68",
    t_global: "1,2",
    z_global: "1,97",
  },
  {
    profile_section_id: 5194,
    name: "PIPBG47.5X1.5",
    type: "Pipe",
    designation: "PIPBG47.5X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,17",
    d_global: "47,5",
    i_global: "5,74",
    t_global: "1,5",
    z_global: "2,42",
  },
  {
    profile_section_id: 5195,
    name: "PIPBG47.5X2",
    type: "Pipe",
    designation: "PIPBG47.5X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,86",
    d_global: "47,5",
    i_global: "7,41",
    t_global: 2,
    z_global: "3,12",
  },
  {
    profile_section_id: 5196,
    name: "PIPBG48.3X2.3",
    type: "Pipe",
    designation: "PIPBG48.3X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,32",
    d_global: "48,3",
    i_global: "8,81",
    t_global: "2,3",
    z_global: "3,65",
  },
  {
    profile_section_id: 5197,
    name: "PIPBG48.3X2.5",
    type: "Pipe",
    designation: "PIPBG48.3X2.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,6",
    d_global: "48,3",
    i_global: "9,46",
    t_global: "2,5",
    z_global: "3,92",
  },
  {
    profile_section_id: 5198,
    name: "PIPBG48.3X2.9",
    type: "Pipe",
    designation: "PIPBG48.3X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,14",
    d_global: "48,3",
    i_global: "10,7",
    t_global: "2,9",
    z_global: "4,43",
  },
  {
    profile_section_id: 5199,
    name: "PIPBG48.3X3.25",
    type: "Pipe",
    designation: "PIPBG48.3X3.25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,6",
    d_global: "48,3",
    i_global: "11,73",
    t_global: "3,25",
    z_global: "4,86",
  },
  {
    profile_section_id: 5200,
    name: "PIPBG48.3X4.05",
    type: "Pipe",
    designation: "PIPBG48.3X4.05",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,63",
    d_global: "48,3",
    i_global: "13,9",
    t_global: "4,05",
    z_global: "5,75",
  },
  {
    profile_section_id: 5201,
    name: "PIPBG48.3X5",
    type: "Pipe",
    designation: "PIPBG48.3X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,8",
    d_global: "48,3",
    i_global: "16,15",
    t_global: 5,
    z_global: "6,69",
  },
  {
    profile_section_id: 5202,
    name: "PIPBG48.3X6.3",
    type: "Pipe",
    designation: "PIPBG48.3X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,31",
    d_global: "48,3",
    i_global: "18,74",
    t_global: "6,3",
    z_global: "7,76",
  },
  {
    profile_section_id: 5203,
    name: "PIPBG50X1.5",
    type: "Pipe",
    designation: "PIPBG50X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,29",
    d_global: 50,
    i_global: "6,73",
    t_global: "1,5",
    z_global: "2,69",
  },
  {
    profile_section_id: 5204,
    name: "PIPBG50.8X1.2",
    type: "Pipe",
    designation: "PIPBG50.8X1.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,87",
    d_global: "50,8",
    i_global: "5,75",
    t_global: "1,2",
    z_global: "2,27",
  },
  {
    profile_section_id: 5205,
    name: "PIPBG50.8X1.5",
    type: "Pipe",
    designation: "PIPBG50.8X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,32",
    d_global: "50,8",
    i_global: "7,07",
    t_global: "1,5",
    z_global: "2,78",
  },
  {
    profile_section_id: 5206,
    name: "PIPBG50.8X1.8",
    type: "Pipe",
    designation: "PIPBG50.8X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,77",
    d_global: "50,8",
    i_global: "8,33",
    t_global: "1,8",
    z_global: "3,28",
  },
  {
    profile_section_id: 5207,
    name: "PIPBG51X2",
    type: "Pipe",
    designation: "PIPBG51X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,08",
    d_global: 51,
    i_global: "9,26",
    t_global: 2,
    z_global: "3,63",
  },
  {
    profile_section_id: 5208,
    name: "PIPBG51X2.25",
    type: "Pipe",
    designation: "PIPBG51X2.25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,45",
    d_global: 51,
    i_global: "10,26",
    t_global: "2,25",
    z_global: "4,02",
  },
  {
    profile_section_id: 5209,
    name: "PIPBG51X2.6",
    type: "Pipe",
    designation: "PIPBG51X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,95",
    d_global: 51,
    i_global: "11,61",
    t_global: "2,6",
    z_global: "4,55",
  },
  {
    profile_section_id: 5210,
    name: "PIPBG51X4",
    type: "Pipe",
    designation: "PIPBG51X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,91",
    d_global: 51,
    i_global: "16,43",
    t_global: 4,
    z_global: "6,44",
  },
  {
    profile_section_id: 5211,
    name: "PIPBG51X4.75",
    type: "Pipe",
    designation: "PIPBG51X4.75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,9",
    d_global: 51,
    i_global: "18,65",
    t_global: "4,75",
    z_global: "7,31",
  },
  {
    profile_section_id: 5212,
    name: "PIPBG51X5",
    type: "Pipe",
    designation: "PIPBG51X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,23",
    d_global: 51,
    i_global: "19,34",
    t_global: 5,
    z_global: "7,58",
  },
  {
    profile_section_id: 5213,
    name: "PIPBG54X1.5",
    type: "Pipe",
    designation: "PIPBG54X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,47",
    d_global: 54,
    i_global: "8,53",
    t_global: "1,5",
    z_global: "3,16",
  },
  {
    profile_section_id: 5214,
    name: "PIPBG54X1.8",
    type: "Pipe",
    designation: "PIPBG54X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,95",
    d_global: 54,
    i_global: "10,07",
    t_global: "1,8",
    z_global: "3,73",
  },
  {
    profile_section_id: 5215,
    name: "PIPBG54X2",
    type: "Pipe",
    designation: "PIPBG54X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,27",
    d_global: 54,
    i_global: "11,06",
    t_global: 2,
    z_global: "4,1",
  },
  {
    profile_section_id: 5216,
    name: "PIPBG57X1.5",
    type: "Pipe",
    designation: "PIPBG57X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,62",
    d_global: 57,
    i_global: "10,08",
    t_global: "1,5",
    z_global: "3,54",
  },
  {
    profile_section_id: 5217,
    name: "PIPBG57X2",
    type: "Pipe",
    designation: "PIPBG57X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,46",
    d_global: 57,
    i_global: "13,08",
    t_global: 2,
    z_global: "4,59",
  },
  {
    profile_section_id: 5218,
    name: "PIPBG57X2.25",
    type: "Pipe",
    designation: "PIPBG57X2.25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,87",
    d_global: 57,
    i_global: "14,53",
    t_global: "2,25",
    z_global: "5,1",
  },
  {
    profile_section_id: 5219,
    name: "PIPBG57X2.6",
    type: "Pipe",
    designation: "PIPBG57X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,44",
    d_global: 57,
    i_global: "16,48",
    t_global: "2,6",
    z_global: "5,78",
  },
  {
    profile_section_id: 5220,
    name: "PIPBG57X2.9",
    type: "Pipe",
    designation: "PIPBG57X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,93",
    d_global: 57,
    i_global: "18,08",
    t_global: "2,9",
    z_global: "6,34",
  },
  {
    profile_section_id: 5221,
    name: "PIPBG57X4",
    type: "Pipe",
    designation: "PIPBG57X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,66",
    d_global: 57,
    i_global: "23,52",
    t_global: 4,
    z_global: "8,25",
  },
  {
    profile_section_id: 5222,
    name: "PIPBG57X5",
    type: "Pipe",
    designation: "PIPBG57X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,17",
    d_global: 57,
    i_global: "27,86",
    t_global: 5,
    z_global: "9,78",
  },
  {
    profile_section_id: 5223,
    name: "PIPBG60.3X1.5",
    type: "Pipe",
    designation: "PIPBG60.3X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,77",
    d_global: "60,3",
    i_global: "11,98",
    t_global: "1,5",
    z_global: "3,97",
  },
  {
    profile_section_id: 5224,
    name: "PIPBG60.3X2",
    type: "Pipe",
    designation: "PIPBG60.3X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,66",
    d_global: "60,3",
    i_global: "15,58",
    t_global: 2,
    z_global: "5,17",
  },
  {
    profile_section_id: 5225,
    name: "PIPBG60.3X2.25",
    type: "Pipe",
    designation: "PIPBG60.3X2.25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,1",
    d_global: "60,3",
    i_global: "17,31",
    t_global: "2,25",
    z_global: "5,74",
  },
  {
    profile_section_id: 5226,
    name: "PIPBG60.3X2.5",
    type: "Pipe",
    designation: "PIPBG60.3X2.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,54",
    d_global: "60,3",
    i_global: "18,99",
    t_global: "2,5",
    z_global: "6,3",
  },
  {
    profile_section_id: 5227,
    name: "PIPBG60.3X2.9",
    type: "Pipe",
    designation: "PIPBG60.3X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,23",
    d_global: "60,3",
    i_global: "21,59",
    t_global: "2,9",
    z_global: "7,16",
  },
  {
    profile_section_id: 5228,
    name: "PIPBG60.3X3.2",
    type: "Pipe",
    designation: "PIPBG60.3X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,74",
    d_global: "60,3",
    i_global: "23,47",
    t_global: "3,2",
    z_global: "7,78",
  },
  {
    profile_section_id: 5229,
    name: "PIPBG60.3X3.65",
    type: "Pipe",
    designation: "PIPBG60.3X3.65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,5",
    d_global: "60,3",
    i_global: "26,17",
    t_global: "3,65",
    z_global: "8,68",
  },
  {
    profile_section_id: 5230,
    name: "PIPBG60.3X3.9",
    type: "Pipe",
    designation: "PIPBG60.3X3.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,91",
    d_global: "60,3",
    i_global: "27,61",
    t_global: "3,9",
    z_global: "9,16",
  },
  {
    profile_section_id: 5231,
    name: "PIPBG60.3X4",
    type: "Pipe",
    designation: "PIPBG60.3X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,07",
    d_global: "60,3",
    i_global: "28,17",
    t_global: 4,
    z_global: "9,34",
  },
  {
    profile_section_id: 5232,
    name: "PIPBG60.3X4.5",
    type: "Pipe",
    designation: "PIPBG60.3X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,89",
    d_global: "60,3",
    i_global: "30,9",
    t_global: "4,5",
    z_global: "10,25",
  },
  {
    profile_section_id: 5233,
    name: "PIPBG60.3X5",
    type: "Pipe",
    designation: "PIPBG60.3X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,69",
    d_global: "60,3",
    i_global: "33,48",
    t_global: 5,
    z_global: "11,1",
  },
  {
    profile_section_id: 5234,
    name: "PIPBG60.3X6.3",
    type: "Pipe",
    designation: "PIPBG60.3X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,69",
    d_global: "60,3",
    i_global: "39,49",
    t_global: "6,3",
    z_global: "13,1",
  },
  {
    profile_section_id: 5235,
    name: "PIPBG63.5X1.5",
    type: "Pipe",
    designation: "PIPBG63.5X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,92",
    d_global: "63,5",
    i_global: "14,05",
    t_global: "1,5",
    z_global: "4,42",
  },
  {
    profile_section_id: 5236,
    name: "PIPBG63.5X2",
    type: "Pipe",
    designation: "PIPBG63.5X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,86",
    d_global: "63,5",
    i_global: "18,29",
    t_global: 2,
    z_global: "5,76",
  },
  {
    profile_section_id: 5237,
    name: "PIPBG63.5X2.25",
    type: "Pipe",
    designation: "PIPBG63.5X2.25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,33",
    d_global: "63,5",
    i_global: "20,33",
    t_global: "2,25",
    z_global: "6,4",
  },
  {
    profile_section_id: 5238,
    name: "PIPBG63.5X2.9",
    type: "Pipe",
    designation: "PIPBG63.5X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,52",
    d_global: "63,5",
    i_global: "25,4",
    t_global: "2,9",
    z_global: 8,
  },
  {
    profile_section_id: 5239,
    name: "PIPBG63.5X4",
    type: "Pipe",
    designation: "PIPBG63.5X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,48",
    d_global: "63,5",
    i_global: "33,24",
    t_global: 4,
    z_global: "10,47",
  },
  {
    profile_section_id: 5240,
    name: "PIPBG63.5X5",
    type: "Pipe",
    designation: "PIPBG63.5X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,19",
    d_global: "63,5",
    i_global: "39,6",
    t_global: 5,
    z_global: "12,47",
  },
  {
    profile_section_id: 5241,
    name: "PIPBG70X2",
    type: "Pipe",
    designation: "PIPBG70X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,27",
    d_global: 70,
    i_global: "24,72",
    t_global: 2,
    z_global: "7,06",
  },
  {
    profile_section_id: 5242,
    name: "PIPBG70X2.5",
    type: "Pipe",
    designation: "PIPBG70X2.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,3",
    d_global: 70,
    i_global: "30,23",
    t_global: "2,5",
    z_global: "8,64",
  },
  {
    profile_section_id: 5243,
    name: "PIPBG70X2.9",
    type: "Pipe",
    designation: "PIPBG70X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,11",
    d_global: 70,
    i_global: "34,47",
    t_global: "2,9",
    z_global: "9,85",
  },
  {
    profile_section_id: 5244,
    name: "PIPBG70X4",
    type: "Pipe",
    designation: "PIPBG70X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,29",
    d_global: 70,
    i_global: "45,33",
    t_global: 4,
    z_global: "12,95",
  },
  {
    profile_section_id: 5245,
    name: "PIPBG70X5",
    type: "Pipe",
    designation: "PIPBG70X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,21",
    d_global: 70,
    i_global: "54,24",
    t_global: 5,
    z_global: "15,5",
  },
  {
    profile_section_id: 5246,
    name: "PIPBG70X6.3",
    type: "Pipe",
    designation: "PIPBG70X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,61",
    d_global: 70,
    i_global: "64,57",
    t_global: "6,3",
    z_global: "18,45",
  },
  {
    profile_section_id: 5247,
    name: "PIPBG76X1.5",
    type: "Pipe",
    designation: "PIPBG76X1.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,51",
    d_global: 76,
    i_global: "24,37",
    t_global: "1,5",
    z_global: "6,41",
  },
  {
    profile_section_id: 5248,
    name: "PIPBG76X1.8",
    type: "Pipe",
    designation: "PIPBG76X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,2",
    d_global: 76,
    i_global: "28,89",
    t_global: "1,8",
    z_global: "7,6",
  },
  {
    profile_section_id: 5249,
    name: "PIPBG76X2",
    type: "Pipe",
    designation: "PIPBG76X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,65",
    d_global: 76,
    i_global: "31,85",
    t_global: 2,
    z_global: "8,38",
  },
  {
    profile_section_id: 5250,
    name: "PIPBG76.1X2",
    type: "Pipe",
    designation: "PIPBG76.1X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,66",
    d_global: "76,1",
    i_global: "31,98",
    t_global: 2,
    z_global: "8,4",
  },
  {
    profile_section_id: 5251,
    name: "PIPBG76.1X2.5",
    type: "Pipe",
    designation: "PIPBG76.1X2.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,78",
    d_global: "76,1",
    i_global: "39,19",
    t_global: "2,5",
    z_global: "10,3",
  },
  {
    profile_section_id: 5252,
    name: "PIPBG76.1X2.9",
    type: "Pipe",
    designation: "PIPBG76.1X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,67",
    d_global: "76,1",
    i_global: "44,74",
    t_global: "2,9",
    z_global: "11,76",
  },
  {
    profile_section_id: 5253,
    name: "PIPBG76.1X3.25",
    type: "Pipe",
    designation: "PIPBG76.1X3.25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,44",
    d_global: "76,1",
    i_global: "49,44",
    t_global: "3,25",
    z_global: "12,99",
  },
  {
    profile_section_id: 5254,
    name: "PIPBG76.1X3.65",
    type: "Pipe",
    designation: "PIPBG76.1X3.65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,31",
    d_global: "76,1",
    i_global: "54,65",
    t_global: "3,65",
    z_global: "14,36",
  },
  {
    profile_section_id: 5255,
    name: "PIPBG76.1X4",
    type: "Pipe",
    designation: "PIPBG76.1X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,06",
    d_global: "76,1",
    i_global: "59,05",
    t_global: 4,
    z_global: "15,52",
  },
  {
    profile_section_id: 5256,
    name: "PIPBG76.1X4.5",
    type: "Pipe",
    designation: "PIPBG76.1X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,12",
    d_global: "76,1",
    i_global: "65,12",
    t_global: "4,5",
    z_global: "17,11",
  },
  {
    profile_section_id: 5257,
    name: "PIPBG76.1X5",
    type: "Pipe",
    designation: "PIPBG76.1X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,17",
    d_global: "76,1",
    i_global: "70,92",
    t_global: 5,
    z_global: "18,64",
  },
  {
    profile_section_id: 5258,
    name: "PIPBG76.1X6.3",
    type: "Pipe",
    designation: "PIPBG76.1X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "13,81",
    d_global: "76,1",
    i_global: "84,82",
    t_global: "6,3",
    z_global: "22,29",
  },
  {
    profile_section_id: 5259,
    name: "PIPBG76.1X7.1",
    type: "Pipe",
    designation: "PIPBG76.1X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,39",
    d_global: "76,1",
    i_global: "92,56",
    t_global: "7,1",
    z_global: "24,33",
  },
  {
    profile_section_id: 5260,
    name: "PIPBG82.5X2.5",
    type: "Pipe",
    designation: "PIPBG82.5X2.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,28",
    d_global: "82,5",
    i_global: "50,31",
    t_global: "2,5",
    z_global: "12,2",
  },
  {
    profile_section_id: 5261,
    name: "PIPBG82.5X3.2",
    type: "Pipe",
    designation: "PIPBG82.5X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,97",
    d_global: "82,5",
    i_global: "62,77",
    t_global: "3,2",
    z_global: "15,22",
  },
  {
    profile_section_id: 5262,
    name: "PIPBG82.5X5",
    type: "Pipe",
    designation: "PIPBG82.5X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,17",
    d_global: "82,5",
    i_global: "91,78",
    t_global: 5,
    z_global: "22,25",
  },
  {
    profile_section_id: 5263,
    name: "PIPBG82.5X6.3",
    type: "Pipe",
    designation: "PIPBG82.5X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,08",
    d_global: "82,5",
    i_global: "110,21",
    t_global: "6,3",
    z_global: "26,72",
  },
  {
    profile_section_id: 5264,
    name: "PIPBG83X2",
    type: "Pipe",
    designation: "PIPBG83X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,09",
    d_global: 83,
    i_global: "41,77",
    t_global: 2,
    z_global: "10,06",
  },
  {
    profile_section_id: 5265,
    name: "PIPBG85X2",
    type: "Pipe",
    designation: "PIPBG85X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,22",
    d_global: 85,
    i_global: "44,93",
    t_global: 2,
    z_global: "10,57",
  },
  {
    profile_section_id: 5266,
    name: "PIPBG88.9X2.5",
    type: "Pipe",
    designation: "PIPBG88.9X2.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,79",
    d_global: "88,9",
    i_global: "63,37",
    t_global: "2,5",
    z_global: "14,26",
  },
  {
    profile_section_id: 5267,
    name: "PIPBG88.9X2.9",
    type: "Pipe",
    designation: "PIPBG88.9X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,84",
    d_global: "88,9",
    i_global: "72,52",
    t_global: "2,9",
    z_global: "16,32",
  },
  {
    profile_section_id: 5268,
    name: "PIPBG88.9X3.2",
    type: "Pipe",
    designation: "PIPBG88.9X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,62",
    d_global: "88,9",
    i_global: "79,21",
    t_global: "3,2",
    z_global: "17,82",
  },
  {
    profile_section_id: 5269,
    name: "PIPBG88.9X3.25",
    type: "Pipe",
    designation: "PIPBG88.9X3.25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,75",
    d_global: "88,9",
    i_global: "80,31",
    t_global: "3,25",
    z_global: "18,07",
  },
  {
    profile_section_id: 5270,
    name: "PIPBG88.9X4.05",
    type: "Pipe",
    designation: "PIPBG88.9X4.05",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,8",
    d_global: "88,9",
    i_global: "97,38",
    t_global: "4,05",
    z_global: "21,91",
  },
  {
    profile_section_id: 5271,
    name: "PIPBG88.9X4.5",
    type: "Pipe",
    designation: "PIPBG88.9X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,93",
    d_global: "88,9",
    i_global: "106,55",
    t_global: "4,5",
    z_global: "23,97",
  },
  {
    profile_section_id: 5272,
    name: "PIPBG88.9X4.85",
    type: "Pipe",
    designation: "PIPBG88.9X4.85",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,81",
    d_global: "88,9",
    i_global: "113,46",
    t_global: "4,85",
    z_global: "25,53",
  },
  {
    profile_section_id: 5273,
    name: "PIPBG88.9X5.5",
    type: "Pipe",
    designation: "PIPBG88.9X5.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,41",
    d_global: "88,9",
    i_global: "125,84",
    t_global: "5,5",
    z_global: "28,31",
  },
  {
    profile_section_id: 5274,
    name: "PIPBG88.9X5.6",
    type: "Pipe",
    designation: "PIPBG88.9X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,65",
    d_global: "88,9",
    i_global: "127,69",
    t_global: "5,6",
    z_global: "28,73",
  },
  {
    profile_section_id: 5275,
    name: "PIPBG88.9X6.3",
    type: "Pipe",
    designation: "PIPBG88.9X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,35",
    d_global: "88,9",
    i_global: "140,24",
    t_global: "6,3",
    z_global: "31,55",
  },
  {
    profile_section_id: 5276,
    name: "PIPBG89X2",
    type: "Pipe",
    designation: "PIPBG89X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,47",
    d_global: 89,
    i_global: "51,75",
    t_global: 2,
    z_global: "11,63",
  },
  {
    profile_section_id: 5277,
    name: "PIPBG95X2.75",
    type: "Pipe",
    designation: "PIPBG95X2.75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,97",
    d_global: 95,
    i_global: "84,86",
    t_global: "2,75",
    z_global: "17,86",
  },
  {
    profile_section_id: 5278,
    name: "PIPBG95X3.5",
    type: "Pipe",
    designation: "PIPBG95X3.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,06",
    d_global: 95,
    i_global: "105,44",
    t_global: "3,5",
    z_global: "22,2",
  },
  {
    profile_section_id: 5279,
    name: "PIPBG101.6X2",
    type: "Pipe",
    designation: "PIPBG101.6X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,26",
    d_global: "101,6",
    i_global: "77,63",
    t_global: 2,
    z_global: "15,28",
  },
  {
    profile_section_id: 5280,
    name: "PIPBG101.6X2.75",
    type: "Pipe",
    designation: "PIPBG101.6X2.75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,54",
    d_global: "101,6",
    i_global: "104,39",
    t_global: "2,75",
    z_global: "20,55",
  },
  {
    profile_section_id: 5281,
    name: "PIPBG101.6X3.2",
    type: "Pipe",
    designation: "PIPBG101.6X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,89",
    d_global: "101,6",
    i_global: "119,85",
    t_global: "3,2",
    z_global: "23,59",
  },
  {
    profile_section_id: 5282,
    name: "PIPBG101.6X3.6",
    type: "Pipe",
    designation: "PIPBG101.6X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,08",
    d_global: "101,6",
    i_global: "133,24",
    t_global: "3,6",
    z_global: "26,23",
  },
  {
    profile_section_id: 5283,
    name: "PIPBG101.6X4",
    type: "Pipe",
    designation: "PIPBG101.6X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,26",
    d_global: "101,6",
    i_global: "146,28",
    t_global: 4,
    z_global: "28,8",
  },
  {
    profile_section_id: 5284,
    name: "PIPBG101.6X5",
    type: "Pipe",
    designation: "PIPBG101.6X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,17",
    d_global: "101,6",
    i_global: "177,47",
    t_global: 5,
    z_global: "34,94",
  },
  {
    profile_section_id: 5285,
    name: "PIPBG101.6X6.3",
    type: "Pipe",
    designation: "PIPBG101.6X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,86",
    d_global: "101,6",
    i_global: "215,07",
    t_global: "6,3",
    z_global: "42,34",
  },
  {
    profile_section_id: 5286,
    name: "PIPBG101.6X8",
    type: "Pipe",
    designation: "PIPBG101.6X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,52",
    d_global: "101,6",
    i_global: "259,5",
    t_global: 8,
    z_global: "51,08",
  },
  {
    profile_section_id: 5287,
    name: "PIPBG102X2",
    type: "Pipe",
    designation: "PIPBG102X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,28",
    d_global: 102,
    i_global: "78,57",
    t_global: 2,
    z_global: "15,41",
  },
  {
    profile_section_id: 5288,
    name: "PIPBG108X2",
    type: "Pipe",
    designation: "PIPBG108X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,66",
    d_global: 108,
    i_global: "93,58",
    t_global: 2,
    z_global: "17,33",
  },
  {
    profile_section_id: 5289,
    name: "PIPBG108X2.75",
    type: "Pipe",
    designation: "PIPBG108X2.75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,09",
    d_global: 108,
    i_global: 126,
    t_global: "2,75",
    z_global: "23,33",
  },
  {
    profile_section_id: 5290,
    name: "PIPBG108X3.6",
    type: "Pipe",
    designation: "PIPBG108X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,81",
    d_global: 108,
    i_global: "161,06",
    t_global: "3,6",
    z_global: "29,82",
  },
  {
    profile_section_id: 5291,
    name: "PIPBG108X4.5",
    type: "Pipe",
    designation: "PIPBG108X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,63",
    d_global: 108,
    i_global: "196,3",
    t_global: "4,5",
    z_global: "36,35",
  },
  {
    profile_section_id: 5292,
    name: "PIPBG108X5",
    type: "Pipe",
    designation: "PIPBG108X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,18",
    d_global: 108,
    i_global: "215,06",
    t_global: 5,
    z_global: "39,83",
  },
  {
    profile_section_id: 5293,
    name: "PIPBG108X6.3",
    type: "Pipe",
    designation: "PIPBG108X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,13",
    d_global: 108,
    i_global: "261,23",
    t_global: "6,3",
    z_global: "48,38",
  },
  {
    profile_section_id: 5294,
    name: "PIPBG108X8",
    type: "Pipe",
    designation: "PIPBG108X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "25,13",
    d_global: 108,
    i_global: "316,17",
    t_global: 8,
    z_global: "58,55",
  },
  {
    profile_section_id: 5295,
    name: "PIPBG114.3X2.75",
    type: "Pipe",
    designation: "PIPBG114.3X2.75",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,64",
    d_global: "114,3",
    i_global: "149,99",
    t_global: "2,75",
    z_global: "26,25",
  },
  {
    profile_section_id: 5296,
    name: "PIPBG114.3X3.2",
    type: "Pipe",
    designation: "PIPBG114.3X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,17",
    d_global: "114,3",
    i_global: "172,47",
    t_global: "3,2",
    z_global: "30,18",
  },
  {
    profile_section_id: 5297,
    name: "PIPBG114.3X3.6",
    type: "Pipe",
    designation: "PIPBG114.3X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,52",
    d_global: "114,3",
    i_global: "191,98",
    t_global: "3,6",
    z_global: "33,59",
  },
  {
    profile_section_id: 5298,
    name: "PIPBG114.3X3.65",
    type: "Pipe",
    designation: "PIPBG114.3X3.65",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,69",
    d_global: "114,3",
    i_global: "194,39",
    t_global: "3,65",
    z_global: "34,01",
  },
  {
    profile_section_id: 5299,
    name: "PIPBG114.3X4.5",
    type: "Pipe",
    designation: "PIPBG114.3X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,52",
    d_global: "114,3",
    i_global: "234,32",
    t_global: "4,5",
    z_global: 41,
  },
  {
    profile_section_id: 5300,
    name: "PIPBG114.3X5",
    type: "Pipe",
    designation: "PIPBG114.3X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "17,17",
    d_global: "114,3",
    i_global: "256,92",
    t_global: 5,
    z_global: "44,95",
  },
  {
    profile_section_id: 5301,
    name: "PIPBG114.3X5.4",
    type: "Pipe",
    designation: "PIPBG114.3X5.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,47",
    d_global: "114,3",
    i_global: "274,54",
    t_global: "5,4",
    z_global: "48,04",
  },
  {
    profile_section_id: 5302,
    name: "PIPBG114.3X6",
    type: "Pipe",
    designation: "PIPBG114.3X6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,41",
    d_global: "114,3",
    i_global: "300,21",
    t_global: 6,
    z_global: "52,53",
  },
  {
    profile_section_id: 5303,
    name: "PIPBG114.3X6.3",
    type: "Pipe",
    designation: "PIPBG114.3X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "21,38",
    d_global: "114,3",
    i_global: "312,71",
    t_global: "6,3",
    z_global: "54,72",
  },
  {
    profile_section_id: 5304,
    name: "PIPBG114.3X8",
    type: "Pipe",
    designation: "PIPBG114.3X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "26,72",
    d_global: "114,3",
    i_global: "379,49",
    t_global: 8,
    z_global: "66,4",
  },
  {
    profile_section_id: 5305,
    name: "PIPBG121X3",
    type: "Pipe",
    designation: "PIPBG121X3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,12",
    d_global: 121,
    i_global: "193,69",
    t_global: 3,
    z_global: "32,02",
  },
  {
    profile_section_id: 5306,
    name: "PIPBG121X4",
    type: "Pipe",
    designation: "PIPBG121X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,7",
    d_global: 121,
    i_global: "251,88",
    t_global: 4,
    z_global: "41,63",
  },
  {
    profile_section_id: 5307,
    name: "PIPBG127X3",
    type: "Pipe",
    designation: "PIPBG127X3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,69",
    d_global: 127,
    i_global: "224,75",
    t_global: 3,
    z_global: "35,39",
  },
  {
    profile_section_id: 5308,
    name: "PIPBG127X4",
    type: "Pipe",
    designation: "PIPBG127X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,46",
    d_global: 127,
    i_global: "292,61",
    t_global: 4,
    z_global: "46,08",
  },
  {
    profile_section_id: 5309,
    name: "PIPBG133X3",
    type: "Pipe",
    designation: "PIPBG133X3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,25",
    d_global: 133,
    i_global: "258,97",
    t_global: 3,
    z_global: "38,94",
  },
  {
    profile_section_id: 5310,
    name: "PIPBG133X3.6",
    type: "Pipe",
    designation: "PIPBG133X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,63",
    d_global: 133,
    i_global: "306,55",
    t_global: "3,6",
    z_global: "46,1",
  },
  {
    profile_section_id: 5311,
    name: "PIPBG133X4",
    type: "Pipe",
    designation: "PIPBG133X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,21",
    d_global: 133,
    i_global: "337,52",
    t_global: 4,
    z_global: "50,76",
  },
  {
    profile_section_id: 5312,
    name: "PIPBG133X5",
    type: "Pipe",
    designation: "PIPBG133X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,11",
    d_global: 133,
    i_global: "412,4",
    t_global: 5,
    z_global: "62,02",
  },
  {
    profile_section_id: 5313,
    name: "PIPBG133X6.3",
    type: "Pipe",
    designation: "PIPBG133X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "25,08",
    d_global: 133,
    i_global: "504,43",
    t_global: "6,3",
    z_global: "75,85",
  },
  {
    profile_section_id: 5314,
    name: "PIPBG133X8",
    type: "Pipe",
    designation: "PIPBG133X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "31,42",
    d_global: 133,
    i_global: "616,11",
    t_global: 8,
    z_global: "92,65",
  },
  {
    profile_section_id: 5315,
    name: "PIPBG133X10",
    type: "Pipe",
    designation: "PIPBG133X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "38,64",
    d_global: 133,
    i_global: "735,59",
    t_global: 10,
    z_global: "110,61",
  },
  {
    profile_section_id: 5316,
    name: "PIPBG139.7X3",
    type: "Pipe",
    designation: "PIPBG139.7X3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,88",
    d_global: "139,7",
    i_global: "301,09",
    t_global: 3,
    z_global: "43,1",
  },
  {
    profile_section_id: 5317,
    name: "PIPBG139.7X3.6",
    type: "Pipe",
    designation: "PIPBG139.7X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,39",
    d_global: "139,7",
    i_global: "356,65",
    t_global: "3,6",
    z_global: "51,06",
  },
  {
    profile_section_id: 5318,
    name: "PIPBG139.7X4",
    type: "Pipe",
    designation: "PIPBG139.7X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "17,05",
    d_global: "139,7",
    i_global: "392,86",
    t_global: 4,
    z_global: "56,24",
  },
  {
    profile_section_id: 5319,
    name: "PIPBG139.7X4.8",
    type: "Pipe",
    designation: "PIPBG139.7X4.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,34",
    d_global: "139,7",
    i_global: "463,33",
    t_global: "4,8",
    z_global: "66,33",
  },
  {
    profile_section_id: 5320,
    name: "PIPBG139.7X5",
    type: "Pipe",
    designation: "PIPBG139.7X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "21,16",
    d_global: "139,7",
    i_global: "480,54",
    t_global: 5,
    z_global: "68,8",
  },
  {
    profile_section_id: 5321,
    name: "PIPBG139.7X6.3",
    type: "Pipe",
    designation: "PIPBG139.7X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "26,4",
    d_global: "139,7",
    i_global: "588,62",
    t_global: "6,3",
    z_global: "84,27",
  },
  {
    profile_section_id: 5322,
    name: "PIPBG139.7X8",
    type: "Pipe",
    designation: "PIPBG139.7X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "33,1",
    d_global: "139,7",
    i_global: "720,29",
    t_global: 8,
    z_global: "103,12",
  },
  {
    profile_section_id: 5323,
    name: "PIPBG152.4X3",
    type: "Pipe",
    designation: "PIPBG152.4X3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,08",
    d_global: "152,4",
    i_global: "393,01",
    t_global: 3,
    z_global: "51,58",
  },
  {
    profile_section_id: 5324,
    name: "PIPBG159X3",
    type: "Pipe",
    designation: "PIPBG159X3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,7",
    d_global: 159,
    i_global: "447,42",
    t_global: 3,
    z_global: "56,28",
  },
  {
    profile_section_id: 5325,
    name: "PIPBG159X4",
    type: "Pipe",
    designation: "PIPBG159X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "19,48",
    d_global: 159,
    i_global: "585,33",
    t_global: 4,
    z_global: "73,63",
  },
  {
    profile_section_id: 5326,
    name: "PIPBG159X4.5",
    type: "Pipe",
    designation: "PIPBG159X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "21,84",
    d_global: 159,
    i_global: "652,27",
    t_global: "4,5",
    z_global: "82,05",
  },
  {
    profile_section_id: 5327,
    name: "PIPBG159X5.6",
    type: "Pipe",
    designation: "PIPBG159X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "26,99",
    d_global: 159,
    i_global: "794,88",
    t_global: "5,6",
    z_global: "99,98",
  },
  {
    profile_section_id: 5328,
    name: "PIPBG159X6.3",
    type: "Pipe",
    designation: "PIPBG159X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "30,22",
    d_global: 159,
    i_global: "882,38",
    t_global: "6,3",
    z_global: "110,99",
  },
  {
    profile_section_id: 5329,
    name: "PIPBG165.1X4.5",
    type: "Pipe",
    designation: "PIPBG165.1X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "22,7",
    d_global: "165,1",
    i_global: "732,57",
    t_global: "4,5",
    z_global: "88,74",
  },
  {
    profile_section_id: 5330,
    name: "PIPBG165.1X4.8",
    type: "Pipe",
    designation: "PIPBG165.1X4.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "24,17",
    d_global: "165,1",
    i_global: "777,13",
    t_global: "4,8",
    z_global: "94,14",
  },
  {
    profile_section_id: 5331,
    name: "PIPBG168.3X3.2",
    type: "Pipe",
    designation: "PIPBG168.3X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,6",
    d_global: "168,3",
    i_global: "565,74",
    t_global: "3,2",
    z_global: "67,23",
  },
  {
    profile_section_id: 5332,
    name: "PIPBG168.3X4",
    type: "Pipe",
    designation: "PIPBG168.3X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,65",
    d_global: "168,3",
    i_global: "697,09",
    t_global: 4,
    z_global: "82,84",
  },
  {
    profile_section_id: 5333,
    name: "PIPBG168.3X4.5",
    type: "Pipe",
    designation: "PIPBG168.3X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,16",
    d_global: "168,3",
    i_global: "777,22",
    t_global: "4,5",
    z_global: "92,36",
  },
  {
    profile_section_id: 5334,
    name: "PIPBG168.3X4.8",
    type: "Pipe",
    designation: "PIPBG168.3X4.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "24,66",
    d_global: "168,3",
    i_global: "824,57",
    t_global: "4,8",
    z_global: "97,99",
  },
  {
    profile_section_id: 5335,
    name: "PIPBG168.3X5.6",
    type: "Pipe",
    designation: "PIPBG168.3X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "28,62",
    d_global: "168,3",
    i_global: "948,25",
    t_global: "5,6",
    z_global: "112,69",
  },
  {
    profile_section_id: 5336,
    name: "PIPBG168.3X6.3",
    type: "Pipe",
    designation: "PIPBG168.3X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "32,06",
    d_global: "168,3",
    i_global: "1053,42",
    t_global: "6,3",
    z_global: "125,18",
  },
  {
    profile_section_id: 5337,
    name: "PIPBG168.3X7.1",
    type: "Pipe",
    designation: "PIPBG168.3X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "35,96",
    d_global: "168,3",
    i_global: "1170,19",
    t_global: "7,1",
    z_global: "139,06",
  },
  {
    profile_section_id: 5338,
    name: "PIPBG177.8X4.5",
    type: "Pipe",
    designation: "PIPBG177.8X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "24,5",
    d_global: "177,8",
    i_global: "920,37",
    t_global: "4,5",
    z_global: "103,53",
  },
  {
    profile_section_id: 5339,
    name: "PIPBG177.8X5",
    type: "Pipe",
    designation: "PIPBG177.8X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "27,14",
    d_global: "177,8",
    i_global: "1013,97",
    t_global: 5,
    z_global: "114,06",
  },
  {
    profile_section_id: 5340,
    name: "PIPBG193.7X4",
    type: "Pipe",
    designation: "PIPBG193.7X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,84",
    d_global: "193,7",
    i_global: "1072,79",
    t_global: 4,
    z_global: "110,77",
  },
  {
    profile_section_id: 5341,
    name: "PIPBG193.7X5.4",
    type: "Pipe",
    designation: "PIPBG193.7X5.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "31,94",
    d_global: "193,7",
    i_global: "1416,97",
    t_global: "5,4",
    z_global: "146,31",
  },
  {
    profile_section_id: 5342,
    name: "PIPBG193.7X6.3",
    type: "Pipe",
    designation: "PIPBG193.7X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "37,09",
    d_global: "193,7",
    i_global: "1630,05",
    t_global: "6,3",
    z_global: "168,31",
  },
  {
    profile_section_id: 5343,
    name: "PIPBG219.1X4",
    type: "Pipe",
    designation: "PIPBG219.1X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "27,03",
    d_global: "219,1",
    i_global: "1563,84",
    t_global: 4,
    z_global: "142,75",
  },
  {
    profile_section_id: 5344,
    name: "PIPBG219.1X4.5",
    type: "Pipe",
    designation: "PIPBG219.1X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "30,34",
    d_global: "219,1",
    i_global: "1747,24",
    t_global: "4,5",
    z_global: "159,49",
  },
  {
    profile_section_id: 5345,
    name: "PIPBG219.1X4.8",
    type: "Pipe",
    designation: "PIPBG219.1X4.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "32,32",
    d_global: "219,1",
    i_global: "1856,03",
    t_global: "4,8",
    z_global: "169,42",
  },
  {
    profile_section_id: 5346,
    name: "PIPBG219.1X5",
    type: "Pipe",
    designation: "PIPBG219.1X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "33,63",
    d_global: "219,1",
    i_global: "1928,04",
    t_global: 5,
    z_global: 176,
  },
  {
    profile_section_id: 5347,
    name: "PIPBG219.1X5.6",
    type: "Pipe",
    designation: "PIPBG219.1X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "37,56",
    d_global: "219,1",
    i_global: "2141,61",
    t_global: "5,6",
    z_global: "195,49",
  },
  {
    profile_section_id: 5348,
    name: "PIPBG219.1X5.9",
    type: "Pipe",
    designation: "PIPBG219.1X5.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "39,52",
    d_global: "219,1",
    i_global: "2247,01",
    t_global: "5,9",
    z_global: "205,11",
  },
  {
    profile_section_id: 5349,
    name: "PIPBG219.1X6.4",
    type: "Pipe",
    designation: "PIPBG219.1X6.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "42,77",
    d_global: "219,1",
    i_global: "2420,67",
    t_global: "6,4",
    z_global: "220,97",
  },
  {
    profile_section_id: 5350,
    name: "PIPBG219.1X7",
    type: "Pipe",
    designation: "PIPBG219.1X7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "46,64",
    d_global: "219,1",
    i_global: "2625,75",
    t_global: 7,
    z_global: "239,69",
  },
  {
    profile_section_id: 5351,
    name: "PIPBG219.1X8.2",
    type: "Pipe",
    designation: "PIPBG219.1X8.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "54,33",
    d_global: "219,1",
    i_global: "3025,24",
    t_global: "8,2",
    z_global: "276,15",
  },
  {
    profile_section_id: 5352,
    name: "PIPBG244.5X6.3",
    type: "Pipe",
    designation: "PIPBG244.5X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "47,14",
    d_global: "244,5",
    i_global: "3346,03",
    t_global: "6,3",
    z_global: "273,7",
  },
  {
    profile_section_id: 5353,
    name: "PIPBG273X4",
    type: "Pipe",
    designation: "PIPBG273X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "33,8",
    d_global: 273,
    i_global: "3058,25",
    t_global: 4,
    z_global: "224,05",
  },
  {
    profile_section_id: 5354,
    name: "PIPBG273X4.8",
    type: "Pipe",
    designation: "PIPBG273X4.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "40,44",
    d_global: 273,
    i_global: "3637,61",
    t_global: "4,8",
    z_global: "266,49",
  },
  {
    profile_section_id: 5355,
    name: "PIPBG273X5.6",
    type: "Pipe",
    designation: "PIPBG273X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "47,04",
    d_global: 273,
    i_global: "4206,52",
    t_global: "5,6",
    z_global: "308,17",
  },
  {
    profile_section_id: 5356,
    name: "PIPBG273X6.3",
    type: "Pipe",
    designation: "PIPBG273X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "52,79",
    d_global: 273,
    i_global: "4695,82",
    t_global: "6,3",
    z_global: "344,02",
  },
  {
    profile_section_id: 5357,
    name: "PIPBG273X6.4",
    type: "Pipe",
    designation: "PIPBG273X6.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "53,6",
    d_global: 273,
    i_global: "4765,08",
    t_global: "6,4",
    z_global: "349,09",
  },
  {
    profile_section_id: 5358,
    name: "PIPBG273X7.8",
    type: "Pipe",
    designation: "PIPBG273X7.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "64,99",
    d_global: 273,
    i_global: "5718,09",
    t_global: "7,8",
    z_global: "418,91",
  },
  {
    profile_section_id: 5359,
    name: "PIPBG273X9.3",
    type: "Pipe",
    designation: "PIPBG273X9.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "77,04",
    d_global: 273,
    i_global: "6705,22",
    t_global: "9,3",
    z_global: "491,23",
  },
  {
    profile_section_id: 5360,
    name: "PIPBG323.9X4",
    type: "Pipe",
    designation: "PIPBG323.9X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "40,2",
    d_global: "323,9",
    i_global: "5143,16",
    t_global: 4,
    z_global: "317,58",
  },
  {
    profile_section_id: 5361,
    name: "PIPBG323.9X4.5",
    type: "Pipe",
    designation: "PIPBG323.9X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "45,15",
    d_global: "323,9",
    i_global: "5759,22",
    t_global: "4,5",
    z_global: "355,62",
  },
  {
    profile_section_id: 5362,
    name: "PIPBG323.9X4.8",
    type: "Pipe",
    designation: "PIPBG323.9X4.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "48,12",
    d_global: "323,9",
    i_global: "6126,04",
    t_global: "4,8",
    z_global: "378,27",
  },
  {
    profile_section_id: 5363,
    name: "PIPBG323.9X5",
    type: "Pipe",
    designation: "PIPBG323.9X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "50,09",
    d_global: "323,9",
    i_global: "6369,43",
    t_global: 5,
    z_global: "393,3",
  },
  {
    profile_section_id: 5364,
    name: "PIPBG323.9X5.6",
    type: "Pipe",
    designation: "PIPBG323.9X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 56,
    d_global: "323,9",
    i_global: "7094,02",
    t_global: "5,6",
    z_global: "438,04",
  },
  {
    profile_section_id: 5365,
    name: "PIPBG323.9X6.3",
    type: "Pipe",
    designation: "PIPBG323.9X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "62,86",
    d_global: "323,9",
    i_global: "7928,9",
    t_global: "6,3",
    z_global: "489,59",
  },
  {
    profile_section_id: 5366,
    name: "PIPBG323.9X6.4",
    type: "Pipe",
    designation: "PIPBG323.9X6.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "63,84",
    d_global: "323,9",
    i_global: "8047,25",
    t_global: "6,4",
    z_global: "496,9",
  },
  {
    profile_section_id: 5367,
    name: "PIPBG323.9X7.1",
    type: "Pipe",
    designation: "PIPBG323.9X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "70,66",
    d_global: "323,9",
    i_global: "8869,35",
    t_global: "7,1",
    z_global: "547,66",
  },
  {
    profile_section_id: 5368,
    name: "PIPBG323.9X8.4",
    type: "Pipe",
    designation: "PIPBG323.9X8.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "83,26",
    d_global: "323,9",
    i_global: "10366,81",
    t_global: "8,4",
    z_global: "640,12",
  },
  {
    profile_section_id: 5369,
    name: "PIPBG323.9X9.5",
    type: "Pipe",
    designation: "PIPBG323.9X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "93,83",
    d_global: "323,9",
    i_global: "11604,53",
    t_global: "9,5",
    z_global: "716,55",
  },
  {
    profile_section_id: 5370,
    name: "PIPBG406.4X5.8",
    type: "Pipe",
    designation: "PIPBG406.4X5.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "60,56",
    d_global: "406,4",
    i_global: "12210,8",
    t_global: "4,8",
    z_global: "600,92",
  },
  {
    profile_section_id: 5371,
    name: "PIPBG406.4X5.2",
    type: "Pipe",
    designation: "PIPBG406.4X5.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "65,54",
    d_global: "406,4",
    i_global: "13189,21",
    t_global: "5,2",
    z_global: "649,08",
  },
  {
    profile_section_id: 5372,
    name: "PIPBG406.4X6.4",
    type: "Pipe",
    designation: "PIPBG406.4X6.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "80,42",
    d_global: "406,4",
    i_global: "16089,07",
    t_global: "6,4",
    z_global: "791,78",
  },
  {
    profile_section_id: 5373,
    name: "PIPBG406.4X7.9",
    type: "Pipe",
    designation: "PIPBG406.4X7.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "98,9",
    d_global: "406,4",
    i_global: "19640,05",
    t_global: "7,9",
    z_global: "966,54",
  },
  {
    profile_section_id: 5374,
    name: "PIPBG406.4X9.5",
    type: "Pipe",
    designation: "PIPBG406.4X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "118,46",
    d_global: "406,4",
    i_global: "23338,64",
    t_global: "9,5",
    z_global: "1148,56",
  },
  {
    profile_section_id: 5375,
    name: "PIPBG406.4X12.7",
    type: "Pipe",
    designation: "PIPBG406.4X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "157,08",
    d_global: "406,4",
    i_global: "30465,73",
    t_global: "12,7",
    z_global: "1499,3",
  },
  {
    profile_section_id: 5376,
    name: "PIPBG457X5.6",
    type: "Pipe",
    designation: "PIPBG457X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "79,41",
    d_global: 457,
    i_global: "20230,16",
    t_global: "5,6",
    z_global: "885,35",
  },
  {
    profile_section_id: 5377,
    name: "PIPBG457X6.4",
    type: "Pipe",
    designation: "PIPBG457X6.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "90,6",
    d_global: 457,
    i_global: "22998,58",
    t_global: "6,4",
    z_global: "1006,5",
  },
  {
    profile_section_id: 5378,
    name: "PIPBG457X7.9",
    type: "Pipe",
    designation: "PIPBG457X7.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "111,46",
    d_global: 457,
    i_global: "28109,33",
    t_global: "7,9",
    z_global: "1230,17",
  },
  {
    profile_section_id: 5379,
    name: "PIPBG457X9.5",
    type: "Pipe",
    designation: "PIPBG457X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "133,56",
    d_global: 457,
    i_global: "33447,09",
    t_global: "9,5",
    z_global: "1463,77",
  },
  {
    profile_section_id: 5380,
    name: "PIPBG457X12.7",
    type: "Pipe",
    designation: "PIPBG457X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "177,27",
    d_global: 457,
    i_global: "43777,13",
    t_global: "12,7",
    z_global: "1915,85",
  },
  {
    profile_section_id: 5381,
    name: "PIPBG508X6.4",
    type: "Pipe",
    designation: "PIPBG508X6.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "100,85",
    d_global: 508,
    i_global: "31723,65",
    t_global: "6,4",
    z_global: "1248,96",
  },
  {
    profile_section_id: 5382,
    name: "PIPBG508X7.9",
    type: "Pipe",
    designation: "PIPBG508X7.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "124,12",
    d_global: 508,
    i_global: "38811,99",
    t_global: "7,9",
    z_global: "1528,03",
  },
  {
    profile_section_id: 5383,
    name: "PIPBG508X9.5",
    type: "Pipe",
    designation: "PIPBG508X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "148,78",
    d_global: 508,
    i_global: "46231,36",
    t_global: "9,5",
    z_global: "1820,13",
  },
  {
    profile_section_id: 5384,
    name: "PIPBG508X12.7",
    type: "Pipe",
    designation: "PIPBG508X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "197,62",
    d_global: 508,
    i_global: "60639,28",
    t_global: "12,7",
    z_global: "2387,37",
  },
  {
    profile_section_id: 5385,
    name: "PIPBG559X9.5",
    type: "Pipe",
    designation: "PIPBG559X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 164,
    d_global: 559,
    i_global: "61917,92",
    t_global: "9,5",
    z_global: "2215,31",
  },
  {
    profile_section_id: 5386,
    name: "PIPBG610X6.4",
    type: "Pipe",
    designation: "PIPBG610X6.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "121,36",
    d_global: 610,
    i_global: "55275,97",
    t_global: "6,4",
    z_global: "1812,33",
  },
  {
    profile_section_id: 5387,
    name: "PIPBG610X7.9",
    type: "Pipe",
    designation: "PIPBG610X7.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "149,43",
    d_global: 610,
    i_global: "67727,9",
    t_global: "7,9",
    z_global: "2220,59",
  },
  {
    profile_section_id: 5388,
    name: "PIPBG610X9.5",
    type: "Pipe",
    designation: "PIPBG610X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "179,22",
    d_global: 610,
    i_global: "80803,7",
    t_global: "9,5",
    z_global: "2649,3",
  },
  {
    profile_section_id: 5389,
    name: "PIPBG610X12.7",
    type: "Pipe",
    designation: "PIPBG610X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "238,31",
    d_global: 610,
    i_global: "106325,5",
    t_global: "12,7",
    z_global: "3486,08",
  },
  {
    profile_section_id: 5390,
    name: "PIPBG610X19",
    type: "Pipe",
    designation: "PIPBG610X19",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "352,77",
    d_global: 610,
    i_global: "154178,8",
    t_global: 19,
    z_global: "5055,04",
  },
  {
    profile_section_id: 5391,
    name: "PIPBG610X25.4",
    type: "Pipe",
    designation: "PIPBG610X25.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "466,49",
    d_global: 610,
    i_global: "199659,1",
    t_global: "25,4",
    z_global: "6546,2",
  },
  {
    profile_section_id: 5392,
    name: "PIPBG660X9.5",
    type: "Pipe",
    designation: "PIPBG660X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "194,14",
    d_global: 660,
    i_global: "102711,3",
    t_global: "9,5",
    z_global: "3112,46",
  },
  {
    profile_section_id: 5393,
    name: "PIPBG660X12.7",
    type: "Pipe",
    designation: "PIPBG660X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "258,26",
    d_global: 660,
    i_global: "135315,5",
    t_global: "12,7",
    z_global: "4100,47",
  },
  {
    profile_section_id: 5394,
    name: "PIPBG711X6.4",
    type: "Pipe",
    designation: "PIPBG711X6.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "141,67",
    d_global: 711,
    i_global: "87923,23",
    t_global: "6,4",
    z_global: "2473,23",
  },
  {
    profile_section_id: 5395,
    name: "PIPBG711X9.5",
    type: "Pipe",
    designation: "PIPBG711X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "209,36",
    d_global: 711,
    i_global: 128809,
    t_global: "9,5",
    z_global: "3623,32",
  },
  {
    profile_section_id: 5396,
    name: "PIPBG711X12.7",
    type: "Pipe",
    designation: "PIPBG711X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "278,61",
    d_global: 711,
    i_global: "169876,5",
    t_global: "12,7",
    z_global: "4778,52",
  },
  {
    profile_section_id: 5397,
    name: "PIPBG762X7.9",
    type: "Pipe",
    designation: "PIPBG762X7.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "187,16",
    d_global: 762,
    i_global: 133052,
    t_global: "7,9",
    z_global: "3492,18",
  },
  {
    profile_section_id: 5398,
    name: "PIPBG762X9.5",
    type: "Pipe",
    designation: "PIPBG762X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "224,58",
    d_global: 762,
    i_global: "158990,9",
    t_global: "9,5",
    z_global: "4172,99",
  },
  {
    profile_section_id: 5399,
    name: "PIPBG762X12.7",
    type: "Pipe",
    designation: "PIPBG762X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "298,96",
    d_global: 762,
    i_global: "209872,5",
    t_global: "12,7",
    z_global: "5508,47",
  },
  {
    profile_section_id: 5400,
    name: "PIPBG762X19",
    type: "Pipe",
    designation: "PIPBG762X19",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "443,5",
    d_global: 762,
    i_global: "306241,3",
    t_global: 19,
    z_global: "8037,83",
  },
  {
    profile_section_id: 5401,
    name: "PIPBG762X19.1",
    type: "Pipe",
    designation: "PIPBG762X19.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "445,77",
    d_global: 762,
    i_global: 307731,
    t_global: "19,1",
    z_global: "8076,93",
  },
  {
    profile_section_id: 5402,
    name: "PIPBG762X25.4",
    type: "Pipe",
    designation: "PIPBG762X25.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "587,78",
    d_global: 762,
    i_global: "399121,3",
    t_global: "25,4",
    z_global: "10475,62",
  },
  {
    profile_section_id: 5403,
    name: "PIPBG813X7.9",
    type: "Pipe",
    designation: "PIPBG813X7.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "199,81",
    d_global: 813,
    i_global: "161911,7",
    t_global: "7,9",
    z_global: "3983,07",
  },
  {
    profile_section_id: 5404,
    name: "PIPBG813X9.5",
    type: "Pipe",
    designation: "PIPBG813X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "239,81",
    d_global: 813,
    i_global: "193553,9",
    t_global: "9,5",
    z_global: "4761,47",
  },
  {
    profile_section_id: 5405,
    name: "PIPBG813X12.7",
    type: "Pipe",
    designation: "PIPBG813X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "319,31",
    d_global: 813,
    i_global: "255700,4",
    t_global: "12,7",
    z_global: "6290,29",
  },
  {
    profile_section_id: 5406,
    name: "PIPBG914X9.5",
    type: "Pipe",
    designation: "PIPBG914X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "269,95",
    d_global: 914,
    i_global: "276094,1",
    t_global: "9,5",
    z_global: "6041,45",
  },
  {
    profile_section_id: 5407,
    name: "PIPBG914X12.7",
    type: "Pipe",
    designation: "PIPBG914X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "359,6",
    d_global: 914,
    i_global: "365222,8",
    t_global: "12,7",
    z_global: "7991,75",
  },
  {
    profile_section_id: 5408,
    name: "PIPBG914X19",
    type: "Pipe",
    designation: "PIPBG914X19",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "534,23",
    d_global: 914,
    i_global: "535153,4",
    t_global: 19,
    z_global: "11710,14",
  },
  {
    profile_section_id: 5409,
    name: "PIPBG914X19.1",
    type: "Pipe",
    designation: "PIPBG914X19.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "536,98",
    d_global: 914,
    i_global: "537792,3",
    t_global: "19,1",
    z_global: "11767,88",
  },
  {
    profile_section_id: 5410,
    name: "PIPBG914X25.4",
    type: "Pipe",
    designation: "PIPBG914X25.4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "709,07",
    d_global: 914,
    i_global: 700434,
    t_global: "25,4",
    z_global: "15326,78",
  },
  {
    profile_section_id: 5411,
    name: "PIPBG914X31.7",
    type: "Pipe",
    designation: "PIPBG914X31.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "878,67",
    d_global: 914,
    i_global: "856107,4",
    t_global: "31,7",
    z_global: "18733,2",
  },
  {
    profile_section_id: 5412,
    name: "PIPBG1016X9.5",
    type: "Pipe",
    designation: "PIPBG1016X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "300,39",
    d_global: 1016,
    i_global: "380420,2",
    t_global: "9,5",
    z_global: "7488,59",
  },
  {
    profile_section_id: 5413,
    name: "PIPBG1016X12.7",
    type: "Pipe",
    designation: "PIPBG1016X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "400,3",
    d_global: 1016,
    i_global: "503762,3",
    t_global: "12,7",
    z_global: "9916,58",
  },
  {
    profile_section_id: 5414,
    name: "PIPBG1067X9.5",
    type: "Pipe",
    designation: "PIPBG1067X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "315,61",
    d_global: 1067,
    i_global: "441224,6",
    t_global: "9,5",
    z_global: "8270,38",
  },
  {
    profile_section_id: 5415,
    name: "PIPBG1067X12.7",
    type: "Pipe",
    designation: "PIPBG1067X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "420,65",
    d_global: 1067,
    i_global: "584546,8",
    t_global: "12,7",
    z_global: "10956,83",
  },
  {
    profile_section_id: 5416,
    name: "PIPBG1118X12.7",
    type: "Pipe",
    designation: "PIPBG1118X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 441,
    d_global: 1118,
    i_global: 673537,
    t_global: "12,7",
    z_global: "12048,96",
  },
  {
    profile_section_id: 5417,
    name: "PIPBG1219X9.5",
    type: "Pipe",
    designation: "PIPBG1219X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "360,98",
    d_global: 1219,
    i_global: "660127,6",
    t_global: "9,5",
    z_global: "10830,64",
  },
  {
    profile_section_id: 5418,
    name: "PIPBG1219X12.7",
    type: "Pipe",
    designation: "PIPBG1219X12.7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "481,29",
    d_global: 1219,
    i_global: "875543,5",
    t_global: "12,7",
    z_global: "14364,95",
  },
  {
    profile_section_id: 5419,
    name: "PIPBN10.2X1.8",
    type: "Pipe",
    designation: "PIPBN10.2X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,48",
    d_global: "10,2",
    i_global: "0,04",
    t_global: "1,8",
    z_global: "0,09",
  },
  {
    profile_section_id: 5420,
    name: "PIPBN10.2X2",
    type: "Pipe",
    designation: "PIPBN10.2X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,52",
    d_global: "10,2",
    i_global: "0,05",
    t_global: 2,
    z_global: "0,09",
  },
  {
    profile_section_id: 5421,
    name: "PIPBN10.2X2.3",
    type: "Pipe",
    designation: "PIPBN10.2X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,57",
    d_global: "10,2",
    i_global: "0,05",
    t_global: "2,3",
    z_global: "0,1",
  },
  {
    profile_section_id: 5422,
    name: "PIPBN10.2X2.6",
    type: "Pipe",
    designation: "PIPBN10.2X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,62",
    d_global: "10,2",
    i_global: "0,05",
    t_global: "2,6",
    z_global: "0,1",
  },
  {
    profile_section_id: 5423,
    name: "PIPBN13.5X1.8",
    type: "Pipe",
    designation: "PIPBN13.5X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,66",
    d_global: "13,5",
    i_global: "0,12",
    t_global: "1,8",
    z_global: "0,17",
  },
  {
    profile_section_id: 5424,
    name: "PIPBN13.5X2",
    type: "Pipe",
    designation: "PIPBN13.5X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,72",
    d_global: "13,5",
    i_global: "0,12",
    t_global: 2,
    z_global: "0,18",
  },
  {
    profile_section_id: 5425,
    name: "PIPBN13.5X2.3",
    type: "Pipe",
    designation: "PIPBN13.5X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,81",
    d_global: "13,5",
    i_global: "0,13",
    t_global: "2,3",
    z_global: "0,2",
  },
  {
    profile_section_id: 5426,
    name: "PIPBN13.5X2.6",
    type: "Pipe",
    designation: "PIPBN13.5X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,89",
    d_global: "13,5",
    i_global: "0,14",
    t_global: "2,6",
    z_global: "0,21",
  },
  {
    profile_section_id: 5427,
    name: "PIPBN13.5X2.9",
    type: "Pipe",
    designation: "PIPBN13.5X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,97",
    d_global: "13,5",
    i_global: "0,15",
    t_global: "2,9",
    z_global: "0,22",
  },
  {
    profile_section_id: 5428,
    name: "PIPBN13.5X3.2",
    type: "Pipe",
    designation: "PIPBN13.5X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,04",
    d_global: "13,5",
    i_global: "0,15",
    t_global: "3,2",
    z_global: "0,22",
  },
  {
    profile_section_id: 5429,
    name: "PIPBN13.5X3.6",
    type: "Pipe",
    designation: "PIPBN13.5X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,12",
    d_global: "13,5",
    i_global: "0,15",
    t_global: "3,6",
    z_global: "0,23",
  },
  {
    profile_section_id: 5430,
    name: "PIPBN16X1.8",
    type: "Pipe",
    designation: "PIPBN16X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,8",
    d_global: 16,
    i_global: "0,21",
    t_global: "1,8",
    z_global: "0,26",
  },
  {
    profile_section_id: 5431,
    name: "PIPBN16X2",
    type: "Pipe",
    designation: "PIPBN16X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,88",
    d_global: 16,
    i_global: "0,22",
    t_global: 2,
    z_global: "0,28",
  },
  {
    profile_section_id: 5432,
    name: "PIPBN16X2.3",
    type: "Pipe",
    designation: "PIPBN16X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,99",
    d_global: 16,
    i_global: "0,24",
    t_global: "2,3",
    z_global: "0,3",
  },
  {
    profile_section_id: 5433,
    name: "PIPBN16X2.6",
    type: "Pipe",
    designation: "PIPBN16X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,09",
    d_global: 16,
    i_global: "0,26",
    t_global: "2,6",
    z_global: "0,32",
  },
  {
    profile_section_id: 5434,
    name: "PIPBN16X2.9",
    type: "Pipe",
    designation: "PIPBN16X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,19",
    d_global: 16,
    i_global: "0,27",
    t_global: "2,9",
    z_global: "0,34",
  },
  {
    profile_section_id: 5435,
    name: "PIPBN16X3.2",
    type: "Pipe",
    designation: "PIPBN16X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,29",
    d_global: 16,
    i_global: "0,28",
    t_global: "3,2",
    z_global: "0,35",
  },
  {
    profile_section_id: 5436,
    name: "PIPBN16X3.6",
    type: "Pipe",
    designation: "PIPBN16X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,4",
    d_global: 16,
    i_global: "0,29",
    t_global: "3,6",
    z_global: "0,36",
  },
  {
    profile_section_id: 5437,
    name: "PIPBN16X4",
    type: "Pipe",
    designation: "PIPBN16X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,51",
    d_global: 16,
    i_global: "0,3",
    t_global: 4,
    z_global: "0,38",
  },
  {
    profile_section_id: 5438,
    name: "PIPBN17.2X1.8",
    type: "Pipe",
    designation: "PIPBN17.2X1.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,87",
    d_global: "17,2",
    i_global: "0,26",
    t_global: "1,8",
    z_global: "0,3",
  },
  {
    profile_section_id: 5439,
    name: "PIPBN17.2X2",
    type: "Pipe",
    designation: "PIPBN17.2X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "0,96",
    d_global: "17,2",
    i_global: "0,28",
    t_global: 2,
    z_global: "0,33",
  },
  {
    profile_section_id: 5440,
    name: "PIPBN17.2X2.3",
    type: "Pipe",
    designation: "PIPBN17.2X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,08",
    d_global: "17,2",
    i_global: "0,31",
    t_global: "2,3",
    z_global: "0,36",
  },
  {
    profile_section_id: 5441,
    name: "PIPBN17.2X2.6",
    type: "Pipe",
    designation: "PIPBN17.2X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,19",
    d_global: "17,2",
    i_global: "0,33",
    t_global: "2,6",
    z_global: "0,38",
  },
  {
    profile_section_id: 5442,
    name: "PIPBN17.2X2.9",
    type: "Pipe",
    designation: "PIPBN17.2X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,3",
    d_global: "17,2",
    i_global: "0,35",
    t_global: "2,9",
    z_global: "0,4",
  },
  {
    profile_section_id: 5443,
    name: "PIPBN17.2X3.2",
    type: "Pipe",
    designation: "PIPBN17.2X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,41",
    d_global: "17,2",
    i_global: "0,36",
    t_global: "3,2",
    z_global: "0,42",
  },
  {
    profile_section_id: 5444,
    name: "PIPBN17.2X3.6",
    type: "Pipe",
    designation: "PIPBN17.2X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,54",
    d_global: "17,2",
    i_global: "0,38",
    t_global: "3,6",
    z_global: "0,44",
  },
  {
    profile_section_id: 5445,
    name: "PIPBN17.2X4.0",
    type: "Pipe",
    designation: "PIPBN17.2X4.0",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,66",
    d_global: "17,2",
    i_global: "0,39",
    t_global: 4,
    z_global: "0,46",
  },
  {
    profile_section_id: 5446,
    name: "PIPBN17.2X4.5",
    type: "Pipe",
    designation: "PIPBN17.2X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,8",
    d_global: "17,2",
    i_global: "0,41",
    t_global: "4,5",
    z_global: "0,47",
  },
  {
    profile_section_id: 5447,
    name: "PIPBN19X2.3",
    type: "Pipe",
    designation: "PIPBN19X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,21",
    d_global: 19,
    i_global: "0,43",
    t_global: "2,3",
    z_global: "0,45",
  },
  {
    profile_section_id: 5448,
    name: "PIPBN19X2.6",
    type: "Pipe",
    designation: "PIPBN19X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,34",
    d_global: 19,
    i_global: "0,46",
    t_global: "2,6",
    z_global: "0,49",
  },
  {
    profile_section_id: 5449,
    name: "PIPBN19X2.9",
    type: "Pipe",
    designation: "PIPBN19X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,47",
    d_global: 19,
    i_global: "0,49",
    t_global: "2,9",
    z_global: "0,52",
  },
  {
    profile_section_id: 5450,
    name: "PIPBN19X3.2",
    type: "Pipe",
    designation: "PIPBN19X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,59",
    d_global: 19,
    i_global: "0,52",
    t_global: "3,2",
    z_global: "0,54",
  },
  {
    profile_section_id: 5451,
    name: "PIPBN19X3.6",
    type: "Pipe",
    designation: "PIPBN19X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,74",
    d_global: 19,
    i_global: "0,55",
    t_global: "3,6",
    z_global: "0,57",
  },
  {
    profile_section_id: 5452,
    name: "PIPBN19X4",
    type: "Pipe",
    designation: "PIPBN19X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,88",
    d_global: 19,
    i_global: "0,57",
    t_global: 4,
    z_global: "0,6",
  },
  {
    profile_section_id: 5453,
    name: "PIPBN19X4.5",
    type: "Pipe",
    designation: "PIPBN19X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,05",
    d_global: 19,
    i_global: "0,59",
    t_global: "4,5",
    z_global: "0,62",
  },
  {
    profile_section_id: 5454,
    name: "PIPBN19X5",
    type: "Pipe",
    designation: "PIPBN19X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,2",
    d_global: 19,
    i_global: "0,61",
    t_global: 5,
    z_global: "0,64",
  },
  {
    profile_section_id: 5455,
    name: "PIPBN20X2",
    type: "Pipe",
    designation: "PIPBN20X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,13",
    d_global: 20,
    i_global: "0,46",
    t_global: 2,
    z_global: "0,46",
  },
  {
    profile_section_id: 5456,
    name: "PIPBN20X2.3",
    type: "Pipe",
    designation: "PIPBN20X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,28",
    d_global: 20,
    i_global: "0,51",
    t_global: "2,3",
    z_global: "0,51",
  },
  {
    profile_section_id: 5457,
    name: "PIPBN20X2.6",
    type: "Pipe",
    designation: "PIPBN20X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,42",
    d_global: 20,
    i_global: "0,55",
    t_global: "2,6",
    z_global: "0,55",
  },
  {
    profile_section_id: 5458,
    name: "PIPBN20X2.9",
    type: "Pipe",
    designation: "PIPBN20X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,56",
    d_global: 20,
    i_global: "0,59",
    t_global: "2,9",
    z_global: "0,59",
  },
  {
    profile_section_id: 5459,
    name: "PIPBN20X3.2",
    type: "Pipe",
    designation: "PIPBN20X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,69",
    d_global: 20,
    i_global: "0,62",
    t_global: "3,2",
    z_global: "0,62",
  },
  {
    profile_section_id: 5460,
    name: "PIPBN20X3.6",
    type: "Pipe",
    designation: "PIPBN20X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,85",
    d_global: 20,
    i_global: "0,65",
    t_global: "3,6",
    z_global: "0,65",
  },
  {
    profile_section_id: 5461,
    name: "PIPBN20X4",
    type: "Pipe",
    designation: "PIPBN20X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,01",
    d_global: 20,
    i_global: "0,68",
    t_global: 4,
    z_global: "0,68",
  },
  {
    profile_section_id: 5462,
    name: "PIPBN20X4.5",
    type: "Pipe",
    designation: "PIPBN20X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,19",
    d_global: 20,
    i_global: "0,71",
    t_global: "4,5",
    z_global: "0,71",
  },
  {
    profile_section_id: 5463,
    name: "PIPBN20X5",
    type: "Pipe",
    designation: "PIPBN20X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,36",
    d_global: 20,
    i_global: "0,74",
    t_global: 5,
    z_global: "0,74",
  },
  {
    profile_section_id: 5464,
    name: "PIPBN21.3X2",
    type: "Pipe",
    designation: "PIPBN21.3X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,21",
    d_global: "21,3",
    i_global: "0,57",
    t_global: 2,
    z_global: "0,54",
  },
  {
    profile_section_id: 5465,
    name: "PIPBN21.3X2.3",
    type: "Pipe",
    designation: "PIPBN21.3X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,37",
    d_global: "21,3",
    i_global: "0,63",
    t_global: "2,3",
    z_global: "0,59",
  },
  {
    profile_section_id: 5466,
    name: "PIPBN21.3X2.6",
    type: "Pipe",
    designation: "PIPBN21.3X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,53",
    d_global: "21,3",
    i_global: "0,68",
    t_global: "2,6",
    z_global: "0,64",
  },
  {
    profile_section_id: 5467,
    name: "PIPBN21.3X2.9",
    type: "Pipe",
    designation: "PIPBN21.3X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,68",
    d_global: "21,3",
    i_global: "0,73",
    t_global: "2,9",
    z_global: "0,68",
  },
  {
    profile_section_id: 5468,
    name: "PIPBN21.3X3.2",
    type: "Pipe",
    designation: "PIPBN21.3X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,82",
    d_global: "21,3",
    i_global: "0,77",
    t_global: "3,2",
    z_global: "0,72",
  },
  {
    profile_section_id: 5469,
    name: "PIPBN21.3X3.6",
    type: "Pipe",
    designation: "PIPBN21.3X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 2,
    d_global: "21,3",
    i_global: "0,82",
    t_global: "3,6",
    z_global: "0,77",
  },
  {
    profile_section_id: 5470,
    name: "PIPBN21.3X4",
    type: "Pipe",
    designation: "PIPBN21.3X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,17",
    d_global: "21,3",
    i_global: "0,86",
    t_global: 4,
    z_global: "0,81",
  },
  {
    profile_section_id: 5471,
    name: "PIPBN21.3X4.5",
    type: "Pipe",
    designation: "PIPBN21.3X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,38",
    d_global: "21,3",
    i_global: "0,9",
    t_global: "4,5",
    z_global: "0,84",
  },
  {
    profile_section_id: 5472,
    name: "PIPBN21.3X5",
    type: "Pipe",
    designation: "PIPBN21.3X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,56",
    d_global: "21,3",
    i_global: "0,93",
    t_global: 5,
    z_global: "0,87",
  },
  {
    profile_section_id: 5473,
    name: "PIPBN21.3X5.6",
    type: "Pipe",
    designation: "PIPBN21.3X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,76",
    d_global: "21,3",
    i_global: "0,96",
    t_global: "5,6",
    z_global: "0,9",
  },
  {
    profile_section_id: 5474,
    name: "PIPBN24X2",
    type: "Pipe",
    designation: "PIPBN24X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,38",
    d_global: 24,
    i_global: "0,84",
    t_global: 2,
    z_global: "0,7",
  },
  {
    profile_section_id: 5475,
    name: "PIPBN24X2.3",
    type: "Pipe",
    designation: "PIPBN24X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,57",
    d_global: 24,
    i_global: "0,93",
    t_global: "2,3",
    z_global: "0,78",
  },
  {
    profile_section_id: 5476,
    name: "PIPBN24X2.6",
    type: "Pipe",
    designation: "PIPBN24X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,75",
    d_global: 24,
    i_global: "1,01",
    t_global: "2,6",
    z_global: "0,85",
  },
  {
    profile_section_id: 5477,
    name: "PIPBN24X2.9",
    type: "Pipe",
    designation: "PIPBN24X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,92",
    d_global: 24,
    i_global: "1,09",
    t_global: "2,9",
    z_global: "0,91",
  },
  {
    profile_section_id: 5478,
    name: "PIPBN24X3.2",
    type: "Pipe",
    designation: "PIPBN24X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,09",
    d_global: 24,
    i_global: "1,16",
    t_global: "3,2",
    z_global: "0,96",
  },
  {
    profile_section_id: 5479,
    name: "PIPBN24X3.6",
    type: "Pipe",
    designation: "PIPBN24X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,31",
    d_global: 24,
    i_global: "1,24",
    t_global: "3,6",
    z_global: "1,03",
  },
  {
    profile_section_id: 5480,
    name: "PIPBN24X4",
    type: "Pipe",
    designation: "PIPBN24X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,51",
    d_global: 24,
    i_global: "1,31",
    t_global: 4,
    z_global: "1,09",
  },
  {
    profile_section_id: 5481,
    name: "PIPBN24X4.5",
    type: "Pipe",
    designation: "PIPBN24X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,76",
    d_global: 24,
    i_global: "1,38",
    t_global: "4,5",
    z_global: "1,15",
  },
  {
    profile_section_id: 5482,
    name: "PIPBN24X5",
    type: "Pipe",
    designation: "PIPBN24X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,98",
    d_global: 24,
    i_global: "1,44",
    t_global: 5,
    z_global: "1,2",
  },
  {
    profile_section_id: 5483,
    name: "PIPBN24X5.6",
    type: "Pipe",
    designation: "PIPBN24X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,24",
    d_global: 24,
    i_global: "1,5",
    t_global: "5,6",
    z_global: "1,25",
  },
  {
    profile_section_id: 5484,
    name: "PIPBN25X2",
    type: "Pipe",
    designation: "PIPBN25X2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,45",
    d_global: 25,
    i_global: "0,96",
    t_global: 2,
    z_global: "0,77",
  },
  {
    profile_section_id: 5485,
    name: "PIPBN25X2.3",
    type: "Pipe",
    designation: "PIPBN25X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,64",
    d_global: 25,
    i_global: "1,07",
    t_global: "2,3",
    z_global: "0,85",
  },
  {
    profile_section_id: 5486,
    name: "PIPBN25X2.6",
    type: "Pipe",
    designation: "PIPBN25X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,83",
    d_global: 25,
    i_global: "1,16",
    t_global: "2,6",
    z_global: "0,93",
  },
  {
    profile_section_id: 5487,
    name: "PIPBN25X2.9",
    type: "Pipe",
    designation: "PIPBN25X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,01",
    d_global: 25,
    i_global: "1,25",
    t_global: "2,9",
    z_global: 1,
  },
  {
    profile_section_id: 5488,
    name: "PIPBN25X3.2",
    type: "Pipe",
    designation: "PIPBN25X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,19",
    d_global: 25,
    i_global: "1,33",
    t_global: "3,2",
    z_global: "1,06",
  },
  {
    profile_section_id: 5489,
    name: "PIPBN25X3.6",
    type: "Pipe",
    designation: "PIPBN25X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,42",
    d_global: 25,
    i_global: "1,43",
    t_global: "3,6",
    z_global: "1,14",
  },
  {
    profile_section_id: 5490,
    name: "PIPBN25X4",
    type: "Pipe",
    designation: "PIPBN25X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,64",
    d_global: 25,
    i_global: "1,51",
    t_global: 4,
    z_global: "1,21",
  },
  {
    profile_section_id: 5491,
    name: "PIPBN25X4.5",
    type: "Pipe",
    designation: "PIPBN25X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,9",
    d_global: 25,
    i_global: "1,6",
    t_global: "4,5",
    z_global: "1,28",
  },
  {
    profile_section_id: 5492,
    name: "PIPBN25X5",
    type: "Pipe",
    designation: "PIPBN25X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,14",
    d_global: 25,
    i_global: "1,67",
    t_global: 5,
    z_global: "1,33",
  },
  {
    profile_section_id: 5493,
    name: "PIPBN25X5.6",
    type: "Pipe",
    designation: "PIPBN25X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,41",
    d_global: 25,
    i_global: "1,74",
    t_global: "5,6",
    z_global: "1,39",
  },
  {
    profile_section_id: 5494,
    name: "PIPBN25X6.3",
    type: "Pipe",
    designation: "PIPBN25X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,7",
    d_global: 25,
    i_global: "1,8",
    t_global: "6,3",
    z_global: "1,44",
  },
  {
    profile_section_id: 5495,
    name: "PIPBN25.4X2.3",
    type: "Pipe",
    designation: "PIPBN25.4X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,67",
    d_global: "25,4",
    i_global: "1,12",
    t_global: "2,3",
    z_global: "0,89",
  },
  {
    profile_section_id: 5496,
    name: "PIPBN25.4X2.6",
    type: "Pipe",
    designation: "PIPBN25.4X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,86",
    d_global: "25,4",
    i_global: "1,23",
    t_global: "2,6",
    z_global: "0,96",
  },
  {
    profile_section_id: 5497,
    name: "PIPBN25.4X2.9",
    type: "Pipe",
    designation: "PIPBN25.4X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,05",
    d_global: "25,4",
    i_global: "1,32",
    t_global: "2,9",
    z_global: "1,04",
  },
  {
    profile_section_id: 5498,
    name: "PIPBN25.4X3.2",
    type: "Pipe",
    designation: "PIPBN25.4X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,23",
    d_global: "25,4",
    i_global: "1,4",
    t_global: "3,2",
    z_global: "1,1",
  },
  {
    profile_section_id: 5499,
    name: "PIPBN25.4X3.6",
    type: "Pipe",
    designation: "PIPBN25.4X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,47",
    d_global: "25,4",
    i_global: "1,5",
    t_global: "3,6",
    z_global: "1,19",
  },
  {
    profile_section_id: 5500,
    name: "PIPBN25.4X4",
    type: "Pipe",
    designation: "PIPBN25.4X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,69",
    d_global: "25,4",
    i_global: "1,59",
    t_global: 4,
    z_global: "1,25",
  },
  {
    profile_section_id: 5501,
    name: "PIPBN25.4X4.5",
    type: "Pipe",
    designation: "PIPBN25.4X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,95",
    d_global: "25,4",
    i_global: "1,69",
    t_global: "4,5",
    z_global: "1,33",
  },
  {
    profile_section_id: 5502,
    name: "PIPBN25.4X5",
    type: "Pipe",
    designation: "PIPBN25.4X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,2",
    d_global: "25,4",
    i_global: "1,77",
    t_global: 5,
    z_global: "1,39",
  },
  {
    profile_section_id: 5503,
    name: "PIPBN25.4X5.6",
    type: "Pipe",
    designation: "PIPBN25.4X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,48",
    d_global: "25,4",
    i_global: "1,84",
    t_global: "5,6",
    z_global: "1,45",
  },
  {
    profile_section_id: 5504,
    name: "PIPBN25.4X6.3",
    type: "Pipe",
    designation: "PIPBN25.4X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,78",
    d_global: "25,4",
    i_global: "1,91",
    t_global: "6,3",
    z_global: "1,5",
  },
  {
    profile_section_id: 5505,
    name: "PIPBN26.9X2.3",
    type: "Pipe",
    designation: "PIPBN26.9X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,78",
    d_global: "26,9",
    i_global: "1,36",
    t_global: "2,3",
    z_global: "1,01",
  },
  {
    profile_section_id: 5506,
    name: "PIPBN26.9X2.6",
    type: "Pipe",
    designation: "PIPBN26.9X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,98",
    d_global: "26,9",
    i_global: "1,48",
    t_global: "2,6",
    z_global: "1,1",
  },
  {
    profile_section_id: 5507,
    name: "PIPBN26.9X2.9",
    type: "Pipe",
    designation: "PIPBN26.9X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,19",
    d_global: "26,9",
    i_global: "1,6",
    t_global: "2,9",
    z_global: "1,19",
  },
  {
    profile_section_id: 5508,
    name: "PIPBN26.9X3.2",
    type: "Pipe",
    designation: "PIPBN26.9X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,38",
    d_global: "26,9",
    i_global: "1,7",
    t_global: "3,2",
    z_global: "1,27",
  },
  {
    profile_section_id: 5509,
    name: "PIPBN26.9X3.6",
    type: "Pipe",
    designation: "PIPBN26.9X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,64",
    d_global: "26,9",
    i_global: "1,83",
    t_global: "3,6",
    z_global: "1,36",
  },
  {
    profile_section_id: 5510,
    name: "PIPBN26.9X4",
    type: "Pipe",
    designation: "PIPBN26.9X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,88",
    d_global: "26,9",
    i_global: "1,94",
    t_global: 4,
    z_global: "1,45",
  },
  {
    profile_section_id: 5511,
    name: "PIPBN26.9X4.5",
    type: "Pipe",
    designation: "PIPBN26.9X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,17",
    d_global: "26,9",
    i_global: "2,07",
    t_global: "4,5",
    z_global: "1,54",
  },
  {
    profile_section_id: 5512,
    name: "PIPBN26.9X5",
    type: "Pipe",
    designation: "PIPBN26.9X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,44",
    d_global: "26,9",
    i_global: "2,17",
    t_global: 5,
    z_global: "1,61",
  },
  {
    profile_section_id: 5513,
    name: "PIPBN26.9X5.6",
    type: "Pipe",
    designation: "PIPBN26.9X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,75",
    d_global: "26,9",
    i_global: "2,27",
    t_global: "5,6",
    z_global: "1,69",
  },
  {
    profile_section_id: 5514,
    name: "PIPBN26.9X6.3",
    type: "Pipe",
    designation: "PIPBN26.9X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,08",
    d_global: "26,9",
    i_global: "2,37",
    t_global: "6,3",
    z_global: "1,76",
  },
  {
    profile_section_id: 5515,
    name: "PIPBN26.9X7.1",
    type: "Pipe",
    designation: "PIPBN26.9X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,42",
    d_global: "26,9",
    i_global: "2,44",
    t_global: "7,1",
    z_global: "1,82",
  },
  {
    profile_section_id: 5516,
    name: "PIPBN28X2.3",
    type: "Pipe",
    designation: "PIPBN28X2.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "1,86",
    d_global: 28,
    i_global: "1,54",
    t_global: "2,3",
    z_global: "1,1",
  },
  {
    profile_section_id: 5517,
    name: "PIPBN28X2.9",
    type: "Pipe",
    designation: "PIPBN28X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,29",
    d_global: 28,
    i_global: "1,82",
    t_global: "2,9",
    z_global: "1,3",
  },
  {
    profile_section_id: 5518,
    name: "PIPBN28X3.2",
    type: "Pipe",
    designation: "PIPBN28X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,49",
    d_global: 28,
    i_global: "1,95",
    t_global: "3,2",
    z_global: "1,39",
  },
  {
    profile_section_id: 5519,
    name: "PIPBN28X3.6",
    type: "Pipe",
    designation: "PIPBN28X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,76",
    d_global: 28,
    i_global: "2,1",
    t_global: "3,6",
    z_global: "1,5",
  },
  {
    profile_section_id: 5520,
    name: "PIPBN28X4",
    type: "Pipe",
    designation: "PIPBN28X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,02",
    d_global: 28,
    i_global: "2,23",
    t_global: 4,
    z_global: "1,59",
  },
  {
    profile_section_id: 5521,
    name: "PIPBN28X4.5",
    type: "Pipe",
    designation: "PIPBN28X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,32",
    d_global: 28,
    i_global: "2,38",
    t_global: "4,5",
    z_global: "1,7",
  },
  {
    profile_section_id: 5522,
    name: "PIPBN28X5",
    type: "Pipe",
    designation: "PIPBN28X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,61",
    d_global: 28,
    i_global: "2,5",
    t_global: 5,
    z_global: "1,79",
  },
  {
    profile_section_id: 5523,
    name: "PIPBN28X5.6",
    type: "Pipe",
    designation: "PIPBN28X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,94",
    d_global: 28,
    i_global: "2,63",
    t_global: "5,6",
    z_global: "1,88",
  },
  {
    profile_section_id: 5524,
    name: "PIPBN28X6.3",
    type: "Pipe",
    designation: "PIPBN28X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,29",
    d_global: 28,
    i_global: "2,74",
    t_global: "6,3",
    z_global: "1,96",
  },
  {
    profile_section_id: 5525,
    name: "PIPBN28X7.1",
    type: "Pipe",
    designation: "PIPBN28X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,66",
    d_global: 28,
    i_global: "2,84",
    t_global: "7,1",
    z_global: "2,03",
  },
  {
    profile_section_id: 5526,
    name: "PIPBN30X2.6",
    type: "Pipe",
    designation: "PIPBN30X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,24",
    d_global: 30,
    i_global: "2,12",
    t_global: "2,6",
    z_global: "1,41",
  },
  {
    profile_section_id: 5527,
    name: "PIPBN30X2.9",
    type: "Pipe",
    designation: "PIPBN30X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,47",
    d_global: 30,
    i_global: "2,29",
    t_global: "2,9",
    z_global: "1,53",
  },
  {
    profile_section_id: 5528,
    name: "PIPBN30X3.2",
    type: "Pipe",
    designation: "PIPBN30X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,69",
    d_global: 30,
    i_global: "2,45",
    t_global: "3,2",
    z_global: "1,64",
  },
  {
    profile_section_id: 5529,
    name: "PIPBN30X3.6",
    type: "Pipe",
    designation: "PIPBN30X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,99",
    d_global: 30,
    i_global: "2,65",
    t_global: "3,6",
    z_global: "1,77",
  },
  {
    profile_section_id: 5530,
    name: "PIPBN30X4",
    type: "Pipe",
    designation: "PIPBN30X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,27",
    d_global: 30,
    i_global: "2,83",
    t_global: 4,
    z_global: "1,88",
  },
  {
    profile_section_id: 5531,
    name: "PIPBN30X4.5",
    type: "Pipe",
    designation: "PIPBN30X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,6",
    d_global: 30,
    i_global: "3,02",
    t_global: "4,5",
    z_global: "2,01",
  },
  {
    profile_section_id: 5532,
    name: "PIPBN30X5",
    type: "Pipe",
    designation: "PIPBN30X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,93",
    d_global: 30,
    i_global: "3,19",
    t_global: 5,
    z_global: "2,13",
  },
  {
    profile_section_id: 5533,
    name: "PIPBN30X5.6",
    type: "Pipe",
    designation: "PIPBN30X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,29",
    d_global: 30,
    i_global: "3,36",
    t_global: "5,6",
    z_global: "2,24",
  },
  {
    profile_section_id: 5534,
    name: "PIPBN30X6.3",
    type: "Pipe",
    designation: "PIPBN30X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,69",
    d_global: 30,
    i_global: "3,53",
    t_global: "6,3",
    z_global: "2,35",
  },
  {
    profile_section_id: 5535,
    name: "PIPBN30X7.1",
    type: "Pipe",
    designation: "PIPBN30X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,11",
    d_global: 30,
    i_global: "3,67",
    t_global: "7,1",
    z_global: "2,45",
  },
  {
    profile_section_id: 5536,
    name: "PIPBN30X8",
    type: "Pipe",
    designation: "PIPBN30X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,53",
    d_global: 30,
    i_global: "3,79",
    t_global: 8,
    z_global: "2,52",
  },
  {
    profile_section_id: 5537,
    name: "PIPBN30X8.8",
    type: "Pipe",
    designation: "PIPBN30X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,86",
    d_global: 30,
    i_global: "3,86",
    t_global: "8,8",
    z_global: "2,57",
  },
  {
    profile_section_id: 5538,
    name: "PIPBN31.8X2.6",
    type: "Pipe",
    designation: "PIPBN31.8X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,39",
    d_global: "31,8",
    i_global: "2,56",
    t_global: "2,6",
    z_global: "1,61",
  },
  {
    profile_section_id: 5539,
    name: "PIPBN31.8X2.9",
    type: "Pipe",
    designation: "PIPBN31.8X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,63",
    d_global: "31,8",
    i_global: "2,78",
    t_global: "2,9",
    z_global: "1,75",
  },
  {
    profile_section_id: 5540,
    name: "PIPBN31.8X3.2",
    type: "Pipe",
    designation: "PIPBN31.8X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,88",
    d_global: "31,8",
    i_global: "2,98",
    t_global: "3,2",
    z_global: "1,87",
  },
  {
    profile_section_id: 5541,
    name: "PIPBN31.8X3.6",
    type: "Pipe",
    designation: "PIPBN31.8X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,19",
    d_global: "31,8",
    i_global: "3,22",
    t_global: "3,6",
    z_global: "2,03",
  },
  {
    profile_section_id: 5542,
    name: "PIPBN31.8X4",
    type: "Pipe",
    designation: "PIPBN31.8X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,49",
    d_global: "31,8",
    i_global: "3,44",
    t_global: 4,
    z_global: "2,17",
  },
  {
    profile_section_id: 5543,
    name: "PIPBN31.8X4.5",
    type: "Pipe",
    designation: "PIPBN31.8X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,86",
    d_global: "31,8",
    i_global: "3,69",
    t_global: "4,5",
    z_global: "2,32",
  },
  {
    profile_section_id: 5544,
    name: "PIPBN31.8X5",
    type: "Pipe",
    designation: "PIPBN31.8X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,21",
    d_global: "31,8",
    i_global: "3,91",
    t_global: 5,
    z_global: "2,46",
  },
  {
    profile_section_id: 5545,
    name: "PIPBN31.8X5.6",
    type: "Pipe",
    designation: "PIPBN31.8X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,61",
    d_global: "31,8",
    i_global: "4,14",
    t_global: "5,6",
    z_global: "2,6",
  },
  {
    profile_section_id: 5546,
    name: "PIPBN31.8X6.3",
    type: "Pipe",
    designation: "PIPBN31.8X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,05",
    d_global: "31,8",
    i_global: "4,35",
    t_global: "6,3",
    z_global: "2,74",
  },
  {
    profile_section_id: 5547,
    name: "PIPBN31.8X7.1",
    type: "Pipe",
    designation: "PIPBN31.8X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,51",
    d_global: "31,8",
    i_global: "4,55",
    t_global: "7,1",
    z_global: "2,86",
  },
  {
    profile_section_id: 5548,
    name: "PIPBN31.8X8",
    type: "Pipe",
    designation: "PIPBN31.8X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,98",
    d_global: "31,8",
    i_global: "4,71",
    t_global: 8,
    z_global: "2,96",
  },
  {
    profile_section_id: 5549,
    name: "PIPBN31.8X8.8",
    type: "Pipe",
    designation: "PIPBN31.8X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,36",
    d_global: "31,8",
    i_global: "4,82",
    t_global: "8,8",
    z_global: "3,03",
  },
  {
    profile_section_id: 5550,
    name: "PIPBN32X6",
    type: "Pipe",
    designation: "PIPBN32X6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,9",
    d_global: 32,
    i_global: "4,36",
    t_global: 6,
    z_global: "2,73",
  },
  {
    profile_section_id: 5551,
    name: "PIPBN32X8",
    type: "Pipe",
    designation: "PIPBN32X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,03",
    d_global: 32,
    i_global: "4,83",
    t_global: 8,
    z_global: "3,02",
  },
  {
    profile_section_id: 5552,
    name: "PIPBN33.7X2.6",
    type: "Pipe",
    designation: "PIPBN33.7X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,54",
    d_global: "33,7",
    i_global: "3,09",
    t_global: "2,6",
    z_global: "1,83",
  },
  {
    profile_section_id: 5553,
    name: "PIPBN33.7X2.9",
    type: "Pipe",
    designation: "PIPBN33.7X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,81",
    d_global: "33,7",
    i_global: "3,36",
    t_global: "2,9",
    z_global: "1,99",
  },
  {
    profile_section_id: 5554,
    name: "PIPBN33.7X3.2",
    type: "Pipe",
    designation: "PIPBN33.7X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,07",
    d_global: "33,7",
    i_global: "3,6",
    t_global: "3,2",
    z_global: "2,14",
  },
  {
    profile_section_id: 5555,
    name: "PIPBN33.7X3.6",
    type: "Pipe",
    designation: "PIPBN33.7X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,4",
    d_global: "33,7",
    i_global: "3,91",
    t_global: "3,6",
    z_global: "2,32",
  },
  {
    profile_section_id: 5556,
    name: "PIPBN33.7X4",
    type: "Pipe",
    designation: "PIPBN33.7X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,73",
    d_global: "33,7",
    i_global: "4,19",
    t_global: 4,
    z_global: "2,49",
  },
  {
    profile_section_id: 5557,
    name: "PIPBN33.7X4.5",
    type: "Pipe",
    designation: "PIPBN33.7X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,13",
    d_global: "33,7",
    i_global: "4,5",
    t_global: "4,5",
    z_global: "2,67",
  },
  {
    profile_section_id: 5558,
    name: "PIPBN33.7X5",
    type: "Pipe",
    designation: "PIPBN33.7X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,51",
    d_global: "33,7",
    i_global: "4,78",
    t_global: 5,
    z_global: "2,84",
  },
  {
    profile_section_id: 5559,
    name: "PIPBN33.7X5.6",
    type: "Pipe",
    designation: "PIPBN33.7X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,94",
    d_global: "33,7",
    i_global: "5,07",
    t_global: "5,6",
    z_global: "3,01",
  },
  {
    profile_section_id: 5560,
    name: "PIPBN33.7X6.3",
    type: "Pipe",
    designation: "PIPBN33.7X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,42",
    d_global: "33,7",
    i_global: "5,36",
    t_global: "6,3",
    z_global: "3,18",
  },
  {
    profile_section_id: 5561,
    name: "PIPBN33.7X7.1",
    type: "Pipe",
    designation: "PIPBN33.7X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,93",
    d_global: "33,7",
    i_global: "5,62",
    t_global: "7,1",
    z_global: "3,34",
  },
  {
    profile_section_id: 5562,
    name: "PIPBN33.7X8",
    type: "Pipe",
    designation: "PIPBN33.7X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,46",
    d_global: "33,7",
    i_global: "5,85",
    t_global: 8,
    z_global: "3,47",
  },
  {
    profile_section_id: 5563,
    name: "PIPBN33.7X8.8",
    type: "Pipe",
    designation: "PIPBN33.7X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,88",
    d_global: "33,7",
    i_global: 6,
    t_global: "8,8",
    z_global: "3,56",
  },
  {
    profile_section_id: 5564,
    name: "PIPBN33.7X10",
    type: "Pipe",
    designation: "PIPBN33.7X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,45",
    d_global: "33,7",
    i_global: "6,16",
    t_global: 10,
    z_global: "3,65",
  },
  {
    profile_section_id: 5565,
    name: "PIPBN35X2.6",
    type: "Pipe",
    designation: "PIPBN35X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,65",
    d_global: 35,
    i_global: "3,5",
    t_global: "2,6",
    z_global: 2,
  },
  {
    profile_section_id: 5566,
    name: "PIPBN35X2.9",
    type: "Pipe",
    designation: "PIPBN35X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,92",
    d_global: 35,
    i_global: "3,8",
    t_global: "2,9",
    z_global: "2,17",
  },
  {
    profile_section_id: 5567,
    name: "PIPBN35X3.2",
    type: "Pipe",
    designation: "PIPBN35X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,2",
    d_global: 35,
    i_global: "4,08",
    t_global: "3,2",
    z_global: "2,33",
  },
  {
    profile_section_id: 5568,
    name: "PIPBN35X3.6",
    type: "Pipe",
    designation: "PIPBN35X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,55",
    d_global: 35,
    i_global: "4,43",
    t_global: "3,6",
    z_global: "2,53",
  },
  {
    profile_section_id: 5569,
    name: "PIPBN35X4",
    type: "Pipe",
    designation: "PIPBN35X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,9",
    d_global: 35,
    i_global: "4,76",
    t_global: 4,
    z_global: "2,72",
  },
  {
    profile_section_id: 5570,
    name: "PIPBN35X4.5",
    type: "Pipe",
    designation: "PIPBN35X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,31",
    d_global: 35,
    i_global: "5,12",
    t_global: "4,5",
    z_global: "2,93",
  },
  {
    profile_section_id: 5571,
    name: "PIPBN35X5",
    type: "Pipe",
    designation: "PIPBN35X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,71",
    d_global: 35,
    i_global: "5,45",
    t_global: 5,
    z_global: "3,11",
  },
  {
    profile_section_id: 5572,
    name: "PIPBN35X5.6",
    type: "Pipe",
    designation: "PIPBN35X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,17",
    d_global: 35,
    i_global: "5,79",
    t_global: "5,6",
    z_global: "3,31",
  },
  {
    profile_section_id: 5573,
    name: "PIPBN35X6.3",
    type: "Pipe",
    designation: "PIPBN35X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,68",
    d_global: 35,
    i_global: "6,13",
    t_global: "6,3",
    z_global: "3,5",
  },
  {
    profile_section_id: 5574,
    name: "PIPBN35X7.1",
    type: "Pipe",
    designation: "PIPBN35X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,22",
    d_global: 35,
    i_global: "6,45",
    t_global: "7,1",
    z_global: "3,68",
  },
  {
    profile_section_id: 5575,
    name: "PIPBN35X8",
    type: "Pipe",
    designation: "PIPBN35X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,79",
    d_global: 35,
    i_global: "6,73",
    t_global: 8,
    z_global: "3,84",
  },
  {
    profile_section_id: 5576,
    name: "PIPBN35X10",
    type: "Pipe",
    designation: "PIPBN35X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,85",
    d_global: 35,
    i_global: "7,12",
    t_global: 10,
    z_global: "4,07",
  },
  {
    profile_section_id: 5577,
    name: "PIPBN36X5.5",
    type: "Pipe",
    designation: "PIPBN36X5.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,27",
    d_global: 36,
    i_global: "6,33",
    t_global: "5,5",
    z_global: "3,52",
  },
  {
    profile_section_id: 5578,
    name: "PIPBN36X8",
    type: "Pipe",
    designation: "PIPBN36X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,04",
    d_global: 36,
    i_global: "7,46",
    t_global: 8,
    z_global: "4,14",
  },
  {
    profile_section_id: 5579,
    name: "PIPBN36X10",
    type: "Pipe",
    designation: "PIPBN36X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,17",
    d_global: 36,
    i_global: "7,92",
    t_global: 10,
    z_global: "4,4",
  },
  {
    profile_section_id: 5580,
    name: "PIPBN38X2.6",
    type: "Pipe",
    designation: "PIPBN38X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "2,89",
    d_global: 38,
    i_global: "4,55",
    t_global: "2,6",
    z_global: "2,4",
  },
  {
    profile_section_id: 5581,
    name: "PIPBN38X2.9",
    type: "Pipe",
    designation: "PIPBN38X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,2",
    d_global: 38,
    i_global: "4,96",
    t_global: "2,9",
    z_global: "2,61",
  },
  {
    profile_section_id: 5582,
    name: "PIPBN38X3.2",
    type: "Pipe",
    designation: "PIPBN38X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,5",
    d_global: 38,
    i_global: "5,34",
    t_global: "3,2",
    z_global: "2,81",
  },
  {
    profile_section_id: 5583,
    name: "PIPBN38X3.6",
    type: "Pipe",
    designation: "PIPBN38X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,89",
    d_global: 38,
    i_global: "5,82",
    t_global: "3,6",
    z_global: "3,06",
  },
  {
    profile_section_id: 5584,
    name: "PIPBN38X4",
    type: "Pipe",
    designation: "PIPBN38X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,27",
    d_global: 38,
    i_global: "6,26",
    t_global: 4,
    z_global: "3,29",
  },
  {
    profile_section_id: 5585,
    name: "PIPBN38X4.5",
    type: "Pipe",
    designation: "PIPBN38X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,74",
    d_global: 38,
    i_global: "6,76",
    t_global: "4,5",
    z_global: "3,56",
  },
  {
    profile_section_id: 5586,
    name: "PIPBN38X5",
    type: "Pipe",
    designation: "PIPBN38X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,18",
    d_global: 38,
    i_global: "7,22",
    t_global: 5,
    z_global: "3,8",
  },
  {
    profile_section_id: 5587,
    name: "PIPBN38X5.6",
    type: "Pipe",
    designation: "PIPBN38X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,7",
    d_global: 38,
    i_global: "7,7",
    t_global: "5,6",
    z_global: "4,05",
  },
  {
    profile_section_id: 5588,
    name: "PIPBN38X6.3",
    type: "Pipe",
    designation: "PIPBN38X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,27",
    d_global: 38,
    i_global: "8,19",
    t_global: "6,3",
    z_global: "4,31",
  },
  {
    profile_section_id: 5589,
    name: "PIPBN38X7.1",
    type: "Pipe",
    designation: "PIPBN38X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,89",
    d_global: 38,
    i_global: "8,66",
    t_global: "7,1",
    z_global: "4,56",
  },
  {
    profile_section_id: 5590,
    name: "PIPBN38X8",
    type: "Pipe",
    designation: "PIPBN38X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,54",
    d_global: 38,
    i_global: "9,09",
    t_global: 8,
    z_global: "4,78",
  },
  {
    profile_section_id: 5591,
    name: "PIPBN38X8.8",
    type: "Pipe",
    designation: "PIPBN38X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,07",
    d_global: 38,
    i_global: "9,38",
    t_global: "8,8",
    z_global: "4,94",
  },
  {
    profile_section_id: 5592,
    name: "PIPBN38X10",
    type: "Pipe",
    designation: "PIPBN38X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,8",
    d_global: 38,
    i_global: "9,72",
    t_global: 10,
    z_global: "5,12",
  },
  {
    profile_section_id: 5593,
    name: "PIPBN38X11",
    type: "Pipe",
    designation: "PIPBN38X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,33",
    d_global: 38,
    i_global: "9,91",
    t_global: 11,
    z_global: "5,22",
  },
  {
    profile_section_id: 5594,
    name: "PIPBN40X6",
    type: "Pipe",
    designation: "PIPBN40X6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,41",
    d_global: 40,
    i_global: "9,55",
    t_global: 6,
    z_global: "4,78",
  },
  {
    profile_section_id: 5595,
    name: "PIPBN40X7.5",
    type: "Pipe",
    designation: "PIPBN40X7.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,66",
    d_global: 40,
    i_global: "10,65",
    t_global: "7,5",
    z_global: "5,32",
  },
  {
    profile_section_id: 5596,
    name: "PIPBN40X10",
    type: "Pipe",
    designation: "PIPBN40X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,42",
    d_global: 40,
    i_global: "11,78",
    t_global: 10,
    z_global: "5,89",
  },
  {
    profile_section_id: 5597,
    name: "PIPBN42.4X2.6",
    type: "Pipe",
    designation: "PIPBN42.4X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,25",
    d_global: "42,4",
    i_global: "6,46",
    t_global: "2,6",
    z_global: "3,05",
  },
  {
    profile_section_id: 5598,
    name: "PIPBN42.4X2.9",
    type: "Pipe",
    designation: "PIPBN42.4X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,6",
    d_global: "42,4",
    i_global: "7,06",
    t_global: "2,9",
    z_global: "3,33",
  },
  {
    profile_section_id: 5599,
    name: "PIPBN42.4X3.2",
    type: "Pipe",
    designation: "PIPBN42.4X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,94",
    d_global: "42,4",
    i_global: "7,62",
    t_global: "3,2",
    z_global: "3,59",
  },
  {
    profile_section_id: 5600,
    name: "PIPBN42.4X3.6",
    type: "Pipe",
    designation: "PIPBN42.4X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,39",
    d_global: "42,4",
    i_global: "8,33",
    t_global: "3,6",
    z_global: "3,93",
  },
  {
    profile_section_id: 5601,
    name: "PIPBN42.4X4",
    type: "Pipe",
    designation: "PIPBN42.4X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,83",
    d_global: "42,4",
    i_global: "8,99",
    t_global: 4,
    z_global: "4,24",
  },
  {
    profile_section_id: 5602,
    name: "PIPBN42.4X4.5",
    type: "Pipe",
    designation: "PIPBN42.4X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,36",
    d_global: "42,4",
    i_global: "9,76",
    t_global: "4,5",
    z_global: "4,6",
  },
  {
    profile_section_id: 5603,
    name: "PIPBN42.4X5",
    type: "Pipe",
    designation: "PIPBN42.4X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,87",
    d_global: "42,4",
    i_global: "10,46",
    t_global: 5,
    z_global: "4,93",
  },
  {
    profile_section_id: 5604,
    name: "PIPBN42.4X5.6",
    type: "Pipe",
    designation: "PIPBN42.4X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,47",
    d_global: "42,4",
    i_global: "11,21",
    t_global: "5,6",
    z_global: "5,29",
  },
  {
    profile_section_id: 5605,
    name: "PIPBN42.4X6.3",
    type: "Pipe",
    designation: "PIPBN42.4X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,14",
    d_global: "42,4",
    i_global: "11,99",
    t_global: "6,3",
    z_global: "5,66",
  },
  {
    profile_section_id: 5606,
    name: "PIPBN42.4X7.1",
    type: "Pipe",
    designation: "PIPBN42.4X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,87",
    d_global: "42,4",
    i_global: "12,76",
    t_global: "7,1",
    z_global: "6,02",
  },
  {
    profile_section_id: 5607,
    name: "PIPBN42.4X8",
    type: "Pipe",
    designation: "PIPBN42.4X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,65",
    d_global: "42,4",
    i_global: "13,48",
    t_global: 8,
    z_global: "6,36",
  },
  {
    profile_section_id: 5608,
    name: "PIPBN42.4X8.8",
    type: "Pipe",
    designation: "PIPBN42.4X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,29",
    d_global: "42,4",
    i_global: "14,01",
    t_global: "8,8",
    z_global: "6,61",
  },
  {
    profile_section_id: 5609,
    name: "PIPBN42.4X10",
    type: "Pipe",
    designation: "PIPBN42.4X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,18",
    d_global: "42,4",
    i_global: "14,63",
    t_global: 10,
    z_global: "6,9",
  },
  {
    profile_section_id: 5610,
    name: "PIPBN42.4X11",
    type: "Pipe",
    designation: "PIPBN42.4X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,85",
    d_global: "42,4",
    i_global: "15,02",
    t_global: 11,
    z_global: "7,08",
  },
  {
    profile_section_id: 5611,
    name: "PIPBN44.5X2.6",
    type: "Pipe",
    designation: "PIPBN44.5X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,42",
    d_global: "44,5",
    i_global: "7,54",
    t_global: "2,6",
    z_global: "3,39",
  },
  {
    profile_section_id: 5612,
    name: "PIPBN44.5X2.9",
    type: "Pipe",
    designation: "PIPBN44.5X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,79",
    d_global: "44,5",
    i_global: "8,24",
    t_global: "2,9",
    z_global: "3,7",
  },
  {
    profile_section_id: 5613,
    name: "PIPBN44.5X3.2",
    type: "Pipe",
    designation: "PIPBN44.5X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,15",
    d_global: "44,5",
    i_global: "8,91",
    t_global: "3,2",
    z_global: 4,
  },
  {
    profile_section_id: 5614,
    name: "PIPBN44.5X3.6",
    type: "Pipe",
    designation: "PIPBN44.5X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,63",
    d_global: "44,5",
    i_global: "9,75",
    t_global: "3,6",
    z_global: "4,38",
  },
  {
    profile_section_id: 5615,
    name: "PIPBN44.5X4",
    type: "Pipe",
    designation: "PIPBN44.5X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,09",
    d_global: "44,5",
    i_global: "10,54",
    t_global: 4,
    z_global: "4,74",
  },
  {
    profile_section_id: 5616,
    name: "PIPBN44.5X4.5",
    type: "Pipe",
    designation: "PIPBN44.5X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,65",
    d_global: "44,5",
    i_global: "11,45",
    t_global: "4,5",
    z_global: "5,15",
  },
  {
    profile_section_id: 5617,
    name: "PIPBN44.5X5",
    type: "Pipe",
    designation: "PIPBN44.5X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,2",
    d_global: "44,5",
    i_global: "12,29",
    t_global: 5,
    z_global: "5,53",
  },
  {
    profile_section_id: 5618,
    name: "PIPBN44.5X5.6",
    type: "Pipe",
    designation: "PIPBN44.5X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,84",
    d_global: "44,5",
    i_global: "13,21",
    t_global: "5,6",
    z_global: "5,94",
  },
  {
    profile_section_id: 5619,
    name: "PIPBN44.5X6.3",
    type: "Pipe",
    designation: "PIPBN44.5X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,56",
    d_global: "44,5",
    i_global: "14,17",
    t_global: "6,3",
    z_global: "6,37",
  },
  {
    profile_section_id: 5620,
    name: "PIPBN44.5X7.1",
    type: "Pipe",
    designation: "PIPBN44.5X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,34",
    d_global: "44,5",
    i_global: "15,11",
    t_global: "7,1",
    z_global: "6,79",
  },
  {
    profile_section_id: 5621,
    name: "PIPBN44.5X8",
    type: "Pipe",
    designation: "PIPBN44.5X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,17",
    d_global: "44,5",
    i_global: "16,01",
    t_global: 8,
    z_global: "7,2",
  },
  {
    profile_section_id: 5622,
    name: "PIPBN44.5X8.8",
    type: "Pipe",
    designation: "PIPBN44.5X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,87",
    d_global: "44,5",
    i_global: "16,68",
    t_global: "8,8",
    z_global: "7,5",
  },
  {
    profile_section_id: 5623,
    name: "PIPBN44.5X10",
    type: "Pipe",
    designation: "PIPBN44.5X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,84",
    d_global: "44,5",
    i_global: "17,48",
    t_global: 10,
    z_global: "7,86",
  },
  {
    profile_section_id: 5624,
    name: "PIPBN44.5X11",
    type: "Pipe",
    designation: "PIPBN44.5X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,58",
    d_global: "44,5",
    i_global: "17,99",
    t_global: 11,
    z_global: "8,09",
  },
  {
    profile_section_id: 5625,
    name: "PIPBN44.5X12.5",
    type: "Pipe",
    designation: "PIPBN44.5X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,57",
    d_global: "44,5",
    i_global: "18,54",
    t_global: "12,5",
    z_global: "8,33",
  },
  {
    profile_section_id: 5626,
    name: "PIPBN45X6.5",
    type: "Pipe",
    designation: "PIPBN45X6.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,86",
    d_global: 45,
    i_global: "14,98",
    t_global: "6,5",
    z_global: "6,66",
  },
  {
    profile_section_id: 5627,
    name: "PIPBN45X8.5",
    type: "Pipe",
    designation: "PIPBN45X8.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,75",
    d_global: 45,
    i_global: "17,11",
    t_global: "8,5",
    z_global: "7,61",
  },
  {
    profile_section_id: 5628,
    name: "PIPBN45X12.5",
    type: "Pipe",
    designation: "PIPBN45X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,76",
    d_global: 45,
    i_global: "19,34",
    t_global: "12,5",
    z_global: "8,6",
  },
  {
    profile_section_id: 5629,
    name: "PIPBN48.3X2.6",
    type: "Pipe",
    designation: "PIPBN48.3X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,73",
    d_global: "48,3",
    i_global: "9,78",
    t_global: "2,6",
    z_global: "4,05",
  },
  {
    profile_section_id: 5630,
    name: "PIPBN48.3X2.9",
    type: "Pipe",
    designation: "PIPBN48.3X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,14",
    d_global: "48,3",
    i_global: "10,7",
    t_global: "2,9",
    z_global: "4,43",
  },
  {
    profile_section_id: 5631,
    name: "PIPBN48.3X3.2",
    type: "Pipe",
    designation: "PIPBN48.3X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,53",
    d_global: "48,3",
    i_global: "11,59",
    t_global: "3,2",
    z_global: "4,8",
  },
  {
    profile_section_id: 5632,
    name: "PIPBN48.3X3.6",
    type: "Pipe",
    designation: "PIPBN48.3X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,06",
    d_global: "48,3",
    i_global: "12,71",
    t_global: "3,6",
    z_global: "5,26",
  },
  {
    profile_section_id: 5633,
    name: "PIPBN48.3X4",
    type: "Pipe",
    designation: "PIPBN48.3X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,57",
    d_global: "48,3",
    i_global: "13,77",
    t_global: 4,
    z_global: "5,7",
  },
  {
    profile_section_id: 5634,
    name: "PIPBN48.3X4.5",
    type: "Pipe",
    designation: "PIPBN48.3X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,19",
    d_global: "48,3",
    i_global: "15,01",
    t_global: "4,5",
    z_global: "6,21",
  },
  {
    profile_section_id: 5635,
    name: "PIPBN48.3X5",
    type: "Pipe",
    designation: "PIPBN48.3X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,8",
    d_global: "48,3",
    i_global: "16,15",
    t_global: 5,
    z_global: "6,69",
  },
  {
    profile_section_id: 5636,
    name: "PIPBN48.3X5.6",
    type: "Pipe",
    designation: "PIPBN48.3X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,51",
    d_global: "48,3",
    i_global: "17,42",
    t_global: "5,6",
    z_global: "7,21",
  },
  {
    profile_section_id: 5637,
    name: "PIPBN48.3X6.3",
    type: "Pipe",
    designation: "PIPBN48.3X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,31",
    d_global: "48,3",
    i_global: "18,74",
    t_global: "6,3",
    z_global: "7,76",
  },
  {
    profile_section_id: 5638,
    name: "PIPBN48.3X7.1",
    type: "Pipe",
    designation: "PIPBN48.3X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,19",
    d_global: "48,3",
    i_global: "20,08",
    t_global: "7,1",
    z_global: "8,31",
  },
  {
    profile_section_id: 5639,
    name: "PIPBN48.3X8",
    type: "Pipe",
    designation: "PIPBN48.3X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,13",
    d_global: "48,3",
    i_global: "21,37",
    t_global: 8,
    z_global: "8,85",
  },
  {
    profile_section_id: 5640,
    name: "PIPBN48.3X8.8",
    type: "Pipe",
    designation: "PIPBN48.3X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,92",
    d_global: "48,3",
    i_global: "22,36",
    t_global: "8,8",
    z_global: "9,26",
  },
  {
    profile_section_id: 5641,
    name: "PIPBN48.3X10",
    type: "Pipe",
    designation: "PIPBN48.3X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,03",
    d_global: "48,3",
    i_global: "23,57",
    t_global: 10,
    z_global: "9,76",
  },
  {
    profile_section_id: 5642,
    name: "PIPBN48.3X11",
    type: "Pipe",
    designation: "PIPBN48.3X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,89",
    d_global: "48,3",
    i_global: "24,37",
    t_global: 11,
    z_global: "10,09",
  },
  {
    profile_section_id: 5643,
    name: "PIPBN48.3X12.5",
    type: "Pipe",
    designation: "PIPBN48.3X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,06",
    d_global: "48,3",
    i_global: "25,27",
    t_global: "12,5",
    z_global: "10,46",
  },
  {
    profile_section_id: 5644,
    name: "PIPBN50X7",
    type: "Pipe",
    designation: "PIPBN50X7",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,46",
    d_global: 50,
    i_global: "22,43",
    t_global: 7,
    z_global: "8,97",
  },
  {
    profile_section_id: 5645,
    name: "PIPBN50X9",
    type: "Pipe",
    designation: "PIPBN50X9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,59",
    d_global: 50,
    i_global: "25,53",
    t_global: 9,
    z_global: "10,21",
  },
  {
    profile_section_id: 5646,
    name: "PIPBN50X12.5",
    type: "Pipe",
    designation: "PIPBN50X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,73",
    d_global: 50,
    i_global: "28,76",
    t_global: "12,5",
    z_global: "11,51",
  },
  {
    profile_section_id: 5647,
    name: "PIPBN51X2.6",
    type: "Pipe",
    designation: "PIPBN51X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "3,95",
    d_global: 51,
    i_global: "11,61",
    t_global: "2,6",
    z_global: "4,55",
  },
  {
    profile_section_id: 5648,
    name: "PIPBN51X2.9",
    type: "Pipe",
    designation: "PIPBN51X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,38",
    d_global: 51,
    i_global: "12,72",
    t_global: "2,9",
    z_global: "4,99",
  },
  {
    profile_section_id: 5649,
    name: "PIPBN51X3.2",
    type: "Pipe",
    designation: "PIPBN51X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,81",
    d_global: 51,
    i_global: "13,79",
    t_global: "3,2",
    z_global: "5,41",
  },
  {
    profile_section_id: 5650,
    name: "PIPBN51X3.6",
    type: "Pipe",
    designation: "PIPBN51X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,36",
    d_global: 51,
    i_global: "15,14",
    t_global: "3,6",
    z_global: "5,94",
  },
  {
    profile_section_id: 5651,
    name: "PIPBN51X4",
    type: "Pipe",
    designation: "PIPBN51X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,91",
    d_global: 51,
    i_global: "16,43",
    t_global: 4,
    z_global: "6,44",
  },
  {
    profile_section_id: 5652,
    name: "PIPBN51X4.5",
    type: "Pipe",
    designation: "PIPBN51X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,57",
    d_global: 51,
    i_global: "17,93",
    t_global: "4,5",
    z_global: "7,03",
  },
  {
    profile_section_id: 5653,
    name: "PIPBN51X5",
    type: "Pipe",
    designation: "PIPBN51X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,23",
    d_global: 51,
    i_global: "19,34",
    t_global: 5,
    z_global: "7,58",
  },
  {
    profile_section_id: 5654,
    name: "PIPBN51X5.6",
    type: "Pipe",
    designation: "PIPBN51X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,99",
    d_global: 51,
    i_global: "20,89",
    t_global: "5,6",
    z_global: "8,19",
  },
  {
    profile_section_id: 5655,
    name: "PIPBN51X6.3",
    type: "Pipe",
    designation: "PIPBN51X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,85",
    d_global: 51,
    i_global: "22,54",
    t_global: "6,3",
    z_global: "8,84",
  },
  {
    profile_section_id: 5656,
    name: "PIPBN51X7.1",
    type: "Pipe",
    designation: "PIPBN51X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,79",
    d_global: 51,
    i_global: "24,21",
    t_global: "7,1",
    z_global: "9,49",
  },
  {
    profile_section_id: 5657,
    name: "PIPBN51X8",
    type: "Pipe",
    designation: "PIPBN51X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,81",
    d_global: 51,
    i_global: "25,84",
    t_global: 8,
    z_global: "10,13",
  },
  {
    profile_section_id: 5658,
    name: "PIPBN51X8.8",
    type: "Pipe",
    designation: "PIPBN51X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,67",
    d_global: 51,
    i_global: "27,1",
    t_global: "8,8",
    z_global: "10,63",
  },
  {
    profile_section_id: 5659,
    name: "PIPBN51X10",
    type: "Pipe",
    designation: "PIPBN51X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,88",
    d_global: 51,
    i_global: "28,68",
    t_global: 10,
    z_global: "11,24",
  },
  {
    profile_section_id: 5660,
    name: "PIPBN51X11",
    type: "Pipe",
    designation: "PIPBN51X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "13,82",
    d_global: 51,
    i_global: "29,74",
    t_global: 11,
    z_global: "11,66",
  },
  {
    profile_section_id: 5661,
    name: "PIPBN51X12.5",
    type: "Pipe",
    designation: "PIPBN51X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,12",
    d_global: 51,
    i_global: "30,96",
    t_global: "12,5",
    z_global: "12,14",
  },
  {
    profile_section_id: 5662,
    name: "PIPBN51X14.2",
    type: "Pipe",
    designation: "PIPBN51X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,42",
    d_global: 51,
    i_global: "31,93",
    t_global: "14,2",
    z_global: "12,52",
  },
  {
    profile_section_id: 5663,
    name: "PIPBN54X2.6",
    type: "Pipe",
    designation: "PIPBN54X2.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,2",
    d_global: 54,
    i_global: "13,9",
    t_global: "2,6",
    z_global: "5,15",
  },
  {
    profile_section_id: 5664,
    name: "PIPBN54X2.9",
    type: "Pipe",
    designation: "PIPBN54X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,66",
    d_global: 54,
    i_global: "15,24",
    t_global: "2,9",
    z_global: "5,65",
  },
  {
    profile_section_id: 5665,
    name: "PIPBN54X3.2",
    type: "Pipe",
    designation: "PIPBN54X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,11",
    d_global: 54,
    i_global: "16,54",
    t_global: "3,2",
    z_global: "6,13",
  },
  {
    profile_section_id: 5666,
    name: "PIPBN54X3.6",
    type: "Pipe",
    designation: "PIPBN54X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,7",
    d_global: 54,
    i_global: "18,19",
    t_global: "3,6",
    z_global: "6,74",
  },
  {
    profile_section_id: 5667,
    name: "PIPBN54X4",
    type: "Pipe",
    designation: "PIPBN54X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,28",
    d_global: 54,
    i_global: "19,76",
    t_global: 4,
    z_global: "7,32",
  },
  {
    profile_section_id: 5668,
    name: "PIPBN54X4.5",
    type: "Pipe",
    designation: "PIPBN54X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 7,
    d_global: 54,
    i_global: "21,61",
    t_global: 4,
    z_global: 8,
  },
  {
    profile_section_id: 5669,
    name: "PIPBN54X5",
    type: "Pipe",
    designation: "PIPBN54X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,7",
    d_global: 54,
    i_global: "23,34",
    t_global: 5,
    z_global: "8,64",
  },
  {
    profile_section_id: 5670,
    name: "PIPBN54X5.6",
    type: "Pipe",
    designation: "PIPBN54X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,51",
    d_global: 54,
    i_global: "25,27",
    t_global: "5,6",
    z_global: "9,36",
  },
  {
    profile_section_id: 5671,
    name: "PIPBN54X6.3",
    type: "Pipe",
    designation: "PIPBN54X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,44",
    d_global: 54,
    i_global: "27,32",
    t_global: "6,3",
    z_global: "10,12",
  },
  {
    profile_section_id: 5672,
    name: "PIPBN54X7.1",
    type: "Pipe",
    designation: "PIPBN54X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,46",
    d_global: 54,
    i_global: "29,42",
    t_global: "7,1",
    z_global: "10,9",
  },
  {
    profile_section_id: 5673,
    name: "PIPBN54X8",
    type: "Pipe",
    designation: "PIPBN54X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,56",
    d_global: 54,
    i_global: "31,5",
    t_global: 8,
    z_global: "11,67",
  },
  {
    profile_section_id: 5674,
    name: "PIPBN54X8.8",
    type: "Pipe",
    designation: "PIPBN54X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,5",
    d_global: 54,
    i_global: "33,12",
    t_global: "8,8",
    z_global: "12,27",
  },
  {
    profile_section_id: 5675,
    name: "PIPBN54X10",
    type: "Pipe",
    designation: "PIPBN54X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "13,82",
    d_global: 54,
    i_global: "35,18",
    t_global: 10,
    z_global: "13,03",
  },
  {
    profile_section_id: 5676,
    name: "PIPBN54X11",
    type: "Pipe",
    designation: "PIPBN54X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,86",
    d_global: 54,
    i_global: "36,59",
    t_global: 11,
    z_global: "13,55",
  },
  {
    profile_section_id: 5677,
    name: "PIPBN54X12.5",
    type: "Pipe",
    designation: "PIPBN54X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,3",
    d_global: 54,
    i_global: "38,27",
    t_global: "12,5",
    z_global: "14,17",
  },
  {
    profile_section_id: 5678,
    name: "PIPBN54X14.2",
    type: "Pipe",
    designation: "PIPBN54X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "17,76",
    d_global: 54,
    i_global: "39,63",
    t_global: "14,2",
    z_global: "14,68",
  },
  {
    profile_section_id: 5679,
    name: "PIPBN56X8",
    type: "Pipe",
    designation: "PIPBN56X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,06",
    d_global: 56,
    i_global: "35,71",
    t_global: 8,
    z_global: "12,75",
  },
  {
    profile_section_id: 5680,
    name: "PIPBN56X10",
    type: "Pipe",
    designation: "PIPBN56X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,45",
    d_global: 56,
    i_global: "40,03",
    t_global: 10,
    z_global: "14,3",
  },
  {
    profile_section_id: 5681,
    name: "PIPBN56X14",
    type: "Pipe",
    designation: "PIPBN56X14",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,47",
    d_global: 56,
    i_global: "45,26",
    t_global: 14,
    z_global: "16,16",
  },
  {
    profile_section_id: 5682,
    name: "PIPBN57X2.9",
    type: "Pipe",
    designation: "PIPBN57X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "4,93",
    d_global: 57,
    i_global: "18,08",
    t_global: "2,9",
    z_global: "6,34",
  },
  {
    profile_section_id: 5683,
    name: "PIPBN57X3.2",
    type: "Pipe",
    designation: "PIPBN57X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,41",
    d_global: 57,
    i_global: "19,64",
    t_global: "3,2",
    z_global: "6,89",
  },
  {
    profile_section_id: 5684,
    name: "PIPBN57X3.6",
    type: "Pipe",
    designation: "PIPBN57X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,04",
    d_global: 57,
    i_global: "21,63",
    t_global: "3,6",
    z_global: "7,59",
  },
  {
    profile_section_id: 5685,
    name: "PIPBN57X4",
    type: "Pipe",
    designation: "PIPBN57X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,66",
    d_global: 57,
    i_global: "23,52",
    t_global: 4,
    z_global: "8,25",
  },
  {
    profile_section_id: 5686,
    name: "PIPBN57X4.5",
    type: "Pipe",
    designation: "PIPBN57X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,42",
    d_global: 57,
    i_global: "25,76",
    t_global: "4,5",
    z_global: "9,04",
  },
  {
    profile_section_id: 5687,
    name: "PIPBN57X5",
    type: "Pipe",
    designation: "PIPBN57X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,17",
    d_global: 57,
    i_global: "27,86",
    t_global: 5,
    z_global: "9,78",
  },
  {
    profile_section_id: 5688,
    name: "PIPBN57X5.6",
    type: "Pipe",
    designation: "PIPBN57X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,04",
    d_global: 57,
    i_global: "30,22",
    t_global: "5,6",
    z_global: "10,6",
  },
  {
    profile_section_id: 5689,
    name: "PIPBN57X6.3",
    type: "Pipe",
    designation: "PIPBN57X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,03",
    d_global: 57,
    i_global: "32,74",
    t_global: "6,3",
    z_global: "11,49",
  },
  {
    profile_section_id: 5690,
    name: "PIPBN57X7.1",
    type: "Pipe",
    designation: "PIPBN57X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,13",
    d_global: 57,
    i_global: "35,34",
    t_global: "7,1",
    z_global: "12,4",
  },
  {
    profile_section_id: 5691,
    name: "PIPBN57X8",
    type: "Pipe",
    designation: "PIPBN57X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,32",
    d_global: 57,
    i_global: "37,95",
    t_global: 8,
    z_global: "13,31",
  },
  {
    profile_section_id: 5692,
    name: "PIPBN57X8.8",
    type: "Pipe",
    designation: "PIPBN57X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "13,33",
    d_global: 57,
    i_global: "39,99",
    t_global: "8,8",
    z_global: "14,03",
  },
  {
    profile_section_id: 5693,
    name: "PIPBN57X10",
    type: "Pipe",
    designation: "PIPBN57X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,77",
    d_global: 57,
    i_global: "42,62",
    t_global: 10,
    z_global: "14,95",
  },
  {
    profile_section_id: 5694,
    name: "PIPBN57X11",
    type: "Pipe",
    designation: "PIPBN57X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,9",
    d_global: 57,
    i_global: "44,45",
    t_global: 11,
    z_global: "15,6",
  },
  {
    profile_section_id: 5695,
    name: "PIPBN57X12.5",
    type: "Pipe",
    designation: "PIPBN57X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "17,48",
    d_global: 57,
    i_global: "46,67",
    t_global: "12,5",
    z_global: "16,38",
  },
  {
    profile_section_id: 5696,
    name: "PIPBN57X14.2",
    type: "Pipe",
    designation: "PIPBN57X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "19,09",
    d_global: 57,
    i_global: "48,53",
    t_global: "14,2",
    z_global: "17,03",
  },
  {
    profile_section_id: 5697,
    name: "PIPBN57X16",
    type: "Pipe",
    designation: "PIPBN57X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,61",
    d_global: 57,
    i_global: "49,9",
    t_global: 16,
    z_global: "17,51",
  },
  {
    profile_section_id: 5698,
    name: "PIPBN60.3X2.9",
    type: "Pipe",
    designation: "PIPBN60.3X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,23",
    d_global: "60,3",
    i_global: "21,59",
    t_global: "2,9",
    z_global: "7,16",
  },
  {
    profile_section_id: 5699,
    name: "PIPBN60.3X3.2",
    type: "Pipe",
    designation: "PIPBN60.3X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,74",
    d_global: "60,3",
    i_global: "23,47",
    t_global: "3,2",
    z_global: "7,78",
  },
  {
    profile_section_id: 5700,
    name: "PIPBN60.3X3.6",
    type: "Pipe",
    designation: "PIPBN60.3X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,41",
    d_global: "60,3",
    i_global: "25,87",
    t_global: "3,6",
    z_global: "8,58",
  },
  {
    profile_section_id: 5701,
    name: "PIPBN60.3X4",
    type: "Pipe",
    designation: "PIPBN60.3X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,07",
    d_global: "60,3",
    i_global: "28,17",
    t_global: 4,
    z_global: "9,34",
  },
  {
    profile_section_id: 5702,
    name: "PIPBN60.3X4.5",
    type: "Pipe",
    designation: "PIPBN60.3X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,89",
    d_global: "60,3",
    i_global: "30,9",
    t_global: "4,5",
    z_global: "10,25",
  },
  {
    profile_section_id: 5703,
    name: "PIPBN60.3X5",
    type: "Pipe",
    designation: "PIPBN60.3X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,69",
    d_global: "60,3",
    i_global: "33,48",
    t_global: 5,
    z_global: "11,1",
  },
  {
    profile_section_id: 5704,
    name: "PIPBN60.3X5.6",
    type: "Pipe",
    designation: "PIPBN60.3X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,62",
    d_global: "60,3",
    i_global: "36,37",
    t_global: "5,6",
    z_global: "12,06",
  },
  {
    profile_section_id: 5705,
    name: "PIPBN60.3X6.3",
    type: "Pipe",
    designation: "PIPBN60.3X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,69",
    d_global: "60,3",
    i_global: "39,49",
    t_global: "6,3",
    z_global: "13,1",
  },
  {
    profile_section_id: 5706,
    name: "PIPBN60.3X7.1",
    type: "Pipe",
    designation: "PIPBN60.3X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,87",
    d_global: "60,3",
    i_global: "42,73",
    t_global: "7,1",
    z_global: "14,17",
  },
  {
    profile_section_id: 5707,
    name: "PIPBN60.3X8",
    type: "Pipe",
    designation: "PIPBN60.3X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "13,14",
    d_global: "60,3",
    i_global: "45,99",
    t_global: 8,
    z_global: "15,26",
  },
  {
    profile_section_id: 5708,
    name: "PIPBN60.3X8.8",
    type: "Pipe",
    designation: "PIPBN60.3X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,24",
    d_global: "60,3",
    i_global: "48,58",
    t_global: "8,8",
    z_global: "16,11",
  },
  {
    profile_section_id: 5709,
    name: "PIPBN60.3X10",
    type: "Pipe",
    designation: "PIPBN60.3X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,8",
    d_global: "60,3",
    i_global: "51,95",
    t_global: 10,
    z_global: "17,23",
  },
  {
    profile_section_id: 5710,
    name: "PIPBN60.3X11",
    type: "Pipe",
    designation: "PIPBN60.3X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "17,04",
    d_global: "60,3",
    i_global: "54,34",
    t_global: 11,
    z_global: "18,02",
  },
  {
    profile_section_id: 5711,
    name: "PIPBN60.3X12.5",
    type: "Pipe",
    designation: "PIPBN60.3X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,77",
    d_global: "60,3",
    i_global: "57,28",
    t_global: "12,5",
    z_global: 19,
  },
  {
    profile_section_id: 5712,
    name: "PIPBN60.3X14.2",
    type: "Pipe",
    designation: "PIPBN60.3X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,57",
    d_global: "60,3",
    i_global: "59,82",
    t_global: "14,2",
    z_global: "19,84",
  },
  {
    profile_section_id: 5713,
    name: "PIPBN60.3X16",
    type: "Pipe",
    designation: "PIPBN60.3X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "22,27",
    d_global: "60,3",
    i_global: "61,75",
    t_global: 16,
    z_global: "20,48",
  },
  {
    profile_section_id: 5714,
    name: "PIPBN60.3X17.5",
    type: "Pipe",
    designation: "PIPBN60.3X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,53",
    d_global: "60,3",
    i_global: "62,89",
    t_global: "17,5",
    z_global: "20,86",
  },
  {
    profile_section_id: 5715,
    name: "PIPBN63X6.5",
    type: "Pipe",
    designation: "PIPBN63X6.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,54",
    d_global: 63,
    i_global: "46,65",
    t_global: "6,5",
    z_global: "14,81",
  },
  {
    profile_section_id: 5716,
    name: "PIPBN63X11.5",
    type: "Pipe",
    designation: "PIPBN63X11.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,61",
    d_global: 63,
    i_global: "64,76",
    t_global: "11,5",
    z_global: "20,56",
  },
  {
    profile_section_id: 5717,
    name: "PIPBN63X15.5",
    type: "Pipe",
    designation: "PIPBN63X15.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,13",
    d_global: 63,
    i_global: "72,18",
    t_global: "15,5",
    z_global: "22,91",
  },
  {
    profile_section_id: 5718,
    name: "PIPBN63.5X2.9",
    type: "Pipe",
    designation: "PIPBN63.5X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "5,52",
    d_global: "63,5",
    i_global: "25,4",
    t_global: "2,9",
    z_global: 8,
  },
  {
    profile_section_id: 5719,
    name: "PIPBN63.5X3.2",
    type: "Pipe",
    designation: "PIPBN63.5X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,06",
    d_global: "63,5",
    i_global: "27,63",
    t_global: "3,2",
    z_global: "8,7",
  },
  {
    profile_section_id: 5720,
    name: "PIPBN63.5X3.6",
    type: "Pipe",
    designation: "PIPBN63.5X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,77",
    d_global: "63,5",
    i_global: "30,49",
    t_global: "3,6",
    z_global: "9,6",
  },
  {
    profile_section_id: 5721,
    name: "PIPBN63.5X4",
    type: "Pipe",
    designation: "PIPBN63.5X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,48",
    d_global: "63,5",
    i_global: "33,24",
    t_global: 4,
    z_global: "10,47",
  },
  {
    profile_section_id: 5722,
    name: "PIPBN63.5X4.5",
    type: "Pipe",
    designation: "PIPBN63.5X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,34",
    d_global: "63,5",
    i_global: "36,51",
    t_global: "4,5",
    z_global: "11,5",
  },
  {
    profile_section_id: 5723,
    name: "PIPBN63.5X5",
    type: "Pipe",
    designation: "PIPBN63.5X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,19",
    d_global: "63,5",
    i_global: "39,6",
    t_global: 5,
    z_global: "12,47",
  },
  {
    profile_section_id: 5724,
    name: "PIPBN63.5X5.6",
    type: "Pipe",
    designation: "PIPBN63.5X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,19",
    d_global: "63,5",
    i_global: "43,09",
    t_global: "5,6",
    z_global: "13,57",
  },
  {
    profile_section_id: 5725,
    name: "PIPBN63.5X6.3",
    type: "Pipe",
    designation: "PIPBN63.5X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,32",
    d_global: "63,5",
    i_global: "46,86",
    t_global: "6,3",
    z_global: "14,76",
  },
  {
    profile_section_id: 5726,
    name: "PIPBN63.5X7.1",
    type: "Pipe",
    designation: "PIPBN63.5X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,58",
    d_global: "63,5",
    i_global: "50,81",
    t_global: "7,1",
    z_global: 16,
  },
  {
    profile_section_id: 5727,
    name: "PIPBN63.5X8",
    type: "Pipe",
    designation: "PIPBN63.5X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "13,95",
    d_global: "63,5",
    i_global: "54,82",
    t_global: 8,
    z_global: "17,27",
  },
  {
    profile_section_id: 5728,
    name: "PIPBN63.5X8.8",
    type: "Pipe",
    designation: "PIPBN63.5X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,12",
    d_global: "63,5",
    i_global: "58,02",
    t_global: "8,8",
    z_global: "18,27",
  },
  {
    profile_section_id: 5729,
    name: "PIPBN63.5X10",
    type: "Pipe",
    designation: "PIPBN63.5X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,81",
    d_global: "63,5",
    i_global: "62,23",
    t_global: 10,
    z_global: "19,6",
  },
  {
    profile_section_id: 5730,
    name: "PIPBN63.5X11",
    type: "Pipe",
    designation: "PIPBN63.5X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,14",
    d_global: "63,5",
    i_global: "65,25",
    t_global: 11,
    z_global: "20,55",
  },
  {
    profile_section_id: 5731,
    name: "PIPBN63.5X12.5",
    type: "Pipe",
    designation: "PIPBN63.5X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,03",
    d_global: "63,5",
    i_global: "69,03",
    t_global: "12,5",
    z_global: "21,74",
  },
  {
    profile_section_id: 5732,
    name: "PIPBN63.5X14.2",
    type: "Pipe",
    designation: "PIPBN63.5X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "21,99",
    d_global: "63,5",
    i_global: "72,36",
    t_global: "14,2",
    z_global: "22,79",
  },
  {
    profile_section_id: 5733,
    name: "PIPBN63.5X16",
    type: "Pipe",
    designation: "PIPBN63.5X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,88",
    d_global: "63,5",
    i_global: "74,98",
    t_global: 16,
    z_global: "23,61",
  },
  {
    profile_section_id: 5734,
    name: "PIPBN63.5X17.5",
    type: "Pipe",
    designation: "PIPBN63.5X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "25,29",
    d_global: "63,5",
    i_global: "76,57",
    t_global: "17,5",
    z_global: "24,12",
  },
  {
    profile_section_id: 5735,
    name: "PIPBN70X2.9",
    type: "Pipe",
    designation: "PIPBN70X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,11",
    d_global: 70,
    i_global: "34,47",
    t_global: "2,9",
    z_global: "9,85",
  },
  {
    profile_section_id: 5736,
    name: "PIPBN70X3.2",
    type: "Pipe",
    designation: "PIPBN70X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,72",
    d_global: 70,
    i_global: "37,54",
    t_global: "3,2",
    z_global: "10,73",
  },
  {
    profile_section_id: 5737,
    name: "PIPBN70X3.6",
    type: "Pipe",
    designation: "PIPBN70X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,51",
    d_global: 70,
    i_global: "41,51",
    t_global: "3,6",
    z_global: "11,86",
  },
  {
    profile_section_id: 5738,
    name: "PIPBN70X4",
    type: "Pipe",
    designation: "PIPBN70X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,29",
    d_global: 70,
    i_global: "45,33",
    t_global: 4,
    z_global: "12,95",
  },
  {
    profile_section_id: 5739,
    name: "PIPBN70X4.5",
    type: "Pipe",
    designation: "PIPBN70X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,26",
    d_global: 70,
    i_global: "49,89",
    t_global: "4,5",
    z_global: "14,26",
  },
  {
    profile_section_id: 5740,
    name: "PIPBN70X5",
    type: "Pipe",
    designation: "PIPBN70X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,21",
    d_global: 70,
    i_global: "54,24",
    t_global: 5,
    z_global: "15,5",
  },
  {
    profile_section_id: 5741,
    name: "PIPBN70X5.6",
    type: "Pipe",
    designation: "PIPBN70X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,33",
    d_global: 70,
    i_global: "59,18",
    t_global: "5,6",
    z_global: "16,91",
  },
  {
    profile_section_id: 5742,
    name: "PIPBN70X6.3",
    type: "Pipe",
    designation: "PIPBN70X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,61",
    d_global: 70,
    i_global: "64,57",
    t_global: "6,3",
    z_global: "18,45",
  },
  {
    profile_section_id: 5743,
    name: "PIPBN70X7.1",
    type: "Pipe",
    designation: "PIPBN70X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,03",
    d_global: 70,
    i_global: "70,27",
    t_global: "7,1",
    z_global: "20,08",
  },
  {
    profile_section_id: 5744,
    name: "PIPBN70X8",
    type: "Pipe",
    designation: "PIPBN70X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,58",
    d_global: 70,
    i_global: "76,12",
    t_global: 8,
    z_global: "21,75",
  },
  {
    profile_section_id: 5745,
    name: "PIPBN70X8.8",
    type: "Pipe",
    designation: "PIPBN70X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,92",
    d_global: 70,
    i_global: "80,85",
    t_global: "8,8",
    z_global: "23,1",
  },
  {
    profile_section_id: 5746,
    name: "PIPBN70X10",
    type: "Pipe",
    designation: "PIPBN70X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,85",
    d_global: 70,
    i_global: "87,18",
    t_global: 10,
    z_global: "24,91",
  },
  {
    profile_section_id: 5747,
    name: "PIPBN70X11",
    type: "Pipe",
    designation: "PIPBN70X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,39",
    d_global: 70,
    i_global: "91,8",
    t_global: 11,
    z_global: "26,23",
  },
  {
    profile_section_id: 5748,
    name: "PIPBN70X12.5",
    type: "Pipe",
    designation: "PIPBN70X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "22,58",
    d_global: 70,
    i_global: "97,73",
    t_global: "12,5",
    z_global: "27,92",
  },
  {
    profile_section_id: 5749,
    name: "PIPBN70X14.2",
    type: "Pipe",
    designation: "PIPBN70X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "24,89",
    d_global: 70,
    i_global: "103,16",
    t_global: "14,2",
    z_global: "29,47",
  },
  {
    profile_section_id: 5750,
    name: "PIPBN70X16",
    type: "Pipe",
    designation: "PIPBN70X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "27,14",
    d_global: 70,
    i_global: "107,62",
    t_global: 16,
    z_global: "30,75",
  },
  {
    profile_section_id: 5751,
    name: "PIPBN70X17.5",
    type: "Pipe",
    designation: "PIPBN70X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "28,86",
    d_global: 70,
    i_global: "110,49",
    t_global: "17,5",
    z_global: "31,57",
  },
  {
    profile_section_id: 5752,
    name: "PIPBN70X20",
    type: "Pipe",
    designation: "PIPBN70X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "31,42",
    d_global: 70,
    i_global: "113,88",
    t_global: 20,
    z_global: "32,54",
  },
  {
    profile_section_id: 5753,
    name: "PIPBN71X7.5",
    type: "Pipe",
    designation: "PIPBN71X7.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,96",
    d_global: 71,
    i_global: "76,46",
    t_global: "7,5",
    z_global: "21,54",
  },
  {
    profile_section_id: 5754,
    name: "PIPBN71X13",
    type: "Pipe",
    designation: "PIPBN71X13",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,69",
    d_global: 71,
    i_global: "104,61",
    t_global: 13,
    z_global: "29,47",
  },
  {
    profile_section_id: 5755,
    name: "PIPBN71X17.5",
    type: "Pipe",
    designation: "PIPBN71X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "29,41",
    d_global: 71,
    i_global: "116,5",
    t_global: "17,5",
    z_global: "32,81",
  },
  {
    profile_section_id: 5756,
    name: "PIPBN73X5",
    type: "Pipe",
    designation: "PIPBN73X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,68",
    d_global: 73,
    i_global: "62,07",
    t_global: 5,
    z_global: "17,01",
  },
  {
    profile_section_id: 5757,
    name: "PIPBN73X7.1",
    type: "Pipe",
    designation: "PIPBN73X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,7",
    d_global: 73,
    i_global: "80,72",
    t_global: "7,1",
    z_global: "22,11",
  },
  {
    profile_section_id: 5758,
    name: "PIPBN73X14.2",
    type: "Pipe",
    designation: "PIPBN73X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "26,23",
    d_global: 73,
    i_global: "119,98",
    t_global: "14,2",
    z_global: "32,87",
  },
  {
    profile_section_id: 5759,
    name: "PIPBN75X7.5",
    type: "Pipe",
    designation: "PIPBN75X7.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,9",
    d_global: 75,
    i_global: "91,7",
    t_global: "7,5",
    z_global: "24,45",
  },
  {
    profile_section_id: 5760,
    name: "PIPBN75X12.5",
    type: "Pipe",
    designation: "PIPBN75X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "24,54",
    d_global: 75,
    i_global: "124,64",
    t_global: "12,5",
    z_global: "33,24",
  },
  {
    profile_section_id: 5761,
    name: "PIPBN75X17.5",
    type: "Pipe",
    designation: "PIPBN75X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "31,61",
    d_global: 75,
    i_global: "142,75",
    t_global: "17,5",
    z_global: "38,07",
  },
  {
    profile_section_id: 5762,
    name: "PIPBN76.1X2.9",
    type: "Pipe",
    designation: "PIPBN76.1X2.9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "6,67",
    d_global: "76,1",
    i_global: "44,74",
    t_global: "2,9",
    z_global: "11,76",
  },
  {
    profile_section_id: 5763,
    name: "PIPBN76.1X3.2",
    type: "Pipe",
    designation: "PIPBN76.1X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,33",
    d_global: "76,1",
    i_global: "48,78",
    t_global: "3,2",
    z_global: "12,82",
  },
  {
    profile_section_id: 5764,
    name: "PIPBN76.1X3.6",
    type: "Pipe",
    designation: "PIPBN76.1X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,2",
    d_global: "76,1",
    i_global: "54,01",
    t_global: "3,6",
    z_global: "14,19",
  },
  {
    profile_section_id: 5765,
    name: "PIPBN76.1X4",
    type: "Pipe",
    designation: "PIPBN76.1X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,06",
    d_global: "76,1",
    i_global: "59,05",
    t_global: 4,
    z_global: "15,52",
  },
  {
    profile_section_id: 5766,
    name: "PIPBN76.1X4.5",
    type: "Pipe",
    designation: "PIPBN76.1X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,12",
    d_global: "76,1",
    i_global: "65,12",
    t_global: "4,5",
    z_global: "17,11",
  },
  {
    profile_section_id: 5767,
    name: "PIPBN76.1X5",
    type: "Pipe",
    designation: "PIPBN76.1X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,17",
    d_global: "76,1",
    i_global: "70,92",
    t_global: 5,
    z_global: "18,64",
  },
  {
    profile_section_id: 5768,
    name: "PIPBN76.1X5.6",
    type: "Pipe",
    designation: "PIPBN76.1X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,4",
    d_global: "76,1",
    i_global: "77,54",
    t_global: "5,6",
    z_global: "20,38",
  },
  {
    profile_section_id: 5769,
    name: "PIPBN76.1X6.3",
    type: "Pipe",
    designation: "PIPBN76.1X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "13,81",
    d_global: "76,1",
    i_global: "84,82",
    t_global: "6,3",
    z_global: "22,29",
  },
  {
    profile_section_id: 5770,
    name: "PIPBN76.1X7.1",
    type: "Pipe",
    designation: "PIPBN76.1X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,39",
    d_global: "76,1",
    i_global: "92,56",
    t_global: "7,1",
    z_global: "24,33",
  },
  {
    profile_section_id: 5771,
    name: "PIPBN76.1X8",
    type: "Pipe",
    designation: "PIPBN76.1X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "17,12",
    d_global: "76,1",
    i_global: "100,59",
    t_global: 8,
    z_global: "26,44",
  },
  {
    profile_section_id: 5772,
    name: "PIPBN76.1X8.8",
    type: "Pipe",
    designation: "PIPBN76.1X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,61",
    d_global: "76,1",
    i_global: "107,14",
    t_global: "8,8",
    z_global: "28,16",
  },
  {
    profile_section_id: 5773,
    name: "PIPBN76.1X10",
    type: "Pipe",
    designation: "PIPBN76.1X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,77",
    d_global: "76,1",
    i_global: "116,01",
    t_global: 10,
    z_global: "30,49",
  },
  {
    profile_section_id: 5774,
    name: "PIPBN76.1X11",
    type: "Pipe",
    designation: "PIPBN76.1X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "22,5",
    d_global: "76,1",
    i_global: "122,58",
    t_global: 11,
    z_global: "32,22",
  },
  {
    profile_section_id: 5775,
    name: "PIPBN76.1X12.5",
    type: "Pipe",
    designation: "PIPBN76.1X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "24,98",
    d_global: "76,1",
    i_global: "131,16",
    t_global: "12,5",
    z_global: "34,47",
  },
  {
    profile_section_id: 5776,
    name: "PIPBN76.1X14.2",
    type: "Pipe",
    designation: "PIPBN76.1X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "27,61",
    d_global: "76,1",
    i_global: "139,22",
    t_global: "14,2",
    z_global: "36,59",
  },
  {
    profile_section_id: 5777,
    name: "PIPBN76.1X16",
    type: "Pipe",
    designation: "PIPBN76.1X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "30,21",
    d_global: "76,1",
    i_global: "146,06",
    t_global: 16,
    z_global: "38,39",
  },
  {
    profile_section_id: 5778,
    name: "PIPBN76.1X17.5",
    type: "Pipe",
    designation: "PIPBN76.1X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "32,22",
    d_global: "76,1",
    i_global: "150,62",
    t_global: "17,5",
    z_global: "39,59",
  },
  {
    profile_section_id: 5779,
    name: "PIPBN76.1X20",
    type: "Pipe",
    designation: "PIPBN76.1X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "35,25",
    d_global: "76,1",
    i_global: "156,29",
    t_global: 20,
    z_global: "41,08",
  },
  {
    profile_section_id: 5780,
    name: "PIPBN76.1X22.2",
    type: "Pipe",
    designation: "PIPBN76.1X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "37,59",
    d_global: "76,1",
    i_global: "159,67",
    t_global: "22,2",
    z_global: "41,96",
  },
  {
    profile_section_id: 5781,
    name: "PIPBN80X8.5",
    type: "Pipe",
    designation: "PIPBN80X8.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "19,09",
    d_global: 80,
    i_global: "123,73",
    t_global: "8,5",
    z_global: "30,93",
  },
  {
    profile_section_id: 5782,
    name: "PIPBN80X15",
    type: "Pipe",
    designation: "PIPBN80X15",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "30,63",
    d_global: 80,
    i_global: "170,38",
    t_global: 15,
    z_global: "42,6",
  },
  {
    profile_section_id: 5783,
    name: "PIPBN80X20",
    type: "Pipe",
    designation: "PIPBN80X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "37,7",
    d_global: 80,
    i_global: "188,5",
    t_global: 20,
    z_global: "47,12",
  },
  {
    profile_section_id: 5784,
    name: "PIPBN82.5X3.2",
    type: "Pipe",
    designation: "PIPBN82.5X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "7,97",
    d_global: "82,5",
    i_global: "62,77",
    t_global: "3,2",
    z_global: "15,22",
  },
  {
    profile_section_id: 5785,
    name: "PIPBN82.5X3.6",
    type: "Pipe",
    designation: "PIPBN82.5X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,92",
    d_global: "82,5",
    i_global: "69,58",
    t_global: "3,6",
    z_global: "16,87",
  },
  {
    profile_section_id: 5786,
    name: "PIPBN82.5X4",
    type: "Pipe",
    designation: "PIPBN82.5X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,86",
    d_global: "82,5",
    i_global: "76,18",
    t_global: 4,
    z_global: "18,47",
  },
  {
    profile_section_id: 5787,
    name: "PIPBN82.5X4.5",
    type: "Pipe",
    designation: "PIPBN82.5X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,03",
    d_global: "82,5",
    i_global: "84,14",
    t_global: "4,5",
    z_global: "20,4",
  },
  {
    profile_section_id: 5788,
    name: "PIPBN82.5X5",
    type: "Pipe",
    designation: "PIPBN82.5X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,17",
    d_global: "82,5",
    i_global: "91,78",
    t_global: 5,
    z_global: "22,25",
  },
  {
    profile_section_id: 5789,
    name: "PIPBN82.5X5.6",
    type: "Pipe",
    designation: "PIPBN82.5X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "13,53",
    d_global: "82,5",
    i_global: "100,54",
    t_global: "5,6",
    z_global: "24,37",
  },
  {
    profile_section_id: 5790,
    name: "PIPBN82.5X6.3",
    type: "Pipe",
    designation: "PIPBN82.5X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,08",
    d_global: "82,5",
    i_global: "110,21",
    t_global: "6,3",
    z_global: "26,72",
  },
  {
    profile_section_id: 5791,
    name: "PIPBN82.5X7.1",
    type: "Pipe",
    designation: "PIPBN82.5X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,82",
    d_global: "82,5",
    i_global: "120,58",
    t_global: "7,1",
    z_global: "29,23",
  },
  {
    profile_section_id: 5792,
    name: "PIPBN82.5X8",
    type: "Pipe",
    designation: "PIPBN82.5X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,72",
    d_global: "82,5",
    i_global: "131,4",
    t_global: 8,
    z_global: "31,86",
  },
  {
    profile_section_id: 5793,
    name: "PIPBN82.5X8.8",
    type: "Pipe",
    designation: "PIPBN82.5X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,38",
    d_global: "82,5",
    i_global: "140,31",
    t_global: "8,8",
    z_global: "34,02",
  },
  {
    profile_section_id: 5794,
    name: "PIPBN82.5X10",
    type: "Pipe",
    designation: "PIPBN82.5X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "22,78",
    d_global: "82,5",
    i_global: "152,5",
    t_global: 10,
    z_global: "36,97",
  },
  {
    profile_section_id: 5795,
    name: "PIPBN82.5X11",
    type: "Pipe",
    designation: "PIPBN82.5X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "24,71",
    d_global: "82,5",
    i_global: "161,63",
    t_global: 11,
    z_global: "39,18",
  },
  {
    profile_section_id: 5796,
    name: "PIPBN82.5X12.5",
    type: "Pipe",
    designation: "PIPBN82.5X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "27,49",
    d_global: "82,5",
    i_global: "173,74",
    t_global: "12,5",
    z_global: "42,12",
  },
  {
    profile_section_id: 5797,
    name: "PIPBN82.5X14.2",
    type: "Pipe",
    designation: "PIPBN82.5X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "30,47",
    d_global: "82,5",
    i_global: "185,35",
    t_global: "14,2",
    z_global: "44,93",
  },
  {
    profile_section_id: 5798,
    name: "PIPBN82.5X16",
    type: "Pipe",
    designation: "PIPBN82.5X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "33,43",
    d_global: "82,5",
    i_global: "195,47",
    t_global: 16,
    z_global: "47,39",
  },
  {
    profile_section_id: 5799,
    name: "PIPBN82.5X17.5",
    type: "Pipe",
    designation: "PIPBN82.5X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "35,74",
    d_global: "82,5",
    i_global: "202,41",
    t_global: "17,5",
    z_global: "49,07",
  },
  {
    profile_section_id: 5800,
    name: "PIPBN82.5X20",
    type: "Pipe",
    designation: "PIPBN82.5X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "39,27",
    d_global: "82,5",
    i_global: "211,38",
    t_global: 20,
    z_global: "51,24",
  },
  {
    profile_section_id: 5801,
    name: "PIPBN82.5X22.2",
    type: "Pipe",
    designation: "PIPBN82.5X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "42,06",
    d_global: "82,5",
    i_global: "217,05",
    t_global: "22,2",
    z_global: "52,62",
  },
  {
    profile_section_id: 5802,
    name: "PIPBN82.5X25",
    type: "Pipe",
    designation: "PIPBN82.5X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "45,16",
    d_global: "82,5",
    i_global: "221,92",
    t_global: 25,
    z_global: "53,8",
  },
  {
    profile_section_id: 5803,
    name: "PIPBN85X9",
    type: "Pipe",
    designation: "PIPBN85X9",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "21,49",
    d_global: 85,
    i_global: "157,32",
    t_global: 9,
    z_global: "37,02",
  },
  {
    profile_section_id: 5804,
    name: "PIPBN85X15",
    type: "Pipe",
    designation: "PIPBN85X15",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "32,99",
    d_global: 85,
    i_global: "211,32",
    t_global: 15,
    z_global: "49,72",
  },
  {
    profile_section_id: 5805,
    name: "PIPBN85X20",
    type: "Pipe",
    designation: "PIPBN85X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "40,84",
    d_global: 85,
    i_global: "236,11",
    t_global: 20,
    z_global: "55,55",
  },
  {
    profile_section_id: 5806,
    name: "PIPBN88.9X3.2",
    type: "Pipe",
    designation: "PIPBN88.9X3.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "8,62",
    d_global: "88,9",
    i_global: "79,21",
    t_global: "3,2",
    z_global: "17,82",
  },
  {
    profile_section_id: 5807,
    name: "PIPBN88.9X3.6",
    type: "Pipe",
    designation: "PIPBN88.9X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "9,65",
    d_global: "88,9",
    i_global: "87,9",
    t_global: "3,6",
    z_global: "19,77",
  },
  {
    profile_section_id: 5808,
    name: "PIPBN88.9X4",
    type: "Pipe",
    designation: "PIPBN88.9X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,67",
    d_global: "88,9",
    i_global: "96,34",
    t_global: 4,
    z_global: "21,67",
  },
  {
    profile_section_id: 5809,
    name: "PIPBN88.9X4.5",
    type: "Pipe",
    designation: "PIPBN88.9X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,93",
    d_global: "88,9",
    i_global: "106,55",
    t_global: "4,5",
    z_global: "23,97",
  },
  {
    profile_section_id: 5810,
    name: "PIPBN88.9X5",
    type: "Pipe",
    designation: "PIPBN88.9X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "13,18",
    d_global: "88,9",
    i_global: "116,37",
    t_global: 5,
    z_global: "26,18",
  },
  {
    profile_section_id: 5811,
    name: "PIPBN88.9X5.6",
    type: "Pipe",
    designation: "PIPBN88.9X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,65",
    d_global: "88,9",
    i_global: "127,69",
    t_global: "5,6",
    z_global: "28,73",
  },
  {
    profile_section_id: 5812,
    name: "PIPBN88.9X6.3",
    type: "Pipe",
    designation: "PIPBN88.9X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,35",
    d_global: "88,9",
    i_global: "140,24",
    t_global: "6,3",
    z_global: "31,55",
  },
  {
    profile_section_id: 5813,
    name: "PIPBN88.9X7.1",
    type: "Pipe",
    designation: "PIPBN88.9X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,25",
    d_global: "88,9",
    i_global: "153,76",
    t_global: "7,1",
    z_global: "34,59",
  },
  {
    profile_section_id: 5814,
    name: "PIPBN88.9X8",
    type: "Pipe",
    designation: "PIPBN88.9X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,33",
    d_global: "88,9",
    i_global: "167,97",
    t_global: 8,
    z_global: "37,79",
  },
  {
    profile_section_id: 5815,
    name: "PIPBN88.9X8.8",
    type: "Pipe",
    designation: "PIPBN88.9X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "22,14",
    d_global: "88,9",
    i_global: "179,74",
    t_global: "8,8",
    z_global: "40,44",
  },
  {
    profile_section_id: 5816,
    name: "PIPBN88.9X10",
    type: "Pipe",
    designation: "PIPBN88.9X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "24,79",
    d_global: "88,9",
    i_global: "195,98",
    t_global: 10,
    z_global: "44,09",
  },
  {
    profile_section_id: 5817,
    name: "PIPBN88.9X11",
    type: "Pipe",
    designation: "PIPBN88.9X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "26,92",
    d_global: "88,9",
    i_global: "208,28",
    t_global: 11,
    z_global: "46,86",
  },
  {
    profile_section_id: 5818,
    name: "PIPBN88.9X12.5",
    type: "Pipe",
    designation: "PIPBN88.9X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: 30,
    d_global: "88,9",
    i_global: "224,76",
    t_global: "12,5",
    z_global: "50,56",
  },
  {
    profile_section_id: 5819,
    name: "PIPBN88.9X14.2",
    type: "Pipe",
    designation: "PIPBN88.9X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "33,32",
    d_global: "88,9",
    i_global: "240,84",
    t_global: "14,2",
    z_global: "54,18",
  },
  {
    profile_section_id: 5820,
    name: "PIPBN88.9X16",
    type: "Pipe",
    designation: "PIPBN88.9X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "36,64",
    d_global: "88,9",
    i_global: "255,15",
    t_global: 16,
    z_global: "57,4",
  },
  {
    profile_section_id: 5821,
    name: "PIPBN88.9X17.5",
    type: "Pipe",
    designation: "PIPBN88.9X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "39,25",
    d_global: "88,9",
    i_global: "265,17",
    t_global: "17,5",
    z_global: "59,66",
  },
  {
    profile_section_id: 5822,
    name: "PIPBN88.9X20",
    type: "Pipe",
    designation: "PIPBN88.9X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "43,29",
    d_global: "88,9",
    i_global: "278,54",
    t_global: 20,
    z_global: "62,66",
  },
  {
    profile_section_id: 5823,
    name: "PIPBN88.9X22.2",
    type: "Pipe",
    designation: "PIPBN88.9X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "46,52",
    d_global: "88,9",
    i_global: "287,35",
    t_global: "22,2",
    z_global: "64,65",
  },
  {
    profile_section_id: 5824,
    name: "PIPBN88.9X25",
    type: "Pipe",
    designation: "PIPBN88.9X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "50,19",
    d_global: "88,9",
    i_global: "295,36",
    t_global: 25,
    z_global: "66,45",
  },
  {
    profile_section_id: 5825,
    name: "PIPBN88.9X28",
    type: "Pipe",
    designation: "PIPBN88.9X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "53,57",
    d_global: "88,9",
    i_global: "300,85",
    t_global: 28,
    z_global: "67,68",
  },
  {
    profile_section_id: 5826,
    name: "PIPBN90X9.5",
    type: "Pipe",
    designation: "PIPBN90X9.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "24,03",
    d_global: 90,
    i_global: "197,32",
    t_global: "9,5",
    z_global: "43,85",
  },
  {
    profile_section_id: 5827,
    name: "PIPBN90X13.5",
    type: "Pipe",
    designation: "PIPBN90X13.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "32,44",
    d_global: 90,
    i_global: "244,74",
    t_global: "13,5",
    z_global: "54,39",
  },
  {
    profile_section_id: 5828,
    name: "PIPBN90X20",
    type: "Pipe",
    designation: "PIPBN90X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "43,98",
    d_global: 90,
    i_global: "291,38",
    t_global: 20,
    z_global: "64,75",
  },
  {
    profile_section_id: 5829,
    name: "PIPBN95X3.6",
    type: "Pipe",
    designation: "PIPBN95X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "10,34",
    d_global: 95,
    i_global: "108,11",
    t_global: "3,6",
    z_global: "22,76",
  },
  {
    profile_section_id: 5830,
    name: "PIPBN95X4",
    type: "Pipe",
    designation: "PIPBN95X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,44",
    d_global: 95,
    i_global: "118,6",
    t_global: 4,
    z_global: "24,97",
  },
  {
    profile_section_id: 5831,
    name: "PIPBN95X4.5",
    type: "Pipe",
    designation: "PIPBN95X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,79",
    d_global: 95,
    i_global: "131,31",
    t_global: "4,5",
    z_global: "27,64",
  },
  {
    profile_section_id: 5832,
    name: "PIPBN95X5",
    type: "Pipe",
    designation: "PIPBN95X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,14",
    d_global: 95,
    i_global: "143,58",
    t_global: 5,
    z_global: "30,23",
  },
  {
    profile_section_id: 5833,
    name: "PIPBN95X5.6",
    type: "Pipe",
    designation: "PIPBN95X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,73",
    d_global: 95,
    i_global: "157,75",
    t_global: "5,6",
    z_global: "33,21",
  },
  {
    profile_section_id: 5834,
    name: "PIPBN95X6.3",
    type: "Pipe",
    designation: "PIPBN95X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "17,56",
    d_global: 95,
    i_global: "173,52",
    t_global: "6,3",
    z_global: "36,53",
  },
  {
    profile_section_id: 5835,
    name: "PIPBN95X7.1",
    type: "Pipe",
    designation: "PIPBN95X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "19,61",
    d_global: 95,
    i_global: "190,59",
    t_global: "7,1",
    z_global: "40,13",
  },
  {
    profile_section_id: 5836,
    name: "PIPBN95X8",
    type: "Pipe",
    designation: "PIPBN95X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "21,87",
    d_global: 95,
    i_global: "208,62",
    t_global: 8,
    z_global: "43,92",
  },
  {
    profile_section_id: 5837,
    name: "PIPBN95X8.8",
    type: "Pipe",
    designation: "PIPBN95X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,83",
    d_global: 95,
    i_global: "223,65",
    t_global: "8,8",
    z_global: "47,08",
  },
  {
    profile_section_id: 5838,
    name: "PIPBN95X10",
    type: "Pipe",
    designation: "PIPBN95X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "26,7",
    d_global: 95,
    i_global: "244,5",
    t_global: 10,
    z_global: "51,48",
  },
  {
    profile_section_id: 5839,
    name: "PIPBN95X11",
    type: "Pipe",
    designation: "PIPBN95X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "29,03",
    d_global: 95,
    i_global: "260,42",
    t_global: 11,
    z_global: "54,83",
  },
  {
    profile_section_id: 5840,
    name: "PIPBN95X12.5",
    type: "Pipe",
    designation: "PIPBN95X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "32,4",
    d_global: 95,
    i_global: "281,96",
    t_global: "12,5",
    z_global: "59,36",
  },
  {
    profile_section_id: 5841,
    name: "PIPBN95X14",
    type: "Pipe",
    designation: "PIPBN95X14",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "35,63",
    d_global: 95,
    i_global: "300,9",
    t_global: 14,
    z_global: "63,35",
  },
  {
    profile_section_id: 5842,
    name: "PIPBN95X14.2",
    type: "Pipe",
    designation: "PIPBN95X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "36,05",
    d_global: 95,
    i_global: "303,24",
    t_global: "14,2",
    z_global: "63,84",
  },
  {
    profile_section_id: 5843,
    name: "PIPBN95X16",
    type: "Pipe",
    designation: "PIPBN95X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "39,71",
    d_global: 95,
    i_global: "322,49",
    t_global: 16,
    z_global: "67,89",
  },
  {
    profile_section_id: 5844,
    name: "PIPBN95X17.5",
    type: "Pipe",
    designation: "PIPBN95X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "42,61",
    d_global: 95,
    i_global: "336,2",
    t_global: "17,5",
    z_global: "70,78",
  },
  {
    profile_section_id: 5845,
    name: "PIPBN95X20",
    type: "Pipe",
    designation: "PIPBN95X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "47,12",
    d_global: 95,
    i_global: "354,9",
    t_global: 20,
    z_global: "74,72",
  },
  {
    profile_section_id: 5846,
    name: "PIPBN95X22.5",
    type: "Pipe",
    designation: "PIPBN95X22.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "51,25",
    d_global: 95,
    i_global: "369,14",
    t_global: "22,5",
    z_global: "77,71",
  },
  {
    profile_section_id: 5847,
    name: "PIPBN95X25",
    type: "Pipe",
    designation: "PIPBN95X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "54,98",
    d_global: 95,
    i_global: "379,69",
    t_global: 25,
    z_global: "79,94",
  },
  {
    profile_section_id: 5848,
    name: "PIPBN95X28",
    type: "Pipe",
    designation: "PIPBN95X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "58,94",
    d_global: 95,
    i_global: "388,46",
    t_global: 28,
    z_global: "81,78",
  },
  {
    profile_section_id: 5849,
    name: "PIPBN100X10",
    type: "Pipe",
    designation: "PIPBN100X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "28,27",
    d_global: 100,
    i_global: "289,81",
    t_global: 10,
    z_global: "57,96",
  },
  {
    profile_section_id: 5850,
    name: "PIPBN100X14.5",
    type: "Pipe",
    designation: "PIPBN100X14.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "38,95",
    d_global: 100,
    i_global: "366,13",
    t_global: "14,5",
    z_global: "73,23",
  },
  {
    profile_section_id: 5851,
    name: "PIPBN100X22",
    type: "Pipe",
    designation: "PIPBN100X22",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "53,91",
    d_global: 100,
    i_global: "442,6",
    t_global: 22,
    z_global: "88,52",
  },
  {
    profile_section_id: 5852,
    name: "PIPBN101.6X3.6",
    type: "Pipe",
    designation: "PIPBN101.6X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,08",
    d_global: "101,6",
    i_global: "133,24",
    t_global: "3,6",
    z_global: "26,23",
  },
  {
    profile_section_id: 5853,
    name: "PIPBN101.6X4",
    type: "Pipe",
    designation: "PIPBN101.6X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,26",
    d_global: "101,6",
    i_global: "146,28",
    t_global: 4,
    z_global: "28,8",
  },
  {
    profile_section_id: 5854,
    name: "PIPBN101.6X4.5",
    type: "Pipe",
    designation: "PIPBN101.6X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "13,73",
    d_global: "101,6",
    i_global: "162,13",
    t_global: "4,5",
    z_global: "31,91",
  },
  {
    profile_section_id: 5855,
    name: "PIPBN101.6X5",
    type: "Pipe",
    designation: "PIPBN101.6X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,17",
    d_global: "101,6",
    i_global: "177,47",
    t_global: 5,
    z_global: "34,94",
  },
  {
    profile_section_id: 5856,
    name: "PIPBN101.6X5.6",
    type: "Pipe",
    designation: "PIPBN101.6X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,89",
    d_global: "101,6",
    i_global: "195,23",
    t_global: "5,6",
    z_global: "38,43",
  },
  {
    profile_section_id: 5857,
    name: "PIPBN101.6X6.3",
    type: "Pipe",
    designation: "PIPBN101.6X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,86",
    d_global: "101,6",
    i_global: "215,07",
    t_global: "6,3",
    z_global: "42,34",
  },
  {
    profile_section_id: 5858,
    name: "PIPBN101.6X7.1",
    type: "Pipe",
    designation: "PIPBN101.6X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "21,08",
    d_global: "101,6",
    i_global: "236,62",
    t_global: "7,1",
    z_global: "46,58",
  },
  {
    profile_section_id: 5859,
    name: "PIPBN101.6X8",
    type: "Pipe",
    designation: "PIPBN101.6X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,52",
    d_global: "101,6",
    i_global: "259,5",
    t_global: 8,
    z_global: "51,08",
  },
  {
    profile_section_id: 5860,
    name: "PIPBN101.6X8.8",
    type: "Pipe",
    designation: "PIPBN101.6X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "25,66",
    d_global: "101,6",
    i_global: "278,66",
    t_global: "8,8",
    z_global: "54,85",
  },
  {
    profile_section_id: 5861,
    name: "PIPBN101.6X10",
    type: "Pipe",
    designation: "PIPBN101.6X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "28,78",
    d_global: "101,6",
    i_global: "305,42",
    t_global: 10,
    z_global: "60,12",
  },
  {
    profile_section_id: 5862,
    name: "PIPBN101.6X11",
    type: "Pipe",
    designation: "PIPBN101.6X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "31,31",
    d_global: "101,6",
    i_global: "325,98",
    t_global: 11,
    z_global: "64,17",
  },
  {
    profile_section_id: 5863,
    name: "PIPBN101.6X12.5",
    type: "Pipe",
    designation: "PIPBN101.6X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "34,99",
    d_global: "101,6",
    i_global: "354,05",
    t_global: "12,5",
    z_global: "69,69",
  },
  {
    profile_section_id: 5864,
    name: "PIPBN101.6X14.2",
    type: "Pipe",
    designation: "PIPBN101.6X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "38,99",
    d_global: "101,6",
    i_global: "382,12",
    t_global: "14,2",
    z_global: "75,22",
  },
  {
    profile_section_id: 5865,
    name: "PIPBN101.6X16",
    type: "Pipe",
    designation: "PIPBN101.6X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "43,03",
    d_global: "101,6",
    i_global: "407,86",
    t_global: 16,
    z_global: "80,29",
  },
  {
    profile_section_id: 5866,
    name: "PIPBN101.6X17.5",
    type: "Pipe",
    designation: "PIPBN101.6X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "46,24",
    d_global: "101,6",
    i_global: "426,48",
    t_global: "17,5",
    z_global: "83,95",
  },
  {
    profile_section_id: 5867,
    name: "PIPBN101.6X20",
    type: "Pipe",
    designation: "PIPBN101.6X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "51,27",
    d_global: "101,6",
    i_global: "452,37",
    t_global: 20,
    z_global: "89,05",
  },
  {
    profile_section_id: 5868,
    name: "PIPBN101.6X22.2",
    type: "Pipe",
    designation: "PIPBN101.6X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "55,38",
    d_global: "101,6",
    i_global: "470,5",
    t_global: "22,2",
    z_global: "92,62",
  },
  {
    profile_section_id: 5869,
    name: "PIPBN101.6X25",
    type: "Pipe",
    designation: "PIPBN101.6X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "60,16",
    d_global: "101,6",
    i_global: "488,25",
    t_global: 25,
    z_global: "96,11",
  },
  {
    profile_section_id: 5870,
    name: "PIPBN101.6X28",
    type: "Pipe",
    designation: "PIPBN101.6X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "64,74",
    d_global: "101,6",
    i_global: "501,83",
    t_global: 28,
    z_global: "98,78",
  },
  {
    profile_section_id: 5871,
    name: "PIPBN101.6X30",
    type: "Pipe",
    designation: "PIPBN101.6X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "67,48",
    d_global: "101,6",
    i_global: "508,35",
    t_global: 30,
    z_global: "100,07",
  },
  {
    profile_section_id: 5872,
    name: "PIPBN101.6X32",
    type: "Pipe",
    designation: "PIPBN101.6X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "69,97",
    d_global: "101,6",
    i_global: "513,24",
    t_global: 32,
    z_global: "101,03",
  },
  {
    profile_section_id: 5873,
    name: "PIPBN106X13",
    type: "Pipe",
    designation: "PIPBN106X13",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "37,98",
    d_global: 106,
    i_global: "418,65",
    t_global: 13,
    z_global: "78,99",
  },
  {
    profile_section_id: 5874,
    name: "PIPBN106X17.5",
    type: "Pipe",
    designation: "PIPBN106X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "48,66",
    d_global: 106,
    i_global: "494,98",
    t_global: "17,5",
    z_global: "93,39",
  },
  {
    profile_section_id: 5875,
    name: "PIPBN106X25",
    type: "Pipe",
    designation: "PIPBN106X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "63,62",
    d_global: 106,
    i_global: "571,44",
    t_global: 25,
    z_global: "107,82",
  },
  {
    profile_section_id: 5876,
    name: "PIPBN108X3.6",
    type: "Pipe",
    designation: "PIPBN108X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "11,81",
    d_global: 108,
    i_global: "161,06",
    t_global: "3,6",
    z_global: "29,82",
  },
  {
    profile_section_id: 5877,
    name: "PIPBN108X4",
    type: "Pipe",
    designation: "PIPBN108X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "13,07",
    d_global: 108,
    i_global: "176,96",
    t_global: 4,
    z_global: "32,77",
  },
  {
    profile_section_id: 5878,
    name: "PIPBN108X4.5",
    type: "Pipe",
    designation: "PIPBN108X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,63",
    d_global: 108,
    i_global: "196,3",
    t_global: "4,5",
    z_global: "36,35",
  },
  {
    profile_section_id: 5879,
    name: "PIPBN108X5",
    type: "Pipe",
    designation: "PIPBN108X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,18",
    d_global: 108,
    i_global: "215,06",
    t_global: 5,
    z_global: "39,83",
  },
  {
    profile_section_id: 5880,
    name: "PIPBN108X5.6",
    type: "Pipe",
    designation: "PIPBN108X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,02",
    d_global: 108,
    i_global: "236,83",
    t_global: "5,6",
    z_global: "43,86",
  },
  {
    profile_section_id: 5881,
    name: "PIPBN108X6.3",
    type: "Pipe",
    designation: "PIPBN108X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,13",
    d_global: 108,
    i_global: "261,23",
    t_global: "6,3",
    z_global: "48,38",
  },
  {
    profile_section_id: 5882,
    name: "PIPBN108X7.1",
    type: "Pipe",
    designation: "PIPBN108X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "22,51",
    d_global: 108,
    i_global: "287,83",
    t_global: "7,1",
    z_global: "53,3",
  },
  {
    profile_section_id: 5883,
    name: "PIPBN108X8",
    type: "Pipe",
    designation: "PIPBN108X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "25,13",
    d_global: 108,
    i_global: "316,17",
    t_global: 8,
    z_global: "58,55",
  },
  {
    profile_section_id: 5884,
    name: "PIPBN108X8.8",
    type: "Pipe",
    designation: "PIPBN108X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "27,42",
    d_global: 108,
    i_global: 340,
    t_global: "8,8",
    z_global: "62,96",
  },
  {
    profile_section_id: 5885,
    name: "PIPBN108X10",
    type: "Pipe",
    designation: "PIPBN108X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "30,79",
    d_global: 108,
    i_global: "373,45",
    t_global: 10,
    z_global: "69,16",
  },
  {
    profile_section_id: 5886,
    name: "PIPBN108X11",
    type: "Pipe",
    designation: "PIPBN108X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "33,52",
    d_global: 108,
    i_global: "399,32",
    t_global: 11,
    z_global: "73,95",
  },
  {
    profile_section_id: 5887,
    name: "PIPBN108X12.5",
    type: "Pipe",
    designation: "PIPBN108X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "37,5",
    d_global: 108,
    i_global: "434,87",
    t_global: "12,5",
    z_global: "80,53",
  },
  {
    profile_section_id: 5888,
    name: "PIPBN108X14.2",
    type: "Pipe",
    designation: "PIPBN108X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "41,84",
    d_global: 108,
    i_global: "470,76",
    t_global: "14,2",
    z_global: "87,18",
  },
  {
    profile_section_id: 5889,
    name: "PIPBN108X16",
    type: "Pipe",
    designation: "PIPBN108X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "46,24",
    d_global: 108,
    i_global: "504,06",
    t_global: 16,
    z_global: "93,34",
  },
  {
    profile_section_id: 5890,
    name: "PIPBN108X17.5",
    type: "Pipe",
    designation: "PIPBN108X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "49,75",
    d_global: 108,
    i_global: "528,43",
    t_global: "17,5",
    z_global: "97,86",
  },
  {
    profile_section_id: 5891,
    name: "PIPBN108X20",
    type: "Pipe",
    designation: "PIPBN108X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "55,29",
    d_global: 108,
    i_global: "562,87",
    t_global: 20,
    z_global: "104,24",
  },
  {
    profile_section_id: 5892,
    name: "PIPBN108X22.2",
    type: "Pipe",
    designation: "PIPBN108X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "59,84",
    d_global: 108,
    i_global: "587,51",
    t_global: "22,2",
    z_global: "108,8",
  },
  {
    profile_section_id: 5893,
    name: "PIPBN108X25",
    type: "Pipe",
    designation: "PIPBN108X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "65,19",
    d_global: 108,
    i_global: "612,28",
    t_global: 25,
    z_global: "113,39",
  },
  {
    profile_section_id: 5894,
    name: "PIPBN108X28",
    type: "Pipe",
    designation: "PIPBN108X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "70,37",
    d_global: 108,
    i_global: "631,94",
    t_global: 28,
    z_global: "117,03",
  },
  {
    profile_section_id: 5895,
    name: "PIPBN108X30",
    type: "Pipe",
    designation: "PIPBN108X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "73,51",
    d_global: 108,
    i_global: "641,77",
    t_global: 30,
    z_global: "118,85",
  },
  {
    profile_section_id: 5896,
    name: "PIPBN108X32",
    type: "Pipe",
    designation: "PIPBN108X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "76,4",
    d_global: 108,
    i_global: "649,43",
    t_global: 32,
    z_global: "120,27",
  },
  {
    profile_section_id: 5897,
    name: "PIPBN108X36",
    type: "Pipe",
    designation: "PIPBN108X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "81,43",
    d_global: 108,
    i_global: "659,58",
    t_global: 36,
    z_global: "122,14",
  },
  {
    profile_section_id: 5898,
    name: "PIPBN112X11",
    type: "Pipe",
    designation: "PIPBN112X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "34,9",
    d_global: 112,
    i_global: "450,34",
    t_global: 11,
    z_global: "80,42",
  },
  {
    profile_section_id: 5899,
    name: "PIPBN112X16",
    type: "Pipe",
    designation: "PIPBN112X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "48,25",
    d_global: 112,
    i_global: "571,34",
    t_global: 16,
    z_global: "102,03",
  },
  {
    profile_section_id: 5900,
    name: "PIPBN112X24.5",
    type: "Pipe",
    designation: "PIPBN112X24.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "67,35",
    d_global: 112,
    i_global: "695,07",
    t_global: "24,5",
    z_global: "124,12",
  },
  {
    profile_section_id: 5901,
    name: "PIPBN114.3X3.6",
    type: "Pipe",
    designation: "PIPBN114.3X3.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "12,52",
    d_global: "114,3",
    i_global: "191,98",
    t_global: "3,6",
    z_global: "33,59",
  },
  {
    profile_section_id: 5902,
    name: "PIPBN114.3X4",
    type: "Pipe",
    designation: "PIPBN114.3X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "13,86",
    d_global: "114,3",
    i_global: "211,06",
    t_global: 4,
    z_global: "36,93",
  },
  {
    profile_section_id: 5903,
    name: "PIPBN114.3X4.5",
    type: "Pipe",
    designation: "PIPBN114.3X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,52",
    d_global: "114,3",
    i_global: "234,32",
    t_global: "4,5",
    z_global: 41,
  },
  {
    profile_section_id: 5904,
    name: "PIPBN114.3X5",
    type: "Pipe",
    designation: "PIPBN114.3X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "17,17",
    d_global: "114,3",
    i_global: "256,92",
    t_global: 5,
    z_global: "44,95",
  },
  {
    profile_section_id: 5905,
    name: "PIPBN114.3X5.6",
    type: "Pipe",
    designation: "PIPBN114.3X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "19,12",
    d_global: "114,3",
    i_global: "283,2",
    t_global: "5,6",
    z_global: "49,55",
  },
  {
    profile_section_id: 5906,
    name: "PIPBN114.3X6.3",
    type: "Pipe",
    designation: "PIPBN114.3X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "21,38",
    d_global: "114,3",
    i_global: "312,71",
    t_global: "6,3",
    z_global: "54,72",
  },
  {
    profile_section_id: 5907,
    name: "PIPBN114.3X7.1",
    type: "Pipe",
    designation: "PIPBN114.3X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,91",
    d_global: "114,3",
    i_global: "344,99",
    t_global: "7,1",
    z_global: "60,37",
  },
  {
    profile_section_id: 5908,
    name: "PIPBN114.3X8",
    type: "Pipe",
    designation: "PIPBN114.3X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "26,72",
    d_global: "114,3",
    i_global: "379,49",
    t_global: 8,
    z_global: "66,4",
  },
  {
    profile_section_id: 5909,
    name: "PIPBN114.3X8.8",
    type: "Pipe",
    designation: "PIPBN114.3X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "29,17",
    d_global: "114,3",
    i_global: "408,61",
    t_global: "8,8",
    z_global: "71,5",
  },
  {
    profile_section_id: 5910,
    name: "PIPBN114.3X10",
    type: "Pipe",
    designation: "PIPBN114.3X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "32,77",
    d_global: "114,3",
    i_global: "449,66",
    t_global: 10,
    z_global: "78,68",
  },
  {
    profile_section_id: 5911,
    name: "PIPBN114.3X11",
    type: "Pipe",
    designation: "PIPBN114.3X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "35,7",
    d_global: "114,3",
    i_global: "481,56",
    t_global: 11,
    z_global: "84,26",
  },
  {
    profile_section_id: 5912,
    name: "PIPBN114.3X12.5",
    type: "Pipe",
    designation: "PIPBN114.3X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "39,98",
    d_global: "114,3",
    i_global: "525,67",
    t_global: "12,5",
    z_global: "91,98",
  },
  {
    profile_section_id: 5913,
    name: "PIPBN114.3X14.2",
    type: "Pipe",
    designation: "PIPBN114.3X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "44,66",
    d_global: "114,3",
    i_global: "570,56",
    t_global: "14,2",
    z_global: "99,84",
  },
  {
    profile_section_id: 5914,
    name: "PIPBN114.3X16",
    type: "Pipe",
    designation: "PIPBN114.3X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "49,41",
    d_global: "114,3",
    i_global: "612,63",
    t_global: 16,
    z_global: "107,2",
  },
  {
    profile_section_id: 5915,
    name: "PIPBN114.3X17.5",
    type: "Pipe",
    designation: "PIPBN114.3X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "53,22",
    d_global: "114,3",
    i_global: "643,71",
    t_global: "17,5",
    z_global: "112,64",
  },
  {
    profile_section_id: 5916,
    name: "PIPBN114.3X20",
    type: "Pipe",
    designation: "PIPBN114.3X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "59,25",
    d_global: "114,3",
    i_global: "688,23",
    t_global: 20,
    z_global: "120,42",
  },
  {
    profile_section_id: 5917,
    name: "PIPBN114.3X22.2",
    type: "Pipe",
    designation: "PIPBN114.3X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "64,23",
    d_global: "114,3",
    i_global: "720,64",
    t_global: "22,2",
    z_global: "126,1",
  },
  {
    profile_section_id: 5918,
    name: "PIPBN114.3X25",
    type: "Pipe",
    designation: "PIPBN114.3X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "70,14",
    d_global: "114,3",
    i_global: "753,92",
    t_global: 25,
    z_global: "131,92",
  },
  {
    profile_section_id: 5919,
    name: "PIPBN114.3X28",
    type: "Pipe",
    designation: "PIPBN114.3X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "75,91",
    d_global: "114,3",
    i_global: "781,12",
    t_global: 28,
    z_global: "136,68",
  },
  {
    profile_section_id: 5920,
    name: "PIPBN114.3X30",
    type: "Pipe",
    designation: "PIPBN114.3X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "79,45",
    d_global: "114,3",
    i_global: "795,15",
    t_global: 30,
    z_global: "139,13",
  },
  {
    profile_section_id: 5921,
    name: "PIPBN114.3X32",
    type: "Pipe",
    designation: "PIPBN114.3X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "82,74",
    d_global: "114,3",
    i_global: "806,4",
    t_global: 32,
    z_global: "141,1",
  },
  {
    profile_section_id: 5922,
    name: "PIPBN114.3X36",
    type: "Pipe",
    designation: "PIPBN114.3X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "88,56",
    d_global: "114,3",
    i_global: "822,11",
    t_global: 36,
    z_global: "143,85",
  },
  {
    profile_section_id: 5923,
    name: "PIPBN114.3X40",
    type: "Pipe",
    designation: "PIPBN114.3X40",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "93,37",
    d_global: "114,3",
    i_global: "831,03",
    t_global: 40,
    z_global: "145,41",
  },
  {
    profile_section_id: 5924,
    name: "PIPBN118X14",
    type: "Pipe",
    designation: "PIPBN118X14",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "45,74",
    d_global: 118,
    i_global: "629,63",
    t_global: 14,
    z_global: "106,72",
  },
  {
    profile_section_id: 5925,
    name: "PIPBN118X19",
    type: "Pipe",
    designation: "PIPBN118X19",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "59,09",
    d_global: 118,
    i_global: "750,63",
    t_global: 19,
    z_global: "127,23",
  },
  {
    profile_section_id: 5926,
    name: "PIPBN118X27.5",
    type: "Pipe",
    designation: "PIPBN118X27.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "78,19",
    d_global: 118,
    i_global: "874,37",
    t_global: "27,5",
    z_global: "148,2",
  },
  {
    profile_section_id: 5927,
    name: "PIPBN121X4",
    type: "Pipe",
    designation: "PIPBN121X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "14,7",
    d_global: 121,
    i_global: "251,88",
    t_global: 4,
    z_global: "41,63",
  },
  {
    profile_section_id: 5928,
    name: "PIPBN121X4.5",
    type: "Pipe",
    designation: "PIPBN121X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "16,47",
    d_global: 121,
    i_global: "279,83",
    t_global: "4,5",
    z_global: "46,25",
  },
  {
    profile_section_id: 5929,
    name: "PIPBN121X5",
    type: "Pipe",
    designation: "PIPBN121X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "18,22",
    d_global: 121,
    i_global: "307,05",
    t_global: 5,
    z_global: "50,75",
  },
  {
    profile_section_id: 5930,
    name: "PIPBN121X5.6",
    type: "Pipe",
    designation: "PIPBN121X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "20,3",
    d_global: 121,
    i_global: "338,76",
    t_global: "5,6",
    z_global: "55,99",
  },
  {
    profile_section_id: 5931,
    name: "PIPBN121X6.3",
    type: "Pipe",
    designation: "PIPBN121X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "22,7",
    d_global: 121,
    i_global: "374,45",
    t_global: "6,3",
    z_global: "61,89",
  },
  {
    profile_section_id: 5932,
    name: "PIPBN121X7.1",
    type: "Pipe",
    designation: "PIPBN121X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "25,41",
    d_global: 121,
    i_global: "413,59",
    t_global: "7,1",
    z_global: "68,36",
  },
  {
    profile_section_id: 5933,
    name: "PIPBN121X8",
    type: "Pipe",
    designation: "PIPBN121X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "28,4",
    d_global: 121,
    i_global: "455,57",
    t_global: 8,
    z_global: "75,3",
  },
  {
    profile_section_id: 5934,
    name: "PIPBN121X8.8",
    type: "Pipe",
    designation: "PIPBN121X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "31,02",
    d_global: 121,
    i_global: "491,12",
    t_global: "8,8",
    z_global: "81,18",
  },
  {
    profile_section_id: 5935,
    name: "PIPBN121X10",
    type: "Pipe",
    designation: "PIPBN121X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "34,87",
    d_global: 121,
    i_global: "541,43",
    t_global: 10,
    z_global: "89,49",
  },
  {
    profile_section_id: 5936,
    name: "PIPBN121X11",
    type: "Pipe",
    designation: "PIPBN121X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "38,01",
    d_global: 121,
    i_global: "580,7",
    t_global: 11,
    z_global: "95,98",
  },
  {
    profile_section_id: 5937,
    name: "PIPBN121X12.5",
    type: "Pipe",
    designation: "PIPBN121X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "42,61",
    d_global: 121,
    i_global: "635,31",
    t_global: "12,5",
    z_global: "105,01",
  },
  {
    profile_section_id: 5938,
    name: "PIPBN121X14.2",
    type: "Pipe",
    designation: "PIPBN121X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "47,64",
    d_global: 121,
    i_global: "691,31",
    t_global: "14,2",
    z_global: "114,27",
  },
  {
    profile_section_id: 5939,
    name: "PIPBN121X16",
    type: "Pipe",
    designation: "PIPBN121X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "52,78",
    d_global: 121,
    i_global: "744,25",
    t_global: 16,
    z_global: "123,02",
  },
  {
    profile_section_id: 5940,
    name: "PIPBN121X17.5",
    type: "Pipe",
    designation: "PIPBN121X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "56,9",
    d_global: 121,
    i_global: "783,72",
    t_global: "17,5",
    z_global: "129,54",
  },
  {
    profile_section_id: 5941,
    name: "PIPBN121X20",
    type: "Pipe",
    designation: "PIPBN121X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "63,46",
    d_global: 121,
    i_global: "840,93",
    t_global: 20,
    z_global: 139,
  },
  {
    profile_section_id: 5942,
    name: "PIPBN121X22.2",
    type: "Pipe",
    designation: "PIPBN121X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "68,91",
    d_global: 121,
    i_global: "883,23",
    t_global: "22,2",
    z_global: "145,99",
  },
  {
    profile_section_id: 5943,
    name: "PIPBN121X25",
    type: "Pipe",
    designation: "PIPBN121X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "75,4",
    d_global: 121,
    i_global: "927,49",
    t_global: 25,
    z_global: "153,31",
  },
  {
    profile_section_id: 5944,
    name: "PIPBN121X28",
    type: "Pipe",
    designation: "PIPBN121X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "81,81",
    d_global: 121,
    i_global: "964,61",
    t_global: 28,
    z_global: "159,44",
  },
  {
    profile_section_id: 5945,
    name: "PIPBN121X30",
    type: "Pipe",
    designation: "PIPBN121X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "85,77",
    d_global: 121,
    i_global: "984,27",
    t_global: 30,
    z_global: "162,69",
  },
  {
    profile_section_id: 5946,
    name: "PIPBN125X12.5",
    type: "Pipe",
    designation: "PIPBN125X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "44,18",
    d_global: 125,
    i_global: "707,55",
    t_global: "12,5",
    z_global: "113,21",
  },
  {
    profile_section_id: 5947,
    name: "PIPBN125X17.5",
    type: "Pipe",
    designation: "PIPBN125X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "59,1",
    d_global: 125,
    i_global: "876,36",
    t_global: "17,5",
    z_global: "140,22",
  },
  {
    profile_section_id: 5948,
    name: "PIPBN125X27",
    type: "Pipe",
    designation: "PIPBN125X27",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "83,13",
    d_global: 125,
    i_global: "1073,68",
    t_global: 27,
    z_global: "171,79",
  },
  {
    profile_section_id: 5949,
    name: "PIPBN127X4",
    type: "Pipe",
    designation: "PIPBN127X4",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "15,46",
    d_global: 127,
    i_global: "292,61",
    t_global: 4,
    z_global: "46,08",
  },
  {
    profile_section_id: 5950,
    name: "PIPBN127X4.5",
    type: "Pipe",
    designation: "PIPBN127X4.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "17,32",
    d_global: 127,
    i_global: "325,29",
    t_global: "4,5",
    z_global: "51,23",
  },
  {
    profile_section_id: 5951,
    name: "PIPBN127X5",
    type: "Pipe",
    designation: "PIPBN127X5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "19,16",
    d_global: 127,
    i_global: "357,14",
    t_global: 5,
    z_global: "56,24",
  },
  {
    profile_section_id: 5952,
    name: "PIPBN127X5.6",
    type: "Pipe",
    designation: "PIPBN127X5.6",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "21,36",
    d_global: 127,
    i_global: "394,3",
    t_global: "5,6",
    z_global: "62,09",
  },
  {
    profile_section_id: 5953,
    name: "PIPBN127X6.3",
    type: "Pipe",
    designation: "PIPBN127X6.3",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "23,89",
    d_global: 127,
    i_global: "436,22",
    t_global: "6,3",
    z_global: "68,7",
  },
  {
    profile_section_id: 5954,
    name: "PIPBN127X7.1",
    type: "Pipe",
    designation: "PIPBN127X7.1",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "26,74",
    d_global: 127,
    i_global: "482,28",
    t_global: "7,1",
    z_global: "75,95",
  },
  {
    profile_section_id: 5955,
    name: "PIPBN127X8",
    type: "Pipe",
    designation: "PIPBN127X8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "29,91",
    d_global: 127,
    i_global: "531,8",
    t_global: 8,
    z_global: "83,75",
  },
  {
    profile_section_id: 5956,
    name: "PIPBN127X8.8",
    type: "Pipe",
    designation: "PIPBN127X8.8",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "32,68",
    d_global: 127,
    i_global: "573,85",
    t_global: "8,8",
    z_global: "90,37",
  },
  {
    profile_section_id: 5957,
    name: "PIPBN127X10",
    type: "Pipe",
    designation: "PIPBN127X10",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "36,76",
    d_global: 127,
    i_global: "633,55",
    t_global: 10,
    z_global: "99,77",
  },
  {
    profile_section_id: 5958,
    name: "PIPBN127X11",
    type: "Pipe",
    designation: "PIPBN127X11",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "40,09",
    d_global: 127,
    i_global: "680,32",
    t_global: 11,
    z_global: "107,14",
  },
  {
    profile_section_id: 5959,
    name: "PIPBN127X12.5",
    type: "Pipe",
    designation: "PIPBN127X12.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "44,96",
    d_global: 127,
    i_global: "745,64",
    t_global: "12,5",
    z_global: "117,42",
  },
  {
    profile_section_id: 5960,
    name: "PIPBN127X14.2",
    type: "Pipe",
    designation: "PIPBN127X14.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "50,32",
    d_global: 127,
    i_global: "813,02",
    t_global: "14,2",
    z_global: "128,03",
  },
  {
    profile_section_id: 5961,
    name: "PIPBN127X16",
    type: "Pipe",
    designation: "PIPBN127X16",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "55,79",
    d_global: 127,
    i_global: "877,16",
    t_global: 16,
    z_global: "138,14",
  },
  {
    profile_section_id: 5962,
    name: "PIPBN127X17.5",
    type: "Pipe",
    designation: "PIPBN127X17.5",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "60,2",
    d_global: 127,
    i_global: "925,32",
    t_global: "17,5",
    z_global: "145,72",
  },
  {
    profile_section_id: 5963,
    name: "PIPBN127X20",
    type: "Pipe",
    designation: "PIPBN127X20",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "67,23",
    d_global: 127,
    i_global: "995,76",
    t_global: 20,
    z_global: "156,81",
  },
  {
    profile_section_id: 5964,
    name: "PIPBN127X22.2",
    type: "Pipe",
    designation: "PIPBN127X22.2",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "73,09",
    d_global: 127,
    i_global: "1048,48",
    t_global: "22,2",
    z_global: "165,12",
  },
  {
    profile_section_id: 5965,
    name: "PIPBN127X25",
    type: "Pipe",
    designation: "PIPBN127X25",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "80,11",
    d_global: 127,
    i_global: "1104,42",
    t_global: 25,
    z_global: "173,93",
  },
  {
    profile_section_id: 5966,
    name: "PIPBN127X28",
    type: "Pipe",
    designation: "PIPBN127X28",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "87,08",
    d_global: 127,
    i_global: "1152,24",
    t_global: 28,
    z_global: "181,46",
  },
  {
    profile_section_id: 5967,
    name: "PIPBN127X30",
    type: "Pipe",
    designation: "PIPBN127X30",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "91,42",
    d_global: 127,
    i_global: "1178,07",
    t_global: 30,
    z_global: "185,52",
  },
  {
    profile_section_id: 5968,
    name: "PIPBN127X32",
    type: "Pipe",
    designation: "PIPBN127X32",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "95,5",
    d_global: 127,
    i_global: "1199,65",
    t_global: 32,
    z_global: "188,92",
  },
  {
    profile_section_id: 5969,
    name: "PIPBN127X36",
    type: "Pipe",
    designation: "PIPBN127X36",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "102,92",
    d_global: 127,
    i_global: "1232,06",
    t_global: 36,
    z_global: "194,03",
  },
  {
    profile_section_id: 5970,
    name: "PIPBN132X13",
    type: "Pipe",
    designation: "PIPBN132X13",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "48,6",
    d_global: 132,
    i_global: "870,55",
    t_global: 13,
    z_global: "131,9",
  },
  {
    profile_section_id: 5971,
    name: "PIPBN132X21",
    type: "Pipe",
    designation: "PIPBN132X21",
    shape: "PIPE",
    country_code: "Dutch",
    ax_global: "73,23",
    d_global: 132,
    i_global: "1168,21",
    t_global: 21,
    z_global: 177,
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: null,
    bf: null,
    c: null,
    ct: null,
    d: null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: null,
    i: null,
    i_global: p.i_global ? fixValueToNumber(p.i_global, "float") : undefined,
    ix: null,
    iy: null,
    iz: null,
    k: null,
    k1: null,
    od: null,
    r1: null,
    r2: null,
    rz: null,
    t: null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: null,
    tw: null,
    z: null,
    z_global: p.z_global ? fixValueToNumber(p.z_global, "float") : undefined,
    zx: null,
    zy: null,
    zz: null,
    width: 0,
    width_global: 0,
    thickness: 0,
    thickness_global: 0,
    height: 0,
    height_global: 0,
  };
  return profile;
});
