import { fixValueToNumber } from "../../components/3d-models/utils";

export const pipingLappedFlanges = [
  {
    piping_flange_id: 1,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 89,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "60,3",
    k: "",
    e: "",
    c: "11,2",
    r: 3,
    r_mini: "",
    x: 30,
    y: 16,
    a: "",
    b: "22,9",
    weigth: "0,8",
  },
  {
    piping_flange_id: 2,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 99,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "69,8",
    k: "",
    e: "",
    c: "12,7",
    r: 3,
    r_mini: "",
    x: 38,
    y: 16,
    a: "",
    b: "28,2",
    weigth: "0,9",
  },
  {
    piping_flange_id: 3,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 108,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "79,4",
    k: "63,5",
    e: "",
    c: "14,3",
    r: 3,
    r_mini: "",
    x: 49,
    y: 17,
    a: "",
    b: 35,
    weigth: 1,
  },
  {
    piping_flange_id: 4,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 117,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "88,9",
    k: "73,2",
    e: "",
    c: "15,7",
    r: 5,
    r_mini: "",
    x: 59,
    y: 21,
    a: "",
    b: "43,7",
    weigth: "1,3",
  },
  {
    piping_flange_id: 5,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 127,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "98,4",
    k: "82,5",
    e: "",
    c: "17,5",
    r: 6,
    r_mini: "",
    x: 65,
    y: 22,
    a: "",
    b: 50,
    weigth: "1,5",
  },
  {
    piping_flange_id: 6,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 152,
    dr_no: 4,
    dr_d: 19,
    dr_g: "120,6",
    k: "101,6",
    e: "",
    c: "19,1",
    r: 8,
    r_mini: "",
    x: 78,
    y: 25,
    a: "",
    b: "62,5",
    weigth: "2,3",
  },
  {
    piping_flange_id: 7,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 178,
    dr_no: 4,
    dr_d: 19,
    dr_g: "139,7",
    k: "120,7",
    e: "",
    c: "22,3",
    r: 8,
    r_mini: "",
    x: 90,
    y: 29,
    a: "",
    b: "75,4",
    weigth: "3,7",
  },
  {
    piping_flange_id: 8,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 190,
    dr_no: 4,
    dr_d: 19,
    dr_g: "152,4",
    k: "133,4",
    e: "",
    c: "23,9",
    r: 10,
    r_mini: "",
    x: 108,
    y: 30,
    a: "",
    b: "91,4",
    weigth: "4,2",
  },
  {
    piping_flange_id: 9,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 216,
    dr_no: 8,
    dr_d: 19,
    dr_g: "177,8",
    k: 154,
    e: "",
    c: "23,9",
    r: 11,
    r_mini: "",
    x: 122,
    y: 32,
    a: "",
    b: "104,1",
    weigth: "5,3",
  },
  {
    piping_flange_id: 10,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 229,
    dr_no: 8,
    dr_d: 19,
    dr_g: "190,5",
    k: "171,5",
    e: "",
    c: "23,9",
    r: 11,
    r_mini: "",
    x: 135,
    y: 33,
    a: "",
    b: "116,8",
    weigth: "5,9",
  },
  {
    piping_flange_id: 11,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 254,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "215,9",
    k: "193,5",
    e: "",
    c: "23,9",
    r: 11,
    r_mini: "",
    x: 164,
    y: 37,
    a: "",
    b: "144,5",
    weigth: 7,
  },
  {
    piping_flange_id: 12,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 279,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "241,3",
    k: 219,
    e: "",
    c: "25,4",
    r: 13,
    r_mini: "",
    x: 192,
    y: 40,
    a: "",
    b: "171,4",
    weigth: "8,5",
  },
  {
    piping_flange_id: 13,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 343,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "298,4",
    k: 273,
    e: "",
    c: "28,5",
    r: 13,
    r_mini: "",
    x: 246,
    y: 44,
    a: "",
    b: "222,2",
    weigth: "13,5",
  },
  {
    piping_flange_id: 14,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 406,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: 362,
    k: "330,2",
    e: "",
    c: "30,2",
    r: 13,
    r_mini: "",
    x: 305,
    y: 49,
    a: "",
    b: "277,4",
    weigth: "19,5",
  },
  {
    piping_flange_id: 15,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 483,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: "431,8",
    k: "406,4",
    e: "",
    c: "31,8",
    r: 13,
    r_mini: "",
    x: 365,
    y: 56,
    a: "",
    b: "328,2",
    weigth: 29,
  },
  {
    piping_flange_id: 16,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 533,
    dr_no: 12,
    dr_d: "28,5",
    dr_g: "476,2",
    k: "425,5",
    e: "",
    c: 35,
    r: 13,
    r_mini: "",
    x: 400,
    y: 79,
    a: "",
    b: "360,2",
    weigth: 45,
  },
  {
    piping_flange_id: 17,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 597,
    dr_no: 16,
    dr_d: "28,5",
    dr_g: "539,8",
    k: "482,6",
    e: "",
    c: "36,6",
    r: 13,
    r_mini: "",
    x: 457,
    y: 87,
    a: "",
    b: "411,2",
    weigth: 58,
  },
  {
    piping_flange_id: 18,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 635,
    dr_no: 16,
    dr_d: "31,8",
    dr_g: "577,8",
    k: "546,1",
    e: "",
    c: "39,7",
    r: 13,
    r_mini: "",
    x: 505,
    y: 97,
    a: "",
    b: "462,3",
    weigth: 66,
  },
  {
    piping_flange_id: 19,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 698,
    dr_no: 20,
    dr_d: "31,8",
    dr_g: 635,
    k: "596,9",
    e: "",
    c: "42,9",
    r: 13,
    r_mini: "",
    x: 559,
    y: 103,
    a: "",
    b: "514,4",
    weigth: 84,
  },
  {
    piping_flange_id: 20,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 150,
    o: 813,
    dr_no: 20,
    dr_d: 35,
    dr_g: "749,3",
    k: "711,2",
    e: "",
    c: "47,7",
    r: 13,
    r_mini: "",
    x: 664,
    y: 111,
    a: "",
    b: 616,
    weigth: 118,
  },
  {
    piping_flange_id: 21,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 95,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "66,7",
    k: "50,8",
    e: "5,56",
    c: "14,2",
    r: 3,
    r_mini: "",
    x: 38,
    y: 22,
    a: "",
    b: "22,9",
    weigth: "1,2",
  },
  {
    piping_flange_id: 22,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 117,
    dr_no: 4,
    dr_d: 19,
    dr_g: "82,6",
    k: "63,5",
    e: "6,35",
    c: "15,7",
    r: 3,
    r_mini: "",
    x: 48,
    y: 25,
    a: "",
    b: "28,2",
    weigth: "1,3",
  },
  {
    piping_flange_id: 23,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    k: "69,9",
    e: "6,35",
    c: "17,5",
    r: 3,
    r_mini: "",
    x: 54,
    y: 27,
    a: "",
    b: 35,
    weigth: "1,4",
  },
  {
    piping_flange_id: 24,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 133,
    dr_no: 4,
    dr_d: 19,
    dr_g: "98,4",
    k: "79,2",
    e: "6,35",
    c: 19,
    r: 5,
    r_mini: "",
    x: 63,
    y: 27,
    a: "",
    b: "43,7",
    weigth: "1,8",
  },
  {
    piping_flange_id: 25,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    k: "90,4",
    e: "6,35",
    c: "20,6",
    r: 6,
    r_mini: "",
    x: 70,
    y: 30,
    a: "",
    b: 50,
    weigth: "2,5",
  },
  {
    piping_flange_id: 26,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    k: 108,
    e: "7,92",
    c: "22,4",
    r: 8,
    r_mini: "",
    x: 84,
    y: 33,
    a: "",
    b: "62,5",
    weigth: 3,
  },
  {
    piping_flange_id: 27,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    k: 127,
    e: "7,92",
    c: "25,4",
    r: 8,
    r_mini: "",
    x: 100,
    y: 38,
    a: "",
    b: "75,4",
    weigth: "4,5",
  },
  {
    piping_flange_id: 28,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    k: "146,1",
    e: "7,92",
    c: "28,4",
    r: 10,
    r_mini: "",
    x: 117,
    y: 43,
    a: "",
    b: "91,4",
    weigth: 6,
  },
  {
    piping_flange_id: 29,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 229,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "184,1",
    k: "158,8",
    e: "7,92",
    c: "30,2",
    r: 10,
    r_mini: "",
    x: 133,
    y: 44,
    a: "",
    b: "104,1",
    weigth: "7,5",
  },
  {
    piping_flange_id: 30,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 254,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: 200,
    k: "174,8",
    e: "7,92",
    c: "31,8",
    r: 11,
    r_mini: "",
    x: 146,
    y: 48,
    a: "",
    b: "116,8",
    weigth: "10,1",
  },
  {
    piping_flange_id: 31,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 279,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: 235,
    k: "209,6",
    e: "7,92",
    c: 35,
    r: 11,
    r_mini: "",
    x: 178,
    y: 51,
    a: "",
    b: "144,5",
    weigth: "12,5",
  },
  {
    piping_flange_id: 32,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 318,
    dr_no: 12,
    dr_d: "22,2",
    dr_g: "269,9",
    k: "241,3",
    e: "7,92",
    c: "36,6",
    r: 13,
    r_mini: "",
    x: 206,
    y: 52,
    a: "",
    b: "171,4",
    weigth: "17,5",
  },
  {
    piping_flange_id: 33,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 381,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: "330,2",
    k: "301,8",
    e: "7,92",
    c: "41,1",
    r: 13,
    r_mini: "",
    x: 260,
    y: 62,
    a: "",
    b: "222,2",
    weigth: 26,
  },
  {
    piping_flange_id: 34,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 444,
    dr_no: 16,
    dr_d: "28,5",
    dr_g: "387,4",
    k: "355,6",
    e: "7,92",
    c: "47,8",
    r: 13,
    r_mini: "",
    x: 320,
    y: 95,
    a: "",
    b: "277,4",
    weigth: 41,
  },
  {
    piping_flange_id: 35,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 521,
    dr_no: 16,
    dr_d: "31,8",
    dr_g: "450,8",
    k: "412,8",
    e: "7,92",
    c: "50,8",
    r: 13,
    r_mini: "",
    x: 375,
    y: 102,
    a: "",
    b: "328,2",
    weigth: 63,
  },
  {
    piping_flange_id: 36,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 584,
    dr_no: 20,
    dr_d: "31,8",
    dr_g: "514,4",
    k: "457,2",
    e: "7,92",
    c: "53,8",
    r: 13,
    r_mini: "",
    x: 425,
    y: 111,
    a: "",
    b: "360,2",
    weigth: 86,
  },
  {
    piping_flange_id: 37,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 648,
    dr_no: 20,
    dr_d: 35,
    dr_g: "571,5",
    k: 508,
    e: "7,92",
    c: "57,2",
    r: 13,
    r_mini: "",
    x: 483,
    y: 121,
    a: "",
    b: "411,2",
    weigth: 109,
  },
  {
    piping_flange_id: 38,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 711,
    dr_no: 24,
    dr_d: 35,
    dr_g: "628,6",
    k: "574,5",
    e: "7,92",
    c: "60,5",
    r: 13,
    r_mini: "",
    x: 533,
    y: 130,
    a: "",
    b: "462,3",
    weigth: 138,
  },
  {
    piping_flange_id: 39,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 775,
    dr_no: 24,
    dr_d: 35,
    dr_g: "685,8",
    k: 635,
    e: "9,52",
    c: "63,5",
    r: 13,
    r_mini: "",
    x: 587,
    y: 140,
    a: "",
    b: "514,4",
    weigth: 170,
  },
  {
    piping_flange_id: 40,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 300,
    o: 914,
    dr_no: 24,
    dr_d: "41,1",
    dr_g: "812,8",
    k: "749,3",
    e: "11,13",
    c: "69,9",
    r: 13,
    r_mini: "",
    x: 701,
    y: 152,
    a: "",
    b: 616,
    weigth: 241,
  },
  {
    piping_flange_id: 41,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 95,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "66,7",
    k: "50,8",
    e: "5,56",
    c: "14,2",
    r: 3,
    r_mini: "",
    x: 38,
    y: 22,
    a: "",
    b: "22,9",
    weigth: "1,3",
  },
  {
    piping_flange_id: 42,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 117,
    dr_no: 4,
    dr_d: 19,
    dr_g: "82,6",
    k: "63,5",
    e: "6,35",
    c: "15,7",
    r: 3,
    r_mini: "",
    x: 48,
    y: 25,
    a: "",
    b: "29,2",
    weigth: "1,4",
  },
  {
    piping_flange_id: 43,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    k: "69,9",
    e: "6,35",
    c: "17,5",
    r: 3,
    r_mini: "",
    x: 54,
    y: 27,
    a: "",
    b: 35,
    weigth: "1,8",
  },
  {
    piping_flange_id: 44,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 133,
    dr_no: 4,
    dr_d: 19,
    dr_g: "98,4",
    k: "79,2",
    e: "6,35",
    c: "20,6",
    r: 5,
    r_mini: "",
    x: 64,
    y: 29,
    a: "",
    b: "43,7",
    weigth: "2,1",
  },
  {
    piping_flange_id: 45,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    k: "90,4",
    e: "6,35",
    c: "22,4",
    r: 6,
    r_mini: "",
    x: 70,
    y: 32,
    a: "",
    b: 50,
    weigth: "3,1",
  },
  {
    piping_flange_id: 46,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    k: 108,
    e: "7,92",
    c: "25,4",
    r: 8,
    r_mini: "",
    x: 84,
    y: 37,
    a: "",
    b: "62,5",
    weigth: 4,
  },
  {
    piping_flange_id: 47,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    k: 127,
    e: "7,92",
    c: "28,4",
    r: 8,
    r_mini: "",
    x: 100,
    y: 41,
    a: "",
    b: "75,4",
    weigth: "5,4",
  },
  {
    piping_flange_id: 48,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    k: "146,1",
    e: "7,92",
    c: "31,8",
    r: 10,
    r_mini: "",
    x: 118,
    y: 46,
    a: "",
    b: "91,4",
    weigth: 7,
  },
  {
    piping_flange_id: 49,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 229,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "184,1",
    k: "158,8",
    e: "7,92",
    c: 35,
    r: 10,
    r_mini: "",
    x: 133,
    y: 49,
    a: "",
    b: "104,1",
    weigth: "8,9",
  },
  {
    piping_flange_id: 50,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 254,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "200,1",
    k: "174,8",
    e: "7,92",
    c: 35,
    r: 11,
    r_mini: "",
    x: 146,
    y: 51,
    a: "",
    b: "116,8",
    weigth: 13,
  },
  {
    piping_flange_id: 51,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 279,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: 235,
    k: "209,6",
    e: "7,92",
    c: "38,1",
    r: 11,
    r_mini: "",
    x: 178,
    y: 54,
    a: "",
    b: "144,5",
    weigth: "18,5",
  },
  {
    piping_flange_id: 52,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 318,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: "269,9",
    k: "241,3",
    e: "7,92",
    c: "41,1",
    r: 13,
    r_mini: "",
    x: 206,
    y: 57,
    a: "",
    b: "171,4",
    weigth: 25,
  },
  {
    piping_flange_id: 53,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 381,
    dr_no: 12,
    dr_d: "28,5",
    dr_g: "330,2",
    k: "301,8",
    e: "7,92",
    c: "47,8",
    r: 13,
    r_mini: "",
    x: 260,
    y: 68,
    a: "",
    b: "222,2",
    weigth: 34,
  },
  {
    piping_flange_id: 54,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 444,
    dr_no: 16,
    dr_d: "31,8",
    dr_g: "387,4",
    k: "355,6",
    e: "7,92",
    c: "53,8",
    r: 13,
    r_mini: "",
    x: 320,
    y: 102,
    a: "",
    b: "277,4",
    weigth: 51,
  },
  {
    piping_flange_id: 55,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 521,
    dr_no: 16,
    dr_d: 35,
    dr_g: "450,8",
    k: "412,8",
    e: "7,92",
    c: "57,2",
    r: 13,
    r_mini: "",
    x: 375,
    y: 108,
    a: "",
    b: "328,2",
    weigth: 69,
  },
  {
    piping_flange_id: 56,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 584,
    dr_no: 20,
    dr_d: 35,
    dr_g: "514,4",
    k: "457,2",
    e: "7,92",
    c: "60,5",
    r: 13,
    r_mini: "",
    x: 425,
    y: 117,
    a: "",
    b: "360,2",
    weigth: 95,
  },
  {
    piping_flange_id: 57,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 648,
    dr_no: 20,
    dr_d: "38,1",
    dr_g: "571,5",
    k: 508,
    e: "7,92",
    c: "63,5",
    r: 13,
    r_mini: "",
    x: 483,
    y: 127,
    a: "",
    b: "411,2",
    weigth: 127,
  },
  {
    piping_flange_id: 58,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 711,
    dr_no: 24,
    dr_d: "38,1",
    dr_g: "628,6",
    k: "574,5",
    e: "7,92",
    c: "66,5",
    r: 13,
    r_mini: "",
    x: 533,
    y: 137,
    a: "",
    b: "462,3",
    weigth: 157,
  },
  {
    piping_flange_id: 59,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 775,
    dr_no: 24,
    dr_d: "41,1",
    dr_g: "685,8",
    k: 635,
    e: "9,52",
    c: "69,9",
    r: 13,
    r_mini: "",
    x: 587,
    y: 146,
    a: "",
    b: "514,4",
    weigth: 191,
  },
  {
    piping_flange_id: 60,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 400,
    o: 914,
    dr_no: 24,
    dr_d: "47,8",
    dr_g: "812,8",
    k: "749,3",
    e: "11,13",
    c: "76,2",
    r: 13,
    r_mini: "",
    x: 701,
    y: 159,
    a: "",
    b: 616,
    weigth: 279,
  },
  {
    piping_flange_id: 61,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 95,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "66,7",
    k: "50,8",
    e: "5,56",
    c: "14,2",
    r: 3,
    r_mini: "",
    x: 38,
    y: 22,
    a: "",
    b: "22,9",
    weigth: "1,3",
  },
  {
    piping_flange_id: 62,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 117,
    dr_no: 4,
    dr_d: 19,
    dr_g: "82,6",
    k: "63,5",
    e: "6,35",
    c: "15,7",
    r: 3,
    r_mini: "",
    x: 48,
    y: 25,
    a: "",
    b: "29,2",
    weigth: "1,4",
  },
  {
    piping_flange_id: 63,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    k: "69,9",
    e: "6,35",
    c: "17,5",
    r: 3,
    r_mini: "",
    x: 54,
    y: 27,
    a: "",
    b: 35,
    weigth: "1,8",
  },
  {
    piping_flange_id: 64,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 133,
    dr_no: 4,
    dr_d: 19,
    dr_g: "98,4",
    k: "79,2",
    e: "6,35",
    c: "20,6",
    r: 5,
    r_mini: "",
    x: 64,
    y: 29,
    a: "",
    b: "43,7",
    weigth: "2,1",
  },
  {
    piping_flange_id: 65,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    k: "90,4",
    e: "6,35",
    c: "22,4",
    r: 6,
    r_mini: "",
    x: 70,
    y: 32,
    a: "",
    b: 50,
    weigth: "3,1",
  },
  {
    piping_flange_id: 66,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    k: 108,
    e: "7,92",
    c: "25,4",
    r: 8,
    r_mini: "",
    x: 84,
    y: 37,
    a: "",
    b: "62,5",
    weigth: 4,
  },
  {
    piping_flange_id: 67,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    k: 127,
    e: "7,92",
    c: "28,4",
    r: 8,
    r_mini: "",
    x: 100,
    y: 41,
    a: "",
    b: "75,4",
    weigth: "5,4",
  },
  {
    piping_flange_id: 68,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    k: "146,1",
    e: "7,92",
    c: "31,8",
    r: 10,
    r_mini: "",
    x: 118,
    y: 46,
    a: "",
    b: "91,4",
    weigth: 7,
  },
  {
    piping_flange_id: 69,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 229,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "184,1",
    k: "158,8",
    e: "7,92",
    c: 35,
    r: 10,
    r_mini: "",
    x: 133,
    y: 49,
    a: "",
    b: "104,1",
    weigth: "8,9",
  },
  {
    piping_flange_id: 70,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 273,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "215,9",
    k: "174,8",
    e: "7,92",
    c: "38,1",
    r: 11,
    r_mini: "",
    x: 152,
    y: 54,
    a: "",
    b: "116,8",
    weigth: 16,
  },
  {
    piping_flange_id: 71,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 330,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "266,7",
    k: "209,6",
    e: "7,92",
    c: "44,5",
    r: 11,
    r_mini: "",
    x: 189,
    y: 60,
    a: "",
    b: "144,5",
    weigth: 25,
  },
  {
    piping_flange_id: 72,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 356,
    dr_no: 12,
    dr_d: "28,5",
    dr_g: "292,1",
    k: "241,3",
    e: "7,92",
    c: "47,8",
    r: 13,
    r_mini: "",
    x: 222,
    y: 67,
    a: "",
    b: "171,4",
    weigth: 30,
  },
  {
    piping_flange_id: 73,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 419,
    dr_no: 12,
    dr_d: "31,8",
    dr_g: "349,2",
    k: "301,8",
    e: "7,92",
    c: "55,6",
    r: 13,
    r_mini: "",
    x: 273,
    y: 76,
    a: "",
    b: "222,2",
    weigth: 43,
  },
  {
    piping_flange_id: 74,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 508,
    dr_no: 16,
    dr_d: 35,
    dr_g: "431,8",
    k: "355,6",
    e: "7,92",
    c: "63,5",
    r: 13,
    r_mini: "",
    x: 343,
    y: 111,
    a: "",
    b: "277,4",
    weigth: 89,
  },
  {
    piping_flange_id: 75,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 559,
    dr_no: 20,
    dr_d: 35,
    dr_g: 489,
    k: "412,8",
    e: "7,92",
    c: "66,5",
    r: 13,
    r_mini: "",
    x: 400,
    y: 117,
    a: "",
    b: "328,2",
    weigth: 109,
  },
  {
    piping_flange_id: 76,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 603,
    dr_no: 20,
    dr_d: "38,1",
    dr_g: 527,
    k: "457,2",
    e: "7,92",
    c: "69,9",
    r: 13,
    r_mini: "",
    x: 432,
    y: 127,
    a: "",
    b: "360,2",
    weigth: 132,
  },
  {
    piping_flange_id: 77,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 686,
    dr_no: 20,
    dr_d: "41,1",
    dr_g: "603,2",
    k: 508,
    e: "7,92",
    c: "76,2",
    r: 13,
    r_mini: "",
    x: 495,
    y: 140,
    a: "",
    b: "411,2",
    weigth: 182,
  },
  {
    piping_flange_id: 78,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 743,
    dr_no: 20,
    dr_d: "44,5",
    dr_g: 654,
    k: "574,5",
    e: "7,92",
    c: "82,6",
    r: 13,
    r_mini: "",
    x: 546,
    y: 152,
    a: "",
    b: "462,3",
    weigth: 213,
  },
  {
    piping_flange_id: 79,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 813,
    dr_no: 24,
    dr_d: "44,5",
    dr_g: "723,9",
    k: 635,
    e: "9,52",
    c: "88,9",
    r: 13,
    r_mini: "",
    x: 610,
    y: 165,
    a: "",
    b: "514,4",
    weigth: 274,
  },
  {
    piping_flange_id: 80,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 600,
    o: 940,
    dr_no: 24,
    dr_d: "50,8",
    dr_g: "838,2",
    k: "749,3",
    e: "11,13",
    c: "101,6",
    r: 13,
    r_mini: "",
    x: 718,
    y: 184,
    a: "",
    b: 616,
    weigth: 393,
  },
  {
    piping_flange_id: 81,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 121,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "82,6",
    k: "60,5",
    e: "6,35",
    c: "22,4",
    r: 3,
    r_mini: "",
    x: 38,
    y: 32,
    a: "",
    b: "22,9",
    weigth: "1,7",
  },
  {
    piping_flange_id: 82,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 130,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "88,9",
    k: "66,5",
    e: "6,35",
    c: "25,4",
    r: 3,
    r_mini: "",
    x: 44,
    y: 35,
    a: "",
    b: "29,2",
    weigth: "2,3",
  },
  {
    piping_flange_id: 83,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 149,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: "101,6",
    k: "71,4",
    e: "6,35",
    c: "28,4",
    r: 3,
    r_mini: "",
    x: 52,
    y: 41,
    a: "",
    b: 35,
    weigth: "3,4",
  },
  {
    piping_flange_id: 84,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 159,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: "111,1",
    k: 81,
    e: "6,35",
    c: "28,4",
    r: 5,
    r_mini: "",
    x: 63,
    y: 41,
    a: "",
    b: "43,7",
    weigth: "3,9",
  },
  {
    piping_flange_id: 85,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 178,
    dr_no: 4,
    dr_d: "28,5",
    dr_g: "123,8",
    k: "91,9",
    e: "6,35",
    c: "31,8",
    r: 6,
    r_mini: "",
    x: 70,
    y: 44,
    a: "",
    b: 50,
    weigth: "5,4",
  },
  {
    piping_flange_id: 86,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 216,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "165,1",
    k: "123,9",
    e: "7,92",
    c: "38,1",
    r: 8,
    r_mini: "",
    x: 105,
    y: 57,
    a: "",
    b: "62,5",
    weigth: "11,3",
  },
  {
    piping_flange_id: 87,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 244,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "190,5",
    k: "136,7",
    e: "7,92",
    c: "41,1",
    r: 8,
    r_mini: "",
    x: 124,
    y: 63,
    a: "",
    b: "75,4",
    weigth: "15,9",
  },
  {
    piping_flange_id: 88,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 241,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "190,5",
    k: "155,4",
    e: "7,92",
    c: "38,1",
    r: 10,
    r_mini: "",
    x: 127,
    y: 54,
    a: "",
    b: "91,4",
    weigth: "11,6",
  },
  {
    piping_flange_id: 89,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 292,
    dr_no: 8,
    dr_d: "31,8",
    dr_g: 235,
    k: "180,8",
    e: "7,92",
    c: "44,5",
    r: 11,
    r_mini: "",
    x: 159,
    y: 70,
    a: "",
    b: "116,8",
    weigth: "19,8",
  },
  {
    piping_flange_id: 90,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 349,
    dr_no: 8,
    dr_d: 35,
    dr_g: "279,4",
    k: "215,9",
    e: "7,92",
    c: "50,8",
    r: 11,
    r_mini: "",
    x: 190,
    y: 79,
    a: "",
    b: "144,5",
    weigth: 32,
  },
  {
    piping_flange_id: 91,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 381,
    dr_no: 12,
    dr_d: "31,8",
    dr_g: "317,5",
    k: "241,3",
    e: "7,92",
    c: "55,6",
    r: 13,
    r_mini: "",
    x: 235,
    y: 86,
    a: "",
    b: "171,4",
    weigth: 41,
  },
  {
    piping_flange_id: 92,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 470,
    dr_no: 12,
    dr_d: "38,1",
    dr_g: "393,7",
    k: "307,8",
    e: "7,92",
    c: "63,5",
    r: 13,
    r_mini: "",
    x: 298,
    y: 114,
    a: "",
    b: "222,2",
    weigth: 85,
  },
  {
    piping_flange_id: 93,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 546,
    dr_no: 16,
    dr_d: "38,1",
    dr_g: "469,9",
    k: 362,
    e: "7,92",
    c: "69,9",
    r: 13,
    r_mini: "",
    x: 368,
    y: 127,
    a: "",
    b: "277,4",
    weigth: 126,
  },
  {
    piping_flange_id: 94,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 610,
    dr_no: 20,
    dr_d: "38,1",
    dr_g: "533,4",
    k: "419,1",
    e: "7,92",
    c: "79,2",
    r: 13,
    r_mini: "",
    x: 419,
    y: 143,
    a: "",
    b: "328,2",
    weigth: 168,
  },
  {
    piping_flange_id: 95,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 641,
    dr_no: 20,
    dr_d: "41,1",
    dr_g: "558,8",
    k: "466,9",
    e: "11,13",
    c: "85,9",
    r: 13,
    r_mini: "",
    x: 451,
    y: 155,
    a: "",
    b: "360,2",
    weigth: 180,
  },
  {
    piping_flange_id: 96,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 705,
    dr_no: 20,
    dr_d: "44,5",
    dr_g: 616,
    k: "523,7",
    e: "11,13",
    c: "88,9",
    r: 13,
    r_mini: "",
    x: 508,
    y: 165,
    a: "",
    b: "411,2",
    weigth: 222,
  },
  {
    piping_flange_id: 97,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 787,
    dr_no: 20,
    dr_d: "50,8",
    dr_g: "685,8",
    k: "593,9",
    e: "12,7",
    c: "101,6",
    r: 13,
    r_mini: "",
    x: 565,
    y: 190,
    a: "",
    b: "462,3",
    weigth: 304,
  },
  {
    piping_flange_id: 98,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 857,
    dr_no: 20,
    dr_d: "53,8",
    dr_g: "749,3",
    k: "647,7",
    e: "12,7",
    c: 108,
    r: 13,
    r_mini: "",
    x: 622,
    y: 210,
    a: "",
    b: "514,4",
    weigth: 394,
  },
  {
    piping_flange_id: 99,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 900,
    o: 1041,
    dr_no: 20,
    dr_d: "66,5",
    dr_g: "901,7",
    k: "771,7",
    e: "15,88",
    c: "139,7",
    r: 13,
    r_mini: "",
    x: 749,
    y: 267,
    a: "",
    b: 616,
    weigth: 753,
  },
  {
    piping_flange_id: 100,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 121,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "82,6",
    k: "60,5",
    e: "6,35",
    c: "22,4",
    r: 3,
    r_mini: "",
    x: 38,
    y: 32,
    a: "",
    b: "22,9",
    weigth: "1,7",
  },
  {
    piping_flange_id: 101,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 130,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "88,9",
    k: "66,5",
    e: "6,35",
    c: "25,4",
    r: 3,
    r_mini: "",
    x: 44,
    y: 35,
    a: "",
    b: "29,2",
    weigth: "2,3",
  },
  {
    piping_flange_id: 102,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 149,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: "101,6",
    k: "71,4",
    e: "6,35",
    c: "28,4",
    r: 3,
    r_mini: "",
    x: 52,
    y: 41,
    a: "",
    b: 35,
    weigth: "3,4",
  },
  {
    piping_flange_id: 103,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 159,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: "111,1",
    k: 81,
    e: "6,35",
    c: "28,4",
    r: 5,
    r_mini: "",
    x: 63,
    y: 41,
    a: "",
    b: "43,7",
    weigth: "3,9",
  },
  {
    piping_flange_id: 104,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 178,
    dr_no: 4,
    dr_d: "28,5",
    dr_g: "123,8",
    k: "91,9",
    e: "6,35",
    c: "31,8",
    r: 6,
    r_mini: "",
    x: 70,
    y: 44,
    a: "",
    b: 50,
    weigth: "5,4",
  },
  {
    piping_flange_id: 105,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 216,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "165,1",
    k: "123,9",
    e: "7,92",
    c: "38,1",
    r: 8,
    r_mini: "",
    x: 105,
    y: 57,
    a: "",
    b: "62,5",
    weigth: "11,3",
  },
  {
    piping_flange_id: 106,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 244,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "190,5",
    k: "136,7",
    e: "7,92",
    c: "41,1",
    r: 8,
    r_mini: "",
    x: 124,
    y: 63,
    a: "",
    b: "75,4",
    weigth: "15,9",
  },
  {
    piping_flange_id: 107,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 267,
    dr_no: 8,
    dr_d: "31,8",
    dr_g: "203,2",
    k: "168,1",
    e: "7,92",
    c: "47,8",
    r: 10,
    r_mini: "",
    x: 133,
    y: 73,
    a: "",
    b: "91,4",
    weigth: "21,3",
  },
  {
    piping_flange_id: 108,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 311,
    dr_no: 8,
    dr_d: 35,
    dr_g: "241,3",
    k: "193,5",
    e: "7,92",
    c: "53,8",
    r: 11,
    r_mini: "",
    x: 162,
    y: 90,
    a: "",
    b: "116,8",
    weigth: 34,
  },
  {
    piping_flange_id: 109,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 375,
    dr_no: 8,
    dr_d: "41,1",
    dr_g: "292,1",
    k: "228,6",
    e: "7,92",
    c: "73,2",
    r: 11,
    r_mini: "",
    x: 197,
    y: 105,
    a: "",
    b: "144,5",
    weigth: 63,
  },
  {
    piping_flange_id: 110,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 394,
    dr_no: 12,
    dr_d: "38,1",
    dr_g: "317,5",
    k: 248,
    e: "9,52",
    c: "82,6",
    r: 13,
    r_mini: "",
    x: 229,
    y: 119,
    a: "",
    b: "171,4",
    weigth: 77,
  },
  {
    piping_flange_id: 111,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 483,
    dr_no: 12,
    dr_d: "44,5",
    dr_g: "393,7",
    k: 318,
    e: "11,13",
    c: "91,9",
    r: 13,
    r_mini: "",
    x: 292,
    y: 143,
    a: "",
    b: "222,2",
    weigth: 129,
  },
  {
    piping_flange_id: 112,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 584,
    dr_no: 12,
    dr_d: "50,8",
    dr_g: "482,6",
    k: 371,
    e: "11,13",
    c: 108,
    r: 13,
    r_mini: "",
    x: 368,
    y: 178,
    a: "",
    b: "277,4",
    weigth: 219,
  },
  {
    piping_flange_id: 113,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 673,
    dr_no: 16,
    dr_d: "53,8",
    dr_g: "571,5",
    k: 438,
    e: "14,27",
    c: 124,
    r: 13,
    r_mini: "",
    x: 451,
    y: 219,
    a: "",
    b: "328,2",
    weigth: 285,
  },
  {
    piping_flange_id: 114,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 749,
    dr_no: 16,
    dr_d: "60,5",
    dr_g: 635,
    k: 489,
    e: "15,88",
    c: "133,4",
    r: 13,
    r_mini: "",
    x: 495,
    y: 241,
    a: "",
    b: "360,2",
    weigth: 360,
  },
  {
    piping_flange_id: 115,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 826,
    dr_no: 16,
    dr_d: "66,5",
    dr_g: "704,8",
    k: 546,
    e: "17,48",
    c: 146,
    r: 13,
    r_mini: "",
    x: 552,
    y: 260,
    a: "",
    b: "411,2",
    weigth: 460,
  },
  {
    piping_flange_id: 116,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 914,
    dr_no: 16,
    dr_d: "73,2",
    dr_g: "774,7",
    k: 613,
    e: "17,48",
    c: 162,
    r: 13,
    r_mini: "",
    x: 597,
    y: 276,
    a: "",
    b: "462,3",
    weigth: 621,
  },
  {
    piping_flange_id: 117,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 984,
    dr_no: 16,
    dr_d: "79,2",
    dr_g: "831,8",
    k: 673,
    e: "17,48",
    c: "177,8",
    r: 13,
    r_mini: "",
    x: 641,
    y: 292,
    a: "",
    b: "514,4",
    weigth: 772,
  },
  {
    piping_flange_id: 118,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 1500,
    o: 1168,
    dr_no: 16,
    dr_d: "91,9",
    dr_g: "990,6",
    k: 794,
    e: "20,62",
    c: "203,2",
    r: 13,
    r_mini: "",
    x: 762,
    y: 330,
    a: "",
    b: 616,
    weigth: 1236,
  },
  {
    piping_flange_id: 119,
    dn: 15,
    nps: "02.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 133,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "88,9",
    k: 65,
    e: "6,35",
    c: "30,2",
    r: 3,
    r_mini: "",
    x: 43,
    y: 40,
    a: "",
    b: "22,9",
    weigth: 3,
  },
  {
    piping_flange_id: 120,
    dn: 20,
    nps: "04.���",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 140,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "95,3",
    k: "73,2",
    e: "6,35",
    c: "31,8",
    r: 3,
    r_mini: "",
    x: 51,
    y: 43,
    a: "",
    b: "28,2",
    weigth: 4,
  },
  {
    piping_flange_id: 121,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 159,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: 108,
    k: "82,6",
    e: "6,35",
    c: 35,
    r: 3,
    r_mini: "",
    x: 57,
    y: 48,
    a: "",
    b: 35,
    weigth: 5,
  },
  {
    piping_flange_id: 122,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 184,
    dr_no: 4,
    dr_d: "28,5",
    dr_g: 130,
    k: "101,6",
    e: "7,92",
    c: "38,1",
    r: 5,
    r_mini: "",
    x: 73,
    y: 52,
    a: "",
    b: "43,7",
    weigth: 8,
  },
  {
    piping_flange_id: 123,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 203,
    dr_no: 4,
    dr_d: "31,8",
    dr_g: 146,
    k: "114,3",
    e: "7,92",
    c: "44,5",
    r: 6,
    r_mini: "",
    x: 79,
    y: 60,
    a: "",
    b: 50,
    weigth: 11,
  },
  {
    piping_flange_id: 124,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 235,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "171,5",
    k: "133,4",
    e: "7,92",
    c: "50,8",
    r: 8,
    r_mini: "",
    x: 95,
    y: 70,
    a: "",
    b: "62,5",
    weigth: 17,
  },
  {
    piping_flange_id: 125,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 267,
    dr_no: 8,
    dr_d: "31,8",
    dr_g: "196,9",
    k: "149,4",
    e: "9,52",
    c: "57,2",
    r: 8,
    r_mini: "",
    x: 114,
    y: 79,
    a: "",
    b: "75,4",
    weigth: 24,
  },
  {
    piping_flange_id: 126,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 305,
    dr_no: 8,
    dr_d: 35,
    dr_g: "228,6",
    k: "168,1",
    e: "9,52",
    c: "66,5",
    r: 10,
    r_mini: "",
    x: 133,
    y: 92,
    a: "",
    b: "91,4",
    weigth: 36,
  },
  {
    piping_flange_id: 127,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 356,
    dr_no: 8,
    dr_d: "41,1",
    dr_g: 273,
    k: "203,2",
    e: "11,13",
    c: "76,2",
    r: 11,
    r_mini: "",
    x: 165,
    y: 108,
    a: "",
    b: "116,8",
    weigth: 55,
  },
  {
    piping_flange_id: 128,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 419,
    dr_no: 8,
    dr_d: "47,8",
    dr_g: "323,9",
    k: "241,3",
    e: "12,7",
    c: "91,9",
    r: 11,
    r_mini: "",
    x: 203,
    y: 130,
    a: "",
    b: "144,5",
    weigth: 93,
  },
  {
    piping_flange_id: 129,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 483,
    dr_no: 8,
    dr_d: "53,8",
    dr_g: "368,3",
    k: "279,4",
    e: "12,7",
    c: 108,
    r: 13,
    r_mini: "",
    x: 235,
    y: 152,
    a: "",
    b: "171,4",
    weigth: 142,
  },
  {
    piping_flange_id: 130,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 552,
    dr_no: 12,
    dr_d: "53,8",
    dr_g: "438,2",
    k: "339,9",
    e: "14,27",
    c: 127,
    r: 13,
    r_mini: "",
    x: 305,
    y: 178,
    a: "",
    b: "222,2",
    weigth: 214,
  },
  {
    piping_flange_id: 131,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 673,
    dr_no: 12,
    dr_d: "66,5",
    dr_g: "539,8",
    k: "425,5",
    e: "17,48",
    c: "165,1",
    r: 13,
    r_mini: "",
    x: 375,
    y: 229,
    a: "",
    b: "277,4",
    weigth: 407,
  },
  {
    piping_flange_id: 132,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - LAPPED",
    class: 2500,
    o: 762,
    dr_no: 12,
    dr_d: "73,2",
    dr_g: "619,3",
    k: "495,3",
    e: "17,48",
    c: "184,2",
    r: 13,
    r_mini: "",
    x: 441,
    y: 254,
    a: "",
    b: "328,2",
    weigth: 573,
  },
].map((item) => ({
  ...item,
  nps: item.nps + "",
  o: fixValueToNumber(item.o, "float"),
  dr_d: fixValueToNumber(item.dr_d, "float"),
  dr_g: fixValueToNumber(item.dr_g, "float"),
  k: fixValueToNumber(item.k, "float"),
  e: fixValueToNumber(item.e, "float"),
  c: fixValueToNumber(item.c, "float"),
  r: fixValueToNumber(item.r, "float"),
  r_mini: fixValueToNumber(item.r_mini, "float"),
  x: fixValueToNumber(item.x, "float"),
  y: fixValueToNumber(item.y, "float"),
  a: fixValueToNumber(item.a, "float"),
  b: fixValueToNumber(item.b, "float"),
  weigth: fixValueToNumber(item.weigth, "float"),
}));
