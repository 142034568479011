import { fixValueToNumber } from "../../components/3d-models/utils";

export const pipingBlindFlanges = [
  {
    piping_flange_id: 3,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 108,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "79,4",
    k: "63,5",
    e: "",
    c: "14,3",
    r: "50,8",
    r_mini: "",
    x: 49,
    y: 17,
    a: "",
    b: "",
    weight: "1,4",
  },
  {
    piping_flange_id: 4,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 117,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "88,9",
    k: "73,2",
    e: "",
    c: "15,7",
    r: "63,5",
    r_mini: "",
    x: 59,
    y: 21,
    a: "",
    b: "",
    weight: "1,8",
  },
  {
    piping_flange_id: 5,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 127,
    dr_no: 4,
    dr_d: "15,8",
    dr_g: "98,4",
    k: "82,5",
    e: "",
    c: "17,5",
    r: 73,
    r_mini: "",
    x: 65,
    y: 22,
    a: "",
    b: "",
    weight: "2,2",
  },
  {
    piping_flange_id: 6,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 152,
    dr_no: 4,
    dr_d: 19,
    dr_g: "120,6",
    k: "101,6",
    e: "",
    c: "19,1",
    r: "92,1",
    r_mini: "",
    x: 78,
    y: 25,
    a: "",
    b: "",
    weight: "2,8",
  },
  {
    piping_flange_id: 7,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 178,
    dr_no: 4,
    dr_d: 19,
    dr_g: "139,7",
    k: "120,7",
    e: "",
    c: "22,3",
    r: "104,8",
    r_mini: "",
    x: 90,
    y: 29,
    a: "",
    b: "",
    weight: "4,7",
  },
  {
    piping_flange_id: 8,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 190,
    dr_no: 4,
    dr_d: 19,
    dr_g: "152,4",
    k: "133,4",
    e: "",
    c: "23,9",
    r: 127,
    r_mini: "",
    x: 108,
    y: 30,
    a: "",
    b: "",
    weight: "5,5",
  },
  {
    piping_flange_id: 9,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 216,
    dr_no: 8,
    dr_d: 19,
    dr_g: "177,8",
    k: 154,
    e: "",
    c: "23,9",
    r: "139,7",
    r_mini: "",
    x: 122,
    y: 32,
    a: "",
    b: "",
    weight: "6,8",
  },
  {
    piping_flange_id: 10,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 229,
    dr_no: 8,
    dr_d: 19,
    dr_g: "190,5",
    k: "171,5",
    e: "",
    c: "23,9",
    r: "157,2",
    r_mini: "",
    x: 135,
    y: 33,
    a: "",
    b: "",
    weight: 8,
  },
  {
    piping_flange_id: 11,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 254,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "215,9",
    k: "193,5",
    e: "",
    c: "23,9",
    r: "185,7",
    r_mini: "",
    x: 164,
    y: 37,
    a: "",
    b: "",
    weight: 9,
  },
  {
    piping_flange_id: 12,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 279,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "241,3",
    k: 219,
    e: "",
    c: "25,4",
    r: "215,9",
    r_mini: "",
    x: 192,
    y: 40,
    a: "",
    b: "",
    weight: 12,
  },
  {
    piping_flange_id: 13,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 343,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "298,4",
    k: 273,
    e: "",
    c: "28,5",
    r: "269,9",
    r_mini: "",
    x: 246,
    y: 44,
    a: "",
    b: "",
    weight: 20,
  },
  {
    piping_flange_id: 14,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 406,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: 362,
    k: "330,2",
    e: "",
    c: "30,2",
    r: "323,8",
    r_mini: "",
    x: 305,
    y: 49,
    a: "",
    b: "",
    weight: 32,
  },
  {
    piping_flange_id: 15,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 483,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: "431,8",
    k: "406,4",
    e: "",
    c: "31,8",
    r: 381,
    r_mini: "",
    x: 365,
    y: 56,
    a: "",
    b: "",
    weight: 40,
  },
  {
    piping_flange_id: 16,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 533,
    dr_no: 12,
    dr_d: "28,5",
    dr_g: "476,2",
    k: "425,5",
    e: "",
    c: 35,
    r: "412,8",
    r_mini: "",
    x: 400,
    y: 57,
    a: "",
    b: "",
    weight: 59,
  },
  {
    piping_flange_id: 17,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 597,
    dr_no: 16,
    dr_d: "28,5",
    dr_g: "539,8",
    k: "482,6",
    e: "",
    c: "36,6",
    r: "469,9",
    r_mini: "",
    x: 457,
    y: 63,
    a: "",
    b: "",
    weight: 77,
  },
  {
    piping_flange_id: 18,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 635,
    dr_no: 16,
    dr_d: "31,8",
    dr_g: "577,8",
    k: "546,1",
    e: "",
    c: "39,7",
    r: "533,4",
    r_mini: "",
    x: 505,
    y: 68,
    a: "",
    b: "",
    weight: 95,
  },
  {
    piping_flange_id: 19,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 698,
    dr_no: 20,
    dr_d: "31,8",
    dr_g: 635,
    k: "596,9",
    e: "",
    c: "42,9",
    r: "584,2",
    r_mini: "",
    x: 559,
    y: 73,
    a: "",
    b: "",
    weight: 123,
  },
  {
    piping_flange_id: 20,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 813,
    dr_no: 20,
    dr_d: 35,
    dr_g: "749,3",
    k: "711,2",
    e: "",
    c: "47,7",
    r: "692,2",
    r_mini: "",
    x: 664,
    y: 83,
    a: "",
    b: "",
    weight: 186,
  },
  {
    piping_flange_id: 23,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    k: "69,9",
    e: "6,35",
    c: "17,5",
    r: "50,8",
    r_mini: "",
    x: 54,
    y: 27,
    a: "",
    b: "",
    weight: 2,
  },
  {
    piping_flange_id: 24,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 133,
    dr_no: 4,
    dr_d: 19,
    dr_g: "98,4",
    k: "79,2",
    e: "6,35",
    c: 19,
    r: "63,5",
    r_mini: "",
    x: 63,
    y: 27,
    a: "",
    b: "",
    weight: "2,5",
  },
  {
    piping_flange_id: 25,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    k: "90,4",
    e: "6,35",
    c: "20,6",
    r: 73,
    r_mini: "",
    x: 70,
    y: 30,
    a: "",
    b: "",
    weight: 3,
  },
  {
    piping_flange_id: 26,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    k: 108,
    e: "7,92",
    c: "22,4",
    r: "92,1",
    r_mini: "",
    x: 84,
    y: 33,
    a: "",
    b: "",
    weight: "3,5",
  },
  {
    piping_flange_id: 27,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    k: 127,
    e: "7,92",
    c: "25,4",
    r: "104,8",
    r_mini: "",
    x: 100,
    y: 38,
    a: "",
    b: "",
    weight: "5,5",
  },
  {
    piping_flange_id: 28,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    k: "146,1",
    e: "7,92",
    c: "28,4",
    r: 127,
    r_mini: "",
    x: 117,
    y: 43,
    a: "",
    b: "",
    weight: 7,
  },
  {
    piping_flange_id: 29,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 229,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "184,1",
    k: "158,8",
    e: "7,92",
    c: "30,2",
    r: "139,7",
    r_mini: "",
    x: 133,
    y: 44,
    a: "",
    b: "",
    weight: 9,
  },
  {
    piping_flange_id: 30,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 254,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: 200,
    k: "174,8",
    e: "7,92",
    c: "31,8",
    r: "157,2",
    r_mini: "",
    x: 146,
    y: 48,
    a: "",
    b: "",
    weight: 12,
  },
  {
    piping_flange_id: 31,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 279,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: 235,
    k: "209,6",
    e: "7,92",
    c: 35,
    r: "185,7",
    r_mini: "",
    x: 178,
    y: 51,
    a: "",
    b: "",
    weight: "15,8",
  },
  {
    piping_flange_id: 32,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 318,
    dr_no: 12,
    dr_d: "22,2",
    dr_g: "269,9",
    k: "241,3",
    e: "7,92",
    c: "36,6",
    r: "215,9",
    r_mini: "",
    x: 206,
    y: 52,
    a: "",
    b: "",
    weight: 23,
  },
  {
    piping_flange_id: 33,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 381,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: "330,2",
    k: "301,8",
    e: "7,92",
    c: "41,1",
    r: "269,9",
    r_mini: "",
    x: 260,
    y: 62,
    a: "",
    b: "",
    weight: 37,
  },
  {
    piping_flange_id: 34,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 444,
    dr_no: 16,
    dr_d: "28,5",
    dr_g: "387,4",
    k: "355,6",
    e: "7,92",
    c: "47,8",
    r: "323,8",
    r_mini: "",
    x: 320,
    y: 66,
    a: "",
    b: "",
    weight: 58,
  },
  {
    piping_flange_id: 35,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 521,
    dr_no: 16,
    dr_d: "31,8",
    dr_g: "450,8",
    k: "412,8",
    e: "7,92",
    c: "50,8",
    r: 381,
    r_mini: "",
    x: 375,
    y: 73,
    a: "",
    b: "",
    weight: 83,
  },
  {
    piping_flange_id: 36,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 584,
    dr_no: 20,
    dr_d: "31,8",
    dr_g: "514,4",
    k: "457,2",
    e: "7,92",
    c: "53,8",
    r: "412,8",
    r_mini: "",
    x: 425,
    y: 76,
    a: "",
    b: "",
    weight: 107,
  },
  {
    piping_flange_id: 37,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 648,
    dr_no: 20,
    dr_d: 35,
    dr_g: "571,5",
    k: 508,
    e: "7,92",
    c: "57,2",
    r: "469,9",
    r_mini: "",
    x: 483,
    y: 83,
    a: "",
    b: "",
    weight: 139,
  },
  {
    piping_flange_id: 38,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 711,
    dr_no: 24,
    dr_d: 35,
    dr_g: "628,6",
    k: "574,5",
    e: "7,92",
    c: "60,5",
    r: "533,4",
    r_mini: "",
    x: 533,
    y: 89,
    a: "",
    b: "",
    weight: 177,
  },
  {
    piping_flange_id: 39,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 775,
    dr_no: 24,
    dr_d: 35,
    dr_g: "685,8",
    k: 635,
    e: "9,52",
    c: "63,5",
    r: "584,2",
    r_mini: "",
    x: 587,
    y: 95,
    a: "",
    b: "",
    weight: 223,
  },
  {
    piping_flange_id: 40,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 914,
    dr_no: 24,
    dr_d: "41,1",
    dr_g: "812,8",
    k: "749,3",
    e: "11,13",
    c: "69,9",
    r: "692,2",
    r_mini: "",
    x: 701,
    y: 106,
    a: "",
    b: "",
    weight: 342,
  },
  {
    piping_flange_id: 43,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    k: "69,9",
    e: "6,35",
    c: "17,5",
    r: "50,8",
    r_mini: "",
    x: 54,
    y: 27,
    a: "",
    b: "",
    weight: "2,1",
  },
  {
    piping_flange_id: 44,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 133,
    dr_no: 4,
    dr_d: 19,
    dr_g: "98,4",
    k: "79,2",
    e: "6,35",
    c: "20,6",
    r: "63,5",
    r_mini: "",
    x: 64,
    y: 29,
    a: "",
    b: "",
    weight: "2,6",
  },
  {
    piping_flange_id: 45,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    k: "90,4",
    e: "6,35",
    c: "22,4",
    r: 73,
    r_mini: "",
    x: 70,
    y: 32,
    a: "",
    b: "",
    weight: "3,3",
  },
  {
    piping_flange_id: 46,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    k: 108,
    e: "7,92",
    c: "25,4",
    r: "92,1",
    r_mini: "",
    x: 84,
    y: 37,
    a: "",
    b: "",
    weight: "4,4",
  },
  {
    piping_flange_id: 47,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    k: 127,
    e: "7,92",
    c: "28,4",
    r: "104,8",
    r_mini: "",
    x: 100,
    y: 41,
    a: "",
    b: "",
    weight: 6,
  },
  {
    piping_flange_id: 48,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    k: "146,1",
    e: "7,92",
    c: "31,8",
    r: 127,
    r_mini: "",
    x: 118,
    y: 46,
    a: "",
    b: "",
    weight: "7,4",
  },
  {
    piping_flange_id: 49,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 229,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "184,1",
    k: "158,8",
    e: "7,92",
    c: 35,
    r: "139,7",
    r_mini: "",
    x: 133,
    y: 49,
    a: "",
    b: "",
    weight: "9,5",
  },
  {
    piping_flange_id: 50,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 254,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "200,1",
    k: "174,8",
    e: "7,92",
    c: 35,
    r: "157,2",
    r_mini: "",
    x: 146,
    y: 51,
    a: "",
    b: "",
    weight: 15,
  },
  {
    piping_flange_id: 51,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 279,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: 235,
    k: "209,6",
    e: "7,92",
    c: "38,1",
    r: "185,7",
    r_mini: "",
    x: 178,
    y: 54,
    a: "",
    b: "",
    weight: 21,
  },
  {
    piping_flange_id: 52,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 318,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: "269,9",
    k: "241,3",
    e: "7,92",
    c: "41,1",
    r: "215,9",
    r_mini: "",
    x: 206,
    y: 57,
    a: "",
    b: "",
    weight: 28,
  },
  {
    piping_flange_id: 53,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 381,
    dr_no: 12,
    dr_d: "28,5",
    dr_g: "330,2",
    k: "301,8",
    e: "7,92",
    c: "47,8",
    r: "269,9",
    r_mini: "",
    x: 260,
    y: 68,
    a: "",
    b: "",
    weight: 43,
  },
  {
    piping_flange_id: 54,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 444,
    dr_no: 16,
    dr_d: "31,8",
    dr_g: "387,4",
    k: "355,6",
    e: "7,92",
    c: "53,8",
    r: "323,8",
    r_mini: "",
    x: 320,
    y: 73,
    a: "",
    b: "",
    weight: 65,
  },
  {
    piping_flange_id: 55,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 521,
    dr_no: 16,
    dr_d: 35,
    dr_g: "450,8",
    k: "412,8",
    e: "7,92",
    c: "57,2",
    r: 381,
    r_mini: "",
    x: 375,
    y: 79,
    a: "",
    b: "",
    weight: 95,
  },
  {
    piping_flange_id: 56,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 584,
    dr_no: 20,
    dr_d: 35,
    dr_g: "514,4",
    k: "457,2",
    e: "7,92",
    c: "60,5",
    r: "412,8",
    r_mini: "",
    x: 425,
    y: 84,
    a: "",
    b: "",
    weight: 126,
  },
  {
    piping_flange_id: 57,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 648,
    dr_no: 20,
    dr_d: "38,1",
    dr_g: "571,5",
    k: 508,
    e: "7,92",
    c: "63,5",
    r: "469,9",
    r_mini: "",
    x: 483,
    y: 94,
    a: "",
    b: "",
    weight: 163,
  },
  {
    piping_flange_id: 58,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 711,
    dr_no: 24,
    dr_d: "38,1",
    dr_g: "628,6",
    k: "574,5",
    e: "7,92",
    c: "66,5",
    r: "533,4",
    r_mini: "",
    x: 533,
    y: 98,
    a: "",
    b: "",
    weight: 206,
  },
  {
    piping_flange_id: 59,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 775,
    dr_no: 24,
    dr_d: "41,1",
    dr_g: "685,8",
    k: 635,
    e: "9,52",
    c: "69,9",
    r: "584,2",
    r_mini: "",
    x: 587,
    y: 102,
    a: "",
    b: "",
    weight: 256,
  },
  {
    piping_flange_id: 60,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 400,
    o: 914,
    dr_no: 24,
    dr_d: "47,8",
    dr_g: "812,8",
    k: "749,3",
    e: "11,13",
    c: "76,2",
    r: "692,2",
    r_mini: "",
    x: 701,
    y: 114,
    a: "",
    b: "",
    weight: 387,
  },
  {
    piping_flange_id: 63,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    k: "69,9",
    e: "6,35",
    c: "17,5",
    r: "50,8",
    r_mini: "",
    x: 54,
    y: 27,
    a: "",
    b: "",
    weight: "2,1",
  },
  {
    piping_flange_id: 64,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 133,
    dr_no: 4,
    dr_d: 19,
    dr_g: "98,4",
    k: "79,2",
    e: "6,35",
    c: "20,6",
    r: "63,5",
    r_mini: "",
    x: 64,
    y: 29,
    a: "",
    b: "",
    weight: "2,6",
  },
  {
    piping_flange_id: 65,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    k: "90,4",
    e: "6,35",
    c: "22,4",
    r: 73,
    r_mini: "",
    x: 70,
    y: 32,
    a: "",
    b: "",
    weight: "3,3",
  },
  {
    piping_flange_id: 66,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    k: 108,
    e: "7,92",
    c: "25,4",
    r: "92,1",
    r_mini: "",
    x: 84,
    y: 37,
    a: "",
    b: "",
    weight: "4,4",
  },
  {
    piping_flange_id: 67,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    k: 127,
    e: "7,92",
    c: "28,4",
    r: "104,8",
    r_mini: "",
    x: 100,
    y: 41,
    a: "",
    b: "",
    weight: 6,
  },
  {
    piping_flange_id: 68,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    k: "146,1",
    e: "7,92",
    c: "31,8",
    r: 127,
    r_mini: "",
    x: 118,
    y: 46,
    a: "",
    b: "",
    weight: "7,4",
  },
  {
    piping_flange_id: 69,
    dn: 0,
    nps: "3 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 229,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "184,1",
    k: "158,8",
    e: "7,92",
    c: 35,
    r: "139,7",
    r_mini: "",
    x: 133,
    y: 49,
    a: "",
    b: "",
    weight: "9,5",
  },
  {
    piping_flange_id: 70,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 273,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "215,9",
    k: "174,8",
    e: "7,92",
    c: "38,1",
    r: "157,2",
    r_mini: "",
    x: 152,
    y: 54,
    a: "",
    b: "",
    weight: 17,
  },
  {
    piping_flange_id: 71,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 330,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "266,7",
    k: "209,6",
    e: "7,92",
    c: "44,5",
    r: "185,7",
    r_mini: "",
    x: 189,
    y: 60,
    a: "",
    b: "",
    weight: 27,
  },
  {
    piping_flange_id: 72,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 356,
    dr_no: 12,
    dr_d: "28,5",
    dr_g: "292,1",
    k: "241,3",
    e: "7,92",
    c: "47,8",
    r: "215,9",
    r_mini: "",
    x: 222,
    y: 67,
    a: "",
    b: "",
    weight: 32,
  },
  {
    piping_flange_id: 73,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 419,
    dr_no: 12,
    dr_d: "31,8",
    dr_g: "349,2",
    k: "301,8",
    e: "7,92",
    c: "55,6",
    r: "269,9",
    r_mini: "",
    x: 273,
    y: 76,
    a: "",
    b: "",
    weight: 46,
  },
  {
    piping_flange_id: 74,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 508,
    dr_no: 16,
    dr_d: 35,
    dr_g: "431,8",
    k: "355,6",
    e: "7,92",
    c: "63,5",
    r: "323,8",
    r_mini: "",
    x: 343,
    y: 86,
    a: "",
    b: "",
    weight: 74,
  },
  {
    piping_flange_id: 75,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 559,
    dr_no: 20,
    dr_d: 35,
    dr_g: 489,
    k: "412,8",
    e: "7,92",
    c: "66,5",
    r: 381,
    r_mini: "",
    x: 400,
    y: 92,
    a: "",
    b: "",
    weight: 90,
  },
  {
    piping_flange_id: 76,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 603,
    dr_no: 20,
    dr_d: "38,1",
    dr_g: 527,
    k: "457,2",
    e: "7,92",
    c: "69,9",
    r: "412,8",
    r_mini: "",
    x: 432,
    y: 94,
    a: "",
    b: "",
    weight: 108,
  },
  {
    piping_flange_id: 77,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 686,
    dr_no: 20,
    dr_d: "41,1",
    dr_g: "603,2",
    k: 508,
    e: "7,92",
    c: "76,2",
    r: "469,9",
    r_mini: "",
    x: 495,
    y: 106,
    a: "",
    b: "",
    weight: 150,
  },
  {
    piping_flange_id: 78,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 743,
    dr_no: 20,
    dr_d: "44,5",
    dr_g: 654,
    k: "574,5",
    e: "7,92",
    c: "82,6",
    r: "533,4",
    r_mini: "",
    x: 546,
    y: 117,
    a: "",
    b: "",
    weight: 188,
  },
  {
    piping_flange_id: 79,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 813,
    dr_no: 24,
    dr_d: "44,5",
    dr_g: "723,9",
    k: 635,
    e: "9,52",
    c: "88,9",
    r: "584,2",
    r_mini: "",
    x: 610,
    y: 127,
    a: "",
    b: "",
    weight: 230,
  },
  {
    piping_flange_id: 80,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 600,
    o: 940,
    dr_no: 24,
    dr_d: "50,8",
    dr_g: "838,2",
    k: "749,3",
    e: "11,13",
    c: "101,6",
    r: "692,2",
    r_mini: "",
    x: 718,
    y: 140,
    a: "",
    b: "",
    weight: 325,
  },
  {
    piping_flange_id: 83,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 149,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: "101,6",
    k: "71,4",
    e: "6,35",
    c: "28,4",
    r: "50,8",
    r_mini: "",
    x: 52,
    y: 41,
    a: "",
    b: "",
    weight: 4,
  },
  {
    piping_flange_id: 84,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 159,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: "111,1",
    k: 81,
    e: "6,35",
    c: "28,4",
    r: "63,5",
    r_mini: "",
    x: 63,
    y: 41,
    a: "",
    b: "",
    weight: "5,8",
  },
  {
    piping_flange_id: 85,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 178,
    dr_no: 4,
    dr_d: "28,5",
    dr_g: "123,8",
    k: "91,9",
    e: "6,35",
    c: "31,8",
    r: 73,
    r_mini: "",
    x: 70,
    y: 44,
    a: "",
    b: "",
    weight: "6,5",
  },
  {
    piping_flange_id: 86,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 216,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "165,1",
    k: "123,9",
    e: "7,92",
    c: "38,1",
    r: "92,1",
    r_mini: "",
    x: 105,
    y: 57,
    a: "",
    b: "",
    weight: "11,5",
  },
  {
    piping_flange_id: 87,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 244,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "190,5",
    k: "136,7",
    e: "7,92",
    c: "41,1",
    r: "104,8",
    r_mini: "",
    x: 124,
    y: 63,
    a: "",
    b: "",
    weight: "15,5",
  },
  {
    piping_flange_id: 88,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 241,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "190,5",
    k: "155,4",
    e: "7,92",
    c: "38,1",
    r: 127,
    r_mini: "",
    x: 127,
    y: 54,
    a: "",
    b: "",
    weight: "14,5",
  },
  {
    piping_flange_id: 89,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 292,
    dr_no: 8,
    dr_d: "31,8",
    dr_g: 235,
    k: "180,8",
    e: "7,92",
    c: "44,5",
    r: "157,2",
    r_mini: "",
    x: 159,
    y: 70,
    a: "",
    b: "",
    weight: 24,
  },
  {
    piping_flange_id: 90,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 349,
    dr_no: 8,
    dr_d: 35,
    dr_g: "279,4",
    k: "215,9",
    e: "7,92",
    c: "50,8",
    r: "185,7",
    r_mini: "",
    x: 190,
    y: 79,
    a: "",
    b: "",
    weight: 39,
  },
  {
    piping_flange_id: 91,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 381,
    dr_no: 12,
    dr_d: "31,8",
    dr_g: "317,5",
    k: "241,3",
    e: "7,92",
    c: "55,6",
    r: "215,9",
    r_mini: "",
    x: 235,
    y: 86,
    a: "",
    b: "",
    weight: 51,
  },
  {
    piping_flange_id: 92,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 470,
    dr_no: 12,
    dr_d: "38,1",
    dr_g: "393,7",
    k: "307,8",
    e: "7,92",
    c: "63,5",
    r: "269,9",
    r_mini: "",
    x: 298,
    y: 102,
    a: "",
    b: "",
    weight: 89,
  },
  {
    piping_flange_id: 93,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 546,
    dr_no: 16,
    dr_d: "38,1",
    dr_g: "469,9",
    k: 362,
    e: "7,92",
    c: "69,9",
    r: "323,8",
    r_mini: "",
    x: 368,
    y: 108,
    a: "",
    b: "",
    weight: 130,
  },
  {
    piping_flange_id: 94,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 610,
    dr_no: 20,
    dr_d: "38,1",
    dr_g: "533,4",
    k: "419,1",
    e: "7,92",
    c: "79,2",
    r: 381,
    r_mini: "",
    x: 419,
    y: 117,
    a: "",
    b: "",
    weight: 175,
  },
  {
    piping_flange_id: 95,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 641,
    dr_no: 20,
    dr_d: "41,1",
    dr_g: "558,8",
    k: "466,9",
    e: "11,13",
    c: "85,9",
    r: "412,8",
    r_mini: "",
    x: 451,
    y: 130,
    a: "",
    b: "",
    weight: 206,
  },
  {
    piping_flange_id: 96,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 705,
    dr_no: 20,
    dr_d: "44,5",
    dr_g: 616,
    k: "523,7",
    e: "11,13",
    c: "88,9",
    r: "469,9",
    r_mini: "",
    x: 508,
    y: 133,
    a: "",
    b: "",
    weight: 259,
  },
  {
    piping_flange_id: 97,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 787,
    dr_no: 20,
    dr_d: "50,8",
    dr_g: "685,8",
    k: "593,9",
    e: "12,7",
    c: "101,6",
    r: "533,4",
    r_mini: "",
    x: 565,
    y: 152,
    a: "",
    b: "",
    weight: 367,
  },
  {
    piping_flange_id: 98,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 857,
    dr_no: 20,
    dr_d: "53,8",
    dr_g: "749,3",
    k: "647,7",
    e: "12,7",
    c: 108,
    r: "584,2",
    r_mini: "",
    x: 622,
    y: 159,
    a: "",
    b: "",
    weight: 463,
  },
  {
    piping_flange_id: 99,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 900,
    o: 1041,
    dr_no: 20,
    dr_d: "66,5",
    dr_g: "901,7",
    k: "771,7",
    e: "15,88",
    c: "139,7",
    r: "692,2",
    r_mini: "",
    x: 749,
    y: 203,
    a: "",
    b: "",
    weight: 875,
  },
  {
    piping_flange_id: 102,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 149,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: "101,6",
    k: "71,4",
    e: "6,35",
    c: "28,4",
    r: "50,8",
    r_mini: "",
    x: 52,
    y: 41,
    a: "",
    b: "",
    weight: 4,
  },
  {
    piping_flange_id: 103,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 159,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: "111,1",
    k: 81,
    e: "6,35",
    c: "28,4",
    r: "63,5",
    r_mini: "",
    x: 63,
    y: 41,
    a: "",
    b: "",
    weight: "5,8",
  },
  {
    piping_flange_id: 104,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 178,
    dr_no: 4,
    dr_d: "28,5",
    dr_g: "123,8",
    k: "91,9",
    e: "6,35",
    c: "31,8",
    r: 73,
    r_mini: "",
    x: 70,
    y: 44,
    a: "",
    b: "",
    weight: "6,5",
  },
  {
    piping_flange_id: 105,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 216,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "165,1",
    k: "123,9",
    e: "7,92",
    c: "38,1",
    r: "92,1",
    r_mini: "",
    x: 105,
    y: 57,
    a: "",
    b: "",
    weight: "11,5",
  },
  {
    piping_flange_id: 106,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 244,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "190,5",
    k: "136,7",
    e: "7,92",
    c: "41,1",
    r: "104,8",
    r_mini: "",
    x: 124,
    y: 63,
    a: "",
    b: "",
    weight: "15,5",
  },
  {
    piping_flange_id: 107,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 267,
    dr_no: 8,
    dr_d: "31,8",
    dr_g: "203,2",
    k: "168,1",
    e: "7,92",
    c: "47,8",
    r: 127,
    r_mini: "",
    x: 133,
    y: "",
    a: "",
    b: "",
    weight: 22,
  },
  {
    piping_flange_id: 108,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 311,
    dr_no: 8,
    dr_d: 35,
    dr_g: "241,3",
    k: "193,5",
    e: "7,92",
    c: "53,8",
    r: "157,2",
    r_mini: "",
    x: 162,
    y: "",
    a: "",
    b: "",
    weight: 33,
  },
  {
    piping_flange_id: 109,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 375,
    dr_no: 8,
    dr_d: "41,1",
    dr_g: "292,1",
    k: "228,6",
    e: "7,92",
    c: "73,2",
    r: "185,7",
    r_mini: "",
    x: 197,
    y: "",
    a: "",
    b: "",
    weight: 60,
  },
  {
    piping_flange_id: 110,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 394,
    dr_no: 12,
    dr_d: "38,1",
    dr_g: "317,5",
    k: 248,
    e: "9,52",
    c: "82,6",
    r: "215,9",
    r_mini: "",
    x: 229,
    y: "",
    a: "",
    b: "",
    weight: 72,
  },
  {
    piping_flange_id: 111,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 483,
    dr_no: 12,
    dr_d: "44,5",
    dr_g: "393,7",
    k: 318,
    e: "11,13",
    c: "91,9",
    r: "269,7",
    r_mini: "",
    x: 292,
    y: "",
    a: "",
    b: "",
    weight: 122,
  },
  {
    piping_flange_id: 112,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 584,
    dr_no: 12,
    dr_d: "50,8",
    dr_g: "482,6",
    k: 371,
    e: "11,13",
    c: 108,
    r: "323,8",
    r_mini: "",
    x: 368,
    y: "",
    a: "",
    b: "",
    weight: 210,
  },
  {
    piping_flange_id: 113,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 673,
    dr_no: 16,
    dr_d: "53,8",
    dr_g: "571,5",
    k: 438,
    e: "14,27",
    c: 124,
    r: 381,
    r_mini: "",
    x: 451,
    y: "",
    a: "",
    b: "",
    weight: 315,
  },
  {
    piping_flange_id: 114,
    dn: 350,
    nps: 14,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 749,
    dr_no: 16,
    dr_d: "60,5",
    dr_g: 635,
    k: 489,
    e: "15,88",
    c: "133,4",
    r: "412,8",
    r_mini: "",
    x: 495,
    y: "",
    a: "",
    b: "",
    weight: 460,
  },
  {
    piping_flange_id: 115,
    dn: 400,
    nps: 16,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 826,
    dr_no: 16,
    dr_d: "66,5",
    dr_g: "704,8",
    k: 546,
    e: "17,48",
    c: 146,
    r: "469,9",
    r_mini: "",
    x: 552,
    y: "",
    a: "",
    b: "",
    weight: 610,
  },
  {
    piping_flange_id: 116,
    dn: 450,
    nps: 18,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 914,
    dr_no: 16,
    dr_d: "73,2",
    dr_g: "774,7",
    k: 613,
    e: "17,48",
    c: 162,
    r: "533,4",
    r_mini: "",
    x: 597,
    y: "",
    a: "",
    b: "",
    weight: 835,
  },
  {
    piping_flange_id: 117,
    dn: 500,
    nps: 20,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 984,
    dr_no: 16,
    dr_d: "79,2",
    dr_g: "831,8",
    k: 673,
    e: "17,48",
    c: "177,8",
    r: "584,2",
    r_mini: "",
    x: 641,
    y: "",
    a: "",
    b: "",
    weight: 1062,
  },
  {
    piping_flange_id: 118,
    dn: 600,
    nps: 24,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 1500,
    o: 1168,
    dr_no: 16,
    dr_d: "91,9",
    dr_g: "990,6",
    k: 794,
    e: "20,62",
    c: "203,2",
    r: "692,2",
    r_mini: "",
    x: 762,
    y: "",
    a: "",
    b: "",
    weight: 1712,
  },
  {
    piping_flange_id: 121,
    dn: 25,
    nps: 1,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 2500,
    o: 159,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: 108,
    k: "82,6",
    e: "6,35",
    c: 35,
    r: "50,8",
    r_mini: "",
    x: 57,
    y: "",
    a: "",
    b: "",
    weight: 5,
  },
  {
    piping_flange_id: 122,
    dn: 32,
    nps: "1 1/4",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 2500,
    o: 184,
    dr_no: 4,
    dr_d: "28,5",
    dr_g: 130,
    k: "101,6",
    e: "7,92",
    c: "38,1",
    r: "63,5",
    r_mini: "",
    x: 73,
    y: "",
    a: "",
    b: "",
    weight: "8,1",
  },
  {
    piping_flange_id: 123,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 2500,
    o: 203,
    dr_no: 4,
    dr_d: "31,8",
    dr_g: 146,
    k: "114,3",
    e: "7,92",
    c: "44,5",
    r: 73,
    r_mini: "",
    x: 79,
    y: "",
    a: "",
    b: "",
    weight: "11,5",
  },
  {
    piping_flange_id: 124,
    dn: 50,
    nps: 2,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 2500,
    o: 235,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "171,5",
    k: "133,4",
    e: "7,92",
    c: "50,8",
    r: "92,1",
    r_mini: "",
    x: 95,
    y: "",
    a: "",
    b: "",
    weight: "17,6",
  },
  {
    piping_flange_id: 125,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 2500,
    o: 267,
    dr_no: 8,
    dr_d: "31,8",
    dr_g: "196,9",
    k: "149,4",
    e: "9,52",
    c: "57,2",
    r: "104,8",
    r_mini: "",
    x: 114,
    y: "",
    a: "",
    b: "",
    weight: 26,
  },
  {
    piping_flange_id: 126,
    dn: 80,
    nps: 3,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 2500,
    o: 305,
    dr_no: 8,
    dr_d: 35,
    dr_g: "228,6",
    k: "168,1",
    e: "9,52",
    c: "66,5",
    r: 127,
    r_mini: "",
    x: 133,
    y: "",
    a: "",
    b: "",
    weight: 39,
  },
  {
    piping_flange_id: 127,
    dn: 100,
    nps: 4,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 2500,
    o: 356,
    dr_no: 8,
    dr_d: "41,1",
    dr_g: 273,
    k: "203,2",
    e: "11,13",
    c: "76,2",
    r: "157,2",
    r_mini: "",
    x: 165,
    y: "",
    a: "",
    b: "",
    weight: 60,
  },
  {
    piping_flange_id: 128,
    dn: 125,
    nps: 5,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 2500,
    o: 419,
    dr_no: 8,
    dr_d: "47,8",
    dr_g: "323,9",
    k: "241,3",
    e: "12,7",
    c: "91,9",
    r: "185,7",
    r_mini: "",
    x: 203,
    y: "",
    a: "",
    b: "",
    weight: 100,
  },
  {
    piping_flange_id: 129,
    dn: 150,
    nps: 6,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 2500,
    o: 483,
    dr_no: 8,
    dr_d: "53,8",
    dr_g: "368,3",
    k: "279,4",
    e: "12,7",
    c: 108,
    r: "215,9",
    r_mini: "",
    x: 235,
    y: "",
    a: "",
    b: "",
    weight: 140,
  },
  {
    piping_flange_id: 130,
    dn: 200,
    nps: 8,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 2500,
    o: 552,
    dr_no: 12,
    dr_d: "53,8",
    dr_g: "438,2",
    k: "339,9",
    e: "14,27",
    c: 127,
    r: "269,9",
    r_mini: "",
    x: 305,
    y: "",
    a: "",
    b: "",
    weight: 236,
  },
  {
    piping_flange_id: 131,
    dn: 250,
    nps: 10,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 2500,
    o: 673,
    dr_no: 12,
    dr_d: "66,5",
    dr_g: "539,8",
    k: "425,5",
    e: "17,48",
    c: "165,1",
    r: "323,8",
    r_mini: "",
    x: 375,
    y: "",
    a: "",
    b: "",
    weight: 450,
  },
  {
    piping_flange_id: 132,
    dn: 300,
    nps: 12,
    material: "ASME B 16.5-1996",
    shape: "FLANGE - BLIND",
    class: 2500,
    o: 762,
    dr_no: 12,
    dr_d: "73,2",
    dr_g: "619,3",
    k: "495,3",
    e: "17,48",
    c: "184,2",
    r: 381,
    r_mini: "",
    x: 441,
    y: "",
    a: "",
    b: "",
    weight: 650,
  },
  {
    piping_flange_id: 133,
    dn: 650,
    nps: 26,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "869,9",
    dr_no: 24,
    dr_d: "34,9",
    dr_g: "806,4",
    k: "",
    e: "",
    c: "68,3",
    r: "749,3",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 323,
  },
  {
    piping_flange_id: 134,
    dn: 700,
    nps: 28,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "927,1",
    dr_no: 28,
    dr_d: "34,9",
    dr_g: "863,6",
    k: "",
    e: "",
    c: "71,4",
    r: "800,1",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 382,
  },
  {
    piping_flange_id: 135,
    dn: 750,
    nps: 30,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "984,2",
    dr_no: 28,
    dr_d: "34,9",
    dr_g: "914,4",
    k: "",
    e: "",
    c: "74,7",
    r: "857,2",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 450,
  },
  {
    piping_flange_id: 136,
    dn: 800,
    nps: 32,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1060,4",
    dr_no: 28,
    dr_d: "41,3",
    dr_g: "977,9",
    k: "",
    e: "",
    c: "80,8",
    r: "914,4",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 560,
  },
  {
    piping_flange_id: 137,
    dn: 850,
    nps: 34,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1111,2",
    dr_no: 32,
    dr_d: "41,3",
    dr_g: "1028,7",
    k: "",
    e: "",
    c: "82,6",
    r: "965,2",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 630,
  },
  {
    piping_flange_id: 138,
    dn: 900,
    nps: 36,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1168,4",
    dr_no: 32,
    dr_d: "41,3",
    dr_g: "1085,8",
    k: "",
    e: "",
    c: "90,4",
    r: "1022,3",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 765,
  },
  {
    piping_flange_id: 139,
    dn: 950,
    nps: 38,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1238,2",
    dr_no: 32,
    dr_d: "41,3",
    dr_g: "1149,3",
    k: "",
    e: "",
    c: "87,4",
    r: "1073,1",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 850,
  },
  {
    piping_flange_id: 140,
    dn: 1000,
    nps: 40,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 1289,
    dr_no: 36,
    dr_d: "41,3",
    dr_g: "1200,1",
    k: "",
    e: "",
    c: "90,4",
    r: "1123,9",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 955,
  },
  {
    piping_flange_id: 141,
    dn: 1050,
    nps: 42,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1346,2",
    dr_no: 36,
    dr_d: "41,3",
    dr_g: "1257,3",
    k: "",
    e: "",
    c: "96,8",
    r: "1193,8",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1075,
  },
  {
    piping_flange_id: 142,
    dn: 1100,
    nps: 44,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1403,3",
    dr_no: 40,
    dr_d: "41,3",
    dr_g: "1314,4",
    k: "",
    e: "",
    c: "101,6",
    r: "1244,6",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1200,
  },
  {
    piping_flange_id: 143,
    dn: 1150,
    nps: 46,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1454,1",
    dr_no: 40,
    dr_d: "41,3",
    dr_g: "1365,2",
    k: "",
    e: "",
    c: "103,1",
    r: "1295,4",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1380,
  },
  {
    piping_flange_id: 144,
    dn: 1200,
    nps: 48,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1511,3",
    dr_no: 44,
    dr_d: "41,3",
    dr_g: "1422,4",
    k: "",
    e: "",
    c: 108,
    r: "1358,9",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1590,
  },
  {
    piping_flange_id: 145,
    dn: 1250,
    nps: 50,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1568,5",
    dr_no: 44,
    dr_d: "47,7",
    dr_g: "1479,5",
    k: "",
    e: "",
    c: "111,3",
    r: "1409,7",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: "",
  },
  {
    piping_flange_id: 146,
    dn: 1300,
    nps: 52,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1625,6",
    dr_no: 44,
    dr_d: "47,7",
    dr_g: "1536,7",
    k: "",
    e: "",
    c: "115,8",
    r: "1460,5",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: "",
  },
  {
    piping_flange_id: 147,
    dn: 1350,
    nps: 54,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1682,8",
    dr_no: 44,
    dr_d: "47,7",
    dr_g: "1593,8",
    k: "",
    e: "",
    c: "120,7",
    r: "1511,3",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: "",
  },
  {
    piping_flange_id: 148,
    dn: 1400,
    nps: 56,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1746,3",
    dr_no: 48,
    dr_d: "47,7",
    dr_g: 1651,
    k: "",
    e: "",
    c: 124,
    r: "1574,8",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: "",
  },
  {
    piping_flange_id: 149,
    dn: 1450,
    nps: 58,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1803,4",
    dr_no: 48,
    dr_d: "47,7",
    dr_g: "1708,1",
    k: "",
    e: "",
    c: "128,5",
    r: "1625,6",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: "",
  },
  {
    piping_flange_id: 150,
    dn: 1500,
    nps: 60,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1854,2",
    dr_no: 52,
    dr_d: "47,7",
    dr_g: "1758,9",
    k: "",
    e: "",
    c: "131,8",
    r: "1676,4",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: "",
  },
  {
    piping_flange_id: 151,
    dn: 650,
    nps: 26,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "869,9",
    dr_no: 24,
    dr_d: "34,9",
    dr_g: "806,4",
    k: "",
    e: "",
    c: "68,3",
    r: "749,3",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 323,
  },
  {
    piping_flange_id: 152,
    dn: 700,
    nps: 28,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "927,1",
    dr_no: 28,
    dr_d: "34,9",
    dr_g: "863,6",
    k: "",
    e: "",
    c: "71,4",
    r: "800,1",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 382,
  },
  {
    piping_flange_id: 153,
    dn: 750,
    nps: 30,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "984,2",
    dr_no: 28,
    dr_d: "34,9",
    dr_g: "914,4",
    k: "",
    e: "",
    c: "74,7",
    r: "857,2",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 450,
  },
  {
    piping_flange_id: 154,
    dn: 800,
    nps: 32,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1060,4",
    dr_no: 28,
    dr_d: "41,3",
    dr_g: "977,9",
    k: "",
    e: "",
    c: "80,8",
    r: "914,4",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 560,
  },
  {
    piping_flange_id: 155,
    dn: 850,
    nps: 34,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1111,2",
    dr_no: 32,
    dr_d: "41,3",
    dr_g: "1028,7",
    k: "",
    e: "",
    c: "82,6",
    r: "965,2",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 630,
  },
  {
    piping_flange_id: 156,
    dn: 900,
    nps: 36,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1168,4",
    dr_no: 32,
    dr_d: "41,3",
    dr_g: "1085,8",
    k: "",
    e: "",
    c: "90,4",
    r: "1022,3",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 765,
  },
  {
    piping_flange_id: 157,
    dn: 950,
    nps: 38,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1238,2",
    dr_no: 32,
    dr_d: "41,3",
    dr_g: "1149,3",
    k: "",
    e: "",
    c: "87,4",
    r: "1073,1",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 850,
  },
  {
    piping_flange_id: 158,
    dn: 1000,
    nps: 40,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: 1289,
    dr_no: 36,
    dr_d: "41,3",
    dr_g: "1200,1",
    k: "",
    e: "",
    c: "90,4",
    r: "1123,9",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 955,
  },
  {
    piping_flange_id: 159,
    dn: 1050,
    nps: 42,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1346,2",
    dr_no: 36,
    dr_d: "41,3",
    dr_g: "1257,3",
    k: "",
    e: "",
    c: "96,8",
    r: "1193,8",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1075,
  },
  {
    piping_flange_id: 160,
    dn: 1100,
    nps: 44,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1403,3",
    dr_no: 40,
    dr_d: "41,3",
    dr_g: "1314,4",
    k: "",
    e: "",
    c: "101,6",
    r: "1244,6",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1200,
  },
  {
    piping_flange_id: 161,
    dn: 1150,
    nps: 46,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1454,1",
    dr_no: 40,
    dr_d: "41,3",
    dr_g: "1365,2",
    k: "",
    e: "",
    c: "103,1",
    r: "1295,4",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1380,
  },
  {
    piping_flange_id: 162,
    dn: 1200,
    nps: 48,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1511,3",
    dr_no: 44,
    dr_d: "41,3",
    dr_g: "1422,4",
    k: "",
    e: "",
    c: 108,
    r: "1358,9",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1590,
  },
  {
    piping_flange_id: 163,
    dn: 1250,
    nps: 50,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1568,5",
    dr_no: 44,
    dr_d: "47,7",
    dr_g: "1479,5",
    k: "",
    e: "",
    c: "111,3",
    r: "1409,7",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: "",
  },
  {
    piping_flange_id: 164,
    dn: 1300,
    nps: 52,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1625,6",
    dr_no: 44,
    dr_d: "47,7",
    dr_g: "1536,7",
    k: "",
    e: "",
    c: "115,8",
    r: "1460,5",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: "",
  },
  {
    piping_flange_id: 165,
    dn: 1350,
    nps: 54,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1682,8",
    dr_no: 44,
    dr_d: "47,7",
    dr_g: "1593,8",
    k: "",
    e: "",
    c: "120,7",
    r: "1511,3",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: "",
  },
  {
    piping_flange_id: 166,
    dn: 1400,
    nps: 56,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1746,3",
    dr_no: 48,
    dr_d: "47,7",
    dr_g: 1651,
    k: "",
    e: "",
    c: 124,
    r: "1574,8",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: "",
  },
  {
    piping_flange_id: 167,
    dn: 1450,
    nps: 58,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1803,4",
    dr_no: 48,
    dr_d: "47,7",
    dr_g: "1708,1",
    k: "",
    e: "",
    c: "128,5",
    r: "1625,6",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: "",
  },
  {
    piping_flange_id: 168,
    dn: 1500,
    nps: 60,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 150,
    o: "1854,2",
    dr_no: 52,
    dr_d: "47,7",
    dr_g: "1758,9",
    k: "",
    e: "",
    c: "131,8",
    r: "1676,4",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: "",
  },
  {
    piping_flange_id: 169,
    dn: 650,
    nps: 26,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "971,5",
    dr_no: 28,
    dr_d: "44,4",
    dr_g: "876,3",
    k: "",
    e: "",
    c: "84,1",
    r: "749,3",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 505,
  },
  {
    piping_flange_id: 170,
    dn: 700,
    nps: 28,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 1035,
    dr_no: 28,
    dr_d: "44,4",
    dr_g: "939,8",
    k: "",
    e: "",
    c: "90,4",
    r: "800,1",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 615,
  },
  {
    piping_flange_id: 171,
    dn: 750,
    nps: 30,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1092,2",
    dr_no: 28,
    dr_d: "47,8",
    dr_g: "996,9",
    k: "",
    e: "",
    c: "95,2",
    r: "857,2",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 705,
  },
  {
    piping_flange_id: 172,
    dn: 800,
    nps: 32,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1149,3",
    dr_no: 28,
    dr_d: "50,8",
    dr_g: "1054,1",
    k: "",
    e: "",
    c: "100,1",
    r: "914,4",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 820,
  },
  {
    piping_flange_id: 173,
    dn: 850,
    nps: 34,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1206,5",
    dr_no: 28,
    dr_d: "50,8",
    dr_g: "1104,9",
    k: "",
    e: "",
    c: "104,6",
    r: "965,2",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 950,
  },
  {
    piping_flange_id: 174,
    dn: 900,
    nps: 36,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 1270,
    dr_no: 32,
    dr_d: "53,8",
    dr_g: "1168,4",
    k: "",
    e: "",
    c: "111,3",
    r: "1022,3",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1085,
  },
  {
    piping_flange_id: 175,
    dn: 950,
    nps: 38,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1168,4",
    dr_no: 32,
    dr_d: "41,1",
    dr_g: "1092,2",
    k: "",
    e: "",
    c: "107,9",
    r: "1028,7",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 908,
  },
  {
    piping_flange_id: 176,
    dn: 1000,
    nps: 40,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1238,2",
    dr_no: 32,
    dr_d: "44,4",
    dr_g: "1155,7",
    k: "",
    e: "",
    c: "114,3",
    r: "1085,8",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1077,
  },
  {
    piping_flange_id: 177,
    dn: 1050,
    nps: 42,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 1289,
    dr_no: 32,
    dr_d: "44,4",
    dr_g: "1206,5",
    k: "",
    e: "",
    c: "119,1",
    r: "1136,6",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1219,
  },
  {
    piping_flange_id: 178,
    dn: 1100,
    nps: 44,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1352,5",
    dr_no: 32,
    dr_d: "47,8",
    dr_g: "1263,6",
    k: "",
    e: "",
    c: 124,
    r: "1193,8",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1396,
  },
  {
    piping_flange_id: 179,
    dn: 1150,
    nps: 46,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 1416,
    dr_no: 28,
    dr_d: "50,8",
    dr_g: "1320,8",
    k: "",
    e: "",
    c: "128,5",
    r: "1244,6",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1581,
  },
  {
    piping_flange_id: 180,
    dn: 1200,
    nps: 48,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1466,8",
    dr_no: 32,
    dr_d: "50,8",
    dr_g: "1371,6",
    k: "",
    e: "",
    c: "133,3",
    r: "1301,7",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1760,
  },
  {
    piping_flange_id: 181,
    dn: 1250,
    nps: 50,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1530,3",
    dr_no: 32,
    dr_d: "53,8",
    dr_g: "1428,8",
    k: "",
    e: "",
    c: "139,7",
    r: "1358,9",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 2005,
  },
  {
    piping_flange_id: 182,
    dn: 1300,
    nps: 52,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1581,1",
    dr_no: 32,
    dr_d: "53,8",
    dr_g: "1479,6",
    k: "",
    e: "",
    c: "144,5",
    r: "1409,7",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 2215,
  },
  {
    piping_flange_id: 183,
    dn: 1350,
    nps: 54,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1657,3",
    dr_no: 28,
    dr_d: "60,4",
    dr_g: "1549,4",
    k: "",
    e: "",
    c: "152,4",
    r: "1466,8",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 2568,
  },
  {
    piping_flange_id: 184,
    dn: 1400,
    nps: 56,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1708,1",
    dr_no: 28,
    dr_d: "60,4",
    dr_g: "1600,2",
    k: "",
    e: "",
    c: "153,9",
    r: "1517,6",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 2932,
  },
  {
    piping_flange_id: 185,
    dn: 1450,
    nps: 58,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1758,9",
    dr_no: 32,
    dr_d: "60,4",
    dr_g: 1651,
    k: "",
    e: "",
    c: "158,8",
    r: "1574,8",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 3009,
  },
  {
    piping_flange_id: 186,
    dn: 1500,
    nps: 60,
    material: "MSS SP 44 - 1996",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1809,7",
    dr_no: 32,
    dr_d: "60,4",
    dr_g: "1701,8",
    k: "",
    e: "",
    c: "163,6",
    r: "1625,6",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 3283,
  },
  {
    piping_flange_id: 187,
    dn: 650,
    nps: 26,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "971,5",
    dr_no: 28,
    dr_d: "44,4",
    dr_g: "876,3",
    k: "",
    e: "",
    c: "84,1",
    r: "749,3",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 505,
  },
  {
    piping_flange_id: 188,
    dn: 700,
    nps: 28,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 1035,
    dr_no: 28,
    dr_d: "44,4",
    dr_g: "939,8",
    k: "",
    e: "",
    c: "90,4",
    r: "800,1",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 615,
  },
  {
    piping_flange_id: 189,
    dn: 750,
    nps: 30,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1092,2",
    dr_no: 28,
    dr_d: "47,8",
    dr_g: "996,9",
    k: "",
    e: "",
    c: "95,2",
    r: "857,2",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 705,
  },
  {
    piping_flange_id: 190,
    dn: 800,
    nps: 32,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1149,3",
    dr_no: 28,
    dr_d: "50,8",
    dr_g: "1054,1",
    k: "",
    e: "",
    c: "100,1",
    r: "914,4",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 820,
  },
  {
    piping_flange_id: 191,
    dn: 850,
    nps: 34,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1206,5",
    dr_no: 28,
    dr_d: "50,8",
    dr_g: "1104,9",
    k: "",
    e: "",
    c: "104,6",
    r: "965,2",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 950,
  },
  {
    piping_flange_id: 192,
    dn: 900,
    nps: 36,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 1270,
    dr_no: 32,
    dr_d: "53,8",
    dr_g: "1168,4",
    k: "",
    e: "",
    c: "111,3",
    r: "1022,3",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1085,
  },
  {
    piping_flange_id: 193,
    dn: 950,
    nps: 38,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1168,4",
    dr_no: 32,
    dr_d: "41,1",
    dr_g: "1092,2",
    k: "",
    e: "",
    c: "107,9",
    r: "1028,7",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 908,
  },
  {
    piping_flange_id: 194,
    dn: 1000,
    nps: 40,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1238,2",
    dr_no: 32,
    dr_d: "44,4",
    dr_g: "1155,7",
    k: "",
    e: "",
    c: "114,3",
    r: "1085,8",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1077,
  },
  {
    piping_flange_id: 195,
    dn: 1050,
    nps: 42,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 1289,
    dr_no: 32,
    dr_d: "44,4",
    dr_g: "1206,5",
    k: "",
    e: "",
    c: "119,1",
    r: "1136,6",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1219,
  },
  {
    piping_flange_id: 196,
    dn: 1100,
    nps: 44,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1352,5",
    dr_no: 32,
    dr_d: "47,8",
    dr_g: "1263,6",
    k: "",
    e: "",
    c: 124,
    r: "1193,8",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1396,
  },
  {
    piping_flange_id: 197,
    dn: 1150,
    nps: 46,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: 1416,
    dr_no: 28,
    dr_d: "50,8",
    dr_g: "1320,8",
    k: "",
    e: "",
    c: "128,5",
    r: "1244,6",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1581,
  },
  {
    piping_flange_id: 198,
    dn: 1200,
    nps: 48,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1466,8",
    dr_no: 32,
    dr_d: "50,8",
    dr_g: "1371,6",
    k: "",
    e: "",
    c: "133,3",
    r: "1301,7",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 1760,
  },
  {
    piping_flange_id: 199,
    dn: 1250,
    nps: 50,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1530,3",
    dr_no: 32,
    dr_d: "53,8",
    dr_g: "1428,8",
    k: "",
    e: "",
    c: "139,7",
    r: "1358,9",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 2005,
  },
  {
    piping_flange_id: 200,
    dn: 1300,
    nps: 52,
    material: "ASME B 16.47 -1996 series A",
    shape: "FLANGE - BLIND",
    class: 300,
    o: "1581,1",
    dr_no: 32,
    dr_d: "53,8",
    dr_g: "1479,6",
    k: "",
    e: "",
    c: "144,5",
    r: "1409,7",
    r_mini: "",
    x: "",
    y: "",
    a: "",
    b: "",
    weight: 2215,
  },
].map((item) => ({
  ...item,
  nps: item.nps + "",
  o: fixValueToNumber(item.o, "float"),
  dr_d: fixValueToNumber(item.dr_d, "float"),
  dr_g: fixValueToNumber(item.dr_g, "float"),
  k: fixValueToNumber(item.k, "float"),
  e: fixValueToNumber(item.e, "float"),
  c: fixValueToNumber(item.c, "float"),
  r: fixValueToNumber(item.r, "float"),
  r_mini: fixValueToNumber(item.r_mini, "float"),
  x: fixValueToNumber(item.x, "float"),
  y: fixValueToNumber(item.y, "float"),
  a: fixValueToNumber(item.a, "float"),
  b: fixValueToNumber(item.b, "float"),
  weight: fixValueToNumber(item.weight, "float"),
}));
