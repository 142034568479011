import { SET_USER_ROLE } from '../../../../../store/main/actions';
import { UserRoleState } from '../../../../../store';

const initialState: UserRoleState = {
    userRole: "",
};

export const userRoleReducer = (state = initialState, action): UserRoleState => {
    switch (action.type) {
        case SET_USER_ROLE:
            console.log("is action payload updating",action.payload);
            return {
                ...state,
                userRole: action.payload,
            };
        default:
            return state;
    }
};
