import React, { useEffect, useState } from "react";
import { MenuItem, Navbar, Popover, Position, Menu } from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { API_ROOT, secondServerAPI, secondServerAPILearning } from "../../utils/agent";
import { ApplicationState } from "../../../store";
import { logOutAction } from "../../../store/auth/actions";
import Logo from "../../../assets/A-Logo-White-Transparent.png";
import "./navbar.css";
import { setUserRole } from "../../../store/main/actions";
import { useHistory } from "react-router-dom";

interface NavbarProps {
    onProfileClick: () => void;
}

export default function NavbarPrimary({ onProfileClick }: NavbarProps) {
    const [userName, setUserName] = useState<string>("");
    const [profilePhoto, setProfilePhoto] = useState<string>("https://www.w3schools.com/howto/img_avatar.png");
    const auth = useSelector((state: ApplicationState) => state.auth);
    const dispatch = useDispatch();
    const history = useHistory();
    const [showHelpDropdown, setShowHelpDropdown] = useState<boolean>(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const photoResponse = await Axios.get(`${secondServerAPI}rest/api/v1/dashboard/get/profile_photo`, {
                    headers: { 
                        "user-id": auth.User_id
                        // "user-id": 1
                    },
                    responseType: 'blob',
                });

                if (photoResponse.status === 200) {
                    const url = URL.createObjectURL(photoResponse.data);
                    setProfilePhoto(url);
                }

                const userResponse = await Axios.get(`${secondServerAPILearning}/api/v1/ufc/userInformation`, {
                    headers: { 
                        "user-id": auth.User_id
                        // "user-id" : 1,
                    }
                });

                if (userResponse.status === 200) {
                    setUserName(userResponse.data.userName);
                    const userId = userResponse.data.userId;

                    const rolesResponse = await Axios.get(`${API_ROOT}/rest/api/v1/dashboard/get/userroles`, {
                        headers: {
                            "user-id": auth.User_id
                            // "user-id": 1,
                        }
                    });

                    if (rolesResponse.status === 200) {
                        const users = rolesResponse.data;
                        const loggedInUser = users.find((user: any) => user.userId === userId);
                        dispatch(setUserRole(loggedInUser.role));
                    }
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };
        fetchUserData();
    }, []);

    function handleLogOut() {
        window.localStorage.setItem("jwt", "")
        dispatch(logOutAction())
        dispatch(push("/"))
        Axios.get(`${API_ROOT}/rest/api/v1/deleteUser`, {
            headers: { 'user-id': auth.User_id }
        })
            .then(response => {
                console.log("Updated");
            })
    }

    function invokeReportIssue() {
        window.open("https://asets-tech-support.atlassian.net/servicedesk/customer/portal/1", "_blank");
    }

    function handleImageClick() {
        history.push('/modes');
    }

    return (
        <Navbar className="primary-newmodeswitcher-navbar">
            <div className="secondary-newmodeswitcher-navbar">
                <img src={Logo} alt="Logo" className="navbar-logo" onClick={handleImageClick} />
                <h1 className="navbar-title">ASETS</h1>
                <div className="tertiary-newmodeswitcher-navbar">
                    <span className="welcome-text">Welcome, <span style={{ color: "#F1C40F", fontWeight: "bold" }}>{userName}</span></span>
                    <Popover
                        content={
                            <Menu className="file-menu">
                                <MenuItem className="file-menu-item" icon="person" text="profile" onClick={onProfileClick} />
                                <MenuItem
                                    className="file-menu-item"
                                    icon="help"
                                    text="Help"
                                    onMouseEnter={() => setShowHelpDropdown(true)}
                                    onMouseLeave={() => setShowHelpDropdown(false)}
                                >
                                    {showHelpDropdown && (
                                        <Menu>
                                            <MenuItem
                                                className="file-menu-item"
                                                text="Report an Issue"
                                                onClick={invokeReportIssue}
                                            />
                                        </Menu>
                                    )}
                                </MenuItem>
                                <MenuItem className="file-menu-item" icon="log-out" text="Logout" onClick={handleLogOut} />
                            </Menu>
                        }
                        position={Position.BOTTOM}
                        className="avatar-container"
                    >
                        <div className="avatar-and-icon">
                            <img src={profilePhoto} alt="Avatar" className="nav-avatar" />
                            <FontAwesomeIcon icon={faAngleDoubleDown} className="down-arrow-icon" style={{ color: "#1F8EB7", fontSize: "1.5rem" }} />
                        </div>
                    </Popover>
                </div>
            </div>
        </Navbar>
    );
}
