import React from 'react';
import Axios from 'axios';
import { secondServerAPI } from '../../utils/agent';

export function handleRegisterUser(
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  userCompany: string,
  callback: (success: boolean, message: string) => void
) {
  Axios.post(`${secondServerAPI}/rest/api/v1/ufc/createUserAccount`, {
    'firstName': firstName,
    'lastName': lastName,
    'email': email,
    'password': password,
    'companyName': userCompany,
  })
  .then(result => {
    console.log("User registered successfully");
    callback(true, "User registered successfully");
  })
  .catch(error => {
    console.log("This is the error", error);
    callback(false, "Registration failed. Please try again.");
  });
  
}
