import { fixValueToNumber } from "../../components/3d-models/utils";

export const pipingWeldingNeckFlanges = [
  {
    piping_flange_id: 1,
    dn: 25,
    nps: 1,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    a: "33,5",
    tt: "6,4",
    c_mini: "38,1",
    y: "82,6",
    x: 54,
    r: "50,8",
    weight: "4,5",
  },
  {
    piping_flange_id: 2,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    a: "48,3",
    tt: "6,4",
    c_mini: "38,1",
    y: "85,9",
    x: 70,
    r: 73,
    weight: "6,5",
  },
  {
    piping_flange_id: 3,
    dn: 50,
    nps: 2,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    a: "60,3",
    tt: "6,4",
    c_mini: "38,1",
    y: "85,9",
    x: 84,
    r: "92,1",
    weight: 7,
  },
  {
    piping_flange_id: 4,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    a: 73,
    tt: "6,4",
    c_mini: "38,1",
    y: "88,9",
    x: 100,
    r: "104,8",
    weight: "7,5",
  },
  {
    piping_flange_id: 5,
    dn: 80,
    nps: 3,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    a: "88,9",
    tt: "9,5",
    c_mini: "38,1",
    y: "88,9",
    x: 117,
    r: 127,
    weight: "9,4",
  },
  {
    piping_flange_id: 6,
    dn: 100,
    nps: 4,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 254,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: 200,
    a: "114,3",
    tt: "12,7",
    c_mini: "38,1",
    y: "91,9",
    x: 146,
    r: "157,2",
    weight: "13,2",
  },
  {
    piping_flange_id: 7,
    dn: 150,
    nps: 6,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 318,
    dr_no: 12,
    dr_d: "22,2",
    dr_g: "269,9",
    a: "168,3",
    tt: "12,7",
    c_mini: "38,1",
    y: "100,1",
    x: 206,
    r: "215,9",
    weight: "14,6",
  },
  {
    piping_flange_id: 8,
    dn: 200,
    nps: 8,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 381,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: "330,2",
    a: "219,1",
    tt: "12,7",
    c_mini: "41,1",
    y: "111,3",
    x: 260,
    r: "269,9",
    weight: 30,
  },
  {
    piping_flange_id: 9,
    dn: 250,
    nps: 10,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 444,
    dr_no: 16,
    dr_d: "28,5",
    dr_g: "387,4",
    a: 273,
    tt: "12,7",
    c_mini: "47,8",
    y: "117,3",
    x: 320,
    r: "323,8",
    weight: 41,
  },
  {
    piping_flange_id: 10,
    dn: 300,
    nps: 12,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 521,
    dr_no: 16,
    dr_d: "31,8",
    dr_g: "450,8",
    a: "323,9",
    tt: "12,7",
    c_mini: "50,8",
    y: 130,
    x: 375,
    r: 381,
    weight: 62,
  },
  {
    piping_flange_id: 11,
    dn: 350,
    nps: 14,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 584,
    dr_no: 20,
    dr_d: "31,8",
    dr_g: "514,4",
    a: "355,6",
    tt: "12,7",
    c_mini: "53,8",
    y: "142,7",
    x: 425,
    r: "412,8",
    weight: 84,
  },
  {
    piping_flange_id: 12,
    dn: 400,
    nps: 16,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 648,
    dr_no: 20,
    dr_d: 35,
    dr_g: "571,5",
    a: "406,4",
    tt: "12,7",
    c_mini: "57,2",
    y: 146,
    x: 483,
    r: "469,9",
    weight: 111,
  },
  {
    piping_flange_id: 13,
    dn: 450,
    nps: 18,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 711,
    dr_no: 24,
    dr_d: 35,
    dr_g: "628,6",
    a: 457,
    tt: "12,7",
    c_mini: "60,5",
    y: "158,8",
    x: 533,
    r: "533,4",
    weight: 138,
  },
  {
    piping_flange_id: 14,
    dn: 500,
    nps: 20,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 775,
    dr_no: 24,
    dr_d: 35,
    dr_g: "685,8",
    a: 508,
    tt: "12,7",
    c_mini: "63,5",
    y: "162,1",
    x: 587,
    r: "584,2",
    weight: 171,
  },
  {
    piping_flange_id: 15,
    dn: 600,
    nps: 24,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 300,
    o: 914,
    dr_no: 24,
    dr_d: "41,1",
    dr_g: "812,8",
    a: 610,
    tt: "12,7",
    c_mini: "69,9",
    y: "168,1",
    x: 701,
    r: "692,2",
    weight: 247,
  },
  {
    piping_flange_id: 16,
    dn: 25,
    nps: 1,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    a: "33,4",
    tt: "6,4",
    c_mini: "36,6",
    y: 81,
    x: 54,
    r: "50,8",
    weight: "5,5",
  },
  {
    piping_flange_id: 17,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    a: "48,3",
    tt: "6,4",
    c_mini: "36,6",
    y: "84,3",
    x: 70,
    r: 73,
    weight: "7,8",
  },
  {
    piping_flange_id: 18,
    dn: 50,
    nps: 2,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    a: "60,3",
    tt: "6,4",
    c_mini: "36,6",
    y: "84,3",
    x: 84,
    r: "92,1",
    weight: "8,3",
  },
  {
    piping_flange_id: 19,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    a: 73,
    tt: "6,4",
    c_mini: "36,6",
    y: "87,3",
    x: 100,
    r: "104,8",
    weight: "10,8",
  },
  {
    piping_flange_id: 20,
    dn: 80,
    nps: 3,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    a: "88,9",
    tt: "9,5",
    c_mini: "36,6",
    y: "87,3",
    x: 118,
    r: 127,
    weight: "12,6",
  },
  {
    piping_flange_id: 21,
    dn: 100,
    nps: 4,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 254,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: 200,
    a: "114,3",
    tt: "12,7",
    c_mini: "38,1",
    y: "88,9",
    x: 146,
    r: "157,2",
    weight: 16,
  },
  {
    piping_flange_id: 22,
    dn: 150,
    nps: 6,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 318,
    dr_no: 12,
    dr_d: "25,4",
    dr_g: "269,9",
    a: "168,3",
    tt: "12,7",
    c_mini: "41,1",
    y: "103,1",
    x: 206,
    r: "215,9",
    weight: 26,
  },
  {
    piping_flange_id: 23,
    dn: 200,
    nps: 8,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 381,
    dr_no: 12,
    dr_d: "28,5",
    dr_g: "330,2",
    a: "219,1",
    tt: "12,7",
    c_mini: "47,8",
    y: "117,3",
    x: 260,
    r: "269,9",
    weight: 40,
  },
  {
    piping_flange_id: 24,
    dn: 250,
    nps: 10,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 444,
    dr_no: 16,
    dr_d: "31,8",
    dr_g: "387,4",
    a: 273,
    tt: "12,7",
    c_mini: "53,8",
    y: "123,9",
    x: 320,
    r: "323,8",
    weight: 57,
  },
  {
    piping_flange_id: 25,
    dn: 300,
    nps: 12,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 521,
    dr_no: 16,
    dr_d: 35,
    dr_g: "450,8",
    a: "323,9",
    tt: "12,7",
    c_mini: "57,2",
    y: "136,7",
    x: 375,
    r: 381,
    weight: 80,
  },
  {
    piping_flange_id: 26,
    dn: 350,
    nps: 14,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 584,
    dr_no: 20,
    dr_d: 35,
    dr_g: "514,4",
    a: "355,6",
    tt: "12,7",
    c_mini: "60,7",
    y: "149,4",
    x: 425,
    r: "412,8",
    weight: 105,
  },
  {
    piping_flange_id: 27,
    dn: 400,
    nps: 16,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 648,
    dr_no: 20,
    dr_d: "38,1",
    dr_g: "571,5",
    a: "406,4",
    tt: "12,7",
    c_mini: "63,5",
    y: "152,4",
    x: 483,
    r: "469,9",
    weight: 131,
  },
  {
    piping_flange_id: 28,
    dn: 450,
    nps: 18,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 711,
    dr_no: 24,
    dr_d: "38,1",
    dr_g: "628,6",
    a: "457,2",
    tt: "12,7",
    c_mini: "66,5",
    y: "165,1",
    x: 533,
    r: "533,4",
    weight: 159,
  },
  {
    piping_flange_id: 29,
    dn: 500,
    nps: 20,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 775,
    dr_no: 24,
    dr_d: "41,1",
    dr_g: "685,8",
    a: 508,
    tt: "12,7",
    c_mini: "69,9",
    y: "168,1",
    x: 587,
    r: "584,2",
    weight: 190,
  },
  {
    piping_flange_id: 30,
    dn: 600,
    nps: 24,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 400,
    o: 914,
    dr_no: 24,
    dr_d: "47,8",
    dr_g: "812,8",
    a: 610,
    tt: "12,7",
    c_mini: "76,2",
    y: "174,8",
    x: 701,
    r: "692,2",
    weight: 275,
  },
  {
    piping_flange_id: 31,
    dn: 25,
    nps: 1,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 124,
    dr_no: 4,
    dr_d: 19,
    dr_g: "88,9",
    a: "33,4",
    tt: "6,4",
    c_mini: "36,6",
    y: 81,
    x: 54,
    r: "50,8",
    weight: "5,5",
  },
  {
    piping_flange_id: 32,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 156,
    dr_no: 4,
    dr_d: "22,2",
    dr_g: "114,3",
    a: "48,3",
    tt: "6,4",
    c_mini: "36,6",
    y: "84,3",
    x: 70,
    r: 73,
    weight: "7,8",
  },
  {
    piping_flange_id: 33,
    dn: 50,
    nps: 2,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 165,
    dr_no: 8,
    dr_d: 19,
    dr_g: 127,
    a: "60,3",
    tt: "6,4",
    c_mini: "36,6",
    y: "84,3",
    x: 84,
    r: "92,1",
    weight: "8,3",
  },
  {
    piping_flange_id: 34,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 190,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "149,2",
    a: 73,
    tt: "6,4",
    c_mini: "36,6",
    y: "87,3",
    x: 100,
    r: "104,8",
    weight: "10,8",
  },
  {
    piping_flange_id: 35,
    dn: 80,
    nps: 3,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 210,
    dr_no: 8,
    dr_d: "22,2",
    dr_g: "168,3",
    a: "88,9",
    tt: "9,5",
    c_mini: "36,6",
    y: "87,3",
    x: 118,
    r: 127,
    weight: "12,6",
  },
  {
    piping_flange_id: 36,
    dn: 100,
    nps: 4,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 273,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "215,9",
    a: "114,3",
    tt: "12,7",
    c_mini: "38,1",
    y: "101,6",
    x: 152,
    r: "157,2",
    weight: 19,
  },
  {
    piping_flange_id: 37,
    dn: 150,
    nps: 6,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 356,
    dr_no: 12,
    dr_d: "28,5",
    dr_g: "292,1",
    a: "168,3",
    tt: "12,7",
    c_mini: "47,8",
    y: "117,3",
    x: 222,
    r: "215,9",
    weight: 37,
  },
  {
    piping_flange_id: 38,
    dn: 200,
    nps: 8,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 419,
    dr_no: 12,
    dr_d: "31,8",
    dr_g: "349,2",
    a: "219,1",
    tt: "12,7",
    c_mini: "55,6",
    y: "133,4",
    x: 273,
    r: "269,9",
    weight: 53,
  },
  {
    piping_flange_id: 39,
    dn: 250,
    nps: 10,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 508,
    dr_no: 16,
    dr_d: 35,
    dr_g: "431,8",
    a: 273,
    tt: "12,7",
    c_mini: "63,5",
    y: "152,4",
    x: 343,
    r: "323,8",
    weight: 86,
  },
  {
    piping_flange_id: 40,
    dn: 300,
    nps: 12,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 559,
    dr_no: 20,
    dr_d: 35,
    dr_g: 489,
    a: "323,9",
    tt: "12,7",
    c_mini: "66,5",
    y: "155,4",
    x: 400,
    r: 381,
    weight: 102,
  },
  {
    piping_flange_id: 41,
    dn: 350,
    nps: 14,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 603,
    dr_no: 20,
    dr_d: "38,1",
    dr_g: 527,
    a: "355,6",
    tt: "12,7",
    c_mini: "69,9",
    y: "165,1",
    x: 432,
    r: "412,8",
    weight: 150,
  },
  {
    piping_flange_id: 42,
    dn: 400,
    nps: 16,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 686,
    dr_no: 20,
    dr_d: "41,1",
    dr_g: "603,2",
    a: "406,4",
    tt: "12,7",
    c_mini: "76,2",
    y: "177,8",
    x: 495,
    r: "469,9",
    weight: 190,
  },
  {
    piping_flange_id: 43,
    dn: 450,
    nps: 18,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 743,
    dr_no: 20,
    dr_d: "44,5",
    dr_g: 654,
    a: "457,2",
    tt: "12,7",
    c_mini: "82,6",
    y: "184,2",
    x: 546,
    r: "533,4",
    weight: 240,
  },
  {
    piping_flange_id: 44,
    dn: 500,
    nps: 20,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 813,
    dr_no: 24,
    dr_d: "44,5",
    dr_g: "723,9",
    a: 508,
    tt: "12,7",
    c_mini: "88,9",
    y: "190,2",
    x: 610,
    r: "584,2",
    weight: 295,
  },
  {
    piping_flange_id: 45,
    dn: 600,
    nps: 24,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 600,
    o: 940,
    dr_no: 24,
    dr_d: "50,8",
    dr_g: "838,2",
    a: 610,
    tt: "12,7",
    c_mini: "101,6",
    y: "203,2",
    x: 718,
    r: "692,2",
    weight: 365,
  },
  {
    piping_flange_id: 46,
    dn: 25,
    nps: 1,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 149,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: "101,6",
    a: "33,4",
    tt: "6,4",
    c_mini: "38,1",
    y: "82,6",
    x: 52,
    r: "50,8",
    weight: "5,4",
  },
  {
    piping_flange_id: 47,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 178,
    dr_no: 4,
    dr_d: "28,5",
    dr_g: "123,8",
    a: "48,3",
    tt: "6,4",
    c_mini: "38,1",
    y: "88,9",
    x: 70,
    r: 73,
    weight: "7,8",
  },
  {
    piping_flange_id: 48,
    dn: 50,
    nps: 2,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 216,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "165,1",
    a: "60,3",
    tt: "6,4",
    c_mini: "38,1",
    y: "101,6",
    x: 105,
    r: "92,1",
    weight: "11,5",
  },
  {
    piping_flange_id: 49,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 244,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "190,5",
    a: 73,
    tt: "6,4",
    c_mini: "41,1",
    y: "104,6",
    x: 124,
    r: "104,8",
    weight: "15,8",
  },
  {
    piping_flange_id: 50,
    dn: 80,
    nps: 3,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 241,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "190,5",
    a: "88,9",
    tt: "9,5",
    c_mini: "38,1",
    y: "101,6",
    x: 127,
    r: 127,
    weight: "14,5",
  },
  {
    piping_flange_id: 51,
    dn: 100,
    nps: 4,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 292,
    dr_no: 8,
    dr_d: "31,8",
    dr_g: 235,
    a: "114,3",
    tt: "12,7",
    c_mini: "44,5",
    y: "114,3",
    x: 159,
    r: "157,2",
    weight: 23,
  },
  {
    piping_flange_id: 52,
    dn: 150,
    nps: 6,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 381,
    dr_no: 12,
    dr_d: "31,8",
    dr_g: "317,5",
    a: "168,3",
    tt: "12,7",
    c_mini: "55,6",
    y: "139,7",
    x: 235,
    r: "215,9",
    weight: 50,
  },
  {
    piping_flange_id: 53,
    dn: 200,
    nps: 8,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 470,
    dr_no: 12,
    dr_d: "38,1",
    dr_g: "393,7",
    a: "219,1",
    tt: "12,7",
    c_mini: "63,5",
    y: "162,1",
    x: 298,
    r: "269,9",
    weight: 85,
  },
  {
    piping_flange_id: 54,
    dn: 250,
    nps: 10,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 546,
    dr_no: 16,
    dr_d: "38,1",
    dr_g: "469,9",
    a: 273,
    tt: "12,7",
    c_mini: "69,9",
    y: "184,2",
    x: 368,
    r: "323,8",
    weight: 118,
  },
  {
    piping_flange_id: 55,
    dn: 300,
    nps: 12,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 610,
    dr_no: 20,
    dr_d: "38,1",
    dr_g: "533,4",
    a: "323,9",
    tt: "12,7",
    c_mini: "79,2",
    y: "200,2",
    x: 419,
    r: 381,
    weight: 163,
  },
  {
    piping_flange_id: 56,
    dn: 350,
    nps: 14,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 641,
    dr_no: 20,
    dr_d: "41,1",
    dr_g: "558,8",
    a: "355,6",
    tt: "12,7",
    c_mini: "85,9",
    y: "212,9",
    x: 451,
    r: "412,8",
    weight: 186,
  },
  {
    piping_flange_id: 57,
    dn: 400,
    nps: 16,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 705,
    dr_no: 20,
    dr_d: "44,5",
    dr_g: 616,
    a: "406,4",
    tt: "12,7",
    c_mini: "88,9",
    y: "215,9",
    x: 508,
    r: "469,9",
    weight: 224,
  },
  {
    piping_flange_id: 58,
    dn: 450,
    nps: 18,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 787,
    dr_no: 20,
    dr_d: "50,8",
    dr_g: "685,8",
    a: "457,2",
    tt: "12,7",
    c_mini: "101,6",
    y: "228,6",
    x: 565,
    r: "533,4",
    weight: 300,
  },
  {
    piping_flange_id: 59,
    dn: 500,
    nps: 20,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 857,
    dr_no: 20,
    dr_d: "53,8",
    dr_g: "749,3",
    a: 508,
    tt: "12,7",
    c_mini: 108,
    y: "247,7",
    x: 622,
    r: "584,2",
    weight: 373,
  },
  {
    piping_flange_id: 60,
    dn: 600,
    nps: 24,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 900,
    o: 1041,
    dr_no: 20,
    dr_d: "66,5",
    dr_g: "901,7",
    a: 610,
    tt: "12,7",
    c_mini: "139,7",
    y: "292,1",
    x: 749,
    r: "692,2",
    weight: 680,
  },
  {
    piping_flange_id: 61,
    dn: 25,
    nps: 1,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 149,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: "101,6",
    a: "33,4",
    tt: "6,4",
    c_mini: "38,1",
    y: "82,6",
    x: 52,
    r: "50,8",
    weight: "5,4",
  },
  {
    piping_flange_id: 62,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 178,
    dr_no: 4,
    dr_d: "28,5",
    dr_g: "123,8",
    a: "48,3",
    tt: "6,4",
    c_mini: "38,1",
    y: "88,9",
    x: 70,
    r: 73,
    weight: "7,8",
  },
  {
    piping_flange_id: 63,
    dn: 50,
    nps: 2,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 216,
    dr_no: 8,
    dr_d: "25,4",
    dr_g: "165,1",
    a: "60,3",
    tt: "6,4",
    c_mini: "38,1",
    y: "101,6",
    x: 105,
    r: "92,1",
    weight: "11,5",
  },
  {
    piping_flange_id: 64,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 244,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "190,5",
    a: 73,
    tt: "6,4",
    c_mini: "41,1",
    y: "104,6",
    x: 124,
    r: "104,8",
    weight: "15,8",
  },
  {
    piping_flange_id: 65,
    dn: 80,
    nps: 3,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 267,
    dr_no: 8,
    dr_d: "31,8",
    dr_g: "203,2",
    a: "88,9",
    tt: "9,5",
    c_mini: "47,8",
    y: "117,3",
    x: 133,
    r: 127,
    weight: 22,
  },
  {
    piping_flange_id: 66,
    dn: 100,
    nps: 4,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 311,
    dr_no: 8,
    dr_d: 35,
    dr_g: "241,3",
    a: "114,3",
    tt: "12,7",
    c_mini: "53,8",
    y: 124,
    x: 162,
    r: "157,2",
    weight: 30,
  },
  {
    piping_flange_id: 67,
    dn: 150,
    nps: 6,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 394,
    dr_no: 12,
    dr_d: "38,1",
    dr_g: "317,5",
    a: "168,3",
    tt: "12,7",
    c_mini: "82,6",
    y: "171,5",
    x: 229,
    r: "215,9",
    weight: 70,
  },
  {
    piping_flange_id: 68,
    dn: 200,
    nps: 8,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 483,
    dr_no: 12,
    dr_d: "44,5",
    dr_g: "393,7",
    a: "219,1",
    tt: "12,7",
    c_mini: "91,9",
    y: "212,9",
    x: 292,
    r: "269,9",
    weight: 119,
  },
  {
    piping_flange_id: 69,
    dn: 250,
    nps: 10,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 584,
    dr_no: 12,
    dr_d: "50,8",
    dr_g: "482,6",
    a: 273,
    tt: "12,7",
    c_mini: 108,
    y: 254,
    x: 368,
    r: "323,8",
    weight: 204,
  },
  {
    piping_flange_id: 70,
    dn: 300,
    nps: 12,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 673,
    dr_no: 16,
    dr_d: "53,8",
    dr_g: "571,5",
    a: "323,9",
    tt: "12,7",
    c_mini: 124,
    y: "282,4",
    x: 451,
    r: 381,
    weight: 303,
  },
  {
    piping_flange_id: 71,
    dn: 350,
    nps: 14,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 749,
    dr_no: 16,
    dr_d: "60,5",
    dr_g: 635,
    a: "355,6",
    tt: "12,7",
    c_mini: "133,4",
    y: "298,4",
    x: 495,
    r: "412,8",
    weight: 400,
  },
  {
    piping_flange_id: 72,
    dn: 400,
    nps: 16,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 826,
    dr_no: 16,
    dr_d: "66,5",
    dr_g: "704,8",
    a: "406,4",
    tt: "12,7",
    c_mini: 146,
    y: "311,2",
    x: 552,
    r: "469,9",
    weight: 510,
  },
  {
    piping_flange_id: 73,
    dn: 450,
    nps: 18,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 914,
    dr_no: 16,
    dr_d: "73,2",
    dr_g: "774,7",
    a: "457,2",
    tt: "12,7",
    c_mini: 162,
    y: "327,2",
    x: 597,
    r: "533,4",
    weight: "",
  },
  {
    piping_flange_id: 74,
    dn: 500,
    nps: 20,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 984,
    dr_no: 16,
    dr_d: "79,2",
    dr_g: "831,8",
    a: 508,
    tt: "12,7",
    c_mini: "177,8",
    y: "355,6",
    x: 641,
    r: "584,2",
    weight: "",
  },
  {
    piping_flange_id: 75,
    dn: 600,
    nps: 24,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 1500,
    o: 1168,
    dr_no: 16,
    dr_d: "91,9",
    dr_g: "990,6",
    a: 610,
    tt: "12,7",
    c_mini: "203,2",
    y: "406,4",
    x: 762,
    r: "692,2",
    weight: "",
  },
  {
    piping_flange_id: 76,
    dn: 25,
    nps: 1,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 2500,
    o: 159,
    dr_no: 4,
    dr_d: "25,4",
    dr_g: 108,
    a: "33,4",
    tt: "6,4",
    c_mini: "38,1",
    y: "91,9",
    x: 57,
    r: "50,8",
    weight: "6,5",
  },
  {
    piping_flange_id: 77,
    dn: 40,
    nps: "1 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 2500,
    o: 203,
    dr_no: 4,
    dr_d: "31,8",
    dr_g: 146,
    a: "48,3",
    tt: "6,4",
    c_mini: "44,5",
    y: "111,3",
    x: 79,
    r: 73,
    weight: 13,
  },
  {
    piping_flange_id: 78,
    dn: 50,
    nps: 2,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 2500,
    o: 235,
    dr_no: 8,
    dr_d: "28,5",
    dr_g: "171,5",
    a: "60,3",
    tt: "6,4",
    c_mini: "50,8",
    y: 127,
    x: 95,
    r: "92,1",
    weight: 19,
  },
  {
    piping_flange_id: 79,
    dn: 65,
    nps: "2 1/2",
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 2500,
    o: 267,
    dr_no: 8,
    dr_d: "31,8",
    dr_g: "196,9",
    a: 73,
    tt: "6,4",
    c_mini: "57,2",
    y: "142,7",
    x: 114,
    r: "104,8",
    weight: 24,
  },
  {
    piping_flange_id: 80,
    dn: 80,
    nps: 3,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 2500,
    o: 305,
    dr_no: 8,
    dr_d: 35,
    dr_g: "228,6",
    a: "88,9",
    tt: "9,5",
    c_mini: "66,5",
    y: "168,1",
    x: 133,
    r: 127,
    weight: 43,
  },
  {
    piping_flange_id: 81,
    dn: 100,
    nps: 4,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 2500,
    o: 356,
    dr_no: 8,
    dr_d: "41,1",
    dr_g: 273,
    a: "114,3",
    tt: "12,7",
    c_mini: "76,2",
    y: "190,5",
    x: 165,
    r: "157,2",
    weight: 66,
  },
  {
    piping_flange_id: 82,
    dn: 150,
    nps: 6,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 2500,
    o: 483,
    dr_no: 8,
    dr_d: "53,8",
    dr_g: "368,3",
    a: "168,3",
    tt: "12,7",
    c_mini: 108,
    y: "273,1",
    x: 235,
    r: "215,9",
    weight: 172,
  },
  {
    piping_flange_id: 83,
    dn: 200,
    nps: 8,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 2500,
    o: 552,
    dr_no: 12,
    dr_d: "53,8",
    dr_g: "438,2",
    a: "219,1",
    tt: "12,7",
    c_mini: 127,
    y: "317,5",
    x: 305,
    r: "269,9",
    weight: 261,
  },
  {
    piping_flange_id: 84,
    dn: 250,
    nps: 10,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 2500,
    o: 673,
    dr_no: 12,
    dr_d: "66,5",
    dr_g: "539,8",
    a: 273,
    tt: "12,7",
    c_mini: "165,1",
    y: "419,1",
    x: 375,
    r: "323,8",
    weight: 485,
  },
  {
    piping_flange_id: 85,
    dn: 300,
    nps: 12,
    material: "ASME B 16.36 -1996",
    shape: "FLANGE - ORIFICE WELDING NECK",
    class: 2500,
    o: 762,
    dr_no: 12,
    dr_d: "73,2",
    dr_g: "619,3",
    a: "323,9",
    tt: "12,7",
    c_mini: "184,2",
    y: "463,6",
    x: 441,
    r: 381,
    weight: 730,
  },
].map((item) => ({
  ...item,
  nps: item.nps + "",
  o: fixValueToNumber(item.o, "float"),
  dr_d: fixValueToNumber(item.dr_d, "float"),
  dr_g: fixValueToNumber(item.dr_g, "float"),
  r: fixValueToNumber(item.r, "float"),
  a: fixValueToNumber(item.a, "float"),
  x: fixValueToNumber(item.x, "float"),
  y: fixValueToNumber(item.y, "float"),
  tt: fixValueToNumber(item.tt, "float"),
  weight: fixValueToNumber(item.weight, "float"),
}));
