import { fixValueToNumber } from "../../components/3d-models/utils";

export const pipingTees = [
  {
    piping_tees_id: 1,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 21,
    d1: "",
    d2: "",
    c: 25,
    m: 25,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "2,77",
    t1: "",
    t2: "",
    weight: "0,14",
  },
  {
    piping_tees_id: 2,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 27,
    d1: "",
    d2: "",
    c: 29,
    m: 29,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "2,87",
    t1: "",
    t2: "",
    weight: "0,2",
  },
  {
    piping_tees_id: 3,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 33,
    d1: "",
    d2: "",
    c: 38,
    m: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,38",
    t1: "",
    t2: "",
    weight: "0,29",
  },
  {
    piping_tees_id: 4,
    nps: "1,25",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 42,
    d1: "",
    d2: "",
    c: 48,
    m: 48,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,56",
    t1: "",
    t2: "",
    weight: "0,53",
  },
  {
    piping_tees_id: 5,
    nps: "1,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 48,
    d1: "",
    d2: "",
    c: 57,
    m: 57,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,68",
    t1: "",
    t2: "",
    weight: "0,77",
  },
  {
    piping_tees_id: 6,
    nps: "2,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 60,
    d1: "",
    d2: "",
    c: 64,
    m: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,91",
    t1: "",
    t2: "",
    weight: "1,88",
  },
  {
    piping_tees_id: 7,
    nps: "2,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 73,
    d1: "",
    d2: "",
    c: 76,
    m: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,16",
    t1: "",
    t2: "",
    weight: "2,69",
  },
  {
    piping_tees_id: 8,
    nps: "3,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 89,
    d1: "",
    d2: "",
    c: 86,
    m: 86,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,49",
    t1: "",
    t2: "",
    weight: "3,82",
  },
  {
    piping_tees_id: 9,
    nps: "3,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 102,
    d1: "",
    d2: "",
    c: 95,
    m: 95,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,74",
    t1: "",
    t2: "",
    weight: "5,18",
  },
  {
    piping_tees_id: 10,
    nps: "4,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 114,
    d1: "",
    d2: "",
    c: 105,
    m: 105,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,02",
    t1: "",
    t2: "",
    weight: 6,
  },
  {
    piping_tees_id: 11,
    nps: "5,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 141,
    d1: "",
    d2: "",
    c: 124,
    m: 124,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,55",
    t1: "",
    t2: "",
    weight: "9,94",
  },
  {
    piping_tees_id: 12,
    nps: "6,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 168,
    d1: "",
    d2: "",
    c: 143,
    m: 143,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "7,11",
    t1: "",
    t2: "",
    weight: "16,48",
  },
  {
    piping_tees_id: 13,
    nps: "8,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 219,
    d1: "",
    d2: "",
    c: 178,
    m: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "8,18",
    t1: "",
    t2: "",
    weight: 33,
  },
  {
    piping_tees_id: 14,
    nps: "10,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 273,
    d1: "",
    d2: "",
    c: 216,
    m: 216,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,27",
    t1: "",
    t2: "",
    weight: "49,35",
  },
  {
    piping_tees_id: 15,
    nps: "12,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 324,
    d1: "",
    d2: "",
    c: 254,
    m: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 65,
  },
  {
    piping_tees_id: 16,
    nps: "14,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 356,
    d1: "",
    d2: "",
    c: 279,
    m: 279,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 93,
  },
  {
    piping_tees_id: 17,
    nps: "16,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 406,
    d1: "",
    d2: "",
    c: 305,
    m: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 115,
  },
  {
    piping_tees_id: 18,
    nps: "18,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 457,
    d1: "",
    d2: "",
    c: 343,
    m: 343,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 135,
  },
  {
    piping_tees_id: 19,
    nps: "20,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 508,
    d1: "",
    d2: "",
    c: 381,
    m: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 168,
  },
  {
    piping_tees_id: 20,
    nps: "22,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 559,
    d1: "",
    d2: "",
    c: 419,
    m: 419,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 200,
  },
  {
    piping_tees_id: 21,
    nps: "24,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 610,
    d1: "",
    d2: "",
    c: 432,
    m: 432,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 240,
  },
  {
    piping_tees_id: 22,
    nps: "26,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 660,
    d1: "",
    d2: "",
    c: 495,
    m: 495,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 288,
  },
  {
    piping_tees_id: 23,
    nps: "28,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 711,
    d1: "",
    d2: "",
    c: 521,
    m: 521,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 337,
  },
  {
    piping_tees_id: 24,
    nps: "30,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 762,
    d1: "",
    d2: "",
    c: 559,
    m: 559,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: "388,5",
  },
  {
    piping_tees_id: 25,
    nps: "32,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 813,
    d1: "",
    d2: "",
    c: 597,
    m: 597,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 451,
  },
  {
    piping_tees_id: 26,
    nps: "34,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 864,
    d1: "",
    d2: "",
    c: 635,
    m: 635,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 516,
  },
  {
    piping_tees_id: 27,
    nps: "36,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 914,
    d1: "",
    d2: "",
    c: 673,
    m: 673,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: "587,5",
  },
  {
    piping_tees_id: 28,
    nps: "38,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 965,
    d1: "",
    d2: "",
    c: 711,
    m: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 638,
  },
  {
    piping_tees_id: 29,
    nps: "40,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1016,
    d1: "",
    d2: "",
    c: 749,
    m: 749,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 690,
  },
  {
    piping_tees_id: 30,
    nps: "42,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1067,
    d1: "",
    d2: "",
    c: 762,
    m: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 831,
  },
  {
    piping_tees_id: 31,
    nps: "44,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1118,
    d1: "",
    d2: "",
    c: 813,
    m: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 899,
  },
  {
    piping_tees_id: 32,
    nps: "46,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1168,
    d1: "",
    d2: "",
    c: 851,
    m: 800,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 1007,
  },
  {
    piping_tees_id: 33,
    nps: "48,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1219,
    d1: "",
    d2: "",
    c: 889,
    m: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    t1: "",
    t2: "",
    weight: "1112,5",
  },
  {
    piping_tees_id: 34,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 21,
    d1: "",
    d2: "",
    c: 25,
    m: 25,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "3,73",
    t1: "",
    t2: "",
    weight: "0,25",
  },
  {
    piping_tees_id: 35,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 27,
    d1: "",
    d2: "",
    c: 29,
    m: 29,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "3,91",
    t1: "",
    t2: "",
    weight: "0,27",
  },
  {
    piping_tees_id: 36,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 33,
    d1: "",
    d2: "",
    c: 38,
    m: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,55",
    t1: "",
    t2: "",
    weight: "0,35",
  },
  {
    piping_tees_id: 37,
    nps: "1,25",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 42,
    d1: "",
    d2: "",
    c: 48,
    m: 48,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,85",
    t1: "",
    t2: "",
    weight: "0,65",
  },
  {
    piping_tees_id: 38,
    nps: "1,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 48,
    d1: "",
    d2: "",
    c: 57,
    m: 57,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,08",
    t1: "",
    t2: "",
    weight: "0,96",
  },
  {
    piping_tees_id: 39,
    nps: "2,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 60,
    d1: "",
    d2: "",
    c: 64,
    m: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,54",
    t1: "",
    t2: "",
    weight: "1,9",
  },
  {
    piping_tees_id: 40,
    nps: "2,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 73,
    d1: "",
    d2: "",
    c: 76,
    m: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,01",
    t1: "",
    t2: "",
    weight: "3,07",
  },
  {
    piping_tees_id: 41,
    nps: "3,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 89,
    d1: "",
    d2: "",
    c: 86,
    m: 86,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,62",
    t1: "",
    t2: "",
    weight: "4,5",
  },
  {
    piping_tees_id: 42,
    nps: "3,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 102,
    d1: "",
    d2: "",
    c: 95,
    m: 95,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,08",
    t1: "",
    t2: "",
    weight: "6,17",
  },
  {
    piping_tees_id: 43,
    nps: "4,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 114,
    d1: "",
    d2: "",
    c: 105,
    m: 105,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,56",
    t1: "",
    t2: "",
    weight: "8,44",
  },
  {
    piping_tees_id: 44,
    nps: "5,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 141,
    d1: "",
    d2: "",
    c: 124,
    m: 124,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "9,52",
    t1: "",
    t2: "",
    weight: "12,94",
  },
  {
    piping_tees_id: 45,
    nps: "6,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 168,
    d1: "",
    d2: "",
    c: 143,
    m: 143,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "10,97",
    t1: "",
    t2: "",
    weight: "19,3",
  },
  {
    piping_tees_id: 46,
    nps: "8,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 219,
    d1: "",
    d2: "",
    c: 178,
    m: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: "34,5",
  },
  {
    piping_tees_id: 47,
    nps: "10,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 273,
    d1: "",
    d2: "",
    c: 216,
    m: 216,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: "58,57",
  },
  {
    piping_tees_id: 48,
    nps: "12,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 324,
    d1: "",
    d2: "",
    c: 254,
    m: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: "84,9",
  },
  {
    piping_tees_id: 49,
    nps: "14,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 356,
    d1: "",
    d2: "",
    c: 279,
    m: 279,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: "127,12",
  },
  {
    piping_tees_id: 50,
    nps: "16,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 406,
    d1: "",
    d2: "",
    c: 305,
    m: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: "167,52",
  },
  {
    piping_tees_id: 51,
    nps: "18,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 457,
    d1: "",
    d2: "",
    c: 343,
    m: 343,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 190,
  },
  {
    piping_tees_id: 52,
    nps: "20,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 508,
    d1: "",
    d2: "",
    c: 381,
    m: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 245,
  },
  {
    piping_tees_id: 53,
    nps: "22,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 559,
    d1: "",
    d2: "",
    c: 419,
    m: 419,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 280,
  },
  {
    piping_tees_id: 54,
    nps: "24,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 610,
    d1: "",
    d2: "",
    c: 432,
    m: 432,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 350,
  },
  {
    piping_tees_id: 55,
    nps: "26,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 660,
    d1: "",
    d2: "",
    c: 495,
    m: 495,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: "360,5",
  },
  {
    piping_tees_id: 56,
    nps: "28,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 711,
    d1: "",
    d2: "",
    c: 521,
    m: 521,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 421,
  },
  {
    piping_tees_id: 57,
    nps: "30,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 762,
    d1: "",
    d2: "",
    c: 559,
    m: 559,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: "483,5",
  },
  {
    piping_tees_id: 58,
    nps: "32,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 813,
    d1: "",
    d2: "",
    c: 597,
    m: 597,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 559,
  },
  {
    piping_tees_id: 59,
    nps: "34,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 864,
    d1: "",
    d2: "",
    c: 635,
    m: 635,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 645,
  },
  {
    piping_tees_id: 60,
    nps: "36,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 914,
    d1: "",
    d2: "",
    c: 673,
    m: 673,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 731,
  },
  {
    piping_tees_id: 61,
    nps: "38,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 965,
    d1: "",
    d2: "",
    c: 711,
    m: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 812,
  },
  {
    piping_tees_id: 62,
    nps: "40,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1016,
    d1: "",
    d2: "",
    c: 749,
    m: 749,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: "894,5",
  },
  {
    piping_tees_id: 63,
    nps: "42,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1067,
    d1: "",
    d2: "",
    c: 762,
    m: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 1045,
  },
  {
    piping_tees_id: 64,
    nps: "44,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1118,
    d1: "",
    d2: "",
    c: 813,
    m: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 1194,
  },
  {
    piping_tees_id: 65,
    nps: "46,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1168,
    d1: "",
    d2: "",
    c: 851,
    m: 800,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 1346,
  },
  {
    piping_tees_id: 66,
    nps: "48,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1219,
    d1: "",
    d2: "",
    c: 889,
    m: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    t1: "",
    t2: "",
    weight: "1498,5",
  },
  {
    piping_tees_id: 67,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 21,
    d1: "",
    d2: "",
    c: 25,
    m: 25,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "7,47",
    t1: "",
    t2: "",
    weight: -1,
  },
  {
    piping_tees_id: 68,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 27,
    d1: "",
    d2: "",
    c: 29,
    m: 29,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "7,82",
    t1: "",
    t2: "",
    weight: "0,38",
  },
  {
    piping_tees_id: 69,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 33,
    d1: "",
    d2: "",
    c: 38,
    m: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "9,09",
    t1: "",
    t2: "",
    weight: "0,58",
  },
  {
    piping_tees_id: 70,
    nps: "1,25",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 42,
    d1: "",
    d2: "",
    c: 48,
    m: 48,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "9,7",
    t1: "",
    t2: "",
    weight: "1,05",
  },
  {
    piping_tees_id: 71,
    nps: "1,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 48,
    d1: "",
    d2: "",
    c: 57,
    m: 57,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "10,16",
    t1: "",
    t2: "",
    weight: "1,56",
  },
  {
    piping_tees_id: 72,
    nps: "2,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 60,
    d1: "",
    d2: "",
    c: 64,
    m: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "11,07",
    t1: "",
    t2: "",
    weight: "2,69",
  },
  {
    piping_tees_id: 73,
    nps: "2,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 73,
    d1: "",
    d2: "",
    c: 76,
    m: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "14,02",
    t1: "",
    t2: "",
    weight: "4,54",
  },
  {
    piping_tees_id: 74,
    nps: "3,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 89,
    d1: "",
    d2: "",
    c: 86,
    m: 86,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "15,24",
    t1: "",
    t2: "",
    weight: "7,63",
  },
  {
    piping_tees_id: 75,
    nps: "3,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 102,
    d1: "",
    d2: "",
    c: 95,
    m: 95,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 76,
    nps: "4,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 114,
    d1: "",
    d2: "",
    c: 105,
    m: 105,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "17,12",
    t1: "",
    t2: "",
    weight: 17,
  },
  {
    piping_tees_id: 77,
    nps: "5,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 141,
    d1: "",
    d2: "",
    c: 124,
    m: 124,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "19,05",
    t1: "",
    t2: "",
    weight: 25,
  },
  {
    piping_tees_id: 78,
    nps: "6,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 168,
    d1: "",
    d2: "",
    c: 143,
    m: 143,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "21,95",
    t1: "",
    t2: "",
    weight: 39,
  },
  {
    piping_tees_id: 79,
    nps: "8,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 219,
    d1: "",
    d2: "",
    c: 178,
    m: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "22,23",
    t1: "",
    t2: "",
    weight: 69,
  },
  {
    piping_tees_id: 80,
    nps: "10,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 273,
    d1: "",
    d2: "",
    c: 216,
    m: 216,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "25,4",
    t1: "",
    t2: "",
    weight: 98,
  },
  {
    piping_tees_id: 81,
    nps: "12,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 324,
    d1: "",
    d2: "",
    c: 254,
    m: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "25,4",
    t1: "",
    t2: "",
    weight: 150,
  },
  {
    piping_tees_id: 82,
    nps: "14,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 356,
    d1: "",
    d2: "",
    c: 279,
    m: 279,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 83,
    nps: "16,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 406,
    d1: "",
    d2: "",
    c: 305,
    m: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 84,
    nps: "18,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 457,
    d1: "",
    d2: "",
    c: 343,
    m: 343,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 85,
    nps: "20,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 508,
    d1: "",
    d2: "",
    c: 381,
    m: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 86,
    nps: "22,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 559,
    d1: "",
    d2: "",
    c: 419,
    m: 419,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 87,
    nps: "24,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 610,
    d1: "",
    d2: "",
    c: 432,
    m: 432,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 88,
    nps: "26,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 660,
    d1: "",
    d2: "",
    c: 495,
    m: 495,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 89,
    nps: "28,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 711,
    d1: "",
    d2: "",
    c: 521,
    m: 521,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 90,
    nps: "30,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 762,
    d1: "",
    d2: "",
    c: 559,
    m: 559,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 91,
    nps: "32,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 813,
    d1: "",
    d2: "",
    c: 597,
    m: 597,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 92,
    nps: "34,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 864,
    d1: "",
    d2: "",
    c: 635,
    m: 635,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 93,
    nps: "36,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 914,
    d1: "",
    d2: "",
    c: 673,
    m: 673,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 94,
    nps: "38,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 965,
    d1: "",
    d2: "",
    c: 711,
    m: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 95,
    nps: "40,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1016,
    d1: "",
    d2: "",
    c: 749,
    m: 749,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 96,
    nps: "42,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1067,
    d1: "",
    d2: "",
    c: 762,
    m: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 97,
    nps: "44,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1118,
    d1: "",
    d2: "",
    c: 813,
    m: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 98,
    nps: "46,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1168,
    d1: "",
    d2: "",
    c: 851,
    m: 800,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 99,
    nps: "48,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1219,
    d1: "",
    d2: "",
    c: 889,
    m: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 100,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 21,
    d1: "",
    d2: "",
    c: 25,
    m: 25,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 101,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 27,
    d1: "",
    d2: "",
    c: 29,
    m: 29,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 102,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 33,
    d1: "",
    d2: "",
    c: 38,
    m: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 103,
    nps: "1,25",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 42,
    d1: "",
    d2: "",
    c: 48,
    m: 48,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 104,
    nps: "1,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 48,
    d1: "",
    d2: "",
    c: 57,
    m: 57,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 105,
    nps: "2,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 60,
    d1: "",
    d2: "",
    c: 64,
    m: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 106,
    nps: "2,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 73,
    d1: "",
    d2: "",
    c: 76,
    m: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 107,
    nps: "3,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 89,
    d1: "",
    d2: "",
    c: 86,
    m: 86,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 108,
    nps: "3,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 102,
    d1: "",
    d2: "",
    c: 95,
    m: 95,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 109,
    nps: "4,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 114,
    d1: "",
    d2: "",
    c: 105,
    m: 105,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 110,
    nps: "5,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 141,
    d1: "",
    d2: "",
    c: 124,
    m: 124,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 111,
    nps: "6,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 168,
    d1: "",
    d2: "",
    c: 143,
    m: 143,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 112,
    nps: "8,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 219,
    d1: "",
    d2: "",
    c: 178,
    m: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 113,
    nps: "10,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 273,
    d1: "",
    d2: "",
    c: 216,
    m: 216,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 114,
    nps: "12,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 324,
    d1: "",
    d2: "",
    c: 254,
    m: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 115,
    nps: "14,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 356,
    d1: "",
    d2: "",
    c: 279,
    m: 279,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    t1: "",
    t2: "",
    weight: "87,16",
  },
  {
    piping_tees_id: 116,
    nps: "16,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 406,
    d1: "",
    d2: "",
    c: 305,
    m: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    t1: "",
    t2: "",
    weight: "90,8",
  },
  {
    piping_tees_id: 117,
    nps: "18,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 457,
    d1: "",
    d2: "",
    c: 343,
    m: 343,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    t1: "",
    t2: "",
    weight: "94,5",
  },
  {
    piping_tees_id: 118,
    nps: "20,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 508,
    d1: "",
    d2: "",
    c: 381,
    m: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    t1: "",
    t2: "",
    weight: 120,
  },
  {
    piping_tees_id: 119,
    nps: "22,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 559,
    d1: "",
    d2: "",
    c: 419,
    m: 419,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    t1: "",
    t2: "",
    weight: 143,
  },
  {
    piping_tees_id: 120,
    nps: "24,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 610,
    d1: "",
    d2: "",
    c: 432,
    m: 432,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    t1: "",
    t2: "",
    weight: 170,
  },
  {
    piping_tees_id: 121,
    nps: "26,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 660,
    d1: "",
    d2: "",
    c: 495,
    m: 495,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "7,92",
    t1: "",
    t2: "",
    weight: 240,
  },
  {
    piping_tees_id: 122,
    nps: "28,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 711,
    d1: "",
    d2: "",
    c: 521,
    m: 521,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "7,92",
    t1: "",
    t2: "",
    weight: 280,
  },
  {
    piping_tees_id: 123,
    nps: "30,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 762,
    d1: "",
    d2: "",
    c: 559,
    m: 559,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "7,92",
    t1: "",
    t2: "",
    weight: 322,
  },
  {
    piping_tees_id: 124,
    nps: "32,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 813,
    d1: "",
    d2: "",
    c: 597,
    m: 597,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "7,92",
    t1: "",
    t2: "",
    weight: 375,
  },
  {
    piping_tees_id: 125,
    nps: "34,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 864,
    d1: "",
    d2: "",
    c: 635,
    m: 635,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "7,92",
    t1: "",
    t2: "",
    weight: 429,
  },
  {
    piping_tees_id: 126,
    nps: "36,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 914,
    d1: "",
    d2: "",
    c: 673,
    m: 673,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "7,92",
    t1: "",
    t2: "",
    weight: 488,
  },
  {
    piping_tees_id: 127,
    nps: "38,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 965,
    d1: "",
    d2: "",
    c: 711,
    m: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 128,
    nps: "40,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1016,
    d1: "",
    d2: "",
    c: 749,
    m: 749,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 129,
    nps: "42,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1067,
    d1: "",
    d2: "",
    c: 762,
    m: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 130,
    nps: "44,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1118,
    d1: "",
    d2: "",
    c: 813,
    m: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 131,
    nps: "46,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1168,
    d1: "",
    d2: "",
    c: 851,
    m: 800,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 132,
    nps: "48,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1219,
    d1: "",
    d2: "",
    c: 889,
    m: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 133,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 21,
    d1: "",
    d2: "",
    c: 25,
    m: 25,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 134,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 27,
    d1: "",
    d2: "",
    c: 29,
    m: 29,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 135,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 33,
    d1: "",
    d2: "",
    c: 38,
    m: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 136,
    nps: "1,25",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 42,
    d1: "",
    d2: "",
    c: 48,
    m: 48,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 137,
    nps: "1,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 48,
    d1: "",
    d2: "",
    c: 57,
    m: 57,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 138,
    nps: "2,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 60,
    d1: "",
    d2: "",
    c: 64,
    m: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 139,
    nps: "2,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 73,
    d1: "",
    d2: "",
    c: 76,
    m: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 140,
    nps: "3,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 89,
    d1: "",
    d2: "",
    c: 86,
    m: 86,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 141,
    nps: "3,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 102,
    d1: "",
    d2: "",
    c: 95,
    m: 95,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 142,
    nps: "4,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 114,
    d1: "",
    d2: "",
    c: 105,
    m: 105,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 143,
    nps: "5,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 141,
    d1: "",
    d2: "",
    c: 124,
    m: 124,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 144,
    nps: "6,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 168,
    d1: "",
    d2: "",
    c: 143,
    m: 143,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 145,
    nps: "8,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 219,
    d1: "",
    d2: "",
    c: 178,
    m: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "6,35",
    t1: "",
    t2: "",
    weight: 29,
  },
  {
    piping_tees_id: 146,
    nps: "10,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 273,
    d1: "",
    d2: "",
    c: 216,
    m: 216,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "6,35",
    t1: "",
    t2: "",
    weight: "34,7",
  },
  {
    piping_tees_id: 147,
    nps: "12,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 324,
    d1: "",
    d2: "",
    c: 254,
    m: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "6,35",
    t1: "",
    t2: "",
    weight: 59,
  },
  {
    piping_tees_id: 148,
    nps: "14,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 356,
    d1: "",
    d2: "",
    c: 279,
    m: 279,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "7,92",
    t1: "",
    t2: "",
    weight: 90,
  },
  {
    piping_tees_id: 149,
    nps: "16,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 406,
    d1: "",
    d2: "",
    c: 305,
    m: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "7,92",
    t1: "",
    t2: "",
    weight: 100,
  },
  {
    piping_tees_id: 150,
    nps: "18,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 457,
    d1: "",
    d2: "",
    c: 343,
    m: 343,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "7,92",
    t1: "",
    t2: "",
    weight: "127,12",
  },
  {
    piping_tees_id: 151,
    nps: "20,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 508,
    d1: "",
    d2: "",
    c: 381,
    m: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 168,
  },
  {
    piping_tees_id: 152,
    nps: "22,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 559,
    d1: "",
    d2: "",
    c: 419,
    m: 419,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 200,
  },
  {
    piping_tees_id: 153,
    nps: "24,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 610,
    d1: "",
    d2: "",
    c: 432,
    m: 432,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 240,
  },
  {
    piping_tees_id: 154,
    nps: "26,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 660,
    d1: "",
    d2: "",
    c: 495,
    m: 495,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "12,7",
    t1: "",
    t2: "",
    weight: "360,5",
  },
  {
    piping_tees_id: 155,
    nps: "28,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 711,
    d1: "",
    d2: "",
    c: 521,
    m: 521,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 421,
  },
  {
    piping_tees_id: 156,
    nps: "30,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 762,
    d1: "",
    d2: "",
    c: 559,
    m: 559,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "12,7",
    t1: "",
    t2: "",
    weight: "483,5",
  },
  {
    piping_tees_id: 157,
    nps: "32,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 813,
    d1: "",
    d2: "",
    c: 597,
    m: 597,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 559,
  },
  {
    piping_tees_id: 158,
    nps: "34,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 864,
    d1: "",
    d2: "",
    c: 635,
    m: 635,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 645,
  },
  {
    piping_tees_id: 159,
    nps: "36,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 914,
    d1: "",
    d2: "",
    c: 673,
    m: 673,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 731,
  },
  {
    piping_tees_id: 160,
    nps: "38,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 965,
    d1: "",
    d2: "",
    c: 711,
    m: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 161,
    nps: "40,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1016,
    d1: "",
    d2: "",
    c: 749,
    m: 749,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 162,
    nps: "42,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1067,
    d1: "",
    d2: "",
    c: 762,
    m: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 163,
    nps: "44,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1118,
    d1: "",
    d2: "",
    c: 813,
    m: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 164,
    nps: "46,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1168,
    d1: "",
    d2: "",
    c: 851,
    m: 800,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 165,
    nps: "48,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1219,
    d1: "",
    d2: "",
    c: 889,
    m: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 166,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 21,
    d1: "",
    d2: "",
    c: 25,
    m: 25,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "2,41",
    t1: "",
    t2: "",
    weight: -1,
  },
  {
    piping_tees_id: 167,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 27,
    d1: "",
    d2: "",
    c: 29,
    m: 29,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "2,41",
    t1: "",
    t2: "",
    weight: -1,
  },
  {
    piping_tees_id: 168,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 33,
    d1: "",
    d2: "",
    c: 38,
    m: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "2,9",
    t1: "",
    t2: "",
    weight: -1,
  },
  {
    piping_tees_id: 169,
    nps: "1,25",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 42,
    d1: "",
    d2: "",
    c: 48,
    m: 48,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "2,97",
    t1: "",
    t2: "",
    weight: -1,
  },
  {
    piping_tees_id: 170,
    nps: "1,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 48,
    d1: "",
    d2: "",
    c: 57,
    m: 57,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "3,18",
    t1: "",
    t2: "",
    weight: -1,
  },
  {
    piping_tees_id: 171,
    nps: "2,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 60,
    d1: "",
    d2: "",
    c: 64,
    m: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "3,18",
    t1: "",
    t2: "",
    weight: -1,
  },
  {
    piping_tees_id: 172,
    nps: "2,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 73,
    d1: "",
    d2: "",
    c: 76,
    m: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "4,78",
    t1: "",
    t2: "",
    weight: -1,
  },
  {
    piping_tees_id: 173,
    nps: "3,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 89,
    d1: "",
    d2: "",
    c: 86,
    m: 86,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "4,78",
    t1: "",
    t2: "",
    weight: -1,
  },
  {
    piping_tees_id: 174,
    nps: "3,50",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 102,
    d1: "",
    d2: "",
    c: 95,
    m: 95,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "4,78",
    t1: "",
    t2: "",
    weight: -1,
  },
  {
    piping_tees_id: 175,
    nps: "4,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 114,
    d1: "",
    d2: "",
    c: 105,
    m: 105,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "4,78",
    t1: "",
    t2: "",
    weight: -1,
  },
  {
    piping_tees_id: 176,
    nps: "5,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 141,
    d1: "",
    d2: "",
    c: 124,
    m: 124,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 177,
    nps: "6,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 168,
    d1: "",
    d2: "",
    c: 143,
    m: 143,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 178,
    nps: "8,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 219,
    d1: "",
    d2: "",
    c: 178,
    m: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "7,03",
    t1: "",
    t2: "",
    weight: 31,
  },
  {
    piping_tees_id: 179,
    nps: "10,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 273,
    d1: "",
    d2: "",
    c: 216,
    m: 216,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "7,8",
    t1: "",
    t2: "",
    weight: "36,77",
  },
  {
    piping_tees_id: 180,
    nps: "12,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 324,
    d1: "",
    d2: "",
    c: 254,
    m: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "8,38",
    t1: "",
    t2: "",
    weight: "61,74",
  },
  {
    piping_tees_id: 181,
    nps: "14,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 356,
    d1: "",
    d2: "",
    c: 279,
    m: 279,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 93,
  },
  {
    piping_tees_id: 182,
    nps: "16,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 406,
    d1: "",
    d2: "",
    c: 305,
    m: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "9,52",
    t1: "",
    t2: "",
    weight: 115,
  },
  {
    piping_tees_id: 183,
    nps: "18,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 457,
    d1: "",
    d2: "",
    c: 343,
    m: 343,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "11,13",
    t1: "",
    t2: "",
    weight: "181,14",
  },
  {
    piping_tees_id: 184,
    nps: "20,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 508,
    d1: "",
    d2: "",
    c: 381,
    m: 381,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 265,
  },
  {
    piping_tees_id: 185,
    nps: "22,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 559,
    d1: "",
    d2: "",
    c: 419,
    m: 419,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "12,7",
    t1: "",
    t2: "",
    weight: 355,
  },
  {
    piping_tees_id: 186,
    nps: "24,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 610,
    d1: "",
    d2: "",
    c: 432,
    m: 432,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "14,27",
    t1: "",
    t2: "",
    weight: "443,55",
  },
  {
    piping_tees_id: 187,
    nps: "26,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 660,
    d1: "",
    d2: "",
    c: 495,
    m: 495,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 188,
    nps: "28,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 711,
    d1: "",
    d2: "",
    c: 521,
    m: 521,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "15,88",
    t1: "",
    t2: "",
    weight: 526,
  },
  {
    piping_tees_id: 189,
    nps: "30,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 762,
    d1: "",
    d2: "",
    c: 559,
    m: 559,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "15,88",
    t1: "",
    t2: "",
    weight: 604,
  },
  {
    piping_tees_id: 190,
    nps: "32,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 813,
    d1: "",
    d2: "",
    c: 597,
    m: 597,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "15,88",
    t1: "",
    t2: "",
    weight: 699,
  },
  {
    piping_tees_id: 191,
    nps: "34,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 864,
    d1: "",
    d2: "",
    c: 635,
    m: 635,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "15,88",
    t1: "",
    t2: "",
    weight: 805,
  },
  {
    piping_tees_id: 192,
    nps: "36,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 914,
    d1: "",
    d2: "",
    c: 673,
    m: 673,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "15,88",
    t1: "",
    t2: "",
    weight: 913,
  },
  {
    piping_tees_id: 193,
    nps: "38,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 965,
    d1: "",
    d2: "",
    c: 711,
    m: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 194,
    nps: "40,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1016,
    d1: "",
    d2: "",
    c: 749,
    m: 749,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 195,
    nps: "42,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1067,
    d1: "",
    d2: "",
    c: 762,
    m: 711,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 196,
    nps: "44,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1118,
    d1: "",
    d2: "",
    c: 813,
    m: 762,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 197,
    nps: "46,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1168,
    d1: "",
    d2: "",
    c: 851,
    m: 800,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 198,
    nps: "48,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 1219,
    d1: "",
    d2: "",
    c: 889,
    m: 838,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    t1: "",
    t2: "",
    weight: "",
  },
  {
    piping_tees_id: 199,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 21,
    d1: "",
    d2: "",
    c: 25,
    m: 25,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 40",
    t: "2,77",
    t1: "",
    t2: "",
    weight: "0,16",
  },
  {
    piping_tees_id: 200,
    nps: "1,00",
    material: "ASME B 16.9-1993",
    shape: "TEES - STRAIGHT",
    d: 27,
    d1: "",
    d2: "",
    c: 29,
    m: 29,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 40",
    t: "2,87",
    t1: "",
    t2: "",
    weight: "0,2",
  },
].map((item) => ({
  ...item,
  id: item.piping_tees_id,
  nps: fixValueToNumber(item.nps, "float") + "",
  t: item.t ? fixValueToNumber(item.t, "float") : undefined,
  t1: item.t1 ? fixValueToNumber(item.t1, "float") : undefined,
  t2: item.t2 ? fixValueToNumber(item.t2, "float") : undefined,
  d: item.d ? fixValueToNumber(item.d, "float") : undefined,
  d1: item.d1 ? fixValueToNumber(item.d1, "float") : undefined,
  d2: item.d2 ? fixValueToNumber(item.d2, "float") : undefined,
  weight: fixValueToNumber(item.weight, "float"),
}));
