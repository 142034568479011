export type TPipingValveType =
  | "Gate"
  | "Globe"
  | "Check"
  | "Flange"
  | "Forged steel";

export type TPipingValve = {
  id: number;
  type: TPipingValveType;
  class: number;
  nps: string; // diameter (inch)
  a: number; // valve connector length (mm)
  v?: number; // valve diameter (mm)
  ho: number; // valve opened height (mm)
  k: number; // diameter (mm)
  weight: number; // valve masse (kg)
};

export const pipingValves: TPipingValve[] = [
  {
    id: 1,
    type: "Gate",
    class: 800,
    nps: "1/2",
    a: 84,
    v: 87,
    ho: 134,
    k: 21.8,
    weight: 1.7,
  },
  {
    id: 2,
    type: "Gate",
    class: 800,
    nps: "3/4",
    a: 90.5,
    v: 87,
    ho: 143,
    k: 27.2,
    weight: 2,
  },
  {
    id: 3,
    type: "Gate",
    class: 800,
    nps: "1",
    a: 114,
    v: 104,
    ho: 182,
    k: 33.9,
    weight: 3.5,
  },
  {
    id: 4,
    type: "Gate",
    class: 800,
    nps: "1 1/2",
    a: 120.5,
    v: 131,
    ho: 228.5,
    k: 48.8,
    weight: 6.2,
  },
  {
    id: 5,
    type: "Gate",
    class: 800,
    nps: "2",
    a: 130,
    v: 146,
    ho: 259,
    k: 61.2,
    weight: 8.4,
  },
  {
    id: 6,
    type: "Gate",
    class: 800,
    nps: "1/4",
    a: 84,
    v: 87,
    ho: 134,
    k: 14.2,
    weight: 1.7,
  },
  {
    id: 7,
    type: "Gate",
    class: 800,
    nps: "3/8",
    a: 84,
    v: 87,
    ho: 134,
    k: 17.6,
    weight: 1.7,
  },
  {
    id: 8,
    type: "Gate",
    class: 800,
    nps: "1/2",
    a: 90.5,
    v: 87,
    ho: 143,
    k: 21.8,
    weight: 2,
  },
  {
    id: 9,
    type: "Gate",
    class: 800,
    nps: "3/4",
    a: 114,
    v: 104,
    ho: 182,
    k: 27.2,
    weight: 3.6,
  },
  {
    id: 10,
    type: "Gate",
    class: 800,
    nps: "1",
    a: 120.5,
    v: 113,
    ho: 216,
    k: 33.9,
    weight: 5.1,
  },
  {
    id: 11,
    type: "Gate",
    class: 800,
    nps: "1 1/4",
    a: 120.5,
    v: 131,
    ho: 228.5,
    k: 42.7,
    weight: 6.5,
  },
  {
    id: 12,
    type: "Gate",
    class: 800,
    nps: "1 1/2",
    a: 130,
    v: 146,
    ho: 259,
    k: 48.8,
    weight: 8.9,
  },
  {
    id: 13,
    type: "Gate",
    class: 800,
    nps: "2",
    a: 140,
    v: 172,
    ho: 325.5,
    k: 61.2,
    weight: 15,
  },
  {
    id: 14,
    type: "Gate",
    class: 800,
    nps: "1/2",
    a: 84,
    v: 87,
    ho: 134,
    k: 21.8,
    weight: 1.3,
  },
  {
    id: 15,
    type: "Gate",
    class: 800,
    nps: "3/4",
    a: 90.5,
    v: 87,
    ho: 143,
    k: 27.2,
    weight: 1.5,
  },
  {
    id: 16,
    type: "Gate",
    class: 800,
    nps: "1",
    a: 114,
    v: 104,
    ho: 182,
    k: 33.9,
    weight: 2.5,
  },
  {
    id: 17,
    type: "Gate",
    class: 800,
    nps: "1 1/4",
    a: 120.5,
    v: 113,
    ho: 216,
    k: 42.7,
    weight: 3.3,
  },
  {
    id: 18,
    type: "Gate",
    class: 800,
    nps: "1 1/2",
    a: 120.5,
    v: 131,
    ho: 228.5,
    k: 48.8,
    weight: 4.7,
  },
  {
    id: 19,
    type: "Gate",
    class: 800,
    nps: "2",
    a: 130,
    v: 146,
    ho: 259,
    k: 61.2,
    weight: 6.1,
  },
  {
    id: 20,
    type: "Gate",
    class: 800,
    nps: "1/4",
    a: 84,
    v: 87,
    ho: 134,
    k: 14.2,
    weight: 1.4,
  },
  {
    id: 21,
    type: "Gate",
    class: 800,
    nps: "3/8",
    a: 84,
    v: 87,
    ho: 134,
    k: 17.6,
    weight: 1.4,
  },
  {
    id: 22,
    type: "Gate",
    class: 800,
    nps: "1/2",
    a: 90.5,
    v: 87,
    ho: 143,
    k: 21.8,
    weight: 1.6,
  },
  {
    id: 23,
    type: "Gate",
    class: 800,
    nps: "3/4",
    a: 114,
    v: 104,
    ho: 182,
    k: 27.2,
    weight: 2.7,
  },
  {
    id: 24,
    type: "Gate",
    class: 800,
    nps: "1",
    a: 120.5,
    v: 113,
    ho: 216,
    k: 33.9,
    weight: 3.6,
  },
  {
    id: 25,
    type: "Gate",
    class: 800,
    nps: "1 1/4",
    a: 120.5,
    v: 131,
    ho: 228.5,
    k: 42.7,
    weight: 5,
  },
  {
    id: 26,
    type: "Gate",
    class: 800,
    nps: "1 1/2",
    a: 130,
    v: 146,
    ho: 259,
    k: 48.8,
    weight: 6.5,
  },
  {
    id: 27,
    type: "Gate",
    class: 800,
    nps: "2",
    a: 140,
    v: 172,
    ho: 325.5,
    k: 61.2,
    weight: 11.5,
  },
  {
    id: 28,
    type: "Globe",
    class: 800,
    nps: "1/2",
    a: 84,
    v: 74,
    ho: 150,
    k: 21.8,
    weight: 2,
  },
  {
    id: 29,
    type: "Globe",
    class: 800,
    nps: "3/4",
    a: 90.5,
    v: 74,
    ho: 162,
    k: 27.2,
    weight: 2,
  },
  {
    id: 30,
    type: "Globe",
    class: 800,
    nps: "1",
    a: 111,
    v: 81,
    ho: 197,
    k: 33.9,
    weight: 3.5,
  },
  {
    id: 31,
    type: "Globe",
    class: 800,
    nps: "1 1/4",
    a: 135.5,
    v: 95,
    ho: 227,
    k: 42.7,
    weight: 5.3,
  },
  {
    id: 32,
    type: "Globe",
    class: 800,
    nps: "1 1/2",
    a: 165,
    v: 95,
    ho: 249,
    k: 48.8,
    weight: 9.5,
  },
  {
    id: 33,
    type: "Globe",
    class: 800,
    nps: "2",
    a: 178,
    v: 143,
    ho: 281,
    k: 61.2,
    weight: 11.4,
  },
  {
    id: 34,
    type: "Globe",
    class: 800,
    nps: "1/4",
    a: 84,
    v: 74,
    ho: 150,
    k: 14.2,
    weight: 1.8,
  },
  {
    id: 35,
    type: "Globe",
    class: 800,
    nps: "3/8",
    a: 84,
    v: 74,
    ho: 150,
    k: 17.6,
    weight: 2,
  },
  {
    id: 36,
    type: "Globe",
    class: 800,
    nps: "1/2",
    a: 90.5,
    v: 74,
    ho: 162,
    k: 21.8,
    weight: 2,
  },
  {
    id: 37,
    type: "Globe",
    class: 800,
    nps: "3/4",
    a: 111,
    v: 81,
    ho: 197,
    k: 27.2,
    weight: 3.5,
  },
  {
    id: 38,
    type: "Globe",
    class: 800,
    nps: "1",
    a: 133.5,
    v: 95,
    ho: 227,
    k: 33.9,
    weight: 5.3,
  },
  {
    id: 39,
    type: "Globe",
    class: 800,
    nps: "1 1/4",
    a: 165,
    v: 95,
    ho: 249,
    k: 42.7,
    weight: 9.5,
  },
  {
    id: 40,
    type: "Globe",
    class: 800,
    nps: "1 1/2",
    a: 178,
    v: 143,
    ho: 281,
    k: 48.8,
    weight: 11.4,
  },
  {
    id: 41,
    type: "Globe",
    class: 800,
    nps: "2",
    a: 228.5,
    v: 190,
    ho: 328.5,
    k: 61.2,
    weight: 17.7,
  },
  {
    id: 42,
    type: "Globe",
    class: 800,
    nps: "1/2",
    a: 84,
    v: 74,
    ho: 150,
    k: 21.8,
    weight: 2,
  },
  {
    id: 43,
    type: "Globe",
    class: 800,
    nps: "3/4",
    a: 90.5,
    v: 74,
    ho: 162,
    k: 27.2,
    weight: 2,
  },
  {
    id: 44,
    type: "Globe",
    class: 800,
    nps: "1",
    a: 111,
    v: 81,
    ho: 197,
    k: 33.9,
    weight: 3.5,
  },
  {
    id: 45,
    type: "Globe",
    class: 800,
    nps: "1 1/4",
    a: 133.5,
    v: 95,
    ho: 227,
    k: 42.7,
    weight: 5.3,
  },
  {
    id: 46,
    type: "Globe",
    class: 800,
    nps: "1 1/2",
    a: 165,
    v: 95,
    ho: 249,
    k: 48.8,
    weight: 9.5,
  },
  {
    id: 47,
    type: "Globe",
    class: 800,
    nps: "2",
    a: 178,
    v: 143,
    ho: 281,
    k: 61.2,
    weight: 11.4,
  },
  {
    id: 48,
    type: "Globe",
    class: 800,
    nps: "1/4",
    a: 84,
    v: 74,
    ho: 150,
    k: 14.2,
    weight: 1.6,
  },
  {
    id: 49,
    type: "Globe",
    class: 800,
    nps: "3/8",
    a: 84,
    v: 74,
    ho: 150,
    k: 17.6,
    weight: 1.8,
  },
  {
    id: 50,
    type: "Globe",
    class: 800,
    nps: "1/2",
    a: 90.5,
    v: 74,
    ho: 162,
    k: 21.8,
    weight: 1.8,
  },
  {
    id: 51,
    type: "Globe",
    class: 800,
    nps: "3/4",
    a: 111,
    v: 81,
    ho: 197,
    k: 27.2,
    weight: 3.3,
  },
  {
    id: 52,
    type: "Globe",
    class: 800,
    nps: "1",
    a: 133.5,
    v: 95,
    ho: 227,
    k: 33.9,
    weight: 5,
  },
  {
    id: 53,
    type: "Globe",
    class: 800,
    nps: "1 1/4",
    a: 165,
    v: 95,
    ho: 249,
    k: 42.7,
    weight: 9.2,
  },
  {
    id: 54,
    type: "Globe",
    class: 800,
    nps: "1 1/2",
    a: 178,
    v: 143,
    ho: 281,
    k: 48.8,
    weight: 11,
  },
  {
    id: 55,
    type: "Globe",
    class: 800,
    nps: "2",
    a: 228.5,
    v: 190,
    ho: 329,
    k: 61.2,
    weight: 17,
  },
  {
    id: 56,
    type: "Globe",
    class: 3500,
    nps: "1/4",
    a: 49,
    v: 70,
    ho: 86,
    k: 14.2,
    weight: 0.8,
  },
  {
    id: 57,
    type: "Globe",
    class: 3500,
    nps: "3/8",
    a: 49,
    v: 70,
    ho: 86,
    k: 17.6,
    weight: 0.8,
  },
  {
    id: 58,
    type: "Globe",
    class: 3500,
    nps: "1/2",
    a: 57,
    v: 70,
    ho: 94.5,
    k: 21.8,
    weight: 1,
  },
  {
    id: 59,
    type: "Globe",
    class: 3500,
    nps: "3/4",
    a: 75,
    v: 91,
    ho: 156,
    k: 27.2,
    weight: 1.4,
  },
  {
    id: 60,
    type: "Globe",
    class: 3500,
    nps: "1",
    a: 88,
    v: 91,
    ho: 166,
    k: 33.9,
    weight: 2,
  },
  {
    id: 61,
    type: "Check",
    class: 800,
    nps: "1/2",
    a: 84,
    ho: 47,
    k: 21.8,
    weight: 1.2,
  },
  {
    id: 62,
    type: "Check",
    class: 800,
    nps: "3/4",
    a: 90.5,
    ho: 53,
    k: 27.2,
    weight: 1.4,
  },
  {
    id: 63,
    type: "Check",
    class: 800,
    nps: "1",
    a: 111,
    ho: 69,
    k: 33.9,
    weight: 2.5,
  },
  {
    id: 64,
    type: "Check",
    class: 800,
    nps: "1 1/4",
    a: 135.5,
    ho: 85,
    k: 42.7,
    weight: 3.8,
  },
  {
    id: 65,
    type: "Check",
    class: 800,
    nps: "1 1/2",
    a: 165,
    ho: 105,
    k: 48.8,
    weight: 6.3,
  },
  {
    id: 66,
    type: "Check",
    class: 800,
    nps: "2",
    a: 178,
    ho: 131,
    k: 61.2,
    weight: 9.1,
  },
];
