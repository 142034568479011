import React, { useState, useEffect } from "react";
import { CustomDlg } from "../../common/CustomDlg";
import { Button } from "@blueprintjs/core";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addEventAction } from "../../../store/ui/actions";
import { ApplicationState } from "../../../store";
import { secondServerAPI } from "../../../pages/utils/agent";
import "./assetUploaddlg.css";

type Props = {
    onClose: () => any;
    items: any[];
    onChange?: (items: any[]) => any; 
    stype: string;
};

type Project = {
    projectId: number;
    projectName: string;
    projectPhase: string;
};

export function UploadAsset(props: Props) {
    const { items, onChange, onClose, stype } = props;
    const [editableData, setEditableData] = useState<Record<string, any>>({});
    const [equipmentName, setEquipmentName] = useState("");
    const [projectName, setProjectName] = useState("");
    const [projectId, setProjectId] = useState<number>();
    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [type, setType] = useState(editableData.Type || "none");

    const auth = useSelector((state: ApplicationState) => state.auth);
    const dispatch = useDispatch();

    const handleUserDataChange = (key: string, value: any) => {
        setEditableData((prevData) => {
            const newUserData = { ...prevData, [key]: value };
            if (key === "Type") {
                setType(value); 
            }
            return newUserData;
        });
        console.log("this is the editable data", editableData)
    };

    const handleUserDataBlur = (key: string) => {
        if (editableData && onChange) {
            onChange(editableData);
        }
    };
    

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await Axios.get(`${secondServerAPI}rest/api/v1/ufc/dashboard/projects`, {
                    headers: {
                        "user-id": auth.User_id,
                        // "user-id" : 1
                    },
                });

                if (response.status === 200) {
                    const projectsData = response.data.map((proj: any) => ({
                        projectId: proj.projectId,
                        projectName: proj.projectName,
                        projectPhase: proj.projectPhase,
                    }));

                    setProjects(projectsData);

                    if (projectsData.length > 0) {
                        setProjectName(projectsData[0].projectName);
                        setProjectId(projectsData[0].projectId);
                    }
                }
            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };

        fetchProjects();
    }, [auth.User_id]);

    useEffect(() => {
        const selectedProject = projects.find((project) => project.projectName === projectName);
        if (selectedProject) {
            setProjectId(selectedProject.projectId);
        }
    }, [projectName, projects]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    // const handleSaveLayout = async () => {
    //     if (!selectedFile) {
    //         dispatch(addEventAction("No file selected", "danger"));
    //         return;
    //     }
    //     const formData = new FormData();
    //     formData.append("file", selectedFile);

    //     try {
    //         const response = await Axios.post(
    //             `${secondServerAPI}/zuul/rest/api/v1/udc/upload/equipmentfile`,
    //             formData,
    //             {
    //                 headers: {
    //                     "user-id": auth.User_id,
    //                     "file-name": equipmentName,
    //                     "project-id": projectId,
    //                     "Content-Type": "multipart/form-data",
    //                 },
    //             }
    //         );
    //         if (response.status === 200) {
    //             dispatch(addEventAction("Equipment saved successfully", "success"));
    //         }
    //     } catch (error: any) {
    //         dispatch(addEventAction(`${error} Equipment could not be saved`, "danger"));
    //     }
    // };


    const handleSaveLayout = async () => {
        if (!selectedFile) {
            dispatch(addEventAction("No file selected", "danger"));
            return;
        }
        const formData = new FormData();
        formData.append("file", selectedFile);
    
        try {
            const extraHeaders: Record<string, string> = {
                "user-id": auth.User_id,
                "file-name": equipmentName,
                "project-id": String(projectId),
                "Content-Type": "multipart/form-data",
                "Type": type, 
                "name": "", 
                "clipped": "", 
                "texture":  "",
                "height": "",
                "width": "", 
                "length": "",
                "density": "", 
                "thickness": "", 
                "weight": ""
            };
    
            Object.keys(editableData).forEach((key) => {
                if (editableData[key] && key !== "Type") { 
                    extraHeaders[key] = editableData[key];
                }
            });
    
            const response = await Axios.post(
                `${secondServerAPI}/zuul/rest/api/v1/udc/upload/equipmentfile`,
                formData,
                { headers: extraHeaders } 
            );
    
            if (response.status === 200) {
                dispatch(addEventAction("Equipment saved successfully", "success"));
            }
        } catch (error: any) {
            dispatch(addEventAction(`${error} Equipment could not be saved`, "danger"));
        }
    };
    

    const renderFieldsBasedOnType = () => {
        switch (type) {
          case "wall":
            return (
              <>
                <li key="Height">
                  <strong>Height:</strong>
                  <input
                    className="bp3-input bp3-large"
                    type="text"
                    placeholder="Wall Height..."
                    value={editableData.Height || ""}
                    onChange={(e) => handleUserDataChange("Height", e.target.value)}
                    onBlur={() => handleUserDataBlur("Height")}
                  />
                </li>
                <li key="Width">
                  <strong>Width:</strong>
                  <input
                    className="bp3-input bp3-large"
                    type="text"
                    placeholder="Wall Width..."
                    value={editableData.Width || ""}
                    onChange={(e) => handleUserDataChange("Width", e.target.value)}
                    onBlur={() => handleUserDataBlur("Width")}
                  />
                </li>
                <li key="Length">
                  <strong>Length:</strong>
                  <input
                    type="text"
                    className="bp3-input bp3-large"
                    placeholder="Wall Length..."
                    value={editableData.Length || ""}
                    onChange={(e) => handleUserDataChange("Length", e.target.value)}
                    onBlur={() => handleUserDataBlur("Length")}
                  />
                </li>
                <li key="Density">
                  <strong>Density:</strong>
                  <input
                    type="text"
                    className="bp3-input bp3-large"
                    placeholder="Wall Density..."
                    value={editableData.Density || ""}
                    onChange={(e) => handleUserDataChange("Density", e.target.value)}
                    onBlur={() => handleUserDataBlur("Density")}
                  />
                </li>
                <li key="Weight">
                  <strong>Weight:</strong>
                  <input
                    type="text"
                    className="bp3-input bp3-large"
                    placeholder="Wall Weight..."
                    value={editableData.Weight || ""}
                    onChange={(e) => handleUserDataChange("Weight", e.target.value)}
                    onBlur={() => handleUserDataBlur("Weight")}
                  />
                </li>
              </>
            );
          case "slab":
            return (
              <>
                <li key="Thickness">
                  <strong>Thickness:</strong>
                  <input
                    type="text"
                    placeholder="Slab Thickness..."
                    className="bp3-input bp3-large"
                    value={editableData.Thickness || ""}
                    onChange={(e) => handleUserDataChange("Thickness", e.target.value)}
                    onBlur={() => handleUserDataBlur("Thickness")}
                  />
                </li>
                <li key="Width">
                  <strong>Width:</strong>
                  <input
                    type="text"
                    placeholder="Slab Width..."
                    className="bp3-input bp3-large"
                    value={editableData.Width || ""}
                    onChange={(e) => handleUserDataChange("Width", e.target.value)}
                    onBlur={() => handleUserDataBlur("Width")}
                  />
                </li>
                <li key="Length">
                  <strong>Length:</strong>
                  <input
                    type="text"
                    className="bp3-input bp3-large"
                    placeholder="Slab Length..."
                    value={editableData.Length || ""}
                    onChange={(e) => handleUserDataChange("Length", e.target.value)}
                    onBlur={() => handleUserDataBlur("Length")}
                  />
                </li>
                <li key="Density">
                  <strong>Density:</strong>
                  <input
                    type="text"
                    className="bp3-input bp3-large"
                    placeholder="Slab Density..."
                    value={editableData.Density || ""}
                    onChange={(e) => handleUserDataChange("Density", e.target.value)}
                    onBlur={() => handleUserDataBlur("Density")}
                  />
                </li>
                <li key="Weight">
                  <strong>Weight:</strong>
                  <input
                    type="text"
                    placeholder="Slab Weight..."
                    className="bp3-input bp3-large"
                    value={editableData.Weight || ""}
                    onChange={(e) => handleUserDataChange("Weight", e.target.value)}
                    onBlur={() => handleUserDataBlur("Weight")}
                  />
                </li>
              </>
            );
          case "door":
            return (
              <>
                <li key="Width">
                  <strong>Width:</strong>
                  <input
                    type="text"
                    placeholder="Door Width..."
                    className="bp3-input bp3-large"
                    value={editableData.Width || ""}
                    onChange={(e) => handleUserDataChange("Width", e.target.value)}
                    onBlur={() => handleUserDataBlur("Width")}
                  />
                </li>
                <li key="Height">
                  <strong>Height:</strong>
                  <input
                    type="text"
                    placeholder="Door Height..."
                    className="bp3-input bp3-large"
                    value={editableData.Height || ""}
                    onChange={(e) => handleUserDataChange("Height", e.target.value)}
                    onBlur={() => handleUserDataBlur("Height")}
                  />
                </li>
              </>
            );
          case "window":
            return (
              <>
                <li key="Height">
                  <strong>Height:</strong>
                  <input
                    type="text"
                    placeholder="Window Height..."
                    className="bp3-input bp3-large"
                    value={editableData.Height || ""}
                    onChange={(e) => handleUserDataChange("Height", e.target.value)}
                    onBlur={() => handleUserDataBlur("Height")}
                  />
                </li>
                <li key="Length">
                  <strong>Length:</strong>
                  <input
                    type="text"
                    placeholder="Window Length..."
                    className="bp3-input bp3-large"
                    value={editableData.Length || ""}
                    onChange={(e) => handleUserDataChange("Length", e.target.value)}
                    onBlur={() => handleUserDataBlur("Length")}
                  />
                </li>
              </>
            );
          case "equipment":
            return(
              <>
                <li key="Height">
                  <strong>Height:</strong>
                  <input
                    type="text"
                    placeholder="Equipment Height..."
                    className="bp3-input bp3-large"
                    value={editableData.Height || ""}
                    onChange={(e) => handleUserDataChange("Height", e.target.value)}
                    onBlur={() => handleUserDataBlur("Height")}
                  />
                </li>
                <li key="Length">
                  <strong>Length:</strong>
                  <input
                    type="text"
                    placeholder="Equipment Length..."
                    className="bp3-input bp3-large"
                    value={editableData.Length || ""}
                    onChange={(e) => handleUserDataChange("Length", e.target.value)}
                    onBlur={() => handleUserDataBlur("Length")}
                  />
                </li>
                <li key="Width">
                  <strong>Width:</strong>
                  <input
                    type="text"
                    placeholder="Equipment Width..."
                    className="bp3-input bp3-large"
                    value={editableData.Width || ""}
                    onChange={(e) => handleUserDataChange("Width", e.target.value)}
                    onBlur={() => handleUserDataBlur("Width")}
                  />
                </li>
                <li key="Weight">
                  <strong>Weight:</strong>
                  <input
                    type="text"
                    placeholder="Equipment Weight..."
                    className="bp3-input bp3-large"
                    value={editableData.Weight || ""}
                    onChange={(e) => handleUserDataChange("Weight", e.target.value)}
                    onBlur={() => handleUserDataBlur("Weight")}
                  />
                </li>
              </>
            )
          default:
            return null;
        }
      };

    return (
        <>
            <CustomDlg 
                title={"Upload Asset"} 
                idText="upload-asset" 
                body={
                    <div className="file-data-panel">
                        <div style={{ margin: 5, padding: 15, backgroundColor: "black" }}>
                            <div className="d-flex f-column">
                                <li>
                                    <strong>Name:</strong>
                                    <input
                                        className="bp3-input bp3-large"
                                        type="text"
                                        placeholder="Equipment Name..."
                                        style={{ margin: 5 }}
                                        value={equipmentName}
                                        onChange={(e) => setEquipmentName(e.target.value)}
                                    />
                                </li>
                                <li>
                                    <strong>Project Available:</strong>
                                    <select
                                        className="bp3-select w-100"
                                        value={projectName}
                                        onChange={(e) => setProjectName(e.target.value)}
                                    >
                                        {projects.map((project) => (
                                            <option key={project.projectId} value={project.projectName}>
                                                {project.projectName}
                                            </option>
                                        ))}
                                    </select>
                                </li>
                                <li>
                                    <strong>Select Asset:</strong>
                                    <input
                                        className="bp3-input bp3-large"
                                        type="file"
                                        accept=".idsv"
                                        style={{ margin: 5 }}
                                        onChange={handleFileChange}
                                    />
                                </li>
                                
                                <li key="Type">
                                    <strong>Type:</strong>
                                    <select
                                        value={type}
                                        onChange={(e) => handleUserDataChange("Type", e.target.value)}
                                        onBlur={() => handleUserDataBlur("Type")}
                                    >
                                        <option value="none">None Selected</option>
                                        <option value="wall">Wall</option>
                                        <option value="slab">Slab</option>
                                        <option value="door">Door</option>
                                        <option value="window">Window</option>
                                        <option value="equipment">Equipment</option>
                                    </select>
                                    </li>
                                    {renderFieldsBasedOnType()}
                            </div>
                            <Button
                                className='bp3-intent-success bp3-large bp3-icon-cloud-upload table-area-button'
                                text="Upload Asset"
                                onClick={handleSaveLayout}
                                style={{ margin: 5 }}
                            />
                        </div>
                    </div>
                }
                onClose={onClose}
            />
        </>
    );
}
