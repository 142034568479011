import React, { useState, useMemo, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Icon } from '@blueprintjs/core';
import { ApplicationState } from '../store';
import {Link} from "react-router-dom";
import { DataState } from '../store/data/types';
import { getCurrentProject,getCurrentUI } from '../components/3d-models/utils';
import { Button,Position } from '@blueprintjs/core';
import logo from "../assets/A-Logo-White-Transparent.png"
import "./AdminDashboard/adminDashboard.css"
import "./AdminDashboard/Catalog.css"

enum EDataTypes {
    Profile_Section = "Profile_section",
    Piping_CS = "Piping_CS",
    Piping_SS = "Piping_SS",
    Piping_caps = "Piping_caps",
    Piping_collets = "Piping_collets",
    Piping_elbows = "Piping_elbows",
    Piping_returns = "Piping_returns",
    Piping_reducers = "Piping_reducers",
    Piping_tees = "Piping_tees",
    Piping_flanges= "Piping_flanges",
    Piping_blind_flanges= "Piping_blind_flanges",
    Piping_long_welding_neck_flanges = "Piping_long_welding_neck_flanges",
    Piping_orifice_slipon_flanges = "Piping_orifice_slipon_flanges",
    Piping_threaded_flanges = "Piping_threaded_flanges",
    PIPING_LONG_WELDING_NECK_FLANGES = "PIPING_LONG_WELDING_NECK_FL",
    Piping_ring_joint_facing_flanges = "PIPING_ring_joint_facing_flanges",
    Piping_socket_welding_flanges = "PIPING_socket_welding_flanges",
    PIPING_VALVE_TYPES  = "PIPING_VALVE_TYPES",
    PIPING_VALVE_ACTUATORS = "PIPING_VALVE_ACTUATORS",
    PIPING_VALVE_CONTROLS = "Piping_VALVE_CONTROLS",
}


const dataTypes: EDataTypes[] = [
    EDataTypes.Profile_Section,
    EDataTypes.Piping_CS,
    EDataTypes.Piping_SS,
    EDataTypes.Piping_caps,
    EDataTypes.Piping_collets,
    EDataTypes.Piping_elbows,
    EDataTypes.Piping_returns,
    EDataTypes.Piping_reducers,
    EDataTypes.Piping_tees,
    EDataTypes.Piping_flanges,
    EDataTypes.Piping_blind_flanges,
    EDataTypes.PIPING_LONG_WELDING_NECK_FLANGES,
    EDataTypes.Piping_threaded_flanges,
    EDataTypes.Piping_ring_joint_facing_flanges,
    EDataTypes.Piping_socket_welding_flanges,
    EDataTypes.PIPING_VALVE_TYPES,
    EDataTypes.PIPING_VALVE_ACTUATORS,
    EDataTypes.PIPING_VALVE_CONTROLS,
];

const initData: DataState = {
    CS_Libraries: [],
    materials: [],
    pipingCS: [],
    pipingSS: [],
    pipingCaps: [],
    pipingCollets: [],
    pipingElbows: [],
    pipingFlangesAllPresRating: [],
    pipingFlangesBlind: [],
    pipingFlangesLapped: [],
    pipingFlangesRingJointFacing: [],
    pipingFlangesSlipon: [],
    pipingFlangesSocketWelding: [],
    pipingFlangesThreaded: [],
    pipingFlangesWeldingneck: [],
    pipingLongWeldingNeckFlanges: [],
    pipingReducers: [],
    pipingReturns: [],
    pipingTees: [],
    pipingValves: [],
    profileSectionData: [],
    pipingValveActuators: [],
    pipingValveControls: [],
  };

const isAvailable = "is available";

const profilesKeys = [
    "profile_section_id",
    isAvailable,
    "name",
    "type",
    "designation",
    "shape",
    "country_code",
    "ax",
    "ax_global",
    "b",
    "b_global",
    "bf",
    "bf_global",
    "c",
    "c_global",
    "ct",
    "ct_global",
    "d",
    "d_global",
    "de",
    "de_global",
    "i",
    "i_global",
    "ix",
    "ix_global",
    "iy",
    "iy_global",
    "iz",
    "iz_global",
    "k",
    "k_global",
    "k1",
    "k1_global",
    "od",
    "od_global",
    "r1",
    "r1_global",
    "r2",
    "r2_global",
    "rz",
    "rz_global",
    "t",
    "t_global",
    "tf",
    "tf_global",
    "tw",
    "tw_global",
    "z",
    "z_global",
    "zx",
    "zx_global",
    "zy",
    "zy_global",
    "zz",
    "zz_global",
    "width",
    "width_global",
    "thickness",
    "thickness_global",
    "height",
    "height_global",
  ];
  
const pipingKeys = [
    "piping_details_id",
    isAvailable,
    "nominal_pipe_size_inch",
    "schedule",
    "outside_diameter",
    "wall_thickness",
    "weight",
    "outside_diameter_global",
    "wall_thickness_global",
    "weight_global",
    "specification",
    "ixx",
    "material",
    "country_code",
    "type",
  ];
  
const pipingCapKeys = [
    "id",
    isAvailable,
    "nps",
    "material",
    "shape",
    "d",
    "e",
    "limiting_wt",
    "e1",
    "std",
    "xs",
    "xxs",
    "sch_10",
    "sch_20",
    "sch_30",
    "sch_40",
    "sch_60",
    "sch_80",
    "sch_100",
    "sch_120",
    "sch_140",
    "sch_160",
    "sch_5s",
    "sch_10s",
    "sch_40s",
    "sch_80s",
    "schedule",
    "t",
    "weight",
  ];
  
  const pipingCollets = [
    "id",
    isAvailable,
    "nps",
    "material",
    "shape",
    "d",
    "f1",
    "f2",
    "g",
    "a",
    "b",
    "sch_5s",
    "sch_10s",
    "sch_40s",
    "schedule",
    "t",
    "weight",
  ];
  
  const pipingElbowKeys = [
    "id",
    isAvailable,
    "nps",
    "material",
    "shape",
    "degree",
    "d",
    "a",
    "std",
    "xs",
    "xxs",
    "sch_10",
    "sch_20",
    "sch_30",
    "sch_40",
    "sch_60",
    "sch_80",
    "sch_100",
    "sch_120",
    "sch_140",
    "sch_160",
    "sch_5s",
    "sch_10s",
    "sch_40s",
    "sch_80s",
    "schedule",
    "t",
    "weight",
  ];
  
  const pipingReturnKeys = [
    "id",
    isAvailable,
    "nps",
    "material",
    "shape",
    "degree",
    "d",
    "o",
    "k",
    "std",
    "xs",
    "xxs",
    "sch_10",
    "sch_20",
    "sch_30",
    "sch_40",
    "sch_60",
    "sch_80",
    "sch_100",
    "sch_120",
    "sch_140",
    "sch_160",
    "sch_5s",
    "sch_10s",
    "sch_40s",
    "sch_80s",
    "schedule",
    "t",
    "weigth",
  ];
  
  const pipingReducerKeys = [
    "id",
    isAvailable,
    "nps",
    "material",
    "shape",
    "d1",
    "d2",
    "h",
    "std",
    "xs",
    "xxs",
    "sch_10",
    "sch_20",
    "sch_30",
    "sch_40",
    "sch_60",
    "sch_80",
    "sch_100",
    "sch_120",
    "sch_140",
    "sch_160",
    "sch_5s",
    "sch_10s",
    "sch_40s",
    "sch_80s",
    "schedule",
    "t1",
    "t2",
    "weight",
  ];
  
  const pipingTeeKeys = [
    "id",
    isAvailable,
    "nps",
    "material",
    "shape",
    "d",
    "d1",
    "d2",
    "c",
    "m",
    "std",
    "xs",
    "xxs",
    "sch_10",
    "sch_20",
    "sch_30",
    "sch_40",
    "sch_60",
    "sch_80",
    "sch_100",
    "sch_120",
    "sch_140",
    "sch_160",
    "sch_5s",
    "sch_10s",
    "sch_40s",
    "sch_80s",
    "schedule",
    "t",
    "t1",
    "t2",
    "weight",
  ];
  
  const pipingFlangeKeys = [
    "piping_flange_id",
    isAvailable,
    "dn",
    "nps",
    "material",
    "shape",
    "r",
    "s",
    "t",
    "u",
    "k",
    "l",
    "w",
    "x",
    "y",
    "z",
  ];
  
  const pipingBlindFlangeKeys = [
    "piping_flange_id",
    isAvailable,
    "dn",
    "nps",
    "material",
    "shape",
    "class",
    "o",
    "dr_no",
    "dr_d",
    "dr_g",
    "k",
    "e",
    "c",
    "r",
    "r_mini",
    "x",
    "y",
    "a",
    "b",
    "weight",
  ];
  
  const pipingThreadedFlangeKeys = [
    "piping_flange_id",
    isAvailable,
    "dn",
    "nps",
    "material",
    "shape",
    "class",
    "o",
    "dr_no",
    "dr_d",
    "dr_g",
    "c_mini",
    "y",
    "r",
    "x",
    "tt",
    "qb",
    "qf",
    "f",
    "weight",
  ];
  
  const pipingWeldNeckFlangeKeys = [
    "piping_flange_id",
    isAvailable,
    "dn",
    "nps",
    "material",
    "shape",
    "class",
    "o",
    "dr_no",
    "dr_d",
    "dr_g",
    "a",
    "tt",
    "c_mini",
    "y",
    "x",
    "r",
    "weight",
  ];
  
  const pipingSliponFlangeKeys = [
    "piping_flange_id",
    isAvailable,
    "dn",
    "nps",
    "material",
    "shape",
    "class",
    "o",
    "dr_no",
    "dr_d",
    "dr_g",
    "b_mini",
    "tt",
    "c_mini",
    "y",
    "x",
    "r",
    "weight",
  ];
  
  const pipingSocketWeldingFlangeKeys = [
    "piping_flange_id",
    isAvailable,
    "dn",
    "nps",
    "material",
    "shape",
    "class",
    "o",
    "dr_no",
    "dr_d",
    "dr_g",
    "k",
    "e",
    "c",
    "r",
    "r_mini",
    "x",
    "y",
    "a",
    "b",
    "weight",
    "b3",
    "d",
  ];
  
  const pipingLongWeldingNeckFlangeKeys = [
    "piping_flange_id",
    isAvailable,
    "dn",
    "nps",
    "material",
    "shape",
    "class",
    "o",
    "dr_no",
    "dr_d",
    "dr_g",
    "n",
    "a",
    "b",
    "c_mini",
    "r",
  ];
  
  const pipingRingJointFacingFlangeKeys = [
    "piping_flange_id",
    isAvailable,
    "dn",
    "nps",
    "material",
    "shape",
    "r",
    "s",
    "t",
    "u",
    "k",
    "l",
    "w",
    "x",
    "y",
    "z",
  ];

  const pipingValveKeys = [
    "id",
    isAvailable,
    "type",
    "mass (kg)",
    "length (mm)",
    "SIF (unitless)",
  ];
  
  const pipingValveActuatorKeys = ["id", isAvailable, "actuator"];
  
  const pipingValveControlKeys = ["id", isAvailable, "control"];

  const items_per_page = 20;


export default function CatalogueComponent() {

    const [dataType, setDataType] = useState<EDataTypes>(dataTypes[0]);
    const [tableHeaders, setTableHeaders] = useState<string[]>([]);
    const [filtered, setFiltered] = useState<any[]>([]);
    const [currentpage, setCurrentPage] = useState<number>(0);
    const [countries, setCountries] = useState<string[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<string | undefined>();
    const [types, setTypes] = useState<string[]>([]);
    const [selectedType, setSelectedType] = useState<string | undefined>();
    const [shapes,setShapes] = useState<string[]>([]);
    const [selectedShape, setSelectedShape] = useState<string | undefined>();

    const dispatch = useDispatch();
    const project = useSelector((state:ApplicationState) => getCurrentProject(state));
    const dataState = useSelector((state : ApplicationState) => state.data)
    const ui = useSelector((state:ApplicationState) => getCurrentUI(state));

    useEffect(()=>{
        console.log("ui state", ui)
    })

    function getDataFieldByType(type: EDataTypes) {
        switch (type) {
          case EDataTypes.Profile_Section:
            return "profileSectionData";
          case EDataTypes.Piping_CS:
            return "pipingCS";
          case EDataTypes.Piping_SS:
            return "pipingSS";
          case EDataTypes.Piping_caps:
            return "pipingCaps";
          case EDataTypes.Piping_collets:
            return "pipingCollets";
          case EDataTypes.Piping_elbows:
            return "pipingElbows";
          case EDataTypes.Piping_returns:
            return "pipingReturns";
          case EDataTypes.Piping_reducers:
            return "pipingReducers";
          case EDataTypes.Piping_tees:
            return "pipingTees";
          case EDataTypes.Piping_flanges:
            return "pipingFlangesAllPresRating";
          case EDataTypes.Piping_blind_flanges:
            return "pipingFlangesBlind";
          case EDataTypes.Piping_threaded_flanges:
            return "pipingFlangesThreaded";
          case EDataTypes.PIPING_LONG_WELDING_NECK_FLANGES:
            return "pipingFlangesWeldingneck";
          case EDataTypes.Piping_orifice_slipon_flanges:
            return "pipingFlangesSlipon";
          case EDataTypes.Piping_socket_welding_flanges:
            return "pipingFlangesSocketWelding";
        //   case EDataTypes.PIPING_LONG_WELDING_NECK_FLANGES:
        //     return "pipingLongWeldingNeckFlanges";
          case EDataTypes.Piping_ring_joint_facing_flanges:
            return "pipingFlangesRingJointFacing";
          case EDataTypes.PIPING_VALVE_TYPES:
            return "pipingValves";
          case EDataTypes.PIPING_VALVE_ACTUATORS:
            return "pipingValveActuators";
          case EDataTypes.PIPING_VALVE_CONTROLS:
            return "pipingValveControls";
          default:
            return undefined;
        }
      }
      
    function getDataByType(type: EDataTypes, state: DataState): any[] {
        switch (type) {
          case EDataTypes.Profile_Section:
            return dataState.profileSectionData;
          case EDataTypes.Piping_CS:
            return dataState.pipingCS;
          case EDataTypes.Piping_SS:
            return dataState.pipingSS;
          case EDataTypes.Piping_caps:
            return dataState.pipingCaps;
          case EDataTypes.Piping_collets:
            return dataState.pipingCollets;
          case EDataTypes.Piping_elbows:
            return dataState.pipingElbows;
          case EDataTypes.Piping_returns:
            return dataState.pipingReturns;
          case EDataTypes.Piping_reducers:
            return dataState.pipingReducers;
          case EDataTypes.Piping_tees:
            return dataState.pipingTees;
          case EDataTypes.Piping_flanges:
            return dataState.pipingFlangesAllPresRating;
          case EDataTypes.Piping_blind_flanges:
            return dataState.pipingFlangesBlind;
          case EDataTypes.Piping_threaded_flanges:
            return dataState.pipingFlangesThreaded;
          case EDataTypes.PIPING_LONG_WELDING_NECK_FLANGES:
            return dataState.pipingFlangesWeldingneck;
          case EDataTypes.Piping_orifice_slipon_flanges:
            return dataState.pipingFlangesSlipon;
          case EDataTypes.Piping_socket_welding_flanges:
            return dataState.pipingFlangesSocketWelding;
        //   case EDataTypes.PIPING_LONG_WELDING_NECK_FLANGES:
        //     return state.pipingLongWeldingNeckFlanges;
          case EDataTypes.Piping_ring_joint_facing_flanges:
            return dataState.pipingFlangesRingJointFacing;
          case EDataTypes.PIPING_VALVE_TYPES:
            return dataState.pipingValves;
          case EDataTypes.PIPING_VALVE_ACTUATORS:
            return dataState.pipingValveActuators;
          case EDataTypes.PIPING_VALVE_CONTROLS:
            return dataState.pipingValveControls;
          default:
            return [];
        }
     }
      
    function getHeaderColumnsOfDataType(type: EDataTypes): string[] {
        switch (type) {
          case EDataTypes.Profile_Section:
            return profilesKeys;
          case EDataTypes.Piping_CS:
            return pipingKeys;
          case EDataTypes.Piping_SS:
            return pipingKeys;
          case EDataTypes.Piping_caps:
            return pipingCapKeys;
          case EDataTypes.Piping_collets:
            return pipingCollets;
          case EDataTypes.Piping_elbows:
            return pipingElbowKeys;
          case EDataTypes.Piping_returns:
            return pipingReturnKeys;
          case EDataTypes.Piping_reducers:
            return pipingReducerKeys;
          case EDataTypes.Piping_tees:
            return pipingTeeKeys;
          case EDataTypes.Piping_flanges:
            return pipingFlangeKeys;
          case EDataTypes.Piping_blind_flanges:
            return pipingBlindFlangeKeys;
          case EDataTypes.Piping_threaded_flanges:
            return pipingThreadedFlangeKeys;
          case EDataTypes.PIPING_LONG_WELDING_NECK_FLANGES:
            return pipingWeldNeckFlangeKeys;
          case EDataTypes.Piping_orifice_slipon_flanges:
            return pipingSliponFlangeKeys;
          case EDataTypes.Piping_socket_welding_flanges:
            return pipingSocketWeldingFlangeKeys;
        //   case EDataTypes.PIPING_LONG_WELDING_NECK_FLANGES:
        //     return pipingLongWeldingNeckFlangeKeys;
          case EDataTypes.Piping_ring_joint_facing_flanges:
            return pipingRingJointFacingFlangeKeys;
          case EDataTypes.PIPING_VALVE_TYPES:
            return pipingValveKeys;
          case EDataTypes.PIPING_VALVE_ACTUATORS:
            return pipingValveActuatorKeys;
          case EDataTypes.PIPING_VALVE_CONTROLS:
            return pipingValveControlKeys;
          default:
            return [];
        }
     }

    const availableDataState: DataState = useMemo(()=>{
        return ui?.availableData ?? initData;
    },[ui?.availableData]) 

    useEffect(() => {
      const headers = getHeaderColumnsOfDataType(dataType);
      setTableHeaders(headers);
      const fullData = getDataByType(dataType, availableDataState);
      const countryCodes = Array.from(new Set(fullData.map(item => item.country_code))).filter(Boolean);
      setCountries(countryCodes);
      const differentTypes = Array.from(new Set(fullData.map(item => item.type))).filter(Boolean);
      setTypes(differentTypes);
      const differentShapes = Array.from(new Set(fullData.map(item => item.shape))).filter(Boolean);
      setShapes(differentShapes);
      let filteredData = fullData;
      if (selectedCountry) {
          filteredData = filteredData.filter(item => item.country_code === selectedCountry);
      }
      if (selectedType) {
          filteredData = filteredData.filter(item => item.type === selectedType);
      }
      if (selectedShape) {
          filteredData = filteredData.filter(item => item.shape === selectedShape);
      }
      const startIndex = currentpage * items_per_page;
      const endIndex = startIndex + items_per_page;
      setFiltered(filteredData.slice(startIndex, endIndex));
  }, [dataType, currentpage, availableDataState, selectedCountry, selectedType, selectedShape]);

    const availableDataItems = useMemo(()=>{
        return getDataByType(dataType,availableDataState);
    },[dataType,availableDataState]);

    function handleDataTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setDataType(event.target.value as EDataTypes);
        setCurrentPage(0);
        setSelectedCountry(undefined);
        setSelectedType(undefined);
        setSelectedShape(undefined);
    }

    function handleCountryChange(event: React.ChangeEvent<HTMLSelectElement>) {
      setSelectedCountry(event.target.value);
      setCurrentPage(0);
    }

    function handleTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
      setSelectedType(event.target.value);
      setCurrentPage(0);
    }

    function handleShapeChange(event: React.ChangeEvent<HTMLSelectElement>) {
      setSelectedShape(event.target.value);
      setCurrentPage(0);
    }

    const totalPages = useMemo(() => {
        const fullData = getDataByType(dataType, availableDataState);
        return Math.ceil(fullData.length / items_per_page);
    }, [availableDataState, dataType]);
    
    const goToNextPage = () => {
        setCurrentPage((current) => Math.min(current + 1, totalPages - 1));
    };

    const goToPreviousPage = () => {
        setCurrentPage((current) => Math.max(current - 1, 0));
    };

    useEffect(()=>{
        console.log("Table Headers",tableHeaders);
    })

    return (
        <>
            <div className="catalogue">
                <nav className="bp3-navbar">
                    <div className="bp3-navbar-group bp3-align-left">
                        <img className="Company-Logo" src={logo} alt="Company Logo" />
                        <div className="bp3-navbar-heading">SETS</div>
                    </div>
                    <div className="bp3-navbar-group bp3-align-right">
                        <input className="bp3-input" placeholder="Search..." type="text" />
                        <Link to="/modes"><Icon icon="home" className="bp3-button bp3-minimal" /></Link>
                        <Icon icon="cog" className="bp3-button bp3-minimal" />
                    </div>
                </nav>
                <div className='table-area'>
                <text className='text'>Catalague</text>
                <div className='table-area-1'>
                  <text className='text'>Select Type</text>
                  <select className='selector-menu' onChange={handleDataTypeChange} value={dataType}>
                      {dataTypes.map(type => (
                          <option key={type} value={type}>{type}</option>
                      ))}
                  </select>
                  <text className='text'>Country Code</text>
                  <select className='selector-menu' onChange={handleCountryChange}>
                      {countries.map(country => (
                          <option key={country} value={country}>{country}</option>
                      ))}
                  </select>
                  <text className='text'>Type</text>
                  <select className='selector-menu' onChange={handleTypeChange} >
                      {types.map(type_1 => (
                          <option key={type_1} value={type_1}>{type_1}</option>
                      ))}
                  </select>
                  <text className='text'>Shape</text>
                  <select className='selector-menu' onChange={handleShapeChange}>
                      {shapes.map(shape => (
                          <option key={shape} value={shape}>{shape}</option>
                      ))}
                  </select>
                  <Button className='bp3-intent-success bp3-large bp3-icon-cloud-upload table-area-button' text="Download CSV"/>
                  <Button className='bp3-intent-primary bp3-large bp3-icon-cloud-download table-area-button' text="Upload CSV"/>
                  <Button className='bp3-intent-danger bp3-large bp3-icon-trash table-area-button' text="Delete Data"/>
                  <div className="table-wrapper">
                      <table className="my-custom-table">
                          <thead>
                              <tr>{tableHeaders.map(header => <th key={header}>{header}</th>)}</tr>
                          </thead>
                          <tbody>
                              {filtered.map((item, index) => (
                                  <tr key={index}>
                                      {tableHeaders.map(header => (
                                          <td key={header}>{item[header]}</td>
                                      ))}
                                  </tr>
                              ))}
                          </tbody>
                      </table>
                    </div>
                    <div className="pagination">
                          <Button className="bp3-button bp3-intent-primary bp3-icon-arrow-left table-area-button " onClick={goToPreviousPage} disabled={currentpage === 0}/>
                          <text className='text'>{currentpage}/{totalPages}</text>
                          <Button className="bp3-button bp3-intent-primary bp3-icon-arrow-right table-area-button" onClick={goToNextPage} disabled={currentpage >= totalPages - 1}  />
                    </div>
                  </div>
            </div>
            </div>
        </>
    );
}

