import React, { useEffect, useMemo,useState } from "react";
import { FormGroup, ProgressBar } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { LoadingAsPerCode, Project } from "../../../../../store/main/types";
import { SimpleSelector } from "../../../../common/SimpleSelector";
import {
  loadingAsPerCodes,
  baseUrl,
} from "../../../../../store/main/constants";
import {
  changeModel,
  changeProjectAction,
} from "../../../../../store/main/actions";
import axios from "axios";
import {
  addEventAction,
  changeOFUIAction,
  changeProjectRequestProgressAction,
} from "../../../../../store/ui/actions";
import { CustomDlg } from "../../../../common/CustomDlg";
import { getElementByName, getCurrentUI, fixVectorByOrientation } from "../../../../3d-models/utils";
import { filteredY, getJSONForDesignCodesAndParametersOF } from "../../../../3d-models/designCodeAndParametersOF";
import { getOFModels } from "../../../../3d-models/openFrame";
import { TOpenFrame } from "../../../../../store/main/openFrameTypes";
import { AdditionalLoadsOF } from "../AdditionalLoads";
import { ISParamsOF } from "./ISParams";
import { USParamsOF } from "./USParams";
import { ManualParamsOF } from "./ManualParams";
import { Group, Scene, Vector3 } from "three";
import { drawWindLoads } from "../../../../3d-models/openFrame";
import {filteredX} from "../../../../3d-models/designCodeAndParametersOF";
import { model } from "makerjs";


type Props = {
  onClose: () => any;
};

export function WindLoadOF({ onClose }: Props) {
  const scene = useSelector((state: ApplicationState) => state.main.scene);
  const windLoadGroups: Group[] = [];
  let windLoadPositions: any[] = [];
  const [ShowWindLoadArrows,setShowWindLoadArrows] = useState<boolean>(false);
  const [selectedDirection, setSelectedDirection] = useState<string>('dir90');
  const [windLoadData, setWindLoadData] = useState(null);


  const natFreqProgress = useSelector(
    (state: ApplicationState) => getCurrentUI(state)?.requests?.natfreq
  );

  const profilePressureProgress = useSelector(
    (state: ApplicationState) => getCurrentUI(state)?.requests?.profilePressure
  );

  const ui = useSelector((state: ApplicationState) => getCurrentUI(state));

  const project = useSelector((state: ApplicationState) =>
    getElementByName(state.main.projects, state.main.currentProject)
  );

  const dispatch = useDispatch();

  const openFrameUI = useMemo(() => ui?.openFrameUI, [ui]);

  const models = useMemo(() => {
    return getOFModels(project);
  }, [project]);

  const data = useMemo(() => {
    return openFrameUI?.loadingsUI.windLoadUI;
  }, [openFrameUI]);


  // useEffect(() => {
  //   if (!project) return;
  //   handleGettingDesignCodesAndParameters(models, project);
  //   dispatch(
  //     changeProjectAction({
  //       ...project,
  //       models: project.models.map((model) => ({
  //         ...model,
  //         structuralNaturalFrequency: undefined,
  //       })),
  //     })
  //   );
  // }, []);

  useEffect(() => {
    if (!project) return;
    handleGettingDesignCodesAndParameters(models, project, selectedDirection);
    dispatch(
      changeProjectAction({
        ...project,
        models: project.models.map((model) => ({
          ...model,
          structuralNaturalFrequency: undefined,
        })),
      })
    );
  }, []);
  

  useEffect(() => {
    if (ShowWindLoadArrows === false) {
      removeWindLoads(scene); 
    } 
  });

  useEffect(() => {
    if (models.some((model) => model.structuralNaturalFrequency)) {
      handleChangeData("tab", "NF");
    } else handleChangeData("tab", "AL");
  }, [models]);

  useEffect(() => {
    console.log("Selected Direction is ", selectedDirection)
  })

  // function handleGettingDesignCodesAndParameters(
  //   models: TOpenFrame[],
  //   project: Project
  // ) {
  //   if (!models.length) return;
  //   if (!ui || !openFrameUI || !data) return;
  //   dispatch(changeProjectRequestProgressAction(project.name, "natfreq"));
  //   if (models.length > 1) {
  //     const jsons = models.map((model) =>
  //       getJSONForDesignCodesAndParametersOF(
  //         openFrameUI,
  //         ui.designCodeAndParametersUI,
  //         scene,
  //         project,
  //         model,
  //         models
  //       )
  //     );
  //     jsons.forEach((json, i, arr) => {
  //       sendToNatFreq(project, json, models, arr.length === i + 1);
  //     });
  //   } else {
  //     const json = getJSONForDesignCodesAndParametersOF(
  //       openFrameUI,
  //       ui.designCodeAndParametersUI,
  //       scene,
  //       project,
  //       models[0],
  //       models
  //     );
  //     sendToNatFreq(project, json, models, true);
  //   }
  // }

  // function sendToNatFreq(
  //   project: Project,
  //   data: any,
  //   models: TOpenFrame[],
  //   isLast?: boolean
  // ) {
  //   axios
  //     .post(
  //       `${baseUrl}api/v2/openFrame/calculate/natfreq`,
  //       JSON.stringify(data),
  //       {
  //         headers: { "Content-Type": "application/json" },
  //       }
  //     )
  //     .then((response) => {
  //       const model = models.find((model) => model.name === data.openFrameName);
  //       model &&
  //         dispatch(
  //           changeModel({
  //             ...model,
  //             structuralNaturalFrequency:
  //               response.data.structuralNaturalFrequency,
  //           } as TOpenFrame)
  //         );
  //       isLast &&
  //         dispatch(
  //           changeProjectRequestProgressAction(project.name, "natfreq", false)
  //         );
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       // dispatch(
  //       //   addEventAction(
  //       //     `Natural frequency (${data.openFrameName}): ${err.message}`,
  //       //     "danger"
  //       //   )
  //       // );
  //       dispatch(
  //         addEventAction(
  //           `Natural Frequency Analysis Check${data.openFrameName ? ` (${data.openFrameName})` : ""}: ${err.message ? `${err.message}` : ""}\n${
  //             ["Please check the required inputs for natural frequency analysis, i.e.,",
  //              "- Support conditions",
  //              "- Member profile",
  //              "- Structural geometry",
  //              "- Material density",
  //              "- End connectors"].join("\n")
  //           }`,
  //           "danger"
  //         )
  //       );
        
  //       isLast &&
  //         dispatch(
  //           changeProjectRequestProgressAction(project.name, "natfreq", false)
  //         );
  //     });
  // }

  // async function handleGettingDesignCodesAndParameters(models: TOpenFrame[], project: Project) {
  //   if (!models.length || !ui || !openFrameUI || !data) return;
    
  //   dispatch(changeProjectRequestProgressAction(project.name, "natfreq"));
  
  //   const jsons = models.map((model) =>
  //     getJSONForDesignCodesAndParametersOF(
  //       openFrameUI,
  //       ui.designCodeAndParametersUI,
  //       scene,
  //       project,
  //       model,
  //       models
  //     )
  //   );
  //   for (const [index, json] of jsons.entries()) {
  //     const isLast = index === jsons.length - 1;
  //     await sendToNatFreq(project, json, models,selectedDirection, isLast);
  //   }
  // }

  async function handleGettingDesignCodesAndParameters(models: TOpenFrame[], project: Project, selectedDirection: string) {
    if (!models.length || !ui || !openFrameUI || !data) return;
  
    dispatch(changeProjectRequestProgressAction(project.name, "natfreq"));
  
    const jsons = models.map((model) =>
      getJSONForDesignCodesAndParametersOF(
        openFrameUI,
        ui.designCodeAndParametersUI,
        scene,
        project,
        model,
        models
      )
    );
    for (const [index, json] of jsons.entries()) {
      const isLast = index === jsons.length - 1;
      await sendToNatFreq(project, json, models, selectedDirection, isLast);
    }
  }
  


  function removeWindLoads(scene: THREE.Scene): void {
    console.log("Initial Scene: ", scene);
    if (!scene) {
        console.error("No scene object provided or scene is undefined");
        return;
    }
    const windLoads: THREE.Object3D[] = [];
    function collectWindLoads(object: THREE.Object3D): void {
        object.children.forEach(child => {
            if (child.name === "WIND-LOADS") {
                windLoads.push(child);
            }
            collectWindLoads(child);
        });
    }
    collectWindLoads(scene);
    windLoads.forEach(obj => {
        if (obj.parent) {
            // console.log("Removing: ", obj);
            obj.parent.remove(obj);
        }
    });

    console.log("Wind loads removed from the scene (if found)");
}

async function sendToNatFreq(project: Project, data: any, models: TOpenFrame[], selectedDir: string, isLast?: boolean) {
  try {
    const response = await axios.post(
      `${baseUrl}api/v2/openFrame/calculate/natfreq`,
      JSON.stringify(data),
      { headers: { "Content-Type": "application/json" } }
    );

    const model = models.find((model) => model.name === data.openFrameName);
    if (model) {
      dispatch(
        changeModel({
          ...model,
          structuralNaturalFrequency: response.data.structuralNaturalFrequency,
        } as TOpenFrame)
      );
    }
    const seenIndices = new Set();
    const uniqueArray = filteredX.filter(obj => {
      if (!seenIndices.has(obj.index)) {
        seenIndices.add(obj.index);
        return true;
      }
      return false;
    });

    const windDirectionData = data.windLoads[selectedDir];
    const windFacingMembers = windDirectionData.windFacingMembers;
    const angleDeg = parseInt(selectedDir.substring(3), 10);

    console.log('Selected Direction:', selectedDir);
    console.log('Parsed Angle (degrees):', angleDeg);

    let windDirection;
    if (angleDeg === 0) {
      windDirection = new Vector3(0, 0, 1);
    } else if (angleDeg === 90) {
      windDirection = new Vector3(1, 0, 0);
    } else if (angleDeg === 180) {
      windDirection = new Vector3(0, 0, -1);
    } else if (angleDeg === 270) {
      windDirection = new Vector3(-1, 0, 0);
    } else {
      const angleRad = angleDeg * (Math.PI / 180);

      const x = Math.sin(angleRad);
      const z = Math.cos(angleRad);

      windDirection = new Vector3(x, 0, z);
      }

      console.log('Computed Wind Direction:', windDirection);

      windLoadPositions.splice(0, windLoadPositions.length);
      windLoadPositions = getWindLoadPositions(uniqueArray, windFacingMembers, data.elementsElevations);

      windLoadPositions.forEach(position => {
          const windLoadGroup = drawWindLoads(position, windDirection);
          scene.add(windLoadGroup);
          windLoadGroups.push(windLoadGroup);
      });
    if (isLast) {
      dispatch(
        changeProjectRequestProgressAction(project.name, "natfreq", false)
      );
    }
  } catch (err) {
    console.error(err);
    dispatch(
      addEventAction(
        `Natural Frequency Analysis Check${data.openFrameName ? ` (${data.openFrameName})` : ""}: ${err.message ? `${err.message}` : ""}\n${
          [
            "Please check the required inputs for natural frequency analysis, i.e.,",
            "- Support conditions",
            "- Member profile",
            "- Structural geometry",
            "- Material density",
            "- End connectors",
          ].join("\n")
        }`,
        "danger"
      )
    );

    if (isLast) {
      dispatch(
        changeProjectRequestProgressAction(project.name, "natfreq", false)
      );
    }
  }
}


  
  function getWindLoadPositions(data: any, windFacingMembers: number[], maxY: number): Vector3[] {
    const positions = [];
    const interval = 2.4;
    for (const nodeKey in data) {
        const node = data[nodeKey];
        if (node && node.middleCoordinates) {
            const { x, y, z } = node.middleCoordinates;  
            const position = new Vector3(x, y, z);  
            positions.push(position);
        }
        // if(node && node.middleCoordinates && windFacingMembers.includes(parseInt(nodeKey))){
        //   const { x, z } = node.middleCoordinates;
        //     for (let y = 0; y <= maxY; y += interval) {
        //         const position = new Vector3(x, y, z);
        //         positions.push(position); 
        //         console.log("Added wind facing position: ", position);
        //     }
        // }
      }
    return positions;
}


  function handleChangeData(field: string, value: any) {
    if (!openFrameUI || !data) return;
    console.log("field", field);
    console.log("value", value);
    dispatch(
      changeOFUIAction({
        ...openFrameUI,
        loadingsUI: {
          ...openFrameUI.loadingsUI,
          windLoadUI: { ...data, [field]: value },
        },
      })
    );
  }

  function showImportErrorMsg(loads: string, msg: string) {
    dispatch(
      addEventAction(`Wind Load (Import of ${loads}): ${msg}`, "danger")
    );
  }


  return (
    <CustomDlg
      title={"Wind Load"}
      isMinimize={true}
      body={
        <div className="d-flex f-grow f-column">
          <div className="d-flex f-ai-center bg-dark always">
            <div className="label-light t-end w-160">Wind Loading as per</div>
            <FormGroup className="no-m w-160">
              <SimpleSelector<LoadingAsPerCode | "Manual">
                items={[...loadingAsPerCodes, "Manual"]}
                selected={data?.windLoadingAsPerCode}
                onSelect={(value) =>
                  handleChangeData("windLoadingAsPerCode", value)
                }
                itemLabel={(item) => item}
                className="fill-select"
              />
              
            </FormGroup>
            <div className="label-light t-end w-160">Display Direction Arrows</div>
            <input
              type="checkbox"
              checked={ShowWindLoadArrows}
              onChange={(e) => setShowWindLoadArrows(e.target.checked)}
            />
            <div className="label-light t-end w-2"></div>
            <SimpleSelector
              items={['dir0', 'dir45', 'dir90', 'dir135', 'dir180', 'dir225', 'dir270', 'dir315']}
              selected={selectedDirection}
              onSelect={(item) => {
                if (item) {
                  setSelectedDirection(item);
                  if(project){
                    removeWindLoads(scene);
                  handleGettingDesignCodesAndParameters(models,project,item);
                  }
                }
              }}
              itemLabel={(item) => `${item.toUpperCase()} Direction`}
              className="fill-select"
            />

          </div>
          <div className={"hr"} />
          {data?.windLoadingAsPerCode === "IS Code" && (
            <ISParamsOF data={data} onChange={handleChangeData} />
          )}
          {data?.windLoadingAsPerCode === "US Code" && (
            <USParamsOF data={data} onChange={handleChangeData} />
          )}
          {data?.windLoadingAsPerCode === "EU Code" && <></>}
          {data?.windLoadingAsPerCode === "Manual" && (
            <ManualParamsOF data={data} onChange={handleChangeData} />
          )}
          <div className={"hr"} />
          {natFreqProgress || profilePressureProgress ? (
            <>
              <ProgressBar />
              <div className={"hr"} />
            </>
          ) : null}
          {data && (
            <AdditionalLoadsOF
              scene={scene}
              ui={ui}
              openFrameUI={openFrameUI}
              project={project}
              data={data}
              models={models}
              load={"windLoadUI"}
              onChange={handleChangeData}
              onImportError={(msg) =>
                showImportErrorMsg("Additional Loads", msg)
              }
            />
          )}
        </div>
      }
      onClose={onClose}
    />
  );

  
}
