import { UIState, ProjectUI, LoadingsUI } from "./types";
import {
  zoneFactors,
  soilTypes,
  soilFoundationConditions,
  concreteGrade,
  dampingRatios,
} from "../main/constants";

export const initLoadingsUI: LoadingsUI = {
  deadLoadUI: {
    SWF: 1.15,
    DLI: 200,
    SDLI: 200,
    FPd: 0,
    FPt: 0,
    FPh: 0,
    FPdl: 0,
    FPto: "All elements",
    accessoriesTab: "TP",
    accessoriesTPLoads: [],
    accessoriesFPLoads: [],
    accessoriesCTLoads: [],
    loads: [],
  },
  liveLoadUI: {
    intensity: 500,
    stairIntensity: 500,
    loads: [],
  },
  tempLoadUI: {
    minTemp: 20,
    maxTemp: 20,
  },
  pipingLoadsUI: {
    directLoads: [],
    blanketLoads: [],
  },
  equipmentLoadUI: [],
  windLoadUI: {
    windLoadingAsPerCode: "IS Code",
    isWindCode: {
      windCode: "IS875(Part3): 2015",
      basicWindSpeed: 23,
      riskCoefficient: 1,
      terrainCategory: "Category 1",
      topographicFactor: 1,
      impFactorCyclonic: 1,
      locationOfStructure: "",
      windDirectionalityFactor: 1,
      areaAveragingFactor: 1,
      combinationFactor: 1,
      datumElevation: 0,
    },
    usWindCode: {
      windCode: "ASCE 7-16",
      basicWindSpeed: 23,
      exposure: "B",
      windDirectionalityFactor: 1,
      topographyDetails: 0,
      importanceFactor: 0,
      flexibleStructure: "",
      structuralCategory: "",
      crossSectionShape: "",
      structureType: "",
      datumElevation: 0,
    },
    euWindCode: {},
    manualWindCode: [],
    tab: "AL",
    loads: [],
  },
  seismicLoadsUI: {
    seismicLoadingAsPerCode: "IS Code",
    seismicAnalysisMethod: "Equivalent Static",
    modalCombinationMethod: "CQC",
    spectralMode: "Manual",
    spectralsPoints: [],
    isSeismicCode: {
      zoneFactor: zoneFactors[0],
      dampingRatio: dampingRatios[1],
      importanceFactor: 1.15,
      responseReductionFactor: 1,
      soilType: soilTypes[0],
      soilFoundationCondition: soilFoundationConditions[0],
      timePeriod: "1/Naturalfreq",
    },
    usSeismicCode: {
      S_S: 0.35,
      S_1: 0.08,
      siteClass: "C",
      category: "III",
      T_L: 1,
      R: 3.5,
      dampingRatio: dampingRatios[1],
      importanceFactor: 1.25,
      structureHeight: 20,
      structureType: "Steel moment-resisting frames",
      timePeriod: 0.31,
    },
    seismicLoads: [],
  },
  loadCombinations: {
    LC_lib: "CUSTOM",
    loadCombinations: [],
  },
};

export const initialStateProjectUI: ProjectUI = {
  project: "New Project",
  pipeRackUI: {
    portals: {
      geometry: {
        noOfTiers: 2,
        noOfBays: 3,
        length: 10,
        width: 4,
        baseElevation: 0,
        topTierElevation: 4,
        direction: "+X",
        supportType: "Fix",
        x: 0,
        y: 0,
        z: 0,
      },
      parameters: [],
    },
  },
  openFrameUI: {
    frames: {
      x: 0,
      y: 0,
      z: 0,
      noOfTiers: 2,
      noOfColumns: 4,
      frameWidth: 12,
      frameHeight: 6,
      pedestalHeight: 0,
      noOfSimilarFrames: 3,
      spacingOfFrames: 6,
      directionToReplicate: "+X",
      supportType: "Fix",
      parameters: [],
      relocations: [],
    },
    elementsElevations: {
      elements: [],
    },
    additionalBeams: {
      cantilever: [],
      columnToColumn: [],
      columnToBeam: [],
      beamToBeam: [],
      columns: [],
      kneeBracings: [],
      verticalBracings: [],
      planBracings: [],
      staircases: [],
    },
    accessories: [],
    members: {
      beams: [],
      columns: [],
      releases: [],
    },
    platforms: [],
    ladders: {
      params: {
        spacingStringer: 400,
        diameterRung: 20,
        spacingRung: 300,
        widthCageHBars: 75,
        thicknessCageHBars: 6,
        spacingCageHBars: 300,
        diameterCageHBars: 800,
        widthCageVBars: 75,
        thicknessCageVBars: 6,
        nosCageVBars: 8,
        cageHeadRoom: 2.1,
      },
      ladders: [],
    },
    basePlates: {
      concreteGrade: concreteGrade[0],
      circular: [],
      rectangular: [],
    },
    spliceFlanges: {
      circular: [],
      rectangular: [],
    },
    pipes: {
      items: [],
      supports: [],
    },
    truss: [],
    runners: [],
    metalCladdings: [],
    masonryCladdings: [],
    loadingsUI: { ...initLoadingsUI },
  },
  designCodeAndParametersUI: {
    designCode: "IS 800 : 2007 LSD",
    indianDesignCode: {
      cmx: 0.9,
      cmy: 0.9,
      cmz: 0.9,
      deflectionRatio: 325,
      klrMaxColumns: 180,
      klrMaxBracings: 180,
      klrMaxBeams: 250,
      stressRation: 1,
      effectiveLengths: [],
    },
    americanDesignCode: {
      cb: 1.0,
      deflectionRatio: 325,
      klrMaxColumns: 180,
      klrMaxBracings: 180,
      klrMaxBeams: 250,
      stressRation: 1,
      effectiveLengths: [],
    },
  },
  analysisUI: {},
};

export const initialState: UIState = {
  project: "New Project",
  projectUIs: [initialStateProjectUI],
};
