import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesRussian = [
  {
    profile_section_id: 7591,
    name: "25x2.5",
    type: "Pipe",
    designation: "25x2.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "1,77",
    d_global: 25,
    i_global: "1,13",
    t_global: "2,5",
    z_global: "1,27",
  },
  {
    profile_section_id: 7592,
    name: "25x2.8",
    type: "Pipe",
    designation: "25x2.8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "1,95",
    d_global: 25,
    i_global: "1,22",
    t_global: "2,8",
    z_global: "1,39",
  },
  {
    profile_section_id: 7593,
    name: "25x3",
    type: "Pipe",
    designation: "25x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "2,07",
    d_global: 25,
    i_global: "1,28",
    t_global: 3,
    z_global: "1,46",
  },
  {
    profile_section_id: 7594,
    name: "25x3.5",
    type: "Pipe",
    designation: "25x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "2,36",
    d_global: 25,
    i_global: "1,4",
    t_global: "3,5",
    z_global: "1,63",
  },
  {
    profile_section_id: 7595,
    name: "25x4",
    type: "Pipe",
    designation: "25x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "2,64",
    d_global: 25,
    i_global: "1,51",
    t_global: 4,
    z_global: "1,78",
  },
  {
    profile_section_id: 7596,
    name: "25x4.5",
    type: "Pipe",
    designation: "25x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "2,9",
    d_global: 25,
    i_global: "1,6",
    t_global: "4,5",
    z_global: "1,92",
  },
  {
    profile_section_id: 7597,
    name: "25x5",
    type: "Pipe",
    designation: "25x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,14",
    d_global: 25,
    i_global: "1,67",
    t_global: 5,
    z_global: "2,04",
  },
  {
    profile_section_id: 7598,
    name: "25x5.5",
    type: "Pipe",
    designation: "25x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,37",
    d_global: 25,
    i_global: "1,73",
    t_global: "5,5",
    z_global: "2,15",
  },
  {
    profile_section_id: 7599,
    name: "25x6",
    type: "Pipe",
    designation: "25x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,58",
    d_global: 25,
    i_global: "1,78",
    t_global: 6,
    z_global: "2,24",
  },
  {
    profile_section_id: 7600,
    name: "25x6.5",
    type: "Pipe",
    designation: "25x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,78",
    d_global: 25,
    i_global: "1,82",
    t_global: "6,5",
    z_global: "2,32",
  },
  {
    profile_section_id: 7601,
    name: "25x7",
    type: "Pipe",
    designation: "25x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,96",
    d_global: 25,
    i_global: "1,85",
    t_global: 7,
    z_global: "2,38",
  },
  {
    profile_section_id: 7602,
    name: "25x7.5",
    type: "Pipe",
    designation: "25x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,12",
    d_global: 25,
    i_global: "1,87",
    t_global: "7,5",
    z_global: "2,44",
  },
  {
    profile_section_id: 7603,
    name: "25x8",
    type: "Pipe",
    designation: "25x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,27",
    d_global: 25,
    i_global: "1,89",
    t_global: 8,
    z_global: "2,48",
  },
  {
    profile_section_id: 7604,
    name: "28x2.5",
    type: "Pipe",
    designation: "28x2.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 2,
    d_global: 28,
    i_global: "1,64",
    t_global: "2,5",
    z_global: "1,63",
  },
  {
    profile_section_id: 7605,
    name: "28x2.8",
    type: "Pipe",
    designation: "28x2.8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "2,22",
    d_global: 28,
    i_global: "1,78",
    t_global: "2,8",
    z_global: "1,78",
  },
  {
    profile_section_id: 7606,
    name: "28x3",
    type: "Pipe",
    designation: "28x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "2,36",
    d_global: 28,
    i_global: "1,87",
    t_global: 3,
    z_global: "1,88",
  },
  {
    profile_section_id: 7607,
    name: "28x3.5",
    type: "Pipe",
    designation: "28x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "2,69",
    d_global: 28,
    i_global: "2,06",
    t_global: "3,5",
    z_global: "2,11",
  },
  {
    profile_section_id: 7608,
    name: "28x4",
    type: "Pipe",
    designation: "28x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,02",
    d_global: 28,
    i_global: "2,23",
    t_global: 4,
    z_global: "2,32",
  },
  {
    profile_section_id: 7609,
    name: "28x4.5",
    type: "Pipe",
    designation: "28x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,32",
    d_global: 28,
    i_global: "2,38",
    t_global: "4,5",
    z_global: "2,51",
  },
  {
    profile_section_id: 7610,
    name: "28x5",
    type: "Pipe",
    designation: "28x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,61",
    d_global: 28,
    i_global: "2,5",
    t_global: 5,
    z_global: "2,69",
  },
  {
    profile_section_id: 7611,
    name: "28x5.5",
    type: "Pipe",
    designation: "28x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,89",
    d_global: 28,
    i_global: "2,61",
    t_global: "5,5",
    z_global: "2,84",
  },
  {
    profile_section_id: 7612,
    name: "28x6",
    type: "Pipe",
    designation: "28x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,15",
    d_global: 28,
    i_global: "2,7",
    t_global: 6,
    z_global: "2,98",
  },
  {
    profile_section_id: 7613,
    name: "28x6.5",
    type: "Pipe",
    designation: "28x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,39",
    d_global: 28,
    i_global: "2,77",
    t_global: "6,5",
    z_global: "3,1",
  },
  {
    profile_section_id: 7614,
    name: "28x7",
    type: "Pipe",
    designation: "28x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,62",
    d_global: 28,
    i_global: "2,83",
    t_global: 7,
    z_global: "3,2",
  },
  {
    profile_section_id: 7615,
    name: "28x7.5",
    type: "Pipe",
    designation: "28x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,83",
    d_global: 28,
    i_global: "2,88",
    t_global: "7,5",
    z_global: "3,29",
  },
  {
    profile_section_id: 7616,
    name: "28x8",
    type: "Pipe",
    designation: "28x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,03",
    d_global: 28,
    i_global: "2,92",
    t_global: 8,
    z_global: "3,37",
  },
  {
    profile_section_id: 7617,
    name: "32x2.5",
    type: "Pipe",
    designation: "32x2.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "2,32",
    d_global: 32,
    i_global: "2,54",
    t_global: "2,5",
    z_global: "2,18",
  },
  {
    profile_section_id: 7618,
    name: "32x2.8",
    type: "Pipe",
    designation: "32x2.8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "2,57",
    d_global: 32,
    i_global: "2,76",
    t_global: "2,8",
    z_global: "2,39",
  },
  {
    profile_section_id: 7619,
    name: "32x3",
    type: "Pipe",
    designation: "32x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "2,73",
    d_global: 32,
    i_global: "2,9",
    t_global: 3,
    z_global: "2,53",
  },
  {
    profile_section_id: 7620,
    name: "32x3.5",
    type: "Pipe",
    designation: "32x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,13",
    d_global: 32,
    i_global: "3,23",
    t_global: "3,5",
    z_global: "2,86",
  },
  {
    profile_section_id: 7621,
    name: "32x4",
    type: "Pipe",
    designation: "32x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,52",
    d_global: 32,
    i_global: "3,52",
    t_global: 4,
    z_global: "3,16",
  },
  {
    profile_section_id: 7622,
    name: "32x4.5",
    type: "Pipe",
    designation: "32x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,89",
    d_global: 32,
    i_global: "3,77",
    t_global: "4,5",
    z_global: "3,43",
  },
  {
    profile_section_id: 7623,
    name: "32x5",
    type: "Pipe",
    designation: "32x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,24",
    d_global: 32,
    i_global: 4,
    t_global: 5,
    z_global: "3,69",
  },
  {
    profile_section_id: 7624,
    name: "32x5.5",
    type: "Pipe",
    designation: "32x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,58",
    d_global: 32,
    i_global: "4,19",
    t_global: "5,5",
    z_global: "3,92",
  },
  {
    profile_section_id: 7625,
    name: "32x6",
    type: "Pipe",
    designation: "32x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,9",
    d_global: 32,
    i_global: "4,36",
    t_global: 6,
    z_global: "4,13",
  },
  {
    profile_section_id: 7626,
    name: "32x6.5",
    type: "Pipe",
    designation: "32x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,21",
    d_global: 32,
    i_global: "4,51",
    t_global: "6,5",
    z_global: "4,32",
  },
  {
    profile_section_id: 7627,
    name: "32x7",
    type: "Pipe",
    designation: "32x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,5",
    d_global: 32,
    i_global: "4,63",
    t_global: 7,
    z_global: "4,49",
  },
  {
    profile_section_id: 7628,
    name: "32x7.5",
    type: "Pipe",
    designation: "32x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,77",
    d_global: 32,
    i_global: "4,74",
    t_global: "7,5",
    z_global: "4,64",
  },
  {
    profile_section_id: 7629,
    name: "32x8",
    type: "Pipe",
    designation: "32x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,03",
    d_global: 32,
    i_global: "4,83",
    t_global: 8,
    z_global: "4,78",
  },
  {
    profile_section_id: 7630,
    name: "38x2.5",
    type: "Pipe",
    designation: "38x2.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "2,79",
    d_global: 38,
    i_global: "4,41",
    t_global: "2,5",
    z_global: "3,16",
  },
  {
    profile_section_id: 7631,
    name: "38x2.8",
    type: "Pipe",
    designation: "38x2.8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,1",
    d_global: 38,
    i_global: "4,83",
    t_global: "2,8",
    z_global: "3,48",
  },
  {
    profile_section_id: 7632,
    name: "38x3",
    type: "Pipe",
    designation: "38x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,3",
    d_global: 38,
    i_global: "5,09",
    t_global: 3,
    z_global: "3,68",
  },
  {
    profile_section_id: 7633,
    name: "38x3.5",
    type: "Pipe",
    designation: "38x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,79",
    d_global: 38,
    i_global: "5,7",
    t_global: "3,5",
    z_global: "4,18",
  },
  {
    profile_section_id: 7634,
    name: "38x4",
    type: "Pipe",
    designation: "38x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,27",
    d_global: 38,
    i_global: "6,26",
    t_global: 4,
    z_global: "4,64",
  },
  {
    profile_section_id: 7635,
    name: "38x4.5",
    type: "Pipe",
    designation: "38x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,74",
    d_global: 38,
    i_global: "6,76",
    t_global: "4,5",
    z_global: "5,08",
  },
  {
    profile_section_id: 7636,
    name: "38x5",
    type: "Pipe",
    designation: "38x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,18",
    d_global: 38,
    i_global: "7,22",
    t_global: 5,
    z_global: "5,49",
  },
  {
    profile_section_id: 7637,
    name: "38x5.5",
    type: "Pipe",
    designation: "38x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,62",
    d_global: 38,
    i_global: "7,63",
    t_global: "5,5",
    z_global: "5,86",
  },
  {
    profile_section_id: 7638,
    name: "38x6",
    type: "Pipe",
    designation: "38x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,03",
    d_global: 38,
    i_global: "7,99",
    t_global: 6,
    z_global: "6,21",
  },
  {
    profile_section_id: 7639,
    name: "38x6.5",
    type: "Pipe",
    designation: "38x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,43",
    d_global: 38,
    i_global: "8,32",
    t_global: "6,5",
    z_global: "6,54",
  },
  {
    profile_section_id: 7640,
    name: "38x7",
    type: "Pipe",
    designation: "38x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,82",
    d_global: 38,
    i_global: "8,61",
    t_global: 7,
    z_global: "6,84",
  },
  {
    profile_section_id: 7641,
    name: "38x7.5",
    type: "Pipe",
    designation: "38x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,19",
    d_global: 38,
    i_global: "8,86",
    t_global: "7,5",
    z_global: "7,12",
  },
  {
    profile_section_id: 7642,
    name: "38x8",
    type: "Pipe",
    designation: "38x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,54",
    d_global: 38,
    i_global: "9,09",
    t_global: 8,
    z_global: "7,37",
  },
  {
    profile_section_id: 7643,
    name: "42x2.5",
    type: "Pipe",
    designation: "42x2.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,1",
    d_global: 42,
    i_global: "6,07",
    t_global: "2,5",
    z_global: "3,9",
  },
  {
    profile_section_id: 7644,
    name: "42x2.8",
    type: "Pipe",
    designation: "42x2.8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,45",
    d_global: 42,
    i_global: "6,66",
    t_global: "2,8",
    z_global: "4,31",
  },
  {
    profile_section_id: 7645,
    name: "42x3",
    type: "Pipe",
    designation: "42x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,68",
    d_global: 42,
    i_global: "7,03",
    t_global: 3,
    z_global: "4,57",
  },
  {
    profile_section_id: 7646,
    name: "42x3.5",
    type: "Pipe",
    designation: "42x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,23",
    d_global: 42,
    i_global: "7,91",
    t_global: "3,5",
    z_global: "5,2",
  },
  {
    profile_section_id: 7647,
    name: "42x4",
    type: "Pipe",
    designation: "42x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,78",
    d_global: 42,
    i_global: "8,71",
    t_global: 4,
    z_global: "5,8",
  },
  {
    profile_section_id: 7648,
    name: "42x4.5",
    type: "Pipe",
    designation: "42x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,3",
    d_global: 42,
    i_global: "9,45",
    t_global: "4,5",
    z_global: "6,36",
  },
  {
    profile_section_id: 7649,
    name: "42x5",
    type: "Pipe",
    designation: "42x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,81",
    d_global: 42,
    i_global: "10,13",
    t_global: 5,
    z_global: "6,88",
  },
  {
    profile_section_id: 7650,
    name: "42x5.5",
    type: "Pipe",
    designation: "42x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,31",
    d_global: 42,
    i_global: "10,74",
    t_global: "5,5",
    z_global: "7,38",
  },
  {
    profile_section_id: 7651,
    name: "42x6",
    type: "Pipe",
    designation: "42x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,79",
    d_global: 42,
    i_global: "11,3",
    t_global: 6,
    z_global: "7,85",
  },
  {
    profile_section_id: 7652,
    name: "42x6.5",
    type: "Pipe",
    designation: "42x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,25",
    d_global: 42,
    i_global: "11,8",
    t_global: "6,5",
    z_global: "8,28",
  },
  {
    profile_section_id: 7653,
    name: "42x7",
    type: "Pipe",
    designation: "42x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,7",
    d_global: 42,
    i_global: "12,26",
    t_global: 7,
    z_global: "8,69",
  },
  {
    profile_section_id: 7654,
    name: "42x7.5",
    type: "Pipe",
    designation: "42x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,13",
    d_global: 42,
    i_global: "12,67",
    t_global: "7,5",
    z_global: "9,07",
  },
  {
    profile_section_id: 7655,
    name: "42x8",
    type: "Pipe",
    designation: "42x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,55",
    d_global: 42,
    i_global: "13,03",
    t_global: 8,
    z_global: "9,42",
  },
  {
    profile_section_id: 7656,
    name: "42x8.5",
    type: "Pipe",
    designation: "42x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,95",
    d_global: 42,
    i_global: "13,36",
    t_global: "8,5",
    z_global: "9,74",
  },
  {
    profile_section_id: 7657,
    name: "42x9",
    type: "Pipe",
    designation: "42x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,33",
    d_global: 42,
    i_global: "13,65",
    t_global: 9,
    z_global: "10,04",
  },
  {
    profile_section_id: 7658,
    name: "42x9.5",
    type: "Pipe",
    designation: "42x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,7",
    d_global: 42,
    i_global: "13,9",
    t_global: "9,5",
    z_global: "10,32",
  },
  {
    profile_section_id: 7659,
    name: "42x10",
    type: "Pipe",
    designation: "42x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,05",
    d_global: 42,
    i_global: "14,12",
    t_global: 10,
    z_global: "10,57",
  },
  {
    profile_section_id: 7660,
    name: "45x2.5",
    type: "Pipe",
    designation: "45x2.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,34",
    d_global: 45,
    i_global: "7,56",
    t_global: "2,5",
    z_global: "4,52",
  },
  {
    profile_section_id: 7661,
    name: "45x2.8",
    type: "Pipe",
    designation: "45x2.8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,71",
    d_global: 45,
    i_global: "8,3",
    t_global: "2,8",
    z_global: "4,99",
  },
  {
    profile_section_id: 7662,
    name: "45x3",
    type: "Pipe",
    designation: "45x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,96",
    d_global: 45,
    i_global: "8,77",
    t_global: 3,
    z_global: "5,3",
  },
  {
    profile_section_id: 7663,
    name: "45x3.5",
    type: "Pipe",
    designation: "45x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,56",
    d_global: 45,
    i_global: "9,89",
    t_global: "3,5",
    z_global: "6,04",
  },
  {
    profile_section_id: 7664,
    name: "45x4",
    type: "Pipe",
    designation: "45x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,15",
    d_global: 45,
    i_global: "10,93",
    t_global: 4,
    z_global: "6,74",
  },
  {
    profile_section_id: 7665,
    name: "45x4.5",
    type: "Pipe",
    designation: "45x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,73",
    d_global: 45,
    i_global: "11,88",
    t_global: "4,5",
    z_global: "7,41",
  },
  {
    profile_section_id: 7666,
    name: "45x5",
    type: "Pipe",
    designation: "45x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,28",
    d_global: 45,
    i_global: "12,76",
    t_global: 5,
    z_global: "8,04",
  },
  {
    profile_section_id: 7667,
    name: "45x5.5",
    type: "Pipe",
    designation: "45x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,83",
    d_global: 45,
    i_global: "13,57",
    t_global: "5,5",
    z_global: "8,63",
  },
  {
    profile_section_id: 7668,
    name: "45x6",
    type: "Pipe",
    designation: "45x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,35",
    d_global: 45,
    i_global: "14,31",
    t_global: 6,
    z_global: "9,2",
  },
  {
    profile_section_id: 7669,
    name: "45x6.5",
    type: "Pipe",
    designation: "45x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,86",
    d_global: 45,
    i_global: "14,98",
    t_global: "6,5",
    z_global: "9,72",
  },
  {
    profile_section_id: 7670,
    name: "45x7",
    type: "Pipe",
    designation: "45x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,36",
    d_global: 45,
    i_global: "15,6",
    t_global: 7,
    z_global: "10,22",
  },
  {
    profile_section_id: 7671,
    name: "45x7.5",
    type: "Pipe",
    designation: "45x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,84",
    d_global: 45,
    i_global: "16,15",
    t_global: "7,5",
    z_global: "10,68",
  },
  {
    profile_section_id: 7672,
    name: "45x8",
    type: "Pipe",
    designation: "45x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,3",
    d_global: 45,
    i_global: "16,66",
    t_global: 8,
    z_global: "11,12",
  },
  {
    profile_section_id: 7673,
    name: "45x8.5",
    type: "Pipe",
    designation: "45x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,75",
    d_global: 45,
    i_global: "17,11",
    t_global: "8,5",
    z_global: "11,53",
  },
  {
    profile_section_id: 7674,
    name: "45x9",
    type: "Pipe",
    designation: "45x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,18",
    d_global: 45,
    i_global: "17,52",
    t_global: 9,
    z_global: "11,9",
  },
  {
    profile_section_id: 7675,
    name: "45x9.5",
    type: "Pipe",
    designation: "45x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,6",
    d_global: 45,
    i_global: "17,89",
    t_global: "9,5",
    z_global: "12,26",
  },
  {
    profile_section_id: 7676,
    name: "45x10",
    type: "Pipe",
    designation: "45x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 11,
    d_global: 45,
    i_global: "18,21",
    t_global: 10,
    z_global: "12,58",
  },
  {
    profile_section_id: 7677,
    name: "50x2.5",
    type: "Pipe",
    designation: "50x2.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "3,73",
    d_global: 50,
    i_global: "10,55",
    t_global: "2,5",
    z_global: "5,64",
  },
  {
    profile_section_id: 7678,
    name: "50x2.8",
    type: "Pipe",
    designation: "50x2.8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,15",
    d_global: 50,
    i_global: "11,6",
    t_global: "2,8",
    z_global: "6,24",
  },
  {
    profile_section_id: 7679,
    name: "50x3",
    type: "Pipe",
    designation: "50x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,43",
    d_global: 50,
    i_global: "12,28",
    t_global: 3,
    z_global: "6,63",
  },
  {
    profile_section_id: 7680,
    name: "50x3.5",
    type: "Pipe",
    designation: "50x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,11",
    d_global: 50,
    i_global: "13,9",
    t_global: "3,5",
    z_global: "7,58",
  },
  {
    profile_section_id: 7681,
    name: "50x4",
    type: "Pipe",
    designation: "50x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,78",
    d_global: 50,
    i_global: "15,41",
    t_global: 4,
    z_global: "8,48",
  },
  {
    profile_section_id: 7682,
    name: "50x4.5",
    type: "Pipe",
    designation: "50x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,43",
    d_global: 50,
    i_global: "16,81",
    t_global: "4,5",
    z_global: "9,34",
  },
  {
    profile_section_id: 7683,
    name: "50x5",
    type: "Pipe",
    designation: "50x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,07",
    d_global: 50,
    i_global: "18,11",
    t_global: 5,
    z_global: "10,16",
  },
  {
    profile_section_id: 7684,
    name: "50x5.5",
    type: "Pipe",
    designation: "50x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,69",
    d_global: 50,
    i_global: "19,32",
    t_global: "5,5",
    z_global: "10,94",
  },
  {
    profile_section_id: 7685,
    name: "50x6",
    type: "Pipe",
    designation: "50x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,29",
    d_global: 50,
    i_global: "20,44",
    t_global: 6,
    z_global: "11,69",
  },
  {
    profile_section_id: 7686,
    name: "50x6.5",
    type: "Pipe",
    designation: "50x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,88",
    d_global: 50,
    i_global: "21,48",
    t_global: "6,5",
    z_global: "12,39",
  },
  {
    profile_section_id: 7687,
    name: "50x7",
    type: "Pipe",
    designation: "50x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,46",
    d_global: 50,
    i_global: "22,43",
    t_global: 7,
    z_global: "13,05",
  },
  {
    profile_section_id: 7688,
    name: "50x7.5",
    type: "Pipe",
    designation: "50x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,01",
    d_global: 50,
    i_global: "23,31",
    t_global: "7,5",
    z_global: "13,68",
  },
  {
    profile_section_id: 7689,
    name: "50x8",
    type: "Pipe",
    designation: "50x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,56",
    d_global: 50,
    i_global: "24,12",
    t_global: 8,
    z_global: "14,28",
  },
  {
    profile_section_id: 7690,
    name: "50x8.5",
    type: "Pipe",
    designation: "50x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,08",
    d_global: 50,
    i_global: "24,86",
    t_global: "8,5",
    z_global: "14,84",
  },
  {
    profile_section_id: 7691,
    name: "50x9",
    type: "Pipe",
    designation: "50x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,59",
    d_global: 50,
    i_global: "25,53",
    t_global: 9,
    z_global: "15,37",
  },
  {
    profile_section_id: 7692,
    name: "50x9.5",
    type: "Pipe",
    designation: "50x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,09",
    d_global: 50,
    i_global: "26,15",
    t_global: "9,5",
    z_global: "15,86",
  },
  {
    profile_section_id: 7693,
    name: "50x10",
    type: "Pipe",
    designation: "50x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,57",
    d_global: 50,
    i_global: "26,7",
    t_global: 10,
    z_global: "16,33",
  },
  {
    profile_section_id: 7694,
    name: "54x3",
    type: "Pipe",
    designation: "54x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "4,81",
    d_global: 54,
    i_global: "15,68",
    t_global: 3,
    z_global: "7,81",
  },
  {
    profile_section_id: 7695,
    name: "54x3.5",
    type: "Pipe",
    designation: "54x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,55",
    d_global: 54,
    i_global: "17,79",
    t_global: "3,5",
    z_global: "8,94",
  },
  {
    profile_section_id: 7696,
    name: "54x4",
    type: "Pipe",
    designation: "54x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,28",
    d_global: 54,
    i_global: "19,76",
    t_global: 4,
    z_global: "10,02",
  },
  {
    profile_section_id: 7697,
    name: "54x4.5",
    type: "Pipe",
    designation: "54x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 7,
    d_global: 54,
    i_global: "21,61",
    t_global: "4,5",
    z_global: "11,05",
  },
  {
    profile_section_id: 7698,
    name: "54x5",
    type: "Pipe",
    designation: "54x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,7",
    d_global: 54,
    i_global: "23,34",
    t_global: 5,
    z_global: "12,04",
  },
  {
    profile_section_id: 7699,
    name: "54x5.5",
    type: "Pipe",
    designation: "54x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,38",
    d_global: 54,
    i_global: "24,96",
    t_global: "5,5",
    z_global: "12,99",
  },
  {
    profile_section_id: 7700,
    name: "54x6",
    type: "Pipe",
    designation: "54x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,05",
    d_global: 54,
    i_global: "26,46",
    t_global: 6,
    z_global: "13,89",
  },
  {
    profile_section_id: 7701,
    name: "54x6.5",
    type: "Pipe",
    designation: "54x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,7",
    d_global: 54,
    i_global: "27,87",
    t_global: "6,5",
    z_global: "14,75",
  },
  {
    profile_section_id: 7702,
    name: "54x7",
    type: "Pipe",
    designation: "54x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,34",
    d_global: 54,
    i_global: "29,17",
    t_global: 7,
    z_global: "15,57",
  },
  {
    profile_section_id: 7703,
    name: "54x7.5",
    type: "Pipe",
    designation: "54x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,96",
    d_global: 54,
    i_global: "30,38",
    t_global: "7,5",
    z_global: "16,35",
  },
  {
    profile_section_id: 7704,
    name: "54x8",
    type: "Pipe",
    designation: "54x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,56",
    d_global: 54,
    i_global: "31,5",
    t_global: 8,
    z_global: "17,09",
  },
  {
    profile_section_id: 7705,
    name: "54x8.5",
    type: "Pipe",
    designation: "54x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,15",
    d_global: 54,
    i_global: "32,54",
    t_global: "8,5",
    z_global: "17,8",
  },
  {
    profile_section_id: 7706,
    name: "54x9",
    type: "Pipe",
    designation: "54x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,72",
    d_global: 54,
    i_global: "33,49",
    t_global: 9,
    z_global: "18,46",
  },
  {
    profile_section_id: 7707,
    name: "54x9.5",
    type: "Pipe",
    designation: "54x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,28",
    d_global: 54,
    i_global: "34,37",
    t_global: "9,5",
    z_global: "19,09",
  },
  {
    profile_section_id: 7708,
    name: "54x10",
    type: "Pipe",
    designation: "54x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,82",
    d_global: 54,
    i_global: "35,18",
    t_global: 10,
    z_global: "19,69",
  },
  {
    profile_section_id: 7709,
    name: "54x11",
    type: "Pipe",
    designation: "54x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,86",
    d_global: 54,
    i_global: "36,59",
    t_global: 11,
    z_global: "20,78",
  },
  {
    profile_section_id: 7710,
    name: "57x3",
    type: "Pipe",
    designation: "57x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,09",
    d_global: 57,
    i_global: "18,61",
    t_global: 3,
    z_global: "8,75",
  },
  {
    profile_section_id: 7711,
    name: "57x3.5",
    type: "Pipe",
    designation: "57x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,88",
    d_global: 57,
    i_global: "21,14",
    t_global: "3,5",
    z_global: "10,03",
  },
  {
    profile_section_id: 7712,
    name: "57x4",
    type: "Pipe",
    designation: "57x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,66",
    d_global: 57,
    i_global: "23,52",
    t_global: 4,
    z_global: "11,25",
  },
  {
    profile_section_id: 7713,
    name: "57x4.5",
    type: "Pipe",
    designation: "57x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,42",
    d_global: 57,
    i_global: "25,76",
    t_global: "4,5",
    z_global: "12,43",
  },
  {
    profile_section_id: 7714,
    name: "57x5",
    type: "Pipe",
    designation: "57x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,17",
    d_global: 57,
    i_global: "27,86",
    t_global: 5,
    z_global: "13,56",
  },
  {
    profile_section_id: 7715,
    name: "57x5.5",
    type: "Pipe",
    designation: "57x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,9",
    d_global: 57,
    i_global: "29,84",
    t_global: "5,5",
    z_global: "14,64",
  },
  {
    profile_section_id: 7716,
    name: "57x6",
    type: "Pipe",
    designation: "57x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,61",
    d_global: 57,
    i_global: "31,69",
    t_global: 6,
    z_global: "15,67",
  },
  {
    profile_section_id: 7717,
    name: "57x6.5",
    type: "Pipe",
    designation: "57x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,31",
    d_global: 57,
    i_global: "33,42",
    t_global: "6,5",
    z_global: "16,66",
  },
  {
    profile_section_id: 7718,
    name: "57x7",
    type: "Pipe",
    designation: "57x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 11,
    d_global: 57,
    i_global: "35,03",
    t_global: 7,
    z_global: "17,61",
  },
  {
    profile_section_id: 7719,
    name: "57x7.5",
    type: "Pipe",
    designation: "57x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,66",
    d_global: 57,
    i_global: "36,54",
    t_global: "7,5",
    z_global: "18,51",
  },
  {
    profile_section_id: 7720,
    name: "57x8",
    type: "Pipe",
    designation: "57x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,32",
    d_global: 57,
    i_global: "37,95",
    t_global: 8,
    z_global: "19,37",
  },
  {
    profile_section_id: 7721,
    name: "57x8.5",
    type: "Pipe",
    designation: "57x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,95",
    d_global: 57,
    i_global: "39,25",
    t_global: "8,5",
    z_global: "20,19",
  },
  {
    profile_section_id: 7722,
    name: "57x9",
    type: "Pipe",
    designation: "57x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,57",
    d_global: 57,
    i_global: "40,46",
    t_global: 9,
    z_global: "20,97",
  },
  {
    profile_section_id: 7723,
    name: "57x9.5",
    type: "Pipe",
    designation: "57x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,18",
    d_global: 57,
    i_global: "41,58",
    t_global: "9,5",
    z_global: "21,71",
  },
  {
    profile_section_id: 7724,
    name: "57x10",
    type: "Pipe",
    designation: "57x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,77",
    d_global: 57,
    i_global: "42,62",
    t_global: 10,
    z_global: "22,42",
  },
  {
    profile_section_id: 7725,
    name: "57x11",
    type: "Pipe",
    designation: "57x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,9",
    d_global: 57,
    i_global: "44,45",
    t_global: 11,
    z_global: "23,71",
  },
  {
    profile_section_id: 7726,
    name: "57x12",
    type: "Pipe",
    designation: "57x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,96",
    d_global: 57,
    i_global: 46,
    t_global: 12,
    z_global: "24,87",
  },
  {
    profile_section_id: 7727,
    name: "57x13",
    type: "Pipe",
    designation: "57x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "17,97",
    d_global: 57,
    i_global: "47,28",
    t_global: 13,
    z_global: "25,89",
  },
  {
    profile_section_id: 7728,
    name: "60x3",
    type: "Pipe",
    designation: "60x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,37",
    d_global: 60,
    i_global: "21,88",
    t_global: 3,
    z_global: "9,75",
  },
  {
    profile_section_id: 7729,
    name: "60x3.5",
    type: "Pipe",
    designation: "60x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,21",
    d_global: 60,
    i_global: "24,88",
    t_global: "3,5",
    z_global: "11,18",
  },
  {
    profile_section_id: 7730,
    name: "60x4",
    type: "Pipe",
    designation: "60x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,04",
    d_global: 60,
    i_global: "27,73",
    t_global: 4,
    z_global: "12,56",
  },
  {
    profile_section_id: 7731,
    name: "60x4.5",
    type: "Pipe",
    designation: "60x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,85",
    d_global: 60,
    i_global: "30,41",
    t_global: "4,5",
    z_global: "13,89",
  },
  {
    profile_section_id: 7732,
    name: "60x5",
    type: "Pipe",
    designation: "60x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,64",
    d_global: 60,
    i_global: "32,94",
    t_global: 5,
    z_global: "15,16",
  },
  {
    profile_section_id: 7733,
    name: "60x5.5",
    type: "Pipe",
    designation: "60x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,42",
    d_global: 60,
    i_global: "35,32",
    t_global: "5,5",
    z_global: "16,39",
  },
  {
    profile_section_id: 7734,
    name: "60x6",
    type: "Pipe",
    designation: "60x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,18",
    d_global: 60,
    i_global: "37,56",
    t_global: 6,
    z_global: "17,56",
  },
  {
    profile_section_id: 7735,
    name: "60x6.5",
    type: "Pipe",
    designation: "60x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,92",
    d_global: 60,
    i_global: "39,66",
    t_global: "6,5",
    z_global: "18,69",
  },
  {
    profile_section_id: 7736,
    name: "60x7",
    type: "Pipe",
    designation: "60x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,66",
    d_global: 60,
    i_global: "41,64",
    t_global: 7,
    z_global: "19,77",
  },
  {
    profile_section_id: 7737,
    name: "60x7.5",
    type: "Pipe",
    designation: "60x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,37",
    d_global: 60,
    i_global: "43,49",
    t_global: "7,5",
    z_global: "20,81",
  },
  {
    profile_section_id: 7738,
    name: "60x8",
    type: "Pipe",
    designation: "60x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,07",
    d_global: 60,
    i_global: "45,22",
    t_global: 8,
    z_global: "21,8",
  },
  {
    profile_section_id: 7739,
    name: "60x8.5",
    type: "Pipe",
    designation: "60x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,75",
    d_global: 60,
    i_global: "46,84",
    t_global: "8,5",
    z_global: "22,74",
  },
  {
    profile_section_id: 7740,
    name: "60x9",
    type: "Pipe",
    designation: "60x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,42",
    d_global: 60,
    i_global: "48,34",
    t_global: 9,
    z_global: "23,65",
  },
  {
    profile_section_id: 7741,
    name: "60x9.5",
    type: "Pipe",
    designation: "60x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,07",
    d_global: 60,
    i_global: "49,75",
    t_global: "9,5",
    z_global: "24,51",
  },
  {
    profile_section_id: 7742,
    name: "60x10",
    type: "Pipe",
    designation: "60x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,71",
    d_global: 60,
    i_global: "51,05",
    t_global: 10,
    z_global: "25,33",
  },
  {
    profile_section_id: 7743,
    name: "60x11",
    type: "Pipe",
    designation: "60x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,93",
    d_global: 60,
    i_global: "53,38",
    t_global: 11,
    z_global: "26,85",
  },
  {
    profile_section_id: 7744,
    name: "60x12",
    type: "Pipe",
    designation: "60x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,1",
    d_global: 60,
    i_global: "55,37",
    t_global: 12,
    z_global: "28,22",
  },
  {
    profile_section_id: 7745,
    name: "60x13",
    type: "Pipe",
    designation: "60x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,2",
    d_global: 60,
    i_global: "57,06",
    t_global: 13,
    z_global: "29,44",
  },
  {
    profile_section_id: 7746,
    name: "60x14",
    type: "Pipe",
    designation: "60x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,23",
    d_global: 60,
    i_global: "58,47",
    t_global: 14,
    z_global: "30,53",
  },
  {
    profile_section_id: 7747,
    name: "63.5x3",
    type: "Pipe",
    designation: "63.5x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "5,7",
    d_global: "63,5",
    i_global: "26,15",
    t_global: 3,
    z_global: "10,99",
  },
  {
    profile_section_id: 7748,
    name: "63.5x3.5",
    type: "Pipe",
    designation: "63.5x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,6",
    d_global: "63,5",
    i_global: "29,79",
    t_global: "3,5",
    z_global: "12,61",
  },
  {
    profile_section_id: 7749,
    name: "63.5x4",
    type: "Pipe",
    designation: "63.5x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,48",
    d_global: "63,5",
    i_global: "33,24",
    t_global: 4,
    z_global: "14,18",
  },
  {
    profile_section_id: 7750,
    name: "63.5x4.5",
    type: "Pipe",
    designation: "63.5x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,34",
    d_global: "63,5",
    i_global: "36,5",
    t_global: "4,5",
    z_global: "15,69",
  },
  {
    profile_section_id: 7751,
    name: "63.5x5",
    type: "Pipe",
    designation: "63.5x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,19",
    d_global: "63,5",
    i_global: "39,6",
    t_global: 5,
    z_global: "17,15",
  },
  {
    profile_section_id: 7752,
    name: "63.5x5.5",
    type: "Pipe",
    designation: "63.5x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,02",
    d_global: "63,5",
    i_global: "42,52",
    t_global: "5,5",
    z_global: "18,55",
  },
  {
    profile_section_id: 7753,
    name: "63.5x6",
    type: "Pipe",
    designation: "63.5x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,84",
    d_global: "63,5",
    i_global: "45,28",
    t_global: 6,
    z_global: "19,9",
  },
  {
    profile_section_id: 7754,
    name: "63.5x6.5",
    type: "Pipe",
    designation: "63.5x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,64",
    d_global: "63,5",
    i_global: "47,89",
    t_global: "6,5",
    z_global: "21,2",
  },
  {
    profile_section_id: 7755,
    name: "63.5x7",
    type: "Pipe",
    designation: "63.5x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,43",
    d_global: "63,5",
    i_global: "50,34",
    t_global: 7,
    z_global: "22,45",
  },
  {
    profile_section_id: 7756,
    name: "63.5x7.5",
    type: "Pipe",
    designation: "63.5x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,19",
    d_global: "63,5",
    i_global: "52,65",
    t_global: "7,5",
    z_global: "23,65",
  },
  {
    profile_section_id: 7757,
    name: "63.5x8",
    type: "Pipe",
    designation: "63.5x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,95",
    d_global: "63,5",
    i_global: "54,82",
    t_global: 8,
    z_global: "24,81",
  },
  {
    profile_section_id: 7758,
    name: "63.5x8.5",
    type: "Pipe",
    designation: "63.5x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,69",
    d_global: "63,5",
    i_global: "56,86",
    t_global: "8,5",
    z_global: "25,91",
  },
  {
    profile_section_id: 7759,
    name: "63.5x9",
    type: "Pipe",
    designation: "63.5x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,41",
    d_global: "63,5",
    i_global: "58,77",
    t_global: 9,
    z_global: "26,97",
  },
  {
    profile_section_id: 7760,
    name: "63.5x9.5",
    type: "Pipe",
    designation: "63.5x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,12",
    d_global: "63,5",
    i_global: "60,56",
    t_global: "9,5",
    z_global: "27,98",
  },
  {
    profile_section_id: 7761,
    name: "63.5x10",
    type: "Pipe",
    designation: "63.5x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,81",
    d_global: "63,5",
    i_global: "62,24",
    t_global: 10,
    z_global: "28,95",
  },
  {
    profile_section_id: 7762,
    name: "63.5x11",
    type: "Pipe",
    designation: "63.5x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,14",
    d_global: "63,5",
    i_global: "65,25",
    t_global: 11,
    z_global: "30,75",
  },
  {
    profile_section_id: 7763,
    name: "63.5x12",
    type: "Pipe",
    designation: "63.5x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,42",
    d_global: "63,5",
    i_global: "67,86",
    t_global: 12,
    z_global: "32,39",
  },
  {
    profile_section_id: 7764,
    name: "63.5x13",
    type: "Pipe",
    designation: "63.5x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,62",
    d_global: "63,5",
    i_global: "70,1",
    t_global: 13,
    z_global: "33,88",
  },
  {
    profile_section_id: 7765,
    name: "63.5x14",
    type: "Pipe",
    designation: "63.5x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "21,77",
    d_global: "63,5",
    i_global: "72,02",
    t_global: 14,
    z_global: "35,21",
  },
  {
    profile_section_id: 7766,
    name: "68x3",
    type: "Pipe",
    designation: "68x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,13",
    d_global: 68,
    i_global: "32,42",
    t_global: 3,
    z_global: "12,68",
  },
  {
    profile_section_id: 7767,
    name: "68x3.5",
    type: "Pipe",
    designation: "68x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,09",
    d_global: 68,
    i_global: "36,99",
    t_global: "3,5",
    z_global: "14,57",
  },
  {
    profile_section_id: 7768,
    name: "68x4",
    type: "Pipe",
    designation: "68x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,04",
    d_global: 68,
    i_global: "41,34",
    t_global: 4,
    z_global: "16,4",
  },
  {
    profile_section_id: 7769,
    name: "68x4.5",
    type: "Pipe",
    designation: "68x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,98",
    d_global: 68,
    i_global: "45,47",
    t_global: "4,5",
    z_global: "18,17",
  },
  {
    profile_section_id: 7770,
    name: "68x5",
    type: "Pipe",
    designation: "68x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,9",
    d_global: 68,
    i_global: "49,41",
    t_global: 5,
    z_global: "19,88",
  },
  {
    profile_section_id: 7771,
    name: "68x5.5",
    type: "Pipe",
    designation: "68x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,8",
    d_global: 68,
    i_global: "53,14",
    t_global: "5,5",
    z_global: "21,53",
  },
  {
    profile_section_id: 7772,
    name: "68x6",
    type: "Pipe",
    designation: "68x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,69",
    d_global: 68,
    i_global: "56,68",
    t_global: 6,
    z_global: "23,13",
  },
  {
    profile_section_id: 7773,
    name: "68x6.5",
    type: "Pipe",
    designation: "68x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,56",
    d_global: 68,
    i_global: "60,04",
    t_global: "6,5",
    z_global: "24,67",
  },
  {
    profile_section_id: 7774,
    name: "68x7",
    type: "Pipe",
    designation: "68x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,41",
    d_global: 68,
    i_global: "63,22",
    t_global: 7,
    z_global: "26,15",
  },
  {
    profile_section_id: 7775,
    name: "68x7.5",
    type: "Pipe",
    designation: "68x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,25",
    d_global: 68,
    i_global: "66,22",
    t_global: "7,5",
    z_global: "27,59",
  },
  {
    profile_section_id: 7776,
    name: "68x8",
    type: "Pipe",
    designation: "68x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,08",
    d_global: 68,
    i_global: "69,06",
    t_global: 8,
    z_global: "28,96",
  },
  {
    profile_section_id: 7777,
    name: "68x8.5",
    type: "Pipe",
    designation: "68x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,89",
    d_global: 68,
    i_global: "71,75",
    t_global: "8,5",
    z_global: "30,29",
  },
  {
    profile_section_id: 7778,
    name: "68x9",
    type: "Pipe",
    designation: "68x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,68",
    d_global: 68,
    i_global: "74,28",
    t_global: 9,
    z_global: "31,56",
  },
  {
    profile_section_id: 7779,
    name: "68x9.5",
    type: "Pipe",
    designation: "68x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "17,46",
    d_global: 68,
    i_global: "76,66",
    t_global: "9,5",
    z_global: "32,79",
  },
  {
    profile_section_id: 7780,
    name: "68x10",
    type: "Pipe",
    designation: "68x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,22",
    d_global: 68,
    i_global: "78,9",
    t_global: 10,
    z_global: "33,96",
  },
  {
    profile_section_id: 7781,
    name: "68x11",
    type: "Pipe",
    designation: "68x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,7",
    d_global: 68,
    i_global: "82,98",
    t_global: 11,
    z_global: "36,17",
  },
  {
    profile_section_id: 7782,
    name: "68x12",
    type: "Pipe",
    designation: "68x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "21,11",
    d_global: 68,
    i_global: "86,56",
    t_global: 12,
    z_global: "38,2",
  },
  {
    profile_section_id: 7783,
    name: "68x13",
    type: "Pipe",
    designation: "68x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "22,46",
    d_global: 68,
    i_global: "89,68",
    t_global: 13,
    z_global: "40,05",
  },
  {
    profile_section_id: 7784,
    name: "68x14",
    type: "Pipe",
    designation: "68x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "23,75",
    d_global: 68,
    i_global: "92,39",
    t_global: 14,
    z_global: "41,73",
  },
  {
    profile_section_id: 7785,
    name: "68x15",
    type: "Pipe",
    designation: "68x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "24,98",
    d_global: 68,
    i_global: "94,72",
    t_global: 15,
    z_global: "43,25",
  },
  {
    profile_section_id: 7786,
    name: "68x16",
    type: "Pipe",
    designation: "68x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "26,14",
    d_global: 68,
    i_global: "96,71",
    t_global: 16,
    z_global: "44,62",
  },
  {
    profile_section_id: 7787,
    name: "70x3",
    type: "Pipe",
    designation: "70x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,31",
    d_global: 70,
    i_global: "35,5",
    t_global: 3,
    z_global: "13,47",
  },
  {
    profile_section_id: 7788,
    name: "70x3.5",
    type: "Pipe",
    designation: "70x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,31",
    d_global: 70,
    i_global: "40,53",
    t_global: "3,5",
    z_global: "15,49",
  },
  {
    profile_section_id: 7789,
    name: "70x4",
    type: "Pipe",
    designation: "70x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,29",
    d_global: 70,
    i_global: "45,33",
    t_global: 4,
    z_global: "17,44",
  },
  {
    profile_section_id: 7790,
    name: "70x4.5",
    type: "Pipe",
    designation: "70x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,26",
    d_global: 70,
    i_global: "49,89",
    t_global: "4,5",
    z_global: "19,33",
  },
  {
    profile_section_id: 7791,
    name: "70x5",
    type: "Pipe",
    designation: "70x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,21",
    d_global: 70,
    i_global: "54,24",
    t_global: 5,
    z_global: "21,16",
  },
  {
    profile_section_id: 7792,
    name: "70x5.5",
    type: "Pipe",
    designation: "70x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,14",
    d_global: 70,
    i_global: "58,38",
    t_global: "5,5",
    z_global: "22,93",
  },
  {
    profile_section_id: 7793,
    name: "70x6",
    type: "Pipe",
    designation: "70x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,06",
    d_global: 70,
    i_global: "62,31",
    t_global: 6,
    z_global: "24,64",
  },
  {
    profile_section_id: 7794,
    name: "70x6.5",
    type: "Pipe",
    designation: "70x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,97",
    d_global: 70,
    i_global: "66,04",
    t_global: "6,5",
    z_global: "26,29",
  },
  {
    profile_section_id: 7795,
    name: "70x7",
    type: "Pipe",
    designation: "70x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,85",
    d_global: 70,
    i_global: "69,58",
    t_global: 7,
    z_global: "27,89",
  },
  {
    profile_section_id: 7796,
    name: "70x7.5",
    type: "Pipe",
    designation: "70x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,73",
    d_global: 70,
    i_global: "72,94",
    t_global: "7,5",
    z_global: "29,43",
  },
  {
    profile_section_id: 7797,
    name: "70x8",
    type: "Pipe",
    designation: "70x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,58",
    d_global: 70,
    i_global: "76,12",
    t_global: 8,
    z_global: "30,91",
  },
  {
    profile_section_id: 7798,
    name: "70x8.5",
    type: "Pipe",
    designation: "70x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,42",
    d_global: 70,
    i_global: "79,13",
    t_global: "8,5",
    z_global: "32,35",
  },
  {
    profile_section_id: 7799,
    name: "70x9",
    type: "Pipe",
    designation: "70x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "17,25",
    d_global: 70,
    i_global: "81,97",
    t_global: 9,
    z_global: "33,72",
  },
  {
    profile_section_id: 7800,
    name: "70x9.5",
    type: "Pipe",
    designation: "70x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,06",
    d_global: 70,
    i_global: "84,65",
    t_global: "9,5",
    z_global: "35,05",
  },
  {
    profile_section_id: 7801,
    name: "70x10",
    type: "Pipe",
    designation: "70x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,85",
    d_global: 70,
    i_global: "87,18",
    t_global: 10,
    z_global: "36,32",
  },
  {
    profile_section_id: 7802,
    name: "70x11",
    type: "Pipe",
    designation: "70x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,39",
    d_global: 70,
    i_global: "91,8",
    t_global: 11,
    z_global: "38,72",
  },
  {
    profile_section_id: 7803,
    name: "70x12",
    type: "Pipe",
    designation: "70x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "21,87",
    d_global: 70,
    i_global: "95,88",
    t_global: 12,
    z_global: "40,93",
  },
  {
    profile_section_id: 7804,
    name: "70x13",
    type: "Pipe",
    designation: "70x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "23,28",
    d_global: 70,
    i_global: "99,46",
    t_global: 13,
    z_global: "42,96",
  },
  {
    profile_section_id: 7805,
    name: "70x14",
    type: "Pipe",
    designation: "70x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "24,63",
    d_global: 70,
    i_global: "102,58",
    t_global: 14,
    z_global: "44,81",
  },
  {
    profile_section_id: 7806,
    name: "70x15",
    type: "Pipe",
    designation: "70x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "25,92",
    d_global: 70,
    i_global: "105,29",
    t_global: 15,
    z_global: "46,49",
  },
  {
    profile_section_id: 7807,
    name: "70x16",
    type: "Pipe",
    designation: "70x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "27,14",
    d_global: 70,
    i_global: "107,62",
    t_global: 16,
    z_global: "48,01",
  },
  {
    profile_section_id: 7808,
    name: "73x3",
    type: "Pipe",
    designation: "73x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,6",
    d_global: 73,
    i_global: "40,48",
    t_global: 3,
    z_global: "14,71",
  },
  {
    profile_section_id: 7809,
    name: "73x3.5",
    type: "Pipe",
    designation: "73x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,64",
    d_global: 73,
    i_global: "46,26",
    t_global: "3,5",
    z_global: "16,92",
  },
  {
    profile_section_id: 7810,
    name: "73x4",
    type: "Pipe",
    designation: "73x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,67",
    d_global: 73,
    i_global: "51,78",
    t_global: 4,
    z_global: "19,06",
  },
  {
    profile_section_id: 7811,
    name: "73x4.5",
    type: "Pipe",
    designation: "73x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,68",
    d_global: 73,
    i_global: "57,04",
    t_global: "4,5",
    z_global: "21,14",
  },
  {
    profile_section_id: 7812,
    name: "73x5",
    type: "Pipe",
    designation: "73x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,68",
    d_global: 73,
    i_global: "62,07",
    t_global: 5,
    z_global: "23,16",
  },
  {
    profile_section_id: 7813,
    name: "73x5.5",
    type: "Pipe",
    designation: "73x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,66",
    d_global: 73,
    i_global: "66,87",
    t_global: "5,5",
    z_global: "25,11",
  },
  {
    profile_section_id: 7814,
    name: "73x6",
    type: "Pipe",
    designation: "73x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,63",
    d_global: 73,
    i_global: "71,43",
    t_global: 6,
    z_global: 27,
  },
  {
    profile_section_id: 7815,
    name: "73x6.5",
    type: "Pipe",
    designation: "73x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,58",
    d_global: 73,
    i_global: "75,78",
    t_global: "6,5",
    z_global: "28,83",
  },
  {
    profile_section_id: 7816,
    name: "73x7",
    type: "Pipe",
    designation: "73x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,51",
    d_global: 73,
    i_global: "79,92",
    t_global: 7,
    z_global: "30,6",
  },
  {
    profile_section_id: 7817,
    name: "73x7.5",
    type: "Pipe",
    designation: "73x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,43",
    d_global: 73,
    i_global: "83,85",
    t_global: "7,5",
    z_global: "32,31",
  },
  {
    profile_section_id: 7818,
    name: "73x8",
    type: "Pipe",
    designation: "73x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,34",
    d_global: 73,
    i_global: "87,58",
    t_global: 8,
    z_global: "33,96",
  },
  {
    profile_section_id: 7819,
    name: "73x8.5",
    type: "Pipe",
    designation: "73x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "17,22",
    d_global: 73,
    i_global: "91,12",
    t_global: "8,5",
    z_global: "35,56",
  },
  {
    profile_section_id: 7820,
    name: "73x9",
    type: "Pipe",
    designation: "73x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,1",
    d_global: 73,
    i_global: "94,48",
    t_global: 9,
    z_global: "37,1",
  },
  {
    profile_section_id: 7821,
    name: "73x9.5",
    type: "Pipe",
    designation: "73x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,95",
    d_global: 73,
    i_global: "97,66",
    t_global: "9,5",
    z_global: "38,58",
  },
  {
    profile_section_id: 7822,
    name: "73x10",
    type: "Pipe",
    designation: "73x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,79",
    d_global: 73,
    i_global: "100,67",
    t_global: 10,
    z_global: "40,01",
  },
  {
    profile_section_id: 7823,
    name: "73x11",
    type: "Pipe",
    designation: "73x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "21,43",
    d_global: 73,
    i_global: "106,19",
    t_global: 11,
    z_global: "42,72",
  },
  {
    profile_section_id: 7824,
    name: "73x12",
    type: "Pipe",
    designation: "73x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 23,
    d_global: 73,
    i_global: "111,1",
    t_global: 12,
    z_global: "45,22",
  },
  {
    profile_section_id: 7825,
    name: "73x13",
    type: "Pipe",
    designation: "73x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "24,5",
    d_global: 73,
    i_global: "115,45",
    t_global: 13,
    z_global: "47,52",
  },
  {
    profile_section_id: 7826,
    name: "73x14",
    type: "Pipe",
    designation: "73x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "25,95",
    d_global: 73,
    i_global: "119,27",
    t_global: 14,
    z_global: "49,64",
  },
  {
    profile_section_id: 7827,
    name: "73x15",
    type: "Pipe",
    designation: "73x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "27,33",
    d_global: 73,
    i_global: "122,62",
    t_global: 15,
    z_global: "51,57",
  },
  {
    profile_section_id: 7828,
    name: "73x16",
    type: "Pipe",
    designation: "73x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "28,65",
    d_global: 73,
    i_global: "125,53",
    t_global: 16,
    z_global: "53,34",
  },
  {
    profile_section_id: 7829,
    name: "73x17",
    type: "Pipe",
    designation: "73x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "29,91",
    d_global: 73,
    i_global: "128,04",
    t_global: 17,
    z_global: "54,94",
  },
  {
    profile_section_id: 7830,
    name: "73x18",
    type: "Pipe",
    designation: "73x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "31,1",
    d_global: 73,
    i_global: "130,2",
    t_global: 18,
    z_global: "56,38",
  },
  {
    profile_section_id: 7831,
    name: "73x19",
    type: "Pipe",
    designation: "73x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "32,23",
    d_global: 73,
    i_global: "132,03",
    t_global: 19,
    z_global: "57,68",
  },
  {
    profile_section_id: 7832,
    name: "76x3",
    type: "Pipe",
    designation: "76x3",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "6,88",
    d_global: 76,
    i_global: "45,91",
    t_global: 3,
    z_global: "15,99",
  },
  {
    profile_section_id: 7833,
    name: "76x3.5",
    type: "Pipe",
    designation: "76x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "7,97",
    d_global: 76,
    i_global: "52,5",
    t_global: "3,5",
    z_global: "18,41",
  },
  {
    profile_section_id: 7834,
    name: "76x4",
    type: "Pipe",
    designation: "76x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,05",
    d_global: 76,
    i_global: "58,81",
    t_global: 4,
    z_global: "20,75",
  },
  {
    profile_section_id: 7835,
    name: "76x4.5",
    type: "Pipe",
    designation: "76x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,11",
    d_global: 76,
    i_global: "64,85",
    t_global: "4,5",
    z_global: "23,03",
  },
  {
    profile_section_id: 7836,
    name: "76x5",
    type: "Pipe",
    designation: "76x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,15",
    d_global: 76,
    i_global: "70,62",
    t_global: 5,
    z_global: "25,24",
  },
  {
    profile_section_id: 7837,
    name: "76x5.5",
    type: "Pipe",
    designation: "76x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,18",
    d_global: 76,
    i_global: "76,14",
    t_global: "5,5",
    z_global: "27,38",
  },
  {
    profile_section_id: 7838,
    name: "76x6",
    type: "Pipe",
    designation: "76x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,19",
    d_global: 76,
    i_global: "81,41",
    t_global: 6,
    z_global: "29,46",
  },
  {
    profile_section_id: 7839,
    name: "76x6.5",
    type: "Pipe",
    designation: "76x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,19",
    d_global: 76,
    i_global: "86,44",
    t_global: "6,5",
    z_global: "31,48",
  },
  {
    profile_section_id: 7840,
    name: "76x7",
    type: "Pipe",
    designation: "76x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,17",
    d_global: 76,
    i_global: "91,23",
    t_global: 7,
    z_global: "33,43",
  },
  {
    profile_section_id: 7841,
    name: "76x7.5",
    type: "Pipe",
    designation: "76x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,14",
    d_global: 76,
    i_global: "95,8",
    t_global: "7,5",
    z_global: "35,32",
  },
  {
    profile_section_id: 7842,
    name: "76x8",
    type: "Pipe",
    designation: "76x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "17,09",
    d_global: 76,
    i_global: "100,15",
    t_global: 8,
    z_global: "37,15",
  },
  {
    profile_section_id: 7843,
    name: "76x8.5",
    type: "Pipe",
    designation: "76x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,02",
    d_global: 76,
    i_global: "104,29",
    t_global: "8,5",
    z_global: "38,92",
  },
  {
    profile_section_id: 7844,
    name: "76x9",
    type: "Pipe",
    designation: "76x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,94",
    d_global: 76,
    i_global: "108,22",
    t_global: 9,
    z_global: "40,63",
  },
  {
    profile_section_id: 7845,
    name: "76x9.5",
    type: "Pipe",
    designation: "76x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,85",
    d_global: 76,
    i_global: "111,95",
    t_global: "9,5",
    z_global: "42,29",
  },
  {
    profile_section_id: 7846,
    name: "76x10",
    type: "Pipe",
    designation: "76x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,73",
    d_global: 76,
    i_global: "115,49",
    t_global: 10,
    z_global: "43,88",
  },
  {
    profile_section_id: 7847,
    name: "76x11",
    type: "Pipe",
    designation: "76x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "22,46",
    d_global: 76,
    i_global: "122,03",
    t_global: 11,
    z_global: "46,91",
  },
  {
    profile_section_id: 7848,
    name: "76x12",
    type: "Pipe",
    designation: "76x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "24,13",
    d_global: 76,
    i_global: "127,88",
    t_global: 12,
    z_global: "49,72",
  },
  {
    profile_section_id: 7849,
    name: "76x13",
    type: "Pipe",
    designation: "76x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "25,73",
    d_global: 76,
    i_global: "133,09",
    t_global: 13,
    z_global: "52,32",
  },
  {
    profile_section_id: 7850,
    name: "76x14",
    type: "Pipe",
    designation: "76x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "27,27",
    d_global: 76,
    i_global: "137,71",
    t_global: 14,
    z_global: "54,72",
  },
  {
    profile_section_id: 7851,
    name: "76x15",
    type: "Pipe",
    designation: "76x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "28,75",
    d_global: 76,
    i_global: "141,79",
    t_global: 15,
    z_global: "56,93",
  },
  {
    profile_section_id: 7852,
    name: "76x16",
    type: "Pipe",
    designation: "76x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "30,16",
    d_global: 76,
    i_global: "145,37",
    t_global: 16,
    z_global: "58,95",
  },
  {
    profile_section_id: 7853,
    name: "76x17",
    type: "Pipe",
    designation: "76x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "31,51",
    d_global: 76,
    i_global: "148,49",
    t_global: 17,
    z_global: "60,8",
  },
  {
    profile_section_id: 7854,
    name: "76x18",
    type: "Pipe",
    designation: "76x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "32,8",
    d_global: 76,
    i_global: "151,2",
    t_global: 18,
    z_global: "62,48",
  },
  {
    profile_section_id: 7855,
    name: "76x19",
    type: "Pipe",
    designation: "76x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "34,02",
    d_global: 76,
    i_global: "153,53",
    t_global: 19,
    z_global: 64,
  },
  {
    profile_section_id: 7856,
    name: "83x3.5",
    type: "Pipe",
    designation: "83x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "8,74",
    d_global: 83,
    i_global: "69,19",
    t_global: "3,5",
    z_global: "22,13",
  },
  {
    profile_section_id: 7857,
    name: "83x4",
    type: "Pipe",
    designation: "83x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,93",
    d_global: 83,
    i_global: "77,64",
    t_global: 4,
    z_global: "24,98",
  },
  {
    profile_section_id: 7858,
    name: "83x4.5",
    type: "Pipe",
    designation: "83x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,1",
    d_global: 83,
    i_global: "85,76",
    t_global: "4,5",
    z_global: "27,75",
  },
  {
    profile_section_id: 7859,
    name: "83x5",
    type: "Pipe",
    designation: "83x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,25",
    d_global: 83,
    i_global: "93,56",
    t_global: 5,
    z_global: "30,45",
  },
  {
    profile_section_id: 7860,
    name: "83x5.5",
    type: "Pipe",
    designation: "83x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,39",
    d_global: 83,
    i_global: "101,04",
    t_global: "5,5",
    z_global: "33,08",
  },
  {
    profile_section_id: 7861,
    name: "83x6",
    type: "Pipe",
    designation: "83x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,51",
    d_global: 83,
    i_global: "108,22",
    t_global: 6,
    z_global: "35,64",
  },
  {
    profile_section_id: 7862,
    name: "83x6.5",
    type: "Pipe",
    designation: "83x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,62",
    d_global: 83,
    i_global: "115,1",
    t_global: "6,5",
    z_global: "38,12",
  },
  {
    profile_section_id: 7863,
    name: "83x7",
    type: "Pipe",
    designation: "83x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,71",
    d_global: 83,
    i_global: "121,69",
    t_global: 7,
    z_global: "40,54",
  },
  {
    profile_section_id: 7864,
    name: "83x7.5",
    type: "Pipe",
    designation: "83x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "17,79",
    d_global: 83,
    i_global: 128,
    t_global: "7,5",
    z_global: "42,88",
  },
  {
    profile_section_id: 7865,
    name: "83x8",
    type: "Pipe",
    designation: "83x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,85",
    d_global: 83,
    i_global: "134,04",
    t_global: 8,
    z_global: "45,16",
  },
  {
    profile_section_id: 7866,
    name: "83x8.5",
    type: "Pipe",
    designation: "83x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,89",
    d_global: 83,
    i_global: "139,82",
    t_global: "8,5",
    z_global: "47,37",
  },
  {
    profile_section_id: 7867,
    name: "83x9",
    type: "Pipe",
    designation: "83x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,92",
    d_global: 83,
    i_global: "145,34",
    t_global: 9,
    z_global: "49,51",
  },
  {
    profile_section_id: 7868,
    name: "83x9.5",
    type: "Pipe",
    designation: "83x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "21,94",
    d_global: 83,
    i_global: "150,61",
    t_global: "9,5",
    z_global: "51,59",
  },
  {
    profile_section_id: 7869,
    name: "83x10",
    type: "Pipe",
    designation: "83x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "22,93",
    d_global: 83,
    i_global: "155,63",
    t_global: 10,
    z_global: "53,61",
  },
  {
    profile_section_id: 7870,
    name: "83x11",
    type: "Pipe",
    designation: "83x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "24,88",
    d_global: 83,
    i_global: "164,99",
    t_global: 11,
    z_global: "57,45",
  },
  {
    profile_section_id: 7871,
    name: "83x12",
    type: "Pipe",
    designation: "83x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "26,77",
    d_global: 83,
    i_global: "173,48",
    t_global: 12,
    z_global: "61,05",
  },
  {
    profile_section_id: 7872,
    name: "83x13",
    type: "Pipe",
    designation: "83x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "28,59",
    d_global: 83,
    i_global: "181,14",
    t_global: 13,
    z_global: "64,42",
  },
  {
    profile_section_id: 7873,
    name: "83x14",
    type: "Pipe",
    designation: "83x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "30,35",
    d_global: 83,
    i_global: "188,04",
    t_global: 14,
    z_global: "67,55",
  },
  {
    profile_section_id: 7874,
    name: "83x15",
    type: "Pipe",
    designation: "83x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "32,04",
    d_global: 83,
    i_global: "194,23",
    t_global: 15,
    z_global: "70,47",
  },
  {
    profile_section_id: 7875,
    name: "83x16",
    type: "Pipe",
    designation: "83x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "33,68",
    d_global: 83,
    i_global: "199,75",
    t_global: 16,
    z_global: "73,17",
  },
  {
    profile_section_id: 7876,
    name: "83x17",
    type: "Pipe",
    designation: "83x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "35,25",
    d_global: 83,
    i_global: "204,66",
    t_global: 17,
    z_global: "75,67",
  },
  {
    profile_section_id: 7877,
    name: "83x18",
    type: "Pipe",
    designation: "83x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "36,76",
    d_global: 83,
    i_global: "209,01",
    t_global: 18,
    z_global: "77,97",
  },
  {
    profile_section_id: 7878,
    name: "83x19",
    type: "Pipe",
    designation: "83x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "38,2",
    d_global: 83,
    i_global: "212,83",
    t_global: 19,
    z_global: "80,09",
  },
  {
    profile_section_id: 7879,
    name: "89x3.5",
    type: "Pipe",
    designation: "89x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "9,4",
    d_global: 89,
    i_global: "86,05",
    t_global: "3,5",
    z_global: "25,59",
  },
  {
    profile_section_id: 7880,
    name: "89x4",
    type: "Pipe",
    designation: "89x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,68",
    d_global: 89,
    i_global: "96,68",
    t_global: 4,
    z_global: "28,91",
  },
  {
    profile_section_id: 7881,
    name: "89x4.5",
    type: "Pipe",
    designation: "89x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,95",
    d_global: 89,
    i_global: "106,92",
    t_global: "4,5",
    z_global: "32,15",
  },
  {
    profile_section_id: 7882,
    name: "89x5",
    type: "Pipe",
    designation: "89x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,19",
    d_global: 89,
    i_global: "116,79",
    t_global: 5,
    z_global: "35,31",
  },
  {
    profile_section_id: 7883,
    name: "89x5.5",
    type: "Pipe",
    designation: "89x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,43",
    d_global: 89,
    i_global: "126,29",
    t_global: "5,5",
    z_global: "38,39",
  },
  {
    profile_section_id: 7884,
    name: "89x6",
    type: "Pipe",
    designation: "89x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,65",
    d_global: 89,
    i_global: "135,43",
    t_global: 6,
    z_global: "41,4",
  },
  {
    profile_section_id: 7885,
    name: "89x6.5",
    type: "Pipe",
    designation: "89x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,85",
    d_global: 89,
    i_global: "144,22",
    t_global: "6,5",
    z_global: "44,32",
  },
  {
    profile_section_id: 7886,
    name: "89x7",
    type: "Pipe",
    designation: "89x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,03",
    d_global: 89,
    i_global: "152,67",
    t_global: 7,
    z_global: "47,17",
  },
  {
    profile_section_id: 7887,
    name: "89x7.5",
    type: "Pipe",
    designation: "89x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,2",
    d_global: 89,
    i_global: "160,79",
    t_global: "7,5",
    z_global: "49,95",
  },
  {
    profile_section_id: 7888,
    name: "89x8",
    type: "Pipe",
    designation: "89x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,36",
    d_global: 89,
    i_global: "168,59",
    t_global: 8,
    z_global: "52,65",
  },
  {
    profile_section_id: 7889,
    name: "89x8.5",
    type: "Pipe",
    designation: "89x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "21,5",
    d_global: 89,
    i_global: "176,07",
    t_global: "8,5",
    z_global: "55,27",
  },
  {
    profile_section_id: 7890,
    name: "89x9",
    type: "Pipe",
    designation: "89x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "22,62",
    d_global: 89,
    i_global: "183,25",
    t_global: 9,
    z_global: "57,83",
  },
  {
    profile_section_id: 7891,
    name: "89x9.5",
    type: "Pipe",
    designation: "89x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "23,73",
    d_global: 89,
    i_global: "190,13",
    t_global: "9,5",
    z_global: "60,31",
  },
  {
    profile_section_id: 7892,
    name: "89x10",
    type: "Pipe",
    designation: "89x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "24,82",
    d_global: 89,
    i_global: "196,72",
    t_global: 10,
    z_global: "62,73",
  },
  {
    profile_section_id: 7893,
    name: "89x11",
    type: "Pipe",
    designation: "89x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "26,95",
    d_global: 89,
    i_global: "209,07",
    t_global: 11,
    z_global: "67,35",
  },
  {
    profile_section_id: 7894,
    name: "89x12",
    type: "Pipe",
    designation: "89x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "29,03",
    d_global: 89,
    i_global: "220,36",
    t_global: 12,
    z_global: "71,71",
  },
  {
    profile_section_id: 7895,
    name: "89x13",
    type: "Pipe",
    designation: "89x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "31,04",
    d_global: 89,
    i_global: "230,66",
    t_global: 13,
    z_global: "75,8",
  },
  {
    profile_section_id: 7896,
    name: "89x14",
    type: "Pipe",
    designation: "89x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "32,99",
    d_global: 89,
    i_global: "240,02",
    t_global: 14,
    z_global: "79,64",
  },
  {
    profile_section_id: 7897,
    name: "89x15",
    type: "Pipe",
    designation: "89x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "34,87",
    d_global: 89,
    i_global: "248,5",
    t_global: 15,
    z_global: "83,24",
  },
  {
    profile_section_id: 7898,
    name: "89x16",
    type: "Pipe",
    designation: "89x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "36,69",
    d_global: 89,
    i_global: "256,17",
    t_global: 16,
    z_global: "86,61",
  },
  {
    profile_section_id: 7899,
    name: "89x17",
    type: "Pipe",
    designation: "89x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "38,45",
    d_global: 89,
    i_global: "263,07",
    t_global: 17,
    z_global: "89,74",
  },
  {
    profile_section_id: 7900,
    name: "89x18",
    type: "Pipe",
    designation: "89x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "40,15",
    d_global: 89,
    i_global: "269,25",
    t_global: 18,
    z_global: "92,66",
  },
  {
    profile_section_id: 7901,
    name: "89x19",
    type: "Pipe",
    designation: "89x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "41,78",
    d_global: 89,
    i_global: "274,78",
    t_global: 19,
    z_global: "95,36",
  },
  {
    profile_section_id: 7902,
    name: "89x20",
    type: "Pipe",
    designation: "89x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "43,35",
    d_global: 89,
    i_global: "279,69",
    t_global: 20,
    z_global: "97,86",
  },
  {
    profile_section_id: 7903,
    name: "89x22",
    type: "Pipe",
    designation: "89x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "46,31",
    d_global: 89,
    i_global: "287,86",
    t_global: 22,
    z_global: "102,28",
  },
  {
    profile_section_id: 7904,
    name: "89x24",
    type: "Pipe",
    designation: "89x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "49,01",
    d_global: 89,
    i_global: "294,11",
    t_global: 24,
    z_global: "105,98",
  },
  {
    profile_section_id: 7905,
    name: "95x3.5",
    type: "Pipe",
    designation: "95x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,06",
    d_global: 95,
    i_global: "105,44",
    t_global: "3,5",
    z_global: "29,31",
  },
  {
    profile_section_id: 7906,
    name: "95x4",
    type: "Pipe",
    designation: "95x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "11,44",
    d_global: 95,
    i_global: "118,6",
    t_global: 4,
    z_global: "33,14",
  },
  {
    profile_section_id: 7907,
    name: "95x4.5",
    type: "Pipe",
    designation: "95x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,79",
    d_global: 95,
    i_global: "131,31",
    t_global: "4,5",
    z_global: "36,88",
  },
  {
    profile_section_id: 7908,
    name: "95x5",
    type: "Pipe",
    designation: "95x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,14",
    d_global: 95,
    i_global: "143,58",
    t_global: 5,
    z_global: "40,53",
  },
  {
    profile_section_id: 7909,
    name: "95x5.5",
    type: "Pipe",
    designation: "95x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,46",
    d_global: 95,
    i_global: "155,43",
    t_global: "5,5",
    z_global: "44,1",
  },
  {
    profile_section_id: 7910,
    name: "95x6",
    type: "Pipe",
    designation: "95x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,78",
    d_global: 95,
    i_global: "166,86",
    t_global: 6,
    z_global: "47,59",
  },
  {
    profile_section_id: 7911,
    name: "95x6.5",
    type: "Pipe",
    designation: "95x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,07",
    d_global: 95,
    i_global: "177,89",
    t_global: "6,5",
    z_global: "50,99",
  },
  {
    profile_section_id: 7912,
    name: "95x7",
    type: "Pipe",
    designation: "95x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,35",
    d_global: 95,
    i_global: "188,51",
    t_global: 7,
    z_global: "54,31",
  },
  {
    profile_section_id: 7913,
    name: "95x7.5",
    type: "Pipe",
    designation: "95x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,62",
    d_global: 95,
    i_global: "198,76",
    t_global: "7,5",
    z_global: "57,55",
  },
  {
    profile_section_id: 7914,
    name: "95x8",
    type: "Pipe",
    designation: "95x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "21,87",
    d_global: 95,
    i_global: "208,62",
    t_global: 8,
    z_global: "60,71",
  },
  {
    profile_section_id: 7915,
    name: "95x8.5",
    type: "Pipe",
    designation: "95x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "23,1",
    d_global: 95,
    i_global: "218,12",
    t_global: "8,5",
    z_global: "63,79",
  },
  {
    profile_section_id: 7916,
    name: "95x9",
    type: "Pipe",
    designation: "95x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "24,32",
    d_global: 95,
    i_global: "227,26",
    t_global: 9,
    z_global: "66,79",
  },
  {
    profile_section_id: 7917,
    name: "95x9.5",
    type: "Pipe",
    designation: "95x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "25,52",
    d_global: 95,
    i_global: "236,05",
    t_global: "9,5",
    z_global: "69,72",
  },
  {
    profile_section_id: 7918,
    name: "95x10",
    type: "Pipe",
    designation: "95x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "26,7",
    d_global: 95,
    i_global: "244,5",
    t_global: 10,
    z_global: "72,57",
  },
  {
    profile_section_id: 7919,
    name: "95x11",
    type: "Pipe",
    designation: "95x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "29,03",
    d_global: 95,
    i_global: "260,42",
    t_global: 11,
    z_global: "78,04",
  },
  {
    profile_section_id: 7920,
    name: "95x12",
    type: "Pipe",
    designation: "95x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "31,29",
    d_global: 95,
    i_global: "275,08",
    t_global: 12,
    z_global: "83,22",
  },
  {
    profile_section_id: 7921,
    name: "95x13",
    type: "Pipe",
    designation: "95x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "33,49",
    d_global: 95,
    i_global: "288,55",
    t_global: 13,
    z_global: "88,12",
  },
  {
    profile_section_id: 7922,
    name: "95x14",
    type: "Pipe",
    designation: "95x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "35,63",
    d_global: 95,
    i_global: "300,9",
    t_global: 14,
    z_global: "92,75",
  },
  {
    profile_section_id: 7923,
    name: "95x15",
    type: "Pipe",
    designation: "95x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "37,7",
    d_global: 95,
    i_global: "312,2",
    t_global: 15,
    z_global: "97,1",
  },
  {
    profile_section_id: 7924,
    name: "95x16",
    type: "Pipe",
    designation: "95x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "39,71",
    d_global: 95,
    i_global: "322,49",
    t_global: 16,
    z_global: "101,2",
  },
  {
    profile_section_id: 7925,
    name: "95x17",
    type: "Pipe",
    designation: "95x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "41,66",
    d_global: 95,
    i_global: "331,85",
    t_global: 17,
    z_global: "105,04",
  },
  {
    profile_section_id: 7926,
    name: "95x18",
    type: "Pipe",
    designation: "95x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "43,54",
    d_global: 95,
    i_global: "340,34",
    t_global: 18,
    z_global: "108,64",
  },
  {
    profile_section_id: 7927,
    name: "95x19",
    type: "Pipe",
    designation: "95x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "45,36",
    d_global: 95,
    i_global: 348,
    t_global: 19,
    z_global: 112,
  },
  {
    profile_section_id: 7928,
    name: "95x20",
    type: "Pipe",
    designation: "95x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "47,12",
    d_global: 95,
    i_global: "354,9",
    t_global: 20,
    z_global: "115,14",
  },
  {
    profile_section_id: 7929,
    name: "95x22",
    type: "Pipe",
    designation: "95x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "50,45",
    d_global: 95,
    i_global: "366,61",
    t_global: 22,
    z_global: "120,76",
  },
  {
    profile_section_id: 7930,
    name: "95x24",
    type: "Pipe",
    designation: "95x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "53,53",
    d_global: 95,
    i_global: "375,87",
    t_global: 24,
    z_global: "125,56",
  },
  {
    profile_section_id: 7931,
    name: "102x3.5",
    type: "Pipe",
    designation: "102x3.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "10,83",
    d_global: 102,
    i_global: "131,52",
    t_global: "3,5",
    z_global: "33,96",
  },
  {
    profile_section_id: 7932,
    name: "102x4",
    type: "Pipe",
    designation: "102x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "12,32",
    d_global: 102,
    i_global: "148,09",
    t_global: 4,
    z_global: "38,43",
  },
  {
    profile_section_id: 7933,
    name: "102x4.5",
    type: "Pipe",
    designation: "102x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,78",
    d_global: 102,
    i_global: "164,14",
    t_global: "4,5",
    z_global: "42,8",
  },
  {
    profile_section_id: 7934,
    name: "102x5",
    type: "Pipe",
    designation: "102x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,24",
    d_global: 102,
    i_global: "179,68",
    t_global: 5,
    z_global: "47,07",
  },
  {
    profile_section_id: 7935,
    name: "102x5.5",
    type: "Pipe",
    designation: "102x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,67",
    d_global: 102,
    i_global: "194,72",
    t_global: "5,5",
    z_global: "51,26",
  },
  {
    profile_section_id: 7936,
    name: "102x6",
    type: "Pipe",
    designation: "102x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,1",
    d_global: 102,
    i_global: "209,28",
    t_global: 6,
    z_global: "55,35",
  },
  {
    profile_section_id: 7937,
    name: "102x6.5",
    type: "Pipe",
    designation: "102x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,5",
    d_global: 102,
    i_global: "223,35",
    t_global: "6,5",
    z_global: "59,36",
  },
  {
    profile_section_id: 7938,
    name: "102x7",
    type: "Pipe",
    designation: "102x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,89",
    d_global: 102,
    i_global: "236,96",
    t_global: 7,
    z_global: "63,27",
  },
  {
    profile_section_id: 7939,
    name: "102x7.5",
    type: "Pipe",
    designation: "102x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "22,27",
    d_global: 102,
    i_global: "250,12",
    t_global: "7,5",
    z_global: "67,1",
  },
  {
    profile_section_id: 7940,
    name: "102x8",
    type: "Pipe",
    designation: "102x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "23,62",
    d_global: 102,
    i_global: "262,83",
    t_global: 8,
    z_global: "70,84",
  },
  {
    profile_section_id: 7941,
    name: "102x8.5",
    type: "Pipe",
    designation: "102x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "24,97",
    d_global: 102,
    i_global: "275,1",
    t_global: "8,5",
    z_global: "74,5",
  },
  {
    profile_section_id: 7942,
    name: "102x9",
    type: "Pipe",
    designation: "102x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "26,3",
    d_global: 102,
    i_global: "286,95",
    t_global: 9,
    z_global: "78,06",
  },
  {
    profile_section_id: 7943,
    name: "102x9.5",
    type: "Pipe",
    designation: "102x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "27,61",
    d_global: 102,
    i_global: "298,38",
    t_global: "9,5",
    z_global: "81,55",
  },
  {
    profile_section_id: 7944,
    name: "102x10",
    type: "Pipe",
    designation: "102x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "28,9",
    d_global: 102,
    i_global: "309,4",
    t_global: 10,
    z_global: "84,95",
  },
  {
    profile_section_id: 7945,
    name: "102x11",
    type: "Pipe",
    designation: "102x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "31,45",
    d_global: 102,
    i_global: "330,28",
    t_global: 11,
    z_global: "91,51",
  },
  {
    profile_section_id: 7946,
    name: "102x12",
    type: "Pipe",
    designation: "102x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "33,93",
    d_global: 102,
    i_global: "349,64",
    t_global: 12,
    z_global: "97,75",
  },
  {
    profile_section_id: 7947,
    name: "102x13",
    type: "Pipe",
    designation: "102x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "36,35",
    d_global: 102,
    i_global: "367,57",
    t_global: 13,
    z_global: "103,68",
  },
  {
    profile_section_id: 7948,
    name: "102x14",
    type: "Pipe",
    designation: "102x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "38,7",
    d_global: 102,
    i_global: "384,14",
    t_global: 14,
    z_global: "109,3",
  },
  {
    profile_section_id: 7949,
    name: "102x15",
    type: "Pipe",
    designation: "102x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 41,
    d_global: 102,
    i_global: "399,42",
    t_global: 15,
    z_global: "114,63",
  },
  {
    profile_section_id: 7950,
    name: "102x16",
    type: "Pipe",
    designation: "102x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "43,23",
    d_global: 102,
    i_global: "413,48",
    t_global: 16,
    z_global: "119,67",
  },
  {
    profile_section_id: 7951,
    name: "102x17",
    type: "Pipe",
    designation: "102x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "45,4",
    d_global: 102,
    i_global: "426,38",
    t_global: 17,
    z_global: "124,43",
  },
  {
    profile_section_id: 7952,
    name: "102x18",
    type: "Pipe",
    designation: "102x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "47,5",
    d_global: 102,
    i_global: "438,2",
    t_global: 18,
    z_global: "128,92",
  },
  {
    profile_section_id: 7953,
    name: "102x19",
    type: "Pipe",
    designation: "102x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "49,54",
    d_global: 102,
    i_global: "448,98",
    t_global: 19,
    z_global: "133,14",
  },
  {
    profile_section_id: 7954,
    name: "102x20",
    type: "Pipe",
    designation: "102x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "51,52",
    d_global: 102,
    i_global: "458,8",
    t_global: 20,
    z_global: "137,11",
  },
  {
    profile_section_id: 7955,
    name: "102x22",
    type: "Pipe",
    designation: "102x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "55,29",
    d_global: 102,
    i_global: "475,79",
    t_global: 22,
    z_global: "144,31",
  },
  {
    profile_section_id: 7956,
    name: "102x24",
    type: "Pipe",
    designation: "102x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "58,81",
    d_global: 102,
    i_global: "489,6",
    t_global: 24,
    z_global: "150,59",
  },
  {
    profile_section_id: 7957,
    name: "108x4",
    type: "Pipe",
    designation: "108x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,07",
    d_global: 108,
    i_global: "176,95",
    t_global: 4,
    z_global: "43,27",
  },
  {
    profile_section_id: 7958,
    name: "108x4.5",
    type: "Pipe",
    designation: "108x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,63",
    d_global: 108,
    i_global: "196,3",
    t_global: "4,5",
    z_global: "48,22",
  },
  {
    profile_section_id: 7959,
    name: "108x5",
    type: "Pipe",
    designation: "108x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,18",
    d_global: 108,
    i_global: "215,06",
    t_global: 5,
    z_global: "53,07",
  },
  {
    profile_section_id: 7960,
    name: "108x5.5",
    type: "Pipe",
    designation: "108x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "17,71",
    d_global: 108,
    i_global: "233,26",
    t_global: "5,5",
    z_global: "57,83",
  },
  {
    profile_section_id: 7961,
    name: "108x6",
    type: "Pipe",
    designation: "108x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,23",
    d_global: 108,
    i_global: "250,91",
    t_global: 6,
    z_global: "62,48",
  },
  {
    profile_section_id: 7962,
    name: "108x6.5",
    type: "Pipe",
    designation: "108x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,73",
    d_global: 108,
    i_global: "268,01",
    t_global: "6,5",
    z_global: "67,04",
  },
  {
    profile_section_id: 7963,
    name: "108x7",
    type: "Pipe",
    designation: "108x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "22,21",
    d_global: 108,
    i_global: "284,58",
    t_global: 7,
    z_global: "71,5",
  },
  {
    profile_section_id: 7964,
    name: "108x7.5",
    type: "Pipe",
    designation: "108x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "23,68",
    d_global: 108,
    i_global: "300,63",
    t_global: "7,5",
    z_global: "75,87",
  },
  {
    profile_section_id: 7965,
    name: "108x8",
    type: "Pipe",
    designation: "108x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "25,13",
    d_global: 108,
    i_global: "316,17",
    t_global: 8,
    z_global: "80,15",
  },
  {
    profile_section_id: 7966,
    name: "108x8.5",
    type: "Pipe",
    designation: "108x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "26,57",
    d_global: 108,
    i_global: "331,21",
    t_global: "8,5",
    z_global: "84,34",
  },
  {
    profile_section_id: 7967,
    name: "108x9",
    type: "Pipe",
    designation: "108x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "27,99",
    d_global: 108,
    i_global: "345,77",
    t_global: 9,
    z_global: "88,43",
  },
  {
    profile_section_id: 7968,
    name: "108x9.5",
    type: "Pipe",
    designation: "108x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "29,4",
    d_global: 108,
    i_global: "359,84",
    t_global: "9,5",
    z_global: "92,43",
  },
  {
    profile_section_id: 7969,
    name: "108x10",
    type: "Pipe",
    designation: "108x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "30,79",
    d_global: 108,
    i_global: "373,45",
    t_global: 10,
    z_global: "96,35",
  },
  {
    profile_section_id: 7970,
    name: "108x11",
    type: "Pipe",
    designation: "108x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "33,52",
    d_global: 108,
    i_global: "399,32",
    t_global: 11,
    z_global: "103,92",
  },
  {
    profile_section_id: 7971,
    name: "108x12",
    type: "Pipe",
    designation: "108x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "36,19",
    d_global: 108,
    i_global: "423,44",
    t_global: 12,
    z_global: "111,14",
  },
  {
    profile_section_id: 7972,
    name: "108x13",
    type: "Pipe",
    designation: "108x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "38,8",
    d_global: 108,
    i_global: "445,89",
    t_global: 13,
    z_global: "118,03",
  },
  {
    profile_section_id: 7973,
    name: "108x14",
    type: "Pipe",
    designation: "108x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "41,34",
    d_global: 108,
    i_global: "466,77",
    t_global: 14,
    z_global: "124,59",
  },
  {
    profile_section_id: 7974,
    name: "108x15",
    type: "Pipe",
    designation: "108x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "43,83",
    d_global: 108,
    i_global: "486,13",
    t_global: 15,
    z_global: "130,83",
  },
  {
    profile_section_id: 7975,
    name: "108x16",
    type: "Pipe",
    designation: "108x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "46,24",
    d_global: 108,
    i_global: "504,06",
    t_global: 16,
    z_global: "136,76",
  },
  {
    profile_section_id: 7976,
    name: "108x17",
    type: "Pipe",
    designation: "108x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "48,6",
    d_global: 108,
    i_global: "520,63",
    t_global: 17,
    z_global: "142,38",
  },
  {
    profile_section_id: 7977,
    name: "108x18",
    type: "Pipe",
    designation: "108x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "50,89",
    d_global: 108,
    i_global: "535,91",
    t_global: 18,
    z_global: "147,71",
  },
  {
    profile_section_id: 7978,
    name: "108x19",
    type: "Pipe",
    designation: "108x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "53,12",
    d_global: 108,
    i_global: "549,97",
    t_global: 19,
    z_global: "152,75",
  },
  {
    profile_section_id: 7979,
    name: "108x20",
    type: "Pipe",
    designation: "108x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "55,29",
    d_global: 108,
    i_global: "562,87",
    t_global: 20,
    z_global: "157,51",
  },
  {
    profile_section_id: 7980,
    name: "108x22",
    type: "Pipe",
    designation: "108x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "59,44",
    d_global: 108,
    i_global: "585,47",
    t_global: 22,
    z_global: "166,22",
  },
  {
    profile_section_id: 7981,
    name: "108x24",
    type: "Pipe",
    designation: "108x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "63,33",
    d_global: 108,
    i_global: "604,21",
    t_global: 24,
    z_global: "173,91",
  },
  {
    profile_section_id: 7982,
    name: "108x25",
    type: "Pipe",
    designation: "108x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "65,19",
    d_global: 108,
    i_global: "612,28",
    t_global: 25,
    z_global: "177,39",
  },
  {
    profile_section_id: 7983,
    name: "108x26",
    type: "Pipe",
    designation: "108x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "66,98",
    d_global: 108,
    i_global: "619,55",
    t_global: 26,
    z_global: "180,64",
  },
  {
    profile_section_id: 7984,
    name: "108x28",
    type: "Pipe",
    designation: "108x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "70,37",
    d_global: 108,
    i_global: "631,94",
    t_global: 28,
    z_global: "186,47",
  },
  {
    profile_section_id: 7985,
    name: "114x4",
    type: "Pipe",
    designation: "114x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "13,82",
    d_global: 114,
    i_global: "209,35",
    t_global: 4,
    z_global: "48,41",
  },
  {
    profile_section_id: 7986,
    name: "114x4.5",
    type: "Pipe",
    designation: "114x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,48",
    d_global: 114,
    i_global: "232,41",
    t_global: "4,5",
    z_global: "53,97",
  },
  {
    profile_section_id: 7987,
    name: "114x5",
    type: "Pipe",
    designation: "114x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "17,12",
    d_global: 114,
    i_global: "254,81",
    t_global: 5,
    z_global: "59,43",
  },
  {
    profile_section_id: 7988,
    name: "114x5.5",
    type: "Pipe",
    designation: "114x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,75",
    d_global: 114,
    i_global: "276,58",
    t_global: "5,5",
    z_global: "64,79",
  },
  {
    profile_section_id: 7989,
    name: "114x6",
    type: "Pipe",
    designation: "114x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,36",
    d_global: 114,
    i_global: "297,73",
    t_global: 6,
    z_global: "70,04",
  },
  {
    profile_section_id: 7990,
    name: "114x6.5",
    type: "Pipe",
    designation: "114x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "21,95",
    d_global: 114,
    i_global: "318,26",
    t_global: "6,5",
    z_global: "75,19",
  },
  {
    profile_section_id: 7991,
    name: "114x7",
    type: "Pipe",
    designation: "114x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "23,53",
    d_global: 114,
    i_global: "338,19",
    t_global: 7,
    z_global: "80,24",
  },
  {
    profile_section_id: 7992,
    name: "114x7.5",
    type: "Pipe",
    designation: "114x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "25,09",
    d_global: 114,
    i_global: "357,53",
    t_global: "7,5",
    z_global: "85,19",
  },
  {
    profile_section_id: 7993,
    name: "114x8",
    type: "Pipe",
    designation: "114x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "26,64",
    d_global: 114,
    i_global: "376,3",
    t_global: 8,
    z_global: "90,04",
  },
  {
    profile_section_id: 7994,
    name: "114x8.5",
    type: "Pipe",
    designation: "114x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "28,17",
    d_global: 114,
    i_global: "394,5",
    t_global: "8,5",
    z_global: "94,79",
  },
  {
    profile_section_id: 7995,
    name: "114x9",
    type: "Pipe",
    designation: "114x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "29,69",
    d_global: 114,
    i_global: "412,14",
    t_global: 9,
    z_global: "99,44",
  },
  {
    profile_section_id: 7996,
    name: "114x9.5",
    type: "Pipe",
    designation: "114x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "31,19",
    d_global: 114,
    i_global: "429,25",
    t_global: "9,5",
    z_global: 104,
  },
  {
    profile_section_id: 7997,
    name: "114x10",
    type: "Pipe",
    designation: "114x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "32,67",
    d_global: 114,
    i_global: "445,82",
    t_global: 10,
    z_global: "108,47",
  },
  {
    profile_section_id: 7998,
    name: "114x11",
    type: "Pipe",
    designation: "114x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "35,59",
    d_global: 114,
    i_global: "477,41",
    t_global: 11,
    z_global: "117,11",
  },
  {
    profile_section_id: 7999,
    name: "114x12",
    type: "Pipe",
    designation: "114x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "38,45",
    d_global: 114,
    i_global: 507,
    t_global: 12,
    z_global: "125,39",
  },
  {
    profile_section_id: 8000,
    name: "114x13",
    type: "Pipe",
    designation: "114x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "41,25",
    d_global: 114,
    i_global: "534,69",
    t_global: 13,
    z_global: "133,31",
  },
  {
    profile_section_id: 8001,
    name: "114x14",
    type: "Pipe",
    designation: "114x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "43,98",
    d_global: 114,
    i_global: "560,55",
    t_global: 14,
    z_global: "140,88",
  },
  {
    profile_section_id: 8002,
    name: "114x15",
    type: "Pipe",
    designation: "114x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "46,65",
    d_global: 114,
    i_global: "584,67",
    t_global: 15,
    z_global: "148,1",
  },
  {
    profile_section_id: 8003,
    name: "114x16",
    type: "Pipe",
    designation: "114x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "49,26",
    d_global: 114,
    i_global: "607,13",
    t_global: 16,
    z_global: "154,99",
  },
  {
    profile_section_id: 8004,
    name: "114x17",
    type: "Pipe",
    designation: "114x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "51,8",
    d_global: 114,
    i_global: 628,
    t_global: 17,
    z_global: "161,55",
  },
  {
    profile_section_id: 8005,
    name: "114x18",
    type: "Pipe",
    designation: "114x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "54,29",
    d_global: 114,
    i_global: "647,37",
    t_global: 18,
    z_global: "167,79",
  },
  {
    profile_section_id: 8006,
    name: "114x19",
    type: "Pipe",
    designation: "114x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "56,71",
    d_global: 114,
    i_global: "665,3",
    t_global: 19,
    z_global: "173,72",
  },
  {
    profile_section_id: 8007,
    name: "114x20",
    type: "Pipe",
    designation: "114x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "59,06",
    d_global: 114,
    i_global: "681,87",
    t_global: 20,
    z_global: "179,34",
  },
  {
    profile_section_id: 8008,
    name: "114x22",
    type: "Pipe",
    designation: "114x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "63,59",
    d_global: 114,
    i_global: "711,21",
    t_global: 22,
    z_global: "189,71",
  },
  {
    profile_section_id: 8009,
    name: "114x24",
    type: "Pipe",
    designation: "114x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "67,86",
    d_global: 114,
    i_global: "735,92",
    t_global: 24,
    z_global: "198,96",
  },
  {
    profile_section_id: 8010,
    name: "114x25",
    type: "Pipe",
    designation: "114x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "69,9",
    d_global: 114,
    i_global: "746,71",
    t_global: 25,
    z_global: "203,18",
  },
  {
    profile_section_id: 8011,
    name: "114x26",
    type: "Pipe",
    designation: "114x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "71,88",
    d_global: 114,
    i_global: "756,53",
    t_global: 26,
    z_global: "207,15",
  },
  {
    profile_section_id: 8012,
    name: "114x28",
    type: "Pipe",
    designation: "114x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "75,65",
    d_global: 114,
    i_global: "773,52",
    t_global: 28,
    z_global: "214,35",
  },
  {
    profile_section_id: 8013,
    name: "121x4",
    type: "Pipe",
    designation: "121x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "14,7",
    d_global: 121,
    i_global: "251,87",
    t_global: 4,
    z_global: "54,76",
  },
  {
    profile_section_id: 8014,
    name: "121x4.5",
    type: "Pipe",
    designation: "121x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,47",
    d_global: 121,
    i_global: "279,83",
    t_global: "4,5",
    z_global: "61,09",
  },
  {
    profile_section_id: 8015,
    name: "121x5",
    type: "Pipe",
    designation: "121x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,22",
    d_global: 121,
    i_global: "307,05",
    t_global: 5,
    z_global: "67,3",
  },
  {
    profile_section_id: 8016,
    name: "121x5.5",
    type: "Pipe",
    designation: "121x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,96",
    d_global: 121,
    i_global: "333,54",
    t_global: "5,5",
    z_global: "73,41",
  },
  {
    profile_section_id: 8017,
    name: "121x6",
    type: "Pipe",
    designation: "121x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "21,68",
    d_global: 121,
    i_global: "359,32",
    t_global: 6,
    z_global: "79,4",
  },
  {
    profile_section_id: 8018,
    name: "121x6.5",
    type: "Pipe",
    designation: "121x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "23,38",
    d_global: 121,
    i_global: "384,4",
    t_global: "6,5",
    z_global: "85,29",
  },
  {
    profile_section_id: 8019,
    name: "121x7",
    type: "Pipe",
    designation: "121x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "25,07",
    d_global: 121,
    i_global: "408,8",
    t_global: 7,
    z_global: "91,06",
  },
  {
    profile_section_id: 8020,
    name: "121x7.5",
    type: "Pipe",
    designation: "121x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "26,74",
    d_global: 121,
    i_global: "432,51",
    t_global: "7,5",
    z_global: "96,73",
  },
  {
    profile_section_id: 8021,
    name: "121x8",
    type: "Pipe",
    designation: "121x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "28,4",
    d_global: 121,
    i_global: "455,57",
    t_global: 8,
    z_global: "102,3",
  },
  {
    profile_section_id: 8022,
    name: "121x8.5",
    type: "Pipe",
    designation: "121x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "30,04",
    d_global: 121,
    i_global: "477,98",
    t_global: "8,5",
    z_global: "107,76",
  },
  {
    profile_section_id: 8023,
    name: "121x9",
    type: "Pipe",
    designation: "121x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "31,67",
    d_global: 121,
    i_global: "499,75",
    t_global: 9,
    z_global: "113,11",
  },
  {
    profile_section_id: 8024,
    name: "121x9.5",
    type: "Pipe",
    designation: "121x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "33,28",
    d_global: 121,
    i_global: "520,89",
    t_global: "9,5",
    z_global: "118,36",
  },
  {
    profile_section_id: 8025,
    name: "121x10",
    type: "Pipe",
    designation: "121x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "34,87",
    d_global: 121,
    i_global: "541,43",
    t_global: 10,
    z_global: "123,51",
  },
  {
    profile_section_id: 8026,
    name: "121x11",
    type: "Pipe",
    designation: "121x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "38,01",
    d_global: 121,
    i_global: "580,7",
    t_global: 11,
    z_global: "133,51",
  },
  {
    profile_section_id: 8027,
    name: "121x12",
    type: "Pipe",
    designation: "121x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "41,09",
    d_global: 121,
    i_global: "617,66",
    t_global: 12,
    z_global: "143,11",
  },
  {
    profile_section_id: 8028,
    name: "121x13",
    type: "Pipe",
    designation: "121x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "44,11",
    d_global: 121,
    i_global: "652,41",
    t_global: 13,
    z_global: "152,33",
  },
  {
    profile_section_id: 8029,
    name: "121x14",
    type: "Pipe",
    designation: "121x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "47,06",
    d_global: 121,
    i_global: "685,03",
    t_global: 14,
    z_global: "161,16",
  },
  {
    profile_section_id: 8030,
    name: "121x15",
    type: "Pipe",
    designation: "121x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "49,95",
    d_global: 121,
    i_global: "715,62",
    t_global: 15,
    z_global: "169,62",
  },
  {
    profile_section_id: 8031,
    name: "121x16",
    type: "Pipe",
    designation: "121x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "52,78",
    d_global: 121,
    i_global: "744,25",
    t_global: 16,
    z_global: "177,72",
  },
  {
    profile_section_id: 8032,
    name: "121x17",
    type: "Pipe",
    designation: "121x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "55,54",
    d_global: 121,
    i_global: "771,01",
    t_global: 17,
    z_global: "185,46",
  },
  {
    profile_section_id: 8033,
    name: "121x18",
    type: "Pipe",
    designation: "121x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "58,25",
    d_global: 121,
    i_global: "795,99",
    t_global: 18,
    z_global: "192,86",
  },
  {
    profile_section_id: 8034,
    name: "121x19",
    type: "Pipe",
    designation: "121x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "60,88",
    d_global: 121,
    i_global: "819,27",
    t_global: 19,
    z_global: "199,91",
  },
  {
    profile_section_id: 8035,
    name: "121x20",
    type: "Pipe",
    designation: "121x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "63,46",
    d_global: 121,
    i_global: "840,93",
    t_global: 20,
    z_global: "206,63",
  },
  {
    profile_section_id: 8036,
    name: "121x22",
    type: "Pipe",
    designation: "121x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "68,42",
    d_global: 121,
    i_global: "879,67",
    t_global: 22,
    z_global: "219,12",
  },
  {
    profile_section_id: 8037,
    name: "121x24",
    type: "Pipe",
    designation: "121x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "73,14",
    d_global: 121,
    i_global: "912,83",
    t_global: 24,
    z_global: "230,37",
  },
  {
    profile_section_id: 8038,
    name: "121x25",
    type: "Pipe",
    designation: "121x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "75,4",
    d_global: 121,
    i_global: "927,49",
    t_global: 25,
    z_global: "235,55",
  },
  {
    profile_section_id: 8039,
    name: "121x26",
    type: "Pipe",
    designation: "121x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "77,6",
    d_global: 121,
    i_global: "940,96",
    t_global: 26,
    z_global: "240,45",
  },
  {
    profile_section_id: 8040,
    name: "121x28",
    type: "Pipe",
    designation: "121x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "81,81",
    d_global: 121,
    i_global: "964,61",
    t_global: 28,
    z_global: "249,43",
  },
  {
    profile_section_id: 8041,
    name: "127x4",
    type: "Pipe",
    designation: "127x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "15,46",
    d_global: 127,
    i_global: "292,61",
    t_global: 4,
    z_global: "60,52",
  },
  {
    profile_section_id: 8042,
    name: "127x4.5",
    type: "Pipe",
    designation: "127x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "17,32",
    d_global: 127,
    i_global: "325,29",
    t_global: "4,5",
    z_global: "67,54",
  },
  {
    profile_section_id: 8043,
    name: "127x5",
    type: "Pipe",
    designation: "127x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,16",
    d_global: 127,
    i_global: "357,14",
    t_global: 5,
    z_global: "74,44",
  },
  {
    profile_section_id: 8044,
    name: "127x5.5",
    type: "Pipe",
    designation: "127x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,99",
    d_global: 127,
    i_global: "388,19",
    t_global: "5,5",
    z_global: "81,23",
  },
  {
    profile_section_id: 8045,
    name: "127x6",
    type: "Pipe",
    designation: "127x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "22,81",
    d_global: 127,
    i_global: "418,44",
    t_global: 6,
    z_global: "87,9",
  },
  {
    profile_section_id: 8046,
    name: "127x6.5",
    type: "Pipe",
    designation: "127x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "24,61",
    d_global: 127,
    i_global: "447,92",
    t_global: "6,5",
    z_global: "94,45",
  },
  {
    profile_section_id: 8047,
    name: "127x7",
    type: "Pipe",
    designation: "127x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "26,39",
    d_global: 127,
    i_global: "476,63",
    t_global: 7,
    z_global: "100,89",
  },
  {
    profile_section_id: 8048,
    name: "127x7.5",
    type: "Pipe",
    designation: "127x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "28,16",
    d_global: 127,
    i_global: "504,58",
    t_global: "7,5",
    z_global: "107,22",
  },
  {
    profile_section_id: 8049,
    name: "127x8",
    type: "Pipe",
    designation: "127x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "29,91",
    d_global: 127,
    i_global: "531,8",
    t_global: 8,
    z_global: "113,43",
  },
  {
    profile_section_id: 8050,
    name: "127x8.5",
    type: "Pipe",
    designation: "127x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "31,64",
    d_global: 127,
    i_global: "558,29",
    t_global: "8,5",
    z_global: "119,53",
  },
  {
    profile_section_id: 8051,
    name: "127x9",
    type: "Pipe",
    designation: "127x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "33,36",
    d_global: 127,
    i_global: "584,07",
    t_global: 9,
    z_global: "125,53",
  },
  {
    profile_section_id: 8052,
    name: "127x9.5",
    type: "Pipe",
    designation: "127x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "35,07",
    d_global: 127,
    i_global: "609,15",
    t_global: "9,5",
    z_global: "131,41",
  },
  {
    profile_section_id: 8053,
    name: "127x10",
    type: "Pipe",
    designation: "127x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "36,76",
    d_global: 127,
    i_global: "633,55",
    t_global: 10,
    z_global: "137,19",
  },
  {
    profile_section_id: 8054,
    name: "127x11",
    type: "Pipe",
    designation: "127x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "40,09",
    d_global: 127,
    i_global: "680,32",
    t_global: 11,
    z_global: "148,42",
  },
  {
    profile_section_id: 8055,
    name: "127x12",
    type: "Pipe",
    designation: "127x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "43,35",
    d_global: 127,
    i_global: "724,5",
    t_global: 12,
    z_global: "159,24",
  },
  {
    profile_section_id: 8056,
    name: "127x13",
    type: "Pipe",
    designation: "127x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "46,56",
    d_global: 127,
    i_global: "766,18",
    t_global: 13,
    z_global: "169,64",
  },
  {
    profile_section_id: 8057,
    name: "127x14",
    type: "Pipe",
    designation: "127x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "49,7",
    d_global: 127,
    i_global: "805,45",
    t_global: 14,
    z_global: "179,64",
  },
  {
    profile_section_id: 8058,
    name: "127x15",
    type: "Pipe",
    designation: "127x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "52,78",
    d_global: 127,
    i_global: "842,41",
    t_global: 15,
    z_global: "189,24",
  },
  {
    profile_section_id: 8059,
    name: "127x16",
    type: "Pipe",
    designation: "127x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "55,79",
    d_global: 127,
    i_global: "877,16",
    t_global: 16,
    z_global: "198,45",
  },
  {
    profile_section_id: 8060,
    name: "127x17",
    type: "Pipe",
    designation: "127x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "58,75",
    d_global: 127,
    i_global: "909,78",
    t_global: 17,
    z_global: "207,29",
  },
  {
    profile_section_id: 8061,
    name: "127x18",
    type: "Pipe",
    designation: "127x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "61,64",
    d_global: 127,
    i_global: "940,37",
    t_global: 18,
    z_global: "215,75",
  },
  {
    profile_section_id: 8062,
    name: "127x19",
    type: "Pipe",
    designation: "127x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "64,47",
    d_global: 127,
    i_global: 969,
    t_global: 19,
    z_global: "223,85",
  },
  {
    profile_section_id: 8063,
    name: "127x20",
    type: "Pipe",
    designation: "127x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "67,23",
    d_global: 127,
    i_global: "995,76",
    t_global: 20,
    z_global: "231,59",
  },
  {
    profile_section_id: 8064,
    name: "127x22",
    type: "Pipe",
    designation: "127x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "72,57",
    d_global: 127,
    i_global: "1044,02",
    t_global: 22,
    z_global: "246,04",
  },
  {
    profile_section_id: 8065,
    name: "127x24",
    type: "Pipe",
    designation: "127x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "77,66",
    d_global: 127,
    i_global: "1085,79",
    t_global: 24,
    z_global: "259,16",
  },
  {
    profile_section_id: 8066,
    name: "127x25",
    type: "Pipe",
    designation: "127x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "80,11",
    d_global: 127,
    i_global: "1104,42",
    t_global: 25,
    z_global: "265,24",
  },
  {
    profile_section_id: 8067,
    name: "127x26",
    type: "Pipe",
    designation: "127x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "82,5",
    d_global: 127,
    i_global: "1121,67",
    t_global: 26,
    z_global: "271,02",
  },
  {
    profile_section_id: 8068,
    name: "127x28",
    type: "Pipe",
    designation: "127x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "87,08",
    d_global: 127,
    i_global: "1152,24",
    t_global: 28,
    z_global: "281,67",
  },
  {
    profile_section_id: 8069,
    name: "127x30",
    type: "Pipe",
    designation: "127x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "91,42",
    d_global: 127,
    i_global: "1178,07",
    t_global: 30,
    z_global: "291,2",
  },
  {
    profile_section_id: 8070,
    name: "133x4",
    type: "Pipe",
    designation: "133x4",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "16,21",
    d_global: 133,
    i_global: "337,53",
    t_global: 4,
    z_global: "66,57",
  },
  {
    profile_section_id: 8071,
    name: "133x4.5",
    type: "Pipe",
    designation: "133x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "18,17",
    d_global: 133,
    i_global: "375,42",
    t_global: "4,5",
    z_global: "74,32",
  },
  {
    profile_section_id: 8072,
    name: "133x5",
    type: "Pipe",
    designation: "133x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,11",
    d_global: 133,
    i_global: "412,4",
    t_global: 5,
    z_global: "81,94",
  },
  {
    profile_section_id: 8073,
    name: "133x5.5",
    type: "Pipe",
    designation: "133x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "22,03",
    d_global: 133,
    i_global: "448,5",
    t_global: "5,5",
    z_global: "89,44",
  },
  {
    profile_section_id: 8074,
    name: "133x6",
    type: "Pipe",
    designation: "133x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "23,94",
    d_global: 133,
    i_global: "483,72",
    t_global: 6,
    z_global: "96,82",
  },
  {
    profile_section_id: 8075,
    name: "133x6.5",
    type: "Pipe",
    designation: "133x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "25,83",
    d_global: 133,
    i_global: "518,07",
    t_global: "6,5",
    z_global: "104,08",
  },
  {
    profile_section_id: 8076,
    name: "133x7",
    type: "Pipe",
    designation: "133x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "27,71",
    d_global: 133,
    i_global: "551,58",
    t_global: 7,
    z_global: "111,22",
  },
  {
    profile_section_id: 8077,
    name: "133x7.5",
    type: "Pipe",
    designation: "133x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "29,57",
    d_global: 133,
    i_global: "584,25",
    t_global: "7,5",
    z_global: "118,24",
  },
  {
    profile_section_id: 8078,
    name: "133x8",
    type: "Pipe",
    designation: "133x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "31,42",
    d_global: 133,
    i_global: "616,11",
    t_global: 8,
    z_global: "125,14",
  },
  {
    profile_section_id: 8079,
    name: "133x8.5",
    type: "Pipe",
    designation: "133x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "33,25",
    d_global: 133,
    i_global: "647,15",
    t_global: "8,5",
    z_global: "131,92",
  },
  {
    profile_section_id: 8080,
    name: "133x9",
    type: "Pipe",
    designation: "133x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "35,06",
    d_global: 133,
    i_global: "677,41",
    t_global: 9,
    z_global: "138,59",
  },
  {
    profile_section_id: 8081,
    name: "133x9.5",
    type: "Pipe",
    designation: "133x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "36,86",
    d_global: 133,
    i_global: "706,88",
    t_global: "9,5",
    z_global: "145,15",
  },
  {
    profile_section_id: 8082,
    name: "133x10",
    type: "Pipe",
    designation: "133x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "38,64",
    d_global: 133,
    i_global: "735,59",
    t_global: 10,
    z_global: "151,59",
  },
  {
    profile_section_id: 8083,
    name: "133x11",
    type: "Pipe",
    designation: "133x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "42,16",
    d_global: 133,
    i_global: "790,77",
    t_global: 11,
    z_global: "164,13",
  },
  {
    profile_section_id: 8084,
    name: "133x12",
    type: "Pipe",
    designation: "133x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "45,62",
    d_global: 133,
    i_global: "843,04",
    t_global: 12,
    z_global: "176,22",
  },
  {
    profile_section_id: 8085,
    name: "133x13",
    type: "Pipe",
    designation: "133x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "49,01",
    d_global: 133,
    i_global: "892,51",
    t_global: 13,
    z_global: "187,89",
  },
  {
    profile_section_id: 8086,
    name: "133x14",
    type: "Pipe",
    designation: "133x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "52,34",
    d_global: 133,
    i_global: "939,29",
    t_global: 14,
    z_global: "199,12",
  },
  {
    profile_section_id: 8087,
    name: "133x15",
    type: "Pipe",
    designation: "133x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "55,61",
    d_global: 133,
    i_global: "983,47",
    t_global: 15,
    z_global: "209,93",
  },
  {
    profile_section_id: 8088,
    name: "133x16",
    type: "Pipe",
    designation: "133x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "58,81",
    d_global: 133,
    i_global: "1025,14",
    t_global: 16,
    z_global: "220,33",
  },
  {
    profile_section_id: 8089,
    name: "133x17",
    type: "Pipe",
    designation: "133x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "61,95",
    d_global: 133,
    i_global: "1064,42",
    t_global: 17,
    z_global: "230,33",
  },
  {
    profile_section_id: 8090,
    name: "133x18",
    type: "Pipe",
    designation: "133x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "65,03",
    d_global: 133,
    i_global: "1101,38",
    t_global: 18,
    z_global: "239,93",
  },
  {
    profile_section_id: 8091,
    name: "133x19",
    type: "Pipe",
    designation: "133x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "68,05",
    d_global: 133,
    i_global: "1136,13",
    t_global: 19,
    z_global: "249,15",
  },
  {
    profile_section_id: 8092,
    name: "133x20",
    type: "Pipe",
    designation: "133x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 71,
    d_global: 133,
    i_global: "1168,75",
    t_global: 20,
    z_global: "257,98",
  },
  {
    profile_section_id: 8093,
    name: "133x22",
    type: "Pipe",
    designation: "133x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "76,72",
    d_global: 133,
    i_global: "1227,96",
    t_global: 22,
    z_global: "274,54",
  },
  {
    profile_section_id: 8094,
    name: "133x24",
    type: "Pipe",
    designation: "133x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "82,18",
    d_global: 133,
    i_global: "1279,71",
    t_global: 24,
    z_global: "289,68",
  },
  {
    profile_section_id: 8095,
    name: "133x25",
    type: "Pipe",
    designation: "133x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "84,82",
    d_global: 133,
    i_global: "1302,99",
    t_global: 25,
    z_global: "296,73",
  },
  {
    profile_section_id: 8096,
    name: "133x26",
    type: "Pipe",
    designation: "133x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "87,4",
    d_global: 133,
    i_global: "1324,64",
    t_global: 26,
    z_global: "303,46",
  },
  {
    profile_section_id: 8097,
    name: "133x28",
    type: "Pipe",
    designation: "133x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "92,36",
    d_global: 133,
    i_global: "1363,39",
    t_global: 28,
    z_global: "315,94",
  },
  {
    profile_section_id: 8098,
    name: "133x30",
    type: "Pipe",
    designation: "133x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "97,08",
    d_global: 133,
    i_global: "1396,55",
    t_global: 30,
    z_global: "327,19",
  },
  {
    profile_section_id: 8099,
    name: "133x32",
    type: "Pipe",
    designation: "133x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "101,54",
    d_global: 133,
    i_global: "1424,68",
    t_global: 32,
    z_global: "337,27",
  },
  {
    profile_section_id: 8100,
    name: "140x4.5",
    type: "Pipe",
    designation: "140x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "19,16",
    d_global: 140,
    i_global: "440,12",
    t_global: "4,5",
    z_global: "82,63",
  },
  {
    profile_section_id: 8101,
    name: "140x5",
    type: "Pipe",
    designation: "140x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "21,21",
    d_global: 140,
    i_global: "483,76",
    t_global: 5,
    z_global: "91,14",
  },
  {
    profile_section_id: 8102,
    name: "140x5.5",
    type: "Pipe",
    designation: "140x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "23,24",
    d_global: 140,
    i_global: "526,4",
    t_global: "5,5",
    z_global: "99,53",
  },
  {
    profile_section_id: 8103,
    name: "140x6",
    type: "Pipe",
    designation: "140x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "25,26",
    d_global: 140,
    i_global: "568,06",
    t_global: 6,
    z_global: "107,78",
  },
  {
    profile_section_id: 8104,
    name: "140x6.5",
    type: "Pipe",
    designation: "140x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "27,26",
    d_global: 140,
    i_global: "608,76",
    t_global: "6,5",
    z_global: "115,91",
  },
  {
    profile_section_id: 8105,
    name: "140x7",
    type: "Pipe",
    designation: "140x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "29,25",
    d_global: 140,
    i_global: "648,51",
    t_global: 7,
    z_global: "123,91",
  },
  {
    profile_section_id: 8106,
    name: "140x7.5",
    type: "Pipe",
    designation: "140x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "31,22",
    d_global: 140,
    i_global: "687,32",
    t_global: "7,5",
    z_global: "131,78",
  },
  {
    profile_section_id: 8107,
    name: "140x8",
    type: "Pipe",
    designation: "140x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "33,18",
    d_global: 140,
    i_global: "725,21",
    t_global: 8,
    z_global: "139,53",
  },
  {
    profile_section_id: 8108,
    name: "140x8.5",
    type: "Pipe",
    designation: "140x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "35,12",
    d_global: 140,
    i_global: "762,2",
    t_global: "8,5",
    z_global: "147,15",
  },
  {
    profile_section_id: 8109,
    name: "140x9",
    type: "Pipe",
    designation: "140x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "37,04",
    d_global: 140,
    i_global: "798,29",
    t_global: 9,
    z_global: "154,65",
  },
  {
    profile_section_id: 8110,
    name: "140x9.5",
    type: "Pipe",
    designation: "140x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "38,95",
    d_global: 140,
    i_global: "833,51",
    t_global: "9,5",
    z_global: "162,03",
  },
  {
    profile_section_id: 8111,
    name: "140x10",
    type: "Pipe",
    designation: "140x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "40,84",
    d_global: 140,
    i_global: "867,87",
    t_global: 10,
    z_global: "169,29",
  },
  {
    profile_section_id: 8112,
    name: "140x11",
    type: "Pipe",
    designation: "140x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "44,58",
    d_global: 140,
    i_global: "934,05",
    t_global: 11,
    z_global: "183,45",
  },
  {
    profile_section_id: 8113,
    name: "140x12",
    type: "Pipe",
    designation: "140x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "48,25",
    d_global: 140,
    i_global: "996,95",
    t_global: 12,
    z_global: "197,13",
  },
  {
    profile_section_id: 8114,
    name: "140x13",
    type: "Pipe",
    designation: "140x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "51,87",
    d_global: 140,
    i_global: "1056,67",
    t_global: 13,
    z_global: "210,36",
  },
  {
    profile_section_id: 8115,
    name: "140x14",
    type: "Pipe",
    designation: "140x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "55,42",
    d_global: 140,
    i_global: "1113,34",
    t_global: 14,
    z_global: "223,12",
  },
  {
    profile_section_id: 8116,
    name: "140x15",
    type: "Pipe",
    designation: "140x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "58,9",
    d_global: 140,
    i_global: "1167,05",
    t_global: 15,
    z_global: "235,44",
  },
  {
    profile_section_id: 8117,
    name: "140x16",
    type: "Pipe",
    designation: "140x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "62,33",
    d_global: 140,
    i_global: "1217,91",
    t_global: 16,
    z_global: "247,32",
  },
  {
    profile_section_id: 8118,
    name: "140x17",
    type: "Pipe",
    designation: "140x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "65,69",
    d_global: 140,
    i_global: "1266,02",
    t_global: 17,
    z_global: "258,77",
  },
  {
    profile_section_id: 8119,
    name: "140x18",
    type: "Pipe",
    designation: "140x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "68,99",
    d_global: 140,
    i_global: "1311,49",
    t_global: 18,
    z_global: "269,79",
  },
  {
    profile_section_id: 8120,
    name: "140x19",
    type: "Pipe",
    designation: "140x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "72,23",
    d_global: 140,
    i_global: "1354,4",
    t_global: 19,
    z_global: "280,4",
  },
  {
    profile_section_id: 8121,
    name: "140x20",
    type: "Pipe",
    designation: "140x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "75,4",
    d_global: 140,
    i_global: "1394,87",
    t_global: 20,
    z_global: "290,59",
  },
  {
    profile_section_id: 8122,
    name: "140x22",
    type: "Pipe",
    designation: "140x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "81,56",
    d_global: 140,
    i_global: "1468,82",
    t_global: 22,
    z_global: "309,8",
  },
  {
    profile_section_id: 8123,
    name: "140x24",
    type: "Pipe",
    designation: "140x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "87,46",
    d_global: 140,
    i_global: "1534,08",
    t_global: 24,
    z_global: "327,47",
  },
  {
    profile_section_id: 8124,
    name: "140x25",
    type: "Pipe",
    designation: "140x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "90,32",
    d_global: 140,
    i_global: "1563,68",
    t_global: 25,
    z_global: "335,75",
  },
  {
    profile_section_id: 8125,
    name: "140x26",
    type: "Pipe",
    designation: "140x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "93,12",
    d_global: 140,
    i_global: "1591,37",
    t_global: 26,
    z_global: "343,67",
  },
  {
    profile_section_id: 8126,
    name: "140x28",
    type: "Pipe",
    designation: "140x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "98,52",
    d_global: 140,
    i_global: "1641,35",
    t_global: 28,
    z_global: "358,46",
  },
  {
    profile_section_id: 8127,
    name: "140x30",
    type: "Pipe",
    designation: "140x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "103,67",
    d_global: 140,
    i_global: "1684,68",
    t_global: 30,
    z_global: "371,91",
  },
  {
    profile_section_id: 8128,
    name: "140x32",
    type: "Pipe",
    designation: "140x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "108,57",
    d_global: 140,
    i_global: "1721,97",
    t_global: 32,
    z_global: "384,07",
  },
  {
    profile_section_id: 8129,
    name: "140x34",
    type: "Pipe",
    designation: "140x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "113,22",
    d_global: 140,
    i_global: "1753,82",
    t_global: 34,
    z_global: "395,03",
  },
  {
    profile_section_id: 8130,
    name: "140x35",
    type: "Pipe",
    designation: "140x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "115,45",
    d_global: 140,
    i_global: "1767,88",
    t_global: 35,
    z_global: "400,07",
  },
  {
    profile_section_id: 8131,
    name: "140x36",
    type: "Pipe",
    designation: "140x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "117,62",
    d_global: 140,
    i_global: "1780,79",
    t_global: 36,
    z_global: "404,83",
  },
  {
    profile_section_id: 8132,
    name: "146x4.5",
    type: "Pipe",
    designation: "146x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 20,
    d_global: 146,
    i_global: "501,17",
    t_global: "4,5",
    z_global: "90,11",
  },
  {
    profile_section_id: 8133,
    name: "146x5",
    type: "Pipe",
    designation: "146x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "22,15",
    d_global: 146,
    i_global: "551,1",
    t_global: 5,
    z_global: "99,42",
  },
  {
    profile_section_id: 8134,
    name: "146x5.5",
    type: "Pipe",
    designation: "146x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "24,28",
    d_global: 146,
    i_global: "599,95",
    t_global: "5,5",
    z_global: "108,6",
  },
  {
    profile_section_id: 8135,
    name: "146x6",
    type: "Pipe",
    designation: "146x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "26,39",
    d_global: 146,
    i_global: "647,73",
    t_global: 6,
    z_global: "117,64",
  },
  {
    profile_section_id: 8136,
    name: "146x6.5",
    type: "Pipe",
    designation: "146x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "28,49",
    d_global: 146,
    i_global: "694,44",
    t_global: "6,5",
    z_global: "126,55",
  },
  {
    profile_section_id: 8137,
    name: "146x7",
    type: "Pipe",
    designation: "146x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "30,57",
    d_global: 146,
    i_global: "740,12",
    t_global: 7,
    z_global: "135,33",
  },
  {
    profile_section_id: 8138,
    name: "146x7.5",
    type: "Pipe",
    designation: "146x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "32,63",
    d_global: 146,
    i_global: "784,77",
    t_global: "7,5",
    z_global: "143,97",
  },
  {
    profile_section_id: 8139,
    name: "146x8",
    type: "Pipe",
    designation: "146x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "34,68",
    d_global: 146,
    i_global: "828,41",
    t_global: 8,
    z_global: "152,48",
  },
  {
    profile_section_id: 8140,
    name: "146x8.5",
    type: "Pipe",
    designation: "146x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "36,72",
    d_global: 146,
    i_global: "871,05",
    t_global: "8,5",
    z_global: "160,87",
  },
  {
    profile_section_id: 8141,
    name: "146x9",
    type: "Pipe",
    designation: "146x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "38,74",
    d_global: 146,
    i_global: "912,71",
    t_global: 9,
    z_global: "169,12",
  },
  {
    profile_section_id: 8142,
    name: "146x9.5",
    type: "Pipe",
    designation: "146x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "40,74",
    d_global: 146,
    i_global: "953,41",
    t_global: "9,5",
    z_global: "177,25",
  },
  {
    profile_section_id: 8143,
    name: "146x10",
    type: "Pipe",
    designation: "146x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "42,73",
    d_global: 146,
    i_global: "993,16",
    t_global: 10,
    z_global: "185,25",
  },
  {
    profile_section_id: 8144,
    name: "146x11",
    type: "Pipe",
    designation: "146x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "46,65",
    d_global: 146,
    i_global: "1069,86",
    t_global: 11,
    z_global: "200,87",
  },
  {
    profile_section_id: 8145,
    name: "146x12",
    type: "Pipe",
    designation: "146x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "50,52",
    d_global: 146,
    i_global: "1142,94",
    t_global: 12,
    z_global: "215,99",
  },
  {
    profile_section_id: 8146,
    name: "146x13",
    type: "Pipe",
    designation: "146x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "54,32",
    d_global: 146,
    i_global: "1212,52",
    t_global: 13,
    z_global: "230,63",
  },
  {
    profile_section_id: 8147,
    name: "146x14",
    type: "Pipe",
    designation: "146x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "58,06",
    d_global: 146,
    i_global: "1278,7",
    t_global: 14,
    z_global: "244,79",
  },
  {
    profile_section_id: 8148,
    name: "146x15",
    type: "Pipe",
    designation: "146x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "61,73",
    d_global: 146,
    i_global: "1341,6",
    t_global: 15,
    z_global: "258,48",
  },
  {
    profile_section_id: 8149,
    name: "146x16",
    type: "Pipe",
    designation: "146x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "65,35",
    d_global: 146,
    i_global: "1401,33",
    t_global: 16,
    z_global: "271,7",
  },
  {
    profile_section_id: 8150,
    name: "146x17",
    type: "Pipe",
    designation: "146x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "68,9",
    d_global: 146,
    i_global: "1457,99",
    t_global: 17,
    z_global: "284,46",
  },
  {
    profile_section_id: 8151,
    name: "146x18",
    type: "Pipe",
    designation: "146x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "72,38",
    d_global: 146,
    i_global: "1511,7",
    t_global: 18,
    z_global: "296,78",
  },
  {
    profile_section_id: 8152,
    name: "146x19",
    type: "Pipe",
    designation: "146x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "75,81",
    d_global: 146,
    i_global: "1562,56",
    t_global: 19,
    z_global: "308,66",
  },
  {
    profile_section_id: 8153,
    name: "146x20",
    type: "Pipe",
    designation: "146x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "79,17",
    d_global: 146,
    i_global: "1610,68",
    t_global: 20,
    z_global: "320,11",
  },
  {
    profile_section_id: 8154,
    name: "146x22",
    type: "Pipe",
    designation: "146x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "85,7",
    d_global: 146,
    i_global: "1699,06",
    t_global: 22,
    z_global: "341,74",
  },
  {
    profile_section_id: 8155,
    name: "146x24",
    type: "Pipe",
    designation: "146x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "91,99",
    d_global: 146,
    i_global: "1777,63",
    t_global: 24,
    z_global: "361,73",
  },
  {
    profile_section_id: 8156,
    name: "146x25",
    type: "Pipe",
    designation: "146x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "95,03",
    d_global: 146,
    i_global: "1813,47",
    t_global: 25,
    z_global: "371,14",
  },
  {
    profile_section_id: 8157,
    name: "146x26",
    type: "Pipe",
    designation: "146x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "98,02",
    d_global: 146,
    i_global: "1847,14",
    t_global: 26,
    z_global: "380,16",
  },
  {
    profile_section_id: 8158,
    name: "146x28",
    type: "Pipe",
    designation: "146x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "103,8",
    d_global: 146,
    i_global: "1908,33",
    t_global: 28,
    z_global: "397,09",
  },
  {
    profile_section_id: 8159,
    name: "146x30",
    type: "Pipe",
    designation: "146x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "109,33",
    d_global: 146,
    i_global: "1961,88",
    t_global: 30,
    z_global: "412,58",
  },
  {
    profile_section_id: 8160,
    name: "146x32",
    type: "Pipe",
    designation: "146x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "114,61",
    d_global: 146,
    i_global: "2008,46",
    t_global: 32,
    z_global: "426,69",
  },
  {
    profile_section_id: 8161,
    name: "146x34",
    type: "Pipe",
    designation: "146x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "119,63",
    d_global: 146,
    i_global: "2048,7",
    t_global: 34,
    z_global: "439,49",
  },
  {
    profile_section_id: 8162,
    name: "146x35",
    type: "Pipe",
    designation: "146x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "122,05",
    d_global: 146,
    i_global: "2066,63",
    t_global: 35,
    z_global: "445,42",
  },
  {
    profile_section_id: 8163,
    name: "146x36",
    type: "Pipe",
    designation: "146x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "124,41",
    d_global: 146,
    i_global: "2083,2",
    t_global: 36,
    z_global: "451,04",
  },
  {
    profile_section_id: 8164,
    name: "152x4.5",
    type: "Pipe",
    designation: "152x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "20,85",
    d_global: 152,
    i_global: "567,61",
    t_global: "4,5",
    z_global: "97,91",
  },
  {
    profile_section_id: 8165,
    name: "152x5",
    type: "Pipe",
    designation: "152x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "23,09",
    d_global: 152,
    i_global: "624,43",
    t_global: 5,
    z_global: "108,06",
  },
  {
    profile_section_id: 8166,
    name: "152x5.5",
    type: "Pipe",
    designation: "152x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "25,31",
    d_global: 152,
    i_global: "680,06",
    t_global: "5,5",
    z_global: "118,07",
  },
  {
    profile_section_id: 8167,
    name: "152x6",
    type: "Pipe",
    designation: "152x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "27,52",
    d_global: 152,
    i_global: "734,52",
    t_global: 6,
    z_global: "127,94",
  },
  {
    profile_section_id: 8168,
    name: "152x6.5",
    type: "Pipe",
    designation: "152x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "29,71",
    d_global: 152,
    i_global: "787,82",
    t_global: "6,5",
    z_global: "137,66",
  },
  {
    profile_section_id: 8169,
    name: "152x7",
    type: "Pipe",
    designation: "152x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "31,89",
    d_global: 152,
    i_global: "839,99",
    t_global: 7,
    z_global: "147,25",
  },
  {
    profile_section_id: 8170,
    name: "152x7.5",
    type: "Pipe",
    designation: "152x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "34,05",
    d_global: 152,
    i_global: "891,03",
    t_global: "7,5",
    z_global: "156,7",
  },
  {
    profile_section_id: 8171,
    name: "152x8",
    type: "Pipe",
    designation: "152x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "36,19",
    d_global: 152,
    i_global: "940,97",
    t_global: 8,
    z_global: "166,02",
  },
  {
    profile_section_id: 8172,
    name: "152x8.5",
    type: "Pipe",
    designation: "152x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "38,32",
    d_global: 152,
    i_global: "989,82",
    t_global: "8,5",
    z_global: "175,19",
  },
  {
    profile_section_id: 8173,
    name: "152x9",
    type: "Pipe",
    designation: "152x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "40,43",
    d_global: 152,
    i_global: "1037,59",
    t_global: 9,
    z_global: "184,24",
  },
  {
    profile_section_id: 8174,
    name: "152x9.5",
    type: "Pipe",
    designation: "152x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "42,53",
    d_global: 152,
    i_global: "1084,31",
    t_global: "9,5",
    z_global: "193,15",
  },
  {
    profile_section_id: 8175,
    name: "152x10",
    type: "Pipe",
    designation: "152x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "44,61",
    d_global: 152,
    i_global: "1129,99",
    t_global: 10,
    z_global: "201,92",
  },
  {
    profile_section_id: 8176,
    name: "152x11",
    type: "Pipe",
    designation: "152x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "48,73",
    d_global: 152,
    i_global: "1218,27",
    t_global: 11,
    z_global: "219,08",
  },
  {
    profile_section_id: 8177,
    name: "152x12",
    type: "Pipe",
    designation: "152x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "52,78",
    d_global: 152,
    i_global: "1302,58",
    t_global: 12,
    z_global: "235,72",
  },
  {
    profile_section_id: 8178,
    name: "152x13",
    type: "Pipe",
    designation: "152x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "56,77",
    d_global: 152,
    i_global: "1383,03",
    t_global: 13,
    z_global: "251,84",
  },
  {
    profile_section_id: 8179,
    name: "152x14",
    type: "Pipe",
    designation: "152x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "60,7",
    d_global: 152,
    i_global: "1459,73",
    t_global: 14,
    z_global: "267,46",
  },
  {
    profile_section_id: 8180,
    name: "152x15",
    type: "Pipe",
    designation: "152x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "64,56",
    d_global: 152,
    i_global: "1532,81",
    t_global: 15,
    z_global: "282,59",
  },
  {
    profile_section_id: 8181,
    name: "152x16",
    type: "Pipe",
    designation: "152x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "68,36",
    d_global: 152,
    i_global: "1602,38",
    t_global: 16,
    z_global: "297,23",
  },
  {
    profile_section_id: 8182,
    name: "152x17",
    type: "Pipe",
    designation: "152x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "72,1",
    d_global: 152,
    i_global: "1668,56",
    t_global: 17,
    z_global: "311,38",
  },
  {
    profile_section_id: 8183,
    name: "152x18",
    type: "Pipe",
    designation: "152x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "75,78",
    d_global: 152,
    i_global: "1731,46",
    t_global: 18,
    z_global: "325,07",
  },
  {
    profile_section_id: 8184,
    name: "152x19",
    type: "Pipe",
    designation: "152x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "79,39",
    d_global: 152,
    i_global: "1791,19",
    t_global: 19,
    z_global: "338,29",
  },
  {
    profile_section_id: 8185,
    name: "152x20",
    type: "Pipe",
    designation: "152x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "82,94",
    d_global: 152,
    i_global: "1847,86",
    t_global: 20,
    z_global: "351,06",
  },
  {
    profile_section_id: 8186,
    name: "152x22",
    type: "Pipe",
    designation: "152x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "89,85",
    d_global: 152,
    i_global: "1952,43",
    t_global: 22,
    z_global: "375,26",
  },
  {
    profile_section_id: 8187,
    name: "152x24",
    type: "Pipe",
    designation: "152x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "96,51",
    d_global: 152,
    i_global: "2046,01",
    t_global: 24,
    z_global: "397,72",
  },
  {
    profile_section_id: 8188,
    name: "152x25",
    type: "Pipe",
    designation: "152x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "99,75",
    d_global: 152,
    i_global: "2088,92",
    t_global: 25,
    z_global: "408,33",
  },
  {
    profile_section_id: 8189,
    name: "152x26",
    type: "Pipe",
    designation: "152x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "102,92",
    d_global: 152,
    i_global: "2129,39",
    t_global: 26,
    z_global: "418,53",
  },
  {
    profile_section_id: 8190,
    name: "152x28",
    type: "Pipe",
    designation: "152x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "109,08",
    d_global: 152,
    i_global: "2203,34",
    t_global: 28,
    z_global: "437,74",
  },
  {
    profile_section_id: 8191,
    name: "152x30",
    type: "Pipe",
    designation: "152x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "114,98",
    d_global: 152,
    i_global: "2268,6",
    t_global: 30,
    z_global: "455,41",
  },
  {
    profile_section_id: 8192,
    name: "152x32",
    type: "Pipe",
    designation: "152x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "120,64",
    d_global: 152,
    i_global: "2325,89",
    t_global: 32,
    z_global: "471,6",
  },
  {
    profile_section_id: 8193,
    name: "152x34",
    type: "Pipe",
    designation: "152x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "126,04",
    d_global: 152,
    i_global: "2375,87",
    t_global: 34,
    z_global: "486,4",
  },
  {
    profile_section_id: 8194,
    name: "152x35",
    type: "Pipe",
    designation: "152x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "128,65",
    d_global: 152,
    i_global: "2398,32",
    t_global: 35,
    z_global: "493,28",
  },
  {
    profile_section_id: 8195,
    name: "152x36",
    type: "Pipe",
    designation: "152x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "131,19",
    d_global: 152,
    i_global: "2419,2",
    t_global: 36,
    z_global: "499,84",
  },
  {
    profile_section_id: 8196,
    name: "159x4.5",
    type: "Pipe",
    designation: "159x4.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "21,84",
    d_global: 159,
    i_global: "652,27",
    t_global: "4,5",
    z_global: "107,42",
  },
  {
    profile_section_id: 8197,
    name: "159x5",
    type: "Pipe",
    designation: "159x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "24,19",
    d_global: 159,
    i_global: "717,88",
    t_global: 5,
    z_global: "118,59",
  },
  {
    profile_section_id: 8198,
    name: "159x5.5",
    type: "Pipe",
    designation: "159x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "26,52",
    d_global: 159,
    i_global: "782,18",
    t_global: "5,5",
    z_global: "129,62",
  },
  {
    profile_section_id: 8199,
    name: "159x6",
    type: "Pipe",
    designation: "159x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "28,84",
    d_global: 159,
    i_global: "845,19",
    t_global: 6,
    z_global: "140,49",
  },
  {
    profile_section_id: 8200,
    name: "159x6.5",
    type: "Pipe",
    designation: "159x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "31,14",
    d_global: 159,
    i_global: "906,92",
    t_global: "6,5",
    z_global: "151,22",
  },
  {
    profile_section_id: 8201,
    name: "159x7",
    type: "Pipe",
    designation: "159x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "33,43",
    d_global: 159,
    i_global: "967,41",
    t_global: 7,
    z_global: "161,8",
  },
  {
    profile_section_id: 8202,
    name: "159x7.5",
    type: "Pipe",
    designation: "159x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "35,7",
    d_global: 159,
    i_global: "1026,65",
    t_global: "7,5",
    z_global: "172,24",
  },
  {
    profile_section_id: 8203,
    name: "159x8",
    type: "Pipe",
    designation: "159x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "37,95",
    d_global: 159,
    i_global: "1084,67",
    t_global: 8,
    z_global: "182,53",
  },
  {
    profile_section_id: 8204,
    name: "159x8.5",
    type: "Pipe",
    designation: "159x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "40,19",
    d_global: 159,
    i_global: "1141,49",
    t_global: "8,5",
    z_global: "192,68",
  },
  {
    profile_section_id: 8205,
    name: "159x9",
    type: "Pipe",
    designation: "159x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "42,41",
    d_global: 159,
    i_global: "1197,12",
    t_global: 9,
    z_global: "202,69",
  },
  {
    profile_section_id: 8206,
    name: "159x9.5",
    type: "Pipe",
    designation: "159x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "44,62",
    d_global: 159,
    i_global: "1251,58",
    t_global: "9,5",
    z_global: "212,56",
  },
  {
    profile_section_id: 8207,
    name: "159x10",
    type: "Pipe",
    designation: "159x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "46,81",
    d_global: 159,
    i_global: "1304,88",
    t_global: 10,
    z_global: "222,29",
  },
  {
    profile_section_id: 8208,
    name: "159x11",
    type: "Pipe",
    designation: "159x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "51,15",
    d_global: 159,
    i_global: "1408,09",
    t_global: 11,
    z_global: "241,33",
  },
  {
    profile_section_id: 8209,
    name: "159x12",
    type: "Pipe",
    designation: "159x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "55,42",
    d_global: 159,
    i_global: "1506,88",
    t_global: 12,
    z_global: "259,82",
  },
  {
    profile_section_id: 8210,
    name: "159x13",
    type: "Pipe",
    designation: "159x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "59,63",
    d_global: 159,
    i_global: "1601,37",
    t_global: 13,
    z_global: "277,77",
  },
  {
    profile_section_id: 8211,
    name: "159x14",
    type: "Pipe",
    designation: "159x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "63,77",
    d_global: 159,
    i_global: "1691,69",
    t_global: 14,
    z_global: "295,19",
  },
  {
    profile_section_id: 8212,
    name: "159x15",
    type: "Pipe",
    designation: "159x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "67,86",
    d_global: 159,
    i_global: "1777,97",
    t_global: 15,
    z_global: "312,09",
  },
  {
    profile_section_id: 8213,
    name: "159x16",
    type: "Pipe",
    designation: "159x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "71,88",
    d_global: 159,
    i_global: "1860,34",
    t_global: 16,
    z_global: "328,47",
  },
  {
    profile_section_id: 8214,
    name: "159x17",
    type: "Pipe",
    designation: "159x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "75,84",
    d_global: 159,
    i_global: "1938,89",
    t_global: 17,
    z_global: "344,34",
  },
  {
    profile_section_id: 8215,
    name: "159x18",
    type: "Pipe",
    designation: "159x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "79,73",
    d_global: 159,
    i_global: "2013,77",
    t_global: 18,
    z_global: "359,71",
  },
  {
    profile_section_id: 8216,
    name: "159x19",
    type: "Pipe",
    designation: "159x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "83,57",
    d_global: 159,
    i_global: "2085,09",
    t_global: 19,
    z_global: "374,59",
  },
  {
    profile_section_id: 8217,
    name: "159x20",
    type: "Pipe",
    designation: "159x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "87,34",
    d_global: 159,
    i_global: "2152,95",
    t_global: 20,
    z_global: "388,99",
  },
  {
    profile_section_id: 8218,
    name: "159x22",
    type: "Pipe",
    designation: "159x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "94,69",
    d_global: 159,
    i_global: "2278,78",
    t_global: 22,
    z_global: "416,36",
  },
  {
    profile_section_id: 8219,
    name: "159x24",
    type: "Pipe",
    designation: "159x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "101,79",
    d_global: 159,
    i_global: "2392,14",
    t_global: 24,
    z_global: "441,9",
  },
  {
    profile_section_id: 8220,
    name: "159x25",
    type: "Pipe",
    designation: "159x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "105,24",
    d_global: 159,
    i_global: "2444,41",
    t_global: 25,
    z_global: "453,99",
  },
  {
    profile_section_id: 8221,
    name: "159x26",
    type: "Pipe",
    designation: "159x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "108,64",
    d_global: 159,
    i_global: "2493,88",
    t_global: 26,
    z_global: "465,66",
  },
  {
    profile_section_id: 8222,
    name: "159x28",
    type: "Pipe",
    designation: "159x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "115,23",
    d_global: 159,
    i_global: "2584,83",
    t_global: 28,
    z_global: "487,7",
  },
  {
    profile_section_id: 8223,
    name: "159x30",
    type: "Pipe",
    designation: "159x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "121,58",
    d_global: 159,
    i_global: "2665,78",
    t_global: 30,
    z_global: "508,1",
  },
  {
    profile_section_id: 8224,
    name: "159x32",
    type: "Pipe",
    designation: "159x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "127,67",
    d_global: 159,
    i_global: "2737,5",
    t_global: 32,
    z_global: "526,92",
  },
  {
    profile_section_id: 8225,
    name: "159x34",
    type: "Pipe",
    designation: "159x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "133,52",
    d_global: 159,
    i_global: "2800,7",
    t_global: 34,
    z_global: "544,22",
  },
  {
    profile_section_id: 8226,
    name: "159x35",
    type: "Pipe",
    designation: "159x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "136,35",
    d_global: 159,
    i_global: "2829,33",
    t_global: 35,
    z_global: "552,31",
  },
  {
    profile_section_id: 8227,
    name: "159x36",
    type: "Pipe",
    designation: "159x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "139,11",
    d_global: 159,
    i_global: "2856,1",
    t_global: 36,
    z_global: "560,06",
  },
  {
    profile_section_id: 8228,
    name: "168x5",
    type: "Pipe",
    designation: "168x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "25,6",
    d_global: 168,
    i_global: "851,14",
    t_global: 5,
    z_global: "132,85",
  },
  {
    profile_section_id: 8229,
    name: "168x5.5",
    type: "Pipe",
    designation: "168x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "28,08",
    d_global: 168,
    i_global: "927,85",
    t_global: "5,5",
    z_global: "145,25",
  },
  {
    profile_section_id: 8230,
    name: "168x6",
    type: "Pipe",
    designation: "168x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "30,54",
    d_global: 168,
    i_global: "1003,12",
    t_global: 6,
    z_global: "157,5",
  },
  {
    profile_section_id: 8231,
    name: "168x6.5",
    type: "Pipe",
    designation: "168x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "32,98",
    d_global: 168,
    i_global: "1076,95",
    t_global: "6,5",
    z_global: "169,58",
  },
  {
    profile_section_id: 8232,
    name: "168x7",
    type: "Pipe",
    designation: "168x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "35,41",
    d_global: 168,
    i_global: "1149,36",
    t_global: 7,
    z_global: "181,52",
  },
  {
    profile_section_id: 8233,
    name: "168x7.5",
    type: "Pipe",
    designation: "168x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "37,82",
    d_global: 168,
    i_global: "1220,38",
    t_global: "7,5",
    z_global: "193,29",
  },
  {
    profile_section_id: 8234,
    name: "168x8",
    type: "Pipe",
    designation: "168x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "40,21",
    d_global: 168,
    i_global: "1290,01",
    t_global: 8,
    z_global: "204,92",
  },
  {
    profile_section_id: 8235,
    name: "168x8.5",
    type: "Pipe",
    designation: "168x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "42,59",
    d_global: 168,
    i_global: "1358,29",
    t_global: "8,5",
    z_global: "216,39",
  },
  {
    profile_section_id: 8236,
    name: "168x9",
    type: "Pipe",
    designation: "168x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "44,96",
    d_global: 168,
    i_global: "1425,22",
    t_global: 9,
    z_global: "227,72",
  },
  {
    profile_section_id: 8237,
    name: "168x9.5",
    type: "Pipe",
    designation: "168x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "47,3",
    d_global: 168,
    i_global: "1490,83",
    t_global: "9,5",
    z_global: "238,89",
  },
  {
    profile_section_id: 8238,
    name: "168x10",
    type: "Pipe",
    designation: "168x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "49,64",
    d_global: 168,
    i_global: "1555,13",
    t_global: 10,
    z_global: "249,91",
  },
  {
    profile_section_id: 8239,
    name: "168x11",
    type: "Pipe",
    designation: "168x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "54,26",
    d_global: 168,
    i_global: "1679,88",
    t_global: 11,
    z_global: "271,51",
  },
  {
    profile_section_id: 8240,
    name: "168x12",
    type: "Pipe",
    designation: "168x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "58,81",
    d_global: 168,
    i_global: "1799,61",
    t_global: 12,
    z_global: "292,53",
  },
  {
    profile_section_id: 8241,
    name: "168x13",
    type: "Pipe",
    designation: "168x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "63,3",
    d_global: 168,
    i_global: "1914,44",
    t_global: 13,
    z_global: "312,98",
  },
  {
    profile_section_id: 8242,
    name: "168x14",
    type: "Pipe",
    designation: "168x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "67,73",
    d_global: 168,
    i_global: "2024,53",
    t_global: 14,
    z_global: "332,86",
  },
  {
    profile_section_id: 8243,
    name: "168x15",
    type: "Pipe",
    designation: "168x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "72,1",
    d_global: 168,
    i_global: 2130,
    t_global: 15,
    z_global: "352,17",
  },
  {
    profile_section_id: 8244,
    name: "168x16",
    type: "Pipe",
    designation: "168x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "76,4",
    d_global: 168,
    i_global: "2230,98",
    t_global: 16,
    z_global: "370,94",
  },
  {
    profile_section_id: 8245,
    name: "168x17",
    type: "Pipe",
    designation: "168x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "80,64",
    d_global: 168,
    i_global: "2327,61",
    t_global: 17,
    z_global: "389,16",
  },
  {
    profile_section_id: 8246,
    name: "168x18",
    type: "Pipe",
    designation: "168x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "84,82",
    d_global: 168,
    i_global: 2420,
    t_global: 18,
    z_global: "406,84",
  },
  {
    profile_section_id: 8247,
    name: "168x19",
    type: "Pipe",
    designation: "168x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "88,94",
    d_global: 168,
    i_global: "2508,29",
    t_global: 19,
    z_global: 424,
  },
  {
    profile_section_id: 8248,
    name: "168x20",
    type: "Pipe",
    designation: "168x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "92,99",
    d_global: 168,
    i_global: "2592,59",
    t_global: 20,
    z_global: "440,64",
  },
  {
    profile_section_id: 8249,
    name: "168x22",
    type: "Pipe",
    designation: "168x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "100,91",
    d_global: 168,
    i_global: "2749,74",
    t_global: 22,
    z_global: "472,38",
  },
  {
    profile_section_id: 8250,
    name: "168x24",
    type: "Pipe",
    designation: "168x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "108,57",
    d_global: 168,
    i_global: "2892,4",
    t_global: 24,
    z_global: "502,15",
  },
  {
    profile_section_id: 8251,
    name: "168x25",
    type: "Pipe",
    designation: "168x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "112,31",
    d_global: 168,
    i_global: "2958,58",
    t_global: 25,
    z_global: "516,3",
  },
  {
    profile_section_id: 8252,
    name: "168x26",
    type: "Pipe",
    designation: "168x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "115,99",
    d_global: 168,
    i_global: "3021,48",
    t_global: 26,
    z_global: "529,99",
  },
  {
    profile_section_id: 8253,
    name: "168x28",
    type: "Pipe",
    designation: "168x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "123,15",
    d_global: 168,
    i_global: "3137,87",
    t_global: 28,
    z_global: "555,98",
  },
  {
    profile_section_id: 8254,
    name: "168x30",
    type: "Pipe",
    designation: "168x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "130,06",
    d_global: 168,
    i_global: "3242,44",
    t_global: 30,
    z_global: "580,17",
  },
  {
    profile_section_id: 8255,
    name: "168x32",
    type: "Pipe",
    designation: "168x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "136,72",
    d_global: 168,
    i_global: "3336,02",
    t_global: 32,
    z_global: "602,64",
  },
  {
    profile_section_id: 8256,
    name: "168x34",
    type: "Pipe",
    designation: "168x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "143,13",
    d_global: 168,
    i_global: "3419,4",
    t_global: 34,
    z_global: "623,45",
  },
  {
    profile_section_id: 8257,
    name: "168x35",
    type: "Pipe",
    designation: "168x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "146,24",
    d_global: 168,
    i_global: "3457,51",
    t_global: 35,
    z_global: "633,25",
  },
  {
    profile_section_id: 8258,
    name: "168x36",
    type: "Pipe",
    designation: "168x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "149,29",
    d_global: 168,
    i_global: "3493,35",
    t_global: 36,
    z_global: "642,66",
  },
  {
    profile_section_id: 8259,
    name: "168x38",
    type: "Pipe",
    designation: "168x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "155,19",
    d_global: 168,
    i_global: "3558,61",
    t_global: 38,
    z_global: "660,33",
  },
  {
    profile_section_id: 8260,
    name: "168x40",
    type: "Pipe",
    designation: "168x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "160,85",
    d_global: 168,
    i_global: "3615,9",
    t_global: 40,
    z_global: "676,52",
  },
  {
    profile_section_id: 8261,
    name: "168x42",
    type: "Pipe",
    designation: "168x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "166,25",
    d_global: 168,
    i_global: "3665,88",
    t_global: 42,
    z_global: "691,32",
  },
  {
    profile_section_id: 8262,
    name: "168x45",
    type: "Pipe",
    designation: "168x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "173,89",
    d_global: 168,
    i_global: "3728,57",
    t_global: 45,
    z_global: 711,
  },
  {
    profile_section_id: 8263,
    name: "180x5",
    type: "Pipe",
    designation: "180x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "27,49",
    d_global: 180,
    i_global: "1053,17",
    t_global: 5,
    z_global: "153,13",
  },
  {
    profile_section_id: 8264,
    name: "180x5.5",
    type: "Pipe",
    designation: "180x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "30,15",
    d_global: 180,
    i_global: "1148,79",
    t_global: "5,5",
    z_global: "167,49",
  },
  {
    profile_section_id: 8265,
    name: "180x6",
    type: "Pipe",
    designation: "180x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "32,8",
    d_global: 180,
    i_global: "1242,72",
    t_global: 6,
    z_global: "181,68",
  },
  {
    profile_section_id: 8266,
    name: "180x6.5",
    type: "Pipe",
    designation: "180x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "35,43",
    d_global: 180,
    i_global: 1335,
    t_global: "6,5",
    z_global: "195,71",
  },
  {
    profile_section_id: 8267,
    name: "180x7",
    type: "Pipe",
    designation: "180x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "38,04",
    d_global: 180,
    i_global: "1425,63",
    t_global: 7,
    z_global: "209,56",
  },
  {
    profile_section_id: 8268,
    name: "180x7.5",
    type: "Pipe",
    designation: "180x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "40,64",
    d_global: 180,
    i_global: "1514,64",
    t_global: "7,5",
    z_global: "223,26",
  },
  {
    profile_section_id: 8269,
    name: "180x8",
    type: "Pipe",
    designation: "180x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "43,23",
    d_global: 180,
    i_global: "1602,04",
    t_global: 8,
    z_global: "236,78",
  },
  {
    profile_section_id: 8270,
    name: "180x8.5",
    type: "Pipe",
    designation: "180x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "45,8",
    d_global: 180,
    i_global: "1687,86",
    t_global: "8,5",
    z_global: "250,15",
  },
  {
    profile_section_id: 8271,
    name: "180x9",
    type: "Pipe",
    designation: "180x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "48,35",
    d_global: 180,
    i_global: "1772,12",
    t_global: 9,
    z_global: "263,35",
  },
  {
    profile_section_id: 8272,
    name: "180x9.5",
    type: "Pipe",
    designation: "180x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "50,89",
    d_global: 180,
    i_global: "1854,83",
    t_global: "9,5",
    z_global: "276,38",
  },
  {
    profile_section_id: 8273,
    name: "180x10",
    type: "Pipe",
    designation: "180x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "53,41",
    d_global: 180,
    i_global: "1936,01",
    t_global: 10,
    z_global: "289,26",
  },
  {
    profile_section_id: 8274,
    name: "180x11",
    type: "Pipe",
    designation: "180x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "58,4",
    d_global: 180,
    i_global: "2093,86",
    t_global: 11,
    z_global: "314,54",
  },
  {
    profile_section_id: 8275,
    name: "180x12",
    type: "Pipe",
    designation: "180x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "63,33",
    d_global: 180,
    i_global: "2245,84",
    t_global: 12,
    z_global: "339,18",
  },
  {
    profile_section_id: 8276,
    name: "180x13",
    type: "Pipe",
    designation: "180x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "68,2",
    d_global: 180,
    i_global: "2392,08",
    t_global: 13,
    z_global: "363,2",
  },
  {
    profile_section_id: 8277,
    name: "180x14",
    type: "Pipe",
    designation: "180x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "73,01",
    d_global: 180,
    i_global: "2532,74",
    t_global: 14,
    z_global: "386,6",
  },
  {
    profile_section_id: 8278,
    name: "180x15",
    type: "Pipe",
    designation: "180x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "77,75",
    d_global: 180,
    i_global: "2667,95",
    t_global: 15,
    z_global: "409,4",
  },
  {
    profile_section_id: 8279,
    name: "180x16",
    type: "Pipe",
    designation: "180x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "82,44",
    d_global: 180,
    i_global: "2797,86",
    t_global: 16,
    z_global: "431,59",
  },
  {
    profile_section_id: 8280,
    name: "180x17",
    type: "Pipe",
    designation: "180x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "87,05",
    d_global: 180,
    i_global: "2922,61",
    t_global: 17,
    z_global: "453,2",
  },
  {
    profile_section_id: 8281,
    name: "180x18",
    type: "Pipe",
    designation: "180x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "91,61",
    d_global: 180,
    i_global: "3042,33",
    t_global: 18,
    z_global: "474,22",
  },
  {
    profile_section_id: 8282,
    name: "180x19",
    type: "Pipe",
    designation: "180x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "96,1",
    d_global: 180,
    i_global: "3157,17",
    t_global: 19,
    z_global: "494,66",
  },
  {
    profile_section_id: 8283,
    name: "180x20",
    type: "Pipe",
    designation: "180x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "100,53",
    d_global: 180,
    i_global: "3267,26",
    t_global: 20,
    z_global: "514,54",
  },
  {
    profile_section_id: 8284,
    name: "180x22",
    type: "Pipe",
    designation: "180x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "109,2",
    d_global: 180,
    i_global: "3473,71",
    t_global: 22,
    z_global: "552,62",
  },
  {
    profile_section_id: 8285,
    name: "180x24",
    type: "Pipe",
    designation: "180x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "117,62",
    d_global: 180,
    i_global: "3662,72",
    t_global: 24,
    z_global: "588,52",
  },
  {
    profile_section_id: 8286,
    name: "180x25",
    type: "Pipe",
    designation: "180x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "121,74",
    d_global: 180,
    i_global: "3751,01",
    t_global: 25,
    z_global: "605,68",
  },
  {
    profile_section_id: 8287,
    name: "180x26",
    type: "Pipe",
    designation: "180x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "125,79",
    d_global: 180,
    i_global: "3835,32",
    t_global: 26,
    z_global: "622,32",
  },
  {
    profile_section_id: 8288,
    name: "180x28",
    type: "Pipe",
    designation: "180x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "133,71",
    d_global: 180,
    i_global: "3992,47",
    t_global: 28,
    z_global: "654,07",
  },
  {
    profile_section_id: 8289,
    name: "180x30",
    type: "Pipe",
    designation: "180x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "141,37",
    d_global: 180,
    i_global: "4135,12",
    t_global: 30,
    z_global: "683,83",
  },
  {
    profile_section_id: 8290,
    name: "180x32",
    type: "Pipe",
    designation: "180x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "148,79",
    d_global: 180,
    i_global: "4264,2",
    t_global: 32,
    z_global: "711,67",
  },
  {
    profile_section_id: 8291,
    name: "180x34",
    type: "Pipe",
    designation: "180x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "155,95",
    d_global: 180,
    i_global: "4380,6",
    t_global: 34,
    z_global: "737,66",
  },
  {
    profile_section_id: 8292,
    name: "180x35",
    type: "Pipe",
    designation: "180x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "159,44",
    d_global: 180,
    i_global: "4434,31",
    t_global: 35,
    z_global: "749,98",
  },
  {
    profile_section_id: 8293,
    name: "180x36",
    type: "Pipe",
    designation: "180x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "162,86",
    d_global: 180,
    i_global: "4485,17",
    t_global: 36,
    z_global: "761,86",
  },
  {
    profile_section_id: 8294,
    name: "180x38",
    type: "Pipe",
    designation: "180x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "169,52",
    d_global: 180,
    i_global: "4578,74",
    t_global: 38,
    z_global: "784,33",
  },
  {
    profile_section_id: 8295,
    name: "180x40",
    type: "Pipe",
    designation: "180x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "175,93",
    d_global: 180,
    i_global: "4662,12",
    t_global: 40,
    z_global: "805,13",
  },
  {
    profile_section_id: 8296,
    name: "180x42",
    type: "Pipe",
    designation: "180x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "182,09",
    d_global: 180,
    i_global: "4736,07",
    t_global: 42,
    z_global: "824,34",
  },
  {
    profile_section_id: 8297,
    name: "180x45",
    type: "Pipe",
    designation: "180x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "190,85",
    d_global: 180,
    i_global: "4830,94",
    t_global: 45,
    z_global: "850,29",
  },
  {
    profile_section_id: 8298,
    name: "194x5",
    type: "Pipe",
    designation: "194x5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "29,69",
    d_global: 194,
    i_global: "1326,54",
    t_global: 5,
    z_global: "178,6",
  },
  {
    profile_section_id: 8299,
    name: "194x5.5",
    type: "Pipe",
    designation: "194x5.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "32,57",
    d_global: 194,
    i_global: "1447,86",
    t_global: "5,5",
    z_global: "195,43",
  },
  {
    profile_section_id: 8300,
    name: "194x6",
    type: "Pipe",
    designation: "194x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "35,44",
    d_global: 194,
    i_global: "1567,21",
    t_global: 6,
    z_global: "212,08",
  },
  {
    profile_section_id: 8301,
    name: "194x6.5",
    type: "Pipe",
    designation: "194x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "38,29",
    d_global: 194,
    i_global: "1684,61",
    t_global: "6,5",
    z_global: "228,55",
  },
  {
    profile_section_id: 8302,
    name: "194x7",
    type: "Pipe",
    designation: "194x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "41,12",
    d_global: 194,
    i_global: "1800,08",
    t_global: 7,
    z_global: "244,84",
  },
  {
    profile_section_id: 8303,
    name: "194x7.5",
    type: "Pipe",
    designation: "194x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "43,94",
    d_global: 194,
    i_global: "1913,64",
    t_global: "7,5",
    z_global: "260,94",
  },
  {
    profile_section_id: 8304,
    name: "194x8",
    type: "Pipe",
    designation: "194x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "46,75",
    d_global: 194,
    i_global: "2025,31",
    t_global: 8,
    z_global: "276,87",
  },
  {
    profile_section_id: 8305,
    name: "194x8.5",
    type: "Pipe",
    designation: "194x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "49,54",
    d_global: 194,
    i_global: "2135,12",
    t_global: "8,5",
    z_global: "292,62",
  },
  {
    profile_section_id: 8306,
    name: "194x9",
    type: "Pipe",
    designation: "194x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "52,31",
    d_global: 194,
    i_global: "2243,08",
    t_global: 9,
    z_global: "308,19",
  },
  {
    profile_section_id: 8307,
    name: "194x9.5",
    type: "Pipe",
    designation: "194x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "55,06",
    d_global: 194,
    i_global: "2349,21",
    t_global: "9,5",
    z_global: "323,59",
  },
  {
    profile_section_id: 8308,
    name: "194x10",
    type: "Pipe",
    designation: "194x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "57,81",
    d_global: 194,
    i_global: "2453,55",
    t_global: 10,
    z_global: "338,81",
  },
  {
    profile_section_id: 8309,
    name: "194x11",
    type: "Pipe",
    designation: "194x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "63,24",
    d_global: 194,
    i_global: "2656,88",
    t_global: 11,
    z_global: "368,73",
  },
  {
    profile_section_id: 8310,
    name: "194x12",
    type: "Pipe",
    designation: "194x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "68,61",
    d_global: 194,
    i_global: "2853,25",
    t_global: 12,
    z_global: "397,96",
  },
  {
    profile_section_id: 8311,
    name: "194x13",
    type: "Pipe",
    designation: "194x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "73,92",
    d_global: 194,
    i_global: "3042,8",
    t_global: 13,
    z_global: "426,52",
  },
  {
    profile_section_id: 8312,
    name: "194x14",
    type: "Pipe",
    designation: "194x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "79,17",
    d_global: 194,
    i_global: "3225,71",
    t_global: 14,
    z_global: "454,4",
  },
  {
    profile_section_id: 8313,
    name: "194x15",
    type: "Pipe",
    designation: "194x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "84,35",
    d_global: 194,
    i_global: "3402,12",
    t_global: 15,
    z_global: "481,62",
  },
  {
    profile_section_id: 8314,
    name: "194x16",
    type: "Pipe",
    designation: "194x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "89,47",
    d_global: 194,
    i_global: "3572,19",
    t_global: 16,
    z_global: "508,18",
  },
  {
    profile_section_id: 8315,
    name: "194x17",
    type: "Pipe",
    designation: "194x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "94,53",
    d_global: 194,
    i_global: "3736,08",
    t_global: 17,
    z_global: "534,1",
  },
  {
    profile_section_id: 8316,
    name: "194x18",
    type: "Pipe",
    designation: "194x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "99,53",
    d_global: 194,
    i_global: "3893,94",
    t_global: 18,
    z_global: "559,37",
  },
  {
    profile_section_id: 8317,
    name: "194x19",
    type: "Pipe",
    designation: "194x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "104,46",
    d_global: 194,
    i_global: "4045,92",
    t_global: 19,
    z_global: "584,02",
  },
  {
    profile_section_id: 8318,
    name: "194x20",
    type: "Pipe",
    designation: "194x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "109,33",
    d_global: 194,
    i_global: "4192,16",
    t_global: 20,
    z_global: "608,03",
  },
  {
    profile_section_id: 8319,
    name: "194x22",
    type: "Pipe",
    designation: "194x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "118,88",
    d_global: 194,
    i_global: "4468,02",
    t_global: 22,
    z_global: "654,23",
  },
  {
    profile_section_id: 8320,
    name: "194x24",
    type: "Pipe",
    designation: "194x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "128,18",
    d_global: 194,
    i_global: "4722,68",
    t_global: 24,
    z_global: "698,03",
  },
  {
    profile_section_id: 8321,
    name: "194x25",
    type: "Pipe",
    designation: "194x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "132,73",
    d_global: 194,
    i_global: "4842,41",
    t_global: 25,
    z_global: "719,05",
  },
  {
    profile_section_id: 8322,
    name: "194x26",
    type: "Pipe",
    designation: "194x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "137,22",
    d_global: 194,
    i_global: "4957,24",
    t_global: 26,
    z_global: "739,5",
  },
  {
    profile_section_id: 8323,
    name: "194x28",
    type: "Pipe",
    designation: "194x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "146,02",
    d_global: 194,
    i_global: "5172,8",
    t_global: 28,
    z_global: "778,69",
  },
  {
    profile_section_id: 8324,
    name: "194x30",
    type: "Pipe",
    designation: "194x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "154,57",
    d_global: 194,
    i_global: "5370,41",
    t_global: 30,
    z_global: "815,68",
  },
  {
    profile_section_id: 8325,
    name: "194x32",
    type: "Pipe",
    designation: "194x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "162,86",
    d_global: 194,
    i_global: "5551,09",
    t_global: 32,
    z_global: "850,52",
  },
  {
    profile_section_id: 8326,
    name: "194x34",
    type: "Pipe",
    designation: "194x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "170,9",
    d_global: 194,
    i_global: "5715,84",
    t_global: 34,
    z_global: "883,28",
  },
  {
    profile_section_id: 8327,
    name: "194x35",
    type: "Pipe",
    designation: "194x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "174,83",
    d_global: 194,
    i_global: "5792,54",
    t_global: 35,
    z_global: "898,9",
  },
  {
    profile_section_id: 8328,
    name: "194x36",
    type: "Pipe",
    designation: "194x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "178,69",
    d_global: 194,
    i_global: "5865,62",
    t_global: 36,
    z_global: "914,03",
  },
  {
    profile_section_id: 8329,
    name: "194x38",
    type: "Pipe",
    designation: "194x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "186,23",
    d_global: 194,
    i_global: "6001,38",
    t_global: 38,
    z_global: "942,82",
  },
  {
    profile_section_id: 8330,
    name: "194x40",
    type: "Pipe",
    designation: "194x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "193,52",
    d_global: 194,
    i_global: "6124,01",
    t_global: 40,
    z_global: "969,73",
  },
  {
    profile_section_id: 8331,
    name: "194x42",
    type: "Pipe",
    designation: "194x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "200,56",
    d_global: 194,
    i_global: "6234,39",
    t_global: 42,
    z_global: "994,82",
  },
  {
    profile_section_id: 8332,
    name: "194x45",
    type: "Pipe",
    designation: "194x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "210,64",
    d_global: 194,
    i_global: "6378,82",
    t_global: 45,
    z_global: "1029,16",
  },
  {
    profile_section_id: 8333,
    name: "203x6",
    type: "Pipe",
    designation: "203x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "37,13",
    d_global: 203,
    i_global: "1803,07",
    t_global: 6,
    z_global: "232,87",
  },
  {
    profile_section_id: 8334,
    name: "203x6.5",
    type: "Pipe",
    designation: "203x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "40,13",
    d_global: 203,
    i_global: "1938,81",
    t_global: "6,5",
    z_global: "251,01",
  },
  {
    profile_section_id: 8335,
    name: "203x7",
    type: "Pipe",
    designation: "203x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "43,1",
    d_global: 203,
    i_global: "2072,43",
    t_global: 7,
    z_global: "268,96",
  },
  {
    profile_section_id: 8336,
    name: "203x7.5",
    type: "Pipe",
    designation: "203x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "46,06",
    d_global: 203,
    i_global: "2203,94",
    t_global: "7,5",
    z_global: "286,72",
  },
  {
    profile_section_id: 8337,
    name: "203x8",
    type: "Pipe",
    designation: "203x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "49,01",
    d_global: 203,
    i_global: "2333,37",
    t_global: 8,
    z_global: "304,29",
  },
  {
    profile_section_id: 8338,
    name: "203x8.5",
    type: "Pipe",
    designation: "203x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "51,94",
    d_global: 203,
    i_global: "2460,74",
    t_global: "8,5",
    z_global: "321,68",
  },
  {
    profile_section_id: 8339,
    name: "203x9",
    type: "Pipe",
    designation: "203x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "54,85",
    d_global: 203,
    i_global: "2586,08",
    t_global: 9,
    z_global: "338,88",
  },
  {
    profile_section_id: 8340,
    name: "203x9.5",
    type: "Pipe",
    designation: "203x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "57,75",
    d_global: 203,
    i_global: "2709,39",
    t_global: "9,5",
    z_global: "355,9",
  },
  {
    profile_section_id: 8341,
    name: "203x10",
    type: "Pipe",
    designation: "203x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "60,63",
    d_global: 203,
    i_global: "2830,72",
    t_global: 10,
    z_global: "372,73",
  },
  {
    profile_section_id: 8342,
    name: "203x11",
    type: "Pipe",
    designation: "203x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "66,35",
    d_global: 203,
    i_global: "3067,46",
    t_global: 11,
    z_global: "405,85",
  },
  {
    profile_section_id: 8343,
    name: "203x12",
    type: "Pipe",
    designation: "203x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "72,01",
    d_global: 203,
    i_global: "3296,49",
    t_global: 12,
    z_global: "438,24",
  },
  {
    profile_section_id: 8344,
    name: "203x13",
    type: "Pipe",
    designation: "203x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "77,6",
    d_global: 203,
    i_global: "3517,97",
    t_global: 13,
    z_global: "469,91",
  },
  {
    profile_section_id: 8345,
    name: "203x14",
    type: "Pipe",
    designation: "203x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "83,13",
    d_global: 203,
    i_global: "3732,07",
    t_global: 14,
    z_global: "500,88",
  },
  {
    profile_section_id: 8346,
    name: "203x15",
    type: "Pipe",
    designation: "203x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "88,59",
    d_global: 203,
    i_global: "3938,95",
    t_global: 15,
    z_global: "531,15",
  },
  {
    profile_section_id: 8347,
    name: "203x16",
    type: "Pipe",
    designation: "203x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 94,
    d_global: 203,
    i_global: "4138,78",
    t_global: 16,
    z_global: "560,73",
  },
  {
    profile_section_id: 8348,
    name: "203x17",
    type: "Pipe",
    designation: "203x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "99,34",
    d_global: 203,
    i_global: "4331,72",
    t_global: 17,
    z_global: "589,62",
  },
  {
    profile_section_id: 8349,
    name: "203x18",
    type: "Pipe",
    designation: "203x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "104,61",
    d_global: 203,
    i_global: "4517,93",
    t_global: 18,
    z_global: "617,84",
  },
  {
    profile_section_id: 8350,
    name: "203x19",
    type: "Pipe",
    designation: "203x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "109,83",
    d_global: 203,
    i_global: "4697,57",
    t_global: 19,
    z_global: "645,39",
  },
  {
    profile_section_id: 8351,
    name: "203x20",
    type: "Pipe",
    designation: "203x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "114,98",
    d_global: 203,
    i_global: "4870,79",
    t_global: 20,
    z_global: "672,28",
  },
  {
    profile_section_id: 8352,
    name: "203x22",
    type: "Pipe",
    designation: "203x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "125,1",
    d_global: 203,
    i_global: "5198,61",
    t_global: 22,
    z_global: "724,11",
  },
  {
    profile_section_id: 8353,
    name: "203x24",
    type: "Pipe",
    designation: "203x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "134,96",
    d_global: 203,
    i_global: "5502,6",
    t_global: 24,
    z_global: "773,4",
  },
  {
    profile_section_id: 8354,
    name: "203x25",
    type: "Pipe",
    designation: "203x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "139,8",
    d_global: 203,
    i_global: "5646,03",
    t_global: 25,
    z_global: "797,11",
  },
  {
    profile_section_id: 8355,
    name: "203x26",
    type: "Pipe",
    designation: "203x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "144,58",
    d_global: 203,
    i_global: "5783,95",
    t_global: 26,
    z_global: "820,21",
  },
  {
    profile_section_id: 8356,
    name: "203x28",
    type: "Pipe",
    designation: "203x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "153,94",
    d_global: 203,
    i_global: "6043,8",
    t_global: 28,
    z_global: "864,6",
  },
  {
    profile_section_id: 8357,
    name: "203x30",
    type: "Pipe",
    designation: "203x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "163,05",
    d_global: 203,
    i_global: "6283,28",
    t_global: 30,
    z_global: "906,64",
  },
  {
    profile_section_id: 8358,
    name: "203x32",
    type: "Pipe",
    designation: "203x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "171,91",
    d_global: 203,
    i_global: "6503,49",
    t_global: 32,
    z_global: "946,4",
  },
  {
    profile_section_id: 8359,
    name: "203x34",
    type: "Pipe",
    designation: "203x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "180,52",
    d_global: 203,
    i_global: "6705,49",
    t_global: 34,
    z_global: "983,93",
  },
  {
    profile_section_id: 8360,
    name: "203x35",
    type: "Pipe",
    designation: "203x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "184,73",
    d_global: 203,
    i_global: "6799,98",
    t_global: 35,
    z_global: "1001,88",
  },
  {
    profile_section_id: 8361,
    name: "203x36",
    type: "Pipe",
    designation: "203x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "188,87",
    d_global: 203,
    i_global: "6890,31",
    t_global: 36,
    z_global: "1019,3",
  },
  {
    profile_section_id: 8362,
    name: "203x38",
    type: "Pipe",
    designation: "203x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "196,98",
    d_global: 203,
    i_global: "7058,95",
    t_global: 38,
    z_global: "1052,58",
  },
  {
    profile_section_id: 8363,
    name: "203x40",
    type: "Pipe",
    designation: "203x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "204,83",
    d_global: 203,
    i_global: "7212,39",
    t_global: 40,
    z_global: "1083,82",
  },
  {
    profile_section_id: 8364,
    name: "203x42",
    type: "Pipe",
    designation: "203x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "212,43",
    d_global: 203,
    i_global: "7351,56",
    t_global: 42,
    z_global: "1113,1",
  },
  {
    profile_section_id: 8365,
    name: "203x45",
    type: "Pipe",
    designation: "203x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "223,37",
    d_global: 203,
    i_global: "7535,57",
    t_global: 45,
    z_global: "1153,47",
  },
  {
    profile_section_id: 8366,
    name: "203x48",
    type: "Pipe",
    designation: "203x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "233,73",
    d_global: 203,
    i_global: "7692,49",
    t_global: 48,
    z_global: "1189,77",
  },
  {
    profile_section_id: 8367,
    name: "203x50",
    type: "Pipe",
    designation: "203x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "240,33",
    d_global: 203,
    i_global: "7783,45",
    t_global: 50,
    z_global: "1211,81",
  },
  {
    profile_section_id: 8368,
    name: "219x6",
    type: "Pipe",
    designation: "219x6",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "40,15",
    d_global: 219,
    i_global: "2278,74",
    t_global: 6,
    z_global: "272,22",
  },
  {
    profile_section_id: 8369,
    name: "219x6.5",
    type: "Pipe",
    designation: "219x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "43,39",
    d_global: 219,
    i_global: "2451,64",
    t_global: "6,5",
    z_global: "293,53",
  },
  {
    profile_section_id: 8370,
    name: "219x7",
    type: "Pipe",
    designation: "219x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "46,62",
    d_global: 219,
    i_global: "2622,04",
    t_global: 7,
    z_global: "314,64",
  },
  {
    profile_section_id: 8371,
    name: "219x7.5",
    type: "Pipe",
    designation: "219x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "49,83",
    d_global: 219,
    i_global: "2789,96",
    t_global: "7,5",
    z_global: "335,55",
  },
  {
    profile_section_id: 8372,
    name: "219x8",
    type: "Pipe",
    designation: "219x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "53,03",
    d_global: 219,
    i_global: "2955,43",
    t_global: 8,
    z_global: "356,25",
  },
  {
    profile_section_id: 8373,
    name: "219x8.5",
    type: "Pipe",
    designation: "219x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "56,21",
    d_global: 219,
    i_global: "3118,48",
    t_global: "8,5",
    z_global: "376,75",
  },
  {
    profile_section_id: 8374,
    name: "219x9",
    type: "Pipe",
    designation: "219x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "59,38",
    d_global: 219,
    i_global: "3279,12",
    t_global: 9,
    z_global: "397,04",
  },
  {
    profile_section_id: 8375,
    name: "219x9.5",
    type: "Pipe",
    designation: "219x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "62,53",
    d_global: 219,
    i_global: "3437,38",
    t_global: "9,5",
    z_global: "417,14",
  },
  {
    profile_section_id: 8376,
    name: "219x10",
    type: "Pipe",
    designation: "219x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "65,66",
    d_global: 219,
    i_global: "3593,29",
    t_global: 10,
    z_global: "437,03",
  },
  {
    profile_section_id: 8377,
    name: "219x11",
    type: "Pipe",
    designation: "219x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "71,88",
    d_global: 219,
    i_global: "3898,12",
    t_global: 11,
    z_global: "476,23",
  },
  {
    profile_section_id: 8378,
    name: "219x12",
    type: "Pipe",
    designation: "219x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "78,04",
    d_global: 219,
    i_global: "4193,81",
    t_global: 12,
    z_global: "514,64",
  },
  {
    profile_section_id: 8379,
    name: "219x13",
    type: "Pipe",
    designation: "219x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "84,13",
    d_global: 219,
    i_global: "4480,55",
    t_global: 13,
    z_global: "552,26",
  },
  {
    profile_section_id: 8380,
    name: "219x14",
    type: "Pipe",
    designation: "219x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "90,16",
    d_global: 219,
    i_global: "4758,5",
    t_global: 14,
    z_global: "589,12",
  },
  {
    profile_section_id: 8381,
    name: "219x15",
    type: "Pipe",
    designation: "219x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "96,13",
    d_global: 219,
    i_global: "5027,86",
    t_global: 15,
    z_global: "625,21",
  },
  {
    profile_section_id: 8382,
    name: "219x16",
    type: "Pipe",
    designation: "219x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "102,04",
    d_global: 219,
    i_global: "5288,81",
    t_global: 16,
    z_global: "660,54",
  },
  {
    profile_section_id: 8383,
    name: "219x17",
    type: "Pipe",
    designation: "219x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "107,88",
    d_global: 219,
    i_global: "5541,51",
    t_global: 17,
    z_global: "695,13",
  },
  {
    profile_section_id: 8384,
    name: "219x18",
    type: "Pipe",
    designation: "219x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "113,66",
    d_global: 219,
    i_global: "5786,15",
    t_global: 18,
    z_global: "728,98",
  },
  {
    profile_section_id: 8385,
    name: "219x19",
    type: "Pipe",
    designation: "219x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "119,38",
    d_global: 219,
    i_global: "6022,9",
    t_global: 19,
    z_global: "762,1",
  },
  {
    profile_section_id: 8386,
    name: "219x20",
    type: "Pipe",
    designation: "219x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "125,04",
    d_global: 219,
    i_global: "6251,93",
    t_global: 20,
    z_global: "794,49",
  },
  {
    profile_section_id: 8387,
    name: "219x22",
    type: "Pipe",
    designation: "219x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "136,16",
    d_global: 219,
    i_global: "6687,5",
    t_global: 22,
    z_global: "857,13",
  },
  {
    profile_section_id: 8388,
    name: "219x24",
    type: "Pipe",
    designation: "219x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "147,03",
    d_global: 219,
    i_global: "7094,21",
    t_global: 24,
    z_global: "916,98",
  },
  {
    profile_section_id: 8389,
    name: "219x25",
    type: "Pipe",
    designation: "219x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "152,37",
    d_global: 219,
    i_global: "7287,15",
    t_global: 25,
    z_global: "945,87",
  },
  {
    profile_section_id: 8390,
    name: "219x26",
    type: "Pipe",
    designation: "219x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "157,65",
    d_global: 219,
    i_global: "7473,36",
    t_global: 26,
    z_global: "974,09",
  },
  {
    profile_section_id: 8391,
    name: "219x28",
    type: "Pipe",
    designation: "219x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "168,01",
    d_global: 219,
    i_global: "7826,23",
    t_global: 28,
    z_global: "1028,53",
  },
  {
    profile_section_id: 8392,
    name: "219x30",
    type: "Pipe",
    designation: "219x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "178,13",
    d_global: 219,
    i_global: "8154,05",
    t_global: 30,
    z_global: "1080,36",
  },
  {
    profile_section_id: 8393,
    name: "219x32",
    type: "Pipe",
    designation: "219x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "187,99",
    d_global: 219,
    i_global: "8458,04",
    t_global: 32,
    z_global: "1129,65",
  },
  {
    profile_section_id: 8394,
    name: "219x34",
    type: "Pipe",
    designation: "219x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "197,61",
    d_global: 219,
    i_global: "8739,38",
    t_global: 34,
    z_global: "1176,46",
  },
  {
    profile_section_id: 8395,
    name: "219x35",
    type: "Pipe",
    designation: "219x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "202,32",
    d_global: 219,
    i_global: "8871,92",
    t_global: 35,
    z_global: "1198,95",
  },
  {
    profile_section_id: 8396,
    name: "219x36",
    type: "Pipe",
    designation: "219x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "206,97",
    d_global: 219,
    i_global: "8999,23",
    t_global: 36,
    z_global: "1220,85",
  },
  {
    profile_section_id: 8397,
    name: "219x38",
    type: "Pipe",
    designation: "219x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "216,08",
    d_global: 219,
    i_global: "9238,72",
    t_global: 38,
    z_global: "1262,89",
  },
  {
    profile_section_id: 8398,
    name: "219x40",
    type: "Pipe",
    designation: "219x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "224,94",
    d_global: 219,
    i_global: "9458,93",
    t_global: 40,
    z_global: "1302,65",
  },
  {
    profile_section_id: 8399,
    name: "219x42",
    type: "Pipe",
    designation: "219x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "233,55",
    d_global: 219,
    i_global: "9660,92",
    t_global: 42,
    z_global: "1340,18",
  },
  {
    profile_section_id: 8400,
    name: "219x45",
    type: "Pipe",
    designation: "219x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "245,99",
    d_global: 219,
    i_global: "9932,02",
    t_global: 45,
    z_global: "1392,45",
  },
  {
    profile_section_id: 8401,
    name: "219x48",
    type: "Pipe",
    designation: "219x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "257,86",
    d_global: 219,
    i_global: "10167,82",
    t_global: 48,
    z_global: "1440,07",
  },
  {
    profile_section_id: 8402,
    name: "219x50",
    type: "Pipe",
    designation: "219x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "265,46",
    d_global: 219,
    i_global: "10306,99",
    t_global: 50,
    z_global: "1469,35",
  },
  {
    profile_section_id: 8403,
    name: "245x6.5",
    type: "Pipe",
    designation: "245x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "48,7",
    d_global: 245,
    i_global: "3465,46",
    t_global: "6,5",
    z_global: "369,73",
  },
  {
    profile_section_id: 8404,
    name: "245x7",
    type: "Pipe",
    designation: "245x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "52,34",
    d_global: 245,
    i_global: "3709,06",
    t_global: 7,
    z_global: "396,52",
  },
  {
    profile_section_id: 8405,
    name: "245x7.5",
    type: "Pipe",
    designation: "245x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "55,96",
    d_global: 245,
    i_global: "3949,53",
    t_global: "7,5",
    z_global: "423,08",
  },
  {
    profile_section_id: 8406,
    name: "245x8",
    type: "Pipe",
    designation: "245x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "59,56",
    d_global: 245,
    i_global: "4186,87",
    t_global: 8,
    z_global: "449,41",
  },
  {
    profile_section_id: 8407,
    name: "245x8.5",
    type: "Pipe",
    designation: "245x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "63,15",
    d_global: 245,
    i_global: "4421,13",
    t_global: "8,5",
    z_global: "475,51",
  },
  {
    profile_section_id: 8408,
    name: "245x9",
    type: "Pipe",
    designation: "245x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "66,73",
    d_global: 245,
    i_global: "4652,32",
    t_global: 9,
    z_global: "501,38",
  },
  {
    profile_section_id: 8409,
    name: "245x9.5",
    type: "Pipe",
    designation: "245x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "70,29",
    d_global: 245,
    i_global: "4880,48",
    t_global: "9,5",
    z_global: "527,03",
  },
  {
    profile_section_id: 8410,
    name: "245x10",
    type: "Pipe",
    designation: "245x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "73,83",
    d_global: 245,
    i_global: "5105,63",
    t_global: 10,
    z_global: "552,45",
  },
  {
    profile_section_id: 8411,
    name: "245x11",
    type: "Pipe",
    designation: "245x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "80,86",
    d_global: 245,
    i_global: "5547,01",
    t_global: 11,
    z_global: "602,61",
  },
  {
    profile_section_id: 8412,
    name: "245x12",
    type: "Pipe",
    designation: "245x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "87,84",
    d_global: 245,
    i_global: "5976,67",
    t_global: 12,
    z_global: "651,88",
  },
  {
    profile_section_id: 8413,
    name: "245x13",
    type: "Pipe",
    designation: "245x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "94,75",
    d_global: 245,
    i_global: "6394,82",
    t_global: 13,
    z_global: "700,27",
  },
  {
    profile_section_id: 8414,
    name: "245x14",
    type: "Pipe",
    designation: "245x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "101,6",
    d_global: 245,
    i_global: "6801,68",
    t_global: 14,
    z_global: "747,78",
  },
  {
    profile_section_id: 8415,
    name: "245x15",
    type: "Pipe",
    designation: "245x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "108,38",
    d_global: 245,
    i_global: "7197,44",
    t_global: 15,
    z_global: "794,43",
  },
  {
    profile_section_id: 8416,
    name: "245x16",
    type: "Pipe",
    designation: "245x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "115,11",
    d_global: 245,
    i_global: "7582,31",
    t_global: 16,
    z_global: "840,21",
  },
  {
    profile_section_id: 8417,
    name: "245x17",
    type: "Pipe",
    designation: "245x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "121,77",
    d_global: 245,
    i_global: "7956,48",
    t_global: 17,
    z_global: "885,14",
  },
  {
    profile_section_id: 8418,
    name: "245x18",
    type: "Pipe",
    designation: "245x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "128,37",
    d_global: 245,
    i_global: "8320,17",
    t_global: 18,
    z_global: "929,23",
  },
  {
    profile_section_id: 8419,
    name: "245x19",
    type: "Pipe",
    designation: "245x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "134,9",
    d_global: 245,
    i_global: "8673,56",
    t_global: 19,
    z_global: "972,49",
  },
  {
    profile_section_id: 8420,
    name: "245x20",
    type: "Pipe",
    designation: "245x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "141,37",
    d_global: 245,
    i_global: "9016,86",
    t_global: 20,
    z_global: "1014,91",
  },
  {
    profile_section_id: 8421,
    name: "245x22",
    type: "Pipe",
    designation: "245x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "154,13",
    d_global: 245,
    i_global: "9673,94",
    t_global: 22,
    z_global: "1097,31",
  },
  {
    profile_section_id: 8422,
    name: "245x24",
    type: "Pipe",
    designation: "245x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "166,63",
    d_global: 245,
    i_global: "10292,95",
    t_global: 24,
    z_global: "1176,5",
  },
  {
    profile_section_id: 8423,
    name: "245x25",
    type: "Pipe",
    designation: "245x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "172,79",
    d_global: 245,
    i_global: "10588,64",
    t_global: 25,
    z_global: "1214,9",
  },
  {
    profile_section_id: 8424,
    name: "245x26",
    type: "Pipe",
    designation: "245x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "178,88",
    d_global: 245,
    i_global: "10875,37",
    t_global: 26,
    z_global: "1252,53",
  },
  {
    profile_section_id: 8425,
    name: "245x28",
    type: "Pipe",
    designation: "245x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "190,88",
    d_global: 245,
    i_global: "11422,69",
    t_global: 28,
    z_global: "1325,48",
  },
  {
    profile_section_id: 8426,
    name: "245x30",
    type: "Pipe",
    designation: "245x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "202,63",
    d_global: 245,
    i_global: "11936,33",
    t_global: 30,
    z_global: "1395,4",
  },
  {
    profile_section_id: 8427,
    name: "245x32",
    type: "Pipe",
    designation: "245x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "214,13",
    d_global: 245,
    i_global: "12417,72",
    t_global: 32,
    z_global: "1462,37",
  },
  {
    profile_section_id: 8428,
    name: "245x34",
    type: "Pipe",
    designation: "245x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "225,38",
    d_global: 245,
    i_global: "12868,23",
    t_global: 34,
    z_global: "1526,43",
  },
  {
    profile_section_id: 8429,
    name: "245x35",
    type: "Pipe",
    designation: "245x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "230,91",
    d_global: 245,
    i_global: "13082,33",
    t_global: 35,
    z_global: "1557,4",
  },
  {
    profile_section_id: 8430,
    name: "245x36",
    type: "Pipe",
    designation: "245x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "236,37",
    d_global: 245,
    i_global: "13289,21",
    t_global: 36,
    z_global: "1587,67",
  },
  {
    profile_section_id: 8431,
    name: "245x38",
    type: "Pipe",
    designation: "245x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "247,12",
    d_global: 245,
    i_global: "13681,98",
    t_global: 38,
    z_global: "1646,14",
  },
  {
    profile_section_id: 8432,
    name: "245x40",
    type: "Pipe",
    designation: "245x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "257,61",
    d_global: 245,
    i_global: "14047,83",
    t_global: 40,
    z_global: "1701,91",
  },
  {
    profile_section_id: 8433,
    name: "245x42",
    type: "Pipe",
    designation: "245x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "267,85",
    d_global: 245,
    i_global: "14388,02",
    t_global: 42,
    z_global: "1755,04",
  },
  {
    profile_section_id: 8434,
    name: "245x45",
    type: "Pipe",
    designation: "245x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "282,74",
    d_global: 245,
    i_global: "14852,86",
    t_global: 45,
    z_global: "1829,92",
  },
  {
    profile_section_id: 8435,
    name: "245x48",
    type: "Pipe",
    designation: "245x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "297,07",
    d_global: 245,
    i_global: "15266,75",
    t_global: 48,
    z_global: "1899,22",
  },
  {
    profile_section_id: 8436,
    name: "245x50",
    type: "Pipe",
    designation: "245x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "306,31",
    d_global: 245,
    i_global: "15516,28",
    t_global: 50,
    z_global: "1942,43",
  },
  {
    profile_section_id: 8437,
    name: "273x6.5",
    type: "Pipe",
    designation: "273x6.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "54,42",
    d_global: 273,
    i_global: "4834,18",
    t_global: "6,5",
    z_global: "461,62",
  },
  {
    profile_section_id: 8438,
    name: "273x7",
    type: "Pipe",
    designation: "273x7",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "58,5",
    d_global: 273,
    i_global: "5177,3",
    t_global: 7,
    z_global: "495,28",
  },
  {
    profile_section_id: 8439,
    name: "273x7.5",
    type: "Pipe",
    designation: "273x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "62,56",
    d_global: 273,
    i_global: "5516,47",
    t_global: "7,5",
    z_global: "528,69",
  },
  {
    profile_section_id: 8440,
    name: "273x8",
    type: "Pipe",
    designation: "273x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "66,6",
    d_global: 273,
    i_global: "5851,71",
    t_global: 8,
    z_global: "561,83",
  },
  {
    profile_section_id: 8441,
    name: "273x8.5",
    type: "Pipe",
    designation: "273x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "70,63",
    d_global: 273,
    i_global: "6183,07",
    t_global: "8,5",
    z_global: "594,72",
  },
  {
    profile_section_id: 8442,
    name: "273x9",
    type: "Pipe",
    designation: "273x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "74,64",
    d_global: 273,
    i_global: "6510,56",
    t_global: 9,
    z_global: "627,35",
  },
  {
    profile_section_id: 8443,
    name: "273x9.5",
    type: "Pipe",
    designation: "273x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "78,64",
    d_global: 273,
    i_global: "6834,23",
    t_global: "9,5",
    z_global: "659,73",
  },
  {
    profile_section_id: 8444,
    name: "273x10",
    type: "Pipe",
    designation: "273x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "82,62",
    d_global: 273,
    i_global: "7154,09",
    t_global: 10,
    z_global: "691,85",
  },
  {
    profile_section_id: 8445,
    name: "273x11",
    type: "Pipe",
    designation: "273x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "90,54",
    d_global: 273,
    i_global: "7782,54",
    t_global: 11,
    z_global: "755,34",
  },
  {
    profile_section_id: 8446,
    name: "273x12",
    type: "Pipe",
    designation: "273x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "98,39",
    d_global: 273,
    i_global: "8396,14",
    t_global: 12,
    z_global: "817,82",
  },
  {
    profile_section_id: 8447,
    name: "273x13",
    type: "Pipe",
    designation: "273x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "106,19",
    d_global: 273,
    i_global: "8995,14",
    t_global: 13,
    z_global: "879,31",
  },
  {
    profile_section_id: 8448,
    name: "273x14",
    type: "Pipe",
    designation: "273x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "113,91",
    d_global: 273,
    i_global: "9579,75",
    t_global: 14,
    z_global: "939,81",
  },
  {
    profile_section_id: 8449,
    name: "273x15",
    type: "Pipe",
    designation: "273x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "121,58",
    d_global: 273,
    i_global: "10150,23",
    t_global: 15,
    z_global: "999,34",
  },
  {
    profile_section_id: 8450,
    name: "273x16",
    type: "Pipe",
    designation: "273x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "129,18",
    d_global: 273,
    i_global: "10706,79",
    t_global: 16,
    z_global: "1057,88",
  },
  {
    profile_section_id: 8451,
    name: "273x17",
    type: "Pipe",
    designation: "273x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "136,72",
    d_global: 273,
    i_global: "11249,67",
    t_global: 17,
    z_global: "1115,47",
  },
  {
    profile_section_id: 8452,
    name: "273x18",
    type: "Pipe",
    designation: "273x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "144,2",
    d_global: 273,
    i_global: "11779,08",
    t_global: 18,
    z_global: "1172,1",
  },
  {
    profile_section_id: 8453,
    name: "273x19",
    type: "Pipe",
    designation: "273x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "151,61",
    d_global: 273,
    i_global: "12295,27",
    t_global: 19,
    z_global: "1227,78",
  },
  {
    profile_section_id: 8454,
    name: "273x20",
    type: "Pipe",
    designation: "273x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "158,96",
    d_global: 273,
    i_global: "12798,44",
    t_global: 20,
    z_global: "1282,53",
  },
  {
    profile_section_id: 8455,
    name: "273x22",
    type: "Pipe",
    designation: "273x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "173,48",
    d_global: 273,
    i_global: "13766,62",
    t_global: 22,
    z_global: "1389,22",
  },
  {
    profile_section_id: 8456,
    name: "273x24",
    type: "Pipe",
    designation: "273x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "187,74",
    d_global: 273,
    i_global: "14685,38",
    t_global: 24,
    z_global: "1492,26",
  },
  {
    profile_section_id: 8457,
    name: "273x25",
    type: "Pipe",
    designation: "273x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "194,78",
    d_global: 273,
    i_global: "15126,76",
    t_global: 25,
    z_global: "1542,42",
  },
  {
    profile_section_id: 8458,
    name: "273x26",
    type: "Pipe",
    designation: "273x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "201,75",
    d_global: 273,
    i_global: "15556,42",
    t_global: 26,
    z_global: "1591,69",
  },
  {
    profile_section_id: 8459,
    name: "273x28",
    type: "Pipe",
    designation: "273x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "215,51",
    d_global: 273,
    i_global: "16381,43",
    t_global: 28,
    z_global: "1687,6",
  },
  {
    profile_section_id: 8460,
    name: "273x30",
    type: "Pipe",
    designation: "273x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "229,02",
    d_global: 273,
    i_global: "17162,06",
    t_global: 30,
    z_global: "1780,03",
  },
  {
    profile_section_id: 8461,
    name: "273x32",
    type: "Pipe",
    designation: "273x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "242,28",
    d_global: 273,
    i_global: "17899,92",
    t_global: 32,
    z_global: "1869,05",
  },
  {
    profile_section_id: 8462,
    name: "273x34",
    type: "Pipe",
    designation: "273x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "255,29",
    d_global: 273,
    i_global: "18596,62",
    t_global: 34,
    z_global: "1954,73",
  },
  {
    profile_section_id: 8463,
    name: "273x35",
    type: "Pipe",
    designation: "273x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "261,69",
    d_global: 273,
    i_global: "18930,01",
    t_global: 35,
    z_global: "1996,33",
  },
  {
    profile_section_id: 8464,
    name: "273x36",
    type: "Pipe",
    designation: "273x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "268,04",
    d_global: 273,
    i_global: "19253,7",
    t_global: 36,
    z_global: "2037,13",
  },
  {
    profile_section_id: 8465,
    name: "273x38",
    type: "Pipe",
    designation: "273x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "280,54",
    d_global: 273,
    i_global: "19872,7",
    t_global: 38,
    z_global: "2116,31",
  },
  {
    profile_section_id: 8466,
    name: "273x40",
    type: "Pipe",
    designation: "273x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "292,8",
    d_global: 273,
    i_global: "20455,13",
    t_global: 40,
    z_global: "2192,34",
  },
  {
    profile_section_id: 8467,
    name: "273x42",
    type: "Pipe",
    designation: "273x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "304,8",
    d_global: 273,
    i_global: "21002,44",
    t_global: 42,
    z_global: "2265,29",
  },
  {
    profile_section_id: 8468,
    name: "273x45",
    type: "Pipe",
    designation: "273x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "322,33",
    d_global: 273,
    i_global: "21760,73",
    t_global: 45,
    z_global: "2369,06",
  },
  {
    profile_section_id: 8469,
    name: "273x48",
    type: "Pipe",
    designation: "273x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "339,29",
    d_global: 273,
    i_global: "22447,98",
    t_global: 48,
    z_global: "2466,25",
  },
  {
    profile_section_id: 8470,
    name: "273x50",
    type: "Pipe",
    designation: "273x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "350,29",
    d_global: 273,
    i_global: "22868,96",
    t_global: 50,
    z_global: "2527,49",
  },
  {
    profile_section_id: 8471,
    name: "299x7.5",
    type: "Pipe",
    designation: "299x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "68,68",
    d_global: 299,
    i_global: "7300,02",
    t_global: "7,5",
    z_global: "637,27",
  },
  {
    profile_section_id: 8472,
    name: "299x8",
    type: "Pipe",
    designation: "299x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "73,14",
    d_global: 299,
    i_global: "7747,42",
    t_global: 8,
    z_global: "677,45",
  },
  {
    profile_section_id: 8473,
    name: "299x8.5",
    type: "Pipe",
    designation: "299x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "77,57",
    d_global: 299,
    i_global: "8190,09",
    t_global: "8,5",
    z_global: "717,34",
  },
  {
    profile_section_id: 8474,
    name: "299x9",
    type: "Pipe",
    designation: "299x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 82,
    d_global: 299,
    i_global: "8628,09",
    t_global: 9,
    z_global: "756,95",
  },
  {
    profile_section_id: 8475,
    name: "299x9.5",
    type: "Pipe",
    designation: "299x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "86,4",
    d_global: 299,
    i_global: "9061,43",
    t_global: "9,5",
    z_global: "796,28",
  },
  {
    profile_section_id: 8476,
    name: "299x10",
    type: "Pipe",
    designation: "299x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "90,79",
    d_global: 299,
    i_global: "9490,15",
    t_global: 10,
    z_global: "835,33",
  },
  {
    profile_section_id: 8477,
    name: "299x11",
    type: "Pipe",
    designation: "299x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "99,53",
    d_global: 299,
    i_global: "10333,87",
    t_global: 11,
    z_global: "912,6",
  },
  {
    profile_section_id: 8478,
    name: "299x12",
    type: "Pipe",
    designation: "299x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "108,2",
    d_global: 299,
    i_global: "11159,52",
    t_global: 12,
    z_global: "988,76",
  },
  {
    profile_section_id: 8479,
    name: "299x13",
    type: "Pipe",
    designation: "299x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "116,8",
    d_global: 299,
    i_global: "11967,34",
    t_global: 13,
    z_global: "1063,81",
  },
  {
    profile_section_id: 8480,
    name: "299x14",
    type: "Pipe",
    designation: "299x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "125,35",
    d_global: 299,
    i_global: "12757,61",
    t_global: 14,
    z_global: "1137,78",
  },
  {
    profile_section_id: 8481,
    name: "299x15",
    type: "Pipe",
    designation: "299x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "133,83",
    d_global: 299,
    i_global: "13530,57",
    t_global: 15,
    z_global: "1210,66",
  },
  {
    profile_section_id: 8482,
    name: "299x16",
    type: "Pipe",
    designation: "299x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "142,25",
    d_global: 299,
    i_global: "14286,48",
    t_global: 16,
    z_global: "1282,47",
  },
  {
    profile_section_id: 8483,
    name: "299x17",
    type: "Pipe",
    designation: "299x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "150,61",
    d_global: 299,
    i_global: "15025,59",
    t_global: 17,
    z_global: "1353,21",
  },
  {
    profile_section_id: 8484,
    name: "299x18",
    type: "Pipe",
    designation: "299x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "158,9",
    d_global: 299,
    i_global: "15748,16",
    t_global: 18,
    z_global: "1422,89",
  },
  {
    profile_section_id: 8485,
    name: "299x19",
    type: "Pipe",
    designation: "299x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "167,13",
    d_global: 299,
    i_global: "16454,43",
    t_global: 19,
    z_global: "1491,51",
  },
  {
    profile_section_id: 8486,
    name: "299x20",
    type: "Pipe",
    designation: "299x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "175,3",
    d_global: 299,
    i_global: "17144,64",
    t_global: 20,
    z_global: "1559,1",
  },
  {
    profile_section_id: 8487,
    name: "299x22",
    type: "Pipe",
    designation: "299x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "191,45",
    d_global: 299,
    i_global: "18477,91",
    t_global: 22,
    z_global: "1691,16",
  },
  {
    profile_section_id: 8488,
    name: "299x24",
    type: "Pipe",
    designation: "299x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "207,35",
    d_global: 299,
    i_global: "19749,88",
    t_global: 24,
    z_global: "1819,15",
  },
  {
    profile_section_id: 8489,
    name: "299x25",
    type: "Pipe",
    designation: "299x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "215,2",
    d_global: 299,
    i_global: "20363,48",
    t_global: 25,
    z_global: "1881,64",
  },
  {
    profile_section_id: 8490,
    name: "299x26",
    type: "Pipe",
    designation: "299x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "222,99",
    d_global: 299,
    i_global: "20962,48",
    t_global: 26,
    z_global: "1943,13",
  },
  {
    profile_section_id: 8491,
    name: "299x28",
    type: "Pipe",
    designation: "299x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "238,38",
    d_global: 299,
    i_global: "22117,57",
    t_global: 28,
    z_global: "2063,15",
  },
  {
    profile_section_id: 8492,
    name: "299x30",
    type: "Pipe",
    designation: "299x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "253,53",
    d_global: 299,
    i_global: "23217,01",
    t_global: 30,
    z_global: "2179,28",
  },
  {
    profile_section_id: 8493,
    name: "299x32",
    type: "Pipe",
    designation: "299x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "268,42",
    d_global: 299,
    i_global: "24262,61",
    t_global: 32,
    z_global: "2291,6",
  },
  {
    profile_section_id: 8494,
    name: "299x34",
    type: "Pipe",
    designation: "299x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "283,06",
    d_global: 299,
    i_global: "25256,16",
    t_global: 34,
    z_global: "2400,15",
  },
  {
    profile_section_id: 8495,
    name: "299x35",
    type: "Pipe",
    designation: "299x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "290,28",
    d_global: 299,
    i_global: "25733,96",
    t_global: 35,
    z_global: "2453,04",
  },
  {
    profile_section_id: 8496,
    name: "299x36",
    type: "Pipe",
    designation: "299x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "297,45",
    d_global: 299,
    i_global: "26199,41",
    t_global: 36,
    z_global: "2505,01",
  },
  {
    profile_section_id: 8497,
    name: "299x38",
    type: "Pipe",
    designation: "299x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "311,58",
    d_global: 299,
    i_global: "27094,1",
    t_global: 38,
    z_global: "2606,24",
  },
  {
    profile_section_id: 8498,
    name: "299x40",
    type: "Pipe",
    designation: "299x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "325,47",
    d_global: 299,
    i_global: "27941,92",
    t_global: 40,
    z_global: "2703,9",
  },
  {
    profile_section_id: 8499,
    name: "299x42",
    type: "Pipe",
    designation: "299x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "339,1",
    d_global: 299,
    i_global: "28744,53",
    t_global: 42,
    z_global: "2798,05",
  },
  {
    profile_section_id: 8500,
    name: "299x45",
    type: "Pipe",
    designation: "299x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "359,08",
    d_global: 299,
    i_global: "29867,26",
    t_global: 45,
    z_global: "2932,86",
  },
  {
    profile_section_id: 8501,
    name: "299x48",
    type: "Pipe",
    designation: "299x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "378,5",
    d_global: 299,
    i_global: "30897,35",
    t_global: 48,
    z_global: "3060,15",
  },
  {
    profile_section_id: 8502,
    name: "299x50",
    type: "Pipe",
    designation: "299x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "391,13",
    d_global: 299,
    i_global: "31535,21",
    t_global: 50,
    z_global: "3140,93",
  },
  {
    profile_section_id: 8503,
    name: "299x56",
    type: "Pipe",
    designation: "299x56",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "427,51",
    d_global: 299,
    i_global: "33230,73",
    t_global: 56,
    z_global: "3364,44",
  },
  {
    profile_section_id: 8504,
    name: "299x60",
    type: "Pipe",
    designation: "299x60",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "450,5",
    d_global: 299,
    i_global: "34193,85",
    t_global: 60,
    z_global: "3498,39",
  },
  {
    profile_section_id: 8505,
    name: "299x63",
    type: "Pipe",
    designation: "299x63",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "467,09",
    d_global: 299,
    i_global: "34836,3",
    t_global: 63,
    z_global: "3591,3",
  },
  {
    profile_section_id: 8506,
    name: "299x65",
    type: "Pipe",
    designation: "299x65",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "477,84",
    d_global: 299,
    i_global: "35229,07",
    t_global: 65,
    z_global: "3649,77",
  },
  {
    profile_section_id: 8507,
    name: "299x70",
    type: "Pipe",
    designation: "299x70",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "503,6",
    d_global: 299,
    i_global: "36095,96",
    t_global: 70,
    z_global: "3784,26",
  },
  {
    profile_section_id: 8508,
    name: "299x75",
    type: "Pipe",
    designation: "299x75",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "527,79",
    d_global: 299,
    i_global: "36813,84",
    t_global: 75,
    z_global: "3902,85",
  },
  {
    profile_section_id: 8509,
    name: "325x7.5",
    type: "Pipe",
    designation: "325x7.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "74,81",
    d_global: 325,
    i_global: "9431,8",
    t_global: "7,5",
    z_global: 756,
  },
  {
    profile_section_id: 8510,
    name: "325x8",
    type: "Pipe",
    designation: "325x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "79,67",
    d_global: 325,
    i_global: "10013,92",
    t_global: 8,
    z_global: "803,88",
  },
  {
    profile_section_id: 8511,
    name: "325x8.5",
    type: "Pipe",
    designation: "325x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "84,52",
    d_global: 325,
    i_global: "10590,42",
    t_global: "8,5",
    z_global: "851,46",
  },
  {
    profile_section_id: 8512,
    name: "325x9",
    type: "Pipe",
    designation: "325x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "89,35",
    d_global: 325,
    i_global: "11161,33",
    t_global: 9,
    z_global: "898,72",
  },
  {
    profile_section_id: 8513,
    name: "325x9.5",
    type: "Pipe",
    designation: "325x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "94,16",
    d_global: 325,
    i_global: "11726,68",
    t_global: "9,5",
    z_global: "945,68",
  },
  {
    profile_section_id: 8514,
    name: "325x10",
    type: "Pipe",
    designation: "325x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "98,96",
    d_global: 325,
    i_global: "12286,52",
    t_global: 10,
    z_global: "992,34",
  },
  {
    profile_section_id: 8515,
    name: "325x11",
    type: "Pipe",
    designation: "325x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "108,51",
    d_global: 325,
    i_global: "13389,8",
    t_global: 11,
    z_global: "1084,73",
  },
  {
    profile_section_id: 8516,
    name: "325x12",
    type: "Pipe",
    designation: "325x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 118,
    d_global: 325,
    i_global: "14471,45",
    t_global: 12,
    z_global: "1175,91",
  },
  {
    profile_section_id: 8517,
    name: "325x13",
    type: "Pipe",
    designation: "325x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "127,42",
    d_global: 325,
    i_global: "15531,75",
    t_global: 13,
    z_global: "1265,89",
  },
  {
    profile_section_id: 8518,
    name: "325x14",
    type: "Pipe",
    designation: "325x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "136,78",
    d_global: 325,
    i_global: "16570,98",
    t_global: 14,
    z_global: "1354,67",
  },
  {
    profile_section_id: 8519,
    name: "325x15",
    type: "Pipe",
    designation: "325x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "146,08",
    d_global: 325,
    i_global: "17589,43",
    t_global: 15,
    z_global: "1442,26",
  },
  {
    profile_section_id: 8520,
    name: "325x16",
    type: "Pipe",
    designation: "325x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "155,32",
    d_global: 325,
    i_global: "18587,38",
    t_global: 16,
    z_global: "1528,68",
  },
  {
    profile_section_id: 8521,
    name: "325x17",
    type: "Pipe",
    designation: "325x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "164,49",
    d_global: 325,
    i_global: "19565,1",
    t_global: 17,
    z_global: "1613,92",
  },
  {
    profile_section_id: 8522,
    name: "325x18",
    type: "Pipe",
    designation: "325x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "173,6",
    d_global: 325,
    i_global: "20522,86",
    t_global: 18,
    z_global: 1698,
  },
  {
    profile_section_id: 8523,
    name: "325x19",
    type: "Pipe",
    designation: "325x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "182,65",
    d_global: 325,
    i_global: "21460,95",
    t_global: 19,
    z_global: "1780,92",
  },
  {
    profile_section_id: 8524,
    name: "325x20",
    type: "Pipe",
    designation: "325x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "191,64",
    d_global: 325,
    i_global: "22379,63",
    t_global: 20,
    z_global: "1862,7",
  },
  {
    profile_section_id: 8525,
    name: "325x22",
    type: "Pipe",
    designation: "325x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "209,42",
    d_global: 325,
    i_global: "24159,83",
    t_global: 22,
    z_global: "2022,84",
  },
  {
    profile_section_id: 8526,
    name: "325x24",
    type: "Pipe",
    designation: "325x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "226,95",
    d_global: 325,
    i_global: "25865,62",
    t_global: 24,
    z_global: "2178,49",
  },
  {
    profile_section_id: 8527,
    name: "325x25",
    type: "Pipe",
    designation: "325x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "235,62",
    d_global: 325,
    i_global: "26691,27",
    t_global: 25,
    z_global: "2254,64",
  },
  {
    profile_section_id: 8528,
    name: "325x26",
    type: "Pipe",
    designation: "325x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "244,23",
    d_global: 325,
    i_global: "27499,09",
    t_global: 26,
    z_global: "2329,7",
  },
  {
    profile_section_id: 8529,
    name: "325x28",
    type: "Pipe",
    designation: "325x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "261,25",
    d_global: 325,
    i_global: "29062,32",
    t_global: 28,
    z_global: "2476,55",
  },
  {
    profile_section_id: 8530,
    name: "325x30",
    type: "Pipe",
    designation: "325x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "278,03",
    d_global: 325,
    i_global: "30557,34",
    t_global: 30,
    z_global: "2619,09",
  },
  {
    profile_section_id: 8531,
    name: "325x32",
    type: "Pipe",
    designation: "325x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "294,56",
    d_global: 325,
    i_global: "31986,17",
    t_global: 32,
    z_global: "2757,4",
  },
  {
    profile_section_id: 8532,
    name: "325x34",
    type: "Pipe",
    designation: "325x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "310,83",
    d_global: 325,
    i_global: "33350,8",
    t_global: 34,
    z_global: "2891,53",
  },
  {
    profile_section_id: 8533,
    name: "325x35",
    type: "Pipe",
    designation: "325x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "318,87",
    d_global: 325,
    i_global: "34009,66",
    t_global: 35,
    z_global: "2957,05",
  },
  {
    profile_section_id: 8534,
    name: "325x36",
    type: "Pipe",
    designation: "325x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "326,85",
    d_global: 325,
    i_global: "34653,18",
    t_global: 36,
    z_global: "3021,55",
  },
  {
    profile_section_id: 8535,
    name: "325x38",
    type: "Pipe",
    designation: "325x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "342,62",
    d_global: 325,
    i_global: "35895,23",
    t_global: 38,
    z_global: "3147,53",
  },
  {
    profile_section_id: 8536,
    name: "325x40",
    type: "Pipe",
    designation: "325x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "358,14",
    d_global: 325,
    i_global: "37078,84",
    t_global: 40,
    z_global: "3269,52",
  },
  {
    profile_section_id: 8537,
    name: "325x42",
    type: "Pipe",
    designation: "325x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "373,41",
    d_global: 325,
    i_global: "38205,88",
    t_global: 42,
    z_global: "3387,59",
  },
  {
    profile_section_id: 8538,
    name: "325x45",
    type: "Pipe",
    designation: "325x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "395,84",
    d_global: 325,
    i_global: "39794,36",
    t_global: 45,
    z_global: "3557,49",
  },
  {
    profile_section_id: 8539,
    name: "325x48",
    type: "Pipe",
    designation: "325x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "417,71",
    d_global: 325,
    i_global: "41265,71",
    t_global: 48,
    z_global: "3718,93",
  },
  {
    profile_section_id: 8540,
    name: "325x50",
    type: "Pipe",
    designation: "325x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "431,97",
    d_global: 325,
    i_global: "42184,47",
    t_global: 50,
    z_global: "3821,96",
  },
  {
    profile_section_id: 8541,
    name: "325x56",
    type: "Pipe",
    designation: "325x56",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "473,25",
    d_global: 325,
    i_global: "44661,15",
    t_global: 56,
    z_global: "4109,73",
  },
  {
    profile_section_id: 8542,
    name: "325x60",
    type: "Pipe",
    designation: "325x60",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "499,51",
    d_global: 325,
    i_global: "46095,71",
    t_global: 60,
    z_global: "4284,43",
  },
  {
    profile_section_id: 8543,
    name: "325x63",
    type: "Pipe",
    designation: "325x63",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "518,55",
    d_global: 325,
    i_global: "47066,96",
    t_global: 63,
    z_global: "4406,82",
  },
  {
    profile_section_id: 8544,
    name: "325x65",
    type: "Pipe",
    designation: "325x65",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "530,93",
    d_global: 325,
    i_global: "47667,48",
    t_global: 65,
    z_global: "4484,42",
  },
  {
    profile_section_id: 8545,
    name: "325x70",
    type: "Pipe",
    designation: "325x70",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "560,77",
    d_global: 325,
    i_global: "49015,18",
    t_global: 70,
    z_global: "4664,92",
  },
  {
    profile_section_id: 8546,
    name: "325x75",
    type: "Pipe",
    designation: "325x75",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "589,05",
    d_global: 325,
    i_global: "50161,17",
    t_global: 75,
    z_global: "4826,92",
  },
  {
    profile_section_id: 8547,
    name: "351x8",
    type: "Pipe",
    designation: "351x8",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "86,21",
    d_global: 351,
    i_global: "12684,36",
    t_global: 8,
    z_global: "941,13",
  },
  {
    profile_section_id: 8548,
    name: "351x8.5",
    type: "Pipe",
    designation: "351x8.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "91,46",
    d_global: 351,
    i_global: "13419,24",
    t_global: "8,5",
    z_global: "997,06",
  },
  {
    profile_section_id: 8549,
    name: "351x9",
    type: "Pipe",
    designation: "351x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "96,7",
    d_global: 351,
    i_global: "14147,55",
    t_global: 9,
    z_global: "1052,66",
  },
  {
    profile_section_id: 8550,
    name: "351x9.5",
    type: "Pipe",
    designation: "351x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "101,92",
    d_global: 351,
    i_global: "14869,34",
    t_global: "9,5",
    z_global: "1107,92",
  },
  {
    profile_section_id: 8551,
    name: "351x10",
    type: "Pipe",
    designation: "351x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "107,13",
    d_global: 351,
    i_global: "15584,63",
    t_global: 10,
    z_global: "1162,85",
  },
  {
    profile_section_id: 8552,
    name: "351x11",
    type: "Pipe",
    designation: "351x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "117,5",
    d_global: 351,
    i_global: "16995,88",
    t_global: 11,
    z_global: "1271,73",
  },
  {
    profile_section_id: 8553,
    name: "351x12",
    type: "Pipe",
    designation: "351x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "127,8",
    d_global: 351,
    i_global: "18381,63",
    t_global: 12,
    z_global: "1379,28",
  },
  {
    profile_section_id: 8554,
    name: "351x13",
    type: "Pipe",
    designation: "351x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "138,04",
    d_global: 351,
    i_global: "19742,19",
    t_global: 13,
    z_global: "1485,53",
  },
  {
    profile_section_id: 8555,
    name: "351x14",
    type: "Pipe",
    designation: "351x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "148,22",
    d_global: 351,
    i_global: "21077,86",
    t_global: 14,
    z_global: "1590,48",
  },
  {
    profile_section_id: 8556,
    name: "351x15",
    type: "Pipe",
    designation: "351x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "158,34",
    d_global: 351,
    i_global: "22388,95",
    t_global: 15,
    z_global: "1694,14",
  },
  {
    profile_section_id: 8557,
    name: "351x16",
    type: "Pipe",
    designation: "351x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "168,39",
    d_global: 351,
    i_global: "23675,76",
    t_global: 16,
    z_global: "1796,52",
  },
  {
    profile_section_id: 8558,
    name: "351x17",
    type: "Pipe",
    designation: "351x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "178,38",
    d_global: 351,
    i_global: "24938,59",
    t_global: 17,
    z_global: "1897,62",
  },
  {
    profile_section_id: 8559,
    name: "351x18",
    type: "Pipe",
    designation: "351x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "188,31",
    d_global: 351,
    i_global: "26177,74",
    t_global: 18,
    z_global: "1997,45",
  },
  {
    profile_section_id: 8560,
    name: "351x19",
    type: "Pipe",
    designation: "351x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "198,17",
    d_global: 351,
    i_global: "27393,52",
    t_global: 19,
    z_global: "2096,02",
  },
  {
    profile_section_id: 8561,
    name: "351x20",
    type: "Pipe",
    designation: "351x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "207,97",
    d_global: 351,
    i_global: "28586,21",
    t_global: 20,
    z_global: "2193,34",
  },
  {
    profile_section_id: 8562,
    name: "351x22",
    type: "Pipe",
    designation: "351x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "227,39",
    d_global: 351,
    i_global: "30903,52",
    t_global: 22,
    z_global: "2384,26",
  },
  {
    profile_section_id: 8563,
    name: "351x24",
    type: "Pipe",
    designation: "351x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "246,55",
    d_global: 351,
    i_global: "33131,99",
    t_global: 24,
    z_global: "2570,26",
  },
  {
    profile_section_id: 8564,
    name: "351x25",
    type: "Pipe",
    designation: "351x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "256,04",
    d_global: 351,
    i_global: "34213,64",
    t_global: 25,
    z_global: "2661,44",
  },
  {
    profile_section_id: 8565,
    name: "351x26",
    type: "Pipe",
    designation: "351x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "265,46",
    d_global: 351,
    i_global: "35273,94",
    t_global: 26,
    z_global: "2751,42",
  },
  {
    profile_section_id: 8566,
    name: "351x28",
    type: "Pipe",
    designation: "351x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "284,13",
    d_global: 351,
    i_global: "37331,62",
    t_global: 28,
    z_global: "2927,8",
  },
  {
    profile_section_id: 8567,
    name: "351x30",
    type: "Pipe",
    designation: "351x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "302,54",
    d_global: 351,
    i_global: "39307,29",
    t_global: 30,
    z_global: "3099,45",
  },
  {
    profile_section_id: 8568,
    name: "351x32",
    type: "Pipe",
    designation: "351x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "320,69",
    d_global: 351,
    i_global: "41203,14",
    t_global: 32,
    z_global: "3266,46",
  },
  {
    profile_section_id: 8569,
    name: "351x34",
    type: "Pipe",
    designation: "351x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "338,6",
    d_global: 351,
    i_global: "43021,36",
    t_global: 34,
    z_global: "3428,87",
  },
  {
    profile_section_id: 8570,
    name: "351x35",
    type: "Pipe",
    designation: "351x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "347,46",
    d_global: 351,
    i_global: "43902,02",
    t_global: 35,
    z_global: "3508,37",
  },
  {
    profile_section_id: 8571,
    name: "351x36",
    type: "Pipe",
    designation: "351x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "356,26",
    d_global: 351,
    i_global: "44764,09",
    t_global: 36,
    z_global: "3586,76",
  },
  {
    profile_section_id: 8572,
    name: "351x38",
    type: "Pipe",
    designation: "351x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "373,66",
    d_global: 351,
    i_global: "46433,45",
    t_global: 38,
    z_global: "3740,18",
  },
  {
    profile_section_id: 8573,
    name: "351x40",
    type: "Pipe",
    designation: "351x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "390,81",
    d_global: 351,
    i_global: "48031,54",
    t_global: 40,
    z_global: "3889,2",
  },
  {
    profile_section_id: 8574,
    name: "351x42",
    type: "Pipe",
    designation: "351x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "407,72",
    d_global: 351,
    i_global: "49560,41",
    t_global: 42,
    z_global: "4033,89",
  },
  {
    profile_section_id: 8575,
    name: "351x45",
    type: "Pipe",
    designation: "351x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "432,6",
    d_global: 351,
    i_global: "51728,36",
    t_global: 45,
    z_global: "4242,93",
  },
  {
    profile_section_id: 8576,
    name: "351x48",
    type: "Pipe",
    designation: "351x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "456,91",
    d_global: 351,
    i_global: "53751,84",
    t_global: 48,
    z_global: "4442,59",
  },
  {
    profile_section_id: 8577,
    name: "351x50",
    type: "Pipe",
    designation: "351x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "472,81",
    d_global: 351,
    i_global: "55023,82",
    t_global: 50,
    z_global: "4570,57",
  },
  {
    profile_section_id: 8578,
    name: "351x56",
    type: "Pipe",
    designation: "351x56",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "518,99",
    d_global: 351,
    i_global: "58490,95",
    t_global: 56,
    z_global: "4930,71",
  },
  {
    profile_section_id: 8579,
    name: "351x60",
    type: "Pipe",
    designation: "351x60",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "548,52",
    d_global: 351,
    i_global: "60530,1",
    t_global: 60,
    z_global: "5151,57",
  },
  {
    profile_section_id: 8580,
    name: "351x63",
    type: "Pipe",
    designation: "351x63",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "570,01",
    d_global: 351,
    i_global: "61926,66",
    t_global: 63,
    z_global: "5307,49",
  },
  {
    profile_section_id: 8581,
    name: "351x65",
    type: "Pipe",
    designation: "351x65",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "584,02",
    d_global: 351,
    i_global: "62797,7",
    t_global: 65,
    z_global: "5406,93",
  },
  {
    profile_section_id: 8582,
    name: "351x70",
    type: "Pipe",
    designation: "351x70",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "617,95",
    d_global: 351,
    i_global: "64777,52",
    t_global: 70,
    z_global: "5640,19",
  },
  {
    profile_section_id: 8583,
    name: "351x75",
    type: "Pipe",
    designation: "351x75",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "650,31",
    d_global: 351,
    i_global: "66494,98",
    t_global: 75,
    z_global: "5852,36",
  },
  {
    profile_section_id: 8584,
    name: "377x9",
    type: "Pipe",
    designation: "377x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "104,05",
    d_global: 377,
    i_global: "17624,04",
    t_global: 9,
    z_global: "1218,75",
  },
  {
    profile_section_id: 8585,
    name: "377x9.5",
    type: "Pipe",
    designation: "377x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "109,68",
    d_global: 377,
    i_global: "18528,73",
    t_global: "9,5",
    z_global: 1283,
  },
  {
    profile_section_id: 8586,
    name: "377x10",
    type: "Pipe",
    designation: "377x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "115,3",
    d_global: 377,
    i_global: "19425,87",
    t_global: 10,
    z_global: "1346,89",
  },
  {
    profile_section_id: 8587,
    name: "377x11",
    type: "Pipe",
    designation: "377x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "126,48",
    d_global: 377,
    i_global: "21197,66",
    t_global: 11,
    z_global: "1473,59",
  },
  {
    profile_section_id: 8588,
    name: "377x12",
    type: "Pipe",
    designation: "377x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "137,6",
    d_global: 377,
    i_global: "22939,76",
    t_global: 12,
    z_global: "1598,88",
  },
  {
    profile_section_id: 8589,
    name: "377x13",
    type: "Pipe",
    designation: "377x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "148,66",
    d_global: 377,
    i_global: "24652,5",
    t_global: 13,
    z_global: "1722,75",
  },
  {
    profile_section_id: 8590,
    name: "377x14",
    type: "Pipe",
    designation: "377x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "159,66",
    d_global: 377,
    i_global: "26336,21",
    t_global: 14,
    z_global: "1845,22",
  },
  {
    profile_section_id: 8591,
    name: "377x15",
    type: "Pipe",
    designation: "377x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "170,59",
    d_global: 377,
    i_global: "27991,22",
    t_global: 15,
    z_global: "1966,29",
  },
  {
    profile_section_id: 8592,
    name: "377x16",
    type: "Pipe",
    designation: "377x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "181,46",
    d_global: 377,
    i_global: "29617,87",
    t_global: 16,
    z_global: "2085,98",
  },
  {
    profile_section_id: 8593,
    name: "377x17",
    type: "Pipe",
    designation: "377x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "192,27",
    d_global: 377,
    i_global: "31216,46",
    t_global: 17,
    z_global: "2204,29",
  },
  {
    profile_section_id: 8594,
    name: "377x18",
    type: "Pipe",
    designation: "377x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "203,01",
    d_global: 377,
    i_global: "32787,34",
    t_global: 18,
    z_global: "2321,22",
  },
  {
    profile_section_id: 8595,
    name: "377x19",
    type: "Pipe",
    designation: "377x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "213,69",
    d_global: 377,
    i_global: "34330,82",
    t_global: 19,
    z_global: "2436,79",
  },
  {
    profile_section_id: 8596,
    name: "377x20",
    type: "Pipe",
    designation: "377x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "224,31",
    d_global: 377,
    i_global: "35847,21",
    t_global: 20,
    z_global: "2551,01",
  },
  {
    profile_section_id: 8597,
    name: "377x22",
    type: "Pipe",
    designation: "377x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "245,36",
    d_global: 377,
    i_global: "38800,05",
    t_global: 22,
    z_global: "2775,41",
  },
  {
    profile_section_id: 8598,
    name: "377x24",
    type: "Pipe",
    designation: "377x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "266,16",
    d_global: 377,
    i_global: "41648,38",
    t_global: 24,
    z_global: "2994,47",
  },
  {
    profile_section_id: 8599,
    name: "377x25",
    type: "Pipe",
    designation: "377x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "276,46",
    d_global: 377,
    i_global: "43034,13",
    t_global: 25,
    z_global: "3102,03",
  },
  {
    profile_section_id: 8600,
    name: "377x26",
    type: "Pipe",
    designation: "377x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "286,7",
    d_global: 377,
    i_global: "44394,69",
    t_global: 26,
    z_global: "3208,28",
  },
  {
    profile_section_id: 8601,
    name: "377x28",
    type: "Pipe",
    designation: "377x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 307,
    d_global: 377,
    i_global: "47041,45",
    t_global: 28,
    z_global: "3416,89",
  },
  {
    profile_section_id: 8602,
    name: "377x30",
    type: "Pipe",
    designation: "377x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "327,04",
    d_global: 377,
    i_global: "49591,09",
    t_global: 30,
    z_global: "3620,36",
  },
  {
    profile_section_id: 8603,
    name: "377x32",
    type: "Pipe",
    designation: "377x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "346,83",
    d_global: 377,
    i_global: "52046,02",
    t_global: 32,
    z_global: "3818,77",
  },
  {
    profile_section_id: 8604,
    name: "377x34",
    type: "Pipe",
    designation: "377x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "366,37",
    d_global: 377,
    i_global: "54408,61",
    t_global: 34,
    z_global: "4012,16",
  },
  {
    profile_section_id: 8605,
    name: "377x35",
    type: "Pipe",
    designation: "377x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "376,05",
    d_global: 377,
    i_global: "55556,02",
    t_global: 35,
    z_global: "4107,01",
  },
  {
    profile_section_id: 8606,
    name: "377x36",
    type: "Pipe",
    designation: "377x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "385,66",
    d_global: 377,
    i_global: "56681,21",
    t_global: 36,
    z_global: "4200,62",
  },
  {
    profile_section_id: 8607,
    name: "377x38",
    type: "Pipe",
    designation: "377x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "404,7",
    d_global: 377,
    i_global: "58866,14",
    t_global: 38,
    z_global: "4384,19",
  },
  {
    profile_section_id: 8608,
    name: "377x40",
    type: "Pipe",
    designation: "377x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "423,49",
    d_global: 377,
    i_global: "60965,67",
    t_global: 40,
    z_global: "4562,95",
  },
  {
    profile_section_id: 8609,
    name: "377x42",
    type: "Pipe",
    designation: "377x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "442,02",
    d_global: 377,
    i_global: "62982,07",
    t_global: 42,
    z_global: "4736,96",
  },
  {
    profile_section_id: 8610,
    name: "377x45",
    type: "Pipe",
    designation: "377x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "469,35",
    d_global: 377,
    i_global: "65855,64",
    t_global: 45,
    z_global: "4989,21",
  },
  {
    profile_section_id: 8611,
    name: "377x48",
    type: "Pipe",
    designation: "377x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "496,12",
    d_global: 377,
    i_global: "68554,52",
    t_global: 48,
    z_global: "5231,12",
  },
  {
    profile_section_id: 8612,
    name: "377x50",
    type: "Pipe",
    designation: "377x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "513,65",
    d_global: 377,
    i_global: "70260,31",
    t_global: 50,
    z_global: "5386,77",
  },
  {
    profile_section_id: 8613,
    name: "377x56",
    type: "Pipe",
    designation: "377x56",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "564,73",
    d_global: 377,
    i_global: "74952,03",
    t_global: 56,
    z_global: "5827,38",
  },
  {
    profile_section_id: 8614,
    name: "377x60",
    type: "Pipe",
    designation: "377x60",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "597,53",
    d_global: 377,
    i_global: "77745,49",
    t_global: 60,
    z_global: "6099,81",
  },
  {
    profile_section_id: 8615,
    name: "377x63",
    type: "Pipe",
    designation: "377x63",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "621,47",
    d_global: 377,
    i_global: "79676,32",
    t_global: 63,
    z_global: "6293,32",
  },
  {
    profile_section_id: 8616,
    name: "377x65",
    type: "Pipe",
    designation: "377x65",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "637,12",
    d_global: 377,
    i_global: "80888,91",
    t_global: 65,
    z_global: "6417,3",
  },
  {
    profile_section_id: 8617,
    name: "377x70",
    type: "Pipe",
    designation: "377x70",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "675,13",
    d_global: 377,
    i_global: "83672,87",
    t_global: 70,
    z_global: "6710,09",
  },
  {
    profile_section_id: 8618,
    name: "377x75",
    type: "Pipe",
    designation: "377x75",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "711,57",
    d_global: 377,
    i_global: "86125,85",
    t_global: 75,
    z_global: "6979,18",
  },
  {
    profile_section_id: 8619,
    name: "402x9",
    type: "Pipe",
    designation: "402x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "111,12",
    d_global: 402,
    i_global: "21463,86",
    t_global: 9,
    z_global: "1389,94",
  },
  {
    profile_section_id: 8620,
    name: "402x9.5",
    type: "Pipe",
    designation: "402x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "117,14",
    d_global: 402,
    i_global: "22571,31",
    t_global: "9,5",
    z_global: "1463,45",
  },
  {
    profile_section_id: 8621,
    name: "402x10",
    type: "Pipe",
    designation: "402x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "123,15",
    d_global: 402,
    i_global: "23670,13",
    t_global: 10,
    z_global: "1536,59",
  },
  {
    profile_section_id: 8622,
    name: "402x11",
    type: "Pipe",
    designation: "402x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "135,12",
    d_global: 402,
    i_global: "25842,02",
    t_global: 11,
    z_global: "1681,71",
  },
  {
    profile_section_id: 8623,
    name: "402x12",
    type: "Pipe",
    designation: "402x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "147,03",
    d_global: 402,
    i_global: "27979,88",
    t_global: 12,
    z_global: "1825,32",
  },
  {
    profile_section_id: 8624,
    name: "402x13",
    type: "Pipe",
    designation: "402x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "158,87",
    d_global: 402,
    i_global: "30084,08",
    t_global: 13,
    z_global: "1967,41",
  },
  {
    profile_section_id: 8625,
    name: "402x14",
    type: "Pipe",
    designation: "402x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "170,65",
    d_global: 402,
    i_global: "32154,97",
    t_global: 14,
    z_global: 2108,
  },
  {
    profile_section_id: 8626,
    name: "402x15",
    type: "Pipe",
    designation: "402x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "182,37",
    d_global: 402,
    i_global: "34192,91",
    t_global: 15,
    z_global: "2247,1",
  },
  {
    profile_section_id: 8627,
    name: "402x16",
    type: "Pipe",
    designation: "402x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "194,02",
    d_global: 402,
    i_global: "36198,23",
    t_global: 16,
    z_global: "2384,7",
  },
  {
    profile_section_id: 8628,
    name: "402x17",
    type: "Pipe",
    designation: "402x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "205,62",
    d_global: 402,
    i_global: "38171,3",
    t_global: 17,
    z_global: "2520,83",
  },
  {
    profile_section_id: 8629,
    name: "402x18",
    type: "Pipe",
    designation: "402x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "217,15",
    d_global: 402,
    i_global: "40112,46",
    t_global: 18,
    z_global: "2655,49",
  },
  {
    profile_section_id: 8630,
    name: "402x19",
    type: "Pipe",
    designation: "402x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "228,61",
    d_global: 402,
    i_global: "42022,05",
    t_global: 19,
    z_global: "2788,68",
  },
  {
    profile_section_id: 8631,
    name: "402x20",
    type: "Pipe",
    designation: "402x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "240,02",
    d_global: 402,
    i_global: "43900,43",
    t_global: 20,
    z_global: "2920,42",
  },
  {
    profile_section_id: 8632,
    name: "402x22",
    type: "Pipe",
    designation: "402x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "262,64",
    d_global: 402,
    i_global: "47564,9",
    t_global: 22,
    z_global: "3179,55",
  },
  {
    profile_section_id: 8633,
    name: "402x24",
    type: "Pipe",
    designation: "402x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "285,01",
    d_global: 402,
    i_global: "51108,57",
    t_global: 24,
    z_global: "3432,97",
  },
  {
    profile_section_id: 8634,
    name: "402x25",
    type: "Pipe",
    designation: "402x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "296,1",
    d_global: 402,
    i_global: "52835,95",
    t_global: 25,
    z_global: "3557,54",
  },
  {
    profile_section_id: 8635,
    name: "402x26",
    type: "Pipe",
    designation: "402x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "307,12",
    d_global: 402,
    i_global: "54534,14",
    t_global: 26,
    z_global: "3680,71",
  },
  {
    profile_section_id: 8636,
    name: "402x28",
    type: "Pipe",
    designation: "402x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "328,99",
    d_global: 402,
    i_global: "57844,24",
    t_global: 28,
    z_global: "3922,86",
  },
  {
    profile_section_id: 8637,
    name: "402x30",
    type: "Pipe",
    designation: "402x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "350,6",
    d_global: 402,
    i_global: "61041,52",
    t_global: 30,
    z_global: "4159,48",
  },
  {
    profile_section_id: 8638,
    name: "402x32",
    type: "Pipe",
    designation: "402x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "371,96",
    d_global: 402,
    i_global: "64128,55",
    t_global: 32,
    z_global: "4390,63",
  },
  {
    profile_section_id: 8639,
    name: "402x34",
    type: "Pipe",
    designation: "402x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "393,08",
    d_global: 402,
    i_global: "67107,91",
    t_global: 34,
    z_global: "4616,36",
  },
  {
    profile_section_id: 8640,
    name: "402x35",
    type: "Pipe",
    designation: "402x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "403,54",
    d_global: 402,
    i_global: "68558,01",
    t_global: 35,
    z_global: "4727,23",
  },
  {
    profile_section_id: 8641,
    name: "402x36",
    type: "Pipe",
    designation: "402x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "413,94",
    d_global: 402,
    i_global: "69982,13",
    t_global: 36,
    z_global: "4836,76",
  },
  {
    profile_section_id: 8642,
    name: "402x38",
    type: "Pipe",
    designation: "402x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "434,55",
    d_global: 402,
    i_global: "72753,71",
    t_global: 38,
    z_global: "5051,88",
  },
  {
    profile_section_id: 8643,
    name: "402x40",
    type: "Pipe",
    designation: "402x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "454,9",
    d_global: 402,
    i_global: "75425,13",
    t_global: 40,
    z_global: "5261,78",
  },
  {
    profile_section_id: 8644,
    name: "402x42",
    type: "Pipe",
    designation: "402x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "475,01",
    d_global: 402,
    i_global: "77998,82",
    t_global: 42,
    z_global: "5466,53",
  },
  {
    profile_section_id: 8645,
    name: "402x45",
    type: "Pipe",
    designation: "402x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "504,7",
    d_global: 402,
    i_global: "81681,4",
    t_global: 45,
    z_global: "5764,14",
  },
  {
    profile_section_id: 8646,
    name: "402x48",
    type: "Pipe",
    designation: "402x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "533,82",
    d_global: 402,
    i_global: "85157,55",
    t_global: 48,
    z_global: "6050,52",
  },
  {
    profile_section_id: 8647,
    name: "402x50",
    type: "Pipe",
    designation: "402x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "552,92",
    d_global: 402,
    i_global: "87364,17",
    t_global: 50,
    z_global: "6235,31",
  },
  {
    profile_section_id: 8648,
    name: "402x56",
    type: "Pipe",
    designation: "402x56",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "608,72",
    d_global: 402,
    i_global: "93477,32",
    t_global: 56,
    z_global: "6760,94",
  },
  {
    profile_section_id: 8649,
    name: "402x60",
    type: "Pipe",
    designation: "402x60",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "644,65",
    d_global: 402,
    i_global: "97152,7",
    t_global: 60,
    z_global: "7088,07",
  },
  {
    profile_section_id: 8650,
    name: "402x63",
    type: "Pipe",
    designation: "402x63",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "670,95",
    d_global: 402,
    i_global: "99711,55",
    t_global: 63,
    z_global: "7321,54",
  },
  {
    profile_section_id: 8651,
    name: "402x65",
    type: "Pipe",
    designation: "402x65",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "688,17",
    d_global: 402,
    i_global: "101327,3",
    t_global: 65,
    z_global: "7471,66",
  },
  {
    profile_section_id: 8652,
    name: "402x70",
    type: "Pipe",
    designation: "402x70",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "730,11",
    d_global: 402,
    i_global: "105065,9",
    t_global: 70,
    z_global: "7828,06",
  },
  {
    profile_section_id: 8653,
    name: "402x75",
    type: "Pipe",
    designation: "402x75",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "770,48",
    d_global: 402,
    i_global: "108400,1",
    t_global: 75,
    z_global: "8158,26",
  },
  {
    profile_section_id: 8654,
    name: "426x9",
    type: "Pipe",
    designation: "426x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "117,9",
    d_global: 426,
    i_global: "25639,69",
    t_global: 9,
    z_global: "1564,85",
  },
  {
    profile_section_id: 8655,
    name: "426x9.5",
    type: "Pipe",
    designation: "426x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "124,31",
    d_global: 426,
    i_global: "26968,35",
    t_global: "9,5",
    z_global: "1647,86",
  },
  {
    profile_section_id: 8656,
    name: "426x10",
    type: "Pipe",
    designation: "426x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "130,69",
    d_global: 426,
    i_global: "28287,25",
    t_global: 10,
    z_global: "1730,46",
  },
  {
    profile_section_id: 8657,
    name: "426x11",
    type: "Pipe",
    designation: "426x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "143,41",
    d_global: 426,
    i_global: "30895,97",
    t_global: 11,
    z_global: "1894,44",
  },
  {
    profile_section_id: 8658,
    name: "426x12",
    type: "Pipe",
    designation: "426x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "156,07",
    d_global: 426,
    i_global: "33466,24",
    t_global: 12,
    z_global: "2056,81",
  },
  {
    profile_section_id: 8659,
    name: "426x13",
    type: "Pipe",
    designation: "426x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "168,67",
    d_global: 426,
    i_global: "35998,42",
    t_global: 13,
    z_global: "2217,57",
  },
  {
    profile_section_id: 8660,
    name: "426x14",
    type: "Pipe",
    designation: "426x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "181,21",
    d_global: 426,
    i_global: "38492,91",
    t_global: 14,
    z_global: "2376,74",
  },
  {
    profile_section_id: 8661,
    name: "426x15",
    type: "Pipe",
    designation: "426x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "193,68",
    d_global: 426,
    i_global: "40950,07",
    t_global: 15,
    z_global: "2534,31",
  },
  {
    profile_section_id: 8662,
    name: "426x16",
    type: "Pipe",
    designation: "426x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "206,09",
    d_global: 426,
    i_global: "43370,29",
    t_global: 16,
    z_global: "2690,29",
  },
  {
    profile_section_id: 8663,
    name: "426x17",
    type: "Pipe",
    designation: "426x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "218,43",
    d_global: 426,
    i_global: "45753,93",
    t_global: 17,
    z_global: "2844,7",
  },
  {
    profile_section_id: 8664,
    name: "426x18",
    type: "Pipe",
    designation: "426x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "230,72",
    d_global: 426,
    i_global: "48101,36",
    t_global: 18,
    z_global: "2997,55",
  },
  {
    profile_section_id: 8665,
    name: "426x19",
    type: "Pipe",
    designation: "426x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "242,94",
    d_global: 426,
    i_global: "50412,95",
    t_global: 19,
    z_global: "3148,83",
  },
  {
    profile_section_id: 8666,
    name: "426x20",
    type: "Pipe",
    designation: "426x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "255,1",
    d_global: 426,
    i_global: "52689,08",
    t_global: 20,
    z_global: "3298,56",
  },
  {
    profile_section_id: 8667,
    name: "426x22",
    type: "Pipe",
    designation: "426x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "279,22",
    d_global: 426,
    i_global: "57136,37",
    t_global: 22,
    z_global: "3593,4",
  },
  {
    profile_section_id: 8668,
    name: "426x24",
    type: "Pipe",
    designation: "426x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "303,1",
    d_global: 426,
    i_global: "61446,12",
    t_global: 24,
    z_global: "3882,13",
  },
  {
    profile_section_id: 8669,
    name: "426x25",
    type: "Pipe",
    designation: "426x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "314,94",
    d_global: 426,
    i_global: "63550,32",
    t_global: 25,
    z_global: "4024,23",
  },
  {
    profile_section_id: 8670,
    name: "426x26",
    type: "Pipe",
    designation: "426x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "326,73",
    d_global: 426,
    i_global: "65621,21",
    t_global: 26,
    z_global: "4164,82",
  },
  {
    profile_section_id: 8671,
    name: "426x28",
    type: "Pipe",
    designation: "426x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "350,1",
    d_global: 426,
    i_global: "69664,47",
    t_global: 28,
    z_global: "4441,52",
  },
  {
    profile_section_id: 8672,
    name: "426x30",
    type: "Pipe",
    designation: "426x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "373,22",
    d_global: 426,
    i_global: "73578,7",
    t_global: 30,
    z_global: "4712,3",
  },
  {
    profile_section_id: 8673,
    name: "426x32",
    type: "Pipe",
    designation: "426x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "396,09",
    d_global: 426,
    i_global: "77366,67",
    t_global: 32,
    z_global: "4977,23",
  },
  {
    profile_section_id: 8674,
    name: "426x34",
    type: "Pipe",
    designation: "426x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "418,71",
    d_global: 426,
    i_global: "81031,14",
    t_global: 34,
    z_global: "5236,37",
  },
  {
    profile_section_id: 8675,
    name: "426x35",
    type: "Pipe",
    designation: "426x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "429,93",
    d_global: 426,
    i_global: "82817,91",
    t_global: 35,
    z_global: "5363,78",
  },
  {
    profile_section_id: 8676,
    name: "426x36",
    type: "Pipe",
    designation: "426x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "441,08",
    d_global: 426,
    i_global: "84574,81",
    t_global: 36,
    z_global: "5489,78",
  },
  {
    profile_section_id: 8677,
    name: "426x38",
    type: "Pipe",
    designation: "426x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "463,2",
    d_global: 426,
    i_global: "88000,38",
    t_global: 38,
    z_global: "5737,53",
  },
  {
    profile_section_id: 8678,
    name: "426x40",
    type: "Pipe",
    designation: "426x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "485,06",
    d_global: 426,
    i_global: "91310,48",
    t_global: 40,
    z_global: "5979,68",
  },
  {
    profile_section_id: 8679,
    name: "426x42",
    type: "Pipe",
    designation: "426x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "506,68",
    d_global: 426,
    i_global: "94507,75",
    t_global: 42,
    z_global: "6216,29",
  },
  {
    profile_section_id: 8680,
    name: "426x45",
    type: "Pipe",
    designation: "426x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "538,63",
    d_global: 426,
    i_global: "99097,77",
    t_global: 45,
    z_global: "6560,98",
  },
  {
    profile_section_id: 8681,
    name: "426x48",
    type: "Pipe",
    designation: "426x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "570,01",
    d_global: 426,
    i_global: "103448,4",
    t_global: 48,
    z_global: "6893,57",
  },
  {
    profile_section_id: 8682,
    name: "426x50",
    type: "Pipe",
    designation: "426x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "590,62",
    d_global: 426,
    i_global: 106220,
    t_global: 50,
    z_global: "7108,69",
  },
  {
    profile_section_id: 8683,
    name: "426x56",
    type: "Pipe",
    designation: "426x56",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "650,94",
    d_global: 426,
    i_global: "113943,4",
    t_global: 56,
    z_global: "7723,01",
  },
  {
    profile_section_id: 8684,
    name: "426x60",
    type: "Pipe",
    designation: "426x60",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "689,89",
    d_global: 426,
    i_global: "118623,8",
    t_global: 60,
    z_global: "8107,33",
  },
  {
    profile_section_id: 8685,
    name: "426x63",
    type: "Pipe",
    designation: "426x63",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "718,45",
    d_global: 426,
    i_global: "121901,3",
    t_global: 63,
    z_global: "8382,7",
  },
  {
    profile_section_id: 8686,
    name: "426x65",
    type: "Pipe",
    designation: "426x65",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "737,17",
    d_global: 426,
    i_global: "123979,9",
    t_global: 65,
    z_global: "8560,27",
  },
  {
    profile_section_id: 8687,
    name: "426x70",
    type: "Pipe",
    designation: "426x70",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "782,88",
    d_global: 426,
    i_global: "128819,8",
    t_global: 70,
    z_global: "8983,61",
  },
  {
    profile_section_id: 8688,
    name: "426x75",
    type: "Pipe",
    designation: "426x75",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "827,02",
    d_global: 426,
    i_global: "133177,8",
    t_global: 75,
    z_global: "9378,35",
  },
  {
    profile_section_id: 8689,
    name: "450x9",
    type: "Pipe",
    designation: "450x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "124,69",
    d_global: 450,
    i_global: "30324,88",
    t_global: 9,
    z_global: "1750,13",
  },
  {
    profile_section_id: 8690,
    name: "450x9.5",
    type: "Pipe",
    designation: "450x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "131,47",
    d_global: 450,
    i_global: "31902,39",
    t_global: "9,5",
    z_global: "1843,21",
  },
  {
    profile_section_id: 8691,
    name: "450x10",
    type: "Pipe",
    designation: "450x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "138,23",
    d_global: 450,
    i_global: "33468,96",
    t_global: 10,
    z_global: "1935,85",
  },
  {
    profile_section_id: 8692,
    name: "450x11",
    type: "Pipe",
    designation: "450x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "151,71",
    d_global: 450,
    i_global: "36569,47",
    t_global: 11,
    z_global: "2119,84",
  },
  {
    profile_section_id: 8693,
    name: "450x12",
    type: "Pipe",
    designation: "450x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "165,12",
    d_global: 450,
    i_global: "39626,83",
    t_global: 12,
    z_global: "2302,13",
  },
  {
    profile_section_id: 8694,
    name: "450x13",
    type: "Pipe",
    designation: "450x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "178,47",
    d_global: 450,
    i_global: "42641,43",
    t_global: 13,
    z_global: "2482,71",
  },
  {
    profile_section_id: 8695,
    name: "450x14",
    type: "Pipe",
    designation: "450x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "191,76",
    d_global: 450,
    i_global: "45613,66",
    t_global: 14,
    z_global: "2661,59",
  },
  {
    profile_section_id: 8696,
    name: "450x15",
    type: "Pipe",
    designation: "450x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "204,99",
    d_global: 450,
    i_global: "48543,94",
    t_global: 15,
    z_global: "2838,79",
  },
  {
    profile_section_id: 8697,
    name: "450x16",
    type: "Pipe",
    designation: "450x16",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "218,15",
    d_global: 450,
    i_global: "51432,65",
    t_global: 16,
    z_global: "3014,31",
  },
  {
    profile_section_id: 8698,
    name: "450x17",
    type: "Pipe",
    designation: "450x17",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "231,25",
    d_global: 450,
    i_global: "54280,2",
    t_global: 17,
    z_global: "3188,15",
  },
  {
    profile_section_id: 8699,
    name: "450x18",
    type: "Pipe",
    designation: "450x18",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "244,29",
    d_global: 450,
    i_global: "57086,96",
    t_global: 18,
    z_global: "3360,34",
  },
  {
    profile_section_id: 8700,
    name: "450x19",
    type: "Pipe",
    designation: "450x19",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "257,26",
    d_global: 450,
    i_global: "59853,35",
    t_global: 19,
    z_global: "3530,86",
  },
  {
    profile_section_id: 8701,
    name: "450x20",
    type: "Pipe",
    designation: "450x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "270,18",
    d_global: 450,
    i_global: "62579,74",
    t_global: 20,
    z_global: "3699,74",
  },
  {
    profile_section_id: 8702,
    name: "450x22",
    type: "Pipe",
    designation: "450x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "295,81",
    d_global: 450,
    i_global: "67914,08",
    t_global: 22,
    z_global: "4032,59",
  },
  {
    profile_section_id: 8703,
    name: "450x24",
    type: "Pipe",
    designation: "450x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "321,2",
    d_global: 450,
    i_global: "73093,06",
    t_global: 24,
    z_global: "4358,94",
  },
  {
    profile_section_id: 8704,
    name: "450x25",
    type: "Pipe",
    designation: "450x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "333,79",
    d_global: 450,
    i_global: "75625,25",
    t_global: 25,
    z_global: "4519,7",
  },
  {
    profile_section_id: 8705,
    name: "450x26",
    type: "Pipe",
    designation: "450x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "346,33",
    d_global: 450,
    i_global: "78119,74",
    t_global: 26,
    z_global: "4678,86",
  },
  {
    profile_section_id: 8706,
    name: "450x28",
    type: "Pipe",
    designation: "450x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "371,21",
    d_global: 450,
    i_global: "82997,12",
    t_global: 28,
    z_global: "4992,42",
  },
  {
    profile_section_id: 8707,
    name: "450x30",
    type: "Pipe",
    designation: "450x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "395,84",
    d_global: 450,
    i_global: "87728,19",
    t_global: 30,
    z_global: "5299,68",
  },
  {
    profile_section_id: 8708,
    name: "450x32",
    type: "Pipe",
    designation: "450x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "420,22",
    d_global: 450,
    i_global: "92315,91",
    t_global: 32,
    z_global: "5600,69",
  },
  {
    profile_section_id: 8709,
    name: "450x34",
    type: "Pipe",
    designation: "450x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "444,35",
    d_global: 450,
    i_global: "96763,2",
    t_global: 34,
    z_global: "5895,53",
  },
  {
    profile_section_id: 8710,
    name: "450x35",
    type: "Pipe",
    designation: "450x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "456,32",
    d_global: 450,
    i_global: "98935,09",
    t_global: 35,
    z_global: "6040,66",
  },
  {
    profile_section_id: 8711,
    name: "450x36",
    type: "Pipe",
    designation: "450x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "468,22",
    d_global: 450,
    i_global: 101073,
    t_global: 36,
    z_global: "6184,26",
  },
  {
    profile_section_id: 8712,
    name: "450x38",
    type: "Pipe",
    designation: "450x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "491,85",
    d_global: 450,
    i_global: 105248,
    t_global: 38,
    z_global: "6466,94",
  },
  {
    profile_section_id: 8713,
    name: "450x40",
    type: "Pipe",
    designation: "450x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "515,22",
    d_global: 450,
    i_global: "109291,3",
    t_global: 40,
    z_global: "6743,65",
  },
  {
    profile_section_id: 8714,
    name: "450x42",
    type: "Pipe",
    designation: "450x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "538,34",
    d_global: 450,
    i_global: "113205,5",
    t_global: 42,
    z_global: "7014,43",
  },
  {
    profile_section_id: 8715,
    name: "450x45",
    type: "Pipe",
    designation: "450x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "572,56",
    d_global: 450,
    i_global: 118841,
    t_global: 45,
    z_global: "7409,65",
  },
  {
    profile_section_id: 8716,
    name: "450x48",
    type: "Pipe",
    designation: "450x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "606,2",
    d_global: 450,
    i_global: "124201,6",
    t_global: 48,
    z_global: "7791,91",
  },
  {
    profile_section_id: 8717,
    name: "450x50",
    type: "Pipe",
    designation: "450x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "628,32",
    d_global: 450,
    i_global: "127627,2",
    t_global: 50,
    z_global: "8039,66",
  },
  {
    profile_section_id: 8718,
    name: "450x56",
    type: "Pipe",
    designation: "450x56",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "693,16",
    d_global: 450,
    i_global: "137221,6",
    t_global: 56,
    z_global: "8749,57",
  },
  {
    profile_section_id: 8719,
    name: "450x60",
    type: "Pipe",
    designation: "450x60",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "735,13",
    d_global: 450,
    i_global: "143075,2",
    t_global: 60,
    z_global: "9195,7",
  },
  {
    profile_section_id: 8720,
    name: "450x63",
    type: "Pipe",
    designation: "450x63",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "765,95",
    d_global: 450,
    i_global: "147194,9",
    t_global: 63,
    z_global: "9516,42",
  },
  {
    profile_section_id: 8721,
    name: "450x65",
    type: "Pipe",
    designation: "450x65",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "786,18",
    d_global: 450,
    i_global: "149817,1",
    t_global: 65,
    z_global: "9723,74",
  },
  {
    profile_section_id: 8722,
    name: "450x70",
    type: "Pipe",
    designation: "450x70",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "835,66",
    d_global: 450,
    i_global: "155955,7",
    t_global: 70,
    z_global: "10219,78",
  },
  {
    profile_section_id: 8723,
    name: "450x75",
    type: "Pipe",
    designation: "450x75",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "883,57",
    d_global: 450,
    i_global: "161528,2",
    t_global: 75,
    z_global: "10684,83",
  },
  {
    profile_section_id: 8724,
    name: "465x9",
    type: "Pipe",
    designation: "465x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "128,93",
    d_global: 465,
    i_global: "33524,79",
    t_global: 9,
    z_global: "1871,2",
  },
  {
    profile_section_id: 8725,
    name: "465x9.5",
    type: "Pipe",
    designation: "465x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "135,94",
    d_global: 465,
    i_global: "35272,6",
    t_global: "9,5",
    z_global: "1970,86",
  },
  {
    profile_section_id: 8726,
    name: "465x10",
    type: "Pipe",
    designation: "465x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "142,94",
    d_global: 465,
    i_global: "37008,7",
    t_global: 10,
    z_global: "2070,07",
  },
  {
    profile_section_id: 8727,
    name: "465x11",
    type: "Pipe",
    designation: "465x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "156,89",
    d_global: 465,
    i_global: "40445,95",
    t_global: 11,
    z_global: "2267,15",
  },
  {
    profile_section_id: 8728,
    name: "465x12",
    type: "Pipe",
    designation: "465x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "170,78",
    d_global: 465,
    i_global: "43836,96",
    t_global: 12,
    z_global: "2462,47",
  },
  {
    profile_section_id: 8729,
    name: "465x13",
    type: "Pipe",
    designation: "465x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "184,6",
    d_global: 465,
    i_global: "47182,14",
    t_global: 13,
    z_global: "2656,02",
  },
  {
    profile_section_id: 8730,
    name: "465x14",
    type: "Pipe",
    designation: "465x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "198,36",
    d_global: 465,
    i_global: "50481,92",
    t_global: 14,
    z_global: "2847,82",
  },
  {
    profile_section_id: 8731,
    name: "465x15",
    type: "Pipe",
    designation: "465x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "212,06",
    d_global: 465,
    i_global: "53736,7",
    t_global: 15,
    z_global: "3037,86",
  },
  {
    profile_section_id: 8732,
    name: "465x20",
    type: "Pipe",
    designation: "465x20",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "279,6",
    d_global: 465,
    i_global: "69349,97",
    t_global: 20,
    z_global: "3962,18",
  },
  {
    profile_section_id: 8733,
    name: "465x22",
    type: "Pipe",
    designation: "465x22",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "306,18",
    d_global: 465,
    i_global: "75294,55",
    t_global: 22,
    z_global: "4319,95",
  },
  {
    profile_section_id: 8734,
    name: "465x24",
    type: "Pipe",
    designation: "465x24",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "332,51",
    d_global: 465,
    i_global: "81072,07",
    t_global: 24,
    z_global: "4670,98",
  },
  {
    profile_section_id: 8735,
    name: "465x25",
    type: "Pipe",
    designation: "465x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "345,58",
    d_global: 465,
    i_global: "83899,18",
    t_global: 25,
    z_global: 4844,
  },
  {
    profile_section_id: 8736,
    name: "465x26",
    type: "Pipe",
    designation: "465x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "358,58",
    d_global: 465,
    i_global: "86685,7",
    t_global: 26,
    z_global: "5015,35",
  },
  {
    profile_section_id: 8737,
    name: "465x28",
    type: "Pipe",
    designation: "465x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "384,41",
    d_global: 465,
    i_global: "92138,58",
    t_global: 28,
    z_global: "5353,11",
  },
  {
    profile_section_id: 8738,
    name: "465x30",
    type: "Pipe",
    designation: "465x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "409,98",
    d_global: 465,
    i_global: "97433,8",
    t_global: 30,
    z_global: "5684,33",
  },
  {
    profile_section_id: 8739,
    name: "465x32",
    type: "Pipe",
    designation: "465x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "435,3",
    d_global: 465,
    i_global: "102574,4",
    t_global: 32,
    z_global: "6009,07",
  },
  {
    profile_section_id: 8740,
    name: "465x34",
    type: "Pipe",
    designation: "465x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "460,37",
    d_global: 465,
    i_global: "107563,5",
    t_global: 34,
    z_global: "6327,39",
  },
  {
    profile_section_id: 8741,
    name: "465x35",
    type: "Pipe",
    designation: "465x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "472,81",
    d_global: 465,
    i_global: "110002,1",
    t_global: 35,
    z_global: "6484,17",
  },
  {
    profile_section_id: 8742,
    name: "465x36",
    type: "Pipe",
    designation: "465x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "485,19",
    d_global: 465,
    i_global: 112404,
    t_global: 36,
    z_global: "6639,37",
  },
  {
    profile_section_id: 8743,
    name: "465x38",
    type: "Pipe",
    designation: "465x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "509,75",
    d_global: 465,
    i_global: 117099,
    t_global: 38,
    z_global: "6945,06",
  },
  {
    profile_section_id: 8744,
    name: "465x40",
    type: "Pipe",
    designation: "465x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "534,07",
    d_global: 465,
    i_global: "121651,3",
    t_global: 40,
    z_global: "7244,52",
  },
  {
    profile_section_id: 8745,
    name: "465x42",
    type: "Pipe",
    designation: "465x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "558,14",
    d_global: 465,
    i_global: "126063,9",
    t_global: 42,
    z_global: "7537,83",
  },
  {
    profile_section_id: 8746,
    name: "465x45",
    type: "Pipe",
    designation: "465x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "593,76",
    d_global: 465,
    i_global: "132427,3",
    t_global: 45,
    z_global: "7966,38",
  },
  {
    profile_section_id: 8747,
    name: "465x48",
    type: "Pipe",
    designation: "465x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "628,82",
    d_global: 465,
    i_global: "138492,4",
    t_global: 48,
    z_global: "8381,44",
  },
  {
    profile_section_id: 8748,
    name: "465x50",
    type: "Pipe",
    designation: "465x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "651,88",
    d_global: 465,
    i_global: "142374,8",
    t_global: 50,
    z_global: "8650,75",
  },
  {
    profile_section_id: 8749,
    name: "465x56",
    type: "Pipe",
    designation: "465x56",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "719,55",
    d_global: 465,
    i_global: "153279,5",
    t_global: 56,
    z_global: "9423,92",
  },
  {
    profile_section_id: 8750,
    name: "465x60",
    type: "Pipe",
    designation: "465x60",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "763,41",
    d_global: 465,
    i_global: "159957,6",
    t_global: 60,
    z_global: "9911,02",
  },
  {
    profile_section_id: 8751,
    name: "465x63",
    type: "Pipe",
    designation: "465x63",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "795,64",
    d_global: 465,
    i_global: "164670,6",
    t_global: 63,
    z_global: "10261,83",
  },
  {
    profile_section_id: 8752,
    name: "465x65",
    type: "Pipe",
    designation: "465x65",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "816,81",
    d_global: 465,
    i_global: "167676,6",
    t_global: 65,
    z_global: "10488,92",
  },
  {
    profile_section_id: 8753,
    name: "465x70",
    type: "Pipe",
    designation: "465x70",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "868,65",
    d_global: 465,
    i_global: "174734,5",
    t_global: 70,
    z_global: "11033,32",
  },
  {
    profile_section_id: 8754,
    name: "465x75",
    type: "Pipe",
    designation: "465x75",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "918,92",
    d_global: 465,
    i_global: 181170,
    t_global: 75,
    z_global: "11545,24",
  },
  {
    profile_section_id: 8755,
    name: "480x9",
    type: "Pipe",
    designation: "480x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "133,17",
    d_global: 480,
    i_global: "36942,28",
    t_global: 9,
    z_global: "1996,31",
  },
  {
    profile_section_id: 8756,
    name: "480x9.5",
    type: "Pipe",
    designation: "480x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "140,42",
    d_global: 480,
    i_global: "38872,22",
    t_global: "9,5",
    z_global: "2102,78",
  },
  {
    profile_section_id: 8757,
    name: "480x10",
    type: "Pipe",
    designation: "480x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "147,65",
    d_global: 480,
    i_global: "40789,65",
    t_global: 10,
    z_global: "2208,78",
  },
  {
    profile_section_id: 8758,
    name: "480x11",
    type: "Pipe",
    designation: "480x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "162,07",
    d_global: 480,
    i_global: "44587,17",
    t_global: 11,
    z_global: "2419,41",
  },
  {
    profile_section_id: 8759,
    name: "480x12",
    type: "Pipe",
    designation: "480x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "176,43",
    d_global: 480,
    i_global: "48335,27",
    t_global: 12,
    z_global: "2628,21",
  },
  {
    profile_section_id: 8760,
    name: "480x13",
    type: "Pipe",
    designation: "480x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "190,73",
    d_global: 480,
    i_global: "52034,37",
    t_global: 13,
    z_global: "2835,18",
  },
  {
    profile_section_id: 8761,
    name: "480x14",
    type: "Pipe",
    designation: "480x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "204,96",
    d_global: 480,
    i_global: "55684,91",
    t_global: 14,
    z_global: "3040,34",
  },
  {
    profile_section_id: 8762,
    name: "480x15",
    type: "Pipe",
    designation: "480x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "219,13",
    d_global: 480,
    i_global: "59287,3",
    t_global: 15,
    z_global: "3243,69",
  },
  {
    profile_section_id: 8763,
    name: "480x25",
    type: "Pipe",
    designation: "480x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "357,36",
    d_global: 480,
    i_global: "92756,26",
    t_global: 25,
    z_global: "5179,54",
  },
  {
    profile_section_id: 8764,
    name: "480x26",
    type: "Pipe",
    designation: "480x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "370,83",
    d_global: 480,
    i_global: "95856,77",
    t_global: 26,
    z_global: "5363,53",
  },
  {
    profile_section_id: 8765,
    name: "480x28",
    type: "Pipe",
    designation: "480x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "397,6",
    d_global: 480,
    i_global: "101928,7",
    t_global: 28,
    z_global: "5726,4",
  },
  {
    profile_section_id: 8766,
    name: "480x30",
    type: "Pipe",
    designation: "480x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "424,12",
    d_global: 480,
    i_global: "107831,2",
    t_global: 30,
    z_global: "6082,48",
  },
  {
    profile_section_id: 8767,
    name: "480x32",
    type: "Pipe",
    designation: "480x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "450,38",
    d_global: 480,
    i_global: "113567,5",
    t_global: 32,
    z_global: "6431,84",
  },
  {
    profile_section_id: 8768,
    name: "480x34",
    type: "Pipe",
    designation: "480x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "476,39",
    d_global: 480,
    i_global: "119140,7",
    t_global: 34,
    z_global: "6774,55",
  },
  {
    profile_section_id: 8769,
    name: "480x35",
    type: "Pipe",
    designation: "480x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "489,3",
    d_global: 480,
    i_global: 121867,
    t_global: 35,
    z_global: "6943,43",
  },
  {
    profile_section_id: 8770,
    name: "480x36",
    type: "Pipe",
    designation: "480x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "502,15",
    d_global: 480,
    i_global: "124553,8",
    t_global: 36,
    z_global: "7110,67",
  },
  {
    profile_section_id: 8771,
    name: "480x38",
    type: "Pipe",
    designation: "480x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "527,66",
    d_global: 480,
    i_global: "129810,1",
    t_global: 38,
    z_global: "7440,26",
  },
  {
    profile_section_id: 8772,
    name: "480x40",
    type: "Pipe",
    designation: "480x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "552,92",
    d_global: 480,
    i_global: "134912,6",
    t_global: 40,
    z_global: "7763,39",
  },
  {
    profile_section_id: 8773,
    name: "480x42",
    type: "Pipe",
    designation: "480x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "577,93",
    d_global: 480,
    i_global: "139864,2",
    t_global: 42,
    z_global: "8080,12",
  },
  {
    profile_section_id: 8774,
    name: "480x45",
    type: "Pipe",
    designation: "480x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "614,97",
    d_global: 480,
    i_global: "147015,5",
    t_global: 45,
    z_global: "8543,36",
  },
  {
    profile_section_id: 8775,
    name: "480x48",
    type: "Pipe",
    designation: "480x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "651,44",
    d_global: 480,
    i_global: "153844,2",
    t_global: 48,
    z_global: "8992,57",
  },
  {
    profile_section_id: 8776,
    name: "480x50",
    type: "Pipe",
    designation: "480x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "675,44",
    d_global: 480,
    i_global: "158222,4",
    t_global: 50,
    z_global: "9284,34",
  },
  {
    profile_section_id: 8777,
    name: "480x56",
    type: "Pipe",
    designation: "480x56",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "745,94",
    d_global: 480,
    i_global: "170551,7",
    t_global: 56,
    z_global: "10123,46",
  },
  {
    profile_section_id: 8778,
    name: "480x60",
    type: "Pipe",
    designation: "480x60",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "791,68",
    d_global: 480,
    i_global: "178128,3",
    t_global: 60,
    z_global: "10653,34",
  },
  {
    profile_section_id: 8779,
    name: "480x63",
    type: "Pipe",
    designation: "480x63",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "825,33",
    d_global: 480,
    i_global: "183488,9",
    t_global: 63,
    z_global: "11035,6",
  },
  {
    profile_section_id: 8780,
    name: "480x65",
    type: "Pipe",
    designation: "480x65",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "847,44",
    d_global: 480,
    i_global: "186914,5",
    t_global: 65,
    z_global: "11283,34",
  },
  {
    profile_section_id: 8781,
    name: "480x70",
    type: "Pipe",
    designation: "480x70",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "901,64",
    d_global: 480,
    i_global: 194979,
    t_global: 70,
    z_global: "11878,36",
  },
  {
    profile_section_id: 8782,
    name: "480x75",
    type: "Pipe",
    designation: "480x75",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "954,26",
    d_global: 480,
    i_global: "202362,5",
    t_global: 75,
    z_global: "12439,39",
  },
  {
    profile_section_id: 8783,
    name: "500x9",
    type: "Pipe",
    designation: "500x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "138,83",
    d_global: 500,
    i_global: "41849,74",
    t_global: 9,
    z_global: "2169,43",
  },
  {
    profile_section_id: 8784,
    name: "500x9.5",
    type: "Pipe",
    designation: "500x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "146,39",
    d_global: 500,
    i_global: "44041,63",
    t_global: "9,5",
    z_global: "2285,32",
  },
  {
    profile_section_id: 8785,
    name: "500x10",
    type: "Pipe",
    designation: "500x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "153,94",
    d_global: 500,
    i_global: "46219,9",
    t_global: 10,
    z_global: "2400,73",
  },
  {
    profile_section_id: 8786,
    name: "500x11",
    type: "Pipe",
    designation: "500x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "168,99",
    d_global: 500,
    i_global: "50535,77",
    t_global: 11,
    z_global: "2630,12",
  },
  {
    profile_section_id: 8787,
    name: "500x12",
    type: "Pipe",
    designation: "500x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "183,97",
    d_global: 500,
    i_global: "54797,8",
    t_global: 12,
    z_global: "2857,59",
  },
  {
    profile_section_id: 8788,
    name: "500x13",
    type: "Pipe",
    designation: "500x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "198,89",
    d_global: 500,
    i_global: "59006,45",
    t_global: 13,
    z_global: "3083,16",
  },
  {
    profile_section_id: 8789,
    name: "500x14",
    type: "Pipe",
    designation: "500x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "213,75",
    d_global: 500,
    i_global: "63162,16",
    t_global: 14,
    z_global: "3306,83",
  },
  {
    profile_section_id: 8790,
    name: "500x15",
    type: "Pipe",
    designation: "500x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "228,55",
    d_global: 500,
    i_global: "67265,38",
    t_global: 15,
    z_global: "3528,62",
  },
  {
    profile_section_id: 8791,
    name: "500x25",
    type: "Pipe",
    designation: "500x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "373,06",
    d_global: 500,
    i_global: "105507,2",
    t_global: 25,
    z_global: "5644,42",
  },
  {
    profile_section_id: 8792,
    name: "500x26",
    type: "Pipe",
    designation: "500x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "387,17",
    d_global: 500,
    i_global: "109061,9",
    t_global: 26,
    z_global: "5845,97",
  },
  {
    profile_section_id: 8793,
    name: "500x28",
    type: "Pipe",
    designation: "500x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "415,19",
    d_global: 500,
    i_global: "116029,8",
    t_global: 28,
    z_global: "6243,71",
  },
  {
    profile_section_id: 8794,
    name: "500x30",
    type: "Pipe",
    designation: "500x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "442,96",
    d_global: 500,
    i_global: "122811,9",
    t_global: 30,
    z_global: "6634,34",
  },
  {
    profile_section_id: 8795,
    name: "500x32",
    type: "Pipe",
    designation: "500x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "470,48",
    d_global: 500,
    i_global: "129411,6",
    t_global: 32,
    z_global: "7017,94",
  },
  {
    profile_section_id: 8796,
    name: "500x34",
    type: "Pipe",
    designation: "500x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "497,75",
    d_global: 500,
    i_global: "135832,1",
    t_global: 34,
    z_global: "7394,56",
  },
  {
    profile_section_id: 8797,
    name: "500x35",
    type: "Pipe",
    designation: "500x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "511,29",
    d_global: 500,
    i_global: "138976,2",
    t_global: 35,
    z_global: "7580,27",
  },
  {
    profile_section_id: 8798,
    name: "500x36",
    type: "Pipe",
    designation: "500x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "524,77",
    d_global: 500,
    i_global: "142076,7",
    t_global: 36,
    z_global: "7764,27",
  },
  {
    profile_section_id: 8799,
    name: "500x38",
    type: "Pipe",
    designation: "500x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "551,54",
    d_global: 500,
    i_global: "148148,6",
    t_global: 38,
    z_global: "8127,13",
  },
  {
    profile_section_id: 8800,
    name: "500x40",
    type: "Pipe",
    designation: "500x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "578,05",
    d_global: 500,
    i_global: "154051,1",
    t_global: 40,
    z_global: "8483,21",
  },
  {
    profile_section_id: 8801,
    name: "500x42",
    type: "Pipe",
    designation: "500x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "604,32",
    d_global: 500,
    i_global: "159787,4",
    t_global: 42,
    z_global: "8832,58",
  },
  {
    profile_section_id: 8802,
    name: "500x45",
    type: "Pipe",
    designation: "500x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "643,24",
    d_global: 500,
    i_global: "168086,9",
    t_global: 45,
    z_global: "9344,16",
  },
  {
    profile_section_id: 8803,
    name: "500x48",
    type: "Pipe",
    designation: "500x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "681,6",
    d_global: 500,
    i_global: 176030,
    t_global: 48,
    z_global: 9841,
  },
  {
    profile_section_id: 8804,
    name: "500x50",
    type: "Pipe",
    designation: "500x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "706,86",
    d_global: 500,
    i_global: "181132,5",
    t_global: 50,
    z_global: "10164,13",
  },
  {
    profile_section_id: 8805,
    name: "500x56",
    type: "Pipe",
    designation: "500x56",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "781,13",
    d_global: 500,
    i_global: 195547,
    t_global: 56,
    z_global: "11095,38",
  },
  {
    profile_section_id: 8806,
    name: "500x60",
    type: "Pipe",
    designation: "500x60",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "829,38",
    d_global: 500,
    i_global: "204442,3",
    t_global: 60,
    z_global: "11685,08",
  },
  {
    profile_section_id: 8807,
    name: "500x63",
    type: "Pipe",
    designation: "500x63",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "864,91",
    d_global: 500,
    i_global: "210755,2",
    t_global: 63,
    z_global: "12111,37",
  },
  {
    profile_section_id: 8808,
    name: "500x65",
    type: "Pipe",
    designation: "500x65",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "888,29",
    d_global: 500,
    i_global: "214798,5",
    t_global: 65,
    z_global: "12388,07",
  },
  {
    profile_section_id: 8809,
    name: "500x70",
    type: "Pipe",
    designation: "500x70",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "945,62",
    d_global: 500,
    i_global: "224348,2",
    t_global: 70,
    z_global: "13054,07",
  },
  {
    profile_section_id: 8810,
    name: "500x75",
    type: "Pipe",
    designation: "500x75",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "1001,38",
    d_global: 500,
    i_global: "233134,4",
    t_global: 75,
    z_global: "13684,08",
  },
  {
    profile_section_id: 8811,
    name: "530x9",
    type: "Pipe",
    designation: "530x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "147,31",
    d_global: 530,
    i_global: "49997,14",
    t_global: 9,
    z_global: "2442,6",
  },
  {
    profile_section_id: 8812,
    name: "530x9.5",
    type: "Pipe",
    designation: "530x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "155,34",
    d_global: 530,
    i_global: "52624,79",
    t_global: "9,5",
    z_global: "2573,39",
  },
  {
    profile_section_id: 8813,
    name: "530x10",
    type: "Pipe",
    designation: "530x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "163,36",
    d_global: 530,
    i_global: "55237,05",
    t_global: 10,
    z_global: "2703,66",
  },
  {
    profile_section_id: 8814,
    name: "530x11",
    type: "Pipe",
    designation: "530x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "179,35",
    d_global: 530,
    i_global: "60415,68",
    t_global: 11,
    z_global: "2962,67",
  },
  {
    profile_section_id: 8815,
    name: "530x12",
    type: "Pipe",
    designation: "530x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "195,28",
    d_global: 530,
    i_global: "65533,51",
    t_global: 12,
    z_global: "3219,66",
  },
  {
    profile_section_id: 8816,
    name: "530x13",
    type: "Pipe",
    designation: "530x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "211,15",
    d_global: 530,
    i_global: "70591,01",
    t_global: 13,
    z_global: "3474,62",
  },
  {
    profile_section_id: 8817,
    name: "530x14",
    type: "Pipe",
    designation: "530x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "226,95",
    d_global: 530,
    i_global: "75588,66",
    t_global: 14,
    z_global: "3727,57",
  },
  {
    profile_section_id: 8818,
    name: "530x15",
    type: "Pipe",
    designation: "530x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "242,69",
    d_global: 530,
    i_global: "80526,92",
    t_global: 15,
    z_global: "3978,51",
  },
  {
    profile_section_id: 8819,
    name: "530x25",
    type: "Pipe",
    designation: "530x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "396,63",
    d_global: 530,
    i_global: "126746,8",
    t_global: 25,
    z_global: "6379,24",
  },
  {
    profile_section_id: 8820,
    name: "530x26",
    type: "Pipe",
    designation: "530x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "411,67",
    d_global: 530,
    i_global: "131062,7",
    t_global: 26,
    z_global: "6608,62",
  },
  {
    profile_section_id: 8821,
    name: "530x28",
    type: "Pipe",
    designation: "530x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "441,58",
    d_global: 530,
    i_global: "139533,4",
    t_global: 28,
    z_global: "7061,66",
  },
  {
    profile_section_id: 8822,
    name: "530x30",
    type: "Pipe",
    designation: "530x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "471,24",
    d_global: 530,
    i_global: "147792,3",
    t_global: 30,
    z_global: "7507,12",
  },
  {
    profile_section_id: 8823,
    name: "530x32",
    type: "Pipe",
    designation: "530x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "500,64",
    d_global: 530,
    i_global: 155843,
    t_global: 32,
    z_global: "7945,06",
  },
  {
    profile_section_id: 8824,
    name: "530x34",
    type: "Pipe",
    designation: "530x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "529,8",
    d_global: 530,
    i_global: "163689,1",
    t_global: 34,
    z_global: "8375,55",
  },
  {
    profile_section_id: 8825,
    name: "530x35",
    type: "Pipe",
    designation: "530x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "544,28",
    d_global: 530,
    i_global: "167536,5",
    t_global: 35,
    z_global: "8588,02",
  },
  {
    profile_section_id: 8826,
    name: "530x36",
    type: "Pipe",
    designation: "530x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "558,7",
    d_global: 530,
    i_global: 171334,
    t_global: 36,
    z_global: "8798,65",
  },
  {
    profile_section_id: 8827,
    name: "530x38",
    type: "Pipe",
    designation: "530x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "587,35",
    d_global: 530,
    i_global: "178781,2",
    t_global: 38,
    z_global: "9214,42",
  },
  {
    profile_section_id: 8828,
    name: "530x40",
    type: "Pipe",
    designation: "530x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "615,75",
    d_global: 530,
    i_global: "186034,1",
    t_global: 40,
    z_global: "9622,93",
  },
  {
    profile_section_id: 8829,
    name: "530x42",
    type: "Pipe",
    designation: "530x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "643,9",
    d_global: 530,
    i_global: "193096,2",
    t_global: 42,
    z_global: "10024,24",
  },
  {
    profile_section_id: 8830,
    name: "530x45",
    type: "Pipe",
    designation: "530x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "685,65",
    d_global: 530,
    i_global: "203338,8",
    t_global: 45,
    z_global: "10612,85",
  },
  {
    profile_section_id: 8831,
    name: "530x48",
    type: "Pipe",
    designation: "530x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "726,84",
    d_global: 530,
    i_global: "213170,9",
    t_global: 48,
    z_global: "11185,62",
  },
  {
    profile_section_id: 8832,
    name: "530x50",
    type: "Pipe",
    designation: "530x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "753,98",
    d_global: 530,
    i_global: "219503,1",
    t_global: 50,
    z_global: "11558,78",
  },
  {
    profile_section_id: 8833,
    name: "530x56",
    type: "Pipe",
    designation: "530x56",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "833,9",
    d_global: 530,
    i_global: "237466,8",
    t_global: 56,
    z_global: "12637,23",
  },
  {
    profile_section_id: 8834,
    name: "530x60",
    type: "Pipe",
    designation: "530x60",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "885,93",
    d_global: 530,
    i_global: "248613,9",
    t_global: 60,
    z_global: "13322,67",
  },
  {
    profile_section_id: 8835,
    name: "530x63",
    type: "Pipe",
    designation: "530x63",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "924,29",
    d_global: 530,
    i_global: "256556,9",
    t_global: 63,
    z_global: "13819,5",
  },
  {
    profile_section_id: 8836,
    name: "530x65",
    type: "Pipe",
    designation: "530x65",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "949,55",
    d_global: 530,
    i_global: "261659,4",
    t_global: 65,
    z_global: "14142,63",
  },
  {
    profile_section_id: 8837,
    name: "530x70",
    type: "Pipe",
    designation: "530x70",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "1011,59",
    d_global: 530,
    i_global: "273762,3",
    t_global: 70,
    z_global: "14922,6",
  },
  {
    profile_section_id: 8838,
    name: "530x75",
    type: "Pipe",
    designation: "530x75",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "1072,07",
    d_global: 530,
    i_global: "284969,2",
    t_global: 75,
    z_global: "15663,58",
  },
  {
    profile_section_id: 8839,
    name: "550x9",
    type: "Pipe",
    designation: "550x9",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "152,96",
    d_global: 550,
    i_global: "55977,61",
    t_global: 9,
    z_global: "2633,71",
  },
  {
    profile_section_id: 8840,
    name: "550x9.5",
    type: "Pipe",
    designation: "550x9.5",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "161,31",
    d_global: 550,
    i_global: "58925,7",
    t_global: "9,5",
    z_global: "2774,92",
  },
  {
    profile_section_id: 8841,
    name: "550x10",
    type: "Pipe",
    designation: "550x10",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "169,65",
    d_global: 550,
    i_global: "61857,18",
    t_global: 10,
    z_global: "2915,6",
  },
  {
    profile_section_id: 8842,
    name: "550x11",
    type: "Pipe",
    designation: "550x11",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "186,26",
    d_global: 550,
    i_global: "67670,55",
    t_global: 11,
    z_global: "3195,38",
  },
  {
    profile_section_id: 8843,
    name: "550x12",
    type: "Pipe",
    designation: "550x12",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "202,82",
    d_global: 550,
    i_global: "73418,24",
    t_global: 12,
    z_global: "3473,04",
  },
  {
    profile_section_id: 8844,
    name: "550x13",
    type: "Pipe",
    designation: "550x13",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "219,31",
    d_global: 550,
    i_global: "79100,74",
    t_global: 13,
    z_global: "3748,59",
  },
  {
    profile_section_id: 8845,
    name: "550x14",
    type: "Pipe",
    designation: "550x14",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "235,75",
    d_global: 550,
    i_global: "84718,54",
    t_global: 14,
    z_global: "4022,05",
  },
  {
    profile_section_id: 8846,
    name: "550x15",
    type: "Pipe",
    designation: "550x15",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "252,11",
    d_global: 550,
    i_global: "90272,14",
    t_global: 15,
    z_global: "4293,43",
  },
  {
    profile_section_id: 8847,
    name: "550x25",
    type: "Pipe",
    designation: "550x25",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "412,33",
    d_global: 550,
    i_global: "142384,1",
    t_global: 25,
    z_global: "6894,11",
  },
  {
    profile_section_id: 8848,
    name: "550x26",
    type: "Pipe",
    designation: "550x26",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "428,01",
    d_global: 550,
    i_global: "147263,5",
    t_global: 26,
    z_global: "7143,05",
  },
  {
    profile_section_id: 8849,
    name: "550x28",
    type: "Pipe",
    designation: "550x28",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "459,18",
    d_global: 550,
    i_global: "156847,4",
    t_global: 28,
    z_global: "7634,96",
  },
  {
    profile_section_id: 8850,
    name: "550x30",
    type: "Pipe",
    designation: "550x30",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "490,09",
    d_global: 550,
    i_global: "166201,3",
    t_global: 30,
    z_global: "8118,97",
  },
  {
    profile_section_id: 8851,
    name: "550x32",
    type: "Pipe",
    designation: "550x32",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "520,75",
    d_global: 550,
    i_global: "175328,8",
    t_global: 32,
    z_global: "8595,14",
  },
  {
    profile_section_id: 8852,
    name: "550x34",
    type: "Pipe",
    designation: "550x34",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "551,16",
    d_global: 550,
    i_global: "184233,8",
    t_global: 34,
    z_global: "9063,54",
  },
  {
    profile_section_id: 8853,
    name: "550x35",
    type: "Pipe",
    designation: "550x35",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "566,27",
    d_global: 550,
    i_global: 188604,
    t_global: 35,
    z_global: "9294,84",
  },
  {
    profile_section_id: 8854,
    name: "550x36",
    type: "Pipe",
    designation: "550x36",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "581,32",
    d_global: 550,
    i_global: "192919,9",
    t_global: 36,
    z_global: "9524,23",
  },
  {
    profile_section_id: 8855,
    name: "550x38",
    type: "Pipe",
    designation: "550x38",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "611,23",
    d_global: 550,
    i_global: "201390,5",
    t_global: 38,
    z_global: "9977,27",
  },
  {
    profile_section_id: 8856,
    name: "550x40",
    type: "Pipe",
    designation: "550x40",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "640,88",
    d_global: 550,
    i_global: "209649,5",
    t_global: 40,
    z_global: "10422,73",
  },
  {
    profile_section_id: 8857,
    name: "550x42",
    type: "Pipe",
    designation: "550x42",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "670,29",
    d_global: 550,
    i_global: "217700,2",
    t_global: 42,
    z_global: "10860,67",
  },
  {
    profile_section_id: 8858,
    name: "550x45",
    type: "Pipe",
    designation: "550x45",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "713,93",
    d_global: 550,
    i_global: "229393,6",
    t_global: 45,
    z_global: "11503,62",
  },
  {
    profile_section_id: 8859,
    name: "550x48",
    type: "Pipe",
    designation: "550x48",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: 757,
    d_global: 550,
    i_global: "240638,4",
    t_global: 48,
    z_global: "12130,02",
  },
  {
    profile_section_id: 8860,
    name: "550x50",
    type: "Pipe",
    designation: "550x50",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "785,4",
    d_global: 550,
    i_global: "247891,3",
    t_global: 50,
    z_global: "12538,53",
  },
  {
    profile_section_id: 8861,
    name: "550x56",
    type: "Pipe",
    designation: "550x56",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "869,09",
    d_global: 550,
    i_global: "268518,4",
    t_global: 56,
    z_global: "13721,12",
  },
  {
    profile_section_id: 8862,
    name: "550x60",
    type: "Pipe",
    designation: "550x60",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "923,63",
    d_global: 550,
    i_global: "281360,3",
    t_global: 60,
    z_global: "14474,38",
  },
  {
    profile_section_id: 8863,
    name: "550x63",
    type: "Pipe",
    designation: "550x63",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "963,87",
    d_global: 550,
    i_global: "290532,7",
    t_global: 63,
    z_global: "15021,24",
  },
  {
    profile_section_id: 8864,
    name: "550x65",
    type: "Pipe",
    designation: "550x65",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "990,39",
    d_global: 550,
    i_global: "296435,2",
    t_global: 65,
    z_global: "15377,32",
  },
  {
    profile_section_id: 8865,
    name: "550x70",
    type: "Pipe",
    designation: "550x70",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "1055,58",
    d_global: 550,
    i_global: 310471,
    t_global: 70,
    z_global: "16238,27",
  },
  {
    profile_section_id: 8866,
    name: "550x75",
    type: "Pipe",
    designation: "550x75",
    shape: "PIPE",
    country_code: "Russian (GOST)",
    ax_global: "1119,19",
    d_global: 550,
    i_global: "323516,6",
    t_global: 75,
    z_global: "17058,23",
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: null,
    bf: null,
    c: null,
    ct: null,
    d: null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: null,
    i: null,
    i_global: p.i_global ? fixValueToNumber(p.i_global, "float") : undefined,
    ix: null,
    iy: null,
    iz: null,
    k: null,
    k1: null,
    od: null,
    r1: null,
    r2: null,
    rz: null,
    t: null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: null,
    tw: null,
    z: null,
    z_global: p.z_global ? fixValueToNumber(p.z_global, "float") : undefined,
    zx: null,
    zy: null,
    zz: null,
    width: 0,
    width_global: 0,
    thickness: 0,
    thickness_global: 0,
    height: 0,
    height_global: 0,
  };
  return profile;
});
