// import React, { FunctionComponent, useEffect, useRef, useState } from "react";
// import { FormGroup, Button } from "@blueprintjs/core";
// import { CustomDlg } from "../../common/CustomDlg";
// import { ModelType, TWorkMode } from "../../../store/main/types";
// import { SimpleSelector } from "../../common/SimpleSelector";

// type Props = {
//   mode: TWorkMode;
//   title?: string;
//   extensions?: string[];
//   onClose: (file?: File, type?: ModelType) => any;
// };

// const OpenModelDlg: FunctionComponent<Props> = (props) => {
//   const { mode, title, extensions, onClose } = props;

//   const [type, setType] = useState<ModelType>();
//   const [file, setFile] = useState<File>();

//   useEffect(() => {
//     if ((mode === "STRUCTURE" && !type) || !file) return;
//     onClose(file, type);
//   }, [mode, file, type]);

//   function handleCLoseDlg(event: React.ChangeEvent<HTMLInputElement>) {
//     event.currentTarget.files && setFile(event.currentTarget.files[0]);
//   }

//   return (
//     <CustomDlg
//       zIndex={10}
//       isMinimize={true}
//       position={"center"}
//       title={title ?? "Open Project"}
//       body={
//         <>
//           {mode === "STRUCTURE" ? (
//             <SimpleSelector<ModelType>
//               label={"Type of Models"}
//               items={["Flare", "Pipe Rack", "Open Frame", "Factory Shed"]}
//               selected={type}
//               onSelect={setType}
//               className={"fill-select"}
//               itemLabel={(item) => item}
//             />
//           ) : null}
//           <FormGroup label="Select File">
//             <input
//               type="file"
//               onChange={handleCLoseDlg}
//               accept={extensions?.join(", ")}
//               disabled={mode === "STRUCTURE" && !type}
//             />
//           </FormGroup>
//         </>
//       }
//       actions={<Button text={"Cancel"} onClick={() => onClose()} />}
//       onClose={() => onClose()}
//     />
//   );
// };

// export default OpenModelDlg;



import React, { FunctionComponent, useEffect, useState } from "react";
import { FormGroup, Button } from "@blueprintjs/core";
import { CustomDlg } from "../../common/CustomDlg";
import { ModelType, TWorkMode } from "../../../store/main/types";
import { SimpleSelector } from "../../common/SimpleSelector";
import { loadGLTF2 } from "../ga-drawings-tab/exchangeUtils";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { secondServerAPI } from "../../../pages/utils/agent";
import Axios from "axios";

type Props = {
  mode: TWorkMode;
  title?: string;
  extensions?: string[];
  onClose: (file?: File, type?: ModelType) => any;
};

const OpenModelDlg: FunctionComponent<Props> = (props) => {
  const { mode, title, extensions, onClose } = props;

  const [type, setType] = useState<ModelType>();
  const [file, setFile] = useState<File>();
  const [projects, setProjects] = useState<{ [key: string]: number }>({});

  const scene = useSelector((state: ApplicationState) => state.main.scene);
  const auth = useSelector((state: ApplicationState) => state.auth);

  useEffect(() => {
    const fetchProjects = async () => {
        try {
            const response = await Axios.get(`${secondServerAPI}rest/api/v1/ufc/dashboard/projects`, {
                headers: {
                    "user-id": auth.User_id,
                    // "user-id": 1,
                },
            });
            if (response.status === 200) {
                const projectsData = response.data.projectResponses.reduce((acc: { [key: string]: number }, proj: any) => {
                    acc[proj.projectName] = proj.projectId;
                    return acc;
                }, {});
                setProjects(projectsData);
            }
        } catch (error) {
            console.error("Error fetching projects:", error);
        }
    };

    fetchProjects();
}, [auth.User_id]);

  useEffect(() => {
    if ((mode === "STRUCTURE" && !type) || !file) return;
    onClose(file, type);
  }, [mode, file, type]);

  // async function handleCLoseDlg(event: React.ChangeEvent<HTMLInputElement>) {
  //   const file = event.currentTarget.files && event.currentTarget.files[0];
  //   if (file) {
  //     setFile(file);
  //     const reader = new FileReader();
  //     reader.onload = async (e) => {
  //       try {
  //         const content = e.target?.result;
  //         if (content) {
  //           const parsedContent = JSON.parse(content as string);
  //           console.log(parsedContent.GLTFItems);
  //           const modifiedItems = parsedContent.GLTFItems.map((item: any) => ({
  //             ...item,
  //             position: {
  //               x: item.position.x,
  //               y: item.position.y,
  //               z: item.position.z,
  //             },
  //             rotation: {
  //               x: item.rotation.x,
  //               y: item.rotation.y,
  //               z: item.rotation.z,
  //             },
  //             scale: {
  //               x: item.scale.x,
  //               y: item.scale.y,
  //               z: item.scale.z,
  //             },
  //             fileName: item.name,
  //           }));

  //           const projectName = parsedContent.GLTFItems[0]?.projectname;
            

  //           for (let index = 0; index < modifiedItems.length; index++) {
  //             const item = modifiedItems[index];
  //             const projectName = item.projectname;
  //             const ProjectId = projects[projectName];
  //             try {
  //               const response = await fetch(`http://testing.asets-ca.com:8080/download-file`, {
  //                 method: 'GET',
  //                 headers: {
  //                   'project-id': ProjectId,
  //                   'file-name': item.fileName,
  //                 }
  //               });

  //               if (response.ok) {
  //                 const arrayBuffer = await response.arrayBuffer();
  //                 const blob = new Blob([arrayBuffer], { type: 'model/gltf+json' });
  //                 const newFile = new File([blob], item.fileName, { type: 'gltf' });
  //                 console.log("this is the trial file", newFile);
  //                 console.log("this is the project name", projectName);

  //                 loadGLTF2(newFile, projectName, (data) => {
  //                   data.traverse((child) => {
  //                     if (child.isMesh) {
  //                       console.log("Original Position", item.position);
  //                       child.position.set(
  //                         item.position.x,
  //                         item.position.y,
  //                         item.position.z
  //                       );
  //                       child.rotation.set(
  //                         item.rotation.x,
  //                         item.rotation.y,
  //                         item.rotation.z
  //                       );
  //                       child.scale.set(
  //                         item.scale.x,
  //                         item.scale.y,
  //                         item.scale.z
  //                       );
  //                       console.log("Set Position", child.position);
  //                     }
  //                   });
  //                   scene.add(data);
  //                   console.log("scene info", scene);
  //                 });
  //               } else {
  //                 console.error('Failed to fetch file');
  //               }
  //             } catch (error) {
  //               console.error('Error fetching file:', error);
  //             }
  //           }
  //         }
  //       } catch (error) {
  //         console.error("Error parsing file content:", error);
  //       }
  //     };
  //     reader.readAsText(file);
  //     console.log("this is the current scene", scene);
  //   }
  // }

  async function handleCLoseDlg(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.currentTarget.files && event.currentTarget.files[0];
    if (file) {
        setFile(file);

        const fileName = file.name;
        const fileExtension = fileName.split('.').pop()?.toLowerCase();

        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const content = e.target?.result;

                if (content) {
                    if (fileExtension === 'gltf') {
                        const parsedContent = JSON.parse(content as string);

                        if (parsedContent.buffers && parsedContent.buffers[0]?.uri.startsWith('data:')) {
                            console.log("GLTF contains embedded binary data");

                            const base64Data = parsedContent.buffers[0].uri.split(',')[1];
                            const binaryData = Uint8Array.from(atob(base64Data), c => c.charCodeAt(0));
                            const blob = new Blob([binaryData], { type: 'application/octet-stream' });

                            const newFile = new File([blob], file.name, { type: 'model/gltf+json' });

                            loadGLTF2(newFile, "", (data) => {
                                scene.add(data);
                                console.log("Loaded GLTF file with embedded binary data into scene", scene);
                            });
                        } else {
                            const modifiedItems = parsedContent.GLTFItems.map((item: any) => ({
                                ...item,
                                position: {
                                    x: item.position.x,
                                    y: item.position.y,
                                    z: item.position.z,
                                },
                                rotation: {
                                    x: item.rotation.x,
                                    y: item.rotation.y,
                                    z: item.rotation.z,
                                },
                                scale: {
                                    x: item.scale.x,
                                    y: item.scale.y,
                                    z: item.scale.z,
                                },
                                fileName: item.name,
                            }));

                            const projectName = parsedContent.GLTFItems[0]?.projectname;

                            for (let index = 0; index < modifiedItems.length; index++) {
                                const item = modifiedItems[index];
                                const projectName = item.projectname;
                                const ProjectId = projects[projectName];
                                try {
                                    const response = await fetch(`http://testing.asets-ca.com:8080/download-file`, {
                                        method: 'GET',
                                        headers: {
                                            'project-id': ProjectId,
                                            'file-name': item.fileName,
                                        }
                                    });

                                    if (response.ok) {
                                        const arrayBuffer = await response.arrayBuffer();
                                        const blob = new Blob([arrayBuffer], { type: 'model/gltf+json' });
                                        const newFile = new File([blob], item.fileName, { type: 'gltf' });
                                        console.log("this is the trial file", newFile);
                                        console.log("this is the project name", projectName);

                                        loadGLTF2(newFile, projectName, (data) => {
                                            data.traverse((child) => {
                                                if (child.isMesh) {
                                                    console.log("Original Position", item.position);
                                                    child.position.set(
                                                        item.position.x,
                                                        item.position.y,
                                                        item.position.z
                                                    );
                                                    child.rotation.set(
                                                        item.rotation.x,
                                                        item.rotation.y,
                                                        item.rotation.z
                                                    );
                                                    child.scale.set(
                                                        item.scale.x,
                                                        item.scale.y,
                                                        item.scale.z
                                                    );
                                                    console.log("Set Position", child.position);
                                                }
                                            });
                                            scene.add(data);
                                            console.log("scene info", scene);
                                        });
                                    } else {
                                        console.error('Failed to fetch file');
                                    }
                                } catch (error) {
                                    console.error('Error fetching file:', error);
                                }
                            }
                        }
                    } else if (fileExtension === 'glb') {
                        // Handle GLB files as binary
                        const arrayBuffer = await file.arrayBuffer();
                        const blob = new Blob([arrayBuffer], { type: 'model/gltf-binary' });
                        const newFile = new File([blob], file.name, { type: 'model/gltf-binary' });

                        loadGLTF2(newFile, "", (data) => {
                            scene.add(data);
                            console.log("Loaded GLB file into scene", scene);
                        });
                    }
                }
            } catch (error) {
                console.error("Error parsing file content:", error);
            }
        };

        if (fileExtension === 'gltf') {
            reader.readAsText(file);
        } else if (fileExtension === 'glb') {
            reader.readAsArrayBuffer(file);
        }

        console.log("this is the current scene", scene);
    }
}


  return (
    <CustomDlg
      zIndex={10}
      isMinimize={true}
      position={"center"}
      title={title ?? "Open Project"}
      body={
        <>
          {mode === "STRUCTURE" ? (
            <SimpleSelector<ModelType>
              label={"Type of Models"}
              items={["Flare", "Pipe Rack", "Open Frame", "Factory Shed"]}
              selected={type}
              onSelect={setType}
              className={"fill-select"}
              itemLabel={(item) => item}
            />
          ) : null}
          <FormGroup label="Select File">
            <input
              type="file"
              onChange={handleCLoseDlg}
              accept={extensions?.join(", ")}
              disabled={mode === "STRUCTURE" && !type}
            />
          </FormGroup>
        </>
      }
      actions={<Button text={"Cancel"} onClick={() => onClose()} />}
      onClose={() => onClose()}
    />
  );
};

export default OpenModelDlg;


