import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesAmerican3 = [
  {
    profile_section_id: 1110,
    name: "HSS6X4X1/4",
    type: "HSS Rectangle",
    designation: "HSST6X4X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "4,3",
    ax_global: "2774,188",
    b: 4,
    b_global: "101,6",
    c: "10,1",
    c_global: "165509,3464",
    d: 6,
    d_global: "152,4",
    ix: "23,6",
    ix_global: "9823061,644",
    iy: "11,1",
    iy_global: "4620168,824",
    iz: "20,9",
    iz_global: "8699236,795",
    t: "0,233",
    t_global: "5,9182",
    zy: "6,45",
    zy_global: "105696,5628",
    zz: "8,53",
    zz_global: "139781,6559",
  },
  {
    profile_section_id: 1111,
    name: "HSS6X4X3/16",
    type: "HSS Rectangle",
    designation: "HSST6X4X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,28",
    ax_global: "2116,1248",
    b: 4,
    b_global: "101,6",
    c: "7,74",
    c_global: "126835,8754",
    d: 6,
    d_global: "152,4",
    ix: "18,2",
    ix_global: "7575411,946",
    iy: "8,76",
    iy_global: "3646187,288",
    iz: "16,4",
    iz_global: "6826195,38",
    t: "0,174",
    t_global: "4,4196",
    zy: 5,
    zy_global: "81935,32",
    zz: "6,6",
    zz_global: "108154,6224",
  },
  {
    profile_section_id: 1112,
    name: "HSS6X4X1/8",
    type: "HSS Rectangle",
    designation: "HSST6X4X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,23",
    ax_global: "1438,7068",
    b: 4,
    b_global: "101,6",
    c: "5,3",
    c_global: "86851,4392",
    d: 6,
    d_global: "152,4",
    ix: "12,6",
    ix_global: "5244515,963",
    iy: "6,15",
    iy_global: "2559823,267",
    iz: "11,4",
    iz_global: "4745038,252",
    t: "0,116",
    t_global: "2,9464",
    zy: "3,46",
    zy_global: "56699,24144",
    zz: "4,56",
    zz_global: "74725,01184",
  },
  {
    profile_section_id: 1113,
    name: "HSS6X3X1/2",
    type: "HSS Rectangle",
    designation: "HSST6X3X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "6,95",
    ax_global: "4483,862",
    b: 3,
    b_global: "76,2",
    c: "12,7",
    c_global: "208115,7128",
    d: 6,
    d_global: "152,4",
    ix: "23,1",
    ix_global: "9614945,931",
    iy: "8,69",
    iy_global: "3617051,088",
    iz: "26,8",
    iz_global: "11155002,21",
    t: "0,465",
    t_global: "11,811",
    zy: "7,28",
    zy_global: "119297,8259",
    zz: "12,1",
    zz_global: "198283,4744",
  },
  {
    profile_section_id: 1114,
    name: "HSS6X3X3/8",
    type: "HSS Rectangle",
    designation: "HSST6X3X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "5,48",
    ax_global: "3535,4768",
    b: 3,
    b_global: "76,2",
    c: "10,3",
    c_global: "168786,7592",
    d: 6,
    d_global: "152,4",
    ix: "19,3",
    ix_global: "8033266,514",
    iy: "7,48",
    iy_global: "3113411,063",
    iz: "22,7",
    iz_global: "9448453,361",
    t: "0,349",
    t_global: "8,8646",
    zy: "6,03",
    zy_global: "98813,99592",
    zz: "9,9",
    zz_global: "162231,9336",
  },
  {
    profile_section_id: 1115,
    name: "HSS6X3X5/16",
    type: "HSS Rectangle",
    designation: "HSST6X3X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "4,68",
    ax_global: "3019,3488",
    b: 3,
    b_global: "76,2",
    c: "8,91",
    c_global: "146008,7402",
    d: 6,
    d_global: "152,4",
    ix: "16,9",
    ix_global: "7034311,093",
    iy: "6,67",
    iy_global: "2776263,609",
    iz: "20,1",
    iz_global: "8366251,655",
    t: "0,291",
    t_global: "7,3914",
    zy: "5,27",
    zy_global: "86359,82728",
    zz: "8,61",
    zz_global: "141092,621",
  },
  {
    profile_section_id: 1116,
    name: "HSS6X3X1/4",
    type: "HSS Rectangle",
    designation: "HSST6X3X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "3,84",
    ax_global: "2477,4144",
    b: 3,
    b_global: "76,2",
    c: "7,39",
    c_global: "121100,403",
    d: 6,
    d_global: "152,4",
    ix: "14,2",
    ix_global: "5910486,244",
    iy: "5,7",
    iy_global: "2372519,126",
    iz: 17,
    iz_global: "7075934,235",
    t: "0,233",
    t_global: "5,9182",
    zy: "4,41",
    zy_global: "72266,95224",
    zz: "7,19",
    zz_global: "117822,9902",
  },
  {
    profile_section_id: 1117,
    name: "HSS6X3X3/16",
    type: "HSS Rectangle",
    designation: "HSST6X3X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "2,93",
    ax_global: "1890,3188",
    b: 3,
    b_global: "76,2",
    c: "5,71",
    c_global: "93570,13544",
    d: 6,
    d_global: "152,4",
    ix: "11,1",
    ix_global: "4620168,824",
    iy: "4,55",
    iy_global: "1893852,986",
    iz: "13,4",
    iz_global: "5577501,103",
    t: "0,174",
    t_global: "4,4196",
    zy: "3,45",
    zy_global: "56535,3708",
    zz: "5,59",
    zz_global: "91603,68776",
  },
  {
    profile_section_id: 1118,
    name: "HSS6X3X1/8",
    type: "HSS Rectangle",
    designation: "HSST6X3X0.125",
    shape: "RHS",
    country_code: "American",
    ax: 2,
    ax_global: "1290,32",
    b: 3,
    b_global: "76,2",
    c: "3,93",
    c_global: "64401,16152",
    d: 6,
    d_global: "152,4",
    ix: "7,73",
    ix_global: "3217468,92",
    iy: "3,23",
    iy_global: "1344427,505",
    iz: "9,43",
    iz_global: "3925062,343",
    t: "0,116",
    t_global: "2,9464",
    zy: "2,4",
    zy_global: "39328,9536",
    zz: "3,87",
    zz_global: "63417,93768",
  },
  {
    profile_section_id: 1119,
    name: "HSS6X2X3/8",
    type: "HSS Rectangle",
    designation: "HSST6X2X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "4,78",
    ax_global: "3083,8648",
    b: 2,
    b_global: "50,8",
    c: "6,35",
    c_global: "104057,8564",
    d: 6,
    d_global: "152,4",
    ix: "8,42",
    ix_global: "3504668,604",
    iy: "2,77",
    iy_global: "1152961,049",
    iz: "17,1",
    iz_global: "7117557,378",
    t: "0,349",
    t_global: "8,8646",
    zy: "3,46",
    zy_global: "56699,24144",
    zz: "7,93",
    zz_global: "129949,4175",
  },
  {
    profile_section_id: 1120,
    name: "HSS6X2X5/16",
    type: "HSS Rectangle",
    designation: "HSST6X2X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "4,1",
    ax_global: "2645,156",
    b: 2,
    b_global: "50,8",
    c: "5,58",
    c_global: "91439,81712",
    d: 6,
    d_global: "152,4",
    ix: "7,6",
    ix_global: "3163358,835",
    iy: "2,52",
    iy_global: "1048903,193",
    iz: "15,3",
    iz_global: "6368340,812",
    t: "0,291",
    t_global: "7,3914",
    zy: "3,07",
    zy_global: "50308,28648",
    zz: "6,95",
    zz_global: "113890,0948",
  },
  {
    profile_section_id: 1121,
    name: "HSS6X2X1/4",
    type: "HSS Rectangle",
    designation: "HSST6X2X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "3,37",
    ax_global: "2174,1892",
    b: 2,
    b_global: "50,8",
    c: "4,7",
    c_global: "77019,2008",
    d: 6,
    d_global: "152,4",
    ix: "6,55",
    ix_global: "2726315,838",
    iy: "2,21",
    iy_global: "919871,4506",
    iz: "13,1",
    iz_global: "5452631,675",
    t: "0,233",
    t_global: "5,9182",
    zy: "2,61",
    zy_global: "42770,23704",
    zz: "5,84",
    zz_global: "95700,45376",
  },
  {
    profile_section_id: 1122,
    name: "HSS6X2X3/16",
    type: "HSS Rectangle",
    designation: "HSST6X2X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "2,58",
    ax_global: "1664,5128",
    b: 2,
    b_global: "50,8",
    c: "3,68",
    c_global: "60304,39552",
    d: 6,
    d_global: "152,4",
    ix: "5,24",
    ix_global: "2181052,67",
    iy: "1,8",
    iy_global: "749216,5661",
    iz: "10,5",
    iz_global: "4370429,969",
    t: "0,174",
    t_global: "4,4196",
    zy: "2,07",
    zy_global: "33921,22248",
    zz: "4,58",
    zz_global: "75052,75312",
  },
  {
    profile_section_id: 1123,
    name: "HSS6X2X1/8",
    type: "HSS Rectangle",
    designation: "HSST6X2X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,77",
    ax_global: "1141,9332",
    b: 2,
    b_global: "50,8",
    c: "2,57",
    c_global: "42114,75448",
    d: 6,
    d_global: "152,4",
    ix: "3,72",
    ix_global: "1548380,903",
    iy: "1,31",
    iy_global: "545263,1675",
    iz: "7,42",
    iz_global: "3088437,178",
    t: "0,116",
    t_global: "2,9464",
    zy: "1,46",
    zy_global: "23925,11344",
    zz: "3,19",
    zz_global: "52274,73416",
  },
  {
    profile_section_id: 1124,
    name: "HSS5-1/2X5-1/2X3/8",
    type: "HSS Rectangle",
    designation: "HSST5.5X5.5X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "6,88",
    ax_global: "4438,7008",
    b: "5,5",
    b_global: "139,7",
    c: "18,4",
    c_global: "301521,9776",
    d: "5,5",
    d_global: "139,7",
    ix: 49,
    ix_global: "20395339,85",
    iy: "29,7",
    iy_global: "12362073,34",
    iz: "29,7",
    iz_global: "12362073,34",
    t: "0,349",
    t_global: "8,8646",
    zy: "13,1",
    zy_global: "214670,5384",
    zz: "13,1",
    zz_global: "214670,5384",
  },
  {
    profile_section_id: 1125,
    name: "HSS5-1/2X5-1/2X5/16",
    type: "HSS Rectangle",
    designation: "HSST5.5X5.5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "5,85",
    ax_global: "3774,186",
    b: "5,5",
    b_global: "139,7",
    c: "15,7",
    c_global: "257276,9048",
    d: "5,5",
    d_global: "139,7",
    ix: "42,2",
    ix_global: "17564966,16",
    iy: "25,9",
    iy_global: "10780393,92",
    iz: "25,9",
    iz_global: "10780393,92",
    t: "0,291",
    t_global: "7,3914",
    zy: "11,3",
    zy_global: "185173,8232",
    zz: "11,3",
    zz_global: "185173,8232",
  },
  {
    profile_section_id: 1126,
    name: "HSS5-1/2X5-1/2X1/4",
    type: "HSS Rectangle",
    designation: "HSST5.5X5.5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "4,77",
    ax_global: "3077,4132",
    b: "5,5",
    b_global: "139,7",
    c: "12,9",
    c_global: "211393,1256",
    d: "5,5",
    d_global: "139,7",
    ix: "34,8",
    ix_global: "14484853,61",
    iy: "21,7",
    iy_global: "9032221,936",
    iz: "21,7",
    iz_global: "9032221,936",
    t: "0,233",
    t_global: "5,9182",
    zy: "9,32",
    zy_global: "152727,4365",
    zz: "9,32",
    zz_global: "152727,4365",
  },
  {
    profile_section_id: 1127,
    name: "HSS5-1/2X5-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSST5.5X5.5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,63",
    ax_global: "2341,9308",
    b: "5,5",
    b_global: "139,7",
    c: "9,85",
    c_global: "161412,5804",
    d: "5,5",
    d_global: "139,7",
    ix: "26,7",
    ix_global: "11113379,06",
    iy: 17,
    iy_global: "7075934,235",
    iz: 17,
    iz_global: "7075934,235",
    t: "0,174",
    t_global: "4,4196",
    zy: "7,19",
    zy_global: "117822,9902",
    zz: "7,19",
    zz_global: "117822,9902",
  },
  {
    profile_section_id: 1128,
    name: "HSS5-1/2X5-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSST5.5X5.5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,46",
    ax_global: "1587,0936",
    b: "5,5",
    b_global: "139,7",
    c: "6,72",
    c_global: "110121,0701",
    d: "5,5",
    d_global: "139,7",
    ix: "18,3",
    ix_global: "7617035,088",
    iy: "11,8",
    iy_global: "4911530,822",
    iz: "11,8",
    iz_global: "4911530,822",
    t: "0,116",
    t_global: "2,9464",
    zy: "4,95",
    zy_global: "81115,9668",
    zz: "4,95",
    zz_global: "81115,9668",
  },
  {
    profile_section_id: 1129,
    name: "HSS5X5X1/2",
    type: "HSS Rectangle",
    designation: "HSST5X5X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "7,88",
    ax_global: "5083,8608",
    b: 5,
    b_global: 127,
    c: "18,7",
    c_global: "306438,0968",
    d: 5,
    d_global: 127,
    ix: "44,6",
    ix_global: "18563921,58",
    iy: 26,
    iy_global: "10822017,07",
    iz: 26,
    iz_global: "10822017,07",
    t: "0,465",
    t_global: "11,811",
    zy: "13,1",
    zy_global: "214670,5384",
    zz: "13,1",
    zz_global: "214670,5384",
  },
  {
    profile_section_id: 1130,
    name: "HSS5X5X3/8",
    type: "HSS Rectangle",
    designation: "HSST5X5X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "6,18",
    ax_global: "3987,0888",
    b: 5,
    b_global: 127,
    c: "14,9",
    c_global: "244167,2536",
    d: 5,
    d_global: 127,
    ix: "36,1",
    ix_global: "15025954,46",
    iy: "21,7",
    iy_global: "9032221,936",
    iz: "21,7",
    iz_global: "9032221,936",
    t: "0,349",
    t_global: "8,8646",
    zy: "10,6",
    zy_global: "173702,8784",
    zz: "10,6",
    zz_global: "173702,8784",
  },
  {
    profile_section_id: 1131,
    name: "HSS5X5X5/16",
    type: "HSS Rectangle",
    designation: "HSST5X5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "5,26",
    ax_global: "3393,5416",
    b: 5,
    b_global: 127,
    c: "12,8",
    c_global: "209754,4192",
    d: 5,
    d_global: 127,
    ix: "31,2",
    ix_global: "12986420,48",
    iy: 19,
    iy_global: "7908397,086",
    iz: 19,
    iz_global: "7908397,086",
    t: "0,291",
    t_global: "7,3914",
    zy: "9,16",
    zy_global: "150105,5062",
    zz: "9,16",
    zz_global: "150105,5062",
  },
  {
    profile_section_id: 1132,
    name: "HSS5X5X1/4",
    type: "HSS Rectangle",
    designation: "HSST5X5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "4,3",
    ax_global: "2774,188",
    b: 5,
    b_global: 127,
    c: "10,5",
    c_global: "172064,172",
    d: 5,
    d_global: 127,
    ix: "25,8",
    ix_global: "10738770,78",
    iy: 16,
    iy_global: "6659702,81",
    iz: 16,
    iz_global: "6659702,81",
    t: "0,233",
    t_global: "5,9182",
    zy: "7,61",
    zy_global: "124705,557",
    zz: "7,61",
    zz_global: "124705,557",
  },
  {
    profile_section_id: 1133,
    name: "HSS5X5X3/16",
    type: "HSS Rectangle",
    designation: "HSST5X5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "3,28",
    ax_global: "2116,1248",
    b: 5,
    b_global: 127,
    c: "8,08",
    c_global: "132407,4771",
    d: 5,
    d_global: 127,
    ix: "19,9",
    ix_global: "8283005,369",
    iy: "12,6",
    iy_global: "5244515,963",
    iz: "12,6",
    iz_global: "5244515,963",
    t: "0,174",
    t_global: "4,4196",
    zy: "5,89",
    zy_global: "96519,80696",
    zz: "5,89",
    zz_global: "96519,80696",
  },
  {
    profile_section_id: 1134,
    name: "HSS5X5X1/8",
    type: "HSS Rectangle",
    designation: "HSST5X5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "2,23",
    ax_global: "1438,7068",
    b: 5,
    b_global: 127,
    c: "5,53",
    c_global: "90620,46392",
    d: 5,
    d_global: 127,
    ix: "13,7",
    ix_global: "5702370,531",
    iy: "8,8",
    iy_global: "3662836,545",
    iz: "8,8",
    iz_global: "3662836,545",
    t: "0,116",
    t_global: "2,9464",
    zy: "4,07",
    zy_global: "66695,35048",
    zz: "4,07",
    zz_global: "66695,35048",
  },
  {
    profile_section_id: 1135,
    name: "HSS5X4X1/2",
    type: "HSS Rectangle",
    designation: "HSST5X4X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "6,95",
    ax_global: "4483,862",
    b: 4,
    b_global: "101,6",
    c: "14,5",
    c_global: "237612,428",
    d: 5,
    d_global: 127,
    ix: "30,3",
    ix_global: "12611812,2",
    iy: "14,9",
    iy_global: "6201848,241",
    iz: "21,2",
    iz_global: "8824106,223",
    t: "0,465",
    t_global: "11,811",
    zy: "9,35",
    zy_global: "153219,0484",
    zz: "10,9",
    zz_global: "178618,9976",
  },
  {
    profile_section_id: 1136,
    name: "HSS5X4X3/8",
    type: "HSS Rectangle",
    designation: "HSST5X4X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "5,48",
    ax_global: "3535,4768",
    b: 4,
    b_global: "101,6",
    c: "11,7",
    c_global: "191728,6488",
    d: 5,
    d_global: 127,
    ix: "24,9",
    ix_global: "10364162,5",
    iy: "12,6",
    iy_global: "5244515,963",
    iz: "17,9",
    iz_global: "7450542,518",
    t: "0,349",
    t_global: "8,8646",
    zy: "7,67",
    zy_global: "125688,7809",
    zz: "8,96",
    zz_global: "146828,0934",
  },
  {
    profile_section_id: 1137,
    name: "HSS5X4X5/16",
    type: "HSS Rectangle",
    designation: "HSST5X4X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "4,68",
    ax_global: "3019,3488",
    b: 4,
    b_global: "101,6",
    c: "10,1",
    c_global: "165509,3464",
    d: 5,
    d_global: 127,
    ix: "21,7",
    ix_global: "9032221,936",
    iy: "11,1",
    iy_global: "4620168,824",
    iz: "15,8",
    iz_global: "6576456,524",
    t: "0,291",
    t_global: "7,3914",
    zy: "6,67",
    zy_global: "109301,7169",
    zz: "7,79",
    zz_global: "127655,2286",
  },
  {
    profile_section_id: 1138,
    name: "HSS5X4X1/4",
    type: "HSS Rectangle",
    designation: "HSST5X4X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "3,84",
    ax_global: "2477,4144",
    b: 4,
    b_global: "101,6",
    c: "8,32",
    c_global: "136340,3725",
    d: 5,
    d_global: 127,
    ix: 18,
    ix_global: "7492165,661",
    iy: "9,46",
    iy_global: "3937549,286",
    iz: "13,4",
    iz_global: "5577501,103",
    t: "0,233",
    t_global: "5,9182",
    zy: "5,57",
    zy_global: "91275,94648",
    zz: "6,49",
    zz_global: "106352,0454",
  },
  {
    profile_section_id: 1139,
    name: "HSS5X4X3/16",
    type: "HSS Rectangle",
    designation: "HSST5X4X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "2,93",
    ax_global: "1890,3188",
    b: 4,
    b_global: "101,6",
    c: "6,41",
    c_global: "105041,0802",
    d: 5,
    d_global: 127,
    ix: 14,
    ix_global: "5827239,958",
    iy: "7,48",
    iy_global: "3113411,063",
    iz: "10,6",
    iz_global: "4412053,111",
    t: "0,174",
    t_global: "4,4196",
    zy: "4,34",
    zy_global: "71119,85776",
    zz: "5,05",
    zz_global: "82754,6732",
  },
  {
    profile_section_id: 1140,
    name: "HSS5X4X1/8",
    type: "HSS Rectangle",
    designation: "HSST5X4X0.125",
    shape: "RHS",
    country_code: "American",
    ax: 2,
    ax_global: "1290,32",
    b: 4,
    b_global: "101,6",
    c: "4,39",
    c_global: "71939,21096",
    d: 5,
    d_global: 127,
    ix: "9,66",
    ix_global: "4020795,571",
    iy: "5,27",
    iy_global: "2193539,613",
    iz: "7,42",
    iz_global: "3088437,178",
    t: "0,116",
    t_global: "2,9464",
    zy: "3,01",
    zy_global: "49325,06264",
    zz: "3,5",
    zz_global: "57354,724",
  },
  {
    profile_section_id: 1141,
    name: "HSS5X3X1/2",
    type: "HSS Rectangle",
    designation: "HSST5X3X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "6,02",
    ax_global: "3883,8632",
    b: 3,
    b_global: "76,2",
    c: "10,3",
    c_global: "168786,7592",
    d: 5,
    d_global: 127,
    ix: "17,6",
    ix_global: "7325673,091",
    iy: "7,18",
    iy_global: "2988541,636",
    iz: "16,4",
    iz_global: "6826195,38",
    t: "0,465",
    t_global: "11,811",
    zy: "6,1",
    zy_global: "99961,0904",
    zz: "8,83",
    zz_global: "144697,7751",
  },
  {
    profile_section_id: 1142,
    name: "HSS5X3X3/8",
    type: "HSS Rectangle",
    designation: "HSST5X3X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "4,78",
    ax_global: "3083,8648",
    b: 3,
    b_global: "76,2",
    c: "8,44",
    c_global: "138306,8202",
    d: 5,
    d_global: 127,
    ix: "14,9",
    ix_global: "6201848,241",
    iy: "6,25",
    iy_global: "2601446,41",
    iz: "14,1",
    iz_global: "5868863,101",
    t: "0,349",
    t_global: "8,8646",
    zy: "5,1",
    zy_global: "83574,0264",
    zz: "7,34",
    zz_global: "120281,0498",
  },
  {
    profile_section_id: 1143,
    name: "HSS5X3X5/16",
    type: "HSS Rectangle",
    designation: "HSST5X3X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "4,1",
    ax_global: "2645,156",
    b: 3,
    b_global: "76,2",
    c: "7,33",
    c_global: "120117,1791",
    d: 5,
    d_global: 127,
    ix: "13,1",
    ix_global: "5452631,675",
    iy: "5,6",
    iy_global: "2330895,983",
    iz: "12,6",
    iz_global: "5244515,963",
    t: "0,291",
    t_global: "7,3914",
    zy: "4,48",
    zy_global: "73414,04672",
    zz: "6,42",
    zz_global: "105204,9509",
  },
  {
    profile_section_id: 1144,
    name: "HSS5X3X1/4",
    type: "HSS Rectangle",
    designation: "HSST5X3X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "3,37",
    ax_global: "2174,1892",
    b: 3,
    b_global: "76,2",
    c: "6,1",
    c_global: "99961,0904",
    d: 5,
    d_global: 127,
    ix: 11,
    ix_global: "4578545,682",
    iy: "4,81",
    iy_global: "2002073,157",
    iz: "10,7",
    iz_global: "4453676,254",
    t: "0,233",
    t_global: "5,9182",
    zy: "3,77",
    zy_global: "61779,23128",
    zz: "5,38",
    zz_global: "88162,40432",
  },
  {
    profile_section_id: 1145,
    name: "HSS5X3X3/16",
    type: "HSS Rectangle",
    designation: "HSST5X3X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "2,58",
    ax_global: "1664,5128",
    b: 3,
    b_global: "76,2",
    c: "4,73",
    c_global: "77510,81272",
    d: 5,
    d_global: 127,
    ix: "8,64",
    ix_global: "3596239,517",
    iy: "3,85",
    iy_global: "1602490,989",
    iz: "8,53",
    iz_global: "3550454,06",
    t: "0,174",
    t_global: "4,4196",
    zy: "2,96",
    zy_global: "48505,70944",
    zz: "4,21",
    zz_global: "68989,53944",
  },
  {
    profile_section_id: 1146,
    name: "HSS5X3X1/8",
    type: "HSS Rectangle",
    designation: "HSST5X3X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,77",
    ax_global: "1141,9332",
    b: 3,
    b_global: "76,2",
    c: "3,26",
    c_global: "53421,82864",
    d: 5,
    d_global: 127,
    ix: "6,02",
    ix_global: "2505713,182",
    iy: "2,75",
    iy_global: "1144636,42",
    iz: "6,03",
    iz_global: "2509875,496",
    t: "0,116",
    t_global: "2,9464",
    zy: "2,07",
    zy_global: "33921,22248",
    zz: "2,93",
    zz_global: "48014,09752",
  },
  {
    profile_section_id: 1147,
    name: "HSS5X2-1/2X1/4",
    type: "HSS Rectangle",
    designation: "HSST5X2.5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "3,14",
    ax_global: "2025,8024",
    b: "2,5",
    b_global: "63,5",
    c: "4,99",
    c_global: "81771,44936",
    d: 5,
    d_global: 127,
    ix: "7,93",
    ix_global: "3300715,205",
    iy: "3,13",
    iy_global: "1302804,362",
    iz: "9,4",
    iz_global: "3912575,401",
    t: "0,233",
    t_global: "5,9182",
    zy: "2,95",
    zy_global: "48341,8388",
    zz: "4,83",
    zz_global: "79149,51912",
  },
  {
    profile_section_id: 1148,
    name: "HSS5X2-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSST5X2.5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "2,41",
    ax_global: "1554,8356",
    b: "2,5",
    b_global: "63,5",
    c: "3,89",
    c_global: "63745,67896",
    d: 5,
    d_global: 127,
    ix: "6,26",
    ix_global: "2605608,724",
    iy: "2,53",
    iy_global: "1053065,507",
    iz: "7,51",
    iz_global: "3125898,006",
    t: "0,174",
    t_global: "4,4196",
    zy: "2,33",
    zy_global: "38181,85912",
    zz: "3,79",
    zz_global: "62106,97256",
  },
  {
    profile_section_id: 1149,
    name: "HSS5X2-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSST5X2.5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,65",
    ax_global: "1064,514",
    b: "2,5",
    b_global: "63,5",
    c: "2,7",
    c_global: "44245,0728",
    d: 5,
    d_global: 127,
    ix: "4,4",
    ix_global: "1831418,273",
    iy: "1,82",
    iy_global: "757541,1946",
    iz: "5,34",
    iz_global: "2222675,813",
    t: "0,116",
    t_global: "2,9464",
    zy: "1,64",
    zy_global: "26874,78496",
    zz: "2,65",
    zz_global: "43425,7196",
  },
  {
    profile_section_id: 1150,
    name: "HSS5X2X3/8",
    type: "HSS Rectangle",
    designation: "HSST5X2X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "4,09",
    ax_global: "2638,7044",
    b: 2,
    b_global: "50,8",
    c: "5,2",
    c_global: "85212,7328",
    d: 5,
    d_global: 127,
    ix: "6,61",
    ix_global: "2751289,723",
    iy: "2,28",
    iy_global: "949007,6504",
    iz: "10,4",
    iz_global: "4328806,826",
    t: "0,349",
    t_global: "8,8646",
    zy: "2,88",
    zy_global: "47194,74432",
    zz: "5,71",
    zz_global: "93570,13544",
  },
  {
    profile_section_id: 1151,
    name: "HSS5X2X5/16",
    type: "HSS Rectangle",
    designation: "HSST5X2X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "3,52",
    ax_global: "2270,9632",
    b: 2,
    b_global: "50,8",
    c: "4,59",
    c_global: "75216,62376",
    d: 5,
    d_global: 127,
    ix: "5,99",
    ix_global: "2493226,239",
    iy: "2,1",
    iy_global: "874085,9938",
    iz: "9,35",
    iz_global: "3891763,829",
    t: "0,291",
    t_global: "7,3914",
    zy: "2,57",
    zy_global: "42114,75448",
    zz: "5,05",
    zz_global: "82754,6732",
  },
  {
    profile_section_id: 1152,
    name: "HSS5X2X1/4",
    type: "HSS Rectangle",
    designation: "HSST5X2X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "2,91",
    ax_global: "1877,4156",
    b: 2,
    b_global: "50,8",
    c: "3,88",
    c_global: "63581,80832",
    d: 5,
    d_global: 127,
    ix: "5,17",
    ix_global: "2151916,47",
    iy: "1,84",
    iy_global: "765865,8231",
    iz: "8,08",
    iz_global: "3363149,919",
    t: "0,233",
    t_global: "5,9182",
    zy: "2,2",
    zy_global: "36051,5408",
    zz: "4,27",
    zz_global: "69972,76328",
  },
  {
    profile_section_id: 1153,
    name: "HSS5X2X3/16",
    type: "HSS Rectangle",
    designation: "HSST5X2X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "2,24",
    ax_global: "1445,1584",
    b: 2,
    b_global: "50,8",
    c: "3,05",
    c_global: "49980,5452",
    d: 5,
    d_global: 127,
    ix: "4,15",
    ix_global: "1727360,416",
    iy: "1,51",
    iy_global: "628509,4527",
    iz: "6,5",
    iz_global: "2705504,266",
    t: "0,174",
    t_global: "4,4196",
    zy: "1,75",
    zy_global: "28677,362",
    zz: "3,37",
    zz_global: "55224,40568",
  },
  {
    profile_section_id: 1154,
    name: "HSS5X2X1/8",
    type: "HSS Rectangle",
    designation: "HSST5X2X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,54",
    ax_global: "993,5464",
    b: 2,
    b_global: "50,8",
    c: "2,13",
    c_global: "34904,44632",
    d: 5,
    d_global: 127,
    ix: "2,95",
    ix_global: "1227882,706",
    iy: "1,1",
    iy_global: "457854,5682",
    iz: "4,65",
    iz_global: "1935476,129",
    t: "0,116",
    t_global: "2,9464",
    zy: "1,24",
    zy_global: "20319,95936",
    zz: "2,37",
    zz_global: "38837,34168",
  },
  {
    profile_section_id: 1155,
    name: "HSS4-1/2X4-1/2X1/2",
    type: "HSS Rectangle",
    designation: "HSST4.5X4.5X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "6,95",
    ax_global: "4483,862",
    b: "4,5",
    b_global: "114,3",
    c: "14,8",
    c_global: "242528,5472",
    d: "4,5",
    d_global: "114,3",
    ix: "31,3",
    ix_global: "13028043,62",
    iy: "18,1",
    iy_global: "7533788,803",
    iz: "18,1",
    iz_global: "7533788,803",
    t: "0,465",
    t_global: "11,811",
    zy: "10,2",
    zy_global: "167148,0528",
    zz: "10,2",
    zz_global: "167148,0528",
  },
  {
    profile_section_id: 1156,
    name: "HSS4-1/2X4-1/2X3/8",
    type: "HSS Rectangle",
    designation: "HSST4.5X4.5X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "5,48",
    ax_global: "3535,4768",
    b: "4,5",
    b_global: "114,3",
    c: "11,9",
    c_global: "195006,0616",
    d: "4,5",
    d_global: "114,3",
    ix: "25,7",
    ix_global: "10697147,64",
    iy: "15,3",
    iy_global: "6368340,812",
    iz: "15,3",
    iz_global: "6368340,812",
    t: "0,349",
    t_global: "8,8646",
    zy: "8,36",
    zy_global: "136995,855",
    zz: "8,36",
    zz_global: "136995,855",
  },
  {
    profile_section_id: 1157,
    name: "HSS4-1/2X4-1/2X5/16",
    type: "HSS Rectangle",
    designation: "HSST4.5X4.5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "4,68",
    ax_global: "3019,3488",
    b: "4,5",
    b_global: "114,3",
    c: "10,2",
    c_global: "167148,0528",
    d: "4,5",
    d_global: "114,3",
    ix: "22,3",
    ix_global: "9281960,791",
    iy: "13,5",
    iy_global: "5619124,246",
    iz: "13,5",
    iz_global: "5619124,246",
    t: "0,291",
    t_global: "7,3914",
    zy: "7,27",
    zy_global: "119133,9553",
    zz: "7,27",
    zz_global: "119133,9553",
  },
  {
    profile_section_id: 1158,
    name: "HSS4-1/2X4-1/2X1/4",
    type: "HSS Rectangle",
    designation: "HSST4.5X4.5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "3,84",
    ax_global: "2477,4144",
    b: "4,5",
    b_global: "114,3",
    c: "8,44",
    c_global: "138306,8202",
    d: "4,5",
    d_global: "114,3",
    ix: "18,5",
    ix_global: "7700281,374",
    iy: "11,4",
    iy_global: "4745038,252",
    iz: "11,4",
    iz_global: "4745038,252",
    t: "0,233",
    t_global: "5,9182",
    zy: "6,06",
    zy_global: "99305,60784",
    zz: "6,06",
    zz_global: "99305,60784",
  },
  {
    profile_section_id: 1159,
    name: "HSS4-1/2X4-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSST4.5X4.5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "2,93",
    ax_global: "1890,3188",
    b: "4,5",
    b_global: "114,3",
    c: "6,49",
    c_global: "106352,0454",
    d: "4,5",
    d_global: "114,3",
    ix: "14,4",
    ix_global: "5993732,529",
    iy: "9,02",
    iy_global: "3754407,459",
    iz: "9,02",
    iz_global: "3754407,459",
    t: "0,174",
    t_global: "4,4196",
    zy: "4,71",
    zy_global: "77183,07144",
    zz: "4,71",
    zz_global: "77183,07144",
  },
  {
    profile_section_id: 1160,
    name: "HSS4-1/2X4-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSST4.5X4.5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: 2,
    ax_global: "1290,32",
    b: "4,5",
    b_global: "114,3",
    c: "4,45",
    c_global: "72922,4348",
    d: "4,5",
    d_global: "114,3",
    ix: "9,92",
    ix_global: "4129015,742",
    iy: "6,35",
    iy_global: "2643069,553",
    iz: "6,35",
    iz_global: "2643069,553",
    t: "0,116",
    t_global: "2,9464",
    zy: "3,27",
    zy_global: "53585,69928",
    zz: "3,27",
    zz_global: "53585,69928",
  },
  {
    profile_section_id: 1161,
    name: "HSS4X4X1/2",
    type: "HSS Rectangle",
    designation: "HSST4X4X0.5",
    shape: "RHS",
    country_code: "American",
    ax: "6,02",
    ax_global: "3883,8632",
    b: 4,
    b_global: "101,6",
    c: "11,2",
    c_global: "183535,1168",
    d: 4,
    d_global: "101,6",
    ix: 21,
    ix_global: "8740859,938",
    iy: "11,9",
    iy_global: "4953153,965",
    iz: "11,9",
    iz_global: "4953153,965",
    t: "0,465",
    t_global: "11,811",
    zy: "7,7",
    zy_global: "126180,3928",
    zz: "7,7",
    zz_global: "126180,3928",
  },
  {
    profile_section_id: 1162,
    name: "HSS4X4X3/8",
    type: "HSS Rectangle",
    designation: "HSST4X4X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "4,78",
    ax_global: "3083,8648",
    b: 4,
    b_global: "101,6",
    c: "9,14",
    c_global: "149777,765",
    d: 4,
    d_global: "101,6",
    ix: "17,5",
    ix_global: "7284049,948",
    iy: "10,3",
    iy_global: "4287183,684",
    iz: "10,3",
    iz_global: "4287183,684",
    t: "0,349",
    t_global: "8,8646",
    zy: "6,39",
    zy_global: "104713,339",
    zz: "6,39",
    zz_global: "104713,339",
  },
  {
    profile_section_id: 1163,
    name: "HSS4X4X5/16",
    type: "HSS Rectangle",
    designation: "HSST4X4X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "4,1",
    ax_global: "2645,156",
    b: 4,
    b_global: "101,6",
    c: "7,91",
    c_global: "129621,6762",
    d: 4,
    d_global: "101,6",
    ix: "15,3",
    ix_global: "6368340,812",
    iy: "9,14",
    iy_global: "3804355,23",
    iz: "9,14",
    iz_global: "3804355,23",
    t: "0,291",
    t_global: "7,3914",
    zy: "5,59",
    zy_global: "91603,68776",
    zz: "5,59",
    zz_global: "91603,68776",
  },
  {
    profile_section_id: 1164,
    name: "HSS4X4X1/4",
    type: "HSS Rectangle",
    designation: "HSST4X4X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "3,37",
    ax_global: "2174,1892",
    b: 4,
    b_global: "101,6",
    c: "6,56",
    c_global: "107499,1398",
    d: 4,
    d_global: "101,6",
    ix: "12,8",
    ix_global: "5327762,248",
    iy: "7,8",
    iy_global: "3246605,12",
    iz: "7,8",
    iz_global: "3246605,12",
    t: "0,233",
    t_global: "5,9182",
    zy: "4,69",
    zy_global: "76855,33016",
    zz: "4,69",
    zz_global: "76855,33016",
  },
  {
    profile_section_id: 1165,
    name: "HSS4X4X3/16",
    type: "HSS Rectangle",
    designation: "HSST4X4X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "2,58",
    ax_global: "1664,5128",
    b: 4,
    b_global: "101,6",
    c: "5,07",
    c_global: "83082,41448",
    d: 4,
    d_global: "101,6",
    ix: 10,
    ix_global: "4162314,256",
    iy: "6,21",
    iy_global: "2584797,153",
    iz: "6,21",
    iz_global: "2584797,153",
    t: "0,174",
    t_global: "4,4196",
    zy: "3,67",
    zy_global: "60140,52488",
    zz: "3,67",
    zz_global: "60140,52488",
  },
  {
    profile_section_id: 1166,
    name: "HSS4X4X1/8",
    type: "HSS Rectangle",
    designation: "HSST4X4X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,77",
    ax_global: "1141,9332",
    b: 4,
    b_global: "101,6",
    c: "3,49",
    c_global: "57190,85336",
    d: 4,
    d_global: "101,6",
    ix: "6,91",
    ix_global: "2876159,151",
    iy: "4,4",
    iy_global: "1831418,273",
    iz: "4,4",
    iz_global: "1831418,273",
    t: "0,116",
    t_global: "2,9464",
    zy: "2,56",
    zy_global: "41950,88384",
    zz: "2,56",
    zz_global: "41950,88384",
  },
  {
    profile_section_id: 1167,
    name: "HSS4X3X3/8",
    type: "HSS Rectangle",
    designation: "HSST4X3X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "4,09",
    ax_global: "2638,7044",
    b: 3,
    b_global: "76,2",
    c: "6,59",
    c_global: "107990,7518",
    d: 4,
    d_global: "101,6",
    ix: "10,6",
    ix_global: "4412053,111",
    iy: "5,01",
    iy_global: "2085319,442",
    iz: "7,93",
    iz_global: "3300715,205",
    t: "0,349",
    t_global: "8,8646",
    zy: "4,18",
    zy_global: "68497,92752",
    zz: "5,12",
    zz_global: "83901,76768",
  },
  {
    profile_section_id: 1168,
    name: "HSS4X3X5/16",
    type: "HSS Rectangle",
    designation: "HSST4X3X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "3,52",
    ax_global: "2270,9632",
    b: 3,
    b_global: "76,2",
    c: "5,75",
    c_global: "94225,618",
    d: 4,
    d_global: "101,6",
    ix: "9,41",
    ix_global: "3916737,715",
    iy: "4,52",
    iy_global: "1881366,044",
    iz: "7,14",
    iz_global: "2971892,379",
    t: "0,291",
    t_global: "7,3914",
    zy: "3,69",
    zy_global: "60468,26616",
    zz: "4,51",
    zz_global: "73905,65864",
  },
  {
    profile_section_id: 1169,
    name: "HSS4X3X1/4",
    type: "HSS Rectangle",
    designation: "HSST4X3X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "2,91",
    ax_global: "1877,4156",
    b: 3,
    b_global: "76,2",
    c: "4,81",
    c_global: "78821,77784",
    d: 4,
    d_global: "101,6",
    ix: "7,96",
    ix_global: "3313202,148",
    iy: "3,91",
    iy_global: "1627464,874",
    iz: "6,15",
    iz_global: "2559823,267",
    t: "0,233",
    t_global: "5,9182",
    zy: "3,12",
    zy_global: "51127,63968",
    zz: "3,81",
    zz_global: "62434,71384",
  },
  {
    profile_section_id: 1170,
    name: "HSS4X3X3/16",
    type: "HSS Rectangle",
    designation: "HSST4X3X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "2,24",
    ax_global: "1445,1584",
    b: 3,
    b_global: "76,2",
    c: "3,74",
    c_global: "61287,61936",
    d: 4,
    d_global: "101,6",
    ix: "6,26",
    ix_global: "2605608,724",
    iy: "3,16",
    iy_global: "1315291,305",
    iz: "4,93",
    iz_global: "2052020,928",
    t: "0,174",
    t_global: "4,4196",
    zy: "2,46",
    zy_global: "40312,17744",
    zz: 3,
    zz_global: "49161,192",
  },
  {
    profile_section_id: 1171,
    name: "HSS4X3X1/8",
    type: "HSS Rectangle",
    designation: "HSST4X3X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,54",
    ax_global: "993,5464",
    b: 3,
    b_global: "76,2",
    c: "2,59",
    c_global: "42442,49576",
    d: 4,
    d_global: "101,6",
    ix: "4,38",
    ix_global: "1823093,644",
    iy: "2,27",
    iy_global: "944845,3361",
    iz: "3,52",
    iz_global: "1465134,618",
    t: "0,116",
    t_global: "2,9464",
    zy: "1,73",
    zy_global: "28349,62072",
    zz: "2,11",
    zz_global: "34576,70504",
  },
  {
    profile_section_id: 1172,
    name: "HSS4X2-1/2X3/8",
    type: "HSS Rectangle",
    designation: "HSST4X2.5X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "3,74",
    ax_global: "2412,8984",
    b: "2,5",
    b_global: "63,5",
    c: "5,32",
    c_global: "87179,18048",
    d: 4,
    d_global: "101,6",
    ix: "7,57",
    ix_global: "3150871,892",
    iy: "3,17",
    iy_global: "1319453,619",
    iz: "6,77",
    iz_global: "2817886,751",
    t: "0,349",
    t_global: "8,8646",
    zy: "3,2",
    zy_global: "52438,6048",
    zz: "4,48",
    zz_global: "73414,04672",
  },
  {
    profile_section_id: 1173,
    name: "HSS4X2-1/2X5/16",
    type: "HSS Rectangle",
    designation: "HSST4X2.5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "3,23",
    ax_global: "2083,8668",
    b: "2,5",
    b_global: "63,5",
    c: "4,67",
    c_global: "76527,58888",
    d: 4,
    d_global: "101,6",
    ix: "6,77",
    ix_global: "2817886,751",
    iy: "2,89",
    iy_global: "1202908,82",
    iz: "6,13",
    iz_global: "2551498,639",
    t: "0,291",
    t_global: "7,3914",
    zy: "2,85",
    zy_global: "46703,1324",
    zz: "3,97",
    zz_global: "65056,64408",
  },
  {
    profile_section_id: 1174,
    name: "HSS4X2-1/2X1/4",
    type: "HSS Rectangle",
    designation: "HSST4X2.5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "2,67",
    ax_global: "1722,5772",
    b: "2,5",
    b_global: "63,5",
    c: "3,93",
    c_global: "64401,16152",
    d: 4,
    d_global: "101,6",
    ix: "5,78",
    ix_global: "2405817,64",
    iy: "2,53",
    iy_global: "1053065,507",
    iz: "5,32",
    iz_global: "2214351,184",
    t: "0,233",
    t_global: "5,9182",
    zy: "2,43",
    zy_global: "39820,56552",
    zz: "3,38",
    zz_global: "55388,27632",
  },
  {
    profile_section_id: 1175,
    name: "HSS4X2-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSST4X2.5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "2,06",
    ax_global: "1329,0296",
    b: "2,5",
    b_global: "63,5",
    c: "3,08",
    c_global: "50472,15712",
    d: 4,
    d_global: "101,6",
    ix: "4,59",
    ix_global: "1910502,244",
    iy: "2,06",
    iy_global: "857436,7367",
    iz: "4,3",
    iz_global: "1789795,13",
    t: "0,174",
    t_global: "4,4196",
    zy: "1,93",
    zy_global: "31627,03352",
    zz: "2,67",
    zz_global: "43753,46088",
  },
  {
    profile_section_id: 1176,
    name: "HSS4X2-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSST4X2.5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,42",
    ax_global: "916,1272",
    b: "2,5",
    b_global: "63,5",
    c: "2,14",
    c_global: "35068,31696",
    d: 4,
    d_global: "101,6",
    ix: "3,23",
    ix_global: "1344427,505",
    iy: "1,49",
    iy_global: "620184,8241",
    iz: "3,09",
    iz_global: "1286155,105",
    t: "0,116",
    t_global: "2,9464",
    zy: "1,36",
    zy_global: "22286,40704",
    zz: "1,88",
    zz_global: "30807,68032",
  },
  {
    profile_section_id: 1177,
    name: "HSS4X2X3/8",
    type: "HSS Rectangle",
    designation: "HSST4X2X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "3,39",
    ax_global: "2187,0924",
    b: 2,
    b_global: "50,8",
    c: "4,04",
    c_global: "66203,73856",
    d: 4,
    d_global: "101,6",
    ix: "4,83",
    ix_global: "2010397,786",
    iy: "1,8",
    iy_global: "749216,5661",
    iz: "5,6",
    iz_global: "2330895,983",
    t: "0,349",
    t_global: "8,8646",
    zy: "2,31",
    zy_global: "37854,11784",
    zz: "3,84",
    zz_global: "62926,32576",
  },
  {
    profile_section_id: 1178,
    name: "HSS4X2X5/16",
    type: "HSS Rectangle",
    designation: "HSST4X2X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "2,94",
    ax_global: "1896,7704",
    b: 2,
    b_global: "50,8",
    c: "3,59",
    c_global: "58829,55976",
    d: 4,
    d_global: "101,6",
    ix: "4,4",
    ix_global: "1831418,273",
    iy: "1,67",
    iy_global: "695106,4808",
    iz: "5,13",
    iz_global: "2135267,213",
    t: "0,291",
    t_global: "7,3914",
    zy: "2,08",
    zy_global: "34085,09312",
    zz: "3,43",
    zz_global: "56207,62952",
  },
  {
    profile_section_id: 1179,
    name: "HSS4X2X1/4",
    type: "HSS Rectangle",
    designation: "HSST4X2X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "2,44",
    ax_global: "1574,1904",
    b: 2,
    b_global: "50,8",
    c: "3,05",
    c_global: "49980,5452",
    d: 4,
    d_global: "101,6",
    ix: "3,82",
    ix_global: "1590004,046",
    iy: "1,48",
    iy_global: "616022,5099",
    iz: "4,49",
    iz_global: "1868879,101",
    t: "0,233",
    t_global: "5,9182",
    zy: "1,79",
    zy_global: "29332,84456",
    zz: "2,94",
    zz_global: "48177,96816",
  },
  {
    profile_section_id: 1180,
    name: "HSS4X2X3/16",
    type: "HSS Rectangle",
    designation: "HSST4X2X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,89",
    ax_global: "1219,3524",
    b: 2,
    b_global: "50,8",
    c: "2,41",
    c_global: "39492,82424",
    d: 4,
    d_global: "101,6",
    ix: "3,08",
    ix_global: "1281992,791",
    iy: "1,22",
    iy_global: "507802,3392",
    iz: "3,66",
    iz_global: "1523407,018",
    t: "0,174",
    t_global: "4,4196",
    zy: "1,43",
    zy_global: "23433,50152",
    zz: "2,34",
    zz_global: "38345,72976",
  },
  {
    profile_section_id: 1181,
    name: "HSS4X2X1/8",
    type: "HSS Rectangle",
    designation: "HSST4X2X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,3",
    ax_global: "838,708",
    b: 2,
    b_global: "50,8",
    c: "1,69",
    c_global: "27694,13816",
    d: 4,
    d_global: "101,6",
    ix: "2,2",
    ix_global: "915709,1363",
    iy: "0,898",
    iy_global: "373775,8202",
    iz: "2,65",
    iz_global: "1103013,278",
    t: "0,116",
    t_global: "2,9464",
    zy: "1,02",
    zy_global: "16714,80528",
    zz: "1,66",
    zz_global: "27202,52624",
  },
  {
    profile_section_id: 1182,
    name: "HSS3-1/2X3-1/2X3/8",
    type: "HSS Rectangle",
    designation: "HSST3.5X3.5X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "4,09",
    ax_global: "2638,7044",
    b: "3,5",
    b_global: "88,9",
    c: "6,77",
    c_global: "110940,4233",
    d: "3,5",
    d_global: "88,9",
    ix: "11,2",
    ix_global: "4661791,967",
    iy: "6,49",
    iy_global: "2701341,952",
    iz: "6,49",
    iz_global: "2701341,952",
    t: "0,349",
    t_global: "8,8646",
    zy: "4,69",
    zy_global: "76855,33016",
    zz: "4,69",
    zz_global: "76855,33016",
  },
  {
    profile_section_id: 1183,
    name: "HSS3-1/2X3-1/2X5/16",
    type: "HSS Rectangle",
    designation: "HSST3.5X3.5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "3,52",
    ax_global: "2270,9632",
    b: "3,5",
    b_global: "88,9",
    c: "5,9",
    c_global: "96683,6776",
    d: "3,5",
    d_global: "88,9",
    ix: "9,89",
    ix_global: "4116528,799",
    iy: "5,84",
    iy_global: "2430791,526",
    iz: "5,84",
    iz_global: "2430791,526",
    t: "0,291",
    t_global: "7,3914",
    zy: "4,14",
    zy_global: "67842,44496",
    zz: "4,14",
    zz_global: "67842,44496",
  },
  {
    profile_section_id: 1184,
    name: "HSS3-1/2X3-1/2X1/4",
    type: "HSS Rectangle",
    designation: "HSST3.5X3.5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "2,91",
    ax_global: "1877,4156",
    b: "3,5",
    b_global: "88,9",
    c: "4,92",
    c_global: "80624,35488",
    d: "3,5",
    d_global: "88,9",
    ix: "8,35",
    ix_global: "3475532,404",
    iy: "5,04",
    iy_global: "2097806,385",
    iz: "5,04",
    iz_global: "2097806,385",
    t: "0,233",
    t_global: "5,9182",
    zy: "3,5",
    zy_global: "57354,724",
    zz: "3,5",
    zz_global: "57354,724",
  },
  {
    profile_section_id: 1185,
    name: "HSS3-1/2X3-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSST3.5X3.5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "2,24",
    ax_global: "1445,1584",
    b: "3,5",
    b_global: "88,9",
    c: "3,83",
    c_global: "62762,45512",
    d: "3,5",
    d_global: "88,9",
    ix: "6,56",
    ix_global: "2730478,152",
    iy: "4,05",
    iy_global: "1685737,274",
    iz: "4,05",
    iz_global: "1685737,274",
    t: "0,174",
    t_global: "4,4196",
    zy: "2,76",
    zy_global: "45228,29664",
    zz: "2,76",
    zz_global: "45228,29664",
  },
  {
    profile_section_id: 1186,
    name: "HSS3-1/2X3-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSST3.5X3.5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,54",
    ax_global: "993,5464",
    b: "3,5",
    b_global: "88,9",
    c: "2,65",
    c_global: "43425,7196",
    d: "3,5",
    d_global: "88,9",
    ix: "4,58",
    ix_global: "1906339,929",
    iy: "2,9",
    iy_global: "1207071,134",
    iz: "2,9",
    iz_global: "1207071,134",
    t: "0,116",
    t_global: "2,9464",
    zy: "1,93",
    zy_global: "31627,03352",
    zz: "1,93",
    zz_global: "31627,03352",
  },
  {
    profile_section_id: 1187,
    name: "HSS3-1/2X2X1/4",
    type: "HSS Rectangle",
    designation: "HSST3.5X2X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "2,21",
    ax_global: "1425,8036",
    b: 2,
    b_global: "50,8",
    c: "2,64",
    c_global: "43261,84896",
    d: "3,5",
    d_global: "88,9",
    ix: "3,16",
    ix_global: "1315291,305",
    iy: "1,3",
    iy_global: "541100,8533",
    iz: "3,17",
    iz_global: "1319453,619",
    t: "0,233",
    t_global: "5,9182",
    zy: "1,58",
    zy_global: "25891,56112",
    zz: "2,36",
    zz_global: "38673,47104",
  },
  {
    profile_section_id: 1188,
    name: "HSS3-1/2X2X3/16",
    type: "HSS Rectangle",
    designation: "HSST3.5X2X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,71",
    ax_global: "1103,2236",
    b: 2,
    b_global: "50,8",
    c: "2,09",
    c_global: "34248,96376",
    d: "3,5",
    d_global: "88,9",
    ix: "2,55",
    ix_global: "1061390,135",
    iy: "1,08",
    iy_global: "449529,9396",
    iz: "2,61",
    iz_global: "1086364,021",
    t: "0,174",
    t_global: "4,4196",
    zy: "1,27",
    zy_global: "20811,57128",
    zz: "1,89",
    zz_global: "30971,55096",
  },
  {
    profile_section_id: 1189,
    name: "HSS3-1/2X2X1/8",
    type: "HSS Rectangle",
    designation: "HSST3.5X2X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,19",
    ax_global: "767,7404",
    b: 2,
    b_global: "50,8",
    c: "1,47",
    c_global: "24088,98408",
    d: "3,5",
    d_global: "88,9",
    ix: "1,83",
    ix_global: "761703,5088",
    iy: "0,795",
    iy_global: "330903,9834",
    iz: "1,9",
    iz_global: "790839,7086",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,912",
    zy_global: "14945,00237",
    zz: "1,34",
    zz_global: "21958,66576",
  },
  {
    profile_section_id: 1190,
    name: "HSS3-1/2X1-1/2X1/4",
    type: "HSS Rectangle",
    designation: "HSST3.5X1.5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "1,97",
    ax_global: "1270,9652",
    b: "1,5",
    b_global: "38,1",
    c: "1,88",
    c_global: "30807,68032",
    d: "3,5",
    d_global: "88,9",
    ix: "1,79",
    ix_global: "745054,2518",
    iy: "0,638",
    iy_global: "265555,6495",
    iz: "2,55",
    iz_global: "1061390,135",
    t: "0,233",
    t_global: "5,9182",
    zy: "1,06",
    zy_global: "17370,28784",
    zz: "1,98",
    zz_global: "32446,38672",
  },
  {
    profile_section_id: 1191,
    name: "HSS3-1/2X1-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSST3.5X1.5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,54",
    ax_global: "993,5464",
    b: "1,5",
    b_global: "38,1",
    c: "1,51",
    c_global: "24744,46664",
    d: "3,5",
    d_global: "88,9",
    ix: "1,49",
    ix_global: "620184,8241",
    iy: "0,544",
    iy_global: "226429,8955",
    iz: "2,12",
    iz_global: "882410,6223",
    t: "0,174",
    t_global: "4,4196",
    zy: "0,867",
    zy_global: "14207,58449",
    zz: "1,6",
    zz_global: "26219,3024",
  },
  {
    profile_section_id: 1192,
    name: "HSS3-1/2X1-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSST3.5X1.5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,07",
    ax_global: "690,3212",
    b: "1,5",
    b_global: "38,1",
    c: "1,08",
    c_global: "17698,02912",
    d: "3,5",
    d_global: "88,9",
    ix: "1,09",
    ix_global: "453692,2539",
    iy: "0,411",
    iy_global: "171071,1159",
    iz: "1,57",
    iz_global: "653483,3382",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,63",
    zy_global: "10323,85032",
    zz: "1,15",
    zz_global: "18845,1236",
  },
  {
    profile_section_id: 1193,
    name: "HSS3X3X3/8",
    type: "HSS Rectangle",
    designation: "HSST3X3X0.375",
    shape: "RHS",
    country_code: "American",
    ax: "3,39",
    ax_global: "2187,0924",
    b: 3,
    b_global: "76,2",
    c: "4,74",
    c_global: "77674,68336",
    d: 3,
    d_global: "76,2",
    ix: "6,64",
    ix_global: "2763776,666",
    iy: "3,78",
    iy_global: "1573354,789",
    iz: "3,78",
    iz_global: "1573354,789",
    t: "0,349",
    t_global: "8,8646",
    zy: "3,25",
    zy_global: "53257,958",
    zz: "3,25",
    zz_global: "53257,958",
  },
  {
    profile_section_id: 1194,
    name: "HSS3X3X5/16",
    type: "HSS Rectangle",
    designation: "HSST3X3X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "2,94",
    ax_global: "1896,7704",
    b: 3,
    b_global: "76,2",
    c: "4,18",
    c_global: "68497,92752",
    d: 3,
    d_global: "76,2",
    ix: "5,94",
    ix_global: "2472414,668",
    iy: "3,45",
    iy_global: "1435998,418",
    iz: "3,45",
    iz_global: "1435998,418",
    t: "0,291",
    t_global: "7,3914",
    zy: "2,9",
    zy_global: "47522,4856",
    zz: "2,9",
    zz_global: "47522,4856",
  },
  {
    profile_section_id: 1195,
    name: "HSS3X3X1/4",
    type: "HSS Rectangle",
    designation: "HSST3X3X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "2,44",
    ax_global: "1574,1904",
    b: 3,
    b_global: "76,2",
    c: "3,52",
    c_global: "57682,46528",
    d: 3,
    d_global: "76,2",
    ix: "5,08",
    ix_global: "2114455,642",
    iy: "3,02",
    iy_global: "1257018,905",
    iz: "3,02",
    iz_global: "1257018,905",
    t: "0,233",
    t_global: "5,9182",
    zy: "2,48",
    zy_global: "40639,91872",
    zz: "2,48",
    zz_global: "40639,91872",
  },
  {
    profile_section_id: 1196,
    name: "HSS3X3X3/16",
    type: "HSS Rectangle",
    designation: "HSST3X3X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,89",
    ax_global: "1219,3524",
    b: 3,
    b_global: "76,2",
    c: "2,76",
    c_global: "45228,29664",
    d: 3,
    d_global: "76,2",
    ix: "4,03",
    ix_global: "1677412,645",
    iy: "2,46",
    iy_global: "1023929,307",
    iz: "2,46",
    iz_global: "1023929,307",
    t: "0,174",
    t_global: "4,4196",
    zy: "1,97",
    zy_global: "32282,51608",
    zz: "1,97",
    zz_global: "32282,51608",
  },
  {
    profile_section_id: 1197,
    name: "HSS3X3X1/8",
    type: "HSS Rectangle",
    designation: "HSST3X3X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,3",
    ax_global: "838,708",
    b: 3,
    b_global: "76,2",
    c: "1,92",
    c_global: "31463,16288",
    d: 3,
    d_global: "76,2",
    ix: "2,84",
    ix_global: "1182097,249",
    iy: "1,78",
    iy_global: "740891,9376",
    iz: "1,78",
    iz_global: "740891,9376",
    t: "0,116",
    t_global: "2,9464",
    zy: "1,4",
    zy_global: "22941,8896",
    zz: "1,4",
    zz_global: "22941,8896",
  },
  {
    profile_section_id: 1198,
    name: "HSS3X2-1/2X5/16",
    type: "HSS Rectangle",
    designation: "HSST3X2.5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "2,64",
    ax_global: "1703,2224",
    b: "2,5",
    b_global: "63,5",
    c: "3,39",
    c_global: "55552,14696",
    d: 3,
    d_global: "76,2",
    ix: "4,34",
    ix_global: "1806444,387",
    iy: "2,18",
    iy_global: "907384,5078",
    iz: "2,92",
    iz_global: "1215395,763",
    t: "0,291",
    t_global: "7,3914",
    zy: "2,2",
    zy_global: "36051,5408",
    zz: "2,51",
    zz_global: "41131,53064",
  },
  {
    profile_section_id: 1199,
    name: "HSS3X2-1/2X1/4",
    type: "HSS Rectangle",
    designation: "HSST3X2.5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "2,21",
    ax_global: "1425,8036",
    b: "2,5",
    b_global: "63,5",
    c: "2,87",
    c_global: "47030,87368",
    d: 3,
    d_global: "76,2",
    ix: "3,74",
    ix_global: "1556705,532",
    iy: "1,93",
    iy_global: "803326,6514",
    iz: "2,57",
    iz_global: "1069714,764",
    t: "0,233",
    t_global: "5,9182",
    zy: "1,9",
    zy_global: "31135,4216",
    zz: "2,16",
    zz_global: "35396,05824",
  },
  {
    profile_section_id: 1200,
    name: "HSS3X2-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSST3X2.5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,71",
    ax_global: "1103,2236",
    b: "2,5",
    b_global: "63,5",
    c: "2,27",
    c_global: "37198,63528",
    d: 3,
    d_global: "76,2",
    ix: 3,
    ix_global: "1248694,277",
    iy: "1,59",
    iy_global: "661807,9667",
    iz: "2,11",
    iz_global: "878248,308",
    t: "0,174",
    t_global: "4,4196",
    zy: "1,52",
    zy_global: "24908,33728",
    zz: "1,73",
    zz_global: "28349,62072",
  },
  {
    profile_section_id: 1201,
    name: "HSS3X2-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSST3X2.5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,19",
    ax_global: "767,7404",
    b: "2,5",
    b_global: "63,5",
    c: "1,59",
    c_global: "26055,43176",
    d: 3,
    d_global: "76,2",
    ix: "2,13",
    ix_global: "886572,9365",
    iy: "1,16",
    iy_global: "482828,4537",
    iz: "1,54",
    iz_global: "640996,3954",
    t: "0,116",
    t_global: "2,9464",
    zy: "1,09",
    zy_global: "17861,89976",
    zz: "1,23",
    zz_global: "20156,08872",
  },
  {
    profile_section_id: 1202,
    name: "HSS3X2X5/16",
    type: "HSS Rectangle",
    designation: "HSST3X2X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "2,35",
    ax_global: "1516,126",
    b: 2,
    b_global: "50,8",
    c: "2,6",
    c_global: "42606,3664",
    d: 3,
    d_global: "76,2",
    ix: "2,87",
    ix_global: "1194584,191",
    iy: "1,24",
    iy_global: "516126,9677",
    iz: "2,38",
    iz_global: "990630,7929",
    t: "0,291",
    t_global: "7,3914",
    zy: "1,58",
    zy_global: "25891,56112",
    zz: "2,11",
    zz_global: "34576,70504",
  },
  {
    profile_section_id: 1203,
    name: "HSS3X2X1/4",
    type: "HSS Rectangle",
    designation: "HSST3X2X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "1,97",
    ax_global: "1270,9652",
    b: 2,
    b_global: "50,8",
    c: "2,23",
    c_global: "36543,15272",
    d: 3,
    d_global: "76,2",
    ix: "2,52",
    ix_global: "1048903,193",
    iy: "1,11",
    iy_global: "462016,8824",
    iz: "2,13",
    iz_global: "886572,9365",
    t: "0,233",
    t_global: "5,9182",
    zy: "1,38",
    zy_global: "22614,14832",
    zz: "1,83",
    zz_global: "29988,32712",
  },
  {
    profile_section_id: 1204,
    name: "HSS3X2X3/16",
    type: "HSS Rectangle",
    designation: "HSST3X2X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,54",
    ax_global: "993,5464",
    b: 2,
    b_global: "50,8",
    c: "1,78",
    c_global: "29168,97392",
    d: 3,
    d_global: "76,2",
    ix: "2,05",
    ix_global: "853274,4225",
    iy: "0,932",
    iy_global: "387927,6887",
    iz: "1,77",
    iz_global: "736729,6233",
    t: "0,174",
    t_global: "4,4196",
    zy: "1,12",
    zy_global: "18353,51168",
    zz: "1,48",
    zz_global: "24252,85472",
  },
  {
    profile_section_id: 1205,
    name: "HSS3X2X1/8",
    type: "HSS Rectangle",
    designation: "HSST3X2X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,07",
    ax_global: "690,3212",
    b: 2,
    b_global: "50,8",
    c: "1,25",
    c_global: "20483,83",
    d: 3,
    d_global: "76,2",
    ix: "1,47",
    ix_global: "611860,1956",
    iy: "0,692",
    iy_global: "288032,1465",
    iz: "1,3",
    iz_global: "541100,8533",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,803",
    zy_global: "13158,81239",
    zz: "1,06",
    zz_global: "17370,28784",
  },
  {
    profile_section_id: 1206,
    name: "HSS3X1-1/2X1/4",
    type: "HSS Rectangle",
    designation: "HSST3X1.5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "1,74",
    ax_global: "1122,5784",
    b: "1,5",
    b_global: "38,1",
    c: "1,58",
    c_global: "25891,56112",
    d: 3,
    d_global: "76,2",
    ix: "1,44",
    ix_global: "599373,2529",
    iy: "0,543",
    iy_global: "226013,6641",
    iz: "1,68",
    iz_global: "699268,795",
    t: "0,233",
    t_global: "5,9182",
    zy: "0,911",
    zy_global: "14928,6153",
    zz: "1,51",
    zz_global: "24744,46664",
  },
  {
    profile_section_id: 1207,
    name: "HSS3X1-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSST3X1.5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,37",
    ax_global: "883,8692",
    b: "1,5",
    b_global: "38,1",
    c: "1,28",
    c_global: "20975,44192",
    d: 3,
    d_global: "76,2",
    ix: "1,21",
    ix_global: "503640,025",
    iy: "0,467",
    iy_global: "194380,0758",
    iz: "1,42",
    iz_global: "591048,6244",
    t: "0,174",
    t_global: "4,4196",
    zy: "0,752",
    zy_global: "12323,07213",
    zz: "1,24",
    zz_global: "20319,95936",
  },
  {
    profile_section_id: 1208,
    name: "HSS3X1-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSST3X1.5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "0,956",
    ax_global: "616,77296",
    b: "1,5",
    b_global: "38,1",
    c: "0,92",
    c_global: "15076,09888",
    d: 3,
    d_global: "76,2",
    ix: "0,886",
    ix_global: "368781,0431",
    iy: "0,355",
    iy_global: "147762,1561",
    iz: "1,06",
    iz_global: "441205,3111",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,55",
    zy_global: "9012,8852",
    zz: "0,895",
    zz_global: "14666,42228",
  },
  {
    profile_section_id: 1209,
    name: "HSS3X1X3/16",
    type: "HSS Rectangle",
    designation: "HSST3X1X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,19",
    ax_global: "767,7404",
    b: 1,
    b_global: "25,4",
    c: "0,792",
    c_global: "12978,55469",
    d: 3,
    d_global: "76,2",
    ix: "0,526",
    ix_global: "218937,7299",
    iy: "0,173",
    iy_global: "72008,03663",
    iz: "1,07",
    iz_global: "445367,6254",
    t: "0,174",
    t_global: "4,4196",
    zy: "0,432",
    zy_global: "7079,211648",
    zz: "0,989",
    zz_global: "16206,8063",
  },
  {
    profile_section_id: 1210,
    name: "HSS3X1X1/8",
    type: "HSS Rectangle",
    designation: "HSST3X1X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "0,84",
    ax_global: "541,9344",
    b: 1,
    b_global: "25,4",
    c: "0,585",
    c_global: "9586,43244",
    d: 3,
    d_global: "76,2",
    ix: "0,408",
    ix_global: "169822,4216",
    iy: "0,138",
    iy_global: "57439,93673",
    iz: "0,817",
    iz_global: "340061,0747",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,325",
    zy_global: "5325,7958",
    zz: "0,728",
    zz_global: "11929,78259",
  },
  {
    profile_section_id: 1211,
    name: "HSS2-1/2X2-1/2X5/16",
    type: "HSS Rectangle",
    designation: "HSST2.5X2.5X0.313",
    shape: "RHS",
    country_code: "American",
    ax: "2,35",
    ax_global: "1516,126",
    b: "2,5",
    b_global: "63,5",
    c: "2,74",
    c_global: "44900,55536",
    d: "2,5",
    d_global: "63,5",
    ix: "3,2",
    ix_global: "1331940,562",
    iy: "1,82",
    iy_global: "757541,1946",
    iz: "1,82",
    iz_global: "757541,1946",
    t: "0,291",
    t_global: "7,3914",
    zy: "1,88",
    zy_global: "30807,68032",
    zz: "1,88",
    zz_global: "30807,68032",
  },
  {
    profile_section_id: 1212,
    name: "HSS2-1/2X2-1/2X1/4",
    type: "HSS Rectangle",
    designation: "HSST2.5X2.5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "1,97",
    ax_global: "1270,9652",
    b: "2,5",
    b_global: "63,5",
    c: "2,35",
    c_global: "38509,6004",
    d: "2,5",
    d_global: "63,5",
    ix: "2,79",
    ix_global: "1161285,677",
    iy: "1,63",
    iy_global: "678457,2237",
    iz: "1,63",
    iz_global: "678457,2237",
    t: "0,233",
    t_global: "5,9182",
    zy: "1,63",
    zy_global: "26710,91432",
    zz: "1,63",
    zz_global: "26710,91432",
  },
  {
    profile_section_id: 1213,
    name: "HSS2-1/2X2-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSST2.5X2.5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,54",
    ax_global: "993,5464",
    b: "2,5",
    b_global: "63,5",
    c: "1,86",
    c_global: "30479,93904",
    d: "2,5",
    d_global: "63,5",
    ix: "2,25",
    ix_global: "936520,7076",
    iy: "1,35",
    iy_global: "561912,4246",
    iz: "1,35",
    iz_global: "561912,4246",
    t: "0,174",
    t_global: "4,4196",
    zy: "1,32",
    zy_global: "21630,92448",
    zz: "1,32",
    zz_global: "21630,92448",
  },
  {
    profile_section_id: 1214,
    name: "HSS2-1/2X2-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSST2.5X2.5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,07",
    ax_global: "690,3212",
    b: "2,5",
    b_global: "63,5",
    c: "1,31",
    c_global: "21467,05384",
    d: "2,5",
    d_global: "63,5",
    ix: "1,61",
    ix_global: "670132,5952",
    iy: "0,998",
    iy_global: "415398,9627",
    iz: "0,998",
    iz_global: "415398,9627",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,947",
    zy_global: "15518,54961",
    zz: "0,947",
    zz_global: "15518,54961",
  },
  {
    profile_section_id: 1215,
    name: "HSS2-1/2X2X1/4",
    type: "HSS Rectangle",
    designation: "HSST2.5X2X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "1,74",
    ax_global: "1122,5784",
    b: 2,
    b_global: "50,8",
    c: "1,82",
    c_global: "29824,45648",
    d: "2,5",
    d_global: "63,5",
    ix: "1,9",
    ix_global: "790839,7086",
    iy: "0,93",
    iy_global: "387095,2258",
    iz: "1,33",
    iz_global: "553587,796",
    t: "0,233",
    t_global: "5,9182",
    zy: "1,17",
    zy_global: "19172,86488",
    zz: "1,37",
    zz_global: "22450,27768",
  },
  {
    profile_section_id: 1216,
    name: "HSS2-1/2X2X3/16",
    type: "HSS Rectangle",
    designation: "HSST2.5X2X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,37",
    ax_global: "883,8692",
    b: 2,
    b_global: "50,8",
    c: "1,46",
    c_global: "23925,11344",
    d: "2,5",
    d_global: "63,5",
    ix: "1,55",
    ix_global: "645158,7097",
    iy: "0,786",
    iy_global: "327157,9005",
    iz: "1,12",
    iz_global: "466179,1967",
    t: "0,174",
    t_global: "4,4196",
    zy: "0,956",
    zy_global: "15666,03318",
    zz: "1,12",
    zz_global: "18353,51168",
  },
  {
    profile_section_id: 1217,
    name: "HSS2-1/2X2X1/8",
    type: "HSS Rectangle",
    designation: "HSST2.5X2X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "0,956",
    ax_global: "616,77296",
    b: 2,
    b_global: "50,8",
    c: "1,04",
    c_global: "17042,54656",
    d: "2,5",
    d_global: "63,5",
    ix: "1,12",
    ix_global: "466179,1967",
    iy: "0,589",
    iy_global: "245160,3097",
    iz: "0,833",
    iz_global: "346720,7775",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,694",
    zy_global: "11372,62242",
    zz: "0,809",
    zz_global: "13257,13478",
  },
  {
    profile_section_id: 1218,
    name: "HSS2-1/2X1-1/2X1/4",
    type: "HSS Rectangle",
    designation: "HSST2.5X1.5X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "1,51",
    ax_global: "974,1916",
    b: "1,5",
    b_global: "38,1",
    c: "1,29",
    c_global: "21139,31256",
    d: "2,5",
    d_global: "63,5",
    ix: "1,1",
    ix_global: "457854,5682",
    iy: "0,449",
    iy_global: "186887,9101",
    iz: "1,03",
    iz_global: "428718,3684",
    t: "0,233",
    t_global: "5,9182",
    zy: "0,764",
    zy_global: "12519,7169",
    zz: "1,11",
    zz_global: "18189,64104",
  },
  {
    profile_section_id: 1219,
    name: "HSS2-1/2X1-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSST2.5X1.5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,19",
    ax_global: "767,7404",
    b: "1,5",
    b_global: "38,1",
    c: "1,05",
    c_global: "17206,4172",
    d: "2,5",
    d_global: "63,5",
    ix: "0,929",
    ix_global: "386678,9944",
    iy: "0,39",
    iy_global: "162330,256",
    iz: "0,882",
    iz_global: "367116,1174",
    t: "0,174",
    t_global: "4,4196",
    zy: "0,636",
    zy_global: "10422,1727",
    zz: "0,915",
    zz_global: "14994,16356",
  },
  {
    profile_section_id: 1220,
    name: "HSS2-1/2X1-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSST2.5X1.5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "0,84",
    ax_global: "541,9344",
    b: "1,5",
    b_global: "38,1",
    c: "0,759",
    c_global: "12437,78158",
    d: "2,5",
    d_global: "63,5",
    ix: "0,687",
    ix_global: "285950,9894",
    iy: "0,3",
    iy_global: "124869,4277",
    iz: "0,668",
    iz_global: "278042,5923",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,469",
    zy_global: "7685,533016",
    zz: "0,671",
    zz_global: "10995,71994",
  },
  {
    profile_section_id: 1221,
    name: "HSS2-1/2X1X3/16",
    type: "HSS Rectangle",
    designation: "HSST2.5X1X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,02",
    ax_global: "658,0632",
    b: 1,
    b_global: "25,4",
    c: "0,648",
    c_global: "10618,81747",
    d: "2,5",
    d_global: "63,5",
    ix: "0,412",
    ix_global: "171487,3473",
    iy: "0,143",
    iy_global: "59521,09386",
    iz: "0,646",
    iz_global: "268885,5009",
    t: "0,174",
    t_global: "4,4196",
    zy: "0,36",
    zy_global: "5899,34304",
    zz: "0,713",
    zz_global: "11683,97663",
  },
  {
    profile_section_id: 1222,
    name: "HSS2-1/2X1X1/8",
    type: "HSS Rectangle",
    designation: "HSST2.5X1X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "0,724",
    ax_global: "467,09584",
    b: 1,
    b_global: "25,4",
    c: "0,483",
    c_global: "7914,951912",
    d: "2,5",
    d_global: "63,5",
    ix: "0,322",
    ix_global: "134026,519",
    iy: "0,115",
    iy_global: "47866,61394",
    iz: "0,503",
    iz_global: "209364,4071",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,274",
    zy_global: "4490,055536",
    zz: "0,532",
    zz_global: "8717,918048",
  },
  {
    profile_section_id: 1223,
    name: "HSS2-1/4X2-1/4X1/4",
    type: "HSS Rectangle",
    designation: "HSST2.25X2.25X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "1,74",
    ax_global: "1122,5784",
    b: "2,25",
    b_global: "57,15",
    c: "1,85",
    c_global: "30316,0684",
    d: "2,25",
    d_global: "57,15",
    ix: "1,96",
    ix_global: "815813,5942",
    iy: "1,13",
    iy_global: "470341,5109",
    iz: "1,13",
    iz_global: "470341,5109",
    t: "0,233",
    t_global: "5,9182",
    zy: "1,28",
    zy_global: "20975,44192",
    zz: "1,28",
    zz_global: "20975,44192",
  },
  {
    profile_section_id: 1224,
    name: "HSS2-1/4X2-1/4X3/16",
    type: "HSS Rectangle",
    designation: "HSST2.25X2.25X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,37",
    ax_global: "883,8692",
    b: "2,25",
    b_global: "57,15",
    c: "1,48",
    c_global: "24252,85472",
    d: "2,25",
    d_global: "57,15",
    ix: "1,6",
    ix_global: "665970,281",
    iy: "0,953",
    iy_global: "396668,5486",
    iz: "0,953",
    iz_global: "396668,5486",
    t: "0,174",
    t_global: "4,4196",
    zy: "1,04",
    zy_global: "17042,54656",
    zz: "1,04",
    zz_global: "17042,54656",
  },
  {
    profile_section_id: 1225,
    name: "HSS2-1/4X2-1/4X1/8",
    type: "HSS Rectangle",
    designation: "HSST2.25X2.25X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "0,956",
    ax_global: "616,77296",
    b: "2,25",
    b_global: "57,15",
    c: "1,05",
    c_global: "17206,4172",
    d: "2,25",
    d_global: "57,15",
    ix: "1,15",
    ix_global: "478666,1394",
    iy: "0,712",
    iy_global: "296356,775",
    iz: "0,712",
    iz_global: "296356,775",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,755",
    zy_global: "12372,23332",
    zz: "0,755",
    zz_global: "12372,23332",
  },
  {
    profile_section_id: 1226,
    name: "HSS2-1/4X2X3/16",
    type: "HSS Rectangle",
    designation: "HSST2.25X2X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,28",
    ax_global: "825,8048",
    b: "2,25",
    b_global: "57,15",
    c: "1,3",
    c_global: "21303,1832",
    d: "2,25",
    d_global: "57,15",
    ix: "1,32",
    ix_global: "549425,4818",
    iy: "0,713",
    iy_global: "296773,0065",
    iz: "0,859",
    iz_global: "357542,7946",
    t: "0,174",
    t_global: "4,4196",
    zy: "0,877",
    zy_global: "14371,45513",
    zz: "0,952",
    zz_global: "15600,48493",
  },
  {
    profile_section_id: 1227,
    name: "HSS2-1/4X2X1/8",
    type: "HSS Rectangle",
    designation: "HSST2.25X2X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "0,898",
    ax_global: "579,35368",
    b: "2,25",
    b_global: "57,15",
    c: "0,927",
    c_global: "15190,80833",
    d: "2,25",
    d_global: "57,15",
    ix: "0,957",
    ix_global: "398333,4743",
    iy: "0,538",
    iy_global: "223932,507",
    iz: "0,646",
    iz_global: "268885,5009",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,639",
    zy_global: "10471,3339",
    zz: "0,693",
    zz_global: "11356,23535",
  },
  {
    profile_section_id: 1228,
    name: "HSS2X2X1/4",
    type: "HSS Rectangle",
    designation: "HSST2X2X0.25",
    shape: "RHS",
    country_code: "American",
    ax: "1,51",
    ax_global: "974,1916",
    b: 2,
    b_global: "50,8",
    c: "1,41",
    c_global: "23105,76024",
    d: 2,
    d_global: "50,8",
    ix: "1,31",
    ix_global: "545263,1675",
    iy: "0,747",
    iy_global: "310924,8749",
    iz: "0,747",
    iz_global: "310924,8749",
    t: "0,233",
    t_global: "5,9182",
    zy: "0,964",
    zy_global: "15797,1297",
    zz: "0,964",
    zz_global: "15797,1297",
  },
  {
    profile_section_id: 1229,
    name: "HSS2X2X3/16",
    type: "HSS Rectangle",
    designation: "HSST2X2X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,19",
    ax_global: "767,7404",
    b: 2,
    b_global: "50,8",
    c: "1,14",
    c_global: "18681,25296",
    d: 2,
    d_global: "50,8",
    ix: "1,09",
    ix_global: "453692,2539",
    iy: "0,641",
    iy_global: "266804,3438",
    iz: "0,641",
    iz_global: "266804,3438",
    t: "0,174",
    t_global: "4,4196",
    zy: "0,797",
    zy_global: "13060,49001",
    zz: "0,797",
    zz_global: "13060,49001",
  },
  {
    profile_section_id: 1230,
    name: "HSS2X2X1/8",
    type: "HSS Rectangle",
    designation: "HSST2X2X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "0,84",
    ax_global: "541,9344",
    b: 2,
    b_global: "50,8",
    c: "0,817",
    c_global: "13388,23129",
    d: 2,
    d_global: "50,8",
    ix: "0,796",
    ix_global: "331320,2148",
    iy: "0,486",
    iy_global: "202288,4728",
    iz: "0,486",
    iz_global: "202288,4728",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,584",
    zy_global: "9570,045376",
    zz: "0,584",
    zz_global: "9570,045376",
  },
  {
    profile_section_id: 1231,
    name: "HSS2X1-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSST2X1.5X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,02",
    ax_global: "658,0632",
    b: "1,5",
    b_global: "38,1",
    c: "0,822",
    c_global: "13470,16661",
    d: 2,
    d_global: "50,8",
    ix: "0,664",
    ix_global: "276377,6666",
    iy: "0,313",
    iy_global: "130280,4362",
    iz: "0,495",
    iz_global: "206034,5557",
    t: "0,174",
    t_global: "4,4196",
    zy: "0,521",
    zy_global: "8537,660344",
    zz: "0,639",
    zz_global: "10471,3339",
  },
  {
    profile_section_id: 1232,
    name: "HSS2X1-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSST2X1.5X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "0,724",
    ax_global: "467,09584",
    b: 2,
    b_global: "50,8",
    c: "0,599",
    c_global: "9815,851336",
    d: 2,
    d_global: "50,8",
    ix: "0,496",
    ix_global: "206450,7871",
    iy: "0,244",
    iy_global: "101560,4678",
    iz: "0,383",
    iz_global: "159416,636",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,389",
    zy_global: "6374,567896",
    zz: "0,475",
    zz_global: "7783,8554",
  },
  {
    profile_section_id: 1233,
    name: "HSS2X1X3/16",
    type: "HSS Rectangle",
    designation: "HSST2X1X0.188",
    shape: "RHS",
    country_code: "American",
    ax: "0,845",
    ax_global: "545,1602",
    b: 1,
    b_global: "25,4",
    c: "0,505",
    c_global: "8275,46732",
    d: 2,
    d_global: "50,8",
    ix: "0,301",
    ix_global: "125285,6591",
    iy: "0,112",
    iy_global: "46617,91967",
    iz: "0,35",
    iz_global: "145680,999",
    t: "0,174",
    t_global: "4,4196",
    zy: "0,288",
    zy_global: "4719,474432",
    zz: "0,48",
    zz_global: "7865,79072",
  },
  {
    profile_section_id: 1234,
    name: "HSS2X1X1/8",
    type: "HSS Rectangle",
    designation: "HSST2X1X0.125",
    shape: "RHS",
    country_code: "American",
    ax: "0,608",
    ax_global: "392,25728",
    b: 1,
    b_global: "25,4",
    c: "0,38",
    c_global: "6227,08432",
    d: 2,
    d_global: "50,8",
    ix: "0,238",
    ix_global: "99063,07929",
    iy: "0,0922",
    iy_global: "38376,53744",
    iz: "0,28",
    iz_global: "116544,7992",
    t: "0,116",
    t_global: "2,9464",
    zy: "0,223",
    zy_global: "3654,315272",
    zz: "0,366",
    zz_global: "5997,665424",
  },
  {
    profile_section_id: 1235,
    name: "HSS24X12X3/4",
    type: "HSS Rectangle",
    designation: "HSST24X12X.75",
    shape: "RHS",
    country_code: "American",
    ax: "47,1",
    ax_global: "30387,036",
    b: 12,
    b_global: "304,8",
    c: 221,
    c_global: "3621541,144",
    d: 24,
    d_global: "609,6",
    ix: 2850,
    ix_global: 1186259563,
    iy: 1170,
    iy_global: 486990768,
    iz: 3440,
    iz_global: 1431836104,
    t: "0,7",
    t_global: "17,78",
    zy: 221,
    zy_global: "3621541,144",
    zz: 359,
    zz_global: "5882955,976",
  },
  {
    profile_section_id: 1236,
    name: "HSS24X12X5/8",
    type: "HSS Rectangle",
    designation: "HSST24X12X.625",
    shape: "RHS",
    country_code: "American",
    ax: "39,6",
    ax_global: "25548,336",
    b: 12,
    b_global: "304,8",
    c: 188,
    c_global: "3080768,032",
    d: 24,
    d_global: "609,6",
    ix: 2430,
    ix_global: 1011442364,
    iy: 1000,
    iy_global: "416231425,6",
    iz: 2940,
    iz_global: 1223720391,
    t: "0,58",
    t_global: "14,732",
    zy: 188,
    zy_global: "3080768,032",
    zz: 304,
    zz_global: "4981667,456",
  },
  {
    profile_section_id: 1237,
    name: "HSS24X12X1/2",
    type: "HSS Rectangle",
    designation: "HSST24X12X.5",
    shape: "RHS",
    country_code: "American",
    ax: "32,1",
    ax_global: "20709,636",
    b: 12,
    b_global: "304,8",
    c: 154,
    c_global: "2523607,856",
    d: 24,
    d_global: "609,6",
    ix: 1980,
    ix_global: "824138222,7",
    iy: 829,
    iy_global: "345055851,8",
    iz: 2420,
    iz_global: 1007280050,
    t: "0,47",
    t_global: "11,938",
    zy: 154,
    zy_global: "2523607,856",
    zz: 248,
    zz_global: "4063991,872",
  },
  {
    profile_section_id: 1238,
    name: "HSS20X12X3/4",
    type: "HSS Rectangle",
    designation: "HSST20X12X.75",
    shape: "RHS",
    country_code: "American",
    ax: "41,5",
    ax_global: "26774,14",
    b: 12,
    b_global: "304,8",
    c: 190,
    c_global: "3113542,16",
    d: 20,
    d_global: 508,
    ix: 2220,
    ix_global: "924033764,8",
    iy: 988,
    iy_global: "411236648,5",
    iz: 2190,
    iz_global: "911546822,1",
    t: "0,7",
    t_global: "17,78",
    zy: 190,
    zy_global: "3113542,16",
    zz: 270,
    zz_global: "4424507,28",
  },
  {
    profile_section_id: 1239,
    name: "HSS16X12X3/4",
    type: "HSS Rectangle",
    designation: "HSST16X12X.75",
    shape: "RHS",
    country_code: "American",
    ax: "35,9",
    ax_global: "23161,244",
    b: 12,
    b_global: "304,8",
    c: 158,
    c_global: "2589156,112",
    d: 16,
    d_global: "406,4",
    ix: 1610,
    ix_global: "670132595,2",
    iy: 810,
    iy_global: "337147454,7",
    iz: 1270,
    iz_global: "528613910,5",
    t: "0,7",
    t_global: "17,78",
    zy: 158,
    zy_global: "2589156,112",
    zz: 193,
    zz_global: "3162703,352",
  },
  {
    profile_section_id: 1240,
    name: "HSS22X22X7/8",
    type: "HSS Rectangle",
    designation: "HSST22X22X.875",
    shape: "RHS",
    country_code: "American",
    ax: "67,3",
    ax_global: "43419,268",
    b: 22,
    b_global: "558,8",
    c: 729,
    c_global: "11946169,66",
    d: 22,
    d_global: "558,8",
    ix: 7890,
    ix_global: 3284065948,
    iy: 4970,
    iy_global: 2068670185,
    iz: 4970,
    iz_global: 2068670185,
    t: "0,81",
    t_global: "20,574",
    zy: 530,
    zy_global: "8685143,92",
    zz: 530,
    zz_global: "8685143,92",
  },
  {
    profile_section_id: 1241,
    name: "HSS22X22X3/4",
    type: "HSS Rectangle",
    designation: "HSST22X22X.75",
    shape: "RHS",
    country_code: "American",
    ax: "58,2",
    ax_global: "37548,312",
    b: 22,
    b_global: "558,8",
    c: 632,
    c_global: "10356624,45",
    d: 22,
    d_global: "558,8",
    ix: 6860,
    ix_global: 2855347580,
    iy: 4350,
    iy_global: 1810606701,
    iz: 4350,
    iz_global: 1810606701,
    t: "0,7",
    t_global: "17,78",
    zy: 462,
    zy_global: "7570823,568",
    zz: 462,
    zz_global: "7570823,568",
  },
  {
    profile_section_id: 1242,
    name: "HSS20X20X7/8",
    type: "HSS Rectangle",
    designation: "HSST20X20X.875",
    shape: "RHS",
    country_code: "American",
    ax: "60,8",
    ax_global: "39225,728",
    b: 20,
    b_global: 508,
    c: 597,
    c_global: "9783077,208",
    d: 20,
    d_global: 508,
    ix: 5870,
    ix_global: 2443278468,
    iy: 3670,
    iy_global: 1527569332,
    iz: 3670,
    iz_global: 1527569332,
    t: "0,81",
    t_global: "20,574",
    zy: 433,
    zy_global: "7095598,712",
    zz: 433,
    zz_global: "7095598,712",
  },
  {
    profile_section_id: 1243,
    name: "HSS20X20X3/4",
    type: "HSS Rectangle",
    designation: "HSST20X20X.75",
    shape: "RHS",
    country_code: "American",
    ax: "52,6",
    ax_global: "33935,416",
    b: 20,
    b_global: 508,
    c: 519,
    c_global: "8504886,216",
    d: 20,
    d_global: 508,
    ix: 5110,
    ix_global: 2126942585,
    iy: 3230,
    iy_global: 1344427505,
    iz: 3230,
    iz_global: 1344427505,
    t: "0,7",
    t_global: "17,78",
    zy: 378,
    zy_global: "6194310,192",
    zz: 378,
    zz_global: "6194310,192",
  },
  {
    profile_section_id: 1244,
    name: "HSS20X20X5/8",
    type: "HSS Rectangle",
    designation: "HSST20X20X.625",
    shape: "RHS",
    country_code: "American",
    ax: "44,3",
    ax_global: "28580,588",
    b: 20,
    b_global: 508,
    c: 437,
    c_global: "7161146,968",
    d: 20,
    d_global: 508,
    ix: 4320,
    ix_global: 1798119759,
    iy: 2750,
    iy_global: 1144636420,
    iz: 2750,
    iz_global: 1144636420,
    t: "0,58",
    t_global: "14,732",
    zy: 320,
    zy_global: "5243860,48",
    zz: 320,
    zz_global: "5243860,48",
  },
  {
    profile_section_id: 1245,
    name: "HSS20X20X1/2",
    type: "HSS Rectangle",
    designation: "HSST20X20X.5",
    shape: "RHS",
    country_code: "American",
    ax: "35,8",
    ax_global: "23096,728",
    b: 20,
    b_global: 508,
    c: 355,
    c_global: "5817407,72",
    d: 20,
    d_global: 508,
    ix: 3510,
    ix_global: 1460972304,
    iy: 2260,
    iy_global: "940683021,9",
    iz: 2260,
    iz_global: "940683021,9",
    t: "0,47",
    t_global: "11,938",
    zy: 261,
    zy_global: "4277023,704",
    zz: 261,
    zz_global: "4277023,704",
  },
  {
    profile_section_id: 1246,
    name: "HSS18X18X7/8",
    type: "HSS Rectangle",
    designation: "HSST18X18X.875",
    shape: "RHS",
    country_code: "American",
    ax: "54,3",
    ax_global: "35032,188",
    b: 18,
    b_global: "457,2",
    c: 479,
    c_global: "7849403,656",
    d: 18,
    d_global: "457,2",
    ix: 4220,
    ix_global: 1756496616,
    iy: 2630,
    iy_global: 1094688649,
    iz: 2630,
    iz_global: 1094688649,
    t: "0,81",
    t_global: "20,574",
    zy: 346,
    zy_global: "5669924,144",
    zz: 346,
    zz_global: "5669924,144",
  },
  {
    profile_section_id: 1247,
    name: "HSS18X18X3/4",
    type: "HSS Rectangle",
    designation: "HSST18X18X.75",
    shape: "RHS",
    country_code: "American",
    ax: "47,1",
    ax_global: "30387,036",
    b: 18,
    b_global: "457,2",
    c: 417,
    c_global: "6833405,688",
    d: 18,
    d_global: "457,2",
    ix: 3690,
    ix_global: 1535893960,
    iy: 2320,
    iy_global: "965656907,4",
    iz: 2320,
    iz_global: "965656907,4",
    t: "0,7",
    t_global: "17,78",
    zy: 302,
    zy_global: "4948893,328",
    zz: 302,
    zz_global: "4948893,328",
  },
  {
    profile_section_id: 1248,
    name: "HSS18X18X5/8",
    type: "HSS Rectangle",
    designation: "HSST18X18X.625",
    shape: "RHS",
    country_code: "American",
    ax: "39,6",
    ax_global: "25548,336",
    b: 18,
    b_global: "457,2",
    c: 352,
    c_global: "5768246,528",
    d: 18,
    d_global: "457,2",
    ix: 3120,
    ix_global: 1298642048,
    iy: 1980,
    iy_global: "824138222,7",
    iz: 1980,
    iz_global: "824138222,7",
    t: "0,58",
    t_global: "14,732",
    zy: 257,
    zy_global: "4211475,448",
    zz: 257,
    zz_global: "4211475,448",
  },
  {
    profile_section_id: 1249,
    name: "HSS18X18X1/2",
    type: "HSS Rectangle",
    designation: "HSST18X18X.5",
    shape: "RHS",
    country_code: "American",
    ax: "32,1",
    ax_global: "20709,636",
    b: 18,
    b_global: "457,2",
    c: 286,
    c_global: "4686700,304",
    d: 18,
    d_global: "457,2",
    ix: 2540,
    ix_global: 1057227821,
    iy: 1630,
    iy_global: "678457223,7",
    iz: 1630,
    iz_global: "678457223,7",
    t: "0,47",
    t_global: "11,938",
    zy: 210,
    zy_global: "3441283,44",
    zz: 210,
    zz_global: "3441283,44",
  },
  {
    profile_section_id: 1250,
    name: "HSS16X16X7/8",
    type: "HSS Rectangle",
    designation: "HSST16X16X.875",
    shape: "RHS",
    country_code: "American",
    ax: "47,7",
    ax_global: "30774,132",
    b: 16,
    b_global: "406,4",
    c: 373,
    c_global: "6112374,872",
    d: 16,
    d_global: "406,4",
    ix: 2920,
    ix_global: 1215395763,
    iy: 1800,
    iy_global: "749216566,1",
    iz: 1800,
    iz_global: "749216566,1",
    t: "0,81",
    t_global: "20,574",
    zy: 268,
    zy_global: "4391733,152",
    zz: 268,
    zz_global: "4391733,152",
  },
  {
    profile_section_id: 1251,
    name: "HSS16X16X3/4",
    type: "HSS Rectangle",
    designation: "HSST16X16X.75",
    shape: "RHS",
    country_code: "American",
    ax: "41,5",
    ax_global: "26774,14",
    b: 16,
    b_global: "406,4",
    c: 326,
    c_global: "5342182,864",
    d: 16,
    d_global: "406,4",
    ix: 2560,
    ix_global: 1065552450,
    iy: 1590,
    iy_global: "661807966,7",
    iz: 1590,
    iz_global: "661807966,7",
    t: "0,7",
    t_global: "17,78",
    zy: 235,
    zy_global: "3850960,04",
    zz: 235,
    zz_global: "3850960,04",
  },
  {
    profile_section_id: 1252,
    name: "HSS14X14X7/8",
    type: "HSS Rectangle",
    designation: "HSST14X14X.875",
    shape: "RHS",
    country_code: "American",
    ax: "41,2",
    ax_global: "26580,592",
    b: 14,
    b_global: "355,6",
    c: 281,
    c_global: "4604764,984",
    d: 14,
    d_global: "355,6",
    ix: 1910,
    ix_global: "795002022,9",
    iy: 1170,
    iy_global: 486990768,
    iz: 1170,
    iz_global: 486990768,
    t: "0,81",
    t_global: "20,574",
    zy: 201,
    zy_global: "3293799,864",
    zz: 201,
    zz_global: "3293799,864",
  },
  {
    profile_section_id: 1253,
    name: "HSS14X14X3/4",
    type: "HSS Rectangle",
    designation: "HSST14X14X.75",
    shape: "RHS",
    country_code: "American",
    ax: "35,9",
    ax_global: "23161,244",
    b: 14,
    b_global: "355,6",
    c: 246,
    c_global: "4031217,744",
    d: 14,
    d_global: "355,6",
    ix: 1680,
    ix_global: 699268795,
    iy: 1040,
    iy_global: "432880682,6",
    iz: 1040,
    iz_global: "432880682,6",
    t: "0,7",
    t_global: "17,78",
    zy: 177,
    zy_global: "2900510,328",
    zz: 177,
    zz_global: "2900510,328",
  },
  {
    profile_section_id: 1254,
    name: "HSS12X12X3/4",
    type: "HSS Rectangle",
    designation: "HSST12X12X.75",
    shape: "RHS",
    country_code: "American",
    ax: "30,3",
    ax_global: "19548,348",
    b: 12,
    b_global: "304,8",
    c: 177,
    c_global: "2900510,328",
    d: 12,
    d_global: "304,8",
    ix: 1030,
    ix_global: "428718368,4",
    iy: 631,
    iy_global: "262642029,6",
    iz: 631,
    iz_global: "262642029,6",
    t: "0,7",
    t_global: "17,78",
    zy: 127,
    zy_global: "2081157,128",
    zz: 127,
    zz_global: "2081157,128",
  },
  {
    profile_section_id: 1255,
    name: "HSS10X10X3/4",
    type: "HSS Rectangle",
    designation: "HSST10X10X.75",
    shape: "RHS",
    country_code: "American",
    ax: "24,7",
    ax_global: "15935,452",
    b: 10,
    b_global: 254,
    c: 119,
    c_global: "1950060,616",
    d: 10,
    d_global: 254,
    ix: 578,
    ix_global: 240581764,
    iy: 347,
    iy_global: "144432304,7",
    iz: 347,
    iz_global: "144432304,7",
    t: "0,7",
    t_global: "17,78",
    zy: "84,7",
    zy_global: "1387984,321",
    zz: "84,7",
    zz_global: "1387984,321",
  },
  {
    profile_section_id: 1256,
    name: "HSS3-1/2X2-1/2X3/8",
    type: "HSS Rectangle",
    designation: "HSS3.5X2.5X.375",
    shape: "RHS",
    country_code: "American",
    ax: "3,39",
    ax_global: "2187,0924",
    b: "2,5",
    b_global: "63,5",
    c: "4,57",
    c_global: "74888,88248",
    d: "3,5",
    d_global: "88,9",
    ix: "6,16",
    ix_global: "2563985,582",
    iy: "2,77",
    iy_global: "1152961,049",
    iz: "4,75",
    iz_global: "1977099,272",
    t: "0,35",
    t_global: "8,89",
    zy: "2,82",
    zy_global: "46211,52048",
    zz: "3,59",
    zz_global: "58829,55976",
  },
  {
    profile_section_id: 1257,
    name: "HSS3-1/2X2-1/2X5/16",
    type: "HSS Rectangle",
    designation: "HSS3.5X2.5X.313",
    shape: "RHS",
    country_code: "American",
    ax: "2,94",
    ax_global: "1896,7704",
    b: "2,5",
    b_global: "63,5",
    c: "4,03",
    c_global: "66039,86792",
    d: "3,5",
    d_global: "88,9",
    ix: "5,53",
    ix_global: "2301759,784",
    iy: "2,54",
    iy_global: "1057227,821",
    iz: "4,34",
    iz_global: "1806444,387",
    t: "0,29",
    t_global: "7,366",
    zy: "2,52",
    zy_global: "41295,40128",
    zz: "3,2",
    zz_global: "52438,6048",
  },
  {
    profile_section_id: 1258,
    name: "HSS3-1/2X2-1/2X1/4",
    type: "HSS Rectangle",
    designation: "HSS3.5X2.5X.250",
    shape: "RHS",
    country_code: "American",
    ax: "2,44",
    ax_global: "1574,1904",
    b: "2,5",
    b_global: "63,5",
    c: "3,4",
    c_global: "55716,0176",
    d: "3,5",
    d_global: "88,9",
    ix: "4,75",
    ix_global: "1977099,272",
    iy: "2,23",
    iy_global: "928196,0791",
    iz: "3,79",
    iz_global: "1577517,103",
    t: "0,23",
    t_global: "5,842",
    zy: "2,16",
    zy_global: "35396,05824",
    zz: "2,74",
    zz_global: "44900,55536",
  },
  {
    profile_section_id: 1259,
    name: "HSS3-1/2X2-1/2X3/16",
    type: "HSS Rectangle",
    designation: "HSS3.5X2.5X.188",
    shape: "RHS",
    country_code: "American",
    ax: "1,89",
    ax_global: "1219,3524",
    b: "2,5",
    b_global: "63,5",
    c: "2,67",
    c_global: "43753,46088",
    d: "3,5",
    d_global: "88,9",
    ix: "3,78",
    ix_global: "1573354,789",
    iy: "1,82",
    iy_global: "757541,1946",
    iz: "3,09",
    iz_global: "1286155,105",
    t: "0,17",
    t_global: "4,318",
    zy: "1,72",
    zy_global: "28185,75008",
    zz: "2,18",
    zz_global: "35723,79952",
  },
  {
    profile_section_id: 1260,
    name: "HSS3-1/2X2-1/2X1/8",
    type: "HSS Rectangle",
    designation: "HSS3.5X2.5X.125",
    shape: "RHS",
    country_code: "American",
    ax: "1,3",
    ax_global: "838,708",
    b: "2,5",
    b_global: "63,5",
    c: "1,87",
    c_global: "30643,80968",
    d: "3,5",
    d_global: "88,9",
    ix: "2,67",
    ix_global: "1111337,906",
    iy: "1,33",
    iy_global: "553587,796",
    iz: "2,23",
    iz_global: "928196,0791",
    t: "0,12",
    t_global: "3,048",
    zy: "1,22",
    zy_global: "19992,21808",
    zz: "1,54",
    zz_global: "25236,07856",
  },
  {
    profile_section_id: 1920,
    name: "TUB20203",
    type: "Tube",
    designation: "TUB20203",
    shape: "RHS",
    country_code: "American",
    ax: "1,27",
    ax_global: "819,3532",
    b: 2,
    b_global: "50,8",
    d: 2,
    d_global: "50,8",
    ix: "1,2",
    ix_global: "499477,7107",
    iy: "0,7",
    iy_global: "291361,9979",
    iz: "0,7",
    iz_global: "291361,9979",
    t: "0,1875",
    t_global: "4,7625",
    zy: "0,84",
    zy_global: "13765,13376",
    zz: "0,84",
    zz_global: "13765,13376",
  },
  {
    profile_section_id: 1921,
    name: "TUB20204",
    type: "Tube",
    designation: "TUB20204",
    shape: "RHS",
    country_code: "American",
    ax: "1,59",
    ax_global: "1025,8044",
    b: 2,
    b_global: "50,8",
    d: 2,
    d_global: "50,8",
    ix: "1,4",
    ix_global: "582723,9958",
    iy: "0,8",
    iy_global: "332985,1405",
    iz: "0,8",
    iz_global: "332985,1405",
    t: "0,25",
    t_global: "6,35",
    zy: 1,
    zy_global: "16387,064",
    zz: 1,
    zz_global: "16387,064",
  },
  {
    profile_section_id: 1922,
    name: "TUB20205",
    type: "Tube",
    designation: "TUB20205",
    shape: "RHS",
    country_code: "American",
    ax: "1,86",
    ax_global: "1199,9976",
    b: 2,
    b_global: "50,8",
    d: 2,
    d_global: "50,8",
    ix: "1,49",
    ix_global: "620184,8241",
    iy: "0,88",
    iy_global: "366283,6545",
    iz: "0,88",
    iz_global: "366283,6545",
    t: "0,3125",
    t_global: "7,9375",
    zy: "1,11",
    zy_global: "18189,64104",
    zz: "1,11",
    zz_global: "18189,64104",
  },
  {
    profile_section_id: 1923,
    name: "TUB25253",
    type: "Tube",
    designation: "TUB25253",
    shape: "RHS",
    country_code: "American",
    ax: "1,64",
    ax_global: "1058,0624",
    b: "2,5",
    b_global: "63,5",
    d: "2,5",
    d_global: "63,5",
    ix: "2,4",
    ix_global: "998955,4214",
    iy: "1,4",
    iy_global: "582723,9958",
    iz: "1,4",
    iz_global: "582723,9958",
    t: "0,1875",
    t_global: "4,7625",
    zy: "1,4",
    zy_global: "22941,8896",
    zz: "1,4",
    zz_global: "22941,8896",
  },
  {
    profile_section_id: 1924,
    name: "TUB25254",
    type: "Tube",
    designation: "TUB25254",
    shape: "RHS",
    country_code: "American",
    ax: "2,09",
    ax_global: "1348,3844",
    b: "2,5",
    b_global: "63,5",
    d: "2,5",
    d_global: "63,5",
    ix: "2,9",
    ix_global: "1207071,134",
    iy: "1,7",
    iy_global: "707593,4235",
    iz: "1,7",
    iz_global: "707593,4235",
    t: "0,25",
    t_global: "6,35",
    zy: "1,71",
    zy_global: "28021,87944",
    zz: "1,71",
    zz_global: "28021,87944",
  },
  {
    profile_section_id: 1925,
    name: "TUB25255",
    type: "Tube",
    designation: "TUB25255",
    shape: "RHS",
    country_code: "American",
    ax: "2,48",
    ax_global: "1599,9968",
    b: "2,5",
    b_global: "63,5",
    d: "2,5",
    d_global: "63,5",
    ix: "3,32",
    ix_global: "1381888,333",
    iy: "1,87",
    iy_global: "778352,7659",
    iz: "1,87",
    iz_global: "778352,7659",
    t: "0,3125",
    t_global: "7,9375",
    zy: "1,96",
    zy_global: "32118,64544",
    zz: "1,96",
    zz_global: "32118,64544",
  },
  {
    profile_section_id: 1926,
    name: "TUB30203",
    type: "Tube",
    designation: "TUB30203",
    shape: "RHS",
    country_code: "American",
    ax: "1,64",
    ax_global: "1058,0624",
    b: 2,
    b_global: "50,8",
    d: 3,
    d_global: "76,2",
    ix: "2,2",
    ix_global: "915709,1363",
    iy: 1,
    iy_global: "416231,4256",
    iz: "1,9",
    iz_global: "790839,7086",
    t: "0,1875",
    t_global: "4,7625",
    zy: "1,18",
    zy_global: "19336,73552",
    zz: "1,57",
    zz_global: "25727,69048",
  },
  {
    profile_section_id: 1927,
    name: "TUB30204",
    type: "Tube",
    designation: "TUB30204",
    shape: "RHS",
    country_code: "American",
    ax: "2,09",
    ax_global: "1348,3844",
    b: 2,
    b_global: "50,8",
    d: 3,
    d_global: "76,2",
    ix: "2,6",
    ix_global: "1082201,707",
    iy: "1,2",
    iy_global: "499477,7107",
    iz: "2,2",
    iz_global: "915709,1363",
    t: "0,25",
    t_global: "6,35",
    zy: "1,44",
    zy_global: "23597,37216",
    zz: "1,92",
    zz_global: "31463,16288",
  },
  {
    profile_section_id: 1928,
    name: "TUB30303",
    type: "Tube",
    designation: "TUB30303",
    shape: "RHS",
    country_code: "American",
    ax: "2,02",
    ax_global: "1303,2232",
    b: 3,
    b_global: "76,2",
    d: 3,
    d_global: "76,2",
    ix: "4,3",
    ix_global: "1789795,13",
    iy: "2,6",
    iy_global: "1082201,707",
    iz: "2,6",
    iz_global: "1082201,707",
    t: "0,1875",
    t_global: "4,7625",
    zy: "2,1",
    zy_global: "34412,8344",
    zz: "2,1",
    zz_global: "34412,8344",
  },
  {
    profile_section_id: 1929,
    name: "TUB30304",
    type: "Tube",
    designation: "TUB30304",
    shape: "RHS",
    country_code: "American",
    ax: "2,59",
    ax_global: "1670,9644",
    b: 3,
    b_global: "76,2",
    d: 3,
    d_global: "76,2",
    ix: "5,4",
    ix_global: "2247649,698",
    iy: "3,2",
    iy_global: "1331940,562",
    iz: "3,2",
    iz_global: "1331940,562",
    t: "0,25",
    t_global: "6,35",
    zy: "2,61",
    zy_global: "42770,23704",
    zz: "2,61",
    zz_global: "42770,23704",
  },
  {
    profile_section_id: 1930,
    name: "TUB30305",
    type: "Tube",
    designation: "TUB30305",
    shape: "RHS",
    country_code: "American",
    ax: "3,11",
    ax_global: "2006,4476",
    b: 3,
    b_global: "76,2",
    d: 3,
    d_global: "76,2",
    ix: "6,2",
    ix_global: "2580634,839",
    iy: "3,6",
    iy_global: "1498433,132",
    iz: "3,6",
    iz_global: "1498433,132",
    t: "0,3125",
    t_global: "7,9375",
    zy: "3,04",
    zy_global: "49816,67456",
    zz: "3,04",
    zz_global: "49816,67456",
  },
  {
    profile_section_id: 1931,
    name: "TUB35253",
    type: "Tube",
    designation: "TUB35253",
    shape: "RHS",
    country_code: "American",
    ax: "2,02",
    ax_global: "1303,2232",
    b: "2,5",
    b_global: "63,5",
    d: "3,5",
    d_global: "88,9",
    ix: "4,02",
    ix_global: "1673250,331",
    iy: "1,93",
    iy_global: "803326,6514",
    iz: "3,26",
    iz_global: "1356914,447",
    t: "0,1875",
    t_global: "4,7625",
    zy: "1,83",
    zy_global: "29988,32712",
    zz: "2,31",
    zz_global: "37854,11784",
  },
  {
    profile_section_id: 1932,
    name: "TUB35254",
    type: "Tube",
    designation: "TUB35254",
    shape: "RHS",
    country_code: "American",
    ax: "2,59",
    ax_global: "1670,9644",
    b: "2,5",
    b_global: "63,5",
    d: "3,5",
    d_global: "88,9",
    ix: "4,99",
    ix_global: "2076994,814",
    iy: "2,33",
    iy_global: "969819,2216",
    iz: "3,97",
    iz_global: "1652438,76",
    t: "0,25",
    t_global: "6,35",
    zy: "2,28",
    zy_global: "37362,50592",
    zz: "2,88",
    zz_global: "47194,74432",
  },
  {
    profile_section_id: 1933,
    name: "TUB35353",
    type: "Tube",
    designation: "TUB35353",
    shape: "RHS",
    country_code: "American",
    ax: "2,39",
    ax_global: "1541,9324",
    b: "3,5",
    b_global: "88,9",
    d: "3,5",
    d_global: "88,9",
    ix: 7,
    ix_global: "2913619,979",
    iy: "4,3",
    iy_global: "1789795,13",
    iz: "4,3",
    iz_global: "1789795,13",
    t: "0,1875",
    t_global: "4,7625",
    zy: "2,93",
    zy_global: "48014,09752",
    zz: "2,93",
    zz_global: "48014,09752",
  },
  {
    profile_section_id: 1934,
    name: "TUB35354",
    type: "Tube",
    designation: "TUB35354",
    shape: "RHS",
    country_code: "American",
    ax: "3,09",
    ax_global: "1993,5444",
    b: "3,5",
    b_global: "88,9",
    d: "3,5",
    d_global: "88,9",
    ix: "8,8",
    ix_global: "3662836,545",
    iy: "5,3",
    iy_global: "2206026,556",
    iz: "5,3",
    iz_global: "2206026,556",
    t: "0,25",
    t_global: "6,35",
    zy: "3,7",
    zy_global: "60632,1368",
    zz: "3,7",
    zz_global: "60632,1368",
  },
  {
    profile_section_id: 1935,
    name: "TUB35355",
    type: "Tube",
    designation: "TUB35355",
    shape: "RHS",
    country_code: "American",
    ax: "3,73",
    ax_global: "2406,4468",
    b: "3,5",
    b_global: "88,9",
    d: "3,5",
    d_global: "88,9",
    ix: "10,4",
    ix_global: "4328806,826",
    iy: "6,1",
    iy_global: "2539011,696",
    iz: "6,1",
    iz_global: "2539011,696",
    t: "0,3125",
    t_global: "7,9375",
    zy: "4,35",
    zy_global: "71283,7284",
    zz: "4,35",
    zz_global: "71283,7284",
  },
  {
    profile_section_id: 1936,
    name: "TUB40203",
    type: "Tube",
    designation: "TUB40203",
    shape: "RHS",
    country_code: "American",
    ax: "2,02",
    ax_global: "1303,2232",
    b: 2,
    b_global: "50,8",
    d: 4,
    d_global: "101,6",
    ix: "3,8",
    ix_global: "1581679,417",
    iy: "1,3",
    iy_global: "541100,8533",
    iz: "3,9",
    iz_global: "1623302,56",
    t: "0,1875",
    t_global: "4,7625",
    zy: "1,52",
    zy_global: "24908,33728",
    zz: "2,48",
    zz_global: "40639,91872",
  },
  {
    profile_section_id: 1937,
    name: "TUB40204",
    type: "Tube",
    designation: "TUB40204",
    shape: "RHS",
    country_code: "American",
    ax: "2,59",
    ax_global: "1670,9644",
    b: 2,
    b_global: "50,8",
    d: 4,
    d_global: "101,6",
    ix: 4,
    ix_global: "1664925,702",
    iy: "1,5",
    iy_global: "624347,1384",
    iz: "4,7",
    iz_global: "1956287,7",
    t: "0,25",
    t_global: "6,35",
    zy: "1,88",
    zy_global: "30807,68032",
    zz: "3,09",
    zz_global: "50636,02776",
  },
  {
    profile_section_id: 1938,
    name: "TUB40205",
    type: "Tube",
    designation: "TUB40205",
    shape: "RHS",
    country_code: "American",
    ax: "3,11",
    ax_global: "2006,4476",
    b: 2,
    b_global: "50,8",
    d: 4,
    d_global: "101,6",
    ix: "4,6",
    ix_global: "1914664,558",
    iy: "1,7",
    iy_global: "707593,4235",
    iz: "5,3",
    iz_global: "2206026,556",
    t: "0,3125",
    t_global: "7,9375",
    zy: "2,17",
    zy_global: "35559,92888",
    zz: "3,6",
    zz_global: "58993,4304",
  },
  {
    profile_section_id: 1939,
    name: "TUB40303",
    type: "Tube",
    designation: "TUB40303",
    shape: "RHS",
    country_code: "American",
    ax: "2,39",
    ax_global: "1541,9324",
    b: 3,
    b_global: "76,2",
    d: 4,
    d_global: "101,6",
    ix: "6,7",
    ix_global: "2788750,552",
    iy: "3,3",
    iy_global: "1373563,704",
    iz: "5,2",
    iz_global: "2164403,413",
    t: "0,1875",
    t_global: "4,7625",
    zy: "2,62",
    zy_global: "42934,10768",
    zz: "3,2",
    zz_global: "52438,6048",
  },
  {
    profile_section_id: 1940,
    name: "TUB40304",
    type: "Tube",
    designation: "TUB40304",
    shape: "RHS",
    country_code: "American",
    ax: "3,09",
    ax_global: "1993,5444",
    b: 3,
    b_global: "76,2",
    d: 4,
    d_global: "101,6",
    ix: "8,4",
    ix_global: "3496343,975",
    iy: "4,1",
    iy_global: "1706548,845",
    iz: "6,5",
    iz_global: "2705504,266",
    t: "0,25",
    t_global: "6,35",
    zy: "3,3",
    zy_global: "54077,3112",
    zz: "4,03",
    zz_global: "66039,86792",
  },
  {
    profile_section_id: 1941,
    name: "TUB40305",
    type: "Tube",
    designation: "TUB40305",
    shape: "RHS",
    country_code: "American",
    ax: "3,73",
    ax_global: "2406,4468",
    b: 3,
    b_global: "76,2",
    d: 4,
    d_global: "101,6",
    ix: "9,9",
    ix_global: "4120691,113",
    iy: "4,7",
    iy_global: "1956287,7",
    iz: "7,5",
    iz_global: "3121735,692",
    t: "0,3125",
    t_global: "7,9375",
    zy: "3,88",
    zy_global: "63581,80832",
    zz: "4,75",
    zz_global: "77838,554",
  },
  {
    profile_section_id: 1942,
    name: "TUB40403",
    type: "Tube",
    designation: "TUB40403",
    shape: "RHS",
    country_code: "American",
    ax: "2,77",
    ax_global: "1787,0932",
    b: 4,
    b_global: "101,6",
    d: 4,
    d_global: "101,6",
    ix: "10,6",
    ix_global: "4412053,111",
    iy: "6,6",
    iy_global: "2747127,409",
    iz: "6,6",
    iz_global: "2747127,409",
    t: "0,1875",
    t_global: "4,7625",
    zy: "3,91",
    zy_global: "64073,42024",
    zz: "3,91",
    zz_global: "64073,42024",
  },
  {
    profile_section_id: 1943,
    name: "TUB40404",
    type: "Tube",
    designation: "TUB40404",
    shape: "RHS",
    country_code: "American",
    ax: "3,59",
    ax_global: "2316,1244",
    b: 4,
    b_global: "101,6",
    d: 4,
    d_global: "101,6",
    ix: "13,5",
    ix_global: "5619124,246",
    iy: "8,2",
    iy_global: "3413097,69",
    iz: "8,2",
    iz_global: "3413097,69",
    t: "0,25",
    t_global: "6,35",
    zy: "4,97",
    zy_global: "81443,70808",
    zz: "4,97",
    zz_global: "81443,70808",
  },
  {
    profile_section_id: 1944,
    name: "TUB40405",
    type: "Tube",
    designation: "TUB40405",
    shape: "RHS",
    country_code: "American",
    ax: "4,36",
    ax_global: "2812,8976",
    b: 4,
    b_global: "101,6",
    d: 4,
    d_global: "101,6",
    ix: "16,1",
    ix_global: "6701325,952",
    iy: "9,6",
    iy_global: "3995821,686",
    iz: "9,6",
    iz_global: "3995821,686",
    t: "0,3125",
    t_global: "7,9375",
    zy: "5,9",
    zy_global: "96683,6776",
    zz: "5,9",
    zz_global: "96683,6776",
  },
  {
    profile_section_id: 1945,
    name: "TUB40406",
    type: "Tube",
    designation: "TUB40406",
    shape: "RHS",
    country_code: "American",
    ax: "5,08",
    ax_global: "3277,4128",
    b: 4,
    b_global: "101,6",
    d: 4,
    d_global: "101,6",
    ix: "18,4",
    ix_global: "7658658,231",
    iy: "10,7",
    iy_global: "4453676,254",
    iz: "10,7",
    iz_global: "4453676,254",
    t: "0,375",
    t_global: "9,525",
    zy: "6,72",
    zy_global: "110121,0701",
    zz: "6,72",
    zz_global: "110121,0701",
  },
  {
    profile_section_id: 1946,
    name: "TUB40408",
    type: "Tube",
    designation: "TUB40408",
    shape: "RHS",
    country_code: "American",
    ax: "6,36",
    ax_global: "4103,2176",
    b: 4,
    b_global: "101,6",
    d: 4,
    d_global: "101,6",
    ix: "21,8",
    ix_global: "9073845,078",
    iy: "12,3",
    iy_global: "5119646,535",
    iz: "12,3",
    iz_global: "5119646,535",
    t: "0,5",
    t_global: "12,7",
    zy: "8,02",
    zy_global: "131424,2533",
    zz: "8,02",
    zz_global: "131424,2533",
  },
  {
    profile_section_id: 1947,
    name: "TUB45453",
    type: "Tube",
    designation: "TUB45453",
    shape: "RHS",
    country_code: "American",
    ax: "3,14",
    ax_global: "2025,8024",
    b: "4,5",
    b_global: "114,3",
    d: "4,5",
    d_global: "114,3",
    ix: "15,4",
    ix_global: "6409963,954",
    iy: "9,6",
    iy_global: "3995821,686",
    iz: "9,6",
    iz_global: "3995821,686",
    t: "0,1875",
    t_global: "4,7625",
    zy: "5,03",
    zy_global: "82426,93192",
    zz: "5,03",
    zz_global: "82426,93192",
  },
  {
    profile_section_id: 1948,
    name: "TUB45454",
    type: "Tube",
    designation: "TUB45454",
    shape: "RHS",
    country_code: "American",
    ax: "4,09",
    ax_global: "2638,7044",
    b: "4,5",
    b_global: "114,3",
    d: "4,5",
    d_global: "114,3",
    ix: "19,7",
    ix_global: "8199759,084",
    iy: "12,1",
    iy_global: "5036400,25",
    iz: "12,1",
    iz_global: "5036400,25",
    t: "0,25",
    t_global: "6,35",
    zy: "6,43",
    zy_global: "105368,8215",
    zz: "6,43",
    zz_global: "105368,8215",
  },
  {
    profile_section_id: 1949,
    name: "TUB50203",
    type: "Tube",
    designation: "TUB50203",
    shape: "RHS",
    country_code: "American",
    ax: "2,39",
    ax_global: "1541,9324",
    b: 2,
    b_global: "50,8",
    d: 5,
    d_global: 127,
    ix: "4,4",
    ix_global: "1831418,273",
    iy: "1,6",
    iy_global: "665970,281",
    iz: "6,9",
    iz_global: "2871996,837",
    t: "0,1875",
    t_global: "4,7625",
    zy: "1,86",
    zy_global: "30479,93904",
    zz: "3,59",
    zz_global: "58829,55976",
  },
  {
    profile_section_id: 1950,
    name: "TUB50204",
    type: "Tube",
    designation: "TUB50204",
    shape: "RHS",
    country_code: "American",
    ax: "3,09",
    ax_global: "1993,5444",
    b: 2,
    b_global: "50,8",
    d: 5,
    d_global: 127,
    ix: "5,4",
    ix_global: "2247649,698",
    iy: "1,9",
    iy_global: "790839,7086",
    iz: "8,5",
    iz_global: "3537967,118",
    t: "0,25",
    t_global: "6,35",
    zy: "2,32",
    zy_global: "38017,98848",
    zz: "4,51",
    zz_global: "73905,65864",
  },
  {
    profile_section_id: 1951,
    name: "TUB50205",
    type: "Tube",
    designation: "TUB50205",
    shape: "RHS",
    country_code: "American",
    ax: "3,73",
    ax_global: "2406,4468",
    b: 2,
    b_global: "50,8",
    d: 5,
    d_global: 127,
    ix: "6,2",
    ix_global: "2580634,839",
    iy: "2,2",
    iy_global: "915709,1363",
    iz: "9,7",
    iz_global: "4037444,828",
    t: "0,3125",
    t_global: "7,9375",
    zy: "2,7",
    zy_global: "44245,0728",
    zz: "5,31",
    zz_global: "87015,30984",
  },
  {
    profile_section_id: 1952,
    name: "TUB50303",
    type: "Tube",
    designation: "TUB50303",
    shape: "RHS",
    country_code: "American",
    ax: "2,77",
    ax_global: "1787,0932",
    b: 3,
    b_global: "76,2",
    d: 5,
    d_global: 127,
    ix: "9,2",
    ix_global: "3829329,116",
    iy: "4,1",
    iy_global: "1706548,845",
    iz: "9,1",
    iz_global: "3787705,973",
    t: "0,1875",
    t_global: "4,7625",
    zy: "3,15",
    zy_global: "51619,2516",
    zz: "4,49",
    zz_global: "73577,91736",
  },
  {
    profile_section_id: 1953,
    name: "TUB50304",
    type: "Tube",
    designation: "TUB50304",
    shape: "RHS",
    country_code: "American",
    ax: "3,59",
    ax_global: "2316,1244",
    b: 3,
    b_global: "76,2",
    d: 5,
    d_global: 127,
    ix: "11,7",
    ix_global: "4869907,68",
    iy: "5,1",
    iy_global: "2122780,271",
    iz: "11,3",
    iz_global: "4703415,109",
    t: "0,25",
    t_global: "6,35",
    zy: "3,99",
    zy_global: "65384,38536",
    zz: "5,7",
    zz_global: "93406,2648",
  },
  {
    profile_section_id: 1954,
    name: "TUB50305",
    type: "Tube",
    designation: "TUB50305",
    shape: "RHS",
    country_code: "American",
    ax: "4,36",
    ax_global: "2812,8976",
    b: 3,
    b_global: "76,2",
    d: 5,
    d_global: 127,
    ix: "13,8",
    ix_global: "5743993,673",
    iy: "5,9",
    iy_global: "2455765,411",
    iz: "13,2",
    iz_global: "5494254,818",
    t: "0,3125",
    t_global: "7,9375",
    zy: "4,72",
    zy_global: "77346,94208",
    zz: "6,77",
    zz_global: "110940,4233",
  },
  {
    profile_section_id: 1955,
    name: "TUB50306",
    type: "Tube",
    designation: "TUB50306",
    shape: "RHS",
    country_code: "American",
    ax: "5,08",
    ax_global: "3277,4128",
    b: 3,
    b_global: "76,2",
    d: 5,
    d_global: 127,
    ix: "15,6",
    ix_global: "6493210,239",
    iy: "6,5",
    iy_global: "2705504,266",
    iz: "14,7",
    iz_global: "6118601,956",
    t: "0,375",
    t_global: "9,525",
    zy: "5,35",
    zy_global: "87670,7924",
    zz: "7,71",
    zz_global: "126344,2634",
  },
  {
    profile_section_id: 1956,
    name: "TUB50308",
    type: "Tube",
    designation: "TUB50308",
    shape: "RHS",
    country_code: "American",
    ax: "6,36",
    ax_global: "4103,2176",
    b: 3,
    b_global: "76,2",
    d: 5,
    d_global: 127,
    ix: "18,2",
    ix_global: "7575411,946",
    iy: "7,3",
    iy_global: "3038489,407",
    iz: "16,9",
    iz_global: "7034311,093",
    t: "0,5",
    t_global: "12,7",
    zy: "6,35",
    zy_global: "104057,8564",
    zz: "9,2",
    zz_global: "150760,9888",
  },
  {
    profile_section_id: 1957,
    name: "TUB50403",
    type: "Tube",
    designation: "TUB50403",
    shape: "RHS",
    country_code: "American",
    ax: "3,14",
    ax_global: "2025,8024",
    b: 4,
    b_global: "101,6",
    d: 5,
    d_global: 127,
    ix: "14,9",
    ix_global: "6201848,241",
    iy: 8,
    iy_global: "3329851,405",
    iz: "11,2",
    iz_global: "4661791,967",
    t: "0,1875",
    t_global: "4,7625",
    zy: "4,63",
    zy_global: "75872,10632",
    zz: "5,39",
    zz_global: "88326,27496",
  },
  {
    profile_section_id: 1958,
    name: "TUB50404",
    type: "Tube",
    designation: "TUB50404",
    shape: "RHS",
    country_code: "American",
    ax: "4,09",
    ax_global: "2638,7044",
    b: 4,
    b_global: "101,6",
    d: 5,
    d_global: 127,
    ix: "19,1",
    ix_global: "7950020,229",
    iy: 10,
    iy_global: "4162314,256",
    iz: "14,1",
    iz_global: "5868863,101",
    t: "0,25",
    t_global: "6,35",
    zy: "5,9",
    zy_global: "96683,6776",
    zz: "6,89",
    zz_global: "112906,871",
  },
  {
    profile_section_id: 1959,
    name: "TUB50405",
    type: "Tube",
    designation: "TUB50405",
    shape: "RHS",
    country_code: "American",
    ax: "4,98",
    ax_global: "3212,8968",
    b: 4,
    b_global: "101,6",
    d: 5,
    d_global: 127,
    ix: "22,9",
    ix_global: "9531699,646",
    iy: "11,7",
    iy_global: "4869907,68",
    iz: "16,6",
    iz_global: "6909441,665",
    t: "0,3125",
    t_global: "7,9375",
    zy: "7,05",
    zy_global: "115528,8012",
    zz: "8,24",
    zz_global: "135029,4074",
  },
  {
    profile_section_id: 1960,
    name: "TUB50406",
    type: "Tube",
    designation: "TUB50406",
    shape: "RHS",
    country_code: "American",
    ax: "5,83",
    ax_global: "3761,2828",
    b: 4,
    b_global: "101,6",
    d: 5,
    d_global: 127,
    ix: "26,3",
    ix_global: "10946886,49",
    iy: "13,2",
    iy_global: "5494254,818",
    iz: "18,7",
    iz_global: "7783527,659",
    t: "0,375",
    t_global: "9,525",
    zy: "8,08",
    zy_global: "132407,4771",
    zz: "9,44",
    zz_global: "154693,8842",
  },
  {
    profile_section_id: 1961,
    name: "TUB50503",
    type: "Tube",
    designation: "TUB50503",
    shape: "RHS",
    country_code: "American",
    ax: "3,52",
    ax_global: "2270,9632",
    b: 5,
    b_global: 127,
    d: 5,
    d_global: 127,
    ix: "21,3",
    ix_global: "8865729,365",
    iy: "13,4",
    iy_global: "5577501,103",
    iz: "13,4",
    iz_global: "5577501,103",
    t: "0,1875",
    t_global: "4,7625",
    zy: "6,29",
    zy_global: "103074,6326",
    zz: "6,29",
    zz_global: "103074,6326",
  },
  {
    profile_section_id: 1962,
    name: "TUB50504",
    type: "Tube",
    designation: "TUB50504",
    shape: "RHS",
    country_code: "American",
    ax: "4,59",
    ax_global: "2961,2844",
    b: 5,
    b_global: 127,
    d: 5,
    d_global: 127,
    ix: "27,4",
    ix_global: "11404741,06",
    iy: "16,9",
    iy_global: "7034311,093",
    iz: "16,9",
    iz_global: "7034311,093",
    t: "0,25",
    t_global: "6,35",
    zy: "8,07",
    zy_global: "132243,6065",
    zz: "8,07",
    zz_global: "132243,6065",
  },
  {
    profile_section_id: 1963,
    name: "TUB50505",
    type: "Tube",
    designation: "TUB50505",
    shape: "RHS",
    country_code: "American",
    ax: "5,61",
    ax_global: "3619,3476",
    b: 5,
    b_global: 127,
    d: 5,
    d_global: 127,
    ix: "33,1",
    ix_global: "13777260,19",
    iy: "20,1",
    iy_global: "8366251,655",
    iz: "20,1",
    iz_global: "8366251,655",
    t: "0,3125",
    t_global: "7,9375",
    zy: "9,7",
    zy_global: "158954,5208",
    zz: "9,7",
    zz_global: "158954,5208",
  },
  {
    profile_section_id: 1964,
    name: "TUB50506",
    type: "Tube",
    designation: "TUB50506",
    shape: "RHS",
    country_code: "American",
    ax: "6,58",
    ax_global: "4245,1528",
    b: 5,
    b_global: 127,
    d: 5,
    d_global: 127,
    ix: "38,2",
    ix_global: "15900040,46",
    iy: "22,8",
    iy_global: "9490076,504",
    iz: "22,8",
    iz_global: "9490076,504",
    t: "0,375",
    t_global: "9,525",
    zy: "11,2",
    zy_global: "183535,1168",
    zz: "11,2",
    zz_global: "183535,1168",
  },
  {
    profile_section_id: 1965,
    name: "TUB50508",
    type: "Tube",
    designation: "TUB50508",
    shape: "RHS",
    country_code: "American",
    ax: "8,36",
    ax_global: "5393,5376",
    b: 5,
    b_global: 127,
    d: 5,
    d_global: 127,
    ix: "46,8",
    ix_global: "19479630,72",
    iy: 27,
    iy_global: "11238248,49",
    iz: 27,
    iz_global: "11238248,49",
    t: "0,5",
    t_global: "12,7",
    zy: "13,7",
    zy_global: "224502,7768",
    zz: "13,7",
    zz_global: "224502,7768",
  },
  {
    profile_section_id: 1966,
    name: "TUB60203",
    type: "Tube",
    designation: "TUB60203",
    shape: "RHS",
    country_code: "American",
    ax: "2,77",
    ax_global: "1787,0932",
    b: 2,
    b_global: "50,8",
    d: 6,
    d_global: "152,4",
    ix: "5,6",
    ix_global: "2330895,983",
    iy: "1,9",
    iy_global: "790839,7086",
    iz: "11,1",
    iz_global: "4620168,824",
    t: "0,1875",
    t_global: "4,7625",
    zy: "2,2",
    zy_global: "36051,5408",
    zz: "4,88",
    zz_global: "79968,87232",
  },
  {
    profile_section_id: 1967,
    name: "TUB60204",
    type: "Tube",
    designation: "TUB60204",
    shape: "RHS",
    country_code: "American",
    ax: "3,59",
    ax_global: "2316,1244",
    b: 2,
    b_global: "50,8",
    d: 6,
    d_global: "152,4",
    ix: "6,9",
    ix_global: "2871996,837",
    iy: "2,3",
    iy_global: "957332,2789",
    iz: "13,8",
    iz_global: "5743993,673",
    t: "0,25",
    t_global: "6,35",
    zy: "2,75",
    zy_global: "45064,426",
    zz: "6,18",
    zz_global: "101272,0555",
  },
  {
    profile_section_id: 1968,
    name: "TUB60205",
    type: "Tube",
    designation: "TUB60205",
    shape: "RHS",
    country_code: "American",
    ax: "4,36",
    ax_global: "2812,8976",
    b: 2,
    b_global: "50,8",
    d: 6,
    d_global: "152,4",
    ix: "7,9",
    ix_global: "3288228,262",
    iy: "2,6",
    iy_global: "1082201,707",
    iz: 16,
    iz_global: "6659702,81",
    t: "0,3125",
    t_global: "7,9375",
    zy: "3,22",
    zy_global: "52766,34608",
    zz: "7,33",
    zz_global: "120117,1791",
  },
  {
    profile_section_id: 1969,
    name: "TUB60206",
    type: "Tube",
    designation: "TUB60206",
    shape: "RHS",
    country_code: "American",
    ax: "5,08",
    ax_global: "3277,4128",
    b: 2,
    b_global: "50,8",
    d: 6,
    d_global: "152,4",
    ix: "8,7",
    ix_global: "3621213,403",
    iy: "2,8",
    iy_global: "1165447,992",
    iz: "17,8",
    iz_global: "7408919,376",
    t: "0,375",
    t_global: "9,525",
    zy: "3,61",
    zy_global: "59157,30104",
    zz: "8,33",
    zz_global: "136504,2431",
  },
  {
    profile_section_id: 1970,
    name: "TUB60303",
    type: "Tube",
    designation: "TUB60303",
    shape: "RHS",
    country_code: "American",
    ax: "3,14",
    ax_global: "2025,8024",
    b: 3,
    b_global: "76,2",
    d: 6,
    d_global: "152,4",
    ix: "11,9",
    ix_global: "4953153,965",
    iy: "4,8",
    iy_global: "1997910,843",
    iz: "14,3",
    iz_global: "5952109,386",
    t: "0,1875",
    t_global: "4,7625",
    zy: "3,68",
    zy_global: "60304,39552",
    zz: "5,97",
    zz_global: "97830,77208",
  },
  {
    profile_section_id: 1971,
    name: "TUB60304",
    type: "Tube",
    designation: "TUB60304",
    shape: "RHS",
    country_code: "American",
    ax: "4,09",
    ax_global: "2638,7044",
    b: 3,
    b_global: "76,2",
    d: 6,
    d_global: "152,4",
    ix: "15,1",
    ix_global: "6285094,527",
    iy: 6,
    iy_global: "2497388,554",
    iz: "17,9",
    iz_global: "7450542,518",
    t: "0,25",
    t_global: "6,35",
    zy: "4,67",
    zy_global: "76527,58888",
    zz: "7,62",
    zz_global: "124869,4277",
  },
  {
    profile_section_id: 1972,
    name: "TUB60305",
    type: "Tube",
    designation: "TUB60305",
    shape: "RHS",
    country_code: "American",
    ax: "4,98",
    ax_global: "3212,8968",
    b: 3,
    b_global: "76,2",
    d: 6,
    d_global: "152,4",
    ix: "17,9",
    ix_global: "7450542,518",
    iy: 7,
    iy_global: "2913619,979",
    iz: "21,1",
    iz_global: "8782483,08",
    t: "0,3125",
    t_global: "7,9375",
    zy: "5,56",
    zy_global: "91112,07584",
    zz: "9,11",
    zz_global: "149286,153",
  },
  {
    profile_section_id: 1973,
    name: "TUB60306",
    type: "Tube",
    designation: "TUB60306",
    shape: "RHS",
    country_code: "American",
    ax: "5,83",
    ax_global: "3761,2828",
    b: 3,
    b_global: "76,2",
    d: 6,
    d_global: "152,4",
    ix: "20,3",
    ix_global: "8449497,94",
    iy: "7,8",
    iy_global: "3246605,12",
    iz: "23,8",
    iz_global: "9906307,929",
    t: "0,375",
    t_global: "9,525",
    zy: "6,34",
    zy_global: "103893,9858",
    zz: "10,4",
    zz_global: "170425,4656",
  },
  {
    profile_section_id: 1974,
    name: "TUB60403",
    type: "Tube",
    designation: "TUB60403",
    shape: "RHS",
    country_code: "American",
    ax: "3,52",
    ax_global: "2270,9632",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    ix: "19,5",
    ix_global: "8116512,799",
    iy: "9,3",
    iy_global: "3870952,258",
    iz: "17,4",
    iz_global: "7242426,805",
    t: "0,1875",
    t_global: "4,7625",
    zy: "5,34",
    zy_global: "87506,92176",
    zz: "7,06",
    zz_global: "115692,6718",
  },
  {
    profile_section_id: 1975,
    name: "TUB60404",
    type: "Tube",
    designation: "TUB60404",
    shape: "RHS",
    country_code: "American",
    ax: "4,59",
    ax_global: "2961,2844",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    ix: 25,
    ix_global: "10405785,64",
    iy: "11,7",
    iy_global: "4869907,68",
    iz: "22,1",
    iz_global: "9198714,506",
    t: "0,25",
    t_global: "6,35",
    zy: "6,84",
    zy_global: "112087,5178",
    zz: "9,06",
    zz_global: "148466,7998",
  },
  {
    profile_section_id: 1976,
    name: "TUB60405",
    type: "Tube",
    designation: "TUB60405",
    shape: "RHS",
    country_code: "American",
    ax: "5,61",
    ax_global: "3619,3476",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    ix: "30,1",
    ix_global: "12528565,91",
    iy: "13,8",
    iy_global: "5743993,673",
    iz: "26,2",
    iz_global: "10905263,35",
    t: "0,3125",
    t_global: "7,9375",
    zy: "8,21",
    zy_global: "134537,7954",
    zz: "10,9",
    zz_global: "178618,9976",
  },
  {
    profile_section_id: 1977,
    name: "TUB60406",
    type: "Tube",
    designation: "TUB60406",
    shape: "RHS",
    country_code: "American",
    ax: "6,58",
    ax_global: "4245,1528",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    ix: "34,6",
    ix_global: "14401607,33",
    iy: "15,6",
    iy_global: "6493210,239",
    iz: "29,7",
    iz_global: "12362073,34",
    t: "0,375",
    t_global: "9,525",
    zy: "9,44",
    zy_global: "154693,8842",
    zz: "12,5",
    zz_global: "204838,3",
  },
  {
    profile_section_id: 1978,
    name: "TUB60408",
    type: "Tube",
    designation: "TUB60408",
    shape: "RHS",
    country_code: "American",
    ax: "8,36",
    ax_global: "5393,5376",
    b: 4,
    b_global: "101,6",
    d: 6,
    d_global: "152,4",
    ix: "42,1",
    ix_global: "17523343,02",
    iy: "18,4",
    iy_global: "7658658,231",
    iz: "35,3",
    iz_global: "14692969,32",
    t: "0,5",
    t_global: "12,7",
    zy: "11,5",
    zy_global: "188451,236",
    zz: "15,4",
    zz_global: "252360,7856",
  },
  {
    profile_section_id: 1979,
    name: "TUB60503",
    type: "Tube",
    designation: "TUB60503",
    shape: "RHS",
    country_code: "American",
    ax: "3,89",
    ax_global: "2509,6724",
    b: 5,
    b_global: 127,
    d: 6,
    d_global: "152,4",
    ix: "28,1",
    ix_global: "11696103,06",
    iy: "15,6",
    iy_global: "6493210,239",
    iz: "20,6",
    iz_global: "8574367,367",
    t: "0,1875",
    t_global: "4,7625",
    zy: "7,2",
    zy_global: "117986,8608",
    zz: "8,15",
    zz_global: "133554,5716",
  },
  {
    profile_section_id: 1980,
    name: "TUB60504",
    type: "Tube",
    designation: "TUB60504",
    shape: "RHS",
    country_code: "American",
    ax: "5,09",
    ax_global: "3283,8644",
    b: 5,
    b_global: 127,
    d: 6,
    d_global: "152,4",
    ix: "36,3",
    ix_global: "15109200,75",
    iy: "19,8",
    iy_global: "8241382,227",
    iz: "26,2",
    iz_global: "10905263,35",
    t: "0,25",
    t_global: "6,35",
    zy: "9,26",
    zy_global: "151744,2126",
    zz: "10,5",
    zz_global: "172064,172",
  },
  {
    profile_section_id: 1981,
    name: "TUB60505",
    type: "Tube",
    designation: "TUB60505",
    shape: "RHS",
    country_code: "American",
    ax: "6,23",
    ax_global: "4019,3468",
    b: 5,
    b_global: 127,
    d: 6,
    d_global: "152,4",
    ix: "43,9",
    ix_global: "18272559,58",
    iy: "23,5",
    iy_global: "9781438,502",
    iz: "31,2",
    iz_global: "12986420,48",
    t: "0,3125",
    t_global: "7,9375",
    zy: "11,2",
    zy_global: "183535,1168",
    zz: "12,7",
    zz_global: "208115,7128",
  },
  {
    profile_section_id: 1982,
    name: "TUB60506",
    type: "Tube",
    designation: "TUB60506",
    shape: "RHS",
    country_code: "American",
    ax: "7,33",
    ax_global: "4729,0228",
    b: 5,
    b_global: 127,
    d: 6,
    d_global: "152,4",
    ix: "50,9",
    ix_global: "21186179,56",
    iy: "26,8",
    iy_global: "11155002,21",
    iz: "35,6",
    iz_global: "14817838,75",
    t: "0,375",
    t_global: "9,525",
    zy: "12,9",
    zy_global: "211393,1256",
    zz: "14,7",
    zz_global: "240889,8408",
  },
  {
    profile_section_id: 1983,
    name: "TUB60508",
    type: "Tube",
    designation: "TUB60508",
    shape: "RHS",
    country_code: "American",
    ax: "9,36",
    ax_global: "6038,6976",
    b: 5,
    b_global: 127,
    d: 6,
    d_global: "152,4",
    ix: "62,9",
    ix_global: "26180956,67",
    iy: "32,1",
    iy_global: "13361028,76",
    iz: "42,9",
    iz_global: "17856328,16",
    t: "0,5",
    t_global: "12,7",
    zy: 16,
    zy_global: "262193,024",
    zz: "18,1",
    zz_global: "296605,8584",
  },
  {
    profile_section_id: 1984,
    name: "TUB60603",
    type: "Tube",
    designation: "TUB60603",
    shape: "RHS",
    country_code: "American",
    ax: "4,27",
    ax_global: "2754,8332",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    ix: "37,5",
    ix_global: "15608678,46",
    iy: "23,8",
    iy_global: "9906307,929",
    iz: "23,8",
    iz_global: "9906307,929",
    t: "0,1875",
    t_global: "4,7625",
    zy: "9,24",
    zy_global: "151416,4714",
    zz: "9,24",
    zz_global: "151416,4714",
  },
  {
    profile_section_id: 1985,
    name: "TUB60604",
    type: "Tube",
    designation: "TUB60604",
    shape: "RHS",
    country_code: "American",
    ax: "5,59",
    ax_global: "3606,4444",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    ix: "48,5",
    ix_global: "20187224,14",
    iy: "30,3",
    iy_global: "12611812,2",
    iz: "30,3",
    iz_global: "12611812,2",
    t: "0,25",
    t_global: "6,35",
    zy: "11,9",
    zy_global: "195006,0616",
    zz: "11,9",
    zz_global: "195006,0616",
  },
  {
    profile_section_id: 1986,
    name: "TUB60605",
    type: "Tube",
    designation: "TUB60605",
    shape: "RHS",
    country_code: "American",
    ax: "6,86",
    ax_global: "4425,7976",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    ix: "58,9",
    ix_global: "24516030,97",
    iy: "36,3",
    iy_global: "15109200,75",
    iz: "36,3",
    iz_global: "15109200,75",
    t: "0,3125",
    t_global: "7,9375",
    zy: "14,4",
    zy_global: "235973,7216",
    zz: "14,4",
    zz_global: "235973,7216",
  },
  {
    profile_section_id: 1987,
    name: "TUB60606",
    type: "Tube",
    designation: "TUB60606",
    shape: "RHS",
    country_code: "American",
    ax: "8,08",
    ax_global: "5212,8928",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    ix: "68,5",
    ix_global: "28511852,65",
    iy: "41,6",
    iy_global: "17315227,3",
    iz: "41,6",
    iz_global: "17315227,3",
    t: "0,375",
    t_global: "9,525",
    zy: "16,8",
    zy_global: "275302,6752",
    zz: "16,8",
    zz_global: "275302,6752",
  },
  {
    profile_section_id: 1988,
    name: "TUB60608",
    type: "Tube",
    designation: "TUB60608",
    shape: "RHS",
    country_code: "American",
    ax: "10,4",
    ax_global: "6709,664",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    ix: "85,6",
    ix_global: "35629410,03",
    iy: "50,5",
    iy_global: "21019686,99",
    iz: "50,5",
    iz_global: "21019686,99",
    t: "0,5",
    t_global: "12,7",
    zy: "20,9",
    zy_global: "342489,6376",
    zz: "20,9",
    zz_global: "342489,6376",
  },
  {
    profile_section_id: 1989,
    name: "TUB60609",
    type: "Tube",
    designation: "TUB60609",
    shape: "RHS",
    country_code: "American",
    ax: "11,4",
    ax_global: "7354,824",
    b: 6,
    b_global: "152,4",
    d: 6,
    d_global: "152,4",
    ix: "92,9",
    ix_global: "38667899,44",
    iy: "54,1",
    iy_global: "22518120,12",
    iz: 54,
    iz_global: "22476496,98",
    t: "0,5625",
    t_global: "14,2875",
    zy: "22,7",
    zy_global: "371986,3528",
    zz: "22,7",
    zz_global: "371986,3528",
  },
  {
    profile_section_id: 1990,
    name: "TUB70203",
    type: "Tube",
    designation: "TUB70203",
    shape: "RHS",
    country_code: "American",
    ax: "3,14",
    ax_global: "2025,8024",
    b: 2,
    b_global: "50,8",
    d: 7,
    d_global: "177,8",
    ix: "6,74",
    ix_global: "2805399,809",
    iy: "2,21",
    iy_global: "919871,4506",
    iz: "16,7",
    iz_global: "6951064,808",
    t: "0,1875",
    t_global: "4,7625",
    zy: "2,54",
    zy_global: "41623,14256",
    zz: "6,36",
    zz_global: "104221,727",
  },
  {
    profile_section_id: 1991,
    name: "TUB70204",
    type: "Tube",
    designation: "TUB70204",
    shape: "RHS",
    country_code: "American",
    ax: "4,09",
    ax_global: "2638,7044",
    b: 2,
    b_global: "50,8",
    d: 7,
    d_global: "177,8",
    ix: "8,36",
    ix_global: "3479694,718",
    iy: "2,69",
    iy_global: "1119662,535",
    iz: "20,9",
    iz_global: "8699236,795",
    t: "0,25",
    t_global: "6,35",
    zy: "3,19",
    zy_global: "52274,73416",
    zz: "8,1",
    zz_global: "132735,2184",
  },
  {
    profile_section_id: 1992,
    name: "TUB70303",
    type: "Tube",
    designation: "TUB70303",
    shape: "RHS",
    country_code: "American",
    ax: "3,52",
    ax_global: "2270,9632",
    b: 3,
    b_global: "76,2",
    d: 7,
    d_global: "177,8",
    ix: "14,6",
    ix_global: "6076978,814",
    iy: "5,6",
    iy_global: "2330895,983",
    iz: "21,1",
    iz_global: "8782483,08",
    t: "0,1875",
    t_global: "4,7625",
    zy: "4,21",
    zy_global: "68989,53944",
    zz: "7,63",
    zz_global: "125033,2983",
  },
  {
    profile_section_id: 1993,
    name: "TUB70304",
    type: "Tube",
    designation: "TUB70304",
    shape: "RHS",
    country_code: "American",
    ax: "4,59",
    ax_global: "2961,2844",
    b: 3,
    b_global: "76,2",
    d: 7,
    d_global: "177,8",
    ix: "18,5",
    ix_global: "7700281,374",
    iy: 7,
    iy_global: "2913619,979",
    iz: "26,6",
    iz_global: "11071755,92",
    t: "0,25",
    t_global: "6,35",
    zy: "5,36",
    zy_global: "87834,66304",
    zz: "9,79",
    zz_global: "160429,3566",
  },
  {
    profile_section_id: 1994,
    name: "TUB70305",
    type: "Tube",
    designation: "TUB70305",
    shape: "RHS",
    country_code: "American",
    ax: "5,61",
    ax_global: "3619,3476",
    b: 3,
    b_global: "76,2",
    d: 7,
    d_global: "177,8",
    ix: 22,
    ix_global: "9157091,363",
    iy: "8,1",
    iy_global: "3371474,547",
    iz: "31,5",
    iz_global: "13111289,91",
    t: "0,3125",
    t_global: "7,9375",
    zy: "6,4",
    zy_global: "104877,2096",
    zz: "11,8",
    zz_global: "193367,3552",
  },
  {
    profile_section_id: 1995,
    name: "TUB70306",
    type: "Tube",
    designation: "TUB70306",
    shape: "RHS",
    country_code: "American",
    ax: "6,58",
    ax_global: "4245,1528",
    b: 3,
    b_global: "76,2",
    d: 7,
    d_global: "177,8",
    ix: "25,1",
    ix_global: "10447408,78",
    iy: "9,1",
    iy_global: "3787705,973",
    iz: "35,7",
    iz_global: "14859461,89",
    t: "0,375",
    t_global: "9,525",
    zy: "7,32",
    zy_global: "119953,3085",
    zz: "13,5",
    zz_global: "221225,364",
  },
  {
    profile_section_id: 1996,
    name: "TUB70308",
    type: "Tube",
    designation: "TUB70308",
    shape: "RHS",
    country_code: "American",
    ax: "8,36",
    ax_global: "5393,5376",
    b: 3,
    b_global: "76,2",
    d: 7,
    d_global: "177,8",
    ix: "29,8",
    ix_global: "12403696,48",
    iy: "10,5",
    iy_global: "4370429,969",
    iz: "42,3",
    iz_global: "17606589,3",
    t: "0,5",
    t_global: "12,7",
    zy: "8,84",
    zy_global: "144861,6458",
    zz: "16,6",
    zz_global: "272025,2624",
  },
  {
    profile_section_id: 1997,
    name: "TUB70403",
    type: "Tube",
    designation: "TUB70403",
    shape: "RHS",
    country_code: "American",
    ax: "3,89",
    ax_global: "2509,6724",
    b: 4,
    b_global: "101,6",
    d: 7,
    d_global: "177,8",
    ix: "24,2",
    ix_global: "10072800,5",
    iy: "10,7",
    iy_global: "4453676,254",
    iz: "25,4",
    iz_global: "10572278,21",
    t: "0,1875",
    t_global: "4,7625",
    zy: "6,06",
    zy_global: "99305,60784",
    zz: "8,91",
    zz_global: "146008,7402",
  },
  {
    profile_section_id: 1998,
    name: "TUB70404",
    type: "Tube",
    designation: "TUB70404",
    shape: "RHS",
    country_code: "American",
    ax: "5,09",
    ax_global: "3283,8644",
    b: 4,
    b_global: "101,6",
    d: 7,
    d_global: "177,8",
    ix: "31,2",
    ix_global: "12986420,48",
    iy: "13,5",
    iy_global: "5619124,246",
    iz: "32,3",
    iz_global: "13444275,05",
    t: "0,25",
    t_global: "6,35",
    zy: "7,78",
    zy_global: "127491,3579",
    zz: "11,5",
    zz_global: "188451,236",
  },
  {
    profile_section_id: 1999,
    name: "TUB70405",
    type: "Tube",
    designation: "TUB70405",
    shape: "RHS",
    country_code: "American",
    ax: "6,23",
    ax_global: "4019,3468",
    b: 4,
    b_global: "101,6",
    d: 7,
    d_global: "177,8",
    ix: "37,5",
    ix_global: "15608678,46",
    iy: 16,
    iy_global: "6659702,81",
    iz: "38,5",
    iz_global: "16024909,89",
    t: "0,3125",
    t_global: "7,9375",
    zy: "9,36",
    zy_global: "153382,919",
    zz: "13,8",
    zz_global: "226141,4832",
  },
  {
    profile_section_id: 2000,
    name: "TUB70406",
    type: "Tube",
    designation: "TUB70406",
    shape: "RHS",
    country_code: "American",
    ax: "7,33",
    ax_global: "4729,0228",
    b: 4,
    b_global: "101,6",
    d: 7,
    d_global: "177,8",
    ix: "43,3",
    ix_global: "18022820,73",
    iy: "18,1",
    iy_global: "7533788,803",
    iz: 44,
    iz_global: "18314182,73",
    t: "0,375",
    t_global: "9,525",
    zy: "10,8",
    zy_global: "176980,2912",
    zz: 16,
    zz_global: "262193,024",
  },
  {
    profile_section_id: 2001,
    name: "TUB70408",
    type: "Tube",
    designation: "TUB70408",
    shape: "RHS",
    country_code: "American",
    ax: "9,36",
    ax_global: "6038,6976",
    b: 4,
    b_global: "101,6",
    d: 7,
    d_global: "177,8",
    ix: 53,
    ix_global: "22060265,56",
    iy: "21,5",
    iy_global: "8948975,65",
    iz: "52,9",
    iz_global: "22018642,41",
    t: "0,5",
    t_global: "12,7",
    zy: "13,3",
    zy_global: "217947,9512",
    zz: "19,8",
    zz_global: "324463,8672",
  },
  {
    profile_section_id: 2002,
    name: "TUB70503",
    type: "Tube",
    designation: "TUB70503",
    shape: "RHS",
    country_code: "American",
    ax: "4,27",
    ax_global: "2754,8332",
    b: 5,
    b_global: 127,
    d: 7,
    d_global: "177,8",
    ix: "35,3",
    ix_global: "14692969,32",
    iy: "17,7",
    iy_global: "7367296,233",
    iz: "29,8",
    iz_global: "12403696,48",
    t: "0,1875",
    t_global: "4,7625",
    zy: "8,1",
    zy_global: "132735,2184",
    zz: "10,2",
    zz_global: "167148,0528",
  },
  {
    profile_section_id: 2003,
    name: "TUB70504",
    type: "Tube",
    designation: "TUB70504",
    shape: "RHS",
    country_code: "American",
    ax: "5,59",
    ax_global: "3606,4444",
    b: 5,
    b_global: 127,
    d: 7,
    d_global: "177,8",
    ix: "45,6",
    ix_global: "18980153,01",
    iy: "22,6",
    iy_global: "9406830,219",
    iz: 38,
    iz_global: "15816794,17",
    t: "0,25",
    t_global: "6,35",
    zy: "10,4",
    zy_global: "170425,4656",
    zz: "13,2",
    zz_global: "216309,2448",
  },
  {
    profile_section_id: 2004,
    name: "TUB70505",
    type: "Tube",
    designation: "TUB70505",
    shape: "RHS",
    country_code: "American",
    ax: "6,86",
    ax_global: "4425,7976",
    b: 5,
    b_global: 127,
    d: 7,
    d_global: "177,8",
    ix: "55,3",
    ix_global: "23017597,84",
    iy: "26,9",
    iy_global: "11196625,35",
    iz: "45,5",
    iz_global: "18938529,86",
    t: "0,3125",
    t_global: "7,9375",
    zy: "12,6",
    zy_global: "206477,0064",
    zz: "15,9",
    zz_global: "260554,3176",
  },
  {
    profile_section_id: 2005,
    name: "TUB70506",
    type: "Tube",
    designation: "TUB70506",
    shape: "RHS",
    country_code: "American",
    ax: "8,08",
    ax_global: "5212,8928",
    b: 5,
    b_global: 127,
    d: 7,
    d_global: "177,8",
    ix: "64,2",
    ix_global: "26722057,52",
    iy: "30,8",
    iy_global: "12819927,91",
    iz: "52,2",
    iz_global: "21727280,42",
    t: "0,375",
    t_global: "9,525",
    zy: "14,6",
    zy_global: "239251,1344",
    zz: "18,5",
    zz_global: "303160,684",
  },
  {
    profile_section_id: 2006,
    name: "TUB70508",
    type: "Tube",
    designation: "TUB70508",
    shape: "RHS",
    country_code: "American",
    ax: "10,4",
    ax_global: "6709,664",
    b: 5,
    b_global: 127,
    d: 7,
    d_global: "177,8",
    ix: "79,9",
    ix_global: "33256890,91",
    iy: "37,2",
    iy_global: "15483809,03",
    iz: "63,5",
    iz_global: "26430695,53",
    t: "0,5",
    t_global: "12,7",
    zy: "18,2",
    zy_global: "298244,5648",
    zz: "23,1",
    zz_global: "378541,1784",
  },
  {
    profile_section_id: 2007,
    name: "TUB70703",
    type: "Tube",
    designation: "TUB70703",
    shape: "RHS",
    country_code: "American",
    ax: "5,02",
    ax_global: "3238,7032",
    b: 7,
    b_global: "177,8",
    d: 7,
    d_global: "177,8",
    ix: "60,2",
    ix_global: "25057131,82",
    iy: "38,5",
    iy_global: "16024909,89",
    iz: "38,5",
    iz_global: "16024909,89",
    t: "0,1875",
    t_global: "4,7625",
    zy: "12,7",
    zy_global: "208115,7128",
    zz: "12,7",
    zz_global: "208115,7128",
  },
  {
    profile_section_id: 2008,
    name: "TUB70704",
    type: "Tube",
    designation: "TUB70704",
    shape: "RHS",
    country_code: "American",
    ax: "6,59",
    ax_global: "4251,6044",
    b: 7,
    b_global: "177,8",
    d: 7,
    d_global: "177,8",
    ix: "78,3",
    ix_global: "32590920,62",
    iy: "49,4",
    iy_global: "20561832,42",
    iz: "49,4",
    iz_global: "20561832,42",
    t: "0,25",
    t_global: "6,35",
    zy: "16,5",
    zy_global: "270386,556",
    zz: "16,5",
    zz_global: "270386,556",
  },
  {
    profile_section_id: 2009,
    name: "TUB70705",
    type: "Tube",
    designation: "TUB70705",
    shape: "RHS",
    country_code: "American",
    ax: "8,11",
    ax_global: "5232,2476",
    b: 7,
    b_global: "177,8",
    d: 7,
    d_global: "177,8",
    ix: "95,6",
    ix_global: "39791724,29",
    iy: "59,5",
    iy_global: "24765769,82",
    iz: "59,5",
    iz_global: "24765769,82",
    t: "0,3125",
    t_global: "7,9375",
    zy: "20,1",
    zy_global: "329379,9864",
    zz: "20,1",
    zz_global: "329379,9864",
  },
  {
    profile_section_id: 2010,
    name: "TUB70706",
    type: "Tube",
    designation: "TUB70706",
    shape: "RHS",
    country_code: "American",
    ax: "9,58",
    ax_global: "6180,6328",
    b: 7,
    b_global: "177,8",
    d: 7,
    d_global: "177,8",
    ix: 112,
    ix_global: "46617919,67",
    iy: "68,7",
    iy_global: "28595098,94",
    iz: "68,7",
    iz_global: "28595098,94",
    t: "0,375",
    t_global: "9,525",
    zy: "23,5",
    zy_global: "385096,004",
    zz: "23,5",
    zz_global: "385096,004",
  },
  {
    profile_section_id: 2011,
    name: "TUB70708",
    type: "Tube",
    designation: "TUB70708",
    shape: "RHS",
    country_code: "American",
    ax: "12,4",
    ax_global: "7999,984",
    b: 7,
    b_global: "177,8",
    d: 7,
    d_global: "177,8",
    ix: 141,
    ix_global: "58688631,01",
    iy: "84,6",
    iy_global: "35213178,61",
    iz: "84,6",
    iz_global: "35213178,61",
    t: "0,5",
    t_global: "12,7",
    zy: "29,6",
    zy_global: "485057,0944",
    zz: "29,6",
    zz_global: "485057,0944",
  },
  {
    profile_section_id: 2012,
    name: "TUB70709",
    type: "Tube",
    designation: "TUB70709",
    shape: "RHS",
    country_code: "American",
    ax: "13,7",
    ax_global: "8838,692",
    b: 7,
    b_global: "177,8",
    d: 7,
    d_global: "177,8",
    ix: 154,
    ix_global: "64099639,54",
    iy: "91,4",
    iy_global: "38043552,3",
    iz: "91,4",
    iz_global: "38043552,3",
    t: "0,5625",
    t_global: "14,2875",
    zy: "32,3",
    zy_global: "529302,1672",
    zz: "32,3",
    zz_global: "529302,1672",
  },
  {
    profile_section_id: 2013,
    name: "TUB80203",
    type: "Tube",
    designation: "TUB80203",
    shape: "RHS",
    country_code: "American",
    ax: "3,52",
    ax_global: "2270,9632",
    b: 2,
    b_global: "50,8",
    d: 8,
    d_global: "203,2",
    ix: "7,9",
    ix_global: "3288228,262",
    iy: "2,5",
    iy_global: "1040578,564",
    iz: "23,9",
    iz_global: "9947931,072",
    t: "0,1875",
    t_global: "4,7625",
    zy: "2,88",
    zy_global: "47194,74432",
    zz: "8,02",
    zz_global: "131424,2533",
  },
  {
    profile_section_id: 2014,
    name: "TUB80204",
    type: "Tube",
    designation: "TUB80204",
    shape: "RHS",
    country_code: "American",
    ax: "4,59",
    ax_global: "2961,2844",
    b: 2,
    b_global: "50,8",
    d: 8,
    d_global: "203,2",
    ix: "9,8",
    ix_global: "4079067,971",
    iy: "3,1",
    iy_global: "1290317,419",
    iz: "30,1",
    iz_global: "12528565,91",
    t: "0,25",
    t_global: "6,35",
    zy: "3,63",
    zy_global: "59485,04232",
    zz: "10,3",
    zz_global: "168786,7592",
  },
  {
    profile_section_id: 2015,
    name: "TUB80205",
    type: "Tube",
    designation: "TUB80205",
    shape: "RHS",
    country_code: "American",
    ax: "5,61",
    ax_global: "3619,3476",
    b: 2,
    b_global: "50,8",
    d: 8,
    d_global: "203,2",
    ix: "11,4",
    ix_global: "4745038,252",
    iy: "3,5",
    iy_global: "1456809,99",
    iz: "35,5",
    iz_global: "14776215,61",
    t: "0,3125",
    t_global: "7,9375",
    zy: "4,28",
    zy_global: "70136,63392",
    zz: "12,3",
    zz_global: "201560,8872",
  },
  {
    profile_section_id: 2016,
    name: "TUB80206",
    type: "Tube",
    designation: "TUB80206",
    shape: "RHS",
    country_code: "American",
    ax: "6,58",
    ax_global: "4245,1528",
    b: 2,
    b_global: "50,8",
    d: 8,
    d_global: "203,2",
    ix: "12,6",
    ix_global: "5244515,963",
    iy: "3,9",
    iy_global: "1623302,56",
    iz: "40,1",
    iz_global: "16690880,17",
    t: "0,375",
    t_global: "9,525",
    zy: "4,83",
    zy_global: "79149,51912",
    zz: "14,2",
    zz_global: "232696,3088",
  },
  {
    profile_section_id: 2017,
    name: "TUB80303",
    type: "Tube",
    designation: "TUB80303",
    shape: "RHS",
    country_code: "American",
    ax: "3,89",
    ax_global: "2509,6724",
    b: 3,
    b_global: "76,2",
    d: 8,
    d_global: "203,2",
    ix: "17,3",
    ix_global: "7200803,663",
    iy: "6,3",
    iy_global: "2622257,981",
    iz: "29,6",
    iz_global: "12320450,2",
    t: "0,1875",
    t_global: "4,7625",
    zy: "4,73",
    zy_global: "77510,81272",
    zz: "9,49",
    zz_global: "155513,2374",
  },
  {
    profile_section_id: 2018,
    name: "TUB80304",
    type: "Tube",
    designation: "TUB80304",
    shape: "RHS",
    country_code: "American",
    ax: "5,09",
    ax_global: "3283,8644",
    b: 3,
    b_global: "76,2",
    d: 8,
    d_global: "203,2",
    ix: "22,1",
    ix_global: "9198714,506",
    iy: "7,9",
    iy_global: "3288228,262",
    iz: "37,6",
    iz_global: "15650301,6",
    t: "0,25",
    t_global: "6,35",
    zy: "6,05",
    zy_global: "99141,7372",
    zz: "12,2",
    zz_global: "199922,1808",
  },
  {
    profile_section_id: 2019,
    name: "TUB80305",
    type: "Tube",
    designation: "TUB80305",
    shape: "RHS",
    country_code: "American",
    ax: "6,23",
    ax_global: "4019,3468",
    b: 3,
    b_global: "76,2",
    d: 8,
    d_global: "203,2",
    ix: "26,3",
    ix_global: "10946886,49",
    iy: "9,3",
    iy_global: "3870952,258",
    iz: "44,7",
    iz_global: "18605544,72",
    t: "0,3125",
    t_global: "7,9375",
    zy: "7,24",
    zy_global: "118642,3434",
    zz: "14,7",
    zz_global: "240889,8408",
  },
  {
    profile_section_id: 2020,
    name: "TUB80306",
    type: "Tube",
    designation: "TUB80306",
    shape: "RHS",
    country_code: "American",
    ax: "7,33",
    ax_global: "4729,0228",
    b: 3,
    b_global: "76,2",
    d: 8,
    d_global: "203,2",
    ix: "29,9",
    ix_global: "12445319,63",
    iy: "10,4",
    iy_global: "4328806,826",
    iz: 51,
    iz_global: "21227802,71",
    t: "0,375",
    t_global: "9,525",
    zy: "8,31",
    zy_global: "136176,5018",
    zz: 17,
    zz_global: "278580,088",
  },
  {
    profile_section_id: 2021,
    name: "TUB80308",
    type: "Tube",
    designation: "TUB80308",
    shape: "RHS",
    country_code: "American",
    ax: "9,36",
    ax_global: "6038,6976",
    b: 3,
    b_global: "76,2",
    d: 8,
    d_global: "203,2",
    ix: "35,7",
    ix_global: "14859461,89",
    iy: "12,1",
    iy_global: "5036400,25",
    iz: 61,
    iz_global: "25390116,96",
    t: "0,5",
    t_global: "12,7",
    zy: "10,1",
    zy_global: "165509,3464",
    zz: 21,
    zz_global: "344128,344",
  },
  {
    profile_section_id: 2022,
    name: "TUB80403",
    type: "Tube",
    designation: "TUB80403",
    shape: "RHS",
    country_code: "American",
    ax: "4,27",
    ax_global: "2754,8332",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    ix: "29,1",
    ix_global: "12112334,48",
    iy: 12,
    iy_global: "4994777,107",
    iz: "35,3",
    iz_global: "14692969,32",
    t: "0,1875",
    t_global: "4,7625",
    zy: "6,77",
    zy_global: "110940,4233",
    zz: 11,
    zz_global: "180257,704",
  },
  {
    profile_section_id: 2023,
    name: "TUB80404",
    type: "Tube",
    designation: "TUB80404",
    shape: "RHS",
    country_code: "American",
    ax: "5,59",
    ax_global: "3606,4444",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    ix: "37,5",
    ix_global: "15608678,46",
    iy: "15,3",
    iy_global: "6368340,812",
    iz: "45,1",
    iz_global: "18772037,29",
    t: "0,25",
    t_global: "6,35",
    zy: "8,72",
    zy_global: "142895,1981",
    zz: "14,1",
    zz_global: "231057,6024",
  },
  {
    profile_section_id: 2024,
    name: "TUB80405",
    type: "Tube",
    designation: "TUB80405",
    shape: "RHS",
    country_code: "American",
    ax: "6,86",
    ax_global: "4425,7976",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    ix: "45,2",
    ix_global: "18813660,44",
    iy: "18,1",
    iy_global: "7533788,803",
    iz: "53,9",
    iz_global: "22434873,84",
    t: "0,3125",
    t_global: "7,9375",
    zy: "10,5",
    zy_global: "172064,172",
    zz: "17,1",
    zz_global: "280218,7944",
  },
  {
    profile_section_id: 2025,
    name: "TUB80406",
    type: "Tube",
    designation: "TUB80406",
    shape: "RHS",
    country_code: "American",
    ax: "8,08",
    ax_global: "5212,8928",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    ix: "52,2",
    ix_global: "21727280,42",
    iy: "20,6",
    iy_global: "8574367,367",
    iz: "61,9",
    iz_global: "25764725,24",
    t: "0,375",
    t_global: "9,525",
    zy: "12,2",
    zy_global: "199922,1808",
    zz: "19,9",
    zz_global: "326102,5736",
  },
  {
    profile_section_id: 2026,
    name: "TUB80408",
    type: "Tube",
    designation: "TUB80408",
    shape: "RHS",
    country_code: "American",
    ax: "10,4",
    ax_global: "6709,664",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    ix: "64,1",
    ix_global: "26680434,38",
    iy: "24,6",
    iy_global: "10239293,07",
    iz: "75,1",
    iz_global: "31258980,06",
    t: "0,5",
    t_global: "12,7",
    zy: 15,
    zy_global: "245805,96",
    zz: "24,7",
    zz_global: "404760,4808",
  },
  {
    profile_section_id: 2027,
    name: "TUB80409",
    type: "Tube",
    designation: "TUB80409",
    shape: "RHS",
    country_code: "American",
    ax: "11,4",
    ax_global: "7354,824",
    b: 4,
    b_global: "101,6",
    d: 8,
    d_global: "203,2",
    ix: 69,
    ix_global: "28719968,37",
    iy: "26,2",
    iy_global: "10905263,35",
    iz: "80,5",
    iz_global: "33506629,76",
    t: "0,5625",
    t_global: "14,2875",
    zy: "16,2",
    zy_global: "265470,4368",
    zz: "26,9",
    zz_global: "440812,0216",
  },
  {
    profile_section_id: 2028,
    name: "TUB80603",
    type: "Tube",
    designation: "TUB80603",
    shape: "RHS",
    country_code: "American",
    ax: "5,02",
    ax_global: "3238,7032",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    ix: "57,6",
    ix_global: "23974930,11",
    iy: "30,1",
    iy_global: "12528565,91",
    iz: "46,8",
    iz_global: "19479630,72",
    t: "0,1875",
    t_global: "4,7625",
    zy: "11,4",
    zy_global: "186812,5296",
    zz: "13,9",
    zz_global: "227780,1896",
  },
  {
    profile_section_id: 2029,
    name: "TUB80604",
    type: "Tube",
    designation: "TUB80604",
    shape: "RHS",
    country_code: "American",
    ax: "6,59",
    ax_global: "4251,6044",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    ix: "74,9",
    ix_global: "31175733,78",
    iy: "38,6",
    iy_global: "16066533,03",
    iz: "60,1",
    iz_global: "25015508,68",
    t: "0,25",
    t_global: "6,35",
    zy: "14,8",
    zy_global: "242528,5472",
    zz: 18,
    zz_global: "294967,152",
  },
  {
    profile_section_id: 2030,
    name: "TUB80605",
    type: "Tube",
    designation: "TUB80605",
    shape: "RHS",
    country_code: "American",
    ax: "8,11",
    ax_global: "5232,2476",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    ix: "91,3",
    ix_global: "38001929,16",
    iy: "46,4",
    iy_global: "19313138,15",
    iz: "72,4",
    iz_global: "30135155,21",
    t: "0,3125",
    t_global: "7,9375",
    zy: 18,
    zy_global: "294967,152",
    zz: "21,9",
    zz_global: "358876,7016",
  },
  {
    profile_section_id: 2031,
    name: "TUB80606",
    type: "Tube",
    designation: "TUB80606",
    shape: "RHS",
    country_code: "American",
    ax: "9,58",
    ax_global: "6180,6328",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    ix: 107,
    ix_global: "44536762,54",
    iy: "53,5",
    iy_global: "22268381,27",
    iz: "83,7",
    iz_global: "34838570,32",
    t: "0,375",
    t_global: "9,525",
    zy: 21,
    zy_global: "344128,344",
    zz: "25,6",
    zz_global: "419508,8384",
  },
  {
    profile_section_id: 2032,
    name: "TUB80608",
    type: "Tube",
    designation: "TUB80608",
    shape: "RHS",
    country_code: "American",
    ax: "12,4",
    ax_global: "7999,984",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    ix: 135,
    ix_global: "56191242,46",
    iy: "65,7",
    iy_global: "27346404,66",
    iz: 103,
    iz_global: "42871836,84",
    t: "0,5",
    t_global: "12,7",
    zy: "26,4",
    zy_global: "432618,4896",
    zz: "32,2",
    zz_global: "527663,4608",
  },
  {
    profile_section_id: 2033,
    name: "TUB80609",
    type: "Tube",
    designation: "TUB80609",
    shape: "RHS",
    country_code: "American",
    ax: "13,7",
    ax_global: "8838,692",
    b: 6,
    b_global: "152,4",
    d: 8,
    d_global: "203,2",
    ix: 147,
    ix_global: "61186019,56",
    iy: "70,8",
    iy_global: "29469184,93",
    iz: 112,
    iz_global: "46617919,67",
    t: "0,5625",
    t_global: "14,2875",
    zy: "28,8",
    zy_global: "471947,4432",
    zz: "35,2",
    zz_global: "576824,6528",
  },
  {
    profile_section_id: 2034,
    name: "TUB80803",
    type: "Tube",
    designation: "TUB80803",
    shape: "RHS",
    country_code: "American",
    ax: "5,77",
    ax_global: "3722,5732",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    ix: "90,6",
    ix_global: "37710567,16",
    iy: "58,2",
    iy_global: "24224668,97",
    iz: "58,2",
    iz_global: "24224668,97",
    t: "0,1875",
    t_global: "4,7625",
    zy: "16,8",
    zy_global: "275302,6752",
    zz: "16,8",
    zz_global: "275302,6752",
  },
  {
    profile_section_id: 2035,
    name: "TUB80804",
    type: "Tube",
    designation: "TUB80804",
    shape: "RHS",
    country_code: "American",
    ax: "7,59",
    ax_global: "4896,7644",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    ix: 118,
    ix_global: "49115308,22",
    iy: "75,1",
    iy_global: "31258980,06",
    iz: "75,1",
    iz_global: "31258980,06",
    t: "0,25",
    t_global: "6,35",
    zy: "21,9",
    zy_global: "358876,7016",
    zz: "21,9",
    zz_global: "358876,7016",
  },
  {
    profile_section_id: 2036,
    name: "TUB80805",
    type: "Tube",
    designation: "TUB80805",
    shape: "RHS",
    country_code: "American",
    ax: "9,36",
    ax_global: "6038,6976",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    ix: 145,
    ix_global: "60353556,71",
    iy: "90,9",
    iy_global: "37835436,59",
    iz: "90,9",
    iz_global: "37835436,59",
    t: "0,3125",
    t_global: "7,9375",
    zy: "26,7",
    zy_global: "437534,6088",
    zz: "26,7",
    zz_global: "437534,6088",
  },
  {
    profile_section_id: 2037,
    name: "TUB80806",
    type: "Tube",
    designation: "TUB80806",
    shape: "RHS",
    country_code: "American",
    ax: "11,1",
    ax_global: "7161,276",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    ix: 170,
    ix_global: "70759342,35",
    iy: 106,
    iy_global: "44120531,11",
    iz: 106,
    iz_global: "44120531,11",
    t: "0,375",
    t_global: "9,525",
    zy: "31,3",
    zy_global: "512915,1032",
    zz: "31,3",
    zz_global: "512915,1032",
  },
  {
    profile_section_id: 2038,
    name: "TUB80808",
    type: "Tube",
    designation: "TUB80808",
    shape: "RHS",
    country_code: "American",
    ax: "14,4",
    ax_global: "9290,304",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    ix: 217,
    ix_global: "90322219,36",
    iy: 131,
    iy_global: "54526316,75",
    iz: 131,
    iz_global: "54526316,75",
    t: "0,5",
    t_global: "12,7",
    zy: "39,7",
    zy_global: "650566,4408",
    zz: "39,7",
    zz_global: "650566,4408",
  },
  {
    profile_section_id: 2039,
    name: "TUB80809",
    type: "Tube",
    designation: "TUB80809",
    shape: "RHS",
    country_code: "American",
    ax: "15,9",
    ax_global: "10258,044",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    ix: 238,
    ix_global: "99063079,29",
    iy: 143,
    iy_global: "59521093,86",
    iz: 143,
    iz_global: "59521093,86",
    t: "0,5625",
    t_global: "14,2875",
    zy: "43,6",
    zy_global: "714475,9904",
    zz: "43,6",
    zz_global: "714475,9904",
  },
  {
    profile_section_id: 2040,
    name: "TUB808010",
    type: "Tube",
    designation: "TUB808010",
    shape: "RHS",
    country_code: "American",
    ax: "17,4",
    ax_global: "11225,784",
    b: 8,
    b_global: "203,2",
    d: 8,
    d_global: "203,2",
    ix: 258,
    ix_global: "107387707,8",
    iy: 153,
    iy_global: "63683408,12",
    iz: 153,
    iz_global: "63683408,12",
    t: "0,625",
    t_global: "15,875",
    zy: "47,2",
    zy_global: "773469,4208",
    zz: "47,2",
    zz_global: "773469,4208",
  },
  {
    profile_section_id: 2041,
    name: "TUB90303",
    type: "Tube",
    designation: "TUB90303",
    shape: "RHS",
    country_code: "American",
    ax: "4,27",
    ax_global: "2754,8332",
    b: 3,
    b_global: "76,2",
    d: 9,
    d_global: "228,6",
    ix: "20,1",
    ix_global: "8366251,655",
    iy: "7,06",
    iy_global: "2938593,865",
    iz: "40,1",
    iz_global: "16690880,17",
    t: "0,1875",
    t_global: "4,7625",
    zy: "5,26",
    zy_global: "86195,95664",
    zz: "11,5",
    zz_global: "188451,236",
  },
  {
    profile_section_id: 2042,
    name: "TUB90304",
    type: "Tube",
    designation: "TUB90304",
    shape: "RHS",
    country_code: "American",
    ax: "5,59",
    ax_global: "3606,4444",
    b: 3,
    b_global: "76,2",
    d: 9,
    d_global: "228,6",
    ix: "25,6",
    ix_global: "10655524,5",
    iy: "8,84",
    iy_global: "3679485,802",
    iz: "51,1",
    iz_global: "21269425,85",
    t: "0,25",
    t_global: "6,35",
    zy: "6,73",
    zy_global: "110284,9407",
    zz: "14,9",
    zz_global: "244167,2536",
  },
  {
    profile_section_id: 2043,
    name: "TUB90305",
    type: "Tube",
    designation: "TUB90305",
    shape: "RHS",
    country_code: "American",
    ax: "6,86",
    ax_global: "4425,7976",
    b: 3,
    b_global: "76,2",
    d: 9,
    d_global: "228,6",
    ix: "30,5",
    ix_global: "12695058,48",
    iy: "10,4",
    iy_global: "4328806,826",
    iz: 61,
    iz_global: "25390116,96",
    t: "0,3125",
    t_global: "7,9375",
    zy: "8,08",
    zy_global: "132407,4771",
    zz: 18,
    zz_global: "294967,152",
  },
  {
    profile_section_id: 2044,
    name: "TUB90306",
    type: "Tube",
    designation: "TUB90306",
    shape: "RHS",
    country_code: "American",
    ax: "8,08",
    ax_global: "5212,8928",
    b: 3,
    b_global: "76,2",
    d: 9,
    d_global: "228,6",
    ix: "34,9",
    ix_global: "14526476,75",
    iy: "11,7",
    iy_global: "4869907,68",
    iz: "69,9",
    iz_global: "29094576,65",
    t: "0,375",
    t_global: "9,525",
    zy: "9,29",
    zy_global: "152235,8246",
    zz: "20,9",
    zz_global: "342489,6376",
  },
  {
    profile_section_id: 2045,
    name: "TUB90308",
    type: "Tube",
    designation: "TUB90308",
    shape: "RHS",
    country_code: "American",
    ax: "10,4",
    ax_global: "6709,664",
    b: 3,
    b_global: "76,2",
    d: 9,
    d_global: "228,6",
    ix: "41,6",
    ix_global: "17315227,3",
    iy: "13,7",
    iy_global: "5702370,531",
    iz: "84,4",
    iz_global: "35129932,32",
    t: "0,5",
    t_global: "12,7",
    zy: "11,3",
    zy_global: "185173,8232",
    zz: "25,9",
    zz_global: "424424,9576",
  },
  {
    profile_section_id: 2046,
    name: "TUB90503",
    type: "Tube",
    designation: "TUB90503",
    shape: "RHS",
    country_code: "American",
    ax: "5,02",
    ax_global: "3238,7032",
    b: 5,
    b_global: 127,
    d: 9,
    d_global: "228,6",
    ix: "50,2",
    ix_global: "20894817,57",
    iy: "22,1",
    iy_global: "9198714,506",
    iz: "54,7",
    iz_global: "22767858,98",
    t: "0,1875",
    t_global: "4,7625",
    zy: "9,9",
    zy_global: "162231,9336",
    zz: "14,8",
    zz_global: "242528,5472",
  },
  {
    profile_section_id: 2047,
    name: "TUB90504",
    type: "Tube",
    designation: "TUB90504",
    shape: "RHS",
    country_code: "American",
    ax: "6,59",
    ax_global: "4251,6044",
    b: 5,
    b_global: 127,
    d: 9,
    d_global: "228,6",
    ix: "65,2",
    ix_global: "27138288,95",
    iy: "28,2",
    iy_global: "11737726,2",
    iz: "70,3",
    iz_global: "29261069,22",
    t: "0,25",
    t_global: "6,35",
    zy: "12,8",
    zy_global: "209754,4192",
    zz: "19,3",
    zz_global: "316270,3352",
  },
  {
    profile_section_id: 2048,
    name: "TUB90505",
    type: "Tube",
    designation: "TUB90505",
    shape: "RHS",
    country_code: "American",
    ax: "8,11",
    ax_global: "5232,2476",
    b: 5,
    b_global: 127,
    d: 9,
    d_global: "228,6",
    ix: "79,2",
    ix_global: "32965528,91",
    iy: "33,8",
    iy_global: "14068622,19",
    iz: "84,6",
    iz_global: "35213178,61",
    t: "0,3125",
    t_global: "7,9375",
    zy: "15,6",
    zy_global: "255638,1984",
    zz: "23,4",
    zz_global: "383457,2976",
  },
  {
    profile_section_id: 2049,
    name: "TUB90506",
    type: "Tube",
    designation: "TUB90506",
    shape: "RHS",
    country_code: "American",
    ax: "9,58",
    ax_global: "6180,6328",
    b: 5,
    b_global: 127,
    d: 9,
    d_global: "228,6",
    ix: "92,2",
    ix_global: "38376537,44",
    iy: "38,8",
    iy_global: "16149779,31",
    iz: "97,8",
    iz_global: "40707433,42",
    t: "0,375",
    t_global: "9,525",
    zy: "18,1",
    zy_global: "296605,8584",
    zz: "27,3",
    zz_global: "447366,8472",
  },
  {
    profile_section_id: 2050,
    name: "TUB90508",
    type: "Tube",
    designation: "TUB90508",
    shape: "RHS",
    country_code: "American",
    ax: "12,4",
    ax_global: "7999,984",
    b: 5,
    b_global: 127,
    d: 9,
    d_global: "228,6",
    ix: 115,
    ix_global: "47866613,94",
    iy: "47,4",
    iy_global: "19729369,57",
    iz: 121,
    iz_global: "50364002,5",
    t: "0,5",
    t_global: "12,7",
    zy: "22,7",
    zy_global: "371986,3528",
    zz: "34,4",
    zz_global: "563715,0016",
  },
  {
    profile_section_id: 2051,
    name: "TUB90509",
    type: "Tube",
    designation: "TUB90509",
    shape: "RHS",
    country_code: "American",
    ax: "13,7",
    ax_global: "8838,692",
    b: 5,
    b_global: 127,
    d: 9,
    d_global: "228,6",
    ix: 126,
    ix_global: "52445159,63",
    iy: "50,9",
    iy_global: "21186179,56",
    iz: 130,
    iz_global: "54110085,33",
    t: "0,5625",
    t_global: "14,2875",
    zy: "24,7",
    zy_global: "404760,4808",
    zz: "37,6",
    zz_global: "616153,6064",
  },
  {
    profile_section_id: 2052,
    name: "TUB90603",
    type: "Tube",
    designation: "TUB90603",
    shape: "RHS",
    country_code: "American",
    ax: "5,39",
    ax_global: "3477,4124",
    b: 6,
    b_global: "152,4",
    d: 9,
    d_global: "228,6",
    ix: "68,2",
    ix_global: "28386983,23",
    iy: "33,3",
    iy_global: "13860506,47",
    iz: "61,9",
    iz_global: "25764725,24",
    t: "0,1875",
    t_global: "4,7625",
    zy: "12,5",
    zy_global: "204838,3",
    zz: "16,5",
    zz_global: "270386,556",
  },
  {
    profile_section_id: 2053,
    name: "TUB90604",
    type: "Tube",
    designation: "TUB90604",
    shape: "RHS",
    country_code: "American",
    ax: "7,09",
    ax_global: "4574,1844",
    b: 6,
    b_global: "152,4",
    d: 9,
    d_global: "228,6",
    ix: "88,8",
    ix_global: "36961350,59",
    iy: "42,7",
    iy_global: "17773081,87",
    iz: "79,8",
    iz_global: "33215267,76",
    t: "0,25",
    t_global: "6,35",
    zy: "16,2",
    zy_global: "265470,4368",
    zz: "21,4",
    zz_global: "350683,1696",
  },
  {
    profile_section_id: 2054,
    name: "TUB90605",
    type: "Tube",
    designation: "TUB90605",
    shape: "RHS",
    country_code: "American",
    ax: "8,73",
    ax_global: "5632,2468",
    b: 6,
    b_global: "152,4",
    d: 9,
    d_global: "228,6",
    ix: 108,
    ix_global: "44952993,96",
    iy: "51,4",
    iy_global: "21394295,28",
    iz: "96,4",
    iz_global: "40124709,43",
    t: "0,3125",
    t_global: "7,9375",
    zy: "19,8",
    zy_global: "324463,8672",
    zz: "26,1",
    zz_global: "427702,3704",
  },
  {
    profile_section_id: 2055,
    name: "TUB90606",
    type: "Tube",
    designation: "TUB90606",
    shape: "RHS",
    country_code: "American",
    ax: "10,3",
    ax_global: "6645,148",
    b: 6,
    b_global: "152,4",
    d: 9,
    d_global: "228,6",
    ix: 127,
    ix_global: "52861391,05",
    iy: "59,4",
    iy_global: "24724146,68",
    iz: 112,
    iz_global: "46617919,67",
    t: "0,375",
    t_global: "9,525",
    zy: "23,1",
    zy_global: "378541,1784",
    zz: "30,6",
    zz_global: "501444,1584",
  },
  {
    profile_section_id: 2056,
    name: "TUB90608",
    type: "Tube",
    designation: "TUB90608",
    shape: "RHS",
    country_code: "American",
    ax: "13,4",
    ax_global: "8645,144",
    b: 6,
    b_global: "152,4",
    d: 9,
    d_global: "228,6",
    ix: 160,
    ix_global: "66597028,1",
    iy: "73,2",
    iy_global: "30468140,35",
    iz: 139,
    iz_global: "57856168,16",
    t: "0,5",
    t_global: "12,7",
    zy: "29,1",
    zy_global: "476863,5624",
    zz: "38,7",
    zz_global: "634179,3768",
  },
  {
    profile_section_id: 2057,
    name: "TUB90609",
    type: "Tube",
    designation: "TUB90609",
    shape: "RHS",
    country_code: "American",
    ax: "14,8",
    ax_global: "9548,368",
    b: 6,
    b_global: "152,4",
    d: 9,
    d_global: "228,6",
    ix: 175,
    ix_global: "72840499,48",
    iy: "79,2",
    iy_global: "32965528,91",
    iz: 150,
    iz_global: "62434713,84",
    t: "0,5625",
    t_global: "14,2875",
    zy: "31,9",
    zy_global: "522747,3416",
    zz: "42,3",
    zz_global: "693172,8072",
  },
  {
    profile_section_id: 2058,
    name: "TUB906010",
    type: "Tube",
    designation: "TUB906010",
    shape: "RHS",
    country_code: "American",
    ax: "16,2",
    ax_global: "10451,592",
    b: 6,
    b_global: "152,4",
    d: 9,
    d_global: "228,6",
    ix: 189,
    ix_global: "78667739,44",
    iy: "84,5",
    iy_global: "35171555,46",
    iz: 161,
    iz_global: "67013259,52",
    t: "0,625",
    t_global: "15,875",
    zy: "34,4",
    zy_global: "563715,0016",
    zz: "45,8",
    zz_global: "750527,5312",
  },
  {
    profile_section_id: 2059,
    name: "TUB90703",
    type: "Tube",
    designation: "TUB90703",
    shape: "RHS",
    country_code: "American",
    ax: "5,77",
    ax_global: "3722,5732",
    b: 7,
    b_global: "177,8",
    d: 9,
    d_global: "228,6",
    ix: "87,7",
    ix_global: "36503496,03",
    iy: "47,2",
    iy_global: "19646123,29",
    iz: "69,2",
    iz_global: "28803214,65",
    t: "0,1875",
    t_global: "4,7625",
    zy: "15,3",
    zy_global: "250722,0792",
    zz: "18,1",
    zz_global: "296605,8584",
  },
  {
    profile_section_id: 2060,
    name: "TUB90704",
    type: "Tube",
    designation: "TUB90704",
    shape: "RHS",
    country_code: "American",
    ax: "7,59",
    ax_global: "4896,7644",
    b: 7,
    b_global: "177,8",
    d: 9,
    d_global: "228,6",
    ix: 114,
    ix_global: "47450382,52",
    iy: "60,8",
    iy_global: "25306870,68",
    iz: "89,4",
    iz_global: "37211089,45",
    t: "0,25",
    t_global: "6,35",
    zy: "19,9",
    zy_global: "326102,5736",
    zz: "23,6",
    zz_global: "386734,7104",
  },
  {
    profile_section_id: 2061,
    name: "TUB90705",
    type: "Tube",
    designation: "TUB90705",
    shape: "RHS",
    country_code: "American",
    ax: "9,36",
    ax_global: "6038,6976",
    b: 7,
    b_global: "177,8",
    d: 9,
    d_global: "228,6",
    ix: 140,
    ix_global: "58272399,58",
    iy: "73,5",
    iy_global: "30593009,78",
    iz: 108,
    iz_global: "44952993,96",
    t: "0,3125",
    t_global: "7,9375",
    zy: "24,3",
    zy_global: "398205,6552",
    zz: "28,8",
    zz_global: "471947,4432",
  },
  {
    profile_section_id: 2062,
    name: "TUB90706",
    type: "Tube",
    designation: "TUB90706",
    shape: "RHS",
    country_code: "American",
    ax: "11,1",
    ax_global: "7161,276",
    b: 7,
    b_global: "177,8",
    d: 9,
    d_global: "228,6",
    ix: 164,
    ix_global: "68261953,8",
    iy: "85,1",
    iy_global: "35421294,32",
    iz: 126,
    iz_global: "52445159,63",
    t: "0,375",
    t_global: "9,525",
    zy: "28,4",
    zy_global: "465392,6176",
    zz: "33,8",
    zz_global: "553882,7632",
  },
  {
    profile_section_id: 2063,
    name: "TUB90708",
    type: "Tube",
    designation: "TUB90708",
    shape: "RHS",
    country_code: "American",
    ax: "14,4",
    ax_global: "9290,304",
    b: 7,
    b_global: "177,8",
    d: 9,
    d_global: "228,6",
    ix: 209,
    ix_global: "86992367,95",
    iy: 106,
    iy_global: "44120531,11",
    iz: 157,
    iz_global: "65348333,82",
    t: "0,5",
    t_global: "12,7",
    zy: "36,1",
    zy_global: "591573,0104",
    zz: "42,9",
    zz_global: "703005,0456",
  },
  {
    profile_section_id: 2064,
    name: "TUB90709",
    type: "Tube",
    designation: "TUB90709",
    shape: "RHS",
    country_code: "American",
    ax: "15,9",
    ax_global: "10258,044",
    b: 7,
    b_global: "177,8",
    d: 9,
    d_global: "228,6",
    ix: 229,
    ix_global: "95316996,46",
    iy: 115,
    iy_global: "47866613,94",
    iz: 170,
    iz_global: "70759342,35",
    t: "0,5625",
    t_global: "14,2875",
    zy: "39,5",
    zy_global: "647289,028",
    zz: "47,1",
    zz_global: "771830,7144",
  },
  {
    profile_section_id: 2065,
    name: "TUB907010",
    type: "Tube",
    designation: "TUB907010",
    shape: "RHS",
    country_code: "American",
    ax: "17,4",
    ax_global: "11225,784",
    b: 7,
    b_global: "177,8",
    d: 9,
    d_global: "228,6",
    ix: 248,
    ix_global: "103225393,5",
    iy: 123,
    iy_global: "51196465,35",
    iz: 183,
    iz_global: "76170350,88",
    t: "0,625",
    t_global: "15,875",
    zy: "42,8",
    zy_global: "701366,3392",
    zz: 51,
    zz_global: "835740,264",
  },
  {
    profile_section_id: 2066,
    name: "TUB90903",
    type: "Tube",
    designation: "TUB90903",
    shape: "RHS",
    country_code: "American",
    ax: "6,52",
    ax_global: "4206,4432",
    b: 9,
    b_global: "228,6",
    d: 9,
    d_global: "228,6",
    ix: 130,
    ix_global: "54110085,33",
    iy: "83,8",
    iy_global: "34880193,47",
    iz: "83,8",
    iz_global: "34880193,47",
    t: "0,1875",
    t_global: "4,7625",
    zy: "21,4",
    zy_global: "350683,1696",
    zz: "21,4",
    zz_global: "350683,1696",
  },
  {
    profile_section_id: 2067,
    name: "TUB90904",
    type: "Tube",
    designation: "TUB90904",
    shape: "RHS",
    country_code: "American",
    ax: "8,59",
    ax_global: "5541,9244",
    b: 9,
    b_global: "228,6",
    d: 9,
    d_global: "228,6",
    ix: 170,
    ix_global: "70759342,35",
    iy: 109,
    iy_global: "45369225,39",
    iz: 109,
    iz_global: "45369225,39",
    t: "0,25",
    t_global: "6,35",
    zy: 28,
    zy_global: "458837,792",
    zz: 28,
    zz_global: "458837,792",
  },
  {
    profile_section_id: 2068,
    name: "TUB90905",
    type: "Tube",
    designation: "TUB90905",
    shape: "RHS",
    country_code: "American",
    ax: "10,6",
    ax_global: "6838,696",
    b: 9,
    b_global: "228,6",
    d: 9,
    d_global: "228,6",
    ix: 209,
    ix_global: "86992367,95",
    iy: 132,
    iy_global: "54942548,18",
    iz: 132,
    iz_global: "54942548,18",
    t: "0,3125",
    t_global: "7,9375",
    zy: "34,3",
    zy_global: "562076,2952",
    zz: "34,3",
    zz_global: "562076,2952",
  },
  {
    profile_section_id: 2069,
    name: "TUB90906",
    type: "Tube",
    designation: "TUB90906",
    shape: "RHS",
    country_code: "American",
    ax: "12,6",
    ax_global: "8129,016",
    b: 9,
    b_global: "228,6",
    d: 9,
    d_global: "228,6",
    ix: 246,
    ix_global: "102392930,7",
    iy: 154,
    iy_global: "64099639,54",
    iz: 154,
    iz_global: "64099639,54",
    t: "0,375",
    t_global: "9,525",
    zy: "40,3",
    zy_global: "660398,6792",
    zz: "40,3",
    zz_global: "660398,6792",
  },
  {
    profile_section_id: 2070,
    name: "TUB90908",
    type: "Tube",
    designation: "TUB90908",
    shape: "RHS",
    country_code: "American",
    ax: "16,4",
    ax_global: "10580,624",
    b: 9,
    b_global: "228,6",
    d: 9,
    d_global: "228,6",
    ix: 315,
    ix_global: "131112899,1",
    iy: 193,
    iy_global: "80332665,14",
    iz: 193,
    iz_global: "80332665,14",
    t: "0,5",
    t_global: "12,7",
    zy: "51,4",
    zy_global: "842295,0896",
    zz: "51,4",
    zz_global: "842295,0896",
  },
  {
    profile_section_id: 2071,
    name: "TUB90909",
    type: "Tube",
    designation: "TUB90909",
    shape: "RHS",
    country_code: "American",
    ax: "18,2",
    ax_global: "11741,912",
    b: 9,
    b_global: "228,6",
    d: 9,
    d_global: "228,6",
    ix: 347,
    ix_global: "144432304,7",
    iy: 211,
    iy_global: "87824830,8",
    iz: 211,
    iz_global: "87824830,8",
    t: "0,5625",
    t_global: "14,2875",
    zy: "56,6",
    zy_global: "927507,8224",
    zz: "56,6",
    zz_global: "927507,8224",
  },
  {
    profile_section_id: 2072,
    name: "TUB909010",
    type: "Tube",
    designation: "TUB909010",
    shape: "RHS",
    country_code: "American",
    ax: "19,9",
    ax_global: "12838,684",
    b: 9,
    b_global: "228,6",
    d: 9,
    d_global: "228,6",
    ix: 377,
    ix_global: "156919247,5",
    iy: 227,
    iy_global: "94484533,61",
    iz: 227,
    iz_global: "94484533,61",
    t: "0,625",
    t_global: "15,875",
    zy: "61,5",
    zy_global: "1007804,436",
    zz: "61,5",
    zz_global: "1007804,436",
  },
  {
    profile_section_id: 2073,
    name: "TUB100203",
    type: "Tube",
    designation: "TUB100203",
    shape: "RHS",
    country_code: "American",
    ax: "4,27",
    ax_global: "2754,8332",
    b: 2,
    b_global: "50,8",
    d: 10,
    d_global: 254,
    ix: "10,3",
    ix_global: "4287183,684",
    iy: "3,1",
    iy_global: "1290317,419",
    iz: "43,7",
    iz_global: "18189313,3",
    t: "0,1875",
    t_global: "4,7625",
    zy: "3,56",
    zy_global: "58337,94784",
    zz: "11,9",
    zz_global: "195006,0616",
  },
  {
    profile_section_id: 2074,
    name: "TUB100204",
    type: "Tube",
    designation: "TUB100204",
    shape: "RHS",
    country_code: "American",
    ax: "5,59",
    ax_global: "3606,4444",
    b: 2,
    b_global: "50,8",
    d: 10,
    d_global: 254,
    ix: "12,8",
    ix_global: "5327762,248",
    iy: "3,9",
    iy_global: "1623302,56",
    iz: "55,5",
    iz_global: "23100844,12",
    t: "0,25",
    t_global: "6,35",
    zy: "4,5",
    zy_global: "73741,788",
    zz: "15,4",
    zz_global: "252360,7856",
  },
  {
    profile_section_id: 2075,
    name: "TUB100205",
    type: "Tube",
    designation: "TUB100205",
    shape: "RHS",
    country_code: "American",
    ax: "6,86",
    ax_global: "4425,7976",
    b: 2,
    b_global: "50,8",
    d: 10,
    d_global: 254,
    ix: "14,9",
    ix_global: "6201848,241",
    iy: "4,4",
    iy_global: "1831418,273",
    iz: "66,1",
    iz_global: "27512897,23",
    t: "0,3125",
    t_global: "7,9375",
    zy: "5,33",
    zy_global: "87343,05112",
    zz: "18,5",
    zz_global: "303160,684",
  },
  {
    profile_section_id: 2076,
    name: "TUB100206",
    type: "Tube",
    designation: "TUB100206",
    shape: "RHS",
    country_code: "American",
    ax: "8,08",
    ax_global: "5212,8928",
    b: 2,
    b_global: "50,8",
    d: 10,
    d_global: 254,
    ix: "16,5",
    ix_global: "6867818,522",
    iy: "4,9",
    iy_global: "2039533,985",
    iz: "75,4",
    iz_global: "31383849,49",
    t: "0,375",
    t_global: "9,525",
    zy: "6,05",
    zy_global: "99141,7372",
    zz: "21,5",
    zz_global: "352321,876",
  },
  {
    profile_section_id: 2077,
    name: "TUB100403",
    type: "Tube",
    designation: "TUB100403",
    shape: "RHS",
    country_code: "American",
    ax: "5,02",
    ax_global: "3238,7032",
    b: 4,
    b_global: "101,6",
    d: 10,
    d_global: 254,
    ix: "39,1",
    ix_global: "16274648,74",
    iy: "14,8",
    iy_global: "6160225,099",
    iz: "61,7",
    iz_global: "25681478,96",
    t: "0,1875",
    t_global: "4,7625",
    zy: "8,2",
    zy_global: "134373,9248",
    zz: "15,6",
    zz_global: "255638,1984",
  },
  {
    profile_section_id: 2078,
    name: "TUB100404",
    type: "Tube",
    designation: "TUB100404",
    shape: "RHS",
    country_code: "American",
    ax: "6,59",
    ax_global: "4251,6044",
    b: 4,
    b_global: "101,6",
    d: 10,
    d_global: 254,
    ix: "50,4",
    ix_global: "20978063,85",
    iy: "18,8",
    iy_global: "7825150,801",
    iz: "79,3",
    iz_global: "33007152,05",
    t: "0,25",
    t_global: "6,35",
    zy: "10,6",
    zy_global: "173702,8784",
    zz: "20,2",
    zz_global: "331018,6928",
  },
  {
    profile_section_id: 2079,
    name: "TUB100405",
    type: "Tube",
    designation: "TUB100405",
    shape: "RHS",
    country_code: "American",
    ax: "8,11",
    ax_global: "5232,2476",
    b: 4,
    b_global: "101,6",
    d: 10,
    d_global: 254,
    ix: "60,8",
    ix_global: "25306870,68",
    iy: "22,4",
    iy_global: "9323583,933",
    iz: 95,
    iz_global: "39541985,43",
    t: "0,3125",
    t_global: "7,9375",
    zy: "12,8",
    zy_global: "209754,4192",
    zz: "24,6",
    zz_global: "403121,7744",
  },
  {
    profile_section_id: 2080,
    name: "TUB100406",
    type: "Tube",
    designation: "TUB100406",
    shape: "RHS",
    country_code: "American",
    ax: "9,58",
    ax_global: "6180,6328",
    b: 4,
    b_global: "101,6",
    d: 10,
    d_global: 254,
    ix: "70,4",
    ix_global: "29302692,36",
    iy: "25,5",
    iy_global: "10613901,35",
    iz: 110,
    iz_global: "45785456,82",
    t: "0,375",
    t_global: "9,525",
    zy: "14,9",
    zy_global: "244167,2536",
    zz: "28,7",
    zz_global: "470308,7368",
  },
  {
    profile_section_id: 2081,
    name: "TUB100408",
    type: "Tube",
    designation: "TUB100408",
    shape: "RHS",
    country_code: "American",
    ax: "12,4",
    ax_global: "7999,984",
    b: 4,
    b_global: "101,6",
    d: 10,
    d_global: 254,
    ix: "89,9",
    ix_global: "37419205,16",
    iy: "30,8",
    iy_global: "12819927,91",
    iz: 136,
    iz_global: "56607473,88",
    t: "0,5",
    t_global: "12,7",
    zy: "18,5",
    zy_global: "303160,684",
    zz: "36,1",
    zz_global: "591573,0104",
  },
  {
    profile_section_id: 2082,
    name: "TUB100409",
    type: "Tube",
    designation: "TUB100409",
    shape: "RHS",
    country_code: "American",
    ax: "13,7",
    ax_global: "8838,692",
    b: 4,
    b_global: "101,6",
    d: 10,
    d_global: 254,
    ix: "93,8",
    ix_global: "39042507,72",
    iy: "32,9",
    iy_global: "13694013,9",
    iz: 146,
    iz_global: "60769788,14",
    t: "0,5625",
    t_global: "14,2875",
    zy: "20,1",
    zy_global: "329379,9864",
    zz: "39,4",
    zz_global: "645650,3216",
  },
  {
    profile_section_id: 2083,
    name: "TUB100503",
    type: "Tube",
    designation: "TUB100503",
    shape: "RHS",
    country_code: "American",
    ax: "5,39",
    ax_global: "3477,4124",
    b: 5,
    b_global: 127,
    d: 10,
    d_global: 254,
    ix: 58,
    ix_global: "24141422,68",
    iy: "24,3",
    iy_global: "10114423,64",
    iz: "70,8",
    iz_global: "29469184,93",
    t: "0,1875",
    t_global: "4,7625",
    zy: "10,8",
    zy_global: "176980,2912",
    zz: "17,4",
    zz_global: "285134,9136",
  },
  {
    profile_section_id: 2084,
    name: "TUB100504",
    type: "Tube",
    designation: "TUB100504",
    shape: "RHS",
    country_code: "American",
    ax: "7,09",
    ax_global: "4574,1844",
    b: 5,
    b_global: 127,
    d: 10,
    d_global: 254,
    ix: "75,2",
    ix_global: "31300603,21",
    iy: "31,1",
    iy_global: "12944797,34",
    iz: "91,2",
    iz_global: "37960306,01",
    t: "0,25",
    t_global: "6,35",
    zy: 14,
    zy_global: "229418,896",
    zz: "22,7",
    zz_global: "371986,3528",
  },
  {
    profile_section_id: 2085,
    name: "TUB100505",
    type: "Tube",
    designation: "TUB100505",
    shape: "RHS",
    country_code: "American",
    ax: "8,73",
    ax_global: "5632,2468",
    b: 5,
    b_global: 127,
    d: 10,
    d_global: 254,
    ix: "91,5",
    ix_global: "38085175,44",
    iy: "37,2",
    iy_global: "15483809,03",
    iz: 110,
    iz_global: "45785456,82",
    t: "0,3125",
    t_global: "7,9375",
    zy: 17,
    zy_global: "278580,088",
    zz: "27,6",
    zz_global: "452282,9664",
  },
  {
    profile_section_id: 2086,
    name: "TUB100506",
    type: "Tube",
    designation: "TUB100506",
    shape: "RHS",
    country_code: "American",
    ax: "10,3",
    ax_global: "6645,148",
    b: 5,
    b_global: 127,
    d: 10,
    d_global: 254,
    ix: 107,
    ix_global: "44536762,54",
    iy: "42,9",
    iy_global: "17856328,16",
    iz: 128,
    iz_global: "53277622,48",
    t: "0,375",
    t_global: "9,525",
    zy: "19,9",
    zy_global: "326102,5736",
    zz: "32,3",
    zz_global: "529302,1672",
  },
  {
    profile_section_id: 2087,
    name: "TUB100508",
    type: "Tube",
    designation: "TUB100508",
    shape: "RHS",
    country_code: "American",
    ax: "13,4",
    ax_global: "8645,144",
    b: 5,
    b_global: 127,
    d: 10,
    d_global: 254,
    ix: 134,
    ix_global: "55775011,03",
    iy: "52,5",
    iy_global: "21852149,84",
    iz: 158,
    iz_global: "65764565,24",
    t: "0,5",
    t_global: "12,7",
    zy: 25,
    zy_global: "409676,6",
    zz: "40,8",
    zz_global: "668592,2112",
  },
  {
    profile_section_id: 2088,
    name: "TUB100509",
    type: "Tube",
    designation: "TUB100509",
    shape: "RHS",
    country_code: "American",
    ax: "14,8",
    ax_global: "9548,368",
    b: 5,
    b_global: 127,
    d: 10,
    d_global: 254,
    ix: 146,
    ix_global: "60769788,14",
    iy: "56,5",
    iy_global: "23517075,55",
    iz: 171,
    iz_global: "71175573,78",
    t: "0,5625",
    t_global: "14,2875",
    zy: "27,2",
    zy_global: "445728,1408",
    zz: "44,7",
    zz_global: "732501,7608",
  },
  {
    profile_section_id: 2089,
    name: "TUB1005010",
    type: "Tube",
    designation: "TUB1005010",
    shape: "RHS",
    country_code: "American",
    ax: "16,2",
    ax_global: "10451,592",
    b: 5,
    b_global: 127,
    d: 10,
    d_global: 254,
    ix: 157,
    ix_global: "65348333,82",
    iy: 60,
    iy_global: "24973885,54",
    iz: 183,
    iz_global: "76170350,88",
    t: "0,625",
    t_global: "15,875",
    zy: "29,3",
    zy_global: "480140,9752",
    zz: "48,3",
    zz_global: "791495,1912",
  },
  {
    profile_section_id: 2090,
    name: "TUB100603",
    type: "Tube",
    designation: "TUB100603",
    shape: "RHS",
    country_code: "American",
    ax: "5,77",
    ax_global: "3722,5732",
    b: 6,
    b_global: "152,4",
    d: 10,
    d_global: 254,
    ix: "79,1",
    ix_global: "32923905,76",
    iy: "36,5",
    iy_global: "15192447,03",
    iz: "79,8",
    iz_global: "33215267,76",
    t: "0,1875",
    t_global: "4,7625",
    zy: "13,6",
    zy_global: "222864,0704",
    zz: "19,3",
    zz_global: "316270,3352",
  },
  {
    profile_section_id: 2091,
    name: "TUB100604",
    type: "Tube",
    designation: "TUB100604",
    shape: "RHS",
    country_code: "American",
    ax: "7,59",
    ax_global: "4896,7644",
    b: 6,
    b_global: "152,4",
    d: 10,
    d_global: 254,
    ix: 103,
    ix_global: "42871836,84",
    iy: "46,9",
    iy_global: "19521253,86",
    iz: 103,
    iz_global: "42871836,84",
    t: "0,25",
    t_global: "6,35",
    zy: "17,7",
    zy_global: "290051,0328",
    zz: "25,1",
    zz_global: "411315,3064",
  },
  {
    profile_section_id: 2092,
    name: "TUB100605",
    type: "Tube",
    designation: "TUB100605",
    shape: "RHS",
    country_code: "American",
    ax: "9,36",
    ax_global: "6038,6976",
    b: 6,
    b_global: "152,4",
    d: 10,
    d_global: 254,
    ix: 126,
    ix_global: "52445159,63",
    iy: "56,5",
    iy_global: "23517075,55",
    iz: 125,
    iz_global: "52028928,2",
    t: "0,3125",
    t_global: "7,9375",
    zy: "21,5",
    zy_global: "352321,876",
    zz: "30,7",
    zz_global: "503082,8648",
  },
  {
    profile_section_id: 2093,
    name: "TUB100606",
    type: "Tube",
    designation: "TUB100606",
    shape: "RHS",
    country_code: "American",
    ax: "11,1",
    ax_global: "7161,276",
    b: 6,
    b_global: "152,4",
    d: 10,
    d_global: 254,
    ix: 147,
    ix_global: "61186019,56",
    iy: "65,4",
    iy_global: "27221535,23",
    iz: 145,
    iz_global: "60353556,71",
    t: "0,375",
    t_global: "9,525",
    zy: "25,2",
    zy_global: "412954,0128",
    zz: "35,9",
    zz_global: "588295,5976",
  },
  {
    profile_section_id: 2094,
    name: "TUB100608",
    type: "Tube",
    designation: "TUB100608",
    shape: "RHS",
    country_code: "American",
    ax: "14,4",
    ax_global: "9290,304",
    b: 6,
    b_global: "152,4",
    d: 10,
    d_global: 254,
    ix: 187,
    ix_global: "77835276,59",
    iy: "80,8",
    iy_global: "33631499,19",
    iz: 181,
    iz_global: "75337888,03",
    t: "0,5",
    t_global: "12,7",
    zy: "31,9",
    zy_global: "522747,3416",
    zz: "45,6",
    zz_global: "747250,1184",
  },
  {
    profile_section_id: 2095,
    name: "TUB100609",
    type: "Tube",
    designation: "TUB100609",
    shape: "RHS",
    country_code: "American",
    ax: "15,9",
    ax_global: "10258,044",
    b: 6,
    b_global: "152,4",
    d: 10,
    d_global: 254,
    ix: 204,
    ix_global: "84911210,82",
    iy: "87,5",
    iy_global: "36420249,74",
    iz: 197,
    iz_global: "81997590,84",
    t: "0,5625",
    t_global: "14,2875",
    zy: "34,9",
    zy_global: "571908,5336",
    zz: 50,
    zz_global: "819353,2",
  },
  {
    profile_section_id: 2096,
    name: "TUB1006010",
    type: "Tube",
    designation: "TUB1006010",
    shape: "RHS",
    country_code: "American",
    ax: "17,4",
    ax_global: "11225,784",
    b: 6,
    b_global: "152,4",
    d: 10,
    d_global: 254,
    ix: 221,
    ix_global: "91987145,06",
    iy: "93,5",
    iy_global: "38917638,29",
    iz: 211,
    iz_global: "87824830,8",
    t: "0,625",
    t_global: "15,875",
    zy: "37,7",
    zy_global: "617792,3128",
    zz: "54,2",
    zz_global: "888178,8688",
  },
  {
    profile_section_id: 2097,
    name: "TUB100803",
    type: "Tube",
    designation: "TUB100803",
    shape: "RHS",
    country_code: "American",
    ax: "6,52",
    ax_global: "4206,4432",
    b: 8,
    b_global: "203,2",
    d: 10,
    d_global: 254,
    ix: 127,
    ix_global: "52861391,05",
    iy: "69,7",
    iy_global: "29011330,36",
    iz: "97,9",
    iz_global: "40749056,57",
    t: "0,1875",
    t_global: "4,7625",
    zy: "19,7",
    zy_global: "322825,1608",
    zz: 23,
    zz_global: "376902,472",
  },
  {
    profile_section_id: 2098,
    name: "TUB100804",
    type: "Tube",
    designation: "TUB100804",
    shape: "RHS",
    country_code: "American",
    ax: "8,59",
    ax_global: "5541,9244",
    b: 8,
    b_global: "203,2",
    d: 10,
    d_global: 254,
    ix: 166,
    ix_global: "69094416,65",
    iy: "90,2",
    iy_global: "37544074,59",
    iz: 127,
    iz_global: "52861391,05",
    t: "0,25",
    t_global: "6,35",
    zy: "25,8",
    zy_global: "422786,2512",
    zz: 30,
    zz_global: "491611,92",
  },
  {
    profile_section_id: 2099,
    name: "TUB100805",
    type: "Tube",
    designation: "TUB100805",
    shape: "RHS",
    country_code: "American",
    ax: "10,6",
    ax_global: "6838,696",
    b: 8,
    b_global: "203,2",
    d: 10,
    d_global: 254,
    ix: 203,
    ix_global: "84494979,4",
    iy: 109,
    iy_global: "45369225,39",
    iz: 154,
    iz_global: "64099639,54",
    t: "0,3125",
    t_global: "7,9375",
    zy: "31,5",
    zy_global: "516192,516",
    zz: "36,7",
    zz_global: "601405,2488",
  },
  {
    profile_section_id: 2100,
    name: "TUB100806",
    type: "Tube",
    designation: "TUB100806",
    shape: "RHS",
    country_code: "American",
    ax: "12,6",
    ax_global: "8129,016",
    b: 8,
    b_global: "203,2",
    d: 10,
    d_global: 254,
    ix: 239,
    ix_global: "99479310,72",
    iy: 127,
    iy_global: "52861391,05",
    iz: 180,
    iz_global: "74921656,61",
    t: "0,375",
    t_global: "9,525",
    zy: 37,
    zy_global: "606321,368",
    zz: "43,1",
    zz_global: "706282,4584",
  },
  {
    profile_section_id: 2101,
    name: "TUB100808",
    type: "Tube",
    designation: "TUB100808",
    shape: "RHS",
    country_code: "American",
    ax: "16,4",
    ax_global: "10580,624",
    b: 8,
    b_global: "203,2",
    d: 10,
    d_global: 254,
    ix: 306,
    ix_global: "127366816,2",
    iy: 160,
    iy_global: "66597028,1",
    iz: 226,
    iz_global: "94068302,19",
    t: "0,5",
    t_global: "12,7",
    zy: "47,2",
    zy_global: "773469,4208",
    zz: "55,1",
    zz_global: "902927,2264",
  },
  {
    profile_section_id: 2102,
    name: "TUB100809",
    type: "Tube",
    designation: "TUB100809",
    shape: "RHS",
    country_code: "American",
    ax: "18,2",
    ax_global: "11741,912",
    b: 8,
    b_global: "203,2",
    d: 10,
    d_global: 254,
    ix: 337,
    ix_global: "140269990,4",
    iy: 174,
    iy_global: "72424268,05",
    iz: 247,
    iz_global: "102809162,1",
    t: "0,5625",
    t_global: "14,2875",
    zy: 52,
    zy_global: "852127,328",
    zz: "60,6",
    zz_global: "993056,0784",
  },
  {
    profile_section_id: 2103,
    name: "TUB1008010",
    type: "Tube",
    designation: "TUB1008010",
    shape: "RHS",
    country_code: "American",
    ax: "19,9",
    ax_global: "12838,684",
    b: 8,
    b_global: "203,2",
    d: 10,
    d_global: 254,
    ix: 367,
    ix_global: "152756933,2",
    iy: 187,
    iy_global: "77835276,59",
    iz: 266,
    iz_global: "110717559,2",
    t: "0,625",
    t_global: "15,875",
    zy: "56,4",
    zy_global: "924230,4096",
    zz: "65,9",
    zz_global: "1079907,518",
  },
  {
    profile_section_id: 2104,
    name: "TUB1001003",
    type: "Tube",
    designation: "TUB1001003",
    shape: "RHS",
    country_code: "American",
    ax: "7,27",
    ax_global: "4690,3132",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    ix: 179,
    ix_global: "74505425,18",
    iy: 116,
    iy_global: "48282845,37",
    iz: 116,
    iz_global: "48282845,37",
    t: "0,1875",
    t_global: "4,7625",
    zy: "26,6",
    zy_global: "435895,9024",
    zz: "26,6",
    zz_global: "435895,9024",
  },
  {
    profile_section_id: 2105,
    name: "TUB1001004",
    type: "Tube",
    designation: "TUB1001004",
    shape: "RHS",
    country_code: "American",
    ax: "9,59",
    ax_global: "6187,0844",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    ix: 235,
    ix_global: "97814385,02",
    iy: 151,
    iy_global: "62850945,27",
    iz: 151,
    iz_global: "62850945,27",
    t: "0,25",
    t_global: "6,35",
    zy: "34,9",
    zy_global: "571908,5336",
    zz: "34,9",
    zz_global: "571908,5336",
  },
  {
    profile_section_id: 2106,
    name: "TUB1001005",
    type: "Tube",
    designation: "TUB1001005",
    shape: "RHS",
    country_code: "American",
    ax: "11,9",
    ax_global: "7677,404",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    ix: 289,
    ix_global: 120290882,
    iy: 183,
    iy_global: "76170350,88",
    iz: 183,
    iz_global: "76170350,88",
    t: "0,3125",
    t_global: "7,9375",
    zy: "42,8",
    zy_global: "701366,3392",
    zz: "42,8",
    zz_global: "701366,3392",
  },
  {
    profile_section_id: 2107,
    name: "TUB1001006",
    type: "Tube",
    designation: "TUB1001006",
    shape: "RHS",
    country_code: "American",
    ax: "14,1",
    ax_global: "9096,756",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    ix: 341,
    ix_global: "141934916,1",
    iy: 214,
    iy_global: "89073525,08",
    iz: 214,
    iz_global: "89073525,08",
    t: "0,375",
    t_global: "9,525",
    zy: "50,4",
    zy_global: "825908,0256",
    zz: "50,4",
    zz_global: "825908,0256",
  },
  {
    profile_section_id: 2108,
    name: "TUB1001008",
    type: "Tube",
    designation: "TUB1001008",
    shape: "RHS",
    country_code: "American",
    ax: "18,4",
    ax_global: "11870,944",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    ix: 439,
    ix_global: "182725595,8",
    iy: 271,
    iy_global: "112798716,3",
    iz: 271,
    iz_global: "112798716,3",
    t: "0,5",
    t_global: "12,7",
    zy: "64,6",
    zy_global: "1058604,334",
    zz: "64,6",
    zz_global: "1058604,334",
  },
  {
    profile_section_id: 2109,
    name: "TIB1001009",
    type: "Tube",
    designation: "TUB1001009",
    shape: "RHS",
    country_code: "American",
    ax: "20,4",
    ax_global: "13161,264",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    ix: 485,
    ix_global: "201872241,4",
    iy: 297,
    iy_global: "123620733,4",
    iz: 297,
    iz_global: "123620733,4",
    t: "0,5625",
    t_global: "14,2875",
    zy: "71,3",
    zy_global: "1168397,663",
    zz: "71,3",
    zz_global: "1168397,663",
  },
  {
    profile_section_id: 2110,
    name: "TUB10010010",
    type: "Tube",
    designation: "TUB10010010",
    shape: "RHS",
    country_code: "American",
    ax: "22,4",
    ax_global: "14451,584",
    b: 10,
    b_global: 254,
    d: 10,
    d_global: 254,
    ix: 529,
    ix_global: "220186424,1",
    iy: 321,
    iy_global: "133610287,6",
    iz: 321,
    iz_global: "133610287,6",
    t: "0,625",
    t_global: "15,875",
    zy: "77,6",
    zy_global: "1271636,166",
    zz: "77,6",
    zz_global: "1271636,166",
  },
  {
    profile_section_id: 2111,
    name: "TUB120203",
    type: "Tube",
    designation: "TUB120203",
    shape: "RHS",
    country_code: "American",
    ax: "5,02",
    ax_global: "3238,7032",
    b: 2,
    b_global: "50,8",
    d: 12,
    d_global: "304,8",
    ix: "12,8",
    ix_global: "5327762,248",
    iy: "3,8",
    iy_global: "1581679,417",
    iz: 72,
    iz_global: "29968662,64",
    t: "0,1875",
    t_global: "4,7625",
    zy: "4,24",
    zy_global: "69481,15136",
    zz: "16,6",
    zz_global: "272025,2624",
  },
  {
    profile_section_id: 2112,
    name: "TUB120204",
    type: "Tube",
    designation: "TUB120204",
    shape: "RHS",
    country_code: "American",
    ax: "6,59",
    ax_global: "4251,6044",
    b: 2,
    b_global: "50,8",
    d: 12,
    d_global: "304,8",
    ix: "15,9",
    ix_global: "6618079,667",
    iy: "4,6",
    iy_global: "1914664,558",
    iz: "92,2",
    iz_global: "38376537,44",
    t: "0,25",
    t_global: "6,35",
    zy: "5,38",
    zy_global: "88162,40432",
    zz: "21,4",
    zz_global: "350683,1696",
  },
  {
    profile_section_id: 2113,
    name: "TUB120403",
    type: "Tube",
    designation: "TUB120403",
    shape: "RHS",
    country_code: "American",
    ax: "5,77",
    ax_global: "3722,5732",
    b: 4,
    b_global: "101,6",
    d: 12,
    d_global: "304,8",
    ix: "49,3",
    ix_global: "20520209,28",
    iy: "17,5",
    iy_global: "7284049,948",
    iz: "98,2",
    iz_global: "40873925,99",
    t: "0,1875",
    t_global: "4,7625",
    zy: "9,63",
    zy_global: "157807,4263",
    zz: 21,
    zz_global: "344128,344",
  },
  {
    profile_section_id: 2114,
    name: "TUB120404",
    type: "Tube",
    designation: "TUB120404",
    shape: "RHS",
    country_code: "American",
    ax: "7,59",
    ax_global: "4896,7644",
    b: 4,
    b_global: "101,6",
    d: 12,
    d_global: "304,8",
    ix: "63,6",
    ix_global: "26472318,67",
    iy: "22,3",
    iy_global: "9281960,791",
    iz: 127,
    iz_global: "52861391,05",
    t: "0,25",
    t_global: "6,35",
    zy: "12,5",
    zy_global: "204838,3",
    zz: "27,3",
    zz_global: "447366,8472",
  },
  {
    profile_section_id: 2115,
    name: "TUB120405",
    type: "Tube",
    designation: "TUB120405",
    shape: "RHS",
    country_code: "American",
    ax: "9,36",
    ax_global: "6038,6976",
    b: 4,
    b_global: "101,6",
    d: 12,
    d_global: "304,8",
    ix: "76,9",
    ix_global: "32008196,63",
    iy: "26,6",
    iy_global: "11071755,92",
    iz: 153,
    iz_global: "63683408,12",
    t: "0,3125",
    t_global: "7,9375",
    zy: "15,1",
    zy_global: "247444,6664",
    zz: "33,3",
    zz_global: "545689,2312",
  },
  {
    profile_section_id: 2116,
    name: "TUB120406",
    type: "Tube",
    designation: "TUB120406",
    shape: "RHS",
    country_code: "American",
    ax: "11,1",
    ax_global: "7161,276",
    b: 4,
    b_global: "101,6",
    d: 12,
    d_global: "304,8",
    ix: 89,
    ix_global: "37044596,88",
    iy: "30,5",
    iy_global: "12695058,48",
    iz: 178,
    iz_global: "74089193,76",
    t: "0,375",
    t_global: "9,525",
    zy: "17,6",
    zy_global: "288412,3264",
    zz: 39,
    zz_global: "639095,496",
  },
  {
    profile_section_id: 2117,
    name: "TUB120408",
    type: "Tube",
    designation: "TUB120408",
    shape: "RHS",
    country_code: "American",
    ax: "14,4",
    ax_global: "9290,304",
    b: 4,
    b_global: "101,6",
    d: 12,
    d_global: "304,8",
    ix: 110,
    ix_global: "45785456,82",
    iy: "36,9",
    iy_global: "15358939,6",
    iz: 221,
    iz_global: "91987145,06",
    t: "0,5",
    t_global: "12,7",
    zy: 22,
    zy_global: "360515,408",
    zz: "49,4",
    zz_global: "809520,9616",
  },
  {
    profile_section_id: 2118,
    name: "TUB120409",
    type: "Tube",
    designation: "TUB120409",
    shape: "RHS",
    country_code: "American",
    ax: "15,9",
    ax_global: "10258,044",
    b: 4,
    b_global: "101,6",
    d: 12,
    d_global: "304,8",
    ix: 119,
    ix_global: "49531539,65",
    iy: "39,6",
    iy_global: "16482764,45",
    iz: 240,
    iz_global: "99895542,14",
    t: "0,5625",
    t_global: "14,2875",
    zy: 24,
    zy_global: "393289,536",
    zz: "54,2",
    zz_global: "888178,8688",
  },
  {
    profile_section_id: 2119,
    name: "TUIB1204010",
    type: "Tube",
    designation: "TUB1204010",
    shape: "RHS",
    country_code: "American",
    ax: "17,4",
    ax_global: "11225,784",
    b: 4,
    b_global: "101,6",
    d: 12,
    d_global: "304,8",
    ix: 127,
    ix_global: "52861391,05",
    iy: "41,8",
    iy_global: "17398473,59",
    iz: 257,
    iz_global: "106971476,4",
    t: "0,625",
    t_global: "15,875",
    zy: "25,8",
    zy_global: "422786,2512",
    zz: "58,6",
    zz_global: "960281,9504",
  },
  {
    profile_section_id: 2120,
    name: "TUB120603",
    type: "Tube",
    designation: "TUB120603",
    shape: "RHS",
    country_code: "American",
    ax: "6,52",
    ax_global: "4206,4432",
    b: 6,
    b_global: "152,4",
    d: 12,
    d_global: "304,8",
    ix: 101,
    ix_global: "42039373,99",
    iy: "42,8",
    iy_global: "17814705,02",
    iz: 124,
    iz_global: "51612696,77",
    t: "0,1875",
    t_global: "4,7625",
    zy: "15,8",
    zy_global: "258915,6112",
    zz: "25,4",
    zz_global: "416231,4256",
  },
  {
    profile_section_id: 2121,
    name: "TUB120604",
    type: "Tube",
    designation: "TUB120604",
    shape: "RHS",
    country_code: "American",
    ax: "8,59",
    ax_global: "5541,9244",
    b: 6,
    b_global: "152,4",
    d: 12,
    d_global: "304,8",
    ix: 132,
    ix_global: "54942548,18",
    iy: "55,2",
    iy_global: "22975974,69",
    iz: 161,
    iz_global: "67013259,52",
    t: "0,25",
    t_global: "6,35",
    zy: "20,6",
    zy_global: "337573,5184",
    zz: "33,2",
    zz_global: "544050,5248",
  },
  {
    profile_section_id: 2122,
    name: "TUB120605",
    type: "Tube",
    designation: "TUB120605",
    shape: "RHS",
    country_code: "American",
    ax: "10,6",
    ax_global: "6838,696",
    b: 6,
    b_global: "152,4",
    d: 12,
    d_global: "304,8",
    ix: 162,
    ix_global: "67429490,95",
    iy: "66,6",
    iy_global: "27721012,94",
    iz: 196,
    iz_global: "81581359,42",
    t: "0,3125",
    t_global: "7,9375",
    zy: "25,1",
    zy_global: "411315,3064",
    zz: "40,6",
    zz_global: "665314,7984",
  },
  {
    profile_section_id: 2123,
    name: "TUB120606",
    type: "Tube",
    designation: "TUB120606",
    shape: "RHS",
    country_code: "American",
    ax: "12,6",
    ax_global: "8129,016",
    b: 6,
    b_global: "152,4",
    d: 12,
    d_global: "304,8",
    ix: 190,
    ix_global: "79083970,86",
    iy: "77,2",
    iy_global: "32133066,06",
    iz: 228,
    iz_global: "94900765,04",
    t: "0,375",
    t_global: "9,525",
    zy: "29,4",
    zy_global: "481779,6816",
    zz: "47,7",
    zz_global: "781662,9528",
  },
  {
    profile_section_id: 2124,
    name: "TUB120608",
    type: "Tube",
    designation: "TUB120608",
    shape: "RHS",
    country_code: "American",
    ax: "16,4",
    ax_global: "10580,624",
    b: 6,
    b_global: "152,4",
    d: 12,
    d_global: "304,8",
    ix: 241,
    ix_global: "100311773,6",
    iy: 96,
    iy_global: "39958216,86",
    iz: 287,
    iz_global: "119458419,1",
    t: "0,5",
    t_global: "12,7",
    zy: "37,4",
    zy_global: "612876,1936",
    zz: "60,9",
    zz_global: "997972,1976",
  },
  {
    profile_section_id: 2125,
    name: "TUB120609",
    type: "Tube",
    designation: "TUB120609",
    shape: "RHS",
    country_code: "American",
    ax: "18,2",
    ax_global: "11741,912",
    b: 6,
    b_global: "152,4",
    d: 12,
    d_global: "304,8",
    ix: 264,
    ix_global: "109885096,4",
    iy: 104,
    iy_global: "43288068,26",
    iz: 313,
    iz_global: "130280436,2",
    t: "0,5625",
    t_global: "14,2875",
    zy: 41,
    zy_global: "671869,624",
    zz: "67,1",
    zz_global: "1099571,994",
  },
  {
    profile_section_id: 2126,
    name: "TUB1206010",
    type: "Tube",
    designation: "TUB1206010",
    shape: "RHS",
    country_code: "American",
    ax: "19,9",
    ax_global: "12838,684",
    b: 6,
    b_global: "152,4",
    d: 12,
    d_global: "304,8",
    ix: 286,
    ix_global: "119042187,7",
    iy: 112,
    iy_global: "46617919,67",
    iz: 337,
    iz_global: "140269990,4",
    t: "0,625",
    t_global: "15,875",
    zy: "44,5",
    zy_global: "729224,348",
    zz: "72,9",
    zz_global: "1194616,966",
  },
  {
    profile_section_id: 2127,
    name: "TUB120803",
    type: "Tube",
    designation: "TUB120803",
    shape: "RHS",
    country_code: "American",
    ax: "7,27",
    ax_global: "4690,3132",
    b: 8,
    b_global: "203,2",
    d: 12,
    d_global: "304,8",
    ix: 165,
    ix_global: "68678185,22",
    iy: "81,1",
    iy_global: "33756368,62",
    iz: 151,
    iz_global: "62850945,27",
    t: "0,1875",
    t_global: "4,7625",
    zy: "22,7",
    zy_global: "371986,3528",
    zz: "29,8",
    zz_global: "488334,5072",
  },
  {
    profile_section_id: 2128,
    name: "TUB120804",
    type: "Tube",
    designation: "TUB120804",
    shape: "RHS",
    country_code: "American",
    ax: "9,59",
    ax_global: "6187,0844",
    b: 8,
    b_global: "203,2",
    d: 12,
    d_global: "304,8",
    ix: 216,
    ix_global: "89905987,93",
    iy: 105,
    iy_global: "43704299,69",
    iz: 192,
    iz_global: "79916433,72",
    t: "0,25",
    t_global: "6,35",
    zy: "29,6",
    zy_global: "485057,0944",
    zz: "39,1",
    zz_global: "640734,2024",
  },
  {
    profile_section_id: 2129,
    name: "TUB120805",
    type: "Tube",
    designation: "TUB120805",
    shape: "RHS",
    country_code: "American",
    ax: "11,9",
    ax_global: "7677,404",
    b: 8,
    b_global: "203,2",
    d: 12,
    d_global: "304,8",
    ix: 265,
    ix_global: "110301327,8",
    iy: 128,
    iy_global: "53277622,48",
    iz: 237,
    iz_global: "98646847,87",
    t: "0,3125",
    t_global: "7,9375",
    zy: "36,3",
    zy_global: "594850,4232",
    zz: "47,9",
    zz_global: "784940,3656",
  },
  {
    profile_section_id: 2130,
    name: "TUB120806",
    type: "Tube",
    designation: "TUB120806",
    shape: "RHS",
    country_code: "American",
    ax: "14,1",
    ax_global: "9096,756",
    b: 8,
    b_global: "203,2",
    d: 12,
    d_global: "304,8",
    ix: 312,
    ix_global: "129864204,8",
    iy: 149,
    iy_global: "62018482,41",
    iz: 288,
    iz_global: "119874650,6",
    t: "0,375",
    t_global: "9,525",
    zy: "42,7",
    zy_global: "699727,6328",
    zz: "56,5",
    zz_global: "925869,116",
  },
  {
    profile_section_id: 2131,
    name: "TUB120808",
    type: "Tube",
    designation: "TUB120808",
    shape: "RHS",
    country_code: "American",
    ax: "18,4",
    ax_global: "11870,944",
    b: 8,
    b_global: "203,2",
    d: 12,
    d_global: "304,8",
    ix: 401,
    ix_global: "166908801,7",
    iy: 188,
    iy_global: "78251508,01",
    iz: 337,
    iz_global: "140269990,4",
    t: "0,5",
    t_global: "12,7",
    zy: "54,7",
    zy_global: "896372,4008",
    zz: "72,4",
    zz_global: "1186423,434",
  },
  {
    profile_section_id: 2132,
    name: "TUB120809",
    type: "Tube",
    designation: "TUB120809",
    shape: "RHS",
    country_code: "American",
    ax: "20,4",
    ax_global: "13161,264",
    b: 8,
    b_global: "203,2",
    d: 12,
    d_global: "304,8",
    ix: 442,
    ix_global: "183974290,1",
    iy: 205,
    iy_global: "85327442,25",
    iz: 387,
    iz_global: "161081561,7",
    t: "0,5625",
    t_global: "14,2875",
    zy: "60,3",
    zy_global: "988139,9592",
    zz: "79,9",
    zz_global: "1309326,414",
  },
  {
    profile_section_id: 2133,
    name: "TUB1208010",
    type: "Tube",
    designation: "TUB1208010",
    shape: "RHS",
    country_code: "American",
    ax: "22,4",
    ax_global: "14451,584",
    b: 8,
    b_global: "203,2",
    d: 12,
    d_global: "304,8",
    ix: 481,
    ix_global: "200207315,7",
    iy: 222,
    iy_global: "92403376,48",
    iz: 418,
    iz_global: "173984735,9",
    t: "0,625",
    t_global: "15,875",
    zy: "65,6",
    zy_global: "1074991,398",
    zz: "87,1",
    zz_global: "1427313,274",
  },
  {
    profile_section_id: 2134,
    name: "TUB1201203",
    type: "Tube",
    designation: "TUB1201203",
    shape: "RHS",
    country_code: "American",
    ax: "8,77",
    ax_global: "5658,0532",
    b: 12,
    b_global: "304,8",
    d: 12,
    d_global: "304,8",
    ix: 312,
    ix_global: "129864204,8",
    iy: 203,
    iy_global: "84494979,4",
    iz: 203,
    iz_global: "84494979,4",
    t: "0,1875",
    t_global: "4,7625",
    zy: "38,7",
    zy_global: "634179,3768",
    zz: "38,7",
    zz_global: "634179,3768",
  },
  {
    profile_section_id: 2135,
    name: "TUB1201204",
    type: "Tube",
    designation: "TUB1201204",
    shape: "RHS",
    country_code: "American",
    ax: "11,6",
    ax_global: "7483,856",
    b: 12,
    b_global: "304,8",
    d: 12,
    d_global: "304,8",
    ix: 410,
    ix_global: "170654884,5",
    iy: 265,
    iy_global: "110301327,8",
    iz: 265,
    iz_global: "110301327,8",
    t: "0,25",
    t_global: "6,35",
    zy: "50,8",
    zy_global: "832462,8512",
    zz: "50,8",
    zz_global: "832462,8512",
  },
  {
    profile_section_id: 2136,
    name: "TUB1201205",
    type: "Tube",
    designation: "TUB1201205",
    shape: "RHS",
    country_code: "American",
    ax: "14,4",
    ax_global: "9290,304",
    b: 12,
    b_global: "304,8",
    d: 12,
    d_global: "304,8",
    ix: 506,
    ix_global: "210613101,4",
    iy: 324,
    iy_global: "134858981,9",
    iz: 324,
    iz_global: "134858981,9",
    t: "0,3125",
    t_global: "7,9375",
    zy: "62,6",
    zy_global: "1025830,206",
    zz: "62,6",
    zz_global: "1025830,206",
  },
  {
    profile_section_id: 2137,
    name: "TUB1201206",
    type: "Tube",
    designation: "TUB1201206",
    shape: "RHS",
    country_code: "American",
    ax: "17,1",
    ax_global: "11032,236",
    b: 12,
    b_global: "304,8",
    d: 12,
    d_global: "304,8",
    ix: 599,
    ix_global: "249322623,9",
    iy: 380,
    iy_global: "158167941,7",
    iz: 380,
    iz_global: "158167941,7",
    t: "0,375",
    t_global: "9,525",
    zy: "73,9",
    zy_global: "1211004,03",
    zz: "73,9",
    zz_global: "1211004,03",
  },
  {
    profile_section_id: 2138,
    name: "TUB1201208",
    type: "Tube",
    designation: "TUB1201208",
    shape: "RHS",
    country_code: "American",
    ax: "22,4",
    ax_global: "14451,584",
    b: 12,
    b_global: "304,8",
    d: 12,
    d_global: "304,8",
    ix: 777,
    ix_global: "323411817,7",
    iy: 485,
    iy_global: "201872241,4",
    iz: 485,
    iz_global: "201872241,4",
    t: "0,5",
    t_global: "12,7",
    zy: "95,4",
    zy_global: "1563325,906",
    zz: "95,4",
    zz_global: "1563325,906",
  },
  {
    profile_section_id: 2139,
    name: "TUB12012010",
    type: "Tube",
    designation: "TUB12012010",
    shape: "RHS",
    country_code: "American",
    ax: "27,4",
    ax_global: "17677,384",
    b: 12,
    b_global: "304,8",
    d: 12,
    d_global: "304,8",
    ix: 943,
    ix_global: "392506234,3",
    iy: 580,
    iy_global: "241414226,8",
    iz: 580,
    iz_global: "241414226,8",
    t: "0,625",
    t_global: "15,875",
    zy: 116,
    zy_global: "1900899,424",
    zz: 116,
    zz_global: "1900899,424",
  },
  {
    profile_section_id: 2140,
    name: "TUB140404",
    type: "Tube",
    designation: "TUB140404",
    shape: "RHS",
    country_code: "American",
    ax: "8,59",
    ax_global: "5541,9244",
    b: 4,
    b_global: "101,6",
    d: 14,
    d_global: "355,6",
    ix: 77,
    ix_global: "32049819,77",
    iy: "25,8",
    iy_global: "10738770,78",
    iz: 189,
    iz_global: "78667739,44",
    t: "0,25",
    t_global: "6,35",
    zy: "14,3",
    zy_global: "234335,0152",
    zz: "35,4",
    zz_global: "580102,0656",
  },
  {
    profile_section_id: 2141,
    name: "TUB140405",
    type: "Tube",
    designation: "TUB140405",
    shape: "RHS",
    country_code: "American",
    ax: "10,6",
    ax_global: "6838,696",
    b: 4,
    b_global: "101,6",
    d: 14,
    d_global: "355,6",
    ix: "93,1",
    ix_global: "38751145,72",
    iy: "30,9",
    iy_global: "12861551,05",
    iz: 230,
    iz_global: "95733227,89",
    t: "0,3125",
    t_global: "7,9375",
    zy: "17,4",
    zy_global: "285134,9136",
    zz: "43,3",
    zz_global: "709559,8712",
  },
  {
    profile_section_id: 2142,
    name: "TUB140406",
    type: "Tube",
    designation: "TUB140406",
    shape: "RHS",
    country_code: "American",
    ax: "12,6",
    ax_global: "8129,016",
    b: 4,
    b_global: "101,6",
    d: 14,
    d_global: "355,6",
    ix: 108,
    ix_global: "44952993,96",
    iy: "35,4",
    iy_global: "14734592,47",
    iz: 267,
    iz_global: "111133790,6",
    t: "0,375",
    t_global: "9,525",
    zy: "20,3",
    zy_global: "332657,3992",
    zz: "50,8",
    zz_global: "832462,8512",
  },
  {
    profile_section_id: 2143,
    name: "TUB140408",
    type: "Tube",
    designation: "TUB140408",
    shape: "RHS",
    country_code: "American",
    ax: "16,4",
    ax_global: "10580,624",
    b: 4,
    b_global: "101,6",
    d: 14,
    d_global: "355,6",
    ix: 134,
    ix_global: "55775011,03",
    iy: "43,1",
    iy_global: "17939574,44",
    iz: 335,
    iz_global: "139437527,6",
    t: "0,5",
    t_global: "12,7",
    zy: "25,5",
    zy_global: "417870,132",
    zz: "64,8",
    zz_global: "1061881,747",
  },
  {
    profile_section_id: 2144,
    name: "TUB140604",
    type: "Tube",
    designation: "TUB140604",
    shape: "RHS",
    country_code: "American",
    ax: "9,59",
    ax_global: "6187,0844",
    b: 6,
    b_global: "152,4",
    d: 14,
    d_global: "355,6",
    ix: 162,
    ix_global: "67429490,95",
    iy: "63,4",
    iy_global: "26389072,38",
    iz: 237,
    iz_global: "98646847,87",
    t: "0,25",
    t_global: "6,35",
    zy: 234,
    zy_global: "3834572,976",
    zz: "42,3",
    zz_global: "693172,8072",
  },
  {
    profile_section_id: 2145,
    name: "TUB140605",
    type: "Tube",
    designation: "TUB140605",
    shape: "RHS",
    country_code: "American",
    ax: "11,9",
    ax_global: "7677,404",
    b: 6,
    b_global: "152,4",
    d: 14,
    d_global: "355,6",
    ix: 199,
    ix_global: "82830053,69",
    iy: "76,7",
    iy_global: "31924950,34",
    iz: 288,
    iz_global: "119874650,6",
    t: "0,3125",
    t_global: "7,9375",
    zy: "28,7",
    zy_global: "470308,7368",
    zz: "51,9",
    zz_global: "850488,6216",
  },
  {
    profile_section_id: 2146,
    name: "TUB140606",
    type: "Tube",
    designation: "TUB140606",
    shape: "RHS",
    country_code: "American",
    ax: "14,1",
    ax_global: "9096,756",
    b: 6,
    b_global: "152,4",
    d: 14,
    d_global: "355,6",
    ix: 233,
    ix_global: "96981922,16",
    iy: "89,1",
    iy_global: "37086220,02",
    iz: 337,
    iz_global: "140269990,4",
    t: "0,375",
    t_global: "9,525",
    zy: "33,6",
    zy_global: "550605,3504",
    zz: "61,1",
    zz_global: "1001249,61",
  },
  {
    profile_section_id: 2147,
    name: "TUB140608",
    type: "Tube",
    designation: "TUB140608",
    shape: "RHS",
    country_code: "American",
    ax: "18,4",
    ax_global: "11870,944",
    b: 6,
    b_global: "152,4",
    d: 14,
    d_global: "355,6",
    ix: 296,
    ix_global: 123204502,
    iy: 111,
    iy_global: "46201688,24",
    iz: 426,
    iz_global: "177314587,3",
    t: "0,5",
    t_global: "12,7",
    zy: "42,9",
    zy_global: "703005,0456",
    zz: "78,3",
    zz_global: "1283107,111",
  },
  {
    profile_section_id: 2148,
    name: "TUB1401005",
    type: "Tube",
    designation: "TUB1401005",
    shape: "RHS",
    country_code: "American",
    ax: "14,4",
    ax_global: "9290,304",
    b: 10,
    b_global: 254,
    d: 14,
    d_global: "355,6",
    ix: 477,
    ix_global: 198542390,
    iy: 242,
    iy_global: 100728005,
    iz: 405,
    iz_global: "168573727,4",
    t: "0,3125",
    t_global: "7,9375",
    zy: "54,9",
    zy_global: "899649,8136",
    zz: 69,
    zz_global: "1130707,416",
  },
  {
    profile_section_id: 2149,
    name: "TUB1401006",
    type: "Tube",
    designation: "TUB1401006",
    shape: "RHS",
    country_code: "American",
    ax: "17,1",
    ax_global: "11032,236",
    b: 10,
    b_global: 254,
    d: 14,
    d_global: "355,6",
    ix: 564,
    ix_global: 234754524,
    iy: 284,
    iy_global: "118209724,9",
    iz: 467,
    iz_global: "194380075,8",
    t: "0,375",
    t_global: "9,525",
    zy: "64,8",
    zy_global: "1061881,747",
    zz: "81,5",
    zz_global: "1335545,716",
  },
  {
    profile_section_id: 2150,
    name: "TUB1401008",
    type: "Tube",
    designation: "TUB1401008",
    shape: "RHS",
    country_code: "American",
    ax: "22,4",
    ax_global: "14451,584",
    b: 10,
    b_global: 254,
    d: 14,
    d_global: "355,6",
    ix: 730,
    ix_global: "303848940,7",
    iy: 361,
    iy_global: "150259544,6",
    iz: 608,
    iz_global: "253068706,8",
    t: "0,5",
    t_global: "12,7",
    zy: "83,6",
    zy_global: "1369958,55",
    zz: 105,
    zz_global: "1720641,72",
  },
  {
    profile_section_id: 2151,
    name: "TUB14010010",
    type: "Tube",
    designation: "TUB14010010",
    shape: "RHS",
    country_code: "American",
    ax: "27,4",
    ax_global: "17677,384",
    b: 10,
    b_global: 254,
    d: 14,
    d_global: "355,6",
    ix: 885,
    ix_global: "368364811,7",
    iy: 431,
    iy_global: "179395744,4",
    iz: 728,
    iz_global: "303016477,8",
    t: "0,625",
    t_global: "15,875",
    zy: 101,
    zy_global: "1655093,464",
    zz: 127,
    zz_global: "2081157,128",
  },
  {
    profile_section_id: 2152,
    name: "TUB1401405",
    type: "Tube",
    designation: "TUB1401405",
    shape: "RHS",
    country_code: "American",
    ax: "16,9",
    ax_global: "10903,204",
    b: 14,
    b_global: "355,6",
    d: 14,
    d_global: "355,6",
    ix: 812,
    ix_global: "337979917,6",
    iy: 522,
    iy_global: "217272804,2",
    iz: 522,
    iz_global: "217272804,2",
    t: "0,3125",
    t_global: "7,9375",
    zy: "86,1",
    zy_global: "1410926,21",
    zz: "86,1",
    zz_global: "1410926,21",
  },
  {
    profile_section_id: 2153,
    name: "TUB1401406",
    type: "Tube",
    designation: "TUB1401406",
    shape: "RHS",
    country_code: "American",
    ax: "20,1",
    ax_global: "12967,716",
    b: 14,
    b_global: "355,6",
    d: 14,
    d_global: "355,6",
    ix: 963,
    ix_global: "400830862,9",
    iy: 615,
    iy_global: "255982326,7",
    iz: 615,
    iz_global: "255982326,7",
    t: "0,375",
    t_global: "9,525",
    zy: 102,
    zy_global: "1671480,528",
    zz: 102,
    zz_global: "1671480,528",
  },
  {
    profile_section_id: 2154,
    name: "TUB1401408",
    type: "Tube",
    designation: "TUB1401408",
    shape: "RHS",
    country_code: "American",
    ax: "26,4",
    ax_global: "17032,224",
    b: 14,
    b_global: "355,6",
    d: 14,
    d_global: "355,6",
    ix: 1250,
    ix_global: 520289282,
    iy: 791,
    iy_global: "329239057,6",
    iz: 791,
    iz_global: "329239057,6",
    t: "0,5",
    t_global: "12,7",
    zy: 132,
    zy_global: "2163092,448",
    zz: 132,
    zz_global: "2163092,448",
  },
  {
    profile_section_id: 2155,
    name: "TUB14014010",
    type: "Tube",
    designation: "TUB14014010",
    shape: "RHS",
    country_code: "American",
    ax: "32,4",
    ax_global: "20903,184",
    b: 14,
    b_global: "355,6",
    d: 14,
    d_global: "355,6",
    ix: 1530,
    ix_global: "636834081,2",
    iy: 952,
    iy_global: "396252317,2",
    iz: 952,
    iz_global: "396252317,2",
    t: "0,625",
    t_global: "15,875",
    zy: 161,
    zy_global: "2638317,304",
    zz: 161,
    zz_global: "2638317,304",
  },
  {
    profile_section_id: 2156,
    name: "TUB160405",
    type: "Tube",
    designation: "TUB160405",
    shape: "RHS",
    country_code: "American",
    ax: "11,9",
    ax_global: "7677,404",
    b: 4,
    b_global: "101,6",
    d: 16,
    d_global: "406,4",
    ix: 110,
    ix_global: "45785456,82",
    iy: "35,1",
    iy_global: "14609723,04",
    iz: 327,
    iz_global: "136107676,2",
    t: "0,3125",
    t_global: "7,9375",
    zy: "19,7",
    zy_global: "322825,1608",
    zz: "54,5",
    zz_global: "893094,988",
  },
  {
    profile_section_id: 2157,
    name: "TUB160406",
    type: "Tube",
    designation: "TUB160406",
    shape: "RHS",
    country_code: "American",
    ax: "14,1",
    ax_global: "9096,756",
    b: 4,
    b_global: "101,6",
    d: 16,
    d_global: "406,4",
    ix: 127,
    ix_global: "52861391,05",
    iy: "40,4",
    iy_global: "16815749,59",
    iz: 382,
    iz_global: "159000404,6",
    t: "0,375",
    t_global: "9,525",
    zy: 23,
    zy_global: "376902,472",
    zz: "64,2",
    zz_global: "1052049,509",
  },
  {
    profile_section_id: 2158,
    name: "TUB160408",
    type: "Tube",
    designation: "TUB160408",
    shape: "RHS",
    country_code: "American",
    ax: "18,4",
    ax_global: "11870,944",
    b: 4,
    b_global: "101,6",
    d: 16,
    d_global: "406,4",
    ix: 157,
    ix_global: "65348333,82",
    iy: "49,3",
    iy_global: "20520209,28",
    iz: 481,
    iz_global: "200207315,7",
    t: "0,5",
    t_global: "12,7",
    zy: 29,
    zy_global: "475224,856",
    zz: "82,2",
    zz_global: "1347016,661",
  },
  {
    profile_section_id: 2159,
    name: "TUB160805",
    type: "Tube",
    designation: "TUB160805",
    shape: "RHS",
    country_code: "American",
    ax: "14,4",
    ax_global: "9290,304",
    b: 8,
    b_global: "203,2",
    d: 16,
    d_global: "406,4",
    ix: 394,
    ix_global: "163995181,7",
    iy: 165,
    iy_global: "68678185,22",
    iz: 481,
    iz_global: "200207315,7",
    t: "0,3125",
    t_global: "7,9375",
    zy: "45,9",
    zy_global: "752166,2376",
    zz: "74,2",
    zz_global: "1215920,149",
  },
  {
    profile_section_id: 2160,
    name: "TUB160806",
    type: "Tube",
    designation: "TUB160806",
    shape: "RHS",
    country_code: "American",
    ax: "17,1",
    ax_global: "11032,236",
    b: 8,
    b_global: "203,2",
    d: 16,
    d_global: "406,4",
    ix: 465,
    ix_global: "193547612,9",
    iy: 193,
    iy_global: "80332665,14",
    iz: 565,
    iz_global: "235170755,5",
    t: "0,375",
    t_global: "9,525",
    zy: "54,2",
    zy_global: "888178,8688",
    zz: "87,6",
    zz_global: "1435506,806",
  },
  {
    profile_section_id: 2161,
    name: "TUB160808",
    type: "Tube",
    designation: "TUB160808",
    shape: "RHS",
    country_code: "American",
    ax: "22,4",
    ax_global: "14451,584",
    b: 8,
    b_global: "203,2",
    d: 16,
    d_global: "406,4",
    ix: 599,
    ix_global: "249322623,9",
    iy: 244,
    iy_global: "101560467,8",
    iz: 722,
    iz_global: "300519089,3",
    t: "0,5",
    t_global: "12,7",
    zy: "69,7",
    zy_global: "1142178,361",
    zz: 113,
    zz_global: "1851738,232",
  },
  {
    profile_section_id: 2162,
    name: "TUB1601205",
    type: "Tube",
    designation: "TUB1601205",
    shape: "RHS",
    country_code: "American",
    ax: "16,9",
    ax_global: "10903,204",
    b: 12,
    b_global: "304,8",
    d: 16,
    d_global: "406,4",
    ix: 777,
    ix_global: "323411817,7",
    iy: 409,
    iy_global: "170238653,1",
    iz: 635,
    iz_global: "264306955,3",
    t: "0,3125",
    t_global: "7,9375",
    zy: "77,2",
    zy_global: "1265081,341",
    zz: "93,8",
    zz_global: "1537106,603",
  },
  {
    profile_section_id: 2163,
    name: "TUB1601206",
    type: "Tube",
    designation: "TUB1601206",
    shape: "RHS",
    country_code: "American",
    ax: "20,1",
    ax_global: "12967,716",
    b: 12,
    b_global: "304,8",
    d: 16,
    d_global: "406,4",
    ix: 922,
    ix_global: "383765374,4",
    iy: 482,
    iy_global: "200623547,1",
    iz: 748,
    iz_global: "311341106,3",
    t: "0,375",
    t_global: "9,525",
    zy: "91,3",
    zy_global: "1496138,943",
    zz: 111,
    zz_global: "1818964,104",
  },
  {
    profile_section_id: 2164,
    name: "TUB1601208",
    type: "Tube",
    designation: "TUB1601208",
    shape: "RHS",
    country_code: "American",
    ax: "26,4",
    ax_global: "17032,224",
    b: 12,
    b_global: "304,8",
    d: 16,
    d_global: "406,4",
    ix: 1200,
    ix_global: "499477710,7",
    iy: 618,
    iy_global: 257231021,
    iz: 962,
    iz_global: "400414631,4",
    t: "0,5",
    t_global: "12,7",
    zy: 118,
    zy_global: "1933673,552",
    zz: 144,
    zz_global: "2359737,216",
  },
  {
    profile_section_id: 2165,
    name: "TUB16012010",
    type: "Tube",
    designation: "TUB16012010",
    shape: "RHS",
    country_code: "American",
    ax: "32,4",
    ax_global: "20903,184",
    b: 12,
    b_global: "304,8",
    d: 16,
    d_global: "406,4",
    ix: 1460,
    ix_global: "607697881,4",
    iy: 742,
    iy_global: "308843717,8",
    iz: 1160,
    iz_global: "482828453,7",
    t: "0,625",
    t_global: "15,875",
    zy: 144,
    zy_global: "2359737,216",
    zz: 175,
    zz_global: "2867736,2",
  },
  {
    profile_section_id: 2166,
    name: "TUB1601605",
    type: "Tube",
    designation: "TUB1601605",
    shape: "RHS",
    country_code: "American",
    ax: "19,4",
    ax_global: "12516,104",
    b: 16,
    b_global: "406,4",
    d: 16,
    d_global: "406,4",
    ix: 1220,
    ix_global: "507802339,2",
    iy: 789,
    iy_global: "328406594,8",
    iz: 789,
    iz_global: "328406594,8",
    t: "0,3125",
    t_global: "7,9375",
    zy: 113,
    zy_global: "1851738,232",
    zz: 113,
    zz_global: "1851738,232",
  },
  {
    profile_section_id: 2167,
    name: "TUB1601606",
    type: "Tube",
    designation: "TUB1601606",
    shape: "RHS",
    country_code: "American",
    ax: "23,1",
    ax_global: "14903,196",
    b: 16,
    b_global: "406,4",
    d: 16,
    d_global: "406,4",
    ix: 1450,
    ix_global: "603535567,1",
    iy: 931,
    iy_global: "387511457,2",
    iz: 931,
    iz_global: "387511457,2",
    t: "0,375",
    t_global: "9,525",
    zy: 134,
    zy_global: "2195866,576",
    zz: 134,
    zz_global: "2195866,576",
  },
  {
    profile_section_id: 2168,
    name: "TUB1601608",
    type: "Tube",
    designation: "TUB1601608",
    shape: "RHS",
    country_code: "American",
    ax: "30,4",
    ax_global: "19612,864",
    b: 16,
    b_global: "406,4",
    d: 16,
    d_global: "406,4",
    ix: 1890,
    ix_global: "786677394,4",
    iy: 1200,
    iy_global: "499477710,7",
    iz: 1200,
    iz_global: "499477710,7",
    t: "0,5",
    t_global: "12,7",
    zy: 175,
    zy_global: "2867736,2",
    zz: 175,
    zz_global: "2867736,2",
  },
  {
    profile_section_id: 2169,
    name: "TUB16016010",
    type: "Tube",
    designation: "TUB16016010",
    shape: "RHS",
    country_code: "American",
    ax: "37,4",
    ax_global: "24128,984",
    b: 16,
    b_global: "406,4",
    d: 16,
    d_global: "406,4",
    ix: 2320,
    ix_global: "965656907,4",
    iy: 1450,
    iy_global: "603535567,1",
    iz: 1450,
    iz_global: "603535567,1",
    t: "0,625",
    t_global: "15,875",
    zy: 214,
    zy_global: "3506831,696",
    zz: 214,
    zz_global: "3506831,696",
  },
  {
    profile_section_id: 2170,
    name: "TUB180605",
    type: "Tube",
    designation: "TUB180605",
    shape: "RHS",
    country_code: "American",
    ax: "14,4",
    ax_global: "9290,304",
    b: 6,
    b_global: "152,4",
    d: 18,
    d_global: "457,2",
    ix: 274,
    ix_global: "114047410,6",
    iy: 97,
    iy_global: "40374448,28",
    iz: 546,
    iz_global: "227262358,4",
    t: "0,3125",
    t_global: "7,9375",
    zy: "35,8",
    zy_global: "586656,8912",
    zz: "78,1",
    zz_global: "1279829,698",
  },
  {
    profile_section_id: 2171,
    name: "TUB180606",
    type: "Tube",
    designation: "TUB180606",
    shape: "RHS",
    country_code: "American",
    ax: "17,1",
    ax_global: "11032,236",
    b: 6,
    b_global: "152,4",
    d: 18,
    d_global: "457,2",
    ix: 322,
    ix_global: 134026519,
    iy: 113,
    iy_global: "47034151,09",
    iz: 641,
    iz_global: "266804343,8",
    t: "0,375",
    t_global: "9,525",
    zy: "42,1",
    zy_global: "689895,3944",
    zz: "92,2",
    zz_global: "1510887,301",
  },
  {
    profile_section_id: 2172,
    name: "TUB180608",
    type: "Tube",
    designation: "TUB180608",
    shape: "RHS",
    country_code: "American",
    ax: "22,4",
    ax_global: "14451,584",
    b: 6,
    b_global: "152,4",
    d: 18,
    d_global: "457,2",
    ix: 410,
    ix_global: "170654884,5",
    iy: 141,
    iy_global: "58688631,01",
    iz: 818,
    iz_global: "340477306,1",
    t: "0,5",
    t_global: "12,7",
    zy: "53,9",
    zy_global: "883262,7496",
    zz: 119,
    zz_global: "1950060,616",
  },
  {
    profile_section_id: 2173,
    name: "TUB200405",
    type: "Tube",
    designation: "TUB200405",
    shape: "RHS",
    country_code: "American",
    ax: "14,4",
    ax_global: "9290,304",
    b: 4,
    b_global: "101,6",
    d: 20,
    d_global: 508,
    ix: 143,
    ix_global: "59521093,86",
    iy: "43,7",
    iy_global: "18189313,3",
    iz: 596,
    iz_global: "248073929,7",
    t: "0,3125",
    t_global: "7,9375",
    zy: "24,3",
    zy_global: "398205,6552",
    zz: "80,8",
    zz_global: "1324074,771",
  },
  {
    profile_section_id: 2174,
    name: "TUB200406",
    type: "Tube",
    designation: "TUB200406",
    shape: "RHS",
    country_code: "American",
    ax: "17,1",
    ax_global: "11032,236",
    b: 4,
    b_global: "101,6",
    d: 20,
    d_global: 508,
    ix: 165,
    ix_global: "68678185,22",
    iy: "50,3",
    iy_global: "20936440,71",
    iz: 699,
    iz_global: "290945766,5",
    t: "0,375",
    t_global: "9,525",
    zy: "28,5",
    zy_global: "467031,324",
    zz: "95,3",
    zz_global: "1561687,199",
  },
  {
    profile_section_id: 2175,
    name: "TUB200408",
    type: "Tube",
    designation: "TUB200408",
    shape: "RHS",
    country_code: "American",
    ax: "22,4",
    ax_global: "14451,584",
    b: 4,
    b_global: "101,6",
    d: 20,
    d_global: 508,
    ix: 205,
    ix_global: "85327442,25",
    iy: "61,6",
    iy_global: "25639855,82",
    iz: 889,
    iz_global: "370029737,4",
    t: "0,5",
    t_global: "12,7",
    zy: 36,
    zy_global: "589934,304",
    zz: 123,
    zz_global: "2015608,872",
  },
  {
    profile_section_id: 2176,
    name: "TUB200805",
    type: "Tube",
    designation: "TUB200805",
    shape: "RHS",
    country_code: "American",
    ax: "16,9",
    ax_global: "10903,204",
    b: 8,
    b_global: "203,2",
    d: 20,
    d_global: 508,
    ix: 529,
    ix_global: "220186424,1",
    iy: 202,
    iy_global: "84078747,97",
    iz: 838,
    iz_global: "348801934,7",
    t: "0,3125",
    t_global: "7,9375",
    zy: "55,6",
    zy_global: "911120,7584",
    zz: 105,
    zz_global: "1720641,72",
  },
  {
    profile_section_id: 2177,
    name: "TUB200806",
    type: "Tube",
    designation: "TUB200806",
    shape: "RHS",
    country_code: "American",
    ax: "20,1",
    ax_global: "12967,716",
    b: 8,
    b_global: "203,2",
    d: 20,
    d_global: 508,
    ix: 625,
    ix_global: 260144641,
    iy: 236,
    iy_global: "98230616,44",
    iz: 988,
    iz_global: "411236648,5",
    t: "0,375",
    t_global: "9,525",
    zy: "65,6",
    zy_global: "1074991,398",
    zz: 125,
    zz_global: 2048383,
  },
  {
    profile_section_id: 2178,
    name: "TUB200808",
    type: "Tube",
    designation: "TUB200808",
    shape: "RHS",
    country_code: "American",
    ax: "26,4",
    ax_global: "17032,224",
    b: 8,
    b_global: "203,2",
    d: 20,
    d_global: 508,
    ix: 806,
    ix_global: 335482529,
    iy: 300,
    iy_global: "124869427,7",
    iz: 1270,
    iz_global: "528613910,5",
    t: "0,5",
    t_global: "12,7",
    zy: "84,7",
    zy_global: "1387984,321",
    zz: 162,
    zz_global: "2654704,368",
  },
  {
    profile_section_id: 2179,
    name: "TUB2001205",
    type: "Tube",
    designation: "TUB2001205",
    shape: "RHS",
    country_code: "American",
    ax: "19,4",
    ax_global: "12516,104",
    b: 12,
    b_global: "304,8",
    d: 20,
    d_global: 508,
    ix: 1070,
    ix_global: "445367625,4",
    iy: 495,
    iy_global: "206034555,7",
    iz: 1080,
    iz_global: "449529939,6",
    t: "0,3125",
    t_global: "7,9375",
    zy: "91,8",
    zy_global: "1504332,475",
    zz: 130,
    zz_global: "2130318,32",
  },
  {
    profile_section_id: 2180,
    name: "TUB2001206",
    type: "Tube",
    designation: "TUB2001206",
    shape: "RHS",
    country_code: "American",
    ax: "23,1",
    ax_global: "14903,196",
    b: 12,
    b_global: "304,8",
    d: 20,
    d_global: 508,
    ix: 1270,
    ix_global: "528613910,5",
    iy: 583,
    iy_global: "242662921,1",
    iz: 1280,
    iz_global: "532776224,8",
    t: "0,375",
    t_global: "9,525",
    zy: 109,
    zy_global: "1786189,976",
    zz: 154,
    zz_global: "2523607,856",
  },
  {
    profile_section_id: 2181,
    name: "TUB2001208",
    type: "Tube",
    designation: "TUB2001208",
    shape: "RHS",
    country_code: "American",
    ax: "30,4",
    ax_global: "19612,864",
    b: 12,
    b_global: "304,8",
    d: 20,
    d_global: 508,
    ix: 1650,
    ix_global: "686781852,2",
    iy: 750,
    iy_global: "312173569,2",
    iz: 1650,
    iz_global: "686781852,2",
    t: "0,5",
    t_global: "12,7",
    zy: 141,
    zy_global: "2310576,024",
    zz: 201,
    zz_global: "3293799,864",
  },
  {
    profile_section_id: 4111,
    name: "Pipe26STD",
    type: "pipe",
    designation: "PIPS240",
    shape: "PIPE",
    country_code: "American",
    ax: "28,2",
    d: 26,
    i: 2320,
    t: "0,35",
    z: 196,
  },
  {
    profile_section_id: 4112,
    name: "Pipe24SCH20",
    type: "pipe",
    designation: "PIPS200",
    shape: "PIPE",
    country_code: "American",
    ax: 26,
    d: 24,
    i: 1820,
    t: "0,35",
    z: 135,
  },
  {
    profile_section_id: 4113,
    name: "Pipe20SCH20",
    type: "pipe",
    designation: "PIPS180",
    shape: "PIPE",
    country_code: "American",
    ax: "21,6",
    d: 20,
    i: 1040,
    t: "0,35",
    z: 109,
  },
  {
    profile_section_id: 4114,
    name: "Pipe18STD",
    type: "pipe",
    designation: "PIPS160",
    shape: "PIPE",
    country_code: "American",
    ax: "19,4",
    d: 18,
    i: 756,
    t: "0,35",
    z: "85,7",
  },
  {
    profile_section_id: 4115,
    name: "Pipe16SCH30",
    type: "pipe",
    designation: "PIPS140",
    shape: "PIPE",
    country_code: "American",
    ax: "17,2",
    d: 16,
    i: 527,
    t: "0,35",
    z: "65,2",
  },
  {
    profile_section_id: 4116,
    name: "Pipe14SCH30",
    type: "pipe",
    designation: "PIPS140",
    shape: "PIPE",
    country_code: "American",
    ax: 15,
    d: 14,
    i: 350,
    t: "0,35",
    z: "53,7",
  },
  {
    profile_section_id: 4117,
    name: "Pipe26SCH20",
    type: "pipe",
    designation: "PIPX240",
    shape: "PIPE",
    country_code: "American",
    ax: "36,1",
    d: 26,
    i: 2950,
    t: "0,47",
    z: 250,
  },
  {
    profile_section_id: 4118,
    name: "Pipe24XS",
    type: "pipe",
    designation: "PIPX200",
    shape: "PIPE",
    country_code: "American",
    ax: "33,3",
    d: 24,
    i: 2310,
    t: "0,47",
    z: 172,
  },
  {
    profile_section_id: 4119,
    name: "Pipe20SCH30",
    type: "pipe",
    designation: "PIPX180",
    shape: "PIPE",
    country_code: "American",
    ax: "27,6",
    d: 20,
    i: 1320,
    t: "0,47",
    z: 139,
  },
  {
    profile_section_id: 4120,
    name: "Pipe18XS",
    type: "pipe",
    designation: "PIPX160",
    shape: "PIPE",
    country_code: "American",
    ax: "24,8",
    d: 18,
    i: 956,
    t: "0,47",
    z: 109,
  },
  {
    profile_section_id: 4121,
    name: "Pipe16SCH40",
    type: "pipe",
    designation: "PIPX140",
    shape: "PIPE",
    country_code: "American",
    ax: 22,
    d: 16,
    i: 665,
    t: "0,47",
    z: "82,7",
  },
  {
    profile_section_id: 4122,
    name: "Pipe14XS",
    type: "pipe",
    designation: "PIPX120",
    shape: "PIPE",
    country_code: "American",
    ax: "19,2",
    d: 14,
    i: 440,
    t: "0,47",
    z: "70,2",
  },
  {
    profile_section_id: 4123,
    name: "Pipe12SCH120",
    type: "pipe",
    designation: "PIPD100",
    shape: "PIPE",
    country_code: "American",
    ax: "35,4",
    d: "12,75",
    i: 625,
    t: "0,93",
    z: "90,9",
  },
  {
    profile_section_id: 4124,
    name: "Pipe10SCH140",
    type: "pipe",
    designation: "PIPD80",
    shape: "PIPE",
    country_code: "American",
    ax: "28,8",
    d: "10,75",
    i: 354,
    t: "0,93",
    z: "49,9",
  },
  {
    profile_section_id: 4125,
    name: "Pipe12STD",
    type: "Pipe",
    designation: "PIPS120",
    shape: "PIPE",
    country_code: "American",
    ax: "13,7",
    ax_global: "8838,692",
    i: 262,
    i_global: "109052633,5",
    ix_global: 262,
    iy_global: 262,
    iz_global: 262,
    od: "12,8",
    od_global: "325,12",
    tw: "0,35",
    tw_global: "8,89",
    z: "53,7",
    z_global: "879985,3368",
  },
  {
    profile_section_id: 4126,
    name: "Pipe10SCH40",
    type: "Pipe",
    designation: "PIPS100",
    shape: "PIPE",
    country_code: "American",
    ax: "11,5",
    ax_global: "7419,34",
    i: 151,
    i_global: "62850945,27",
    ix_global: "62850945,27",
    iy_global: "62850945,27",
    iz_global: "62850945,27",
    od: "10,8",
    od_global: "274,32",
    tw: "0,34",
    tw_global: "8,636",
    z: "36,9",
    z_global: "604682,6616",
  },
  {
    profile_section_id: 4127,
    name: "Pipe8SCH40",
    type: "Pipe",
    designation: "PIPS80",
    shape: "PIPE",
    country_code: "American",
    ax: "7,85",
    ax_global: "5064,506",
    i: "68,1",
    i_global: "28345360,08",
    ix_global: "28345360,08",
    iy_global: "28345360,08",
    iz_global: "28345360,08",
    od: "8,63",
    od_global: "219,202",
    tw: "0,3",
    tw_global: "7,62",
    z: "20,8",
    z_global: "340850,9312",
  },
  {
    profile_section_id: 4128,
    name: "Pipe6SCH40",
    type: "Pipe",
    designation: "PIPS60",
    shape: "PIPE",
    country_code: "American",
    ax: "5,2",
    ax_global: "3354,832",
    i: "26,5",
    i_global: "11030132,78",
    ix_global: "11030132,78",
    iy_global: "11030132,78",
    iz_global: "11030132,78",
    od: "6,63",
    od_global: "168,402",
    tw: "0,26",
    tw_global: "6,604",
    z: "10,6",
    z_global: "173702,8784",
  },
  {
    profile_section_id: 4129,
    name: "Pipe5SCH40",
    type: "Pipe",
    designation: "PIPS50",
    shape: "PIPE",
    country_code: "American",
    ax: "4,01",
    ax_global: "2587,0916",
    i: "14,3",
    i_global: "5952109,386",
    od: "5,56",
    od_global: "141,224",
    tw: "0,24",
    tw_global: "6,096",
    z: "6,83",
    z_global: "111923,6471",
  },
  {
    profile_section_id: 4130,
    name: "Pipe4SCH40",
    type: "Pipe",
    designation: "PIPS40",
    shape: "PIPE",
    country_code: "American",
    ax: "2,96",
    ax_global: "1909,6736",
    i: "6,82",
    i_global: "2838698,323",
    od: "4,5",
    od_global: "114,3",
    tw: "0,22",
    tw_global: "5,588",
    z: "4,05",
    z_global: "66367,6092",
  },
  {
    profile_section_id: 4131,
    name: "Pipe3-1/2SCH40",
    type: "Pipe",
    designation: "PIPS35",
    shape: "PIPE",
    country_code: "American",
    ax: "2,5",
    ax_global: "1612,9",
    i: "4,52",
    i_global: "1881366,044",
    od: 4,
    od_global: "101,6",
    tw: "0,21",
    tw_global: "5,334",
    z: "3,03",
    z_global: "49652,80392",
  },
  {
    profile_section_id: 4132,
    name: "Pipe3SCH40",
    type: "Pipe",
    designation: "PIPS30",
    shape: "PIPE",
    country_code: "American",
    ax: "2,07",
    ax_global: "1335,4812",
    i: "2,85",
    i_global: "1186259,563",
    od: "3,5",
    od_global: "88,9",
    tw: "0,2",
    tw_global: "5,08",
    z: "2,19",
    z_global: "35887,67016",
  },
  {
    profile_section_id: 4133,
    name: "Pipe2-1/2SCH40",
    type: "Pipe",
    designation: "PIPS25",
    shape: "PIPE",
    country_code: "American",
    ax: "1,61",
    ax_global: "1038,7076",
    i: "1,45",
    i_global: "603535,5671",
    od: "2,88",
    od_global: "73,152",
    tw: "0,19",
    tw_global: "4,826",
    z: "1,37",
    z_global: "22450,27768",
  },
  {
    profile_section_id: 4134,
    name: "Pipe2SCH40",
    type: "Pipe",
    designation: "PIPS20",
    shape: "PIPE",
    country_code: "American",
    ax: "1,02",
    ax_global: "658,0632",
    i: "0,63",
    i_global: "262225,7981",
    od: "2,38",
    od_global: "60,452",
    tw: "0,14",
    tw_global: "3,556",
    z: "0,71",
    z_global: "11634,81544",
  },
  {
    profile_section_id: 4135,
    name: "Pipe1-1/2SCH40",
    type: "Pipe",
    designation: "PIPS15",
    shape: "PIPE",
    country_code: "American",
    ax: "0,75",
    ax_global: "483,87",
    i: "0,29",
    i_global: "120707,1134",
    od: "1,9",
    od_global: "48,26",
    tw: "0,14",
    tw_global: "3,556",
    z: "0,42",
    z_global: "6882,56688",
  },
  {
    profile_section_id: 4136,
    name: "Pipe1-1/4SCH40",
    type: "Pipe",
    designation: "PIPS12",
    shape: "PIPE",
    country_code: "American",
    ax: "0,63",
    ax_global: "406,4508",
    i: "0,18",
    i_global: "74921,65661",
    od: "1,66",
    od_global: "42,164",
    tw: "0,13",
    tw_global: "3,302",
    z: "0,3",
    z_global: "4916,1192",
  },
  {
    profile_section_id: 4137,
    name: "Pipe1SCH40",
    type: "Pipe",
    designation: "PIPS10",
    shape: "PIPE",
    country_code: "American",
    ax: "0,47",
    ax_global: "303,2252",
    i: "0,08",
    i_global: "33298,51405",
    od: "1,32",
    od_global: "33,528",
    tw: "0,12",
    tw_global: "3,048",
    z: "0,18",
    z_global: "2949,67152",
  },
  {
    profile_section_id: 4138,
    name: "Pipe3/4SCH40",
    type: "Pipe",
    designation: "PIPS7",
    shape: "PIPE",
    country_code: "American",
    ax: "0,31",
    ax_global: "199,9996",
    i: "0,04",
    i_global: "16649,25702",
    od: "1,05",
    od_global: "26,67",
    tw: "0,11",
    tw_global: "2,794",
    z: "0,09",
    z_global: "1474,83576",
  },
  {
    profile_section_id: 4139,
    name: "Pipe1/2SCH40",
    type: "Pipe",
    designation: "PIPS5",
    shape: "PIPE",
    country_code: "American",
    ax: "0,23",
    ax_global: "148,3868",
    i: "0,02",
    i_global: "8324,628512",
    od: "0,84",
    od_global: "21,336",
    tw: "0,1",
    tw_global: "2,54",
    z: "0,06",
    z_global: "983,22384",
  },
  {
    profile_section_id: 4140,
    name: "Pipe12XS",
    type: "Pipe",
    designation: "PIPX120",
    shape: "PIPE",
    country_code: "American",
    ax: "17,5",
    ax_global: "11290,3",
    i: 339,
    i_global: "141102453,3",
    od: "12,8",
    od_global: "325,12",
    tw: "0,47",
    tw_global: "11,938",
    z: "70,2",
    z_global: "1150371,893",
  },
  {
    profile_section_id: 4141,
    name: "Pipe10SCH80",
    type: "Pipe",
    designation: "PIPX100",
    shape: "PIPE",
    country_code: "American",
    ax: "15,1",
    ax_global: "9741,916",
    i: 199,
    i_global: "82830053,69",
    od: "10,8",
    od_global: "274,32",
    tw: "0,47",
    tw_global: "11,938",
    z: "49,2",
    z_global: "806243,5488",
  },
  {
    profile_section_id: 4142,
    name: "Pipe8SCH80",
    type: "Pipe",
    designation: "PIPX80",
    shape: "PIPE",
    country_code: "American",
    ax: "11,9",
    ax_global: "7677,404",
    i: 100,
    i_global: "41623142,56",
    od: "8,63",
    od_global: "219,202",
    tw: "0,47",
    tw_global: "11,938",
    z: 31,
    z_global: "507998,984",
  },
  {
    profile_section_id: 4143,
    name: "Pipe6SCH80",
    type: "Pipe",
    designation: "PIPX60",
    shape: "PIPE",
    country_code: "American",
    ax: "7,83",
    ax_global: "5051,6028",
    i: "38,3",
    i_global: "15941663,6",
    od: "6,63",
    od_global: "168,402",
    tw: "0,4",
    tw_global: "10,16",
    z: "15,6",
    z_global: "255638,1984",
  },
  {
    profile_section_id: 4144,
    name: "Pipe5SCH80",
    type: "Pipe",
    designation: "PIPX50",
    shape: "PIPE",
    country_code: "American",
    ax: "5,73",
    ax_global: "3696,7668",
    i: "19,5",
    i_global: "8116512,799",
    od: "5,56",
    od_global: "141,224",
    tw: "0,35",
    tw_global: "8,89",
    z: "9,5",
    z_global: "155677,108",
  },
  {
    profile_section_id: 4145,
    name: "Pipe4SCH80",
    type: "Pipe",
    designation: "PIPX40",
    shape: "PIPE",
    country_code: "American",
    ax: "4,14",
    ax_global: "2670,9624",
    i: "9,12",
    i_global: "3796030,601",
    od: "4,5",
    od_global: "114,3",
    tw: "0,32",
    tw_global: "8,128",
    z: "5,53",
    z_global: "90620,46392",
  },
  {
    profile_section_id: 4146,
    name: "Pipe3-1/2SCH80",
    type: "Pipe",
    designation: "PIPX35",
    shape: "PIPE",
    country_code: "American",
    ax: "3,43",
    ax_global: "2212,8988",
    i: "5,94",
    i_global: "2472414,668",
    od: 4,
    od_global: "101,6",
    tw: "0,3",
    tw_global: "7,62",
    z: "4,07",
    z_global: "66695,35048",
  },
  {
    profile_section_id: 4147,
    name: "Pipe3SCH80",
    type: "Pipe",
    designation: "PIPX30",
    shape: "PIPE",
    country_code: "American",
    ax: "2,83",
    ax_global: "1825,8028",
    i: "3,7",
    i_global: "1540056,275",
    od: "3,5",
    od_global: "88,9",
    tw: "0,28",
    tw_global: "7,112",
    z: "2,91",
    z_global: "47686,35624",
  },
  {
    profile_section_id: 4148,
    name: "Pipe2-1/2SCH80",
    type: "Pipe",
    designation: "PIPX25",
    shape: "PIPE",
    country_code: "American",
    ax: "2,1",
    ax_global: "1354,836",
    i: "1,83",
    i_global: "761703,5088",
    od: "2,88",
    od_global: "73,152",
    tw: "0,26",
    tw_global: "6,604",
    z: "1,77",
    z_global: "29005,10328",
  },
  {
    profile_section_id: 4149,
    name: "Pipe2SCH80",
    type: "Pipe",
    designation: "PIPX20",
    shape: "PIPE",
    country_code: "American",
    ax: "1,4",
    ax_global: "903,224",
    i: "0,83",
    i_global: "345472,0832",
    od: "2,38",
    od_global: "60,452",
    tw: "0,2",
    tw_global: "5,08",
    z: "0,96",
    z_global: "15731,58144",
  },
  {
    profile_section_id: 4150,
    name: "Pipe1-1/2SCH80",
    type: "Pipe",
    designation: "PIPX15",
    shape: "PIPE",
    country_code: "American",
    ax: 1,
    ax_global: "645,16",
    i: "0,37",
    i_global: "154005,6275",
    od: "1,9",
    od_global: "48,26",
    tw: "0,19",
    tw_global: "4,826",
    z: "0,55",
    z_global: "9012,8852",
  },
  {
    profile_section_id: 4151,
    name: "Pipe1-1/4SCH80",
    type: "Pipe",
    designation: "PIPX12",
    shape: "PIPE",
    country_code: "American",
    ax: "0,84",
    ax_global: "541,9344",
    i: "0,23",
    i_global: "95733,22789",
    od: "1,66",
    od_global: "42,164",
    tw: "0,18",
    tw_global: "4,572",
    z: "0,39",
    z_global: "6390,95496",
  },
  {
    profile_section_id: 4152,
    name: "Pipe1SCH80",
    type: "Pipe",
    designation: "PIPX10",
    shape: "PIPE",
    country_code: "American",
    ax: "0,6",
    ax_global: "387,096",
    i: "0,1",
    i_global: "41623,14256",
    od: "1,32",
    od_global: "33,528",
    tw: "0,17",
    tw_global: "4,318",
    z: "0,22",
    z_global: "3605,15408",
  },
  {
    profile_section_id: 4153,
    name: "Pipe3/4SCH80",
    type: "Pipe",
    designation: "PIPX7",
    shape: "PIPE",
    country_code: "American",
    ax: "0,41",
    ax_global: "264,5156",
    i: "0,04",
    i_global: "16649,25702",
    od: "1,05",
    od_global: "26,67",
    tw: "0,14",
    tw_global: "3,556",
    z: "0,12",
    z_global: "1966,44768",
  },
  {
    profile_section_id: 4154,
    name: "Pipe1/2SCH80",
    type: "Pipe",
    designation: "PIPX5",
    shape: "PIPE",
    country_code: "American",
    ax: "0,3",
    ax_global: "193,548",
    i: "0,02",
    i_global: "8324,628512",
    od: "0,84",
    od_global: "21,336",
    tw: "0,14",
    tw_global: "3,556",
    z: "0,07",
    z_global: "1147,09448",
  },
  {
    profile_section_id: 4155,
    name: "Pipe8XXS",
    type: "Pipe",
    designation: "PIPD80",
    shape: "PIPE",
    country_code: "American",
    ax: 20,
    ax_global: "12903,2",
    i: 154,
    i_global: "64099639,54",
    od: "8,63",
    od_global: "219,202",
    tw: "0,82",
    tw_global: "20,828",
    z: "49,9",
    z_global: "817714,4936",
  },
  {
    profile_section_id: 4156,
    name: "Pipe6XXS",
    type: "Pipe",
    designation: "PIPD60",
    shape: "PIPE",
    country_code: "American",
    ax: "14,7",
    ax_global: "9483,852",
    i: "63,5",
    i_global: "26430695,53",
    od: "6,63",
    od_global: "168,402",
    tw: "0,81",
    tw_global: "20,574",
    z: "27,4",
    z_global: "449005,5536",
  },
  {
    profile_section_id: 4157,
    name: "Pipe5XXS",
    type: "Pipe",
    designation: "PIPD50",
    shape: "PIPE",
    country_code: "American",
    ax: "10,7",
    ax_global: "6903,212",
    i: "32,2",
    i_global: "13402651,9",
    od: "5,56",
    od_global: "141,224",
    tw: "0,7",
    tw_global: "17,78",
    z: "16,7",
    z_global: "273663,9688",
  },
  {
    profile_section_id: 4158,
    name: "Pipe4XXS",
    type: "Pipe",
    designation: "PIPD40",
    shape: "PIPE",
    country_code: "American",
    ax: "7,66",
    ax_global: "4941,9256",
    i: "14,7",
    i_global: "6118601,956",
    od: "4,5",
    od_global: "114,3",
    tw: "0,63",
    tw_global: "16,002",
    z: "9,5",
    z_global: "155677,108",
  },
  {
    profile_section_id: 4159,
    name: "Pipe3XXS",
    type: "Pipe",
    designation: "PIPD30",
    shape: "PIPE",
    country_code: "American",
    ax: "5,17",
    ax_global: "3335,4772",
    i: "5,79",
    i_global: "2409979,954",
    od: "3,5",
    od_global: "88,9",
    tw: "0,56",
    tw_global: "14,224",
    z: "4,89",
    z_global: "80132,74296",
  },
  {
    profile_section_id: 4160,
    name: "Pipe2-1/2XXS",
    type: "Pipe",
    designation: "PIPD25",
    shape: "PIPE",
    country_code: "American",
    ax: "3,83",
    ax_global: "2470,9628",
    i: "2,78",
    i_global: "1157123,363",
    od: "2,88",
    od_global: "73,152",
    tw: "0,51",
    tw_global: "12,954",
    z: "2,91",
    z_global: "47686,35624",
  },
  {
    profile_section_id: 4161,
    name: "Pipe2XXS",
    type: "Pipe",
    designation: "PIPD20",
    shape: "PIPE",
    country_code: "American",
    ax: "2,51",
    ax_global: "1619,3516",
    i: "1,27",
    i_global: "528613,9105",
    od: "2,38",
    od_global: "60,452",
    tw: "0,41",
    tw_global: "10,414",
    z: "1,6",
    z_global: "26219,3024",
  },
  {
    profile_section_id: 4162,
    name: "HSS1.9X0.188_A1085",
    type: "HSS Round A1085",
    designation: "HSS1.9X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,01",
    d: "1,9",
    i: "0,38",
    t: "0,19",
    z: "0,55",
  },
  {
    profile_section_id: 4163,
    name: "HSS2.375X0.154_A1085",
    type: "HSS Round A1086",
    designation: "HSS2.375X0.154_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,07",
    d: "2,38",
    i: "0,67",
    t: "0,15",
    z: "0,76",
  },
  {
    profile_section_id: 4164,
    name: "HSS2.375X0.188_A1085",
    type: "HSS Round A1087",
    designation: "HSS2.375X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,29",
    d: "2,38",
    i: "0,78",
    t: "0,19",
    z: "0,9",
  },
  {
    profile_section_id: 4165,
    name: "HSS3X0.152_A1085",
    type: "HSS Round A1088",
    designation: "HSS3X0.152_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,36",
    d: 3,
    i: "1,38",
    t: "0,15",
    z: "1,23",
  },
  {
    profile_section_id: 4166,
    name: "HSS2.5X0.188_A1085",
    type: "HSS Round A1089",
    designation: "HSS2.5X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,37",
    d: "2,5",
    i: "0,92",
    t: "0,19",
    z: "1,01",
  },
  {
    profile_section_id: 4167,
    name: "HSS2.375X0.218_A1085",
    type: "HSS Round A1090",
    designation: "HSS2.375X0.218_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,48",
    d: "2,38",
    i: "0,87",
    t: "0,22",
    z: "1,02",
  },
  {
    profile_section_id: 4168,
    name: "HSS2.875X0.188_A1085",
    type: "HSS Round A1091",
    designation: "HSS2.875X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,59",
    d: "2,88",
    i: "1,44",
    t: "0,19",
    z: "1,36",
  },
  {
    profile_section_id: 4169,
    name: "HSS3X0.188_A1085",
    type: "HSS Round A1092",
    designation: "HSS3X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,66",
    d: 3,
    i: "1,65",
    t: "0,19",
    z: "1,49",
  },
  {
    profile_section_id: 4170,
    name: "HSS2.375X0.250_A1085",
    type: "HSS Round A1093",
    designation: "HSS2.375X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,67",
    d: "2,38",
    i: "0,95",
    t: "0,25",
    z: "1,13",
  },
  {
    profile_section_id: 4171,
    name: "HSS2.875X0.203_A1085",
    type: "HSS Round A1094",
    designation: "HSS2.875X0.203_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,7",
    d: "2,88",
    i: "1,53",
    t: "0,2",
    z: "1,45",
  },
  {
    profile_section_id: 4172,
    name: "HSS2.5X0.250_A1085",
    type: "HSS Round A1095",
    designation: "HSS2.5X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,77",
    d: "2,5",
    i: "1,13",
    t: "0,25",
    z: "1,27",
  },
  {
    profile_section_id: 4173,
    name: "HSS3X0.203_A1085",
    type: "HSS Round A1096",
    designation: "HSS3X0.203_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,78",
    d: 3,
    i: "1,75",
    t: "0,2",
    z: "1,59",
  },
  {
    profile_section_id: 4174,
    name: "HSS3X0.216_A1085",
    type: "HSS Round A1097",
    designation: "HSS3X0.216_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,89",
    d: 3,
    i: "1,84",
    t: "0,22",
    z: "1,68",
  },
  {
    profile_section_id: 4175,
    name: "HSS3.5X0.188_A1085",
    type: "HSS Round A1098",
    designation: "HSS3.5X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "1,96",
    d: "3,5",
    i: "2,69",
    t: "0,19",
    z: "2,06",
  },
  {
    profile_section_id: 4176,
    name: "HSS2.875X0.250_A1085",
    type: "HSS Round A1099",
    designation: "HSS2.875X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "2,06",
    d: "2,88",
    i: "1,79",
    t: "0,25",
    z: "1,73",
  },
  {
    profile_section_id: 4177,
    name: "HSS3.5X0.203_A1085",
    type: "HSS Round A1100",
    designation: "HSS3.5X0.203_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "2,1",
    d: "3,5",
    i: "2,87",
    t: "0,2",
    z: "2,21",
  },
  {
    profile_section_id: 4178,
    name: "HSS3X0.250_A1085",
    type: "HSS Round A1101",
    designation: "HSS3X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "2,16",
    d: 3,
    i: "2,06",
    t: "0,25",
    z: "1,9",
  },
  {
    profile_section_id: 4179,
    name: "HSS3.5X0.216_A1085",
    type: "HSS Round A1102",
    designation: "HSS3.5X0.216_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "2,23",
    d: "3,5",
    i: "3,02",
    t: "0,22",
    z: "2,33",
  },
  {
    profile_section_id: 4180,
    name: "HSS4X0.188_A1085",
    type: "HSS Round A1103",
    designation: "HSS4X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "2,25",
    d: 4,
    i: "4,1",
    t: "0,19",
    z: "2,73",
  },
  {
    profile_section_id: 4181,
    name: "HSS4.5X0.188_A1085",
    type: "HSS Round A1104",
    designation: "HSS4.5X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "2,55",
    d: "4,5",
    i: "5,93",
    t: "0,19",
    z: "3,5",
  },
  {
    profile_section_id: 4182,
    name: "HSS3.5X0.250_A1085",
    type: "HSS Round A1105",
    designation: "HSS3.5X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "2,55",
    d: "3,5",
    i: "3,39",
    t: "0,25",
    z: "2,65",
  },
  {
    profile_section_id: 4183,
    name: "HSS4X0.220_A1085",
    type: "HSS Round A1106",
    designation: "HSS4X0.220_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "2,61",
    d: 4,
    i: "4,68",
    t: "0,22",
    z: "3,15",
  },
  {
    profile_section_id: 4184,
    name: "HSS4X0.226_A1085",
    type: "HSS Round A1107",
    designation: "HSS4X0.226_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "2,68",
    d: 4,
    i: "4,79",
    t: "0,23",
    z: "3,22",
  },
  {
    profile_section_id: 4185,
    name: "HSS4X0.237_A1085",
    type: "HSS Round A1108",
    designation: "HSS4X0.237_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "2,8",
    d: 4,
    i: "4,98",
    t: "0,24",
    z: "3,36",
  },
  {
    profile_section_id: 4186,
    name: "HSS5X0.188_A1085",
    type: "HSS Round A1109",
    designation: "HSS5X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "2,84",
    d: 5,
    i: "8,24",
    t: "0,19",
    z: "4,36",
  },
  {
    profile_section_id: 4187,
    name: "HSS4X0.250_A1085",
    type: "HSS Round A1110",
    designation: "HSS4X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "2,95",
    d: 4,
    i: "5,2",
    t: "0,25",
    z: "3,52",
  },
  {
    profile_section_id: 4188,
    name: "HSS3.5X0.300_A1085",
    type: "HSS Round A1111",
    designation: "HSS3.5X0.300_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "3,02",
    d: "3,5",
    i: "3,89",
    t: "0,3",
    z: "3,08",
  },
  {
    profile_section_id: 4189,
    name: "HSS3.5X0.313_A1085",
    type: "HSS Round A1112",
    designation: "HSS3.5X0.313_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "3,13",
    d: "3,5",
    i: "4,02",
    t: "0,31",
    z: "3,19",
  },
  {
    profile_section_id: 4190,
    name: "HSS5.563X0.188_A1085",
    type: "HSS Round A1113",
    designation: "HSS5.563X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "3,17",
    d: "5,56",
    i: "11,5",
    t: "0,19",
    z: "5,43",
  },
  {
    profile_section_id: 4191,
    name: "HSS4.5X0.237_A1085",
    type: "HSS Round A1114",
    designation: "HSS4.5X0.237_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "3,17",
    d: "4,5",
    i: "7,23",
    t: "0,24",
    z: "4,31",
  },
  {
    profile_section_id: 4192,
    name: "HSS6X0.188_A1085",
    type: "HSS Round A1115",
    designation: "HSS6X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "3,43",
    d: 6,
    i: "14,5",
    t: "0,19",
    z: "6,35",
  },
  {
    profile_section_id: 4193,
    name: "HSS4X0.313_A1085",
    type: "HSS Round A1116",
    designation: "HSS4X0.313_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "3,63",
    d: 4,
    i: "6,21",
    t: "0,31",
    z: "4,27",
  },
  {
    profile_section_id: 4194,
    name: "HSS5X0.250_A1085",
    type: "HSS Round A1117",
    designation: "HSS5X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "3,73",
    d: 5,
    i: "10,6",
    t: "0,25",
    z: "5,65",
  },
  {
    profile_section_id: 4195,
    name: "HSS6.625X0.188_A1085",
    type: "HSS Round A1118",
    designation: "HSS6.625X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "3,8",
    d: "6,63",
    i: "19,7",
    t: "0,19",
    z: "7,79",
  },
  {
    profile_section_id: 4196,
    name: "HSS5X0.258_A1085",
    type: "HSS Round A1119",
    designation: "HSS5X0.258_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "3,84",
    d: 5,
    i: "10,8",
    t: "0,26",
    z: "5,81",
  },
  {
    profile_section_id: 4197,
    name: "HSS6.875X0.188_A1085",
    type: "HSS Round A1120",
    designation: "HSS6.875X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "3,95",
    d: "6,88",
    i: "22,1",
    t: "0,19",
    z: "8,41",
  },
  {
    profile_section_id: 4198,
    name: "HSS7X0.188_A1085",
    type: "HSS Round A1121",
    designation: "HSS7X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "4,02",
    d: 7,
    i: "23,4",
    t: "0,19",
    z: "8,73",
  },
  {
    profile_section_id: 4199,
    name: "HSS5.5X0.258_A1085",
    type: "HSS Round A1122",
    designation: "HSS5.5X0.258_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "4,25",
    d: "5,5",
    i: "14,6",
    t: "0,26",
    z: "7,1",
  },
  {
    profile_section_id: 4200,
    name: "HSS5.563X0.258_A1085",
    type: "HSS Round A1123",
    designation: "HSS5.563X0.258_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "4,3",
    d: "5,56",
    i: "15,2",
    t: "0,26",
    z: "7,27",
  },
  {
    profile_section_id: 4201,
    name: "HSS7.5X0.188_A1085",
    type: "HSS Round A1124",
    designation: "HSS7.5X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "4,32",
    d: "7,5",
    i: "28,9",
    t: "0,19",
    z: "10,1",
  },
  {
    profile_section_id: 4202,
    name: "HSS4.5X0.337_A1085",
    type: "HSS Round A1125",
    designation: "HSS4.5X0.337_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "4,41",
    d: "4,5",
    i: "9,61",
    t: "0,34",
    z: "5,85",
  },
  {
    profile_section_id: 4203,
    name: "HSS6X0.250_A1085",
    type: "HSS Round A1126",
    designation: "HSS6X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "4,52",
    d: 6,
    i: "18,7",
    t: "0,25",
    z: "8,27",
  },
  {
    profile_section_id: 4204,
    name: "HSS5X0.312_A1085",
    type: "HSS Round A1127",
    designation: "HSS5X0.312_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "4,6",
    d: 5,
    i: "12,7",
    t: "0,31",
    z: "6,87",
  },
  {
    profile_section_id: 4205,
    name: "HSS4.5X0.375_A1085",
    type: "HSS Round A1128",
    designation: "HSS4.5X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "4,86",
    d: "4,5",
    i: "10,4",
    t: "0,38",
    z: "6,4",
  },
  {
    profile_section_id: 4206,
    name: "HSS8.625X0.188_A1085",
    type: "HSS Round A1129",
    designation: "HSS8.625X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "4,98",
    d: "8,63",
    i: "44,4",
    t: "0,19",
    z: "13,4",
  },
  {
    profile_section_id: 4207,
    name: "HSS6.625X0.250_A1085",
    type: "HSS Round A1130",
    designation: "HSS6.625X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "5,01",
    d: "6,63",
    i: "25,5",
    t: "0,25",
    z: "10,2",
  },
  {
    profile_section_id: 4208,
    name: "HSS6X0.280_A1085",
    type: "HSS Round A1131",
    designation: "HSS6X0.280_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "5,03",
    d: 6,
    i: "20,6",
    t: "0,28",
    z: "9,17",
  },
  {
    profile_section_id: 4209,
    name: "HSS6.875X0.250_A1085",
    type: "HSS Round A1132",
    designation: "HSS6.875X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "5,2",
    d: "6,88",
    i: "28,6",
    t: "0,25",
    z: 11,
  },
  {
    profile_section_id: 4210,
    name: "HSS7X0.250_A1085",
    type: "HSS Round A1133",
    designation: "HSS7X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "5,3",
    d: 7,
    i: "30,2",
    t: "0,25",
    z: "11,4",
  },
  {
    profile_section_id: 4211,
    name: "HSS5X0.375_A1085",
    type: "HSS Round A1134",
    designation: "HSS5X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "5,45",
    d: 5,
    i: "14,7",
    t: "0,38",
    z: "8,04",
  },
  {
    profile_section_id: 4212,
    name: "HSS9.625X0.188_A1085",
    type: "HSS Round A1135",
    designation: "HSS9.625X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "5,57",
    d: "9,63",
    i: "62,1",
    t: "0,19",
    z: "16,7",
  },
  {
    profile_section_id: 4213,
    name: "HSS6.625X0.280_A1085",
    type: "HSS Round A1136",
    designation: "HSS6.625X0.280_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "5,58",
    d: "6,63",
    i: "28,1",
    t: "0,28",
    z: "11,3",
  },
  {
    profile_section_id: 4214,
    name: "HSS6X0.312_A1085",
    type: "HSS Round A1137",
    designation: "HSS6X0.312_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "5,58",
    d: 6,
    i: "22,6",
    t: "0,31",
    z: "10,1",
  },
  {
    profile_section_id: 4215,
    name: "HSS7.5X0.250_A1085",
    type: "HSS Round A1138",
    designation: "HSS7.5X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "5,69",
    d: "7,5",
    i: "37,5",
    t: "0,25",
    z: "13,1",
  },
  {
    profile_section_id: 4216,
    name: "HSS10X0.188_A1085",
    type: "HSS Round A1139",
    designation: "HSS10X0.188_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "5,8",
    d: 10,
    i: "69,8",
    t: "0,19",
    z: "18,1",
  },
  {
    profile_section_id: 4217,
    name: "HSS5.5X0.375_A1085",
    type: "HSS Round A1140",
    designation: "HSS5.5X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "6,04",
    d: "5,5",
    i: "19,9",
    t: "0,38",
    z: "9,87",
  },
  {
    profile_section_id: 4218,
    name: "HSS5.563X0.375_A1085",
    type: "HSS Round A1141",
    designation: "HSS5.563X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "6,11",
    d: "5,56",
    i: "20,7",
    t: "0,38",
    z: "10,1",
  },
  {
    profile_section_id: 4219,
    name: "HSS6.625X0.312_A1085",
    type: "HSS Round A1142",
    designation: "HSS6.625X0.312_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "6,19",
    d: "6,63",
    i: "30,9",
    t: "0,31",
    z: "12,4",
  },
  {
    profile_section_id: 4220,
    name: "HSS6.875X0.312_A1085",
    type: "HSS Round A1143",
    designation: "HSS6.875X0.312_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "6,43",
    d: "6,88",
    i: "34,7",
    t: "0,31",
    z: "13,4",
  },
  {
    profile_section_id: 4221,
    name: "HSS7X0.312_A1085",
    type: "HSS Round A1144",
    designation: "HSS7X0.312_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "6,56",
    d: 7,
    i: "36,7",
    t: "0,31",
    z: 14,
  },
  {
    profile_section_id: 4222,
    name: "HSS8.625X0.250_A1085",
    type: "HSS Round A1145",
    designation: "HSS8.625X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "6,58",
    d: "8,63",
    i: "57,7",
    t: "0,25",
    z: "17,5",
  },
  {
    profile_section_id: 4223,
    name: "HSS6X0.375_A1085",
    type: "HSS Round A1146",
    designation: "HSS6X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "6,63",
    d: 6,
    i: "26,3",
    t: "0,38",
    z: "11,9",
  },
  {
    profile_section_id: 4224,
    name: "HSS7.5X0.312_A1085",
    type: "HSS Round A1147",
    designation: "HSS7.5X0.312_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "7,05",
    d: "7,5",
    i: "45,6",
    t: "0,31",
    z: "16,1",
  },
  {
    profile_section_id: 4225,
    name: "HSS5X0.500_A1085",
    type: "HSS Round A1148",
    designation: "HSS5X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "7,07",
    d: 5,
    i: "18,1",
    t: "0,5",
    z: "10,2",
  },
  {
    profile_section_id: 4226,
    name: "HSS9.625X0.250_A1085",
    type: "HSS Round A1149",
    designation: "HSS9.625X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "7,36",
    d: "9,63",
    i: 81,
    t: "0,25",
    z: 22,
  },
  {
    profile_section_id: 4227,
    name: "HSS6.625X0.375_A1085",
    type: "HSS Round A1150",
    designation: "HSS6.625X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "7,36",
    d: "6,63",
    i: "36,1",
    t: "0,38",
    z: "14,7",
  },
  {
    profile_section_id: 4228,
    name: "HSS7.625X0.328_A1085",
    type: "HSS Round A1151",
    designation: "HSS7.625X0.328_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "7,52",
    d: "7,63",
    i: "50,1",
    t: "0,33",
    z: "17,5",
  },
  {
    profile_section_id: 4229,
    name: "HSS10X0.250_A1085",
    type: "HSS Round A1152",
    designation: "HSS10X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "7,66",
    d: 10,
    i: "91,1",
    t: "0,25",
    z: "23,8",
  },
  {
    profile_section_id: 4230,
    name: "HSS6.875X0.375_A1085",
    type: "HSS Round A1153",
    designation: "HSS6.875X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "7,66",
    d: "6,88",
    i: "40,6",
    t: "0,38",
    z: "15,9",
  },
  {
    profile_section_id: 4231,
    name: "HSS7X0.375_A1085",
    type: "HSS Round A1154",
    designation: "HSS7X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "7,8",
    d: 7,
    i: 43,
    t: "0,38",
    z: "16,5",
  },
  {
    profile_section_id: 4232,
    name: "HSS5.5X0.500_A1085",
    type: "HSS Round A1155",
    designation: "HSS5.5X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "7,85",
    d: "5,5",
    i: "24,8",
    t: "0,5",
    z: "12,5",
  },
  {
    profile_section_id: 4233,
    name: "HSS5.563X0.500_A1085",
    type: "HSS Round A1156",
    designation: "HSS5.563X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "7,95",
    d: "5,56",
    i: "25,7",
    t: "0,5",
    z: "12,9",
  },
  {
    profile_section_id: 4234,
    name: "HSS10.75X0.250_A1085",
    type: "HSS Round A1157",
    designation: "HSS10.75X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "8,25",
    d: "10,75",
    i: 114,
    t: "0,25",
    z: "27,6",
  },
  {
    profile_section_id: 4235,
    name: "HSS7.5X0.375_A1085",
    type: "HSS Round A1158",
    designation: "HSS7.5X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "8,39",
    d: "7,5",
    i: "53,4",
    t: "0,38",
    z: "19,1",
  },
  {
    profile_section_id: 4236,
    name: "HSS8.625X0.322_A1085",
    type: "HSS Round A1159",
    designation: "HSS8.625X0.322_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "8,4",
    d: "8,63",
    i: "72,5",
    t: "0,32",
    z: "22,2",
  },
  {
    profile_section_id: 4237,
    name: "HSS6.625X0.432_A1085",
    type: "HSS Round A1160",
    designation: "HSS6.625X0.432_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "8,4",
    d: "6,63",
    i: "40,5",
    t: "0,43",
    z: "16,6",
  },
  {
    profile_section_id: 4238,
    name: "HSS7.625X0.375_A1085",
    type: "HSS Round A1161",
    designation: "HSS7.625X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "8,54",
    d: "7,63",
    i: "56,3",
    t: "0,38",
    z: "19,7",
  },
  {
    profile_section_id: 4239,
    name: "HSS6X0.500_A1085",
    type: "HSS Round A1162",
    designation: "HSS6X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "8,64",
    d: 6,
    i: "32,9",
    t: "0,5",
    z: "15,2",
  },
  {
    profile_section_id: 4240,
    name: "HSS9.625X0.312_A1085",
    type: "HSS Round A1163",
    designation: "HSS9.625X0.312_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "9,13",
    d: "9,63",
    i: "99,1",
    t: "0,31",
    z: "27,1",
  },
  {
    profile_section_id: 4241,
    name: "HSS10X0.312_A1085",
    type: "HSS Round A1164",
    designation: "HSS10X0.312_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "9,5",
    d: 10,
    i: 112,
    t: "0,31",
    z: "29,3",
  },
  {
    profile_section_id: 4242,
    name: "HSS6.625X0.500_A1085",
    type: "HSS Round A1165",
    designation: "HSS6.625X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "9,62",
    d: "6,63",
    i: "45,4",
    t: "0,5",
    z: "18,8",
  },
  {
    profile_section_id: 4243,
    name: "HSS8.625X0.375_A1085",
    type: "HSS Round A1166",
    designation: "HSS8.625X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "9,72",
    d: "8,63",
    i: "82,9",
    t: "0,38",
    z: "25,5",
  },
  {
    profile_section_id: 4244,
    name: "HSS12.75X0.250_A1085",
    type: "HSS Round A1167",
    designation: "HSS12.75X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "9,82",
    d: "12,75",
    i: 192,
    t: "0,25",
    z: "39,1",
  },
  {
    profile_section_id: 4245,
    name: "HSS6.875X0.500_A1085",
    type: "HSS Round A1168",
    designation: "HSS6.875X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: 10,
    d: "6,88",
    i: "51,2",
    t: "0,5",
    z: "20,4",
  },
  {
    profile_section_id: 4246,
    name: "HSS7X0.500_A1085",
    type: "HSS Round A1169",
    designation: "HSS7X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "10,2",
    d: 7,
    i: "54,2",
    t: "0,5",
    z: "21,2",
  },
  {
    profile_section_id: 4247,
    name: "HSS14X0.250_A1085",
    type: "HSS Round A1170",
    designation: "HSS14X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "10,8",
    d: 14,
    i: 255,
    t: "0,25",
    z: "47,3",
  },
  {
    profile_section_id: 4248,
    name: "HSS9.625X0.375_A1085",
    type: "HSS Round A1171",
    designation: "HSS9.625X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "10,9",
    d: "9,63",
    i: 117,
    t: "0,38",
    z: "32,1",
  },
  {
    profile_section_id: 4249,
    name: "HSS7.5X0.500_A1085",
    type: "HSS Round A1172",
    designation: "HSS7.5X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: 11,
    d: "7,5",
    i: "67,7",
    t: "0,5",
    z: "24,5",
  },
  {
    profile_section_id: 4250,
    name: "HSS10X0.375_A1085",
    type: "HSS Round A1173",
    designation: "HSS10X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "11,3",
    d: 10,
    i: 132,
    t: "0,38",
    z: "34,8",
  },
  {
    profile_section_id: 4251,
    name: "HSS10.75X0.375_A1085",
    type: "HSS Round A1174",
    designation: "HSS10.75X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "12,2",
    d: "10,75",
    i: 165,
    t: "0,38",
    z: "40,4",
  },
  {
    profile_section_id: 4252,
    name: "HSS16X0.250_A1085",
    type: "HSS Round A1175",
    designation: "HSS16X0.250_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "12,4",
    d: 16,
    i: 384,
    t: "0,25",
    z: 62,
  },
  {
    profile_section_id: 4253,
    name: "HSS8.625X0.500_A1085",
    type: "HSS Round A1176",
    designation: "HSS8.625X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "12,8",
    d: "8,63",
    i: 106,
    t: "0,5",
    z: 33,
  },
  {
    profile_section_id: 4254,
    name: "HSS14X0.312_A1085",
    type: "HSS Round A1177",
    designation: "HSS14X0.312_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "13,4",
    d: 14,
    i: 314,
    t: "0,31",
    z: "58,5",
  },
  {
    profile_section_id: 4255,
    name: "HSS9.625X0.500_A1085",
    type: "HSS Round A1178",
    designation: "HSS9.625X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "14,3",
    d: "9,63",
    i: 150,
    t: "0,5",
    z: "41,7",
  },
  {
    profile_section_id: 4256,
    name: "HSS12.75X0.375_A1085",
    type: "HSS Round A1179",
    designation: "HSS12.75X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "14,6",
    d: "12,75",
    i: 279,
    t: "0,38",
    z: "57,4",
  },
  {
    profile_section_id: 4257,
    name: "HSS10X0.500_A1085",
    type: "HSS Round A1180",
    designation: "HSS10X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "14,9",
    d: 10,
    i: 169,
    t: "0,5",
    z: "45,2",
  },
  {
    profile_section_id: 4258,
    name: "HSS16X0.312_A1085",
    type: "HSS Round A1181",
    designation: "HSS16X0.312_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "15,4",
    d: 16,
    i: 473,
    t: "0,31",
    z: "76,8",
  },
  {
    profile_section_id: 4259,
    name: "HSS8.625X0.625_A1085",
    type: "HSS Round A1182",
    designation: "HSS8.625X0.625_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "15,7",
    d: "8,63",
    i: 126,
    t: "0,63",
    z: "40,1",
  },
  {
    profile_section_id: 4260,
    name: "HSS14X0.375_A1085",
    type: "HSS Round A1183",
    designation: "HSS14X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "16,1",
    d: 14,
    i: 373,
    t: "0,38",
    z: "69,6",
  },
  {
    profile_section_id: 4261,
    name: "HSS10.75X0.500_A1085",
    type: "HSS Round A1184",
    designation: "HSS10.75X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "16,1",
    d: "10,75",
    i: 212,
    t: "0,5",
    z: "52,6",
  },
  {
    profile_section_id: 4262,
    name: "HSS16X0.375_A1085",
    type: "HSS Round A1185",
    designation: "HSS16X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "18,4",
    d: 16,
    i: 562,
    t: "0,38",
    z: "91,6",
  },
  {
    profile_section_id: 4263,
    name: "HSS10X0.625_A1085",
    type: "HSS Round A1186",
    designation: "HSS10X0.625_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "18,4",
    d: 10,
    i: 203,
    t: "0,63",
    z: 55,
  },
  {
    profile_section_id: 4264,
    name: "HSS12.75X0.500_A1085",
    type: "HSS Round A1187",
    designation: "HSS12.75X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "19,2",
    d: "12,75",
    i: 362,
    t: "0,5",
    z: "75,1",
  },
  {
    profile_section_id: 4265,
    name: "HSS18X0.375_A1085",
    type: "HSS Round A1188",
    designation: "HSS18X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "20,8",
    d: 18,
    i: 807,
    t: "0,38",
    z: 117,
  },
  {
    profile_section_id: 4266,
    name: "HSS14X0.500_A1085",
    type: "HSS Round A1189",
    designation: "HSS14X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "21,2",
    d: 14,
    i: 484,
    t: "0,5",
    z: "91,2",
  },
  {
    profile_section_id: 4267,
    name: "HSS16X0.438_A1085",
    type: "HSS Round A1190",
    designation: "HSS16X0.438_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "21,4",
    d: 16,
    i: 649,
    t: "0,44",
    z: 106,
  },
  {
    profile_section_id: 4268,
    name: "HSS20X0.375_A1085",
    type: "HSS Round A1191",
    designation: "HSS20X0.375_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "23,1",
    d: 20,
    i: 1110,
    t: "0,38",
    z: 144,
  },
  {
    profile_section_id: 4269,
    name: "HSS16X0.500_A1085",
    type: "HSS Round A1192",
    designation: "HSS16X0.500_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "24,3",
    d: 16,
    i: 732,
    t: "0,5",
    z: 120,
  },
  {
    profile_section_id: 4270,
    name: "HSS14X0.625_A1085",
    type: "HSS Round A1193",
    designation: "HSS14X0.625_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "26,3",
    d: 14,
    i: 589,
    t: "0,63",
    z: 112,
  },
  {
    profile_section_id: 4271,
    name: "HSS18X0.500_A1085",
    type: "HSS Round A1194",
    designation: "HSSP18X0.5_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "27,5",
    d: 18,
    i: 1050,
    t: "0,5",
    z: 153,
  },
  {
    profile_section_id: 4272,
    name: "HSS16X0.625_A1085",
    type: "HSS Round A1195",
    designation: "HSSP16X0.625_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "30,2",
    d: 16,
    i: 894,
    t: "0,63",
    z: 148,
  },
  {
    profile_section_id: 4273,
    name: "HSS20X0.500_A1085",
    type: "HSS Round A1196",
    designation: "HSSP20X0.5_A1085",
    shape: "PIPE",
    country_code: "American",
    ax: "30,6",
    d: 20,
    i: 1460,
    t: "0,5",
    z: 190,
  },
  {
    profile_section_id: 9574,
    name: "HSS20X.500",
    type: "HSS Round",
    designation: "HSSP20X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "28,5",
    ax_global: "18387,06",
    c: 272,
    c_global: "4457281,408",
    i: 1360,
    i_global: "566074738,8",
    od: 20,
    od_global: 508,
    tw: "0,47",
    tw_global: "11,938",
    z: 177,
    z_global: "2900510,328",
  },
  {
    profile_section_id: 9575,
    name: "HSS20X.375",
    type: "HSS Round",
    designation: "HSSP20X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "21,5",
    ax_global: "13870,94",
    c: 208,
    c_global: "3408509,312",
    i: 1040,
    i_global: "432880682,6",
    od: 20,
    od_global: 508,
    tw: "0,35",
    tw_global: "8,89",
    z: 135,
    z_global: "2212253,64",
  },
  {
    profile_section_id: 9576,
    name: "HSS18X.500",
    type: "HSS Round",
    designation: "HSSP18X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "25,6",
    ax_global: "16516,096",
    c: 219,
    c_global: "3588767,016",
    i: 985,
    i_global: "409987954,2",
    od: 18,
    od_global: "457,2",
    tw: "0,47",
    tw_global: "11,938",
    z: 143,
    z_global: "2343350,152",
  },
  {
    profile_section_id: 9577,
    name: "HSS18X.375",
    type: "HSS Round",
    designation: "HSSP18X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "19,4",
    ax_global: "12516,104",
    c: 168,
    c_global: "2753026,752",
    i: 754,
    i_global: "313838494,9",
    od: 18,
    od_global: "457,2",
    tw: "0,35",
    tw_global: "8,89",
    z: 109,
    z_global: "1786189,976",
  },
  {
    profile_section_id: 9578,
    name: "HSS16X.625",
    type: "HSS Round",
    designation: "HSSP16X0.625",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "28,1",
    ax_global: "18128,996",
    c: 209,
    c_global: "3424896,376",
    i: 838,
    i_global: "348801934,7",
    od: 16,
    od_global: "406,4",
    tw: "0,58",
    tw_global: "14,732",
    z: 138,
    z_global: "2261414,832",
  },
  {
    profile_section_id: 9579,
    name: "HSS16X.500",
    type: "HSS Round",
    designation: "HSSP16X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "22,7",
    ax_global: "14645,132",
    c: 171,
    c_global: "2802187,944",
    i: 685,
    i_global: "285118526,5",
    od: 16,
    od_global: "406,4",
    tw: "0,47",
    tw_global: "11,938",
    z: 112,
    z_global: "1835351,168",
  },
  {
    profile_section_id: 9580,
    name: "HSS16X.438",
    type: "HSS Round",
    designation: "HSSP16X0.438",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "19,9",
    ax_global: "12838,684",
    c: 152,
    c_global: "2490833,728",
    i: 606,
    i_global: "252236243,9",
    od: 16,
    od_global: "406,4",
    tw: "0,41",
    tw_global: "10,414",
    z: 99,
    z_global: "1622319,336",
  },
  {
    profile_section_id: 9581,
    name: "HSS16X.375",
    type: "HSS Round",
    designation: "HSSP16X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "17,2",
    ax_global: "11096,752",
    c: 131,
    c_global: "2146705,384",
    i: 526,
    i_global: "218937729,9",
    od: 16,
    od_global: "406,4",
    tw: "0,35",
    tw_global: "8,89",
    z: "85,5",
    z_global: "1401093,972",
  },
  {
    profile_section_id: 9582,
    name: "HSS16X.312",
    type: "HSS Round",
    designation: "HSSP16X0.312",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "14,4",
    ax_global: "9290,304",
    c: 111,
    c_global: "1818964,104",
    i: 443,
    i_global: "184390521,5",
    od: 16,
    od_global: "406,4",
    tw: "0,29",
    tw_global: "7,366",
    z: "71,8",
    z_global: "1176591,195",
  },
  {
    profile_section_id: 9583,
    name: "HSS16X.250",
    type: "HSS Round",
    designation: "HSSP16X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "11,5",
    ax_global: "7419,34",
    c: "89,7",
    c_global: "1469919,641",
    i: 359,
    i_global: "149427081,8",
    od: 16,
    od_global: "406,4",
    tw: "0,23",
    tw_global: "5,842",
    z: "57,9",
    z_global: "948811,0056",
  },
  {
    profile_section_id: 9584,
    name: "HSS14X.625",
    type: "HSS Round",
    designation: "HSSP14X0.625",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "24,5",
    ax_global: "15806,42",
    c: 158,
    c_global: "2589156,112",
    i: 552,
    i_global: "229759746,9",
    od: 14,
    od_global: "355,6",
    tw: "0,58",
    tw_global: "14,732",
    z: 105,
    z_global: "1720641,72",
  },
  {
    profile_section_id: 9585,
    name: "HSS14X.500",
    type: "HSS Round",
    designation: "HSSP14X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "19,8",
    ax_global: "12774,168",
    c: 130,
    c_global: "2130318,32",
    i: 453,
    i_global: "188552835,8",
    od: 14,
    od_global: "355,6",
    tw: "0,47",
    tw_global: "11,938",
    z: "85,2",
    z_global: "1396177,853",
  },
  {
    profile_section_id: 9586,
    name: "HSS14X.375",
    type: "HSS Round",
    designation: "HSSP14X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: 15,
    ax_global: "9677,4",
    c: 100,
    c_global: "1638706,4",
    i: 349,
    i_global: "145264767,5",
    od: 14,
    od_global: "355,6",
    tw: "0,35",
    tw_global: "8,89",
    z: "65,1",
    z_global: "1066797,866",
  },
  {
    profile_section_id: 9587,
    name: "HSS14X.312",
    type: "HSS Round",
    designation: "HSSP14X0.312",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "12,5",
    ax_global: "8064,5",
    c: "84,2",
    c_global: "1379790,789",
    i: 295,
    i_global: "122788270,6",
    od: 14,
    od_global: "355,6",
    tw: "0,29",
    tw_global: "7,366",
    z: "54,7",
    z_global: "896372,4008",
  },
  {
    profile_section_id: 9588,
    name: "HSS14X.250",
    type: "HSS Round",
    designation: "HSSP14X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "10,1",
    ax_global: "6516,116",
    c: "68,2",
    c_global: "1117597,765",
    i: 239,
    i_global: "99479310,72",
    od: 14,
    od_global: "355,6",
    tw: "0,23",
    tw_global: "5,842",
    z: "44,2",
    z_global: "724308,2288",
  },
  {
    profile_section_id: 9589,
    name: "HSS12.75X.500",
    type: "HSS Round",
    designation: "HSSP12.750X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "17,9",
    ax_global: "11548,364",
    c: 106,
    c_global: "1737028,784",
    i: 339,
    i_global: "141102453,3",
    od: "12,75",
    od_global: "323,85",
    tw: "0,47",
    tw_global: "11,938",
    z: "70,2",
    z_global: "1150371,893",
  },
  {
    profile_section_id: 9590,
    name: "HSS12.75X.375",
    type: "HSS Round",
    designation: "HSSP12.750X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "13,6",
    ax_global: "8774,176",
    c: "82,1",
    c_global: "1345377,954",
    i: 262,
    i_global: "109052633,5",
    od: "12,75",
    od_global: "323,85",
    tw: "0,35",
    tw_global: "8,89",
    z: "53,7",
    z_global: "879985,3368",
  },
  {
    profile_section_id: 9591,
    name: "HSS12.75X.250",
    type: "HSS Round",
    designation: "HSSP12.750X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "9,16",
    ax_global: "5909,6656",
    c: "56,3",
    c_global: "922591,7032",
    i: 180,
    i_global: "74921656,61",
    od: "12,75",
    od_global: "323,85",
    tw: "0,23",
    tw_global: "5,842",
    z: "36,5",
    z_global: "598127,836",
  },
  {
    profile_section_id: 9592,
    name: "HSS10.75X.500",
    type: "HSS Round",
    designation: "HSSP10.750X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: 15,
    ax_global: "9677,4",
    c: "74,1",
    c_global: "1214281,442",
    i: 199,
    i_global: "82830053,69",
    od: "10,75",
    od_global: "273,05",
    tw: "0,47",
    tw_global: "11,938",
    z: "49,2",
    z_global: "806243,5488",
  },
  {
    profile_section_id: 9593,
    name: "HSS10.75X.375",
    type: "HSS Round",
    designation: "HSSP10.750X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "11,4",
    ax_global: "7354,824",
    c: "57,4",
    c_global: "940617,4736",
    i: 154,
    i_global: "64099639,54",
    od: "10,75",
    od_global: "273,05",
    tw: "0,35",
    tw_global: "8,89",
    z: "37,8",
    z_global: "619431,0192",
  },
  {
    profile_section_id: 9594,
    name: "HSS10.75X.250",
    type: "HSS Round",
    designation: "HSSP10.750X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "7,7",
    ax_global: "4967,732",
    c: "39,6",
    c_global: "648927,7344",
    i: 106,
    i_global: "44120531,11",
    od: "10,75",
    od_global: "273,05",
    tw: "0,23",
    tw_global: "5,842",
    z: "25,8",
    z_global: "422786,2512",
  },
  {
    profile_section_id: 9595,
    name: "HSS10X.625",
    type: "HSS Round",
    designation: "HSSP10X0.625",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "17,2",
    ax_global: "11096,752",
    c: "76,6",
    c_global: "1255249,102",
    i: 191,
    i_global: "79500202,29",
    od: 10,
    od_global: 254,
    tw: "0,58",
    tw_global: "14,732",
    z: "51,6",
    z_global: "845572,5024",
  },
  {
    profile_section_id: 9596,
    name: "HSS10X.500",
    type: "HSS Round",
    designation: "HSSP10X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "13,9",
    ax_global: "8967,724",
    c: "63,5",
    c_global: "1040578,564",
    i: 159,
    i_global: "66180796,67",
    od: 10,
    od_global: 254,
    tw: "0,47",
    tw_global: "11,938",
    z: "42,3",
    z_global: "693172,8072",
  },
  {
    profile_section_id: 9597,
    name: "HSS10X.375",
    type: "HSS Round",
    designation: "HSSP10X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "10,6",
    ax_global: "6838,696",
    c: "49,3",
    c_global: "807882,2552",
    i: 123,
    i_global: "51196465,35",
    od: 10,
    od_global: 254,
    tw: "0,35",
    tw_global: "8,89",
    z: "32,5",
    z_global: "532579,58",
  },
  {
    profile_section_id: 9598,
    name: "HSS10X.312",
    type: "HSS Round",
    designation: "HSSP10X0.312",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "8,88",
    ax_global: "5729,0208",
    c: "41,9",
    c_global: "686617,9816",
    i: 105,
    i_global: "43704299,69",
    od: 10,
    od_global: 254,
    tw: "0,29",
    tw_global: "7,366",
    z: "27,4",
    z_global: "449005,5536",
  },
  {
    profile_section_id: 9599,
    name: "HSS10X.250",
    type: "HSS Round",
    designation: "HSSP10X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "7,15",
    ax_global: "4612,894",
    c: "34,1",
    c_global: "558798,8824",
    i: "85,3",
    i_global: "35504540,6",
    od: 10,
    od_global: 254,
    tw: "0,23",
    tw_global: "5,842",
    z: "22,2",
    z_global: "363792,8208",
  },
  {
    profile_section_id: 9600,
    name: "HSS10X.188",
    type: "HSS Round",
    designation: "HSSP10X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "5,37",
    ax_global: "3464,5092",
    c: "25,9",
    c_global: "424424,9576",
    i: "64,8",
    i_global: "26971796,38",
    od: 10,
    od_global: 254,
    tw: "0,17",
    tw_global: "4,318",
    z: "16,8",
    z_global: "275302,6752",
  },
  {
    profile_section_id: 9601,
    name: "HSS9.625X.500",
    type: "HSS Round",
    designation: "HSSP9.625X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "13,4",
    ax_global: "8645,144",
    c: "58,5",
    c_global: "958643,244",
    i: 141,
    i_global: "58688631,01",
    od: "9,63",
    od_global: "244,602",
    tw: "0,47",
    tw_global: "11,938",
    z: 39,
    z_global: "639095,496",
  },
  {
    profile_section_id: 9602,
    name: "HSS9.625X.375",
    type: "HSS Round",
    designation: "HSSP9.625X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "10,2",
    ax_global: "6580,632",
    c: "45,5",
    c_global: "745611,412",
    i: 110,
    i_global: "45785456,82",
    od: "9,63",
    od_global: "244,602",
    tw: "0,35",
    tw_global: "8,89",
    z: 30,
    z_global: "491611,92",
  },
  {
    profile_section_id: 9603,
    name: "HSS9.625X.312",
    type: "HSS Round",
    designation: "HSSP9.625X0.312",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "8,53",
    ax_global: "5503,2148",
    c: "38,7",
    c_global: "634179,3768",
    i: 93,
    i_global: "38709522,58",
    od: "9,63",
    od_global: "244,602",
    tw: "0,29",
    tw_global: "7,366",
    z: "25,4",
    z_global: "416231,4256",
  },
  {
    profile_section_id: 9604,
    name: "HSS9.625X.250",
    type: "HSS Round",
    designation: "HSSP9.625X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "6,87",
    ax_global: "4432,2492",
    c: "31,5",
    c_global: "516192,516",
    i: "75,9",
    i_global: "31591965,2",
    od: "9,63",
    od_global: "244,602",
    tw: "0,23",
    tw_global: "5,842",
    z: "20,6",
    z_global: "337573,5184",
  },
  {
    profile_section_id: 9605,
    name: "HSS9.625X.188",
    type: "HSS Round",
    designation: "HSSP9.625X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "5,17",
    ax_global: "3335,4772",
    c: 24,
    c_global: "393289,536",
    i: "57,7",
    i_global: "24016553,26",
    od: "9,63",
    od_global: "244,602",
    tw: "0,17",
    tw_global: "4,318",
    z: "15,5",
    z_global: "253999,492",
  },
  {
    profile_section_id: 9606,
    name: "HSS8.625X.625",
    type: "HSS Round",
    designation: "HSSP8.625X0.625",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "14,7",
    ax_global: "9483,852",
    c: "55,4",
    c_global: "907843,3456",
    i: 119,
    i_global: "49531539,65",
    od: "8,63",
    od_global: "219,202",
    tw: "0,58",
    tw_global: "14,732",
    z: "37,7",
    z_global: "617792,3128",
  },
  {
    profile_section_id: 9607,
    name: "HSS8.625X.500",
    type: "HSS Round",
    designation: "HSSP8.625X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "11,9",
    ax_global: "7677,404",
    c: "46,2",
    c_global: "757082,3568",
    i: 100,
    i_global: "41623142,56",
    od: "8,63",
    od_global: "219,202",
    tw: "0,47",
    tw_global: "11,938",
    z: 31,
    z_global: "507998,984",
  },
  {
    profile_section_id: 9608,
    name: "HSS8.625X.375",
    type: "HSS Round",
    designation: "HSSP8.625X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "9,07",
    ax_global: "5851,6012",
    c: "36,1",
    c_global: "591573,0104",
    i: "77,8",
    i_global: "32382804,91",
    od: "8,63",
    od_global: "219,202",
    tw: "0,35",
    tw_global: "8,89",
    z: "23,9",
    z_global: "391650,8296",
  },
  {
    profile_section_id: 9609,
    name: "HSS8.625X.322",
    type: "HSS Round",
    designation: "HSSP8.625X0.322",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "7,85",
    ax_global: "5064,506",
    c: "31,6",
    c_global: "517831,2224",
    i: "68,1",
    i_global: "28345360,08",
    od: "8,63",
    od_global: "219,202",
    tw: "0,3",
    tw_global: "7,62",
    z: "20,8",
    z_global: "340850,9312",
  },
  {
    profile_section_id: 9610,
    name: "HSS8.625X.250",
    type: "HSS Round",
    designation: "HSSP8.625X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "6,14",
    ax_global: "3961,2824",
    c: "25,1",
    c_global: "411315,3064",
    i: "54,1",
    i_global: "22518120,12",
    od: "8,63",
    od_global: "219,202",
    tw: "0,23",
    tw_global: "5,842",
    z: "16,4",
    z_global: "268747,8496",
  },
  {
    profile_section_id: 9611,
    name: "HSS8.625X.188",
    type: "HSS Round",
    designation: "HSSP8.625X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "4,62",
    ax_global: "2980,6392",
    c: "19,1",
    c_global: "312992,9224",
    i: "41,3",
    i_global: "17190357,88",
    od: "8,63",
    od_global: "219,202",
    tw: "0,17",
    tw_global: "4,318",
    z: "12,4",
    z_global: "203199,5936",
  },
  {
    profile_section_id: 9612,
    name: "HSS7.625X.375",
    type: "HSS Round",
    designation: "HSSP7.625X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "7,98",
    ax_global: "5148,3768",
    c: "27,8",
    c_global: "455560,3792",
    i: "52,9",
    i_global: "22018642,41",
    od: "7,63",
    od_global: "193,802",
    tw: "0,35",
    tw_global: "8,89",
    z: "18,5",
    z_global: "303160,684",
  },
  {
    profile_section_id: 9613,
    name: "HSS7.625X.328",
    type: "HSS Round",
    designation: "HSSP7.625X0.328",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "7,01",
    ax_global: "4522,5716",
    c: "24,7",
    c_global: "404760,4808",
    i: "47,1",
    i_global: "19604500,15",
    od: "7,63",
    od_global: "193,802",
    tw: "0,3",
    tw_global: "7,62",
    z: "16,4",
    z_global: "268747,8496",
  },
  {
    profile_section_id: 9614,
    name: "HSS7.5X.500",
    type: "HSS Round",
    designation: "HSSP7.500X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "10,3",
    ax_global: "6645,148",
    c: "34,1",
    c_global: "558798,8824",
    i: "63,9",
    i_global: "26597188,1",
    od: "7,5",
    od_global: "190,5",
    tw: "0,47",
    tw_global: "11,938",
    z: 23,
    z_global: "376902,472",
  },
  {
    profile_section_id: 9615,
    name: "HSS7.5X.375",
    type: "HSS Round",
    designation: "HSSP7.500X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "7,84",
    ax_global: "5058,0544",
    c: "26,8",
    c_global: "439173,3152",
    i: "50,2",
    i_global: "20894817,57",
    od: "7,5",
    od_global: "190,5",
    tw: "0,35",
    tw_global: "8,89",
    z: "17,9",
    z_global: "293328,4456",
  },
  {
    profile_section_id: 9616,
    name: "HSS7.5X.312",
    type: "HSS Round",
    designation: "HSSP7.500X0.312",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "6,59",
    ax_global: "4251,6044",
    c: "22,9",
    c_global: "375263,7656",
    i: "42,9",
    i_global: "17856328,16",
    od: "7,5",
    od_global: "190,5",
    tw: "0,29",
    tw_global: "7,366",
    z: "15,1",
    z_global: "247444,6664",
  },
  {
    profile_section_id: 9617,
    name: "HSS7.5X.250",
    type: "HSS Round",
    designation: "HSSP7.500X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "5,32",
    ax_global: "3432,2512",
    c: "18,7",
    c_global: "306438,0968",
    i: "35,2",
    i_global: "14651346,18",
    od: "7,5",
    od_global: "190,5",
    tw: "0,23",
    tw_global: "5,842",
    z: "12,3",
    z_global: "201560,8872",
  },
  {
    profile_section_id: 9618,
    name: "HSS7.5X.188",
    type: "HSS Round",
    designation: "HSSP7.500X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: 4,
    ax_global: "2580,64",
    c: "14,3",
    c_global: "234335,0152",
    i: "26,9",
    i_global: "11196625,35",
    od: "7,5",
    od_global: "190,5",
    tw: "0,17",
    tw_global: "4,318",
    z: "9,34",
    z_global: "153055,1778",
  },
  {
    profile_section_id: 9619,
    name: "HSS7X.500",
    type: "HSS Round",
    designation: "HSSP7X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "9,55",
    ax_global: "6161,278",
    c: "29,3",
    c_global: "480140,9752",
    i: "51,2",
    i_global: "21311048,99",
    od: 7,
    od_global: "177,8",
    tw: "0,47",
    tw_global: "11,938",
    z: "19,9",
    z_global: "326102,5736",
  },
  {
    profile_section_id: 9620,
    name: "HSS7X.375",
    type: "HSS Round",
    designation: "HSSP7X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "7,29",
    ax_global: "4703,2164",
    c: "23,1",
    c_global: "378541,1784",
    i: "40,4",
    i_global: "16815749,59",
    od: 7,
    od_global: "177,8",
    tw: "0,35",
    tw_global: "8,89",
    z: "15,5",
    z_global: "253999,492",
  },
  {
    profile_section_id: 9621,
    name: "HSS7X.312",
    type: "HSS Round",
    designation: "HSSP7X0.312",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "6,13",
    ax_global: "3954,8308",
    c: "19,8",
    c_global: "324463,8672",
    i: "34,6",
    i_global: "14401607,33",
    od: 7,
    od_global: "177,8",
    tw: "0,29",
    tw_global: "7,366",
    z: "13,1",
    z_global: "214670,5384",
  },
  {
    profile_section_id: 9622,
    name: "HSS7X.250",
    type: "HSS Round",
    designation: "HSSP7X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "4,95",
    ax_global: "3193,542",
    c: "16,2",
    c_global: "265470,4368",
    i: "28,4",
    i_global: "11820972,49",
    od: 7,
    od_global: "177,8",
    tw: "0,23",
    tw_global: "5,842",
    z: "10,7",
    z_global: "175341,5848",
  },
  {
    profile_section_id: 9623,
    name: "HSS7X.188",
    type: "HSS Round",
    designation: "HSSP7X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "3,73",
    ax_global: "2406,4468",
    c: "12,4",
    c_global: "203199,5936",
    i: "21,7",
    i_global: "9032221,936",
    od: 7,
    od_global: "177,8",
    tw: "0,17",
    tw_global: "4,318",
    z: "8,11",
    z_global: "132899,089",
  },
  {
    profile_section_id: 9624,
    name: "HSS7X.125",
    type: "HSS Round",
    designation: "HSSP7X0.125",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,51",
    ax_global: "1619,3516",
    c: "8,49",
    c_global: "139126,1734",
    i: "14,9",
    i_global: "6201848,241",
    od: 7,
    od_global: "177,8",
    tw: "0,12",
    tw_global: "3,048",
    z: "5,5",
    z_global: "90128,852",
  },
  {
    profile_section_id: 9625,
    name: "HSS6.875X.500",
    type: "HSS Round",
    designation: "HSSP6.875X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "9,36",
    ax_global: "6038,6976",
    c: "28,1",
    c_global: "460476,4984",
    i: "48,3",
    i_global: "20103977,86",
    od: "6,88",
    od_global: "174,752",
    tw: "0,47",
    tw_global: "11,938",
    z: "19,1",
    z_global: "312992,9224",
  },
  {
    profile_section_id: 9626,
    name: "HSS6.875X.375",
    type: "HSS Round",
    designation: "HSSP6.875X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "7,16",
    ax_global: "4619,3456",
    c: "22,2",
    c_global: "363792,8208",
    i: "38,2",
    i_global: "15900040,46",
    od: "6,88",
    od_global: "174,752",
    tw: "0,35",
    tw_global: "8,89",
    z: "14,9",
    z_global: "244167,2536",
  },
  {
    profile_section_id: 9627,
    name: "HSS6.875X.312",
    type: "HSS Round",
    designation: "HSSP6.875X0.312",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "6,02",
    ax_global: "3883,8632",
    c: 19,
    c_global: "311354,216",
    i: "32,7",
    i_global: "13610767,62",
    od: "6,88",
    od_global: "174,752",
    tw: "0,29",
    tw_global: "7,366",
    z: "12,6",
    z_global: "206477,0064",
  },
  {
    profile_section_id: 9628,
    name: "HSS6.875X.250",
    type: "HSS Round",
    designation: "HSSP6.875X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "4,86",
    ax_global: "3135,4776",
    c: "15,6",
    c_global: "255638,1984",
    i: "26,8",
    i_global: "11155002,21",
    od: "6,88",
    od_global: "174,752",
    tw: "0,23",
    tw_global: "5,842",
    z: "10,3",
    z_global: "168786,7592",
  },
  {
    profile_section_id: 9629,
    name: "HSS6.875X.188",
    type: "HSS Round",
    designation: "HSSP6.875X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "3,66",
    ax_global: "2361,2856",
    c: 12,
    c_global: "196644,768",
    i: "20,6",
    i_global: "8574367,367",
    od: "6,88",
    od_global: "174,752",
    tw: "0,17",
    tw_global: "4,318",
    z: "7,81",
    z_global: "127982,9698",
  },
  {
    profile_section_id: 9630,
    name: "HSS6.625X.500",
    type: "HSS Round",
    designation: "HSSP6.625X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: 9,
    ax_global: "5806,44",
    c: "25,9",
    c_global: "424424,9576",
    i: "42,9",
    i_global: "17856328,16",
    od: "6,63",
    od_global: "168,402",
    tw: "0,47",
    tw_global: "11,938",
    z: "17,7",
    z_global: "290051,0328",
  },
  {
    profile_section_id: 9631,
    name: "HSS6.625X.432",
    type: "HSS Round",
    designation: "HSSP6.625X0.432",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "7,86",
    ax_global: "5070,9576",
    c: "23,1",
    c_global: "378541,1784",
    i: "38,2",
    i_global: "15900040,46",
    od: "6,63",
    od_global: "168,402",
    tw: "0,4",
    tw_global: "10,16",
    z: "15,6",
    z_global: "255638,1984",
  },
  {
    profile_section_id: 9632,
    name: "HSS6.625X.375",
    type: "HSS Round",
    designation: "HSSP6.625X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "6,88",
    ax_global: "4438,7008",
    c: "20,5",
    c_global: "335934,812",
    i: 34,
    i_global: "14151868,47",
    od: "6,63",
    od_global: "168,402",
    tw: "0,35",
    tw_global: "8,89",
    z: "13,8",
    z_global: "226141,4832",
  },
  {
    profile_section_id: 9633,
    name: "HSS6.625X.312",
    type: "HSS Round",
    designation: "HSSP6.625X0.312",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "5,79",
    ax_global: "3735,4764",
    c: "17,6",
    c_global: "288412,3264",
    i: "29,1",
    i_global: "12112334,48",
    od: "6,63",
    od_global: "168,402",
    tw: "0,29",
    tw_global: "7,366",
    z: "11,7",
    z_global: "191728,6488",
  },
  {
    profile_section_id: 9634,
    name: "HSS6.625X.280",
    type: "HSS Round",
    designation: "HSSP6.625X0.28",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "5,2",
    ax_global: "3354,832",
    c: "15,9",
    c_global: "260554,3176",
    i: "26,4",
    i_global: "10988509,64",
    od: "6,63",
    od_global: "168,402",
    tw: "0,26",
    tw_global: "6,604",
    z: "10,5",
    z_global: "172064,172",
  },
  {
    profile_section_id: 9635,
    name: "HSS6.625X.250",
    type: "HSS Round",
    designation: "HSSP6.625X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "4,68",
    ax_global: "3019,3488",
    c: "14,4",
    c_global: "235973,7216",
    i: "23,9",
    i_global: "9947931,072",
    od: "6,63",
    od_global: "168,402",
    tw: "0,23",
    tw_global: "5,842",
    z: "9,52",
    z_global: "156004,8493",
  },
  {
    profile_section_id: 9636,
    name: "HSS6.625X.188",
    type: "HSS Round",
    designation: "HSSP6.625X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "3,53",
    ax_global: "2277,4148",
    c: "11,1",
    c_global: "181896,4104",
    i: "18,4",
    i_global: "7658658,231",
    od: "6,63",
    od_global: "168,402",
    tw: "0,17",
    tw_global: "4,318",
    z: "7,24",
    z_global: "118642,3434",
  },
  {
    profile_section_id: 9637,
    name: "HSS6.625X.125",
    type: "HSS Round",
    designation: "HSSP6.625X0.125",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,37",
    ax_global: "1529,0292",
    c: "7,59",
    c_global: "124377,8158",
    i: "12,6",
    i_global: "5244515,963",
    od: "6,63",
    od_global: "168,402",
    tw: "0,12",
    tw_global: "3,048",
    z: "4,92",
    z_global: "80624,35488",
  },
  {
    profile_section_id: 9638,
    name: "HSS6X.500",
    type: "HSS Round",
    designation: "HSSP6X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "8,09",
    ax_global: "5219,3444",
    c: "20,8",
    c_global: "340850,9312",
    i: "31,2",
    i_global: "12986420,48",
    od: 6,
    od_global: "152,4",
    tw: "0,47",
    tw_global: "11,938",
    z: "14,3",
    z_global: "234335,0152",
  },
  {
    profile_section_id: 9639,
    name: "HSS6X.375",
    type: "HSS Round",
    designation: "HSSP6X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "6,2",
    ax_global: "3999,992",
    c: "16,6",
    c_global: "272025,2624",
    i: "24,8",
    i_global: "10322539,35",
    od: 6,
    od_global: "152,4",
    tw: "0,35",
    tw_global: "8,89",
    z: "11,2",
    z_global: "183535,1168",
  },
  {
    profile_section_id: 9640,
    name: "HSS6X.312",
    type: "HSS Round",
    designation: "HSSP6X0.312",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "5,22",
    ax_global: "3367,7352",
    c: "14,2",
    c_global: "232696,3088",
    i: "21,3",
    i_global: "8865729,365",
    od: 6,
    od_global: "152,4",
    tw: "0,29",
    tw_global: "7,366",
    z: "9,49",
    z_global: "155513,2374",
  },
  {
    profile_section_id: 9641,
    name: "HSS6X.280",
    type: "HSS Round",
    designation: "HSSP6X0.28",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "4,69",
    ax_global: "3025,8004",
    c: "12,9",
    c_global: "211393,1256",
    i: "19,3",
    i_global: "8033266,514",
    od: 6,
    od_global: "152,4",
    tw: "0,26",
    tw_global: "6,604",
    z: "8,57",
    z_global: "140437,1385",
  },
  {
    profile_section_id: 9642,
    name: "HSS6X.250",
    type: "HSS Round",
    designation: "HSSP6X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "4,22",
    ax_global: "2722,5752",
    c: "11,7",
    c_global: "191728,6488",
    i: "17,6",
    i_global: "7325673,091",
    od: 6,
    od_global: "152,4",
    tw: "0,23",
    tw_global: "5,842",
    z: "7,75",
    z_global: "126999,746",
  },
  {
    profile_section_id: 9643,
    name: "HSS6X.188",
    type: "HSS Round",
    designation: "HSSP6X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "3,18",
    ax_global: "2051,6088",
    c: "9,02",
    c_global: "147811,3173",
    i: "13,5",
    i_global: "5619124,246",
    od: 6,
    od_global: "152,4",
    tw: "0,17",
    tw_global: "4,318",
    z: "5,91",
    z_global: "96847,54824",
  },
  {
    profile_section_id: 9644,
    name: "HSS6X.125",
    type: "HSS Round",
    designation: "HSSP6X0.125",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,14",
    ax_global: "1380,6424",
    c: "6,19",
    c_global: "101435,9262",
    i: "9,28",
    i_global: "3862627,63",
    od: 6,
    od_global: "152,4",
    tw: "0,12",
    tw_global: "3,048",
    z: "4,02",
    z_global: "65875,99728",
  },
  {
    profile_section_id: 9645,
    name: "HSS5.563X.500",
    type: "HSS Round",
    designation: "HSSP5.563X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "7,45",
    ax_global: "4806,442",
    c: "17,5",
    c_global: "286773,62",
    i: "24,4",
    i_global: "10156046,78",
    od: "5,56",
    od_global: "141,224",
    tw: "0,47",
    tw_global: "11,938",
    z: "12,1",
    z_global: "198283,4744",
  },
  {
    profile_section_id: 9646,
    name: "HSS5.563X.375",
    type: "HSS Round",
    designation: "HSSP5.563X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "5,72",
    ax_global: "3690,3152",
    c: 14,
    c_global: "229418,896",
    i: "19,5",
    i_global: "8116512,799",
    od: "5,56",
    od_global: "141,224",
    tw: "0,35",
    tw_global: "8,89",
    z: "9,5",
    z_global: "155677,108",
  },
  {
    profile_section_id: 9647,
    name: "HSS5.563X.258",
    type: "HSS Round",
    designation: "HSSP5.563X0.258",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "4,01",
    ax_global: "2587,0916",
    c: "10,2",
    c_global: "167148,0528",
    i: "14,2",
    i_global: "5910486,244",
    od: "5,56",
    od_global: "141,224",
    tw: "0,24",
    tw_global: "6,096",
    z: "6,8",
    z_global: "111432,0352",
  },
  {
    profile_section_id: 9648,
    name: "HSS5.563X.188",
    type: "HSS Round",
    designation: "HSSP5.563X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,95",
    ax_global: "1903,222",
    c: "7,7",
    c_global: "126180,3928",
    i: "10,7",
    i_global: "4453676,254",
    od: "5,56",
    od_global: "141,224",
    tw: "0,17",
    tw_global: "4,318",
    z: "5,05",
    z_global: "82754,6732",
  },
  {
    profile_section_id: 9649,
    name: "HSS5.563X.134",
    type: "HSS Round",
    designation: "HSSP5.563X0.134",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,12",
    ax_global: "1367,7392",
    c: "5,64",
    c_global: "92423,04096",
    i: "7,84",
    i_global: "3263254,377",
    od: "5,56",
    od_global: "141,224",
    tw: "0,12",
    tw_global: "3,048",
    z: "3,67",
    z_global: "60140,52488",
  },
  {
    profile_section_id: 9650,
    name: "HSS5.5X.500",
    type: "HSS Round",
    designation: "HSSP5.500X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "7,36",
    ax_global: "4748,3776",
    c: "17,1",
    c_global: "280218,7944",
    i: "23,5",
    i_global: "9781438,502",
    od: "5,5",
    od_global: "139,7",
    tw: "0,47",
    tw_global: "11,938",
    z: "11,8",
    z_global: "193367,3552",
  },
  {
    profile_section_id: 9651,
    name: "HSS5.5X.375",
    type: "HSS Round",
    designation: "HSSP5.500X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "5,65",
    ax_global: "3645,154",
    c: "13,7",
    c_global: "224502,7768",
    i: "18,8",
    i_global: "7825150,801",
    od: "5,5",
    od_global: "139,7",
    tw: "0,35",
    tw_global: "8,89",
    z: "9,27",
    z_global: "151908,0833",
  },
  {
    profile_section_id: 9652,
    name: "HSS5.5X.258",
    type: "HSS Round",
    designation: "HSSP5.500X0.258",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "3,97",
    ax_global: "2561,2852",
    c: 10,
    c_global: "163870,64",
    i: "13,7",
    i_global: "5702370,531",
    od: "5,5",
    od_global: "139,7",
    tw: "0,24",
    tw_global: "6,096",
    z: "6,64",
    z_global: "108810,105",
  },
  {
    profile_section_id: 9653,
    name: "HSS5X.500",
    type: "HSS Round",
    designation: "HSSP5X0.5",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "6,62",
    ax_global: "4270,9592",
    c: "13,8",
    c_global: "226141,4832",
    i: "17,2",
    i_global: "7159180,52",
    od: 5,
    od_global: 127,
    tw: "0,47",
    tw_global: "11,938",
    z: "9,6",
    z_global: "157315,8144",
  },
  {
    profile_section_id: 9654,
    name: "HSS5X.375",
    type: "HSS Round",
    designation: "HSSP5X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "5,1",
    ax_global: "3290,316",
    c: "11,1",
    c_global: "181896,4104",
    i: "13,9",
    i_global: "5785616,816",
    od: 5,
    od_global: 127,
    tw: "0,35",
    tw_global: "8,89",
    z: "7,56",
    z_global: "123886,2038",
  },
  {
    profile_section_id: 9655,
    name: "HSS5X.312",
    type: "HSS Round",
    designation: "HSSP5X0.312",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "4,3",
    ax_global: "2774,188",
    c: "9,58",
    c_global: "156988,0731",
    i: 12,
    i_global: "4994777,107",
    od: 5,
    od_global: 127,
    tw: "0,29",
    tw_global: "7,366",
    z: "6,46",
    z_global: "105860,4334",
  },
  {
    profile_section_id: 9656,
    name: "HSS5X.258",
    type: "HSS Round",
    designation: "HSSP5X0.258",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "3,59",
    ax_global: "2316,1244",
    c: "8,15",
    c_global: "133554,5716",
    i: "10,2",
    i_global: "4245560,541",
    od: 5,
    od_global: 127,
    tw: "0,24",
    tw_global: "6,096",
    z: "5,44",
    z_global: "89145,62816",
  },
  {
    profile_section_id: 9657,
    name: "HSS5X.250",
    type: "HSS Round",
    designation: "HSSP5X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "3,49",
    ax_global: "2251,6084",
    c: "7,95",
    c_global: "130277,1588",
    i: "9,94",
    i_global: "4137340,37",
    od: 5,
    od_global: 127,
    tw: "0,23",
    tw_global: "5,842",
    z: "5,3",
    z_global: "86851,4392",
  },
  {
    profile_section_id: 9658,
    name: "HSS5X.188",
    type: "HSS Round",
    designation: "HSSP5X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,64",
    ax_global: "1703,2224",
    c: "6,15",
    c_global: "100780,4436",
    i: "7,69",
    i_global: "3200819,663",
    od: 5,
    od_global: 127,
    tw: "0,17",
    tw_global: "4,318",
    z: "4,05",
    z_global: "66367,6092",
  },
  {
    profile_section_id: 9659,
    name: "HSS5X.125",
    type: "HSS Round",
    designation: "HSSP5X0.125",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,78",
    ax_global: "1148,3848",
    c: "4,25",
    c_global: "69645,022",
    i: "5,31",
    i_global: "2210188,87",
    od: 5,
    od_global: 127,
    tw: "0,12",
    tw_global: "3,048",
    z: "2,77",
    z_global: "45392,16728",
  },
  {
    profile_section_id: 9660,
    name: "HSS4.5X.375",
    type: "HSS Round",
    designation: "HSSP4.500X0.375",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "4,55",
    ax_global: "2935,478",
    c: "8,78",
    c_global: "143878,4219",
    i: "9,87",
    i_global: "4108204,171",
    od: "4,5",
    od_global: "114,3",
    tw: "0,35",
    tw_global: "8,89",
    z: "6,03",
    z_global: "98813,99592",
  },
  {
    profile_section_id: 9661,
    name: "HSS4.5X.337",
    type: "HSS Round",
    designation: "HSSP4.500X0.337",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "4,12",
    ax_global: "2658,0592",
    c: "8,06",
    c_global: "132079,7358",
    i: "9,07",
    i_global: "3775219,03",
    od: "4,5",
    od_global: "114,3",
    tw: "0,31",
    tw_global: "7,874",
    z: "5,5",
    z_global: "90128,852",
  },
  {
    profile_section_id: 9662,
    name: "HSS4.5X.237",
    type: "HSS Round",
    designation: "HSSP4.500X0.237",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,96",
    ax_global: "1909,6736",
    c: "6,04",
    c_global: "98977,86656",
    i: "6,79",
    i_global: "2826211,38",
    od: "4,5",
    od_global: "114,3",
    tw: "0,22",
    tw_global: "5,588",
    z: "4,03",
    z_global: "66039,86792",
  },
  {
    profile_section_id: 9663,
    name: "HSS4.5X.188",
    type: "HSS Round",
    designation: "HSSP4.500X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,36",
    ax_global: "1522,5776",
    c: "4,93",
    c_global: "80788,22552",
    i: "5,54",
    i_global: "2305922,098",
    od: "4,5",
    od_global: "114,3",
    tw: "0,17",
    tw_global: "4,318",
    z: "3,26",
    z_global: "53421,82864",
  },
  {
    profile_section_id: 9664,
    name: "HSS4.5X.125",
    type: "HSS Round",
    designation: "HSSP4.500X0.125",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,6",
    ax_global: "1032,256",
    c: "3,41",
    c_global: "55879,88824",
    i: "3,84",
    i_global: "1598328,674",
    od: "4,5",
    od_global: "114,3",
    tw: "0,12",
    tw_global: "3,048",
    z: "2,23",
    z_global: "36543,15272",
  },
  {
    profile_section_id: 9665,
    name: "HSS4X.313",
    type: "HSS Round",
    designation: "HSSP4X0.313",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "3,39",
    ax_global: "2187,0924",
    c: "5,87",
    c_global: "96192,06568",
    i: "5,87",
    i_global: "2443278,468",
    od: 4,
    od_global: "101,6",
    tw: "0,29",
    tw_global: "7,366",
    z: "4,01",
    z_global: "65712,12664",
  },
  {
    profile_section_id: 9666,
    name: "HSS4X.250",
    type: "HSS Round",
    designation: "HSSP4X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,76",
    ax_global: "1780,6416",
    c: "4,91",
    c_global: "80460,48424",
    i: "4,91",
    i_global: "2043696,3",
    od: 4,
    od_global: "101,6",
    tw: "0,23",
    tw_global: "5,842",
    z: "3,31",
    z_global: "54241,18184",
  },
  {
    profile_section_id: 9667,
    name: "HSS4X.237",
    type: "HSS Round",
    designation: "HSSP4X0.237",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,61",
    ax_global: "1683,8676",
    c: "4,68",
    c_global: "76691,45952",
    i: "4,68",
    i_global: "1947963,072",
    od: 4,
    od_global: "101,6",
    tw: "0,22",
    tw_global: "5,588",
    z: "3,15",
    z_global: "51619,2516",
  },
  {
    profile_section_id: 9668,
    name: "HSS4X.226",
    type: "HSS Round",
    designation: "HSSP4X0.226",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,5",
    ax_global: "1612,9",
    c: "4,5",
    c_global: "73741,788",
    i: "4,5",
    i_global: "1873041,415",
    od: 4,
    od_global: "101,6",
    tw: "0,21",
    tw_global: "5,334",
    z: "3,02",
    z_global: "49488,93328",
  },
  {
    profile_section_id: 9669,
    name: "HSS4X.220",
    type: "HSS Round",
    designation: "HSSP4X0.22",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,44",
    ax_global: "1574,1904",
    c: "4,41",
    c_global: "72266,95224",
    i: "4,41",
    i_global: "1835580,587",
    od: 4,
    od_global: "101,6",
    tw: "0,2",
    tw_global: "5,08",
    z: "2,96",
    z_global: "48505,70944",
  },
  {
    profile_section_id: 9670,
    name: "HSS4X.188",
    type: "HSS Round",
    designation: "HSSP4X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,09",
    ax_global: "1348,3844",
    c: "3,83",
    c_global: "62762,45512",
    i: "3,83",
    i_global: "1594166,36",
    od: 4,
    od_global: "101,6",
    tw: "0,17",
    tw_global: "4,318",
    z: "2,55",
    z_global: "41787,0132",
  },
  {
    profile_section_id: 9671,
    name: "HSS4X.125",
    type: "HSS Round",
    designation: "HSSP4X0.125",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,42",
    ax_global: "916,1272",
    c: "2,67",
    c_global: "43753,46088",
    i: "2,67",
    i_global: "1111337,906",
    od: 4,
    od_global: "101,6",
    tw: "0,12",
    tw_global: "3,048",
    z: "1,75",
    z_global: "28677,362",
  },
  {
    profile_section_id: 9672,
    name: "HSS3.5X.313",
    type: "HSS Round",
    designation: "HSSP3.500X0.313",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,93",
    ax_global: "1890,3188",
    c: "4,35",
    c_global: "71283,7284",
    i: "3,81",
    i_global: "1585841,732",
    od: "3,5",
    od_global: "88,9",
    tw: "0,29",
    tw_global: "7,366",
    z: 3,
    z_global: "49161,192",
  },
  {
    profile_section_id: 9673,
    name: "HSS3.5X.300",
    type: "HSS Round",
    designation: "HSSP3.500X0.3",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,82",
    ax_global: "1819,3512",
    c: "4,22",
    c_global: "69153,41008",
    i: "3,69",
    i_global: "1535893,96",
    od: "3,5",
    od_global: "88,9",
    tw: "0,28",
    tw_global: "7,112",
    z: "2,9",
    z_global: "47522,4856",
  },
  {
    profile_section_id: 9674,
    name: "HSS3.5X.250",
    type: "HSS Round",
    designation: "HSSP3.500X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,39",
    ax_global: "1541,9324",
    c: "3,66",
    c_global: "59976,65424",
    i: "3,21",
    i_global: "1336102,876",
    od: "3,5",
    od_global: "88,9",
    tw: "0,23",
    tw_global: "5,842",
    z: "2,49",
    z_global: "40803,78936",
  },
  {
    profile_section_id: 9675,
    name: "HSS3.5X.216",
    type: "HSS Round",
    designation: "HSSP3.500X0.216",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,08",
    ax_global: "1341,9328",
    c: "3,25",
    c_global: "53257,958",
    i: "2,84",
    i_global: "1182097,249",
    od: "3,5",
    od_global: "88,9",
    tw: "0,2",
    tw_global: "5,08",
    z: "2,19",
    z_global: "35887,67016",
  },
  {
    profile_section_id: 9676,
    name: "HSS3.5X.203",
    type: "HSS Round",
    designation: "HSSP3.500X0.203",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,97",
    ax_global: "1270,9652",
    c: "3,09",
    c_global: "50636,02776",
    i: "2,7",
    i_global: "1123824,849",
    od: "3,5",
    od_global: "88,9",
    tw: "0,19",
    tw_global: "4,826",
    z: "2,07",
    z_global: "33921,22248",
  },
  {
    profile_section_id: 9677,
    name: "HSS3.5X.188",
    type: "HSS Round",
    designation: "HSSP3.500X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,82",
    ax_global: "1174,1912",
    c: "2,88",
    c_global: "47194,74432",
    i: "2,52",
    i_global: "1048903,193",
    od: "3,5",
    od_global: "88,9",
    tw: "0,17",
    tw_global: "4,318",
    z: "1,93",
    z_global: "31627,03352",
  },
  {
    profile_section_id: 9678,
    name: "HSS3.5X.125",
    type: "HSS Round",
    designation: "HSSP3.500X0.125",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,23",
    ax_global: "793,5468",
    c: "2,02",
    c_global: "33101,86928",
    i: "1,77",
    i_global: "736729,6233",
    od: "3,5",
    od_global: "88,9",
    tw: "0,12",
    tw_global: "3,048",
    z: "1,33",
    z_global: "21794,79512",
  },
  {
    profile_section_id: 9679,
    name: "HSS3X.250",
    type: "HSS Round",
    designation: "HSSP3X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "2,03",
    ax_global: "1309,6748",
    c: "2,6",
    c_global: "42606,3664",
    i: "1,95",
    i_global: "811651,2799",
    od: 3,
    od_global: "76,2",
    tw: "0,23",
    tw_global: "5,842",
    z: "1,79",
    z_global: "29332,84456",
  },
  {
    profile_section_id: 9680,
    name: "HSS3X.216",
    type: "HSS Round",
    designation: "HSSP3X0.216",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,77",
    ax_global: "1141,9332",
    c: "2,32",
    c_global: "38017,98848",
    i: "1,74",
    i_global: "724242,6805",
    od: 3,
    od_global: "76,2",
    tw: "0,2",
    tw_global: "5,08",
    z: "1,58",
    z_global: "25891,56112",
  },
  {
    profile_section_id: 9681,
    name: "HSS3X.203",
    type: "HSS Round",
    designation: "HSSP3X0.203",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,67",
    ax_global: "1077,4172",
    c: "2,21",
    c_global: "36215,41144",
    i: "1,66",
    i_global: "690944,1665",
    od: 3,
    od_global: "76,2",
    tw: "0,19",
    tw_global: "4,826",
    z: "1,5",
    z_global: "24580,596",
  },
  {
    profile_section_id: 9682,
    name: "HSS3X.188",
    type: "HSS Round",
    designation: "HSSP3X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,54",
    ax_global: "993,5464",
    c: "2,06",
    c_global: "33757,35184",
    i: "1,55",
    i_global: "645158,7097",
    od: 3,
    od_global: "76,2",
    tw: "0,17",
    tw_global: "4,318",
    z: "1,39",
    z_global: "22778,01896",
  },
  {
    profile_section_id: 9683,
    name: "HSS3X.152",
    type: "HSS Round",
    designation: "HSSP3X0.152",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,27",
    ax_global: "819,3532",
    c: "1,73",
    c_global: "28349,62072",
    i: "1,3",
    i_global: "541100,8533",
    od: 3,
    od_global: "76,2",
    tw: "0,14",
    tw_global: "3,556",
    z: "1,15",
    z_global: "18845,1236",
  },
  {
    profile_section_id: 9684,
    name: "HSS3X.134",
    type: "HSS Round",
    designation: "HSSP3X0.134",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,12",
    ax_global: "722,5792",
    c: "1,55",
    c_global: "25399,9492",
    i: "1,16",
    i_global: "482828,4537",
    od: 3,
    od_global: "76,2",
    tw: "0,12",
    tw_global: "3,048",
    z: "1,03",
    z_global: "16878,67592",
  },
  {
    profile_section_id: 9685,
    name: "HSS3X.125",
    type: "HSS Round",
    designation: "HSSP3X0.125",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,05",
    ax_global: "677,418",
    c: "1,46",
    c_global: "23925,11344",
    i: "1,09",
    i_global: "453692,2539",
    od: 3,
    od_global: "76,2",
    tw: "0,12",
    tw_global: "3,048",
    z: "0,96",
    z_global: "15731,58144",
  },
  {
    profile_section_id: 9686,
    name: "HSS2.875X.250",
    type: "HSS Round",
    designation: "HSSP2.875X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,93",
    ax_global: "1245,1588",
    c: "2,37",
    c_global: "38837,34168",
    i: "1,7",
    i_global: "707593,4235",
    od: "2,88",
    od_global: "73,152",
    tw: "0,23",
    tw_global: "5,842",
    z: "1,63",
    z_global: "26710,91432",
  },
  {
    profile_section_id: 9687,
    name: "HSS2.875X.203",
    type: "HSS Round",
    designation: "HSSP2.875X0.203",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,59",
    ax_global: "1025,8044",
    c: "2,01",
    c_global: "32937,99864",
    i: "1,45",
    i_global: "603535,5671",
    od: "2,88",
    od_global: "73,152",
    tw: "0,19",
    tw_global: "4,826",
    z: "1,37",
    z_global: "22450,27768",
  },
  {
    profile_section_id: 9688,
    name: "HSS2.875X.188",
    type: "HSS Round",
    designation: "HSSP2.875X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,48",
    ax_global: "954,8368",
    c: "1,88",
    c_global: "30807,68032",
    i: "1,35",
    i_global: "561912,4246",
    od: "2,88",
    od_global: "73,152",
    tw: "0,17",
    tw_global: "4,318",
    z: "1,27",
    z_global: "20811,57128",
  },
  {
    profile_section_id: 9689,
    name: "HSS2.875X.125",
    type: "HSS Round",
    designation: "HSSP2.875X0.125",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,01",
    ax_global: "651,6116",
    c: "1,33",
    c_global: "21794,79512",
    i: "0,96",
    i_global: "399582,1686",
    od: "2,88",
    od_global: "73,152",
    tw: "0,12",
    tw_global: "3,048",
    z: "0,88",
    z_global: "14420,61632",
  },
  {
    profile_section_id: 9690,
    name: "HSS2.5X.250",
    type: "HSS Round",
    designation: "HSSP2.500X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,66",
    ax_global: "1070,9656",
    c: "1,72",
    c_global: "28185,75008",
    i: "1,08",
    i_global: "449529,9396",
    od: "2,5",
    od_global: "63,5",
    tw: "0,23",
    tw_global: "5,842",
    z: "1,2",
    z_global: "19664,4768",
  },
  {
    profile_section_id: 9691,
    name: "HSS2.5X.188",
    type: "HSS Round",
    designation: "HSSP2.500X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,27",
    ax_global: "819,3532",
    c: "1,38",
    c_global: "22614,14832",
    i: "0,86",
    i_global: "357959,026",
    od: "2,5",
    od_global: "63,5",
    tw: "0,17",
    tw_global: "4,318",
    z: "0,94",
    z_global: "15403,84016",
  },
  {
    profile_section_id: 9692,
    name: "HSS2.5X.125",
    type: "HSS Round",
    designation: "HSSP2.500X0.125",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "0,87",
    ax_global: "561,2892",
    c: "0,99",
    c_global: "16223,19336",
    i: "0,62",
    i_global: "258063,4839",
    od: "2,5",
    od_global: "63,5",
    tw: "0,12",
    tw_global: "3,048",
    z: "0,66",
    z_global: "10815,46224",
  },
  {
    profile_section_id: 9693,
    name: "HSS2.375X.250",
    type: "HSS Round",
    designation: "HSSP2.375X0.25",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,57",
    ax_global: "1012,9012",
    c: "1,53",
    c_global: "25072,20792",
    i: "0,91",
    i_global: "378770,5973",
    od: "2,38",
    od_global: "60,452",
    tw: "0,23",
    tw_global: "5,842",
    z: "1,07",
    z_global: "17534,15848",
  },
  {
    profile_section_id: 9694,
    name: "HSS2.375X.218",
    type: "HSS Round",
    designation: "HSSP2.375X0.218",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,39",
    ax_global: "896,7724",
    c: "1,39",
    c_global: "22778,01896",
    i: "0,82",
    i_global: "341309,769",
    od: "2,38",
    od_global: "60,452",
    tw: "0,2",
    tw_global: "5,08",
    z: "0,96",
    z_global: "15731,58144",
  },
  {
    profile_section_id: 9695,
    name: "HSS2.375X.188",
    type: "HSS Round",
    designation: "HSSP2.375X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "1,2",
    ax_global: "774,192",
    c: "1,23",
    c_global: "20156,08872",
    i: "0,73",
    i_global: "303848,9407",
    od: "2,38",
    od_global: "60,452",
    tw: "0,17",
    tw_global: "4,318",
    z: "0,84",
    z_global: "13765,13376",
  },
  {
    profile_section_id: 9696,
    name: "HSS2.375X.154",
    type: "HSS Round",
    designation: "HSSP2.375X0.154",
    shape: "HSS ROUND",
    country_code: "American",
    ax: 1,
    ax_global: "645,16",
    c: "1,06",
    c_global: "17370,28784",
    i: "0,63",
    i_global: "262225,7981",
    od: "2,38",
    od_global: "60,452",
    tw: "0,14",
    tw_global: "3,556",
    z: "0,71",
    z_global: "11634,81544",
  },
  {
    profile_section_id: 9697,
    name: "HSS2.375X.125",
    type: "HSS Round",
    designation: "HSSP2.375X0.125",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "0,82",
    ax_global: "529,0312",
    c: "0,89",
    c_global: "14584,48696",
    i: "0,53",
    i_global: "220602,6556",
    od: "2,38",
    od_global: "60,452",
    tw: "0,12",
    tw_global: "3,048",
    z: "0,59",
    z_global: "9668,36776",
  },
  {
    profile_section_id: 9698,
    name: "HSS1.9X.188",
    type: "HSS Round",
    designation: "HSSP1.900X0.188",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "0,94",
    ax_global: "606,4504",
    c: "0,75",
    c_global: "12290,298",
    i: "0,35",
    i_global: "145680,999",
    od: "1,9",
    od_global: "48,26",
    tw: "0,17",
    tw_global: "4,318",
    z: "0,52",
    z_global: "8521,27328",
  },
  {
    profile_section_id: 9699,
    name: "HSS1.9X.145",
    type: "HSS Round",
    designation: "HSSP1.900X0.145",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "0,75",
    ax_global: "483,87",
    c: "0,62",
    c_global: "10159,97968",
    i: "0,29",
    i_global: "120707,1134",
    od: "1,9",
    od_global: "48,26",
    tw: "0,14",
    tw_global: "3,556",
    z: "0,42",
    z_global: "6882,56688",
  },
  {
    profile_section_id: 9700,
    name: "HSS1.9X.120",
    type: "HSS Round",
    designation: "HSSP1.900X0.12",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "0,62",
    ax_global: "399,9992",
    c: "0,53",
    c_global: "8685,14392",
    i: "0,25",
    i_global: "104057,8564",
    od: "1,9",
    od_global: "48,26",
    tw: "0,11",
    tw_global: "2,794",
    z: "0,36",
    z_global: "5899,34304",
  },
  {
    profile_section_id: 9701,
    name: "HSS1.66X.140",
    type: "HSS Round",
    designation: "HSSP1.660X0.14",
    shape: "HSS ROUND",
    country_code: "American",
    ax: "0,63",
    ax_global: "406,4508",
    c: "0,44",
    c_global: "7210,30816",
    i: "0,18",
    i_global: "74921,65661",
    od: "1,66",
    od_global: "42,164",
    tw: "0,13",
    tw_global: "3,302",
    z: "0,3",
    z_global: "4916,1192",
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: p.ax ? fixValueToNumber(p.ax, "float") : null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: p.b ? fixValueToNumber(p.b, "float") : null,
    b_global: p.b_global ? fixValueToNumber(p.b_global, "float") : undefined,
    bf: null,
    c: p.c ? fixValueToNumber(p.c, "float") : null,
    c_global: p.c_global ? fixValueToNumber(p.c_global, "float") : undefined,
    ct: null,
    d: p.d ? fixValueToNumber(p.d, "float") : null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: null,
    i: p.i ? fixValueToNumber(p.i, "float") : null,
    i_global: p.i_global ? fixValueToNumber(p.i_global, "float") : undefined,
    ix: p.ix ? fixValueToNumber(p.ix, "float") : null,
    ix_global: p.ix_global ? fixValueToNumber(p.ix_global, "float") : undefined,
    iy: p.iy ? fixValueToNumber(p.iy, "float") : null,
    iy_global: p.iy_global ? fixValueToNumber(p.iy_global, "float") : undefined,
    iz: p.iz ? fixValueToNumber(p.iz, "float") : null,
    iz_global: p.iz_global ? fixValueToNumber(p.iz_global, "float") : undefined,
    k: null,
    k1: null,
    od: p.od ? fixValueToNumber(p.od, "float") : null,
    od_global: p.od_global ? fixValueToNumber(p.od_global, "float") : undefined,
    r1: null,
    r2: null,
    rz: null,
    t: p.t ? fixValueToNumber(p.t, "float") : null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: null,
    tw: p.tw ? fixValueToNumber(p.tw, "float") : null,
    tw_global: p.tw_global ? fixValueToNumber(p.tw_global, "float") : undefined,
    z: p.z ? fixValueToNumber(p.z, "float") : null,
    z_global: p.z_global ? fixValueToNumber(p.z_global, "float") : undefined,
    zx: null,
    zy: p.zy ? fixValueToNumber(p.zy, "float") : null,
    zy_global: p.zy_global ? fixValueToNumber(p.zy_global, "float") : undefined,
    zz: p.zz ? fixValueToNumber(p.zz, "float") : null,
    zz_global: p.zz_global ? fixValueToNumber(p.zz_global, "float") : undefined,
    width: 0,
    width_global: 0,
    thickness: 0,
    thickness_global: 0,
    height: 0,
    height_global: 0,
  };
  return profile;
});
