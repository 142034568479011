/* eslint-disable react/jsx-no-undef */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, FormGroup, InputGroup, Spinner, Switch, Overlay } from "@blueprintjs/core";
import "./PricingPlans.css";
import countryList from "react-select-country-list";


// const stripePromise = loadStripe('pk_test_51O5w67GFmWd66UgV0ZrcRLdQic88kCCCVb27OWcLLKKpZFRI8Y1RdGExl4pOMfugq4CO0mjj9HMomMHVZwmI90aQ007i9jH6Sw');

const stripePromise = loadStripe('pk_live_51NudgsJW9waUS7YnWsw6R87u03k0szPgHFm4f4sBT4pNwAwqgFv49OUOWMVO1IfU9hYZXkWg8XLCk9OzFSsdNQ9b00IKqDkYmL');

const plans = [
  { name: 'Enterprise Pro', price: 200, credit: true, features: ['Upload Files','Share Files','Convert Files','View Scans','View Converted Files','3D modelling','250 credits*','*From 5GB to 10GB'] },
  { name: 'Individual', price: 25, credit: true, features: ['Upload Files','Share Files','Convert Files','View Scans','View Converted Files','3D modelling','25 credits*','Up to 150MB'] },
  { name: 'Standard', price: 35, credit: true, features: ['Upload Files','Share Files','Convert Files','View Scans','View Converted Files','3D modelling','50 credits*','From 150MB to 250MB'] },
  { name: 'Business Pro', price: 65, credit: true, features: ['Upload Files','Share Files','Convert Files','View Scans','View Converted Files','3D modelling','100 credits*','From 250MB to 500MB'] },
  { name: 'Enterprise', price: 125, credit: true, features: ['Upload Files','Share Files','Convert Files','View Scans','View Converted Files','3D modelling','150 credits*','From 500MB to 2GB'] },
  { name: 'Business Gold Pro', price: 175, credit: true, features: ['Upload Files','Share Files','Convert Files','View Scans','View Converted Files','3D modelling','200 credits*','From 2GB to 5GB'], highlight: true },
  { name: 'Enterprise Silver', price: 250, credit: false, features: ['Upload Files','Share Files','Convert Files','View Scans','View Converted Files','3D modelling','300 credits*','*From 10GB to 20GB'] },
  { name: 'Enterprise Gold', price: 275, credit: true, features: ['Upload Files','Share Files','Convert Files','View Scans','View Converted Files','3D modelling','750 credits*','Greater than 20GB'] },
  { name: 'Enterprise Platinum', price: 500, credit: true, features: ['Upload Files','Share Files','Convert Files','View Scans','View Converted Files','3D modelling','1000 credits*','Greater than 20GB'] },
  { name: 'Resellers Pro', price: 200, credit: true, features: ['Upload Files','Share Files','Convert Files','View Scans','View Converted Files','3D modelling','250 credits*','*From 5GB to 10GB'] },
];

const PricingPlans = () => {
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  const [email, setEmail] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(null);
  const [licenseneeded, setLicenseneeded] = useState<number>();
  const [assetNumber, setAssetNumber] = useState<number>(0);
  const [area, setArea] = useState<number>();
  const [areaSqFeet, setAreaSqFeet] = useState<number>();
  const [dpi,setDPI] = useState<number>(0);
  const [fileSize, setFileSize] = useState<number>(0);
  const [highlightedPlan,sethighlightedPlan] = useState<Boolean>(false);
  const [isChecked, setIsChecked] = useState<Boolean>(false);
  const [mhr, setMhr] = useState<number>(0);
  const [meh, setMeh] = useState<number>(0);
  const [meh2, setMeh2] = useState<number>(0);
  const [noScans, setNoScans] = useState<number>(0);
  const [noResources, setNoResources] = useState<number>();
  const [noModels, setNoModels] = useState<number>();
  const [costPerScan, setCostPerScan] = useState<number>();
  const [totalResourceRequired, setTotalResourcesRequired] = useState<Number>();
  const [country, setCountry] = useState('');
  const [rotating,setRotating] = useState<Boolean>(false);
  const [remainingAssets, setRemainingAssets] = useState<number>(0);
  const [tcl, setTcl] = useState<number>(0);
  const [capacity, setCapacity] = useState<number>();
  const [manpower, setManpower] = useState<number>();
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalcostComp, setTotalCostComp] = useState<number>(0);
  const [otherLicenseCost, setOtherLicenseCost] = useState<number>(0);

  const [isOpen, setIsOpen] = useState(false);
  const [isComparison, setIsComparison] = useState(false);
  const toggleOverlay = useCallback(() => setIsOpen(open => !open), [setIsOpen]);
  const toggleOverlay2 = useCallback(() => setIsComparison(open => !open), [setIsComparison]);

  const OPTIONS = [
    { label: "Recap ($50)", value: 50 },
    { label: "Revit ($365)", value: 365 },
    { label: "BIM 360 ($40)", value: 40 }
  ];

  const handleCheckboxChange = (item) => {
    setSelectedItems((current) => {
      const isSelected = current.includes(item);
      const updatedSelection = isSelected
        ? current.filter((i) => i !== item) 
        : [...current, item]; 

      const updatedTcl = updatedSelection.reduce((total, selectedItem) => {
        const option = OPTIONS.find((opt) => opt.label === selectedItem);
        return option ? total + option.value : total;
      }, 0);

      setTcl(updatedTcl);
      return updatedSelection;
    });
  };

  useEffect(() => {
    const updatedTcl = selectedItems.reduce((total, selectedItem) => {
      const option = OPTIONS.find((opt) => opt.label === selectedItem);
      return option ? total + option.value : total;
    }, 0) + otherLicenseCost; 

    setTcl(updatedTcl);
  }, [selectedItems, otherLicenseCost]);

  useEffect(() => {
    setTotalCostComp(tcl + (meh2 * mhr));
  }, [tcl, mhr, meh, otherLicenseCost]);

  

  const stripe = useStripe();
  const elements = useElements();

  const SQ_METER_TO_SQ_FEET = 10.7639;

  const handlePlanSelect = (e) => {
    const selectedPlanName = e.target.value;
    const plan = plans.find(p => p.name === selectedPlanName);
    setSelectedPlan(plan);
    sethighlightedPlan(false);
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setIsSubmitting(true);
  //   setMessage('');
    
  //   if (!stripe || !elements) {
  //     setMessage('Stripe has not loaded yet. Please try again.');
  //     setIsSubmitting(false);
  //     return;
  //   }
  
  //   try {
  //     const cardElement = elements.getElement(CardElement);
  //     const { token, error } = await stripe.createToken(cardElement);
  
  //     if (error) {
  //       setMessage(error.message);
  //       setIsSuccess(false);
  //       setIsSubmitting(false);
  //       return;
  //     }
  
  //     const amount = selectedPlan.price;
  //     const credits = parseInt(selectedPlan.features[0].match(/\d+/)[0], 10);
  
  //     const response = await fetch('https://idsufc.asets.io/rest/api/v1/ufc/makePayment', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json', // Ensure this is application/json
  //       },
  //       body: JSON.stringify({
  //         planId: selectedPlan.name,
  //         email,
  //         amount,
  //         credits, 
  //         stripeToken: token.id,
  //         additionalInfo: {
  //           ID_TAG: Math.random().toString(36).substr(2, 9)
  //         }
  //       }),
  //     });

  //     if (!response.ok) {
  //       const errorText = await response.text();
  //       setMessage(`Error: ${errorText || 'Payment failed'}`);
  //       setIsSuccess(false);
  //       setIsSubmitting(false);
  //       return;
  //     }
  
  //     const session = await response.json();
  
  //     if (session.error) {
  //       setMessage(session.error.message);
  //       setIsSuccess(false);
  //     } else {
  //       setIsSuccess(true);
  //       setMessage(`Payment for the ${selectedPlan.name} plan was successful!`);
  //     }
  //   } catch (error) {
  //     setIsSuccess(false);
  //     setMessage('An error occurred. Please try again.');
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessage('');
  
    if (!stripe || !elements) {
      setMessage('Stripe has not loaded yet. Please try again.');
      setIsSubmitting(false);
      return;
    }
  
    try {
      const cardElement = elements.getElement(CardElement);
      const { token, error } = await stripe.createToken(cardElement);
  
      if (error) {
        setMessage(error.message);
        setIsSuccess(false);
        setIsSubmitting(false);
        return;
      }
  
      const amount = selectedPlan.price;
  
      // Extract credits more robustly
      const creditFeature = selectedPlan.features.find(feature => feature.includes('credits'));
      const credits = creditFeature ? parseInt(creditFeature.match(/\d+/)[0], 10) : 0;
  
      // Enhanced debug logs for each value and to verify credit extraction
      console.log("Selected Plan:", selectedPlan);
      console.log("Selected Plan Name:", selectedPlan.name);
      console.log("Selected Plan Price:", amount);
      console.log("Selected Plan Features:", selectedPlan.features);
      console.log("Credit Feature Found:", creditFeature);
      console.log("Credits Extracted:", credits);
      console.log("Email:", email);
      console.log("Stripe Token:", token ? token.id : "Token not created");
  
      const response = await fetch('https://ufc.asets.io/rest/api/v1/ufc/makePayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          planId: selectedPlan.name,
          email,
          amount,
          credits,
          stripeToken: token.id,
          additionalInfo: {
            ID_TAG: Math.random().toString(36).substr(2, 9),
          },
        }),
      });
  
      const responseText = await response.text();
      console.log('Server Response:', responseText);
  
      const session = response.ok ? JSON.parse(responseText) : { error: { message: responseText } };
  
      if (session.error) {
        setMessage(session.error.message);
        setIsSuccess(false);
      } else {
        setIsSuccess(true);
        setMessage(`Payment for the ${selectedPlan.name} plan was successful!`);
      }
    } catch (error) {
      setIsSuccess(false);
      console.log("Caught error in try block:", error);
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };
  
  

  

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setIsSubmitting(true);
  //   setMessage('');
  
  //   if (!stripe || !elements) {
  //     setMessage('Stripe has not loaded yet. Please try again.');
  //     setIsSubmitting(false);
  //     return;
  //   }
  
  //   try {
  //     const cardElement = elements.getElement(CardElement);
  //     const { token, error } = await stripe.createToken(cardElement);
  
  //     if (error) {
  //       setMessage(error.message);
  //       setIsSuccess(false);
  //       setIsSubmitting(false);
  //       return;
  //     }
  
  //     const amount = selectedPlan.price;
  //     const credits = parseInt(selectedPlan.features[0].match(/\d+/)[0], 10);
  
  //     const response = await fetch('https://idsufc.asets.io/rest/api/v1/ufc/makePayment', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         planId: selectedPlan.name,
  //         email,
  //         amount,
  //         credits, 
  //         stripeToken: token.id,
  //         additionalInfo: {
  //           ID_TAG: Math.random().toString(36).substr(2, 9)
  //         }
  //       }),
  //     });
  
  //     const session = await response.json();
  
  //     if (session.error) {
  //       setMessage(session.error.message);
  //       setIsSuccess(false);
  //     } else {
  //       setIsSuccess(true);
  //       setMessage(`Payment for the ${selectedPlan.name} plan was successful!`);
  //     }
  //   } catch (error) {
  //     setIsSuccess(false);
  //     setMessage('An error occurred. Please try again.');
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  const handleSubscriptionSubmit = () => {
    const selectedPlanBasedOnSize = plans.find((plan) => {
      const sizeFeature = plan.features.find(feature =>
        feature.includes("From") || feature.includes("Greater") || feature.includes("Up to")
      );
      
      if (fileSize > 0 && fileSize <= 0.15 && sizeFeature?.includes("Up to 150MB")) return true;
      if (fileSize > 0.15 && fileSize <= 0.25 && sizeFeature?.includes("From 150MB to 250MB")) return true;
      if (fileSize > 0.25 && fileSize <= 0.5 && sizeFeature?.includes("From 250MB to 500MB")) return true;
      if (fileSize > 0.5 && fileSize <= 2 && sizeFeature?.includes("From 500MB to 2GB")) return true;
      if (fileSize > 2 && fileSize <= 5 && sizeFeature?.includes("From 2GB to 5GB")) return true;
      if (fileSize > 5 && fileSize <= 10 && sizeFeature?.includes("From 5GB to 10GB")) return true;
      if (fileSize > 10 && fileSize <= 20 && sizeFeature?.includes("From 10GB to 20GB")) return true;
      if (fileSize > 20 && sizeFeature?.includes("Greater than 20GB")) return true;
      
      return false;
    });
  
    if (!selectedPlanBasedOnSize) {
      alert("No suitable plan found for the specified file size.");
      return;
    }
    setSelectedPlan(selectedPlanBasedOnSize);
  
    let assets = 0;
    if (area < 1000) {
      assets = 15;
    } else if (area >= 1000 && area < 10000){
      assets = 25;
    } else if (area >= 10000 && area < 25000){
      assets = 35;
    } else if (area >= 25000 && area < 50000){
      assets = 50;
    } else if (area >= 50000 && area < 100000) {
      assets = 120;
    } else if (area >= 100000 && area < 500000) {
      assets = 150;
    } else if (area >= 500000 && area < 750000) {
      assets = 200;
    } else if (area >= 750000 && area < 1000000) {
      assets = 250;
    }
  
    setAssetNumber(assets);
  
    const credits = parseInt(
      selectedPlanBasedOnSize.features.find(feature => feature.includes("credits"))?.match(/\d+/)?.[0] || "0",
      10
    );
  
    const requiredLicenses = Math.ceil((assets) / credits);
    setLicenseneeded(requiredLicenses);

    setRemainingAssets(credits-assets)
  
    console.log("this is the number of asset", assets);
    console.log("this is the required licenses", requiredLicenses);
    sethighlightedPlan(true);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDPI(Number(event.target.value));
  };

  const handleSwitchChange = async (event) => {
    setRotating(true); 

    setTimeout(() => {
      setRotating(false); 
      setIsChecked(!isChecked);  
    }, 300); 
  };

  const handleCostSubmit = () => {
    if (mhr > 0 && meh > 0 && noScans > 0) {
        if (!licenseneeded) {
            alert("Please use the estimator to determine the required plan and the number of licenses needed.");
            return;
        }

        const models = (175 / meh);
        setNoModels(models);
        setTotalResourcesRequired(noScans / models);
        setMeh2(meh*6);

        const totalEffortCost = mhr * meh;
        const scanningCost = (selectedPlan.price * licenseneeded);
        setCapacity(175/(meh*6));
        setManpower(noScans/(175/(meh*6)));

        setCostPerScan((totalEffortCost + scanningCost) );
    } else {
        alert("Please ensure all input values are provided and greater than zero.");
    }
};


  const countryOptions = useMemo(() => countryList().getData(), []);

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleAreaSqMetersChange = (event) => {
    const value = Number(event.target.value);
    if (value < 0) {
      alert("Please enter a positive number for area.");
      setArea(0);
      setAreaSqFeet(0);
    } else {
      setArea(value);
      setAreaSqFeet((value * SQ_METER_TO_SQ_FEET).toFixed(3));
    }
  };

  const handleAreaSqFeetChange = (event) => {
    const value = Number(event.target.value);
    if (value < 0) {
      alert("Please enter a positive number for area.");
      setArea(0);
      setAreaSqFeet(0);
    } else {
      setAreaSqFeet(value);
      setArea((value / SQ_METER_TO_SQ_FEET).toFixed(3));
    }
  };
  
  

  return (
    <div className="pricing-page">
    <div className='switch'>
    <div className="switch-text">
        {isChecked ? 'Cost Calculation' : 'Estimator'}
      <Switch 
        className="switch-slider" 
        onChange={handleSwitchChange}
      />
    </div>
    <div className={`pricing-recommendation ${rotating ? 'rotate-animation' : ''}`}>
      {isChecked ? (
        <>
        <h2>Modelling</h2>
        <div className="row">
          <p>Select Country</p>
          <select
            value={country}
            onChange={handleCountryChange}
            style={{
              width: window.innerHeight < 400 ? '57%' : '57%', 
              backgroundColor: '#fff',
              padding: '6px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              fontSize: window.innerHeight < 400 ? '12px' : '24px', 
              fontWeight: '600',
              color: '#333'
            }}
          >
            <option value="" disabled>Select Country</option>
            {countryOptions.map((country) => (
              <option key={country.value} value={country.value}>
                {country.label}
              </option>
            ))}
          </select>
        </div>
        <div className="row">
            <p>Man-hour rate in $</p>
            <InputGroup 
              large
              type="number"
              required
              value={mhr}
              onChange={(event) => {
                const value = Number(event.target.value);
                if (value < 0) {
                  alert("Please enter a positive number for man-hour rate.");
                  setMhr(0); 
                } else {
                  setMhr(value); 
                }
              }}
              className="input-group"
            />

          </div>
          <div className="row">
            <p>Modelling efforts in Hours per Scan (per Resource)</p>
            <InputGroup 
              large
              type="number"
              required
              value={meh}
              onChange={(event) => {
                const value = Number(event.target.value);
                if (value < 0) {
                  alert("Please enter a positive number for expected days for completion.");
                  setMeh(0);
                } else {
                  setMeh(value);
                }
              }}
              className="input-group"
            />
          </div>
          <div className="row">
            <p>Number of Scans per Month</p>
            <InputGroup 
              large
              type="number"
              required
              value={noScans}
              onChange={(event) => {
                const value = Number(event.target.value);
                if (value < 0) {
                  alert("Please enter a positive number for expected days for completion.");
                  setNoScans(0);
                } else {
                  setNoScans(value);
                }
              }}
              className="input-group"
            />
          </div>
          <Button 
            className="calculate"  
            onClick={handleCostSubmit}
          >
            Calculate
          </Button>
          {noModels && (
            <>
              <div className="row">
                <p>Capacity (per Resource)</p>
                <InputGroup
                  large
                  type="number"
                  className="input-group"
                  readOnly
                  value={Math.floor(noModels)}
                  style={{ backgroundColor: "#F8E187" }}
                />
              </div>
              <div className="row">
                <p>ManPower Estimation</p>
                <InputGroup
                  large
                  type="number"
                  className="input-group"
                  readOnly
                  value={Math.ceil(totalResourceRequired)}
                  style={{ backgroundColor: "#F8E187" }}
                />
              </div>
              <div className="row">
              <p>
                Cost to Company Per Month$ (for the first scan)
                <button 
                  className="question-mark" 
                  onClick={toggleOverlay}
                >
                  ?
              </button>
              </p>
              <InputGroup
                large
                type="number"
                className="input-group"
                readOnly
                value={Math.floor(costPerScan)}
                style={{ backgroundColor: "#F8E187" }}
              />
              </div>
              {isOpen && (
                <div className="custom-overlay">
                  <div className="overlay-content">
                    <button className="close-button" onClick={toggleOverlay}>×</button>
                    <p>Subsequent scans will incur only the manpower cost until the remaining credits from your current subscription are used up. Once your subscription expires after one month or the credits are depleted, the full subscription cost will be charged again.</p>
                  </div>
                </div>
              )}

              <button 
                  className="button-2" 
                  onClick={toggleOverlay2}
                >
                  View Comparison
              </button>
            
            {isComparison && (
              <>
                <h2>Using Recap, BIM 360 & Revit</h2>
                <div className="row">
                  <p>Licenses used:</p>
                  {OPTIONS.map((option) => (
                    <label key={option.label} style={{ display: "block", marginBottom: "5px", color: "#333", fontSize:"20px", fontWeight:"700" }}>
                      <input
                        type="checkbox"
                        style={{backgroundColor:"#F8E187"}}
                        checked={selectedItems.includes(option.label)}
                        onChange={() => handleCheckboxChange(option.label)}
                      />
                      {option.label}
                    </label>
                  ))}
                </div>
                <div className="row">
                  <p>Other Licenses Cost(per Month):</p>
                  <InputGroup
                    large
                    type="text"
                    className="input-group"
                    value={otherLicenseCost}
                    style={{ backgroundColor: "#F8E187" }}
                    onChange={(event) => {
                      const value = Number(event.target.value);
                      if (value < 0) {
                        alert("Please enter a positive number for other license cost rate.");
                        setOtherLicenseCost(0); 
                      } else {
                        setOtherLicenseCost(value); 
                      }
                    }}
                  />
                </div>
                <div className="row">
                  <p>Total cost on licenses(per Month):</p>
                  <InputGroup
                    large
                    type="text"
                    className="input-group"
                    readOnly
                    value={tcl}
                    style={{ backgroundColor: "#F8E187" }}
                  />
                </div>
                <div className="row">
                  <p>Modelling efforts in Hours per Scan (per Resource)</p>
                  <InputGroup
                    large
                    type="number"
                    className="input-group"
                    readOnly
                    value={meh2}
                    style={{ backgroundColor: "#F8E187" }}
                  />
                </div>
                <div className="row">
                  <p>
                    Capacity per resource
                  </p>
                  <InputGroup
                    large
                    type="number"
                    className="input-group"
                    readOnly
                    value={Math.floor(capacity)}
                    style={{ backgroundColor: "#F8E187" }}
                  />
                </div>
                <div className="row">
                  <p>
                    ManPower Estimation              
                  </p>
                  <InputGroup
                    large
                    type="number"
                    className="input-group"
                    readOnly
                    value={Math.ceil(manpower)}
                    style={{ backgroundColor: "#F8E187" }}
                  />
                </div>
                <div className="row">
                  <p>
                    Cost to Company Per Month$ (for First Scan)              
                  </p>
                  <InputGroup
                    large
                    type="number"
                    className="input-group"
                    readOnly
                    value={Math.ceil(totalcostComp)}
                    style={{ backgroundColor: "#F8E187" }}
                  />
                </div>
              </>
            )}

          </>
          )}
      </>
      ) : (
        <>
          <div>
            <h2>Scanning</h2>
          </div>
          <div className="row">
            <p>Area in Sq. Meter</p>
            <InputGroup
              large
              type="number"
              required
              value={area}
              onChange={handleAreaSqMetersChange}
              className="input-group"
            />
          </div>
          <div className="row">
            <p>Area in Sq. Feet</p>
            <InputGroup
              large
              type="number"
              required
              value={areaSqFeet}
              onChange={handleAreaSqFeetChange}
              className="input-group"
            />
          </div>
          <div className="row">
            <p>DPI (per sq.inch)</p>
            <select
              value={dpi}
              onChange={handleSelectChange}
              style={{
                  width: '57%',
                  backgroundColor: '#fff',
                  padding: '6px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  fontSize : "24px",
                  fontWeight : "600",
                  color : "#333"
              }}
            >
              {[100, 150, 200, 250, 300, 350, 600, 800, 1200].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="row">
            <p>Average File Size (*GB)</p>
            <InputGroup 
              large
              type="number"
              required
              value={fileSize}
              onChange={(event) => {
                const value = Number(event.target.value);
                if (value < 0) {
                  alert("Please enter a positive number for pipelines.");
                  setFileSize(0);
                } else {
                  setFileSize(value);
                }
              }}
              className="input-group"
            />
          </div>
          <Button className="select" onClick={handleSubscriptionSubmit}>Submit</Button>
          {licenseneeded && (
            <>
            <div className="row">
              <p>Credit Packages</p>
              <InputGroup
                large
                type="number"
                className="input-group"
                readOnly
                value={licenseneeded}
                style={{ backgroundColor: "#7CCCE9" }}
              />
            </div>
            <div className="row">
              <p>Credits Remaining</p>
              <InputGroup
                large
                type="number"
                className="input-group"
                readOnly
                value={remainingAssets}
                style={{ backgroundColor: "#7CCCE9" }}
              />
            </div>
            </>
          )}
        </>
      )}
    </div>

    </div>
      <div className={highlightedPlan ? 'plan highlight' : 'plan'}>
      <header className="pricing-header">
        <h1>Select Your Converter Plan</h1>
      </header>
        <div className="plan-list">
          <h2>{selectedPlan.name}</h2>
          <p>{selectedPlan.contactUs ? "Contact Us" : `$${selectedPlan.price}/month`}</p>
        </div>
        {/* <Button className="buy-now" onClick={() => setShowPaymentModal(true)}>
          Buy Now
        </Button> */}
        <Button 
          className={`${isChecked ? 'yellow-button' : 'buy-now'}`} 
          onClick={() => setShowPaymentModal(true)}
        >
          Buy Now
        </Button>

        <div className="plan-footer">
          <ul>
            {selectedPlan.features.map((feature, index) => (
              <li 
                key={index} 
                // className={index >= selectedPlan.features.length - 2 ? 'highlight-feature' : ''}
                className={`${
                  index >= selectedPlan.features.length - 2 
                    ? (isChecked ? 'highlight-feature-2' : 'highlight-feature') 
                    : ''
                }`}
              >
                {feature}
              </li>
            ))}
          </ul>
          <p style={{fontSize:"10px"}}>* 1 credit consumed per feature</p>
        </div>
        <select onChange={handlePlanSelect} value={selectedPlan.name} className="plan-selector">
          {plans.map((plan) => (
            <option key={plan.name} value={plan.name}>
              {plan.name}
            </option>
          ))}
        </select>
      </div>

      {showPaymentModal && (
        <div className={`modal ${showPaymentModal ? 'show' : 'hide'}`}>
          <div className="modal-content">
            <span className="close" onClick={() => setShowPaymentModal(false)}>&times;</span>
            <h2 style={{color:"black"}}>Enter Payment Details</h2>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <label style={{color:"black"}}>Email<span style={{color: "red"}}>*</span></label>
                <InputGroup
                  large
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <label>Credit or Debit Card</label>
                <CardElement />
              </FormGroup>
              <FormGroup>
                <Button style={{backgroundColor:"#007bff",backgroundImage:"none",color:"white"}} large type="submit" disabled={isSubmitting}>
                  {isSubmitting ? <Spinner size={20} /> : "Submit Payment"}
                </Button>
              </FormGroup>
            </form>
            {message && <div className={`message ${isSuccess ? "success" : "error"}`}>{message}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

const PricingPlansWithStripe = () => (
  <Elements stripe={stripePromise}>
    <PricingPlans />
  </Elements>
);

export default PricingPlansWithStripe;
