import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesEuropain2 = [
  {
    profile_section_id: 2739,
    name: "UPN260",
    type: "UPN Channel",
    designation: "UPN260",
    shape: "C",
    country_code: "European",
    ax: "48,3",
    ax_global: 4830,
    bf: 90,
    bf_global: 90,
    ct: "2,4",
    ct_global: 24,
    d: 260,
    d_global: 260,
    ix: "25,4",
    ix_global: 254000,
    iy: 317,
    iy_global: 3170000,
    iz: 4820,
    iz_global: 48200000,
    tf: 14,
    tf_global: 14,
    tw: 10,
    tw_global: 10,
    zx: "444,5",
    zx_global: 444500,
    zy: "96,6",
    zy_global: 96600,
  },
  {
    profile_section_id: 2740,
    name: "UPN280",
    type: "UPN Channel",
    designation: "UPN280",
    shape: "C",
    country_code: "European",
    ax: "53,3",
    ax_global: 5330,
    bf: 95,
    bf_global: 95,
    ct: "2,5",
    ct_global: 25,
    d: 280,
    d_global: 280,
    ix: "31,2",
    ix_global: 312000,
    iy: 399,
    iy_global: 3990000,
    iz: 6280,
    iz_global: 62800000,
    tf: 15,
    tf_global: 15,
    tw: 10,
    tw_global: 10,
    zx: "533,9",
    zx_global: 533900,
    zy: "113,2",
    zy_global: 113200,
  },
  {
    profile_section_id: 2741,
    name: "UPN300",
    type: "UPN Channel",
    designation: "UPN300",
    shape: "C",
    country_code: "European",
    ax: "58,8",
    ax_global: 5880,
    bf: 100,
    bf_global: 100,
    ct: "2,7",
    ct_global: 27,
    d: 300,
    d_global: 300,
    ix: 38,
    ix_global: 380000,
    iy: 495,
    iy_global: 4950000,
    iz: 8030,
    iz_global: 80300000,
    tf: 16,
    tf_global: 16,
    tw: 10,
    tw_global: 10,
    zx: 634,
    zx_global: 634000,
    zy: "131,3",
    zy_global: 131300,
  },
  {
    profile_section_id: 2742,
    name: "UPN320",
    type: "UPN Channel",
    designation: "UPN320",
    shape: "C",
    country_code: "European",
    ax: "75,8",
    ax_global: 7580,
    bf: 100,
    bf_global: 100,
    ct: "2,6",
    ct_global: 26,
    d: 320,
    d_global: 320,
    ix: "64,8",
    ix_global: 648000,
    iy: 597,
    iy_global: 5970000,
    iz: 10870,
    iz_global: 108700000,
    tf: "17,5",
    tf_global: "17,5",
    tw: 14,
    tw_global: 14,
    zx: "813,7",
    zx_global: 813700,
    zy: "160,3",
    zy_global: 160300,
  },
  {
    profile_section_id: 2743,
    name: "UPN350",
    type: "UPN Channel",
    designation: "UPN350",
    shape: "C",
    country_code: "European",
    ax: "77,3",
    ax_global: 7730,
    bf: 100,
    bf_global: 100,
    ct: "2,4",
    ct_global: 24,
    d: 350,
    d_global: 350,
    ix: "59,2",
    ix_global: 592000,
    iy: 570,
    iy_global: 5700000,
    iz: 12840,
    iz_global: 128400000,
    tf: 16,
    tf_global: 16,
    tw: 14,
    tw_global: 14,
    zx: "888,3",
    zx_global: 888300,
    zy: "160,1",
    zy_global: 160100,
  },
  {
    profile_section_id: 2744,
    name: "UPN380",
    type: "UPN Channel",
    designation: "UPN380",
    shape: "C",
    country_code: "European",
    ax: "80,4",
    ax_global: 8040,
    bf: 102,
    bf_global: 102,
    ct: "2,4",
    ct_global: 24,
    d: 380,
    d_global: 380,
    ix: "59,2",
    ix_global: 592000,
    iy: 615,
    iy_global: 6150000,
    iz: 15760,
    iz_global: 157600000,
    tf: 16,
    tf_global: 16,
    tw: "13,5",
    tw_global: "13,5",
    zx: "1002,8",
    zx_global: 1002800,
    zy: "170,4",
    zy_global: 170400,
  },
  {
    profile_section_id: 2745,
    name: "UPN400",
    type: "UPN Channel",
    designation: "UPN400",
    shape: "C",
    country_code: "European",
    ax: "91,5",
    ax_global: 9150,
    bf: 110,
    bf_global: 110,
    ct: "2,7",
    ct_global: 27,
    d: 400,
    d_global: 400,
    ix: "79,8",
    ix_global: 798000,
    iy: 846,
    iy_global: 8460000,
    iz: 20350,
    iz_global: 203500000,
    tf: 18,
    tf_global: 18,
    tw: 14,
    tw_global: 14,
    zx: "1220,1",
    zx_global: 1220100,
    zy: "213,9",
    zy_global: 213900,
  },
  {
    profile_section_id: 2746,
    name: "U30X15",
    type: "U Channel",
    designation: "U30X15",
    shape: "C",
    country_code: "European",
    ax: "2,21",
    ax_global: 221,
    bf: 15,
    bf_global: 15,
    ct: "0,5",
    ct_global: 5,
    d: 30,
    d_global: 30,
    ix: "0,1",
    ix_global: 1000,
    iy: "0,4",
    iy_global: 4000,
    iz: "2,5",
    iz_global: 25000,
    tf: "4,5",
    tf_global: "4,5",
    tw: 4,
    tw_global: 4,
    zx: "2,2",
    zx_global: 2200,
    zy: "0,6",
    zy_global: 600,
  },
  {
    profile_section_id: 2747,
    name: "U40X20",
    type: "U Channel",
    designation: "U40X20",
    shape: "C",
    country_code: "European",
    ax: "3,66",
    ax_global: 366,
    bf: 20,
    bf_global: 20,
    ct: "1,1",
    ct_global: 11,
    d: 40,
    d_global: 40,
    ix: "0,4",
    ix_global: 4000,
    iy: "1,1",
    iy_global: 11000,
    iz: "7,6",
    iz_global: 76000,
    tf: "5,5",
    tf_global: "5,5",
    tw: 5,
    tw_global: 5,
    zx: "4,8",
    zx_global: 4800,
    zy: "1,3",
    zy_global: 1300,
  },
  {
    profile_section_id: 2748,
    name: "U50X25",
    type: "U Channel",
    designation: "U50X25",
    shape: "C",
    country_code: "European",
    ax: "4,92",
    ax_global: 492,
    bf: 25,
    bf_global: 25,
    ct: "0,8",
    ct_global: 8,
    d: 50,
    d_global: 50,
    ix: "0,5",
    ix_global: 5000,
    iy: "2,5",
    iy_global: 25000,
    iz: "16,8",
    iz_global: 168000,
    tf: 6,
    tf_global: 6,
    tw: 5,
    tw_global: 5,
    zx: "8,4",
    zx_global: 8400,
    zy: "2,3",
    zy_global: 2300,
  },
  {
    profile_section_id: 2749,
    name: "U40X35",
    type: "U Channel",
    designation: "U40X35",
    shape: "C",
    country_code: "European",
    ax: "6,21",
    ax_global: 621,
    bf: 35,
    bf_global: 35,
    ct: "1,3",
    ct_global: 13,
    d: 40,
    d_global: 40,
    ix: 1,
    ix_global: 10000,
    iy: "6,7",
    iy_global: 67000,
    iz: "14,1",
    iz_global: 141000,
    tf: 7,
    tf_global: 7,
    tw: 5,
    tw_global: 5,
    zx: "8,9",
    zx_global: 8900,
    zy: "3,1",
    zy_global: 3100,
  },
  {
    profile_section_id: 2750,
    name: "U60X30",
    type: "U Channel",
    designation: "U60X30",
    shape: "C",
    country_code: "European",
    ax: "6,46",
    ax_global: 646,
    bf: 30,
    bf_global: 30,
    ct: "0,9",
    ct_global: 9,
    d: 60,
    d_global: 60,
    ix: "0,8",
    ix_global: 8000,
    iy: "4,5",
    iy_global: 45000,
    iz: "31,6",
    iz_global: 316000,
    tf: 6,
    tf_global: 6,
    tw: 6,
    tw_global: 6,
    zx: "13,2",
    zx_global: 13200,
    zy: "3,8",
    zy_global: 3800,
  },
  {
    profile_section_id: 2751,
    name: "U50X38",
    type: "U Channel",
    designation: "U50X38",
    shape: "C",
    country_code: "European",
    ax: "7,12",
    ax_global: 712,
    bf: 38,
    bf_global: 38,
    ct: "1,4",
    ct_global: 14,
    d: 50,
    d_global: 50,
    ix: "1,1",
    ix_global: 11000,
    iy: "9,1",
    iy_global: 91000,
    iz: "26,4",
    iz_global: 264000,
    tf: 7,
    tf_global: 7,
    tw: 5,
    tw_global: 5,
    zx: "13,1",
    zx_global: 13100,
    zy: "4,4",
    zy_global: 4400,
  },
  {
    profile_section_id: 2752,
    name: "U70X40",
    type: "U Channel",
    designation: "U70X40",
    shape: "C",
    country_code: "European",
    ax: "8,62",
    ax_global: 862,
    bf: 40,
    bf_global: 40,
    ct: "1,3",
    ct_global: 13,
    d: 70,
    d_global: 70,
    ix: "1,2",
    ix_global: 12000,
    iy: 13,
    iy_global: 130000,
    iz: "61,8",
    iz_global: 618000,
    tf: "6,5",
    tf_global: "6,5",
    tw: 6,
    tw_global: 6,
    zx: "21,4",
    zx_global: 21400,
    zy: 7,
    zy_global: 7000,
  },
  {
    profile_section_id: 2753,
    name: "U65X42",
    type: "U Channel",
    designation: "U65X42",
    shape: "C",
    country_code: "European",
    ax: "9,03",
    ax_global: 903,
    bf: 42,
    bf_global: 42,
    ct: "1,4",
    ct_global: 14,
    d: 65,
    d_global: 65,
    ix: "1,5",
    ix_global: 15000,
    iy: "14,1",
    iy_global: 141000,
    iz: "57,5",
    iz_global: 575000,
    tf: "7,5",
    tf_global: "7,5",
    tw: "5,5",
    tw_global: "5,5",
    zx: "21,5",
    zx_global: 21500,
    zy: 7,
    zy_global: 7000,
  },
  {
    profile_section_id: 2754,
    name: "L20X20X2.5",
    type: "Angle",
    designation: "L20X20X2.5",
    shape: "L",
    country_code: "European",
    ax: "0,95",
    ax_global: 95,
    b: 20,
    b_global: 20,
    d: 20,
    d_global: 20,
    r1: "0,38",
    r1_global: "0,38",
    t: "2,5",
    t_global: "2,5",
    width: 20,
    width_global: 20,
    thickness: "2,5",
    thickness_global: "2,5",
    height: 20,
    height_global: 20,
  },
  {
    profile_section_id: 2755,
    name: "L20X20X3",
    type: "Angle",
    designation: "L20X20X3",
    shape: "L",
    country_code: "European",
    ax: "1,13",
    ax_global: 113,
    b: 20,
    b_global: 20,
    d: 20,
    d_global: 20,
    r1: "0,38",
    r1_global: "0,38",
    t: 3,
    t_global: 3,
    width: 20,
    width_global: 20,
    thickness: 3,
    thickness_global: 3,
    height: 20,
    height_global: 20,
  },
  {
    profile_section_id: 2756,
    name: "L25X25X2.5",
    type: "Angle",
    designation: "L25X25X2.5",
    shape: "L",
    country_code: "European",
    ax: "1,2",
    ax_global: 120,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    r1: "0,48",
    r1_global: "0,48",
    t: "2,5",
    t_global: "2,5",
    width: 25,
    width_global: 25,
    thickness: "2,5",
    thickness_global: "2,5",
    height: 25,
    height_global: 25,
  },
  {
    profile_section_id: 2757,
    name: "L25X25X3",
    type: "Angle",
    designation: "L25X25X3",
    shape: "L",
    country_code: "European",
    ax: "1,43",
    ax_global: 143,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    r1: "0,48",
    r1_global: "0,48",
    t: 3,
    t_global: 3,
    width: 25,
    width_global: 25,
    thickness: 3,
    thickness_global: 3,
    height: 25,
    height_global: 25,
  },
  {
    profile_section_id: 2758,
    name: "L30X20X3",
    type: "Angle",
    designation: "L30X20X3",
    shape: "L",
    country_code: "European",
    ax: "1,43",
    ax_global: 143,
    b: 20,
    b_global: 20,
    d: 30,
    d_global: 30,
    r1: "0,42",
    r1_global: "0,42",
    t: 3,
    t_global: 3,
    width: 20,
    width_global: 20,
    thickness: 3,
    thickness_global: 3,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 2759,
    name: "L20X20X4",
    type: "Angle",
    designation: "L20X20X4",
    shape: "L",
    country_code: "European",
    ax: "1,46",
    ax_global: 146,
    b: 20,
    b_global: 20,
    d: 20,
    d_global: 20,
    r1: "0,38",
    r1_global: "0,38",
    t: 4,
    t_global: 4,
    width: 20,
    width_global: 20,
    thickness: 4,
    thickness_global: 4,
    height: 20,
    height_global: 20,
  },
  {
    profile_section_id: 2760,
    name: "L30X30X2.5",
    type: "Angle",
    designation: "L30X30X2.5",
    shape: "L",
    country_code: "European",
    ax: "1,46",
    ax_global: 146,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    r1: "0,58",
    r1_global: "0,58",
    t: "2,5",
    t_global: "2,5",
    width: 30,
    width_global: 30,
    thickness: "2,5",
    thickness_global: "2,5",
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 2761,
    name: "L35X35X2.5",
    type: "Angle",
    designation: "L35X35X2.5",
    shape: "L",
    country_code: "European",
    ax: "1,71",
    ax_global: 171,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    r1: "0,68",
    r1_global: "0,68",
    t: "2,5",
    t_global: "2,5",
    width: 35,
    width_global: 35,
    thickness: "2,5",
    thickness_global: "2,5",
    height: 35,
    height_global: 35,
  },
  {
    profile_section_id: 2762,
    name: "L40X20X3",
    type: "Angle",
    designation: "L40X20X3",
    shape: "L",
    country_code: "European",
    ax: "1,73",
    ax_global: 173,
    b: 20,
    b_global: 20,
    d: 40,
    d_global: 40,
    r1: "0,42",
    r1_global: "0,42",
    t: 3,
    t_global: 3,
    width: 20,
    width_global: 20,
    thickness: 3,
    thickness_global: 3,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 2763,
    name: "L30X30X3",
    type: "Angle",
    designation: "L30X30X3",
    shape: "L",
    country_code: "European",
    ax: "1,74",
    ax_global: 174,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    r1: "0,58",
    r1_global: "0,58",
    t: 3,
    t_global: 3,
    width: 30,
    width_global: 30,
    thickness: 3,
    thickness_global: 3,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 2764,
    name: "L20X20X5",
    type: "Angle",
    designation: "L20X20X5",
    shape: "L",
    country_code: "European",
    ax: "1,77",
    ax_global: 177,
    b: 20,
    b_global: 20,
    d: 20,
    d_global: 20,
    r1: "0,38",
    r1_global: "0,38",
    t: 5,
    t_global: 5,
    width: 20,
    width_global: 20,
    thickness: 5,
    thickness_global: 5,
    height: 20,
    height_global: 20,
  },
  {
    profile_section_id: 2765,
    name: "L25X25X4",
    type: "Angle",
    designation: "L25X25X4",
    shape: "L",
    country_code: "European",
    ax: "1,86",
    ax_global: 186,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    r1: "0,48",
    r1_global: "0,48",
    t: 4,
    t_global: 4,
    width: 25,
    width_global: 25,
    thickness: 4,
    thickness_global: 4,
    height: 25,
    height_global: 25,
  },
  {
    profile_section_id: 2766,
    name: "L30X20X4",
    type: "Angle",
    designation: "L30X20X4",
    shape: "L",
    country_code: "European",
    ax: "1,86",
    ax_global: 186,
    b: 20,
    b_global: 20,
    d: 30,
    d_global: 30,
    r1: "0,42",
    r1_global: "0,42",
    t: 4,
    t_global: 4,
    width: 20,
    width_global: 20,
    thickness: 4,
    thickness_global: 4,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 2767,
    name: "L35X35X3",
    type: "Angle",
    designation: "L35X35X3",
    shape: "L",
    country_code: "European",
    ax: "2,04",
    ax_global: 204,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    r1: "0,68",
    r1_global: "0,68",
    t: 3,
    t_global: 3,
    width: 35,
    width_global: 35,
    thickness: 3,
    thickness_global: 3,
    height: 35,
    height_global: 35,
  },
  {
    profile_section_id: 2768,
    name: "L45X30X3",
    type: "Angle",
    designation: "L45X30X3",
    shape: "L",
    country_code: "European",
    ax: "2,18",
    ax_global: 218,
    b: 30,
    b_global: 30,
    d: 45,
    d_global: 45,
    r1: "0,65",
    r1_global: "0,65",
    t: 3,
    t_global: 3,
    width: 30,
    width_global: 30,
    thickness: 3,
    thickness_global: 3,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 2769,
    name: "L40X20X4",
    type: "Angle",
    designation: "L40X20X4",
    shape: "L",
    country_code: "European",
    ax: "2,26",
    ax_global: 226,
    b: 20,
    b_global: 20,
    d: 40,
    d_global: 40,
    r1: "0,42",
    r1_global: "0,42",
    t: 4,
    t_global: 4,
    width: 20,
    width_global: 20,
    thickness: 4,
    thickness_global: 4,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 2770,
    name: "L25X25X5",
    type: "Angle",
    designation: "L25X25X5",
    shape: "L",
    country_code: "European",
    ax: "2,27",
    ax_global: 227,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    r1: "0,48",
    r1_global: "0,48",
    t: 5,
    t_global: 5,
    width: 25,
    width_global: 25,
    thickness: 5,
    thickness_global: 5,
    height: 25,
    height_global: 25,
  },
  {
    profile_section_id: 2771,
    name: "L30X30X4",
    type: "Angle",
    designation: "L30X30X4",
    shape: "L",
    country_code: "European",
    ax: "2,27",
    ax_global: 227,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    r1: "0,57",
    r1_global: "0,57",
    t: 4,
    t_global: 4,
    width: 30,
    width_global: 30,
    thickness: 4,
    thickness_global: 4,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 2772,
    name: "L30X20X5",
    type: "Angle",
    designation: "L30X20X5",
    shape: "L",
    country_code: "European",
    ax: "2,27",
    ax_global: 227,
    b: 20,
    b_global: 20,
    d: 30,
    d_global: 30,
    r1: "0,42",
    r1_global: "0,42",
    t: 5,
    t_global: 5,
    width: 20,
    width_global: 20,
    thickness: 5,
    thickness_global: 5,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 2773,
    name: "L35X35X3.5",
    type: "Angle",
    designation: "L35X35X3.5",
    shape: "L",
    country_code: "European",
    ax: "2,35",
    ax_global: 235,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    r1: "0,68",
    r1_global: "0,68",
    t: "3,5",
    t_global: "3,5",
    width: 35,
    width_global: 35,
    thickness: "3,5",
    thickness_global: "3,5",
    height: 35,
    height_global: 35,
  },
  {
    profile_section_id: 2774,
    name: "L40X40X3",
    type: "Angle",
    designation: "L40X40X3",
    shape: "L",
    country_code: "European",
    ax: "2,35",
    ax_global: 235,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    r1: "0,78",
    r1_global: "0,78",
    t: 3,
    t_global: 3,
    width: 40,
    width_global: 40,
    thickness: 3,
    thickness_global: 3,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 2775,
    name: "L50X40X3",
    type: "Angle",
    designation: "L50X40X3",
    shape: "L",
    country_code: "European",
    ax: "2,64",
    ax_global: 264,
    b: 40,
    b_global: 40,
    d: 50,
    d_global: 50,
    r1: "0,85",
    r1_global: "0,85",
    t: 3,
    t_global: 3,
    width: 40,
    width_global: 40,
    thickness: 3,
    thickness_global: 3,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2776,
    name: "L60X30X3",
    type: "Angle",
    designation: "L60X30X3",
    shape: "L",
    country_code: "European",
    ax: "2,65",
    ax_global: 265,
    b: 30,
    b_global: 30,
    d: 60,
    d_global: 60,
    r1: "1,06",
    r1_global: "1,06",
    t: 3,
    t_global: 3,
    width: 30,
    width_global: 30,
    thickness: 3,
    thickness_global: 3,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2777,
    name: "L25X25X6",
    type: "Angle",
    designation: "L25X25X6",
    shape: "L",
    country_code: "European",
    ax: "2,66",
    ax_global: 266,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    r1: "0,48",
    r1_global: "0,48",
    t: 6,
    t_global: 6,
    width: 25,
    width_global: 25,
    thickness: 6,
    thickness_global: 6,
    height: 25,
    height_global: 25,
  },
  {
    profile_section_id: 2778,
    name: "L45X45X3",
    type: "Angle",
    designation: "L45X45X3",
    shape: "L",
    country_code: "European",
    ax: "2,66",
    ax_global: 266,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    r1: "0,88",
    r1_global: "0,88",
    t: 3,
    t_global: 3,
    width: 45,
    width_global: 45,
    thickness: 3,
    thickness_global: 3,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 2779,
    name: "L35X35X4",
    type: "Angle",
    designation: "L35X35X4",
    shape: "L",
    country_code: "European",
    ax: "2,67",
    ax_global: 267,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    r1: "0,68",
    r1_global: "0,68",
    t: 4,
    t_global: 4,
    width: 35,
    width_global: 35,
    thickness: 4,
    thickness_global: 4,
    height: 35,
    height_global: 35,
  },
  {
    profile_section_id: 2780,
    name: "L40X20X5",
    type: "Angle",
    designation: "L40X20X5",
    shape: "L",
    country_code: "European",
    ax: "2,77",
    ax_global: 277,
    b: 20,
    b_global: 20,
    d: 40,
    d_global: 40,
    r1: "0,42",
    r1_global: "0,42",
    t: 5,
    t_global: 5,
    width: 20,
    width_global: 20,
    thickness: 5,
    thickness_global: 5,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 2781,
    name: "L30X30X5",
    type: "Angle",
    designation: "L30X30X5",
    shape: "L",
    country_code: "European",
    ax: "2,78",
    ax_global: 278,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    r1: "0,57",
    r1_global: "0,57",
    t: 5,
    t_global: 5,
    width: 30,
    width_global: 30,
    thickness: 5,
    thickness_global: 5,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 2782,
    name: "L45X30X4",
    type: "Angle",
    designation: "L45X30X4",
    shape: "L",
    country_code: "European",
    ax: "2,86",
    ax_global: 286,
    b: 30,
    b_global: 30,
    d: 45,
    d_global: 45,
    r1: "0,64",
    r1_global: "0,64",
    t: 4,
    t_global: 4,
    width: 30,
    width_global: 30,
    thickness: 4,
    thickness_global: 4,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 2783,
    name: "L60X40X3",
    type: "Angle",
    designation: "L60X40X3",
    shape: "L",
    country_code: "European",
    ax: "2,95",
    ax_global: 295,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    r1: "0,87",
    r1_global: "0,87",
    t: 3,
    t_global: 3,
    width: 40,
    width_global: 40,
    thickness: 3,
    thickness_global: 3,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2784,
    name: "L50X50X3",
    type: "Angle",
    designation: "L50X50X3",
    shape: "L",
    country_code: "European",
    ax: "2,96",
    ax_global: 296,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: "0,98",
    r1_global: "0,98",
    t: 3,
    t_global: 3,
    width: 50,
    width_global: 50,
    thickness: 3,
    thickness_global: 3,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2785,
    name: "L50X30X4",
    type: "Angle",
    designation: "L50X30X4",
    shape: "L",
    country_code: "European",
    ax: "3,07",
    ax_global: 307,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    r1: "0,64",
    r1_global: "0,64",
    t: 4,
    t_global: 4,
    width: 30,
    width_global: 30,
    thickness: 4,
    thickness_global: 4,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2786,
    name: "L40X40X4",
    type: "Angle",
    designation: "L40X40X4",
    shape: "L",
    country_code: "European",
    ax: "3,08",
    ax_global: 308,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    r1: "0,77",
    r1_global: "0,77",
    t: 4,
    t_global: 4,
    width: 40,
    width_global: 40,
    thickness: 4,
    thickness_global: 4,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 2787,
    name: "L40X20X6",
    type: "Angle",
    designation: "L40X20X6",
    shape: "L",
    country_code: "European",
    ax: "3,26",
    ax_global: 326,
    b: 20,
    b_global: 20,
    d: 40,
    d_global: 40,
    r1: "0,42",
    r1_global: "0,42",
    t: 6,
    t_global: 6,
    width: 20,
    width_global: 20,
    thickness: 6,
    thickness_global: 6,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 2788,
    name: "L30X30X6",
    type: "Angle",
    designation: "L30X30X6",
    shape: "L",
    country_code: "European",
    ax: "3,27",
    ax_global: 327,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    r1: "0,57",
    r1_global: "0,57",
    t: 6,
    t_global: 6,
    width: 30,
    width_global: 30,
    thickness: 6,
    thickness_global: 6,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 2789,
    name: "L35X35X5",
    type: "Angle",
    designation: "L35X35X5",
    shape: "L",
    country_code: "European",
    ax: "3,28",
    ax_global: 328,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    r1: "0,67",
    r1_global: "0,67",
    t: 5,
    t_global: 5,
    width: 35,
    width_global: 35,
    thickness: 5,
    thickness_global: 5,
    height: 35,
    height_global: 35,
  },
  {
    profile_section_id: 2790,
    name: "L50X40X4",
    type: "Angle",
    designation: "L50X40X4",
    shape: "L",
    country_code: "European",
    ax: "3,47",
    ax_global: 347,
    b: 40,
    b_global: 40,
    d: 50,
    d_global: 50,
    r1: "0,85",
    r1_global: "0,85",
    t: 4,
    t_global: 4,
    width: 40,
    width_global: 40,
    thickness: 4,
    thickness_global: 4,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2791,
    name: "L60X30X4",
    type: "Angle",
    designation: "L60X30X4",
    shape: "L",
    country_code: "European",
    ax: "3,48",
    ax_global: 348,
    b: 30,
    b_global: 30,
    d: 60,
    d_global: 60,
    r1: "0,63",
    r1_global: "0,63",
    t: 4,
    t_global: 4,
    width: 30,
    width_global: 30,
    thickness: 4,
    thickness_global: 4,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2792,
    name: "L45X45X4",
    type: "Angle",
    designation: "L45X45X4",
    shape: "L",
    country_code: "European",
    ax: "3,49",
    ax_global: 349,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    r1: "0,87",
    r1_global: "0,87",
    t: 4,
    t_global: 4,
    width: 45,
    width_global: 45,
    thickness: 4,
    thickness_global: 4,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 2793,
    name: "L45X30X5",
    type: "Angle",
    designation: "L45X30X5",
    shape: "L",
    country_code: "European",
    ax: "3,52",
    ax_global: 352,
    b: 30,
    b_global: 30,
    d: 45,
    d_global: 45,
    r1: "0,64",
    r1_global: "0,64",
    t: 5,
    t_global: 5,
    width: 30,
    width_global: 30,
    thickness: 5,
    thickness_global: 5,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 2794,
    name: "L30X30X7",
    type: "Angle",
    designation: "L30X30X7",
    shape: "L",
    country_code: "European",
    ax: "3,74",
    ax_global: 374,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    r1: "0,58",
    r1_global: "0,58",
    t: 7,
    t_global: 7,
    width: 30,
    width_global: 30,
    thickness: 7,
    thickness_global: 7,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 2795,
    name: "L50X30X5",
    type: "Angle",
    designation: "L50X30X5",
    shape: "L",
    country_code: "European",
    ax: "3,78",
    ax_global: 378,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    r1: "0,64",
    r1_global: "0,64",
    t: 5,
    t_global: 5,
    width: 30,
    width_global: 30,
    thickness: 5,
    thickness_global: 5,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2796,
    name: "L40X40X5",
    type: "Angle",
    designation: "L40X40X5",
    shape: "L",
    country_code: "European",
    ax: "3,79",
    ax_global: 379,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    r1: "0,77",
    r1_global: "0,77",
    t: 5,
    t_global: 5,
    width: 40,
    width_global: 40,
    thickness: 5,
    thickness_global: 5,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 2797,
    name: "L35X35X6",
    type: "Angle",
    designation: "L35X35X6",
    shape: "L",
    country_code: "European",
    ax: "3,87",
    ax_global: 387,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    r1: "0,67",
    r1_global: "0,67",
    t: 6,
    t_global: 6,
    width: 35,
    width_global: 35,
    thickness: 6,
    thickness_global: 6,
    height: 35,
    height_global: 35,
  },
  {
    profile_section_id: 2798,
    name: "L60X40X4",
    type: "Angle",
    designation: "L60X40X4",
    shape: "L",
    country_code: "European",
    ax: "3,88",
    ax_global: 388,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    r1: "0,86",
    r1_global: "0,86",
    t: 4,
    t_global: 4,
    width: 40,
    width_global: 40,
    thickness: 4,
    thickness_global: 4,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2799,
    name: "L50X50X4",
    type: "Angle",
    designation: "L50X50X4",
    shape: "L",
    country_code: "European",
    ax: "3,89",
    ax_global: 389,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: "0,97",
    r1_global: "0,97",
    t: 4,
    t_global: 4,
    width: 50,
    width_global: 50,
    thickness: 4,
    thickness_global: 4,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2800,
    name: "L45X45X4.5",
    type: "Angle",
    designation: "L45X45X4.5",
    shape: "L",
    country_code: "European",
    ax: "3,9",
    ax_global: 390,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    r1: "0,87",
    r1_global: "0,87",
    t: "4,5",
    t_global: "4,5",
    width: 45,
    width_global: 45,
    thickness: "4,5",
    thickness_global: "4,5",
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 2801,
    name: "L45X30X6",
    type: "Angle",
    designation: "L45X30X6",
    shape: "L",
    country_code: "European",
    ax: "4,16",
    ax_global: 416,
    b: 30,
    b_global: 30,
    d: 45,
    d_global: 45,
    r1: "0,64",
    r1_global: "0,64",
    t: 6,
    t_global: 6,
    width: 30,
    width_global: 30,
    thickness: 6,
    thickness_global: 6,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 2802,
    name: "L50X40X5",
    type: "Angle",
    designation: "L50X40X5",
    shape: "L",
    country_code: "European",
    ax: "4,28",
    ax_global: 428,
    b: 40,
    b_global: 40,
    d: 50,
    d_global: 50,
    r1: "0,84",
    r1_global: "0,84",
    t: 5,
    t_global: 5,
    width: 40,
    width_global: 40,
    thickness: 5,
    thickness_global: 5,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2803,
    name: "L60X30X5",
    type: "Angle",
    designation: "L60X30X5",
    shape: "L",
    country_code: "European",
    ax: "4,29",
    ax_global: 429,
    b: 30,
    b_global: 30,
    d: 60,
    d_global: 60,
    r1: "0,63",
    r1_global: "0,63",
    t: 5,
    t_global: 5,
    width: 30,
    width_global: 30,
    thickness: 5,
    thickness_global: 5,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2804,
    name: "L45X45X5",
    type: "Angle",
    designation: "L45X45X5",
    shape: "L",
    country_code: "European",
    ax: "4,3",
    ax_global: 430,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    r1: "0,87",
    r1_global: "0,87",
    t: 5,
    t_global: 5,
    width: 45,
    width_global: 45,
    thickness: 5,
    thickness_global: 5,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 2805,
    name: "L55X55X4",
    type: "Angle",
    designation: "L55X55X4",
    shape: "L",
    country_code: "European",
    ax: "4,31",
    ax_global: 431,
    b: 55,
    b_global: 55,
    d: 55,
    d_global: 55,
    r1: "1,07",
    r1_global: "1,07",
    t: 4,
    t_global: 4,
    width: 55,
    width_global: 55,
    thickness: 4,
    thickness_global: 4,
    height: 55,
    height_global: 55,
  },
  {
    profile_section_id: 2806,
    name: "L35X35X7",
    type: "Angle",
    designation: "L35X35X7",
    shape: "L",
    country_code: "European",
    ax: "4,44",
    ax_global: 444,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    r1: "0,67",
    r1_global: "0,67",
    t: 7,
    t_global: 7,
    width: 35,
    width_global: 35,
    thickness: 7,
    thickness_global: 7,
    height: 35,
    height_global: 35,
  },
  {
    profile_section_id: 2807,
    name: "L50X30X6",
    type: "Angle",
    designation: "L50X30X6",
    shape: "L",
    country_code: "European",
    ax: "4,47",
    ax_global: 447,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    r1: "0,64",
    r1_global: "0,64",
    t: 6,
    t_global: 6,
    width: 30,
    width_global: 30,
    thickness: 6,
    thickness_global: 6,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2808,
    name: "L40X40X6",
    type: "Angle",
    designation: "L40X40X6",
    shape: "L",
    country_code: "European",
    ax: "4,48",
    ax_global: 448,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    r1: "0,77",
    r1_global: "0,77",
    t: 6,
    t_global: 6,
    width: 40,
    width_global: 40,
    thickness: 6,
    thickness_global: 6,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 2809,
    name: "L60X40X5",
    type: "Angle",
    designation: "L60X40X5",
    shape: "L",
    country_code: "European",
    ax: "4,79",
    ax_global: 479,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    r1: "0,86",
    r1_global: "0,86",
    t: 5,
    t_global: 5,
    width: 40,
    width_global: 40,
    thickness: 5,
    thickness_global: 5,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2810,
    name: "L50X50X5",
    type: "Angle",
    designation: "L50X50X5",
    shape: "L",
    country_code: "European",
    ax: "4,8",
    ax_global: 480,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: "0,97",
    r1_global: "0,97",
    t: 5,
    t_global: 5,
    width: 50,
    width_global: 50,
    thickness: 5,
    thickness_global: 5,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2811,
    name: "L50X40X6",
    type: "Angle",
    designation: "L50X40X6",
    shape: "L",
    country_code: "European",
    ax: "5,07",
    ax_global: 507,
    b: 40,
    b_global: 40,
    d: 50,
    d_global: 50,
    r1: "0,84",
    r1_global: "0,84",
    t: 6,
    t_global: 6,
    width: 40,
    width_global: 40,
    thickness: 6,
    thickness_global: 6,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2812,
    name: "L60X30X6",
    type: "Angle",
    designation: "L60X30X6",
    shape: "L",
    country_code: "European",
    ax: "5,08",
    ax_global: 508,
    b: 30,
    b_global: 30,
    d: 60,
    d_global: 60,
    r1: "0,62",
    r1_global: "0,62",
    t: 6,
    t_global: 6,
    width: 30,
    width_global: 30,
    thickness: 6,
    thickness_global: 6,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2813,
    name: "L45X45X6",
    type: "Angle",
    designation: "L45X45X6",
    shape: "L",
    country_code: "European",
    ax: "5,09",
    ax_global: 509,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    r1: "0,86",
    r1_global: "0,86",
    t: 6,
    t_global: 6,
    width: 45,
    width_global: 45,
    thickness: 6,
    thickness_global: 6,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 2814,
    name: "L40X40X7",
    type: "Angle",
    designation: "L40X40X7",
    shape: "L",
    country_code: "European",
    ax: "5,15",
    ax_global: 515,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    r1: "0,77",
    r1_global: "0,77",
    t: 7,
    t_global: 7,
    width: 40,
    width_global: 40,
    thickness: 7,
    thickness_global: 7,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 2815,
    name: "L60X60X4.5",
    type: "Angle",
    designation: "L60X60X4.5",
    shape: "L",
    country_code: "European",
    ax: "5,27",
    ax_global: 527,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    r1: "1,17",
    r1_global: "1,17",
    t: "4,5",
    t_global: "4,5",
    width: 60,
    width_global: 60,
    thickness: "4,5",
    thickness_global: "4,5",
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2816,
    name: "L55X55X5",
    type: "Angle",
    designation: "L55X55X5",
    shape: "L",
    country_code: "European",
    ax: "5,32",
    ax_global: 532,
    b: 55,
    b_global: 55,
    d: 55,
    d_global: 55,
    r1: "1,07",
    r1_global: "1,07",
    t: 5,
    t_global: 5,
    width: 55,
    width_global: 55,
    thickness: 5,
    thickness_global: 5,
    height: 55,
    height_global: 55,
  },
  {
    profile_section_id: 2817,
    name: "L65X50X5",
    type: "Angle",
    designation: "L65X50X5",
    shape: "L",
    country_code: "European",
    ax: "5,54",
    ax_global: 554,
    b: 50,
    b_global: 50,
    d: 65,
    d_global: 65,
    r1: "1,07",
    r1_global: "1,07",
    t: 5,
    t_global: 5,
    width: 50,
    width_global: 50,
    thickness: 5,
    thickness_global: 5,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2818,
    name: "L60X40X6",
    type: "Angle",
    designation: "L60X40X6",
    shape: "L",
    country_code: "European",
    ax: "5,68",
    ax_global: 568,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    r1: "0,85",
    r1_global: "0,85",
    t: 6,
    t_global: 6,
    width: 40,
    width_global: 40,
    thickness: 6,
    thickness_global: 6,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2819,
    name: "L50X50X6",
    type: "Angle",
    designation: "L50X50X6",
    shape: "L",
    country_code: "European",
    ax: "5,69",
    ax_global: 569,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: "0,97",
    r1_global: "0,97",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2820,
    name: "L40X40X8",
    type: "Angle",
    designation: "L40X40X8",
    shape: "L",
    country_code: "European",
    ax: "5,8",
    ax_global: 580,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    r1: "0,77",
    r1_global: "0,77",
    t: 8,
    t_global: 8,
    width: 40,
    width_global: 40,
    thickness: 8,
    thickness_global: 8,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 2821,
    name: "L55X55X5.5",
    type: "Angle",
    designation: "L55X55X5.5",
    shape: "L",
    country_code: "European",
    ax: "5,82",
    ax_global: 582,
    b: 55,
    b_global: 55,
    d: 55,
    d_global: 55,
    r1: "1,06",
    r1_global: "1,06",
    t: "5,5",
    t_global: "5,5",
    width: 55,
    width_global: 55,
    thickness: "5,5",
    thickness_global: "5,5",
    height: 55,
    height_global: 55,
  },
  {
    profile_section_id: 2822,
    name: "L60X60X5",
    type: "Angle",
    designation: "L60X60X5",
    shape: "L",
    country_code: "European",
    ax: "5,82",
    ax_global: 582,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    r1: "1,17",
    r1_global: "1,17",
    t: 5,
    t_global: 5,
    width: 60,
    width_global: 60,
    thickness: 5,
    thickness_global: 5,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2823,
    name: "L60X30X7",
    type: "Angle",
    designation: "L60X30X7",
    shape: "L",
    country_code: "European",
    ax: "5,85",
    ax_global: 585,
    b: 30,
    b_global: 30,
    d: 60,
    d_global: 60,
    r1: "0,62",
    r1_global: "0,62",
    t: 7,
    t_global: 7,
    width: 30,
    width_global: 30,
    thickness: 7,
    thickness_global: 7,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2824,
    name: "L45X45X7",
    type: "Angle",
    designation: "L45X45X7",
    shape: "L",
    country_code: "European",
    ax: "5,86",
    ax_global: 586,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    r1: "0,86",
    r1_global: "0,86",
    t: 7,
    t_global: 7,
    width: 45,
    width_global: 45,
    thickness: 7,
    thickness_global: 7,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 2825,
    name: "L75X50X5",
    type: "Angle",
    designation: "L75X50X5",
    shape: "L",
    country_code: "European",
    ax: "6,05",
    ax_global: 605,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    r1: "1,08",
    r1_global: "1,08",
    t: 5,
    t_global: 5,
    width: 50,
    width_global: 50,
    thickness: 5,
    thickness_global: 5,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2826,
    name: "L75X55X5",
    type: "Angle",
    designation: "L75X55X5",
    shape: "L",
    country_code: "European",
    ax: "6,3",
    ax_global: 630,
    b: 55,
    b_global: 55,
    d: 75,
    d_global: 75,
    r1: "1,18",
    r1_global: "1,18",
    t: 5,
    t_global: 5,
    width: 55,
    width_global: 55,
    thickness: 5,
    thickness_global: 5,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2827,
    name: "L55X55X6",
    type: "Angle",
    designation: "L55X55X6",
    shape: "L",
    country_code: "European",
    ax: "6,31",
    ax_global: 631,
    b: 55,
    b_global: 55,
    d: 55,
    d_global: 55,
    r1: "1,06",
    r1_global: "1,06",
    t: 6,
    t_global: 6,
    width: 55,
    width_global: 55,
    thickness: 6,
    thickness_global: 6,
    height: 55,
    height_global: 55,
  },
  {
    profile_section_id: 2828,
    name: "L65X65X5",
    type: "Angle",
    designation: "L65X65X5",
    shape: "L",
    country_code: "European",
    ax: "6,34",
    ax_global: 634,
    b: 65,
    b_global: 65,
    d: 65,
    d_global: 65,
    r1: "1,27",
    r1_global: "1,27",
    t: 5,
    t_global: 5,
    width: 65,
    width_global: 65,
    thickness: 5,
    thickness_global: 5,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2829,
    name: "L60X40X7",
    type: "Angle",
    designation: "L60X40X7",
    shape: "L",
    country_code: "European",
    ax: "6,55",
    ax_global: 655,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    r1: "0,85",
    r1_global: "0,85",
    t: 7,
    t_global: 7,
    width: 40,
    width_global: 40,
    thickness: 7,
    thickness_global: 7,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2830,
    name: "L50X50X7",
    type: "Angle",
    designation: "L50X50X7",
    shape: "L",
    country_code: "European",
    ax: "6,56",
    ax_global: 656,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: "0,96",
    r1_global: "0,96",
    t: 7,
    t_global: 7,
    width: 50,
    width_global: 50,
    thickness: 7,
    thickness_global: 7,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2831,
    name: "L65X50X6",
    type: "Angle",
    designation: "L65X50X6",
    shape: "L",
    country_code: "European",
    ax: "6,58",
    ax_global: 658,
    b: 50,
    b_global: 50,
    d: 65,
    d_global: 65,
    r1: "1,06",
    r1_global: "1,06",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2832,
    name: "L45X45X8",
    type: "Angle",
    designation: "L45X45X8",
    shape: "L",
    country_code: "European",
    ax: "6,61",
    ax_global: 661,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    r1: "0,86",
    r1_global: "0,86",
    t: 8,
    t_global: 8,
    width: 45,
    width_global: 45,
    thickness: 8,
    thickness_global: 8,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 2833,
    name: "L70X70X5",
    type: "Angle",
    designation: "L70X70X5",
    shape: "L",
    country_code: "European",
    ax: "6,84",
    ax_global: 684,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    r1: "1,37",
    r1_global: "1,37",
    t: 5,
    t_global: 5,
    width: 70,
    width_global: 70,
    thickness: 5,
    thickness_global: 5,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 2834,
    name: "L80X40X6",
    type: "Angle",
    designation: "L80X40X6",
    shape: "L",
    country_code: "European",
    ax: "6,89",
    ax_global: 689,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    r1: "0,84",
    r1_global: "0,84",
    t: 6,
    t_global: 6,
    width: 40,
    width_global: 40,
    thickness: 6,
    thickness_global: 6,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2835,
    name: "L60X60X6",
    type: "Angle",
    designation: "L60X60X6",
    shape: "L",
    country_code: "European",
    ax: "6,91",
    ax_global: 691,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    r1: "1,17",
    r1_global: "1,17",
    t: 6,
    t_global: 6,
    width: 60,
    width_global: 60,
    thickness: 6,
    thickness_global: 6,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2836,
    name: "L75X50X6",
    type: "Angle",
    designation: "L75X50X6",
    shape: "L",
    country_code: "European",
    ax: "7,19",
    ax_global: 719,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    r1: "1,08",
    r1_global: "1,08",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2837,
    name: "L55X55X7",
    type: "Angle",
    designation: "L55X55X7",
    shape: "L",
    country_code: "European",
    ax: "7,28",
    ax_global: 728,
    b: 55,
    b_global: 55,
    d: 55,
    d_global: 55,
    r1: "1,06",
    r1_global: "1,06",
    t: 7,
    t_global: 7,
    width: 55,
    width_global: 55,
    thickness: 7,
    thickness_global: 7,
    height: 55,
    height_global: 55,
  },
  {
    profile_section_id: 2838,
    name: "L75X75X5",
    type: "Angle",
    designation: "L75X75X5",
    shape: "L",
    country_code: "European",
    ax: "7,36",
    ax_global: 736,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    r1: "1,47",
    r1_global: "1,47",
    t: 5,
    t_global: 5,
    width: 75,
    width_global: 75,
    thickness: 5,
    thickness_global: 5,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2839,
    name: "L50X50X8",
    type: "Angle",
    designation: "L50X50X8",
    shape: "L",
    country_code: "European",
    ax: "7,41",
    ax_global: 741,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: "0,96",
    r1_global: "0,96",
    t: 8,
    t_global: 8,
    width: 50,
    width_global: 50,
    thickness: 8,
    thickness_global: 8,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2840,
    name: "L65X65X6",
    type: "Angle",
    designation: "L65X65X6",
    shape: "L",
    country_code: "European",
    ax: "7,53",
    ax_global: 753,
    b: 65,
    b_global: 65,
    d: 65,
    d_global: 65,
    r1: "1,26",
    r1_global: "1,26",
    t: 6,
    t_global: 6,
    width: 65,
    width_global: 65,
    thickness: 6,
    thickness_global: 6,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2841,
    name: "L65X50X7",
    type: "Angle",
    designation: "L65X50X7",
    shape: "L",
    country_code: "European",
    ax: "7,6",
    ax_global: 760,
    b: 50,
    b_global: 50,
    d: 65,
    d_global: 65,
    r1: "1,06",
    r1_global: "1,06",
    t: 7,
    t_global: 7,
    width: 50,
    width_global: 50,
    thickness: 7,
    thickness_global: 7,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2842,
    name: "L80X40X7",
    type: "Angle",
    designation: "L80X40X7",
    shape: "L",
    country_code: "European",
    ax: "7,96",
    ax_global: 796,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    r1: "0,84",
    r1_global: "0,84",
    t: 7,
    t_global: 7,
    width: 40,
    width_global: 40,
    thickness: 7,
    thickness_global: 7,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2843,
    name: "L60X60X7",
    type: "Angle",
    designation: "L60X60X7",
    shape: "L",
    country_code: "European",
    ax: "7,98",
    ax_global: 798,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    r1: "1,16",
    r1_global: "1,16",
    t: 7,
    t_global: 7,
    width: 60,
    width_global: 60,
    thickness: 7,
    thickness_global: 7,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2844,
    name: "L70X70X6",
    type: "Angle",
    designation: "L70X70X6",
    shape: "L",
    country_code: "European",
    ax: "8,13",
    ax_global: 813,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    r1: "1,37",
    r1_global: "1,37",
    t: 6,
    t_global: 6,
    width: 70,
    width_global: 70,
    thickness: 6,
    thickness_global: 6,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 2845,
    name: "L55X55X8",
    type: "Angle",
    designation: "L55X55X8",
    shape: "L",
    country_code: "European",
    ax: "8,23",
    ax_global: 823,
    b: 55,
    b_global: 55,
    d: 55,
    d_global: 55,
    r1: "1,06",
    r1_global: "1,06",
    t: 8,
    t_global: 8,
    width: 55,
    width_global: 55,
    thickness: 8,
    thickness_global: 8,
    height: 55,
    height_global: 55,
  },
  {
    profile_section_id: 2846,
    name: "L50X50X9",
    type: "Angle",
    designation: "L50X50X9",
    shape: "L",
    country_code: "European",
    ax: "8,24",
    ax_global: 824,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: "0,96",
    r1_global: "0,96",
    t: 9,
    t_global: 9,
    width: 50,
    width_global: 50,
    thickness: 9,
    thickness_global: 9,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2847,
    name: "L75X50X7",
    type: "Angle",
    designation: "L75X50X7",
    shape: "L",
    country_code: "European",
    ax: "8,31",
    ax_global: 831,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    r1: "1,07",
    r1_global: "1,07",
    t: 7,
    t_global: 7,
    width: 50,
    width_global: 50,
    thickness: 7,
    thickness_global: 7,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2848,
    name: "L65X50X8",
    type: "Angle",
    designation: "L65X50X8",
    shape: "L",
    country_code: "European",
    ax: "8,6",
    ax_global: 860,
    b: 50,
    b_global: 50,
    d: 65,
    d_global: 65,
    r1: "1,05",
    r1_global: "1,05",
    t: 8,
    t_global: 8,
    width: 50,
    width_global: 50,
    thickness: 8,
    thickness_global: 8,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2849,
    name: "L75X55X7",
    type: "Angle",
    designation: "L75X55X7",
    shape: "L",
    country_code: "European",
    ax: "8,66",
    ax_global: 866,
    b: 55,
    b_global: 55,
    d: 75,
    d_global: 75,
    r1: "1,17",
    r1_global: "1,17",
    t: 7,
    t_global: 7,
    width: 55,
    width_global: 55,
    thickness: 7,
    thickness_global: 7,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2850,
    name: "L65X65X7",
    type: "Angle",
    designation: "L65X65X7",
    shape: "L",
    country_code: "European",
    ax: "8,7",
    ax_global: 870,
    b: 65,
    b_global: 65,
    d: 65,
    d_global: 65,
    r1: "1,26",
    r1_global: "1,26",
    t: 7,
    t_global: 7,
    width: 65,
    width_global: 65,
    thickness: 7,
    thickness_global: 7,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2851,
    name: "L100X50X6",
    type: "Angle",
    designation: "L100X50X6",
    shape: "L",
    country_code: "European",
    ax: "8,73",
    ax_global: 873,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    r1: "1,06",
    r1_global: "1,06",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2852,
    name: "L75X75X6",
    type: "Angle",
    designation: "L75X75X6",
    shape: "L",
    country_code: "European",
    ax: "8,75",
    ax_global: 875,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    r1: "1,46",
    r1_global: "1,46",
    t: 6,
    t_global: 6,
    width: 75,
    width_global: 75,
    thickness: 6,
    thickness_global: 6,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2853,
    name: "L80X40X8",
    type: "Angle",
    designation: "L80X40X8",
    shape: "L",
    country_code: "European",
    ax: "9,01",
    ax_global: 901,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    r1: "0,84",
    r1_global: "0,84",
    t: 8,
    t_global: 8,
    width: 40,
    width_global: 40,
    thickness: 8,
    thickness_global: 8,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2854,
    name: "L60X60X8",
    type: "Angle",
    designation: "L60X60X8",
    shape: "L",
    country_code: "European",
    ax: "9,03",
    ax_global: 903,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    r1: "1,16",
    r1_global: "1,16",
    t: 8,
    t_global: 8,
    width: 60,
    width_global: 60,
    thickness: 8,
    thickness_global: 8,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2855,
    name: "L50X50X10",
    type: "Angle",
    designation: "L50X50X10",
    shape: "L",
    country_code: "European",
    ax: "9,05",
    ax_global: 905,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: "0,96",
    r1_global: "0,96",
    t: 10,
    t_global: 10,
    width: 50,
    width_global: 50,
    thickness: 10,
    thickness_global: 10,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2856,
    name: "L55X55X9",
    type: "Angle",
    designation: "L55X55X9",
    shape: "L",
    country_code: "European",
    ax: "9,16",
    ax_global: 916,
    b: 55,
    b_global: 55,
    d: 55,
    d_global: 55,
    r1: "1,06",
    r1_global: "1,06",
    t: 9,
    t_global: 9,
    width: 55,
    width_global: 55,
    thickness: 9,
    thickness_global: 9,
    height: 55,
    height_global: 55,
  },
  {
    profile_section_id: 2857,
    name: "L80X80X6",
    type: "Angle",
    designation: "L80X80X6",
    shape: "L",
    country_code: "European",
    ax: "9,35",
    ax_global: 935,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: "1,57",
    r1_global: "1,57",
    t: 6,
    t_global: 6,
    width: 80,
    width_global: 80,
    thickness: 6,
    thickness_global: 6,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2858,
    name: "L70X70X7",
    type: "Angle",
    designation: "L70X70X7",
    shape: "L",
    country_code: "European",
    ax: "9,4",
    ax_global: 940,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    r1: "1,36",
    r1_global: "1,36",
    t: 7,
    t_global: 7,
    width: 70,
    width_global: 70,
    thickness: 7,
    thickness_global: 7,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 2859,
    name: "L75X50X8",
    type: "Angle",
    designation: "L75X50X8",
    shape: "L",
    country_code: "European",
    ax: "9,41",
    ax_global: 941,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    r1: "1,07",
    r1_global: "1,07",
    t: 8,
    t_global: 8,
    width: 50,
    width_global: 50,
    thickness: 8,
    thickness_global: 8,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2860,
    name: "L65X50X9",
    type: "Angle",
    designation: "L65X50X9",
    shape: "L",
    country_code: "European",
    ax: "9,58",
    ax_global: 958,
    b: 50,
    b_global: 50,
    d: 65,
    d_global: 65,
    r1: "1,05",
    r1_global: "1,05",
    t: 9,
    t_global: 9,
    width: 50,
    width_global: 50,
    thickness: 9,
    thickness_global: 9,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2861,
    name: "L50X50X11",
    type: "Angle",
    designation: "L50X50X11",
    shape: "L",
    country_code: "European",
    ax: "9,84",
    ax_global: 984,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: "0,96",
    r1_global: "0,96",
    t: 11,
    t_global: 11,
    width: 50,
    width_global: 50,
    thickness: 11,
    thickness_global: 11,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2862,
    name: "L65X65X8",
    type: "Angle",
    designation: "L65X65X8",
    shape: "L",
    country_code: "European",
    ax: "9,85",
    ax_global: 985,
    b: 65,
    b_global: 65,
    d: 65,
    d_global: 65,
    r1: "1,26",
    r1_global: "1,26",
    t: 8,
    t_global: 8,
    width: 65,
    width_global: 65,
    thickness: 8,
    thickness_global: 8,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2863,
    name: "L80X40X9",
    type: "Angle",
    designation: "L80X40X9",
    shape: "L",
    country_code: "European",
    ax: 10,
    ax_global: 1000,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    r1: "0,84",
    r1_global: "0,84",
    t: 9,
    t_global: 9,
    width: 40,
    width_global: 40,
    thickness: 9,
    thickness_global: 9,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2864,
    name: "L55X55X10",
    type: "Angle",
    designation: "L55X55X10",
    shape: "L",
    country_code: "European",
    ax: "10,1",
    ax_global: 1010,
    b: 55,
    b_global: 55,
    d: 55,
    d_global: 55,
    r1: "1,06",
    r1_global: "1,06",
    t: 10,
    t_global: 10,
    width: 55,
    width_global: 55,
    thickness: 10,
    thickness_global: 10,
    height: 55,
    height_global: 55,
  },
  {
    profile_section_id: 2865,
    name: "L60X60X9",
    type: "Angle",
    designation: "L60X60X9",
    shape: "L",
    country_code: "European",
    ax: "10,1",
    ax_global: 1010,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    r1: "1,16",
    r1_global: "1,16",
    t: 9,
    t_global: 9,
    width: 60,
    width_global: 60,
    thickness: 9,
    thickness_global: 9,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2866,
    name: "L75X75X7",
    type: "Angle",
    designation: "L75X75X7",
    shape: "L",
    country_code: "European",
    ax: "10,1",
    ax_global: 1010,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    r1: "1,46",
    r1_global: "1,46",
    t: 7,
    t_global: 7,
    width: 75,
    width_global: 75,
    thickness: 7,
    thickness_global: 7,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2867,
    name: "L75X50X9",
    type: "Angle",
    designation: "L75X50X9",
    shape: "L",
    country_code: "European",
    ax: "10,5",
    ax_global: 1050,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    r1: "1,06",
    r1_global: "1,06",
    t: 9,
    t_global: 9,
    width: 50,
    width_global: 50,
    thickness: 9,
    thickness_global: 9,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2868,
    name: "L50X50X12",
    type: "Angle",
    designation: "L50X50X12",
    shape: "L",
    country_code: "European",
    ax: "10,6",
    ax_global: 1060,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: "0,97",
    r1_global: "0,97",
    t: 12,
    t_global: 12,
    width: 50,
    width_global: 50,
    thickness: 12,
    thickness_global: 12,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 2869,
    name: "L90X90X6",
    type: "Angle",
    designation: "L90X90X6",
    shape: "L",
    country_code: "European",
    ax: "10,6",
    ax_global: 1060,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "1,77",
    r1_global: "1,77",
    t: 6,
    t_global: 6,
    width: 90,
    width_global: 90,
    thickness: 6,
    thickness_global: 6,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 2870,
    name: "L80X80X7",
    type: "Angle",
    designation: "L80X80X7",
    shape: "L",
    country_code: "European",
    ax: "10,8",
    ax_global: 1080,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: "1,56",
    r1_global: "1,56",
    t: 7,
    t_global: 7,
    width: 80,
    width_global: 80,
    thickness: 7,
    thickness_global: 7,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2871,
    name: "L75X55X9",
    type: "Angle",
    designation: "L75X55X9",
    shape: "L",
    country_code: "European",
    ax: "10,9",
    ax_global: 1090,
    b: 55,
    b_global: 55,
    d: 75,
    d_global: 75,
    r1: "1,17",
    r1_global: "1,17",
    t: 9,
    t_global: 9,
    width: 55,
    width_global: 55,
    thickness: 9,
    thickness_global: 9,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2872,
    name: "L65X65X9",
    type: "Angle",
    designation: "L65X65X9",
    shape: "L",
    country_code: "European",
    ax: 11,
    ax_global: 1100,
    b: 65,
    b_global: 65,
    d: 65,
    d_global: 65,
    r1: "1,25",
    r1_global: "1,25",
    t: 9,
    t_global: 9,
    width: 65,
    width_global: 65,
    thickness: 9,
    thickness_global: 9,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2873,
    name: "L60X60X10",
    type: "Angle",
    designation: "L60X60X10",
    shape: "L",
    country_code: "European",
    ax: "11,1",
    ax_global: 1110,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    r1: "1,15",
    r1_global: "1,15",
    t: 10,
    t_global: 10,
    width: 60,
    width_global: 60,
    thickness: 10,
    thickness_global: 10,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 2874,
    name: "L80X40X10",
    type: "Angle",
    designation: "L80X40X10",
    shape: "L",
    country_code: "European",
    ax: "11,1",
    ax_global: 1110,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    r1: "0,83",
    r1_global: "0,83",
    t: 10,
    t_global: 10,
    width: 40,
    width_global: 40,
    thickness: 10,
    thickness_global: 10,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2875,
    name: "L100X75X6.5",
    type: "Angle",
    designation: "L100X75X6.5",
    shape: "L",
    country_code: "European",
    ax: "11,1",
    ax_global: 1110,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    r1: "1,61",
    r1_global: "1,61",
    t: "6,5",
    t_global: "6,5",
    width: 75,
    width_global: 75,
    thickness: "6,5",
    thickness_global: "6,5",
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2876,
    name: "L100X65X7",
    type: "Angle",
    designation: "L100X65X7",
    shape: "L",
    country_code: "European",
    ax: "11,2",
    ax_global: 1120,
    b: 65,
    b_global: 65,
    d: 100,
    d_global: 100,
    r1: "1,4",
    r1_global: "1,4",
    t: 7,
    t_global: 7,
    width: 65,
    width_global: 65,
    thickness: 7,
    thickness_global: 7,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2877,
    name: "L100X50X8",
    type: "Angle",
    designation: "L100X50X8",
    shape: "L",
    country_code: "European",
    ax: "11,4",
    ax_global: 1140,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    r1: "1,05",
    r1_global: "1,05",
    t: 8,
    t_global: 8,
    width: 50,
    width_global: 50,
    thickness: 8,
    thickness_global: 8,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2878,
    name: "L75X75X8",
    type: "Angle",
    designation: "L75X75X8",
    shape: "L",
    country_code: "European",
    ax: "11,5",
    ax_global: 1150,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    r1: "1,45",
    r1_global: "1,45",
    t: 8,
    t_global: 8,
    width: 75,
    width_global: 75,
    thickness: 8,
    thickness_global: 8,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2879,
    name: "L100X100X6",
    type: "Angle",
    designation: "L100X100X6",
    shape: "L",
    country_code: "European",
    ax: "11,8",
    ax_global: 1180,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "1,97",
    r1_global: "1,97",
    t: 6,
    t_global: 6,
    width: 100,
    width_global: 100,
    thickness: 6,
    thickness_global: 6,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2880,
    name: "L70X70X9",
    type: "Angle",
    designation: "L70X70X9",
    shape: "L",
    country_code: "European",
    ax: "11,9",
    ax_global: 1190,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    r1: "1,35",
    r1_global: "1,35",
    t: 9,
    t_global: 9,
    width: 70,
    width_global: 70,
    thickness: 9,
    thickness_global: 9,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 2881,
    name: "L100X75X7",
    type: "Angle",
    designation: "L100X75X7",
    shape: "L",
    country_code: "European",
    ax: "11,9",
    ax_global: 1190,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    r1: "1,61",
    r1_global: "1,61",
    t: 7,
    t_global: 7,
    width: 75,
    width_global: 75,
    thickness: 7,
    thickness_global: 7,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2882,
    name: "L65X65X10",
    type: "Angle",
    designation: "L65X65X10",
    shape: "L",
    country_code: "European",
    ax: "12,1",
    ax_global: 1210,
    b: 65,
    b_global: 65,
    d: 65,
    d_global: 65,
    r1: "1,25",
    r1_global: "1,25",
    t: 10,
    t_global: 10,
    width: 65,
    width_global: 65,
    thickness: 10,
    thickness_global: 10,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2883,
    name: "L90X90X7",
    type: "Angle",
    designation: "L90X90X7",
    shape: "L",
    country_code: "European",
    ax: "12,2",
    ax_global: 1220,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "1,76",
    r1_global: "1,76",
    t: 7,
    t_global: 7,
    width: 90,
    width_global: 90,
    thickness: 7,
    thickness_global: 7,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 2884,
    name: "L80X80X8",
    type: "Angle",
    designation: "L80X80X8",
    shape: "L",
    country_code: "European",
    ax: "12,3",
    ax_global: 1230,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: "1,56",
    r1_global: "1,56",
    t: 8,
    t_global: 8,
    width: 80,
    width_global: 80,
    thickness: 8,
    thickness_global: 8,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2885,
    name: "L65X65X11",
    type: "Angle",
    designation: "L65X65X11",
    shape: "L",
    country_code: "European",
    ax: "13,2",
    ax_global: 1320,
    b: 65,
    b_global: 65,
    d: 65,
    d_global: 65,
    r1: "1,25",
    r1_global: "1,25",
    t: 11,
    t_global: 11,
    width: 65,
    width_global: 65,
    thickness: 11,
    thickness_global: 11,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2886,
    name: "L100X75X8",
    type: "Angle",
    designation: "L100X75X8",
    shape: "L",
    country_code: "European",
    ax: "13,5",
    ax_global: 1350,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    r1: "1,6",
    r1_global: "1,6",
    t: 8,
    t_global: 8,
    width: 75,
    width_global: 75,
    thickness: 8,
    thickness_global: 8,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2887,
    name: "L80X80X9",
    type: "Angle",
    designation: "L80X80X9",
    shape: "L",
    country_code: "European",
    ax: "13,7",
    ax_global: 1370,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: "1,55",
    r1_global: "1,55",
    t: 9,
    t_global: 9,
    width: 80,
    width_global: 80,
    thickness: 9,
    thickness_global: 9,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2888,
    name: "L100X100X7",
    type: "Angle",
    designation: "L100X100X7",
    shape: "L",
    country_code: "European",
    ax: "13,7",
    ax_global: 1370,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "1,96",
    r1_global: "1,96",
    t: 7,
    t_global: 7,
    width: 100,
    width_global: 100,
    thickness: 7,
    thickness_global: 7,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2889,
    name: "L90X90X8",
    type: "Angle",
    designation: "L90X90X8",
    shape: "L",
    country_code: "European",
    ax: "13,9",
    ax_global: 1390,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "1,76",
    r1_global: "1,76",
    t: 8,
    t_global: 8,
    width: 90,
    width_global: 90,
    thickness: 8,
    thickness_global: 8,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 2890,
    name: "L75X75X10",
    type: "Angle",
    designation: "L75X75X10",
    shape: "L",
    country_code: "European",
    ax: "14,1",
    ax_global: 1410,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    r1: "1,45",
    r1_global: "1,45",
    t: 10,
    t_global: 10,
    width: 75,
    width_global: 75,
    thickness: 10,
    thickness_global: 10,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2891,
    name: "L100X50X10",
    type: "Angle",
    designation: "L100X50X10",
    shape: "L",
    country_code: "European",
    ax: "14,1",
    ax_global: 1410,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    r1: "1,05",
    r1_global: "1,05",
    t: 10,
    t_global: 10,
    width: 50,
    width_global: 50,
    thickness: 10,
    thickness_global: 10,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2892,
    name: "L100X65X9",
    type: "Angle",
    designation: "L100X65X9",
    shape: "L",
    country_code: "European",
    ax: "14,1",
    ax_global: 1410,
    b: 65,
    b_global: 65,
    d: 100,
    d_global: 100,
    r1: "1,39",
    r1_global: "1,39",
    t: 9,
    t_global: 9,
    width: 65,
    width_global: 65,
    thickness: 9,
    thickness_global: 9,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2893,
    name: "L65X65X12",
    type: "Angle",
    designation: "L65X65X12",
    shape: "L",
    country_code: "European",
    ax: "14,2",
    ax_global: 1420,
    b: 65,
    b_global: 65,
    d: 65,
    d_global: 65,
    r1: "1,25",
    r1_global: "1,25",
    t: 12,
    t_global: 12,
    width: 65,
    width_global: 65,
    thickness: 12,
    thickness_global: 12,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 2894,
    name: "L70X70X11",
    type: "Angle",
    designation: "L70X70X11",
    shape: "L",
    country_code: "European",
    ax: "14,3",
    ax_global: 1430,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    r1: "1,35",
    r1_global: "1,35",
    t: 11,
    t_global: 11,
    width: 70,
    width_global: 70,
    thickness: 11,
    thickness_global: 11,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 2895,
    name: "L100X75X9",
    type: "Angle",
    designation: "L100X75X9",
    shape: "L",
    country_code: "European",
    ax: 15,
    ax_global: 1500,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    r1: "1,6",
    r1_global: "1,6",
    t: 9,
    t_global: 9,
    width: 75,
    width_global: 75,
    thickness: 9,
    thickness_global: 9,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2896,
    name: "L80X80X10",
    type: "Angle",
    designation: "L80X80X10",
    shape: "L",
    country_code: "European",
    ax: "15,1",
    ax_global: 1510,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: "1,55",
    r1_global: "1,55",
    t: 10,
    t_global: 10,
    width: 80,
    width_global: 80,
    thickness: 10,
    thickness_global: 10,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2897,
    name: "L130X65X8",
    type: "Angle",
    designation: "L130X65X8",
    shape: "L",
    country_code: "European",
    ax: "15,1",
    ax_global: 1510,
    b: 65,
    b_global: 65,
    d: 130,
    d_global: 130,
    r1: "1,38",
    r1_global: "1,38",
    t: 8,
    t_global: 8,
    width: 65,
    width_global: 65,
    thickness: 8,
    thickness_global: 8,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 2898,
    name: "L90X90X9",
    type: "Angle",
    designation: "L90X90X9",
    shape: "L",
    country_code: "European",
    ax: "15,5",
    ax_global: 1550,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "1,75",
    r1_global: "1,75",
    t: 9,
    t_global: 9,
    width: 90,
    width_global: 90,
    thickness: 9,
    thickness_global: 9,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 2899,
    name: "L100X100X8",
    type: "Angle",
    designation: "L100X100X8",
    shape: "L",
    country_code: "European",
    ax: "15,5",
    ax_global: 1550,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "1,96",
    r1_global: "1,96",
    t: 8,
    t_global: 8,
    width: 100,
    width_global: 100,
    thickness: 8,
    thickness_global: 8,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2900,
    name: "L120X80X8",
    type: "Angle",
    designation: "L120X80X8",
    shape: "L",
    country_code: "European",
    ax: "15,5",
    ax_global: 1550,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    r1: "1,73",
    r1_global: "1,73",
    t: 8,
    t_global: 8,
    width: 80,
    width_global: 80,
    thickness: 8,
    thickness_global: 8,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2901,
    name: "L80X80X11",
    type: "Angle",
    designation: "L80X80X11",
    shape: "L",
    country_code: "European",
    ax: "16,5",
    ax_global: 1650,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: "1,55",
    r1_global: "1,55",
    t: 11,
    t_global: 11,
    width: 80,
    width_global: 80,
    thickness: 11,
    thickness_global: 11,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2902,
    name: "L75X50X10",
    type: "Angle",
    designation: "L75X50X10",
    shape: "L",
    country_code: "European",
    ax: "16,6",
    ax_global: 1660,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    r1: "1,06",
    r1_global: "1,06",
    t: 10,
    t_global: 10,
    width: 50,
    width_global: 50,
    thickness: 10,
    thickness_global: 10,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2903,
    name: "L100X75X10",
    type: "Angle",
    designation: "L100X75X10",
    shape: "L",
    country_code: "European",
    ax: "16,6",
    ax_global: 1660,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    r1: "1,59",
    r1_global: "1,59",
    t: 10,
    t_global: 10,
    width: 75,
    width_global: 75,
    thickness: 10,
    thickness_global: 10,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2904,
    name: "L75X75X12",
    type: "Angle",
    designation: "L75X75X12",
    shape: "L",
    country_code: "European",
    ax: "16,7",
    ax_global: 1670,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    r1: "1,44",
    r1_global: "1,44",
    t: 12,
    t_global: 12,
    width: 75,
    width_global: 75,
    thickness: 12,
    thickness_global: 12,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 2905,
    name: "L130X65X9",
    type: "Angle",
    designation: "L130X65X9",
    shape: "L",
    country_code: "European",
    ax: "16,91",
    ax_global: 1691,
    b: 65,
    b_global: 65,
    d: 130,
    d_global: 130,
    r1: "1,38",
    r1_global: "1,38",
    t: 9,
    t_global: 9,
    width: 65,
    width_global: 65,
    thickness: 9,
    thickness_global: 9,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 2906,
    name: "L100X65X11",
    type: "Angle",
    designation: "L100X65X11",
    shape: "L",
    country_code: "European",
    ax: 17,
    ax_global: 1700,
    b: 65,
    b_global: 65,
    d: 100,
    d_global: 100,
    r1: "1,38",
    r1_global: "1,38",
    t: 11,
    t_global: 11,
    width: 65,
    width_global: 65,
    thickness: 11,
    thickness_global: 11,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2907,
    name: "L90X90X10",
    type: "Angle",
    designation: "L90X90X10",
    shape: "L",
    country_code: "European",
    ax: "17,1",
    ax_global: 1710,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "1,75",
    r1_global: "1,75",
    t: 10,
    t_global: 10,
    width: 90,
    width_global: 90,
    thickness: 10,
    thickness_global: 10,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 2908,
    name: "L110X110X8",
    type: "Angle",
    designation: "L110X110X8",
    shape: "L",
    country_code: "European",
    ax: "17,1",
    ax_global: 1710,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    r1: "2,16",
    r1_global: "2,16",
    t: 8,
    t_global: 8,
    width: 110,
    width_global: 110,
    thickness: 8,
    thickness_global: 8,
    height: 110,
    height_global: 110,
  },
  {
    profile_section_id: 2909,
    name: "L100X100X9",
    type: "Angle",
    designation: "L100X100X9",
    shape: "L",
    country_code: "European",
    ax: "17,3",
    ax_global: 1730,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "1,95",
    r1_global: "1,95",
    t: 9,
    t_global: 9,
    width: 100,
    width_global: 100,
    thickness: 9,
    thickness_global: 9,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2910,
    name: "L120X80X9",
    type: "Angle",
    designation: "L120X80X9",
    shape: "L",
    country_code: "European",
    ax: "17,3",
    ax_global: 1730,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    r1: "1,73",
    r1_global: "1,73",
    t: 9,
    t_global: 9,
    width: 80,
    width_global: 80,
    thickness: 9,
    thickness_global: 9,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2911,
    name: "L80X80X12",
    type: "Angle",
    designation: "L80X80X12",
    shape: "L",
    country_code: "European",
    ax: "17,9",
    ax_global: 1790,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: "1,54",
    r1_global: "1,54",
    t: 12,
    t_global: 12,
    width: 80,
    width_global: 80,
    thickness: 12,
    thickness_global: 12,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2912,
    name: "L100X75X11",
    type: "Angle",
    designation: "L100X75X11",
    shape: "L",
    country_code: "European",
    ax: "18,1",
    ax_global: 1810,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    r1: "1,59",
    r1_global: "1,59",
    t: 11,
    t_global: 11,
    width: 75,
    width_global: 75,
    thickness: 11,
    thickness_global: 11,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2913,
    name: "L130X65X10",
    type: "Angle",
    designation: "L130X65X10",
    shape: "L",
    country_code: "European",
    ax: "18,6",
    ax_global: 1860,
    b: 65,
    b_global: 65,
    d: 130,
    d_global: 130,
    r1: "1,37",
    r1_global: "1,37",
    t: 10,
    t_global: 10,
    width: 65,
    width_global: 65,
    thickness: 10,
    thickness_global: 10,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 2914,
    name: "L90X90X11",
    type: "Angle",
    designation: "L90X90X11",
    shape: "L",
    country_code: "European",
    ax: "18,7",
    ax_global: 1870,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "1,74",
    r1_global: "1,74",
    t: 11,
    t_global: 11,
    width: 90,
    width_global: 90,
    thickness: 11,
    thickness_global: 11,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 2915,
    name: "L120X120X8",
    type: "Angle",
    designation: "L120X120X8",
    shape: "L",
    country_code: "European",
    ax: "18,7",
    ax_global: 1870,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    r1: "2,36",
    r1_global: "2,36",
    t: 8,
    t_global: 8,
    width: 120,
    width_global: 120,
    thickness: 8,
    thickness_global: 8,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2916,
    name: "L120X80X10",
    type: "Angle",
    designation: "L120X80X10",
    shape: "L",
    country_code: "European",
    ax: "19,1",
    ax_global: 1910,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    r1: "1,72",
    r1_global: "1,72",
    t: 10,
    t_global: 10,
    width: 80,
    width_global: 80,
    thickness: 10,
    thickness_global: 10,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2917,
    name: "L80X80X13",
    type: "Angle",
    designation: "L80X80X13",
    shape: "L",
    country_code: "European",
    ax: "19,2",
    ax_global: 1920,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: "1,54",
    r1_global: "1,54",
    t: 13,
    t_global: 13,
    width: 80,
    width_global: 80,
    thickness: 13,
    thickness_global: 13,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2918,
    name: "L100X100X10",
    type: "Angle",
    designation: "L100X100X10",
    shape: "L",
    country_code: "European",
    ax: "19,2",
    ax_global: 1920,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "1,95",
    r1_global: "1,95",
    t: 10,
    t_global: 10,
    width: 100,
    width_global: 100,
    thickness: 10,
    thickness_global: 10,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2919,
    name: "L110X110X9",
    type: "Angle",
    designation: "L110X110X9",
    shape: "L",
    country_code: "European",
    ax: "19,2",
    ax_global: 1920,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    r1: "2,15",
    r1_global: "2,15",
    t: 9,
    t_global: 9,
    width: 110,
    width_global: 110,
    thickness: 9,
    thickness_global: 9,
    height: 110,
    height_global: 110,
  },
  {
    profile_section_id: 2920,
    name: "L90X90X12",
    type: "Angle",
    designation: "L90X90X12",
    shape: "L",
    country_code: "European",
    ax: "20,3",
    ax_global: 2030,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "1,74",
    r1_global: "1,74",
    t: 12,
    t_global: 12,
    width: 90,
    width_global: 90,
    thickness: 12,
    thickness_global: 12,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 2921,
    name: "L130X65X11",
    type: "Angle",
    designation: "L130X65X11",
    shape: "L",
    country_code: "European",
    ax: "20,4",
    ax_global: 2040,
    b: 65,
    b_global: 65,
    d: 130,
    d_global: 130,
    r1: "1,37",
    r1_global: "1,37",
    t: 11,
    t_global: 11,
    width: 65,
    width_global: 65,
    thickness: 11,
    thickness_global: 11,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 2922,
    name: "L80X80X14",
    type: "Angle",
    designation: "L80X80X14",
    shape: "L",
    country_code: "European",
    ax: "20,5",
    ax_global: 2050,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: "1,54",
    r1_global: "1,54",
    t: 14,
    t_global: 14,
    width: 80,
    width_global: 80,
    thickness: 14,
    thickness_global: 14,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2923,
    name: "L100X75X12.7",
    type: "Angle",
    designation: "L100X75X12.7",
    shape: "L",
    country_code: "European",
    ax: "20,7",
    ax_global: 2070,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    r1: "1,58",
    r1_global: "1,58",
    t: "12,7",
    t_global: "12,7",
    width: 75,
    width_global: 75,
    thickness: "12,7",
    thickness_global: "12,7",
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2924,
    name: "L100X100X11",
    type: "Angle",
    designation: "L100X100X11",
    shape: "L",
    country_code: "European",
    ax: "20,9",
    ax_global: 2090,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "1,94",
    r1_global: "1,94",
    t: 11,
    t_global: 11,
    width: 100,
    width_global: 100,
    thickness: 11,
    thickness_global: 11,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2925,
    name: "L120X80X11",
    type: "Angle",
    designation: "L120X80X11",
    shape: "L",
    country_code: "European",
    ax: "20,9",
    ax_global: 2090,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    r1: "1,72",
    r1_global: "1,72",
    t: 11,
    t_global: 11,
    width: 80,
    width_global: 80,
    thickness: 11,
    thickness_global: 11,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2926,
    name: "L120X120X9",
    type: "Angle",
    designation: "L120X120X9",
    shape: "L",
    country_code: "European",
    ax: 21,
    ax_global: 2100,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    r1: "2,35",
    r1_global: "2,35",
    t: 9,
    t_global: 9,
    width: 120,
    width_global: 120,
    thickness: 9,
    thickness_global: 9,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2927,
    name: "L110X110X10",
    type: "Angle",
    designation: "L110X110X10",
    shape: "L",
    country_code: "European",
    ax: "21,2",
    ax_global: 2120,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    r1: "2,15",
    r1_global: "2,15",
    t: 10,
    t_global: 10,
    width: 110,
    width_global: 110,
    thickness: 10,
    thickness_global: 10,
    height: 110,
    height_global: 110,
  },
  {
    profile_section_id: 2928,
    name: "L90X90X13",
    type: "Angle",
    designation: "L90X90X13",
    shape: "L",
    country_code: "European",
    ax: "21,8",
    ax_global: 2180,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "1,74",
    r1_global: "1,74",
    t: 13,
    t_global: 13,
    width: 90,
    width_global: 90,
    thickness: 13,
    thickness_global: 13,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 2929,
    name: "L100X100X12",
    type: "Angle",
    designation: "L100X100X12",
    shape: "L",
    country_code: "European",
    ax: "22,7",
    ax_global: 2270,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "1,94",
    r1_global: "1,94",
    t: 12,
    t_global: 12,
    width: 100,
    width_global: 100,
    thickness: 12,
    thickness_global: 12,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2930,
    name: "L120X80X12",
    type: "Angle",
    designation: "L120X80X12",
    shape: "L",
    country_code: "European",
    ax: "22,7",
    ax_global: 2270,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    r1: "1,71",
    r1_global: "1,71",
    t: 12,
    t_global: 12,
    width: 80,
    width_global: 80,
    thickness: 12,
    thickness_global: 12,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2931,
    name: "L110X110X11",
    type: "Angle",
    designation: "L110X110X11",
    shape: "L",
    country_code: "European",
    ax: "23,2",
    ax_global: 2320,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    r1: "2,14",
    r1_global: "2,14",
    t: 11,
    t_global: 11,
    width: 110,
    width_global: 110,
    thickness: 11,
    thickness_global: 11,
    height: 110,
    height_global: 110,
  },
  {
    profile_section_id: 2932,
    name: "L120X120X10",
    type: "Angle",
    designation: "L120X120X10",
    shape: "L",
    country_code: "European",
    ax: "23,2",
    ax_global: 2320,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    r1: "2,36",
    r1_global: "2,36",
    t: 10,
    t_global: 10,
    width: 120,
    width_global: 120,
    thickness: 10,
    thickness_global: 10,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2933,
    name: "L160X80X10",
    type: "Angle",
    designation: "L160X80X10",
    shape: "L",
    country_code: "European",
    ax: "23,2",
    ax_global: 2320,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    r1: "1,7",
    r1_global: "1,7",
    t: 10,
    t_global: 10,
    width: 80,
    width_global: 80,
    thickness: 10,
    thickness_global: 10,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2934,
    name: "L90X90X14",
    type: "Angle",
    designation: "L90X90X14",
    shape: "L",
    country_code: "European",
    ax: "23,4",
    ax_global: 2340,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "1,74",
    r1_global: "1,74",
    t: 14,
    t_global: 14,
    width: 90,
    width_global: 90,
    thickness: 14,
    thickness_global: 14,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 2935,
    name: "L150X100X10",
    type: "Angle",
    designation: "L150X100X10",
    shape: "L",
    country_code: "European",
    ax: "24,2",
    ax_global: 2420,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    r1: "2,17",
    r1_global: "2,17",
    t: 10,
    t_global: 10,
    width: 100,
    width_global: 100,
    thickness: 10,
    thickness_global: 10,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2936,
    name: "L120X80X13",
    type: "Angle",
    designation: "L120X80X13",
    shape: "L",
    country_code: "European",
    ax: "24,4",
    ax_global: 2440,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    r1: "1,71",
    r1_global: "1,71",
    t: 13,
    t_global: 13,
    width: 80,
    width_global: 80,
    thickness: 13,
    thickness_global: 13,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2937,
    name: "L100X100X13",
    type: "Angle",
    designation: "L100X100X13",
    shape: "L",
    country_code: "European",
    ax: "24,5",
    ax_global: 2450,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "1,94",
    r1_global: "1,94",
    t: 13,
    t_global: 13,
    width: 100,
    width_global: 100,
    thickness: 13,
    thickness_global: 13,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2938,
    name: "L90X90X15",
    type: "Angle",
    designation: "L90X90X15",
    shape: "L",
    country_code: "European",
    ax: "24,9",
    ax_global: 2490,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "1,74",
    r1_global: "1,74",
    t: 15,
    t_global: 15,
    width: 90,
    width_global: 90,
    thickness: 15,
    thickness_global: 15,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 2939,
    name: "L110X110X12",
    type: "Angle",
    designation: "L110X110X12",
    shape: "L",
    country_code: "European",
    ax: "25,1",
    ax_global: 2510,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    r1: "2,14",
    r1_global: "2,14",
    t: 12,
    t_global: 12,
    width: 110,
    width_global: 110,
    thickness: 12,
    thickness_global: 12,
    height: 110,
    height_global: 110,
  },
  {
    profile_section_id: 2940,
    name: "L130X130X10",
    type: "Angle",
    designation: "L130X130X10",
    shape: "L",
    country_code: "European",
    ax: "25,2",
    ax_global: 2520,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    r1: "2,55",
    r1_global: "2,55",
    t: 10,
    t_global: 10,
    width: 130,
    width_global: 130,
    thickness: 10,
    thickness_global: 10,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 2941,
    name: "L120X120X11",
    type: "Angle",
    designation: "L120X120X11",
    shape: "L",
    country_code: "European",
    ax: "25,4",
    ax_global: 2540,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    r1: "2,35",
    r1_global: "2,35",
    t: 11,
    t_global: 11,
    width: 120,
    width_global: 120,
    thickness: 11,
    thickness_global: 11,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2942,
    name: "L160X80X11",
    type: "Angle",
    designation: "L160X80X11",
    shape: "L",
    country_code: "European",
    ax: "25,4",
    ax_global: 2540,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    r1: "1,7",
    r1_global: "1,7",
    t: 11,
    t_global: 11,
    width: 80,
    width_global: 80,
    thickness: 11,
    thickness_global: 11,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2943,
    name: "L100X100X14",
    type: "Angle",
    designation: "L100X100X14",
    shape: "L",
    country_code: "European",
    ax: "26,2",
    ax_global: 2620,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "1,93",
    r1_global: "1,93",
    t: 14,
    t_global: 14,
    width: 100,
    width_global: 100,
    thickness: 14,
    thickness_global: 14,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2944,
    name: "L120X80X14",
    type: "Angle",
    designation: "L120X80X14",
    shape: "L",
    country_code: "European",
    ax: "26,2",
    ax_global: 2620,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    r1: "1,7",
    r1_global: "1,7",
    t: 14,
    t_global: 14,
    width: 80,
    width_global: 80,
    thickness: 14,
    thickness_global: 14,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2945,
    name: "L90X90X16",
    type: "Angle",
    designation: "L90X90X16",
    shape: "L",
    country_code: "European",
    ax: "26,4",
    ax_global: 2640,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "1,74",
    r1_global: "1,74",
    t: 16,
    t_global: 16,
    width: 90,
    width_global: 90,
    thickness: 16,
    thickness_global: 16,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 2946,
    name: "L150X100X11",
    type: "Angle",
    designation: "L150X100X11",
    shape: "L",
    country_code: "European",
    ax: "26,5",
    ax_global: 2650,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    r1: "2,16",
    r1_global: "2,16",
    t: 11,
    t_global: 11,
    width: 100,
    width_global: 100,
    thickness: 11,
    thickness_global: 11,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2947,
    name: "L110X110X13",
    type: "Angle",
    designation: "L110X110X13",
    shape: "L",
    country_code: "European",
    ax: "27,1",
    ax_global: 2710,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    r1: "2,13",
    r1_global: "2,13",
    t: 13,
    t_global: 13,
    width: 110,
    width_global: 110,
    thickness: 13,
    thickness_global: 13,
    height: 110,
    height_global: 110,
  },
  {
    profile_section_id: 2948,
    name: "L120X120X12",
    type: "Angle",
    designation: "L120X120X12",
    shape: "L",
    country_code: "European",
    ax: "27,5",
    ax_global: 2750,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    r1: "2,34",
    r1_global: "2,34",
    t: 12,
    t_global: 12,
    width: 120,
    width_global: 120,
    thickness: 12,
    thickness_global: 12,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2949,
    name: "L150X90X12",
    type: "Angle",
    designation: "L150X90X12",
    shape: "L",
    country_code: "European",
    ax: "27,5",
    ax_global: 2750,
    b: 90,
    b_global: 90,
    d: 150,
    d_global: 150,
    r1: "1,94",
    r1_global: "1,94",
    t: 12,
    t_global: 12,
    width: 90,
    width_global: 90,
    thickness: 12,
    thickness_global: 12,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2950,
    name: "L160X80X12",
    type: "Angle",
    designation: "L160X80X12",
    shape: "L",
    country_code: "European",
    ax: "27,5",
    ax_global: 2750,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    r1: "1,69",
    r1_global: "1,69",
    t: 12,
    t_global: 12,
    width: 80,
    width_global: 80,
    thickness: 12,
    thickness_global: 12,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2951,
    name: "L130X130X11",
    type: "Angle",
    designation: "L130X130X11",
    shape: "L",
    country_code: "European",
    ax: "27,6",
    ax_global: 2760,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    r1: "2,55",
    r1_global: "2,55",
    t: 11,
    t_global: 11,
    width: 130,
    width_global: 130,
    thickness: 11,
    thickness_global: 11,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 2952,
    name: "L150X100X12",
    type: "Angle",
    designation: "L150X100X12",
    shape: "L",
    country_code: "European",
    ax: "28,7",
    ax_global: 2870,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    r1: "2,16",
    r1_global: "2,16",
    t: 12,
    t_global: 12,
    width: 100,
    width_global: 100,
    thickness: 12,
    thickness_global: 12,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2953,
    name: "L110X110X14",
    type: "Angle",
    designation: "L110X110X14",
    shape: "L",
    country_code: "European",
    ax: 29,
    ax_global: 2900,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    r1: "2,13",
    r1_global: "2,13",
    t: 14,
    t_global: 14,
    width: 110,
    width_global: 110,
    thickness: 14,
    thickness_global: 14,
    height: 110,
    height_global: 110,
  },
  {
    profile_section_id: 2954,
    name: "L200X100X10",
    type: "Angle",
    designation: "L200X100X10",
    shape: "L",
    country_code: "European",
    ax: "29,2",
    ax_global: 2920,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    r1: "2,14",
    r1_global: "2,14",
    t: 10,
    t_global: 10,
    width: 100,
    width_global: 100,
    thickness: 10,
    thickness_global: 10,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2955,
    name: "L150X150X10",
    type: "Angle",
    designation: "L150X150X10",
    shape: "L",
    country_code: "European",
    ax: "29,3",
    ax_global: 2930,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: "2,96",
    r1_global: "2,96",
    t: 10,
    t_global: 10,
    width: 150,
    width_global: 150,
    thickness: 10,
    thickness_global: 10,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2956,
    name: "L120X120X13",
    type: "Angle",
    designation: "L120X120X13",
    shape: "L",
    country_code: "European",
    ax: "29,7",
    ax_global: 2970,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    r1: "2,34",
    r1_global: "2,34",
    t: 13,
    t_global: 13,
    width: 120,
    width_global: 120,
    thickness: 13,
    thickness_global: 13,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2957,
    name: "L150X90X13",
    type: "Angle",
    designation: "L150X90X13",
    shape: "L",
    country_code: "European",
    ax: "29,7",
    ax_global: 2970,
    b: 90,
    b_global: 90,
    d: 150,
    d_global: 150,
    r1: "1,93",
    r1_global: "1,93",
    t: 13,
    t_global: 13,
    width: 90,
    width_global: 90,
    thickness: 13,
    thickness_global: 13,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2958,
    name: "L160X80X13",
    type: "Angle",
    designation: "L160X80X13",
    shape: "L",
    country_code: "European",
    ax: "29,7",
    ax_global: 2970,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    r1: "1,69",
    r1_global: "1,69",
    t: 13,
    t_global: 13,
    width: 80,
    width_global: 80,
    thickness: 13,
    thickness_global: 13,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2959,
    name: "L130X130X12",
    type: "Angle",
    designation: "L130X130X12",
    shape: "L",
    country_code: "European",
    ax: 30,
    ax_global: 3000,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    r1: "2,54",
    r1_global: "2,54",
    t: 12,
    t_global: 12,
    width: 130,
    width_global: 130,
    thickness: 12,
    thickness_global: 12,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 2960,
    name: "L150X100X13",
    type: "Angle",
    designation: "L150X100X13",
    shape: "L",
    country_code: "European",
    ax: 31,
    ax_global: 3100,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    r1: "2,15",
    r1_global: "2,15",
    t: 13,
    t_global: 13,
    width: 100,
    width_global: 100,
    thickness: 13,
    thickness_global: 13,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2961,
    name: "L120X120X14",
    type: "Angle",
    designation: "L120X120X14",
    shape: "L",
    country_code: "European",
    ax: "31,8",
    ax_global: 3180,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    r1: "2,33",
    r1_global: "2,33",
    t: 14,
    t_global: 14,
    width: 120,
    width_global: 120,
    thickness: 14,
    thickness_global: 14,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2962,
    name: "L150X90X14",
    type: "Angle",
    designation: "L150X90X14",
    shape: "L",
    country_code: "European",
    ax: "31,8",
    ax_global: 3180,
    b: 90,
    b_global: 90,
    d: 150,
    d_global: 150,
    r1: "1,93",
    r1_global: "1,93",
    t: 14,
    t_global: 14,
    width: 90,
    width_global: 90,
    thickness: 14,
    thickness_global: 14,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2963,
    name: "L160X80X14",
    type: "Angle",
    designation: "L160X80X14",
    shape: "L",
    country_code: "European",
    ax: "31,8",
    ax_global: 3180,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    r1: "1,68",
    r1_global: "1,68",
    t: 14,
    t_global: 14,
    width: 80,
    width_global: 80,
    thickness: 14,
    thickness_global: 14,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2964,
    name: "L200X100X11",
    type: "Angle",
    designation: "L200X100X11",
    shape: "L",
    country_code: "European",
    ax: 32,
    ax_global: 3200,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    r1: "2,14",
    r1_global: "2,14",
    t: 11,
    t_global: 11,
    width: 100,
    width_global: 100,
    thickness: 11,
    thickness_global: 11,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2965,
    name: "L150X150X11",
    type: "Angle",
    designation: "L150X150X11",
    shape: "L",
    country_code: "European",
    ax: "32,1",
    ax_global: 3210,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: "2,95",
    r1_global: "2,95",
    t: 11,
    t_global: 11,
    width: 150,
    width_global: 150,
    thickness: 11,
    thickness_global: 11,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2966,
    name: "L130X130X13",
    type: "Angle",
    designation: "L130X130X13",
    shape: "L",
    country_code: "European",
    ax: "32,3",
    ax_global: 3230,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    r1: "2,54",
    r1_global: "2,54",
    t: 13,
    t_global: 13,
    width: 130,
    width_global: 130,
    thickness: 13,
    thickness_global: 13,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 2967,
    name: "L140X140X12",
    type: "Angle",
    designation: "L140X140X12",
    shape: "L",
    country_code: "European",
    ax: "32,4",
    ax_global: 3240,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    r1: "2,74",
    r1_global: "2,74",
    t: 12,
    t_global: 12,
    width: 140,
    width_global: 140,
    thickness: 12,
    thickness_global: 12,
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 2968,
    name: "L150X100X14",
    type: "Angle",
    designation: "L150X100X14",
    shape: "L",
    country_code: "European",
    ax: "33,2",
    ax_global: 3320,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    r1: "2,15",
    r1_global: "2,15",
    t: 14,
    t_global: 14,
    width: 100,
    width_global: 100,
    thickness: 14,
    thickness_global: 14,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2969,
    name: "L120X120X15",
    type: "Angle",
    designation: "L120X120X15",
    shape: "L",
    country_code: "European",
    ax: "33,9",
    ax_global: 3390,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    r1: "2,33",
    r1_global: "2,33",
    t: 15,
    t_global: 15,
    width: 120,
    width_global: 120,
    thickness: 15,
    thickness_global: 15,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2970,
    name: "L150X90X15",
    type: "Angle",
    designation: "L150X90X15",
    shape: "L",
    country_code: "European",
    ax: "33,9",
    ax_global: 3390,
    b: 90,
    b_global: 90,
    d: 150,
    d_global: 150,
    r1: "1,93",
    r1_global: "1,93",
    t: 15,
    t_global: 15,
    width: 90,
    width_global: 90,
    thickness: 15,
    thickness_global: 15,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2971,
    name: "L130X130X14",
    type: "Angle",
    designation: "L130X130X14",
    shape: "L",
    country_code: "European",
    ax: "34,7",
    ax_global: 3470,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    r1: "2,53",
    r1_global: "2,53",
    t: 14,
    t_global: 14,
    width: 130,
    width_global: 130,
    thickness: 14,
    thickness_global: 14,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 2972,
    name: "L150X150X12",
    type: "Angle",
    designation: "L150X150X12",
    shape: "L",
    country_code: "European",
    ax: "34,8",
    ax_global: 3480,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: "2,94",
    r1_global: "2,94",
    t: 12,
    t_global: 12,
    width: 150,
    width_global: 150,
    thickness: 12,
    thickness_global: 12,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2973,
    name: "L200X100X12",
    type: "Angle",
    designation: "L200X100X12",
    shape: "L",
    country_code: "European",
    ax: "34,8",
    ax_global: 3480,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    r1: "2,13",
    r1_global: "2,13",
    t: 12,
    t_global: 12,
    width: 100,
    width_global: 100,
    thickness: 12,
    thickness_global: 12,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2974,
    name: "L140X140X13",
    type: "Angle",
    designation: "L140X140X13",
    shape: "L",
    country_code: "European",
    ax: 35,
    ax_global: 3500,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    r1: "2,74",
    r1_global: "2,74",
    t: 13,
    t_global: 13,
    width: 140,
    width_global: 140,
    thickness: 13,
    thickness_global: 13,
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 2975,
    name: "L150X100X15",
    type: "Angle",
    designation: "L150X100X15",
    shape: "L",
    country_code: "European",
    ax: "35,4",
    ax_global: 3540,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    r1: "2,14",
    r1_global: "2,14",
    t: 15,
    t_global: 15,
    width: 100,
    width_global: 100,
    thickness: 15,
    thickness_global: 15,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2976,
    name: "L130X130X15",
    type: "Angle",
    designation: "L130X130X15",
    shape: "L",
    country_code: "European",
    ax: 37,
    ax_global: 3700,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    r1: "2,53",
    r1_global: "2,53",
    t: 15,
    t_global: 15,
    width: 130,
    width_global: 130,
    thickness: 15,
    thickness_global: 15,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 2977,
    name: "L140X140X14",
    type: "Angle",
    designation: "L140X140X14",
    shape: "L",
    country_code: "European",
    ax: "37,5",
    ax_global: 3750,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    r1: "2,73",
    r1_global: "2,73",
    t: 14,
    t_global: 14,
    width: 140,
    width_global: 140,
    thickness: 14,
    thickness_global: 14,
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 2978,
    name: "L150X150X13",
    type: "Angle",
    designation: "L150X150X13",
    shape: "L",
    country_code: "European",
    ax: "37,6",
    ax_global: 3760,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: "2,94",
    r1_global: "2,94",
    t: 13,
    t_global: 13,
    width: 150,
    width_global: 150,
    thickness: 13,
    thickness_global: 13,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2979,
    name: "L200X100X13",
    type: "Angle",
    designation: "L200X100X13",
    shape: "L",
    country_code: "European",
    ax: "37,6",
    ax_global: 3760,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    r1: "2,13",
    r1_global: "2,13",
    t: 13,
    t_global: 13,
    width: 100,
    width_global: 100,
    thickness: 13,
    thickness_global: 13,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2980,
    name: "L130X130X16",
    type: "Angle",
    designation: "L130X130X16",
    shape: "L",
    country_code: "European",
    ax: "39,3",
    ax_global: 3930,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    r1: "2,52",
    r1_global: "2,52",
    t: 16,
    t_global: 16,
    width: 130,
    width_global: 130,
    thickness: 16,
    thickness_global: 16,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 2981,
    name: "L140X140X15",
    type: "Angle",
    designation: "L140X140X15",
    shape: "L",
    country_code: "European",
    ax: 40,
    ax_global: 4000,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    r1: "2,73",
    r1_global: "2,73",
    t: 15,
    t_global: 15,
    width: 140,
    width_global: 140,
    thickness: 15,
    thickness_global: 15,
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 2982,
    name: "L150X150X14",
    type: "Angle",
    designation: "L150X150X14",
    shape: "L",
    country_code: "European",
    ax: "40,3",
    ax_global: 4030,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: "2,93",
    r1_global: "2,93",
    t: 14,
    t_global: 14,
    width: 150,
    width_global: 150,
    thickness: 14,
    thickness_global: 14,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2983,
    name: "L200X100X14",
    type: "Angle",
    designation: "L200X100X14",
    shape: "L",
    country_code: "European",
    ax: "40,3",
    ax_global: 4030,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    r1: "2,12",
    r1_global: "2,12",
    t: 14,
    t_global: 14,
    width: 100,
    width_global: 100,
    thickness: 14,
    thickness_global: 14,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2984,
    name: "L140X140X16",
    type: "Angle",
    designation: "L140X140X16",
    shape: "L",
    country_code: "European",
    ax: "42,5",
    ax_global: 4250,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    r1: "2,72",
    r1_global: "2,72",
    t: 16,
    t_global: 16,
    width: 140,
    width_global: 140,
    thickness: 16,
    thickness_global: 16,
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 2985,
    name: "L150X150X15",
    type: "Angle",
    designation: "L150X150X15",
    shape: "L",
    country_code: "European",
    ax: 43,
    ax_global: 4300,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: "2,93",
    r1_global: "2,93",
    t: 15,
    t_global: 15,
    width: 150,
    width_global: 150,
    thickness: 15,
    thickness_global: 15,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2986,
    name: "L200X100X15",
    type: "Angle",
    designation: "L200X100X15",
    shape: "L",
    country_code: "European",
    ax: 43,
    ax_global: 4300,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    r1: "2,12",
    r1_global: "2,12",
    t: 15,
    t_global: 15,
    width: 100,
    width_global: 100,
    thickness: 15,
    thickness_global: 15,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2987,
    name: "L200X200X11",
    type: "Angle",
    designation: "L200X200X11",
    shape: "L",
    country_code: "European",
    ax: "43,1",
    ax_global: 4310,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,97",
    r1_global: "3,97",
    t: 11,
    t_global: 11,
    width: 200,
    width_global: 200,
    thickness: 11,
    thickness_global: 11,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2988,
    name: "L180X180X13",
    type: "Angle",
    designation: "L180X180X13",
    shape: "L",
    country_code: "European",
    ax: "45,5",
    ax_global: 4550,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    r1: "3,55",
    r1_global: "3,55",
    t: 13,
    t_global: 13,
    width: 180,
    width_global: 180,
    thickness: 13,
    thickness_global: 13,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 2989,
    name: "L150X150X16",
    type: "Angle",
    designation: "L150X150X16",
    shape: "L",
    country_code: "European",
    ax: "45,7",
    ax_global: 4570,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: "2,92",
    r1_global: "2,92",
    t: 16,
    t_global: 16,
    width: 150,
    width_global: 150,
    thickness: 16,
    thickness_global: 16,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2990,
    name: "L200X100X16",
    type: "Angle",
    designation: "L200X100X16",
    shape: "L",
    country_code: "European",
    ax: "45,7",
    ax_global: 4570,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    r1: "2,11",
    r1_global: "2,11",
    t: 16,
    t_global: 16,
    width: 100,
    width_global: 100,
    thickness: 16,
    thickness_global: 16,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2991,
    name: "L160X160X15",
    type: "Angle",
    designation: "L160X160X15",
    shape: "L",
    country_code: "European",
    ax: "46,1",
    ax_global: 4610,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    r1: "3,13",
    r1_global: "3,13",
    t: 15,
    t_global: 15,
    width: 160,
    width_global: 160,
    thickness: 15,
    thickness_global: 15,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2992,
    name: "L200X200X12",
    type: "Angle",
    designation: "L200X200X12",
    shape: "L",
    country_code: "European",
    ax: "46,9",
    ax_global: 4690,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,96",
    r1_global: "3,96",
    t: 12,
    t_global: 12,
    width: 200,
    width_global: 200,
    thickness: 12,
    thickness_global: 12,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2993,
    name: "L150X150X17",
    type: "Angle",
    designation: "L150X150X17",
    shape: "L",
    country_code: "European",
    ax: "48,4",
    ax_global: 4840,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: "2,92",
    r1_global: "2,92",
    t: 17,
    t_global: 17,
    width: 150,
    width_global: 150,
    thickness: 17,
    thickness_global: 17,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2994,
    name: "L180X180X14",
    type: "Angle",
    designation: "L180X180X14",
    shape: "L",
    country_code: "European",
    ax: "48,8",
    ax_global: 4880,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    r1: "3,54",
    r1_global: "3,54",
    t: 14,
    t_global: 14,
    width: 180,
    width_global: 180,
    thickness: 14,
    thickness_global: 14,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 2995,
    name: "L160X160X16",
    type: "Angle",
    designation: "L160X160X16",
    shape: "L",
    country_code: "European",
    ax: 49,
    ax_global: 4900,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    r1: "3,12",
    r1_global: "3,12",
    t: 16,
    t_global: 16,
    width: 160,
    width_global: 160,
    thickness: 16,
    thickness_global: 16,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2996,
    name: "L200X200X13",
    type: "Angle",
    designation: "L200X200X13",
    shape: "L",
    country_code: "European",
    ax: "50,7",
    ax_global: 5070,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,96",
    r1_global: "3,96",
    t: 13,
    t_global: 13,
    width: 200,
    width_global: 200,
    thickness: 13,
    thickness_global: 13,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2997,
    name: "L150X150X18",
    type: "Angle",
    designation: "L150X150X18",
    shape: "L",
    country_code: "European",
    ax: 51,
    ax_global: 5100,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: "2,92",
    r1_global: "2,92",
    t: 18,
    t_global: 18,
    width: 150,
    width_global: 150,
    thickness: 18,
    thickness_global: 18,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 2998,
    name: "L160X160X17",
    type: "Angle",
    designation: "L160X160X17",
    shape: "L",
    country_code: "European",
    ax: "51,8",
    ax_global: 5180,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    r1: "3,12",
    r1_global: "3,12",
    t: 17,
    t_global: 17,
    width: 160,
    width_global: 160,
    thickness: 17,
    thickness_global: 17,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2999,
    name: "L180X180X15",
    type: "Angle",
    designation: "L180X180X15",
    shape: "L",
    country_code: "European",
    ax: "52,1",
    ax_global: 5210,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    r1: "3,53",
    r1_global: "3,53",
    t: 15,
    t_global: 15,
    width: 180,
    width_global: 180,
    thickness: 15,
    thickness_global: 15,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 3000,
    name: "L150X150X19",
    type: "Angle",
    designation: "L150X150X19",
    shape: "L",
    country_code: "European",
    ax: "53,7",
    ax_global: 5370,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: "2,91",
    r1_global: "2,91",
    t: 19,
    t_global: 19,
    width: 150,
    width_global: 150,
    thickness: 19,
    thickness_global: 19,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3001,
    name: "L200X200X14",
    type: "Angle",
    designation: "L200X200X14",
    shape: "L",
    country_code: "European",
    ax: "54,4",
    ax_global: 5440,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,95",
    r1_global: "3,95",
    t: 14,
    t_global: 14,
    width: 200,
    width_global: 200,
    thickness: 14,
    thickness_global: 14,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3002,
    name: "L160X160X18",
    type: "Angle",
    designation: "L160X160X18",
    shape: "L",
    country_code: "European",
    ax: "54,7",
    ax_global: 5470,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    r1: "3,11",
    r1_global: "3,11",
    t: 18,
    t_global: 18,
    width: 160,
    width_global: 160,
    thickness: 18,
    thickness_global: 18,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 3003,
    name: "L180X180X16",
    type: "Angle",
    designation: "L180X180X16",
    shape: "L",
    country_code: "European",
    ax: "55,4",
    ax_global: 5540,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    r1: "3,53",
    r1_global: "3,53",
    t: 16,
    t_global: 16,
    width: 180,
    width_global: 180,
    thickness: 16,
    thickness_global: 16,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 3004,
    name: "L150X150X20",
    type: "Angle",
    designation: "L150X150X20",
    shape: "L",
    country_code: "European",
    ax: "56,3",
    ax_global: 5630,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: "2,91",
    r1_global: "2,91",
    t: 20,
    t_global: 20,
    width: 150,
    width_global: 150,
    thickness: 20,
    thickness_global: 20,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3005,
    name: "L160X160X19",
    type: "Angle",
    designation: "L160X160X19",
    shape: "L",
    country_code: "European",
    ax: "57,5",
    ax_global: 5750,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    r1: "3,11",
    r1_global: "3,11",
    t: 19,
    t_global: 19,
    width: 160,
    width_global: 160,
    thickness: 19,
    thickness_global: 19,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 3006,
    name: "L200X200X15",
    type: "Angle",
    designation: "L200X200X15",
    shape: "L",
    country_code: "European",
    ax: "58,1",
    ax_global: 5810,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,94",
    r1_global: "3,94",
    t: 15,
    t_global: 15,
    width: 200,
    width_global: 200,
    thickness: 15,
    thickness_global: 15,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3007,
    name: "L180X180X17",
    type: "Angle",
    designation: "L180X180X17",
    shape: "L",
    country_code: "European",
    ax: "58,7",
    ax_global: 5870,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    r1: "3,52",
    r1_global: "3,52",
    t: 17,
    t_global: 17,
    width: 180,
    width_global: 180,
    thickness: 17,
    thickness_global: 17,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 3008,
    name: "L160X160X20",
    type: "Angle",
    designation: "L160X160X20",
    shape: "L",
    country_code: "European",
    ax: "60,3",
    ax_global: 6030,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    r1: "3,11",
    r1_global: "3,11",
    t: 20,
    t_global: 20,
    width: 160,
    width_global: 160,
    thickness: 20,
    thickness_global: 20,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 3009,
    name: "L200X200X16",
    type: "Angle",
    designation: "L200X200X16",
    shape: "L",
    country_code: "European",
    ax: "61,8",
    ax_global: 6180,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,94",
    r1_global: "3,94",
    t: 16,
    t_global: 16,
    width: 200,
    width_global: 200,
    thickness: 16,
    thickness_global: 16,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3010,
    name: "L180X180X18",
    type: "Angle",
    designation: "L180X180X18",
    shape: "L",
    country_code: "European",
    ax: "61,9",
    ax_global: 6190,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    r1: "3,52",
    r1_global: "3,52",
    t: 18,
    t_global: 18,
    width: 180,
    width_global: 180,
    thickness: 18,
    thickness_global: 18,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 3011,
    name: "L180X180X19",
    type: "Angle",
    designation: "L180X180X19",
    shape: "L",
    country_code: "European",
    ax: "65,1",
    ax_global: 6510,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    r1: "3,51",
    r1_global: "3,51",
    t: 19,
    t_global: 19,
    width: 180,
    width_global: 180,
    thickness: 19,
    thickness_global: 19,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 3012,
    name: "L200X200X17",
    type: "Angle",
    designation: "L200X200X17",
    shape: "L",
    country_code: "European",
    ax: "65,5",
    ax_global: 6550,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,93",
    r1_global: "3,93",
    t: 17,
    t_global: 17,
    width: 200,
    width_global: 200,
    thickness: 17,
    thickness_global: 17,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3013,
    name: "L180X180X20",
    type: "Angle",
    designation: "L180X180X20",
    shape: "L",
    country_code: "European",
    ax: "68,3",
    ax_global: 6830,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    r1: "3,51",
    r1_global: "3,51",
    t: 20,
    t_global: 20,
    width: 180,
    width_global: 180,
    thickness: 20,
    thickness_global: 20,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 3014,
    name: "L200X200X18",
    type: "Angle",
    designation: "L200X200X18",
    shape: "L",
    country_code: "European",
    ax: "69,1",
    ax_global: 6910,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,92",
    r1_global: "3,92",
    t: 18,
    t_global: 18,
    width: 200,
    width_global: 200,
    thickness: 18,
    thickness_global: 18,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3015,
    name: "L200X200X19",
    type: "Angle",
    designation: "L200X200X19",
    shape: "L",
    country_code: "European",
    ax: "72,7",
    ax_global: 7270,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,92",
    r1_global: "3,92",
    t: 19,
    t_global: 19,
    width: 200,
    width_global: 200,
    thickness: 19,
    thickness_global: 19,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3016,
    name: "L200X200X20",
    type: "Angle",
    designation: "L200X200X20",
    shape: "L",
    country_code: "European",
    ax: "76,3",
    ax_global: 7630,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,91",
    r1_global: "3,91",
    t: 20,
    t_global: 20,
    width: 200,
    width_global: 200,
    thickness: 20,
    thickness_global: 20,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3017,
    name: "L200X200X21",
    type: "Angle",
    designation: "L200X200X21",
    shape: "L",
    country_code: "European",
    ax: "79,9",
    ax_global: 7990,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,91",
    r1_global: "3,91",
    t: 21,
    t_global: 21,
    width: 200,
    width_global: 200,
    thickness: 21,
    thickness_global: 21,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3018,
    name: "L200X200X22",
    type: "Angle",
    designation: "L200X200X22",
    shape: "L",
    country_code: "European",
    ax: "83,5",
    ax_global: 8350,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,9",
    r1_global: "3,9",
    t: 22,
    t_global: 22,
    width: 200,
    width_global: 200,
    thickness: 22,
    thickness_global: 22,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3019,
    name: "L200X200X23",
    type: "Angle",
    designation: "L200X200X23",
    shape: "L",
    country_code: "European",
    ax: "87,1",
    ax_global: 8710,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,9",
    r1_global: "3,9",
    t: 23,
    t_global: 23,
    width: 200,
    width_global: 200,
    thickness: 23,
    thickness_global: 23,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3020,
    name: "L200X200X24",
    type: "Angle",
    designation: "L200X200X24",
    shape: "L",
    country_code: "European",
    ax: "90,6",
    ax_global: 9060,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,9",
    r1_global: "3,9",
    t: 24,
    t_global: 24,
    width: 200,
    width_global: 200,
    thickness: 24,
    thickness_global: 24,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3021,
    name: "L200X200X25",
    type: "Angle",
    designation: "L200X200X25",
    shape: "L",
    country_code: "European",
    ax: "94,1",
    ax_global: 9410,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,89",
    r1_global: "3,89",
    t: 25,
    t_global: 25,
    width: 200,
    width_global: 200,
    thickness: 25,
    thickness_global: 25,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3022,
    name: "L200X200X26",
    type: "Angle",
    designation: "L200X200X26",
    shape: "L",
    country_code: "European",
    ax: "97,6",
    ax_global: 9760,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: "3,89",
    r1_global: "3,89",
    t: 26,
    t_global: 26,
    width: 200,
    width_global: 200,
    thickness: 26,
    thickness_global: 26,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3023,
    name: "L140X140X10",
    type: "Angle",
    designation: "L140X140X10",
    shape: "L",
    country_code: "European",
    ax: "27,2",
    ax_global: 2720,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    r1: "2,76",
    r1_global: "2,76",
    t: 10,
    t_global: 10,
    width: 140,
    width_global: 140,
    thickness: 10,
    thickness_global: 10,
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 3024,
    name: "L160X160X14",
    type: "Angle",
    designation: "L160X160X14",
    shape: "L",
    country_code: "European",
    ax: "43,2",
    ax_global: 4320,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    r1: "3,13",
    r1_global: "3,13",
    t: 14,
    t_global: 14,
    width: 160,
    width_global: 160,
    thickness: 14,
    thickness_global: 14,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 3025,
    name: "L250X250X20",
    type: "Angle",
    designation: "L250X250X20",
    shape: "L",
    country_code: "European",
    ax: "96,4",
    ax_global: 9640,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    r1: "4,93",
    r1_global: "4,93",
    t: 20,
    t_global: 20,
    width: 250,
    width_global: 250,
    thickness: 20,
    thickness_global: 20,
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3026,
    name: "L250X250X21",
    type: "Angle",
    designation: "L250X250X21",
    shape: "L",
    country_code: "European",
    ax: 101,
    ax_global: 10100,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    r1: "4,92",
    r1_global: "4,92",
    t: 21,
    t_global: 21,
    width: 250,
    width_global: 250,
    thickness: 21,
    thickness_global: 21,
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3027,
    name: "L250X250X22",
    type: "Angle",
    designation: "L250X250X22",
    shape: "L",
    country_code: "European",
    ax: 106,
    ax_global: 10600,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    r1: "4,92",
    r1_global: "4,92",
    t: 22,
    t_global: 22,
    width: 250,
    width_global: 250,
    thickness: 22,
    thickness_global: 22,
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3028,
    name: "L250X250X23",
    type: "Angle",
    designation: "L250X250X23",
    shape: "L",
    country_code: "European",
    ax: 110,
    ax_global: 11000,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    r1: "4,91",
    r1_global: "4,91",
    t: 23,
    t_global: 23,
    width: 250,
    width_global: 250,
    thickness: 23,
    thickness_global: 23,
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3029,
    name: "L250X250X24",
    type: "Angle",
    designation: "L250X250X24",
    shape: "L",
    country_code: "European",
    ax: 115,
    ax_global: 11500,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    r1: "4,91",
    r1_global: "4,91",
    t: 24,
    t_global: 24,
    width: 250,
    width_global: 250,
    thickness: 24,
    thickness_global: 24,
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3030,
    name: "L250X250X25",
    type: "Angle",
    designation: "L250X250X25",
    shape: "L",
    country_code: "European",
    ax: 119,
    ax_global: 11900,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    r1: "4,9",
    r1_global: "4,9",
    t: 25,
    t_global: 25,
    width: 250,
    width_global: 250,
    thickness: 25,
    thickness_global: 25,
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3031,
    name: "L250X250X26",
    type: "Angle",
    designation: "L250X250X26",
    shape: "L",
    country_code: "European",
    ax: 124,
    ax_global: 12400,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    r1: "4,9",
    r1_global: "4,9",
    t: 26,
    t_global: 26,
    width: 250,
    width_global: 250,
    thickness: 26,
    thickness_global: 26,
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3032,
    name: "L250X250X27",
    type: "Angle",
    designation: "L250X250X27",
    shape: "L",
    country_code: "European",
    ax: 128,
    ax_global: 12800,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    r1: "4,89",
    r1_global: "4,89",
    t: 27,
    t_global: 27,
    width: 250,
    width_global: 250,
    thickness: 27,
    thickness_global: 27,
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3033,
    name: "L250X250X28",
    type: "Angle",
    designation: "L250X250X28",
    shape: "L",
    country_code: "European",
    ax: 133,
    ax_global: 13300,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    r1: "4,89",
    r1_global: "4,89",
    t: 28,
    t_global: 28,
    width: 250,
    width_global: 250,
    thickness: 28,
    thickness_global: 28,
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3034,
    name: "L250X250X35",
    type: "Angle",
    designation: "L250X250X35",
    shape: "L",
    country_code: "European",
    ax: 163,
    ax_global: 16300,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    r1: "4,86",
    r1_global: "4,86",
    t: 35,
    t_global: 35,
    width: 250,
    width_global: 250,
    thickness: 35,
    thickness_global: 35,
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3035,
    name: "L203X203X19",
    type: "Angle",
    designation: "L203X203X19",
    shape: "L",
    country_code: "European",
    ax: "73,6",
    ax_global: 7360,
    b: 203,
    b_global: 203,
    d: 203,
    d_global: 203,
    r1: "3,9",
    r1_global: "3,9",
    t: 19,
    t_global: 19,
    width: 203,
    width_global: 203,
    thickness: 19,
    thickness_global: 19,
    height: 203,
    height_global: 203,
  },
  {
    profile_section_id: 3036,
    name: "L203X203X22.2",
    type: "Angle",
    designation: "L203X203X22.2",
    shape: "L",
    country_code: "European",
    ax: 85,
    ax_global: 8500,
    b: 203,
    b_global: 203,
    d: 203,
    d_global: 203,
    r1: "3,9",
    r1_global: "3,9",
    t: "22,2",
    t_global: "22,2",
    width: 203,
    width_global: 203,
    thickness: "22,2",
    thickness_global: "22,2",
    height: 203,
    height_global: 203,
  },
  {
    profile_section_id: 3037,
    name: "L203X203X25.4",
    type: "Angle",
    designation: "L203X203X25.4",
    shape: "L",
    country_code: "European",
    ax: "96,8",
    ax_global: 9680,
    b: 203,
    b_global: 203,
    d: 203,
    d_global: 203,
    r1: "3,9",
    r1_global: "3,9",
    t: "25,4",
    t_global: "25,4",
    width: 203,
    width_global: 203,
    thickness: "25,4",
    thickness_global: "25,4",
    height: 203,
    height_global: 203,
  },
  {
    profile_section_id: 3038,
    name: "L203X203X28.6",
    type: "Angle",
    designation: "L203X203X28.6",
    shape: "L",
    country_code: "European",
    ax: 108,
    ax_global: 10800,
    b: 203,
    b_global: 203,
    d: 203,
    d_global: 203,
    r1: "3,9",
    r1_global: "3,9",
    t: "28,6",
    t_global: "28,6",
    width: 203,
    width_global: 203,
    thickness: "28,6",
    thickness_global: "28,6",
    height: 203,
    height_global: 203,
  },
  {
    profile_section_id: 3039,
    name: "L150X75X9",
    type: "Angle",
    designation: "L150X75X9",
    shape: "L",
    country_code: "European",
    ax: "19,6",
    ax_global: 1960,
    b: 75,
    b_global: 75,
    d: 150,
    d_global: 150,
    r1: "1,6",
    r1_global: "1,6",
    t: 9,
    t_global: 9,
    width: 75,
    width_global: 75,
    thickness: 9,
    thickness_global: 9,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3040,
    name: "L150X75X10",
    type: "Angle",
    designation: "L150X75X10",
    shape: "L",
    country_code: "European",
    ax: "21,7",
    ax_global: 2170,
    b: 75,
    b_global: 75,
    d: 150,
    d_global: 150,
    r1: "1,59",
    r1_global: "1,59",
    t: 10,
    t_global: 10,
    width: 75,
    width_global: 75,
    thickness: 10,
    thickness_global: 10,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3041,
    name: "L150X75X11",
    type: "Angle",
    designation: "L150X75X11",
    shape: "L",
    country_code: "European",
    ax: "23,7",
    ax_global: 2370,
    b: 75,
    b_global: 75,
    d: 150,
    d_global: 150,
    r1: "1,59",
    r1_global: "1,59",
    t: 11,
    t_global: 11,
    width: 75,
    width_global: 75,
    thickness: 11,
    thickness_global: 11,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3042,
    name: "L150X75X12",
    type: "Angle",
    designation: "L150X75X12",
    shape: "L",
    country_code: "European",
    ax: "25,7",
    ax_global: 2570,
    b: 75,
    b_global: 75,
    d: 150,
    d_global: 150,
    r1: "1,58",
    r1_global: "1,58",
    t: 12,
    t_global: 12,
    width: 75,
    width_global: 75,
    thickness: 12,
    thickness_global: 12,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3043,
    name: "L150X90X10",
    type: "Angle",
    designation: "L150X90X10",
    shape: "L",
    country_code: "European",
    ax: "23,2",
    ax_global: 2320,
    b: 90,
    b_global: 90,
    d: 150,
    d_global: 150,
    r1: "1,95",
    r1_global: "1,95",
    t: 10,
    t_global: 10,
    width: 90,
    width_global: 90,
    thickness: 10,
    thickness_global: 10,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3044,
    name: "L150X90X11",
    type: "Angle",
    designation: "L150X90X11",
    shape: "L",
    country_code: "European",
    ax: "25,3",
    ax_global: 2530,
    b: 90,
    b_global: 90,
    d: 150,
    d_global: 150,
    r1: "1,94",
    r1_global: "1,94",
    t: 11,
    t_global: 11,
    width: 90,
    width_global: 90,
    thickness: 11,
    thickness_global: 11,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3045,
    name: "50x30x2.6 RHS",
    type: "Rectangular Hollow",
    designation: "50x30x2.6RHS",
    shape: "RHS",
    country_code: "European",
    ax: "3,82",
    ax_global: 382,
    b: 30,
    b_global: 30,
    c: 0,
    c_global: 0,
    d: 50,
    d_global: 50,
    ix: "12,1",
    ix_global: 121000,
    iy: "5,38",
    iy_global: 53800,
    iz: "12,2",
    iz_global: 122000,
    t: "2,6",
    t_global: "2,6",
    zx: "6,12",
    zx_global: 6120,
    zy: "4,25",
    zy_global: 4250,
  },
  {
    profile_section_id: 3046,
    name: "50x30x3.2 RHS",
    type: "Rectangular Hollow",
    designation: "50x30x3.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: "4,6",
    ax_global: 460,
    b: 30,
    b_global: 30,
    c: 0,
    c_global: 0,
    d: 50,
    d_global: 50,
    ix: "14,2",
    ix_global: 142000,
    iy: "6,2",
    iy_global: 62000,
    iz: "14,2",
    iz_global: 142000,
    t: "3,2",
    t_global: "3,2",
    zx: "7,25",
    zx_global: 7250,
    zy: 5,
    zy_global: 5000,
  },
  {
    profile_section_id: 3047,
    name: "50x30x4 RHS",
    type: "Rectangular Hollow",
    designation: "50x30x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: "5,59",
    ax_global: 559,
    b: 30,
    b_global: 30,
    c: 0,
    c_global: 0,
    d: 50,
    d_global: 50,
    ix: "16,6",
    ix_global: 166000,
    iy: "7,08",
    iy_global: 70800,
    iz: "16,5",
    iz_global: 165000,
    t: 4,
    t_global: 4,
    zx: "8,59",
    zx_global: 8590,
    zy: "5,88",
    zy_global: 5880,
  },
  {
    profile_section_id: 3048,
    name: "50x30x5 RHS",
    type: "Rectangular Hollow",
    designation: "50x30x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "6,73",
    ax_global: 673,
    b: 30,
    b_global: 30,
    c: 0,
    c_global: 0,
    d: 50,
    d_global: 50,
    ix: 19,
    ix_global: 190000,
    iy: "7,89",
    iy_global: 78900,
    iz: "18,7",
    iz_global: 187000,
    t: 5,
    t_global: 5,
    zx: 10,
    zx_global: 10000,
    zy: "6,8",
    zy_global: 6800,
  },
  {
    profile_section_id: 3049,
    name: "60x40x2.6 RHS",
    type: "Rectangular Hollow",
    designation: "60x40x2.6RHS",
    shape: "RHS",
    country_code: "European",
    ax: "4,86",
    ax_global: 486,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 60,
    d_global: 60,
    ix: "25,9",
    ix_global: 259000,
    iy: "12,4",
    iy_global: 124000,
    iz: "23,6",
    iz_global: 236000,
    t: "2,6",
    t_global: "2,6",
    zx: "9,65",
    zx_global: 9650,
    zy: "7,26",
    zy_global: 7260,
  },
  {
    profile_section_id: 3050,
    name: "60x40x3.2 RHS",
    type: "Rectangular Hollow",
    designation: "60x40x3.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: "5,88",
    ax_global: 588,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 60,
    d_global: 60,
    ix: "30,8",
    ix_global: 308000,
    iy: "14,6",
    iy_global: 146000,
    iz: "27,8",
    iz_global: 278000,
    t: "3,2",
    t_global: "3,2",
    zx: "11,5",
    zx_global: 11500,
    zy: "8,64",
    zy_global: 8640,
  },
  {
    profile_section_id: 3051,
    name: "60x40x4 RHS",
    type: "Rectangular Hollow",
    designation: "60x40x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: "7,19",
    ax_global: 719,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 60,
    d_global: 60,
    ix: "36,7",
    ix_global: 367000,
    iy: 17,
    iy_global: 170000,
    iz: "32,8",
    iz_global: 328000,
    t: 4,
    t_global: 4,
    zx: "13,8",
    zx_global: 13800,
    zy: "10,3",
    zy_global: 10300,
  },
  {
    profile_section_id: 3052,
    name: "60x40x5 RHS",
    type: "Rectangular Hollow",
    designation: "60x40x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "8,73",
    ax_global: 873,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 60,
    d_global: 60,
    ix: 43,
    ix_global: 430000,
    iy: "19,5",
    iy_global: 195000,
    iz: "38,1",
    iz_global: 381000,
    t: 5,
    t_global: 5,
    zx: "16,4",
    zx_global: 16400,
    zy: "12,2",
    zy_global: 12200,
  },
  {
    profile_section_id: 3053,
    name: "60x40x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "60x40x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "10,6",
    ax_global: 1060,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 60,
    d_global: 60,
    ix: "49,5",
    ix_global: 495000,
    iy: "21,9",
    iy_global: 219000,
    iz: "43,4",
    iz_global: 434000,
    t: "6,3",
    t_global: "6,3",
    zx: "19,2",
    zx_global: 19200,
    zy: "14,2",
    zy_global: 14200,
  },
  {
    profile_section_id: 3054,
    name: "80x40x3.2 RHS",
    type: "Rectangular Hollow",
    designation: "80x40x3.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: "7,16",
    ax_global: 716,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 80,
    d_global: 80,
    ix: "46,2",
    ix_global: 462000,
    iy: "18,9",
    iy_global: 189000,
    iz: "57,2",
    iz_global: 572000,
    t: "3,2",
    t_global: "3,2",
    zx: 18,
    zx_global: 18000,
    zy: 11,
    zy_global: 11000,
  },
  {
    profile_section_id: 3055,
    name: "80x40x4 RHS",
    type: "Rectangular Hollow",
    designation: "80x40x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: "8,79",
    ax_global: 879,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 80,
    d_global: 80,
    ix: "55,2",
    ix_global: 552000,
    iy: "22,2",
    iy_global: 222000,
    iz: "68,2",
    iz_global: 682000,
    t: 4,
    t_global: 4,
    zx: "21,8",
    zx_global: 21800,
    zy: "13,2",
    zy_global: 13200,
  },
  {
    profile_section_id: 3056,
    name: "80x40x5 RHS",
    type: "Rectangular Hollow",
    designation: "80x40x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "10,7",
    ax_global: 1070,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 80,
    d_global: 80,
    ix: "65,1",
    ix_global: 651000,
    iy: "25,7",
    iy_global: 257000,
    iz: "80,3",
    iz_global: 803000,
    t: 5,
    t_global: 5,
    zx: "26,1",
    zx_global: 26100,
    zy: "15,7",
    zy_global: 15700,
  },
  {
    profile_section_id: 3057,
    name: "80x40x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "80x40x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "13,1",
    ax_global: 1310,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 80,
    d_global: 80,
    ix: "75,6",
    ix_global: 756000,
    iy: "29,2",
    iy_global: 292000,
    iz: "93,3",
    iz_global: 933000,
    t: "6,3",
    t_global: "6,3",
    zx: "31,1",
    zx_global: 31100,
    zy: "18,4",
    zy_global: 18400,
  },
  {
    profile_section_id: 3058,
    name: "80x40x8 RHS",
    type: "Rectangular Hollow",
    designation: "80x40x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: 16,
    ax_global: 1600,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 80,
    d_global: 80,
    ix: "85,8",
    ix_global: 858000,
    iy: "32,1",
    iy_global: 321000,
    iz: 106,
    iz_global: 1060000,
    t: 8,
    t_global: 8,
    zx: "36,5",
    zx_global: 36500,
    zy: "21,2",
    zy_global: 21200,
  },
  {
    profile_section_id: 3059,
    name: "90x50x3.2 RHS",
    type: "Rectangular Hollow",
    designation: "90x50x3.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: "8,44",
    ax_global: 844,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 90,
    d_global: 90,
    ix: "80,9",
    ix_global: 809000,
    iy: "35,3",
    iy_global: 353000,
    iz: "89,1",
    iz_global: 891000,
    t: "3,2",
    t_global: "3,2",
    zx: "24,6",
    zx_global: 24600,
    zy: "16,2",
    zy_global: 16200,
  },
  {
    profile_section_id: 3060,
    name: "90x50x4 RHS",
    type: "Rectangular Hollow",
    designation: "90x50x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: "10,4",
    ax_global: 1040,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 90,
    d_global: 90,
    ix: "97,5",
    ix_global: 975000,
    iy: "41,9",
    iy_global: 419000,
    iz: 107,
    iz_global: 1070000,
    t: 4,
    t_global: 4,
    zx: "29,8",
    zx_global: 29800,
    zy: "19,6",
    zy_global: 19600,
  },
  {
    profile_section_id: 3061,
    name: "90x50x5 RHS",
    type: "Rectangular Hollow",
    designation: "90x50x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "12,7",
    ax_global: 1270,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 90,
    d_global: 90,
    ix: 116,
    ix_global: 1160000,
    iy: "49,2",
    iy_global: 492000,
    iz: 127,
    iz_global: 1270000,
    t: 5,
    t_global: 5,
    zx: 36,
    zx_global: 36000,
    zy: "23,5",
    zy_global: 23500,
  },
  {
    profile_section_id: 3062,
    name: "90x50x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "90x50x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "15,6",
    ax_global: 1560,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 90,
    d_global: 90,
    ix: 138,
    ix_global: 1380000,
    iy: 57,
    iy_global: 570000,
    iz: 150,
    iz_global: 1500000,
    t: "6,3",
    t_global: "6,3",
    zx: "43,2",
    zx_global: 43200,
    zy: 28,
    zy_global: 28000,
  },
  {
    profile_section_id: 3063,
    name: "90x50x8 RHS",
    type: "Rectangular Hollow",
    designation: "90x50x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "19,2",
    ax_global: 1920,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 90,
    d_global: 90,
    ix: 160,
    ix_global: 1600000,
    iy: "64,6",
    iy_global: 646000,
    iz: 174,
    iz_global: 1740000,
    t: 8,
    t_global: 8,
    zx: "51,4",
    zx_global: 51400,
    zy: "32,9",
    zy_global: 32900,
  },
  {
    profile_section_id: 3064,
    name: "100x50x3.2 RHS",
    type: "Rectangular Hollow",
    designation: "100x50x3.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: "9,08",
    ax_global: 908,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: "93,4",
    ix_global: 934000,
    iy: "38,8",
    iy_global: 388000,
    iz: 116,
    iz_global: 1160000,
    t: "3,2",
    t_global: "3,2",
    zx: "28,9",
    zx_global: 28900,
    zy: "17,7",
    zy_global: 17700,
  },
  {
    profile_section_id: 3065,
    name: "100x50x4 RHS",
    type: "Rectangular Hollow",
    designation: "100x50x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: "11,2",
    ax_global: 1120,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 113,
    ix_global: 1130000,
    iy: "46,2",
    iy_global: 462000,
    iz: 140,
    iz_global: 1400000,
    t: 4,
    t_global: 4,
    zx: "35,2",
    zx_global: 35200,
    zy: "21,5",
    zy_global: 21500,
  },
  {
    profile_section_id: 3066,
    name: "100x50x5 RHS",
    type: "Rectangular Hollow",
    designation: "100x50x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "13,7",
    ax_global: 1370,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 135,
    ix_global: 1350000,
    iy: "54,3",
    iy_global: 543000,
    iz: 167,
    iz_global: 1670000,
    t: 5,
    t_global: 5,
    zx: "42,6",
    zx_global: 42600,
    zy: "25,8",
    zy_global: 25800,
  },
  {
    profile_section_id: 3067,
    name: "100x50x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "100x50x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "16,9",
    ax_global: 1690,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 160,
    ix_global: 1600000,
    iy: 63,
    iy_global: 630000,
    iz: 197,
    iz_global: 1970000,
    t: "6,3",
    t_global: "6,3",
    zx: "51,3",
    zx_global: 51300,
    zy: "30,8",
    zy_global: 30800,
  },
  {
    profile_section_id: 3068,
    name: "100x50x8 RHS",
    type: "Rectangular Hollow",
    designation: "100x50x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "20,8",
    ax_global: 2080,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 186,
    ix_global: 1860000,
    iy: "71,7",
    iy_global: 717000,
    iz: 230,
    iz_global: 2300000,
    t: 8,
    t_global: 8,
    zx: "61,4",
    zx_global: 61400,
    zy: "36,3",
    zy_global: 36300,
  },
  {
    profile_section_id: 3069,
    name: "100x60x3.2 RHS",
    type: "Rectangular Hollow",
    designation: "100x60x3.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: "9,72",
    ax_global: 972,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 129,
    ix_global: 1290000,
    iy: "58,8",
    iy_global: 588000,
    iz: 131,
    iz_global: 1310000,
    t: "3,2",
    t_global: "3,2",
    zx: 32,
    zx_global: 32000,
    zy: "22,4",
    zy_global: 22400,
  },
  {
    profile_section_id: 3070,
    name: "100x60x4 RHS",
    type: "Rectangular Hollow",
    designation: "100x60x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: 12,
    ax_global: 1200,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 156,
    ix_global: 1560000,
    iy: "70,5",
    iy_global: 705000,
    iz: 158,
    iz_global: 1580000,
    t: 4,
    t_global: 4,
    zx: "39,1",
    zx_global: 39100,
    zy: "27,3",
    zy_global: 27300,
  },
  {
    profile_section_id: 3071,
    name: "100x60x5 RHS",
    type: "Rectangular Hollow",
    designation: "100x60x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "14,7",
    ax_global: 1470,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 188,
    ix_global: 1880000,
    iy: "83,6",
    iy_global: 836000,
    iz: 189,
    iz_global: 1890000,
    t: 5,
    t_global: 5,
    zx: "47,4",
    zx_global: 47400,
    zy: "32,9",
    zy_global: 32900,
  },
  {
    profile_section_id: 3072,
    name: "100x60x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "100x60x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "18,1",
    ax_global: 1810,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 224,
    ix_global: 2240000,
    iy: "98,1",
    iy_global: 981000,
    iz: 225,
    iz_global: 2250000,
    t: "6,3",
    t_global: "6,3",
    zx: "57,3",
    zx_global: 57300,
    zy: "39,5",
    zy_global: 39500,
  },
  {
    profile_section_id: 3073,
    name: "100x60x8 RHS",
    type: "Rectangular Hollow",
    designation: "100x60x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "22,4",
    ax_global: 2240,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 265,
    ix_global: 2650000,
    iy: 113,
    iy_global: 1130000,
    iz: 264,
    iz_global: 2640000,
    t: 8,
    t_global: 8,
    zx: "68,7",
    zx_global: 68700,
    zy: "47,1",
    zy_global: 47100,
  },
  {
    profile_section_id: 3074,
    name: "120x60x4 RHS",
    type: "Rectangular Hollow",
    designation: "120x60x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: "13,6",
    ax_global: 1360,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 201,
    ix_global: 2010000,
    iy: "83,1",
    iy_global: 831000,
    iz: 249,
    iz_global: 2490000,
    t: 4,
    t_global: 4,
    zx: "51,9",
    zx_global: 51900,
    zy: "31,7",
    zy_global: 31700,
  },
  {
    profile_section_id: 3075,
    name: "120x60x5 RHS",
    type: "Rectangular Hollow",
    designation: "120x60x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "16,7",
    ax_global: 1670,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 242,
    ix_global: 2420000,
    iy: "98,8",
    iy_global: 988000,
    iz: 299,
    iz_global: 2990000,
    t: 5,
    t_global: 5,
    zx: "63,1",
    zx_global: 63100,
    zy: "38,4",
    zy_global: 38400,
  },
  {
    profile_section_id: 3076,
    name: "120x60x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "120x60x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "20,7",
    ax_global: 2070,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 290,
    ix_global: 2900000,
    iy: 116,
    iy_global: 1160000,
    iz: 358,
    iz_global: 3580000,
    t: "6,3",
    t_global: "6,3",
    zx: "76,7",
    zx_global: 76700,
    zy: "46,3",
    zy_global: 46300,
  },
  {
    profile_section_id: 3077,
    name: "120x60x8 RHS",
    type: "Rectangular Hollow",
    designation: "120x60x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "25,6",
    ax_global: 2560,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 344,
    ix_global: 3440000,
    iy: 135,
    iy_global: 1350000,
    iz: 425,
    iz_global: 4250000,
    t: 8,
    t_global: 8,
    zx: "92,7",
    zx_global: 92700,
    zy: "55,4",
    zy_global: 55400,
  },
  {
    profile_section_id: 3078,
    name: "120x60x10 RHS",
    type: "Rectangular Hollow",
    designation: "120x60x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: "30,9",
    ax_global: 3090,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 396,
    ix_global: 3960000,
    iy: 152,
    iy_global: 1520000,
    iz: 488,
    iz_global: 4880000,
    t: 10,
    t_global: 10,
    zx: 109,
    zx_global: 109000,
    zy: "64,4",
    zy_global: 64400,
  },
  {
    profile_section_id: 3079,
    name: "120x80x4 RHS",
    type: "Rectangular Hollow",
    designation: "120x80x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: "15,2",
    ax_global: 1520,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 330,
    ix_global: 3300000,
    iy: 161,
    iy_global: 1610000,
    iz: 303,
    iz_global: 3030000,
    t: 4,
    t_global: 4,
    zx: "61,2",
    zx_global: 61200,
    zy: "46,1",
    zy_global: 46100,
  },
  {
    profile_section_id: 3080,
    name: "120x80x5 RHS",
    type: "Rectangular Hollow",
    designation: "120x80x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "18,7",
    ax_global: 1870,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 401,
    ix_global: 4010000,
    iy: 193,
    iy_global: 1930000,
    iz: 365,
    iz_global: 3650000,
    t: 5,
    t_global: 5,
    zx: "74,6",
    zx_global: 74600,
    zy: "56,1",
    zy_global: 56100,
  },
  {
    profile_section_id: 3081,
    name: "120x80x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "120x80x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "23,2",
    ax_global: 2320,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 487,
    ix_global: 4870000,
    iy: 230,
    iy_global: 2300000,
    iz: 440,
    iz_global: 4400000,
    t: "6,3",
    t_global: "6,3",
    zx: 91,
    zx_global: 91000,
    zy: "68,2",
    zy_global: 68200,
  },
  {
    profile_section_id: 3082,
    name: "120x80x8 RHS",
    type: "Rectangular Hollow",
    designation: "120x80x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "28,8",
    ax_global: 2880,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 587,
    ix_global: 5870000,
    iy: 273,
    iy_global: 2730000,
    iz: 525,
    iz_global: 5250000,
    t: 8,
    t_global: 8,
    zx: 111,
    zx_global: 111000,
    zy: "82,6",
    zy_global: 82600,
  },
  {
    profile_section_id: 3083,
    name: "120x80x10 RHS",
    type: "Rectangular Hollow",
    designation: "120x80x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: "34,9",
    ax_global: 3490,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 688,
    ix_global: 6880000,
    iy: 313,
    iy_global: 3130000,
    iz: 609,
    iz_global: 6090000,
    t: 10,
    t_global: 10,
    zx: 131,
    zx_global: 131000,
    zy: "97,3",
    zy_global: 97300,
  },
  {
    profile_section_id: 3084,
    name: "140x80x4 RHS",
    type: "Rectangular Hollow",
    designation: "140x80x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: "16,8",
    ax_global: 1680,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 140,
    d_global: 140,
    ix: 411,
    ix_global: 4110000,
    iy: 184,
    iy_global: 1840000,
    iz: 441,
    iz_global: 4410000,
    t: 4,
    t_global: 4,
    zx: "77,1",
    zx_global: 77100,
    zy: "52,2",
    zy_global: 52200,
  },
  {
    profile_section_id: 3085,
    name: "140x80x5 RHS",
    type: "Rectangular Hollow",
    designation: "140x80x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "20,7",
    ax_global: 2070,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 140,
    d_global: 140,
    ix: 499,
    ix_global: 4990000,
    iy: 221,
    iy_global: 2210000,
    iz: 534,
    iz_global: 5340000,
    t: 5,
    t_global: 5,
    zx: "94,3",
    zx_global: 94300,
    zy: "63,6",
    zy_global: 63600,
  },
  {
    profile_section_id: 3086,
    name: "140x80x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "140x80x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "25,7",
    ax_global: 2570,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 140,
    d_global: 140,
    ix: 607,
    ix_global: 6070000,
    iy: 265,
    iy_global: 2650000,
    iz: 646,
    iz_global: 6460000,
    t: "6,3",
    t_global: "6,3",
    zx: 115,
    zx_global: 115000,
    zy: "77,5",
    zy_global: 77500,
  },
  {
    profile_section_id: 3087,
    name: "140x80x8 RHS",
    type: "Rectangular Hollow",
    designation: "140x80x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: 32,
    ax_global: 3200,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 140,
    d_global: 140,
    ix: 733,
    ix_global: 7330000,
    iy: 314,
    iy_global: 3140000,
    iz: 776,
    iz_global: 7760000,
    t: 8,
    t_global: 8,
    zx: 141,
    zx_global: 141000,
    zy: "94,1",
    zy_global: 94100,
  },
  {
    profile_section_id: 3088,
    name: "140x80x10 RHS",
    type: "Rectangular Hollow",
    designation: "140x80x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: "38,9",
    ax_global: 3890,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 140,
    d_global: 140,
    ix: 862,
    ix_global: 8620000,
    iy: 362,
    iy_global: 3620000,
    iz: 908,
    iz_global: 9080000,
    t: 10,
    t_global: 10,
    zx: 168,
    zx_global: 168000,
    zy: 111,
    zy_global: 111000,
  },
  {
    profile_section_id: 3089,
    name: "150x100x4 RHS",
    type: "Rectangular Hollow",
    designation: "150x100x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: "19,2",
    ax_global: 1920,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 660,
    ix_global: 6600000,
    iy: 324,
    iy_global: 3240000,
    iz: 607,
    iz_global: 6070000,
    t: 4,
    t_global: 4,
    zx: "97,4",
    zx_global: 97400,
    zy: "73,6",
    zy_global: 73600,
  },
  {
    profile_section_id: 3090,
    name: "150x100x5 RHS",
    type: "Rectangular Hollow",
    designation: "150x100x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "23,7",
    ax_global: 2370,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 807,
    ix_global: 8070000,
    iy: 392,
    iy_global: 3920000,
    iz: 739,
    iz_global: 7390000,
    t: 5,
    t_global: 5,
    zx: 119,
    zx_global: 119000,
    zy: "90,1",
    zy_global: 90100,
  },
  {
    profile_section_id: 3091,
    name: "150x100x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "150x100x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "29,5",
    ax_global: 2950,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 986,
    ix_global: 9860000,
    iy: 474,
    iy_global: 4740000,
    iz: 898,
    iz_global: 8980000,
    t: "6,3",
    t_global: "6,3",
    zx: 147,
    zx_global: 147000,
    zy: 110,
    zy_global: 110000,
  },
  {
    profile_section_id: 3092,
    name: "150x100x8 RHS",
    type: "Rectangular Hollow",
    designation: "150x100x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "36,8",
    ax_global: 3680,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 1203,
    ix_global: 12030000,
    iy: 569,
    iy_global: 5690000,
    iz: 1087,
    iz_global: 10870000,
    t: 8,
    t_global: 8,
    zx: 180,
    zx_global: 180000,
    zy: 135,
    zy_global: 135000,
  },
  {
    profile_section_id: 3093,
    name: "150x100x10 RHS",
    type: "Rectangular Hollow",
    designation: "150x100x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: "44,9",
    ax_global: 4490,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 1432,
    ix_global: 14320000,
    iy: 665,
    iy_global: 6650000,
    iz: 1282,
    iz_global: 12820000,
    t: 10,
    t_global: 10,
    zx: 216,
    zx_global: 216000,
    zy: 161,
    zy_global: 161000,
  },
  {
    profile_section_id: 3094,
    name: "150x100x12.5 RHS",
    type: "Rectangular Hollow",
    designation: "150x100x12.5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "54,6",
    ax_global: 5460,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 1679,
    ix_global: 16790000,
    iy: 763,
    iy_global: 7630000,
    iz: 1488,
    iz_global: 14880000,
    t: "12,5",
    t_global: "12,5",
    zx: 256,
    zx_global: 256000,
    zy: 190,
    zy_global: 190000,
  },
  {
    profile_section_id: 3095,
    name: "160x80x4 RHS",
    type: "Rectangular Hollow",
    designation: "160x80x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: "18,4",
    ax_global: 1840,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 493,
    ix_global: 4930000,
    iy: 207,
    iy_global: 2070000,
    iz: 612,
    iz_global: 6120000,
    t: 4,
    t_global: 4,
    zx: "94,7",
    zx_global: 94700,
    zy: "58,3",
    zy_global: 58300,
  },
  {
    profile_section_id: 3096,
    name: "160x80x5 RHS",
    type: "Rectangular Hollow",
    designation: "160x80x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "22,7",
    ax_global: 2270,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 600,
    ix_global: 6000000,
    iy: 249,
    iy_global: 2490000,
    iz: 744,
    iz_global: 7440000,
    t: 5,
    t_global: 5,
    zx: 116,
    zx_global: 116000,
    zy: "71,1",
    zy_global: 71100,
  },
  {
    profile_section_id: 3097,
    name: "160x80x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "160x80x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "28,2",
    ax_global: 2820,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 730,
    ix_global: 7300000,
    iy: 299,
    iy_global: 2990000,
    iz: 903,
    iz_global: 9030000,
    t: "6,3",
    t_global: "6,3",
    zx: 142,
    zx_global: 142000,
    zy: "86,8",
    zy_global: 86800,
  },
  {
    profile_section_id: 3098,
    name: "160x80x8 RHS",
    type: "Rectangular Hollow",
    designation: "160x80x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "35,2",
    ax_global: 3520,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 883,
    ix_global: 8830000,
    iy: 356,
    iy_global: 3560000,
    iz: 1091,
    iz_global: 10910000,
    t: 8,
    t_global: 8,
    zx: 175,
    zx_global: 175000,
    zy: 106,
    zy_global: 106000,
  },
  {
    profile_section_id: 3099,
    name: "160x80x10 RHS",
    type: "Rectangular Hollow",
    designation: "160x80x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: "42,9",
    ax_global: 4290,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 1041,
    ix_global: 10410000,
    iy: 411,
    iy_global: 4110000,
    iz: 1284,
    iz_global: 12840000,
    t: 10,
    t_global: 10,
    zx: 209,
    zx_global: 209000,
    zy: 125,
    zy_global: 125000,
  },
  {
    profile_section_id: 3100,
    name: "160x80x12.5 RHS",
    type: "Rectangular Hollow",
    designation: "160x80x12.5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "52,1",
    ax_global: 5210,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 1204,
    ix_global: 12040000,
    iy: 465,
    iy_global: 4650000,
    iz: 1485,
    iz_global: 14850000,
    t: "12,5",
    t_global: "12,5",
    zx: 247,
    zx_global: 247000,
    zy: 146,
    zy_global: 146000,
  },
  {
    profile_section_id: 3101,
    name: "180x100x4 RHS",
    type: "Rectangular Hollow",
    designation: "180x100x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: "21,6",
    ax_global: 2160,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 852,
    ix_global: 8520000,
    iy: 379,
    iy_global: 3790000,
    iz: 945,
    iz_global: 9450000,
    t: 4,
    t_global: 4,
    zx: 128,
    zx_global: 128000,
    zy: "85,2",
    zy_global: 85200,
  },
  {
    profile_section_id: 3102,
    name: "180x100x5 RHS",
    type: "Rectangular Hollow",
    designation: "180x100x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "26,7",
    ax_global: 2670,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 1042,
    ix_global: 10420000,
    iy: 460,
    iy_global: 4600000,
    iz: 1153,
    iz_global: 11530000,
    t: 5,
    t_global: 5,
    zx: 157,
    zx_global: 157000,
    zy: 104,
    zy_global: 104000,
  },
  {
    profile_section_id: 3103,
    name: "180x100x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "180x100x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "33,3",
    ax_global: 3330,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 1277,
    ix_global: 12770000,
    iy: 557,
    iy_global: 5570000,
    iz: 1407,
    iz_global: 14070000,
    t: "6,3",
    t_global: "6,3",
    zx: 194,
    zx_global: 194000,
    zy: 128,
    zy_global: 128000,
  },
  {
    profile_section_id: 3104,
    name: "180x100x8 RHS",
    type: "Rectangular Hollow",
    designation: "180x100x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "41,6",
    ax_global: 4160,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 1560,
    ix_global: 15600000,
    iy: 671,
    iy_global: 6710000,
    iz: 1713,
    iz_global: 17130000,
    t: 8,
    t_global: 8,
    zx: 239,
    zx_global: 239000,
    zy: 157,
    zy_global: 157000,
  },
  {
    profile_section_id: 3105,
    name: "180x100x10 RHS",
    type: "Rectangular Hollow",
    designation: "180x100x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: "50,9",
    ax_global: 5090,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 1862,
    ix_global: 18620000,
    iy: 787,
    iy_global: 7870000,
    iz: 2036,
    iz_global: 20360000,
    t: 10,
    t_global: 10,
    zx: 288,
    zx_global: 288000,
    zy: 188,
    zy_global: 188000,
  },
  {
    profile_section_id: 3106,
    name: "180x100x12.5 RHS",
    type: "Rectangular Hollow",
    designation: "180x100x12.5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "62,1",
    ax_global: 6210,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 2191,
    ix_global: 21910000,
    iy: 908,
    iy_global: 9080000,
    iz: 2385,
    iz_global: 23850000,
    t: "12,5",
    t_global: "12,5",
    zx: 344,
    zx_global: 344000,
    zy: 223,
    zy_global: 223000,
  },
  {
    profile_section_id: 3107,
    name: "200x100x4 RHS",
    type: "Rectangular Hollow",
    designation: "200x100x4RHS",
    shape: "RHS",
    country_code: "European",
    ax: "23,2",
    ax_global: 2320,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 983,
    ix_global: 9830000,
    iy: 416,
    iy_global: 4160000,
    iz: 1223,
    iz_global: 12230000,
    t: 4,
    t_global: 4,
    zx: 150,
    zx_global: 150000,
    zy: 93,
    zy_global: 93000,
  },
  {
    profile_section_id: 3108,
    name: "200x100x5 RHS",
    type: "Rectangular Hollow",
    designation: "200x100x5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "28,7",
    ax_global: 2870,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 1204,
    ix_global: 12040000,
    iy: 505,
    iy_global: 5050000,
    iz: 1495,
    iz_global: 14950000,
    t: 5,
    t_global: 5,
    zx: 185,
    zx_global: 185000,
    zy: 114,
    zy_global: 114000,
  },
  {
    profile_section_id: 3109,
    name: "200x100x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "200x100x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "35,8",
    ax_global: 3580,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 1475,
    ix_global: 14750000,
    iy: 613,
    iy_global: 6130000,
    iz: 1829,
    iz_global: 18290000,
    t: "6,3",
    t_global: "6,3",
    zx: 228,
    zx_global: 228000,
    zy: 140,
    zy_global: 140000,
  },
  {
    profile_section_id: 3110,
    name: "200x100x8 RHS",
    type: "Rectangular Hollow",
    designation: "200x100x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "44,8",
    ax_global: 4480,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 1804,
    ix_global: 18040000,
    iy: 739,
    iy_global: 7390000,
    iz: 2234,
    iz_global: 22340000,
    t: 8,
    t_global: 8,
    zx: 282,
    zx_global: 282000,
    zy: 172,
    zy_global: 172000,
  },
  {
    profile_section_id: 3111,
    name: "200x100x10 RHS",
    type: "Rectangular Hollow",
    designation: "200x100x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: "54,9",
    ax_global: 5490,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 2156,
    ix_global: 21560000,
    iy: 869,
    iy_global: 8690000,
    iz: 2664,
    iz_global: 26640000,
    t: 10,
    t_global: 10,
    zx: 341,
    zx_global: 341000,
    zy: 206,
    zy_global: 206000,
  },
  {
    profile_section_id: 3112,
    name: "200x100x12.5 RHS",
    type: "Rectangular Hollow",
    designation: "200x100x12.5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "67,1",
    ax_global: 6710,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 2541,
    ix_global: 25410000,
    iy: 1004,
    iy_global: 10040000,
    iz: 3136,
    iz_global: 31360000,
    t: "12,5",
    t_global: "12,5",
    zx: 408,
    zx_global: 408000,
    zy: 245,
    zy_global: 245000,
  },
  {
    profile_section_id: 3113,
    name: "200x100x16 RHS",
    type: "Rectangular Hollow",
    designation: "200x100x16RHS",
    shape: "RHS",
    country_code: "European",
    ax: 83,
    ax_global: 8300,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 2982,
    ix_global: 29820000,
    iy: 1147,
    iy_global: 11470000,
    iz: 3678,
    iz_global: 36780000,
    t: 16,
    t_global: 16,
    zx: 491,
    zx_global: 491000,
    zy: 290,
    zy_global: 290000,
  },
  {
    profile_section_id: 3114,
    name: "200x120x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "200x120x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "38,3",
    ax_global: 3830,
    b: 120,
    b_global: 120,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 2028,
    ix_global: 20280000,
    iy: 929,
    iy_global: 9290000,
    iz: 2065,
    iz_global: 20650000,
    t: "6,3",
    t_global: "6,3",
    zx: 253,
    zx_global: 253000,
    zy: 177,
    zy_global: 177000,
  },
  {
    profile_section_id: 3115,
    name: "200x120x8 RHS",
    type: "Rectangular Hollow",
    designation: "200x120x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: 48,
    ax_global: 4800,
    b: 120,
    b_global: 120,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 2495,
    ix_global: 24950000,
    iy: 1128,
    iy_global: 11280000,
    iz: 2529,
    iz_global: 25290000,
    t: 8,
    t_global: 8,
    zx: 313,
    zx_global: 313000,
    zy: 218,
    zy_global: 218000,
  },
  {
    profile_section_id: 3116,
    name: "200x120x10 RHS",
    type: "Rectangular Hollow",
    designation: "200x120x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: "58,9",
    ax_global: 5890,
    b: 120,
    b_global: 120,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 3001,
    ix_global: 30010000,
    iy: 1337,
    iy_global: 13370000,
    iz: 3026,
    iz_global: 30260000,
    t: 10,
    t_global: 10,
    zx: 379,
    zx_global: 379000,
    zy: 263,
    zy_global: 263000,
  },
  {
    profile_section_id: 3117,
    name: "200x120x12.5 RHS",
    type: "Rectangular Hollow",
    designation: "200x120x12.5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "72,1",
    ax_global: 7210,
    b: 120,
    b_global: 120,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 3569,
    ix_global: 35690000,
    iy: 1562,
    iy_global: 15620000,
    iz: 3576,
    iz_global: 35760000,
    t: "12,5",
    t_global: "12,5",
    zx: 455,
    zx_global: 455000,
    zy: 314,
    zy_global: 314000,
  },
  {
    profile_section_id: 3118,
    name: "250x150x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "250x150x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "48,4",
    ax_global: 4840,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 250,
    d_global: 250,
    ix: 4054,
    ix_global: 40540000,
    iy: 1874,
    iy_global: 18740000,
    iz: 4143,
    iz_global: 41430000,
    t: "6,3",
    t_global: "6,3",
    zx: 402,
    zx_global: 402000,
    zy: 283,
    zy_global: 283000,
  },
  {
    profile_section_id: 3119,
    name: "250x150x8 RHS",
    type: "Rectangular Hollow",
    designation: "250x150x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "60,8",
    ax_global: 6080,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 250,
    d_global: 250,
    ix: 5021,
    ix_global: 50210000,
    iy: 2298,
    iy_global: 22980000,
    iz: 5111,
    iz_global: 51110000,
    t: 8,
    t_global: 8,
    zx: 501,
    zx_global: 501000,
    zy: 350,
    zy_global: 350000,
  },
  {
    profile_section_id: 3120,
    name: "250x150x10 RHS",
    type: "Rectangular Hollow",
    designation: "250x150x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: "74,9",
    ax_global: 7490,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 250,
    d_global: 250,
    ix: 6090,
    ix_global: 60900000,
    iy: 2755,
    iy_global: 27550000,
    iz: 6174,
    iz_global: 61740000,
    t: 10,
    t_global: 10,
    zx: 611,
    zx_global: 611000,
    zy: 426,
    zy_global: 426000,
  },
  {
    profile_section_id: 3121,
    name: "250x150x12.5 RHS",
    type: "Rectangular Hollow",
    designation: "250x150x12.5RHS",
    shape: "RHS",
    country_code: "European",
    ax: "92,1",
    ax_global: 9210,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 250,
    d_global: 250,
    ix: 7326,
    ix_global: 73260000,
    iy: 3265,
    iy_global: 32650000,
    iz: 7387,
    iz_global: 73870000,
    t: "12,5",
    t_global: "12,5",
    zx: 740,
    zx_global: 740000,
    zy: 514,
    zy_global: 514000,
  },
  {
    profile_section_id: 3122,
    name: "250x150x14.2 RHS",
    type: "Rectangular Hollow",
    designation: "250x150x14.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: 103,
    ax_global: 10300,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 250,
    d_global: 250,
    ix: 8102,
    ix_global: 81020000,
    iy: 3576,
    iy_global: 35760000,
    iz: 8141,
    iz_global: 81410000,
    t: "14,2",
    t_global: "14,2",
    zx: 823,
    zx_global: 823000,
    zy: 570,
    zy_global: 570000,
  },
  {
    profile_section_id: 3123,
    name: "250x150x16 RHS",
    type: "Rectangular Hollow",
    designation: "250x150x16RHS",
    shape: "RHS",
    country_code: "European",
    ax: 115,
    ax_global: 11500,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 250,
    d_global: 250,
    ix: 8868,
    ix_global: 88680000,
    iy: 3873,
    iy_global: 38730000,
    iz: 8879,
    iz_global: 88790000,
    t: 16,
    t_global: 16,
    zx: 906,
    zx_global: 906000,
    zy: 625,
    zy_global: 625000,
  },
  {
    profile_section_id: 3124,
    name: "260x180x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "260x180x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "53,4",
    ax_global: 5340,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 260,
    d_global: 260,
    ix: 5810,
    ix_global: 58100000,
    iy: 2929,
    iy_global: 29290000,
    iz: 5166,
    iz_global: 51660000,
    t: "6,3",
    t_global: "6,3",
    zx: 475,
    zx_global: 475000,
    zy: 369,
    zy_global: 369000,
  },
  {
    profile_section_id: 3125,
    name: "260x180x8 RHS",
    type: "Rectangular Hollow",
    designation: "260x180x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "67,2",
    ax_global: 6720,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 260,
    d_global: 260,
    ix: 7221,
    ix_global: 72210000,
    iy: 3608,
    iy_global: 36080000,
    iz: 6390,
    iz_global: 63900000,
    t: 8,
    t_global: 8,
    zx: 592,
    zx_global: 592000,
    zy: 459,
    zy_global: 459000,
  },
  {
    profile_section_id: 3126,
    name: "260x180x10 RHS",
    type: "Rectangular Hollow",
    designation: "260x180x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: "82,9",
    ax_global: 8290,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 260,
    d_global: 260,
    ix: 8798,
    ix_global: 87980000,
    iy: 4351,
    iy_global: 43510000,
    iz: 7741,
    iz_global: 77410000,
    t: 10,
    t_global: 10,
    zx: 724,
    zx_global: 724000,
    zy: 560,
    zy_global: 560000,
  },
  {
    profile_section_id: 3127,
    name: "260x180x12.5 RHS",
    type: "Rectangular Hollow",
    designation: "260x180x12.5RHS",
    shape: "RHS",
    country_code: "European",
    ax: 102,
    ax_global: 10200,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 260,
    d_global: 260,
    ix: 10640,
    ix_global: 106400000,
    iy: 5196,
    iy_global: 51960000,
    iz: 9299,
    iz_global: 92990000,
    t: "12,5",
    t_global: "12,5",
    zx: 879,
    zx_global: 879000,
    zy: 679,
    zy_global: 679000,
  },
  {
    profile_section_id: 3128,
    name: "260x180x14.2 RHS",
    type: "Rectangular Hollow",
    designation: "260x180x14.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: 115,
    ax_global: 11500,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 260,
    d_global: 260,
    ix: 11820,
    ix_global: 118200000,
    iy: 5719,
    iy_global: 57190000,
    iz: 10280,
    iz_global: 102800000,
    t: "14,2",
    t_global: "14,2",
    zx: 980,
    zx_global: 980000,
    zy: 755,
    zy_global: 755000,
  },
  {
    profile_section_id: 3129,
    name: "260x180x16 RHS",
    type: "Rectangular Hollow",
    designation: "260x180x16RHS",
    shape: "RHS",
    country_code: "European",
    ax: 128,
    ax_global: 12800,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 260,
    d_global: 260,
    ix: 12990,
    ix_global: 129900000,
    iy: 6231,
    iy_global: 62310000,
    iz: 11250,
    iz_global: 112500000,
    t: 16,
    t_global: 16,
    zx: 1081,
    zx_global: 1081000,
    zy: 831,
    zy_global: 831000,
  },
  {
    profile_section_id: 3130,
    name: "300x200x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "300x200x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: 61,
    ax_global: 6100,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 300,
    d_global: 300,
    ix: 8476,
    ix_global: 84760000,
    iy: 4193,
    iy_global: 41930000,
    iz: 7829,
    iz_global: 78290000,
    t: "6,3",
    t_global: "6,3",
    zx: 624,
    zx_global: 624000,
    zy: 472,
    zy_global: 472000,
  },
  {
    profile_section_id: 3131,
    name: "300x200x8 RHS",
    type: "Rectangular Hollow",
    designation: "300x200x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "76,8",
    ax_global: 7680,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 300,
    d_global: 300,
    ix: 10560,
    ix_global: 105600000,
    iy: 5184,
    iy_global: 51840000,
    iz: 9717,
    iz_global: 97170000,
    t: 8,
    t_global: 8,
    zx: 779,
    zx_global: 779000,
    zy: 589,
    zy_global: 589000,
  },
  {
    profile_section_id: 3132,
    name: "300x200x10 RHS",
    type: "Rectangular Hollow",
    designation: "300x200x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: "94,9",
    ax_global: 9490,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 300,
    d_global: 300,
    ix: 12910,
    ix_global: 129100000,
    iy: 6278,
    iy_global: 62780000,
    iz: 11820,
    iz_global: 118200000,
    t: 10,
    t_global: 10,
    zx: 956,
    zx_global: 956000,
    zy: 721,
    zy_global: 721000,
  },
  {
    profile_section_id: 3133,
    name: "300x200x12.5 RHS",
    type: "Rectangular Hollow",
    designation: "300x200x12.5RHS",
    shape: "RHS",
    country_code: "European",
    ax: 117,
    ax_global: 11700,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 300,
    d_global: 300,
    ix: 15680,
    ix_global: 156800000,
    iy: 7537,
    iy_global: 75370000,
    iz: 14270,
    iz_global: 142700000,
    t: "12,5",
    t_global: "12,5",
    zx: 1165,
    zx_global: 1165000,
    zy: 877,
    zy_global: 877000,
  },
  {
    profile_section_id: 3134,
    name: "300x200x14.2 RHS",
    type: "Rectangular Hollow",
    designation: "300x200x14.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: 132,
    ax_global: 13200,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 300,
    d_global: 300,
    ix: 17460,
    ix_global: 174600000,
    iy: 8328,
    iy_global: 83280000,
    iz: 15830,
    iz_global: 158300000,
    t: "14,2",
    t_global: "14,2",
    zx: 1302,
    zx_global: 1302000,
    zy: 978,
    zy_global: 978000,
  },
  {
    profile_section_id: 3135,
    name: "300x200x16 RHS",
    type: "Rectangular Hollow",
    designation: "300x200x16RHS",
    shape: "RHS",
    country_code: "European",
    ax: 147,
    ax_global: 14700,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 300,
    d_global: 300,
    ix: 19250,
    ix_global: 192500000,
    iy: 9109,
    iy_global: 91090000,
    iz: 17390,
    iz_global: 173900000,
    t: 16,
    t_global: 16,
    zx: 1441,
    zx_global: 1441000,
    zy: 1080,
    zy_global: 1080000,
  },
  {
    profile_section_id: 3136,
    name: "350x250x6.3 RHS",
    type: "Rectangular Hollow",
    designation: "350x250x6.3RHS",
    shape: "RHS",
    country_code: "European",
    ax: "73,6",
    ax_global: 7360,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 350,
    d_global: 350,
    ix: 15220,
    ix_global: 152200000,
    iy: 7885,
    iy_global: 78850000,
    iz: 13200,
    iz_global: 132000000,
    t: "6,3",
    t_global: "6,3",
    zx: 892,
    zx_global: 892000,
    zy: 709,
    zy_global: 709000,
  },
  {
    profile_section_id: 3137,
    name: "350x250x8 RHS",
    type: "Rectangular Hollow",
    designation: "350x250x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "92,8",
    ax_global: 9280,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 350,
    d_global: 350,
    ix: 19030,
    ix_global: 190300000,
    iy: 9798,
    iy_global: 97980000,
    iz: 16450,
    iz_global: 164500000,
    t: 8,
    t_global: 8,
    zx: 1118,
    zx_global: 1118000,
    zy: 888,
    zy_global: 888000,
  },
  {
    profile_section_id: 3138,
    name: "350x250x10 RHS",
    type: "Rectangular Hollow",
    designation: "350x250x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: 115,
    ax_global: 11500,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 350,
    d_global: 350,
    ix: 23350,
    ix_global: 233500000,
    iy: 11940,
    iy_global: 119400000,
    iz: 20100,
    iz_global: 201000000,
    t: 10,
    t_global: 10,
    zx: 1375,
    zx_global: 1375000,
    zy: 1091,
    zy_global: 1091000,
  },
  {
    profile_section_id: 3139,
    name: "350x250x12.5 RHS",
    type: "Rectangular Hollow",
    designation: "350x250x12.5RHS",
    shape: "RHS",
    country_code: "European",
    ax: 142,
    ax_global: 14200,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 350,
    d_global: 350,
    ix: 28530,
    ix_global: 285300000,
    iy: 14440,
    iy_global: 144400000,
    iz: 24420,
    iz_global: 244200000,
    t: "12,5",
    t_global: "12,5",
    zx: 1685,
    zx_global: 1685000,
    zy: 1334,
    zy_global: 1334000,
  },
  {
    profile_section_id: 3140,
    name: "350x250x14.2 RHS",
    type: "Rectangular Hollow",
    designation: "350x250x14.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: 160,
    ax_global: 16000,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 350,
    d_global: 350,
    ix: 31890,
    ix_global: 318900000,
    iy: 16050,
    iy_global: 160500000,
    iz: 27200,
    iz_global: 272000000,
    t: "14,2",
    t_global: "14,2",
    zx: 1887,
    zx_global: 1887000,
    zy: 1492,
    zy_global: 1492000,
  },
  {
    profile_section_id: 3141,
    name: "350x250x16 RHS",
    type: "Rectangular Hollow",
    designation: "350x250x16RHS",
    shape: "RHS",
    country_code: "European",
    ax: 179,
    ax_global: 17900,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 350,
    d_global: 350,
    ix: 35330,
    ix_global: 353300000,
    iy: 17650,
    iy_global: 176500000,
    iz: 30010,
    iz_global: 300100000,
    t: 16,
    t_global: 16,
    zx: 2095,
    zx_global: 2095000,
    zy: 1655,
    zy_global: 1655000,
  },
  {
    profile_section_id: 3142,
    name: "400x200x8 RHS",
    type: "Rectangular Hollow",
    designation: "400x200x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: "92,8",
    ax_global: 9280,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 400,
    d_global: 400,
    ix: 15740,
    ix_global: 157400000,
    iy: 6660,
    iy_global: 66600000,
    iz: 19560,
    iz_global: 195600000,
    t: 8,
    t_global: 8,
    zx: 1203,
    zx_global: 1203000,
    zy: 743,
    zy_global: 743000,
  },
  {
    profile_section_id: 3143,
    name: "400x200x10 RHS",
    type: "Rectangular Hollow",
    designation: "400x200x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: 115,
    ax_global: 11500,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 400,
    d_global: 400,
    ix: 19260,
    ix_global: 192600000,
    iy: 8084,
    iy_global: 80840000,
    iz: 23910,
    iz_global: 239100000,
    t: 10,
    t_global: 10,
    zx: 1480,
    zx_global: 1480000,
    zy: 911,
    zy_global: 911000,
  },
  {
    profile_section_id: 3144,
    name: "400x200x12.5 RHS",
    type: "Rectangular Hollow",
    designation: "400x200x12.5RHS",
    shape: "RHS",
    country_code: "European",
    ax: 142,
    ax_global: 14200,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 400,
    d_global: 400,
    ix: 23440,
    ix_global: 234400000,
    iy: 9738,
    iy_global: 97380000,
    iz: 29060,
    iz_global: 290600000,
    t: "12,5",
    t_global: "12,5",
    zx: 1813,
    zx_global: 1813000,
    zy: 1111,
    zy_global: 1111000,
  },
  {
    profile_section_id: 3145,
    name: "400x200x14.2 RHS",
    type: "Rectangular Hollow",
    designation: "400x200x14.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: 160,
    ax_global: 16000,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 400,
    d_global: 400,
    ix: 26140,
    ix_global: 261400000,
    iy: 10780,
    iy_global: 107800000,
    iz: 32380,
    iz_global: 323800000,
    t: "14,2",
    t_global: "14,2",
    zx: 2032,
    zx_global: 2032000,
    zy: 1242,
    zy_global: 1242000,
  },
  {
    profile_section_id: 3146,
    name: "400x200x16 RHS",
    type: "Rectangular Hollow",
    designation: "400x200x16RHS",
    shape: "RHS",
    country_code: "European",
    ax: 179,
    ax_global: 17900,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 400,
    d_global: 400,
    ix: 28870,
    ix_global: 288700000,
    iy: 11820,
    iy_global: 118200000,
    iz: 35740,
    iz_global: 357400000,
    t: 16,
    t_global: 16,
    zx: 2256,
    zx_global: 2256000,
    zy: 1374,
    zy_global: 1374000,
  },
  {
    profile_section_id: 3147,
    name: "450x250x8 RHS",
    type: "Rectangular Hollow",
    designation: "450x250x8RHS",
    shape: "RHS",
    country_code: "European",
    ax: 109,
    ax_global: 10900,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 450,
    d_global: 450,
    ix: 27080,
    ix_global: 270800000,
    iy: 12140,
    iy_global: 121400000,
    iz: 30080,
    iz_global: 300800000,
    t: 8,
    t_global: 8,
    zx: 1622,
    zx_global: 1622000,
    zy: 1081,
    zy_global: 1081000,
  },
  {
    profile_section_id: 3148,
    name: "450x250x10 RHS",
    type: "Rectangular Hollow",
    designation: "450x250x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: 135,
    ax_global: 13500,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 450,
    d_global: 450,
    ix: 33280,
    ix_global: 332800000,
    iy: 14820,
    iy_global: 148200000,
    iz: 36900,
    iz_global: 369000000,
    t: 10,
    t_global: 10,
    zx: 2000,
    zx_global: 2000000,
    zy: 1331,
    zy_global: 1331000,
  },
  {
    profile_section_id: 3149,
    name: "450x250x12.5 RHS",
    type: "Rectangular Hollow",
    designation: "450x250x12.5RHS",
    shape: "RHS",
    country_code: "European",
    ax: 167,
    ax_global: 16700,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 450,
    d_global: 450,
    ix: 40720,
    ix_global: 407200000,
    iy: 17970,
    iy_global: 179700000,
    iz: 45030,
    iz_global: 450300000,
    t: "12,5",
    t_global: "12,5",
    zx: 2458,
    zx_global: 2458000,
    zy: 1631,
    zy_global: 1631000,
  },
  {
    profile_section_id: 3150,
    name: "450x250x14.2 RHS",
    type: "Rectangular Hollow",
    designation: "450x250x14.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: 189,
    ax_global: 18900,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 450,
    d_global: 450,
    ix: 45580,
    ix_global: 455800000,
    iy: 20000,
    iy_global: 200000000,
    iz: 50320,
    iz_global: 503200000,
    t: "14,2",
    t_global: "14,2",
    zx: 2759,
    zx_global: 2759000,
    zy: 1827,
    zy_global: 1827000,
  },
  {
    profile_section_id: 3151,
    name: "450x250x16 RHS",
    type: "Rectangular Hollow",
    designation: "450x250x16RHS",
    shape: "RHS",
    country_code: "European",
    ax: 211,
    ax_global: 21100,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 450,
    d_global: 450,
    ix: 50550,
    ix_global: 505500000,
    iy: 22040,
    iy_global: 220400000,
    iz: 55710,
    iz_global: 557100000,
    t: 16,
    t_global: 16,
    zx: 3070,
    zx_global: 3070000,
    zy: 2029,
    zy_global: 2029000,
  },
  {
    profile_section_id: 3152,
    name: "500x300x10 RHS",
    type: "Rectangular Hollow",
    designation: "500x300x10RHS",
    shape: "RHS",
    country_code: "European",
    ax: 155,
    ax_global: 15500,
    b: 300,
    b_global: 300,
    c: 0,
    c_global: 0,
    d: 500,
    d_global: 500,
    ix: 52450,
    ix_global: 524500000,
    iy: 24440,
    iy_global: 244400000,
    iz: 53760,
    iz_global: 537600000,
    t: 10,
    t_global: 10,
    zx: 2595,
    zx_global: 2595000,
    zy: 1826,
    zy_global: 1826000,
  },
  {
    profile_section_id: 3153,
    name: "500x300x12.5 RHS",
    type: "Rectangular Hollow",
    designation: "500x300x12.5RHS",
    shape: "RHS",
    country_code: "European",
    ax: 192,
    ax_global: 19200,
    b: 300,
    b_global: 300,
    c: 0,
    c_global: 0,
    d: 500,
    d_global: 500,
    ix: 64390,
    ix_global: 643900000,
    iy: 29780,
    iy_global: 297800000,
    iz: 65810,
    iz_global: 658100000,
    t: "12,5",
    t_global: "12,5",
    zx: 3196,
    zx_global: 3196000,
    zy: 2244,
    zy_global: 2244000,
  },
  {
    profile_section_id: 3154,
    name: "500x300x14.2 RHS",
    type: "Rectangular Hollow",
    designation: "500x300x14.2RHS",
    shape: "RHS",
    country_code: "European",
    ax: 217,
    ax_global: 21700,
    b: 300,
    b_global: 300,
    c: 0,
    c_global: 0,
    d: 500,
    d_global: 500,
    ix: 72240,
    ix_global: 722400000,
    iy: 33250,
    iy_global: 332500000,
    iz: 73700,
    iz_global: 737000000,
    t: "14,2",
    t_global: "14,2",
    zx: 3593,
    zx_global: 3593000,
    zy: 2519,
    zy_global: 2519000,
  },
  {
    profile_section_id: 3155,
    name: "500x300x16 RHS",
    type: "Rectangular Hollow",
    designation: "500x300x16RHS",
    shape: "RHS",
    country_code: "European",
    ax: 243,
    ax_global: 24300,
    b: 300,
    b_global: 300,
    c: 0,
    c_global: 0,
    d: 500,
    d_global: 500,
    ix: 80330,
    ix_global: 803300000,
    iy: 36770,
    iy_global: 367700000,
    iz: 81780,
    iz_global: 817800000,
    t: 16,
    t_global: 16,
    zx: 4005,
    zx_global: 4005000,
    zy: 2804,
    zy_global: 2804000,
  },
  {
    profile_section_id: 3156,
    name: "500x300x20 RHS",
    type: "Rectangular Hollow",
    designation: "500x300x20RHS",
    shape: "RHS",
    country_code: "European",
    ax: 300,
    ax_global: 30000,
    b: 300,
    b_global: 300,
    c: 0,
    c_global: 0,
    d: 500,
    d_global: 500,
    ix: 97450,
    ix_global: 974500000,
    iy: 44080,
    iy_global: 440800000,
    iz: 98780,
    iz_global: 987800000,
    t: 20,
    t_global: 20,
    zx: 4885,
    zx_global: 4885000,
    zy: 3408,
    zy_global: 3408000,
  },
  {
    profile_section_id: 3157,
    name: "40x2.6 SHS",
    type: "Square Hollow",
    designation: "40x2.6SHS",
    shape: "SHS",
    country_code: "European",
    ax: "3,82",
    ax_global: 382,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 40,
    d_global: 40,
    ix: 14,
    ix_global: 140000,
    iy: "8,8",
    iy_global: 88000,
    iz: "8,8",
    iz_global: 88000,
    t: "2,6",
    t_global: "2,6",
    zx: "5,31",
    zx_global: 5310,
    zy: "5,31",
    zy_global: 5310,
  },
  {
    profile_section_id: 3158,
    name: "40x3.2 SHS",
    type: "Square Hollow",
    designation: "40x3.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: "4,6",
    ax_global: 460,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 40,
    d_global: 40,
    ix: "16,5",
    ix_global: 165000,
    iy: "10,2",
    iy_global: 102000,
    iz: "10,2",
    iz_global: 102000,
    t: "3,2",
    t_global: "3,2",
    zx: "6,28",
    zx_global: 6280,
    zy: "6,28",
    zy_global: 6280,
  },
  {
    profile_section_id: 3159,
    name: "40x4 SHS",
    type: "Square Hollow",
    designation: "40x4SHS",
    shape: "SHS",
    country_code: "European",
    ax: "5,59",
    ax_global: 559,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 40,
    d_global: 40,
    ix: "19,5",
    ix_global: 195000,
    iy: "11,8",
    iy_global: 118000,
    iz: "11,8",
    iz_global: 118000,
    t: 4,
    t_global: 4,
    zx: "7,44",
    zx_global: 7440,
    zy: "7,44",
    zy_global: 7440,
  },
  {
    profile_section_id: 3160,
    name: "40x5 SHS",
    type: "Square Hollow",
    designation: "40x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "6,73",
    ax_global: 673,
    b: 40,
    b_global: 40,
    c: 0,
    c_global: 0,
    d: 40,
    d_global: 40,
    ix: "22,5",
    ix_global: 225000,
    iy: "13,4",
    iy_global: 134000,
    iz: "13,4",
    iz_global: 134000,
    t: 5,
    t_global: 5,
    zx: "8,66",
    zx_global: 8660,
    zy: "8,66",
    zy_global: 8660,
  },
  {
    profile_section_id: 3161,
    name: "50x2.6 SHS",
    type: "Square Hollow",
    designation: "50x2.6SHS",
    shape: "SHS",
    country_code: "European",
    ax: "4,86",
    ax_global: 486,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 50,
    d_global: 50,
    ix: "28,4",
    ix_global: 284000,
    iy: 18,
    iy_global: 180000,
    iz: 18,
    iz_global: 180000,
    t: "2,6",
    t_global: "2,6",
    zx: "8,58",
    zx_global: 8580,
    zy: "8,58",
    zy_global: 8580,
  },
  {
    profile_section_id: 3162,
    name: "50x3.2 SHS",
    type: "Square Hollow",
    designation: "50x3.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: "5,88",
    ax_global: 588,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 50,
    d_global: 50,
    ix: "33,8",
    ix_global: 338000,
    iy: "21,2",
    iy_global: 212000,
    iz: "21,2",
    iz_global: 212000,
    t: "3,2",
    t_global: "3,2",
    zx: "10,2",
    zx_global: 10200,
    zy: "10,2",
    zy_global: 10200,
  },
  {
    profile_section_id: 3163,
    name: "50x4 SHS",
    type: "Square Hollow",
    designation: "50x4SHS",
    shape: "SHS",
    country_code: "European",
    ax: "7,19",
    ax_global: 719,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 50,
    d_global: 50,
    ix: "40,4",
    ix_global: 404000,
    iy: 25,
    iy_global: 250000,
    iz: 25,
    iz_global: 250000,
    t: 4,
    t_global: 4,
    zx: "12,3",
    zx_global: 12300,
    zy: "12,3",
    zy_global: 12300,
  },
  {
    profile_section_id: 3164,
    name: "50x5 SHS",
    type: "Square Hollow",
    designation: "50x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "8,73",
    ax_global: 873,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 50,
    d_global: 50,
    ix: "47,6",
    ix_global: 476000,
    iy: "28,9",
    iy_global: 289000,
    iz: "28,9",
    iz_global: 289000,
    t: 5,
    t_global: 5,
    zx: "14,5",
    zx_global: 14500,
    zy: "14,5",
    zy_global: 14500,
  },
  {
    profile_section_id: 3165,
    name: "50x6.3 SHS",
    type: "Square Hollow",
    designation: "50x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "10,6",
    ax_global: 1060,
    b: 50,
    b_global: 50,
    c: 0,
    c_global: 0,
    d: 50,
    d_global: 50,
    ix: "55,2",
    ix_global: 552000,
    iy: "32,8",
    iy_global: 328000,
    iz: "32,8",
    iz_global: 328000,
    t: "6,3",
    t_global: "6,3",
    zx: 17,
    zx_global: 17000,
    zy: 17,
    zy_global: 17000,
  },
  {
    profile_section_id: 3166,
    name: "60x2.6 SHS",
    type: "Square Hollow",
    designation: "60x2.6SHS",
    shape: "SHS",
    country_code: "European",
    ax: "5,9",
    ax_global: 590,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 60,
    d_global: 60,
    ix: "50,2",
    ix_global: 502000,
    iy: "32,2",
    iy_global: 322000,
    iz: "32,2",
    iz_global: 322000,
    t: "2,6",
    t_global: "2,6",
    zx: "12,6",
    zx_global: 12600,
    zy: "12,6",
    zy_global: 12600,
  },
  {
    profile_section_id: 3167,
    name: "60x3.2 SHS",
    type: "Square Hollow",
    designation: "60x3.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: "7,16",
    ax_global: 716,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 60,
    d_global: 60,
    ix: "60,2",
    ix_global: 602000,
    iy: "38,2",
    iy_global: 382000,
    iz: "38,2",
    iz_global: 382000,
    t: "3,2",
    t_global: "3,2",
    zx: "15,2",
    zx_global: 15200,
    zy: "15,2",
    zy_global: 15200,
  },
  {
    profile_section_id: 3168,
    name: "60x4 SHS",
    type: "Square Hollow",
    designation: "60x4SHS",
    shape: "SHS",
    country_code: "European",
    ax: "8,79",
    ax_global: 879,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 60,
    d_global: 60,
    ix: "72,5",
    ix_global: 725000,
    iy: "45,4",
    iy_global: 454000,
    iz: "45,4",
    iz_global: 454000,
    t: 4,
    t_global: 4,
    zx: "18,3",
    zx_global: 18300,
    zy: "18,3",
    zy_global: 18300,
  },
  {
    profile_section_id: 3169,
    name: "60x5 SHS",
    type: "Square Hollow",
    designation: "60x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "10,7",
    ax_global: 1070,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 60,
    d_global: 60,
    ix: "86,4",
    ix_global: 864000,
    iy: "53,3",
    iy_global: 533000,
    iz: "53,3",
    iz_global: 533000,
    t: 5,
    t_global: 5,
    zx: "21,9",
    zx_global: 21900,
    zy: "21,9",
    zy_global: 21900,
  },
  {
    profile_section_id: 3170,
    name: "60x6.3 SHS",
    type: "Square Hollow",
    designation: "60x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "13,1",
    ax_global: 1310,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 60,
    d_global: 60,
    ix: 102,
    ix_global: 1020000,
    iy: "61,6",
    iy_global: 616000,
    iz: "61,6",
    iz_global: 616000,
    t: "6,3",
    t_global: "6,3",
    zx: 26,
    zx_global: 26000,
    zy: 26,
    zy_global: 26000,
  },
  {
    profile_section_id: 3171,
    name: "60x8 SHS",
    type: "Square Hollow",
    designation: "60x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: 16,
    ax_global: 1600,
    b: 60,
    b_global: 60,
    c: 0,
    c_global: 0,
    d: 60,
    d_global: 60,
    ix: 118,
    ix_global: 1180000,
    iy: "69,7",
    iy_global: 697000,
    iz: "69,7",
    iz_global: 697000,
    t: 8,
    t_global: 8,
    zx: "30,4",
    zx_global: 30400,
    zy: "30,4",
    zy_global: 30400,
  },
  {
    profile_section_id: 3172,
    name: "70x3.2 SHS",
    type: "Square Hollow",
    designation: "70x3.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: "8,4",
    ax_global: 840,
    b: 70,
    b_global: 70,
    c: 0,
    c_global: 0,
    d: 70,
    d_global: 70,
    ix: "97,6",
    ix_global: 976000,
    iy: "62,3",
    iy_global: 623000,
    iz: "62,3",
    iz_global: 623000,
    t: "3,2",
    t_global: "3,2",
    zx: 21,
    zx_global: 21000,
    zy: 21,
    zy_global: 21000,
  },
  {
    profile_section_id: 3173,
    name: "70x4 SHS",
    type: "Square Hollow",
    designation: "70x4SHS",
    shape: "SHS",
    country_code: "European",
    ax: "10,4",
    ax_global: 1040,
    b: 70,
    b_global: 70,
    c: 0,
    c_global: 0,
    d: 70,
    d_global: 70,
    ix: 118,
    ix_global: 1180000,
    iy: "74,7",
    iy_global: 747000,
    iz: "74,7",
    iz_global: 747000,
    t: 4,
    t_global: 4,
    zx: "25,5",
    zx_global: 25500,
    zy: "25,5",
    zy_global: 25500,
  },
  {
    profile_section_id: 3174,
    name: "70x5 SHS",
    type: "Square Hollow",
    designation: "70x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "12,7",
    ax_global: 1270,
    b: 70,
    b_global: 70,
    c: 0,
    c_global: 0,
    d: 70,
    d_global: 70,
    ix: 142,
    ix_global: 1420000,
    iy: "88,5",
    iy_global: 885000,
    iz: "88,5",
    iz_global: 885000,
    t: 5,
    t_global: 5,
    zx: "30,8",
    zx_global: 30800,
    zy: "30,8",
    zy_global: 30800,
  },
  {
    profile_section_id: 3175,
    name: "70x6.3 SHS",
    type: "Square Hollow",
    designation: "70x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "15,6",
    ax_global: 1560,
    b: 70,
    b_global: 70,
    c: 0,
    c_global: 0,
    d: 70,
    d_global: 70,
    ix: 169,
    ix_global: 1690000,
    iy: 104,
    iy_global: 1040000,
    iz: 104,
    iz_global: 1040000,
    t: "6,3",
    t_global: "6,3",
    zx: "36,9",
    zx_global: 36900,
    zy: "36,9",
    zy_global: 36900,
  },
  {
    profile_section_id: 3176,
    name: "70x8 SHS",
    type: "Square Hollow",
    designation: "70x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: "19,2",
    ax_global: 1920,
    b: 70,
    b_global: 70,
    c: 0,
    c_global: 0,
    d: 70,
    d_global: 70,
    ix: 200,
    ix_global: 2000000,
    iy: 120,
    iy_global: 1200000,
    iz: 120,
    iz_global: 1200000,
    t: 8,
    t_global: 8,
    zx: "43,8",
    zx_global: 43800,
    zy: "43,8",
    zy_global: 43800,
  },
  {
    profile_section_id: 3177,
    name: "80x3.2 SHS",
    type: "Square Hollow",
    designation: "80x3.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: "9,72",
    ax_global: 972,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 80,
    d_global: 80,
    ix: 148,
    ix_global: 1480000,
    iy: 95,
    iy_global: 950000,
    iz: 95,
    iz_global: 950000,
    t: "3,2",
    t_global: "3,2",
    zx: "27,9",
    zx_global: 27900,
    zy: "27,9",
    zy_global: 27900,
  },
  {
    profile_section_id: 3178,
    name: "80x4 SHS",
    type: "Square Hollow",
    designation: "80x4SHS",
    shape: "SHS",
    country_code: "European",
    ax: 12,
    ax_global: 1200,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 80,
    d_global: 80,
    ix: 180,
    ix_global: 1800000,
    iy: 114,
    iy_global: 1140000,
    iz: 114,
    iz_global: 1140000,
    t: 4,
    t_global: 4,
    zx: 34,
    zx_global: 34000,
    zy: 34,
    zy_global: 34000,
  },
  {
    profile_section_id: 3179,
    name: "80x5 SHS",
    type: "Square Hollow",
    designation: "80x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "14,7",
    ax_global: 1470,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 80,
    d_global: 80,
    ix: 217,
    ix_global: 2170000,
    iy: 137,
    iy_global: 1370000,
    iz: 137,
    iz_global: 1370000,
    t: 5,
    t_global: 5,
    zx: "41,1",
    zx_global: 41100,
    zy: "41,1",
    zy_global: 41100,
  },
  {
    profile_section_id: 3180,
    name: "80x6.3 SHS",
    type: "Square Hollow",
    designation: "80x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "18,1",
    ax_global: 1810,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 80,
    d_global: 80,
    ix: 262,
    ix_global: 2620000,
    iy: 162,
    iy_global: 1620000,
    iz: 162,
    iz_global: 1620000,
    t: "6,3",
    t_global: "6,3",
    zx: "49,7",
    zx_global: 49700,
    zy: "49,7",
    zy_global: 49700,
  },
  {
    profile_section_id: 3181,
    name: "80x8 SHS",
    type: "Square Hollow",
    designation: "80x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: "22,4",
    ax_global: 2240,
    b: 80,
    b_global: 80,
    c: 0,
    c_global: 0,
    d: 80,
    d_global: 80,
    ix: 312,
    ix_global: 3120000,
    iy: 189,
    iy_global: 1890000,
    iz: 189,
    iz_global: 1890000,
    t: 8,
    t_global: 8,
    zx: "59,5",
    zx_global: 59500,
    zy: "59,5",
    zy_global: 59500,
  },
  {
    profile_section_id: 3182,
    name: "90x4 SHS",
    type: "Square Hollow",
    designation: "90x4SHS",
    shape: "SHS",
    country_code: "European",
    ax: "13,6",
    ax_global: 1360,
    b: 90,
    b_global: 90,
    c: 0,
    c_global: 0,
    d: 90,
    d_global: 90,
    ix: 260,
    ix_global: 2600000,
    iy: 166,
    iy_global: 1660000,
    iz: 166,
    iz_global: 1660000,
    t: 4,
    t_global: 4,
    zx: "43,6",
    zx_global: 43600,
    zy: "43,6",
    zy_global: 43600,
  },
  {
    profile_section_id: 3183,
    name: "90x5 SHS",
    type: "Square Hollow",
    designation: "90x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "16,7",
    ax_global: 1670,
    b: 90,
    b_global: 90,
    c: 0,
    c_global: 0,
    d: 90,
    d_global: 90,
    ix: 316,
    ix_global: 3160000,
    iy: 200,
    iy_global: 2000000,
    iz: 200,
    iz_global: 2000000,
    t: 5,
    t_global: 5,
    zx: 53,
    zx_global: 53000,
    zy: 53,
    zy_global: 53000,
  },
  {
    profile_section_id: 3184,
    name: "90x6.3 SHS",
    type: "Square Hollow",
    designation: "90x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "20,7",
    ax_global: 2070,
    b: 90,
    b_global: 90,
    c: 0,
    c_global: 0,
    d: 90,
    d_global: 90,
    ix: 382,
    ix_global: 3820000,
    iy: 238,
    iy_global: 2380000,
    iz: 238,
    iz_global: 2380000,
    t: "6,3",
    t_global: "6,3",
    zx: "64,3",
    zx_global: 64300,
    zy: "64,3",
    zy_global: 64300,
  },
  {
    profile_section_id: 3185,
    name: "90x8 SHS",
    type: "Square Hollow",
    designation: "90x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: "25,6",
    ax_global: 2560,
    b: 90,
    b_global: 90,
    c: 0,
    c_global: 0,
    d: 90,
    d_global: 90,
    ix: 459,
    ix_global: 4590000,
    iy: 281,
    iy_global: 2810000,
    iz: 281,
    iz_global: 2810000,
    t: 8,
    t_global: 8,
    zx: "77,6",
    zx_global: 77600,
    zy: "77,6",
    zy_global: 77600,
  },
  {
    profile_section_id: 3186,
    name: "100x4 SHS",
    type: "Square Hollow",
    designation: "100x4SHS",
    shape: "SHS",
    country_code: "European",
    ax: "15,2",
    ax_global: 1520,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 361,
    ix_global: 3610000,
    iy: 232,
    iy_global: 2320000,
    iz: 232,
    iz_global: 2320000,
    t: 4,
    t_global: 4,
    zx: "54,4",
    zx_global: 54400,
    zy: "54,4",
    zy_global: 54400,
  },
  {
    profile_section_id: 3187,
    name: "100x5 SHS",
    type: "Square Hollow",
    designation: "100x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "18,7",
    ax_global: 1870,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 439,
    ix_global: 4390000,
    iy: 279,
    iy_global: 2790000,
    iz: 279,
    iz_global: 2790000,
    t: 5,
    t_global: 5,
    zx: "66,4",
    zx_global: 66400,
    zy: "66,4",
    zy_global: 66400,
  },
  {
    profile_section_id: 3188,
    name: "100x6.3 SHS",
    type: "Square Hollow",
    designation: "100x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "23,2",
    ax_global: 2320,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 534,
    ix_global: 5340000,
    iy: 336,
    iy_global: 3360000,
    iz: 336,
    iz_global: 3360000,
    t: "6,3",
    t_global: "6,3",
    zx: "80,9",
    zx_global: 80900,
    zy: "80,9",
    zy_global: 80900,
  },
  {
    profile_section_id: 3189,
    name: "100x8 SHS",
    type: "Square Hollow",
    designation: "100x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: "28,8",
    ax_global: 2880,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 646,
    ix_global: 6460000,
    iy: 400,
    iy_global: 4000000,
    iz: 400,
    iz_global: 4000000,
    t: 8,
    t_global: 8,
    zx: "98,2",
    zx_global: 98200,
    zy: "98,2",
    zy_global: 98200,
  },
  {
    profile_section_id: 3190,
    name: "100x10 SHS",
    type: "Square Hollow",
    designation: "100x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: "34,9",
    ax_global: 3490,
    b: 100,
    b_global: 100,
    c: 0,
    c_global: 0,
    d: 100,
    d_global: 100,
    ix: 761,
    ix_global: 7610000,
    iy: 462,
    iy_global: 4620000,
    iz: 462,
    iz_global: 4620000,
    t: 10,
    t_global: 10,
    zx: 116,
    zx_global: 116000,
    zy: 116,
    zy_global: 116000,
  },
  {
    profile_section_id: 3191,
    name: "120x5 SHS",
    type: "Square Hollow",
    designation: "120x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "22,7",
    ax_global: 2270,
    b: 120,
    b_global: 120,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 777,
    ix_global: 7770000,
    iy: 498,
    iy_global: 4980000,
    iz: 498,
    iz_global: 4980000,
    t: 5,
    t_global: 5,
    zx: "97,6",
    zx_global: 97600,
    zy: "97,6",
    zy_global: 97600,
  },
  {
    profile_section_id: 3192,
    name: "120x6.3 SHS",
    type: "Square Hollow",
    designation: "120x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "28,2",
    ax_global: 2820,
    b: 120,
    b_global: 120,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 950,
    ix_global: 9500000,
    iy: 603,
    iy_global: 6030000,
    iz: 603,
    iz_global: 6030000,
    t: "6,3",
    t_global: "6,3",
    zx: 120,
    zx_global: 120000,
    zy: 120,
    zy_global: 120000,
  },
  {
    profile_section_id: 3193,
    name: "120x8 SHS",
    type: "Square Hollow",
    designation: "120x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: "35,2",
    ax_global: 3520,
    b: 120,
    b_global: 120,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 1160,
    ix_global: 11600000,
    iy: 726,
    iy_global: 7260000,
    iz: 726,
    iz_global: 7260000,
    t: 8,
    t_global: 8,
    zx: 146,
    zx_global: 146000,
    zy: 146,
    zy_global: 146000,
  },
  {
    profile_section_id: 3194,
    name: "120x10 SHS",
    type: "Square Hollow",
    designation: "120x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: "42,9",
    ax_global: 4290,
    b: 120,
    b_global: 120,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 1382,
    ix_global: 13820000,
    iy: 852,
    iy_global: 8520000,
    iz: 852,
    iz_global: 8520000,
    t: 10,
    t_global: 10,
    zx: 175,
    zx_global: 175000,
    zy: 175,
    zy_global: 175000,
  },
  {
    profile_section_id: 3195,
    name: "120x12.5 SHS",
    type: "Square Hollow",
    designation: "120x12.5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "52,1",
    ax_global: 5210,
    b: 120,
    b_global: 120,
    c: 0,
    c_global: 0,
    d: 120,
    d_global: 120,
    ix: 1623,
    ix_global: 16230000,
    iy: 982,
    iy_global: 9820000,
    iz: 982,
    iz_global: 9820000,
    t: "12,5",
    t_global: "12,5",
    zx: 207,
    zx_global: 207000,
    zy: 207,
    zy_global: 207000,
  },
  {
    profile_section_id: 3196,
    name: "140x5 SHS",
    type: "Square Hollow",
    designation: "140x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "26,7",
    ax_global: 2670,
    b: 140,
    b_global: 140,
    c: 0,
    c_global: 0,
    d: 140,
    d_global: 140,
    ix: 1253,
    ix_global: 12530000,
    iy: 807,
    iy_global: 8070000,
    iz: 807,
    iz_global: 8070000,
    t: 5,
    t_global: 5,
    zx: 135,
    zx_global: 135000,
    zy: 135,
    zy_global: 135000,
  },
  {
    profile_section_id: 3197,
    name: "140x6.3 SHS",
    type: "Square Hollow",
    designation: "140x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "33,3",
    ax_global: 3330,
    b: 140,
    b_global: 140,
    c: 0,
    c_global: 0,
    d: 140,
    d_global: 140,
    ix: 1540,
    ix_global: 15400000,
    iy: 984,
    iy_global: 9840000,
    iz: 984,
    iz_global: 9840000,
    t: "6,3",
    t_global: "6,3",
    zx: 166,
    zx_global: 166000,
    zy: 166,
    zy_global: 166000,
  },
  {
    profile_section_id: 3198,
    name: "140x8 SHS",
    type: "Square Hollow",
    designation: "140x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: "41,6",
    ax_global: 4160,
    b: 140,
    b_global: 140,
    c: 0,
    c_global: 0,
    d: 140,
    d_global: 140,
    ix: 1892,
    ix_global: 18920000,
    iy: 1195,
    iy_global: 11950000,
    iz: 1195,
    iz_global: 11950000,
    t: 8,
    t_global: 8,
    zx: 204,
    zx_global: 204000,
    zy: 204,
    zy_global: 204000,
  },
  {
    profile_section_id: 3199,
    name: "140x10 SHS",
    type: "Square Hollow",
    designation: "140x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: "50,9",
    ax_global: 5090,
    b: 140,
    b_global: 140,
    c: 0,
    c_global: 0,
    d: 140,
    d_global: 140,
    ix: 2272,
    ix_global: 22720000,
    iy: 1416,
    iy_global: 14160000,
    iz: 1416,
    iz_global: 14160000,
    t: 10,
    t_global: 10,
    zx: 246,
    zx_global: 246000,
    zy: 246,
    zy_global: 246000,
  },
  {
    profile_section_id: 3200,
    name: "140x12.5 SHS",
    type: "Square Hollow",
    designation: "140x12.5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "62,1",
    ax_global: 6210,
    b: 140,
    b_global: 140,
    c: 0,
    c_global: 0,
    d: 140,
    d_global: 140,
    ix: 2696,
    ix_global: 26960000,
    iy: 1653,
    iy_global: 16530000,
    iz: 1653,
    iz_global: 16530000,
    t: "12,5",
    t_global: "12,5",
    zx: 293,
    zx_global: 293000,
    zy: 293,
    zy_global: 293000,
  },
  {
    profile_section_id: 3201,
    name: "150x5 SHS",
    type: "Square Hollow",
    designation: "150x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "28,7",
    ax_global: 2870,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 1550,
    ix_global: 15500000,
    iy: 1002,
    iy_global: 10020000,
    iz: 1002,
    iz_global: 10020000,
    t: 5,
    t_global: 5,
    zx: 156,
    zx_global: 156000,
    zy: 156,
    zy_global: 156000,
  },
  {
    profile_section_id: 3202,
    name: "150x6.3 SHS",
    type: "Square Hollow",
    designation: "150x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "35,8",
    ax_global: 3580,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 1909,
    ix_global: 19090000,
    iy: 1223,
    iy_global: 12230000,
    iz: 1223,
    iz_global: 12230000,
    t: "6,3",
    t_global: "6,3",
    zx: 192,
    zx_global: 192000,
    zy: 192,
    zy_global: 192000,
  },
  {
    profile_section_id: 3203,
    name: "150x8 SHS",
    type: "Square Hollow",
    designation: "150x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: "44,8",
    ax_global: 4480,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 2351,
    ix_global: 23510000,
    iy: 1491,
    iy_global: 14910000,
    iz: 1491,
    iz_global: 14910000,
    t: 8,
    t_global: 8,
    zx: 237,
    zx_global: 237000,
    zy: 237,
    zy_global: 237000,
  },
  {
    profile_section_id: 3204,
    name: "150x10 SHS",
    type: "Square Hollow",
    designation: "150x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: "54,9",
    ax_global: 5490,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 2832,
    ix_global: 28320000,
    iy: 1773,
    iy_global: 17730000,
    iz: 1773,
    iz_global: 17730000,
    t: 10,
    t_global: 10,
    zx: 286,
    zx_global: 286000,
    zy: 286,
    zy_global: 286000,
  },
  {
    profile_section_id: 3205,
    name: "150x12.5 SHS",
    type: "Square Hollow",
    designation: "150x12.5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "67,1",
    ax_global: 6710,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 3375,
    ix_global: 33750000,
    iy: 2080,
    iy_global: 20800000,
    iz: 2080,
    iz_global: 20800000,
    t: "12,5",
    t_global: "12,5",
    zx: 342,
    zx_global: 342000,
    zy: 342,
    zy_global: 342000,
  },
  {
    profile_section_id: 3206,
    name: "150x14.2 SHS",
    type: "Square Hollow",
    designation: "150x14.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: 75,
    ax_global: 7500,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 3707,
    ix_global: 37070000,
    iy: 2262,
    iy_global: 22620000,
    iz: 2262,
    iz_global: 22620000,
    t: "14,2",
    t_global: "14,2",
    zx: 377,
    zx_global: 377000,
    zy: 377,
    zy_global: 377000,
  },
  {
    profile_section_id: 3207,
    name: "150x16 SHS",
    type: "Square Hollow",
    designation: "150x16SHS",
    shape: "SHS",
    country_code: "European",
    ax: 83,
    ax_global: 8300,
    b: 150,
    b_global: 150,
    c: 0,
    c_global: 0,
    d: 150,
    d_global: 150,
    ix: 4026,
    ix_global: 40260000,
    iy: 2430,
    iy_global: 24300000,
    iz: 2430,
    iz_global: 24300000,
    t: 16,
    t_global: 16,
    zx: 411,
    zx_global: 411000,
    zy: 411,
    zy_global: 411000,
  },
  {
    profile_section_id: 3208,
    name: "160x5 SHS",
    type: "Square Hollow",
    designation: "160x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "30,7",
    ax_global: 3070,
    b: 160,
    b_global: 160,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 1892,
    ix_global: 18920000,
    iy: 1225,
    iy_global: 12250000,
    iz: 1225,
    iz_global: 12250000,
    t: 5,
    t_global: 5,
    zx: 178,
    zx_global: 178000,
    zy: 178,
    zy_global: 178000,
  },
  {
    profile_section_id: 3209,
    name: "160x6.3 SHS",
    type: "Square Hollow",
    designation: "160x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "38,3",
    ax_global: 3830,
    b: 160,
    b_global: 160,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 2333,
    ix_global: 23330000,
    iy: 1499,
    iy_global: 14990000,
    iz: 1499,
    iz_global: 14990000,
    t: "6,3",
    t_global: "6,3",
    zx: 220,
    zx_global: 220000,
    zy: 220,
    zy_global: 220000,
  },
  {
    profile_section_id: 3210,
    name: "160x8 SHS",
    type: "Square Hollow",
    designation: "160x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: 48,
    ax_global: 4800,
    b: 160,
    b_global: 160,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 2880,
    ix_global: 28800000,
    iy: 1831,
    iy_global: 18310000,
    iz: 1831,
    iz_global: 18310000,
    t: 8,
    t_global: 8,
    zx: 272,
    zx_global: 272000,
    zy: 272,
    zy_global: 272000,
  },
  {
    profile_section_id: 3211,
    name: "160x10 SHS",
    type: "Square Hollow",
    designation: "160x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: "58,9",
    ax_global: 5890,
    b: 160,
    b_global: 160,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 3478,
    ix_global: 34780000,
    iy: 2186,
    iy_global: 21860000,
    iz: 2186,
    iz_global: 21860000,
    t: 10,
    t_global: 10,
    zx: 329,
    zx_global: 329000,
    zy: 329,
    zy_global: 329000,
  },
  {
    profile_section_id: 3212,
    name: "160x12.5 SHS",
    type: "Square Hollow",
    designation: "160x12.5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "72,1",
    ax_global: 7210,
    b: 160,
    b_global: 160,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 4158,
    ix_global: 41580000,
    iy: 2576,
    iy_global: 25760000,
    iz: 2576,
    iz_global: 25760000,
    t: "12,5",
    t_global: "12,5",
    zx: 395,
    zx_global: 395000,
    zy: 395,
    zy_global: 395000,
  },
  {
    profile_section_id: 3213,
    name: "160x14.2 SHS",
    type: "Square Hollow",
    designation: "160x14.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: "80,7",
    ax_global: 8070,
    b: 160,
    b_global: 160,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 4579,
    ix_global: 45790000,
    iy: 2809,
    iy_global: 28090000,
    iz: 2809,
    iz_global: 28090000,
    t: "14,2",
    t_global: "14,2",
    zx: 436,
    zx_global: 436000,
    zy: 436,
    zy_global: 436000,
  },
  {
    profile_section_id: 3214,
    name: "160x16 SHS",
    type: "Square Hollow",
    designation: "160x16SHS",
    shape: "SHS",
    country_code: "European",
    ax: "89,4",
    ax_global: 8940,
    b: 160,
    b_global: 160,
    c: 0,
    c_global: 0,
    d: 160,
    d_global: 160,
    ix: 4988,
    ix_global: 49880000,
    iy: 3028,
    iy_global: 30280000,
    iz: 3028,
    iz_global: 30280000,
    t: 16,
    t_global: 16,
    zx: 476,
    zx_global: 476000,
    zy: 476,
    zy_global: 476000,
  },
  {
    profile_section_id: 3215,
    name: "180x5 SHS",
    type: "Square Hollow",
    designation: "180x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "34,7",
    ax_global: 3470,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 2718,
    ix_global: 27180000,
    iy: 1765,
    iy_global: 17650000,
    iz: 1765,
    iz_global: 17650000,
    t: 5,
    t_global: 5,
    zx: 227,
    zx_global: 227000,
    zy: 227,
    zy_global: 227000,
  },
  {
    profile_section_id: 3216,
    name: "180x6.3 SHS",
    type: "Square Hollow",
    designation: "180x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "43,3",
    ax_global: 4330,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 3361,
    ix_global: 33610000,
    iy: 2168,
    iy_global: 21680000,
    iz: 2168,
    iz_global: 21680000,
    t: "6,3",
    t_global: "6,3",
    zx: 281,
    zx_global: 281000,
    zy: 281,
    zy_global: 281000,
  },
  {
    profile_section_id: 3217,
    name: "180x8 SHS",
    type: "Square Hollow",
    designation: "180x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: "54,4",
    ax_global: 5440,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 4162,
    ix_global: 41620000,
    iy: 2661,
    iy_global: 26610000,
    iz: 2661,
    iz_global: 26610000,
    t: 8,
    t_global: 8,
    zx: 349,
    zx_global: 349000,
    zy: 349,
    zy_global: 349000,
  },
  {
    profile_section_id: 3218,
    name: "180x10 SHS",
    type: "Square Hollow",
    designation: "180x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: "66,9",
    ax_global: 6690,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 5048,
    ix_global: 50480000,
    iy: 3193,
    iy_global: 31930000,
    iz: 3193,
    iz_global: 31930000,
    t: 10,
    t_global: 10,
    zx: 424,
    zx_global: 424000,
    zy: 424,
    zy_global: 424000,
  },
  {
    profile_section_id: 3219,
    name: "180x12.5 SHS",
    type: "Square Hollow",
    designation: "180x12.5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "82,1",
    ax_global: 8210,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 6070,
    ix_global: 60700000,
    iy: 3790,
    iy_global: 37900000,
    iz: 3790,
    iz_global: 37900000,
    t: "12,5",
    t_global: "12,5",
    zx: 511,
    zx_global: 511000,
    zy: 511,
    zy_global: 511000,
  },
  {
    profile_section_id: 3220,
    name: "180x14.2 SHS",
    type: "Square Hollow",
    designation: "180x14.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: 92,
    ax_global: 9200,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 6711,
    ix_global: 67110000,
    iy: 4154,
    iy_global: 41540000,
    iz: 4154,
    iz_global: 41540000,
    t: "14,2",
    t_global: "14,2",
    zx: 566,
    zx_global: 566000,
    zy: 566,
    zy_global: 566000,
  },
  {
    profile_section_id: 3221,
    name: "180x16 SHS",
    type: "Square Hollow",
    designation: "180x16SHS",
    shape: "SHS",
    country_code: "European",
    ax: 102,
    ax_global: 10200,
    b: 180,
    b_global: 180,
    c: 0,
    c_global: 0,
    d: 180,
    d_global: 180,
    ix: 7343,
    ix_global: 73430000,
    iy: 4504,
    iy_global: 45040000,
    iz: 4504,
    iz_global: 45040000,
    t: 16,
    t_global: 16,
    zx: 621,
    zx_global: 621000,
    zy: 621,
    zy_global: 621000,
  },
  {
    profile_section_id: 3222,
    name: "200x5 SHS",
    type: "Square Hollow",
    designation: "200x5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "38,7",
    ax_global: 3870,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 3756,
    ix_global: 37560000,
    iy: 2445,
    iy_global: 24450000,
    iz: 2445,
    iz_global: 24450000,
    t: 5,
    t_global: 5,
    zx: 283,
    zx_global: 283000,
    zy: 283,
    zy_global: 283000,
  },
  {
    profile_section_id: 3223,
    name: "200x6.3 SHS",
    type: "Square Hollow",
    designation: "200x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "48,4",
    ax_global: 4840,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 4653,
    ix_global: 46530000,
    iy: 3011,
    iy_global: 30110000,
    iz: 3011,
    iz_global: 30110000,
    t: "6,3",
    t_global: "6,3",
    zx: 350,
    zx_global: 350000,
    zy: 350,
    zy_global: 350000,
  },
  {
    profile_section_id: 3224,
    name: "200x8 SHS",
    type: "Square Hollow",
    designation: "200x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: "60,8",
    ax_global: 6080,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 5778,
    ix_global: 57780000,
    iy: 3709,
    iy_global: 37090000,
    iz: 3709,
    iz_global: 37090000,
    t: 8,
    t_global: 8,
    zx: 436,
    zx_global: 436000,
    zy: 436,
    zy_global: 436000,
  },
  {
    profile_section_id: 3225,
    name: "200x10 SHS",
    type: "Square Hollow",
    designation: "200x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: "74,9",
    ax_global: 7490,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 7031,
    ix_global: 70310000,
    iy: 4471,
    iy_global: 44710000,
    iz: 4471,
    iz_global: 44710000,
    t: 10,
    t_global: 10,
    zx: 531,
    zx_global: 531000,
    zy: 531,
    zy_global: 531000,
  },
  {
    profile_section_id: 3226,
    name: "200x12.5 SHS",
    type: "Square Hollow",
    designation: "200x12.5SHS",
    shape: "SHS",
    country_code: "European",
    ax: "92,1",
    ax_global: 9210,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 8491,
    ix_global: 84910000,
    iy: 5336,
    iy_global: 53360000,
    iz: 5336,
    iz_global: 53360000,
    t: "12,5",
    t_global: "12,5",
    zx: 643,
    zx_global: 643000,
    zy: 643,
    zy_global: 643000,
  },
  {
    profile_section_id: 3227,
    name: "200x14.2 SHS",
    type: "Square Hollow",
    designation: "200x14.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: 103,
    ax_global: 10300,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 9417,
    ix_global: 94170000,
    iy: 5872,
    iy_global: 58720000,
    iz: 5872,
    iz_global: 58720000,
    t: "14,2",
    t_global: "14,2",
    zx: 714,
    zx_global: 714000,
    zy: 714,
    zy_global: 714000,
  },
  {
    profile_section_id: 3228,
    name: "200x16 SHS",
    type: "Square Hollow",
    designation: "200x16SHS",
    shape: "SHS",
    country_code: "European",
    ax: 115,
    ax_global: 11500,
    b: 200,
    b_global: 200,
    c: 0,
    c_global: 0,
    d: 200,
    d_global: 200,
    ix: 10340,
    ix_global: 103400000,
    iy: 6394,
    iy_global: 63940000,
    iz: 6394,
    iz_global: 63940000,
    t: 16,
    t_global: 16,
    zx: 785,
    zx_global: 785000,
    zy: 785,
    zy_global: 785000,
  },
  {
    profile_section_id: 3229,
    name: "220x6.3 SHS",
    type: "Square Hollow",
    designation: "220x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "53,4",
    ax_global: 5340,
    b: 220,
    b_global: 220,
    c: 0,
    c_global: 0,
    d: 220,
    d_global: 220,
    ix: 6240,
    ix_global: 62400000,
    iy: 4049,
    iy_global: 40490000,
    iz: 4049,
    iz_global: 40490000,
    t: "6,3",
    t_global: "6,3",
    zx: 427,
    zx_global: 427000,
    zy: 427,
    zy_global: 427000,
  },
  {
    profile_section_id: 3230,
    name: "220x8 SHS",
    type: "Square Hollow",
    designation: "220x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: "67,2",
    ax_global: 6720,
    b: 220,
    b_global: 220,
    c: 0,
    c_global: 0,
    d: 220,
    d_global: 220,
    ix: 7765,
    ix_global: 77650000,
    iy: 5002,
    iy_global: 50020000,
    iz: 5002,
    iz_global: 50020000,
    t: 8,
    t_global: 8,
    zx: 532,
    zx_global: 532000,
    zy: 532,
    zy_global: 532000,
  },
  {
    profile_section_id: 3231,
    name: "220x10 SHS",
    type: "Square Hollow",
    designation: "220x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: "82,9",
    ax_global: 8290,
    b: 220,
    b_global: 220,
    c: 0,
    c_global: 0,
    d: 220,
    d_global: 220,
    ix: 9473,
    ix_global: 94730000,
    iy: 6050,
    iy_global: 60500000,
    iz: 6050,
    iz_global: 60500000,
    t: 10,
    t_global: 10,
    zx: 650,
    zx_global: 650000,
    zy: 650,
    zy_global: 650000,
  },
  {
    profile_section_id: 3232,
    name: "220x12.5 SHS",
    type: "Square Hollow",
    designation: "220x12.5SHS",
    shape: "SHS",
    country_code: "European",
    ax: 102,
    ax_global: 10200,
    b: 220,
    b_global: 220,
    c: 0,
    c_global: 0,
    d: 220,
    d_global: 220,
    ix: 11480,
    ix_global: 114800000,
    iy: 7254,
    iy_global: 72540000,
    iz: 7254,
    iz_global: 72540000,
    t: "12,5",
    t_global: "12,5",
    zx: 789,
    zx_global: 789000,
    zy: 789,
    zy_global: 789000,
  },
  {
    profile_section_id: 3233,
    name: "220x14.2 SHS",
    type: "Square Hollow",
    designation: "220x14.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: 115,
    ax_global: 11500,
    b: 220,
    b_global: 220,
    c: 0,
    c_global: 0,
    d: 220,
    d_global: 220,
    ix: 12770,
    ix_global: 127700000,
    iy: 8007,
    iy_global: 80070000,
    iz: 8007,
    iz_global: 80070000,
    t: "14,2",
    t_global: "14,2",
    zx: 879,
    zx_global: 879000,
    zy: 879,
    zy_global: 879000,
  },
  {
    profile_section_id: 3234,
    name: "220x16 SHS",
    type: "Square Hollow",
    designation: "220x16SHS",
    shape: "SHS",
    country_code: "European",
    ax: 128,
    ax_global: 12800,
    b: 220,
    b_global: 220,
    c: 0,
    c_global: 0,
    d: 220,
    d_global: 220,
    ix: 14050,
    ix_global: 140500000,
    iy: 8749,
    iy_global: 87490000,
    iz: 8749,
    iz_global: 87490000,
    t: 16,
    t_global: 16,
    zx: 969,
    zx_global: 969000,
    zy: 969,
    zy_global: 969000,
  },
  {
    profile_section_id: 3235,
    name: "250x6.3 SHS",
    type: "Square Hollow",
    designation: "250x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: 61,
    ax_global: 6100,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 250,
    d_global: 250,
    ix: 9238,
    ix_global: 92380000,
    iy: 6014,
    iy_global: 60140000,
    iz: 6014,
    iz_global: 60140000,
    t: "6,3",
    t_global: "6,3",
    zx: 556,
    zx_global: 556000,
    zy: 556,
    zy_global: 556000,
  },
  {
    profile_section_id: 3236,
    name: "250x8 SHS",
    type: "Square Hollow",
    designation: "250x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: "76,8",
    ax_global: 7680,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 250,
    d_global: 250,
    ix: 11530,
    ix_global: 115300000,
    iy: 7455,
    iy_global: 74550000,
    iz: 7455,
    iz_global: 74550000,
    t: 8,
    t_global: 8,
    zx: 694,
    zx_global: 694000,
    zy: 694,
    zy_global: 694000,
  },
  {
    profile_section_id: 3237,
    name: "250x10 SHS",
    type: "Square Hollow",
    designation: "250x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: "94,9",
    ax_global: 9490,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 250,
    d_global: 250,
    ix: 14110,
    ix_global: 141100000,
    iy: 9055,
    iy_global: 90550000,
    iz: 9055,
    iz_global: 90550000,
    t: 10,
    t_global: 10,
    zx: 851,
    zx_global: 851000,
    zy: 851,
    zy_global: 851000,
  },
  {
    profile_section_id: 3238,
    name: "250x12.5 SHS",
    type: "Square Hollow",
    designation: "250x12.5SHS",
    shape: "SHS",
    country_code: "European",
    ax: 117,
    ax_global: 11700,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 250,
    d_global: 250,
    ix: 17160,
    ix_global: 171600000,
    iy: 10920,
    iy_global: 109200000,
    iz: 10920,
    iz_global: 109200000,
    t: "12,5",
    t_global: "12,5",
    zx: 1037,
    zx_global: 1037000,
    zy: 1037,
    zy_global: 1037000,
  },
  {
    profile_section_id: 3239,
    name: "250x14.2 SHS",
    type: "Square Hollow",
    designation: "250x14.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: 132,
    ax_global: 13200,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 250,
    d_global: 250,
    ix: 19140,
    ix_global: 191400000,
    iy: 12090,
    iy_global: 120900000,
    iz: 12090,
    iz_global: 120900000,
    t: "14,2",
    t_global: "14,2",
    zx: 1158,
    zx_global: 1158000,
    zy: 1158,
    zy_global: 1158000,
  },
  {
    profile_section_id: 3240,
    name: "250x16 SHS",
    type: "Square Hollow",
    designation: "250x16SHS",
    shape: "SHS",
    country_code: "European",
    ax: 147,
    ax_global: 14700,
    b: 250,
    b_global: 250,
    c: 0,
    c_global: 0,
    d: 250,
    d_global: 250,
    ix: 21140,
    ix_global: 211400000,
    iy: 13270,
    iy_global: 132700000,
    iz: 13270,
    iz_global: 132700000,
    t: 16,
    t_global: 16,
    zx: 1280,
    zx_global: 1280000,
    zy: 1280,
    zy_global: 1280000,
  },
  {
    profile_section_id: 3241,
    name: "260x6.3 SHS",
    type: "Square Hollow",
    designation: "260x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: "63,5",
    ax_global: 6350,
    b: 260,
    b_global: 260,
    c: 0,
    c_global: 0,
    d: 260,
    d_global: 260,
    ix: 10420,
    ix_global: 104200000,
    iy: 6788,
    iy_global: 67880000,
    iz: 6788,
    iz_global: 67880000,
    t: "6,3",
    t_global: "6,3",
    zx: 603,
    zx_global: 603000,
    zy: 603,
    zy_global: 603000,
  },
  {
    profile_section_id: 3242,
    name: "260x8 SHS",
    type: "Square Hollow",
    designation: "260x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: 80,
    ax_global: 8000,
    b: 260,
    b_global: 260,
    c: 0,
    c_global: 0,
    d: 260,
    d_global: 260,
    ix: 13010,
    ix_global: 130100000,
    iy: 8423,
    iy_global: 84230000,
    iz: 8423,
    iz_global: 84230000,
    t: 8,
    t_global: 8,
    zx: 753,
    zx_global: 753000,
    zy: 753,
    zy_global: 753000,
  },
  {
    profile_section_id: 3243,
    name: "260x10 SHS",
    type: "Square Hollow",
    designation: "260x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: "98,9",
    ax_global: 9890,
    b: 260,
    b_global: 260,
    c: 0,
    c_global: 0,
    d: 260,
    d_global: 260,
    ix: 15930,
    ix_global: 159300000,
    iy: 10240,
    iy_global: 102400000,
    iz: 10240,
    iz_global: 102400000,
    t: 10,
    t_global: 10,
    zx: 924,
    zx_global: 924000,
    zy: 924,
    zy_global: 924000,
  },
  {
    profile_section_id: 3244,
    name: "260x12.5 SHS",
    type: "Square Hollow",
    designation: "260x12.5SHS",
    shape: "SHS",
    country_code: "European",
    ax: 122,
    ax_global: 12200,
    b: 260,
    b_global: 260,
    c: 0,
    c_global: 0,
    d: 260,
    d_global: 260,
    ix: 19410,
    ix_global: 194100000,
    iy: 12370,
    iy_global: 123700000,
    iz: 12370,
    iz_global: 123700000,
    t: "12,5",
    t_global: "12,5",
    zx: 1127,
    zx_global: 1127000,
    zy: 1127,
    zy_global: 1127000,
  },
  {
    profile_section_id: 3245,
    name: "260x14.2 SHS",
    type: "Square Hollow",
    designation: "260x14.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: 137,
    ax_global: 13700,
    b: 260,
    b_global: 260,
    c: 0,
    c_global: 0,
    d: 260,
    d_global: 260,
    ix: 21660,
    ix_global: 216600000,
    iy: 13710,
    iy_global: 137100000,
    iz: 13710,
    iz_global: 137100000,
    t: "14,2",
    t_global: "14,2",
    zx: 1259,
    zx_global: 1259000,
    zy: 1259,
    zy_global: 1259000,
  },
  {
    profile_section_id: 3246,
    name: "260x16 SHS",
    type: "Square Hollow",
    designation: "260x16SHS",
    shape: "SHS",
    country_code: "European",
    ax: 153,
    ax_global: 15300,
    b: 260,
    b_global: 260,
    c: 0,
    c_global: 0,
    d: 260,
    d_global: 260,
    ix: 23940,
    ix_global: 239400000,
    iy: 15060,
    iy_global: 150600000,
    iz: 15060,
    iz_global: 150600000,
    t: 16,
    t_global: 16,
    zx: 1394,
    zx_global: 1394000,
    zy: 1394,
    zy_global: 1394000,
  },
  {
    profile_section_id: 3247,
    name: "300x6.3 SHS",
    type: "Square Hollow",
    designation: "300x6.3SHS",
    shape: "SHS",
    country_code: "European",
    ax: 74,
    ax_global: 7400,
    b: 300,
    b_global: 300,
    c: 0,
    c_global: 0,
    d: 300,
    d_global: 300,
    ix: 16140,
    ix_global: 161400000,
    iy: 10550,
    iy_global: 105500000,
    iz: 10550,
    iz_global: 105500000,
    t: "6,3",
    t_global: "6,3",
    zx: 809,
    zx_global: 809000,
    zy: 809,
    zy_global: 809000,
  },
  {
    profile_section_id: 3248,
    name: "300x8 SHS",
    type: "Square Hollow",
    designation: "300x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: 93,
    ax_global: 9300,
    b: 300,
    b_global: 300,
    c: 0,
    c_global: 0,
    d: 300,
    d_global: 300,
    ix: 20190,
    ix_global: 201900000,
    iy: 13130,
    iy_global: 131300000,
    iz: 13130,
    iz_global: 131300000,
    t: 8,
    t_global: 8,
    zx: 1013,
    zx_global: 1013000,
    zy: 1013,
    zy_global: 1013000,
  },
  {
    profile_section_id: 3249,
    name: "300x10 SHS",
    type: "Square Hollow",
    designation: "300x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: 115,
    ax_global: 11500,
    b: 300,
    b_global: 300,
    c: 0,
    c_global: 0,
    d: 300,
    d_global: 300,
    ix: 24810,
    ix_global: 248100000,
    iy: 16030,
    iy_global: 160300000,
    iz: 16030,
    iz_global: 160300000,
    t: 10,
    t_global: 10,
    zx: 1246,
    zx_global: 1246000,
    zy: 1246,
    zy_global: 1246000,
  },
  {
    profile_section_id: 3250,
    name: "300x12.5 SHS",
    type: "Square Hollow",
    designation: "300x12.5SHS",
    shape: "SHS",
    country_code: "European",
    ax: 142,
    ax_global: 14200,
    b: 300,
    b_global: 300,
    c: 0,
    c_global: 0,
    d: 300,
    d_global: 300,
    ix: 30330,
    ix_global: 303300000,
    iy: 19440,
    iy_global: 194400000,
    iz: 19440,
    iz_global: 194400000,
    t: "12,5",
    t_global: "12,5",
    zx: 1525,
    zx_global: 1525000,
    zy: 1525,
    zy_global: 1525000,
  },
  {
    profile_section_id: 3251,
    name: "300x14.2 SHS",
    type: "Square Hollow",
    designation: "300x14.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: 160,
    ax_global: 16000,
    b: 300,
    b_global: 300,
    c: 0,
    c_global: 0,
    d: 300,
    d_global: 300,
    ix: 33940,
    ix_global: 339400000,
    iy: 21640,
    iy_global: 216400000,
    iz: 21640,
    iz_global: 216400000,
    t: "14,2",
    t_global: "14,2",
    zx: 1708,
    zx_global: 1708000,
    zy: 1708,
    zy_global: 1708000,
  },
  {
    profile_section_id: 3252,
    name: "300x16 SHS",
    type: "Square Hollow",
    designation: "300x16SHS",
    shape: "SHS",
    country_code: "European",
    ax: 179,
    ax_global: 17900,
    b: 300,
    b_global: 300,
    c: 0,
    c_global: 0,
    d: 300,
    d_global: 300,
    ix: 37620,
    ix_global: 376200000,
    iy: 23850,
    iy_global: 238500000,
    iz: 23850,
    iz_global: 238500000,
    t: 16,
    t_global: 16,
    zx: 1895,
    zx_global: 1895000,
    zy: 1895,
    zy_global: 1895000,
  },
  {
    profile_section_id: 3253,
    name: "350x8 SHS",
    type: "Square Hollow",
    designation: "350x8SHS",
    shape: "SHS",
    country_code: "European",
    ax: 109,
    ax_global: 10900,
    b: 350,
    b_global: 350,
    c: 0,
    c_global: 0,
    d: 350,
    d_global: 350,
    ix: 32380,
    ix_global: 323800000,
    iy: 21130,
    iy_global: 211300000,
    iz: 21130,
    iz_global: 211300000,
    t: 8,
    t_global: 8,
    zx: 1392,
    zx_global: 1392000,
    zy: 1392,
    zy_global: 1392000,
  },
  {
    profile_section_id: 3254,
    name: "350x10 SHS",
    type: "Square Hollow",
    designation: "350x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: 135,
    ax_global: 13500,
    b: 350,
    b_global: 350,
    c: 0,
    c_global: 0,
    d: 350,
    d_global: 350,
    ix: 39890,
    ix_global: 398900000,
    iy: 25880,
    iy_global: 258800000,
    iz: 25880,
    iz_global: 258800000,
    t: 10,
    t_global: 10,
    zx: 1715,
    zx_global: 1715000,
    zy: 1715,
    zy_global: 1715000,
  },
  {
    profile_section_id: 3255,
    name: "350x12.5 SHS",
    type: "Square Hollow",
    designation: "350x12.5SHS",
    shape: "SHS",
    country_code: "European",
    ax: 167,
    ax_global: 16700,
    b: 350,
    b_global: 350,
    c: 0,
    c_global: 0,
    d: 350,
    d_global: 350,
    ix: 48930,
    ix_global: 489300000,
    iy: 31540,
    iy_global: 315400000,
    iz: 31540,
    iz_global: 315400000,
    t: "12,5",
    t_global: "12,5",
    zx: 2107,
    zx_global: 2107000,
    zy: 2107,
    zy_global: 2107000,
  },
  {
    profile_section_id: 3256,
    name: "350x14.2 SHS",
    type: "Square Hollow",
    designation: "350x14.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: 189,
    ax_global: 18900,
    b: 350,
    b_global: 350,
    c: 0,
    c_global: 0,
    d: 350,
    d_global: 350,
    ix: 54880,
    ix_global: 548800000,
    iy: 35210,
    iy_global: 352100000,
    iz: 35210,
    iz_global: 352100000,
    t: "14,2",
    t_global: "14,2",
    zx: 2364,
    zx_global: 2364000,
    zy: 2364,
    zy_global: 2364000,
  },
  {
    profile_section_id: 3257,
    name: "350x16 SHS",
    type: "Square Hollow",
    designation: "350x16SHS",
    shape: "SHS",
    country_code: "European",
    ax: 211,
    ax_global: 21100,
    b: 350,
    b_global: 350,
    c: 0,
    c_global: 0,
    d: 350,
    d_global: 350,
    ix: 60990,
    ix_global: 609900000,
    iy: 38940,
    iy_global: 389400000,
    iz: 38940,
    iz_global: 389400000,
    t: 16,
    t_global: 16,
    zx: 2630,
    zx_global: 2630000,
    zy: 2630,
    zy_global: 2630000,
  },
  {
    profile_section_id: 3258,
    name: "400x10 SHS",
    type: "Square Hollow",
    designation: "400x10SHS",
    shape: "SHS",
    country_code: "European",
    ax: 155,
    ax_global: 15500,
    b: 400,
    b_global: 400,
    c: 0,
    c_global: 0,
    d: 400,
    d_global: 400,
    ix: 60090,
    ix_global: 600900000,
    iy: 39130,
    iy_global: 391300000,
    iz: 39130,
    iz_global: 391300000,
    t: 10,
    t_global: 10,
    zx: 2260,
    zx_global: 2260000,
    zy: 2260,
    zy_global: 2260000,
  },
  {
    profile_section_id: 3259,
    name: "400x12.5 SHS",
    type: "Square Hollow",
    designation: "400x12.5SHS",
    shape: "SHS",
    country_code: "European",
    ax: 192,
    ax_global: 19200,
    b: 400,
    b_global: 400,
    c: 0,
    c_global: 0,
    d: 400,
    d_global: 400,
    ix: 73910,
    ix_global: 739100000,
    iy: 47840,
    iy_global: 478400000,
    iz: 47840,
    iz_global: 478400000,
    t: "12,5",
    t_global: "12,5",
    zx: 2782,
    zx_global: 2782000,
    zy: 2782,
    zy_global: 2782000,
  },
  {
    profile_section_id: 3260,
    name: "400x14.2 SHS",
    type: "Square Hollow",
    designation: "400x14.2SHS",
    shape: "SHS",
    country_code: "European",
    ax: 217,
    ax_global: 21700,
    b: 400,
    b_global: 400,
    c: 0,
    c_global: 0,
    d: 400,
    d_global: 400,
    ix: 83030,
    ix_global: 830300000,
    iy: 53530,
    iy_global: 535300000,
    iz: 53530,
    iz_global: 535300000,
    t: "14,2",
    t_global: "14,2",
    zx: 3127,
    zx_global: 3127000,
    zy: 3127,
    zy_global: 3127000,
  },
  {
    profile_section_id: 3261,
    name: "400x16 SHS",
    type: "Square Hollow",
    designation: "400x16SHS",
    shape: "SHS",
    country_code: "European",
    ax: 243,
    ax_global: 24300,
    b: 400,
    b_global: 400,
    c: 0,
    c_global: 0,
    d: 400,
    d_global: 400,
    ix: 92440,
    ix_global: 924400000,
    iy: 59340,
    iy_global: 593400000,
    iz: 59340,
    iz_global: 593400000,
    t: 16,
    t_global: 16,
    zx: 3484,
    zx_global: 3484000,
    zy: 3484,
    zy_global: 3484000,
  },
  {
    profile_section_id: 3262,
    name: "400x20 SHS",
    type: "Square Hollow",
    designation: "400x20SHS",
    shape: "SHS",
    country_code: "European",
    ax: 300,
    ax_global: 30000,
    b: 400,
    b_global: 400,
    c: 0,
    c_global: 0,
    d: 400,
    d_global: 400,
    ix: 112500,
    ix_global: 1125000000,
    iy: 71540,
    iy_global: 715400000,
    iz: 71540,
    iz_global: 715400000,
    t: 20,
    t_global: 20,
    zx: 4247,
    zx_global: 4247000,
    zy: 4247,
    zy_global: 4247000,
  },
  {
    profile_section_id: 3263,
    name: "45x45",
    type: "SolidSquare",
    designation: "45x45",
    shape: "Square Rod",
    country_code: "European",
    ax: "20,25",
    ax_global: 2025,
    d: 45,
    d_global: 45,
    ix: "68,34",
    ix_global: 683400,
    iy: "34,17",
    iy_global: 341700,
    iz: "34,17",
    iz_global: 341700,
    zx: "15,19",
    zx_global: 15190,
    zy: "15,19",
    zy_global: 15190,
  },
  {
    profile_section_id: 3264,
    name: "50x50",
    type: "SolidSquare",
    designation: "50x50",
    shape: "Square Rod",
    country_code: "European",
    ax: 25,
    ax_global: 2500,
    d: 50,
    d_global: 50,
    ix: "104,17",
    ix_global: 1041700,
    iy: "52,08",
    iy_global: 520800,
    iz: "52,08",
    iz_global: 520800,
    zx: "20,83",
    zx_global: 20830,
    zy: "20,83",
    zy_global: 20830,
  },
  {
    profile_section_id: 3265,
    name: "55x55",
    type: "SolidSquare",
    designation: "55x55",
    shape: "Square Rod",
    country_code: "European",
    ax: "30,25",
    ax_global: 3025,
    d: 55,
    d_global: 55,
    ix: "152,51",
    ix_global: 1525100,
    iy: "76,26",
    iy_global: 762600,
    iz: "76,26",
    iz_global: 762600,
    zx: "27,73",
    zx_global: 27730,
    zy: "27,73",
    zy_global: 27730,
  },
  {
    profile_section_id: 3266,
    name: "60x60",
    type: "SolidSquare",
    designation: "60x60",
    shape: "Square Rod",
    country_code: "European",
    ax: 36,
    ax_global: 3600,
    d: 60,
    d_global: 60,
    ix: 216,
    ix_global: 2160000,
    iy: 108,
    iy_global: 1080000,
    iz: 108,
    iz_global: 1080000,
    zx: 36,
    zx_global: 36000,
    zy: 36,
    zy_global: 36000,
  },
  {
    profile_section_id: 3267,
    name: "70x70",
    type: "SolidSquare",
    designation: "70x70",
    shape: "Square Rod",
    country_code: "European",
    ax: 49,
    ax_global: 4900,
    d: 70,
    d_global: 70,
    ix: "400,17",
    ix_global: 4001700,
    iy: "200,08",
    iy_global: 2000800,
    iz: "200,08",
    iz_global: 2000800,
    zx: "57,17",
    zx_global: 57170,
    zy: "57,17",
    zy_global: 57170,
  },
  {
    profile_section_id: 3268,
    name: "80x80",
    type: "SolidSquare",
    designation: "80x80",
    shape: "Square Rod",
    country_code: "European",
    ax: 64,
    ax_global: 6400,
    d: 80,
    d_global: 80,
    ix: "682,67",
    ix_global: 6826700,
    iy: "341,33",
    iy_global: 3413300,
    iz: "341,33",
    iz_global: 3413300,
    zx: "85,33",
    zx_global: 85330,
    zy: "85,33",
    zy_global: 85330,
  },
  {
    profile_section_id: 3269,
    name: "85x85",
    type: "SolidSquare",
    designation: "85x85",
    shape: "Square Rod",
    country_code: "European",
    ax: "72,25",
    ax_global: 7225,
    d: 85,
    d_global: 85,
    ix: "870,01",
    ix_global: 8700100,
    iy: "435,01",
    iy_global: 4350100,
    iz: "435,01",
    iz_global: 4350100,
    zx: "102,35",
    zx_global: 102350,
    zy: "102,35",
    zy_global: 102350,
  },
  {
    profile_section_id: 3270,
    name: "90x90",
    type: "SolidSquare",
    designation: "90x90",
    shape: "Square Rod",
    country_code: "European",
    ax: 81,
    ax_global: 8100,
    d: 90,
    d_global: 90,
    ix: "1093,5",
    ix_global: 10935000,
    iy: "546,75",
    iy_global: 5467500,
    iz: "546,75",
    iz_global: 5467500,
    zx: "121,5",
    zx_global: 121500,
    zy: "121,5",
    zy_global: 121500,
  },
  {
    profile_section_id: 3271,
    name: "95x95",
    type: "SolidSquare",
    designation: "95x95",
    shape: "Square Rod",
    country_code: "European",
    ax: "90,25",
    ax_global: 9025,
    d: 95,
    d_global: 95,
    ix: "1357,51",
    ix_global: 13575100,
    iy: "678,76",
    iy_global: 6787600,
    iz: "678,76",
    iz_global: 6787600,
    zx: "142,9",
    zx_global: 142900,
    zy: "142,9",
    zy_global: 142900,
  },
  {
    profile_section_id: 3272,
    name: "100x100",
    type: "SolidSquare",
    designation: "100x100",
    shape: "Square Rod",
    country_code: "European",
    ax: 100,
    ax_global: 10000,
    d: 100,
    d_global: 100,
    ix: "1666,67",
    ix_global: 16666700,
    iy: "833,33",
    iy_global: 8333300,
    iz: "833,33",
    iz_global: 8333300,
    zx: "166,67",
    zx_global: 166670,
    zy: "166,67",
    zy_global: 166670,
  },
  {
    profile_section_id: 3273,
    name: "110x110",
    type: "SolidSquare",
    designation: "110x110",
    shape: "Square Rod",
    country_code: "European",
    ax: 121,
    ax_global: 12100,
    d: 110,
    d_global: 110,
    ix: "2440,17",
    ix_global: 24401700,
    iy: "1220,08",
    iy_global: 12200800,
    iz: "1220,08",
    iz_global: 12200800,
    zx: "221,83",
    zx_global: 221830,
    zy: "221,83",
    zy_global: 221830,
  },
  {
    profile_section_id: 3274,
    name: "120x120",
    type: "SolidSquare",
    designation: "120x120",
    shape: "Square Rod",
    country_code: "European",
    ax: 144,
    ax_global: 14400,
    d: 120,
    d_global: 120,
    ix: 3456,
    ix_global: 34560000,
    iy: 1728,
    iy_global: 17280000,
    iz: 1728,
    iz_global: 17280000,
    zx: 288,
    zx_global: 288000,
    zy: 288,
    zy_global: 288000,
  },
  {
    profile_section_id: 3275,
    name: "130x130",
    type: "SolidSquare",
    designation: "130x130",
    shape: "Square Rod",
    country_code: "European",
    ax: 169,
    ax_global: 16900,
    d: 130,
    d_global: 130,
    ix: "4760,17",
    ix_global: 47601700,
    iy: "2380,08",
    iy_global: 23800800,
    iz: "2380,08",
    iz_global: 23800800,
    zx: "366,17",
    zx_global: 366170,
    zy: "366,17",
    zy_global: 366170,
  },
  {
    profile_section_id: 3276,
    name: "140x140",
    type: "SolidSquare",
    designation: "140x140",
    shape: "Square Rod",
    country_code: "European",
    ax: 196,
    ax_global: 19600,
    d: 140,
    d_global: 140,
    ix: "6402,67",
    ix_global: 64026700,
    iy: "3201,33",
    iy_global: 32013300,
    iz: "3201,33",
    iz_global: 32013300,
    zx: "457,33",
    zx_global: 457330,
    zy: "457,33",
    zy_global: 457330,
  },
  {
    profile_section_id: 3277,
    name: "150x150",
    type: "SolidSquare",
    designation: "150x150",
    shape: "Square Rod",
    country_code: "European",
    ax: 225,
    ax_global: 22500,
    d: 150,
    d_global: 150,
    ix: "8437,5",
    ix_global: 84375000,
    iy: "4218,75",
    iy_global: 42187500,
    iz: "4218,75",
    iz_global: 42187500,
    zx: "562,5",
    zx_global: 562500,
    zy: "562,5",
    zy_global: 562500,
  },
  {
    profile_section_id: 3278,
    name: "160x160",
    type: "SolidSquare",
    designation: "160x160",
    shape: "Square Rod",
    country_code: "European",
    ax: 256,
    ax_global: 25600,
    d: 160,
    d_global: 160,
    ix: "10922,67",
    ix_global: 109226700,
    iy: "5461,33",
    iy_global: 54613300,
    iz: "5461,33",
    iz_global: 54613300,
    zx: "682,67",
    zx_global: 682670,
    zy: "682,67",
    zy_global: 682670,
  },
  {
    profile_section_id: 3745,
    name: "400x40",
    type: "FlatBar",
    designation: "400x40",
    shape: "FLAT",
    country_code: "European",
    ax: 160,
    ax_global: 16000,
    b: 400,
    b_global: 400,
    t: 10,
    t_global: 10,
  },
  {
    profile_section_id: 3746,
    name: "400x35",
    type: "FlatBar",
    designation: "400x35",
    shape: "FLAT",
    country_code: "European",
    ax: 140,
    ax_global: 14000,
    b: 400,
    b_global: 400,
    t: 12,
    t_global: 12,
  },
  {
    profile_section_id: 3747,
    name: "400x30",
    type: "FlatBar",
    designation: "400x30",
    shape: "FLAT",
    country_code: "European",
    ax: 120,
    ax_global: 12000,
    b: 400,
    b_global: 400,
    t: 15,
    t_global: 15,
  },
  {
    profile_section_id: 3748,
    name: "400x25",
    type: "FlatBar",
    designation: "400x25",
    shape: "FLAT",
    country_code: "European",
    ax: 100,
    ax_global: 10000,
    b: 400,
    b_global: 400,
    t: 20,
    t_global: 20,
  },
  {
    profile_section_id: 3749,
    name: "400x20",
    type: "FlatBar",
    designation: "400x20",
    shape: "FLAT",
    country_code: "European",
    ax: 80,
    ax_global: 8000,
    b: 400,
    b_global: 400,
    t: 25,
    t_global: 25,
  },
  {
    profile_section_id: 3750,
    name: "400x15",
    type: "FlatBar",
    designation: "400x15",
    shape: "FLAT",
    country_code: "European",
    ax: 60,
    ax_global: 6000,
    b: 400,
    b_global: 400,
    t: 30,
    t_global: 30,
  },
  {
    profile_section_id: 3751,
    name: "400x12",
    type: "FlatBar",
    designation: "400x12",
    shape: "FLAT",
    country_code: "European",
    ax: 48,
    ax_global: 4800,
    b: 400,
    b_global: 400,
    t: 35,
    t_global: 35,
  },
  {
    profile_section_id: 3752,
    name: "400x10",
    type: "FlatBar",
    designation: "400x10",
    shape: "FLAT",
    country_code: "European",
    ax: 40,
    ax_global: 4000,
    b: 400,
    b_global: 400,
    t: 40,
    t_global: 40,
  },
  {
    profile_section_id: 3753,
    name: "350x40",
    type: "FlatBar",
    designation: "350x40",
    shape: "FLAT",
    country_code: "European",
    ax: 140,
    ax_global: 14000,
    b: 350,
    b_global: 350,
    t: 10,
    t_global: 10,
  },
  {
    profile_section_id: 3754,
    name: "350x35",
    type: "FlatBar",
    designation: "350x35",
    shape: "FLAT",
    country_code: "European",
    ax: "122,5",
    ax_global: 12250,
    b: 350,
    b_global: 350,
    t: 12,
    t_global: 12,
  },
  {
    profile_section_id: 3755,
    name: "350x30",
    type: "FlatBar",
    designation: "350x30",
    shape: "FLAT",
    country_code: "European",
    ax: 105,
    ax_global: 10500,
    b: 350,
    b_global: 350,
    t: 15,
    t_global: 15,
  },
  {
    profile_section_id: 3756,
    name: "350x25",
    type: "FlatBar",
    designation: "350x25",
    shape: "FLAT",
    country_code: "European",
    ax: "87,5",
    ax_global: 8750,
    b: 350,
    b_global: 350,
    t: 20,
    t_global: 20,
  },
  {
    profile_section_id: 3757,
    name: "350x20",
    type: "FlatBar",
    designation: "350x20",
    shape: "FLAT",
    country_code: "European",
    ax: 70,
    ax_global: 7000,
    b: 350,
    b_global: 350,
    t: 25,
    t_global: 25,
  },
  {
    profile_section_id: 3758,
    name: "350x15",
    type: "FlatBar",
    designation: "350x15",
    shape: "FLAT",
    country_code: "European",
    ax: "52,5",
    ax_global: 5250,
    b: 350,
    b_global: 350,
    t: 30,
    t_global: 30,
  },
  {
    profile_section_id: 3759,
    name: "350x12",
    type: "FlatBar",
    designation: "350x12",
    shape: "FLAT",
    country_code: "European",
    ax: 42,
    ax_global: 4200,
    b: 350,
    b_global: 350,
    t: 35,
    t_global: 35,
  },
  {
    profile_section_id: 3760,
    name: "350x10",
    type: "FlatBar",
    designation: "350x10",
    shape: "FLAT",
    country_code: "European",
    ax: 35,
    ax_global: 3500,
    b: 350,
    b_global: 350,
    t: 40,
    t_global: 40,
  },
  {
    profile_section_id: 3761,
    name: "300x40",
    type: "FlatBar",
    designation: "300x40",
    shape: "FLAT",
    country_code: "European",
    ax: 120,
    ax_global: 12000,
    b: 300,
    b_global: 300,
    t: 10,
    t_global: 10,
  },
  {
    profile_section_id: 3762,
    name: "300x35",
    type: "FlatBar",
    designation: "300x35",
    shape: "FLAT",
    country_code: "European",
    ax: 105,
    ax_global: 10500,
    b: 300,
    b_global: 300,
    t: 12,
    t_global: 12,
  },
  {
    profile_section_id: 3763,
    name: "300x30",
    type: "FlatBar",
    designation: "300x30",
    shape: "FLAT",
    country_code: "European",
    ax: 90,
    ax_global: 9000,
    b: 300,
    b_global: 300,
    t: 15,
    t_global: 15,
  },
  {
    profile_section_id: 3764,
    name: "300x25",
    type: "FlatBar",
    designation: "300x25",
    shape: "FLAT",
    country_code: "European",
    ax: 75,
    ax_global: 7500,
    b: 300,
    b_global: 300,
    t: 20,
    t_global: 20,
  },
  {
    profile_section_id: 3765,
    name: "300x20",
    type: "FlatBar",
    designation: "300x20",
    shape: "FLAT",
    country_code: "European",
    ax: 60,
    ax_global: 6000,
    b: 300,
    b_global: 300,
    t: 25,
    t_global: 25,
  },
  {
    profile_section_id: 3766,
    name: "300x15",
    type: "FlatBar",
    designation: "300x15",
    shape: "FLAT",
    country_code: "European",
    ax: 45,
    ax_global: 4500,
    b: 300,
    b_global: 300,
    t: 30,
    t_global: 30,
  },
  {
    profile_section_id: 3767,
    name: "300x12",
    type: "FlatBar",
    designation: "300x12",
    shape: "FLAT",
    country_code: "European",
    ax: 36,
    ax_global: 3600,
    b: 300,
    b_global: 300,
    t: 35,
    t_global: 35,
  },
  {
    profile_section_id: 3768,
    name: "300x10",
    type: "FlatBar",
    designation: "300x10",
    shape: "FLAT",
    country_code: "European",
    ax: 30,
    ax_global: 3000,
    b: 300,
    b_global: 300,
    t: 40,
    t_global: 40,
  },
  {
    profile_section_id: 3769,
    name: "250x40",
    type: "FlatBar",
    designation: "250x40",
    shape: "FLAT",
    country_code: "European",
    ax: 100,
    ax_global: 10000,
    b: 250,
    b_global: 250,
    t: 10,
    t_global: 10,
  },
  {
    profile_section_id: 3770,
    name: "250x35",
    type: "FlatBar",
    designation: "250x35",
    shape: "FLAT",
    country_code: "European",
    ax: "87,5",
    ax_global: 8750,
    b: 250,
    b_global: 250,
    t: 12,
    t_global: 12,
  },
  {
    profile_section_id: 3771,
    name: "250x30",
    type: "FlatBar",
    designation: "250x30",
    shape: "FLAT",
    country_code: "European",
    ax: 75,
    ax_global: 7500,
    b: 250,
    b_global: 250,
    t: 15,
    t_global: 15,
  },
  {
    profile_section_id: 3772,
    name: "250x25",
    type: "FlatBar",
    designation: "250x25",
    shape: "FLAT",
    country_code: "European",
    ax: "62,5",
    ax_global: 6250,
    b: 250,
    b_global: 250,
    t: 20,
    t_global: 20,
  },
  {
    profile_section_id: 3773,
    name: "250x20",
    type: "FlatBar",
    designation: "250x20",
    shape: "FLAT",
    country_code: "European",
    ax: 50,
    ax_global: 5000,
    b: 250,
    b_global: 250,
    t: 25,
    t_global: 25,
  },
  {
    profile_section_id: 3774,
    name: "250x15",
    type: "FlatBar",
    designation: "250x15",
    shape: "FLAT",
    country_code: "European",
    ax: "37,5",
    ax_global: 3750,
    b: 250,
    b_global: 250,
    t: 30,
    t_global: 30,
  },
  {
    profile_section_id: 3775,
    name: "250x12",
    type: "FlatBar",
    designation: "250x12",
    shape: "FLAT",
    country_code: "European",
    ax: 30,
    ax_global: 3000,
    b: 250,
    b_global: 250,
    t: 35,
    t_global: 35,
  },
  {
    profile_section_id: 3776,
    name: "250x10",
    type: "FlatBar",
    designation: "250x10",
    shape: "FLAT",
    country_code: "European",
    ax: 25,
    ax_global: 2500,
    b: 250,
    b_global: 250,
    t: 40,
    t_global: 40,
  },
  {
    profile_section_id: 3777,
    name: "220x40",
    type: "FlatBar",
    designation: "220x40",
    shape: "FLAT",
    country_code: "European",
    ax: 88,
    ax_global: 8800,
    b: 220,
    b_global: 220,
    t: 10,
    t_global: 10,
  },
  {
    profile_section_id: 3778,
    name: "220x35",
    type: "FlatBar",
    designation: "220x35",
    shape: "FLAT",
    country_code: "European",
    ax: 77,
    ax_global: 7700,
    b: 220,
    b_global: 220,
    t: 12,
    t_global: 12,
  },
  {
    profile_section_id: 3779,
    name: "220x30",
    type: "FlatBar",
    designation: "220x30",
    shape: "FLAT",
    country_code: "European",
    ax: 66,
    ax_global: 6600,
    b: 220,
    b_global: 220,
    t: 15,
    t_global: 15,
  },
  {
    profile_section_id: 3780,
    name: "220x25",
    type: "FlatBar",
    designation: "220x25",
    shape: "FLAT",
    country_code: "European",
    ax: 55,
    ax_global: 5500,
    b: 220,
    b_global: 220,
    t: 20,
    t_global: 20,
  },
  {
    profile_section_id: 3781,
    name: "220x20",
    type: "FlatBar",
    designation: "220x20",
    shape: "FLAT",
    country_code: "European",
    ax: 44,
    ax_global: 4400,
    b: 220,
    b_global: 220,
    t: 25,
    t_global: 25,
  },
  {
    profile_section_id: 3782,
    name: "220x15",
    type: "FlatBar",
    designation: "220x15",
    shape: "FLAT",
    country_code: "European",
    ax: 33,
    ax_global: 3300,
    b: 220,
    b_global: 220,
    t: 30,
    t_global: 30,
  },
  {
    profile_section_id: 3783,
    name: "220x12",
    type: "FlatBar",
    designation: "220x12",
    shape: "FLAT",
    country_code: "European",
    ax: "26,4",
    ax_global: 2640,
    b: 220,
    b_global: 220,
    t: 35,
    t_global: 35,
  },
  {
    profile_section_id: 3784,
    name: "220x10",
    type: "FlatBar",
    designation: "220x10",
    shape: "FLAT",
    country_code: "European",
    ax: 22,
    ax_global: 2200,
    b: 220,
    b_global: 220,
    t: 40,
    t_global: 40,
  },
  {
    profile_section_id: 3785,
    name: "200x40",
    type: "FlatBar",
    designation: "200x40",
    shape: "FLAT",
    country_code: "European",
    ax: 80,
    ax_global: 8000,
    b: 200,
    b_global: 200,
    t: 8,
    t_global: 8,
  },
  {
    profile_section_id: 3786,
    name: "200x35",
    type: "FlatBar",
    designation: "200x35",
    shape: "FLAT",
    country_code: "European",
    ax: 70,
    ax_global: 7000,
    b: 200,
    b_global: 200,
    t: 10,
    t_global: 10,
  },
  {
    profile_section_id: 3787,
    name: "200x30",
    type: "FlatBar",
    designation: "200x30",
    shape: "FLAT",
    country_code: "European",
    ax: 60,
    ax_global: 6000,
    b: 200,
    b_global: 200,
    t: 12,
    t_global: 12,
  },
  {
    profile_section_id: 3788,
    name: "200x25",
    type: "FlatBar",
    designation: "200x25",
    shape: "FLAT",
    country_code: "European",
    ax: 50,
    ax_global: 5000,
    b: 200,
    b_global: 200,
    t: 14,
    t_global: 14,
  },
  {
    profile_section_id: 3789,
    name: "200x20",
    type: "FlatBar",
    designation: "200x20",
    shape: "FLAT",
    country_code: "European",
    ax: 40,
    ax_global: 4000,
    b: 200,
    b_global: 200,
    t: 15,
    t_global: 15,
  },
  {
    profile_section_id: 3790,
    name: "200x18",
    type: "FlatBar",
    designation: "200x18",
    shape: "FLAT",
    country_code: "European",
    ax: 36,
    ax_global: 3600,
    b: 200,
    b_global: 200,
    t: 16,
    t_global: 16,
  },
  {
    profile_section_id: 3791,
    name: "200x16",
    type: "FlatBar",
    designation: "200x16",
    shape: "FLAT",
    country_code: "European",
    ax: 32,
    ax_global: 3200,
    b: 200,
    b_global: 200,
    t: 18,
    t_global: 18,
  },
  {
    profile_section_id: 3792,
    name: "200x15",
    type: "FlatBar",
    designation: "200x15",
    shape: "FLAT",
    country_code: "European",
    ax: 30,
    ax_global: 3000,
    b: 200,
    b_global: 200,
    t: 20,
    t_global: 20,
  },
  {
    profile_section_id: 3793,
    name: "200x14",
    type: "FlatBar",
    designation: "200x14",
    shape: "FLAT",
    country_code: "European",
    ax: 28,
    ax_global: 2800,
    b: 200,
    b_global: 200,
    t: 25,
    t_global: 25,
  },
  {
    profile_section_id: 3794,
    name: "200x12",
    type: "FlatBar",
    designation: "200x12",
    shape: "FLAT",
    country_code: "European",
    ax: 24,
    ax_global: 2400,
    b: 200,
    b_global: 200,
    t: 30,
    t_global: 30,
  },
  {
    profile_section_id: 3795,
    name: "200x10",
    type: "FlatBar",
    designation: "200x10",
    shape: "FLAT",
    country_code: "European",
    ax: 20,
    ax_global: 2000,
    b: 200,
    b_global: 200,
    t: 35,
    t_global: 35,
  },
  {
    profile_section_id: 3796,
    name: "200x8",
    type: "FlatBar",
    designation: "200x8",
    shape: "FLAT",
    country_code: "European",
    ax: 16,
    ax_global: 1600,
    b: 200,
    b_global: 200,
    t: 40,
    t_global: 40,
  },
  {
    profile_section_id: 3797,
    name: "180x60",
    type: "FLAT",
    designation: "180x60",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 60,
  },
  {
    profile_section_id: 3798,
    name: "180X50",
    type: "FLAT",
    designation: "180X50",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 50,
  },
  {
    profile_section_id: 3799,
    name: "180X45",
    type: "FLAT",
    designation: "180X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 45,
  },
  {
    profile_section_id: 3800,
    name: "180X40",
    type: "FLAT",
    designation: "180X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 40,
  },
  {
    profile_section_id: 3801,
    name: "180X35",
    type: "FLAT",
    designation: "180X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 35,
  },
  {
    profile_section_id: 3802,
    name: "180X30",
    type: "FLAT",
    designation: "180X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 30,
  },
  {
    profile_section_id: 3803,
    name: "180X25",
    type: "FLAT",
    designation: "180X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 25,
  },
  {
    profile_section_id: 3804,
    name: "180X20",
    type: "FLAT",
    designation: "180X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 20,
  },
  {
    profile_section_id: 3805,
    name: "180X18",
    type: "FLAT",
    designation: "180X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 18,
  },
  {
    profile_section_id: 3806,
    name: "180X16",
    type: "FLAT",
    designation: "180X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 16,
  },
  {
    profile_section_id: 3807,
    name: "180X15",
    type: "FLAT",
    designation: "180X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 15,
  },
  {
    profile_section_id: 3808,
    name: "180X14",
    type: "FLAT",
    designation: "180X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 14,
  },
  {
    profile_section_id: 3809,
    name: "180X12",
    type: "FLAT",
    designation: "180X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 12,
  },
  {
    profile_section_id: 3810,
    name: "180X10",
    type: "FLAT",
    designation: "180X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 10,
  },
  {
    profile_section_id: 3811,
    name: "180X8",
    type: "FLAT",
    designation: "180X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 180,
    t_global: 8,
  },
  {
    profile_section_id: 3812,
    name: "170x60",
    type: "FLAT",
    designation: "170x60",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 60,
  },
  {
    profile_section_id: 3813,
    name: "170X50",
    type: "FLAT",
    designation: "170X50",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 50,
  },
  {
    profile_section_id: 3814,
    name: "170X45",
    type: "FLAT",
    designation: "170X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 45,
  },
  {
    profile_section_id: 3815,
    name: "170X40",
    type: "FLAT",
    designation: "170X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 40,
  },
  {
    profile_section_id: 3816,
    name: "170X35",
    type: "FLAT",
    designation: "170X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 35,
  },
  {
    profile_section_id: 3817,
    name: "170X30",
    type: "FLAT",
    designation: "170X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 30,
  },
  {
    profile_section_id: 3818,
    name: "170X25",
    type: "FLAT",
    designation: "170X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 25,
  },
  {
    profile_section_id: 3819,
    name: "170X20",
    type: "FLAT",
    designation: "170X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 20,
  },
  {
    profile_section_id: 3820,
    name: "170X18",
    type: "FLAT",
    designation: "170X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 18,
  },
  {
    profile_section_id: 3821,
    name: "170X16",
    type: "FLAT",
    designation: "170X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 16,
  },
  {
    profile_section_id: 3822,
    name: "170X15",
    type: "FLAT",
    designation: "170X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 15,
  },
  {
    profile_section_id: 3823,
    name: "170X14",
    type: "FLAT",
    designation: "170X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 14,
  },
  {
    profile_section_id: 3824,
    name: "170X12",
    type: "FLAT",
    designation: "170X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 12,
  },
  {
    profile_section_id: 3825,
    name: "170X10",
    type: "FLAT",
    designation: "170X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 10,
  },
  {
    profile_section_id: 3826,
    name: "170X8",
    type: "FLAT",
    designation: "170X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 170,
    t_global: 8,
  },
  {
    profile_section_id: 3827,
    name: "160x60",
    type: "FLAT",
    designation: "160x60",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 60,
  },
  {
    profile_section_id: 3828,
    name: "160X50",
    type: "FLAT",
    designation: "160X50",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 50,
  },
  {
    profile_section_id: 3829,
    name: "160X45",
    type: "FLAT",
    designation: "160X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 45,
  },
  {
    profile_section_id: 3830,
    name: "160X40",
    type: "FLAT",
    designation: "160X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 40,
  },
  {
    profile_section_id: 3831,
    name: "160X35",
    type: "FLAT",
    designation: "160X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 35,
  },
  {
    profile_section_id: 3832,
    name: "160X30",
    type: "FLAT",
    designation: "160X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 30,
  },
  {
    profile_section_id: 3833,
    name: "160X25",
    type: "FLAT",
    designation: "160X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 25,
  },
  {
    profile_section_id: 3834,
    name: "160X20",
    type: "FLAT",
    designation: "160X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 20,
  },
  {
    profile_section_id: 3835,
    name: "160X18",
    type: "FLAT",
    designation: "160X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 18,
  },
  {
    profile_section_id: 3836,
    name: "160X16",
    type: "FLAT",
    designation: "160X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 16,
  },
  {
    profile_section_id: 3837,
    name: "160X15",
    type: "FLAT",
    designation: "160X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 15,
  },
  {
    profile_section_id: 3838,
    name: "160X14",
    type: "FLAT",
    designation: "160X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 14,
  },
  {
    profile_section_id: 3839,
    name: "160X12",
    type: "FLAT",
    designation: "160X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 12,
  },
  {
    profile_section_id: 3840,
    name: "160X10",
    type: "FLAT",
    designation: "160X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 10,
  },
  {
    profile_section_id: 3841,
    name: "160X8",
    type: "FLAT",
    designation: "160X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 160,
    t_global: 8,
  },
  {
    profile_section_id: 3842,
    name: "150x60",
    type: "FLAT",
    designation: "150x60",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 60,
  },
  {
    profile_section_id: 3843,
    name: "150X50",
    type: "FLAT",
    designation: "150X50",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 50,
  },
  {
    profile_section_id: 3844,
    name: "150X45",
    type: "FLAT",
    designation: "150X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 45,
  },
  {
    profile_section_id: 3845,
    name: "150X40",
    type: "FLAT",
    designation: "150X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 40,
  },
  {
    profile_section_id: 3846,
    name: "150X35",
    type: "FLAT",
    designation: "150X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 35,
  },
  {
    profile_section_id: 3847,
    name: "150X30",
    type: "FLAT",
    designation: "150X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 30,
  },
  {
    profile_section_id: 3848,
    name: "150X25",
    type: "FLAT",
    designation: "150X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 25,
  },
  {
    profile_section_id: 3849,
    name: "150X20",
    type: "FLAT",
    designation: "150X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 20,
  },
  {
    profile_section_id: 3850,
    name: "150X18",
    type: "FLAT",
    designation: "150X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 18,
  },
  {
    profile_section_id: 3851,
    name: "150X16",
    type: "FLAT",
    designation: "150X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 16,
  },
  {
    profile_section_id: 3852,
    name: "150X15",
    type: "FLAT",
    designation: "150X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 15,
  },
  {
    profile_section_id: 3853,
    name: "150X14",
    type: "FLAT",
    designation: "150X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 14,
  },
  {
    profile_section_id: 3854,
    name: "150X12",
    type: "FLAT",
    designation: "150X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 12,
  },
  {
    profile_section_id: 3855,
    name: "150X10",
    type: "FLAT",
    designation: "150X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 10,
  },
  {
    profile_section_id: 3856,
    name: "150X8",
    type: "FLAT",
    designation: "150X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 150,
    t_global: 8,
  },
  {
    profile_section_id: 3857,
    name: "140x60",
    type: "FLAT",
    designation: "140x60",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 60,
  },
  {
    profile_section_id: 3858,
    name: "140X50",
    type: "FLAT",
    designation: "140X50",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 50,
  },
  {
    profile_section_id: 3859,
    name: "140X45",
    type: "FLAT",
    designation: "140X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 45,
  },
  {
    profile_section_id: 3860,
    name: "140X40",
    type: "FLAT",
    designation: "140X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 40,
  },
  {
    profile_section_id: 3861,
    name: "140X35",
    type: "FLAT",
    designation: "140X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 35,
  },
  {
    profile_section_id: 3862,
    name: "140X30",
    type: "FLAT",
    designation: "140X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 30,
  },
  {
    profile_section_id: 3863,
    name: "140X25",
    type: "FLAT",
    designation: "140X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 25,
  },
  {
    profile_section_id: 3864,
    name: "140X20",
    type: "FLAT",
    designation: "140X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 20,
  },
  {
    profile_section_id: 3865,
    name: "140X18",
    type: "FLAT",
    designation: "140X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 18,
  },
  {
    profile_section_id: 3866,
    name: "140X16",
    type: "FLAT",
    designation: "140X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 16,
  },
  {
    profile_section_id: 3867,
    name: "140X15",
    type: "FLAT",
    designation: "140X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 15,
  },
  {
    profile_section_id: 3868,
    name: "140X14",
    type: "FLAT",
    designation: "140X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 14,
  },
  {
    profile_section_id: 3869,
    name: "140X12",
    type: "FLAT",
    designation: "140X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 12,
  },
  {
    profile_section_id: 3870,
    name: "140X10",
    type: "FLAT",
    designation: "140X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 10,
  },
  {
    profile_section_id: 3871,
    name: "140X8",
    type: "FLAT",
    designation: "140X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 140,
    t_global: 8,
  },
  {
    profile_section_id: 3872,
    name: "130x60",
    type: "FLAT",
    designation: "130x60",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 60,
  },
  {
    profile_section_id: 3873,
    name: "130X50",
    type: "FLAT",
    designation: "130X50",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 50,
  },
  {
    profile_section_id: 3874,
    name: "130X45",
    type: "FLAT",
    designation: "130X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 45,
  },
  {
    profile_section_id: 3875,
    name: "130X40",
    type: "FLAT",
    designation: "130X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 40,
  },
  {
    profile_section_id: 3876,
    name: "130X35",
    type: "FLAT",
    designation: "130X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 35,
  },
  {
    profile_section_id: 3877,
    name: "130X30",
    type: "FLAT",
    designation: "130X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 30,
  },
  {
    profile_section_id: 3878,
    name: "130X25",
    type: "FLAT",
    designation: "130X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 25,
  },
  {
    profile_section_id: 3879,
    name: "130X20",
    type: "FLAT",
    designation: "130X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 20,
  },
  {
    profile_section_id: 3880,
    name: "130X18",
    type: "FLAT",
    designation: "130X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 18,
  },
  {
    profile_section_id: 3881,
    name: "130X16",
    type: "FLAT",
    designation: "130X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 16,
  },
  {
    profile_section_id: 3882,
    name: "130X15",
    type: "FLAT",
    designation: "130X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 15,
  },
  {
    profile_section_id: 3883,
    name: "130X14",
    type: "FLAT",
    designation: "130X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 14,
  },
  {
    profile_section_id: 3884,
    name: "130X12",
    type: "FLAT",
    designation: "130X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 12,
  },
  {
    profile_section_id: 3885,
    name: "130X10",
    type: "FLAT",
    designation: "130X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 10,
  },
  {
    profile_section_id: 3886,
    name: "130X8",
    type: "FLAT",
    designation: "130X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 130,
    t_global: 8,
  },
  {
    profile_section_id: 3887,
    name: "120X45",
    type: "FLAT",
    designation: "120X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 45,
  },
  {
    profile_section_id: 3888,
    name: "120X40",
    type: "FLAT",
    designation: "120X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 40,
  },
  {
    profile_section_id: 3889,
    name: "120X35",
    type: "FLAT",
    designation: "120X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 35,
  },
  {
    profile_section_id: 3890,
    name: "120X30",
    type: "FLAT",
    designation: "120X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 30,
  },
  {
    profile_section_id: 3891,
    name: "120X25",
    type: "FLAT",
    designation: "120X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 25,
  },
  {
    profile_section_id: 3892,
    name: "120X20",
    type: "FLAT",
    designation: "120X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 20,
  },
  {
    profile_section_id: 3893,
    name: "120X18",
    type: "FLAT",
    designation: "120X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 18,
  },
  {
    profile_section_id: 3894,
    name: "120X16",
    type: "FLAT",
    designation: "120X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 16,
  },
  {
    profile_section_id: 3895,
    name: "120X15",
    type: "FLAT",
    designation: "120X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 15,
  },
  {
    profile_section_id: 3896,
    name: "120X14",
    type: "FLAT",
    designation: "120X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 14,
  },
  {
    profile_section_id: 3897,
    name: "120X12",
    type: "FLAT",
    designation: "120X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 12,
  },
  {
    profile_section_id: 3898,
    name: "120X10",
    type: "FLAT",
    designation: "120X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 10,
  },
  {
    profile_section_id: 3899,
    name: "120X8",
    type: "FLAT",
    designation: "120X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 8,
  },
  {
    profile_section_id: 3900,
    name: "120X7",
    type: "FLAT",
    designation: "120X7",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 7,
  },
  {
    profile_section_id: 3901,
    name: "120X6",
    type: "FLAT",
    designation: "120X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 6,
  },
  {
    profile_section_id: 3902,
    name: "120X5",
    type: "FLAT",
    designation: "120X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 120,
    t_global: 5,
  },
  {
    profile_section_id: 3903,
    name: "110X50",
    type: "FLAT",
    designation: "110X50",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 50,
  },
  {
    profile_section_id: 3904,
    name: "110X45",
    type: "FLAT",
    designation: "110X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 45,
  },
  {
    profile_section_id: 3905,
    name: "110X40",
    type: "FLAT",
    designation: "110X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 40,
  },
  {
    profile_section_id: 3906,
    name: "110X35",
    type: "FLAT",
    designation: "110X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 35,
  },
  {
    profile_section_id: 3907,
    name: "110X30",
    type: "FLAT",
    designation: "110X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 30,
  },
  {
    profile_section_id: 3908,
    name: "110X25",
    type: "FLAT",
    designation: "110X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 25,
  },
  {
    profile_section_id: 3909,
    name: "110X20",
    type: "FLAT",
    designation: "110X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 20,
  },
  {
    profile_section_id: 3910,
    name: "110X18",
    type: "FLAT",
    designation: "110X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 18,
  },
  {
    profile_section_id: 3911,
    name: "110X16",
    type: "FLAT",
    designation: "110X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 16,
  },
  {
    profile_section_id: 3912,
    name: "110X15",
    type: "FLAT",
    designation: "110X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 15,
  },
  {
    profile_section_id: 3913,
    name: "110X14",
    type: "FLAT",
    designation: "110X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 14,
  },
  {
    profile_section_id: 3914,
    name: "110X12",
    type: "FLAT",
    designation: "110X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 12,
  },
  {
    profile_section_id: 3915,
    name: "110X10",
    type: "FLAT",
    designation: "110X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 10,
  },
  {
    profile_section_id: 3916,
    name: "110X8",
    type: "FLAT",
    designation: "110X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 8,
  },
  {
    profile_section_id: 3917,
    name: "110X7",
    type: "FLAT",
    designation: "110X7",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 7,
  },
  {
    profile_section_id: 3918,
    name: "110X6",
    type: "FLAT",
    designation: "110X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 6,
  },
  {
    profile_section_id: 3919,
    name: "110X5",
    type: "FLAT",
    designation: "110X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 110,
    t_global: 5,
  },
  {
    profile_section_id: 3920,
    name: "100X50",
    type: "FLAT",
    designation: "100X50",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 50,
  },
  {
    profile_section_id: 3921,
    name: "100X45",
    type: "FLAT",
    designation: "100X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 45,
  },
  {
    profile_section_id: 3922,
    name: "100X40",
    type: "FLAT",
    designation: "100X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 40,
  },
  {
    profile_section_id: 3923,
    name: "100X35",
    type: "FLAT",
    designation: "100X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 35,
  },
  {
    profile_section_id: 3924,
    name: "100X30",
    type: "FLAT",
    designation: "100X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 30,
  },
  {
    profile_section_id: 3925,
    name: "100X25",
    type: "FLAT",
    designation: "100X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 25,
  },
  {
    profile_section_id: 3926,
    name: "100X20",
    type: "FLAT",
    designation: "100X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 20,
  },
  {
    profile_section_id: 3927,
    name: "100X18",
    type: "FLAT",
    designation: "100X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 18,
  },
  {
    profile_section_id: 3928,
    name: "100X16",
    type: "FLAT",
    designation: "100X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 16,
  },
  {
    profile_section_id: 3929,
    name: "100X15",
    type: "FLAT",
    designation: "100X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 15,
  },
  {
    profile_section_id: 3930,
    name: "100X14",
    type: "FLAT",
    designation: "100X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 14,
  },
  {
    profile_section_id: 3931,
    name: "100X12",
    type: "FLAT",
    designation: "100X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 12,
  },
  {
    profile_section_id: 3932,
    name: "100X10",
    type: "FLAT",
    designation: "100X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 10,
  },
  {
    profile_section_id: 3933,
    name: "100X8",
    type: "FLAT",
    designation: "100X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 8,
  },
  {
    profile_section_id: 3934,
    name: "100X7",
    type: "FLAT",
    designation: "100X7",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 7,
  },
  {
    profile_section_id: 3935,
    name: "100X6",
    type: "FLAT",
    designation: "100X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 6,
  },
  {
    profile_section_id: 3936,
    name: "100X5",
    type: "FLAT",
    designation: "100X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 100,
    t_global: 5,
  },
  {
    profile_section_id: 3937,
    name: "90X50",
    type: "FLAT",
    designation: "90X50",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 50,
  },
  {
    profile_section_id: 3938,
    name: "90X45",
    type: "FLAT",
    designation: "90X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 45,
  },
  {
    profile_section_id: 3939,
    name: "90X40",
    type: "FLAT",
    designation: "90X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 40,
  },
  {
    profile_section_id: 3940,
    name: "90X35",
    type: "FLAT",
    designation: "90X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 35,
  },
  {
    profile_section_id: 3941,
    name: "90X30",
    type: "FLAT",
    designation: "90X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 30,
  },
  {
    profile_section_id: 3942,
    name: "90X25",
    type: "FLAT",
    designation: "90X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 25,
  },
  {
    profile_section_id: 3943,
    name: "90X20",
    type: "FLAT",
    designation: "90X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 20,
  },
  {
    profile_section_id: 3944,
    name: "90X18",
    type: "FLAT",
    designation: "90X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 18,
  },
  {
    profile_section_id: 3945,
    name: "90X16",
    type: "FLAT",
    designation: "90X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 16,
  },
  {
    profile_section_id: 3946,
    name: "90X15",
    type: "FLAT",
    designation: "90X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 15,
  },
  {
    profile_section_id: 3947,
    name: "90X14",
    type: "FLAT",
    designation: "90X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 14,
  },
  {
    profile_section_id: 3948,
    name: "90X12",
    type: "FLAT",
    designation: "90X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 12,
  },
  {
    profile_section_id: 3949,
    name: "90X10",
    type: "FLAT",
    designation: "90X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 10,
  },
  {
    profile_section_id: 3950,
    name: "90X8",
    type: "FLAT",
    designation: "90X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 8,
  },
  {
    profile_section_id: 3951,
    name: "90X7",
    type: "FLAT",
    designation: "90X7",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 7,
  },
  {
    profile_section_id: 3952,
    name: "90X6",
    type: "FLAT",
    designation: "90X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 6,
  },
  {
    profile_section_id: 3953,
    name: "90X5",
    type: "FLAT",
    designation: "90X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 90,
    t_global: 5,
  },
  {
    profile_section_id: 3954,
    name: "80X50",
    type: "FLAT",
    designation: "80X50",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 50,
  },
  {
    profile_section_id: 3955,
    name: "80X45",
    type: "FLAT",
    designation: "80X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 45,
  },
  {
    profile_section_id: 3956,
    name: "80X40",
    type: "FLAT",
    designation: "80X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 40,
  },
  {
    profile_section_id: 3957,
    name: "80X35",
    type: "FLAT",
    designation: "80X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 35,
  },
  {
    profile_section_id: 3958,
    name: "80X30",
    type: "FLAT",
    designation: "80X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 30,
  },
  {
    profile_section_id: 3959,
    name: "80X25",
    type: "FLAT",
    designation: "80X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 25,
  },
  {
    profile_section_id: 3960,
    name: "80X20",
    type: "FLAT",
    designation: "80X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 20,
  },
  {
    profile_section_id: 3961,
    name: "80X18",
    type: "FLAT",
    designation: "80X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 18,
  },
  {
    profile_section_id: 3962,
    name: "80X16",
    type: "FLAT",
    designation: "80X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 16,
  },
  {
    profile_section_id: 3963,
    name: "80X15",
    type: "FLAT",
    designation: "80X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 15,
  },
  {
    profile_section_id: 3964,
    name: "80X14",
    type: "FLAT",
    designation: "80X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 14,
  },
  {
    profile_section_id: 3965,
    name: "80X12",
    type: "FLAT",
    designation: "80X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 12,
  },
  {
    profile_section_id: 3966,
    name: "80X10",
    type: "FLAT",
    designation: "80X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 10,
  },
  {
    profile_section_id: 3967,
    name: "80X8",
    type: "FLAT",
    designation: "80X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 8,
  },
  {
    profile_section_id: 3968,
    name: "80X7",
    type: "FLAT",
    designation: "80X7",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 7,
  },
  {
    profile_section_id: 3969,
    name: "80X6",
    type: "FLAT",
    designation: "80X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 6,
  },
  {
    profile_section_id: 3970,
    name: "80X5",
    type: "FLAT",
    designation: "80X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 80,
    t_global: 5,
  },
  {
    profile_section_id: 3971,
    name: "75X50",
    type: "FLAT",
    designation: "75X50",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 50,
  },
  {
    profile_section_id: 3972,
    name: "75X45",
    type: "FLAT",
    designation: "75X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 45,
  },
  {
    profile_section_id: 3973,
    name: "75X40",
    type: "FLAT",
    designation: "75X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 40,
  },
  {
    profile_section_id: 3974,
    name: "75X35",
    type: "FLAT",
    designation: "75X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 35,
  },
  {
    profile_section_id: 3975,
    name: "75X30",
    type: "FLAT",
    designation: "75X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 30,
  },
  {
    profile_section_id: 3976,
    name: "75X25",
    type: "FLAT",
    designation: "75X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 25,
  },
  {
    profile_section_id: 3977,
    name: "75X20",
    type: "FLAT",
    designation: "75X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 20,
  },
  {
    profile_section_id: 3978,
    name: "75X18",
    type: "FLAT",
    designation: "75X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 18,
  },
  {
    profile_section_id: 3979,
    name: "75X16",
    type: "FLAT",
    designation: "75X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 16,
  },
  {
    profile_section_id: 3980,
    name: "75X15",
    type: "FLAT",
    designation: "75X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 15,
  },
  {
    profile_section_id: 3981,
    name: "75X14",
    type: "FLAT",
    designation: "75X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 14,
  },
  {
    profile_section_id: 3982,
    name: "75X12",
    type: "FLAT",
    designation: "75X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 12,
  },
  {
    profile_section_id: 3983,
    name: "75X10",
    type: "FLAT",
    designation: "75X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 10,
  },
  {
    profile_section_id: 3984,
    name: "75X8",
    type: "FLAT",
    designation: "75X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 8,
  },
  {
    profile_section_id: 3985,
    name: "75X7",
    type: "FLAT",
    designation: "75X7",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 7,
  },
  {
    profile_section_id: 3986,
    name: "75X6",
    type: "FLAT",
    designation: "75X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 6,
  },
  {
    profile_section_id: 3987,
    name: "75X5",
    type: "FLAT",
    designation: "75X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 75,
    t_global: 5,
  },
  {
    profile_section_id: 3988,
    name: "70X50",
    type: "FLAT",
    designation: "70X50",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 50,
  },
  {
    profile_section_id: 3989,
    name: "70X45",
    type: "FLAT",
    designation: "70X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 45,
  },
  {
    profile_section_id: 3990,
    name: "70X40",
    type: "FLAT",
    designation: "70X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 40,
  },
  {
    profile_section_id: 3991,
    name: "70X35",
    type: "FLAT",
    designation: "70X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 35,
  },
  {
    profile_section_id: 3992,
    name: "70X30",
    type: "FLAT",
    designation: "70X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 30,
  },
  {
    profile_section_id: 3993,
    name: "70X25",
    type: "FLAT",
    designation: "70X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 25,
  },
  {
    profile_section_id: 3994,
    name: "70X20",
    type: "FLAT",
    designation: "70X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 20,
  },
  {
    profile_section_id: 3995,
    name: "70X18",
    type: "FLAT",
    designation: "70X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 18,
  },
  {
    profile_section_id: 3996,
    name: "70X16",
    type: "FLAT",
    designation: "70X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 16,
  },
  {
    profile_section_id: 3997,
    name: "70X15",
    type: "FLAT",
    designation: "70X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 15,
  },
  {
    profile_section_id: 3998,
    name: "70X14",
    type: "FLAT",
    designation: "70X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 14,
  },
  {
    profile_section_id: 3999,
    name: "70X12",
    type: "FLAT",
    designation: "70X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 12,
  },
  {
    profile_section_id: 4000,
    name: "70X10",
    type: "FLAT",
    designation: "70X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 10,
  },
  {
    profile_section_id: 4001,
    name: "70X8",
    type: "FLAT",
    designation: "70X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 8,
  },
  {
    profile_section_id: 4002,
    name: "70X7",
    type: "FLAT",
    designation: "70X7",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 7,
  },
  {
    profile_section_id: 4003,
    name: "70X6",
    type: "FLAT",
    designation: "70X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 6,
  },
  {
    profile_section_id: 4004,
    name: "70X5",
    type: "FLAT",
    designation: "70X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 70,
    t_global: 5,
  },
  {
    profile_section_id: 4005,
    name: "65X65",
    type: "FLAT",
    designation: "65X65",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 50,
  },
  {
    profile_section_id: 4006,
    name: "65X45",
    type: "FLAT",
    designation: "65X45",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 45,
  },
  {
    profile_section_id: 4007,
    name: "65X40",
    type: "FLAT",
    designation: "65X40",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 40,
  },
  {
    profile_section_id: 4008,
    name: "65X35",
    type: "FLAT",
    designation: "65X35",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 35,
  },
  {
    profile_section_id: 4009,
    name: "65X30",
    type: "FLAT",
    designation: "65X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 30,
  },
  {
    profile_section_id: 4010,
    name: "65X25",
    type: "FLAT",
    designation: "65X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 25,
  },
  {
    profile_section_id: 4011,
    name: "65X20",
    type: "FLAT",
    designation: "65X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 20,
  },
  {
    profile_section_id: 4012,
    name: "65X18",
    type: "FLAT",
    designation: "65X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 18,
  },
  {
    profile_section_id: 4013,
    name: "65X16",
    type: "FLAT",
    designation: "65X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 16,
  },
  {
    profile_section_id: 4014,
    name: "65X15",
    type: "FLAT",
    designation: "65X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 15,
  },
  {
    profile_section_id: 4015,
    name: "65X14",
    type: "FLAT",
    designation: "65X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 14,
  },
  {
    profile_section_id: 4016,
    name: "65X12",
    type: "FLAT",
    designation: "65X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 12,
  },
  {
    profile_section_id: 4017,
    name: "65X10",
    type: "FLAT",
    designation: "65X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 10,
  },
  {
    profile_section_id: 4018,
    name: "65X8",
    type: "FLAT",
    designation: "65X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 8,
  },
  {
    profile_section_id: 4019,
    name: "65X7",
    type: "FLAT",
    designation: "65X7",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 7,
  },
  {
    profile_section_id: 4020,
    name: "65X6",
    type: "FLAT",
    designation: "65X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 6,
  },
  {
    profile_section_id: 4021,
    name: "65X5",
    type: "FLAT",
    designation: "65X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 65,
    t_global: 5,
  },
  {
    profile_section_id: 4022,
    name: "60X30",
    type: "FLAT",
    designation: "60X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 30,
  },
  {
    profile_section_id: 4023,
    name: "60X25",
    type: "FLAT",
    designation: "60X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 25,
  },
  {
    profile_section_id: 4024,
    name: "60X20",
    type: "FLAT",
    designation: "60X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 20,
  },
  {
    profile_section_id: 4025,
    name: "60X18",
    type: "FLAT",
    designation: "60X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 18,
  },
  {
    profile_section_id: 4026,
    name: "60X16",
    type: "FLAT",
    designation: "60X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 16,
  },
  {
    profile_section_id: 4027,
    name: "60X15",
    type: "FLAT",
    designation: "60X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 15,
  },
  {
    profile_section_id: 4028,
    name: "60X14",
    type: "FLAT",
    designation: "60X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 14,
  },
  {
    profile_section_id: 4029,
    name: "60X12",
    type: "FLAT",
    designation: "60X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 12,
  },
  {
    profile_section_id: 4030,
    name: "60X10",
    type: "FLAT",
    designation: "60X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 10,
  },
  {
    profile_section_id: 4031,
    name: "60X8",
    type: "FLAT",
    designation: "60X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 8,
  },
  {
    profile_section_id: 4032,
    name: "60X6",
    type: "FLAT",
    designation: "60X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 6,
  },
  {
    profile_section_id: 4033,
    name: "60X5",
    type: "FLAT",
    designation: "60X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 5,
  },
  {
    profile_section_id: 4034,
    name: "60X4",
    type: "FLAT",
    designation: "60X4",
    shape: "FLAT",
    country_code: "European",
    b_global: 60,
    t_global: 4,
  },
  {
    profile_section_id: 4035,
    name: "55X30",
    type: "FLAT",
    designation: "55X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 30,
  },
  {
    profile_section_id: 4036,
    name: "55X25",
    type: "FLAT",
    designation: "55X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 25,
  },
  {
    profile_section_id: 4037,
    name: "55X20",
    type: "FLAT",
    designation: "55X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 20,
  },
  {
    profile_section_id: 4038,
    name: "55X18",
    type: "FLAT",
    designation: "55X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 18,
  },
  {
    profile_section_id: 4039,
    name: "55X16",
    type: "FLAT",
    designation: "55X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 16,
  },
  {
    profile_section_id: 4040,
    name: "55X15",
    type: "FLAT",
    designation: "55X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 15,
  },
  {
    profile_section_id: 4041,
    name: "55X14",
    type: "FLAT",
    designation: "55X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 14,
  },
  {
    profile_section_id: 4042,
    name: "55X12",
    type: "FLAT",
    designation: "55X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 12,
  },
  {
    profile_section_id: 4043,
    name: "55X10",
    type: "FLAT",
    designation: "55X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 10,
  },
  {
    profile_section_id: 4044,
    name: "55X8",
    type: "FLAT",
    designation: "55X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 8,
  },
  {
    profile_section_id: 4045,
    name: "55X6",
    type: "FLAT",
    designation: "55X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 6,
  },
  {
    profile_section_id: 4046,
    name: "55X5",
    type: "FLAT",
    designation: "55X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 5,
  },
  {
    profile_section_id: 4047,
    name: "55X4",
    type: "FLAT",
    designation: "55X4",
    shape: "FLAT",
    country_code: "European",
    b_global: 55,
    t_global: 4,
  },
  {
    profile_section_id: 4048,
    name: "50X30",
    type: "FLAT",
    designation: "50X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 50,
    t_global: 30,
  },
  {
    profile_section_id: 4049,
    name: "50X25",
    type: "FLAT",
    designation: "50X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 50,
    t_global: 25,
  },
  {
    profile_section_id: 4050,
    name: "50X20",
    type: "FLAT",
    designation: "50X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 50,
    t_global: 20,
  },
  {
    profile_section_id: 4051,
    name: "50X18",
    type: "FLAT",
    designation: "50X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 50,
    t_global: 18,
  },
  {
    profile_section_id: 4052,
    name: "50X16",
    type: "FLAT",
    designation: "50X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 50,
    t_global: 16,
  },
  {
    profile_section_id: 4053,
    name: "50X15",
    type: "FLAT",
    designation: "50X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 50,
    t_global: 15,
  },
  {
    profile_section_id: 4054,
    name: "50X14",
    type: "FLAT",
    designation: "50X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 50,
    t_global: 14,
  },
  {
    profile_section_id: 4055,
    name: "50X12",
    type: "FLAT",
    designation: "50X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 50,
    t_global: 12,
  },
  {
    profile_section_id: 4056,
    name: "50X10",
    type: "FLAT",
    designation: "50X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 50,
    t_global: 10,
  },
  {
    profile_section_id: 4057,
    name: "50X8",
    type: "FLAT",
    designation: "50X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 50,
    t_global: 8,
  },
  {
    profile_section_id: 4058,
    name: "50X6",
    type: "FLAT",
    designation: "50X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 50,
    t_global: 6,
  },
  {
    profile_section_id: 4059,
    name: "50x 5",
    type: "FlatBar",
    designation: "50x5",
    shape: "FLAT",
    country_code: "European",
    ax: "2,5",
    ax_global: 250,
    b: 50,
    b_global: 50,
    t: 5,
    t_global: 5,
  },
  {
    profile_section_id: 4060,
    name: "50X4",
    type: "FLAT",
    designation: "50X4",
    shape: "FLAT",
    country_code: "European",
    b_global: 50,
    t_global: 4,
  },
  {
    profile_section_id: 4061,
    name: "45X30",
    type: "FLAT",
    designation: "45X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 30,
  },
  {
    profile_section_id: 4062,
    name: "45X25",
    type: "FLAT",
    designation: "45X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 25,
  },
  {
    profile_section_id: 4063,
    name: "45X20",
    type: "FLAT",
    designation: "45X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 20,
  },
  {
    profile_section_id: 4064,
    name: "45X18",
    type: "FLAT",
    designation: "45X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 18,
  },
  {
    profile_section_id: 4065,
    name: "45X16",
    type: "FLAT",
    designation: "45X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 16,
  },
  {
    profile_section_id: 4066,
    name: "45X15",
    type: "FLAT",
    designation: "45X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 15,
  },
  {
    profile_section_id: 4067,
    name: "45X14",
    type: "FLAT",
    designation: "45X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 14,
  },
  {
    profile_section_id: 4068,
    name: "45X12",
    type: "FLAT",
    designation: "45X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 12,
  },
  {
    profile_section_id: 4069,
    name: "45X10",
    type: "FLAT",
    designation: "45X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 10,
  },
  {
    profile_section_id: 4070,
    name: "45X8",
    type: "FLAT",
    designation: "45X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 8,
  },
  {
    profile_section_id: 4071,
    name: "45X6",
    type: "FLAT",
    designation: "45X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 6,
  },
  {
    profile_section_id: 4072,
    name: "45X5",
    type: "FLAT",
    designation: "45X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 5,
  },
  {
    profile_section_id: 4073,
    name: "45X4",
    type: "FLAT",
    designation: "45X4",
    shape: "FLAT",
    country_code: "European",
    b_global: 45,
    t_global: 4,
  },
  {
    profile_section_id: 4074,
    name: "40X30",
    type: "FLAT",
    designation: "40X30",
    shape: "FLAT",
    country_code: "European",
    b_global: 40,
    t_global: 30,
  },
  {
    profile_section_id: 4075,
    name: "40X25",
    type: "FLAT",
    designation: "40X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 40,
    t_global: 25,
  },
  {
    profile_section_id: 4076,
    name: "40X20",
    type: "FLAT",
    designation: "40X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 40,
    t_global: 20,
  },
  {
    profile_section_id: 4077,
    name: "40X18",
    type: "FLAT",
    designation: "40X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 40,
    t_global: 18,
  },
  {
    profile_section_id: 4078,
    name: "40X16",
    type: "FLAT",
    designation: "40X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 40,
    t_global: 16,
  },
  {
    profile_section_id: 4079,
    name: "40X15",
    type: "FlatBar",
    designation: "40X15",
    shape: "FLAT",
    country_code: "European",
    ax: 6,
    ax_global: 600,
    b: 40,
    b_global: 40,
    t: 15,
    t_global: 15,
  },
  {
    profile_section_id: 4080,
    name: "40X14",
    type: "FlatBar",
    designation: "40X14",
    shape: "FLAT",
    country_code: "European",
    ax: "5,6",
    ax_global: 560,
    b: 40,
    b_global: 40,
    t: 14,
    t_global: 14,
  },
  {
    profile_section_id: 4081,
    name: "40X12",
    type: "FlatBar",
    designation: "40X12",
    shape: "FLAT",
    country_code: "European",
    ax: "4,8",
    ax_global: 480,
    b: 40,
    b_global: 40,
    t: 12,
    t_global: 12,
  },
  {
    profile_section_id: 4082,
    name: "40X10",
    type: "FlatBar",
    designation: "40X10",
    shape: "FLAT",
    country_code: "European",
    ax: 4,
    ax_global: 400,
    b: 40,
    b_global: 40,
    t: 10,
    t_global: 10,
  },
  {
    profile_section_id: 4083,
    name: "40X8",
    type: "FlatBar",
    designation: "40X8",
    shape: "FLAT",
    country_code: "European",
    ax: "3,2",
    ax_global: 320,
    b: 40,
    b_global: 40,
    t: 8,
    t_global: 8,
  },
  {
    profile_section_id: 4084,
    name: "40X7",
    type: "FlatBar",
    designation: "40X7",
    shape: "FLAT",
    country_code: "European",
    ax: "2,8",
    ax_global: 280,
    b: 40,
    b_global: 40,
    t: 7,
    t_global: 7,
  },
  {
    profile_section_id: 4085,
    name: "40X6",
    type: "FlatBar",
    designation: "40X6",
    shape: "FLAT",
    country_code: "European",
    ax: "2,4",
    ax_global: 240,
    b: 40,
    b_global: 40,
    t: 6,
    t_global: 6,
  },
  {
    profile_section_id: 4086,
    name: "40X5",
    type: "FlatBar",
    designation: "40X5",
    shape: "FLAT",
    country_code: "European",
    ax: 2,
    ax_global: 200,
    b: 40,
    b_global: 40,
    t: 5,
    t_global: 5,
  },
  {
    profile_section_id: 4087,
    name: "40X4",
    type: "FLAT",
    designation: "40X4",
    shape: "FLAT",
    country_code: "European",
    b_global: 40,
    t_global: 4,
  },
  {
    profile_section_id: 4088,
    name: "35X25",
    type: "FLAT",
    designation: "35X25",
    shape: "FLAT",
    country_code: "European",
    b_global: 35,
    t_global: 25,
  },
  {
    profile_section_id: 4089,
    name: "35X20",
    type: "FLAT",
    designation: "35X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 35,
    t_global: 20,
  },
  {
    profile_section_id: 4090,
    name: "35X18",
    type: "FLAT",
    designation: "35X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 35,
    t_global: 18,
  },
  {
    profile_section_id: 4091,
    name: "35X16",
    type: "FLAT",
    designation: "35X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 35,
    t_global: 16,
  },
  {
    profile_section_id: 4092,
    name: "35X15",
    type: "FLAT",
    designation: "35X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 35,
    t_global: 15,
  },
  {
    profile_section_id: 4093,
    name: "35X14",
    type: "FLAT",
    designation: "35X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 35,
    t_global: 14,
  },
  {
    profile_section_id: 4094,
    name: "35X12",
    type: "FLAT",
    designation: "35X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 35,
    t_global: 12,
  },
  {
    profile_section_id: 4095,
    name: "35X10",
    type: "FLAT",
    designation: "35X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 35,
    t_global: 10,
  },
  {
    profile_section_id: 4096,
    name: "35X8",
    type: "FLAT",
    designation: "35X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 35,
    t_global: 8,
  },
  {
    profile_section_id: 4097,
    name: "35X6",
    type: "FLAT",
    designation: "35X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 35,
    t_global: 6,
  },
  {
    profile_section_id: 4098,
    name: "35X5",
    type: "FLAT",
    designation: "35X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 35,
    t_global: 5,
  },
  {
    profile_section_id: 4099,
    name: "35X4",
    type: "FLAT",
    designation: "35X4",
    shape: "FLAT",
    country_code: "European",
    b_global: 35,
    t_global: 4,
  },
  {
    profile_section_id: 4100,
    name: "30X20",
    type: "FLAT",
    designation: "30X20",
    shape: "FLAT",
    country_code: "European",
    b_global: 30,
    t_global: 20,
  },
  {
    profile_section_id: 4101,
    name: "30X18",
    type: "FLAT",
    designation: "30X18",
    shape: "FLAT",
    country_code: "European",
    b_global: 30,
    t_global: 18,
  },
  {
    profile_section_id: 4102,
    name: "30X16",
    type: "FLAT",
    designation: "30X16",
    shape: "FLAT",
    country_code: "European",
    b_global: 30,
    t_global: 16,
  },
  {
    profile_section_id: 4103,
    name: "30X15",
    type: "FLAT",
    designation: "30X15",
    shape: "FLAT",
    country_code: "European",
    b_global: 30,
    t_global: 15,
  },
  {
    profile_section_id: 4104,
    name: "30X14",
    type: "FLAT",
    designation: "30X14",
    shape: "FLAT",
    country_code: "European",
    b_global: 30,
    t_global: 14,
  },
  {
    profile_section_id: 4105,
    name: "30X12",
    type: "FLAT",
    designation: "30X12",
    shape: "FLAT",
    country_code: "European",
    b_global: 30,
    t_global: 12,
  },
  {
    profile_section_id: 4106,
    name: "30X10",
    type: "FLAT",
    designation: "30X10",
    shape: "FLAT",
    country_code: "European",
    b_global: 30,
    t_global: 10,
  },
  {
    profile_section_id: 4107,
    name: "30X8",
    type: "FLAT",
    designation: "30X8",
    shape: "FLAT",
    country_code: "European",
    b_global: 30,
    t_global: 8,
  },
  {
    profile_section_id: 4108,
    name: "30X6",
    type: "FLAT",
    designation: "30X6",
    shape: "FLAT",
    country_code: "European",
    b_global: 30,
    t_global: 6,
  },
  {
    profile_section_id: 4109,
    name: "30X5",
    type: "FLAT",
    designation: "30X5",
    shape: "FLAT",
    country_code: "European",
    b_global: 30,
    t_global: 5,
  },
  {
    profile_section_id: 4110,
    name: "30X4",
    type: "FLAT",
    designation: "30X4",
    shape: "FLAT",
    country_code: "European",
    b_global: 30,
    t_global: 4,
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: p.ax ? fixValueToNumber(p.ax, "float") : null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: p.b ? fixValueToNumber(p.b, "float") : null,
    b_global: p.b_global ? fixValueToNumber(p.b_global, "float") : undefined,
    bf: p.bf ? fixValueToNumber(p.bf, "float") : null,
    bf_global: p.bf_global ? fixValueToNumber(p.bf_global, "float") : undefined,
    c: p.c ? fixValueToNumber(p.c, "float") : null,
    c_global: p.c_global ? fixValueToNumber(p.c_global, "float") : undefined,
    ct: p.ct ? fixValueToNumber(p.ct, "float") : null,
    ct_global: p.ct_global ? fixValueToNumber(p.ct_global, "float") : undefined,
    d: p.d ? fixValueToNumber(p.d, "float") : null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: null,
    i: null,
    ix: p.ix ? fixValueToNumber(p.ix, "float") : null,
    ix_global: p.ix_global ? fixValueToNumber(p.ix_global, "float") : undefined,
    iy: p.iy ? fixValueToNumber(p.iy, "float") : null,
    iy_global: p.iy_global ? fixValueToNumber(p.iy_global, "float") : undefined,
    iz: p.iz ? fixValueToNumber(p.iz, "float") : null,
    iz_global: p.iz_global ? fixValueToNumber(p.iz_global, "float") : undefined,
    k: null,
    k1: null,
    od: null,
    r1: p.r1 ? fixValueToNumber(p.r1, "float") : null,
    r1_global: p.r1_global ? fixValueToNumber(p.r1_global, "float") : undefined,
    r2: null,
    rz: null,
    t: p.t ? fixValueToNumber(p.t, "float") : null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: p.tf ? fixValueToNumber(p.tf, "float") : null,
    tf_global: p.tf_global ? fixValueToNumber(p.tf_global, "float") : undefined,
    tw: p.tw ? fixValueToNumber(p.tw, "float") : null,
    tw_global: p.tw_global ? fixValueToNumber(p.tw_global, "float") : undefined,
    z: null,
    zx: p.zx ? fixValueToNumber(p.zx, "float") : null,
    zx_global: p.zx_global ? fixValueToNumber(p.zx_global, "float") : undefined,
    zy: p.zy ? fixValueToNumber(p.zy, "float") : null,
    zy_global: p.zy_global ? fixValueToNumber(p.zy_global, "float") : undefined,
    zz: null,
    width: fixValueToNumber(p.width, "float"),
    width_global: fixValueToNumber(p.width_global, "float"),
    thickness: fixValueToNumber(p.thickness, "float"),
    thickness_global: p.thickness_global
      ? fixValueToNumber(p.thickness_global, "float")
      : undefined,
    height: fixValueToNumber(p.height, "float"),
    height_global: fixValueToNumber(p.height_global, "float"),
  };
  return profile;
});
