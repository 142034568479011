import React, { useEffect, useState } from "react";
import { Button, FormGroup, InputGroup, Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import Logo from "../../assets/A-Logo-Blue-Transparent.png";
import "./Login.css";

type Props = {
  inProgress?: boolean;
  onUnload: () => any;
  onSubmit: (email: string, password: string) => any;
  loginError: string;
  setLoginError: (error: string) => void;
  onGoogleSignupSuccess: (response: any) => void; // For Google sign-up success
  onGoogleSigninSuccess: (response: any) => void; // For Google sign-in success
  onGoogleLoginFailure: (error: any) => void; // For Google login failure
};

export function Login({
  onUnload,
  onSubmit,
  inProgress,
  loginError,
  setLoginError,
  onGoogleSignupSuccess,
  onGoogleSigninSuccess,
  onGoogleLoginFailure,
}: Props) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    return () => onUnload();
  }, []);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onSubmit(email, password);
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string>>
  ) => {
    if (loginError) {
      setLoginError("");
    }
    setter(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="auth-page">
      <div className="primary-container">
        <div className="container-1">
          <h1>welcome</h1>
          <h2><i>Convert, Manage, and Optimize !</i></h2>
        </div>
        <div className="container-2">
          <div className="logo-container-2">
            <img className="primary-logo" src={Logo} alt="Logo" />
            <h1>
              Integrated
              <br />
              Design
              <br />
              Suite
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="login-text-group">email</div>
            <FormGroup>
              <InputGroup
                large
                type="email"
                value={email}
                className="login-input-group"
                autoFocus={true}
                onChange={(e) => handleInputChange(e, setEmail)}
              />
            </FormGroup>
            <div className="login-text-group">password</div>
            <FormGroup style={{ position: "relative" }}>
              <InputGroup
                large
                type={showPassword ? "text" : "password"}
                className="login-input-group"
                value={password}
                onChange={(e) => handleInputChange(e, setPassword)}
              />
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  borderRadius: "20px",
                  cursor: "pointer",
                  transform: "translateY(-50%)",
                }}
                onClick={togglePasswordVisibility}
              >
                <Icon icon={showPassword ? IconNames.EYE_OFF : IconNames.EYE_OPEN} />
              </span>
            </FormGroup>
            <a href="/forgot" style={{fontSize:"1.2rem"}}>forgot your password?</a>
            {loginError && (
              <div className="error-message" style={{ color: "red" }}>
                {loginError}
              </div>
            )}
            <div className="login-buttons">
              <FormGroup className="login-btn-group">
                <Button
                  large
                  type="submit"
                  className="form-group-button"
                  loading={inProgress}
                >
                  Login
                </Button>
              </FormGroup>
              <div className="google-signin-container">
                <GoogleOAuthProvider clientId="95616334971-0kjtsdnvdbegu2849ii5tckeo8ih3fds.apps.googleusercontent.com">
                  <GoogleLogin
                    scope="profile email https://www.googleapis.com/auth/user.birthday.read"
                    onSuccess={onGoogleSigninSuccess} 
                    onError={onGoogleLoginFailure}
                    text="signin_with" 
                    theme="filled_black"
                  />
                </GoogleOAuthProvider>
              </div>
            </div>
          </form>
          <div className="login-text-group-2">
            don&apos;t have an account?
            <a
              href="/register"
              target="_blank"
              className="anchor"
              rel="noopener noreferrer"
            >
              {" "}
              create one
            </a>
          </div>
          <div className="google-signup-container">
            <GoogleOAuthProvider clientId="95616334971-0kjtsdnvdbegu2849ii5tckeo8ih3fds.apps.googleusercontent.com">
              <GoogleLogin
                scope="profile email https://www.googleapis.com/auth/user.birthday.read"
                onSuccess={onGoogleSignupSuccess} 
                onError={onGoogleLoginFailure}
                text="signup_with" 
                theme="outline"
              />
            </GoogleOAuthProvider>
          </div>
          <div className="login-text-group-2" style={{paddingTop:"10px"}}>
            have an account?
            <a
              href="/buy"
              target="_blank"
              className="anchor"
              rel="noopener noreferrer"
            >
              {" "}
              Buy Credits
            </a>
          </div>
          <div className="login-text-group-2">
            read privacy
            <a
              href="/privacypolicy"
              target="_blank"
              className="anchor"
              rel="noopener noreferrer"
            >
              {" "}
              policy
            </a>
          </div>
        </div>
      </div>
    </div>

  );
}
