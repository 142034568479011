import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesIndian = [
  {
    profile_section_id: 329,
    name: "IW350300X010",
    type: "W Shape",
    designation: "IW350300X010",
    shape: "I",
    country_code: "Indian",
    ax: 143,
    ax_global: 14300,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 368,
    d_global: 368,
    ix: 52,
    ix_global: 520000,
    iy: 8841,
    iy_global: 88410000,
    iz: 34942,
    iz_global: 349420000,
    t: 11,
    t_global: 11,
    tf: 8,
    tf_global: 8,
    z: 1958,
    z_global: 1958000,
    zx: 725,
    zx_global: 725000,
    width: 200,
    width_global: 200,
    thickness: 8,
    thickness_global: 8,
    height: 368,
    height_global: 368,
  },
  {
    profile_section_id: 330,
    name: "IW350300X012",
    type: "W Shape",
    designation: "IW350300X012",
    shape: "I",
    country_code: "Indian",
    ax: 148,
    ax_global: 14800,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 370,
    d_global: 370,
    ix: 58,
    ix_global: 580000,
    iy: 9101,
    iy_global: 91010000,
    iz: 36977,
    iz_global: 369770000,
    t: 11,
    t_global: 11,
    tf: 8,
    tf_global: 8,
    z: 2056,
    z_global: 2056000,
    zx: 725,
    zx_global: 725000,
    width: 200,
    width_global: 200,
    thickness: 8,
    thickness_global: 8,
    height: 370,
    height_global: 370,
  },
  {
    profile_section_id: 331,
    name: "IW400300X010",
    type: "W Shape",
    designation: "IW400300X010",
    shape: "I",
    country_code: "Indian",
    ax: 156,
    ax_global: 15600,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 418,
    d_global: 418,
    ix: 64,
    ix_global: 640000,
    iy: 9053,
    iy_global: 90530000,
    iz: 49010,
    iz_global: 490100000,
    t: 13,
    t_global: 13,
    tf: 9,
    tf_global: 9,
    z: 2381,
    z_global: 2381000,
    zx: 759,
    zx_global: 759000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 418,
    height_global: 418,
  },
  {
    profile_section_id: 332,
    name: "IW350300X016",
    type: "W Shape",
    designation: "IW350300X016",
    shape: "I",
    country_code: "Indian",
    ax: 158,
    ax_global: 15800,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 374,
    d_global: 374,
    ix: 78,
    ix_global: 780000,
    iy: 9622,
    iy_global: 96220000,
    iz: 40775,
    iz_global: 407750000,
    t: 11,
    t_global: 11,
    tf: 8,
    tf_global: 8,
    z: 2228,
    z_global: 2228000,
    zx: 725,
    zx_global: 725000,
    width: 200,
    width_global: 200,
    thickness: 8,
    thickness_global: 8,
    height: 374,
    height_global: 374,
  },
  {
    profile_section_id: 333,
    name: "IW350350X010",
    type: "W Shape",
    designation: "IW350350X010",
    shape: "I",
    country_code: "Indian",
    ax: 158,
    ax_global: 15800,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 368,
    d_global: 368,
    ix: 57,
    ix_global: 570000,
    iy: 13915,
    iy_global: 139150000,
    iz: 39357,
    iz_global: 393570000,
    t: 11,
    t_global: 11,
    tf: 8,
    tf_global: 8,
    z: 2205,
    z_global: 2205000,
    zx: 899,
    zx_global: 899000,
    width: 200,
    width_global: 200,
    thickness: 8,
    thickness_global: 8,
    height: 368,
    height_global: 368,
  },
  {
    profile_section_id: 334,
    name: "IW400300X012",
    type: "W Shape",
    designation: "IW400300X012",
    shape: "I",
    country_code: "Indian",
    ax: 161,
    ax_global: 16100,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 420,
    d_global: 420,
    ix: 70,
    ix_global: 700000,
    iy: 9313,
    iy_global: 93130000,
    iz: 51618,
    iz_global: 516180000,
    t: 13,
    t_global: 13,
    tf: 9,
    tf_global: 9,
    z: 2485,
    z_global: 2485000,
    zx: 759,
    zx_global: 759000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 420,
    height_global: 420,
  },
  {
    profile_section_id: 335,
    name: "IW350350X012",
    type: "W Shape",
    designation: "IW350350X012",
    shape: "I",
    country_code: "Indian",
    ax: 165,
    ax_global: 16500,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 370,
    d_global: 370,
    ix: 65,
    ix_global: 650000,
    iy: 14461,
    iy_global: 144610000,
    iz: 41903,
    iz_global: 419030000,
    t: 11,
    t_global: 11,
    tf: 8,
    tf_global: 8,
    z: 2326,
    z_global: 2326000,
    zx: 899,
    zx_global: 899000,
    width: 200,
    width_global: 200,
    thickness: 8,
    thickness_global: 8,
    height: 370,
    height_global: 370,
  },
  {
    profile_section_id: 336,
    name: "IW400300X016",
    type: "W Shape",
    designation: "IW400300X016",
    shape: "I",
    country_code: "Indian",
    ax: 171,
    ax_global: 17100,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 424,
    d_global: 424,
    ix: 90,
    ix_global: 900000,
    iy: 9834,
    iy_global: 98340000,
    iz: 56503,
    iz_global: 565030000,
    t: 13,
    t_global: 13,
    tf: 9,
    tf_global: 9,
    z: 2677,
    z_global: 2677000,
    zx: 759,
    zx_global: 759000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 424,
    height_global: 424,
  },
  {
    profile_section_id: 337,
    name: "IW450300X010",
    type: "W Shape",
    designation: "IW450300X010",
    shape: "I",
    country_code: "Indian",
    ax: 172,
    ax_global: 17200,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 468,
    d_global: 468,
    ix: 86,
    ix_global: 860000,
    iy: 9371,
    iy_global: 93710000,
    iz: 67696,
    iz_global: 676960000,
    t: 15,
    t_global: 15,
    tf: 9,
    tf_global: 9,
    z: 2905,
    z_global: 2905000,
    zx: 809,
    zx_global: 809000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 468,
    height_global: 468,
  },
  {
    profile_section_id: 338,
    name: "IW450300X012",
    type: "W Shape",
    designation: "IW450300X012",
    shape: "I",
    country_code: "Indian",
    ax: 177,
    ax_global: 17700,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 470,
    d_global: 470,
    ix: 92,
    ix_global: 920000,
    iy: 9632,
    iy_global: 96320000,
    iz: 70931,
    iz_global: 709310000,
    t: 15,
    t_global: 15,
    tf: 9,
    tf_global: 9,
    z: 3017,
    z_global: 3017000,
    zx: 809,
    zx_global: 809000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 470,
    height_global: 470,
  },
  {
    profile_section_id: 339,
    name: "IW400350X012",
    type: "W Shape",
    designation: "IW400350X012",
    shape: "I",
    country_code: "Indian",
    ax: 177,
    ax_global: 17700,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 420,
    d_global: 420,
    ix: 77,
    ix_global: 770000,
    iy: 14673,
    iy_global: 146730000,
    iz: 58051,
    iz_global: 580510000,
    t: 13,
    t_global: 13,
    tf: 9,
    tf_global: 9,
    z: 2797,
    z_global: 2797000,
    zx: 932,
    zx_global: 932000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 420,
    height_global: 420,
  },
  {
    profile_section_id: 340,
    name: "IW350350X016",
    type: "W Shape",
    designation: "IW350350X016",
    shape: "I",
    country_code: "Indian",
    ax: 177,
    ax_global: 17700,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 374,
    d_global: 374,
    ix: 90,
    ix_global: 900000,
    iy: 15553,
    iy_global: 155530000,
    iz: 46583,
    iz_global: 465830000,
    t: 11,
    t_global: 11,
    tf: 8,
    tf_global: 8,
    z: 2545,
    z_global: 2545000,
    zx: 899,
    zx_global: 899000,
    width: 200,
    width_global: 200,
    thickness: 8,
    thickness_global: 8,
    height: 374,
    height_global: 374,
  },
  {
    profile_section_id: 341,
    name: "IW450350X010",
    type: "W Shape",
    designation: "IW450350X010",
    shape: "I",
    country_code: "Indian",
    ax: 187,
    ax_global: 18700,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 468,
    d_global: 468,
    ix: 92,
    ix_global: 920000,
    iy: 14445,
    iy_global: 144450000,
    iz: 75002,
    iz_global: 750020000,
    t: 15,
    t_global: 15,
    tf: 9,
    tf_global: 9,
    z: 3224,
    z_global: 3224000,
    zx: 982,
    zx_global: 982000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 468,
    height_global: 468,
  },
  {
    profile_section_id: 342,
    name: "IW450300X016",
    type: "W Shape",
    designation: "IW450300X016",
    shape: "I",
    country_code: "Indian",
    ax: 187,
    ax_global: 18700,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 474,
    d_global: 474,
    ix: 112,
    ix_global: 1120000,
    iy: 10153,
    iy_global: 101530000,
    iz: 77026,
    iz_global: 770260000,
    t: 15,
    t_global: 15,
    tf: 9,
    tf_global: 9,
    z: 3225,
    z_global: 3225000,
    zx: 809,
    zx_global: 809000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 474,
    height_global: 474,
  },
  {
    profile_section_id: 343,
    name: "IW400350X016",
    type: "W Shape",
    designation: "IW400350X016",
    shape: "I",
    country_code: "Indian",
    ax: 190,
    ax_global: 19000,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 424,
    d_global: 424,
    ix: 102,
    ix_global: 1020000,
    iy: 15765,
    iy_global: 157650000,
    iz: 64094,
    iz_global: 640940000,
    t: 13,
    t_global: 13,
    tf: 9,
    tf_global: 9,
    z: 3039,
    z_global: 3039000,
    zx: 932,
    zx_global: 932000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 424,
    height_global: 424,
  },
  {
    profile_section_id: 344,
    name: "IW450350X012",
    type: "W Shape",
    designation: "IW450350X012",
    shape: "I",
    country_code: "Indian",
    ax: 193,
    ax_global: 19300,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 470,
    d_global: 470,
    ix: 99,
    ix_global: 990000,
    iy: 14992,
    iy_global: 149920000,
    iz: 79075,
    iz_global: 790750000,
    t: 15,
    t_global: 15,
    tf: 9,
    tf_global: 9,
    z: 3368,
    z_global: 3368000,
    zx: 982,
    zx_global: 982000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 470,
    height_global: 470,
  },
  {
    profile_section_id: 345,
    name: "IW450350X016",
    type: "W Shape",
    designation: "IW450350X016",
    shape: "I",
    country_code: "Indian",
    ax: 206,
    ax_global: 20600,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 474,
    d_global: 474,
    ix: 125,
    ix_global: 1250000,
    iy: 16084,
    iy_global: 160840000,
    iz: 86641,
    iz_global: 866410000,
    t: 15,
    t_global: 15,
    tf: 9,
    tf_global: 9,
    z: 3632,
    z_global: 3632000,
    zx: 982,
    zx_global: 982000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 474,
    height_global: 474,
  },
  {
    profile_section_id: 346,
    name: "IW500350X010",
    type: "W Shape",
    designation: "IW500350X010",
    shape: "I",
    country_code: "Indian",
    ax: 207,
    ax_global: 20700,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 518,
    d_global: 518,
    ix: 100,
    ix_global: 1000000,
    iy: 15727,
    iy_global: 157270000,
    iz: 101912,
    iz_global: 1019120000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 3900,
    z_global: 3900000,
    zx: 1136,
    zx_global: 1136000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 518,
    height_global: 518,
  },
  {
    profile_section_id: 347,
    name: "IW500350X012",
    type: "W Shape",
    designation: "IW500350X012",
    shape: "I",
    country_code: "Indian",
    ax: 213,
    ax_global: 21300,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 520,
    d_global: 520,
    ix: 108,
    ix_global: 1080000,
    iy: 16273,
    iy_global: 162730000,
    iz: 106854,
    iz_global: 1068540000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 4054,
    z_global: 4054000,
    zx: 1136,
    zx_global: 1136000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 520,
    height_global: 520,
  },
  {
    profile_section_id: 348,
    name: "IW350350X1020",
    type: "W Shape",
    designation: "IW350350X1020",
    shape: "I",
    country_code: "Indian",
    ax: 215,
    ax_global: 21500,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 388,
    d_global: 388,
    ix: 140,
    ix_global: 1400000,
    iy: 17947,
    iy_global: 179470000,
    iz: 61115,
    iz_global: 611150000,
    t: 11,
    t_global: 11,
    tf: 8,
    tf_global: 8,
    z: 3317,
    z_global: 3317000,
    zx: 1368,
    zx_global: 1368000,
    width: 200,
    width_global: 200,
    thickness: 8,
    thickness_global: 8,
    height: 388,
    height_global: 388,
  },
  {
    profile_section_id: 349,
    name: "IW500400X010",
    type: "W Shape",
    designation: "IW500400X010",
    shape: "I",
    country_code: "Indian",
    ax: 216,
    ax_global: 21600,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 519,
    d_global: 519,
    ix: 109,
    ix_global: 1090000,
    iy: 20801,
    iy_global: 208010000,
    iz: 106173,
    iz_global: 1061730000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 4119,
    z_global: 4119000,
    zx: 1353,
    zx_global: 1353000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 519,
    height_global: 519,
  },
  {
    profile_section_id: 350,
    name: "IW500400X012",
    type: "W Shape",
    designation: "IW500400X012",
    shape: "I",
    country_code: "Indian",
    ax: 223,
    ax_global: 22300,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 521,
    d_global: 521,
    ix: 117,
    ix_global: 1170000,
    iy: 21347,
    iy_global: 213470000,
    iz: 111454,
    iz_global: 1114540000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 4287,
    z_global: 4287000,
    zx: 1353,
    zx_global: 1353000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 521,
    height_global: 521,
  },
  {
    profile_section_id: 351,
    name: "IW500350X016",
    type: "W Shape",
    designation: "IW500350X016",
    shape: "I",
    country_code: "Indian",
    ax: 226,
    ax_global: 22600,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 524,
    d_global: 524,
    ix: 133,
    ix_global: 1330000,
    iy: 17365,
    iy_global: 173650000,
    iz: 116100,
    iz_global: 1161000000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 4338,
    z_global: 4338000,
    zx: 1136,
    zx_global: 1136000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 524,
    height_global: 524,
  },
  {
    profile_section_id: 352,
    name: "IW400350X1020",
    type: "W Shape",
    designation: "IW400350X1020",
    shape: "I",
    country_code: "Indian",
    ax: 228,
    ax_global: 22800,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 438,
    d_global: 438,
    ix: 152,
    ix_global: 1520000,
    iy: 18159,
    iy_global: 181590000,
    iz: 82554,
    iz_global: 825540000,
    t: 13,
    t_global: 13,
    tf: 9,
    tf_global: 9,
    z: 3913,
    z_global: 3913000,
    zx: 1401,
    zx_global: 1401000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 438,
    height_global: 438,
  },
  {
    profile_section_id: 353,
    name: "IW550350X010",
    type: "W Shape",
    designation: "IW550350X010",
    shape: "I",
    country_code: "Indian",
    ax: 229,
    ax_global: 22900,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 568,
    d_global: 568,
    ix: 143,
    ix_global: 1430000,
    iy: 16479,
    iy_global: 164790000,
    iz: 135282,
    iz_global: 1352820000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 4689,
    z_global: 4689000,
    zx: 1230,
    zx_global: 1230000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 568,
    height_global: 568,
  },
  {
    profile_section_id: 354,
    name: "IW500400X016",
    type: "W Shape",
    designation: "IW500400X016",
    shape: "I",
    country_code: "Indian",
    ax: 235,
    ax_global: 23500,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 525,
    d_global: 525,
    ix: 142,
    ix_global: 1420000,
    iy: 22440,
    iy_global: 224400000,
    iz: 121362,
    iz_global: 1213620000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 4598,
    z_global: 4598000,
    zx: 1353,
    zx_global: 1353000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 525,
    height_global: 525,
  },
  {
    profile_section_id: 355,
    name: "IW550350X012",
    type: "W Shape",
    designation: "IW550350X012",
    shape: "I",
    country_code: "Indian",
    ax: 235,
    ax_global: 23500,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 570,
    d_global: 570,
    ix: 150,
    ix_global: 1500000,
    iy: 17025,
    iy_global: 170250000,
    iz: 141169,
    iz_global: 1411690000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 4853,
    z_global: 4853000,
    zx: 1230,
    zx_global: 1230000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 570,
    height_global: 570,
  },
  {
    profile_section_id: 356,
    name: "IW350350X1225",
    type: "W Shape",
    designation: "IW350350X1225",
    shape: "I",
    country_code: "Indian",
    ax: 236,
    ax_global: 23600,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 395,
    d_global: 395,
    ix: 228,
    ix_global: 2280000,
    iy: 19573,
    iy_global: 195730000,
    iz: 69580,
    iz_global: 695800000,
    t: 11,
    t_global: 11,
    tf: 8,
    tf_global: 8,
    z: 3723,
    z_global: 3723000,
    zx: 1477,
    zx_global: 1477000,
    width: 200,
    width_global: 200,
    thickness: 8,
    thickness_global: 8,
    height: 395,
    height_global: 395,
  },
  {
    profile_section_id: 357,
    name: "IW550400X010",
    type: "W Shape",
    designation: "IW550400X010",
    shape: "I",
    country_code: "Indian",
    ax: 238,
    ax_global: 23800,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 569,
    d_global: 569,
    ix: 152,
    ix_global: 1520000,
    iy: 21554,
    iy_global: 215540000,
    iz: 140570,
    iz_global: 1405700000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 4938,
    z_global: 4938000,
    zx: 1446,
    zx_global: 1446000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 569,
    height_global: 569,
  },
  {
    profile_section_id: 358,
    name: "IW450350X1020",
    type: "W Shape",
    designation: "IW450350X1020",
    shape: "I",
    country_code: "Indian",
    ax: 244,
    ax_global: 24400,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 488,
    d_global: 488,
    ix: 175,
    ix_global: 1750000,
    iy: 18478,
    iy_global: 184780000,
    iz: 109501,
    iz_global: 1095010000,
    t: 15,
    t_global: 15,
    tf: 9,
    tf_global: 9,
    z: 4609,
    z_global: 4609000,
    zx: 1451,
    zx_global: 1451000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 488,
    height_global: 488,
  },
  {
    profile_section_id: 359,
    name: "IW550400X012",
    type: "W Shape",
    designation: "IW550400X012",
    shape: "I",
    country_code: "Indian",
    ax: 245,
    ax_global: 24500,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 571,
    d_global: 571,
    ix: 160,
    ix_global: 1600000,
    iy: 22100,
    iy_global: 221000000,
    iz: 146830,
    iz_global: 1468300000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 5116,
    z_global: 5116000,
    zx: 1446,
    zx_global: 1446000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 571,
    height_global: 571,
  },
  {
    profile_section_id: 360,
    name: "IW550350X016",
    type: "W Shape",
    designation: "IW550350X016",
    shape: "I",
    country_code: "Indian",
    ax: 248,
    ax_global: 24800,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 574,
    d_global: 574,
    ix: 176,
    ix_global: 1760000,
    iy: 18118,
    iy_global: 181180000,
    iz: 152251,
    iz_global: 1522510000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 5159,
    z_global: 5159000,
    zx: 1230,
    zx_global: 1230000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 574,
    height_global: 574,
  },
  {
    profile_section_id: 361,
    name: "IW400350X1225",
    type: "W Shape",
    designation: "IW400350X1225",
    shape: "I",
    country_code: "Indian",
    ax: 249,
    ax_global: 24900,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 445,
    d_global: 445,
    ix: 240,
    ix_global: 2400000,
    iy: 19785,
    iy_global: 197850000,
    iz: 93305,
    iz_global: 933050000,
    t: 13,
    t_global: 13,
    tf: 9,
    tf_global: 9,
    z: 4365,
    z_global: 4365000,
    zx: 1511,
    zx_global: 1511000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 445,
    height_global: 445,
  },
  {
    profile_section_id: 362,
    name: "IW600350X010",
    type: "W Shape",
    designation: "IW600350X010",
    shape: "I",
    country_code: "Indian",
    ax: 256,
    ax_global: 25600,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 618,
    d_global: 618,
    ix: 219,
    ix_global: 2190000,
    iy: 17441,
    iy_global: 174410000,
    iz: 178423,
    iz_global: 1784230000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 5658,
    z_global: 5658000,
    zx: 1349,
    zx_global: 1349000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 618,
    height_global: 618,
  },
  {
    profile_section_id: 363,
    name: "IW550400X016",
    type: "W Shape",
    designation: "IW550400X016",
    shape: "I",
    country_code: "Indian",
    ax: 258,
    ax_global: 25800,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 575,
    d_global: 575,
    ix: 185,
    ix_global: 1850000,
    iy: 23193,
    iy_global: 231930000,
    iz: 158641,
    iz_global: 1586410000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 5448,
    z_global: 5448000,
    zx: 1446,
    zx_global: 1446000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 575,
    height_global: 575,
  },
  {
    profile_section_id: 364,
    name: "IW600350X012",
    type: "W Shape",
    designation: "IW600350X012",
    shape: "I",
    country_code: "Indian",
    ax: 262,
    ax_global: 26200,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 620,
    d_global: 620,
    ix: 227,
    ix_global: 2270000,
    iy: 17987,
    iy_global: 179870000,
    iz: 185319,
    iz_global: 1853190000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 5832,
    z_global: 5832000,
    zx: 1349,
    zx_global: 1349000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 620,
    height_global: 620,
  },
  {
    profile_section_id: 365,
    name: "IW500350X1020",
    type: "W Shape",
    designation: "IW500350X1020",
    shape: "I",
    country_code: "Indian",
    ax: 264,
    ax_global: 26400,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 538,
    d_global: 538,
    ix: 183,
    ix_global: 1830000,
    iy: 19759,
    iy_global: 197590000,
    iz: 143834,
    iz_global: 1438340000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 5419,
    z_global: 5419000,
    zx: 1605,
    zx_global: 1605000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 538,
    height_global: 538,
  },
  {
    profile_section_id: 366,
    name: "IW450350X1225",
    type: "W Shape",
    designation: "IW450350X1225",
    shape: "I",
    country_code: "Indian",
    ax: 265,
    ax_global: 26500,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 495,
    d_global: 495,
    ix: 262,
    ix_global: 2620000,
    iy: 20104,
    iy_global: 201040000,
    iz: 122802,
    iz_global: 1228020000,
    t: 15,
    t_global: 15,
    tf: 9,
    tf_global: 9,
    z: 5106,
    z_global: 5106000,
    zx: 1561,
    zx_global: 1561000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 495,
    height_global: 495,
  },
  {
    profile_section_id: 367,
    name: "IW600400X010",
    type: "W Shape",
    designation: "IW600400X010",
    shape: "I",
    country_code: "Indian",
    ax: 265,
    ax_global: 26500,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 619,
    d_global: 619,
    ix: 228,
    ix_global: 2280000,
    iy: 22516,
    iy_global: 225160000,
    iz: 184878,
    iz_global: 1848780000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 5938,
    z_global: 5938000,
    zx: 1565,
    zx_global: 1565000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 619,
    height_global: 619,
  },
  {
    profile_section_id: 368,
    name: "IW350350X1632",
    type: "W Shape",
    designation: "IW350350X1632",
    shape: "I",
    country_code: "Indian",
    ax: 269,
    ax_global: 26900,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 406,
    d_global: 406,
    ix: 430,
    ix_global: 4300000,
    iy: 22005,
    iy_global: 220050000,
    iz: 82872,
    iz_global: 828720000,
    t: 11,
    t_global: 11,
    tf: 8,
    tf_global: 8,
    z: 4355,
    z_global: 4355000,
    zx: 1649,
    zx_global: 1649000,
    width: 200,
    width_global: 200,
    thickness: 8,
    thickness_global: 8,
    height: 406,
    height_global: 406,
  },
  {
    profile_section_id: 369,
    name: "IW600400X012",
    type: "W Shape",
    designation: "IW600400X012",
    shape: "I",
    country_code: "Indian",
    ax: 272,
    ax_global: 27200,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 621,
    d_global: 621,
    ix: 236,
    ix_global: 2360000,
    iy: 23062,
    iy_global: 230620000,
    iz: 192173,
    iz_global: 1921730000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 6126,
    z_global: 6126000,
    zx: 1565,
    zx_global: 1565000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 621,
    height_global: 621,
  },
  {
    profile_section_id: 370,
    name: "IW600350X016",
    type: "W Shape",
    designation: "IW600350X016",
    shape: "I",
    country_code: "Indian",
    ax: 275,
    ax_global: 27500,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 624,
    d_global: 624,
    ix: 252,
    ix_global: 2520000,
    iy: 19080,
    iy_global: 190800000,
    iz: 198388,
    iz_global: 1983880000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 6159,
    z_global: 6159000,
    zx: 1349,
    zx_global: 1349000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 624,
    height_global: 624,
  },
  {
    profile_section_id: 371,
    name: "IW500400X1020",
    type: "W Shape",
    designation: "IW500400X1020",
    shape: "I",
    country_code: "Indian",
    ax: 280,
    ax_global: 28000,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 539,
    d_global: 539,
    ix: 195,
    ix_global: 1950000,
    iy: 26263,
    iy_global: 262630000,
    iz: 152781,
    iz_global: 1527810000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 5836,
    z_global: 5836000,
    zx: 2121,
    zx_global: 2121000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 539,
    height_global: 539,
  },
  {
    profile_section_id: 372,
    name: "IW400350X1632",
    type: "W Shape",
    designation: "IW400350X1632",
    shape: "I",
    country_code: "Indian",
    ax: 281,
    ax_global: 28100,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 456,
    d_global: 456,
    ix: 442,
    ix_global: 4420000,
    iy: 22218,
    iy_global: 222180000,
    iz: 110096,
    iz_global: 1100960000,
    t: 13,
    t_global: 13,
    tf: 9,
    tf_global: 9,
    z: 5070,
    z_global: 5070000,
    zx: 1682,
    zx_global: 1682000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 456,
    height_global: 456,
  },
  {
    profile_section_id: 373,
    name: "IW600400X016",
    type: "W Shape",
    designation: "IW600400X016",
    shape: "I",
    country_code: "Indian",
    ax: 285,
    ax_global: 28500,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 625,
    d_global: 625,
    ix: 261,
    ix_global: 2610000,
    iy: 24154,
    iy_global: 241540000,
    iz: 206025,
    iz_global: 2060250000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 6479,
    z_global: 6479000,
    zx: 1565,
    zx_global: 1565000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 625,
    height_global: 625,
  },
  {
    profile_section_id: 374,
    name: "IW500350X1225",
    type: "W Shape",
    designation: "IW500350X1225",
    shape: "I",
    country_code: "Indian",
    ax: 285,
    ax_global: 28500,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 545,
    d_global: 545,
    ix: 271,
    ix_global: 2710000,
    iy: 21385,
    iy_global: 213850000,
    iz: 159946,
    iz_global: 1599460000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 5960,
    z_global: 5960000,
    zx: 1715,
    zx_global: 1715000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 545,
    height_global: 545,
  },
  {
    profile_section_id: 375,
    name: "IW550350X1020",
    type: "W Shape",
    designation: "IW550350X1020",
    shape: "I",
    country_code: "Indian",
    ax: 286,
    ax_global: 28600,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 588,
    d_global: 588,
    ix: 226,
    ix_global: 2260000,
    iy: 20512,
    iy_global: 205120000,
    iz: 185333,
    iz_global: 1853330000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 6344,
    z_global: 6344000,
    zx: 1698,
    zx_global: 1698000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 588,
    height_global: 588,
  },
  {
    profile_section_id: 376,
    name: "IW450350X1632",
    type: "W Shape",
    designation: "IW450350X1632",
    shape: "I",
    country_code: "Indian",
    ax: 297,
    ax_global: 29700,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 506,
    d_global: 506,
    ix: 465,
    ix_global: 4650000,
    iy: 22536,
    iy_global: 225360000,
    iz: 143501,
    iz_global: 1435010000,
    t: 15,
    t_global: 15,
    tf: 9,
    tf_global: 9,
    z: 5884,
    z_global: 5884000,
    zx: 1732,
    zx_global: 1732000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 506,
    height_global: 506,
  },
  {
    profile_section_id: 377,
    name: "IW550400X1020",
    type: "W Shape",
    designation: "IW550400X1020",
    shape: "I",
    country_code: "Indian",
    ax: 302,
    ax_global: 30200,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 589,
    d_global: 589,
    ix: 237,
    ix_global: 2370000,
    iy: 27015,
    iy_global: 270150000,
    iz: 196231,
    iz_global: 1962310000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 6811,
    z_global: 6811000,
    zx: 2214,
    zx_global: 2214000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 589,
    height_global: 589,
  },
  {
    profile_section_id: 378,
    name: "IW500400X1225",
    type: "W Shape",
    designation: "IW500400X1225",
    shape: "I",
    country_code: "Indian",
    ax: 303,
    ax_global: 30300,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 546,
    d_global: 546,
    ix: 284,
    ix_global: 2840000,
    iy: 28174,
    iy_global: 281740000,
    iz: 170718,
    iz_global: 1707180000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 6449,
    z_global: 6449000,
    zx: 2300,
    zx_global: 2300000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 546,
    height_global: 546,
  },
  {
    profile_section_id: 379,
    name: "IW350350X2040",
    type: "W Shape",
    designation: "IW350350X2040",
    shape: "I",
    country_code: "Indian",
    ax: 304,
    ax_global: 30400,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 418,
    d_global: 418,
    ix: 796,
    ix_global: 7960000,
    iy: 24711,
    iy_global: 247110000,
    iz: 97828,
    iz_global: 978280000,
    t: 11,
    t_global: 11,
    tf: 8,
    tf_global: 8,
    z: 5045,
    z_global: 5045000,
    zx: 1836,
    zx_global: 1836000,
    width: 200,
    width_global: 200,
    thickness: 8,
    thickness_global: 8,
    height: 418,
    height_global: 418,
  },
  {
    profile_section_id: 380,
    name: "IW550350X1225",
    type: "W Shape",
    designation: "IW550350X1225",
    shape: "I",
    country_code: "Indian",
    ax: 307,
    ax_global: 30700,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 595,
    d_global: 595,
    ix: 313,
    ix_global: 3130000,
    iy: 22138,
    iy_global: 221380000,
    iz: 204516,
    iz_global: 2045160000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 6928,
    z_global: 6928000,
    zx: 1808,
    zx_global: 1808000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 595,
    height_global: 595,
  },
  {
    profile_section_id: 381,
    name: "IW600350X1020",
    type: "W Shape",
    designation: "IW600350X1020",
    shape: "I",
    country_code: "Indian",
    ax: 313,
    ax_global: 31300,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 638,
    d_global: 638,
    ix: 302,
    ix_global: 3020000,
    iy: 21474,
    iy_global: 214740000,
    iz: 237298,
    iz_global: 2372980000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 7448,
    z_global: 7448000,
    zx: 1817,
    zx_global: 1817000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 638,
    height_global: 638,
  },
  {
    profile_section_id: 382,
    name: "IW400350X2040",
    type: "W Shape",
    designation: "IW400350X2040",
    shape: "I",
    country_code: "Indian",
    ax: 317,
    ax_global: 31700,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 468,
    d_global: 468,
    ix: 808,
    ix_global: 8080000,
    iy: 24923,
    iy_global: 249230000,
    iz: 128882,
    iz_global: 1288820000,
    t: 13,
    t_global: 13,
    tf: 9,
    tf_global: 9,
    z: 5838,
    z_global: 5838000,
    zx: 1870,
    zx_global: 1870000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 468,
    height_global: 468,
  },
  {
    profile_section_id: 383,
    name: "IW500350X1632",
    type: "W Shape",
    designation: "IW500350X1632",
    shape: "I",
    country_code: "Indian",
    ax: 317,
    ax_global: 31700,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 556,
    d_global: 556,
    ix: 473,
    ix_global: 4730000,
    iy: 23817,
    iy_global: 238170000,
    iz: 184964,
    iz_global: 1849640000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 6809,
    z_global: 6809000,
    zx: 1886,
    zx_global: 1886000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 556,
    height_global: 556,
  },
  {
    profile_section_id: 384,
    name: "IW550400X1225",
    type: "W Shape",
    designation: "IW550400X1225",
    shape: "I",
    country_code: "Indian",
    ax: 325,
    ax_global: 32500,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 596,
    d_global: 596,
    ix: 326,
    ix_global: 3260000,
    iy: 28927,
    iy_global: 289270000,
    iz: 217526,
    iz_global: 2175260000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 7473,
    z_global: 7473000,
    zx: 2393,
    zx_global: 2393000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 596,
    height_global: 596,
  },
  {
    profile_section_id: 385,
    name: "IW600400X1020",
    type: "W Shape",
    designation: "IW600400X1020",
    shape: "I",
    country_code: "Indian",
    ax: 329,
    ax_global: 32900,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 639,
    d_global: 639,
    ix: 314,
    ix_global: 3140000,
    iy: 27977,
    iy_global: 279770000,
    iz: 250375,
    iz_global: 2503750000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 7968,
    z_global: 7968000,
    zx: 2333,
    zx_global: 2333000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 639,
    height_global: 639,
  },
  {
    profile_section_id: 386,
    name: "IW450350X2040",
    type: "W Shape",
    designation: "IW450350X2040",
    shape: "I",
    country_code: "Indian",
    ax: 333,
    ax_global: 33300,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 518,
    d_global: 518,
    ix: 830,
    ix_global: 8300000,
    iy: 25242,
    iy_global: 252420000,
    iz: 166568,
    iz_global: 1665680000,
    t: 15,
    t_global: 15,
    tf: 9,
    tf_global: 9,
    z: 6730,
    z_global: 6730000,
    zx: 1920,
    zx_global: 1920000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 518,
    height_global: 518,
  },
  {
    profile_section_id: 387,
    name: "IW600350X1225",
    type: "W Shape",
    designation: "IW600350X1225",
    shape: "I",
    country_code: "Indian",
    ax: 334,
    ax_global: 33400,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 645,
    d_global: 645,
    ix: 390,
    ix_global: 3900000,
    iy: 23100,
    iy_global: 231000000,
    iz: 259810,
    iz_global: 2598100000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 8076,
    z_global: 8076000,
    zx: 1927,
    zx_global: 1927000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 645,
    height_global: 645,
  },
  {
    profile_section_id: 388,
    name: "IW500400X1632",
    type: "W Shape",
    designation: "IW500400X1632",
    shape: "I",
    country_code: "Indian",
    ax: 338,
    ax_global: 33800,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 557,
    d_global: 557,
    ix: 492,
    ix_global: 4920000,
    iy: 31178,
    iy_global: 311780000,
    iz: 198798,
    iz_global: 1987980000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 7416,
    z_global: 7416000,
    zx: 2582,
    zx_global: 2582000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 557,
    height_global: 557,
  },
  {
    profile_section_id: 389,
    name: "IW550350X1632",
    type: "W Shape",
    designation: "IW550350X1632",
    shape: "I",
    country_code: "Indian",
    ax: 339,
    ax_global: 33900,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 606,
    d_global: 606,
    ix: 516,
    ix_global: 5160000,
    iy: 24570,
    iy_global: 245700000,
    iz: 234262,
    iz_global: 2342620000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 7849,
    z_global: 7849000,
    zx: 1980,
    zx_global: 1980000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 606,
    height_global: 606,
  },
  {
    profile_section_id: 390,
    name: "IW600400X1225",
    type: "W Shape",
    designation: "IW600400X1225",
    shape: "I",
    country_code: "Indian",
    ax: 352,
    ax_global: 35200,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 646,
    d_global: 646,
    ix: 403,
    ix_global: 4030000,
    iy: 29889,
    iy_global: 298890000,
    iz: 275281,
    iz_global: 2752810000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 8678,
    z_global: 8678000,
    zx: 2512,
    zx_global: 2512000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 646,
    height_global: 646,
  },
  {
    profile_section_id: 391,
    name: "IW500350X2040",
    type: "W Shape",
    designation: "IW500350X2040",
    shape: "I",
    country_code: "Indian",
    ax: 353,
    ax_global: 35300,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 568,
    d_global: 568,
    ix: 839,
    ix_global: 8390000,
    iy: 26523,
    iy_global: 265230000,
    iz: 212769,
    iz_global: 2127690000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 7732,
    z_global: 7732000,
    zx: 2074,
    zx_global: 2074000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 568,
    height_global: 568,
  },
  {
    profile_section_id: 392,
    name: "IW550400X1632",
    type: "W Shape",
    designation: "IW550400X1632",
    shape: "I",
    country_code: "Indian",
    ax: 360,
    ax_global: 36000,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 607,
    d_global: 607,
    ix: 534,
    ix_global: 5340000,
    iy: 31931,
    iy_global: 319310000,
    iz: 250844,
    iz_global: 2508440000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 8521,
    z_global: 8521000,
    zx: 2675,
    zx_global: 2675000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 607,
    height_global: 607,
  },
  {
    profile_section_id: 393,
    name: "IW600350X1632",
    type: "W Shape",
    designation: "IW600350X1632",
    shape: "I",
    country_code: "Indian",
    ax: 366,
    ax_global: 36600,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 656,
    d_global: 656,
    ix: 592,
    ix_global: 5920000,
    iy: 25532,
    iy_global: 255320000,
    iz: 294693,
    iz_global: 2946930000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 9070,
    z_global: 9070000,
    zx: 2099,
    zx_global: 2099000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 656,
    height_global: 656,
  },
  {
    profile_section_id: 394,
    name: "IW550350X2040",
    type: "W Shape",
    designation: "IW550350X2040",
    shape: "I",
    country_code: "Indian",
    ax: 375,
    ax_global: 37500,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 618,
    d_global: 618,
    ix: 881,
    ix_global: 8810000,
    iy: 27275,
    iy_global: 272750000,
    iz: 267257,
    iz_global: 2672570000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 8850,
    z_global: 8850000,
    zx: 2167,
    zx_global: 2167000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 618,
    height_global: 618,
  },
  {
    profile_section_id: 395,
    name: "IW500400X2040",
    type: "W Shape",
    designation: "IW500400X2040",
    shape: "I",
    country_code: "Indian",
    ax: 376,
    ax_global: 37600,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 569,
    d_global: 569,
    ix: 867,
    ix_global: 8670000,
    iy: 34455,
    iy_global: 344550000,
    iz: 230195,
    iz_global: 2301950000,
    t: 15,
    t_global: 15,
    tf: 10,
    tf_global: 10,
    z: 8474,
    z_global: 8474000,
    zx: 2889,
    zx_global: 2889000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 569,
    height_global: 569,
  },
  {
    profile_section_id: 396,
    name: "IW600400X1632",
    type: "W Shape",
    designation: "IW600400X1632",
    shape: "I",
    country_code: "Indian",
    ax: 387,
    ax_global: 38700,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 657,
    d_global: 657,
    ix: 611,
    ix_global: 6110000,
    iy: 30893,
    iy_global: 308930000,
    iz: 314274,
    iz_global: 3142740000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 9807,
    z_global: 9807000,
    zx: 2794,
    zx_global: 2794000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 657,
    height_global: 657,
  },
  {
    profile_section_id: 397,
    name: "IW550400X2040",
    type: "W Shape",
    designation: "IW550400X2040",
    shape: "I",
    country_code: "Indian",
    ax: 398,
    ax_global: 39800,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 619,
    d_global: 619,
    ix: 909,
    ix_global: 9090000,
    iy: 35207,
    iy_global: 352070000,
    iz: 288011,
    iz_global: 2880110000,
    t: 18,
    t_global: 18,
    tf: 11,
    tf_global: 11,
    z: 9666,
    z_global: 9666000,
    zx: 2982,
    zx_global: 2982000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 619,
    height_global: 619,
  },
  {
    profile_section_id: 398,
    name: "IW600350X2040",
    type: "W Shape",
    designation: "IW600350X2040",
    shape: "I",
    country_code: "Indian",
    ax: 402,
    ax_global: 40200,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 668,
    d_global: 668,
    ix: 958,
    ix_global: 9580000,
    iy: 28237,
    iy_global: 282370000,
    iz: 333343,
    iz_global: 3333430000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 10147,
    z_global: 10147000,
    zx: 2286,
    zx_global: 2286000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 668,
    height_global: 668,
  },
  {
    profile_section_id: 399,
    name: "IW600400X2040",
    type: "W Shape",
    designation: "IW600400X2040",
    shape: "I",
    country_code: "Indian",
    ax: 425,
    ax_global: 42500,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 669,
    d_global: 669,
    ix: 986,
    ix_global: 9860000,
    iy: 36170,
    iy_global: 361700000,
    iz: 357691,
    iz_global: 3576910000,
    t: 21,
    t_global: 21,
    tf: 11,
    tf_global: 11,
    z: 11039,
    z_global: 11039000,
    zx: 3101,
    zx_global: 3101000,
    width: 250,
    width_global: 250,
    thickness: 11,
    thickness_global: 11,
    height: 669,
    height_global: 669,
  },
  {
    profile_section_id: 421,
    name: "IM350200X010",
    type: "M Shape",
    designation: "IM350200X010",
    shape: "I",
    country_code: "Indian",
    ax: 115,
    ax_global: 11500,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 366,
    d_global: 366,
    ix: 47,
    ix_global: 470000,
    iy: 3024,
    iy_global: 30240000,
    iz: 27350,
    iz_global: 273500000,
    t: 14,
    t_global: 14,
    tf: 8,
    tf_global: 8,
    z: 1489,
    z_global: 1489000,
    zx: 354,
    zx_global: 354000,
    width: 140,
    width_global: 140,
    thickness: 8,
    thickness_global: 8,
    height: 366,
    height_global: 366,
  },
  {
    profile_section_id: 422,
    name: "IM350200X012",
    type: "M Shape",
    designation: "IM350200X012",
    shape: "I",
    country_code: "Indian",
    ax: 119,
    ax_global: 11900,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 368,
    d_global: 368,
    ix: 52,
    ix_global: 520000,
    iy: 3157,
    iy_global: 31570000,
    iz: 28802,
    iz_global: 288020000,
    t: 14,
    t_global: 14,
    tf: 8,
    tf_global: 8,
    z: 1552,
    z_global: 1552000,
    zx: 354,
    zx_global: 354000,
    width: 140,
    width_global: 140,
    thickness: 8,
    thickness_global: 8,
    height: 368,
    height_global: 368,
  },
  {
    profile_section_id: 423,
    name: "IM350225X010",
    type: "M Shape",
    designation: "IM350225X010",
    shape: "I",
    country_code: "Indian",
    ax: 120,
    ax_global: 12000,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 366,
    d_global: 366,
    ix: 50,
    ix_global: 500000,
    iy: 3899,
    iy_global: 38990000,
    iz: 28360,
    iz_global: 283600000,
    t: 14,
    t_global: 14,
    tf: 8,
    tf_global: 8,
    z: 1566,
    z_global: 1566000,
    zx: 419,
    zx_global: 419000,
    width: 140,
    width_global: 140,
    thickness: 8,
    thickness_global: 8,
    height: 366,
    height_global: 366,
  },
  {
    profile_section_id: 424,
    name: "IM350225X012",
    type: "M Shape",
    designation: "IM350225X012",
    shape: "I",
    country_code: "Indian",
    ax: 124,
    ax_global: 12400,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 368,
    d_global: 368,
    ix: 55,
    ix_global: 550000,
    iy: 4032,
    iy_global: 40320000,
    iz: 29902,
    iz_global: 299020000,
    t: 14,
    t_global: 14,
    tf: 8,
    tf_global: 8,
    z: 1635,
    z_global: 1635000,
    zx: 419,
    zx_global: 419000,
    width: 140,
    width_global: 140,
    thickness: 8,
    thickness_global: 8,
    height: 368,
    height_global: 368,
  },
  {
    profile_section_id: 425,
    name: "IM400200X010",
    type: "M Shape",
    designation: "IM400200X010",
    shape: "I",
    country_code: "Indian",
    ax: 127,
    ax_global: 12700,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 416,
    d_global: 416,
    ix: 62,
    ix_global: 620000,
    iy: 2908,
    iy_global: 29080000,
    iz: 38502,
    iz_global: 385020000,
    t: 16,
    t_global: 16,
    tf: 9,
    tf_global: 9,
    z: 1809,
    z_global: 1809000,
    zx: 373,
    zx_global: 373000,
    width: 140,
    width_global: 140,
    thickness: 9,
    thickness_global: 9,
    height: 416,
    height_global: 416,
  },
  {
    profile_section_id: 426,
    name: "IM350200X016",
    type: "M Shape",
    designation: "IM350200X016",
    shape: "I",
    country_code: "Indian",
    ax: 127,
    ax_global: 12700,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 372,
    d_global: 372,
    ix: 68,
    ix_global: 680000,
    iy: 3424,
    iy_global: 34240000,
    iz: 31516,
    iz_global: 315160000,
    t: 14,
    t_global: 14,
    tf: 8,
    tf_global: 8,
    z: 1669,
    z_global: 1669000,
    zx: 354,
    zx_global: 354000,
    width: 140,
    width_global: 140,
    thickness: 8,
    thickness_global: 8,
    height: 372,
    height_global: 372,
  },
  {
    profile_section_id: 427,
    name: "IM350250X010",
    type: "M Shape",
    designation: "IM350250X010",
    shape: "I",
    country_code: "Indian",
    ax: 130,
    ax_global: 13000,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 367,
    d_global: 367,
    ix: 57,
    ix_global: 570000,
    iy: 5657,
    iy_global: 56570000,
    iz: 31531,
    iz_global: 315310000,
    t: 14,
    t_global: 14,
    tf: 8,
    tf_global: 8,
    z: 1750,
    z_global: 1750000,
    zx: 498,
    zx_global: 498000,
    width: 140,
    width_global: 140,
    thickness: 8,
    thickness_global: 8,
    height: 367,
    height_global: 367,
  },
  {
    profile_section_id: 428,
    name: "IM400200X012",
    type: "M Shape",
    designation: "IM400200X012",
    shape: "I",
    country_code: "Indian",
    ax: 131,
    ax_global: 13100,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 418,
    d_global: 418,
    ix: 67,
    ix_global: 670000,
    iy: 3041,
    iy_global: 30410000,
    iz: 40374,
    iz_global: 403740000,
    t: 16,
    t_global: 16,
    tf: 9,
    tf_global: 9,
    z: 1878,
    z_global: 1878000,
    zx: 373,
    zx_global: 373000,
    width: 140,
    width_global: 140,
    thickness: 9,
    thickness_global: 9,
    height: 418,
    height_global: 418,
  },
  {
    profile_section_id: 429,
    name: "IM400225X010",
    type: "M Shape",
    designation: "IM400225X010",
    shape: "I",
    country_code: "Indian",
    ax: 132,
    ax_global: 13200,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 416,
    d_global: 416,
    ix: 65,
    ix_global: 650000,
    iy: 3783,
    iy_global: 37830000,
    iz: 39865,
    iz_global: 398650000,
    t: 16,
    t_global: 16,
    tf: 9,
    tf_global: 9,
    z: 1902,
    z_global: 1902000,
    zx: 439,
    zx_global: 439000,
    width: 140,
    width_global: 140,
    thickness: 9,
    thickness_global: 9,
    height: 416,
    height_global: 416,
  },
  {
    profile_section_id: 430,
    name: "IM350225X016",
    type: "M Shape",
    designation: "IM350225X016",
    shape: "I",
    country_code: "Indian",
    ax: 132,
    ax_global: 13200,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 372,
    d_global: 372,
    ix: 71,
    ix_global: 710000,
    iy: 4299,
    iy_global: 42990000,
    iz: 32793,
    iz_global: 327930000,
    t: 14,
    t_global: 14,
    tf: 8,
    tf_global: 8,
    z: 1762,
    z_global: 1762000,
    zx: 419,
    zx_global: 419000,
    width: 140,
    width_global: 140,
    thickness: 8,
    thickness_global: 8,
    height: 372,
    height_global: 372,
  },
  {
    profile_section_id: 431,
    name: "IM350250X012",
    type: "M Shape",
    designation: "IM350250X012",
    shape: "I",
    country_code: "Indian",
    ax: 135,
    ax_global: 13500,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 369,
    d_global: 369,
    ix: 63,
    ix_global: 630000,
    iy: 5917,
    iy_global: 59170000,
    iz: 33431,
    iz_global: 334310000,
    t: 14,
    t_global: 14,
    tf: 8,
    tf_global: 8,
    z: 1836,
    z_global: 1836000,
    zx: 498,
    zx_global: 498000,
    width: 140,
    width_global: 140,
    thickness: 8,
    thickness_global: 8,
    height: 369,
    height_global: 369,
  },
  {
    profile_section_id: 432,
    name: "IM400225X012",
    type: "M Shape",
    designation: "IM400225X012",
    shape: "I",
    country_code: "Indian",
    ax: 136,
    ax_global: 13600,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 418,
    d_global: 418,
    ix: 70,
    ix_global: 700000,
    iy: 3917,
    iy_global: 39170000,
    iz: 41845,
    iz_global: 418450000,
    t: 16,
    t_global: 16,
    tf: 9,
    tf_global: 9,
    z: 1976,
    z_global: 1976000,
    zx: 439,
    zx_global: 439000,
    width: 140,
    width_global: 140,
    thickness: 9,
    thickness_global: 9,
    height: 418,
    height_global: 418,
  },
  {
    profile_section_id: 433,
    name: "IM350300X010",
    type: "M Shape",
    designation: "IM350300X010",
    shape: "I",
    country_code: "Indian",
    ax: 137,
    ax_global: 13700,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 368,
    d_global: 368,
    ix: 59,
    ix_global: 590000,
    iy: 8202,
    iy_global: 82020000,
    iz: 33028,
    iz_global: 330280000,
    t: 14,
    t_global: 14,
    tf: 8,
    tf_global: 8,
    z: 1854,
    z_global: 1854000,
    zx: 636,
    zx_global: 636000,
    width: 140,
    width_global: 140,
    thickness: 8,
    thickness_global: 8,
    height: 368,
    height_global: 368,
  },
  {
    profile_section_id: 434,
    name: "IM400200X016",
    type: "M Shape",
    designation: "IM400200X016",
    shape: "I",
    country_code: "Indian",
    ax: 139,
    ax_global: 13900,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 422,
    d_global: 422,
    ix: 83,
    ix_global: 830000,
    iy: 3308,
    iy_global: 33080000,
    iz: 43890,
    iz_global: 438900000,
    t: 16,
    t_global: 16,
    tf: 9,
    tf_global: 9,
    z: 2004,
    z_global: 2004000,
    zx: 373,
    zx_global: 373000,
    width: 140,
    width_global: 140,
    thickness: 9,
    thickness_global: 9,
    height: 422,
    height_global: 422,
  },
  {
    profile_section_id: 435,
    name: "IM400250X010",
    type: "M Shape",
    designation: "IM400250X010",
    shape: "I",
    country_code: "Indian",
    ax: 142,
    ax_global: 14200,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 417,
    d_global: 417,
    ix: 72,
    ix_global: 720000,
    iy: 5541,
    iy_global: 55410000,
    iz: 44012,
    iz_global: 440120000,
    t: 16,
    t_global: 16,
    tf: 9,
    tf_global: 9,
    z: 2112,
    z_global: 2112000,
    zx: 518,
    zx_global: 518000,
    width: 140,
    width_global: 140,
    thickness: 9,
    thickness_global: 9,
    height: 417,
    height_global: 417,
  },
  {
    profile_section_id: 436,
    name: "IM350300X012",
    type: "M Shape",
    designation: "IM350300X012",
    shape: "I",
    country_code: "Indian",
    ax: 142,
    ax_global: 14200,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 370,
    d_global: 370,
    ix: 65,
    ix_global: 650000,
    iy: 8463,
    iy_global: 84630000,
    iz: 35077,
    iz_global: 350770000,
    t: 14,
    t_global: 14,
    tf: 8,
    tf_global: 8,
    z: 1949,
    z_global: 1949000,
    zx: 636,
    zx_global: 636000,
    width: 140,
    width_global: 140,
    thickness: 8,
    thickness_global: 8,
    height: 370,
    height_global: 370,
  },
  {
    profile_section_id: 437,
    name: "IM400225X016",
    type: "M Shape",
    designation: "IM400225X016",
    shape: "I",
    country_code: "Indian",
    ax: 144,
    ax_global: 14400,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 422,
    d_global: 422,
    ix: 86,
    ix_global: 860000,
    iy: 4183,
    iy_global: 41830000,
    iz: 25575,
    iz_global: 255750000,
    t: 16,
    t_global: 16,
    tf: 9,
    tf_global: 9,
    z: 2114,
    z_global: 2114000,
    zx: 439,
    zx_global: 439000,
    width: 140,
    width_global: 140,
    thickness: 9,
    thickness_global: 9,
    height: 422,
    height_global: 422,
  },
  {
    profile_section_id: 438,
    name: "IM450225X010",
    type: "M Shape",
    designation: "IM450225X010",
    shape: "I",
    country_code: "Indian",
    ax: 145,
    ax_global: 14500,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 466,
    d_global: 466,
    ix: 82,
    ix_global: 820000,
    iy: 4195,
    iy_global: 41950000,
    iz: 55139,
    iz_global: 551390000,
    t: 17,
    t_global: 17,
    tf: 9,
    tf_global: 9,
    z: 2314,
    z_global: 2314000,
    zx: 480,
    zx_global: 480000,
    width: 150,
    width_global: 150,
    thickness: 9,
    thickness_global: 9,
    height: 466,
    height_global: 466,
  },
  {
    profile_section_id: 439,
    name: "IM350250X016",
    type: "M Shape",
    designation: "IM350250X016",
    shape: "I",
    country_code: "Indian",
    ax: 145,
    ax_global: 14500,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 373,
    d_global: 373,
    ix: 83,
    ix_global: 830000,
    iy: 6438,
    iy_global: 64380000,
    iz: 36948,
    iz_global: 369480000,
    t: 14,
    t_global: 14,
    tf: 8,
    tf_global: 8,
    z: 1994,
    z_global: 1994000,
    zx: 498,
    zx_global: 498000,
    width: 140,
    width_global: 140,
    thickness: 8,
    thickness_global: 8,
    height: 373,
    height_global: 373,
  },
  {
    profile_section_id: 440,
    name: "IM400250X012",
    type: "M Shape",
    designation: "IM400250X012",
    shape: "I",
    country_code: "Indian",
    ax: 147,
    ax_global: 14700,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 419,
    d_global: 419,
    ix: 78,
    ix_global: 780000,
    iy: 5801,
    iy_global: 58010000,
    iz: 46458,
    iz_global: 464580000,
    t: 16,
    t_global: 16,
    tf: 9,
    tf_global: 9,
    z: 2206,
    z_global: 2206000,
    zx: 518,
    zx_global: 518000,
    width: 140,
    width_global: 140,
    thickness: 9,
    thickness_global: 9,
    height: 419,
    height_global: 419,
  },
  {
    profile_section_id: 441,
    name: "IM400300X010",
    type: "M Shape",
    designation: "IM400300X010",
    shape: "I",
    country_code: "Indian",
    ax: 149,
    ax_global: 14900,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 418,
    d_global: 418,
    ix: 73,
    ix_global: 730000,
    iy: 8087,
    iy_global: 80870000,
    iz: 46011,
    iz_global: 460110000,
    t: 16,
    t_global: 16,
    tf: 9,
    tf_global: 9,
    z: 2238,
    z_global: 2238000,
    zx: 656,
    zx_global: 656000,
    width: 140,
    width_global: 140,
    thickness: 9,
    thickness_global: 9,
    height: 418,
    height_global: 418,
  },
  {
    profile_section_id: 442,
    name: "IM450225X012",
    type: "M Shape",
    designation: "IM450225X012",
    shape: "I",
    country_code: "Indian",
    ax: 149,
    ax_global: 14900,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 468,
    d_global: 468,
    ix: 87,
    ix_global: 870000,
    iy: 4329,
    iy_global: 43290000,
    iz: 57605,
    iz_global: 576050000,
    t: 17,
    t_global: 17,
    tf: 9,
    tf_global: 9,
    z: 2394,
    z_global: 2394000,
    zx: 480,
    zx_global: 480000,
    width: 150,
    width_global: 150,
    thickness: 9,
    thickness_global: 9,
    height: 468,
    height_global: 468,
  },
  {
    profile_section_id: 443,
    name: "IM350300X016",
    type: "M Shape",
    designation: "IM350300X016",
    shape: "I",
    country_code: "Indian",
    ax: 152,
    ax_global: 15200,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 374,
    d_global: 374,
    ix: 85,
    ix_global: 850000,
    iy: 8984,
    iy_global: 89840000,
    iz: 38883,
    iz_global: 388830000,
    t: 14,
    t_global: 14,
    tf: 8,
    tf_global: 8,
    z: 2123,
    z_global: 2123000,
    zx: 636,
    zx_global: 636000,
    width: 140,
    width_global: 140,
    thickness: 8,
    thickness_global: 8,
    height: 374,
    height_global: 374,
  },
  {
    profile_section_id: 444,
    name: "IM400300X012",
    type: "M Shape",
    designation: "IM400300X012",
    shape: "I",
    country_code: "Indian",
    ax: 154,
    ax_global: 15400,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 420,
    d_global: 420,
    ix: 80,
    ix_global: 800000,
    iy: 8347,
    iy_global: 83470000,
    iz: 48636,
    iz_global: 486360000,
    t: 16,
    t_global: 16,
    tf: 9,
    tf_global: 9,
    z: 2341,
    z_global: 2341000,
    zx: 656,
    zx_global: 656000,
    width: 140,
    width_global: 140,
    thickness: 9,
    thickness_global: 9,
    height: 420,
    height_global: 420,
  },
  {
    profile_section_id: 445,
    name: "IM450250X010",
    type: "M Shape",
    designation: "IM450250X010",
    shape: "I",
    country_code: "Indian",
    ax: 156,
    ax_global: 15600,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 467,
    d_global: 467,
    ix: 89,
    ix_global: 890000,
    iy: 5953,
    iy_global: 59530000,
    iz: 60395,
    iz_global: 603950000,
    t: 17,
    t_global: 17,
    tf: 9,
    tf_global: 9,
    z: 2551,
    z_global: 2551000,
    zx: 558,
    zx_global: 558000,
    width: 150,
    width_global: 150,
    thickness: 9,
    thickness_global: 9,
    height: 467,
    height_global: 467,
  },
  {
    profile_section_id: 446,
    name: "IM400250X016",
    type: "M Shape",
    designation: "IM400250X016",
    shape: "I",
    country_code: "Indian",
    ax: 157,
    ax_global: 15700,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 423,
    d_global: 423,
    ix: 98,
    ix_global: 980000,
    iy: 6322,
    iy_global: 63220000,
    iz: 51006,
    iz_global: 510060000,
    t: 16,
    t_global: 16,
    tf: 9,
    tf_global: 9,
    z: 2378,
    z_global: 2378000,
    zx: 518,
    zx_global: 518000,
    width: 140,
    width_global: 140,
    thickness: 9,
    thickness_global: 9,
    height: 423,
    height_global: 423,
  },
  {
    profile_section_id: 447,
    name: "IM450225X016",
    type: "M Shape",
    designation: "IM450225X016",
    shape: "I",
    country_code: "Indian",
    ax: 157,
    ax_global: 15700,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 472,
    d_global: 472,
    ix: 103,
    ix_global: 1030000,
    iy: 4595,
    iy_global: 45950000,
    iz: 62273,
    iz_global: 622730000,
    t: 17,
    t_global: 17,
    tf: 9,
    tf_global: 9,
    z: 2544,
    z_global: 2544000,
    zx: 480,
    zx_global: 480000,
    width: 150,
    width_global: 150,
    thickness: 9,
    thickness_global: 9,
    height: 472,
    height_global: 472,
  },
  {
    profile_section_id: 448,
    name: "IM450250X012",
    type: "M Shape",
    designation: "IM450250X012",
    shape: "I",
    country_code: "Indian",
    ax: 161,
    ax_global: 16100,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 469,
    d_global: 469,
    ix: 95,
    ix_global: 950000,
    iy: 6213,
    iy_global: 62130000,
    iz: 63446,
    iz_global: 634460000,
    t: 17,
    t_global: 17,
    tf: 9,
    tf_global: 9,
    z: 2652,
    z_global: 2652000,
    zx: 558,
    zx_global: 558000,
    width: 150,
    width_global: 150,
    thickness: 9,
    thickness_global: 9,
    height: 469,
    height_global: 469,
  },
  {
    profile_section_id: 449,
    name: "IM450300X010",
    type: "M Shape",
    designation: "IM450300X010",
    shape: "I",
    country_code: "Indian",
    ax: 163,
    ax_global: 16300,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 468,
    d_global: 468,
    ix: 91,
    ix_global: 910000,
    iy: 8499,
    iy_global: 84990000,
    iz: 62984,
    iz_global: 629840000,
    t: 17,
    t_global: 17,
    tf: 9,
    tf_global: 9,
    z: 2699,
    z_global: 2699000,
    zx: 697,
    zx_global: 697000,
    width: 150,
    width_global: 150,
    thickness: 9,
    thickness_global: 9,
    height: 468,
    height_global: 468,
  },
  {
    profile_section_id: 450,
    name: "IM400300X016",
    type: "M Shape",
    designation: "IM400300X016",
    shape: "I",
    country_code: "Indian",
    ax: 164,
    ax_global: 16400,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 424,
    d_global: 424,
    ix: 99,
    ix_global: 990000,
    iy: 8868,
    iy_global: 88680000,
    iz: 53535,
    iz_global: 535350000,
    t: 16,
    t_global: 16,
    tf: 9,
    tf_global: 9,
    z: 2531,
    z_global: 2531000,
    zx: 656,
    zx_global: 656000,
    width: 140,
    width_global: 140,
    thickness: 9,
    thickness_global: 9,
    height: 424,
    height_global: 424,
  },
  {
    profile_section_id: 451,
    name: "IM450300X012",
    type: "M Shape",
    designation: "IM450300X012",
    shape: "I",
    country_code: "Indian",
    ax: 168,
    ax_global: 16800,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 470,
    d_global: 470,
    ix: 97,
    ix_global: 970000,
    iy: 8759,
    iy_global: 87590000,
    iz: 66244,
    iz_global: 662440000,
    t: 17,
    t_global: 17,
    tf: 9,
    tf_global: 9,
    z: 2810,
    z_global: 2810000,
    zx: 697,
    zx_global: 697000,
    width: 150,
    width_global: 150,
    thickness: 9,
    thickness_global: 9,
    height: 470,
    height_global: 470,
  },
  {
    profile_section_id: 452,
    name: "IM450250X016",
    type: "M Shape",
    designation: "IM450250X016",
    shape: "I",
    country_code: "Indian",
    ax: 171,
    ax_global: 17100,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 473,
    d_global: 473,
    ix: 115,
    ix_global: 1150000,
    iy: 6734,
    iy_global: 67340000,
    iz: 69153,
    iz_global: 691530000,
    t: 17,
    t_global: 17,
    tf: 9,
    tf_global: 9,
    z: 2840,
    z_global: 2840000,
    zx: 558,
    zx_global: 558000,
    width: 150,
    width_global: 150,
    thickness: 9,
    thickness_global: 9,
    height: 473,
    height_global: 473,
  },
  {
    profile_section_id: 453,
    name: "IM500250X010",
    type: "M Shape",
    designation: "IM500250X010",
    shape: "I",
    country_code: "Indian",
    ax: 174,
    ax_global: 17400,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 517,
    d_global: 517,
    ix: 103,
    ix_global: 1030000,
    iy: 6489,
    iy_global: 64890000,
    iz: 82477,
    iz_global: 824770000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 3089,
    z_global: 3089000,
    zx: 644,
    zx_global: 644000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 517,
    height_global: 517,
  },
  {
    profile_section_id: 454,
    name: "IM450300X016",
    type: "M Shape",
    designation: "IM450300X016",
    shape: "I",
    country_code: "Indian",
    ax: 178,
    ax_global: 17800,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 474,
    d_global: 474,
    ix: 117,
    ix_global: 1170000,
    iy: 9280,
    iy_global: 92800000,
    iz: 72359,
    iz_global: 723590000,
    t: 17,
    t_global: 17,
    tf: 9,
    tf_global: 9,
    z: 3016,
    z_global: 3016000,
    zx: 697,
    zx_global: 697000,
    width: 150,
    width_global: 150,
    thickness: 9,
    thickness_global: 9,
    height: 474,
    height_global: 474,
  },
  {
    profile_section_id: 455,
    name: "IM500250X012",
    type: "M Shape",
    designation: "IM500250X012",
    shape: "I",
    country_code: "Indian",
    ax: 179,
    ax_global: 17900,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 519,
    d_global: 519,
    ix: 109,
    ix_global: 1090000,
    iy: 6749,
    iy_global: 67490000,
    iz: 86189,
    iz_global: 861890000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 3197,
    z_global: 3197000,
    zx: 644,
    zx_global: 644000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 519,
    height_global: 519,
  },
  {
    profile_section_id: 456,
    name: "IM500300X010",
    type: "M Shape",
    designation: "IM500300X010",
    shape: "I",
    country_code: "Indian",
    ax: 181,
    ax_global: 18100,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 518,
    d_global: 518,
    ix: 104,
    ix_global: 1040000,
    iy: 9035,
    iy_global: 90350000,
    iz: 85756,
    iz_global: 857560000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 3261,
    z_global: 3261000,
    zx: 783,
    zx_global: 783000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 518,
    height_global: 518,
  },
  {
    profile_section_id: 457,
    name: "IM500300X012",
    type: "M Shape",
    designation: "IM500300X012",
    shape: "I",
    country_code: "Indian",
    ax: 186,
    ax_global: 18600,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 520,
    d_global: 520,
    ix: 110,
    ix_global: 1100000,
    iy: 9295,
    iy_global: 92950000,
    iz: 89701,
    iz_global: 897010000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 3378,
    z_global: 3378000,
    zx: 783,
    zx_global: 783000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 520,
    height_global: 520,
  },
  {
    profile_section_id: 458,
    name: "IM500250X016",
    type: "M Shape",
    designation: "IM500250X016",
    shape: "I",
    country_code: "Indian",
    ax: 189,
    ax_global: 18900,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 523,
    d_global: 523,
    ix: 128,
    ix_global: 1280000,
    iy: 7270,
    iy_global: 72700000,
    iz: 93179,
    iz_global: 931790000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 3397,
    z_global: 3397000,
    zx: 644,
    zx_global: 644000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 523,
    height_global: 523,
  },
  {
    profile_section_id: 459,
    name: "IM550250X010",
    type: "M Shape",
    designation: "IM550250X010",
    shape: "I",
    country_code: "Indian",
    ax: 196,
    ax_global: 19600,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 567,
    d_global: 567,
    ix: 140,
    ix_global: 1400000,
    iy: 6953,
    iy_global: 69530000,
    iz: 110209,
    iz_global: 1102090000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 3713,
    z_global: 3713000,
    zx: 718,
    zx_global: 718000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 567,
    height_global: 567,
  },
  {
    profile_section_id: 460,
    name: "IM500350X010",
    type: "M Shape",
    designation: "IM500350X010",
    shape: "I",
    country_code: "Indian",
    ax: 196,
    ax_global: 19600,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 518,
    d_global: 518,
    ix: 110,
    ix_global: 1100000,
    iy: 14109,
    iy_global: 141090000,
    iz: 94789,
    iz_global: 947890000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 3616,
    z_global: 3616000,
    zx: 956,
    zx_global: 956000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 518,
    height_global: 518,
  },
  {
    profile_section_id: 461,
    name: "IM500300X016",
    type: "M Shape",
    designation: "IM500300X016",
    shape: "I",
    country_code: "Indian",
    ax: 196,
    ax_global: 19600,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 524,
    d_global: 524,
    ix: 130,
    ix_global: 1300000,
    iy: 9816,
    iy_global: 98160000,
    iz: 97147,
    iz_global: 971470000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 3598,
    z_global: 3598000,
    zx: 783,
    zx_global: 783000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 524,
    height_global: 524,
  },
  {
    profile_section_id: 462,
    name: "IM550250X012",
    type: "M Shape",
    designation: "IM550250X012",
    shape: "I",
    country_code: "Indian",
    ax: 201,
    ax_global: 20100,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 569,
    d_global: 569,
    ix: 146,
    ix_global: 1460000,
    iy: 17213,
    iy_global: 172130000,
    iz: 114639,
    iz_global: 1146390000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 3827,
    z_global: 3827000,
    zx: 718,
    zx_global: 718000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 569,
    height_global: 569,
  },
  {
    profile_section_id: 463,
    name: "IM500350X012",
    type: "M Shape",
    designation: "IM500350X012",
    shape: "I",
    country_code: "Indian",
    ax: 203,
    ax_global: 20300,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 520,
    d_global: 520,
    ix: 117,
    ix_global: 1170000,
    iy: 14655,
    iy_global: 146550000,
    iz: 99764,
    iz_global: 997640000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 3768,
    z_global: 3768000,
    zx: 956,
    zx_global: 956000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 520,
    height_global: 520,
  },
  {
    profile_section_id: 464,
    name: "IM550300X010",
    type: "M Shape",
    designation: "IM550300X010",
    shape: "I",
    country_code: "Indian",
    ax: 203,
    ax_global: 20300,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 568,
    d_global: 568,
    ix: 142,
    ix_global: 1420000,
    iy: 9499,
    iy_global: 94990000,
    iz: 114276,
    iz_global: 1142760000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 3911,
    z_global: 3911000,
    zx: 856,
    zx_global: 856000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 568,
    height_global: 568,
  },
  {
    profile_section_id: 465,
    name: "IM550300X012",
    type: "M Shape",
    designation: "IM550300X012",
    shape: "I",
    country_code: "Indian",
    ax: 208,
    ax_global: 20800,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 570,
    d_global: 570,
    ix: 148,
    ix_global: 1480000,
    iy: 9759,
    iy_global: 97590000,
    iz: 118959,
    iz_global: 1189590000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 4034,
    z_global: 4034000,
    zx: 856,
    zx_global: 856000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 570,
    height_global: 570,
  },
  {
    profile_section_id: 466,
    name: "IM550250X016",
    type: "M Shape",
    designation: "IM550250X016",
    shape: "I",
    country_code: "Indian",
    ax: 211,
    ax_global: 21100,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 573,
    d_global: 573,
    ix: 166,
    ix_global: 1660000,
    iy: 7734,
    iy_global: 77340000,
    iz: 123037,
    iz_global: 1230370000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 4041,
    z_global: 4041000,
    zx: 718,
    zx_global: 718000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 573,
    height_global: 573,
  },
  {
    profile_section_id: 467,
    name: "IM500350X016",
    type: "M Shape",
    designation: "IM500350X016",
    shape: "I",
    country_code: "Indian",
    ax: 216,
    ax_global: 21600,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 524,
    d_global: 524,
    ix: 143,
    ix_global: 1430000,
    iy: 15747,
    iy_global: 157470000,
    iz: 109027,
    iz_global: 1090270000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 4048,
    z_global: 4048000,
    zx: 956,
    zx_global: 956000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 524,
    height_global: 524,
  },
  {
    profile_section_id: 468,
    name: "IM550300X016",
    type: "M Shape",
    designation: "IM550300X016",
    shape: "I",
    country_code: "Indian",
    ax: 218,
    ax_global: 21800,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 574,
    d_global: 574,
    ix: 167,
    ix_global: 1670000,
    iy: 10280,
    iy_global: 102800000,
    iz: 127853,
    iz_global: 1278530000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 4267,
    z_global: 4267000,
    zx: 856,
    zx_global: 856000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 574,
    height_global: 574,
  },
  {
    profile_section_id: 469,
    name: "IM550350X010",
    type: "M Shape",
    designation: "IM550350X010",
    shape: "I",
    country_code: "Indian",
    ax: 218,
    ax_global: 21800,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 568,
    d_global: 568,
    ix: 147,
    ix_global: 1470000,
    iy: 14573,
    iy_global: 145730000,
    iz: 125215,
    iz_global: 1252150000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 4303,
    z_global: 4303000,
    zx: 1030,
    zx_global: 1030000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 568,
    height_global: 568,
  },
  {
    profile_section_id: 470,
    name: "IM550350X012",
    type: "M Shape",
    designation: "IM550350X012",
    shape: "I",
    country_code: "Indian",
    ax: 224,
    ax_global: 22400,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 570,
    d_global: 570,
    ix: 155,
    ix_global: 1550000,
    iy: 15119,
    iy_global: 151190000,
    iz: 131136,
    iz_global: 1311360000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 4463,
    z_global: 4463000,
    zx: 1030,
    zx_global: 1030000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 570,
    height_global: 570,
  },
  {
    profile_section_id: 471,
    name: "IM600300X010",
    type: "M Shape",
    designation: "IM600300X010",
    shape: "I",
    country_code: "Indian",
    ax: 227,
    ax_global: 22700,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 618,
    d_global: 618,
    ix: 185,
    ix_global: 1850000,
    iy: 10316,
    iy_global: 103160000,
    iz: 150938,
    iz_global: 1509380000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 4692,
    z_global: 4692000,
    zx: 971,
    zx_global: 971000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 618,
    height_global: 618,
  },
  {
    profile_section_id: 472,
    name: "IM600300X012",
    type: "M Shape",
    designation: "IM600300X012",
    shape: "I",
    country_code: "Indian",
    ax: 232,
    ax_global: 23200,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 620,
    d_global: 620,
    ix: 191,
    ix_global: 1910000,
    iy: 10576,
    iy_global: 105760000,
    iz: 156413,
    iz_global: 1564130000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 4822,
    z_global: 4822000,
    zx: 971,
    zx_global: 971000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 620,
    height_global: 620,
  },
  {
    profile_section_id: 473,
    name: "IM550350X016",
    type: "M Shape",
    designation: "IM550350X016",
    shape: "I",
    country_code: "Indian",
    ax: 237,
    ax_global: 23700,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 574,
    d_global: 574,
    ix: 180,
    ix_global: 1800000,
    iy: 16211,
    iy_global: 162110000,
    iz: 142237,
    iz_global: 1422370000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 4760,
    z_global: 4760000,
    zx: 1030,
    zx_global: 1030000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 574,
    height_global: 574,
  },
  {
    profile_section_id: 474,
    name: "IM600350X010",
    type: "M Shape",
    designation: "IM600350X010",
    shape: "I",
    country_code: "Indian",
    ax: 242,
    ax_global: 24200,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 618,
    d_global: 618,
    ix: 190,
    ix_global: 1900000,
    iy: 15390,
    iy_global: 153900000,
    iz: 163969,
    iz_global: 1639690000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 5122,
    z_global: 5122000,
    zx: 1144,
    zx_global: 1144000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 618,
    height_global: 618,
  },
  {
    profile_section_id: 475,
    name: "IM600300X016",
    type: "M Shape",
    designation: "IM600300X016",
    shape: "I",
    country_code: "Indian",
    ax: 242,
    ax_global: 24200,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 624,
    d_global: 624,
    ix: 211,
    ix_global: 2110000,
    iy: 11097,
    iy_global: 110970000,
    iz: 166872,
    iz_global: 1668720000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 5068,
    z_global: 5068000,
    zx: 971,
    zx_global: 971000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 624,
    height_global: 624,
  },
  {
    profile_section_id: 476,
    name: "IM600350X012",
    type: "M Shape",
    designation: "IM600350X012",
    shape: "I",
    country_code: "Indian",
    ax: 248,
    ax_global: 24800,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 620,
    d_global: 620,
    ix: 198,
    ix_global: 1980000,
    iy: 15936,
    iy_global: 159360000,
    iz: 170907,
    iz_global: 1709070000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 5291,
    z_global: 5291000,
    zx: 1144,
    zx_global: 1144000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 620,
    height_global: 620,
  },
  {
    profile_section_id: 477,
    name: "IM600400X010",
    type: "M Shape",
    designation: "IM600400X010",
    shape: "I",
    country_code: "Indian",
    ax: 251,
    ax_global: 25100,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 619,
    d_global: 619,
    ix: 199,
    ix_global: 1990000,
    iy: 20465,
    iy_global: 204650000,
    iz: 170352,
    iz_global: 1703520000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 5407,
    z_global: 5407000,
    zx: 1361,
    zx_global: 1361000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 619,
    height_global: 619,
  },
  {
    profile_section_id: 478,
    name: "IM600300X020",
    type: "M Shape",
    designation: "IM600300X020",
    shape: "I",
    country_code: "Indian",
    ax: 252,
    ax_global: 25200,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 628,
    d_global: 628,
    ix: 243,
    ix_global: 2430000,
    iy: 11618,
    iy_global: 116180000,
    iz: 176746,
    iz_global: 1767460000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 5297,
    z_global: 5297000,
    zx: 971,
    zx_global: 971000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 628,
    height_global: 628,
  },
  {
    profile_section_id: 479,
    name: "IM500350X1020",
    type: "M Shape",
    designation: "IM500350X1020",
    shape: "I",
    country_code: "Indian",
    ax: 253,
    ax_global: 25300,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 538,
    d_global: 538,
    ix: 193,
    ix_global: 1930000,
    iy: 18141,
    iy_global: 181410000,
    iz: 136749,
    iz_global: 1367490000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 5133,
    z_global: 5133000,
    zx: 1425,
    zx_global: 1425000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 538,
    height_global: 538,
  },
  {
    profile_section_id: 480,
    name: "IM600400X012",
    type: "M Shape",
    designation: "IM600400X012",
    shape: "I",
    country_code: "Indian",
    ax: 258,
    ax_global: 25800,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 621,
    d_global: 621,
    ix: 207,
    ix_global: 2070000,
    iy: 21011,
    iy_global: 210110000,
    iz: 177711,
    iz_global: 1777110000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 5590,
    z_global: 5590000,
    zx: 1361,
    zx_global: 1361000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 621,
    height_global: 621,
  },
  {
    profile_section_id: 481,
    name: "IM600350X016",
    type: "M Shape",
    designation: "IM600350X016",
    shape: "I",
    country_code: "Indian",
    ax: 261,
    ax_global: 26100,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 624,
    d_global: 624,
    ix: 223,
    ix_global: 2230000,
    iy: 17028,
    iy_global: 170280000,
    iz: 184002,
    iz_global: 1840020000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 5606,
    z_global: 5606000,
    zx: 1144,
    zx_global: 1144000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 624,
    height_global: 624,
  },
  {
    profile_section_id: 482,
    name: "IM600400X016",
    type: "M Shape",
    designation: "IM600400X016",
    shape: "I",
    country_code: "Indian",
    ax: 270,
    ax_global: 27000,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 625,
    d_global: 625,
    ix: 232,
    ix_global: 2320000,
    iy: 22103,
    iy_global: 221030000,
    iz: 191632,
    iz_global: 1916320000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 5933,
    z_global: 5933000,
    zx: 1361,
    zx_global: 1361000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 625,
    height_global: 625,
  },
  {
    profile_section_id: 483,
    name: "IM600350X020",
    type: "M Shape",
    designation: "IM600350X020",
    shape: "I",
    country_code: "Indian",
    ax: 274,
    ax_global: 27400,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 628,
    d_global: 628,
    ix: 265,
    ix_global: 2650000,
    iy: 18120,
    iy_global: 181200000,
    iz: 196179,
    iz_global: 1961790000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 5895,
    z_global: 5895000,
    zx: 1144,
    zx_global: 1144000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 628,
    height_global: 628,
  },
  {
    profile_section_id: 484,
    name: "IM500350X1225",
    type: "M Shape",
    designation: "IM500350X1225",
    shape: "I",
    country_code: "Indian",
    ax: 274,
    ax_global: 27400,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 545,
    d_global: 545,
    ix: 280,
    ix_global: 2800000,
    iy: 19767,
    iy_global: 197670000,
    iz: 152873,
    iz_global: 1528730000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 5671,
    z_global: 5671000,
    zx: 1534,
    zx_global: 1534000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 545,
    height_global: 545,
  },
  {
    profile_section_id: 485,
    name: "IM550350X1020",
    type: "M Shape",
    designation: "IM550350X1020",
    shape: "I",
    country_code: "Indian",
    ax: 275,
    ax_global: 27500,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 588,
    d_global: 588,
    ix: 230,
    ix_global: 2300000,
    iy: 18605,
    iy_global: 186050000,
    iz: 175306,
    iz_global: 1753060000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 5953,
    z_global: 5953000,
    zx: 1499,
    zx_global: 1499000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 588,
    height_global: 588,
  },
  {
    profile_section_id: 486,
    name: "IM600400X020",
    type: "M Shape",
    designation: "IM600400X020",
    shape: "I",
    country_code: "Indian",
    ax: 283,
    ax_global: 28300,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 629,
    d_global: 629,
    ix: 274,
    ix_global: 2740000,
    iy: 23195,
    iy_global: 231950000,
    iz: 204610,
    iz_global: 2046100000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 6249,
    z_global: 6249000,
    zx: 1361,
    zx_global: 1361000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 629,
    height_global: 629,
  },
  {
    profile_section_id: 487,
    name: "IM550350X1225",
    type: "M Shape",
    designation: "IM550350X1225",
    shape: "I",
    country_code: "Indian",
    ax: 296,
    ax_global: 29600,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 595,
    d_global: 595,
    ix: 317,
    ix_global: 3170000,
    iy: 20231,
    iy_global: 202310000,
    iz: 194504,
    iz_global: 1945040000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 6530,
    z_global: 6530000,
    zx: 1608,
    zx_global: 1608000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 595,
    height_global: 595,
  },
  {
    profile_section_id: 488,
    name: "IM600350X1020",
    type: "M Shape",
    designation: "IM600350X1020",
    shape: "I",
    country_code: "Indian",
    ax: 299,
    ax_global: 29900,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 638,
    d_global: 638,
    ix: 273,
    ix_global: 2730000,
    iy: 19422,
    iy_global: 194220000,
    iz: 222894,
    iz_global: 2228940000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 6905,
    z_global: 6905000,
    zx: 1613,
    zx_global: 1613000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 638,
    height_global: 638,
  },
  {
    profile_section_id: 489,
    name: "IM500350X1632",
    type: "M Shape",
    designation: "IM500350X1632",
    shape: "I",
    country_code: "Indian",
    ax: 307,
    ax_global: 30700,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 556,
    d_global: 556,
    ix: 483,
    ix_global: 4830000,
    iy: 22199,
    iy_global: 221990000,
    iz: 177878,
    iz_global: 1778780000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 6517,
    z_global: 6517000,
    zx: 1706,
    zx_global: 1706000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 556,
    height_global: 556,
  },
  {
    profile_section_id: 490,
    name: "IM600400X1020",
    type: "M Shape",
    designation: "IM600400X1020",
    shape: "I",
    country_code: "Indian",
    ax: 315,
    ax_global: 31500,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 639,
    d_global: 639,
    ix: 285,
    ix_global: 2850000,
    iy: 25926,
    iy_global: 259260000,
    iz: 235892,
    iz_global: 2358920000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 7433,
    z_global: 7433000,
    zx: 2129,
    zx_global: 2129000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 639,
    height_global: 639,
  },
  {
    profile_section_id: 491,
    name: "IM600350X1225",
    type: "M Shape",
    designation: "IM600350X1225",
    shape: "I",
    country_code: "Indian",
    ax: 320,
    ax_global: 32000,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 645,
    d_global: 645,
    ix: 361,
    ix_global: 3610000,
    iy: 21048,
    iy_global: 210480000,
    iz: 245424,
    iz_global: 2454240000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 7525,
    z_global: 7525000,
    zx: 1722,
    zx_global: 1722000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 645,
    height_global: 645,
  },
  {
    profile_section_id: 492,
    name: "IM550350X1632",
    type: "M Shape",
    designation: "IM550350X1632",
    shape: "I",
    country_code: "Indian",
    ax: 328,
    ax_global: 32800,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 606,
    d_global: 606,
    ix: 520,
    ix_global: 5200000,
    iy: 22663,
    iy_global: 226630000,
    iz: 224235,
    iz_global: 2242350000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 7447,
    z_global: 7447000,
    zx: 1780,
    zx_global: 1780000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 606,
    height_global: 606,
  },
  {
    profile_section_id: 493,
    name: "IM600400X1225",
    type: "M Shape",
    designation: "IM600400X1225",
    shape: "I",
    country_code: "Indian",
    ax: 338,
    ax_global: 33800,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 646,
    d_global: 646,
    ix: 374,
    ix_global: 3740000,
    iy: 27837,
    iy_global: 278370000,
    iz: 260866,
    iz_global: 2608660000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 8136,
    z_global: 8136000,
    zx: 2308,
    zx_global: 2308000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 646,
    height_global: 646,
  },
  {
    profile_section_id: 494,
    name: "IM500350X2040",
    type: "M Shape",
    designation: "IM500350X2040",
    shape: "I",
    country_code: "Indian",
    ax: 342,
    ax_global: 34200,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 568,
    d_global: 568,
    ix: 848,
    ix_global: 8480000,
    iy: 24905,
    iy_global: 249050000,
    iz: 205642,
    iz_global: 2056420000,
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 7436,
    z_global: 7436000,
    zx: 1894,
    zx_global: 1894000,
    width: 180,
    width_global: 180,
    thickness: 10,
    thickness_global: 10,
    height: 568,
    height_global: 568,
  },
  {
    profile_section_id: 495,
    name: "IM600350X1632",
    type: "M Shape",
    designation: "IM600350X1632",
    shape: "I",
    country_code: "Indian",
    ax: 352,
    ax_global: 35200,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 656,
    d_global: 656,
    ix: 563,
    ix_global: 5630000,
    iy: 23481,
    iy_global: 234810000,
    iz: 280291,
    iz_global: 2802910000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 8510,
    z_global: 8510000,
    zx: 1894,
    zx_global: 1894000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 656,
    height_global: 656,
  },
  {
    profile_section_id: 496,
    name: "IM550350X2040",
    type: "M Shape",
    designation: "IM550350X2040",
    shape: "I",
    country_code: "Indian",
    ax: 364,
    ax_global: 36400,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 618,
    d_global: 618,
    ix: 886,
    ix_global: 8860000,
    iy: 25369,
    iy_global: 253690000,
    iz: 257184,
    iz_global: 2571840000,
    t: 19,
    t_global: 19,
    tf: 11,
    tf_global: 11,
    z: 8440,
    z_global: 8440000,
    zx: 1967,
    zx_global: 1967000,
    width: 190,
    width_global: 190,
    thickness: 11,
    thickness_global: 11,
    height: 618,
    height_global: 618,
  },
  {
    profile_section_id: 497,
    name: "IM600400X1632",
    type: "M Shape",
    designation: "IM600400X1632",
    shape: "I",
    country_code: "Indian",
    ax: 373,
    ax_global: 37300,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 657,
    d_global: 657,
    ix: 582,
    ix_global: 5820000,
    iy: 30841,
    iy_global: 308410000,
    iz: 299883,
    iz_global: 2998830000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 9259,
    z_global: 9259000,
    zx: 2590,
    zx_global: 2590000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 657,
    height_global: 657,
  },
  {
    profile_section_id: 498,
    name: "IM600350X2040",
    type: "M Shape",
    designation: "IM600350X2040",
    shape: "I",
    country_code: "Indian",
    ax: 388,
    ax_global: 38800,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 668,
    d_global: 668,
    ix: 929,
    ix_global: 9290000,
    iy: 26186,
    iy_global: 261860000,
    iz: 318881,
    iz_global: 3188810000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 9578,
    z_global: 9578000,
    zx: 2082,
    zx_global: 2082000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 668,
    height_global: 668,
  },
  {
    profile_section_id: 499,
    name: "IM600400X2040",
    type: "M Shape",
    designation: "IM600400X2040",
    shape: "I",
    country_code: "Indian",
    ax: 411,
    ax_global: 41100,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 669,
    d_global: 669,
    ix: 957,
    ix_global: 9570000,
    iy: 0,
    iy_global: 0,
    iz: 343303,
    iz_global: 3433030000,
    t: 21,
    t_global: 21,
    tf: 12,
    tf_global: 12,
    z: 484,
    z_global: 484000,
    zx: 2897,
    zx_global: 2897000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 669,
    height_global: 669,
  },
  {
    profile_section_id: 637,
    name: "ISNT 20",
    type: "T Shape",
    designation: "ISNT 20",
    shape: "T",
    country_code: "Indian",
    ax: "1,47",
    ax_global: 147,
    bf: 20,
    bf_global: 20,
    ct: "0,646",
    ct_global: "6,46",
    d: 20,
    d_global: 20,
    ix: "0,106",
    ix_global: 1060,
    iy: "0,247",
    iy_global: 2470,
    iz: "0,501",
    iz_global: 5010,
    t: 4,
    t_global: 4,
    tf: 4,
    tf_global: 4,
    z: "0,675",
    z_global: 675,
    zx: "0,447",
    zx_global: 447,
  },
  {
    profile_section_id: 638,
    name: "ISNT 30",
    type: "T Shape",
    designation: "ISNT 30",
    shape: "T",
    country_code: "Indian",
    ax: "2,29",
    ax_global: 229,
    bf: 30,
    bf_global: 30,
    ct: "0,892",
    ct_global: "8,92",
    d: 30,
    d_global: 30,
    ix: "0,165",
    ix_global: 1650,
    iy: "0,813",
    iy_global: 8130,
    iz: "1,85",
    iz_global: 18500,
    t: 4,
    t_global: 4,
    tf: 4,
    tf_global: 4,
    z: "1,6",
    z_global: 1600,
    zx: "0,959",
    zx_global: 959,
  },
  {
    profile_section_id: 639,
    name: "ISNT 40",
    type: "T Shape",
    designation: "ISNT 40",
    shape: "T",
    country_code: "Indian",
    ax: "4,5",
    ax_global: 450,
    bf: 40,
    bf_global: 40,
    ct: "1,22",
    ct_global: "12,2",
    d: 40,
    d_global: 40,
    ix: "0,661",
    ix_global: 6610,
    iy: "3,02",
    iy_global: 30200,
    iz: "6,44",
    iz_global: 64400,
    t: 6,
    t_global: 6,
    tf: 6,
    tf_global: 6,
    z: "4,2",
    z_global: 4200,
    zx: "2,62",
    zx_global: 2620,
  },
  {
    profile_section_id: 640,
    name: "ISNT 50",
    type: "T Shape",
    designation: "ISNT 50",
    shape: "T",
    country_code: "Indian",
    ax: "5,72",
    ax_global: 572,
    bf: 50,
    bf_global: 50,
    ct: "1,46",
    ct_global: "14,6",
    d: 50,
    d_global: 50,
    ix: "0,829",
    ix_global: 8290,
    iy: "5,91",
    iy_global: 59100,
    iz: "13,1",
    iz_global: 131000,
    t: 6,
    t_global: 6,
    tf: 6,
    tf_global: 6,
    z: "6,72",
    z_global: 6720,
    zx: "4,03",
    zx_global: 4030,
  },
  {
    profile_section_id: 641,
    name: "ISNT 60",
    type: "T Shape",
    designation: "ISNT 60",
    shape: "T",
    country_code: "Indian",
    ax: "6,93",
    ax_global: 693,
    bf: 60,
    bf_global: 60,
    ct: "1,71",
    ct_global: "17,1",
    d: 60,
    d_global: 60,
    ix: "0,999",
    ix_global: 9990,
    iy: "10,1",
    iy_global: 101000,
    iz: "23,4",
    iz_global: 234000,
    t: 6,
    t_global: 6,
    tf: 6,
    tf_global: 6,
    z: "9,85",
    z_global: 9850,
    zx: "5,68",
    zx_global: 5680,
  },
  {
    profile_section_id: 642,
    name: "ISNT 75",
    type: "T Shape",
    designation: "ISNT 75",
    shape: "T",
    country_code: "Indian",
    ax: "12,8",
    ax_global: 1280,
    bf: 75,
    bf_global: 75,
    ct: "2,2",
    ct_global: 22,
    d: 75,
    d_global: 75,
    ix: "4,05",
    ix_global: 40500,
    iy: "30,2",
    iy_global: 302000,
    iz: "66,5",
    iz_global: 665000,
    t: 9,
    t_global: 9,
    tf: 9,
    tf_global: 9,
    z: "22,7",
    z_global: 22700,
    zx: "13,6",
    zx_global: 13600,
  },
  {
    profile_section_id: 643,
    name: "ISNT 100",
    type: "T Shape",
    designation: "ISNT 100",
    shape: "T",
    country_code: "Indian",
    ax: "19,2",
    ax_global: 1920,
    bf: 100,
    bf_global: 100,
    ct: "2,86",
    ct_global: "28,6",
    d: 100,
    d_global: 100,
    ix: "7,33",
    ix_global: 73300,
    iy: "79,6",
    iy_global: 796000,
    iz: 180,
    iz_global: 1800000,
    t: 10,
    t_global: 10,
    tf: 10,
    tf_global: 10,
    z: "45,5",
    z_global: 45500,
    zx: "26,6",
    zx_global: 26600,
  },
  {
    profile_section_id: 644,
    name: "ISNT 150",
    type: "T Shape",
    designation: "ISNT 150",
    shape: "T",
    country_code: "Indian",
    ax: "29,2",
    ax_global: 2920,
    bf: 150,
    bf_global: 150,
    ct: "4,1",
    ct_global: 41,
    d: 150,
    d_global: 150,
    ix: "10,9",
    ix_global: 109000,
    iy: 266,
    iy_global: 2660000,
    iz: 638,
    iz_global: 6380000,
    t: 10,
    t_global: 10,
    tf: 10,
    tf_global: 10,
    z: 106,
    z_global: 106000,
    zx: "57,8",
    zx_global: 57800,
  },
  {
    profile_section_id: 645,
    name: "ISDT 100",
    type: "T Shape",
    designation: "ISDT 100",
    shape: "T",
    country_code: "Indian",
    ax: "10,4",
    ax_global: 1040,
    bf: 50,
    bf_global: 50,
    ct: "3,03",
    ct_global: "30,3",
    d: 100,
    d_global: 100,
    ix: "2,69",
    ix_global: 26900,
    iy: "9,61",
    iy_global: 96100,
    iz: "99,8",
    iz_global: 998000,
    t: 10,
    t_global: 10,
    tf: "5,8",
    tf_global: "5,8",
    z: 26,
    z_global: 26000,
    zx: "6,72",
    zx_global: 6720,
  },
  {
    profile_section_id: 646,
    name: "ISDT 150",
    type: "T Shape",
    designation: "ISDT 150",
    shape: "T",
    country_code: "Indian",
    ax: 20,
    ax_global: 2000,
    bf: 75,
    bf_global: 75,
    ct: "4,75",
    ct_global: "47,5",
    d: 150,
    d_global: 150,
    ix: "7,47",
    ix_global: 74700,
    iy: 37,
    iy_global: 370000,
    iz: 453,
    iz_global: 4530000,
    t: "11,6",
    t_global: "11,6",
    tf: 8,
    tf_global: 8,
    z: 80,
    z_global: 80000,
    zx: "17,6",
    zx_global: 17600,
  },
  {
    profile_section_id: 647,
    name: "ISLT 200",
    type: "T Shape",
    designation: "ISLT 200",
    shape: "T",
    country_code: "Indian",
    ax: "36,2",
    ax_global: 3620,
    bf: 165,
    bf_global: 165,
    ct: "4,82",
    ct_global: "48,2",
    d: 200,
    d_global: 200,
    ix: "20,2",
    ix_global: 202000,
    iy: 358,
    iy_global: 3580000,
    iz: 1307,
    iz_global: 13070000,
    t: "12,5",
    t_global: "12,5",
    tf: 8,
    tf_global: 8,
    z: 154,
    z_global: 154000,
    zx: "75,7",
    zx_global: 75700,
  },
  {
    profile_section_id: 648,
    name: "ISLT 250",
    type: "T Shape",
    designation: "ISLT 250",
    shape: "T",
    country_code: "Indian",
    ax: "47,7",
    ax_global: 4770,
    bf: 180,
    bf_global: 180,
    ct: "6,44",
    ct_global: "64,4",
    d: 250,
    d_global: 250,
    ix: "32,2",
    ix_global: 322000,
    iy: 532,
    iy_global: 5320000,
    iz: 2836,
    iz_global: 28360000,
    t: "14,1",
    t_global: "14,1",
    tf: "9,2",
    tf_global: "9,2",
    z: 274,
    z_global: 274000,
    zx: 103,
    zx_global: 103000,
  },
  {
    profile_section_id: 649,
    name: "ISMT 50",
    type: "T Shape",
    designation: "ISMT 50",
    shape: "T",
    country_code: "Indian",
    ax: "7,35",
    ax_global: 735,
    bf: 70,
    bf_global: 70,
    ct: "1,06",
    ct_global: "10,6",
    d: 50,
    d_global: 50,
    ix: "1,58",
    ix_global: 15800,
    iy: "17,7",
    iy_global: 177000,
    iz: "11,8",
    iz_global: 118000,
    t: "7,5",
    t_global: "7,5",
    tf: "4,5",
    tf_global: "4,5",
    z: "5,84",
    z_global: 5840,
    zx: "8,47",
    zx_global: 8470,
  },
  {
    profile_section_id: 650,
    name: "ISMT 62.5",
    type: "T Shape",
    designation: "ISMT 62.5",
    shape: "T",
    country_code: "Indian",
    ax: "8,51",
    ax_global: 851,
    bf: 70,
    bf_global: 70,
    ct: "1,43",
    ct_global: "14,3",
    d: "62,5",
    d_global: "62,5",
    ix: "1,96",
    ix_global: 19600,
    iy: "19,2",
    iy_global: 192000,
    iz: "24,8",
    iz_global: 248000,
    t: 8,
    t_global: 8,
    tf: 5,
    tf_global: 5,
    z: "9,56",
    z_global: 9560,
    zx: "9,23",
    zx_global: 9230,
  },
  {
    profile_section_id: 651,
    name: "ISMT 75",
    type: "T Shape",
    designation: "ISMT 75",
    shape: "T",
    country_code: "Indian",
    ax: "9,54",
    ax_global: 954,
    bf: 75,
    bf_global: 75,
    ct: "1,74",
    ct_global: "17,4",
    d: 75,
    d_global: 75,
    ix: "2,14",
    ix_global: 21400,
    iy: "23,4",
    iy_global: 234000,
    iz: "42,9",
    iz_global: 429000,
    t: 8,
    t_global: 8,
    tf: 5,
    tf_global: 5,
    z: "13,6",
    z_global: 13600,
    zx: "10,5",
    zx_global: 10500,
  },
  {
    profile_section_id: 652,
    name: "ISMT 87.5",
    type: "T Shape",
    designation: "ISMT 87.5",
    shape: "T",
    country_code: "Indian",
    ax: "12,4",
    ax_global: 1240,
    bf: 85,
    bf_global: 85,
    ct: "2,08",
    ct_global: "20,8",
    d: "87,5",
    d_global: "87,5",
    ix: "3,52",
    ix_global: 35200,
    iy: "38,3",
    iy_global: 383000,
    iz: "78,3",
    iz_global: 783000,
    t: 9,
    t_global: 9,
    tf: "5,8",
    tf_global: "5,8",
    z: "21,3",
    z_global: 21300,
    zx: "15,3",
    zx_global: 15300,
  },
  {
    profile_section_id: 653,
    name: "ISMT 100",
    type: "T Shape",
    designation: "ISMT 100",
    shape: "T",
    country_code: "Indian",
    ax: "16,2",
    ax_global: 1620,
    bf: 100,
    bf_global: 100,
    ct: "2,15",
    ct_global: "21,5",
    d: 100,
    d_global: 100,
    ix: "6,24",
    ix_global: 62400,
    iy: 75,
    iy_global: 750000,
    iz: 121,
    iz_global: 1210000,
    t: "10,8",
    t_global: "10,8",
    tf: "5,7",
    tf_global: "5,7",
    z: "28,1",
    z_global: 28100,
    zx: 25,
    zx_global: 25000,
  },
  {
    profile_section_id: 654,
    name: "ISHT 75",
    type: "T Shape",
    designation: "ISHT 75",
    shape: "T",
    country_code: "Indian",
    ax: "19,2",
    ax_global: 1920,
    bf: 150,
    bf_global: 150,
    ct: "1,55",
    ct_global: "15,5",
    d: 75,
    d_global: 75,
    ix: "6,16",
    ix_global: 61600,
    iy: 218,
    iy_global: 2180000,
    iz: "76,1",
    iz_global: 761000,
    t: 9,
    t_global: 9,
    tf: "8,4",
    tf_global: "8,4",
    z: "23,6",
    z_global: 23600,
    zx: "47,4",
    zx_global: 47400,
  },
  {
    profile_section_id: 655,
    name: "ISHT 100",
    type: "T Shape",
    designation: "ISHT 100",
    shape: "T",
    country_code: "Indian",
    ax: "25,3",
    ax_global: 2530,
    bf: 200,
    bf_global: 200,
    ct: "1,88",
    ct_global: "18,8",
    d: 100,
    d_global: 100,
    ix: "8,18",
    ix_global: 81800,
    iy: 486,
    iy_global: 4860000,
    iz: 177,
    iz_global: 1770000,
    t: 9,
    t_global: 9,
    tf: "7,8",
    tf_global: "7,8",
    z: "39,5",
    z_global: 39500,
    zx: "80,5",
    zx_global: 80500,
  },
  {
    profile_section_id: 656,
    name: "ISHT 125",
    type: "T Shape",
    designation: "ISHT 125",
    shape: "T",
    country_code: "Indian",
    ax: "34,7",
    ax_global: 3470,
    bf: 250,
    bf_global: 250,
    ct: "2,35",
    ct_global: "23,5",
    d: 125,
    d_global: 125,
    ix: "13,6",
    ix_global: 136000,
    iy: 985,
    iy_global: 9850000,
    iz: 392,
    iz_global: 3920000,
    t: "9,7",
    t_global: "9,7",
    tf: "8,8",
    tf_global: "8,8",
    z: "69,2",
    z_global: 69200,
    zx: 132,
    zx_global: 132000,
  },
  {
    profile_section_id: 657,
    name: "ISHT 150",
    type: "T Shape",
    designation: "ISHT 150",
    shape: "T",
    country_code: "Indian",
    ax: "37,4",
    ax_global: 3740,
    bf: 250,
    bf_global: 250,
    ct: "2,69",
    ct_global: "26,9",
    d: 150,
    d_global: 150,
    ix: "15,7",
    ix_global: 157000,
    iy: 1097,
    iy_global: 10970000,
    iz: 597,
    iz_global: 5970000,
    t: "10,6",
    t_global: "10,6",
    tf: "7,6",
    tf_global: "7,6",
    z: "86,5",
    z_global: 86500,
    zx: 146,
    zx_global: 146000,
  },
  {
    profile_section_id: 1429,
    name: "ISLB75",
    type: "S Shape",
    designation: "ISLB75",
    shape: "I",
    country_code: "Indian",
    ax: "7,71",
    ax_global: 771,
    bf: 50,
    bf_global: 50,
    ct: 0,
    ct_global: 0,
    d: 75,
    d_global: 75,
    ix: "0,725",
    ix_global: 7250,
    iy: 10,
    iy_global: 100000,
    iz: 72,
    iz_global: 720000,
    r1: "6,5",
    r1_global: "6,5",
    r2: 2,
    r2_global: 2,
    t: 5,
    t_global: 5,
    tf: "3,7",
    tf_global: "3,7",
    z: "22,4",
    z_global: 22400,
    zx: "6,4",
    zx_global: 6400,
    width: 50,
    width_global: 50,
    thickness: "3,7",
    thickness_global: "3,7",
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 1430,
    name: "ISLB100",
    type: "S Shape",
    designation: "ISLB100",
    shape: "I",
    country_code: "Indian",
    ax: "10,2",
    ax_global: 1020,
    bf: 50,
    bf_global: 50,
    ct: 0,
    ct_global: 0,
    d: 100,
    d_global: 100,
    ix: "1,36",
    ix_global: 13600,
    iy: "12,7",
    iy_global: 127000,
    iz: 168,
    iz_global: 1680000,
    r1: 7,
    r1_global: 7,
    r2: 3,
    r2_global: 3,
    t: "6,4",
    t_global: "6,4",
    tf: 4,
    tf_global: 4,
    z: "38,9",
    z_global: 38900,
    zx: "8,2",
    zx_global: 8200,
    width: 50,
    width_global: 50,
    thickness: 4,
    thickness_global: 4,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1431,
    name: "ISLBP100",
    type: "S Shape",
    designation: "ISLBP100",
    shape: "I",
    country_code: "Indian",
    ax: "11,1",
    ax_global: 1110,
    bf: 50,
    bf_global: 50,
    ct: 0,
    ct_global: 0,
    d: 100,
    d_global: 100,
    ix: "1,83",
    ix_global: 18300,
    iy: 14,
    iy_global: 140000,
    iz: 182,
    iz_global: 1820000,
    r1: 8,
    r1_global: 8,
    r2: 3,
    r2_global: 3,
    t: 7,
    t_global: 7,
    tf: "4,3",
    tf_global: "4,3",
    z: "42,3",
    z_global: 42300,
    zx: "9,07",
    zx_global: 9070,
    width: 50,
    width_global: 50,
    thickness: "4,3",
    thickness_global: "4,3",
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1432,
    name: "ISMB100",
    type: "S Shape",
    designation: "ISMB100",
    shape: "I",
    country_code: "Indian",
    ax: "11,4",
    ax_global: 1140,
    bf: 50,
    bf_global: 50,
    ct: 0,
    ct_global: 0,
    d: 100,
    d_global: 100,
    ix: "2,12",
    ix_global: 21200,
    iy: "12,5",
    iy_global: 125000,
    iz: 182,
    iz_global: 1820000,
    r1: 9,
    r1_global: 9,
    r2: "4,5",
    r2_global: "4,5",
    tf: 7,
    tf_global: 7,
    tw: "4,7",
    tw_global: "4,7",
    z: "42,6",
    z_global: 42600,
    zx: "8,58",
    zx_global: 8580,
    width: 50,
    width_global: 50,
    thickness: "4,7",
    thickness_global: "4,7",
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1433,
    name: "ISSC100",
    type: "S Shape",
    designation: "ISSC100",
    shape: "I",
    country_code: "Indian",
    ax: "25,5",
    ax_global: 2550,
    bf: 100,
    bf_global: 100,
    ct: 0,
    ct_global: 0,
    d: 100,
    d_global: 100,
    ix: "10,5",
    ix_global: 105000,
    iy: 136,
    iy_global: 1360000,
    iz: 436,
    iz_global: 4360000,
    r1: 12,
    r1_global: 12,
    r2: 6,
    r2_global: 6,
    t: 10,
    t_global: 10,
    tf: 6,
    tf_global: 6,
    z: 101,
    z_global: 101000,
    zx: "45,2",
    zx_global: 45200,
    width: 100,
    width_global: 100,
    thickness: 6,
    thickness_global: 6,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 1434,
    name: "ISSC120",
    type: "S Shape",
    designation: "ISSC120",
    shape: "I",
    country_code: "Indian",
    ax: "33,4",
    ax_global: 3340,
    bf: 120,
    bf_global: 120,
    ct: 0,
    ct_global: 0,
    d: 120,
    d_global: 120,
    ix: "16,2",
    ix_global: 162000,
    iy: 255,
    iy_global: 2550000,
    iz: 842,
    iz_global: 8420000,
    r1: 12,
    r1_global: 12,
    r2: 6,
    r2_global: 6,
    t: 11,
    t_global: 11,
    tf: "6,5",
    tf_global: "6,5",
    z: 140,
    z_global: 140000,
    zx: "70,9",
    zx_global: 70900,
    width: 120,
    width_global: 120,
    thickness: "6,5",
    thickness_global: "6,5",
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 1435,
    name: "ISLB125",
    type: "S Shape",
    designation: "ISLB125",
    shape: "I",
    country_code: "Indian",
    ax: "15,1",
    ax_global: 1510,
    bf: 75,
    bf_global: 75,
    ct: 0,
    ct_global: 0,
    d: 125,
    d_global: 125,
    ix: "2,17",
    ix_global: 21700,
    iy: "43,4",
    iy_global: 434000,
    iz: 407,
    iz_global: 4070000,
    r1: 8,
    r1_global: 8,
    r2: 3,
    r2_global: 3,
    t: "6,5",
    t_global: "6,5",
    tf: "4,4",
    tf_global: "4,4",
    z: "73,9",
    z_global: 73900,
    zx: "18,4",
    zx_global: 18400,
    width: 75,
    width_global: 75,
    thickness: "4,4",
    thickness_global: "4,4",
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 1436,
    name: "ISMB125",
    type: "S Shape",
    designation: "ISMB125",
    shape: "I",
    country_code: "Indian",
    ax: 17,
    ax_global: 1700,
    bf: 70,
    bf_global: 70,
    ct: 0,
    ct_global: 0,
    d: 125,
    d_global: 125,
    ix: "3,93",
    ix_global: 39300,
    iy: "38,5",
    iy_global: 385000,
    iz: 445,
    iz_global: 4450000,
    r1: 9,
    r1_global: 9,
    r2: "4,5",
    r2_global: "4,5",
    tf: 8,
    tf_global: 8,
    tw: 5,
    tw_global: 5,
    z: "82,1",
    z_global: 82100,
    zx: "18,5",
    zx_global: 18500,
    width: 70,
    width_global: 70,
    thickness: 5,
    thickness_global: 5,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 1437,
    name: "ISSC140",
    type: "S Shape",
    designation: "ISSC140",
    shape: "I",
    country_code: "Indian",
    ax: "42,4",
    ax_global: 4240,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 140,
    d_global: 140,
    ix: "24,2",
    ix_global: 242000,
    iy: 438,
    iy_global: 4380000,
    iz: 1475,
    iz_global: 14750000,
    r1: 12,
    r1_global: 12,
    r2: 6,
    r2_global: 6,
    t: 12,
    t_global: 12,
    tf: 7,
    tf_global: 7,
    z: 241,
    z_global: 241000,
    zx: 104,
    zx_global: 104000,
    width: 140,
    width_global: 140,
    thickness: 7,
    thickness_global: 7,
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 1438,
    name: "ISHB150",
    type: "S Shape",
    designation: "ISHB150",
    shape: "I",
    country_code: "Indian",
    ax: "34,5",
    ax_global: 3450,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 150,
    d_global: 150,
    ix: "9,77",
    ix_global: 97700,
    iy: 432,
    iy_global: 4320000,
    iz: 1456,
    iz_global: 14560000,
    r1: 8,
    r1_global: 8,
    r2: 4,
    r2_global: 4,
    t: 9,
    t_global: 9,
    tf: "5,4",
    tf_global: "5,4",
    z: 216,
    z_global: 216000,
    zx: "92,7",
    zx_global: 92700,
    width: 150,
    width_global: 150,
    thickness: "5,4",
    thickness_global: "5,4",
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1439,
    name: "ISHB150A",
    type: "S Shape",
    designation: "ISHB150A",
    shape: "I",
    country_code: "Indian",
    ax: "38,4",
    ax_global: 3840,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 150,
    d_global: 150,
    ix: "12,4",
    ix_global: 124000,
    iy: 435,
    iy_global: 4350000,
    iz: 1513,
    iz_global: 15130000,
    r1: 8,
    r1_global: 8,
    r2: 4,
    r2_global: 4,
    t: 9,
    t_global: 9,
    tf: "8,4",
    tf_global: "8,4",
    z: 229,
    z_global: 229000,
    zx: "94,7",
    zx_global: 94700,
    width: 150,
    width_global: 150,
    thickness: "8,4",
    thickness_global: "8,4",
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1440,
    name: "ISHB150B",
    type: "S Shape",
    designation: "ISHB150B",
    shape: "I",
    country_code: "Indian",
    ax: "42,9",
    ax_global: 4290,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 150,
    d_global: 150,
    ix: 18,
    ix_global: 180000,
    iy: 440,
    iy_global: 4400000,
    iz: 1579,
    iz_global: 15790000,
    r1: 8,
    r1_global: 8,
    r2: 4,
    r2_global: 4,
    t: 9,
    t_global: 9,
    tf: "11,8",
    tf_global: "11,8",
    z: 244,
    z_global: 244000,
    zx: "97,6",
    zx_global: 97600,
    width: 150,
    width_global: 150,
    thickness: "11,8",
    thickness_global: "11,8",
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1441,
    name: "ISJB150",
    type: "S Shape",
    designation: "ISJB150",
    shape: "I",
    country_code: "Indian",
    ax: "9,01",
    ax_global: 901,
    bf: 50,
    bf_global: 50,
    ct: 0,
    ct_global: 0,
    d: 150,
    d_global: 150,
    ix: "0,54",
    ix_global: 5400,
    iy: "9,22",
    iy_global: 92200,
    iz: 322,
    iz_global: 3220000,
    r1: 5,
    r1_global: 5,
    r2: "1,5",
    r2_global: "1,5",
    t: "4,6",
    t_global: "4,6",
    tf: 3,
    tf_global: 3,
    z: "49,6",
    z_global: 49600,
    zx: "5,96",
    zx_global: 5960,
    width: 50,
    width_global: 50,
    thickness: 3,
    thickness_global: 3,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1442,
    name: "ISLB150",
    type: "S Shape",
    designation: "ISLB150",
    shape: "I",
    country_code: "Indian",
    ax: "18,1",
    ax_global: 1810,
    bf: 80,
    bf_global: 80,
    ct: 0,
    ct_global: 0,
    d: 150,
    d_global: 150,
    ix: "2,96",
    ix_global: 29600,
    iy: "55,2",
    iy_global: 552000,
    iz: 688,
    iz_global: 6880000,
    r1: "9,5",
    r1_global: "9,5",
    r2: 3,
    r2_global: 3,
    t: "6,8",
    t_global: "6,8",
    tf: "4,8",
    tf_global: "4,8",
    z: 105,
    z_global: 105000,
    zx: "22,1",
    zx_global: 22100,
    width: 80,
    width_global: 80,
    thickness: "4,8",
    thickness_global: "4,8",
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1443,
    name: "ISMB150",
    type: "S Shape",
    designation: "ISMB150",
    shape: "I",
    country_code: "Indian",
    ax: "19,1",
    ax_global: 1910,
    bf: 75,
    bf_global: 75,
    ct: 0,
    ct_global: 0,
    d: 150,
    d_global: 150,
    ix: "4,29",
    ix_global: 42900,
    iy: "46,8",
    iy_global: 468000,
    iz: 718,
    iz_global: 7180000,
    r1: 9,
    r1_global: 9,
    r2: "4,5",
    r2_global: "4,5",
    tf: 8,
    tf_global: 8,
    tw: 5,
    tw_global: 5,
    z: 110,
    z_global: 110000,
    zx: "21,1",
    zx_global: 21100,
    width: 75,
    width_global: 75,
    thickness: 5,
    thickness_global: 5,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1444,
    name: "ISWB150",
    type: "S Shape",
    designation: "ISWB150",
    shape: "I",
    country_code: "Indian",
    ax: "21,7",
    ax_global: 2170,
    bf: 100,
    bf_global: 100,
    ct: 0,
    ct_global: 0,
    d: 150,
    d_global: 150,
    ix: "4,13",
    ix_global: 41300,
    iy: "94,8",
    iy_global: 948000,
    iz: 839,
    iz_global: 8390000,
    r1: 8,
    r1_global: 8,
    r2: 4,
    r2_global: 4,
    t: 7,
    t_global: 7,
    tf: "5,4",
    tf_global: "5,4",
    z: 127,
    z_global: 127000,
    zx: "31,9",
    zx_global: 31900,
    width: 100,
    width_global: 100,
    thickness: "5,4",
    thickness_global: "5,4",
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 1445,
    name: "ISSC150H",
    type: "S Shape",
    designation: "ISSC150H",
    shape: "I",
    country_code: "Indian",
    ax: "47,1",
    ax_global: 4710,
    bf: 152,
    bf_global: 152,
    ct: 0,
    ct_global: 0,
    d: 152,
    d_global: 152,
    ix: "27,3",
    ix_global: 273000,
    iy: 554,
    iy_global: 5540000,
    iz: 1927,
    iz_global: 19270000,
    r1: "11,7",
    r1_global: "11,7",
    r2: 3,
    r2_global: 3,
    t: "11,9",
    t_global: "11,9",
    tf: "7,9",
    tf_global: "7,9",
    z: 289,
    z_global: 289000,
    zx: 122,
    zx_global: 122000,
    width: 152,
    width_global: 152,
    thickness: "7,9",
    thickness_global: "7,9",
    height: 152,
    height_global: 152,
  },
  {
    profile_section_id: 1446,
    name: "ISSC160",
    type: "S Shape",
    designation: "ISSC160",
    shape: "I",
    country_code: "Indian",
    ax: "53,4",
    ax_global: 5340,
    bf: 160,
    bf_global: 160,
    ct: 0,
    ct_global: 0,
    d: 160,
    d_global: 160,
    ix: "37,5",
    ix_global: 375000,
    iy: 695,
    iy_global: 6950000,
    iz: 2424,
    iz_global: 24240000,
    r1: 15,
    r1_global: 15,
    r2: "7,5",
    r2_global: "7,5",
    t: 13,
    t_global: 13,
    tf: 8,
    tf_global: 8,
    z: 346,
    z_global: 346000,
    zx: 146,
    zx_global: 146000,
    width: 160,
    width_global: 160,
    thickness: 8,
    thickness_global: 8,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 1447,
    name: "ISJB175",
    type: "S Shape",
    designation: "ISJB175",
    shape: "I",
    country_code: "Indian",
    ax: "10,3",
    ax_global: 1030,
    bf: 50,
    bf_global: 50,
    ct: 0,
    ct_global: 0,
    d: 175,
    d_global: 175,
    ix: "0,647",
    ix_global: 6470,
    iy: "9,65",
    iy_global: 96500,
    iz: 480,
    iz_global: 4800000,
    r1: 5,
    r1_global: 5,
    r2: "1,5",
    r2_global: "1,5",
    t: "4,8",
    t_global: "4,8",
    tf: "3,2",
    tf_global: "3,2",
    z: "64,2",
    z_global: 64200,
    zx: "6,32",
    zx_global: 6320,
    width: 50,
    width_global: 50,
    thickness: "3,2",
    thickness_global: "3,2",
    height: 175,
    height_global: 175,
  },
  {
    profile_section_id: 1448,
    name: "ISLB175",
    type: "S Shape",
    designation: "ISLB175",
    shape: "I",
    country_code: "Indian",
    ax: "21,3",
    ax_global: 2130,
    bf: 90,
    bf_global: 90,
    ct: 0,
    ct_global: 0,
    d: 175,
    d_global: 175,
    ix: "3,53",
    ix_global: 35300,
    iy: "79,6",
    iy_global: 796000,
    iz: 1095,
    iz_global: 10950000,
    r1: "9,5",
    r1_global: "9,5",
    r2: 3,
    r2_global: 3,
    t: "6,9",
    t_global: "6,9",
    tf: "5,1",
    tf_global: "5,1",
    z: 143,
    z_global: 143000,
    zx: "28,3",
    zx_global: 28300,
    width: 90,
    width_global: 90,
    thickness: "5,1",
    thickness_global: "5,1",
    height: 175,
    height_global: 175,
  },
  {
    profile_section_id: 1449,
    name: "ISLBP175",
    type: "S Shape",
    designation: "ISLBP175",
    shape: "I",
    country_code: "Indian",
    ax: "21,2",
    ax_global: 2120,
    bf: 80,
    bf_global: 80,
    ct: 0,
    ct_global: 0,
    d: 175,
    d_global: 175,
    ix: "4,36",
    ix_global: 43600,
    iy: "57,3",
    iy_global: 573000,
    iz: 1068,
    iz_global: 10680000,
    r1: "9,5",
    r1_global: "9,5",
    r2: 3,
    r2_global: 3,
    t: "7,7",
    t_global: "7,7",
    tf: "5,2",
    tf_global: "5,2",
    z: 140,
    z_global: 140000,
    zx: "23,9",
    zx_global: 23900,
    width: 80,
    width_global: 80,
    thickness: "5,2",
    thickness_global: "5,2",
    height: 175,
    height_global: 175,
  },
  {
    profile_section_id: 1450,
    name: "ISMB175",
    type: "S Shape",
    designation: "ISMB175",
    shape: "I",
    country_code: "Indian",
    ax: "24,9",
    ax_global: 2490,
    bf: 85,
    bf_global: 85,
    ct: 0,
    ct_global: 0,
    d: 175,
    d_global: 175,
    ix: "7,06",
    ix_global: 70600,
    iy: "76,7",
    iy_global: 767000,
    iz: 1262,
    iz_global: 12620000,
    r1: 10,
    r1_global: 10,
    r2: 5,
    r2_global: 5,
    tf: 9,
    tf_global: 9,
    tw: "5,8",
    tw_global: "5,8",
    z: 166,
    z_global: 166000,
    zx: "30,6",
    zx_global: 30600,
    width: 85,
    width_global: 85,
    thickness: "5,8",
    thickness_global: "5,8",
    height: 175,
    height_global: 175,
  },
  {
    profile_section_id: 1451,
    name: "ISWB175",
    type: "S Shape",
    designation: "ISWB175",
    shape: "I",
    country_code: "Indian",
    ax: "28,1",
    ax_global: 2810,
    bf: 125,
    bf_global: 125,
    ct: 0,
    ct_global: 0,
    d: 175,
    d_global: 175,
    ix: "6,13",
    ix_global: 61300,
    iy: 189,
    iy_global: 1890000,
    iz: 1509,
    iz_global: 15090000,
    r1: 8,
    r1_global: 8,
    r2: 4,
    r2_global: 4,
    t: "7,4",
    t_global: "7,4",
    tf: "5,8",
    tf_global: "5,8",
    z: 194,
    z_global: 194000,
    zx: "51,3",
    zx_global: 51300,
    width: 125,
    width_global: 125,
    thickness: "5,8",
    thickness_global: "5,8",
    height: 175,
    height_global: 175,
  },
  {
    profile_section_id: 1452,
    name: "ISSC180",
    type: "S Shape",
    designation: "ISSC180",
    shape: "I",
    country_code: "Indian",
    ax: "64,4",
    ax_global: 6440,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 180,
    d_global: 180,
    ix: "51,9",
    ix_global: 519000,
    iy: 1057,
    iy_global: 10570000,
    iz: 3737,
    iz_global: 37370000,
    r1: 15,
    r1_global: 15,
    r2: "7,5",
    r2_global: "7,5",
    t: 14,
    t_global: 14,
    tf: "8,5",
    tf_global: "8,5",
    z: 472,
    z_global: 472000,
    zx: 198,
    zx_global: 198000,
    width: 180,
    width_global: 180,
    thickness: "8,5",
    thickness_global: "8,5",
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 1453,
    name: "ISHB200",
    type: "S Shape",
    designation: "ISHB200",
    shape: "I",
    country_code: "Indian",
    ax: "47,5",
    ax_global: 4750,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 200,
    d_global: 200,
    ix: "14,4",
    ix_global: 144000,
    iy: 967,
    iy_global: 9670000,
    iz: 3609,
    iz_global: 36090000,
    r1: 9,
    r1_global: 9,
    r2: "4,5",
    r2_global: "4,5",
    t: 9,
    t_global: 9,
    tf: "6,1",
    tf_global: "6,1",
    z: 397,
    z_global: 397000,
    zx: 159,
    zx_global: 159000,
    width: 200,
    width_global: 200,
    thickness: "6,1",
    thickness_global: "6,1",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1454,
    name: "ISHB200H",
    type: "S Shape",
    designation: "ISHB200H",
    shape: "I",
    country_code: "Indian",
    ax: "50,6",
    ax_global: 5060,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 200,
    d_global: 200,
    ix: "16,4",
    ix_global: 164000,
    iy: 972,
    iy_global: 9720000,
    iz: 3695,
    iz_global: 36950000,
    r1: 9,
    r1_global: 9,
    r2: "4,5",
    r2_global: "4,5",
    t: 9,
    t_global: 9,
    tf: "7,8",
    tf_global: "7,8",
    z: 411,
    z_global: 411000,
    zx: 161,
    zx_global: 161000,
    width: 200,
    width_global: 200,
    thickness: "7,8",
    thickness_global: "7,8",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1455,
    name: "ISJB200",
    type: "S Shape",
    designation: "ISJB200",
    shape: "I",
    country_code: "Indian",
    ax: "12,6",
    ax_global: 1260,
    bf: 60,
    bf_global: 60,
    ct: 0,
    ct_global: 0,
    d: 200,
    d_global: 200,
    ix: "0,862",
    ix_global: 8620,
    iy: "17,3",
    iy_global: 173000,
    iz: 781,
    iz_global: 7810000,
    r1: 5,
    r1_global: 5,
    r2: "1,5",
    r2_global: "1,5",
    t: 5,
    t_global: 5,
    tf: "3,4",
    tf_global: "3,4",
    z: "90,9",
    z_global: 90900,
    zx: "9,35",
    zx_global: 9350,
    width: 60,
    width_global: 60,
    thickness: "3,4",
    thickness_global: "3,4",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1456,
    name: "ISLB200",
    type: "S Shape",
    designation: "ISLB200",
    shape: "I",
    country_code: "Indian",
    ax: "25,3",
    ax_global: 2530,
    bf: 100,
    bf_global: 100,
    ct: 0,
    ct_global: 0,
    d: 200,
    d_global: 200,
    ix: "4,53",
    ix_global: 45300,
    iy: 115,
    iy_global: 1150000,
    iz: 1695,
    iz_global: 16950000,
    r1: "9,5",
    r1_global: "9,5",
    r2: 3,
    r2_global: 3,
    t: "7,3",
    t_global: "7,3",
    tf: "5,4",
    tf_global: "5,4",
    z: 193,
    z_global: 193000,
    zx: "36,9",
    zx_global: 36900,
    width: 100,
    width_global: 100,
    thickness: "5,4",
    thickness_global: "5,4",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1457,
    name: "ISMB200",
    type: "S Shape",
    designation: "ISMB200",
    shape: "I",
    country_code: "Indian",
    ax: "30,8",
    ax_global: 3080,
    bf: 100,
    bf_global: 100,
    ct: 0,
    ct_global: 0,
    d: 200,
    d_global: 200,
    ix: "10,6",
    ix_global: 106000,
    iy: 137,
    iy_global: 1370000,
    iz: 2115,
    iz_global: 21150000,
    r1: 11,
    r1_global: 11,
    r2: "5,5",
    r2_global: "5,5",
    tf: 10,
    tf_global: 10,
    tw: "5,7",
    tw_global: "5,7",
    z: 240,
    z_global: 240000,
    zx: 46,
    zx_global: 46000,
    width: 100,
    width_global: 100,
    thickness: "5,7",
    thickness_global: "5,7",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1458,
    name: "ISSC200",
    type: "S Shape",
    designation: "ISSC200",
    shape: "I",
    country_code: "Indian",
    ax: "76,8",
    ax_global: 7680,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 200,
    d_global: 200,
    ix: "73,3",
    ix_global: 733000,
    iy: 1528,
    iy_global: 15280000,
    iz: 5535,
    iz_global: 55350000,
    r1: 18,
    r1_global: 18,
    r2: 9,
    r2_global: 9,
    t: 15,
    t_global: 15,
    tf: 9,
    tf_global: 9,
    z: 628,
    z_global: 628000,
    zx: 259,
    zx_global: 259000,
    width: 200,
    width_global: 200,
    thickness: 9,
    thickness_global: 9,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1459,
    name: "ISWB200",
    type: "S Shape",
    designation: "ISWB200",
    shape: "I",
    country_code: "Indian",
    ax: "36,7",
    ax_global: 3670,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 200,
    d_global: 200,
    ix: 11,
    ix_global: 110000,
    iy: 329,
    iy_global: 3290000,
    iz: 2624,
    iz_global: 26240000,
    r1: 9,
    r1_global: 9,
    r2: "4,5",
    r2_global: "4,5",
    t: 9,
    t_global: 9,
    tf: "6,1",
    tf_global: "6,1",
    z: 294,
    z_global: 294000,
    zx: "78,7",
    zx_global: 78700,
    width: 140,
    width_global: 140,
    thickness: "6,1",
    thickness_global: "6,1",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 1460,
    name: "ISWB200H",
    type: "S Shape",
    designation: "ISWB200H",
    shape: "I",
    country_code: "Indian",
    ax: "66,4",
    ax_global: 6640,
    bf: 152,
    bf_global: 152,
    ct: 0,
    ct_global: 0,
    d: 203,
    d_global: 203,
    ix: "64,7",
    ix_global: 647000,
    iy: 810,
    iy_global: 8100000,
    iz: 4776,
    iz_global: 47760000,
    r1: "15,5",
    r1_global: "15,5",
    r2: "7,6",
    r2_global: "7,6",
    t: "16,5",
    t_global: "16,5",
    tf: "8,9",
    tf_global: "8,9",
    z: 539,
    z_global: 539000,
    zx: 175,
    zx_global: 175000,
    width: 152,
    width_global: 152,
    thickness: "8,9",
    thickness_global: "8,9",
    height: 203,
    height_global: 203,
  },
  {
    profile_section_id: 1461,
    name: "ISLBP200",
    type: "S Shape",
    designation: "ISLBP200",
    shape: "I",
    country_code: "Indian",
    ax: 28,
    ax_global: 2800,
    bf: 100,
    bf_global: 100,
    ct: 0,
    ct_global: 0,
    d: 220,
    d_global: 220,
    ix: "6,21",
    ix_global: 62100,
    iy: 113,
    iy_global: 1130000,
    iz: 2242,
    iz_global: 22420000,
    r1: "9,5",
    r1_global: "9,5",
    r2: 3,
    r2_global: 3,
    t: 8,
    t_global: 8,
    tf: "5,6",
    tf_global: "5,6",
    z: 233,
    z_global: 233000,
    zx: "37,9",
    zx_global: 37900,
    width: 100,
    width_global: 100,
    thickness: "5,6",
    thickness_global: "5,6",
    height: 220,
    height_global: 220,
  },
  {
    profile_section_id: 1462,
    name: "ISSC220",
    type: "S Shape",
    designation: "ISSC220",
    shape: "I",
    country_code: "Indian",
    ax: "89,8",
    ax_global: 8980,
    bf: 220,
    bf_global: 220,
    ct: 0,
    ct_global: 0,
    d: 220,
    d_global: 220,
    ix: "96,5",
    ix_global: 965000,
    iy: 2157,
    iy_global: 21570000,
    iz: 7880,
    iz_global: 78800000,
    r1: 18,
    r1_global: 18,
    r2: 9,
    r2_global: 9,
    t: 16,
    t_global: 16,
    tf: "9,5",
    tf_global: "9,5",
    z: 810,
    z_global: 810000,
    zx: 333,
    zx_global: 333000,
    width: 220,
    width_global: 220,
    thickness: "9,5",
    thickness_global: "9,5",
    height: 220,
    height_global: 220,
  },
  {
    profile_section_id: 1463,
    name: "ISHB225",
    type: "S Shape",
    designation: "ISHB225",
    shape: "I",
    country_code: "Indian",
    ax: "54,9",
    ax_global: 5490,
    bf: 225,
    bf_global: 225,
    ct: 0,
    ct_global: 0,
    d: 225,
    d_global: 225,
    ix: "17,8",
    ix_global: 178000,
    iy: 1354,
    iy_global: 13540000,
    iz: 5280,
    iz_global: 52800000,
    r1: 10,
    r1_global: 10,
    r2: 5,
    r2_global: 5,
    t: "9,1",
    t_global: "9,1",
    tf: "6,5",
    tf_global: "6,5",
    z: 516,
    z_global: 516000,
    zx: 200,
    zx_global: 200000,
    width: 225,
    width_global: 225,
    thickness: "6,5",
    thickness_global: "6,5",
    height: 225,
    height_global: 225,
  },
  {
    profile_section_id: 1464,
    name: "ISHB225H",
    type: "S Shape",
    designation: "ISHB225H",
    shape: "I",
    country_code: "Indian",
    ax: "59,3",
    ax_global: 5930,
    bf: 225,
    bf_global: 225,
    ct: 0,
    ct_global: 0,
    d: 225,
    d_global: 225,
    ix: 21,
    ix_global: 210000,
    iy: 1362,
    iy_global: 13620000,
    iz: 5436,
    iz_global: 54360000,
    r1: 10,
    r1_global: 10,
    r2: 5,
    r2_global: 5,
    t: "9,1",
    t_global: "9,1",
    tf: "8,6",
    tf_global: "8,6",
    z: 538,
    z_global: 538000,
    zx: 203,
    zx_global: 203000,
    width: 225,
    width_global: 225,
    thickness: "8,6",
    thickness_global: "8,6",
    height: 225,
    height_global: 225,
  },
  {
    profile_section_id: 1465,
    name: "ISJB225",
    type: "S Shape",
    designation: "ISJB225",
    shape: "I",
    country_code: "Indian",
    ax: "16,3",
    ax_global: 1630,
    bf: 80,
    bf_global: 80,
    ct: 0,
    ct_global: 0,
    d: 225,
    d_global: 225,
    ix: "1,25",
    ix_global: 12500,
    iy: "40,5",
    iy_global: 405000,
    iz: 1310,
    iz_global: 13100000,
    r1: "6,5",
    r1_global: "6,5",
    r2: "1,5",
    r2_global: "1,5",
    t: 5,
    t_global: 5,
    tf: "3,7",
    tf_global: "3,7",
    z: 134,
    z_global: 134000,
    zx: "16,3",
    zx_global: 16300,
    width: 80,
    width_global: 80,
    thickness: "3,7",
    thickness_global: "3,7",
    height: 225,
    height_global: 225,
  },
  {
    profile_section_id: 1466,
    name: "ISLB225",
    type: "S Shape",
    designation: "ISLB225",
    shape: "I",
    country_code: "Indian",
    ax: "29,9",
    ax_global: 2990,
    bf: 100,
    bf_global: 100,
    ct: 0,
    ct_global: 0,
    d: 225,
    d_global: 225,
    ix: "8,33",
    ix_global: 83300,
    iy: 113,
    iy_global: 1130000,
    iz: 2501,
    iz_global: 25010000,
    r1: 12,
    r1_global: 12,
    r2: 6,
    r2_global: 6,
    t: "8,6",
    t_global: "8,6",
    tf: "5,8",
    tf_global: "5,8",
    z: 255,
    z_global: 255000,
    zx: "39,2",
    zx_global: 39200,
    width: 100,
    width_global: 100,
    thickness: "5,8",
    thickness_global: "5,8",
    height: 225,
    height_global: 225,
  },
  {
    profile_section_id: 1467,
    name: "ISMB225",
    type: "S Shape",
    designation: "ISMB225",
    shape: "I",
    country_code: "Indian",
    ax: "39,7",
    ax_global: 3970,
    bf: 110,
    bf_global: 110,
    ct: 0,
    ct_global: 0,
    d: 225,
    d_global: 225,
    ix: "18,3",
    ix_global: 183000,
    iy: 218,
    iy_global: 2180000,
    iz: 3442,
    iz_global: 34420000,
    r1: 12,
    r1_global: 12,
    r2: 6,
    r2_global: 6,
    tf: "11,8",
    tf_global: "11,8",
    tw: "6,5",
    tw_global: "6,5",
    z: 348,
    z_global: 348000,
    zx: "66,3",
    zx_global: 66300,
    width: 110,
    width_global: 110,
    thickness: "6,5",
    thickness_global: "6,5",
    height: 225,
    height_global: 225,
  },
  {
    profile_section_id: 1468,
    name: "ISWB225",
    type: "S Shape",
    designation: "ISWB225",
    shape: "I",
    country_code: "Indian",
    ax: "43,2",
    ax_global: 4320,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 225,
    d_global: 225,
    ix: "15,1",
    ix_global: 151000,
    iy: 449,
    iy_global: 4490000,
    iz: 3920,
    iz_global: 39200000,
    r1: 9,
    r1_global: 9,
    r2: "4,5",
    r2_global: "4,5",
    t: "9,9",
    t_global: "9,9",
    tf: "6,4",
    tf_global: "6,4",
    z: 390,
    z_global: 390000,
    zx: "99,8",
    zx_global: 99800,
    width: 150,
    width_global: 150,
    thickness: "6,4",
    thickness_global: "6,4",
    height: 225,
    height_global: 225,
  },
  {
    profile_section_id: 1469,
    name: "ISHB250",
    type: "S Shape",
    designation: "ISHB250",
    shape: "I",
    country_code: "Indian",
    ax: 65,
    ax_global: 6500,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 250,
    d_global: 250,
    ix: "23,8",
    ix_global: 238000,
    iy: 1961,
    iy_global: 19610000,
    iz: 7737,
    iz_global: 77370000,
    r1: 10,
    r1_global: 10,
    r2: 5,
    r2_global: 5,
    t: "9,7",
    t_global: "9,7",
    tf: "6,9",
    tf_global: "6,9",
    z: 679,
    z_global: 679000,
    zx: 262,
    zx_global: 262000,
    width: 250,
    width_global: 250,
    thickness: "6,9",
    thickness_global: "6,9",
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 1470,
    name: "ISHB250H",
    type: "S Shape",
    designation: "ISHB250H",
    shape: "I",
    country_code: "Indian",
    ax: "69,3",
    ax_global: 6930,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 250,
    d_global: 250,
    ix: "27,3",
    ix_global: 273000,
    iy: 1971,
    iy_global: 19710000,
    iz: 7932,
    iz_global: 79320000,
    r1: 10,
    r1_global: 10,
    r2: 5,
    r2_global: 5,
    t: "9,7",
    t_global: "9,7",
    tf: "8,8",
    tf_global: "8,8",
    z: 704,
    z_global: 704000,
    zx: 265,
    zx_global: 265000,
    width: 250,
    width_global: 250,
    thickness: "8,8",
    thickness_global: "8,8",
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 1471,
    name: "ISLB250",
    type: "S Shape",
    designation: "ISLB250",
    shape: "I",
    country_code: "Indian",
    ax: "35,5",
    ax_global: 3550,
    bf: 125,
    bf_global: 125,
    ct: 0,
    ct_global: 0,
    d: 250,
    d_global: 250,
    ix: "10,3",
    ix_global: 103000,
    iy: 193,
    iy_global: 1930000,
    iz: 3718,
    iz_global: 37180000,
    r1: 13,
    r1_global: 13,
    r2: "6,5",
    r2_global: "6,5",
    t: "8,2",
    t_global: "8,2",
    tf: "6,1",
    tf_global: "6,1",
    z: 339,
    z_global: 339000,
    zx: "55,4",
    zx_global: 55400,
    width: 125,
    width_global: 125,
    thickness: "6,1",
    thickness_global: "6,1",
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 1472,
    name: "ISMB250",
    type: "S Shape",
    designation: "ISMB250",
    shape: "I",
    country_code: "Indian",
    ax: "47,6",
    ax_global: 4760,
    bf: 125,
    bf_global: 125,
    ct: 0,
    ct_global: 0,
    d: 250,
    d_global: 250,
    ix: 25,
    ix_global: 250000,
    iy: 335,
    iy_global: 3350000,
    iz: 5132,
    iz_global: 51320000,
    r1: 13,
    r1_global: 13,
    r2: "6,5",
    r2_global: "6,5",
    tf: "12,5",
    tf_global: "12,5",
    tw: "6,9",
    tw_global: "6,9",
    z: 466,
    z_global: 466000,
    zx: "89,7",
    zx_global: 89700,
    width: 125,
    width_global: 125,
    thickness: "6,9",
    thickness_global: "6,9",
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 1473,
    name: "ISSC250",
    type: "S Shape",
    designation: "ISSC250",
    shape: "I",
    country_code: "Indian",
    ax: 109,
    ax_global: 10900,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 250,
    d_global: 250,
    ix: 141,
    ix_global: 1410000,
    iy: 3257,
    iy_global: 32570000,
    iz: 12459,
    iz_global: 124590000,
    r1: 23,
    r1_global: 23,
    r2: "11,5",
    r2_global: "11,5",
    t: 17,
    t_global: 17,
    tf: 10,
    tf_global: 10,
    z: 1123,
    z_global: 1123000,
    zx: 449,
    zx_global: 449000,
    width: 250,
    width_global: 250,
    thickness: 10,
    thickness_global: 10,
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 1474,
    name: "ISWB250",
    type: "S Shape",
    designation: "ISWB250",
    shape: "I",
    country_code: "Indian",
    ax: 52,
    ax_global: 5200,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 250,
    d_global: 250,
    ix: 18,
    ix_global: 180000,
    iy: 857,
    iy_global: 8570000,
    iz: 5943,
    iz_global: 59430000,
    r1: 10,
    r1_global: 10,
    r2: 5,
    r2_global: 5,
    t: 9,
    t_global: 9,
    tf: "6,7",
    tf_global: "6,7",
    z: 528,
    z_global: 528000,
    zx: 150,
    zx_global: 150000,
    width: 200,
    width_global: 200,
    thickness: "6,7",
    thickness_global: "6,7",
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 1475,
    name: "ISLB275",
    type: "S Shape",
    designation: "ISLB275",
    shape: "I",
    country_code: "Indian",
    ax: 42,
    ax_global: 4200,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 275,
    d_global: 275,
    ix: "14,1",
    ix_global: 141000,
    iy: 287,
    iy_global: 2870000,
    iz: 5375,
    iz_global: 53750000,
    r1: 14,
    r1_global: 14,
    r2: 7,
    r2_global: 7,
    t: "8,8",
    t_global: "8,8",
    tf: "6,4",
    tf_global: "6,4",
    z: 444,
    z_global: 444000,
    zx: "73,5",
    zx_global: 73500,
    width: 140,
    width_global: 140,
    thickness: "6,4",
    thickness_global: "6,4",
    height: 275,
    height_global: 275,
  },
  {
    profile_section_id: 1476,
    name: "ISHB300",
    type: "S Shape",
    designation: "ISHB300",
    shape: "I",
    country_code: "Indian",
    ax: "74,8",
    ax_global: 7480,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 300,
    d_global: 300,
    ix: "31,4",
    ix_global: 314000,
    iy: 2193,
    iy_global: 21930000,
    iz: 12544,
    iz_global: 125440000,
    r1: 11,
    r1_global: 11,
    r2: "5,5",
    r2_global: "5,5",
    t: "10,6",
    t_global: "10,6",
    tf: "7,6",
    tf_global: "7,6",
    z: 922,
    z_global: 922000,
    zx: 292,
    zx_global: 292000,
    width: 250,
    width_global: 250,
    thickness: "7,6",
    thickness_global: "7,6",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 1477,
    name: "ISHB300H",
    type: "S Shape",
    designation: "ISHB300H",
    shape: "I",
    country_code: "Indian",
    ax: "79,9",
    ax_global: 7990,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 300,
    d_global: 300,
    ix: 36,
    ix_global: 360000,
    iy: 2203,
    iy_global: 22030000,
    iz: 12870,
    iz_global: 128700000,
    r1: 11,
    r1_global: 11,
    r2: "5,5",
    r2_global: "5,5",
    t: "10,6",
    t_global: "10,6",
    tf: "9,4",
    tf_global: "9,4",
    z: 957,
    z_global: 957000,
    zx: 295,
    zx_global: 295000,
    width: 250,
    width_global: 250,
    thickness: "9,4",
    thickness_global: "9,4",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 1478,
    name: "ISLB300",
    type: "S Shape",
    designation: "ISLB300",
    shape: "I",
    country_code: "Indian",
    ax: "48,1",
    ax_global: 4810,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 300,
    d_global: 300,
    ix: "18,2",
    ix_global: 182000,
    iy: 376,
    iy_global: 3760000,
    iz: 7334,
    iz_global: 73340000,
    r1: 15,
    r1_global: 15,
    r2: "7,5",
    r2_global: "7,5",
    t: "9,4",
    t_global: "9,4",
    tf: "6,7",
    tf_global: "6,7",
    z: 554,
    z_global: 554000,
    zx: 90,
    zx_global: 90000,
    width: 150,
    width_global: 150,
    thickness: "6,7",
    thickness_global: "6,7",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 1479,
    name: "ISLBP300",
    type: "S Shape",
    designation: "ISLBP300",
    shape: "I",
    country_code: "Indian",
    ax: "52,9",
    ax_global: 5290,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 300,
    d_global: 300,
    ix: "25,8",
    ix_global: 258000,
    iy: 414,
    iy_global: 4140000,
    iz: 8134,
    iz_global: 81340000,
    r1: 15,
    r1_global: 15,
    r2: "7,5",
    r2_global: "7,5",
    t: "11,6",
    t_global: "11,6",
    tf: 7,
    tf_global: 7,
    z: 615,
    z_global: 615000,
    zx: 102,
    zx_global: 102000,
    width: 140,
    width_global: 140,
    thickness: 7,
    thickness_global: 7,
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 1480,
    name: "ISMB300",
    type: "S Shape",
    designation: "ISMB300",
    shape: "I",
    country_code: "Indian",
    ax: "58,7",
    ax_global: 5870,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 300,
    d_global: 300,
    ix: "34,1",
    ix_global: 341000,
    iy: 486,
    iy_global: 4860000,
    iz: 8986,
    iz_global: 89860000,
    r1: 14,
    r1_global: 14,
    r2: 7,
    r2_global: 7,
    tf: "13,1",
    tf_global: "13,1",
    tw: "7,7",
    tw_global: "7,7",
    z: 681,
    z_global: 681000,
    zx: 118,
    zx_global: 118000,
    width: 140,
    width_global: 140,
    thickness: "7,7",
    thickness_global: "7,7",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 1481,
    name: "ISWB300",
    type: "S Shape",
    designation: "ISWB300",
    shape: "I",
    country_code: "Indian",
    ax: "61,3",
    ax_global: 6130,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 300,
    d_global: 300,
    ix: "24,5",
    ix_global: 245000,
    iy: 990,
    iy_global: 9900000,
    iz: 9821,
    iz_global: 98210000,
    r1: 11,
    r1_global: 11,
    r2: "5,5",
    r2_global: "5,5",
    t: 10,
    t_global: 10,
    tf: "7,4",
    tf_global: "7,4",
    z: 731,
    z_global: 731000,
    zx: 171,
    zx_global: 171000,
    width: 200,
    width_global: 200,
    thickness: "7,4",
    thickness_global: "7,4",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 1482,
    name: "ISLB325",
    type: "S Shape",
    designation: "ISLB325",
    shape: "I",
    country_code: "Indian",
    ax: "54,9",
    ax_global: 5490,
    bf: 165,
    bf_global: 165,
    ct: 0,
    ct_global: 0,
    d: 325,
    d_global: 325,
    ix: "23,2",
    ix_global: 232000,
    iy: 511,
    iy_global: 5110000,
    iz: 9876,
    iz_global: 98760000,
    r1: 16,
    r1_global: 16,
    r2: 8,
    r2_global: 8,
    t: "9,8",
    t_global: "9,8",
    tf: 7,
    tf_global: 7,
    z: 688,
    z_global: 688000,
    zx: 112,
    zx_global: 112000,
    width: 165,
    width_global: 165,
    thickness: 7,
    thickness_global: 7,
    height: 325,
    height_global: 325,
  },
  {
    profile_section_id: 1483,
    name: "ISHB350",
    type: "S Shape",
    designation: "ISHB350",
    shape: "I",
    country_code: "Indian",
    ax: "85,9",
    ax_global: 8590,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 350,
    d_global: 350,
    ix: "41,4",
    ix_global: 414000,
    iy: 2451,
    iy_global: 24510000,
    iz: 19162,
    iz_global: 191620000,
    r1: 12,
    r1_global: 12,
    r2: 6,
    r2_global: 6,
    t: "11,6",
    t_global: "11,6",
    tf: "8,3",
    tf_global: "8,3",
    z: 1214,
    z_global: 1214000,
    zx: 324,
    zx_global: 324000,
    width: 250,
    width_global: 250,
    thickness: "8,3",
    thickness_global: "8,3",
    height: 350,
    height_global: 350,
  },
  {
    profile_section_id: 1484,
    name: "ISHB350H",
    type: "S Shape",
    designation: "ISHB350H",
    shape: "I",
    country_code: "Indian",
    ax: "91,8",
    ax_global: 9180,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 350,
    d_global: 350,
    ix: "47,7",
    ix_global: 477000,
    iy: 2461,
    iy_global: 24610000,
    iz: 19686,
    iz_global: 196860000,
    r1: 12,
    r1_global: 12,
    r2: 6,
    r2_global: 6,
    t: "11,6",
    t_global: "11,6",
    tf: "10,1",
    tf_global: "10,1",
    z: 1262,
    z_global: 1262000,
    zx: 328,
    zx_global: 328000,
    width: 250,
    width_global: 250,
    thickness: "10,1",
    thickness_global: "10,1",
    height: 350,
    height_global: 350,
  },
  {
    profile_section_id: 1485,
    name: "ISLB350",
    type: "S Shape",
    designation: "ISLB350",
    shape: "I",
    country_code: "Indian",
    ax: 63,
    ax_global: 6300,
    bf: 165,
    bf_global: 165,
    ct: 0,
    ct_global: 0,
    d: 350,
    d_global: 350,
    ix: "31,8",
    ix_global: 318000,
    iy: 632,
    iy_global: 6320000,
    iz: 13160,
    iz_global: 131600000,
    r1: 16,
    r1_global: 16,
    r2: 8,
    r2_global: 8,
    t: "11,4",
    t_global: "11,4",
    tf: "7,4",
    tf_global: "7,4",
    z: 851,
    z_global: 851000,
    zx: 135,
    zx_global: 135000,
    width: 165,
    width_global: 165,
    thickness: "7,4",
    thickness_global: "7,4",
    height: 350,
    height_global: 350,
  },
  {
    profile_section_id: 1486,
    name: "ISMB350",
    type: "S Shape",
    designation: "ISMB350",
    shape: "I",
    country_code: "Indian",
    ax: "66,7",
    ax_global: 6670,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 350,
    d_global: 350,
    ix: "42,4",
    ix_global: 424000,
    iy: 538,
    iy_global: 5380000,
    iz: 13632,
    iz_global: 136320000,
    r1: 14,
    r1_global: 14,
    r2: 7,
    r2_global: 7,
    tf: "14,2",
    tf_global: "14,2",
    tw: "8,1",
    tw_global: "8,1",
    z: 890,
    z_global: 890000,
    zx: 130,
    zx_global: 130000,
    width: 140,
    width_global: 140,
    thickness: "8,1",
    thickness_global: "8,1",
    height: 350,
    height_global: 350,
  },
  {
    profile_section_id: 1487,
    name: "ISWB350",
    type: "S Shape",
    designation: "ISWB350",
    shape: "I",
    country_code: "Indian",
    ax: "72,5",
    ax_global: 7250,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 350,
    d_global: 350,
    ix: "34,7",
    ix_global: 347000,
    iy: 1176,
    iy_global: 11760000,
    iz: 15524,
    iz_global: 155240000,
    r1: 12,
    r1_global: 12,
    r2: 6,
    r2_global: 6,
    t: "11,4",
    t_global: "11,4",
    tf: 8,
    tf_global: 8,
    z: 995,
    z_global: 995000,
    zx: 200,
    zx_global: 200000,
    width: 200,
    width_global: 200,
    thickness: 8,
    thickness_global: 8,
    height: 350,
    height_global: 350,
  },
  {
    profile_section_id: 1488,
    name: "ISHB400",
    type: "S Shape",
    designation: "ISHB400",
    shape: "I",
    country_code: "Indian",
    ax: "98,7",
    ax_global: 9870,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 400,
    d_global: 400,
    ix: "55,9",
    ix_global: 559000,
    iy: 2728,
    iy_global: 27280000,
    iz: 28086,
    iz_global: 280860000,
    r1: 14,
    r1_global: 14,
    r2: 7,
    r2_global: 7,
    t: "12,7",
    t_global: "12,7",
    tf: "9,1",
    tf_global: "9,1",
    z: 1566,
    z_global: 1566000,
    zx: 361,
    zx_global: 361000,
    width: 250,
    width_global: 250,
    thickness: "9,1",
    thickness_global: "9,1",
    height: 400,
    height_global: 400,
  },
  {
    profile_section_id: 1489,
    name: "ISHB400H",
    type: "S Shape",
    designation: "ISHB400H",
    shape: "I",
    country_code: "Indian",
    ax: 104,
    ax_global: 10400,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 400,
    d_global: 400,
    ix: "62,7",
    ix_global: 627000,
    iy: 2737,
    iy_global: 27370000,
    iz: 28744,
    iz_global: 287440000,
    r1: 14,
    r1_global: 14,
    r2: 7,
    r2_global: 7,
    t: "12,7",
    t_global: "12,7",
    tf: "10,6",
    tf_global: "10,6",
    z: 1619,
    z_global: 1619000,
    zx: 364,
    zx_global: 364000,
    width: 250,
    width_global: 250,
    thickness: "10,6",
    thickness_global: "10,6",
    height: 400,
    height_global: 400,
  },
  {
    profile_section_id: 1490,
    name: "ISLB400",
    type: "S Shape",
    designation: "ISLB400",
    shape: "I",
    country_code: "Indian",
    ax: "72,4",
    ax_global: 7240,
    bf: 165,
    bf_global: 165,
    ct: 0,
    ct_global: 0,
    d: 400,
    d_global: 400,
    ix: "40,5",
    ix_global: 405000,
    iy: 716,
    iy_global: 7160000,
    iz: 19304,
    iz_global: 193040000,
    r1: 16,
    r1_global: 16,
    r2: 8,
    r2_global: 8,
    t: "12,5",
    t_global: "12,5",
    tf: 8,
    tf_global: 8,
    z: 1099,
    z_global: 1099000,
    zx: 151,
    zx_global: 151000,
    width: 165,
    width_global: 165,
    thickness: 8,
    thickness_global: 8,
    height: 400,
    height_global: 400,
  },
  {
    profile_section_id: 1491,
    name: "ISMB400",
    type: "S Shape",
    designation: "ISMB400",
    shape: "I",
    country_code: "Indian",
    ax: "78,5",
    ax_global: 7850,
    bf: 140,
    bf_global: 140,
    ct: 0,
    ct_global: 0,
    d: 400,
    d_global: 400,
    ix: "58,8",
    ix_global: 588000,
    iy: 622,
    iy_global: 6220000,
    iz: 20456,
    iz_global: 204560000,
    r1: 14,
    r1_global: 14,
    r2: 7,
    r2_global: 7,
    tf: 16,
    tf_global: 16,
    tw: "8,9",
    tw_global: "8,9",
    z: 1176,
    z_global: 1176000,
    zx: 150,
    zx_global: 150000,
    width: 140,
    width_global: 140,
    thickness: "8,9",
    thickness_global: "8,9",
    height: 400,
    height_global: 400,
  },
  {
    profile_section_id: 1492,
    name: "ISWB400",
    type: "S Shape",
    designation: "ISWB400",
    shape: "I",
    country_code: "Indian",
    ax: 85,
    ax_global: 8500,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 400,
    d_global: 400,
    ix: "49,1",
    ix_global: 491000,
    iy: 1388,
    iy_global: 13880000,
    iz: 23426,
    iz_global: 234260000,
    r1: 13,
    r1_global: 13,
    r2: "6,5",
    r2_global: "6,5",
    t: 13,
    t_global: 13,
    tf: "8,6",
    tf_global: "8,6",
    z: 1320,
    z_global: 1320000,
    zx: 234,
    zx_global: 234000,
    width: 200,
    width_global: 200,
    thickness: "8,6",
    thickness_global: "8,6",
    height: 400,
    height_global: 400,
  },
  {
    profile_section_id: 1493,
    name: "ISHB450",
    type: "S Shape",
    designation: "ISHB450",
    shape: "I",
    country_code: "Indian",
    ax: 111,
    ax_global: 11100,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 450,
    d_global: 450,
    ix: "71,1",
    ix_global: 711000,
    iy: 2985,
    iy_global: 29850000,
    iz: 39204,
    iz_global: 392040000,
    r1: 15,
    r1_global: 15,
    r2: "7,5",
    r2_global: "7,5",
    t: "13,7",
    t_global: "13,7",
    tf: "9,8",
    tf_global: "9,8",
    z: 1955,
    z_global: 1955000,
    zx: 394,
    zx_global: 394000,
    width: 250,
    width_global: 250,
    thickness: "9,8",
    thickness_global: "9,8",
    height: 450,
    height_global: 450,
  },
  {
    profile_section_id: 1494,
    name: "ISHB450H",
    type: "S Shape",
    designation: "ISHB450H",
    shape: "I",
    country_code: "Indian",
    ax: 117,
    ax_global: 11700,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 450,
    d_global: 450,
    ix: "79,8",
    ix_global: 798000,
    iy: 2994,
    iy_global: 29940000,
    iz: 40149,
    iz_global: 401490000,
    r1: 15,
    r1_global: 15,
    r2: "7,5",
    r2_global: "7,5",
    t: "13,7",
    t_global: "13,7",
    tf: "11,3",
    tf_global: "11,3",
    z: 2022,
    z_global: 2022000,
    zx: 398,
    zx_global: 398000,
    width: 250,
    width_global: 250,
    thickness: "11,3",
    thickness_global: "11,3",
    height: 450,
    height_global: 450,
  },
  {
    profile_section_id: 1495,
    name: "ISLB450",
    type: "S Shape",
    designation: "ISLB450",
    shape: "I",
    country_code: "Indian",
    ax: "83,1",
    ax_global: 8310,
    bf: 170,
    bf_global: 170,
    ct: 0,
    ct_global: 0,
    d: 450,
    d_global: 450,
    ix: 51,
    ix_global: 510000,
    iy: 853,
    iy_global: 8530000,
    iz: 27540,
    iz_global: 275400000,
    r1: 16,
    r1_global: 16,
    r2: 8,
    r2_global: 8,
    t: "13,4",
    t_global: "13,4",
    tf: "8,6",
    tf_global: "8,6",
    z: 1401,
    z_global: 1401000,
    zx: 175,
    zx_global: 175000,
    width: 170,
    width_global: 170,
    thickness: "8,6",
    thickness_global: "8,6",
    height: 450,
    height_global: 450,
  },
  {
    profile_section_id: 1496,
    name: "ISMB450",
    type: "S Shape",
    designation: "ISMB450",
    shape: "I",
    country_code: "Indian",
    ax: "92,3",
    ax_global: 9230,
    bf: 150,
    bf_global: 150,
    ct: 0,
    ct_global: 0,
    d: 450,
    d_global: 450,
    ix: "79,9",
    ix_global: 799000,
    iy: 834,
    iy_global: 8340000,
    iz: 30391,
    iz_global: 303910000,
    r1: 15,
    r1_global: 15,
    r2: "7,5",
    r2_global: "7,5",
    tf: "17,4",
    tf_global: "17,4",
    tw: "9,4",
    tw_global: "9,4",
    z: 1553,
    z_global: 1553000,
    zx: 187,
    zx_global: 187000,
    width: 150,
    width_global: 150,
    thickness: "9,4",
    thickness_global: "9,4",
    height: 450,
    height_global: 450,
  },
  {
    profile_section_id: 1497,
    name: "ISWB450",
    type: "S Shape",
    designation: "ISWB450",
    shape: "I",
    country_code: "Indian",
    ax: 101,
    ax_global: 10100,
    bf: 200,
    bf_global: 200,
    ct: 0,
    ct_global: 0,
    d: 450,
    d_global: 450,
    ix: "76,2",
    ix_global: 762000,
    iy: 1707,
    iy_global: 17070000,
    iz: 35141,
    iz_global: 351410000,
    r1: 15,
    r1_global: 15,
    r2: 7,
    r2_global: 7,
    t: "15,4",
    t_global: "15,4",
    tf: "9,2",
    tf_global: "9,2",
    z: 1764,
    z_global: 1764000,
    zx: 284,
    zx_global: 284000,
    width: 200,
    width_global: 200,
    thickness: "9,2",
    thickness_global: "9,2",
    height: 450,
    height_global: 450,
  },
  {
    profile_section_id: 1498,
    name: "ISLB500",
    type: "S Shape",
    designation: "ISLB500",
    shape: "I",
    country_code: "Indian",
    ax: "95,5",
    ax_global: 9550,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 500,
    d_global: 500,
    ix: "64,5",
    ix_global: 645000,
    iy: 1064,
    iy_global: 10640000,
    iz: 38578,
    iz_global: 385780000,
    r1: 17,
    r1_global: 17,
    r2: "8,5",
    r2_global: "8,5",
    t: "14,1",
    t_global: "14,1",
    tf: "9,2",
    tf_global: "9,2",
    z: 1773,
    z_global: 1773000,
    zx: 207,
    zx_global: 207000,
    width: 180,
    width_global: 180,
    thickness: "9,2",
    thickness_global: "9,2",
    height: 500,
    height_global: 500,
  },
  {
    profile_section_id: 1499,
    name: "ISMB500",
    type: "S Shape",
    designation: "ISMB500",
    shape: "I",
    country_code: "Indian",
    ax: 111,
    ax_global: 11100,
    bf: 180,
    bf_global: 180,
    ct: 0,
    ct_global: 0,
    d: 500,
    d_global: 500,
    ix: 102,
    ix_global: 1020000,
    iy: 1370,
    iy_global: 13700000,
    iz: 45220,
    iz_global: 452200000,
    r1: 17,
    r1_global: 17,
    r2: "8,5",
    r2_global: "8,5",
    tf: "17,2",
    tf_global: "17,2",
    tw: "10,2",
    tw_global: "10,2",
    z: 2075,
    z_global: 2075000,
    zx: 260,
    zx_global: 260000,
    width: 180,
    width_global: 180,
    thickness: "10,2",
    thickness_global: "10,2",
    height: 500,
    height_global: 500,
  },
  {
    profile_section_id: 1500,
    name: "ISWB500",
    type: "S Shape",
    designation: "ISWB500",
    shape: "I",
    country_code: "Indian",
    ax: 121,
    ax_global: 12100,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 500,
    d_global: 500,
    ix: "91,7",
    ix_global: 917000,
    iy: 2988,
    iy_global: 29880000,
    iz: 52287,
    iz_global: 522870000,
    r1: 15,
    r1_global: 15,
    r2: "7,5",
    r2_global: "7,5",
    t: "14,7",
    t_global: "14,7",
    tf: "9,9",
    tf_global: "9,9",
    z: 2353,
    z_global: 2353000,
    zx: 407,
    zx_global: 407000,
    width: 250,
    width_global: 250,
    thickness: "9,9",
    thickness_global: "9,9",
    height: 500,
    height_global: 500,
  },
  {
    profile_section_id: 1501,
    name: "ISLB550",
    type: "S Shape",
    designation: "ISLB550",
    shape: "I",
    country_code: "Indian",
    ax: 110,
    ax_global: 11000,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 550,
    d_global: 550,
    ix: "83,2",
    ix_global: 832000,
    iy: 1335,
    iy_global: 13350000,
    iz: 53168,
    iz_global: 531680000,
    r1: 18,
    r1_global: 18,
    r2: 9,
    r2_global: 9,
    t: 15,
    t_global: 15,
    tf: "9,9",
    tf_global: "9,9",
    z: 2228,
    z_global: 2228000,
    zx: 246,
    zx_global: 246000,
    width: 190,
    width_global: 190,
    thickness: "9,9",
    thickness_global: "9,9",
    height: 550,
    height_global: 550,
  },
  {
    profile_section_id: 1502,
    name: "ISMB550",
    type: "S Shape",
    designation: "ISMB550",
    shape: "I",
    country_code: "Indian",
    ax: 132,
    ax_global: 13200,
    bf: 190,
    bf_global: 190,
    ct: 0,
    ct_global: 0,
    d: 550,
    d_global: 550,
    ix: 148,
    ix_global: 1480000,
    iy: 1834,
    iy_global: 18340000,
    iz: 64889,
    iz_global: 648890000,
    r1: 18,
    r1_global: 18,
    r2: 9,
    r2_global: 9,
    tf: "19,3",
    tf_global: "19,3",
    tw: "11,2",
    tw_global: "11,2",
    z: 2712,
    z_global: 2712000,
    zx: 328,
    zx_global: 328000,
    width: 190,
    width_global: 190,
    thickness: "11,2",
    thickness_global: "11,2",
    height: 550,
    height_global: 550,
  },
  {
    profile_section_id: 1503,
    name: "ISWB550",
    type: "S Shape",
    designation: "ISWB550",
    shape: "I",
    country_code: "Indian",
    ax: 143,
    ax_global: 14300,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 550,
    d_global: 550,
    ix: 141,
    ix_global: 1410000,
    iy: 3741,
    iy_global: 37410000,
    iz: 74912,
    iz_global: 749120000,
    r1: 16,
    r1_global: 16,
    r2: 8,
    r2_global: 8,
    t: "17,6",
    t_global: "17,6",
    tf: "10,5",
    tf_global: "10,5",
    z: 3066,
    z_global: 3066000,
    zx: 500,
    zx_global: 500000,
    width: 250,
    width_global: 250,
    thickness: "10,5",
    thickness_global: "10,5",
    height: 550,
    height_global: 550,
  },
  {
    profile_section_id: 1504,
    name: "ISLB600",
    type: "S Shape",
    designation: "ISLB600",
    shape: "I",
    country_code: "Indian",
    ax: 127,
    ax_global: 12700,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 600,
    d_global: 600,
    ix: 107,
    ix_global: 1070000,
    iy: 1822,
    iy_global: 18220000,
    iz: 72869,
    iz_global: 728690000,
    r1: 20,
    r1_global: 20,
    r2: 10,
    r2_global: 10,
    t: "15,5",
    t_global: "15,5",
    tf: "10,5",
    tf_global: "10,5",
    z: 2799,
    z_global: 2799000,
    zx: 307,
    zx_global: 307000,
    width: 210,
    width_global: 210,
    thickness: "10,5",
    thickness_global: "10,5",
    height: 600,
    height_global: 600,
  },
  {
    profile_section_id: 1505,
    name: "ISMB600",
    type: "S Shape",
    designation: "ISMB600",
    shape: "I",
    country_code: "Indian",
    ax: 154,
    ax_global: 15400,
    bf: 210,
    bf_global: 210,
    ct: 0,
    ct_global: 0,
    d: 600,
    d_global: 600,
    ix: 195,
    ix_global: 1950000,
    iy: 2574,
    iy_global: 25740000,
    iz: 90260,
    iz_global: 902600000,
    r1: 20,
    r1_global: 20,
    r2: 10,
    r2_global: 10,
    tf: "20,3",
    tf_global: "20,3",
    tw: 12,
    tw_global: 12,
    z: 3455,
    z_global: 3455000,
    zx: 418,
    zx_global: 418000,
    width: 210,
    width_global: 210,
    thickness: 12,
    thickness_global: 12,
    height: 600,
    height_global: 600,
  },
  {
    profile_section_id: 1506,
    name: "ISWB600",
    type: "S Shape",
    designation: "ISWB600",
    shape: "I",
    country_code: "Indian",
    ax: 170,
    ax_global: 17000,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 600,
    d_global: 600,
    ix: 227,
    ix_global: 2270000,
    iy: 4703,
    iy_global: 47030000,
    iz: 106202,
    iz_global: 1062020000,
    r1: 17,
    r1_global: 17,
    r2: "8,5",
    r2_global: "8,5",
    t: "21,3",
    t_global: "21,3",
    tf: "11,2",
    tf_global: "11,2",
    z: 3987,
    z_global: 3987000,
    zx: 619,
    zx_global: 619000,
    width: 250,
    width_global: 250,
    thickness: "11,2",
    thickness_global: "11,2",
    height: 600,
    height_global: 600,
  },
  {
    profile_section_id: 1507,
    name: "ISWB600H",
    type: "S Shape",
    designation: "ISWB600H",
    shape: "I",
    country_code: "Indian",
    ax: 185,
    ax_global: 18500,
    bf: 250,
    bf_global: 250,
    ct: 0,
    ct_global: 0,
    d: 600,
    d_global: 600,
    ix: 297,
    ix_global: 2970000,
    iy: 5298,
    iy_global: 52980000,
    iz: 115612,
    iz_global: 1156120000,
    r1: 18,
    r1_global: 18,
    r2: 9,
    r2_global: 9,
    t: "23,6",
    t_global: "23,6",
    tf: "11,8",
    tf_global: "11,8",
    z: 4342,
    z_global: 4342000,
    zx: 693,
    zx_global: 693000,
    width: 250,
    width_global: 250,
    thickness: "11,8",
    thickness_global: "11,8",
    height: 600,
    height_global: 600,
  },
  {
    profile_section_id: 2274,
    name: "TUB25252.6",
    type: "Tube",
    designation: "TUB25252.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "2,16",
    ax_global: 216,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    ix: "3,04",
    ix_global: 30400,
    iy: "1,72",
    iy_global: 17200,
    iz: "1,72",
    iz_global: 17200,
    rz: "2,6",
    rz_global: "2,6",
    z: "1,76",
    z_global: 1760,
    zx: "1,76",
    zx_global: 1760,
  },
  {
    profile_section_id: 2275,
    name: "TUB30302.6",
    type: "Tube",
    designation: "TUB30302.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "2,68",
    ax_global: 268,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    ix: "5,56",
    ix_global: 55600,
    iy: "3,23",
    iy_global: 32300,
    iz: "3,23",
    iz_global: 32300,
    rz: "2,6",
    rz_global: "2,6",
    z: "2,68",
    z_global: 2680,
    zx: "2,68",
    zx_global: 2680,
  },
  {
    profile_section_id: 2276,
    name: "TUB32322.6",
    type: "Tube",
    designation: "TUB32322.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "2,88",
    ax_global: 288,
    b: 32,
    b_global: 32,
    d: 32,
    d_global: 32,
    ix: "6,86",
    ix_global: 68600,
    iy: "4,02",
    iy_global: 40200,
    iz: "4,02",
    iz_global: 40200,
    rz: "2,6",
    rz_global: "2,6",
    z: "3,11",
    z_global: 3110,
    zx: "3,11",
    zx_global: 3110,
  },
  {
    profile_section_id: 2277,
    name: "TUB35352.6",
    type: "Tube",
    designation: "TUB35352.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "3,2",
    ax_global: 320,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    ix: "9,17",
    ix_global: 91700,
    iy: "5,43",
    iy_global: 54300,
    iz: "5,43",
    iz_global: 54300,
    rz: "2,6",
    rz_global: "2,6",
    z: "3,81",
    z_global: 3810,
    zx: "3,81",
    zx_global: 3810,
  },
  {
    profile_section_id: 2278,
    name: "TUB32323.2",
    type: "Tube",
    designation: "TUB32323.2",
    shape: "RHS",
    country_code: "Indian",
    ax: "3,42",
    ax_global: 342,
    b: 32,
    b_global: 32,
    d: 32,
    d_global: 32,
    ix: "7,96",
    ix_global: 79600,
    iy: "4,54",
    iy_global: 45400,
    iz: "4,54",
    iz_global: 45400,
    rz: "3,2",
    rz_global: "3,2",
    z: "3,59",
    z_global: 3590,
    zx: "3,59",
    zx_global: 3590,
  },
  {
    profile_section_id: 2279,
    name: "TUB38382.6",
    type: "Tube",
    designation: "TUB38382.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "3,51",
    ax_global: 351,
    b: 38,
    b_global: 38,
    d: 38,
    d_global: 38,
    ix: "11,9",
    ix_global: 119000,
    iy: "7,14",
    iy_global: 71400,
    iz: "7,14",
    iz_global: 71400,
    rz: "2,6",
    rz_global: "2,6",
    z: "4,57",
    z_global: 4570,
    zx: "4,57",
    zx_global: 4570,
  },
  {
    profile_section_id: 2280,
    name: "TUB40402.6",
    type: "Tube",
    designation: "TUB40402.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "3,72",
    ax_global: 372,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    ix: "14,1",
    ix_global: 141000,
    iy: "8,45",
    iy_global: 84500,
    iz: "8,45",
    iz_global: 84500,
    rz: "2,6",
    rz_global: "2,6",
    z: "5,12",
    z_global: 5120,
    zx: "5,12",
    zx_global: 5120,
  },
  {
    profile_section_id: 2281,
    name: "TUB30304",
    type: "Tube",
    designation: "TUB30304",
    shape: "RHS",
    country_code: "Indian",
    ax: "3,75",
    ax_global: 375,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    ix: "7,31",
    ix_global: 73100,
    iy: "3,97",
    iy_global: 39700,
    iz: "3,97",
    iz_global: 39700,
    rz: 4,
    rz_global: 4,
    z: "3,5",
    z_global: 3500,
    zx: "3,5",
    zx_global: 3500,
  },
  {
    profile_section_id: 2282,
    name: "TUB50252.9",
    type: "Tube",
    designation: "TUB50252.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "3,8",
    ax_global: 380,
    b: 25,
    b_global: 25,
    d: 50,
    d_global: 50,
    ix: "9,41",
    ix_global: 94100,
    iy: "3,6",
    iy_global: 36000,
    iz: "10,9",
    iz_global: 109000,
    rz: "2,9",
    rz_global: "2,9",
    z: "5,72",
    z_global: 5720,
    zx: "3,48",
    zx_global: 3480,
  },
  {
    profile_section_id: 2283,
    name: "TUB35353.2",
    type: "Tube",
    designation: "TUB35353.2",
    shape: "RHS",
    country_code: "Indian",
    ax: "3,81",
    ax_global: 381,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    ix: "10,7",
    ix_global: 107000,
    iy: "6,18",
    iy_global: 61800,
    iz: "6,18",
    iz_global: 61800,
    rz: "3,2",
    rz_global: "3,2",
    z: "4,42",
    z_global: 4420,
    zx: "4,42",
    zx_global: 4420,
  },
  {
    profile_section_id: 2284,
    name: "TUB38382.9",
    type: "Tube",
    designation: "TUB38382.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "3,86",
    ax_global: 386,
    b: 38,
    b_global: 38,
    d: 38,
    d_global: 38,
    ix: 13,
    ix_global: 130000,
    iy: "7,68",
    iy_global: 76800,
    iz: "7,68",
    iz_global: 76800,
    rz: "2,9",
    rz_global: "2,9",
    z: "4,97",
    z_global: 4970,
    zx: "4,97",
    zx_global: 4970,
  },
  {
    profile_section_id: 2285,
    name: "TUB32324",
    type: "Tube",
    designation: "TUB32324",
    shape: "RHS",
    country_code: "Indian",
    ax: "4,07",
    ax_global: 407,
    b: 32,
    b_global: 32,
    d: 32,
    d_global: 32,
    ix: "9,14",
    ix_global: 91400,
    iy: "5,02",
    iy_global: 50200,
    iz: "5,02",
    iz_global: 50200,
    rz: 4,
    rz_global: 4,
    z: "4,11",
    z_global: 4110,
    zx: "4,11",
    zx_global: 4110,
  },
  {
    profile_section_id: 2286,
    name: "TUB38383.2",
    type: "Tube",
    designation: "TUB38383.2",
    shape: "RHS",
    country_code: "Indian",
    ax: "4,19",
    ax_global: 419,
    b: 38,
    b_global: 38,
    d: 38,
    d_global: 38,
    ix: 14,
    ix_global: 140000,
    iy: "8,18",
    iy_global: 81800,
    iz: "8,18",
    iz_global: 81800,
    rz: "3,2",
    rz_global: "3,2",
    z: "5,34",
    z_global: 5340,
    zx: "5,34",
    zx_global: 5340,
  },
  {
    profile_section_id: 2287,
    name: "TUB45452.6",
    type: "Tube",
    designation: "TUB45452.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "4,24",
    ax_global: 424,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    ix: "20,4",
    ix_global: 204000,
    iy: "12,4",
    iy_global: 124000,
    iz: "12,4",
    iz_global: 124000,
    rz: "2,6",
    rz_global: "2,6",
    z: "6,64",
    z_global: 6640,
    zx: "6,64",
    zx_global: 6640,
  },
  {
    profile_section_id: 2288,
    name: "TUB38383.3",
    type: "Tube",
    designation: "TUB38383.3",
    shape: "RHS",
    country_code: "Indian",
    ax: "4,3",
    ax_global: 430,
    b: 38,
    b_global: 38,
    d: 38,
    d_global: 38,
    ix: "14,3",
    ix_global: 143000,
    iy: "8,33",
    iy_global: 83300,
    iz: "8,33",
    iz_global: 83300,
    rz: "3,3",
    rz_global: "3,3",
    z: "5,46",
    z_global: 5460,
    zx: "5,46",
    zx_global: 5460,
  },
  {
    profile_section_id: 2289,
    name: "TUB35354",
    type: "Tube",
    designation: "TUB35354",
    shape: "RHS",
    country_code: "Indian",
    ax: "4,55",
    ax_global: 455,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    ix: "12,4",
    ix_global: 124000,
    iy: "6,93",
    iy_global: 69300,
    iz: "6,93",
    iz_global: 69300,
    rz: 4,
    rz_global: 4,
    z: "5,11",
    z_global: 5110,
    zx: "5,11",
    zx_global: 5110,
  },
  {
    profile_section_id: 2290,
    name: "TUB38383.6",
    type: "Tube",
    designation: "TUB38383.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "4,62",
    ax_global: 462,
    b: 38,
    b_global: 38,
    d: 38,
    d_global: 38,
    ix: "15,3",
    ix_global: 153000,
    iy: "8,76",
    iy_global: 87600,
    iz: "8,76",
    iz_global: 87600,
    rz: "3,6",
    rz_global: "3,6",
    z: "5,8",
    z_global: 5800,
    zx: "5,8",
    zx_global: 5800,
  },
  {
    profile_section_id: 2291,
    name: "TUB45452.9",
    type: "Tube",
    designation: "TUB45452.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "4,67",
    ax_global: 467,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    ix: "22,4",
    ix_global: 224000,
    iy: "13,5",
    iy_global: 135000,
    iz: "13,5",
    iz_global: 135000,
    rz: "2,9",
    rz_global: "2,9",
    z: "7,25",
    z_global: 7250,
    zx: "7,25",
    zx_global: 7250,
  },
  {
    profile_section_id: 2292,
    name: "TUB48482.9",
    type: "Tube",
    designation: "TUB48482.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "5,02",
    ax_global: 502,
    b: 48,
    b_global: 48,
    d: 48,
    d_global: 48,
    ix: "27,5",
    ix_global: 275000,
    iy: "16,6",
    iy_global: 166000,
    iz: "16,6",
    iz_global: 166000,
    rz: "2,9",
    rz_global: "2,9",
    z: "8,35",
    z_global: 8350,
    zx: "8,35",
    zx_global: 8350,
  },
  {
    profile_section_id: 2293,
    name: "TUB38384",
    type: "Tube",
    designation: "TUB38384",
    shape: "RHS",
    country_code: "Indian",
    ax: "5,03",
    ax_global: 503,
    b: 38,
    b_global: 38,
    d: 38,
    d_global: 38,
    ix: "16,4",
    ix_global: 164000,
    iy: "9,26",
    iy_global: 92600,
    iz: "9,26",
    iz_global: 92600,
    rz: 4,
    rz_global: 4,
    z: "6,22",
    z_global: 6220,
    zx: "6,22",
    zx_global: 6220,
  },
  {
    profile_section_id: 2294,
    name: "TUB65322.9",
    type: "Tube",
    designation: "TUB65322.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "5,07",
    ax_global: 507,
    b: 32,
    b_global: 32,
    d: 65,
    d_global: 65,
    ix: "21,4",
    ix_global: 214000,
    iy: "8,36",
    iy_global: 83600,
    iz: "25,7",
    iz_global: 257000,
    rz: "2,9",
    rz_global: "2,9",
    z: "10,2",
    z_global: 10200,
    zx: "6,15",
    zx_global: 6150,
  },
  {
    profile_section_id: 2295,
    name: "TUB45453.2",
    type: "Tube",
    designation: "TUB45453.2",
    shape: "RHS",
    country_code: "Indian",
    ax: "5,09",
    ax_global: 509,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    ix: "24,2",
    ix_global: 242000,
    iy: "14,4",
    iy_global: 144000,
    iz: "14,4",
    iz_global: 144000,
    rz: "3,2",
    rz_global: "3,2",
    z: "7,83",
    z_global: 7830,
    zx: "7,83",
    zx_global: 7830,
  },
  {
    profile_section_id: 2296,
    name: "TUB49492.9",
    type: "Tube",
    designation: "TUB49492.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "5,13",
    ax_global: 513,
    b: 49,
    b_global: 49,
    d: 49,
    d_global: 49,
    ix: "29,3",
    ix_global: 293000,
    iy: "17,8",
    iy_global: 178000,
    iz: "17,8",
    iz_global: 178000,
    rz: "2,9",
    rz_global: "2,9",
    z: "8,74",
    z_global: 8740,
    zx: "8,74",
    zx_global: 8740,
  },
  {
    profile_section_id: 2297,
    name: "TUB66332.9",
    type: "Tube",
    designation: "TUB66332.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "5,19",
    ax_global: 519,
    b: 33,
    b_global: 33,
    d: 66,
    d_global: 66,
    ix: "23,1",
    ix_global: 231000,
    iy: "9,12",
    iy_global: 91200,
    iz: "27,3",
    iz_global: 273000,
    rz: "2,9",
    rz_global: "2,9",
    z: "10,6",
    z_global: 10600,
    zx: "6,49",
    zx_global: 6490,
  },
  {
    profile_section_id: 2298,
    name: "TUB60402.9",
    type: "Tube",
    designation: "TUB60402.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "5,25",
    ax_global: 525,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    ix: "28,5",
    ix_global: 285000,
    iy: "13,1",
    iy_global: 131000,
    iz: "24,7",
    iz_global: 247000,
    rz: "2,9",
    rz_global: "2,9",
    z: "10,2",
    z_global: 10200,
    zx: "7,73",
    zx_global: 7730,
  },
  {
    profile_section_id: 2299,
    name: "TUB70302.9",
    type: "Tube",
    designation: "TUB70302.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "5,25",
    ax_global: 525,
    b: 30,
    b_global: 30,
    d: 70,
    d_global: 70,
    ix: 21,
    ix_global: 210000,
    iy: "7,72",
    iy_global: 77200,
    iz: "29,8",
    iz_global: 298000,
    rz: "2,9",
    rz_global: "2,9",
    z: "11,1",
    z_global: 11100,
    zx: "6,04",
    zx_global: 6040,
  },
  {
    profile_section_id: 2300,
    name: "TUB40404",
    type: "Tube",
    designation: "TUB40404",
    shape: "RHS",
    country_code: "Indian",
    ax: "5,35",
    ax_global: 535,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    ix: "19,4",
    ix_global: 194000,
    iy: "11,1",
    iy_global: 111000,
    iz: "11,1",
    iz_global: 111000,
    rz: 4,
    rz_global: 4,
    z: "7,01",
    z_global: 7010,
    zx: "7,01",
    zx_global: 7010,
  },
  {
    profile_section_id: 2301,
    name: "TUB45453.6",
    type: "Tube",
    designation: "TUB45453.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "5,63",
    ax_global: 563,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    ix: "26,5",
    ix_global: 265000,
    iy: "15,6",
    iy_global: 156000,
    iz: "15,6",
    iz_global: 156000,
    rz: "3,6",
    rz_global: "3,6",
    z: "8,55",
    z_global: 8550,
    zx: "8,55",
    zx_global: 8550,
  },
  {
    profile_section_id: 2302,
    name: "TUB70303.2",
    type: "Tube",
    designation: "TUB70303.2",
    shape: "RHS",
    country_code: "Indian",
    ax: "5,73",
    ax_global: 573,
    b: 30,
    b_global: 30,
    d: 70,
    d_global: 70,
    ix: "22,6",
    ix_global: 226000,
    iy: "8,24",
    iy_global: 82400,
    iz: 32,
    iz_global: 320000,
    rz: "3,2",
    rz_global: "3,2",
    z: 12,
    z_global: 12000,
    zx: "6,51",
    zx_global: 6510,
  },
  {
    profile_section_id: 2303,
    name: "TUB48483.7",
    type: "Tube",
    designation: "TUB48483.7",
    shape: "RHS",
    country_code: "Indian",
    ax: "6,2",
    ax_global: 620,
    b: 48,
    b_global: 48,
    d: 48,
    d_global: 48,
    ix: "33,4",
    ix_global: 334000,
    iy: "19,7",
    iy_global: 197000,
    iz: "19,7",
    iz_global: 197000,
    rz: "3,7",
    rz_global: "3,7",
    z: "10,1",
    z_global: 10100,
    zx: "10,1",
    zx_global: 10100,
  },
  {
    profile_section_id: 2304,
    name: "TUB65323.7",
    type: "Tube",
    designation: "TUB65323.7",
    shape: "RHS",
    country_code: "Indian",
    ax: "6,28",
    ax_global: 628,
    b: 32,
    b_global: 32,
    d: 65,
    d_global: 65,
    ix: "25,7",
    ix_global: 257000,
    iy: "9,78",
    iy_global: 97800,
    iz: "30,5",
    iz_global: 305000,
    rz: "3,7",
    rz_global: "3,7",
    z: "12,3",
    z_global: 12300,
    zx: "7,39",
    zx_global: 7390,
  },
  {
    profile_section_id: 2305,
    name: "TUB49493.6",
    type: "Tube",
    designation: "TUB49493.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "6,2",
    ax_global: 620,
    b: 49,
    b_global: 49,
    d: 49,
    d_global: 49,
    ix: "34,9",
    ix_global: 349000,
    iy: "20,7",
    iy_global: 207000,
    iz: "20,7",
    iz_global: 207000,
    rz: "3,6",
    rz_global: "3,6",
    z: "10,4",
    z_global: 10400,
    zx: "10,4",
    zx_global: 10400,
  },
  {
    profile_section_id: 2306,
    name: "TUB66333.6",
    type: "Tube",
    designation: "TUB66333.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "6,28",
    ax_global: 628,
    b: 33,
    b_global: 33,
    d: 66,
    d_global: 66,
    ix: "27,3",
    ix_global: 273000,
    iy: "10,5",
    iy_global: 105000,
    iz: "31,9",
    iz_global: 319000,
    rz: "3,6",
    rz_global: "3,6",
    z: "12,6",
    z_global: 12600,
    zx: "7,66",
    zx_global: 7660,
  },
  {
    profile_section_id: 2307,
    name: "TUB80402.9",
    type: "Tube",
    designation: "TUB80402.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "6,41",
    ax_global: 641,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: "42,7",
    ix_global: 427000,
    iy: "17,1",
    iy_global: 171000,
    iz: "50,9",
    iz_global: 509000,
    rz: "2,9",
    rz_global: "2,9",
    z: "16,1",
    z_global: 16100,
    zx: "9,88",
    zx_global: 9880,
  },
  {
    profile_section_id: 2308,
    name: "TUB45454.5",
    type: "Tube",
    designation: "TUB45454.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "6,77",
    ax_global: 677,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    ix: "31,1",
    ix_global: 311000,
    iy: "17,7",
    iy_global: 177000,
    iz: "17,7",
    iz_global: 177000,
    rz: "4,5",
    rz_global: "4,5",
    z: "9,99",
    z_global: 9990,
    zx: "9,99",
    zx_global: 9990,
  },
  {
    profile_section_id: 2309,
    name: "TUB70304",
    type: "Tube",
    designation: "TUB70304",
    shape: "RHS",
    country_code: "Indian",
    ax: "6,95",
    ax_global: 695,
    b: 30,
    b_global: 30,
    d: 70,
    d_global: 70,
    ix: "26,5",
    ix_global: 265000,
    iy: "9,42",
    iy_global: 94200,
    iz: "37,2",
    iz_global: 372000,
    rz: 4,
    rz_global: 4,
    z: "14,2",
    z_global: 14200,
    zx: "7,66",
    zx_global: 7660,
  },
  {
    profile_section_id: 2310,
    name: "TUB48484.5",
    type: "Tube",
    designation: "TUB48484.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "7,31",
    ax_global: 731,
    b: 48,
    b_global: 48,
    d: 48,
    d_global: 48,
    ix: "38,6",
    ix_global: 386000,
    iy: "22,2",
    iy_global: 222000,
    iz: "22,2",
    iz_global: 222000,
    rz: "4,5",
    rz_global: "4,5",
    z: "11,6",
    z_global: 11600,
    zx: "11,6",
    zx_global: 11600,
  },
  {
    profile_section_id: 2311,
    name: "TUB65324.5",
    type: "Tube",
    designation: "TUB65324.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "7,4",
    ax_global: 740,
    b: 32,
    b_global: 32,
    d: 65,
    d_global: 65,
    ix: "29,4",
    ix_global: 294000,
    iy: "10,9",
    iy_global: 109000,
    iz: "34,4",
    iz_global: 344000,
    rz: "4,5",
    rz_global: "4,5",
    z: "14,1",
    z_global: 14100,
    zx: "8,44",
    zx_global: 8440,
  },
  {
    profile_section_id: 2312,
    name: "TUB63633.2",
    type: "Tube",
    designation: "TUB63633.2",
    shape: "RHS",
    country_code: "Indian",
    ax: "7,39",
    ax_global: 739,
    b: 63,
    b_global: 63,
    d: 63,
    d_global: 63,
    ix: "70,3",
    ix_global: 703000,
    iy: "43,2",
    iy_global: 432000,
    iz: "43,2",
    iz_global: 432000,
    rz: "3,2",
    rz_global: "3,2",
    z: "16,4",
    z_global: 16400,
    zx: "16,4",
    zx_global: 16400,
  },
  {
    profile_section_id: 2313,
    name: "TUB49494.5",
    type: "Tube",
    designation: "TUB49494.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "7,49",
    ax_global: 749,
    b: 49,
    b_global: 49,
    d: 49,
    d_global: 49,
    ix: "41,3",
    ix_global: 413000,
    iy: "23,8",
    iy_global: 238000,
    iz: "23,8",
    iz_global: 238000,
    rz: "4,5",
    rz_global: "4,5",
    z: "12,2",
    z_global: 12200,
    zx: "12,2",
    zx_global: 12200,
  },
  {
    profile_section_id: 2314,
    name: "TUB66334.5",
    type: "Tube",
    designation: "TUB66334.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "7,58",
    ax_global: 758,
    b: 33,
    b_global: 33,
    d: 66,
    d_global: 66,
    ix: "31,9",
    ix_global: 319000,
    iy: "11,9",
    iy_global: 119000,
    iz: "36,6",
    iz_global: 366000,
    rz: "4,5",
    rz_global: "4,5",
    z: "14,8",
    z_global: 14800,
    zx: "8,94",
    zx_global: 8940,
  },
  {
    profile_section_id: 2315,
    name: "TUB63633.6",
    type: "Tube",
    designation: "TUB63633.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "8,22",
    ax_global: 822,
    b: 63,
    b_global: 63,
    d: 63,
    d_global: 63,
    ix: "77,8",
    ix_global: 778000,
    iy: "47,3",
    iy_global: 473000,
    iz: "47,3",
    iz_global: 473000,
    rz: "3,6",
    rz_global: "3,6",
    z: 18,
    z_global: 18000,
    zx: 18,
    zx_global: 18000,
  },
  {
    profile_section_id: 2316,
    name: "TUB70703.3",
    type: "Tube",
    designation: "TUB70703.3",
    shape: "RHS",
    country_code: "Indian",
    ax: "8,52",
    ax_global: 852,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    ix: 101,
    ix_global: 1010000,
    iy: "62,1",
    iy_global: 621000,
    iz: "62,1",
    iz_global: 621000,
    rz: "3,3",
    rz_global: "3,3",
    z: "21,1",
    z_global: 21100,
    zx: "21,1",
    zx_global: 21100,
  },
  {
    profile_section_id: 2317,
    name: "TUB94473.3",
    type: "Tube",
    designation: "TUB94473.3",
    shape: "RHS",
    country_code: "Indian",
    ax: "8,59",
    ax_global: 859,
    b: 47,
    b_global: 47,
    d: 94,
    d_global: 94,
    ix: "79,1",
    ix_global: 791000,
    iy: "31,8",
    iy_global: 318000,
    iz: "94,5",
    iz_global: 945000,
    rz: "3,3",
    rz_global: "3,3",
    z: "25,4",
    z_global: 25400,
    zx: "15,6",
    zx_global: 15600,
  },
  {
    profile_section_id: 2318,
    name: "TUB72723.2",
    type: "Tube",
    designation: "TUB72723.2",
    shape: "RHS",
    country_code: "Indian",
    ax: "8,54",
    ax_global: 854,
    b: 72,
    b_global: 72,
    d: 72,
    d_global: 72,
    ix: 107,
    ix_global: 1070000,
    iy: "66,3",
    iy_global: 663000,
    iz: "66,3",
    iz_global: 663000,
    rz: "3,2",
    rz_global: "3,2",
    z: "21,8",
    z_global: 21800,
    zx: "21,8",
    zx_global: 21800,
  },
  {
    profile_section_id: 2319,
    name: "TUB96483.2",
    type: "Tube",
    designation: "TUB96483.2",
    shape: "RHS",
    country_code: "Indian",
    ax: "8,54",
    ax_global: 854,
    b: 48,
    b_global: 48,
    d: 96,
    d_global: 96,
    ix: "82,4",
    ix_global: 824000,
    iy: "33,3",
    iy_global: 333000,
    iz: "98,6",
    iz_global: 986000,
    rz: "3,2",
    rz_global: "3,2",
    z: "25,8",
    z_global: 25800,
    zx: "15,9",
    zx_global: 15900,
  },
  {
    profile_section_id: 2320,
    name: "TUB80404",
    type: "Tube",
    designation: "TUB80404",
    shape: "RHS",
    country_code: "Indian",
    ax: "8,55",
    ax_global: 855,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: "55,2",
    ix_global: 552000,
    iy: "21,5",
    iy_global: 215000,
    iz: "64,8",
    iz_global: 648000,
    rz: 4,
    rz_global: 4,
    z: "20,9",
    z_global: 20900,
    zx: "12,8",
    zx_global: 12800,
  },
  {
    profile_section_id: 2321,
    name: "TUB75753.2",
    type: "Tube",
    designation: "TUB75753.2",
    shape: "RHS",
    country_code: "Indian",
    ax: "8,93",
    ax_global: 893,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    ix: 121,
    ix_global: 1210000,
    iy: "75,5",
    iy_global: 755000,
    iz: "75,5",
    iz_global: 755000,
    rz: "3,2",
    rz_global: "3,2",
    z: "23,8",
    z_global: 23800,
    zx: "23,8",
    zx_global: 23800,
  },
  {
    profile_section_id: 2322,
    name: "TUB63634.5",
    type: "Tube",
    designation: "TUB63634.5",
    shape: "RHS",
    country_code: "Indian",
    ax: 10,
    ax_global: 1000,
    b: 63,
    b_global: 63,
    d: 63,
    d_global: 63,
    ix: "93,3",
    ix_global: 933000,
    iy: "55,5",
    iy_global: 555000,
    iz: "55,5",
    iz_global: 555000,
    rz: "4,5",
    rz_global: "4,5",
    z: "21,5",
    z_global: 21500,
    zx: "21,5",
    zx_global: 21500,
  },
  {
    profile_section_id: 2323,
    name: "TUB70704",
    type: "Tube",
    designation: "TUB70704",
    shape: "RHS",
    country_code: "Indian",
    ax: "10,1",
    ax_global: 1010,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    ix: 119,
    ix_global: 1190000,
    iy: "72,1",
    iy_global: 721000,
    iz: "72,1",
    iz_global: 721000,
    rz: 4,
    rz_global: 4,
    z: "24,8",
    z_global: 24800,
    zx: "24,8",
    zx_global: 24800,
  },
  {
    profile_section_id: 2324,
    name: "TUB94474",
    type: "Tube",
    designation: "TUB94474",
    shape: "RHS",
    country_code: "Indian",
    ax: "10,2",
    ax_global: 1020,
    b: 47,
    b_global: 47,
    d: 94,
    d_global: 94,
    ix: "92,8",
    ix_global: 928000,
    iy: "36,7",
    iy_global: 367000,
    iz: 110,
    iz_global: 1100000,
    rz: 4,
    rz_global: 4,
    z: "29,8",
    z_global: 29800,
    zx: "18,3",
    zx_global: 18300,
  },
  {
    profile_section_id: 2325,
    name: "TUB72724",
    type: "Tube",
    designation: "TUB72724",
    shape: "RHS",
    country_code: "Indian",
    ax: "10,5",
    ax_global: 1050,
    b: 72,
    b_global: 72,
    d: 72,
    d_global: 72,
    ix: 130,
    ix_global: 1300000,
    iy: 79,
    iy_global: 790000,
    iz: 79,
    iz_global: 790000,
    rz: 4,
    rz_global: 4,
    z: "26,3",
    z_global: 26300,
    zx: "26,3",
    zx_global: 26300,
  },
  {
    profile_section_id: 2326,
    name: "TUB96484",
    type: "Tube",
    designation: "TUB96484",
    shape: "RHS",
    country_code: "Indian",
    ax: "10,5",
    ax_global: 1050,
    b: 48,
    b_global: 48,
    d: 96,
    d_global: 96,
    ix: "99,2",
    ix_global: 992000,
    iy: "39,3",
    iy_global: 393000,
    iz: 118,
    iz_global: 1180000,
    rz: 4,
    rz_global: 4,
    z: "31,2",
    z_global: 31200,
    zx: "19,1",
    zx_global: 19100,
  },
  {
    profile_section_id: 2327,
    name: "TUB75754",
    type: "Tube",
    designation: "TUB75754",
    shape: "RHS",
    country_code: "Indian",
    ax: "10,9",
    ax_global: 1090,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    ix: 147,
    ix_global: 1470000,
    iy: "90,2",
    iy_global: 902000,
    iz: "90,2",
    iz_global: 902000,
    rz: 4,
    rz_global: 4,
    z: "28,8",
    z_global: 28800,
    zx: "28,8",
    zx_global: 28800,
  },
  {
    profile_section_id: 2328,
    name: "TUB100504",
    type: "Tube",
    designation: "TUB100504",
    shape: "RHS",
    country_code: "Indian",
    ax: "10,9",
    ax_global: 1090,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    ix: 113,
    ix_global: 1130000,
    iy: "44,9",
    iy_global: 449000,
    iz: 134,
    iz_global: 1340000,
    rz: 4,
    rz_global: 4,
    z: "34,1",
    z_global: 34100,
    zx: "20,9",
    zx_global: 20900,
  },
  {
    profile_section_id: 2329,
    name: "TUB127503.6",
    type: "Tube",
    designation: "TUB127503.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "11,9",
    ax_global: 1190,
    b: 50,
    b_global: 50,
    d: 127,
    d_global: 127,
    ix: 142,
    ix_global: 1420000,
    iy: 52,
    iy_global: 520000,
    iz: 227,
    iz_global: 2270000,
    rz: "3,6",
    rz_global: "3,6",
    z: "45,9",
    z_global: 45900,
    zx: "23,7",
    zx_global: 23700,
  },
  {
    profile_section_id: 2330,
    name: "TUB89893.6",
    type: "Tube",
    designation: "TUB89893.6",
    shape: "RHS",
    country_code: "Indian",
    ax: 12,
    ax_global: 1200,
    b: 89,
    b_global: 89,
    d: 89,
    d_global: 89,
    ix: 229,
    ix_global: 2290000,
    iy: 143,
    iy_global: 1430000,
    iz: 143,
    iz_global: 1430000,
    rz: "3,6",
    rz_global: "3,6",
    z: "37,9",
    z_global: 37900,
    zx: "37,9",
    zx_global: 37900,
  },
  {
    profile_section_id: 2331,
    name: "TUB70704.9",
    type: "Tube",
    designation: "TUB70704.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "12,1",
    ax_global: 1210,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    ix: 140,
    ix_global: 1400000,
    iy: "83,5",
    iy_global: 835000,
    iz: "83,5",
    iz_global: 835000,
    rz: "4,9",
    rz_global: "4,9",
    z: "29,1",
    z_global: 29100,
    zx: "29,1",
    zx_global: 29100,
  },
  {
    profile_section_id: 2332,
    name: "TUB94474.9",
    type: "Tube",
    designation: "TUB94474.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "12,2",
    ax_global: 1220,
    b: 47,
    b_global: 47,
    d: 94,
    d_global: 94,
    ix: 109,
    ix_global: 1090000,
    iy: "42,1",
    iy_global: 421000,
    iz: 127,
    iz_global: 1270000,
    rz: "4,9",
    rz_global: "4,9",
    z: 35,
    z_global: 35000,
    zx: "21,4",
    zx_global: 21400,
  },
  {
    profile_section_id: 2333,
    name: "TUB90903.7",
    type: "Tube",
    designation: "TUB90903.7",
    shape: "RHS",
    country_code: "Indian",
    ax: "12,4",
    ax_global: 1240,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    ix: 243,
    ix_global: 2430000,
    iy: 152,
    iy_global: 1520000,
    iz: 152,
    iz_global: 1520000,
    rz: "3,7",
    rz_global: "3,7",
    z: "39,8",
    z_global: 39800,
    zx: "39,8",
    zx_global: 39800,
  },
  {
    profile_section_id: 2334,
    name: "TUB120603.7",
    type: "Tube",
    designation: "TUB120603.7",
    shape: "RHS",
    country_code: "Indian",
    ax: "12,4",
    ax_global: 1240,
    b: 60,
    b_global: 60,
    d: 120,
    d_global: 120,
    ix: 188,
    ix_global: 1880000,
    iy: "76,4",
    iy_global: 764000,
    iz: 226,
    iz_global: 2260000,
    rz: "3,7",
    rz_global: "3,7",
    z: "47,2",
    z_global: 47200,
    zx: "29,1",
    zx_global: 29100,
  },
  {
    profile_section_id: 2335,
    name: "TUB72724.8",
    type: "Tube",
    designation: "TUB72724.8",
    shape: "RHS",
    country_code: "Indian",
    ax: "12,3",
    ax_global: 1230,
    b: 72,
    b_global: 72,
    d: 72,
    d_global: 72,
    ix: 151,
    ix_global: 1510000,
    iy: "90,3",
    iy_global: 903000,
    iz: "90,3",
    iz_global: 903000,
    rz: "4,8",
    rz_global: "4,8",
    z: "30,5",
    z_global: 30500,
    zx: "30,5",
    zx_global: 30500,
  },
  {
    profile_section_id: 2336,
    name: "TUB96484.8",
    type: "Tube",
    designation: "TUB96484.8",
    shape: "RHS",
    country_code: "Indian",
    ax: "12,3",
    ax_global: 1230,
    b: 48,
    b_global: 48,
    d: 96,
    d_global: 96,
    ix: 115,
    ix_global: 1150000,
    iy: "44,6",
    iy_global: 446000,
    iz: 134,
    iz_global: 1340000,
    rz: "4,8",
    rz_global: "4,8",
    z: "36,1",
    z_global: 36100,
    zx: "22,1",
    zx_global: 22100,
  },
  {
    profile_section_id: 2337,
    name: "TUB91913.6",
    type: "Tube",
    designation: "TUB91913.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "12,3",
    ax_global: 1230,
    b: 91,
    b_global: 91,
    d: 91,
    d_global: 91,
    ix: 246,
    ix_global: 2460000,
    iy: 154,
    iy_global: 1540000,
    iz: 154,
    iz_global: 1540000,
    rz: "3,6",
    rz_global: "3,6",
    z: "39,8",
    z_global: 39800,
    zx: "39,8",
    zx_global: 39800,
  },
  {
    profile_section_id: 2338,
    name: "TUB122613.6",
    type: "Tube",
    designation: "TUB122613.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "12,3",
    ax_global: 1230,
    b: 61,
    b_global: 61,
    d: 122,
    d_global: 122,
    ix: 193,
    ix_global: 1930000,
    iy: "78,8",
    iy_global: 788000,
    iz: 233,
    iz_global: 2330000,
    rz: "3,6",
    rz_global: "3,6",
    z: "47,7",
    z_global: 47700,
    zx: "29,4",
    zx_global: 29400,
  },
  {
    profile_section_id: 2339,
    name: "TUB75754.9",
    type: "Tube",
    designation: "TUB75754.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "13,1",
    ax_global: 1310,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    ix: 174,
    ix_global: 1740000,
    iy: 105,
    iy_global: 1050000,
    iz: 105,
    iz_global: 1050000,
    rz: "4,9",
    rz_global: "4,9",
    z: "33,9",
    z_global: 33900,
    zx: "33,9",
    zx_global: 33900,
  },
  {
    profile_section_id: 2340,
    name: "TUB89894.5",
    type: "Tube",
    designation: "TUB89894.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "14,7",
    ax_global: 1470,
    b: 89,
    b_global: 89,
    d: 89,
    d_global: 89,
    ix: 279,
    ix_global: 2790000,
    iy: 172,
    iy_global: 1720000,
    iz: 172,
    iz_global: 1720000,
    rz: "4,5",
    rz_global: "4,5",
    z: 46,
    z_global: 46000,
    zx: 46,
    zx_global: 46000,
  },
  {
    profile_section_id: 2341,
    name: "TUB90904.5",
    type: "Tube",
    designation: "TUB90904.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "14,9",
    ax_global: 1490,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    ix: 289,
    ix_global: 2890000,
    iy: 178,
    iy_global: 1780000,
    iz: 178,
    iz_global: 1780000,
    rz: "4,5",
    rz_global: "4,5",
    z: "47,1",
    z_global: 47100,
    zx: "47,1",
    zx_global: 47100,
  },
  {
    profile_section_id: 2342,
    name: "TUB120604.5",
    type: "Tube",
    designation: "TUB120604.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "14,9",
    ax_global: 1490,
    b: 60,
    b_global: 60,
    d: 120,
    d_global: 120,
    ix: 222,
    ix_global: 2220000,
    iy: "88,9",
    iy_global: 889000,
    iz: 265,
    iz_global: 2650000,
    rz: "4,5",
    rz_global: "4,5",
    z: "55,8",
    z_global: 55800,
    zx: "34,3",
    zx_global: 34300,
  },
  {
    profile_section_id: 2343,
    name: "TUB127504.6",
    type: "Tube",
    designation: "TUB127504.6",
    shape: "RHS",
    country_code: "Indian",
    ax: "14,9",
    ax_global: 1490,
    b: 50,
    b_global: 50,
    d: 127,
    d_global: 127,
    ix: 174,
    ix_global: 1740000,
    iy: "62,5",
    iy_global: 625000,
    iz: 276,
    iz_global: 2760000,
    rz: "4,6",
    rz_global: "4,6",
    z: "56,7",
    z_global: 56700,
    zx: 29,
    zx_global: 29000,
  },
  {
    profile_section_id: 2344,
    name: "TUB1001004",
    type: "Tube",
    designation: "TUB1001004",
    shape: "RHS",
    country_code: "Indian",
    ax: "14,9",
    ax_global: 1490,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 362,
    ix_global: 3620000,
    iy: 226,
    iy_global: 2260000,
    iz: 226,
    iz_global: 2260000,
    rz: 4,
    rz_global: 4,
    z: "53,3",
    z_global: 53300,
    zx: "53,3",
    zx_global: 53300,
  },
  {
    profile_section_id: 2345,
    name: "TUB89894.9",
    type: "Tube",
    designation: "TUB89894.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "15,9",
    ax_global: 1590,
    b: 89,
    b_global: 89,
    d: 89,
    d_global: 89,
    ix: 300,
    ix_global: 3000000,
    iy: 183,
    iy_global: 1830000,
    iz: 183,
    iz_global: 1830000,
    rz: "4,9",
    rz_global: "4,9",
    z: "49,3",
    z_global: 49300,
    zx: "49,3",
    zx_global: 49300,
  },
  {
    profile_section_id: 2346,
    name: "TUB91.591.54.5",
    type: "Tube",
    designation: "TUB91.591.54.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "15,1",
    ax_global: 1510,
    b: 91,
    b_global: 91,
    d: 91,
    d_global: 91,
    ix: 304,
    ix_global: 3040000,
    iy: 188,
    iy_global: 1880000,
    iz: 188,
    iz_global: 1880000,
    rz: "4,5",
    rz_global: "4,5",
    z: "48,8",
    z_global: 48800,
    zx: "48,8",
    zx_global: 48800,
  },
  {
    profile_section_id: 2347,
    name: "TUB122614.5",
    type: "Tube",
    designation: "TUB122614.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "15,1",
    ax_global: 1510,
    b: 61,
    b_global: 61,
    d: 122,
    d_global: 122,
    ix: 234,
    ix_global: 2340000,
    iy: "93,8",
    iy_global: 938000,
    iz: 279,
    iz_global: 2790000,
    rz: "4,5",
    rz_global: "4,5",
    z: "57,8",
    z_global: 57800,
    zx: "35,6",
    zx_global: 35600,
  },
  {
    profile_section_id: 2348,
    name: "TUB90905.4",
    type: "Tube",
    designation: "TUB90905.4",
    shape: "RHS",
    country_code: "Indian",
    ax: "17,5",
    ax_global: 1750,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    ix: 337,
    ix_global: 3370000,
    iy: 204,
    iy_global: 2040000,
    iz: 204,
    iz_global: 2040000,
    rz: "5,4",
    rz_global: "5,4",
    z: "54,7",
    z_global: 54700,
    zx: "54,7",
    zx_global: 54700,
  },
  {
    profile_section_id: 2349,
    name: "TUB120605.4",
    type: "Tube",
    designation: "TUB120605.4",
    shape: "RHS",
    country_code: "Indian",
    ax: "17,5",
    ax_global: 1750,
    b: 60,
    b_global: 60,
    d: 120,
    d_global: 120,
    ix: 258,
    ix_global: 2580000,
    iy: 101,
    iy_global: 1010000,
    iz: 304,
    iz_global: 3040000,
    rz: "5,4",
    rz_global: "5,4",
    z: "64,9",
    z_global: 64900,
    zx: "39,7",
    zx_global: 39700,
  },
  {
    profile_section_id: 2350,
    name: "TUB91915.4",
    type: "Tube",
    designation: "TUB91915.4",
    shape: "RHS",
    country_code: "Indian",
    ax: "17,7",
    ax_global: 1770,
    b: 91,
    b_global: 91,
    d: 91,
    d_global: 91,
    ix: 349,
    ix_global: 3490000,
    iy: 212,
    iy_global: 2120000,
    iz: 212,
    iz_global: 2120000,
    rz: "5,4",
    rz_global: "5,4",
    z: "56,1",
    z_global: 56100,
    zx: "56,1",
    zx_global: 56100,
  },
  {
    profile_section_id: 2351,
    name: "TUB122615.4",
    type: "Tube",
    designation: "TUB122615.4",
    shape: "RHS",
    country_code: "Indian",
    ax: "17,8",
    ax_global: 1780,
    b: 61,
    b_global: 61,
    d: 122,
    d_global: 122,
    ix: 272,
    ix_global: 2720000,
    iy: 107,
    iy_global: 1070000,
    iz: 321,
    iz_global: 3210000,
    rz: "5,4",
    rz_global: "5,4",
    z: "67,3",
    z_global: 67300,
    zx: "41,2",
    zx_global: 41200,
  },
  {
    profile_section_id: 2352,
    name: "TUB1001005",
    type: "Tube",
    designation: "TUB1001005",
    shape: "RHS",
    country_code: "Indian",
    ax: "18,4",
    ax_global: 1840,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 441,
    ix_global: 4410000,
    iy: 271,
    iy_global: 2710000,
    iz: 271,
    iz_global: 2710000,
    rz: 5,
    rz_global: 5,
    z: "64,6",
    z_global: 64600,
    zx: "64,6",
    zx_global: 64600,
  },
  {
    profile_section_id: 2353,
    name: "TUB1101104.5",
    type: "Tube",
    designation: "TUB1101104.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "18,5",
    ax_global: 1850,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    ix: 541,
    ix_global: 5410000,
    iy: 338,
    iy_global: 3380000,
    iz: 338,
    iz_global: 3380000,
    rz: "4,5",
    rz_global: "4,5",
    z: "72,4",
    z_global: 72400,
    zx: "72,4",
    zx_global: 72400,
  },
  {
    profile_section_id: 2354,
    name: "TUB140804.5",
    type: "Tube",
    designation: "TUB140804.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "18,5",
    ax_global: 1850,
    b: 80,
    b_global: 80,
    d: 140,
    d_global: 140,
    ix: 457,
    ix_global: 4570000,
    iy: 199,
    iy_global: 1990000,
    iz: 474,
    iz_global: 4740000,
    rz: "4,5",
    rz_global: "4,5",
    z: "83,8",
    z_global: 83800,
    zx: "56,9",
    zx_global: 56900,
  },
  {
    profile_section_id: 2355,
    name: "TUB1131134.5",
    type: "Tube",
    designation: "TUB1131134.5",
    shape: "RHS",
    country_code: "Indian",
    ax: 19,
    ax_global: 1900,
    b: 113,
    b_global: 113,
    d: 113,
    d_global: 113,
    ix: 588,
    ix_global: 5880000,
    iy: 368,
    iy_global: 3680000,
    iz: 368,
    iz_global: 3680000,
    rz: "4,5",
    rz_global: "4,5",
    z: "76,6",
    z_global: 76600,
    zx: "76,6",
    zx_global: 76600,
  },
  {
    profile_section_id: 2356,
    name: "TUB1101104.9",
    type: "Tube",
    designation: "TUB1101104.9",
    shape: "RHS",
    country_code: "Indian",
    ax: 20,
    ax_global: 2000,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    ix: 583,
    ix_global: 5830000,
    iy: 362,
    iy_global: 3620000,
    iz: 362,
    iz_global: 3620000,
    rz: "4,9",
    rz_global: "4,9",
    z: "77,9",
    z_global: 77900,
    zx: "77,9",
    zx_global: 77900,
  },
  {
    profile_section_id: 2357,
    name: "TUB140804.9",
    type: "Tube",
    designation: "TUB140804.9",
    shape: "RHS",
    country_code: "Indian",
    ax: 20,
    ax_global: 2000,
    b: 80,
    b_global: 80,
    d: 140,
    d_global: 140,
    ix: 492,
    ix_global: 4920000,
    iy: 213,
    iy_global: 2130000,
    iz: 509,
    iz_global: 5090000,
    rz: "4,9",
    rz_global: "4,9",
    z: "90,2",
    z_global: 90200,
    zx: "61,2",
    zx_global: 61200,
  },
  {
    profile_section_id: 2358,
    name: "TUB1131134.8",
    type: "Tube",
    designation: "TUB1131134.8",
    shape: "RHS",
    country_code: "Indian",
    ax: "20,2",
    ax_global: 2020,
    b: 113,
    b_global: 113,
    d: 113,
    d_global: 113,
    ix: 623,
    ix_global: 6230000,
    iy: 388,
    iy_global: 3880000,
    iz: 388,
    iz_global: 3880000,
    rz: "4,8",
    rz_global: "4,8",
    z: 81,
    z_global: 81000,
    zx: 81,
    zx_global: 81000,
  },
  {
    profile_section_id: 2359,
    name: "TUB145824.8",
    type: "Tube",
    designation: "TUB145824.8",
    shape: "RHS",
    country_code: "Indian",
    ax: "20,3",
    ax_global: 2030,
    b: 82,
    b_global: 82,
    d: 145,
    d_global: 145,
    ix: 530,
    ix_global: 5300000,
    iy: 229,
    iy_global: 2290000,
    iz: 555,
    iz_global: 5550000,
    rz: "4,8",
    rz_global: "4,8",
    z: "94,9",
    z_global: 94900,
    zx: "63,9",
    zx_global: 63900,
  },
  {
    profile_section_id: 2360,
    name: "TUB1251254.5",
    type: "Tube",
    designation: "TUB1251254.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "21,2",
    ax_global: 2120,
    b: 125,
    b_global: 125,
    d: 125,
    d_global: 125,
    ix: 804,
    ix_global: 8040000,
    iy: 506,
    iy_global: 5060000,
    iz: 506,
    iz_global: 5060000,
    rz: "4,5",
    rz_global: "4,5",
    z: "94,8",
    z_global: 94800,
    zx: "94,8",
    zx_global: 94800,
  },
  {
    profile_section_id: 2361,
    name: "TUB1001006",
    type: "Tube",
    designation: "TUB1001006",
    shape: "RHS",
    country_code: "Indian",
    ax: "21,6",
    ax_global: 2160,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 514,
    ix_global: 5140000,
    iy: 311,
    iy_global: 3110000,
    iz: 311,
    iz_global: 3110000,
    rz: 6,
    rz_global: 6,
    z: "75,1",
    z_global: 75100,
    zx: "75,1",
    zx_global: 75100,
  },
  {
    profile_section_id: 2362,
    name: "TUB1101105.4",
    type: "Tube",
    designation: "TUB1101105.4",
    shape: "RHS",
    country_code: "Indian",
    ax: "21,8",
    ax_global: 2180,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    ix: 635,
    ix_global: 6350000,
    iy: 391,
    iy_global: 3910000,
    iz: 391,
    iz_global: 3910000,
    rz: "5,4",
    rz_global: "5,4",
    z: "84,6",
    z_global: 84600,
    zx: "84,6",
    zx_global: 84600,
  },
  {
    profile_section_id: 2363,
    name: "TUB140805.4",
    type: "Tube",
    designation: "TUB140805.4",
    shape: "RHS",
    country_code: "Indian",
    ax: "21,8",
    ax_global: 2180,
    b: 80,
    b_global: 80,
    d: 140,
    d_global: 140,
    ix: 535,
    ix_global: 5350000,
    iy: 229,
    iy_global: 2290000,
    iz: 550,
    iz_global: 5500000,
    rz: "5,4",
    rz_global: "5,4",
    z: 98,
    z_global: 98000,
    zx: "66,4",
    zx_global: 66400,
  },
  {
    profile_section_id: 2364,
    name: "TUB1301304.5",
    type: "Tube",
    designation: "TUB1301304.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "22,1",
    ax_global: 2210,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    ix: 907,
    ix_global: 9070000,
    iy: 572,
    iy_global: 5720000,
    iz: 572,
    iz_global: 5720000,
    rz: "4,5",
    rz_global: "4,5",
    z: 103,
    z_global: 103000,
    zx: 103,
    zx_global: 103000,
  },
  {
    profile_section_id: 2365,
    name: "TUB170904.5",
    type: "Tube",
    designation: "TUB170904.5",
    shape: "RHS",
    country_code: "Indian",
    ax: "22,1",
    ax_global: 2210,
    b: 90,
    b_global: 90,
    d: 170,
    d_global: 170,
    ix: 732,
    ix_global: 7320000,
    iy: 310,
    iy_global: 3100000,
    iz: 831,
    iz_global: 8310000,
    rz: "4,5",
    rz_global: "4,5",
    z: 121,
    z_global: 121000,
    zx: "77,9",
    zx_global: 77900,
  },
  {
    profile_section_id: 2366,
    name: "TUB1131135.4",
    type: "Tube",
    designation: "TUB1131135.4",
    shape: "RHS",
    country_code: "Indian",
    ax: "22,5",
    ax_global: 2250,
    b: 113,
    b_global: 113,
    d: 113,
    d_global: 113,
    ix: 691,
    ix_global: 6910000,
    iy: 427,
    iy_global: 4270000,
    iz: 427,
    iz_global: 4270000,
    rz: "5,4",
    rz_global: "5,4",
    z: "89,7",
    z_global: 89700,
    zx: "89,7",
    zx_global: 89700,
  },
  {
    profile_section_id: 2367,
    name: "TUB145825.4",
    type: "Tube",
    designation: "TUB145825.4",
    shape: "RHS",
    country_code: "Indian",
    ax: "22,6",
    ax_global: 2260,
    b: 82,
    b_global: 82,
    d: 145,
    d_global: 145,
    ix: 586,
    ix_global: 5860000,
    iy: 251,
    iy_global: 2510000,
    iz: 611,
    iz_global: 6110000,
    rz: "5,4",
    rz_global: "5,4",
    z: 105,
    z_global: 105000,
    zx: "70,7",
    zx_global: 70700,
  },
  {
    profile_section_id: 2368,
    name: "TUB1251255",
    type: "Tube",
    designation: "TUB1251255",
    shape: "RHS",
    country_code: "Indian",
    ax: "23,4",
    ax_global: 2340,
    b: 125,
    b_global: 125,
    d: 125,
    d_global: 125,
    ix: 884,
    ix_global: 8840000,
    iy: 553,
    iy_global: 5530000,
    iz: 553,
    iz_global: 5530000,
    rz: 5,
    rz_global: 5,
    z: 104,
    z_global: 104000,
    zx: 104,
    zx_global: 104000,
  },
  {
    profile_section_id: 2369,
    name: "TUB1301304.9",
    type: "Tube",
    designation: "TUB1301304.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "23,9",
    ax_global: 2390,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    ix: 980,
    ix_global: 9800000,
    iy: 615,
    iy_global: 6150000,
    iz: 615,
    iz_global: 6150000,
    rz: "4,9",
    rz_global: "4,9",
    z: 111,
    z_global: 111000,
    zx: 111,
    zx_global: 111000,
  },
  {
    profile_section_id: 2370,
    name: "TUB170904.9",
    type: "Tube",
    designation: "TUB170904.9",
    shape: "RHS",
    country_code: "Indian",
    ax: "23,9",
    ax_global: 2390,
    b: 90,
    b_global: 90,
    d: 170,
    d_global: 170,
    ix: 790,
    ix_global: 7900000,
    iy: 332,
    iy_global: 3320000,
    iz: 893,
    iz_global: 8930000,
    rz: "4,9",
    rz_global: "4,9",
    z: 130,
    z_global: 130000,
    zx: "83,9",
    zx_global: 83900,
  },
  {
    profile_section_id: 2371,
    name: "TUB1321324.8",
    type: "Tube",
    designation: "TUB1321324.8",
    shape: "RHS",
    country_code: "Indian",
    ax: "23,8",
    ax_global: 2380,
    b: 132,
    b_global: 132,
    d: 132,
    d_global: 132,
    ix: 1009,
    ix_global: 10090000,
    iy: 634,
    iy_global: 6340000,
    iz: 634,
    iz_global: 6340000,
    rz: "4,8",
    rz_global: "4,8",
    z: 113,
    z_global: 113000,
    zx: 113,
    zx_global: 113000,
  },
  {
    profile_section_id: 2372,
    name: "TUB172924.8",
    type: "Tube",
    designation: "TUB172924.8",
    shape: "RHS",
    country_code: "Indian",
    ax: "23,8",
    ax_global: 2380,
    b: 92,
    b_global: 92,
    d: 172,
    d_global: 172,
    ix: 819,
    ix_global: 8190000,
    iy: 347,
    iy_global: 3470000,
    iz: 917,
    iz_global: 9170000,
    rz: "4,8",
    rz_global: "4,8",
    z: 132,
    z_global: 132000,
    zx: "85,6",
    zx_global: 85600,
  },
  {
    profile_section_id: 2373,
    name: "TUB1131136",
    type: "Tube",
    designation: "TUB1131136",
    shape: "RHS",
    country_code: "Indian",
    ax: "24,8",
    ax_global: 2480,
    b: 113,
    b_global: 113,
    d: 113,
    d_global: 113,
    ix: 756,
    ix_global: 7560000,
    iy: 463,
    iy_global: 4630000,
    iz: 463,
    iz_global: 4630000,
    rz: 6,
    rz_global: 6,
    z: 98,
    z_global: 98000,
    zx: 98,
    zx_global: 98000,
  },
  {
    profile_section_id: 2374,
    name: "TUB1301305.4",
    type: "Tube",
    designation: "TUB1301305.4",
    shape: "RHS",
    country_code: "Indian",
    ax: "26,2",
    ax_global: 2620,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    ix: 1069,
    ix_global: 10690000,
    iy: 667,
    iy_global: 6670000,
    iz: 667,
    iz_global: 6670000,
    rz: "5,4",
    rz_global: "5,4",
    z: 121,
    z_global: 121000,
    zx: 121,
    zx_global: 121000,
  },
  {
    profile_section_id: 2375,
    name: "TUB170905.4",
    type: "Tube",
    designation: "TUB170905.4",
    shape: "RHS",
    country_code: "Indian",
    ax: "26,2",
    ax_global: 2620,
    b: 90,
    b_global: 90,
    d: 170,
    d_global: 170,
    ix: 860,
    ix_global: 8600000,
    iy: 359,
    iy_global: 3590000,
    iz: 969,
    iz_global: 9690000,
    rz: "5,4",
    rz_global: "5,4",
    z: 142,
    z_global: 142000,
    zx: "91,3",
    zx_global: 91300,
  },
  {
    profile_section_id: 2376,
    name: "TUB1321325.4",
    type: "Tube",
    designation: "TUB1321325.4",
    shape: "RHS",
    country_code: "Indian",
    ax: "26,6",
    ax_global: 2660,
    b: 132,
    b_global: 132,
    d: 132,
    d_global: 132,
    ix: 1121,
    ix_global: 11210000,
    iy: 700,
    iy_global: 7000000,
    iz: 700,
    iz_global: 7000000,
    rz: "5,4",
    rz_global: "5,4",
    z: 125,
    z_global: 125000,
    zx: 125,
    zx_global: 125000,
  },
  {
    profile_section_id: 2377,
    name: "TUB172925.4",
    type: "Tube",
    designation: "TUB172925.4",
    shape: "RHS",
    country_code: "Indian",
    ax: "26,6",
    ax_global: 2660,
    b: 92,
    b_global: 92,
    d: 172,
    d_global: 172,
    ix: 908,
    ix_global: 9080000,
    iy: 382,
    iy_global: 3820000,
    iz: 1012,
    iz_global: 10120000,
    rz: "5,4",
    rz_global: "5,4",
    z: 147,
    z_global: 147000,
    zx: "94,9",
    zx_global: 94900,
  },
  {
    profile_section_id: 2378,
    name: "TUB1251256",
    type: "Tube",
    designation: "TUB1251256",
    shape: "RHS",
    country_code: "Indian",
    ax: "27,6",
    ax_global: 2760,
    b: 125,
    b_global: 125,
    d: 125,
    d_global: 125,
    ix: 1038,
    ix_global: 10380000,
    iy: 641,
    iy_global: 6410000,
    iz: 641,
    iz_global: 6410000,
    rz: 6,
    rz_global: 6,
    z: 122,
    z_global: 122000,
    zx: 122,
    zx_global: 122000,
  },
  {
    profile_section_id: 2379,
    name: "TUB1501505",
    type: "Tube",
    designation: "TUB1501505",
    shape: "RHS",
    country_code: "Indian",
    ax: "28,4",
    ax_global: 2840,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    ix: 1554,
    ix_global: 15540000,
    iy: 982,
    iy_global: 9820000,
    iz: 982,
    iz_global: 9820000,
    rz: 5,
    rz_global: 5,
    z: 153,
    z_global: 153000,
    zx: 153,
    zx_global: 153000,
  },
  {
    profile_section_id: 2380,
    name: "TUB1321326",
    type: "Tube",
    designation: "TUB1321326",
    shape: "RHS",
    country_code: "Indian",
    ax: "29,3",
    ax_global: 2930,
    b: 132,
    b_global: 132,
    d: 132,
    d_global: 132,
    ix: 1231,
    ix_global: 12310000,
    iy: 763,
    iy_global: 7630000,
    iz: 763,
    iz_global: 7630000,
    rz: 6,
    rz_global: 6,
    z: 137,
    z_global: 137000,
    zx: 137,
    zx_global: 137000,
  },
  {
    profile_section_id: 2381,
    name: "TUB1501506",
    type: "Tube",
    designation: "TUB1501506",
    shape: "RHS",
    country_code: "Indian",
    ax: "33,6",
    ax_global: 3360,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    ix: 1833,
    ix_global: 18330000,
    iy: 1146,
    iy_global: 11460000,
    iz: 1146,
    iz_global: 11460000,
    rz: 6,
    rz_global: 6,
    z: 180,
    z_global: 180000,
    zx: 180,
    zx_global: 180000,
  },
  {
    profile_section_id: 3279,
    name: "I80012B50012",
    type: "I Shape",
    designation: "I80012B50012",
    shape: "I",
    country_code: "Indian",
    ax: 387,
    ax_global: 38700,
    bf: 500,
    bf_global: 500,
    ct: "9,76",
    ct_global: "97,6",
    d: 824,
    d_global: 824,
    ix: 232,
    ix_global: 2320000,
    iy: 42371,
    iy_global: 423710000,
    iz: 494454,
    iz_global: 4944540000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 12394,
    zx_global: 12394000,
    zy: 2015,
    zy_global: 2015000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 824,
    height_global: 824,
  },
  {
    profile_section_id: 3280,
    name: "I80012A40012",
    type: "I Shape",
    designation: "I80012A40012",
    shape: "I",
    country_code: "Indian",
    ax: 395,
    ax_global: 39500,
    bf: 400,
    bf_global: 400,
    ct: "10,9",
    ct_global: 109,
    d: 824,
    d_global: 824,
    ix: 311,
    ix_global: 3110000,
    iy: 22046,
    iy_global: 220460000,
    iz: 471416,
    iz_global: 4714160000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 13044,
    zx_global: 13044000,
    zy: 2006,
    zy_global: 2006000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 824,
    height_global: 824,
  },
  {
    profile_section_id: 3281,
    name: "I80012B55012",
    type: "I Shape",
    designation: "I80012B55012",
    shape: "I",
    country_code: "Indian",
    ax: 399,
    ax_global: 39900,
    bf: 550,
    bf_global: 550,
    ct: "9,27",
    ct_global: "92,7",
    d: 824,
    d_global: 824,
    ix: 238,
    ix_global: 2380000,
    iy: 50646,
    iy_global: 506460000,
    iz: 514236,
    iz_global: 5142360000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 12881,
    zx_global: 12881000,
    zy: 2330,
    zy_global: 2330000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 824,
    height_global: 824,
  },
  {
    profile_section_id: 3282,
    name: "I100012B50012",
    type: "I Shape",
    designation: "I100012B50012",
    shape: "I",
    country_code: "Indian",
    ax: 411,
    ax_global: 41100,
    bf: 500,
    bf_global: 500,
    ct: "13,4",
    ct_global: 134,
    d: 1024,
    d_global: 1024,
    ix: 243,
    ix_global: 2430000,
    iy: 42374,
    iy_global: 423740000,
    iz: 799105,
    iz_global: 7991050000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 16384,
    zx_global: 16384000,
    zy: 2022,
    zy_global: 2022000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1024,
    height_global: 1024,
  },
  {
    profile_section_id: 3283,
    name: "I100012A40012",
    type: "I Shape",
    designation: "I100012A40012",
    shape: "I",
    country_code: "Indian",
    ax: 419,
    ax_global: 41900,
    bf: 400,
    bf_global: 400,
    ct: "14,82",
    ct_global: "148,2",
    d: 1024,
    d_global: 1024,
    ix: 323,
    ix_global: 3230000,
    iy: 22048,
    iy_global: 220480000,
    iz: 772815,
    iz_global: 7728150000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 17114,
    zx_global: 17114000,
    zy: 2013,
    zy_global: 2013000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1024,
    height_global: 1024,
  },
  {
    profile_section_id: 3284,
    name: "I80016B50012",
    type: "I Shape",
    designation: "I80016B50012",
    shape: "I",
    country_code: "Indian",
    ax: 419,
    ax_global: 41900,
    bf: 500,
    bf_global: 500,
    ct: "8,48",
    ct_global: "84,8",
    d: 824,
    d_global: 824,
    ix: 295,
    ix_global: 2950000,
    iy: 42926,
    iy_global: 429260000,
    iz: 511521,
    iz_global: 5115210000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 13034,
    zx_global: 13034000,
    zy: 2071,
    zy_global: 2071000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 824,
    height_global: 824,
  },
  {
    profile_section_id: 3285,
    name: "I80012A50012",
    type: "I Shape",
    designation: "I80012A50012",
    shape: "I",
    country_code: "Indian",
    ax: 419,
    ax_global: 41900,
    bf: 500,
    bf_global: 500,
    ct: "9,76",
    ct_global: "97,6",
    d: 824,
    d_global: 824,
    ix: 323,
    ix_global: 3230000,
    iy: 34246,
    iy_global: 342460000,
    iz: 510979,
    iz_global: 5109790000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 14018,
    zx_global: 14018000,
    zy: 2546,
    zy_global: 2546000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 824,
    height_global: 824,
  },
  {
    profile_section_id: 3286,
    name: "I100012B55012",
    type: "I Shape",
    designation: "I100012B55012",
    shape: "I",
    country_code: "Indian",
    ax: 423,
    ax_global: 42300,
    bf: 550,
    bf_global: 550,
    ct: "12,79",
    ct_global: "127,9",
    d: 1024,
    d_global: 1024,
    ix: 249,
    ix_global: 2490000,
    iy: 50649,
    iy_global: 506490000,
    iz: 829831,
    iz_global: 8298310000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 16991,
    zx_global: 16991000,
    zy: 2337,
    zy_global: 2337000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1024,
    height_global: 1024,
  },
  {
    profile_section_id: 3287,
    name: "I80012B50016",
    type: "I Shape",
    designation: "I80012B50016",
    shape: "I",
    country_code: "Indian",
    ax: 427,
    ax_global: 42700,
    bf: 500,
    bf_global: 500,
    ct: "11,39",
    ct_global: "113,9",
    d: 832,
    d_global: 832,
    ix: 311,
    ix_global: 3110000,
    iy: 50705,
    iy_global: 507050000,
    iz: 563013,
    iz_global: 5630130000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 14050,
    zx_global: 14050000,
    zy: 2515,
    zy_global: 2515000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 832,
    height_global: 832,
  },
  {
    profile_section_id: 3288,
    name: "I80016A40012",
    type: "I Shape",
    designation: "I80016A40012",
    shape: "I",
    country_code: "Indian",
    ax: 427,
    ax_global: 42700,
    bf: 400,
    bf_global: 400,
    ct: "9,58",
    ct_global: "95,8",
    d: 824,
    d_global: 824,
    ix: 375,
    ix_global: 3750000,
    iy: 22474,
    iy_global: 224740000,
    iz: 488483,
    iz_global: 4884830000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 13684,
    zx_global: 13684000,
    zy: 2069,
    zy_global: 2069000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 824,
    height_global: 824,
  },
  {
    profile_section_id: 3289,
    name: "I80012A40016",
    type: "I Shape",
    designation: "I80012A40016",
    shape: "I",
    country_code: "Indian",
    ax: 427,
    ax_global: 42700,
    bf: 400,
    bf_global: 400,
    ct: "12,54",
    ct_global: "125,4",
    d: 832,
    d_global: 832,
    ix: 375,
    ix_global: 3750000,
    iy: 26312,
    iy_global: 263120000,
    iz: 526263,
    iz_global: 5262630000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 14368,
    zx_global: 14368000,
    zy: 2326,
    zy_global: 2326000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 832,
    height_global: 832,
  },
  {
    profile_section_id: 3290,
    name: "I80016B55012",
    type: "I Shape",
    designation: "I80016B55012",
    shape: "I",
    country_code: "Indian",
    ax: 431,
    ax_global: 43100,
    bf: 550,
    bf_global: 550,
    ct: "8,02",
    ct_global: "80,2",
    d: 824,
    d_global: 824,
    ix: 301,
    ix_global: 3010000,
    iy: 51201,
    iy_global: 512010000,
    iz: 531303,
    iz_global: 5313030000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 13521,
    zx_global: 13521000,
    zy: 2386,
    zy_global: 2386000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 824,
    height_global: 824,
  },
  {
    profile_section_id: 3291,
    name: "I125012B50012",
    type: "I Shape",
    designation: "I125012B50012",
    shape: "I",
    country_code: "Indian",
    ax: 441,
    ax_global: 44100,
    bf: 500,
    bf_global: 500,
    ct: "18,29",
    ct_global: "182,9",
    d: 1274,
    d_global: 1274,
    ix: 258,
    ix_global: 2580000,
    iy: 42378,
    iy_global: 423780000,
    iz: 1296108,
    iz_global: 12961080000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 21709,
    zx_global: 21709000,
    zy: 2031,
    zy_global: 2031000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1274,
    height_global: 1274,
  },
  {
    profile_section_id: 3292,
    name: "I100012A50012",
    type: "I Shape",
    designation: "I100012A50012",
    shape: "I",
    country_code: "Indian",
    ax: 443,
    ax_global: 44300,
    bf: 500,
    bf_global: 500,
    ct: "13,4",
    ct_global: 134,
    d: 1024,
    d_global: 1024,
    ix: 334,
    ix_global: 3340000,
    iy: 34248,
    iy_global: 342480000,
    iz: 834267,
    iz_global: 8342670000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 18328,
    zx_global: 18328000,
    zy: 2553,
    zy_global: 2553000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1024,
    height_global: 1024,
  },
  {
    profile_section_id: 3293,
    name: "I80012B55016",
    type: "I Shape",
    designation: "I80012B55016",
    shape: "I",
    country_code: "Indian",
    ax: 443,
    ax_global: 44300,
    bf: 550,
    bf_global: 550,
    ct: "10,89",
    ct_global: "108,9",
    d: 832,
    d_global: 832,
    ix: 325,
    ix_global: 3250000,
    iy: 61738,
    iy_global: 617380000,
    iz: 589651,
    iz_global: 5896510000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 14703,
    zx_global: 14703000,
    zy: 2935,
    zy_global: 2935000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 832,
    height_global: 832,
  },
  {
    profile_section_id: 3294,
    name: "I125012A40012",
    type: "I Shape",
    designation: "I125012A40012",
    shape: "I",
    country_code: "Indian",
    ax: 449,
    ax_global: 44900,
    bf: 400,
    bf_global: 400,
    ct: "20,02",
    ct_global: "200,2",
    d: 1274,
    d_global: 1274,
    ix: 337,
    ix_global: 3370000,
    iy: 22052,
    iy_global: 220520000,
    iz: 1268015,
    iz_global: 12680150000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 22540,
    zx_global: 22540000,
    zy: 2022,
    zy_global: 2022000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1274,
    height_global: 1274,
  },
  {
    profile_section_id: 3295,
    name: "I100012A40016",
    type: "I Shape",
    designation: "I100012A40016",
    shape: "I",
    country_code: "Indian",
    ax: 451,
    ax_global: 45100,
    bf: 400,
    bf_global: 400,
    ct: "16,77",
    ct_global: "167,7",
    d: 1032,
    d_global: 1032,
    ix: 386,
    ix_global: 3860000,
    iy: 26315,
    iy_global: 263150000,
    iz: 857358,
    iz_global: 8573580000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 18759,
    zx_global: 18759000,
    zy: 2333,
    zy_global: 2333000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1032,
    height_global: 1032,
  },
  {
    profile_section_id: 3296,
    name: "I80016A50012",
    type: "I Shape",
    designation: "I80016A50012",
    shape: "I",
    country_code: "Indian",
    ax: 451,
    ax_global: 45100,
    bf: 500,
    bf_global: 500,
    ct: "8,48",
    ct_global: "84,8",
    d: 824,
    d_global: 824,
    ix: 386,
    ix_global: 3860000,
    iy: 34674,
    iy_global: 346740000,
    iz: 528046,
    iz_global: 5280460000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 14658,
    zx_global: 14658000,
    zy: 2609,
    zy_global: 2609000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 824,
    height_global: 824,
  },
  {
    profile_section_id: 3297,
    name: "I100016B50012",
    type: "I Shape",
    designation: "I100016B50012",
    shape: "I",
    country_code: "Indian",
    ax: 451,
    ax_global: 45100,
    bf: 500,
    bf_global: 500,
    ct: "11,72",
    ct_global: "117,2",
    d: 1024,
    d_global: 1024,
    ix: 322,
    ix_global: 3220000,
    iy: 42933,
    iy_global: 429330000,
    iz: 832438,
    iz_global: 8324380000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 17384,
    zx_global: 17384000,
    zy: 2084,
    zy_global: 2084000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1024,
    height_global: 1024,
  },
  {
    profile_section_id: 3298,
    name: "I100012B50016",
    type: "I Shape",
    designation: "I100012B50016",
    shape: "I",
    country_code: "Indian",
    ax: 451,
    ax_global: 45100,
    bf: 500,
    bf_global: 500,
    ct: "15,39",
    ct_global: "153,9",
    d: 1032,
    d_global: 1032,
    ix: 322,
    ix_global: 3220000,
    iy: 50707,
    iy_global: 507070000,
    iz: 904784,
    iz_global: 9047840000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 18440,
    zx_global: 18440000,
    zy: 2522,
    zy_global: 2522000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1032,
    height_global: 1032,
  },
  {
    profile_section_id: 3299,
    name: "I125012B55012",
    type: "I Shape",
    designation: "I125012B55012",
    shape: "I",
    country_code: "Indian",
    ax: 453,
    ax_global: 45300,
    bf: 550,
    bf_global: 550,
    ct: "17,54",
    ct_global: "175,4",
    d: 1274,
    d_global: 1274,
    ix: 264,
    ix_global: 2640000,
    iy: 50653,
    iy_global: 506530000,
    iz: 1343888,
    iz_global: 13438880000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 22466,
    zx_global: 22466000,
    zy: 2346,
    zy_global: 2346000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1274,
    height_global: 1274,
  },
  {
    profile_section_id: 3300,
    name: "I80012A50016",
    type: "I Shape",
    designation: "I80012A50016",
    shape: "I",
    country_code: "Indian",
    ax: 459,
    ax_global: 45900,
    bf: 500,
    bf_global: 500,
    ct: "11,39",
    ct_global: "113,9",
    d: 832,
    d_global: 832,
    ix: 402,
    ix_global: 4020000,
    iy: 42579,
    iy_global: 425790000,
    iz: 579538,
    iz_global: 5795380000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 15674,
    zx_global: 15674000,
    zy: 3046,
    zy_global: 3046000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 832,
    height_global: 832,
  },
  {
    profile_section_id: 3301,
    name: "I80012A40020",
    type: "I Shape",
    designation: "I80012A40020",
    shape: "I",
    country_code: "Indian",
    ax: 459,
    ax_global: 45900,
    bf: 400,
    bf_global: 400,
    ct: "13,82",
    ct_global: "138,2",
    d: 840,
    d_global: 840,
    ix: 479,
    ix_global: 4790000,
    iy: 30579,
    iy_global: 305790000,
    iz: 582175,
    iz_global: 5821750000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 15706,
    zx_global: 15706000,
    zy: 2646,
    zy_global: 2646000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 840,
    height_global: 840,
  },
  {
    profile_section_id: 3302,
    name: "I100016A40012",
    type: "I Shape",
    designation: "I100016A40012",
    shape: "I",
    country_code: "Indian",
    ax: 459,
    ax_global: 45900,
    bf: 400,
    bf_global: 400,
    ct: "13,14",
    ct_global: "131,4",
    d: 1024,
    d_global: 1024,
    ix: 402,
    ix_global: 4020000,
    iy: 22481,
    iy_global: 224810000,
    iz: 806148,
    iz_global: 8061480000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 18114,
    zx_global: 18114000,
    zy: 2082,
    zy_global: 2082000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1024,
    height_global: 1024,
  },
  {
    profile_section_id: 3303,
    name: "I80016A40016",
    type: "I Shape",
    designation: "I80016A40016",
    shape: "I",
    country_code: "Indian",
    ax: 459,
    ax_global: 45900,
    bf: 400,
    bf_global: 400,
    ct: "11,2",
    ct_global: 112,
    d: 832,
    d_global: 832,
    ix: 438,
    ix_global: 4380000,
    iy: 26741,
    iy_global: 267410000,
    iz: 543330,
    iz_global: 5433300000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 15008,
    zx_global: 15008000,
    zy: 2389,
    zy_global: 2389000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 832,
    height_global: 832,
  },
  {
    profile_section_id: 3304,
    name: "I80016B50016",
    type: "I Shape",
    designation: "I80016B50016",
    shape: "I",
    country_code: "Indian",
    ax: 459,
    ax_global: 45900,
    bf: 500,
    bf_global: 500,
    ct: "10,04",
    ct_global: "100,4",
    d: 832,
    d_global: 832,
    ix: 374,
    ix_global: 3740000,
    iy: 51260,
    iy_global: 512600000,
    iz: 580080,
    iz_global: 5800800000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 14690,
    zx_global: 14690000,
    zy: 2571,
    zy_global: 2571000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 832,
    height_global: 832,
  },
  {
    profile_section_id: 3305,
    name: "I100016B55012",
    type: "I Shape",
    designation: "I100016B55012",
    shape: "I",
    country_code: "Indian",
    ax: 463,
    ax_global: 46300,
    bf: 550,
    bf_global: 550,
    ct: "11,13",
    ct_global: "111,3",
    d: 1024,
    d_global: 1024,
    ix: 328,
    ix_global: 3280000,
    iy: 51208,
    iy_global: 512080000,
    iz: 863164,
    iz_global: 8631640000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 17991,
    zx_global: 17991000,
    zy: 2399,
    zy_global: 2399000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1024,
    height_global: 1024,
  },
  {
    profile_section_id: 3306,
    name: "I100012B55016",
    type: "I Shape",
    designation: "I100012B55016",
    shape: "I",
    country_code: "Indian",
    ax: 467,
    ax_global: 46700,
    bf: 550,
    bf_global: 550,
    ct: "14,79",
    ct_global: "147,9",
    d: 1032,
    d_global: 1032,
    ix: 336,
    ix_global: 3360000,
    iy: 61741,
    iy_global: 617410000,
    iz: 946077,
    iz_global: 9460770000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 19253,
    zx_global: 19253000,
    zy: 2942,
    zy_global: 2942000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1032,
    height_global: 1032,
  },
  {
    profile_section_id: 3307,
    name: "I80012B50020",
    type: "I Shape",
    designation: "I80012B50020",
    shape: "I",
    country_code: "Indian",
    ax: 467,
    ax_global: 46700,
    bf: 500,
    bf_global: 500,
    ct: "12,7",
    ct_global: 127,
    d: 840,
    d_global: 840,
    ix: 441,
    ix_global: 4410000,
    iy: 59038,
    iy_global: 590380000,
    iz: 632904,
    iz_global: 6329040000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 15722,
    zx_global: 15722000,
    zy: 3015,
    zy_global: 3015000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 840,
    height_global: 840,
  },
  {
    profile_section_id: 3308,
    name: "I125012A50012",
    type: "I Shape",
    designation: "I125012A50012",
    shape: "I",
    country_code: "Indian",
    ax: 473,
    ax_global: 47300,
    bf: 500,
    bf_global: 500,
    ct: "18,29",
    ct_global: "182,9",
    d: 1274,
    d_global: 1274,
    ix: 349,
    ix_global: 3490000,
    iy: 34252,
    iy_global: 342520000,
    iz: 1363577,
    iz_global: 13635770000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 24054,
    zx_global: 24054000,
    zy: 2562,
    zy_global: 2562000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1274,
    height_global: 1274,
  },
  {
    profile_section_id: 3309,
    name: "I80016B55016",
    type: "I Shape",
    designation: "I80016B55016",
    shape: "I",
    country_code: "Indian",
    ax: 475,
    ax_global: 47500,
    bf: 550,
    bf_global: 550,
    ct: "9,56",
    ct_global: "95,6",
    d: 832,
    d_global: 832,
    ix: 388,
    ix_global: 3880000,
    iy: 62293,
    iy_global: 622930000,
    iz: 606718,
    iz_global: 6067180000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 15343,
    zx_global: 15343000,
    zy: 2991,
    zy_global: 2991000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 832,
    height_global: 832,
  },
  {
    profile_section_id: 3310,
    name: "I125012A40016",
    type: "I Shape",
    designation: "I125012A40016",
    shape: "I",
    country_code: "Indian",
    ax: 481,
    ax_global: 48100,
    bf: 400,
    bf_global: 400,
    ct: "22,3",
    ct_global: 223,
    d: 1282,
    d_global: 1282,
    ix: 401,
    ix_global: 4010000,
    iy: 26319,
    iy_global: 263190000,
    iz: 1398679,
    iz_global: 13986790000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 24584,
    zx_global: 24584000,
    zy: 2342,
    zy_global: 2342000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1282,
    height_global: 1282,
  },
  {
    profile_section_id: 3311,
    name: "I125012B50016",
    type: "I Shape",
    designation: "I125012B50016",
    shape: "I",
    country_code: "Indian",
    ax: 481,
    ax_global: 48100,
    bf: 500,
    bf_global: 500,
    ct: "20,68",
    ct_global: "206,8",
    d: 1282,
    d_global: 1282,
    ix: 337,
    ix_global: 3370000,
    iy: 50711,
    iy_global: 507110000,
    iz: 1459437,
    iz_global: 14594370000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 24265,
    zx_global: 24265000,
    zy: 2531,
    zy_global: 2531000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1282,
    height_global: 1282,
  },
  {
    profile_section_id: 3312,
    name: "I100012A40020",
    type: "I Shape",
    designation: "I100012A40020",
    shape: "I",
    country_code: "Indian",
    ax: 483,
    ax_global: 48300,
    bf: 400,
    bf_global: 400,
    ct: "18,26",
    ct_global: "182,6",
    d: 1040,
    d_global: 1040,
    ix: 490,
    ix_global: 4900000,
    iy: 30582,
    iy_global: 305820000,
    iz: 943222,
    iz_global: 9432220000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 20416,
    zx_global: 20416000,
    zy: 2653,
    zy_global: 2653000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1040,
    height_global: 1040,
  },
  {
    profile_section_id: 3313,
    name: "I100012A50016",
    type: "I Shape",
    designation: "I100012A50016",
    shape: "I",
    country_code: "Indian",
    ax: 483,
    ax_global: 48300,
    bf: 500,
    bf_global: 500,
    ct: "15,39",
    ct_global: "153,9",
    d: 1032,
    d_global: 1032,
    ix: 413,
    ix_global: 4130000,
    iy: 42582,
    iy_global: 425820000,
    iz: 939946,
    iz_global: 9399460000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 20384,
    zx_global: 20384000,
    zy: 3053,
    zy_global: 3053000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1032,
    height_global: 1032,
  },
  {
    profile_section_id: 3314,
    name: "I100016A50012",
    type: "I Shape",
    designation: "I100016A50012",
    shape: "I",
    country_code: "Indian",
    ax: 483,
    ax_global: 48300,
    bf: 500,
    bf_global: 500,
    ct: "11,72",
    ct_global: "117,2",
    d: 1024,
    d_global: 1024,
    ix: 413,
    ix_global: 4130000,
    iy: 34681,
    iy_global: 346810000,
    iz: 867600,
    iz_global: 8676000000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 19328,
    zx_global: 19328000,
    zy: 2622,
    zy_global: 2622000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1024,
    height_global: 1024,
  },
  {
    profile_section_id: 3315,
    name: "I160012B50012",
    type: "I Shape",
    designation: "I160012B50012",
    shape: "I",
    country_code: "Indian",
    ax: 483,
    ax_global: 48300,
    bf: 500,
    bf_global: 500,
    ct: "25,58",
    ct_global: "255,8",
    d: 1624,
    d_global: 1624,
    ix: 278,
    ix_global: 2780000,
    iy: 42383,
    iy_global: 423830000,
    iz: 2225600,
    iz_global: 22256000000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 29794,
    zx_global: 29794000,
    zy: 2043,
    zy_global: 2043000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3316,
    name: "I80012B55020",
    type: "I Shape",
    designation: "I80012B55020",
    shape: "I",
    country_code: "Indian",
    ax: 487,
    ax_global: 48700,
    bf: 550,
    bf_global: 550,
    ct: "12,21",
    ct_global: "122,1",
    d: 840,
    d_global: 840,
    ix: 468,
    ix_global: 4680000,
    iy: 72830,
    iy_global: 728300000,
    iz: 666530,
    iz_global: 6665300000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 16542,
    zx_global: 16542000,
    zy: 3540,
    zy_global: 3540000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 840,
    height_global: 840,
  },
  {
    profile_section_id: 3317,
    name: "I80016A50016",
    type: "I Shape",
    designation: "I80016A50016",
    shape: "I",
    country_code: "Indian",
    ax: 491,
    ax_global: 49100,
    bf: 500,
    bf_global: 500,
    ct: "10,04",
    ct_global: "100,4",
    d: 832,
    d_global: 832,
    ix: 465,
    ix_global: 4650000,
    iy: 43008,
    iy_global: 430080000,
    iz: 596605,
    iz_global: 5966050000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 16314,
    zx_global: 16314000,
    zy: 3109,
    zy_global: 3109000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 832,
    height_global: 832,
  },
  {
    profile_section_id: 3318,
    name: "I160012A40012",
    type: "I Shape",
    designation: "I160012A40012",
    shape: "I",
    country_code: "Indian",
    ax: 491,
    ax_global: 49100,
    bf: 400,
    bf_global: 400,
    ct: "27,67",
    ct_global: "276,7",
    d: 1624,
    d_global: 1624,
    ix: 358,
    ix_global: 3580000,
    iy: 22057,
    iy_global: 220570000,
    iz: 2199205,
    iz_global: 21992050000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 30765,
    zx_global: 30765000,
    zy: 2035,
    zy_global: 2035000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3319,
    name: "I125016B50012",
    type: "I Shape",
    designation: "I125016B50012",
    shape: "I",
    country_code: "Indian",
    ax: 491,
    ax_global: 49100,
    bf: 500,
    bf_global: 500,
    ct: "16,16",
    ct_global: "161,6",
    d: 1274,
    d_global: 1274,
    ix: 357,
    ix_global: 3570000,
    iy: 42942,
    iy_global: 429420000,
    iz: 1361212,
    iz_global: 13612120000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 23271,
    zx_global: 23271000,
    zy: 2100,
    zy_global: 2100000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1274,
    height_global: 1274,
  },
  {
    profile_section_id: 3320,
    name: "I100016B50016",
    type: "I Shape",
    designation: "I100016B50016",
    shape: "I",
    country_code: "Indian",
    ax: 491,
    ax_global: 49100,
    bf: 500,
    bf_global: 500,
    ct: "13,7",
    ct_global: 137,
    d: 1032,
    d_global: 1032,
    ix: 401,
    ix_global: 4010000,
    iy: 51267,
    iy_global: 512670000,
    iz: 938117,
    iz_global: 9381170000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 19440,
    zx_global: 19440000,
    zy: 2584,
    zy_global: 2584000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1032,
    height_global: 1032,
  },
  {
    profile_section_id: 3321,
    name: "I100016A40016",
    type: "I Shape",
    designation: "I100016A40016",
    shape: "I",
    country_code: "Indian",
    ax: 491,
    ax_global: 49100,
    bf: 400,
    bf_global: 400,
    ct: "15,13",
    ct_global: "151,3",
    d: 1032,
    d_global: 1032,
    ix: 465,
    ix_global: 4650000,
    iy: 26748,
    iy_global: 267480000,
    iz: 890692,
    iz_global: 8906920000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 19759,
    zx_global: 19759000,
    zy: 2402,
    zy_global: 2402000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1032,
    height_global: 1032,
  },
  {
    profile_section_id: 3322,
    name: "I80016A40020",
    type: "I Shape",
    designation: "I80016A40020",
    shape: "I",
    country_code: "Indian",
    ax: 491,
    ax_global: 49100,
    bf: 400,
    bf_global: 400,
    ct: "12,52",
    ct_global: "125,2",
    d: 840,
    d_global: 840,
    ix: 542,
    ix_global: 5420000,
    iy: 31008,
    iy_global: 310080000,
    iz: 599242,
    iz_global: 5992420000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 16346,
    zx_global: 16346000,
    zy: 2709,
    zy_global: 2709000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 840,
    height_global: 840,
  },
  {
    profile_section_id: 3323,
    name: "I100012B50020",
    type: "I Shape",
    designation: "I100012B50020",
    shape: "I",
    country_code: "Indian",
    ax: 491,
    ax_global: 49100,
    bf: 500,
    bf_global: 500,
    ct: "16,95",
    ct_global: "169,5",
    d: 1040,
    d_global: 1040,
    ix: 453,
    ix_global: 4530000,
    iy: 59041,
    iy_global: 590410000,
    iz: 1012114,
    iz_global: 10121140000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 20512,
    zx_global: 20512000,
    zy: 3022,
    zy_global: 3022000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1040,
    height_global: 1040,
  },
  {
    profile_section_id: 3324,
    name: "I160012B55012",
    type: "I Shape",
    designation: "I160012B55012",
    shape: "I",
    country_code: "Indian",
    ax: 495,
    ax_global: 49500,
    bf: 550,
    bf_global: 550,
    ct: "24,66",
    ct_global: "246,6",
    d: 1624,
    d_global: 1624,
    ix: 284,
    ix_global: 2840000,
    iy: 50658,
    iy_global: 506580000,
    iz: 2303557,
    iz_global: 23035570000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 30761,
    zx_global: 30761000,
    zy: 2358,
    zy_global: 2358000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3325,
    name: "I125012B55016",
    type: "I Shape",
    designation: "I125012B55016",
    shape: "I",
    country_code: "Indian",
    ax: 497,
    ax_global: 49700,
    bf: 550,
    bf_global: 550,
    ct: "19,96",
    ct_global: "199,6",
    d: 1282,
    d_global: 1282,
    ix: 350,
    ix_global: 3500000,
    iy: 61744,
    iy_global: 617440000,
    iz: 1523550,
    iz_global: 15235500000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 25278,
    zx_global: 25278000,
    zy: 2951,
    zy_global: 2951000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1282,
    height_global: 1282,
  },
  {
    profile_section_id: 3326,
    name: "I80016B50020",
    type: "I Shape",
    designation: "I80016B50020",
    shape: "I",
    country_code: "Indian",
    ax: 499,
    ax_global: 49900,
    bf: 500,
    bf_global: 500,
    ct: "11,35",
    ct_global: "113,5",
    d: 840,
    d_global: 840,
    ix: 504,
    ix_global: 5040000,
    iy: 59593,
    iy_global: 595930000,
    iz: 649970,
    iz_global: 6499700000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 16362,
    zx_global: 16362000,
    zy: 3071,
    zy_global: 3071000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 840,
    height_global: 840,
  },
  {
    profile_section_id: 3327,
    name: "I80012A50020",
    type: "I Shape",
    designation: "I80012A50020",
    shape: "I",
    country_code: "Indian",
    ax: 499,
    ax_global: 49900,
    bf: 500,
    bf_global: 500,
    ct: "12,7",
    ct_global: 127,
    d: 840,
    d_global: 840,
    ix: 532,
    ix_global: 5320000,
    iy: 50912,
    iy_global: 509120000,
    iz: 649429,
    iz_global: 6494290000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 17346,
    zx_global: 17346000,
    zy: 3546,
    zy_global: 3546000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 840,
    height_global: 840,
  },
  {
    profile_section_id: 3328,
    name: "I125016A40012",
    type: "I Shape",
    designation: "I125016A40012",
    shape: "I",
    country_code: "Indian",
    ax: 499,
    ax_global: 49900,
    bf: 400,
    bf_global: 400,
    ct: "17,93",
    ct_global: "179,3",
    d: 1274,
    d_global: 1274,
    ix: 436,
    ix_global: 4360000,
    iy: 22490,
    iy_global: 224900000,
    iz: 1333120,
    iz_global: 13331200000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 24102,
    zx_global: 24102000,
    zy: 2098,
    zy_global: 2098000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1274,
    height_global: 1274,
  },
  {
    profile_section_id: 3329,
    name: "I80012A40025",
    type: "I Shape",
    designation: "I80012A40025",
    shape: "I",
    country_code: "Indian",
    ax: 499,
    ax_global: 49900,
    bf: 400,
    bf_global: 400,
    ct: "15,11",
    ct_global: "151,1",
    d: 850,
    d_global: 850,
    ix: 682,
    ix_global: 6820000,
    iy: 35912,
    iy_global: 359120000,
    iz: 653579,
    iz_global: 6535790000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 17396,
    zx_global: 17396000,
    zy: 3046,
    zy_global: 3046000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 850,
    height_global: 850,
  },
  {
    profile_section_id: 3330,
    name: "I125016B55012",
    type: "I Shape",
    designation: "I125016B55012",
    shape: "I",
    country_code: "Indian",
    ax: 503,
    ax_global: 50300,
    bf: 550,
    bf_global: 550,
    ct: "15,4",
    ct_global: 154,
    d: 1274,
    d_global: 1274,
    ix: 362,
    ix_global: 3620000,
    iy: 51217,
    iy_global: 512170000,
    iz: 1408993,
    iz_global: 14089930000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 24028,
    zx_global: 24028000,
    zy: 2415,
    zy_global: 2415000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1274,
    height_global: 1274,
  },
  {
    profile_section_id: 3331,
    name: "I100016B55016",
    type: "I Shape",
    designation: "I100016B55016",
    shape: "I",
    country_code: "Indian",
    ax: 507,
    ax_global: 50700,
    bf: 550,
    bf_global: 550,
    ct: "13,09",
    ct_global: "130,9",
    d: 1032,
    d_global: 1032,
    ix: 415,
    ix_global: 4150000,
    iy: 62300,
    iy_global: 623000000,
    iz: 979411,
    iz_global: 9794110000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 20253,
    zx_global: 20253000,
    zy: 3004,
    zy_global: 3004000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1032,
    height_global: 1032,
  },
  {
    profile_section_id: 3332,
    name: "I100012B55020",
    type: "I Shape",
    designation: "I100012B55020",
    shape: "I",
    country_code: "Indian",
    ax: 511,
    ax_global: 51100,
    bf: 550,
    bf_global: 550,
    ct: "16,36",
    ct_global: "163,6",
    d: 1040,
    d_global: 1040,
    ix: 479,
    ix_global: 4790000,
    iy: 72832,
    iy_global: 728320000,
    iz: 1064140,
    iz_global: 10641400000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 21532,
    zx_global: 21532000,
    zy: 3547,
    zy_global: 3547000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1040,
    height_global: 1040,
  },
  {
    profile_section_id: 3333,
    name: "I125012A40020",
    type: "I Shape",
    designation: "I125012A40020",
    shape: "I",
    country_code: "Indian",
    ax: 513,
    ax_global: 51300,
    bf: 400,
    bf_global: 400,
    ct: "23,98",
    ct_global: "239,8",
    d: 1290,
    d_global: 1290,
    ix: 505,
    ix_global: 5050000,
    iy: 30585,
    iy_global: 305850000,
    iz: 1530983,
    iz_global: 15309830000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 26642,
    zx_global: 26642000,
    zy: 2662,
    zy_global: 2662000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1290,
    height_global: 1290,
  },
  {
    profile_section_id: 3334,
    name: "I125012A50016",
    type: "I Shape",
    designation: "I125012A50016",
    shape: "I",
    country_code: "Indian",
    ax: 513,
    ax_global: 51300,
    bf: 500,
    bf_global: 500,
    ct: "20,68",
    ct_global: "206,8",
    d: 1282,
    d_global: 1282,
    ix: 428,
    ix_global: 4280000,
    iy: 42585,
    iy_global: 425850000,
    iz: 1526906,
    iz_global: 15269060000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 26610,
    zx_global: 26610000,
    zy: 3062,
    zy_global: 3062000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1282,
    height_global: 1282,
  },
  {
    profile_section_id: 3335,
    name: "I160012A50012",
    type: "I Shape",
    designation: "I160012A50012",
    shape: "I",
    country_code: "Indian",
    ax: 515,
    ax_global: 51500,
    bf: 500,
    bf_global: 500,
    ct: "25,58",
    ct_global: "255,8",
    d: 1624,
    d_global: 1624,
    ix: 369,
    ix_global: 3690000,
    iy: 34257,
    iy_global: 342570000,
    iz: 2355120,
    iz_global: 23551200000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 32700,
    zx_global: 32700000,
    zy: 2575,
    zy_global: 2575000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3336,
    name: "I80012B50025",
    type: "I Shape",
    designation: "I80012B50025",
    shape: "I",
    country_code: "Indian",
    ax: 517,
    ax_global: 51700,
    bf: 500,
    bf_global: 500,
    ct: "14,04",
    ct_global: "140,4",
    d: 850,
    d_global: 850,
    ix: 695,
    ix_global: 6950000,
    iy: 69455,
    iy_global: 694550000,
    iz: 722158,
    iz_global: 7221580000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 17834,
    zx_global: 17834000,
    zy: 3640,
    zy_global: 3640000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 850,
    height_global: 850,
  },
  {
    profile_section_id: 3337,
    name: "I80016B55020",
    type: "I Shape",
    designation: "I80016B55020",
    shape: "I",
    country_code: "Indian",
    ax: 519,
    ax_global: 51900,
    bf: 550,
    bf_global: 550,
    ct: "10,85",
    ct_global: "108,5",
    d: 840,
    d_global: 840,
    ix: 531,
    ix_global: 5310000,
    iy: 73385,
    iy_global: 733850000,
    iz: 683597,
    iz_global: 6835970000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 17182,
    zx_global: 17182000,
    zy: 3596,
    zy_global: 3596000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 840,
    height_global: 840,
  },
  {
    profile_section_id: 3338,
    name: "I125012B50020",
    type: "I Shape",
    designation: "I125012B50020",
    shape: "I",
    country_code: "Indian",
    ax: 521,
    ax_global: 52100,
    bf: 500,
    bf_global: 500,
    ct: "22,48",
    ct_global: "224,8",
    d: 1290,
    d_global: 1290,
    ix: 467,
    ix_global: 4670000,
    iy: 59044,
    iy_global: 590440000,
    iz: 1624817,
    iz_global: 16248170000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 26837,
    zx_global: 26837000,
    zy: 3031,
    zy_global: 3031000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1290,
    height_global: 1290,
  },
  {
    profile_section_id: 3339,
    name: "I160012A40016",
    type: "I Shape",
    designation: "I160012A40016",
    shape: "I",
    country_code: "Indian",
    ax: 523,
    ax_global: 52300,
    bf: 400,
    bf_global: 400,
    ct: "30,31",
    ct_global: "303,1",
    d: 1632,
    d_global: 1632,
    ix: 421,
    ix_global: 4210000,
    iy: 26324,
    iy_global: 263240000,
    iz: 2411236,
    iz_global: 24112360000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 33370,
    zx_global: 33370000,
    zy: 2355,
    zy_global: 2355000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3340,
    name: "I125016A50012",
    type: "I Shape",
    designation: "I125016A50012",
    shape: "I",
    country_code: "Indian",
    ax: 523,
    ax_global: 52300,
    bf: 500,
    bf_global: 500,
    ct: "16,16",
    ct_global: "161,6",
    d: 1274,
    d_global: 1274,
    ix: 448,
    ix_global: 4480000,
    iy: 34690,
    iy_global: 346900000,
    iz: 1428681,
    iz_global: 14286810000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 25616,
    zx_global: 25616000,
    zy: 2638,
    zy_global: 2638000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1274,
    height_global: 1274,
  },
  {
    profile_section_id: 3341,
    name: "I100016A50016",
    type: "I Shape",
    designation: "I100016A50016",
    shape: "I",
    country_code: "Indian",
    ax: 523,
    ax_global: 52300,
    bf: 500,
    bf_global: 500,
    ct: "13,7",
    ct_global: 137,
    d: 1032,
    d_global: 1032,
    ix: 492,
    ix_global: 4920000,
    iy: 43014,
    iy_global: 430140000,
    iz: 973279,
    iz_global: 9732790000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 21384,
    zx_global: 21384000,
    zy: 3122,
    zy_global: 3122000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1032,
    height_global: 1032,
  },
  {
    profile_section_id: 3342,
    name: "I160012B50016",
    type: "I Shape",
    designation: "I160012B50016",
    shape: "I",
    country_code: "Indian",
    ax: 523,
    ax_global: 52300,
    bf: 500,
    bf_global: 500,
    ct: "28,42",
    ct_global: "284,2",
    d: 1632,
    d_global: 1632,
    ix: 357,
    ix_global: 3570000,
    iy: 50716,
    iy_global: 507160000,
    iz: 2490639,
    iz_global: 24906390000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 33050,
    zx_global: 33050000,
    zy: 2543,
    zy_global: 2543000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3343,
    name: "I100016A40020",
    type: "I Shape",
    designation: "I100016A40020",
    shape: "I",
    country_code: "Indian",
    ax: 523,
    ax_global: 52300,
    bf: 400,
    bf_global: 400,
    ct: "16,7",
    ct_global: 167,
    d: 1040,
    d_global: 1040,
    ix: 569,
    ix_global: 5690000,
    iy: 31014,
    iy_global: 310140000,
    iz: 976556,
    iz_global: 9765560000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 21416,
    zx_global: 21416000,
    zy: 2722,
    zy_global: 2722000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1040,
    height_global: 1040,
  },
  {
    profile_section_id: 3344,
    name: "I100012A40025",
    type: "I Shape",
    designation: "I100012A40025",
    shape: "I",
    country_code: "Indian",
    ax: 523,
    ax_global: 52300,
    bf: 400,
    bf_global: 400,
    ct: "19,7",
    ct_global: 197,
    d: 1050,
    d_global: 1050,
    ix: 694,
    ix_global: 6940000,
    iy: 35915,
    iy_global: 359150000,
    iz: 1052426,
    iz_global: 10524260000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 22506,
    zx_global: 22506000,
    zy: 3053,
    zy_global: 3053000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1050,
    height_global: 1050,
  },
  {
    profile_section_id: 3345,
    name: "I100012A50020",
    type: "I Shape",
    designation: "I100012A50020",
    shape: "I",
    country_code: "Indian",
    ax: 523,
    ax_global: 52300,
    bf: 500,
    bf_global: 500,
    ct: "16,95",
    ct_global: "169,5",
    d: 1040,
    d_global: 1040,
    ix: 544,
    ix_global: 5440000,
    iy: 50915,
    iy_global: 509150000,
    iz: 1047276,
    iz_global: 10472760000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 22456,
    zx_global: 22456000,
    zy: 3553,
    zy_global: 3553000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1040,
    height_global: 1040,
  },
  {
    profile_section_id: 3346,
    name: "I80016A40025",
    type: "I Shape",
    designation: "I80016A40025",
    shape: "I",
    country_code: "Indian",
    ax: 531,
    ax_global: 53100,
    bf: 400,
    bf_global: 400,
    ct: "13,87",
    ct_global: "138,7",
    d: 850,
    d_global: 850,
    ix: 745,
    ix_global: 7450000,
    iy: 36341,
    iy_global: 363410000,
    iz: 670645,
    iz_global: 6706450000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 18036,
    zx_global: 18036000,
    zy: 3109,
    zy_global: 3109000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 850,
    height_global: 850,
  },
  {
    profile_section_id: 3347,
    name: "I125016A40016",
    type: "I Shape",
    designation: "I125016A40016",
    shape: "I",
    country_code: "Indian",
    ax: 531,
    ax_global: 53100,
    bf: 400,
    bf_global: 400,
    ct: "20,34",
    ct_global: "203,4",
    d: 1282,
    d_global: 1282,
    ix: 499,
    ix_global: 4990000,
    iy: 26756,
    iy_global: 267560000,
    iz: 1463783,
    iz_global: 14637830000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 26147,
    zx_global: 26147000,
    zy: 2418,
    zy_global: 2418000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1282,
    height_global: 1282,
  },
  {
    profile_section_id: 3348,
    name: "I100016B50020",
    type: "I Shape",
    designation: "I100016B50020",
    shape: "I",
    country_code: "Indian",
    ax: 531,
    ax_global: 53100,
    bf: 500,
    bf_global: 500,
    ct: "15,3",
    ct_global: 153,
    d: 1040,
    d_global: 1040,
    ix: 531,
    ix_global: 5310000,
    iy: 59600,
    iy_global: 596000000,
    iz: 1045447,
    iz_global: 10454470000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 21512,
    zx_global: 21512000,
    zy: 3084,
    zy_global: 3084000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1040,
    height_global: 1040,
  },
  {
    profile_section_id: 3349,
    name: "I80016A50020",
    type: "I Shape",
    designation: "I80016A50020",
    shape: "I",
    country_code: "Indian",
    ax: 531,
    ax_global: 53100,
    bf: 500,
    bf_global: 500,
    ct: "11,35",
    ct_global: "113,5",
    d: 840,
    d_global: 840,
    ix: 595,
    ix_global: 5950000,
    iy: 51341,
    iy_global: 513410000,
    iz: 666495,
    iz_global: 6664950000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 17986,
    zx_global: 17986000,
    zy: 3609,
    zy_global: 3609000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 840,
    height_global: 840,
  },
  {
    profile_section_id: 3350,
    name: "I125016B50016",
    type: "I Shape",
    designation: "I125016B50016",
    shape: "I",
    country_code: "Indian",
    ax: 531,
    ax_global: 53100,
    bf: 500,
    bf_global: 500,
    ct: "18,61",
    ct_global: "186,1",
    d: 1282,
    d_global: 1282,
    ix: 435,
    ix_global: 4350000,
    iy: 51275,
    iy_global: 512750000,
    iz: 1524541,
    iz_global: 15245410000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 25827,
    zx_global: 25827000,
    zy: 2600,
    zy_global: 2600000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1282,
    height_global: 1282,
  },
  {
    profile_section_id: 3351,
    name: "I160012B55016",
    type: "I Shape",
    designation: "I160012B55016",
    shape: "I",
    country_code: "Indian",
    ax: 539,
    ax_global: 53900,
    bf: 550,
    bf_global: 550,
    ct: "27,57",
    ct_global: "275,7",
    d: 1632,
    d_global: 1632,
    ix: 371,
    ix_global: 3710000,
    iy: 61749,
    iy_global: 617490000,
    iz: 2595100,
    iz_global: 25951000000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 34343,
    zx_global: 34343000,
    zy: 2963,
    zy_global: 2963000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3352,
    name: "I125012B55020",
    type: "I Shape",
    designation: "I125012B55020",
    shape: "I",
    country_code: "Indian",
    ax: 541,
    ax_global: 54100,
    bf: 550,
    bf_global: 550,
    ct: "21,8",
    ct_global: 218,
    d: 1290,
    d_global: 1290,
    ix: 494,
    ix_global: 4940000,
    iy: 72836,
    iy_global: 728360000,
    iz: 1705468,
    iz_global: 17054680000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 28107,
    zx_global: 28107000,
    zy: 3556,
    zy_global: 3556000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1290,
    height_global: 1290,
  },
  {
    profile_section_id: 3353,
    name: "I100012B50025",
    type: "I Shape",
    designation: "I100012B50025",
    shape: "I",
    country_code: "Indian",
    ax: 541,
    ax_global: 54100,
    bf: 500,
    bf_global: 500,
    ct: "18,48",
    ct_global: "184,8",
    d: 1050,
    d_global: 1050,
    ix: 707,
    ix_global: 7070000,
    iy: 69457,
    iy_global: 694570000,
    iz: 1148618,
    iz_global: 11486180000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 23124,
    zx_global: 23124000,
    zy: 3647,
    zy_global: 3647000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1050,
    height_global: 1050,
  },
  {
    profile_section_id: 3354,
    name: "I80012B55025",
    type: "I Shape",
    designation: "I80012B55025",
    shape: "I",
    country_code: "Indian",
    ax: 542,
    ax_global: 54200,
    bf: 550,
    bf_global: 550,
    ct: "13,56",
    ct_global: "135,6",
    d: 850,
    d_global: 850,
    ix: 747,
    ix_global: 7470000,
    iy: 86694,
    iy_global: 866940000,
    iz: 764710,
    iz_global: 7647100000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 18866,
    zx_global: 18866000,
    zy: 4296,
    zy_global: 4296000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 850,
    height_global: 850,
  },
  {
    profile_section_id: 3355,
    name: "I125016B55016",
    type: "I Shape",
    designation: "I125016B55016",
    shape: "I",
    country_code: "Indian",
    ax: 547,
    ax_global: 54700,
    bf: 550,
    bf_global: 550,
    ct: "17,85",
    ct_global: "178,5",
    d: 1282,
    d_global: 1282,
    ix: 449,
    ix_global: 4490000,
    iy: 62308,
    iy_global: 623080000,
    iz: 1588654,
    iz_global: 15886540000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 26840,
    zx_global: 26840000,
    zy: 3020,
    zy_global: 3020000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1282,
    height_global: 1282,
  },
  {
    profile_section_id: 3356,
    name: "I160016B50012",
    type: "I Shape",
    designation: "I160016B50012",
    shape: "I",
    country_code: "Indian",
    ax: 547,
    ax_global: 54700,
    bf: 500,
    bf_global: 500,
    ct: "22,89",
    ct_global: "228,9",
    d: 1624,
    d_global: 1624,
    ix: 404,
    ix_global: 4040000,
    iy: 42954,
    iy_global: 429540000,
    iz: 2362133,
    iz_global: 23621330000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 32354,
    zx_global: 32354000,
    zy: 2122,
    zy_global: 2122000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3357,
    name: "I80016B50025",
    type: "I Shape",
    designation: "I80016B50025",
    shape: "I",
    country_code: "Indian",
    ax: 549,
    ax_global: 54900,
    bf: 500,
    bf_global: 500,
    ct: "12,72",
    ct_global: "127,2",
    d: 850,
    d_global: 850,
    ix: 758,
    ix_global: 7580000,
    iy: 70010,
    iy_global: 700100000,
    iz: 739224,
    iz_global: 7392240000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 18474,
    zx_global: 18474000,
    zy: 3696,
    zy_global: 3696000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 850,
    height_global: 850,
  },
  {
    profile_section_id: 3358,
    name: "I80012A50025",
    type: "I Shape",
    designation: "I80012A50025",
    shape: "I",
    country_code: "Indian",
    ax: 549,
    ax_global: 54900,
    bf: 500,
    bf_global: 500,
    ct: "14,04",
    ct_global: "140,4",
    d: 850,
    d_global: 850,
    ix: 786,
    ix_global: 7860000,
    iy: 61329,
    iy_global: 613290000,
    iz: 738683,
    iz_global: 7386830000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 19459,
    zx_global: 19459000,
    zy: 4171,
    zy_global: 4171000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 850,
    height_global: 850,
  },
  {
    profile_section_id: 3359,
    name: "I160012C50012",
    type: "I Shape",
    designation: "I160012C50012",
    shape: "I",
    country_code: "Indian",
    ax: 551,
    ax_global: 55100,
    bf: 500,
    bf_global: 500,
    ct: "25,58",
    ct_global: "255,8",
    d: 1624,
    d_global: 1624,
    ix: 408,
    ix_global: 4080000,
    iy: 45941,
    iy_global: 459410000,
    iz: 2580240,
    iz_global: 25802400000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 34950,
    zx_global: 34950000,
    zy: 2629,
    zy_global: 2629000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3360,
    name: "I100016B55020",
    type: "I Shape",
    designation: "I100016B55020",
    shape: "I",
    country_code: "Indian",
    ax: 551,
    ax_global: 55100,
    bf: 550,
    bf_global: 550,
    ct: "14,68",
    ct_global: "146,8",
    d: 1040,
    d_global: 1040,
    ix: 558,
    ix_global: 5580000,
    iy: 73392,
    iy_global: 733920000,
    iz: 1097474,
    iz_global: 10974740000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 22532,
    zx_global: 22532000,
    zy: 3609,
    zy_global: 3609000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1040,
    height_global: 1040,
  },
  {
    profile_section_id: 3361,
    name: "I125012A40025",
    type: "I Shape",
    designation: "I125012A40025",
    shape: "I",
    country_code: "Indian",
    ax: 553,
    ax_global: 55300,
    bf: 400,
    bf_global: 400,
    ct: "25,58",
    ct_global: "255,8",
    d: 1300,
    d_global: 1300,
    ix: 708,
    ix_global: 7080000,
    iy: 35919,
    iy_global: 359190000,
    iz: 1698686,
    iz_global: 16986860000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 29232,
    zx_global: 29232000,
    zy: 3062,
    zy_global: 3062000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1300,
    height_global: 1300,
  },
  {
    profile_section_id: 3362,
    name: "I125012A50020",
    type: "I Shape",
    designation: "I125012A50020",
    shape: "I",
    country_code: "Indian",
    ax: 553,
    ax_global: 55300,
    bf: 500,
    bf_global: 500,
    ct: "22,48",
    ct_global: "224,8",
    d: 1290,
    d_global: 1290,
    ix: 558,
    ix_global: 5580000,
    iy: 50919,
    iy_global: 509190000,
    iz: 1692286,
    iz_global: 16922860000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 29182,
    zx_global: 29182000,
    zy: 3562,
    zy_global: 3562000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1290,
    height_global: 1290,
  },
  {
    profile_section_id: 3363,
    name: "I80012A40032",
    type: "I Shape",
    designation: "I80012A40032",
    shape: "I",
    country_code: "Indian",
    ax: 555,
    ax_global: 55500,
    bf: 400,
    bf_global: 400,
    ct: "16,52",
    ct_global: "165,2",
    d: 864,
    d_global: 864,
    ix: 1139,
    ix_global: 11390000,
    iy: 43379,
    iy_global: 433790000,
    iz: 756404,
    iz_global: 7564040000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 19796,
    zx_global: 19796000,
    zy: 3606,
    zy_global: 3606000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 864,
    height_global: 864,
  },
  {
    profile_section_id: 3364,
    name: "I160016A40012",
    type: "I Shape",
    designation: "I160016A40012",
    shape: "I",
    country_code: "Indian",
    ax: 555,
    ax_global: 55500,
    bf: 400,
    bf_global: 400,
    ct: "25,11",
    ct_global: "251,1",
    d: 1624,
    d_global: 1624,
    ix: 484,
    ix_global: 4840000,
    iy: 22502,
    iy_global: 225020000,
    iz: 2335738,
    iz_global: 23357380000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 33325,
    zx_global: 33325000,
    zy: 2120,
    zy_global: 2120000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3365,
    name: "I160012A40020",
    type: "I Shape",
    designation: "I160012A40020",
    shape: "I",
    country_code: "Indian",
    ax: 555,
    ax_global: 55500,
    bf: 400,
    bf_global: 400,
    ct: "32,21",
    ct_global: "322,1",
    d: 1640,
    d_global: 1640,
    ix: 525,
    ix_global: 5250000,
    iy: 30590,
    iy_global: 305900000,
    iz: 2625356,
    iz_global: 26253560000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 35988,
    zx_global: 35988000,
    zy: 2675,
    zy_global: 2675000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3366,
    name: "I160012A50016",
    type: "I Shape",
    designation: "I160012A50016",
    shape: "I",
    country_code: "Indian",
    ax: 555,
    ax_global: 55500,
    bf: 500,
    bf_global: 500,
    ct: "28,42",
    ct_global: "284,2",
    d: 1632,
    d_global: 1632,
    ix: 448,
    ix_global: 4480000,
    iy: 42590,
    iy_global: 425900000,
    iz: 2620159,
    iz_global: 26201590000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 35956,
    zx_global: 35956000,
    zy: 3075,
    zy_global: 3075000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3367,
    name: "I160016B55012",
    type: "I Shape",
    designation: "I160016B55012",
    shape: "I",
    country_code: "Indian",
    ax: 559,
    ax_global: 55900,
    bf: 550,
    bf_global: 550,
    ct: "21,93",
    ct_global: "219,3",
    d: 1624,
    d_global: 1624,
    ix: 410,
    ix_global: 4100000,
    iy: 51229,
    iy_global: 512290000,
    iz: 2440091,
    iz_global: 24400910000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 33321,
    zx_global: 33321000,
    zy: 2437,
    zy_global: 2437000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3368,
    name: "I160012B50020",
    type: "I Shape",
    designation: "I160012B50020",
    shape: "I",
    country_code: "Indian",
    ax: 563,
    ax_global: 56300,
    bf: 500,
    bf_global: 500,
    ct: "30,5",
    ct_global: 305,
    d: 1640,
    d_global: 1640,
    ix: 487,
    ix_global: 4870000,
    iy: 59049,
    iy_global: 590490000,
    iz: 2758289,
    iz_global: 27582890000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 36322,
    zx_global: 36322000,
    zy: 3043,
    zy_global: 3043000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3369,
    name: "I125016A50016",
    type: "I Shape",
    designation: "I125016A50016",
    shape: "I",
    country_code: "Indian",
    ax: 563,
    ax_global: 56300,
    bf: 500,
    bf_global: 500,
    ct: "18,61",
    ct_global: "186,1",
    d: 1282,
    d_global: 1282,
    ix: 526,
    ix_global: 5260000,
    iy: 43023,
    iy_global: 430230000,
    iz: 1592010,
    iz_global: 15920100000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 28172,
    zx_global: 28172000,
    zy: 3138,
    zy_global: 3138000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1282,
    height_global: 1282,
  },
  {
    profile_section_id: 3370,
    name: "I125016A40020",
    type: "I Shape",
    designation: "I125016A40020",
    shape: "I",
    country_code: "Indian",
    ax: 563,
    ax_global: 56300,
    bf: 400,
    bf_global: 400,
    ct: "22,18",
    ct_global: "221,8",
    d: 1290,
    d_global: 1290,
    ix: 603,
    ix_global: 6030000,
    iy: 31023,
    iy_global: 310230000,
    iz: 1596087,
    iz_global: 15960870000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 28204,
    zx_global: 28204000,
    zy: 2738,
    zy_global: 2738000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1290,
    height_global: 1290,
  },
  {
    profile_section_id: 3371,
    name: "I160012C55012",
    type: "I Shape",
    designation: "I160012C55012",
    shape: "I",
    country_code: "Indian",
    ax: 563,
    ax_global: 56300,
    bf: 550,
    bf_global: 550,
    ct: "24,66",
    ct_global: "246,6",
    d: 1624,
    d_global: 1624,
    ix: 414,
    ix_global: 4140000,
    iy: 51246,
    iy_global: 512460000,
    iz: 2658198,
    iz_global: 26581980000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 35917,
    zx_global: 35917000,
    zy: 2944,
    zy_global: 2944000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3372,
    name: "I100016A50020",
    type: "I Shape",
    designation: "I100016A50020",
    shape: "I",
    country_code: "Indian",
    ax: 563,
    ax_global: 56300,
    bf: 500,
    bf_global: 500,
    ct: "15,3",
    ct_global: 153,
    d: 1040,
    d_global: 1040,
    ix: 622,
    ix_global: 6220000,
    iy: 51348,
    iy_global: 513480000,
    iz: 1080609,
    iz_global: 10806090000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 23456,
    zx_global: 23456000,
    zy: 3622,
    zy_global: 3622000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1040,
    height_global: 1040,
  },
  {
    profile_section_id: 3373,
    name: "I100016A40025",
    type: "I Shape",
    designation: "I100016A40025",
    shape: "I",
    country_code: "Indian",
    ax: 563,
    ax_global: 56300,
    bf: 400,
    bf_global: 400,
    ct: "18,27",
    ct_global: "182,7",
    d: 1050,
    d_global: 1050,
    ix: 772,
    ix_global: 7720000,
    iy: 36348,
    iy_global: 363480000,
    iz: 1085759,
    iz_global: 10857590000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 23506,
    zx_global: 23506000,
    zy: 3122,
    zy_global: 3122000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1050,
    height_global: 1050,
  },
  {
    profile_section_id: 3374,
    name: "I100012B55025",
    type: "I Shape",
    designation: "I100012B55025",
    shape: "I",
    country_code: "Indian",
    ax: 566,
    ax_global: 56600,
    bf: 550,
    bf_global: 550,
    ct: "17,93",
    ct_global: "179,3",
    d: 1050,
    d_global: 1050,
    ix: 759,
    ix_global: 7590000,
    iy: 86697,
    iy_global: 866970000,
    iz: 1214295,
    iz_global: 12142950000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 24406,
    zx_global: 24406000,
    zy: 4303,
    zy_global: 4303000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1050,
    height_global: 1050,
  },
  {
    profile_section_id: 3375,
    name: "I125016B50020",
    type: "I Shape",
    designation: "I125016B50020",
    shape: "I",
    country_code: "Indian",
    ax: 571,
    ax_global: 57100,
    bf: 500,
    bf_global: 500,
    ct: "20,51",
    ct_global: "205,1",
    d: 1290,
    d_global: 1290,
    ix: 566,
    ix_global: 5660000,
    iy: 59608,
    iy_global: 596080000,
    iz: 1689921,
    iz_global: 16899210000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 28399,
    zx_global: 28399000,
    zy: 3100,
    zy_global: 3100000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1290,
    height_global: 1290,
  },
  {
    profile_section_id: 3376,
    name: "I125012B50025",
    type: "I Shape",
    designation: "I125012B50025",
    shape: "I",
    country_code: "Indian",
    ax: 571,
    ax_global: 57100,
    bf: 500,
    bf_global: 500,
    ct: "24,22",
    ct_global: "242,2",
    d: 1300,
    d_global: 1300,
    ix: 721,
    ix_global: 7210000,
    iy: 69461,
    iy_global: 694610000,
    iz: 1834446,
    iz_global: 18344460000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 30074,
    zx_global: 30074000,
    zy: 3656,
    zy_global: 3656000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1300,
    height_global: 1300,
  },
  {
    profile_section_id: 3377,
    name: "I100012A50025",
    type: "I Shape",
    designation: "I100012A50025",
    shape: "I",
    country_code: "Indian",
    ax: 573,
    ax_global: 57300,
    bf: 500,
    bf_global: 500,
    ct: "18,48",
    ct_global: "184,8",
    d: 1050,
    d_global: 1050,
    ix: 798,
    ix_global: 7980000,
    iy: 61332,
    iy_global: 613320000,
    iz: 1183780,
    iz_global: 11837800000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 25069,
    zx_global: 25069000,
    zy: 4178,
    zy_global: 4178000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1050,
    height_global: 1050,
  },
  {
    profile_section_id: 3378,
    name: "I80016B55025",
    type: "I Shape",
    designation: "I80016B55025",
    shape: "I",
    country_code: "Indian",
    ax: 574,
    ax_global: 57400,
    bf: 550,
    bf_global: 550,
    ct: "12,22",
    ct_global: "122,2",
    d: 850,
    d_global: 850,
    ix: 810,
    ix_global: 8100000,
    iy: 87249,
    iy_global: 872490000,
    iz: 781776,
    iz_global: 7817760000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 19506,
    zx_global: 19506000,
    zy: 4353,
    zy_global: 4353000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 850,
    height_global: 850,
  },
  {
    profile_section_id: 3379,
    name: "I160016A50012",
    type: "I Shape",
    designation: "I160016A50012",
    shape: "I",
    country_code: "Indian",
    ax: 579,
    ax_global: 57900,
    bf: 500,
    bf_global: 500,
    ct: "22,89",
    ct_global: "228,9",
    d: 1624,
    d_global: 1624,
    ix: 495,
    ix_global: 4950000,
    iy: 34702,
    iy_global: 347020000,
    iz: 2491654,
    iz_global: 24916540000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 35260,
    zx_global: 35260000,
    zy: 2660,
    zy_global: 2660000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3380,
    name: "I100012A40032",
    type: "I Shape",
    designation: "I100012A40032",
    shape: "I",
    country_code: "Indian",
    ax: 579,
    ax_global: 57900,
    bf: 400,
    bf_global: 400,
    ct: "21,24",
    ct_global: "212,4",
    d: 1064,
    d_global: 1064,
    ix: 1151,
    ix_global: 11510000,
    iy: 43382,
    iy_global: 433820000,
    iz: 1208843,
    iz_global: 12088430000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 25466,
    zx_global: 25466000,
    zy: 3613,
    zy_global: 3613000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1064,
    height_global: 1064,
  },
  {
    profile_section_id: 3381,
    name: "I100016B50025",
    type: "I Shape",
    designation: "I100016B50025",
    shape: "I",
    country_code: "Indian",
    ax: 581,
    ax_global: 58100,
    bf: 500,
    bf_global: 500,
    ct: "16,92",
    ct_global: "169,2",
    d: 1050,
    d_global: 1050,
    ix: 786,
    ix_global: 7860000,
    iy: 70017,
    iy_global: 700170000,
    iz: 1181951,
    iz_global: 11819510000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 24124,
    zx_global: 24124000,
    zy: 3709,
    zy_global: 3709000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1050,
    height_global: 1050,
  },
  {
    profile_section_id: 3382,
    name: "I80016A50025",
    type: "I Shape",
    designation: "I80016A50025",
    shape: "I",
    country_code: "Indian",
    ax: 581,
    ax_global: 58100,
    bf: 500,
    bf_global: 500,
    ct: "12,72",
    ct_global: "127,2",
    d: 850,
    d_global: 850,
    ix: 849,
    ix_global: 8490000,
    iy: 61758,
    iy_global: 617580000,
    iz: 755749,
    iz_global: 7557490000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 20099,
    zx_global: 20099000,
    zy: 4234,
    zy_global: 4234000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 850,
    height_global: 850,
  },
  {
    profile_section_id: 3383,
    name: "I160012B55020",
    type: "I Shape",
    designation: "I160012B55020",
    shape: "I",
    country_code: "Indian",
    ax: 583,
    ax_global: 58300,
    bf: 550,
    bf_global: 550,
    ct: "29,71",
    ct_global: "297,1",
    d: 1640,
    d_global: 1640,
    ix: 514,
    ix_global: 5140000,
    iy: 72841,
    iy_global: 728410000,
    iz: 2889515,
    iz_global: 28895150000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 37942,
    zx_global: 37942000,
    zy: 3568,
    zy_global: 3568000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3384,
    name: "I160012D50012",
    type: "I Shape",
    designation: "I160012D50012",
    shape: "I",
    country_code: "Indian",
    ax: 587,
    ax_global: 58700,
    bf: 500,
    bf_global: 500,
    ct: "25,58",
    ct_global: "255,8",
    d: 1624,
    d_global: 1624,
    ix: 447,
    ix_global: 4470000,
    iy: 45992,
    iy_global: 459920000,
    iz: 2722676,
    iz_global: 27226760000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 37797,
    zx_global: 37797000,
    zy: 3281,
    zy_global: 3281000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3385,
    name: "I160016B50016",
    type: "I Shape",
    designation: "I160016B50016",
    shape: "I",
    country_code: "Indian",
    ax: 587,
    ax_global: 58700,
    bf: 500,
    bf_global: 500,
    ct: "25,91",
    ct_global: "259,1",
    d: 1632,
    d_global: 1632,
    ix: 483,
    ix_global: 4830000,
    iy: 51287,
    iy_global: 512870000,
    iz: 2627172,
    iz_global: 26271720000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 35610,
    zx_global: 35610000,
    zy: 2622,
    zy_global: 2622000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3386,
    name: "I80012B50032",
    type: "I Shape",
    designation: "I80012B50032",
    shape: "I",
    country_code: "Indian",
    ax: 587,
    ax_global: 58700,
    bf: 500,
    bf_global: 500,
    ct: "15,53",
    ct_global: "155,3",
    d: 864,
    d_global: 864,
    ix: 1267,
    ix_global: 12670000,
    iy: 84038,
    iy_global: 840380000,
    iz: 850689,
    iz_global: 8506890000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 20834,
    zx_global: 20834000,
    zy: 4515,
    zy_global: 4515000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 864,
    height_global: 864,
  },
  {
    profile_section_id: 3387,
    name: "I160016A40016",
    type: "I Shape",
    designation: "I160016A40016",
    shape: "I",
    country_code: "Indian",
    ax: 587,
    ax_global: 58700,
    bf: 400,
    bf_global: 400,
    ct: 28,
    ct_global: 280,
    d: 1632,
    d_global: 1632,
    ix: 547,
    ix_global: 5470000,
    iy: 26768,
    iy_global: 267680000,
    iz: 2547769,
    iz_global: 25477690000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 35930,
    zx_global: 35930000,
    zy: 2440,
    zy_global: 2440000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3388,
    name: "I80016A40032",
    type: "I Shape",
    designation: "I80016A40032",
    shape: "I",
    country_code: "Indian",
    ax: 587,
    ax_global: 58700,
    bf: 400,
    bf_global: 400,
    ct: "15,39",
    ct_global: "153,9",
    d: 864,
    d_global: 864,
    ix: 1202,
    ix_global: 12020000,
    iy: 43808,
    iy_global: 438080000,
    iz: 773470,
    iz_global: 7734700000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 20436,
    zx_global: 20436000,
    zy: 3669,
    zy_global: 3669000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 864,
    height_global: 864,
  },
  {
    profile_section_id: 3389,
    name: "I125016B55020",
    type: "I Shape",
    designation: "I125016B55020",
    shape: "I",
    country_code: "Indian",
    ax: 591,
    ax_global: 59100,
    bf: 550,
    bf_global: 550,
    ct: "19,78",
    ct_global: "197,8",
    d: 1290,
    d_global: 1290,
    ix: 592,
    ix_global: 5920000,
    iy: 73400,
    iy_global: 734000000,
    iz: 1770572,
    iz_global: 17705720000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 29669,
    zx_global: 29669000,
    zy: 3625,
    zy_global: 3625000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1290,
    height_global: 1290,
  },
  {
    profile_section_id: 3390,
    name: "I160012C50016",
    type: "I Shape",
    designation: "I160012C50016",
    shape: "I",
    country_code: "Indian",
    ax: 591,
    ax_global: 59100,
    bf: 500,
    bf_global: 500,
    ct: "28,42",
    ct_global: "284,2",
    d: 1632,
    d_global: 1632,
    ix: 487,
    ix_global: 4870000,
    iy: 54274,
    iy_global: 542740000,
    iz: 2845279,
    iz_global: 28452790000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 38206,
    zx_global: 38206000,
    zy: 3129,
    zy_global: 3129000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3391,
    name: "I160012A50020",
    type: "I Shape",
    designation: "I160012A50020",
    shape: "I",
    country_code: "Indian",
    ax: 595,
    ax_global: 59500,
    bf: 500,
    bf_global: 500,
    ct: "30,5",
    ct_global: 305,
    d: 1640,
    d_global: 1640,
    ix: 578,
    ix_global: 5780000,
    iy: 50924,
    iy_global: 509240000,
    iz: 2887809,
    iz_global: 28878090000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 39228,
    zx_global: 39228000,
    zy: 3575,
    zy_global: 3575000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3392,
    name: "I160012A40025",
    type: "I Shape",
    designation: "I160012A40025",
    shape: "I",
    country_code: "Indian",
    ax: 595,
    ax_global: 59500,
    bf: 400,
    bf_global: 400,
    ct: "33,97",
    ct_global: "339,7",
    d: 1650,
    d_global: 1650,
    ix: 728,
    ix_global: 7280000,
    iy: 35924,
    iy_global: 359240000,
    iz: 2895959,
    iz_global: 28959590000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 39278,
    zx_global: 39278000,
    zy: 3075,
    zy_global: 3075000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3393,
    name: "I125012B55025",
    type: "I Shape",
    designation: "I125012B55025",
    shape: "I",
    country_code: "Indian",
    ax: 596,
    ax_global: 59600,
    bf: 550,
    bf_global: 550,
    ct: "23,59",
    ct_global: "235,9",
    d: 1300,
    d_global: 1300,
    ix: 773,
    ix_global: 7730000,
    iy: 86701,
    iy_global: 867010000,
    iz: 1936060,
    iz_global: 19360600000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 31668,
    zx_global: 31668000,
    zy: 4312,
    zy_global: 4312000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1300,
    height_global: 1300,
  },
  {
    profile_section_id: 3394,
    name: "I125016A50020",
    type: "I Shape",
    designation: "I125016A50020",
    shape: "I",
    country_code: "Indian",
    ax: 603,
    ax_global: 60300,
    bf: 500,
    bf_global: 500,
    ct: "20,51",
    ct_global: "205,1",
    d: 1290,
    d_global: 1290,
    ix: 657,
    ix_global: 6570000,
    iy: 51356,
    iy_global: 513560000,
    iz: 1757390,
    iz_global: 17573900000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 30744,
    zx_global: 30744000,
    zy: 3638,
    zy_global: 3638000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1290,
    height_global: 1290,
  },
  {
    profile_section_id: 3395,
    name: "I125016A40025",
    type: "I Shape",
    designation: "I125016A40025",
    shape: "I",
    country_code: "Indian",
    ax: 603,
    ax_global: 60300,
    bf: 400,
    bf_global: 400,
    ct: "23,95",
    ct_global: "239,5",
    d: 1300,
    d_global: 1300,
    ix: 807,
    ix_global: 8070000,
    iy: 36356,
    iy_global: 363560000,
    iz: 1763790,
    iz_global: 17637900000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 30794,
    zx_global: 30794000,
    zy: 3138,
    zy_global: 3138000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1300,
    height_global: 1300,
  },
  {
    profile_section_id: 3396,
    name: "I160016B55016",
    type: "I Shape",
    designation: "I160016B55016",
    shape: "I",
    country_code: "Indian",
    ax: 603,
    ax_global: 60300,
    bf: 550,
    bf_global: 550,
    ct: "24,98",
    ct_global: "249,8",
    d: 1632,
    d_global: 1632,
    ix: 497,
    ix_global: 4970000,
    iy: 62320,
    iy_global: 623200000,
    iz: 2731634,
    iz_global: 27316340000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 36903,
    zx_global: 36903000,
    zy: 3042,
    zy_global: 3042000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3397,
    name: "I125012A50025",
    type: "I Shape",
    designation: "I125012A50025",
    shape: "I",
    country_code: "Indian",
    ax: 603,
    ax_global: 60300,
    bf: 500,
    bf_global: 500,
    ct: "24,22",
    ct_global: "242,2",
    d: 1300,
    d_global: 1300,
    ix: 812,
    ix_global: 8120000,
    iy: 61335,
    iy_global: 613350000,
    iz: 1901915,
    iz_global: 19019150000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 32419,
    zx_global: 32419000,
    zy: 4187,
    zy_global: 4187000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1300,
    height_global: 1300,
  },
  {
    profile_section_id: 3398,
    name: "I100016B55025",
    type: "I Shape",
    designation: "I100016B55025",
    shape: "I",
    country_code: "Indian",
    ax: 606,
    ax_global: 60600,
    bf: 550,
    bf_global: 550,
    ct: "16,32",
    ct_global: "163,2",
    d: 1050,
    d_global: 1050,
    ix: 838,
    ix_global: 8380000,
    iy: 87256,
    iy_global: 872560000,
    iz: 1247628,
    iz_global: 12476280000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 25406,
    zx_global: 25406000,
    zy: 4365,
    zy_global: 4365000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1050,
    height_global: 1050,
  },
  {
    profile_section_id: 3399,
    name: "I160012C55016",
    type: "I Shape",
    designation: "I160012C55016",
    shape: "I",
    country_code: "Indian",
    ax: 607,
    ax_global: 60700,
    bf: 550,
    bf_global: 550,
    ct: "27,57",
    ct_global: "275,7",
    d: 1632,
    d_global: 1632,
    ix: 501,
    ix_global: 5010000,
    iy: 65307,
    iy_global: 653070000,
    iz: 2949741,
    iz_global: 29497410000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 39498,
    zx_global: 39498000,
    zy: 3549,
    zy_global: 3549000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3400,
    name: "I125012A40032",
    type: "I Shape",
    designation: "I125012A40032",
    shape: "I",
    country_code: "Indian",
    ax: 609,
    ax_global: 60900,
    bf: 400,
    bf_global: 400,
    ct: "27,25",
    ct_global: "272,5",
    d: 1314,
    d_global: 1314,
    ix: 1165,
    ix_global: 11650000,
    iy: 43385,
    iy_global: 433850000,
    iz: 1937843,
    iz_global: 19378430000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 32892,
    zx_global: 32892000,
    zy: 3622,
    zy_global: 3622000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1314,
    height_global: 1314,
  },
  {
    profile_section_id: 3401,
    name: "I100012B50032",
    type: "I Shape",
    designation: "I100012B50032",
    shape: "I",
    country_code: "Indian",
    ax: 611,
    ax_global: 61100,
    bf: 500,
    bf_global: 500,
    ct: "20,15",
    ct_global: "201,5",
    d: 1064,
    d_global: 1064,
    ix: 1278,
    ix_global: 12780000,
    iy: 84041,
    iy_global: 840410000,
    iz: 1344139,
    iz_global: 13441390000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 26824,
    zx_global: 26824000,
    zy: 4522,
    zy_global: 4522000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1064,
    height_global: 1064,
  },
  {
    profile_section_id: 3402,
    name: "I100016A50025",
    type: "I Shape",
    designation: "I100016A50025",
    shape: "I",
    country_code: "Indian",
    ax: 613,
    ax_global: 61300,
    bf: 500,
    bf_global: 500,
    ct: "16,92",
    ct_global: "169,2",
    d: 1050,
    d_global: 1050,
    ix: 877,
    ix_global: 8770000,
    iy: 61764,
    iy_global: 617640000,
    iz: 1217113,
    iz_global: 12171130000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 26069,
    zx_global: 26069000,
    zy: 4247,
    zy_global: 4247000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1050,
    height_global: 1050,
  },
  {
    profile_section_id: 3403,
    name: "I160012B50025",
    type: "I Shape",
    designation: "I160012B50025",
    shape: "I",
    country_code: "Indian",
    ax: 613,
    ax_global: 61300,
    bf: 500,
    bf_global: 500,
    ct: "32,45",
    ct_global: "324,5",
    d: 1650,
    d_global: 1650,
    ix: 741,
    ix_global: 7410000,
    iy: 69466,
    iy_global: 694660000,
    iz: 3096543,
    iz_global: 30965430000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 40434,
    zx_global: 40434000,
    zy: 3668,
    zy_global: 3668000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3404,
    name: "I160016C50012",
    type: "I Shape",
    designation: "I160016C50012",
    shape: "I",
    country_code: "Indian",
    ax: 615,
    ax_global: 61500,
    bf: 500,
    bf_global: 500,
    ct: "22,89",
    ct_global: "228,9",
    d: 1624,
    d_global: 1624,
    ix: 534,
    ix_global: 5340000,
    iy: 46644,
    iy_global: 466440000,
    iz: 2716773,
    iz_global: 27167730000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 37510,
    zx_global: 37510000,
    zy: 2722,
    zy_global: 2722000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3405,
    name: "I160016A50016",
    type: "I Shape",
    designation: "I160016A50016",
    shape: "I",
    country_code: "Indian",
    ax: 619,
    ax_global: 61900,
    bf: 500,
    bf_global: 500,
    ct: "25,91",
    ct_global: "259,1",
    d: 1632,
    d_global: 1632,
    ix: 574,
    ix_global: 5740000,
    iy: 43035,
    iy_global: 430350000,
    iz: 2756693,
    iz_global: 27566930000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 38516,
    zx_global: 38516000,
    zy: 3160,
    zy_global: 3160000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3406,
    name: "I100016A40032",
    type: "I Shape",
    designation: "I100016A40032",
    shape: "I",
    country_code: "Indian",
    ax: 619,
    ax_global: 61900,
    bf: 400,
    bf_global: 400,
    ct: "19,97",
    ct_global: "199,7",
    d: 1064,
    d_global: 1064,
    ix: 1230,
    ix_global: 12300000,
    iy: 43814,
    iy_global: 438140000,
    iz: 1242176,
    iz_global: 12421760000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 26466,
    zx_global: 26466000,
    zy: 3682,
    zy_global: 3682000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1064,
    height_global: 1064,
  },
  {
    profile_section_id: 3407,
    name: "I80012A40040",
    type: "I Shape",
    designation: "I80012A40040",
    shape: "I",
    country_code: "Indian",
    ax: 619,
    ax_global: 61900,
    bf: 400,
    bf_global: 400,
    ct: "17,78",
    ct_global: "177,8",
    d: 880,
    d_global: 880,
    ix: 1972,
    ix_global: 19720000,
    iy: 51912,
    iy_global: 519120000,
    iz: 878069,
    iz_global: 8780690000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 22586,
    zx_global: 22586000,
    zy: 4246,
    zy_global: 4246000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 880,
    height_global: 880,
  },
  {
    profile_section_id: 3408,
    name: "I80016B50032",
    type: "I Shape",
    designation: "I80016B50032",
    shape: "I",
    country_code: "Indian",
    ax: 619,
    ax_global: 61900,
    bf: 500,
    bf_global: 500,
    ct: "14,29",
    ct_global: "142,9",
    d: 864,
    d_global: 864,
    ix: 1330,
    ix_global: 13300000,
    iy: 84593,
    iy_global: 845930000,
    iz: 867756,
    iz_global: 8677560000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 21474,
    zx_global: 21474000,
    zy: 4571,
    zy_global: 4571000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 864,
    height_global: 864,
  },
  {
    profile_section_id: 3409,
    name: "I80012A50032",
    type: "I Shape",
    designation: "I80012A50032",
    shape: "I",
    country_code: "Indian",
    ax: 619,
    ax_global: 61900,
    bf: 500,
    bf_global: 500,
    ct: "15,53",
    ct_global: "155,3",
    d: 864,
    d_global: 864,
    ix: 1358,
    ix_global: 13580000,
    iy: 75912,
    iy_global: 759120000,
    iz: 867214,
    iz_global: 8672140000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 22458,
    zx_global: 22458000,
    zy: 5046,
    zy_global: 5046000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 864,
    height_global: 864,
  },
  {
    profile_section_id: 3410,
    name: "I80012B55032",
    type: "I Shape",
    designation: "I80012B55032",
    shape: "I",
    country_code: "Indian",
    ax: 619,
    ax_global: 61900,
    bf: 550,
    bf_global: 550,
    ct: "15,09",
    ct_global: "150,9",
    d: 864,
    d_global: 864,
    ix: 1376,
    ix_global: 13760000,
    iy: 106105,
    iy_global: 1061050000,
    iz: 906094,
    iz_global: 9060940000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 22165,
    zx_global: 22165000,
    zy: 5355,
    zy_global: 5355000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 864,
    height_global: 864,
  },
  {
    profile_section_id: 3411,
    name: "I160016A40020",
    type: "I Shape",
    designation: "I160016A40020",
    shape: "I",
    country_code: "Indian",
    ax: 619,
    ax_global: 61900,
    bf: 400,
    bf_global: 400,
    ct: "30,13",
    ct_global: "301,3",
    d: 1640,
    d_global: 1640,
    ix: 651,
    ix_global: 6510000,
    iy: 31035,
    iy_global: 310350000,
    iz: 2761889,
    iz_global: 27618890000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 38548,
    zx_global: 38548000,
    zy: 2760,
    zy_global: 2760000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3412,
    name: "I125016B50025",
    type: "I Shape",
    designation: "I125016B50025",
    shape: "I",
    country_code: "Indian",
    ax: 621,
    ax_global: 62100,
    bf: 500,
    bf_global: 500,
    ct: "22,4",
    ct_global: 224,
    d: 1300,
    d_global: 1300,
    ix: 820,
    ix_global: 8200000,
    iy: 70025,
    iy_global: 700250000,
    iz: 1899550,
    iz_global: 18995500000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 31637,
    zx_global: 31637000,
    zy: 3725,
    zy_global: 3725000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1300,
    height_global: 1300,
  },
  {
    profile_section_id: 3413,
    name: "I160016B50020",
    type: "I Shape",
    designation: "I160016B50020",
    shape: "I",
    country_code: "Indian",
    ax: 627,
    ax_global: 62700,
    bf: 500,
    bf_global: 500,
    ct: "28,18",
    ct_global: "281,8",
    d: 1640,
    d_global: 1640,
    ix: 613,
    ix_global: 6130000,
    iy: 59620,
    iy_global: 596200000,
    iz: 2894822,
    iz_global: 28948220000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 38882,
    zx_global: 38882000,
    zy: 3122,
    zy_global: 3122000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3414,
    name: "I160012D50016",
    type: "I Shape",
    designation: "I160012D50016",
    shape: "I",
    country_code: "Indian",
    ax: 627,
    ax_global: 62700,
    bf: 500,
    bf_global: 500,
    ct: "28,42",
    ct_global: "284,2",
    d: 1632,
    d_global: 1632,
    ix: 526,
    ix_global: 5260000,
    iy: 54326,
    iy_global: 543260000,
    iz: 2987714,
    iz_global: 29877140000,
    tf: 12,
    tf_global: 12,
    tw: 16,
    tw_global: 16,
    zx: 41053,
    zx_global: 41053000,
    zy: 3781,
    zy_global: 3781000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3415,
    name: "I160016C55012",
    type: "I Shape",
    designation: "I160016C55012",
    shape: "I",
    country_code: "Indian",
    ax: 627,
    ax_global: 62700,
    bf: 550,
    bf_global: 550,
    ct: "21,93",
    ct_global: "219,3",
    d: 1624,
    d_global: 1624,
    ix: 540,
    ix_global: 5400000,
    iy: 54919,
    iy_global: 549190000,
    iz: 2794731,
    iz_global: 27947310000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 38477,
    zx_global: 38477000,
    zy: 3037,
    zy_global: 3037000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3416,
    name: "I160012C50020",
    type: "I Shape",
    designation: "I160012C50020",
    shape: "I",
    country_code: "Indian",
    ax: 631,
    ax_global: 63100,
    bf: 500,
    bf_global: 500,
    ct: "30,5",
    ct_global: 305,
    d: 1640,
    d_global: 1640,
    ix: 617,
    ix_global: 6170000,
    iy: 62607,
    iy_global: 626070000,
    iz: 3112929,
    iz_global: 31129290000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 41478,
    zx_global: 41478000,
    zy: 3629,
    zy_global: 3629000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3417,
    name: "I160012B55025",
    type: "I Shape",
    designation: "I160012B55025",
    shape: "I",
    country_code: "Indian",
    ax: 638,
    ax_global: 63800,
    bf: 550,
    bf_global: 550,
    ct: "31,74",
    ct_global: "317,4",
    d: 1650,
    d_global: 1650,
    ix: 793,
    ix_global: 7930000,
    iy: 86706,
    iy_global: 867060000,
    iz: 3261595,
    iz_global: 32615950000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 42466,
    zx_global: 42466000,
    zy: 4325,
    zy_global: 4325000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3418,
    name: "I125012B50032",
    type: "I Shape",
    designation: "I125012B50032",
    shape: "I",
    country_code: "Indian",
    ax: 641,
    ax_global: 64100,
    bf: 500,
    bf_global: 500,
    ct: "26,05",
    ct_global: "260,5",
    d: 1314,
    d_global: 1314,
    ix: 1293,
    ix_global: 12930000,
    iy: 84044,
    iy_global: 840440000,
    iz: 2133392,
    iz_global: 21333920000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 34649,
    zx_global: 34649000,
    zy: 4531,
    zy_global: 4531000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1314,
    height_global: 1314,
  },
  {
    profile_section_id: 3419,
    name: "I100012A50032",
    type: "I Shape",
    designation: "I100012A50032",
    shape: "I",
    country_code: "Indian",
    ax: 643,
    ax_global: 64300,
    bf: 500,
    bf_global: 500,
    ct: "20,15",
    ct_global: "201,5",
    d: 1064,
    d_global: 1064,
    ix: 1369,
    ix_global: 13690000,
    iy: 75915,
    iy_global: 759150000,
    iz: 1379301,
    iz_global: 13793010000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 28768,
    zx_global: 28768000,
    zy: 5053,
    zy_global: 5053000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1064,
    height_global: 1064,
  },
  {
    profile_section_id: 3420,
    name: "I100012A40040",
    type: "I Shape",
    designation: "I100012A40040",
    shape: "I",
    country_code: "Indian",
    ax: 643,
    ax_global: 64300,
    bf: 400,
    bf_global: 400,
    ct: "22,6",
    ct_global: 226,
    d: 1080,
    d_global: 1080,
    ix: 1984,
    ix_global: 19840000,
    iy: 51915,
    iy_global: 519150000,
    iz: 1392716,
    iz_global: 13927160000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 28896,
    zx_global: 28896000,
    zy: 4253,
    zy_global: 4253000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1080,
    height_global: 1080,
  },
  {
    profile_section_id: 3421,
    name: "I100012B55032",
    type: "I Shape",
    designation: "I100012B55032",
    shape: "I",
    country_code: "Indian",
    ax: 643,
    ax_global: 64300,
    bf: 550,
    bf_global: 550,
    ct: "19,65",
    ct_global: "196,5",
    d: 1064,
    d_global: 1064,
    ix: 1387,
    ix_global: 13870000,
    iy: 106107,
    iy_global: 1061070000,
    iz: 1429369,
    iz_global: 14293690000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 28475,
    zx_global: 28475000,
    zy: 5362,
    zy_global: 5362000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1064,
    height_global: 1064,
  },
  {
    profile_section_id: 3422,
    name: "I160012A50025",
    type: "I Shape",
    designation: "I160012A50025",
    shape: "I",
    country_code: "Indian",
    ax: 645,
    ax_global: 64500,
    bf: 500,
    bf_global: 500,
    ct: "32,45",
    ct_global: "324,5",
    d: 1650,
    d_global: 1650,
    ix: 832,
    ix_global: 8320000,
    iy: 61340,
    iy_global: 613400000,
    iz: 3226064,
    iz_global: 32260640000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 43340,
    zx_global: 43340000,
    zy: 4200,
    zy_global: 4200000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3423,
    name: "I125016B55025",
    type: "I Shape",
    designation: "I125016B55025",
    shape: "I",
    country_code: "Indian",
    ax: 646,
    ax_global: 64600,
    bf: 550,
    bf_global: 550,
    ct: "21,7",
    ct_global: 217,
    d: 1300,
    d_global: 1300,
    ix: 872,
    ix_global: 8720000,
    iy: 87264,
    iy_global: 872640000,
    iz: 2001165,
    iz_global: 20011650000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 33231,
    zx_global: 33231000,
    zy: 4381,
    zy_global: 4381000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1300,
    height_global: 1300,
  },
  {
    profile_section_id: 3424,
    name: "I160016B55020",
    type: "I Shape",
    designation: "I160016B55020",
    shape: "I",
    country_code: "Indian",
    ax: 647,
    ax_global: 64700,
    bf: 550,
    bf_global: 550,
    ct: "27,3",
    ct_global: 273,
    d: 1640,
    d_global: 1640,
    ix: 640,
    ix_global: 6400000,
    iy: 73412,
    iy_global: 734120000,
    iz: 3026049,
    iz_global: 30260490000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 40502,
    zx_global: 40502000,
    zy: 3647,
    zy_global: 3647000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3425,
    name: "I80016A50032",
    type: "I Shape",
    designation: "I80016A50032",
    shape: "I",
    country_code: "Indian",
    ax: 651,
    ax_global: 65100,
    bf: 500,
    bf_global: 500,
    ct: "14,29",
    ct_global: "142,9",
    d: 864,
    d_global: 864,
    ix: 1421,
    ix_global: 14210000,
    iy: 76341,
    iy_global: 763410000,
    iz: 884281,
    iz_global: 8842810000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 23098,
    zx_global: 23098000,
    zy: 5109,
    zy_global: 5109000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 864,
    height_global: 864,
  },
  {
    profile_section_id: 3426,
    name: "I160012A40032",
    type: "I Shape",
    designation: "I160012A40032",
    shape: "I",
    country_code: "Indian",
    ax: 651,
    ax_global: 65100,
    bf: 400,
    bf_global: 400,
    ct: "35,77",
    ct_global: "357,7",
    d: 1664,
    d_global: 1664,
    ix: 1185,
    ix_global: 11850000,
    iy: 43390,
    iy_global: 433900000,
    iz: 3280353,
    iz_global: 32803530000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 43917,
    zx_global: 43917000,
    zy: 3635,
    zy_global: 3635000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3427,
    name: "I80016B55032",
    type: "I Shape",
    designation: "I80016B55032",
    shape: "I",
    country_code: "Indian",
    ax: 651,
    ax_global: 65100,
    bf: 550,
    bf_global: 550,
    ct: "13,8",
    ct_global: 138,
    d: 864,
    d_global: 864,
    ix: 1439,
    ix_global: 14390000,
    iy: 106660,
    iy_global: 1066600000,
    iz: 923161,
    iz_global: 9231610000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 22805,
    zx_global: 22805000,
    zy: 5411,
    zy_global: 5411000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 864,
    height_global: 864,
  },
  {
    profile_section_id: 3428,
    name: "I160016D50012",
    type: "I Shape",
    designation: "I160016D50012",
    shape: "I",
    country_code: "Indian",
    ax: 651,
    ax_global: 65100,
    bf: 500,
    bf_global: 500,
    ct: "22,89",
    ct_global: "228,9",
    d: 1624,
    d_global: 1624,
    ix: 573,
    ix_global: 5730000,
    iy: 46719,
    iy_global: 467190000,
    iz: 2859209,
    iz_global: 28592090000,
    tf: 16,
    tf_global: 16,
    tw: 12,
    tw_global: 12,
    zx: 40357,
    zx_global: 40357000,
    zy: 3380,
    zy_global: 3380000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1624,
    height_global: 1624,
  },
  {
    profile_section_id: 3429,
    name: "I100016B50032",
    type: "I Shape",
    designation: "I100016B50032",
    shape: "I",
    country_code: "Indian",
    ax: 651,
    ax_global: 65100,
    bf: 500,
    bf_global: 500,
    ct: "18,72",
    ct_global: "187,2",
    d: 1064,
    d_global: 1064,
    ix: 1357,
    ix_global: 13570000,
    iy: 84600,
    iy_global: 846000000,
    iz: 1377473,
    iz_global: 13774730000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 27824,
    zx_global: 27824000,
    zy: 4584,
    zy_global: 4584000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1064,
    height_global: 1064,
  },
  {
    profile_section_id: 3430,
    name: "I160012C55020",
    type: "I Shape",
    designation: "I160012C55020",
    shape: "I",
    country_code: "Indian",
    ax: 651,
    ax_global: 65100,
    bf: 550,
    bf_global: 550,
    ct: "29,71",
    ct_global: "297,1",
    d: 1640,
    d_global: 1640,
    ix: 644,
    ix_global: 6440000,
    iy: 76399,
    iy_global: 763990000,
    iz: 3244156,
    iz_global: 32441560000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 43098,
    zx_global: 43098000,
    zy: 4154,
    zy_global: 4154000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3431,
    name: "I80016A40040",
    type: "I Shape",
    designation: "I80016A40040",
    shape: "I",
    country_code: "Indian",
    ax: 651,
    ax_global: 65100,
    bf: 400,
    bf_global: 400,
    ct: "16,77",
    ct_global: "167,7",
    d: 880,
    d_global: 880,
    ix: 2035,
    ix_global: 20350000,
    iy: 52341,
    iy_global: 523410000,
    iz: 895135,
    iz_global: 8951350000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 23226,
    zx_global: 23226000,
    zy: 4309,
    zy_global: 4309000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 880,
    height_global: 880,
  },
  {
    profile_section_id: 3432,
    name: "I125016A50025",
    type: "I Shape",
    designation: "I125016A50025",
    shape: "I",
    country_code: "Indian",
    ax: 653,
    ax_global: 65300,
    bf: 500,
    bf_global: 500,
    ct: "22,4",
    ct_global: 224,
    d: 1300,
    d_global: 1300,
    ix: 911,
    ix_global: 9110000,
    iy: 61773,
    iy_global: 617730000,
    iz: 1967019,
    iz_global: 19670190000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 33982,
    zx_global: 33982000,
    zy: 4263,
    zy_global: 4263000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1300,
    height_global: 1300,
  },
  {
    profile_section_id: 3433,
    name: "I160016C50016",
    type: "I Shape",
    designation: "I160016C50016",
    shape: "I",
    country_code: "Indian",
    ax: 655,
    ax_global: 65500,
    bf: 500,
    bf_global: 500,
    ct: "25,91",
    ct_global: "259,1",
    d: 1632,
    d_global: 1632,
    ix: 613,
    ix_global: 6130000,
    iy: 54978,
    iy_global: 549780000,
    iz: 2981812,
    iz_global: 29818120000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 40766,
    zx_global: 40766000,
    zy: 3222,
    zy_global: 3222000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3434,
    name: "I125016A40032",
    type: "I Shape",
    designation: "I125016A40032",
    shape: "I",
    country_code: "Indian",
    ax: 659,
    ax_global: 65900,
    bf: 400,
    bf_global: 400,
    ct: "25,84",
    ct_global: "258,4",
    d: 1314,
    d_global: 1314,
    ix: 1264,
    ix_global: 12640000,
    iy: 43823,
    iy_global: 438230000,
    iz: 2002947,
    iz_global: 20029470000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 34454,
    zx_global: 34454000,
    zy: 3698,
    zy_global: 3698000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1314,
    height_global: 1314,
  },
  {
    profile_section_id: 3435,
    name: "I160016A50020",
    type: "I Shape",
    designation: "I160016A50020",
    shape: "I",
    country_code: "Indian",
    ax: 659,
    ax_global: 65900,
    bf: 500,
    bf_global: 500,
    ct: "28,18",
    ct_global: "281,8",
    d: 1640,
    d_global: 1640,
    ix: 704,
    ix_global: 7040000,
    iy: 51368,
    iy_global: 513680000,
    iz: 3024343,
    iz_global: 30243430000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 41788,
    zx_global: 41788000,
    zy: 3660,
    zy_global: 3660000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3436,
    name: "I160016A40025",
    type: "I Shape",
    designation: "I160016A40025",
    shape: "I",
    country_code: "Indian",
    ax: 659,
    ax_global: 65900,
    bf: 400,
    bf_global: 400,
    ct: "32,13",
    ct_global: "321,3",
    d: 1650,
    d_global: 1650,
    ix: 854,
    ix_global: 8540000,
    iy: 36368,
    iy_global: 363680000,
    iz: 3032493,
    iz_global: 30324930000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 41838,
    zx_global: 41838000,
    zy: 3160,
    zy_global: 3160000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3437,
    name: "I80012B50040",
    type: "I Shape",
    designation: "I80012B50040",
    shape: "I",
    country_code: "Indian",
    ax: 667,
    ax_global: 66700,
    bf: 500,
    bf_global: 500,
    ct: "16,89",
    ct_global: "168,9",
    d: 880,
    d_global: 880,
    ix: 2308,
    ix_global: 23080000,
    iy: 100705,
    iy_global: 1007050000,
    iz: 1002770,
    iz_global: 10027700000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 24322,
    zx_global: 24322000,
    zy: 5515,
    zy_global: 5515000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 880,
    height_global: 880,
  },
  {
    profile_section_id: 3438,
    name: "I160012D50020",
    type: "I Shape",
    designation: "I160012D50020",
    shape: "I",
    country_code: "Indian",
    ax: 667,
    ax_global: 66700,
    bf: 500,
    bf_global: 500,
    ct: "30,5",
    ct_global: 305,
    d: 1640,
    d_global: 1640,
    ix: 656,
    ix_global: 6560000,
    iy: 62659,
    iy_global: 626590000,
    iz: 3255365,
    iz_global: 32553650000,
    tf: 12,
    tf_global: 12,
    tw: 20,
    tw_global: 20,
    zx: 44325,
    zx_global: 44325000,
    zy: 4281,
    zy_global: 4281000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3439,
    name: "I160016C55016",
    type: "I Shape",
    designation: "I160016C55016",
    shape: "I",
    country_code: "Indian",
    ax: 671,
    ax_global: 67100,
    bf: 550,
    bf_global: 550,
    ct: "24,98",
    ct_global: "249,8",
    d: 1632,
    d_global: 1632,
    ix: 627,
    ix_global: 6270000,
    iy: 66011,
    iy_global: 660110000,
    iz: 3086274,
    iz_global: 30862740000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 42058,
    zx_global: 42058000,
    zy: 3642,
    zy_global: 3642000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3440,
    name: "I125012A40040",
    type: "I Shape",
    designation: "I125012A40040",
    shape: "I",
    country_code: "Indian",
    ax: 673,
    ax_global: 67300,
    bf: 400,
    bf_global: 400,
    ct: "28,69",
    ct_global: "286,9",
    d: 1330,
    d_global: 1330,
    ix: 1998,
    ix_global: 19980000,
    iy: 51919,
    iy_global: 519190000,
    iz: 2217476,
    iz_global: 22174760000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 37122,
    zx_global: 37122000,
    zy: 4262,
    zy_global: 4262000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1330,
    height_global: 1330,
  },
  {
    profile_section_id: 3441,
    name: "I125012A50032",
    type: "I Shape",
    designation: "I125012A50032",
    shape: "I",
    country_code: "Indian",
    ax: 673,
    ax_global: 67300,
    bf: 500,
    bf_global: 500,
    ct: "26,05",
    ct_global: "260,5",
    d: 1314,
    d_global: 1314,
    ix: 1384,
    ix_global: 13840000,
    iy: 75919,
    iy_global: 759190000,
    iz: 2200862,
    iz_global: 22008620000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 36994,
    zx_global: 36994000,
    zy: 5062,
    zy_global: 5062000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1314,
    height_global: 1314,
  },
  {
    profile_section_id: 3442,
    name: "I125012B55032",
    type: "I Shape",
    designation: "I125012B55032",
    shape: "I",
    country_code: "Indian",
    ax: 673,
    ax_global: 67300,
    bf: 550,
    bf_global: 550,
    ct: "25,49",
    ct_global: "254,9",
    d: 1314,
    d_global: 1314,
    ix: 1402,
    ix_global: 14020000,
    iy: 106111,
    iy_global: 1061110000,
    iz: 2264902,
    iz_global: 22649020000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 36700,
    zx_global: 36700000,
    zy: 5371,
    zy_global: 5371000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1314,
    height_global: 1314,
  },
  {
    profile_section_id: 3443,
    name: "I160016B50025",
    type: "I Shape",
    designation: "I160016B50025",
    shape: "I",
    country_code: "Indian",
    ax: 677,
    ax_global: 67700,
    bf: 500,
    bf_global: 500,
    ct: "30,36",
    ct_global: "303,6",
    d: 1650,
    d_global: 1650,
    ix: 868,
    ix_global: 8680000,
    iy: 70037,
    iy_global: 700370000,
    iz: 3233076,
    iz_global: 32330760000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 42994,
    zx_global: 42994000,
    zy: 3747,
    zy_global: 3747000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3444,
    name: "I160012C50025",
    type: "I Shape",
    designation: "I160012C50025",
    shape: "I",
    country_code: "Indian",
    ax: 681,
    ax_global: 68100,
    bf: 500,
    bf_global: 500,
    ct: "32,45",
    ct_global: "324,5",
    d: 1650,
    d_global: 1650,
    ix: 871,
    ix_global: 8710000,
    iy: 73024,
    iy_global: 730240000,
    iz: 3451183,
    iz_global: 34511830000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 45590,
    zx_global: 45590000,
    zy: 4254,
    zy_global: 4254000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3445,
    name: "I100016A40040",
    type: "I Shape",
    designation: "I100016A40040",
    shape: "I",
    country_code: "Indian",
    ax: 683,
    ax_global: 68300,
    bf: 400,
    bf_global: 400,
    ct: "21,48",
    ct_global: "214,8",
    d: 1080,
    d_global: 1080,
    ix: 2062,
    ix_global: 20620000,
    iy: 52348,
    iy_global: 523480000,
    iz: 1426049,
    iz_global: 14260490000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 29896,
    zx_global: 29896000,
    zy: 4322,
    zy_global: 4322000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1080,
    height_global: 1080,
  },
  {
    profile_section_id: 3446,
    name: "I160012B50032",
    type: "I Shape",
    designation: "I160012B50032",
    shape: "I",
    country_code: "Indian",
    ax: 683,
    ax_global: 68300,
    bf: 500,
    bf_global: 500,
    ct: "34,46",
    ct_global: "344,6",
    d: 1664,
    d_global: 1664,
    ix: 1313,
    ix_global: 13130000,
    iy: 84049,
    iy_global: 840490000,
    iz: 3577034,
    iz_global: 35770340000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 46234,
    zx_global: 46234000,
    zy: 4543,
    zy_global: 4543000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3447,
    name: "I100016A50032",
    type: "I Shape",
    designation: "I100016A50032",
    shape: "I",
    country_code: "Indian",
    ax: 683,
    ax_global: 68300,
    bf: 500,
    bf_global: 500,
    ct: "18,72",
    ct_global: "187,2",
    d: 1064,
    d_global: 1064,
    ix: 1448,
    ix_global: 14480000,
    iy: 76348,
    iy_global: 763480000,
    iz: 1412635,
    iz_global: 14126350000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 29768,
    zx_global: 29768000,
    zy: 5122,
    zy_global: 5122000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1064,
    height_global: 1064,
  },
  {
    profile_section_id: 3448,
    name: "I100016B55032",
    type: "I Shape",
    designation: "I100016B55032",
    shape: "I",
    country_code: "Indian",
    ax: 683,
    ax_global: 68300,
    bf: 550,
    bf_global: 550,
    ct: "18,15",
    ct_global: "181,5",
    d: 1064,
    d_global: 1064,
    ix: 1466,
    ix_global: 14660000,
    iy: 106667,
    iy_global: 1066670000,
    iz: 1462702,
    iz_global: 14627020000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 29475,
    zx_global: 29475000,
    zy: 5424,
    zy_global: 5424000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1064,
    height_global: 1064,
  },
  {
    profile_section_id: 3449,
    name: "I125016B50032",
    type: "I Shape",
    designation: "I125016B50032",
    shape: "I",
    country_code: "Indian",
    ax: 691,
    ax_global: 69100,
    bf: 500,
    bf_global: 500,
    ct: "24,43",
    ct_global: "244,3",
    d: 1314,
    d_global: 1314,
    ix: 1391,
    ix_global: 13910000,
    iy: 84608,
    iy_global: 846080000,
    iz: 2198497,
    iz_global: 21984970000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 36211,
    zx_global: 36211000,
    zy: 4600,
    zy_global: 4600000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1314,
    height_global: 1314,
  },
  {
    profile_section_id: 3450,
    name: "I160016D50016",
    type: "I Shape",
    designation: "I160016D50016",
    shape: "I",
    country_code: "Indian",
    ax: 691,
    ax_global: 69100,
    bf: 500,
    bf_global: 500,
    ct: "25,91",
    ct_global: "259,1",
    d: 1632,
    d_global: 1632,
    ix: 652,
    ix_global: 6520000,
    iy: 55052,
    iy_global: 550520000,
    iz: 3124248,
    iz_global: 31242480000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 43613,
    zx_global: 43613000,
    zy: 3880,
    zy_global: 3880000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1632,
    height_global: 1632,
  },
  {
    profile_section_id: 3451,
    name: "I100012B50040",
    type: "I Shape",
    designation: "I100012B50040",
    shape: "I",
    country_code: "Indian",
    ax: 691,
    ax_global: 69100,
    bf: 500,
    bf_global: 500,
    ct: "21,63",
    ct_global: "216,3",
    d: 1080,
    d_global: 1080,
    ix: 2319,
    ix_global: 23190000,
    iy: 100707,
    iy_global: 1007070000,
    iz: 1573980,
    iz_global: 15739800000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 31112,
    zx_global: 31112000,
    zy: 5522,
    zy_global: 5522000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1080,
    height_global: 1080,
  },
  {
    profile_section_id: 3452,
    name: "I160016C50020",
    type: "I Shape",
    designation: "I160016C50020",
    shape: "I",
    country_code: "Indian",
    ax: 695,
    ax_global: 69500,
    bf: 500,
    bf_global: 500,
    ct: "28,18",
    ct_global: "281,8",
    d: 1640,
    d_global: 1640,
    ix: 743,
    ix_global: 7430000,
    iy: 63311,
    iy_global: 633110000,
    iz: 3249462,
    iz_global: 32494620000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 44038,
    zx_global: 44038000,
    zy: 3722,
    zy_global: 3722000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3453,
    name: "I80012A50040",
    type: "I Shape",
    designation: "I80012A50040",
    shape: "I",
    country_code: "Indian",
    ax: 699,
    ax_global: 69900,
    bf: 500,
    bf_global: 500,
    ct: "16,89",
    ct_global: "168,9",
    d: 880,
    d_global: 880,
    ix: 2399,
    ix_global: 23990000,
    iy: 92579,
    iy_global: 925790000,
    iz: 1019295,
    iz_global: 10192950000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 25946,
    zx_global: 25946000,
    zy: 6046,
    zy_global: 6046000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 880,
    height_global: 880,
  },
  {
    profile_section_id: 3454,
    name: "I80016B50040",
    type: "I Shape",
    designation: "I80016B50040",
    shape: "I",
    country_code: "Indian",
    ax: 699,
    ax_global: 69900,
    bf: 500,
    bf_global: 500,
    ct: "15,75",
    ct_global: "157,5",
    d: 880,
    d_global: 880,
    ix: 2371,
    ix_global: 23710000,
    iy: 101260,
    iy_global: 1012600000,
    iz: 1019837,
    iz_global: 10198370000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 24962,
    zx_global: 24962000,
    zy: 5571,
    zy_global: 5571000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 880,
    height_global: 880,
  },
  {
    profile_section_id: 3455,
    name: "I160016B55025",
    type: "I Shape",
    designation: "I160016B55025",
    shape: "I",
    country_code: "Indian",
    ax: 702,
    ax_global: 70200,
    bf: 550,
    bf_global: 550,
    ct: "29,54",
    ct_global: "295,4",
    d: 1650,
    d_global: 1650,
    ix: 920,
    ix_global: 9200000,
    iy: 87277,
    iy_global: 872770000,
    iz: 3398128,
    iz_global: 33981280000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 45026,
    zx_global: 45026000,
    zy: 4404,
    zy_global: 4404000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3456,
    name: "I160012C55025",
    type: "I Shape",
    designation: "I160012C55025",
    shape: "I",
    country_code: "Indian",
    ax: 706,
    ax_global: 70600,
    bf: 550,
    bf_global: 550,
    ct: "31,74",
    ct_global: "317,4",
    d: 1650,
    d_global: 1650,
    ix: 923,
    ix_global: 9230000,
    iy: 90263,
    iy_global: 902630000,
    iz: 3616235,
    iz_global: 36162350000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 47621,
    zx_global: 47621000,
    zy: 4910,
    zy_global: 4910000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3457,
    name: "I80012B55040",
    type: "I Shape",
    designation: "I80012B55040",
    shape: "I",
    country_code: "Indian",
    ax: 707,
    ax_global: 70700,
    bf: 550,
    bf_global: 550,
    ct: "16,48",
    ct_global: "164,8",
    d: 880,
    d_global: 880,
    ix: 2521,
    ix_global: 25210000,
    iy: 128288,
    iy_global: 1282880000,
    iz: 1073384,
    iz_global: 10733840000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 26002,
    zx_global: 26002000,
    zy: 6565,
    zy_global: 6565000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 880,
    height_global: 880,
  },
  {
    profile_section_id: 3458,
    name: "I160016A50025",
    type: "I Shape",
    designation: "I160016A50025",
    shape: "I",
    country_code: "Indian",
    ax: 709,
    ax_global: 70900,
    bf: 500,
    bf_global: 500,
    ct: "30,36",
    ct_global: "303,6",
    d: 1650,
    d_global: 1650,
    ix: 959,
    ix_global: 9590000,
    iy: 61785,
    iy_global: 617850000,
    iz: 3362597,
    iz_global: 33625970000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 45900,
    zx_global: 45900000,
    zy: 4285,
    zy_global: 4285000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3459,
    name: "I160012A50032",
    type: "I Shape",
    designation: "I160012A50032",
    shape: "I",
    country_code: "Indian",
    ax: 715,
    ax_global: 71500,
    bf: 500,
    bf_global: 500,
    ct: "34,46",
    ct_global: "344,6",
    d: 1664,
    d_global: 1664,
    ix: 1404,
    ix_global: 14040000,
    iy: 75924,
    iy_global: 759240000,
    iz: 3706555,
    iz_global: 37065550000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 49140,
    zx_global: 49140000,
    zy: 5075,
    zy_global: 5075000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3460,
    name: "I160012B55032",
    type: "I Shape",
    designation: "I160012B55032",
    shape: "I",
    country_code: "Indian",
    ax: 715,
    ax_global: 71500,
    bf: 550,
    bf_global: 550,
    ct: "33,84",
    ct_global: "338,4",
    d: 1664,
    d_global: 1664,
    ix: 1422,
    ix_global: 14220000,
    iy: 106116,
    iy_global: 1061160000,
    iz: 3790136,
    iz_global: 37901360000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 48845,
    zx_global: 48845000,
    zy: 5383,
    zy_global: 5383000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3461,
    name: "I160012A40040",
    type: "I Shape",
    designation: "I160012A40040",
    shape: "I",
    country_code: "Indian",
    ax: 715,
    ax_global: 71500,
    bf: 400,
    bf_global: 400,
    ct: "37,28",
    ct_global: "372,8",
    d: 1680,
    d_global: 1680,
    ix: 2018,
    ix_global: 20180000,
    iy: 51924,
    iy_global: 519240000,
    iz: 3727649,
    iz_global: 37276490000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 49268,
    zx_global: 49268000,
    zy: 4275,
    zy_global: 4275000,
    width: 400,
    width_global: 400,
    thickness: 12,
    thickness_global: 12,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3462,
    name: "I160016C55020",
    type: "I Shape",
    designation: "I160016C55020",
    shape: "I",
    country_code: "Indian",
    ax: 715,
    ax_global: 71500,
    bf: 550,
    bf_global: 550,
    ct: "27,3",
    ct_global: 273,
    d: 1640,
    d_global: 1640,
    ix: 770,
    ix_global: 7700000,
    iy: 77103,
    iy_global: 771030000,
    iz: 3380689,
    iz_global: 33806890000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 45658,
    zx_global: 45658000,
    zy: 4247,
    zy_global: 4247000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3463,
    name: "I160016A40032",
    type: "I Shape",
    designation: "I160016A40032",
    shape: "I",
    country_code: "Indian",
    ax: 715,
    ax_global: 71500,
    bf: 400,
    bf_global: 400,
    ct: "34,21",
    ct_global: "342,1",
    d: 1664,
    d_global: 1664,
    ix: 1312,
    ix_global: 13120000,
    iy: 43835,
    iy_global: 438350000,
    iz: 3416886,
    iz_global: 34168860000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 46477,
    zx_global: 46477000,
    zy: 3720,
    zy_global: 3720000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3464,
    name: "I160012D50025",
    type: "I Shape",
    designation: "I160012D50025",
    shape: "I",
    country_code: "Indian",
    ax: 717,
    ax_global: 71700,
    bf: 500,
    bf_global: 500,
    ct: "32,45",
    ct_global: "324,5",
    d: 1650,
    d_global: 1650,
    ix: 910,
    ix_global: 9100000,
    iy: 73076,
    iy_global: 730760000,
    iz: 3593619,
    iz_global: 35936190000,
    tf: 12,
    tf_global: 12,
    tw: 25,
    tw_global: 25,
    zx: 48438,
    zx_global: 48438000,
    zy: 4906,
    zy_global: 4906000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3465,
    name: "I125012B50040",
    type: "I Shape",
    designation: "I125012B50040",
    shape: "I",
    country_code: "Indian",
    ax: 721,
    ax_global: 72100,
    bf: 500,
    bf_global: 500,
    ct: "27,64",
    ct_global: "276,4",
    d: 1330,
    d_global: 1330,
    ix: 2334,
    ix_global: 23340000,
    iy: 100711,
    iy_global: 1007110000,
    iz: 2482933,
    iz_global: 24829330000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 39937,
    zx_global: 39937000,
    zy: 5531,
    zy_global: 5531000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1330,
    height_global: 1330,
  },
  {
    profile_section_id: 3466,
    name: "I125016A50032",
    type: "I Shape",
    designation: "I125016A50032",
    shape: "I",
    country_code: "Indian",
    ax: 723,
    ax_global: 72300,
    bf: 500,
    bf_global: 500,
    ct: "24,43",
    ct_global: "244,3",
    d: 1314,
    d_global: 1314,
    ix: 1482,
    ix_global: 14820000,
    iy: 76356,
    iy_global: 763560000,
    iz: 2265966,
    iz_global: 22659660000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 38556,
    zx_global: 38556000,
    zy: 5138,
    zy_global: 5138000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1314,
    height_global: 1314,
  },
  {
    profile_section_id: 3467,
    name: "I125016B55032",
    type: "I Shape",
    designation: "I125016B55032",
    shape: "I",
    country_code: "Indian",
    ax: 723,
    ax_global: 72300,
    bf: 550,
    bf_global: 550,
    ct: "23,79",
    ct_global: "237,9",
    d: 1314,
    d_global: 1314,
    ix: 1500,
    ix_global: 15000000,
    iy: 106675,
    iy_global: 1066750000,
    iz: 2330006,
    iz_global: 23300060000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 38262,
    zx_global: 38262000,
    zy: 5440,
    zy_global: 5440000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1314,
    height_global: 1314,
  },
  {
    profile_section_id: 3468,
    name: "I125016A40040",
    type: "I Shape",
    designation: "I125016A40040",
    shape: "I",
    country_code: "Indian",
    ax: 723,
    ax_global: 72300,
    bf: 400,
    bf_global: 400,
    ct: "27,47",
    ct_global: "274,7",
    d: 1330,
    d_global: 1330,
    ix: 2097,
    ix_global: 20970000,
    iy: 52356,
    iy_global: 523560000,
    iz: 2282580,
    iz_global: 22825800000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 38684,
    zx_global: 38684000,
    zy: 4338,
    zy_global: 4338000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1330,
    height_global: 1330,
  },
  {
    profile_section_id: 3469,
    name: "I100012A50040",
    type: "I Shape",
    designation: "I100012A50040",
    shape: "I",
    country_code: "Indian",
    ax: 723,
    ax_global: 72300,
    bf: 500,
    bf_global: 500,
    ct: "21,63",
    ct_global: "216,3",
    d: 1080,
    d_global: 1080,
    ix: 2410,
    ix_global: 24100000,
    iy: 92582,
    iy_global: 925820000,
    iz: 1609142,
    iz_global: 16091420000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 33056,
    zx_global: 33056000,
    zy: 6053,
    zy_global: 6053000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1080,
    height_global: 1080,
  },
  {
    profile_section_id: 3470,
    name: "I100016B50040",
    type: "I Shape",
    designation: "I100016B50040",
    shape: "I",
    country_code: "Indian",
    ax: 731,
    ax_global: 73100,
    bf: 500,
    bf_global: 500,
    ct: "20,34",
    ct_global: "203,4",
    d: 1080,
    d_global: 1080,
    ix: 2398,
    ix_global: 23980000,
    iy: 101267,
    iy_global: 1012670000,
    iz: 1607314,
    iz_global: 16073140000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 32112,
    zx_global: 32112000,
    zy: 5584,
    zy_global: 5584000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1080,
    height_global: 1080,
  },
  {
    profile_section_id: 3471,
    name: "I100012B55040",
    type: "I Shape",
    designation: "I100012B55040",
    shape: "I",
    country_code: "Indian",
    ax: 731,
    ax_global: 73100,
    bf: 550,
    bf_global: 550,
    ct: "21,17",
    ct_global: "211,7",
    d: 1080,
    d_global: 1080,
    ix: 2533,
    ix_global: 25330000,
    iy: 128291,
    iy_global: 1282910000,
    iz: 1682194,
    iz_global: 16821940000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 33192,
    zx_global: 33192000,
    zy: 6572,
    zy_global: 6572000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1080,
    height_global: 1080,
  },
  {
    profile_section_id: 3472,
    name: "I160016D50020",
    type: "I Shape",
    designation: "I160016D50020",
    shape: "I",
    country_code: "Indian",
    ax: 731,
    ax_global: 73100,
    bf: 500,
    bf_global: 500,
    ct: "28,18",
    ct_global: "281,8",
    d: 1640,
    d_global: 1640,
    ix: 782,
    ix_global: 7820000,
    iy: 63385,
    iy_global: 633850000,
    iz: 3391898,
    iz_global: 33918980000,
    tf: 16,
    tf_global: 16,
    tw: 20,
    tw_global: 20,
    zx: 46885,
    zx_global: 46885000,
    zy: 4380,
    zy_global: 4380000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1640,
    height_global: 1640,
  },
  {
    profile_section_id: 3473,
    name: "I80016A50040",
    type: "I Shape",
    designation: "I80016A50040",
    shape: "I",
    country_code: "Indian",
    ax: 731,
    ax_global: 73100,
    bf: 500,
    bf_global: 500,
    ct: "15,75",
    ct_global: "157,5",
    d: 880,
    d_global: 880,
    ix: 2462,
    ix_global: 24620000,
    iy: 93008,
    iy_global: 930080000,
    iz: 1036362,
    iz_global: 10363620000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 26586,
    zx_global: 26586000,
    zy: 6109,
    zy_global: 6109000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 880,
    height_global: 880,
  },
  {
    profile_section_id: 3474,
    name: "I80016B55040",
    type: "I Shape",
    designation: "I80016B55040",
    shape: "I",
    country_code: "Indian",
    ax: 739,
    ax_global: 73900,
    bf: 550,
    bf_global: 550,
    ct: "15,28",
    ct_global: "152,8",
    d: 880,
    d_global: 880,
    ix: 2584,
    ix_global: 25840000,
    iy: 128843,
    iy_global: 1288430000,
    iz: 1090450,
    iz_global: 10904500000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 26642,
    zx_global: 26642000,
    zy: 6621,
    zy_global: 6621000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 880,
    height_global: 880,
  },
  {
    profile_section_id: 3475,
    name: "I160016C50025",
    type: "I Shape",
    designation: "I160016C50025",
    shape: "I",
    country_code: "Indian",
    ax: 745,
    ax_global: 74500,
    bf: 500,
    bf_global: 500,
    ct: "30,36",
    ct_global: "303,6",
    d: 1650,
    d_global: 1650,
    ix: 997,
    ix_global: 9970000,
    iy: 73728,
    iy_global: 737280000,
    iz: 3587717,
    iz_global: 35877170000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 48150,
    zx_global: 48150000,
    zy: 4347,
    zy_global: 4347000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3476,
    name: "I160016B50032",
    type: "I Shape",
    designation: "I160016B50032",
    shape: "I",
    country_code: "Indian",
    ax: 747,
    ax_global: 74700,
    bf: 500,
    bf_global: 500,
    ct: "32,64",
    ct_global: "326,4",
    d: 1664,
    d_global: 1664,
    ix: 1439,
    ix_global: 14390000,
    iy: 84620,
    iy_global: 846200000,
    iz: 3713568,
    iz_global: 37135680000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 48794,
    zx_global: 48794000,
    zy: 4622,
    zy_global: 4622000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3477,
    name: "I160012C50032",
    type: "I Shape",
    designation: "I160012C50032",
    shape: "I",
    country_code: "Indian",
    ax: 751,
    ax_global: 75100,
    bf: 500,
    bf_global: 500,
    ct: "34,46",
    ct_global: "344,6",
    d: 1664,
    d_global: 1664,
    ix: 1443,
    ix_global: 14430000,
    iy: 87607,
    iy_global: 876070000,
    iz: 3931675,
    iz_global: 39316750000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 51390,
    zx_global: 51390000,
    zy: 5129,
    zy_global: 5129000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3478,
    name: "I125012A50040",
    type: "I Shape",
    designation: "I125012A50040",
    shape: "I",
    country_code: "Indian",
    ax: 753,
    ax_global: 75300,
    bf: 500,
    bf_global: 500,
    ct: "27,64",
    ct_global: "276,4",
    d: 1330,
    d_global: 1330,
    ix: 2425,
    ix_global: 24250000,
    iy: 92585,
    iy_global: 925850000,
    iz: 2550403,
    iz_global: 25504030000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 42282,
    zx_global: 42282000,
    zy: 6062,
    zy_global: 6062000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1330,
    height_global: 1330,
  },
  {
    profile_section_id: 3479,
    name: "I125012B55040",
    type: "I Shape",
    designation: "I125012B55040",
    shape: "I",
    country_code: "Indian",
    ax: 761,
    ax_global: 76100,
    bf: 550,
    bf_global: 550,
    ct: "27,14",
    ct_global: "271,4",
    d: 1330,
    d_global: 1330,
    ix: 2547,
    ix_global: 25470000,
    iy: 128294,
    iy_global: 1282940000,
    iz: 2649397,
    iz_global: 26493970000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 42517,
    zx_global: 42517000,
    zy: 6581,
    zy_global: 6581000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1330,
    height_global: 1330,
  },
  {
    profile_section_id: 3480,
    name: "I160012B50040",
    type: "I Shape",
    designation: "I160012B50040",
    shape: "I",
    country_code: "Indian",
    ax: 763,
    ax_global: 76300,
    bf: 500,
    bf_global: 500,
    ct: "36,16",
    ct_global: "361,6",
    d: 1680,
    d_global: 1680,
    ix: 2354,
    ix_global: 23540000,
    iy: 100716,
    iy_global: 1007160000,
    iz: 4136155,
    iz_global: 41361550000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 52922,
    zx_global: 52922000,
    zy: 5543,
    zy_global: 5543000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3481,
    name: "I100016A50040",
    type: "I Shape",
    designation: "I100016A50040",
    shape: "I",
    country_code: "Indian",
    ax: 763,
    ax_global: 76300,
    bf: 500,
    bf_global: 500,
    ct: "20,34",
    ct_global: "203,4",
    d: 1080,
    d_global: 1080,
    ix: 2489,
    ix_global: 24890000,
    iy: 93014,
    iy_global: 930140000,
    iz: 1642476,
    iz_global: 16424760000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 34056,
    zx_global: 34056000,
    zy: 6122,
    zy_global: 6122000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1080,
    height_global: 1080,
  },
  {
    profile_section_id: 3482,
    name: "I160016C55025",
    type: "I Shape",
    designation: "I160016C55025",
    shape: "I",
    country_code: "Indian",
    ax: 770,
    ax_global: 77000,
    bf: 550,
    bf_global: 550,
    ct: "29,54",
    ct_global: "295,4",
    d: 1650,
    d_global: 1650,
    ix: 1050,
    ix_global: 10500000,
    iy: 90967,
    iy_global: 909670000,
    iz: 3752769,
    iz_global: 37527690000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 50181,
    zx_global: 50181000,
    zy: 5003,
    zy_global: 5003000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3483,
    name: "I125016B50040",
    type: "I Shape",
    designation: "I125016B50040",
    shape: "I",
    country_code: "Indian",
    ax: 771,
    ax_global: 77100,
    bf: 500,
    bf_global: 500,
    ct: "26,22",
    ct_global: "262,2",
    d: 1330,
    d_global: 1330,
    ix: 2432,
    ix_global: 24320000,
    iy: 101275,
    iy_global: 1012750000,
    iz: 2548038,
    iz_global: 25480380000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 41499,
    zx_global: 41499000,
    zy: 5600,
    zy_global: 5600000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1330,
    height_global: 1330,
  },
  {
    profile_section_id: 3484,
    name: "I100016B55040",
    type: "I Shape",
    designation: "I100016B55040",
    shape: "I",
    country_code: "Indian",
    ax: 771,
    ax_global: 77100,
    bf: 550,
    bf_global: 550,
    ct: "19,82",
    ct_global: "198,2",
    d: 1080,
    d_global: 1080,
    ix: 2611,
    ix_global: 26110000,
    iy: 128850,
    iy_global: 1288500000,
    iz: 1715527,
    iz_global: 17155270000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 34192,
    zx_global: 34192000,
    zy: 6634,
    zy_global: 6634000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1080,
    height_global: 1080,
  },
  {
    profile_section_id: 3485,
    name: "I160016B55032",
    type: "I Shape",
    designation: "I160016B55032",
    shape: "I",
    country_code: "Indian",
    ax: 779,
    ax_global: 77900,
    bf: 550,
    bf_global: 550,
    ct: "31,91",
    ct_global: "319,1",
    d: 1664,
    d_global: 1664,
    ix: 1548,
    ix_global: 15480000,
    iy: 106687,
    iy_global: 1066870000,
    iz: 3926669,
    iz_global: 39266690000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 51405,
    zx_global: 51405000,
    zy: 5462,
    zy_global: 5462000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3486,
    name: "I160016A50032",
    type: "I Shape",
    designation: "I160016A50032",
    shape: "I",
    country_code: "Indian",
    ax: 779,
    ax_global: 77900,
    bf: 500,
    bf_global: 500,
    ct: "32,64",
    ct_global: "326,4",
    d: 1664,
    d_global: 1664,
    ix: 1530,
    ix_global: 15300000,
    iy: 76368,
    iy_global: 763680000,
    iz: 3843088,
    iz_global: 38430880000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 51700,
    zx_global: 51700000,
    zy: 5160,
    zy_global: 5160000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3487,
    name: "I160016A40040",
    type: "I Shape",
    designation: "I160016A40040",
    shape: "I",
    country_code: "Indian",
    ax: 779,
    ax_global: 77900,
    bf: 400,
    bf_global: 400,
    ct: "35,97",
    ct_global: "359,7",
    d: 1680,
    d_global: 1680,
    ix: 2144,
    ix_global: 21440000,
    iy: 52368,
    iy_global: 523680000,
    iz: 3864183,
    iz_global: 38641830000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 51828,
    zx_global: 51828000,
    zy: 4360,
    zy_global: 4360000,
    width: 400,
    width_global: 400,
    thickness: 16,
    thickness_global: 16,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3488,
    name: "I160016D50025",
    type: "I Shape",
    designation: "I160016D50025",
    shape: "I",
    country_code: "Indian",
    ax: 781,
    ax_global: 78100,
    bf: 500,
    bf_global: 500,
    ct: "30,36",
    ct_global: "303,6",
    d: 1650,
    d_global: 1650,
    ix: 1036,
    ix_global: 10360000,
    iy: 73802,
    iy_global: 738020000,
    iz: 3730152,
    iz_global: 37301520000,
    tf: 16,
    tf_global: 16,
    tw: 25,
    tw_global: 25,
    zx: 50998,
    zx_global: 50998000,
    zy: 5005,
    zy_global: 5005000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1650,
    height_global: 1650,
  },
  {
    profile_section_id: 3489,
    name: "I160012C55032",
    type: "I Shape",
    designation: "I160012C55032",
    shape: "I",
    country_code: "Indian",
    ax: 783,
    ax_global: 78300,
    bf: 550,
    bf_global: 550,
    ct: "33,84",
    ct_global: "338,4",
    d: 1664,
    d_global: 1664,
    ix: 1552,
    ix_global: 15520000,
    iy: 109674,
    iy_global: 1096740000,
    iz: 4144776,
    iz_global: 41447760000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 54001,
    zx_global: 54001000,
    zy: 5969,
    zy_global: 5969000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3490,
    name: "I160012D50032",
    type: "I Shape",
    designation: "I160012D50032",
    shape: "I",
    country_code: "Indian",
    ax: 787,
    ax_global: 78700,
    bf: 500,
    bf_global: 500,
    ct: "34,46",
    ct_global: "344,6",
    d: 1664,
    d_global: 1664,
    ix: 1481,
    ix_global: 14810000,
    iy: 87659,
    iy_global: 876590000,
    iz: 4074110,
    iz_global: 40741100000,
    tf: 12,
    tf_global: 12,
    tw: 32,
    tw_global: 32,
    zx: 54237,
    zx_global: 54237000,
    zy: 5781,
    zy_global: 5781000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3491,
    name: "I160012A50040",
    type: "I Shape",
    designation: "I160012A50040",
    shape: "I",
    country_code: "Indian",
    ax: 795,
    ax_global: 79500,
    bf: 500,
    bf_global: 500,
    ct: "36,16",
    ct_global: "361,6",
    d: 1680,
    d_global: 1680,
    ix: 2445,
    ix_global: 24450000,
    iy: 92590,
    iy_global: 925900000,
    iz: 4265676,
    iz_global: 42656760000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 55828,
    zx_global: 55828000,
    zy: 6075,
    zy_global: 6075000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3492,
    name: "I125016A50040",
    type: "I Shape",
    designation: "I125016A50040",
    shape: "I",
    country_code: "Indian",
    ax: 803,
    ax_global: 80300,
    bf: 500,
    bf_global: 500,
    ct: "26,22",
    ct_global: "262,2",
    d: 1330,
    d_global: 1330,
    ix: 2523,
    ix_global: 25230000,
    iy: 93023,
    iy_global: 930230000,
    iz: 2615507,
    iz_global: 26155070000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 43844,
    zx_global: 43844000,
    zy: 6138,
    zy_global: 6138000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1330,
    height_global: 1330,
  },
  {
    profile_section_id: 3493,
    name: "I160012B55040",
    type: "I Shape",
    designation: "I160012B55040",
    shape: "I",
    country_code: "Indian",
    ax: 803,
    ax_global: 80300,
    bf: 550,
    bf_global: 550,
    ct: "35,62",
    ct_global: "356,2",
    d: 1680,
    d_global: 1680,
    ix: 2567,
    ix_global: 25670000,
    iy: 128299,
    iy_global: 1282990000,
    iz: 4405169,
    iz_global: 44051690000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 56202,
    zx_global: 56202000,
    zy: 6593,
    zy_global: 6593000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3494,
    name: "I125016B55040",
    type: "I Shape",
    designation: "I125016B55040",
    shape: "I",
    country_code: "Indian",
    ax: 811,
    ax_global: 81100,
    bf: 550,
    bf_global: 550,
    ct: "25,63",
    ct_global: "256,3",
    d: 1330,
    d_global: 1330,
    ix: 2646,
    ix_global: 26460000,
    iy: 128858,
    iy_global: 1288580000,
    iz: 2714501,
    iz_global: 27145010000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 44079,
    zx_global: 44079000,
    zy: 6650,
    zy_global: 6650000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1330,
    height_global: 1330,
  },
  {
    profile_section_id: 3495,
    name: "I160016C50032",
    type: "I Shape",
    designation: "I160016C50032",
    shape: "I",
    country_code: "Indian",
    ax: 815,
    ax_global: 81500,
    bf: 500,
    bf_global: 500,
    ct: "32,64",
    ct_global: "326,4",
    d: 1664,
    d_global: 1664,
    ix: 1569,
    ix_global: 15690000,
    iy: 88311,
    iy_global: 883110000,
    iz: 4068208,
    iz_global: 40682080000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 53950,
    zx_global: 53950000,
    zy: 5222,
    zy_global: 5222000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3496,
    name: "I160016B50040",
    type: "I Shape",
    designation: "I160016B50040",
    shape: "I",
    country_code: "Indian",
    ax: 827,
    ax_global: 82700,
    bf: 500,
    bf_global: 500,
    ct: "34,6",
    ct_global: 346,
    d: 1680,
    d_global: 1680,
    ix: 2480,
    ix_global: 24800000,
    iy: 101287,
    iy_global: 1012870000,
    iz: 4272689,
    iz_global: 42726890000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 55482,
    zx_global: 55482000,
    zy: 5622,
    zy_global: 5622000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3497,
    name: "I160012C50040",
    type: "I Shape",
    designation: "I160012C50040",
    shape: "I",
    country_code: "Indian",
    ax: 831,
    ax_global: 83100,
    bf: 500,
    bf_global: 500,
    ct: "36,16",
    ct_global: "361,6",
    d: 1680,
    d_global: 1680,
    ix: 2484,
    ix_global: 24840000,
    iy: 104274,
    iy_global: 1042740000,
    iz: 4490796,
    iz_global: 44907960000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 58078,
    zx_global: 58078000,
    zy: 6129,
    zy_global: 6129000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3498,
    name: "I160016C55032",
    type: "I Shape",
    designation: "I160016C55032",
    shape: "I",
    country_code: "Indian",
    ax: 847,
    ax_global: 84700,
    bf: 550,
    bf_global: 550,
    ct: "31,91",
    ct_global: "319,1",
    d: 1664,
    d_global: 1664,
    ix: 1678,
    ix_global: 16780000,
    iy: 110378,
    iy_global: 1103780000,
    iz: 4281309,
    iz_global: 42813090000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 56561,
    zx_global: 56561000,
    zy: 6062,
    zy_global: 6062000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3499,
    name: "I160016D50032",
    type: "I Shape",
    designation: "I160016D50032",
    shape: "I",
    country_code: "Indian",
    ax: 851,
    ax_global: 85100,
    bf: 500,
    bf_global: 500,
    ct: "32,64",
    ct_global: "326,4",
    d: 1664,
    d_global: 1664,
    ix: 1608,
    ix_global: 16080000,
    iy: 88385,
    iy_global: 883850000,
    iz: 4210644,
    iz_global: 42106440000,
    tf: 16,
    tf_global: 16,
    tw: 32,
    tw_global: 32,
    zx: 56797,
    zx_global: 56797000,
    zy: 5880,
    zy_global: 5880000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1664,
    height_global: 1664,
  },
  {
    profile_section_id: 3500,
    name: "I160016A50040",
    type: "I Shape",
    designation: "I160016A50040",
    shape: "I",
    country_code: "Indian",
    ax: 859,
    ax_global: 85900,
    bf: 500,
    bf_global: 500,
    ct: "34,6",
    ct_global: 346,
    d: 1680,
    d_global: 1680,
    ix: 2571,
    ix_global: 25710000,
    iy: 93035,
    iy_global: 930350000,
    iz: 4402209,
    iz_global: 44022090000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 58388,
    zx_global: 58388000,
    zy: 6160,
    zy_global: 6160000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3501,
    name: "I160012D50040",
    type: "I Shape",
    designation: "I160012D50040",
    shape: "I",
    country_code: "Indian",
    ax: 867,
    ax_global: 86700,
    bf: 500,
    bf_global: 500,
    ct: "36,16",
    ct_global: "361,6",
    d: 1680,
    d_global: 1680,
    ix: 2523,
    ix_global: 25230000,
    iy: 104326,
    iy_global: 1043260000,
    iz: 4633231,
    iz_global: 46332310000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 60925,
    zx_global: 60925000,
    zy: 6781,
    zy_global: 6781000,
    width: 500,
    width_global: 500,
    thickness: 12,
    thickness_global: 12,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3502,
    name: "I160016B55040",
    type: "I Shape",
    designation: "I160016B55040",
    shape: "I",
    country_code: "Indian",
    ax: 867,
    ax_global: 86700,
    bf: 550,
    bf_global: 550,
    ct: "33,95",
    ct_global: "339,5",
    d: 1680,
    d_global: 1680,
    ix: 2693,
    ix_global: 26930000,
    iy: 128870,
    iy_global: 1288700000,
    iz: 4541702,
    iz_global: 45417020000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 58762,
    zx_global: 58762000,
    zy: 6672,
    zy_global: 6672000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3503,
    name: "I160012C55040",
    type: "I Shape",
    designation: "I160012C55040",
    shape: "I",
    country_code: "Indian",
    ax: 871,
    ax_global: 87100,
    bf: 550,
    bf_global: 550,
    ct: "35,62",
    ct_global: "356,2",
    d: 1680,
    d_global: 1680,
    ix: 2697,
    ix_global: 26970000,
    iy: 131857,
    iy_global: 1318570000,
    iz: 4759809,
    iz_global: 47598090000,
    tf: 12,
    tf_global: 12,
    tw: 40,
    tw_global: 40,
    zx: 61358,
    zx_global: 61358000,
    zy: 7179,
    zy_global: 7179000,
    width: 550,
    width_global: 550,
    thickness: 12,
    thickness_global: 12,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3504,
    name: "I160016C50040",
    type: "I Shape",
    designation: "I160016C50040",
    shape: "I",
    country_code: "Indian",
    ax: 895,
    ax_global: 89500,
    bf: 500,
    bf_global: 500,
    ct: "34,6",
    ct_global: 346,
    d: 1680,
    d_global: 1680,
    ix: 2610,
    ix_global: 26100000,
    iy: 104978,
    iy_global: 1049780000,
    iz: 4627329,
    iz_global: 46273290000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 60638,
    zx_global: 60638000,
    zy: 6222,
    zy_global: 6222000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3505,
    name: "I160016D50040",
    type: "I Shape",
    designation: "I160016D50040",
    shape: "I",
    country_code: "Indian",
    ax: 931,
    ax_global: 93100,
    bf: 500,
    bf_global: 500,
    ct: "34,6",
    ct_global: 346,
    d: 1680,
    d_global: 1680,
    ix: 2649,
    ix_global: 26490000,
    iy: 105052,
    iy_global: 1050520000,
    iz: 4769765,
    iz_global: 47697650000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 63485,
    zx_global: 63485000,
    zy: 6880,
    zy_global: 6880000,
    width: 500,
    width_global: 500,
    thickness: 16,
    thickness_global: 16,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3506,
    name: "I160016C55040",
    type: "I Shape",
    designation: "I160016C55040",
    shape: "I",
    country_code: "Indian",
    ax: 935,
    ax_global: 93500,
    bf: 550,
    bf_global: 550,
    ct: "33,95",
    ct_global: "339,5",
    d: 1680,
    d_global: 1680,
    ix: 2823,
    ix_global: 28230000,
    iy: 132561,
    iy_global: 1325610000,
    iz: 4896342,
    iz_global: 48963420000,
    tf: 16,
    tf_global: 16,
    tw: 40,
    tw_global: 40,
    zx: 63918,
    zx_global: 63918000,
    zy: 7272,
    zy_global: 7272000,
    width: 550,
    width_global: 550,
    thickness: 16,
    thickness_global: 16,
    height: 1680,
    height_global: 1680,
  },
  {
    profile_section_id: 3507,
    name: "ISA20x20x3",
    type: "Angle",
    designation: "ISA20x20x3",
    shape: "L",
    country_code: "Indian",
    ax: "1,12",
    ax_global: 112,
    b: 20,
    b_global: 20,
    d: 20,
    d_global: 20,
    r1: 4,
    r1_global: 4,
    rz: "3,77",
    rz_global: "3,77",
    t: 3,
    t_global: 3,
    width: 20,
    width_global: 20,
    thickness: 3,
    thickness_global: 3,
    height: 20,
    height_global: 20,
  },
  {
    profile_section_id: 3508,
    name: "ISA20x20x4",
    type: "Angle",
    designation: "ISA20x20x4",
    shape: "L",
    country_code: "Indian",
    ax: "1,45",
    ax_global: 145,
    b: 20,
    b_global: 20,
    d: 20,
    d_global: 20,
    r1: 4,
    r1_global: 4,
    rz: "3,77",
    rz_global: "3,77",
    t: 4,
    t_global: 4,
    width: 20,
    width_global: 20,
    thickness: 4,
    thickness_global: 4,
    height: 20,
    height_global: 20,
  },
  {
    profile_section_id: 3509,
    name: "ISA25x25x3",
    type: "Angle",
    designation: "ISA25x25x3",
    shape: "L",
    country_code: "Indian",
    ax: "1,41",
    ax_global: 141,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    r1: "4,5",
    r1_global: "4,5",
    rz: "4,74",
    rz_global: "4,74",
    t: 3,
    t_global: 3,
    width: 25,
    width_global: 25,
    thickness: 3,
    thickness_global: 3,
    height: 25,
    height_global: 25,
  },
  {
    profile_section_id: 3510,
    name: "ISA25x25x4",
    type: "Angle",
    designation: "ISA25x25x4",
    shape: "L",
    country_code: "Indian",
    ax: "1,84",
    ax_global: 184,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    r1: "4,5",
    r1_global: "4,5",
    rz: "4,73",
    rz_global: "4,73",
    t: 4,
    t_global: 4,
    width: 25,
    width_global: 25,
    thickness: 4,
    thickness_global: 4,
    height: 25,
    height_global: 25,
  },
  {
    profile_section_id: 3511,
    name: "ISA25x25x5",
    type: "Angle",
    designation: "ISA25x25x5",
    shape: "L",
    country_code: "Indian",
    ax: "2,25",
    ax_global: 225,
    b: 25,
    b_global: 25,
    d: 25,
    d_global: 25,
    r1: "4,5",
    r1_global: "4,5",
    rz: "4,73",
    rz_global: "4,73",
    t: 5,
    t_global: 5,
    width: 25,
    width_global: 25,
    thickness: 5,
    thickness_global: 5,
    height: 25,
    height_global: 25,
  },
  {
    profile_section_id: 3512,
    name: "ISA30x20x3",
    type: "Angle",
    designation: "ISA30x20x3",
    shape: "L",
    country_code: "Indian",
    ax: "1,41",
    ax_global: 141,
    b: 20,
    b_global: 20,
    d: 30,
    d_global: 30,
    r1: "4,5",
    r1_global: "4,5",
    rz: "4,15",
    rz_global: "4,15",
    t: 3,
    t_global: 3,
    width: 20,
    width_global: 20,
    thickness: 3,
    thickness_global: 3,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 3513,
    name: "ISA30x20x4",
    type: "Angle",
    designation: "ISA30x20x4",
    shape: "L",
    country_code: "Indian",
    ax: "1,84",
    ax_global: 184,
    b: 20,
    b_global: 20,
    d: 30,
    d_global: 30,
    r1: "4,5",
    r1_global: "4,5",
    rz: "4,14",
    rz_global: "4,14",
    t: 4,
    t_global: 4,
    width: 20,
    width_global: 20,
    thickness: 4,
    thickness_global: 4,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 3514,
    name: "ISA30x20x5",
    type: "Angle",
    designation: "ISA30x20x5",
    shape: "L",
    country_code: "Indian",
    ax: "2,25",
    ax_global: 225,
    b: 20,
    b_global: 20,
    d: 30,
    d_global: 30,
    r1: "4,5",
    r1_global: "4,5",
    rz: "4,15",
    rz_global: "4,15",
    t: 5,
    t_global: 5,
    width: 20,
    width_global: 20,
    thickness: 5,
    thickness_global: 5,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 3515,
    name: "ISA30x30x3",
    type: "Angle",
    designation: "ISA30x30x3",
    shape: "L",
    country_code: "Indian",
    ax: "1,73",
    ax_global: 173,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    r1: 5,
    r1_global: 5,
    rz: "5,76",
    rz_global: "5,76",
    t: 3,
    t_global: 3,
    width: 30,
    width_global: 30,
    thickness: 3,
    thickness_global: 3,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 3516,
    name: "ISA30x30x4",
    type: "Angle",
    designation: "ISA30x30x4",
    shape: "L",
    country_code: "Indian",
    ax: "2,26",
    ax_global: 226,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    r1: 5,
    r1_global: 5,
    rz: "5,73",
    rz_global: "5,73",
    t: 4,
    t_global: 4,
    width: 30,
    width_global: 30,
    thickness: 4,
    thickness_global: 4,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 3517,
    name: "ISA30x30x5",
    type: "Angle",
    designation: "ISA30x30x5",
    shape: "L",
    country_code: "Indian",
    ax: "2,77",
    ax_global: 277,
    b: 30,
    b_global: 30,
    d: 30,
    d_global: 30,
    r1: 5,
    r1_global: 5,
    rz: "5,72",
    rz_global: "5,72",
    t: 5,
    t_global: 5,
    width: 30,
    width_global: 30,
    thickness: 5,
    thickness_global: 5,
    height: 30,
    height_global: 30,
  },
  {
    profile_section_id: 3518,
    name: "ISA35x35x3",
    type: "Angle",
    designation: "ISA35x35x3",
    shape: "L",
    country_code: "Indian",
    ax: "2,03",
    ax_global: 203,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    r1: 5,
    r1_global: 5,
    rz: "6,8",
    rz_global: "6,8",
    t: 3,
    t_global: 3,
    width: 35,
    width_global: 35,
    thickness: 3,
    thickness_global: 3,
    height: 35,
    height_global: 35,
  },
  {
    profile_section_id: 3519,
    name: "ISA35x35x4",
    type: "Angle",
    designation: "ISA35x35x4",
    shape: "L",
    country_code: "Indian",
    ax: "2,66",
    ax_global: 266,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    r1: 5,
    r1_global: 5,
    rz: "6,75",
    rz_global: "6,75",
    t: 4,
    t_global: 4,
    width: 35,
    width_global: 35,
    thickness: 4,
    thickness_global: 4,
    height: 35,
    height_global: 35,
  },
  {
    profile_section_id: 3520,
    name: "ISA35x35x5",
    type: "Angle",
    designation: "ISA35x35x5",
    shape: "L",
    country_code: "Indian",
    ax: "3,27",
    ax_global: 327,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    r1: 5,
    r1_global: 5,
    rz: "6,72",
    rz_global: "6,72",
    t: 5,
    t_global: 5,
    width: 35,
    width_global: 35,
    thickness: 5,
    thickness_global: 5,
    height: 35,
    height_global: 35,
  },
  {
    profile_section_id: 3521,
    name: "ISA35x35x6",
    type: "Angle",
    designation: "ISA35x35x6",
    shape: "L",
    country_code: "Indian",
    ax: "3,86",
    ax_global: 386,
    b: 35,
    b_global: 35,
    d: 35,
    d_global: 35,
    r1: 5,
    r1_global: 5,
    rz: "6,71",
    rz_global: "6,71",
    t: 6,
    t_global: 6,
    width: 35,
    width_global: 35,
    thickness: 6,
    thickness_global: 6,
    height: 35,
    height_global: 35,
  },
  {
    profile_section_id: 3522,
    name: "ISA40x20x3",
    type: "Angle",
    designation: "ISA40x20x3",
    shape: "L",
    country_code: "Indian",
    ax: "1,73",
    ax_global: 173,
    b: 20,
    b_global: 20,
    d: 40,
    d_global: 40,
    r1: 4,
    r1_global: 4,
    rz: "4,21",
    rz_global: "4,21",
    t: 3,
    t_global: 3,
    width: 20,
    width_global: 20,
    thickness: 3,
    thickness_global: 3,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 3523,
    name: "ISA40x20x4",
    type: "Angle",
    designation: "ISA40x20x4",
    shape: "L",
    country_code: "Indian",
    ax: "2,26",
    ax_global: 226,
    b: 20,
    b_global: 20,
    d: 40,
    d_global: 40,
    r1: 4,
    r1_global: 4,
    rz: "4,17",
    rz_global: "4,17",
    t: 4,
    t_global: 4,
    width: 20,
    width_global: 20,
    thickness: 4,
    thickness_global: 4,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 3524,
    name: "ISA40x20x5",
    type: "Angle",
    designation: "ISA40x20x5",
    shape: "L",
    country_code: "Indian",
    ax: "2,77",
    ax_global: 277,
    b: 20,
    b_global: 20,
    d: 40,
    d_global: 40,
    r1: 4,
    r1_global: 4,
    rz: "4,16",
    rz_global: "4,16",
    t: 5,
    t_global: 5,
    width: 20,
    width_global: 20,
    thickness: 5,
    thickness_global: 5,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 3525,
    name: "ISA40x25x3",
    type: "Angle",
    designation: "ISA40x25x3",
    shape: "L",
    country_code: "Indian",
    ax: "1,88",
    ax_global: 188,
    b: 25,
    b_global: 25,
    d: 40,
    d_global: 40,
    r1: 5,
    r1_global: 5,
    rz: "5,29",
    rz_global: "5,29",
    t: 3,
    t_global: 3,
    width: 25,
    width_global: 25,
    thickness: 3,
    thickness_global: 3,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 3526,
    name: "ISA40x25x4",
    type: "Angle",
    designation: "ISA40x25x4",
    shape: "L",
    country_code: "Indian",
    ax: "2,46",
    ax_global: 246,
    b: 25,
    b_global: 25,
    d: 40,
    d_global: 40,
    r1: 5,
    r1_global: 5,
    rz: "5,27",
    rz_global: "5,27",
    t: 4,
    t_global: 4,
    width: 25,
    width_global: 25,
    thickness: 4,
    thickness_global: 4,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 3527,
    name: "ISA40x25x5",
    type: "Angle",
    designation: "ISA40x25x5",
    shape: "L",
    country_code: "Indian",
    ax: "3,02",
    ax_global: 302,
    b: 25,
    b_global: 25,
    d: 40,
    d_global: 40,
    r1: 5,
    r1_global: 5,
    rz: "5,25",
    rz_global: "5,25",
    t: 5,
    t_global: 5,
    width: 25,
    width_global: 25,
    thickness: 5,
    thickness_global: 5,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 3528,
    name: "ISA40x25x6",
    type: "Angle",
    designation: "ISA40x25x6",
    shape: "L",
    country_code: "Indian",
    ax: "3,56",
    ax_global: 356,
    b: 25,
    b_global: 25,
    d: 40,
    d_global: 40,
    r1: 5,
    r1_global: 5,
    rz: "5,24",
    rz_global: "5,24",
    t: 6,
    t_global: 6,
    width: 25,
    width_global: 25,
    thickness: 6,
    thickness_global: 6,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 3529,
    name: "ISA40x40x3",
    type: "Angle",
    designation: "ISA40x40x3",
    shape: "L",
    country_code: "Indian",
    ax: "2,34",
    ax_global: 234,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    r1: "5,5",
    r1_global: "5,5",
    rz: "7,83",
    rz_global: "7,83",
    t: 3,
    t_global: 3,
    width: 40,
    width_global: 40,
    thickness: 3,
    thickness_global: 3,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 3530,
    name: "ISA40x40x4",
    type: "Angle",
    designation: "ISA40x40x4",
    shape: "L",
    country_code: "Indian",
    ax: "3,07",
    ax_global: 307,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    r1: "5,5",
    r1_global: "5,5",
    rz: "7,77",
    rz_global: "7,77",
    t: 4,
    t_global: 4,
    width: 40,
    width_global: 40,
    thickness: 4,
    thickness_global: 4,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 3531,
    name: "ISA40x40x5",
    type: "Angle",
    designation: "ISA40x40x5",
    shape: "L",
    country_code: "Indian",
    ax: "3,78",
    ax_global: 378,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    r1: "5,5",
    r1_global: "5,5",
    rz: "7,73",
    rz_global: "7,73",
    t: 5,
    t_global: 5,
    width: 40,
    width_global: 40,
    thickness: 5,
    thickness_global: 5,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 3532,
    name: "ISA40x40x6",
    type: "Angle",
    designation: "ISA40x40x6",
    shape: "L",
    country_code: "Indian",
    ax: "4,47",
    ax_global: 447,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    r1: "5,5",
    r1_global: "5,5",
    rz: "7,7",
    rz_global: "7,7",
    t: 6,
    t_global: 6,
    width: 40,
    width_global: 40,
    thickness: 6,
    thickness_global: 6,
    height: 40,
    height_global: 40,
  },
  {
    profile_section_id: 3533,
    name: "ISA45x30x3",
    type: "Angle",
    designation: "ISA45x30x3",
    shape: "L",
    country_code: "Indian",
    ax: "2,18",
    ax_global: 218,
    b: 30,
    b_global: 30,
    d: 45,
    d_global: 45,
    r1: 5,
    r1_global: 5,
    rz: "6,43",
    rz_global: "6,43",
    t: 3,
    t_global: 3,
    width: 30,
    width_global: 30,
    thickness: 3,
    thickness_global: 3,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 3534,
    name: "ISA45x30x4",
    type: "Angle",
    designation: "ISA45x30x4",
    shape: "L",
    country_code: "Indian",
    ax: "2,86",
    ax_global: 286,
    b: 30,
    b_global: 30,
    d: 45,
    d_global: 45,
    r1: 5,
    r1_global: 5,
    rz: "6,39",
    rz_global: "6,39",
    t: 4,
    t_global: 4,
    width: 30,
    width_global: 30,
    thickness: 4,
    thickness_global: 4,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 3535,
    name: "ISA45x30x5",
    type: "Angle",
    designation: "ISA45x30x5",
    shape: "L",
    country_code: "Indian",
    ax: "3,52",
    ax_global: 352,
    b: 30,
    b_global: 30,
    d: 45,
    d_global: 45,
    r1: 5,
    r1_global: 5,
    rz: "6,35",
    rz_global: "6,35",
    t: 5,
    t_global: 5,
    width: 30,
    width_global: 30,
    thickness: 5,
    thickness_global: 5,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 3536,
    name: "ISA45x30x6",
    type: "Angle",
    designation: "ISA45x30x6",
    shape: "L",
    country_code: "Indian",
    ax: "4,16",
    ax_global: 416,
    b: 30,
    b_global: 30,
    d: 45,
    d_global: 45,
    r1: 5,
    r1_global: 5,
    rz: "6,33",
    rz_global: "6,33",
    t: 6,
    t_global: 6,
    width: 30,
    width_global: 30,
    thickness: 6,
    thickness_global: 6,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 3537,
    name: "ISA45x45x3",
    type: "Angle",
    designation: "ISA45x45x3",
    shape: "L",
    country_code: "Indian",
    ax: "2,64",
    ax_global: 264,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    r1: "5,5",
    r1_global: "5,5",
    rz: "8,86",
    rz_global: "8,86",
    t: 3,
    t_global: 3,
    width: 45,
    width_global: 45,
    thickness: 3,
    thickness_global: 3,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 3538,
    name: "ISA45x45x4",
    type: "Angle",
    designation: "ISA45x45x4",
    shape: "L",
    country_code: "Indian",
    ax: "3,47",
    ax_global: 347,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    r1: "5,5",
    r1_global: "5,5",
    rz: "8,79",
    rz_global: "8,79",
    t: 4,
    t_global: 4,
    width: 45,
    width_global: 45,
    thickness: 4,
    thickness_global: 4,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 3539,
    name: "ISA45x45x5",
    type: "Angle",
    designation: "ISA45x45x5",
    shape: "L",
    country_code: "Indian",
    ax: "4,28",
    ax_global: 428,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    r1: "5,5",
    r1_global: "5,5",
    rz: "8,74",
    rz_global: "8,74",
    t: 5,
    t_global: 5,
    width: 45,
    width_global: 45,
    thickness: 5,
    thickness_global: 5,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 3540,
    name: "ISA45x45x6",
    type: "Angle",
    designation: "ISA45x45x6",
    shape: "L",
    country_code: "Indian",
    ax: "5,07",
    ax_global: 507,
    b: 45,
    b_global: 45,
    d: 45,
    d_global: 45,
    r1: "5,5",
    r1_global: "5,5",
    rz: "8,71",
    rz_global: "8,71",
    t: 6,
    t_global: 6,
    width: 45,
    width_global: 45,
    thickness: 6,
    thickness_global: 6,
    height: 45,
    height_global: 45,
  },
  {
    profile_section_id: 3541,
    name: "ISA50x30x3",
    type: "Angle",
    designation: "ISA50x30x3",
    shape: "L",
    country_code: "Indian",
    ax: "2,34",
    ax_global: 234,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    r1: "5,5",
    r1_global: "5,5",
    rz: "6,44",
    rz_global: "6,44",
    t: 3,
    t_global: 3,
    width: 30,
    width_global: 30,
    thickness: 3,
    thickness_global: 3,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 3542,
    name: "ISA50x30x4",
    type: "Angle",
    designation: "ISA50x30x4",
    shape: "L",
    country_code: "Indian",
    ax: "3,07",
    ax_global: 307,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    r1: "5,5",
    r1_global: "5,5",
    rz: "6,4",
    rz_global: "6,4",
    t: 4,
    t_global: 4,
    width: 30,
    width_global: 30,
    thickness: 4,
    thickness_global: 4,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 3543,
    name: "ISA50x30x5",
    type: "Angle",
    designation: "ISA50x30x5",
    shape: "L",
    country_code: "Indian",
    ax: "3,78",
    ax_global: 378,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    r1: "5,5",
    r1_global: "5,5",
    rz: "6,36",
    rz_global: "6,36",
    t: 5,
    t_global: 5,
    width: 30,
    width_global: 30,
    thickness: 5,
    thickness_global: 5,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 3544,
    name: "ISA50x30x6",
    type: "Angle",
    designation: "ISA50x30x6",
    shape: "L",
    country_code: "Indian",
    ax: "4,47",
    ax_global: 447,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    r1: "5,5",
    r1_global: "5,5",
    rz: "6,33",
    rz_global: "6,33",
    t: 6,
    t_global: 6,
    width: 30,
    width_global: 30,
    thickness: 6,
    thickness_global: 6,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 3545,
    name: "ISA50x50x3",
    type: "Angle",
    designation: "ISA50x50x3",
    shape: "L",
    country_code: "Indian",
    ax: "2,95",
    ax_global: 295,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: 6,
    r1_global: 6,
    rz: "9,9",
    rz_global: "9,9",
    t: 3,
    t_global: 3,
    width: 50,
    width_global: 50,
    thickness: 3,
    thickness_global: 3,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 3546,
    name: "ISA50x50x4",
    type: "Angle",
    designation: "ISA50x50x4",
    shape: "L",
    country_code: "Indian",
    ax: "3,88",
    ax_global: 388,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: 6,
    r1_global: 6,
    rz: "9,82",
    rz_global: "9,82",
    t: 4,
    t_global: 4,
    width: 50,
    width_global: 50,
    thickness: 4,
    thickness_global: 4,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 3547,
    name: "ISA50x50x5",
    type: "Angle",
    designation: "ISA50x50x5",
    shape: "L",
    country_code: "Indian",
    ax: "4,79",
    ax_global: 479,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: 6,
    r1_global: 6,
    rz: "9,76",
    rz_global: "9,76",
    t: 5,
    t_global: 5,
    width: 50,
    width_global: 50,
    thickness: 5,
    thickness_global: 5,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 3548,
    name: "ISA50x50x6",
    type: "Angle",
    designation: "ISA50x50x6",
    shape: "L",
    country_code: "Indian",
    ax: "5,68",
    ax_global: 568,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: 6,
    r1_global: 6,
    rz: "9,72",
    rz_global: "9,72",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 3549,
    name: "ISA50x50x7",
    type: "Angle",
    designation: "ISA50x50x7",
    shape: "L",
    country_code: "Indian",
    ax: "6,56",
    ax_global: 656,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: 7,
    r1_global: 7,
    rz: "9,65",
    rz_global: "9,65",
    t: 7,
    t_global: 7,
    width: 50,
    width_global: 50,
    thickness: 7,
    thickness_global: 7,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 3550,
    name: "ISA50x50x8",
    type: "Angle",
    designation: "ISA50x50x8",
    shape: "L",
    country_code: "Indian",
    ax: "7,41",
    ax_global: 741,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    r1: 7,
    r1_global: 7,
    rz: "9,63",
    rz_global: "9,63",
    t: 8,
    t_global: 8,
    width: 50,
    width_global: 50,
    thickness: 8,
    thickness_global: 8,
    height: 50,
    height_global: 50,
  },
  {
    profile_section_id: 3551,
    name: "ISA55x55x5",
    type: "Angle",
    designation: "ISA55x55x5",
    shape: "L",
    country_code: "Indian",
    ax: "5,27",
    ax_global: 527,
    b: 55,
    b_global: 55,
    d: 55,
    d_global: 55,
    r1: "6,5",
    r1_global: "6,5",
    rz: "10,7",
    rz_global: "10,7",
    t: 5,
    t_global: 5,
    width: 55,
    width_global: 55,
    thickness: 5,
    thickness_global: 5,
    height: 55,
    height_global: 55,
  },
  {
    profile_section_id: 3552,
    name: "ISA55x55x6",
    type: "Angle",
    designation: "ISA55x55x6",
    shape: "L",
    country_code: "Indian",
    ax: "6,26",
    ax_global: 626,
    b: 55,
    b_global: 55,
    d: 55,
    d_global: 55,
    r1: "6,5",
    r1_global: "6,5",
    rz: "10,7",
    rz_global: "10,7",
    t: 6,
    t_global: 6,
    width: 55,
    width_global: 55,
    thickness: 6,
    thickness_global: 6,
    height: 55,
    height_global: 55,
  },
  {
    profile_section_id: 3553,
    name: "ISA55x55x8",
    type: "Angle",
    designation: "ISA55x55x8",
    shape: "L",
    country_code: "Indian",
    ax: "8,18",
    ax_global: 818,
    b: 55,
    b_global: 55,
    d: 55,
    d_global: 55,
    r1: "6,5",
    r1_global: "6,5",
    rz: "10,6",
    rz_global: "10,6",
    t: 8,
    t_global: 8,
    width: 55,
    width_global: 55,
    thickness: 8,
    thickness_global: 8,
    height: 55,
    height_global: 55,
  },
  {
    profile_section_id: 3554,
    name: "ISA55x55x10",
    type: "Angle",
    designation: "ISA55x55x10",
    shape: "L",
    country_code: "Indian",
    ax: 10,
    ax_global: 1000,
    b: 55,
    b_global: 55,
    d: 55,
    d_global: 55,
    r1: "6,5",
    r1_global: "6,5",
    rz: "10,6",
    rz_global: "10,6",
    t: 10,
    t_global: 10,
    width: 55,
    width_global: 55,
    thickness: 10,
    thickness_global: 10,
    height: 55,
    height_global: 55,
  },
  {
    profile_section_id: 3555,
    name: "ISA60x30x5",
    type: "Angle",
    designation: "ISA60x30x5",
    shape: "L",
    country_code: "Indian",
    ax: "4,29",
    ax_global: 429,
    b: 30,
    b_global: 30,
    d: 60,
    d_global: 60,
    r1: 6,
    r1_global: 6,
    rz: "6,29",
    rz_global: "6,29",
    t: 5,
    t_global: 5,
    width: 30,
    width_global: 30,
    thickness: 5,
    thickness_global: 5,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 3556,
    name: "ISA60x30x6",
    type: "Angle",
    designation: "ISA60x30x6",
    shape: "L",
    country_code: "Indian",
    ax: "5,08",
    ax_global: 508,
    b: 30,
    b_global: 30,
    d: 60,
    d_global: 60,
    r1: 6,
    r1_global: 6,
    rz: "6,26",
    rz_global: "6,26",
    t: 6,
    t_global: 6,
    width: 30,
    width_global: 30,
    thickness: 6,
    thickness_global: 6,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 3557,
    name: "ISA60x40x5",
    type: "Angle",
    designation: "ISA60x40x5",
    shape: "L",
    country_code: "Indian",
    ax: "4,76",
    ax_global: 476,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    r1: 6,
    r1_global: 6,
    rz: "8,53",
    rz_global: "8,53",
    t: 5,
    t_global: 5,
    width: 40,
    width_global: 40,
    thickness: 5,
    thickness_global: 5,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 3558,
    name: "ISA60x40x6",
    type: "Angle",
    designation: "ISA60x40x6",
    shape: "L",
    country_code: "Indian",
    ax: "5,65",
    ax_global: 565,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    r1: 6,
    r1_global: 6,
    rz: "8,49",
    rz_global: "8,49",
    t: 6,
    t_global: 6,
    width: 40,
    width_global: 40,
    thickness: 6,
    thickness_global: 6,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 3559,
    name: "ISA60x40x7",
    type: "Angle",
    designation: "ISA60x40x7",
    shape: "L",
    country_code: "Indian",
    ax: "6,55",
    ax_global: 655,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    r1: 6,
    r1_global: 6,
    rz: "8,52",
    rz_global: "8,52",
    t: 7,
    t_global: 7,
    width: 40,
    width_global: 40,
    thickness: 7,
    thickness_global: 7,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 3560,
    name: "ISA60x40x8",
    type: "Angle",
    designation: "ISA60x40x8",
    shape: "L",
    country_code: "Indian",
    ax: "7,37",
    ax_global: 737,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    r1: 6,
    r1_global: 6,
    rz: "8,45",
    rz_global: "8,45",
    t: 8,
    t_global: 8,
    width: 40,
    width_global: 40,
    thickness: 8,
    thickness_global: 8,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 3561,
    name: "ISA60x60x4",
    type: "Angle",
    designation: "ISA60x60x4",
    shape: "L",
    country_code: "Indian",
    ax: "4,71",
    ax_global: 471,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    r1: 8,
    r1_global: 8,
    rz: "11,8",
    rz_global: "11,8",
    t: 4,
    t_global: 4,
    width: 60,
    width_global: 60,
    thickness: 4,
    thickness_global: 4,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 3562,
    name: "ISA60x60x5",
    type: "Angle",
    designation: "ISA60x60x5",
    shape: "L",
    country_code: "Indian",
    ax: "5,75",
    ax_global: 575,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    r1: "6,5",
    r1_global: "6,5",
    rz: "11,7",
    rz_global: "11,7",
    t: 5,
    t_global: 5,
    width: 60,
    width_global: 60,
    thickness: 5,
    thickness_global: 5,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 3563,
    name: "ISA60x60x6",
    type: "Angle",
    designation: "ISA60x60x6",
    shape: "L",
    country_code: "Indian",
    ax: "6,84",
    ax_global: 684,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    r1: "6,5",
    r1_global: "6,5",
    rz: "11,7",
    rz_global: "11,7",
    t: 6,
    t_global: 6,
    width: 60,
    width_global: 60,
    thickness: 6,
    thickness_global: 6,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 3564,
    name: "ISA60x60x8",
    type: "Angle",
    designation: "ISA60x60x8",
    shape: "L",
    country_code: "Indian",
    ax: "8,96",
    ax_global: 896,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    r1: "6,5",
    r1_global: "6,5",
    rz: "11,6",
    rz_global: "11,6",
    t: 8,
    t_global: 8,
    width: 60,
    width_global: 60,
    thickness: 8,
    thickness_global: 8,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 3565,
    name: "ISA60x60x10",
    type: "Angle",
    designation: "ISA60x60x10",
    shape: "L",
    country_code: "Indian",
    ax: 11,
    ax_global: 1100,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    r1: "6,5",
    r1_global: "6,5",
    rz: "11,6",
    rz_global: "11,6",
    t: 10,
    t_global: 10,
    width: 60,
    width_global: 60,
    thickness: 10,
    thickness_global: 10,
    height: 60,
    height_global: 60,
  },
  {
    profile_section_id: 3566,
    name: "ISA65x45x5",
    type: "Angle",
    designation: "ISA65x45x5",
    shape: "L",
    country_code: "Indian",
    ax: "5,26",
    ax_global: 526,
    b: 45,
    b_global: 45,
    d: 65,
    d_global: 65,
    r1: 6,
    r1_global: 6,
    rz: "9,64",
    rz_global: "9,64",
    t: 5,
    t_global: 5,
    width: 45,
    width_global: 45,
    thickness: 5,
    thickness_global: 5,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 3567,
    name: "ISA65x45x6",
    type: "Angle",
    designation: "ISA65x45x6",
    shape: "L",
    country_code: "Indian",
    ax: "6,25",
    ax_global: 625,
    b: 45,
    b_global: 45,
    d: 65,
    d_global: 65,
    r1: 6,
    r1_global: 6,
    rz: "9,6",
    rz_global: "9,6",
    t: 6,
    t_global: 6,
    width: 45,
    width_global: 45,
    thickness: 6,
    thickness_global: 6,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 3568,
    name: "ISA65x45x8",
    type: "Angle",
    designation: "ISA65x45x8",
    shape: "L",
    country_code: "Indian",
    ax: "8,17",
    ax_global: 817,
    b: 45,
    b_global: 45,
    d: 65,
    d_global: 65,
    r1: 6,
    r1_global: 6,
    rz: "9,53",
    rz_global: "9,53",
    t: 8,
    t_global: 8,
    width: 45,
    width_global: 45,
    thickness: 8,
    thickness_global: 8,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 3569,
    name: "ISA65x50x5",
    type: "Angle",
    designation: "ISA65x50x5",
    shape: "L",
    country_code: "Indian",
    ax: "5,54",
    ax_global: 554,
    b: 50,
    b_global: 50,
    d: 65,
    d_global: 65,
    r1: 6,
    r1_global: 6,
    rz: "10,7",
    rz_global: "10,7",
    t: 5,
    t_global: 5,
    width: 50,
    width_global: 50,
    thickness: 5,
    thickness_global: 5,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 3570,
    name: "ISA65x50x6",
    type: "Angle",
    designation: "ISA65x50x6",
    shape: "L",
    country_code: "Indian",
    ax: "6,58",
    ax_global: 658,
    b: 50,
    b_global: 50,
    d: 65,
    d_global: 65,
    r1: 6,
    r1_global: 6,
    rz: "10,6",
    rz_global: "10,6",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 3571,
    name: "ISA65x50x7",
    type: "Angle",
    designation: "ISA65x50x7",
    shape: "L",
    country_code: "Indian",
    ax: "7,6",
    ax_global: 760,
    b: 50,
    b_global: 50,
    d: 65,
    d_global: 65,
    r1: 6,
    r1_global: 6,
    rz: "10,6",
    rz_global: "10,6",
    t: 7,
    t_global: 7,
    width: 50,
    width_global: 50,
    thickness: 7,
    thickness_global: 7,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 3572,
    name: "ISA65x50x8",
    type: "Angle",
    designation: "ISA65x50x8",
    shape: "L",
    country_code: "Indian",
    ax: "8,6",
    ax_global: 860,
    b: 50,
    b_global: 50,
    d: 65,
    d_global: 65,
    r1: 6,
    r1_global: 6,
    rz: "10,5",
    rz_global: "10,5",
    t: 8,
    t_global: 8,
    width: 50,
    width_global: 50,
    thickness: 8,
    thickness_global: 8,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 3573,
    name: "ISA65x65x5",
    type: "Angle",
    designation: "ISA65x65x5",
    shape: "L",
    country_code: "Indian",
    ax: "6,25",
    ax_global: 625,
    b: 65,
    b_global: 65,
    d: 65,
    d_global: 65,
    r1: "6,5",
    r1_global: "6,5",
    rz: "12,7",
    rz_global: "12,7",
    t: 5,
    t_global: 5,
    width: 65,
    width_global: 65,
    thickness: 5,
    thickness_global: 5,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 3574,
    name: "ISA65x65x6",
    type: "Angle",
    designation: "ISA65x65x6",
    shape: "L",
    country_code: "Indian",
    ax: "7,44",
    ax_global: 744,
    b: 65,
    b_global: 65,
    d: 65,
    d_global: 65,
    r1: "6,5",
    r1_global: "6,5",
    rz: "12,7",
    rz_global: "12,7",
    t: 6,
    t_global: 6,
    width: 65,
    width_global: 65,
    thickness: 6,
    thickness_global: 6,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 3575,
    name: "ISA65x65x8",
    type: "Angle",
    designation: "ISA65x65x8",
    shape: "L",
    country_code: "Indian",
    ax: "9,76",
    ax_global: 976,
    b: 65,
    b_global: 65,
    d: 65,
    d_global: 65,
    r1: "6,5",
    r1_global: "6,5",
    rz: "12,6",
    rz_global: "12,6",
    t: 8,
    t_global: 8,
    width: 65,
    width_global: 65,
    thickness: 8,
    thickness_global: 8,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 3576,
    name: "ISA65x65x10",
    type: "Angle",
    designation: "ISA65x65x10",
    shape: "L",
    country_code: "Indian",
    ax: 12,
    ax_global: 1200,
    b: 65,
    b_global: 65,
    d: 65,
    d_global: 65,
    r1: "6,5",
    r1_global: "6,5",
    rz: "12,5",
    rz_global: "12,5",
    t: 10,
    t_global: 10,
    width: 65,
    width_global: 65,
    thickness: 10,
    thickness_global: 10,
    height: 65,
    height_global: 65,
  },
  {
    profile_section_id: 3577,
    name: "ISA70x45x5",
    type: "Angle",
    designation: "ISA70x45x5",
    shape: "L",
    country_code: "Indian",
    ax: "5,52",
    ax_global: 552,
    b: 45,
    b_global: 45,
    d: 70,
    d_global: 70,
    r1: "6,5",
    r1_global: "6,5",
    rz: "9,68",
    rz_global: "9,68",
    t: 5,
    t_global: 5,
    width: 45,
    width_global: 45,
    thickness: 5,
    thickness_global: 5,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3578,
    name: "ISA70x45x6",
    type: "Angle",
    designation: "ISA70x45x6",
    shape: "L",
    country_code: "Indian",
    ax: "6,56",
    ax_global: 656,
    b: 45,
    b_global: 45,
    d: 70,
    d_global: 70,
    r1: "6,5",
    r1_global: "6,5",
    rz: "9,64",
    rz_global: "9,64",
    t: 6,
    t_global: 6,
    width: 45,
    width_global: 45,
    thickness: 6,
    thickness_global: 6,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3579,
    name: "ISA70x45x8",
    type: "Angle",
    designation: "ISA70x45x8",
    shape: "L",
    country_code: "Indian",
    ax: "8,58",
    ax_global: 858,
    b: 45,
    b_global: 45,
    d: 70,
    d_global: 70,
    r1: "6,5",
    r1_global: "6,5",
    rz: "9,57",
    rz_global: "9,57",
    t: 8,
    t_global: 8,
    width: 45,
    width_global: 45,
    thickness: 8,
    thickness_global: 8,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3580,
    name: "ISA70x45x10",
    type: "Angle",
    designation: "ISA70x45x10",
    shape: "L",
    country_code: "Indian",
    ax: "10,5",
    ax_global: 1050,
    b: 45,
    b_global: 45,
    d: 70,
    d_global: 70,
    r1: "6,5",
    r1_global: "6,5",
    rz: "9,53",
    rz_global: "9,53",
    t: 10,
    t_global: 10,
    width: 45,
    width_global: 45,
    thickness: 10,
    thickness_global: 10,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3581,
    name: "ISA70x50x5",
    type: "Angle",
    designation: "ISA70x50x5",
    shape: "L",
    country_code: "Indian",
    ax: "5,79",
    ax_global: 579,
    b: 50,
    b_global: 50,
    d: 70,
    d_global: 70,
    r1: 6,
    r1_global: 6,
    rz: "10,8",
    rz_global: "10,8",
    t: 5,
    t_global: 5,
    width: 50,
    width_global: 50,
    thickness: 5,
    thickness_global: 5,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3582,
    name: "ISA70x50x6",
    type: "Angle",
    designation: "ISA70x50x6",
    shape: "L",
    country_code: "Indian",
    ax: "6,88",
    ax_global: 688,
    b: 50,
    b_global: 50,
    d: 70,
    d_global: 70,
    r1: 6,
    r1_global: 6,
    rz: "10,7",
    rz_global: "10,7",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3583,
    name: "ISA70x50x7",
    type: "Angle",
    designation: "ISA70x50x7",
    shape: "L",
    country_code: "Indian",
    ax: "7,95",
    ax_global: 795,
    b: 50,
    b_global: 50,
    d: 70,
    d_global: 70,
    r1: 6,
    r1_global: 6,
    rz: "10,7",
    rz_global: "10,7",
    t: 7,
    t_global: 7,
    width: 50,
    width_global: 50,
    thickness: 7,
    thickness_global: 7,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3584,
    name: "ISA70x50x8",
    type: "Angle",
    designation: "ISA70x50x8",
    shape: "L",
    country_code: "Indian",
    ax: 9,
    ax_global: 900,
    b: 50,
    b_global: 50,
    d: 70,
    d_global: 70,
    r1: 6,
    r1_global: 6,
    rz: "10,7",
    rz_global: "10,7",
    t: 8,
    t_global: 8,
    width: 50,
    width_global: 50,
    thickness: 8,
    thickness_global: 8,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3585,
    name: "ISA70x70x5",
    type: "Angle",
    designation: "ISA70x70x5",
    shape: "L",
    country_code: "Indian",
    ax: "6,77",
    ax_global: 677,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    r1: 7,
    r1_global: 7,
    rz: "13,8",
    rz_global: "13,8",
    t: 5,
    t_global: 5,
    width: 70,
    width_global: 70,
    thickness: 5,
    thickness_global: 5,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3586,
    name: "ISA70x70x6",
    type: "Angle",
    designation: "ISA70x70x6",
    shape: "L",
    country_code: "Indian",
    ax: "8,06",
    ax_global: 806,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    r1: 7,
    r1_global: 7,
    rz: "13,7",
    rz_global: "13,7",
    t: 6,
    t_global: 6,
    width: 70,
    width_global: 70,
    thickness: 6,
    thickness_global: 6,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3587,
    name: "ISA70x70x7",
    type: "Angle",
    designation: "ISA70x70x7",
    shape: "L",
    country_code: "Indian",
    ax: "9,4",
    ax_global: 940,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    r1: 9,
    r1_global: 9,
    rz: "13,6",
    rz_global: "13,6",
    t: 7,
    t_global: 7,
    width: 70,
    width_global: 70,
    thickness: 7,
    thickness_global: 7,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3588,
    name: "ISA70x70x8",
    type: "Angle",
    designation: "ISA70x70x8",
    shape: "L",
    country_code: "Indian",
    ax: "10,6",
    ax_global: 1060,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    r1: 7,
    r1_global: 7,
    rz: "13,6",
    rz_global: "13,6",
    t: 8,
    t_global: 8,
    width: 70,
    width_global: 70,
    thickness: 8,
    thickness_global: 8,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3589,
    name: "ISA70x70x10",
    type: "Angle",
    designation: "ISA70x70x10",
    shape: "L",
    country_code: "Indian",
    ax: 13,
    ax_global: 1300,
    b: 70,
    b_global: 70,
    d: 70,
    d_global: 70,
    r1: 7,
    r1_global: 7,
    rz: "13,5",
    rz_global: "13,5",
    t: 10,
    t_global: 10,
    width: 70,
    width_global: 70,
    thickness: 10,
    thickness_global: 10,
    height: 70,
    height_global: 70,
  },
  {
    profile_section_id: 3590,
    name: "ISA75x50x5",
    type: "Angle",
    designation: "ISA75x50x5",
    shape: "L",
    country_code: "Indian",
    ax: "6,02",
    ax_global: 602,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    r1: "6,5",
    r1_global: "6,5",
    rz: "10,8",
    rz_global: "10,8",
    t: 5,
    t_global: 5,
    width: 50,
    width_global: 50,
    thickness: 5,
    thickness_global: 5,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 3591,
    name: "ISA75x50x6",
    type: "Angle",
    designation: "ISA75x50x6",
    shape: "L",
    country_code: "Indian",
    ax: "7,16",
    ax_global: 716,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    r1: "6,5",
    r1_global: "6,5",
    rz: "10,8",
    rz_global: "10,8",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 3592,
    name: "ISA75x50x7",
    type: "Angle",
    designation: "ISA75x50x7",
    shape: "L",
    country_code: "Indian",
    ax: "8,31",
    ax_global: 831,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    r1: 7,
    r1_global: 7,
    rz: "10,7",
    rz_global: "10,7",
    t: 7,
    t_global: 7,
    width: 50,
    width_global: 50,
    thickness: 7,
    thickness_global: 7,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 3593,
    name: "ISA75x50x8",
    type: "Angle",
    designation: "ISA75x50x8",
    shape: "L",
    country_code: "Indian",
    ax: "9,38",
    ax_global: 938,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    r1: "6,5",
    r1_global: "6,5",
    rz: "10,7",
    rz_global: "10,7",
    t: 8,
    t_global: 8,
    width: 50,
    width_global: 50,
    thickness: 8,
    thickness_global: 8,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 3594,
    name: "ISA75x50x10",
    type: "Angle",
    designation: "ISA75x50x10",
    shape: "L",
    country_code: "Indian",
    ax: "11,5",
    ax_global: 1150,
    b: 50,
    b_global: 50,
    d: 75,
    d_global: 75,
    r1: "6,5",
    r1_global: "6,5",
    rz: "10,6",
    rz_global: "10,6",
    t: 10,
    t_global: 10,
    width: 50,
    width_global: 50,
    thickness: 10,
    thickness_global: 10,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 3595,
    name: "ISA75x75x5",
    type: "Angle",
    designation: "ISA75x75x5",
    shape: "L",
    country_code: "Indian",
    ax: "7,27",
    ax_global: 727,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    r1: 7,
    r1_global: 7,
    rz: "14,8",
    rz_global: "14,8",
    t: 5,
    t_global: 5,
    width: 75,
    width_global: 75,
    thickness: 5,
    thickness_global: 5,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 3596,
    name: "ISA75x75x6",
    type: "Angle",
    designation: "ISA75x75x6",
    shape: "L",
    country_code: "Indian",
    ax: "8,66",
    ax_global: 866,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    r1: 7,
    r1_global: 7,
    rz: "14,7",
    rz_global: "14,7",
    t: 6,
    t_global: 6,
    width: 75,
    width_global: 75,
    thickness: 6,
    thickness_global: 6,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 3597,
    name: "ISA75x75x8",
    type: "Angle",
    designation: "ISA75x75x8",
    shape: "L",
    country_code: "Indian",
    ax: "11,4",
    ax_global: 1140,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    r1: 7,
    r1_global: 7,
    rz: "14,6",
    rz_global: "14,6",
    t: 8,
    t_global: 8,
    width: 75,
    width_global: 75,
    thickness: 8,
    thickness_global: 8,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 3598,
    name: "ISA75x75x10",
    type: "Angle",
    designation: "ISA75x75x10",
    shape: "L",
    country_code: "Indian",
    ax: 14,
    ax_global: 1400,
    b: 75,
    b_global: 75,
    d: 75,
    d_global: 75,
    r1: 7,
    r1_global: 7,
    rz: "14,5",
    rz_global: "14,5",
    t: 10,
    t_global: 10,
    width: 75,
    width_global: 75,
    thickness: 10,
    thickness_global: 10,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 3599,
    name: "ISA80x40x5",
    type: "Angle",
    designation: "ISA80x40x5",
    shape: "L",
    country_code: "Indian",
    ax: "5,8",
    ax_global: 580,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    r1: 7,
    r1_global: 7,
    rz: "8,5",
    rz_global: "8,5",
    t: 5,
    t_global: 5,
    width: 40,
    width_global: 40,
    thickness: 5,
    thickness_global: 5,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3600,
    name: "ISA80x40x6",
    type: "Angle",
    designation: "ISA80x40x6",
    shape: "L",
    country_code: "Indian",
    ax: "6,89",
    ax_global: 689,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    r1: 7,
    r1_global: 7,
    rz: "8,45",
    rz_global: "8,45",
    t: 6,
    t_global: 6,
    width: 40,
    width_global: 40,
    thickness: 6,
    thickness_global: 6,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3601,
    name: "ISA80x40x7",
    type: "Angle",
    designation: "ISA80x40x7",
    shape: "L",
    country_code: "Indian",
    ax: "7,96",
    ax_global: 796,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    r1: 7,
    r1_global: 7,
    rz: "8,42",
    rz_global: "8,42",
    t: 7,
    t_global: 7,
    width: 40,
    width_global: 40,
    thickness: 7,
    thickness_global: 7,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3602,
    name: "ISA80x40x8",
    type: "Angle",
    designation: "ISA80x40x8",
    shape: "L",
    country_code: "Indian",
    ax: "9,01",
    ax_global: 901,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    r1: 7,
    r1_global: 7,
    rz: "8,38",
    rz_global: "8,38",
    t: 8,
    t_global: 8,
    width: 40,
    width_global: 40,
    thickness: 8,
    thickness_global: 8,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3603,
    name: "ISA80x50x5",
    type: "Angle",
    designation: "ISA80x50x5",
    shape: "L",
    country_code: "Indian",
    ax: "6,27",
    ax_global: 627,
    b: 50,
    b_global: 50,
    d: 80,
    d_global: 80,
    r1: 7,
    r1_global: 7,
    rz: "10,8",
    rz_global: "10,8",
    t: 5,
    t_global: 5,
    width: 50,
    width_global: 50,
    thickness: 5,
    thickness_global: 5,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3604,
    name: "ISA80x50x6",
    type: "Angle",
    designation: "ISA80x50x6",
    shape: "L",
    country_code: "Indian",
    ax: "7,46",
    ax_global: 746,
    b: 50,
    b_global: 50,
    d: 80,
    d_global: 80,
    r1: 7,
    r1_global: 7,
    rz: "10,7",
    rz_global: "10,7",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3605,
    name: "ISA80x50x8",
    type: "Angle",
    designation: "ISA80x50x8",
    shape: "L",
    country_code: "Indian",
    ax: "9,78",
    ax_global: 978,
    b: 50,
    b_global: 50,
    d: 80,
    d_global: 80,
    r1: 7,
    r1_global: 7,
    rz: "10,7",
    rz_global: "10,7",
    t: 8,
    t_global: 8,
    width: 50,
    width_global: 50,
    thickness: 8,
    thickness_global: 8,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3606,
    name: "ISA80x50x10",
    type: "Angle",
    designation: "ISA80x50x10",
    shape: "L",
    country_code: "Indian",
    ax: 12,
    ax_global: 1200,
    b: 50,
    b_global: 50,
    d: 80,
    d_global: 80,
    r1: 7,
    r1_global: 7,
    rz: "10,6",
    rz_global: "10,6",
    t: 10,
    t_global: 10,
    width: 50,
    width_global: 50,
    thickness: 10,
    thickness_global: 10,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3607,
    name: "ISA80x60x6",
    type: "Angle",
    designation: "ISA80x60x6",
    shape: "L",
    country_code: "Indian",
    ax: "8,11",
    ax_global: 811,
    b: 60,
    b_global: 60,
    d: 80,
    d_global: 80,
    r1: 8,
    r1_global: 8,
    rz: "12,9",
    rz_global: "12,9",
    t: 6,
    t_global: 6,
    width: 60,
    width_global: 60,
    thickness: 6,
    thickness_global: 6,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3608,
    name: "ISA80x60x7",
    type: "Angle",
    designation: "ISA80x60x7",
    shape: "L",
    country_code: "Indian",
    ax: "9,38",
    ax_global: 938,
    b: 60,
    b_global: 60,
    d: 80,
    d_global: 80,
    r1: 8,
    r1_global: 8,
    rz: "12,8",
    rz_global: "12,8",
    t: 7,
    t_global: 7,
    width: 60,
    width_global: 60,
    thickness: 7,
    thickness_global: 7,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3609,
    name: "ISA80x60x8",
    type: "Angle",
    designation: "ISA80x60x8",
    shape: "L",
    country_code: "Indian",
    ax: "10,6",
    ax_global: 1060,
    b: 60,
    b_global: 60,
    d: 80,
    d_global: 80,
    r1: 8,
    r1_global: 8,
    rz: "12,7",
    rz_global: "12,7",
    t: 8,
    t_global: 8,
    width: 60,
    width_global: 60,
    thickness: 8,
    thickness_global: 8,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3610,
    name: "ISA80x80x6",
    type: "Angle",
    designation: "ISA80x80x6",
    shape: "L",
    country_code: "Indian",
    ax: "9,29",
    ax_global: 929,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: 8,
    r1_global: 8,
    rz: "15,7",
    rz_global: "15,7",
    t: 6,
    t_global: 6,
    width: 80,
    width_global: 80,
    thickness: 6,
    thickness_global: 6,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3611,
    name: "ISA80x80x8",
    type: "Angle",
    designation: "ISA80x80x8",
    shape: "L",
    country_code: "Indian",
    ax: "12,2",
    ax_global: 1220,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: 8,
    r1_global: 8,
    rz: "15,6",
    rz_global: "15,6",
    t: 8,
    t_global: 8,
    width: 80,
    width_global: 80,
    thickness: 8,
    thickness_global: 8,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3612,
    name: "ISA80x80x10",
    type: "Angle",
    designation: "ISA80x80x10",
    shape: "L",
    country_code: "Indian",
    ax: "15,1",
    ax_global: 1510,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: 8,
    r1_global: 8,
    rz: "15,5",
    rz_global: "15,5",
    t: 10,
    t_global: 10,
    width: 80,
    width_global: 80,
    thickness: 10,
    thickness_global: 10,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3613,
    name: "ISA80x80x12",
    type: "Angle",
    designation: "ISA80x80x12",
    shape: "L",
    country_code: "Indian",
    ax: "17,8",
    ax_global: 1780,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    r1: 8,
    r1_global: 8,
    rz: "15,5",
    rz_global: "15,5",
    t: 12,
    t_global: 12,
    width: 80,
    width_global: 80,
    thickness: 12,
    thickness_global: 12,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 3614,
    name: "ISA90x60x6",
    type: "Angle",
    designation: "ISA90x60x6",
    shape: "L",
    country_code: "Indian",
    ax: "8,66",
    ax_global: 866,
    b: 60,
    b_global: 60,
    d: 90,
    d_global: 90,
    r1: 7,
    r1_global: 7,
    rz: 13,
    rz_global: 13,
    t: 6,
    t_global: 6,
    width: 60,
    width_global: 60,
    thickness: 6,
    thickness_global: 6,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 3615,
    name: "ISA90x60x8",
    type: "Angle",
    designation: "ISA90x60x8",
    shape: "L",
    country_code: "Indian",
    ax: "11,4",
    ax_global: 1140,
    b: 60,
    b_global: 60,
    d: 90,
    d_global: 90,
    r1: 7,
    r1_global: 7,
    rz: "12,9",
    rz_global: "12,9",
    t: 8,
    t_global: 8,
    width: 60,
    width_global: 60,
    thickness: 8,
    thickness_global: 8,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 3616,
    name: "ISA90x60x10",
    type: "Angle",
    designation: "ISA90x60x10",
    shape: "L",
    country_code: "Indian",
    ax: 14,
    ax_global: 1400,
    b: 60,
    b_global: 60,
    d: 90,
    d_global: 90,
    r1: 7,
    r1_global: 7,
    rz: "12,8",
    rz_global: "12,8",
    t: 10,
    t_global: 10,
    width: 60,
    width_global: 60,
    thickness: 10,
    thickness_global: 10,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 3617,
    name: "ISA90x60x12",
    type: "Angle",
    designation: "ISA90x60x12",
    shape: "L",
    country_code: "Indian",
    ax: "16,6",
    ax_global: 1660,
    b: 60,
    b_global: 60,
    d: 90,
    d_global: 90,
    r1: 7,
    r1_global: 7,
    rz: "12,8",
    rz_global: "12,8",
    t: 12,
    t_global: 12,
    width: 60,
    width_global: 60,
    thickness: 12,
    thickness_global: 12,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 3618,
    name: "ISA90x65x6",
    type: "Angle",
    designation: "ISA90x65x6",
    shape: "L",
    country_code: "Indian",
    ax: "9,01",
    ax_global: 901,
    b: 65,
    b_global: 65,
    d: 90,
    d_global: 90,
    r1: 8,
    r1_global: 8,
    rz: "14,1",
    rz_global: "14,1",
    t: 6,
    t_global: 6,
    width: 65,
    width_global: 65,
    thickness: 6,
    thickness_global: 6,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 3619,
    name: "ISA90x65x7",
    type: "Angle",
    designation: "ISA90x65x7",
    shape: "L",
    country_code: "Indian",
    ax: "10,4",
    ax_global: 1040,
    b: 65,
    b_global: 65,
    d: 90,
    d_global: 90,
    r1: 8,
    r1_global: 8,
    rz: 14,
    rz_global: 14,
    t: 7,
    t_global: 7,
    width: 65,
    width_global: 65,
    thickness: 7,
    thickness_global: 7,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 3620,
    name: "ISA90x65x8",
    type: "Angle",
    designation: "ISA90x65x8",
    shape: "L",
    country_code: "Indian",
    ax: "11,8",
    ax_global: 1180,
    b: 65,
    b_global: 65,
    d: 90,
    d_global: 90,
    r1: 8,
    r1_global: 8,
    rz: "13,9",
    rz_global: "13,9",
    t: 8,
    t_global: 8,
    width: 65,
    width_global: 65,
    thickness: 8,
    thickness_global: 8,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 3621,
    name: "ISA90x65x10",
    type: "Angle",
    designation: "ISA90x65x10",
    shape: "L",
    country_code: "Indian",
    ax: "14,6",
    ax_global: 1460,
    b: 65,
    b_global: 65,
    d: 90,
    d_global: 90,
    r1: 8,
    r1_global: 8,
    rz: "13,8",
    rz_global: "13,8",
    t: 10,
    t_global: 10,
    width: 65,
    width_global: 65,
    thickness: 10,
    thickness_global: 10,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 3622,
    name: "ISA90x90x6",
    type: "Angle",
    designation: "ISA90x90x6",
    shape: "L",
    country_code: "Indian",
    ax: "10,5",
    ax_global: 1050,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "8,5",
    r1_global: "8,5",
    rz: "17,8",
    rz_global: "17,8",
    t: 6,
    t_global: 6,
    width: 90,
    width_global: 90,
    thickness: 6,
    thickness_global: 6,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 3623,
    name: "ISA90x90x8",
    type: "Angle",
    designation: "ISA90x90x8",
    shape: "L",
    country_code: "Indian",
    ax: "13,8",
    ax_global: 1380,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "8,5",
    r1_global: "8,5",
    rz: "17,7",
    rz_global: "17,7",
    t: 8,
    t_global: 8,
    width: 90,
    width_global: 90,
    thickness: 8,
    thickness_global: 8,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 3624,
    name: "ISA90x90x10",
    type: "Angle",
    designation: "ISA90x90x10",
    shape: "L",
    country_code: "Indian",
    ax: "17,1",
    ax_global: 1710,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "8,5",
    r1_global: "8,5",
    rz: "17,6",
    rz_global: "17,6",
    t: 10,
    t_global: 10,
    width: 90,
    width_global: 90,
    thickness: 10,
    thickness_global: 10,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 3625,
    name: "ISA90x90x12",
    type: "Angle",
    designation: "ISA90x90x12",
    shape: "L",
    country_code: "Indian",
    ax: "20,2",
    ax_global: 2020,
    b: 90,
    b_global: 90,
    d: 90,
    d_global: 90,
    r1: "8,5",
    r1_global: "8,5",
    rz: "17,5",
    rz_global: "17,5",
    t: 12,
    t_global: 12,
    width: 90,
    width_global: 90,
    thickness: 12,
    thickness_global: 12,
    height: 90,
    height_global: 90,
  },
  {
    profile_section_id: 3626,
    name: "ISA100x50x6",
    type: "Angle",
    designation: "ISA100x50x6",
    shape: "L",
    country_code: "Indian",
    ax: "8,73",
    ax_global: 873,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    r1: 9,
    r1_global: 9,
    rz: "10,6",
    rz_global: "10,6",
    t: 6,
    t_global: 6,
    width: 50,
    width_global: 50,
    thickness: 6,
    thickness_global: 6,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3627,
    name: "ISA100x50x7",
    type: "Angle",
    designation: "ISA100x50x7",
    shape: "L",
    country_code: "Indian",
    ax: "10,1",
    ax_global: 1010,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    r1: 9,
    r1_global: 9,
    rz: "10,6",
    rz_global: "10,6",
    t: 7,
    t_global: 7,
    width: 50,
    width_global: 50,
    thickness: 7,
    thickness_global: 7,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3628,
    name: "ISA100x50x8",
    type: "Angle",
    designation: "ISA100x50x8",
    shape: "L",
    country_code: "Indian",
    ax: "11,4",
    ax_global: 1140,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    r1: 9,
    r1_global: 9,
    rz: "10,5",
    rz_global: "10,5",
    t: 8,
    t_global: 8,
    width: 50,
    width_global: 50,
    thickness: 8,
    thickness_global: 8,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3629,
    name: "ISA100x50x10",
    type: "Angle",
    designation: "ISA100x50x10",
    shape: "L",
    country_code: "Indian",
    ax: "14,1",
    ax_global: 1410,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    r1: 9,
    r1_global: 9,
    rz: "10,5",
    rz_global: "10,5",
    t: 10,
    t_global: 10,
    width: 50,
    width_global: 50,
    thickness: 10,
    thickness_global: 10,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3630,
    name: "ISA100x65x6",
    type: "Angle",
    designation: "ISA100x65x6",
    shape: "L",
    country_code: "Indian",
    ax: "9,57",
    ax_global: 957,
    b: 65,
    b_global: 65,
    d: 100,
    d_global: 100,
    r1: 8,
    r1_global: 8,
    rz: "14,1",
    rz_global: "14,1",
    t: 6,
    t_global: 6,
    width: 65,
    width_global: 65,
    thickness: 6,
    thickness_global: 6,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3631,
    name: "ISA100x65x7",
    type: "Angle",
    designation: "ISA100x65x7",
    shape: "L",
    country_code: "Indian",
    ax: "11,2",
    ax_global: 1120,
    b: 65,
    b_global: 65,
    d: 100,
    d_global: 100,
    r1: 10,
    r1_global: 10,
    rz: "14,1",
    rz_global: "14,1",
    t: 7,
    t_global: 7,
    width: 65,
    width_global: 65,
    thickness: 7,
    thickness_global: 7,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3632,
    name: "ISA100x65x8",
    type: "Angle",
    designation: "ISA100x65x8",
    shape: "L",
    country_code: "Indian",
    ax: "12,6",
    ax_global: 1260,
    b: 65,
    b_global: 65,
    d: 100,
    d_global: 100,
    r1: 8,
    r1_global: 8,
    rz: 14,
    rz_global: 14,
    t: 8,
    t_global: 8,
    width: 65,
    width_global: 65,
    thickness: 8,
    thickness_global: 8,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3633,
    name: "ISA100x65x10",
    type: "Angle",
    designation: "ISA100x65x10",
    shape: "L",
    country_code: "Indian",
    ax: "15,5",
    ax_global: 1550,
    b: 65,
    b_global: 65,
    d: 100,
    d_global: 100,
    r1: 8,
    r1_global: 8,
    rz: "13,9",
    rz_global: "13,9",
    t: 10,
    t_global: 10,
    width: 65,
    width_global: 65,
    thickness: 10,
    thickness_global: 10,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3634,
    name: "ISA100x75x6",
    type: "Angle",
    designation: "ISA100x75x6",
    shape: "L",
    country_code: "Indian",
    ax: "10,1",
    ax_global: 1010,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    r1: "8,5",
    r1_global: "8,5",
    rz: "16,1",
    rz_global: "16,1",
    t: 6,
    t_global: 6,
    width: 75,
    width_global: 75,
    thickness: 6,
    thickness_global: 6,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3635,
    name: "ISA100x75x8",
    type: "Angle",
    designation: "ISA100x75x8",
    shape: "L",
    country_code: "Indian",
    ax: "13,4",
    ax_global: 1340,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    r1: "8,5",
    r1_global: "8,5",
    rz: 16,
    rz_global: 16,
    t: 8,
    t_global: 8,
    width: 75,
    width_global: 75,
    thickness: 8,
    thickness_global: 8,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3636,
    name: "ISA100x75x10",
    type: "Angle",
    designation: "ISA100x75x10",
    shape: "L",
    country_code: "Indian",
    ax: "16,5",
    ax_global: 1650,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    r1: "8,5",
    r1_global: "8,5",
    rz: "15,9",
    rz_global: "15,9",
    t: 10,
    t_global: 10,
    width: 75,
    width_global: 75,
    thickness: 10,
    thickness_global: 10,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3637,
    name: "ISA100x75x12",
    type: "Angle",
    designation: "ISA100x75x12",
    shape: "L",
    country_code: "Indian",
    ax: "19,6",
    ax_global: 1960,
    b: 75,
    b_global: 75,
    d: 100,
    d_global: 100,
    r1: "8,5",
    r1_global: "8,5",
    rz: "15,8",
    rz_global: "15,8",
    t: 12,
    t_global: 12,
    width: 75,
    width_global: 75,
    thickness: 12,
    thickness_global: 12,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3638,
    name: "ISA100x100x6",
    type: "Angle",
    designation: "ISA100x100x6",
    shape: "L",
    country_code: "Indian",
    ax: "11,7",
    ax_global: 1170,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "8,5",
    r1_global: "8,5",
    rz: "19,8",
    rz_global: "19,8",
    t: 6,
    t_global: 6,
    width: 100,
    width_global: 100,
    thickness: 6,
    thickness_global: 6,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3639,
    name: "ISA100x100x7",
    type: "Angle",
    designation: "ISA100x100x7",
    shape: "L",
    country_code: "Indian",
    ax: "13,7",
    ax_global: 1370,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: 12,
    r1_global: 12,
    rz: "19,8",
    rz_global: "19,8",
    t: 7,
    t_global: 7,
    width: 100,
    width_global: 100,
    thickness: 7,
    thickness_global: 7,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3640,
    name: "ISA100x100x8",
    type: "Angle",
    designation: "ISA100x100x8",
    shape: "L",
    country_code: "Indian",
    ax: "15,4",
    ax_global: 1540,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "8,5",
    r1_global: "8,5",
    rz: "19,7",
    rz_global: "19,7",
    t: 8,
    t_global: 8,
    width: 100,
    width_global: 100,
    thickness: 8,
    thickness_global: 8,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3641,
    name: "ISA100x100x10",
    type: "Angle",
    designation: "ISA100x100x10",
    shape: "L",
    country_code: "Indian",
    ax: "19,1",
    ax_global: 1910,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "8,5",
    r1_global: "8,5",
    rz: "19,6",
    rz_global: "19,6",
    t: 10,
    t_global: 10,
    width: 100,
    width_global: 100,
    thickness: 10,
    thickness_global: 10,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3642,
    name: "ISA100x100x12",
    type: "Angle",
    designation: "ISA100x100x12",
    shape: "L",
    country_code: "Indian",
    ax: "22,6",
    ax_global: 2260,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: "8,5",
    r1_global: "8,5",
    rz: "19,5",
    rz_global: "19,5",
    t: 12,
    t_global: 12,
    width: 100,
    width_global: 100,
    thickness: 12,
    thickness_global: 12,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3643,
    name: "ISA100x100x15",
    type: "Angle",
    designation: "ISA100x100x15",
    shape: "L",
    country_code: "Indian",
    ax: "27,9",
    ax_global: 2790,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    r1: 12,
    r1_global: 12,
    rz: "19,3",
    rz_global: "19,3",
    t: 15,
    t_global: 15,
    width: 100,
    width_global: 100,
    thickness: 15,
    thickness_global: 15,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3644,
    name: "ISA110x110x8",
    type: "Angle",
    designation: "ISA110x110x8",
    shape: "L",
    country_code: "Indian",
    ax: "17,1",
    ax_global: 1710,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    r1: 10,
    r1_global: 10,
    rz: "21,7",
    rz_global: "21,7",
    t: 8,
    t_global: 8,
    width: 110,
    width_global: 110,
    thickness: 8,
    thickness_global: 8,
    height: 110,
    height_global: 110,
  },
  {
    profile_section_id: 3645,
    name: "ISA110x110x10",
    type: "Angle",
    designation: "ISA110x110x10",
    shape: "L",
    country_code: "Indian",
    ax: "21,1",
    ax_global: 2110,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    r1: 10,
    r1_global: 10,
    rz: "21,6",
    rz_global: "21,6",
    t: 10,
    t_global: 10,
    width: 110,
    width_global: 110,
    thickness: 10,
    thickness_global: 10,
    height: 110,
    height_global: 110,
  },
  {
    profile_section_id: 3646,
    name: "ISA110x110x12",
    type: "Angle",
    designation: "ISA110x110x12",
    shape: "L",
    country_code: "Indian",
    ax: "25,1",
    ax_global: 2510,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    r1: 10,
    r1_global: 10,
    rz: "21,5",
    rz_global: "21,5",
    t: 12,
    t_global: 12,
    width: 110,
    width_global: 110,
    thickness: 12,
    thickness_global: 12,
    height: 110,
    height_global: 110,
  },
  {
    profile_section_id: 3647,
    name: "ISA110x110x16",
    type: "Angle",
    designation: "ISA110x110x16",
    shape: "L",
    country_code: "Indian",
    ax: "32,8",
    ax_global: 3280,
    b: 110,
    b_global: 110,
    d: 110,
    d_global: 110,
    r1: 10,
    r1_global: 10,
    rz: "21,4",
    rz_global: "21,4",
    t: 16,
    t_global: 16,
    width: 110,
    width_global: 110,
    thickness: 16,
    thickness_global: 16,
    height: 110,
    height_global: 110,
  },
  {
    profile_section_id: 3648,
    name: "ISA120x80x8",
    type: "Angle",
    designation: "ISA120x80x8",
    shape: "L",
    country_code: "Indian",
    ax: "15,5",
    ax_global: 1550,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    r1: 11,
    r1_global: 11,
    rz: "17,4",
    rz_global: "17,4",
    t: 8,
    t_global: 8,
    width: 80,
    width_global: 80,
    thickness: 8,
    thickness_global: 8,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 3649,
    name: "ISA120x80x10",
    type: "Angle",
    designation: "ISA120x80x10",
    shape: "L",
    country_code: "Indian",
    ax: "19,2",
    ax_global: 1920,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    r1: 11,
    r1_global: 11,
    rz: "17,3",
    rz_global: "17,3",
    t: 10,
    t_global: 10,
    width: 80,
    width_global: 80,
    thickness: 10,
    thickness_global: 10,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 3650,
    name: "ISA120x80x12",
    type: "Angle",
    designation: "ISA120x80x12",
    shape: "L",
    country_code: "Indian",
    ax: "22,7",
    ax_global: 2270,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    r1: 11,
    r1_global: 11,
    rz: "17,2",
    rz_global: "17,2",
    t: 12,
    t_global: 12,
    width: 80,
    width_global: 80,
    thickness: 12,
    thickness_global: 12,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 3651,
    name: "ISA120x120x8",
    type: "Angle",
    designation: "ISA120x120x8",
    shape: "L",
    country_code: "Indian",
    ax: "18,8",
    ax_global: 1880,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    r1: 13,
    r1_global: 13,
    rz: "23,8",
    rz_global: "23,8",
    t: 8,
    t_global: 8,
    width: 120,
    width_global: 120,
    thickness: 8,
    thickness_global: 8,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 3652,
    name: "ISA120x120x10",
    type: "Angle",
    designation: "ISA120x120x10",
    shape: "L",
    country_code: "Indian",
    ax: "23,3",
    ax_global: 2330,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    r1: 13,
    r1_global: 13,
    rz: "23,7",
    rz_global: "23,7",
    t: 10,
    t_global: 10,
    width: 120,
    width_global: 120,
    thickness: 10,
    thickness_global: 10,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 3653,
    name: "ISA120x120x12",
    type: "Angle",
    designation: "ISA120x120x12",
    shape: "L",
    country_code: "Indian",
    ax: "27,6",
    ax_global: 2760,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    r1: 13,
    r1_global: 13,
    rz: "23,5",
    rz_global: "23,5",
    t: 12,
    t_global: 12,
    width: 120,
    width_global: 120,
    thickness: 12,
    thickness_global: 12,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 3654,
    name: "ISA120x120x15",
    type: "Angle",
    designation: "ISA120x120x15",
    shape: "L",
    country_code: "Indian",
    ax: 34,
    ax_global: 3400,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    r1: 13,
    r1_global: 13,
    rz: "23,4",
    rz_global: "23,4",
    t: 15,
    t_global: 15,
    width: 120,
    width_global: 120,
    thickness: 15,
    thickness_global: 15,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 3655,
    name: "ISA125x75x6",
    type: "Angle",
    designation: "ISA125x75x6",
    shape: "L",
    country_code: "Indian",
    ax: "11,6",
    ax_global: 1160,
    b: 75,
    b_global: 75,
    d: 125,
    d_global: 125,
    r1: "8,5",
    r1_global: "8,5",
    rz: "16,3",
    rz_global: "16,3",
    t: 6,
    t_global: 6,
    width: 75,
    width_global: 75,
    thickness: 6,
    thickness_global: 6,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3656,
    name: "ISA125x75x8",
    type: "Angle",
    designation: "ISA125x75x8",
    shape: "L",
    country_code: "Indian",
    ax: "15,4",
    ax_global: 1540,
    b: 75,
    b_global: 75,
    d: 125,
    d_global: 125,
    r1: "8,5",
    r1_global: "8,5",
    rz: "16,2",
    rz_global: "16,2",
    t: 8,
    t_global: 8,
    width: 75,
    width_global: 75,
    thickness: 8,
    thickness_global: 8,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3657,
    name: "ISA125x75x10",
    type: "Angle",
    designation: "ISA125x75x10",
    shape: "L",
    country_code: "Indian",
    ax: 19,
    ax_global: 1900,
    b: 75,
    b_global: 75,
    d: 125,
    d_global: 125,
    r1: "8,5",
    r1_global: "8,5",
    rz: "16,1",
    rz_global: "16,1",
    t: 10,
    t_global: 10,
    width: 75,
    width_global: 75,
    thickness: 10,
    thickness_global: 10,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3658,
    name: "ISA125x75x12",
    type: "Angle",
    designation: "ISA125x75x12",
    shape: "L",
    country_code: "Indian",
    ax: "22,7",
    ax_global: 2270,
    b: 75,
    b_global: 75,
    d: 125,
    d_global: 125,
    r1: 11,
    r1_global: 11,
    rz: "16,1",
    rz_global: "16,1",
    t: 12,
    t_global: 12,
    width: 75,
    width_global: 75,
    thickness: 12,
    thickness_global: 12,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3659,
    name: "ISA125x95x6",
    type: "Angle",
    designation: "ISA125x95x6",
    shape: "L",
    country_code: "Indian",
    ax: "12,9",
    ax_global: 1290,
    b: 95,
    b_global: 95,
    d: 125,
    d_global: 125,
    r1: 9,
    r1_global: 9,
    rz: "20,6",
    rz_global: "20,6",
    t: 6,
    t_global: 6,
    width: 95,
    width_global: 95,
    thickness: 6,
    thickness_global: 6,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3660,
    name: "ISA125x95x8",
    type: "Angle",
    designation: "ISA125x95x8",
    shape: "L",
    country_code: "Indian",
    ax: 17,
    ax_global: 1700,
    b: 95,
    b_global: 95,
    d: 125,
    d_global: 125,
    r1: 9,
    r1_global: 9,
    rz: "20,5",
    rz_global: "20,5",
    t: 8,
    t_global: 8,
    width: 95,
    width_global: 95,
    thickness: 8,
    thickness_global: 8,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3661,
    name: "ISA125x95x10",
    type: "Angle",
    designation: "ISA125x95x10",
    shape: "L",
    country_code: "Indian",
    ax: "21,1",
    ax_global: 2110,
    b: 95,
    b_global: 95,
    d: 125,
    d_global: 125,
    r1: 9,
    r1_global: 9,
    rz: "20,4",
    rz_global: "20,4",
    t: 10,
    t_global: 10,
    width: 95,
    width_global: 95,
    thickness: 10,
    thickness_global: 10,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3662,
    name: "ISA125x95x12",
    type: "Angle",
    designation: "ISA125x95x12",
    shape: "L",
    country_code: "Indian",
    ax: 25,
    ax_global: 2500,
    b: 95,
    b_global: 95,
    d: 125,
    d_global: 125,
    r1: 9,
    r1_global: 9,
    rz: "20,2",
    rz_global: "20,2",
    t: 12,
    t_global: 12,
    width: 95,
    width_global: 95,
    thickness: 12,
    thickness_global: 12,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3663,
    name: "ISA130x130x8",
    type: "Angle",
    designation: "ISA130x130x8",
    shape: "L",
    country_code: "Indian",
    ax: "20,3",
    ax_global: 2030,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    r1: 10,
    r1_global: 10,
    rz: "25,8",
    rz_global: "25,8",
    t: 8,
    t_global: 8,
    width: 130,
    width_global: 130,
    thickness: 8,
    thickness_global: 8,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 3664,
    name: "ISA130x130x9",
    type: "Angle",
    designation: "ISA130x130x9",
    shape: "L",
    country_code: "Indian",
    ax: "22,9",
    ax_global: 2290,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    r1: 13,
    r1_global: 13,
    rz: "25,8",
    rz_global: "25,8",
    t: 9,
    t_global: 9,
    width: 130,
    width_global: 130,
    thickness: 9,
    thickness_global: 9,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 3665,
    name: "ISA130x130x10",
    type: "Angle",
    designation: "ISA130x130x10",
    shape: "L",
    country_code: "Indian",
    ax: "25,1",
    ax_global: 2510,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    r1: 10,
    r1_global: 10,
    rz: "25,7",
    rz_global: "25,7",
    t: 10,
    t_global: 10,
    width: 130,
    width_global: 130,
    thickness: 10,
    thickness_global: 10,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 3666,
    name: "ISA130x130x12",
    type: "Angle",
    designation: "ISA130x130x12",
    shape: "L",
    country_code: "Indian",
    ax: "29,9",
    ax_global: 2990,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    r1: 10,
    r1_global: 10,
    rz: "25,6",
    rz_global: "25,6",
    t: 12,
    t_global: 12,
    width: 130,
    width_global: 130,
    thickness: 12,
    thickness_global: 12,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 3667,
    name: "ISA130x130x16",
    type: "Angle",
    designation: "ISA130x130x16",
    shape: "L",
    country_code: "Indian",
    ax: "39,2",
    ax_global: 3920,
    b: 130,
    b_global: 130,
    d: 130,
    d_global: 130,
    r1: 10,
    r1_global: 10,
    rz: "25,4",
    rz_global: "25,4",
    t: 16,
    t_global: 16,
    width: 130,
    width_global: 130,
    thickness: 16,
    thickness_global: 16,
    height: 130,
    height_global: 130,
  },
  {
    profile_section_id: 3668,
    name: "ISA135x65x8",
    type: "Angle",
    designation: "ISA135x65x8",
    shape: "L",
    country_code: "Indian",
    ax: "15,5",
    ax_global: 1550,
    b: 65,
    b_global: 65,
    d: 135,
    d_global: 135,
    r1: 11,
    r1_global: 11,
    rz: "13,8",
    rz_global: "13,8",
    t: 8,
    t_global: 8,
    width: 65,
    width_global: 65,
    thickness: 8,
    thickness_global: 8,
    height: 135,
    height_global: 135,
  },
  {
    profile_section_id: 3669,
    name: "ISA135x65x10",
    type: "Angle",
    designation: "ISA135x65x10",
    shape: "L",
    country_code: "Indian",
    ax: "19,2",
    ax_global: 1920,
    b: 65,
    b_global: 65,
    d: 135,
    d_global: 135,
    r1: 11,
    r1_global: 11,
    rz: "13,7",
    rz_global: "13,7",
    t: 10,
    t_global: 10,
    width: 65,
    width_global: 65,
    thickness: 10,
    thickness_global: 10,
    height: 135,
    height_global: 135,
  },
  {
    profile_section_id: 3670,
    name: "ISA135x65x12",
    type: "Angle",
    designation: "ISA135x65x12",
    shape: "L",
    country_code: "Indian",
    ax: "22,7",
    ax_global: 2270,
    b: 65,
    b_global: 65,
    d: 135,
    d_global: 135,
    r1: 11,
    r1_global: 11,
    rz: "13,6",
    rz_global: "13,6",
    t: 12,
    t_global: 12,
    width: 65,
    width_global: 65,
    thickness: 12,
    thickness_global: 12,
    height: 135,
    height_global: 135,
  },
  {
    profile_section_id: 3671,
    name: "ISA150x75x8",
    type: "Angle",
    designation: "ISA150x75x8",
    shape: "L",
    country_code: "Indian",
    ax: "17,5",
    ax_global: 1750,
    b: 75,
    b_global: 75,
    d: 150,
    d_global: 150,
    r1: 10,
    r1_global: 10,
    rz: "16,2",
    rz_global: "16,2",
    t: 8,
    t_global: 8,
    width: 75,
    width_global: 75,
    thickness: 8,
    thickness_global: 8,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3672,
    name: "ISA150x75x9",
    type: "Angle",
    designation: "ISA150x75x9",
    shape: "L",
    country_code: "Indian",
    ax: "19,6",
    ax_global: 1960,
    b: 75,
    b_global: 75,
    d: 150,
    d_global: 150,
    r1: 11,
    r1_global: 11,
    rz: "16,1",
    rz_global: "16,1",
    t: 9,
    t_global: 9,
    width: 75,
    width_global: 75,
    thickness: 9,
    thickness_global: 9,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3673,
    name: "ISA150x75x10",
    type: "Angle",
    designation: "ISA150x75x10",
    shape: "L",
    country_code: "Indian",
    ax: "21,6",
    ax_global: 2160,
    b: 75,
    b_global: 75,
    d: 150,
    d_global: 150,
    r1: 10,
    r1_global: 10,
    rz: 16,
    rz_global: 16,
    t: 10,
    t_global: 10,
    width: 75,
    width_global: 75,
    thickness: 10,
    thickness_global: 10,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3674,
    name: "ISA150x75x12",
    type: "Angle",
    designation: "ISA150x75x12",
    shape: "L",
    country_code: "Indian",
    ax: "25,7",
    ax_global: 2570,
    b: 75,
    b_global: 75,
    d: 150,
    d_global: 150,
    r1: 10,
    r1_global: 10,
    rz: "15,9",
    rz_global: "15,9",
    t: 12,
    t_global: 12,
    width: 75,
    width_global: 75,
    thickness: 12,
    thickness_global: 12,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3675,
    name: "ISA150x75x15",
    type: "Angle",
    designation: "ISA150x75x15",
    shape: "L",
    country_code: "Indian",
    ax: "31,7",
    ax_global: 3170,
    b: 75,
    b_global: 75,
    d: 150,
    d_global: 150,
    r1: 11,
    r1_global: 11,
    rz: "15,8",
    rz_global: "15,8",
    t: 15,
    t_global: 15,
    width: 75,
    width_global: 75,
    thickness: 15,
    thickness_global: 15,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3676,
    name: "ISA150x90x10",
    type: "Angle",
    designation: "ISA150x90x10",
    shape: "L",
    country_code: "Indian",
    ax: "23,2",
    ax_global: 2320,
    b: 90,
    b_global: 90,
    d: 150,
    d_global: 150,
    r1: 12,
    r1_global: 12,
    rz: "19,5",
    rz_global: "19,5",
    t: 10,
    t_global: 10,
    width: 90,
    width_global: 90,
    thickness: 10,
    thickness_global: 10,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3677,
    name: "ISA150x90x12",
    type: "Angle",
    designation: "ISA150x90x12",
    shape: "L",
    country_code: "Indian",
    ax: "27,5",
    ax_global: 2750,
    b: 90,
    b_global: 90,
    d: 150,
    d_global: 150,
    r1: 12,
    r1_global: 12,
    rz: "19,4",
    rz_global: "19,4",
    t: 12,
    t_global: 12,
    width: 90,
    width_global: 90,
    thickness: 12,
    thickness_global: 12,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3678,
    name: "ISA150x90x15",
    type: "Angle",
    designation: "ISA150x90x15",
    shape: "L",
    country_code: "Indian",
    ax: "33,9",
    ax_global: 3390,
    b: 90,
    b_global: 90,
    d: 150,
    d_global: 150,
    r1: 12,
    r1_global: 12,
    rz: "19,3",
    rz_global: "19,3",
    t: 15,
    t_global: 15,
    width: 90,
    width_global: 90,
    thickness: 15,
    thickness_global: 15,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3679,
    name: "ISA150x115x8",
    type: "Angle",
    designation: "ISA150x115x8",
    shape: "L",
    country_code: "Indian",
    ax: "20,7",
    ax_global: 2070,
    b: 115,
    b_global: 115,
    d: 150,
    d_global: 150,
    r1: 11,
    r1_global: 11,
    rz: "24,9",
    rz_global: "24,9",
    t: 8,
    t_global: 8,
    width: 115,
    width_global: 115,
    thickness: 8,
    thickness_global: 8,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3680,
    name: "ISA150x115x10",
    type: "Angle",
    designation: "ISA150x115x10",
    shape: "L",
    country_code: "Indian",
    ax: "25,7",
    ax_global: 2570,
    b: 115,
    b_global: 115,
    d: 150,
    d_global: 150,
    r1: 11,
    r1_global: 11,
    rz: "24,8",
    rz_global: "24,8",
    t: 10,
    t_global: 10,
    width: 115,
    width_global: 115,
    thickness: 10,
    thickness_global: 10,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3681,
    name: "ISA150x115x12",
    type: "Angle",
    designation: "ISA150x115x12",
    shape: "L",
    country_code: "Indian",
    ax: "30,5",
    ax_global: 3050,
    b: 115,
    b_global: 115,
    d: 150,
    d_global: 150,
    r1: 11,
    r1_global: 11,
    rz: "24,7",
    rz_global: "24,7",
    t: 12,
    t_global: 12,
    width: 115,
    width_global: 115,
    thickness: 12,
    thickness_global: 12,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3682,
    name: "ISA150x115x16",
    type: "Angle",
    designation: "ISA150x115x16",
    shape: "L",
    country_code: "Indian",
    ax: 40,
    ax_global: 4000,
    b: 115,
    b_global: 115,
    d: 150,
    d_global: 150,
    r1: 11,
    r1_global: 11,
    rz: "24,4",
    rz_global: "24,4",
    t: 16,
    t_global: 16,
    width: 115,
    width_global: 115,
    thickness: 16,
    thickness_global: 16,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3683,
    name: "ISA150x150x10",
    type: "Angle",
    designation: "ISA150x150x10",
    shape: "L",
    country_code: "Indian",
    ax: "29,2",
    ax_global: 2920,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: 12,
    r1_global: 12,
    rz: "29,8",
    rz_global: "29,8",
    t: 10,
    t_global: 10,
    width: 150,
    width_global: 150,
    thickness: 10,
    thickness_global: 10,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3684,
    name: "ISA150x150x12",
    type: "Angle",
    designation: "ISA150x150x12",
    shape: "L",
    country_code: "Indian",
    ax: "34,8",
    ax_global: 3480,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: 12,
    r1_global: 12,
    rz: "29,6",
    rz_global: "29,6",
    t: 12,
    t_global: 12,
    width: 150,
    width_global: 150,
    thickness: 12,
    thickness_global: 12,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3685,
    name: "ISA150x150x15",
    type: "Angle",
    designation: "ISA150x150x15",
    shape: "L",
    country_code: "Indian",
    ax: 43,
    ax_global: 4300,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: 16,
    r1_global: 16,
    rz: "29,3",
    rz_global: "29,3",
    t: 15,
    t_global: 15,
    width: 150,
    width_global: 150,
    thickness: 15,
    thickness_global: 15,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3686,
    name: "ISA150x150x16",
    type: "Angle",
    designation: "ISA150x150x16",
    shape: "L",
    country_code: "Indian",
    ax: "45,7",
    ax_global: 4570,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: 12,
    r1_global: 12,
    rz: "29,4",
    rz_global: "29,4",
    t: 16,
    t_global: 16,
    width: 150,
    width_global: 150,
    thickness: 16,
    thickness_global: 16,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3687,
    name: "ISA150x150x18",
    type: "Angle",
    designation: "ISA150x150x18",
    shape: "L",
    country_code: "Indian",
    ax: 51,
    ax_global: 5100,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: 16,
    r1_global: 16,
    rz: "29,2",
    rz_global: "29,2",
    t: 18,
    t_global: 18,
    width: 150,
    width_global: 150,
    thickness: 18,
    thickness_global: 18,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3688,
    name: "ISA150x150x20",
    type: "Angle",
    designation: "ISA150x150x20",
    shape: "L",
    country_code: "Indian",
    ax: "56,2",
    ax_global: 5620,
    b: 150,
    b_global: 150,
    d: 150,
    d_global: 150,
    r1: 12,
    r1_global: 12,
    rz: "29,2",
    rz_global: "29,2",
    t: 20,
    t_global: 20,
    width: 150,
    width_global: 150,
    thickness: 20,
    thickness_global: 20,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3689,
    name: "ISA180x180x15",
    type: "Angle",
    designation: "ISA180x180x15",
    shape: "L",
    country_code: "Indian",
    ax: "52,1",
    ax_global: 5210,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    r1: 18,
    r1_global: 18,
    rz: "35,4",
    rz_global: "35,4",
    t: 15,
    t_global: 15,
    width: 180,
    width_global: 180,
    thickness: 15,
    thickness_global: 15,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 3690,
    name: "ISA180x180x18",
    type: "Angle",
    designation: "ISA180x180x18",
    shape: "L",
    country_code: "Indian",
    ax: "61,9",
    ax_global: 6190,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    r1: 18,
    r1_global: 18,
    rz: "35,2",
    rz_global: "35,2",
    t: 18,
    t_global: 18,
    width: 180,
    width_global: 180,
    thickness: 18,
    thickness_global: 18,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 3691,
    name: "ISA180x180x20",
    type: "Angle",
    designation: "ISA180x180x20",
    shape: "L",
    country_code: "Indian",
    ax: "68,3",
    ax_global: 6830,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    r1: 18,
    r1_global: 18,
    rz: "35,1",
    rz_global: "35,1",
    t: 20,
    t_global: 20,
    width: 180,
    width_global: 180,
    thickness: 20,
    thickness_global: 20,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 3692,
    name: "ISA200x100x10",
    type: "Angle",
    designation: "ISA200x100x10",
    shape: "L",
    country_code: "Indian",
    ax: "29,2",
    ax_global: 2920,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    r1: 12,
    r1_global: 12,
    rz: "21,7",
    rz_global: "21,7",
    t: 10,
    t_global: 10,
    width: 100,
    width_global: 100,
    thickness: 10,
    thickness_global: 10,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3693,
    name: "ISA200x100x12",
    type: "Angle",
    designation: "ISA200x100x12",
    shape: "L",
    country_code: "Indian",
    ax: "34,8",
    ax_global: 3480,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    r1: 12,
    r1_global: 12,
    rz: "21,5",
    rz_global: "21,5",
    t: 12,
    t_global: 12,
    width: 100,
    width_global: 100,
    thickness: 12,
    thickness_global: 12,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3694,
    name: "ISA200x100x15",
    type: "Angle",
    designation: "ISA200x100x15",
    shape: "L",
    country_code: "Indian",
    ax: 43,
    ax_global: 4300,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    r1: 15,
    r1_global: 15,
    rz: "21,2",
    rz_global: "21,2",
    t: 15,
    t_global: 15,
    width: 100,
    width_global: 100,
    thickness: 15,
    thickness_global: 15,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3695,
    name: "ISA200x100x16",
    type: "Angle",
    designation: "ISA200x100x16",
    shape: "L",
    country_code: "Indian",
    ax: "45,7",
    ax_global: 4570,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    r1: 12,
    r1_global: 12,
    rz: "21,3",
    rz_global: "21,3",
    t: 16,
    t_global: 16,
    width: 100,
    width_global: 100,
    thickness: 16,
    thickness_global: 16,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3696,
    name: "ISA200x150x10",
    type: "Angle",
    designation: "ISA200x150x10",
    shape: "L",
    country_code: "Indian",
    ax: "34,3",
    ax_global: 3430,
    b: 150,
    b_global: 150,
    d: 200,
    d_global: 200,
    r1: "13,5",
    r1_global: "13,5",
    rz: "32,8",
    rz_global: "32,8",
    t: 10,
    t_global: 10,
    width: 150,
    width_global: 150,
    thickness: 10,
    thickness_global: 10,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3697,
    name: "ISA200x150x12",
    type: "Angle",
    designation: "ISA200x150x12",
    shape: "L",
    country_code: "Indian",
    ax: "40,9",
    ax_global: 4090,
    b: 150,
    b_global: 150,
    d: 200,
    d_global: 200,
    r1: "13,5",
    r1_global: "13,5",
    rz: "32,6",
    rz_global: "32,6",
    t: 12,
    t_global: 12,
    width: 150,
    width_global: 150,
    thickness: 12,
    thickness_global: 12,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3698,
    name: "ISA200x150x15",
    type: "Angle",
    designation: "ISA200x150x15",
    shape: "L",
    country_code: "Indian",
    ax: "50,5",
    ax_global: 5050,
    b: 150,
    b_global: 150,
    d: 200,
    d_global: 200,
    r1: 15,
    r1_global: 15,
    rz: "32,2",
    rz_global: "32,2",
    t: 15,
    t_global: 15,
    width: 150,
    width_global: 150,
    thickness: 15,
    thickness_global: 15,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3699,
    name: "ISA200x150x16",
    type: "Angle",
    designation: "ISA200x150x16",
    shape: "L",
    country_code: "Indian",
    ax: "53,7",
    ax_global: 5370,
    b: 150,
    b_global: 150,
    d: 200,
    d_global: 200,
    r1: "13,5",
    r1_global: "13,5",
    rz: "32,3",
    rz_global: "32,3",
    t: 16,
    t_global: 16,
    width: 150,
    width_global: 150,
    thickness: 16,
    thickness_global: 16,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3700,
    name: "ISA200x150x18",
    type: "Angle",
    designation: "ISA200x150x18",
    shape: "L",
    country_code: "Indian",
    ax: 60,
    ax_global: 6000,
    b: 150,
    b_global: 150,
    d: 200,
    d_global: 200,
    r1: 15,
    r1_global: 15,
    rz: "32,1",
    rz_global: "32,1",
    t: 18,
    t_global: 18,
    width: 150,
    width_global: 150,
    thickness: 18,
    thickness_global: 18,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3701,
    name: "ISA200x150x20",
    type: "Angle",
    designation: "ISA200x150x20",
    shape: "L",
    country_code: "Indian",
    ax: "66,3",
    ax_global: 6630,
    b: 150,
    b_global: 150,
    d: 200,
    d_global: 200,
    r1: "13,5",
    r1_global: "13,5",
    rz: "32,1",
    rz_global: "32,1",
    t: 20,
    t_global: 20,
    width: 150,
    width_global: 150,
    thickness: 20,
    thickness_global: 20,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3702,
    name: "ISA200x200x12",
    type: "Angle",
    designation: "ISA200x200x12",
    shape: "L",
    country_code: "Indian",
    ax: "46,9",
    ax_global: 4690,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: 15,
    r1_global: 15,
    rz: "39,9",
    rz_global: "39,9",
    t: 12,
    t_global: 12,
    width: 200,
    width_global: 200,
    thickness: 12,
    thickness_global: 12,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3703,
    name: "ISA200x200x16",
    type: "Angle",
    designation: "ISA200x200x16",
    shape: "L",
    country_code: "Indian",
    ax: "61,8",
    ax_global: 6180,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: 15,
    r1_global: 15,
    rz: "39,6",
    rz_global: "39,6",
    t: 16,
    t_global: 16,
    width: 200,
    width_global: 200,
    thickness: 16,
    thickness_global: 16,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3704,
    name: "ISA200x200x20",
    type: "Angle",
    designation: "ISA200x200x20",
    shape: "L",
    country_code: "Indian",
    ax: "76,4",
    ax_global: 7640,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: 15,
    r1_global: 15,
    rz: "39,3",
    rz_global: "39,3",
    t: 20,
    t_global: 20,
    width: 200,
    width_global: 200,
    thickness: 20,
    thickness_global: 20,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3705,
    name: "ISA200x200x24",
    type: "Angle",
    designation: "ISA200x200x24",
    shape: "L",
    country_code: "Indian",
    ax: "90,6",
    ax_global: 9060,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: 18,
    r1_global: 18,
    rz: 39,
    rz_global: 39,
    t: 24,
    t_global: 24,
    width: 200,
    width_global: 200,
    thickness: 24,
    thickness_global: 24,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3706,
    name: "ISA200x200x25",
    type: "Angle",
    designation: "ISA200x200x25",
    shape: "L",
    country_code: "Indian",
    ax: "94,1",
    ax_global: 9410,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    r1: 15,
    r1_global: 15,
    rz: "39,1",
    rz_global: "39,1",
    t: 25,
    t_global: 25,
    width: 200,
    width_global: 200,
    thickness: 25,
    thickness_global: 25,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3707,
    name: "ISMC75",
    type: "Channel section",
    designation: "ISMC75",
    shape: "C",
    country_code: "Indian",
    ax: "9,1",
    ax_global: 910,
    bf: 40,
    bf_global: 40,
    ct: "1,32",
    d: 75,
    d_global: 75,
    ix: "1,57",
    ix_global: 15700,
    iy: "12,8",
    iy_global: 128000,
    iz: 78,
    iz_global: 780000,
    r1: "8,5",
    r1_global: "8,5",
    r2: "2,4",
    r2_global: "2,4",
    tf: "7,5",
    tf_global: "7,5",
    tw: "4,8",
    tw_global: "4,8",
    zx: "25,1",
    zx_global: 25100,
    zy: "9,02",
    zy_global: 9020,
    width: 40,
    width_global: 40,
    thickness: "7,5",
    thickness_global: "7,5",
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 3708,
    name: "ISMC100",
    type: "Channel section",
    designation: "ISMC100",
    shape: "C",
    country_code: "Indian",
    ax: "12,2",
    ax_global: 1220,
    bf: 50,
    bf_global: 50,
    ct: "1,54",
    d: 100,
    d_global: 100,
    ix: "2,23",
    ix_global: 22300,
    iy: "26,4",
    iy_global: 264000,
    iz: 192,
    iz_global: 1920000,
    r1: 9,
    r1_global: 9,
    r2: "2,4",
    r2_global: "2,4",
    tf: "7,7",
    tf_global: "7,7",
    tw: 5,
    tw_global: 5,
    zx: "45,4",
    zx_global: 45400,
    zy: "14,8",
    zy_global: 14800,
    width: 50,
    width_global: 50,
    thickness: "7,7",
    thickness_global: "7,7",
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3709,
    name: "ISMC125",
    type: "Channel section",
    designation: "ISMC125",
    shape: "C",
    country_code: "Indian",
    ax: "16,6",
    ax_global: 1660,
    bf: 65,
    bf_global: 65,
    ct: "1,94",
    d: 125,
    d_global: 125,
    ix: "3,47",
    ix_global: 34700,
    iy: "59,8",
    iy_global: 598000,
    iz: 422,
    iz_global: 4220000,
    r1: "9,5",
    r1_global: "9,5",
    r2: "2,4",
    r2_global: "2,4",
    tf: "8,1",
    tf_global: "8,1",
    tw: "5,3",
    tw_global: "5,3",
    zx: "78,4",
    zx_global: 78400,
    zy: "25,9",
    zy_global: 25900,
    width: 65,
    width_global: 65,
    thickness: "8,1",
    thickness_global: "8,1",
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3710,
    name: "ISMC125H",
    type: "Channel section",
    designation: "ISMC125H",
    shape: "C",
    country_code: "Indian",
    ax: "17,5",
    ax_global: 1750,
    bf: 66,
    bf_global: 66,
    ct: "1,92",
    d: 125,
    d_global: 125,
    ix: "3,87",
    ix_global: 38700,
    iy: "63,8",
    iy_global: 638000,
    iz: 435,
    iz_global: 4350000,
    r1: "9,5",
    r1_global: "9,5",
    r2: "2,4",
    r2_global: "2,4",
    tf: "8,1",
    tf_global: "8,1",
    tw: 6,
    tw_global: 6,
    zx: "81,4",
    zx_global: 81400,
    zy: "27,1",
    zy_global: 27100,
    width: 66,
    width_global: 66,
    thickness: "8,1",
    thickness_global: "8,1",
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3711,
    name: "ISMC150",
    type: "Channel section",
    designation: "ISMC150",
    shape: "C",
    country_code: "Indian",
    ax: "21,3",
    ax_global: 2130,
    bf: 75,
    bf_global: 75,
    ct: "2,2",
    d: 150,
    d_global: 150,
    ix: "5,41",
    ix_global: 54100,
    iy: 102,
    iy_global: 1020000,
    iz: 788,
    iz_global: 7880000,
    r1: 10,
    r1_global: 10,
    r2: "2,4",
    r2_global: "2,4",
    tf: 9,
    tf_global: 9,
    tw: "5,7",
    tw_global: "5,7",
    zx: 122,
    zx_global: 122000,
    zy: "38,3",
    zy_global: 38300,
    width: 75,
    width_global: 75,
    thickness: 9,
    thickness_global: 9,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3712,
    name: "ISMC150H",
    type: "Channel section",
    designation: "ISMC150H",
    shape: "C",
    country_code: "Indian",
    ax: "22,6",
    ax_global: 2260,
    bf: 76,
    bf_global: 76,
    ct: "2,17",
    d: 150,
    d_global: 150,
    ix: "6,02",
    ix_global: 60200,
    iy: 109,
    iy_global: 1090000,
    iz: 813,
    iz_global: 8130000,
    r1: 10,
    r1_global: 10,
    r2: "2,4",
    r2_global: "2,4",
    tf: 9,
    tf_global: 9,
    tw: "6,5",
    tw_global: "6,5",
    zx: 126,
    zx_global: 126000,
    zy: 40,
    zy_global: 40000,
    width: 76,
    width_global: 76,
    thickness: 9,
    thickness_global: 9,
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3713,
    name: "ISMC175",
    type: "Channel section",
    designation: "ISMC175",
    shape: "C",
    country_code: "Indian",
    ax: "24,9",
    ax_global: 2490,
    bf: 75,
    bf_global: 75,
    ct: "2,19",
    d: 175,
    d_global: 175,
    ix: "7,44",
    ix_global: 74400,
    iy: 121,
    iy_global: 1210000,
    iz: 1237,
    iz_global: 12370000,
    r1: "10,5",
    r1_global: "10,5",
    r2: "3,2",
    r2_global: "3,2",
    tf: "10,2",
    tf_global: "10,2",
    tw: 6,
    tw_global: 6,
    zx: 164,
    zx_global: 164000,
    zy: "44,7",
    zy_global: 44700,
    width: 75,
    width_global: 75,
    thickness: "10,2",
    thickness_global: "10,2",
    height: 175,
    height_global: 175,
  },
  {
    profile_section_id: 3714,
    name: "ISMC175H",
    type: "Channel section",
    designation: "ISMC175H",
    shape: "C",
    country_code: "Indian",
    ax: "27,6",
    ax_global: 2760,
    bf: 77,
    bf_global: 77,
    ct: "2,14",
    d: 175,
    d_global: 175,
    ix: "9,05",
    ix_global: 90500,
    iy: 135,
    iy_global: 1350000,
    iz: 1311,
    iz_global: 13110000,
    r1: "10,5",
    r1_global: "10,5",
    r2: "3,2",
    r2_global: "3,2",
    tf: "10,2",
    tf_global: "10,2",
    tw: "7,5",
    tw_global: "7,5",
    zx: 176,
    zx_global: 176000,
    zy: 48,
    zy_global: 48000,
    width: 77,
    width_global: 77,
    thickness: "10,2",
    thickness_global: "10,2",
    height: 175,
    height_global: 175,
  },
  {
    profile_section_id: 3715,
    name: "ISMC200",
    type: "Channel section",
    designation: "ISMC200",
    shape: "C",
    country_code: "Indian",
    ax: "28,5",
    ax_global: 2850,
    bf: 75,
    bf_global: 75,
    ct: "2,18",
    d: 200,
    d_global: 200,
    ix: "9,83",
    ix_global: 98300,
    iy: 139,
    iy_global: 1390000,
    iz: 1830,
    iz_global: 18300000,
    r1: 11,
    r1_global: 11,
    r2: "3,2",
    r2_global: "3,2",
    tf: "11,4",
    tf_global: "11,4",
    tw: "6,2",
    tw_global: "6,2",
    zx: 213,
    zx_global: 213000,
    zy: "51,1",
    zy_global: 51100,
    width: 75,
    width_global: 75,
    thickness: "11,4",
    thickness_global: "11,4",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3716,
    name: "ISMC200H",
    type: "Channel section",
    designation: "ISMC200H",
    shape: "C",
    country_code: "Indian",
    ax: 31,
    ax_global: 3100,
    bf: 76,
    bf_global: 76,
    ct: "2,11",
    d: 200,
    d_global: 200,
    ix: "11,4",
    ix_global: 114000,
    iy: 150,
    iy_global: 1500000,
    iz: 1910,
    iz_global: 19100000,
    r1: 11,
    r1_global: 11,
    r2: "3,2",
    r2_global: "3,2",
    tf: "11,4",
    tf_global: "11,4",
    tw: "7,5",
    tw_global: "7,5",
    zx: 225,
    zx_global: 225000,
    zy: "53,3",
    zy_global: 53300,
    width: 76,
    width_global: 76,
    thickness: "11,4",
    thickness_global: "11,4",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3717,
    name: "ISMC225",
    type: "Channel section",
    designation: "ISMC225",
    shape: "C",
    country_code: "Indian",
    ax: "33,3",
    ax_global: 3330,
    bf: 80,
    bf_global: 80,
    ct: "2,31",
    d: 225,
    d_global: 225,
    ix: "13,3",
    ix_global: 133000,
    iy: 186,
    iy_global: 1860000,
    iz: 2711,
    iz_global: 27110000,
    r1: 12,
    r1_global: 12,
    r2: "3,2",
    r2_global: "3,2",
    tf: "12,4",
    tf_global: "12,4",
    tw: "6,5",
    tw_global: "6,5",
    zx: 280,
    zx_global: 280000,
    zy: "63,7",
    zy_global: 63700,
    width: 80,
    width_global: 80,
    thickness: "12,4",
    thickness_global: "12,4",
    height: 225,
    height_global: 225,
  },
  {
    profile_section_id: 3718,
    name: "ISMC225H",
    type: "Channel section",
    designation: "ISMC225H",
    shape: "C",
    country_code: "Indian",
    ax: 39,
    ax_global: 3900,
    bf: 83,
    bf_global: 83,
    ct: "2,22",
    d: 225,
    d_global: 225,
    ix: "17,7",
    ix_global: 177000,
    iy: 218,
    iy_global: 2180000,
    iz: 2962,
    iz_global: 29620000,
    r1: 12,
    r1_global: 12,
    r2: "3,2",
    r2_global: "3,2",
    tf: "12,4",
    tf_global: "12,4",
    tw: 9,
    tw_global: 9,
    zx: 313,
    zx_global: 313000,
    zy: "69,7",
    zy_global: 69700,
    width: 83,
    width_global: 83,
    thickness: "12,4",
    thickness_global: "12,4",
    height: 225,
    height_global: 225,
  },
  {
    profile_section_id: 3719,
    name: "ISMC250",
    type: "Channel section",
    designation: "ISMC250",
    shape: "C",
    country_code: "Indian",
    ax: 39,
    ax_global: 3900,
    bf: 80,
    bf_global: 80,
    ct: "2,29",
    d: 250,
    d_global: 250,
    ix: "18,8",
    ix_global: 188000,
    iy: 218,
    iy_global: 2180000,
    iz: 3838,
    iz_global: 38380000,
    r1: 12,
    r1_global: 12,
    r2: "3,2",
    r2_global: "3,2",
    tf: "14,1",
    tf_global: "14,1",
    tw: "7,2",
    tw_global: "7,2",
    zx: 359,
    zx_global: 359000,
    zy: "73,9",
    zy_global: 73900,
    width: 80,
    width_global: 80,
    thickness: "14,1",
    thickness_global: "14,1",
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3720,
    name: "ISMC250H",
    type: "Channel section",
    designation: "ISMC250H",
    shape: "C",
    country_code: "Indian",
    ax: "43,5",
    ax_global: 4350,
    bf: 82,
    bf_global: 82,
    ct: "2,23",
    d: 250,
    d_global: 250,
    ix: "22,7",
    ix_global: 227000,
    iy: 243,
    iy_global: 2430000,
    iz: 4080,
    iz_global: 40800000,
    r1: 12,
    r1_global: 12,
    r2: "3,2",
    r2_global: "3,2",
    tf: "14,1",
    tf_global: "14,1",
    tw: 9,
    tw_global: 9,
    zx: 388,
    zx_global: 388000,
    zy: "78,3",
    zy_global: 78300,
    width: 82,
    width_global: 82,
    thickness: "14,1",
    thickness_global: "14,1",
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3721,
    name: "ISMC300",
    type: "Channel section",
    designation: "ISMC300",
    shape: "C",
    country_code: "Indian",
    ax: "46,3",
    ax_global: 4630,
    bf: 90,
    bf_global: 90,
    ct: "2,35",
    d: 300,
    d_global: 300,
    ix: "21,7",
    ix_global: 217000,
    iy: 311,
    iy_global: 3110000,
    iz: 6418,
    iz_global: 64180000,
    r1: 13,
    r1_global: 13,
    r2: "3,2",
    r2_global: "3,2",
    tf: "13,6",
    tf_global: "13,6",
    tw: "7,8",
    tw_global: "7,8",
    zx: 502,
    zx_global: 502000,
    zy: "91,2",
    zy_global: 91200,
    width: 90,
    width_global: 90,
    thickness: "13,6",
    thickness_global: "13,6",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 3722,
    name: "ISMC300H",
    type: "Channel section",
    designation: "ISMC300H",
    shape: "C",
    country_code: "Indian",
    ax: "52,8",
    ax_global: 5280,
    bf: 92,
    bf_global: 92,
    ct: "2,26",
    d: 300,
    d_global: 300,
    ix: 28,
    ix_global: 280000,
    iy: 344,
    iy_global: 3440000,
    iz: 6902,
    iz_global: 69020000,
    r1: 13,
    r1_global: 13,
    r2: "3,2",
    r2_global: "3,2",
    tf: "13,6",
    tf_global: "13,6",
    tw: 10,
    tw_global: 10,
    zx: 551,
    zx_global: 551000,
    zy: "96,1",
    zy_global: 96100,
    width: 92,
    width_global: 92,
    thickness: "13,6",
    thickness_global: "13,6",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 3723,
    name: "ISMC350",
    type: "Channel section",
    designation: "ISMC350",
    shape: "C",
    country_code: "Indian",
    ax: "54,4",
    ax_global: 5440,
    bf: 100,
    bf_global: 100,
    ct: "2,44",
    d: 350,
    d_global: 350,
    ix: "25,9",
    ix_global: 259000,
    iy: 430,
    iy_global: 4300000,
    iz: 10090,
    iz_global: 100900000,
    r1: 14,
    r1_global: 14,
    r2: "4,8",
    r2_global: "4,8",
    tf: "13,5",
    tf_global: "13,5",
    tw: "8,3",
    tw_global: "8,3",
    zx: 679,
    zx_global: 679000,
    zy: 111,
    zy_global: 111000,
    width: 100,
    width_global: 100,
    thickness: "13,5",
    thickness_global: "13,5",
    height: 350,
    height_global: 350,
  },
  {
    profile_section_id: 3724,
    name: "ISMC400",
    type: "Channel section",
    designation: "ISMC400",
    shape: "C",
    country_code: "Indian",
    ax: "63,8",
    ax_global: 6380,
    bf: 100,
    bf_global: 100,
    ct: "2,42",
    d: 400,
    d_global: 400,
    ix: "35,9",
    ix_global: 359000,
    iy: 505,
    iy_global: 5050000,
    iz: 15207,
    iz_global: 152070000,
    r1: 15,
    r1_global: 15,
    r2: "4,8",
    r2_global: "4,8",
    tf: "15,3",
    tf_global: "15,3",
    tw: "8,8",
    tw_global: "8,8",
    zx: 900,
    zx_global: 900000,
    zy: 129,
    zy_global: 129000,
    width: 100,
    width_global: 100,
    thickness: "15,3",
    thickness_global: "15,3",
    height: 400,
    height_global: 400,
  },
  {
    profile_section_id: 3725,
    name: "ISJC100",
    type: "Channel section",
    designation: "ISJC100",
    shape: "C",
    country_code: "Indian",
    ax: "7,41",
    ax_global: 741,
    bf: 45,
    bf_global: 45,
    ct: "1,4",
    d: 100,
    d_global: 100,
    ix: "0,52",
    ix_global: 5200,
    iy: "14,6",
    iy_global: 146000,
    iz: 124,
    iz_global: 1240000,
    r1: 6,
    r1_global: 6,
    r2: 2,
    r2_global: 2,
    tf: "5,1",
    tf_global: "5,1",
    tw: 3,
    tw_global: 3,
    zx: "28,4",
    zx_global: 28400,
    zy: "8,76",
    zy_global: 8760,
    width: 45,
    width_global: 45,
    thickness: "5,1",
    thickness_global: "5,1",
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3726,
    name: "ISJC125",
    type: "Channel section",
    designation: "ISJC125",
    shape: "C",
    country_code: "Indian",
    ax: "10,1",
    ax_global: 1010,
    bf: 50,
    bf_global: 50,
    ct: "1,64",
    d: 125,
    d_global: 125,
    ix: "1,07",
    ix_global: 10700,
    iy: "25,2",
    iy_global: 252000,
    iz: 270,
    iz_global: 2700000,
    r1: 6,
    r1_global: 6,
    r2: "2,4",
    r2_global: "2,4",
    tf: "6,6",
    tf_global: "6,6",
    tw: 3,
    tw_global: 3,
    zx: "49,1",
    zx_global: 49100,
    zy: "13,6",
    zy_global: 13600,
    width: 50,
    width_global: 50,
    thickness: "6,6",
    thickness_global: "6,6",
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3727,
    name: "ISJC150",
    type: "Channel section",
    designation: "ISJC150",
    shape: "C",
    country_code: "Indian",
    ax: "12,7",
    ax_global: 1270,
    bf: 55,
    bf_global: 55,
    ct: "1,67",
    d: 150,
    d_global: 150,
    ix: "1,47",
    ix_global: 14700,
    iy: "37,4",
    iy_global: 374000,
    iz: 472,
    iz_global: 4720000,
    r1: 7,
    r1_global: 7,
    r2: "2,4",
    r2_global: "2,4",
    tf: "6,9",
    tf_global: "6,9",
    tw: "3,6",
    tw_global: "3,6",
    zx: "72,1",
    zx_global: 72100,
    zy: "18,1",
    zy_global: 18100,
    width: 55,
    width_global: 55,
    thickness: "6,9",
    thickness_global: "6,9",
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3728,
    name: "ISJC175",
    type: "Channel section",
    designation: "ISJC175",
    shape: "C",
    country_code: "Indian",
    ax: "14,2",
    ax_global: 1420,
    bf: 60,
    bf_global: 60,
    ct: "1,75",
    d: 175,
    d_global: 175,
    ix: "1,63",
    ix_global: 16300,
    iy: "49,6",
    iy_global: 496000,
    iz: 720,
    iz_global: 7200000,
    r1: 7,
    r1_global: 7,
    r2: 3,
    r2_global: 3,
    tf: "6,9",
    tf_global: "6,9",
    tw: "3,6",
    tw_global: "3,6",
    zx: "94,2",
    zx_global: 94200,
    zy: "21,8",
    zy_global: 21800,
    width: 60,
    width_global: 60,
    thickness: "6,9",
    thickness_global: "6,9",
    height: 175,
    height_global: 175,
  },
  {
    profile_section_id: 3729,
    name: "ISJC200",
    type: "Channel section",
    designation: "ISJC200",
    shape: "C",
    country_code: "Indian",
    ax: "17,8",
    ax_global: 1780,
    bf: 70,
    bf_global: 70,
    ct: "1,97",
    d: 200,
    d_global: 200,
    ix: "2,23",
    ix_global: 22300,
    iy: "82,9",
    iy_global: 829000,
    iz: 1161,
    iz_global: 11610000,
    r1: 8,
    r1_global: 8,
    r2: "3,2",
    r2_global: "3,2",
    tf: "7,1",
    tf_global: "7,1",
    tw: "4,1",
    tw_global: "4,1",
    zx: 133,
    zx_global: 133000,
    zy: "30,8",
    zy_global: 30800,
    width: 70,
    width_global: 70,
    thickness: "7,1",
    thickness_global: "7,1",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3730,
    name: "ISLC75",
    type: "Channel section",
    designation: "ISLC75",
    shape: "C",
    country_code: "Indian",
    ax: "7,26",
    ax_global: 726,
    bf: 40,
    bf_global: 40,
    ct: "1,35",
    d: 75,
    d_global: 75,
    ix: "0,72",
    ix_global: 7200,
    iy: "11,3",
    iy_global: 113000,
    iz: 66,
    iz_global: 660000,
    r1: 6,
    r1_global: 6,
    r2: 2,
    r2_global: 2,
    tf: 6,
    tf_global: 6,
    tw: "3,7",
    tw_global: "3,7",
    zx: "20,6",
    zx_global: 20600,
    zy: "7,74",
    zy_global: 7740,
    width: 40,
    width_global: 40,
    thickness: 6,
    thickness_global: 6,
    height: 75,
    height_global: 75,
  },
  {
    profile_section_id: 3731,
    name: "ISLC100",
    type: "Channel section",
    designation: "ISLC100",
    shape: "C",
    country_code: "Indian",
    ax: 10,
    ax_global: 1000,
    bf: 50,
    bf_global: 50,
    ct: "1,62",
    d: 100,
    d_global: 100,
    ix: "1,11",
    ix_global: 11100,
    iy: "24,4",
    iy_global: 244000,
    iz: 165,
    iz_global: 1650000,
    r1: 6,
    r1_global: 6,
    r2: 2,
    r2_global: 2,
    tf: "6,4",
    tf_global: "6,4",
    tw: 4,
    tw_global: 4,
    zx: "38,1",
    zx_global: 38100,
    zy: "13,3",
    zy_global: 13300,
    width: 50,
    width_global: 50,
    thickness: "6,4",
    thickness_global: "6,4",
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 3732,
    name: "ISLC125",
    type: "Channel section",
    designation: "ISLC125",
    shape: "C",
    country_code: "Indian",
    ax: "13,7",
    ax_global: 1370,
    bf: 65,
    bf_global: 65,
    ct: "2,05",
    d: 125,
    d_global: 125,
    ix: "1,68",
    ix_global: 16800,
    iy: "56,3",
    iy_global: 563000,
    iz: 357,
    iz_global: 3570000,
    r1: 7,
    r1_global: 7,
    r2: "2,4",
    r2_global: "2,4",
    tf: "6,6",
    tf_global: "6,6",
    tw: "4,4",
    tw_global: "4,4",
    zx: "65,5",
    zx_global: 65500,
    zy: "23,4",
    zy_global: 23400,
    width: 65,
    width_global: 65,
    thickness: "6,6",
    thickness_global: "6,6",
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3733,
    name: "ISLCP125",
    type: "Channel section",
    designation: "ISLCP 125",
    shape: "C",
    country_code: "Indian",
    ax: "14,4",
    ax_global: 1440,
    bf: 65,
    bf_global: 65,
    ct: "1,87",
    d: 125,
    d_global: 125,
    ix: "2,27",
    ix_global: 22700,
    iy: "50,6",
    iy_global: 506000,
    iz: 371,
    iz_global: 3710000,
    r1: 7,
    r1_global: 7,
    r2: "2,4",
    r2_global: "2,4",
    tf: 7,
    tf_global: 7,
    tw: "4,6",
    tw_global: "4,6",
    zx: "68,4",
    zx_global: 68400,
    zy: 22,
    zy_global: 22000,
    width: 65,
    width_global: 65,
    thickness: 7,
    thickness_global: 7,
    height: 125,
    height_global: 125,
  },
  {
    profile_section_id: 3734,
    name: "ISLC150",
    type: "Channel section",
    designation: "ISLC150",
    shape: "C",
    country_code: "Indian",
    ax: "18,4",
    ax_global: 1840,
    bf: 75,
    bf_global: 75,
    ct: "2,39",
    d: 150,
    d_global: 150,
    ix: "3,04",
    ix_global: 30400,
    iy: 102,
    iy_global: 1020000,
    iz: 699,
    iz_global: 6990000,
    r1: 8,
    r1_global: 8,
    r2: "2,4",
    r2_global: "2,4",
    tf: "7,8",
    tf_global: "7,8",
    tw: "4,8",
    tw_global: "4,8",
    zx: 106,
    zx_global: 106000,
    zy: "36,7",
    zy_global: 36700,
    width: 75,
    width_global: 75,
    thickness: "7,8",
    thickness_global: "7,8",
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3735,
    name: "ISPCP 150",
    type: "Channel section",
    designation: "ISPCP 150",
    shape: "C",
    country_code: "Indian",
    ax: "19,9",
    ax_global: 1990,
    bf: 75,
    bf_global: 75,
    ct: "2,24",
    d: 150,
    d_global: 150,
    ix: "4,54",
    ix_global: 45400,
    iy: "96,2",
    iy_global: 962000,
    iz: 752,
    iz_global: 7520000,
    r1: 8,
    r1_global: 8,
    r2: "2,4",
    r2_global: "2,4",
    tf: "8,7",
    tf_global: "8,7",
    tw: 5,
    tw_global: 5,
    zx: 115,
    zx_global: 115000,
    zy: "36,3",
    zy_global: 36300,
    width: 75,
    width_global: 75,
    thickness: "8,7",
    thickness_global: "8,7",
    height: 150,
    height_global: 150,
  },
  {
    profile_section_id: 3736,
    name: "ISLC175",
    type: "Channel section",
    designation: "ISLC175",
    shape: "C",
    country_code: "Indian",
    ax: "22,4",
    ax_global: 2240,
    bf: 75,
    bf_global: 75,
    ct: "2,4",
    d: 175,
    d_global: 175,
    ix: "5,07",
    ix_global: 50700,
    iy: 124,
    iy_global: 1240000,
    iz: 1150,
    iz_global: 11500000,
    r1: 8,
    r1_global: 8,
    r2: "3,2",
    r2_global: "3,2",
    tf: "9,5",
    tf_global: "9,5",
    tw: "5,1",
    tw_global: "5,1",
    zx: 151,
    zx_global: 151000,
    zy: "44,8",
    zy_global: 44800,
    width: 75,
    width_global: 75,
    thickness: "9,5",
    thickness_global: "9,5",
    height: 175,
    height_global: 175,
  },
  {
    profile_section_id: 3737,
    name: "ISLC200",
    type: "Channel section",
    designation: "ISLC200",
    shape: "C",
    country_code: "Indian",
    ax: "26,3",
    ax_global: 2630,
    bf: 75,
    bf_global: 75,
    ct: "2,36",
    d: 200,
    d_global: 200,
    ix: "7,3",
    ix_global: 73000,
    iy: 145,
    iy_global: 1450000,
    iz: 1730,
    iz_global: 17300000,
    r1: "8,5",
    r1_global: "8,5",
    r2: "3,2",
    r2_global: "3,2",
    tf: "10,8",
    tf_global: "10,8",
    tw: "5,5",
    tw_global: "5,5",
    zx: 199,
    zx_global: 199000,
    zy: "51,9",
    zy_global: 51900,
    width: 75,
    width_global: 75,
    thickness: "10,8",
    thickness_global: "10,8",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3738,
    name: "ISLCP 200",
    type: "Channel section",
    designation: "ISLCP 200",
    shape: "C",
    country_code: "Indian",
    ax: "27,4",
    ax_global: 2740,
    bf: 75,
    bf_global: 75,
    ct: "2,23",
    d: 200,
    d_global: 200,
    ix: "9,12",
    ix_global: 91200,
    iy: 137,
    iy_global: 1370000,
    iz: 1795,
    iz_global: 17950000,
    r1: "8,5",
    r1_global: "8,5",
    r2: "3,2",
    r2_global: "3,2",
    tf: "11,4",
    tf_global: "11,4",
    tw: "5,7",
    tw_global: "5,7",
    zx: 207,
    zx_global: 207000,
    zy: "50,5",
    zy_global: 50500,
    width: 75,
    width_global: 75,
    thickness: "11,4",
    thickness_global: "11,4",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 3739,
    name: "ISLC225",
    type: "Channel section",
    designation: "ISLC225",
    shape: "C",
    country_code: "Indian",
    ax: "30,6",
    ax_global: 3060,
    bf: 90,
    bf_global: 90,
    ct: "2,47",
    d: 225,
    d_global: 225,
    ix: "9,25",
    ix_global: 92500,
    iy: 208,
    iy_global: 2080000,
    iz: 2555,
    iz_global: 25550000,
    r1: 11,
    r1_global: 11,
    r2: "3,2",
    r2_global: "3,2",
    tf: "10,2",
    tf_global: "10,2",
    tw: "5,8",
    tw_global: "5,8",
    zx: 261,
    zx_global: 261000,
    zy: "64,1",
    zy_global: 64100,
    width: 90,
    width_global: 90,
    thickness: "10,2",
    thickness_global: "10,2",
    height: 225,
    height_global: 225,
  },
  {
    profile_section_id: 3740,
    name: "ISLC250",
    type: "Channel section",
    designation: "ISLC250",
    shape: "C",
    country_code: "Indian",
    ax: "35,7",
    ax_global: 3570,
    bf: 100,
    bf_global: 100,
    ct: "2,71",
    d: 250,
    d_global: 250,
    ix: 12,
    ix_global: 120000,
    iy: 296,
    iy_global: 2960000,
    iz: 3697,
    iz_global: 36970000,
    r1: 11,
    r1_global: 11,
    r2: "3,2",
    r2_global: "3,2",
    tf: "10,7",
    tf_global: "10,7",
    tw: "6,1",
    tw_global: "6,1",
    zx: 339,
    zx_global: 339000,
    zy: "82,5",
    zy_global: 82500,
    width: 100,
    width_global: 100,
    thickness: "10,7",
    thickness_global: "10,7",
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 3741,
    name: "ISLC300",
    type: "Channel section",
    designation: "ISLC300",
    shape: "C",
    country_code: "Indian",
    ax: "42,2",
    ax_global: 4220,
    bf: 100,
    bf_global: 100,
    ct: "2,56",
    d: 300,
    d_global: 300,
    ix: "15,6",
    ix_global: 156000,
    iy: 345,
    iy_global: 3450000,
    iz: 6066,
    iz_global: 60660000,
    r1: 12,
    r1_global: 12,
    r2: "3,2",
    r2_global: "3,2",
    tf: "11,6",
    tf_global: "11,6",
    tw: "6,7",
    tw_global: "6,7",
    zx: 468,
    zx_global: 468000,
    zy: "93,1",
    zy_global: 93100,
    width: 100,
    width_global: 100,
    thickness: "11,6",
    thickness_global: "11,6",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 3742,
    name: "ISLCP 300",
    type: "Channel section",
    designation: "ISLCP 300",
    shape: "C",
    country_code: "Indian",
    ax: "42,2",
    ax_global: 4220,
    bf: 90,
    bf_global: 90,
    ct: "2,33",
    d: 300,
    d_global: 300,
    ix: "16,8",
    ix_global: 168000,
    iy: 283,
    iy_global: 2830000,
    iz: 5927,
    iz_global: 59270000,
    r1: 12,
    r1_global: 12,
    r2: "3,2",
    r2_global: "3,2",
    tf: "12,5",
    tf_global: "12,5",
    tw: 7,
    tw_global: 7,
    zx: 461,
    zx_global: 461000,
    zy: "83,3",
    zy_global: 83300,
    width: 90,
    width_global: 90,
    thickness: "12,5",
    thickness_global: "12,5",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 3743,
    name: "ISLC350",
    type: "Channel section",
    designation: "ISLC350",
    shape: "C",
    country_code: "Indian",
    ax: "49,5",
    ax_global: 4950,
    bf: 100,
    bf_global: 100,
    ct: "2,42",
    d: 350,
    d_global: 350,
    ix: "20,2",
    ix_global: 202000,
    iy: 392,
    iy_global: 3920000,
    iz: 9330,
    iz_global: 93300000,
    r1: 13,
    r1_global: 13,
    r2: "4,8",
    r2_global: "4,8",
    tf: "12,5",
    tf_global: "12,5",
    tw: "7,4",
    tw_global: "7,4",
    zx: 624,
    zx_global: 624000,
    zy: 102,
    zy_global: 102000,
    width: 100,
    width_global: 100,
    thickness: "12,5",
    thickness_global: "12,5",
    height: 350,
    height_global: 350,
  },
  {
    profile_section_id: 3744,
    name: "ISLC400",
    type: "Channel section",
    designation: "ISLC400",
    shape: "C",
    country_code: "Indian",
    ax: "58,3",
    ax_global: 5830,
    bf: 100,
    bf_global: 100,
    ct: "2,37",
    d: 400,
    d_global: 400,
    ix: "27,8",
    ix_global: 278000,
    iy: 458,
    iy_global: 4580000,
    iz: 14021,
    iz_global: 140210000,
    r1: 14,
    r1_global: 14,
    r2: "4,8",
    r2_global: "4,8",
    tf: 14,
    tf_global: 14,
    tw: 8,
    tw_global: 8,
    zx: 827,
    zx_global: 827000,
    zy: 117,
    zy_global: 117000,
    width: 100,
    width_global: 100,
    thickness: 14,
    thickness_global: 14,
    height: 400,
    height_global: 400,
  },
  {
    profile_section_id: 7120,
    name: "PIP213L",
    type: "Pipe",
    designation: "PIP213L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "1,21",
    ax_global: 121,
    d_global: "21,3",
    i: "0,571",
    i_global: 5710,
    ix: "0,571",
    ix_global: 5710,
    iy: "0,571",
    iy_global: 5710,
    iz: "0,571",
    iz_global: 5710,
    od: "21,3",
    od_global: "21,3",
    t_global: 2,
    tw: 2,
    tw_global: 2,
    z: "0,748",
    z_global: 748,
  },
  {
    profile_section_id: 7121,
    name: "PIP213M",
    type: "Pipe",
    designation: "PIP213M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "1,53",
    ax_global: 153,
    d_global: "21,3",
    i: "0,681",
    i_global: 6810,
    ix: "0,681",
    ix_global: 6810,
    iy: "0,681",
    iy_global: 6810,
    iz: "0,681",
    iz_global: 6810,
    od: "21,3",
    od_global: "21,3",
    t_global: "2,6",
    tw: "2,6",
    tw_global: "2,6",
    z: "0,915",
    z_global: 915,
  },
  {
    profile_section_id: 7122,
    name: "PIP213H",
    type: "Pipe",
    designation: "PIP213H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "1,82",
    ax_global: 182,
    d_global: "21,3",
    i: "0,768",
    i_global: 7680,
    ix: "0,768",
    ix_global: 7680,
    iy: "0,768",
    iy_global: 7680,
    iz: "0,768",
    iz_global: 7680,
    od: "21,3",
    od_global: "21,3",
    t_global: "3,2",
    tw: "3,2",
    tw_global: "3,2",
    z: "1,06",
    z_global: 1060,
  },
  {
    profile_section_id: 7123,
    name: "PIP269L",
    type: "Pipe",
    designation: "PIP269L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "1,78",
    ax_global: 178,
    d_global: "26,9",
    i: "1,36",
    i_global: 13600,
    ix: "1,36",
    ix_global: 13600,
    iy: "1,36",
    iy_global: 13600,
    iz: "1,36",
    iz_global: 13600,
    od: "26,9",
    od_global: "26,9",
    t_global: "2,3",
    tw: "2,3",
    tw_global: "2,3",
    z: "1,4",
    z_global: 1400,
  },
  {
    profile_section_id: 7124,
    name: "PIP269M",
    type: "Pipe",
    designation: "PIP269M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "1,98",
    ax_global: 198,
    d_global: "26,9",
    i: "1,48",
    i_global: 14800,
    ix: "1,48",
    ix_global: 14800,
    iy: "1,48",
    iy_global: 14800,
    iz: "1,48",
    iz_global: 14800,
    od: "26,9",
    od_global: "26,9",
    t_global: "2,6",
    tw: "2,6",
    tw_global: "2,6",
    z: "1,54",
    z_global: 1540,
  },
  {
    profile_section_id: 7125,
    name: "PIP269H",
    type: "Pipe",
    designation: "PIP269H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "2,38",
    ax_global: 238,
    d_global: "26,9",
    i: "1,7",
    i_global: 17000,
    ix: "1,7",
    ix_global: 17000,
    iy: "1,7",
    iy_global: 17000,
    iz: "1,7",
    iz_global: 17000,
    od: "26,9",
    od_global: "26,9",
    t_global: "3,2",
    tw: "3,2",
    tw_global: "3,2",
    z: "1,81",
    z_global: 1810,
  },
  {
    profile_section_id: 7126,
    name: "PIP337L",
    type: "Pipe",
    designation: "PIP337L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "2,54",
    ax_global: 254,
    d_global: "33,7",
    i: "3,09",
    i_global: 30900,
    ix: "3,09",
    ix_global: 30900,
    iy: "3,09",
    iy_global: 30900,
    iz: "3,09",
    iz_global: 30900,
    od: "33,7",
    od_global: "33,7",
    t_global: "2,6",
    tw: "2,6",
    tw_global: "2,6",
    z: "2,52",
    z_global: 2520,
  },
  {
    profile_section_id: 7127,
    name: "PIP337M",
    type: "Pipe",
    designation: "PIP337M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "3,07",
    ax_global: 307,
    d_global: "33,7",
    i: "3,6",
    i_global: 36000,
    ix: "3,6",
    ix_global: 36000,
    iy: "3,6",
    iy_global: 36000,
    iz: "3,6",
    iz_global: 36000,
    od: "33,7",
    od_global: "33,7",
    t_global: "3,2",
    tw: "3,2",
    tw_global: "3,2",
    z: "2,99",
    z_global: 2990,
  },
  {
    profile_section_id: 7128,
    name: "PIP337H",
    type: "Pipe",
    designation: "PIP337H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "3,73",
    ax_global: 373,
    d_global: "33,7",
    i: "4,19",
    i_global: 41900,
    ix: "4,19",
    ix_global: 41900,
    iy: "4,19",
    iy_global: 41900,
    iz: "4,19",
    iz_global: 41900,
    od: "33,7",
    od_global: "33,7",
    t_global: 4,
    tw: 4,
    tw_global: 4,
    z: "3,55",
    z_global: 3550,
  },
  {
    profile_section_id: 7129,
    name: "PIP424L",
    type: "Pipe",
    designation: "PIP424L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "3,25",
    ax_global: 325,
    d_global: "42,4",
    i: "6,46",
    i_global: 64600,
    ix: "6,46",
    ix_global: 64600,
    iy: "6,46",
    iy_global: 64600,
    iz: "6,46",
    iz_global: 64600,
    od: "42,4",
    od_global: "42,4",
    t_global: "2,6",
    tw: "2,6",
    tw_global: "2,6",
    z: "4,12",
    z_global: 4120,
  },
  {
    profile_section_id: 7130,
    name: "PIP424M",
    type: "Pipe",
    designation: "PIP424M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "3,71",
    ax_global: 371,
    d_global: "42,4",
    i: "7,25",
    i_global: 72500,
    ix: "7,25",
    ix_global: 72500,
    iy: "7,25",
    iy_global: 72500,
    iz: "7,25",
    iz_global: 72500,
    od: "42,4",
    od_global: "42,4",
    t_global: 3,
    tw: 3,
    tw_global: 3,
    z: "4,67",
    z_global: 4670,
  },
  {
    profile_section_id: 7131,
    name: "PIP424H",
    type: "Pipe",
    designation: "PIP424H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "4,83",
    ax_global: 483,
    d_global: "42,4",
    i: "8,99",
    i_global: 89900,
    ix: "8,99",
    ix_global: 89900,
    iy: "8,99",
    iy_global: 89900,
    iz: "8,99",
    iz_global: 89900,
    od: "42,4",
    od_global: "42,4",
    t_global: 4,
    tw: 4,
    tw_global: 4,
    z: "5,92",
    z_global: 5920,
  },
  {
    profile_section_id: 7132,
    name: "PIP483L",
    type: "Pipe",
    designation: "PIP483L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "4,14",
    ax_global: 414,
    d_global: "48,3",
    i: "10,7",
    i_global: 107000,
    ix: "10,7",
    ix_global: 107000,
    iy: "10,7",
    iy_global: 107000,
    iz: "10,7",
    iz_global: 107000,
    od: "48,3",
    od_global: "48,3",
    t_global: "2,9",
    tw: "2,9",
    tw_global: "2,9",
    z: "5,99",
    z_global: 5990,
  },
  {
    profile_section_id: 7133,
    name: "PIP483M",
    type: "Pipe",
    designation: "PIP483M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "4,53",
    ax_global: 453,
    d_global: "48,3",
    i: "11,6",
    i_global: 116000,
    ix: "11,6",
    ix_global: 116000,
    iy: "11,6",
    iy_global: 116000,
    iz: "11,6",
    iz_global: 116000,
    od: "48,3",
    od_global: "48,3",
    t_global: "3,2",
    tw: "3,2",
    tw_global: "3,2",
    z: "6,52",
    z_global: 6520,
  },
  {
    profile_section_id: 7134,
    name: "PIP483H",
    type: "Pipe",
    designation: "PIP483H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "5,57",
    ax_global: 557,
    d_global: "48,3",
    i: "13,8",
    i_global: 138000,
    ix: "13,8",
    ix_global: 138000,
    iy: "13,8",
    iy_global: 138000,
    iz: "13,8",
    iz_global: 138000,
    od: "48,3",
    od_global: "48,3",
    t_global: 4,
    tw: 4,
    tw_global: 4,
    z: "7,87",
    z_global: 7870,
  },
  {
    profile_section_id: 7135,
    name: "PIP603L",
    type: "Pipe",
    designation: "PIP603L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "5,23",
    ax_global: 523,
    d_global: "60,3",
    i: "21,6",
    i_global: 216000,
    ix: "21,6",
    ix_global: 216000,
    iy: "21,6",
    iy_global: 216000,
    iz: "21,6",
    iz_global: 216000,
    od: "60,3",
    od_global: "60,3",
    t_global: "2,9",
    tw: "2,9",
    tw_global: "2,9",
    z: "9,56",
    z_global: 9560,
  },
  {
    profile_section_id: 7136,
    name: "PIP603M",
    type: "Pipe",
    designation: "PIP603M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "6,41",
    ax_global: 641,
    d_global: "60,3",
    i: "25,9",
    i_global: 259000,
    ix: "25,9",
    ix_global: 259000,
    iy: "25,9",
    iy_global: 259000,
    iz: "25,9",
    iz_global: 259000,
    od: "60,3",
    od_global: "60,3",
    t_global: "3,6",
    tw: "3,6",
    tw_global: "3,6",
    z: "11,6",
    z_global: 11600,
  },
  {
    profile_section_id: 7137,
    name: "PIP603H",
    type: "Pipe",
    designation: "PIP603H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "7,89",
    ax_global: 789,
    d_global: "60,3",
    i: "30,9",
    i_global: 309000,
    ix: "30,9",
    ix_global: 309000,
    iy: "30,9",
    iy_global: 309000,
    iz: "30,9",
    iz_global: 309000,
    od: "60,3",
    od_global: "60,3",
    t_global: "4,5",
    tw: "4,5",
    tw_global: "4,5",
    z: 14,
    z_global: 14000,
  },
  {
    profile_section_id: 7138,
    name: "PIP761L",
    type: "Pipe",
    designation: "PIP761L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "7,33",
    ax_global: 733,
    d_global: "76,1",
    i: "48,8",
    i_global: 488000,
    ix: "48,8",
    ix_global: 488000,
    iy: "48,8",
    iy_global: 488000,
    iz: "48,8",
    iz_global: 488000,
    od: "76,1",
    od_global: "76,1",
    t_global: "3,2",
    tw: "3,2",
    tw_global: "3,2",
    z: 17,
    z_global: 17000,
  },
  {
    profile_section_id: 7139,
    name: "PIP761M",
    type: "Pipe",
    designation: "PIP761M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "8,2",
    ax_global: 820,
    d_global: "76,1",
    i: 54,
    i_global: 540000,
    ix: 54,
    ix_global: 540000,
    iy: 54,
    iy_global: 540000,
    iz: 54,
    iz_global: 540000,
    od: "76,1",
    od_global: "76,1",
    t_global: "3,6",
    tw: "3,6",
    tw_global: "3,6",
    z: "18,9",
    z_global: 18900,
  },
  {
    profile_section_id: 7140,
    name: "PIP761H",
    type: "Pipe",
    designation: "PIP761H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "10,1",
    ax_global: 1010,
    d_global: "76,1",
    i: "65,1",
    i_global: 651000,
    ix: "65,1",
    ix_global: 651000,
    iy: "65,1",
    iy_global: 651000,
    iz: "65,1",
    iz_global: 651000,
    od: "76,1",
    od_global: "76,1",
    t_global: "4,5",
    tw: "4,5",
    tw_global: "4,5",
    z: "23,1",
    z_global: 23100,
  },
  {
    profile_section_id: 7141,
    name: "PIP889L",
    type: "Pipe",
    designation: "PIP889L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "8,62",
    ax_global: 862,
    d_global: "88,9",
    i: "79,2",
    i_global: 792000,
    ix: "79,2",
    ix_global: 792000,
    iy: "79,2",
    iy_global: 792000,
    iz: "79,2",
    iz_global: 792000,
    od: "88,9",
    od_global: "88,9",
    t_global: "3,2",
    tw: "3,2",
    tw_global: "3,2",
    z: "23,5",
    z_global: 23500,
  },
  {
    profile_section_id: 7142,
    name: "PIP889M",
    type: "Pipe",
    designation: "PIP889M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "10,7",
    ax_global: 1070,
    d_global: "88,9",
    i: "96,3",
    i_global: 963000,
    ix: "96,3",
    ix_global: 963000,
    iy: "96,3",
    iy_global: 963000,
    iz: "96,3",
    iz_global: 963000,
    od: "88,9",
    od_global: "88,9",
    t_global: 4,
    tw: 4,
    tw_global: 4,
    z: "28,9",
    z_global: 28900,
  },
  {
    profile_section_id: 7143,
    name: "PIP889H",
    type: "Pipe",
    designation: "PIP889H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "12,7",
    ax_global: 1270,
    d_global: "88,9",
    i: 112,
    i_global: 1120000,
    ix: 112,
    ix_global: 1120000,
    iy: 112,
    iy_global: 1120000,
    iz: 112,
    iz_global: 1120000,
    od: "88,9",
    od_global: "88,9",
    t_global: "4,8",
    tw: "4,8",
    tw_global: "4,8",
    z: 34,
    z_global: 34000,
  },
  {
    profile_section_id: 7144,
    name: "PIP1016L",
    type: "Pipe",
    designation: "PIP1016L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "11,1",
    ax_global: 1110,
    d_global: "101,6",
    i: 133,
    i_global: 1330000,
    ix: 133,
    ix_global: 1330000,
    iy: 133,
    iy_global: 1330000,
    iz: 133,
    iz_global: 1330000,
    od: "101,6",
    od_global: "101,6",
    t_global: "3,6",
    tw: "3,6",
    tw_global: "3,6",
    z: "34,6",
    z_global: 34600,
  },
  {
    profile_section_id: 7145,
    name: "PIP1016M",
    type: "Pipe",
    designation: "PIP1016M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "12,3",
    ax_global: 1230,
    d_global: "101,6",
    i: 146,
    i_global: 1460000,
    ix: 146,
    ix_global: 1460000,
    iy: 146,
    iy_global: 1460000,
    iz: 146,
    iz_global: 1460000,
    od: "101,6",
    od_global: "101,6",
    t_global: 4,
    tw: 4,
    tw_global: 4,
    z: "38,1",
    z_global: 38100,
  },
  {
    profile_section_id: 7146,
    name: "PIP1016H",
    type: "Pipe",
    designation: "PIP1016H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "14,6",
    ax_global: 1460,
    d_global: "101,6",
    i: 171,
    i_global: 1710000,
    ix: 171,
    ix_global: 1710000,
    iy: 171,
    iy_global: 1710000,
    iz: 171,
    iz_global: 1710000,
    od: "101,6",
    od_global: "101,6",
    t_global: "4,8",
    tw: "4,8",
    tw_global: "4,8",
    z: 45,
    z_global: 45000,
  },
  {
    profile_section_id: 7147,
    name: "PIP1143L",
    type: "Pipe",
    designation: "PIP1143L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "12,5",
    ax_global: 1250,
    d_global: "114,3",
    i: "192,03",
    i_global: 1920300,
    ix: "192,03",
    ix_global: 1920300,
    iy: "192,03",
    iy_global: 1920300,
    iz: "192,03",
    iz_global: 1920300,
    od: "114,3",
    od_global: "114,3",
    t_global: "3,6",
    tw: "3,6",
    tw_global: "3,6",
    z: "33,6",
    z_global: 33600,
  },
  {
    profile_section_id: 7148,
    name: "PIP1143M",
    type: "Pipe",
    designation: "PIP1143M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "15,5",
    ax_global: 1550,
    d_global: "114,3",
    i: "234,3",
    i_global: 2343000,
    ix: "234,3",
    ix_global: 2343000,
    iy: "234,3",
    iy_global: 2343000,
    iz: "234,3",
    iz_global: 2343000,
    od: "114,3",
    od_global: "114,3",
    t_global: "4,5",
    tw: "4,5",
    tw_global: "4,5",
    z: 41,
    z_global: 41000,
  },
  {
    profile_section_id: 7149,
    name: "PIP1143H",
    type: "Pipe",
    designation: "PIP1143H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "18,5",
    ax_global: 1850,
    d_global: "114,3",
    i: 274,
    i_global: 2740000,
    ix: 274,
    ix_global: 2740000,
    iy: 274,
    iy_global: 2740000,
    iz: 274,
    iz_global: 2740000,
    od: "114,3",
    od_global: "114,3",
    t_global: "5,4",
    tw: "5,4",
    tw_global: "5,4",
    z: 48,
    z_global: 48000,
  },
  {
    profile_section_id: 7150,
    name: "PIP1270L",
    type: "Pipe",
    designation: "PIP1270L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "17,3",
    ax_global: 1730,
    d_global: 127,
    i: 325,
    i_global: 3250000,
    ix: 325,
    ix_global: 3250000,
    iy: 325,
    iy_global: 3250000,
    iz: 325,
    iz_global: 3250000,
    od: 127,
    od_global: 127,
    t_global: "4,5",
    tw: "4,5",
    tw_global: "4,5",
    z: "67,6",
    z_global: 67600,
  },
  {
    profile_section_id: 7151,
    name: "PIP1270M",
    type: "Pipe",
    designation: "PIP1270M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "18,4",
    ax_global: 1840,
    d_global: 127,
    i: 344,
    i_global: 3440000,
    ix: 344,
    ix_global: 3440000,
    iy: 344,
    iy_global: 3440000,
    iz: 344,
    iz_global: 3440000,
    od: 127,
    od_global: 127,
    t_global: "4,8",
    tw: "4,8",
    tw_global: "4,8",
    z: "71,7",
    z_global: 71700,
  },
  {
    profile_section_id: 7152,
    name: "PIP1270H",
    type: "Pipe",
    designation: "PIP1270H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "20,6",
    ax_global: 2060,
    d_global: 127,
    i: 382,
    i_global: 3820000,
    ix: 382,
    ix_global: 3820000,
    iy: 382,
    iy_global: 3820000,
    iz: 382,
    iz_global: 3820000,
    od: 127,
    od_global: 127,
    t_global: "5,4",
    tw: "5,4",
    tw_global: "5,4",
    z: "79,9",
    z_global: 79900,
  },
  {
    profile_section_id: 7153,
    name: "PIP1397L",
    type: "Pipe",
    designation: "PIP1397L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "19,1",
    ax_global: 1910,
    d_global: "139,7",
    i: 437,
    i_global: 4370000,
    ix: 437,
    ix_global: 4370000,
    iy: 437,
    iy_global: 4370000,
    iz: 437,
    iz_global: 4370000,
    od: "139,7",
    od_global: "139,7",
    t_global: "4,5",
    tw: "4,5",
    tw_global: "4,5",
    z: "82,3",
    z_global: 82300,
  },
  {
    profile_section_id: 7154,
    name: "PIP1397M",
    type: "Pipe",
    designation: "PIP1397M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "20,3",
    ax_global: 2030,
    d_global: "139,7",
    i: 463,
    i_global: 4630000,
    ix: 463,
    ix_global: 4630000,
    iy: 463,
    iy_global: 4630000,
    iz: 463,
    iz_global: 4630000,
    od: "139,7",
    od_global: "139,7",
    t_global: "4,8",
    tw: "4,8",
    tw_global: "4,8",
    z: "87,4",
    z_global: 87400,
  },
  {
    profile_section_id: 7155,
    name: "PIP1397H",
    type: "Pipe",
    designation: "PIP1397H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "22,8",
    ax_global: 2280,
    d_global: "139,7",
    i: 514,
    i_global: 5140000,
    ix: 514,
    ix_global: 5140000,
    iy: 514,
    iy_global: 5140000,
    iz: 514,
    iz_global: 5140000,
    od: "139,7",
    od_global: "139,7",
    t_global: "5,4",
    tw: "5,4",
    tw_global: "5,4",
    z: "97,4",
    z_global: 97400,
  },
  {
    profile_section_id: 7156,
    name: "PIP1524L",
    type: "Pipe",
    designation: "PIP1524L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "20,9",
    ax_global: 2090,
    d_global: "152,4",
    i: 572,
    i_global: 5720000,
    ix: 572,
    ix_global: 5720000,
    iy: 572,
    iy_global: 5720000,
    iz: 572,
    iz_global: 5720000,
    od: "152,4",
    od_global: "152,4",
    t_global: "4,5",
    tw: "4,5",
    tw_global: "4,5",
    z: "98,5",
    z_global: 98500,
  },
  {
    profile_section_id: 7157,
    name: "PIP1524M",
    type: "Pipe",
    designation: "PIP1524M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "22,3",
    ax_global: 2230,
    d_global: "152,4",
    i: 607,
    i_global: 6070000,
    ix: 607,
    ix_global: 6070000,
    iy: 607,
    iy_global: 6070000,
    iz: 607,
    iz_global: 6070000,
    od: "152,4",
    od_global: "152,4",
    t_global: "4,8",
    tw: "4,8",
    tw_global: "4,8",
    z: 105,
    z_global: 105000,
  },
  {
    profile_section_id: 7158,
    name: "PIP1524H",
    type: "Pipe",
    designation: "PIP1524H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "24,9",
    ax_global: 2490,
    d_global: "152,4",
    i: 675,
    i_global: 6750000,
    ix: 675,
    ix_global: 6750000,
    iy: 675,
    iy_global: 6750000,
    iz: 675,
    iz_global: 6750000,
    od: "152,4",
    od_global: "152,4",
    t_global: "5,4",
    tw: "5,4",
    tw_global: "5,4",
    z: 117,
    z_global: 117000,
  },
  {
    profile_section_id: 7159,
    name: "PIP1651L",
    type: "Pipe",
    designation: "PIP1651L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "22,7",
    ax_global: 2270,
    d_global: "165,1",
    i: 733,
    i_global: 7330000,
    ix: 733,
    ix_global: 7330000,
    iy: 733,
    iy_global: 7330000,
    iz: 733,
    iz_global: 7330000,
    od: "165,1",
    od_global: "165,1",
    t_global: "4,5",
    tw: "4,5",
    tw_global: "4,5",
    z: 116,
    z_global: 116000,
  },
  {
    profile_section_id: 7160,
    name: "PIP1651M",
    type: "Pipe",
    designation: "PIP1651M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "24,2",
    ax_global: 2420,
    d_global: "165,1",
    i: 777,
    i_global: 7770000,
    ix: 777,
    ix_global: 7770000,
    iy: 777,
    iy_global: 7770000,
    iz: 777,
    iz_global: 7770000,
    od: "165,1",
    od_global: "165,1",
    t_global: "4,8",
    tw: "4,8",
    tw_global: "4,8",
    z: 123,
    z_global: 123000,
  },
  {
    profile_section_id: 7161,
    name: "PIP1651H",
    type: "Pipe",
    designation: "PIP1651H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "27,1",
    ax_global: 2710,
    d_global: "165,1",
    i: 865,
    i_global: 8650000,
    ix: 865,
    ix_global: 8650000,
    iy: 865,
    iy_global: 8650000,
    iz: 865,
    iz_global: 8650000,
    od: "165,1",
    od_global: "165,1",
    t_global: "5,4",
    tw: "5,4",
    tw_global: "5,4",
    z: 138,
    z_global: 138000,
  },
  {
    profile_section_id: 7162,
    name: "PIP1683L",
    type: "Pipe",
    designation: "PIP1683L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "23,2",
    ax_global: 2320,
    d_global: "168,3",
    i: 777,
    i_global: 7770000,
    ix: 777,
    ix_global: 7770000,
    iy: 777,
    iy_global: 7770000,
    iz: 777,
    iz_global: 7770000,
    od: "168,3",
    od_global: "168,3",
    t_global: "4,5",
    tw: "4,5",
    tw_global: "4,5",
    z: 121,
    z_global: 121000,
  },
  {
    profile_section_id: 7163,
    name: "PIP1683M",
    type: "Pipe",
    designation: "PIP1683M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "24,7",
    ax_global: 2470,
    d_global: "168,3",
    i: 825,
    i_global: 8250000,
    ix: 825,
    ix_global: 8250000,
    iy: 825,
    iy_global: 8250000,
    iz: 825,
    iz_global: 8250000,
    od: "168,3",
    od_global: "168,3",
    t_global: "4,8",
    tw: "4,8",
    tw_global: "4,8",
    z: 128,
    z_global: 128000,
  },
  {
    profile_section_id: 7164,
    name: "PIP1683H",
    type: "Pipe",
    designation: "PIP1683H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "27,6",
    ax_global: 2760,
    d_global: "168,3",
    i: 918,
    i_global: 9180000,
    ix: 918,
    ix_global: 9180000,
    iy: 918,
    iy_global: 9180000,
    iz: 918,
    iz_global: 9180000,
    od: "168,3",
    od_global: "168,3",
    t_global: "5,4",
    tw: "5,4",
    tw_global: "5,4",
    z: 143,
    z_global: 143000,
  },
  {
    profile_section_id: 7165,
    name: "PIP1937L",
    type: "Pipe",
    designation: "PIP1937L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "28,5",
    ax_global: 2850,
    d_global: "193,7",
    i: 1271,
    i_global: 12710000,
    ix: 1271,
    ix_global: 12710000,
    iy: 1271,
    iy_global: 12710000,
    iz: 1271,
    iz_global: 12710000,
    od: "193,7",
    od_global: "193,7",
    t_global: "4,8",
    tw: "4,8",
    tw_global: "4,8",
    z: 171,
    z_global: 171000,
  },
  {
    profile_section_id: 7166,
    name: "PIP1937M",
    type: "Pipe",
    designation: "PIP1937M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "31,9",
    ax_global: 3190,
    d_global: "193,7",
    i: 1417,
    i_global: 14170000,
    ix: 1417,
    ix_global: 14170000,
    iy: 1417,
    iy_global: 14170000,
    iz: 1417,
    iz_global: 14170000,
    od: "193,7",
    od_global: "193,7",
    t_global: "5,4",
    tw: "5,4",
    tw_global: "5,4",
    z: 192,
    z_global: 192000,
  },
  {
    profile_section_id: 7167,
    name: "PIP1937H",
    type: "Pipe",
    designation: "PIP1937H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "34,8",
    ax_global: 3480,
    d_global: "193,7",
    i: 1536,
    i_global: 15360000,
    ix: 1536,
    ix_global: 15360000,
    iy: 1536,
    iy_global: 15360000,
    iz: 1536,
    iz_global: 15360000,
    od: "193,7",
    od_global: "193,7",
    t_global: "5,9",
    tw: "5,9",
    tw_global: "5,9",
    z: 208,
    z_global: 208000,
  },
  {
    profile_section_id: 7168,
    name: "PIP2191L",
    type: "Pipe",
    designation: "PIP2191L",
    shape: "PIPE",
    country_code: "Indian",
    ax: "32,3",
    ax_global: 3230,
    d_global: "219,1",
    i: 1856,
    i_global: 18560000,
    ix: 1856,
    ix_global: 18560000,
    iy: 1856,
    iy_global: 18560000,
    iz: 1856,
    iz_global: 18560000,
    od: "219,1",
    od_global: "219,1",
    t_global: "4,8",
    tw: "4,8",
    tw_global: "4,8",
    z: 220,
    z_global: 220000,
  },
  {
    profile_section_id: 7169,
    name: "PIP2191M",
    type: "Pipe",
    designation: "PIP2191M",
    shape: "PIPE",
    country_code: "Indian",
    ax: "37,6",
    ax_global: 3760,
    d_global: "219,1",
    i: 2142,
    i_global: 21420000,
    ix: 2142,
    ix_global: 21420000,
    iy: 2142,
    iy_global: 21420000,
    iz: 2142,
    iz_global: 21420000,
    od: "219,1",
    od_global: "219,1",
    t_global: "5,6",
    tw: "5,6",
    tw_global: "5,6",
    z: 255,
    z_global: 255000,
  },
  {
    profile_section_id: 7170,
    name: "PIP2191H",
    type: "Pipe",
    designation: "PIP2191H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "39,5",
    ax_global: 3950,
    d_global: "219,1",
    i: 2247,
    i_global: 22470000,
    ix: 2247,
    ix_global: 22470000,
    iy: 2247,
    iy_global: 22470000,
    iz: 2247,
    iz_global: 22470000,
    od: "219,1",
    od_global: "219,1",
    t_global: "5,9",
    tw: "5,9",
    tw_global: "5,9",
    z: 268,
    z_global: 268000,
  },
  {
    profile_section_id: 7171,
    name: "PIP2445H",
    type: "Pipe",
    designation: "PIP2445H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "44,2",
    ax_global: 4420,
    d_global: "244,5",
    i: 3149,
    i_global: 31490000,
    ix: 3149,
    ix_global: 31490000,
    iy: 3149,
    iy_global: 31490000,
    iz: 3149,
    iz_global: 31490000,
    od: "244,5",
    od_global: "244,5",
    t_global: "5,9",
    tw: "5,9",
    tw_global: "5,9",
    z: 336,
    z_global: 336000,
    thickness: "5,9",
    thickness_global: "5,9",
  },
  {
    profile_section_id: 7172,
    name: "PIP2730H",
    type: "Pipe",
    designation: "PIP2730H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "49,5",
    ax_global: 4950,
    d_global: 273,
    i: 4417,
    i_global: 44170000,
    ix: 4417,
    ix_global: 44170000,
    iy: 4417,
    iy_global: 44170000,
    iz: 4417,
    iz_global: 44170000,
    od: 273,
    od_global: 273,
    t_global: "5,9",
    tw: "5,9",
    tw_global: "5,9",
    z: 421,
    z_global: 421000,
  },
  {
    profile_section_id: 7173,
    name: "PIP3239H",
    type: "Pipe",
    designation: "PIP3239H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "62,9",
    ax_global: 6290,
    d_global: "323,9",
    i: 7929,
    i_global: 79290000,
    ix: 7929,
    ix_global: 79290000,
    iy: 7929,
    iy_global: 79290000,
    iz: 7929,
    iz_global: 79290000,
    od: "323,9",
    od_global: "323,9",
    t_global: "6,3",
    tw: "6,3",
    tw_global: "6,3",
    z: 636,
    z_global: 636000,
  },
  {
    profile_section_id: 7174,
    name: "PIP3556H",
    type: "Pipe",
    designation: "PIP3556H",
    shape: "PIPE",
    country_code: "Indian",
    ax: "87,4",
    ax_global: 8740,
    d_global: "355,6",
    i: 13201,
    i_global: 132010000,
    ix: 13201,
    ix_global: 132010000,
    iy: 13201,
    iy_global: 132010000,
    iz: 13201,
    iz_global: 132010000,
    od: "355,6",
    od_global: "355,6",
    t_global: 8,
    tw: 8,
    tw_global: 8,
    z: 967,
    z_global: 967000,
  },
  {
    profile_section_id: 9713,
    name: "c1",
    type: "Pipe",
    designation: "c1",
    shape: "PIPE",
    country_code: "Indian",
    ax_global: "29837,6",
    d_global: "609,6",
    i_global: 2630310000,
    ix_global: 2630310000,
    iy_global: 2630310000,
    iz_global: 2630310000,
    thickness: 16,
  },
  {
    profile_section_id: 9714,
    name: "c2",
    type: "Pipe",
    designation: "c2",
    shape: "PIPE",
    country_code: "Indian",
    ax_global: "27243,9",
    d_global: "558,44",
    i_global: 2002560000,
    ix_global: 2002560000,
    iy_global: 2002560000,
    iz_global: 2002560000,
    thickness: 16,
  },
  {
    profile_section_id: 9715,
    name: "PIP610H",
    type: "Pipe",
    designation: "PIP610H",
    shape: "PIPE",
    country_code: "Indian",
    ax_global: "29837,6",
    d_global: "165,1",
    i_global: 733,
    ix_global: 2630310000,
    iy_global: 1315150000,
    iz_global: 1315150000,
    od_global: 610,
    t_global: 16,
    z_global: 116,
  },
  {
    profile_section_id: 9716,
    name: "PIP356H",
    type: "Pipe",
    designation: "PIP356H",
    shape: "PIPE",
    country_code: "Indian",
    ax_global: "27243,9",
    d_global: "165,1",
    i_global: 733,
    ix_global: 2002560000,
    iy_global: 1001280000,
    iz_global: 1001280000,
    od_global: 356,
    t_global: 12,
    z_global: 116,
  },
  {
    profile_section_id: 9717,
    name: "PIP558H",
    type: "Pipe",
    designation: "PIP558H",
    shape: "PIPE",
    country_code: "Indian",
    ax_global: "12953,4",
    d_global: "165,1",
    i_global: 733,
    ix_global: 382789000,
    iy_global: 191395000,
    iz_global: 191395000,
    od_global: 558,
    t_global: 16,
    z_global: 116,
  },
  {
    profile_section_id: 9718,
    name: "PIP273H",
    type: "Pipe",
    designation: "PIP273H",
    shape: "PIPE",
    country_code: "Indian",
    ax_global: "12953,4",
    d_global: "165,1",
    i_global: 733,
    ix_global: 167923000,
    iy_global: 83961400,
    iz_global: 83961400,
    od_global: 558,
    t_global: 12,
    z_global: 116,
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: p.ax ? fixValueToNumber(p.ax, "float") : null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: p.b ? fixValueToNumber(p.b, "float") : null,
    b_global: p.b_global ? fixValueToNumber(p.b_global, "float") : undefined,
    bf: p.bf ? fixValueToNumber(p.bf, "float") : null,
    bf_global: p.bf_global ? fixValueToNumber(p.bf_global, "float") : undefined,
    c: null,
    ct: p.ct ? fixValueToNumber(p.ct, "float") : null,
    ct_global: p.ct_global ? fixValueToNumber(p.ct_global, "float") : undefined,
    d: p.d ? fixValueToNumber(p.d, "float") : null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: null,
    i: p.i ? fixValueToNumber(p.i, "float") : null,
    i_global: p.i_global ? fixValueToNumber(p.i_global, "float") : undefined,
    ix: p.ix ? fixValueToNumber(p.ix, "float") : null,
    ix_global: p.ix_global ? fixValueToNumber(p.ix_global, "float") : undefined,
    iy: p.iy ? fixValueToNumber(p.iy, "float") : null,
    iy_global: p.iy_global ? fixValueToNumber(p.iy_global, "float") : undefined,
    iz: p.iz ? fixValueToNumber(p.iz, "float") : null,
    iz_global: p.iz_global ? fixValueToNumber(p.iz_global, "float") : undefined,
    k: null,
    k1: null,
    od: p.od ? fixValueToNumber(p.od, "float") : null,
    od_global: p.od_global ? fixValueToNumber(p.od_global, "float") : undefined,
    r1: p.r1 ? fixValueToNumber(p.r1, "float") : null,
    r1_global: p.r1_global ? fixValueToNumber(p.r1_global, "float") : undefined,
    r2: p.r2 ? fixValueToNumber(p.r2, "float") : null,
    r2_global: p.r2_global ? fixValueToNumber(p.r2_global, "float") : undefined,
    rz: p.rz ? fixValueToNumber(p.rz, "float") : null,
    rz_global: p.rz_global ? fixValueToNumber(p.rz_global, "float") : undefined,
    t: p.t ? fixValueToNumber(p.t, "float") : null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: p.tf ? fixValueToNumber(p.tf, "float") : null,
    tf_global: p.tf_global ? fixValueToNumber(p.tf_global, "float") : undefined,
    tw: p.tw ? fixValueToNumber(p.tw, "float") : null,
    tw_global: p.tw_global ? fixValueToNumber(p.tw_global, "float") : undefined,
    z: p.z ? fixValueToNumber(p.z, "float") : null,
    z_global: p.z_global ? fixValueToNumber(p.z_global, "float") : undefined,
    zx: p.zx ? fixValueToNumber(p.zx, "float") : null,
    zx_global: p.zx_global ? fixValueToNumber(p.zx_global, "float") : undefined,
    zy: p.zy ? fixValueToNumber(p.zy, "float") : null,
    zy_global: p.zy_global ? fixValueToNumber(p.zy_global, "float") : undefined,
    zz: null,
    width: fixValueToNumber(p.width, "float"),
    width_global: fixValueToNumber(p.width_global, "float"),
    thickness: fixValueToNumber(p.thickness, "float"),
    thickness_global: p.thickness_global
      ? fixValueToNumber(p.thickness_global, "float")
      : undefined,
    height: fixValueToNumber(p.height, "float"),
    height_global: fixValueToNumber(p.height_global, "float"),
  };
  return profile;
});
