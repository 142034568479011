import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useRecoilState } from "recoil";
import {
  mousePipeCreating,
  TBellowParams,
  TPipeSegmentParams,
} from "../../recoil/atoms/process-atoms";
import { ApplicationState } from "../../store";
import { Material } from "../../store/data/types";
import { TLongWeldType } from "../../store/main/types";
import { EPipeElementType } from "../../store/process/types";
import { getUnicuesArray } from "../3d-models/utils";
import { CustomDlg } from "../common/CustomDlg";
import { SimpleNumericInput } from "../common/SimpleNumericInput";
import { SimpleSelector } from "../common/SimpleSelector";

export function SimpleBellowDetails() {
  const resoures = useSelector((state: ApplicationState) => state.data);

  const [MPCState, setMPCState] = useRecoilState(mousePipeCreating);

  const params = useMemo(() => {
    return MPCState.pipeSegmentParams;
  }, [MPCState.pipeSegmentParams]);

  const bellowParams = useMemo(() => {
    return MPCState.bellowParams;
  }, [MPCState.bellowParams]);

  useEffect(() => {
    if (params.lib && params.nps && params.schedule) {
      const profile = resoures.pipingSS.find(
        (p) =>
          p.country_code === params.lib &&
          p.nominal_pipe_size_inch === params.nps &&
          p.schedule === params.schedule
      );
      setMPCState({ ...MPCState, pipeSegmentParams: { ...params, profile } });
    }
  }, [params.lib, params.nps, params.schedule, resoures.pipingSS]);

  const filteredProfiles = useMemo(() => {
    return resoures.pipingSS.filter(
      (p) => p.outside_diameter_global && p.wall_thickness_global
    );
  }, [resoures.pipingSS]);

  const libs = useMemo(() => {
    return getUnicuesArray(
      filteredProfiles.map((profile) => profile.country_code?.trim() ?? "")
    );
  }, [filteredProfiles]);

  const NPSs = useMemo(() => {
    if (params.lib) {
      return getUnicuesArray(
        filteredProfiles
          .filter((p) => p.country_code === params.lib)
          .map((profile) => profile.nominal_pipe_size_inch)
      );
    } else return [];
  }, [filteredProfiles, params.lib]);

  useEffect(() => {
    setMPCState((prev) => ({
      ...prev,
      pipeSegmentParams: { ...prev.pipeSegmentParams, nps: undefined },
    }));
  }, [params.lib]);

  useEffect(() => {
    setMPCState((prev) => ({
      ...prev,
      pipeSegmentParams: { ...prev.pipeSegmentParams, schedule: undefined },
    }));
  }, [params.nps]);

  useEffect(() => {
    if (params.schedule) return;
    setMPCState((prev) => ({
      ...prev,
      pipeSegmentParams: { ...prev.pipeSegmentParams, profile: undefined },
    }));
  }, [params.schedule]);

  function handleChangePipeSegmentParams(params: TPipeSegmentParams) {
    setMPCState({
      ...MPCState,
      pipeSegmentParams: { ...params },
    });
  }
  function handleChangeBellowParams(params: TBellowParams) {
    setMPCState({
      ...MPCState,
      bellowParams: { ...params },
    });
  }

  useEffect(() => {
    if (bellowParams?.d_eff === 0) {
      setMPCState({
        ...MPCState,
        bellowParams: {
          ...MPCState.bellowParams,
          d_eff: parseInt(MPCState.pipeSegmentParams.nps ?? "0") * 0.0254,
        },
      });
    }
  }, [bellowParams?.d_eff]);

  return MPCState.processPipeElement === EPipeElementType.BELLOW ? (
    <CustomDlg
      zIndex={10}
      isMinimize={true}
      title={"Bellow Detail"}
      body={
        <div className="d-flex f-grow f-column bg-dark">
          <div className="hr" />
          <div className="p-5">
            <div className={"d-flex f-ai-center bg-gray p-end-10"}>
              <div className="label-light p-start-10" style={{ minWidth: 100 }}>
                С/S Lib.
              </div>
              <SimpleSelector<string>
                items={libs}
                selected={params.lib}
                onSelect={(lib) =>
                  handleChangePipeSegmentParams({ ...params, lib })
                }
                autoFocus={true}
                itemLabel={(val) => val}
                className={`fill-select w-150`}
              />
            </div>
            <div className={"d-flex f-ai-center bg-gray p-end-10"}>
              <div className="label-light p-start-10" style={{ minWidth: 100 }}>
                NPS
              </div>
              <SimpleSelector<string>
                items={NPSs}
                selected={params.nps}
                onSelect={(nps) =>
                  handleChangePipeSegmentParams({ ...params, nps })
                }
                autoFocus={true}
                itemLabel={(val) => val}
                className={`fill-select w-150`}
              />
            </div>
            <div className={"d-flex f-ai-center bg-gray p-end-10"}>
              <div className="label-light p-start-10" style={{ minWidth: 100 }}>
                K axial
              </div>
              <SimpleNumericInput
                min={0}
                value={bellowParams?.k_axial}
                onChange={(k_axial: number) =>
                  handleChangeBellowParams({
                    ...(bellowParams ? bellowParams : {}),
                    k_axial,
                  })
                }
              />
              <div className="label-light w-mc p-start-10">mm</div>
            </div>
            <div className={"d-flex f-ai-center bg-gray p-end-10"}>
              <div className="label-light p-start-10" style={{ minWidth: 100 }}>
                K bending
              </div>
              <SimpleNumericInput
                min={0}
                value={bellowParams?.k_bending}
                onChange={(k_bending: number) =>
                  handleChangeBellowParams({
                    ...(bellowParams ? bellowParams : {}),
                    k_bending,
                  })
                }
              />
              <div className="label-light w-mc p-start-10">mm</div>
            </div>
            <div className={"d-flex f-ai-center bg-gray p-end-10"}>
              <div className="label-light p-start-10" style={{ minWidth: 100 }}>
                K lateral
              </div>
              <SimpleNumericInput
                min={0}
                value={bellowParams?.k_lateral}
                onChange={(k_lateral: number) =>
                  handleChangeBellowParams({
                    ...(bellowParams ? bellowParams : {}),
                    k_lateral,
                  })
                }
              />
              <div className="label-light w-mc p-start-10">mm</div>
            </div>
            <div className={"d-flex f-ai-center bg-gray p-end-10"}>
              <div className="label-light p-start-10" style={{ minWidth: 100 }}>
                K torsion
              </div>
              <SimpleNumericInput
                min={0}
                value={bellowParams?.k_torsion}
                onChange={(k_torsion: number) =>
                  handleChangeBellowParams({
                    ...(bellowParams ? bellowParams : {}),
                    k_torsion,
                  })
                }
              />
              <div className="label-light w-mc p-start-10">mm</div>
            </div>
            <div className={"d-flex f-ai-center bg-gray p-end-10"}>
              <div className="label-light p-start-10" style={{ minWidth: 100 }}>
                Mass
              </div>
              <SimpleNumericInput
                min={0}
                value={bellowParams?.mass}
                onChange={(mass: number) =>
                  handleChangeBellowParams({
                    ...(bellowParams ? bellowParams : {}),
                    mass,
                  })
                }
              />
              <div className="label-light w-mc p-start-10">mm</div>
            </div>
            <div className={"d-flex f-ai-center bg-gray p-end-10"}>
              <div className="label-light p-start-10" style={{ minWidth: 100 }}>
                D eff
              </div>
              <SimpleNumericInput
                min={0}
                value={bellowParams?.d_eff}
                onChange={(d_eff: number) =>
                  handleChangeBellowParams({
                    ...(bellowParams ? bellowParams : {}),
                    d_eff,
                  })
                }
              />
              <div className="label-light w-mc p-start-10">mm</div>
            </div>
          </div>
        </div>
      }
      onClose={() =>
        setMPCState({ ...MPCState, processPipeElement: undefined })
      }
    />
  ) : null;
}
