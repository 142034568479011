import {
  TProcessState,
  TProcess,
  TProcessElement,
  TProcessPipeType,
  TInstrumentationLineType,
} from "./types";

const pipeLineTypes: TProcessPipeType[] = [
  "Process Flow Line",
  "Pneumatic (Air) Line",
  "Hydraulic Line",
  "Inert gas line",
  "Electric heat tracing",
  "Steam heat tracing",
];

const intstrLineTypes: TInstrumentationLineType[] = [
  "Instrument signal",
  "Instrument capillary",
  "Electrical wires",
];

const processInitialState: TProcessState = {
  processes: new Map<string, TProcess>(),
};

const initialProcess: TProcess = {
  elements: new Map<string, TProcessElement>(),
  analysis: {
    energyBalances: [],
    heatExchangers: [],
    sourceAndSinkSummary: {
      massFlow: { desc: "Mass Flow", label: "W", UOM: "kg/s" },
      moleFlow: { desc: "Mole Flow", label: "F", UOM: "mol/s" },
      volumetricFlow: { desc: "Volumetric Flow", label: "Q", UOM: "m^3/s" },
      pressure: { desc: "Pressure", label: "P", UOM: "Pa" },
      temperature: { desc: "Temperature", label: "T", UOM: "K" },
      vaporFracton: { desc: "Vapor Fracton", label: "State.VF", UOM: "-" },
      composition1: { desc: "Composition", label: "z[E1]", UOM: "-" },
      composition2: { desc: "", label: "z[E2]", UOM: "-" },
      streamHeatFlow: { desc: "Stream heat flow", label: "Fh", UOM: "kj/s" },
    },
    mixerAndSplitSummary: {
      volumen: { desc: "Volumen", label: "V", UOM: "" },
      diameter: { desc: "Diameter", label: "D", UOM: "" },
      orientation: { desc: "Orientation", label: "Vert/Horizontal", UOM: "-" },
      pressure: { desc: "Pressure", label: "P", UOM: "" },
      ratioSum: { desc: "Ratio Sum", label: "Ratio Sum", UOM: "" },
    },
    heaterAndCoolerSummary: {
      massFlow: { desc: "Mass Flow", label: "W", UOM: "" },
      volumen: { desc: "Volumen", label: "V", UOM: "" },
      diameter: { desc: "Diameter", label: "Dc", UOM: "" },
      tMin: { desc: "Number tubes min", label: "Nt_min", UOM: "-" },
      tMax: { desc: "Number tubes max", label: "Nt_max", UOM: "-" },
      vf: { desc: "Vapor fraction", label: "Vfo", UOM: "-" },
      p: { desc: "Pressure", label: "P", UOM: "" },
      dp: { desc: "Pressure drope", label: "DP", UOM: "" },
      t1: { desc: "Temperature in", label: "T1", UOM: "" },
      t2: { desc: "Temperature out", label: "T2", UOM: "" },
      duty: { desc: "Heating/Cooling", label: "Duty", UOM: "kW" },
    },
    headerSummary: {
      volumen: { desc: "Volumen", label: "V", UOM: "" },
      pressure: { desc: "Pressure", label: "P", UOM: "" },
      residenceTime: { desc: "Residence time", label: "tau", UOM: "s" },
      heatDuty: { desc: "Heat duty", label: "Duty", UOM: "kj/s" },
    },
    drumSummary: {
      length: { desc: "Length", label: "L", UOM: "m" },
      diameter: { desc: "Diameter", label: "D", UOM: "m" },
      pressure: { desc: "Pressure", label: "P", UOM: "" },
      temperature: { desc: "Temperature", label: "T", UOM: "" },
      vaporFracton: { desc: "Vapor Fracton", label: "State.VF", UOM: "-" },
      levelFraction: { desc: "Level fraction", label: "Level", UOM: "m" },
    },
    separatorSummary: {
      length: { desc: "Length", label: "L", UOM: "m" },
      diameter: { desc: "Diameter", label: "D", UOM: "m" },
      pressure: { desc: "Pressure", label: "P", UOM: "kPa" },
      temperature: { desc: "Temperature", label: "T", UOM: "k" },
      lengthOfBoot: { desc: "Separator length of boot", label: "Lboot", UOM: "m" },
      diameterOfBoot: { desc: "Separator diameter of boot", label: "Dboot", UOM: "m" },
    },
    compressorSummary: {
      pressure1: { desc: "Pressure 1", label: "P1", UOM: "" },
      pressure2: { desc: "Pressure 2", label: "P2", UOM: "" },
      pressureDrop: { desc: "Pressure Drop", label: "DP", UOM: "" },
      pressureRatio: { desc: "Pressure ratio", label: "Pr", UOM: "" },
      temperature1: { desc: "Temperature 1", label: "T1", UOM: "" },
      temperature2: { desc: "Temperature 2", label: "T2", UOM: "" },
      powerGenerate: { desc: "Power generate", label: "Power", UOM: "kW" },
      rotationSpeed: { desc: "Rotation Speed", label: "speed", UOM: "rpm" },
      volumentricFlow: { desc: "Volumentric Flow", label: "Q", UOM: "" },
      molarFlow: { desc: "Molar flow", label: "F", UOM: "" },
      massFlow: { desc: "Mass flow", label: "W", UOM: "" },
      head: { desc: "Head", label: "Dheat", UOM: "kj/kg" },
      area: { desc: "Area", label: "A", UOM: "" },
      efficiency: { desc: "Efficiency", label: "eta", UOM: "" },
      thermalOperation: {
        desc: "Thermal operation",
        label: "operation",
        UOM: "adiabatic/isothermal",
      },
    },
    pfrSummary: {
      thermalOperation: {
        desc: "Thermal operation",
        label: "operation",
        UOM: "adiabatic/isothermal",
      },
      numberOfTubes: { desc: "Number of tubes", label: "Nt", UOM: "" },
      length: { desc: "Length", label: "L", UOM: "" },
      diameter: { desc: "Diameter", label: "D", UOM: "" },
      volumen: { desc: "Volumen", label: "V", UOM: "" },
      residenceTime: { desc: "Residence time", label: "tau", UOM: "" },
      basisReactorRate: { desc: "Basis reactor rate", label: "basis", UOM: "" },
      catalystLoading: { desc: "Catalyst loading", label: "Mc", UOM: "kg" },
      catalystParticleDensity: { desc: "Catalyst particle density", label: "rhoc", UOM: "kg/m3" },
      catalystVoidFraction: { desc: "Catalyst void fraction", label: "eps", UOM: "-" },
      catalystHeatCapacity: { desc: "Catalyst heat capacity", label: "Cpc", UOM: "kJ/kg*K" },
      pressureDrope: { desc: "Pressure drope", label: "DP", UOM: "" },
      deltaT: { desc: "Delta T", label: "DT", UOM: "" },
      reactorHeatDuty: { desc: "Reactor heat Duty", label: "Duty", UOM: "kj/s" },
      conversion: { desc: "Conversion", label: "", UOM: "" },
    },
    RESummary: {
      thermalOperation: { desc: "Thermal operation", label: "operation", UOM: "" },
      length: { desc: "Length", label: "L", UOM: "" },
      diameter: { desc: "Diameter", label: "D", UOM: "" },
      volumen: { desc: "Volumen", label: "V", UOM: "" },
      residenceTime: { desc: "Residence time", label: "tau", UOM: "" },
      pressure: { desc: "Pressure", label: "P", UOM: "" },
      pressureDrope: { desc: "Pressure drope", label: "DP", UOM: "" },
      temperature: { desc: "Temperature", label: "T", UOM: "" },
      temperatureDrope: { desc: "Temperature drope", label: "DT", UOM: "" },
      reactorHeatDuty: { desc: "Reactor heat Duty", label: "Duty", UOM: "kj/s" },
      equilibriumConversion: { desc: "Equilibtrium Conversion", label: "X", UOM: "" },
    },
    distillationColumnSummary: {
      nstage: { desc: "Nstage", label: "Nstage", UOM: "-" },
      feedStage: { desc: "Feed stage", label: "Feed stage", UOM: "-" },
      diameter: { desc: "Diameter", label: "Dcol", UOM: "m" },
      height: { desc: "Height", label: "Hcol", UOM: "m" },
      pressure: { desc: "Pressure", label: "P", UOM: "Pa" },
      stagePressureDrop: { desc: "Stage Pressure drop", label: "DPstage", UOM: "" },
      temperature: { desc: "Temperature", label: "T", UOM: "K" },
      efficiency: { desc: "Efficiency", label: "Contact", UOM: "-" },
      refluxRatio: { desc: "RefluxRatio", label: "RefluxRatio", UOM: "-" },
      boilupRatio: { desc: "BoilupRatio", label: "BoilupRatio", UOM: "-" },
      condencerTemperature: { desc: "Condencer Temperature", label: "Tcond", UOM: "" },
      condencerPressureTop: { desc: "Condencer Pressure Top", label: "Ptop", UOM: "" },
      condencerDuty: { desc: "Condencer Duty", label: "Duty", UOM: "kj/s" },
      condencerDiameter: { desc: "Condencer Diameter", label: "Dcond", UOM: "m" },
      condencerLength: { desc: "Condencer Length", label: "Lcond", UOM: "m" },
      reboilerSectionTemperature: { desc: "Reboiler Section Temperature", label: "Treb", UOM: "" },
      reboilerSectionPressureBottom: {
        desc: "Reboiler Section Pressure Bottom",
        label: "Pbot",
        UOM: "",
      },
      reboilerSectionDuty: { desc: "Reboiler Section Duty", label: "Duty", UOM: "kj/s" },
      reboilerSectionDiameter: { desc: "Reboiler Section Diameter", label: "Dreb", UOM: "m" },
      reboilerSectionLength: { desc: "Reboiler Section Length", label: "Lreb", UOM: "m" },
    },
    extractorSummary: {
      nstage: { desc: "Nstage", label: "Nstage", UOM: "-" },
      diameter: { desc: "Diameter", label: "Dcol", UOM: "m" },
      height: { desc: "Height", label: "Hcol", UOM: "m" },
      pressure: { desc: "Pressure", label: "P", UOM: "" },
      stagePDrop: { desc: "Stage P drop", label: "DPstage", UOM: "" },
      temperature: { desc: "Temperature", label: "T", UOM: "" },
      efficiency: { desc: "Efficiency", label: "Contact", UOM: "-" },
      productRateL1: { desc: "Product rate L1", label: "L1ovhd", UOM: "kg/h" },
      productRateL2: { desc: "Product rate L2", label: "L2btms", UOM: "kg/h" },
    },
    tankSummary: {
      length: { desc: "Length", label: "L", UOM: "m" },
      diameter: { desc: "Diameter", label: "D", UOM: "m" },
      volumen: { desc: "Volumen", label: "V", UOM: "" },
      pressure: { desc: "Pressure", label: "P", UOM: "" },
      temperature: { desc: "Temperature", label: "T", UOM: "" },
      fluidTimeResidence: { desc: "Fluid time residence", label: "tau", UOM: "s" },
    },
    expanderSummary: {
      pressure1: { desc: "Pressure 1", label: "P1", UOM: "" },
      pressure2: { desc: "Pressure 2", label: "P2", UOM: "" },
      pressureDrop: { desc: "Pressure Drop", label: "DP", UOM: "" },
      temperature1: { desc: "Temperature 1", label: "T1", UOM: "" },
      temperature2: { desc: "Temperature 2", label: "T2", UOM: "" },
      powerGenerate: { desc: "Power generate", label: "Power", UOM: "kW" },
      rotationSpeed: { desc: "Rotation Speed", label: "speed", UOM: "rpm" },
      volumentricFlow: { desc: "Volumentric Flow", label: "Q", UOM: "" },
      area: { desc: "Area", label: "A", UOM: "" },
      efficiency: { desc: "Efficiency", label: "eta", UOM: "-" },
      thermalOperation: {
        desc: "Thermal operation",
        label: "operation",
        UOM: "adiabatic/isothermal",
      },
    },
    PSVSummary: {
      massFlow: { desc: "Mass Flow", label: "W", UOM: "" },
      setPressureDiff: { desc: "set pressure dif", label: "SP", UOM: "" },
      backPressure: { desc: "back pressure", label: "Pb", UOM: "" },
      orificeArea: { desc: "Orifice area", label: "A", UOM: "" },
      overPressure: { desc: "over pressure", label: "OP", UOM: "" },
    },
    enlargerSummary: {
      upstreamDiameter: { desc: "upstream Diameter", label: "W", UOM: "" },
      downstreamDiameter: { desc: "downstream Diameter", label: "SP", UOM: "" },
      enlargerAngle: { desc: "enlarger angle", label: "theta", UOM: "" },
      pressureDrop: { desc: "pressure drop", label: "DP", UOM: "" },
      heatLoss: { desc: "Heat loss", label: "K", UOM: "" },
    },
    CSTRSummary: {
      thermalOperation: { desc: "Thermal operation", label: "operation", UOM: "" },
      length: { desc: "Length", label: "L", UOM: "" },
      diameter: { desc: "Diameter", label: "D", UOM: "" },
      volumen: { desc: "Volumen", label: "V", UOM: "" },
      residenceTime: { desc: "Residence time", label: "tau", UOM: "" },
      basisReactorTime: { desc: "Basis reactor rate", label: "basis", UOM: "" },
      catalystLoading: { desc: "catalyst loading", label: "Mc", UOM: "kg" },
      catalystParticleDensity: { desc: "catalyst particle density", label: "rhoc", UOM: "kg/m3" },
      catalystVoidFraction: { desc: "catalyst void fraction", label: "eps", UOM: "-" },
      catalystHeatCapacity: { desc: "catalyst heat capacity", label: "Cpc", UOM: "kJ/kg*K" },
      pressure: { desc: "Pressure", label: "P", UOM: "" },
      temperature: { desc: "Temperature", label: "T", UOM: "" },
      temperatureDrope: { desc: "Temperature drope", label: "DT", UOM: "" },
      reactorHeatDuty: { desc: "Reactor heat Duty", label: "Duty", UOM: "kj/s" },
      conversion: { desc: "Conversion", label: "", UOM: "" },
      vaporFraction: { desc: "Vapor Fraction", label: "VF", UOM: "" },
    },
    RCSummary: {
      thermalOperation: { desc: "Thermal operation", label: "operation", UOM: "" },
      length: { desc: "Length", label: "L", UOM: "" },
      diameter: { desc: "Diameter", label: "D", UOM: "" },
      volumen: { desc: "Volumen", label: "V", UOM: "" },
      residenceTime: { desc: "Residence time", label: "tau", UOM: "" },
      pressure: { desc: "Pressure", label: "P", UOM: "" },
      pressureDrope: { desc: "Pressure Drope", label: "DP", UOM: "" },
      temperature: { desc: "Temperature", label: "T", UOM: "" },
      temperatureDrope: { desc: "Temperature Drope", label: "DT", UOM: "" },
      reactorHeatDuty: { desc: "Reactor heat Duty", label: "Duty", UOM: "kj/s" },
      conversion: { desc: "Conversion", label: "X", UOM: "" },
    },
    STHE2PSummary: {
      oec: { desc: "overal exchange coefficient", label: "U", UOM: "" },
      area: { desc: "area", label: "A", UOM: "" },
      tc: { desc: "Thermal conductivity", label: "k", UOM: "W/m*k" },
      duty: { desc: "Duty", label: "Duty", UOM: "" },
      efficiency: { desc: "Efficiency", label: "eps", UOM: "-" },
      vssf: { desc: "Volumen shell side fluid", label: "Vs", UOM: "" },
      vtsf: { desc: "Volumen tube side fluid", label: "Vt", UOM: "" },
      tsi: { desc: "Temperature shell in", label: "Tsi", UOM: "" },
      tso: { desc: "Temperature shell out", label: "Tso", UOM: "" },
      tti: { desc: "Temperature tube in", label: "Tti", UOM: "" },
      tto: { desc: "Temperature tube out", label: "Tto", UOM: "" },
      vfso: { desc: "Shell outlet Vapor fraction", label: "VFso", UOM: "" },
      vfto: { desc: "Tubes outlet Vapor fraction", label: "VFto", UOM: "" },
      shellPasses: { desc: "Shell passes", label: "-", UOM: "-" },
      shellDin: { desc: "Shell internal diameter", label: "Din", UOM: "mm" },
      shellRfi: { desc: "Shell fauling factor", label: "Rfi", UOM: "K*m^2/W" },
      shellBaffleSpacing: { desc: "Shell Baffle Spacing", label: "-", UOM: "mm" },
      tubesDin: { desc: "Tubes internal diameter", label: "Din", UOM: "mm" },
      tubesDext: { desc: "Tubes external diameter", label: "Dext", UOM: "mm" },
      tubesL: { desc: "Tubes length", label: "Lt", UOM: "m" },
      tubesRfo: { desc: "Tubes fauling factor", label: "Rfo", UOM: "K*m^2/W" },
      tubesR: { desc: "Tubes roughness", label: "&#955;", UOM: "mm" },
      tubesPerShell: { desc: "Tubes per shell", label: "-", UOM: "" },
      tubesSpacing: { desc: "Tubes spacing", label: "-", UOM: "mm" },
      tubeLayour: { desc: "Tube layour", label: "-", UOM: "triangle/square" },
      fluidInTubes: { desc: "fluid in Tubes", label: "hot/cold", UOM: "" },
    },
    pumpSummary: {
      massFlow: { desc: "Mass Flow", label: "W", UOM: "" },
      molarFlow: { desc: "Molar Flow", label: "Out.F", UOM: "" },
      pressureRise: { desc: "Pressure Rise", label: "Dp", UOM: "" },
      inletPressure: { desc: "Inlet Pressure", label: "P1", UOM: "" },
      outletPressure: { desc: "Outlet Pressure", label: "P2", UOM: "" },
      volumetricFlow: { desc: "Volumetric Flow", label: "Q", UOM: "" },
      head: { desc: "Head", label: "Head", UOM: "" },
      speed: { desc: "Speed", label: "Speed", UOM: "" },
      efficiency: { desc: "Efficiency", label: "eta", UOM: "" },
      power: { desc: "Power", label: "Power", UOM: "" },
    },
    valveSummary: {
      massFlow: { desc: "Mass Flow", label: "W", UOM: "" },
      flowCoefficient: { desc: "Flow Coefficient", label: "Cv", UOM: "" },
      position: { desc: "Position", label: "Pos", UOM: "" },
      vaporFraction: { desc: "Vapor Fraction", label: "OutState.VF", UOM: "" },
      pressureDrop: { desc: "Pressure Drop", label: "DP", UOM: "" },
      flowType: { desc: "Flow Type", label: "FlowType", UOM: "" },
    },
    AAMSummary: {
      material: { desc: "Material", label: "", UOM: "" },
      type:{ desc: "Type", label: "", UOM: "" },
      temperature_mix_operating: { desc: "Temperature mix Operations", label: "", UOM: "F" },
      temperature_mix_design: { desc: "Tempoerature mix Design", label: "", UOM: "F" },
      pressure_operating: { desc: "Pressure Operating", label: "", UOM: "psig" },
      pressure_design: { desc: "Pressure Design", label: "", UOM: "psig" },
      normal_operating_gas_flow_ammonia: { desc: "Normal Operating Gas Flow Ammonia", label: "", UOM: "lb/hr" },
      normal_operating_gas_flow_ammonia_attemp: { desc: "Normal Operating Gas Flow Temperature", label: "", UOM: "F" },
      normal_operating_gas_flow_air: { desc: "Normal Operating Gas Flow Air", label: "", UOM: "lb/hr" },
      normal_operating_gas_flow_air_attemp: { desc: "Normal Operating Gas Flow Air Temperature", label: "", UOM: "F" },
      allowable_pressure_drop: { desc: "Allowable Pressure Drop", label: "", UOM: "psid" },
      materials_of_construction_Shell_and_internal_comps :  { desc: "Material of Construction Shell and Internal Components", label: "", UOM: "" },
      materials_of_construction_flanges : { desc: "Material of Construction Flanges", label: "", UOM: "" },
      code_requirements : { desc: "Code Requirement", label: "", UOM: "" },
    },
    TAMSummary: {
      material_handled: { desc: "Material", label: "", UOM: "" },
      type:{ desc: "Type", label: "", UOM: "" },
      temperature_mix_operating: { desc: "Temperature mix Operations", label: "", UOM: "F" },
      temperature_mix_design: { desc: "Tempoerature mix Design", label: "", UOM: "F" },
      pressure_operating: { desc: "Pressure Operating", label: "", UOM: "psig" },
      pressure_design: { desc: "Pressure Design", label: "", UOM: "psig" },
      normal_operating_gas_flows_ammonia: { desc: "Normal Operating Gas Flow Ammonia", label: "", UOM: "lb/hr" },
      normal_operating_gas_flows_ammonia_attemp: { desc: "Normal Operating Gas Flow Ammonia Temperature", label: "", UOM: "F" },
      normal_operating_gas_flows_tail_gas: { desc: "Normal Operating Gas Flow Tail Gas", label: "", UOM: "lb/hr" },
      normal_operating_gas_flows_tail_gas_attemp: { desc: "Normal Operating Gas Flow Tail Gas Temperature", label: "", UOM: "F" },
      mixing: { desc: "Mixing", label: "", UOM: ""},
      sample_size: { desc: "Sample Size", label: "", UOM: "d/D"},
      allowable_pressure_drop: { desc: "Allowable Pressure Drop", label: "", UOM: "psid" },
      materials_of_construction_Shell_and_internal_comps :  { desc: "Material of Construction Shell and Internal Components", label: "", UOM: "" },
      materials_of_construction_flanges : { desc: "Material of Construction Flanges", label: "", UOM: "" },
      code_requirements : { desc: "Code Requirement", label: "", UOM: "" },
    },
    ACSummary: {
      material_handled: { desc: "Material Handled", label: "", UOM: "" },
      diameter: { desc: "Diameter", label: "", UOM: "in" },
      height: { desc: "Height", label: "", UOM: "in" },
      design_pressure: { desc: "Design Pressure", label: "", UOM: "psig" },
      design_temp: { desc: "Design Temperature", label: "", UOM: "F" },
      tray_spacing: { desc: "Tray Spacing", label: "", UOM: "" },
      no_of_absorption_tray: { desc: "No of Absorption Tray", label: "", UOM: "" },
      no_of_bleach_trays: { desc: "No of Bleach Trays", label: "", UOM: "" },
      corrosion_allowance: { desc: "Corrosion Allowance", label: "", UOM: "" },
      operating_pressure: { desc: "Operating Pressure", label: "", UOM: "psig" },
      operating_temp: { desc: "Operating Temperature", label: "", UOM: "F" },
      earthquake_zone: { desc: "EarthQuake Zone", label: "", UOM: "" },
      windload:{ desc: "Wind Load", label: "", UOM: "mph per ANSI-A 58.1" },
      tray_type: { desc: "Tray Type", label: "", UOM: "" },
      no_of_caps: { desc: "No. of Caps", label: "", UOM: "Tray" },
      pitch: { desc: "Pitch", label: "", UOM: "in Stg" },
      no_of_rows: { desc: "No of Rows", label: "", UOM: "" },
      weir_height: { desc: "Weir Height", label: "", UOM: "in" },
      weephole: { desc: "WeepHole", label: "", UOM: "" },
      level_max: { desc: "Level Max", label: "", UOM: "in" },
      downcomer: { desc: "Down Comer", label: "", UOM: "in" },
      cap_type: { desc: "Cap Type", label: "", UOM: "" },
      cap_od: { desc: "Cap OD", label: "", UOM: "in" },
      cap_bwg: { desc: "  Cap BWG", label: "", UOM: "" },
      cap_height: { desc: "Cap Height", label: "", UOM: "in" },
      no_slots: { desc: "No. of Slots", label: "", UOM: "" },
      slots_width: { desc: "Slots Width", label: "", UOM: "in" },
      slots_height: { desc: "Slots Height", label: "", UOM: "in" },
      skirt_height: { desc: "Skirt Height", label: "", UOM: "in" },
      skirt_clear: { desc: "Skirt Clear", label: "", UOM: "" },
      riser_od: { desc: "Riser Od", label: "", UOM: "in" },
      riser_bwg: { desc: "Riser BWG", label: "", UOM: "in" },
      riser_height: { desc: "Riser Height", label: "", UOM: "in" },
      total_area: { desc: "Total Area", label: "", UOM: "ft^2" },
      cooling_coil_bwg: { desc: "Cooling Coil BWG", label: "", UOM: "" },
      cooling_coil_od: { desc: "Cooling Coil OD", label: "", UOM: "in" },
      cooling_coil_material: { desc: "Cooling Coil Material", label: "", UOM: "" },
      code_requirement: { desc: "Code Requirement", label: "", UOM: "" },
      material_of_construction_shell_head_coil: { desc: "Material of Construction Sheal Head and Coil", label: "", UOM: "" },
      material_construction_skirt_base_ring: { desc: "Material Construction skirt base ring", label: "", UOM: "" },
      material_of_construction_clips_ladders_platforms: { desc: "Material of Construction clips and ladders platforms", label: "", UOM: "" },
      manways: { desc: "Manways", label: "", UOM: "" },
    },
    ESSummary: {
      operation: { desc: "Operation", label: "", UOM: "" },
      material: { desc: "Material Handled", label: "", UOM: "" },
      flow_rate: { desc: "Flow Rate", label: "", UOM: "lb/h" },
      operating_temperature:{ desc: "Operating Temperature", label: "", UOM: "F" },
      diameter_lower: { desc: "Diameter Lower", label: "", UOM: "in" },
      diameter_upper: { desc: "Diameter Upper", label: "", UOM: "in" },
      height: { desc: "Height", label: "", UOM: "in" },
      material_of_construction: { desc: "Material Of Construction", label: "", UOM: "in" },
    },
    AVSummary: {
      size: { desc: "Size", label: "", UOM: "" },
      type: { desc: "Type", label: "", UOM: "" },
      connected_in: { desc: "Connected In", label: "", UOM: "" },
      surf_per_unit: { desc: "Surf Per Unit", label: "", UOM: "ft^2" },
      shells_per_unit: { desc: "Shells per Unit", label: "", UOM: "" },
      surf_per_shell: { desc: "Surf Per Shell", label: "", UOM: "" },
      fluid_entering_shell_side: { desc: "Fluid Entering - Shell Side", label: "", UOM: "" },
      fluid_entering_tube_side: { desc: "Fluid Entering - Tube side", label: "", UOM: "" },
      condensable_vapor_shell_side: { desc: "Condensable Vapor - Shell side", label: "", UOM: "lb/hr" },
      condensable_vapor_tube_side: { desc: "Condensable Vapor - Tube side", label: "", UOM: "lb/hr" },
      non_condensable_vapor_shell_side: { desc: "Non-Condensable Vapor - Shell side", label: "", UOM: "lb/hr" },
      non_condensable_vapor_tube_side: { desc: "Non-Condensable Vapor - Tube side", label: "", UOM: "lb/hr" },
      liquid_shell_side: { desc: "Liquid - Shell side", label: "", UOM: "lb/hr" },
      liquid_tube_side: { desc: "Liquid - Tube side", label: "", UOM: "lb/hr" },
      total_fluid_enter_shell_side: { desc: "Total Fluid Enter - Shell side", label: "", UOM: "lb/hr" },
      total_fluid_enter_tube_side: { desc: "Total Fluid Enter - Tube side", label: "", UOM: "lb/hr" },
      fluid_vapor_or_cond_shell_side: { desc: "Fluid Vapor or cond - Shell side", label: "", UOM: "lb/hr" },
      fluid_vapor_or_cond_tube_side: { desc: "Fluid Vapor or cond - Tube side", label: "", UOM: "lb/hr" },
      gravity_liquid_shell_side: { desc: "Gravity - Liquid - Shell side", label: "", UOM: "" },
      gravity_liquid_tube_side: { desc: "Gravity - Liquid - Tube side", label: "", UOM: "" },
      viscosity_shell_side: { desc: "Viscosity - Shell side", label: "", UOM: "cP" },
      viscosity_tube_side: { desc: "Viscosity - Tube side", label: "", UOM: "cP" },
      molecular_weight_shell_side: { desc: "Molecular Weight - Shell side", label: "", UOM: "" },
      molecular_weight_tube_side: { desc: "Molecular Weight - Tube side", label: "", UOM: "" },
      specific_heat_shell_side: { desc: "Specific Heat - Shell side", label: "", UOM: "BTU/lb F" },
      specific_heat_tube_side: { desc: "Specific Heat - Tube side", label: "", UOM: "BTU/lb F" },
      latent_heat_shell_side: { desc: "Latent Heat - Shell side", label: "", UOM: "BTU/lb" },
      latent_heat_tube_side: { desc: "Latent Heat - Tube side", label: "", UOM: "BTU/lb" },
      thermal_cond_shell_side: { desc: "Thermal cond - Shell side", label: "", UOM: "BTU/hr ft F" },
      thermal_cond_tube_side: { desc: "Thermal cond - Tube side", label: "", UOM: "BTU/hr ft F" },
      fouling_resist_shell_side: { desc: "Fouling Resist - Shell side", label: "", UOM: "ft^2 hr F" },
      fouling_resist_tube_side: { desc: "Fouling Resist - Tube side", label: "", UOM: "ft^2 hr F" },
      temperature_in_shell_side: { desc: "Temperature In - Shell side", label: "", UOM: "F" },
      temperature_in_tube_side: { desc: "Temperature In - Tube side", label: "", UOM: "F" },
      temperature_out_shell_side: { desc: "Temperature Out - Shell side", label: "", UOM: "F" },
      temperature_out_tube_side: { desc: "Temperature Out - Tube side", label: "", UOM: "F" },
      operating_pressure_shell_side: { desc: "Operating Pressure - Shell side", label: "", UOM: "psig" },
      operating_pressure_tube_side: { desc: "Operating Pressure - Tube side", label: "", UOM: "psig" },
      number_of_passes_shell_side: { desc: "Number of Passes - Shell side", label: "", UOM: "" },
      number_of_passes_tube_side: { desc: "Number of Passes - Tube side", label: "", UOM: "" },
      velocity_shell_side: { desc: "Velocity - Shell side", label: "", UOM: "ft/sec" },
      velocity_tube_side: { desc: "Velocity - Tube side", label: "", UOM: "ft/sec" },
      pressure_drop_shell_side: { desc: "Pressure Drop - Shell side", label: "", UOM: "psi" },
      pressure_drop_tube_side: { desc: "Pressure Drop - Tube side", label: "", UOM: "psi" },
      design_pressure_shell_side: { desc: "Design Pressure - Shell side", label: "", UOM: "psig" },
      design_pressure_tube_side: { desc: "Design Pressure - Tube side", label: "", UOM: "psig" },
      test_pressure_shell_side: { desc: "Test Pressure - Shell side", label: "", UOM: "psig" },
      test_pressure_tube_side: { desc: "Test Pressure - Tube side", label: "", UOM: "psig" },
      design_temperature_shell_side: { desc: "Design temperature - Shell side", label: "", UOM: "F" },
      design_temperature_tube_side: { desc: "Design temperature - Tube side", label: "", UOM: "F" },
      heat_exchanged: { desc: "Heat exchanged", label: "", UOM: "BTU/hr" },
      trans_coef_: { desc: "Trans coef", label: "", UOM: "BTU/hr ft^2 F" },
      mtd: { desc: "MTD", label: "", UOM: "" },
      trans_coef_clean: { desc: "Trans Coef Clean", label: "", UOM: "BTU/hr ft^2 F" },
      tubes: { desc: "Tubes", label: "", UOM: "" },
      no: { desc: "No.", label: "", UOM: "" },
      od: { desc: "OD", label: "", UOM: "in" },
      bwg: { desc: "BWG", label: "", UOM: "" },
      length: { desc: "Length", label: "", UOM: "in" },
      pitch: { desc: "Pitch", label: "", UOM: "in" },
      shell: { desc: "Shell", label: "", UOM: "" },
      id: { desc: "ID", label: "", UOM: "in" },
      thickness: { desc: "Thickness", label: "", UOM: "in" },
      shell_cover: { desc: "Shell Cover", label: "", UOM: "" },
      floating_head_cover: { desc: "Floating Head Cover", label: "", UOM: "" },
      channel: { desc: "Channel", label: "", UOM: "" },
      channel_cover: { desc: "Channel Cover", label: "", UOM: "" },
      tube_sheets_stationary: { desc: "Tube Sheets - Stationary", label: "", UOM: "" },
      floating: { desc: "Floating", label: "", UOM: "" },
      baffles_cross: { desc: "Baffles-Cross", label: "", UOM: "" },
      baffles_type: { desc: "Baffles Type", label: "", UOM: "" },
      baffles_thickness: { desc: "Baffles Thickness", label: "", UOM: "" },
      baffles_impingement: { desc: "Baffles Impingement", label: "", UOM: "" },
      tube_fastening: { desc: "Tube fastening", label: "", UOM: "" },
      tube_supports: { desc: "Tube Supports", label: "", UOM: "" },
      tube_thickness: { desc: "Tube Thickness", label: "", UOM: "" },
      gaskets: { desc: "Gaskets", label: "", UOM: "" },
      shell_in_: { desc: "Shell-In", label: "", UOM: "" },
      shell_out: { desc: "Shell-Out", label: "", UOM: "" },
      shell_series: { desc: "Shell-Series", label: "", UOM: "" },
      channel_in: { desc: "Channel-In", label: "", UOM: "" },
      channel_out: { desc: "Channel-out", label: "", UOM: "" },
      channel_series: { desc: "Channel-series", label: "", UOM: "" },
      corrosion_allowance_shell_side: { desc: "Corrosion Allowance - Shell side", label: "", UOM: "in" },
      corr_allowance_tube_side: { desc: "Corr Allowance - Tube side", label: "", UOM: "in" },
      code_requirements: { desc: "Code Requirements", label: "", UOM: "" }
    },
    AHSummary: {
      size: { desc: "Size", label: "", UOM: "" },
      type: { desc: "Type", label: "", UOM: "" },
      connected_in: { desc: "Connected In", label: "", UOM: "" },
      surf_per_unit: { desc: "Surf Per Unit", label: "", UOM: "ft^2" },
      shells_per_unit: { desc: "Shells per Unit", label: "", UOM: "" },
      surf_per_shell: { desc: "Surf Per Shell", label: "", UOM: "" },
      fluid_entering_shell_side: { desc: "Fluid Entering - Shell Side", label: "", UOM: "" },
      fluid_entering_tube_side: { desc: "Fluid Entering - Tube side", label: "", UOM: "" },
      condensable_vapor_shell_side: { desc: "Condensable Vapor - Shell side", label: "", UOM: "lb/hr" },
      condensable_vapor_tube_side: { desc: "Condensable Vapor - Tube side", label: "", UOM: "lb/hr" },
      non_condensable_vapor_shell_side: { desc: "Non-Condensable Vapor - Shell side", label: "", UOM: "lb/hr" },
      non_condensable_vapor_tube_side: { desc: "Non-Condensable Vapor - Tube side", label: "", UOM: "lb/hr" },
      liquid_shell_side: { desc: "Liquid - Shell side", label: "", UOM: "lb/hr" },
      liquid_tube_side: { desc: "Liquid - Tube side", label: "", UOM: "lb/hr" },
      total_fluid_enter_shell_side: { desc: "Total Fluid Enter - Shell side", label: "", UOM: "lb/hr" },
      total_fluid_enter_tube_side: { desc: "Total Fluid Enter - Tube side", label: "", UOM: "lb/hr" },
      fluid_vapor_or_cond_shell_side: { desc: "Fluid Vapor or cond - Shell side", label: "", UOM: "lb/hr" },
      fluid_vapor_or_cond_tube_side: { desc: "Fluid Vapor or cond - Tube side", label: "", UOM: "lb/hr" },
      gravity_liquid_shell_side: { desc: "Gravity - Liquid - Shell side", label: "", UOM: "" },
      gravity_liquid_tube_side: { desc: "Gravity - Liquid - Tube side", label: "", UOM: "" },
      viscosity_shell_side: { desc: "Viscosity - Shell side", label: "", UOM: "cP" },
      viscosity_tube_side: { desc: "Viscosity - Tube side", label: "", UOM: "cP" },
      molecular_weight_shell_side: { desc: "Molecular Weight - Shell side", label: "", UOM: "" },
      molecular_weight_tube_side: { desc: "Molecular Weight - Tube side", label: "", UOM: "" },
      specific_heat_shell_side: { desc: "Specific Heat - Shell side", label: "", UOM: "BTU/lb F" },
      specific_heat_tube_side: { desc: "Specific Heat - Tube side", label: "", UOM: "BTU/lb F" },
      latent_heat_shell_side: { desc: "Latent Heat - Shell side", label: "", UOM: "BTU/lb" },
      latent_heat_tube_side: { desc: "Latent Heat - Tube side", label: "", UOM: "BTU/lb" },
      thermal_cond_shell_side: { desc: "Thermal cond - Shell side", label: "", UOM: "BTU/hr ft F" },
      thermal_cond_tube_side: { desc: "Thermal cond - Tube side", label: "", UOM: "BTU/hr ft F" },
      fouling_resist_shell_side: { desc: "Fouling Resist - Shell side", label: "", UOM: "ft^2 hr F" },
      fouling_resist_tube_side: { desc: "Fouling Resist - Tube side", label: "", UOM: "ft^2 hr F" },
      temperature_in_shell_side: { desc: "Temperature In - Shell side", label: "", UOM: "F" },
      temperature_in_tube_side: { desc: "Temperature In - Tube side", label: "", UOM: "F" },
      temperature_out_shell_side: { desc: "Temperature Out - Shell side", label: "", UOM: "F" },
      temperature_out_tube_side: { desc: "Temperature Out - Tube side", label: "", UOM: "F" },
      operating_pressure_shell_side: { desc: "Operating Pressure - Shell side", label: "", UOM: "psig" },
      operating_pressure_tube_side: { desc: "Operating Pressure - Tube side", label: "", UOM: "psig" },
      number_of_passes_shell_side: { desc: "Number of Passes - Shell side", label: "", UOM: "" },
      number_of_passes_tube_side: { desc: "Number of Passes - Tube side", label: "", UOM: "" },
      velocity_shell_side: { desc: "Velocity - Shell side", label: "", UOM: "ft/sec" },
      velocity_tube_side: { desc: "Velocity - Tube side", label: "", UOM: "ft/sec" },
      pressure_drop_shell_side: { desc: "Pressure Drop - Shell side", label: "", UOM: "psi" },
      pressure_drop_tube_side: { desc: "Pressure Drop - Tube side", label: "", UOM: "psi" },
      design_pressure_shell_side: { desc: "Design Pressure - Shell side", label: "", UOM: "psig" },
      design_pressure_tube_side: { desc: "Design Pressure - Tube side", label: "", UOM: "psig" },
      test_pressure_shell_side: { desc: "Test Pressure - Shell side", label: "", UOM: "psig" },
      test_pressure_tube_side: { desc: "Test Pressure - Tube side", label: "", UOM: "psig" },
      design_temperature_shell_side: { desc: "Design temperature - Shell side", label: "", UOM: "F" },
      design_temperature_tube_side: { desc: "Design temperature - Tube side", label: "", UOM: "F" },
      heat_exchanged: { desc: "Heat exchanged", label: "", UOM: "BTU/hr" },
      trans_coef: { desc: "Trans coef", label: "", UOM: "BTU/hr ft^2 F" },
      mtd: { desc: "MTD", label: "", UOM: "" },
      trans_coef_clean: { desc: "Trans Coef Clean", label: "", UOM: "BTU/hr ft^2 F" },
      tubes: { desc: "Tubes", label: "", UOM: "" },
      no: { desc: "No.", label: "", UOM: "" },
      od: { desc: "OD", label: "", UOM: "in" },
      bwg: { desc: "BWG", label: "", UOM: "" },
      length: { desc: "Length", label: "", UOM: "in" },
      pitch: { desc: "Pitch", label: "", UOM: "in" },
      shell: { desc: "Shell", label: "", UOM: "" },
      id: { desc: "ID", label: "", UOM: "in" },
      thickness: { desc: "Thickness", label: "", UOM: "in" },
      shell_cover: { desc: "Shell Cover", label: "", UOM: "" },
      floating_head_cover: { desc: "Floating Head Cover", label: "", UOM: "" },
      channel: { desc: "Channel", label: "", UOM: "" },
      channel_cover: { desc: "Channel Cover", label: "", UOM: "" },
      tube_sheets_stationary: { desc: "Tube Sheets - Stationary", label: "", UOM: "" },
      floating: { desc: "Floating", label: "", UOM: "" },
      baffles_cross: { desc: "Baffles-Cross", label: "", UOM: "" },
      baffles_type: { desc: "Baffles Type", label: "", UOM: "" },
      baffles_thickness: { desc: "Baffles Thickness", label: "", UOM: "" },
      baffles_impingement: { desc: "Baffles Impingement", label: "", UOM: "" },
      tube_fastening: { desc: "Tube fastening", label: "", UOM: "" },
      tube_supports: { desc: "Tube Supports", label: "", UOM: "" },
      tube_thickness: { desc: "Tube Thickness", label: "", UOM: "" },
      gaskets: { desc: "Gaskets", label: "", UOM: "" },
      shell_in: { desc: "Shell-In", label: "", UOM: "" },
      shell_out: { desc: "Shell-Out", label: "", UOM: "" },
      shell_series: { desc: "Shell-Series", label: "", UOM: "" },
      channel_in: { desc: "Channel-In", label: "", UOM: "" },
      channel_out: { desc: "Channel-out", label: "", UOM: "" },
      channel_series: { desc: "Channel-series", label: "", UOM: "" },
      corrosion_allowance_shell_side: { desc: "Corrosion Allowance - Shell side", label: "", UOM: "in" },
      corr_allowance_tube_side: { desc: "Corr Allowance - Tube side", label: "", UOM: "in" },
      code_requirements: { desc: "Code Requirements", label: "", UOM: "" },
      },
    
    WHBSummary: {
      size: { desc: "Size", label: "", UOM: "" },
      type: { desc: "Type", label: "", UOM: "" },
      connected_in: { desc: "Connected In", label: "", UOM: "" },
      surf_per_unit: { desc: "Surf Per Unit", label: "", UOM: "ft^2" },
      shells_per_unit: { desc: "Shells per Unit", label: "", UOM: "" },
      surf_per_shell: { desc: "Surf Per Shell", label: "", UOM: "" },
      fluid_entering_shell_side: { desc: "Fluid Entering - Shell Side", label: "", UOM: "" },
      fluid_entering_tube_side: { desc: "Fluid Entering - Tube side", label: "", UOM: "" },
      condensable_vapor_shell_side: { desc: "Condensable Vapor - Shell side", label: "", UOM: "lb/hr" },
      condensable_vapor_tube_side: { desc: "Condensable Vapor - Tube side", label: "", UOM: "lb/hr" },
      non_condensable_vapor_shell_side: { desc: "Non-Condensable Vapor - Shell side", label: "", UOM: "lb/hr" },
      non_condensable_vapor_tube_side: { desc: "Non-Condensable Vapor - Tube side", label: "", UOM: "lb/hr" },
      liquid_shell_side: { desc: "Liquid - Shell side", label: "", UOM: "lb/hr" },
      liquid_tube_side: { desc: "Liquid - Tube side", label: "", UOM: "lb/hr" },
      total_fluid_enter_shell_side: { desc: "Total Fluid Enter - Shell side", label: "", UOM: "lb/hr" },
      total_fluid_enter_tube_side: { desc: "Total Fluid Enter - Tube side", label: "", UOM: "lb/hr" },
      fluid_vapor_or_cond_shell_side: { desc: "Fluid Vapor or cond - Shell side", label: "", UOM: "lb/hr" },
      fluid_vapor_or_cond_tube_side: { desc: "Fluid Vapor or cond - Tube side", label: "", UOM: "lb/hr" },
      gravity_liquid_shell_side: { desc: "Gravity - Liquid - Shell side", label: "", UOM: "" },
      gravity_liquid_tube_side: { desc: "Gravity - Liquid - Tube side", label: "", UOM: "" },
      viscosity_shell_side: { desc: "Viscosity - Shell side", label: "", UOM: "cP" },
      viscosity_tube_side: { desc: "Viscosity - Tube side", label: "", UOM: "cP" },
      molecular_weight_shell_side: { desc: "Molecular Weight - Shell side", label: "", UOM: "" },
      molecular_weight_tube_side: { desc: "Molecular Weight - Tube side", label: "", UOM: "" },
      specific_heat_shell_side: { desc: "Specific Heat - Shell side", label: "", UOM: "BTU/lb F" },
      specific_heat_tube_side: { desc: "Specific Heat - Tube side", label: "", UOM: "BTU/lb F" },
      latent_heat_shell_side: { desc: "Latent Heat - Shell side", label: "", UOM: "BTU/lb" },
      latent_heat_tube_side: { desc: "Latent Heat - Tube side", label: "", UOM: "BTU/lb" },
      thermal_cond_shell_side: { desc: "Thermal cond - Shell side", label: "", UOM: "BTU/hr ft F" },
      thermal_cond_tube_side: { desc: "Thermal cond - Tube side", label: "", UOM: "BTU/hr ft F" },
      fouling_resist_shell_side: { desc: "Fouling Resist - Shell side", label: "", UOM: "ft^2 hr F" },
      fouling_resist_tube_side: { desc: "Fouling Resist - Tube side", label: "", UOM: "ft^2 hr F" },
      temperature_in_shell_side: { desc: "Temperature In - Shell side", label: "", UOM: "F" },
      temperature_in_tube_side: { desc: "Temperature In - Tube side", label: "", UOM: "F" },
      temperature_out_shell_side: { desc: "Temperature Out - Shell side", label: "", UOM: "F" },
      temperature_out_tube_side: { desc: "Temperature Out - Tube side", label: "", UOM: "F" },
      operating_pressure_shell_side: { desc: "Operating Pressure - Shell side", label: "", UOM: "psig" },
      operating_pressure_tube_side: { desc: "Operating Pressure - Tube side", label: "", UOM: "psig" },
      number_of_passes_shell_side: { desc: "Number of Passes - Shell side", label: "", UOM: "" },
      number_of_passes_tube_side: { desc: "Number of Passes - Tube side", label: "", UOM: "" },
      velocity_shell_side: { desc: "Velocity - Shell side", label: "", UOM: "ft/sec" },
      velocity_tube_side: { desc: "Velocity - Tube side", label: "", UOM: "ft/sec" },
      pressure_drop_shell_side: { desc: "Pressure Drop - Shell side", label: "", UOM: "psi" },
      pressure_drop_tube_side: { desc: "Pressure Drop - Tube side", label: "", UOM: "psi" },
      design_pressure_shell_side: { desc: "Design Pressure - Shell side", label: "", UOM: "psig" },
      design_pressure_tube_side: { desc: "Design Pressure - Tube side", label: "", UOM: "psig" },
      test_pressure_shell_side: { desc: "Test Pressure - Shell side", label: "", UOM: "psig" },
      test_pressure_tube_side: { desc: "Test Pressure - Tube side", label: "", UOM: "psig" },
      design_temperature_shell_side: { desc: "Design temperature - Shell side", label: "", UOM: "F" },
      design_temperature_tube_side: { desc: "Design temperature - Tube side", label: "", UOM: "F" },
      heat_exchanged: { desc: "Heat exchanged", label: "", UOM: "BTU/hr" },
      trans_coef: { desc: "Trans coef", label: "", UOM: "BTU/hr ft^2 F" },
      mtd: { desc: "MTD", label: "", UOM: "" },
      trans_coef_clean: { desc: "Trans Coef Clean", label: "", UOM: "BTU/hr ft^2 F" },
      tubes: { desc: "Tubes", label: "", UOM: "" },
      no: { desc: "No.", label: "", UOM: "" },
      od: { desc: "OD", label: "", UOM: "in" },
      bwg: { desc: "BWG", label: "", UOM: "" },
      length: { desc: "Length", label: "", UOM: "in" },
      pitch: { desc: "Pitch", label: "", UOM: "in" },
      shell: { desc: "Shell", label: "", UOM: "" },
      id: { desc: "ID", label: "", UOM: "in" },
      thickness: { desc: "Thickness", label: "", UOM: "in" },
      shell_cover: { desc: "Shell Cover", label: "", UOM: "" },
      floating_head_cover: { desc: "Floating Head Cover", label: "", UOM: "" },
      channel: { desc: "Channel", label: "", UOM: "" },
      channel_cover: { desc: "Channel Cover", label: "", UOM: "" },
      tube_sheets_stationary: { desc: "Tube Sheets - Stationary", label: "", UOM: "" },
      floating: { desc: "Floating", label: "", UOM: "" },
      baffles_cross: { desc: "Baffles-Cross", label: "", UOM: "" },
      baffles_type: { desc: "Baffles Type", label: "", UOM: "" },
      baffles_thickness: { desc: "Baffles Thickness", label: "", UOM: "" },
      baffles_impingement: { desc: "Baffles Impingement", label: "", UOM: "" },
      tube_fastening: { desc: "Tube fastening", label: "", UOM: "" },
      tube_supports: { desc: "Tube Supports", label: "", UOM: "" },
      tube_thickness: { desc: "Tube Thickness", label: "", UOM: "" },
      gaskets: { desc: "Gaskets", label: "", UOM: "" },
      shell_in: { desc: "Shell-In", label: "", UOM: "" },
      shell_out: { desc: "Shell-Out", label: "", UOM: "" },
      shell_series: { desc: "Shell-Series", label: "", UOM: "" },
      channel_in: { desc: "Channel-In", label: "", UOM: "" },
      channel_out: { desc: "Channel-out", label: "", UOM: "" },
      channel_series: { desc: "Channel-series", label: "", UOM: "" },
      corrosion_allowance_shell_side: { desc: "Corrosion Allowance - Shell side", label: "", UOM: "in" },
      corr_allowance_tube_side: { desc: "Corr Allowance - Tube side", label: "", UOM: "in" },
      code_requirements: { desc: "Code Requirements", label: "", UOM: "" },
    },
    CCSummary: {
      size: { desc: "Size", label: "", UOM: "" },
      type: { desc: "Type", label: "", UOM: "" },
      connected_in: { desc: "Connected In", label: "", UOM: "" },
      surf_per_unit: { desc: "Surf Per Unit", label: "", UOM: "ft^2" },
      shells_per_unit: { desc: "Shells per Unit", label: "", UOM: "" },
      surf_per_shell: { desc: "Surf Per Shell", label: "", UOM: "" },
      fluid_entering_shell_side: { desc: "Fluid Entering - Shell Side", label: "", UOM: "" },
      fluid_entering_tube_side: { desc: "Fluid Entering - Tube side", label: "", UOM: "" },
      condensable_vapor_shell_side: { desc: "Condensable Vapor - Shell side", label: "", UOM: "lb/hr" },
      condensable_vapor_tube_side: { desc: "Condensable Vapor - Tube side", label: "", UOM: "lb/hr" },
      non_condensable_vapor_shell_side: { desc: "Non-Condensable Vapor - Shell side", label: "", UOM: "lb/hr" },
      non_condensable_vapor_tube_side: { desc: "Non-Condensable Vapor - Tube side", label: "", UOM: "lb/hr" },
      liquid_shell_side: { desc: "Liquid - Shell side", label: "", UOM: "lb/hr" },
      liquid_tube_side: { desc: "Liquid - Tube side", label: "", UOM: "lb/hr" },
      total_fluid_enter_shell_side: { desc: "Total Fluid Enter - Shell side", label: "", UOM: "lb/hr" },
      total_fluid_enter_tube_side: { desc: "Total Fluid Enter - Tube side", label: "", UOM: "lb/hr" },
      fluid_vapor_or_cond_shell_side: { desc: "Fluid Vapor or cond - Shell side", label: "", UOM: "lb/hr" },
      fluid_vapor_or_cond_tube_side: { desc: "Fluid Vapor or cond - Tube side", label: "", UOM: "lb/hr" },
      gravity_liquid_shell_side: { desc: "Gravity - Liquid - Shell side", label: "", UOM: "" },
      gravity_liquid_tube_side: { desc: "Gravity - Liquid - Tube side", label: "", UOM: "" },
      viscosity_shell_side: { desc: "Viscosity - Shell side", label: "", UOM: "cP" },
      viscosity_tube_side: { desc: "Viscosity - Tube side", label: "", UOM: "cP" },
      molecular_weight_shell_side: { desc: "Molecular Weight - Shell side", label: "", UOM: "" },
      molecular_weight_tube_side: { desc: "Molecular Weight - Tube side", label: "", UOM: "" },
      specific_heat_shell_side: { desc: "Specific Heat - Shell side", label: "", UOM: "BTU/lb F" },
      specific_heat_tube_side: { desc: "Specific Heat - Tube side", label: "", UOM: "BTU/lb F" },
      latent_heat_shell_side: { desc: "Latent Heat - Shell side", label: "", UOM: "BTU/lb" },
      latent_heat_tube_side: { desc: "Latent Heat - Tube side", label: "", UOM: "BTU/lb" },
      thermal_cond_shell_side: { desc: "Thermal cond - Shell side", label: "", UOM: "BTU/hr ft F" },
      thermal_cond_tube_side: { desc: "Thermal cond - Tube side", label: "", UOM: "BTU/hr ft F" },
      fouling_resist_shell_side: { desc: "Fouling Resist - Shell side", label: "", UOM: "ft^2 hr F" },
      fouling_resist_tube_side: { desc: "Fouling Resist - Tube side", label: "", UOM: "ft^2 hr F" },
      temperature_in_shell_side: { desc: "Temperature In - Shell side", label: "", UOM: "F" },
      temperature_in_tube_side: { desc: "Temperature In - Tube side", label: "", UOM: "F" },
      temperature_out_shell_side: { desc: "Temperature Out - Shell side", label: "", UOM: "F" },
      temperature_out_tube_side: { desc: "Temperature Out - Tube side", label: "", UOM: "F" },
      operating_pressure_shell_side: { desc: "Operating Pressure - Shell side", label: "", UOM: "psig" },
      operating_pressure_tube_side: { desc: "Operating Pressure - Tube side", label: "", UOM: "psig" },
      number_of_passes_shell_side: { desc: "Number of Passes - Shell side", label: "", UOM: "" },
      number_of_passes_tube_side: { desc: "Number of Passes - Tube side", label: "", UOM: "" },
      velocity_shell_side: { desc: "Velocity - Shell side", label: "", UOM: "ft/sec" },
      velocity_tube_side: { desc: "Velocity - Tube side", label: "", UOM: "ft/sec" },
      pressure_drop_shell_side: { desc: "Pressure Drop - Shell side", label: "", UOM: "psi" },
      pressure_drop_tube_side: { desc: "Pressure Drop - Tube side", label: "", UOM: "psi" },
      design_pressure_shell_side: { desc: "Design Pressure - Shell side", label: "", UOM: "psig" },
      design_pressure_tube_side: { desc: "Design Pressure - Tube side", label: "", UOM: "psig" },
      test_pressure_shell_side: { desc: "Test Pressure - Shell side", label: "", UOM: "psig" },
      test_pressure_tube_side: { desc: "Test Pressure - Tube side", label: "", UOM: "psig" },
      design_temperature_shell_side: { desc: "Design temperature - Shell side", label: "", UOM: "F" },
      design_temperature_tube_side: { desc: "Design temperature - Tube side", label: "", UOM: "F" },
      heat_exchanged: { desc: "Heat exchanged", label: "", UOM: "BTU/hr" },
      trans_coef: { desc: "Trans coef", label: "", UOM: "BTU/hr ft^2 F" },
      mtd: { desc: "MTD", label: "", UOM: "" },
      trans_coef_clean: { desc: "Trans Coef Clean", label: "", UOM: "BTU/hr ft^2 F" },
      tubes: { desc: "Tubes", label: "", UOM: "" },
      no: { desc: "No.", label: "", UOM: "" },
      od: { desc: "OD", label: "", UOM: "in" },
      bwg: { desc: "BWG", label: "", UOM: "" },
      length: { desc: "Length", label: "", UOM: "in" },
      pitch: { desc: "Pitch", label: "", UOM: "in" },
      shell: { desc: "Shell", label: "", UOM: "" },
      id: { desc: "ID", label: "", UOM: "in" },
      thickness: { desc: "Thickness", label: "", UOM: "in" },
      shell_cover: { desc: "Shell Cover", label: "", UOM: "" },
      floating_head_cover: { desc: "Floating Head Cover", label: "", UOM: "" },
      channel: { desc: "Channel", label: "", UOM: "" },
      channel_cover: { desc: "Channel Cover", label: "", UOM: "" },
      tube_sheets_stationary: { desc: "Tube Sheets - Stationary", label: "", UOM: "" },
      floating: { desc: "Floating", label: "", UOM: "" },
      baffles_cross: { desc: "Baffles-Cross", label: "", UOM: "" },
      baffles_type: { desc: "Baffles Type", label: "", UOM: "" },
      baffles_thickness: { desc: "Baffles Thickness", label: "", UOM: "" },
      baffles_impingement: { desc: "Baffles Impingement", label: "", UOM: "" },
      tube_fastening: { desc: "Tube fastening", label: "", UOM: "" },
      tube_supports: { desc: "Tube Supports", label: "", UOM: "" },
      tube_thickness: { desc: "Tube Thickness", label: "", UOM: "" },
      gaskets: { desc: "Gaskets", label: "", UOM: "" },
      shell_in: { desc: "Shell-In", label: "", UOM: "" },
      shell_out: { desc: "Shell-Out", label: "", UOM: "" },
      shell_series: { desc: "Shell-Series", label: "", UOM: "" },
      channel_in: { desc: "Channel-In", label: "", UOM: "" },
      channel_out: { desc: "Channel-out", label: "", UOM: "" },
      channel_series: { desc: "Channel-series", label: "", UOM: "" },
      corrosion_allowance_shell_side: { desc: "Corrosion Allowance - Shell side", label: "", UOM: "in" },
      corr_allowance_tube_side: { desc: "Corr Allowance - Tube side", label: "", UOM: "in" },
      code_requirements: { desc: "Code Requirements", label: "", UOM: "" },
    },
    NAHSummary: {
      size: { desc: "Size", label: "", UOM: "" },
      type: { desc: "Type", label: "", UOM: "" },
      connected_in: { desc: "Connected In", label: "", UOM: "" },
      surf_per_unit: { desc: "Surf Per Unit", label: "", UOM: "ft^2" },
      shells_per_unit: { desc: "Shells per Unit", label: "", UOM: "" },
      surf_per_shell: { desc: "Surf Per Shell", label: "", UOM: "" },
      fluid_entering_shell_side: { desc: "Fluid Entering - Shell Side", label: "", UOM: "" },
      fluid_entering_tube_side: { desc: "Fluid Entering - Tube side", label: "", UOM: "" },
      condensable_vapor_shell_side: { desc: "Condensable Vapor - Shell side", label: "", UOM: "lb/hr" },
      condensable_vapor_tube_side: { desc: "Condensable Vapor - Tube side", label: "", UOM: "lb/hr" },
      non_condensable_vapor_shell_side: { desc: "Non-Condensable Vapor - Shell side", label: "", UOM: "lb/hr" },
      non_condensable_vapor_tube_side: { desc: "Non-Condensable Vapor - Tube side", label: "", UOM: "lb/hr" },
      liquid_shell_side: { desc: "Liquid - Shell side", label: "", UOM: "lb/hr" },
      liquid_tube_side: { desc: "Liquid - Tube side", label: "", UOM: "lb/hr" },
      total_fluid_enter_shell_side: { desc: "Total Fluid Enter - Shell side", label: "", UOM: "lb/hr" },
      total_fluid_enter_tube_side: { desc: "Total Fluid Enter - Tube side", label: "", UOM: "lb/hr" },
      fluid_vapor_or_cond_shell_side: { desc: "Fluid Vapor or cond - Shell side", label: "", UOM: "lb/hr" },
      fluid_vapor_or_cond_tube_side: { desc: "Fluid Vapor or cond - Tube side", label: "", UOM: "lb/hr" },
      gravity_liquid_shell_side: { desc: "Gravity - Liquid - Shell side", label: "", UOM: "" },
      gravity_liquid_tube_side: { desc: "Gravity - Liquid - Tube side", label: "", UOM: "" },
      viscosity_shell_side: { desc: "Viscosity - Shell side", label: "", UOM: "cP" },
      viscosity_tube_side: { desc: "Viscosity - Tube side", label: "", UOM: "cP" },
      molecular_weight_shell_side: { desc: "Molecular Weight - Shell side", label: "", UOM: "" },
      molecular_weight_tube_side: { desc: "Molecular Weight - Tube side", label: "", UOM: "" },
      specific_heat_shell_side: { desc: "Specific Heat - Shell side", label: "", UOM: "BTU/lb F" },
      specific_heat_tube_side: { desc: "Specific Heat - Tube side", label: "", UOM: "BTU/lb F" },
      latent_heat_shell_side: { desc: "Latent Heat - Shell side", label: "", UOM: "BTU/lb" },
      latent_heat_tube_side: { desc: "Latent Heat - Tube side", label: "", UOM: "BTU/lb" },
      thermal_cond_shell_side: { desc: "Thermal cond - Shell side", label: "", UOM: "BTU/hr ft F" },
      thermal_cond_tube_side: { desc: "Thermal cond - Tube side", label: "", UOM: "BTU/hr ft F" },
      fouling_resist_shell_side: { desc: "Fouling Resist - Shell side", label: "", UOM: "ft^2 hr F" },
      fouling_resist_tube_side: { desc: "Fouling Resist - Tube side", label: "", UOM: "ft^2 hr F" },
      temperature_in_shell_side: { desc: "Temperature In - Shell side", label: "", UOM: "F" },
      temperature_in_tube_side: { desc: "Temperature In - Tube side", label: "", UOM: "F" },
      temperature_out_shell_side: { desc: "Temperature Out - Shell side", label: "", UOM: "F" },
      temperature_out_tube_side: { desc: "Temperature Out - Tube side", label: "", UOM: "F" },
      operating_pressure_shell_side: { desc: "Operating Pressure - Shell side", label: "", UOM: "psig" },
      operating_pressure_tube_side: { desc: "Operating Pressure - Tube side", label: "", UOM: "psig" },
      number_of_passes_shell_side: { desc: "Number of Passes - Shell side", label: "", UOM: "" },
      number_of_passes_tube_side: { desc: "Number of Passes - Tube side", label: "", UOM: "" },
      velocity_shell_side: { desc: "Velocity - Shell side", label: "", UOM: "ft/sec" },
      velocity_tube_side: { desc: "Velocity - Tube side", label: "", UOM: "ft/sec" },
      pressure_drop_shell_side: { desc: "Pressure Drop - Shell side", label: "", UOM: "psi" },
      pressure_drop_tube_side: { desc: "Pressure Drop - Tube side", label: "", UOM: "psi" },
      design_pressure_shell_side: { desc: "Design Pressure - Shell side", label: "", UOM: "psig" },
      design_pressure_tube_side: { desc: "Design Pressure - Tube side", label: "", UOM: "psig" },
      test_pressure_shell_side: { desc: "Test Pressure - Shell side", label: "", UOM: "psig" },
      test_pressure_tube_side: { desc: "Test Pressure - Tube side", label: "", UOM: "psig" },
      design_temperature_shell_side: { desc: "Design temperature - Shell side", label: "", UOM: "F" },
      design_temperature_tube_side: { desc: "Design temperature - Tube side", label: "", UOM: "F" },
      heat_exchanged: { desc: "Heat exchanged", label: "", UOM: "BTU/hr" },
      trans_coef: { desc: "Trans coef", label: "", UOM: "BTU/hr ft^2 F" },
      mtd: { desc: "MTD", label: "", UOM: "" },
      trans_coef_clean: { desc: "Trans Coef Clean", label: "", UOM: "BTU/hr ft^2 F" },
      tubes: { desc: "Tubes", label: "", UOM: "" },
      no: { desc: "No.", label: "", UOM: "" },
      od: { desc: "OD", label: "", UOM: "in" },
      bwg: { desc: "BWG", label: "", UOM: "" },
      length: { desc: "Length", label: "", UOM: "in" },
      pitch: { desc: "Pitch", label: "", UOM: "in" },
      shell: { desc: "Shell", label: "", UOM: "" },
      id: { desc: "ID", label: "", UOM: "in" },
      thickness: { desc: "Thickness", label: "", UOM: "in" },
      shell_cover: { desc: "Shell Cover", label: "", UOM: "" },
      floating_head_cover: { desc: "Floating Head Cover", label: "", UOM: "" },
      channel: { desc: "Channel", label: "", UOM: "" },
      channel_cover: { desc: "Channel Cover", label: "", UOM: "" },
      tube_sheets_stationary: { desc: "Tube Sheets - Stationary", label: "", UOM: "" },
      floating: { desc: "Floating", label: "", UOM: "" },
      baffles_cross: { desc: "Baffles-Cross", label: "", UOM: "" },
      baffles_type: { desc: "Baffles Type", label: "", UOM: "" },
      baffles_thickness: { desc: "Baffles Thickness", label: "", UOM: "" },
      baffles_impingement: { desc: "Baffles Impingement", label: "", UOM: "" },
      tube_fastening: { desc: "Tube fastening", label: "", UOM: "" },
      tube_supports: { desc: "Tube Supports", label: "", UOM: "" },
      tube_thickness: { desc: "Tube Thickness", label: "", UOM: "" },
      gaskets: { desc: "Gaskets", label: "", UOM: "" },
      shell_in: { desc: "Shell-In", label: "", UOM: "" },
      shell_out: { desc: "Shell-Out", label: "", UOM: "" },
      shell_series: { desc: "Shell-Series", label: "", UOM: "" },
      channel_in: { desc: "Channel-In", label: "", UOM: "" },
      channel_out: { desc: "Channel-out", label: "", UOM: "" },
      channel_series: { desc: "Channel-series", label: "", UOM: "" },
      corrosion_allowance_shell_side: { desc: "Corrosion Allowance - Shell side", label: "", UOM: "in" },
      corr_allowance_tube_side: { desc: "Corr Allowance - Tube side", label: "", UOM: "in" },
      code_requirements: { desc: "Code Requirements", label: "", UOM: "" },
    },
    TGPSummary:{
      size: { desc: "Size", label: "", UOM: "" },
      type: { desc: "Type", label: "", UOM: "" },
      connected_in: { desc: "Connected In", label: "", UOM: "" },
      surf_per_unit: { desc: "Surf Per Unit", label: "", UOM: "ft^2" },
      shells_per_unit: { desc: "Shells per Unit", label: "", UOM: "" },
      surf_per_shell: { desc: "Surf Per Shell", label: "", UOM: "" },
      fluid_entering_shell_side: { desc: "Fluid Entering - Shell Side", label: "", UOM: "" },
      fluid_entering_tube_side: { desc: "Fluid Entering - Tube side", label: "", UOM: "" },
      condensable_vapor_shell_side: { desc: "Condensable Vapor - Shell side", label: "", UOM: "lb/hr" },
      condensable_vapor_tube_side: { desc: "Condensable Vapor - Tube side", label: "", UOM: "lb/hr" },
      non_condensable_vapor_shell_side: { desc: "Non-Condensable Vapor - Shell side", label: "", UOM: "lb/hr" },
      non_condensable_vapor_tube_side: { desc: "Non-Condensable Vapor - Tube side", label: "", UOM: "lb/hr" },
      liquid_shell_side: { desc: "Liquid - Shell side", label: "", UOM: "lb/hr" },
      liquid_tube_side: { desc: "Liquid - Tube side", label: "", UOM: "lb/hr" },
      total_fluid_enter_shell_side: { desc: "Total Fluid Enter - Shell side", label: "", UOM: "lb/hr" },
      total_fluid_enter_tube_side: { desc: "Total Fluid Enter - Tube side", label: "", UOM: "lb/hr" },
      fluid_vapor_or_cond_shell_side: { desc: "Fluid Vapor or cond - Shell side", label: "", UOM: "lb/hr" },
      fluid_vapor_or_cond_tube_side: { desc: "Fluid Vapor or cond - Tube side", label: "", UOM: "lb/hr" },
      gravity_liquid_shell_side: { desc: "Gravity - Liquid - Shell side", label: "", UOM: "" },
      gravity_liquid_tube_side: { desc: "Gravity - Liquid - Tube side", label: "", UOM: "" },
      viscosity_shell_side: { desc: "Viscosity - Shell side", label: "", UOM: "cP" },
      viscosity_tube_side: { desc: "Viscosity - Tube side", label: "", UOM: "cP" },
      molecular_weight_shell_side: { desc: "Molecular Weight - Shell side", label: "", UOM: "" },
      molecular_weight_tube_side: { desc: "Molecular Weight - Tube side", label: "", UOM: "" },
      specific_heat_shell_side: { desc: "Specific Heat - Shell side", label: "", UOM: "BTU/lb F" },
      specific_heat_tube_side: { desc: "Specific Heat - Tube side", label: "", UOM: "BTU/lb F" },
      latent_heat_shell_side: { desc: "Latent Heat - Shell side", label: "", UOM: "BTU/lb" },
      latent_heat_tube_side: { desc: "Latent Heat - Tube side", label: "", UOM: "BTU/lb" },
      thermal_cond_shell_side: { desc: "Thermal cond - Shell side", label: "", UOM: "BTU/hr ft F" },
      thermal_cond_tube_side: { desc: "Thermal cond - Tube side", label: "", UOM: "BTU/hr ft F" },
      fouling_resist_shell_side: { desc: "Fouling Resist - Shell side", label: "", UOM: "ft^2 hr F" },
      fouling_resist_tube_side: { desc: "Fouling Resist - Tube side", label: "", UOM: "ft^2 hr F" },
      temperature_in_shell_side: { desc: "Temperature In - Shell side", label: "", UOM: "F" },
      temperature_in_tube_side: { desc: "Temperature In - Tube side", label: "", UOM: "F" },
      temperature_out_shell_side: { desc: "Temperature Out - Shell side", label: "", UOM: "F" },
      temperature_out_tube_side: { desc: "Temperature Out - Tube side", label: "", UOM: "F" },
      operating_pressure_shell_side: { desc: "Operating Pressure - Shell side", label: "", UOM: "psig" },
      operating_pressure_tube_side: { desc: "Operating Pressure - Tube side", label: "", UOM: "psig" },
      number_of_passes_shell_side: { desc: "Number of Passes - Shell side", label: "", UOM: "" },
      number_of_passes_tube_side: { desc: "Number of Passes - Tube side", label: "", UOM: "" },
      velocity_shell_side: { desc: "Velocity - Shell side", label: "", UOM: "ft/sec" },
      velocity_tube_side: { desc: "Velocity - Tube side", label: "", UOM: "ft/sec" },
      pressure_drop_shell_side: { desc: "Pressure Drop - Shell side", label: "", UOM: "psi" },
      pressure_drop_tube_side: { desc: "Pressure Drop - Tube side", label: "", UOM: "psi" },
      design_pressure_shell_side: { desc: "Design Pressure - Shell side", label: "", UOM: "psig" },
      design_pressure_tube_side: { desc: "Design Pressure - Tube side", label: "", UOM: "psig" },
      test_pressure_shell_side: { desc: "Test Pressure - Shell side", label: "", UOM: "psig" },
      test_pressure_tube_side: { desc: "Test Pressure - Tube side", label: "", UOM: "psig" },
      design_temperature_shell_side: { desc: "Design temperature - Shell side", label: "", UOM: "F" },
      design_temperature_tube_side: { desc: "Design temperature - Tube side", label: "", UOM: "F" },
      heat_exchanged: { desc: "Heat exchanged", label: "", UOM: "BTU/hr" },
      trans_coef: { desc: "Trans coef", label: "", UOM: "BTU/hr ft^2 F" },
      mtd: { desc: "MTD", label: "", UOM: "" },
      trans_coef_clean: { desc: "Trans Coef Clean", label: "", UOM: "BTU/hr ft^2 F" },
      tubes: { desc: "Tubes", label: "", UOM: "" },
      no: { desc: "No.", label: "", UOM: "" },
      od: { desc: "OD", label: "", UOM: "in" },
      bwg: { desc: "BWG", label: "", UOM: "" },
      length: { desc: "Length", label: "", UOM: "in" },
      pitch: { desc: "Pitch", label: "", UOM: "in" },
      shell: { desc: "Shell", label: "", UOM: "" },
      id: { desc: "ID", label: "", UOM: "in" },
      thickness: { desc: "Thickness", label: "", UOM: "in" },
      shell_cover: { desc: "Shell Cover", label: "", UOM: "" },
      floating_head_cover: { desc: "Floating Head Cover", label: "", UOM: "" },
      channel: { desc: "Channel", label: "", UOM: "" },
      channel_cover: { desc: "Channel Cover", label: "", UOM: "" },
      tube_sheets_stationary: { desc: "Tube Sheets - Stationary", label: "", UOM: "" },
      floating: { desc: "Floating", label: "", UOM: "" },
      baffles_cross: { desc: "Baffles-Cross", label: "", UOM: "" },
      baffles_type: { desc: "Baffles Type", label: "", UOM: "" },
      baffles_thickness: { desc: "Baffles Thickness", label: "", UOM: "" },
      baffles_impingement: { desc: "Baffles Impingement", label: "", UOM: "" },
      tube_fastening: { desc: "Tube fastening", label: "", UOM: "" },
      tube_supports: { desc: "Tube Supports", label: "", UOM: "" },
      tube_thickness: { desc: "Tube Thickness", label: "", UOM: "" },
      gaskets: { desc: "Gaskets", label: "", UOM: "" },
      shell_in: { desc: "Shell-In", label: "", UOM: "" },
      shell_out: { desc: "Shell-Out", label: "", UOM: "" },
      shell_series: { desc: "Shell-Series", label: "", UOM: "" },
      channel_in: { desc: "Channel-In", label: "", UOM: "" },
      channel_out: { desc: "Channel-out", label: "", UOM: "" },
      channel_series: { desc: "Channel-series", label: "", UOM: "" },
      corrosion_allowance_shell_side: { desc: "Corrosion Allowance - Shell side", label: "", UOM: "in" },
      corr_allowance_tube_side: { desc: "Corr Allowance - Tube side", label: "", UOM: "in" },
      code_requirements: { desc: "Code Requirements", label: "", UOM: "" },
    },
    IAFSummary: {
      operation: { desc: "Operation", label: "", UOM: "" },
      material_handled: { desc: "Material", label: "", UOM: "" },
      capacity : {desc: "Capacity", label: "", UOM:"ACFM"},
      inlet_temperature_D_B: {desc: "Inlet Temperature_D_B", label: "", UOM:"F"},
      inlet_temperature_W_B: {desc: "Inlet Temperature_W_B", label: "", UOM:"F"},
      inlet_pressure: {desc: "Inlet Pressure", label: "", UOM:"psia"},
      molecular_weight: {desc: "Molecular Weight", label: "", UOM: ""},
      face_velocity: {desc: "Face Velocity", label: "", UOM: "FPM"},
      pressure_drop: {desc: "Pressure Drop", label: "", UOM:""},
      dust_loading: {desc: "Dust Loading", label: "", UOM:"gms/ft^3"},
      efficiency: {desc: "Efficiency", label: "", UOM: ""}
    },
    DAFSummary: {
      operation: { desc: "Operation", label: "", UOM: "" },
      material_handled: { desc: "Material", label: "", UOM: "" },
      flow_normal_operation: { desc: "Flow Normal Operation", label: "", UOM: "lb/hr" },
      flow_max: { desc: "Flow Max", label: "", UOM: "lb/hr" },
      temperature_operating: { desc: "Temperature Operating", label: "", UOM: "F" },
      temperature_design: { desc: "Temperature Design", label: "", UOM: "F" },
      pressure_operating: { desc: "Pressure Operating", label: "", UOM: "psig" },
      pressure_design: { desc: "Pressure Design", label: "", UOM: "psig" },
      gas_density: { desc: "Gas Density", label: "", UOM: "lb/ft^3" },
      filter_efficiency: { desc: "Filter Effiociency", label: "", UOM: "" },
      filter_media: { desc: "Filter Media", label: "", UOM: "" },
      element_pressure_drop: { desc: "Element Pressure Drop", label: "", UOM: "psi" },
      face_velocity: { desc: "Face Velocity", label: "", UOM: "fpm" },
      material_of_construction: { desc: "Material of Construction", label: "", UOM: "" },
      code_requirement: { desc: "Code Requirements", label: "", UOM: "" },
    },
    BCSummary: {
      design_pressure_int: { desc: "Design Pressure INT", label: "", UOM: "psi" },
      design_pressure_ext: { desc: "Design Pressure EXT", label: "", UOM: "psi" },
      design_temperature: { desc: "Design Temperature", label: "", UOM: "F" },
      mawp_int_200f: { desc: "MAWP INT at 200F", label: "", UOM: "psi" },
      mawp_ext_200f: { desc: "MAWP EXT at 200F", label: "", UOM: "psi" },
      mdmt_int: { desc: "MDMT INT", label: "", UOM: "F" },
      mdmt_int_at: { desc: "MDMT INT at", label: "", UOM: "psi" },
      mdmt_ext: { desc: "MDMT EXT", label: "", UOM: "F" },
      mdmt_ext_at: { desc: "MDMT EXT at", label: "", UOM: "psi" },
      corrosion_allowance: { desc: "Corrosion Allowance", label: "", UOM: "" },
      hydrotest_pressure: { desc: "HydroTest Pressure", label: "", UOM: "psi" },
      radiography: { desc: "Radiography", label: "", UOM: "" },
      joint_efficiency_head: { desc: "Joint Efficiency Heads", label: "", UOM: "%" },
      joint_efficiency_shell: { desc: "Joint Efficiency Shell", label: "", UOM: "%" },
      stress_relieve: { desc: "Stress Relieve", label: "", UOM: "" },
      shell_material: { desc: "Shell - Material", label: "", UOM: "" },
      head_material: { desc: "Head - Material", label: "", UOM: "" },
      nozzle_material: { desc: "Nozzle Material", label: "", UOM: "" },
      flange_material: { desc: "Flange Material", label: "", UOM: "" },
      internal_material: { desc: "Internal Material", label: "", UOM: "" },
      repads_material: { desc: "Repads Material", label: "", UOM: "" },
      legs_material: { desc: "Legs Material", label: "", UOM: "" },
      base_plate_material: { desc: "Base Plate Material", label: "", UOM: "" },
      bolts: { desc: "Bolts", label: "", UOM: "" },
      gasket: { desc: "Gasket", label: "", UOM: "" },
      vessel_weight_empty: { desc: "Vessel Weight - Empty", label: "", UOM: "" },
      vessel_weight_full: { desc: "Vessel Weight - Full", label: "", UOM: "" },
      vessel_weight_capacity: { desc: "Vessel Weight Capacity", label: "", UOM: "gallons" },
    },
    ABPUMPSummary: {
      capacity: { desc: "Capacity", label: "", UOM: "gpm" },
      tdh: { desc: "TDH", label: "", UOM: "in" },
      specific_gravity: { desc: "S.G.", label: "", UOM: "" },
      temperature: { desc: "Temperature", label: "", UOM: "F" },
      npsha: { desc: "NPSHa", label: "", UOM: "in" },
      viscosity: { desc: "Viscosity", label: "", UOM: "cP" },
      c_q_flow: { desc: "C Q-Flow", label: "", UOM: "" },
      c_h_tdh: { desc: "C H-TDH", label: "", UOM: "" },
      c_n_eff: { desc: "C n-Eff", label: "", UOM: "" },
      model: { desc: "Model", label: "", UOM: "" },
      series: { desc: "Series", label: "", UOM: "" },
      size: { desc: "Size", label: "", UOM: "" },
      ansi_std: { desc: "ANSI Std", label: "", UOM: "" },
      ansi_std_2: { desc: "ANSI std", label: "", UOM: "" },
      max_pressure: { desc: "Max Pressure", label: "", UOM: "psi" },
      max_temperature: { desc: "Max Temp", label: "", UOM: "F" },
      casting: { desc: "Casting", label: "", UOM: "" },
      impeller: { desc: "Impeller", label: "", UOM: "" },
      cont_shell: { desc: "Cont. Shell", label: "", UOM: "" },
      shaft: { desc: "Shaft", label: "", UOM: "" },
      gasket: { desc: "Gasket", label: "", UOM: "" },
      magnets: { desc: "Magnets", label: "", UOM: "" },
      bearings: { desc: "Bearings", label: "", UOM: "" },
      w_rings: { desc: "W. Rings", label: "", UOM: "" },
      impeller_shut_off: { desc: "Impeller - Shut Off", label: "", UOM: "" },
      impeller_duty_point: { desc: "Impeller - Duty Point", label: "", UOM: "" },
      impeller_eoc: { desc: "Impeller - E.O.C.", label: "", UOM: "" },
      speed_shut_off: { desc: "Speed - Shut Off", label: "", UOM: "rpm" },
      speed_duty_point: { desc: "Speed - Duty Point", label: "", UOM: "rpm" },
      speed_eoc: { desc: "Speed - E.O.C.", label: "", UOM: "rpm" },
      power_shut_off: { desc: "Power - Shut Off", label: "", UOM: "hp" },
      power_duty_point: { desc: "Power - Duty Point", label: "", UOM: "hp" },
      power_eoc: { desc: "Power - E.O.C.", label: "", UOM: "hp" },
      npshr_shut_off: { desc: "NPSHr - Shut Off", label: "", UOM: "in" },
      npshr_duty_point: { desc: "NPSHr - Duty Point", label: "", UOM: "in" },
      npshr_eoc: { desc: "NPSHr - E.O.C.", label: "", UOM: "in" },
      efficiency_shut_off: { desc: "Efficiency - Shut Off", label: "", UOM: "%" },
      efficiency_duty_point: { desc: "Efficiency - Duty Point", label: "", UOM: "%" },
      efficiency_eoc: { desc: "Efficiency - E.O.C.", label: "", UOM: "%" },
      capacity_shut_off: { desc: "Capacity - Shut Off", label: "", UOM: "" },
      capacity_duty_point: { desc: "Capacity - Duty Point", label: "", UOM: "" },
      capacity_eoc: { desc: "Capacity - E.O.C.", label: "", UOM: "" },
      tdh_shut_off: { desc: "TDH - Shut Off", label: "", UOM: "in" },
      tdh_duty_point: { desc: "TDH - Duty Point", label: "", UOM: "in" },
      tdh_eoc: { desc: "TDH - E.O.C.", label: "", UOM: "in" },
      pressure_shut_off: { desc: "Pressure - Shut Off", label: "", UOM: "psi" },
      pressure_duty_point: { desc: "Pressure - Duty Point", label: "", UOM: "psi" },
      pressure_eoc: { desc: "Pressure - E.O.C.", label: "", UOM: "psi" },
    },
    PUMPPRELUBESummary: {
      speed: { desc: "Maximum Speed", label: "", UOM: "rpm" },
      viscosity:{ desc: "Viscosity", label: "", UOM: "SSU" },
      temperature: { desc: "Temperature", label: "", UOM: "F" },
      inlet_pressure: { desc: "Inlet Pressure", label: "", UOM: "psig" },
      drive: { desc: "Drive", label: "", UOM: "direction" },
      mounting: { desc: "Mounting", label: "", UOM: "" },
    },
    AFDSummary: {
      shell_id: { desc: "Shell ID ", label: "", UOM: "in" },
      shell_corrosion_allowance: { desc: "Shell Corrosion Allowance", label: "", UOM: "in" },
      shell_thickness: { desc: "Shell Thickness", label: "", UOM: "" },
      shell_length: { desc: "Shell Length", label: "", UOM: "in" },
      seams_rt: { desc: "Seams RT", label: "", UOM: "" },
      seams_off: { desc: "Seams off", label: "", UOM: "%" },
      seams_ht_temperature: { desc: "Seams HT Temperature", label: " ", UOM: "F" },
      seams_time: { desc: "Seams Time", label: "", UOM: "hr" },
      seams_girth: { desc: "Seams Girth", label: "", UOM: "" },
      seams_no_of_courses: { desc: "Seams - No. of courses", label: "", UOM: "" },
      head_a_location: { desc: "Head a - Location", label: "", UOM: "" },
      head_a_min_thickness: { desc: "Head a - Min Thickness", label: "", UOM: "in" },
      head_a_corrosion_allowance: { desc: "Head a - Corrosion Allowance", label: "", UOM: "in" },
      head_a_crown_radius: { desc: "Head a - Crown Radius", label: "", UOM: "" },
      head_a_knuckle_radius: { desc: "Head a - Knuckle radius", label: "", UOM: "" },
      head_a_elliptical_ratio: { desc: "Head a - Elliptical Ration", label: "", UOM: "" },
      head_a_conical_apex_angle: { desc: "Head a - Conical Apex Angle", label: "", UOM: "" },
      head_a_hemispherical_radius: { desc: "Head a - Hemispherical radius", label: "", UOM: "" },
      head_a_flat_diameter: { desc: "Head a - Flat Diameter", label: "", UOM: "" },
      head_a_side_to_pressure: { desc: "Head a - Side to Pressur", label: "e", UOM: "" },
      head_b_location: { desc: "Head b - Location", label: "", UOM: "" },
      head_b_min_thickness: { desc: "Head b - Min Thickness", label: "", UOM: "in" },
      head_b_corrosion_allowance: { desc: "Head b - Corrosion Allowance", label: "", UOM: "in" },
      head_b_crown_radius: { desc: "Head b - Crown Radius", label: "", UOM: "" },
      head_b_knuckle_radius: { desc: "Head b - Knuckle radius", label: "", UOM: "" },
      head_b_elliptical_ratio: { desc: "Head b - Elliptical Ration", label: "", UOM: "" },
      head_b_conical_apex_angle: { desc: "Head b - Conical Apex Angle", label: "", UOM: "" },
      head_b_hemispherical_radius: { desc: "Head b - Hemispherical radius", label: "", UOM: "" },
      head_b_flat_diameter: { desc: "Head b - Flat Diameter", label: "", UOM: "" },
      head_b_side_to_pressure: { desc: "Head b - Side to Pressure", label: "", UOM: "" },
      maximum_allowable_work_pressure: { desc: "Maximum Allowable Work Pressure", label: "", UOM: "psi" },
      max_temp: { desc: "At Max Temp", label: "", UOM: "F" },
      min_design_metal_temp: { desc: "Min Design Metal Temp", label: "", UOM: "F" },
      at_pressure: { desc: "At Pressure", label: "", UOM: "psi" },
      hydro_test_pressure: { desc: "Hydro test pressure", label: "", UOM: "psi" },
      supports: { desc: "Supports", label: "", UOM: "" }
    },
    Nox_AbatorSummary: {
      operation: { desc: "Operation", label: "", UOM: "" },
      materials_handled_tail_gas: { desc: "Materials Handled - Tail Gas", label: "", UOM: "" },
      materials_handled_fuel: { desc: "Materials Handled - Fuel", label: "", UOM: "" },
      operating_temperature_tail_gas_inlet: { desc: "Operating Temperature - Tail Gas Inlet", label: "", UOM: "F" },
      operating_temperature_ammonia_inlet: { desc: "Operating Temperature - Ammonia Inlet", label: "", UOM: "F" },
      operating_temperature_outlet: { desc: "Operating Temperature - Outlet", label: "", UOM: "F" },
      design_temperature: { desc: "Design temperature", label: "", UOM: "F" },
      operating_pressure: { desc: "Operating Pressure", label: "", UOM: "psig" },
      design_pressure: { desc: "Design Pressure", label: "", UOM: "psig" },
      allowable_pressure_drop: { desc: "Allowable Pressure Drop", label: "", UOM: "psi" },
      catalyst: { desc: "Catalyst", label: "", UOM: "" },
      materials_of_construction: { desc: "Materials of Construction", label: "", UOM: "" },
      code_requirements: { desc: "Code Requirements", label: "", UOM: "" }
    }
  },
};

export { pipeLineTypes, intstrLineTypes, processInitialState, initialProcess };
