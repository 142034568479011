import React, { useState, useEffect } from "react";
import { Button, InputGroup, Popover, Menu } from "@blueprintjs/core";
import { useDispatch, useSelector } from "react-redux";
import { openFile } from "../../3d-models/utils";
import { CustomDlg } from "../../common/CustomDlg";
import { secondServerAPI } from "../../../pages/utils/agent";
import { ApplicationState } from "../../../store";
import { addEventAction } from "../../../store/ui/actions";

type TFileType = "PDF";

type TFile = {
    id: string;
    selected: boolean;
    file: File;
    mode: TFileType;
};

type APIFile = {
    id: string;
    name: string;
};

type EquipmentDatasheetDlgProps = {
    isVisible: boolean;
    onClose: () => void;
};

export function EquipmentDatasheetDlg({ isVisible, onClose }: EquipmentDatasheetDlgProps) {
    const auth = useSelector((state: ApplicationState) => state.auth);
    const [file, setFile] = useState<TFile | null>(null); 
    const [fileNames, setFileNames] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [projectName, setProjectName] = useState<string>("");
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);

    const dispatch = useDispatch();

    function fetchAllFiles() {
        setLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: { 
                'user-id' : auth.User_id ? String(auth.User_id) : '',
                'project' : projectName
            },
        };
        fetch(`${secondServerAPI}/rest/api/v1/datasheets/getAllFiles`, requestOptions)
            .then(response => response.json()) 
            .then(data => {
                setLoading(false);
                setFileNames(data);
            })
            .catch(error => {
                console.error('Error fetching files:', error);
                setLoading(false);
                dispatch(
                    addEventAction(
                      `Error fetching files. Please Check the Project Name and try again`,
                      "warning"
                    )
                  );
                setFileNames([""]);
            });
    }

    function handleProjectNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setProjectName(event.target.value);
    }

    function fetchIndividualFile(filename: string) {
        setLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: { 
                'user-id' : auth.User_id? String(auth.User_id) : '',
                'project' : projectName,
                'filename': filename, 
            },
        };
        fetch(`${secondServerAPI}/rest/api/v1/datasheets/getFile`, requestOptions) 
            .then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                setPdfUrl(url); 
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching PDF file:', error);
                setLoading(false);
            });
    }

    async function uploadPDF() {
        openFile([".pdf"], async (selectedFiles) => {
            if (selectedFiles.length > 0) {
                setLoading(true);
                const originalFile = selectedFiles[0];
                const newName = originalFile.name.replace(/\s+/g, '');
                const newFile = new File([originalFile], newName, { type: originalFile.type });
                const formData = new FormData();
                formData.append("file", newFile); // Use the new file here
                formData.append("projectName", projectName);
                console.log(newFile);
                await fetch(`${secondServerAPI}/rest/api/v1/datasheets/upload`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'user-id': auth.User_id ? String(auth.User_id) : '',
                        'project': projectName
                    }
                });
                fetchAllFiles();
                setLoading(false);
            }
        }, true);
    }
    

    

    function deleteFile(fileName: string) {
        setLoading(true);
        const requestOptions = {
            method : 'GET',
            headers : {
                'user-id' : auth.User_id ? String(auth.User_id) : '',
                'project' : projectName,
                'filename' : fileName
            }
        }
        fetch(`${secondServerAPI}/rest/api/v1/datasheets/deleteDatasheet`, requestOptions)
            .then(response => response.json());
            dispatch(
                addEventAction(
                  `File has been deleted successfully`,
                  "success"
                )
              );
        fetchAllFiles();
        setLoading(false);
    }

    if (!isVisible) {
        return null;
    }

    return (
        <CustomDlg
          title={"Equipment Data Sheet"}
          isMinimize={true}
          zIndex={1}
          
          body={
            <div>
                <InputGroup 
                    large 
                    type="text" 
                    placeholder="Project Name" 
                    value={projectName}
                    onChange={(event) => setProjectName(event.target.value)}
                    autoFocus={true}
                    style={{ marginBottom: "10px" }}
                />
                <Popover
                    content={
                        <Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            fileNames.map((fileName, index) => (
                            <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                <Button onClick={() => fetchIndividualFile(fileName)}>
                                {fileName}
                                </Button>
                                <Button
                                icon="trash"
                                intent="danger"
                                disabled = {!fileName}
                                onClick={() => deleteFile(fileName)}
                                />
                            </div>
                            ))
                        )}
                        </Menu>
                    }
                    placement="bottom"
                    >
                    <Button alignText="left" icon="applications" rightIcon="caret-down" text="Open with..." />
                </Popover>
                <div>
                    <Button 
                        onClick={uploadPDF} 
                        disabled={!projectName}
                        icon="export"
                        intent="primary"
                        >Upload</Button>
                    <Button 
                    onClick={fetchAllFiles} 
                    disabled={!projectName} 
                    icon="import"
                    intent="success"
                    >Fetch Files</Button>
                    {/* {loading ? <p>Loading...</p> : fileNames.map((fileName, index) => (
                        <div key={index}>
                        <Button key={index} onClick={() => fetchIndividualFile(fileName)}>
                            {fileName}
                        </Button>
                        <Button
                        icon="trash"
                        intent="danger"
                        onClick={() => deleteFile(fileName)}
                    />
                    </div>
                    ))} */}
                </div>
                {pdfUrl && (
                    <iframe
                        src={pdfUrl}
                        width="900px"
                        height="1000px"
                        frameBorder="0"
                        allowFullScreen
                    >
                        This browser does not support PDFs. Please download the PDF to view it: <a href={pdfUrl}>Download PDF</a>.
                    </iframe>
                )}
            </div>
          }
          onClose={() => {
            setPdfUrl(null); 
            onClose();
          }}
        />

    );
}