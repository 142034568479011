import { secondServerAPI } from '../../pages/utils/agent';
import Axios from 'axios';

export async function checkUserCredits(userId: number) {
    try {
        const response = await Axios.get(`${secondServerAPI}/rest/api/v1/ufc/getUserCredits`, {
            headers: {
                'user-id': userId,
            }
        });
        return response.data.availableCredits >= 1;
    } catch (error) {
        console.error("Error checking user credits:", error);
        return false;
    }
}
