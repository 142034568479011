import { fixValueToNumber } from "../../../components/3d-models/utils";
import { ProfileSection, ShapeType } from "../types";

export const profilesEuropain1 = [
  {
    profile_section_id: 2182,
    name: "TUB40402",
    type: "Tube",
    designation: "TUB40402",
    shape: "RHS",
    country_code: "European",
    ax: "3,86",
    ax_global: 386,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    ix: 14,
    ix_global: 140000,
    iy: "8,94",
    iy_global: 89400,
    iz: "8,94",
    iz_global: 89400,
    t: "2,6",
    t_global: "2,6",
    zx: "5,46",
    zx_global: 5460,
    zy: "5,46",
    zy_global: 5460,
  },
  {
    profile_section_id: 2183,
    name: "TUB50302",
    type: "Tube",
    designation: "TUB50302",
    shape: "RHS",
    country_code: "European",
    ax: "3,86",
    ax_global: 386,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    ix: "12,1",
    ix_global: 121000,
    iy: "5,45",
    iy_global: 54500,
    iz: "12,4",
    iz_global: 124000,
    t: "2,6",
    t_global: "2,6",
    zx: "6,31",
    zx_global: 6310,
    zy: "5,16",
    zy_global: 5160,
  },
  {
    profile_section_id: 2184,
    name: "TUB40403",
    type: "Tube",
    designation: "TUB40403",
    shape: "RHS",
    country_code: "European",
    ax: "4,46",
    ax_global: 446,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    ix: "16,5",
    ix_global: 165000,
    iy: "10,4",
    iy_global: 104000,
    iz: "10,4",
    iz_global: 104000,
    t: "3,2",
    t_global: "3,2",
    zx: "6,52",
    zx_global: 6520,
    zy: "6,52",
    zy_global: 6520,
  },
  {
    profile_section_id: 2185,
    name: "TUB50303",
    type: "Tube",
    designation: "TUB50303",
    shape: "RHS",
    country_code: "European",
    ax: "4,66",
    ax_global: 466,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    ix: "14,2",
    ix_global: 142000,
    iy: "6,31",
    iy_global: 63100,
    iz: "14,5",
    iz_global: 145000,
    t: "3,2",
    t_global: "3,2",
    zx: "7,53",
    zx_global: 7530,
    zy: "6,07",
    zy_global: 6070,
  },
  {
    profile_section_id: 2186,
    name: "TUB40404",
    type: "Tube",
    designation: "TUB40404",
    shape: "RHS",
    country_code: "European",
    ax: "5,68",
    ax_global: 568,
    b: 40,
    b_global: 40,
    d: 40,
    d_global: 40,
    ix: "19,5",
    ix_global: 195000,
    iy: "12,1",
    iy_global: 121000,
    iz: "12,1",
    iz_global: 121000,
    t: 4,
    t_global: 4,
    zx: "7,81",
    zx_global: 7810,
    zy: "7,81",
    zy_global: 7810,
  },
  {
    profile_section_id: 2187,
    name: "TUB50304",
    type: "Tube",
    designation: "TUB50304",
    shape: "RHS",
    country_code: "European",
    ax: "5,68",
    ax_global: 568,
    b: 30,
    b_global: 30,
    d: 50,
    d_global: 50,
    ix: "16,6",
    ix_global: 166000,
    iy: "7,25",
    iy_global: 72500,
    iz: 17,
    iz_global: 170000,
    t: 4,
    t_global: 4,
    zx: "9,05",
    zx_global: 9050,
    zy: "7,14",
    zy_global: 7140,
  },
  {
    profile_section_id: 2188,
    name: "TUB50503",
    type: "Tube",
    designation: "TUB50503",
    shape: "RHS",
    country_code: "European",
    ax: "5,94",
    ax_global: 594,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    ix: "33,8",
    ix_global: 338000,
    iy: "21,6",
    iy_global: 216000,
    iz: "21,6",
    iz_global: 216000,
    t: "3,2",
    t_global: "3,2",
    zx: "10,53",
    zx_global: 10530,
    zy: "10,53",
    zy_global: 10530,
  },
  {
    profile_section_id: 2189,
    name: "TUB60403",
    type: "Tube",
    designation: "TUB60403",
    shape: "RHS",
    country_code: "European",
    ax: "5,94",
    ax_global: 594,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    ix: "30,6",
    ix_global: 306000,
    iy: "14,8",
    iy_global: 148000,
    iz: "28,3",
    iz_global: 283000,
    t: "3,2",
    t_global: "3,2",
    zx: "11,87",
    zx_global: 11870,
    zy: "10,68",
    zy_global: 10680,
  },
  {
    profile_section_id: 2190,
    name: "TUB60603",
    type: "Tube",
    designation: "TUB60603",
    shape: "RHS",
    country_code: "European",
    ax: "7,22",
    ax_global: 722,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    ix: "60,1",
    ix_global: 601000,
    iy: "38,7",
    iy_global: 387000,
    iz: "38,7",
    iz_global: 387000,
    t: "3,2",
    t_global: "3,2",
    zx: "15,5",
    zx_global: 15500,
    zy: "15,5",
    zy_global: 15500,
  },
  {
    profile_section_id: 2191,
    name: "TUB80403",
    type: "Tube",
    designation: "TUB80403",
    shape: "RHS",
    country_code: "European",
    ax: "7,22",
    ax_global: 722,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: "46,1",
    ix_global: 461000,
    iy: "19,1",
    iy_global: 191000,
    iz: "58,1",
    iz_global: 581000,
    t: "3,2",
    t_global: "3,2",
    zx: "18,5",
    zx_global: 18500,
    zy: "13,03",
    zy_global: 13030,
  },
  {
    profile_section_id: 2192,
    name: "TUB50504",
    type: "Tube",
    designation: "TUB50504",
    shape: "RHS",
    country_code: "European",
    ax: "7,28",
    ax_global: 728,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    ix: "40,4",
    ix_global: 404000,
    iy: "25,5",
    iy_global: 255000,
    iz: "25,5",
    iz_global: 255000,
    t: 4,
    t_global: 4,
    zx: "12,73",
    zx_global: 12730,
    zy: "12,73",
    zy_global: 12730,
  },
  {
    profile_section_id: 2193,
    name: "TUB60404",
    type: "Tube",
    designation: "TUB60404",
    shape: "RHS",
    country_code: "European",
    ax: "7,28",
    ax_global: 728,
    b: 40,
    b_global: 40,
    d: 60,
    d_global: 60,
    ix: "36,6",
    ix_global: 366000,
    iy: "17,3",
    iy_global: 173000,
    iz: "33,6",
    iz_global: 336000,
    t: 4,
    t_global: 4,
    zx: "14,37",
    zx_global: 14370,
    zy: "12,74",
    zy_global: 12740,
  },
  {
    profile_section_id: 2194,
    name: "TUB90503",
    type: "Tube",
    designation: "TUB90503",
    shape: "RHS",
    country_code: "European",
    ax: "8,5",
    ax_global: 850,
    b: 50,
    b_global: 50,
    d: 90,
    d_global: 90,
    ix: "80,8",
    ix_global: 808000,
    iy: "35,6",
    iy_global: 356000,
    iz: "90,3",
    iz_global: 903000,
    t: "3,2",
    t_global: "3,2",
    zx: "25,07",
    zx_global: 25070,
    zy: "19,56",
    zy_global: 19560,
  },
  {
    profile_section_id: 2195,
    name: "TUB50505",
    type: "Tube",
    designation: "TUB50505",
    shape: "RHS",
    country_code: "European",
    ax: "8,88",
    ax_global: 888,
    b: 50,
    b_global: 50,
    d: 50,
    d_global: 50,
    ix: "47,6",
    ix_global: 476000,
    iy: "29,6",
    iy_global: 296000,
    iz: "29,6",
    iz_global: 296000,
    t: 5,
    t_global: 5,
    zx: "15,25",
    zx_global: 15250,
    zy: "15,25",
    zy_global: 15250,
  },
  {
    profile_section_id: 2196,
    name: "TUB60604",
    type: "Tube",
    designation: "TUB60604",
    shape: "RHS",
    country_code: "European",
    ax: "8,88",
    ax_global: 888,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    ix: "72,4",
    ix_global: 724000,
    iy: "46,1",
    iy_global: 461000,
    iz: "46,1",
    iz_global: 461000,
    t: 4,
    t_global: 4,
    zx: "18,85",
    zx_global: 18850,
    zy: "18,85",
    zy_global: 18850,
  },
  {
    profile_section_id: 2197,
    name: "TUB80404",
    type: "Tube",
    designation: "TUB80404",
    shape: "RHS",
    country_code: "European",
    ax: "8,88",
    ax_global: 888,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: "55,1",
    ix_global: 551000,
    iy: "22,6",
    iy_global: 226000,
    iz: "69,6",
    iz_global: 696000,
    t: 4,
    t_global: 4,
    zx: "22,53",
    zx_global: 22530,
    zy: "15,62",
    zy_global: 15620,
  },
  {
    profile_section_id: 2198,
    name: "TUB100503",
    type: "Tube",
    designation: "TUB100503",
    shape: "RHS",
    country_code: "European",
    ax: "9,14",
    ax_global: 914,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    ix: "93,3",
    ix_global: 933000,
    iy: "39,1",
    iy_global: 391000,
    iz: 117,
    iz_global: 1170000,
    t: "3,2",
    t_global: "3,2",
    zx: "29,51",
    zx_global: 29510,
    zy: "21,06",
    zy_global: 21060,
  },
  {
    profile_section_id: 2199,
    name: "TUB90504",
    type: "Tube",
    designation: "TUB90504",
    shape: "RHS",
    country_code: "European",
    ax: "10,5",
    ax_global: 1050,
    b: 50,
    b_global: 50,
    d: 90,
    d_global: 90,
    ix: "97,4",
    ix_global: 974000,
    iy: "42,5",
    iy_global: 425000,
    iz: 109,
    iz_global: 1090000,
    t: 4,
    t_global: 4,
    zx: "30,65",
    zx_global: 30650,
    zy: "23,62",
    zy_global: 23620,
  },
  {
    profile_section_id: 2200,
    name: "TUB60605",
    type: "Tube",
    designation: "TUB60605",
    shape: "RHS",
    country_code: "European",
    ax: "10,9",
    ax_global: 1090,
    b: 60,
    b_global: 60,
    d: 60,
    d_global: 60,
    ix: "86,3",
    ix_global: 863000,
    iy: "54,4",
    iy_global: 544000,
    iz: "54,4",
    iz_global: 544000,
    t: 5,
    t_global: 5,
    zx: "22,75",
    zx_global: 22750,
    zy: "22,75",
    zy_global: 22750,
  },
  {
    profile_section_id: 2201,
    name: "TUB80803",
    type: "Tube",
    designation: "TUB80803",
    shape: "RHS",
    country_code: "European",
    ax: "10,9",
    ax_global: 1090,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    ix: 164,
    ix_global: 1640000,
    iy: 106,
    iy_global: 1060000,
    iz: 106,
    iz_global: 1060000,
    t: "3,6",
    t_global: "3,6",
    zx: "31,54",
    zx_global: 31540,
    zy: "31,54",
    zy_global: 31540,
  },
  {
    profile_section_id: 2202,
    name: "TUB80405",
    type: "Tube",
    designation: "TUB80405",
    shape: "RHS",
    country_code: "European",
    ax: "10,9",
    ax_global: 1090,
    b: 40,
    b_global: 40,
    d: 80,
    d_global: 80,
    ix: 65,
    ix_global: 650000,
    iy: "26,2",
    iy_global: 262000,
    iz: "82,4",
    iz_global: 824000,
    t: 5,
    t_global: 5,
    zx: "27,25",
    zx_global: 27250,
    zy: "18,5",
    zy_global: 18500,
  },
  {
    profile_section_id: 2203,
    name: "TUB100504",
    type: "Tube",
    designation: "TUB100504",
    shape: "RHS",
    country_code: "European",
    ax: "11,3",
    ax_global: 1130,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    ix: 113,
    ix_global: 1130000,
    iy: "46,7",
    iy_global: 467000,
    iz: 142,
    iz_global: 1420000,
    t: 4,
    t_global: 4,
    zx: "36,13",
    zx_global: 36130,
    zy: "25,46",
    zy_global: 25460,
  },
  {
    profile_section_id: 2204,
    name: "TUB80804",
    type: "Tube",
    designation: "TUB80804",
    shape: "RHS",
    country_code: "European",
    ax: "12,1",
    ax_global: 1210,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    ix: 180,
    ix_global: 1800000,
    iy: 116,
    iy_global: 1160000,
    iz: 116,
    iz_global: 1160000,
    t: 4,
    t_global: 4,
    zx: "34,69",
    zx_global: 34690,
    zy: "34,69",
    zy_global: 34690,
  },
  {
    profile_section_id: 2205,
    name: "TUB120603",
    type: "Tube",
    designation: "TUB120603",
    shape: "RHS",
    country_code: "European",
    ax: "12,4",
    ax_global: 1240,
    b: 60,
    b_global: 60,
    d: 120,
    d_global: 120,
    ix: 183,
    ix_global: 1830000,
    iy: "76,9",
    iy_global: 769000,
    iz: 230,
    iz_global: 2300000,
    t: "3,6",
    t_global: "3,6",
    zx: "48,05",
    zx_global: 48050,
    zy: "34,4",
    zy_global: 34400,
  },
  {
    profile_section_id: 2206,
    name: "TUB90505",
    type: "Tube",
    designation: "TUB90505",
    shape: "RHS",
    country_code: "European",
    ax: "12,9",
    ax_global: 1290,
    b: 50,
    b_global: 50,
    d: 90,
    d_global: 90,
    ix: 116,
    ix_global: 1160000,
    iy: 50,
    iy_global: 500000,
    iz: 130,
    iz_global: 1300000,
    t: 5,
    t_global: 5,
    zx: "37,25",
    zx_global: 37250,
    zy: "28,25",
    zy_global: 28250,
  },
  {
    profile_section_id: 2207,
    name: "TUB100505",
    type: "Tube",
    designation: "TUB100505",
    shape: "RHS",
    country_code: "European",
    ax: "13,9",
    ax_global: 1390,
    b: 50,
    b_global: 50,
    d: 100,
    d_global: 100,
    ix: 135,
    ix_global: 1350000,
    iy: "55,1",
    iy_global: 551000,
    iz: 170,
    iz_global: 1700000,
    t: 5,
    t_global: 5,
    zx: 44,
    zx_global: 44000,
    zy: "30,5",
    zy_global: 30500,
  },
  {
    profile_section_id: 2208,
    name: "TUB80805",
    type: "Tube",
    designation: "TUB80805",
    shape: "RHS",
    country_code: "European",
    ax: "14,9",
    ax_global: 1490,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    ix: 217,
    ix_global: 2170000,
    iy: 139,
    iy_global: 1390000,
    iz: 139,
    iz_global: 1390000,
    t: 5,
    t_global: 5,
    zx: "42,25",
    zx_global: 42250,
    zy: "42,25",
    zy_global: 42250,
  },
  {
    profile_section_id: 2209,
    name: "TUB1001004",
    type: "Tube",
    designation: "TUB1001004",
    shape: "RHS",
    country_code: "European",
    ax: "15,3",
    ax_global: 1530,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 361,
    ix_global: 3610000,
    iy: 234,
    iy_global: 2340000,
    iz: 234,
    iz_global: 2340000,
    t: 4,
    t_global: 4,
    zx: "55,33",
    zx_global: 55330,
    zy: "55,33",
    zy_global: 55330,
  },
  {
    profile_section_id: 2210,
    name: "TUB120605",
    type: "Tube",
    designation: "TUB120605",
    shape: "RHS",
    country_code: "European",
    ax: "16,9",
    ax_global: 1690,
    b: 60,
    b_global: 60,
    d: 120,
    d_global: 120,
    ix: 242,
    ix_global: 2420000,
    iy: "99,9",
    iy_global: 999000,
    iz: 304,
    iz_global: 3040000,
    t: 5,
    t_global: 5,
    zx: "64,75",
    zx_global: 64750,
    zy: "45,5",
    zy_global: 45500,
  },
  {
    profile_section_id: 2211,
    name: "TUB80806",
    type: "Tube",
    designation: "TUB80806",
    shape: "RHS",
    country_code: "European",
    ax: "18,4",
    ax_global: 1840,
    b: 80,
    b_global: 80,
    d: 80,
    d_global: 80,
    ix: 261,
    ix_global: 2610000,
    iy: 165,
    iy_global: 1650000,
    iz: 165,
    iz_global: 1650000,
    t: "6,3",
    t_global: "6,3",
    zx: "51,45",
    zx_global: 51450,
    zy: "51,45",
    zy_global: 51450,
  },
  {
    profile_section_id: 2212,
    name: "TUB1001005",
    type: "Tube",
    designation: "TUB1001005",
    shape: "RHS",
    country_code: "European",
    ax: "18,9",
    ax_global: 1890,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 439,
    ix_global: 4390000,
    iy: 283,
    iy_global: 2830000,
    iz: 283,
    iz_global: 2830000,
    t: 5,
    t_global: 5,
    zx: "67,75",
    zx_global: 67750,
    zy: "67,75",
    zy_global: 67750,
  },
  {
    profile_section_id: 2213,
    name: "TUB120805",
    type: "Tube",
    designation: "TUB120805",
    shape: "RHS",
    country_code: "European",
    ax: "18,9",
    ax_global: 1890,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    ix: 401,
    ix_global: 4010000,
    iy: 195,
    iy_global: 1950000,
    iz: 370,
    iz_global: 3700000,
    t: 5,
    t_global: 5,
    zx: "76,25",
    zx_global: 76250,
    zy: "69,5",
    zy_global: 69500,
  },
  {
    profile_section_id: 2214,
    name: "TUB120606",
    type: "Tube",
    designation: "TUB120606",
    shape: "RHS",
    country_code: "European",
    ax: "20,9",
    ax_global: 2090,
    b: 60,
    b_global: 60,
    d: 120,
    d_global: 120,
    ix: 290,
    ix_global: 2900000,
    iy: 118,
    iy_global: 1180000,
    iz: 366,
    iz_global: 3660000,
    t: "6,3",
    t_global: "6,3",
    zx: "79,31",
    zx_global: 79310,
    zy: "54,75",
    zy_global: 54750,
  },
  {
    profile_section_id: 2215,
    name: "TUB140805",
    type: "Tube",
    designation: "TUB140805",
    shape: "RHS",
    country_code: "European",
    ax: "20,9",
    ax_global: 2090,
    b: 80,
    b_global: 80,
    d: 140,
    d_global: 140,
    ix: 499,
    ix_global: 4990000,
    iy: 223,
    iy_global: 2230000,
    iz: 541,
    iz_global: 5410000,
    t: 5,
    t_global: 5,
    zx: "96,25",
    zx_global: 96250,
    zy: 77,
    zy_global: 77000,
  },
  {
    profile_section_id: 2216,
    name: "TUB160805",
    type: "Tube",
    designation: "TUB160805",
    shape: "RHS",
    country_code: "European",
    ax: "22,9",
    ax_global: 2290,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    ix: 599,
    ix_global: 5990000,
    iy: 251,
    iy_global: 2510000,
    iz: 753,
    iz_global: 7530000,
    t: 5,
    t_global: 5,
    zx: "118,25",
    zx_global: 118250,
    zy: "84,5",
    zy_global: 84500,
  },
  {
    profile_section_id: 2217,
    name: "TUB1201205",
    type: "Tube",
    designation: "TUB1201205",
    shape: "RHS",
    country_code: "European",
    ax: "22,9",
    ax_global: 2290,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    ix: 775,
    ix_global: 7750000,
    iy: 503,
    iy_global: 5030000,
    iz: 503,
    iz_global: 5030000,
    t: 5,
    t_global: 5,
    zx: "99,25",
    zx_global: 99250,
    zy: "99,25",
    zy_global: 99250,
  },
  {
    profile_section_id: 2218,
    name: "TUB1001006",
    type: "Tube",
    designation: "TUB1001006",
    shape: "RHS",
    country_code: "European",
    ax: "23,4",
    ax_global: 2340,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 533,
    ix_global: 5330000,
    iy: 341,
    iy_global: 3410000,
    iz: 341,
    iz_global: 3410000,
    t: "6,3",
    t_global: "6,3",
    zx: "83,09",
    zx_global: 83090,
    zy: "83,09",
    zy_global: 83090,
  },
  {
    profile_section_id: 2219,
    name: "TUB120806",
    type: "Tube",
    designation: "TUB120806",
    shape: "RHS",
    country_code: "European",
    ax: "23,4",
    ax_global: 2340,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    ix: 486,
    ix_global: 4860000,
    iy: 234,
    iy_global: 2340000,
    iz: 447,
    iz_global: 4470000,
    t: "6,3",
    t_global: "6,3",
    zx: "93,64",
    zx_global: 93640,
    zy: "84,34",
    zy_global: 84340,
  },
  {
    profile_section_id: 2220,
    name: "TUB1501005",
    type: "Tube",
    designation: "TUB1501005",
    shape: "RHS",
    country_code: "European",
    ax: "23,9",
    ax_global: 2390,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    ix: 806,
    ix_global: 8060000,
    iy: 396,
    iy_global: 3960000,
    iz: 747,
    iz_global: 7470000,
    t: 5,
    t_global: 5,
    zx: "121,5",
    zx_global: 121500,
    zy: "111,75",
    zy_global: 111750,
  },
  {
    profile_section_id: 2221,
    name: "TUB140806",
    type: "Tube",
    designation: "TUB140806",
    shape: "RHS",
    country_code: "European",
    ax: "25,9",
    ax_global: 2590,
    b: 80,
    b_global: 80,
    d: 140,
    d_global: 140,
    ix: 606,
    ix_global: 6060000,
    iy: 268,
    iy_global: 2680000,
    iz: 656,
    iz_global: 6560000,
    t: "6,3",
    t_global: "6,3",
    zx: "118,51",
    zx_global: 118510,
    zy: "93,62",
    zy_global: 93620,
  },
  {
    profile_section_id: 2222,
    name: "TUB1401405",
    type: "Tube",
    designation: "TUB1401405",
    shape: "RHS",
    country_code: "European",
    ax: "26,9",
    ax_global: 2690,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    ix: 1250,
    ix_global: 12500000,
    iy: 814,
    iy_global: 8140000,
    iz: 814,
    iz_global: 8140000,
    t: 5,
    t_global: 5,
    zx: "136,75",
    zx_global: 136750,
    zy: "136,75",
    zy_global: 136750,
  },
  {
    profile_section_id: 2223,
    name: "TUB1201206",
    type: "Tube",
    designation: "TUB1201206",
    shape: "RHS",
    country_code: "European",
    ax: "28,5",
    ax_global: 2850,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    ix: 949,
    ix_global: 9490000,
    iy: 610,
    iy_global: 6100000,
    iz: 610,
    iz_global: 6100000,
    t: "6,3",
    t_global: "6,3",
    zx: "122,29",
    zx_global: 122290,
    zy: "122,29",
    zy_global: 122290,
  },
  {
    profile_section_id: 2224,
    name: "TUB160806",
    type: "Tube",
    designation: "TUB160806",
    shape: "RHS",
    country_code: "European",
    ax: "28,5",
    ax_global: 2850,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    ix: 729,
    ix_global: 7290000,
    iy: 302,
    iy_global: 3020000,
    iz: 917,
    iz_global: 9170000,
    t: "6,3",
    t_global: "6,3",
    zx: "145,9",
    zx_global: 145900,
    zy: "102,91",
    zy_global: 102910,
  },
  {
    profile_section_id: 2225,
    name: "TUB2001005",
    type: "Tube",
    designation: "TUB2001005",
    shape: "RHS",
    country_code: "European",
    ax: "28,9",
    ax_global: 2890,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    ix: 1200,
    ix_global: 12000000,
    iy: 509,
    iy_global: 5090000,
    iz: 1510,
    iz_global: 15100000,
    t: 5,
    t_global: 5,
    zx: "187,75",
    zx_global: 187750,
    zy: "135,5",
    zy_global: 135500,
  },
  {
    profile_section_id: 2226,
    name: "TUB1001008",
    type: "Tube",
    designation: "TUB1001008",
    shape: "RHS",
    country_code: "European",
    ax: "29,1",
    ax_global: 2910,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 646,
    ix_global: 6460000,
    iy: 408,
    iy_global: 4080000,
    iz: 408,
    iz_global: 4080000,
    t: 8,
    t_global: 8,
    zx: "101,82",
    zx_global: 101820,
    zy: "101,82",
    zy_global: 101820,
  },
  {
    profile_section_id: 2227,
    name: "TUB120808",
    type: "Tube",
    designation: "TUB120808",
    shape: "RHS",
    country_code: "European",
    ax: "29,1",
    ax_global: 2910,
    b: 80,
    b_global: 80,
    d: 120,
    d_global: 120,
    ix: 586,
    ix_global: 5860000,
    iy: 278,
    iy_global: 2780000,
    iz: 537,
    iz_global: 5370000,
    t: 8,
    t_global: 8,
    zx: "114,94",
    zx_global: 114940,
    zy: "101,89",
    zy_global: 101890,
  },
  {
    profile_section_id: 2228,
    name: "TUB1501006",
    type: "Tube",
    designation: "TUB1501006",
    shape: "RHS",
    country_code: "European",
    ax: "29,7",
    ax_global: 2970,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    ix: 985,
    ix_global: 9850000,
    iy: 479,
    iy_global: 4790000,
    iz: 910,
    iz_global: 9100000,
    t: "6,3",
    t_global: "6,3",
    zx: 150,
    zx_global: 150000,
    zy: "136,67",
    zy_global: 136670,
  },
  {
    profile_section_id: 2229,
    name: "TUB140808",
    type: "Tube",
    designation: "TUB140808",
    shape: "RHS",
    country_code: "European",
    ax: "32,3",
    ax_global: 3230,
    b: 80,
    b_global: 80,
    d: 140,
    d_global: 140,
    ix: 732,
    ix_global: 7320000,
    iy: 319,
    iy_global: 3190000,
    iz: 793,
    iz_global: 7930000,
    t: 8,
    t_global: 8,
    zx: "145,98",
    zx_global: 145980,
    zy: "113,41",
    zy_global: 113410,
  },
  {
    profile_section_id: 2230,
    name: "TUB1401406",
    type: "Tube",
    designation: "TUB1401406",
    shape: "RHS",
    country_code: "European",
    ax: "33,5",
    ax_global: 3350,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    ix: 1540,
    ix_global: 15400000,
    iy: 994,
    iy_global: 9940000,
    iz: 994,
    iz_global: 9940000,
    t: "6,3",
    t_global: "6,3",
    zx: "169,05",
    zx_global: 169050,
    zy: "169,05",
    zy_global: 169050,
  },
  {
    profile_section_id: 2231,
    name: "TUB10010010",
    type: "Tube",
    designation: "TUB10010010",
    shape: "RHS",
    country_code: "European",
    ax: "35,5",
    ax_global: 3550,
    b: 100,
    b_global: 100,
    d: 100,
    d_global: 100,
    ix: 761,
    ix_global: 7610000,
    iy: 474,
    iy_global: 4740000,
    iz: 474,
    iz_global: 4740000,
    t: 10,
    t_global: 10,
    zx: 122,
    zx_global: 122000,
    zy: 122,
    zy_global: 122000,
  },
  {
    profile_section_id: 2232,
    name: "TUB1201208",
    type: "Tube",
    designation: "TUB1201208",
    shape: "RHS",
    country_code: "European",
    ax: "35,5",
    ax_global: 3550,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    ix: 1160,
    ix_global: 11600000,
    iy: 738,
    iy_global: 7380000,
    iz: 738,
    iz_global: 7380000,
    t: 8,
    t_global: 8,
    zx: "150,78",
    zx_global: 150780,
    zy: "150,78",
    zy_global: 150780,
  },
  {
    profile_section_id: 2233,
    name: "TUB160808",
    type: "Tube",
    designation: "TUB160808",
    shape: "RHS",
    country_code: "European",
    ax: "35,5",
    ax_global: 3550,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    ix: 882,
    ix_global: 8820000,
    iy: 361,
    iy_global: 3610000,
    iz: 1110,
    iz_global: 11100000,
    t: 8,
    t_global: 8,
    zx: "180,22",
    zx_global: 180220,
    zy: "124,93",
    zy_global: 124930,
  },
  {
    profile_section_id: 2234,
    name: "TUB2001006",
    type: "Tube",
    designation: "TUB2001006",
    shape: "RHS",
    country_code: "European",
    ax: 36,
    ax_global: 3600,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    ix: 1470,
    ix_global: 14700000,
    iy: 618,
    iy_global: 6180000,
    iz: 1850,
    iz_global: 18500000,
    t: "6,3",
    t_global: "6,3",
    zx: "232,66",
    zx_global: 232660,
    zy: "166,19",
    zy_global: 166190,
  },
  {
    profile_section_id: 2235,
    name: "TUB1501008",
    type: "Tube",
    designation: "TUB1501008",
    shape: "RHS",
    country_code: "European",
    ax: "37,1",
    ax_global: 3710,
    b: 100,
    b_global: 100,
    d: 150,
    d_global: 150,
    ix: 1200,
    ix_global: 12000000,
    iy: 577,
    iy_global: 5770000,
    iz: 1110,
    iz_global: 11100000,
    t: 8,
    t_global: 8,
    zx: "185,42",
    zx_global: 185420,
    zy: "166,85",
    zy_global: 166850,
  },
  {
    profile_section_id: 2236,
    name: "TUB1601606",
    type: "Tube",
    designation: "TUB1601606",
    shape: "RHS",
    country_code: "European",
    ax: "37,7",
    ax_global: 3770,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    ix: 2330,
    ix_global: 23300000,
    iy: 1460,
    iy_global: 14600000,
    iz: 1460,
    iz_global: 14600000,
    t: "6,3",
    t_global: "6,3",
    zx: "223,37",
    zx_global: 223370,
    zy: "223,37",
    zy_global: 223370,
  },
  {
    profile_section_id: 2237,
    name: "TUB1401408",
    type: "Tube",
    designation: "TUB1401408",
    shape: "RHS",
    country_code: "European",
    ax: "41,9",
    ax_global: 4190,
    b: 140,
    b_global: 140,
    d: 140,
    d_global: 140,
    ix: 1890,
    ix_global: 18900000,
    iy: 1210,
    iy_global: 12100000,
    iz: 1210,
    iz_global: 12100000,
    t: 8,
    t_global: 8,
    zx: "209,34",
    zx_global: 209340,
    zy: "209,34",
    zy_global: 209340,
  },
  {
    profile_section_id: 2238,
    name: "TUB12012010",
    type: "Tube",
    designation: "TUB12012010",
    shape: "RHS",
    country_code: "European",
    ax: "43,5",
    ax_global: 4350,
    b: 120,
    b_global: 120,
    d: 120,
    d_global: 120,
    ix: 1380,
    ix_global: 13800000,
    iy: 870,
    iy_global: 8700000,
    iz: 870,
    iz_global: 8700000,
    t: 10,
    t_global: 10,
    zx: 182,
    zx_global: 182000,
    zy: 182,
    zy_global: 182000,
  },
  {
    profile_section_id: 2239,
    name: "TUB1608010",
    type: "Tube",
    designation: "TUB1608010",
    shape: "RHS",
    country_code: "European",
    ax: "43,5",
    ax_global: 4350,
    b: 80,
    b_global: 80,
    d: 160,
    d_global: 160,
    ix: 1040,
    ix_global: 10400000,
    iy: 419,
    iy_global: 4190000,
    iz: 1320,
    iz_global: 13200000,
    t: 10,
    t_global: 10,
    zx: 218,
    zx_global: 218000,
    zy: 148,
    zy_global: 148000,
  },
  {
    profile_section_id: 2240,
    name: "TUB1801806",
    type: "Tube",
    designation: "TUB1801806",
    shape: "RHS",
    country_code: "European",
    ax: "43,6",
    ax_global: 4360,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    ix: 3360,
    ix_global: 33600000,
    iy: 2190,
    iy_global: 21900000,
    iz: 2190,
    iz_global: 21900000,
    t: "6,3",
    t_global: "6,3",
    zx: "285,25",
    zx_global: 285250,
    zy: 285,
    zy_global: 285000,
  },
  {
    profile_section_id: 2241,
    name: "TUB2001008",
    type: "Tube",
    designation: "TUB2001008",
    shape: "RHS",
    country_code: "European",
    ax: "45,1",
    ax_global: 4510,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    ix: 1800,
    ix_global: 18000000,
    iy: 747,
    iy_global: 7470000,
    iz: 2270,
    iz_global: 22700000,
    t: 8,
    t_global: 8,
    zx: "289,02",
    zx_global: 289020,
    zy: "203,65",
    zy_global: 203650,
  },
  {
    profile_section_id: 2242,
    name: "TUB1601608",
    type: "Tube",
    designation: "TUB1601608",
    shape: "RHS",
    country_code: "European",
    ax: 47,
    ax_global: 4700,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    ix: 2880,
    ix_global: 28800000,
    iy: 1780,
    iy_global: 17800000,
    iz: 1780,
    iz_global: 17800000,
    t: 8,
    t_global: 8,
    zx: "277,5",
    zx_global: 277500,
    zy: "277,5",
    zy_global: 277500,
  },
  {
    profile_section_id: 2243,
    name: "TUB2002006",
    type: "Tube",
    designation: "TUB2002006",
    shape: "RHS",
    country_code: "European",
    ax: "48,6",
    ax_global: 4860,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 4650,
    ix_global: 46500000,
    iy: 3030,
    iy_global: 30300000,
    iz: 3030,
    iz_global: 30300000,
    t: "6,3",
    t_global: "6,3",
    zx: "354,69",
    zx_global: 354690,
    zy: "354,69",
    zy_global: 354690,
  },
  {
    profile_section_id: 2244,
    name: "TUB2501506",
    type: "Tube",
    designation: "TUB2501506",
    shape: "RHS",
    country_code: "European",
    ax: "48,6",
    ax_global: 4860,
    b: 150,
    b_global: 150,
    d: 250,
    d_global: 250,
    ix: 4090,
    ix_global: 40900000,
    iy: 1890,
    iy_global: 18900000,
    iz: 4180,
    iz_global: 41800000,
    t: "6,3",
    t_global: "6,3",
    zx: "407,83",
    zx_global: 407830,
    zy: "345,26",
    zy_global: 345260,
  },
  {
    profile_section_id: 2245,
    name: "TUB1801808",
    type: "Tube",
    designation: "TUB1801808",
    shape: "RHS",
    country_code: "European",
    ax: "54,7",
    ax_global: 5470,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    ix: 4160,
    ix_global: 41600000,
    iy: 2690,
    iy_global: 26900000,
    iz: 2690,
    iz_global: 26900000,
    t: 8,
    t_global: 8,
    zx: "355,26",
    zx_global: 355260,
    zy: "355,26",
    zy_global: 355260,
  },
  {
    profile_section_id: 2246,
    name: "TUB20010010",
    type: "Tube",
    designation: "TUB20010010",
    shape: "RHS",
    country_code: "European",
    ax: "55,5",
    ax_global: 5550,
    b: 100,
    b_global: 100,
    d: 200,
    d_global: 200,
    ix: 2150,
    ix_global: 21500000,
    iy: 881,
    iy_global: 8810000,
    iz: 2720,
    iz_global: 27200000,
    t: 10,
    t_global: 10,
    zx: 352,
    zx_global: 352000,
    zy: 244,
    zy_global: 244000,
  },
  {
    profile_section_id: 2247,
    name: "TUB16016010",
    type: "Tube",
    designation: "TUB16016010",
    shape: "RHS",
    country_code: "European",
    ax: "57,4",
    ax_global: 5740,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    ix: 3470,
    ix_global: 34700000,
    iy: 2100,
    iy_global: 21000000,
    iz: 2100,
    iz_global: 21000000,
    t: 10,
    t_global: 10,
    zx: 338,
    zx_global: 338000,
    zy: 338,
    zy_global: 338000,
  },
  {
    profile_section_id: 2248,
    name: "TUB2002008",
    type: "Tube",
    designation: "TUB2002008",
    shape: "RHS",
    country_code: "European",
    ax: "61,1",
    ax_global: 6110,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 5770,
    ix_global: 57700000,
    iy: 3740,
    iy_global: 37400000,
    iz: 3740,
    iz_global: 37400000,
    t: 8,
    t_global: 8,
    zx: "442,62",
    zx_global: 442620,
    zy: "442,62",
    zy_global: 442620,
  },
  {
    profile_section_id: 2249,
    name: "TUB2501508",
    type: "Tube",
    designation: "TUB2501508",
    shape: "RHS",
    country_code: "European",
    ax: "61,1",
    ax_global: 6110,
    b: 150,
    b_global: 150,
    d: 250,
    d_global: 250,
    ix: 5010,
    ix_global: 50100000,
    iy: 2320,
    iy_global: 23200000,
    iz: 5170,
    iz_global: 51700000,
    t: 8,
    t_global: 8,
    zx: "509,42",
    zx_global: 509420,
    zy: "427,65",
    zy_global: 427650,
  },
  {
    profile_section_id: 2250,
    name: "TUB2502506",
    type: "Tube",
    designation: "TUB2502506",
    shape: "RHS",
    country_code: "European",
    ax: "61,2",
    ax_global: 6120,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 9230,
    ix_global: 92300000,
    iy: 6050,
    iy_global: 60500000,
    iz: 6050,
    iz_global: 60500000,
    t: "6,3",
    t_global: "6,3",
    zx: "561,36",
    zx_global: 561360,
    zy: "561,36",
    zy_global: 561360,
  },
  {
    profile_section_id: 2251,
    name: "TUB3002006",
    type: "Tube",
    designation: "TUB3002006",
    shape: "RHS",
    country_code: "European",
    ax: "61,2",
    ax_global: 6120,
    b: 200,
    b_global: 200,
    d: 300,
    d_global: 300,
    ix: 8470,
    ix_global: 84700000,
    iy: 4220,
    iy_global: 42200000,
    iz: 7880,
    iz_global: 78800000,
    t: "6,3",
    t_global: "6,3",
    zx: "630,25",
    zx_global: 630250,
    zy: "587,34",
    zy_global: 587340,
  },
  {
    profile_section_id: 2252,
    name: "TUB18018010",
    type: "Tube",
    designation: "TUB18018010",
    shape: "RHS",
    country_code: "European",
    ax: "67,5",
    ax_global: 6750,
    b: 180,
    b_global: 180,
    d: 180,
    d_global: 180,
    ix: 5040,
    ix_global: 50400000,
    iy: 3240,
    iy_global: 32400000,
    iz: 3240,
    iz_global: 32400000,
    t: 10,
    t_global: 10,
    zx: 434,
    zx_global: 434000,
    zy: 434,
    zy_global: 434000,
  },
  {
    profile_section_id: 2253,
    name: "TUB16016012",
    type: "Tube",
    designation: "TUB16016012",
    shape: "RHS",
    country_code: "European",
    ax: "69,7",
    ax_global: 6970,
    b: 160,
    b_global: 160,
    d: 160,
    d_global: 160,
    ix: 4130,
    ix_global: 41300000,
    iy: 2450,
    iy_global: 24500000,
    iz: 2450,
    iz_global: 24500000,
    t: "12,5",
    t_global: "12,5",
    zx: "408,91",
    zx_global: 408910,
    zy: "408,91",
    zy_global: 408910,
  },
  {
    profile_section_id: 2254,
    name: "TUB20020010",
    type: "Tube",
    designation: "TUB20020010",
    shape: "RHS",
    country_code: "European",
    ax: "75,5",
    ax_global: 7550,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 7020,
    ix_global: 70200000,
    iy: 4530,
    iy_global: 45300000,
    iz: 4530,
    iz_global: 45300000,
    t: 10,
    t_global: 10,
    zx: 542,
    zx_global: 542000,
    zy: 542,
    zy_global: 542000,
  },
  {
    profile_section_id: 2255,
    name: "TUB25015010",
    type: "Tube",
    designation: "TUB25015010",
    shape: "RHS",
    country_code: "European",
    ax: "75,5",
    ax_global: 7550,
    b: 150,
    b_global: 150,
    d: 250,
    d_global: 250,
    ix: 6080,
    ix_global: 60800000,
    iy: 2780,
    iy_global: 27800000,
    iz: 6260,
    iz_global: 62600000,
    t: 10,
    t_global: 10,
    zx: "624,5",
    zx_global: 624500,
    zy: 519,
    zy_global: 519000,
  },
  {
    profile_section_id: 2256,
    name: "TUB2502508",
    type: "Tube",
    designation: "TUB2502508",
    shape: "RHS",
    country_code: "European",
    ax: "77,1",
    ax_global: 7710,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 11500,
    ix_global: 115000000,
    iy: 7510,
    iy_global: 75100000,
    iz: 7510,
    iz_global: 75100000,
    t: 8,
    t_global: 8,
    zx: "703,02",
    zx_global: 703020,
    zy: "703,02",
    zy_global: 703020,
  },
  {
    profile_section_id: 2257,
    name: "TUB3002008",
    type: "Tube",
    designation: "TUB3002008",
    shape: "RHS",
    country_code: "European",
    ax: "77,1",
    ax_global: 7710,
    b: 200,
    b_global: 200,
    d: 300,
    d_global: 300,
    ix: 10500,
    ix_global: 105000000,
    iy: 5220,
    iy_global: 52200000,
    iz: 9800,
    iz_global: 98000000,
    t: 8,
    t_global: 8,
    zx: "789,82",
    zx_global: 789820,
    zy: "731,65",
    zy_global: 731650,
  },
  {
    profile_section_id: 2258,
    name: "TUB20020012",
    type: "Tube",
    designation: "TUB20020012",
    shape: "RHS",
    country_code: "European",
    ax: 93,
    ax_global: 9300,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 8480,
    ix_global: 84800000,
    iy: 5420,
    iy_global: 54200000,
    iz: 5420,
    iz_global: 54200000,
    t: "12,5",
    t_global: "12,5",
    zx: "660,16",
    zx_global: 660160,
    zy: "660,16",
    zy_global: 660160,
  },
  {
    profile_section_id: 2259,
    name: "TUB25025010",
    type: "Tube",
    designation: "TUB25025010",
    shape: "RHS",
    country_code: "European",
    ax: "95,5",
    ax_global: 9550,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 14100,
    ix_global: 141000000,
    iy: 9140,
    iy_global: 91400000,
    iz: 9140,
    iz_global: 91400000,
    t: 10,
    t_global: 10,
    zx: "864,5",
    zx_global: 864500,
    zy: "864,5",
    zy_global: 864500,
  },
  {
    profile_section_id: 2260,
    name: "TUB30020010",
    type: "Tube",
    designation: "TUB30020010",
    shape: "RHS",
    country_code: "European",
    ax: "95,5",
    ax_global: 9550,
    b: 200,
    b_global: 200,
    d: 300,
    d_global: 300,
    ix: 12900,
    ix_global: 129000000,
    iy: 6330,
    iy_global: 63300000,
    iz: 11900,
    iz_global: 119000000,
    t: 10,
    t_global: 10,
    zx: 972,
    zx_global: 972000,
    zy: 894,
    zy_global: 894000,
  },
  {
    profile_section_id: 2261,
    name: "TUB30030010",
    type: "Tube",
    designation: "TUB30030010",
    shape: "RHS",
    country_code: "European",
    ax: 116,
    ax_global: 11600,
    b: 300,
    b_global: 300,
    d: 300,
    d_global: 300,
    ix: 24800,
    ix_global: 248000000,
    iy: 16200,
    iy_global: 162000000,
    iz: 16200,
    iz_global: 162000000,
    t: 10,
    t_global: 10,
    zx: 1262,
    zx_global: 1262000,
    zy: 1262,
    zy_global: 1262000,
  },
  {
    profile_section_id: 2262,
    name: "TUB40020010",
    type: "Tube",
    designation: "TUB40020010",
    shape: "RHS",
    country_code: "European",
    ax: 116,
    ax_global: 11600,
    b: 200,
    b_global: 200,
    d: 400,
    d_global: 400,
    ix: 19200,
    ix_global: 192000000,
    iy: 8140,
    iy_global: 81400000,
    iz: 24100,
    iz_global: 241000000,
    t: 10,
    t_global: 10,
    zx: 1502,
    zx_global: 1502000,
    zy: 1084,
    zy_global: 1084000,
  },
  {
    profile_section_id: 2263,
    name: "TUB20020016",
    type: "Tube",
    designation: "TUB20020016",
    shape: "RHS",
    country_code: "European",
    ax: 117,
    ax_global: 11700,
    b: 200,
    b_global: 200,
    d: 200,
    d_global: 200,
    ix: 10300,
    ix_global: 103000000,
    iy: 6520,
    iy_global: 65200000,
    iz: 6520,
    iz_global: 65200000,
    t: 16,
    t_global: 16,
    zx: "814,59",
    zx_global: 814590,
    zy: "814,59",
    zy_global: 814590,
  },
  {
    profile_section_id: 2264,
    name: "TUB25025012",
    type: "Tube",
    designation: "TUB25025012",
    shape: "RHS",
    country_code: "European",
    ax: 118,
    ax_global: 11800,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 17100,
    ix_global: 171000000,
    iy: 10100,
    iy_global: 101000000,
    iz: 10100,
    iz_global: 101000000,
    t: "12,5",
    t_global: "12,5",
    zx: "1058,59",
    zx_global: 1058590,
    zy: "1058,59",
    zy_global: 1058590,
  },
  {
    profile_section_id: 2265,
    name: "TUB30020012",
    type: "Tube",
    designation: "TUB30020012",
    shape: "RHS",
    country_code: "European",
    ax: 118,
    ax_global: 11800,
    b: 200,
    b_global: 200,
    d: 300,
    d_global: 300,
    ix: 15700,
    ix_global: 157000000,
    iy: 7620,
    iy_global: 76200000,
    iz: 14500,
    iz_global: 145000000,
    t: "12,5",
    t_global: "12,5",
    zx: "1191,41",
    zx_global: 1191410,
    zy: "1085,94",
    zy_global: 1085940,
  },
  {
    profile_section_id: 2266,
    name: "TUB35035010",
    type: "Tube",
    designation: "TUB35035010",
    shape: "RHS",
    country_code: "European",
    ax: 136,
    ax_global: 13600,
    b: 350,
    b_global: 350,
    d: 350,
    d_global: 350,
    ix: 39800,
    ix_global: 398000000,
    iy: 26100,
    iy_global: 261000000,
    iz: 26100,
    iz_global: 261000000,
    t: 10,
    t_global: 10,
    zx: "1734,5",
    zx_global: 1734500,
    zy: "1734,5",
    zy_global: 1734500,
  },
  {
    profile_section_id: 2267,
    name: "TUB30030012",
    type: "Tube",
    designation: "TUB30030012",
    shape: "RHS",
    country_code: "European",
    ax: 143,
    ax_global: 14300,
    b: 300,
    b_global: 300,
    d: 300,
    d_global: 300,
    ix: 30300,
    ix_global: 303000000,
    iy: 19600,
    iy_global: 196000000,
    iz: 19600,
    iz_global: 196000000,
    t: "12,5",
    t_global: "12,5",
    zx: "1550,78",
    zx_global: 1550780,
    zy: "1550,78",
    zy_global: 1550780,
  },
  {
    profile_section_id: 2268,
    name: "TUB40020012",
    type: "Tube",
    designation: "TUB40020012",
    shape: "RHS",
    country_code: "European",
    ax: 143,
    ax_global: 14300,
    b: 200,
    b_global: 200,
    d: 400,
    d_global: 400,
    ix: 23400,
    ix_global: 234000000,
    iy: 9820,
    iy_global: 98200000,
    iz: 29400,
    iz_global: 294000000,
    t: "12,5",
    t_global: "12,5",
    zx: "1847,66",
    zx_global: 1847660,
    zy: "1320,31",
    zy_global: 1320310,
  },
  {
    profile_section_id: 2269,
    name: "TUB25025016",
    type: "Tube",
    designation: "TUB25025016",
    shape: "RHS",
    country_code: "European",
    ax: 149,
    ax_global: 14900,
    b: 250,
    b_global: 250,
    d: 250,
    d_global: 250,
    ix: 21100,
    ix_global: 211000000,
    iy: 13500,
    iy_global: 135000000,
    iz: 13500,
    iz_global: 135000000,
    t: 16,
    t_global: 16,
    zx: "1316,19",
    zx_global: 1316190,
    zy: "1316,19",
    zy_global: 1316190,
  },
  {
    profile_section_id: 2270,
    name: "TUB35035012",
    type: "Tube",
    designation: "TUB35035012",
    shape: "RHS",
    country_code: "European",
    ax: 168,
    ax_global: 16800,
    b: 350,
    b_global: 350,
    d: 350,
    d_global: 350,
    ix: 48900,
    ix_global: 489000000,
    iy: 31800,
    iy_global: 318000000,
    iz: 31800,
    iz_global: 318000000,
    t: "12,5",
    t_global: "12,5",
    zx: "2136,72",
    zx_global: 2136720,
    zy: "2136,72",
    zy_global: 2136720,
  },
  {
    profile_section_id: 2271,
    name: "TUB30030016",
    type: "Tube",
    designation: "TUB30030016",
    shape: "RHS",
    country_code: "European",
    ax: 181,
    ax_global: 18100,
    b: 300,
    b_global: 300,
    d: 300,
    d_global: 300,
    ix: 37600,
    ix_global: 376000000,
    iy: 24200,
    iy_global: 242000000,
    iz: 24200,
    iz_global: 242000000,
    t: 16,
    t_global: 16,
    zx: "1937,79",
    zx_global: 1937790,
    zy: "1937,79",
    zy_global: 1937790,
  },
  {
    profile_section_id: 2272,
    name: "TUB40020016",
    type: "Tube",
    designation: "TUB40020016",
    shape: "RHS",
    country_code: "European",
    ax: 181,
    ax_global: 18100,
    b: 200,
    b_global: 200,
    d: 400,
    d_global: 400,
    ix: 28800,
    ix_global: 288000000,
    iy: 12000,
    iy_global: 120000000,
    iz: 36300,
    iz_global: 363000000,
    t: 16,
    t_global: 16,
    zx: "2312,19",
    zx_global: 2312190,
    zy: "1629,18",
    zy_global: 1629180,
  },
  {
    profile_section_id: 2273,
    name: "TUB35035016",
    type: "Tube",
    designation: "TUB35035016",
    shape: "RHS",
    country_code: "European",
    ax: 213,
    ax_global: 21300,
    b: 350,
    b_global: 350,
    d: 350,
    d_global: 350,
    ix: 60900,
    ix_global: 609000000,
    iy: 39400,
    iy_global: 394000000,
    iz: 39400,
    iz_global: 394000000,
    t: 16,
    t_global: 16,
    zx: "2679,39",
    zx_global: 2679390,
    zy: "2679,39",
    zy_global: 2679390,
  },
  {
    profile_section_id: 2382,
    name: "IPE80",
    type: "IPE Shape",
    designation: "IPE80",
    shape: "I",
    country_code: "European",
    ax: "7,6",
    ax_global: 760,
    bf: 46,
    bf_global: 46,
    ct: 1,
    ct_global: 10,
    d: 80,
    d_global: 80,
    ix: "0,7",
    ix_global: 7000,
    iy: 9,
    iy_global: 90000,
    iz: 80,
    iz_global: 800000,
    tf: "5,2",
    tf_global: "5,2",
    tw: "3,8",
    tw_global: "3,8",
    zx: 23,
    zx_global: 23000,
    zy: 6,
    zy_global: 6000,
    width: 46,
    width_global: 46,
    thickness: "5,2",
    thickness_global: "5,2",
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2383,
    name: "IPE100",
    type: "IPE Shape",
    designation: "IPE100",
    shape: "I",
    country_code: "European",
    ax: "10,3",
    ax_global: 1030,
    bf: 55,
    bf_global: 55,
    ct: "1,2",
    ct_global: 12,
    d: 100,
    d_global: 100,
    ix: "1,2",
    ix_global: 12000,
    iy: 16,
    iy_global: 160000,
    iz: 171,
    iz_global: 1710000,
    tf: "5,7",
    tf_global: "5,7",
    tw: "4,1",
    tw_global: "4,1",
    zx: 39,
    zx_global: 39000,
    zy: 9,
    zy_global: 9000,
    width: 55,
    width_global: 55,
    thickness: "5,7",
    thickness_global: "5,7",
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2384,
    name: "IPEA120",
    type: "IPE Shape",
    designation: "IPEA120",
    shape: "I",
    country_code: "European",
    ax: 11,
    ax_global: 1100,
    bf: 64,
    bf_global: 64,
    ct: "1,4",
    ct_global: 14,
    d: "117,6",
    d_global: "117,6",
    ix: 1,
    ix_global: 10000,
    iy: 22,
    iy_global: 220000,
    iz: 257,
    iz_global: 2570000,
    tf: "5,1",
    tf_global: "5,1",
    tw: "3,8",
    tw_global: "3,8",
    zx: 50,
    zx_global: 50000,
    zy: 11,
    zy_global: 11000,
    width: 64,
    width_global: 64,
    thickness: "5,1",
    thickness_global: "5,1",
    height: "117,6",
    height_global: "117,6",
  },
  {
    profile_section_id: 2385,
    name: "IPE120",
    type: "IPE Shape",
    designation: "IPE120",
    shape: "I",
    country_code: "European",
    ax: "13,2",
    ax_global: 1320,
    bf: 64,
    bf_global: 64,
    ct: "1,4",
    ct_global: 14,
    d: 120,
    d_global: 120,
    ix: "1,7",
    ix_global: 17000,
    iy: 28,
    iy_global: 280000,
    iz: 318,
    iz_global: 3180000,
    tf: "6,3",
    tf_global: "6,3",
    tw: "4,4",
    tw_global: "4,4",
    zx: 61,
    zx_global: 61000,
    zy: 14,
    zy_global: 14000,
    width: 64,
    width_global: 64,
    thickness: "6,3",
    thickness_global: "6,3",
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2386,
    name: "IPEA140",
    type: "IPE Shape",
    designation: "IPEA140",
    shape: "I",
    country_code: "European",
    ax: "13,4",
    ax_global: 1340,
    bf: 73,
    bf_global: 73,
    ct: "1,5",
    ct_global: 15,
    d: "137,4",
    d_global: "137,4",
    ix: "1,4",
    ix_global: 14000,
    iy: 36,
    iy_global: 360000,
    iz: 435,
    iz_global: 4350000,
    tf: "5,6",
    tf_global: "5,6",
    tw: "3,8",
    tw_global: "3,8",
    zx: 72,
    zx_global: 72000,
    zy: 16,
    zy_global: 16000,
    width: 73,
    width_global: 73,
    thickness: "5,6",
    thickness_global: "5,6",
    height: "137,4",
    height_global: "137,4",
  },
  {
    profile_section_id: 2387,
    name: "IPEA160",
    type: "IPE Shape",
    designation: "IPEA160",
    shape: "I",
    country_code: "European",
    ax: "16,2",
    ax_global: 1620,
    bf: 82,
    bf_global: 82,
    ct: "1,7",
    ct_global: 17,
    d: 157,
    d_global: 157,
    ix: 2,
    ix_global: 20000,
    iy: 54,
    iy_global: 540000,
    iz: 689,
    iz_global: 6890000,
    tf: "5,9",
    tf_global: "5,9",
    tw: 4,
    tw_global: 4,
    zx: 99,
    zx_global: 99000,
    zy: 21,
    zy_global: 21000,
    width: 82,
    width_global: 82,
    thickness: "5,9",
    thickness_global: "5,9",
    height: 157,
    height_global: 157,
  },
  {
    profile_section_id: 2388,
    name: "IPE140",
    type: "IPE Shape",
    designation: "IPE140",
    shape: "I",
    country_code: "European",
    ax: "16,4",
    ax_global: 1640,
    bf: 73,
    bf_global: 73,
    ct: "1,6",
    ct_global: 16,
    d: 140,
    d_global: 140,
    ix: "2,5",
    ix_global: 25000,
    iy: 45,
    iy_global: 450000,
    iz: 541,
    iz_global: 5410000,
    tf: "6,9",
    tf_global: "6,9",
    tw: "4,7",
    tw_global: "4,7",
    zx: 88,
    zx_global: 88000,
    zy: 19,
    zy_global: 19000,
    width: 73,
    width_global: 73,
    thickness: "6,9",
    thickness_global: "6,9",
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 2389,
    name: "IPER140",
    type: "IPE Shape",
    designation: "IPER140",
    shape: "I",
    country_code: "European",
    ax: "18,4",
    ax_global: 1840,
    bf: 72,
    bf_global: 72,
    ct: "1,5",
    ct_global: 15,
    d: 142,
    d_global: 142,
    ix: "3,4",
    ix_global: 34000,
    iy: 49,
    iy_global: 490000,
    iz: 611,
    iz_global: 6110000,
    tf: "7,8",
    tf_global: "7,8",
    tw: "5,3",
    tw_global: "5,3",
    zx: 99,
    zx_global: 99000,
    zy: 21,
    zy_global: 21000,
    width: 72,
    width_global: 72,
    thickness: "7,8",
    thickness_global: "7,8",
    height: 142,
    height_global: 142,
  },
  {
    profile_section_id: 2390,
    name: "IPEA180",
    type: "IPE Shape",
    designation: "IPEA180",
    shape: "I",
    country_code: "European",
    ax: "19,6",
    ax_global: 1960,
    bf: 91,
    bf_global: 91,
    ct: "1,9",
    ct_global: 19,
    d: 177,
    d_global: 177,
    ix: "2,7",
    ix_global: 27000,
    iy: 82,
    iy_global: 820000,
    iz: 1063,
    iz_global: 10630000,
    tf: "6,5",
    tf_global: "6,5",
    tw: "4,3",
    tw_global: "4,3",
    zx: 135,
    zx_global: 135000,
    zy: 28,
    zy_global: 28000,
    width: 91,
    width_global: 91,
    thickness: "6,5",
    thickness_global: "6,5",
    height: 177,
    height_global: 177,
  },
  {
    profile_section_id: 2391,
    name: "IPE160",
    type: "IPE Shape",
    designation: "IPE160",
    shape: "I",
    country_code: "European",
    ax: "20,1",
    ax_global: 2010,
    bf: 82,
    bf_global: 82,
    ct: "1,8",
    ct_global: 18,
    d: 160,
    d_global: 160,
    ix: "3,6",
    ix_global: 36000,
    iy: 68,
    iy_global: 680000,
    iz: 869,
    iz_global: 8690000,
    tf: "7,4",
    tf_global: "7,4",
    tw: 5,
    tw_global: 5,
    zx: 124,
    zx_global: 124000,
    zy: 26,
    zy_global: 26000,
    width: 82,
    width_global: 82,
    thickness: "7,4",
    thickness_global: "7,4",
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2392,
    name: "IPER160",
    type: "IPE Shape",
    designation: "IPER160",
    shape: "I",
    country_code: "European",
    ax: "22,6",
    ax_global: 2260,
    bf: 81,
    bf_global: 81,
    ct: "1,9",
    ct_global: 19,
    d: 162,
    d_global: 162,
    ix: "5,1",
    ix_global: 51000,
    iy: 76,
    iy_global: 760000,
    iz: 989,
    iz_global: 9890000,
    tf: "8,5",
    tf_global: "8,5",
    tw: "5,6",
    tw_global: "5,6",
    zx: 140,
    zx_global: 140000,
    zy: 29,
    zy_global: 29000,
    width: 81,
    width_global: 81,
    thickness: "8,5",
    thickness_global: "8,5",
    height: 162,
    height_global: 162,
  },
  {
    profile_section_id: 2393,
    name: "IPEA200",
    type: "IPE Shape",
    designation: "IPEA200",
    shape: "I",
    country_code: "European",
    ax: "23,5",
    ax_global: 2350,
    bf: 100,
    bf_global: 100,
    ct: "2,1",
    ct_global: 21,
    d: 197,
    d_global: 197,
    ix: "4,1",
    ix_global: 41000,
    iy: 117,
    iy_global: 1170000,
    iz: 1591,
    iz_global: 15910000,
    tf: 7,
    tf_global: 7,
    tw: "4,5",
    tw_global: "4,5",
    zx: 182,
    zx_global: 182000,
    zy: 37,
    zy_global: 37000,
    width: 100,
    width_global: 100,
    thickness: 7,
    thickness_global: 7,
    height: 197,
    height_global: 197,
  },
  {
    profile_section_id: 2394,
    name: "IPE180",
    type: "IPE Shape",
    designation: "IPE180",
    shape: "I",
    country_code: "European",
    ax: "23,9",
    ax_global: 2390,
    bf: 91,
    bf_global: 91,
    ct: "2,1",
    ct_global: 21,
    d: 180,
    d_global: 180,
    ix: "4,8",
    ix_global: 48000,
    iy: 101,
    iy_global: 1010000,
    iz: 1317,
    iz_global: 13170000,
    tf: 8,
    tf_global: 8,
    tw: "5,3",
    tw_global: "5,3",
    zx: 166,
    zx_global: 166000,
    zy: 35,
    zy_global: 35000,
    width: 91,
    width_global: 91,
    thickness: 8,
    thickness_global: 8,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 2395,
    name: "IPEO180",
    type: "IPE Shape",
    designation: "IPEO180",
    shape: "I",
    country_code: "European",
    ax: "27,1",
    ax_global: 2710,
    bf: 92,
    bf_global: 92,
    ct: "2,1",
    ct_global: 21,
    d: 182,
    d_global: 182,
    ix: "6,8",
    ix_global: 68000,
    iy: 117,
    iy_global: 1170000,
    iz: 1505,
    iz_global: 15050000,
    tf: 9,
    tf_global: 9,
    tw: 6,
    tw_global: 6,
    zx: 189,
    zx_global: 189000,
    zy: 40,
    zy_global: 40000,
    width: 92,
    width_global: 92,
    thickness: 9,
    thickness_global: 9,
    height: 182,
    height_global: 182,
  },
  {
    profile_section_id: 2396,
    name: "IPER180",
    type: "IPE Shape",
    designation: "IPER180",
    shape: "I",
    country_code: "European",
    ax: "28,1",
    ax_global: 2810,
    bf: 89,
    bf_global: 89,
    ct: "2,2",
    ct_global: 22,
    d: 183,
    d_global: 183,
    ix: "7,8",
    ix_global: 78000,
    iy: 112,
    iy_global: 1120000,
    iz: 1554,
    iz_global: 15540000,
    tf: "9,5",
    tf_global: "9,5",
    tw: "6,4",
    tw_global: "6,4",
    zx: 195,
    zx_global: 195000,
    zy: 40,
    zy_global: 40000,
    width: 89,
    width_global: 89,
    thickness: "9,5",
    thickness_global: "9,5",
    height: 183,
    height_global: 183,
  },
  {
    profile_section_id: 2397,
    name: "IPEA220",
    type: "IPE Shape",
    designation: "IPEA220",
    shape: "I",
    country_code: "European",
    ax: "28,3",
    ax_global: 2830,
    bf: 110,
    bf_global: 110,
    ct: "2,4",
    ct_global: 24,
    d: 217,
    d_global: 217,
    ix: "5,7",
    ix_global: 57000,
    iy: 171,
    iy_global: 1710000,
    iz: 2317,
    iz_global: 23170000,
    tf: "7,7",
    tf_global: "7,7",
    tw: 5,
    tw_global: 5,
    zx: 240,
    zx_global: 240000,
    zy: 49,
    zy_global: 49000,
    width: 110,
    width_global: 110,
    thickness: "7,7",
    thickness_global: "7,7",
    height: 217,
    height_global: 217,
  },
  {
    profile_section_id: 2398,
    name: "IPE200",
    type: "IPE Shape",
    designation: "IPE200",
    shape: "I",
    country_code: "European",
    ax: "28,5",
    ax_global: 2850,
    bf: 100,
    bf_global: 100,
    ct: "2,3",
    ct_global: 23,
    d: 200,
    d_global: 200,
    ix: 7,
    ix_global: 70000,
    iy: 142,
    iy_global: 1420000,
    iz: 1943,
    iz_global: 19430000,
    tf: "8,5",
    tf_global: "8,5",
    tw: "5,6",
    tw_global: "5,6",
    zx: 221,
    zx_global: 221000,
    zy: 45,
    zy_global: 45000,
    width: 100,
    width_global: 100,
    thickness: "8,5",
    thickness_global: "8,5",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2399,
    name: "IPEO200",
    type: "IPE Shape",
    designation: "IPEO200",
    shape: "I",
    country_code: "European",
    ax: 32,
    ax_global: 3200,
    bf: 102,
    bf_global: 102,
    ct: "2,7",
    ct_global: 27,
    d: 202,
    d_global: 202,
    ix: "9,5",
    ix_global: 95000,
    iy: 169,
    iy_global: 1690000,
    iz: 2211,
    iz_global: 22110000,
    tf: "9,5",
    tf_global: "9,5",
    tw: "6,2",
    tw_global: "6,2",
    zx: 249,
    zx_global: 249000,
    zy: 52,
    zy_global: 52000,
    width: 102,
    width_global: 102,
    thickness: "9,5",
    thickness_global: "9,5",
    height: 202,
    height_global: 202,
  },
  {
    profile_section_id: 2400,
    name: "IPEA240",
    type: "IPE Shape",
    designation: "IPEA240",
    shape: "I",
    country_code: "European",
    ax: "33,3",
    ax_global: 3330,
    bf: 120,
    bf_global: 120,
    ct: "2,5",
    ct_global: 25,
    d: 237,
    d_global: 237,
    ix: "8,4",
    ix_global: 84000,
    iy: 240,
    iy_global: 2400000,
    iz: 3290,
    iz_global: 32900000,
    tf: "8,3",
    tf_global: "8,3",
    tw: "5,2",
    tw_global: "5,2",
    zx: 312,
    zx_global: 312000,
    zy: 62,
    zy_global: 62000,
    width: 120,
    width_global: 120,
    thickness: "8,3",
    thickness_global: "8,3",
    height: 237,
    height_global: 237,
  },
  {
    profile_section_id: 2401,
    name: "IPE220",
    type: "IPE Shape",
    designation: "IPE220",
    shape: "I",
    country_code: "European",
    ax: "33,4",
    ax_global: 3340,
    bf: 110,
    bf_global: 110,
    ct: "2,5",
    ct_global: 25,
    d: 220,
    d_global: 220,
    ix: "9,1",
    ix_global: 91000,
    iy: 205,
    iy_global: 2050000,
    iz: 2772,
    iz_global: 27720000,
    tf: "9,2",
    tf_global: "9,2",
    tw: "5,9",
    tw_global: "5,9",
    zx: 285,
    zx_global: 285000,
    zy: 58,
    zy_global: 58000,
    width: 110,
    width_global: 110,
    thickness: "9,2",
    thickness_global: "9,2",
    height: 220,
    height_global: 220,
  },
  {
    profile_section_id: 2402,
    name: "IPER200",
    type: "IPE Shape",
    designation: "IPER200",
    shape: "I",
    country_code: "European",
    ax: "33,9",
    ax_global: 3390,
    bf: 98,
    bf_global: 98,
    ct: "2,4",
    ct_global: 24,
    d: 204,
    d_global: 204,
    ix: "11,7",
    ix_global: 117000,
    iy: 166,
    iy_global: 1660000,
    iz: 2363,
    iz_global: 23630000,
    tf: "10,5",
    tf_global: "10,5",
    tw: "6,6",
    tw_global: "6,6",
    zx: 265,
    zx_global: 265000,
    zy: 53,
    zy_global: 53000,
    width: 98,
    width_global: 98,
    thickness: "10,5",
    thickness_global: "10,5",
    height: 204,
    height_global: 204,
  },
  {
    profile_section_id: 2403,
    name: "IPEO220",
    type: "IPE Shape",
    designation: "IPEO220",
    shape: "I",
    country_code: "European",
    ax: "37,4",
    ax_global: 3740,
    bf: 112,
    bf_global: 112,
    ct: "2,5",
    ct_global: 25,
    d: 222,
    d_global: 222,
    ix: "12,3",
    ix_global: 123000,
    iy: 240,
    iy_global: 2400000,
    iz: 3134,
    iz_global: 31340000,
    tf: "10,2",
    tf_global: "10,2",
    tw: "6,6",
    tw_global: "6,6",
    zx: 321,
    zx_global: 321000,
    zy: 67,
    zy_global: 67000,
    width: 112,
    width_global: 112,
    thickness: "10,2",
    thickness_global: "10,2",
    height: 222,
    height_global: 222,
  },
  {
    profile_section_id: 2404,
    name: "IPE240",
    type: "IPE Shape",
    designation: "IPE240",
    shape: "I",
    country_code: "European",
    ax: "39,1",
    ax_global: 3910,
    bf: 120,
    bf_global: 120,
    ct: "2,6",
    ct_global: 26,
    d: 240,
    d_global: 240,
    ix: "12,9",
    ix_global: 129000,
    iy: 284,
    iy_global: 2840000,
    iz: 3892,
    iz_global: 38920000,
    tf: "9,8",
    tf_global: "9,8",
    tw: "6,2",
    tw_global: "6,2",
    zx: 367,
    zx_global: 367000,
    zy: 74,
    zy_global: 74000,
    width: 120,
    width_global: 120,
    thickness: "9,8",
    thickness_global: "9,8",
    height: 240,
    height_global: 240,
  },
  {
    profile_section_id: 2405,
    name: "IPEA270",
    type: "IPE Shape",
    designation: "IPEA270",
    shape: "I",
    country_code: "European",
    ax: "39,1",
    ax_global: 3910,
    bf: 135,
    bf_global: 135,
    ct: "2,8",
    ct_global: 28,
    d: 267,
    d_global: 267,
    ix: "10,3",
    ix_global: 103000,
    iy: 358,
    iy_global: 3580000,
    iz: 4917,
    iz_global: 49170000,
    tf: "8,7",
    tf_global: "8,7",
    tw: "5,5",
    tw_global: "5,5",
    zx: 412,
    zx_global: 412000,
    zy: 82,
    zy_global: 82000,
    width: 135,
    width_global: 135,
    thickness: "8,7",
    thickness_global: "8,7",
    height: 267,
    height_global: 267,
  },
  {
    profile_section_id: 2406,
    name: "IPER220",
    type: "IPE Shape",
    designation: "IPER220",
    shape: "I",
    country_code: "European",
    ax: "40,2",
    ax_global: 4020,
    bf: 108,
    bf_global: 108,
    ct: "2,5",
    ct_global: 25,
    d: 225,
    d_global: 225,
    ix: "16,2",
    ix_global: 162000,
    iy: 249,
    iy_global: 2490000,
    iz: 3474,
    iz_global: 34740000,
    tf: "11,8",
    tf_global: "11,8",
    tw: "6,7",
    tw_global: "6,7",
    zx: 352,
    zx_global: 352000,
    zy: 72,
    zy_global: 72000,
    width: 108,
    width_global: 108,
    thickness: "11,8",
    thickness_global: "11,8",
    height: 225,
    height_global: 225,
  },
  {
    profile_section_id: 2407,
    name: "IPEO240",
    type: "IPE Shape",
    designation: "IPEO240",
    shape: "I",
    country_code: "European",
    ax: "43,7",
    ax_global: 4370,
    bf: 122,
    bf_global: 122,
    ct: "2,7",
    ct_global: 27,
    d: 242,
    d_global: 242,
    ix: "17,2",
    ix_global: 172000,
    iy: 329,
    iy_global: 3290000,
    iz: 4369,
    iz_global: 43690000,
    tf: "10,8",
    tf_global: "10,8",
    tw: 7,
    tw_global: 7,
    zx: 410,
    zx_global: 410000,
    zy: 84,
    zy_global: 84000,
    width: 122,
    width_global: 122,
    thickness: "10,8",
    thickness_global: "10,8",
    height: 242,
    height_global: 242,
  },
  {
    profile_section_id: 2408,
    name: "IPE270",
    type: "IPE Shape",
    designation: "IPE270",
    shape: "I",
    country_code: "European",
    ax: "45,9",
    ax_global: 4590,
    bf: 135,
    bf_global: 135,
    ct: 3,
    ct_global: 30,
    d: 270,
    d_global: 270,
    ix: "15,9",
    ix_global: 159000,
    iy: 420,
    iy_global: 4200000,
    iz: 5790,
    iz_global: 57900000,
    tf: "10,2",
    tf_global: "10,2",
    tw: "6,6",
    tw_global: "6,6",
    zx: 484,
    zx_global: 484000,
    zy: 97,
    zy_global: 97000,
    width: 135,
    width_global: 135,
    thickness: "10,2",
    thickness_global: "10,2",
    height: 270,
    height_global: 270,
  },
  {
    profile_section_id: 2409,
    name: "IPEA300",
    type: "IPE Shape",
    designation: "IPEA300",
    shape: "I",
    country_code: "European",
    ax: "46,5",
    ax_global: 4650,
    bf: 150,
    bf_global: 150,
    ct: "3,2",
    ct_global: 32,
    d: 297,
    d_global: 297,
    ix: "13,4",
    ix_global: 134000,
    iy: 519,
    iy_global: 5190000,
    iz: 7173,
    iz_global: 71730000,
    tf: "9,2",
    tf_global: "9,2",
    tw: "6,1",
    tw_global: "6,1",
    zx: 542,
    zx_global: 542000,
    zy: 107,
    zy_global: 107000,
    width: 150,
    width_global: 150,
    thickness: "9,2",
    thickness_global: "9,2",
    height: 297,
    height_global: 297,
  },
  {
    profile_section_id: 2410,
    name: "IPER240",
    type: "IPE Shape",
    designation: "IPER240",
    shape: "I",
    country_code: "European",
    ax: "47,5",
    ax_global: 4750,
    bf: 118,
    bf_global: 118,
    ct: "2,8",
    ct_global: 28,
    d: 245,
    d_global: 245,
    ix: "22,7",
    ix_global: 227000,
    iy: 339,
    iy_global: 3390000,
    iz: 4823,
    iz_global: 48230000,
    tf: "12,3",
    tf_global: "12,3",
    tw: "7,5",
    tw_global: "7,5",
    zx: 449,
    zx_global: 449000,
    zy: 90,
    zy_global: 90000,
    width: 118,
    width_global: 118,
    thickness: "12,3",
    thickness_global: "12,3",
    height: 245,
    height_global: 245,
  },
  {
    profile_section_id: 2411,
    name: "IPE300",
    type: "IPE Shape",
    designation: "IPE300",
    shape: "I",
    country_code: "European",
    ax: "53,8",
    ax_global: 5380,
    bf: 150,
    bf_global: 150,
    ct: "3,3",
    ct_global: 33,
    d: 300,
    d_global: 300,
    ix: "20,1",
    ix_global: 201000,
    iy: 604,
    iy_global: 6040000,
    iz: 8356,
    iz_global: 83560000,
    tf: "10,7",
    tf_global: "10,7",
    tw: "7,1",
    tw_global: "7,1",
    zx: 628,
    zx_global: 628000,
    zy: 125,
    zy_global: 125000,
    width: 150,
    width_global: 150,
    thickness: "10,7",
    thickness_global: "10,7",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 2412,
    name: "IPEO270",
    type: "IPE Shape",
    designation: "IPEO270",
    shape: "I",
    country_code: "European",
    ax: "53,8",
    ax_global: 5380,
    bf: 136,
    bf_global: 136,
    ct: 3,
    ct_global: 30,
    d: 274,
    d_global: 274,
    ix: "24,9",
    ix_global: 249000,
    iy: 513,
    iy_global: 5130000,
    iz: 6947,
    iz_global: 69470000,
    tf: "12,2",
    tf_global: "12,2",
    tw: "7,5",
    tw_global: "7,5",
    zx: 575,
    zx_global: 575000,
    zy: 118,
    zy_global: 118000,
    width: 136,
    width_global: 136,
    thickness: "12,2",
    thickness_global: "12,2",
    height: 274,
    height_global: 274,
  },
  {
    profile_section_id: 2413,
    name: "IPEA330",
    type: "IPE Shape",
    designation: "IPEA330",
    shape: "I",
    country_code: "European",
    ax: "54,7",
    ax_global: 5470,
    bf: 160,
    bf_global: 160,
    ct: "3,5",
    ct_global: 35,
    d: 327,
    d_global: 327,
    ix: "19,6",
    ix_global: 196000,
    iy: 685,
    iy_global: 6850000,
    iz: 10230,
    iz_global: 102300000,
    tf: 10,
    tf_global: 10,
    tw: "6,5",
    tw_global: "6,5",
    zx: 702,
    zx_global: 702000,
    zy: 133,
    zy_global: 133000,
    width: 160,
    width_global: 160,
    thickness: 10,
    thickness_global: 10,
    height: 327,
    height_global: 327,
  },
  {
    profile_section_id: 2414,
    name: "IPER270",
    type: "IPE Shape",
    designation: "IPER270",
    shape: "I",
    country_code: "European",
    ax: 56,
    ax_global: 5600,
    bf: 133,
    bf_global: 133,
    ct: "3,1",
    ct_global: 31,
    d: 276,
    d_global: 276,
    ix: "28,8",
    ix_global: 288000,
    iy: 516,
    iy_global: 5160000,
    iz: 7312,
    iz_global: 73120000,
    tf: "13,1",
    tf_global: "13,1",
    tw: "7,7",
    tw_global: "7,7",
    zx: 602,
    zx_global: 602000,
    zy: 121,
    zy_global: 121000,
    width: 133,
    width_global: 133,
    thickness: "13,1",
    thickness_global: "13,1",
    height: 276,
    height_global: 276,
  },
  {
    profile_section_id: 2415,
    name: "IPE330",
    type: "IPE Shape",
    designation: "IPE330",
    shape: "I",
    country_code: "European",
    ax: "62,6",
    ax_global: 6260,
    bf: 160,
    bf_global: 160,
    ct: "3,6",
    ct_global: 36,
    d: 330,
    d_global: 330,
    ix: "28,1",
    ix_global: 281000,
    iy: 788,
    iy_global: 7880000,
    iz: 11770,
    iz_global: 117700000,
    tf: "11,5",
    tf_global: "11,5",
    tw: "7,5",
    tw_global: "7,5",
    zx: 804,
    zx_global: 804000,
    zy: 154,
    zy_global: 154000,
    width: 160,
    width_global: 160,
    thickness: "11,5",
    thickness_global: "11,5",
    height: 330,
    height_global: 330,
  },
  {
    profile_section_id: 2416,
    name: "IPEO300",
    type: "IPE Shape",
    designation: "IPEO300",
    shape: "I",
    country_code: "European",
    ax: "62,8",
    ax_global: 6280,
    bf: 152,
    bf_global: 152,
    ct: "3,4",
    ct_global: 34,
    d: 304,
    d_global: 304,
    ix: "31,1",
    ix_global: 311000,
    iy: 746,
    iy_global: 7460000,
    iz: 9994,
    iz_global: 99940000,
    tf: "12,7",
    tf_global: "12,7",
    tw: 8,
    tw_global: 8,
    zx: 744,
    zx_global: 744000,
    zy: 153,
    zy_global: 153000,
    width: 152,
    width_global: 152,
    thickness: "12,7",
    thickness_global: "12,7",
    height: 304,
    height_global: 304,
  },
  {
    profile_section_id: 2417,
    name: "IPEA360",
    type: "IPE Shape",
    designation: "IPEA360",
    shape: "I",
    country_code: "European",
    ax: 64,
    ax_global: 6400,
    bf: 170,
    bf_global: 170,
    ct: "3,7",
    ct_global: 37,
    d: "357,6",
    d_global: "357,6",
    ix: "26,5",
    ix_global: 265000,
    iy: 944,
    iy_global: 9440000,
    iz: 14520,
    iz_global: 145200000,
    tf: "11,5",
    tf_global: "11,5",
    tw: "6,6",
    tw_global: "6,6",
    zx: 907,
    zx_global: 907000,
    zy: 172,
    zy_global: 172000,
    width: 170,
    width_global: 170,
    thickness: "11,5",
    thickness_global: "11,5",
    height: "357,6",
    height_global: "357,6",
  },
  {
    profile_section_id: 2418,
    name: "IPER300",
    type: "IPE Shape",
    designation: "IPER300",
    shape: "I",
    country_code: "European",
    ax: "65,9",
    ax_global: 6590,
    bf: 147,
    bf_global: 147,
    ct: "3,5",
    ct_global: 35,
    d: 306,
    d_global: 306,
    ix: "37,1",
    ix_global: 371000,
    iy: 728,
    iy_global: 7280000,
    iz: 10500,
    iz_global: 105000000,
    tf: "13,7",
    tf_global: "13,7",
    tw: "8,5",
    tw_global: "8,5",
    zx: 780,
    zx_global: 780000,
    zy: 155,
    zy_global: 155000,
    width: 147,
    width_global: 147,
    thickness: "13,7",
    thickness_global: "13,7",
    height: 306,
    height_global: 306,
  },
  {
    profile_section_id: 2419,
    name: "IPEO330",
    type: "IPE Shape",
    designation: "IPEO330",
    shape: "I",
    country_code: "European",
    ax: "72,6",
    ax_global: 7260,
    bf: 162,
    bf_global: 162,
    ct: "3,7",
    ct_global: 37,
    d: 334,
    d_global: 334,
    ix: "42,2",
    ix_global: 422000,
    iy: 960,
    iy_global: 9600000,
    iz: 13910,
    iz_global: 139100000,
    tf: "13,5",
    tf_global: "13,5",
    tw: "8,5",
    tw_global: "8,5",
    zx: 943,
    zx_global: 943000,
    zy: 185,
    zy_global: 185000,
    width: 162,
    width_global: 162,
    thickness: "13,5",
    thickness_global: "13,5",
    height: 334,
    height_global: 334,
  },
  {
    profile_section_id: 2420,
    name: "IPE360",
    type: "IPE Shape",
    designation: "IPE360",
    shape: "I",
    country_code: "European",
    ax: "72,7",
    ax_global: 7270,
    bf: 170,
    bf_global: 170,
    ct: 4,
    ct_global: 40,
    d: 360,
    d_global: 360,
    ix: "37,3",
    ix_global: 373000,
    iy: 1043,
    iy_global: 10430000,
    iz: 16270,
    iz_global: 162700000,
    tf: "12,7",
    tf_global: "12,7",
    tw: 8,
    tw_global: 8,
    zx: 1019,
    zx_global: 1019000,
    zy: 191,
    zy_global: 191000,
    width: 170,
    width_global: 170,
    thickness: "12,7",
    thickness_global: "12,7",
    height: 360,
    height_global: 360,
  },
  {
    profile_section_id: 2421,
    name: "IPEA400",
    type: "IPE Shape",
    designation: "IPEA400",
    shape: "I",
    country_code: "European",
    ax: "73,1",
    ax_global: 7310,
    bf: 180,
    bf_global: 180,
    ct: "4,2",
    ct_global: 42,
    d: 397,
    d_global: 397,
    ix: "34,8",
    ix_global: 348000,
    iy: 1171,
    iy_global: 11710000,
    iz: 20290,
    iz_global: 202900000,
    tf: 12,
    tf_global: 12,
    tw: 7,
    tw_global: 7,
    zx: 1144,
    zx_global: 1144000,
    zy: 202,
    zy_global: 202000,
    width: 180,
    width_global: 180,
    thickness: 12,
    thickness_global: 12,
    height: 397,
    height_global: 397,
  },
  {
    profile_section_id: 2422,
    name: "IPER330",
    type: "IPE Shape",
    designation: "IPER330",
    shape: "I",
    country_code: "European",
    ax: "76,8",
    ax_global: 7680,
    bf: 158,
    bf_global: 158,
    ct: "3,9",
    ct_global: 39,
    d: 336,
    d_global: 336,
    ix: "50,8",
    ix_global: 508000,
    iy: 958,
    iy_global: 9580000,
    iz: 14690,
    iz_global: 146900000,
    tf: "14,5",
    tf_global: "14,5",
    tw: "9,2",
    tw_global: "9,2",
    zx: 995,
    zx_global: 995000,
    zy: 190,
    zy_global: 190000,
    width: 158,
    width_global: 158,
    thickness: "14,5",
    thickness_global: "14,5",
    height: 336,
    height_global: 336,
  },
  {
    profile_section_id: 2423,
    name: "IPEO360",
    type: "IPE Shape",
    designation: "IPEO360",
    shape: "I",
    country_code: "European",
    ax: "84,1",
    ax_global: 8410,
    bf: 172,
    bf_global: 172,
    ct: "4,1",
    ct_global: 41,
    d: 364,
    d_global: 364,
    ix: "55,8",
    ix_global: 558000,
    iy: 1251,
    iy_global: 12510000,
    iz: 19050,
    iz_global: 190500000,
    tf: "14,7",
    tf_global: "14,7",
    tw: "9,2",
    tw_global: "9,2",
    zx: 1186,
    zx_global: 1186000,
    zy: 227,
    zy_global: 227000,
    width: 172,
    width_global: 172,
    thickness: "14,7",
    thickness_global: "14,7",
    height: 364,
    height_global: 364,
  },
  {
    profile_section_id: 2424,
    name: "IPE400",
    type: "IPE Shape",
    designation: "IPE400",
    shape: "I",
    country_code: "European",
    ax: "84,5",
    ax_global: 8450,
    bf: 180,
    bf_global: 180,
    ct: "4,5",
    ct_global: 45,
    d: 400,
    d_global: 400,
    ix: "51,1",
    ix_global: 511000,
    iy: 1318,
    iy_global: 13180000,
    iz: 23130,
    iz_global: 231300000,
    tf: "13,5",
    tf_global: "13,5",
    tw: "8,6",
    tw_global: "8,6",
    zx: 1307,
    zx_global: 1307000,
    zy: 229,
    zy_global: 229000,
    width: 180,
    width_global: 180,
    thickness: "13,5",
    thickness_global: "13,5",
    height: 400,
    height_global: 400,
  },
  {
    profile_section_id: 2425,
    name: "IPEA450",
    type: "IPE Shape",
    designation: "IPEA450",
    shape: "I",
    country_code: "European",
    ax: "85,5",
    ax_global: 8550,
    bf: 190,
    bf_global: 190,
    ct: "4,9",
    ct_global: 49,
    d: 447,
    d_global: 447,
    ix: "45,7",
    ix_global: 457000,
    iy: 1502,
    iy_global: 15020000,
    iz: 29760,
    iz_global: 297600000,
    tf: "13,1",
    tf_global: "13,1",
    tw: "7,6",
    tw_global: "7,6",
    zx: 1494,
    zx_global: 1494000,
    zy: 246,
    zy_global: 246000,
    width: 190,
    width_global: 190,
    thickness: "13,1",
    thickness_global: "13,1",
    height: 447,
    height_global: 447,
  },
  {
    profile_section_id: 2426,
    name: "IPER360",
    type: "IPE Shape",
    designation: "IPER360",
    shape: "I",
    country_code: "European",
    ax: "89,6",
    ax_global: 8960,
    bf: 168,
    bf_global: 168,
    ct: "4,2",
    ct_global: 42,
    d: 366,
    d_global: 366,
    ix: "68,8",
    ix_global: 688000,
    iy: 1270,
    iy_global: 12700000,
    iz: 20290,
    iz_global: 202900000,
    tf: 16,
    tf_global: 16,
    tw: "9,9",
    tw_global: "9,9",
    zx: 1262,
    zx_global: 1262000,
    zy: 236,
    zy_global: 236000,
    width: 168,
    width_global: 168,
    thickness: 16,
    thickness_global: 16,
    height: 366,
    height_global: 366,
  },
  {
    profile_section_id: 2427,
    name: "IPEO400",
    type: "IPE Shape",
    designation: "IPEO400",
    shape: "I",
    country_code: "European",
    ax: "96,4",
    ax_global: 9640,
    bf: 182,
    bf_global: 182,
    ct: "4,6",
    ct_global: 46,
    d: 404,
    d_global: 404,
    ix: "73,1",
    ix_global: 731000,
    iy: 1564,
    iy_global: 15640000,
    iz: 26750,
    iz_global: 267500000,
    tf: "15,5",
    tf_global: "15,5",
    tw: "9,7",
    tw_global: "9,7",
    zx: 1502,
    zx_global: 1502000,
    zy: 269,
    zy_global: 269000,
    width: 182,
    width_global: 182,
    thickness: "15,5",
    thickness_global: "15,5",
    height: 404,
    height_global: 404,
  },
  {
    profile_section_id: 2428,
    name: "IPE450",
    type: "IPE Shape",
    designation: "IPE450",
    shape: "I",
    country_code: "European",
    ax: "98,8",
    ax_global: 9880,
    bf: 190,
    bf_global: 190,
    ct: "5,3",
    ct_global: 53,
    d: 450,
    d_global: 450,
    ix: "66,9",
    ix_global: 669000,
    iy: 1676,
    iy_global: 16760000,
    iz: 33740,
    iz_global: 337400000,
    tf: "14,6",
    tf_global: "14,6",
    tw: "9,4",
    tw_global: "9,4",
    zx: 1702,
    zx_global: 1702000,
    zy: 276,
    zy_global: 276000,
    width: 190,
    width_global: 190,
    thickness: "14,6",
    thickness_global: "14,6",
    height: 450,
    height_global: 450,
  },
  {
    profile_section_id: 2429,
    name: "IPEA500",
    type: "IPE Shape",
    designation: "IPEA500",
    shape: "I",
    country_code: "European",
    ax: 101,
    ax_global: 10100,
    bf: 200,
    bf_global: 200,
    ct: "5,6",
    ct_global: 56,
    d: 497,
    d_global: 497,
    ix: "62,8",
    ix_global: 628000,
    iy: 1939,
    iy_global: 19390000,
    iz: 42930,
    iz_global: 429300000,
    tf: "14,5",
    tf_global: "14,5",
    tw: "8,4",
    tw_global: "8,4",
    zx: 1946,
    zx_global: 1946000,
    zy: 302,
    zy_global: 302000,
    width: 200,
    width_global: 200,
    thickness: "14,5",
    thickness_global: "14,5",
    height: 497,
    height_global: 497,
  },
  {
    profile_section_id: 2430,
    name: "IPER400",
    type: "IPE Shape",
    designation: "IPER400",
    shape: "I",
    country_code: "European",
    ax: 104,
    ax_global: 10400,
    bf: 178,
    bf_global: 178,
    ct: "4,8",
    ct_global: 48,
    d: 407,
    d_global: 407,
    ix: "92,4",
    ix_global: 924000,
    iy: 1606,
    iy_global: 16060000,
    iz: 28860,
    iz_global: 288600000,
    tf: 17,
    tf_global: 17,
    tw: "10,6",
    tw_global: "10,6",
    zx: 1618,
    zx_global: 1618000,
    zy: 284,
    zy_global: 284000,
    width: 178,
    width_global: 178,
    thickness: 17,
    thickness_global: 17,
    height: 407,
    height_global: 407,
  },
  {
    profile_section_id: 2431,
    name: "IPEV400",
    type: "IPE Shape",
    designation: "IPEV400",
    shape: "I",
    country_code: "European",
    ax: 107,
    ax_global: 10700,
    bf: 182,
    bf_global: 182,
    ct: "4,7",
    ct_global: 47,
    d: 408,
    d_global: 408,
    ix: 99,
    ix_global: 990000,
    iy: 1766,
    iy_global: 17660000,
    iz: 30140,
    iz_global: 301400000,
    tf: "17,5",
    tf_global: "17,5",
    tw: "10,6",
    tw_global: "10,6",
    zx: 1681,
    zx_global: 1681000,
    zy: 304,
    zy_global: 304000,
    width: 182,
    width_global: 182,
    thickness: "17,5",
    thickness_global: "17,5",
    height: 408,
    height_global: 408,
  },
  {
    profile_section_id: 2432,
    name: "IPE500",
    type: "IPE Shape",
    designation: "IPE500",
    shape: "I",
    country_code: "European",
    ax: 116,
    ax_global: 11600,
    bf: 200,
    bf_global: 200,
    ct: 6,
    ct_global: 60,
    d: 500,
    d_global: 500,
    ix: "89,3",
    ix_global: 893000,
    iy: 2142,
    iy_global: 21420000,
    iz: 48200,
    iz_global: 482000000,
    tf: 16,
    tf_global: 16,
    tw: "10,2",
    tw_global: "10,2",
    zx: 2194,
    zx_global: 2194000,
    zy: 336,
    zy_global: 336000,
    width: 200,
    width_global: 200,
    thickness: 16,
    thickness_global: 16,
    height: 500,
    height_global: 500,
  },
  {
    profile_section_id: 2433,
    name: "IPEA550",
    type: "IPE Shape",
    designation: "IPEA550",
    shape: "I",
    country_code: "European",
    ax: 117,
    ax_global: 11700,
    bf: 210,
    bf_global: 210,
    ct: "5,6",
    ct_global: 56,
    d: 547,
    d_global: 547,
    ix: "86,5",
    ix_global: 865000,
    iy: 2432,
    iy_global: 24320000,
    iz: 59980,
    iz_global: 599800000,
    tf: "15,7",
    tf_global: "15,7",
    tw: 9,
    tw_global: 9,
    zx: 2475,
    zx_global: 2475000,
    zy: 362,
    zy_global: 362000,
    width: 210,
    width_global: 210,
    thickness: "15,7",
    thickness_global: "15,7",
    height: 547,
    height_global: 547,
  },
  {
    profile_section_id: 2434,
    name: "IPEO450",
    type: "IPE Shape",
    designation: "IPEO450",
    shape: "I",
    country_code: "European",
    ax: 118,
    ax_global: 11800,
    bf: 192,
    bf_global: 192,
    ct: "5,4",
    ct_global: 54,
    d: 456,
    d_global: 456,
    ix: 109,
    ix_global: 1090000,
    iy: 2085,
    iy_global: 20850000,
    iz: 40920,
    iz_global: 409200000,
    tf: "17,6",
    tf_global: "17,6",
    tw: 11,
    tw_global: 11,
    zx: 2046,
    zx_global: 2046000,
    zy: 341,
    zy_global: 341000,
    width: 192,
    width_global: 192,
    thickness: "17,6",
    thickness_global: "17,6",
    height: 456,
    height_global: 456,
  },
  {
    profile_section_id: 2435,
    name: "IPER450",
    type: "IPE Shape",
    designation: "IPER450",
    shape: "I",
    country_code: "European",
    ax: 121,
    ax_global: 12100,
    bf: 188,
    bf_global: 188,
    ct: "5,5",
    ct_global: 55,
    d: 458,
    d_global: 458,
    ix: 122,
    ix_global: 1220000,
    iy: 2070,
    iy_global: 20700000,
    iz: 42400,
    iz_global: 424000000,
    tf: "18,6",
    tf_global: "18,6",
    tw: "11,3",
    tw_global: "11,3",
    zx: 2115,
    zx_global: 2115000,
    zy: 346,
    zy_global: 346000,
    width: 188,
    width_global: 188,
    thickness: "18,6",
    thickness_global: "18,6",
    height: 458,
    height_global: 458,
  },
  {
    profile_section_id: 2436,
    name: "IPEV450",
    type: "IPE Shape",
    designation: "IPEV450",
    shape: "I",
    country_code: "European",
    ax: 132,
    ax_global: 13200,
    bf: 194,
    bf_global: 194,
    ct: "5,6",
    ct_global: 56,
    d: 460,
    d_global: 460,
    ix: 150,
    ix_global: 1500000,
    iy: 2397,
    iy_global: 23970000,
    iz: 46200,
    iz_global: 462000000,
    tf: "19,6",
    tf_global: "19,6",
    tw: "12,4",
    tw_global: "12,4",
    zx: 2301,
    zx_global: 2301000,
    zy: 389,
    zy_global: 389000,
    width: 194,
    width_global: 194,
    thickness: "19,6",
    thickness_global: "19,6",
    height: 460,
    height_global: 460,
  },
  {
    profile_section_id: 2437,
    name: "IPE550",
    type: "IPE Shape",
    designation: "IPE550",
    shape: "I",
    country_code: "European",
    ax: 134,
    ax_global: 13400,
    bf: 210,
    bf_global: 210,
    ct: "6,8",
    ct_global: 68,
    d: 550,
    d_global: 550,
    ix: 123,
    ix_global: 1230000,
    iy: 2668,
    iy_global: 26680000,
    iz: 67120,
    iz_global: 671200000,
    tf: "17,2",
    tf_global: "17,2",
    tw: "11,1",
    tw_global: "11,1",
    zx: 2787,
    zx_global: 2787000,
    zy: 401,
    zy_global: 401000,
    width: 210,
    width_global: 210,
    thickness: "17,2",
    thickness_global: "17,2",
    height: 550,
    height_global: 550,
  },
  {
    profile_section_id: 2438,
    name: "IPEO500",
    type: "IPE Shape",
    designation: "IPEO500",
    shape: "I",
    country_code: "European",
    ax: 137,
    ax_global: 13700,
    bf: 202,
    bf_global: 202,
    ct: "6,2",
    ct_global: 62,
    d: 506,
    d_global: 506,
    ix: 143,
    ix_global: 1430000,
    iy: 2622,
    iy_global: 26220000,
    iz: 57780,
    iz_global: 577800000,
    tf: 19,
    tf_global: 19,
    tw: 12,
    tw_global: 12,
    zx: 2613,
    zx_global: 2613000,
    zy: 409,
    zy_global: 409000,
    width: 202,
    width_global: 202,
    thickness: 19,
    thickness_global: 19,
    height: 506,
    height_global: 506,
  },
  {
    profile_section_id: 2439,
    name: "IPEA600",
    type: "IPE Shape",
    designation: "IPEA600",
    shape: "I",
    country_code: "European",
    ax: 137,
    ax_global: 13700,
    bf: 220,
    bf_global: 220,
    ct: "6,9",
    ct_global: 69,
    d: 597,
    d_global: 597,
    ix: 119,
    ix_global: 1190000,
    iy: 3116,
    iy_global: 31160000,
    iz: 82920,
    iz_global: 829200000,
    tf: "17,5",
    tf_global: "17,5",
    tw: "9,8",
    tw_global: "9,8",
    zx: 3141,
    zx_global: 3141000,
    zy: 442,
    zy_global: 442000,
    width: 220,
    width_global: 220,
    thickness: "17,5",
    thickness_global: "17,5",
    height: 597,
    height_global: 597,
  },
  {
    profile_section_id: 2440,
    name: "IPER500",
    type: "IPE Shape",
    designation: "IPER500",
    shape: "I",
    country_code: "European",
    ax: 142,
    ax_global: 14200,
    bf: 198,
    bf_global: 198,
    ct: "6,3",
    ct_global: 63,
    d: 508,
    d_global: 508,
    ix: 163,
    ix_global: 1630000,
    iy: 2600,
    iy_global: 26000000,
    iz: 59930,
    iz_global: 599300000,
    tf: 20,
    tf_global: 20,
    tw: "12,6",
    tw_global: "12,6",
    zx: 2709,
    zx_global: 2709000,
    zy: 415,
    zy_global: 415000,
    width: 198,
    width_global: 198,
    thickness: 20,
    thickness_global: 20,
    height: 508,
    height_global: 508,
  },
  {
    profile_section_id: 2441,
    name: "IPE600",
    type: "IPE Shape",
    designation: "IPE600",
    shape: "I",
    country_code: "European",
    ax: 156,
    ax_global: 15600,
    bf: 220,
    bf_global: 220,
    ct: "7,5",
    ct_global: 75,
    d: 600,
    d_global: 600,
    ix: 165,
    ix_global: 1650000,
    iy: 3387,
    iy_global: 33870000,
    iz: 92080,
    iz_global: 920800000,
    tf: 19,
    tf_global: 19,
    tw: 12,
    tw_global: 12,
    zx: 3512,
    zx_global: 3512000,
    zy: 486,
    zy_global: 486000,
    width: 220,
    width_global: 220,
    thickness: 19,
    thickness_global: 19,
    height: 600,
    height_global: 600,
  },
  {
    profile_section_id: 2442,
    name: "IPEO550",
    type: "IPE Shape",
    designation: "IPEO550",
    shape: "I",
    country_code: "European",
    ax: 156,
    ax_global: 15600,
    bf: 212,
    bf_global: 212,
    ct: "6,9",
    ct_global: 69,
    d: 556,
    d_global: 556,
    ix: 188,
    ix_global: 1880000,
    iy: 3224,
    iy_global: 32240000,
    iz: 79160,
    iz_global: 791600000,
    tf: "20,2",
    tf_global: "20,2",
    tw: "12,7",
    tw_global: "12,7",
    zx: 3263,
    zx_global: 3263000,
    zy: 481,
    zy_global: 481000,
    width: 212,
    width_global: 212,
    thickness: "20,2",
    thickness_global: "20,2",
    height: 556,
    height_global: 556,
  },
  {
    profile_section_id: 2443,
    name: "IPEV500",
    type: "IPE Shape",
    designation: "IPEV500",
    shape: "I",
    country_code: "European",
    ax: 164,
    ax_global: 16400,
    bf: 204,
    bf_global: 204,
    ct: "6,4",
    ct_global: 64,
    d: 514,
    d_global: 514,
    ix: 243,
    ix_global: 2430000,
    iy: 3271,
    iy_global: 32710000,
    iz: 70720,
    iz_global: 707200000,
    tf: 23,
    tf_global: 23,
    tw: "14,2",
    tw_global: "14,2",
    zx: 3168,
    zx_global: 3168000,
    zy: 507,
    zy_global: 507000,
    width: 204,
    width_global: 204,
    thickness: 23,
    thickness_global: 23,
    height: 514,
    height_global: 514,
  },
  {
    profile_section_id: 2444,
    name: "IPER550",
    type: "IPE Shape",
    designation: "IPER550",
    shape: "I",
    country_code: "European",
    ax: 170,
    ax_global: 17000,
    bf: 210,
    bf_global: 210,
    ct: "7,1",
    ct_global: 71,
    d: 560,
    d_global: 560,
    ix: 243,
    ix_global: 2430000,
    iy: 3447,
    iy_global: 34470000,
    iz: 86600,
    iz_global: 866000000,
    tf: "22,2",
    tf_global: "22,2",
    tw: 14,
    tw_global: 14,
    zx: 3562,
    zx_global: 3562000,
    zy: 521,
    zy_global: 521000,
    width: 210,
    width_global: 210,
    thickness: "22,2",
    thickness_global: "22,2",
    height: 560,
    height_global: 560,
  },
  {
    profile_section_id: 2445,
    name: "IPE750X137",
    type: "IPE Shape",
    designation: "IPE750X137",
    shape: "I",
    country_code: "European",
    ax: 175,
    ax_global: 17500,
    bf: 263,
    bf_global: 263,
    ct: "9,8",
    ct_global: 98,
    d: 753,
    d_global: 753,
    ix: 137,
    ix_global: 1370000,
    iy: 5166,
    iy_global: 51660000,
    iz: 159900,
    iz_global: 1599000000,
    tf: 17,
    tf_global: 17,
    tw: "11,5",
    tw_global: "11,5",
    zx: 4865,
    zx_global: 4865000,
    zy: 614,
    zy_global: 614000,
    width: 263,
    width_global: 263,
    thickness: 17,
    thickness_global: 17,
    height: 753,
    height_global: 753,
  },
  {
    profile_section_id: 2446,
    name: "IPER600",
    type: "IPE Shape",
    designation: "IPER600",
    shape: "I",
    country_code: "European",
    ax: 184,
    ax_global: 18400,
    bf: 218,
    bf_global: 218,
    ct: "7,7",
    ct_global: 77,
    d: 608,
    d_global: 608,
    ix: 271,
    ix_global: 2710000,
    iy: 3993,
    iy_global: 39930000,
    iz: 110300,
    iz_global: 1103000000,
    tf: 23,
    tf_global: 23,
    tw: 14,
    tw_global: 14,
    zx: 4175,
    zx_global: 4175000,
    zy: 580,
    zy_global: 580000,
    width: 218,
    width_global: 218,
    thickness: 23,
    thickness_global: 23,
    height: 608,
    height_global: 608,
  },
  {
    profile_section_id: 2447,
    name: "IPE750X147",
    type: "IPE Shape",
    designation: "IPE750X147",
    shape: "I",
    country_code: "European",
    ax: 187,
    ax_global: 18700,
    bf: 265,
    bf_global: 265,
    ct: "10,4",
    ct_global: 104,
    d: 753,
    d_global: 753,
    ix: 162,
    ix_global: 1620000,
    iy: 5289,
    iy_global: 52890000,
    iz: 166100,
    iz_global: 1661000000,
    tf: 17,
    tf_global: 17,
    tw: "13,2",
    tw_global: "13,2",
    zx: 5110,
    zx_global: 5110000,
    zy: 631,
    zy_global: 631000,
    width: 265,
    width_global: 265,
    thickness: 17,
    thickness_global: 17,
    height: 753,
    height_global: 753,
  },
  {
    profile_section_id: 2448,
    name: "IPEO600",
    type: "IPE Shape",
    designation: "IPEO600",
    shape: "I",
    country_code: "European",
    ax: 197,
    ax_global: 19700,
    bf: 224,
    bf_global: 224,
    ct: "7,8",
    ct_global: 78,
    d: 610,
    d_global: 610,
    ix: 318,
    ix_global: 3180000,
    iy: 4521,
    iy_global: 45210000,
    iz: 118300,
    iz_global: 1183000000,
    tf: 24,
    tf_global: 24,
    tw: 15,
    tw_global: 15,
    zx: 4471,
    zx_global: 4471000,
    zy: 640,
    zy_global: 640000,
    width: 224,
    width_global: 224,
    thickness: 24,
    thickness_global: 24,
    height: 610,
    height_global: 610,
  },
  {
    profile_section_id: 2449,
    name: "IPEV550",
    type: "IPE Shape",
    designation: "IPEV550",
    shape: "I",
    country_code: "European",
    ax: 202,
    ax_global: 20200,
    bf: 216,
    bf_global: 216,
    ct: "7,5",
    ct_global: 75,
    d: 566,
    d_global: 566,
    ix: 380,
    ix_global: 3800000,
    iy: 4265,
    iy_global: 42650000,
    iz: 102300,
    iz_global: 1023000000,
    tf: "25,2",
    tf_global: "25,2",
    tw: "17,1",
    tw_global: "17,1",
    zx: 4205,
    zx_global: 4205000,
    zy: 632,
    zy_global: 632000,
    width: 216,
    width_global: 216,
    thickness: "25,2",
    thickness_global: "25,2",
    height: 566,
    height_global: 566,
  },
  {
    profile_section_id: 2450,
    name: "IPE750X161",
    type: "IPE Shape",
    designation: "IPE750X161",
    shape: "I",
    country_code: "European",
    ax: 204,
    ax_global: 20400,
    bf: 266,
    bf_global: 266,
    ct: "10,2",
    ct_global: 102,
    d: 758,
    d_global: 758,
    ix: 212,
    ix_global: 2120000,
    iy: 6073,
    iy_global: 60730000,
    iz: 186100,
    iz_global: 1861000000,
    tf: "19,3",
    tf_global: "19,3",
    tw: "13,8",
    tw_global: "13,8",
    zx: 5666,
    zx_global: 5666000,
    zy: 720,
    zy_global: 720000,
    width: 266,
    width_global: 266,
    thickness: "19,3",
    thickness_global: "19,3",
    height: 758,
    height_global: 758,
  },
  {
    profile_section_id: 2451,
    name: "IPE750X173",
    type: "IPE Shape",
    designation: "IPE750X173",
    shape: "I",
    country_code: "European",
    ax: 221,
    ax_global: 22100,
    bf: 267,
    bf_global: 267,
    ct: 10,
    ct_global: 100,
    d: 762,
    d_global: 762,
    ix: 274,
    ix_global: 2740000,
    iy: 6873,
    iy_global: 68730000,
    iz: 205800,
    iz_global: 2058000000,
    tf: "21,6",
    tf_global: "21,6",
    tw: "14,4",
    tw_global: "14,4",
    zx: 6218,
    zx_global: 6218000,
    zy: 810,
    zy_global: 810000,
    width: 267,
    width_global: 267,
    thickness: "21,6",
    thickness_global: "21,6",
    height: 762,
    height_global: 762,
  },
  {
    profile_section_id: 2452,
    name: "IPEV600",
    type: "IPE Shape",
    designation: "IPEV600",
    shape: "I",
    country_code: "European",
    ax: 234,
    ax_global: 23400,
    bf: 228,
    bf_global: 228,
    ct: "8,1",
    ct_global: 81,
    d: 618,
    d_global: 618,
    ix: 512,
    ix_global: 5120000,
    iy: 5570,
    iy_global: 55700000,
    iz: 141600,
    iz_global: 1416000000,
    tf: 28,
    tf_global: 28,
    tw: 18,
    tw_global: 18,
    zx: 5324,
    zx_global: 5324000,
    zy: 780,
    zy_global: 780000,
    width: 228,
    width_global: 228,
    thickness: 28,
    thickness_global: 28,
    height: 618,
    height_global: 618,
  },
  {
    profile_section_id: 2453,
    name: "IPE750X185",
    type: "IPE Shape",
    designation: "IPE750X185",
    shape: "I",
    country_code: "European",
    ax: 236,
    ax_global: 23600,
    bf: 267,
    bf_global: 267,
    ct: "9,9",
    ct_global: 99,
    d: 766,
    d_global: 766,
    ix: 337,
    ix_global: 3370000,
    iy: 7510,
    iy_global: 75100000,
    iz: 223000,
    iz_global: 2230000000,
    tf: "23,6",
    tf_global: "23,6",
    tw: "14,9",
    tw_global: "14,9",
    zx: 6691,
    zx_global: 6691000,
    zy: 884,
    zy_global: 884000,
    width: 267,
    width_global: 267,
    thickness: "23,6",
    thickness_global: "23,6",
    height: 766,
    height_global: 766,
  },
  {
    profile_section_id: 2454,
    name: "IPE750X196",
    type: "IPE Shape",
    designation: "IPE750X196",
    shape: "I",
    country_code: "European",
    ax: 251,
    ax_global: 25100,
    bf: 268,
    bf_global: 268,
    ct: "9,9",
    ct_global: 99,
    d: 770,
    d_global: 770,
    ix: 409,
    ix_global: 4090000,
    iy: 8175,
    iy_global: 81750000,
    iz: 240300,
    iz_global: 2403000000,
    tf: "25,4",
    tf_global: "25,4",
    tw: "15,6",
    tw_global: "15,6",
    zx: 7174,
    zx_global: 7174000,
    zy: 959,
    zy_global: 959000,
    width: 268,
    width_global: 268,
    thickness: "25,4",
    thickness_global: "25,4",
    height: 770,
    height_global: 770,
  },
  {
    profile_section_id: 2455,
    name: "IPE750X210",
    type: "IPE Shape",
    designation: "IPE750X210",
    shape: "I",
    country_code: "European",
    ax: 268,
    ax_global: 26800,
    bf: 268,
    bf_global: 268,
    ct: "9,8",
    ct_global: 98,
    d: 775,
    d_global: 775,
    ix: 514,
    ix_global: 5140000,
    iy: 9011,
    iy_global: 90110000,
    iz: 262200,
    iz_global: 2622000000,
    tf: 28,
    tf_global: 28,
    tw: 16,
    tw_global: 16,
    zx: 7762,
    zx_global: 7762000,
    zy: 1054,
    zy_global: 1054000,
    width: 268,
    width_global: 268,
    thickness: 28,
    thickness_global: 28,
    height: 775,
    height_global: 775,
  },
  {
    profile_section_id: 2456,
    name: "IPE750X222",
    type: "IPE Shape",
    designation: "IPE750X222",
    shape: "I",
    country_code: "European",
    ax: 283,
    ax_global: 28300,
    bf: 269,
    bf_global: 269,
    ct: "9,9",
    ct_global: 99,
    d: 778,
    d_global: 778,
    ix: 605,
    ix_global: 6050000,
    iy: 9604,
    iy_global: 96040000,
    iz: 278200,
    iz_global: 2782000000,
    tf: "29,5",
    tf_global: "29,5",
    tw: 17,
    tw_global: 17,
    zx: 8225,
    zx_global: 8225000,
    zy: 1122,
    zy_global: 1122000,
    width: 269,
    width_global: 269,
    thickness: "29,5",
    thickness_global: "29,5",
    height: 778,
    height_global: 778,
  },
  {
    profile_section_id: 2457,
    name: "IPEA80",
    type: "IPE Shape",
    designation: "IPEA80",
    shape: "I",
    country_code: "European",
    ax: "6,38",
    ax_global: 638,
    bf: 46,
    bf_global: 46,
    ct: "0,8",
    ct_global: 8,
    d: 78,
    d_global: 78,
    ix: "0,42",
    ix_global: 4200,
    iy: "6,85",
    iy_global: 68500,
    iz: "64,38",
    iz_global: 643800,
    tf: "4,2",
    tf_global: "4,2",
    tw: "3,3",
    tw_global: "3,3",
    zx: "18,98",
    zx_global: 18980,
    zy: "4,69",
    zy_global: 4690,
    width: 46,
    width_global: 46,
    thickness: "4,2",
    thickness_global: "4,2",
    height: 78,
    height_global: 78,
  },
  {
    profile_section_id: 2458,
    name: "IPEA100",
    type: "IPE Shape",
    designation: "IPEA100",
    shape: "I",
    country_code: "European",
    ax: "8,78",
    ax_global: 878,
    bf: 55,
    bf_global: 55,
    ct: "1,2",
    ct_global: 12,
    d: 98,
    d_global: 98,
    ix: "0,77",
    ix_global: 7700,
    iy: "13,12",
    iy_global: 131200,
    iz: "141,2",
    iz_global: 1412000,
    tf: "4,7",
    tf_global: "4,7",
    tw: "3,6",
    tw_global: "3,6",
    zx: "32,98",
    zx_global: 32980,
    zy: "7,54",
    zy_global: 7540,
    width: 55,
    width_global: 55,
    thickness: "4,7",
    thickness_global: "4,7",
    height: 98,
    height_global: 98,
  },
  {
    profile_section_id: 2459,
    name: "HE100AA",
    type: "HE Shape",
    designation: "HE100AA",
    shape: "I",
    country_code: "European",
    ax: "15,6",
    ax_global: 1560,
    bf: 100,
    bf_global: 100,
    ct: "0,9",
    ct_global: 9,
    d: 91,
    d_global: 91,
    ix: "2,5",
    ix_global: 25000,
    iy: 92,
    iy_global: 920000,
    iz: 237,
    iz_global: 2370000,
    tf: "5,5",
    tf_global: "5,5",
    tw: "4,2",
    tw_global: "4,2",
    zx: 58,
    zx_global: 58000,
    zy: 28,
    zy_global: 28000,
    width: 100,
    width_global: 100,
    thickness: "5,5",
    thickness_global: "5,5",
    height: 91,
    height_global: 91,
  },
  {
    profile_section_id: 2460,
    name: "HE120AA",
    type: "HE Shape",
    designation: "HE120AA",
    shape: "I",
    country_code: "European",
    ax: "18,6",
    ax_global: 1860,
    bf: 120,
    bf_global: 120,
    ct: "0,9",
    ct_global: 9,
    d: 109,
    d_global: 109,
    ix: "2,8",
    ix_global: 28000,
    iy: 159,
    iy_global: 1590000,
    iz: 413,
    iz_global: 4130000,
    tf: "5,5",
    tf_global: "5,5",
    tw: "4,2",
    tw_global: "4,2",
    zx: 84,
    zx_global: 84000,
    zy: 41,
    zy_global: 41000,
    width: 120,
    width_global: 120,
    thickness: "5,5",
    thickness_global: "5,5",
    height: 109,
    height_global: 109,
  },
  {
    profile_section_id: 2461,
    name: "HE100A",
    type: "HE Shape",
    designation: "HE100A",
    shape: "I",
    country_code: "European",
    ax: "21,2",
    ax_global: 2120,
    bf: 100,
    bf_global: 100,
    ct: "0,9",
    ct_global: 9,
    d: 96,
    d_global: 96,
    ix: "5,2",
    ix_global: 52000,
    iy: 134,
    iy_global: 1340000,
    iz: 349,
    iz_global: 3490000,
    tf: 8,
    tf_global: 8,
    tw: 5,
    tw_global: 5,
    zx: 83,
    zx_global: 83000,
    zy: 41,
    zy_global: 41000,
    width: 100,
    width_global: 100,
    thickness: 8,
    thickness_global: 8,
    height: 96,
    height_global: 96,
  },
  {
    profile_section_id: 2462,
    name: "HE140AA",
    type: "HE Shape",
    designation: "HE140AA",
    shape: "I",
    country_code: "European",
    ax: 23,
    ax_global: 2300,
    bf: 140,
    bf_global: 140,
    ct: 1,
    ct_global: 10,
    d: 128,
    d_global: 128,
    ix: "3,5",
    ix_global: 35000,
    iy: 275,
    iy_global: 2750000,
    iz: 719,
    iz_global: 7190000,
    tf: 6,
    tf_global: 6,
    tw: "4,3",
    tw_global: "4,3",
    zx: 124,
    zx_global: 124000,
    zy: 60,
    zy_global: 60000,
    width: 140,
    width_global: 140,
    thickness: 6,
    thickness_global: 6,
    height: 128,
    height_global: 128,
  },
  {
    profile_section_id: 2463,
    name: "HE120A",
    type: "HE Shape",
    designation: "HE120A",
    shape: "I",
    country_code: "European",
    ax: "25,3",
    ax_global: 2530,
    bf: 120,
    bf_global: 120,
    ct: 1,
    ct_global: 10,
    d: 114,
    d_global: 114,
    ix: 6,
    ix_global: 60000,
    iy: 231,
    iy_global: 2310000,
    iz: 606,
    iz_global: 6060000,
    tf: 8,
    tf_global: 8,
    tw: 5,
    tw_global: 5,
    zx: 119,
    zx_global: 119000,
    zy: 59,
    zy_global: 59000,
    width: 120,
    width_global: 120,
    thickness: 8,
    thickness_global: 8,
    height: 114,
    height_global: 114,
  },
  {
    profile_section_id: 2464,
    name: "HE100B",
    type: "HE Shape",
    designation: "HE100B",
    shape: "I",
    country_code: "European",
    ax: 26,
    ax_global: 2600,
    bf: 100,
    bf_global: 100,
    ct: 1,
    ct_global: 10,
    d: 100,
    d_global: 100,
    ix: "9,3",
    ix_global: 93000,
    iy: 167,
    iy_global: 1670000,
    iz: 450,
    iz_global: 4500000,
    tf: 10,
    tf_global: 10,
    tw: 6,
    tw_global: 6,
    zx: 104,
    zx_global: 104000,
    zy: 51,
    zy_global: 51000,
    width: 100,
    width_global: 100,
    thickness: 10,
    thickness_global: 10,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2465,
    name: "HE160AA",
    type: "HE Shape",
    designation: "HE160AA",
    shape: "I",
    country_code: "European",
    ax: "30,4",
    ax_global: 3040,
    bf: 160,
    bf_global: 160,
    ct: "1,1",
    ct_global: 11,
    d: 148,
    d_global: 148,
    ix: "6,3",
    ix_global: 63000,
    iy: 479,
    iy_global: 4790000,
    iz: 1283,
    iz_global: 12830000,
    tf: 7,
    tf_global: 7,
    tw: "4,5",
    tw_global: "4,5",
    zx: 190,
    zx_global: 190000,
    zy: 91,
    zy_global: 91000,
    width: 160,
    width_global: 160,
    thickness: 7,
    thickness_global: 7,
    height: 148,
    height_global: 148,
  },
  {
    profile_section_id: 2466,
    name: "HE140A",
    type: "HE Shape",
    designation: "HE140A",
    shape: "I",
    country_code: "European",
    ax: "31,4",
    ax_global: 3140,
    bf: 140,
    bf_global: 140,
    ct: "1,1",
    ct_global: 11,
    d: 133,
    d_global: 133,
    ix: "8,1",
    ix_global: 81000,
    iy: 389,
    iy_global: 3890000,
    iz: 1033,
    iz_global: 10330000,
    tf: "8,5",
    tf_global: "8,5",
    tw: "5,5",
    tw_global: "5,5",
    zx: 173,
    zx_global: 173000,
    zy: 85,
    zy_global: 85000,
    width: 140,
    width_global: 140,
    thickness: "8,5",
    thickness_global: "8,5",
    height: 133,
    height_global: 133,
  },
  {
    profile_section_id: 2467,
    name: "HE120B",
    type: "HE Shape",
    designation: "HE120B",
    shape: "I",
    country_code: "European",
    ax: 34,
    ax_global: 3400,
    bf: 120,
    bf_global: 120,
    ct: "1,1",
    ct_global: 11,
    d: 120,
    d_global: 120,
    ix: "13,8",
    ix_global: 138000,
    iy: 318,
    iy_global: 3180000,
    iz: 864,
    iz_global: 8640000,
    tf: 11,
    tf_global: 11,
    tw: "6,5",
    tw_global: "6,5",
    zx: 165,
    zx_global: 165000,
    zy: 81,
    zy_global: 81000,
    width: 120,
    width_global: 120,
    thickness: 11,
    thickness_global: 11,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2468,
    name: "HE180AA",
    type: "HE Shape",
    designation: "HE180AA",
    shape: "I",
    country_code: "European",
    ax: "36,5",
    ax_global: 3650,
    bf: 180,
    bf_global: 180,
    ct: "1,3",
    ct_global: 13,
    d: 167,
    d_global: 167,
    ix: "8,3",
    ix_global: 83000,
    iy: 730,
    iy_global: 7300000,
    iz: 1967,
    iz_global: 19670000,
    tf: "7,5",
    tf_global: "7,5",
    tw: 5,
    tw_global: 5,
    zx: 258,
    zx_global: 258000,
    zy: 124,
    zy_global: 124000,
    width: 180,
    width_global: 180,
    thickness: "7,5",
    thickness_global: "7,5",
    height: 167,
    height_global: 167,
  },
  {
    profile_section_id: 2469,
    name: "HE160A",
    type: "HE Shape",
    designation: "HE160A",
    shape: "I",
    country_code: "European",
    ax: "38,8",
    ax_global: 3880,
    bf: 160,
    bf_global: 160,
    ct: "1,3",
    ct_global: 13,
    d: 152,
    d_global: 152,
    ix: "12,2",
    ix_global: 122000,
    iy: 616,
    iy_global: 6160000,
    iz: 1673,
    iz_global: 16730000,
    tf: 9,
    tf_global: 9,
    tw: 6,
    tw_global: 6,
    zx: 245,
    zx_global: 245000,
    zy: 118,
    zy_global: 118000,
    width: 160,
    width_global: 160,
    thickness: 9,
    thickness_global: 9,
    height: 152,
    height_global: 152,
  },
  {
    profile_section_id: 2470,
    name: "HE140B",
    type: "HE Shape",
    designation: "HE140B",
    shape: "I",
    country_code: "European",
    ax: 43,
    ax_global: 4300,
    bf: 140,
    bf_global: 140,
    ct: "1,3",
    ct_global: 13,
    d: 140,
    d_global: 140,
    ix: "20,1",
    ix_global: 201000,
    iy: 550,
    iy_global: 5500000,
    iz: 1509,
    iz_global: 15090000,
    tf: 12,
    tf_global: 12,
    tw: 7,
    tw_global: 7,
    zx: 245,
    zx_global: 245000,
    zy: 120,
    zy_global: 120000,
    width: 140,
    width_global: 140,
    thickness: 12,
    thickness_global: 12,
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 2471,
    name: "HE200AA",
    type: "HE Shape",
    designation: "HE200AA",
    shape: "I",
    country_code: "European",
    ax: "44,1",
    ax_global: 4410,
    bf: 200,
    bf_global: 200,
    ct: "1,4",
    ct_global: 14,
    d: 186,
    d_global: 186,
    ix: "12,7",
    ix_global: 127000,
    iy: 1068,
    iy_global: 10680000,
    iz: 2944,
    iz_global: 29440000,
    tf: 8,
    tf_global: 8,
    tw: "5,5",
    tw_global: "5,5",
    zx: 347,
    zx_global: 347000,
    zy: 163,
    zy_global: 163000,
    width: 200,
    width_global: 200,
    thickness: 8,
    thickness_global: 8,
    height: 186,
    height_global: 186,
  },
  {
    profile_section_id: 2472,
    name: "HE180A",
    type: "HE Shape",
    designation: "HE180A",
    shape: "I",
    country_code: "European",
    ax: "45,3",
    ax_global: 4530,
    bf: 180,
    bf_global: 180,
    ct: "1,4",
    ct_global: 14,
    d: 171,
    d_global: 171,
    ix: "14,8",
    ix_global: 148000,
    iy: 925,
    iy_global: 9250000,
    iz: 2510,
    iz_global: 25100000,
    tf: "9,5",
    tf_global: "9,5",
    tw: 6,
    tw_global: 6,
    zx: 325,
    zx_global: 325000,
    zy: 156,
    zy_global: 156000,
    width: 180,
    width_global: 180,
    thickness: "9,5",
    thickness_global: "9,5",
    height: 171,
    height_global: 171,
  },
  {
    profile_section_id: 2473,
    name: "HE220AA",
    type: "HE Shape",
    designation: "HE220AA",
    shape: "I",
    country_code: "European",
    ax: "51,5",
    ax_global: 5150,
    bf: 220,
    bf_global: 220,
    ct: "1,6",
    ct_global: 16,
    d: 205,
    d_global: 205,
    ix: "15,9",
    ix_global: 159000,
    iy: 1510,
    iy_global: 15100000,
    iz: 4170,
    iz_global: 41700000,
    tf: "8,5",
    tf_global: "8,5",
    tw: 6,
    tw_global: 6,
    zx: 445,
    zx_global: 445000,
    zy: 209,
    zy_global: 209000,
    width: 220,
    width_global: 220,
    thickness: "8,5",
    thickness_global: "8,5",
    height: 205,
    height_global: 205,
  },
  {
    profile_section_id: 2474,
    name: "HE100M",
    type: "HE Shape",
    designation: "HE100M",
    shape: "I",
    country_code: "European",
    ax: "53,2",
    ax_global: 5320,
    bf: 106,
    bf_global: 106,
    ct: "1,6",
    ct_global: 16,
    d: 120,
    d_global: 120,
    ix: "68,2",
    ix_global: 682000,
    iy: 399,
    iy_global: 3990000,
    iz: 1143,
    iz_global: 11430000,
    tf: 20,
    tf_global: 20,
    tw: 12,
    tw_global: 12,
    zx: 236,
    zx_global: 236000,
    zy: 116,
    zy_global: 116000,
    width: 106,
    width_global: 106,
    thickness: 20,
    thickness_global: 20,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2475,
    name: "HE200A",
    type: "HE Shape",
    designation: "HE200A",
    shape: "I",
    country_code: "European",
    ax: "53,8",
    ax_global: 5380,
    bf: 200,
    bf_global: 200,
    ct: "1,5",
    ct_global: 15,
    d: 190,
    d_global: 190,
    ix: 21,
    ix_global: 210000,
    iy: 1336,
    iy_global: 13360000,
    iz: 3692,
    iz_global: 36920000,
    tf: 10,
    tf_global: 10,
    tw: "6,5",
    tw_global: "6,5",
    zx: 429,
    zx_global: 429000,
    zy: 204,
    zy_global: 204000,
    width: 200,
    width_global: 200,
    thickness: 10,
    thickness_global: 10,
    height: 190,
    height_global: 190,
  },
  {
    profile_section_id: 2476,
    name: "HE160B",
    type: "HE Shape",
    designation: "HE160B",
    shape: "I",
    country_code: "European",
    ax: "54,3",
    ax_global: 5430,
    bf: 160,
    bf_global: 160,
    ct: "1,5",
    ct_global: 15,
    d: 160,
    d_global: 160,
    ix: "31,2",
    ix_global: 312000,
    iy: 889,
    iy_global: 8890000,
    iz: 2492,
    iz_global: 24920000,
    tf: 13,
    tf_global: 13,
    tw: 8,
    tw_global: 8,
    zx: 354,
    zx_global: 354000,
    zy: 170,
    zy_global: 170000,
    width: 160,
    width_global: 160,
    thickness: 13,
    thickness_global: 13,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2477,
    name: "HE240AA",
    type: "HE Shape",
    designation: "HE240AA",
    shape: "I",
    country_code: "European",
    ax: "60,4",
    ax_global: 6040,
    bf: 240,
    bf_global: 240,
    ct: "1,8",
    ct_global: 18,
    d: 224,
    d_global: 224,
    ix: 23,
    ix_global: 230000,
    iy: 2077,
    iy_global: 20770000,
    iz: 5835,
    iz_global: 58350000,
    tf: 9,
    tf_global: 9,
    tw: "6,5",
    tw_global: "6,5",
    zx: 571,
    zx_global: 571000,
    zy: 264,
    zy_global: 264000,
    width: 240,
    width_global: 240,
    thickness: 9,
    thickness_global: 9,
    height: 224,
    height_global: 224,
  },
  {
    profile_section_id: 2478,
    name: "HE220A",
    type: "HE Shape",
    designation: "HE220A",
    shape: "I",
    country_code: "European",
    ax: "64,3",
    ax_global: 6430,
    bf: 220,
    bf_global: 220,
    ct: "1,7",
    ct_global: 17,
    d: 210,
    d_global: 210,
    ix: "28,5",
    ix_global: 285000,
    iy: 1955,
    iy_global: 19550000,
    iz: 5410,
    iz_global: 54100000,
    tf: 11,
    tf_global: 11,
    tw: 7,
    tw_global: 7,
    zx: 568,
    zx_global: 568000,
    zy: 271,
    zy_global: 271000,
    width: 220,
    width_global: 220,
    thickness: 11,
    thickness_global: 11,
    height: 210,
    height_global: 210,
  },
  {
    profile_section_id: 2479,
    name: "HE180B",
    type: "HE Shape",
    designation: "HE180B",
    shape: "I",
    country_code: "European",
    ax: "65,3",
    ax_global: 6530,
    bf: 180,
    bf_global: 180,
    ct: "1,6",
    ct_global: 16,
    d: 180,
    d_global: 180,
    ix: "42,2",
    ix_global: 422000,
    iy: 1363,
    iy_global: 13630000,
    iz: 3831,
    iz_global: 38310000,
    tf: 14,
    tf_global: 14,
    tw: "8,5",
    tw_global: "8,5",
    zx: 481,
    zx_global: 481000,
    zy: 231,
    zy_global: 231000,
    width: 180,
    width_global: 180,
    thickness: 14,
    thickness_global: 14,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 2480,
    name: "HE120M",
    type: "HE Shape",
    designation: "HE120M",
    shape: "I",
    country_code: "European",
    ax: "66,4",
    ax_global: 6640,
    bf: 126,
    bf_global: 126,
    ct: "1,7",
    ct_global: 17,
    d: 140,
    d_global: 140,
    ix: "91,7",
    ix_global: 917000,
    iy: 703,
    iy_global: 7030000,
    iz: 2018,
    iz_global: 20180000,
    tf: 21,
    tf_global: 21,
    tw: "12,5",
    tw_global: "12,5",
    zx: 351,
    zx_global: 351000,
    zy: 172,
    zy_global: 172000,
    width: 126,
    width_global: 126,
    thickness: 21,
    thickness_global: 21,
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 2481,
    name: "HE260AA",
    type: "HE Shape",
    designation: "HE260AA",
    shape: "I",
    country_code: "European",
    ax: 69,
    ax_global: 6900,
    bf: 260,
    bf_global: 260,
    ct: "1,8",
    ct_global: 18,
    d: 244,
    d_global: 244,
    ix: "30,3",
    ix_global: 303000,
    iy: 2788,
    iy_global: 27880000,
    iz: 7981,
    iz_global: 79810000,
    tf: "9,5",
    tf_global: "9,5",
    tw: "6,5",
    tw_global: "6,5",
    zx: 714,
    zx_global: 714000,
    zy: 328,
    zy_global: 328000,
    width: 260,
    width_global: 260,
    thickness: "9,5",
    thickness_global: "9,5",
    height: 244,
    height_global: 244,
  },
  {
    profile_section_id: 2482,
    name: "HE240A",
    type: "HE Shape",
    designation: "HE240A",
    shape: "I",
    country_code: "European",
    ax: "76,8",
    ax_global: 7680,
    bf: 240,
    bf_global: 240,
    ct: "1,8",
    ct_global: 18,
    d: 230,
    d_global: 230,
    ix: "41,6",
    ix_global: 416000,
    iy: 2769,
    iy_global: 27690000,
    iz: 7763,
    iz_global: 77630000,
    tf: 12,
    tf_global: 12,
    tw: "7,5",
    tw_global: "7,5",
    zx: 745,
    zx_global: 745000,
    zy: 352,
    zy_global: 352000,
    width: 240,
    width_global: 240,
    thickness: 12,
    thickness_global: 12,
    height: 230,
    height_global: 230,
  },
  {
    profile_section_id: 2483,
    name: "HE280AA",
    type: "HE Shape",
    designation: "HE280AA",
    shape: "I",
    country_code: "European",
    ax: 78,
    ax_global: 7800,
    bf: 280,
    bf_global: 280,
    ct: 2,
    ct_global: 20,
    d: 264,
    d_global: 264,
    ix: "36,2",
    ix_global: 362000,
    iy: 3664,
    iy_global: 36640000,
    iz: 10560,
    iz_global: 105600000,
    tf: 10,
    tf_global: 10,
    tw: 7,
    tw_global: 7,
    zx: 873,
    zx_global: 873000,
    zy: 399,
    zy_global: 399000,
    width: 280,
    width_global: 280,
    thickness: 10,
    thickness_global: 10,
    height: 264,
    height_global: 264,
  },
  {
    profile_section_id: 2484,
    name: "HE200B",
    type: "HE Shape",
    designation: "HE200B",
    shape: "I",
    country_code: "European",
    ax: "78,1",
    ax_global: 7810,
    bf: 200,
    bf_global: 200,
    ct: "1,8",
    ct_global: 18,
    d: 200,
    d_global: 200,
    ix: "59,3",
    ix_global: 593000,
    iy: 2003,
    iy_global: 20030000,
    iz: 5696,
    iz_global: 56960000,
    tf: 15,
    tf_global: 15,
    tw: 9,
    tw_global: 9,
    zx: 643,
    zx_global: 643000,
    zy: 306,
    zy_global: 306000,
    width: 200,
    width_global: 200,
    thickness: 15,
    thickness_global: 15,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2485,
    name: "HE140M",
    type: "HE Shape",
    designation: "HE140M",
    shape: "I",
    country_code: "European",
    ax: "80,6",
    ax_global: 8060,
    bf: 146,
    bf_global: 146,
    ct: "1,9",
    ct_global: 19,
    d: 160,
    d_global: 160,
    ix: 120,
    ix_global: 1200000,
    iy: 1144,
    iy_global: 11440000,
    iz: 3291,
    iz_global: 32910000,
    tf: 22,
    tf_global: 22,
    tw: 13,
    tw_global: 13,
    zx: 494,
    zx_global: 494000,
    zy: 241,
    zy_global: 241000,
    width: 146,
    width_global: 146,
    thickness: 22,
    thickness_global: 22,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2486,
    name: "HE260A",
    type: "HE Shape",
    designation: "HE260A",
    shape: "I",
    country_code: "European",
    ax: "86,8",
    ax_global: 8680,
    bf: 260,
    bf_global: 260,
    ct: "1,9",
    ct_global: 19,
    d: 250,
    d_global: 250,
    ix: "52,4",
    ix_global: 524000,
    iy: 3668,
    iy_global: 36680000,
    iz: 10450,
    iz_global: 104500000,
    tf: "12,5",
    tf_global: "12,5",
    tw: "7,5",
    tw_global: "7,5",
    zx: 920,
    zx_global: 920000,
    zy: 430,
    zy_global: 430000,
    width: 260,
    width_global: 260,
    thickness: "12,5",
    thickness_global: "12,5",
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 2487,
    name: "HE300AA",
    type: "HE Shape",
    designation: "HE300AA",
    shape: "I",
    country_code: "European",
    ax: "88,9",
    ax_global: 8890,
    bf: 300,
    bf_global: 300,
    ct: "2,2",
    ct_global: 22,
    d: 283,
    d_global: 283,
    ix: "49,3",
    ix_global: 493000,
    iy: 4734,
    iy_global: 47340000,
    iz: 13800,
    iz_global: 138000000,
    tf: "10,5",
    tf_global: "10,5",
    tw: "7,5",
    tw_global: "7,5",
    zx: 1065,
    zx_global: 1065000,
    zy: 482,
    zy_global: 482000,
    width: 300,
    width_global: 300,
    thickness: "10,5",
    thickness_global: "10,5",
    height: 283,
    height_global: 283,
  },
  {
    profile_section_id: 2488,
    name: "HE220B",
    type: "HE Shape",
    designation: "HE220B",
    shape: "I",
    country_code: "European",
    ax: 91,
    ax_global: 9100,
    bf: 220,
    bf_global: 220,
    ct: "1,9",
    ct_global: 19,
    d: 220,
    d_global: 220,
    ix: "76,6",
    ix_global: 766000,
    iy: 2843,
    iy_global: 28430000,
    iz: 8091,
    iz_global: 80910000,
    tf: 16,
    tf_global: 16,
    tw: "9,5",
    tw_global: "9,5",
    zx: 827,
    zx_global: 827000,
    zy: 394,
    zy_global: 394000,
    width: 220,
    width_global: 220,
    thickness: 16,
    thickness_global: 16,
    height: 220,
    height_global: 220,
  },
  {
    profile_section_id: 2489,
    name: "HE320AA",
    type: "HE Shape",
    designation: "HE320AA",
    shape: "I",
    country_code: "European",
    ax: "94,6",
    ax_global: 9460,
    bf: 300,
    bf_global: 300,
    ct: "2,4",
    ct_global: 24,
    d: 301,
    d_global: 301,
    ix: "55,9",
    ix_global: 559000,
    iy: 4959,
    iy_global: 49590000,
    iz: 16450,
    iz_global: 164500000,
    tf: 11,
    tf_global: 11,
    tw: 8,
    tw_global: 8,
    zx: 1196,
    zx_global: 1196000,
    zy: 506,
    zy_global: 506000,
    width: 300,
    width_global: 300,
    thickness: 11,
    thickness_global: 11,
    height: 301,
    height_global: 301,
  },
  {
    profile_section_id: 2490,
    name: "HE160M",
    type: "HE Shape",
    designation: "HE160M",
    shape: "I",
    country_code: "European",
    ax: "97,1",
    ax_global: 9710,
    bf: 166,
    bf_global: 166,
    ct: "2,1",
    ct_global: 21,
    d: 180,
    d_global: 180,
    ix: 162,
    ix_global: 1620000,
    iy: 1759,
    iy_global: 17590000,
    iz: 5098,
    iz_global: 50980000,
    tf: 23,
    tf_global: 23,
    tw: 14,
    tw_global: 14,
    zx: 675,
    zx_global: 675000,
    zy: 325,
    zy_global: 325000,
    width: 166,
    width_global: 166,
    thickness: 23,
    thickness_global: 23,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 2491,
    name: "HE280A",
    type: "HE Shape",
    designation: "HE280A",
    shape: "I",
    country_code: "European",
    ax: "97,3",
    ax_global: 9730,
    bf: 280,
    bf_global: 280,
    ct: "2,1",
    ct_global: 21,
    d: 270,
    d_global: 270,
    ix: "62,1",
    ix_global: 621000,
    iy: 4763,
    iy_global: 47630000,
    iz: 13670,
    iz_global: 136700000,
    tf: 13,
    tf_global: 13,
    tw: 8,
    tw_global: 8,
    zx: 1112,
    zx_global: 1112000,
    zy: 518,
    zy_global: 518000,
    width: 280,
    width_global: 280,
    thickness: 13,
    thickness_global: 13,
    height: 270,
    height_global: 270,
  },
  {
    profile_section_id: 2492,
    name: "HE340AA",
    type: "HE Shape",
    designation: "HE340AA",
    shape: "I",
    country_code: "European",
    ax: 101,
    ax_global: 10100,
    bf: 300,
    bf_global: 300,
    ct: "2,7",
    ct_global: 27,
    d: 320,
    d_global: 320,
    ix: "63,1",
    ix_global: 631000,
    iy: 5185,
    iy_global: 51850000,
    iz: 19550,
    iz_global: 195500000,
    tf: "11,5",
    tf_global: "11,5",
    tw: "8,5",
    tw_global: "8,5",
    zx: 1341,
    zx_global: 1341000,
    zy: 529,
    zy_global: 529000,
    width: 300,
    width_global: 300,
    thickness: "11,5",
    thickness_global: "11,5",
    height: 320,
    height_global: 320,
  },
  {
    profile_section_id: 2493,
    name: "HE240B",
    type: "HE Shape",
    designation: "HE240B",
    shape: "I",
    country_code: "European",
    ax: 106,
    ax_global: 10600,
    bf: 240,
    bf_global: 240,
    ct: "2,1",
    ct_global: 21,
    d: 240,
    d_global: 240,
    ix: 103,
    ix_global: 1030000,
    iy: 3923,
    iy_global: 39230000,
    iz: 11260,
    iz_global: 112600000,
    tf: 17,
    tf_global: 17,
    tw: 10,
    tw_global: 10,
    zx: 1053,
    zx_global: 1053000,
    zy: 498,
    zy_global: 498000,
    width: 240,
    width_global: 240,
    thickness: 17,
    thickness_global: 17,
    height: 240,
    height_global: 240,
  },
  {
    profile_section_id: 2494,
    name: "HE360AA",
    type: "HE Shape",
    designation: "HE360AA",
    shape: "I",
    country_code: "European",
    ax: 107,
    ax_global: 10700,
    bf: 300,
    bf_global: 300,
    ct: "2,9",
    ct_global: 29,
    d: 339,
    d_global: 339,
    ix: 71,
    ix_global: 710000,
    iy: 5410,
    iy_global: 54100000,
    iz: 23040,
    iz_global: 230400000,
    tf: 12,
    tf_global: 12,
    tw: 9,
    tw_global: 9,
    zx: 1495,
    zx_global: 1495000,
    zy: 553,
    zy_global: 553000,
    width: 300,
    width_global: 300,
    thickness: 12,
    thickness_global: 12,
    height: 339,
    height_global: 339,
  },
  {
    profile_section_id: 2495,
    name: "HE180M",
    type: "HE Shape",
    designation: "HE180M",
    shape: "I",
    country_code: "European",
    ax: 113,
    ax_global: 11300,
    bf: 186,
    bf_global: 186,
    ct: "2,2",
    ct_global: 22,
    d: 200,
    d_global: 200,
    ix: 203,
    ix_global: 2030000,
    iy: 2580,
    iy_global: 25800000,
    iz: 7483,
    iz_global: 74830000,
    tf: 24,
    tf_global: 24,
    tw: "14,5",
    tw_global: "14,5",
    zx: 883,
    zx_global: 883000,
    zy: 425,
    zy_global: 425000,
    width: 186,
    width_global: 186,
    thickness: 24,
    thickness_global: 24,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2496,
    name: "HE300A",
    type: "HE Shape",
    designation: "HE300A",
    shape: "I",
    country_code: "European",
    ax: 113,
    ax_global: 11300,
    bf: 300,
    bf_global: 300,
    ct: "2,2",
    ct_global: 22,
    d: 290,
    d_global: 290,
    ix: "85,2",
    ix_global: 852000,
    iy: 6310,
    iy_global: 63100000,
    iz: 18260,
    iz_global: 182600000,
    tf: 14,
    tf_global: 14,
    tw: "8,5",
    tw_global: "8,5",
    zx: 1383,
    zx_global: 1383000,
    zy: 641,
    zy_global: 641000,
    width: 300,
    width_global: 300,
    thickness: 14,
    thickness_global: 14,
    height: 290,
    height_global: 290,
  },
  {
    profile_section_id: 2497,
    name: "HE260B",
    type: "HE Shape",
    designation: "HE260B",
    shape: "I",
    country_code: "European",
    ax: 118,
    ax_global: 11800,
    bf: 260,
    bf_global: 260,
    ct: "2,2",
    ct_global: 22,
    d: 260,
    d_global: 260,
    ix: 124,
    ix_global: 1240000,
    iy: 5135,
    iy_global: 51350000,
    iz: 14920,
    iz_global: 149200000,
    tf: "17,5",
    tf_global: "17,5",
    tw: 10,
    tw_global: 10,
    zx: 1283,
    zx_global: 1283000,
    zy: 602,
    zy_global: 602000,
    width: 260,
    width_global: 260,
    thickness: "17,5",
    thickness_global: "17,5",
    height: 260,
    height_global: 260,
  },
  {
    profile_section_id: 2498,
    name: "HE400AA",
    type: "HE Shape",
    designation: "HE400AA",
    shape: "I",
    country_code: "European",
    ax: 118,
    ax_global: 11800,
    bf: 300,
    bf_global: 300,
    ct: "3,4",
    ct_global: 34,
    d: 378,
    d_global: 378,
    ix: "84,7",
    ix_global: 847000,
    iy: 5861,
    iy_global: 58610000,
    iz: 31250,
    iz_global: 312500000,
    tf: 13,
    tf_global: 13,
    tw: "9,5",
    tw_global: "9,5",
    zx: 1824,
    zx_global: 1824000,
    zy: 600,
    zy_global: 600000,
    width: 300,
    width_global: 300,
    thickness: 13,
    thickness_global: 13,
    height: 378,
    height_global: 378,
  },
  {
    profile_section_id: 2499,
    name: "HE320A",
    type: "HE Shape",
    designation: "HE320A",
    shape: "I",
    country_code: "European",
    ax: 124,
    ax_global: 12400,
    bf: 300,
    bf_global: 300,
    ct: "2,4",
    ct_global: 24,
    d: 310,
    d_global: 310,
    ix: 108,
    ix_global: 1080000,
    iy: 6985,
    iy_global: 69850000,
    iz: 22930,
    iz_global: 229300000,
    tf: "15,5",
    tf_global: "15,5",
    tw: 9,
    tw_global: 9,
    zx: 1628,
    zx_global: 1628000,
    zy: 710,
    zy_global: 710000,
    width: 300,
    width_global: 300,
    thickness: "15,5",
    thickness_global: "15,5",
    height: 310,
    height_global: 310,
  },
  {
    profile_section_id: 2500,
    name: "HE450AA",
    type: "HE Shape",
    designation: "HE450AA",
    shape: "I",
    country_code: "European",
    ax: 127,
    ax_global: 12700,
    bf: 300,
    bf_global: 300,
    ct: "4,1",
    ct_global: 41,
    d: 425,
    d_global: 425,
    ix: "95,6",
    ix_global: 956000,
    iy: 6088,
    iy_global: 60880000,
    iz: 41890,
    iz_global: 418900000,
    tf: "13,5",
    tf_global: "13,5",
    tw: 10,
    tw_global: 10,
    zx: 2183,
    zx_global: 2183000,
    zy: 624,
    zy_global: 624000,
    width: 300,
    width_global: 300,
    thickness: "13,5",
    thickness_global: "13,5",
    height: 425,
    height_global: 425,
  },
  {
    profile_section_id: 2501,
    name: "HE200M",
    type: "HE Shape",
    designation: "HE200M",
    shape: "I",
    country_code: "European",
    ax: 131,
    ax_global: 13100,
    bf: 206,
    bf_global: 206,
    ct: "2,4",
    ct_global: 24,
    d: 220,
    d_global: 220,
    ix: 259,
    ix_global: 2590000,
    iy: 3651,
    iy_global: 36510000,
    iz: 10640,
    iz_global: 106400000,
    tf: 25,
    tf_global: 25,
    tw: 15,
    tw_global: 15,
    zx: 1135,
    zx_global: 1135000,
    zy: 543,
    zy_global: 543000,
    width: 206,
    width_global: 206,
    thickness: 25,
    thickness_global: 25,
    height: 220,
    height_global: 220,
  },
  {
    profile_section_id: 2502,
    name: "HE280B",
    type: "HE Shape",
    designation: "HE280B",
    shape: "I",
    country_code: "European",
    ax: 131,
    ax_global: 13100,
    bf: 280,
    bf_global: 280,
    ct: "2,3",
    ct_global: 23,
    d: 280,
    d_global: 280,
    ix: 144,
    ix_global: 1440000,
    iy: 6595,
    iy_global: 65950000,
    iz: 19270,
    iz_global: 192700000,
    tf: 18,
    tf_global: 18,
    tw: "10,5",
    tw_global: "10,5",
    zx: 1534,
    zx_global: 1534000,
    zy: 718,
    zy_global: 718000,
    width: 280,
    width_global: 280,
    thickness: 18,
    thickness_global: 18,
    height: 280,
    height_global: 280,
  },
  {
    profile_section_id: 2503,
    name: "HE340A",
    type: "HE Shape",
    designation: "HE340A",
    shape: "I",
    country_code: "European",
    ax: 133,
    ax_global: 13300,
    bf: 300,
    bf_global: 300,
    ct: "2,6",
    ct_global: 26,
    d: 330,
    d_global: 330,
    ix: 127,
    ix_global: 1270000,
    iy: 7436,
    iy_global: 74360000,
    iz: 27690,
    iz_global: 276900000,
    tf: "16,5",
    tf_global: "16,5",
    tw: "9,5",
    tw_global: "9,5",
    zx: 1850,
    zx_global: 1850000,
    zy: 756,
    zy_global: 756000,
    width: 300,
    width_global: 300,
    thickness: "16,5",
    thickness_global: "16,5",
    height: 330,
    height_global: 330,
  },
  {
    profile_section_id: 2504,
    name: "HE500AA",
    type: "HE Shape",
    designation: "HE500AA",
    shape: "I",
    country_code: "European",
    ax: 137,
    ax_global: 13700,
    bf: 300,
    bf_global: 300,
    ct: "4,8",
    ct_global: 48,
    d: 472,
    d_global: 472,
    ix: 108,
    ix_global: 1080000,
    iy: 6314,
    iy_global: 63140000,
    iz: 54640,
    iz_global: 546400000,
    tf: 14,
    tf_global: 14,
    tw: "10,5",
    tw_global: "10,5",
    zx: 2576,
    zx_global: 2576000,
    zy: 649,
    zy_global: 649000,
    width: 300,
    width_global: 300,
    thickness: 14,
    thickness_global: 14,
    height: 472,
    height_global: 472,
  },
  {
    profile_section_id: 2505,
    name: "HE360A",
    type: "HE Shape",
    designation: "HE360A",
    shape: "I",
    country_code: "European",
    ax: 143,
    ax_global: 14300,
    bf: 300,
    bf_global: 300,
    ct: "2,9",
    ct_global: 29,
    d: 350,
    d_global: 350,
    ix: 149,
    ix_global: 1490000,
    iy: 7887,
    iy_global: 78870000,
    iz: 33090,
    iz_global: 330900000,
    tf: "17,5",
    tf_global: "17,5",
    tw: 10,
    tw_global: 10,
    zx: 2088,
    zx_global: 2088000,
    zy: 802,
    zy_global: 802000,
    width: 300,
    width_global: 300,
    thickness: "17,5",
    thickness_global: "17,5",
    height: 350,
    height_global: 350,
  },
  {
    profile_section_id: 2506,
    name: "HE220M",
    type: "HE Shape",
    designation: "HE220M",
    shape: "I",
    country_code: "European",
    ax: 149,
    ax_global: 14900,
    bf: 226,
    bf_global: 226,
    ct: "2,5",
    ct_global: 25,
    d: 240,
    d_global: 240,
    ix: 315,
    ix_global: 3150000,
    iy: 5012,
    iy_global: 50120000,
    iz: 14600,
    iz_global: 146000000,
    tf: 26,
    tf_global: 26,
    tw: "15,5",
    tw_global: "15,5",
    zx: 1419,
    zx_global: 1419000,
    zy: 679,
    zy_global: 679000,
    width: 226,
    width_global: 226,
    thickness: 26,
    thickness_global: 26,
    height: 240,
    height_global: 240,
  },
  {
    profile_section_id: 2507,
    name: "HE300B",
    type: "HE Shape",
    designation: "HE300B",
    shape: "I",
    country_code: "European",
    ax: 149,
    ax_global: 14900,
    bf: 300,
    bf_global: 300,
    ct: "2,5",
    ct_global: 25,
    d: 300,
    d_global: 300,
    ix: 185,
    ix_global: 1850000,
    iy: 8563,
    iy_global: 85630000,
    iz: 25170,
    iz_global: 251700000,
    tf: 19,
    tf_global: 19,
    tw: 11,
    tw_global: 11,
    zx: 1869,
    zx_global: 1869000,
    zy: 870,
    zy_global: 870000,
    width: 300,
    width_global: 300,
    thickness: 19,
    thickness_global: 19,
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 2508,
    name: "HE550AA",
    type: "HE Shape",
    designation: "HE550AA",
    shape: "I",
    country_code: "European",
    ax: 153,
    ax_global: 15300,
    bf: 300,
    bf_global: 300,
    ct: "5,6",
    ct_global: 56,
    d: 522,
    d_global: 522,
    ix: 134,
    ix_global: 1340000,
    iy: 6767,
    iy_global: 67670000,
    iz: 72870,
    iz_global: 728700000,
    tf: 15,
    tf_global: 15,
    tw: "11,5",
    tw_global: "11,5",
    zx: 3128,
    zx_global: 3128000,
    zy: 699,
    zy_global: 699000,
    width: 300,
    width_global: 300,
    thickness: 15,
    thickness_global: 15,
    height: 522,
    height_global: 522,
  },
  {
    profile_section_id: 2509,
    name: "HE400A",
    type: "HE Shape",
    designation: "HE400A",
    shape: "I",
    country_code: "European",
    ax: 159,
    ax_global: 15900,
    bf: 300,
    bf_global: 300,
    ct: "3,4",
    ct_global: 34,
    d: 390,
    d_global: 390,
    ix: 189,
    ix_global: 1890000,
    iy: 8564,
    iy_global: 85640000,
    iz: 45070,
    iz_global: 450700000,
    tf: 19,
    tf_global: 19,
    tw: 11,
    tw_global: 11,
    zx: 2562,
    zx_global: 2562000,
    zy: 873,
    zy_global: 873000,
    width: 300,
    width_global: 300,
    thickness: 19,
    thickness_global: 19,
    height: 390,
    height_global: 390,
  },
  {
    profile_section_id: 2510,
    name: "HE320B",
    type: "HE Shape",
    designation: "HE320B",
    shape: "I",
    country_code: "European",
    ax: 161,
    ax_global: 16100,
    bf: 300,
    bf_global: 300,
    ct: "2,7",
    ct_global: 27,
    d: 320,
    d_global: 320,
    ix: 225,
    ix_global: 2250000,
    iy: 9239,
    iy_global: 92390000,
    iz: 30820,
    iz_global: 308200000,
    tf: "20,5",
    tf_global: "20,5",
    tw: "11,5",
    tw_global: "11,5",
    zx: 2149,
    zx_global: 2149000,
    zy: 939,
    zy_global: 939000,
    width: 300,
    width_global: 300,
    thickness: "20,5",
    thickness_global: "20,5",
    height: 320,
    height_global: 320,
  },
  {
    profile_section_id: 2511,
    name: "HE600AA",
    type: "HE Shape",
    designation: "HE600AA",
    shape: "I",
    country_code: "European",
    ax: 164,
    ax_global: 16400,
    bf: 300,
    bf_global: 300,
    ct: "6,5",
    ct_global: 65,
    d: 571,
    d_global: 571,
    ix: 150,
    ix_global: 1500000,
    iy: 6993,
    iy_global: 69930000,
    iz: 91870,
    iz_global: 918700000,
    tf: "15,5",
    tf_global: "15,5",
    tw: 12,
    tw_global: 12,
    zx: 3623,
    zx_global: 3623000,
    zy: 724,
    zy_global: 724000,
    width: 300,
    width_global: 300,
    thickness: "15,5",
    thickness_global: "15,5",
    height: 571,
    height_global: 571,
  },
  {
    profile_section_id: 2512,
    name: "HE340B",
    type: "HE Shape",
    designation: "HE340B",
    shape: "I",
    country_code: "European",
    ax: 171,
    ax_global: 17100,
    bf: 300,
    bf_global: 300,
    ct: "2,9",
    ct_global: 29,
    d: 340,
    d_global: 340,
    ix: 257,
    ix_global: 2570000,
    iy: 9690,
    iy_global: 96900000,
    iz: 36660,
    iz_global: 366600000,
    tf: "21,5",
    tf_global: "21,5",
    tw: 12,
    tw_global: 12,
    zx: 2408,
    zx_global: 2408000,
    zy: 986,
    zy_global: 986000,
    width: 300,
    width_global: 300,
    thickness: "21,5",
    thickness_global: "21,5",
    height: 340,
    height_global: 340,
  },
  {
    profile_section_id: 2513,
    name: "HE650AA",
    type: "HE Shape",
    designation: "HE650AA",
    shape: "I",
    country_code: "European",
    ax: 176,
    ax_global: 17600,
    bf: 300,
    bf_global: 300,
    ct: "7,3",
    ct_global: 73,
    d: 620,
    d_global: 620,
    ix: 168,
    ix_global: 1680000,
    iy: 7221,
    iy_global: 72210000,
    iz: 113900,
    iz_global: 1139000000,
    tf: 16,
    tf_global: 16,
    tw: "12,5",
    tw_global: "12,5",
    zx: 4160,
    zx_global: 4160000,
    zy: 751,
    zy_global: 751000,
    width: 300,
    width_global: 300,
    thickness: 16,
    thickness_global: 16,
    height: 620,
    height_global: 620,
  },
  {
    profile_section_id: 2514,
    name: "HE450A",
    type: "HE Shape",
    designation: "HE450A",
    shape: "I",
    country_code: "European",
    ax: 178,
    ax_global: 17800,
    bf: 300,
    bf_global: 300,
    ct: "3,9",
    ct_global: 39,
    d: 440,
    d_global: 440,
    ix: 244,
    ix_global: 2440000,
    iy: 9465,
    iy_global: 94650000,
    iz: 63720,
    iz_global: 637200000,
    tf: 21,
    tf_global: 21,
    tw: "11,5",
    tw_global: "11,5",
    zx: 3216,
    zx_global: 3216000,
    zy: 966,
    zy_global: 966000,
    width: 300,
    width_global: 300,
    thickness: 21,
    thickness_global: 21,
    height: 440,
    height_global: 440,
  },
  {
    profile_section_id: 2515,
    name: "HE360B",
    type: "HE Shape",
    designation: "HE360B",
    shape: "I",
    country_code: "European",
    ax: 181,
    ax_global: 18100,
    bf: 300,
    bf_global: 300,
    ct: "3,2",
    ct_global: 32,
    d: 360,
    d_global: 360,
    ix: 292,
    ix_global: 2920000,
    iy: 10140,
    iy_global: 101400000,
    iz: 43190,
    iz_global: 431900000,
    tf: "22,5",
    tf_global: "22,5",
    tw: "12,5",
    tw_global: "12,5",
    zx: 2683,
    zx_global: 2683000,
    zy: 1032,
    zy_global: 1032000,
    width: 300,
    width_global: 300,
    thickness: "22,5",
    thickness_global: "22,5",
    height: 360,
    height_global: 360,
  },
  {
    profile_section_id: 2516,
    name: "HE700AA",
    type: "HE Shape",
    designation: "HE700AA",
    shape: "I",
    country_code: "European",
    ax: 191,
    ax_global: 19100,
    bf: 300,
    bf_global: 300,
    ct: "8,2",
    ct_global: 82,
    d: 670,
    d_global: 670,
    ix: 195,
    ix_global: 1950000,
    iy: 7673,
    iy_global: 76730000,
    iz: 142700,
    iz_global: 1427000000,
    tf: 17,
    tf_global: 17,
    tw: 13,
    tw_global: 13,
    zx: 4840,
    zx_global: 4840000,
    zy: 800,
    zy_global: 800000,
    width: 300,
    width_global: 300,
    thickness: 17,
    thickness_global: 17,
    height: 670,
    height_global: 670,
  },
  {
    profile_section_id: 2517,
    name: "HE400B",
    type: "HE Shape",
    designation: "HE400B",
    shape: "I",
    country_code: "European",
    ax: 198,
    ax_global: 19800,
    bf: 300,
    bf_global: 300,
    ct: "3,7",
    ct_global: 37,
    d: 400,
    d_global: 400,
    ix: 356,
    ix_global: 3560000,
    iy: 10820,
    iy_global: 108200000,
    iz: 57680,
    iz_global: 576800000,
    tf: 24,
    tf_global: 24,
    tw: "13,5",
    tw_global: "13,5",
    zx: 3232,
    zx_global: 3232000,
    zy: 1104,
    zy_global: 1104000,
    width: 300,
    width_global: 300,
    thickness: 24,
    thickness_global: 24,
    height: 400,
    height_global: 400,
  },
  {
    profile_section_id: 2518,
    name: "HE500A",
    type: "HE Shape",
    designation: "HE500A",
    shape: "I",
    country_code: "European",
    ax: 198,
    ax_global: 19800,
    bf: 300,
    bf_global: 300,
    ct: "4,5",
    ct_global: 45,
    d: 490,
    d_global: 490,
    ix: 309,
    ix_global: 3090000,
    iy: 10370,
    iy_global: 103700000,
    iz: 86970,
    iz_global: 869700000,
    tf: 23,
    tf_global: 23,
    tw: 12,
    tw_global: 12,
    zx: 3949,
    zx_global: 3949000,
    zy: 1059,
    zy_global: 1059000,
    width: 300,
    width_global: 300,
    thickness: 23,
    thickness_global: 23,
    height: 490,
    height_global: 490,
  },
  {
    profile_section_id: 2519,
    name: "HE240M",
    type: "HE Shape",
    designation: "HE240M",
    shape: "I",
    country_code: "European",
    ax: 200,
    ax_global: 20000,
    bf: 248,
    bf_global: 248,
    ct: "2,9",
    ct_global: 29,
    d: 270,
    d_global: 270,
    ix: 628,
    ix_global: 6280000,
    iy: 8153,
    iy_global: 81530000,
    iz: 24290,
    iz_global: 242900000,
    tf: 32,
    tf_global: 32,
    tw: 18,
    tw_global: 18,
    zx: 2117,
    zx_global: 2117000,
    zy: 1006,
    zy_global: 1006000,
    width: 248,
    width_global: 248,
    thickness: 32,
    thickness_global: 32,
    height: 270,
    height_global: 270,
  },
  {
    profile_section_id: 2520,
    name: "HE550A",
    type: "HE Shape",
    designation: "HE550A",
    shape: "I",
    country_code: "European",
    ax: 212,
    ax_global: 21200,
    bf: 300,
    bf_global: 300,
    ct: "5,2",
    ct_global: 52,
    d: 540,
    d_global: 540,
    ix: 352,
    ix_global: 3520000,
    iy: 10820,
    iy_global: 108200000,
    iz: 111900,
    iz_global: 1119000000,
    tf: 24,
    tf_global: 24,
    tw: "12,5",
    tw_global: "12,5",
    zx: 4622,
    zx_global: 4622000,
    zy: 1107,
    zy_global: 1107000,
    width: 300,
    width_global: 300,
    thickness: 24,
    thickness_global: 24,
    height: 540,
    height_global: 540,
  },
  {
    profile_section_id: 2521,
    name: "HE450B",
    type: "HE Shape",
    designation: "HE450B",
    shape: "I",
    country_code: "European",
    ax: 218,
    ax_global: 21800,
    bf: 300,
    bf_global: 300,
    ct: "4,2",
    ct_global: 42,
    d: 450,
    d_global: 450,
    ix: 440,
    ix_global: 4400000,
    iy: 11720,
    iy_global: 117200000,
    iz: 79890,
    iz_global: 798900000,
    tf: 26,
    tf_global: 26,
    tw: 14,
    tw_global: 14,
    zx: 3982,
    zx_global: 3982000,
    zy: 1198,
    zy_global: 1198000,
    width: 300,
    width_global: 300,
    thickness: 26,
    thickness_global: 26,
    height: 450,
    height_global: 450,
  },
  {
    profile_section_id: 2522,
    name: "HE800AA",
    type: "HE Shape",
    designation: "HE800AA",
    shape: "I",
    country_code: "European",
    ax: 218,
    ax_global: 21800,
    bf: 300,
    bf_global: 300,
    ct: 10,
    ct_global: 100,
    d: 770,
    d_global: 770,
    ix: 257,
    ix_global: 2570000,
    iy: 8134,
    iy_global: 81340000,
    iz: 208900,
    iz_global: 2089000000,
    tf: 18,
    tf_global: 18,
    tw: 14,
    tw_global: 14,
    zx: 6225,
    zx_global: 6225000,
    zy: 857,
    zy_global: 857000,
    width: 300,
    width_global: 300,
    thickness: 18,
    thickness_global: 18,
    height: 770,
    height_global: 770,
  },
  {
    profile_section_id: 2523,
    name: "HE260M",
    type: "HE Shape",
    designation: "HE260M",
    shape: "I",
    country_code: "European",
    ax: 220,
    ax_global: 22000,
    bf: 268,
    bf_global: 268,
    ct: 3,
    ct_global: 30,
    d: 290,
    d_global: 290,
    ix: 719,
    ix_global: 7190000,
    iy: 10450,
    iy_global: 104500000,
    iz: 31310,
    iz_global: 313100000,
    tf: "32,5",
    tf_global: "32,5",
    tw: 18,
    tw_global: 18,
    zx: 2524,
    zx_global: 2524000,
    zy: 1192,
    zy_global: 1192000,
    width: 268,
    width_global: 268,
    thickness: "32,5",
    thickness_global: "32,5",
    height: 290,
    height_global: 290,
  },
  {
    profile_section_id: 2524,
    name: "HE300C",
    type: "HE Shape",
    designation: "HE300C",
    shape: "I",
    country_code: "European",
    ax: 225,
    ax_global: 22500,
    bf: 305,
    bf_global: 305,
    ct: 3,
    ct_global: 30,
    d: 320,
    d_global: 320,
    ix: 598,
    ix_global: 5980000,
    iy: 13740,
    iy_global: 137400000,
    iz: 40950,
    iz_global: 409500000,
    tf: 29,
    tf_global: 29,
    tw: 16,
    tw_global: 16,
    zx: 2927,
    zx_global: 2927000,
    zy: 1374,
    zy_global: 1374000,
    width: 305,
    width_global: 305,
    thickness: 29,
    thickness_global: 29,
    height: 320,
    height_global: 320,
  },
  {
    profile_section_id: 2525,
    name: "HE600A",
    type: "HE Shape",
    designation: "HE600A",
    shape: "I",
    country_code: "European",
    ax: 226,
    ax_global: 22600,
    bf: 300,
    bf_global: 300,
    ct: "5,9",
    ct_global: 59,
    d: 590,
    d_global: 590,
    ix: 398,
    ix_global: 3980000,
    iy: 11270,
    iy_global: 112700000,
    iz: 141200,
    iz_global: 1412000000,
    tf: 25,
    tf_global: 25,
    tw: 13,
    tw_global: 13,
    zx: 5350,
    zx_global: 5350000,
    zy: 1156,
    zy_global: 1156000,
    width: 300,
    width_global: 300,
    thickness: 25,
    thickness_global: 25,
    height: 590,
    height_global: 590,
  },
  {
    profile_section_id: 2526,
    name: "HE500B",
    type: "HE Shape",
    designation: "HE500B",
    shape: "I",
    country_code: "European",
    ax: 239,
    ax_global: 23900,
    bf: 300,
    bf_global: 300,
    ct: "4,8",
    ct_global: 48,
    d: 500,
    d_global: 500,
    ix: 538,
    ix_global: 5380000,
    iy: 12620,
    iy_global: 126200000,
    iz: 107200,
    iz_global: 1072000000,
    tf: 28,
    tf_global: 28,
    tw: "14,5",
    tw_global: "14,5",
    zx: 4815,
    zx_global: 4815000,
    zy: 1292,
    zy_global: 1292000,
    width: 300,
    width_global: 300,
    thickness: 28,
    thickness_global: 28,
    height: 500,
    height_global: 500,
  },
  {
    profile_section_id: 2527,
    name: "HE280M",
    type: "HE Shape",
    designation: "HE280M",
    shape: "I",
    country_code: "European",
    ax: 240,
    ax_global: 24000,
    bf: 288,
    bf_global: 288,
    ct: "3,2",
    ct_global: 32,
    d: 310,
    d_global: 310,
    ix: 807,
    ix_global: 8070000,
    iy: 13160,
    iy_global: 131600000,
    iz: 39550,
    iz_global: 395500000,
    tf: 33,
    tf_global: 33,
    tw: "18,5",
    tw_global: "18,5",
    zx: 2966,
    zx_global: 2966000,
    zy: 1397,
    zy_global: 1397000,
    width: 288,
    width_global: 288,
    thickness: 33,
    thickness_global: 33,
    height: 310,
    height_global: 310,
  },
  {
    profile_section_id: 2528,
    name: "HE650A",
    type: "HE Shape",
    designation: "HE650A",
    shape: "I",
    country_code: "European",
    ax: 242,
    ax_global: 24200,
    bf: 300,
    bf_global: 300,
    ct: "6,6",
    ct_global: 66,
    d: 640,
    d_global: 640,
    ix: 448,
    ix_global: 4480000,
    iy: 11720,
    iy_global: 117200000,
    iz: 175200,
    iz_global: 1752000000,
    tf: 26,
    tf_global: 26,
    tw: "13,5",
    tw_global: "13,5",
    zx: 6136,
    zx_global: 6136000,
    zy: 1205,
    zy_global: 1205000,
    width: 300,
    width_global: 300,
    thickness: 26,
    thickness_global: 26,
    height: 640,
    height_global: 640,
  },
  {
    profile_section_id: 2529,
    name: "HE900AA",
    type: "HE Shape",
    designation: "HE900AA",
    shape: "I",
    country_code: "European",
    ax: 252,
    ax_global: 25200,
    bf: 300,
    bf_global: 300,
    ct: "11,8",
    ct_global: 118,
    d: 870,
    d_global: 870,
    ix: 335,
    ix_global: 3350000,
    iy: 9041,
    iy_global: 90410000,
    iz: 301100,
    iz_global: 3011000000,
    tf: 20,
    tf_global: 20,
    tw: 15,
    tw_global: 15,
    zx: 7999,
    zx_global: 7999000,
    zy: 958,
    zy_global: 958000,
    width: 300,
    width_global: 300,
    thickness: 20,
    thickness_global: 20,
    height: 870,
    height_global: 870,
  },
  {
    profile_section_id: 2530,
    name: "HE550B",
    type: "HE Shape",
    designation: "HE550B",
    shape: "I",
    country_code: "European",
    ax: 254,
    ax_global: 25400,
    bf: 300,
    bf_global: 300,
    ct: "5,5",
    ct_global: 55,
    d: 550,
    d_global: 550,
    ix: 600,
    ix_global: 6000000,
    iy: 13080,
    iy_global: 130800000,
    iz: 136700,
    iz_global: 1367000000,
    tf: 29,
    tf_global: 29,
    tw: 15,
    tw_global: 15,
    zx: 5591,
    zx_global: 5591000,
    zy: 1341,
    zy_global: 1341000,
    width: 300,
    width_global: 300,
    thickness: 29,
    thickness_global: 29,
    height: 550,
    height_global: 550,
  },
  {
    profile_section_id: 2531,
    name: "HE700A",
    type: "HE Shape",
    designation: "HE700A",
    shape: "I",
    country_code: "European",
    ax: 260,
    ax_global: 26000,
    bf: 300,
    bf_global: 300,
    ct: "7,5",
    ct_global: 75,
    d: 690,
    d_global: 690,
    ix: 514,
    ix_global: 5140000,
    iy: 12180,
    iy_global: 121800000,
    iz: 215300,
    iz_global: 2153000000,
    tf: 27,
    tf_global: 27,
    tw: "14,5",
    tw_global: "14,5",
    zx: 7032,
    zx_global: 7032000,
    zy: 1257,
    zy_global: 1257000,
    width: 300,
    width_global: 300,
    thickness: 27,
    thickness_global: 27,
    height: 690,
    height_global: 690,
  },
  {
    profile_section_id: 2532,
    name: "HE600B",
    type: "HE Shape",
    designation: "HE600B",
    shape: "I",
    country_code: "European",
    ax: 270,
    ax_global: 27000,
    bf: 300,
    bf_global: 300,
    ct: "6,2",
    ct_global: 62,
    d: 600,
    d_global: 600,
    ix: 667,
    ix_global: 6670000,
    iy: 13530,
    iy_global: 135300000,
    iz: 171000,
    iz_global: 1710000000,
    tf: 30,
    tf_global: 30,
    tw: "15,5",
    tw_global: "15,5",
    zx: 6425,
    zx_global: 6425000,
    zy: 1391,
    zy_global: 1391000,
    width: 300,
    width_global: 300,
    thickness: 30,
    thickness_global: 30,
    height: 600,
    height_global: 600,
  },
  {
    profile_section_id: 2533,
    name: "HE1000AA",
    type: "HE Shape",
    designation: "HE1000AA",
    shape: "I",
    country_code: "European",
    ax: 282,
    ax_global: 28200,
    bf: 300,
    bf_global: 300,
    ct: "13,9",
    ct_global: 139,
    d: 970,
    d_global: 970,
    ix: 403,
    ix_global: 4030000,
    iy: 9501,
    iy_global: 95010000,
    iz: 406500,
    iz_global: 4065000000,
    tf: 21,
    tf_global: 21,
    tw: 16,
    tw_global: 16,
    zx: 9777,
    zx_global: 9777000,
    zy: 1016,
    zy_global: 1016000,
    width: 300,
    width_global: 300,
    thickness: 21,
    thickness_global: 21,
    height: 970,
    height_global: 970,
  },
  {
    profile_section_id: 2534,
    name: "HE650B",
    type: "HE Shape",
    designation: "HE650B",
    shape: "I",
    country_code: "European",
    ax: 286,
    ax_global: 28600,
    bf: 300,
    bf_global: 300,
    ct: "6,9",
    ct_global: 69,
    d: 650,
    d_global: 650,
    ix: 739,
    ix_global: 7390000,
    iy: 13980,
    iy_global: 139800000,
    iz: 210600,
    iz_global: 2106000000,
    tf: 31,
    tf_global: 31,
    tw: 16,
    tw_global: 16,
    zx: 7320,
    zx_global: 7320000,
    zy: 1441,
    zy_global: 1441000,
    width: 300,
    width_global: 300,
    thickness: 31,
    thickness_global: 31,
    height: 650,
    height_global: 650,
  },
  {
    profile_section_id: 2535,
    name: "HE800A",
    type: "HE Shape",
    designation: "HE800A",
    shape: "I",
    country_code: "European",
    ax: 286,
    ax_global: 28600,
    bf: 300,
    bf_global: 300,
    ct: "9,1",
    ct_global: 91,
    d: 790,
    d_global: 790,
    ix: 597,
    ix_global: 5970000,
    iy: 12640,
    iy_global: 126400000,
    iz: 303400,
    iz_global: 3034000000,
    tf: 28,
    tf_global: 28,
    tw: 15,
    tw_global: 15,
    zx: 8699,
    zx_global: 8699000,
    zy: 1312,
    zy_global: 1312000,
    width: 300,
    width_global: 300,
    thickness: 28,
    thickness_global: 28,
    height: 790,
    height_global: 790,
  },
  {
    profile_section_id: 2536,
    name: "HE300M",
    type: "HE Shape",
    designation: "HE300M",
    shape: "I",
    country_code: "European",
    ax: 303,
    ax_global: 30300,
    bf: 310,
    bf_global: 310,
    ct: "3,6",
    ct_global: 36,
    d: 340,
    d_global: 340,
    ix: 1408,
    ix_global: 14080000,
    iy: 19400,
    iy_global: 194000000,
    iz: 59200,
    iz_global: 592000000,
    tf: 39,
    tf_global: 39,
    tw: 21,
    tw_global: 21,
    zx: 4078,
    zx_global: 4078000,
    zy: 1913,
    zy_global: 1913000,
    width: 310,
    width_global: 310,
    thickness: 39,
    thickness_global: 39,
    height: 340,
    height_global: 340,
  },
  {
    profile_section_id: 2537,
    name: "HE700B",
    type: "HE Shape",
    designation: "HE700B",
    shape: "I",
    country_code: "European",
    ax: 306,
    ax_global: 30600,
    bf: 300,
    bf_global: 300,
    ct: "7,8",
    ct_global: 78,
    d: 700,
    d_global: 700,
    ix: 831,
    ix_global: 8310000,
    iy: 14440,
    iy_global: 144400000,
    iz: 256900,
    iz_global: 2569000000,
    tf: 32,
    tf_global: 32,
    tw: 17,
    tw_global: 17,
    zx: 8327,
    zx_global: 8327000,
    zy: 1495,
    zy_global: 1495000,
    width: 300,
    width_global: 300,
    thickness: 32,
    thickness_global: 32,
    height: 700,
    height_global: 700,
  },
  {
    profile_section_id: 2538,
    name: "HE320M",
    type: "HE Shape",
    designation: "HE320M",
    shape: "I",
    country_code: "European",
    ax: 312,
    ax_global: 31200,
    bf: 309,
    bf_global: 309,
    ct: "3,7",
    ct_global: 37,
    d: 359,
    d_global: 359,
    ix: 1501,
    ix_global: 15010000,
    iy: 19710,
    iy_global: 197100000,
    iz: 68130,
    iz_global: 681300000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 4435,
    zx_global: 4435000,
    zy: 1951,
    zy_global: 1951000,
    width: 309,
    width_global: 309,
    thickness: 40,
    thickness_global: 40,
    height: 359,
    height_global: 359,
  },
  {
    profile_section_id: 2539,
    name: "HE340M",
    type: "HE Shape",
    designation: "HE340M",
    shape: "I",
    country_code: "European",
    ax: 316,
    ax_global: 31600,
    bf: 309,
    bf_global: 309,
    ct: "3,9",
    ct_global: 39,
    d: 377,
    d_global: 377,
    ix: 1506,
    ix_global: 15060000,
    iy: 19710,
    iy_global: 197100000,
    iz: 76370,
    iz_global: 763700000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 4718,
    zx_global: 4718000,
    zy: 1953,
    zy_global: 1953000,
    width: 309,
    width_global: 309,
    thickness: 40,
    thickness_global: 40,
    height: 377,
    height_global: 377,
  },
  {
    profile_section_id: 2540,
    name: "HE360M",
    type: "HE Shape",
    designation: "HE360M",
    shape: "I",
    country_code: "European",
    ax: 319,
    ax_global: 31900,
    bf: 308,
    bf_global: 308,
    ct: "4,1",
    ct_global: 41,
    d: 395,
    d_global: 395,
    ix: 1507,
    ix_global: 15070000,
    iy: 19520,
    iy_global: 195200000,
    iz: 84870,
    iz_global: 848700000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 4989,
    zx_global: 4989000,
    zy: 1942,
    zy_global: 1942000,
    width: 308,
    width_global: 308,
    thickness: 40,
    thickness_global: 40,
    height: 395,
    height_global: 395,
  },
  {
    profile_section_id: 2541,
    name: "HE900A",
    type: "HE Shape",
    designation: "HE900A",
    shape: "I",
    country_code: "European",
    ax: 321,
    ax_global: 32100,
    bf: 300,
    bf_global: 300,
    ct: "10,8",
    ct_global: 108,
    d: 890,
    d_global: 890,
    ix: 737,
    ix_global: 7370000,
    iy: 13550,
    iy_global: 135500000,
    iz: 422100,
    iz_global: 4221000000,
    tf: 30,
    tf_global: 30,
    tw: 16,
    tw_global: 16,
    zx: 10810,
    zx_global: 10810000,
    zy: 1414,
    zy_global: 1414000,
    width: 300,
    width_global: 300,
    thickness: 30,
    thickness_global: 30,
    height: 890,
    height_global: 890,
  },
  {
    profile_section_id: 2542,
    name: "HE400M",
    type: "HE Shape",
    designation: "HE400M",
    shape: "I",
    country_code: "European",
    ax: 326,
    ax_global: 32600,
    bf: 307,
    bf_global: 307,
    ct: "4,5",
    ct_global: 45,
    d: 432,
    d_global: 432,
    ix: 1515,
    ix_global: 15150000,
    iy: 19340,
    iy_global: 193400000,
    iz: 104100,
    iz_global: 1041000000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 5571,
    zx_global: 5571000,
    zy: 1934,
    zy_global: 1934000,
    width: 307,
    width_global: 307,
    thickness: 40,
    thickness_global: 40,
    height: 432,
    height_global: 432,
  },
  {
    profile_section_id: 2543,
    name: "HE800B",
    type: "HE Shape",
    designation: "HE800B",
    shape: "I",
    country_code: "European",
    ax: 334,
    ax_global: 33400,
    bf: 300,
    bf_global: 300,
    ct: "9,4",
    ct_global: 94,
    d: 800,
    d_global: 800,
    ix: 946,
    ix_global: 9460000,
    iy: 14900,
    iy_global: 149000000,
    iz: 359100,
    iz_global: 3591000000,
    tf: 33,
    tf_global: 33,
    tw: "17,5",
    tw_global: "17,5",
    zx: 10230,
    zx_global: 10230000,
    zy: 1553,
    zy_global: 1553000,
    width: 300,
    width_global: 300,
    thickness: 33,
    thickness_global: 33,
    height: 800,
    height_global: 800,
  },
  {
    profile_section_id: 2544,
    name: "HE450M",
    type: "HE Shape",
    designation: "HE450M",
    shape: "I",
    country_code: "European",
    ax: 335,
    ax_global: 33500,
    bf: 307,
    bf_global: 307,
    ct: 5,
    ct_global: 50,
    d: 478,
    d_global: 478,
    ix: 1529,
    ix_global: 15290000,
    iy: 19340,
    iy_global: 193400000,
    iz: 131500,
    iz_global: 1315000000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 6331,
    zx_global: 6331000,
    zy: 1939,
    zy_global: 1939000,
    width: 307,
    width_global: 307,
    thickness: 40,
    thickness_global: 40,
    height: 478,
    height_global: 478,
  },
  {
    profile_section_id: 2545,
    name: "HE500M",
    type: "HE Shape",
    designation: "HE500M",
    shape: "I",
    country_code: "European",
    ax: 344,
    ax_global: 34400,
    bf: 306,
    bf_global: 306,
    ct: "5,6",
    ct_global: 56,
    d: 524,
    d_global: 524,
    ix: 1539,
    ix_global: 15390000,
    iy: 19150,
    iy_global: 191500000,
    iz: 161900,
    iz_global: 1619000000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 7094,
    zx_global: 7094000,
    zy: 1932,
    zy_global: 1932000,
    width: 306,
    width_global: 306,
    thickness: 40,
    thickness_global: 40,
    height: 524,
    height_global: 524,
  },
  {
    profile_section_id: 2546,
    name: "HE1000A",
    type: "HE Shape",
    designation: "HE1000A",
    shape: "I",
    country_code: "European",
    ax: 347,
    ax_global: 34700,
    bf: 300,
    bf_global: 300,
    ct: "12,5",
    ct_global: 125,
    d: 990,
    d_global: 990,
    ix: 822,
    ix_global: 8220000,
    iy: 14000,
    iy_global: 140000000,
    iz: 553800,
    iz_global: 5538000000,
    tf: 31,
    tf_global: 31,
    tw: "16,5",
    tw_global: "16,5",
    zx: 12820,
    zx_global: 12820000,
    zy: 1470,
    zy_global: 1470000,
    width: 300,
    width_global: 300,
    thickness: 31,
    thickness_global: 31,
    height: 990,
    height_global: 990,
  },
  {
    profile_section_id: 2547,
    name: "HE550M",
    type: "HE Shape",
    designation: "HE550M",
    shape: "I",
    country_code: "European",
    ax: 354,
    ax_global: 35400,
    bf: 306,
    bf_global: 306,
    ct: "6,2",
    ct_global: 62,
    d: 572,
    d_global: 572,
    ix: 1554,
    ix_global: 15540000,
    iy: 19160,
    iy_global: 191600000,
    iz: 198000,
    iz_global: 1980000000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 7933,
    zx_global: 7933000,
    zy: 1937,
    zy_global: 1937000,
    width: 306,
    width_global: 306,
    thickness: 40,
    thickness_global: 40,
    height: 572,
    height_global: 572,
  },
  {
    profile_section_id: 2548,
    name: "HE600M",
    type: "HE Shape",
    designation: "HE600M",
    shape: "I",
    country_code: "European",
    ax: 364,
    ax_global: 36400,
    bf: 305,
    bf_global: 305,
    ct: "6,9",
    ct_global: 69,
    d: 620,
    d_global: 620,
    ix: 1564,
    ix_global: 15640000,
    iy: 18980,
    iy_global: 189800000,
    iz: 237400,
    iz_global: 2374000000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 8772,
    zx_global: 8772000,
    zy: 1930,
    zy_global: 1930000,
    width: 305,
    width_global: 305,
    thickness: 40,
    thickness_global: 40,
    height: 620,
    height_global: 620,
  },
  {
    profile_section_id: 2549,
    name: "HE900B",
    type: "HE Shape",
    designation: "HE900B",
    shape: "I",
    country_code: "European",
    ax: 371,
    ax_global: 37100,
    bf: 300,
    bf_global: 300,
    ct: "11,1",
    ct_global: 111,
    d: 900,
    d_global: 900,
    ix: 1137,
    ix_global: 11370000,
    iy: 15820,
    iy_global: 158200000,
    iz: 494100,
    iz_global: 4941000000,
    tf: 35,
    tf_global: 35,
    tw: "18,5",
    tw_global: "18,5",
    zx: 12580,
    zx_global: 12580000,
    zy: 1658,
    zy_global: 1658000,
    width: 300,
    width_global: 300,
    thickness: 35,
    thickness_global: 35,
    height: 900,
    height_global: 900,
  },
  {
    profile_section_id: 2550,
    name: "HE650M",
    type: "HE Shape",
    designation: "HE650M",
    shape: "I",
    country_code: "European",
    ax: 374,
    ax_global: 37400,
    bf: 305,
    bf_global: 305,
    ct: "7,6",
    ct_global: 76,
    d: 668,
    d_global: 668,
    ix: 1579,
    ix_global: 15790000,
    iy: 18980,
    iy_global: 189800000,
    iz: 281700,
    iz_global: 2817000000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 9657,
    zx_global: 9657000,
    zy: 1936,
    zy_global: 1936000,
    width: 305,
    width_global: 305,
    thickness: 40,
    thickness_global: 40,
    height: 668,
    height_global: 668,
  },
  {
    profile_section_id: 2551,
    name: "HE700M",
    type: "HE Shape",
    designation: "HE700M",
    shape: "I",
    country_code: "European",
    ax: 383,
    ax_global: 38300,
    bf: 304,
    bf_global: 304,
    ct: "8,3",
    ct_global: 83,
    d: 716,
    d_global: 716,
    ix: 1589,
    ix_global: 15890000,
    iy: 18800,
    iy_global: 188000000,
    iz: 329300,
    iz_global: 3293000000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 10540,
    zx_global: 10540000,
    zy: 1929,
    zy_global: 1929000,
    width: 304,
    width_global: 304,
    thickness: 40,
    thickness_global: 40,
    height: 716,
    height_global: 716,
  },
  {
    profile_section_id: 2552,
    name: "HE1000B",
    type: "HE Shape",
    designation: "HE1000B",
    shape: "I",
    country_code: "European",
    ax: 400,
    ax_global: 40000,
    bf: 300,
    bf_global: 300,
    ct: "12,9",
    ct_global: 129,
    d: 1000,
    d_global: 1000,
    ix: 1254,
    ix_global: 12540000,
    iy: 16280,
    iy_global: 162800000,
    iz: 644700,
    iz_global: 6447000000,
    tf: 36,
    tf_global: 36,
    tw: 19,
    tw_global: 19,
    zx: 14860,
    zx_global: 14860000,
    zy: 1716,
    zy_global: 1716000,
    width: 300,
    width_global: 300,
    thickness: 36,
    thickness_global: 36,
    height: 1000,
    height_global: 1000,
  },
  {
    profile_section_id: 2553,
    name: "HE800M",
    type: "HE Shape",
    designation: "HE800M",
    shape: "I",
    country_code: "European",
    ax: 404,
    ax_global: 40400,
    bf: 303,
    bf_global: 303,
    ct: "9,8",
    ct_global: 98,
    d: 814,
    d_global: 814,
    ix: 1646,
    ix_global: 16460000,
    iy: 18630,
    iy_global: 186300000,
    iz: 442600,
    iz_global: 4426000000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 12490,
    zx_global: 12490000,
    zy: 1930,
    zy_global: 1930000,
    width: 303,
    width_global: 303,
    thickness: 40,
    thickness_global: 40,
    height: 814,
    height_global: 814,
  },
  {
    profile_section_id: 2554,
    name: "HE900M",
    type: "HE Shape",
    designation: "HE900M",
    shape: "I",
    country_code: "European",
    ax: 424,
    ax_global: 42400,
    bf: 302,
    bf_global: 302,
    ct: "11,4",
    ct_global: 114,
    d: 910,
    d_global: 910,
    ix: 1671,
    ix_global: 16710000,
    iy: 18450,
    iy_global: 184500000,
    iz: 570400,
    iz_global: 5704000000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 14440,
    zx_global: 14440000,
    zy: 1929,
    zy_global: 1929000,
    width: 302,
    width_global: 302,
    thickness: 40,
    thickness_global: 40,
    height: 910,
    height_global: 910,
  },
  {
    profile_section_id: 2555,
    name: "HE1000M",
    type: "HE Shape",
    designation: "HE1000M",
    shape: "I",
    country_code: "European",
    ax: 444,
    ax_global: 44400,
    bf: 302,
    bf_global: 302,
    ct: "13,1",
    ct_global: 131,
    d: 1008,
    d_global: 1008,
    ix: 1701,
    ix_global: 17010000,
    iy: 18460,
    iy_global: 184600000,
    iz: 722300,
    iz_global: 7223000000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 16570,
    zx_global: 16570000,
    zy: 1940,
    zy_global: 1940000,
    width: 302,
    width_global: 302,
    thickness: 40,
    thickness_global: 40,
    height: 1008,
    height_global: 1008,
  },
  {
    profile_section_id: 2556,
    name: "HE600x337",
    type: "HE Shape",
    designation: "HE600x337",
    shape: "I",
    country_code: "European",
    ax: "429,2",
    ax_global: 42920,
    bf: 310,
    bf_global: 310,
    ct: "7,4",
    ct_global: 74,
    d: 632,
    d_global: 632,
    ix: 2451,
    ix_global: 24510000,
    iy: 22940,
    iy_global: 229400000,
    iz: 283200,
    iz_global: 2832000000,
    tf: 46,
    tf_global: 46,
    tw: "25,5",
    tw_global: "25,5",
    zx: 10380,
    zx_global: 10380000,
    zy: 2310,
    zy_global: 2310000,
    width: 310,
    width_global: 310,
    thickness: 46,
    thickness_global: 46,
    height: 632,
    height_global: 632,
  },
  {
    profile_section_id: 2557,
    name: "HE600x399",
    type: "HE Shape",
    designation: "HE600x399",
    shape: "I",
    country_code: "European",
    ax: "508,5",
    ax_global: 50850,
    bf: 315,
    bf_global: 315,
    ct: "7,9",
    ct_global: 79,
    d: 648,
    d_global: 648,
    ix: 3966,
    ix_global: 39660000,
    iy: 28280,
    iy_global: 282800000,
    iz: 344600,
    iz_global: 3446000000,
    tf: 54,
    tf_global: 54,
    tw: 30,
    tw_global: 30,
    zx: 12460,
    zx_global: 12460000,
    zy: 2814,
    zy_global: 2814000,
    width: 315,
    width_global: 315,
    thickness: 54,
    thickness_global: 54,
    height: 648,
    height_global: 648,
  },
  {
    profile_section_id: 2558,
    name: "HE650x343",
    type: "HE Shape",
    designation: "HE650x343",
    shape: "I",
    country_code: "European",
    ax: "437,5",
    ax_global: 43750,
    bf: 309,
    bf_global: 309,
    ct: "8,1",
    ct_global: 81,
    d: 680,
    d_global: 680,
    ix: 2442,
    ix_global: 24420000,
    iy: 22720,
    iy_global: 227200000,
    iz: 333700,
    iz_global: 3337000000,
    tf: 46,
    tf_global: 46,
    tw: 25,
    tw_global: 25,
    zx: 11350,
    zx_global: 11350000,
    zy: 2300,
    zy_global: 2300000,
    width: 309,
    width_global: 309,
    thickness: 46,
    thickness_global: 46,
    height: 680,
    height_global: 680,
  },
  {
    profile_section_id: 2559,
    name: "HE650x407",
    type: "HE Shape",
    designation: "HE650x407",
    shape: "I",
    country_code: "European",
    ax: "518,8",
    ax_global: 51880,
    bf: 314,
    bf_global: 314,
    ct: "8,6",
    ct_global: 86,
    d: 696,
    d_global: 696,
    ix: 3958,
    ix_global: 39580000,
    iy: 28020,
    iy_global: 280200000,
    iz: 405400,
    iz_global: 4054000000,
    tf: 54,
    tf_global: 54,
    tw: "29,5",
    tw_global: "29,5",
    zx: 13620,
    zx_global: 13620000,
    zy: 2803,
    zy_global: 2803000,
    width: 314,
    width_global: 314,
    thickness: 54,
    thickness_global: 54,
    height: 696,
    height_global: 696,
  },
  {
    profile_section_id: 2560,
    name: "HE700x352",
    type: "HE Shape",
    designation: "HE700x352",
    shape: "I",
    country_code: "European",
    ax: "448,6",
    ax_global: 44860,
    bf: 308,
    bf_global: 308,
    ct: "8,8",
    ct_global: 88,
    d: 728,
    d_global: 728,
    ix: 2461,
    ix_global: 24610000,
    iy: 22510,
    iy_global: 225100000,
    iz: 389700,
    iz_global: 3897000000,
    tf: 46,
    tf_global: 46,
    tw: 25,
    tw_global: 25,
    zx: 12390,
    zx_global: 12390000,
    zy: 2293,
    zy_global: 2293000,
    width: 308,
    width_global: 308,
    thickness: 46,
    thickness_global: 46,
    height: 728,
    height_global: 728,
  },
  {
    profile_section_id: 2561,
    name: "HE700x418",
    type: "HE Shape",
    designation: "HE700x418",
    shape: "I",
    country_code: "European",
    ax: "531,9",
    ax_global: 53190,
    bf: 313,
    bf_global: 313,
    ct: "9,3",
    ct_global: 93,
    d: 744,
    d_global: 744,
    ix: 3989,
    ix_global: 39890000,
    iy: 27760,
    iy_global: 277600000,
    iz: 472500,
    iz_global: 4725000000,
    tf: 54,
    tf_global: 54,
    tw: "29,5",
    tw_global: "29,5",
    zx: 14840,
    zx_global: 14840000,
    zy: 2797,
    zy_global: 2797000,
    width: 313,
    width_global: 313,
    thickness: 54,
    thickness_global: 54,
    height: 744,
    height_global: 744,
  },
  {
    profile_section_id: 2562,
    name: "HE800x373",
    type: "HE Shape",
    designation: "HE800x373",
    shape: "I",
    country_code: "European",
    ax: "474,6",
    ax_global: 47460,
    bf: 308,
    bf_global: 308,
    ct: "10,4",
    ct_global: 104,
    d: 826,
    d_global: 826,
    ix: 2554,
    ix_global: 25540000,
    iy: 22530,
    iy_global: 225300000,
    iz: 523900,
    iz_global: 5239000000,
    tf: 46,
    tf_global: 46,
    tw: 25,
    tw_global: 25,
    zx: 14700,
    zx_global: 14700000,
    zy: 2311,
    zy_global: 2311000,
    width: 308,
    width_global: 308,
    thickness: 46,
    thickness_global: 46,
    height: 826,
    height_global: 826,
  },
  {
    profile_section_id: 2563,
    name: "HE800x444",
    type: "HE Shape",
    designation: "HE800x444",
    shape: "I",
    country_code: "European",
    ax: 566,
    ax_global: 56600,
    bf: 313,
    bf_global: 313,
    ct: 11,
    ct_global: 110,
    d: 842,
    d_global: 842,
    ix: 4180,
    ix_global: 41800000,
    iy: 27800,
    iy_global: 278000000,
    iz: 634500,
    iz_global: 6345000000,
    tf: 54,
    tf_global: 54,
    tw: 30,
    tw_global: 30,
    zx: 17640,
    zx_global: 17640000,
    zy: 2827,
    zy_global: 2827000,
    width: 313,
    width_global: 313,
    thickness: 54,
    thickness_global: 54,
    height: 842,
    height_global: 842,
  },
  {
    profile_section_id: 2564,
    name: "HE900x391",
    type: "HE Shape",
    designation: "HE900x391",
    shape: "I",
    country_code: "European",
    ax: "497,7",
    ax_global: 49770,
    bf: 307,
    bf_global: 307,
    ct: "12,1",
    ct_global: 121,
    d: 922,
    d_global: 922,
    ix: 2597,
    ix_global: 25970000,
    iy: 22320,
    iy_global: 223200000,
    iz: 674300,
    iz_global: 6743000000,
    tf: 46,
    tf_global: 46,
    tw: 25,
    tw_global: 25,
    zx: 16990,
    zx_global: 16990000,
    zy: 2312,
    zy_global: 2312000,
    width: 307,
    width_global: 307,
    thickness: 46,
    thickness_global: 46,
    height: 922,
    height_global: 922,
  },
  {
    profile_section_id: 2565,
    name: "HE900x466",
    type: "HE Shape",
    designation: "HE900x466",
    shape: "I",
    country_code: "European",
    ax: "593,7",
    ax_global: 59370,
    bf: 312,
    bf_global: 312,
    ct: "12,7",
    ct_global: 127,
    d: 938,
    d_global: 938,
    ix: 4256,
    ix_global: 42560000,
    iy: 27560,
    iy_global: 275600000,
    iz: 814900,
    iz_global: 8149000000,
    tf: 54,
    tf_global: 54,
    tw: 30,
    tw_global: 30,
    zx: 20380,
    zx_global: 20380000,
    zy: 2832,
    zy_global: 2832000,
    width: 312,
    width_global: 312,
    thickness: 54,
    thickness_global: 54,
    height: 938,
    height_global: 938,
  },
  {
    profile_section_id: 2566,
    name: "HE1000x249",
    type: "HE Shape",
    designation: "HE1000x249",
    shape: "I",
    country_code: "European",
    ax: "316,8",
    ax_global: 31680,
    bf: 300,
    bf_global: 300,
    ct: "13,4",
    ct_global: 134,
    d: 980,
    d_global: 980,
    ix: "584,4",
    ix_global: 5844000,
    iy: 11750,
    iy_global: 117500000,
    iz: 481100,
    iz_global: 4811000000,
    tf: 26,
    tf_global: 26,
    tw: "16,5",
    tw_global: "16,5",
    zx: 11350,
    zx_global: 11350000,
    zy: 1245,
    zy_global: 1245000,
    width: 300,
    width_global: 300,
    thickness: 26,
    thickness_global: 26,
    height: 980,
    height_global: 980,
  },
  {
    profile_section_id: 2567,
    name: "HE1000x393",
    type: "HE Shape",
    designation: "HE1000x393",
    shape: "I",
    country_code: "European",
    ax: "500,2",
    ax_global: 50020,
    bf: 303,
    bf_global: 303,
    ct: "13,9",
    ct_global: 139,
    d: 1016,
    d_global: 1016,
    ix: 2332,
    ix_global: 23320000,
    iy: 20500,
    iy_global: 205000000,
    iz: 807700,
    iz_global: 8077000000,
    tf: "43,9",
    tf_global: "43,9",
    tw: "24,4",
    tw_global: "24,4",
    zx: 18540,
    zx_global: 18540000,
    zy: 2168,
    zy_global: 2168000,
    width: 303,
    width_global: 303,
    thickness: "43,9",
    thickness_global: "43,9",
    height: 1016,
    height_global: 1016,
  },
  {
    profile_section_id: 2568,
    name: "HE1000x415",
    type: "HE Shape",
    designation: "HE1000x415",
    shape: "I",
    country_code: "European",
    ax: "528,7",
    ax_global: 52870,
    bf: 304,
    bf_global: 304,
    ct: "14,1",
    ct_global: 141,
    d: 1020,
    d_global: 1020,
    ix: 2713,
    ix_global: 27130000,
    iy: 21710,
    iy_global: 217100000,
    iz: 853100,
    iz_global: 8531000000,
    tf: 46,
    tf_global: 46,
    tw: 26,
    tw_global: 26,
    zx: 19571,
    zx_global: 19571000,
    zy: 2298,
    zy_global: 2298000,
    width: 304,
    width_global: 304,
    thickness: 46,
    thickness_global: 46,
    height: 1020,
    height_global: 1020,
  },
  {
    profile_section_id: 2569,
    name: "HE1000x438",
    type: "HE Shape",
    designation: "HE1000x438",
    shape: "I",
    country_code: "European",
    ax: "557,2",
    ax_global: 55720,
    bf: 305,
    bf_global: 305,
    ct: "14,1",
    ct_global: 141,
    d: 1026,
    d_global: 1026,
    ix: 3200,
    ix_global: 32000000,
    iy: 23360,
    iy_global: 233600000,
    iz: 909800,
    iz_global: 9098000000,
    tf: 49,
    tf_global: 49,
    tw: "26,9",
    tw_global: "26,9",
    zx: 20770,
    zx_global: 20770000,
    zy: 2464,
    zy_global: 2464000,
    width: 305,
    width_global: 305,
    thickness: 49,
    thickness_global: 49,
    height: 1026,
    height_global: 1026,
  },
  {
    profile_section_id: 2570,
    name: "HE1000x494",
    type: "HE Shape",
    designation: "HE1000x494",
    shape: "I",
    country_code: "European",
    ax: "629,1",
    ax_global: 62910,
    bf: 309,
    bf_global: 309,
    ct: "14,7",
    ct_global: 147,
    d: 1036,
    d_global: 1036,
    ix: 4433,
    ix_global: 44330000,
    iy: 26820,
    iy_global: 268200000,
    iz: 1028000,
    iz_global: 10280000000,
    tf: 54,
    tf_global: 54,
    tw: 31,
    tw_global: 31,
    zx: 23413,
    zx_global: 23413000,
    zy: 2818,
    zy_global: 2818000,
    width: 309,
    width_global: 309,
    thickness: 54,
    thickness_global: 54,
    height: 1036,
    height_global: 1036,
  },
  {
    profile_section_id: 2571,
    name: "HE1000x584",
    type: "HE Shape",
    designation: "HE1000x584",
    shape: "I",
    country_code: "European",
    ax: "743,7",
    ax_global: 74370,
    bf: 314,
    bf_global: 314,
    ct: "15,2",
    ct_global: 152,
    d: 1056,
    d_global: 1056,
    ix: 7230,
    ix_global: 72300000,
    iy: 33430,
    iy_global: 334300000,
    iz: 1246100,
    iz_global: 12461000000,
    tf: 64,
    tf_global: 64,
    tw: 36,
    tw_global: 36,
    zx: 28039,
    zx_global: 28039000,
    zy: 3475,
    zy_global: 3475000,
    width: 314,
    width_global: 314,
    thickness: 64,
    thickness_global: 64,
    height: 1056,
    height_global: 1056,
  },
  {
    profile_section_id: 2572,
    name: "HP200x43",
    type: "HP Shape",
    designation: "HP200x43",
    shape: "I",
    country_code: "European",
    ax: "54,1",
    ax_global: 5410,
    bf: 205,
    bf_global: 205,
    ct: "1,99",
    ct_global: "19,9",
    d: 200,
    d_global: 200,
    ix: "17,68",
    ix_global: 176800,
    iy: 1294,
    iy_global: 12940000,
    iz: 3888,
    iz_global: 38880000,
    tf: 9,
    tf_global: 9,
    tw: 9,
    tw_global: 9,
    zx: "434,5",
    zx_global: 434500,
    zy: "193,4",
    zy_global: 193400,
    width: 205,
    width_global: 205,
    thickness: 9,
    thickness_global: 9,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2573,
    name: "HP200x53",
    type: "HP Shape",
    designation: "HP200x53",
    shape: "I",
    country_code: "European",
    ax: "68,4",
    ax_global: 6840,
    bf: 207,
    bf_global: 207,
    ct: "2,12",
    ct_global: "21,2",
    d: 204,
    d_global: 204,
    ix: "34,2",
    ix_global: 342000,
    iy: 1673,
    iy_global: 16730000,
    iz: 4977,
    iz_global: 49770000,
    tf: "11,3",
    tf_global: "11,3",
    tw: "11,3",
    tw_global: "11,3",
    zx: "551,3",
    zx_global: 551300,
    zy: "248,6",
    zy_global: 248600,
    width: 207,
    width_global: 207,
    thickness: "11,3",
    thickness_global: "11,3",
    height: 204,
    height_global: 204,
  },
  {
    profile_section_id: 2574,
    name: "HP220x57",
    type: "HP Shape",
    designation: "HP220x57",
    shape: "I",
    country_code: "European",
    ax: "72,9",
    ax_global: 7290,
    bf: "224,5",
    bf_global: "224,5",
    ct: "2,1",
    ct_global: 21,
    d: 210,
    d_global: 210,
    ix: "44,18",
    ix_global: 441800,
    iy: 2079,
    iy_global: 20790000,
    iz: 5729,
    iz_global: 57290000,
    tf: 11,
    tf_global: 11,
    tw: 11,
    tw_global: 11,
    zx: "613,7",
    zx_global: 613700,
    zy: "285,5",
    zy_global: 285500,
    width: "224,5",
    width_global: "224,5",
    thickness: 11,
    thickness_global: 11,
    height: 210,
    height_global: 210,
  },
  {
    profile_section_id: 2575,
    name: "HP260x75",
    type: "HP Shape",
    designation: "HP260x75",
    shape: "I",
    country_code: "European",
    ax: "95,5",
    ax_global: 9550,
    bf: 265,
    bf_global: 265,
    ct: "2,46",
    ct_global: "24,6",
    d: 249,
    d_global: 249,
    ix: "79,29",
    ix_global: 792900,
    iy: 3733,
    iy_global: 37330000,
    iz: 10650,
    iz_global: 106500000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: "958,5",
    zx_global: 958500,
    zy: "435,1",
    zy_global: 435100,
    width: 265,
    width_global: 265,
    thickness: 12,
    thickness_global: 12,
    height: 249,
    height_global: 249,
  },
  {
    profile_section_id: 2576,
    name: "HP260x87",
    type: "HP Shape",
    designation: "HP260x87",
    shape: "I",
    country_code: "European",
    ax: 111,
    ax_global: 11100,
    bf: 267,
    bf_global: 267,
    ct: "2,58",
    ct_global: "25,8",
    d: 253,
    d_global: 253,
    ix: "115,7",
    ix_global: 1157000,
    iy: 4455,
    iy_global: 44550000,
    iz: 12590,
    iz_global: 125900000,
    tf: 14,
    tf_global: 14,
    tw: 14,
    tw_global: 14,
    zx: 1124,
    zx_global: 1124000,
    zy: "516,2",
    zy_global: 516200,
    width: 267,
    width_global: 267,
    thickness: 14,
    thickness_global: 14,
    height: 253,
    height_global: 253,
  },
  {
    profile_section_id: 2577,
    name: "HP305x79",
    type: "HP Shape",
    designation: "HP305x79",
    shape: "I",
    country_code: "European",
    ax: "99,9",
    ax_global: 9990,
    bf: "306,4",
    bf_global: "306,4",
    ct: "2,88",
    ct_global: "28,8",
    d: "299,3",
    d_global: "299,3",
    ix: "50,64",
    ix_global: 506400,
    iy: 5278,
    iy_global: 52780000,
    iz: 16331,
    iz_global: 163310000,
    tf: 11,
    tf_global: 11,
    tw: 11,
    tw_global: 11,
    zx: 1210,
    zx_global: 1210000,
    zy: "526,5",
    zy_global: 526500,
    width: "306,4",
    width_global: "306,4",
    thickness: 11,
    thickness_global: 11,
    height: "299,3",
    height_global: "299,3",
  },
  {
    profile_section_id: 2578,
    name: "HP305x88",
    type: "HP Shape",
    designation: "HP305x88",
    shape: "I",
    country_code: "European",
    ax: 112,
    ax_global: 11200,
    bf: "307,8",
    bf_global: "307,8",
    ct: "2,97",
    ct_global: "29,7",
    d: "301,7",
    d_global: "301,7",
    ix: "70,05",
    ix_global: 700500,
    iy: 5984,
    iy_global: 59840000,
    iz: 18420,
    iz_global: 184200000,
    tf: "12,3",
    tf_global: "12,3",
    tw: "12,4",
    tw_global: "12,4",
    zx: 1360,
    zx_global: 1360000,
    zy: "595,2",
    zy_global: 595200,
    width: "307,8",
    width_global: "307,8",
    thickness: "12,3",
    thickness_global: "12,3",
    height: "301,7",
    height_global: "301,7",
  },
  {
    profile_section_id: 2579,
    name: "HP305x95",
    type: "HP Shape",
    designation: "HP305x95",
    shape: "I",
    country_code: "European",
    ax: 121,
    ax_global: 12100,
    bf: "308,7",
    bf_global: "308,7",
    ct: "3,02",
    ct_global: "30,2",
    d: "303,7",
    d_global: "303,7",
    ix: "86,69",
    ix_global: 866900,
    iy: 6529,
    iy_global: 65290000,
    iz: 20040,
    iz_global: 200400000,
    tf: "13,3",
    tf_global: "13,3",
    tw: "13,3",
    tw_global: "13,3",
    zx: 1474,
    zx_global: 1474000,
    zy: 648,
    zy_global: 648000,
    width: "308,7",
    width_global: "308,7",
    thickness: "13,3",
    thickness_global: "13,3",
    height: "303,7",
    height_global: "303,7",
  },
  {
    profile_section_id: 2580,
    name: "HP305x110",
    type: "HP Shape",
    designation: "HP305x110",
    shape: "I",
    country_code: "European",
    ax: 140,
    ax_global: 14000,
    bf: "310,7",
    bf_global: "310,7",
    ct: "3,13",
    ct_global: "31,3",
    d: "307,9",
    d_global: "307,9",
    ix: "131,4",
    ix_global: 1314000,
    iy: 7709,
    iy_global: 77090000,
    iz: 23560,
    iz_global: 235600000,
    tf: "15,4",
    tf_global: "15,4",
    tw: "15,3",
    tw_global: "15,3",
    zx: 1720,
    zx_global: 1720000,
    zy: "761,7",
    zy_global: 761700,
    width: "310,7",
    width_global: "310,7",
    thickness: "15,4",
    thickness_global: "15,4",
    height: "307,9",
    height_global: "307,9",
  },
  {
    profile_section_id: 2581,
    name: "HP305x126",
    type: "HP Shape",
    designation: "HP305x126",
    shape: "I",
    country_code: "European",
    ax: 161,
    ax_global: 16100,
    bf: "312,9",
    bf_global: "312,9",
    ct: "3,27",
    ct_global: "32,7",
    d: "312,3",
    d_global: "312,3",
    ix: "194,3",
    ix_global: 1943000,
    iy: 9002,
    iy_global: 90020000,
    iz: 27410,
    iz_global: 274100000,
    tf: "17,6",
    tf_global: "17,6",
    tw: "17,5",
    tw_global: "17,5",
    zx: 1986,
    zx_global: 1986000,
    zy: "885,2",
    zy_global: 885200,
    width: "312,9",
    width_global: "312,9",
    thickness: "17,6",
    thickness_global: "17,6",
    height: "312,3",
    height_global: "312,3",
  },
  {
    profile_section_id: 2582,
    name: "HP305x149",
    type: "HP Shape",
    designation: "HP305x149",
    shape: "I",
    country_code: "European",
    ax: 190,
    ax_global: 19000,
    bf: 316,
    bf_global: 316,
    ct: "3,45",
    ct_global: "34,5",
    d: "318,5",
    d_global: "318,5",
    ix: "314,2",
    ix_global: 3142000,
    iy: 10910,
    iy_global: 109100000,
    iz: 33070,
    iz_global: 330700000,
    tf: "20,7",
    tf_global: "20,7",
    tw: "20,6",
    tw_global: "20,6",
    zx: 2370,
    zx_global: 2370000,
    zy: 1066,
    zy_global: 1066000,
    width: 316,
    width_global: 316,
    thickness: "20,7",
    thickness_global: "20,7",
    height: "318,5",
    height_global: "318,5",
  },
  {
    profile_section_id: 2583,
    name: "HP305x180",
    type: "HP Shape",
    designation: "HP305x180",
    shape: "I",
    country_code: "European",
    ax: 229,
    ax_global: 22900,
    bf: "319,7",
    bf_global: "319,7",
    ct: "3,71",
    ct_global: "37,1",
    d: "326,7",
    d_global: "326,7",
    ix: "541,7",
    ix_global: 5417000,
    iy: 13550,
    iy_global: 135500000,
    iz: 40970,
    iz_global: 409700000,
    tf: "24,8",
    tf_global: "24,8",
    tw: "24,8",
    tw_global: "24,8",
    zx: 2897,
    zx_global: 2897000,
    zy: 1313,
    zy_global: 1313000,
    width: "319,7",
    width_global: "319,7",
    thickness: "24,8",
    thickness_global: "24,8",
    height: "326,7",
    height_global: "326,7",
  },
  {
    profile_section_id: 2584,
    name: "HP305x186",
    type: "HP Shape",
    designation: "HP305x186",
    shape: "I",
    country_code: "European",
    ax: 237,
    ax_global: 23700,
    bf: "320,9",
    bf_global: "320,9",
    ct: "3,75",
    ct_global: "37,5",
    d: "328,3",
    d_global: "328,3",
    ix: "593,7",
    ix_global: 5937000,
    iy: 14140,
    iy_global: 141400000,
    iz: 42610,
    iz_global: 426100000,
    tf: "25,6",
    tf_global: "25,6",
    tw: "25,5",
    tw_global: "25,5",
    zx: 3003,
    zx_global: 3003000,
    zy: 1366,
    zy_global: 1366000,
    width: "320,9",
    width_global: "320,9",
    thickness: "25,6",
    thickness_global: "25,6",
    height: "328,3",
    height_global: "328,3",
  },
  {
    profile_section_id: 2585,
    name: "HP305x223",
    type: "HP Shape",
    designation: "HP305x223",
    shape: "I",
    country_code: "European",
    ax: 284,
    ax_global: 28400,
    bf: "325,7",
    bf_global: "325,7",
    ct: "4,04",
    ct_global: "40,4",
    d: "337,9",
    d_global: "337,9",
    ix: "998,4",
    ix_global: 9984000,
    iy: 17580,
    iy_global: 175800000,
    iz: 52700,
    iz_global: 527000000,
    tf: "30,4",
    tf_global: "30,4",
    tw: "30,3",
    tw_global: "30,3",
    zx: 3653,
    zx_global: 3653000,
    zy: 1680,
    zy_global: 1680000,
    width: "325,7",
    width_global: "325,7",
    thickness: "30,4",
    thickness_global: "30,4",
    height: "337,9",
    height_global: "337,9",
  },
  {
    profile_section_id: 2586,
    name: "HP320x88",
    type: "HP Shape",
    designation: "HP320x88",
    shape: "I",
    country_code: "European",
    ax: 113,
    ax_global: 11300,
    bf: 304,
    bf_global: 304,
    ct: "2,98",
    ct_global: "29,8",
    d: 303,
    d_global: 303,
    ix: "99,04",
    ix_global: 990400,
    iy: 5634,
    iy_global: 56340000,
    iz: 18740,
    iz_global: 187400000,
    tf: 12,
    tf_global: 12,
    tw: 12,
    tw_global: 12,
    zx: 1379,
    zx_global: 1379000,
    zy: "572,1",
    zy_global: 572100,
    width: 304,
    width_global: 304,
    thickness: 12,
    thickness_global: 12,
    height: 303,
    height_global: 303,
  },
  {
    profile_section_id: 2587,
    name: "HP320x103",
    type: "HP Shape",
    designation: "HP320x103",
    shape: "I",
    country_code: "European",
    ax: 131,
    ax_global: 13100,
    bf: 306,
    bf_global: 306,
    ct: "3,1",
    ct_global: 31,
    d: 307,
    d_global: 307,
    ix: "142,3",
    ix_global: 1423000,
    iy: 6704,
    iy_global: 67040000,
    iz: 22050,
    iz_global: 220500000,
    tf: 14,
    tf_global: 14,
    tw: 14,
    tw_global: 14,
    zx: 1611,
    zx_global: 1611000,
    zy: "677,3",
    zy_global: 677300,
    width: 306,
    width_global: 306,
    thickness: 14,
    thickness_global: 14,
    height: 307,
    height_global: 307,
  },
  {
    profile_section_id: 2588,
    name: "HP320x117",
    type: "HP Shape",
    designation: "HP320x117",
    shape: "I",
    country_code: "European",
    ax: 150,
    ax_global: 15000,
    bf: 308,
    bf_global: 308,
    ct: "3,22",
    ct_global: "32,2",
    d: 311,
    d_global: 311,
    ix: "198,5",
    ix_global: 1985000,
    iy: 7815,
    iy_global: 78150000,
    iz: 25480,
    iz_global: 254800000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 1849,
    zx_global: 1849000,
    zy: "785,5",
    zy_global: 785500,
    width: 308,
    width_global: 308,
    thickness: 16,
    thickness_global: 16,
    height: 311,
    height_global: 311,
  },
  {
    profile_section_id: 2589,
    name: "HP320x147",
    type: "HP Shape",
    designation: "HP320x147",
    shape: "I",
    country_code: "European",
    ax: 187,
    ax_global: 18700,
    bf: 312,
    bf_global: 312,
    ct: "3,46",
    ct_global: "34,6",
    d: 319,
    d_global: 319,
    ix: "357,1",
    ix_global: 3571000,
    iy: 10160,
    iy_global: 101600000,
    iz: 32670,
    iz_global: 326700000,
    tf: 20,
    tf_global: 20,
    tw: 20,
    tw_global: 20,
    zx: 2338,
    zx_global: 2338000,
    zy: 1011,
    zy_global: 1011000,
    width: 312,
    width_global: 312,
    thickness: 20,
    thickness_global: 20,
    height: 319,
    height_global: 319,
  },
  {
    profile_section_id: 2590,
    name: "HP320x184",
    type: "HP Shape",
    designation: "HP320x184",
    shape: "I",
    country_code: "European",
    ax: 235,
    ax_global: 23500,
    bf: 317,
    bf_global: 317,
    ct: "3,76",
    ct_global: "37,6",
    d: 329,
    d_global: 329,
    ix: 662,
    ix_global: 6620000,
    iy: 13330,
    iy_global: 133300000,
    iz: 42340,
    iz_global: 423400000,
    tf: 25,
    tf_global: 25,
    tw: 25,
    tw_global: 25,
    zx: 2979,
    zx_global: 2979000,
    zy: 1311,
    zy_global: 1311000,
    width: 317,
    width_global: 317,
    thickness: 25,
    thickness_global: 25,
    height: 329,
    height_global: 329,
  },
  {
    profile_section_id: 2591,
    name: "HP360x84",
    type: "HP Shape",
    designation: "HP360x84",
    shape: "I",
    country_code: "European",
    ax: 107,
    ax_global: 10700,
    bf: 367,
    bf_global: 367,
    ct: "3,08",
    ct_global: "30,8",
    d: 340,
    d_global: 340,
    ix: "44,41",
    ix_global: 444100,
    iy: 8243,
    iy_global: 82430000,
    iz: 23210,
    iz_global: 232100000,
    tf: 10,
    tf_global: 10,
    tw: 10,
    tw_global: 10,
    zx: 1498,
    zx_global: 1498000,
    zy: "683,1",
    zy_global: 683100,
    width: 367,
    width_global: 367,
    thickness: 10,
    thickness_global: 10,
    height: 340,
    height_global: 340,
  },
  {
    profile_section_id: 2592,
    name: "HP360x109",
    type: "HP Shape",
    designation: "HP360x109",
    shape: "I",
    country_code: "European",
    ax: 139,
    ax_global: 13900,
    bf: 371,
    bf_global: 371,
    ct: "3,24",
    ct_global: "32,4",
    d: "346,4",
    d_global: "346,4",
    ix: "90,73",
    ix_global: 907300,
    iy: 10990,
    iy_global: 109900000,
    iz: 30630,
    iz_global: 306300000,
    tf: "12,9",
    tf_global: "12,9",
    tw: "12,8",
    tw_global: "12,8",
    zx: 1956,
    zx_global: 1956000,
    zy: "902,9",
    zy_global: 902900,
    width: 371,
    width_global: 371,
    thickness: "12,9",
    thickness_global: "12,9",
    height: "346,4",
    height_global: "346,4",
  },
  {
    profile_section_id: 2593,
    name: "HP360x133",
    type: "HP Shape",
    designation: "HP360x133",
    shape: "I",
    country_code: "European",
    ax: 169,
    ax_global: 16900,
    bf: "373,8",
    bf_global: "373,8",
    ct: "3,41",
    ct_global: "34,1",
    d: 352,
    d_global: 352,
    ix: "160,7",
    ix_global: 1607000,
    iy: 13680,
    iy_global: 136800000,
    iz: 37980,
    iz_global: 379800000,
    tf: "15,7",
    tf_global: "15,7",
    tw: "15,6",
    tw_global: "15,6",
    zx: 2406,
    zx_global: 2406000,
    zy: 1119,
    zy_global: 1119000,
    width: "373,8",
    width_global: "373,8",
    thickness: "15,7",
    thickness_global: "15,7",
    height: 352,
    height_global: 352,
  },
  {
    profile_section_id: 2594,
    name: "HP360x152",
    type: "HP Shape",
    designation: "HP360x152",
    shape: "I",
    country_code: "European",
    ax: 194,
    ax_global: 19400,
    bf: 376,
    bf_global: 376,
    ct: "3,55",
    ct_global: "35,5",
    d: "356,4",
    d_global: "356,4",
    ix: "236,4",
    ix_global: 2364000,
    iy: 15880,
    iy_global: 158800000,
    iz: 43970,
    iz_global: 439700000,
    tf: "17,9",
    tf_global: "17,9",
    tw: "17,8",
    tw_global: "17,8",
    zx: 2767,
    zx_global: 2767000,
    zy: 1293,
    zy_global: 1293000,
    width: 376,
    width_global: 376,
    thickness: "17,9",
    thickness_global: "17,9",
    height: "356,4",
    height_global: "356,4",
  },
  {
    profile_section_id: 2595,
    name: "HP360x174",
    type: "HP Shape",
    designation: "HP360x174",
    shape: "I",
    country_code: "European",
    ax: 222,
    ax_global: 22200,
    bf: "378,5",
    bf_global: "378,5",
    ct: "3,7",
    ct_global: 37,
    d: "361,4",
    d_global: "361,4",
    ix: "348,5",
    ix_global: 3485000,
    iy: 18460,
    iy_global: 184600000,
    iz: 51010,
    iz_global: 510100000,
    tf: "20,4",
    tf_global: "20,4",
    tw: "20,3",
    tw_global: "20,3",
    zx: 3186,
    zx_global: 3186000,
    zy: 1497,
    zy_global: 1497000,
    width: "378,5",
    width_global: "378,5",
    thickness: "20,4",
    thickness_global: "20,4",
    height: "361,4",
    height_global: "361,4",
  },
  {
    profile_section_id: 2596,
    name: "HP360x180",
    type: "HP Shape",
    designation: "HP360x180",
    shape: "I",
    country_code: "European",
    ax: 230,
    ax_global: 23000,
    bf: "378,8",
    bf_global: "378,8",
    ct: "3,75",
    ct_global: "37,5",
    d: "362,9",
    d_global: "362,9",
    ix: "387,2",
    ix_global: 3872000,
    iy: 19140,
    iy_global: 191400000,
    iz: 53040,
    iz_global: 530400000,
    tf: "21,1",
    tf_global: "21,1",
    tw: "21,1",
    tw_global: "21,1",
    zx: 3306,
    zx_global: 3306000,
    zy: 1552,
    zy_global: 1552000,
    width: "378,8",
    width_global: "378,8",
    thickness: "21,1",
    thickness_global: "21,1",
    height: "362,9",
    height_global: "362,9",
  },
  {
    profile_section_id: 2597,
    name: "HP400x122",
    type: "HP Shape",
    designation: "HP400x122",
    shape: "I",
    country_code: "European",
    ax: 156,
    ax_global: 15600,
    bf: 390,
    bf_global: 390,
    ct: "3,23",
    ct_global: "32,3",
    d: 348,
    d_global: 348,
    ix: "118,7",
    ix_global: 1187000,
    iy: 13850,
    iy_global: 138500000,
    iz: 34770,
    iz_global: 347700000,
    tf: 14,
    tf_global: 14,
    tw: 14,
    tw_global: 14,
    zx: 2212,
    zx_global: 2212000,
    zy: 1082,
    zy_global: 1082000,
    width: 390,
    width_global: 390,
    thickness: 14,
    thickness_global: 14,
    height: 348,
    height_global: 348,
  },
  {
    profile_section_id: 2598,
    name: "HP400x140",
    type: "HP Shape",
    designation: "HP400x140",
    shape: "I",
    country_code: "European",
    ax: 179,
    ax_global: 17900,
    bf: 392,
    bf_global: 392,
    ct: "3,35",
    ct_global: "33,5",
    d: 352,
    d_global: 352,
    ix: "175,3",
    ix_global: 1753000,
    iy: 16080,
    iy_global: 160800000,
    iz: 40270,
    iz_global: 402700000,
    tf: 16,
    tf_global: 16,
    tw: 16,
    tw_global: 16,
    zx: 2547,
    zx_global: 2547000,
    zy: 1252,
    zy_global: 1252000,
    width: 392,
    width_global: 392,
    thickness: 16,
    thickness_global: 16,
    height: 352,
    height_global: 352,
  },
  {
    profile_section_id: 2599,
    name: "HP400x158",
    type: "HP Shape",
    designation: "HP400x158",
    shape: "I",
    country_code: "European",
    ax: 201,
    ax_global: 20100,
    bf: 394,
    bf_global: 394,
    ct: "3,47",
    ct_global: "34,7",
    d: 356,
    d_global: 356,
    ix: 248,
    ix_global: 2480000,
    iy: 18370,
    iy_global: 183700000,
    iz: 45940,
    iz_global: 459400000,
    tf: 18,
    tf_global: 18,
    tw: 18,
    tw_global: 18,
    zx: 2888,
    zx_global: 2888000,
    zy: 1425,
    zy_global: 1425000,
    width: 394,
    width_global: 394,
    thickness: 18,
    thickness_global: 18,
    height: 356,
    height_global: 356,
  },
  {
    profile_section_id: 2600,
    name: "HP400x176",
    type: "HP Shape",
    designation: "HP400x176",
    shape: "I",
    country_code: "European",
    ax: 224,
    ax_global: 22400,
    bf: 396,
    bf_global: 396,
    ct: "3,59",
    ct_global: "35,9",
    d: 360,
    d_global: 360,
    ix: "338,9",
    ix_global: 3389000,
    iy: 20720,
    iy_global: 207200000,
    iz: 51770,
    iz_global: 517700000,
    tf: 20,
    tf_global: 20,
    tw: 20,
    tw_global: 20,
    zx: 3235,
    zx_global: 3235000,
    zy: 1603,
    zy_global: 1603000,
    width: 396,
    width_global: 396,
    thickness: 20,
    thickness_global: 20,
    height: 360,
    height_global: 360,
  },
  {
    profile_section_id: 2601,
    name: "HP400x194",
    type: "HP Shape",
    designation: "HP400x194",
    shape: "I",
    country_code: "European",
    ax: 248,
    ax_global: 24800,
    bf: 398,
    bf_global: 398,
    ct: "3,71",
    ct_global: "37,1",
    d: 364,
    d_global: 364,
    ix: "450,2",
    ix_global: 4502000,
    iy: 23150,
    iy_global: 231500000,
    iz: 57760,
    iz_global: 577600000,
    tf: 22,
    tf_global: 22,
    tw: 22,
    tw_global: 22,
    zx: 3588,
    zx_global: 3588000,
    zy: 1784,
    zy_global: 1784000,
    width: 398,
    width_global: 398,
    thickness: 22,
    thickness_global: 22,
    height: 364,
    height_global: 364,
  },
  {
    profile_section_id: 2602,
    name: "HP400x213",
    type: "HP Shape",
    designation: "HP400x213",
    shape: "I",
    country_code: "European",
    ax: 271,
    ax_global: 27100,
    bf: 400,
    bf_global: 400,
    ct: "3,83",
    ct_global: "38,3",
    d: 368,
    d_global: 368,
    ix: "584,2",
    ix_global: 5842000,
    iy: 25640,
    iy_global: 256400000,
    iz: 63920,
    iz_global: 639200000,
    tf: 24,
    tf_global: 24,
    tw: 24,
    tw_global: 24,
    zx: 3947,
    zx_global: 3947000,
    zy: 1969,
    zy_global: 1969000,
    width: 400,
    width_global: 400,
    thickness: 24,
    thickness_global: 24,
    height: 368,
    height_global: 368,
  },
  {
    profile_section_id: 2603,
    name: "HP400x231",
    type: "HP Shape",
    designation: "HP400x231",
    shape: "I",
    country_code: "European",
    ax: 294,
    ax_global: 29400,
    bf: 402,
    bf_global: 402,
    ct: "3,95",
    ct_global: "39,5",
    d: 372,
    d_global: 372,
    ix: "743,1",
    ix_global: 7431000,
    iy: 28200,
    iy_global: 282000000,
    iz: 70260,
    iz_global: 702600000,
    tf: 26,
    tf_global: 26,
    tw: 26,
    tw_global: 26,
    zx: 4312,
    zx_global: 4312000,
    zy: 2158,
    zy_global: 2158000,
    width: 402,
    width_global: 402,
    thickness: 26,
    thickness_global: 26,
    height: 372,
    height_global: 372,
  },
  {
    profile_section_id: 2604,
    name: "HD260x54.1",
    type: "HD Shape",
    designation: "HD260x54.1",
    shape: "I",
    country_code: "European",
    ax: 69,
    ax_global: 6900,
    bf: 260,
    bf_global: 260,
    ct: "1,87",
    ct_global: "18,7",
    d: 244,
    d_global: 244,
    ix: "30,31",
    ix_global: 303100,
    iy: 2788,
    iy_global: 27880000,
    iz: 7981,
    iz_global: 79810000,
    tf: "9,5",
    tf_global: "9,5",
    tw: "6,5",
    tw_global: "6,5",
    zx: "714,5",
    zx_global: 714500,
    zy: "327,7",
    zy_global: 327700,
    width: 260,
    width_global: 260,
    thickness: "9,5",
    thickness_global: "9,5",
    height: 244,
    height_global: 244,
  },
  {
    profile_section_id: 2605,
    name: "HD260x68.2",
    type: "HD Shape",
    designation: "HD260x68.2",
    shape: "I",
    country_code: "European",
    ax: "86,8",
    ax_global: 8680,
    bf: 260,
    bf_global: 260,
    ct: "1,91",
    ct_global: "19,1",
    d: 250,
    d_global: 250,
    ix: "52,37",
    ix_global: 523700,
    iy: 3668,
    iy_global: 36680000,
    iz: 10450,
    iz_global: 104500000,
    tf: "12,5",
    tf_global: "12,5",
    tw: "7,5",
    tw_global: "7,5",
    zx: "919,8",
    zx_global: 919800,
    zy: "430,2",
    zy_global: 430200,
    width: 260,
    width_global: 260,
    thickness: "12,5",
    thickness_global: "12,5",
    height: 250,
    height_global: 250,
  },
  {
    profile_section_id: 2606,
    name: "HD260x93.0",
    type: "HD Shape",
    designation: "HD260x93.0",
    shape: "I",
    country_code: "European",
    ax: "118,4",
    ax_global: 11840,
    bf: 260,
    bf_global: 260,
    ct: "2,16",
    ct_global: "21,6",
    d: 260,
    d_global: 260,
    ix: "123,8",
    ix_global: 1238000,
    iy: 5135,
    iy_global: 51350000,
    iz: 14920,
    iz_global: 149200000,
    tf: "17,5",
    tf_global: "17,5",
    tw: 10,
    tw_global: 10,
    zx: 1283,
    zx_global: 1283000,
    zy: "602,2",
    zy_global: 602200,
    width: 260,
    width_global: 260,
    thickness: "17,5",
    thickness_global: "17,5",
    height: 260,
    height_global: 260,
  },
  {
    profile_section_id: 2607,
    name: "HD260x114",
    type: "HD Shape",
    designation: "HD260x114",
    shape: "I",
    country_code: "European",
    ax: "145,7",
    ax_global: 14570,
    bf: 262,
    bf_global: 262,
    ct: "2,41",
    ct_global: "24,1",
    d: 268,
    d_global: 268,
    ix: "222,4",
    ix_global: 2224000,
    iy: 6456,
    iy_global: 64560000,
    iz: 18910,
    iz_global: 189100000,
    tf: "21,5",
    tf_global: "21,5",
    tw: "12,5",
    tw_global: "12,5",
    zx: 1600,
    zx_global: 1600000,
    zy: "752,5",
    zy_global: 752500,
    width: 262,
    width_global: 262,
    thickness: "21,5",
    thickness_global: "21,5",
    height: 268,
    height_global: 268,
  },
  {
    profile_section_id: 2608,
    name: "HD260x142",
    type: "HD Shape",
    designation: "HD260x142",
    shape: "I",
    country_code: "European",
    ax: "180,3",
    ax_global: 18030,
    bf: 265,
    bf_global: 265,
    ct: "2,71",
    ct_global: "27,1",
    d: 278,
    d_global: 278,
    ix: "406,8",
    ix_global: 4068000,
    iy: 8236,
    iy_global: 82360000,
    iz: 24330,
    iz_global: 243300000,
    tf: "26,5",
    tf_global: "26,5",
    tw: "15,5",
    tw_global: "15,5",
    zx: 2015,
    zx_global: 2015000,
    zy: "950,5",
    zy_global: 950500,
    width: 265,
    width_global: 265,
    thickness: "26,5",
    thickness_global: "26,5",
    height: 278,
    height_global: 278,
  },
  {
    profile_section_id: 2609,
    name: "HD260x172",
    type: "HD Shape",
    designation: "HD260x172",
    shape: "I",
    country_code: "European",
    ax: "219,6",
    ax_global: 21960,
    bf: 268,
    bf_global: 268,
    ct: "2,99",
    ct_global: "29,9",
    d: 290,
    d_global: 290,
    ix: 719,
    ix_global: 7190000,
    iy: 10450,
    iy_global: 104500000,
    iz: 31310,
    iz_global: 313100000,
    tf: "32,5",
    tf_global: "32,5",
    tw: 18,
    tw_global: 18,
    zx: 2524,
    zx_global: 2524000,
    zy: 1192,
    zy_global: 1192000,
    width: 268,
    width_global: 268,
    thickness: "32,5",
    thickness_global: "32,5",
    height: 290,
    height_global: 290,
  },
  {
    profile_section_id: 2610,
    name: "HD320x74.2",
    type: "HD Shape",
    designation: "HD320x74.2",
    shape: "I",
    country_code: "European",
    ax: "94,6",
    ax_global: 9460,
    bf: 300,
    bf_global: 300,
    ct: "2,45",
    ct_global: "24,5",
    d: 301,
    d_global: 301,
    ix: "55,87",
    ix_global: 558700,
    iy: 4959,
    iy_global: 49590000,
    iz: 16450,
    iz_global: 164500000,
    tf: 11,
    tf_global: 11,
    tw: 8,
    tw_global: 8,
    zx: 1196,
    zx_global: 1196000,
    zy: "505,7",
    zy_global: 505700,
    width: 300,
    width_global: 300,
    thickness: 11,
    thickness_global: 11,
    height: 301,
    height_global: 301,
  },
  {
    profile_section_id: 2611,
    name: "HD320x97.6",
    type: "HD Shape",
    designation: "HD320x97.6",
    shape: "I",
    country_code: "European",
    ax: "124,4",
    ax_global: 12440,
    bf: 300,
    bf_global: 300,
    ct: "2,42",
    ct_global: "24,2",
    d: 310,
    d_global: 310,
    ix: 108,
    ix_global: 1080000,
    iy: 6985,
    iy_global: 69850000,
    iz: 22930,
    iz_global: 229300000,
    tf: "15,5",
    tf_global: "15,5",
    tw: 9,
    tw_global: 9,
    zx: 1628,
    zx_global: 1628000,
    zy: "709,7",
    zy_global: 709700,
    width: 300,
    width_global: 300,
    thickness: "15,5",
    thickness_global: "15,5",
    height: 310,
    height_global: 310,
  },
  {
    profile_section_id: 2612,
    name: "HD320x127",
    type: "HD Shape",
    designation: "HD320x127",
    shape: "I",
    country_code: "European",
    ax: "161,3",
    ax_global: 16130,
    bf: 300,
    bf_global: 300,
    ct: "2,68",
    ct_global: "26,8",
    d: 320,
    d_global: 320,
    ix: "225,1",
    ix_global: 2251000,
    iy: 9239,
    iy_global: 92390000,
    iz: 30820,
    iz_global: 308200000,
    tf: "20,5",
    tf_global: "20,5",
    tw: "11,5",
    tw_global: "11,5",
    zx: 2149,
    zx_global: 2149000,
    zy: "939,1",
    zy_global: 939100,
    width: 300,
    width_global: 300,
    thickness: "20,5",
    thickness_global: "20,5",
    height: 320,
    height_global: 320,
  },
  {
    profile_section_id: 2613,
    name: "HD320x158",
    type: "HD Shape",
    designation: "HD320x158",
    shape: "I",
    country_code: "European",
    ax: "201,2",
    ax_global: 20120,
    bf: 303,
    bf_global: 303,
    ct: "2,99",
    ct_global: "29,9",
    d: 330,
    d_global: 330,
    ix: "420,5",
    ix_global: 4205000,
    iy: 11840,
    iy_global: 118400000,
    iz: 39640,
    iz_global: 396400000,
    tf: "25,5",
    tf_global: "25,5",
    tw: "14,5",
    tw_global: "14,5",
    zx: 2718,
    zx_global: 2718000,
    zy: 1194,
    zy_global: 1194000,
    width: 303,
    width_global: 303,
    thickness: "25,5",
    thickness_global: "25,5",
    height: 330,
    height_global: 330,
  },
  {
    profile_section_id: 2614,
    name: "HD320x198",
    type: "HD Shape",
    designation: "HD320x198",
    shape: "I",
    country_code: "European",
    ax: "252,3",
    ax_global: 25230,
    bf: 306,
    bf_global: 306,
    ct: "3,35",
    ct_global: "33,5",
    d: 343,
    d_global: 343,
    ix: "805,3",
    ix_global: 8053000,
    iy: 15310,
    iy_global: 153100000,
    iz: 51900,
    iz_global: 519000000,
    tf: 32,
    tf_global: 32,
    tw: 18,
    tw_global: 18,
    zx: 3479,
    zx_global: 3479000,
    zy: 1530,
    zy_global: 1530000,
    width: 306,
    width_global: 306,
    thickness: 32,
    thickness_global: 32,
    height: 343,
    height_global: 343,
  },
  {
    profile_section_id: 2615,
    name: "HD320x245",
    type: "HD Shape",
    designation: "HD320x245",
    shape: "I",
    country_code: "European",
    ax: 312,
    ax_global: 31200,
    bf: 309,
    bf_global: 309,
    ct: "3,72",
    ct_global: "37,2",
    d: 359,
    d_global: 359,
    ix: 1501,
    ix_global: 15010000,
    iy: 19710,
    iy_global: 197100000,
    iz: 68130,
    iz_global: 681300000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 4435,
    zx_global: 4435000,
    zy: 1951,
    zy_global: 1951000,
    width: 309,
    width_global: 309,
    thickness: 40,
    thickness_global: 40,
    height: 359,
    height_global: 359,
  },
  {
    profile_section_id: 2616,
    name: "HD320x300",
    type: "HD Shape",
    designation: "HD320x300",
    shape: "I",
    country_code: "European",
    ax: "382,1",
    ax_global: 38210,
    bf: 313,
    bf_global: 313,
    ct: "4,28",
    ct_global: "42,8",
    d: 375,
    d_global: 375,
    ix: 2650,
    ix_global: 26500000,
    iy: 24600,
    iy_global: 246000000,
    iz: 86900,
    iz_global: 869000000,
    tf: 48,
    tf_global: 48,
    tw: 27,
    tw_global: 27,
    zx: 5522,
    zx_global: 5522000,
    zy: 2414,
    zy_global: 2414000,
    width: 313,
    width_global: 313,
    thickness: 48,
    thickness_global: 48,
    height: 375,
    height_global: 375,
  },
  {
    profile_section_id: 2617,
    name: "HD360x134",
    type: "HD Shape",
    designation: "HD360x134",
    shape: "I",
    country_code: "European",
    ax: "170,6",
    ax_global: 17060,
    bf: 369,
    bf_global: 369,
    ct: "2,79",
    ct_global: "27,9",
    d: 356,
    d_global: 356,
    ix: "168,8",
    ix_global: 1688000,
    iy: 15080,
    iy_global: 150800000,
    iz: 41510,
    iz_global: 415100000,
    tf: 18,
    tf_global: 18,
    tw: "11,2",
    tw_global: "11,2",
    zx: 2562,
    zx_global: 2562000,
    zy: 1237,
    zy_global: 1237000,
    width: 369,
    width_global: 369,
    thickness: 18,
    thickness_global: 18,
    height: 356,
    height_global: 356,
  },
  {
    profile_section_id: 2618,
    name: "HD360x147",
    type: "HD Shape",
    designation: "HD360x147",
    shape: "I",
    country_code: "European",
    ax: "187,9",
    ax_global: 18790,
    bf: 370,
    bf_global: 370,
    ct: "2,9",
    ct_global: 29,
    d: 360,
    d_global: 360,
    ix: "223,7",
    ix_global: 2237000,
    iy: 16720,
    iy_global: 167200000,
    iz: 46290,
    iz_global: 462900000,
    tf: "19,8",
    tf_global: "19,8",
    tw: "12,3",
    tw_global: "12,3",
    zx: 2838,
    zx_global: 2838000,
    zy: 1369,
    zy_global: 1369000,
    width: 370,
    width_global: 370,
    thickness: "19,8",
    thickness_global: "19,8",
    height: 360,
    height_global: 360,
  },
  {
    profile_section_id: 2619,
    name: "HD360x162",
    type: "HD Shape",
    designation: "HD360x162",
    shape: "I",
    country_code: "European",
    ax: "206,3",
    ax_global: 20630,
    bf: 371,
    bf_global: 371,
    ct: "2,99",
    ct_global: "29,9",
    d: 364,
    d_global: 364,
    ix: "295,5",
    ix_global: 2955000,
    iy: 18560,
    iy_global: 185600000,
    iz: 51540,
    iz_global: 515400000,
    tf: "21,8",
    tf_global: "21,8",
    tw: "13,3",
    tw_global: "13,3",
    zx: 3139,
    zx_global: 3139000,
    zy: 1516,
    zy_global: 1516000,
    width: 371,
    width_global: 371,
    thickness: "21,8",
    thickness_global: "21,8",
    height: 364,
    height_global: 364,
  },
  {
    profile_section_id: 2620,
    name: "HD360x179",
    type: "HD Shape",
    designation: "HD360x179",
    shape: "I",
    country_code: "European",
    ax: "228,3",
    ax_global: 22830,
    bf: 373,
    bf_global: 373,
    ct: "3,15",
    ct_global: "31,5",
    d: 368,
    d_global: 368,
    ix: "393,8",
    ix_global: 3938000,
    iy: 20680,
    iy_global: 206800000,
    iz: 57440,
    iz_global: 574400000,
    tf: "23,9",
    tf_global: "23,9",
    tw: 15,
    tw_global: 15,
    zx: 3482,
    zx_global: 3482000,
    zy: 1683,
    zy_global: 1683000,
    width: 373,
    width_global: 373,
    thickness: "23,9",
    thickness_global: "23,9",
    height: 368,
    height_global: 368,
  },
  {
    profile_section_id: 2621,
    name: "HD360x196",
    type: "HD Shape",
    designation: "HD360x196",
    shape: "I",
    country_code: "European",
    ax: "250,3",
    ax_global: 25030,
    bf: 374,
    bf_global: 374,
    ct: "3,27",
    ct_global: "32,7",
    d: 372,
    d_global: 372,
    ix: "517,1",
    ix_global: 5171000,
    iy: 22860,
    iy_global: 228600000,
    iz: 63630,
    iz_global: 636300000,
    tf: "26,2",
    tf_global: "26,2",
    tw: "16,4",
    tw_global: "16,4",
    zx: 3837,
    zx_global: 3837000,
    zy: 1856,
    zy_global: 1856000,
    width: 374,
    width_global: 374,
    thickness: "26,2",
    thickness_global: "26,2",
    height: 372,
    height_global: 372,
  },
  {
    profile_section_id: 2622,
    name: "HD400x187",
    type: "HD Shape",
    designation: "HD400x187",
    shape: "I",
    country_code: "European",
    ax: "237,6",
    ax_global: 23760,
    bf: 391,
    bf_global: 391,
    ct: "3,07",
    ct_global: "30,7",
    d: 368,
    d_global: 368,
    ix: "414,6",
    ix_global: 4146000,
    iy: 23920,
    iy_global: 239200000,
    iz: 60180,
    iz_global: 601800000,
    tf: 24,
    tf_global: 24,
    tw: 15,
    tw_global: 15,
    zx: 3642,
    zx_global: 3642000,
    zy: 1855,
    zy_global: 1855000,
    width: 391,
    width_global: 391,
    thickness: 24,
    thickness_global: 24,
    height: 368,
    height_global: 368,
  },
  {
    profile_section_id: 2623,
    name: "HD400x216",
    type: "HD Shape",
    designation: "HD400x216",
    shape: "I",
    country_code: "European",
    ax: "275,5",
    ax_global: 27550,
    bf: 394,
    bf_global: 394,
    ct: "3,28",
    ct_global: "32,8",
    d: 375,
    d_global: 375,
    ix: "637,3",
    ix_global: 6373000,
    iy: 28250,
    iy_global: 282500000,
    iz: 71140,
    iz_global: 711400000,
    tf: "27,7",
    tf_global: "27,7",
    tw: "17,3",
    tw_global: "17,3",
    zx: 4262,
    zx_global: 4262000,
    zy: 2176,
    zy_global: 2176000,
    width: 394,
    width_global: 394,
    thickness: "27,7",
    thickness_global: "27,7",
    height: 375,
    height_global: 375,
  },
  {
    profile_section_id: 2624,
    name: "HD400x237",
    type: "HD Shape",
    designation: "HD400x237",
    shape: "I",
    country_code: "European",
    ax: "300,9",
    ax_global: 30090,
    bf: 395,
    bf_global: 395,
    ct: "3,43",
    ct_global: "34,3",
    d: 380,
    d_global: 380,
    ix: "825,5",
    ix_global: 8255000,
    iy: 31040,
    iy_global: 310400000,
    iz: 78780,
    iz_global: 787800000,
    tf: "30,2",
    tf_global: "30,2",
    tw: "18,9",
    tw_global: "18,9",
    zx: 4686,
    zx_global: 4686000,
    zy: 2387,
    zy_global: 2387000,
    width: 395,
    width_global: 395,
    thickness: "30,2",
    thickness_global: "30,2",
    height: 380,
    height_global: 380,
  },
  {
    profile_section_id: 2625,
    name: "HD400x262",
    type: "HD Shape",
    designation: "HD400x262",
    shape: "I",
    country_code: "European",
    ax: "334,6",
    ax_global: 33460,
    bf: 398,
    bf_global: 398,
    ct: "3,63",
    ct_global: "36,3",
    d: 387,
    d_global: 387,
    ix: 1116,
    ix_global: 11160000,
    iy: 35020,
    iy_global: 350200000,
    iz: 89410,
    iz_global: 894100000,
    tf: "33,3",
    tf_global: "33,3",
    tw: "21,1",
    tw_global: "21,1",
    zx: 5260,
    zx_global: 5260000,
    zy: 2676,
    zy_global: 2676000,
    width: 398,
    width_global: 398,
    thickness: "33,3",
    thickness_global: "33,3",
    height: 387,
    height_global: 387,
  },
  {
    profile_section_id: 2626,
    name: "HD400x287",
    type: "HD Shape",
    designation: "HD400x287",
    shape: "I",
    country_code: "European",
    ax: "366,3",
    ax_global: 36630,
    bf: 399,
    bf_global: 399,
    ct: "3,78",
    ct_global: "37,8",
    d: 393,
    d_global: 393,
    ix: 1464,
    ix_global: 14640000,
    iy: 38780,
    iy_global: 387800000,
    iz: 99710,
    iz_global: 997100000,
    tf: "36,6",
    tf_global: "36,6",
    tw: "22,6",
    tw_global: "22,6",
    zx: 5813,
    zx_global: 5813000,
    zy: 2957,
    zy_global: 2957000,
    width: 399,
    width_global: 399,
    thickness: "36,6",
    thickness_global: "36,6",
    height: 393,
    height_global: 393,
  },
  {
    profile_section_id: 2627,
    name: "HD400x314",
    type: "HD Shape",
    designation: "HD400x314",
    shape: "I",
    country_code: "European",
    ax: "399,2",
    ax_global: 39920,
    bf: 401,
    bf_global: 401,
    ct: "3,98",
    ct_global: "39,8",
    d: 399,
    d_global: 399,
    ix: 1870,
    ix_global: 18700000,
    iy: 42600,
    iy_global: 426000000,
    iz: 110200,
    iz_global: 1102000000,
    tf: "39,6",
    tf_global: "39,6",
    tw: "24,9",
    tw_global: "24,9",
    zx: 6374,
    zx_global: 6374000,
    zy: 3236,
    zy_global: 3236000,
    width: 401,
    width_global: 401,
    thickness: "39,6",
    thickness_global: "39,6",
    height: 399,
    height_global: 399,
  },
  {
    profile_section_id: 2628,
    name: "HD400x347",
    type: "HD Shape",
    designation: "HD400x347",
    shape: "I",
    country_code: "European",
    ax: 442,
    ax_global: 44200,
    bf: 404,
    bf_global: 404,
    ct: "4,2",
    ct_global: 42,
    d: 407,
    d_global: 407,
    ix: 2510,
    ix_global: 25100000,
    iy: 48090,
    iy_global: 480900000,
    iz: 124900,
    iz_global: 1249000000,
    tf: "43,7",
    tf_global: "43,7",
    tw: "27,2",
    tw_global: "27,2",
    zx: 7139,
    zx_global: 7139000,
    zy: 3629,
    zy_global: 3629000,
    width: 404,
    width_global: 404,
    thickness: "43,7",
    thickness_global: "43,7",
    height: 407,
    height_global: 407,
  },
  {
    profile_section_id: 2629,
    name: "HD400x382",
    type: "HD Shape",
    designation: "HD400x382",
    shape: "I",
    country_code: "European",
    ax: "487,1",
    ax_global: 48710,
    bf: 406,
    bf_global: 406,
    ct: "4,44",
    ct_global: "44,4",
    d: 416,
    d_global: 416,
    ix: 3326,
    ix_global: 33260000,
    iy: 53620,
    iy_global: 536200000,
    iz: 141300,
    iz_global: 1413000000,
    tf: 48,
    tf_global: 48,
    tw: "29,8",
    tw_global: "29,8",
    zx: 7965,
    zx_global: 7965000,
    zy: 4031,
    zy_global: 4031000,
    width: 406,
    width_global: 406,
    thickness: 48,
    thickness_global: 48,
    height: 416,
    height_global: 416,
  },
  {
    profile_section_id: 2630,
    name: "HD400x421",
    type: "HD Shape",
    designation: "HD400x421",
    shape: "I",
    country_code: "European",
    ax: "537,1",
    ax_global: 53710,
    bf: 409,
    bf_global: 409,
    ct: "4,71",
    ct_global: "47,1",
    d: 425,
    d_global: 425,
    ix: 4398,
    ix_global: 43980000,
    iy: 60080,
    iy_global: 600800000,
    iz: 159600,
    iz_global: 1596000000,
    tf: "52,6",
    tf_global: "52,6",
    tw: "32,8",
    tw_global: "32,8",
    zx: 8880,
    zx_global: 8880000,
    zy: 4489,
    zy_global: 4489000,
    width: 409,
    width_global: 409,
    thickness: "52,6",
    thickness_global: "52,6",
    height: 425,
    height_global: 425,
  },
  {
    profile_section_id: 2631,
    name: "HD400x463",
    type: "HD Shape",
    designation: "HD400x463",
    shape: "I",
    country_code: "European",
    ax: "589,5",
    ax_global: 58950,
    bf: 412,
    bf_global: 412,
    ct: "4,99",
    ct_global: "49,9",
    d: 435,
    d_global: 435,
    ix: 5735,
    ix_global: 57350000,
    iy: 67040,
    iy_global: 670400000,
    iz: 180200,
    iz_global: 1802000000,
    tf: "57,4",
    tf_global: "57,4",
    tw: "35,8",
    tw_global: "35,8",
    zx: 9878,
    zx_global: 9878000,
    zy: 4978,
    zy_global: 4978000,
    width: 412,
    width_global: 412,
    thickness: "57,4",
    thickness_global: "57,4",
    height: 435,
    height_global: 435,
  },
  {
    profile_section_id: 2632,
    name: "HD400x509",
    type: "HD Shape",
    designation: "HD400x509",
    shape: "I",
    country_code: "European",
    ax: 649,
    ax_global: 64900,
    bf: 416,
    bf_global: 416,
    ct: "5,3",
    ct_global: 53,
    d: 446,
    d_global: 446,
    ix: 7513,
    ix_global: 75130000,
    iy: 75400,
    iy_global: 754000000,
    iz: 204500,
    iz_global: 2045000000,
    tf: "62,7",
    tf_global: "62,7",
    tw: "39,1",
    tw_global: "39,1",
    zx: 11030,
    zx_global: 11030000,
    zy: 5552,
    zy_global: 5552000,
    width: 416,
    width_global: 416,
    thickness: "62,7",
    thickness_global: "62,7",
    height: 446,
    height_global: 446,
  },
  {
    profile_section_id: 2633,
    name: "HD400x551",
    type: "HD Shape",
    designation: "HD400x551",
    shape: "I",
    country_code: "European",
    ax: "701,4",
    ax_global: 70140,
    bf: 418,
    bf_global: 418,
    ct: "5,56",
    ct_global: "55,6",
    d: 455,
    d_global: 455,
    ix: 9410,
    ix_global: 94100000,
    iy: 82490,
    iy_global: 824900000,
    iz: 226100,
    iz_global: 2261000000,
    tf: "67,6",
    tf_global: "67,6",
    tw: 42,
    tw_global: 42,
    zx: 12050,
    zx_global: 12050000,
    zy: 6051,
    zy_global: 6051000,
    width: 418,
    width_global: 418,
    thickness: "67,6",
    thickness_global: "67,6",
    height: 455,
    height_global: 455,
  },
  {
    profile_section_id: 2634,
    name: "HD400x592",
    type: "HD Shape",
    designation: "HD400x592",
    shape: "I",
    country_code: "European",
    ax: "754,9",
    ax_global: 75490,
    bf: 421,
    bf_global: 421,
    ct: "5,84",
    ct_global: "58,4",
    d: 465,
    d_global: 465,
    ix: 11560,
    ix_global: 115600000,
    iy: 90170,
    iy_global: 901700000,
    iz: 250200,
    iz_global: 2502000000,
    tf: "72,3",
    tf_global: "72,3",
    tw: 45,
    tw_global: 45,
    zx: 13140,
    zx_global: 13140000,
    zy: 6574,
    zy_global: 6574000,
    width: 421,
    width_global: 421,
    thickness: "72,3",
    thickness_global: "72,3",
    height: 465,
    height_global: 465,
  },
  {
    profile_section_id: 2635,
    name: "HD400x634",
    type: "HD Shape",
    designation: "HD400x634",
    shape: "I",
    country_code: "European",
    ax: 808,
    ax_global: 80800,
    bf: 424,
    bf_global: 424,
    ct: "6,09",
    ct_global: "60,9",
    d: 474,
    d_global: 474,
    ix: 14020,
    ix_global: 140200000,
    iy: 98250,
    iy_global: 982500000,
    iz: 274200,
    iz_global: 2742000000,
    tf: "77,1",
    tf_global: "77,1",
    tw: "47,6",
    tw_global: "47,6",
    zx: 14220,
    zx_global: 14220000,
    zy: 7117,
    zy_global: 7117000,
    width: 424,
    width_global: 424,
    thickness: "77,1",
    thickness_global: "77,1",
    height: 474,
    height_global: 474,
  },
  {
    profile_section_id: 2636,
    name: "HD400x677",
    type: "HD Shape",
    designation: "HD400x677",
    shape: "I",
    country_code: "European",
    ax: "863,4",
    ax_global: 86340,
    bf: 428,
    bf_global: 428,
    ct: "6,37",
    ct_global: "63,7",
    d: 483,
    d_global: 483,
    ix: 16790,
    ix_global: 167900000,
    iy: 106900,
    iy_global: 1069000000,
    iz: 299500,
    iz_global: 2995000000,
    tf: "81,5",
    tf_global: "81,5",
    tw: "51,2",
    tw_global: "51,2",
    zx: 15350,
    zx_global: 15350000,
    zy: 7680,
    zy_global: 7680000,
    width: 428,
    width_global: 428,
    thickness: "81,5",
    thickness_global: "81,5",
    height: 483,
    height_global: 483,
  },
  {
    profile_section_id: 2637,
    name: "HD400x744",
    type: "HD Shape",
    designation: "HD400x744",
    shape: "I",
    country_code: "European",
    ax: "948,1",
    ax_global: 94810,
    bf: 432,
    bf_global: 432,
    ct: "6,79",
    ct_global: "67,9",
    d: 498,
    d_global: 498,
    ix: 21840,
    ix_global: 218400000,
    iy: 119900,
    iy_global: 1199000000,
    iz: 342100,
    iz_global: 3421000000,
    tf: "88,9",
    tf_global: "88,9",
    tw: "55,6",
    tw_global: "55,6",
    zx: 17170,
    zx_global: 17170000,
    zy: 8549,
    zy_global: 8549000,
    width: 432,
    width_global: 432,
    thickness: "88,9",
    thickness_global: "88,9",
    height: 498,
    height_global: 498,
  },
  {
    profile_section_id: 2638,
    name: "HD400x818",
    type: "HD Shape",
    designation: "HD400x818",
    shape: "I",
    country_code: "European",
    ax: 1043,
    ax_global: 104300,
    bf: 437,
    bf_global: 437,
    ct: "7,24",
    ct_global: "72,4",
    d: 514,
    d_global: 514,
    ix: 28510,
    ix_global: 285100000,
    iy: 135500,
    iy_global: 1355000000,
    iz: 392200,
    iz_global: 3922000000,
    tf: 97,
    tf_global: 97,
    tw: "60,5",
    tw_global: "60,5",
    zx: 19260,
    zx_global: 19260000,
    zy: 9561,
    zy_global: 9561000,
    width: 437,
    width_global: 437,
    thickness: 97,
    thickness_global: 97,
    height: 514,
    height_global: 514,
  },
  {
    profile_section_id: 2639,
    name: "HD400x900",
    type: "HD Shape",
    designation: "HD400x900",
    shape: "I",
    country_code: "European",
    ax: 1149,
    ax_global: 114900,
    bf: 442,
    bf_global: 442,
    ct: "7,73",
    ct_global: "77,3",
    d: 531,
    d_global: 531,
    ix: 37350,
    ix_global: 373500000,
    iy: 153300,
    iy_global: 1533000000,
    iz: 450200,
    iz_global: 4502000000,
    tf: 106,
    tf_global: 106,
    tw: "65,9",
    tw_global: "65,9",
    zx: 21620,
    zx_global: 21620000,
    zy: 10710,
    zy_global: 10710000,
    width: 442,
    width_global: 442,
    thickness: 106,
    thickness_global: 106,
    height: 531,
    height_global: 531,
  },
  {
    profile_section_id: 2640,
    name: "HD400x990",
    type: "HD Shape",
    designation: "HD400x990",
    shape: "I",
    country_code: "European",
    ax: 1262,
    ax_global: 126200,
    bf: 448,
    bf_global: 448,
    ct: "8,26",
    ct_global: "82,6",
    d: 550,
    d_global: 550,
    ix: 48210,
    ix_global: 482100000,
    iy: 173400,
    iy_global: 1734000000,
    iz: 518900,
    iz_global: 5189000000,
    tf: 115,
    tf_global: 115,
    tw: "71,9",
    tw_global: "71,9",
    zx: 24280,
    zx_global: 24280000,
    zy: 11960,
    zy_global: 11960000,
    width: 448,
    width_global: 448,
    thickness: 115,
    thickness_global: 115,
    height: 550,
    height_global: 550,
  },
  {
    profile_section_id: 2641,
    name: "HD400x1086",
    type: "HD Shape",
    designation: "HD400x1086",
    shape: "I",
    country_code: "European",
    ax: 1386,
    ax_global: 138600,
    bf: 454,
    bf_global: 454,
    ct: "8,81",
    ct_global: "88,1",
    d: 569,
    d_global: 569,
    ix: 62290,
    ix_global: 622900000,
    iy: 196200,
    iy_global: 1962000000,
    iz: 595700,
    iz_global: 5957000000,
    tf: 125,
    tf_global: 125,
    tw: 78,
    tw_global: 78,
    zx: 27210,
    zx_global: 27210000,
    zy: 13380,
    zy_global: 13380000,
    width: 454,
    width_global: 454,
    thickness: 125,
    thickness_global: 125,
    height: 569,
    height_global: 569,
  },
  {
    profile_section_id: 2642,
    name: "HL920x342",
    type: "HL Shape",
    designation: "HL920x342",
    shape: "I",
    country_code: "European",
    ax: "436,1",
    ax_global: 43610,
    bf: 418,
    bf_global: 418,
    ct: "10,25",
    ct_global: "102,5",
    d: 912,
    d_global: 912,
    ix: 1193,
    ix_global: 11930000,
    iy: 39010,
    iy_global: 390100000,
    iz: 624900,
    iz_global: 6249000000,
    tf: 32,
    tf_global: 32,
    tw: "19,3",
    tw_global: "19,3",
    zx: 15450,
    zx_global: 15450000,
    zy: 2882,
    zy_global: 2882000,
    width: 418,
    width_global: 418,
    thickness: 32,
    thickness_global: 32,
    height: 912,
    height_global: 912,
  },
  {
    profile_section_id: 2643,
    name: "HL920x365",
    type: "HL Shape",
    designation: "HL920x365",
    shape: "I",
    country_code: "European",
    ax: "464,4",
    ax_global: 46440,
    bf: 419,
    bf_global: 419,
    ct: "10,29",
    ct_global: "102,9",
    d: 916,
    d_global: 916,
    ix: 1446,
    ix_global: 14460000,
    iy: 42120,
    iy_global: 421200000,
    iz: 670500,
    iz_global: 6705000000,
    tf: "34,3",
    tf_global: "34,3",
    tw: "20,3",
    tw_global: "20,3",
    zx: 16520,
    zx_global: 16520000,
    zy: 3106,
    zy_global: 3106000,
    width: 419,
    width_global: 419,
    thickness: "34,3",
    thickness_global: "34,3",
    height: 916,
    height_global: 916,
  },
  {
    profile_section_id: 2644,
    name: "HL920x387",
    type: "HL Shape",
    designation: "HL920x387",
    shape: "I",
    country_code: "European",
    ax: 493,
    ax_global: 49300,
    bf: 420,
    bf_global: 420,
    ct: "10,35",
    ct_global: "103,5",
    d: 921,
    d_global: 921,
    ix: 1734,
    ix_global: 17340000,
    iy: 45280,
    iy_global: 452800000,
    iz: 718300,
    iz_global: 7183000000,
    tf: "36,6",
    tf_global: "36,6",
    tw: "21,3",
    tw_global: "21,3",
    zx: 17630,
    zx_global: 17630000,
    zy: 3332,
    zy_global: 3332000,
    width: 420,
    width_global: 420,
    thickness: "36,6",
    thickness_global: "36,6",
    height: 921,
    height_global: 921,
  },
  {
    profile_section_id: 2645,
    name: "HL920x417",
    type: "HL Shape",
    designation: "HL920x417",
    shape: "I",
    country_code: "European",
    ax: "532,5",
    ax_global: 53250,
    bf: 422,
    bf_global: 422,
    ct: "10,39",
    ct_global: "103,9",
    d: 928,
    d_global: 928,
    ix: 2200,
    ix_global: 22000000,
    iy: 50070,
    iy_global: 500700000,
    iz: 787600,
    iz_global: 7876000000,
    tf: "39,9",
    tf_global: "39,9",
    tw: "22,5",
    tw_global: "22,5",
    zx: 19210,
    zx_global: 19210000,
    zy: 3668,
    zy_global: 3668000,
    width: 422,
    width_global: 422,
    thickness: "39,9",
    thickness_global: "39,9",
    height: 928,
    height_global: 928,
  },
  {
    profile_section_id: 2646,
    name: "HL920x446",
    type: "HL Shape",
    designation: "HL920x446",
    shape: "I",
    country_code: "European",
    ax: "569,6",
    ax_global: 56960,
    bf: 423,
    bf_global: 423,
    ct: "10,54",
    ct_global: "105,4",
    d: 933,
    d_global: 933,
    ix: 2685,
    ix_global: 26850000,
    iy: 53980,
    iy_global: 539800000,
    iz: 846800,
    iz_global: 8468000000,
    tf: "42,7",
    tf_global: "42,7",
    tw: 24,
    tw_global: 24,
    zx: 20600,
    zx_global: 20600000,
    zy: 3951,
    zy_global: 3951000,
    width: 423,
    width_global: 423,
    thickness: "42,7",
    thickness_global: "42,7",
    height: 933,
    height_global: 933,
  },
  {
    profile_section_id: 2647,
    name: "HL920x488",
    type: "HL Shape",
    designation: "HL920x488",
    shape: "I",
    country_code: "European",
    ax: "621,3",
    ax_global: 62130,
    bf: 422,
    bf_global: 422,
    ct: "10,74",
    ct_global: "107,4",
    d: 942,
    d_global: 942,
    ix: 3514,
    ix_global: 35140000,
    iy: 59010,
    iy_global: 590100000,
    iz: 935390,
    iz_global: 9353900000,
    tf: 47,
    tf_global: 47,
    tw: "25,9",
    tw_global: "25,9",
    zx: 22615,
    zx_global: 22615000,
    zy: 4336,
    zy_global: 4336000,
    width: 422,
    width_global: 422,
    thickness: 47,
    thickness_global: 47,
    height: 942,
    height_global: 942,
  },
  {
    profile_section_id: 2648,
    name: "HL920x534",
    type: "HL Shape",
    designation: "HL920x534",
    shape: "I",
    country_code: "European",
    ax: "680,1",
    ax_global: 68010,
    bf: 425,
    bf_global: 425,
    ct: "11,03",
    ct_global: "110,3",
    d: 950,
    d_global: 950,
    ix: 4542,
    ix_global: 45420000,
    iy: 65560,
    iy_global: 655600000,
    iz: 1031000,
    iz_global: 10310000000,
    tf: "51,1",
    tf_global: "51,1",
    tw: "28,4",
    tw_global: "28,4",
    zx: 24830,
    zx_global: 24830000,
    zy: 4796,
    zy_global: 4796000,
    width: 425,
    width_global: 425,
    thickness: "51,1",
    thickness_global: "51,1",
    height: 950,
    height_global: 950,
  },
  {
    profile_section_id: 2649,
    name: "HL920x585",
    type: "HL Shape",
    designation: "HL920x585",
    shape: "I",
    country_code: "European",
    ax: "745,3",
    ax_global: 74530,
    bf: 427,
    bf_global: 427,
    ct: "11,32",
    ct_global: "113,2",
    d: 960,
    d_global: 960,
    ix: 5932,
    ix_global: 59320000,
    iy: 72770,
    iy_global: 727700000,
    iz: 1143090,
    iz_global: 11430900000,
    tf: "55,9",
    tf_global: "55,9",
    tw: 31,
    tw_global: 31,
    zx: 27363,
    zx_global: 27363000,
    zy: 5310,
    zy_global: 5310000,
    width: 427,
    width_global: 427,
    thickness: "55,9",
    thickness_global: "55,9",
    height: 960,
    height_global: 960,
  },
  {
    profile_section_id: 2650,
    name: "HL920x653",
    type: "HL Shape",
    designation: "HL920x653",
    shape: "I",
    country_code: "European",
    ax: "831,9",
    ax_global: 83190,
    bf: 431,
    bf_global: 431,
    ct: "11,7",
    ct_global: 117,
    d: 972,
    d_global: 972,
    ix: 8124,
    ix_global: 81240000,
    iy: 83050,
    iy_global: 830500000,
    iz: 1292000,
    iz_global: 12920000000,
    tf: 62,
    tf_global: 62,
    tw: "34,5",
    tw_global: "34,5",
    zx: 30730,
    zx_global: 30730000,
    zy: 6022,
    zy_global: 6022000,
    width: 431,
    width_global: 431,
    thickness: 62,
    thickness_global: 62,
    height: 972,
    height_global: 972,
  },
  {
    profile_section_id: 2651,
    name: "HL920x784",
    type: "HL Shape",
    designation: "HL920x784",
    shape: "I",
    country_code: "European",
    ax: "997,7",
    ax_global: 99770,
    bf: 437,
    bf_global: 437,
    ct: "12,4",
    ct_global: 124,
    d: 996,
    d_global: 996,
    ix: 13730,
    ix_global: 137300000,
    iy: 103300,
    iy_global: 1033000000,
    iz: 1593000,
    iz_global: 15930000000,
    tf: "73,9",
    tf_global: "73,9",
    tw: "40,9",
    tw_global: "40,9",
    zx: 37340,
    zx_global: 37340000,
    zy: 7424,
    zy_global: 7424000,
    width: 437,
    width_global: 437,
    thickness: "73,9",
    thickness_global: "73,9",
    height: 996,
    height_global: 996,
  },
  {
    profile_section_id: 2652,
    name: "HL920x967",
    type: "HL Shape",
    designation: "HL920x967",
    shape: "I",
    country_code: "European",
    ax: 1231,
    ax_global: 123100,
    bf: 446,
    bf_global: 446,
    ct: "13,39",
    ct_global: "133,9",
    d: 1028,
    d_global: 1028,
    ix: 24930,
    ix_global: 249300000,
    iy: 133900,
    iy_global: 1339000000,
    iz: 2033000,
    iz_global: 20330000000,
    tf: "89,9",
    tf_global: "89,9",
    tw: 50,
    tw_global: 50,
    zx: 46810,
    zx_global: 46810000,
    zy: 9486,
    zy_global: 9486000,
    width: 446,
    width_global: 446,
    thickness: "89,9",
    thickness_global: "89,9",
    height: 1028,
    height_global: 1028,
  },
  {
    profile_section_id: 2653,
    name: "HL1000AA",
    type: "HL Shape",
    designation: "HL1000AA",
    shape: "I",
    country_code: "European",
    ax: "376,8",
    ax_global: 37680,
    bf: 400,
    bf_global: 400,
    ct: "11,53",
    ct_global: "115,3",
    d: 982,
    d_global: 982,
    ix: "756,9",
    ix_global: 7569000,
    iy: 28850,
    iy_global: 288500000,
    iz: 618700,
    iz_global: 6187000000,
    tf: 27,
    tf_global: 27,
    tw: "16,5",
    tw_global: "16,5",
    zx: 14220,
    zx_global: 14220000,
    zy: 2235,
    zy_global: 2235000,
    width: 400,
    width_global: 400,
    thickness: 27,
    thickness_global: 27,
    height: 982,
    height_global: 982,
  },
  {
    profile_section_id: 2654,
    name: "HL1000A",
    type: "HL Shape",
    designation: "HL1000A",
    shape: "I",
    country_code: "European",
    ax: "408,8",
    ax_global: 40880,
    bf: 400,
    bf_global: 400,
    ct: 11,
    ct_global: 110,
    d: 990,
    d_global: 990,
    ix: 1021,
    ix_global: 10210000,
    iy: 33120,
    iy_global: 331200000,
    iz: 696400,
    iz_global: 6964000000,
    tf: 31,
    tf_global: 31,
    tw: "16,5",
    tw_global: "16,5",
    zx: 15800,
    zx_global: 15800000,
    zy: 2555,
    zy_global: 2555000,
    width: 400,
    width_global: 400,
    thickness: 31,
    thickness_global: 31,
    height: 990,
    height_global: 990,
  },
  {
    profile_section_id: 2655,
    name: "HL1000B",
    type: "HL Shape",
    designation: "HL1000B",
    shape: "I",
    country_code: "European",
    ax: 472,
    ax_global: 47200,
    bf: 400,
    bf_global: 400,
    ct: "11,29",
    ct_global: "112,9",
    d: 1000,
    d_global: 1000,
    ix: 1565,
    ix_global: 15650000,
    iy: 38480,
    iy_global: 384800000,
    iz: 812100,
    iz_global: 8121000000,
    tf: 36,
    tf_global: 36,
    tw: 19,
    tw_global: 19,
    zx: 18330,
    zx_global: 18330000,
    zy: 2976,
    zy_global: 2976000,
    width: 400,
    width_global: 400,
    thickness: 36,
    thickness_global: 36,
    height: 1000,
    height_global: 1000,
  },
  {
    profile_section_id: 2656,
    name: "HL1000M",
    type: "HL Shape",
    designation: "HL1000M",
    shape: "I",
    country_code: "European",
    ax: "524,2",
    ax_global: 52420,
    bf: 402,
    bf_global: 402,
    ct: "11,51",
    ct_global: "115,1",
    d: 1008,
    d_global: 1008,
    ix: 2128,
    ix_global: 21280000,
    iy: 43410,
    iy_global: 434100000,
    iz: 909800,
    iz_global: 9098000000,
    tf: 40,
    tf_global: 40,
    tw: 21,
    tw_global: 21,
    zx: 20440,
    zx_global: 20440000,
    zy: 3348,
    zy_global: 3348000,
    width: 402,
    width_global: 402,
    thickness: 40,
    thickness_global: 40,
    height: 1008,
    height_global: 1008,
  },
  {
    profile_section_id: 2657,
    name: "HL1000x443",
    type: "HL Shape",
    designation: "HL1000x443",
    shape: "I",
    country_code: "European",
    ax: "563,7",
    ax_global: 56370,
    bf: 402,
    bf_global: 402,
    ct: "12,06",
    ct_global: "120,6",
    d: 1012,
    d_global: 1012,
    ix: 2545,
    ix_global: 25450000,
    iy: 45500,
    iy_global: 455000000,
    iz: 966510,
    iz_global: 9665100000,
    tf: "41,9",
    tf_global: "41,9",
    tw: "23,6",
    tw_global: "23,6",
    zx: 21777,
    zx_global: 21777000,
    zy: 3529,
    zy_global: 3529000,
    width: 402,
    width_global: 402,
    thickness: "41,9",
    thickness_global: "41,9",
    height: 1012,
    height_global: 1012,
  },
  {
    profile_section_id: 2658,
    name: "HL1000x483",
    type: "HL Shape",
    designation: "HL1000x483",
    shape: "I",
    country_code: "European",
    ax: "615,1",
    ax_global: 61510,
    bf: 404,
    bf_global: 404,
    ct: "12,2",
    ct_global: 122,
    d: 1020,
    d_global: 1020,
    ix: 3311,
    ix_global: 33110000,
    iy: 50710,
    iy_global: 507100000,
    iz: 1067480,
    iz_global: 10674800000,
    tf: 46,
    tf_global: 46,
    tw: "25,4",
    tw_global: "25,4",
    zx: 23923,
    zx_global: 23923000,
    zy: 3919,
    zy_global: 3919000,
    width: 404,
    width_global: 404,
    thickness: 46,
    thickness_global: 46,
    height: 1020,
    height_global: 1020,
  },
  {
    profile_section_id: 2659,
    name: "HL1000x539",
    type: "HL Shape",
    designation: "HL1000x539",
    shape: "I",
    country_code: "European",
    ax: "687,2",
    ax_global: 68720,
    bf: 407,
    bf_global: 407,
    ct: "12,54",
    ct_global: "125,4",
    d: 1030,
    d_global: 1030,
    ix: 4546,
    ix_global: 45460000,
    iy: 57630,
    iy_global: 576300000,
    iz: 1202540,
    iz_global: 12025400000,
    tf: "51,1",
    tf_global: "51,1",
    tw: "28,4",
    tw_global: "28,4",
    zx: 26824,
    zx_global: 26824000,
    zy: 4436,
    zy_global: 4436000,
    width: 407,
    width_global: 407,
    thickness: "51,1",
    thickness_global: "51,1",
    height: 1030,
    height_global: 1030,
  },
  {
    profile_section_id: 2660,
    name: "HL1000x554",
    type: "HL Shape",
    designation: "HL1000x554",
    shape: "I",
    country_code: "European",
    ax: "705,8",
    ax_global: 70580,
    bf: 408,
    bf_global: 408,
    ct: "12,72",
    ct_global: "127,2",
    d: 1032,
    d_global: 1032,
    ix: 4860,
    ix_global: 48600000,
    iy: 59100,
    iy_global: 591000000,
    iz: 1232000,
    iz_global: 12320000000,
    tf: 52,
    tf_global: 52,
    tw: "29,5",
    tw_global: "29,5",
    zx: 27500,
    zx_global: 27500000,
    zy: 4547,
    zy_global: 4547000,
    width: 408,
    width_global: 408,
    thickness: 52,
    thickness_global: 52,
    height: 1032,
    height_global: 1032,
  },
  {
    profile_section_id: 2661,
    name: "HL1000x591",
    type: "HL Shape",
    designation: "HL1000x591",
    shape: "I",
    country_code: "European",
    ax: "752,7",
    ax_global: 75270,
    bf: 409,
    bf_global: 409,
    ct: "12,83",
    ct_global: "128,3",
    d: 1040,
    d_global: 1040,
    ix: 5927,
    ix_global: 59270000,
    iy: 64010,
    iy_global: 640100000,
    iz: 1331040,
    iz_global: 13310400000,
    tf: "55,9",
    tf_global: "55,9",
    tw: 31,
    tw_global: 31,
    zx: 29530,
    zx_global: 29530000,
    zy: 4916,
    zy_global: 4916000,
    width: 409,
    width_global: 409,
    thickness: "55,9",
    thickness_global: "55,9",
    height: 1040,
    height_global: 1040,
  },
  {
    profile_section_id: 2662,
    name: "HL1000x642",
    type: "HL Shape",
    designation: "HL1000x642",
    shape: "I",
    country_code: "European",
    ax: "817,6",
    ax_global: 81760,
    bf: 412,
    bf_global: 412,
    ct: "13,21",
    ct_global: "132,1",
    d: 1048,
    d_global: 1048,
    ix: 7440,
    ix_global: 74400000,
    iy: 70280,
    iy_global: 702800000,
    iz: 1451000,
    iz_global: 14510000000,
    tf: 60,
    tf_global: 60,
    tw: 34,
    tw_global: 34,
    zx: 32100,
    zx_global: 32100000,
    zy: 5379,
    zy_global: 5379000,
    width: 412,
    width_global: 412,
    thickness: 60,
    thickness_global: 60,
    height: 1048,
    height_global: 1048,
  },
  {
    profile_section_id: 2663,
    name: "HL1000x748",
    type: "HL Shape",
    designation: "HL1000x748",
    shape: "I",
    country_code: "European",
    ax: "953,4",
    ax_global: 95340,
    bf: 417,
    bf_global: 417,
    ct: "13,72",
    ct_global: "137,2",
    d: 1068,
    d_global: 1068,
    ix: 11670,
    ix_global: 116700000,
    iy: 85111,
    iy_global: 851110000,
    iz: 1732000,
    iz_global: 17320000000,
    tf: 70,
    tf_global: 70,
    tw: 39,
    tw_global: 39,
    zx: 37880,
    zx_global: 37880000,
    zy: 6459,
    zy_global: 6459000,
    width: 417,
    width_global: 417,
    thickness: 70,
    thickness_global: 70,
    height: 1068,
    height_global: 1068,
  },
  {
    profile_section_id: 2664,
    name: "HL1000x883",
    type: "HL Shape",
    designation: "HL1000x883",
    shape: "I",
    country_code: "European",
    ax: "1125,3",
    ax_global: 112530,
    bf: 424,
    bf_global: 424,
    ct: "14,41",
    ct_global: "144,1",
    d: 1092,
    d_global: 1092,
    ix: 18750,
    ix_global: 187500000,
    iy: 105000,
    iy_global: 1050000000,
    iz: 2096000,
    iz_global: 20960000000,
    tf: 82,
    tf_global: 82,
    tw: "45,5",
    tw_global: "45,5",
    zx: 45260,
    zx_global: 45260000,
    zy: 7874,
    zy_global: 7874000,
    width: 424,
    width_global: 424,
    thickness: 82,
    thickness_global: 82,
    height: 1092,
    height_global: 1092,
  },
  {
    profile_section_id: 2665,
    name: "HL1100A",
    type: "HL Shape",
    designation: "HL1100A",
    shape: "I",
    country_code: "European",
    ax: "436,5",
    ax_global: 43650,
    bf: 400,
    bf_global: 400,
    ct: "13,19",
    ct_global: "131,9",
    d: 1090,
    d_global: 1090,
    ix: 1037,
    ix_global: 10370000,
    iy: 33120,
    iy_global: 331200000,
    iz: 867400,
    iz_global: 8674000000,
    tf: 31,
    tf_global: 31,
    tw: 18,
    tw_global: 18,
    zx: 18060,
    zx_global: 18060000,
    zy: 2568,
    zy_global: 2568000,
    width: 400,
    width_global: 400,
    thickness: 31,
    thickness_global: 31,
    height: 1090,
    height_global: 1090,
  },
  {
    profile_section_id: 2666,
    name: "HL1100B",
    type: "HL Shape",
    designation: "HL1100B",
    shape: "I",
    country_code: "European",
    ax: 497,
    ax_global: 49700,
    bf: 400,
    bf_global: 400,
    ct: "13,25",
    ct_global: "132,5",
    d: 1100,
    d_global: 1100,
    ix: 1564,
    ix_global: 15640000,
    iy: 38480,
    iy_global: 384800000,
    iz: 1005000,
    iz_global: 10050000000,
    tf: 36,
    tf_global: 36,
    tw: 20,
    tw_global: 20,
    zx: 20780,
    zx_global: 20780000,
    zy: 2988,
    zy_global: 2988000,
    width: 400,
    width_global: 400,
    thickness: 36,
    thickness_global: 36,
    height: 1100,
    height_global: 1100,
  },
  {
    profile_section_id: 2667,
    name: "HL1100M",
    type: "HL Shape",
    designation: "HL1100M",
    shape: "I",
    country_code: "European",
    ax: "551,2",
    ax_global: 55120,
    bf: 402,
    bf_global: 402,
    ct: "13,44",
    ct_global: "134,4",
    d: 1108,
    d_global: 1108,
    ix: 2130,
    ix_global: 21300000,
    iy: 43410,
    iy_global: 434100000,
    iz: 1126000,
    iz_global: 11260000000,
    tf: 40,
    tf_global: 40,
    tw: 22,
    tw_global: 22,
    zx: 23160,
    zx_global: 23160000,
    zy: 3362,
    zy_global: 3362000,
    width: 402,
    width_global: 402,
    thickness: 40,
    thickness_global: 40,
    height: 1108,
    height_global: 1108,
  },
  {
    profile_section_id: 2668,
    name: "HL1100R",
    type: "HL Shape",
    designation: "HL1100R",
    shape: "I",
    country_code: "European",
    ax: "635,2",
    ax_global: 63520,
    bf: 405,
    bf_global: 405,
    ct: "14,07",
    ct_global: "140,7",
    d: 1118,
    d_global: 1118,
    ix: 3135,
    ix_global: 31350000,
    iy: 49980,
    iy_global: 499800000,
    iz: 1294000,
    iz_global: 12940000000,
    tf: 45,
    tf_global: 45,
    tw: 26,
    tw_global: 26,
    zx: 26600,
    zx_global: 26600000,
    zy: 3870,
    zy_global: 3870000,
    width: 405,
    width_global: 405,
    thickness: 45,
    thickness_global: 45,
    height: 1118,
    height_global: 1118,
  },
  {
    profile_section_id: 2669,
    name: "IPN160",
    type: "IPN Shape",
    designation: "IPN160",
    shape: "I",
    country_code: "European",
    ax: "22,8",
    ax_global: 2280,
    bf: 74,
    bf_global: 74,
    ct: 2,
    ct_global: 20,
    d: 160,
    d_global: 160,
    ix: "6,6",
    ix_global: 66000,
    iy: 55,
    iy_global: 550000,
    iz: 935,
    iz_global: 9350000,
    tf: "9,5",
    tf_global: "9,5",
    tw: "6,3",
    tw_global: "6,3",
    zx: 136,
    zx_global: 136000,
    zy: 25,
    zy_global: 25000,
    width: 74,
    width_global: 74,
    thickness: "9,5",
    thickness_global: "9,5",
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2670,
    name: "IPN180",
    type: "IPN Shape",
    designation: "IPN180",
    shape: "I",
    country_code: "European",
    ax: "27,9",
    ax_global: 2790,
    bf: 82,
    bf_global: 82,
    ct: "2,3",
    ct_global: 23,
    d: 180,
    d_global: 180,
    ix: "9,6",
    ix_global: 96000,
    iy: 81,
    iy_global: 810000,
    iz: 1450,
    iz_global: 14500000,
    tf: "10,4",
    tf_global: "10,4",
    tw: "6,9",
    tw_global: "6,9",
    zx: 187,
    zx_global: 187000,
    zy: 33,
    zy_global: 33000,
    width: 82,
    width_global: 82,
    thickness: "10,4",
    thickness_global: "10,4",
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 2671,
    name: "IPN200",
    type: "IPN Shape",
    designation: "IPN200",
    shape: "I",
    country_code: "European",
    ax: "33,4",
    ax_global: 3340,
    bf: 90,
    bf_global: 90,
    ct: "2,6",
    ct_global: 26,
    d: 200,
    d_global: 200,
    ix: "13,5",
    ix_global: 135000,
    iy: 117,
    iy_global: 1170000,
    iz: 2140,
    iz_global: 21400000,
    tf: "11,3",
    tf_global: "11,3",
    tw: "7,5",
    tw_global: "7,5",
    zx: 250,
    zx_global: 250000,
    zy: 44,
    zy_global: 44000,
    width: 90,
    width_global: 90,
    thickness: "11,3",
    thickness_global: "11,3",
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2672,
    name: "IPN220",
    type: "IPN Shape",
    designation: "IPN220",
    shape: "I",
    country_code: "European",
    ax: "39,5",
    ax_global: 3950,
    bf: 98,
    bf_global: 98,
    ct: "2,8",
    ct_global: 28,
    d: 220,
    d_global: 220,
    ix: "18,6",
    ix_global: 186000,
    iy: 162,
    iy_global: 1620000,
    iz: 3060,
    iz_global: 30600000,
    tf: "12,2",
    tf_global: "12,2",
    tw: "8,1",
    tw_global: "8,1",
    zx: 324,
    zx_global: 324000,
    zy: 56,
    zy_global: 56000,
    width: 98,
    width_global: 98,
    thickness: "12,2",
    thickness_global: "12,2",
    height: 220,
    height_global: 220,
  },
  {
    profile_section_id: 2673,
    name: "IPN240",
    type: "IPN Shape",
    designation: "IPN240",
    shape: "I",
    country_code: "European",
    ax: "46,1",
    ax_global: 4610,
    bf: 106,
    bf_global: 106,
    ct: "3,1",
    ct_global: 31,
    d: 240,
    d_global: 240,
    ix: 25,
    ix_global: 250000,
    iy: 221,
    iy_global: 2210000,
    iz: 4250,
    iz_global: 42500000,
    tf: "13,1",
    tf_global: "13,1",
    tw: "8,7",
    tw_global: "8,7",
    zx: 412,
    zx_global: 412000,
    zy: 70,
    zy_global: 70000,
    width: 106,
    width_global: 106,
    thickness: "13,1",
    thickness_global: "13,1",
    height: 240,
    height_global: 240,
  },
  {
    profile_section_id: 2674,
    name: "IPN260",
    type: "IPN Shape",
    designation: "IPN260",
    shape: "I",
    country_code: "European",
    ax: "53,3",
    ax_global: 5330,
    bf: 113,
    bf_global: 113,
    ct: "3,4",
    ct_global: 34,
    d: 260,
    d_global: 260,
    ix: "33,5",
    ix_global: 335000,
    iy: 288,
    iy_global: 2880000,
    iz: 5740,
    iz_global: 57400000,
    tf: "14,1",
    tf_global: "14,1",
    tw: "9,4",
    tw_global: "9,4",
    zx: 514,
    zx_global: 514000,
    zy: 86,
    zy_global: 86000,
    width: 113,
    width_global: 113,
    thickness: "14,1",
    thickness_global: "14,1",
    height: 260,
    height_global: 260,
  },
  {
    profile_section_id: 2675,
    name: "IPN280",
    type: "IPN Shape",
    designation: "IPN280",
    shape: "I",
    country_code: "European",
    ax: 61,
    ax_global: 6100,
    bf: 119,
    bf_global: 119,
    ct: "3,7",
    ct_global: 37,
    d: 280,
    d_global: 280,
    ix: "44,2",
    ix_global: 442000,
    iy: 364,
    iy_global: 3640000,
    iz: 7590,
    iz_global: 75900000,
    tf: "15,2",
    tf_global: "15,2",
    tw: "10,1",
    tw_global: "10,1",
    zx: 632,
    zx_global: 632000,
    zy: 103,
    zy_global: 103000,
    width: 119,
    width_global: 119,
    thickness: "15,2",
    thickness_global: "15,2",
    height: 280,
    height_global: 280,
  },
  {
    profile_section_id: 2676,
    name: "IPN300",
    type: "IPN Shape",
    designation: "IPN300",
    shape: "I",
    country_code: "European",
    ax: 69,
    ax_global: 6900,
    bf: 125,
    bf_global: 125,
    ct: 4,
    ct_global: 40,
    d: 300,
    d_global: 300,
    ix: "56,8",
    ix_global: 568000,
    iy: 451,
    iy_global: 4510000,
    iz: 9800,
    iz_global: 98000000,
    tf: "16,2",
    tf_global: "16,2",
    tw: "10,8",
    tw_global: "10,8",
    zx: 762,
    zx_global: 762000,
    zy: 121,
    zy_global: 121000,
    width: 125,
    width_global: 125,
    thickness: "16,2",
    thickness_global: "16,2",
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 2677,
    name: "IPN320",
    type: "IPN Shape",
    designation: "IPN320",
    shape: "I",
    country_code: "European",
    ax: "77,7",
    ax_global: 7770,
    bf: 131,
    bf_global: 131,
    ct: "4,3",
    ct_global: 43,
    d: 320,
    d_global: 320,
    ix: "72,5",
    ix_global: 725000,
    iy: 555,
    iy_global: 5550000,
    iz: 12510,
    iz_global: 125100000,
    tf: "17,3",
    tf_global: "17,3",
    tw: "11,5",
    tw_global: "11,5",
    zx: 914,
    zx_global: 914000,
    zy: 143,
    zy_global: 143000,
    width: 131,
    width_global: 131,
    thickness: "17,3",
    thickness_global: "17,3",
    height: 320,
    height_global: 320,
  },
  {
    profile_section_id: 2678,
    name: "IPN340",
    type: "IPN Shape",
    designation: "IPN340",
    shape: "I",
    country_code: "European",
    ax: "86,7",
    ax_global: 8670,
    bf: 137,
    bf_global: 137,
    ct: "4,6",
    ct_global: 46,
    d: 340,
    d_global: 340,
    ix: "90,4",
    ix_global: 904000,
    iy: 674,
    iy_global: 6740000,
    iz: 15700,
    iz_global: 157000000,
    tf: "18,3",
    tf_global: "18,3",
    tw: "12,2",
    tw_global: "12,2",
    zx: 1080,
    zx_global: 1080000,
    zy: 166,
    zy_global: 166000,
    width: 137,
    width_global: 137,
    thickness: "18,3",
    thickness_global: "18,3",
    height: 340,
    height_global: 340,
  },
  {
    profile_section_id: 2679,
    name: "IPN360",
    type: "IPN Shape",
    designation: "IPN360",
    shape: "I",
    country_code: "European",
    ax: 97,
    ax_global: 9700,
    bf: 143,
    bf_global: 143,
    ct: "4,9",
    ct_global: 49,
    d: 360,
    d_global: 360,
    ix: 115,
    ix_global: 1150000,
    iy: 818,
    iy_global: 8180000,
    iz: 19610,
    iz_global: 196100000,
    tf: "19,5",
    tf_global: "19,5",
    tw: 13,
    tw_global: 13,
    zx: 1276,
    zx_global: 1276000,
    zy: 194,
    zy_global: 194000,
    width: 143,
    width_global: 143,
    thickness: "19,5",
    thickness_global: "19,5",
    height: 360,
    height_global: 360,
  },
  {
    profile_section_id: 2680,
    name: "IPN380",
    type: "IPN Shape",
    designation: "IPN380",
    shape: "I",
    country_code: "European",
    ax: 107,
    ax_global: 10700,
    bf: 149,
    bf_global: 149,
    ct: "5,2",
    ct_global: 52,
    d: 380,
    d_global: 380,
    ix: 141,
    ix_global: 1410000,
    iy: 975,
    iy_global: 9750000,
    iz: 24010,
    iz_global: 240100000,
    tf: "20,5",
    tf_global: "20,5",
    tw: "13,7",
    tw_global: "13,7",
    zx: 1482,
    zx_global: 1482000,
    zy: 221,
    zy_global: 221000,
    width: 149,
    width_global: 149,
    thickness: "20,5",
    thickness_global: "20,5",
    height: 380,
    height_global: 380,
  },
  {
    profile_section_id: 2681,
    name: "IPN400",
    type: "IPN Shape",
    designation: "IPN400",
    shape: "I",
    country_code: "European",
    ax: 118,
    ax_global: 11800,
    bf: 155,
    bf_global: 155,
    ct: "5,5",
    ct_global: 55,
    d: 400,
    d_global: 400,
    ix: 170,
    ix_global: 1700000,
    iy: 1160,
    iy_global: 11600000,
    iz: 29210,
    iz_global: 292100000,
    tf: "21,6",
    tf_global: "21,6",
    tw: "14,4",
    tw_global: "14,4",
    zx: 1714,
    zx_global: 1714000,
    zy: 253,
    zy_global: 253000,
    width: 155,
    width_global: 155,
    thickness: "21,6",
    thickness_global: "21,6",
    height: 400,
    height_global: 400,
  },
  {
    profile_section_id: 2682,
    name: "IPN450",
    type: "IPN Shape",
    designation: "IPN450",
    shape: "I",
    country_code: "European",
    ax: 147,
    ax_global: 14700,
    bf: 170,
    bf_global: 170,
    ct: "6,2",
    ct_global: 62,
    d: 450,
    d_global: 450,
    ix: 267,
    ix_global: 2670000,
    iy: 1730,
    iy_global: 17300000,
    iz: 45850,
    iz_global: 458500000,
    tf: "24,3",
    tf_global: "24,3",
    tw: "16,2",
    tw_global: "16,2",
    zx: 2400,
    zx_global: 2400000,
    zy: 345,
    zy_global: 345000,
    width: 170,
    width_global: 170,
    thickness: "24,3",
    thickness_global: "24,3",
    height: 450,
    height_global: 450,
  },
  {
    profile_section_id: 2683,
    name: "IPN500",
    type: "IPN Shape",
    designation: "IPN500",
    shape: "I",
    country_code: "European",
    ax: 179,
    ax_global: 17900,
    bf: 185,
    bf_global: 185,
    ct: 7,
    ct_global: 70,
    d: 500,
    d_global: 500,
    ix: 402,
    ix_global: 4020000,
    iy: 2480,
    iy_global: 24800000,
    iz: 68740,
    iz_global: 687400000,
    tf: 27,
    tf_global: 27,
    tw: 18,
    tw_global: 18,
    zx: 3240,
    zx_global: 3240000,
    zy: 456,
    zy_global: 456000,
    width: 185,
    width_global: 185,
    thickness: 27,
    thickness_global: 27,
    height: 500,
    height_global: 500,
  },
  {
    profile_section_id: 2684,
    name: "IPN550",
    type: "IPN Shape",
    designation: "IPN550",
    shape: "I",
    country_code: "European",
    ax: 212,
    ax_global: 21200,
    bf: 200,
    bf_global: 200,
    ct: "7,6",
    ct_global: 76,
    d: 550,
    d_global: 550,
    ix: 544,
    ix_global: 5440000,
    iy: 3490,
    iy_global: 34900000,
    iz: 99180,
    iz_global: 991800000,
    tf: 30,
    tf_global: 30,
    tw: 19,
    tw_global: 19,
    zx: 4240,
    zx_global: 4240000,
    zy: 592,
    zy_global: 592000,
    width: 200,
    width_global: 200,
    thickness: 30,
    thickness_global: 30,
    height: 550,
    height_global: 550,
  },
  {
    profile_section_id: 2685,
    name: "IPN600",
    type: "IPN Shape",
    designation: "IPN600",
    shape: "I",
    country_code: "European",
    ax: 254,
    ax_global: 25400,
    bf: 215,
    bf_global: 215,
    ct: "8,4",
    ct_global: 84,
    d: 600,
    d_global: 600,
    ix: 787,
    ix_global: 7870000,
    iy: 4674,
    iy_global: 46740000,
    iz: 138800,
    iz_global: 1388000000,
    tf: "32,4",
    tf_global: "32,4",
    tw: "21,6",
    tw_global: "21,6",
    zx: 5452,
    zx_global: 5452000,
    zy: 752,
    zy_global: 752000,
    width: 215,
    width_global: 215,
    thickness: "32,4",
    thickness_global: "32,4",
    height: 600,
    height_global: 600,
  },
  {
    profile_section_id: 2686,
    name: "IPN80",
    type: "IPN Shape",
    designation: "IPN80",
    shape: "I",
    country_code: "European",
    ax: "7,58",
    ax_global: 758,
    bf: 42,
    bf_global: 42,
    ct: 1,
    ct_global: 10,
    d: 80,
    d_global: 80,
    ix: "0,87",
    ix_global: 8700,
    iy: "6,29",
    iy_global: 62900,
    iz: "77,8",
    iz_global: 778000,
    tf: "5,9",
    tf_global: "5,9",
    tw: "3,9",
    tw_global: "3,9",
    zx: "22,8",
    zx_global: 22800,
    zy: 5,
    zy_global: 5000,
    width: 42,
    width_global: 42,
    thickness: "5,9",
    thickness_global: "5,9",
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2687,
    name: "IPN100",
    type: "IPN Shape",
    designation: "IPN100",
    shape: "I",
    country_code: "European",
    ax: "10,6",
    ax_global: 1060,
    bf: 50,
    bf_global: 50,
    ct: "1,2",
    ct_global: 12,
    d: 100,
    d_global: 100,
    ix: "1,6",
    ix_global: 16000,
    iy: "12,2",
    iy_global: 122000,
    iz: 171,
    iz_global: 1710000,
    tf: "6,8",
    tf_global: "6,8",
    tw: "4,5",
    tw_global: "4,5",
    zx: "39,8",
    zx_global: 39800,
    zy: "8,1",
    zy_global: 8100,
    width: 50,
    width_global: 50,
    thickness: "6,8",
    thickness_global: "6,8",
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2688,
    name: "IPN120",
    type: "IPN Shape",
    designation: "IPN120",
    shape: "I",
    country_code: "European",
    ax: "14,2",
    ax_global: 1420,
    bf: 58,
    bf_global: 58,
    ct: "1,5",
    ct_global: 15,
    d: 120,
    d_global: 120,
    ix: "2,71",
    ix_global: 27100,
    iy: "21,5",
    iy_global: 215000,
    iz: 328,
    iz_global: 3280000,
    tf: "7,7",
    tf_global: "7,7",
    tw: "5,1",
    tw_global: "5,1",
    zx: "63,6",
    zx_global: 63600,
    zy: "12,4",
    zy_global: 12400,
    width: 58,
    width_global: 58,
    thickness: "7,7",
    thickness_global: "7,7",
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2689,
    name: "IPN140",
    type: "IPN Shape",
    designation: "IPN140",
    shape: "I",
    country_code: "European",
    ax: "18,3",
    ax_global: 1830,
    bf: 66,
    bf_global: 66,
    ct: "1,8",
    ct_global: 18,
    d: 140,
    d_global: 140,
    ix: "4,32",
    ix_global: 43200,
    iy: "35,2",
    iy_global: 352000,
    iz: 573,
    iz_global: 5730000,
    tf: "8,6",
    tf_global: "8,6",
    tw: "5,7",
    tw_global: "5,7",
    zx: "95,4",
    zx_global: 95400,
    zy: "17,9",
    zy_global: 17900,
    width: 66,
    width_global: 66,
    thickness: "8,6",
    thickness_global: "8,6",
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 2690,
    name: "DIL100",
    type: "DIL Shape",
    designation: "DIL100",
    shape: "I",
    country_code: "European",
    ax: "25,2",
    ax_global: 2520,
    bf: 100,
    bf_global: 100,
    ct: "0,9",
    ct_global: 9,
    d: 100,
    d_global: 100,
    ix: 167,
    ix_global: 1670000,
    iy: "8,4",
    iy_global: 84000,
    iz: 445,
    iz_global: 4450000,
    tf: 10,
    tf_global: 10,
    tw: 5,
    tw_global: 5,
    zx: 103,
    zx_global: 103000,
    zy: 51,
    zy_global: 51000,
    width: 100,
    width_global: 100,
    thickness: 10,
    thickness_global: 10,
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2691,
    name: "DIL120",
    type: "DIL Shape",
    designation: "DIL120",
    shape: "I",
    country_code: "European",
    ax: "32,5",
    ax_global: 3250,
    bf: 120,
    bf_global: 120,
    ct: 1,
    ct_global: 10,
    d: 120,
    d_global: 120,
    ix: 317,
    ix_global: 3170000,
    iy: "12,4",
    iy_global: 124000,
    iz: 853,
    iz_global: 8530000,
    tf: 11,
    tf_global: 11,
    tw: 5,
    tw_global: 5,
    zx: 162,
    zx_global: 162000,
    zy: 81,
    zy_global: 81000,
    width: 120,
    width_global: 120,
    thickness: 11,
    thickness_global: 11,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2692,
    name: "DIL140",
    type: "DIL Shape",
    designation: "DIL140",
    shape: "I",
    country_code: "European",
    ax: "40,6",
    ax_global: 4060,
    bf: 140,
    bf_global: 140,
    ct: "1,1",
    ct_global: 11,
    d: 140,
    d_global: 140,
    ix: 549,
    ix_global: 5490000,
    iy: "17,8",
    iy_global: 178000,
    iz: 1483,
    iz_global: 14830000,
    tf: 12,
    tf_global: 12,
    tw: 5,
    tw_global: 5,
    zx: 239,
    zx_global: 239000,
    zy: 119,
    zy_global: 119000,
    width: 140,
    width_global: 140,
    thickness: 12,
    thickness_global: 12,
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 2693,
    name: "DIL160",
    type: "DIL Shape",
    designation: "DIL160",
    shape: "I",
    country_code: "European",
    ax: "50,2",
    ax_global: 5020,
    bf: 160,
    bf_global: 160,
    ct: "1,2",
    ct_global: 12,
    d: 160,
    d_global: 160,
    ix: 888,
    ix_global: 8880000,
    iy: "26,1",
    iy_global: 261000,
    iz: 2432,
    iz_global: 24320000,
    tf: 13,
    tf_global: 13,
    tw: 5,
    tw_global: 5,
    zx: 340,
    zx_global: 340000,
    zy: 168,
    zy_global: 168000,
    width: 160,
    width_global: 160,
    thickness: 13,
    thickness_global: 13,
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2694,
    name: "DIL180",
    type: "DIL Shape",
    designation: "DIL180",
    shape: "I",
    country_code: "European",
    ax: "60,7",
    ax_global: 6070,
    bf: 180,
    bf_global: 180,
    ct: "1,4",
    ct_global: 14,
    d: 180,
    d_global: 180,
    ix: 1362,
    ix_global: 13620000,
    iy: "36,1",
    iy_global: 361000,
    iz: 3743,
    iz_global: 37430000,
    tf: 14,
    tf_global: 14,
    tw: "5,5",
    tw_global: "5,5",
    zx: 464,
    zx_global: 464000,
    zy: 229,
    zy_global: 229000,
    width: 180,
    width_global: 180,
    thickness: 14,
    thickness_global: 14,
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 2695,
    name: "DIL200",
    type: "DIL Shape",
    designation: "DIL200",
    shape: "I",
    country_code: "European",
    ax: 73,
    ax_global: 7300,
    bf: 200,
    bf_global: 200,
    ct: "1,5",
    ct_global: 15,
    d: 200,
    d_global: 200,
    ix: 2002,
    ix_global: 20020000,
    iy: "50,7",
    iy_global: 507000,
    iz: 5573,
    iz_global: 55730000,
    tf: 15,
    tf_global: 15,
    tw: 6,
    tw_global: 6,
    zx: 621,
    zx_global: 621000,
    zy: 303,
    zy_global: 303000,
    width: 200,
    width_global: 200,
    thickness: 15,
    thickness_global: 15,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2696,
    name: "DIL220",
    type: "DIL Shape",
    designation: "DIL220",
    shape: "I",
    country_code: "European",
    ax: "85,4",
    ax_global: 8540,
    bf: 220,
    bf_global: 220,
    ct: "1,6",
    ct_global: 16,
    d: 220,
    d_global: 220,
    ix: 2842,
    ix_global: 28420000,
    iy: "66,8",
    iy_global: 668000,
    iz: 7925,
    iz_global: 79250000,
    tf: 16,
    tf_global: 16,
    tw: "6,5",
    tw_global: "6,5",
    zx: 801,
    zx_global: 801000,
    zy: 391,
    zy_global: 391000,
    width: 220,
    width_global: 220,
    thickness: 16,
    thickness_global: 16,
    height: 220,
    height_global: 220,
  },
  {
    profile_section_id: 2697,
    name: "DIL240",
    type: "DIL Shape",
    designation: "DIL240",
    shape: "I",
    country_code: "European",
    ax: "99,8",
    ax_global: 9980,
    bf: 240,
    bf_global: 240,
    ct: "1,8",
    ct_global: 18,
    d: 240,
    d_global: 240,
    ix: 3921,
    ix_global: 39210000,
    iy: "89,5",
    iy_global: 895000,
    iz: 11040,
    iz_global: 110400000,
    tf: 17,
    tf_global: 17,
    tw: 7,
    tw_global: 7,
    zx: 1021,
    zx_global: 1021000,
    zy: 495,
    zy_global: 495000,
    width: 240,
    width_global: 240,
    thickness: 17,
    thickness_global: 17,
    height: 240,
    height_global: 240,
  },
  {
    profile_section_id: 2698,
    name: "DIL280",
    type: "DIL Shape",
    designation: "DIL280",
    shape: "I",
    country_code: "European",
    ax: 125,
    ax_global: 12500,
    bf: 280,
    bf_global: 280,
    ct: "2,1",
    ct_global: 21,
    d: 280,
    d_global: 280,
    ix: 6592,
    ix_global: 65920000,
    iy: 128,
    iy_global: 1280000,
    iz: 18970,
    iz_global: 189700000,
    tf: 18,
    tf_global: 18,
    tw: 8,
    tw_global: 8,
    zx: 1497,
    zx_global: 1497000,
    zy: 714,
    zy_global: 714000,
    width: 280,
    width_global: 280,
    thickness: 18,
    thickness_global: 18,
    height: 280,
    height_global: 280,
  },
  {
    profile_section_id: 2699,
    name: "DIL300",
    type: "DIL Shape",
    designation: "DIL300",
    shape: "I",
    country_code: "European",
    ax: 143,
    ax_global: 14300,
    bf: 300,
    bf_global: 300,
    ct: "2,2",
    ct_global: 22,
    d: 300,
    d_global: 300,
    ix: 8560,
    ix_global: 85600000,
    iy: 165,
    iy_global: 1650000,
    iz: 24790,
    iz_global: 247900000,
    tf: 19,
    tf_global: 19,
    tw: "8,5",
    tw_global: "8,5",
    zx: 1826,
    zx_global: 1826000,
    zy: 866,
    zy_global: 866000,
    width: 300,
    width_global: 300,
    thickness: 19,
    thickness_global: 19,
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 2700,
    name: "DIL340",
    type: "DIL Shape",
    designation: "DIL340",
    shape: "I",
    country_code: "European",
    ax: 163,
    ax_global: 16300,
    bf: 300,
    bf_global: 300,
    ct: "2,6",
    ct_global: 26,
    d: 340,
    d_global: 340,
    ix: 9686,
    ix_global: 96860000,
    iy: 234,
    iy_global: 2340000,
    iz: 36110,
    iz_global: 361100000,
    tf: "21,5",
    tf_global: "21,5",
    tw: "9,5",
    tw_global: "9,5",
    zx: 2353,
    zx_global: 2353000,
    zy: 981,
    zy_global: 981000,
    width: 300,
    width_global: 300,
    thickness: "21,5",
    thickness_global: "21,5",
    height: 340,
    height_global: 340,
  },
  {
    profile_section_id: 2701,
    name: "DIL360",
    type: "DIL Shape",
    designation: "DIL360",
    shape: "I",
    country_code: "European",
    ax: 173,
    ax_global: 17300,
    bf: 300,
    bf_global: 300,
    ct: "2,8",
    ct_global: 28,
    d: 360,
    d_global: 360,
    ix: 10140,
    ix_global: 101400000,
    iy: 267,
    iy_global: 2670000,
    iz: 42540,
    iz_global: 425400000,
    tf: "22,5",
    tf_global: "22,5",
    tw: 10,
    tw_global: 10,
    zx: 2621,
    zx_global: 2621000,
    zy: 1027,
    zy_global: 1027000,
    width: 300,
    width_global: 300,
    thickness: "22,5",
    thickness_global: "22,5",
    height: 360,
    height_global: 360,
  },
  {
    profile_section_id: 2702,
    name: "DIL400",
    type: "DIL Shape",
    designation: "DIL400",
    shape: "I",
    country_code: "European",
    ax: 189,
    ax_global: 18900,
    bf: 300,
    bf_global: 300,
    ct: "3,3",
    ct_global: 33,
    d: 400,
    d_global: 400,
    ix: 10810,
    ix_global: 108100000,
    iy: 325,
    iy_global: 3250000,
    iz: 56770,
    iz_global: 567700000,
    tf: 24,
    tf_global: 24,
    tw: 11,
    tw_global: 11,
    zx: 3154,
    zx_global: 3154000,
    zy: 1098,
    zy_global: 1098000,
    width: 300,
    width_global: 300,
    thickness: 24,
    thickness_global: 24,
    height: 400,
    height_global: 400,
  },
  {
    profile_section_id: 2703,
    name: "DIL450",
    type: "DIL Shape",
    designation: "DIL450",
    shape: "I",
    country_code: "European",
    ax: 208,
    ax_global: 20800,
    bf: 300,
    bf_global: 300,
    ct: "3,8",
    ct_global: 38,
    d: 450,
    d_global: 450,
    ix: 11720,
    ix_global: 117200000,
    iy: 406,
    iy_global: 4060000,
    iz: 78570,
    iz_global: 785700000,
    tf: 26,
    tf_global: 26,
    tw: "11,5",
    tw_global: "11,5",
    zx: 3883,
    zx_global: 3883000,
    zy: 1191,
    zy_global: 1191000,
    width: 300,
    width_global: 300,
    thickness: 26,
    thickness_global: 26,
    height: 450,
    height_global: 450,
  },
  {
    profile_section_id: 2704,
    name: "DIL500",
    type: "DIL Shape",
    designation: "DIL500",
    shape: "I",
    country_code: "European",
    ax: 228,
    ax_global: 22800,
    bf: 300,
    bf_global: 300,
    ct: "4,4",
    ct_global: 44,
    d: 500,
    d_global: 500,
    ix: 12620,
    ix_global: 126200000,
    iy: 499,
    iy_global: 4990000,
    iz: 105400,
    iz_global: 1054000000,
    tf: 28,
    tf_global: 28,
    tw: 12,
    tw_global: 12,
    zx: 4691,
    zx_global: 4691000,
    zy: 1284,
    zy_global: 1284000,
    width: 300,
    width_global: 300,
    thickness: 28,
    thickness_global: 28,
    height: 500,
    height_global: 500,
  },
  {
    profile_section_id: 2705,
    name: "DIL550",
    type: "DIL Shape",
    designation: "DIL550",
    shape: "I",
    country_code: "European",
    ax: 242,
    ax_global: 24200,
    bf: 300,
    bf_global: 300,
    ct: 5,
    ct_global: 50,
    d: 550,
    d_global: 550,
    ix: 13070,
    ix_global: 130700000,
    iy: 556,
    iy_global: 5560000,
    iz: 134200,
    iz_global: 1342000000,
    tf: 29,
    tf_global: 29,
    tw: "12,5",
    tw_global: "12,5",
    zx: 5439,
    zx_global: 5439000,
    zy: 1332,
    zy_global: 1332000,
    width: 300,
    width_global: 300,
    thickness: 29,
    thickness_global: 29,
    height: 550,
    height_global: 550,
  },
  {
    profile_section_id: 2706,
    name: "DIL600",
    type: "DIL Shape",
    designation: "DIL600",
    shape: "I",
    country_code: "European",
    ax: 256,
    ax_global: 25600,
    bf: 300,
    bf_global: 300,
    ct: "5,7",
    ct_global: 57,
    d: 600,
    d_global: 600,
    ix: 13520,
    ix_global: 135200000,
    iy: 618,
    iy_global: 6180000,
    iz: 167800,
    iz_global: 1678000000,
    tf: 30,
    tf_global: 30,
    tw: 13,
    tw_global: 13,
    zx: 6243,
    zx_global: 6243000,
    zy: 1381,
    zy_global: 1381000,
    width: 300,
    width_global: 300,
    thickness: 30,
    thickness_global: 30,
    height: 600,
    height_global: 600,
  },
  {
    profile_section_id: 2707,
    name: "UPE80",
    type: "UPE Channel",
    designation: "UPE80",
    shape: "C",
    country_code: "European",
    ax: "10,1",
    ax_global: 1010,
    bf: 50,
    bf_global: 50,
    ct: "0,9",
    ct_global: 9,
    d: 80,
    d_global: 80,
    ix: "1,47",
    ix_global: 14700,
    iy: "25,41",
    iy_global: 254100,
    iz: "107,2",
    iz_global: 1072000,
    tf: 7,
    tf_global: 7,
    tw: 4,
    tw_global: 4,
    zx: "31,23",
    zx_global: 31230,
    zy: "14,28",
    zy_global: 14280,
    width: 50,
    width_global: 50,
    thickness: 7,
    thickness_global: 7,
    height: 80,
    height_global: 80,
  },
  {
    profile_section_id: 2708,
    name: "UPE100",
    type: "UPE Channel",
    designation: "UPE100",
    shape: "C",
    country_code: "European",
    ax: "12,5",
    ax_global: 1250,
    bf: 55,
    bf_global: 55,
    ct: "1,17",
    ct_global: "11,7",
    d: 100,
    d_global: 100,
    ix: "2,01",
    ix_global: 20100,
    iy: "38,21",
    iy_global: 382100,
    iz: "206,9",
    iz_global: 2069000,
    tf: "7,5",
    tf_global: "7,5",
    tw: "4,5",
    tw_global: "4,5",
    zx: "48,01",
    zx_global: 48010,
    zy: "19,34",
    zy_global: 19340,
    width: 55,
    width_global: 55,
    thickness: "7,5",
    thickness_global: "7,5",
    height: 100,
    height_global: 100,
  },
  {
    profile_section_id: 2709,
    name: "UPE120",
    type: "UPE Channel",
    designation: "UPE120",
    shape: "C",
    country_code: "European",
    ax: "15,4",
    ax_global: 1540,
    bf: 60,
    bf_global: 60,
    ct: "1,45",
    ct_global: "14,5",
    d: 120,
    d_global: 120,
    ix: "2,9",
    ix_global: 29000,
    iy: "55,4",
    iy_global: 554000,
    iz: "363,5",
    iz_global: 3635000,
    tf: 8,
    tf_global: 8,
    tw: 5,
    tw_global: 5,
    zx: "70,33",
    zx_global: 70330,
    zy: "25,28",
    zy_global: 25280,
    width: 60,
    width_global: 60,
    thickness: 8,
    thickness_global: 8,
    height: 120,
    height_global: 120,
  },
  {
    profile_section_id: 2710,
    name: "UPE140",
    type: "UPE Channel",
    designation: "UPE140",
    shape: "C",
    country_code: "European",
    ax: "18,4",
    ax_global: 1840,
    bf: 65,
    bf_global: 65,
    ct: "1,65",
    ct_global: "16,5",
    d: 140,
    d_global: 140,
    ix: "4,05",
    ix_global: 40500,
    iy: "78,7",
    iy_global: 787000,
    iz: "599,5",
    iz_global: 5995000,
    tf: 9,
    tf_global: 9,
    tw: 5,
    tw_global: 5,
    zx: "98,84",
    zx_global: 98840,
    zy: "33,22",
    zy_global: 33220,
    width: 65,
    width_global: 65,
    thickness: 9,
    thickness_global: 9,
    height: 140,
    height_global: 140,
  },
  {
    profile_section_id: 2711,
    name: "UPE160",
    type: "UPE Channel",
    designation: "UPE160",
    shape: "C",
    country_code: "European",
    ax: "21,7",
    ax_global: 2170,
    bf: 70,
    bf_global: 70,
    ct: "1,95",
    ct_global: "19,5",
    d: 160,
    d_global: 160,
    ix: "5,2",
    ix_global: 52000,
    iy: "106,8",
    iy_global: 1068000,
    iz: "911,1",
    iz_global: 9111000,
    tf: "9,5",
    tf_global: "9,5",
    tw: "5,5",
    tw_global: "5,5",
    zx: "131,6",
    zx_global: 131600,
    zy: "41,49",
    zy_global: 41490,
    width: 70,
    width_global: 70,
    thickness: "9,5",
    thickness_global: "9,5",
    height: 160,
    height_global: 160,
  },
  {
    profile_section_id: 2712,
    name: "UPE180",
    type: "UPE Channel",
    designation: "UPE180",
    shape: "C",
    country_code: "European",
    ax: "25,1",
    ax_global: 2510,
    bf: 75,
    bf_global: 75,
    ct: "2,13",
    ct_global: "21,3",
    d: 180,
    d_global: 180,
    ix: "6,99",
    ix_global: 69900,
    iy: "143,7",
    iy_global: 1437000,
    iz: 1353,
    iz_global: 13530000,
    tf: "10,5",
    tf_global: "10,5",
    tw: "5,5",
    tw_global: "5,5",
    zx: 173,
    zx_global: 173000,
    zy: "52,3",
    zy_global: 52300,
    width: 75,
    width_global: 75,
    thickness: "10,5",
    thickness_global: "10,5",
    height: 180,
    height_global: 180,
  },
  {
    profile_section_id: 2713,
    name: "UPE200",
    type: "UPE Channel",
    designation: "UPE200",
    shape: "C",
    country_code: "European",
    ax: 29,
    ax_global: 2900,
    bf: 80,
    bf_global: 80,
    ct: "2,44",
    ct_global: "24,4",
    d: 200,
    d_global: 200,
    ix: "8,89",
    ix_global: 88900,
    iy: "187,3",
    iy_global: 1873000,
    iz: 1909,
    iz_global: 19090000,
    tf: 11,
    tf_global: 11,
    tw: 6,
    tw_global: 6,
    zx: "220,1",
    zx_global: 220100,
    zy: "63,28",
    zy_global: 63280,
    width: 80,
    width_global: 80,
    thickness: 11,
    thickness_global: 11,
    height: 200,
    height_global: 200,
  },
  {
    profile_section_id: 2714,
    name: "UPE220",
    type: "UPE Channel",
    designation: "UPE220",
    shape: "C",
    country_code: "European",
    ax: "33,9",
    ax_global: 3390,
    bf: 85,
    bf_global: 85,
    ct: "2,71",
    ct_global: "27,1",
    d: 220,
    d_global: 220,
    ix: "12,05",
    ix_global: 120500,
    iy: "246,4",
    iy_global: 2464000,
    iz: 2682,
    iz_global: 26820000,
    tf: 12,
    tf_global: 12,
    tw: "6,5",
    tw_global: "6,5",
    zx: "281,5",
    zx_global: 281500,
    zy: "78,25",
    zy_global: 78250,
    width: 85,
    width_global: 85,
    thickness: 12,
    thickness_global: 12,
    height: 220,
    height_global: 220,
  },
  {
    profile_section_id: 2715,
    name: "UPE240",
    type: "UPE Channel",
    designation: "UPE240",
    shape: "C",
    country_code: "European",
    ax: "38,5",
    ax_global: 3850,
    bf: 90,
    bf_global: 90,
    ct: "3,03",
    ct_global: "30,3",
    d: 240,
    d_global: 240,
    ix: "15,14",
    ix_global: 151400,
    iy: "310,9",
    iy_global: 3109000,
    iz: 3599,
    iz_global: 35990000,
    tf: "12,5",
    tf_global: "12,5",
    tw: 7,
    tw_global: 7,
    zx: "346,9",
    zx_global: 346900,
    zy: "92,18",
    zy_global: 92180,
    width: 90,
    width_global: 90,
    thickness: "12,5",
    thickness_global: "12,5",
    height: 240,
    height_global: 240,
  },
  {
    profile_section_id: 2716,
    name: "UPE270",
    type: "UPE Channel",
    designation: "UPE270",
    shape: "C",
    country_code: "European",
    ax: "44,8",
    ax_global: 4480,
    bf: 95,
    bf_global: 95,
    ct: "3,48",
    ct_global: "34,8",
    d: 270,
    d_global: 270,
    ix: "19,91",
    ix_global: 199100,
    iy: 401,
    iy_global: 4010000,
    iz: 5255,
    iz_global: 52550000,
    tf: "13,5",
    tf_global: "13,5",
    tw: "7,5",
    tw_global: "7,5",
    zx: "451,1",
    zx_global: 451100,
    zy: "111,6",
    zy_global: 111600,
    width: 95,
    width_global: 95,
    thickness: "13,5",
    thickness_global: "13,5",
    height: 270,
    height_global: 270,
  },
  {
    profile_section_id: 2717,
    name: "UPE300",
    type: "UPE Channel",
    designation: "UPE300",
    shape: "C",
    country_code: "European",
    ax: "56,6",
    ax_global: 5660,
    bf: 100,
    bf_global: 100,
    ct: "4,21",
    ct_global: "42,1",
    d: 300,
    d_global: 300,
    ix: "31,52",
    ix_global: 315200,
    iy: "537,7",
    iy_global: 5377000,
    iz: 7823,
    iz_global: 78230000,
    tf: 15,
    tf_global: 15,
    tw: "9,5",
    tw_global: "9,5",
    zx: "613,4",
    zx_global: 613400,
    zy: "136,6",
    zy_global: 136600,
    width: 100,
    width_global: 100,
    thickness: 15,
    thickness_global: 15,
    height: 300,
    height_global: 300,
  },
  {
    profile_section_id: 2718,
    name: "UPE330",
    type: "UPE Channel",
    designation: "UPE330",
    shape: "C",
    country_code: "European",
    ax: "67,8",
    ax_global: 6780,
    bf: 105,
    bf_global: 105,
    ct: "4,87",
    ct_global: "48,7",
    d: 330,
    d_global: 330,
    ix: "45,18",
    ix_global: 451800,
    iy: "681,5",
    iy_global: 6815000,
    iz: 11010,
    iz_global: 110100000,
    tf: 16,
    tf_global: 16,
    tw: 11,
    tw_global: 11,
    zx: "791,9",
    zx_global: 791900,
    zy: "156,2",
    zy_global: 156200,
    width: 105,
    width_global: 105,
    thickness: 16,
    thickness_global: 16,
    height: 330,
    height_global: 330,
  },
  {
    profile_section_id: 2719,
    name: "UPE360",
    type: "UPE Channel",
    designation: "UPE360",
    shape: "C",
    country_code: "European",
    ax: "77,9",
    ax_global: 7790,
    bf: 110,
    bf_global: 110,
    ct: "5,45",
    ct_global: "54,5",
    d: 360,
    d_global: 360,
    ix: "58,49",
    ix_global: 584900,
    iy: "843,7",
    iy_global: 8437000,
    iz: 14830,
    iz_global: 148300000,
    tf: 17,
    tf_global: 17,
    tw: 12,
    tw_global: 12,
    zx: "982,3",
    zx_global: 982300,
    zy: "177,8",
    zy_global: 177800,
    width: 110,
    width_global: 110,
    thickness: 17,
    thickness_global: 17,
    height: 360,
    height_global: 360,
  },
  {
    profile_section_id: 2720,
    name: "UPE400",
    type: "UPE Channel",
    designation: "UPE400",
    shape: "C",
    country_code: "European",
    ax: "91,9",
    ax_global: 9190,
    bf: 115,
    bf_global: 115,
    ct: "6,33",
    ct_global: "63,3",
    d: 400,
    d_global: 400,
    ix: "79,14",
    ix_global: 791400,
    iy: 1045,
    iy_global: 10450000,
    iz: 20980,
    iz_global: 209800000,
    tf: 18,
    tf_global: 18,
    tw: "13,5",
    tw_global: "13,5",
    zx: 1263,
    zx_global: 1263000,
    zy: "191,4",
    zy_global: 191400,
    width: 115,
    width_global: 115,
    thickness: 18,
    thickness_global: 18,
    height: 400,
    height_global: 400,
  },
  {
    profile_section_id: 2721,
    name: "UAP80",
    type: "UAP Channel",
    designation: "UAP80",
    shape: "C",
    country_code: "European",
    ax: "10,7",
    ax_global: 1070,
    b: 45,
    b_global: 45,
    ct: "1,02",
    ct_global: "10,2",
    d: 80,
    d_global: 80,
    ix: "1,9",
    ix_global: 19000,
    iy: "21,33",
    iy_global: 213300,
    iz: "107,1",
    iz_global: 1071000,
    tf: 8,
    tf_global: 8,
    tw: 5,
    tw_global: 5,
    zx: "31,87",
    zx_global: 31870,
    zy: "13,64",
    zy_global: 13640,
  },
  {
    profile_section_id: 2722,
    name: "UAP100",
    type: "UAP Channel",
    designation: "UAP100",
    shape: "C",
    country_code: "European",
    ax: "13,4",
    ax_global: 1340,
    b: 50,
    b_global: 50,
    ct: "1,3",
    ct_global: 13,
    d: 100,
    d_global: 100,
    ix: "2,65",
    ix_global: 26500,
    iy: "32,83",
    iy_global: 328300,
    iz: "209,5",
    iz_global: 2095000,
    tf: "8,5",
    tf_global: "8,5",
    tw: "5,5",
    tw_global: "5,5",
    zx: "49,59",
    zx_global: 49590,
    zy: "18,47",
    zy_global: 18470,
  },
  {
    profile_section_id: 2723,
    name: "UAP130",
    type: "UAP Channel",
    designation: "UAP130",
    shape: "C",
    country_code: "European",
    ax: "17,5",
    ax_global: 1750,
    b: 55,
    b_global: 55,
    ct: "1,74",
    ct_global: "17,4",
    d: 130,
    d_global: 130,
    ix: "4,15",
    ix_global: 41500,
    iy: "51,34",
    iy_global: 513400,
    iz: "459,6",
    iz_global: 4596000,
    tf: "9,5",
    tf_global: "9,5",
    tw: 6,
    tw_global: 6,
    zx: "83,51",
    zx_global: 83510,
    zy: "25,55",
    zy_global: 25550,
  },
  {
    profile_section_id: 2724,
    name: "UAP150",
    type: "UAP Channel",
    designation: "UAP150",
    shape: "C",
    country_code: "European",
    ax: "22,9",
    ax_global: 2290,
    b: 65,
    b_global: 65,
    ct: "2,03",
    ct_global: "20,3",
    d: 150,
    d_global: 150,
    ix: "6,51",
    ix_global: 65100,
    iy: "93,25",
    iy_global: 932500,
    iz: "796,1",
    iz_global: 7961000,
    tf: "10,25",
    tf_global: "10,25",
    tw: 7,
    tw_global: 7,
    zx: "125,3",
    zx_global: 125300,
    zy: "38,78",
    zy_global: 38780,
  },
  {
    profile_section_id: 2725,
    name: "UAP175",
    type: "UAP Channel",
    designation: "UAP175",
    shape: "C",
    country_code: "European",
    ax: 27,
    ax_global: 2700,
    b: 70,
    b_global: 70,
    ct: "2,43",
    ct_global: "24,3",
    d: 175,
    d_global: 175,
    ix: "8,43",
    ix_global: 84300,
    iy: "126,4",
    iy_global: 1264000,
    iz: 1270,
    iz_global: 12700000,
    tf: "10,75",
    tf_global: "10,75",
    tw: "7,5",
    tw_global: "7,5",
    zx: "171,5",
    zx_global: 171500,
    zy: "47,47",
    zy_global: 47470,
  },
  {
    profile_section_id: 2726,
    name: "UAP200",
    type: "UAP Channel",
    designation: "UAP200",
    shape: "C",
    country_code: "European",
    ax: 32,
    ax_global: 3200,
    b: 75,
    b_global: 75,
    ct: "2,83",
    ct_global: "28,3",
    d: 200,
    d_global: 200,
    ix: "11,24",
    ix_global: 112400,
    iy: "169,7",
    iy_global: 1697000,
    iz: 1946,
    iz_global: 19460000,
    tf: "11,5",
    tf_global: "11,5",
    tw: 8,
    tw_global: 8,
    zx: "230,1",
    zx_global: 230100,
    zy: "58,29",
    zy_global: 58290,
  },
  {
    profile_section_id: 2727,
    name: "UAP220",
    type: "UAP Channel",
    designation: "UAP220",
    shape: "C",
    country_code: "European",
    ax: "36,3",
    ax_global: 3630,
    b: 80,
    b_global: 80,
    ct: "3,04",
    ct_global: "30,4",
    d: 220,
    d_global: 220,
    ix: "14,4",
    ix_global: 144000,
    iy: "222,3",
    iy_global: 2223000,
    iz: 2710,
    iz_global: 27100000,
    tf: "12,5",
    tf_global: "12,5",
    tw: 8,
    tw_global: 8,
    zx: "289,9",
    zx_global: 289900,
    zy: "72,56",
    zy_global: 72560,
  },
  {
    profile_section_id: 2728,
    name: "UAP250",
    type: "UAP Channel",
    designation: "UAP250",
    shape: "C",
    country_code: "European",
    ax: "43,8",
    ax_global: 4380,
    b: 85,
    b_global: 85,
    ct: "3,59",
    ct_global: "35,9",
    d: 250,
    d_global: 250,
    ix: "20,38",
    ix_global: 203800,
    iy: "295,4",
    iy_global: 2954000,
    iz: 4136,
    iz_global: 41360000,
    tf: "13,5",
    tf_global: "13,5",
    tw: 9,
    tw_global: 9,
    zx: "391,8",
    zx_global: 391800,
    zy: "87,65",
    zy_global: 87650,
  },
  {
    profile_section_id: 2729,
    name: "UAP300",
    type: "UAP Channel",
    designation: "UAP300",
    shape: "C",
    country_code: "European",
    ax: "58,6",
    ax_global: 5860,
    b: 100,
    b_global: 100,
    ct: "4,12",
    ct_global: "41,2",
    d: 300,
    d_global: 300,
    ix: "36,3",
    ix_global: 363000,
    iy: "562,1",
    iy_global: 5621000,
    iz: 8170,
    iz_global: 81700000,
    tf: 16,
    tf_global: 16,
    tw: "9,5",
    tw_global: "9,5",
    zx: "639,3",
    zx_global: 639300,
    zy: "145,8",
    zy_global: 145800,
  },
  {
    profile_section_id: 2730,
    name: "UPN80",
    type: "UPN Channel",
    designation: "UPN80",
    shape: "C",
    country_code: "European",
    ax: 11,
    ax_global: 1100,
    bf: 45,
    bf_global: 45,
    ct: "1,5",
    ct_global: 15,
    d: 80,
    d_global: 80,
    ix: "2,1",
    ix_global: 21000,
    iy: "19,4",
    iy_global: 194000,
    iz: 106,
    iz_global: 1060000,
    tf: 8,
    tf_global: 8,
    tw: 6,
    tw_global: 6,
    zx: "32,1",
    zx_global: 32100,
    zy: "9,8",
    zy_global: 9800,
  },
  {
    profile_section_id: 2731,
    name: "UPN100",
    type: "UPN Channel",
    designation: "UPN100",
    shape: "C",
    country_code: "European",
    ax: "13,5",
    ax_global: 1350,
    bf: 50,
    bf_global: 50,
    ct: "1,5",
    ct_global: 15,
    d: 100,
    d_global: 100,
    ix: "2,8",
    ix_global: 28000,
    iy: "29,3",
    iy_global: 293000,
    iz: 206,
    iz_global: 2060000,
    tf: "8,5",
    tf_global: "8,5",
    tw: 6,
    tw_global: 6,
    zx: "49,2",
    zx_global: 49200,
    zy: "13,8",
    zy_global: 13800,
  },
  {
    profile_section_id: 2732,
    name: "UPN120",
    type: "UPN Channel",
    designation: "UPN120",
    shape: "C",
    country_code: "European",
    ax: 17,
    ax_global: 1700,
    bf: 55,
    bf_global: 55,
    ct: "1,6",
    ct_global: 16,
    d: 120,
    d_global: 120,
    ix: 4,
    ix_global: 40000,
    iy: "43,2",
    iy_global: 432000,
    iz: 364,
    iz_global: 3640000,
    tf: 9,
    tf_global: 9,
    tw: 7,
    tw_global: 7,
    zx: "73,2",
    zx_global: 73200,
    zy: "19,9",
    zy_global: 19900,
  },
  {
    profile_section_id: 2733,
    name: "UPN140",
    type: "UPN Channel",
    designation: "UPN140",
    shape: "C",
    country_code: "European",
    ax: "20,4",
    ax_global: 2040,
    bf: 60,
    bf_global: 60,
    ct: "1,8",
    ct_global: 18,
    d: 140,
    d_global: 140,
    ix: "5,6",
    ix_global: 56000,
    iy: "62,7",
    iy_global: 627000,
    iz: 605,
    iz_global: 6050000,
    tf: 10,
    tf_global: 10,
    tw: 7,
    tw_global: 7,
    zx: "103,2",
    zx_global: 103200,
    zy: "26,2",
    zy_global: 26200,
  },
  {
    profile_section_id: 2734,
    name: "UPN160",
    type: "UPN Channel",
    designation: "UPN160",
    shape: "C",
    country_code: "European",
    ax: 24,
    ax_global: 2400,
    bf: 65,
    bf_global: 65,
    ct: "1,8",
    ct_global: 18,
    d: 160,
    d_global: 160,
    ix: "7,3",
    ix_global: 73000,
    iy: "85,3",
    iy_global: 853000,
    iz: 925,
    iz_global: 9250000,
    tf: "10,5",
    tf_global: "10,5",
    tw: "7,5",
    tw_global: "7,5",
    zx: "138,3",
    zx_global: 138300,
    zy: 34,
    zy_global: 34000,
  },
  {
    profile_section_id: 2735,
    name: "UPN180",
    type: "UPN Channel",
    designation: "UPN180",
    shape: "C",
    country_code: "European",
    ax: 28,
    ax_global: 2800,
    bf: 70,
    bf_global: 70,
    ct: "1,9",
    ct_global: 19,
    d: 180,
    d_global: 180,
    ix: "9,3",
    ix_global: 93000,
    iy: 114,
    iy_global: 1140000,
    iz: 1350,
    iz_global: 13500000,
    tf: 11,
    tf_global: 11,
    tw: 8,
    tw_global: 8,
    zx: "180,1",
    zx_global: 180100,
    zy: 43,
    zy_global: 43000,
  },
  {
    profile_section_id: 2736,
    name: "UPN200",
    type: "UPN Channel",
    designation: "UPN200",
    shape: "C",
    country_code: "European",
    ax: "32,2",
    ax_global: 3220,
    bf: 75,
    bf_global: 75,
    ct: 2,
    ct_global: 20,
    d: 200,
    d_global: 200,
    ix: "11,8",
    ix_global: 118000,
    iy: 148,
    iy_global: 1480000,
    iz: 1910,
    iz_global: 19100000,
    tf: "11,5",
    tf_global: "11,5",
    tw: "8,5",
    tw_global: "8,5",
    zx: "229,2",
    zx_global: 229200,
    zy: "53,4",
    zy_global: 53400,
  },
  {
    profile_section_id: 2737,
    name: "UPN220",
    type: "UPN Channel",
    designation: "UPN220",
    shape: "C",
    country_code: "European",
    ax: "37,4",
    ax_global: 3740,
    bf: 80,
    bf_global: 80,
    ct: "2,1",
    ct_global: 21,
    d: 220,
    d_global: 220,
    ix: "15,9",
    ix_global: 159000,
    iy: 197,
    iy_global: 1970000,
    iz: 2690,
    iz_global: 26900000,
    tf: "12,5",
    tf_global: "12,5",
    tw: 9,
    tw_global: 9,
    zx: "293,1",
    zx_global: 293100,
    zy: "66,4",
    zy_global: 66400,
  },
  {
    profile_section_id: 2738,
    name: "UPN240",
    type: "UPN Channel",
    designation: "UPN240",
    shape: "C",
    country_code: "European",
    ax: "42,3",
    ax_global: 4230,
    bf: 85,
    bf_global: 85,
    ct: "2,2",
    ct_global: 22,
    d: 240,
    d_global: 240,
    ix: "19,5",
    ix_global: 195000,
    iy: 248,
    iy_global: 2480000,
    iz: 3600,
    iz_global: 36000000,
    tf: 13,
    tf_global: 13,
    tw: "9,5",
    tw_global: "9,5",
    zx: "359,6",
    zx_global: 359600,
    zy: "79,9",
    zy_global: 79900,
  },
].map((p) => {
  const profile: ProfileSection = {
    ...p,
    shape: p.shape as ShapeType,
    ax: p.ax ? fixValueToNumber(p.ax, "float") : null,
    ax_global: p.ax_global ? fixValueToNumber(p.ax_global, "float") : undefined,
    b: p.b ? fixValueToNumber(p.b, "float") : null,
    b_global: p.b_global ? fixValueToNumber(p.b_global, "float") : undefined,
    bf: p.bf ? fixValueToNumber(p.bf, "float") : null,
    bf_global: p.bf_global ? fixValueToNumber(p.bf_global, "float") : undefined,
    c: null,
    ct: p.ct ? fixValueToNumber(p.ct, "float") : null,
    ct_global: p.ct_global ? fixValueToNumber(p.ct_global, "float") : undefined,
    d: p.d ? fixValueToNumber(p.d, "float") : null,
    d_global: p.d_global ? fixValueToNumber(p.d_global, "float") : undefined,
    de: null,
    i: null,
    ix: p.ix ? fixValueToNumber(p.ix, "float") : null,
    ix_global: p.ix_global ? fixValueToNumber(p.ix_global, "float") : undefined,
    iy: p.iy ? fixValueToNumber(p.iy, "float") : null,
    iy_global: p.iy_global ? fixValueToNumber(p.iy_global, "float") : undefined,
    iz: p.iz ? fixValueToNumber(p.iz, "float") : null,
    iz_global: p.iz_global ? fixValueToNumber(p.iz_global, "float") : undefined,
    k: null,
    k1: null,
    od: null,
    r1: null,
    r2: null,
    rz: null,
    t: p.t ? fixValueToNumber(p.t, "float") : null,
    t_global: p.t_global ? fixValueToNumber(p.t_global, "float") : undefined,
    tf: p.tf ? fixValueToNumber(p.tf, "float") : null,
    tf_global: p.tf_global ? fixValueToNumber(p.tf_global, "float") : undefined,
    tw: p.tw ? fixValueToNumber(p.tw, "float") : null,
    tw_global: p.tw_global ? fixValueToNumber(p.tw_global, "float") : undefined,
    z: null,
    zx: p.zx ? fixValueToNumber(p.zx, "float") : null,
    zx_global: p.zx_global ? fixValueToNumber(p.zx_global, "float") : undefined,
    zy: p.zy ? fixValueToNumber(p.zy, "float") : null,
    zy_global: p.zy_global ? fixValueToNumber(p.zy_global, "float") : undefined,
    zz: null,
    width: fixValueToNumber(p.width, "float"),
    width_global: fixValueToNumber(p.width_global, "float"),
    thickness: fixValueToNumber(p.thickness, "float"),
    thickness_global: p.thickness_global
      ? fixValueToNumber(p.thickness_global, "float")
      : undefined,
    height: fixValueToNumber(p.height, "float"),
    height_global: fixValueToNumber(p.height_global, "float"),
  };
  return profile;
});
