import { fixValueToNumber } from "../../components/3d-models/utils";

export const pipingCollets = [
  {
    piping_collets_id: 3,
    nps: 1,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 33,
    f1: 51,
    f2: 102,
    g: 51,
    a: 3,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "1,65",
    weight: "0,08",
  },
  {
    piping_collets_id: 4,
    nps: "1 1/4",
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 42,
    f1: 51,
    f2: 102,
    g: 64,
    a: 5,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "1,65",
    weight: "0,11",
  },
  {
    piping_collets_id: 5,
    nps: "1 1/2",
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 48,
    f1: 51,
    f2: 102,
    g: 73,
    a: 6,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "1,65",
    weight: "0,13",
  },
  {
    piping_collets_id: 6,
    nps: 2,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 60,
    f1: 64,
    f2: 152,
    g: 92,
    a: 8,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "1,65",
    weight: "0,2",
  },
  {
    piping_collets_id: 7,
    nps: "2 1/2",
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 73,
    f1: 64,
    f2: 152,
    g: 105,
    a: 8,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,11",
    weight: "0,31",
  },
  {
    piping_collets_id: 8,
    nps: 3,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 89,
    f1: 64,
    f2: 152,
    g: 127,
    a: 10,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,11",
    weight: "0,4",
  },
  {
    piping_collets_id: 9,
    nps: "3 1/2",
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 102,
    f1: 76,
    f2: 152,
    g: 140,
    a: 10,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,11",
    weight: "0,55",
  },
  {
    piping_collets_id: 10,
    nps: 4,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 114,
    f1: 76,
    f2: 152,
    g: 157,
    a: 11,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,11",
    weight: "0,65",
  },
  {
    piping_collets_id: 11,
    nps: 5,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 141,
    f1: 76,
    f2: 203,
    g: 186,
    a: 11,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,77",
    weight: 1,
  },
  {
    piping_collets_id: 12,
    nps: 6,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 168,
    f1: 89,
    f2: 203,
    g: 216,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,77",
    weight: "1,3",
  },
  {
    piping_collets_id: 13,
    nps: 8,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 219,
    f1: 102,
    f2: 203,
    g: 270,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,77",
    weight: 2,
  },
  {
    piping_collets_id: 14,
    nps: 10,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 273,
    f1: 127,
    f2: 254,
    g: 324,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "3,4",
    weight: "3,6",
  },
  {
    piping_collets_id: 15,
    nps: 12,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 324,
    f1: 152,
    f2: 254,
    g: 381,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "3,96",
    weight: "5,9",
  },
  {
    piping_collets_id: 16,
    nps: 14,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 356,
    f1: 152,
    f2: 305,
    g: 413,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "3,96",
    weight: "6,4",
  },
  {
    piping_collets_id: 17,
    nps: 16,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 406,
    f1: 152,
    f2: 305,
    g: 470,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "4,19",
    weight: "7,9",
  },
  {
    piping_collets_id: 18,
    nps: 18,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 457,
    f1: 152,
    f2: 305,
    g: 533,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "4,19",
    weight: "9,2",
  },
  {
    piping_collets_id: 19,
    nps: 20,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 508,
    f1: 152,
    f2: 305,
    g: 584,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "4,78",
    weight: "11,7",
  },
  {
    piping_collets_id: 20,
    nps: 22,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 559,
    f1: 152,
    f2: 305,
    g: 641,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "4,78",
    weight: -1,
  },
  {
    piping_collets_id: 21,
    nps: 24,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 610,
    f1: 152,
    f2: 305,
    g: 692,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "5,54",
    weight: "16,5",
  },
  {
    piping_collets_id: 24,
    nps: 1,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 33,
    f1: 51,
    f2: 102,
    g: 51,
    a: 3,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "1,65",
    weight: "0,08",
  },
  {
    piping_collets_id: 25,
    nps: "1 1/4",
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 42,
    f1: 51,
    f2: 102,
    g: 64,
    a: 5,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "1,65",
    weight: "0,11",
  },
  {
    piping_collets_id: 26,
    nps: "1 1/2",
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 48,
    f1: 51,
    f2: 102,
    g: 73,
    a: 6,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "1,65",
    weight: "0,13",
  },
  {
    piping_collets_id: 27,
    nps: 2,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 60,
    f1: 64,
    f2: 152,
    g: 92,
    a: 8,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "1,65",
    weight: "0,2",
  },
  {
    piping_collets_id: 28,
    nps: "2 1/2",
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 73,
    f1: 64,
    f2: 152,
    g: 105,
    a: 8,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,11",
    weight: "0,31",
  },
  {
    piping_collets_id: 29,
    nps: 3,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 89,
    f1: 64,
    f2: 152,
    g: 127,
    a: 10,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,11",
    weight: "0,4",
  },
  {
    piping_collets_id: 30,
    nps: "3 1/2",
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 102,
    f1: 76,
    f2: 152,
    g: 140,
    a: 10,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,11",
    weight: "0,55",
  },
  {
    piping_collets_id: 31,
    nps: 4,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 114,
    f1: 76,
    f2: 152,
    g: 157,
    a: 11,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,11",
    weight: "0,65",
  },
  {
    piping_collets_id: 32,
    nps: 5,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 141,
    f1: 76,
    f2: 203,
    g: 186,
    a: 11,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,77",
    weight: 1,
  },
  {
    piping_collets_id: 33,
    nps: 6,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 168,
    f1: 89,
    f2: 203,
    g: 216,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,77",
    weight: "1,3",
  },
  {
    piping_collets_id: 34,
    nps: 8,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 219,
    f1: 102,
    f2: 203,
    g: 270,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "2,77",
    weight: 2,
  },
  {
    piping_collets_id: 35,
    nps: 10,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 273,
    f1: 127,
    f2: 254,
    g: 324,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "3,4",
    weight: "3,6",
  },
  {
    piping_collets_id: 36,
    nps: 12,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 324,
    f1: 152,
    f2: 254,
    g: 381,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "3,96",
    weight: "5,9",
  },
  {
    piping_collets_id: 37,
    nps: 14,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 356,
    f1: 152,
    f2: 305,
    g: 413,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "3,96",
    weight: "6,4",
  },
  {
    piping_collets_id: 38,
    nps: 16,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 406,
    f1: 152,
    f2: 305,
    g: 470,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "4,19",
    weight: "7,9",
  },
  {
    piping_collets_id: 39,
    nps: 18,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 457,
    f1: 152,
    f2: 305,
    g: 533,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "4,19",
    weight: "9,2",
  },
  {
    piping_collets_id: 40,
    nps: 20,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 508,
    f1: 152,
    f2: 305,
    g: 584,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "4,78",
    weight: "11,7",
  },
  {
    piping_collets_id: 41,
    nps: 22,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 559,
    f1: 152,
    f2: 305,
    g: 641,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "4,78",
    weight: -1,
  },
  {
    piping_collets_id: 42,
    nps: 24,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 610,
    f1: 152,
    f2: 305,
    g: 692,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 5S",
    t: "5,54",
    weight: "16,5",
  },
  {
    piping_collets_id: 45,
    nps: 1,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 33,
    f1: 51,
    f2: 102,
    g: 51,
    a: 3,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "2,77",
    weight: "0,13",
  },
  {
    piping_collets_id: 46,
    nps: "1 1/4",
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 42,
    f1: 51,
    f2: 102,
    g: 64,
    a: 5,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "2,77",
    weight: "0,18",
  },
  {
    piping_collets_id: 47,
    nps: "1 1/2",
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 48,
    f1: 51,
    f2: 102,
    g: 73,
    a: 6,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "2,77",
    weight: "0,2",
  },
  {
    piping_collets_id: 48,
    nps: 2,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 60,
    f1: 64,
    f2: 152,
    g: 92,
    a: 8,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "2,77",
    weight: "0,34",
  },
  {
    piping_collets_id: 49,
    nps: "2 1/2",
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 73,
    f1: 64,
    f2: 152,
    g: 105,
    a: 8,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,05",
    weight: "0,45",
  },
  {
    piping_collets_id: 50,
    nps: 3,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 89,
    f1: 64,
    f2: 152,
    g: 127,
    a: 10,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,05",
    weight: "0,57",
  },
  {
    piping_collets_id: 51,
    nps: "3 1/2",
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 102,
    f1: 76,
    f2: 152,
    g: 140,
    a: 10,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,05",
    weight: "0,75",
  },
  {
    piping_collets_id: 52,
    nps: 4,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 114,
    f1: 76,
    f2: 152,
    g: 157,
    a: 11,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,05",
    weight: "0,9",
  },
  {
    piping_collets_id: 53,
    nps: 5,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 141,
    f1: 76,
    f2: 203,
    g: 186,
    a: 11,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,4",
    weight: "1,25",
  },
  {
    piping_collets_id: 54,
    nps: 6,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 168,
    f1: 89,
    f2: 203,
    g: 216,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,4",
    weight: "1,6",
  },
  {
    piping_collets_id: 55,
    nps: 8,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 219,
    f1: 102,
    f2: 203,
    g: 270,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,76",
    weight: "2,7",
  },
  {
    piping_collets_id: 56,
    nps: 10,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 273,
    f1: 127,
    f2: 254,
    g: 324,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "4,19",
    weight: "4,4",
  },
  {
    piping_collets_id: 57,
    nps: 12,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 324,
    f1: 152,
    f2: 254,
    g: 381,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "4,57",
    weight: "6,7",
  },
  {
    piping_collets_id: 58,
    nps: 14,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 356,
    f1: 152,
    f2: 305,
    g: 413,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "4,78",
    weight: "7,7",
  },
  {
    piping_collets_id: 59,
    nps: 16,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 406,
    f1: 152,
    f2: 305,
    g: 470,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "4,78",
    weight: 9,
  },
  {
    piping_collets_id: 60,
    nps: 18,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 457,
    f1: 152,
    f2: 305,
    g: 533,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "4,78",
    weight: "10,5",
  },
  {
    piping_collets_id: 61,
    nps: 20,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 508,
    f1: 152,
    f2: 305,
    g: 584,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "5,54",
    weight: "13,5",
  },
  {
    piping_collets_id: 62,
    nps: 22,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 559,
    f1: 152,
    f2: 305,
    g: 641,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "5,54",
    weight: -1,
  },
  {
    piping_collets_id: 63,
    nps: 24,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 610,
    f1: 152,
    f2: 305,
    g: 692,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "6,35",
    weight: "18,9",
  },
  {
    piping_collets_id: 66,
    nps: 1,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 33,
    f1: 51,
    f2: 102,
    g: 51,
    a: 3,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "2,77",
    weight: "0,13",
  },
  {
    piping_collets_id: 67,
    nps: "1 1/4",
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 42,
    f1: 51,
    f2: 102,
    g: 64,
    a: 5,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "2,77",
    weight: "0,18",
  },
  {
    piping_collets_id: 68,
    nps: "1 1/2",
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 48,
    f1: 51,
    f2: 102,
    g: 73,
    a: 6,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "2,77",
    weight: "0,2",
  },
  {
    piping_collets_id: 69,
    nps: 2,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 60,
    f1: 64,
    f2: 152,
    g: 92,
    a: 8,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "2,77",
    weight: "0,34",
  },
  {
    piping_collets_id: 70,
    nps: "2 1/2",
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 73,
    f1: 64,
    f2: 152,
    g: 105,
    a: 8,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,05",
    weight: "0,45",
  },
  {
    piping_collets_id: 71,
    nps: 3,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 89,
    f1: 64,
    f2: 152,
    g: 127,
    a: 10,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,05",
    weight: "0,57",
  },
  {
    piping_collets_id: 72,
    nps: "3 1/2",
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 102,
    f1: 76,
    f2: 152,
    g: 140,
    a: 10,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,05",
    weight: "0,75",
  },
  {
    piping_collets_id: 73,
    nps: 4,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 114,
    f1: 76,
    f2: 152,
    g: 157,
    a: 11,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,05",
    weight: "0,9",
  },
  {
    piping_collets_id: 74,
    nps: 5,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 141,
    f1: 76,
    f2: 203,
    g: 186,
    a: 11,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,4",
    weight: "1,25",
  },
  {
    piping_collets_id: 75,
    nps: 6,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 168,
    f1: 89,
    f2: 203,
    g: 216,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,4",
    weight: "1,6",
  },
  {
    piping_collets_id: 76,
    nps: 8,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 219,
    f1: 102,
    f2: 203,
    g: 270,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "3,76",
    weight: "2,7",
  },
  {
    piping_collets_id: 77,
    nps: 10,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 273,
    f1: 127,
    f2: 254,
    g: 324,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "4,19",
    weight: "4,4",
  },
  {
    piping_collets_id: 78,
    nps: 12,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 324,
    f1: 152,
    f2: 254,
    g: 381,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "4,57",
    weight: "6,7",
  },
  {
    piping_collets_id: 79,
    nps: 14,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 356,
    f1: 152,
    f2: 305,
    g: 413,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "4,78",
    weight: "7,7",
  },
  {
    piping_collets_id: 80,
    nps: 16,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 406,
    f1: 152,
    f2: 305,
    g: 470,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "4,78",
    weight: 9,
  },
  {
    piping_collets_id: 81,
    nps: 18,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 457,
    f1: 152,
    f2: 305,
    g: 533,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "4,78",
    weight: "10,5",
  },
  {
    piping_collets_id: 82,
    nps: 20,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 508,
    f1: 152,
    f2: 305,
    g: 584,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "5,54",
    weight: "13,5",
  },
  {
    piping_collets_id: 83,
    nps: 22,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 559,
    f1: 152,
    f2: 305,
    g: 641,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "5,54",
    weight: -1,
  },
  {
    piping_collets_id: 84,
    nps: 24,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 610,
    f1: 152,
    f2: 305,
    g: 692,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 10S",
    t: "6,35",
    weight: "18,9",
  },
  {
    piping_collets_id: 87,
    nps: 1,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 33,
    f1: 51,
    f2: 102,
    g: 51,
    a: 3,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "3,38",
    weight: "0,16",
  },
  {
    piping_collets_id: 88,
    nps: "1 1/4",
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 42,
    f1: 51,
    f2: 102,
    g: 64,
    a: 5,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "3,56",
    weight: "0,23",
  },
  {
    piping_collets_id: 89,
    nps: "1 1/2",
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 48,
    f1: 51,
    f2: 102,
    g: 73,
    a: 6,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "3,68",
    weight: "0,28",
  },
  {
    piping_collets_id: 90,
    nps: 2,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 60,
    f1: 64,
    f2: 152,
    g: 92,
    a: 8,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "3,91",
    weight: "0,47",
  },
  {
    piping_collets_id: 91,
    nps: "2 1/2",
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 73,
    f1: 64,
    f2: 152,
    g: 105,
    a: 8,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "5,16",
    weight: "0,75",
  },
  {
    piping_collets_id: 92,
    nps: 3,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 89,
    f1: 64,
    f2: 152,
    g: 127,
    a: 10,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "5,49",
    weight: 1,
  },
  {
    piping_collets_id: 93,
    nps: "3 1/2",
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 102,
    f1: 76,
    f2: 152,
    g: 140,
    a: 10,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "5,74",
    weight: "1,4",
  },
  {
    piping_collets_id: 94,
    nps: 4,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 114,
    f1: 76,
    f2: 152,
    g: 157,
    a: 11,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "6,02",
    weight: "1,7",
  },
  {
    piping_collets_id: 95,
    nps: 5,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 141,
    f1: 76,
    f2: 203,
    g: 186,
    a: 11,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "6,55",
    weight: "2,3",
  },
  {
    piping_collets_id: 96,
    nps: 6,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 168,
    f1: 89,
    f2: 203,
    g: 216,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "7,11",
    weight: "3,4",
  },
  {
    piping_collets_id: 97,
    nps: 8,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 219,
    f1: 102,
    f2: 203,
    g: 270,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "8,18",
    weight: "5,7",
  },
  {
    piping_collets_id: 98,
    nps: 10,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 273,
    f1: 127,
    f2: 254,
    g: 324,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "9,27",
    weight: "9,6",
  },
  {
    piping_collets_id: 99,
    nps: 12,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 324,
    f1: 152,
    f2: 254,
    g: 381,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "9,53",
    weight: "13,8",
  },
  {
    piping_collets_id: 100,
    nps: 14,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 356,
    f1: 152,
    f2: 305,
    g: 413,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "",
    weight: "",
  },
  {
    piping_collets_id: 101,
    nps: 16,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 406,
    f1: 152,
    f2: 305,
    g: 470,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "",
    weight: "",
  },
  {
    piping_collets_id: 102,
    nps: 18,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 457,
    f1: 152,
    f2: 305,
    g: 533,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "",
    weight: "",
  },
  {
    piping_collets_id: 103,
    nps: 20,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 508,
    f1: 152,
    f2: 305,
    g: 584,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "",
    weight: "",
  },
  {
    piping_collets_id: 104,
    nps: 22,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 559,
    f1: 152,
    f2: 305,
    g: 641,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "",
    weight: "",
  },
  {
    piping_collets_id: 105,
    nps: 24,
    material: "SME B 16.9-1993",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 610,
    f1: 152,
    f2: 305,
    g: 692,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "",
    weight: "",
  },
  {
    piping_collets_id: 108,
    nps: 1,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 33,
    f1: 51,
    f2: 102,
    g: 51,
    a: 3,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "3,38",
    weight: "0,16",
  },
  {
    piping_collets_id: 109,
    nps: "1 1/4",
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 42,
    f1: 51,
    f2: 102,
    g: 64,
    a: 5,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "3,56",
    weight: "0,23",
  },
  {
    piping_collets_id: 110,
    nps: "1 1/2",
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 48,
    f1: 51,
    f2: 102,
    g: 73,
    a: 6,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "3,68",
    weight: "0,28",
  },
  {
    piping_collets_id: 111,
    nps: 2,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 60,
    f1: 64,
    f2: 152,
    g: 92,
    a: 8,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "3,91",
    weight: "0,47",
  },
  {
    piping_collets_id: 112,
    nps: "2 1/2",
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 73,
    f1: 64,
    f2: 152,
    g: 105,
    a: 8,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "5,16",
    weight: "0,75",
  },
  {
    piping_collets_id: 113,
    nps: 3,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 89,
    f1: 64,
    f2: 152,
    g: 127,
    a: 10,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "5,49",
    weight: 1,
  },
  {
    piping_collets_id: 114,
    nps: "3 1/2",
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 102,
    f1: 76,
    f2: 152,
    g: 140,
    a: 10,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "5,74",
    weight: "1,4",
  },
  {
    piping_collets_id: 115,
    nps: 4,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 114,
    f1: 76,
    f2: 152,
    g: 157,
    a: 11,
    b: "0,8",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "6,02",
    weight: "1,7",
  },
  {
    piping_collets_id: 116,
    nps: 5,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 141,
    f1: 76,
    f2: 203,
    g: 186,
    a: 11,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "6,55",
    weight: "2,3",
  },
  {
    piping_collets_id: 117,
    nps: 6,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 168,
    f1: 89,
    f2: 203,
    g: 216,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "7,11",
    weight: "3,4",
  },
  {
    piping_collets_id: 118,
    nps: 8,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 219,
    f1: 102,
    f2: 203,
    g: 270,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "8,18",
    weight: "5,7",
  },
  {
    piping_collets_id: 119,
    nps: 10,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 273,
    f1: 127,
    f2: 254,
    g: 324,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "9,27",
    weight: "9,6",
  },
  {
    piping_collets_id: 120,
    nps: 12,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 324,
    f1: 152,
    f2: 254,
    g: 381,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "9,53",
    weight: "13,8",
  },
  {
    piping_collets_id: 121,
    nps: 14,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 356,
    f1: 152,
    f2: 305,
    g: 413,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "",
    weight: "",
  },
  {
    piping_collets_id: 122,
    nps: 16,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 406,
    f1: 152,
    f2: 305,
    g: 470,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "",
    weight: "",
  },
  {
    piping_collets_id: 123,
    nps: 18,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 457,
    f1: 152,
    f2: 305,
    g: 533,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "",
    weight: "",
  },
  {
    piping_collets_id: 124,
    nps: 20,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 508,
    f1: 152,
    f2: 305,
    g: 584,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "",
    weight: "",
  },
  {
    piping_collets_id: 125,
    nps: 22,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 559,
    f1: 152,
    f2: 305,
    g: 641,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "",
    weight: "",
  },
  {
    piping_collets_id: 126,
    nps: 24,
    material: "MSS-SP 43-1991",
    shape: "COLLETS - LAP JOINT - STUB END SS",
    d: 610,
    f1: 152,
    f2: 305,
    g: 692,
    a: 13,
    b: "1,6",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    schedule: "SCH 40S",
    t: "",
    weight: "",
  },
].map((item) => ({
  ...item,
  id: item.piping_collets_id,
  nps: item.nps + "",
  d: fixValueToNumber(item.d, "float"),
  f1: fixValueToNumber(item.f1, "float"),
  f2: fixValueToNumber(item.f2, "float"),
  g: fixValueToNumber(item.g, "float"),
  a: fixValueToNumber(item.a, "float"),
  b: fixValueToNumber(item.b, "float"),
  t: fixValueToNumber(item.t, "float"),
  weight: fixValueToNumber(item.weight, "float"),
}));
