import React ,{ useEffect,useState } from "react";
import { Button, FormGroup, InputGroup, Icon } from "@blueprintjs/core"; 
import { IconNames } from "@blueprintjs/icons"; 
import Logo from "../../assets/A-Logo-Blue-Transparent.png";
import "./Login.css";

type Props = { 
    inProgress?: boolean; 
    onUnload: () => any; 
    onSubmit: (email: string) => any; 
};

export function Forgot({ onUnload, onSubmit }: Props) {
    const [email, setEmail] = useState<string>("");
    const [message, setMessage] = useState<string>(""); 

    useEffect(() => {
        return () => onUnload();
    }, []);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        onSubmit(email).then(() => {
          setMessage("If an account with that email exists, we've sent a link to reset your password. Please check your inbox.");
          console.log(email)
        }).catch(() => {
          setMessage("An error occurred. Please try again later.");
        });
      }
      
    

    return (
        <div className="auth-page">
        <div className="primary-container">
          <div className="container-1">
            <h1>forgot password?</h1>
            <h2>let&apos;s reset it.</h2>
          </div>
          <div className="container-2">
            <div className="logo-container-2">
              <img className="primary-logo" src={Logo} alt="Logo" />
              <h1>
                Integrated
                <br />
                Design
                <br />
                Suite
              </h1>
            </div>
            <form onSubmit={handleSubmit}>
              <text className="login-text-group">email</text>
              <FormGroup>
                <InputGroup
                  large
                  type="email"
                  value={email}
                  className="login-input-group"
                  autoFocus={true}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="t-center">
                <Button large type="submit" className="form-group-button">
                    Send Reset Link
                </Button>
              </FormGroup>
            </form>
            {message && <div className="login-text-group-2" style={{alignSelf:"center"}}>{message}</div>}
            <br />
            <text className="login-text-group-2">don&apos;t have an account?<a href="https://www.asets.io/" target="_blank" className="anchor" rel="noopener noreferrer"> create one</a></text>
          </div>
        </div>
      </div>
    );
}
