import React, { useEffect, useState } from 'react';
import { Button, InputGroup, Toaster, Intent } from '@blueprintjs/core';
import Axios from 'axios';
import { secondServerAPI } from '../../utils/agent';
import "./project.css";
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';

const AppToaster = Toaster.create();

export function ProjectModule() {
    const [projectName, setProjectName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [projects, setProjects] = useState<any[]>([]);
    const [expandedProjectId, setExpandedProjectId] = useState<number | null>(null);
    const [shareEmail, setShareEmail] = useState('');
    const [shareRole, setShareRole] = useState('');

    const auth = useSelector((state: ApplicationState) => state.auth);

    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = async () => {
        try {
            const response = await Axios.get(`${secondServerAPI}rest/api/v1/ufc/dashboard/projects`, {
                headers: {
                    "user-id": auth.User_id,
                },
            });

            if (response.status === 200) {
                const fetchedProjects = response.data.map((proj: any) => ({
                    projectId: proj.projectId,
                    projectName: proj.projectName,
                    companyName: proj.companyName,
                    projectPhase: proj.projectPhase,
                    userRole: proj.role,  
                }));

                setProjects(fetchedProjects);
            }
        } catch (error) {
            console.error("Error fetching projects:", error);
            AppToaster.show({ message: "Failed to load projects", intent: Intent.DANGER });
        }
    };

    const handleProjectCreate = async () => {
        if (!projectName || !companyName) return;

        try {
            const response = await Axios.post(`${secondServerAPI}/rest/api/v1/ufc/dashboard/projects/create`, {
                projectName,
                companyName,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "user-id": auth.User_id,
                }
            });

            if (response.status === 200) {
                AppToaster.show({ message: "Project created successfully", intent: Intent.SUCCESS });
                setProjectName('');
                setCompanyName('');
                fetchProjects();
            }
        } catch (error) {
            console.error('Error creating project:', error);
            AppToaster.show({ message: "Failed to create project", intent: Intent.DANGER });
        }
    };

    const toggleProjectDetails = (projectId: number) => {
        setExpandedProjectId(expandedProjectId === projectId ? null : projectId); 
    };

    const handleProjectShare = async (projectId: number, shareEndpoint: string) => {
        if (!shareEmail || !shareRole) {
            AppToaster.show({ message: "Please enter both email and role", intent: Intent.WARNING });
            return;
        }

        try {
            const response = await Axios.post(`${secondServerAPI}/rest/api/v1/ufc/dashboard/projects/${shareEndpoint}`, {
                emailId: shareEmail,
                role: shareRole
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "user-id": auth.User_id,
                    "project-id": projectId,
                    "email" : shareEmail,
                    "role" : shareRole
                }
            });

            if (response.status === 200) {
                AppToaster.show({ message: "Project shared successfully", intent: Intent.SUCCESS });
                setShareEmail(''); 
                setShareRole(''); 
            }
        } catch (error) {
            console.error(`Error sharing project to ${shareEndpoint}:`, error);
            AppToaster.show({ message: `Failed to share project to ${shareEndpoint}`, intent: Intent.DANGER });
        }
    };

    const handleProjectDelete = async( projectId: number) => {
        try {
            const response = await Axios.delete(`${secondServerAPI}/rest/api/v1/ufc/dashboard/delete/project`, {
                headers: {
                    "user-id": auth.User_id,
                    "project-to-delete": projectId,
                },
            });
            if (response.status === 200) {
                AppToaster.show({ message: "Project deleted successfully", intent: Intent.SUCCESS });
                fetchProjects();
            }
        } catch (error) {
            console.error("Error deleting project:", error);
            AppToaster.show({ message: "Failed to delete project", intent: Intent.DANGER });
        }
    }

    const handleProjectFileDelete = async( projectId: number) => {
        try {
            const response = await Axios.delete(`${secondServerAPI}/rest/api/v1/ufc/dashboard/deleteProjectFile`, {
                headers: {
                    "user-id": auth.User_id,
                    "project-id": projectId,
                },
            });
            if (response.status === 200) {
                AppToaster.show({ message: "Project deleted successfully", intent: Intent.SUCCESS });
                fetchProjects();
            }
        } catch (error) {
            console.error("Error deleting project:", error);
            AppToaster.show({ message: "Failed to delete project", intent: Intent.DANGER });
        }
    }

    return (
        <div className="project-module-container">
            <div className="project-form-container">
                <h2>Create a New Project</h2>
                <p>Fill in the details to create a new project:</p>
                <div className="project-form">
                    <InputGroup
                        placeholder="Project Name"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                    />
                    <InputGroup
                        placeholder="Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                    />
                    <Button
                        icon="plus"
                        intent="primary"
                        text="Create Project"
                        disabled={!projectName || !companyName}
                        onClick={handleProjectCreate}
                    />
                </div>
            </div>

            <div className="project-list-container">
                <h2>Existing Projects</h2>
                {projects.length > 0 ? (
                    projects.map((project) => (
                        <div key={project.projectId} className="project-list-item">
                            <div className="project-header">
                                <p className="project-title">{project.projectName}</p>
                                <Button 
                                    icon={expandedProjectId === project.projectId ? "arrow-up" : "arrow-down"} 
                                    onClick={() => toggleProjectDetails(project.projectId)} 
                                />
                            </div>

                            {expandedProjectId === project.projectId && (
                                <div className="project-details">
                                    <p><strong>Company:</strong> {project.companyName}</p>
                                    {project.userRole === "Manager" ? (
                                        <>
                                            <Button 
                                                icon="floppy-disk"
                                                intent="danger"  
                                                style={{borderRadius: "5px", margin: "5px"}}
                                                text="Delete Project File" 
                                                onClick={() => handleProjectFileDelete(project.projectId)} 
                                            />
                                            <Button 
                                                icon="delete" 
                                                intent="danger"  
                                                style={{borderRadius: "5px", margin: "5px"}}
                                                text="Delete Project" 
                                                onClick={() => handleProjectDelete(project.projectId)} 
                                            />
                                            <div className="share-options">
                                                <div className="share-form">
                                                    <InputGroup
                                                        className="share-input"
                                                        placeholder="Enter email"
                                                        value={shareEmail}
                                                        onChange={(e) => setShareEmail(e.target.value)}
                                                    />
                                                    <InputGroup
                                                        className="share-input"
                                                        placeholder="Enter user role"
                                                        value={shareRole}
                                                        onChange={(e) => setShareRole(e.target.value)}
                                                    />
                                                </div>
                                                <div className="button-container">
                                                    <Button 
                                                        icon="share" 
                                                        intent="primary" 
                                                        className="share-button ufc-button"
                                                        text="Share to UFC" 
                                                        onClick={() => handleProjectShare(project.projectId, 'shareOnUfc')} 
                                                    />
                                                    <Button 
                                                        icon="share" 
                                                        intent="success" 
                                                        className="share-button idsv-button"
                                                        text="Share to Idsv" 
                                                        onClick={() => handleProjectShare(project.projectId, 'shareToIdsv')} 
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <p>Sharing options are only available for project managers.</p>
                                    )}
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <p>No projects available</p>
                )}
            </div>
        </div>
    );
}
