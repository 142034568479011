import { SET_PROJECT_NAME } from '../../../../../store/main/actions';
import { ProjectNameState } from '../../../../../store';

const initialState: ProjectNameState = {
    projectName: "",
};

export const projectNameReducer = (state = initialState, action): ProjectNameState => {
    switch (action.type) {
        case SET_PROJECT_NAME:
            return {
                ...state,
                projectName: action.payload,
            };
        default:
            return state;
    }
};
