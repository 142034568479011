import { SET_PROJECT_RESPONSE } from '../../../../../store/main/actions';
import { ProjectResponseState } from '../../../../../store';

const initialState: ProjectResponseState = {
    projectResponse: {},
};

export const projectResponseReducer = (state = initialState, action): ProjectResponseState => {
    switch (action.type) {
        case SET_PROJECT_RESPONSE:
            return {
                ...state,
                projectResponse: action.payload,
            };
        default:
            return state;
    }
};
