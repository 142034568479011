import { Button } from "@blueprintjs/core";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPipePoints,
  getPrecedingDirs,
} from "../../../../../services/pipe-services/pipe-service";
import { ApplicationState } from "../../../../../store";
import { TPipeMTO } from "../../../../../store/main/pipeTypes";
import { FreePipe, PipeConnectorType } from "../../../../../store/main/types";
import { getStartOffsetFromConnector } from "../../../../3d-models/pipes/pipesUtils";
import {
  exportToCSV,
  fixNumberToStr,
  getCurrentProject,
  getPosByDistance,
  degToRad,
  roundM,
} from "../../../../3d-models/utils";
import { CustomDlg } from "../../../../common/CustomDlg";
import { SimpleSelector } from "../../../../common/SimpleSelector";
import { useRecoilState } from "recoil";
import { mousePipeCreating } from "../../../../../recoil/atoms/process-atoms";
import { Matrix4, Vector3 } from "three";
import {
  changePipeAction,
  setPipesAction,
} from "../../../../../store/main/actions";
type Props = {
  onClose: () => any;
};

export function PipeAlignMTO({ onClose }: Props) {
  const project = useSelector((state: ApplicationState) =>
    getCurrentProject(state)
  );
  const dispatch = useDispatch();

  const [MPCState, setMPCState] = useRecoilState(mousePipeCreating);

  const pipes = useMemo(() => {
    return project?.freePipes ?? [];
  }, [project]);

  const handleChangeRow = useCallback(
    (pipes: FreePipe[], row: FreePipe, field: string, value: any) => {
      let changed = { ...row, [field]: value };
      // @ts-ignore
      const start = new Vector3(changed.x1, changed.y1, changed.z1);
      const end = new Vector3(changed.x2, changed.y2, changed.z2);
      changed = {
        ...changed,
        length: roundM(start.distanceTo(end)),
        elevation: (start.y + end.y) / 2,
        ...getPrecedingDirs(
          pipes.find((item) => item.pipe === changed.preceding),
          changed
        ),
      };
      let connectedPipe = pipes.find((item) => item.preceding === row.pipe);
      let freePipes = pipes.map((item) =>
        item.id === row.id ? changed : item
      );
      if (connectedPipe) {
        connectedPipe = { ...connectedPipe, x1: end.x, y1: end.y, z1: end.z };
        connectedPipe = {
          ...connectedPipe,
          elevation: (connectedPipe.y1 + connectedPipe.y2) / 2,
          length: roundM(
            new Vector3(
              connectedPipe.x1,
              connectedPipe.y1,
              connectedPipe.z1
            ).distanceTo(
              new Vector3(connectedPipe.x2, connectedPipe.y2, connectedPipe.z2)
            )
          ),
          ...getPrecedingDirs(changed, connectedPipe),
        };
        freePipes = freePipes.map((item) =>
          item.id === connectedPipe!.id
            ? connectedPipe!
            : item.preceding === connectedPipe!.pipe
            ? { ...item, ...getPrecedingDirs(connectedPipe, item) }
            : item
        );
      }
      if (changed.preceding !== "START") {
        const prev = pipes.find((r) => r.pipe === row.preceding);
        if (prev) {
          if (field === "x1" || field === "y1" || field === "z1") {
            if (field === "y1") {
              changed = {
                ...changed,
                elevation: (changed.y2 + value) / 2,
                hDir: 0,
                vDir: 0,
              };
            }
            const connectedPipe = pipes.find(
              (item) => item.pipe === changed.preceding
            );
            if (connectedPipe) {
              freePipes =
                field === "y1"
                  ? pipes.map((item) =>
                      item.id === connectedPipe.id
                        ? {
                            ...item,
                            y2: value,
                            elevation: (value + item.y1) / 2,
                          }
                        : item
                    )
                  : pipes.map((item) =>
                      item.id === connectedPipe.id
                        ? { ...item, [field === "x1" ? "x2" : "z2"]: value }
                        : item
                    );
            }
          }
        }
        changed = {
          ...changed,
          x1: start.x,
          y1: start.y,
          z1: start.z,
          x2: end.x,
          y2: end.y,
          z2: end.z,
        };
      }
      changed = { ...changed, length: roundM(start.distanceTo(end)) };
      freePipes = freePipes.map((item) =>
        item.id === row.id ? changed : item
      );
      dispatch(setPipesAction(freePipes));
    },
    [dispatch]
  );
  const selected = useSelector(
    (state: ApplicationState) => state.selections.selectedPipes
  );
  const selectedPipe = useMemo(() => {
    return selected?.length === 1 ? selected[0] : undefined;
  }, [selected]);
  const selectedNozzle = useSelector(
    (state: ApplicationState) => state.process.selectedNozzle
  );
  console.log(selectedNozzle);

  const onApply = () => {
    if (!selectedPipe) {
      alert("please selecte a pipe");
      return;
    }
    if (!selectedNozzle) {
      alert("please selecte a nozzle");
      return;
    }

    const pipe = pipes.find((pipe) => pipe.pipe === selectedPipe.pipeName);
    const pointPosition = selectedNozzle.point.generalPosition.clone(); // Example point

    // Create a rotation matrix for rotating around the Y axis
    const rotationMatrix = new Matrix4();
    if (selectedNozzle.el.rotation)
      rotationMatrix.makeRotationY(degToRad(selectedNozzle.el.rotation));

    if (selectedNozzle.el?.rotationX)
      rotationMatrix.makeRotationX(degToRad(selectedNozzle.el.rotationX));
    if (selectedNozzle.el.rotationZ)
      rotationMatrix.makeRotationZ(degToRad(selectedNozzle.el.rotationZ));
    // Apply the rotation matrix to the point
    pointPosition.applyMatrix4(rotationMatrix);
    const point = new Vector3(
      selectedNozzle.el.position.x,
      selectedNozzle.el.position.y,
      selectedNozzle.el.position.z
    ).add(pointPosition);
    if (pipe) {
      let newPipe: FreePipe = { ...pipe };
      if (MPCState.pipeAlignPosition === "START") {
        const distance = new Vector3(pipe.x1, pipe.y1, pipe.z1).sub(
          point.clone()
        );
        const newEnd = new Vector3(pipe.x2, pipe.y2, pipe.z2).sub(distance);
        newPipe = {
          ...pipe,
          x1: point.x,
          y1: point.y,
          z1: point.z,
          x2: newEnd.x,
          y2: newEnd.y,
          z2: newEnd.z,
        };
      } else {
        const distance = new Vector3(pipe.x2, pipe.y2, pipe.z2).sub(
          point.clone()
        );
        const newStart = new Vector3(pipe.x1, pipe.y1, pipe.z1).sub(distance);

        newPipe = {
          ...pipe,
          x2: point.x,
          y2: point.y,
          z2: point.z,
          x1: newStart.x,
          y1: newStart.y,
          z1: newStart.z,
        };
      }

      let changed = { ...newPipe };
      // @ts-ignore
      const start = new Vector3(changed.x1, changed.y1, changed.z1);
      const end = new Vector3(changed.x2, changed.y2, changed.z2);
      changed = {
        ...changed,
        length: roundM(start.distanceTo(end)),
        elevation: (start.y + end.y) / 2,
        ...getPrecedingDirs(
          pipes.find((item) => item.pipe === changed.preceding),
          changed
        ),
      };
      let connectedPipe = pipes.find((item) => item.preceding === newPipe.pipe);
      let freePipes = pipes.map((item) =>
        item.id === newPipe.id ? changed : item
      );
      if (connectedPipe) {
        connectedPipe = { ...connectedPipe, x1: end.x, y1: end.y, z1: end.z };
        connectedPipe = {
          ...connectedPipe,
          elevation: (connectedPipe.y1 + connectedPipe.y2) / 2,
          length: roundM(
            new Vector3(
              connectedPipe.x1,
              connectedPipe.y1,
              connectedPipe.z1
            ).distanceTo(
              new Vector3(connectedPipe.x2, connectedPipe.y2, connectedPipe.z2)
            )
          ),
          ...getPrecedingDirs(changed, connectedPipe),
        };
        freePipes = freePipes.map((item) =>
          item.id === connectedPipe!.id
            ? connectedPipe!
            : item.preceding === connectedPipe!.pipe
            ? { ...item, ...getPrecedingDirs(connectedPipe, item) }
            : item
        );
      }
      if (changed.preceding !== "START") {
        const prev = pipes.find((r) => r.pipe === newPipe.preceding);
        if (prev) {
          changed = {
            ...changed,
            elevation: (changed.y2 + changed.y1) / 2,
            hDir: 0,
            vDir: 0,
          };
          const connectedPipe = pipes.find(
            (item) => item.pipe === changed.preceding
          );
          if (connectedPipe) {
            freePipes = pipes.map((item) =>
              item.id === connectedPipe.id
                ? {
                    ...item,
                    y2: changed.y1,
                    x2: changed.x1,
                    z2: changed.z1,

                    elevation: (item.y2 + item.y1) / 2,
                  }
                : item
            );
          }
        }
        changed = {
          ...changed,
          x1: start.x,
          y1: start.y,
          z1: start.z,
          x2: end.x,
          y2: end.y,
          z2: end.z,
        };
      }
      changed = { ...changed, length: roundM(start.distanceTo(end)) };
      freePipes = freePipes.map((item) =>
        item.id === newPipe.id ? changed : item
      );
      dispatch(setPipesAction(freePipes));

      // dispatch(changePipeAction(newPipe));
    }
  };

  return (
    <CustomDlg
      title={"Pipe Align"}
      isMinimize={true}
      onClose={onClose}
      body={
        <div className="d-flex f-column f-grow">
          <div className={"d-flex f-ai-center bg-gray p-end-10"}>
            <div className="label-light p-start-10" style={{ minWidth: 100 }}>
              Selected Pipe
            </div>
            <div>{selectedPipe?.pipeName}</div>
          </div>
          <div className={"d-flex f-ai-center bg-gray p-end-10"}>
            <div className="label-light p-start-10" style={{ minWidth: 100 }}>
              Position
            </div>
            <SimpleSelector<"START" | "END">
              items={["START", "END"]}
              selected={MPCState.pipeAlignPosition}
              onSelect={(position: any) => {
                setMPCState((prev) => ({
                  ...prev,
                  pipeAlignPosition: position,
                }));
              }}
              autoFocus={true}
              itemLabel={(val) => val}
              className={`fill-select w-150`}
            />
          </div>
          <div className={"d-flex f-ai-center bg-gray p-end-10"}>
            <div className="label-light p-start-10" style={{ minWidth: 100 }}>
              Selected nozzle
            </div>
            <div>
              {selectedNozzle
                ? `Conn. ${selectedNozzle?.point.id} of ${selectedNozzle?.el.name}`
                : ""}
            </div>
          </div>
          <Button text="Apply" intent="danger" onClick={onApply} />
        </div>
      }
    />
  );
}
