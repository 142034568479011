import { fixValueToNumber } from "../../components/3d-models/utils";

export const pipingCaps = [
  {
    piping_caps_id: 3,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 33,
    e: 38,
    limiting_wt: "4,55",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,38",
    weight: "0,1",
  },
  {
    piping_caps_id: 4,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 42,
    e: 38,
    limiting_wt: "4,85",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,56",
    weight: "0,14",
  },
  {
    piping_caps_id: 5,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 48,
    e: 38,
    limiting_wt: "5,08",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,68",
    weight: "0,2",
  },
  {
    piping_caps_id: 6,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 60,
    e: 38,
    limiting_wt: "5,54",
    e1: 44,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "3,91",
    weight: "0,3",
  },
  {
    piping_caps_id: 7,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 73,
    e: 38,
    limiting_wt: "7,01",
    e1: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,16",
    weight: "0,5",
  },
  {
    piping_caps_id: 8,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 89,
    e: 51,
    limiting_wt: "7,62",
    e1: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,49",
    weight: "0,7",
  },
  {
    piping_caps_id: 9,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 102,
    e: 64,
    limiting_wt: "8,08",
    e1: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "5,74",
    weight: "1,4",
  },
  {
    piping_caps_id: 10,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 114,
    e: 64,
    limiting_wt: "8,56",
    e1: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,02",
    weight: "1,6",
  },
  {
    piping_caps_id: 11,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 141,
    e: 76,
    limiting_wt: "9,53",
    e1: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "6,55",
    weight: "2,3",
  },
  {
    piping_caps_id: 12,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 168,
    e: 89,
    limiting_wt: "10,97",
    e1: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "7,11",
    weight: "3,6",
  },
  {
    piping_caps_id: 13,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 219,
    e: 102,
    limiting_wt: "12,7",
    e1: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "8,18",
    weight: "5,5",
  },
  {
    piping_caps_id: 14,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 273,
    e: 127,
    limiting_wt: "12,7",
    e1: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,27",
    weight: 10,
  },
  {
    piping_caps_id: 15,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 324,
    e: 152,
    limiting_wt: "12,7",
    e1: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 15,
  },
  {
    piping_caps_id: 16,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 356,
    e: 165,
    limiting_wt: "12,7",
    e1: 191,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 17,
  },
  {
    piping_caps_id: 17,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 406,
    e: 178,
    limiting_wt: "12,7",
    e1: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 23,
  },
  {
    piping_caps_id: 18,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 457,
    e: 203,
    limiting_wt: "12,7",
    e1: 229,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 29,
  },
  {
    piping_caps_id: 19,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 508,
    e: 229,
    limiting_wt: "12,7",
    e1: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 36,
  },
  {
    piping_caps_id: 20,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 559,
    e: 254,
    limiting_wt: "12,7",
    e1: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 42,
  },
  {
    piping_caps_id: 21,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 610,
    e: 267,
    limiting_wt: "12,7",
    e1: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 52,
  },
  {
    piping_caps_id: 22,
    nps: 26,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 660,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: "46,5",
  },
  {
    piping_caps_id: 23,
    nps: 28,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 711,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 56,
  },
  {
    piping_caps_id: 24,
    nps: 30,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 762,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 62,
  },
  {
    piping_caps_id: 25,
    nps: 32,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 813,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 68,
  },
  {
    piping_caps_id: 26,
    nps: 34,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 864,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 73,
  },
  {
    piping_caps_id: 27,
    nps: 36,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 914,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: "79,5",
  },
  {
    piping_caps_id: 28,
    nps: 38,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 965,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 86,
  },
  {
    piping_caps_id: 29,
    nps: 40,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1016,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 95,
  },
  {
    piping_caps_id: 30,
    nps: 42,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1067,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: "104,5",
  },
  {
    piping_caps_id: 31,
    nps: 44,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1118,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 120,
  },
  {
    piping_caps_id: 32,
    nps: 46,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1168,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 136,
  },
  {
    piping_caps_id: 33,
    nps: 48,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1219,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "STD",
    t: "9,52",
    weight: 159,
  },
  {
    piping_caps_id: 36,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 33,
    e: 38,
    limiting_wt: "4,55",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,55",
    weight: "0,13",
  },
  {
    piping_caps_id: 37,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 42,
    e: 38,
    limiting_wt: "4,85",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "4,85",
    weight: "0,2",
  },
  {
    piping_caps_id: 38,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 48,
    e: 38,
    limiting_wt: "5,08",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,08",
    weight: "0,23",
  },
  {
    piping_caps_id: 39,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 60,
    e: 38,
    limiting_wt: "5,54",
    e1: 44,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "5,54",
    weight: "0,3",
  },
  {
    piping_caps_id: 40,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 73,
    e: 38,
    limiting_wt: "7,01",
    e1: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,01",
    weight: "0,5",
  },
  {
    piping_caps_id: 41,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 89,
    e: 51,
    limiting_wt: "7,62",
    e1: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "7,62",
    weight: "0,9",
  },
  {
    piping_caps_id: 42,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 102,
    e: 64,
    limiting_wt: "8,08",
    e1: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,08",
    weight: "1,7",
  },
  {
    piping_caps_id: 43,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 114,
    e: 64,
    limiting_wt: "8,56",
    e1: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "8,56",
    weight: 2,
  },
  {
    piping_caps_id: 44,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 141,
    e: 76,
    limiting_wt: "9,53",
    e1: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "9,52",
    weight: 3,
  },
  {
    piping_caps_id: 45,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 168,
    e: 89,
    limiting_wt: "10,97",
    e1: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "10,97",
    weight: 4,
  },
  {
    piping_caps_id: 46,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 219,
    e: 102,
    limiting_wt: "12,7",
    e1: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "8,4",
  },
  {
    piping_caps_id: 47,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 273,
    e: 127,
    limiting_wt: "12,7",
    e1: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "13,6",
  },
  {
    piping_caps_id: 48,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 324,
    e: 152,
    limiting_wt: "12,7",
    e1: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 22,
  },
  {
    piping_caps_id: 49,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 356,
    e: 165,
    limiting_wt: "12,7",
    e1: 191,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 27,
  },
  {
    piping_caps_id: 50,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 406,
    e: 178,
    limiting_wt: "12,7",
    e1: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 30,
  },
  {
    piping_caps_id: 51,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 457,
    e: 203,
    limiting_wt: "12,7",
    e1: 229,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 32,
  },
  {
    piping_caps_id: 52,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 508,
    e: 229,
    limiting_wt: "12,7",
    e1: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 49,
  },
  {
    piping_caps_id: 53,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 559,
    e: 254,
    limiting_wt: "12,7",
    e1: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 51,
  },
  {
    piping_caps_id: 54,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 610,
    e: 267,
    limiting_wt: "12,7",
    e1: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 60,
  },
  {
    piping_caps_id: 55,
    nps: 26,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 660,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 66,
  },
  {
    piping_caps_id: 56,
    nps: 28,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 711,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 75,
  },
  {
    piping_caps_id: 57,
    nps: 30,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 762,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 83,
  },
  {
    piping_caps_id: 58,
    nps: 32,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 813,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 93,
  },
  {
    piping_caps_id: 59,
    nps: 34,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 864,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 97,
  },
  {
    piping_caps_id: 60,
    nps: 36,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 914,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 107,
  },
  {
    piping_caps_id: 61,
    nps: 38,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 965,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 125,
  },
  {
    piping_caps_id: 62,
    nps: 40,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1016,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 129,
  },
  {
    piping_caps_id: 63,
    nps: 42,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1067,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: "136,5",
  },
  {
    piping_caps_id: 64,
    nps: 44,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1118,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 168,
  },
  {
    piping_caps_id: 65,
    nps: 46,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1168,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 186,
  },
  {
    piping_caps_id: 66,
    nps: 48,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1219,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XS",
    t: "12,7",
    weight: 215,
  },
  {
    piping_caps_id: 69,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 33,
    e: 38,
    limiting_wt: "4,55",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "9,09",
    weight: "0,2",
  },
  {
    piping_caps_id: 70,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 42,
    e: 38,
    limiting_wt: "4,85",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "9,7",
    weight: "0,28",
  },
  {
    piping_caps_id: 71,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 48,
    e: 38,
    limiting_wt: "5,08",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "10,16",
    weight: "0,36",
  },
  {
    piping_caps_id: 72,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 60,
    e: 38,
    limiting_wt: "5,54",
    e1: 44,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "11,07",
    weight: "0,59",
  },
  {
    piping_caps_id: 73,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 73,
    e: 38,
    limiting_wt: "7,01",
    e1: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "14,02",
    weight: 1,
  },
  {
    piping_caps_id: 74,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 89,
    e: 51,
    limiting_wt: "7,62",
    e1: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "15,24",
    weight: "1,78",
  },
  {
    piping_caps_id: 75,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 102,
    e: 64,
    limiting_wt: "8,08",
    e1: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 76,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 114,
    e: 64,
    limiting_wt: "8,56",
    e1: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "17,12",
    weight: "3,17",
  },
  {
    piping_caps_id: 77,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 141,
    e: 76,
    limiting_wt: "9,53",
    e1: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "19,05",
    weight: "5,5",
  },
  {
    piping_caps_id: 78,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 168,
    e: 89,
    limiting_wt: "10,97",
    e1: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "21,95",
    weight: "8,1",
  },
  {
    piping_caps_id: 79,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 219,
    e: 102,
    limiting_wt: "12,7",
    e1: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "22,23",
    weight: "19,5",
  },
  {
    piping_caps_id: 80,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 273,
    e: 127,
    limiting_wt: "12,7",
    e1: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "25,4",
    weight: "29,3",
  },
  {
    piping_caps_id: 81,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 324,
    e: 152,
    limiting_wt: "12,7",
    e1: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "25,4",
    weight: 41,
  },
  {
    piping_caps_id: 82,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 356,
    e: 165,
    limiting_wt: "12,7",
    e1: 191,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 83,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 406,
    e: 178,
    limiting_wt: "12,7",
    e1: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 84,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 457,
    e: 203,
    limiting_wt: "12,7",
    e1: 229,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 85,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 508,
    e: 229,
    limiting_wt: "12,7",
    e1: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 86,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 559,
    e: 254,
    limiting_wt: "12,7",
    e1: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 87,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 610,
    e: 267,
    limiting_wt: "12,7",
    e1: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 88,
    nps: 26,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 660,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 89,
    nps: 28,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 711,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 90,
    nps: 30,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 762,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 91,
    nps: 32,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 813,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 92,
    nps: 34,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 864,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 93,
    nps: 36,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 914,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 94,
    nps: 38,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 965,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 95,
    nps: 40,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1016,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 96,
    nps: 42,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1067,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 97,
    nps: 44,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1118,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 98,
    nps: 46,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1168,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 99,
    nps: 48,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1219,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "XXS",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 102,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 33,
    e: 38,
    limiting_wt: "4,55",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 103,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 42,
    e: 38,
    limiting_wt: "4,85",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 104,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 48,
    e: 38,
    limiting_wt: "5,08",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 105,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 60,
    e: 38,
    limiting_wt: "5,54",
    e1: 44,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 106,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 73,
    e: 38,
    limiting_wt: "7,01",
    e1: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 107,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 89,
    e: 51,
    limiting_wt: "7,62",
    e1: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 108,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 102,
    e: 64,
    limiting_wt: "8,08",
    e1: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 109,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 114,
    e: 64,
    limiting_wt: "8,56",
    e1: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 110,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 141,
    e: 76,
    limiting_wt: "9,53",
    e1: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 111,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 168,
    e: 89,
    limiting_wt: "10,97",
    e1: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 112,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 219,
    e: 102,
    limiting_wt: "12,7",
    e1: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 113,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 273,
    e: 127,
    limiting_wt: "12,7",
    e1: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 114,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 324,
    e: 152,
    limiting_wt: "12,7",
    e1: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 115,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 356,
    e: 165,
    limiting_wt: "12,7",
    e1: 191,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weight: 14,
  },
  {
    piping_caps_id: 116,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 406,
    e: 178,
    limiting_wt: "12,7",
    e1: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weight: 18,
  },
  {
    piping_caps_id: 117,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 457,
    e: 203,
    limiting_wt: "12,7",
    e1: 229,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weight: 22,
  },
  {
    piping_caps_id: 118,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 508,
    e: 229,
    limiting_wt: "12,7",
    e1: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weight: 31,
  },
  {
    piping_caps_id: 119,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 559,
    e: 254,
    limiting_wt: "12,7",
    e1: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weight: "35,5",
  },
  {
    piping_caps_id: 120,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 610,
    e: 267,
    limiting_wt: "12,7",
    e1: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "6,35",
    weight: 40,
  },
  {
    piping_caps_id: 121,
    nps: 26,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 660,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "7,92",
    weight: 42,
  },
  {
    piping_caps_id: 122,
    nps: 28,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 711,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "7,92",
    weight: 47,
  },
  {
    piping_caps_id: 123,
    nps: 30,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 762,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "7,92",
    weight: 51,
  },
  {
    piping_caps_id: 124,
    nps: 32,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 813,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "7,92",
    weight: 57,
  },
  {
    piping_caps_id: 125,
    nps: 34,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 864,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "7,92",
    weight: 60,
  },
  {
    piping_caps_id: 126,
    nps: 36,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 914,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "7,92",
    weight: 66,
  },
  {
    piping_caps_id: 127,
    nps: 38,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 965,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 128,
    nps: 40,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1016,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 129,
    nps: 42,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1067,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 130,
    nps: 44,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1118,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 131,
    nps: 46,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1168,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 132,
    nps: 48,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1219,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 10",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 135,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 33,
    e: 38,
    limiting_wt: "4,55",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 136,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 42,
    e: 38,
    limiting_wt: "4,85",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 137,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 48,
    e: 38,
    limiting_wt: "5,08",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 138,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 60,
    e: 38,
    limiting_wt: "5,54",
    e1: 44,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 139,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 73,
    e: 38,
    limiting_wt: "7,01",
    e1: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 140,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 89,
    e: 51,
    limiting_wt: "7,62",
    e1: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 141,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 102,
    e: 64,
    limiting_wt: "8,08",
    e1: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 142,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 114,
    e: 64,
    limiting_wt: "8,56",
    e1: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 143,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 141,
    e: 76,
    limiting_wt: "9,53",
    e1: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 144,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 168,
    e: 89,
    limiting_wt: "10,97",
    e1: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 145,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 219,
    e: 102,
    limiting_wt: "12,7",
    e1: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "6,35",
    weight: "4,5",
  },
  {
    piping_caps_id: 146,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 273,
    e: 127,
    limiting_wt: "12,7",
    e1: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "6,35",
    weight: 7,
  },
  {
    piping_caps_id: 147,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 324,
    e: 152,
    limiting_wt: "12,7",
    e1: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "6,35",
    weight: 9,
  },
  {
    piping_caps_id: 148,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 356,
    e: 165,
    limiting_wt: "12,7",
    e1: 191,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "7,92",
    weight: "15,5",
  },
  {
    piping_caps_id: 149,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 406,
    e: 178,
    limiting_wt: "12,7",
    e1: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "7,92",
    weight: 20,
  },
  {
    piping_caps_id: 150,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 457,
    e: 203,
    limiting_wt: "12,7",
    e1: 229,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "7,92",
    weight: 25,
  },
  {
    piping_caps_id: 151,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 508,
    e: 229,
    limiting_wt: "12,7",
    e1: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "9,52",
    weight: 36,
  },
  {
    piping_caps_id: 152,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 559,
    e: 254,
    limiting_wt: "12,7",
    e1: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "9,52",
    weight: 42,
  },
  {
    piping_caps_id: 153,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 610,
    e: 267,
    limiting_wt: "12,7",
    e1: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "9,52",
    weight: 52,
  },
  {
    piping_caps_id: 154,
    nps: 26,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 660,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "12,7",
    weight: 66,
  },
  {
    piping_caps_id: 155,
    nps: 28,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 711,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "12,7",
    weight: 75,
  },
  {
    piping_caps_id: 156,
    nps: 30,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 762,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "12,7",
    weight: 83,
  },
  {
    piping_caps_id: 157,
    nps: 32,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 813,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "12,7",
    weight: 93,
  },
  {
    piping_caps_id: 158,
    nps: 34,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 864,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "12,7",
    weight: 97,
  },
  {
    piping_caps_id: 159,
    nps: 36,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 914,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "12,7",
    weight: 107,
  },
  {
    piping_caps_id: 160,
    nps: 38,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 965,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 161,
    nps: 40,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1016,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 162,
    nps: 42,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1067,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 163,
    nps: 44,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1118,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 164,
    nps: 46,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1168,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 165,
    nps: 48,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1219,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 20",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 168,
    nps: 1,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 33,
    e: 38,
    limiting_wt: "4,55",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "2,9",
    weight: -1,
  },
  {
    piping_caps_id: 169,
    nps: "1 1/4",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 42,
    e: 38,
    limiting_wt: "4,85",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "2,97",
    weight: -1,
  },
  {
    piping_caps_id: 170,
    nps: "1 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 48,
    e: 38,
    limiting_wt: "5,08",
    e1: 38,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "3,18",
    weight: -1,
  },
  {
    piping_caps_id: 171,
    nps: 2,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 60,
    e: 38,
    limiting_wt: "5,54",
    e1: 44,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "3,18",
    weight: -1,
  },
  {
    piping_caps_id: 172,
    nps: "2 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 73,
    e: 38,
    limiting_wt: "7,01",
    e1: 51,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "4,78",
    weight: -1,
  },
  {
    piping_caps_id: 173,
    nps: 3,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 89,
    e: 51,
    limiting_wt: "7,62",
    e1: 64,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "4,78",
    weight: -1,
  },
  {
    piping_caps_id: 174,
    nps: "3 1/2",
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 102,
    e: 64,
    limiting_wt: "8,08",
    e1: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "4,78",
    weight: -1,
  },
  {
    piping_caps_id: 175,
    nps: 4,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 114,
    e: 64,
    limiting_wt: "8,56",
    e1: 76,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "4,78",
    weight: -1,
  },
  {
    piping_caps_id: 176,
    nps: 5,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 141,
    e: 76,
    limiting_wt: "9,53",
    e1: 89,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 177,
    nps: 6,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 168,
    e: 89,
    limiting_wt: "10,97",
    e1: 102,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 178,
    nps: 8,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 219,
    e: 102,
    limiting_wt: "12,7",
    e1: 127,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "7,03",
    weight: 5,
  },
  {
    piping_caps_id: 179,
    nps: 10,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 273,
    e: 127,
    limiting_wt: "12,7",
    e1: 152,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "7,8",
    weight: "7,63",
  },
  {
    piping_caps_id: 180,
    nps: 12,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 324,
    e: 152,
    limiting_wt: "12,7",
    e1: 178,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "8,38",
    weight: 13,
  },
  {
    piping_caps_id: 181,
    nps: 14,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 356,
    e: 165,
    limiting_wt: "12,7",
    e1: 191,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "9,52",
    weight: 17,
  },
  {
    piping_caps_id: 182,
    nps: 16,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 406,
    e: 178,
    limiting_wt: "12,7",
    e1: 203,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "9,52",
    weight: 23,
  },
  {
    piping_caps_id: 183,
    nps: 18,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 457,
    e: 203,
    limiting_wt: "12,7",
    e1: 229,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "11,13",
    weight: "30,3",
  },
  {
    piping_caps_id: 184,
    nps: 20,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 508,
    e: 229,
    limiting_wt: "12,7",
    e1: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "12,7",
    weight: 49,
  },
  {
    piping_caps_id: 185,
    nps: 22,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 559,
    e: 254,
    limiting_wt: "12,7",
    e1: 254,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "12,7",
    weight: 59,
  },
  {
    piping_caps_id: 186,
    nps: 24,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 610,
    e: 267,
    limiting_wt: "12,7",
    e1: 305,
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "14,27",
    weight: "74,5",
  },
  {
    piping_caps_id: 187,
    nps: 26,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 660,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 188,
    nps: 28,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 711,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "15,88",
    weight: 93,
  },
  {
    piping_caps_id: 189,
    nps: 30,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 762,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "15,88",
    weight: 103,
  },
  {
    piping_caps_id: 190,
    nps: 32,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 813,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "15,88",
    weight: 117,
  },
  {
    piping_caps_id: 191,
    nps: 34,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 864,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "15,88",
    weight: 121,
  },
  {
    piping_caps_id: 192,
    nps: 36,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 914,
    e: 267,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "15,88",
    weight: 134,
  },
  {
    piping_caps_id: 193,
    nps: 38,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 965,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 194,
    nps: 40,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1016,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 195,
    nps: 42,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1067,
    e: 305,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 196,
    nps: 44,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1118,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 197,
    nps: 46,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1168,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    weight: "",
  },
  {
    piping_caps_id: 198,
    nps: 48,
    material: "ASME B 16.9-1993",
    shape: "CAPS",
    d: 1219,
    e: 343,
    limiting_wt: "",
    e1: "",
    std: "",
    xs: "",
    xxs: "",
    sch_10: "",
    sch_20: "",
    sch_30: "",
    sch_40: "",
    sch_60: "",
    sch_80: "",
    sch_100: "",
    sch_120: "",
    sch_140: "",
    sch_160: "",
    sch_5s: "",
    sch_10s: "",
    sch_40s: "",
    sch_80s: "",
    schedule: "SCH 30",
    t: "",
    weight: "",
  },
].map((item) => ({
  ...item,
  id: item.piping_caps_id,
  nps: item.nps + "",
  limiting_wt: fixValueToNumber(item.limiting_wt, "float"),
  t: fixValueToNumber(item.t, "float"),
  weight: fixValueToNumber(item.weight, "float"),
}));
