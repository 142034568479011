import React, { useCallback, useEffect, useState } from 'react';  
import { secondServerAPI } from '../utils/agent';
import { checkUserCredits } from '../../services/file-upload-services/UfcFilehandler';
import Axios from 'axios';
import "./ImageModule.css";
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { openDB } from 'idb';
import { Spinner } from '@blueprintjs/core';

interface UDCFile {
    udcId: number;
    filename: string;
    description: string;
    imageLink: string;
    fileAddress: string;
    fileSize: string;
    fileUploadedOn: string;
    udcComponentType: string;
}

export function ImageModule() {
    const [hasCredits, setHasCredits] = useState(false);
    const [udcFiles, setUdcFiles] = useState<UDCFile[]>([]);
    const [imagesFetched, setImagesFetched] = useState(false); 
    const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);
    const [selectedOtherFile, setSelectedOtherFile] = useState<File | null>(null);
    const [description, setDescription] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');
    const [componentType, setComponentType] = useState<string>('All');
    const [newcomponentType, setNewComponentType] = useState<string>('All');
    const [selectedFile, setSelectedFile] = useState<UDCFile | null>(null);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [udcId, setUdcId] = useState<number | null>(null);
    const [showFormFields, setShowFormFields] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean | null>(false);


    const auth = useSelector((state: ApplicationState) => state.auth);

    const userId = auth.User_id || 1;

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => setSuccessMessage(null), 3000); 
            return () => clearTimeout(timer);
        }
    }, [successMessage]);
    

    useEffect(() => {
        async function fetchCredits() {
            const result = await checkUserCredits(userId);
            setHasCredits(result);
        }

        fetchCredits();
    }, [userId]);

    const fetchImageBlob = useCallback(async (udcFile) => {
        console.log("the blob  triggered for the new file",udcFile.udcId);
        try {
            const response = await Axios.get(`${secondServerAPI}/rest/api/v1/udc/asets-udc/get-file-from-S3`, {
                headers: {
                    "udc-id": udcFile.udcId.toString(),
                    "file-type": "image",
                    "cache" : "no-cache",
                },
                responseType: 'blob'
            });

            if (response.status === 200) {
                const imageBlob = response.data;
                const imageUrl = URL.createObjectURL(imageBlob);
                await saveImageToCache(udcFile.udcId, imageBlob);
                URL.revokeObjectURL( imageUrl);
                return { ...udcFile, imageLink: imageUrl };
            } else {
                throw new Error(`Failed to fetch image for file ${udcFile.filename}`);
            }
        } catch (error) {
            console.error("Error fetching image from S3", error);
            return { ...udcFile, imageLink: ""};
        }
    }, []);

    const saveImageToCache = async (udcId, imageBlob : any) => {
        const db = await openDB('image-cache', 1, {
            upgrade(db) {
                db.createObjectStore('images');
            }
        });
        const tx = db.transaction('images', 'readwrite');
        const store = tx.objectStore('images');
        store.put(imageBlob, udcId.toString());
        await tx.done;
    };

    const getImageFromCache = async (udcId) => {
        const db = await openDB('image-cache', 1, {
            upgrade(db) {
                db.createObjectStore('images');
            }
        });
        const imageBlob = await db.transaction('images').objectStore('images').get(udcId.toString());
        return imageBlob ? URL.createObjectURL(imageBlob) : null;
    };

    const fetchImagesWithCache = useCallback(async () => {
        const updatedFiles = await Promise.all(
            udcFiles.map(async (file) => {
                const cachedImage = await getImageFromCache(file.udcId);
                if (cachedImage) {
                    return { ...file, imageLink: cachedImage };
                } else {
                    return await fetchImageBlob(file);
                }
            })
        );
        setUdcFiles(updatedFiles);
        setImagesFetched(true);
    }, [udcFiles, fetchImageBlob]);

    useEffect(() => {
        async function getAllFiles() {
            try {
                const response = await Axios.get(`${secondServerAPI}/rest/api/v1/udc/asets-udc/get-all-udc-components`, {
                    headers: {
                        "user-id": userId,
                    }
                });
                setUdcFiles(response.data);
                setImagesFetched(false);
            } catch (error) {
                console.error("Error fetching UDC files", error);
            }
        }

        getAllFiles();
    }, [userId]);

    useEffect(() => {
        if (udcFiles.length > 0 && !imagesFetched) {
            fetchImagesWithCache();
        }

        const interval = setInterval(() => {
            setImagesFetched(false);  
        }, 10 * 60 * 1000);

        return () => {
            clearInterval(interval);
            udcFiles.forEach(file => {
                if (file.imageLink) {
                    URL.revokeObjectURL(file.imageLink);
                }
            });
        };
    }, [udcFiles, imagesFetched, fetchImagesWithCache]);

    // useEffect(() => {
    //     async function getImages() {
    //         try {
    //             const updatedFiles = await Promise.all(udcFiles.map(async (file) => {
    //                 const response = await Axios.get(`${secondServerAPI}/rest/api/v1/udc/asets-udc/get-file-from-S3`, {
    //                     headers: {
    //                         "udc-id": file.udcId.toString(),
    //                         "file-type": "image"
    //                     },
    //                     responseType: 'blob'
    //                 });
    
    //                 // Check if response is successful
    //                 if (response.status !== 200) {
    //                     throw new Error(`Failed to fetch image for file ${file.filename}`);
    //                 }
    
    //                 const imageBlob = response.data;
    //                 const imageUrl = URL.createObjectURL(imageBlob);
    
    //                 return {
    //                     ...file,
    //                     imageLink: imageUrl,
    //                 };
    //             }));
    
    //             setUdcFiles(updatedFiles);
    //             setImagesFetched(true);
    //         } catch (error) {
    //             console.error("Error fetching images from S3", error);
    //         }
    //     }
    
    //     if (udcFiles.length > 0 && !imagesFetched) {
    //         getImages();
    //     }
    
    //     return () => {
    //         udcFiles.forEach(file => {
    //             if (file.imageLink) {
    //                 URL.revokeObjectURL(file.imageLink);
    //             }
    //         });
    //     };
    // }, [udcFiles, imagesFetched]);

    async function checkFilenameExists(filename: string): Promise<boolean> {
        return udcFiles.some(file => file.filename === filename);
    }

    async function downloadFile(udcId: number) {
        try {
            setIsLoading(true);
            const response = await Axios.get(`${secondServerAPI}/rest/api/v1/udc/asets-udc/get-file-from-S3`, {
                headers: {
                    "udc-id": udcId.toString(),
                    "file-type": "file",
                },
                responseType: 'blob'
            });
            const contentDisposition = response.headers['content-disposition'];
            let filename = 'downloaded-file';
    
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                if (filenameMatch && filenameMatch.length > 1) {
                    filename = filenameMatch[1];
                }
            }
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            URL.revokeObjectURL(url);
            setSuccessMessage("File downloaded successfully!");
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error("Error downloading file", error);
        }
    }

    useEffect(() => {
        if (selectedImageFile && udcId !== null) {
            uploadUDCImageFileComponent(udcId);
        }
    }, [selectedImageFile, udcId]);

    useEffect(() => {
        if (selectedOtherFile && udcId !== null) {
            uploadUDCFileComponent(udcId);
        }
    }, [selectedOtherFile, udcId]);

    async function createUDCComponent() {
        try {
            const response = await Axios.post(`${secondServerAPI}/rest/api/v1/udc/asets-udc/create-udc-component`, {}, {
                headers: {
                    "user-id": userId,
                    "filename": fileName,
                },
            });
            const createdUdcFile = response.data; 
            setUdcFiles(prevFiles => [...prevFiles, createdUdcFile]); 
            setUdcId(createdUdcFile.udcId); 
            setShowFormFields(true); 
        } catch (error) {
            console.error("Error creating UDC component", error);
        }
    }

    async function uploadUDCImageFileComponent(udcId: number) {
        try {
            if (!selectedImageFile) {
                console.error("Please select an image before uploading.");
                return;
            }
    
            const formData = new FormData();
            formData.append('file', selectedImageFile);
    
            const imageResponse = await Axios.post(`${secondServerAPI}/zuul/rest/api/v1/udc/asets-udc/upload-component-file`, formData, {
                headers: {
                    "user-id": userId,
                    "udc-id": udcId.toString(),
                    "file-type": "image",
                    "Content-Type": "multipart/form-data" 
                }
            });
    
            const imageLink = imageResponse.data.imageLink;
    
            setUdcFiles(prevFiles => 
                prevFiles.map(file => 
                    file.udcId === udcId
                    ? { 
                        ...file, 
                        imageLink, 
                        udcId: file.udcId, 
                        filename: file.filename,
                        description: file.description,
                        fileAddress: file.fileAddress,
                        fileSize: file.fileSize,
                        fileUploadedOn: file.fileUploadedOn,
                        udcComponentType: file.udcComponentType,
                    } 
                    : file
                ) as UDCFile[], 
                
            );
            setSuccessMessage("Image uploaded successfully!");
        } catch (error) {
            console.error("Error uploading image file", error);
        }
    }
    

    async function uploadUDCFileComponent(udcId: number) {
        try {
            if (!selectedOtherFile) {
                console.error("Please select a file before uploading.");
                return;
            }
    
            const formData = new FormData();
            formData.append('file', selectedOtherFile);
    
            const fileResponse = await Axios.post(`${secondServerAPI}/zuul/rest/api/v1/udc/asets-udc/upload-component-file`, formData, {
                headers: {
                    "user-id": userId,
                    "udc-id": udcId.toString(),
                    "file-type": "file",
                    "Content-Type": "multipart/form-data"
                }
            });
    
            const fileAddress = fileResponse.data.fileAddress;
            const fileSize = fileResponse.data.fileSize; 
    
            setUdcFiles(prevFiles => 
                prevFiles.map(file => 
                    file.udcId === udcId 
                    ? { ...file, fileAddress, fileSize } 
                    : file
                ) as UDCFile[]
            );
            setSuccessMessage("File uploaded successfully!");
        } catch (error) {
            console.error("Error uploading UDC file", error);
        }
    }
    

    async function updateUDCComponent() {
        if (udcId === null) return;
        try {
            const existingFile = udcFiles.find(file => file.udcId === udcId);
            if (!existingFile) {
                console.error("UDC Component not found");
                return;
            }
            const payload = {
                udcId,
                filename: fileName || existingFile.filename,
                description: description || existingFile.description,
                imageLink: existingFile.imageLink,  
                fileAddress: existingFile.fileAddress,  
                fileSize: existingFile.fileSize,  
                fileUploadedOn: existingFile.fileUploadedOn || new Date().toISOString(),
                udcComponentType: newcomponentType || existingFile.udcComponentType
            };
            const response = await Axios.post(`${secondServerAPI}/rest/api/v1/udc/asets-udc/update-component`, payload, {
                headers: {
                    "user-id": userId,
                    "udc-id": udcId.toString(),
                }
            });
    
            const updatedUdcFile = response.data;
            setUdcFiles(prevFiles => 
                prevFiles.map(file => 
                    file.udcId === updatedUdcFile.udcId 
                    ? updatedUdcFile 
                    : file
                ) as UDCFile[]
            );
    
            console.log("UDC Component updated successfully", updatedUdcFile);
            setSuccessMessage("UDC Component updated successfully");
        } catch (error) {
            console.error("Error updating UDC component", error);
        }
    }
    

    const filteredFiles = udcFiles.filter(file => 
        file.filename.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (componentType === 'All' || (file.udcComponentType && file.udcComponentType.toLowerCase() === componentType.toLowerCase()))
    );
    

    function deleteComponent(udcId: number) {
        try{
            const response = Axios.delete(`${secondServerAPI}/rest/api/v1/udc/asets-udc/delete-component`,{
                headers: {
                    "user-id": userId,
                    "udc-id": udcId.toString(),
                }
        });
        setSuccessMessage("UDC Component deleted successfully");
        console.log("UDC Component deleted successfully", response);
        }catch(e){
            console.log(e);
        }
    }

    return (
        <div className='Primary_Container'>
            <div className='Secondary_Container'>
            {(userId === 1 || userId === 38 || userId === 39 || userId === 60 || userId === 61 || userId === 62)  && (
                <div className='Upload_Form'>
                    <h1>3D Files</h1>
                    <p>Download 3D files as you want</p>
                    
                        <div className='form_items'>
                            <input
                                type="text"
                                value={fileName}
                                onChange={(e)=> setFileName(e.target.value)}
                                placeholder="Enter File Name"
                                className="Input_Field"
                            />
                            <button 
                                onClick={createUDCComponent}
                                disabled={!fileName || showFormFields}
                                className="Back_Button"
                            >
                                Create
                            </button>

                            {showFormFields && (
                                <>
                                    <label>
                                        Select Display Image
                                        <input 
                                            type="file" 
                                            onChange={(e) => setSelectedImageFile(e.target.files?.[0] || null)}
                                            accept="image/*"
                                            className="Input_File"
                                        />
                                    </label>

                                    <label>
                                        Upload UDC Component File
                                        <input 
                                            type="file" 
                                            onChange={(e) => setSelectedOtherFile(e.target.files?.[0] || null)}
                                            accept=".idsv"
                                            className="Input_File"
                                        />
                                    </label>
                                    <label>
                                        Enter the Description
                                        <textarea 
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            placeholder="Enter file description"
                                            className="Input_Description"
                                        />
                                    </label>
                                    <select 
                                        value={newcomponentType} 
                                        onChange={(e) => setNewComponentType(e.target.value)}
                                        className="Input_Select"
                                    >
                                        <option value="All">All</option>
                                        <option value="Equipment">Equipment</option>
                                        <option value="Electrical">Electrical</option>
                                        <option value="Physical">Physical</option>
                                    </select>
                                    <button 
                                        onClick={updateUDCComponent}
                                        className="Download_Button"
                                    >
                                        Update
                                    </button>
                                    {successMessage && <div className="Success_Message">{successMessage}</div>}
                                </>
                            )}
                        </div>
                    
                </div>
            )}
    
                {selectedFile ? (
                    <div className='Selected_File_Container'>
                        <div className='Selected_File_Image'>
                            {selectedFile.imageLink ? (
                                <img src={selectedFile.imageLink} alt={selectedFile.filename} className='Image_Large' />
                            ) : (
                                <div className='No_Image_Large'>No image available</div>
                            )}
                        </div>
                        <div className='Selected_File_Info'>
                            <h3>{selectedFile.filename}</h3>
                            <p>{selectedFile.description}</p>
                            <p>File Size: {selectedFile.fileSize}</p>
                            <p>File Type: {selectedFile.udcComponentType}</p>
                            <button onClick={() => downloadFile(selectedFile.udcId)} className='Download_Button'>
                                Download File
                            </button>
                            <button onClick={() => setSelectedFile(null)} className='Back_Button'>
                                Back to List
                            </button>
                            {(userId === 1 || userId === 38 || userId === 39 || userId === 60 || userId === 61 || userId === 62) && (
                                <button onClick={() => deleteComponent(selectedFile.udcId)} className='Delete_Button'>
                                    Delete
                                </button>
                            )}
                            {isLoading && (
                                <Spinner/>
                            )}
                            {successMessage && <div className="Success_Message">{successMessage}</div>}
                        </div>
                    </div>
                ) : (
                    <div className='Image_Container_Primary'>
                        <h2>3D Files</h2>
                        <p>Explore and download a curated collection of 3D files, including equipment, electrical components, and physical models. Use the search and filter options to find the specific file you need.</p>
                        <div className='search_box'>
                        <input 
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search files"
                            className="Input_Search"
                            title="Search by file name or description"
                        />
                            <select 
                                value={componentType} 
                                onChange={(e) => setComponentType(e.target.value)}
                                className="Input_Select"
                            >
                                <option value="All">All</option>
                                <option value="Equipment">Equipment</option>
                                <option value="Electrical">Electrical</option>
                                <option value="Physical">Physical</option>
                            </select>
                        </div>
                        <div className='Image_Container_Secondary'>
                            {filteredFiles.map(file => (
                                <div key={file.udcId} className="Image_Card" onClick={() => setSelectedFile(file)}>
                                    <div className="Image_Header">
                                        {file.imageLink ? (
                                            <img src={file.imageLink} alt={file.filename} className='Image_Thumbnail' />
                                        ) : (
                                            <div className='No_Image'>No image available</div>
                                        )}
                                        <div className='Image_Info'>
                                            <h3>{file.filename}</h3>
                                            <p>{file.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
