import GLTFLoader from "three-gltf-loader";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter.js";
import { PLYExporter } from "three/examples/jsm/exporters/PLYExporter.js";
import { ColladaExporter } from "three/examples/jsm/exporters/ColladaExporter.js";
import { Color, Material, Mesh, MeshStandardMaterial, Object3D, Scene } from "three";
import saveAs from "file-saver";
import { BeamIfc, IfcModelDataValidationModelIfc, ModelsDataIfc, Project, ProjectModelIfc } from "../../../store/main/types";
import axios from "axios";
import { secondServerAPI } from "../../../pages/utils/agent";

const glTF = new GLTFExporter();
const ply = new PLYExporter();
const collada = new ColladaExporter();

function removeElement(scene: Scene, name: string) {
  const element = scene.getObjectByName(name);
  while (element && element.parent) {
    element.parent.remove(element);
  }
}

export function fixSceneForConverting(scene: Scene) {
  const fixed = scene.clone();
  removeElement(fixed, "AxesBoxHelper");
  removeElement(fixed, "DROP-CIRCLE");
  removeElement(fixed, "DROP-CYLINDER-X");
  removeElement(fixed, "DROP-CYLINDER-Y");
  removeElement(fixed, "DROP-CYLINDER-Z");
  removeElement(fixed, "PIPE-ELEMENT-ANCHOR");
  removeElement(fixed, "COORDS");
  removeElement(fixed, "CENTER-COORDS");
  removeElement(fixed, "DISTANCE-HELPER");
  // fixed.scale.multiplyScalar(1000);
  return fixed;
}

export function exportToGLTF(scene: Scene, name: string) {
  try {
    const fixed = fixSceneForConverting(scene);
    glTF.parse(
      fixed,
      (obj) => {
        console.log(obj);
        const data = new Blob([JSON.stringify(obj)]);
        saveAs(data, `${name}.idsv`);
      },
      {}
    );
  } catch (error) {
    console.error(error);
  }
}

// function create_analysis_data(project: Project) {
//   const replaceNullWithZero = (value: any) => (value === null || value === 'null' || value === true || value === false) ? 0 : value;

//   const data_1: ModelsDataIfc[] = project.models.map(model => ({
//     name: replaceNullWithZero(model.name),
//     project: replaceNullWithZero(model.project),
//     type: replaceNullWithZero(model.type),
//     baseElevation: replaceNullWithZero(model.baseElevation),
//     startPos: {
//       x: replaceNullWithZero(model.startPos?.x),
//       y: replaceNullWithZero(model.startPos?.y),
//       z: replaceNullWithZero(model.startPos?.z)
//     },
//     direction: replaceNullWithZero(model.direction),
//     CSLibrary: replaceNullWithZero(model.CSLibrary),
//     frameColProfile: {
//       ...model.frameColProfile,
//       b: replaceNullWithZero(model.frameColProfile.b),
//       c: replaceNullWithZero(model.frameColProfile.c),
//       de: replaceNullWithZero(model.frameColProfile.de),
//       i: replaceNullWithZero(model.frameColProfile.i),
//       k: replaceNullWithZero(model.frameColProfile.k),
//       k1: replaceNullWithZero(model.frameColProfile.k1),
//       r1: replaceNullWithZero(model.frameColProfile.r1),
//       r2: replaceNullWithZero(model.frameColProfile.r2),
//       z: replaceNullWithZero(model.frameColProfile.z),
//       zz: replaceNullWithZero(model.frameColProfile.zz),
//       created_by: replaceNullWithZero(model.frameColProfile.created_by),
//       updated_by: replaceNullWithZero(model.frameColProfile.updated_by),
//       width_global: replaceNullWithZero(model.frameColProfile.width_global),
//       thickness_global: replaceNullWithZero(model.frameColProfile.thickness_global),
//       height_global: replaceNullWithZero(model.frameColProfile.height_global),
//       ax_global: replaceNullWithZero(model.frameColProfile.ax_global),
//       b_global: replaceNullWithZero(model.frameColProfile.b_global),
//       bf_global: replaceNullWithZero(model.frameColProfile.bf_global),
//       c_global: replaceNullWithZero(model.frameColProfile.c_global),
//       ct_global: replaceNullWithZero(model.frameColProfile.ct_global),
//       d_global: replaceNullWithZero(model.frameColProfile.d_global),
//       de_global: replaceNullWithZero(model.frameColProfile.de_global),
//       i_global: replaceNullWithZero(model.frameColProfile.i_global),
//       ix_global: replaceNullWithZero(model.frameColProfile.ix_global),
//       iy_global: replaceNullWithZero(model.frameColProfile.iy_global),
//       iz_global: replaceNullWithZero(model.frameColProfile.iz_global),
//       k_global: replaceNullWithZero(model.frameColProfile.k_global),
//       k1_global: replaceNullWithZero(model.frameColProfile.k1_global),
//       od_global: replaceNullWithZero(model.frameColProfile.od_global),
//       r1_global: replaceNullWithZero(model.frameColProfile.r1_global),
//       r2_global: replaceNullWithZero(model.frameColProfile.r2_global),
//       rz_global: replaceNullWithZero(model.frameColProfile.rz_global),
//       t_global: replaceNullWithZero(model.frameColProfile.t_global),
//       tf_global: replaceNullWithZero(model.frameColProfile.tf_global),
//       tw_global: replaceNullWithZero(model.frameColProfile.tw_global),
//       z_global: replaceNullWithZero(model.frameColProfile.z_global),
//       zx_global: replaceNullWithZero(model.frameColProfile.zx_global),
//       zy_global: replaceNullWithZero(model.frameColProfile.zy_global),
//       zz_global: replaceNullWithZero(model.frameColProfile.zz_global)
//     },
//     frameBeamProfile: {
//       ...model.frameBeamProfile,
//       b: replaceNullWithZero(model.frameBeamProfile.b),
//       c: replaceNullWithZero(model.frameBeamProfile.c),
//       de: replaceNullWithZero(model.frameBeamProfile.de),
//       i: replaceNullWithZero(model.frameBeamProfile.i),
//       k: replaceNullWithZero(model.frameBeamProfile.k),
//       k1: replaceNullWithZero(model.frameBeamProfile.k1),
//       r1: replaceNullWithZero(model.frameBeamProfile.r1),
//       r2: replaceNullWithZero(model.frameBeamProfile.r2),
//       z: replaceNullWithZero(model.frameBeamProfile.z),
//       zz: replaceNullWithZero(model.frameBeamProfile.zz),
//       created_by: replaceNullWithZero(model.frameBeamProfile.created_by),
//       updated_by: replaceNullWithZero(model.frameBeamProfile.updated_by),
//       width_global: replaceNullWithZero(model.frameBeamProfile.width_global),
//       thickness_global: replaceNullWithZero(model.frameBeamProfile.thickness_global),
//       height_global: replaceNullWithZero(model.frameBeamProfile.height_global),
//       ax_global: replaceNullWithZero(model.frameBeamProfile.ax_global),
//       b_global: replaceNullWithZero(model.frameBeamProfile.b_global),
//       bf_global: replaceNullWithZero(model.frameBeamProfile.bf_global),
//       c_global: replaceNullWithZero(model.frameBeamProfile.c_global),
//       ct_global: replaceNullWithZero(model.frameBeamProfile.ct_global),
//       d_global: replaceNullWithZero(model.frameBeamProfile.d_global),
//       de_global: replaceNullWithZero(model.frameBeamProfile.de_global),
//       i_global: replaceNullWithZero(model.frameBeamProfile.i_global),
//       ix_global: replaceNullWithZero(model.frameBeamProfile.ix_global),
//       iy_global: replaceNullWithZero(model.frameBeamProfile.iy_global),
//       iz_global: replaceNullWithZero(model.frameBeamProfile.iz_global),
//       k_global: replaceNullWithZero(model.frameBeamProfile.k_global),
//       k1_global: replaceNullWithZero(model.frameBeamProfile.k1_global),
//       od_global: replaceNullWithZero(model.frameBeamProfile.od_global),
//       r1_global: replaceNullWithZero(model.frameBeamProfile.r1_global),
//       r2_global: replaceNullWithZero(model.frameBeamProfile.r2_global),
//       rz_global: replaceNullWithZero(model.frameBeamProfile.rz_global),
//       t_global: replaceNullWithZero(model.frameBeamProfile.t_global),
//       z_global: replaceNullWithZero(model.frameBeamProfile.z_global),
//       tf_global: replaceNullWithZero(model.frameBeamProfile.tf_global),
//       tw_global: replaceNullWithZero(model.frameBeamProfile.tw_global),
//       zx_global: replaceNullWithZero(model.frameBeamProfile.zx_global),
//       zy_global: replaceNullWithZero(model.frameBeamProfile.zy_global),
//       zz_global: replaceNullWithZero(model.frameBeamProfile.zz_global)
//     },
//     columns: model.columns.map(column => ({
//       ...column,
//       orientation: replaceNullWithZero(column.orientation),
//       startConnected: column.startConnected || [],
//       connected: column.connected || [],
//       endConnected: column.endConnected || [],
//       profile: {
//         profile_section_id: replaceNullWithZero(column.profile?.profile_section_id),
//         name: replaceNullWithZero(column.profile?.name),
//         type: replaceNullWithZero(column.profile?.type),
//         designation: replaceNullWithZero(column.profile?.designation),
//         ax: replaceNullWithZero(column.profile?.ax),
//         ax_global: replaceNullWithZero(column.profile?.ax_global),
//         b: replaceNullWithZero(column.profile?.b),
//         b_global: replaceNullWithZero(column.profile?.b_global),
//         bf: replaceNullWithZero(column.profile?.bf),
//         bf_global: replaceNullWithZero(column.profile?.bf_global),
//         c: replaceNullWithZero(column.profile?.c),
//         c_global: replaceNullWithZero(column.profile?.c_global),
//         country_code: replaceNullWithZero(column.profile?.country_code),
//         created_by: replaceNullWithZero(column.profile?.created_by),
//         created_on: replaceNullWithZero(column.profile?.created_on),
//         ct: replaceNullWithZero(column.profile?.ct),
//         ct_global: replaceNullWithZero(column.profile?.ct_global),
//         d: replaceNullWithZero(column.profile?.d),
//         d_global: replaceNullWithZero(column.profile?.d_global),
//         de: replaceNullWithZero(column.profile?.de),
//         de_global: replaceNullWithZero(column.profile?.de_global),
//         height: replaceNullWithZero(column.profile?.height),
//         height_global: replaceNullWithZero(column.profile?.height_global),
//         i: replaceNullWithZero(column.profile?.i),
//         i_global: replaceNullWithZero(column.profile?.i_global),
//         ix: replaceNullWithZero(column.profile?.ix),
//         ix_global: replaceNullWithZero(column.profile?.ix_global),
//         iy: replaceNullWithZero(column.profile?.iy),
//         iy_global: replaceNullWithZero(column.profile?.iy_global),
//         iz: replaceNullWithZero(column.profile?.iz),
//         iz_global: replaceNullWithZero(column.profile?.iz_global),
//         k: replaceNullWithZero(column.profile?.k),
//         k1: replaceNullWithZero(column.profile?.k1),
//         k1_global: replaceNullWithZero(column.profile?.k1_global),
//         k_global: replaceNullWithZero(column.profile?.k_global),
//         od: replaceNullWithZero(column.profile?.od),
//         od_global: replaceNullWithZero(column.profile?.od_global),
//         r1: replaceNullWithZero(column.profile?.r1),
//         r1_global: replaceNullWithZero(column.profile?.r1_global),
//         r2: replaceNullWithZero(column.profile?.r2),
//         r2_global: replaceNullWithZero(column.profile?.r2_global),
//         rz: replaceNullWithZero(column.profile?.rz),
//         rz_global: replaceNullWithZero(column.profile?.rz_global),
//         shape: replaceNullWithZero(column.profile?.shape),
//         t: replaceNullWithZero(column.profile?.t),
//         t_global: replaceNullWithZero(column.profile?.t_global),
//         tf: replaceNullWithZero(column.profile?.tf),
//         tf_global: replaceNullWithZero(column.profile?.tf_global),
//         thickness: replaceNullWithZero(column.profile?.thickness),
//         thickness_global: replaceNullWithZero(column.profile?.thickness_global),
//         tw: replaceNullWithZero(column.profile?.tw),
//         tw_global: replaceNullWithZero(column.profile?.tw_global),
//         updated_by: replaceNullWithZero(column.profile?.updated_by),
//         updated_on: replaceNullWithZero(column.profile?.updated_on),
//         width: replaceNullWithZero(column.profile?.width),
//         width_global: replaceNullWithZero(column.profile?.width_global),
//         z: replaceNullWithZero(column.profile?.z),
//         z_global: replaceNullWithZero(column.profile?.z_global),
//         zx: replaceNullWithZero(column.profile?.zx),
//         zx_global: replaceNullWithZero(column.profile?.zx_global),
//         zy: replaceNullWithZero(column.profile?.zy),
//         zy_global: replaceNullWithZero(column.profile?.zy_global),
//         zz: replaceNullWithZero(column.profile?.zz),
//         zz_global: replaceNullWithZero(column.profile?.zz_global)
//       }
//     })),    
//     beams: model.beams.map(beam => ({
//       ...beam,
//       orientation: replaceNullWithZero(beam.orientation),
//       startConnected: beam.startConnected || [],
//       connected: beam.connected || [],
//       endConnected: beam.endConnected || [],
//       profile: {
//         profile_section_id: replaceNullWithZero(beam.profile?.profile_section_id),
//         name: replaceNullWithZero(beam.profile?.name),
//         type: replaceNullWithZero(beam.profile?.type),
//         designation: replaceNullWithZero(beam.profile?.designation),
//         ax: replaceNullWithZero(beam.profile?.ax),
//         ax_global: replaceNullWithZero(beam.profile?.ax_global),
//         b: replaceNullWithZero(beam.profile?.b),
//         b_global: replaceNullWithZero(beam.profile?.b_global),
//         bf: replaceNullWithZero(beam.profile?.bf),
//         bf_global: replaceNullWithZero(beam.profile?.bf_global),
//         c: replaceNullWithZero(beam.profile?.c),
//         c_global: replaceNullWithZero(beam.profile?.c_global),
//         country_code: replaceNullWithZero(beam.profile?.country_code),
//         created_by: replaceNullWithZero(beam.profile?.created_by),
//         created_on: replaceNullWithZero(beam.profile?.created_on),
//         ct: replaceNullWithZero(beam.profile?.ct),
//         ct_global: replaceNullWithZero(beam.profile?.ct_global),
//         d: replaceNullWithZero(beam.profile?.d),
//         d_global: replaceNullWithZero(beam.profile?.d_global),
//         de: replaceNullWithZero(beam.profile?.de),
//         de_global: replaceNullWithZero(beam.profile?.de_global),
//         height: replaceNullWithZero(beam.profile?.height),
//         height_global: replaceNullWithZero(beam.profile?.height_global),
//         i: replaceNullWithZero(beam.profile?.i),
//         i_global: replaceNullWithZero(beam.profile?.i_global),
//         ix: replaceNullWithZero(beam.profile?.ix),
//         ix_global: replaceNullWithZero(beam.profile?.ix_global),
//         iy: replaceNullWithZero(beam.profile?.iy),
//         iy_global: replaceNullWithZero(beam.profile?.iy_global),
//         iz: replaceNullWithZero(beam.profile?.iz),
//         iz_global: replaceNullWithZero(beam.profile?.iz_global),
//         k: replaceNullWithZero(beam.profile?.k),
//         k1: replaceNullWithZero(beam.profile?.k1),
//         k1_global: replaceNullWithZero(beam.profile?.k1_global),
//         k_global: replaceNullWithZero(beam.profile?.k_global),
//         od: replaceNullWithZero(beam.profile?.od),
//         od_global: replaceNullWithZero(beam.profile?.od_global),
//         r1: replaceNullWithZero(beam.profile?.r1),
//         r1_global: replaceNullWithZero(beam.profile?.r1_global),
//         r2: replaceNullWithZero(beam.profile?.r2),
//         r2_global: replaceNullWithZero(beam.profile?.r2_global),
//         rz: replaceNullWithZero(beam.profile?.rz),
//         rz_global: replaceNullWithZero(beam.profile?.rz_global),
//         shape: replaceNullWithZero(beam.profile?.shape),
//         t: replaceNullWithZero(beam.profile?.t),
//         t_global: replaceNullWithZero(beam.profile?.t_global),
//         tf: replaceNullWithZero(beam.profile?.tf),
//         tf_global: replaceNullWithZero(beam.profile?.tf_global),
//         thickness: replaceNullWithZero(beam.profile?.thickness),
//         thickness_global: replaceNullWithZero(beam.profile?.thickness_global),
//         tw: replaceNullWithZero(beam.profile?.tw),
//         tw_global: replaceNullWithZero(beam.profile?.tw_global),
//         updated_by: replaceNullWithZero(beam.profile?.updated_by),
//         updated_on: replaceNullWithZero(beam.profile?.updated_on),
//         width: replaceNullWithZero(beam.profile?.width),
//         width_global: replaceNullWithZero(beam.profile?.width_global),
//         z: replaceNullWithZero(beam.profile?.z),
//         z_global: replaceNullWithZero(beam.profile?.z_global),
//         zx: replaceNullWithZero(beam.profile?.zx),
//         zx_global: replaceNullWithZero(beam.profile?.zx_global),
//         zy: replaceNullWithZero(beam.profile?.zy),
//         zy_global: replaceNullWithZero(beam.profile?.zy_global),
//         zz: replaceNullWithZero(beam.profile?.zz),
//         zz_global: replaceNullWithZero(beam.profile?.zz_global)
//       }
//     }))    
//   }));

//   const data_2: ProjectModelIfc = {
//     name: replaceNullWithZero(project.name),
//     models: data_1
//   };

//   const data_3: IfcModelDataValidationModelIfc = {
//     name: replaceNullWithZero(project.name),
//     project: data_2
//   };

//   return data_3;
// }


// export function exporttoIFC(project: Project) {
//   const analysis_data = create_analysis_data(project);
//   axios.post(`${secondServerAPI}/ifc/ifc/structure`, analysis_data, {
//     responseType: 'blob'
//   })
//   .then((response: any) => {
//     const fileURL = URL.createObjectURL(new Blob([response.data]));
//     const fileLink = document.createElement('a');
//     fileLink.href = fileURL;
//     fileLink.setAttribute('download', `${project.name}_analysis_data.ifc`);
//     document.body.appendChild(fileLink);
//     fileLink.click();
//     fileLink.parentNode?.removeChild(fileLink);
//     URL.revokeObjectURL(fileURL);
//   })
//   .catch((error) => {
//     console.error("Error exporting to IFC:", error);
//   });
// }


export function exportToPLY(scene: Scene, name: string) {
  const fixed = fixSceneForConverting(scene);
  ply.parse(
    fixed,
    (obj) => {
      console.log(obj);
      const data = new Blob([obj]);
      saveAs(data, `${name}.ply`);
    },
    {}
  );
}

export function exportToCollada(scene: Scene, name: string) {
  const fixed = fixSceneForConverting(scene);
  collada.parse(
    fixed,
    (obj) => {
      console.log(obj);
      const data = new Blob([JSON.stringify(obj)]);
      saveAs(data, `${name}.dae`);
    },
    {}
  );
}

// export function loadGLTF(file: File, callback: (scene: Scene) => any) {
//   const loader = new GLTFLoader();
//   file
//     .arrayBuffer()
//     .then((buffer) => loader.parse(buffer, "", (gltf) => callback(gltf.scene)));
// }

export function loadGLTF(file: File, callback: (scene: Scene) => any) {
  const loader = new GLTFLoader();

  file.arrayBuffer().then((buffer) => {
    loader.parse(buffer, "", (gltf) => {
      gltf.scene.traverse((node: Object3D) => {
        if (node instanceof Mesh) {
          const mesh = node as Mesh;
          const materials: Material[] = Array.isArray(mesh.material)
            ? mesh.material
            : [mesh.material];
          materials.forEach((material) => {
            if (material instanceof MeshStandardMaterial) {
              material.color = material.color || new THREE.Color(0xffffff);
              material.map = material.map || null;
              material.metalness = material.metalness !== undefined ? material.metalness : 0.5;
              material.roughness = material.roughness !== undefined ? material.roughness : 0.5;
              material.emissive = material.emissive || new THREE.Color(0x000000);
              material.emissiveIntensity = material.emissiveIntensity !== undefined ? material.emissiveIntensity : 1;
              material.envMap = material.envMap || null;
            }
          });
          materials.forEach((material) => {
            if (material instanceof MeshStandardMaterial) {
              material.color = new Color(material.color.getHex()).multiplyScalar(1.5); 
              material.emissive = material.emissive.clone().multiplyScalar(1.2); 
              material.emissiveIntensity = Math.min(material.emissiveIntensity * 1.2, 1); 
              material.needsUpdate = true; 
            }
          });
        }
      });
      callback(gltf.scene);
    });
  });
}


// export function loadGLTF2(file: File, project: string, callback: (scene: Scene) => any) {
//   const loader = new GLTFLoader();
//   file.arrayBuffer().then((buffer) => {
//     loader.parse(buffer, "", (gltf) => {
//       const filteredScene = new Scene();

//       gltf.scene.traverse((child: Object3D) => {
//         if ((child as Mesh).isMesh) {
//           (child as Mesh).userData = {
//             ...child.userData,
//             isGLTFObject: true,
//             name: file.name,
//             projectname: project,
//           };
//           filteredScene.add(child);
//         }
//       });

//       callback(filteredScene);
//     });
//   });
// }


// export function loadGLTF2(file: File, project: string, callback: (scene: Scene) => any) {
//   const loader = new GLTFLoader();

//   file.arrayBuffer().then((buffer) => {
//     loader.parse(buffer, "", (gltf) => {
//       const filteredScene = new Scene();

//       gltf.scene.traverse((child: Object3D) => {
//         if (child instanceof Mesh) {
//           const materials = Array.isArray(child.material) ? child.material : [child.material];

//           materials.forEach((material) => {
//             if (material instanceof MeshStandardMaterial) {
//               material.color = new Color(material.color.getHex()).multiplyScalar(4.5); 
//               material.emissive = material.emissive.clone().multiplyScalar(3.6); 
//               material.emissiveIntensity = Math.min(material.emissiveIntensity * 3.6, 1); 
//               material.needsUpdate = true; 
//             }
//           });

//           child.userData = {
//             ...child.userData,
//             isGLTFObject: true,
//             name: file.name,
//             projectname: project,
//           };

//           filteredScene.add(child);
//         }
//       });

//       callback(filteredScene);
//     });
//   });
// }


export function loadGLTF2(file: File, project: string, callback: (scene: Scene) => any) {
  const loader = new GLTFLoader();

  file.arrayBuffer().then((buffer) => {
    loader.parse(buffer, "", (gltf) => {
      const filteredScene = new Scene();

      gltf.scene.traverse((child: Object3D) => {
        if (child instanceof Mesh) {
          // Flatten the child object's transformation hierarchy
          child.updateMatrixWorld(true);

          // Apply the transformation matrix to the geometry (bake the transformations)
          // if (child.geometry) {
          //   child.geometry.applyMatrix4(child.matrixWorld);
          //   child.matrix.identity(); // Reset the object's transformation matrix
          // }

          // Set the position of the object to (0, 0, 0) in world coordinates
          // child.position.set(0, 0, 0);
          child.rotation.set(0, 0, 0);
          // child.scale.set(1, 1, 1); // Reset scale

          // Optionally, normalize the scale if needed (based on your GLTF model size)
          // const scaleFactor = 1; // Adjust as necessary
          // child.scale.set(scaleFactor, scaleFactor, scaleFactor);

          // Update the geometry's bounding box and center it, if necessary
          // const box = new THREE.Box3().setFromObject(child);
          // const center = new THREE.Vector3();
          // box.getCenter(center);

          // Center the object's geometry around (0, 0, 0)
          // if (child.geometry) {
          //   child.geometry.translate(-center.x, -center.y, -center.z);
          // }

          // Ensure the materials are properly set
          const materials = Array.isArray(child.material) ? child.material : [child.material];
          materials.forEach((material) => {
            if (material instanceof MeshStandardMaterial) {
              material.color = new Color(material.color.getHex()).multiplyScalar(4.5);
              material.emissive = material.emissive.clone().multiplyScalar(3.6);
              material.emissiveIntensity = Math.min(material.emissiveIntensity * 3.6, 1);
              material.needsUpdate = true;
            }
          });

          // Add necessary user data
          child.userData = {
            ...child.userData,
            isGLTFObject: true,
            name: file.name,
            projectname: project,
            ClippedAsset: child.userData.ClippedAsset !== undefined ? child.userData.ClippedAsset : false,
            Type: child.userData.Type !== undefined ? child.userData.Type : undefined,
            texture: child.userData.texture !== undefined ? child.userData.texture : undefined,
            Height: child.userData.Height !== undefined ? child.userData.Height : undefined,
            Width: child.userData.Width !== undefined ? child.userData.Width : undefined,
            length: child.userData.length !== undefined ? child.userData.length : undefined,
            Volume: child.userData.Volume !== undefined ? child.userData.Volume : undefined,
            Thickness: child.userData.Thickness !== undefined ? child.userData.Thickness : undefined,
            SurfaceArea: child.userData.SurfaceArea !== undefined ? child.userData.SurfaceArea : undefined,
            Weight: child.userData.Weight !== undefined ? child.userData.Weight : undefined,
            Density: child.userData.Density !== undefined ? child.userData.Density : undefined,
          };

          // Add the child mesh to the filtered scene
          filteredScene.add(child);
        }
      });

      // Return the filtered scene with centered, scaled, and reset transformations
      callback(filteredScene);
    });
  });
}



// export function loadGLTF2(file: File, project: string,  callback: (scene: Scene) => any, userData: any = {},) {
//   const loader = new GLTFLoader();
  
//   const reader = new FileReader();
  
//   reader.onload = function (event) {
//     const contents = event.target?.result as string | ArrayBuffer;

//     if (typeof contents === "string") {
//       loader.parse(contents, "", (gltf) => {
//         console.log("this is the gltf scene",gltf.scene)
//         processGLTFScene(gltf.scene);
//       }, (error) => {
//         console.error('Error parsing GLTF file:', error);
//       });
//     } else if (contents instanceof ArrayBuffer) {
//       loader.parse(contents, "", (gltf) => {
//         processGLTFScene(gltf.scene);
//       }, (error) => {
//         console.error('Error parsing IDSV file:', error);
//       });
//     }
//   };

//   reader.onerror = function (error) {
//     console.error('Error reading the IDSV file:', error);
//   };
//   reader.readAsArrayBuffer(file);
// }

//   function processGLTFScene(scene: Scene) {
//     const filteredScene = new Scene();
  
//     scene.traverse((child: Object3D) => {
//       if ((child as Mesh).isMesh) {
//         const meshChild = child as Mesh;
//         const materials = Array.isArray(meshChild.material) ? meshChild.material : [meshChild.material];
//         materials.forEach((material) => {
//           if (material instanceof MeshStandardMaterial) {
//             material.color = new Color(material.color.getHex()).multiplyScalar(1.5);
//             material.emissive = material.emissive.clone().multiplyScalar(1.1);
//             material.emissiveIntensity = Math.min(material.emissiveIntensity * 1.1, 1);
//             material.needsUpdate = true;
//           }
//         });

//         // if (userData.Type && userData.texture) {
//         //   applyTextureToMesh(meshChild, userData.texture);
//         // }
//         meshChild.userData = {
//           ...meshChild.userData,
//           isGLTFObject: true,
//           name: file.name,
//           projectname: project,
//           ClippedAsset: meshChild.userData.ClippedAsset !== undefined ? meshChild.userData.ClippedAsset : false,
//           Type: userData.Type !== undefined ? userData.Type : undefined,
//           texture: userData.texture !== undefined ? userData.texture : undefined,
//           Height: userData.Height !== undefined ? userData.Height : undefined,
//           Width: userData.Width !== undefined ? userData.Width : undefined,
//           length: userData.length !== undefined ? userData.length : undefined,
//           Volume: userData.Volume !== undefined ? userData.Volume : undefined,
//           SurfaceArea: userData.SurfaceArea !== undefined ? userData.SurfaceArea : undefined,
//           Weight: userData.Weight !== undefined ? userData.Weight : undefined,
//           Density: userData.Density !== undefined ? userData.Density : undefined,
//         };
//         filteredScene.add(meshChild);
//       }
//     });
//     callback(filteredScene);
//   }
  
  // function applyTextureToMesh(mesh: THREE.Mesh, textureType: string) {
  //   const textureLoader = new THREE.TextureLoader();
  
  //   let texturePath: string | null = null;
  //   let normalMapPath: string | null = null;
  //   let roughnessMapPath: string | null = null;
  //   let metalnessMapPath: string | null = null;
  //   let bumpMapPath: string | null = null;
  //   let displacementMapPath: string | null = null;

  //   if (mesh.material.isMaterial) {
  //     mesh.material = mesh.material.clone();
  //   }

  //   switch (textureType) {
  //     case "concrete_wall":
  //       texturePath = ConcreteFloorTexturePath;
  //       normalMapPath = ConcreteFloorNormalMapPath;
  //       roughnessMapPath = ConcreteFloorRoughnessMapPath;
  //       metalnessMapPath = ConcreteFloorMetalnessMapPath;
  //       break;
  //     case "Rough_Wall":
  //       texturePath = RoughWallTexturePath;
  //       normalMapPath = RoughWallNormalMapPath;
  //       bumpMapPath = RoughWallBumpMapPath;
  //       displacementMapPath = RoughWallDisplacementMapPath;
  //       roughnessMapPath = RoughWallRoughnessMapPath;
  //       metalnessMapPath = RoughWallMetalnessMapPath;
  //       break;
  //     case "brick_wall":
  //       texturePath = BrickWallTexturePath;
  //       normalMapPath = BrickWallNormalMapPath;
  //       displacementMapPath = BrickWallDisplacementMapPath;
  //       roughnessMapPath = BrickWallRoughnessMapPath;
  //       metalnessMapPath = BrickWallMetalnessMapPath;
  //       break;
  //     case "asphalt":
  //       texturePath = asphaltTexturePath;
  //       normalMapPath = asphaltNormalMapPath;
  //       bumpMapPath = asphaltBumpMapPath;
  //       displacementMapPath = asphaltDisplacementMapPath;
  //       roughnessMapPath = asphaltRoughnessMapPath;
  //       metalnessMapPath = asphaltMetalnessMapPath;
  //       break;
  //     case "concrete":
  //       texturePath = ConcreteTexturePath;
  //       normalMapPath = ConcreteNormalMapPath;
  //       roughnessMapPath = ConcreteRoughnessMapPath;
  //       metalnessMapPath = ConcreteMetalnessMapPath;
  //       break;
  //     case "grass":
  //       texturePath = GrassTexturePath;
  //       normalMapPath = GrassNormalMapPath;
  //       roughnessMapPath = GrassRoughnessMapPath;
  //       metalnessMapPath = GrassMetalnessMapPath;
  //       break;
  //     default:
  //       return; 
  //   }
  
  //   if (texturePath) {
  //     textureLoader.load(texturePath, (texture) => {
  //       if (mesh.material.map) mesh.material.map.dispose(); 
  //       mesh.material.map = texture;
  //       mesh.material.needsUpdate = true;
  //     });
  //   }
  
  //   // Load and apply the normal map
  //   if (normalMapPath) {
  //     textureLoader.load(normalMapPath, (normalMap) => {
  //       if (mesh.material.normalMap) mesh.material.normalMap.dispose();
  //       mesh.material.normalMap = normalMap;
  //       mesh.material.needsUpdate = true;
  //     });
  //   }
  
  //   // Load and apply the roughness map
  //   if (roughnessMapPath) {
  //     textureLoader.load(roughnessMapPath, (roughnessMap) => {
  //       if (mesh.material.roughnessMap) mesh.material.roughnessMap.dispose();
  //       mesh.material.roughnessMap = roughnessMap;
  //       mesh.material.needsUpdate = true;
  //     });
  //   }
  
  //   // Load and apply the metalness map
  //   if (metalnessMapPath) {
  //     textureLoader.load(metalnessMapPath, (metalnessMap) => {
  //       if (mesh.material.metalnessMap) mesh.material.metalnessMap.dispose();
  //       mesh.material.metalnessMap = metalnessMap;
  //       mesh.material.needsUpdate = true;
  //     });
  //   }
  
  //   // Load and apply the bump map
  //   if (bumpMapPath) {
  //     textureLoader.load(bumpMapPath, (bumpMap) => {
  //       if (mesh.material.bumpMap) mesh.material.bumpMap.dispose();
  //       mesh.material.bumpMap = bumpMap;
  //       mesh.material.bumpScale = 0.1; // Adjust bump scale as needed
  //       mesh.material.needsUpdate = true;
  //     });
  //   }
  
  //   // Load and apply the displacement map
  //   if (displacementMapPath) {
  //     textureLoader.load(displacementMapPath, (displacementMap) => {
  //       if (mesh.material.displacementMap) mesh.material.displacementMap.dispose();
  //       mesh.material.displacementMap = displacementMap;
  //       mesh.material.displacementScale = 0.2;
  //       mesh.material.needsUpdate = true;
  //     });
  //   }
  // }
  
  




